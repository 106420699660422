import { useState } from "react"
import { TextStyle } from "../App"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { Paginator } from "../Components/Paginator"
import { UnitDefinition } from "../Config/UnitDefinitionMap"
import { HistoryElement, HistoryState } from "../Database/HistoryState"
import { InnerTile } from "../Styles/Styles"
import { Data } from "../Types/Interfaces"

export const HistoryElementDisplay: React.FC<Data<{ Unit: UnitDefinition<any>, History: HistoryElement[] }>> = props => {

    enum DisplayMode {
        All,
        Item
    }

    const [displayMode, setDisplayMode] = useState(DisplayMode.All)

    const [displayItem, setDisplayItem] = useState<any | undefined>(undefined)
    const [displayHistory, setDisplayHistory] = useState<HistoryElement | undefined>(undefined)

    var itemCache = {} as any

    props.Data.Unit.Items.map(i =>
        itemCache[props.Data.Unit.GetItemId(i)] = i
    )

    const SetItemView = (itemId: number) => {

        console.log(itemId)
        var item = itemCache[itemId]
        console.log(item)
        var unitId = props.Data.Unit.UnitId;
        var tdId = props.Data.Unit.ParentTestDefinitionId;

        (new HistoryState()).GetByItemId(unitId, tdId, itemId)
            .then((historyElement => {
                var el = historyElement.shift()
                console.log(el)
                setDisplayItem(item)
                setDisplayHistory(el)
                setDisplayMode(DisplayMode.Item)

            }))

    }

    return (
        <>
            {displayMode == DisplayMode.All && <>
                <Paginator>
                    {
                        props.Data.History.map(h => {
                            var item = itemCache[h.ItemId]
                            return <InnerTile onClick={() => SetItemView(h.ItemId)} Small={true} key={h.Id}>
                                {/* <TextStyle size={1} weight='bold'>{props.Data.Unit.GetItemId(item)}</TextStyle> */}
                                <TextStyle size={2} weight='bold'>{props.Data.Unit.GetItemDisplay(item)}</TextStyle>
                                {/* {h.Introduced && <TextStyle>Success%: {h.Record.length > 0 ? ((h.Record.filter(r => r.Record == AnswerState.Success).length / h.Record.length) * 100).toFixed(2) : 0}%</TextStyle>} */}


                            </InnerTile>
                        })
                    }
                </Paginator>
            </>}
            {displayMode == DisplayMode.Item && <>
                <ContentTile>
                    <TextStyle size={4}>{props.Data.Unit.GetItemDisplay(displayItem)}</TextStyle>
                    <TextStyle>Introduced: {displayHistory?.Introduced ? "true" : "false"}</TextStyle>
                    <TextStyle>Last Seen: {displayHistory?.LastSeen?.toString()}</TextStyle>
                    <TextStyle>Score: {displayHistory?.Score}</TextStyle>
                    <hr />
                    <StyledButton onClick={() => { setDisplayItem(undefined); setDisplayHistory(undefined); setDisplayMode(DisplayMode.All) }}>Close</StyledButton>
                </ContentTile>
            </>}
        </>
    )
}