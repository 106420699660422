import { RoundControllerParams, RoundItemData, RoundRecord } from "../Views/RoundView";
import { db } from "./db";

export interface RoundHistoryItem {
    Id?: number,
    UnitId: number,
    Date: Date,
    Items: RoundItemData[],
    Params : RoundControllerParams
}
export class RoundHistoryState {

    async AppendHistory(roundRecord: RoundRecord) {

        roundRecord.Params.OrderRound = undefined

        return (await db.RoundHistory.add(roundRecord).then((Id : number) => { return Id }))
    }

    async Get(UnitId : number) {
        return await (db.RoundHistory.where('UnitId').equals(UnitId)).toArray()
    }

}
