import React, { useRef, useState } from 'react';
import './App.css';
import styled, { css } from 'styled-components';
import { ContentTile, ModeTile, StyledButton } from './Components/ModeTile';
import { UnitDefinitionMap, UnitType } from './Config/UnitDefinitionMap';
import { UnitLoader } from './Loaders/UnitLoader';
import { HistoryState } from './Database/HistoryState';
import { NotificationIcon } from './Icons/NotificationIcon';
import { CloseIcon } from './Icons/CloseIcon';
import { EventDefn, MessageRecordState } from './Database/MessageRecord';
import { MessagesLoader } from './Loaders/MessagesLoader';
import { ControllerModeContainer } from './Styles/Styles';
import { GlobalSettingsView } from './Views/GlobalSettingsView';
import { GlobalDebugView } from './Views/GlobalDebugView';

interface TextProps {
  size?: number,
  weight?: 'bold' | 'normal'
  colour?: string,
  case?: 'uppercase' | 'normal'
}
export const TextStyle = styled.span<TextProps>`
font-size: ${props => props.size ? props.size : 1}rem;
font-weight: ${props => props.weight ? props.weight : 'normal'};
display: block;
color: ${props => props.colour ? props.colour : 'black'};

font-family: Noto Serif SC, serif;
font-variant: ${props => props.case == 'uppercase' ? "small-caps" : "normal"};
`

enum AppMode {
  Home,
  Unit
}

const AppStyle = styled.div`
  background-color: white;
  display: flex;

  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
`
const NavBar = styled.div`
  width: 100vw;
  background-color: white;
  height: 100px;
  align-self: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 3px 3px 6px #eadfd5;
  position: fixed;
  bottom: -66px;
  bottom: 0px;
  /*https://www.stevefenton.co.uk/blog/2022/12/mobile-position-sticky-issue/  */
  z-index: 10;

  box-shadow: 3px -3px 6px #eadfd5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

`

const MainColumn = styled.div<{ Blur?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  z-index: 5;

  ${props => props.Blur ? css`

  @keyframes image_blur {
    0% { filter: blur(0px);}
    100% { filter: blur(5px);}

}
animation: image_blur 0.01s;

filter: blur(5px);

  ` : ""}


`

const TileContainer = styled.div`
display: flex;
flex-direction: row;
gap: 12px;
padding-left: 24px;
padding-right: 24px;
padding-bottom: 24px;
align-items: center;
justify-content: center;
flex-wrap: wrap;

@media (width <  400px) {
  padding-left: 6px;
  padding-right: 6px;
  width: calc(100vw - 12px);

}

@media (width > 400px) {
  padding-left: 24px;
  padding-right: 24px;
  width: calc(100vw - 48px);

}
`

enum AppState {
  Home,
  DisplayUnit,
  GlobalSettings,
  DevDebug,
}


export interface MenuConfig {
  Label: string,
  OnClick: () => any
}

const NotificationPaneBackground = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: #0000001a;
  position: fixed;
  top: 0;
  z-index: 5;

`



export interface AppCallbacks {
  OnEnter: () => any
  OnExit: () => any
  SetPreamble: (...a: any) => any
  SetMenu: (...a: any) => any
  SendEvent: (event: EventDefn) => any
}

function App() {

  const [appState, setAppState] = useState(AppState.Home)

  const [unitControllerId, setUnitControllerId] = useState(-1)

  const [preambleText, setPreambleText] = useState<string | undefined>("Available units")

  const OptionsMenuConfig: MenuConfig[] = [
    {
      Label: "Exit",
      OnClick: () => { 
        setAppState(AppState.Home)
        setMenuConfig(HomeMenuConfig)

      }
    }
  ]

  const HomeMenuConfig: MenuConfig[] = [
    {
      Label: "DevDebug",
      OnClick: () => { 
        setMenuConfig(OptionsMenuConfig)
        setAppState(AppState.DevDebug) 
      }
    },
    {
      Label: "Options",
      OnClick: () => { 
        setMenuConfig(OptionsMenuConfig)
        setAppState(AppState.GlobalSettings) 
      }
    }
  ]

  const ReceiveEvent = (event: EventDefn) => {
    console.log(event);

    (new MessageRecordState()).AddEvent(event)
  }


  const [menuConfig, setMenuConfig] = useState(HomeMenuConfig)

  const [showNotificationPane, setShowNotificationPane] = useState(false)

  const [showAlert, setShowAlert] = useState(false)

  return (
    <>
      <AppStyle >
        <NavBar>
          {/* <div style={{ paddingLeft: 24, marginLeft: 0 }}>
          <TextStyle size={1} weight='bold' case='uppercase'>No name</TextStyle>
        </div> */}
          <div style={{ marginLeft: 0, marginRight: 'auto', paddingLeft: 24 }}>
            <div onClick={() => setShowNotificationPane(!showNotificationPane)}>
              {showNotificationPane && <>
                <CloseIcon />
              </>}
              {!showNotificationPane && <>
                <NotificationIcon />
              </>}
            </div>
          </div>

          <div style={{ paddingLeft: 24, marginRight: 24, marginLeft: 'auto', gap: 12, display: 'flex' }}>
            {menuConfig.map((mc, i) => {
              return <StyledButton key={i} onClick={mc.OnClick}><TextStyle size={1} case='uppercase'>{mc.Label}</TextStyle></StyledButton>
            })}
          </div>

        </NavBar>
        <MainColumn Blur={showNotificationPane}>

          <div style={{ display: 'flex', flexDirection: 'row', height: 52, padding: 24, paddingBottom: 24 }}>
            {preambleText != undefined && <TextStyle size={2} weight='bold'>{preambleText}</TextStyle>}
          </div>
          <TileContainer>

            {(appState == AppState.Home || appState == AppState.DisplayUnit) && Object.entries(UnitDefinitionMap).map((e, i) => {
              if (appState == AppState.Home) {
                return <UnitLoader
                  key={e[1].UnitId}
                  SetPreamble={setPreambleText}
                  SetMenu={setMenuConfig}
                  Data={UnitDefinitionMap[parseInt(e[0])]}
                  OnEnter={() => { setUnitControllerId(e[1].UnitId); setAppState(AppState.DisplayUnit); }}
                  OnExit={() => { setAppState(AppState.Home); setUnitControllerId(-1); setMenuConfig(HomeMenuConfig); setPreambleText("Available units") }}
                  SendEvent={ReceiveEvent}
                />
              } else if (appState == AppState.DisplayUnit) {
                if (unitControllerId == e[1].UnitId)
                  return <UnitLoader
                    key={e[1].UnitId}
                    SetPreamble={setPreambleText}
                    SetMenu={setMenuConfig}
                    Data={UnitDefinitionMap[parseInt(e[0])]}
                    OnEnter={() => { setUnitControllerId(e[1].UnitId); setAppState(AppState.DisplayUnit); }}
                    OnExit={() => { setAppState(AppState.Home); setUnitControllerId(-1); setMenuConfig(HomeMenuConfig); setPreambleText("Available units") }}
                    SendEvent={ReceiveEvent}

                  />
              }
            })}
            {appState == AppState.DevDebug && <>
              <GlobalDebugView />
            </>}
            {appState == AppState.GlobalSettings && <>
              <GlobalSettingsView />
            </>}
          </TileContainer>
        </MainColumn>
      </AppStyle >
      {showNotificationPane && <>
        <NotificationPaneBackground onClick={() => setShowNotificationPane(false)}>
          <MessagesLoader />
        </NotificationPaneBackground>
      </>}
    </>
  );
}

export default App;
