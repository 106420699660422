import { AnswerRecord } from "../Views/RoundView";
import { UnitDefinitionMap } from "../Config/UnitDefinitionMap";
import { db } from "./db";

export enum AnswerState {
    Success,
    Failure,
    Override
}

export interface HistoryElement {
    Id?: number

    TestDefinitionId: number
    UnitId: number
    ItemId: number

    Introduced: boolean
    Unlocked: boolean

    LastSeen: Date | undefined
    Score: number
    Record: AnswerRecord[]
}



export class HistoryState {


    async IsUnitCompleted(UnitId: number) {

        var UnitState = await (db.UnitState.where('UnitId').equals(UnitId)).first()
        return UnitState?.Completed
    }

    async IsParentUnitCompleted(UnitId: number) {

    }

    async SetUnitCompleted(UnitId: number) : Promise<boolean> {
        var unit = UnitDefinitionMap[UnitId];

        var unitHistory = await (this.GetByTestDefinitionId(unit.UnitId, unit.ParentTestDefinitionId))
        var unitItemsCompleted = unitHistory.every(h => h.Introduced == true)

        var UnitState = await (db.UnitState.where('UnitId').equals(UnitId)).first()

        if (UnitState?.Completed == false && unitItemsCompleted) {

            db.UnitState.update(UnitId, { "Completed": true })

            return true
        }

        return false
    }

    async UnlockUnit(UnitId: number) : Promise<boolean> {
        var unit = UnitDefinitionMap[UnitId];

        var UnitState = await (db.UnitState.where('UnitId').equals(UnitId)).first()

        if ((UnitState?.Locked == true) && await unit.UnlockUnitOn()) {

            await db.UnitState.update(UnitId, { "Locked": false })
            
            return true
        }

        return false
    }

    async GetByUnitId(UnitId: number) {
        var history = await (db.History.where('UnitId').equals(UnitId)).toArray()
        return history
    }

    async GetByTestDefinitionId(UnitId: number, TestDefinitionId: number) {
        var history = await (db.History.where('UnitId').equals(UnitId).and(h => h.TestDefinitionId == TestDefinitionId).toArray())
        return history

    }

    async SetTestDefinition(history: HistoryElement[], UnitId: number, TestDefinitionId: number) {
        history.map(async h => {
            await db.History.update(h.Id, h)
        })
    }

    async GetByItemId(UnitId: number, TestDefinitionId: number, ItemId: number) {
        var history = await (db.History.where('UnitId').equals(UnitId)
        .and(h => h.TestDefinitionId == TestDefinitionId)
        .and(h => h.ItemId == ItemId)
        .toArray())
        return history
    }

}