import styled from "styled-components"

const AnimationStyle = styled.div`
#
`

export const NotificationIcon: React.FC = props => {
    return <>
        <svg fill="#000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 490 490" width={52}  height={52}>
            <g>
                <g id="XMLID_223_">
                    <rect id="XMLID_895_" x="237" y="435" width="15" height="55" />
                </g>
                <g>
                    <g id="XMLID_224_">
                        <rect id="XMLID_896_" x="49" y="406" width="392" height="14" />
                    </g>
                    <g id="XMLID_222_">
                        <path id="XMLID_894_" d="M411.013,386.72h-3.841c-32.738,0-59.371-26.633-59.371-59.371V148.977
				c0-56.68-46.114-102.794-102.794-102.794S142.212,92.296,142.212,148.977v178.022c0,32.932-26.79,59.722-59.722,59.722h-3.472
				v-15.304h3.472c24.495,0,44.418-19.922,44.418-44.418V148.977c0-65.117,52.981-118.098,118.098-118.098
				s118.098,52.981,118.098,118.098V327.35c0,24.301,19.765,44.066,44.066,44.066h3.841V386.72z"/>
                    </g>
                    <g id="XMLID_221_">
                        <rect id="XMLID_893_" x="165" y="191" width="15" height="146" />
                    </g>
                    <g id="XMLID_219_">
                        <rect id="XMLID_150_" x="165" y="145" width="15" height="23" />
                    </g>
                </g>
                <g id="XMLID_218_">
                    <rect id="XMLID_149_" x="230" width="30" height="15" />
                </g>
            </g>
        </svg>
    </>
}