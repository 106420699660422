import { Button } from "antd"
import styled from "styled-components"
import { TextStyle } from "../App"
import { UnitDefinition, UnitType } from "../Config/UnitDefinitionMap"
import { Data } from "../Types/Interfaces"

interface OnStartUnit {
    OnStart: (unitType: UnitType) => any
}

export const ContentTile = styled.div`
background-color: #ffffff40;
box-shadow: 3px 3px 9px #eadfd5;
display: flex;
flex-direction: column;

align-items: center;

flex-wrap: wrap;
flex-basis: 100%;
padding: 24px;

width: calc(100vw - 72px);;
/*min-height: calc(100vh - 230px);

@media (width < 400px) {
    min-height: calc(100vh - 230px);

}
@media (width > 400px) {
    min-height: calc(100vh - 248px);

}
*/
`

export const StyledButton = styled(Button)`
    height :64px;

    background-color: #f5efea00 ;
    outline: none;
    border: none;
    box-shadow: 3px 3px 12px #eadfd5;

    &:hover {
        background-color: #f5efea !important;

        transition: 0.5s;
    }
`

export const ModeTile: React.FC<Data<UnitDefinition<any>> & OnStartUnit> = props => {
    return (
        <ContentTile>

            <TextStyle size={8} weight='bold'>{props.Data.DisplayLabel}</TextStyle>
            <TextStyle size={3} weight='bold' case='uppercase'>{props.Data.NameLabel}</TextStyle>
            <TextStyle size={2}></TextStyle>
            <div style={{ padding: 12 }}></div>
            <StyledButton onClick={() => props.OnStart(props.Data.UnitType)}><TextStyle case='uppercase' size={2} weight='bold'>Study</TextStyle></StyledButton>
        </ContentTile>
    )
}