import { RoundControllerParams } from "../Views/RoundView";
import { db } from "./db";

export interface SettingsStorageItem {
    Id?: number,
    UnitId: number,
    Params: RoundControllerParams
}

export const DefaultUnitControllerParams: RoundControllerParams = {
    IntroduceNew: true,
    IntroduceCount: 10,
    RoundSize: 10,

    TimeLimit: 0,
    PromoteOnNth: 1,
    FailOnNth: 1,

    ShowUntilCorrect: true,
    ShuffleOnIncorrect: true,

    SaveResults: true,

    OrderRound: (Item: any[]) => Item
}

export class SettingsState {
    async Set(UnitId: number, Options: RoundControllerParams) {
        var settingsStorage = await (db.Settings.where('UnitId').equals(UnitId).first());

        if (settingsStorage == undefined) {
            await db.Settings.add({
                UnitId: UnitId,
                Params: Options
            })
        } else {
            await (db.Settings.update(settingsStorage?.Id, { Params: Options }))
        }

    }

    async Get(UnitId: number) {
        var settingsStorage = await (db.Settings.where('UnitId').equals(UnitId).first());

        if (settingsStorage == undefined) {

            var defaultSettings = {
                UnitId: UnitId,
                Params: DefaultUnitControllerParams
            }

            defaultSettings.Params.OrderRound = undefined


            await db.Settings.add(defaultSettings)

            return defaultSettings
        }

        return settingsStorage
    }
}