import Dexie from "dexie"
import { TextStyle } from "../App"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { db } from "../Database/db"
import { ControllerModeContainer } from "../Styles/Styles"

export const GlobalSettingsView: React.FC = props => {


    return <>
        <ContentTile>
        </ContentTile>
    </>
}