
export type CnWordUnitItem = { id: number, traditional: string, simplified: string, reading: string, meaning: string, grade: number }


export const cnwords : CnWordUnitItem[] = [
  {
    "id": 1,
    "traditional": "愛",
    "simplified": "爱",
    "reading": "ài",
    "meaning": "love, like, be fond of, be keen on, cherish, be apt to",
    "grade": 1
  },
  {
    "id": 2,
    "traditional": "愛好",
    "simplified": "爱好",
    "reading": "àihào",
    "meaning": "love, like, be fond of, be keen on",
    "grade": 1
  },
  {
    "id": 3,
    "traditional": "爸爸",
    "simplified": "爸爸",
    "reading": "bàba",
    "meaning": "old man, father, papa, pappa, daddy, pa, begetter, pop, dada, dad, male parent, pater, pappy, pops, poppa",
    "grade": 1
  },
  {
    "id": 4,
    "traditional": "白",
    "simplified": "白",
    "reading": "bái",
    "meaning": "white, clear, pure, plain, wrongly written/mispronounced,",
    "grade": 1
  },
  {
    "id": 5,
    "traditional": "八",
    "simplified": "八",
    "reading": "bā",
    "meaning": "det.: eight",
    "grade": 1
  },
  {
    "id": 6,
    "traditional": "白天",
    "simplified": "白天",
    "reading": "báitian",
    "meaning": "daytime, during the day, day, CL:個|个[ge4]",
    "grade": 1
  },
  {
    "id": 7,
    "traditional": "班",
    "simplified": "班",
    "reading": "bān",
    "meaning": "m.[event]",
    "grade": 1
  },
  {
    "id": 8,
    "traditional": "百",
    "simplified": "百",
    "reading": "bǎi",
    "meaning": "cent, centum, century, hundred, det.: hundred",
    "grade": 1
  },
  {
    "id": 9,
    "traditional": "吧",
    "simplified": "吧",
    "reading": "ba",
    "meaning": "particle: used to show mild imperative or uncertainty",
    "grade": 1
  },
  {
    "id": 10,
    "traditional": "半",
    "simplified": "半",
    "reading": "bàn",
    "meaning": "det.: half",
    "grade": 1
  },
  {
    "id": 11,
    "traditional": "半年",
    "simplified": "半年",
    "reading": "bànnián",
    "meaning": "half a year",
    "grade": 1
  },
  {
    "id": 12,
    "traditional": "半天",
    "simplified": "半天",
    "reading": "bàntiān",
    "meaning": "half of the day, a long time, quite a while, midair, CL:個|个[ge4]",
    "grade": 1
  },
  {
    "id": 13,
    "traditional": "幫",
    "simplified": "帮",
    "reading": "bāng",
    "meaning": "help",
    "grade": 1
  },
  {
    "id": 14,
    "traditional": "幫忙",
    "simplified": "帮忙",
    "reading": "bāng máng",
    "meaning": "assist, aid, smile, lend a hand, do a favour, help, oblige, give a hand, do favor, do a good turn",
    "grade": 1
  },
  {
    "id": 15,
    "traditional": "包",
    "simplified": "包",
    "reading": "bāo",
    "meaning": "wrap, surround, encircle, envelop, include, contain, undertake the whole thing, assure, guarantee",
    "grade": 1
  },
  {
    "id": 16,
    "traditional": "包子",
    "simplified": "包子",
    "reading": "bāozi",
    "meaning": "steamed stuffed bun, CL:個|个[ge4]",
    "grade": 1
  },
  {
    "id": 17,
    "traditional": "杯",
    "simplified": "杯",
    "reading": "bēi",
    "meaning": "m.[container]",
    "grade": 1
  },
  {
    "id": 18,
    "traditional": "杯子",
    "simplified": "杯子",
    "reading": "bēizi",
    "meaning": "tumbler, drinking glass, cup, glass",
    "grade": 1
  },
  {
    "id": 19,
    "traditional": "北邊",
    "simplified": "北边",
    "reading": "běibian",
    "meaning": "north, north side, northern part, to the north of",
    "grade": 1
  },
  {
    "id": 20,
    "traditional": "本",
    "simplified": "本",
    "reading": "běn",
    "meaning": "det.: this",
    "grade": 1
  },
  {
    "id": 21,
    "traditional": "北京",
    "simplified": "北京",
    "reading": "Běijīng",
    "meaning": "capital of Red China, Beijing, Peking, Beijing (Peking), Peiping",
    "grade": 1
  },
  {
    "id": 22,
    "traditional": "北",
    "simplified": "北",
    "reading": "běi",
    "meaning": "atomic number 97, northward, north, berkelium, Bk, northern",
    "grade": 1
  },
  {
    "id": 23,
    "traditional": "本子",
    "simplified": "本子",
    "reading": "běnzi",
    "meaning": "book, notebook, edition, CL:本[ben3]",
    "grade": 1
  },
  {
    "id": 24,
    "traditional": "別",
    "simplified": "别",
    "reading": "bié",
    "meaning": "don't",
    "grade": 1
  },
  {
    "id": 25,
    "traditional": "別的",
    "simplified": "别的",
    "reading": "biéde",
    "meaning": "else, other",
    "grade": 1
  },
  {
    "id": 26,
    "traditional": "別人",
    "simplified": "别人",
    "reading": "biéren",
    "meaning": "others, other people, another person",
    "grade": 1
  },
  {
    "id": 27,
    "traditional": "病",
    "simplified": "病",
    "reading": "bìng",
    "meaning": "disease",
    "grade": 1
  },
  {
    "id": 28,
    "traditional": "病人",
    "simplified": "病人",
    "reading": "bìngrén",
    "meaning": "shut-in, patient, subject, sufferer, case, valetudinarian, invalid",
    "grade": 1
  },
  {
    "id": 29,
    "traditional": "比",
    "simplified": "比",
    "reading": "bǐ",
    "meaning": "compared with",
    "grade": 1
  },
  {
    "id": 30,
    "traditional": "不大",
    "simplified": "不大",
    "reading": "bùdà",
    "meaning": "not very/too, not often",
    "grade": 1
  },
  {
    "id": 31,
    "traditional": "不客氣",
    "simplified": "不客气",
    "reading": "bù kèqì",
    "meaning": "you're welcome, don't mention it, impolite, rude, blunt",
    "grade": 1
  },
  {
    "id": 32,
    "traditional": "不用",
    "simplified": "不用",
    "reading": "bùyòng",
    "meaning": "need not",
    "grade": 1
  },
  {
    "id": 33,
    "traditional": "菜",
    "simplified": "菜",
    "reading": "cài",
    "meaning": "vegetable, greens, dish, course, food",
    "grade": 1
  },
  {
    "id": 34,
    "traditional": "不對",
    "simplified": "不对",
    "reading": "bù duì",
    "meaning": "incorrect, wrong, amiss, abnormal, queer",
    "grade": 1
  },
  {
    "id": 35,
    "traditional": "差",
    "simplified": "差",
    "reading": "chà",
    "meaning": "be short, lack",
    "grade": 1
  },
  {
    "id": 36,
    "traditional": "不",
    "simplified": "不",
    "reading": "bù",
    "meaning": "non, do not, no, nowise, not, nope, nae, no more, nix",
    "grade": 1
  },
  {
    "id": 37,
    "traditional": "茶",
    "simplified": "茶",
    "reading": "chá",
    "meaning": "tea",
    "grade": 1
  },
  {
    "id": 38,
    "traditional": "常常",
    "simplified": "常常",
    "reading": "chángcháng",
    "meaning": "oftentimes, ofttimes, commonly, ordinarily, oft, often, a great deal, normally, frequently, unremarkably, much, usually",
    "grade": 1
  },
  {
    "id": 39,
    "traditional": "常",
    "simplified": "常",
    "reading": "cháng",
    "meaning": "often, usually, frequently",
    "grade": 1
  },
  {
    "id": 40,
    "traditional": "車",
    "simplified": "车",
    "reading": "chē",
    "meaning": "vehicle",
    "grade": 1
  },
  {
    "id": 41,
    "traditional": "唱歌",
    "simplified": "唱歌",
    "reading": "chàng gē",
    "meaning": "sing",
    "grade": 1
  },
  {
    "id": 42,
    "traditional": "車上",
    "simplified": "车上",
    "reading": "chē shàng",
    "meaning": "Car",
    "grade": 1
  },
  {
    "id": 43,
    "traditional": "車票",
    "simplified": "车票",
    "reading": "chēpiào",
    "meaning": "ticket (for train/bus)",
    "grade": 1
  },
  {
    "id": 44,
    "traditional": "唱",
    "simplified": "唱",
    "reading": "chàng",
    "meaning": "cry, chant, chirm, tune, vocalize, call, sing",
    "grade": 1
  },
  {
    "id": 45,
    "traditional": "吃",
    "simplified": "吃",
    "reading": "chī",
    "meaning": "feed, have, be subject to, absorb, chow, live on, eat, have one's meals, be a strain, exhaust, fare, take in, live off, annihilate, wipe out, partake, suffer, ingest, consume, graze, lunch, manducate, incur, take, get, use up, make, taste, soak up",
    "grade": 1
  },
  {
    "id": 46,
    "traditional": "車站",
    "simplified": "车站",
    "reading": "chēzhàn",
    "meaning": "station",
    "grade": 1
  },
  {
    "id": 47,
    "traditional": "吃飯",
    "simplified": "吃饭",
    "reading": "chī fàn",
    "meaning": "feed, dine, make a living, keep alive, have a meal, eat",
    "grade": 1
  },
  {
    "id": 48,
    "traditional": "出來",
    "simplified": "出来",
    "reading": "chū lai",
    "meaning": "come forward, issue, come up, step up, step to the fore, out (toward one), come_out, pop out, come out, rise, come to the fore, step forward, emergence, come forth, emerge",
    "grade": 1
  },
  {
    "id": 49,
    "traditional": "齣",
    "simplified": "出",
    "reading": "chū",
    "meaning": "produce, turn out, arise, happen",
    "grade": 1
  },
  {
    "id": 50,
    "traditional": "出去",
    "simplified": "出去",
    "reading": "chū qu",
    "meaning": "go_out, go/get out, get_out, go out, get out, go, exit",
    "grade": 1
  },
  {
    "id": 51,
    "traditional": "床",
    "simplified": "床",
    "reading": "chuáng",
    "meaning": "bed",
    "grade": 1
  },
  {
    "id": 52,
    "traditional": "次",
    "simplified": "次",
    "reading": "cì",
    "meaning": "order, sequence, next",
    "grade": 1
  },
  {
    "id": 53,
    "traditional": "穿",
    "simplified": "穿",
    "reading": "chuān",
    "meaning": "to wear, to put on, to dress, to bore through, to pierce, to perforate, to penetrate, to pass through, to thread",
    "grade": 1
  },
  {
    "id": 54,
    "traditional": "錯",
    "simplified": "错",
    "reading": "cuò",
    "meaning": "wrong, mistaken, bad, poor, confused, complex",
    "grade": 1
  },
  {
    "id": 55,
    "traditional": "從",
    "simplified": "从",
    "reading": "cóng",
    "meaning": "from, since, through",
    "grade": 1
  },
  {
    "id": 56,
    "traditional": "打",
    "simplified": "打",
    "reading": "dǎ",
    "meaning": "generalized verb of doing with specific meaning determined by its object, strike, hit, fight, construct, forge, mix",
    "grade": 1
  },
  {
    "id": 57,
    "traditional": "打車",
    "simplified": "打车",
    "reading": "dǎchē",
    "meaning": "to take a taxi (in town), to hitch a lift",
    "grade": 1
  },
  {
    "id": 58,
    "traditional": "打電話",
    "simplified": "打电话",
    "reading": "dǎ diànhuà",
    "meaning": "make telephone call",
    "grade": 1
  },
  {
    "id": 59,
    "traditional": "打開",
    "simplified": "打开",
    "reading": "dǎ kāi",
    "meaning": "unscrew, undo, break_out, open up, unbar, unpack, unwind, shoot, turn, switch_on, open, switch on, unroll, turn/switch on, unclose, straighten, unfold, crack, unwrap, ope, turn_on, turn on, chop",
    "grade": 1
  },
  {
    "id": 60,
    "traditional": "打球",
    "simplified": "打球",
    "reading": "dǎqiú",
    "meaning": "play a ball game using arms/hands",
    "grade": 1
  },
  {
    "id": 61,
    "traditional": "大學",
    "simplified": "大学",
    "reading": "dàxué",
    "meaning": "university, college",
    "grade": 1
  },
  {
    "id": 62,
    "traditional": "大",
    "simplified": "大",
    "reading": "dà",
    "meaning": "great, macro, old, very, vast, tall, big, high, much",
    "grade": 1
  },
  {
    "id": 63,
    "traditional": "大學生",
    "simplified": "大学生",
    "reading": "dàxuéshēng",
    "meaning": "man, university/college student, college man, university student, colleger, university, undergrad, undergraduate, college boy, college student, collegian, university_student, undergraduate student, geeks",
    "grade": 1
  },
  {
    "id": 64,
    "traditional": "得到",
    "simplified": "得到",
    "reading": "dé dào",
    "meaning": "to get, to obtain, to receive",
    "grade": 1
  },
  {
    "id": 65,
    "traditional": "到",
    "simplified": "到",
    "reading": "dào",
    "meaning": "up until",
    "grade": 1
  },
  {
    "id": 66,
    "traditional": "等",
    "simplified": "等",
    "reading": "děng",
    "meaning": "conj.: and so on, etc",
    "grade": 1
  },
  {
    "id": 67,
    "traditional": "地點",
    "simplified": "地点",
    "reading": "dìdiǎn",
    "meaning": "location, site, locale, locality, topographic point, point, venue, spot, situs, land site, place, locus",
    "grade": 1
  },
  {
    "id": 68,
    "traditional": "地",
    "simplified": "地",
    "reading": "de",
    "meaning": "the earth, land, soil, fields, ground, place, position, background, distance",
    "grade": 1
  },
  {
    "id": 69,
    "traditional": "地方",
    "simplified": "地方",
    "reading": "dìfang",
    "meaning": "country, soil, terrain, part, clime, way, territory, room, neighbourhood, tinderbox, locality (as distinct from the central administrat, child, tract, province, position, respect, where, region, space, place, local",
    "grade": 1
  },
  {
    "id": 70,
    "traditional": "地上",
    "simplified": "地上",
    "reading": "dìshang",
    "meaning": "on the ground, on the floor",
    "grade": 1
  },
  {
    "id": 71,
    "traditional": "的",
    "simplified": "的",
    "reading": "de",
    "meaning": "particle: emphasizing an action/situation",
    "grade": 1
  },
  {
    "id": 72,
    "traditional": "地圖",
    "simplified": "地图",
    "reading": "dìtú",
    "meaning": "map",
    "grade": 1
  },
  {
    "id": 73,
    "traditional": "弟弟",
    "simplified": "弟弟",
    "reading": "dìdi",
    "meaning": "brother, little brother, younger brother, cadet, little_brother, viscount",
    "grade": 1
  },
  {
    "id": 74,
    "traditional": "第",
    "simplified": "第",
    "reading": "dì",
    "meaning": "mansion, stadium, decker, det.: marker of ordinal numerals, abomasum, residence",
    "grade": 1
  },
  {
    "id": 75,
    "traditional": "點",
    "simplified": "点",
    "reading": "diǎn",
    "meaning": "m.[proximation]",
    "grade": 1
  },
  {
    "id": 76,
    "traditional": "電話",
    "simplified": "电话",
    "reading": "diànhuà",
    "meaning": "blower, phone call, headphone, earpiece, telephone, telephone_call, buzz, earphone, horn, telephone set, phone_call, telephony, phone",
    "grade": 1
  },
  {
    "id": 77,
    "traditional": "電",
    "simplified": "电",
    "reading": "diàn",
    "meaning": "electricity",
    "grade": 1
  },
  {
    "id": 78,
    "traditional": "電腦",
    "simplified": "电脑",
    "reading": "diànnǎo",
    "meaning": "electronic computer, computery, data processor, information processing system, computer",
    "grade": 1
  },
  {
    "id": 79,
    "traditional": "電視機",
    "simplified": "电视机",
    "reading": "diànshìjī",
    "meaning": "set, idiot_box, tv, goggle-box, idiot box, television receiver/set, teleset, television set, television receiver, TV set, goggle_box, tv set, goggle box, boob tube, receiver, telly, television",
    "grade": 1
  },
  {
    "id": 80,
    "traditional": "電影",
    "simplified": "电影",
    "reading": "diànyǐng",
    "meaning": "cinematography, moving_picture, pix, kinema, picture, film, movie, moving picture, flick, cinema, motion_picture, cinematic, celluloid, picture show, motion picture, flicker",
    "grade": 1
  },
  {
    "id": 81,
    "traditional": "電視",
    "simplified": "电视",
    "reading": "diànshì",
    "meaning": "TV, box, iconoscope, video, television set, teevee, orthicon, huckster, TV set, radiovision, television, telecasting, airwave, telly",
    "grade": 1
  },
  {
    "id": 82,
    "traditional": "電影院",
    "simplified": "电影院",
    "reading": "diànyǐng yuàn",
    "meaning": "cinema, movie (house)",
    "grade": 1
  },
  {
    "id": 83,
    "traditional": "東邊",
    "simplified": "东边",
    "reading": "dōngbiān",
    "meaning": "east, east side, eastern part, to the east of",
    "grade": 1
  },
  {
    "id": 84,
    "traditional": "東",
    "simplified": "东",
    "reading": "dōng",
    "meaning": "east",
    "grade": 1
  },
  {
    "id": 85,
    "traditional": "東西",
    "simplified": "东西",
    "reading": "dōngxi",
    "meaning": "thing, creature",
    "grade": 1
  },
  {
    "id": 86,
    "traditional": "動",
    "simplified": "动",
    "reading": "dòng",
    "meaning": "concuss, get moving, travel, go, use, eat or drink, act, arouse, stir, disturb, alter, touch, change, dynamic, locomote, move",
    "grade": 1
  },
  {
    "id": 87,
    "traditional": "動作",
    "simplified": "动作",
    "reading": "dòngzuò",
    "meaning": "demeanor, motion, behavior, performance, demeanour, play, turn, operation, operative, looseness, agency, gesticulation, movement, gesture, comportment, business, action, move",
    "grade": 1
  },
  {
    "id": 88,
    "traditional": "讀",
    "simplified": "读",
    "reading": "dú",
    "meaning": "study, read, attend school, take, read aloud, read (aloud), peruse",
    "grade": 1
  },
  {
    "id": 89,
    "traditional": "都",
    "simplified": "都",
    "reading": "dōu",
    "meaning": "all, even, already",
    "grade": 1
  },
  {
    "id": 90,
    "traditional": "讀書",
    "simplified": "读书",
    "reading": "dú shū",
    "meaning": "read, study, attend school",
    "grade": 1
  },
  {
    "id": 91,
    "traditional": "對不起",
    "simplified": "对不起",
    "reading": "duìbuqǐ",
    "meaning": "be unfair to, I'm sorry, let ... down, let sb. down, excuse me",
    "grade": 1
  },
  {
    "id": 92,
    "traditional": "多",
    "simplified": "多",
    "reading": "duō",
    "meaning": "many, much, more, more than the correct/required number, excessive, too much, much/far more",
    "grade": 1
  },
  {
    "id": 93,
    "traditional": "多少",
    "simplified": "多少",
    "reading": "duōshao",
    "meaning": "det.: how many, how much, many",
    "grade": 1
  },
  {
    "id": 94,
    "traditional": "兒子",
    "simplified": "儿子",
    "reading": "érzi",
    "meaning": "viscount, man-child, boy, male offspring, son",
    "grade": 1
  },
  {
    "id": 95,
    "traditional": "餓",
    "simplified": "饿",
    "reading": "è",
    "meaning": "starve",
    "grade": 1
  },
  {
    "id": 96,
    "traditional": "對",
    "simplified": "对",
    "reading": "duì",
    "meaning": "correct, true, toward",
    "grade": 1
  },
  {
    "id": 97,
    "traditional": "二",
    "simplified": "二",
    "reading": "èr",
    "meaning": "det.: two, second",
    "grade": 1
  },
  {
    "id": 98,
    "traditional": "飯",
    "simplified": "饭",
    "reading": "fàn",
    "meaning": "food, meal, repast, cooked rice or other cereals, dinner",
    "grade": 1
  },
  {
    "id": 99,
    "traditional": "飯店",
    "simplified": "饭店",
    "reading": "fàndiàn",
    "meaning": "restraurant, restaurant, hotel",
    "grade": 1
  },
  {
    "id": 100,
    "traditional": "房間",
    "simplified": "房间",
    "reading": "fángjiān",
    "meaning": "apartment, aspect, houseroom, room, chamber, bedroom",
    "grade": 1
  },
  {
    "id": 101,
    "traditional": "房子",
    "simplified": "房子",
    "reading": "fángzi",
    "meaning": "building (of less than three stories), room, building, property, house",
    "grade": 1
  },
  {
    "id": 102,
    "traditional": "放",
    "simplified": "放",
    "reading": "fàng",
    "meaning": "loose, bloom, make longer, set ... free, let sb. have his own way, show, readjust, free, make larger, put in, fly, etc., release, discharge, leave alone, bung, lay aside, put, expansion, make longer/larger/etc., fling, localize, lend (money) at interest, let go, set, pasture, pose, set free, blossom, lay, let off, put out to pasture, send away, play, expand, make larger/etc., readjus",
    "grade": 1
  },
  {
    "id": 103,
    "traditional": "放假",
    "simplified": "放假",
    "reading": "fàng jià",
    "meaning": "have a day off, have a vacation, have day off, have or be on vacation, have or be on holiday, have a holiday, vacation",
    "grade": 1
  },
  {
    "id": 104,
    "traditional": "飛",
    "simplified": "飞",
    "reading": "fēi",
    "meaning": "hover in the air, flight, hover, volatilize, wing, disappear through volatilization, flutter, skip, hover/flutter in the air, fly, blow, flutter in the air, flit",
    "grade": 1
  },
  {
    "id": 105,
    "traditional": "放學",
    "simplified": "放学",
    "reading": "fàng xué",
    "meaning": "have winter holidays, classes are over, finish classes, have summer holidays, finish classes (for the day)",
    "grade": 1
  },
  {
    "id": 106,
    "traditional": "飛機",
    "simplified": "飞机",
    "reading": "fēijī",
    "meaning": "aerocraft, airplane, machine, craft, aeroplane, ship, aero, avion, aeronef, plane, aircraft",
    "grade": 1
  },
  {
    "id": 107,
    "traditional": "非常",
    "simplified": "非常",
    "reading": "fēicháng",
    "meaning": "extraordinary, unusual, special, very, extremely, highly",
    "grade": 1
  },
  {
    "id": 108,
    "traditional": "分",
    "simplified": "分",
    "reading": "fēn",
    "meaning": "m.[general]",
    "grade": 1
  },
  {
    "id": 109,
    "traditional": "風",
    "simplified": "风",
    "reading": "fēng",
    "meaning": "wind, common practice, custom, general mood, style, news, information",
    "grade": 1
  },
  {
    "id": 110,
    "traditional": "幹",
    "simplified": "干",
    "reading": "gàn",
    "meaning": "do, work, fight",
    "grade": 1
  },
  {
    "id": 111,
    "traditional": "乾淨",
    "simplified": "干净",
    "reading": "gānjìng",
    "meaning": "clean, clear",
    "grade": 1
  },
  {
    "id": 112,
    "traditional": "高興",
    "simplified": "高兴",
    "reading": "gāoxìng",
    "meaning": "glad, happy, cheerful, merry, pleased",
    "grade": 1
  },
  {
    "id": 113,
    "traditional": "高",
    "simplified": "高",
    "reading": "gāo",
    "meaning": "tall, high",
    "grade": 1
  },
  {
    "id": 114,
    "traditional": "告訴",
    "simplified": "告诉",
    "reading": "gàosu",
    "meaning": "tell, preach, notify, impart, inform, represent, apprize, send word, share, advise, give notice, apprise, assure, let know",
    "grade": 1
  },
  {
    "id": 115,
    "traditional": "哥哥",
    "simplified": "哥哥",
    "reading": "gēge",
    "meaning": "big brother, elder brother, big_brother",
    "grade": 1
  },
  {
    "id": 116,
    "traditional": "歌",
    "simplified": "歌",
    "reading": "gē",
    "meaning": "song",
    "grade": 1
  },
  {
    "id": 117,
    "traditional": "幹什麼",
    "simplified": "干什么",
    "reading": "gànshénme",
    "meaning": "do something",
    "grade": 1
  },
  {
    "id": 118,
    "traditional": "個",
    "simplified": "个",
    "reading": "gè",
    "meaning": "piece, item",
    "grade": 1
  },
  {
    "id": 119,
    "traditional": "給",
    "simplified": "给",
    "reading": "gěi",
    "meaning": "give, give to",
    "grade": 1
  },
  {
    "id": 120,
    "traditional": "跟",
    "simplified": "跟",
    "reading": "gēn",
    "meaning": "with, to, from",
    "grade": 1
  },
  {
    "id": 121,
    "traditional": "工人",
    "simplified": "工人",
    "reading": "gōngren",
    "meaning": "navvy, hired man, operative, working person, laborer, shopworker, esne, working man, hired hand, worker, workpeople, workman, hand",
    "grade": 1
  },
  {
    "id": 122,
    "traditional": "工作",
    "simplified": "工作",
    "reading": "gōngzuò",
    "meaning": "work, job",
    "grade": 1
  },
  {
    "id": 123,
    "traditional": "關",
    "simplified": "关",
    "reading": "guān",
    "meaning": "shut, close, turn off, lock up, close down",
    "grade": 1
  },
  {
    "id": 124,
    "traditional": "關上",
    "simplified": "关上",
    "reading": "guānshàng",
    "meaning": "to close (a door), to turn off (light, electrical equipment etc)",
    "grade": 1
  },
  {
    "id": 125,
    "traditional": "國",
    "simplified": "国",
    "reading": "guó",
    "meaning": "surname Guo, country, nation, state, national, CL:個|个[ge4]",
    "grade": 1
  },
  {
    "id": 126,
    "traditional": "國家",
    "simplified": "国家",
    "reading": "guójiā",
    "meaning": "sirkar, state, country, patria, a people, nationality, nation, republic, soli, body politic, clime, national, civil order, polity, commonwealth, land, res publica",
    "grade": 1
  },
  {
    "id": 127,
    "traditional": "貴",
    "simplified": "贵",
    "reading": "guì",
    "meaning": "expensive, costly, highly valued",
    "grade": 1
  },
  {
    "id": 128,
    "traditional": "國外",
    "simplified": "国外",
    "reading": "guówài",
    "meaning": "overseas",
    "grade": 1
  },
  {
    "id": 129,
    "traditional": "還是",
    "simplified": "还是",
    "reading": "háishi",
    "meaning": "still, nevertheless",
    "grade": 1
  },
  {
    "id": 130,
    "traditional": "孩子",
    "simplified": "孩子",
    "reading": "háizi",
    "meaning": "kid, issue, chap, daughter, children, sonny, son or daughter, child, spawn, boy, kipper, son",
    "grade": 1
  },
  {
    "id": 131,
    "traditional": "還有",
    "simplified": "还有",
    "reading": "háiyǒu",
    "meaning": "conj.: furthermore, in addition",
    "grade": 1
  },
  {
    "id": 132,
    "traditional": "過",
    "simplified": "过",
    "reading": "guò",
    "meaning": "[aspect]",
    "grade": 1
  },
  {
    "id": 133,
    "traditional": "漢語",
    "simplified": "汉语",
    "reading": "Hànyǔ",
    "meaning": "Chinese language, CL:門|门[men2]",
    "grade": 1
  },
  {
    "id": 134,
    "traditional": "還",
    "simplified": "还",
    "reading": "hái",
    "meaning": "still, yet, also, too, as well, besides",
    "grade": 1
  },
  {
    "id": 135,
    "traditional": "漢字",
    "simplified": "汉字",
    "reading": "Hànzì",
    "meaning": "Chinese character, CL:個|个[ge4], Japanese: kanji, Korean: hanja, Vietnamese: hán tự",
    "grade": 1
  },
  {
    "id": 136,
    "traditional": "好",
    "simplified": "好",
    "reading": "hǎo",
    "meaning": "good",
    "grade": 1
  },
  {
    "id": 137,
    "traditional": "好吃",
    "simplified": "好吃",
    "reading": "hǎochī",
    "meaning": "delicious, good to eat, tasty, enjoy eating good food, gluttonous",
    "grade": 1
  },
  {
    "id": 138,
    "traditional": "好看",
    "simplified": "好看",
    "reading": "hǎokàn",
    "meaning": "embarrassed, interesting, nice, good-looking, delightful",
    "grade": 1
  },
  {
    "id": 139,
    "traditional": "好聽",
    "simplified": "好听",
    "reading": "hǎotīng",
    "meaning": "pleasant to hear",
    "grade": 1
  },
  {
    "id": 140,
    "traditional": "好玩兒",
    "simplified": "好玩儿",
    "reading": "hǎowánr",
    "meaning": "interesting, delightful, amusing",
    "grade": 1
  },
  {
    "id": 141,
    "traditional": "號",
    "simplified": "号",
    "reading": "hào",
    "meaning": "m.[general]",
    "grade": 1
  },
  {
    "id": 142,
    "traditional": "喝",
    "simplified": "喝",
    "reading": "hē",
    "meaning": "drink",
    "grade": 1
  },
  {
    "id": 143,
    "traditional": "和",
    "simplified": "和",
    "reading": "hé",
    "meaning": "and, with, gentle, peaceful",
    "grade": 1
  },
  {
    "id": 144,
    "traditional": "很",
    "simplified": "很",
    "reading": "hěn",
    "meaning": "pretty, thrice, monstrously, gey, quite, mighty, stinking, sopping, some, assai, full, most, clinking, much, thumping, awfully, fiendishly, thundering, very, real, really, bally, proper, grossly, powerful, so, rattling, well, considerably, greatly, terribly",
    "grade": 1
  },
  {
    "id": 145,
    "traditional": "後",
    "simplified": "后",
    "reading": "hòu",
    "meaning": "rear, back, behind",
    "grade": 1
  },
  {
    "id": 146,
    "traditional": "後邊",
    "simplified": "后边",
    "reading": "hòubian",
    "meaning": "back, rear, behind",
    "grade": 1
  },
  {
    "id": 147,
    "traditional": "話",
    "simplified": "话",
    "reading": "huà",
    "meaning": "words, thing, language, tongue, talk, speech, spoken_language, conversation, utterance, expression, story, tale, saying, natural language, sayings, word",
    "grade": 1
  },
  {
    "id": 148,
    "traditional": "後天",
    "simplified": "后天",
    "reading": "hòutiān",
    "meaning": "the day after tomorrow, post-natal, acquired (not innate), a posteriori",
    "grade": 1
  },
  {
    "id": 149,
    "traditional": "壞",
    "simplified": "坏",
    "reading": "huài",
    "meaning": "spoiled",
    "grade": 1
  },
  {
    "id": 150,
    "traditional": "回答",
    "simplified": "回答",
    "reading": "huídá",
    "meaning": "answer, reply",
    "grade": 1
  },
  {
    "id": 151,
    "traditional": "回",
    "simplified": "回",
    "reading": "huí",
    "meaning": "return, go back",
    "grade": 1
  },
  {
    "id": 152,
    "traditional": "花",
    "simplified": "花",
    "reading": "huā",
    "meaning": "spend, expend, cost",
    "grade": 1
  },
  {
    "id": 153,
    "traditional": "回到",
    "simplified": "回到",
    "reading": "huídào",
    "meaning": "remount, retour, recur, turn back, regress, regain, revert, go_back, return to, return, go back to",
    "grade": 1
  },
  {
    "id": 154,
    "traditional": "回家",
    "simplified": "回家",
    "reading": "huí jiā",
    "meaning": "return home",
    "grade": 1
  },
  {
    "id": 155,
    "traditional": "回去",
    "simplified": "回去",
    "reading": "huí  qu",
    "meaning": "go_home, go_back, retrocede, go back, be back, go, return",
    "grade": 1
  },
  {
    "id": 156,
    "traditional": "回來",
    "simplified": "回来",
    "reading": "huí lai",
    "meaning": "come back, get_back, recur, revisit, come, return, be back, come_back",
    "grade": 1
  },
  {
    "id": 157,
    "traditional": "會",
    "simplified": "会",
    "reading": "huì",
    "meaning": "to be able, can, will, to meet",
    "grade": 1
  },
  {
    "id": 158,
    "traditional": "火車",
    "simplified": "火车",
    "reading": "huǒchē",
    "meaning": "choo-choo, railroad train, car train, marshalling_yard, puff-puff, train",
    "grade": 1
  },
  {
    "id": 159,
    "traditional": "機場",
    "simplified": "机场",
    "reading": "jīchǎng",
    "meaning": "landing field, flying field, airfield, airport, airdrome, field, aerodrome",
    "grade": 1
  },
  {
    "id": 160,
    "traditional": "雞蛋",
    "simplified": "鸡蛋",
    "reading": "jīdàn",
    "meaning": "(chicken) egg, hen's egg, CL:個|个[ge4],打[da2]",
    "grade": 1
  },
  {
    "id": 161,
    "traditional": "機票",
    "simplified": "机票",
    "reading": "jīpiào",
    "meaning": "air ticket, passenger ticket, CL:張|张[zhang1]",
    "grade": 1
  },
  {
    "id": 162,
    "traditional": "幾",
    "simplified": "几",
    "reading": "jǐ",
    "meaning": "det.: how many, a few, several, some",
    "grade": 1
  },
  {
    "id": 163,
    "traditional": "記得",
    "simplified": "记得",
    "reading": "jì de",
    "meaning": "come to mind, think back, remember, recollect, recall, rememberance, mind, retain, place, spring to mind, bear in mind",
    "grade": 1
  },
  {
    "id": 164,
    "traditional": "記住",
    "simplified": "记住",
    "reading": "jì zhu",
    "meaning": "to remember, to bear in mind, to learn by heart",
    "grade": 1
  },
  {
    "id": 165,
    "traditional": "家",
    "simplified": "家",
    "reading": "jiā",
    "meaning": "family, household, home, house",
    "grade": 1
  },
  {
    "id": 166,
    "traditional": "記",
    "simplified": "记",
    "reading": "jì",
    "meaning": "write down, record",
    "grade": 1
  },
  {
    "id": 167,
    "traditional": "家裡",
    "simplified": "家里",
    "reading": "jiālǐ",
    "meaning": "home",
    "grade": 1
  },
  {
    "id": 168,
    "traditional": "見面",
    "simplified": "见面",
    "reading": "jiàn miàn",
    "meaning": "meet, see",
    "grade": 1
  },
  {
    "id": 169,
    "traditional": "教",
    "simplified": "教",
    "reading": "jiào",
    "meaning": "teach",
    "grade": 1
  },
  {
    "id": 170,
    "traditional": "家人",
    "simplified": "家人",
    "reading": "jiārén",
    "meaning": "relation, homefolk, inmate, kindred, household, relative, people, folks, kin, family members, family, kinsfolk, house",
    "grade": 1
  },
  {
    "id": 171,
    "traditional": "間",
    "simplified": "间",
    "reading": "jiān",
    "meaning": "locality, room",
    "grade": 1
  },
  {
    "id": 172,
    "traditional": "見",
    "simplified": "见",
    "reading": "jiàn",
    "meaning": "see, be exposed to, become visible, call on, refer to, meet with, appear to be, appear, vide, catch sight of, meet",
    "grade": 1
  },
  {
    "id": 173,
    "traditional": "叫",
    "simplified": "叫",
    "reading": "jiào",
    "meaning": "to be called",
    "grade": 1
  },
  {
    "id": 174,
    "traditional": "教學樓",
    "simplified": "教学楼",
    "reading": "jiàoxuélóu",
    "meaning": "teaching block, school building",
    "grade": 1
  },
  {
    "id": 175,
    "traditional": "介紹",
    "simplified": "介绍",
    "reading": "jièshào",
    "meaning": "introduce, present, recommend, suggest, let know, brief",
    "grade": 1
  },
  {
    "id": 176,
    "traditional": "今年",
    "simplified": "今年",
    "reading": "jīnnián",
    "meaning": "this year",
    "grade": 1
  },
  {
    "id": 177,
    "traditional": "姐姐",
    "simplified": "姐姐",
    "reading": "jiějie",
    "meaning": "sis, big_sister, elder sister, sister",
    "grade": 1
  },
  {
    "id": 178,
    "traditional": "今天",
    "simplified": "今天",
    "reading": "jīntiān",
    "meaning": "today, at the present, now",
    "grade": 1
  },
  {
    "id": 179,
    "traditional": "進",
    "simplified": "进",
    "reading": "jìn",
    "meaning": "enter, come/go into, advance",
    "grade": 1
  },
  {
    "id": 180,
    "traditional": "進來",
    "simplified": "进来",
    "reading": "jìn lai",
    "meaning": "come in, enter",
    "grade": 1
  },
  {
    "id": 181,
    "traditional": "進去",
    "simplified": "进去",
    "reading": "jìn qu",
    "meaning": "enter, entry, go_in, get in, go in",
    "grade": 1
  },
  {
    "id": 182,
    "traditional": "就",
    "simplified": "就",
    "reading": "jiù",
    "meaning": "then, at once, right away, an indicator strengthening speaker's evaluation",
    "grade": 1
  },
  {
    "id": 183,
    "traditional": "覺得",
    "simplified": "觉得",
    "reading": "juéde",
    "meaning": "imagine, guess, believe, reckon, suspect, suppose, think, listen, feel, consider, think_of, appear, conceive",
    "grade": 1
  },
  {
    "id": 184,
    "traditional": "九",
    "simplified": "九",
    "reading": "jiǔ",
    "meaning": "det.: nine",
    "grade": 1
  },
  {
    "id": 185,
    "traditional": "開",
    "simplified": "开",
    "reading": "kāi",
    "meaning": "open, open up, reclaim (land), open out, come loose, start, begin, operate, turn on (light), run (business), hold (meeting/etc.), divide into, reveal",
    "grade": 1
  },
  {
    "id": 186,
    "traditional": "開車",
    "simplified": "开车",
    "reading": "kāi chē",
    "meaning": "to drive a car",
    "grade": 1
  },
  {
    "id": 187,
    "traditional": "開會",
    "simplified": "开会",
    "reading": "kāi huì",
    "meaning": "hold/attend meeting",
    "grade": 1
  },
  {
    "id": 188,
    "traditional": "開玩笑",
    "simplified": "开玩笑",
    "reading": "kāi wánxiào",
    "meaning": "kid, spoof, jive, gag, make fun of, josh, daff, rot, quip, chaff, rib, banter, jolly, fun, sport, put_on, clown, fool, droll, jape, joke, skylark, jest, jocose, crack a joke",
    "grade": 1
  },
  {
    "id": 189,
    "traditional": "看",
    "simplified": "看",
    "reading": "kàn",
    "meaning": "see, look at, watch, read, look upon, regard, look after, call on, visit",
    "grade": 1
  },
  {
    "id": 190,
    "traditional": "看病",
    "simplified": "看病",
    "reading": "kàn bìng",
    "meaning": "to visit a doctor, to see a patient",
    "grade": 1
  },
  {
    "id": 191,
    "traditional": "看到",
    "simplified": "看到",
    "reading": "kàn dào",
    "meaning": "view, receive, ken, observe, lamp, catch, see, take in, watch, chatter away, lay_eyes_on, boast, brag, shoot the breeze, spy, catch sight of, catch sight of",
    "grade": 1
  },
  {
    "id": 192,
    "traditional": "考",
    "simplified": "考",
    "reading": "kǎo",
    "meaning": "study, inspect, give test, inspection, give, give/take test, test, investigate, verify, take test, give or take an examination, quiz, check",
    "grade": 1
  },
  {
    "id": 193,
    "traditional": "看見",
    "simplified": "看见",
    "reading": "kàn jian",
    "meaning": "seeing, behold, see, descry, show, perceive, lay eyes on, lamp, spy, show up, sight, catch sight of, catch sight of",
    "grade": 1
  },
  {
    "id": 194,
    "traditional": "考試",
    "simplified": "考试",
    "reading": "kǎoshì",
    "meaning": "examination, test",
    "grade": 1
  },
  {
    "id": 195,
    "traditional": "渴",
    "simplified": "渴",
    "reading": "kě",
    "meaning": "thirsty",
    "grade": 1
  },
  {
    "id": 196,
    "traditional": "課",
    "simplified": "课",
    "reading": "kè",
    "meaning": "subject, course, class",
    "grade": 1
  },
  {
    "id": 197,
    "traditional": "課本",
    "simplified": "课本",
    "reading": "kèběn",
    "meaning": "text edition, textbook, text, school text, schoolbook",
    "grade": 1
  },
  {
    "id": 198,
    "traditional": "課文",
    "simplified": "课文",
    "reading": "kèwén",
    "meaning": "text, CL:篇[pian1]",
    "grade": 1
  },
  {
    "id": 199,
    "traditional": "口",
    "simplified": "口",
    "reading": "kǒu",
    "meaning": "m.[general]",
    "grade": 1
  },
  {
    "id": 200,
    "traditional": "塊",
    "simplified": "块",
    "reading": "kuài",
    "meaning": "m.[general]",
    "grade": 1
  },
  {
    "id": 201,
    "traditional": "快",
    "simplified": "快",
    "reading": "kuài",
    "meaning": "fast, quick, quick-witted, ingenious, sharp (of knives), straightforward",
    "grade": 1
  },
  {
    "id": 202,
    "traditional": "來",
    "simplified": "来",
    "reading": "lái",
    "meaning": "to",
    "grade": 1
  },
  {
    "id": 203,
    "traditional": "來到",
    "simplified": "来到",
    "reading": "láidào",
    "meaning": "arrive, come",
    "grade": 1
  },
  {
    "id": 204,
    "traditional": "老",
    "simplified": "老",
    "reading": "lǎo",
    "meaning": "old, aged;of long standing, outdated, tough, overgrown, dark (of colors)",
    "grade": 1
  },
  {
    "id": 205,
    "traditional": "老人",
    "simplified": "老人",
    "reading": "lǎoren",
    "meaning": "oldster, ancient, old woman, crock, one's aged parents or grandparents, old man/woman, one's aged parents/grandparents, Sir, old person, gaffer, pop, one's aged parents, the aged/old, old_man, senior_citizen, oldie, greybeard, old man, graybeard, old, grandparents, the old, the aged, senior citizen, old_boy, old boy, woman, old-timer, old man or woman, one's aged grandparents",
    "grade": 1
  },
  {
    "id": 206,
    "traditional": "老師",
    "simplified": "老师",
    "reading": "lǎoshī",
    "meaning": "rabbi, professor, swami, instructor, teacher, mast",
    "grade": 1
  },
  {
    "id": 207,
    "traditional": "了",
    "simplified": "了",
    "reading": "le",
    "meaning": "entirely",
    "grade": 1
  },
  {
    "id": 208,
    "traditional": "累",
    "simplified": "累",
    "reading": "lèi",
    "meaning": "tire, build by piling up, pile up, strain, weary, toil, accumulate, involve, tired, burthen, work hard, accumulation, fatigued, wear out",
    "grade": 1
  },
  {
    "id": 209,
    "traditional": "冷",
    "simplified": "冷",
    "reading": "lěng",
    "meaning": "cool, rare, cold, unfrequented, feel cold, chill, frosty(in manner), shot from hiding, strange",
    "grade": 1
  },
  {
    "id": 210,
    "traditional": "裏",
    "simplified": "里",
    "reading": "lǐ",
    "meaning": "inside",
    "grade": 1
  },
  {
    "id": 211,
    "traditional": "裡邊",
    "simplified": "里边",
    "reading": "lǐbian",
    "meaning": "inside",
    "grade": 1
  },
  {
    "id": 212,
    "traditional": "兩",
    "simplified": "两",
    "reading": "liǎng",
    "meaning": "det.: two, both (sides), either (side), a few, some",
    "grade": 1
  },
  {
    "id": 213,
    "traditional": "零",
    "simplified": "零",
    "reading": "líng",
    "meaning": "det.: zero, naught, nil",
    "grade": 1
  },
  {
    "id": 214,
    "traditional": "六",
    "simplified": "六",
    "reading": "liù",
    "meaning": "det.: six",
    "grade": 1
  },
  {
    "id": 215,
    "traditional": "樓",
    "simplified": "楼",
    "reading": "lóu",
    "meaning": "storey, Lou, tower, superstructure, storied building, story, floor, Surname, a storied building",
    "grade": 1
  },
  {
    "id": 216,
    "traditional": "樓上",
    "simplified": "楼上",
    "reading": "lóushàng",
    "meaning": "upstairs",
    "grade": 1
  },
  {
    "id": 217,
    "traditional": "樓下",
    "simplified": "楼下",
    "reading": "lóuxià",
    "meaning": "downstairs",
    "grade": 1
  },
  {
    "id": 218,
    "traditional": "路口",
    "simplified": "路口",
    "reading": "lùkǒu",
    "meaning": "crossing, intersection (of roads)",
    "grade": 1
  },
  {
    "id": 219,
    "traditional": "路上",
    "simplified": "路上",
    "reading": "lùshang",
    "meaning": "on the road, on a journey, road surface",
    "grade": 1
  },
  {
    "id": 220,
    "traditional": "媽媽",
    "simplified": "妈妈",
    "reading": "māma",
    "meaning": "mummy, mammy, ma, mother, mamma, mum, mommy, female parent, mom, Mama, mater, mama, momma",
    "grade": 1
  },
  {
    "id": 221,
    "traditional": "馬路",
    "simplified": "马路",
    "reading": "mǎlù",
    "meaning": "drive, road, driveway, riding, a road, carriageway, avenue, street, a street used by vehicular traffic",
    "grade": 1
  },
  {
    "id": 222,
    "traditional": "路",
    "simplified": "路",
    "reading": "lù",
    "meaning": "road, path, way, journey, distance, means, sequence, logic, line, region, district, route, sort, grade, class",
    "grade": 1
  },
  {
    "id": 223,
    "traditional": "馬上",
    "simplified": "马上",
    "reading": "mǎshàng",
    "meaning": "in a short time, at_once, instantaneously, promptly, at once, incontinent, in the same breath, directively, instantly, slapbang, pronto, right_away, immediately, shortly, without delay, right away, tout-de-suite, forthwith, presently, in_no_time, at a time, soon, hereupon, in a few seconds, before long, at one time, outright, straightaway, in a minute, directly, momently, in a f",
    "grade": 1
  },
  {
    "id": 224,
    "traditional": "買",
    "simplified": "买",
    "reading": "mǎi",
    "meaning": "pick up, invest, hire, buy, purchase, put_down, trade, get",
    "grade": 1
  },
  {
    "id": 225,
    "traditional": "嗎",
    "simplified": "吗",
    "reading": "ma",
    "meaning": "particle: calling for agreement or disagreement",
    "grade": 1
  },
  {
    "id": 226,
    "traditional": "慢",
    "simplified": "慢",
    "reading": "màn",
    "meaning": "tardy, rude, slow down, slow, slow up, retard, lose, defer, supercilious, postpone, decelerate",
    "grade": 1
  },
  {
    "id": 227,
    "traditional": "忙",
    "simplified": "忙",
    "reading": "máng",
    "meaning": "hurry, hasten",
    "grade": 1
  },
  {
    "id": 228,
    "traditional": "毛",
    "simplified": "毛",
    "reading": "máo",
    "meaning": "Mao, wool, bristle, nap, pilus, down, feather, mold, mildew, Surname, hair, 1 1 0 th yuan, 1 0 th yuan, dime, silk, cent, fur, marking",
    "grade": 1
  },
  {
    "id": 229,
    "traditional": "沒",
    "simplified": "没",
    "reading": "méi",
    "meaning": "not",
    "grade": 1
  },
  {
    "id": 230,
    "traditional": "沒關係",
    "simplified": "没关系",
    "reading": "méiguānxi",
    "meaning": "it doesn't matter, never mind",
    "grade": 1
  },
  {
    "id": 231,
    "traditional": "沒什麼",
    "simplified": "没什么",
    "reading": "méishénme",
    "meaning": "nothing, it doesn't matter, it's nothing, never mind",
    "grade": 1
  },
  {
    "id": 232,
    "traditional": "沒有",
    "simplified": "没有",
    "reading": "méiyou",
    "meaning": "not have, there is not, be without",
    "grade": 1
  },
  {
    "id": 233,
    "traditional": "沒事兒",
    "simplified": "没事儿",
    "reading": "méishìr",
    "meaning": "to have spare time, free from work, it's not important, it's nothing, never mind",
    "grade": 1
  },
  {
    "id": 234,
    "traditional": "妹妹",
    "simplified": "妹妹",
    "reading": "mèimei",
    "meaning": "little_sister, sis, younger sister, little sister, sister",
    "grade": 1
  },
  {
    "id": 235,
    "traditional": "門",
    "simplified": "门",
    "reading": "mén",
    "meaning": "door, gate",
    "grade": 1
  },
  {
    "id": 236,
    "traditional": "門口",
    "simplified": "门口",
    "reading": "ménkǒu",
    "meaning": "porch, portal, doorway, gateway, threshold, room access, door, entering, entrance",
    "grade": 1
  },
  {
    "id": 237,
    "traditional": "門票",
    "simplified": "门票",
    "reading": "ménpiào",
    "meaning": "entrance/admission ticket, admission ticket, admission fee, admission price, entrance ticket, admission charge, cover_charge, admission, entrance fee, entrance money, pasteboard, price of admission, entrance",
    "grade": 1
  },
  {
    "id": 238,
    "traditional": "米飯",
    "simplified": "米饭",
    "reading": "mǐfàn",
    "meaning": "(cooked) rice",
    "grade": 1
  },
  {
    "id": 239,
    "traditional": "們",
    "simplified": "们",
    "reading": "men",
    "meaning": "pluralizer after pronouns and nouns of persons",
    "grade": 1
  },
  {
    "id": 240,
    "traditional": "麵包",
    "simplified": "面包",
    "reading": "miànbāo",
    "meaning": "bun, staff of life, cookie, breadstuff, bread",
    "grade": 1
  },
  {
    "id": 241,
    "traditional": "麵條兒",
    "simplified": "面条儿",
    "reading": "miàntiáor",
    "meaning": "noodles, erhua variant of 麵條|面条[mian4 tiao2]",
    "grade": 1
  },
  {
    "id": 242,
    "traditional": "名字",
    "simplified": "名字",
    "reading": "míngzi",
    "meaning": "first_name, forename, moniker, monaker, first name, monicker, cognomen, given name, name, patronymic, appellation",
    "grade": 1
  },
  {
    "id": 243,
    "traditional": "明白",
    "simplified": "明白",
    "reading": "míngbai",
    "meaning": "clarify, downright, recognize, agnize, tumble, follow, make out, catch, understand, discern, think, know, seize, agnise, twig, realize, get, realization",
    "grade": 1
  },
  {
    "id": 244,
    "traditional": "明年",
    "simplified": "明年",
    "reading": "míngnián",
    "meaning": "next year",
    "grade": 1
  },
  {
    "id": 245,
    "traditional": "明天",
    "simplified": "明天",
    "reading": "míngtiān",
    "meaning": "tomorrow",
    "grade": 1
  },
  {
    "id": 246,
    "traditional": "哪",
    "simplified": "哪",
    "reading": "nǎ",
    "meaning": "det.: which",
    "grade": 1
  },
  {
    "id": 247,
    "traditional": "哪裡",
    "simplified": "哪里",
    "reading": "nǎli",
    "meaning": "where",
    "grade": 1
  },
  {
    "id": 248,
    "traditional": "哪兒",
    "simplified": "哪儿",
    "reading": "nǎr",
    "meaning": "where",
    "grade": 1
  },
  {
    "id": 249,
    "traditional": "哪些",
    "simplified": "哪些",
    "reading": "nǎxiē",
    "meaning": "det.: which",
    "grade": 1
  },
  {
    "id": 250,
    "traditional": "拿",
    "simplified": "拿",
    "reading": "ná",
    "meaning": "take, hold, seize, capture",
    "grade": 1
  },
  {
    "id": 251,
    "traditional": "那邊",
    "simplified": "那边",
    "reading": "nàbian",
    "meaning": "beyond, that side, there",
    "grade": 1
  },
  {
    "id": 252,
    "traditional": "那",
    "simplified": "那",
    "reading": "nà",
    "meaning": "det.: that",
    "grade": 1
  },
  {
    "id": 253,
    "traditional": "那裡",
    "simplified": "那里",
    "reading": "nàlǐ",
    "meaning": "that place",
    "grade": 1
  },
  {
    "id": 254,
    "traditional": "那些",
    "simplified": "那些",
    "reading": "nàxiē",
    "meaning": "det.: those",
    "grade": 1
  },
  {
    "id": 255,
    "traditional": "奶",
    "simplified": "奶",
    "reading": "nǎi",
    "meaning": "breasts, milk",
    "grade": 1
  },
  {
    "id": 256,
    "traditional": "那兒",
    "simplified": "那儿",
    "reading": "nàr",
    "meaning": "that place",
    "grade": 1
  },
  {
    "id": 257,
    "traditional": "奶奶",
    "simplified": "奶奶",
    "reading": "nǎinai",
    "meaning": "nanna, address for married woman, granny, gran, grandmother, grandma, respectful form of address for an old woman, paternal grandmother",
    "grade": 1
  },
  {
    "id": 258,
    "traditional": "男孩兒",
    "simplified": "男孩儿",
    "reading": "nánháir",
    "meaning": "boy, erhua variant of 男孩[nan2 hai2]",
    "grade": 1
  },
  {
    "id": 259,
    "traditional": "男朋友",
    "simplified": "男朋友",
    "reading": "nánpéngyou",
    "meaning": "boyfriend",
    "grade": 1
  },
  {
    "id": 260,
    "traditional": "男",
    "simplified": "男",
    "reading": "nán",
    "meaning": "baron, male, man, steady, mankind, male person, boy, chauvinism, creature, son",
    "grade": 1
  },
  {
    "id": 261,
    "traditional": "男生",
    "simplified": "男生",
    "reading": "nánshēng",
    "meaning": "brother, sonny boy, man, male, laddie, lad, boy student, male student, boy, schoolboy, sonny, cub, man student",
    "grade": 1
  },
  {
    "id": 262,
    "traditional": "南",
    "simplified": "南",
    "reading": "nán",
    "meaning": "Yugoslavia, South, Nan, south, Surname, S, due south, austral, southern",
    "grade": 1
  },
  {
    "id": 263,
    "traditional": "男人",
    "simplified": "男人",
    "reading": "nánren",
    "meaning": "male, man, chap, mankind, buck, bastard, gentleman, jack, gent, men, blighter, cuss, hombre, lad, adult male, groom, husband, bimbo, dog, fellow, mon, menfolk, fella",
    "grade": 1
  },
  {
    "id": 264,
    "traditional": "南邊",
    "simplified": "南边",
    "reading": "nánbian",
    "meaning": "south, south side, southern part, to the south of",
    "grade": 1
  },
  {
    "id": 265,
    "traditional": "呢",
    "simplified": "呢",
    "reading": "ne",
    "meaning": "particle: marking quesitons about subject already mentioned; indicating strong affirmation",
    "grade": 1
  },
  {
    "id": 266,
    "traditional": "難",
    "simplified": "难",
    "reading": "nán",
    "meaning": "make difficulties, take to task, put ... into a difficult position, disagreeable, make difficult, unpleasant, hard, difficult, troublesome, blame",
    "grade": 1
  },
  {
    "id": 267,
    "traditional": "你",
    "simplified": "你",
    "reading": "nǐ",
    "meaning": "you",
    "grade": 1
  },
  {
    "id": 268,
    "traditional": "能",
    "simplified": "能",
    "reading": "néng",
    "meaning": "can or to be capable of",
    "grade": 1
  },
  {
    "id": 269,
    "traditional": "你們",
    "simplified": "你们",
    "reading": "nǐmen",
    "meaning": "you, you (plural), you-all",
    "grade": 1
  },
  {
    "id": 270,
    "traditional": "您",
    "simplified": "您",
    "reading": "nín",
    "meaning": "you",
    "grade": 1
  },
  {
    "id": 271,
    "traditional": "牛奶",
    "simplified": "牛奶",
    "reading": "niúnǎi",
    "meaning": "cow's milk",
    "grade": 1
  },
  {
    "id": 272,
    "traditional": "女",
    "simplified": "女",
    "reading": "nǚ",
    "meaning": "girl, female, woman, neodymium, hen, daughter, beaver, creature",
    "grade": 1
  },
  {
    "id": 273,
    "traditional": "年",
    "simplified": "年",
    "reading": "nián",
    "meaning": "year",
    "grade": 1
  },
  {
    "id": 274,
    "traditional": "女孩兒",
    "simplified": "女孩儿",
    "reading": "nǚháir",
    "meaning": "girl, erhua variant of 女孩[nu:3 hai2]",
    "grade": 1
  },
  {
    "id": 275,
    "traditional": "女兒",
    "simplified": "女儿",
    "reading": "nǚér",
    "meaning": "daughter, girl",
    "grade": 1
  },
  {
    "id": 276,
    "traditional": "女生",
    "simplified": "女生",
    "reading": "nǚshēng",
    "meaning": "schoolgirl, girl student, female student, female, girl, woman student, co-ed",
    "grade": 1
  },
  {
    "id": 277,
    "traditional": "女朋友",
    "simplified": "女朋友",
    "reading": "nǚpéngyou",
    "meaning": "jane, girl, girl friend, lady friend, girlfriend, amie",
    "grade": 1
  },
  {
    "id": 278,
    "traditional": "旁邊",
    "simplified": "旁边",
    "reading": "pángbiān",
    "meaning": "by the side of, side, adjacency, near by",
    "grade": 1
  },
  {
    "id": 279,
    "traditional": "跑",
    "simplified": "跑",
    "reading": "pǎo",
    "meaning": "travel, race, be away, escape, course, run errands, skirr, double, walk, be away/off, run away, be off, run about doing sth., run, drive, paw the earth, run about doing ..., ride, leg",
    "grade": 1
  },
  {
    "id": 280,
    "traditional": "女人",
    "simplified": "女人",
    "reading": "nǚren",
    "meaning": "jade, crumpet, female, female person, Gill, bitch, chick, fair, squaw, womenfolk, adult female, doll, momma, wench, skirt, womankind, girl, jill, wife, broad, petticoat, sister, woman, bimbo, bird, jane, she, hen, hairpin, dame, women",
    "grade": 1
  },
  {
    "id": 281,
    "traditional": "朋友",
    "simplified": "朋友",
    "reading": "péngyou",
    "meaning": "amigo, connections, kith, friend, boy, crony, ami, boy/girl friend, companion, mate, boy friend or girl friend, compadre, boy friend, Mac, paisano, matey, old_boy, sport, folks, pard, hearty, Kamerad, cocker, cobber, girl friend, pardner, company",
    "grade": 1
  },
  {
    "id": 282,
    "traditional": "票",
    "simplified": "票",
    "reading": "piào",
    "meaning": "ticket, ballot, note",
    "grade": 1
  },
  {
    "id": 283,
    "traditional": "七",
    "simplified": "七",
    "reading": "qī",
    "meaning": "det.: seven",
    "grade": 1
  },
  {
    "id": 284,
    "traditional": "起床",
    "simplified": "起床",
    "reading": "qǐ chuáng",
    "meaning": "uprise, get up from bed, arise, get out of bed, turn out, rise, deck, get_up, awake, get up",
    "grade": 1
  },
  {
    "id": 285,
    "traditional": "起來",
    "simplified": "起来",
    "reading": "qǐ  lai",
    "meaning": "[aspect]",
    "grade": 1
  },
  {
    "id": 286,
    "traditional": "起",
    "simplified": "起",
    "reading": "qǐ",
    "meaning": "[aspect]",
    "grade": 1
  },
  {
    "id": 287,
    "traditional": "汽車",
    "simplified": "汽车",
    "reading": "qìchē",
    "meaning": "automotive vehicle, machine, wheels, motorcar, motor vehicle, car, automotive, auto, wheel, autocar, motor, automobile, hoot, mobile, bus",
    "grade": 1
  },
  {
    "id": 288,
    "traditional": "前",
    "simplified": "前",
    "reading": "qián",
    "meaning": "front",
    "grade": 1
  },
  {
    "id": 289,
    "traditional": "前邊",
    "simplified": "前边",
    "reading": "qiánbian",
    "meaning": "front, the front side, in front of",
    "grade": 1
  },
  {
    "id": 290,
    "traditional": "錢",
    "simplified": "钱",
    "reading": "qián",
    "meaning": "copper coin, cash, money, fund, sum",
    "grade": 1
  },
  {
    "id": 291,
    "traditional": "錢包",
    "simplified": "钱包",
    "reading": "qiánbāo",
    "meaning": "purse, wallet",
    "grade": 1
  },
  {
    "id": 292,
    "traditional": "請",
    "simplified": "请",
    "reading": "qǐng",
    "meaning": "request, ask (a favor), engage, hire (teacher/etc.), Please...",
    "grade": 1
  },
  {
    "id": 293,
    "traditional": "前天",
    "simplified": "前天",
    "reading": "qiántiān",
    "meaning": "the day before yesterday",
    "grade": 1
  },
  {
    "id": 294,
    "traditional": "請假",
    "simplified": "请假",
    "reading": "qǐng jià",
    "meaning": "vacate, ask for leave",
    "grade": 1
  },
  {
    "id": 295,
    "traditional": "請問",
    "simplified": "请问",
    "reading": "qǐngwèn",
    "meaning": "Excuse me, may I ask...?",
    "grade": 1
  },
  {
    "id": 296,
    "traditional": "請進",
    "simplified": "请进",
    "reading": "qǐngjìn",
    "meaning": "'please come in'",
    "grade": 1
  },
  {
    "id": 297,
    "traditional": "請坐",
    "simplified": "请坐",
    "reading": "qǐng zuò",
    "meaning": "Sitting",
    "grade": 1
  },
  {
    "id": 298,
    "traditional": "球",
    "simplified": "球",
    "reading": "qiú",
    "meaning": "ball",
    "grade": 1
  },
  {
    "id": 299,
    "traditional": "去年",
    "simplified": "去年",
    "reading": "qùnián",
    "meaning": "last year",
    "grade": 1
  },
  {
    "id": 300,
    "traditional": "熱",
    "simplified": "热",
    "reading": "rè",
    "meaning": "hot",
    "grade": 1
  },
  {
    "id": 301,
    "traditional": "人",
    "simplified": "人",
    "reading": "rén",
    "meaning": "person, people",
    "grade": 1
  },
  {
    "id": 302,
    "traditional": "認識",
    "simplified": "认识",
    "reading": "rènshi",
    "meaning": "know, recognize",
    "grade": 1
  },
  {
    "id": 303,
    "traditional": "認真",
    "simplified": "认真",
    "reading": "rènzhēn",
    "meaning": "serious, earnest, take for real",
    "grade": 1
  },
  {
    "id": 304,
    "traditional": "去",
    "simplified": "去",
    "reading": "qù",
    "meaning": "to go, to leave, in order to",
    "grade": 1
  },
  {
    "id": 305,
    "traditional": "日",
    "simplified": "日",
    "reading": "rì",
    "meaning": "m.[standard]",
    "grade": 1
  },
  {
    "id": 306,
    "traditional": "日期",
    "simplified": "日期",
    "reading": "rìqī",
    "meaning": "calendar, when, date",
    "grade": 1
  },
  {
    "id": 307,
    "traditional": "肉",
    "simplified": "肉",
    "reading": "ròu",
    "meaning": "pulp, chilli, cabobs, meat, lobscouse, paella, meaty, fleshy, flesh, marmite",
    "grade": 1
  },
  {
    "id": 308,
    "traditional": "山",
    "simplified": "山",
    "reading": "shān",
    "meaning": "Shan, jebel, pike, mountain, whitethorn, hump, anything resembling a mountain, gable, kop, chimney, hill, Surname, mount",
    "grade": 1
  },
  {
    "id": 309,
    "traditional": "商場",
    "simplified": "商场",
    "reading": "shāngchǎng",
    "meaning": "department store, bazar, line of business, field of operation, grocery store, emporium, bazaar, grocery, mall, commercial circle, field, market",
    "grade": 1
  },
  {
    "id": 310,
    "traditional": "上",
    "simplified": "上",
    "reading": "shàng",
    "meaning": "on, above",
    "grade": 1
  },
  {
    "id": 311,
    "traditional": "三",
    "simplified": "三",
    "reading": "sān",
    "meaning": "det.: three",
    "grade": 1
  },
  {
    "id": 312,
    "traditional": "商店",
    "simplified": "商店",
    "reading": "shāngdiàn",
    "meaning": "concern, hospital, business, store, shoppe, shop",
    "grade": 1
  },
  {
    "id": 313,
    "traditional": "上班",
    "simplified": "上班",
    "reading": "shàng bān",
    "meaning": "start work, go to work, be on duty",
    "grade": 1
  },
  {
    "id": 314,
    "traditional": "上車",
    "simplified": "上车",
    "reading": "shàngchē",
    "meaning": "to get on or into (a bus, train, car etc)",
    "grade": 1
  },
  {
    "id": 315,
    "traditional": "上課",
    "simplified": "上课",
    "reading": "shàng kè",
    "meaning": "attend class, go to class;give lesson/lecture",
    "grade": 1
  },
  {
    "id": 316,
    "traditional": "上網",
    "simplified": "上网",
    "reading": "shàng wǎng",
    "meaning": "to go online, to connect to the Internet, (of a document etc) to be uploaded to the Internet, (tennis, volleyball etc) to move in close to the net",
    "grade": 1
  },
  {
    "id": 317,
    "traditional": "上邊",
    "simplified": "上边",
    "reading": "shàngbian",
    "meaning": "the top, above, overhead, upwards, the top margin, above-mentioned, those higher up",
    "grade": 1
  },
  {
    "id": 318,
    "traditional": "上學",
    "simplified": "上学",
    "reading": "shàng xué",
    "meaning": "attend school, be at school",
    "grade": 1
  },
  {
    "id": 319,
    "traditional": "上午",
    "simplified": "上午",
    "reading": "shàngwǔ",
    "meaning": "morning, CL:個|个[ge4]",
    "grade": 1
  },
  {
    "id": 320,
    "traditional": "少",
    "simplified": "少",
    "reading": "shǎo",
    "meaning": "lack, be deficient, lose, be missing",
    "grade": 1
  },
  {
    "id": 321,
    "traditional": "上次",
    "simplified": "上次",
    "reading": "shàngcì",
    "meaning": "last time",
    "grade": 1
  },
  {
    "id": 322,
    "traditional": "身上",
    "simplified": "身上",
    "reading": "shēnshang",
    "meaning": "on the body, at hand, among",
    "grade": 1
  },
  {
    "id": 323,
    "traditional": "誰",
    "simplified": "谁",
    "reading": "shéi",
    "meaning": "who, someone, anyone",
    "grade": 1
  },
  {
    "id": 324,
    "traditional": "身體",
    "simplified": "身体",
    "reading": "shēntǐ",
    "meaning": "soma, system, physical structure, body, health, carcase, corpus, frame, person, somatic, organic structure, personage",
    "grade": 1
  },
  {
    "id": 325,
    "traditional": "生病",
    "simplified": "生病",
    "reading": "shēng bìng",
    "meaning": "come down, ail, fall ill, traik, sick, sicken",
    "grade": 1
  },
  {
    "id": 326,
    "traditional": "生氣",
    "simplified": "生气",
    "reading": "shēng qì",
    "meaning": "get angry, be angry",
    "grade": 1
  },
  {
    "id": 327,
    "traditional": "十",
    "simplified": "十",
    "reading": "shí",
    "meaning": "det.: ten",
    "grade": 1
  },
  {
    "id": 328,
    "traditional": "時候",
    "simplified": "时候",
    "reading": "shíhou",
    "meaning": "day, moment, (a point in) time, (duration of) time, time",
    "grade": 1
  },
  {
    "id": 329,
    "traditional": "時間",
    "simplified": "时间",
    "reading": "shíjiān",
    "meaning": "moment, day, clock time, fourth_dimension, relativity, hour, duration, time, when, while, temporal, economy",
    "grade": 1
  },
  {
    "id": 330,
    "traditional": "事",
    "simplified": "事",
    "reading": "shì",
    "meaning": "responsibility, trouble, event, thing, worry, accident, case, involvement, work, affair, job, concern, business, toast, matter",
    "grade": 1
  },
  {
    "id": 331,
    "traditional": "試",
    "simplified": "试",
    "reading": "shì",
    "meaning": "offer, attempt, essay, flesh, try, seek, assay, test",
    "grade": 1
  },
  {
    "id": 332,
    "traditional": "生日",
    "simplified": "生日",
    "reading": "shēngri",
    "meaning": "birthday",
    "grade": 1
  },
  {
    "id": 333,
    "traditional": "是不是",
    "simplified": "是不是",
    "reading": "shìbùshì",
    "meaning": "is or isn't, yes or no, whether or not",
    "grade": 1
  },
  {
    "id": 334,
    "traditional": "是",
    "simplified": "是",
    "reading": "shì",
    "meaning": "is, are, am, yes, to be, variant of 是[shi4], (used in given names)",
    "grade": 1
  },
  {
    "id": 335,
    "traditional": "手機",
    "simplified": "手机",
    "reading": "shǒujī",
    "meaning": "cell phone, mobile phone, CL:部[bu4],支[zhi1]",
    "grade": 1
  },
  {
    "id": 336,
    "traditional": "手",
    "simplified": "手",
    "reading": "shǒu",
    "meaning": "hand",
    "grade": 1
  },
  {
    "id": 337,
    "traditional": "書包",
    "simplified": "书包",
    "reading": "shūbāo",
    "meaning": "bag, book bag, satchel, schoolbag",
    "grade": 1
  },
  {
    "id": 338,
    "traditional": "書",
    "simplified": "书",
    "reading": "shū",
    "meaning": "volume, document, style of calligraphy, book, script, letter",
    "grade": 1
  },
  {
    "id": 339,
    "traditional": "書店",
    "simplified": "书店",
    "reading": "shūdiàn",
    "meaning": "bookstore",
    "grade": 1
  },
  {
    "id": 340,
    "traditional": "樹",
    "simplified": "树",
    "reading": "shù",
    "meaning": "wood, Ruptiliocarpon caracolito, tree, arbor, Surname, caracolito",
    "grade": 1
  },
  {
    "id": 341,
    "traditional": "水",
    "simplified": "水",
    "reading": "shuǐ",
    "meaning": "water, H2O, liquid, bubble, a general term for rivers, lakes, seas, etc., Shiu, waters, aqueous, Aquarius, river, Surname, blister, drink, eau, aqua",
    "grade": 1
  },
  {
    "id": 342,
    "traditional": "水果",
    "simplified": "水果",
    "reading": "shuǐguǒ",
    "meaning": "fruit",
    "grade": 1
  },
  {
    "id": 343,
    "traditional": "睡",
    "simplified": "睡",
    "reading": "shuì",
    "meaning": "sleep",
    "grade": 1
  },
  {
    "id": 344,
    "traditional": "說",
    "simplified": "说",
    "reading": "shuō",
    "meaning": "speak, talk, explain, scold",
    "grade": 1
  },
  {
    "id": 345,
    "traditional": "說話",
    "simplified": "说话",
    "reading": "shuō huà",
    "meaning": "philosophize, thrum, talk, peach, babble out, speech, articulate, chat, speak, babble, gossip, blab out, sing, address, tattle, blab, mouth, say",
    "grade": 1
  },
  {
    "id": 346,
    "traditional": "睡覺",
    "simplified": "睡觉",
    "reading": "shuì jiào",
    "meaning": "turn_in, log Z's, doss, slumber, catch some Z's, go to bed, sleep, doss down, crash, go_to_sleep, hit_the_sack, kip",
    "grade": 1
  },
  {
    "id": 347,
    "traditional": "送",
    "simplified": "送",
    "reading": "sòng",
    "meaning": "accompaniment, escort, see sb. off, see sb. off/out, to dispatch, see ... off, accompany, to deliver, send, direct, serve, guide, give as a present, deliver, see sb. out, see, lead, see ... out, conduct, give, to send, carry, delivery, carriage, take, hospitalize",
    "grade": 1
  },
  {
    "id": 348,
    "traditional": "四",
    "simplified": "四",
    "reading": "sì",
    "meaning": "det.: four",
    "grade": 1
  },
  {
    "id": 349,
    "traditional": "嵗",
    "simplified": "岁",
    "reading": "suì",
    "meaning": "year (for crops), m.[standard], , year of age, year (of age), year, annum, year for crops",
    "grade": 1
  },
  {
    "id": 350,
    "traditional": "他們",
    "simplified": "他们",
    "reading": "tāmen",
    "meaning": "they",
    "grade": 1
  },
  {
    "id": 351,
    "traditional": "什麼",
    "simplified": "什么",
    "reading": "shénme",
    "meaning": "anything, what",
    "grade": 1
  },
  {
    "id": 352,
    "traditional": "她",
    "simplified": "她",
    "reading": "tā",
    "meaning": "she, her, she (neologism for {1ta1}[2]), )",
    "grade": 1
  },
  {
    "id": 353,
    "traditional": "她們",
    "simplified": "她们",
    "reading": "tāmen",
    "meaning": "they (female)",
    "grade": 1
  },
  {
    "id": 354,
    "traditional": "天",
    "simplified": "天",
    "reading": "tiān",
    "meaning": "m.[standard]",
    "grade": 1
  },
  {
    "id": 355,
    "traditional": "他",
    "simplified": "他",
    "reading": "tā",
    "meaning": "he, she, it, they (referring to inanimate things in object position)",
    "grade": 1
  },
  {
    "id": 356,
    "traditional": "太",
    "simplified": "太",
    "reading": "tài",
    "meaning": "excessively, too, extremely, very",
    "grade": 1
  },
  {
    "id": 357,
    "traditional": "天氣",
    "simplified": "天气",
    "reading": "tiānqì",
    "meaning": "sky, elements, weather condition, atmospheric condition, weather",
    "grade": 1
  },
  {
    "id": 358,
    "traditional": "聽到",
    "simplified": "听到",
    "reading": "tīngdào",
    "meaning": "hear",
    "grade": 1
  },
  {
    "id": 359,
    "traditional": "聽見",
    "simplified": "听见",
    "reading": "tīng jian",
    "meaning": "hear",
    "grade": 1
  },
  {
    "id": 360,
    "traditional": "聽寫",
    "simplified": "听写",
    "reading": "tīngxiě",
    "meaning": "dictate, dictation, CL:次[ci4]",
    "grade": 1
  },
  {
    "id": 361,
    "traditional": "聽",
    "simplified": "听",
    "reading": "tīng",
    "meaning": "hear, list, hearken, obey, obedience, listen, heed, administer, let, allow, hark",
    "grade": 1
  },
  {
    "id": 362,
    "traditional": "圖書館",
    "simplified": "图书馆",
    "reading": "túshūguǎn",
    "meaning": "library",
    "grade": 1
  },
  {
    "id": 363,
    "traditional": "外",
    "simplified": "外",
    "reading": "wài",
    "meaning": "outside, in addition, foreign, external",
    "grade": 1
  },
  {
    "id": 364,
    "traditional": "外邊",
    "simplified": "外边",
    "reading": "wàibian",
    "meaning": "outside, outer surface, abroad, place other than one's home",
    "grade": 1
  },
  {
    "id": 365,
    "traditional": "同學",
    "simplified": "同学",
    "reading": "tóngxué",
    "meaning": "class fellow, fellow student, condisciple, classmate, schoolmate, schoolfellow, form of address used in speaking to a student",
    "grade": 1
  },
  {
    "id": 366,
    "traditional": "外國",
    "simplified": "外国",
    "reading": "wàiguó",
    "meaning": "foreign, foreign country, outland, outremer",
    "grade": 1
  },
  {
    "id": 367,
    "traditional": "玩兒",
    "simplified": "玩儿",
    "reading": "wánr",
    "meaning": "to play, to have fun, to hang out",
    "grade": 1
  },
  {
    "id": 368,
    "traditional": "晚",
    "simplified": "晚",
    "reading": "wǎn",
    "meaning": "far on in time, late",
    "grade": 1
  },
  {
    "id": 369,
    "traditional": "晚飯",
    "simplified": "晚饭",
    "reading": "wǎnfàn",
    "meaning": "evening meal, dinner, supper, CL:份[fen4],頓|顿[dun4],次[ci4],餐[can1]",
    "grade": 1
  },
  {
    "id": 370,
    "traditional": "晚上",
    "simplified": "晚上",
    "reading": "wǎnshang",
    "meaning": "evening, night, CL:個|个[ge4], in the evening",
    "grade": 1
  },
  {
    "id": 371,
    "traditional": "網上",
    "simplified": "网上",
    "reading": "wǎngshàng",
    "meaning": "online",
    "grade": 1
  },
  {
    "id": 372,
    "traditional": "網友",
    "simplified": "网友",
    "reading": "wǎngyǒu",
    "meaning": "online friend, Internet user",
    "grade": 1
  },
  {
    "id": 373,
    "traditional": "外語",
    "simplified": "外语",
    "reading": "wàiyǔ",
    "meaning": "foreign language",
    "grade": 1
  },
  {
    "id": 374,
    "traditional": "忘記",
    "simplified": "忘记",
    "reading": "wàngjì",
    "meaning": "misremember, block, blank out, neglect, overlook, draw a blank, leave_out, disremember, fail, forget",
    "grade": 1
  },
  {
    "id": 375,
    "traditional": "忘",
    "simplified": "忘",
    "reading": "wàng",
    "meaning": "forget, overlook, neglect",
    "grade": 1
  },
  {
    "id": 376,
    "traditional": "問",
    "simplified": "问",
    "reading": "wèn",
    "meaning": "ask, examine, question, interrogate",
    "grade": 1
  },
  {
    "id": 377,
    "traditional": "我們",
    "simplified": "我们",
    "reading": "wǒmen",
    "meaning": "we",
    "grade": 1
  },
  {
    "id": 378,
    "traditional": "我",
    "simplified": "我",
    "reading": "wǒ",
    "meaning": "I, me, ego, self",
    "grade": 1
  },
  {
    "id": 379,
    "traditional": "五",
    "simplified": "五",
    "reading": "wǔ",
    "meaning": "det.: five",
    "grade": 1
  },
  {
    "id": 380,
    "traditional": "午飯",
    "simplified": "午饭",
    "reading": "wǔfàn",
    "meaning": "lunch, CL:份[fen4],頓|顿[dun4],次[ci4],餐[can1]",
    "grade": 1
  },
  {
    "id": 381,
    "traditional": "西",
    "simplified": "西",
    "reading": "xī",
    "meaning": "W, Spain, west, occident, due west, western",
    "grade": 1
  },
  {
    "id": 382,
    "traditional": "西邊",
    "simplified": "西边",
    "reading": "xībian",
    "meaning": "west, west side, western part, to the west of",
    "grade": 1
  },
  {
    "id": 383,
    "traditional": "洗",
    "simplified": "洗",
    "reading": "xǐ",
    "meaning": "wash, bathe, sack, develop (film), shuffle (cards/etc.)",
    "grade": 1
  },
  {
    "id": 384,
    "traditional": "洗手間",
    "simplified": "洗手间",
    "reading": "xǐshǒujiān",
    "meaning": "toilet, lavatory, washroom",
    "grade": 1
  },
  {
    "id": 385,
    "traditional": "喜歡",
    "simplified": "喜欢",
    "reading": "xǐhuan",
    "meaning": "like, love, be fond of, be happy/elated",
    "grade": 1
  },
  {
    "id": 386,
    "traditional": "下",
    "simplified": "下",
    "reading": "xià",
    "meaning": "under (condition/fact etc.)",
    "grade": 1
  },
  {
    "id": 387,
    "traditional": "下班",
    "simplified": "下班",
    "reading": "xià bān",
    "meaning": "get_off",
    "grade": 1
  },
  {
    "id": 388,
    "traditional": "下邊",
    "simplified": "下边",
    "reading": "xiàbian",
    "meaning": "under, the underside, below",
    "grade": 1
  },
  {
    "id": 389,
    "traditional": "下車",
    "simplified": "下车",
    "reading": "xiàchē",
    "meaning": "get off/out of vehicle",
    "grade": 1
  },
  {
    "id": 390,
    "traditional": "下次",
    "simplified": "下次",
    "reading": "xiàcì",
    "meaning": "next time",
    "grade": 1
  },
  {
    "id": 391,
    "traditional": "下課",
    "simplified": "下课",
    "reading": "xià kè",
    "meaning": "finish class, get out of class",
    "grade": 1
  },
  {
    "id": 392,
    "traditional": "下午",
    "simplified": "下午",
    "reading": "xiàwǔ",
    "meaning": "afternoon, CL:個|个[ge4], p.m.",
    "grade": 1
  },
  {
    "id": 393,
    "traditional": "下雨",
    "simplified": "下雨",
    "reading": "xiàyǔ",
    "meaning": "rain",
    "grade": 1
  },
  {
    "id": 394,
    "traditional": "先",
    "simplified": "先",
    "reading": "xiān",
    "meaning": "earlier, in advance, before",
    "grade": 1
  },
  {
    "id": 395,
    "traditional": "先生",
    "simplified": "先生",
    "reading": "xiānsheng",
    "meaning": "doctor of Chinese medicine, Don, mulla, Ms., sir., teacher, gentleman, gospodin, Signor, signor, fortune-teller, babu, baboo, Herr, Mister, bey, mister, bwana, husband, siree, buckra, sirrah, senor, guv, sahib, Mr., rabbi, don, signore, Dan, sir, m'sieur, effendi, Tuan, baas, Monsieur, lording, monsieur, doctor, Mr, milord, Mynheer, signior, esquire, bookkeeper, Senor, rabboni, Senhor, sieur, sen",
    "grade": 1
  },
  {
    "id": 396,
    "traditional": "想",
    "simplified": "想",
    "reading": "xiǎng",
    "meaning": "think, suppose, reckon, consider, want to, would like to, feel like (doing sth.)",
    "grade": 1
  },
  {
    "id": 397,
    "traditional": "現在",
    "simplified": "现在",
    "reading": "xiànzài",
    "meaning": "now, at present, at the moment, modern, current, nowadays",
    "grade": 1
  },
  {
    "id": 398,
    "traditional": "小",
    "simplified": "小",
    "reading": "xiǎo",
    "meaning": "small, little, petty, minor;young",
    "grade": 1
  },
  {
    "id": 399,
    "traditional": "小孩兒",
    "simplified": "小孩儿",
    "reading": "xiǎoháir",
    "meaning": "child, erhua variant of 小孩[xiao3 hai2]",
    "grade": 1
  },
  {
    "id": 400,
    "traditional": "小姐",
    "simplified": "小姐",
    "reading": "xiǎojiě",
    "meaning": "Signorina, Senorita, senorita, signorina, young_lady, damosel, Dona, mum, young lady, damoiselle, missy, tootsy, demoiselle, damsel, Miss, Fraulein, miss, sheila, sister, toots, senhorita, damozel, Ladyship, ladyship, Mademoiselle, mademoiselle",
    "grade": 1
  },
  {
    "id": 401,
    "traditional": "小時",
    "simplified": "小时",
    "reading": "xiǎoshí",
    "meaning": "hour",
    "grade": 1
  },
  {
    "id": 402,
    "traditional": "小朋友",
    "simplified": "小朋友",
    "reading": "xiǎopéngyǒu",
    "meaning": "child (adult address to child), little boy, children, little boy/girl, girl, little girl, little boy or girl, child",
    "grade": 1
  },
  {
    "id": 403,
    "traditional": "小學",
    "simplified": "小学",
    "reading": "xiǎoxué",
    "meaning": "primary school, elementary school",
    "grade": 1
  },
  {
    "id": 404,
    "traditional": "小學生",
    "simplified": "小学生",
    "reading": "xiǎo xuésheng",
    "meaning": "(primary) pupil, schoolboy, schoolgirl",
    "grade": 1
  },
  {
    "id": 405,
    "traditional": "笑",
    "simplified": "笑",
    "reading": "xiào",
    "meaning": "smile, laugh",
    "grade": 1
  },
  {
    "id": 406,
    "traditional": "寫",
    "simplified": "写",
    "reading": "xiě",
    "meaning": "description, bewrite, direct, describe, pencil, draw, paint, inscribe, write, depiction, write_out, throw_off, depict, address, author, screeve, word, compose",
    "grade": 1
  },
  {
    "id": 407,
    "traditional": "謝謝",
    "simplified": "谢谢",
    "reading": "xièxie",
    "meaning": "thank",
    "grade": 1
  },
  {
    "id": 408,
    "traditional": "新",
    "simplified": "新",
    "reading": "xīn",
    "meaning": "new, fresh, up-to-date",
    "grade": 1
  },
  {
    "id": 409,
    "traditional": "新年",
    "simplified": "新年",
    "reading": "xīnnián",
    "meaning": "New Year, CL:個|个[ge4]",
    "grade": 1
  },
  {
    "id": 410,
    "traditional": "星期",
    "simplified": "星期",
    "reading": "xīngqī",
    "meaning": "Sunday, week",
    "grade": 1
  },
  {
    "id": 411,
    "traditional": "星期日",
    "simplified": "星期日",
    "reading": "Xīngqīrì",
    "meaning": "Sunday, CL:個|个[ge4]",
    "grade": 1
  },
  {
    "id": 412,
    "traditional": "星期天",
    "simplified": "星期天",
    "reading": "xīngqītiān",
    "meaning": "Sunday, CL:個|个[ge4]",
    "grade": 1
  },
  {
    "id": 413,
    "traditional": "行",
    "simplified": "行",
    "reading": "xíng",
    "meaning": "go, travel, prevail, circulate, be current",
    "grade": 1
  },
  {
    "id": 414,
    "traditional": "休息",
    "simplified": "休息",
    "reading": "xiūxi",
    "meaning": "repose, have, lie dormant, respire, take time off, have a rest, take a rest, take off, relax, take_time_off, have/take a rest, rest",
    "grade": 1
  },
  {
    "id": 415,
    "traditional": "學生",
    "simplified": "学生",
    "reading": "xuésheng",
    "meaning": "academic, pupil, pup, tutee, follower, scholar, schoolchild, schoolboy, alumnus, disciple, learner, scholastic, student",
    "grade": 1
  },
  {
    "id": 416,
    "traditional": "學",
    "simplified": "学",
    "reading": "xué",
    "meaning": "study, learn, imitate, mimic",
    "grade": 1
  },
  {
    "id": 417,
    "traditional": "學習",
    "simplified": "学习",
    "reading": "xuéxí",
    "meaning": "study, learn, emulate",
    "grade": 1
  },
  {
    "id": 418,
    "traditional": "學校",
    "simplified": "学校",
    "reading": "xuéxiào",
    "meaning": "seminary, schoolhouse, educational institution, school, institution",
    "grade": 1
  },
  {
    "id": 419,
    "traditional": "學院",
    "simplified": "学院",
    "reading": "xuéyuàn",
    "meaning": "seminary, college, school, hall, institute, academy, faculty",
    "grade": 1
  },
  {
    "id": 420,
    "traditional": "要",
    "simplified": "要",
    "reading": "yào",
    "meaning": "want, need, must, should",
    "grade": 1
  },
  {
    "id": 421,
    "traditional": "爺爺",
    "simplified": "爷爷",
    "reading": "yéye",
    "meaning": "grandad, Grandpa, granddad, grandpa, (paternal) grandfather, gramps, granddaddy, grandfather",
    "grade": 1
  },
  {
    "id": 422,
    "traditional": "頁",
    "simplified": "页",
    "reading": "yè",
    "meaning": "page, leaf, m.[general], ,sheet of paper, sheet, piece, piece of paper, folio",
    "grade": 1
  },
  {
    "id": 423,
    "traditional": "衣服",
    "simplified": "衣服",
    "reading": "yīfu",
    "meaning": "clothes, wearing apparel, toggery, gear, tog, wear, bib-and-tucker, drapery, thing, dress, duds, vestment, wardrobe, clout, apparel, clothing, dud, wrapping, vesture, clobber, habit, robe, togs, livery, habilimentation, array, hull, garb, attire, attirement, stitch, rig, garment, raiment, wearable, dressing",
    "grade": 1
  },
  {
    "id": 424,
    "traditional": "也",
    "simplified": "也",
    "reading": "yě",
    "meaning": "also, too, as well, either, even",
    "grade": 1
  },
  {
    "id": 425,
    "traditional": "醫生",
    "simplified": "医生",
    "reading": "yīshēng",
    "meaning": "Galen, MD, physician, Dr., medic, doc, hakeem, medico, Aesculapius, medical man, surgeon, leech, doctor",
    "grade": 1
  },
  {
    "id": 426,
    "traditional": "醫院",
    "simplified": "医院",
    "reading": "yīyuàn",
    "meaning": "spital, hospital, infirmary, hospice",
    "grade": 1
  },
  {
    "id": 427,
    "traditional": "一",
    "simplified": "一",
    "reading": "yī",
    "meaning": "det.: one",
    "grade": 1
  },
  {
    "id": 428,
    "traditional": "一會兒",
    "simplified": "一会儿",
    "reading": "yīhuìr",
    "meaning": "a moment, a while, in a moment, now...now..., also pr. [yi1 hui3 r5]",
    "grade": 1
  },
  {
    "id": 429,
    "traditional": "一塊兒",
    "simplified": "一块儿",
    "reading": "yīkuàir",
    "meaning": "together",
    "grade": 1
  },
  {
    "id": 430,
    "traditional": "一下兒",
    "simplified": "一下儿",
    "reading": "yīxiàr",
    "meaning": "(used after a verb) give it a go, to do (sth for a bit to give it a try), one time, once, in a while, all of a sudden, all at once, erhua variant of 一下[yi1 xia4]",
    "grade": 1
  },
  {
    "id": 431,
    "traditional": "一半",
    "simplified": "一半",
    "reading": "yībàn",
    "meaning": "det.: one half, in part, half",
    "grade": 1
  },
  {
    "id": 432,
    "traditional": "一邊",
    "simplified": "一边",
    "reading": "yībiān",
    "meaning": "at the same time, simultaneously;on the one/other hand",
    "grade": 1
  },
  {
    "id": 433,
    "traditional": "一點兒",
    "simplified": "一点儿",
    "reading": "yīdiǎnr",
    "meaning": "det.: a little bit",
    "grade": 1
  },
  {
    "id": 434,
    "traditional": "一起",
    "simplified": "一起",
    "reading": "yīqǐ",
    "meaning": "in_chorus, together, in all, in the same place, holus-bolus, altogether, in company",
    "grade": 1
  },
  {
    "id": 435,
    "traditional": "一些",
    "simplified": "一些",
    "reading": "yīxiē",
    "meaning": "det.: some, a few, a little",
    "grade": 1
  },
  {
    "id": 436,
    "traditional": "一樣",
    "simplified": "一样",
    "reading": "yīyàng",
    "meaning": "same, equal, similar",
    "grade": 1
  },
  {
    "id": 437,
    "traditional": "有的",
    "simplified": "有的",
    "reading": "yǒude",
    "meaning": "det : some",
    "grade": 1
  },
  {
    "id": 438,
    "traditional": "有",
    "simplified": "有",
    "reading": "yǒu",
    "meaning": "have, possess",
    "grade": 1
  },
  {
    "id": 439,
    "traditional": "有名",
    "simplified": "有名",
    "reading": "yǒu míng",
    "meaning": "well-known, famous",
    "grade": 1
  },
  {
    "id": 440,
    "traditional": "用",
    "simplified": "用",
    "reading": "yòng",
    "meaning": "with",
    "grade": 1
  },
  {
    "id": 441,
    "traditional": "有時候",
    "simplified": "有时候",
    "reading": "yǒushíhou",
    "meaning": "from time to time, on_occasion, occasionally, somewhile, otherwhile, now and again, sometimes, now and then, at times, on occasion, whiles, once in a while",
    "grade": 1
  },
  {
    "id": 442,
    "traditional": "有些",
    "simplified": "有些",
    "reading": "yǒuxiē",
    "meaning": "kind_of, rather, somewhat",
    "grade": 1
  },
  {
    "id": 443,
    "traditional": "有用",
    "simplified": "有用",
    "reading": "yǒuyòng",
    "meaning": "belong, profit, count, useful, avail, powerful",
    "grade": 1
  },
  {
    "id": 444,
    "traditional": "右",
    "simplified": "右",
    "reading": "yòu",
    "meaning": "right side as side of precedence, right side, west, winger, right, the right side, the Right, the right",
    "grade": 1
  },
  {
    "id": 445,
    "traditional": "右邊",
    "simplified": "右边",
    "reading": "yòubian",
    "meaning": "right side, right, to the right",
    "grade": 1
  },
  {
    "id": 446,
    "traditional": "雨",
    "simplified": "雨",
    "reading": "yǔ",
    "meaning": "rain",
    "grade": 1
  },
  {
    "id": 447,
    "traditional": "元",
    "simplified": "元",
    "reading": "yuán",
    "meaning": "m.[standard]",
    "grade": 1
  },
  {
    "id": 448,
    "traditional": "遠",
    "simplified": "远",
    "reading": "yuǎn",
    "meaning": "far, distant (lit./fig.)",
    "grade": 1
  },
  {
    "id": 449,
    "traditional": "月",
    "simplified": "月",
    "reading": "yuè",
    "meaning": "moon",
    "grade": 1
  },
  {
    "id": 450,
    "traditional": "再",
    "simplified": "再",
    "reading": "zài",
    "meaning": "again, once more, further(more), in a higher degree, and then, not (do sth.) before",
    "grade": 1
  },
  {
    "id": 451,
    "traditional": "再見",
    "simplified": "再见",
    "reading": "zàijiàn",
    "meaning": "good-bye, see you again",
    "grade": 1
  },
  {
    "id": 452,
    "traditional": "在",
    "simplified": "在",
    "reading": "zài",
    "meaning": "in, at, on, etc.",
    "grade": 1
  },
  {
    "id": 453,
    "traditional": "在家",
    "simplified": "在家",
    "reading": "zàijiā",
    "meaning": "At home",
    "grade": 1
  },
  {
    "id": 454,
    "traditional": "早",
    "simplified": "早",
    "reading": "zǎo",
    "meaning": "early, long ago, in advance, beforehand, prematurely",
    "grade": 1
  },
  {
    "id": 455,
    "traditional": "早飯",
    "simplified": "早饭",
    "reading": "zǎofàn",
    "meaning": "breakfast, CL:份[fen4],頓|顿[dun4],次[ci4],餐[can1]",
    "grade": 1
  },
  {
    "id": 456,
    "traditional": "早上",
    "simplified": "早上",
    "reading": "zǎoshang",
    "meaning": "early morning, CL:個|个[ge4]",
    "grade": 1
  },
  {
    "id": 457,
    "traditional": "怎麼",
    "simplified": "怎么",
    "reading": "zěnme",
    "meaning": "how",
    "grade": 1
  },
  {
    "id": 458,
    "traditional": "站",
    "simplified": "站",
    "reading": "zhàn",
    "meaning": "to stand",
    "grade": 1
  },
  {
    "id": 459,
    "traditional": "找",
    "simplified": "找",
    "reading": "zhǎo",
    "meaning": "look for, seek, call on, approach",
    "grade": 1
  },
  {
    "id": 460,
    "traditional": "找到",
    "simplified": "找到",
    "reading": "zhǎodào",
    "meaning": "light upon, find, discover, run_down, founded, regain, strike, come upon, come across, run down, trace, turn_up, happen upon, get, chance on, chance upon",
    "grade": 1
  },
  {
    "id": 461,
    "traditional": "這邊",
    "simplified": "这边",
    "reading": "zhèbiān",
    "meaning": "this side, here",
    "grade": 1
  },
  {
    "id": 462,
    "traditional": "這",
    "simplified": "这",
    "reading": "zhè",
    "meaning": "now, this, det.: this, it",
    "grade": 1
  },
  {
    "id": 463,
    "traditional": "這裡",
    "simplified": "这里",
    "reading": "zhèlǐ",
    "meaning": "this place, there, here",
    "grade": 1
  },
  {
    "id": 464,
    "traditional": "這兒",
    "simplified": "这儿",
    "reading": "zhèr",
    "meaning": "now, here",
    "grade": 1
  },
  {
    "id": 465,
    "traditional": "這些",
    "simplified": "这些",
    "reading": "zhèxiē",
    "meaning": "det.: these",
    "grade": 1
  },
  {
    "id": 466,
    "traditional": "著",
    "simplified": "着",
    "reading": "zhe",
    "meaning": "[aspect]",
    "grade": 1
  },
  {
    "id": 467,
    "traditional": "真",
    "simplified": "真",
    "reading": "zhēn",
    "meaning": "really, truly, indeed, clearly",
    "grade": 1
  },
  {
    "id": 468,
    "traditional": "真的",
    "simplified": "真的",
    "reading": "zhēn de",
    "meaning": "really, truly, indeed",
    "grade": 1
  },
  {
    "id": 469,
    "traditional": "正",
    "simplified": "正",
    "reading": "zhèng",
    "meaning": "precisely, punctually",
    "grade": 1
  },
  {
    "id": 470,
    "traditional": "正在",
    "simplified": "正在",
    "reading": "zhèngzài",
    "meaning": "course of, be, be in process",
    "grade": 1
  },
  {
    "id": 471,
    "traditional": "知道",
    "simplified": "知道",
    "reading": "zhīdao",
    "meaning": "tell, have, ken, understand, infer, wis, learn, cognize, gather, know, be aware of, wise up, savvy, mind, realize",
    "grade": 1
  },
  {
    "id": 472,
    "traditional": "知識",
    "simplified": "知识",
    "reading": "zhīshi",
    "meaning": "lear, know-how, understanding, ken, instruction, science, sophistication, consciousness, light, awareness, cognizance, knowledge, lore, mastery, know, cognition, acquaintanceship, mind, intellect, knowingness, equipment",
    "grade": 1
  },
  {
    "id": 473,
    "traditional": "中國",
    "simplified": "中国",
    "reading": "Zhōngguó",
    "meaning": "Cathay, China, china",
    "grade": 1
  },
  {
    "id": 474,
    "traditional": "中",
    "simplified": "中",
    "reading": "zhōng",
    "meaning": "center, middle, interior",
    "grade": 1
  },
  {
    "id": 475,
    "traditional": "中間",
    "simplified": "中间",
    "reading": "zhōngjiān",
    "meaning": "center, middle",
    "grade": 1
  },
  {
    "id": 476,
    "traditional": "中文",
    "simplified": "中文",
    "reading": "Zhōngwén",
    "meaning": "Chinese, Ch. (written) language, Ch. language, Sinitic",
    "grade": 1
  },
  {
    "id": 477,
    "traditional": "中午",
    "simplified": "中午",
    "reading": "zhōngwǔ",
    "meaning": "noon, midday, CL:個|个[ge4]",
    "grade": 1
  },
  {
    "id": 478,
    "traditional": "中學",
    "simplified": "中学",
    "reading": "zhōngxué",
    "meaning": "middle_school, grammar_school, secondary school, Ch. learning, middle school, high_school, grammar school",
    "grade": 1
  },
  {
    "id": 479,
    "traditional": "中學生",
    "simplified": "中学生",
    "reading": "zhōngxuéshēng",
    "meaning": "middle-school student, high school student",
    "grade": 1
  },
  {
    "id": 480,
    "traditional": "重",
    "simplified": "重",
    "reading": "zhòng",
    "meaning": "heavy, weighty, important, considerable in amount/value",
    "grade": 1
  },
  {
    "id": 481,
    "traditional": "重要",
    "simplified": "重要",
    "reading": "zhòngyào",
    "meaning": "important, significant, major",
    "grade": 1
  },
  {
    "id": 482,
    "traditional": "住",
    "simplified": "住",
    "reading": "zhù",
    "meaning": "inhabit, cease, stay, visit, shack, dwell, stop, people, live, reside, populate, dig",
    "grade": 1
  },
  {
    "id": 483,
    "traditional": "準備",
    "simplified": "准备",
    "reading": "zhǔnbèi",
    "meaning": "prepare, get ready",
    "grade": 1
  },
  {
    "id": 484,
    "traditional": "桌子",
    "simplified": "桌子",
    "reading": "zhuōzi",
    "meaning": "mahogany, table, desk",
    "grade": 1
  },
  {
    "id": 485,
    "traditional": "字",
    "simplified": "字",
    "reading": "zì",
    "meaning": "graphic symbol, writing, pronunciation (of a word), form of a written character, receipt, printing type, script, name taken at the age of 20, courtesy name, wordage, character, grapheme, contract, scripts, symbolic representation, style of handwriting, word",
    "grade": 1
  },
  {
    "id": 486,
    "traditional": "子",
    "simplified": "子",
    "reading": "zi",
    "meaning": "son, child, seed, egg, small thing, 1st earthly branch: 11 p.m.-1 a.m., midnight, 11th solar month (7th December to 5th January), year of the Rat, Viscount, fourth of five orders of nobility 五等爵位[wu3 deng3 jue2 wei4], ancient Chinese compass point: 0° (north), (noun suffix)",
    "grade": 1
  },
  {
    "id": 487,
    "traditional": "走",
    "simplified": "走",
    "reading": "zǒu",
    "meaning": "scram, travel, foot, go away, evaporate, ankle, waltz, step, bugger off, etc., go through, leave, leak, run, buzz off, leg, pad, fork, visit, go, escape, lose flavor, work, call on, strike, sashay, trace, pursue, gang, lose shape/etc., get_along, come_along, walk, hoof it, lose flavor/shape/etc., leg it, let out, shape, drive, cover, take, get, hoof, move",
    "grade": 1
  },
  {
    "id": 488,
    "traditional": "最",
    "simplified": "最",
    "reading": "zuì",
    "meaning": "most, to the highest degree, much, superlatively",
    "grade": 1
  },
  {
    "id": 489,
    "traditional": "走路",
    "simplified": "走路",
    "reading": "zǒu lù",
    "meaning": "go away, stroam, go on foot, walk",
    "grade": 1
  },
  {
    "id": 490,
    "traditional": "最好",
    "simplified": "最好",
    "reading": "zuìhǎo",
    "meaning": "had better, it would be best",
    "grade": 1
  },
  {
    "id": 491,
    "traditional": "最後",
    "simplified": "最后",
    "reading": "zuìhòu",
    "meaning": "final, last, finally, ultimate",
    "grade": 1
  },
  {
    "id": 492,
    "traditional": "昨天",
    "simplified": "昨天",
    "reading": "zuótiān",
    "meaning": "yesterday",
    "grade": 1
  },
  {
    "id": 493,
    "traditional": "左邊",
    "simplified": "左边",
    "reading": "zuǒbian",
    "meaning": "left, the left side, to the left of",
    "grade": 1
  },
  {
    "id": 494,
    "traditional": "左",
    "simplified": "左",
    "reading": "zuǒ",
    "meaning": "left side, east, the Left",
    "grade": 1
  },
  {
    "id": 495,
    "traditional": "坐",
    "simplified": "坐",
    "reading": "zuò",
    "meaning": "sit, take seat",
    "grade": 1
  },
  {
    "id": 496,
    "traditional": "坐下",
    "simplified": "坐下",
    "reading": "zuòxià",
    "meaning": "subside, sit, sit down, sink, sit_down",
    "grade": 1
  },
  {
    "id": 497,
    "traditional": "做",
    "simplified": "做",
    "reading": "zuò",
    "meaning": "make, do",
    "grade": 1
  },
  {
    "id": 498,
    "traditional": "啊",
    "simplified": "啊",
    "reading": "a",
    "meaning": "particle: used in direct address and exclamation; indicating obviousness/impatience/suggestion; used for confirmation",
    "grade": 2
  },
  {
    "id": 499,
    "traditional": "愛情",
    "simplified": "爱情",
    "reading": "àiqíng",
    "meaning": "god, warmheartedness, affection, love_affair, heart, kindness, tenderness, bosom, love, fondness, romantic love, affectionateness",
    "grade": 2
  },
  {
    "id": 500,
    "traditional": "安靜",
    "simplified": "安静",
    "reading": "ānjìng",
    "meaning": "lull, quieten, quiesce, dummy, quiet down, quiet, hush, pipe down, peaceful",
    "grade": 2
  },
  {
    "id": 501,
    "traditional": "安全",
    "simplified": "安全",
    "reading": "ānquán",
    "meaning": "safe, secure",
    "grade": 2
  },
  {
    "id": 502,
    "traditional": "愛人",
    "simplified": "爱人",
    "reading": "àiren",
    "meaning": "spouse (PRC), lover (non-PRC), CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 503,
    "traditional": "班長",
    "simplified": "班长",
    "reading": "bānzhǎng",
    "meaning": "monitor, (work) team leader, squad leader, team leader, class monitor",
    "grade": 2
  },
  {
    "id": 504,
    "traditional": "辦",
    "simplified": "办",
    "reading": "bàn",
    "meaning": "punish (by law), manage, get ... ready, set_up, buy a fair amount of, set up, run, management, handle, tackle, attend to, do, punish, bring ... to justice",
    "grade": 2
  },
  {
    "id": 505,
    "traditional": "辦法",
    "simplified": "办法",
    "reading": "bànfǎ",
    "meaning": "road, expedient, policy, agency, measure, way, method, means",
    "grade": 2
  },
  {
    "id": 506,
    "traditional": "辦公室",
    "simplified": "办公室",
    "reading": "bàngōngshì",
    "meaning": "agency, office, authority, government agency, bureau",
    "grade": 2
  },
  {
    "id": 507,
    "traditional": "白色",
    "simplified": "白色",
    "reading": "báisè",
    "meaning": "White, white, whiteness, White (as symbol of anticommunism), white (color)",
    "grade": 2
  },
  {
    "id": 508,
    "traditional": "幫助",
    "simplified": "帮助",
    "reading": "bāngzhù",
    "meaning": "aid, bridge_over, help, bestead, bring_on, tide_over, serve, support, facilitate, accommodate, carry_through, assist, administer, attend_to, assistance, befriend, further, favour",
    "grade": 2
  },
  {
    "id": 509,
    "traditional": "報名",
    "simplified": "报名",
    "reading": "bào míng",
    "meaning": "register, inscribe, enter, enroll, enrol, enter one's name, sign up, recruit",
    "grade": 2
  },
  {
    "id": 510,
    "traditional": "飽",
    "simplified": "饱",
    "reading": "bǎo",
    "meaning": "to eat till full, satisfied",
    "grade": 2
  },
  {
    "id": 511,
    "traditional": "半夜",
    "simplified": "半夜",
    "reading": "bànyè",
    "meaning": "midnight, in the middle of the night",
    "grade": 2
  },
  {
    "id": 512,
    "traditional": "報紙",
    "simplified": "报纸",
    "reading": "bàozhǐ",
    "meaning": "paper, headliner, newspaper publisher, tabloid, copyreader, newspaper, rag, courant, newsprint, sheet, gazette, dailies",
    "grade": 2
  },
  {
    "id": 513,
    "traditional": "北方",
    "simplified": "北方",
    "reading": "běifāng",
    "meaning": "norland, septentrion, compass north, northern part of country, northernness, northern, magnetic north, northward, north, the North, the northern part of the country",
    "grade": 2
  },
  {
    "id": 514,
    "traditional": "比如說",
    "simplified": "比如说",
    "reading": "bǐrúshuō",
    "meaning": "For example",
    "grade": 2
  },
  {
    "id": 515,
    "traditional": "比如",
    "simplified": "比如",
    "reading": "bǐrú",
    "meaning": "for example, for instance, such as",
    "grade": 2
  },
  {
    "id": 516,
    "traditional": "筆記",
    "simplified": "笔记",
    "reading": "bǐjì",
    "meaning": "short sketches, adversaria, notes, note",
    "grade": 2
  },
  {
    "id": 517,
    "traditional": "筆",
    "simplified": "笔",
    "reading": "bǐ",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 518,
    "traditional": "背",
    "simplified": "背",
    "reading": "bèi",
    "meaning": "learn by heart, recite from memory",
    "grade": 2
  },
  {
    "id": 519,
    "traditional": "筆記本",
    "simplified": "笔记本",
    "reading": "bǐjìběn",
    "meaning": "notebook (stationery), CL:本[ben3], notebook (computing)",
    "grade": 2
  },
  {
    "id": 520,
    "traditional": "邊",
    "simplified": "边",
    "reading": "biān",
    "meaning": "border, boundary",
    "grade": 2
  },
  {
    "id": 521,
    "traditional": "變",
    "simplified": "变",
    "reading": "biàn",
    "meaning": "change, transform",
    "grade": 2
  },
  {
    "id": 522,
    "traditional": "必須",
    "simplified": "必须",
    "reading": "bìxū",
    "meaning": "must",
    "grade": 2
  },
  {
    "id": 523,
    "traditional": "遍",
    "simplified": "遍",
    "reading": "biàn",
    "meaning": "m.[activity]",
    "grade": 2
  },
  {
    "id": 524,
    "traditional": "變成",
    "simplified": "变成",
    "reading": "biàn chéng",
    "meaning": "grow_into, change into, go, form, grow into, change state, develop into, turn to, fall, turn_into, be, turn, become, turn into, grow, run, get",
    "grade": 2
  },
  {
    "id": 525,
    "traditional": "不錯",
    "simplified": "不错",
    "reading": "bùcuò",
    "meaning": "not bad, pretty good;correct, right",
    "grade": 2
  },
  {
    "id": 526,
    "traditional": "不夠",
    "simplified": "不够",
    "reading": "bùgòu",
    "meaning": "not enough, insufficient, inadequate",
    "grade": 2
  },
  {
    "id": 527,
    "traditional": "不但",
    "simplified": "不但",
    "reading": "bùdàn",
    "meaning": "conj.: not only",
    "grade": 2
  },
  {
    "id": 528,
    "traditional": "不過",
    "simplified": "不过",
    "reading": "bùguò",
    "meaning": "conj.: but, however, only",
    "grade": 2
  },
  {
    "id": 529,
    "traditional": "不太",
    "simplified": "不太",
    "reading": "bù tài",
    "meaning": "Not too",
    "grade": 2
  },
  {
    "id": 530,
    "traditional": "錶",
    "simplified": "表",
    "reading": "biǎo",
    "meaning": "table, form, list",
    "grade": 2
  },
  {
    "id": 531,
    "traditional": "不好意思",
    "simplified": "不好意思",
    "reading": "bù hǎoyìsi",
    "meaning": "embarrassed, be ill at ease, find it embarrassing, feel embarrassed",
    "grade": 2
  },
  {
    "id": 532,
    "traditional": "不滿",
    "simplified": "不满",
    "reading": "bùmǎn",
    "meaning": "resentful, dissatisfied",
    "grade": 2
  },
  {
    "id": 533,
    "traditional": "不要",
    "simplified": "不要",
    "reading": "bùyào",
    "meaning": "never, noway, don't want, don't (do sth.)",
    "grade": 2
  },
  {
    "id": 534,
    "traditional": "不如",
    "simplified": "不如",
    "reading": "bùrú",
    "meaning": "be unequal/inferior to",
    "grade": 2
  },
  {
    "id": 535,
    "traditional": "不久",
    "simplified": "不久",
    "reading": "bùjiǔ",
    "meaning": "not long (after), before too long, soon, soon after",
    "grade": 2
  },
  {
    "id": 536,
    "traditional": "不少",
    "simplified": "不少",
    "reading": "bùshǎo",
    "meaning": "det.: a lot of",
    "grade": 2
  },
  {
    "id": 537,
    "traditional": "不同",
    "simplified": "不同",
    "reading": "bùtóng",
    "meaning": "different",
    "grade": 2
  },
  {
    "id": 538,
    "traditional": "不行",
    "simplified": "不行",
    "reading": "bù xíng",
    "meaning": "won't do, be out of the question, be no good, not work, not be capable",
    "grade": 2
  },
  {
    "id": 539,
    "traditional": "不一定",
    "simplified": "不一定",
    "reading": "bùyīdìng",
    "meaning": "not sure, maybe",
    "grade": 2
  },
  {
    "id": 540,
    "traditional": "不一會兒",
    "simplified": "不一会儿",
    "reading": "bù yīhuǐr5",
    "meaning": "Take a short while",
    "grade": 2
  },
  {
    "id": 541,
    "traditional": "部分",
    "simplified": "部分",
    "reading": "bùfen",
    "meaning": "partial, department, section, partialness, slice, pars, part, det.: part, percent, partage, sphere, component, divvy, segment, portion, fraction, sector, chapter, share, percentage, piece, division, sectional, parcel",
    "grade": 2
  },
  {
    "id": 542,
    "traditional": "菜單",
    "simplified": "菜单",
    "reading": "càidān",
    "meaning": "menu, CL:份[fen4],張|张[zhang1]",
    "grade": 2
  },
  {
    "id": 543,
    "traditional": "參觀",
    "simplified": "参观",
    "reading": "cānguān",
    "meaning": "visit, tour",
    "grade": 2
  },
  {
    "id": 544,
    "traditional": "才",
    "simplified": "才",
    "reading": "cái",
    "meaning": "then and only then, just now, only (before a number)",
    "grade": 2
  },
  {
    "id": 545,
    "traditional": "參加",
    "simplified": "参加",
    "reading": "cānjiā",
    "meaning": "join, attend, take part in, give (advice/etc.)",
    "grade": 2
  },
  {
    "id": 546,
    "traditional": "草地",
    "simplified": "草地",
    "reading": "cǎodì",
    "meaning": "lawn, meadow, sod, turf, CL:片[pian4]",
    "grade": 2
  },
  {
    "id": 547,
    "traditional": "層",
    "simplified": "层",
    "reading": "céng",
    "meaning": "m.[proximation]",
    "grade": 2
  },
  {
    "id": 548,
    "traditional": "草",
    "simplified": "草",
    "reading": "cǎo",
    "meaning": "female (of animals), herb, herbage, female, careless, hasty, grass, rough, wort, draft, straw",
    "grade": 2
  },
  {
    "id": 549,
    "traditional": "差不多",
    "simplified": "差不多",
    "reading": "chàbuduō",
    "meaning": "good enough",
    "grade": 2
  },
  {
    "id": 550,
    "traditional": "長",
    "simplified": "长",
    "reading": "zhǎng",
    "meaning": "long, be strong/good (in/at)",
    "grade": 2
  },
  {
    "id": 551,
    "traditional": "查",
    "simplified": "查",
    "reading": "chá",
    "meaning": "look into, consult, look up, search, investigate, consultation, audit, examination, investigation, check, examine",
    "grade": 2
  },
  {
    "id": 552,
    "traditional": "常見",
    "simplified": "常见",
    "reading": "chángjiàn",
    "meaning": "commonly seen",
    "grade": 2
  },
  {
    "id": 553,
    "traditional": "常用",
    "simplified": "常用",
    "reading": "chángyòng",
    "meaning": "in common usage",
    "grade": 2
  },
  {
    "id": 554,
    "traditional": "場",
    "simplified": "场",
    "reading": "chǎng",
    "meaning": "m.[event]",
    "grade": 2
  },
  {
    "id": 555,
    "traditional": "超過",
    "simplified": "超过",
    "reading": "chāo guò",
    "meaning": "overpass, overreach, outwalk, outstrip, overstep, outmarch, distance, pass, overstride, outpace, overtop, surpass, crown, exceed, overtake, preponderate, overrun, top, outrun, go_over, transcend, outrange, transgress, go past, break, excess, overhaul, overshine",
    "grade": 2
  },
  {
    "id": 556,
    "traditional": "超市",
    "simplified": "超市",
    "reading": "chāoshì",
    "meaning": "supermarket, abbr. for 超級市場|超级市场, CL:家[jia1]",
    "grade": 2
  },
  {
    "id": 557,
    "traditional": "車輛",
    "simplified": "车辆",
    "reading": "chēliàng",
    "meaning": "voiture, vehicular traffic, vehicle, cars, vehicle traffic, car, vehicles, carriage, rolling_stock, equipment",
    "grade": 2
  },
  {
    "id": 558,
    "traditional": "成績",
    "simplified": "成绩",
    "reading": "chéngjì",
    "meaning": "execution, carrying into action, achievement, result, showing, carrying out, performance, grade, success, doing, batting average",
    "grade": 2
  },
  {
    "id": 559,
    "traditional": "稱",
    "simplified": "称",
    "reading": "chēng",
    "meaning": "call",
    "grade": 2
  },
  {
    "id": 560,
    "traditional": "重複",
    "simplified": "重复",
    "reading": "chóngfù",
    "meaning": "repeat, duplicate",
    "grade": 2
  },
  {
    "id": 561,
    "traditional": "重新",
    "simplified": "重新",
    "reading": "chóngxīn",
    "meaning": "over_again, newly, afresh, anew, again",
    "grade": 2
  },
  {
    "id": 562,
    "traditional": "出發",
    "simplified": "出发",
    "reading": "chūfā",
    "meaning": "depart, get_off, proceed, take_the_road, part, set out, start from, start out, sidetrack, start/proceed from, stray, take the road, sally out, sally_forth, proceed from, set_off, sally forth, take off, start, set forth, set off, digress, straggle, start off, move",
    "grade": 2
  },
  {
    "id": 563,
    "traditional": "成為",
    "simplified": "成为",
    "reading": "chéngwéi",
    "meaning": "find, join, comprise, turn to, commemorate, sample, entomb, come_up, commence, get hold, learn, add up, necessitate, run, make up, constitute, make, form, come, amount, represent, fall, presage, change, be, turn, turn into, become, line up, come up, shape, portend, foretell, get",
    "grade": 2
  },
  {
    "id": 564,
    "traditional": "成",
    "simplified": "成",
    "reading": "chéng",
    "meaning": "become, turn into",
    "grade": 2
  },
  {
    "id": 565,
    "traditional": "出國",
    "simplified": "出国",
    "reading": "chū guó",
    "meaning": "go abroad (from one's own country)",
    "grade": 2
  },
  {
    "id": 566,
    "traditional": "出口",
    "simplified": "出口",
    "reading": "chū kǒu",
    "meaning": "export",
    "grade": 2
  },
  {
    "id": 567,
    "traditional": "出門",
    "simplified": "出门",
    "reading": "chū mén",
    "meaning": "go_out, go on journey, go out, go on a journey, be away from home",
    "grade": 2
  },
  {
    "id": 568,
    "traditional": "出生",
    "simplified": "出生",
    "reading": "chūshēng",
    "meaning": "be born",
    "grade": 2
  },
  {
    "id": 569,
    "traditional": "出現",
    "simplified": "出现",
    "reading": "chūxiàn",
    "meaning": "appear, arise, emerge",
    "grade": 2
  },
  {
    "id": 570,
    "traditional": "出院",
    "simplified": "出院",
    "reading": "chū yuàn",
    "meaning": "to leave hospital, to be discharged from hospital",
    "grade": 2
  },
  {
    "id": 571,
    "traditional": "出租",
    "simplified": "出租",
    "reading": "chūzū",
    "meaning": "hire, let, rent",
    "grade": 2
  },
  {
    "id": 572,
    "traditional": "出租車",
    "simplified": "出租车",
    "reading": "chūzūchē",
    "meaning": "taxi, (Taiwan) rental car",
    "grade": 2
  },
  {
    "id": 573,
    "traditional": "吹",
    "simplified": "吹",
    "reading": "chuī",
    "meaning": "fall through (of plans), wind, fall through, tout, play, gasconade, shoot a line, blow, break up, chuff, vaunt, boast, brag, gas, bluster, talk big, break off, toot, swash, play (wind instruments), huff, puff, land",
    "grade": 2
  },
  {
    "id": 574,
    "traditional": "春節",
    "simplified": "春节",
    "reading": "Chūn Jié",
    "meaning": "Spring Festival (Chinese New Year)",
    "grade": 2
  },
  {
    "id": 575,
    "traditional": "船",
    "simplified": "船",
    "reading": "chuán",
    "meaning": "bottom, body, ship, catcher, bow, bark, boat, shipboard, prow, watercraft, craft, drone, vessel, keel, sailer",
    "grade": 2
  },
  {
    "id": 576,
    "traditional": "春天",
    "simplified": "春天",
    "reading": "chūntiān",
    "meaning": "spring (season), CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 577,
    "traditional": "詞典",
    "simplified": "词典",
    "reading": "cídiǎn",
    "meaning": "lexicon, dictionary",
    "grade": 2
  },
  {
    "id": 578,
    "traditional": "詞",
    "simplified": "词",
    "reading": "cí",
    "meaning": "vocable, words, phrase, a kind of poetry originating in Tang dyn, statement, a form of classical poetry, part of speech, speech, Ci, Chinese poetry, term, word",
    "grade": 2
  },
  {
    "id": 579,
    "traditional": "詞語",
    "simplified": "词语",
    "reading": "cíyǔ",
    "meaning": "word (general term including monosyllables through to short phrases), term (e.g. technical term), expression",
    "grade": 2
  },
  {
    "id": 580,
    "traditional": "答應",
    "simplified": "答应",
    "reading": "dāying",
    "meaning": "agree, promise",
    "grade": 2
  },
  {
    "id": 581,
    "traditional": "打工",
    "simplified": "打工",
    "reading": "dǎ gōng",
    "meaning": "work part-time while studying in college, work as an employee, etc.",
    "grade": 2
  },
  {
    "id": 582,
    "traditional": "從小",
    "simplified": "从小",
    "reading": "cóngxiǎo",
    "meaning": "from childhood, as a child",
    "grade": 2
  },
  {
    "id": 583,
    "traditional": "打算",
    "simplified": "打算",
    "reading": "dǎsuan",
    "meaning": "mean, specify, plan, think, intention, propose, design, purpose, contemplate, allot, destine, calculate, intend, designate, be after",
    "grade": 2
  },
  {
    "id": 584,
    "traditional": "打印",
    "simplified": "打印",
    "reading": "dǎyìn",
    "meaning": "to print, to seal, to stamp",
    "grade": 2
  },
  {
    "id": 585,
    "traditional": "大部分",
    "simplified": "大部分",
    "reading": "dàbùfen",
    "meaning": "det.: most of, the majority of",
    "grade": 2
  },
  {
    "id": 586,
    "traditional": "大大",
    "simplified": "大大",
    "reading": "dàda",
    "meaning": "greatly, enormously, (dialect) dad, uncle",
    "grade": 2
  },
  {
    "id": 587,
    "traditional": "大多數",
    "simplified": "大多数",
    "reading": "dàduōshù",
    "meaning": "det.: most of, the majority of",
    "grade": 2
  },
  {
    "id": 588,
    "traditional": "大家",
    "simplified": "大家",
    "reading": "dàjiā",
    "meaning": "sundry, great master, rich and influential family, pontiff, all of us, everyone, authority, master, distinguished family",
    "grade": 2
  },
  {
    "id": 589,
    "traditional": "大海",
    "simplified": "大海",
    "reading": "dǎhǎi",
    "meaning": "seaway, sea, the open sea",
    "grade": 2
  },
  {
    "id": 590,
    "traditional": "大量",
    "simplified": "大量",
    "reading": "dàliàng",
    "meaning": "det.: any numer of, a great many",
    "grade": 2
  },
  {
    "id": 591,
    "traditional": "大門",
    "simplified": "大门",
    "reading": "dàmén",
    "meaning": "barndoor, portal, main entrance, front_door, gate, door, entry, main door/gate, front door, main entrance/door/gate, entrance",
    "grade": 2
  },
  {
    "id": 592,
    "traditional": "大人",
    "simplified": "大人",
    "reading": "dàren",
    "meaning": "grownup, adult, milord, respectful salutation for one's seniors/, grown-up, respectful salutation for one's parents, Your Excellency, panjandrum, Highness, respectful salutation for one's parents/seniors/etc., Tuan, Bahadur, bahadur, sahib",
    "grade": 2
  },
  {
    "id": 593,
    "traditional": "大聲",
    "simplified": "大声",
    "reading": "dàshēng",
    "meaning": "loud, loud voice",
    "grade": 2
  },
  {
    "id": 594,
    "traditional": "大衣",
    "simplified": "大衣",
    "reading": "dàyī",
    "meaning": "overcoat, topcoat, cloak, CL:件[jian4]",
    "grade": 2
  },
  {
    "id": 595,
    "traditional": "大自然",
    "simplified": "大自然",
    "reading": "dàzìrán",
    "meaning": "wild, earth, nature, natural state, state of nature",
    "grade": 2
  },
  {
    "id": 596,
    "traditional": "帶",
    "simplified": "带",
    "reading": "dài",
    "meaning": "look after, raise",
    "grade": 2
  },
  {
    "id": 597,
    "traditional": "帶來",
    "simplified": "带来",
    "reading": "dài lái",
    "meaning": "bring, redound, impart, bring_on, bring on, express, fetch, lend, convey, contribute, bring about, bestow, carry, bring oneself, add, usher, incur, land",
    "grade": 2
  },
  {
    "id": 598,
    "traditional": "單位",
    "simplified": "单位",
    "reading": "dānwèi",
    "meaning": "department, unit, denomination, module, building block, unit of measurement, monad, unit (in measurement or organization)",
    "grade": 2
  },
  {
    "id": 599,
    "traditional": "大小",
    "simplified": "大小",
    "reading": "dàxiǎo",
    "meaning": "calibre, measurement, admeasurement, bulk, proportion, magnitude, extent, degree of seniority, dimension, girth, size, adults and children",
    "grade": 2
  },
  {
    "id": 600,
    "traditional": "但",
    "simplified": "但",
    "reading": "dàn",
    "meaning": "conj.: but, yet, still, nevertheless, only, merely",
    "grade": 2
  },
  {
    "id": 601,
    "traditional": "但是",
    "simplified": "但是",
    "reading": "dànshì",
    "meaning": "conj.: but, however, yet, still",
    "grade": 2
  },
  {
    "id": 602,
    "traditional": "蛋",
    "simplified": "蛋",
    "reading": "dàn",
    "meaning": "clump, egg, lump, bunch, an egg-shaped thing",
    "grade": 2
  },
  {
    "id": 603,
    "traditional": "當",
    "simplified": "当",
    "reading": "dāng",
    "meaning": "when, while",
    "grade": 2
  },
  {
    "id": 604,
    "traditional": "當時",
    "simplified": "当时",
    "reading": "dāngshí",
    "meaning": "then, at that time, while, at once, right away",
    "grade": 2
  },
  {
    "id": 605,
    "traditional": "到處",
    "simplified": "到处",
    "reading": "dàochù",
    "meaning": "universally, everywhere, everyplace, far and near, about, around, far and wide, all_over, high_and_low, all over, throughout, high and low, far_and_near, passim, abroad, at all places",
    "grade": 2
  },
  {
    "id": 606,
    "traditional": "道",
    "simplified": "道",
    "reading": "dào",
    "meaning": "say, speak, talk, think, suppose",
    "grade": 2
  },
  {
    "id": 607,
    "traditional": "道路",
    "simplified": "道路",
    "reading": "dàolù",
    "meaning": "track, path, runway, steps, route, course, toby, road, thoroughfare, way",
    "grade": 2
  },
  {
    "id": 608,
    "traditional": "倒",
    "simplified": "倒",
    "reading": "dào",
    "meaning": "actually",
    "grade": 2
  },
  {
    "id": 609,
    "traditional": "道理",
    "simplified": "道理",
    "reading": "dàoli",
    "meaning": "thread, principle, the right way, theory, reason, argument, sense, order, truth, rationality",
    "grade": 2
  },
  {
    "id": 610,
    "traditional": "得",
    "simplified": "得",
    "reading": "de",
    "meaning": "should be, need",
    "grade": 2
  },
  {
    "id": 611,
    "traditional": "得出",
    "simplified": "得出",
    "reading": "dé chū",
    "meaning": "to obtain (results), to arrive at (a conclusion)",
    "grade": 2
  },
  {
    "id": 612,
    "traditional": "的話",
    "simplified": "的话",
    "reading": "dehuà",
    "meaning": "conj.: if",
    "grade": 2
  },
  {
    "id": 613,
    "traditional": "等",
    "simplified": "等",
    "reading": "děng",
    "meaning": "conj.: and so on, etc",
    "grade": 2
  },
  {
    "id": 614,
    "traditional": "等到",
    "simplified": "等到",
    "reading": "děngdào",
    "meaning": "until",
    "grade": 2
  },
  {
    "id": 615,
    "traditional": "燈",
    "simplified": "灯",
    "reading": "dēng",
    "meaning": "blowlamp, lantern, light source, light, lamp, tube, valve, burner",
    "grade": 2
  },
  {
    "id": 616,
    "traditional": "低",
    "simplified": "低",
    "reading": "dī",
    "meaning": "let droop, hang down, lower",
    "grade": 2
  },
  {
    "id": 617,
    "traditional": "等於",
    "simplified": "等于",
    "reading": "děngyú",
    "meaning": "be equal to, tantamount to, equal, be equal, amount, reach, be, be equivalent to",
    "grade": 2
  },
  {
    "id": 618,
    "traditional": "地鐵",
    "simplified": "地铁",
    "reading": "dìtiě",
    "meaning": "subway, metro",
    "grade": 2
  },
  {
    "id": 619,
    "traditional": "地球",
    "simplified": "地球",
    "reading": "dìqiú",
    "meaning": "the earth/globe, globe, world, Earth, ball, terrene, earth, the globe, the earth",
    "grade": 2
  },
  {
    "id": 620,
    "traditional": "地鐵站",
    "simplified": "地铁站",
    "reading": "dìtiězhàn",
    "meaning": "subway station",
    "grade": 2
  },
  {
    "id": 621,
    "traditional": "店",
    "simplified": "店",
    "reading": "diàn",
    "meaning": "shop, store, inn",
    "grade": 2
  },
  {
    "id": 622,
    "traditional": "點頭",
    "simplified": "点头",
    "reading": "diǎntóu",
    "meaning": "take a bow, nod one's head, noddle, nod",
    "grade": 2
  },
  {
    "id": 623,
    "traditional": "東北",
    "simplified": "东北",
    "reading": "dōngběi",
    "meaning": "Northeast China, Manchuria",
    "grade": 2
  },
  {
    "id": 624,
    "traditional": "東方",
    "simplified": "东方",
    "reading": "dōngfāng",
    "meaning": "oriental, dawning, northeast, eastward, E, Orient, orient, orientation, the Orient, Surname, sunrise, eastern, eastern hemisphere, Dongfang, due east, East, the East, east, the east",
    "grade": 2
  },
  {
    "id": 625,
    "traditional": "冬天",
    "simplified": "冬天",
    "reading": "dōngtiān",
    "meaning": "winter, CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 626,
    "traditional": "掉",
    "simplified": "掉",
    "reading": "diào",
    "meaning": "drop, reduce, exchange, lose, fall behind, be missing, fall, knock, come off, change, shed, turn",
    "grade": 2
  },
  {
    "id": 627,
    "traditional": "東南",
    "simplified": "东南",
    "reading": "dōngnán",
    "meaning": "sou'-east, Southeast China, southeast, SE",
    "grade": 2
  },
  {
    "id": 628,
    "traditional": "懂得",
    "simplified": "懂得",
    "reading": "dǒngde",
    "meaning": "experience, know, live, understanding, understand, twig, gaum, grasp",
    "grade": 2
  },
  {
    "id": 629,
    "traditional": "動物",
    "simplified": "动物",
    "reading": "dòngwù",
    "meaning": "thing, critter, crittur, zoic, anthropomorphism, animal, deer, animate being, beast, creature, burrow, sire, worker, fauna, brute",
    "grade": 2
  },
  {
    "id": 630,
    "traditional": "動物園",
    "simplified": "动物园",
    "reading": "dòngwùyuán",
    "meaning": "zoological_garden, zoo, zoological garden, menagerie",
    "grade": 2
  },
  {
    "id": 631,
    "traditional": "度",
    "simplified": "度",
    "reading": "dù",
    "meaning": "m.[activity]",
    "grade": 2
  },
  {
    "id": 632,
    "traditional": "讀音",
    "simplified": "读音",
    "reading": "dúyīn",
    "meaning": "pronunciation, literary (rather than colloquial) pronunciation of a Chinese character",
    "grade": 2
  },
  {
    "id": 633,
    "traditional": "短",
    "simplified": "短",
    "reading": "duǎn",
    "meaning": "short, brief",
    "grade": 2
  },
  {
    "id": 634,
    "traditional": "短信",
    "simplified": "短信",
    "reading": "duǎnxìn",
    "meaning": "text message, SMS",
    "grade": 2
  },
  {
    "id": 635,
    "traditional": "段",
    "simplified": "段",
    "reading": "duàn",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 636,
    "traditional": "隊",
    "simplified": "队",
    "reading": "duì",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 637,
    "traditional": "隊長",
    "simplified": "队长",
    "reading": "duìzhǎng",
    "meaning": "captain, chieftain, skipper, police chief, header, headman, commandant, police captain, sirdar, team leader",
    "grade": 2
  },
  {
    "id": 638,
    "traditional": "懂",
    "simplified": "懂",
    "reading": "dǒng",
    "meaning": "have, comprehend, understanding, understand, compass, grasp, get the picture, glom, know, apprehend, twig, savvy, follow, make out, dig",
    "grade": 2
  },
  {
    "id": 639,
    "traditional": "對話",
    "simplified": "对话",
    "reading": "duìhuà",
    "meaning": "dialogue",
    "grade": 2
  },
  {
    "id": 640,
    "traditional": "對面",
    "simplified": "对面",
    "reading": "duìmiàn",
    "meaning": "opposite",
    "grade": 2
  },
  {
    "id": 641,
    "traditional": "多",
    "simplified": "多",
    "reading": "duō",
    "meaning": "many, much, more, more than the correct/required number, excessive, too much, much/far more",
    "grade": 2
  },
  {
    "id": 642,
    "traditional": "多久",
    "simplified": "多久",
    "reading": "duōjiǔ",
    "meaning": "how long?",
    "grade": 2
  },
  {
    "id": 643,
    "traditional": "對",
    "simplified": "对",
    "reading": "duì",
    "meaning": "correct, true, toward",
    "grade": 2
  },
  {
    "id": 644,
    "traditional": "多麼",
    "simplified": "多么",
    "reading": "duōme",
    "meaning": "to what extent, to what degree, however, what, how",
    "grade": 2
  },
  {
    "id": 645,
    "traditional": "多數",
    "simplified": "多数",
    "reading": "duōshù",
    "meaning": "trillions, millions, mort, relative majority, throng, det : majority, prevalence, multitude, lac, Host, host, bulk, horde, raft, million, lakh, rimption, pack, skit, strength, acre, generality, ruck, chunk, number, shoal, multiplicity, billions, preponderance, jillions, zillions, grist, world, majority, meiny, regiment, legion, mass, plurality",
    "grade": 2
  },
  {
    "id": 646,
    "traditional": "多雲",
    "simplified": "多云",
    "reading": "duōyún",
    "meaning": "cloudy (meteorology)",
    "grade": 2
  },
  {
    "id": 647,
    "traditional": "而且",
    "simplified": "而且",
    "reading": "érqiě",
    "meaning": "conj.: furthermore, and",
    "grade": 2
  },
  {
    "id": 648,
    "traditional": "發",
    "simplified": "发",
    "reading": "fā",
    "meaning": "distribute",
    "grade": 2
  },
  {
    "id": 649,
    "traditional": "發現",
    "simplified": "发现",
    "reading": "fāxiàn",
    "meaning": "find, discover",
    "grade": 2
  },
  {
    "id": 650,
    "traditional": "飯館",
    "simplified": "饭馆",
    "reading": "fànguǎn",
    "meaning": "restaurant, CL:家[jia1]",
    "grade": 2
  },
  {
    "id": 651,
    "traditional": "方便",
    "simplified": "方便",
    "reading": "fāngbiàn",
    "meaning": "convenient",
    "grade": 2
  },
  {
    "id": 652,
    "traditional": "方便麵",
    "simplified": "方便面",
    "reading": "fāngbiànmiàn",
    "meaning": "instant noodles",
    "grade": 2
  },
  {
    "id": 653,
    "traditional": "方法",
    "simplified": "方法",
    "reading": "fāngfǎ",
    "meaning": "know-how, plan, attack, system, stepping-stone, manner, method, gateway, idea, measure, way, guise, key, wise, quomodo, plan of attack, tack, machinery, process, avenue, modus, how, means, channel, gate, approach, agency, gamesmanship, mode, medium, sort",
    "grade": 2
  },
  {
    "id": 654,
    "traditional": "方面",
    "simplified": "方面",
    "reading": "fāngmiàn",
    "meaning": "side, aspect, sector, part, light, facet, behalf, way, respect, direction, bearing, field, phase, sphere, angle, hand",
    "grade": 2
  },
  {
    "id": 655,
    "traditional": "放下",
    "simplified": "放下",
    "reading": "fàngxià",
    "meaning": "set_down, set down, lay/put down, lay, lower, lay_down, draw, lay down, dismount, put_down, plank, drop_away, deposit, digress, drop_off, depose, put down, place down",
    "grade": 2
  },
  {
    "id": 656,
    "traditional": "方向",
    "simplified": "方向",
    "reading": "fāngxiang",
    "meaning": "path, set, directional, aspect, tenor, course, lay, exposure, way, range, aim, trend, direction, bearing, orientation, hand, tack, way of life, quarter, channel, line, heading",
    "grade": 2
  },
  {
    "id": 657,
    "traditional": "分",
    "simplified": "分",
    "reading": "fēn",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 658,
    "traditional": "分開",
    "simplified": "分开",
    "reading": "fēn kāi",
    "meaning": "rive, uncouple, unyoke, divaricate, detach, disjoint, cleave, shift, break_up, cut, segregate, isolate, divorce, severalize, unclasp, divide, dissever, disjoin, break, come off, demarcate, fork, sever, sequester, disconnect, part, come away, sunder, break up, keep apart, separate, diverge, branch, set_off, ramify, split, sequestrate, untwine, separation, set apart, parcel",
    "grade": 2
  },
  {
    "id": 659,
    "traditional": "分數",
    "simplified": "分数",
    "reading": "fēnshù",
    "meaning": "point, score, mark, grade, fraction, football score, numeric",
    "grade": 2
  },
  {
    "id": 660,
    "traditional": "放心",
    "simplified": "放心",
    "reading": "fàng xīn",
    "meaning": "disburden, set one's mind at rest, set one's mood at rest, be at ease, feel relieved, rest assured",
    "grade": 2
  },
  {
    "id": 661,
    "traditional": "分鐘",
    "simplified": "分钟",
    "reading": "fēnzhōng",
    "meaning": "m.[standard]",
    "grade": 2
  },
  {
    "id": 662,
    "traditional": "服務",
    "simplified": "服务",
    "reading": "fúwù",
    "meaning": "be in the service of, serve",
    "grade": 2
  },
  {
    "id": 663,
    "traditional": "複習",
    "simplified": "复习",
    "reading": "fùxí",
    "meaning": "to review, revision, variant of 復習|复习[fu4 xi2]",
    "grade": 2
  },
  {
    "id": 664,
    "traditional": "份",
    "simplified": "份",
    "reading": "fèn",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 665,
    "traditional": "封",
    "simplified": "封",
    "reading": "fēng",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 666,
    "traditional": "改",
    "simplified": "改",
    "reading": "gǎi",
    "meaning": "alteration, rectification, transformation, change, correct, put ... right, transform, rectify, revision, switch over to, ameliorate, revise, put right, amend, correction, gybe, alter",
    "grade": 2
  },
  {
    "id": 667,
    "traditional": "改變",
    "simplified": "改变",
    "reading": "gǎibiàn",
    "meaning": "change, transform",
    "grade": 2
  },
  {
    "id": 668,
    "traditional": "該",
    "simplified": "该",
    "reading": "gāi",
    "meaning": "det.: this, that",
    "grade": 2
  },
  {
    "id": 669,
    "traditional": "感到",
    "simplified": "感到",
    "reading": "gǎndào",
    "meaning": "feel, sense",
    "grade": 2
  },
  {
    "id": 670,
    "traditional": "感動",
    "simplified": "感动",
    "reading": "gǎndòng",
    "meaning": "be touched, be moved, affect, feel, stir, thrill, melt, magnetize, inspire, touch, reach, move",
    "grade": 2
  },
  {
    "id": 671,
    "traditional": "乾杯",
    "simplified": "干杯",
    "reading": "gān bēi",
    "meaning": "to drink a toast, Cheers! (proposing a toast), Here's to you!, Bottoms up!, lit. dry cup",
    "grade": 2
  },
  {
    "id": 672,
    "traditional": "感覺",
    "simplified": "感觉",
    "reading": "gǎnjué",
    "meaning": "sense perception, feeling",
    "grade": 2
  },
  {
    "id": 673,
    "traditional": "幹活兒",
    "simplified": "干活儿",
    "reading": "gànhuór5",
    "meaning": "to work, manual labor",
    "grade": 2
  },
  {
    "id": 674,
    "traditional": "剛",
    "simplified": "刚",
    "reading": "gāng",
    "meaning": "exactly, only a short while ago, just now, barely, only just, just",
    "grade": 2
  },
  {
    "id": 675,
    "traditional": "剛纔",
    "simplified": "刚才",
    "reading": "gāngcái",
    "meaning": "(just) a moment ago",
    "grade": 2
  },
  {
    "id": 676,
    "traditional": "剛剛",
    "simplified": "刚刚",
    "reading": "gānggāng",
    "meaning": "just now, scarcely, fresh, only, exactly, a moment ago, hardly, but, just",
    "grade": 2
  },
  {
    "id": 677,
    "traditional": "高級",
    "simplified": "高级",
    "reading": "gāojí",
    "meaning": "high-level, high-class, quality",
    "grade": 2
  },
  {
    "id": 678,
    "traditional": "感謝",
    "simplified": "感谢",
    "reading": "gǎnxiè",
    "meaning": "thank, be grateful",
    "grade": 2
  },
  {
    "id": 679,
    "traditional": "高中",
    "simplified": "高中",
    "reading": "gāozhōng",
    "meaning": "senior middle school, senior high school, senior_high_school",
    "grade": 2
  },
  {
    "id": 680,
    "traditional": "個子",
    "simplified": "个子",
    "reading": "gèzi",
    "meaning": "height, stature, build",
    "grade": 2
  },
  {
    "id": 681,
    "traditional": "更",
    "simplified": "更",
    "reading": "gèng",
    "meaning": "more, still/even more, further, furthermore",
    "grade": 2
  },
  {
    "id": 682,
    "traditional": "公共汽車",
    "simplified": "公共汽车",
    "reading": "gōnggòng qìchē",
    "meaning": "bus, CL:輛|辆[liang4],班[ban1]",
    "grade": 2
  },
  {
    "id": 683,
    "traditional": "公交車",
    "simplified": "公交车",
    "reading": "gōngjiāochē",
    "meaning": "public transport vehicle, town bus, CL:輛|辆[liang4]",
    "grade": 2
  },
  {
    "id": 684,
    "traditional": "公斤",
    "simplified": "公斤",
    "reading": "gōngjīn",
    "meaning": "m.[standard]",
    "grade": 2
  },
  {
    "id": 685,
    "traditional": "公路",
    "simplified": "公路",
    "reading": "gōnglù",
    "meaning": "trunk road, calzada, route, main road, highroad, highway, road, thoroughfare, causeway, chaussee",
    "grade": 2
  },
  {
    "id": 686,
    "traditional": "公平",
    "simplified": "公平",
    "reading": "gōngpíng",
    "meaning": "fair, just, impartial",
    "grade": 2
  },
  {
    "id": 687,
    "traditional": "公里",
    "simplified": "公里",
    "reading": "gōnglǐ",
    "meaning": "m.[standard]",
    "grade": 2
  },
  {
    "id": 688,
    "traditional": "公園",
    "simplified": "公园",
    "reading": "gōngyuán",
    "meaning": "common, green, garden, commons, park",
    "grade": 2
  },
  {
    "id": 689,
    "traditional": "公司",
    "simplified": "公司",
    "reading": "gōngsī",
    "meaning": "place of business, business organization, incorporation, establishment, business concern, office, concern, business establishment, business, enterprise, company, corporation",
    "grade": 2
  },
  {
    "id": 690,
    "traditional": "故事",
    "simplified": "故事",
    "reading": "gùshi",
    "meaning": "spiel, recital, relation, scam, rede, plot, yarn, narration, story, fiction, tale, history, legend, narrative",
    "grade": 2
  },
  {
    "id": 691,
    "traditional": "狗",
    "simplified": "狗",
    "reading": "gǒu",
    "meaning": "dog",
    "grade": 2
  },
  {
    "id": 692,
    "traditional": "夠",
    "simplified": "够",
    "reading": "gòu",
    "meaning": "be enough/sufficient/adequate",
    "grade": 2
  },
  {
    "id": 693,
    "traditional": "故意",
    "simplified": "故意",
    "reading": "gùyì",
    "meaning": "purposive, deliberate, willful, intentional",
    "grade": 2
  },
  {
    "id": 694,
    "traditional": "顧客",
    "simplified": "顾客",
    "reading": "gùkè",
    "meaning": "chap, client, shopper, correspondent, constituency, patronage, trade, market, guest, clientele, newspaperman, newspaperwoman, newswriter, pressman, customer agent, customer, business",
    "grade": 2
  },
  {
    "id": 695,
    "traditional": "關機",
    "simplified": "关机",
    "reading": "guān jī",
    "meaning": "to turn off (a machine or device), to finish shooting a film",
    "grade": 2
  },
  {
    "id": 696,
    "traditional": "關心",
    "simplified": "关心",
    "reading": "guānxīn",
    "meaning": "be concerned about",
    "grade": 2
  },
  {
    "id": 697,
    "traditional": "觀點",
    "simplified": "观点",
    "reading": "guāndiǎn",
    "meaning": "view, aspect, stand, point_of_view, light, eyes, framework, sentiment, bet, standpoint, point of view, eye, viewpoint, slant, perspective, position, angle",
    "grade": 2
  },
  {
    "id": 698,
    "traditional": "廣場",
    "simplified": "广场",
    "reading": "guǎngchǎng",
    "meaning": "mews, agora, public square, concourse, carrefour, piazza, plaza, square, campus, place",
    "grade": 2
  },
  {
    "id": 699,
    "traditional": "廣告",
    "simplified": "广告",
    "reading": "guǎnggào",
    "meaning": "advertisement",
    "grade": 2
  },
  {
    "id": 700,
    "traditional": "過來",
    "simplified": "过来",
    "reading": "guò lai",
    "meaning": "come over/up, up, come over, come up, come_over, come here",
    "grade": 2
  },
  {
    "id": 701,
    "traditional": "過年",
    "simplified": "过年",
    "reading": "guònian",
    "meaning": "celebrate/spend New Year",
    "grade": 2
  },
  {
    "id": 702,
    "traditional": "國際",
    "simplified": "国际",
    "reading": "guójì",
    "meaning": "international",
    "grade": 2
  },
  {
    "id": 703,
    "traditional": "過去",
    "simplified": "过去",
    "reading": "guò qu",
    "meaning": "go over, pass by",
    "grade": 2
  },
  {
    "id": 704,
    "traditional": "海",
    "simplified": "海",
    "reading": "hǎi",
    "meaning": "water, foam, bottom, main, huge group of people, Surname, sea catfish, the sea, sea, mare, drink, fishpond, briny, great capacity, Neptune, billow, huge group of things, depth, waters, pond, wave, brine, big lake",
    "grade": 2
  },
  {
    "id": 705,
    "traditional": "過",
    "simplified": "过",
    "reading": "guò",
    "meaning": "[aspect]",
    "grade": 2
  },
  {
    "id": 706,
    "traditional": "海邊",
    "simplified": "海边",
    "reading": "hǎibiān",
    "meaning": "coast, seaside, seashore, beach",
    "grade": 2
  },
  {
    "id": 707,
    "traditional": "好",
    "simplified": "好",
    "reading": "hǎo",
    "meaning": "good",
    "grade": 2
  },
  {
    "id": 708,
    "traditional": "喊",
    "simplified": "喊",
    "reading": "hǎn",
    "meaning": "cry out, yell",
    "grade": 2
  },
  {
    "id": 709,
    "traditional": "好多",
    "simplified": "好多",
    "reading": "hǎoduō",
    "meaning": "det : many, much",
    "grade": 2
  },
  {
    "id": 710,
    "traditional": "好處",
    "simplified": "好处",
    "reading": "hǎochu",
    "meaning": "pull, profit, advantage, stead, benefit, virtu, welfare, virtue, good, behoof, goodness, gain, pap, beauty, beaut",
    "grade": 2
  },
  {
    "id": 711,
    "traditional": "好久",
    "simplified": "好久",
    "reading": "hǎojiǔ",
    "meaning": "quite a while",
    "grade": 2
  },
  {
    "id": 712,
    "traditional": "好人",
    "simplified": "好人",
    "reading": "hǎorén",
    "meaning": "a healthy person, good person, goodman, sb. who tries to get along with everyone, bawcock, good, agreeable individual, good egg, a person who tries to get along with everyone (oft",
    "grade": 2
  },
  {
    "id": 713,
    "traditional": "好事",
    "simplified": "好事",
    "reading": "hǎoshì",
    "meaning": "good action, deed, thing or work (also sarcastic, 'a fine thing indeed'), charity, happy occasion, Daoist or Buddhist ceremony for the souls of the dead, to be meddlesome",
    "grade": 2
  },
  {
    "id": 714,
    "traditional": "好像",
    "simplified": "好像",
    "reading": "hǎoxiàng",
    "meaning": "seem, be like",
    "grade": 2
  },
  {
    "id": 715,
    "traditional": "合適",
    "simplified": "合适",
    "reading": "héshì",
    "meaning": "set, fit, accommodate, befit, suitable, right, appropriate, belong, beseem, becoming, suit",
    "grade": 2
  },
  {
    "id": 716,
    "traditional": "黑",
    "simplified": "黑",
    "reading": "hēi",
    "meaning": "black, dark, wicked, sinister, vicious, be greedy, extort",
    "grade": 2
  },
  {
    "id": 717,
    "traditional": "河",
    "simplified": "河",
    "reading": "hé",
    "meaning": "watercourse, stream, Yellow River, fluvial, river, drink",
    "grade": 2
  },
  {
    "id": 718,
    "traditional": "黑板",
    "simplified": "黑板",
    "reading": "hēibǎn",
    "meaning": "blackboard, CL:塊|块[kuai4],個|个[ge4]",
    "grade": 2
  },
  {
    "id": 719,
    "traditional": "紅",
    "simplified": "红",
    "reading": "hóng",
    "meaning": "in vogue, famous, redden, red, popular",
    "grade": 2
  },
  {
    "id": 720,
    "traditional": "黑色",
    "simplified": "黑色",
    "reading": "hēisè",
    "meaning": "black",
    "grade": 2
  },
  {
    "id": 721,
    "traditional": "紅色",
    "simplified": "红色",
    "reading": "hóngsè",
    "meaning": "red color",
    "grade": 2
  },
  {
    "id": 722,
    "traditional": "忽然",
    "simplified": "忽然",
    "reading": "hūrán",
    "meaning": "suddenly",
    "grade": 2
  },
  {
    "id": 723,
    "traditional": "後來",
    "simplified": "后来",
    "reading": "hòulái",
    "meaning": "afterwards, later",
    "grade": 2
  },
  {
    "id": 724,
    "traditional": "護照",
    "simplified": "护照",
    "reading": "hùzhào",
    "meaning": "passport",
    "grade": 2
  },
  {
    "id": 725,
    "traditional": "花",
    "simplified": "花",
    "reading": "huā",
    "meaning": "spend, expend, cost",
    "grade": 2
  },
  {
    "id": 726,
    "traditional": "花園",
    "simplified": "花园",
    "reading": "huāyuán",
    "meaning": "flower garden",
    "grade": 2
  },
  {
    "id": 727,
    "traditional": "湖",
    "simplified": "湖",
    "reading": "hú",
    "meaning": "water, waterfront, a name referring to the provinces of Hunan and Hub, lacustrine, loch, mere, lake, lough, Hunan and Hubei, laky",
    "grade": 2
  },
  {
    "id": 728,
    "traditional": "畫",
    "simplified": "画",
    "reading": "huà",
    "meaning": "painting, pictures",
    "grade": 2
  },
  {
    "id": 729,
    "traditional": "畫兒",
    "simplified": "画儿",
    "reading": "huàr5",
    "meaning": "picture, drawing, painting",
    "grade": 2
  },
  {
    "id": 730,
    "traditional": "畫家",
    "simplified": "画家",
    "reading": "huàjiā",
    "meaning": "painterly, creative person, artist, painter, penman, artists",
    "grade": 2
  },
  {
    "id": 731,
    "traditional": "壞人",
    "simplified": "坏人",
    "reading": "huàirén",
    "meaning": "hellion, bad person, scoundrel, badman, baddie, villain, malefactor, evildoer",
    "grade": 2
  },
  {
    "id": 732,
    "traditional": "壞處",
    "simplified": "坏处",
    "reading": "huàichu",
    "meaning": "harm, troubles, CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 733,
    "traditional": "歡迎",
    "simplified": "欢迎",
    "reading": "huānyíng",
    "meaning": "invite, greet, take in, receive, welcome, gratulate, embrace, salute",
    "grade": 2
  },
  {
    "id": 734,
    "traditional": "黃",
    "simplified": "黄",
    "reading": "huáng",
    "meaning": "Surname",
    "grade": 2
  },
  {
    "id": 735,
    "traditional": "換",
    "simplified": "换",
    "reading": "huàn",
    "meaning": "adjourn, barter, exchange, commute, reseat, trade, conversion, convert, change",
    "grade": 2
  },
  {
    "id": 736,
    "traditional": "黃色",
    "simplified": "黄色",
    "reading": "huángsè",
    "meaning": "chrome, yellowness, yellow, salacity, or",
    "grade": 2
  },
  {
    "id": 737,
    "traditional": "回",
    "simplified": "回",
    "reading": "huí",
    "meaning": "return, go back",
    "grade": 2
  },
  {
    "id": 738,
    "traditional": "回國",
    "simplified": "回国",
    "reading": "huí guó",
    "meaning": "return to one's country",
    "grade": 2
  },
  {
    "id": 739,
    "traditional": "會",
    "simplified": "会",
    "reading": "huì",
    "meaning": "to be able, can, will, to meet",
    "grade": 2
  },
  {
    "id": 740,
    "traditional": "活動",
    "simplified": "活动",
    "reading": "huódong",
    "meaning": "activity, maneuver, behavior",
    "grade": 2
  },
  {
    "id": 741,
    "traditional": "或",
    "simplified": "或",
    "reading": "huò",
    "meaning": "conj.: or, either or",
    "grade": 2
  },
  {
    "id": 742,
    "traditional": "或者",
    "simplified": "或者",
    "reading": "huòzhě",
    "meaning": "conj.: or, either...or...",
    "grade": 2
  },
  {
    "id": 743,
    "traditional": "鷄",
    "simplified": "鸡",
    "reading": "jī",
    "meaning": "capercailzie, Gallus gallus, giblets, chook, horse of the wood, capercaillie, gallinaceous, Tetrao urogallus, sultan, chicken",
    "grade": 2
  },
  {
    "id": 744,
    "traditional": "機會",
    "simplified": "机会",
    "reading": "jīhui",
    "meaning": "scouth, occasion, good luck, hap, liberty, fortune, opportunity, chance, joss, hazard, happy chance, probability, luck, scope, room, break, turn, opening",
    "grade": 2
  },
  {
    "id": 745,
    "traditional": "級",
    "simplified": "级",
    "reading": "jí",
    "meaning": "level, rank, grade",
    "grade": 2
  },
  {
    "id": 746,
    "traditional": "急",
    "simplified": "急",
    "reading": "jí",
    "meaning": "rapid, fast, violent",
    "grade": 2
  },
  {
    "id": 747,
    "traditional": "計劃",
    "simplified": "计划",
    "reading": "jìhuà",
    "meaning": "plan, project, program",
    "grade": 2
  },
  {
    "id": 748,
    "traditional": "計算機",
    "simplified": "计算机",
    "reading": "jìsuànjī",
    "meaning": "computer, (Tw) calculator, CL:臺|台[tai2]",
    "grade": 2
  },
  {
    "id": 749,
    "traditional": "加",
    "simplified": "加",
    "reading": "jiā",
    "meaning": "dramatise, plant, embroider, intercalate, lard, put in, blow up, inflict, deliver, aggrandize, plus, dramatize, increase, augment, embellish, augmentation, confer, append, add, pad, summate",
    "grade": 2
  },
  {
    "id": 750,
    "traditional": "加油",
    "simplified": "加油",
    "reading": "jiā yóu",
    "meaning": "gas, lubricate, oil, refuel, gas up, embolden, accelerate, lubrication, fuel, cheer, recreate, hearten, make an extra effort",
    "grade": 2
  },
  {
    "id": 751,
    "traditional": "家",
    "simplified": "家",
    "reading": "jiā",
    "meaning": "family, household, home, house",
    "grade": 2
  },
  {
    "id": 752,
    "traditional": "家庭",
    "simplified": "家庭",
    "reading": "jiātíng",
    "meaning": "hearthstone, background, fireside, establishment, household, hearth, domestic, hearthside, menage, home, family unit, family, house",
    "grade": 2
  },
  {
    "id": 753,
    "traditional": "家長",
    "simplified": "家长",
    "reading": "jiāzhǎng",
    "meaning": "masterman, housemaster, graybeard, patriarch, parent of schoolchildren, patriarchal, goodman, patriarchate, paterfamilias, the head of a family, genearch, patriarchy, householder, head of household, the parent or guadian of a child",
    "grade": 2
  },
  {
    "id": 754,
    "traditional": "假",
    "simplified": "假",
    "reading": "jiǎ",
    "meaning": "false, fake, phony, artificial",
    "grade": 2
  },
  {
    "id": 755,
    "traditional": "假期",
    "simplified": "假期",
    "reading": "jiàqī",
    "meaning": "season, holiday, leave, period of leave, vacation",
    "grade": 2
  },
  {
    "id": 756,
    "traditional": "檢查",
    "simplified": "检查",
    "reading": "jiǎnchá",
    "meaning": "check, inspect, examine",
    "grade": 2
  },
  {
    "id": 757,
    "traditional": "件",
    "simplified": "件",
    "reading": "jiàn",
    "meaning": "correspondence, paper, document, bit, m.[general], , single item, letter, piece",
    "grade": 2
  },
  {
    "id": 758,
    "traditional": "見過",
    "simplified": "见过",
    "reading": "jiànguò",
    "meaning": "See",
    "grade": 2
  },
  {
    "id": 759,
    "traditional": "講",
    "simplified": "讲",
    "reading": "jiǎng",
    "meaning": "discourse, utter, talk, negotiation, make clear, negotiate, parley, pay attention to, speak, syllable, discuss, explain, tell, be particular about, explanation, interpretation, interpret, relate, discussion, stress, mouth, say, remark, verbalize",
    "grade": 2
  },
  {
    "id": 760,
    "traditional": "見到",
    "simplified": "见到",
    "reading": "jiàndào",
    "meaning": "see, meet, perceive",
    "grade": 2
  },
  {
    "id": 761,
    "traditional": "健康",
    "simplified": "健康",
    "reading": "jiànkāng",
    "meaning": "healthy, sound",
    "grade": 2
  },
  {
    "id": 762,
    "traditional": "講話",
    "simplified": "讲话",
    "reading": "jiǎnghuà",
    "meaning": "parley, jaw, turn to, address, talk, speak, say, speak to",
    "grade": 2
  },
  {
    "id": 763,
    "traditional": "交",
    "simplified": "交",
    "reading": "jiāo",
    "meaning": "associate with, make friends, fall in with, mate",
    "grade": 2
  },
  {
    "id": 764,
    "traditional": "交給",
    "simplified": "交给",
    "reading": "jiāogěi",
    "meaning": "relinquish, give_away, submit, give to, hand/give to, hand to, give_up, reach, hand",
    "grade": 2
  },
  {
    "id": 765,
    "traditional": "交朋友",
    "simplified": "交朋友",
    "reading": "jiāo péngyou",
    "meaning": "to make friends, (dialect) to start an affair with sb",
    "grade": 2
  },
  {
    "id": 766,
    "traditional": "交通",
    "simplified": "交通",
    "reading": "jiāotōng",
    "meaning": "intercourse, commutation, transportation, communication, commuting, traffic, traffic_light, liaison man, communications, dealings, liaison",
    "grade": 2
  },
  {
    "id": 767,
    "traditional": "角",
    "simplified": "角",
    "reading": "jiǎo",
    "meaning": "horn, bugle, sth. horn-shaped",
    "grade": 2
  },
  {
    "id": 768,
    "traditional": "角度",
    "simplified": "角度",
    "reading": "jiǎodù",
    "meaning": "aspect, perspective, degree, arcdegree, point of view, angle",
    "grade": 2
  },
  {
    "id": 769,
    "traditional": "餃子",
    "simplified": "饺子",
    "reading": "jiǎozi",
    "meaning": "dumpling, pot-sticker, CL:個|个[ge4],隻|只[zhi1]",
    "grade": 2
  },
  {
    "id": 770,
    "traditional": "叫作",
    "simplified": "叫作",
    "reading": "jiàozuò",
    "meaning": "to call, to be called",
    "grade": 2
  },
  {
    "id": 771,
    "traditional": "教師",
    "simplified": "教师",
    "reading": "jiàoshī",
    "meaning": "beak, don, school teacher, schoolman, teacher, schoolmaster, preceptor, professor, pedagogue, schoolteacher, coach, master, instructor, educator, preceptorship",
    "grade": 2
  },
  {
    "id": 772,
    "traditional": "腳",
    "simplified": "脚",
    "reading": "jiǎo",
    "meaning": "foot, leg, base",
    "grade": 2
  },
  {
    "id": 773,
    "traditional": "教學",
    "simplified": "教学",
    "reading": "jiāo xué",
    "meaning": "teach and study",
    "grade": 2
  },
  {
    "id": 774,
    "traditional": "教育",
    "simplified": "教育",
    "reading": "jiàoyù",
    "meaning": "education",
    "grade": 2
  },
  {
    "id": 775,
    "traditional": "教室",
    "simplified": "教室",
    "reading": "jiàoshì",
    "meaning": "classroom, schoolroom",
    "grade": 2
  },
  {
    "id": 776,
    "traditional": "接到",
    "simplified": "接到",
    "reading": "jiēdào",
    "meaning": "welcome, receive, meet",
    "grade": 2
  },
  {
    "id": 777,
    "traditional": "接受",
    "simplified": "接受",
    "reading": "jiēshòu",
    "meaning": "receive (honors, have, receive, acknowledge, sweep up, espouse, etc.), come to grips, adopt, embrace, greet, welcome, hook on, seize on, take up, accede, taken, entertain, latch on, buy, fasten on, swallow, imbibe, accept, receive (honors/etc.), undergo, get to grips, take, adapt, acceptance, thole",
    "grade": 2
  },
  {
    "id": 778,
    "traditional": "接下來",
    "simplified": "接下来",
    "reading": "jiēxiàlái",
    "meaning": "next, immediately after",
    "grade": 2
  },
  {
    "id": 779,
    "traditional": "接著",
    "simplified": "接着",
    "reading": "jiēzhe",
    "meaning": "next, immediately after",
    "grade": 2
  },
  {
    "id": 780,
    "traditional": "街",
    "simplified": "街",
    "reading": "jiē",
    "meaning": "county fair, fair, place, market, toby, street",
    "grade": 2
  },
  {
    "id": 781,
    "traditional": "節",
    "simplified": "节",
    "reading": "jié",
    "meaning": "m.[event]",
    "grade": 2
  },
  {
    "id": 782,
    "traditional": "節目",
    "simplified": "节目",
    "reading": "jiémù",
    "meaning": "item, item (on program), programme, act, telecast, number, program",
    "grade": 2
  },
  {
    "id": 783,
    "traditional": "節日",
    "simplified": "节日",
    "reading": "jiérì",
    "meaning": "festival, holiday",
    "grade": 2
  },
  {
    "id": 784,
    "traditional": "結果",
    "simplified": "结果",
    "reading": "jiē guǒ",
    "meaning": "result, outcome, consequence",
    "grade": 2
  },
  {
    "id": 785,
    "traditional": "斤",
    "simplified": "斤",
    "reading": "jīn",
    "meaning": "m.[standard]",
    "grade": 2
  },
  {
    "id": 786,
    "traditional": "接",
    "simplified": "接",
    "reading": "jiē",
    "meaning": "to receive, to answer (the phone), to meet or welcome sb, to connect, to catch, to join, to extend, to take one's turn on duty, to take over for sb",
    "grade": 2
  },
  {
    "id": 787,
    "traditional": "借",
    "simplified": "借",
    "reading": "jiè",
    "meaning": "lend",
    "grade": 2
  },
  {
    "id": 788,
    "traditional": "進入",
    "simplified": "进入",
    "reading": "jìnrù",
    "meaning": "go_into, get_in, debouch, get in, go into, move into, come_into, pass, step_in, lapse, get_into, set in, go in, get into, kick in, penetrate, intromission, pierce, come_in, come in, enter, entry, sink, impenetrate",
    "grade": 2
  },
  {
    "id": 789,
    "traditional": "今後",
    "simplified": "今后",
    "reading": "jīnhòu",
    "meaning": "hereafter, henceforth, in the future, from now on",
    "grade": 2
  },
  {
    "id": 790,
    "traditional": "進行",
    "simplified": "进行",
    "reading": "jìnxíng",
    "meaning": "be in progress, go on, carry on/out",
    "grade": 2
  },
  {
    "id": 791,
    "traditional": "經常",
    "simplified": "经常",
    "reading": "jīngcháng",
    "meaning": "everyday, daily, alway, often, a great deal, frequently, sempre, much, regularly, constantly, evermore, day-to-day",
    "grade": 2
  },
  {
    "id": 792,
    "traditional": "經過",
    "simplified": "经过",
    "reading": "jīngguò",
    "meaning": "pass, go through, undergo",
    "grade": 2
  },
  {
    "id": 793,
    "traditional": "近",
    "simplified": "近",
    "reading": "jìn",
    "meaning": "almost",
    "grade": 2
  },
  {
    "id": 794,
    "traditional": "經理",
    "simplified": "经理",
    "reading": "jīnglǐ",
    "meaning": "amaldar, intendant, manager, steward, executive, managing director, exec, director, skipper, syndic, executive director",
    "grade": 2
  },
  {
    "id": 795,
    "traditional": "酒店",
    "simplified": "酒店",
    "reading": "jiǔdiàn",
    "meaning": "wineshop, public house",
    "grade": 2
  },
  {
    "id": 796,
    "traditional": "就要",
    "simplified": "就要",
    "reading": "jiùyào",
    "meaning": "will, shall, to be going to",
    "grade": 2
  },
  {
    "id": 797,
    "traditional": "酒",
    "simplified": "酒",
    "reading": "jiǔ",
    "meaning": "libation, bracer, plonk, wine, tipple, poison, pottle, spirits, creature, vin, cup, drink, hooch, arrack, medicine, alcohol, bottle, hootch, alcoholic drink, rum, bibble, potation, belly-wash, alcoholic beverage, Bacchus, booze, liquor, juice, fortifier, inebriant, lotion, intoxicant",
    "grade": 2
  },
  {
    "id": 798,
    "traditional": "舉手",
    "simplified": "举手",
    "reading": "jǔ shǒu",
    "meaning": "raise one's hand, haul_off, raise one's hand(s), show, put up one's hand",
    "grade": 2
  },
  {
    "id": 799,
    "traditional": "舉行",
    "simplified": "举行",
    "reading": "jǔxíng",
    "meaning": "hold (meeting/etc.)",
    "grade": 2
  },
  {
    "id": 800,
    "traditional": "舉",
    "simplified": "举",
    "reading": "jǔ",
    "meaning": "lift, raise, hold up, cite, propose",
    "grade": 2
  },
  {
    "id": 801,
    "traditional": "句子",
    "simplified": "句子",
    "reading": "jùzi",
    "meaning": "sentence",
    "grade": 2
  },
  {
    "id": 802,
    "traditional": "卡",
    "simplified": "卡",
    "reading": "kǎ",
    "meaning": "to stop, to block, card, CL:張|张[zhang1],片[pian4], calorie, cassette, (computing) (coll.) slow, to block, to be stuck, to be wedged, customs station, a clip, a fastener, a checkpost, Taiwan pr. [ka3]",
    "grade": 2
  },
  {
    "id": 803,
    "traditional": "開機",
    "simplified": "开机",
    "reading": "kāi jī",
    "meaning": "to start an engine, to boot up (a computer), to press Ctrl-Alt-Delete, to begin shooting a film or TV show",
    "grade": 2
  },
  {
    "id": 804,
    "traditional": "句",
    "simplified": "句",
    "reading": "jù",
    "meaning": "sentence, m.[general],",
    "grade": 2
  },
  {
    "id": 805,
    "traditional": "看法",
    "simplified": "看法",
    "reading": "kànfa",
    "meaning": "side, view, mentality, idea, outlook, a way of looking at a thing, opinion, thought, sight, standpoint, hypothesis, attitude, hobbyhorse, viewpoint, possibility, theory, slant, pronouncement, way of looking at sth., thinking, supposition, supposal",
    "grade": 2
  },
  {
    "id": 806,
    "traditional": "開心",
    "simplified": "开心",
    "reading": "kāi xīn",
    "meaning": "feel happy, rejoice",
    "grade": 2
  },
  {
    "id": 807,
    "traditional": "考生",
    "simplified": "考生",
    "reading": "kǎoshēng",
    "meaning": "candidate for an entrance examination, examinee",
    "grade": 2
  },
  {
    "id": 808,
    "traditional": "靠",
    "simplified": "靠",
    "reading": "kào",
    "meaning": "depend/rely on",
    "grade": 2
  },
  {
    "id": 809,
    "traditional": "開學",
    "simplified": "开学",
    "reading": "kāi xué",
    "meaning": "start school, begin term",
    "grade": 2
  },
  {
    "id": 810,
    "traditional": "科",
    "simplified": "科",
    "reading": "kē",
    "meaning": "department, section, rules, laws, a branch of academic or vocational study, stage directions, (animal) family, a division or subdivision of an administrative uni, family, branch of study, faculty, administrative section",
    "grade": 2
  },
  {
    "id": 811,
    "traditional": "科學",
    "simplified": "科学",
    "reading": "kēxué",
    "meaning": "ology, scientific, science, scientific knowledge",
    "grade": 2
  },
  {
    "id": 812,
    "traditional": "可愛",
    "simplified": "可爱",
    "reading": "kěài",
    "meaning": "lovable, likeable, lovely",
    "grade": 2
  },
  {
    "id": 813,
    "traditional": "可怕",
    "simplified": "可怕",
    "reading": "kěpà",
    "meaning": "fearful, terrible",
    "grade": 2
  },
  {
    "id": 814,
    "traditional": "可是",
    "simplified": "可是",
    "reading": "kěshì",
    "meaning": "conj.: but, yet, however",
    "grade": 2
  },
  {
    "id": 815,
    "traditional": "可能",
    "simplified": "可能",
    "reading": "kěnéng",
    "meaning": "probably, maybe",
    "grade": 2
  },
  {
    "id": 816,
    "traditional": "克",
    "simplified": "克",
    "reading": "kè",
    "meaning": "to be able to, to subdue, to restrain, to overcome, gram, variant of 剋|克[ke4], Ke (c. 2000 BC), seventh of the legendary Flame Emperors, 炎帝[Yan2 di4] descended from Shennong 神農|神农[Shen2 nong2] Farmer God, to scold, to beat",
    "grade": 2
  },
  {
    "id": 817,
    "traditional": "可以",
    "simplified": "可以",
    "reading": "kěyǐ",
    "meaning": "can, may",
    "grade": 2
  },
  {
    "id": 818,
    "traditional": "刻",
    "simplified": "刻",
    "reading": "kè",
    "meaning": "m.[standard]",
    "grade": 2
  },
  {
    "id": 819,
    "traditional": "課堂",
    "simplified": "课堂",
    "reading": "kètáng",
    "meaning": "classroom, CL:間|间[jian1]",
    "grade": 2
  },
  {
    "id": 820,
    "traditional": "空氣",
    "simplified": "空气",
    "reading": "kōngqì",
    "meaning": "air, airspace, atmosphere, aerial, deflector, atmospherics",
    "grade": 2
  },
  {
    "id": 821,
    "traditional": "快餐",
    "simplified": "快餐",
    "reading": "kuàicān",
    "meaning": "fast food, snack, quick meal",
    "grade": 2
  },
  {
    "id": 822,
    "traditional": "快點兒",
    "simplified": "快点儿",
    "reading": "kuàidiǎnr5",
    "meaning": "to do sth more quickly, erhua variant of 快點|快点[kuai4 dian3]",
    "grade": 2
  },
  {
    "id": 823,
    "traditional": "客人",
    "simplified": "客人",
    "reading": "kèren",
    "meaning": "stranger, guest, caller, client, visitant, invitee, company, visitor",
    "grade": 2
  },
  {
    "id": 824,
    "traditional": "快樂",
    "simplified": "快乐",
    "reading": "kuàilè",
    "meaning": "happy, cheerful",
    "grade": 2
  },
  {
    "id": 825,
    "traditional": "快要",
    "simplified": "快要",
    "reading": "kuàiyào",
    "meaning": "almost, nearly, almost all",
    "grade": 2
  },
  {
    "id": 826,
    "traditional": "哭",
    "simplified": "哭",
    "reading": "kū",
    "meaning": "greet, cry, weep",
    "grade": 2
  },
  {
    "id": 827,
    "traditional": "筷子",
    "simplified": "筷子",
    "reading": "kuàizi",
    "meaning": "chopsticks, CL:對|对[dui4],根[gen1],把[ba3],雙|双[shuang1]",
    "grade": 2
  },
  {
    "id": 828,
    "traditional": "來自",
    "simplified": "来自",
    "reading": "láizì",
    "meaning": "come from, originate from, come, stem/originate from, attach_to, stem, come/stem/originate from, hail",
    "grade": 2
  },
  {
    "id": 829,
    "traditional": "拉",
    "simplified": "拉",
    "reading": "lā",
    "meaning": "slit, press-gang, help, solicit, lend a helping hand, canvass, draw, zoom, transport by vehicle, pull, release, press, cut, schlep, play (bowed instruments), tote, tug, gash, tow, bend, snake, schlepp, move (troops), solicitation, haul, chat, solicit (business), give a helping hand, drag out, force, play, slash, drum_up, claw, implicate, overstretch, empty the bowels, drag, trail, drag",
    "grade": 2
  },
  {
    "id": 830,
    "traditional": "藍",
    "simplified": "蓝",
    "reading": "lán",
    "meaning": "blue",
    "grade": 2
  },
  {
    "id": 831,
    "traditional": "藍色",
    "simplified": "蓝色",
    "reading": "lánsè",
    "meaning": "blue, blue (color), blueness",
    "grade": 2
  },
  {
    "id": 832,
    "traditional": "籃球",
    "simplified": "篮球",
    "reading": "lánqiú",
    "meaning": "basketball",
    "grade": 2
  },
  {
    "id": 833,
    "traditional": "老年",
    "simplified": "老年",
    "reading": "lǎonián",
    "meaning": "senescence, caducity, senility, old_age, agedness, hoariness, age, old age",
    "grade": 2
  },
  {
    "id": 834,
    "traditional": "老朋友",
    "simplified": "老朋友",
    "reading": "lǎopéngyou",
    "meaning": "old friend, (slang) period, menstruation",
    "grade": 2
  },
  {
    "id": 835,
    "traditional": "離",
    "simplified": "离",
    "reading": "lí",
    "meaning": "distant/apart from",
    "grade": 2
  },
  {
    "id": 836,
    "traditional": "老",
    "simplified": "老",
    "reading": "lǎo",
    "meaning": "old, aged;of long standing, outdated, tough, overgrown, dark (of colors)",
    "grade": 2
  },
  {
    "id": 837,
    "traditional": "老是",
    "simplified": "老是",
    "reading": "lǎoshi",
    "meaning": "always",
    "grade": 2
  },
  {
    "id": 838,
    "traditional": "離開",
    "simplified": "离开",
    "reading": "lí kāi",
    "meaning": "leave, depart/deviate from",
    "grade": 2
  },
  {
    "id": 839,
    "traditional": "裡頭",
    "simplified": "里头",
    "reading": "lǐtou",
    "meaning": "inside, interior",
    "grade": 2
  },
  {
    "id": 840,
    "traditional": "理想",
    "simplified": "理想",
    "reading": "lǐxiǎng",
    "meaning": "ideal,",
    "grade": 2
  },
  {
    "id": 841,
    "traditional": "禮物",
    "simplified": "礼物",
    "reading": "lǐwù",
    "meaning": "tribute, giving, proffer, gift, compliment, box, benefice, acknowledgment, present, souvenir",
    "grade": 2
  },
  {
    "id": 842,
    "traditional": "例如",
    "simplified": "例如",
    "reading": "lìrú",
    "meaning": "for instance/example, e.g., such as",
    "grade": 2
  },
  {
    "id": 843,
    "traditional": "例子",
    "simplified": "例子",
    "reading": "lìzi",
    "meaning": "exemplification, example, representative, illustration, instance, piece, case",
    "grade": 2
  },
  {
    "id": 844,
    "traditional": "臉",
    "simplified": "脸",
    "reading": "liǎn",
    "meaning": "map, mug, kisser, puss, visage, snoot, frontispiece, pan, smiler, mazzard, mush, facial, countenance, phiz, figurehead, face, physiognomy, front, human face",
    "grade": 2
  },
  {
    "id": 845,
    "traditional": "練習",
    "simplified": "练习",
    "reading": "liànxí",
    "meaning": "practice, exercise",
    "grade": 2
  },
  {
    "id": 846,
    "traditional": "練",
    "simplified": "练",
    "reading": "liàn",
    "meaning": "practice, exercise, boil and scour raw silk, educate, reeducate, nurture, coach, hone, drill, school, lunge, break, breed, train",
    "grade": 2
  },
  {
    "id": 847,
    "traditional": "涼",
    "simplified": "凉",
    "reading": "liáng",
    "meaning": "cool, cold, discouraged, disappointed",
    "grade": 2
  },
  {
    "id": 848,
    "traditional": "涼快",
    "simplified": "凉快",
    "reading": "liángkuai",
    "meaning": "nice and cold, pleasantly cool",
    "grade": 2
  },
  {
    "id": 849,
    "traditional": "輛",
    "simplified": "辆",
    "reading": "liàng",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 850,
    "traditional": "亮",
    "simplified": "亮",
    "reading": "liàng",
    "meaning": "bright, light, loud and clear",
    "grade": 2
  },
  {
    "id": 851,
    "traditional": "零下",
    "simplified": "零下",
    "reading": "língxià",
    "meaning": "below zero",
    "grade": 2
  },
  {
    "id": 852,
    "traditional": "兩",
    "simplified": "两",
    "reading": "liǎng",
    "meaning": "det.: two, both (sides), either (side), a few, some",
    "grade": 2
  },
  {
    "id": 853,
    "traditional": "留下",
    "simplified": "留下",
    "reading": "liú xià",
    "meaning": "stay, leave behind, leave, allocate, devote, entail, stick, stay behind, leave_behind, stay put, stick around, remain",
    "grade": 2
  },
  {
    "id": 854,
    "traditional": "留",
    "simplified": "留",
    "reading": "liú",
    "meaning": "continue, stay, keep, leave behind, leave, grow, accept, (let) grow, leave (sth.), let grow, ask ... to stay, take, bide, wear, study in a foreign country, save, reserve, reservation, ask sb. to stay, remain",
    "grade": 2
  },
  {
    "id": 855,
    "traditional": "留學生",
    "simplified": "留学生",
    "reading": "liúxuéshēng",
    "meaning": "student studying abroad, returned student",
    "grade": 2
  },
  {
    "id": 856,
    "traditional": "流利",
    "simplified": "流利",
    "reading": "liúlì",
    "meaning": "fluent",
    "grade": 2
  },
  {
    "id": 857,
    "traditional": "流",
    "simplified": "流",
    "reading": "liú",
    "meaning": "spread, circulate",
    "grade": 2
  },
  {
    "id": 858,
    "traditional": "流行",
    "simplified": "流行",
    "reading": "liúxíng",
    "meaning": "prevalent, popular, fashionable, in vogue, spread, rage (of contagious disease)",
    "grade": 2
  },
  {
    "id": 859,
    "traditional": "旅客",
    "simplified": "旅客",
    "reading": "lǚkè",
    "meaning": "journeyer, guest, hotel guest, traveler, traffic, wayfarer, viator, rider, traveller, passer, passenger",
    "grade": 2
  },
  {
    "id": 860,
    "traditional": "旅行",
    "simplified": "旅行",
    "reading": "lǚxíng",
    "meaning": "travel, journey, tour",
    "grade": 2
  },
  {
    "id": 861,
    "traditional": "綠",
    "simplified": "绿",
    "reading": "lǜ",
    "meaning": "green",
    "grade": 2
  },
  {
    "id": 862,
    "traditional": "綠色",
    "simplified": "绿色",
    "reading": "lǜsè",
    "meaning": "verdure, green, greenness, green color, verdancy, bice, virescence, vert, pistachio, viridity",
    "grade": 2
  },
  {
    "id": 863,
    "traditional": "路邊",
    "simplified": "路边",
    "reading": "lùbiān",
    "meaning": "curb, roadside, wayside",
    "grade": 2
  },
  {
    "id": 864,
    "traditional": "賣",
    "simplified": "卖",
    "reading": "mài",
    "meaning": "bring, parade, prostitute, make living at, go, sell out, show off, negotiate, exert to the utmost, tout, not spare, deal, betray, make living by, at, sell, make living by/at, betrayal, trade, fetch, market, bring in",
    "grade": 2
  },
  {
    "id": 865,
    "traditional": "旅遊",
    "simplified": "旅游",
    "reading": "lǚyóu",
    "meaning": "trip, journey, tourism, travel, tour, to travel",
    "grade": 2
  },
  {
    "id": 866,
    "traditional": "滿意",
    "simplified": "满意",
    "reading": "mǎnyì",
    "meaning": "satisfy, well-pleasing, pleased, satisfied",
    "grade": 2
  },
  {
    "id": 867,
    "traditional": "貓",
    "simplified": "猫",
    "reading": "māo",
    "meaning": "pussycat, puss, malkin, cat, kitty, tabby, felid, feline, true cat, pussy",
    "grade": 2
  },
  {
    "id": 868,
    "traditional": "米",
    "simplified": "米",
    "reading": "mǐ",
    "meaning": "rice, shelled/husked seed",
    "grade": 2
  },
  {
    "id": 869,
    "traditional": "面",
    "simplified": "面",
    "reading": "miàn",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 870,
    "traditional": "面前",
    "simplified": "面前",
    "reading": "miànqián",
    "meaning": "in_front, in front of, in the front of, in the face of, front, presence, before",
    "grade": 2
  },
  {
    "id": 871,
    "traditional": "名稱",
    "simplified": "名称",
    "reading": "míngchēng",
    "meaning": "title, denotation, compellation, appellative, designation, name, denomination, calling, rubric, appellation",
    "grade": 2
  },
  {
    "id": 872,
    "traditional": "名",
    "simplified": "名",
    "reading": "míng",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 873,
    "traditional": "名單",
    "simplified": "名单",
    "reading": "míngdān",
    "meaning": "checklist, register, registry, list, roll, listing, roster, name list",
    "grade": 2
  },
  {
    "id": 874,
    "traditional": "滿",
    "simplified": "满",
    "reading": "mǎn",
    "meaning": "det.: entirely, wholly",
    "grade": 2
  },
  {
    "id": 875,
    "traditional": "目的",
    "simplified": "目的",
    "reading": "mùdì",
    "meaning": "view, pretence, objective, goal, purport, intention, bourn, object, motive, motivation, meaning, aim, end point, hent, target, scope, sense of purpose, errand, purposefulness, sake, function, end, purpose, quaesitum, intent",
    "grade": 2
  },
  {
    "id": 876,
    "traditional": "拿到",
    "simplified": "拿到",
    "reading": "nádào",
    "meaning": "take, get_hold_of, get",
    "grade": 2
  },
  {
    "id": 877,
    "traditional": "拿出",
    "simplified": "拿出",
    "reading": "náchū",
    "meaning": "ante, produce, take_out, get out, pose, take out, bring forth, take away, bring out",
    "grade": 2
  },
  {
    "id": 878,
    "traditional": "那",
    "simplified": "那",
    "reading": "nà",
    "meaning": "det.: that",
    "grade": 2
  },
  {
    "id": 879,
    "traditional": "那會兒",
    "simplified": "那会儿",
    "reading": "nàhuìr5",
    "meaning": "Then",
    "grade": 2
  },
  {
    "id": 880,
    "traditional": "那麼",
    "simplified": "那么",
    "reading": "nàme",
    "meaning": "so",
    "grade": 2
  },
  {
    "id": 881,
    "traditional": "那時候",
    "simplified": "那时候",
    "reading": "nà shíhou",
    "meaning": "at that time",
    "grade": 2
  },
  {
    "id": 882,
    "traditional": "明星",
    "simplified": "明星",
    "reading": "míngxīng",
    "meaning": "headliner, lucida, (movie/etc.) star, impersonator, (movie, famous performer, stardom, Venus, star, etc.) star, movie_star",
    "grade": 2
  },
  {
    "id": 883,
    "traditional": "那樣",
    "simplified": "那样",
    "reading": "nàyàng",
    "meaning": "that kind of, like that",
    "grade": 2
  },
  {
    "id": 884,
    "traditional": "南方",
    "simplified": "南方",
    "reading": "nánfāng",
    "meaning": "S, due south, the southern part of the country, South, south, southern part of country, the South, southern",
    "grade": 2
  },
  {
    "id": 885,
    "traditional": "難過",
    "simplified": "难过",
    "reading": "nánguò",
    "meaning": "uncomfortable, feel bad, feel sorry, have hard time, bad, sad, have a hard time, be grieved, sorry",
    "grade": 2
  },
  {
    "id": 886,
    "traditional": "難看",
    "simplified": "难看",
    "reading": "nánkàn",
    "meaning": "ugly, unsightly",
    "grade": 2
  },
  {
    "id": 887,
    "traditional": "難受",
    "simplified": "难受",
    "reading": "nánshòu",
    "meaning": "to feel unwell, to suffer pain, to be difficult to bear",
    "grade": 2
  },
  {
    "id": 888,
    "traditional": "難聽",
    "simplified": "难听",
    "reading": "nántīng",
    "meaning": "unpleasant to hear, coarse, vulgar, offensive, shameful",
    "grade": 2
  },
  {
    "id": 889,
    "traditional": "能夠",
    "simplified": "能够",
    "reading": "nénggòu",
    "meaning": "can, able, would, may",
    "grade": 2
  },
  {
    "id": 890,
    "traditional": "年級",
    "simplified": "年级",
    "reading": "niánjí",
    "meaning": "grade, year (in school, college etc), CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 891,
    "traditional": "難題",
    "simplified": "难题",
    "reading": "nántí",
    "meaning": "problem, Gordian knot, grief, sticker, question, enigma, difficult problem, puzzler, spinosity, stickler, a hard nut to crack, puzzle, toughie, poser, difficulty, crux, nut, challenge, tickler, conundrum, knot, hump, knottiness, stinker, puzzlement, teaser, stumper, twister",
    "grade": 2
  },
  {
    "id": 892,
    "traditional": "年輕",
    "simplified": "年轻",
    "reading": "niánqīng",
    "meaning": "junior, young",
    "grade": 2
  },
  {
    "id": 893,
    "traditional": "鳥",
    "simplified": "鸟",
    "reading": "niǎo",
    "meaning": "beak, caprimulgiform bird, jay, carinate bird, birdie, bird, carinate, chaffinch, flying bird, fowl",
    "grade": 2
  },
  {
    "id": 894,
    "traditional": "弄",
    "simplified": "弄",
    "reading": "nòng",
    "meaning": "play with, make, do, handle, engage in, obtain, get, fetch, play tricks",
    "grade": 2
  },
  {
    "id": 895,
    "traditional": "努力",
    "simplified": "努力",
    "reading": "nǔlì",
    "meaning": "make great effort, try hard",
    "grade": 2
  },
  {
    "id": 896,
    "traditional": "爬山",
    "simplified": "爬山",
    "reading": "páshān",
    "meaning": "mountaineer, climb mountain, climb",
    "grade": 2
  },
  {
    "id": 897,
    "traditional": "排",
    "simplified": "排",
    "reading": "pái",
    "meaning": "stand in line, arrange, put in order, sequence",
    "grade": 2
  },
  {
    "id": 898,
    "traditional": "排隊",
    "simplified": "排队",
    "reading": "pái duì",
    "meaning": "stand in line, queue",
    "grade": 2
  },
  {
    "id": 899,
    "traditional": "怕",
    "simplified": "怕",
    "reading": "pà",
    "meaning": "I'm afraid, dread, funk, I suppose, be afraid of, fear",
    "grade": 2
  },
  {
    "id": 900,
    "traditional": "排球",
    "simplified": "排球",
    "reading": "páiqiú",
    "meaning": "volleyball",
    "grade": 2
  },
  {
    "id": 901,
    "traditional": "碰",
    "simplified": "碰",
    "reading": "pèng",
    "meaning": "explore, stub, paw, encounter, knock against, partake, run into, take one's chance, knock, disturb, touch, meet, bump",
    "grade": 2
  },
  {
    "id": 902,
    "traditional": "爬",
    "simplified": "爬",
    "reading": "pá",
    "meaning": "swarm, creep, shin, grabble, crawl, climb, go up, trail, climb up, mount, clamber, scramble",
    "grade": 2
  },
  {
    "id": 903,
    "traditional": "碰到",
    "simplified": "碰到",
    "reading": "pèngdào",
    "meaning": "come across, run_into, encounter, come_across, run into, touch, run_across, meet",
    "grade": 2
  },
  {
    "id": 904,
    "traditional": "碰見",
    "simplified": "碰见",
    "reading": "pèng jian",
    "meaning": "to run into, to meet (unexpectedly), to bump into",
    "grade": 2
  },
  {
    "id": 905,
    "traditional": "篇",
    "simplified": "篇",
    "reading": "piān",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 906,
    "traditional": "片",
    "simplified": "片",
    "reading": "piàn",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 907,
    "traditional": "便宜",
    "simplified": "便宜",
    "reading": "biànyí",
    "meaning": "cheap",
    "grade": 2
  },
  {
    "id": 908,
    "traditional": "平安",
    "simplified": "平安",
    "reading": "píngān",
    "meaning": "safe and sound, quiet and stable",
    "grade": 2
  },
  {
    "id": 909,
    "traditional": "平",
    "simplified": "平",
    "reading": "píng",
    "meaning": "flat, level, even, ordinary, common, uniform, safe and sound, peaceful, calm, fair, just, objective",
    "grade": 2
  },
  {
    "id": 910,
    "traditional": "漂亮",
    "simplified": "漂亮",
    "reading": "piàoliang",
    "meaning": "posh, handsome, brilliant, remarkable, splendid, beautiful, smart",
    "grade": 2
  },
  {
    "id": 911,
    "traditional": "平時",
    "simplified": "平时",
    "reading": "píngshí",
    "meaning": "ordinarily, in normal times, in peacetime",
    "grade": 2
  },
  {
    "id": 912,
    "traditional": "平常",
    "simplified": "平常",
    "reading": "píngcháng",
    "meaning": "ordinary, common",
    "grade": 2
  },
  {
    "id": 913,
    "traditional": "瓶子",
    "simplified": "瓶子",
    "reading": "píngzi",
    "meaning": "bottle, CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 914,
    "traditional": "平等",
    "simplified": "平等",
    "reading": "píngděng",
    "meaning": "equal",
    "grade": 2
  },
  {
    "id": 915,
    "traditional": "瓶",
    "simplified": "瓶",
    "reading": "píng",
    "meaning": "cruse, flask, vase, bottle, ninepin, jug, jar, pot, ampulla, m.[container],",
    "grade": 2
  },
  {
    "id": 916,
    "traditional": "普通話",
    "simplified": "普通话",
    "reading": "pǔtōnghuà",
    "meaning": "Mandarin (common language), Putonghua (common speech of the Chinese language), ordinary speech",
    "grade": 2
  },
  {
    "id": 917,
    "traditional": "其中",
    "simplified": "其中",
    "reading": "qízhōng",
    "meaning": "among, of them, in it, among them, which, them, det.: it, in, in which",
    "grade": 2
  },
  {
    "id": 918,
    "traditional": "普通",
    "simplified": "普通",
    "reading": "pǔtōng",
    "meaning": "ordinary, common, average",
    "grade": 2
  },
  {
    "id": 919,
    "traditional": "其他",
    "simplified": "其他",
    "reading": "qítā",
    "meaning": "det.: others, the rest, other, else",
    "grade": 2
  },
  {
    "id": 920,
    "traditional": "騎車",
    "simplified": "骑车",
    "reading": "qíchē",
    "meaning": "to cycle",
    "grade": 2
  },
  {
    "id": 921,
    "traditional": "起飛",
    "simplified": "起飞",
    "reading": "qǐfēi",
    "meaning": "take off (of planes/etc.)",
    "grade": 2
  },
  {
    "id": 922,
    "traditional": "氣",
    "simplified": "气",
    "reading": "qì",
    "meaning": "air, gas, smell, atmosphere, spirit",
    "grade": 2
  },
  {
    "id": 923,
    "traditional": "騎",
    "simplified": "骑",
    "reading": "qí",
    "meaning": "ride/sit astride, ride astride, override, get_onto, sit on the back of, sit, stride, ride_on, ride, mount, pedal, sit astride, bestraddle",
    "grade": 2
  },
  {
    "id": 924,
    "traditional": "千",
    "simplified": "千",
    "reading": "qiān",
    "meaning": "det.: thousand",
    "grade": 2
  },
  {
    "id": 925,
    "traditional": "氣溫",
    "simplified": "气温",
    "reading": "qìwēn",
    "meaning": "atmospheric temperature, mercury, temperature, air temperature",
    "grade": 2
  },
  {
    "id": 926,
    "traditional": "千克",
    "simplified": "千克",
    "reading": "qiānkè",
    "meaning": "kilogram",
    "grade": 2
  },
  {
    "id": 927,
    "traditional": "前年",
    "simplified": "前年",
    "reading": "qiánnián",
    "meaning": "the year before last",
    "grade": 2
  },
  {
    "id": 928,
    "traditional": "墻",
    "simplified": "墙",
    "reading": "qiáng",
    "meaning": "wall",
    "grade": 2
  },
  {
    "id": 929,
    "traditional": "青少年",
    "simplified": "青少年",
    "reading": "qīngshàonián",
    "meaning": "adolescent, stilyaga, teens, young person, teenager, teenagers, young people and teenagers, youths, youth, stripling, hobbledehoy, youngsters, younker",
    "grade": 2
  },
  {
    "id": 930,
    "traditional": "輕",
    "simplified": "轻",
    "reading": "qīng",
    "meaning": "light, agile, alert, easy, simple, rash, reckless, unimportant, frivolous, slight, neglect",
    "grade": 2
  },
  {
    "id": 931,
    "traditional": "青年",
    "simplified": "青年",
    "reading": "qīngnián",
    "meaning": "young people, adolescent, youngster, springal, boy, youth, youths, stripling, young, hobbledehoy, younker",
    "grade": 2
  },
  {
    "id": 932,
    "traditional": "清楚",
    "simplified": "清楚",
    "reading": "qīngchu",
    "meaning": "clear",
    "grade": 2
  },
  {
    "id": 933,
    "traditional": "晴天",
    "simplified": "晴天",
    "reading": "qíngtiān",
    "meaning": "clear sky, sunny day",
    "grade": 2
  },
  {
    "id": 934,
    "traditional": "請客",
    "simplified": "请客",
    "reading": "qǐng kè",
    "meaning": "to give a dinner party, to entertain guests, to invite to dinner",
    "grade": 2
  },
  {
    "id": 935,
    "traditional": "晴",
    "simplified": "晴",
    "reading": "qíng",
    "meaning": "clear, fine (weather)",
    "grade": 2
  },
  {
    "id": 936,
    "traditional": "請求",
    "simplified": "请求",
    "reading": "qǐngqiú",
    "meaning": "ask, request, entreat",
    "grade": 2
  },
  {
    "id": 937,
    "traditional": "球場",
    "simplified": "球场",
    "reading": "qiúchǎng",
    "meaning": "playing_field, gridiron, court, ball field, field, pitch, course, park, ballpark",
    "grade": 2
  },
  {
    "id": 938,
    "traditional": "求",
    "simplified": "求",
    "reading": "qiú",
    "meaning": "seek, try, strive for",
    "grade": 2
  },
  {
    "id": 939,
    "traditional": "球隊",
    "simplified": "球队",
    "reading": "qiúduì",
    "meaning": "side, team, ball game team",
    "grade": 2
  },
  {
    "id": 940,
    "traditional": "取",
    "simplified": "取",
    "reading": "qǔ",
    "meaning": "have, withdraw, choose, assume, take away, adopt, assumption, select, draw_out, withdrawal, obtain, remove, take, fetch, get, aim at, pick_up, sponge, adoption",
    "grade": 2
  },
  {
    "id": 941,
    "traditional": "球鞋",
    "simplified": "球鞋",
    "reading": "qiúxié",
    "meaning": "athletic shoes",
    "grade": 2
  },
  {
    "id": 942,
    "traditional": "秋天",
    "simplified": "秋天",
    "reading": "qiūtiān",
    "meaning": "autumn, CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 943,
    "traditional": "全",
    "simplified": "全",
    "reading": "quán",
    "meaning": "det.: all, Surname, whole, Quan",
    "grade": 2
  },
  {
    "id": 944,
    "traditional": "取得",
    "simplified": "取得",
    "reading": "qǔdé",
    "meaning": "gain, acquire, obtain",
    "grade": 2
  },
  {
    "id": 945,
    "traditional": "全部",
    "simplified": "全部",
    "reading": "quánbù",
    "meaning": "lot, aggregate, all, gross, entirety, allness, shebang, caboodle, complete, bunch, sum, total, gamut, entire, det.: all, totality, whole, everything",
    "grade": 2
  },
  {
    "id": 946,
    "traditional": "全家",
    "simplified": "全家",
    "reading": "quánjiā",
    "meaning": "whole family",
    "grade": 2
  },
  {
    "id": 947,
    "traditional": "全年",
    "simplified": "全年",
    "reading": "quánnián",
    "meaning": "the whole year, all year long",
    "grade": 2
  },
  {
    "id": 948,
    "traditional": "全身",
    "simplified": "全身",
    "reading": "quánshēn",
    "meaning": "mutton, the whole body, em, whole body, mut",
    "grade": 2
  },
  {
    "id": 949,
    "traditional": "全體",
    "simplified": "全体",
    "reading": "quántǐ",
    "meaning": "great, entirety, general, ensemble, altogether, collectivity, entire, totality, whole body, whole, all, pie, crew, be-all, plenary assembly, en_bloc, wholeness, gamut",
    "grade": 2
  },
  {
    "id": 950,
    "traditional": "全國",
    "simplified": "全国",
    "reading": "quánguó",
    "meaning": "whole nation, nationwide, countrywide, national",
    "grade": 2
  },
  {
    "id": 951,
    "traditional": "熱情",
    "simplified": "热情",
    "reading": "rèqíng",
    "meaning": "enthusiastic",
    "grade": 2
  },
  {
    "id": 952,
    "traditional": "讓",
    "simplified": "让",
    "reading": "ràng",
    "meaning": "allow, induce sb. to do sth.",
    "grade": 2
  },
  {
    "id": 953,
    "traditional": "人們",
    "simplified": "人们",
    "reading": "rénmen",
    "meaning": "workforce, hands, work force, folk, people, they, the people, men, manpower, common people",
    "grade": 2
  },
  {
    "id": 954,
    "traditional": "人口",
    "simplified": "人口",
    "reading": "rénkǒu",
    "meaning": "population",
    "grade": 2
  },
  {
    "id": 955,
    "traditional": "人數",
    "simplified": "人数",
    "reading": "rénshù",
    "meaning": "number of people",
    "grade": 2
  },
  {
    "id": 956,
    "traditional": "然後",
    "simplified": "然后",
    "reading": "ránhòu",
    "meaning": "therewith, and so, and_then, and then, thereupon, after that, so, then, with that",
    "grade": 2
  },
  {
    "id": 957,
    "traditional": "認為",
    "simplified": "认为",
    "reading": "rènwéi",
    "meaning": "find, treat, deem, suppose, look_on, repute, discount, esteem, accredit, feel, account, calculate, estimate, take to be, reckon, expect, think, hold, trust, recognise, opine, sound off, adjudge, consideration, consider, regard as, look upon, believe_in, disbelieve, allow, conceive, regard, call, imagine, view, forecast, believe, suspect, rate, animadvert, look on, listen, speak up, figure, c",
    "grade": 2
  },
  {
    "id": 958,
    "traditional": "日子",
    "simplified": "日子",
    "reading": "rìzi",
    "meaning": "yom, day, a particular day, livelihood, life, date, days, time",
    "grade": 2
  },
  {
    "id": 959,
    "traditional": "日報",
    "simplified": "日报",
    "reading": "rìbào",
    "meaning": "daily newspaper",
    "grade": 2
  },
  {
    "id": 960,
    "traditional": "入口",
    "simplified": "入口",
    "reading": "rù kǒu",
    "meaning": "adit, window, gateway, propylaeum, entranceway, entree, entrance, portal, in door, threshold, inlet, approach, door, entry, entering, access, entryway, intake, ingress",
    "grade": 2
  },
  {
    "id": 961,
    "traditional": "如果",
    "simplified": "如果",
    "reading": "rúguǒ",
    "meaning": "conj.: if",
    "grade": 2
  },
  {
    "id": 962,
    "traditional": "商量",
    "simplified": "商量",
    "reading": "shāngliang",
    "meaning": "talk, consult, arrange, confer, talk over, discussion, advise, discuss, concert",
    "grade": 2
  },
  {
    "id": 963,
    "traditional": "商人",
    "simplified": "商人",
    "reading": "shāngrén",
    "meaning": "merchandiser, trader, businessman, merchant, monger, trafficker, chapman, dealer, cit, merchantman, tradespeople, merchantry, bargainer, man of affairs",
    "grade": 2
  },
  {
    "id": 964,
    "traditional": "上週",
    "simplified": "上周",
    "reading": "shàngzhōu",
    "meaning": "last week",
    "grade": 2
  },
  {
    "id": 965,
    "traditional": "身邊",
    "simplified": "身边",
    "reading": "shēnbiān",
    "meaning": "nearby, side, at hand",
    "grade": 2
  },
  {
    "id": 966,
    "traditional": "少年",
    "simplified": "少年",
    "reading": "shàonián",
    "meaning": "nipper, covey, early youth (10-16), juvenility, adolescent, youngster, teens, gossoon, boy, boy or girl of early youth, spalpeen, juvenile, callan, callant, shaver, lad, laddie, juvenile person, teenager, stripling, young boy, early youth, younker",
    "grade": 2
  },
  {
    "id": 967,
    "traditional": "少數",
    "simplified": "少数",
    "reading": "shǎoshù",
    "meaning": "det.: a few",
    "grade": 2
  },
  {
    "id": 968,
    "traditional": "什麼樣",
    "simplified": "什么样",
    "reading": "shénmeyàng",
    "meaning": "what kind?, what sort?, what appearance?",
    "grade": 2
  },
  {
    "id": 969,
    "traditional": "生",
    "simplified": "生",
    "reading": "shēng",
    "meaning": "give birth to, bear, grow, get, have, cause to happen, make (a fire), exist, live",
    "grade": 2
  },
  {
    "id": 970,
    "traditional": "生詞",
    "simplified": "生词",
    "reading": "shēngcí",
    "meaning": "new word (in textbook), word that is unfamiliar or not yet studied, CL:組|组[zu3],個|个[ge4]",
    "grade": 2
  },
  {
    "id": 971,
    "traditional": "聲音",
    "simplified": "声音",
    "reading": "shēngyīn",
    "meaning": "voice, vocal, language, huskiness, vox, vocalization, audio, noise, sonic, tone, sound, phone, audio frequency",
    "grade": 2
  },
  {
    "id": 972,
    "traditional": "十分",
    "simplified": "十分",
    "reading": "shífēn",
    "meaning": "very, fully, utterly, extremely",
    "grade": 2
  },
  {
    "id": 973,
    "traditional": "生活",
    "simplified": "生活",
    "reading": "shēnghuó",
    "meaning": "life, existence, livelihood",
    "grade": 2
  },
  {
    "id": 974,
    "traditional": "實習",
    "simplified": "实习",
    "reading": "shíxí",
    "meaning": "practice, do fieldwork",
    "grade": 2
  },
  {
    "id": 975,
    "traditional": "實際",
    "simplified": "实际",
    "reading": "shíjì",
    "meaning": "practical, literal",
    "grade": 2
  },
  {
    "id": 976,
    "traditional": "省",
    "simplified": "省",
    "reading": "shěng",
    "meaning": "to save, to economize, to do without, to omit, to leave out, province, CL:個|个[ge4], to inspect, to examine, to be aware, to pay a visit (to one's parents or elders)",
    "grade": 2
  },
  {
    "id": 977,
    "traditional": "實現",
    "simplified": "实现",
    "reading": "shíxiàn",
    "meaning": "realize, achieve, bring about",
    "grade": 2
  },
  {
    "id": 978,
    "traditional": "食物",
    "simplified": "食物",
    "reading": "shíwù",
    "meaning": "scran, board, ingesta, sustentation, provender, viand, chow, aliment, pabulum, edibles, prog, nurture, cheer, eatable, edible, alimentary, eatables, chowchow, nutrient, diet, bread, trencher, sustenance, nutriment, nosh, nutrition, victuals, cib., vivers, eating, toke, meat, cib, grub, peck, viands, comestible, nourishment, fare, victual, food, commons, fodder, scoff, victualage, muckamuck, ta",
    "grade": 2
  },
  {
    "id": 979,
    "traditional": "實在",
    "simplified": "实在",
    "reading": "shízai",
    "meaning": "indeed, really, honestly, in fact, as a matter of fact",
    "grade": 2
  },
  {
    "id": 980,
    "traditional": "使用",
    "simplified": "使用",
    "reading": "shǐyòng",
    "meaning": "use, employ, apply",
    "grade": 2
  },
  {
    "id": 981,
    "traditional": "市長",
    "simplified": "市长",
    "reading": "shìzhǎng",
    "meaning": "alcalde, city manager, mayor, portreeve, mayoral, civic leader, provost, civil leader, burgomaster",
    "grade": 2
  },
  {
    "id": 982,
    "traditional": "市",
    "simplified": "市",
    "reading": "shì",
    "meaning": "market, city, CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 983,
    "traditional": "事情",
    "simplified": "事情",
    "reading": "shìqing",
    "meaning": "proposition, pie, shebang, thing, affair, occasions, undertaking, concern, question, business, schmear, matter",
    "grade": 2
  },
  {
    "id": 984,
    "traditional": "收到",
    "simplified": "收到",
    "reading": "shōu dào",
    "meaning": "hear, find, achievement, receive, receipt, obtain, achieve, incur, get, come_to",
    "grade": 2
  },
  {
    "id": 985,
    "traditional": "收入",
    "simplified": "收入",
    "reading": "shōurù",
    "meaning": "earning, incoming, gainings, finance, ingathering, proceed, receipts, income, coming-in, taking, pocket, earnings, fruit, revenue, proceeds, living",
    "grade": 2
  },
  {
    "id": 986,
    "traditional": "手錶",
    "simplified": "手表",
    "reading": "shǒubiǎo",
    "meaning": "wristwatch",
    "grade": 2
  },
  {
    "id": 987,
    "traditional": "受到",
    "simplified": "受到",
    "reading": "shòudào",
    "meaning": "experience, receive, come in for, suffer, undergo, come_in_for, be given, come_to, be subjected to",
    "grade": 2
  },
  {
    "id": 988,
    "traditional": "舒服",
    "simplified": "舒服",
    "reading": "shūfu",
    "meaning": "comfortable",
    "grade": 2
  },
  {
    "id": 989,
    "traditional": "熟",
    "simplified": "熟",
    "reading": "shú",
    "meaning": "ripe, cooked, done, deep (sleep, thoughts, etc.)",
    "grade": 2
  },
  {
    "id": 990,
    "traditional": "收",
    "simplified": "收",
    "reading": "shōu",
    "meaning": "receive, accept, take in/back, gather in, conclude, stop",
    "grade": 2
  },
  {
    "id": 991,
    "traditional": "數字",
    "simplified": "数字",
    "reading": "shùzì",
    "meaning": "numeral, numerical, digit, amount, number, numeric, figure, digital, quantity",
    "grade": 2
  },
  {
    "id": 992,
    "traditional": "水平",
    "simplified": "水平",
    "reading": "shuǐpíng",
    "meaning": "level, horizontal, grade, degree, standard, horizontality",
    "grade": 2
  },
  {
    "id": 993,
    "traditional": "順利",
    "simplified": "顺利",
    "reading": "shùnlì",
    "meaning": "smooth, successful, without a hitch",
    "grade": 2
  },
  {
    "id": 994,
    "traditional": "說明",
    "simplified": "说明",
    "reading": "shuōmíng",
    "meaning": "explain, illustrate, show",
    "grade": 2
  },
  {
    "id": 995,
    "traditional": "司機",
    "simplified": "司机",
    "reading": "sījī",
    "meaning": "driver",
    "grade": 2
  },
  {
    "id": 996,
    "traditional": "送到",
    "simplified": "送到",
    "reading": "sòng dào",
    "meaning": "Deliver",
    "grade": 2
  },
  {
    "id": 997,
    "traditional": "數",
    "simplified": "数",
    "reading": "shù",
    "meaning": "det.: several",
    "grade": 2
  },
  {
    "id": 998,
    "traditional": "算",
    "simplified": "算",
    "reading": "suàn",
    "meaning": "consider, regard/count as",
    "grade": 2
  },
  {
    "id": 999,
    "traditional": "送給",
    "simplified": "送给",
    "reading": "sònggěi",
    "meaning": "present to, to give as a present, send to, give, send/present to, to present",
    "grade": 2
  },
  {
    "id": 1000,
    "traditional": "隨便",
    "simplified": "随便",
    "reading": "suí biàn",
    "meaning": "informal, willful, do as one pleases, wanton, casual, random, careless",
    "grade": 2
  },
  {
    "id": 1001,
    "traditional": "隨時",
    "simplified": "随时",
    "reading": "suíshí",
    "meaning": "whenever, whene'er, always, readily, whenever necessary, at all times, as occasion demands, at any time",
    "grade": 2
  },
  {
    "id": 1002,
    "traditional": "所以",
    "simplified": "所以",
    "reading": "suǒyǐ",
    "meaning": "conj.: so, therefore, as a result",
    "grade": 2
  },
  {
    "id": 1003,
    "traditional": "雖然",
    "simplified": "虽然",
    "reading": "suīrán",
    "meaning": "conj.: though, although",
    "grade": 2
  },
  {
    "id": 1004,
    "traditional": "所有",
    "simplified": "所有",
    "reading": "suǒyǒu",
    "meaning": "det.: all",
    "grade": 2
  },
  {
    "id": 1005,
    "traditional": "它",
    "simplified": "它",
    "reading": "tā",
    "meaning": "they (neologism for {1ta1}[3] [4]), [4]), ), this, that, it",
    "grade": 2
  },
  {
    "id": 1006,
    "traditional": "它們",
    "simplified": "它们",
    "reading": "tāmen",
    "meaning": "they, they (non-human)",
    "grade": 2
  },
  {
    "id": 1007,
    "traditional": "太太",
    "simplified": "太太",
    "reading": "tàitai",
    "meaning": "lady, memsahib, Dona, dona, Mrs, mum, Senora, senora, Mrs., mistress of a household, madam, Frau, goodwife, missus, senhora, married woman, wife, vrouw, Madame, madame, missis, wahine, Signora, signora",
    "grade": 2
  },
  {
    "id": 1008,
    "traditional": "太陽",
    "simplified": "太阳",
    "reading": "tàiyáng",
    "meaning": "Apollo, luminary, daystar, digit, sunlight, solar, sunshine, Phoebus, lamp, sun, Sol, fireball",
    "grade": 2
  },
  {
    "id": 1009,
    "traditional": "討論",
    "simplified": "讨论",
    "reading": "tǎolùn",
    "meaning": "to discuss, to take something up with someone, to talk about",
    "grade": 2
  },
  {
    "id": 1010,
    "traditional": "態度",
    "simplified": "态度",
    "reading": "tàidu",
    "meaning": "stance, treatment, demeanor, dealing, deportment, pose, behavior, manner, demeanour, way, bearing, attitude, air, behaviour, front, mien, conduct, mental attitude, approach, posture, address, position, bedside_manner, movement, gesture, comportment, presence, sort",
    "grade": 2
  },
  {
    "id": 1011,
    "traditional": "套",
    "simplified": "套",
    "reading": "tào",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 1012,
    "traditional": "特點",
    "simplified": "特点",
    "reading": "tèdiǎn",
    "meaning": "distinctive feature, trait, point, flavor, singularity, flavour, distinction, characteristic, excellence, speciality, specialty, idiosyncrasy, salience, distinctiveness, specialness, feature, spot, peculiarity",
    "grade": 2
  },
  {
    "id": 1013,
    "traditional": "疼",
    "simplified": "疼",
    "reading": "téng",
    "meaning": "(it) hurts, sore, to love dearly",
    "grade": 2
  },
  {
    "id": 1014,
    "traditional": "提",
    "simplified": "提",
    "reading": "tí",
    "meaning": "mention, refer to, promote, bring up",
    "grade": 2
  },
  {
    "id": 1015,
    "traditional": "特別",
    "simplified": "特别",
    "reading": "tèbié",
    "meaning": "unusual, specially",
    "grade": 2
  },
  {
    "id": 1016,
    "traditional": "提出",
    "simplified": "提出",
    "reading": "tí chū",
    "meaning": "put forward, pose, raise",
    "grade": 2
  },
  {
    "id": 1017,
    "traditional": "提到",
    "simplified": "提到",
    "reading": "tídào",
    "meaning": "mention, refer to",
    "grade": 2
  },
  {
    "id": 1018,
    "traditional": "提高",
    "simplified": "提高",
    "reading": "tí gāo",
    "meaning": "raise, heighten, enhance, increase, improve",
    "grade": 2
  },
  {
    "id": 1019,
    "traditional": "體育",
    "simplified": "体育",
    "reading": "tǐyù",
    "meaning": "training, physical education, athletics, sports, gymnastic, physical_education, physical training, physical culture, physical education/training, gym",
    "grade": 2
  },
  {
    "id": 1020,
    "traditional": "題",
    "simplified": "题",
    "reading": "tí",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 1021,
    "traditional": "體育場",
    "simplified": "体育场",
    "reading": "tǐyùchǎng",
    "meaning": "stadium, CL:個|个[ge4],座[zuo4]",
    "grade": 2
  },
  {
    "id": 1022,
    "traditional": "體育館",
    "simplified": "体育馆",
    "reading": "tǐyùguǎn",
    "meaning": "stadium, coliseum, gym, turnhall, palestra, gymnasium",
    "grade": 2
  },
  {
    "id": 1023,
    "traditional": "天上",
    "simplified": "天上",
    "reading": "tiānshang",
    "meaning": "celestial, heavenly",
    "grade": 2
  },
  {
    "id": 1024,
    "traditional": "條",
    "simplified": "条",
    "reading": "tiáo",
    "meaning": "bar, item, short note, a long narrow piece, streak, clause, stripe, sth. long and narrow, filet, m.[general], , article, strip, assize, twig, ingot, slip",
    "grade": 2
  },
  {
    "id": 1025,
    "traditional": "條件",
    "simplified": "条件",
    "reading": "tiáojiàn",
    "meaning": "mood, formula, conditional, precondition, prerequisite, requisition, factor, qualification, requirement, stipulation, proviso, circumstances, provision, term, reservation, if, condition",
    "grade": 2
  },
  {
    "id": 1026,
    "traditional": "聽說",
    "simplified": "听说",
    "reading": "tīngshuō",
    "meaning": "hear, understand, learn, rumor, hear that..., hear/understand that..., be told, understand that..., hear of",
    "grade": 2
  },
  {
    "id": 1027,
    "traditional": "停車",
    "simplified": "停车",
    "reading": "tíng chē",
    "meaning": "park the car",
    "grade": 2
  },
  {
    "id": 1028,
    "traditional": "聽講",
    "simplified": "听讲",
    "reading": "tīng jiǎng",
    "meaning": "to attend a lecture, to listen to a talk",
    "grade": 2
  },
  {
    "id": 1029,
    "traditional": "停",
    "simplified": "停",
    "reading": "tíng",
    "meaning": "stay, cease, put_off, cessation, stop, switch off, cheese, stop over, cut off, halt, turn off, lie at anchor, pause, park",
    "grade": 2
  },
  {
    "id": 1030,
    "traditional": "挺",
    "simplified": "挺",
    "reading": "tǐng",
    "meaning": "very, rather, quite",
    "grade": 2
  },
  {
    "id": 1031,
    "traditional": "停車場",
    "simplified": "停车场",
    "reading": "tíngchēchǎng",
    "meaning": "parking_lot, parking area, parking, car_park, parking lot, car park, halt, yard, park",
    "grade": 2
  },
  {
    "id": 1032,
    "traditional": "通",
    "simplified": "通",
    "reading": "tōng",
    "meaning": "m.[event]",
    "grade": 2
  },
  {
    "id": 1033,
    "traditional": "挺好",
    "simplified": "挺好",
    "reading": "tǐnghǎo",
    "meaning": "very good",
    "grade": 2
  },
  {
    "id": 1034,
    "traditional": "通知",
    "simplified": "通知",
    "reading": "tōngzhī",
    "meaning": "notify, inform",
    "grade": 2
  },
  {
    "id": 1035,
    "traditional": "同時",
    "simplified": "同时",
    "reading": "tóngshí",
    "meaning": "at the same time, simultaneously",
    "grade": 2
  },
  {
    "id": 1036,
    "traditional": "同樣",
    "simplified": "同样",
    "reading": "tóngyàng",
    "meaning": "same, equal, similar",
    "grade": 2
  },
  {
    "id": 1037,
    "traditional": "同事",
    "simplified": "同事",
    "reading": "tóngshì",
    "meaning": "brother, coadjutor, workmate, friend, yokefellow, co-worker, fellow worker, confrere, colleague, stablemate, companion, crew, workfellow, staff, socius, coworker, bedfellow, fellow, haver, rival, comrade",
    "grade": 2
  },
  {
    "id": 1038,
    "traditional": "通過",
    "simplified": "通过",
    "reading": "tōngguò",
    "meaning": "adopt, pass, carry (motion/legislation)",
    "grade": 2
  },
  {
    "id": 1039,
    "traditional": "頭",
    "simplified": "头",
    "reading": "tóu",
    "meaning": "head, hair (style), top/end of sth., chief",
    "grade": 2
  },
  {
    "id": 1040,
    "traditional": "頭髮",
    "simplified": "头发",
    "reading": "tóufa",
    "meaning": "barba, chevelure, lock, head of hair, hair, tress, mane, hair (on human head)",
    "grade": 2
  },
  {
    "id": 1041,
    "traditional": "圖片",
    "simplified": "图片",
    "reading": "túpiàn",
    "meaning": "pictorial matter, picture, photograph, pic, artwork",
    "grade": 2
  },
  {
    "id": 1042,
    "traditional": "腿",
    "simplified": "腿",
    "reading": "tuǐ",
    "meaning": "ham, a leg-like support, underpinning, thigh, gamb, shank, supporter, leg",
    "grade": 2
  },
  {
    "id": 1043,
    "traditional": "外地",
    "simplified": "外地",
    "reading": "wàidì",
    "meaning": "parts of the country other than where one is, place other than where one is",
    "grade": 2
  },
  {
    "id": 1044,
    "traditional": "完",
    "simplified": "完",
    "reading": "wán",
    "meaning": "exhaust, run_out, wipe_out, run out, pay, complete, completion, finish, be through, be over, use_up, wind up, payment, use up, pay (taxes), end up",
    "grade": 2
  },
  {
    "id": 1045,
    "traditional": "完成",
    "simplified": "完成",
    "reading": "wán chéng",
    "meaning": "fill in, finish out, fulfil, practice, complete, finish, encompass, clear_up, top_off, cleave, top off, accomplishment, make out, effect, perfective, fulfill, go through, exercise, discharge, fill out, completion, end, run, bring ... to fruition, round_out, achieve, execute, round out, make, cap off, accomplish, practise, follow through, come, finalize, fulfilment, dispatch, bring ...",
    "grade": 2
  },
  {
    "id": 1046,
    "traditional": "完全",
    "simplified": "完全",
    "reading": "wánquán",
    "meaning": "completely, fully, wholly, absolutely",
    "grade": 2
  },
  {
    "id": 1047,
    "traditional": "外賣",
    "simplified": "外卖",
    "reading": "wàimài",
    "meaning": "to go, take out (relates to food bought at a restaurant)",
    "grade": 2
  },
  {
    "id": 1048,
    "traditional": "推",
    "simplified": "推",
    "reading": "tuī",
    "meaning": "push, decline, refuse, reject, put off, postpone, delay",
    "grade": 2
  },
  {
    "id": 1049,
    "traditional": "晚報",
    "simplified": "晚报",
    "reading": "wǎnbào",
    "meaning": "evening newspaper, (in a newspaper's name) Evening News",
    "grade": 2
  },
  {
    "id": 1050,
    "traditional": "晚安",
    "simplified": "晚安",
    "reading": "wǎnān",
    "meaning": "Good night!, Good evening!",
    "grade": 2
  },
  {
    "id": 1051,
    "traditional": "晚餐",
    "simplified": "晚餐",
    "reading": "wǎncān",
    "meaning": "dinner, supper",
    "grade": 2
  },
  {
    "id": 1052,
    "traditional": "晚會",
    "simplified": "晚会",
    "reading": "wǎnhuì",
    "meaning": "evening entertainment, soiree, evening party, social evening, party, evening, evening entertainment/party",
    "grade": 2
  },
  {
    "id": 1053,
    "traditional": "碗",
    "simplified": "碗",
    "reading": "wǎn",
    "meaning": "m.[container]",
    "grade": 2
  },
  {
    "id": 1054,
    "traditional": "網",
    "simplified": "网",
    "reading": "wǎng",
    "meaning": "net",
    "grade": 2
  },
  {
    "id": 1055,
    "traditional": "萬",
    "simplified": "万",
    "reading": "wàn",
    "meaning": "det.: ten thousand, myriad, multitudinous",
    "grade": 2
  },
  {
    "id": 1056,
    "traditional": "網球",
    "simplified": "网球",
    "reading": "wǎngqiú",
    "meaning": "lawn tennis, tennis, follow-through, tennis ball",
    "grade": 2
  },
  {
    "id": 1057,
    "traditional": "網站",
    "simplified": "网站",
    "reading": "wǎngzhàn",
    "meaning": "website, network station, node",
    "grade": 2
  },
  {
    "id": 1058,
    "traditional": "往",
    "simplified": "往",
    "reading": "wǎng",
    "meaning": "go toward",
    "grade": 2
  },
  {
    "id": 1059,
    "traditional": "為什麼",
    "simplified": "为什么",
    "reading": "wèishénme",
    "meaning": "why, why?, how come, why is it that?, whereat, whereto",
    "grade": 2
  },
  {
    "id": 1060,
    "traditional": "為",
    "simplified": "为",
    "reading": "wèi",
    "meaning": "by",
    "grade": 2
  },
  {
    "id": 1061,
    "traditional": "位",
    "simplified": "位",
    "reading": "wèi",
    "meaning": "location, digit, m.[general], , status, figure, throne, position, place, condition",
    "grade": 2
  },
  {
    "id": 1062,
    "traditional": "味道",
    "simplified": "味道",
    "reading": "wèidao",
    "meaning": "gustatory perception, gust, tang, taste perception, feeling, savor, flavor, taste sensation, savour, streak, flavour, relish, sapidity, smatch, soupcon, tincture, tasting, smell, smack, gustatory sensation, suggestion, snack, taste",
    "grade": 2
  },
  {
    "id": 1063,
    "traditional": "喂",
    "simplified": "喂",
    "reading": "wèi",
    "meaning": "hello, hey",
    "grade": 2
  },
  {
    "id": 1064,
    "traditional": "聞",
    "simplified": "闻",
    "reading": "wén",
    "meaning": "smell",
    "grade": 2
  },
  {
    "id": 1065,
    "traditional": "問路",
    "simplified": "问路",
    "reading": "wèn lù",
    "meaning": "to ask for directions, to ask the way (to some place)",
    "grade": 2
  },
  {
    "id": 1066,
    "traditional": "午餐",
    "simplified": "午餐",
    "reading": "wǔcān",
    "meaning": "midday meal, lunch",
    "grade": 2
  },
  {
    "id": 1067,
    "traditional": "問題",
    "simplified": "问题",
    "reading": "wèntí",
    "meaning": "problem, mishap, query, job, interface, question, inquiry, matter, enquiry, trouble, issue, worry, subject, topic, hang-up, business, quaere",
    "grade": 2
  },
  {
    "id": 1068,
    "traditional": "午睡",
    "simplified": "午睡",
    "reading": "wǔshuì",
    "meaning": "to take a nap, siesta",
    "grade": 2
  },
  {
    "id": 1069,
    "traditional": "西北",
    "simplified": "西北",
    "reading": "xīběi",
    "meaning": "northwest, Northwest China, the Northwest",
    "grade": 2
  },
  {
    "id": 1070,
    "traditional": "溫度",
    "simplified": "温度",
    "reading": "wēndù",
    "meaning": "barothermohygrograph, temperature, barothermohygrogram",
    "grade": 2
  },
  {
    "id": 1071,
    "traditional": "西南",
    "simplified": "西南",
    "reading": "xīnán",
    "meaning": "southwest",
    "grade": 2
  },
  {
    "id": 1072,
    "traditional": "西方",
    "simplified": "西方",
    "reading": "xīfāng",
    "meaning": "the Occident, West, New World, occidental, westward, west, the West, the west, Hesperian, occident, Western, sunset, western hemisphere, western",
    "grade": 2
  },
  {
    "id": 1073,
    "traditional": "西醫",
    "simplified": "西医",
    "reading": "xīyī",
    "meaning": "Western medicine, a doctor trained in Western medicine",
    "grade": 2
  },
  {
    "id": 1074,
    "traditional": "西餐",
    "simplified": "西餐",
    "reading": "xīcān",
    "meaning": "Western-style food, CL:份[fen4],頓|顿[dun4]",
    "grade": 2
  },
  {
    "id": 1075,
    "traditional": "習慣",
    "simplified": "习惯",
    "reading": "xíguàn",
    "meaning": "habit, custom",
    "grade": 2
  },
  {
    "id": 1076,
    "traditional": "洗衣機",
    "simplified": "洗衣机",
    "reading": "xǐyījī",
    "meaning": "washer, washing machine, CL:臺|台[tai2]",
    "grade": 2
  },
  {
    "id": 1077,
    "traditional": "下",
    "simplified": "下",
    "reading": "xià",
    "meaning": "under (condition/fact etc.)",
    "grade": 2
  },
  {
    "id": 1078,
    "traditional": "洗澡",
    "simplified": "洗澡",
    "reading": "xǐ zǎo",
    "meaning": "have a bath, lave, take a bath, tub, bath, wash, bathing, bathe",
    "grade": 2
  },
  {
    "id": 1079,
    "traditional": "下週",
    "simplified": "下周",
    "reading": "xiàzhōu",
    "meaning": "next week",
    "grade": 2
  },
  {
    "id": 1080,
    "traditional": "相同",
    "simplified": "相同",
    "reading": "xiāngtóng",
    "meaning": "be identical/alike, uniform, be identical, overlap, alike, identical, be alike",
    "grade": 2
  },
  {
    "id": 1081,
    "traditional": "夏天",
    "simplified": "夏天",
    "reading": "xiàtiān",
    "meaning": "summer, CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 1082,
    "traditional": "相信",
    "simplified": "相信",
    "reading": "xiāngxìn",
    "meaning": "deem, believe, credit, esteem, accredit, feel, trow, count, have faith in, guess, lippen, buy, be convinced of, hold, think, trust, accept, know, hone, believe_in, favour, believe in, hope",
    "grade": 2
  },
  {
    "id": 1083,
    "traditional": "想到",
    "simplified": "想到",
    "reading": "xiǎng dào",
    "meaning": "imagine, ideate, think, envisage, anticipate, bethink, dream, recollect, think_of, call to mind, think of, occur, conceive of",
    "grade": 2
  },
  {
    "id": 1084,
    "traditional": "想法",
    "simplified": "想法",
    "reading": "xiǎngfa",
    "meaning": "view, stance, mentality, idea, notion, sentiment, judgment, construct, thought, opinion, conception, viewpoint, judgement, belief, mind-set, what one has in mind, persuasion, perspective, concept, mind, impression",
    "grade": 2
  },
  {
    "id": 1085,
    "traditional": "下雪",
    "simplified": "下雪",
    "reading": "xiàxuě",
    "meaning": "to snow",
    "grade": 2
  },
  {
    "id": 1086,
    "traditional": "想起",
    "simplified": "想起",
    "reading": "xiǎng qǐ",
    "meaning": "retrieve, think back, envisage, hit, echo, remember, think_of, call to mind, recall, recollect, think of",
    "grade": 2
  },
  {
    "id": 1087,
    "traditional": "嚮",
    "simplified": "向",
    "reading": "xiàng",
    "meaning": "to, towards",
    "grade": 2
  },
  {
    "id": 1088,
    "traditional": "相機",
    "simplified": "相机",
    "reading": "xiàngjī",
    "meaning": "camera",
    "grade": 2
  },
  {
    "id": 1089,
    "traditional": "響",
    "simplified": "响",
    "reading": "xiǎng",
    "meaning": "make a sound",
    "grade": 2
  },
  {
    "id": 1090,
    "traditional": "像",
    "simplified": "像",
    "reading": "xiàng",
    "meaning": "be like",
    "grade": 2
  },
  {
    "id": 1091,
    "traditional": "小聲",
    "simplified": "小声",
    "reading": "xiǎoshēng",
    "meaning": "in a low voice, (speak) in whispers",
    "grade": 2
  },
  {
    "id": 1092,
    "traditional": "小時候",
    "simplified": "小时候",
    "reading": "xiǎoshíhou",
    "meaning": "in one's childhood",
    "grade": 2
  },
  {
    "id": 1093,
    "traditional": "小說",
    "simplified": "小说",
    "reading": "xiǎoshuō",
    "meaning": "novel, offprint, fiction",
    "grade": 2
  },
  {
    "id": 1094,
    "traditional": "小心",
    "simplified": "小心",
    "reading": "xiǎoxin",
    "meaning": "beware, watch_out, keep one's eyes skinned, keep one's eyes peeled, careful, cautious, be careful, see, watch, guard, ware, cautionary, keep one's eyes open, take care, look_out, look out",
    "grade": 2
  },
  {
    "id": 1095,
    "traditional": "小",
    "simplified": "小",
    "reading": "xiǎo",
    "meaning": "small, little, petty, minor;young",
    "grade": 2
  },
  {
    "id": 1096,
    "traditional": "小組",
    "simplified": "小组",
    "reading": "xiǎozǔ",
    "meaning": "squad, section, unit, group",
    "grade": 2
  },
  {
    "id": 1097,
    "traditional": "校園",
    "simplified": "校园",
    "reading": "xiàoyuán",
    "meaning": "schoolyard, campus",
    "grade": 2
  },
  {
    "id": 1098,
    "traditional": "校長",
    "simplified": "校长",
    "reading": "xiàozhǎng",
    "meaning": "prexy, head, principal, headteacher, schoolmaster, master, warden, president, beak, rector, superintendent, director, head teacher, preceptor, headmaster, chancellor, school principal",
    "grade": 2
  },
  {
    "id": 1099,
    "traditional": "笑話兒",
    "simplified": "笑话儿",
    "reading": "xiàohuar5",
    "meaning": "Joke",
    "grade": 2
  },
  {
    "id": 1100,
    "traditional": "笑話",
    "simplified": "笑话",
    "reading": "xiàohua",
    "meaning": "joke, jest",
    "grade": 2
  },
  {
    "id": 1101,
    "traditional": "心裡",
    "simplified": "心里",
    "reading": "xīnli",
    "meaning": "chest, heart, mind",
    "grade": 2
  },
  {
    "id": 1102,
    "traditional": "心情",
    "simplified": "心情",
    "reading": "xīnqíng",
    "meaning": "cognitive state, cue, frame of mind, form, stomach, pulse, humor, feeling, temper, state of mind, cheer, humour, frame/state of mind, mood, vein, spirit, feelings, frame, frame_of_mind, habit of mind, mind, tune",
    "grade": 2
  },
  {
    "id": 1103,
    "traditional": "鞋",
    "simplified": "鞋",
    "reading": "xié",
    "meaning": "shoe, variant of 鞋[xie2]",
    "grade": 2
  },
  {
    "id": 1104,
    "traditional": "心中",
    "simplified": "心中",
    "reading": "xīnzhōng",
    "meaning": "central point, in one's thoughts, in one's heart",
    "grade": 2
  },
  {
    "id": 1105,
    "traditional": "信",
    "simplified": "信",
    "reading": "xìn",
    "meaning": "letter, mail, message",
    "grade": 2
  },
  {
    "id": 1106,
    "traditional": "新聞",
    "simplified": "新闻",
    "reading": "xīnwén",
    "meaning": "unco, leak, intelligence, newsworthiness, witting, journalistic, information, journalism, courant, news",
    "grade": 2
  },
  {
    "id": 1107,
    "traditional": "信號",
    "simplified": "信号",
    "reading": "xìnhào",
    "meaning": "sign, cue, beacon, code, beacon fire, messenger, signal, semaphore, signaling, waft",
    "grade": 2
  },
  {
    "id": 1108,
    "traditional": "信心",
    "simplified": "信心",
    "reading": "xìnxīn",
    "meaning": "trustfulness, trustingness, confidence, belief, affiance, religion, feeling, fay, trust, positiveness, notion, reliance, religious belief, faith, hope, impression",
    "grade": 2
  },
  {
    "id": 1109,
    "traditional": "信用卡",
    "simplified": "信用卡",
    "reading": "xìnyòngkǎ",
    "meaning": "charge plate, charge card, credit card, credit_card, charge_card, bank card",
    "grade": 2
  },
  {
    "id": 1110,
    "traditional": "信息",
    "simplified": "信息",
    "reading": "xìnxī",
    "meaning": "information, news, message",
    "grade": 2
  },
  {
    "id": 1111,
    "traditional": "星星",
    "simplified": "星星",
    "reading": "xīngxing",
    "meaning": "star",
    "grade": 2
  },
  {
    "id": 1112,
    "traditional": "行動",
    "simplified": "行动",
    "reading": "xíngdòng",
    "meaning": "action, operation",
    "grade": 2
  },
  {
    "id": 1113,
    "traditional": "行人",
    "simplified": "行人",
    "reading": "xíngrén",
    "meaning": "foot traffic, passerby, goer, pedestrian, pedestrian traffic, passenger",
    "grade": 2
  },
  {
    "id": 1114,
    "traditional": "姓",
    "simplified": "姓",
    "reading": "xìng",
    "meaning": "name",
    "grade": 2
  },
  {
    "id": 1115,
    "traditional": "行為",
    "simplified": "行为",
    "reading": "xíngwéi",
    "meaning": "fact, demeanor, form, deportment, dealing, behavior, doings, course, act, point, goings-on, demeanour, procedure, lapse, going, bearing, turn, behaviour, activity, ongoing, thing, human action, conduct, commitment, human activity, behavioral, agency, deed, comportment, action",
    "grade": 2
  },
  {
    "id": 1116,
    "traditional": "姓名",
    "simplified": "姓名",
    "reading": "xìngmíng",
    "meaning": "surname and personal name, name, full name",
    "grade": 2
  },
  {
    "id": 1117,
    "traditional": "學期",
    "simplified": "学期",
    "reading": "xuéqī",
    "meaning": "school term, semester",
    "grade": 2
  },
  {
    "id": 1118,
    "traditional": "休假",
    "simplified": "休假",
    "reading": "xiū jià",
    "meaning": "have holiday/vacation/leave",
    "grade": 2
  },
  {
    "id": 1119,
    "traditional": "許多",
    "simplified": "许多",
    "reading": "xǔduō",
    "meaning": "lot, scores, lots, sight, pot, heap, quantity, plenty, slews, ton, battalion, many, scads, tidy sum, power, spate, chunk, pile, crowd, vastness, batch, lashings, whole lot, mint, mound, pocketful, large number, trunkful, ream, mickle, great deal, flock, mass, slew, heaps, a lot of, piles, whole slew, mess, raff, slue, gobs, much, lotta, multitude, volume, wad, store, raft, tons, satiety, rafts, doze",
    "grade": 2
  },
  {
    "id": 1120,
    "traditional": "選",
    "simplified": "选",
    "reading": "xuǎn",
    "meaning": "select, pick, elect, choose, take, pick out, van",
    "grade": 2
  },
  {
    "id": 1121,
    "traditional": "顏色",
    "simplified": "颜色",
    "reading": "yánsè",
    "meaning": "companion, countenance, pigment, coloring, tincture, dyestuff, colour, facial expression, tinct, grain, colouring, color",
    "grade": 2
  },
  {
    "id": 1122,
    "traditional": "雪",
    "simplified": "雪",
    "reading": "xuě",
    "meaning": "snow",
    "grade": 2
  },
  {
    "id": 1123,
    "traditional": "眼睛",
    "simplified": "眼睛",
    "reading": "yǎnjing",
    "meaning": "eye",
    "grade": 2
  },
  {
    "id": 1124,
    "traditional": "眼",
    "simplified": "眼",
    "reading": "yǎn",
    "meaning": "eye, small hole, aperture, salient/weighted point, salient/weighted point",
    "grade": 2
  },
  {
    "id": 1125,
    "traditional": "養",
    "simplified": "养",
    "reading": "yǎng",
    "meaning": "keep in good repair, keep, cultivate, cultivation, form, maintenance, foster, give birth to, convalesce, sustain, nourish, support, provide for, convalescence, grow, raise, maintain, acquire, rest, recuperate",
    "grade": 2
  },
  {
    "id": 1126,
    "traditional": "樣子",
    "simplified": "样子",
    "reading": "yàngzi",
    "meaning": "tendency, pattern, form, manner, likelihood, way, resemblance, semblance, sample, guise, fashion, wise, model, air, mien, appearance, shape, suggestion, gesture, sort",
    "grade": 2
  },
  {
    "id": 1127,
    "traditional": "要求",
    "simplified": "要求",
    "reading": "yāoqiú",
    "meaning": "request, demand",
    "grade": 2
  },
  {
    "id": 1128,
    "traditional": "藥片",
    "simplified": "药片",
    "reading": "yàopiàn",
    "meaning": "a (medicine) pill or tablet, CL:片[pian4]",
    "grade": 2
  },
  {
    "id": 1129,
    "traditional": "藥",
    "simplified": "药",
    "reading": "yào",
    "meaning": "healer, medicine, certain chemicals, drug, medicament, medicinal drug, aspirin, medication, remedy, physic",
    "grade": 2
  },
  {
    "id": 1130,
    "traditional": "也許",
    "simplified": "也许",
    "reading": "yěxǔ",
    "meaning": "possibly, peradventure, conceivably, assumedly, mayhap, doubtless, ablings, perhaps, perchance, potentially, probably, maybe, presumably, ablins, just",
    "grade": 2
  },
  {
    "id": 1131,
    "traditional": "藥水",
    "simplified": "药水",
    "reading": "yàoshuǐ",
    "meaning": "Yaksu in North Korea, near the border with Liaoning and Jiling province, medicine in liquid form, bottled medicine, lotion",
    "grade": 2
  },
  {
    "id": 1132,
    "traditional": "夜",
    "simplified": "夜",
    "reading": "yè",
    "meaning": "night",
    "grade": 2
  },
  {
    "id": 1133,
    "traditional": "藥店",
    "simplified": "药店",
    "reading": "yàodiàn",
    "meaning": "pharmacy",
    "grade": 2
  },
  {
    "id": 1134,
    "traditional": "夜裡",
    "simplified": "夜里",
    "reading": "yèli",
    "meaning": "during the night, at night, nighttime",
    "grade": 2
  },
  {
    "id": 1135,
    "traditional": "一定",
    "simplified": "一定",
    "reading": "yīdìng",
    "meaning": "must",
    "grade": 2
  },
  {
    "id": 1136,
    "traditional": "一部分",
    "simplified": "一部分",
    "reading": "yībùfen",
    "meaning": "portion, part of, subset",
    "grade": 2
  },
  {
    "id": 1137,
    "traditional": "一會兒",
    "simplified": "一会儿",
    "reading": "yīhuìr5",
    "meaning": "a moment, a while, in a moment, now...now..., also pr. [yi1 hui3 r5]",
    "grade": 2
  },
  {
    "id": 1138,
    "traditional": "一路平安",
    "simplified": "一路平安",
    "reading": "yīlù-píngān",
    "meaning": "to have a pleasant journey, Bon voyage!",
    "grade": 2
  },
  {
    "id": 1139,
    "traditional": "已經",
    "simplified": "已经",
    "reading": "yǐjīng",
    "meaning": "already",
    "grade": 2
  },
  {
    "id": 1140,
    "traditional": "一路順風",
    "simplified": "一路顺风",
    "reading": "yīlùshùnfēng",
    "meaning": "to have a pleasant journey (idiom)",
    "grade": 2
  },
  {
    "id": 1141,
    "traditional": "以前",
    "simplified": "以前",
    "reading": "yǐqián",
    "meaning": "langsyne, ago, in the past, formerly, prevenient, before, previously",
    "grade": 2
  },
  {
    "id": 1142,
    "traditional": "一共",
    "simplified": "一共",
    "reading": "yīgòng",
    "meaning": "altogether, in all, all told",
    "grade": 2
  },
  {
    "id": 1143,
    "traditional": "以後",
    "simplified": "以后",
    "reading": "yǐhòu",
    "meaning": "afterwards, later, future, in the future, hereafter",
    "grade": 2
  },
  {
    "id": 1144,
    "traditional": "以上",
    "simplified": "以上",
    "reading": "yǐshàng",
    "meaning": "the above/foregoing/above-mentioned",
    "grade": 2
  },
  {
    "id": 1145,
    "traditional": "以外",
    "simplified": "以外",
    "reading": "yǐwài",
    "meaning": "other than, except, beyond, outside",
    "grade": 2
  },
  {
    "id": 1146,
    "traditional": "椅子",
    "simplified": "椅子",
    "reading": "yǐzi",
    "meaning": "chair",
    "grade": 2
  },
  {
    "id": 1147,
    "traditional": "以下",
    "simplified": "以下",
    "reading": "yǐxià",
    "meaning": "the following",
    "grade": 2
  },
  {
    "id": 1148,
    "traditional": "一點點",
    "simplified": "一点点",
    "reading": "yīdiǎndiǎn",
    "meaning": "a little bit",
    "grade": 2
  },
  {
    "id": 1149,
    "traditional": "一般",
    "simplified": "一般",
    "reading": "yībān",
    "meaning": "general, ordinary, common",
    "grade": 2
  },
  {
    "id": 1150,
    "traditional": "一生",
    "simplified": "一生",
    "reading": "yīshēng",
    "meaning": "lifetime, all, existence, life, throughout one's life, all one's life, career, all/throughout one's life",
    "grade": 2
  },
  {
    "id": 1151,
    "traditional": "一直",
    "simplified": "一直",
    "reading": "yīzhí",
    "meaning": "e'er, away, continuously, all the way, right, forever, all_along, always, the whole way, all along, straight, slap, through, ever, evermore",
    "grade": 2
  },
  {
    "id": 1152,
    "traditional": "億",
    "simplified": "亿",
    "reading": "yì",
    "meaning": "det.: hundred million",
    "grade": 2
  },
  {
    "id": 1153,
    "traditional": "以為",
    "simplified": "以为",
    "reading": "yǐwéi",
    "meaning": "imagine, think/believe/consider erroneously, treat, deem, believe, suspect, suppose, consider erroneously, repute, animadvert, esteem, trow, speak up, feel, count, believe/consider erroneously, calculate, view as, guess, ween, look, expect, think erroneously, think, hold, opine, interpret, sound off, take_for, consider, take for",
    "grade": 2
  },
  {
    "id": 1154,
    "traditional": "因為",
    "simplified": "因为",
    "reading": "yīnwei",
    "meaning": "conj.: because, for, on account of",
    "grade": 2
  },
  {
    "id": 1155,
    "traditional": "意見",
    "simplified": "意见",
    "reading": "yìjian",
    "meaning": "latitude, point_of_view, estimation, sight, conceit, verdict, hypothesis, attitude, estimate, possibility, judgement, appraisal, ground, slant, differing opinion, ruling, complaint, thinking, suggestion, comment, advisement, mind, apprehension, say, vote, impression, deliverance, view, understanding, feeling, idea, notion, sentiment, judgment, advice, current, opinion, advert, theory, v",
    "grade": 2
  },
  {
    "id": 1156,
    "traditional": "意思",
    "simplified": "意思",
    "reading": "yìsi",
    "meaning": "significance, token of infection, intention, interest, idea, trace, meaning, thought, opinion, substance, etc., import, a token of appreciation/etc., indirect request, wish, a token of affection, desire, appreciation, fun, signification, gratitude, sense, hint, a token of affection/appreciation/etc., suggestion, mind, intent",
    "grade": 2
  },
  {
    "id": 1157,
    "traditional": "陰天",
    "simplified": "阴天",
    "reading": "yīntiān",
    "meaning": "cloudy day, overcast sky",
    "grade": 2
  },
  {
    "id": 1158,
    "traditional": "音節",
    "simplified": "音节",
    "reading": "yīnjié",
    "meaning": "syllable, key, pitch",
    "grade": 2
  },
  {
    "id": 1159,
    "traditional": "音樂",
    "simplified": "音乐",
    "reading": "yīnyuè",
    "meaning": "music",
    "grade": 2
  },
  {
    "id": 1160,
    "traditional": "音樂會",
    "simplified": "音乐会",
    "reading": "yīnyuèhuì",
    "meaning": "musicale, philharmonic, concert",
    "grade": 2
  },
  {
    "id": 1161,
    "traditional": "銀行卡",
    "simplified": "银行卡",
    "reading": "yínhángkǎ",
    "meaning": "bank card, ATM card",
    "grade": 2
  },
  {
    "id": 1162,
    "traditional": "應該",
    "simplified": "应该",
    "reading": "yīnggāi",
    "meaning": "should, ought to",
    "grade": 2
  },
  {
    "id": 1163,
    "traditional": "銀行",
    "simplified": "银行",
    "reading": "yínháng",
    "meaning": "jug, depository financial institution, bank building, banking company, banking concern, bank",
    "grade": 2
  },
  {
    "id": 1164,
    "traditional": "英文",
    "simplified": "英文",
    "reading": "Yīngwén",
    "meaning": "English, English (language), English language, English_language",
    "grade": 2
  },
  {
    "id": 1165,
    "traditional": "英語",
    "simplified": "英语",
    "reading": "Yīngyǔ",
    "meaning": "English, English (language), profit, surplus",
    "grade": 2
  },
  {
    "id": 1166,
    "traditional": "影片",
    "simplified": "影片",
    "reading": "yǐngpiàn",
    "meaning": "celluloid, movie_film, flick, production, film, pic, movie",
    "grade": 2
  },
  {
    "id": 1167,
    "traditional": "陰",
    "simplified": "阴",
    "reading": "yīn",
    "meaning": "overcast (weather), cloudy, shady, Yin (the negative principle of Yin and Yang), negative (electric.), feminine, moon, implicit, hidden, genitalia, surname Yin, variant of 陰|阴[yin1]",
    "grade": 2
  },
  {
    "id": 1168,
    "traditional": "影響",
    "simplified": "影响",
    "reading": "yǐngxiǎng",
    "meaning": "influence",
    "grade": 2
  },
  {
    "id": 1169,
    "traditional": "油",
    "simplified": "油",
    "reading": "yóu",
    "meaning": "oil, fat, grease",
    "grade": 2
  },
  {
    "id": 1170,
    "traditional": "永遠",
    "simplified": "永远",
    "reading": "yǒngyuǎn",
    "meaning": "e'er, always, for_good, evermore, ever, forever",
    "grade": 2
  },
  {
    "id": 1171,
    "traditional": "有空兒",
    "simplified": "有空儿",
    "reading": "yǒukòngr5",
    "meaning": "available",
    "grade": 2
  },
  {
    "id": 1172,
    "traditional": "友好",
    "simplified": "友好",
    "reading": "yǒuhǎo",
    "meaning": "Youhao district of Yichun city 伊春市[Yi1 chun1 shi4], Heilongjiang, friendly, amicable, close friend",
    "grade": 2
  },
  {
    "id": 1173,
    "traditional": "有點兒",
    "simplified": "有点儿",
    "reading": "yǒudiǎnr5",
    "meaning": "slightly, a little, somewhat",
    "grade": 2
  },
  {
    "id": 1174,
    "traditional": "遊客",
    "simplified": "游客",
    "reading": "yóukè",
    "meaning": "traveler, tourist, (online gaming) guest player",
    "grade": 2
  },
  {
    "id": 1175,
    "traditional": "有人",
    "simplified": "有人",
    "reading": "yǒurén",
    "meaning": "someone, people, anyone, there is someone there, occupied (as in restroom)",
    "grade": 2
  },
  {
    "id": 1176,
    "traditional": "有意思",
    "simplified": "有意思",
    "reading": "yǒuyìsi",
    "meaning": "interesting, meaningful, enjoyable",
    "grade": 2
  },
  {
    "id": 1177,
    "traditional": "語言",
    "simplified": "语言",
    "reading": "yǔyán",
    "meaning": "linguistic communication, lingual, language, idiom, tongue, oral communication, speech, perspicuity, lingo, contumely, Fortran, parole, natural language, spoken language, linguistic process",
    "grade": 2
  },
  {
    "id": 1178,
    "traditional": "又",
    "simplified": "又",
    "reading": "yòu",
    "meaning": "again, moreover",
    "grade": 2
  },
  {
    "id": 1179,
    "traditional": "原來",
    "simplified": "原来",
    "reading": "yuánlái",
    "meaning": "originally, formerly",
    "grade": 2
  },
  {
    "id": 1180,
    "traditional": "原因",
    "simplified": "原因",
    "reading": "yuányīn",
    "meaning": "origin, causation, reason, wherefore, causal, breeder, spore, cause, causal agent, whence, causal agency, occasion, root, subject, causality, why, starter",
    "grade": 2
  },
  {
    "id": 1181,
    "traditional": "魚",
    "simplified": "鱼",
    "reading": "yú",
    "meaning": "paella, variegated carp, gudgeon, bighead, Yu, Surname, Argonaut, salmon, fish, catfish",
    "grade": 2
  },
  {
    "id": 1182,
    "traditional": "院長",
    "simplified": "院长",
    "reading": "yuànzhǎng",
    "meaning": "director/president (of museum/institute/etc.), head of branch of government",
    "grade": 2
  },
  {
    "id": 1183,
    "traditional": "院",
    "simplified": "院",
    "reading": "yuàn",
    "meaning": "public facility, college, courtyard, designation for certain government offices and pub, hospital, clinic, branch of government, university, institutes, compound, abbey, academy, institute, yard, educational institution, faculty",
    "grade": 2
  },
  {
    "id": 1184,
    "traditional": "願意",
    "simplified": "愿意",
    "reading": "yuànyi",
    "meaning": "will, be willing, please, wish, like, care",
    "grade": 2
  },
  {
    "id": 1185,
    "traditional": "月份",
    "simplified": "月份",
    "reading": "yuèfèn",
    "meaning": "month",
    "grade": 2
  },
  {
    "id": 1186,
    "traditional": "月亮",
    "simplified": "月亮",
    "reading": "yuèliang",
    "meaning": "moonlight, Cynthia, moon, lune, Phoebe, Diana, lunar",
    "grade": 2
  },
  {
    "id": 1187,
    "traditional": "越來越",
    "simplified": "越来越",
    "reading": "yuèláiyuè",
    "meaning": "more_and_more, more and more",
    "grade": 2
  },
  {
    "id": 1188,
    "traditional": "越",
    "simplified": "越",
    "reading": "yuè",
    "meaning": "~A~B) the more A the more B",
    "grade": 2
  },
  {
    "id": 1189,
    "traditional": "運動",
    "simplified": "运动",
    "reading": "yùndong",
    "meaning": "sports, athletics, exercise, movement, campaign",
    "grade": 2
  },
  {
    "id": 1190,
    "traditional": "雲",
    "simplified": "云",
    "reading": "yún",
    "meaning": "cloud",
    "grade": 2
  },
  {
    "id": 1191,
    "traditional": "咱們",
    "simplified": "咱们",
    "reading": "zánmen",
    "meaning": "we, you and I",
    "grade": 2
  },
  {
    "id": 1192,
    "traditional": "咱",
    "simplified": "咱",
    "reading": "zán",
    "meaning": "I or me we (including both the speaker and the person spoken to) variant of 咱[zan2], see 咱[zan2]",
    "grade": 2
  },
  {
    "id": 1193,
    "traditional": "臟",
    "simplified": "脏",
    "reading": "zàng",
    "meaning": "dirty, squalid, smudge, filthy, smutch, blur, smear",
    "grade": 2
  },
  {
    "id": 1194,
    "traditional": "院子",
    "simplified": "院子",
    "reading": "yuànzi",
    "meaning": "patio, court, courtyard, yard, compound",
    "grade": 2
  },
  {
    "id": 1195,
    "traditional": "早餐",
    "simplified": "早餐",
    "reading": "zǎocān",
    "meaning": "breaker, petit dejeuner, brekker, dejeuner, breakfast, morning tea",
    "grade": 2
  },
  {
    "id": 1196,
    "traditional": "早晨",
    "simplified": "早晨",
    "reading": "zǎochen",
    "meaning": "early morning, CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 1197,
    "traditional": "怎麼辦",
    "simplified": "怎么办",
    "reading": "zěnmebàn",
    "meaning": "what should someone do?",
    "grade": 2
  },
  {
    "id": 1198,
    "traditional": "怎麼樣",
    "simplified": "怎么样",
    "reading": "zěnmeyàng",
    "meaning": "How are things?",
    "grade": 2
  },
  {
    "id": 1199,
    "traditional": "早就",
    "simplified": "早就",
    "reading": "zǎojiù",
    "meaning": "already at an earlier time",
    "grade": 2
  },
  {
    "id": 1200,
    "traditional": "怎樣",
    "simplified": "怎样",
    "reading": "zěnyàng",
    "meaning": "no matter how, in any way, in a certain way, how?, what, how",
    "grade": 2
  },
  {
    "id": 1201,
    "traditional": "占",
    "simplified": "占",
    "reading": "zhàn",
    "meaning": "constitute, make up, account for",
    "grade": 2
  },
  {
    "id": 1202,
    "traditional": "站住",
    "simplified": "站住",
    "reading": "zhànzhù",
    "meaning": "to stand",
    "grade": 2
  },
  {
    "id": 1203,
    "traditional": "長大",
    "simplified": "长大",
    "reading": "zhǎng dà",
    "meaning": "vegetate, be brought up, grow_up, maturate, grow, grow up, bulk, age, mature",
    "grade": 2
  },
  {
    "id": 1204,
    "traditional": "站",
    "simplified": "站",
    "reading": "zhàn",
    "meaning": "to stand",
    "grade": 2
  },
  {
    "id": 1205,
    "traditional": "找出",
    "simplified": "找出",
    "reading": "zhǎochū",
    "meaning": "rout out, find, unearth, rout up, rout, quest, observe, notice, grub, seek_out, dig_out, seek out, discover, find_out, root_out, spy, spot, detect, smoke out",
    "grade": 2
  },
  {
    "id": 1206,
    "traditional": "照片",
    "simplified": "照片",
    "reading": "zhàopiàn",
    "meaning": "snapshot, shot, likeness, picture, photo, icon, image, caption, exposure, photograph, pic, ikon",
    "grade": 2
  },
  {
    "id": 1207,
    "traditional": "照相",
    "simplified": "照相",
    "reading": "zhào xiàng",
    "meaning": "to take a photograph",
    "grade": 2
  },
  {
    "id": 1208,
    "traditional": "照顧",
    "simplified": "照顾",
    "reading": "zhàogu",
    "meaning": "look after, care for, attend to, patronize (as customer)",
    "grade": 2
  },
  {
    "id": 1209,
    "traditional": "這麼",
    "simplified": "这么",
    "reading": "zhème",
    "meaning": "so, such, this way, like this",
    "grade": 2
  },
  {
    "id": 1210,
    "traditional": "這時候",
    "simplified": "这时候",
    "reading": "zhè shíhòu",
    "meaning": "At this moment",
    "grade": 2
  },
  {
    "id": 1211,
    "traditional": "真正",
    "simplified": "真正",
    "reading": "zhēnzhèng",
    "meaning": "true, indeed, certainty",
    "grade": 2
  },
  {
    "id": 1212,
    "traditional": "正常",
    "simplified": "正常",
    "reading": "zhèngcháng",
    "meaning": "normal, regular",
    "grade": 2
  },
  {
    "id": 1213,
    "traditional": "正是",
    "simplified": "正是",
    "reading": "zhèngshì",
    "meaning": "(emphasizes that sth is precisely or exactly as stated), precisely, exactly, even, if, just like, in the same way as",
    "grade": 2
  },
  {
    "id": 1214,
    "traditional": "這樣",
    "simplified": "这样",
    "reading": "zhèyàng",
    "meaning": "so, such, like this, this way",
    "grade": 2
  },
  {
    "id": 1215,
    "traditional": "正確",
    "simplified": "正确",
    "reading": "zhèngquè",
    "meaning": "correct, true, exact, proper, right",
    "grade": 2
  },
  {
    "id": 1216,
    "traditional": "只",
    "simplified": "只",
    "reading": "zhǐ",
    "meaning": "only, merely",
    "grade": 2
  },
  {
    "id": 1217,
    "traditional": "只能",
    "simplified": "只能",
    "reading": "zhǐnéng",
    "meaning": "can only, obliged to do sth, to have no other choice",
    "grade": 2
  },
  {
    "id": 1218,
    "traditional": "直接",
    "simplified": "直接",
    "reading": "zhíjiē",
    "meaning": "direct, immediate",
    "grade": 2
  },
  {
    "id": 1219,
    "traditional": "正好",
    "simplified": "正好",
    "reading": "zhènghǎo",
    "meaning": "just, exactly",
    "grade": 2
  },
  {
    "id": 1220,
    "traditional": "紙",
    "simplified": "纸",
    "reading": "zhǐ",
    "meaning": "paper",
    "grade": 2
  },
  {
    "id": 1221,
    "traditional": "只要",
    "simplified": "只要",
    "reading": "zhǐyào",
    "meaning": "conj.: so long as, provided",
    "grade": 2
  },
  {
    "id": 1222,
    "traditional": "中級",
    "simplified": "中级",
    "reading": "zhōngjí",
    "meaning": "middle level (in a hierarchy)",
    "grade": 2
  },
  {
    "id": 1223,
    "traditional": "中年",
    "simplified": "中年",
    "reading": "zhōngnián",
    "meaning": "middle age",
    "grade": 2
  },
  {
    "id": 1224,
    "traditional": "中小學",
    "simplified": "中小学",
    "reading": "zhōngxiǎoxué",
    "meaning": "primary and middle school",
    "grade": 2
  },
  {
    "id": 1225,
    "traditional": "中心",
    "simplified": "中心",
    "reading": "zhōngxīn",
    "meaning": "center, heart, hub",
    "grade": 2
  },
  {
    "id": 1226,
    "traditional": "中餐",
    "simplified": "中餐",
    "reading": "zhōngcān",
    "meaning": "Chinese meal, Chinese food, CL:份[fen4],頓|顿[dun4]",
    "grade": 2
  },
  {
    "id": 1227,
    "traditional": "中醫",
    "simplified": "中医",
    "reading": "zhōngyī",
    "meaning": "trad. Ch. medicine, doctor of trad. Ch. medicine",
    "grade": 2
  },
  {
    "id": 1228,
    "traditional": "重視",
    "simplified": "重视",
    "reading": "zhòngshì",
    "meaning": "attach importance to, take sth. seriously, value",
    "grade": 2
  },
  {
    "id": 1229,
    "traditional": "周",
    "simplified": "周",
    "reading": "zhōu",
    "meaning": "Surname",
    "grade": 2
  },
  {
    "id": 1230,
    "traditional": "週末",
    "simplified": "周末",
    "reading": "zhōumò",
    "meaning": "weekend",
    "grade": 2
  },
  {
    "id": 1231,
    "traditional": "週年",
    "simplified": "周年",
    "reading": "zhōunián",
    "meaning": "anniversary, annual",
    "grade": 2
  },
  {
    "id": 1232,
    "traditional": "重點",
    "simplified": "重点",
    "reading": "zhòngdiǎn",
    "meaning": "taproot, accent, emphasis, focal point, point, keystone, hinge, salient point, stress, gravity",
    "grade": 2
  },
  {
    "id": 1233,
    "traditional": "住房",
    "simplified": "住房",
    "reading": "zhùfáng",
    "meaning": "housing",
    "grade": 2
  },
  {
    "id": 1234,
    "traditional": "主人",
    "simplified": "主人",
    "reading": "zhǔrén",
    "meaning": "lord, housemaster, host, symposiarch, marse, bwana, padrone, boss, master, baas, provider, mast, owner, landlord",
    "grade": 2
  },
  {
    "id": 1235,
    "traditional": "住院",
    "simplified": "住院",
    "reading": "zhù yuàn",
    "meaning": "be hospitalized",
    "grade": 2
  },
  {
    "id": 1236,
    "traditional": "自己",
    "simplified": "自己",
    "reading": "zìjǐ",
    "meaning": "lonesome, number_one, own, ego, self, oneself, name",
    "grade": 2
  },
  {
    "id": 1237,
    "traditional": "裝",
    "simplified": "装",
    "reading": "zhuāng",
    "meaning": "install, fit, assemble, load, pack, hold",
    "grade": 2
  },
  {
    "id": 1238,
    "traditional": "準確",
    "simplified": "准确",
    "reading": "zhǔnquè",
    "meaning": "accurate, exact, precise",
    "grade": 2
  },
  {
    "id": 1239,
    "traditional": "自行車",
    "simplified": "自行车",
    "reading": "zìxíngchē",
    "meaning": "two-wheeler, roadster, machine, bicycle, wheel, push-bike, cycle, motorcycle, bike, velocipede",
    "grade": 2
  },
  {
    "id": 1240,
    "traditional": "自由",
    "simplified": "自由",
    "reading": "zìyóu",
    "meaning": "free, unrestrained",
    "grade": 2
  },
  {
    "id": 1241,
    "traditional": "字典",
    "simplified": "字典",
    "reading": "zìdiǎn",
    "meaning": "lexicon, wordbook, lexical, thesaurus, chararter dictionary, dictionary",
    "grade": 2
  },
  {
    "id": 1242,
    "traditional": "走過",
    "simplified": "走过",
    "reading": "zǒuguò",
    "meaning": "to walk past, to pass by",
    "grade": 2
  },
  {
    "id": 1243,
    "traditional": "走進",
    "simplified": "走进",
    "reading": "zǒujìn",
    "meaning": "enter, step_in, walk in, go in",
    "grade": 2
  },
  {
    "id": 1244,
    "traditional": "租",
    "simplified": "租",
    "reading": "zū",
    "meaning": "rent out",
    "grade": 2
  },
  {
    "id": 1245,
    "traditional": "組",
    "simplified": "组",
    "reading": "zǔ",
    "meaning": "m.[general]",
    "grade": 2
  },
  {
    "id": 1246,
    "traditional": "走開",
    "simplified": "走开",
    "reading": "zǒukāi",
    "meaning": "to leave, to walk away, to beat it, to move aside",
    "grade": 2
  },
  {
    "id": 1247,
    "traditional": "組成",
    "simplified": "组成",
    "reading": "zǔ chéng",
    "meaning": "form, make up (into), compose",
    "grade": 2
  },
  {
    "id": 1248,
    "traditional": "主要",
    "simplified": "主要",
    "reading": "zhǔyào",
    "meaning": "main, chief, principal, major",
    "grade": 2
  },
  {
    "id": 1249,
    "traditional": "嘴",
    "simplified": "嘴",
    "reading": "zuǐ",
    "meaning": "spigot, mug, rattletrap, kisser, trap, puss, visage, spile, nib, smiler, beak, countenance, snout, physiognomy, bill, cake-hole, bazoo, spout, anything shaped or functioning like a mouth, mouth, gob, neb",
    "grade": 2
  },
  {
    "id": 1250,
    "traditional": "最近",
    "simplified": "最近",
    "reading": "zuìjìn",
    "meaning": "recent, recently, these days, latest, soon, nearest (of locations), shortest (of routes)",
    "grade": 2
  },
  {
    "id": 1251,
    "traditional": "組長",
    "simplified": "组长",
    "reading": "zǔzhǎng",
    "meaning": "chief/head of group/section",
    "grade": 2
  },
  {
    "id": 1252,
    "traditional": "作家",
    "simplified": "作家",
    "reading": "zuòjiā",
    "meaning": "penster, scribe, ink-slinger, pen, inkslinger, writer, wright, novelist, litterateur, literator, tragedian, composer, author, penman, biobibliography, bookman",
    "grade": 2
  },
  {
    "id": 1253,
    "traditional": "作文",
    "simplified": "作文",
    "reading": "zuòwén",
    "meaning": "composition",
    "grade": 2
  },
  {
    "id": 1254,
    "traditional": "作用",
    "simplified": "作用",
    "reading": "zuòyòng",
    "meaning": "action, function, effect, intention, motive",
    "grade": 2
  },
  {
    "id": 1255,
    "traditional": "座",
    "simplified": "座",
    "reading": "zuò",
    "meaning": "fare, stand, base, constellation, footstall, pedestal, m.[general], , customer, Aquarius, seating, plinth, place, seat",
    "grade": 2
  },
  {
    "id": 1256,
    "traditional": "座位",
    "simplified": "座位",
    "reading": "zuòwei",
    "meaning": "seat, CL:個|个[ge4]",
    "grade": 2
  },
  {
    "id": 1257,
    "traditional": "做到",
    "simplified": "做到",
    "reading": "zuò dào",
    "meaning": "achievement, accomplishment, achieve, accomplish",
    "grade": 2
  },
  {
    "id": 1258,
    "traditional": "做法",
    "simplified": "做法",
    "reading": "zuòfa",
    "meaning": "way of doing sth., modus_operandi, practice, manner, method, making sth., way of making sth., shtick, way, way of doing, way of doing/making sth., personal manner",
    "grade": 2
  },
  {
    "id": 1259,
    "traditional": "作業",
    "simplified": "作业",
    "reading": "zuòyè",
    "meaning": "school assignment, work, task, operation, production",
    "grade": 2
  },
  {
    "id": 1260,
    "traditional": "做飯",
    "simplified": "做饭",
    "reading": "zuò fàn",
    "meaning": "do the cooking, prepare a meal, prepare meal",
    "grade": 2
  },
  {
    "id": 1261,
    "traditional": "愛心",
    "simplified": "爱心",
    "reading": "àixīn",
    "meaning": "liking, pity, love, benevolence, mercy, fondness, commitment",
    "grade": 3
  },
  {
    "id": 1262,
    "traditional": "安排",
    "simplified": "安排",
    "reading": "ānpái",
    "meaning": "arrange, plan, fix up, provide (meals/etc.)",
    "grade": 3
  },
  {
    "id": 1263,
    "traditional": "安裝",
    "simplified": "安装",
    "reading": "ānzhuāng",
    "meaning": "install, erect, fix, mount",
    "grade": 3
  },
  {
    "id": 1264,
    "traditional": "按照",
    "simplified": "按照",
    "reading": "ànzhào",
    "meaning": "according to, in the light of, on the basis of",
    "grade": 3
  },
  {
    "id": 1265,
    "traditional": "按",
    "simplified": "按",
    "reading": "àn",
    "meaning": "according to, in accordance with",
    "grade": 3
  },
  {
    "id": 1266,
    "traditional": "把握",
    "simplified": "把握",
    "reading": "bǎwò",
    "meaning": "grasp firmly",
    "grade": 3
  },
  {
    "id": 1267,
    "traditional": "把",
    "simplified": "把",
    "reading": "bǎ",
    "meaning": "indicating pre-verbal object as thing dealt with by the action",
    "grade": 3
  },
  {
    "id": 1268,
    "traditional": "白菜",
    "simplified": "白菜",
    "reading": "báicài",
    "meaning": "Chinese cabbage, pak choi, CL:棵[ke1], 個|个[ge4]",
    "grade": 3
  },
  {
    "id": 1269,
    "traditional": "搬",
    "simplified": "搬",
    "reading": "bān",
    "meaning": "copy mechanically, remove, apply indiscriminately, take away, transfer, move, move (house)",
    "grade": 3
  },
  {
    "id": 1270,
    "traditional": "白",
    "simplified": "白",
    "reading": "bái",
    "meaning": "white, clear, pure, plain, wrongly written/mispronounced,",
    "grade": 3
  },
  {
    "id": 1271,
    "traditional": "班級",
    "simplified": "班级",
    "reading": "bānjí",
    "meaning": "classes and grades in school",
    "grade": 3
  },
  {
    "id": 1272,
    "traditional": "搬家",
    "simplified": "搬家",
    "reading": "bān jiā",
    "meaning": "to move house, removal",
    "grade": 3
  },
  {
    "id": 1273,
    "traditional": "辦理",
    "simplified": "办理",
    "reading": "bànlǐ",
    "meaning": "handle, manage, attend, conduct, enact, transact, transaction",
    "grade": 3
  },
  {
    "id": 1274,
    "traditional": "板",
    "simplified": "板",
    "reading": "bǎn",
    "meaning": "see 老闆|老板, boss, to catch sight of in a doorway (old)",
    "grade": 3
  },
  {
    "id": 1275,
    "traditional": "保安",
    "simplified": "保安",
    "reading": "bǎoān",
    "meaning": "to ensure public security, to ensure safety (for workers engaged in production), public security, security guard",
    "grade": 3
  },
  {
    "id": 1276,
    "traditional": "保持",
    "simplified": "保持",
    "reading": "bǎochí",
    "meaning": "keep, maintain, preserve",
    "grade": 3
  },
  {
    "id": 1277,
    "traditional": "保存",
    "simplified": "保存",
    "reading": "bǎocún",
    "meaning": "preserve, conserve, keep",
    "grade": 3
  },
  {
    "id": 1278,
    "traditional": "保",
    "simplified": "保",
    "reading": "bǎo",
    "meaning": "keep, maintenance, preserve soil moisture, stand guarantor, defend, protection, ensure, stand guarantor for sb., preserve, preservation, warrant, bail, defence, protect, maintain, seek, guarantee",
    "grade": 3
  },
  {
    "id": 1279,
    "traditional": "保留",
    "simplified": "保留",
    "reading": "bǎoliú",
    "meaning": "continue to have, retain, hold back, reserve",
    "grade": 3
  },
  {
    "id": 1280,
    "traditional": "保險",
    "simplified": "保险",
    "reading": "bǎoxiǎn",
    "meaning": "insurance",
    "grade": 3
  },
  {
    "id": 1281,
    "traditional": "保證",
    "simplified": "保证",
    "reading": "bǎozhèng",
    "meaning": "pledge, guarantee",
    "grade": 3
  },
  {
    "id": 1282,
    "traditional": "保護",
    "simplified": "保护",
    "reading": "bǎohù",
    "meaning": "protect, safeguard",
    "grade": 3
  },
  {
    "id": 1283,
    "traditional": "報到",
    "simplified": "报到",
    "reading": "bào dào",
    "meaning": "check in, register, registration, report for duty, appear, report, check_in",
    "grade": 3
  },
  {
    "id": 1284,
    "traditional": "背",
    "simplified": "背",
    "reading": "bèi",
    "meaning": "learn by heart, recite from memory",
    "grade": 3
  },
  {
    "id": 1285,
    "traditional": "報道",
    "simplified": "报道",
    "reading": "bàodào",
    "meaning": "to report (news), report, CL:篇[pian1],份[fen4]",
    "grade": 3
  },
  {
    "id": 1286,
    "traditional": "北部",
    "simplified": "北部",
    "reading": "běibù",
    "meaning": "the north, the northern part of the country, northern part",
    "grade": 3
  },
  {
    "id": 1287,
    "traditional": "報",
    "simplified": "报",
    "reading": "bào",
    "meaning": "newspaper, periodical, bulletin, telegram",
    "grade": 3
  },
  {
    "id": 1288,
    "traditional": "報告",
    "simplified": "报告",
    "reading": "bàogào",
    "meaning": "report, speech, (student) term paper",
    "grade": 3
  },
  {
    "id": 1289,
    "traditional": "背後",
    "simplified": "背后",
    "reading": "bèihòu",
    "meaning": "rear, in the rear, behind, rearward, at the back, backside, back end",
    "grade": 3
  },
  {
    "id": 1290,
    "traditional": "本來",
    "simplified": "本来",
    "reading": "běnlái",
    "meaning": "originally, at first, of course",
    "grade": 3
  },
  {
    "id": 1291,
    "traditional": "本領",
    "simplified": "本领",
    "reading": "běnlǐng",
    "meaning": "skill, ability, capability, CL:項|项[xiang4],個|个[ge4]",
    "grade": 3
  },
  {
    "id": 1292,
    "traditional": "被",
    "simplified": "被",
    "reading": "bèi",
    "meaning": "by",
    "grade": 3
  },
  {
    "id": 1293,
    "traditional": "比較",
    "simplified": "比较",
    "reading": "bǐjiào",
    "meaning": "relatively, fairly",
    "grade": 3
  },
  {
    "id": 1294,
    "traditional": "比例",
    "simplified": "比例",
    "reading": "bǐlì",
    "meaning": "proportional, rate, proportionality, quotient, proportion, proportionment, scale, ratio, balance",
    "grade": 3
  },
  {
    "id": 1295,
    "traditional": "比賽",
    "simplified": "比赛",
    "reading": "bǐsài",
    "meaning": "match, competition",
    "grade": 3
  },
  {
    "id": 1296,
    "traditional": "必然",
    "simplified": "必然",
    "reading": "bìrán",
    "meaning": "surely, inevitably, pardi, certainly",
    "grade": 3
  },
  {
    "id": 1297,
    "traditional": "被子",
    "simplified": "被子",
    "reading": "bèizi",
    "meaning": "quilt, CL:床[chuang2]",
    "grade": 3
  },
  {
    "id": 1298,
    "traditional": "變化",
    "simplified": "变化",
    "reading": "biànhuà",
    "meaning": "change, transformation",
    "grade": 3
  },
  {
    "id": 1299,
    "traditional": "本事",
    "simplified": "本事",
    "reading": "běnshi",
    "meaning": "literary source material, know-how, proficiency, skill, prowess, this matter, ability, capability",
    "grade": 3
  },
  {
    "id": 1300,
    "traditional": "變為",
    "simplified": "变为",
    "reading": "biànwéi",
    "meaning": "change to, become",
    "grade": 3
  },
  {
    "id": 1301,
    "traditional": "必要",
    "simplified": "必要",
    "reading": "bìyào",
    "meaning": "necessary, indispensable",
    "grade": 3
  },
  {
    "id": 1302,
    "traditional": "表達",
    "simplified": "表达",
    "reading": "biǎodá",
    "meaning": "express, convey, voice",
    "grade": 3
  },
  {
    "id": 1303,
    "traditional": "標準",
    "simplified": "标准",
    "reading": "biāozhǔn",
    "meaning": "standard, criterion",
    "grade": 3
  },
  {
    "id": 1304,
    "traditional": "標題",
    "simplified": "标题",
    "reading": "biāotí",
    "meaning": "head, headline, title, superscription, statute title, header, headword, caption, cutline, rubric, heading",
    "grade": 3
  },
  {
    "id": 1305,
    "traditional": "表格",
    "simplified": "表格",
    "reading": "biǎogé",
    "meaning": "tabular array, tabulation, table, form, blank, tabular matter",
    "grade": 3
  },
  {
    "id": 1306,
    "traditional": "表面",
    "simplified": "表面",
    "reading": "biǎomiàn",
    "meaning": "crust, superficial, facing, show, exterior, obverse, rind, face, superficies, top, outside, appearance, facade, surface, bosom, window dressing",
    "grade": 3
  },
  {
    "id": 1307,
    "traditional": "表明",
    "simplified": "表明",
    "reading": "biǎomíng",
    "meaning": "argue, utter, disclose, enunciate, indicate, speak_for, make clear, indication, prove, point, express, manifest, tell, state clearly, give, denote, evince, unfold, portend, declare, kithe, make known, signalize",
    "grade": 3
  },
  {
    "id": 1308,
    "traditional": "表演",
    "simplified": "表演",
    "reading": "biǎoyǎn",
    "meaning": "perform, act, play, demonstrate",
    "grade": 3
  },
  {
    "id": 1309,
    "traditional": "表現",
    "simplified": "表现",
    "reading": "biǎoxiàn",
    "meaning": "manifestation, expression",
    "grade": 3
  },
  {
    "id": 1310,
    "traditional": "播出",
    "simplified": "播出",
    "reading": "bō chū",
    "meaning": "broadcast, disseminate",
    "grade": 3
  },
  {
    "id": 1311,
    "traditional": "並且",
    "simplified": "并且",
    "reading": "bìngqiě",
    "meaning": "conj.: and, besides, moreover",
    "grade": 3
  },
  {
    "id": 1312,
    "traditional": "播放",
    "simplified": "播放",
    "reading": "bōfàng",
    "meaning": "broadcast, transmit",
    "grade": 3
  },
  {
    "id": 1313,
    "traditional": "不必",
    "simplified": "不必",
    "reading": "bùbì",
    "meaning": "need not, not have to",
    "grade": 3
  },
  {
    "id": 1314,
    "traditional": "并",
    "simplified": "并",
    "reading": "bìng",
    "meaning": "to be side by side, simultaneously, and",
    "grade": 3
  },
  {
    "id": 1315,
    "traditional": "不論",
    "simplified": "不论",
    "reading": "bùlùn",
    "meaning": "conj.: no matter how/who/what/etc.",
    "grade": 3
  },
  {
    "id": 1316,
    "traditional": "補",
    "simplified": "补",
    "reading": "bǔ",
    "meaning": "mend, patch, nourish, supplementary",
    "grade": 3
  },
  {
    "id": 1317,
    "traditional": "不斷",
    "simplified": "不断",
    "reading": "bùduàn",
    "meaning": "unceasingly, continuously",
    "grade": 3
  },
  {
    "id": 1318,
    "traditional": "補充",
    "simplified": "补充",
    "reading": "bǔchōng",
    "meaning": "supply, supplement, augment, replenishment, complement, eke, fill again, accompany, add, renew, replenish, implement, stock, recruit, refill",
    "grade": 3
  },
  {
    "id": 1319,
    "traditional": "不得不",
    "simplified": "不得不",
    "reading": "bùdébù",
    "meaning": "cannot but, have to",
    "grade": 3
  },
  {
    "id": 1320,
    "traditional": "不安",
    "simplified": "不安",
    "reading": "bùān",
    "meaning": "be anxious; be uneasy; be restless",
    "grade": 3
  },
  {
    "id": 1321,
    "traditional": "不光",
    "simplified": "不光",
    "reading": "bùguāng",
    "meaning": "not the only one, not only",
    "grade": 3
  },
  {
    "id": 1322,
    "traditional": "不僅",
    "simplified": "不仅",
    "reading": "bùjǐn",
    "meaning": "conj.: not only",
    "grade": 3
  },
  {
    "id": 1323,
    "traditional": "布",
    "simplified": "布",
    "reading": "bù",
    "meaning": "cloth",
    "grade": 3
  },
  {
    "id": 1324,
    "traditional": "步",
    "simplified": "步",
    "reading": "bù",
    "meaning": "m.[activity]",
    "grade": 3
  },
  {
    "id": 1325,
    "traditional": "部門",
    "simplified": "部门",
    "reading": "bùmén",
    "meaning": "category, section, department, service, instrumentality, branch, realm, sector, ministry, province, divisional, classification, departmental, division",
    "grade": 3
  },
  {
    "id": 1326,
    "traditional": "部",
    "simplified": "部",
    "reading": "bù",
    "meaning": "m.[general]",
    "grade": 3
  },
  {
    "id": 1327,
    "traditional": "部長",
    "simplified": "部长",
    "reading": "bùzhǎng",
    "meaning": "commissar, manager, superintendent, cabinet minister, head of a section, minister, political commissar, ministerial, secretary, government minister, president, head of a department",
    "grade": 3
  },
  {
    "id": 1328,
    "traditional": "才能",
    "simplified": "才能",
    "reading": "cáinéng",
    "meaning": "ability, talent",
    "grade": 3
  },
  {
    "id": 1329,
    "traditional": "採取",
    "simplified": "采取",
    "reading": "cǎiqǔ",
    "meaning": "operate, assume, adopt, accept, strike, take, take up, take over, borrow",
    "grade": 3
  },
  {
    "id": 1330,
    "traditional": "彩色",
    "simplified": "彩色",
    "reading": "cǎisè",
    "meaning": "colour, chromatic, multicolour, colorful",
    "grade": 3
  },
  {
    "id": 1331,
    "traditional": "採用",
    "simplified": "采用",
    "reading": "cǎiyòng",
    "meaning": "invoke, admit, assume, adoptive, use, adopt, select for use, embrace, accept, employment, take, introduce, take up, take_on, take over, take on, borrow, employ, adoption",
    "grade": 3
  },
  {
    "id": 1332,
    "traditional": "曾經",
    "simplified": "曾经",
    "reading": "céngjīng",
    "meaning": "in one case, , one time, formerly, syne, at one time, of all time, once, ever",
    "grade": 3
  },
  {
    "id": 1333,
    "traditional": "產生",
    "simplified": "产生",
    "reading": "chǎnshēng",
    "meaning": "come into being, produce, engender, emerge",
    "grade": 3
  },
  {
    "id": 1334,
    "traditional": "長處",
    "simplified": "长处",
    "reading": "chángchu",
    "meaning": "good aspects, strong points",
    "grade": 3
  },
  {
    "id": 1335,
    "traditional": "長城",
    "simplified": "长城",
    "reading": "Chángchéng",
    "meaning": "impregnable bulwark, the Great Wall, Great Wall",
    "grade": 3
  },
  {
    "id": 1336,
    "traditional": "厰",
    "simplified": "厂",
    "reading": "chǎng",
    "meaning": "factory, mill, plant, works, yard, depot",
    "grade": 3
  },
  {
    "id": 1337,
    "traditional": "長期",
    "simplified": "长期",
    "reading": "chángqī",
    "meaning": "long term, long time, long range (of a forecast)",
    "grade": 3
  },
  {
    "id": 1338,
    "traditional": "場合",
    "simplified": "场合",
    "reading": "chǎnghé",
    "meaning": "conjuncture, situation, event, occasion, social occasion, affair",
    "grade": 3
  },
  {
    "id": 1339,
    "traditional": "場所",
    "simplified": "场所",
    "reading": "chǎngsuǒ",
    "meaning": "site, digging, terrain, locality, point, arena, scene, room, locus, location, locale, stead, scene of action, lieu, amenity, meridian, where, spot, space, land site, place, seat",
    "grade": 3
  },
  {
    "id": 1340,
    "traditional": "朝",
    "simplified": "朝",
    "reading": "cháo",
    "meaning": "abbr. for 朝鮮|朝鲜[Chao2 xian3] Korea, imperial or royal court, government, dynasty, reign of a sovereign or emperor, court or assembly held by a sovereign or emperor, to make a pilgrimage to, facing, towards, morning",
    "grade": 3
  },
  {
    "id": 1341,
    "traditional": "吵",
    "simplified": "吵",
    "reading": "chǎo",
    "meaning": "shout for something",
    "grade": 3
  },
  {
    "id": 1342,
    "traditional": "襯衫",
    "simplified": "衬衫",
    "reading": "chènshān",
    "meaning": "shirt, blouse, CL:件[jian4]",
    "grade": 3
  },
  {
    "id": 1343,
    "traditional": "超級",
    "simplified": "超级",
    "reading": "chāojí",
    "meaning": "super",
    "grade": 3
  },
  {
    "id": 1344,
    "traditional": "吵架",
    "simplified": "吵架",
    "reading": "chǎo jià",
    "meaning": "quarrel, have a row/spat",
    "grade": 3
  },
  {
    "id": 1345,
    "traditional": "襯衣",
    "simplified": "衬衣",
    "reading": "chènyī",
    "meaning": "shirt, CL:件[jian4]",
    "grade": 3
  },
  {
    "id": 1346,
    "traditional": "稱為",
    "simplified": "称为",
    "reading": "chēngwéi",
    "meaning": "call as",
    "grade": 3
  },
  {
    "id": 1347,
    "traditional": "成功",
    "simplified": "成功",
    "reading": "chénggōng",
    "meaning": "succeed",
    "grade": 3
  },
  {
    "id": 1348,
    "traditional": "成果",
    "simplified": "成果",
    "reading": "chéngguǒ",
    "meaning": "product, achievement, outcome, gain, production, fruit, positive result, progeny, job",
    "grade": 3
  },
  {
    "id": 1349,
    "traditional": "成就",
    "simplified": "成就",
    "reading": "chéngjiù",
    "meaning": "achievement, accomplishment, success",
    "grade": 3
  },
  {
    "id": 1350,
    "traditional": "成熟",
    "simplified": "成熟",
    "reading": "chéngshú",
    "meaning": "ripe, mature",
    "grade": 3
  },
  {
    "id": 1351,
    "traditional": "成立",
    "simplified": "成立",
    "reading": "chénglì",
    "meaning": "found, establish, be tenable, hold water",
    "grade": 3
  },
  {
    "id": 1352,
    "traditional": "成員",
    "simplified": "成员",
    "reading": "chéngyuán",
    "meaning": "member",
    "grade": 3
  },
  {
    "id": 1353,
    "traditional": "成長",
    "simplified": "成长",
    "reading": "chéngzhǎng",
    "meaning": "grow up, mature",
    "grade": 3
  },
  {
    "id": 1354,
    "traditional": "城",
    "simplified": "城",
    "reading": "chéng",
    "meaning": "wall, city wall, city, kasbah, town, township, burgh, castle, burg",
    "grade": 3
  },
  {
    "id": 1355,
    "traditional": "城市",
    "simplified": "城市",
    "reading": "chéngshì",
    "meaning": "city, town, CL:座[zuo4]",
    "grade": 3
  },
  {
    "id": 1356,
    "traditional": "持續",
    "simplified": "持续",
    "reading": "chíxù",
    "meaning": "perennate, hang on, carry_over, persist, steady, keep, carry over, keep on, proceed, stretch, carry_on, retain, sustain, persevere, continuity, endure, stay, continue, hang in, hold, maintain, run, span, go on, run for, last, go along, exist, hold on",
    "grade": 3
  },
  {
    "id": 1357,
    "traditional": "程度",
    "simplified": "程度",
    "reading": "chéngdù",
    "meaning": "latitude, rate, degree, point, measure, way, grade, gree, pitch, cut, length, plane, stage, level, extent, scale, notch",
    "grade": 3
  },
  {
    "id": 1358,
    "traditional": "初",
    "simplified": "初",
    "reading": "chū",
    "meaning": "at the beginning of, in the early part of",
    "grade": 3
  },
  {
    "id": 1359,
    "traditional": "充滿",
    "simplified": "充满",
    "reading": "chōngmǎn",
    "meaning": "swarm, be brimming, inhabit, be brimming with, teem, crawl, fill, lard, pullulate with, brim, be permeated with, bathe, be filled with, permeated with, abound in, implement, exuberate, fill up, flow, abound, burst, perfuse, foam, bristle, crackle, inform, pervade, be imbued with, simmer, glut, teem in, permeate, congest, be full of, reek, charge, suffuse, pullulate, overfill, swell",
    "grade": 3
  },
  {
    "id": 1360,
    "traditional": "初步",
    "simplified": "初步",
    "reading": "chūbù",
    "meaning": "initially, preliminarily, tentatively",
    "grade": 3
  },
  {
    "id": 1361,
    "traditional": "初級",
    "simplified": "初级",
    "reading": "chūjí",
    "meaning": "junior, primary",
    "grade": 3
  },
  {
    "id": 1362,
    "traditional": "重",
    "simplified": "重",
    "reading": "zhòng",
    "meaning": "heavy, weighty, important, considerable in amount/value",
    "grade": 3
  },
  {
    "id": 1363,
    "traditional": "初中",
    "simplified": "初中",
    "reading": "chūzhōng",
    "meaning": "junior high school, abbr. for 初級中學|初级中学[chu1 ji2 zhong1 xue2]",
    "grade": 3
  },
  {
    "id": 1364,
    "traditional": "除了",
    "simplified": "除了",
    "reading": "chúle",
    "meaning": "except, besides",
    "grade": 3
  },
  {
    "id": 1365,
    "traditional": "處理",
    "simplified": "处理",
    "reading": "chǔlǐ",
    "meaning": "handle, deal with, dispose of, process",
    "grade": 3
  },
  {
    "id": 1366,
    "traditional": "傳",
    "simplified": "传",
    "reading": "chuán",
    "meaning": "pass (on), hand down, impart, teach, spread, transmit",
    "grade": 3
  },
  {
    "id": 1367,
    "traditional": "傳播",
    "simplified": "传播",
    "reading": "chuánbō",
    "meaning": "disseminate, propagate, spread",
    "grade": 3
  },
  {
    "id": 1368,
    "traditional": "傳說",
    "simplified": "传说",
    "reading": "chuánshuō",
    "meaning": "legend",
    "grade": 3
  },
  {
    "id": 1369,
    "traditional": "創新",
    "simplified": "创新",
    "reading": "chuàngxīn",
    "meaning": "bring forth new ideas, blaze new trails",
    "grade": 3
  },
  {
    "id": 1370,
    "traditional": "創造",
    "simplified": "创造",
    "reading": "chuàngzào",
    "meaning": "create, produce, bring about",
    "grade": 3
  },
  {
    "id": 1371,
    "traditional": "傳來",
    "simplified": "传来",
    "reading": "chuánlái",
    "meaning": "spread, arise, salute, deliver something to here",
    "grade": 3
  },
  {
    "id": 1372,
    "traditional": "創作",
    "simplified": "创作",
    "reading": "chuàngzuò",
    "meaning": "creative work, creation",
    "grade": 3
  },
  {
    "id": 1373,
    "traditional": "創業",
    "simplified": "创业",
    "reading": "chuàngyè",
    "meaning": "start undertaking, do pioneering work",
    "grade": 3
  },
  {
    "id": 1374,
    "traditional": "從前",
    "simplified": "从前",
    "reading": "cóngqián",
    "meaning": "previously, formerly, once upon a time",
    "grade": 3
  },
  {
    "id": 1375,
    "traditional": "從來",
    "simplified": "从来",
    "reading": "cónglái",
    "meaning": "all along, at all times, always",
    "grade": 3
  },
  {
    "id": 1376,
    "traditional": "從事",
    "simplified": "从事",
    "reading": "cóngshì",
    "meaning": "engross, have, join, treat, plow, handle, follow, embark, undertake, attend_to, attack, occupy, absorb, go_into, work, engage, prosecute, go_about, go in for, pursue, be, meddle, embark_on, perform, enter, cover, deal, address, be engaged in, deal with, place, wage",
    "grade": 3
  },
  {
    "id": 1377,
    "traditional": "存",
    "simplified": "存",
    "reading": "cún",
    "meaning": "harbour, keep, deposit (money), gather, harbor, cherish, accumulate, existence, live, leave with, retain, reserve, save, consist, accumulation, preserve, survival, store, survive, check (luggage), collect, deposit, place, bank, check, exist, be in stock, remain on balance",
    "grade": 3
  },
  {
    "id": 1378,
    "traditional": "村",
    "simplified": "村",
    "reading": "cūn",
    "meaning": "village, variant of 村[cun1]",
    "grade": 3
  },
  {
    "id": 1379,
    "traditional": "存在",
    "simplified": "存在",
    "reading": "cúnzài",
    "meaning": "exist, be",
    "grade": 3
  },
  {
    "id": 1380,
    "traditional": "錯誤",
    "simplified": "错误",
    "reading": "cuòwù",
    "meaning": "error, blunder",
    "grade": 3
  },
  {
    "id": 1381,
    "traditional": "達到",
    "simplified": "达到",
    "reading": "dá dào",
    "meaning": "extend to, find, fulfil, compass, encompass, extend, gain, answer, achievement, succeed, fulfill, live_up_to, attainment, strain, bring_up, achieve, get to, touch, strive, make, accomplish, progress to, come, hit, come_out, obtain, reach, attain, clock_up, suffice, do, get, arrive_at",
    "grade": 3
  },
  {
    "id": 1382,
    "traditional": "打破",
    "simplified": "打破",
    "reading": "dǎpo",
    "meaning": "shiver, better, explode, smash, crush, fall apart, discomfit, part, shatter, force, murder, wear, break, bust, drub, wear out",
    "grade": 3
  },
  {
    "id": 1383,
    "traditional": "大概",
    "simplified": "大概",
    "reading": "dàgài",
    "meaning": "chiefly, if not, perchance, approximately, believably, maybe, presumably, plausibly, almost, conceivably, most likely, assumedly, ablings, likely, perhaps, probably, supposedly, ablins, credibly, assumably, belike",
    "grade": 3
  },
  {
    "id": 1384,
    "traditional": "打聽",
    "simplified": "打听",
    "reading": "dǎting",
    "meaning": "to ask about, to make some inquiries, to ask around",
    "grade": 3
  },
  {
    "id": 1385,
    "traditional": "大使館",
    "simplified": "大使馆",
    "reading": "dàshǐguǎn",
    "meaning": "embassy, CL:座[zuo4],個|个[ge4]",
    "grade": 3
  },
  {
    "id": 1386,
    "traditional": "大約",
    "simplified": "大约",
    "reading": "dàyuē",
    "meaning": "about, around, probably, likely",
    "grade": 3
  },
  {
    "id": 1387,
    "traditional": "代",
    "simplified": "代",
    "reading": "dài",
    "meaning": "period, generation",
    "grade": 3
  },
  {
    "id": 1388,
    "traditional": "代表",
    "simplified": "代表",
    "reading": "dàibiǎo",
    "meaning": "representative, delegate",
    "grade": 3
  },
  {
    "id": 1389,
    "traditional": "大夫",
    "simplified": "大夫",
    "reading": "dàfū",
    "meaning": "a senior official in feudal China, physician, senior official, doctor",
    "grade": 3
  },
  {
    "id": 1390,
    "traditional": "代表團",
    "simplified": "代表团",
    "reading": "dàibiǎotuán",
    "meaning": "contingent, delegacy, mission, deputation, delegation",
    "grade": 3
  },
  {
    "id": 1391,
    "traditional": "帶動",
    "simplified": "带动",
    "reading": "dài dòng",
    "meaning": "drive, spur on, bring along",
    "grade": 3
  },
  {
    "id": 1392,
    "traditional": "單元",
    "simplified": "单元",
    "reading": "dānyuán",
    "meaning": "module, unit, unit of teaching materials, residential unit",
    "grade": 3
  },
  {
    "id": 1393,
    "traditional": "當初",
    "simplified": "当初",
    "reading": "dāngchū",
    "meaning": "at that time, originally",
    "grade": 3
  },
  {
    "id": 1394,
    "traditional": "帶領",
    "simplified": "带领",
    "reading": "dàilǐng",
    "meaning": "lead (army/party/etc.), guide",
    "grade": 3
  },
  {
    "id": 1395,
    "traditional": "當然",
    "simplified": "当然",
    "reading": "dāngrán",
    "meaning": "certainly, of course",
    "grade": 3
  },
  {
    "id": 1396,
    "traditional": "當地",
    "simplified": "当地",
    "reading": "dāngdì",
    "meaning": "locality, the place (named/mentioned/etc.)",
    "grade": 3
  },
  {
    "id": 1397,
    "traditional": "當中",
    "simplified": "当中",
    "reading": "dāngzhōng",
    "meaning": "in the middle/center",
    "grade": 3
  },
  {
    "id": 1398,
    "traditional": "刀",
    "simplified": "刀",
    "reading": "dāo",
    "meaning": "knife, sword, blade, sth. shaped like a knife",
    "grade": 3
  },
  {
    "id": 1399,
    "traditional": "導演",
    "simplified": "导演",
    "reading": "dǎoyǎn",
    "meaning": "director",
    "grade": 3
  },
  {
    "id": 1400,
    "traditional": "到達",
    "simplified": "到达",
    "reading": "dàodá",
    "meaning": "find, arrive at, go_into, get_in, go, come, show, hit, strike, arrive, fetch_up, range, show_up, gain, come_to, win, reach, come to, attain, lead, go_down, come_in, arrival, get_at, get to, get, touch, make, come_through, arrive_at, land",
    "grade": 3
  },
  {
    "id": 1401,
    "traditional": "得分",
    "simplified": "得分",
    "reading": "dé fēn",
    "meaning": "score",
    "grade": 3
  },
  {
    "id": 1402,
    "traditional": "到底",
    "simplified": "到底",
    "reading": "dàodǐ",
    "meaning": "to the end, at last, after all",
    "grade": 3
  },
  {
    "id": 1403,
    "traditional": "等待",
    "simplified": "等待",
    "reading": "děngdài",
    "meaning": "wait for, await",
    "grade": 3
  },
  {
    "id": 1404,
    "traditional": "底下",
    "simplified": "底下",
    "reading": "dǐxia",
    "meaning": "under, below, beneath",
    "grade": 3
  },
  {
    "id": 1405,
    "traditional": "地區",
    "simplified": "地区",
    "reading": "dìqū",
    "meaning": "section, country, area, latitude, zone, terrain, parts, locality, sector, province, prefecture, region, district, faubourg",
    "grade": 3
  },
  {
    "id": 1406,
    "traditional": "電視臺",
    "simplified": "电视台",
    "reading": "diànshìtái",
    "meaning": "television station, station, spreader, broadcaster, TV station",
    "grade": 3
  },
  {
    "id": 1407,
    "traditional": "電臺",
    "simplified": "电台",
    "reading": "diàntái",
    "meaning": "transceiver, transmitter-receiver, broadcasting station",
    "grade": 3
  },
  {
    "id": 1408,
    "traditional": "電子郵件",
    "simplified": "电子邮件",
    "reading": "diànzǐyóujiàn",
    "meaning": "email, CL:封[feng1],份[fen4]",
    "grade": 3
  },
  {
    "id": 1409,
    "traditional": "調",
    "simplified": "调",
    "reading": "diào",
    "meaning": "to transfer, to move (troops or cadres), to investigate, to enquire into, accent, view, argument, key (in music), mode (music), tune, tone, melody, to harmonize, to reconcile, to blend, to suit well, to adjust, to regulate, to season (food), to provoke, to incite",
    "grade": 3
  },
  {
    "id": 1410,
    "traditional": "電視劇",
    "simplified": "电视剧",
    "reading": "diànshìjù",
    "meaning": "TV play, soap opera, CL:部[bu4]",
    "grade": 3
  },
  {
    "id": 1411,
    "traditional": "調查",
    "simplified": "调查",
    "reading": "diàochá",
    "meaning": "investigate, look into, survey",
    "grade": 3
  },
  {
    "id": 1412,
    "traditional": "訂",
    "simplified": "订",
    "reading": "dìng",
    "meaning": "subscribe to, agree on, revise, draw up, staple together, book (seats), conclude, order, book, subscribe, make corrections",
    "grade": 3
  },
  {
    "id": 1413,
    "traditional": "定期",
    "simplified": "定期",
    "reading": "dìngqī",
    "meaning": "fixed (of time), periodically, regularly",
    "grade": 3
  },
  {
    "id": 1414,
    "traditional": "東部",
    "simplified": "东部",
    "reading": "dōngbù",
    "meaning": "orient, East, eastward, east",
    "grade": 3
  },
  {
    "id": 1415,
    "traditional": "動人",
    "simplified": "动人",
    "reading": "dòngrén",
    "meaning": "moving, fetch, emotional, attractive, touching",
    "grade": 3
  },
  {
    "id": 1416,
    "traditional": "讀者",
    "simplified": "读者",
    "reading": "dúzhě",
    "meaning": "audience, subscriber, reader",
    "grade": 3
  },
  {
    "id": 1417,
    "traditional": "動力",
    "simplified": "动力",
    "reading": "dònglì",
    "meaning": "driving force, impetus, motivity, (motive) power, wheel, (motive) power/force, agency, vector, dynamics, force, motive_power, momentum, power",
    "grade": 3
  },
  {
    "id": 1418,
    "traditional": "短褲",
    "simplified": "短裤",
    "reading": "duǎnkù",
    "meaning": "short pants, shorts",
    "grade": 3
  },
  {
    "id": 1419,
    "traditional": "短處",
    "simplified": "短处",
    "reading": "duǎnchu",
    "meaning": "shortcoming, defect, fault, one's weak points",
    "grade": 3
  },
  {
    "id": 1420,
    "traditional": "斷",
    "simplified": "断",
    "reading": "duàn",
    "meaning": "give up, cut off, break off, stop, abstain from, judge, snap, break, decide",
    "grade": 3
  },
  {
    "id": 1421,
    "traditional": "短期",
    "simplified": "短期",
    "reading": "duǎnqī",
    "meaning": "short term, short-term",
    "grade": 3
  },
  {
    "id": 1422,
    "traditional": "隊員",
    "simplified": "队员",
    "reading": "duìyuán",
    "meaning": "team member",
    "grade": 3
  },
  {
    "id": 1423,
    "traditional": "對待",
    "simplified": "对待",
    "reading": "duìdài",
    "meaning": "treat, approach, handle",
    "grade": 3
  },
  {
    "id": 1424,
    "traditional": "對方",
    "simplified": "对方",
    "reading": "duìfāng",
    "meaning": "other party, the other party, fellow, party, other side, adversary, other side/party",
    "grade": 3
  },
  {
    "id": 1425,
    "traditional": "對象",
    "simplified": "对象",
    "reading": "duìxiàng",
    "meaning": "boy/girl friend, mate, fair game, boy friend, prey, boy, object, quarry, target, girl friend, catch, match",
    "grade": 3
  },
  {
    "id": 1426,
    "traditional": "對手",
    "simplified": "对手",
    "reading": "duìshǒu",
    "meaning": "mate, competitor, opposite, antagonist, comparative, contender, adversary, opponent, opposition, counterpart, equal, counterworker, rival, match",
    "grade": 3
  },
  {
    "id": 1427,
    "traditional": "發表",
    "simplified": "发表",
    "reading": "fābiǎo",
    "meaning": "voice, enounce, enunciate, publish, free, sound, announce, pronounce, deliver, circulation, put_out, release, issue, vend, circularize, give, give_out, denote, present, circulate, publicize, blazon, promulgate, mouth",
    "grade": 3
  },
  {
    "id": 1428,
    "traditional": "頓",
    "simplified": "顿",
    "reading": "dùn",
    "meaning": "m.[event]",
    "grade": 3
  },
  {
    "id": 1429,
    "traditional": "發出",
    "simplified": "发出",
    "reading": "fā chū",
    "meaning": "voice, send_out, give_forth, send out, exhale, sound, fetch, pass off, shoot, effuse, issue, emanate, raise, give, shed, send/give out, utter, go, escape, proceed, send, give forth, give_off, give off, fall, eradiate, emit, flash, scintillate, issue_forth, give_out, give out, clank, reek, place, breathe",
    "grade": 3
  },
  {
    "id": 1430,
    "traditional": "發達",
    "simplified": "发达",
    "reading": "fādá",
    "meaning": "developed, flourishing",
    "grade": 3
  },
  {
    "id": 1431,
    "traditional": "發動",
    "simplified": "发动",
    "reading": "fādòng",
    "meaning": "start, launch, mobilize, arouse",
    "grade": 3
  },
  {
    "id": 1432,
    "traditional": "發明",
    "simplified": "发明",
    "reading": "fāmíng",
    "meaning": "invention",
    "grade": 3
  },
  {
    "id": 1433,
    "traditional": "發生",
    "simplified": "发生",
    "reading": "fāshēng",
    "meaning": "happen, occur, take place",
    "grade": 3
  },
  {
    "id": 1434,
    "traditional": "發送",
    "simplified": "发送",
    "reading": "fāsong",
    "meaning": "to transmit, to dispatch, to issue (an official document or credential)",
    "grade": 3
  },
  {
    "id": 1435,
    "traditional": "發言",
    "simplified": "发言",
    "reading": "fāyán",
    "meaning": "speak, make statement/speech",
    "grade": 3
  },
  {
    "id": 1436,
    "traditional": "發展",
    "simplified": "发展",
    "reading": "fāzhǎn",
    "meaning": "develop, expand, grow",
    "grade": 3
  },
  {
    "id": 1437,
    "traditional": "反復",
    "simplified": "反复",
    "reading": "fǎnfù",
    "meaning": "repeatedly, backward_and_forward",
    "grade": 3
  },
  {
    "id": 1438,
    "traditional": "反應",
    "simplified": "反应",
    "reading": "fǎnyìng",
    "meaning": "reaction, response, repercussion",
    "grade": 3
  },
  {
    "id": 1439,
    "traditional": "反正",
    "simplified": "反正",
    "reading": "fǎnzheng",
    "meaning": "anyway, anyhow, in any case",
    "grade": 3
  },
  {
    "id": 1440,
    "traditional": "範圍",
    "simplified": "范围",
    "reading": "fànwéi",
    "meaning": "demesne, latitude, shot, compass, extensity, panorama, parameter, boundary, arena, stretch, territory, radius, spectrum, scope, extension, sphere, orbit, circumscription, limits, purview, grasp, extent, region, space, limit, hemisphere, horizon, envelope, bounds, realm, terrain, tether, sphere of influence, range, circuit, domain, precinct, reach, area, length, spread, bailiwick, ambit, con",
    "grade": 3
  },
  {
    "id": 1441,
    "traditional": "反對",
    "simplified": "反对",
    "reading": "fǎnduì",
    "meaning": "to fight against, to oppose, to be opposed to, opposition",
    "grade": 3
  },
  {
    "id": 1442,
    "traditional": "防",
    "simplified": "防",
    "reading": "fáng",
    "meaning": "guard against, guard, prevent, prepare against, defence, resistance, guard/prepare against, resist, defend, provide against",
    "grade": 3
  },
  {
    "id": 1443,
    "traditional": "防止",
    "simplified": "防止",
    "reading": "fángzhǐ",
    "meaning": "estop, guard against, preclude, prevention, inhibit, avoidance, help, cumber, prevent, forbid, prohibit, obviate, restrain, deter, avoid, head_off, debar, guard, help oneself, ward_off, constrain, ward, encumber, avert, ward off, disenable, forfend, shield, countercheck, forestall, keep_from, foreclose",
    "grade": 3
  },
  {
    "id": 1444,
    "traditional": "方式",
    "simplified": "方式",
    "reading": "fāngshì",
    "meaning": "path, plan, pattern, tenor, system, method, manner, style, way, guise, fashion, wise, means, shape, how, embodiment, agency, gate, mode, sort",
    "grade": 3
  },
  {
    "id": 1445,
    "traditional": "房東",
    "simplified": "房东",
    "reading": "fángdōng",
    "meaning": "landlord",
    "grade": 3
  },
  {
    "id": 1446,
    "traditional": "訪問",
    "simplified": "访问",
    "reading": "fǎngwèn",
    "meaning": "visit, call on, interview",
    "grade": 3
  },
  {
    "id": 1447,
    "traditional": "放到",
    "simplified": "放到",
    "reading": "fàng dào",
    "meaning": "Put in",
    "grade": 3
  },
  {
    "id": 1448,
    "traditional": "房屋",
    "simplified": "房屋",
    "reading": "fángwū",
    "meaning": "casa, premises, buildings, digging, houses, housing, tenement, house",
    "grade": 3
  },
  {
    "id": 1449,
    "traditional": "費",
    "simplified": "费",
    "reading": "fèi",
    "meaning": "cost, spend, expend",
    "grade": 3
  },
  {
    "id": 1450,
    "traditional": "飛行",
    "simplified": "飞行",
    "reading": "fēixíng",
    "meaning": "fly",
    "grade": 3
  },
  {
    "id": 1451,
    "traditional": "房租",
    "simplified": "房租",
    "reading": "fángzū",
    "meaning": "rent for a room or house",
    "grade": 3
  },
  {
    "id": 1452,
    "traditional": "費用",
    "simplified": "费用",
    "reading": "fèiyong",
    "meaning": "tax, cost, expense, expenditure, due, rate, exes, outlay, outgo, fee, terms, toll, price, disbursement, fare, expenses, disbursal, retainer, pay, damage, charge, monetary value, consideration, outgoing",
    "grade": 3
  },
  {
    "id": 1453,
    "traditional": "分別",
    "simplified": "分别",
    "reading": "fēnbié",
    "meaning": "separately, respectively",
    "grade": 3
  },
  {
    "id": 1454,
    "traditional": "分組",
    "simplified": "分组",
    "reading": "fēn zǔ",
    "meaning": "divide into groups",
    "grade": 3
  },
  {
    "id": 1455,
    "traditional": "分配",
    "simplified": "分配",
    "reading": "fēnpèi",
    "meaning": "distribute, allot, assign",
    "grade": 3
  },
  {
    "id": 1456,
    "traditional": "豐富",
    "simplified": "丰富",
    "reading": "fēngfù",
    "meaning": "rich, abundant, plentiful",
    "grade": 3
  },
  {
    "id": 1457,
    "traditional": "風險",
    "simplified": "风险",
    "reading": "fēngxiǎn",
    "meaning": "peril, danger, risk, hazard",
    "grade": 3
  },
  {
    "id": 1458,
    "traditional": "否定",
    "simplified": "否定",
    "reading": "fǒudìng",
    "meaning": "negate, deny",
    "grade": 3
  },
  {
    "id": 1459,
    "traditional": "服裝",
    "simplified": "服装",
    "reading": "fúzhuāng",
    "meaning": "turnout, wearing_apparel, clothing, investiture, rigging, costuming, toggery, habilimentation, habiliment, drapery, garb, attire, toilette, frock, costume, dress, investment, garmenture, rig, garment, setout, implement, garniture, getup, outfit",
    "grade": 3
  },
  {
    "id": 1460,
    "traditional": "父母",
    "simplified": "父母",
    "reading": "fùmǔ",
    "meaning": "parental, parents, father and mother, folks",
    "grade": 3
  },
  {
    "id": 1461,
    "traditional": "否認",
    "simplified": "否认",
    "reading": "fǒurèn",
    "meaning": "deny, disavow, repudiation, naysay, denial, gainsay, reject, veto, negate, disclaim, disown, challenge, dispute, renounce, disaffirm, negative, contradict, contravene, repudiate",
    "grade": 3
  },
  {
    "id": 1462,
    "traditional": "福",
    "simplified": "福",
    "reading": "fú",
    "meaning": "felicity, happiness, blessing, good fortune",
    "grade": 3
  },
  {
    "id": 1463,
    "traditional": "父親",
    "simplified": "父亲",
    "reading": "fùqin",
    "meaning": "parent, father, pere, Pa, begetter, governor, abba, male parent, pater, guv",
    "grade": 3
  },
  {
    "id": 1464,
    "traditional": "負責",
    "simplified": "负责",
    "reading": "fùzé",
    "meaning": "preside, be responsible for, see, entail, sponsor, be in charge of, blame, answer, undertake, liable, respond",
    "grade": 3
  },
  {
    "id": 1465,
    "traditional": "複印",
    "simplified": "复印",
    "reading": "fùyìn",
    "meaning": "to photocopy, to duplicate a document",
    "grade": 3
  },
  {
    "id": 1466,
    "traditional": "付",
    "simplified": "付",
    "reading": "fù",
    "meaning": "hand/turn over to, commit to, pay",
    "grade": 3
  },
  {
    "id": 1467,
    "traditional": "複雜",
    "simplified": "复杂",
    "reading": "fùzá",
    "meaning": "complicated, complex",
    "grade": 3
  },
  {
    "id": 1468,
    "traditional": "改造",
    "simplified": "改造",
    "reading": "gǎizào",
    "meaning": "recast, rebuild, re-form, reconstruct, remake, transformation, remould, transmake, reform, transform, remodel, reorganize, retread, remold, remodify, reshape, remoulding, redo, alter, recreate, transmute, make over, refashion, revamp",
    "grade": 3
  },
  {
    "id": 1469,
    "traditional": "富",
    "simplified": "富",
    "reading": "fù",
    "meaning": "be rich in, be full of",
    "grade": 3
  },
  {
    "id": 1470,
    "traditional": "改進",
    "simplified": "改进",
    "reading": "gǎijìn",
    "meaning": "improve",
    "grade": 3
  },
  {
    "id": 1471,
    "traditional": "概念",
    "simplified": "概念",
    "reading": "gàiniàn",
    "meaning": "estimate, conception, view, intention, image, estimation, idea, notion, concept, construct, paradox, approximation, impression",
    "grade": 3
  },
  {
    "id": 1472,
    "traditional": "趕",
    "simplified": "赶",
    "reading": "gǎn",
    "meaning": "run after, pursue",
    "grade": 3
  },
  {
    "id": 1473,
    "traditional": "趕到",
    "simplified": "赶到",
    "reading": "gǎndào",
    "meaning": "leave for, hurry to",
    "grade": 3
  },
  {
    "id": 1474,
    "traditional": "趕快",
    "simplified": "赶快",
    "reading": "gǎnkuài",
    "meaning": "quickly, hastily",
    "grade": 3
  },
  {
    "id": 1475,
    "traditional": "趕緊",
    "simplified": "赶紧",
    "reading": "gǎnjǐn",
    "meaning": "hasten, double-quick, losing no time, hurriedly",
    "grade": 3
  },
  {
    "id": 1476,
    "traditional": "敢",
    "simplified": "敢",
    "reading": "gǎn",
    "meaning": "dare, make bold, have courage to, be sure, have confidence to, venture, have the confidence to, be certain",
    "grade": 3
  },
  {
    "id": 1477,
    "traditional": "感冒",
    "simplified": "感冒",
    "reading": "gǎnmào",
    "meaning": "catch cold",
    "grade": 3
  },
  {
    "id": 1478,
    "traditional": "感受",
    "simplified": "感受",
    "reading": "gǎnshòu",
    "meaning": "perception, sentiment, mood",
    "grade": 3
  },
  {
    "id": 1479,
    "traditional": "幹嗎",
    "simplified": "干吗",
    "reading": "gànmá",
    "meaning": "see 幹嘛|干嘛[gan4 ma2]",
    "grade": 3
  },
  {
    "id": 1480,
    "traditional": "感情",
    "simplified": "感情",
    "reading": "gǎnqíng",
    "meaning": "warmheartedness, reticence, sensation, affection, susceptibility, feeling, feelings, heart, affective, withers, expression, tenderness, emotion, sentiment, fondness, soul, affectionateness, soulfulness, passion",
    "grade": 3
  },
  {
    "id": 1481,
    "traditional": "高速",
    "simplified": "高速",
    "reading": "gāosù",
    "meaning": "great speed, velocity, high speed, high",
    "grade": 3
  },
  {
    "id": 1482,
    "traditional": "高速公路",
    "simplified": "高速公路",
    "reading": "gāosùgōnglù",
    "meaning": "expressway, highway, freeway",
    "grade": 3
  },
  {
    "id": 1483,
    "traditional": "告別",
    "simplified": "告别",
    "reading": "gàobié",
    "meaning": "valedictory, take_leave, part from, leave, parting, say good-bye to, bid farewell to",
    "grade": 3
  },
  {
    "id": 1484,
    "traditional": "歌聲",
    "simplified": "歌声",
    "reading": "gēshēng",
    "meaning": "voice, song, singing, sound of singing, singing voice",
    "grade": 3
  },
  {
    "id": 1485,
    "traditional": "歌迷",
    "simplified": "歌迷",
    "reading": "gēmí",
    "meaning": "lover of song, fan of a singer",
    "grade": 3
  },
  {
    "id": 1486,
    "traditional": "歌手",
    "simplified": "歌手",
    "reading": "gēshǒu",
    "meaning": "warbler, voice, vocalist, singer, bulbul, performer, lark, songster",
    "grade": 3
  },
  {
    "id": 1487,
    "traditional": "各",
    "simplified": "各",
    "reading": "gè",
    "meaning": "det.: each, every",
    "grade": 3
  },
  {
    "id": 1488,
    "traditional": "個性",
    "simplified": "个性",
    "reading": "gèxìng",
    "meaning": "kidney, specific property, personalism, fibre, individuation, personhood, particularity, fiber, character, individual character, self-identity, mentality, idiosyncrasy, spirit, individuality, individualism, selfhood, personality",
    "grade": 3
  },
  {
    "id": 1489,
    "traditional": "個人",
    "simplified": "个人",
    "reading": "gèrén",
    "meaning": "individual person, man_jack, personal, individual (person), man jack, individual, individuality, identity",
    "grade": 3
  },
  {
    "id": 1490,
    "traditional": "各位",
    "simplified": "各位",
    "reading": "gèwèi",
    "meaning": "everybody, all (guests, colleagues etc), all of you",
    "grade": 3
  },
  {
    "id": 1491,
    "traditional": "各地",
    "simplified": "各地",
    "reading": "gèdì",
    "meaning": "in all parts of (a country), various regions",
    "grade": 3
  },
  {
    "id": 1492,
    "traditional": "各種",
    "simplified": "各种",
    "reading": "gèzhǒng",
    "meaning": "every kind of, all kinds of, various kinds",
    "grade": 3
  },
  {
    "id": 1493,
    "traditional": "根本",
    "simplified": "根本",
    "reading": "gēnběn",
    "meaning": "at all, utterly",
    "grade": 3
  },
  {
    "id": 1494,
    "traditional": "各自",
    "simplified": "各自",
    "reading": "gèzì",
    "meaning": "each, respective, severalty, oneself",
    "grade": 3
  },
  {
    "id": 1495,
    "traditional": "更加",
    "simplified": "更加",
    "reading": "gèngjiā",
    "meaning": "(even) more",
    "grade": 3
  },
  {
    "id": 1496,
    "traditional": "工程師",
    "simplified": "工程师",
    "reading": "gōngchéngshī",
    "meaning": "technologist, engineer, applied scientist",
    "grade": 3
  },
  {
    "id": 1497,
    "traditional": "工夫",
    "simplified": "工夫",
    "reading": "gōngfu",
    "meaning": "workmanship, skill, art",
    "grade": 3
  },
  {
    "id": 1498,
    "traditional": "工具",
    "simplified": "工具",
    "reading": "gōngjù",
    "meaning": "stock-in-trade, instrumentality, instrument, ministry, hand_tool, escalator, creature, utensil, material, gear, pawn, lobster-joint, cat's-paw, kit, apparatus, tool, appurtenances, paraphernalia, means, wood, tackle, implement, medium, lever, outfit",
    "grade": 3
  },
  {
    "id": 1499,
    "traditional": "工業",
    "simplified": "工业",
    "reading": "gōngyè",
    "meaning": "industrial, industry",
    "grade": 3
  },
  {
    "id": 1500,
    "traditional": "工廠",
    "simplified": "工厂",
    "reading": "gōngchǎng",
    "meaning": "layout, mill, factory, remuneration, workshop, industrial plant, plant, hacienda, manufactory, works, shop",
    "grade": 3
  },
  {
    "id": 1501,
    "traditional": "工資",
    "simplified": "工资",
    "reading": "gōngzī",
    "meaning": "earning, hire, remuneration, screw, laborage, pay, paycheck, wages, earnings, pay_packet, salary, wage_scale, differential, wage",
    "grade": 3
  },
  {
    "id": 1502,
    "traditional": "公布",
    "simplified": "公布",
    "reading": "gōngbù",
    "meaning": "promulgate, announce, publish",
    "grade": 3
  },
  {
    "id": 1503,
    "traditional": "公共",
    "simplified": "公共",
    "reading": "gōnggòng",
    "meaning": "common, commonly, publicly, public, communal",
    "grade": 3
  },
  {
    "id": 1504,
    "traditional": "公開",
    "simplified": "公开",
    "reading": "gōngkāi",
    "meaning": "bare, air, unclose, publish, come_out, make known to the public, publicize, divulgate, overt, publicise, public, disclosure, make known to public, unfurl, make public",
    "grade": 3
  },
  {
    "id": 1505,
    "traditional": "公務員",
    "simplified": "公务员",
    "reading": "gōngwùyuán",
    "meaning": "jobholder, servant, public_servant, official, government official worker, functionary, government employee, public servant, officeholder, officialdom, officer, orderly, civil servant, government officials",
    "grade": 3
  },
  {
    "id": 1506,
    "traditional": "功課",
    "simplified": "功课",
    "reading": "gōngkè",
    "meaning": "task, schoolwork, homework, preparation, prep, subject, lesson",
    "grade": 3
  },
  {
    "id": 1507,
    "traditional": "公民",
    "simplified": "公民",
    "reading": "gōngmín",
    "meaning": "freeman, citizen, denizen, national, civic, freewoman, commonwealth, burgher, civics",
    "grade": 3
  },
  {
    "id": 1508,
    "traditional": "功能",
    "simplified": "功能",
    "reading": "gōngnéng",
    "meaning": "functional, niche, function, competence, capacity, anatomy",
    "grade": 3
  },
  {
    "id": 1509,
    "traditional": "共同",
    "simplified": "共同",
    "reading": "gòngtóng",
    "meaning": "common, joint",
    "grade": 3
  },
  {
    "id": 1510,
    "traditional": "功夫",
    "simplified": "功夫",
    "reading": "gōngfu",
    "meaning": "workmanship, skill, art",
    "grade": 3
  },
  {
    "id": 1511,
    "traditional": "姑娘",
    "simplified": "姑娘",
    "reading": "gūniang",
    "meaning": "quiff, cummer, gill, calico, girlie, missy, moll, damsel, fille, girl, jill, miss, judy, jeune fille, sheila, jane, lass, colleen, toots, daughter, young girl, lassie",
    "grade": 3
  },
  {
    "id": 1512,
    "traditional": "共有",
    "simplified": "共有",
    "reading": "gòngyǒu",
    "meaning": "to have altogether, in all",
    "grade": 3
  },
  {
    "id": 1513,
    "traditional": "古",
    "simplified": "古",
    "reading": "gǔ",
    "meaning": "ancient, age-old;not following current customs/practice",
    "grade": 3
  },
  {
    "id": 1514,
    "traditional": "古代",
    "simplified": "古代",
    "reading": "gǔdài",
    "meaning": "ancient times, olden times",
    "grade": 3
  },
  {
    "id": 1515,
    "traditional": "故鄉",
    "simplified": "故乡",
    "reading": "gùxiāng",
    "meaning": "country, home, patria, place of birth, native place, hometown, homeland, motherland, birthplace",
    "grade": 3
  },
  {
    "id": 1516,
    "traditional": "關係",
    "simplified": "关系",
    "reading": "guānxi",
    "meaning": "relation, bearing, impact, membership credentials, backdoor connections",
    "grade": 3
  },
  {
    "id": 1517,
    "traditional": "關注",
    "simplified": "关注",
    "reading": "guānzhù",
    "meaning": "follow with interest, pay close attention to",
    "grade": 3
  },
  {
    "id": 1518,
    "traditional": "觀察",
    "simplified": "观察",
    "reading": "guānchá",
    "meaning": "observe, survey, inspect",
    "grade": 3
  },
  {
    "id": 1519,
    "traditional": "掛",
    "simplified": "挂",
    "reading": "guà",
    "meaning": "hang, put up, hitch, ring up",
    "grade": 3
  },
  {
    "id": 1520,
    "traditional": "觀看",
    "simplified": "观看",
    "reading": "guānkàn",
    "meaning": "eye, view, watch, spectate, eyeball",
    "grade": 3
  },
  {
    "id": 1521,
    "traditional": "觀念",
    "simplified": "观念",
    "reading": "guānniàn",
    "meaning": "conception, intension, theory, philosophy, idolum, connotation, idea, sense, presentation, notion, concept, construct, intellection, presentment, thought, notional, conceit",
    "grade": 3
  },
  {
    "id": 1522,
    "traditional": "管理",
    "simplified": "管理",
    "reading": "guǎnlǐ",
    "meaning": "manage, supervise, take care of",
    "grade": 3
  },
  {
    "id": 1523,
    "traditional": "觀眾",
    "simplified": "观众",
    "reading": "guānzhòng",
    "meaning": "onlooker, TV audience, attendance, audience, spectator, viewers, followers, crowd, gallery, viewer, following, house, viewing audience",
    "grade": 3
  },
  {
    "id": 1524,
    "traditional": "光明",
    "simplified": "光明",
    "reading": "guāngmíng",
    "meaning": "bright, promising, openhearted, guileless",
    "grade": 3
  },
  {
    "id": 1525,
    "traditional": "廣播",
    "simplified": "广播",
    "reading": "guǎngbō",
    "meaning": "broadcast, air",
    "grade": 3
  },
  {
    "id": 1526,
    "traditional": "管",
    "simplified": "管",
    "reading": "guǎn",
    "meaning": "run, manage, administer, have charge of, subject to discipline, bother about",
    "grade": 3
  },
  {
    "id": 1527,
    "traditional": "光",
    "simplified": "光",
    "reading": "guāng",
    "meaning": "light, ray, brightness, honor, glory, luster",
    "grade": 3
  },
  {
    "id": 1528,
    "traditional": "規定",
    "simplified": "规定",
    "reading": "guīdìng",
    "meaning": "rules, disciplines, regulations",
    "grade": 3
  },
  {
    "id": 1529,
    "traditional": "廣大",
    "simplified": "广大",
    "reading": "guǎngdà",
    "meaning": "large, wide, vast, ample, large-scale, numerous, wide-spread",
    "grade": 3
  },
  {
    "id": 1530,
    "traditional": "國內",
    "simplified": "国内",
    "reading": "guónèi",
    "meaning": "interior (of country), interior, home, intestine, internal, domestic",
    "grade": 3
  },
  {
    "id": 1531,
    "traditional": "規範",
    "simplified": "规范",
    "reading": "guīfàn",
    "meaning": "constraint, norm",
    "grade": 3
  },
  {
    "id": 1532,
    "traditional": "國慶",
    "simplified": "国庆",
    "reading": "Guóqìng",
    "meaning": "National Day",
    "grade": 3
  },
  {
    "id": 1533,
    "traditional": "果然",
    "simplified": "果然",
    "reading": "guǒrán",
    "meaning": "really, as expected, sure enough",
    "grade": 3
  },
  {
    "id": 1534,
    "traditional": "果汁",
    "simplified": "果汁",
    "reading": "guǒzhī",
    "meaning": "fruit juice",
    "grade": 3
  },
  {
    "id": 1535,
    "traditional": "過程",
    "simplified": "过程",
    "reading": "guòchéng",
    "meaning": "unconscious process, mechanism, process, act, round, course",
    "grade": 3
  },
  {
    "id": 1536,
    "traditional": "哈哈",
    "simplified": "哈哈",
    "reading": "hāha",
    "meaning": "sound of laughter, haha",
    "grade": 3
  },
  {
    "id": 1537,
    "traditional": "過去",
    "simplified": "过去",
    "reading": "guò qu",
    "meaning": "go over, pass by",
    "grade": 3
  },
  {
    "id": 1538,
    "traditional": "海關",
    "simplified": "海关",
    "reading": "hǎiguān",
    "meaning": "chophouse, customhouse, customshouse, custom, haikwan, customs, douane",
    "grade": 3
  },
  {
    "id": 1539,
    "traditional": "害怕",
    "simplified": "害怕",
    "reading": "hàipà",
    "meaning": "dread, be afraid/scared, fearful, frighten, fear, frightened, shrink_from, be afraid, funk, scared, be scared, tremble",
    "grade": 3
  },
  {
    "id": 1540,
    "traditional": "好好",
    "simplified": "好好",
    "reading": "hǎohǎo",
    "meaning": "well, carefully, nicely, properly",
    "grade": 3
  },
  {
    "id": 1541,
    "traditional": "行",
    "simplified": "行",
    "reading": "xíng",
    "meaning": "go, travel, prevail, circulate, be current",
    "grade": 3
  },
  {
    "id": 1542,
    "traditional": "好奇",
    "simplified": "好奇",
    "reading": "hàoqí",
    "meaning": "curious, full of curiosity",
    "grade": 3
  },
  {
    "id": 1543,
    "traditional": "合",
    "simplified": "合",
    "reading": "hé",
    "meaning": "be equal to, amount to",
    "grade": 3
  },
  {
    "id": 1544,
    "traditional": "合法",
    "simplified": "合法",
    "reading": "héfǎ",
    "meaning": "legal, well-formed, rightful, lawful, legitimate",
    "grade": 3
  },
  {
    "id": 1545,
    "traditional": "合格",
    "simplified": "合格",
    "reading": "hégé",
    "meaning": "measure_up, reach standard, measure up, qualify, nail, make it, pass, qualified",
    "grade": 3
  },
  {
    "id": 1546,
    "traditional": "合理",
    "simplified": "合理",
    "reading": "hélǐ",
    "meaning": "plausible, rational, reasonable, legitimate, equitable, sensible",
    "grade": 3
  },
  {
    "id": 1547,
    "traditional": "和平",
    "simplified": "和平",
    "reading": "hépíng",
    "meaning": "mild, peaceful",
    "grade": 3
  },
  {
    "id": 1548,
    "traditional": "紅茶",
    "simplified": "红茶",
    "reading": "hóngchá",
    "meaning": "tea, bohea, black_tea, black tea",
    "grade": 3
  },
  {
    "id": 1549,
    "traditional": "紅酒",
    "simplified": "红酒",
    "reading": "hóngjiǔ",
    "meaning": "Red wine",
    "grade": 3
  },
  {
    "id": 1550,
    "traditional": "後果",
    "simplified": "后果",
    "reading": "hòuguǒ",
    "meaning": "aftereffect, aftermath, consequent, wake, backwash, sequel, consequence, subsequence",
    "grade": 3
  },
  {
    "id": 1551,
    "traditional": "後面",
    "simplified": "后面",
    "reading": "hòumian",
    "meaning": "rear, back, in the rear, latter, behind, rearward, at the back",
    "grade": 3
  },
  {
    "id": 1552,
    "traditional": "後年",
    "simplified": "后年",
    "reading": "hòunián",
    "meaning": "the year after next",
    "grade": 3
  },
  {
    "id": 1553,
    "traditional": "互聯網",
    "simplified": "互联网",
    "reading": "hùliánwǎng",
    "meaning": "Internet",
    "grade": 3
  },
  {
    "id": 1554,
    "traditional": "合作",
    "simplified": "合作",
    "reading": "hézuò",
    "meaning": "cooperate, collaborate, work together",
    "grade": 3
  },
  {
    "id": 1555,
    "traditional": "划船",
    "simplified": "划船",
    "reading": "huá chuán",
    "meaning": "paddle/row boat",
    "grade": 3
  },
  {
    "id": 1556,
    "traditional": "互相",
    "simplified": "互相",
    "reading": "hùxiāng",
    "meaning": "mutual, each other, mutually, one another, reciprocally",
    "grade": 3
  },
  {
    "id": 1557,
    "traditional": "華人",
    "simplified": "华人",
    "reading": "huárén",
    "meaning": "Chinese, Ch. people",
    "grade": 3
  },
  {
    "id": 1558,
    "traditional": "化",
    "simplified": "化",
    "reading": "huà",
    "meaning": "melt, dissolve, thaw, digest, remove, burn up, incinerate, disguise",
    "grade": 3
  },
  {
    "id": 1559,
    "traditional": "話劇",
    "simplified": "话剧",
    "reading": "huàjù",
    "meaning": "stage play, modern drama, CL:臺|台[tai2],部[bu4]",
    "grade": 3
  },
  {
    "id": 1560,
    "traditional": "歡樂",
    "simplified": "欢乐",
    "reading": "huānlè",
    "meaning": "happy, joyous, gay",
    "grade": 3
  },
  {
    "id": 1561,
    "traditional": "環",
    "simplified": "环",
    "reading": "huán",
    "meaning": "ring, link",
    "grade": 3
  },
  {
    "id": 1562,
    "traditional": "話題",
    "simplified": "话题",
    "reading": "huàtí",
    "meaning": "talking_point, theme, gambit, subject of a talk, ground, talk, talking point, topic, chapter, topic of conversation",
    "grade": 3
  },
  {
    "id": 1563,
    "traditional": "環保",
    "simplified": "环保",
    "reading": "huánbǎo",
    "meaning": "environmental protection",
    "grade": 3
  },
  {
    "id": 1564,
    "traditional": "環境",
    "simplified": "环境",
    "reading": "huánjìng",
    "meaning": "circumambiency, environmental, circumstances, environment, entourage, surround, atmosphere, setting, surroundings, ambiance, condition, context, circumstance, surrounding, frame, milieu, ambient, consideration, environs, medium, ambience",
    "grade": 3
  },
  {
    "id": 1565,
    "traditional": "會議",
    "simplified": "会议",
    "reading": "huìyì",
    "meaning": "synod, moot, conference, board, convocation, soviet, congress, talks, indaba, negotiation, junta, group discussion, convention, meeting place, session, council, rendezvous, colloquy, coming together, powwow, seance, meeting, forum, consultation, roll-up, get-together, assembly",
    "grade": 3
  },
  {
    "id": 1566,
    "traditional": "會員",
    "simplified": "会员",
    "reading": "huìyuán",
    "meaning": "member",
    "grade": 3
  },
  {
    "id": 1567,
    "traditional": "火",
    "simplified": "火",
    "reading": "huǒ",
    "meaning": "fire;internal heat, anger, temper;firearms, ammunition",
    "grade": 3
  },
  {
    "id": 1568,
    "traditional": "活",
    "simplified": "活",
    "reading": "huó",
    "meaning": "alive, live, vivid, save (sb.'s life), activate, save, be",
    "grade": 3
  },
  {
    "id": 1569,
    "traditional": "機器",
    "simplified": "机器",
    "reading": "jīqì",
    "meaning": "enginery, life, machinery, machine, plant, apparatus, engine, gizmo",
    "grade": 3
  },
  {
    "id": 1570,
    "traditional": "基本",
    "simplified": "基本",
    "reading": "jīběn",
    "meaning": "fundamental, essential, main",
    "grade": 3
  },
  {
    "id": 1571,
    "traditional": "積極",
    "simplified": "积极",
    "reading": "jījí",
    "meaning": "positive, active, energetic, vigorous",
    "grade": 3
  },
  {
    "id": 1572,
    "traditional": "基本上",
    "simplified": "基本上",
    "reading": "jīběnshang",
    "meaning": "basically, on the whole",
    "grade": 3
  },
  {
    "id": 1573,
    "traditional": "及時",
    "simplified": "及时",
    "reading": "jíshí",
    "meaning": "timely, in time, promptly",
    "grade": 3
  },
  {
    "id": 1574,
    "traditional": "基礎",
    "simplified": "基础",
    "reading": "jīchǔ",
    "meaning": "base, foundation",
    "grade": 3
  },
  {
    "id": 1575,
    "traditional": "…極了",
    "simplified": "…极了",
    "reading": "…jíle",
    "meaning": "…Extremely",
    "grade": 3
  },
  {
    "id": 1576,
    "traditional": "集中",
    "simplified": "集中",
    "reading": "jízhōng",
    "meaning": "concentrate, centralize, focus, put together",
    "grade": 3
  },
  {
    "id": 1577,
    "traditional": "集體",
    "simplified": "集体",
    "reading": "jítǐ",
    "meaning": "collective",
    "grade": 3
  },
  {
    "id": 1578,
    "traditional": "計算",
    "simplified": "计算",
    "reading": "jìsuàn",
    "meaning": "count, calculate, compute",
    "grade": 3
  },
  {
    "id": 1579,
    "traditional": "記錄",
    "simplified": "记录",
    "reading": "jìlù",
    "meaning": "record, note",
    "grade": 3
  },
  {
    "id": 1580,
    "traditional": "記者",
    "simplified": "记者",
    "reading": "jìzhě",
    "meaning": "journalist, writer, correspondent, newspaperwoman, newspaperman, gazetteer, newswriter, reporter, pressman, newshound, newsman, newsperson",
    "grade": 3
  },
  {
    "id": 1581,
    "traditional": "紀念",
    "simplified": "纪念",
    "reading": "jìniàn",
    "meaning": "commemorate, mark",
    "grade": 3
  },
  {
    "id": 1582,
    "traditional": "技術",
    "simplified": "技术",
    "reading": "jìshù",
    "meaning": "mechanics, know-how, prowess, science, Minerva, superior skill, art, competence, technology, craft, craftsmanship, skill, education, technique, artistry, engineering, technological, equipment, technical",
    "grade": 3
  },
  {
    "id": 1583,
    "traditional": "繼續",
    "simplified": "继续",
    "reading": "jìxù",
    "meaning": "go, resume, keep_on, abide, come on, persist in, extend, run_on, run on, carry_on, stretch, sustain, pursue, keep going, continuance, get_along, get_on, remain, continue, keep_up, go ahead, keep up, come up, continuation, hold, carry, run, go_on, maintain, go on, bide, plow ahead, hold_on, piece, restart",
    "grade": 3
  },
  {
    "id": 1584,
    "traditional": "加工",
    "simplified": "加工",
    "reading": "jiā gōng",
    "meaning": "process",
    "grade": 3
  },
  {
    "id": 1585,
    "traditional": "加強",
    "simplified": "加强",
    "reading": "jiāqiáng",
    "meaning": "strengthen, augment, reinforce",
    "grade": 3
  },
  {
    "id": 1586,
    "traditional": "家具",
    "simplified": "家具",
    "reading": "jiāju",
    "meaning": "household goods, movable, furnishings, furniture, home furnishings, household stuff, implement, household furnishings, fitment",
    "grade": 3
  },
  {
    "id": 1587,
    "traditional": "加快",
    "simplified": "加快",
    "reading": "jiākuài",
    "meaning": "speed up, accelerate",
    "grade": 3
  },
  {
    "id": 1588,
    "traditional": "家鄉",
    "simplified": "家乡",
    "reading": "jiāxiāng",
    "meaning": "fatherland, country, home, country of origin, native place, mother country, hometown, native land, motherland, homeland",
    "grade": 3
  },
  {
    "id": 1589,
    "traditional": "紀錄",
    "simplified": "纪录",
    "reading": "jìlù",
    "meaning": "minutes, notes, record, record",
    "grade": 3
  },
  {
    "id": 1590,
    "traditional": "家屬",
    "simplified": "家属",
    "reading": "jiāshǔ",
    "meaning": "blood relative, kinswoman, kinsman, household, kinfolk, kin, dependent, kinsfolk, family members, family dependents, cognate, folk, sib, people, dependents, blood relation, family members/dependents",
    "grade": 3
  },
  {
    "id": 1591,
    "traditional": "價格",
    "simplified": "价格",
    "reading": "jiàgé",
    "meaning": "price",
    "grade": 3
  },
  {
    "id": 1592,
    "traditional": "價錢",
    "simplified": "价钱",
    "reading": "jiàqian",
    "meaning": "cost, monetary value, rate, price",
    "grade": 3
  },
  {
    "id": 1593,
    "traditional": "價值",
    "simplified": "价值",
    "reading": "jiàzhí",
    "meaning": "value, worth",
    "grade": 3
  },
  {
    "id": 1594,
    "traditional": "架",
    "simplified": "架",
    "reading": "jià",
    "meaning": "m.[general]",
    "grade": 3
  },
  {
    "id": 1595,
    "traditional": "堅持",
    "simplified": "坚持",
    "reading": "jiānchí",
    "meaning": "persist in, insist on",
    "grade": 3
  },
  {
    "id": 1596,
    "traditional": "堅強",
    "simplified": "坚强",
    "reading": "jiānqiáng",
    "meaning": "strong, firm, staunch",
    "grade": 3
  },
  {
    "id": 1597,
    "traditional": "簡單",
    "simplified": "简单",
    "reading": "jiǎndān",
    "meaning": "terse, casual, commonplace, simple, oversimplified, ordinary, uncomplicated",
    "grade": 3
  },
  {
    "id": 1598,
    "traditional": "簡直",
    "simplified": "简直",
    "reading": "jiǎnzhí",
    "meaning": "simply, really",
    "grade": 3
  },
  {
    "id": 1599,
    "traditional": "堅決",
    "simplified": "坚决",
    "reading": "jiānjué",
    "meaning": "firm, determined",
    "grade": 3
  },
  {
    "id": 1600,
    "traditional": "建",
    "simplified": "建",
    "reading": "jiàn",
    "meaning": "establishment, erection, build, proposal, propose, set up, establish, found, advocate, erect, construction, construct",
    "grade": 3
  },
  {
    "id": 1601,
    "traditional": "建成",
    "simplified": "建成",
    "reading": "jiànchéng",
    "meaning": "to establish, to build",
    "grade": 3
  },
  {
    "id": 1602,
    "traditional": "建立",
    "simplified": "建立",
    "reading": "jiànlì",
    "meaning": "establish, set up, found",
    "grade": 3
  },
  {
    "id": 1603,
    "traditional": "建設",
    "simplified": "建设",
    "reading": "jiànshè",
    "meaning": "construction",
    "grade": 3
  },
  {
    "id": 1604,
    "traditional": "將近",
    "simplified": "将近",
    "reading": "jiāngjìn",
    "meaning": "almost",
    "grade": 3
  },
  {
    "id": 1605,
    "traditional": "將來",
    "simplified": "将来",
    "reading": "jiānglái",
    "meaning": "in the future, future, the future, CL:個|个[ge4]",
    "grade": 3
  },
  {
    "id": 1606,
    "traditional": "交費",
    "simplified": "交费",
    "reading": "jiāofèi",
    "meaning": "Pay a fee",
    "grade": 3
  },
  {
    "id": 1607,
    "traditional": "建議",
    "simplified": "建议",
    "reading": "jiànyì",
    "meaning": "propose, suggest, recommend",
    "grade": 3
  },
  {
    "id": 1608,
    "traditional": "交警",
    "simplified": "交警",
    "reading": "jiāojǐng",
    "meaning": "traffic police, abbr. for 交通警察",
    "grade": 3
  },
  {
    "id": 1609,
    "traditional": "交流",
    "simplified": "交流",
    "reading": "jiāoliú",
    "meaning": "exchange, interflow, interchange",
    "grade": 3
  },
  {
    "id": 1610,
    "traditional": "交往",
    "simplified": "交往",
    "reading": "jiāowǎng",
    "meaning": "associate, contact",
    "grade": 3
  },
  {
    "id": 1611,
    "traditional": "交易",
    "simplified": "交易",
    "reading": "jiāoyì",
    "meaning": "deal, trade, transaction",
    "grade": 3
  },
  {
    "id": 1612,
    "traditional": "教材",
    "simplified": "教材",
    "reading": "jiàocái",
    "meaning": "textbook, teaching material",
    "grade": 3
  },
  {
    "id": 1613,
    "traditional": "教練",
    "simplified": "教练",
    "reading": "jiàoliàn",
    "meaning": "training, drillmaster, wise man, tutor, drilling, handler, coacher, coach, instructor, private instructor, trainer, mentor",
    "grade": 3
  },
  {
    "id": 1614,
    "traditional": "較",
    "simplified": "较",
    "reading": "jiào",
    "meaning": "rather, quite, relatively, more",
    "grade": 3
  },
  {
    "id": 1615,
    "traditional": "接待",
    "simplified": "接待",
    "reading": "jiēdài",
    "meaning": "receive (guests), serve (customers)",
    "grade": 3
  },
  {
    "id": 1616,
    "traditional": "結實",
    "simplified": "结实",
    "reading": "jiēshi",
    "meaning": "to bear fruit, rugged, sturdy, strong, durable, buff (physique)",
    "grade": 3
  },
  {
    "id": 1617,
    "traditional": "接近",
    "simplified": "接近",
    "reading": "jiējìn",
    "meaning": "approach, near, be close to",
    "grade": 3
  },
  {
    "id": 1618,
    "traditional": "結合",
    "simplified": "结合",
    "reading": "jiéhé",
    "meaning": "combine, unite, integrate, link, be joined in wedlock",
    "grade": 3
  },
  {
    "id": 1619,
    "traditional": "結婚",
    "simplified": "结婚",
    "reading": "jié hūn",
    "meaning": "middle-aisle, intermarry, mate, conjoin, get married, couple, married, espouse, pair, marry, unite, get_married, get hitched with, spouse, splice, hook up with, wed",
    "grade": 3
  },
  {
    "id": 1620,
    "traditional": "叫",
    "simplified": "叫",
    "reading": "jiào",
    "meaning": "to be called",
    "grade": 3
  },
  {
    "id": 1621,
    "traditional": "解決",
    "simplified": "解决",
    "reading": "jiějué",
    "meaning": "solve, resolve, settle, dispose of, finish off",
    "grade": 3
  },
  {
    "id": 1622,
    "traditional": "結束",
    "simplified": "结束",
    "reading": "jiéshù",
    "meaning": "end, close, conclude",
    "grade": 3
  },
  {
    "id": 1623,
    "traditional": "解開",
    "simplified": "解开",
    "reading": "jiě kāi",
    "meaning": "unknot, loose, uncouple, unbuckle, unyoke, reel off, disengage, unreel, unloosen, disentwine, detach, untie, unwreathe, unlock, ravel, disentangle, unbraid, unsnarl, unhook, unfasten, unwind, unroll, tease apart, loosen, unhitch, unlace, undone, tease, unwire, uncord, unwrap, unleash, unthread, undo, disassemble, ravel out, disconnect, unlink, sleave, untangle, unpack, puzzle_out, unbend, u",
    "grade": 3
  },
  {
    "id": 1624,
    "traditional": "節約",
    "simplified": "节约",
    "reading": "jiéyuē",
    "meaning": "economize, save",
    "grade": 3
  },
  {
    "id": 1625,
    "traditional": "金",
    "simplified": "金",
    "reading": "jīn",
    "meaning": "metals, gold, money, ancient metal percussion instruments",
    "grade": 3
  },
  {
    "id": 1626,
    "traditional": "金牌",
    "simplified": "金牌",
    "reading": "jīnpái",
    "meaning": "gold medal",
    "grade": 3
  },
  {
    "id": 1627,
    "traditional": "盡量",
    "simplified": "尽量",
    "reading": "jǐnliàng",
    "meaning": "to the best of one's ability",
    "grade": 3
  },
  {
    "id": 1628,
    "traditional": "僅",
    "simplified": "仅",
    "reading": "jǐn",
    "meaning": "scantily, simply, alone, merely, barely, only, but, just",
    "grade": 3
  },
  {
    "id": 1629,
    "traditional": "僅僅",
    "simplified": "仅仅",
    "reading": "jǐnjǐn",
    "meaning": "strictly, solely, exclusively, entirely, alone, merely, only, purely, simply, scantly, narrowly, barely, just",
    "grade": 3
  },
  {
    "id": 1630,
    "traditional": "緊張",
    "simplified": "紧张",
    "reading": "jǐnzhāng",
    "meaning": "be nervous",
    "grade": 3
  },
  {
    "id": 1631,
    "traditional": "緊",
    "simplified": "紧",
    "reading": "jǐn",
    "meaning": "tighten, tight, hard up, strict, urgent, tense, taut, stringent",
    "grade": 3
  },
  {
    "id": 1632,
    "traditional": "進步",
    "simplified": "进步",
    "reading": "jìnbù",
    "meaning": "progress, advance, improve",
    "grade": 3
  },
  {
    "id": 1633,
    "traditional": "進一步",
    "simplified": "进一步",
    "reading": "jìnyībù",
    "meaning": "go step further",
    "grade": 3
  },
  {
    "id": 1634,
    "traditional": "進展",
    "simplified": "进展",
    "reading": "jìnzhǎn",
    "meaning": "progress, advance",
    "grade": 3
  },
  {
    "id": 1635,
    "traditional": "近期",
    "simplified": "近期",
    "reading": "jìnqī",
    "meaning": "near in time, in the near future, very soon, recent",
    "grade": 3
  },
  {
    "id": 1636,
    "traditional": "經濟",
    "simplified": "经济",
    "reading": "jīngjì",
    "meaning": "economy, financial condition, income",
    "grade": 3
  },
  {
    "id": 1637,
    "traditional": "京劇",
    "simplified": "京剧",
    "reading": "jīngjù",
    "meaning": "Beijing opera, CL:場|场[chang3],出[chu1]",
    "grade": 3
  },
  {
    "id": 1638,
    "traditional": "經驗",
    "simplified": "经验",
    "reading": "jīngyàn",
    "meaning": "experience",
    "grade": 3
  },
  {
    "id": 1639,
    "traditional": "緊急",
    "simplified": "紧急",
    "reading": "jǐnjí",
    "meaning": "urgent, critical",
    "grade": 3
  },
  {
    "id": 1640,
    "traditional": "經歷",
    "simplified": "经历",
    "reading": "jīnglì",
    "meaning": "go through, undergo, experience",
    "grade": 3
  },
  {
    "id": 1641,
    "traditional": "精神",
    "simplified": "精神",
    "reading": "jīngshen",
    "meaning": "god, guts, heart, pneuma, consciousness, vigor, life, energy, vim, courage, esprit, moral, get-up-and-go, ginger, vitality, Psyche, psyche, spunk, gumption, juice, sand, mind, mental, mens, mickey, mettle, nature, grit, oomph, genius, vigour, substance, psychic, backbone, nous, nerve, pazazz, essence, pep, spirit, inward, drive, push, soul, gist",
    "grade": 3
  },
  {
    "id": 1642,
    "traditional": "精彩",
    "simplified": "精彩",
    "reading": "jīngcǎi",
    "meaning": "brilliant, splendid",
    "grade": 3
  },
  {
    "id": 1643,
    "traditional": "經營",
    "simplified": "经营",
    "reading": "jīngyíng",
    "meaning": "manage, run, engage in",
    "grade": 3
  },
  {
    "id": 1644,
    "traditional": "景色",
    "simplified": "景色",
    "reading": "jǐngsè",
    "meaning": "view, scenic, outlook, perspective, scene, scenery, prospect, landscape",
    "grade": 3
  },
  {
    "id": 1645,
    "traditional": "警察",
    "simplified": "警察",
    "reading": "jǐngchá",
    "meaning": "police officer, cop, trooper, bluecoat, finest, jack, peon, policeman, police force, constable, policemen, flattie, fuzz, constabulary, shamus, bull, bobby, robert, copper, tipstaff, rozzer, flatfoot, john, peeler, Vopo, law, runner, officer, gumshoe, pig, police, the police, beetle-crusher, flic",
    "grade": 3
  },
  {
    "id": 1646,
    "traditional": "靜",
    "simplified": "静",
    "reading": "jìng",
    "meaning": "still, quiet, calm",
    "grade": 3
  },
  {
    "id": 1647,
    "traditional": "久",
    "simplified": "久",
    "reading": "jiǔ",
    "meaning": "for long time, of specified duration",
    "grade": 3
  },
  {
    "id": 1648,
    "traditional": "就是",
    "simplified": "就是",
    "reading": "jiùshì",
    "meaning": "conj.: even if, even",
    "grade": 3
  },
  {
    "id": 1649,
    "traditional": "救",
    "simplified": "救",
    "reading": "jiù",
    "meaning": "relief, relieve, help, salve, salvage, rescue, save, succour",
    "grade": 3
  },
  {
    "id": 1650,
    "traditional": "就業",
    "simplified": "就业",
    "reading": "jiù yè",
    "meaning": "get or take up a job",
    "grade": 3
  },
  {
    "id": 1651,
    "traditional": "舉辦",
    "simplified": "举办",
    "reading": "jǔbàn",
    "meaning": "conduct, hold, run",
    "grade": 3
  },
  {
    "id": 1652,
    "traditional": "舊",
    "simplified": "旧",
    "reading": "jiù",
    "meaning": "old, aged, onetime, bygone, used, worn, past, former",
    "grade": 3
  },
  {
    "id": 1653,
    "traditional": "具有",
    "simplified": "具有",
    "reading": "jùyǒu",
    "meaning": "be provided with, have, have got, possess, hold, possession, carry, inhere, bear",
    "grade": 3
  },
  {
    "id": 1654,
    "traditional": "劇場",
    "simplified": "剧场",
    "reading": "jùchǎng",
    "meaning": "theater",
    "grade": 3
  },
  {
    "id": 1655,
    "traditional": "具體",
    "simplified": "具体",
    "reading": "jùtǐ",
    "meaning": "concrete, specific, particular",
    "grade": 3
  },
  {
    "id": 1656,
    "traditional": "決定",
    "simplified": "决定",
    "reading": "juédìng",
    "meaning": "decide, resolve, make up one's mind, determine",
    "grade": 3
  },
  {
    "id": 1657,
    "traditional": "據說",
    "simplified": "据说",
    "reading": "jùshuō",
    "meaning": "they say, it is said, it is said that..., reputedly, allegedly",
    "grade": 3
  },
  {
    "id": 1658,
    "traditional": "決賽",
    "simplified": "决赛",
    "reading": "juésài",
    "meaning": "runoff, fight-off, cup_final, playoff, cup final, match_game, finals, final, run-off",
    "grade": 3
  },
  {
    "id": 1659,
    "traditional": "絕對",
    "simplified": "绝对",
    "reading": "juéduì",
    "meaning": "absolutely, definitely",
    "grade": 3
  },
  {
    "id": 1660,
    "traditional": "咖啡",
    "simplified": "咖啡",
    "reading": "kāfēi",
    "meaning": "arabica, coffee, cafe, Java, java, mocha, blackstrap",
    "grade": 3
  },
  {
    "id": 1661,
    "traditional": "決心",
    "simplified": "决心",
    "reading": "juéxīn",
    "meaning": "determination, resolution",
    "grade": 3
  },
  {
    "id": 1662,
    "traditional": "開放",
    "simplified": "开放",
    "reading": "kāifàng",
    "meaning": "lift a ban/etc., be turned on, be in operation",
    "grade": 3
  },
  {
    "id": 1663,
    "traditional": "開發",
    "simplified": "开发",
    "reading": "kāifa",
    "meaning": "develop, open up, exploit",
    "grade": 3
  },
  {
    "id": 1664,
    "traditional": "開始",
    "simplified": "开始",
    "reading": "kāishǐ",
    "meaning": "begin, start",
    "grade": 3
  },
  {
    "id": 1665,
    "traditional": "開展",
    "simplified": "开展",
    "reading": "kāizhǎn",
    "meaning": "develop, launch, unfold",
    "grade": 3
  },
  {
    "id": 1666,
    "traditional": "開業",
    "simplified": "开业",
    "reading": "kāi yè",
    "meaning": "to open a business, to open a practice, open (for business)",
    "grade": 3
  },
  {
    "id": 1667,
    "traditional": "看起來",
    "simplified": "看起来",
    "reading": "kànqilai",
    "meaning": "it looks as if, on the face of it, it appears, it seems, ostensibly, seemingly, apparently, it look as if",
    "grade": 3
  },
  {
    "id": 1668,
    "traditional": "看上去",
    "simplified": "看上去",
    "reading": "kànshangqu",
    "meaning": "it would appear, it seems (that)",
    "grade": 3
  },
  {
    "id": 1669,
    "traditional": "考驗",
    "simplified": "考验",
    "reading": "kǎoyàn",
    "meaning": "test, trial",
    "grade": 3
  },
  {
    "id": 1670,
    "traditional": "可靠",
    "simplified": "可靠",
    "reading": "kěkào",
    "meaning": "reliable, dependable",
    "grade": 3
  },
  {
    "id": 1671,
    "traditional": "科技",
    "simplified": "科技",
    "reading": "kējì",
    "meaning": "technology, tech, engineering science, applied science, science and technology, technological, engineering",
    "grade": 3
  },
  {
    "id": 1672,
    "traditional": "客觀",
    "simplified": "客观",
    "reading": "kèguān",
    "meaning": "objective",
    "grade": 3
  },
  {
    "id": 1673,
    "traditional": "可樂",
    "simplified": "可乐",
    "reading": "kělè",
    "meaning": "amusing, entertaining, (loanword) cola",
    "grade": 3
  },
  {
    "id": 1674,
    "traditional": "克服",
    "simplified": "克服",
    "reading": "kèfú",
    "meaning": "surmount, conquer, put up with (hardship/etc.)",
    "grade": 3
  },
  {
    "id": 1675,
    "traditional": "空",
    "simplified": "空",
    "reading": "kōng",
    "meaning": "free, with spare time",
    "grade": 3
  },
  {
    "id": 1676,
    "traditional": "課程",
    "simplified": "课程",
    "reading": "kèchéng",
    "meaning": "course of instruction, lesson, course, course of lectures, course of study, curricular, class, curricula, syllabus, curriculum, program",
    "grade": 3
  },
  {
    "id": 1677,
    "traditional": "空調",
    "simplified": "空调",
    "reading": "kōngtiáo",
    "meaning": "air conditioner, air-conditioning, air-conditioner",
    "grade": 3
  },
  {
    "id": 1678,
    "traditional": "褲子",
    "simplified": "裤子",
    "reading": "kùzi",
    "meaning": "breeches, pant, unmentionables, britches, trouser, continuation, breeks, bags, unmentionable, inexpressible, pants, pantaloon, trousers",
    "grade": 3
  },
  {
    "id": 1679,
    "traditional": "空兒",
    "simplified": "空儿",
    "reading": "kòngr5",
    "meaning": "spare time, free time",
    "grade": 3
  },
  {
    "id": 1680,
    "traditional": "快速",
    "simplified": "快速",
    "reading": "kuàisù",
    "meaning": "expeditious, high-speed, fast, rapid, clip",
    "grade": 3
  },
  {
    "id": 1681,
    "traditional": "困",
    "simplified": "困",
    "reading": "kùn",
    "meaning": "sleepy, tired",
    "grade": 3
  },
  {
    "id": 1682,
    "traditional": "困難",
    "simplified": "困难",
    "reading": "kùnnan",
    "meaning": "difficult",
    "grade": 3
  },
  {
    "id": 1683,
    "traditional": "恐怕",
    "simplified": "恐怕",
    "reading": "kǒngpà",
    "meaning": "perhaps",
    "grade": 3
  },
  {
    "id": 1684,
    "traditional": "浪費",
    "simplified": "浪费",
    "reading": "làngfèi",
    "meaning": "waste, squander",
    "grade": 3
  },
  {
    "id": 1685,
    "traditional": "老百姓",
    "simplified": "老百姓",
    "reading": "lǎobǎixìng",
    "meaning": "plebe, populace, mister, civilians, roturier, ordinary people, common people",
    "grade": 3
  },
  {
    "id": 1686,
    "traditional": "老闆",
    "simplified": "老板",
    "reading": "lǎobǎn",
    "meaning": "proprietor, chief, shopkeeper, Tuan, baas, honcho, hirer, storekeeper, milord, bwana, mugwump, cit, market keeper, buckra, governor, boss, joss, tradesman, employer, guv",
    "grade": 3
  },
  {
    "id": 1687,
    "traditional": "老太太",
    "simplified": "老太太",
    "reading": "lǎotàitai",
    "meaning": "old lady, your (my/his/etc.) mother",
    "grade": 3
  },
  {
    "id": 1688,
    "traditional": "老頭兒",
    "simplified": "老头儿",
    "reading": "lǎotóur5",
    "meaning": "see 老頭子|老头子[lao3 tou2 zi5]",
    "grade": 3
  },
  {
    "id": 1689,
    "traditional": "樂",
    "simplified": "乐",
    "reading": "lè",
    "meaning": "happy, cheerful, be glad to, enjoy, be amused",
    "grade": 3
  },
  {
    "id": 1690,
    "traditional": "樂觀",
    "simplified": "乐观",
    "reading": "lèguān",
    "meaning": "be optimistic; be hopeful",
    "grade": 3
  },
  {
    "id": 1691,
    "traditional": "類似",
    "simplified": "类似",
    "reading": "lèisì",
    "meaning": "analogize, conform, analogic, be similar to, resemble, similar, analogous",
    "grade": 3
  },
  {
    "id": 1692,
    "traditional": "離婚",
    "simplified": "离婚",
    "reading": "lí hūn",
    "meaning": "divorce",
    "grade": 3
  },
  {
    "id": 1693,
    "traditional": "裡面",
    "simplified": "里面",
    "reading": "lǐmian",
    "meaning": "inside, interior",
    "grade": 3
  },
  {
    "id": 1694,
    "traditional": "類",
    "simplified": "类",
    "reading": "lèi",
    "meaning": "m.[kind]",
    "grade": 3
  },
  {
    "id": 1695,
    "traditional": "理髮",
    "simplified": "理发",
    "reading": "lǐ fà",
    "meaning": "a barber, hairdressing",
    "grade": 3
  },
  {
    "id": 1696,
    "traditional": "理論",
    "simplified": "理论",
    "reading": "lǐlùn",
    "meaning": "theory",
    "grade": 3
  },
  {
    "id": 1697,
    "traditional": "理由",
    "simplified": "理由",
    "reading": "lǐyóu",
    "meaning": "excuse, reasoning, reason, wherefore, argument, meaning, account, cause, room, alibi, substance, warranty, matter, justification, occasion, score, warrant, ground, sake, why, grounds, consideration, self-justification, skill",
    "grade": 3
  },
  {
    "id": 1698,
    "traditional": "理解",
    "simplified": "理解",
    "reading": "lǐjiě",
    "meaning": "understand, comprehend",
    "grade": 3
  },
  {
    "id": 1699,
    "traditional": "力量",
    "simplified": "力量",
    "reading": "lìliang",
    "meaning": "fibre, strength, potency, ability, guts, kick, cogency, point, dint, energy, mightiness, physical strength, demon, force, punch, whiz, tooth, might, forcefulness, exercise, fiber, powerfulness, lift, pith, leverage, agency, push, muscle, power",
    "grade": 3
  },
  {
    "id": 1700,
    "traditional": "力",
    "simplified": "力",
    "reading": "lì",
    "meaning": "power, strength, ability, force, physical strength",
    "grade": 3
  },
  {
    "id": 1701,
    "traditional": "利用",
    "simplified": "利用",
    "reading": "lìyòng",
    "meaning": "use, utilize, take advantage of, exploit",
    "grade": 3
  },
  {
    "id": 1702,
    "traditional": "連",
    "simplified": "连",
    "reading": "lián",
    "meaning": "conj.: including",
    "grade": 3
  },
  {
    "id": 1703,
    "traditional": "立刻",
    "simplified": "立刻",
    "reading": "lìkè",
    "meaning": "in a short time, at_once, promptly, offhand, at once, anon, instantly, straightway, now, slapbang, pronto, right_away, immediately, without delay, right away, forthwith, presto, at a time, before long, in a minute, swith, directly, in no time, incontinent, subito, without_delay, in real time, shortly, tout-de-suite, away, soon, presently, in a few seconds, very fast, chop-chop, at o",
    "grade": 3
  },
  {
    "id": 1704,
    "traditional": "連忙",
    "simplified": "连忙",
    "reading": "liánmáng",
    "meaning": "promptly, at once",
    "grade": 3
  },
  {
    "id": 1705,
    "traditional": "連續劇",
    "simplified": "连续剧",
    "reading": "liánxùjù",
    "meaning": "serialized drama, dramatic series, show in parts",
    "grade": 3
  },
  {
    "id": 1706,
    "traditional": "聯合國",
    "simplified": "联合国",
    "reading": "Liánhéguó",
    "meaning": "alignment, United Nations, United_Nations, alliance, confederation, coalition, confederate, alinement, UN",
    "grade": 3
  },
  {
    "id": 1707,
    "traditional": "連續",
    "simplified": "连续",
    "reading": "liánxù",
    "meaning": "continuously, successively",
    "grade": 3
  },
  {
    "id": 1708,
    "traditional": "聯繫",
    "simplified": "联系",
    "reading": "liánxì",
    "meaning": "integrate, relate, link, get in touch with",
    "grade": 3
  },
  {
    "id": 1709,
    "traditional": "聯合",
    "simplified": "联合",
    "reading": "liánhé",
    "meaning": "unite, ally",
    "grade": 3
  },
  {
    "id": 1710,
    "traditional": "涼水",
    "simplified": "凉水",
    "reading": "liángshuǐ",
    "meaning": "cool water, unboiled water",
    "grade": 3
  },
  {
    "id": 1711,
    "traditional": "了",
    "simplified": "了",
    "reading": "le",
    "meaning": "entirely",
    "grade": 3
  },
  {
    "id": 1712,
    "traditional": "領",
    "simplified": "领",
    "reading": "lǐng",
    "meaning": "receive, draw, get, have jurisdiction over, be in possession of",
    "grade": 3
  },
  {
    "id": 1713,
    "traditional": "領導",
    "simplified": "领导",
    "reading": "lǐngdǎo",
    "meaning": "lead, exercise leadership",
    "grade": 3
  },
  {
    "id": 1714,
    "traditional": "領先",
    "simplified": "领先",
    "reading": "lǐng xiān",
    "meaning": "lead, lead (in competition), precede, lead_up, be in lead, show, be in the lead, get the jump",
    "grade": 3
  },
  {
    "id": 1715,
    "traditional": "另一方面",
    "simplified": "另一方面",
    "reading": "lìngyīfāngmiàn",
    "meaning": "conj.: on the other hand",
    "grade": 3
  },
  {
    "id": 1716,
    "traditional": "留學",
    "simplified": "留学",
    "reading": "liú xué",
    "meaning": "study abroad",
    "grade": 3
  },
  {
    "id": 1717,
    "traditional": "龍",
    "simplified": "龙",
    "reading": "lóng",
    "meaning": "dragon, dinosaur",
    "grade": 3
  },
  {
    "id": 1718,
    "traditional": "另外",
    "simplified": "另外",
    "reading": "lìngwài",
    "meaning": "conj.: in addition, besides, moreover",
    "grade": 3
  },
  {
    "id": 1719,
    "traditional": "錄",
    "simplified": "录",
    "reading": "lù",
    "meaning": "surname Lu, diary, record, to hit, to copy",
    "grade": 3
  },
  {
    "id": 1720,
    "traditional": "路線",
    "simplified": "路线",
    "reading": "lùxiàn",
    "meaning": "track, path, cart track, way of life, itinerary, route, cartroad, course, road, channel, line, way, routeway, pipeline, curriculum",
    "grade": 3
  },
  {
    "id": 1721,
    "traditional": "旅館",
    "simplified": "旅馆",
    "reading": "lǚguǎn",
    "meaning": "gasthaus, caravanserai, nonresident, roadhouse, hotel, lodge, hostler, hostel, fonda, ordinary, inn, hostelry",
    "grade": 3
  },
  {
    "id": 1722,
    "traditional": "錄音",
    "simplified": "录音",
    "reading": "lùyīn",
    "meaning": "record sound",
    "grade": 3
  },
  {
    "id": 1723,
    "traditional": "旅行社",
    "simplified": "旅行社",
    "reading": "lǚxíngshè",
    "meaning": "a tourist agency, travel_agency, a travel agency, travel service/agent, travel service, travel agent, travel agency",
    "grade": 3
  },
  {
    "id": 1724,
    "traditional": "綠茶",
    "simplified": "绿茶",
    "reading": "lǜchá",
    "meaning": "green tea",
    "grade": 3
  },
  {
    "id": 1725,
    "traditional": "落後",
    "simplified": "落后",
    "reading": "luò hòu",
    "meaning": "fall/lay behind",
    "grade": 3
  },
  {
    "id": 1726,
    "traditional": "麻煩",
    "simplified": "麻烦",
    "reading": "máfan",
    "meaning": "trouble sb., bother",
    "grade": 3
  },
  {
    "id": 1727,
    "traditional": "滿足",
    "simplified": "满足",
    "reading": "mǎnzú",
    "meaning": "fulfill, fit, cope with, fill, be satisfied/contented, gloat, contented, arrive, be satisfied, satisfy, sate, gratify, serve, meet, appease, replete, supply, satisfied, fulfill, suffice, assuage, satiate, content, be contented, conform to, suit, match",
    "grade": 3
  },
  {
    "id": 1728,
    "traditional": "亂",
    "simplified": "乱",
    "reading": "luàn",
    "meaning": "indiscriminate, riot, mix up, arbitrary, confused, throw into diorder, confuse, jumble, confusion, random, mishandle, in a turmoil, in a confused state of mind, snarl, in disorder, disturb",
    "grade": 3
  },
  {
    "id": 1729,
    "traditional": "慢慢",
    "simplified": "慢慢",
    "reading": "mànmàn",
    "meaning": "slowly; gradually",
    "grade": 3
  },
  {
    "id": 1730,
    "traditional": "馬",
    "simplified": "马",
    "reading": "mǎ",
    "meaning": "horse",
    "grade": 3
  },
  {
    "id": 1731,
    "traditional": "毛",
    "simplified": "毛",
    "reading": "máo",
    "meaning": "Mao, wool, bristle, nap, pilus, down, feather, mold, mildew, Surname, hair, 1 1 0 th yuan, 1 0 th yuan, dime, silk, cent, fur, marking",
    "grade": 3
  },
  {
    "id": 1732,
    "traditional": "沒用",
    "simplified": "没用",
    "reading": "méiyòng",
    "meaning": "useless",
    "grade": 3
  },
  {
    "id": 1733,
    "traditional": "毛病",
    "simplified": "毛病",
    "reading": "máobing",
    "meaning": "trouble, mishap, breakdown, defect, shortcoming, fault, mistake, illness",
    "grade": 3
  },
  {
    "id": 1734,
    "traditional": "每",
    "simplified": "每",
    "reading": "měi",
    "meaning": "det.: every",
    "grade": 3
  },
  {
    "id": 1735,
    "traditional": "媒體",
    "simplified": "媒体",
    "reading": "méitǐ",
    "meaning": "mass media, media, medium",
    "grade": 3
  },
  {
    "id": 1736,
    "traditional": "美",
    "simplified": "美",
    "reading": "měi",
    "meaning": "beautiful, pretty",
    "grade": 3
  },
  {
    "id": 1737,
    "traditional": "美麗",
    "simplified": "美丽",
    "reading": "měilì",
    "meaning": "beautiful",
    "grade": 3
  },
  {
    "id": 1738,
    "traditional": "美好",
    "simplified": "美好",
    "reading": "měihǎo",
    "meaning": "fine, happy, glorious",
    "grade": 3
  },
  {
    "id": 1739,
    "traditional": "美術",
    "simplified": "美术",
    "reading": "měishù",
    "meaning": "constructivism, beaux arts, beaux-arts, artistic production, artistic creation, painting, fineart, fine_art, cubism, art, fine arts",
    "grade": 3
  },
  {
    "id": 1740,
    "traditional": "美元",
    "simplified": "美元",
    "reading": "měiyuán",
    "meaning": "m.[standard]",
    "grade": 3
  },
  {
    "id": 1741,
    "traditional": "米",
    "simplified": "米",
    "reading": "mǐ",
    "meaning": "rice, shelled/husked seed",
    "grade": 3
  },
  {
    "id": 1742,
    "traditional": "美食",
    "simplified": "美食",
    "reading": "měishí",
    "meaning": "cate, luxury, good food, delicious food, fleshpot",
    "grade": 3
  },
  {
    "id": 1743,
    "traditional": "面對",
    "simplified": "面对",
    "reading": "miànduì",
    "meaning": "face up, stand up, face, look_out_on, confront, front, affront, envisage, accost, breast",
    "grade": 3
  },
  {
    "id": 1744,
    "traditional": "迷",
    "simplified": "迷",
    "reading": "mí",
    "meaning": "to bewilder, crazy about, fan, enthusiast, lost, confused",
    "grade": 3
  },
  {
    "id": 1745,
    "traditional": "民間",
    "simplified": "民间",
    "reading": "mínjiān",
    "meaning": "grass roots, people-to-people, nongovernmental, among the people, folk, popular",
    "grade": 3
  },
  {
    "id": 1746,
    "traditional": "民族",
    "simplified": "民族",
    "reading": "mínzú",
    "meaning": "tribe, citizenry, ethnos, ethnic minority/group, race, nationality, group, Volk, nation, folk, people, ethnic group, ethnographer, family, ethnic minority",
    "grade": 3
  },
  {
    "id": 1747,
    "traditional": "面積",
    "simplified": "面积",
    "reading": "miànjī",
    "meaning": "area unit, area, superficies, superficial, acreage, expanse, surface_area, square_measure, surface area, content, space, square measure",
    "grade": 3
  },
  {
    "id": 1748,
    "traditional": "明確",
    "simplified": "明确",
    "reading": "míngquè",
    "meaning": "define, make clear/definite, make definite, definite, make clear",
    "grade": 3
  },
  {
    "id": 1749,
    "traditional": "命運",
    "simplified": "命运",
    "reading": "mìngyùn",
    "meaning": "foredoom, lot, appointment, lottery, fortune, chance, dole, circumstances, allotment, predestination, cup, luck, fare, destiny, portion, kismet, fate, doom, line, fatality, weird, hazard, karma",
    "grade": 3
  },
  {
    "id": 1750,
    "traditional": "明顯",
    "simplified": "明显",
    "reading": "míngxiǎn",
    "meaning": "clear, obvious",
    "grade": 3
  },
  {
    "id": 1751,
    "traditional": "某",
    "simplified": "某",
    "reading": "mǒu",
    "meaning": "det.: some, certain",
    "grade": 3
  },
  {
    "id": 1752,
    "traditional": "母親",
    "simplified": "母亲",
    "reading": "mǔqin",
    "meaning": "venter, materfamilias, mother, mither, sultana, maternal, female parent, mater",
    "grade": 3
  },
  {
    "id": 1753,
    "traditional": "目標",
    "simplified": "目标",
    "reading": "mùbiāo",
    "meaning": "polestar, objective, goal, bourn, object, meaning, aim, end point, target, cause, landmark, butt, lodestar, bull's eye, mark, tee, cynosure, end, bell ringer, loadstar, quaesitum, destination",
    "grade": 3
  },
  {
    "id": 1754,
    "traditional": "目前",
    "simplified": "目前",
    "reading": "mùqián",
    "meaning": "at the present time, currently",
    "grade": 3
  },
  {
    "id": 1755,
    "traditional": "木頭",
    "simplified": "木头",
    "reading": "mùtou",
    "meaning": "slow-witted, blockhead, log (of wood, timber etc), CL:塊|块[kuai4],根[gen1]",
    "grade": 3
  },
  {
    "id": 1756,
    "traditional": "男子",
    "simplified": "男子",
    "reading": "nánzǐ",
    "meaning": "man, male",
    "grade": 3
  },
  {
    "id": 1757,
    "traditional": "南部",
    "simplified": "南部",
    "reading": "nánbù",
    "meaning": "meridional, the southern part of the country, southern part, South, south, the south",
    "grade": 3
  },
  {
    "id": 1758,
    "traditional": "奶茶",
    "simplified": "奶茶",
    "reading": "nǎichá",
    "meaning": "milk tea",
    "grade": 3
  },
  {
    "id": 1759,
    "traditional": "難道",
    "simplified": "难道",
    "reading": "nándào",
    "meaning": "Do you really mean to say that...",
    "grade": 3
  },
  {
    "id": 1760,
    "traditional": "難度",
    "simplified": "难度",
    "reading": "nándù",
    "meaning": "trouble, problem",
    "grade": 3
  },
  {
    "id": 1761,
    "traditional": "內心",
    "simplified": "内心",
    "reading": "nèixīn",
    "meaning": "interior, inner being, conscience, inside, breast, bosom, innermost being, incenter, heart",
    "grade": 3
  },
  {
    "id": 1762,
    "traditional": "內",
    "simplified": "内",
    "reading": "nèi",
    "meaning": "inside",
    "grade": 3
  },
  {
    "id": 1763,
    "traditional": "內容",
    "simplified": "内容",
    "reading": "nèiróng",
    "meaning": "subject_matter, content, cognitive content, internal, contents, mental object, meat, substance, matter, lining",
    "grade": 3
  },
  {
    "id": 1764,
    "traditional": "能不能",
    "simplified": "能不能",
    "reading": "néng bùnéng",
    "meaning": "Can you",
    "grade": 3
  },
  {
    "id": 1765,
    "traditional": "年代",
    "simplified": "年代",
    "reading": "niándài",
    "meaning": "a decade of a century (e.g. the Sixties), age, era, period, CL:個|个[ge4]",
    "grade": 3
  },
  {
    "id": 1766,
    "traditional": "能力",
    "simplified": "能力",
    "reading": "nénglì",
    "meaning": "department, potency, ability, acquisition, acquirement, capacity, performance, oomph, sufficiency, energy, competence, accomplishment, size, might, faculty, hand, coequal, efficiency, inner resources, capability, giftie, attainment, capableness, zip, skill, competency, power",
    "grade": 3
  },
  {
    "id": 1767,
    "traditional": "年初",
    "simplified": "年初",
    "reading": "niánchū",
    "meaning": "beginning of year",
    "grade": 3
  },
  {
    "id": 1768,
    "traditional": "年紀",
    "simplified": "年纪",
    "reading": "niánjì",
    "meaning": "years, old age, age",
    "grade": 3
  },
  {
    "id": 1769,
    "traditional": "念",
    "simplified": "念",
    "reading": "niàn",
    "meaning": "study, read, attend school, think_of, read aloud, think of, miss",
    "grade": 3
  },
  {
    "id": 1770,
    "traditional": "年底",
    "simplified": "年底",
    "reading": "niándǐ",
    "meaning": "the end of the year, year-end",
    "grade": 3
  },
  {
    "id": 1771,
    "traditional": "農民",
    "simplified": "农民",
    "reading": "nóngmín",
    "meaning": "mujik, farmhand, granger, boor, farm worker, peasantry, countrymen, farmer, agriculture, husbandman, muzhik, peasant, fieldhand, moujik, fellah, muzjik",
    "grade": 3
  },
  {
    "id": 1772,
    "traditional": "牛",
    "simplified": "牛",
    "reading": "niú",
    "meaning": "ox, cattle, cow",
    "grade": 3
  },
  {
    "id": 1773,
    "traditional": "農村",
    "simplified": "农村",
    "reading": "nóngcūn",
    "meaning": "country, village, countryside, rural area",
    "grade": 3
  },
  {
    "id": 1774,
    "traditional": "農業",
    "simplified": "农业",
    "reading": "nóngyè",
    "meaning": "farm, farming, geoponics, husbandry, agricultural, plow, agriculture",
    "grade": 3
  },
  {
    "id": 1775,
    "traditional": "女子",
    "simplified": "女子",
    "reading": "nǚzǐ",
    "meaning": "lady, female, femme, young lady, adult female, missy, young woman, fem, moll, womankind, fille, damsel, girl, miss, woman, wren, blade",
    "grade": 3
  },
  {
    "id": 1776,
    "traditional": "暖和",
    "simplified": "暖和",
    "reading": "nuǎnhuo",
    "meaning": "warm, nice and warm",
    "grade": 3
  },
  {
    "id": 1777,
    "traditional": "怕",
    "simplified": "怕",
    "reading": "pà",
    "meaning": "I'm afraid, dread, funk, I suppose, be afraid of, fear",
    "grade": 3
  },
  {
    "id": 1778,
    "traditional": "拍",
    "simplified": "拍",
    "reading": "pāi",
    "meaning": "clap, pat, lick sb.'s boots",
    "grade": 3
  },
  {
    "id": 1779,
    "traditional": "排",
    "simplified": "排",
    "reading": "pái",
    "meaning": "stand in line, arrange, put in order, sequence",
    "grade": 3
  },
  {
    "id": 1780,
    "traditional": "排名",
    "simplified": "排名",
    "reading": "pái míng",
    "meaning": "put names in order",
    "grade": 3
  },
  {
    "id": 1781,
    "traditional": "牌子",
    "simplified": "牌子",
    "reading": "páizi",
    "meaning": "sign, trademark, brand",
    "grade": 3
  },
  {
    "id": 1782,
    "traditional": "派",
    "simplified": "派",
    "reading": "pài",
    "meaning": "send, dispatch, assign, appoint",
    "grade": 3
  },
  {
    "id": 1783,
    "traditional": "判斷",
    "simplified": "判断",
    "reading": "pànduàn",
    "meaning": "judge, determine",
    "grade": 3
  },
  {
    "id": 1784,
    "traditional": "配合",
    "simplified": "配合",
    "reading": "pèihe",
    "meaning": "coordinate, cooperate",
    "grade": 3
  },
  {
    "id": 1785,
    "traditional": "跑步",
    "simplified": "跑步",
    "reading": "pǎo bù",
    "meaning": "to run, to jog, (military) to march at the double",
    "grade": 3
  },
  {
    "id": 1786,
    "traditional": "胖",
    "simplified": "胖",
    "reading": "pàng",
    "meaning": "fat, stout, plump",
    "grade": 3
  },
  {
    "id": 1787,
    "traditional": "配",
    "simplified": "配",
    "reading": "pèi",
    "meaning": "match, find sth. to fit/replace sth. else, deserve, be worthy of, be qualified, compound, mix",
    "grade": 3
  },
  {
    "id": 1788,
    "traditional": "批評",
    "simplified": "批评",
    "reading": "pīpíng",
    "meaning": "criticize",
    "grade": 3
  },
  {
    "id": 1789,
    "traditional": "批准",
    "simplified": "批准",
    "reading": "pī zhǔn",
    "meaning": "to approve, to ratify",
    "grade": 3
  },
  {
    "id": 1790,
    "traditional": "皮",
    "simplified": "皮",
    "reading": "pí",
    "meaning": "skin, leather, hide, wrapper, surface, thin/flat pieces/sheets, rubber",
    "grade": 3
  },
  {
    "id": 1791,
    "traditional": "啤酒",
    "simplified": "啤酒",
    "reading": "píjiǔ",
    "meaning": "malt liquor, malt, suds, nappy, wallop, beery, malt_liquor, stout, barley-bree, brewage, beer",
    "grade": 3
  },
  {
    "id": 1792,
    "traditional": "票價",
    "simplified": "票价",
    "reading": "piàojià",
    "meaning": "ticket price, fare, admission fee",
    "grade": 3
  },
  {
    "id": 1793,
    "traditional": "皮包",
    "simplified": "皮包",
    "reading": "píbāo",
    "meaning": "handbag, briefcase",
    "grade": 3
  },
  {
    "id": 1794,
    "traditional": "評價",
    "simplified": "评价",
    "reading": "píngjià",
    "meaning": "estimate, diagnoses, appraisal, mark, rating, rate, value, assessment, esteem, image, estimation, valuation, appraisement, opinion, rank, remark, evaluation",
    "grade": 3
  },
  {
    "id": 1795,
    "traditional": "蘋果",
    "simplified": "苹果",
    "reading": "píngguǒ",
    "meaning": "mincemeat, pome, apple, Empire",
    "grade": 3
  },
  {
    "id": 1796,
    "traditional": "破壞",
    "simplified": "破坏",
    "reading": "pòhuài",
    "meaning": "violate, destroy",
    "grade": 3
  },
  {
    "id": 1797,
    "traditional": "普遍",
    "simplified": "普遍",
    "reading": "pǔbiàn",
    "meaning": "universal, general, widespread, common",
    "grade": 3
  },
  {
    "id": 1798,
    "traditional": "普及",
    "simplified": "普及",
    "reading": "pǔjí",
    "meaning": "popularize, disseminate, spread",
    "grade": 3
  },
  {
    "id": 1799,
    "traditional": "期",
    "simplified": "期",
    "reading": "qī",
    "meaning": "m.[event]",
    "grade": 3
  },
  {
    "id": 1800,
    "traditional": "破",
    "simplified": "破",
    "reading": "pò",
    "meaning": "tear, split up, defeat, expose, eradicate, lay bare, cleave, expose the truth of, worn-out, break with, come apart, destroy, capture (city/etc.), defeat (enemy), broken, expose (lie/etc.), destruction, cut, separate, tattered, reveal truth, fall apart, beat, capture, split, not intact, rupture, get rid of, do away with, break",
    "grade": 3
  },
  {
    "id": 1801,
    "traditional": "齊",
    "simplified": "齐",
    "reading": "qí",
    "meaning": "complete, prepared, together",
    "grade": 3
  },
  {
    "id": 1802,
    "traditional": "其實",
    "simplified": "其实",
    "reading": "qíshí",
    "meaning": "as a matter of fact, actually, in fact",
    "grade": 3
  },
  {
    "id": 1803,
    "traditional": "其次",
    "simplified": "其次",
    "reading": "qícì",
    "meaning": "conj.: next, second",
    "grade": 3
  },
  {
    "id": 1804,
    "traditional": "氣候",
    "simplified": "气候",
    "reading": "qìhòu",
    "meaning": "situation, clime, climatic, climate, weather, climactic",
    "grade": 3
  },
  {
    "id": 1805,
    "traditional": "千萬",
    "simplified": "千万",
    "reading": "qiānwàn",
    "meaning": "by all means; absolutely",
    "grade": 3
  },
  {
    "id": 1806,
    "traditional": "前後",
    "simplified": "前后",
    "reading": "qiánhòu",
    "meaning": "front and rear, around or about, altogether",
    "grade": 3
  },
  {
    "id": 1807,
    "traditional": "奇怪",
    "simplified": "奇怪",
    "reading": "qíguài",
    "meaning": "wonder",
    "grade": 3
  },
  {
    "id": 1808,
    "traditional": "前進",
    "simplified": "前进",
    "reading": "qiánjìn",
    "meaning": "go forward",
    "grade": 3
  },
  {
    "id": 1809,
    "traditional": "前往",
    "simplified": "前往",
    "reading": "qiánwǎng",
    "meaning": "depart, cruise, go_to, go, leave, go to, proceed to, pay, seek, leave for",
    "grade": 3
  },
  {
    "id": 1810,
    "traditional": "前面",
    "simplified": "前面",
    "reading": "qiánmian",
    "meaning": "front end, face, forepart, front, in front, ahead, preceding, above, fore, forgoing, frontage, obverse",
    "grade": 3
  },
  {
    "id": 1811,
    "traditional": "強",
    "simplified": "强",
    "reading": "qiáng",
    "meaning": "strong, powerful, vigorous, better, slightly more than, plus",
    "grade": 3
  },
  {
    "id": 1812,
    "traditional": "強大",
    "simplified": "强大",
    "reading": "qiángdà",
    "meaning": "big and powerful, formidable",
    "grade": 3
  },
  {
    "id": 1813,
    "traditional": "強調",
    "simplified": "强调",
    "reading": "qiángdiào",
    "meaning": "underscore, spotlight, place emphasis on, point_up, highlight, point, point up, enforce, emphasise, urge, underline, overstress, give emphasis to, play up, keynote, insist, punctuate, accent, betone, foreground, emphasize, emphatic, stress, set off, overemphasize, accentuate, bring out",
    "grade": 3
  },
  {
    "id": 1814,
    "traditional": "強烈",
    "simplified": "强烈",
    "reading": "qiángliè",
    "meaning": "strong, intense, violent",
    "grade": 3
  },
  {
    "id": 1815,
    "traditional": "巧",
    "simplified": "巧",
    "reading": "qiǎo",
    "meaning": "clever, intelligent, skillful, ingenious, artful, deceiving, pretty, cute, opportune, fortuitous, coincidental",
    "grade": 3
  },
  {
    "id": 1816,
    "traditional": "橋",
    "simplified": "桥",
    "reading": "qiáo",
    "meaning": "backbend, fret, span, pons, pier arch, bridge, spar",
    "grade": 3
  },
  {
    "id": 1817,
    "traditional": "親切",
    "simplified": "亲切",
    "reading": "qīnqiè",
    "meaning": "cordial, genial, warm",
    "grade": 3
  },
  {
    "id": 1818,
    "traditional": "親人",
    "simplified": "亲人",
    "reading": "qīnrén",
    "meaning": "those dear to one, one's parents, spouse, children, etc., one's family members, dear ones, relative, close relatives, folks",
    "grade": 3
  },
  {
    "id": 1819,
    "traditional": "親",
    "simplified": "亲",
    "reading": "qīn",
    "meaning": "parent, one's own (flesh and blood), relative, related, marriage, bride, close, intimate, in person, first-hand, in favor of, pro-, to kiss, (Internet slang) dear, parents-in-law of one's offspring",
    "grade": 3
  },
  {
    "id": 1820,
    "traditional": "情感",
    "simplified": "情感",
    "reading": "qínggǎn",
    "meaning": "affect, friendship, sensibility, bond, emotion, bosom, breast, sentiment, emotional, chasm, feeling, feelings",
    "grade": 3
  },
  {
    "id": 1821,
    "traditional": "請教",
    "simplified": "请教",
    "reading": "qǐngjiào",
    "meaning": "consultation, Please enlighten me., seek advice, take counsel, consult, ask, ask for advice",
    "grade": 3
  },
  {
    "id": 1822,
    "traditional": "情況",
    "simplified": "情况",
    "reading": "qíngkuàng",
    "meaning": "event, things, state of affairs, circumstantiality, case, circumstances, way, plight, experimental condition, streak, military situation, matter, condition, mood, circumstance, context, occasion, thing, instance, affair, shape, situation, juncture, consideration",
    "grade": 3
  },
  {
    "id": 1823,
    "traditional": "親自",
    "simplified": "亲自",
    "reading": "qīnzì",
    "meaning": "personally, in person, firsthand",
    "grade": 3
  },
  {
    "id": 1824,
    "traditional": "球迷",
    "simplified": "球迷",
    "reading": "qiúmí",
    "meaning": "fan (ball sports), CL:個|个[ge4]",
    "grade": 3
  },
  {
    "id": 1825,
    "traditional": "取消",
    "simplified": "取消",
    "reading": "qǔxiāo",
    "meaning": "cancel, abolish, nullify",
    "grade": 3
  },
  {
    "id": 1826,
    "traditional": "慶祝",
    "simplified": "庆祝",
    "reading": "qìngzhù",
    "meaning": "fete, celebration, keep, festive, commemorate, celebrate, observe, rejoice, felicitate",
    "grade": 3
  },
  {
    "id": 1827,
    "traditional": "區",
    "simplified": "区",
    "reading": "qū",
    "meaning": "surname Ou, area, region, district, small, distinguish, CL:個|个[ge4]",
    "grade": 3
  },
  {
    "id": 1828,
    "traditional": "去世",
    "simplified": "去世",
    "reading": "qùshì",
    "meaning": "die, pass away",
    "grade": 3
  },
  {
    "id": 1829,
    "traditional": "全場",
    "simplified": "全场",
    "reading": "quánchǎng",
    "meaning": "everyone present, the whole audience, across-the-board, unanimously, whole duration (of a competition or match)",
    "grade": 3
  },
  {
    "id": 1830,
    "traditional": "全面",
    "simplified": "全面",
    "reading": "quánmiàn",
    "meaning": "overall, comprehensive, all-round, all-out",
    "grade": 3
  },
  {
    "id": 1831,
    "traditional": "全球",
    "simplified": "全球",
    "reading": "quánqiú",
    "meaning": "global, whole world",
    "grade": 3
  },
  {
    "id": 1832,
    "traditional": "區別",
    "simplified": "区别",
    "reading": "qūbié",
    "meaning": "difference, to distinguish, to discriminate, to make a distinction, CL:個|个[ge4]",
    "grade": 3
  },
  {
    "id": 1833,
    "traditional": "缺少",
    "simplified": "缺少",
    "reading": "quēshǎo",
    "meaning": "lack, be short of",
    "grade": 3
  },
  {
    "id": 1834,
    "traditional": "缺點",
    "simplified": "缺点",
    "reading": "quēdiǎn",
    "meaning": "privilege, bug, failing, flaw, demerit, blemish, debit, limitation, mar, defect, glitch, foible, infirmity, blot, vice, drawback, disadvantage, imperfection, blemishment, weakness, tache, defection, shortcoming, malady, frailty, fault",
    "grade": 3
  },
  {
    "id": 1835,
    "traditional": "缺",
    "simplified": "缺",
    "reading": "quē",
    "meaning": "be short of, lack",
    "grade": 3
  },
  {
    "id": 1836,
    "traditional": "確保",
    "simplified": "确保",
    "reading": "quèbǎo",
    "meaning": "secure, insure, ensure, assure, reassure, guarantee",
    "grade": 3
  },
  {
    "id": 1837,
    "traditional": "確定",
    "simplified": "确定",
    "reading": "quèdìng",
    "meaning": "find, determination, make a point, pin down, narrow down, corroborate, prove, jell, settle, express, ensure, clench, assure, confirm, decide firmly, see, certain, define, clinch, nail_down, ascertain, determinate, guarantee, nail down, insure, fix, nail, peg, sustain, state, stipulate, secure, make_sure, seal, make sure, verify, find out, affirm, determine",
    "grade": 3
  },
  {
    "id": 1838,
    "traditional": "群",
    "simplified": "群",
    "reading": "qún",
    "meaning": "m.[general]",
    "grade": 3
  },
  {
    "id": 1839,
    "traditional": "確實",
    "simplified": "确实",
    "reading": "quèshí",
    "meaning": "really, certainly, truly, indeed",
    "grade": 3
  },
  {
    "id": 1840,
    "traditional": "裙子",
    "simplified": "裙子",
    "reading": "qúnzi",
    "meaning": "skirt, CL:條|条[tiao2]",
    "grade": 3
  },
  {
    "id": 1841,
    "traditional": "熱烈",
    "simplified": "热烈",
    "reading": "rèliè",
    "meaning": "ardent",
    "grade": 3
  },
  {
    "id": 1842,
    "traditional": "熱愛",
    "simplified": "热爱",
    "reading": "rèài",
    "meaning": "love ardently",
    "grade": 3
  },
  {
    "id": 1843,
    "traditional": "人才",
    "simplified": "人才",
    "reading": "réncái",
    "meaning": "talent, capable person, a talented person, qualified personnel, a person of ability",
    "grade": 3
  },
  {
    "id": 1844,
    "traditional": "人類",
    "simplified": "人类",
    "reading": "rénlèi",
    "meaning": "man, wight, human_being, human, human race, mankind, individual, human species, Homo_sapiens, populace, creature, hominid, world, mortality, microcosm, humans, humanitarianism, humanity, humanness, human beings/species, people, species, humankind, human beings, public, mortal",
    "grade": 3
  },
  {
    "id": 1845,
    "traditional": "人工",
    "simplified": "人工",
    "reading": "réngōng",
    "meaning": "man-made, artificiality, manual work, manpower, art, labor",
    "grade": 3
  },
  {
    "id": 1846,
    "traditional": "人民",
    "simplified": "人民",
    "reading": "rénmín",
    "meaning": "multitude, citizen, quarter, Volk, hoi polloi, subject, people, nationals, masses, the people, mass, demotic",
    "grade": 3
  },
  {
    "id": 1847,
    "traditional": "人民幣",
    "simplified": "人民币",
    "reading": "rénmínbì",
    "meaning": "RMB, PRC currency",
    "grade": 3
  },
  {
    "id": 1848,
    "traditional": "人群",
    "simplified": "人群",
    "reading": "rénqún",
    "meaning": "multitude, scrooge, herd, ruck, scrouge, meiny, crowd, troop, confluence, drove, throng, flock, gang",
    "grade": 3
  },
  {
    "id": 1849,
    "traditional": "人生",
    "simplified": "人生",
    "reading": "rénshēng",
    "meaning": "lifetime, life, lifespan, human life, time of life",
    "grade": 3
  },
  {
    "id": 1850,
    "traditional": "人員",
    "simplified": "人员",
    "reading": "rényuán",
    "meaning": "strength, personnel, force, staff",
    "grade": 3
  },
  {
    "id": 1851,
    "traditional": "認得",
    "simplified": "认得",
    "reading": "rèn de",
    "meaning": "to recognize, to remember sth (or sb) on seeing it, to know",
    "grade": 3
  },
  {
    "id": 1852,
    "traditional": "認出",
    "simplified": "认出",
    "reading": "rèn chū",
    "meaning": "recognition, to recognize",
    "grade": 3
  },
  {
    "id": 1853,
    "traditional": "認可",
    "simplified": "认可",
    "reading": "rènkě",
    "meaning": "to approve, approval, acknowledgment, OK",
    "grade": 3
  },
  {
    "id": 1854,
    "traditional": "任",
    "simplified": "任",
    "reading": "rèn",
    "meaning": "serve in a position, appoint to a position",
    "grade": 3
  },
  {
    "id": 1855,
    "traditional": "任何",
    "simplified": "任何",
    "reading": "rènhé",
    "meaning": "aught, det.: any",
    "grade": 3
  },
  {
    "id": 1856,
    "traditional": "仍然",
    "simplified": "仍然",
    "reading": "réngrán",
    "meaning": "still, yet",
    "grade": 3
  },
  {
    "id": 1857,
    "traditional": "仍",
    "simplified": "仍",
    "reading": "réng",
    "meaning": "yet, anyway, still",
    "grade": 3
  },
  {
    "id": 1858,
    "traditional": "任務",
    "simplified": "任务",
    "reading": "rènwu",
    "meaning": "imperative, assignment, cue, duty assignment, task, service, chore, labor, errand, job, lookout, undertaking, mission, military mission, duty, project, role",
    "grade": 3
  },
  {
    "id": 1859,
    "traditional": "日常",
    "simplified": "日常",
    "reading": "rìcháng",
    "meaning": "daily, everyday",
    "grade": 3
  },
  {
    "id": 1860,
    "traditional": "如何",
    "simplified": "如何",
    "reading": "rúhé",
    "meaning": "how",
    "grade": 3
  },
  {
    "id": 1861,
    "traditional": "容易",
    "simplified": "容易",
    "reading": "róngyì",
    "meaning": "easy",
    "grade": 3
  },
  {
    "id": 1862,
    "traditional": "沙發",
    "simplified": "沙发",
    "reading": "shāfā",
    "meaning": "lounge, settee, sofa",
    "grade": 3
  },
  {
    "id": 1863,
    "traditional": "散步",
    "simplified": "散步",
    "reading": "sàn bù",
    "meaning": "take a walk",
    "grade": 3
  },
  {
    "id": 1864,
    "traditional": "傷心",
    "simplified": "伤心",
    "reading": "shāng xīn",
    "meaning": "injure, offend, broken-hearted, be sad, pierce, hurt, grieved, sad, spite, aggrieve, grieve, be grieved, sadden, smart, wound",
    "grade": 3
  },
  {
    "id": 1865,
    "traditional": "傷",
    "simplified": "伤",
    "reading": "shāng",
    "meaning": "injure, wound, fall ill from, damage, harm",
    "grade": 3
  },
  {
    "id": 1866,
    "traditional": "商業",
    "simplified": "商业",
    "reading": "shāngyè",
    "meaning": "business enterprise, commercial, people, mercantilism, commercial pursuit, commercial enterprise, business sector, merchantry, trade, mercantile pursuit, business, commerce",
    "grade": 3
  },
  {
    "id": 1867,
    "traditional": "商品",
    "simplified": "商品",
    "reading": "shāngpǐn",
    "meaning": "article, product, merchandise, commodity, wares, ware, goods",
    "grade": 3
  },
  {
    "id": 1868,
    "traditional": "上面",
    "simplified": "上面",
    "reading": "shàngmian",
    "meaning": "surface of, aspect, higher authority, upper side, the higher authorities, on top/surface of, on surface of, top, the higher-ups, top side, upside, on top of, respect, above, on top, regard",
    "grade": 3
  },
  {
    "id": 1869,
    "traditional": "上去",
    "simplified": "上去",
    "reading": "shàng qu",
    "meaning": "to go up",
    "grade": 3
  },
  {
    "id": 1870,
    "traditional": "上來",
    "simplified": "上来",
    "reading": "shàng lai",
    "meaning": "come_up, come up",
    "grade": 3
  },
  {
    "id": 1871,
    "traditional": "上升",
    "simplified": "上升",
    "reading": "shàngshēng",
    "meaning": "rise",
    "grade": 3
  },
  {
    "id": 1872,
    "traditional": "沙子",
    "simplified": "沙子",
    "reading": "shāzi",
    "meaning": "sand, grit, CL:粒[li4],把[ba3]",
    "grade": 3
  },
  {
    "id": 1873,
    "traditional": "上衣",
    "simplified": "上衣",
    "reading": "shàngyī",
    "meaning": "jacket, upper outer garment, CL:件[jian4]",
    "grade": 3
  },
  {
    "id": 1874,
    "traditional": "設計",
    "simplified": "设计",
    "reading": "shèjì",
    "meaning": "design, plan",
    "grade": 3
  },
  {
    "id": 1875,
    "traditional": "設備",
    "simplified": "设备",
    "reading": "shèbèi",
    "meaning": "installation, set, device, appointment, plant, fixing, system, appliance, fixture, fitting, accommodation, fitment, finishing, unit, furnishings, materiel, contrivance, paraphernalia, facilities, rig, equipage, setout, facility, fixings, equipment",
    "grade": 3
  },
  {
    "id": 1876,
    "traditional": "設立",
    "simplified": "设立",
    "reading": "shèlì",
    "meaning": "establish, found",
    "grade": 3
  },
  {
    "id": 1877,
    "traditional": "身份證",
    "simplified": "身份证",
    "reading": "shēnfènzhèng",
    "meaning": "identity card, ID",
    "grade": 3
  },
  {
    "id": 1878,
    "traditional": "社會",
    "simplified": "社会",
    "reading": "shèhuì",
    "meaning": "community, society, monde, social, public",
    "grade": 3
  },
  {
    "id": 1879,
    "traditional": "深刻",
    "simplified": "深刻",
    "reading": "shēnkè",
    "meaning": "deep, profound",
    "grade": 3
  },
  {
    "id": 1880,
    "traditional": "深",
    "simplified": "深",
    "reading": "shēn",
    "meaning": "deepen, penetrating, profound, deep, dark",
    "grade": 3
  },
  {
    "id": 1881,
    "traditional": "深入",
    "simplified": "深入",
    "reading": "shēnrù",
    "meaning": "thorough, deep-going",
    "grade": 3
  },
  {
    "id": 1882,
    "traditional": "生產",
    "simplified": "生产",
    "reading": "shēngchǎn",
    "meaning": "produce, manufacture",
    "grade": 3
  },
  {
    "id": 1883,
    "traditional": "生存",
    "simplified": "生存",
    "reading": "shēngcún",
    "meaning": "live, exist",
    "grade": 3
  },
  {
    "id": 1884,
    "traditional": "升",
    "simplified": "升",
    "reading": "shēng",
    "meaning": "litre, raise variant of 升[sheng1]",
    "grade": 3
  },
  {
    "id": 1885,
    "traditional": "生動",
    "simplified": "生动",
    "reading": "shēngdòng",
    "meaning": "lively, moving, vivid, lifelike",
    "grade": 3
  },
  {
    "id": 1886,
    "traditional": "生命",
    "simplified": "生命",
    "reading": "shēngmìng",
    "meaning": "breath, god, head, anima, days, organism, blood, life, living thing, skin, longevity, being, vital, life form",
    "grade": 3
  },
  {
    "id": 1887,
    "traditional": "生意",
    "simplified": "生意",
    "reading": "shēngyi",
    "meaning": "business enterprise, vocation, practice, merchant, dealing, patronage, deal, commercial enterprise, trade, biz, business, dealings, racket",
    "grade": 3
  },
  {
    "id": 1888,
    "traditional": "生長",
    "simplified": "生长",
    "reading": "shēngzhǎng",
    "meaning": "grow, develop",
    "grade": 3
  },
  {
    "id": 1889,
    "traditional": "生",
    "simplified": "生",
    "reading": "shēng",
    "meaning": "give birth to, bear, grow, get, have, cause to happen, make (a fire), exist, live",
    "grade": 3
  },
  {
    "id": 1890,
    "traditional": "聲明",
    "simplified": "声明",
    "reading": "shēngmíng",
    "meaning": "statement, pronouncement",
    "grade": 3
  },
  {
    "id": 1891,
    "traditional": "勝",
    "simplified": "胜",
    "reading": "shèng",
    "meaning": "be superior to, surpass, succeed, win victory, be victorious, get the better of, can bear, carry, excel, be successful, win",
    "grade": 3
  },
  {
    "id": 1892,
    "traditional": "勝利",
    "simplified": "胜利",
    "reading": "shènglì",
    "meaning": "win victory/success",
    "grade": 3
  },
  {
    "id": 1893,
    "traditional": "石頭",
    "simplified": "石头",
    "reading": "shítou",
    "meaning": "stony, chimney, rock, lapis, stone",
    "grade": 3
  },
  {
    "id": 1894,
    "traditional": "石油",
    "simplified": "石油",
    "reading": "shíyóu",
    "meaning": "crude, rock oil, crude oil, oil, coal oil, fossil_oil, fossil oil, petroleum",
    "grade": 3
  },
  {
    "id": 1895,
    "traditional": "時",
    "simplified": "时",
    "reading": "shí",
    "meaning": "time (when), period, hour",
    "grade": 3
  },
  {
    "id": 1896,
    "traditional": "失去",
    "simplified": "失去",
    "reading": "shīqù",
    "meaning": "desert, shear, outgrow, loss, lost, miss, lose",
    "grade": 3
  },
  {
    "id": 1897,
    "traditional": "時代",
    "simplified": "时代",
    "reading": "shídài",
    "meaning": "day, period, a period in one's life, epoch, present time, historical period, time, chapter, period in one's life, modern times, historic period, contemporary world, times, era, modern world, age",
    "grade": 3
  },
  {
    "id": 1898,
    "traditional": "實際上",
    "simplified": "实际上",
    "reading": "shíjìshang",
    "meaning": "in fact, in reality, as a matter of fact, in practice",
    "grade": 3
  },
  {
    "id": 1899,
    "traditional": "實行",
    "simplified": "实行",
    "reading": "shíxíng",
    "meaning": "put into effect, fulfil, put ... into practice, practise, practice, put into practice/effect, live, pass, obtain, prosecute, cause, phase, meet, carry_out, pull, effect, operate, carry out, actualize, use, effectuate, put ... into effect, perform, apply, put into practice, run, implement, execute, do, make, wage, implementation",
    "grade": 3
  },
  {
    "id": 1900,
    "traditional": "實力",
    "simplified": "实力",
    "reading": "shílì",
    "meaning": "strength, actual strength, efficiency",
    "grade": 3
  },
  {
    "id": 1901,
    "traditional": "時刻",
    "simplified": "时刻",
    "reading": "shíkè",
    "meaning": "moment, occasion, juncture, sand, hour, time",
    "grade": 3
  },
  {
    "id": 1902,
    "traditional": "實驗室",
    "simplified": "实验室",
    "reading": "shíyànshì",
    "meaning": "lab, science lab, laboratory",
    "grade": 3
  },
  {
    "id": 1903,
    "traditional": "實驗",
    "simplified": "实验",
    "reading": "shíyàn",
    "meaning": "experiment, test",
    "grade": 3
  },
  {
    "id": 1904,
    "traditional": "食品",
    "simplified": "食品",
    "reading": "shípǐn",
    "meaning": "menu, vivers, viand, chow, eatable, provisions, tuck, foodstuff, viands, nourishment, comestible, tack, nutrient, victual, food, grocery, victualage, cuisine",
    "grade": 3
  },
  {
    "id": 1905,
    "traditional": "始終",
    "simplified": "始终",
    "reading": "shǐzhōng",
    "meaning": "all_the_time, from beginning to end, from start to finish, always, all along, evermore, throughout",
    "grade": 3
  },
  {
    "id": 1906,
    "traditional": "使",
    "simplified": "使",
    "reading": "shǐ",
    "meaning": "rarefy, piss_off, germinate, drench, blow_out, leave, cut_up, refract, run, dress_up, compose, shed, frizz, put_off, trot, send, strike, send (as envoy), peal, alienate, pull_in, tone_up, bring around, astound, sublease, wither, cripple, bed_down, address, rehearse, usage, warm, smell_out, throw, inflect, get_down, skunk, capsize, congeal, bowl_over, bring_on, unseat, ensure, amalgamate, pro",
    "grade": 3
  },
  {
    "id": 1907,
    "traditional": "世紀",
    "simplified": "世纪",
    "reading": "shìjì",
    "meaning": "one C, centred, C, century, age, hundred, 100",
    "grade": 3
  },
  {
    "id": 1908,
    "traditional": "世界",
    "simplified": "世界",
    "reading": "shìjiè",
    "meaning": "mundane, creation, world, monde, orb, nature, domain",
    "grade": 3
  },
  {
    "id": 1909,
    "traditional": "市場",
    "simplified": "市场",
    "reading": "shìchǎng",
    "meaning": "bourse, bazar, marketplace, grocery store, agora, bazaar, securities industry, grocery, piazza, plaza, rialto, mart, pitch, market",
    "grade": 3
  },
  {
    "id": 1910,
    "traditional": "世界杯",
    "simplified": "世界杯",
    "reading": "shìjièbēi",
    "meaning": "World Cup",
    "grade": 3
  },
  {
    "id": 1911,
    "traditional": "事故",
    "simplified": "事故",
    "reading": "shìgù",
    "meaning": "circumstance, trouble, mischance, accident, mishap, affair, bad luck, natural event, happening, occurrence",
    "grade": 3
  },
  {
    "id": 1912,
    "traditional": "事件",
    "simplified": "事件",
    "reading": "shìjiàn",
    "meaning": "fact, event, page, incidental, go, case, job, natural event, timing, proceedings, social occasion, happening, occurrence, matter, occasion, incident, shebang, affair, chapter, concernment",
    "grade": 3
  },
  {
    "id": 1913,
    "traditional": "事實",
    "simplified": "事实",
    "reading": "shìshí",
    "meaning": "mood, circumstance, fact, factual, reality, verity, case, deed, sooth, troth, actuality, truth, matter, matter of fact",
    "grade": 3
  },
  {
    "id": 1914,
    "traditional": "事實上",
    "simplified": "事实上",
    "reading": "shìshíshang",
    "meaning": "in fact, in reality, actually, as a matter of fact, de facto, ipso facto",
    "grade": 3
  },
  {
    "id": 1915,
    "traditional": "事業",
    "simplified": "事业",
    "reading": "shìyè",
    "meaning": "proposition, task, pidgin, institution, undertaking, deed, facilities, life history, endeavour, endeavor, cause, career, project, action, enterprise",
    "grade": 3
  },
  {
    "id": 1916,
    "traditional": "試驗",
    "simplified": "试验",
    "reading": "shìyàn",
    "meaning": "test, experiment",
    "grade": 3
  },
  {
    "id": 1917,
    "traditional": "試題",
    "simplified": "试题",
    "reading": "shìtí",
    "meaning": "exam question, test topic",
    "grade": 3
  },
  {
    "id": 1918,
    "traditional": "適合",
    "simplified": "适合",
    "reading": "shìhé",
    "meaning": "qualify, conform, fit, go, befit, quadrate, will, accord, tailor, beseem, serve, comport, gee, answer, become, fit_in, service, pertain, like, accommodate, agree, belong, characterize, make, suit, match",
    "grade": 3
  },
  {
    "id": 1919,
    "traditional": "適用",
    "simplified": "适用",
    "reading": "shìyòng",
    "meaning": "applicable, lend oneself, apply, be applicable, suit",
    "grade": 3
  },
  {
    "id": 1920,
    "traditional": "適應",
    "simplified": "适应",
    "reading": "shìyìng",
    "meaning": "be accustomed to, conform, be used to, fit, humor, gear, orient, phase, adjust, accommodate, be accustomed, be inure to, get used to, adaptation, adapt, suit",
    "grade": 3
  },
  {
    "id": 1921,
    "traditional": "收費",
    "simplified": "收费",
    "reading": "shōu fèi",
    "meaning": "collect fees, charge",
    "grade": 3
  },
  {
    "id": 1922,
    "traditional": "收看",
    "simplified": "收看",
    "reading": "shōukàn",
    "meaning": "watch, tune in, watch (e.g., receive, look in, watch (e.g., TV), tune into, TV)",
    "grade": 3
  },
  {
    "id": 1923,
    "traditional": "室",
    "simplified": "室",
    "reading": "shì",
    "meaning": "surname Shi, room, work unit, grave, scabbard, family or clan, one of the 28 constellations of Chinese astronomy",
    "grade": 3
  },
  {
    "id": 1924,
    "traditional": "收聽",
    "simplified": "收听",
    "reading": "shōutīng",
    "meaning": "to listen (to), to listen (in)",
    "grade": 3
  },
  {
    "id": 1925,
    "traditional": "手續",
    "simplified": "手续",
    "reading": "shǒuxù",
    "meaning": "formality, procedures, formalities, procedure",
    "grade": 3
  },
  {
    "id": 1926,
    "traditional": "收音機",
    "simplified": "收音机",
    "reading": "shōuyīnjī",
    "meaning": "radio, CL:臺|台[tai2]",
    "grade": 3
  },
  {
    "id": 1927,
    "traditional": "首都",
    "simplified": "首都",
    "reading": "shǒudū",
    "meaning": "capital (of country)",
    "grade": 3
  },
  {
    "id": 1928,
    "traditional": "手指",
    "simplified": "手指",
    "reading": "shǒuzhǐ",
    "meaning": "finger",
    "grade": 3
  },
  {
    "id": 1929,
    "traditional": "首先",
    "simplified": "首先",
    "reading": "shǒuxiān",
    "meaning": "first (of all), in the first place",
    "grade": 3
  },
  {
    "id": 1930,
    "traditional": "受傷",
    "simplified": "受伤",
    "reading": "shòu shāng",
    "meaning": "injure, bruise, injured, be wounded, crush, hurt, sustain an injury, be injured, wounded, maimed, wound",
    "grade": 3
  },
  {
    "id": 1931,
    "traditional": "受",
    "simplified": "受",
    "reading": "shòu",
    "meaning": "passive signifier",
    "grade": 3
  },
  {
    "id": 1932,
    "traditional": "輸",
    "simplified": "输",
    "reading": "shū",
    "meaning": "lose, be defeated",
    "grade": 3
  },
  {
    "id": 1933,
    "traditional": "書架",
    "simplified": "书架",
    "reading": "shūjià",
    "meaning": "bookshelf, CL:個|个[ge4]",
    "grade": 3
  },
  {
    "id": 1934,
    "traditional": "熟人",
    "simplified": "熟人",
    "reading": "shúrén",
    "meaning": "acquaintance, friend",
    "grade": 3
  },
  {
    "id": 1935,
    "traditional": "輸入",
    "simplified": "输入",
    "reading": "shūrù",
    "meaning": "import, input",
    "grade": 3
  },
  {
    "id": 1936,
    "traditional": "屬",
    "simplified": "属",
    "reading": "shǔ",
    "meaning": "belong, be born in the year of, belong to, be subordinate to, be",
    "grade": 3
  },
  {
    "id": 1937,
    "traditional": "束",
    "simplified": "束",
    "reading": "shù",
    "meaning": "surname Shu, to bind, bunch, bundle, classifier for bunches, bundles, beams of light etc, to control",
    "grade": 3
  },
  {
    "id": 1938,
    "traditional": "屬於",
    "simplified": "属于",
    "reading": "shǔyú",
    "meaning": "fall under, be part of, pertain, appertain, belong, relegate, fall into, belong_to, fall_under, reside, belong to, attach",
    "grade": 3
  },
  {
    "id": 1939,
    "traditional": "雙方",
    "simplified": "双方",
    "reading": "shuāngfāng",
    "meaning": "both, the two parties, two parties, both sides",
    "grade": 3
  },
  {
    "id": 1940,
    "traditional": "數量",
    "simplified": "数量",
    "reading": "shùliàng",
    "meaning": "magnitude, scalar, aggregate, deal, measure, numerical quantity, measurement, sum, worth, amount, quantity, number",
    "grade": 3
  },
  {
    "id": 1941,
    "traditional": "思想",
    "simplified": "思想",
    "reading": "sīxiǎng",
    "meaning": "cogitation, mentation, imagery, imaging, lunatic_fringe, mental imagery, idea, thinking, imagination, ideology, thought, pensee, ideological",
    "grade": 3
  },
  {
    "id": 1942,
    "traditional": "雙",
    "simplified": "双",
    "reading": "shuāng",
    "meaning": "m.[general]",
    "grade": 3
  },
  {
    "id": 1943,
    "traditional": "死",
    "simplified": "死",
    "reading": "sǐ",
    "meaning": "die",
    "grade": 3
  },
  {
    "id": 1944,
    "traditional": "速度",
    "simplified": "速度",
    "reading": "sùdù",
    "meaning": "pacing, wings, rate, quickness, fastness, velocity, swiftness, lick, pace, rapidity, speed, career, tempo",
    "grade": 3
  },
  {
    "id": 1945,
    "traditional": "隨",
    "simplified": "随",
    "reading": "suí",
    "meaning": "v.2 right after v.1",
    "grade": 3
  },
  {
    "id": 1946,
    "traditional": "所",
    "simplified": "所",
    "reading": "suǒ",
    "meaning": "that which",
    "grade": 3
  },
  {
    "id": 1947,
    "traditional": "颱",
    "simplified": "台",
    "reading": "tái",
    "meaning": "short for Taiwan",
    "grade": 3
  },
  {
    "id": 1948,
    "traditional": "所長",
    "simplified": "所长",
    "reading": "suǒcháng",
    "meaning": "head of an institute/etc.",
    "grade": 3
  },
  {
    "id": 1949,
    "traditional": "談判",
    "simplified": "谈判",
    "reading": "tánpàn",
    "meaning": "negociate, treat, talks, negotiation, negotiate, talk terms, parley, palaver, negotiations, imparl",
    "grade": 3
  },
  {
    "id": 1950,
    "traditional": "談話",
    "simplified": "谈话",
    "reading": "tánhuà",
    "meaning": "talk, speech",
    "grade": 3
  },
  {
    "id": 1951,
    "traditional": "談",
    "simplified": "谈",
    "reading": "tán",
    "meaning": "talk, chat",
    "grade": 3
  },
  {
    "id": 1952,
    "traditional": "湯",
    "simplified": "汤",
    "reading": "tāng",
    "meaning": "hot/boiling water, hot springs, soup, broth",
    "grade": 3
  },
  {
    "id": 1953,
    "traditional": "糖",
    "simplified": "糖",
    "reading": "táng",
    "meaning": "refined sugar, carob bar, negus, sugar, candy, carbohydrate, sweets",
    "grade": 3
  },
  {
    "id": 1954,
    "traditional": "特色",
    "simplified": "特色",
    "reading": "tèsè",
    "meaning": "motif, a character, a characteristic, manner, trait, differentiation, flavor, colour, singularity, distinction, characteristic, hue, stripe, physiognomy, a distinguishing feature, idiom, specialty, character, difference, particular, distinguishing feature, distinguishing feature/quality, salience, coloration, distinguishing quality, specific, feature, peculiarity, artistic st",
    "grade": 3
  },
  {
    "id": 1955,
    "traditional": "提問",
    "simplified": "提问",
    "reading": "tíwèn",
    "meaning": "to question, to quiz, to grill",
    "grade": 3
  },
  {
    "id": 1956,
    "traditional": "提前",
    "simplified": "提前",
    "reading": "tíqián",
    "meaning": "counter, foresee, shift to an earlier date, set ahead, advance date, advanced, advancement, move up, anticipate, advance, forestall",
    "grade": 3
  },
  {
    "id": 1957,
    "traditional": "體會",
    "simplified": "体会",
    "reading": "tǐhuì",
    "meaning": "know/learn from experience, realize",
    "grade": 3
  },
  {
    "id": 1958,
    "traditional": "體現",
    "simplified": "体现",
    "reading": "tǐxiàn",
    "meaning": "to embody, to reflect, to incarnate",
    "grade": 3
  },
  {
    "id": 1959,
    "traditional": "題目",
    "simplified": "题目",
    "reading": "tímù",
    "meaning": "exam questions, head, lemma, theme, thesis, examination questions, question, text, title, ground, subject, topic, exercise problems, chapter, caption, rubric, heading",
    "grade": 3
  },
  {
    "id": 1960,
    "traditional": "體驗",
    "simplified": "体验",
    "reading": "tǐyàn",
    "meaning": "learn through practice/experience",
    "grade": 3
  },
  {
    "id": 1961,
    "traditional": "天空",
    "simplified": "天空",
    "reading": "tiānkōng",
    "meaning": "firmament, heavenly, empyrean, heavens, welkin, blue, canopy, cloud, the heavens, vault, air, sky, the sky, Aether, wild blue yonder, blue air, heaven, blue sky, space",
    "grade": 3
  },
  {
    "id": 1962,
    "traditional": "甜",
    "simplified": "甜",
    "reading": "tián",
    "meaning": "sweet, agreeable",
    "grade": 3
  },
  {
    "id": 1963,
    "traditional": "跳",
    "simplified": "跳",
    "reading": "tiào",
    "meaning": "ricochet, make omissions, sell grain, vault, hop, bounce, bound, omission, omit, palpitate, move up and down, skip over, beat, take, spring, skip, dance, hurdle, skip (over), jump, leap",
    "grade": 3
  },
  {
    "id": 1964,
    "traditional": "跳高",
    "simplified": "跳高",
    "reading": "tiàogāo",
    "meaning": "high jump (athletics)",
    "grade": 3
  },
  {
    "id": 1965,
    "traditional": "跳遠",
    "simplified": "跳远",
    "reading": "tiàoyuǎn",
    "meaning": "long jump (athletics)",
    "grade": 3
  },
  {
    "id": 1966,
    "traditional": "調整",
    "simplified": "调整",
    "reading": "tiáozhěng",
    "meaning": "adjust, readjust, regulate, restructure, balance",
    "grade": 3
  },
  {
    "id": 1967,
    "traditional": "鐵",
    "simplified": "铁",
    "reading": "tiě",
    "meaning": "shovel, smoothing iron, iron, ferrous, arms, Surname, Fe, ferrum, spade, weapon",
    "grade": 3
  },
  {
    "id": 1968,
    "traditional": "跳舞",
    "simplified": "跳舞",
    "reading": "tiào wǔ",
    "meaning": "hop, trip the light fantastic, trip, cakewalk, dancing, dance, saltate, hoof, step",
    "grade": 3
  },
  {
    "id": 1969,
    "traditional": "聽眾",
    "simplified": "听众",
    "reading": "tīngzhòng",
    "meaning": "auditor, listener, hearer, audience, auditory, gallery, listeners",
    "grade": 3
  },
  {
    "id": 1970,
    "traditional": "聽力",
    "simplified": "听力",
    "reading": "tīnglì",
    "meaning": "hearing, listening ability",
    "grade": 3
  },
  {
    "id": 1971,
    "traditional": "通常",
    "simplified": "通常",
    "reading": "tōngcháng",
    "meaning": "normal, commonly, customarily, more often than not, general, usual, on average, as a rule, ordinarily, mostly, as usual, typically, generally, normally, by and large, unremarkably, on the average, usually",
    "grade": 3
  },
  {
    "id": 1972,
    "traditional": "通信",
    "simplified": "通信",
    "reading": "tōngxìn",
    "meaning": "communicate by letter, correspond",
    "grade": 3
  },
  {
    "id": 1973,
    "traditional": "鐵路",
    "simplified": "铁路",
    "reading": "tiělù",
    "meaning": "rails, railroad track, loop-line, rail, Pullman, railway, road, couchette, chemmy, railroad",
    "grade": 3
  },
  {
    "id": 1974,
    "traditional": "停止",
    "simplified": "停止",
    "reading": "tíngzhǐ",
    "meaning": "cease, flunk, desist, pass, suspend, stint, pass_off, break_up, stash, cut, stay, cessation, adjourn, rain_out, give, detain, blow_over, seize, give up, balk, cheese, abstain, refrain, knock_off, rest, abandon, break, knock off, drop, go, tie_up, stop, interdict, strike, lay_off, stow, cut off, discontinue, pause, leave_off, leave off, set aside, give_out, cut_out, give_up, delay, quit, cut out",
    "grade": 3
  },
  {
    "id": 1975,
    "traditional": "同意",
    "simplified": "同意",
    "reading": "tóngyì",
    "meaning": "agree, consent, approve",
    "grade": 3
  },
  {
    "id": 1976,
    "traditional": "痛苦",
    "simplified": "痛苦",
    "reading": "tòngkǔ",
    "meaning": "be painful; pain",
    "grade": 3
  },
  {
    "id": 1977,
    "traditional": "痛",
    "simplified": "痛",
    "reading": "tòng",
    "meaning": "ache, pain",
    "grade": 3
  },
  {
    "id": 1978,
    "traditional": "頭",
    "simplified": "头",
    "reading": "tóu",
    "meaning": "head, hair (style), top/end of sth., chief",
    "grade": 3
  },
  {
    "id": 1979,
    "traditional": "頭腦",
    "simplified": "头脑",
    "reading": "tóunǎo",
    "meaning": "pericranium, leader, head, noggin, brain, brains, main threads, clue, nous, loaf, bean, psyche, headpiece, mind, pate, skull",
    "grade": 3
  },
  {
    "id": 1980,
    "traditional": "突出",
    "simplified": "突出",
    "reading": "tū chū",
    "meaning": "outstanding",
    "grade": 3
  },
  {
    "id": 1981,
    "traditional": "圖",
    "simplified": "图",
    "reading": "tú",
    "meaning": "picture, drawing, chart, map",
    "grade": 3
  },
  {
    "id": 1982,
    "traditional": "突然",
    "simplified": "突然",
    "reading": "tūrán",
    "meaning": "suddenly, presto, unexpectedly, abruptly, at once, all_at_once, all together, all of a sudden, all at once",
    "grade": 3
  },
  {
    "id": 1983,
    "traditional": "圖畫",
    "simplified": "图画",
    "reading": "túhuà",
    "meaning": "drawing, picture",
    "grade": 3
  },
  {
    "id": 1984,
    "traditional": "土",
    "simplified": "土",
    "reading": "tǔ",
    "meaning": "soil, earth, clay, land",
    "grade": 3
  },
  {
    "id": 1985,
    "traditional": "團",
    "simplified": "团",
    "reading": "tuán",
    "meaning": "m.[proximation]",
    "grade": 3
  },
  {
    "id": 1986,
    "traditional": "團結",
    "simplified": "团结",
    "reading": "tuánjié",
    "meaning": "unite, rally",
    "grade": 3
  },
  {
    "id": 1987,
    "traditional": "團體",
    "simplified": "团体",
    "reading": "tuántǐ",
    "meaning": "fraternity, sect, phalanx, collective, body, group, sodality, element, organisation, corporation, confession, college, establishment, brotherhood, community, organization, squad, union, administration, society, army, commonwealth, consort, company, lineup, combine, governance, clan, combination, fellowship, organizational, bloc, team, party, connection, family, brass, squadron",
    "grade": 3
  },
  {
    "id": 1988,
    "traditional": "推動",
    "simplified": "推动",
    "reading": "tuī dòng",
    "meaning": "push forward, promote",
    "grade": 3
  },
  {
    "id": 1989,
    "traditional": "推廣",
    "simplified": "推广",
    "reading": "tuīguǎng",
    "meaning": "popularize, spread, extend",
    "grade": 3
  },
  {
    "id": 1990,
    "traditional": "推進",
    "simplified": "推进",
    "reading": "tuījìn",
    "meaning": "to impel, to carry forward, to push on, to advance, to drive forward",
    "grade": 3
  },
  {
    "id": 1991,
    "traditional": "推開",
    "simplified": "推开",
    "reading": "tuīkāi",
    "meaning": "to push open (a gate etc), to push away, to reject, to decline",
    "grade": 3
  },
  {
    "id": 1992,
    "traditional": "退",
    "simplified": "退",
    "reading": "tuì",
    "meaning": "retreat, retire, decline, ebb",
    "grade": 3
  },
  {
    "id": 1993,
    "traditional": "退出",
    "simplified": "退出",
    "reading": "tuì chū",
    "meaning": "withdraw from, quit",
    "grade": 3
  },
  {
    "id": 1994,
    "traditional": "退休",
    "simplified": "退休",
    "reading": "tuìxiū",
    "meaning": "to retire, to go into retirement, to step down",
    "grade": 3
  },
  {
    "id": 1995,
    "traditional": "外交",
    "simplified": "外交",
    "reading": "wàijiāo",
    "meaning": "diplomatics, diplomatic negotiations, diplomacy, nonintervention, diplomatism, diplomatic, foreign affairs",
    "grade": 3
  },
  {
    "id": 1996,
    "traditional": "外文",
    "simplified": "外文",
    "reading": "wàiwén",
    "meaning": "foreign language (written)",
    "grade": 3
  },
  {
    "id": 1997,
    "traditional": "完美",
    "simplified": "完美",
    "reading": "wánměi",
    "meaning": "perfect, consummate",
    "grade": 3
  },
  {
    "id": 1998,
    "traditional": "外面",
    "simplified": "外面",
    "reading": "wàimian",
    "meaning": "surface, outward appearance, without, outside, exterior",
    "grade": 3
  },
  {
    "id": 1999,
    "traditional": "完整",
    "simplified": "完整",
    "reading": "wánzhěng",
    "meaning": "complete, integrated, intact",
    "grade": 3
  },
  {
    "id": 2000,
    "traditional": "完善",
    "simplified": "完善",
    "reading": "wánshàn",
    "meaning": "perfect, consummate",
    "grade": 3
  },
  {
    "id": 2001,
    "traditional": "玩具",
    "simplified": "玩具",
    "reading": "wánjù",
    "meaning": "yo-yo, bauble, plaything, toy, teether, knickknack",
    "grade": 3
  },
  {
    "id": 2002,
    "traditional": "往往",
    "simplified": "往往",
    "reading": "wǎngwǎng",
    "meaning": "oftentimes, ofttimes, always, more often than not, frequently, oft, often",
    "grade": 3
  },
  {
    "id": 2003,
    "traditional": "危險",
    "simplified": "危险",
    "reading": "wēixiǎn",
    "meaning": "dangerous, perilous",
    "grade": 3
  },
  {
    "id": 2004,
    "traditional": "危害",
    "simplified": "危害",
    "reading": "wēihài",
    "meaning": "to jeopardize, to harm, to endanger, harmful effect, damage, CL:個|个[ge4]",
    "grade": 3
  },
  {
    "id": 2005,
    "traditional": "為",
    "simplified": "为",
    "reading": "wèi",
    "meaning": "by",
    "grade": 3
  },
  {
    "id": 2006,
    "traditional": "偉大",
    "simplified": "伟大",
    "reading": "wěidà",
    "meaning": "great, mighty",
    "grade": 3
  },
  {
    "id": 2007,
    "traditional": "衛生間",
    "simplified": "卫生间",
    "reading": "wèishēngjiān",
    "meaning": "bathroom, toilet, WC, CL:間|间[jian1]",
    "grade": 3
  },
  {
    "id": 2008,
    "traditional": "衛生",
    "simplified": "卫生",
    "reading": "wèishēng",
    "meaning": "hygiene, health, sanitation",
    "grade": 3
  },
  {
    "id": 2009,
    "traditional": "為了",
    "simplified": "为了",
    "reading": "wèile",
    "meaning": "in order to, for the purpose of, so as to",
    "grade": 3
  },
  {
    "id": 2010,
    "traditional": "溫暖",
    "simplified": "温暖",
    "reading": "wēnnuǎn",
    "meaning": "warm",
    "grade": 3
  },
  {
    "id": 2011,
    "traditional": "圍",
    "simplified": "围",
    "reading": "wéi",
    "meaning": "surname Wei, to encircle, to surround, all around, to wear by wrapping around (scarf, shawl)",
    "grade": 3
  },
  {
    "id": 2012,
    "traditional": "文化",
    "simplified": "文化",
    "reading": "wénhuà",
    "meaning": "civilization, literacy, acculturation, Kultur, vandalism, cultural, education, schooling, culture",
    "grade": 3
  },
  {
    "id": 2013,
    "traditional": "文明",
    "simplified": "文明",
    "reading": "wénmíng",
    "meaning": "civilization, culture",
    "grade": 3
  },
  {
    "id": 2014,
    "traditional": "文件",
    "simplified": "文件",
    "reading": "wénjiàn",
    "meaning": "page, documental, archive, writ, documents, data file, paper, document, judicial writ, literature, circular, documentation, portfolio, archives, file, written document, papers",
    "grade": 3
  },
  {
    "id": 2015,
    "traditional": "文章",
    "simplified": "文章",
    "reading": "wénzhāng",
    "meaning": "nonsense, hidden meaning, writing, hidden, written material, article, implied meaning, essay, writings, literary works, patch, hidden/implied meaning",
    "grade": 3
  },
  {
    "id": 2016,
    "traditional": "文學",
    "simplified": "文学",
    "reading": "wénxué",
    "meaning": "literary, philology, letters, letter, literature",
    "grade": 3
  },
  {
    "id": 2017,
    "traditional": "握手",
    "simplified": "握手",
    "reading": "wò shǒu",
    "meaning": "to shake hands",
    "grade": 3
  },
  {
    "id": 2018,
    "traditional": "屋子",
    "simplified": "屋子",
    "reading": "wūzi",
    "meaning": "room, house",
    "grade": 3
  },
  {
    "id": 2019,
    "traditional": "武器",
    "simplified": "武器",
    "reading": "wǔqì",
    "meaning": "enginery, steel, arming, armament, weapons system, hardware, armored, armature, munition, weaponry, artillery, implements of war, armory, arms, ammunition, armor, weapon, arm, armoring",
    "grade": 3
  },
  {
    "id": 2020,
    "traditional": "武術",
    "simplified": "武术",
    "reading": "wǔshù",
    "meaning": "military skill or technique (in former times), all kinds of martial art sports (some claiming spiritual development), self-defense, tradition of choreographed fights from opera and film (recent usage), also called kungfu 功夫, CL:種|种[zhong3]",
    "grade": 3
  },
  {
    "id": 2021,
    "traditional": "文字",
    "simplified": "文字",
    "reading": "wénzì",
    "meaning": "writing, phraseology, writing style, language, endearment, script, written language, text, writing phraseology, writing style/phraseology, character, textual, characters, symbolic representation, version, word-sign, literal, letter, word, literalness",
    "grade": 3
  },
  {
    "id": 2022,
    "traditional": "西部",
    "simplified": "西部",
    "reading": "xībù",
    "meaning": "western part, West, westward, west, the west",
    "grade": 3
  },
  {
    "id": 2023,
    "traditional": "舞臺",
    "simplified": "舞台",
    "reading": "wǔtái",
    "meaning": "board, arena, bandstand, stage, proscenium, boards",
    "grade": 3
  },
  {
    "id": 2024,
    "traditional": "下來",
    "simplified": "下来",
    "reading": "xià lai",
    "meaning": "come down from a higher place",
    "grade": 3
  },
  {
    "id": 2025,
    "traditional": "系",
    "simplified": "系",
    "reading": "xì",
    "meaning": "be",
    "grade": 3
  },
  {
    "id": 2026,
    "traditional": "希望",
    "simplified": "希望",
    "reading": "xīwàng",
    "meaning": "hope, wish",
    "grade": 3
  },
  {
    "id": 2027,
    "traditional": "下去",
    "simplified": "下去",
    "reading": "xià qu",
    "meaning": "go on, continue, go down, descend, take_up, descent",
    "grade": 3
  },
  {
    "id": 2028,
    "traditional": "顯然",
    "simplified": "显然",
    "reading": "xiǎnrán",
    "meaning": "evidently, manifestly, clear, visibly, plainly, plain, distinctly, clearly, patently, apparently, obviously",
    "grade": 3
  },
  {
    "id": 2029,
    "traditional": "先進",
    "simplified": "先进",
    "reading": "xiānjìn",
    "meaning": "advance",
    "grade": 3
  },
  {
    "id": 2030,
    "traditional": "顯得",
    "simplified": "显得",
    "reading": "xiǎnde",
    "meaning": "seem, look, appear",
    "grade": 3
  },
  {
    "id": 2031,
    "traditional": "下面",
    "simplified": "下面",
    "reading": "xiàmian",
    "meaning": "next, lower level, underside, following, underneath, subordinate, undersurface",
    "grade": 3
  },
  {
    "id": 2032,
    "traditional": "顯示",
    "simplified": "显示",
    "reading": "xiǎnshì",
    "meaning": "demonstrate, argue, exhibit, prove, display, show, illustrate, manifest, demonstration, announce, stamp, unroll, witness, write, give, evince, dangle, betray, bespeak, say, profess, develop, indicate, signal, flaunt, read, register, manifestation, speak, reveal, evidence, tell, shew, proclaim, discover, stage, bear, establish, record, prognosticate, betoken, note, signalize",
    "grade": 3
  },
  {
    "id": 2033,
    "traditional": "現場",
    "simplified": "现场",
    "reading": "xiànchǎng",
    "meaning": "scene (of an incident), location, site, locale, scene of a crime, locality, live, scene, spot, field",
    "grade": 3
  },
  {
    "id": 2034,
    "traditional": "現代",
    "simplified": "现代",
    "reading": "xiàndài",
    "meaning": "modern",
    "grade": 3
  },
  {
    "id": 2035,
    "traditional": "現金",
    "simplified": "现金",
    "reading": "xiànjīn",
    "meaning": "quids, rhino, clink, ready, cash reserve in bank, dough, dust, ducat, kale, cash, blunt, till, ready money",
    "grade": 3
  },
  {
    "id": 2036,
    "traditional": "現實",
    "simplified": "现实",
    "reading": "xiànshí",
    "meaning": "reality, actuality",
    "grade": 3
  },
  {
    "id": 2037,
    "traditional": "線",
    "simplified": "线",
    "reading": "xiàn",
    "meaning": "thread, string, wire",
    "grade": 3
  },
  {
    "id": 2038,
    "traditional": "相當",
    "simplified": "相当",
    "reading": "xiāngdāng",
    "meaning": "quite, fairly, considerably",
    "grade": 3
  },
  {
    "id": 2039,
    "traditional": "相比",
    "simplified": "相比",
    "reading": "xiāngbǐ",
    "meaning": "liken, equate, compare, comparison",
    "grade": 3
  },
  {
    "id": 2040,
    "traditional": "現象",
    "simplified": "现象",
    "reading": "xiànxiàng",
    "meaning": "phenomenon, phenomenal, appearance",
    "grade": 3
  },
  {
    "id": 2041,
    "traditional": "相互",
    "simplified": "相互",
    "reading": "xiānghù",
    "meaning": "each other, mutually, one another, in return, reciprocally",
    "grade": 3
  },
  {
    "id": 2042,
    "traditional": "相關",
    "simplified": "相关",
    "reading": "xiāngguān",
    "meaning": "cognate, relational, interrelate, associated, be interrelated, relevant, related, be mutually related",
    "grade": 3
  },
  {
    "id": 2043,
    "traditional": "相似",
    "simplified": "相似",
    "reading": "xiāngsì",
    "meaning": "resemblance, alike, be alike, be similar/alike, match, be similar, resemble",
    "grade": 3
  },
  {
    "id": 2044,
    "traditional": "香",
    "simplified": "香",
    "reading": "xiāng",
    "meaning": "fragrant, scented, savory, appetizing, popular, welcome",
    "grade": 3
  },
  {
    "id": 2045,
    "traditional": "香蕉",
    "simplified": "香蕉",
    "reading": "xiāngjiāo",
    "meaning": "banana",
    "grade": 3
  },
  {
    "id": 2046,
    "traditional": "消費",
    "simplified": "消费",
    "reading": "xiāofèi",
    "meaning": "expenditure",
    "grade": 3
  },
  {
    "id": 2047,
    "traditional": "消失",
    "simplified": "消失",
    "reading": "xiāoshī",
    "meaning": "disappear, vanish, dissolve",
    "grade": 3
  },
  {
    "id": 2048,
    "traditional": "消息",
    "simplified": "消息",
    "reading": "xiāoxi",
    "meaning": "communication, witting, message, item, news, peep, info, intelligence, notice, information, advertisement, tidings, advert, word",
    "grade": 3
  },
  {
    "id": 2049,
    "traditional": "寫作",
    "simplified": "写作",
    "reading": "xiězuò",
    "meaning": "writing",
    "grade": 3
  },
  {
    "id": 2050,
    "traditional": "血",
    "simplified": "血",
    "reading": "xuè",
    "meaning": "claret, blood, hematic, lifeline, lifeblood, sap",
    "grade": 3
  },
  {
    "id": 2051,
    "traditional": "效果",
    "simplified": "效果",
    "reading": "xiàoguǒ",
    "meaning": "operation, execution, result, effect, background, sound effects, purpose, point, background signal, fruitage, outcome, force, punch",
    "grade": 3
  },
  {
    "id": 2052,
    "traditional": "心",
    "simplified": "心",
    "reading": "xīn",
    "meaning": "heart, mind, feeling, center, core",
    "grade": 3
  },
  {
    "id": 2053,
    "traditional": "信封",
    "simplified": "信封",
    "reading": "xìnfēng",
    "meaning": "envelope, CL:個|个[ge4]",
    "grade": 3
  },
  {
    "id": 2054,
    "traditional": "行李",
    "simplified": "行李",
    "reading": "xíngli",
    "meaning": "clobber, impedimenta, dunnage, luggage, baggage",
    "grade": 3
  },
  {
    "id": 2055,
    "traditional": "信任",
    "simplified": "信任",
    "reading": "xìnrèn",
    "meaning": "trust, have confidence in",
    "grade": 3
  },
  {
    "id": 2056,
    "traditional": "形成",
    "simplified": "形成",
    "reading": "xíngchéng",
    "meaning": "take shape, form",
    "grade": 3
  },
  {
    "id": 2057,
    "traditional": "形式",
    "simplified": "形式",
    "reading": "xíngshì",
    "meaning": "circumstance, form, kind, modality, reading, interpretation, variety, shape, embodiment, species, mode, version, sort",
    "grade": 3
  },
  {
    "id": 2058,
    "traditional": "形象",
    "simplified": "形象",
    "reading": "xíngxiàng",
    "meaning": "imagery, figure, form, picture, persona, image",
    "grade": 3
  },
  {
    "id": 2059,
    "traditional": "形狀",
    "simplified": "形状",
    "reading": "xíngzhuàng",
    "meaning": "set, figuration, physiognomy, form, appearance, shape",
    "grade": 3
  },
  {
    "id": 2060,
    "traditional": "幸福",
    "simplified": "幸福",
    "reading": "xìngfú",
    "meaning": "happy",
    "grade": 3
  },
  {
    "id": 2061,
    "traditional": "幸運",
    "simplified": "幸运",
    "reading": "xìngyùn",
    "meaning": "very fortunate, lucky",
    "grade": 3
  },
  {
    "id": 2062,
    "traditional": "信",
    "simplified": "信",
    "reading": "xìn",
    "meaning": "letter, mail, message",
    "grade": 3
  },
  {
    "id": 2063,
    "traditional": "性",
    "simplified": "性",
    "reading": "xìng",
    "meaning": "nature, character, sex, gender",
    "grade": 3
  },
  {
    "id": 2064,
    "traditional": "性別",
    "simplified": "性别",
    "reading": "xìngbié",
    "meaning": "sexuality, grammatical gender, sexual distinction, sex, gender",
    "grade": 3
  },
  {
    "id": 2065,
    "traditional": "性格",
    "simplified": "性格",
    "reading": "xìnggé",
    "meaning": "fibre, make-up, mettle, humor, nature, complexion, capacity, mentality, mould, mold, being, streak, temperament, personality, character, fiber, disposition, strain, makeup, make",
    "grade": 3
  },
  {
    "id": 2066,
    "traditional": "修",
    "simplified": "修",
    "reading": "xiū",
    "meaning": "embellish, decorate, repair, mend, overhaul, build, construct, trim, prune",
    "grade": 3
  },
  {
    "id": 2067,
    "traditional": "修改",
    "simplified": "修改",
    "reading": "xiūgǎi",
    "meaning": "revise, amend, alter",
    "grade": 3
  },
  {
    "id": 2068,
    "traditional": "需求",
    "simplified": "需求",
    "reading": "xūqiú",
    "meaning": "need, requisition, demand, want, desiderative, requirement, call",
    "grade": 3
  },
  {
    "id": 2069,
    "traditional": "需要",
    "simplified": "需要",
    "reading": "xūyào",
    "meaning": "need, want, require, demand",
    "grade": 3
  },
  {
    "id": 2070,
    "traditional": "宣布",
    "simplified": "宣布",
    "reading": "xuānbù",
    "meaning": "declare, proclaim, announce",
    "grade": 3
  },
  {
    "id": 2071,
    "traditional": "宣傳",
    "simplified": "宣传",
    "reading": "xuānchuán",
    "meaning": "propagandist, publicist",
    "grade": 3
  },
  {
    "id": 2072,
    "traditional": "訓練",
    "simplified": "训练",
    "reading": "xùnliàn",
    "meaning": "training",
    "grade": 3
  },
  {
    "id": 2073,
    "traditional": "選手",
    "simplified": "选手",
    "reading": "xuǎnshǒu",
    "meaning": "participant, basketeer, athlete selected for a sports meet, basketball player, jock, contestant, cap, cager, athlete, player, champ, performer, player selected as contestant",
    "grade": 3
  },
  {
    "id": 2074,
    "traditional": "學費",
    "simplified": "学费",
    "reading": "xuéfèi",
    "meaning": "entrance_fee, tuition fee, premium, schooling, tuition",
    "grade": 3
  },
  {
    "id": 2075,
    "traditional": "壓",
    "simplified": "压",
    "reading": "yā",
    "meaning": "weight_down, hold down, push down, compact, constrict, be getting near, suppress, squeeze, compress, risk money on, quell, bring pressure to, pull, press, keep under control, throttle, push/hold down, intimidate, scrunch, weigh down, scrouge, daunt, shelve, pigeonhole, contract, approach, keep under, push, stake, control",
    "grade": 3
  },
  {
    "id": 2076,
    "traditional": "壓力",
    "simplified": "压力",
    "reading": "yālì",
    "meaning": "force per unit area, P, strain, overwhelming force, pressure sensation, burdensomeness, oppressiveness, pressure, onerousness, stress, compulsion, constraint, muscle, lading",
    "grade": 3
  },
  {
    "id": 2077,
    "traditional": "煙",
    "simplified": "烟",
    "reading": "yān",
    "meaning": "smoke, mist, vapor",
    "grade": 3
  },
  {
    "id": 2078,
    "traditional": "演",
    "simplified": "演",
    "reading": "yǎn",
    "meaning": "elaborate, development, practise, practice, develop, act, performance, represent, deduction, drill, play, evolution, evolve, play the part of, perform, play the role of, mount, elaboration, deduce",
    "grade": 3
  },
  {
    "id": 2079,
    "traditional": "眼前",
    "simplified": "眼前",
    "reading": "yǎnqián",
    "meaning": "before one's eyes",
    "grade": 3
  },
  {
    "id": 2080,
    "traditional": "演唱",
    "simplified": "演唱",
    "reading": "yǎnchàng",
    "meaning": "sing in performance",
    "grade": 3
  },
  {
    "id": 2081,
    "traditional": "演唱會",
    "simplified": "演唱会",
    "reading": "yǎnchànghuì",
    "meaning": "vocal recital",
    "grade": 3
  },
  {
    "id": 2082,
    "traditional": "演員",
    "simplified": "演员",
    "reading": "yǎnyuán",
    "meaning": "actor or actress, playactor, imitator, stage manager, barnstormer, actor, performer, impersonator, cast, performing artist, dramatis personae, role player, histrionic, thespian, trouper, player, cast of characters, stager, histrion",
    "grade": 3
  },
  {
    "id": 2083,
    "traditional": "演出",
    "simplified": "演出",
    "reading": "yǎnchū",
    "meaning": "show, perform",
    "grade": 3
  },
  {
    "id": 2084,
    "traditional": "羊",
    "simplified": "羊",
    "reading": "yáng",
    "meaning": "kidney, drover, baa, trotter, argali, mutton, sheep, Surname",
    "grade": 3
  },
  {
    "id": 2085,
    "traditional": "陽光",
    "simplified": "阳光",
    "reading": "yángguāng",
    "meaning": "sunray, shine, sun, sunlight, sunshine, sunbeam",
    "grade": 3
  },
  {
    "id": 2086,
    "traditional": "要是",
    "simplified": "要是",
    "reading": "yàoshi",
    "meaning": "conj.: if, suppose, in case",
    "grade": 3
  },
  {
    "id": 2087,
    "traditional": "一切",
    "simplified": "一切",
    "reading": "yīqiè",
    "meaning": "det.: everything, every",
    "grade": 3
  },
  {
    "id": 2088,
    "traditional": "衣架",
    "simplified": "衣架",
    "reading": "yījià",
    "meaning": "clothes hanger, clothes rack",
    "grade": 3
  },
  {
    "id": 2089,
    "traditional": "以來",
    "simplified": "以来",
    "reading": "yǐlái",
    "meaning": "since",
    "grade": 3
  },
  {
    "id": 2090,
    "traditional": "已",
    "simplified": "已",
    "reading": "yǐ",
    "meaning": "already",
    "grade": 3
  },
  {
    "id": 2091,
    "traditional": "一方面",
    "simplified": "一方面",
    "reading": "yīfāngmiàn",
    "meaning": "conj.: on one side, on the one hand..., on the other hand..., for one thing..., for another...",
    "grade": 3
  },
  {
    "id": 2092,
    "traditional": "藝術",
    "simplified": "艺术",
    "reading": "yìshù",
    "meaning": "beaux-arts, aesthetical, artistic production, artistic, ars, art, academy, aesthetic, artistic creation, skill, craft, artistry, esthetic",
    "grade": 3
  },
  {
    "id": 2093,
    "traditional": "意義",
    "simplified": "意义",
    "reading": "yìyì",
    "meaning": "importance, significance, denotation, purport, drift, signality, emptiness, meaning, construction, bearing, substance, import, effect, insignificancy, hang, signification, sense",
    "grade": 3
  },
  {
    "id": 2094,
    "traditional": "意外",
    "simplified": "意外",
    "reading": "yìwài",
    "meaning": "accident, mishap",
    "grade": 3
  },
  {
    "id": 2095,
    "traditional": "因此",
    "simplified": "因此",
    "reading": "yīncǐ",
    "meaning": "conj.: therefore, consequently",
    "grade": 3
  },
  {
    "id": 2096,
    "traditional": "銀牌",
    "simplified": "银牌",
    "reading": "yínpái",
    "meaning": "silver medal, CL:枚[mei2]",
    "grade": 3
  },
  {
    "id": 2097,
    "traditional": "印象",
    "simplified": "印象",
    "reading": "yìnxiàng",
    "meaning": "effect, mark, impress, embossment, impressional, image, imprint, engram, sound, intuition, impression",
    "grade": 3
  },
  {
    "id": 2098,
    "traditional": "應當",
    "simplified": "应当",
    "reading": "yīngdāng",
    "meaning": "should",
    "grade": 3
  },
  {
    "id": 2099,
    "traditional": "銀",
    "simplified": "银",
    "reading": "yín",
    "meaning": "silver",
    "grade": 3
  },
  {
    "id": 2100,
    "traditional": "迎接",
    "simplified": "迎接",
    "reading": "yíngjiē",
    "meaning": "greet, receive, welcome, accolade, meet",
    "grade": 3
  },
  {
    "id": 2101,
    "traditional": "營養",
    "simplified": "营养",
    "reading": "yíngyǎng",
    "meaning": "nutrition, nourishment",
    "grade": 3
  },
  {
    "id": 2102,
    "traditional": "影視",
    "simplified": "影视",
    "reading": "yǐngshì",
    "meaning": "movies and television",
    "grade": 3
  },
  {
    "id": 2103,
    "traditional": "贏",
    "simplified": "赢",
    "reading": "yíng",
    "meaning": "lick, beat, whip, gain, capot, win",
    "grade": 3
  },
  {
    "id": 2104,
    "traditional": "應用",
    "simplified": "应用",
    "reading": "yìngyòng",
    "meaning": "application, use",
    "grade": 3
  },
  {
    "id": 2105,
    "traditional": "優點",
    "simplified": "优点",
    "reading": "yōudiǎn",
    "meaning": "strong, merit, vantage, excellency, perfection, assets, beaut, advantage, excellence, upside, strong_point, good point, virtue, desert, desirability, strong point, long_suit, beauty, strong/good point, desirableness",
    "grade": 3
  },
  {
    "id": 2106,
    "traditional": "由",
    "simplified": "由",
    "reading": "yóu",
    "meaning": "from, via, by, through, owing/due to",
    "grade": 3
  },
  {
    "id": 2107,
    "traditional": "優勢",
    "simplified": "优势",
    "reading": "yōushì",
    "meaning": "ascendence, laterality, preponderancy, vantage, ascendancy, ascendent, transcendency, dominance, predominance, draw, gree, upper_hand, prevalence, advantage, mastery, ascendency, supremacy, advantageousness, control, preeminence, dominion, jump, edge, better, deadwood, overweight, superiority, domination, sovereignty, preponderance, ascendance, transcendence, prepotency, hank, asce",
    "grade": 3
  },
  {
    "id": 2108,
    "traditional": "郵件",
    "simplified": "邮件",
    "reading": "yóujiàn",
    "meaning": "mail, postal items, V-mail, mailing, postal matter, post, postbag, posting",
    "grade": 3
  },
  {
    "id": 2109,
    "traditional": "郵票",
    "simplified": "邮票",
    "reading": "yóupiào",
    "meaning": "postage_stamp, album, label, stamp, postage, postage stamp, stickies",
    "grade": 3
  },
  {
    "id": 2110,
    "traditional": "由於",
    "simplified": "由于",
    "reading": "yóuyú",
    "meaning": "conj.: owing/due/thanks to",
    "grade": 3
  },
  {
    "id": 2111,
    "traditional": "郵箱",
    "simplified": "邮箱",
    "reading": "yóuxiāng",
    "meaning": "mailbox, post office box",
    "grade": 3
  },
  {
    "id": 2112,
    "traditional": "遊戲",
    "simplified": "游戏",
    "reading": "yóuxì",
    "meaning": "game, CL:場|场[chang3], to play",
    "grade": 3
  },
  {
    "id": 2113,
    "traditional": "游",
    "simplified": "游",
    "reading": "yóu",
    "meaning": "swim, float, travel, rove",
    "grade": 3
  },
  {
    "id": 2114,
    "traditional": "游泳",
    "simplified": "游泳",
    "reading": "yóuyǒng",
    "meaning": "fin, swim, bathe",
    "grade": 3
  },
  {
    "id": 2115,
    "traditional": "有的是",
    "simplified": "有的是",
    "reading": "yǒudeshì",
    "meaning": "have plenty of, there's no lack of",
    "grade": 3
  },
  {
    "id": 2116,
    "traditional": "有利",
    "simplified": "有利",
    "reading": "yǒulì",
    "meaning": "be advantageous/beneficial",
    "grade": 3
  },
  {
    "id": 2117,
    "traditional": "有效",
    "simplified": "有效",
    "reading": "yǒuxiào",
    "meaning": "run, stand, valid, effective, efficacious, avail, make, hold",
    "grade": 3
  },
  {
    "id": 2118,
    "traditional": "預報",
    "simplified": "预报",
    "reading": "yùbào",
    "meaning": "forecast",
    "grade": 3
  },
  {
    "id": 2119,
    "traditional": "預防",
    "simplified": "预防",
    "reading": "yùfáng",
    "meaning": "prevent, guard against",
    "grade": 3
  },
  {
    "id": 2120,
    "traditional": "預計",
    "simplified": "预计",
    "reading": "yùjì",
    "meaning": "estimate, view, predict, expect, calculate in advance, anticipate",
    "grade": 3
  },
  {
    "id": 2121,
    "traditional": "員",
    "simplified": "员",
    "reading": "yuán",
    "meaning": "m.[general]",
    "grade": 3
  },
  {
    "id": 2122,
    "traditional": "員工",
    "simplified": "员工",
    "reading": "yuángōng",
    "meaning": "employee, personnel, reason, force, staff, cause",
    "grade": 3
  },
  {
    "id": 2123,
    "traditional": "願望",
    "simplified": "愿望",
    "reading": "yuànwàng",
    "meaning": "wish, desire, pleasure, Eros, will, orexis, desirability, dream, wishfulness, mind, mirage, wistfulness, aspiration, ambition, hope",
    "grade": 3
  },
  {
    "id": 2124,
    "traditional": "約",
    "simplified": "约",
    "reading": "yuē",
    "meaning": "about, around",
    "grade": 3
  },
  {
    "id": 2125,
    "traditional": "預習",
    "simplified": "预习",
    "reading": "yùxí",
    "meaning": "to prepare a lesson",
    "grade": 3
  },
  {
    "id": 2126,
    "traditional": "樂隊",
    "simplified": "乐队",
    "reading": "yuèduì",
    "meaning": "orchestra, dance orchestra, dance band, band",
    "grade": 3
  },
  {
    "id": 2127,
    "traditional": "運輸",
    "simplified": "运输",
    "reading": "yùnshū",
    "meaning": "transport",
    "grade": 3
  },
  {
    "id": 2128,
    "traditional": "雜誌",
    "simplified": "杂志",
    "reading": "zázhì",
    "meaning": "journal, bulletin, mag, magazine, periodical",
    "grade": 3
  },
  {
    "id": 2129,
    "traditional": "早已",
    "simplified": "早已",
    "reading": "zǎoyǐ",
    "meaning": "already, since, long ago, long ago/since, long since",
    "grade": 3
  },
  {
    "id": 2130,
    "traditional": "造成",
    "simplified": "造成",
    "reading": "zàochéng",
    "meaning": "make_for, give rise to, create, set_up, strike, creation, cause, contribute, bring_about, procure, give, bring about, cut_out, necessitate, shape, present, bring_forth, generate, yield, make, breed",
    "grade": 3
  },
  {
    "id": 2131,
    "traditional": "責任",
    "simplified": "责任",
    "reading": "zérèn",
    "meaning": "need, care, encumbrance, buck, accountability, blame, obligation, turn, load, responsibleness, incumbrance, responsibility, onus, commission, burden, commitment, loading, trust, pigeon, charge, business, fault, place, liability, duty, guardianship",
    "grade": 3
  },
  {
    "id": 2132,
    "traditional": "增加",
    "simplified": "增加",
    "reading": "zēngjiā",
    "meaning": "increase, raise, add",
    "grade": 3
  },
  {
    "id": 2133,
    "traditional": "造",
    "simplified": "造",
    "reading": "zào",
    "meaning": "arrive at, training, create, go to, build, invent, construct, education, concoction, mint, invention, cook up, educate, establish, fabricate, make, concoct, train",
    "grade": 3
  },
  {
    "id": 2134,
    "traditional": "增長",
    "simplified": "增长",
    "reading": "zēngzhǎng",
    "meaning": "increase, grow",
    "grade": 3
  },
  {
    "id": 2135,
    "traditional": "張",
    "simplified": "张",
    "reading": "zhāng",
    "meaning": "m.[general]",
    "grade": 3
  },
  {
    "id": 2136,
    "traditional": "展開",
    "simplified": "展开",
    "reading": "zhǎnkāi",
    "meaning": "spread out, unfold, open up, launch, develop",
    "grade": 3
  },
  {
    "id": 2137,
    "traditional": "者",
    "simplified": "者",
    "reading": "zhě",
    "meaning": "nominal suffix, one who, -er",
    "grade": 3
  },
  {
    "id": 2138,
    "traditional": "真實",
    "simplified": "真实",
    "reading": "zhēnshí",
    "meaning": "true, real, authentic, factual",
    "grade": 3
  },
  {
    "id": 2139,
    "traditional": "爭取",
    "simplified": "争取",
    "reading": "zhēngqǔ",
    "meaning": "strive for, fight for, wrangle, work hard for, contest, go for, strive/fight for, contend, try_for, woo, try for, strive, win over, scramble, do all one can to",
    "grade": 3
  },
  {
    "id": 2140,
    "traditional": "爭",
    "simplified": "争",
    "reading": "zhēng",
    "meaning": "be short of, disagree, argue, duel, arguement, compete, vie, dispute, contest, fence, be wanting, contend, debate, disputation, strive",
    "grade": 3
  },
  {
    "id": 2141,
    "traditional": "照",
    "simplified": "照",
    "reading": "zhào",
    "meaning": "according to, in accordance with, towards",
    "grade": 3
  },
  {
    "id": 2142,
    "traditional": "整",
    "simplified": "整",
    "reading": "zhěng",
    "meaning": "det.: exact",
    "grade": 3
  },
  {
    "id": 2143,
    "traditional": "整理",
    "simplified": "整理",
    "reading": "zhěnglǐ",
    "meaning": "put in order, arrange",
    "grade": 3
  },
  {
    "id": 2144,
    "traditional": "整個",
    "simplified": "整个",
    "reading": "zhěnggè",
    "meaning": "whole, entire, total",
    "grade": 3
  },
  {
    "id": 2145,
    "traditional": "整齊",
    "simplified": "整齐",
    "reading": "zhěngqí",
    "meaning": "in good order, neat, tidy, even, regular",
    "grade": 3
  },
  {
    "id": 2146,
    "traditional": "整天",
    "simplified": "整天",
    "reading": "zhěngtiān",
    "meaning": "all day long, whole day",
    "grade": 3
  },
  {
    "id": 2147,
    "traditional": "整整",
    "simplified": "整整",
    "reading": "zhěngzhěng",
    "meaning": "fully, full, whole",
    "grade": 3
  },
  {
    "id": 2148,
    "traditional": "整體",
    "simplified": "整体",
    "reading": "zhěngtǐ",
    "meaning": "integrity, allness, entirety, integer, integral, monolith, entireness, ensemble, wholeness, entire, tout ensemble, whole, totality, unity",
    "grade": 3
  },
  {
    "id": 2149,
    "traditional": "正式",
    "simplified": "正式",
    "reading": "zhèngshì",
    "meaning": "formal (of actions/speeches/etc.)",
    "grade": 3
  },
  {
    "id": 2150,
    "traditional": "正",
    "simplified": "正",
    "reading": "zhèng",
    "meaning": "precisely, punctually",
    "grade": 3
  },
  {
    "id": 2151,
    "traditional": "證",
    "simplified": "证",
    "reading": "zhèng",
    "meaning": "certificate, proof, to prove, to demonstrate, to confirm, variant of 症[zheng4]",
    "grade": 3
  },
  {
    "id": 2152,
    "traditional": "證據",
    "simplified": "证据",
    "reading": "zhèngjù",
    "meaning": "support, telltale, testification, witness, case, testimonial, documentation, grounds, evidential, token, proof, earnest, testimony, cogent evidence, attestation, testament, evidence",
    "grade": 3
  },
  {
    "id": 2153,
    "traditional": "證件",
    "simplified": "证件",
    "reading": "zhèngjiàn",
    "meaning": "certificate, papers, credentials, document, ID",
    "grade": 3
  },
  {
    "id": 2154,
    "traditional": "支",
    "simplified": "支",
    "reading": "zhī",
    "meaning": "m.[general]",
    "grade": 3
  },
  {
    "id": 2155,
    "traditional": "支持",
    "simplified": "支持",
    "reading": "zhīchí",
    "meaning": "sustain, hold out, bear;support, back, stand by",
    "grade": 3
  },
  {
    "id": 2156,
    "traditional": "支付",
    "simplified": "支付",
    "reading": "zhīfù",
    "meaning": "pay (money), defray",
    "grade": 3
  },
  {
    "id": 2157,
    "traditional": "證明",
    "simplified": "证明",
    "reading": "zhèngmíng",
    "meaning": "prove, testify, bear out",
    "grade": 3
  },
  {
    "id": 2158,
    "traditional": "只",
    "simplified": "只",
    "reading": "zhǐ",
    "meaning": "only, merely",
    "grade": 3
  },
  {
    "id": 2159,
    "traditional": "直播",
    "simplified": "直播",
    "reading": "zhíbō",
    "meaning": "live broadcast (not recorded), direct Internet broadcasting, (agriculture) direct seeding",
    "grade": 3
  },
  {
    "id": 2160,
    "traditional": "直",
    "simplified": "直",
    "reading": "zhí",
    "meaning": "directly, straight, continuously, just, simply",
    "grade": 3
  },
  {
    "id": 2161,
    "traditional": "直到",
    "simplified": "直到",
    "reading": "zhídào",
    "meaning": "until, up to",
    "grade": 3
  },
  {
    "id": 2162,
    "traditional": "值得",
    "simplified": "值得",
    "reading": "zhí de",
    "meaning": "be worth, merit, pay, rate, behove, worthwhile, worth, worthy, deserve, call_for, command",
    "grade": 3
  },
  {
    "id": 2163,
    "traditional": "值",
    "simplified": "值",
    "reading": "zhí",
    "meaning": "be worth",
    "grade": 3
  },
  {
    "id": 2164,
    "traditional": "職工",
    "simplified": "职工",
    "reading": "zhígōng",
    "meaning": "workers, staff, CL:個|个[ge4]",
    "grade": 3
  },
  {
    "id": 2165,
    "traditional": "職業",
    "simplified": "职业",
    "reading": "zhíyè",
    "meaning": "metier, vocation, iceman, mystery, engagement, work, walk of life, employment, job, occupational group, ploy, craft, trade, avocation, employ, racket, pursuit, profession, situation, line, occupation, position, calling, walk_of_life, career, billet, duty, living, shop",
    "grade": 3
  },
  {
    "id": 2166,
    "traditional": "只好",
    "simplified": "只好",
    "reading": "zhǐhǎo",
    "meaning": "can only, have no choice but, have to, be forced to",
    "grade": 3
  },
  {
    "id": 2167,
    "traditional": "只有",
    "simplified": "只有",
    "reading": "zhǐyǒu",
    "meaning": "can only, have no choice but, to be forced to",
    "grade": 3
  },
  {
    "id": 2168,
    "traditional": "只是",
    "simplified": "只是",
    "reading": "zhǐshì",
    "meaning": "merely, only, just, simply be",
    "grade": 3
  },
  {
    "id": 2169,
    "traditional": "指",
    "simplified": "指",
    "reading": "zhǐ",
    "meaning": "refer to",
    "grade": 3
  },
  {
    "id": 2170,
    "traditional": "指導",
    "simplified": "指导",
    "reading": "zhǐdǎo",
    "meaning": "guide, direct",
    "grade": 3
  },
  {
    "id": 2171,
    "traditional": "指出",
    "simplified": "指出",
    "reading": "zhǐchū",
    "meaning": "point, point_out, catch_out, bespeak, indicate, denote, point out (that), note, show, designate, point out",
    "grade": 3
  },
  {
    "id": 2172,
    "traditional": "至少",
    "simplified": "至少",
    "reading": "zhìshǎo",
    "meaning": "leastwise, leastways, at (the) least, at_least, only, fully, even a little, at least, at any rate, in the least, at least, at the least",
    "grade": 3
  },
  {
    "id": 2173,
    "traditional": "至今",
    "simplified": "至今",
    "reading": "zhìjīn",
    "meaning": "thus_far, up_to_now, until_now, so far, to this day, up to now, to date, hitherto, erenow",
    "grade": 3
  },
  {
    "id": 2174,
    "traditional": "志願者",
    "simplified": "志愿者",
    "reading": "zhìyuànzhě",
    "meaning": "volunteer",
    "grade": 3
  },
  {
    "id": 2175,
    "traditional": "制度",
    "simplified": "制度",
    "reading": "zhìdù",
    "meaning": "ism, organization, dispensation, regimen, machine, regulations, form, system, regime, organisation, economy, institutional, institution",
    "grade": 3
  },
  {
    "id": 2176,
    "traditional": "制定",
    "simplified": "制定",
    "reading": "zhìdìng",
    "meaning": "formulate, draft",
    "grade": 3
  },
  {
    "id": 2177,
    "traditional": "製作",
    "simplified": "制作",
    "reading": "zhìzuò",
    "meaning": "make, manufacture",
    "grade": 3
  },
  {
    "id": 2178,
    "traditional": "志願",
    "simplified": "志愿",
    "reading": "zhìyuàn",
    "meaning": "aspiration, wish, ideal",
    "grade": 3
  },
  {
    "id": 2179,
    "traditional": "製造",
    "simplified": "制造",
    "reading": "zhìzào",
    "meaning": "make, manufacture, engineer, create, fabricate",
    "grade": 3
  },
  {
    "id": 2180,
    "traditional": "中部",
    "simplified": "中部",
    "reading": "zhōngbù",
    "meaning": "central section, middle",
    "grade": 3
  },
  {
    "id": 2181,
    "traditional": "中華民族",
    "simplified": "中华民族",
    "reading": "Zhōnghuámínzú",
    "meaning": "the Chinese nation, Ch. people",
    "grade": 3
  },
  {
    "id": 2182,
    "traditional": "鐘",
    "simplified": "钟",
    "reading": "zhōng",
    "meaning": "timekeeper, timepiece, ticker, carillon, Zhong, time, bell, handleless cup, chime, gong, carillon playing, bell ringing, clock, Surname",
    "grade": 3
  },
  {
    "id": 2183,
    "traditional": "終於",
    "simplified": "终于",
    "reading": "zhōngyú",
    "meaning": "in the last analysis, at long last, at last, lastly, ultimately, all things considered, out, on the whole, at_last, eventually, in the final analysis, finally",
    "grade": 3
  },
  {
    "id": 2184,
    "traditional": "種",
    "simplified": "种",
    "reading": "zhǒng",
    "meaning": "m.[kind]",
    "grade": 3
  },
  {
    "id": 2185,
    "traditional": "種子",
    "simplified": "种子",
    "reading": "zhǒngzi",
    "meaning": "pippin, berry, pip, edible seed, key, spore, seed, canary seed",
    "grade": 3
  },
  {
    "id": 2186,
    "traditional": "週圍",
    "simplified": "周围",
    "reading": "zhōuwéi",
    "meaning": "all around, circumference, circumambience, compass, ambit, periphery, circumambiency, round, surrounding, perimeter, environment, precinct, vicinity, circuit, ambience, around",
    "grade": 3
  },
  {
    "id": 2187,
    "traditional": "重大",
    "simplified": "重大",
    "reading": "zhòngdà",
    "meaning": "great, major, crucial, weighty, whang, fatal, significant, grave",
    "grade": 3
  },
  {
    "id": 2188,
    "traditional": "豬",
    "simplified": "猪",
    "reading": "zhū",
    "meaning": "piggy, porcine, pig, porker, hoggery, Sus scrofa, piggish, hog, piggery, swine",
    "grade": 3
  },
  {
    "id": 2189,
    "traditional": "主持",
    "simplified": "主持",
    "reading": "zhǔchí",
    "meaning": "preside, head, care of, take care of, preside over, chair, minister, sponsor, direct, uphold, direction, take charge/care of, take charge of, moderate, emcee, celebrate, manage, host, mastermind, officiate, stand for, managment, run, take charge, compere",
    "grade": 3
  },
  {
    "id": 2190,
    "traditional": "主動",
    "simplified": "主动",
    "reading": "zhǔdòng",
    "meaning": "on one's own initiative",
    "grade": 3
  },
  {
    "id": 2191,
    "traditional": "主張",
    "simplified": "主张",
    "reading": "zhǔzhāng",
    "meaning": "advocate, stand for, maintain, hold",
    "grade": 3
  },
  {
    "id": 2192,
    "traditional": "主意",
    "simplified": "主意",
    "reading": "zhǔyi",
    "meaning": "idea, plan, decision, definite view",
    "grade": 3
  },
  {
    "id": 2193,
    "traditional": "主任",
    "simplified": "主任",
    "reading": "zhǔrèn",
    "meaning": "head, principal, captain, chair, director, head teacher, chief, chairman, school principal",
    "grade": 3
  },
  {
    "id": 2194,
    "traditional": "抓",
    "simplified": "抓",
    "reading": "zhuā",
    "meaning": "clutch, cop, grab, press-gang, scrape, arrest, hent, catch, pay special attention to, be responsible for, scratch up, take charge of, paw, grasp, scratch, claw, nab, clapperclaw, glom, seize, capture, snatch, prehend, stress",
    "grade": 3
  },
  {
    "id": 2195,
    "traditional": "祝",
    "simplified": "祝",
    "reading": "zhù",
    "meaning": "bless, invoke blessing, express good wishes, wish",
    "grade": 3
  },
  {
    "id": 2196,
    "traditional": "注意",
    "simplified": "注意",
    "reading": "zhù yì",
    "meaning": "pay attention to, take note of",
    "grade": 3
  },
  {
    "id": 2197,
    "traditional": "專家",
    "simplified": "专家",
    "reading": "zhuānjiā",
    "meaning": "guru, pundit, adept, expertise, professional person, specializer, specialist, proficient, whiz, oner, expert, swami, savant, dab, professional, authority, stunner, technician",
    "grade": 3
  },
  {
    "id": 2198,
    "traditional": "專門",
    "simplified": "专门",
    "reading": "zhuānmén",
    "meaning": "special, specialized",
    "grade": 3
  },
  {
    "id": 2199,
    "traditional": "抓住",
    "simplified": "抓住",
    "reading": "zhuā zhù",
    "meaning": "clutch, grip, grab, kep, nail, grapple, get_hold_of, catch, seize hold of, take_hold_of, grip sb.'s attention, cleek, grasp, hold, nab, seize, tackle, capture, snatch, take, take hold of, prehend, catch hold of, glaum, hold on",
    "grade": 3
  },
  {
    "id": 2200,
    "traditional": "專題",
    "simplified": "专题",
    "reading": "zhuāntí",
    "meaning": "special subject/topic",
    "grade": 3
  },
  {
    "id": 2201,
    "traditional": "專業",
    "simplified": "专业",
    "reading": "zhuānyè",
    "meaning": "professional",
    "grade": 3
  },
  {
    "id": 2202,
    "traditional": "轉",
    "simplified": "转",
    "reading": "zhuǎn",
    "meaning": "turn, revolve, rotate",
    "grade": 3
  },
  {
    "id": 2203,
    "traditional": "轉變",
    "simplified": "转变",
    "reading": "zhuǎnbiàn",
    "meaning": "change, transform",
    "grade": 3
  },
  {
    "id": 2204,
    "traditional": "狀況",
    "simplified": "状况",
    "reading": "zhuàngkuàng",
    "meaning": "repair, background, picture, state (of affairs), state of affairs, status, gear, scene, setting, scope, condition, state, circumstance, trimness, context, showing, trim, factor, condition/state (of affairs), situation, shoes, consideration, place",
    "grade": 3
  },
  {
    "id": 2205,
    "traditional": "狀態",
    "simplified": "状态",
    "reading": "zhuàngtài",
    "meaning": "repair, form, lie, state (of affairs), state of affairs, fix, status, going, plight, phase, condition, state, trimness, attitude, trim, shape, situation, posture, position, fettle, mode, predicament",
    "grade": 3
  },
  {
    "id": 2206,
    "traditional": "準",
    "simplified": "准",
    "reading": "zhǔn",
    "meaning": "accurate, exact",
    "grade": 3
  },
  {
    "id": 2207,
    "traditional": "資格",
    "simplified": "资格",
    "reading": "zīge",
    "meaning": "claim, credential, prerequisite, character, capability, qualifications, requirement, qualification, capacity, root/basis part of a character, status, sufficiency, seniority, competence, fitness, competency, condition",
    "grade": 3
  },
  {
    "id": 2208,
    "traditional": "追",
    "simplified": "追",
    "reading": "zhuī",
    "meaning": "reminiscence, to pursue, course, reminisce, trace, chase after, recall, follow, chase, pursue, go after, to chase after, look into, pursuit, heel, chevy, find out, get to the bottom of, seek, run after",
    "grade": 3
  },
  {
    "id": 2209,
    "traditional": "資金",
    "simplified": "资金",
    "reading": "zījīn",
    "meaning": "principal_sum, fund, bankroll, finance, coffer, ante, grubstake, munition, wherewithal, capital, working capital",
    "grade": 3
  },
  {
    "id": 2210,
    "traditional": "自從",
    "simplified": "自从",
    "reading": "zìcóng",
    "meaning": "since",
    "grade": 3
  },
  {
    "id": 2211,
    "traditional": "子女",
    "simplified": "子女",
    "reading": "zǐnǚ",
    "meaning": "sons and daughters, children",
    "grade": 3
  },
  {
    "id": 2212,
    "traditional": "自動",
    "simplified": "自动",
    "reading": "zìdòng",
    "meaning": "automatic",
    "grade": 3
  },
  {
    "id": 2213,
    "traditional": "自然",
    "simplified": "自然",
    "reading": "zìran",
    "meaning": "natural world",
    "grade": 3
  },
  {
    "id": 2214,
    "traditional": "自覺",
    "simplified": "自觉",
    "reading": "zìjué",
    "meaning": "be conscious/aware",
    "grade": 3
  },
  {
    "id": 2215,
    "traditional": "自身",
    "simplified": "自身",
    "reading": "zìshēn",
    "meaning": "self, oneself",
    "grade": 3
  },
  {
    "id": 2216,
    "traditional": "縂",
    "simplified": "总",
    "reading": "zǒng",
    "meaning": "always, invariably, anyway, after all, inevitably, sooner or later",
    "grade": 3
  },
  {
    "id": 2217,
    "traditional": "自主",
    "simplified": "自主",
    "reading": "zìzhǔ",
    "meaning": "be one's own master, take initiative",
    "grade": 3
  },
  {
    "id": 2218,
    "traditional": "足夠",
    "simplified": "足够",
    "reading": "zúgòu",
    "meaning": "fully, enough, last, amply, suffice, sufficiently",
    "grade": 3
  },
  {
    "id": 2219,
    "traditional": "總是",
    "simplified": "总是",
    "reading": "zǒngshì",
    "meaning": "always",
    "grade": 3
  },
  {
    "id": 2220,
    "traditional": "組合",
    "simplified": "组合",
    "reading": "zǔhé",
    "meaning": "make up, compose, unite",
    "grade": 3
  },
  {
    "id": 2221,
    "traditional": "足球",
    "simplified": "足球",
    "reading": "zúqiú",
    "meaning": "soccer, football",
    "grade": 3
  },
  {
    "id": 2222,
    "traditional": "作品",
    "simplified": "作品",
    "reading": "zuòpǐn",
    "meaning": "writing, musical composition, whole kit and caboodle, works (of literature, artwork, kit and caboodle, product, whole works, creation, full treatment, kit and boodle, whole kit and boodle, works (of literature/art), composition, thing, piece of music, workpiece, output, art), written material, opus, whole shebang, whole caboodle, production, whole kit, piece, workmanship, wo",
    "grade": 3
  },
  {
    "id": 2223,
    "traditional": "左右",
    "simplified": "左右",
    "reading": "zuǒyòu",
    "meaning": "left and right",
    "grade": 3
  },
  {
    "id": 2224,
    "traditional": "總結",
    "simplified": "总结",
    "reading": "zǒngjié",
    "meaning": "to sum up, to conclude, summary, résumé, CL:個|个[ge4]",
    "grade": 3
  },
  {
    "id": 2225,
    "traditional": "做客",
    "simplified": "做客",
    "reading": "zuò kè",
    "meaning": "to be a guest or visitor",
    "grade": 3
  },
  {
    "id": 2226,
    "traditional": "作者",
    "simplified": "作者",
    "reading": "zuòzhě",
    "meaning": "composer, author, writer, wright, paragrapher, auteur, paternity",
    "grade": 3
  },
  {
    "id": 2227,
    "traditional": "阿姨",
    "simplified": "阿姨",
    "reading": "āyí",
    "meaning": "aunt, child's address to woman of similar age as parents, girl, babysitter, aunty, elderly unattached woman, cr#4 eche attendant, maiden aunt, family nurse, nursery/cr#4eche attendant, nurse, mother's sister, auntie, nursery eche attendant, child's address to woman of similar age, one's mother's sister",
    "grade": 4
  },
  {
    "id": 2228,
    "traditional": "啊",
    "simplified": "啊",
    "reading": "a",
    "meaning": "particle: used in direct address and exclamation; indicating obviousness/impatience/suggestion; used for confirmation",
    "grade": 4
  },
  {
    "id": 2229,
    "traditional": "愛國",
    "simplified": "爱国",
    "reading": "àiguó",
    "meaning": "love one's country, patriotic",
    "grade": 4
  },
  {
    "id": 2230,
    "traditional": "矮",
    "simplified": "矮",
    "reading": "ǎi",
    "meaning": "short (of stature), low",
    "grade": 4
  },
  {
    "id": 2231,
    "traditional": "愛護",
    "simplified": "爱护",
    "reading": "àihù",
    "meaning": "cherish, treasure, take good care of",
    "grade": 4
  },
  {
    "id": 2232,
    "traditional": "矮小",
    "simplified": "矮小",
    "reading": "ǎixiǎo",
    "meaning": "short and small, low and small, undersized",
    "grade": 4
  },
  {
    "id": 2233,
    "traditional": "按時",
    "simplified": "按时",
    "reading": "ànshí",
    "meaning": "on time, before deadline, on schedule",
    "grade": 4
  },
  {
    "id": 2234,
    "traditional": "安置",
    "simplified": "安置",
    "reading": "ānzhì",
    "meaning": "find place for, help settle down, arrange for",
    "grade": 4
  },
  {
    "id": 2235,
    "traditional": "暗示",
    "simplified": "暗示",
    "reading": "ànshì",
    "meaning": "(drop a) hint, suggest",
    "grade": 4
  },
  {
    "id": 2236,
    "traditional": "安",
    "simplified": "安",
    "reading": "ān",
    "meaning": "surname An, content, calm, still, quiet, safe, secure, in good health, to find a place for, to install, to fix, to fit, to bring (a charge against sb), to pacify, to harbor (good intentions), security, safety, peace, ampere",
    "grade": 4
  },
  {
    "id": 2237,
    "traditional": "百貨",
    "simplified": "百货",
    "reading": "bǎihuò",
    "meaning": "general merchandise",
    "grade": 4
  },
  {
    "id": 2238,
    "traditional": "暗",
    "simplified": "暗",
    "reading": "àn",
    "meaning": "unclear, dull, hidden, dim, dusk, secret, dark",
    "grade": 4
  },
  {
    "id": 2239,
    "traditional": "擺",
    "simplified": "摆",
    "reading": "bǎi",
    "meaning": "place, put, arrange",
    "grade": 4
  },
  {
    "id": 2240,
    "traditional": "擺動",
    "simplified": "摆动",
    "reading": "bǎidòng",
    "meaning": "to sway, to swing, to move back and forth, to oscillate",
    "grade": 4
  },
  {
    "id": 2241,
    "traditional": "巴士",
    "simplified": "巴士",
    "reading": "bāshì",
    "meaning": "bus",
    "grade": 4
  },
  {
    "id": 2242,
    "traditional": "擺脫",
    "simplified": "摆脱",
    "reading": "bǎi tuō",
    "meaning": "disembroil, free oneself, cast/shake off, fling_off, break away from, shake_off, disinvolve, disentangle, shake off, shift, wangle, cast off, extricate oneself from, shake, waste, free oneself from, disburden, ditch, manage, lose, cast, unload, extricate oneself, prescind, unship, slip, wriggle, shunt",
    "grade": 4
  },
  {
    "id": 2243,
    "traditional": "辦事",
    "simplified": "办事",
    "reading": "bàn shì",
    "meaning": "dispose, handle affairs, work",
    "grade": 4
  },
  {
    "id": 2244,
    "traditional": "包裹",
    "simplified": "包裹",
    "reading": "bāoguǒ",
    "meaning": "to wrap up, to bind up, bundle, parcel, package, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 2245,
    "traditional": "敗",
    "simplified": "败",
    "reading": "bài",
    "meaning": "defeat, fail, lose, counteract, be defeated, beat, spoil, decay, wither",
    "grade": 4
  },
  {
    "id": 2246,
    "traditional": "包括",
    "simplified": "包括",
    "reading": "bāokuò",
    "meaning": "consist_of, consist of, subtend, embrace, comprise, incorporate, embody, involve, count, subsume, take_in, comprehend, incorporation, include, comprize, run, cover, package, consist in",
    "grade": 4
  },
  {
    "id": 2247,
    "traditional": "寶",
    "simplified": "宝",
    "reading": "bǎo",
    "meaning": "treasure",
    "grade": 4
  },
  {
    "id": 2248,
    "traditional": "包含",
    "simplified": "包含",
    "reading": "bāohán",
    "meaning": "comprehend, consist of, subtend, imply, boast, go, colligate, include, hold, encompass, embrace, bear, carry, comprise, incorporate, cover, embody, connote, consist in, house, subsume, contain",
    "grade": 4
  },
  {
    "id": 2249,
    "traditional": "寶寶",
    "simplified": "宝宝",
    "reading": "bǎobao",
    "meaning": "pet, sonny, darling baby, precious baby, baby, precious, poppet, sweetie, precious/darling baby",
    "grade": 4
  },
  {
    "id": 2250,
    "traditional": "寶貝",
    "simplified": "宝贝",
    "reading": "bǎobèi",
    "meaning": "treasured object, treasure, darling, baby, cowry, good-for-nothing, odd character",
    "grade": 4
  },
  {
    "id": 2251,
    "traditional": "寶貴",
    "simplified": "宝贵",
    "reading": "bǎoguì",
    "meaning": "valuable, precious",
    "grade": 4
  },
  {
    "id": 2252,
    "traditional": "寶石",
    "simplified": "宝石",
    "reading": "bǎoshí",
    "meaning": "precious stone, gem",
    "grade": 4
  },
  {
    "id": 2253,
    "traditional": "保守",
    "simplified": "保守",
    "reading": "bǎoshǒu",
    "meaning": "conservative",
    "grade": 4
  },
  {
    "id": 2254,
    "traditional": "抱",
    "simplified": "抱",
    "reading": "bào",
    "meaning": "hold/carry in arms, adopt, hatch, brood",
    "grade": 4
  },
  {
    "id": 2255,
    "traditional": "背景",
    "simplified": "背景",
    "reading": "bèijǐng",
    "meaning": "context, circumstance, locale, background, ground, stage setting, mise en scene, groundwork, background knowledge, frame, milieu, backcloth, scene, setting, backdrop",
    "grade": 4
  },
  {
    "id": 2256,
    "traditional": "倍",
    "simplified": "倍",
    "reading": "bèi",
    "meaning": "#NAME?",
    "grade": 4
  },
  {
    "id": 2257,
    "traditional": "保密",
    "simplified": "保密",
    "reading": "bǎo mì",
    "meaning": "maintain secrecy, hide, conceal, keep sth. secret, keep secret, hold_back",
    "grade": 4
  },
  {
    "id": 2258,
    "traditional": "本科",
    "simplified": "本科",
    "reading": "běnkē",
    "meaning": "undergraduate course, undergraduate (attributive)",
    "grade": 4
  },
  {
    "id": 2259,
    "traditional": "薄",
    "simplified": "薄",
    "reading": "báo",
    "meaning": "lacking in warmth, infertile, light, cold, weak, thin, despise, flimsy",
    "grade": 4
  },
  {
    "id": 2260,
    "traditional": "比分",
    "simplified": "比分",
    "reading": "bǐfēn",
    "meaning": "score",
    "grade": 4
  },
  {
    "id": 2261,
    "traditional": "畢業",
    "simplified": "毕业",
    "reading": "bì yè",
    "meaning": "graduate, finish school",
    "grade": 4
  },
  {
    "id": 2262,
    "traditional": "笨",
    "simplified": "笨",
    "reading": "bèn",
    "meaning": "dull, cumbersome, wooden-headed, clumsy, stupid, awkward",
    "grade": 4
  },
  {
    "id": 2263,
    "traditional": "畢業生",
    "simplified": "毕业生",
    "reading": "bìyèshēng",
    "meaning": "alumnus, postgraduate, grad, alum, alumna, graduating class, graduate",
    "grade": 4
  },
  {
    "id": 2264,
    "traditional": "避",
    "simplified": "避",
    "reading": "bì",
    "meaning": "avoid, preclude, evade, repel, prevent, forbid, ward off, shun, keep away, forestall, foreclose, stay away from",
    "grade": 4
  },
  {
    "id": 2265,
    "traditional": "避免",
    "simplified": "避免",
    "reading": "bìmiǎn",
    "meaning": "forbear, preclude, refrain_from, forebear, escape, help, elude, leapfrog, refrain from, stave_off, evite, shun, obviate, avoid, evade, waive, help oneself, ward_off, ward, miss, avert, ward off, balk, refrain",
    "grade": 4
  },
  {
    "id": 2266,
    "traditional": "被迫",
    "simplified": "被迫",
    "reading": "bèipò",
    "meaning": "forced, be forced, be compelled/forced, be compelled",
    "grade": 4
  },
  {
    "id": 2267,
    "traditional": "辯論",
    "simplified": "辩论",
    "reading": "biànlùn",
    "meaning": "argue, debate",
    "grade": 4
  },
  {
    "id": 2268,
    "traditional": "標志",
    "simplified": "标志",
    "reading": "biāozhì",
    "meaning": "earmark, banner, standing for, logo, symbol, marker, stamp, sign, symbolization, mark, emblem, seal, logotype, symbolizing, blip, marking, insignia",
    "grade": 4
  },
  {
    "id": 2269,
    "traditional": "編",
    "simplified": "编",
    "reading": "biān",
    "meaning": "group, arrangement, arrange, invent, entwine, weave, organize, edit, compilation, write, invention, pleach, cook up, plait, make up, braid, raddle, fabricate, compose, compile, fabrication",
    "grade": 4
  },
  {
    "id": 2270,
    "traditional": "表情",
    "simplified": "表情",
    "reading": "biǎoqíng",
    "meaning": "brow, air, countenance, glow, look, cast, visage, expression, facial expression, gesture, business, dramatics, facial gesture",
    "grade": 4
  },
  {
    "id": 2271,
    "traditional": "表揚",
    "simplified": "表扬",
    "reading": "biǎoyáng",
    "meaning": "praise, commend",
    "grade": 4
  },
  {
    "id": 2272,
    "traditional": "別",
    "simplified": "别",
    "reading": "bié",
    "meaning": "don't",
    "grade": 4
  },
  {
    "id": 2273,
    "traditional": "冰",
    "simplified": "冰",
    "reading": "bīng",
    "meaning": "ice",
    "grade": 4
  },
  {
    "id": 2274,
    "traditional": "冰箱",
    "simplified": "冰箱",
    "reading": "bīngxiāng",
    "meaning": "frig, electric refrigerator, fridge, freezer, refrigerator, deepfreeze, icebox, reefer, cooler, refrigeratory",
    "grade": 4
  },
  {
    "id": 2275,
    "traditional": "冰雪",
    "simplified": "冰雪",
    "reading": "bīngxuě",
    "meaning": "ice and snow",
    "grade": 4
  },
  {
    "id": 2276,
    "traditional": "兵",
    "simplified": "兵",
    "reading": "bīng",
    "meaning": "fighter, troops, armed force, arms, dogface, pawn in Ch. chess, military, weapons, private, soldier, ranker, joe, army, pawn, rank-and-file soldier",
    "grade": 4
  },
  {
    "id": 2277,
    "traditional": "并",
    "simplified": "并",
    "reading": "bìng",
    "meaning": "to be side by side, simultaneously, and",
    "grade": 4
  },
  {
    "id": 2278,
    "traditional": "不要緊",
    "simplified": "不要紧",
    "reading": "bù yàojǐn",
    "meaning": "unimportant, not serious, it doesn't matter, never mind, it looks all right, but",
    "grade": 4
  },
  {
    "id": 2279,
    "traditional": "不管",
    "simplified": "不管",
    "reading": "bùguǎn",
    "meaning": "conj.: no matter, regardless of",
    "grade": 4
  },
  {
    "id": 2280,
    "traditional": "不在乎",
    "simplified": "不在乎",
    "reading": "bù zàihu",
    "meaning": "not to care",
    "grade": 4
  },
  {
    "id": 2281,
    "traditional": "布置",
    "simplified": "布置",
    "reading": "bùzhì",
    "meaning": "dispose, embellish, arrange, set up, lay, assign, fix up, upholster",
    "grade": 4
  },
  {
    "id": 2282,
    "traditional": "不然",
    "simplified": "不然",
    "reading": "bùrán",
    "meaning": "conj.: otherwise, if not, or else",
    "grade": 4
  },
  {
    "id": 2283,
    "traditional": "步行",
    "simplified": "步行",
    "reading": "bùxíng",
    "meaning": "hoof it, tread, ambulate, leg it, foot, pedestrianize, footslog, tramp, go on foot, hoof, step, locomote, walk",
    "grade": 4
  },
  {
    "id": 2284,
    "traditional": "才",
    "simplified": "才",
    "reading": "cái",
    "meaning": "then and only then, just now, only (before a number)",
    "grade": 4
  },
  {
    "id": 2285,
    "traditional": "擦",
    "simplified": "擦",
    "reading": "cā",
    "meaning": "swab, shave, spread on, scrape, apply sth. on, spread sth. on, touch lightly, wipe, grate, rub, put/spread on, brush past, brush, obliterate, chafe, efface, sweep, scratch, fray, put on, apply, besmear, put, polish, scrape into shreds, fret, scrub, mop, erase",
    "grade": 4
  },
  {
    "id": 2286,
    "traditional": "材料",
    "simplified": "材料",
    "reading": "cáiliào",
    "meaning": "making, aggregate, stuff, ingredients, food, ingredient, data, fodder, material, datum, stock, makings, data point, timber",
    "grade": 4
  },
  {
    "id": 2287,
    "traditional": "財富",
    "simplified": "财富",
    "reading": "cáifù",
    "meaning": "opulence, pile, worth, fortune, moneybag, riches, mammon, substance, wealth, wealthy, gold, lucre, means, shekel, pelf, opulency, money, oof, nabobery",
    "grade": 4
  },
  {
    "id": 2288,
    "traditional": "財產",
    "simplified": "财产",
    "reading": "cáichǎn",
    "meaning": "aught, belonging, fortune, material possession, cash, riches, substance, assets, property, wealth, belongings, estate, seizin, thing, holding, possession, means, goods, asset, pelf, money, propertied, seisin, res, having",
    "grade": 4
  },
  {
    "id": 2289,
    "traditional": "參考",
    "simplified": "参考",
    "reading": "cānkǎo",
    "meaning": "consult, refer to",
    "grade": 4
  },
  {
    "id": 2290,
    "traditional": "參與",
    "simplified": "参与",
    "reading": "cānyù",
    "meaning": "participate in",
    "grade": 4
  },
  {
    "id": 2291,
    "traditional": "採訪",
    "simplified": "采访",
    "reading": "cǎifǎng",
    "meaning": "cover, interview, gather news",
    "grade": 4
  },
  {
    "id": 2292,
    "traditional": "操場",
    "simplified": "操场",
    "reading": "cāochǎng",
    "meaning": "schoolyard, school ground, school yard, drill ground, sportsground, playing field, playground, sports ground",
    "grade": 4
  },
  {
    "id": 2293,
    "traditional": "操作",
    "simplified": "操作",
    "reading": "cāozuò",
    "meaning": "operate, manipulate",
    "grade": 4
  },
  {
    "id": 2294,
    "traditional": "測量",
    "simplified": "测量",
    "reading": "cèliáng",
    "meaning": "survey, measure",
    "grade": 4
  },
  {
    "id": 2295,
    "traditional": "測",
    "simplified": "测",
    "reading": "cè",
    "meaning": "measure out, conjecture, measure, infer, survey, fathom",
    "grade": 4
  },
  {
    "id": 2296,
    "traditional": "測試",
    "simplified": "测试",
    "reading": "cèshì",
    "meaning": "test",
    "grade": 4
  },
  {
    "id": 2297,
    "traditional": "茶葉",
    "simplified": "茶叶",
    "reading": "cháyè",
    "meaning": "tea, tea leaf, tea leaves",
    "grade": 4
  },
  {
    "id": 2298,
    "traditional": "產品",
    "simplified": "产品",
    "reading": "chǎnpǐn",
    "meaning": "baby, brand, output, product, produce, end product, merchandise, fruitage, growth, manufacture, wares, production",
    "grade": 4
  },
  {
    "id": 2299,
    "traditional": "曾",
    "simplified": "曾",
    "reading": "céng",
    "meaning": "once, ever, before",
    "grade": 4
  },
  {
    "id": 2300,
    "traditional": "長途",
    "simplified": "长途",
    "reading": "chángtú",
    "meaning": "long-distance, long distance",
    "grade": 4
  },
  {
    "id": 2301,
    "traditional": "唱片",
    "simplified": "唱片",
    "reading": "chàngpiàn",
    "meaning": "pressing, waxing, cut, disc, phonogram, phonograph record, record, disk, wax, phonorecord, platter, recording, gramophone record, phonograph recording",
    "grade": 4
  },
  {
    "id": 2302,
    "traditional": "常識",
    "simplified": "常识",
    "reading": "chángshí",
    "meaning": "nous, reason, mother_wit, general knowledge, common_sense, mother wit, horse sense, public knowledge, common sense, sense, good sense, gumption, commonsense, wisdom",
    "grade": 4
  },
  {
    "id": 2303,
    "traditional": "抄寫",
    "simplified": "抄写",
    "reading": "chāoxiě",
    "meaning": "to copy, to transcribe",
    "grade": 4
  },
  {
    "id": 2304,
    "traditional": "潮流",
    "simplified": "潮流",
    "reading": "cháoliú",
    "meaning": "stream, tidal flow, tidal_current, tideway, trend, movement, wind, sea, fashion, tide, tidal current",
    "grade": 4
  },
  {
    "id": 2305,
    "traditional": "抄",
    "simplified": "抄",
    "reading": "chāo",
    "meaning": "to make a copy, to plagiarize, to search and seize, to raid, to grab, to go off with, to take a shortcut, to make a turning move, to fold one's arms",
    "grade": 4
  },
  {
    "id": 2306,
    "traditional": "潮",
    "simplified": "潮",
    "reading": "cháo",
    "meaning": "tide, current, damp, moist, humid",
    "grade": 4
  },
  {
    "id": 2307,
    "traditional": "徹底",
    "simplified": "彻底",
    "reading": "chèdǐ",
    "meaning": "thorough, drastic, exhaustive, outright, complete",
    "grade": 4
  },
  {
    "id": 2308,
    "traditional": "潮濕",
    "simplified": "潮湿",
    "reading": "cháoshī",
    "meaning": "damp, moist",
    "grade": 4
  },
  {
    "id": 2309,
    "traditional": "沉",
    "simplified": "沉",
    "reading": "chén",
    "meaning": "see 黑沉沉[hei1 chen1 chen1], to submerge, to immerse, to sink, to keep down, to lower, to drop, deep, profound, heavy",
    "grade": 4
  },
  {
    "id": 2310,
    "traditional": "沉默",
    "simplified": "沉默",
    "reading": "chénmò",
    "meaning": "taciturn, uncommunicative, silent",
    "grade": 4
  },
  {
    "id": 2311,
    "traditional": "沉重",
    "simplified": "沉重",
    "reading": "chénzhòng",
    "meaning": "heavy, hard, serious, critical",
    "grade": 4
  },
  {
    "id": 2312,
    "traditional": "成人",
    "simplified": "成人",
    "reading": "chéngrén",
    "meaning": "grownup, adult, majority, manhood, grown-up",
    "grade": 4
  },
  {
    "id": 2313,
    "traditional": "誠實",
    "simplified": "诚实",
    "reading": "chéngshi",
    "meaning": "honest",
    "grade": 4
  },
  {
    "id": 2314,
    "traditional": "稱贊",
    "simplified": "称赞",
    "reading": "chēngzàn",
    "meaning": "to praise, to acclaim, to commend, to compliment",
    "grade": 4
  },
  {
    "id": 2315,
    "traditional": "承擔",
    "simplified": "承担",
    "reading": "chéngdān",
    "meaning": "bear, undertake, assume",
    "grade": 4
  },
  {
    "id": 2316,
    "traditional": "承認",
    "simplified": "承认",
    "reading": "chéngrèn",
    "meaning": "receive, acknowledge, okay, approbate, validate, recognize, avow, admittance, count, confess, sanctify, grant, countersign, avouch, admit, acknowledgement, reckon, own, sanction, give diplomatic recognition, concede, cede, give, ratify, agree, recognise, accept, recognition, yield, allow, endorse, profess",
    "grade": 4
  },
  {
    "id": 2317,
    "traditional": "誠信",
    "simplified": "诚信",
    "reading": "chéngxìn",
    "meaning": "genuine, honest, in good faith, honesty, integrity",
    "grade": 4
  },
  {
    "id": 2318,
    "traditional": "程序",
    "simplified": "程序",
    "reading": "chéngxù",
    "meaning": "rules of order, parliamentary law, instruction, process, procedure, agenda, instance, parliamentary procedure, system, order, proceeding, programme, method, sequence, course, program",
    "grade": 4
  },
  {
    "id": 2319,
    "traditional": "吃驚",
    "simplified": "吃惊",
    "reading": "chī jīng",
    "meaning": "to be startled, to be shocked, to be amazed",
    "grade": 4
  },
  {
    "id": 2320,
    "traditional": "承受",
    "simplified": "承受",
    "reading": "chéngshòu",
    "meaning": "bear, support, endure, inherit (legacy/etc.)",
    "grade": 4
  },
  {
    "id": 2321,
    "traditional": "尺",
    "simplified": "尺",
    "reading": "chǐ",
    "meaning": "m.[standard]",
    "grade": 4
  },
  {
    "id": 2322,
    "traditional": "遲到",
    "simplified": "迟到",
    "reading": "chí dào",
    "meaning": "tardy, be late, arrive late, be/come/arrive late, come, late, come/arrive late, be",
    "grade": 4
  },
  {
    "id": 2323,
    "traditional": "沖",
    "simplified": "冲",
    "reading": "chōng",
    "meaning": "charge, rush, dash",
    "grade": 4
  },
  {
    "id": 2324,
    "traditional": "尺子",
    "simplified": "尺子",
    "reading": "chǐzi",
    "meaning": "rule, ruler (measuring instrument), CL:把[ba3]",
    "grade": 4
  },
  {
    "id": 2325,
    "traditional": "充電",
    "simplified": "充电",
    "reading": "chōng diàn",
    "meaning": "charge, electricize, refresh",
    "grade": 4
  },
  {
    "id": 2326,
    "traditional": "充電器",
    "simplified": "充电器",
    "reading": "chōngdiànqì",
    "meaning": "battery charger",
    "grade": 4
  },
  {
    "id": 2327,
    "traditional": "尺寸",
    "simplified": "尺寸",
    "reading": "chǐcun",
    "meaning": "measurement, dimension",
    "grade": 4
  },
  {
    "id": 2328,
    "traditional": "蟲子",
    "simplified": "虫子",
    "reading": "chóngzi",
    "meaning": "insect, bug, worm, CL:條|条[tiao2],隻|只[zhi1]",
    "grade": 4
  },
  {
    "id": 2329,
    "traditional": "充分",
    "simplified": "充分",
    "reading": "chōngfèn",
    "meaning": "full, ample, abundant",
    "grade": 4
  },
  {
    "id": 2330,
    "traditional": "抽",
    "simplified": "抽",
    "reading": "chōu",
    "meaning": "lash, whip, thrash",
    "grade": 4
  },
  {
    "id": 2331,
    "traditional": "抽獎",
    "simplified": "抽奖",
    "reading": "chōu jiǎng",
    "meaning": "to draw a prize, a lottery, a raffle",
    "grade": 4
  },
  {
    "id": 2332,
    "traditional": "出口",
    "simplified": "出口",
    "reading": "chū kǒu",
    "meaning": "export",
    "grade": 4
  },
  {
    "id": 2333,
    "traditional": "抽煙",
    "simplified": "抽烟",
    "reading": "chōu yān",
    "meaning": "smoke (pipe/etc.)",
    "grade": 4
  },
  {
    "id": 2334,
    "traditional": "出色",
    "simplified": "出色",
    "reading": "chūsè",
    "meaning": "outstanding, remarkable, splendid",
    "grade": 4
  },
  {
    "id": 2335,
    "traditional": "出席",
    "simplified": "出席",
    "reading": "chū xí",
    "meaning": "attend, be present",
    "grade": 4
  },
  {
    "id": 2336,
    "traditional": "處於",
    "simplified": "处于",
    "reading": "chǔyú",
    "meaning": "go, be in, be (in a certain condition)",
    "grade": 4
  },
  {
    "id": 2337,
    "traditional": "出售",
    "simplified": "出售",
    "reading": "chūshòu",
    "meaning": "offer for sale, sell",
    "grade": 4
  },
  {
    "id": 2338,
    "traditional": "處",
    "simplified": "处",
    "reading": "chù",
    "meaning": "m.[general]",
    "grade": 4
  },
  {
    "id": 2339,
    "traditional": "穿上",
    "simplified": "穿上",
    "reading": "chuānshang",
    "meaning": "assume, wear, put_on, slip, enclothe, put on",
    "grade": 4
  },
  {
    "id": 2340,
    "traditional": "窗戶",
    "simplified": "窗户",
    "reading": "chuānghu",
    "meaning": "window, casement",
    "grade": 4
  },
  {
    "id": 2341,
    "traditional": "窗臺",
    "simplified": "窗台",
    "reading": "chuāngtái",
    "meaning": "window sill, window ledge",
    "grade": 4
  },
  {
    "id": 2342,
    "traditional": "傳統",
    "simplified": "传统",
    "reading": "chuántǒng",
    "meaning": "rule, patrimony, legacy, custom, heritage, convention, conventionality, routine, normal practice, common practice, tradition",
    "grade": 4
  },
  {
    "id": 2343,
    "traditional": "純",
    "simplified": "纯",
    "reading": "chún",
    "meaning": "pure, simple",
    "grade": 4
  },
  {
    "id": 2344,
    "traditional": "純淨水",
    "simplified": "纯净水",
    "reading": "chúnjìngshuǐ",
    "meaning": "pure water",
    "grade": 4
  },
  {
    "id": 2345,
    "traditional": "窗子",
    "simplified": "窗子",
    "reading": "chuāngzi",
    "meaning": "window",
    "grade": 4
  },
  {
    "id": 2346,
    "traditional": "春季",
    "simplified": "春季",
    "reading": "chūnjì",
    "meaning": "springtime",
    "grade": 4
  },
  {
    "id": 2347,
    "traditional": "詞匯",
    "simplified": "词汇",
    "reading": "cíhuì",
    "meaning": "mental lexicon, vocabulary, wordbook, lexicon, word-stock, lexis, words and phrases",
    "grade": 4
  },
  {
    "id": 2348,
    "traditional": "此",
    "simplified": "此",
    "reading": "cǐ",
    "meaning": "this, det.: this, here",
    "grade": 4
  },
  {
    "id": 2349,
    "traditional": "此外",
    "simplified": "此外",
    "reading": "cǐwài",
    "meaning": "conj.: besides, in addition, moreover",
    "grade": 4
  },
  {
    "id": 2350,
    "traditional": "次",
    "simplified": "次",
    "reading": "cì",
    "meaning": "order, sequence, next",
    "grade": 4
  },
  {
    "id": 2351,
    "traditional": "刺激",
    "simplified": "刺激",
    "reading": "cìjī",
    "meaning": "stimulate, provoke, irritate, upset",
    "grade": 4
  },
  {
    "id": 2352,
    "traditional": "刺",
    "simplified": "刺",
    "reading": "cì",
    "meaning": "stab, prick, assassinate, irritate, stimulate, criticize",
    "grade": 4
  },
  {
    "id": 2353,
    "traditional": "從此",
    "simplified": "从此",
    "reading": "cóngcǐ",
    "meaning": "hereon, hence, henceforth, thereupon, therefrom, from now on, from then on",
    "grade": 4
  },
  {
    "id": 2354,
    "traditional": "粗",
    "simplified": "粗",
    "reading": "cū",
    "meaning": "wide (in diameter), thick",
    "grade": 4
  },
  {
    "id": 2355,
    "traditional": "粗心",
    "simplified": "粗心",
    "reading": "cūxīn",
    "meaning": "careless, thoughtless",
    "grade": 4
  },
  {
    "id": 2356,
    "traditional": "促銷",
    "simplified": "促销",
    "reading": "cùxiāo",
    "meaning": "merchandise, sell",
    "grade": 4
  },
  {
    "id": 2357,
    "traditional": "措施",
    "simplified": "措施",
    "reading": "cuòshī",
    "meaning": "measure, demarche, corrective, move, step",
    "grade": 4
  },
  {
    "id": 2358,
    "traditional": "促使",
    "simplified": "促使",
    "reading": "cùshǐ",
    "meaning": "incite, prod, prompt, spur, send, motive, induce, trigger, urge, bring on, cause, propel, shame, instigate, impel, actuate, motivate, bear on, push, get, move",
    "grade": 4
  },
  {
    "id": 2359,
    "traditional": "打",
    "simplified": "打",
    "reading": "dǎ",
    "meaning": "generalized verb of doing with specific meaning determined by its object, strike, hit, fight, construct, forge, mix",
    "grade": 4
  },
  {
    "id": 2360,
    "traditional": "促進",
    "simplified": "促进",
    "reading": "cùjìn",
    "meaning": "rush, help, step_up, advancement, progress, acceleration, subserve, encourage, facilitate, anticipate, accelerate, assist, fillip, expedite, advance, promotive, boost, further, provoke, hurry, redound, cultivate, induce, hasten, catalyze, speed, promote, contribute, impel, promotion, push, forward, stimulate, whet",
    "grade": 4
  },
  {
    "id": 2361,
    "traditional": "答案",
    "simplified": "答案",
    "reading": "dáàn",
    "meaning": "result, script, resolution, solution, answer, key",
    "grade": 4
  },
  {
    "id": 2362,
    "traditional": "打雷",
    "simplified": "打雷",
    "reading": "dǎ léi",
    "meaning": "to rumble with thunder, clap of thunder",
    "grade": 4
  },
  {
    "id": 2363,
    "traditional": "打敗",
    "simplified": "打败",
    "reading": "dǎ bài",
    "meaning": "rout, outplay, whip, scupper, wallop, mop up, finish, suffer a defeat, overrule, overwhelm, flog, rack up, whop, be beaten, overpower, subdue, overmatch, snooker, be defeated, larrup, outfight, overmaster, floor, whup, knock_off, best, drub, defeat, euchre, confound, lick, fall, outrival, worst, overthrow, convince, vanquish, beat, pip",
    "grade": 4
  },
  {
    "id": 2364,
    "traditional": "打掃",
    "simplified": "打扫",
    "reading": "dǎsǎo",
    "meaning": "to clean, to sweep",
    "grade": 4
  },
  {
    "id": 2365,
    "traditional": "打折",
    "simplified": "打折",
    "reading": "dǎ zhé",
    "meaning": "to give a discount",
    "grade": 4
  },
  {
    "id": 2366,
    "traditional": "打針",
    "simplified": "打针",
    "reading": "dǎ zhēn",
    "meaning": "to give or have an injection",
    "grade": 4
  },
  {
    "id": 2367,
    "traditional": "大多",
    "simplified": "大多",
    "reading": "dàduō",
    "meaning": "for the most part, many, most, the greater part, mostly",
    "grade": 4
  },
  {
    "id": 2368,
    "traditional": "大巴",
    "simplified": "大巴",
    "reading": "dàbā",
    "meaning": "a big coach, tourist bus",
    "grade": 4
  },
  {
    "id": 2369,
    "traditional": "大方",
    "simplified": "大方",
    "reading": "dàfang",
    "meaning": "expert, scholar, mother earth, a type of green tea, generous, magnanimous, stylish, in good taste, easy-mannered, natural and relaxed",
    "grade": 4
  },
  {
    "id": 2370,
    "traditional": "大哥",
    "simplified": "大哥",
    "reading": "dàgē",
    "meaning": "eldest brother, elder brother, gang leader",
    "grade": 4
  },
  {
    "id": 2371,
    "traditional": "大規模",
    "simplified": "大规模",
    "reading": "dàguīmó",
    "meaning": "large scale, extensive, wide scale, broad scale",
    "grade": 4
  },
  {
    "id": 2372,
    "traditional": "大會",
    "simplified": "大会",
    "reading": "dàhuì",
    "meaning": "synod, moot, conference, plenary meeting, congress, rally, plenum, mass meeting, plenary session",
    "grade": 4
  },
  {
    "id": 2373,
    "traditional": "大姐",
    "simplified": "大姐",
    "reading": "dàjiě",
    "meaning": "big sister, elder sister, older sister (also polite term of address for a girl or woman slightly older than the speaker)",
    "grade": 4
  },
  {
    "id": 2374,
    "traditional": "大樓",
    "simplified": "大楼",
    "reading": "dàlóu",
    "meaning": "mansion, massif, hall, block, manse, edifice, building, multi-storied building, residence, mansion house",
    "grade": 4
  },
  {
    "id": 2375,
    "traditional": "大媽",
    "simplified": "大妈",
    "reading": "dàmā",
    "meaning": "father's elder brother's wife, aunt (affectionate term for an elderly woman)",
    "grade": 4
  },
  {
    "id": 2376,
    "traditional": "大型",
    "simplified": "大型",
    "reading": "dàxíng",
    "meaning": "large-size (machines/factories/etc.)",
    "grade": 4
  },
  {
    "id": 2377,
    "traditional": "大陸",
    "simplified": "大陆",
    "reading": "dàlù",
    "meaning": "Ch. mainland",
    "grade": 4
  },
  {
    "id": 2378,
    "traditional": "大眾",
    "simplified": "大众",
    "reading": "dàzhòng",
    "meaning": "the broad masses, roughscuff, the public, herd, concourse, commonality, riffraff, masses, commonness, the masses, throng, multitude, commonage, commonalty, plebeian, plebs, horde, the people/masses, community, the people, million, mass, public",
    "grade": 4
  },
  {
    "id": 2379,
    "traditional": "大爺",
    "simplified": "大爷",
    "reading": "dàye",
    "meaning": "arrogant idler, self-centered show-off, (coll.) father's older brother, uncle, term of respect for older man",
    "grade": 4
  },
  {
    "id": 2380,
    "traditional": "代替",
    "simplified": "代替",
    "reading": "dàitì",
    "meaning": "fill in, replace, supply, supersede, replacement, substitute for, surrogate, oust, substitute, stand_in, stand in, cover, supplant, take the place of, fill_in, sub",
    "grade": 4
  },
  {
    "id": 2381,
    "traditional": "袋",
    "simplified": "袋",
    "reading": "dài",
    "meaning": "pouch, bag, sack, pocket",
    "grade": 4
  },
  {
    "id": 2382,
    "traditional": "待遇",
    "simplified": "待遇",
    "reading": "dàiyù",
    "meaning": "treatment (salary/etc.)",
    "grade": 4
  },
  {
    "id": 2383,
    "traditional": "戴",
    "simplified": "戴",
    "reading": "dài",
    "meaning": "wear, put on (of accessories)",
    "grade": 4
  },
  {
    "id": 2384,
    "traditional": "擔任",
    "simplified": "担任",
    "reading": "dānrèn",
    "meaning": "hold the post of, take charge of, assume, take_charge, fill, shoulder, adopt, skipper, assume the office of, assume office of, act_as, take, act as, play, undertake, take over, take on",
    "grade": 4
  },
  {
    "id": 2385,
    "traditional": "擔保",
    "simplified": "担保",
    "reading": "dānbǎo",
    "meaning": "to guarantee, to vouch for",
    "grade": 4
  },
  {
    "id": 2386,
    "traditional": "擔心",
    "simplified": "担心",
    "reading": "dān xīn",
    "meaning": "worry, feel anxious",
    "grade": 4
  },
  {
    "id": 2387,
    "traditional": "單純",
    "simplified": "单纯",
    "reading": "dānchún",
    "meaning": "simple, pure, plain, artless",
    "grade": 4
  },
  {
    "id": 2388,
    "traditional": "單",
    "simplified": "单",
    "reading": "dān",
    "meaning": "singly, alone",
    "grade": 4
  },
  {
    "id": 2389,
    "traditional": "單調",
    "simplified": "单调",
    "reading": "dāndiào",
    "meaning": "monotonous",
    "grade": 4
  },
  {
    "id": 2390,
    "traditional": "單獨",
    "simplified": "单独",
    "reading": "dāndú",
    "meaning": "tete-a-tete, apart, unaccompanied, individually, singly, solely, alone, on one's own",
    "grade": 4
  },
  {
    "id": 2391,
    "traditional": "導遊",
    "simplified": "导游",
    "reading": "dǎoyóu",
    "meaning": "tour guide, guidebook, to conduct a tour",
    "grade": 4
  },
  {
    "id": 2392,
    "traditional": "倒閉",
    "simplified": "倒闭",
    "reading": "dǎobì",
    "meaning": "to go bankrupt, to close down",
    "grade": 4
  },
  {
    "id": 2393,
    "traditional": "淡",
    "simplified": "淡",
    "reading": "dàn",
    "meaning": "tasteless, light, wishy-washy, pale, thin, weak",
    "grade": 4
  },
  {
    "id": 2394,
    "traditional": "倒車",
    "simplified": "倒车",
    "reading": "dǎo chē",
    "meaning": "to change buses, trains etc, to reverse (a vehicle), to drive backwards",
    "grade": 4
  },
  {
    "id": 2395,
    "traditional": "得意",
    "simplified": "得意",
    "reading": "déyì",
    "meaning": "be proud of; be exalted; be complacent",
    "grade": 4
  },
  {
    "id": 2396,
    "traditional": "得",
    "simplified": "得",
    "reading": "de",
    "meaning": "should be, need",
    "grade": 4
  },
  {
    "id": 2397,
    "traditional": "導致",
    "simplified": "导致",
    "reading": "dǎozhì",
    "meaning": "bring, lead, bring_about, lead to, incite, result in, conduce, bring about, produce, bring_on, render, set off, spark_off, inspire, inflict, cause, land",
    "grade": 4
  },
  {
    "id": 2398,
    "traditional": "燈光",
    "simplified": "灯光",
    "reading": "dēngguāng",
    "meaning": "light, lighting, the light of a lamp, illuminance, glim, illumination, lamplight",
    "grade": 4
  },
  {
    "id": 2399,
    "traditional": "登",
    "simplified": "登",
    "reading": "dēng",
    "meaning": "to scale (a height), to ascend, to mount, to publish or record, to enter (e.g. in a register), to press down with the foot, to step or tread on, to put on (shoes or trousers) (dialect), to be gathered and taken to the threshing ground (old)",
    "grade": 4
  },
  {
    "id": 2400,
    "traditional": "登記",
    "simplified": "登记",
    "reading": "dēng jì",
    "meaning": "register, check in",
    "grade": 4
  },
  {
    "id": 2401,
    "traditional": "登錄",
    "simplified": "登录",
    "reading": "dēnglù",
    "meaning": "to register, to log in",
    "grade": 4
  },
  {
    "id": 2402,
    "traditional": "登山",
    "simplified": "登山",
    "reading": "dēng shān",
    "meaning": "engage in mountain-climbing, sport",
    "grade": 4
  },
  {
    "id": 2403,
    "traditional": "的確",
    "simplified": "的确",
    "reading": "díquè",
    "meaning": "certainly, surely",
    "grade": 4
  },
  {
    "id": 2404,
    "traditional": "敵人",
    "simplified": "敌人",
    "reading": "dírén",
    "meaning": "antagonist, enemy, enemy force, foeman, opponent, adversary, hostile force, foe, opposing force",
    "grade": 4
  },
  {
    "id": 2405,
    "traditional": "地方",
    "simplified": "地方",
    "reading": "dìfang",
    "meaning": "country, soil, terrain, part, clime, way, territory, room, neighbourhood, tinderbox, locality (as distinct from the central administrat, child, tract, province, position, respect, where, region, space, place, local",
    "grade": 4
  },
  {
    "id": 2406,
    "traditional": "底",
    "simplified": "底",
    "reading": "dǐ",
    "meaning": "underside, bottom, base, end, ground",
    "grade": 4
  },
  {
    "id": 2407,
    "traditional": "地面",
    "simplified": "地面",
    "reading": "dìmiàn",
    "meaning": "(earth's) surface, ground, floor, region",
    "grade": 4
  },
  {
    "id": 2408,
    "traditional": "地位",
    "simplified": "地位",
    "reading": "dìwèi",
    "meaning": "station, estate, importance, appointment, character, footing, capacity, chair, estate of the realm, level, situation, status, standing, position, spot, connection, space, sphere, quality, place, condition",
    "grade": 4
  },
  {
    "id": 2409,
    "traditional": "地下",
    "simplified": "地下",
    "reading": "dìxia",
    "meaning": "subterranean, underground, secretly, subsurface, secret, secret activity",
    "grade": 4
  },
  {
    "id": 2410,
    "traditional": "地址",
    "simplified": "地址",
    "reading": "dìzhǐ",
    "meaning": "location, domicile, street address, address, name and address, destination",
    "grade": 4
  },
  {
    "id": 2411,
    "traditional": "典型",
    "simplified": "典型",
    "reading": "diǎnxíng",
    "meaning": "typical, representative",
    "grade": 4
  },
  {
    "id": 2412,
    "traditional": "點名",
    "simplified": "点名",
    "reading": "diǎn míng",
    "meaning": "roll call, to mention sb by name, (to call or praise or criticize sb) by name",
    "grade": 4
  },
  {
    "id": 2413,
    "traditional": "電燈",
    "simplified": "电灯",
    "reading": "diàndēng",
    "meaning": "electric light, CL:盞|盏[zhan3]",
    "grade": 4
  },
  {
    "id": 2414,
    "traditional": "電動車",
    "simplified": "电动车",
    "reading": "diàndòngchē",
    "meaning": "Electric vehicle",
    "grade": 4
  },
  {
    "id": 2415,
    "traditional": "電梯",
    "simplified": "电梯",
    "reading": "diàntī",
    "meaning": "lift, elevator",
    "grade": 4
  },
  {
    "id": 2416,
    "traditional": "電源",
    "simplified": "电源",
    "reading": "diànyuán",
    "meaning": "electric power source",
    "grade": 4
  },
  {
    "id": 2417,
    "traditional": "定",
    "simplified": "定",
    "reading": "dìng",
    "meaning": "decide",
    "grade": 4
  },
  {
    "id": 2418,
    "traditional": "頂",
    "simplified": "顶",
    "reading": "dǐng",
    "meaning": "top, tip",
    "grade": 4
  },
  {
    "id": 2419,
    "traditional": "冬季",
    "simplified": "冬季",
    "reading": "dōngjì",
    "meaning": "winter",
    "grade": 4
  },
  {
    "id": 2420,
    "traditional": "動畫片",
    "simplified": "动画片",
    "reading": "dònghuàpiàn",
    "meaning": "animated film",
    "grade": 4
  },
  {
    "id": 2421,
    "traditional": "豆腐",
    "simplified": "豆腐",
    "reading": "dòufu",
    "meaning": "tofu, bean curd",
    "grade": 4
  },
  {
    "id": 2422,
    "traditional": "獨立",
    "simplified": "独立",
    "reading": "dúlì",
    "meaning": "stand alone",
    "grade": 4
  },
  {
    "id": 2423,
    "traditional": "動搖",
    "simplified": "动摇",
    "reading": "dòngyáo",
    "meaning": "totter, unnerve, faze, fluctuate, jounce, falter, oscillate, enervate, seesaw, stagger, waver, depolarize, vibrate, shaken, ferment, wabble, shake, churn, teeter, vacillate, wobble, rock, weaken, agitate, unsettle, sway, vacillation, hesitate",
    "grade": 4
  },
  {
    "id": 2424,
    "traditional": "獨自",
    "simplified": "独自",
    "reading": "dúzì",
    "meaning": "unaccompanied, solely, by oneself, alone",
    "grade": 4
  },
  {
    "id": 2425,
    "traditional": "獨特",
    "simplified": "独特",
    "reading": "dútè",
    "meaning": "special, distinctive, unique",
    "grade": 4
  },
  {
    "id": 2426,
    "traditional": "堵",
    "simplified": "堵",
    "reading": "dǔ",
    "meaning": "to stop up, (to feel) stifled or suffocated, wall, classifier for walls",
    "grade": 4
  },
  {
    "id": 2427,
    "traditional": "肚子",
    "simplified": "肚子",
    "reading": "dǔzi",
    "meaning": "kyte, venter, womb, tummy, belly, stomach, paunch, abdomen, breadbasket, wame, tripe",
    "grade": 4
  },
  {
    "id": 2428,
    "traditional": "堵車",
    "simplified": "堵车",
    "reading": "dǔ chē",
    "meaning": "traffic jam, choking",
    "grade": 4
  },
  {
    "id": 2429,
    "traditional": "度過",
    "simplified": "度过",
    "reading": "dùguò",
    "meaning": "pass, spend (time/etc.)",
    "grade": 4
  },
  {
    "id": 2430,
    "traditional": "對比",
    "simplified": "对比",
    "reading": "duìbǐ",
    "meaning": "ratio, correlation",
    "grade": 4
  },
  {
    "id": 2431,
    "traditional": "鍛煉",
    "simplified": "锻炼",
    "reading": "duànliàn",
    "meaning": "to toughen, to temper, to engage in physical exercise, to work out, (fig.) to develop one's skills, to train oneself",
    "grade": 4
  },
  {
    "id": 2432,
    "traditional": "多次",
    "simplified": "多次",
    "reading": "duōcì",
    "meaning": "many times, repeatedly",
    "grade": 4
  },
  {
    "id": 2433,
    "traditional": "對於",
    "simplified": "对于",
    "reading": "duìyú",
    "meaning": "in regard) to, toward, at, for",
    "grade": 4
  },
  {
    "id": 2434,
    "traditional": "對付",
    "simplified": "对付",
    "reading": "duìfu",
    "meaning": "counter, deal/cope with, face, confront, cope with, fix, make do, handle, deal, tackle, deal with",
    "grade": 4
  },
  {
    "id": 2435,
    "traditional": "多年",
    "simplified": "多年",
    "reading": "duōnián",
    "meaning": "for many years",
    "grade": 4
  },
  {
    "id": 2436,
    "traditional": "多種",
    "simplified": "多种",
    "reading": "duōzhǒng",
    "meaning": "many kinds of, multiple, diverse, multi-",
    "grade": 4
  },
  {
    "id": 2437,
    "traditional": "而",
    "simplified": "而",
    "reading": "ér",
    "meaning": "conj.: and yet",
    "grade": 4
  },
  {
    "id": 2438,
    "traditional": "惡心",
    "simplified": "恶心",
    "reading": "ěxin",
    "meaning": "nausea, to feel sick, disgust, nauseating, to embarrass (deliberately), bad habit, vicious habit, vice",
    "grade": 4
  },
  {
    "id": 2439,
    "traditional": "兒童",
    "simplified": "儿童",
    "reading": "értóng",
    "meaning": "kid, nipper, enfant, tyke, youngster, small fry, minor, shaver, nestling, tike, child, chit, fry, children, infant, tiddler, small-fry",
    "grade": 4
  },
  {
    "id": 2440,
    "traditional": "多樣",
    "simplified": "多样",
    "reading": "duōyàng",
    "meaning": "diverse, multiform, various",
    "grade": 4
  },
  {
    "id": 2441,
    "traditional": "而是",
    "simplified": "而是",
    "reading": "érshì",
    "meaning": "conj.: if not A, then B",
    "grade": 4
  },
  {
    "id": 2442,
    "traditional": "二手",
    "simplified": "二手",
    "reading": "èrshǒu",
    "meaning": "indirectly acquired, second-hand (information, equipment etc), assistant",
    "grade": 4
  },
  {
    "id": 2443,
    "traditional": "耳機",
    "simplified": "耳机",
    "reading": "ěrjī",
    "meaning": "headphones, earphones, telephone receiver",
    "grade": 4
  },
  {
    "id": 2444,
    "traditional": "發揮",
    "simplified": "发挥",
    "reading": "fāhuī",
    "meaning": "bring into play, give free rein to, develop (idea/etc.), elaborate",
    "grade": 4
  },
  {
    "id": 2445,
    "traditional": "發票",
    "simplified": "发票",
    "reading": "fāpiào",
    "meaning": "invoice, receipt or bill for purchase",
    "grade": 4
  },
  {
    "id": 2446,
    "traditional": "發燒",
    "simplified": "发烧",
    "reading": "fāshāo",
    "meaning": "fever, kindle, feverish, temperature, run a temperature, run a fever, have a fever, have a temperature",
    "grade": 4
  },
  {
    "id": 2447,
    "traditional": "法",
    "simplified": "法",
    "reading": "fǎ",
    "meaning": "law, method, way, mode, standard, model, legalists, Legalist School, Buddhist doctrine, dharma, magic arts",
    "grade": 4
  },
  {
    "id": 2448,
    "traditional": "法官",
    "simplified": "法官",
    "reading": "fǎguān",
    "meaning": "sentencer, Bench, judiciary, judgeship, bench, tippet, wig, justice, judicature, magistrate, court, judge, jurist, recorder, hakim, justicer, judgship",
    "grade": 4
  },
  {
    "id": 2449,
    "traditional": "法律",
    "simplified": "法律",
    "reading": "fǎlǜ",
    "meaning": "constitution, juridical, legal, jus, judiciary, statute law, law, codex, legislation, jurisprudence, lex, code, Themis, statute, judicial, practice of law, prescript, forensic, natural law",
    "grade": 4
  },
  {
    "id": 2450,
    "traditional": "法院",
    "simplified": "法院",
    "reading": "fǎyuàn",
    "meaning": "judicature, (law) court, judicatory, bar, courthouse, law court, court, tribunal, judiciary, banc, court of justice, jug, banco, forensic",
    "grade": 4
  },
  {
    "id": 2451,
    "traditional": "翻譯",
    "simplified": "翻译",
    "reading": "fānyì",
    "meaning": "translate, interpret",
    "grade": 4
  },
  {
    "id": 2452,
    "traditional": "煩",
    "simplified": "烦",
    "reading": "fán",
    "meaning": "to feel vexed, to bother, to trouble, superfluous and confusing, edgy",
    "grade": 4
  },
  {
    "id": 2453,
    "traditional": "翻",
    "simplified": "翻",
    "reading": "fān",
    "meaning": "to turn over, to flip over, to overturn, to rummage through, to translate, to decode, to double, to climb over or into, to cross, variant of 翻[fan1]",
    "grade": 4
  },
  {
    "id": 2454,
    "traditional": "反而",
    "simplified": "反而",
    "reading": "fǎnér",
    "meaning": "conj.: on the contrary, instead",
    "grade": 4
  },
  {
    "id": 2455,
    "traditional": "反映",
    "simplified": "反映",
    "reading": "fǎnyìng",
    "meaning": "reflect, mirror, make known",
    "grade": 4
  },
  {
    "id": 2456,
    "traditional": "反",
    "simplified": "反",
    "reading": "fǎn",
    "meaning": "counter, revolt, rebel, oppose, combat",
    "grade": 4
  },
  {
    "id": 2457,
    "traditional": "方案",
    "simplified": "方案",
    "reading": "fāngàn",
    "meaning": "formula, plan, scheme, projection, programme, proposal, schema, strategy, project, design, program",
    "grade": 4
  },
  {
    "id": 2458,
    "traditional": "方",
    "simplified": "方",
    "reading": "fāng",
    "meaning": "just, only just/then",
    "grade": 4
  },
  {
    "id": 2459,
    "traditional": "方針",
    "simplified": "方针",
    "reading": "fāngzhēn",
    "meaning": "policy, guiding principle",
    "grade": 4
  },
  {
    "id": 2460,
    "traditional": "非",
    "simplified": "非",
    "reading": "fēi",
    "meaning": "not",
    "grade": 4
  },
  {
    "id": 2461,
    "traditional": "放鬆",
    "simplified": "放松",
    "reading": "fàngsōng",
    "meaning": "relax, slacken, loosen",
    "grade": 4
  },
  {
    "id": 2462,
    "traditional": "肥",
    "simplified": "肥",
    "reading": "féi",
    "meaning": "fat, fertile, loose-fitting or large, to fertilize, to become rich by illegal means, fertilizer, manure",
    "grade": 4
  },
  {
    "id": 2463,
    "traditional": "分布",
    "simplified": "分布",
    "reading": "fēnbù",
    "meaning": "be distributed (over an area), be scattered",
    "grade": 4
  },
  {
    "id": 2464,
    "traditional": "分手",
    "simplified": "分手",
    "reading": "fēn shǒu",
    "meaning": "part, part company, separate, say good-bye, break up",
    "grade": 4
  },
  {
    "id": 2465,
    "traditional": "分為",
    "simplified": "分为",
    "reading": "fēnwéi",
    "meaning": "divide ... into, fall_into, divide(into)",
    "grade": 4
  },
  {
    "id": 2466,
    "traditional": "…分之…",
    "simplified": "…分之…",
    "reading": "… fēn zhī …",
    "meaning": "... is divided into ...",
    "grade": 4
  },
  {
    "id": 2467,
    "traditional": "奮鬥",
    "simplified": "奋斗",
    "reading": "fèndòu",
    "meaning": "struggle, fight, strive",
    "grade": 4
  },
  {
    "id": 2468,
    "traditional": "風格",
    "simplified": "风格",
    "reading": "fēnggé",
    "meaning": "plan, signature, idiom, kind, aroma, manner, idiosyncrasy, negligence, style, mode, tone, expressive style, artistic style, touch",
    "grade": 4
  },
  {
    "id": 2469,
    "traditional": "分散",
    "simplified": "分散",
    "reading": "fēnsàn",
    "meaning": "divert, diversify, diffract, disperse, deconcentrate, decentralize, dispel, disgregate, decentralization, scatter, distract, decentralise, shed, break up, dispersal",
    "grade": 4
  },
  {
    "id": 2470,
    "traditional": "風景",
    "simplified": "风景",
    "reading": "fēngjǐng",
    "meaning": "view, aspect, vista, panorama, scenery, prospect, scene, sight, landscape",
    "grade": 4
  },
  {
    "id": 2471,
    "traditional": "紛紛",
    "simplified": "纷纷",
    "reading": "fēnfēn",
    "meaning": "in droves, one_after_another, numerous and in great confusion",
    "grade": 4
  },
  {
    "id": 2472,
    "traditional": "封閉",
    "simplified": "封闭",
    "reading": "fēngbì",
    "meaning": "resistant to anything new",
    "grade": 4
  },
  {
    "id": 2473,
    "traditional": "否則",
    "simplified": "否则",
    "reading": "fǒuzé",
    "meaning": "conj.: otherwise, if not, or else",
    "grade": 4
  },
  {
    "id": 2474,
    "traditional": "風俗",
    "simplified": "风俗",
    "reading": "fēngsú",
    "meaning": "mores, custom, public decency, manner, institution, ritual, Sunna, manners, rite, guise",
    "grade": 4
  },
  {
    "id": 2475,
    "traditional": "夫妻",
    "simplified": "夫妻",
    "reading": "fūqī",
    "meaning": "couple, spouse, pair, man and wife, husband and wife",
    "grade": 4
  },
  {
    "id": 2476,
    "traditional": "夫人",
    "simplified": "夫人",
    "reading": "fūren",
    "meaning": "Lady, lady, memsahib, milady, Dona, Mistress, dona, grande dame, donna, Mrs, mum, Senora, grace, senora, Mrs., madam, Frau, miladi, missus, senhora, wife, ma'am, Madame, madame, missis, Ladyship, Signora, signora",
    "grade": 4
  },
  {
    "id": 2477,
    "traditional": "夫婦",
    "simplified": "夫妇",
    "reading": "fūfù",
    "meaning": "conjugality, couple, man_and_wife, husband and wife",
    "grade": 4
  },
  {
    "id": 2478,
    "traditional": "符合",
    "simplified": "符合",
    "reading": "fúhé",
    "meaning": "fulfil, conform, fit, tally with, fill, come_up_to, cohere, satisfy, accord, coincide, assort, equate, answer, tally, consist, meet, be in keeping with, chime_in, fulfill, look, accord/tally with, relate, chime, live up to, square, accord with, conform to, correspond",
    "grade": 4
  },
  {
    "id": 2479,
    "traditional": "付出",
    "simplified": "付出",
    "reading": "fùchū",
    "meaning": "pay, expend",
    "grade": 4
  },
  {
    "id": 2480,
    "traditional": "符號",
    "simplified": "符号",
    "reading": "fúhào",
    "meaning": "sign, denotation, mark, icon, symbolical, symbol, notation, token, insignia, note, symbolic",
    "grade": 4
  },
  {
    "id": 2481,
    "traditional": "附近",
    "simplified": "附近",
    "reading": "fùjìn",
    "meaning": "neighborship, neighborhood, precinct, vicinity, neighbourhood",
    "grade": 4
  },
  {
    "id": 2482,
    "traditional": "負擔",
    "simplified": "负担",
    "reading": "fùdān",
    "meaning": "burden, load, encumbrance",
    "grade": 4
  },
  {
    "id": 2483,
    "traditional": "複製",
    "simplified": "复制",
    "reading": "fùzhì",
    "meaning": "duplicate, reproduce",
    "grade": 4
  },
  {
    "id": 2484,
    "traditional": "改善",
    "simplified": "改善",
    "reading": "gǎishàn",
    "meaning": "improve, perfect",
    "grade": 4
  },
  {
    "id": 2485,
    "traditional": "改正",
    "simplified": "改正",
    "reading": "gǎizhèng",
    "meaning": "to correct, to amend, to put right, correction, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 2486,
    "traditional": "概括",
    "simplified": "概括",
    "reading": "gàikuò",
    "meaning": "to summarize, to generalize, briefly, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 2487,
    "traditional": "感興趣",
    "simplified": "感兴趣",
    "reading": "gǎn xìngqù",
    "meaning": "be interested, be interested in",
    "grade": 4
  },
  {
    "id": 2488,
    "traditional": "高潮",
    "simplified": "高潮",
    "reading": "gāocháo",
    "meaning": "water, high_tide, crescendo, coming, high tide/water, high tide, climax, upsurge, eagre, springtide, tidemark, sexual climax, blowoff, orgasm, culmination, payoff, wave, high, high water",
    "grade": 4
  },
  {
    "id": 2489,
    "traditional": "蓋",
    "simplified": "盖",
    "reading": "gài",
    "meaning": "cover, seal, affix seal, surpass, top, prevail over",
    "grade": 4
  },
  {
    "id": 2490,
    "traditional": "高價",
    "simplified": "高价",
    "reading": "gāojià",
    "meaning": "high price",
    "grade": 4
  },
  {
    "id": 2491,
    "traditional": "格外",
    "simplified": "格外",
    "reading": "géwài",
    "meaning": "piu, especially, all the more, helluva, particularly",
    "grade": 4
  },
  {
    "id": 2492,
    "traditional": "高鐵",
    "simplified": "高铁",
    "reading": "gāotiě",
    "meaning": "high speed rail",
    "grade": 4
  },
  {
    "id": 2493,
    "traditional": "高尚",
    "simplified": "高尚",
    "reading": "gāoshàng",
    "meaning": "noble, lofty, refined, exquisite",
    "grade": 4
  },
  {
    "id": 2494,
    "traditional": "隔",
    "simplified": "隔",
    "reading": "gé",
    "meaning": "separate, cut off, impede",
    "grade": 4
  },
  {
    "id": 2495,
    "traditional": "隔開",
    "simplified": "隔开",
    "reading": "gékāi",
    "meaning": "to separate",
    "grade": 4
  },
  {
    "id": 2496,
    "traditional": "個別",
    "simplified": "个别",
    "reading": "gèbié",
    "meaning": "specifically, very few, specific, one or two exceptional, individual, exceptional",
    "grade": 4
  },
  {
    "id": 2497,
    "traditional": "各個",
    "simplified": "各个",
    "reading": "gègè",
    "meaning": "every, various, separately, one by one",
    "grade": 4
  },
  {
    "id": 2498,
    "traditional": "個體",
    "simplified": "个体",
    "reading": "gètǐ",
    "meaning": "unit, individual, individuality",
    "grade": 4
  },
  {
    "id": 2499,
    "traditional": "根",
    "simplified": "根",
    "reading": "gēn",
    "meaning": "m.[general]",
    "grade": 4
  },
  {
    "id": 2500,
    "traditional": "根據",
    "simplified": "根据",
    "reading": "gēnjù",
    "meaning": "on the basis of, according to",
    "grade": 4
  },
  {
    "id": 2501,
    "traditional": "工程",
    "simplified": "工程",
    "reading": "gōngchéng",
    "meaning": "process, construction, engineering, engineering project, project",
    "grade": 4
  },
  {
    "id": 2502,
    "traditional": "供應",
    "simplified": "供应",
    "reading": "gōngyìng",
    "meaning": "supply",
    "grade": 4
  },
  {
    "id": 2503,
    "traditional": "共",
    "simplified": "共",
    "reading": "gòng",
    "meaning": "totally, together, in all, in company, altogether",
    "grade": 4
  },
  {
    "id": 2504,
    "traditional": "構成",
    "simplified": "构成",
    "reading": "gòu chéng",
    "meaning": "constitute, form, compose, make up",
    "grade": 4
  },
  {
    "id": 2505,
    "traditional": "公元",
    "simplified": "公元",
    "reading": "gōngyuán",
    "meaning": "CE (Common Era), Christian Era, AD (Anno Domini)",
    "grade": 4
  },
  {
    "id": 2506,
    "traditional": "構造",
    "simplified": "构造",
    "reading": "gòuzào",
    "meaning": "constitution, configuration, make-up, build, anatomy, construction, structure, tectonic, fabric, compages, making, contexture, texture, conformation, apparatus, formation, architecture, makeup",
    "grade": 4
  },
  {
    "id": 2507,
    "traditional": "購物",
    "simplified": "购物",
    "reading": "gòuwù",
    "meaning": "shopping",
    "grade": 4
  },
  {
    "id": 2508,
    "traditional": "購買",
    "simplified": "购买",
    "reading": "gòumǎi",
    "meaning": "invest, take, buy, make purchases, go shopping, purchase",
    "grade": 4
  },
  {
    "id": 2509,
    "traditional": "骨頭",
    "simplified": "骨头",
    "reading": "gǔtou",
    "meaning": "os, strong character, bone, character, person of a certain character",
    "grade": 4
  },
  {
    "id": 2510,
    "traditional": "固定",
    "simplified": "固定",
    "reading": "gùdìng",
    "meaning": "set, plant, imbed, fix, strap, jell, fixed, affix, peg, stick, clench, rivet, regularization, rigidify, embed, infix, anchor, fixate, hold, regularize, implant, stitch, stabile, engraft, fasten, immobilize, moor, peg down, flat",
    "grade": 4
  },
  {
    "id": 2511,
    "traditional": "怪",
    "simplified": "怪",
    "reading": "guài",
    "meaning": "find sth. strange, blame",
    "grade": 4
  },
  {
    "id": 2512,
    "traditional": "關",
    "simplified": "关",
    "reading": "guān",
    "meaning": "shut, close, turn off, lock up, close down",
    "grade": 4
  },
  {
    "id": 2513,
    "traditional": "關閉",
    "simplified": "关闭",
    "reading": "guānbì",
    "meaning": "close, shut (down)",
    "grade": 4
  },
  {
    "id": 2514,
    "traditional": "官方",
    "simplified": "官方",
    "reading": "guānfāng",
    "meaning": "authorities, official, authority, regime, authorization, government",
    "grade": 4
  },
  {
    "id": 2515,
    "traditional": "官",
    "simplified": "官",
    "reading": "guān",
    "meaning": "government official, officeholder",
    "grade": 4
  },
  {
    "id": 2516,
    "traditional": "光臨",
    "simplified": "光临",
    "reading": "guānglín",
    "meaning": "(formal) to honor with one's presence, to attend",
    "grade": 4
  },
  {
    "id": 2517,
    "traditional": "關於",
    "simplified": "关于",
    "reading": "guānyú",
    "meaning": "about, with regard to, concerning",
    "grade": 4
  },
  {
    "id": 2518,
    "traditional": "歸",
    "simplified": "归",
    "reading": "guī",
    "meaning": "return ... to, put in sb.'s charge, take refuge, turn over to, return sth. to, come together, belong, give back to, marry, converge, return, take refuge (in Buddha/etc.), go back to",
    "grade": 4
  },
  {
    "id": 2519,
    "traditional": "逛",
    "simplified": "逛",
    "reading": "guàng",
    "meaning": "to stroll, to visit",
    "grade": 4
  },
  {
    "id": 2520,
    "traditional": "光盤",
    "simplified": "光盘",
    "reading": "guāngpán",
    "meaning": "compact disc, CD or DVD, CD ROM, CL:片[pian4],張|张[zhang1]",
    "grade": 4
  },
  {
    "id": 2521,
    "traditional": "瓜",
    "simplified": "瓜",
    "reading": "guā",
    "meaning": "melon, gourd, squash",
    "grade": 4
  },
  {
    "id": 2522,
    "traditional": "規律",
    "simplified": "规律",
    "reading": "guīlǜ",
    "meaning": "law, regular pattern",
    "grade": 4
  },
  {
    "id": 2523,
    "traditional": "規則",
    "simplified": "规则",
    "reading": "guīzé",
    "meaning": "rule, regulation",
    "grade": 4
  },
  {
    "id": 2524,
    "traditional": "果實",
    "simplified": "果实",
    "reading": "guǒshí",
    "meaning": "fruitery, fruitage, gains, fruits, fruit, fructification",
    "grade": 4
  },
  {
    "id": 2525,
    "traditional": "海水",
    "simplified": "海水",
    "reading": "hǎishuǐ",
    "meaning": "waters, seawater, salt water, sea, the sea, brine",
    "grade": 4
  },
  {
    "id": 2526,
    "traditional": "過分",
    "simplified": "过分",
    "reading": "guòfèn",
    "meaning": "excessively, over-",
    "grade": 4
  },
  {
    "id": 2527,
    "traditional": "海鮮",
    "simplified": "海鲜",
    "reading": "hǎixiān",
    "meaning": "seafood",
    "grade": 4
  },
  {
    "id": 2528,
    "traditional": "含",
    "simplified": "含",
    "reading": "hán",
    "meaning": "keep in mouth, cherish",
    "grade": 4
  },
  {
    "id": 2529,
    "traditional": "規模",
    "simplified": "规模",
    "reading": "guīmó",
    "meaning": "magnitude, dimensions, extent, scale, scope",
    "grade": 4
  },
  {
    "id": 2530,
    "traditional": "含量",
    "simplified": "含量",
    "reading": "hánliàng",
    "meaning": "content, quantity contained",
    "grade": 4
  },
  {
    "id": 2531,
    "traditional": "含有",
    "simplified": "含有",
    "reading": "hányǒu",
    "meaning": "have, involve, infer, imply, tinge, include, number, carry, contain",
    "grade": 4
  },
  {
    "id": 2532,
    "traditional": "含義",
    "simplified": "含义",
    "reading": "hányì",
    "meaning": "meaning (implicit in a phrase), implied meaning, hidden meaning, hint, connotation",
    "grade": 4
  },
  {
    "id": 2533,
    "traditional": "寒假",
    "simplified": "寒假",
    "reading": "hánjià",
    "meaning": "winter vacation",
    "grade": 4
  },
  {
    "id": 2534,
    "traditional": "寒冷",
    "simplified": "寒冷",
    "reading": "hánlěng",
    "meaning": "cold, frigid",
    "grade": 4
  },
  {
    "id": 2535,
    "traditional": "航班",
    "simplified": "航班",
    "reading": "hángbān",
    "meaning": "scheduled flight, flight number, plane, scheduled sailing, sailing number, passenger ship",
    "grade": 4
  },
  {
    "id": 2536,
    "traditional": "航空",
    "simplified": "航空",
    "reading": "hángkōng",
    "meaning": "air, aerospace, aerial, air travel, air power, aeronautical, navigation, voyage, aviation, aeronautic",
    "grade": 4
  },
  {
    "id": 2537,
    "traditional": "毫米",
    "simplified": "毫米",
    "reading": "háomǐ",
    "meaning": "millimeter",
    "grade": 4
  },
  {
    "id": 2538,
    "traditional": "行業",
    "simplified": "行业",
    "reading": "hángyè",
    "meaning": "metier, mystery, profession, line, game, craft, calling, trade, industry, biz, walk_of_life, racket, living",
    "grade": 4
  },
  {
    "id": 2539,
    "traditional": "好友",
    "simplified": "好友",
    "reading": "hǎoyǒu",
    "meaning": "peer, chum, crony, great friend, good friend, brick",
    "grade": 4
  },
  {
    "id": 2540,
    "traditional": "毫升",
    "simplified": "毫升",
    "reading": "háoshēng",
    "meaning": "milliliter",
    "grade": 4
  },
  {
    "id": 2541,
    "traditional": "好",
    "simplified": "好",
    "reading": "hǎo",
    "meaning": "good",
    "grade": 4
  },
  {
    "id": 2542,
    "traditional": "號碼",
    "simplified": "号码",
    "reading": "hàomǎ",
    "meaning": "pressmark, call mark, identification number, (serial) number, call number, no., number",
    "grade": 4
  },
  {
    "id": 2543,
    "traditional": "黑暗",
    "simplified": "黑暗",
    "reading": "hēiàn",
    "meaning": "dark",
    "grade": 4
  },
  {
    "id": 2544,
    "traditional": "合同",
    "simplified": "合同",
    "reading": "hétong",
    "meaning": "(business) contract, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 2545,
    "traditional": "紅包",
    "simplified": "红包",
    "reading": "hóngbāo",
    "meaning": "money wrapped in red as a gift, bonus payment, kickback, bribe",
    "grade": 4
  },
  {
    "id": 2546,
    "traditional": "厚",
    "simplified": "厚",
    "reading": "hòu",
    "meaning": "thick, fat, magnanimous, favor, kind, generous, deep, strong in flavor, large, stress, profound, rich",
    "grade": 4
  },
  {
    "id": 2547,
    "traditional": "後頭",
    "simplified": "后头",
    "reading": "hòutou",
    "meaning": "behind, in the back, the rear side, later, in future",
    "grade": 4
  },
  {
    "id": 2548,
    "traditional": "忽視",
    "simplified": "忽视",
    "reading": "hūshì",
    "meaning": "ignore, overlook, neglect",
    "grade": 4
  },
  {
    "id": 2549,
    "traditional": "戶",
    "simplified": "户",
    "reading": "hù",
    "meaning": "m.[general]",
    "grade": 4
  },
  {
    "id": 2550,
    "traditional": "呼吸",
    "simplified": "呼吸",
    "reading": "hūxī",
    "meaning": "take a breather, respiratory, take a breath, respire, catch one's breath, inspire, breathe, rest, suspire, respiration",
    "grade": 4
  },
  {
    "id": 2551,
    "traditional": "花",
    "simplified": "花",
    "reading": "huā",
    "meaning": "spend, expend, cost",
    "grade": 4
  },
  {
    "id": 2552,
    "traditional": "護士",
    "simplified": "护士",
    "reading": "hùshi",
    "meaning": "graduate nurse, nana, nurse, sister, trained nurse, (hospital) nurse",
    "grade": 4
  },
  {
    "id": 2553,
    "traditional": "懷念",
    "simplified": "怀念",
    "reading": "huáiniàn",
    "meaning": "cherish memory of, think of",
    "grade": 4
  },
  {
    "id": 2554,
    "traditional": "劃",
    "simplified": "划",
    "reading": "huà",
    "meaning": "to cut, to slash, to scratch (cut into the surface of sth), to strike (a match), to delimit, to transfer, to assign, to plan, to draw (a line), stroke of a Chinese character",
    "grade": 4
  },
  {
    "id": 2555,
    "traditional": "懷疑",
    "simplified": "怀疑",
    "reading": "huáiyí",
    "meaning": "suspect, doubt",
    "grade": 4
  },
  {
    "id": 2556,
    "traditional": "黃瓜",
    "simplified": "黄瓜",
    "reading": "huánggua",
    "meaning": "cucumber, CL:條|条[tiao2]",
    "grade": 4
  },
  {
    "id": 2557,
    "traditional": "緩解",
    "simplified": "缓解",
    "reading": "huǎnjiě",
    "meaning": "to bring relief, to alleviate (a crisis), to dull (a pain)",
    "grade": 4
  },
  {
    "id": 2558,
    "traditional": "黃金",
    "simplified": "黄金",
    "reading": "huángjīn",
    "meaning": "gold",
    "grade": 4
  },
  {
    "id": 2559,
    "traditional": "回復",
    "simplified": "回复",
    "reading": "huífù",
    "meaning": "to reply, to recover, to return (to a previous condition), Re: in reply to (email)",
    "grade": 4
  },
  {
    "id": 2560,
    "traditional": "匯",
    "simplified": "汇",
    "reading": "huì",
    "meaning": "to remit, to converge (of rivers), to exchange, variant of 匯|汇[hui4]",
    "grade": 4
  },
  {
    "id": 2561,
    "traditional": "彙報",
    "simplified": "汇报",
    "reading": "huìbào",
    "meaning": "to report, to give an account of, report",
    "grade": 4
  },
  {
    "id": 2562,
    "traditional": "婚禮",
    "simplified": "婚礼",
    "reading": "hūnlǐ",
    "meaning": "wedding ceremony, wedding, CL:場|场[chang3]",
    "grade": 4
  },
  {
    "id": 2563,
    "traditional": "匯率",
    "simplified": "汇率",
    "reading": "huìlǜ",
    "meaning": "interchange, exchange rate, rate of exchange, exchange, exchange_rate",
    "grade": 4
  },
  {
    "id": 2564,
    "traditional": "伙",
    "simplified": "伙",
    "reading": "huǒ",
    "meaning": "companion, partner, group, classifier for groups of people, to combine, together",
    "grade": 4
  },
  {
    "id": 2565,
    "traditional": "火",
    "simplified": "火",
    "reading": "huǒ",
    "meaning": "fire;internal heat, anger, temper;firearms, ammunition",
    "grade": 4
  },
  {
    "id": 2566,
    "traditional": "或許",
    "simplified": "或许",
    "reading": "huòxǔ",
    "meaning": "possibly, in all probability, peradventure, conceivably, mayhap, likely, perhaps, perchance, probably, maybe, haply, in all likelihood, belike",
    "grade": 4
  },
  {
    "id": 2567,
    "traditional": "伙伴",
    "simplified": "伙伴",
    "reading": "huǒbàn",
    "meaning": "partner, companion, comrade",
    "grade": 4
  },
  {
    "id": 2568,
    "traditional": "獲",
    "simplified": "获",
    "reading": "huò",
    "meaning": "harvest, capture, obtain, get, catch, poll, be able to, reap, gather in, win",
    "grade": 4
  },
  {
    "id": 2569,
    "traditional": "獲得",
    "simplified": "获得",
    "reading": "huòdé",
    "meaning": "find, cop, acquirement, come_into, gain, draw, pick_up, pull, achievement, score, procure, come_by, get hold, purchase, nab, come upon, derive, latch, incur, achieve, make, bring, receive, arrive, come_out, pocket, obtain, luck into, sack, win, enter upon, pick up, garner, harvest, attain, come up, line up, extract, secure, possess, earn, reap, accrue, cover, take, acquire, get, arrive_at",
    "grade": 4
  },
  {
    "id": 2570,
    "traditional": "獲獎",
    "simplified": "获奖",
    "reading": "huò jiǎng",
    "meaning": "to win an award",
    "grade": 4
  },
  {
    "id": 2571,
    "traditional": "獲取",
    "simplified": "获取",
    "reading": "huòqǔ",
    "meaning": "to gain, to get, to acquire",
    "grade": 4
  },
  {
    "id": 2572,
    "traditional": "機構",
    "simplified": "机构",
    "reading": "jīgòu",
    "meaning": "internal structure of an organization, motion, gadget, instrumentality, institute, organ, mechanism, gear, representation, mechanics, machinery, apparatus, establishment, institution, means, organization, agency, setup, delegacy, outfit",
    "grade": 4
  },
  {
    "id": 2573,
    "traditional": "貨",
    "simplified": "货",
    "reading": "huò",
    "meaning": "commodity, idiot, money, blockhead, loading, goods",
    "grade": 4
  },
  {
    "id": 2574,
    "traditional": "幾乎",
    "simplified": "几乎",
    "reading": "jīhū",
    "meaning": "chiefly, anear, about, near, just_about, most, well-nigh, almost, closely, practically, intimately, all but, nearly, just about, virtually, much, nigh",
    "grade": 4
  },
  {
    "id": 2575,
    "traditional": "積累",
    "simplified": "积累",
    "reading": "jīlěi",
    "meaning": "to accumulate, accumulation, cumulative, cumulatively",
    "grade": 4
  },
  {
    "id": 2576,
    "traditional": "激動",
    "simplified": "激动",
    "reading": "jīdòng",
    "meaning": "boil, tingle, kindle, seethe, toss, inflammatory, tizz, excited, fire, throb, carry_away, ferment, effervesce, stirred, bespirit, heat, agitation, hound, agitate, invigorate, burnt, actuate, inflame, stir, agitated, excite, move, impassion",
    "grade": 4
  },
  {
    "id": 2577,
    "traditional": "激烈",
    "simplified": "激烈",
    "reading": "jīliè",
    "meaning": "drastic, fierce, heated, acute, sharp, intense",
    "grade": 4
  },
  {
    "id": 2578,
    "traditional": "及格",
    "simplified": "及格",
    "reading": "jí gé",
    "meaning": "to pass an exam or a test, to meet a minimum standard",
    "grade": 4
  },
  {
    "id": 2579,
    "traditional": "極",
    "simplified": "极",
    "reading": "jí",
    "meaning": "extraordinary, unusual, special, very, extremely, highly",
    "grade": 4
  },
  {
    "id": 2580,
    "traditional": "機遇",
    "simplified": "机遇",
    "reading": "jīyù",
    "meaning": "opportunity, favorable circumstance, stroke of luck",
    "grade": 4
  },
  {
    "id": 2581,
    "traditional": "即將",
    "simplified": "即将",
    "reading": "jíjiāng",
    "meaning": "in a short time, promptly, on the point of, at once, instantly, pronto, immediately, shortly, without delay, tout-de-suite, right away, forthwith, presently, soon, in a few seconds, before long, straightaway, in a minute, about to",
    "grade": 4
  },
  {
    "id": 2582,
    "traditional": "急忙",
    "simplified": "急忙",
    "reading": "jímáng",
    "meaning": "in haste, hurriedly",
    "grade": 4
  },
  {
    "id": 2583,
    "traditional": "記載",
    "simplified": "记载",
    "reading": "jìzǎi",
    "meaning": "put down in writing, record",
    "grade": 4
  },
  {
    "id": 2584,
    "traditional": "極其",
    "simplified": "极其",
    "reading": "jíqí",
    "meaning": "absolutely, extremely, very, parlous, heaps, utterly, entirely, miserably, goldarn, precious, exceedingly, passing, definitely, plaguily, passingly, totally, staving, goldurn, completely",
    "grade": 4
  },
  {
    "id": 2585,
    "traditional": "技巧",
    "simplified": "技巧",
    "reading": "jìqiǎo",
    "meaning": "technics, artifice, superior skill, acquisition, acquirement, cunning, trick, dexterity, accomplishment, mechanism, craft, craftsmanship, technique, artistry, handiwork, mastery, handicraft, finesse, attainment, sleight, skill, implement, execution, prowess, ingenuity, art, method, dish, mechanics, knack, cup of tea, management, bag, proficiency, address, facility, virtuosity, workman",
    "grade": 4
  },
  {
    "id": 2586,
    "traditional": "集合",
    "simplified": "集合",
    "reading": "jíhé",
    "meaning": "gather, assemble, muster",
    "grade": 4
  },
  {
    "id": 2587,
    "traditional": "紀律",
    "simplified": "纪律",
    "reading": "jìlǜ",
    "meaning": "disciplinary, discipline, ferule, morale, regime, laws and regulations",
    "grade": 4
  },
  {
    "id": 2588,
    "traditional": "系",
    "simplified": "系",
    "reading": "xì",
    "meaning": "be",
    "grade": 4
  },
  {
    "id": 2589,
    "traditional": "季",
    "simplified": "季",
    "reading": "jì",
    "meaning": "surname Ji, season, the last month of a season, fourth or youngest amongst brothers, classifier for seasonal crop yields",
    "grade": 4
  },
  {
    "id": 2590,
    "traditional": "季度",
    "simplified": "季度",
    "reading": "jìdù",
    "meaning": "quarter of a year, season (sports)",
    "grade": 4
  },
  {
    "id": 2591,
    "traditional": "既",
    "simplified": "既",
    "reading": "jì",
    "meaning": "conj.: since...then",
    "grade": 4
  },
  {
    "id": 2592,
    "traditional": "既然",
    "simplified": "既然",
    "reading": "jìrán",
    "meaning": "conj.: since, as, now that",
    "grade": 4
  },
  {
    "id": 2593,
    "traditional": "加班",
    "simplified": "加班",
    "reading": "jiā bān",
    "meaning": "to work overtime",
    "grade": 4
  },
  {
    "id": 2594,
    "traditional": "季節",
    "simplified": "季节",
    "reading": "jìjié",
    "meaning": "season",
    "grade": 4
  },
  {
    "id": 2595,
    "traditional": "寄",
    "simplified": "寄",
    "reading": "jì",
    "meaning": "attach oneself to, drop, transport, mail, ship, leave, entrust, post, get off, lodge at, depend on, send, entrustment, deposit, transmit, place, consign",
    "grade": 4
  },
  {
    "id": 2596,
    "traditional": "加入",
    "simplified": "加入",
    "reading": "jiārù",
    "meaning": "join, accede to",
    "grade": 4
  },
  {
    "id": 2597,
    "traditional": "家務",
    "simplified": "家务",
    "reading": "jiāwù",
    "meaning": "household duties, housework",
    "grade": 4
  },
  {
    "id": 2598,
    "traditional": "加油站",
    "simplified": "加油站",
    "reading": "jiāyóuzhàn",
    "meaning": "pit, petrol_station, filling station, gasoline station, filling_station, gas station, gas_station, petrol station",
    "grade": 4
  },
  {
    "id": 2599,
    "traditional": "假如",
    "simplified": "假如",
    "reading": "jiǎrú",
    "meaning": "conj.: if, supposing, in case",
    "grade": 4
  },
  {
    "id": 2600,
    "traditional": "檢測",
    "simplified": "检测",
    "reading": "jiǎncè",
    "meaning": "to detect, to test, detection, sensing",
    "grade": 4
  },
  {
    "id": 2601,
    "traditional": "減肥",
    "simplified": "减肥",
    "reading": "jiǎn féi",
    "meaning": "lose weight",
    "grade": 4
  },
  {
    "id": 2602,
    "traditional": "減少",
    "simplified": "减少",
    "reading": "jiǎnshǎo",
    "meaning": "reduce, decrease",
    "grade": 4
  },
  {
    "id": 2603,
    "traditional": "堅固",
    "simplified": "坚固",
    "reading": "jiāngù",
    "meaning": "firm, firmly, hard, stable",
    "grade": 4
  },
  {
    "id": 2604,
    "traditional": "減",
    "simplified": "减",
    "reading": "jiǎn",
    "meaning": "knock_down, derogate, cut, lessen, reduce, subtraction, diminish, reduction, decrease, dwindle, subtract, minus, deduct",
    "grade": 4
  },
  {
    "id": 2605,
    "traditional": "簡歷",
    "simplified": "简历",
    "reading": "jiǎnlì",
    "meaning": "curriculum vitae (CV), résumé, biographical notes",
    "grade": 4
  },
  {
    "id": 2606,
    "traditional": "健身",
    "simplified": "健身",
    "reading": "jiàn shēn",
    "meaning": "to exercise, to keep fit, to work out, physical exercise",
    "grade": 4
  },
  {
    "id": 2607,
    "traditional": "講究",
    "simplified": "讲究",
    "reading": "jiǎngjiu",
    "meaning": "be particular about, pay attention to, stress, strive for, backbite",
    "grade": 4
  },
  {
    "id": 2608,
    "traditional": "漸漸",
    "simplified": "渐渐",
    "reading": "jiànjiàn",
    "meaning": "gradually, by degrees, little by little",
    "grade": 4
  },
  {
    "id": 2609,
    "traditional": "江",
    "simplified": "江",
    "reading": "jiāng",
    "meaning": "river",
    "grade": 4
  },
  {
    "id": 2610,
    "traditional": "講座",
    "simplified": "讲座",
    "reading": "jiǎngzuò",
    "meaning": "lecture, professorship, cathedra, talk-in, course of lecture, chair, course of lectures",
    "grade": 4
  },
  {
    "id": 2611,
    "traditional": "獎",
    "simplified": "奖",
    "reading": "jiǎng",
    "meaning": "award, prize, reward",
    "grade": 4
  },
  {
    "id": 2612,
    "traditional": "獎金",
    "simplified": "奖金",
    "reading": "jiǎngjīn",
    "meaning": "pewter, gainings, money award, gratuity, bounty, stakes, subsidy, prix, premium, incentive, prize money, perquisite, purse, winnings, stake, prize, bonus, gratification",
    "grade": 4
  },
  {
    "id": 2613,
    "traditional": "獎學金",
    "simplified": "奖学金",
    "reading": "jiǎngxuéjīn",
    "meaning": "burse, stipend, foundation, exhibition, studentship, scholarship",
    "grade": 4
  },
  {
    "id": 2614,
    "traditional": "降價",
    "simplified": "降价",
    "reading": "jiàng jià",
    "meaning": "lower prices",
    "grade": 4
  },
  {
    "id": 2615,
    "traditional": "降",
    "simplified": "降",
    "reading": "jiàng",
    "meaning": "fall, drop, lower",
    "grade": 4
  },
  {
    "id": 2616,
    "traditional": "降低",
    "simplified": "降低",
    "reading": "jiàngdī",
    "meaning": "reduce, cut down, drop, lower",
    "grade": 4
  },
  {
    "id": 2617,
    "traditional": "降落",
    "simplified": "降落",
    "reading": "jiàngluò",
    "meaning": "descend, land, drop (in water level, etc.)",
    "grade": 4
  },
  {
    "id": 2618,
    "traditional": "交換",
    "simplified": "交换",
    "reading": "jiāohuàn",
    "meaning": "reciprocate, switching, barter, exchange, commute, switch, communicate, interchange, bandy, shift, trade, batter, truck, swop, change, counterchange, swap, clear, buy, transpose, change over, commune, turn around",
    "grade": 4
  },
  {
    "id": 2619,
    "traditional": "交際",
    "simplified": "交际",
    "reading": "jiāojì",
    "meaning": "communication, social intercourse",
    "grade": 4
  },
  {
    "id": 2620,
    "traditional": "教授",
    "simplified": "教授",
    "reading": "jiàoshòu",
    "meaning": "professor",
    "grade": 4
  },
  {
    "id": 2621,
    "traditional": "教訓",
    "simplified": "教训",
    "reading": "jiàoxun",
    "meaning": "lesson, moral",
    "grade": 4
  },
  {
    "id": 2622,
    "traditional": "階段",
    "simplified": "阶段",
    "reading": "jiēduàn",
    "meaning": "staged, period, phase angle, stage, gradation, level, peg, grade, remove, phase, leg, step",
    "grade": 4
  },
  {
    "id": 2623,
    "traditional": "街道",
    "simplified": "街道",
    "reading": "jiēdào",
    "meaning": "neighborhood, mews, gate, residential district, neighbourhood, street",
    "grade": 4
  },
  {
    "id": 2624,
    "traditional": "節省",
    "simplified": "节省",
    "reading": "jiéshěng",
    "meaning": "saving, to save, to use sparingly, to cut down on",
    "grade": 4
  },
  {
    "id": 2625,
    "traditional": "結構",
    "simplified": "结构",
    "reading": "jiégòu",
    "meaning": "constitution, configuration, framing, structural, design, underframe, framework, construction, structure, schematism, economy, fabric, mechanics, composition, machinery, contexture, texture, constellation, fable, ordonnance, skeleton, organization, frame, architecture, chemistry, make",
    "grade": 4
  },
  {
    "id": 2626,
    "traditional": "結",
    "simplified": "结",
    "reading": "jié",
    "meaning": "tie, knit, knot, weave, congeal, form, forge, cement, settle, conclude",
    "grade": 4
  },
  {
    "id": 2627,
    "traditional": "降溫",
    "simplified": "降温",
    "reading": "jiàng wēn",
    "meaning": "to become cooler, to lower the temperature, cooling, (of interest, activity etc) to decline",
    "grade": 4
  },
  {
    "id": 2628,
    "traditional": "姐妹",
    "simplified": "姐妹",
    "reading": "jiěmèi",
    "meaning": "sisters, siblings, sister (school, city etc)",
    "grade": 4
  },
  {
    "id": 2629,
    "traditional": "結論",
    "simplified": "结论",
    "reading": "jiélùn",
    "meaning": "ultimate, sequitur, consequent, ending, close, inference, closing, end matter, afterword, consequence, verdict, finality, back matter, conclusion, peroration, summation, summing-up, end, upshot, illation, outcome, conclusion (of a syllogism)",
    "grade": 4
  },
  {
    "id": 2630,
    "traditional": "解釋",
    "simplified": "解释",
    "reading": "jiěshì",
    "meaning": "explain, interpret, analyze",
    "grade": 4
  },
  {
    "id": 2631,
    "traditional": "儘快",
    "simplified": "尽快",
    "reading": "jǐnkuài",
    "meaning": "as quickly/soon as possible",
    "grade": 4
  },
  {
    "id": 2632,
    "traditional": "盡力",
    "simplified": "尽力",
    "reading": "jìn lì",
    "meaning": "do_one's_best, do all one can, render, take pains, be at pains, take_pains, stretch, strain, strive, try one's best",
    "grade": 4
  },
  {
    "id": 2633,
    "traditional": "進口",
    "simplified": "进口",
    "reading": "jìn kǒu",
    "meaning": "import",
    "grade": 4
  },
  {
    "id": 2634,
    "traditional": "禁止",
    "simplified": "禁止",
    "reading": "jìnzhǐ",
    "meaning": "estop, inhibit, bar, outlaw, banish, forbid, suppress, interdict, defend, taboo, veto, prohibit, enjoin, blackball, restrain, debar, proscribe, negative, disallow, relegate, nix, prohibition, ban",
    "grade": 4
  },
  {
    "id": 2635,
    "traditional": "緊密",
    "simplified": "紧密",
    "reading": "jǐnmì",
    "meaning": "settle, close together, rapid and intense, compact, inseparable, close",
    "grade": 4
  },
  {
    "id": 2636,
    "traditional": "近代",
    "simplified": "近代",
    "reading": "jìndài",
    "meaning": "the not-very-distant past, modern times, excluding recent decades, (in the context of Chinese history) the period from the Opium Wars until the May 4th Movement (mid-19th century to 1919), capitalist times (pre-1949)",
    "grade": 4
  },
  {
    "id": 2637,
    "traditional": "經典",
    "simplified": "经典",
    "reading": "jīngdiǎn",
    "meaning": "canon, classic, classics, sutra, scriptures, scripture, Bible",
    "grade": 4
  },
  {
    "id": 2638,
    "traditional": "竟然",
    "simplified": "竟然",
    "reading": "jìngrán",
    "meaning": "unexpectedly, to one's surprise",
    "grade": 4
  },
  {
    "id": 2639,
    "traditional": "鏡頭",
    "simplified": "镜头",
    "reading": "jìngtóu",
    "meaning": "training, lens, scene, shot, camera lens, vertex, optical lens",
    "grade": 4
  },
  {
    "id": 2640,
    "traditional": "精力",
    "simplified": "精力",
    "reading": "jīnglì",
    "meaning": "stamina, get-up, birr, spirits, vigor, energy, cheer, vinegar, vim, whiz, pride, starch, get-up-and-go, mercury, zing, ginger, sap, verve, zip, git-up, pizazz, power, sinew, go, dynamism, oomph, vigour, foison, ambition, moxie, gimp, peppiness, pazazz, pep, pizzaz, snap, stingo, spirit, drive, pith, pizzazz, last, push, steam",
    "grade": 4
  },
  {
    "id": 2641,
    "traditional": "鏡子",
    "simplified": "镜子",
    "reading": "jìngzi",
    "meaning": "looking_glass, gaper, looking glass, mirror, spectacles, glass, glasses",
    "grade": 4
  },
  {
    "id": 2642,
    "traditional": "酒吧",
    "simplified": "酒吧",
    "reading": "jiǔbā",
    "meaning": "bar, pub, saloon, CL:家[jia1]",
    "grade": 4
  },
  {
    "id": 2643,
    "traditional": "究竟",
    "simplified": "究竟",
    "reading": "jiūjìng",
    "meaning": "actually, exactly, after all, in the end",
    "grade": 4
  },
  {
    "id": 2644,
    "traditional": "居民",
    "simplified": "居民",
    "reading": "jūmín",
    "meaning": "municipality, population, citizen, denizen, townie, people, dweller, populace, residenter, resident, towny, inhabitant, habitant, liver",
    "grade": 4
  },
  {
    "id": 2645,
    "traditional": "局",
    "simplified": "局",
    "reading": "jú",
    "meaning": "m.[event]",
    "grade": 4
  },
  {
    "id": 2646,
    "traditional": "居住",
    "simplified": "居住",
    "reading": "jūzhù",
    "meaning": "inhabit, occupy, shack_up, locate, domicile, shack, abide, subsist, settle, live, howff, reside, indwell, populate, lodge in, quarter, dwell, people, belong, bide, hang_out, house, residence",
    "grade": 4
  },
  {
    "id": 2647,
    "traditional": "距離",
    "simplified": "距离",
    "reading": "jùlí",
    "meaning": "distance",
    "grade": 4
  },
  {
    "id": 2648,
    "traditional": "巨大",
    "simplified": "巨大",
    "reading": "jùdà",
    "meaning": "gigantic, gargantuan, huge",
    "grade": 4
  },
  {
    "id": 2649,
    "traditional": "聚會",
    "simplified": "聚会",
    "reading": "jùhuì",
    "meaning": "get-together, gathering",
    "grade": 4
  },
  {
    "id": 2650,
    "traditional": "聚",
    "simplified": "聚",
    "reading": "jù",
    "meaning": "get together, assemble, forgather, gather",
    "grade": 4
  },
  {
    "id": 2651,
    "traditional": "具備",
    "simplified": "具备",
    "reading": "jùbèi",
    "meaning": "be provided with, supply, render, have, furnish, possess, provide, possession",
    "grade": 4
  },
  {
    "id": 2652,
    "traditional": "角色",
    "simplified": "角色",
    "reading": "juésè",
    "meaning": "theatrical role, character, persona, part, function, office, fictional character, actor, fictitious character, personage, role",
    "grade": 4
  },
  {
    "id": 2653,
    "traditional": "開花",
    "simplified": "开花",
    "reading": "kāi huā",
    "meaning": "burst forth, flower, bloom, split apart, explode, blossom, effloresce, unfold, feel elated, break apart, blow",
    "grade": 4
  },
  {
    "id": 2654,
    "traditional": "捲",
    "simplified": "卷",
    "reading": "juǎn",
    "meaning": "volume, coil, furl, roll, spool, documents, m.[general], ,part, data file, tome, reel, book, examination paper, cylindrical mass of something, file, dossier",
    "grade": 4
  },
  {
    "id": 2655,
    "traditional": "開水",
    "simplified": "开水",
    "reading": "kāishuǐ",
    "meaning": "boiled water, boiling water",
    "grade": 4
  },
  {
    "id": 2656,
    "traditional": "看來",
    "simplified": "看来",
    "reading": "kànlai",
    "meaning": "it looks as if, it appears, it seems, it look as if",
    "grade": 4
  },
  {
    "id": 2657,
    "traditional": "看不起",
    "simplified": "看不起",
    "reading": "kànbuqǐ",
    "meaning": "to look down upon, to despise",
    "grade": 4
  },
  {
    "id": 2658,
    "traditional": "看望",
    "simplified": "看望",
    "reading": "kànwang",
    "meaning": "to visit, to pay a call to",
    "grade": 4
  },
  {
    "id": 2659,
    "traditional": "考察",
    "simplified": "考察",
    "reading": "kǎochá",
    "meaning": "inspect, make on-the-spot investigation, observe and study",
    "grade": 4
  },
  {
    "id": 2660,
    "traditional": "考慮",
    "simplified": "考虑",
    "reading": "kǎolǜ",
    "meaning": "think over, consider",
    "grade": 4
  },
  {
    "id": 2661,
    "traditional": "可見",
    "simplified": "可见",
    "reading": "kějiàn",
    "meaning": "therefore",
    "grade": 4
  },
  {
    "id": 2662,
    "traditional": "空間",
    "simplified": "空间",
    "reading": "kōngjiān",
    "meaning": "elbow room, allowance, margin, roomage, open air/sky/space, road, leeway, way, open air, sheets, room, tolerance, opening, clear, sky, spacial, interspace, vacuity, spatial, dimension, open sky/space, space, place, vacuum",
    "grade": 4
  },
  {
    "id": 2663,
    "traditional": "空",
    "simplified": "空",
    "reading": "kōng",
    "meaning": "free, with spare time",
    "grade": 4
  },
  {
    "id": 2664,
    "traditional": "棵",
    "simplified": "棵",
    "reading": "kē",
    "meaning": "head, tuft, m.[general],",
    "grade": 4
  },
  {
    "id": 2665,
    "traditional": "口袋",
    "simplified": "口袋",
    "reading": "kǒudai",
    "meaning": "pocket",
    "grade": 4
  },
  {
    "id": 2666,
    "traditional": "苦",
    "simplified": "苦",
    "reading": "kǔ",
    "meaning": "be troubled by, suffer from, cause sb. suffering, bitter",
    "grade": 4
  },
  {
    "id": 2667,
    "traditional": "口語",
    "simplified": "口语",
    "reading": "kǒuyǔ",
    "meaning": "colloquial speech, spoken language, vernacular language, slander, gossip, CL:門|门[men2]",
    "grade": 4
  },
  {
    "id": 2668,
    "traditional": "快遞",
    "simplified": "快递",
    "reading": "kuàidì",
    "meaning": "express delivery",
    "grade": 4
  },
  {
    "id": 2669,
    "traditional": "寬廣",
    "simplified": "宽广",
    "reading": "kuānguǎng",
    "meaning": "wide, vast, extensive, broad",
    "grade": 4
  },
  {
    "id": 2670,
    "traditional": "礦泉水",
    "simplified": "矿泉水",
    "reading": "kuàngquánshuǐ",
    "meaning": "mineral spring water, CL:瓶[ping2],杯[bei1]",
    "grade": 4
  },
  {
    "id": 2671,
    "traditional": "會計",
    "simplified": "会计",
    "reading": "kuàiji",
    "meaning": "accountant, bookkeeper",
    "grade": 4
  },
  {
    "id": 2672,
    "traditional": "擴大",
    "simplified": "扩大",
    "reading": "kuòdà",
    "meaning": "enlarge, expand, extend, swell, dilate",
    "grade": 4
  },
  {
    "id": 2673,
    "traditional": "擴展",
    "simplified": "扩展",
    "reading": "kuòzhǎn",
    "meaning": "expand, spread, extend",
    "grade": 4
  },
  {
    "id": 2674,
    "traditional": "寬",
    "simplified": "宽",
    "reading": "kuān",
    "meaning": "wide, broad, generous, lenient, comfortably off, well-off, relax, relieve, extend",
    "grade": 4
  },
  {
    "id": 2675,
    "traditional": "垃圾",
    "simplified": "垃圾",
    "reading": "lājī",
    "meaning": "leavings, crap, sordes, trumpery, culch, garbage, filth, debris, cultch, sweeping, trashery, raffle, slush, truck, detritus, rubbish, trash, dirt, muck, litter, offscouring, offal, rubble, recrement, dust, rejectamenta, refuse, junk, gook",
    "grade": 4
  },
  {
    "id": 2676,
    "traditional": "括號",
    "simplified": "括号",
    "reading": "kuòhào",
    "meaning": "parentheses, brackets",
    "grade": 4
  },
  {
    "id": 2677,
    "traditional": "拉開",
    "simplified": "拉开",
    "reading": "lā kai",
    "meaning": "to pull open, to pull apart, to space out, to increase",
    "grade": 4
  },
  {
    "id": 2678,
    "traditional": "來不及",
    "simplified": "来不及",
    "reading": "láibují",
    "meaning": "be too late to do sth.,",
    "grade": 4
  },
  {
    "id": 2679,
    "traditional": "辣",
    "simplified": "辣",
    "reading": "là",
    "meaning": "hot (spicy), pungent",
    "grade": 4
  },
  {
    "id": 2680,
    "traditional": "來得及",
    "simplified": "来得及",
    "reading": "láidejí",
    "meaning": "there's still time, able to do sth in time",
    "grade": 4
  },
  {
    "id": 2681,
    "traditional": "來源",
    "simplified": "来源",
    "reading": "láiyuán",
    "meaning": "resource, wellspring, origin, provenance, quarter, root, source, beginning, springhead, fodder, fount, quarry, fountain, mint, parentage, whence, paternity",
    "grade": 4
  },
  {
    "id": 2682,
    "traditional": "老家",
    "simplified": "老家",
    "reading": "lǎojiā",
    "meaning": "native place, old home, one's original home",
    "grade": 4
  },
  {
    "id": 2683,
    "traditional": "老婆",
    "simplified": "老婆",
    "reading": "lǎopo",
    "meaning": "old lady, broomstick, missis, missus, married woman, squaw, old_lady, wife",
    "grade": 4
  },
  {
    "id": 2684,
    "traditional": "老公",
    "simplified": "老公",
    "reading": "lǎogong",
    "meaning": "old man, eunuch, married man, hubby, husband",
    "grade": 4
  },
  {
    "id": 2685,
    "traditional": "老實",
    "simplified": "老实",
    "reading": "lǎoshi",
    "meaning": "honest, frank, well-behaved, good, simple-minded, naive",
    "grade": 4
  },
  {
    "id": 2686,
    "traditional": "淚",
    "simplified": "泪",
    "reading": "lèi",
    "meaning": "tear, lachrymal, teardrop, brine, eyewater",
    "grade": 4
  },
  {
    "id": 2687,
    "traditional": "淚水",
    "simplified": "泪水",
    "reading": "lèishuǐ",
    "meaning": "teardrop, tears",
    "grade": 4
  },
  {
    "id": 2688,
    "traditional": "樂趣",
    "simplified": "乐趣",
    "reading": "lèqù",
    "meaning": "amusement, playfulness, fun, merriment, pleasure, sweet, delight, bang, entertainment, enjoyment, pleasance, joy",
    "grade": 4
  },
  {
    "id": 2689,
    "traditional": "類型",
    "simplified": "类型",
    "reading": "lèixíng",
    "meaning": "category, species, genre, portrait, nature, stamp, cast, type",
    "grade": 4
  },
  {
    "id": 2690,
    "traditional": "釐米",
    "simplified": "厘米",
    "reading": "límǐ",
    "meaning": "centimeter",
    "grade": 4
  },
  {
    "id": 2691,
    "traditional": "冷靜",
    "simplified": "冷静",
    "reading": "lěngjìng",
    "meaning": "sober, calm",
    "grade": 4
  },
  {
    "id": 2692,
    "traditional": "離不開",
    "simplified": "离不开",
    "reading": "lí bukāi",
    "meaning": "inseparable, inevitably linked to",
    "grade": 4
  },
  {
    "id": 2693,
    "traditional": "力氣",
    "simplified": "力气",
    "reading": "lìqi",
    "meaning": "physical strength, effort",
    "grade": 4
  },
  {
    "id": 2694,
    "traditional": "歷史",
    "simplified": "历史",
    "reading": "lìshǐ",
    "meaning": "past records, historical, history",
    "grade": 4
  },
  {
    "id": 2695,
    "traditional": "立即",
    "simplified": "立即",
    "reading": "lìjí",
    "meaning": "at_once, off-hand, promptly, at once, down, instantly, directively, straightway, herewith, thereupon, ex tempore, without_delay, pronto, right_away, instant, straight, immediately, in_short_order, right away, instanter, straightaway, in short order, off-the-cuff",
    "grade": 4
  },
  {
    "id": 2696,
    "traditional": "利益",
    "simplified": "利益",
    "reading": "lìyì",
    "meaning": "privilege, profit, vantage, favor, interest, behoof, account, goodness, gain, increment, avail, boon, plus, commodity, lucre, advantage, stead, boot, benefit, welfare, good, asset, behalf, percentage, stake, favour",
    "grade": 4
  },
  {
    "id": 2697,
    "traditional": "利息",
    "simplified": "利息",
    "reading": "lìxī",
    "meaning": "premium, usury, accrual, interest",
    "grade": 4
  },
  {
    "id": 2698,
    "traditional": "量",
    "simplified": "量",
    "reading": "liàng",
    "meaning": "to measure, capacity, quantity, amount, to estimate, abbr. for 量詞|量词[liang4 ci2], classifier (in Chinese grammar), measure word",
    "grade": 4
  },
  {
    "id": 2699,
    "traditional": "良好",
    "simplified": "良好",
    "reading": "liánghǎo",
    "meaning": "good, well",
    "grade": 4
  },
  {
    "id": 2700,
    "traditional": "兩邊",
    "simplified": "两边",
    "reading": "liǎngbiān",
    "meaning": "either side, both sides",
    "grade": 4
  },
  {
    "id": 2701,
    "traditional": "糧食",
    "simplified": "粮食",
    "reading": "liángshi",
    "meaning": "victuals, victualage, provisions, nutrient, ration, sustentation, provision, provender, food, purveyance, sustenance, foodstuff, commissariat, cereals, bread, viands, grain",
    "grade": 4
  },
  {
    "id": 2702,
    "traditional": "了不起",
    "simplified": "了不起",
    "reading": "liǎobuqǐ",
    "meaning": "amazing, terrific, extraordinary",
    "grade": 4
  },
  {
    "id": 2703,
    "traditional": "療養",
    "simplified": "疗养",
    "reading": "liáoyǎng",
    "meaning": "to get well, to heal, to recuperate, to convalesce, convalescence, to nurse",
    "grade": 4
  },
  {
    "id": 2704,
    "traditional": "了解",
    "simplified": "了解",
    "reading": "liǎojiě",
    "meaning": "understand, comprehend, find out, acquaint oneself with",
    "grade": 4
  },
  {
    "id": 2705,
    "traditional": "列",
    "simplified": "列",
    "reading": "liè",
    "meaning": "arrange, line up, list, enter in a list",
    "grade": 4
  },
  {
    "id": 2706,
    "traditional": "列車",
    "simplified": "列车",
    "reading": "lièchē",
    "meaning": "train",
    "grade": 4
  },
  {
    "id": 2707,
    "traditional": "列入",
    "simplified": "列入",
    "reading": "lièrù",
    "meaning": "be listed, be listed/placed, placed, be placed, rank",
    "grade": 4
  },
  {
    "id": 2708,
    "traditional": "列為",
    "simplified": "列为",
    "reading": "lièwéi",
    "meaning": "be classified as",
    "grade": 4
  },
  {
    "id": 2709,
    "traditional": "倆",
    "simplified": "俩",
    "reading": "liǎ",
    "meaning": "two (colloquial equivalent of 兩個|两个), both, some",
    "grade": 4
  },
  {
    "id": 2710,
    "traditional": "臨時",
    "simplified": "临时",
    "reading": "línshí",
    "meaning": "as the time draws near, at the last moment, temporary, interim, ad hoc",
    "grade": 4
  },
  {
    "id": 2711,
    "traditional": "零食",
    "simplified": "零食",
    "reading": "língshí",
    "meaning": "between-meal nibbles, snacks",
    "grade": 4
  },
  {
    "id": 2712,
    "traditional": "流傳",
    "simplified": "流传",
    "reading": "liúchuán",
    "meaning": "spread, circulate, hand down",
    "grade": 4
  },
  {
    "id": 2713,
    "traditional": "樓梯",
    "simplified": "楼梯",
    "reading": "lóutī",
    "meaning": "stairs, staircase",
    "grade": 4
  },
  {
    "id": 2714,
    "traditional": "陸續",
    "simplified": "陆续",
    "reading": "lùxù",
    "meaning": "continuously, one after another, in succession",
    "grade": 4
  },
  {
    "id": 2715,
    "traditional": "陸地",
    "simplified": "陆地",
    "reading": "lùdì",
    "meaning": "dry land (as opposed to the sea)",
    "grade": 4
  },
  {
    "id": 2716,
    "traditional": "律師",
    "simplified": "律师",
    "reading": "lǜshī",
    "meaning": "scribe, counsellor, advocate, gown, avocat, solicitor, barrister, lawyer, counsel, jurist, counselor, counselor-at-law, attorney",
    "grade": 4
  },
  {
    "id": 2717,
    "traditional": "錄取",
    "simplified": "录取",
    "reading": "lùqǔ",
    "meaning": "enroll, recruit, admit",
    "grade": 4
  },
  {
    "id": 2718,
    "traditional": "輪",
    "simplified": "轮",
    "reading": "lún",
    "meaning": "m.[event]",
    "grade": 4
  },
  {
    "id": 2719,
    "traditional": "輪椅",
    "simplified": "轮椅",
    "reading": "lúnyǐ",
    "meaning": "wheelchair",
    "grade": 4
  },
  {
    "id": 2720,
    "traditional": "論文",
    "simplified": "论文",
    "reading": "lùnwén",
    "meaning": "disquisition, memoir, paper, discourse, theme, treatise, thesis, article, tractate, dissertation",
    "grade": 4
  },
  {
    "id": 2721,
    "traditional": "輪船",
    "simplified": "轮船",
    "reading": "lúnchuán",
    "meaning": "steamship, steamer, ocean liner, ship, CL:艘[sou1]",
    "grade": 4
  },
  {
    "id": 2722,
    "traditional": "落",
    "simplified": "落",
    "reading": "luò",
    "meaning": "come down, have, remain where one is, go under, leave behind, lower, decline, stay behind, lag behind, dip, precipitate, go down, be missing, fall/leave/stay behind, belong to, set (of sun), drop, ebb (of tide), set, receive, set down, drop behind, light, rest with, obtain, fall, leave/stay behind, fall onto, turn over to, fall behind, sink, get, ebb, land, leave out",
    "grade": 4
  },
  {
    "id": 2723,
    "traditional": "輪子",
    "simplified": "轮子",
    "reading": "lúnzi",
    "meaning": "wheel, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 2724,
    "traditional": "毛巾",
    "simplified": "毛巾",
    "reading": "máojīn",
    "meaning": "washcloth, flannel, Turkish towel, face towel, face cloth, hand_towel, towel, washrag, napkin, terry towel, hand towel",
    "grade": 4
  },
  {
    "id": 2725,
    "traditional": "毛衣",
    "simplified": "毛衣",
    "reading": "máoyī",
    "meaning": "(wool) sweater, CL:件[jian4]",
    "grade": 4
  },
  {
    "id": 2726,
    "traditional": "帽子",
    "simplified": "帽子",
    "reading": "màozi",
    "meaning": "castor, lid, cap, brand, headgear, chapeau, titfer, hat, headpiece, label, amice, tag",
    "grade": 4
  },
  {
    "id": 2727,
    "traditional": "沒法兒",
    "simplified": "没法儿",
    "reading": "méifǎr5",
    "meaning": "Can't",
    "grade": 4
  },
  {
    "id": 2728,
    "traditional": "沒錯",
    "simplified": "没错",
    "reading": "méicuò",
    "meaning": "that's right, sure!, rest assured!, that's good, can't go wrong",
    "grade": 4
  },
  {
    "id": 2729,
    "traditional": "沒想到",
    "simplified": "没想到",
    "reading": "méixiǎngdào",
    "meaning": "unexpectedly",
    "grade": 4
  },
  {
    "id": 2730,
    "traditional": "美金",
    "simplified": "美金",
    "reading": "měijīn",
    "meaning": "buck, dollar, U.S. dollar",
    "grade": 4
  },
  {
    "id": 2731,
    "traditional": "美女",
    "simplified": "美女",
    "reading": "měinǚ",
    "meaning": "looker, bombshell, siren, cookie, peach, dish, sweetheart, peri, lulu, beautiful woman, charmer, smasher, belle, knockout, mantrap, Venus, stunner, beauty, lovely",
    "grade": 4
  },
  {
    "id": 2732,
    "traditional": "夢見",
    "simplified": "梦见",
    "reading": "mèngjiàn",
    "meaning": "to dream about (sth or sb), to see in a dream",
    "grade": 4
  },
  {
    "id": 2733,
    "traditional": "夢想",
    "simplified": "梦想",
    "reading": "mèngxiǎng",
    "meaning": "fond dream",
    "grade": 4
  },
  {
    "id": 2734,
    "traditional": "夢",
    "simplified": "梦",
    "reading": "mèng",
    "meaning": "dream",
    "grade": 4
  },
  {
    "id": 2735,
    "traditional": "祕密",
    "simplified": "秘密",
    "reading": "mìmì",
    "meaning": "secret",
    "grade": 4
  },
  {
    "id": 2736,
    "traditional": "祕書",
    "simplified": "秘书",
    "reading": "mìshū",
    "meaning": "secretarial, secretary, clerk, amanuensis, secretarial assistant",
    "grade": 4
  },
  {
    "id": 2737,
    "traditional": "密碼",
    "simplified": "密码",
    "reading": "mìmǎ",
    "meaning": "code, secret code, password, pin number",
    "grade": 4
  },
  {
    "id": 2738,
    "traditional": "密",
    "simplified": "密",
    "reading": "mì",
    "meaning": "dense, thick, intimate, close",
    "grade": 4
  },
  {
    "id": 2739,
    "traditional": "密切",
    "simplified": "密切",
    "reading": "mìqiē",
    "meaning": "osculate, intimate, close",
    "grade": 4
  },
  {
    "id": 2740,
    "traditional": "面臨",
    "simplified": "面临",
    "reading": "miànlín",
    "meaning": "have, face, confront, envisage, flank, present, be faced with, be up against, bide, meet",
    "grade": 4
  },
  {
    "id": 2741,
    "traditional": "免費",
    "simplified": "免费",
    "reading": "miǎn fèi",
    "meaning": "be free of charge, free_of_charge, gratuitous, gratis",
    "grade": 4
  },
  {
    "id": 2742,
    "traditional": "面試",
    "simplified": "面试",
    "reading": "miànshì",
    "meaning": "to be interviewed (as a candidate), interview",
    "grade": 4
  },
  {
    "id": 2743,
    "traditional": "描寫",
    "simplified": "描写",
    "reading": "miáoxiě",
    "meaning": "describe, depict, portray",
    "grade": 4
  },
  {
    "id": 2744,
    "traditional": "描述",
    "simplified": "描述",
    "reading": "miáoshù",
    "meaning": "describe",
    "grade": 4
  },
  {
    "id": 2745,
    "traditional": "名牌兒",
    "simplified": "名牌儿",
    "reading": "míngpáir5",
    "meaning": "Famous brand",
    "grade": 4
  },
  {
    "id": 2746,
    "traditional": "名人",
    "simplified": "名人",
    "reading": "míngrén",
    "meaning": "notability, luminary, celebrity, don, celeb, hotshot, lion, famous person, eminent peron, anybody, name, adept, star, figure, man of mark, proficient, performer, personality, social lion, nob, famous peron, guiding light, article, eminent person, boss, famous/eminent person, famous, toff, leading light, notable, swell, personage",
    "grade": 4
  },
  {
    "id": 2747,
    "traditional": "名片",
    "simplified": "名片",
    "reading": "míngpiàn",
    "meaning": "visiting_card, calling card, card, pasteboard, visiting card, carte, namecard, calling_card, visiting",
    "grade": 4
  },
  {
    "id": 2748,
    "traditional": "摸",
    "simplified": "摸",
    "reading": "mō",
    "meaning": "feel out, sound out, fumble, feel for, palpate, feel, stroke, finger, try to find out, touch, grope for, feel/sound out",
    "grade": 4
  },
  {
    "id": 2749,
    "traditional": "模特兒",
    "simplified": "模特儿",
    "reading": "mótèr5",
    "meaning": "poser, artist's model, model",
    "grade": 4
  },
  {
    "id": 2750,
    "traditional": "模型",
    "simplified": "模型",
    "reading": "móxíng",
    "meaning": "formula, pattern, mould, mold, modeling, construct, die, convention, exponent, plaything, former, modelling, model, simulation, normal, rule, moulding, molding, shape, matrix, clay sculpture, restoration, toy",
    "grade": 4
  },
  {
    "id": 2751,
    "traditional": "末",
    "simplified": "末",
    "reading": "mò",
    "meaning": "tip, end",
    "grade": 4
  },
  {
    "id": 2752,
    "traditional": "默默",
    "simplified": "默默",
    "reading": "mòmò",
    "meaning": "quietly, silently",
    "grade": 4
  },
  {
    "id": 2753,
    "traditional": "哪",
    "simplified": "哪",
    "reading": "nǎ",
    "meaning": "det.: which",
    "grade": 4
  },
  {
    "id": 2754,
    "traditional": "男女",
    "simplified": "男女",
    "reading": "nánnǚ",
    "meaning": "men and women",
    "grade": 4
  },
  {
    "id": 2755,
    "traditional": "男士",
    "simplified": "男士",
    "reading": "nánshì",
    "meaning": "gent, man, men, menfolk",
    "grade": 4
  },
  {
    "id": 2756,
    "traditional": "難免",
    "simplified": "难免",
    "reading": "nánmiǎn",
    "meaning": "hard to avoid",
    "grade": 4
  },
  {
    "id": 2757,
    "traditional": "哪怕",
    "simplified": "哪怕",
    "reading": "nǎpà",
    "meaning": "even, even if, even though, no matter how",
    "grade": 4
  },
  {
    "id": 2758,
    "traditional": "腦袋",
    "simplified": "脑袋",
    "reading": "nǎodai",
    "meaning": "head",
    "grade": 4
  },
  {
    "id": 2759,
    "traditional": "鬧",
    "simplified": "闹",
    "reading": "nào",
    "meaning": "give vent, be troubled by, give vent to anger, suffer from, make a noise, do, stir up trouble, make, go in for",
    "grade": 4
  },
  {
    "id": 2760,
    "traditional": "鬧鐘",
    "simplified": "闹钟",
    "reading": "nàozhōng",
    "meaning": "alarm clock",
    "grade": 4
  },
  {
    "id": 2761,
    "traditional": "內部",
    "simplified": "内部",
    "reading": "nèibù",
    "meaning": "entrails, bowel, interior, inner, within, bosom, inside, innards, internal, inward",
    "grade": 4
  },
  {
    "id": 2762,
    "traditional": "內科",
    "simplified": "内科",
    "reading": "nèikē",
    "meaning": "internal medicine, general medicine",
    "grade": 4
  },
  {
    "id": 2763,
    "traditional": "能幹",
    "simplified": "能干",
    "reading": "nénggàn",
    "meaning": "capable, competent",
    "grade": 4
  },
  {
    "id": 2764,
    "traditional": "寧靜",
    "simplified": "宁静",
    "reading": "níngjìng",
    "meaning": "peaceful, tranquil, quiet",
    "grade": 4
  },
  {
    "id": 2765,
    "traditional": "濃",
    "simplified": "浓",
    "reading": "nóng",
    "meaning": "thick, concentrated, stress, favor, dense",
    "grade": 4
  },
  {
    "id": 2766,
    "traditional": "女士",
    "simplified": "女士",
    "reading": "nǚshì",
    "meaning": "senorita, lady, Ms., mum, Senora, educated girl, educated woman, madonna, madam, Miss, woman, ma'am, Madame, madame, gentlewoman, senhorita, dame, mademoiselle",
    "grade": 4
  },
  {
    "id": 2767,
    "traditional": "暖氣",
    "simplified": "暖气",
    "reading": "nuǎnqì",
    "meaning": "central heating, heater, warm air",
    "grade": 4
  },
  {
    "id": 2768,
    "traditional": "拍照",
    "simplified": "拍照",
    "reading": "pāi zhào",
    "meaning": "take a picture, take (picture), shoot (film), snap, film, take a photograph, take, photograph, shoot",
    "grade": 4
  },
  {
    "id": 2769,
    "traditional": "排列",
    "simplified": "排列",
    "reading": "páiliè",
    "meaning": "arrange, put in order",
    "grade": 4
  },
  {
    "id": 2770,
    "traditional": "牌",
    "simplified": "牌",
    "reading": "pái",
    "meaning": "dominoes, plate, prosodic pattern for ^1 ci2 ^ or ^3 qu3, card, cards, cards, dominoes, etc., brand, tablet, prosodic pattern for ^1ci2^ or ^3qu3^",
    "grade": 4
  },
  {
    "id": 2771,
    "traditional": "盤",
    "simplified": "盘",
    "reading": "pán",
    "meaning": "m.[event]",
    "grade": 4
  },
  {
    "id": 2772,
    "traditional": "盤子",
    "simplified": "盘子",
    "reading": "pánzi",
    "meaning": "plate, pan, salver, bowl, dish, tray",
    "grade": 4
  },
  {
    "id": 2773,
    "traditional": "胖子",
    "simplified": "胖子",
    "reading": "pàngzi",
    "meaning": "fat person, fatty",
    "grade": 4
  },
  {
    "id": 2774,
    "traditional": "培訓",
    "simplified": "培训",
    "reading": "péixùn",
    "meaning": "cultivate, train",
    "grade": 4
  },
  {
    "id": 2775,
    "traditional": "培訓班",
    "simplified": "培训班",
    "reading": "péixùnbān",
    "meaning": "training class",
    "grade": 4
  },
  {
    "id": 2776,
    "traditional": "培養",
    "simplified": "培养",
    "reading": "péiyǎng",
    "meaning": "foster, train, develop, culture",
    "grade": 4
  },
  {
    "id": 2777,
    "traditional": "批",
    "simplified": "批",
    "reading": "pī",
    "meaning": "m.[general]",
    "grade": 4
  },
  {
    "id": 2778,
    "traditional": "培育",
    "simplified": "培育",
    "reading": "péiyù",
    "meaning": "cultivate, foster, breed",
    "grade": 4
  },
  {
    "id": 2779,
    "traditional": "品質",
    "simplified": "品质",
    "reading": "pǐnzhì",
    "meaning": "calibre, appraisal, caliber, character, self, intrinsic, brew, quality, timber, sort",
    "grade": 4
  },
  {
    "id": 2780,
    "traditional": "片面",
    "simplified": "片面",
    "reading": "piànmiàn",
    "meaning": "unilateral, one-sided",
    "grade": 4
  },
  {
    "id": 2781,
    "traditional": "平方",
    "simplified": "平方",
    "reading": "píngfāng",
    "meaning": "square (as in square foot, square mile, square root)",
    "grade": 4
  },
  {
    "id": 2782,
    "traditional": "平均",
    "simplified": "平均",
    "reading": "píngjūn",
    "meaning": "average",
    "grade": 4
  },
  {
    "id": 2783,
    "traditional": "平靜",
    "simplified": "平静",
    "reading": "píngjìng",
    "meaning": "calm, quiet, tranquil",
    "grade": 4
  },
  {
    "id": 2784,
    "traditional": "迫切",
    "simplified": "迫切",
    "reading": "pòqiè",
    "meaning": "imminent, imperative, pressing, urgent",
    "grade": 4
  },
  {
    "id": 2785,
    "traditional": "平穩",
    "simplified": "平稳",
    "reading": "píngwěn",
    "meaning": "smooth, steady",
    "grade": 4
  },
  {
    "id": 2786,
    "traditional": "妻子",
    "simplified": "妻子",
    "reading": "qīzi",
    "meaning": "frow, feme, married woman, wifelike, wife, woman, femme, missis, squaw",
    "grade": 4
  },
  {
    "id": 2787,
    "traditional": "破產",
    "simplified": "破产",
    "reading": "pòchǎn",
    "meaning": "go bankrupt, come to naught",
    "grade": 4
  },
  {
    "id": 2788,
    "traditional": "期末",
    "simplified": "期末",
    "reading": "qīmò",
    "meaning": "end of term",
    "grade": 4
  },
  {
    "id": 2789,
    "traditional": "期待",
    "simplified": "期待",
    "reading": "qīdài",
    "meaning": "look forward to",
    "grade": 4
  },
  {
    "id": 2790,
    "traditional": "期間",
    "simplified": "期间",
    "reading": "qījiān",
    "meaning": "length, period, time period, amount of time, duration, course, interim, period of time, time, standing, chapter, tract, space, term, streak, efflux",
    "grade": 4
  },
  {
    "id": 2791,
    "traditional": "期中",
    "simplified": "期中",
    "reading": "qīzhōng",
    "meaning": "interim, midterm",
    "grade": 4
  },
  {
    "id": 2792,
    "traditional": "期限",
    "simplified": "期限",
    "reading": "qīxiàn",
    "meaning": "time limit, allowed time, deadline, term",
    "grade": 4
  },
  {
    "id": 2793,
    "traditional": "企業",
    "simplified": "企业",
    "reading": "qǐyè",
    "meaning": "proposition, business enterprise, business organization, business concern, concern, commercial enterprise, endeavour, endeavor, industry, business, project, corporation, enterprise",
    "grade": 4
  },
  {
    "id": 2794,
    "traditional": "氣球",
    "simplified": "气球",
    "reading": "qìqiú",
    "meaning": "balloon",
    "grade": 4
  },
  {
    "id": 2795,
    "traditional": "其餘",
    "simplified": "其余",
    "reading": "qíyú",
    "meaning": "etceteras, det.: others, remainder, the rest",
    "grade": 4
  },
  {
    "id": 2796,
    "traditional": "汽水",
    "simplified": "汽水",
    "reading": "qìshuǐ",
    "meaning": "soda, pop",
    "grade": 4
  },
  {
    "id": 2797,
    "traditional": "器官",
    "simplified": "器官",
    "reading": "qìguān",
    "meaning": "appendage, member, variety meat, penis, apparatus, organs, organ, transplant, phallus, extremity",
    "grade": 4
  },
  {
    "id": 2798,
    "traditional": "前途",
    "simplified": "前途",
    "reading": "qiántú",
    "meaning": "lookout, future, prospects, prospect, futurity, career",
    "grade": 4
  },
  {
    "id": 2799,
    "traditional": "汽油",
    "simplified": "汽油",
    "reading": "qìyóu",
    "meaning": "gasoline",
    "grade": 4
  },
  {
    "id": 2800,
    "traditional": "淺",
    "simplified": "浅",
    "reading": "qiǎn",
    "meaning": "shallow, superficial, easy, simple, light (of color)",
    "grade": 4
  },
  {
    "id": 2801,
    "traditional": "前頭",
    "simplified": "前头",
    "reading": "qiántou",
    "meaning": "in front, at the head, ahead, above",
    "grade": 4
  },
  {
    "id": 2802,
    "traditional": "巧克力",
    "simplified": "巧克力",
    "reading": "qiǎokèlì",
    "meaning": "chocolate (loanword), CL:塊|块[kuai4]",
    "grade": 4
  },
  {
    "id": 2803,
    "traditional": "親密",
    "simplified": "亲密",
    "reading": "qīnmì",
    "meaning": "chum, intimate, close",
    "grade": 4
  },
  {
    "id": 2804,
    "traditional": "親愛",
    "simplified": "亲爱",
    "reading": "qīnài",
    "meaning": "dear, beloved, darling",
    "grade": 4
  },
  {
    "id": 2805,
    "traditional": "切",
    "simplified": "切",
    "reading": "qiè",
    "meaning": "cut, slice",
    "grade": 4
  },
  {
    "id": 2806,
    "traditional": "青春",
    "simplified": "青春",
    "reading": "qīngchūn",
    "meaning": "springtime, flower, prime, juvenility, youthfulness, one's youth, young adulthood, youth, prime of life, youthhood, juvenescence, may",
    "grade": 4
  },
  {
    "id": 2807,
    "traditional": "輕鬆",
    "simplified": "轻松",
    "reading": "qīngsōng",
    "meaning": "light, relaxed",
    "grade": 4
  },
  {
    "id": 2808,
    "traditional": "輕易",
    "simplified": "轻易",
    "reading": "qīngyi",
    "meaning": "lightly, rashly",
    "grade": 4
  },
  {
    "id": 2809,
    "traditional": "清醒",
    "simplified": "清醒",
    "reading": "qīngxǐng",
    "meaning": "waken, wake up, sober up, be wide-awake, be clear-headed, wide-awake, wake, be sober, awaken, be sober/wide-awake, sober, come alive, awake, come to, regain consciousness, arouse, sober_up",
    "grade": 4
  },
  {
    "id": 2810,
    "traditional": "情景",
    "simplified": "情景",
    "reading": "qíngjǐng",
    "meaning": "scene, sight, circumstances",
    "grade": 4
  },
  {
    "id": 2811,
    "traditional": "窮",
    "simplified": "穷",
    "reading": "qióng",
    "meaning": "exhaust, exhausted, impoverished, hard-pressed, pursue to limit, poor",
    "grade": 4
  },
  {
    "id": 2812,
    "traditional": "窮人",
    "simplified": "穷人",
    "reading": "qióngrén",
    "meaning": "pauper, poor people, prole, poor, poor man, the poor, beggar, have-not",
    "grade": 4
  },
  {
    "id": 2813,
    "traditional": "趨勢",
    "simplified": "趋势",
    "reading": "qūshì",
    "meaning": "stream, gravitation, tendency, wind, tide, drift, sequel, trend, current, direction, inclination, tendence, momentum, flow",
    "grade": 4
  },
  {
    "id": 2814,
    "traditional": "秋季",
    "simplified": "秋季",
    "reading": "qiūjì",
    "meaning": "autumn, fall",
    "grade": 4
  },
  {
    "id": 2815,
    "traditional": "圈",
    "simplified": "圈",
    "reading": "quān",
    "meaning": "m.[activity]",
    "grade": 4
  },
  {
    "id": 2816,
    "traditional": "權利",
    "simplified": "权利",
    "reading": "quánlì",
    "meaning": "privilege, claim, merit, title, entitlements, escheatage, right, dib, authority, competency, obligation, pretension, prescription, dibs",
    "grade": 4
  },
  {
    "id": 2817,
    "traditional": "卻",
    "simplified": "却",
    "reading": "què",
    "meaning": "however, but, yet, indeed",
    "grade": 4
  },
  {
    "id": 2818,
    "traditional": "確認",
    "simplified": "确认",
    "reading": "quèrèn",
    "meaning": "countersign, confirm, affirmation, identify with certainty, identify, acknowledgement, acknowledge, quiet, confirmation, recognize, seal, verify, uphold, affirm, notarize",
    "grade": 4
  },
  {
    "id": 2819,
    "traditional": "燃料",
    "simplified": "燃料",
    "reading": "ránliào",
    "meaning": "fuel",
    "grade": 4
  },
  {
    "id": 2820,
    "traditional": "然而",
    "simplified": "然而",
    "reading": "ránér",
    "meaning": "conj.: even so, but",
    "grade": 4
  },
  {
    "id": 2821,
    "traditional": "燃燒",
    "simplified": "燃烧",
    "reading": "ránshāo",
    "meaning": "burn",
    "grade": 4
  },
  {
    "id": 2822,
    "traditional": "熱心",
    "simplified": "热心",
    "reading": "rèxīn",
    "meaning": "be enthusiastic about",
    "grade": 4
  },
  {
    "id": 2823,
    "traditional": "熱鬧",
    "simplified": "热闹",
    "reading": "rènao",
    "meaning": "lively, buzzing with excitement",
    "grade": 4
  },
  {
    "id": 2824,
    "traditional": "人家",
    "simplified": "人家",
    "reading": "rénjia",
    "meaning": "I (used by females), dwelling, I, sb. else, sb. else's house, family, household",
    "grade": 4
  },
  {
    "id": 2825,
    "traditional": "日曆",
    "simplified": "日历",
    "reading": "rìlì",
    "meaning": "calendar, CL:張|张[zhang1],本[ben3]",
    "grade": 4
  },
  {
    "id": 2826,
    "traditional": "如今",
    "simplified": "如今",
    "reading": "rújīn",
    "meaning": "nowadays, now",
    "grade": 4
  },
  {
    "id": 2827,
    "traditional": "日記",
    "simplified": "日记",
    "reading": "rìjì",
    "meaning": "diary, journal",
    "grade": 4
  },
  {
    "id": 2828,
    "traditional": "弱",
    "simplified": "弱",
    "reading": "ruò",
    "meaning": "infirm, a little less than, young, inferior, weak, a bit less than, feeble",
    "grade": 4
  },
  {
    "id": 2829,
    "traditional": "散",
    "simplified": "散",
    "reading": "sàn",
    "meaning": "break up",
    "grade": 4
  },
  {
    "id": 2830,
    "traditional": "掃",
    "simplified": "扫",
    "reading": "sǎo",
    "meaning": "to sweep, broom",
    "grade": 4
  },
  {
    "id": 2831,
    "traditional": "色",
    "simplified": "色",
    "reading": "sè",
    "meaning": "color, CL:種|种[zhong3], look, appearance, sex, color, dice",
    "grade": 4
  },
  {
    "id": 2832,
    "traditional": "傘",
    "simplified": "伞",
    "reading": "sǎn",
    "meaning": "fimbria, brolly, sth. shaped like an umbrella, umbrella, mush, gamp, bumbershoot",
    "grade": 4
  },
  {
    "id": 2833,
    "traditional": "色彩",
    "simplified": "色彩",
    "reading": "sècǎi",
    "meaning": "hue, tincture, palette, tint, blazonry, leavening, tinct, grain, color, shade",
    "grade": 4
  },
  {
    "id": 2834,
    "traditional": "閃",
    "simplified": "闪",
    "reading": "shǎn",
    "meaning": "sprain, get out of the way, wink, shine, leave behind, have mishap, twist, sparkle, dodge, flash",
    "grade": 4
  },
  {
    "id": 2835,
    "traditional": "閃電",
    "simplified": "闪电",
    "reading": "shǎndiàn",
    "meaning": "lightning, CL:道[dao4]",
    "grade": 4
  },
  {
    "id": 2836,
    "traditional": "曬",
    "simplified": "晒",
    "reading": "shài",
    "meaning": "air, cold-shoulder, soak up sun, sun-dry, shine on, sun upturned soil",
    "grade": 4
  },
  {
    "id": 2837,
    "traditional": "傷害",
    "simplified": "伤害",
    "reading": "shānghài",
    "meaning": "harm",
    "grade": 4
  },
  {
    "id": 2838,
    "traditional": "善於",
    "simplified": "善于",
    "reading": "shànyú",
    "meaning": "excel, surpass, stand out, be adept in, be good at",
    "grade": 4
  },
  {
    "id": 2839,
    "traditional": "善良",
    "simplified": "善良",
    "reading": "shànliáng",
    "meaning": "good and kind, decent",
    "grade": 4
  },
  {
    "id": 2840,
    "traditional": "商務",
    "simplified": "商务",
    "reading": "shāngwù",
    "meaning": "commercial affairs, commercial, commerce, business",
    "grade": 4
  },
  {
    "id": 2841,
    "traditional": "上個月",
    "simplified": "上个月",
    "reading": "shànggèyuè",
    "meaning": "last month",
    "grade": 4
  },
  {
    "id": 2842,
    "traditional": "賞",
    "simplified": "赏",
    "reading": "shǎng",
    "meaning": "admire, enjoy",
    "grade": 4
  },
  {
    "id": 2843,
    "traditional": "上門",
    "simplified": "上门",
    "reading": "shàng mén",
    "meaning": "to drop in, to visit, to lock a door, (of a shop) to close, to go and live with one's wife's family, in effect becoming a member of her family",
    "grade": 4
  },
  {
    "id": 2844,
    "traditional": "上樓",
    "simplified": "上楼",
    "reading": "shànglóu",
    "meaning": "to go upstairs",
    "grade": 4
  },
  {
    "id": 2845,
    "traditional": "燒",
    "simplified": "烧",
    "reading": "shāo",
    "meaning": "oven broil, roast, burn, broil, run a fever, baking, stew ... in soy sauce, cook, heat, bake, burning, burnt, stew, grill, cooking, have a temperature",
    "grade": 4
  },
  {
    "id": 2846,
    "traditional": "設置",
    "simplified": "设置",
    "reading": "shèzhì",
    "meaning": "set/put up, install",
    "grade": 4
  },
  {
    "id": 2847,
    "traditional": "申請",
    "simplified": "申请",
    "reading": "shēnqǐng",
    "meaning": "apply for",
    "grade": 4
  },
  {
    "id": 2848,
    "traditional": "身材",
    "simplified": "身材",
    "reading": "shēncái",
    "meaning": "stature, figure",
    "grade": 4
  },
  {
    "id": 2849,
    "traditional": "設施",
    "simplified": "设施",
    "reading": "shèshī",
    "meaning": "installation, facilities",
    "grade": 4
  },
  {
    "id": 2850,
    "traditional": "深厚",
    "simplified": "深厚",
    "reading": "shēnhòu",
    "meaning": "deep, profound, solid, deep-seated",
    "grade": 4
  },
  {
    "id": 2851,
    "traditional": "身份",
    "simplified": "身份",
    "reading": "shēnfen",
    "meaning": "state, dignity, station, estate, body, estate of the realm, capacity, status, standing, caste, doctorate, place, identity, condition",
    "grade": 4
  },
  {
    "id": 2852,
    "traditional": "身高",
    "simplified": "身高",
    "reading": "shēngāo",
    "meaning": "height, stature, height (of person)",
    "grade": 4
  },
  {
    "id": 2853,
    "traditional": "神祕",
    "simplified": "神秘",
    "reading": "shénmì",
    "meaning": "mysterious, mystical",
    "grade": 4
  },
  {
    "id": 2854,
    "traditional": "失敗",
    "simplified": "失败",
    "reading": "shībài",
    "meaning": "be defeated, fail, lose",
    "grade": 4
  },
  {
    "id": 2855,
    "traditional": "甚至",
    "simplified": "甚至",
    "reading": "shènzhì",
    "meaning": "even (to the point of), so much so that",
    "grade": 4
  },
  {
    "id": 2856,
    "traditional": "神話",
    "simplified": "神话",
    "reading": "shénhuà",
    "meaning": "fairy story, fairy tale, mythos, mythic, fable, fairytale, myth, mythology",
    "grade": 4
  },
  {
    "id": 2857,
    "traditional": "失業",
    "simplified": "失业",
    "reading": "shī yè",
    "meaning": "lose one's job; be out of work",
    "grade": 4
  },
  {
    "id": 2858,
    "traditional": "詩",
    "simplified": "诗",
    "reading": "shī",
    "meaning": "verse form, poem, canto, verse, rhyme, number, song, poesy, poetry",
    "grade": 4
  },
  {
    "id": 2859,
    "traditional": "詩人",
    "simplified": "诗人",
    "reading": "shīrén",
    "meaning": "muse, rhymist, swan, lark, Parnassus, Maker, minstrel, harmonist, metrist, maker, bulbul, songster, versifier, poet, singer, eisteddfod, bard",
    "grade": 4
  },
  {
    "id": 2860,
    "traditional": "失望",
    "simplified": "失望",
    "reading": "shīwàng",
    "meaning": "become disappointed, lose (hope/faith)",
    "grade": 4
  },
  {
    "id": 2861,
    "traditional": "實用",
    "simplified": "实用",
    "reading": "shíyòng",
    "meaning": "practical, pragmatic, functional",
    "grade": 4
  },
  {
    "id": 2862,
    "traditional": "濕",
    "simplified": "湿",
    "reading": "shī",
    "meaning": "humidify, moisten, humid, damp, dampen, wet",
    "grade": 4
  },
  {
    "id": 2863,
    "traditional": "食堂",
    "simplified": "食堂",
    "reading": "shítáng",
    "meaning": "mess_hall, eatery, hall, buttery, commissariat, (institutional) dining room, mess hall, mess, refectory, dining room, canteen",
    "grade": 4
  },
  {
    "id": 2864,
    "traditional": "實施",
    "simplified": "实施",
    "reading": "shíshī",
    "meaning": "put into effect, implement",
    "grade": 4
  },
  {
    "id": 2865,
    "traditional": "使勁",
    "simplified": "使劲",
    "reading": "shǐ jìn",
    "meaning": "to exert all one's strength",
    "grade": 4
  },
  {
    "id": 2866,
    "traditional": "士兵",
    "simplified": "士兵",
    "reading": "shìbīng",
    "meaning": "rank-and-file soldiers",
    "grade": 4
  },
  {
    "id": 2867,
    "traditional": "市區",
    "simplified": "市区",
    "reading": "shìqū",
    "meaning": "urban area, city proper, row, urban district, downtown, urban_area",
    "grade": 4
  },
  {
    "id": 2868,
    "traditional": "似的",
    "simplified": "似的",
    "reading": "shìde",
    "meaning": "seems as if, rather like, Taiwan pr. [si4 de5]",
    "grade": 4
  },
  {
    "id": 2869,
    "traditional": "事物",
    "simplified": "事物",
    "reading": "shìwù",
    "meaning": "proposition, pigeon, object, thing, affair, un",
    "grade": 4
  },
  {
    "id": 2870,
    "traditional": "事先",
    "simplified": "事先",
    "reading": "shìxiān",
    "meaning": "ahead, beforehand, in advance, in_advance, aforetime, aforehand",
    "grade": 4
  },
  {
    "id": 2871,
    "traditional": "是否",
    "simplified": "是否",
    "reading": "shìfǒu",
    "meaning": "whether or not, is it so or not",
    "grade": 4
  },
  {
    "id": 2872,
    "traditional": "試卷",
    "simplified": "试卷",
    "reading": "shìjuàn",
    "meaning": "examination paper, test paper, CL:份[fen4],張|张[zhang1]",
    "grade": 4
  },
  {
    "id": 2873,
    "traditional": "收回",
    "simplified": "收回",
    "reading": "shōu huí",
    "meaning": "take back, call in, recall, withdraw, countermand",
    "grade": 4
  },
  {
    "id": 2874,
    "traditional": "收益",
    "simplified": "收益",
    "reading": "shōuyì",
    "meaning": "earnings, profit",
    "grade": 4
  },
  {
    "id": 2875,
    "traditional": "收穫",
    "simplified": "收获",
    "reading": "shōuhuò",
    "meaning": "harvest, gains, results",
    "grade": 4
  },
  {
    "id": 2876,
    "traditional": "手裡",
    "simplified": "手里",
    "reading": "shǒuli",
    "meaning": "in hand, (a situation is) in sb's hands",
    "grade": 4
  },
  {
    "id": 2877,
    "traditional": "守",
    "simplified": "守",
    "reading": "shǒu",
    "meaning": "guard, defend, keep watch",
    "grade": 4
  },
  {
    "id": 2878,
    "traditional": "手術",
    "simplified": "手术",
    "reading": "shǒushù",
    "meaning": "operation",
    "grade": 4
  },
  {
    "id": 2879,
    "traditional": "手工",
    "simplified": "手工",
    "reading": "shǒugōng",
    "meaning": "handwork",
    "grade": 4
  },
  {
    "id": 2880,
    "traditional": "受不了",
    "simplified": "受不了",
    "reading": "shòubuliǎo",
    "meaning": "unbearable, unable to endure, can't stand",
    "grade": 4
  },
  {
    "id": 2881,
    "traditional": "手套",
    "simplified": "手套",
    "reading": "shǒutào",
    "meaning": "glove, mitten, CL:雙|双[shuang1],隻|只[zhi1]",
    "grade": 4
  },
  {
    "id": 2882,
    "traditional": "首",
    "simplified": "首",
    "reading": "shǒu",
    "meaning": "m.[general]",
    "grade": 4
  },
  {
    "id": 2883,
    "traditional": "售貨員",
    "simplified": "售货员",
    "reading": "shòuhuòyuán",
    "meaning": "salesperson, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 2884,
    "traditional": "舒適",
    "simplified": "舒适",
    "reading": "shūshì",
    "meaning": "comfortable, cozy",
    "grade": 4
  },
  {
    "id": 2885,
    "traditional": "叔叔",
    "simplified": "叔叔",
    "reading": "shūshu",
    "meaning": "father's younger brother, uncle, uncle (child's address for young males)",
    "grade": 4
  },
  {
    "id": 2886,
    "traditional": "熟練",
    "simplified": "熟练",
    "reading": "shúliàn",
    "meaning": "practiced, proficient, skilled, skillful",
    "grade": 4
  },
  {
    "id": 2887,
    "traditional": "暑假",
    "simplified": "暑假",
    "reading": "shǔjià",
    "meaning": "summer vacation, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 2888,
    "traditional": "樹林",
    "simplified": "树林",
    "reading": "shùlín",
    "meaning": "forest, motte, woods, wood, planting, grove, timberland, woodland, hurst, timber",
    "grade": 4
  },
  {
    "id": 2889,
    "traditional": "數據",
    "simplified": "数据",
    "reading": "shùjù",
    "meaning": "data",
    "grade": 4
  },
  {
    "id": 2890,
    "traditional": "樹葉",
    "simplified": "树叶",
    "reading": "shùyè",
    "meaning": "leaves, foliage, leafage, leaf, leave, leaves (of trees)",
    "grade": 4
  },
  {
    "id": 2891,
    "traditional": "數碼",
    "simplified": "数码",
    "reading": "shùmǎ",
    "meaning": "number, numerals, figures, digital, amount, numerical code",
    "grade": 4
  },
  {
    "id": 2892,
    "traditional": "刷",
    "simplified": "刷",
    "reading": "shuā",
    "meaning": "to brush, to paint, to daub, to paste up, to skip class (of students), to fire from a job, to select",
    "grade": 4
  },
  {
    "id": 2893,
    "traditional": "刷牙",
    "simplified": "刷牙",
    "reading": "shuā yá",
    "meaning": "to brush one's teeth",
    "grade": 4
  },
  {
    "id": 2894,
    "traditional": "刷子",
    "simplified": "刷子",
    "reading": "shuāzi",
    "meaning": "brush, scrub, CL:把[ba3]",
    "grade": 4
  },
  {
    "id": 2895,
    "traditional": "帥",
    "simplified": "帅",
    "reading": "shuài",
    "meaning": "surname Shuai, handsome, graceful, smart, commander in chief, (coll.) cool!, sweet!",
    "grade": 4
  },
  {
    "id": 2896,
    "traditional": "帥哥",
    "simplified": "帅哥",
    "reading": "shuàigē",
    "meaning": "handsome guy, lady-killer, handsome (form of address)",
    "grade": 4
  },
  {
    "id": 2897,
    "traditional": "睡著",
    "simplified": "睡着",
    "reading": "shuìzháo",
    "meaning": "asleep, fall_asleep, go_off, nod off, drift off, doze off, fall asleep, dope off, flake, drowse off, conk, dormant, flake out, drop off",
    "grade": 4
  },
  {
    "id": 2898,
    "traditional": "率先",
    "simplified": "率先",
    "reading": "shuàixiān",
    "meaning": "take lead/initiative",
    "grade": 4
  },
  {
    "id": 2899,
    "traditional": "說不定",
    "simplified": "说不定",
    "reading": "shuōbudìng",
    "meaning": "perhaps, maybe",
    "grade": 4
  },
  {
    "id": 2900,
    "traditional": "說服",
    "simplified": "说服",
    "reading": "shuō fú",
    "meaning": "persuade, talk sb. over",
    "grade": 4
  },
  {
    "id": 2901,
    "traditional": "似乎",
    "simplified": "似乎",
    "reading": "sìhū",
    "meaning": "it seems, quasi, apparently, seemingly, as if",
    "grade": 4
  },
  {
    "id": 2902,
    "traditional": "順序",
    "simplified": "顺序",
    "reading": "shùnxù",
    "meaning": "system, method, taxis, gradation, consecution, succession, order, ranking, rota, sequence, orderliness, turn",
    "grade": 4
  },
  {
    "id": 2903,
    "traditional": "松樹",
    "simplified": "松树",
    "reading": "sōngshù",
    "meaning": "pine, pine tree, CL:棵[ke1]",
    "grade": 4
  },
  {
    "id": 2904,
    "traditional": "思考",
    "simplified": "思考",
    "reading": "sīkǎo",
    "meaning": "ponder over, reflect on",
    "grade": 4
  },
  {
    "id": 2905,
    "traditional": "松",
    "simplified": "松",
    "reading": "sōng",
    "meaning": "crisp, loose, light and flaky, slack, loosen, relax, unbend, soft, not hard up",
    "grade": 4
  },
  {
    "id": 2906,
    "traditional": "塑料袋",
    "simplified": "塑料袋",
    "reading": "sùliàodài",
    "meaning": "plastic bag",
    "grade": 4
  },
  {
    "id": 2907,
    "traditional": "酸",
    "simplified": "酸",
    "reading": "suān",
    "meaning": "sick at heart, grieved, distressed, aching, tingling",
    "grade": 4
  },
  {
    "id": 2908,
    "traditional": "塑料",
    "simplified": "塑料",
    "reading": "sùliào",
    "meaning": "plastics, CL:種|种[zhong3]",
    "grade": 4
  },
  {
    "id": 2909,
    "traditional": "酸奶",
    "simplified": "酸奶",
    "reading": "suānnǎi",
    "meaning": "yogurt",
    "grade": 4
  },
  {
    "id": 2910,
    "traditional": "隨手",
    "simplified": "随手",
    "reading": "suíshǒu",
    "meaning": "conveniently, without extra trouble, while doing it, in passing",
    "grade": 4
  },
  {
    "id": 2911,
    "traditional": "孫子",
    "simplified": "孙子",
    "reading": "sūnzi",
    "meaning": "son's son, grandson",
    "grade": 4
  },
  {
    "id": 2912,
    "traditional": "孫女",
    "simplified": "孙女",
    "reading": "sūnnü",
    "meaning": "son's daughter, granddaughter",
    "grade": 4
  },
  {
    "id": 2913,
    "traditional": "縮短",
    "simplified": "缩短",
    "reading": "suō duǎn",
    "meaning": "shorten, curtail, cut down",
    "grade": 4
  },
  {
    "id": 2914,
    "traditional": "臺階",
    "simplified": "台阶",
    "reading": "táijiē",
    "meaning": "steps, flight of steps, step (over obstacle), fig. way out of an embarrassing situation, bench (geology)",
    "grade": 4
  },
  {
    "id": 2915,
    "traditional": "縮小",
    "simplified": "缩小",
    "reading": "suō xiǎo",
    "meaning": "recede, dwindle away, narrow, foreshorten, shrinkage, narrow down, abridge, minify, reduction, puncture, compress, specialize, scale down, dwindle, deflate, minish, take in, reduce, lessen, shorten, diminish, dwindle down, shrink, subside, deescalate, retract",
    "grade": 4
  },
  {
    "id": 2916,
    "traditional": "臺上",
    "simplified": "台上",
    "reading": "táishàng",
    "meaning": "on stage",
    "grade": 4
  },
  {
    "id": 2917,
    "traditional": "套餐",
    "simplified": "套餐",
    "reading": "tàocān",
    "meaning": "set meal, product or service package (e.g. for a cell phone subscription)",
    "grade": 4
  },
  {
    "id": 2918,
    "traditional": "躺",
    "simplified": "躺",
    "reading": "tǎng",
    "meaning": "couch, lie, lean back, recline",
    "grade": 4
  },
  {
    "id": 2919,
    "traditional": "特殊",
    "simplified": "特殊",
    "reading": "tèshū",
    "meaning": "exceptional, special, particular; peculiar, distinctive",
    "grade": 4
  },
  {
    "id": 2920,
    "traditional": "特徵",
    "simplified": "特征",
    "reading": "tèzhēng",
    "meaning": "impress, trait, hallmark, cachet, stamp, characteristic, speciality, cast, feature, characteristic marks, features, artistic style, touch, trademark, earmark, particularity, nature, genius, imprint, lineament, mold, shtick, distinction, specificity, physiognomy, idiom, signature, character, salience, diagnosis, syndrome, stigma, stylemark, signalment",
    "grade": 4
  },
  {
    "id": 2921,
    "traditional": "提醒",
    "simplified": "提醒",
    "reading": "tí xǐng",
    "meaning": "remind, warn, alert to",
    "grade": 4
  },
  {
    "id": 2922,
    "traditional": "特價",
    "simplified": "特价",
    "reading": "tèjià",
    "meaning": "special price",
    "grade": 4
  },
  {
    "id": 2923,
    "traditional": "提供",
    "simplified": "提供",
    "reading": "tígōng",
    "meaning": "feed, offer, put_up, afford, proffer, set_up, capitalize, bring_forward, sponsor, extend, ply, provision, sustain, equip, supply, cough_up, bear, render, furnish, cater, billet, provide",
    "grade": 4
  },
  {
    "id": 2924,
    "traditional": "體重",
    "simplified": "体重",
    "reading": "tǐzhòng",
    "meaning": "body weight, beef, (body) weight, avoirdupois, weight",
    "grade": 4
  },
  {
    "id": 2925,
    "traditional": "體操",
    "simplified": "体操",
    "reading": "tǐcāo",
    "meaning": "jerk, physical exertion, workout, exercise, physical_exercise, gymnastic, calisthenics, exercising, gymnastics, physical exercise, gym",
    "grade": 4
  },
  {
    "id": 2926,
    "traditional": "體檢",
    "simplified": "体检",
    "reading": "tǐjiǎn",
    "meaning": "abbr. for 體格檢查|体格检查[ti3 ge2 jian3 cha2]",
    "grade": 4
  },
  {
    "id": 2927,
    "traditional": "替代",
    "simplified": "替代",
    "reading": "tìdài",
    "meaning": "replace, supervene upon, replacement, supersede, substitute for, alternate, substitute, supersession, displace, supplant",
    "grade": 4
  },
  {
    "id": 2928,
    "traditional": "替",
    "simplified": "替",
    "reading": "tì",
    "meaning": "for, on behalf of",
    "grade": 4
  },
  {
    "id": 2929,
    "traditional": "天真",
    "simplified": "天真",
    "reading": "tiānzhēn",
    "meaning": "naive, innocent",
    "grade": 4
  },
  {
    "id": 2930,
    "traditional": "填空",
    "simplified": "填空",
    "reading": "tián kòng",
    "meaning": "to fill a job vacancy, to fill in a blank (e.g. on questionnaire or exam paper)",
    "grade": 4
  },
  {
    "id": 2931,
    "traditional": "填",
    "simplified": "填",
    "reading": "tián",
    "meaning": "writing, fill in, stuff, write, fill, close, charge, fill up",
    "grade": 4
  },
  {
    "id": 2932,
    "traditional": "挑",
    "simplified": "挑",
    "reading": "tiāo",
    "meaning": "pluck, provoking, stir up, choose, incite, instigation, lance, shoulder, push sth. up, selection, carry on both ends of shoulder pole, push ... up with a pole or stick, choice, defy, prick, raise, instigate, poke, provocation, plunk, select, carry on the shoulder with a pole, push/poke sth. up, poke sth. up, pick, push, provoke",
    "grade": 4
  },
  {
    "id": 2933,
    "traditional": "挑選",
    "simplified": "挑选",
    "reading": "tiāoxuǎn",
    "meaning": "choose, select",
    "grade": 4
  },
  {
    "id": 2934,
    "traditional": "調皮",
    "simplified": "调皮",
    "reading": "tiáopí",
    "meaning": "naughty, mischievous, unruly",
    "grade": 4
  },
  {
    "id": 2935,
    "traditional": "貼",
    "simplified": "贴",
    "reading": "tiē",
    "meaning": "paste, glue, stick to",
    "grade": 4
  },
  {
    "id": 2936,
    "traditional": "挑戰",
    "simplified": "挑战",
    "reading": "tiǎo zhàn",
    "meaning": "a challenge to battle/contest",
    "grade": 4
  },
  {
    "id": 2937,
    "traditional": "停下",
    "simplified": "停下",
    "reading": "tíngxià",
    "meaning": "to stop",
    "grade": 4
  },
  {
    "id": 2938,
    "traditional": "挺",
    "simplified": "挺",
    "reading": "tǐng",
    "meaning": "very, rather, quite",
    "grade": 4
  },
  {
    "id": 2939,
    "traditional": "通知書",
    "simplified": "通知书",
    "reading": "tōngzhīshū",
    "meaning": "Notice",
    "grade": 4
  },
  {
    "id": 2940,
    "traditional": "童話",
    "simplified": "童话",
    "reading": "tónghuà",
    "meaning": "fairy story, fairy tale, fairy-tale, children's stories, fairytale, fairy tales",
    "grade": 4
  },
  {
    "id": 2941,
    "traditional": "同情",
    "simplified": "同情",
    "reading": "tóngqíng",
    "meaning": "sympathize with",
    "grade": 4
  },
  {
    "id": 2942,
    "traditional": "統計",
    "simplified": "统计",
    "reading": "tǒngjì",
    "meaning": "statistics",
    "grade": 4
  },
  {
    "id": 2943,
    "traditional": "童年",
    "simplified": "童年",
    "reading": "tóngnián",
    "meaning": "childhood",
    "grade": 4
  },
  {
    "id": 2944,
    "traditional": "統一",
    "simplified": "统一",
    "reading": "tǒngyī",
    "meaning": "unify, unite, integrate",
    "grade": 4
  },
  {
    "id": 2945,
    "traditional": "投",
    "simplified": "投",
    "reading": "tóu",
    "meaning": "heave, agree with, throw, drop, mail, join, toss, go to, cater to, lodge, send, put into, fit in with, flip, peck, pitch, hurtle, betoss, project, deliver, stay, sky, post, cast, delivery, throw into, throw oneself into, hurl, fling, poll, vote",
    "grade": 4
  },
  {
    "id": 2946,
    "traditional": "痛快",
    "simplified": "痛快",
    "reading": "tòngkuai",
    "meaning": "delighted, to one's heart's content, straightforward, also pr. [tong4 kuai5]",
    "grade": 4
  },
  {
    "id": 2947,
    "traditional": "投訴",
    "simplified": "投诉",
    "reading": "tóusù",
    "meaning": "complaint, to complain, to register a complaint (esp. as a customer)",
    "grade": 4
  },
  {
    "id": 2948,
    "traditional": "投入",
    "simplified": "投入",
    "reading": "tóurù",
    "meaning": "throw/put into",
    "grade": 4
  },
  {
    "id": 2949,
    "traditional": "透",
    "simplified": "透",
    "reading": "tòu",
    "meaning": "to penetrate, to pass through, thoroughly, completely, transparent, to appear, to show",
    "grade": 4
  },
  {
    "id": 2950,
    "traditional": "投資",
    "simplified": "投资",
    "reading": "tóuzī",
    "meaning": "investment",
    "grade": 4
  },
  {
    "id": 2951,
    "traditional": "途中",
    "simplified": "途中",
    "reading": "túzhōng",
    "meaning": "en route",
    "grade": 4
  },
  {
    "id": 2952,
    "traditional": "圖案",
    "simplified": "图案",
    "reading": "túàn",
    "meaning": "miniature, device, pattern, illumination, emblem, embossment, design, imprint, cutout, designing, type",
    "grade": 4
  },
  {
    "id": 2953,
    "traditional": "推遲",
    "simplified": "推迟",
    "reading": "tuīchí",
    "meaning": "to postpone, to put off, to defer",
    "grade": 4
  },
  {
    "id": 2954,
    "traditional": "土地",
    "simplified": "土地",
    "reading": "tǔdi",
    "meaning": "country, acre, terra, soil, premise, realm, territory, dirt, local god of the land, farming, earth, ground, kingdom, holding, agrarian, solid ground, benefice, esplees, tract, village god, glebe, terra firma, land, dry land",
    "grade": 4
  },
  {
    "id": 2955,
    "traditional": "透明",
    "simplified": "透明",
    "reading": "tòumíng",
    "meaning": "diaphanous, see-through, crystalline, transparent",
    "grade": 4
  },
  {
    "id": 2956,
    "traditional": "推銷",
    "simplified": "推销",
    "reading": "tuīxiāo",
    "meaning": "promote sale of, peddle",
    "grade": 4
  },
  {
    "id": 2957,
    "traditional": "襪子",
    "simplified": "袜子",
    "reading": "wàzi",
    "meaning": "socks, stockings, CL:隻|只[zhi1],對|对[dui4],雙|双[shuang1]",
    "grade": 4
  },
  {
    "id": 2958,
    "traditional": "脫",
    "simplified": "脱",
    "reading": "tuō",
    "meaning": "cast/come off, shed, take off, escape from",
    "grade": 4
  },
  {
    "id": 2959,
    "traditional": "外匯",
    "simplified": "外汇",
    "reading": "wàihuì",
    "meaning": "foreign exchange",
    "grade": 4
  },
  {
    "id": 2960,
    "traditional": "外交官",
    "simplified": "外交官",
    "reading": "wàijiāoguān",
    "meaning": "diplomat",
    "grade": 4
  },
  {
    "id": 2961,
    "traditional": "外套",
    "simplified": "外套",
    "reading": "wàitào",
    "meaning": "stragulum, overclothes, outerwear, pall, surcoat, overcoat, loose coat, greatcoat, manta, outer garment, topcoat, coat, bolero, lagging, benny",
    "grade": 4
  },
  {
    "id": 2962,
    "traditional": "晚點",
    "simplified": "晚点",
    "reading": "wǎn diǎn",
    "meaning": "(of trains etc) late, delayed, behind schedule, light dinner",
    "grade": 4
  },
  {
    "id": 2963,
    "traditional": "彎",
    "simplified": "弯",
    "reading": "wān",
    "meaning": "to bend, bent, a bend, a turn (in the road etc), CL:道[dao4]",
    "grade": 4
  },
  {
    "id": 2964,
    "traditional": "萬一",
    "simplified": "万一",
    "reading": "wànyī",
    "meaning": "conj.: just in case, if by any chance",
    "grade": 4
  },
  {
    "id": 2965,
    "traditional": "王",
    "simplified": "王",
    "reading": "wáng",
    "meaning": "Rex, ameer, king, prince, emeer, monarch, shah, emir, amir, Surname, sovereign, Wang",
    "grade": 4
  },
  {
    "id": 2966,
    "traditional": "網絡",
    "simplified": "网络",
    "reading": "wǎngluò",
    "meaning": "Internet, network (computing, telecommunications, transport etc)",
    "grade": 4
  },
  {
    "id": 2967,
    "traditional": "微笑",
    "simplified": "微笑",
    "reading": "wēixiào",
    "meaning": "smile",
    "grade": 4
  },
  {
    "id": 2968,
    "traditional": "網址",
    "simplified": "网址",
    "reading": "wǎngzhǐ",
    "meaning": "website, web address, URL",
    "grade": 4
  },
  {
    "id": 2969,
    "traditional": "圍巾",
    "simplified": "围巾",
    "reading": "wéijīn",
    "meaning": "scarf, shawl, CL:條|条[tiao2]",
    "grade": 4
  },
  {
    "id": 2970,
    "traditional": "微信",
    "simplified": "微信",
    "reading": "Wēixìn",
    "meaning": "Weixin or WeChat (mobile text and voice messaging service developed by Tencent 騰訊|腾讯[Teng2 xun4])",
    "grade": 4
  },
  {
    "id": 2971,
    "traditional": "維持",
    "simplified": "维持",
    "reading": "wéichí",
    "meaning": "keep, preserve",
    "grade": 4
  },
  {
    "id": 2972,
    "traditional": "維護",
    "simplified": "维护",
    "reading": "wéihù",
    "meaning": "safeguard, defend, uphold",
    "grade": 4
  },
  {
    "id": 2973,
    "traditional": "維修",
    "simplified": "维修",
    "reading": "wéixiū",
    "meaning": "keep in (good) repair, maintain",
    "grade": 4
  },
  {
    "id": 2974,
    "traditional": "尾巴",
    "simplified": "尾巴",
    "reading": "wěiba",
    "meaning": "appendage, a person shadowing sb., stern, tail-like part, servile adherent, tail",
    "grade": 4
  },
  {
    "id": 2975,
    "traditional": "未必",
    "simplified": "未必",
    "reading": "wèibì",
    "meaning": "may_not, not necessarily, may not",
    "grade": 4
  },
  {
    "id": 2976,
    "traditional": "未來",
    "simplified": "未来",
    "reading": "wèilái",
    "meaning": "future, tomorrow, CL:個|个[ge4], approaching, coming, pending",
    "grade": 4
  },
  {
    "id": 2977,
    "traditional": "位於",
    "simplified": "位于",
    "reading": "wèiyú",
    "meaning": "be situated, situated, locate, precede, lie, come, nestle, subsist, underlie, sit, be located, skirt, rank",
    "grade": 4
  },
  {
    "id": 2978,
    "traditional": "位置",
    "simplified": "位置",
    "reading": "wèizhi",
    "meaning": "positioning, site, stand, stance, about-face, lie, locality, spatial relation, setting, situs, locus, slot, ubiety, station, location, emplacement, ubication, situation, niche, positional, position, place, seat",
    "grade": 4
  },
  {
    "id": 2979,
    "traditional": "喂",
    "simplified": "喂",
    "reading": "wèi",
    "meaning": "hello, hey",
    "grade": 4
  },
  {
    "id": 2980,
    "traditional": "味兒",
    "simplified": "味儿",
    "reading": "wèir5",
    "meaning": "taste",
    "grade": 4
  },
  {
    "id": 2981,
    "traditional": "穩",
    "simplified": "稳",
    "reading": "wěn",
    "meaning": "firm, stable, steady, staid, sedate, sure, certain",
    "grade": 4
  },
  {
    "id": 2982,
    "traditional": "穩定",
    "simplified": "稳定",
    "reading": "wěndìng",
    "meaning": "stable, steady",
    "grade": 4
  },
  {
    "id": 2983,
    "traditional": "無",
    "simplified": "无",
    "reading": "wú",
    "meaning": "not have, there is not",
    "grade": 4
  },
  {
    "id": 2984,
    "traditional": "無法",
    "simplified": "无法",
    "reading": "wúfǎ",
    "meaning": "nohow, unable to, unable, cannot",
    "grade": 4
  },
  {
    "id": 2985,
    "traditional": "無聊",
    "simplified": "无聊",
    "reading": "wúliáo",
    "meaning": "bored, boring, senseless, silly, stupid",
    "grade": 4
  },
  {
    "id": 2986,
    "traditional": "無論",
    "simplified": "无论",
    "reading": "wúlùn",
    "meaning": "conj.: no matter what/how/etc., regardless",
    "grade": 4
  },
  {
    "id": 2987,
    "traditional": "問候",
    "simplified": "问候",
    "reading": "wènhòu",
    "meaning": "to give one's respects, to send a greeting",
    "grade": 4
  },
  {
    "id": 2988,
    "traditional": "無所謂",
    "simplified": "无所谓",
    "reading": "wúsuǒwèi",
    "meaning": "not deserve the name of, be indifferent, cannot be designated as, not matter, can't be considered as",
    "grade": 4
  },
  {
    "id": 2989,
    "traditional": "無數",
    "simplified": "无数",
    "reading": "wúshù",
    "meaning": "countlessness, thousand, limitlessness, infinitude, boundlessness, trillions, millions, myriad, innumerableness, unboundedness, billions, zillions, jillions, host, countless, legion, det.: innumerable, incalculability, million, infiniteness, innumerability, immensity, umpteen",
    "grade": 4
  },
  {
    "id": 2990,
    "traditional": "無限",
    "simplified": "无限",
    "reading": "wúxiàn",
    "meaning": "infinite, limitless",
    "grade": 4
  },
  {
    "id": 2991,
    "traditional": "五顏六色",
    "simplified": "五颜六色",
    "reading": "wǔyán-liùsè",
    "meaning": "multi-colored, every color under the sun",
    "grade": 4
  },
  {
    "id": 2992,
    "traditional": "西瓜",
    "simplified": "西瓜",
    "reading": "xīgua",
    "meaning": "water melon, watermelon",
    "grade": 4
  },
  {
    "id": 2993,
    "traditional": "誤會",
    "simplified": "误会",
    "reading": "wùhuì",
    "meaning": "misunderstanding",
    "grade": 4
  },
  {
    "id": 2994,
    "traditional": "吸",
    "simplified": "吸",
    "reading": "xī",
    "meaning": "drop, absorption, absorb, draw to oneself, sip, breathe in, attraction, suck, suck up, sup, draw_in, puff, drink, inhale, attract, inhaust",
    "grade": 4
  },
  {
    "id": 2995,
    "traditional": "吸管",
    "simplified": "吸管",
    "reading": "xīguǎn",
    "meaning": "(drinking) straw, pipette, eyedropper, snorkel, CL:支[zhi1]",
    "grade": 4
  },
  {
    "id": 2996,
    "traditional": "吸收",
    "simplified": "吸收",
    "reading": "xīshōu",
    "meaning": "absorb, suck up, assimilate, recruit",
    "grade": 4
  },
  {
    "id": 2997,
    "traditional": "吸煙",
    "simplified": "吸烟",
    "reading": "xī yān",
    "meaning": "to smoke",
    "grade": 4
  },
  {
    "id": 2998,
    "traditional": "吸引",
    "simplified": "吸引",
    "reading": "xīyǐn",
    "meaning": "engross, arrest, appeal, fascinate, attraction, fetch, draw, transfix, pull in, entrance, pull, enamour, spellbind, enamor, capture, trance, captivate, call, grip, fascination, absorb, bewitch, tempt, becharm, speak_to, fix, engage, catch, rivet, enchant, beguile, charm, seduce, take, enwrap, magnetize, draw in, attract",
    "grade": 4
  },
  {
    "id": 2999,
    "traditional": "喜愛",
    "simplified": "喜爱",
    "reading": "xǐài",
    "meaning": "like, love, be fond of",
    "grade": 4
  },
  {
    "id": 3000,
    "traditional": "系統",
    "simplified": "系统",
    "reading": "xìtǒng",
    "meaning": "lineage, filiation, scheme, pedigree, system, formation, superstructure, programme, organization, succession, tract, system of rules, organisation, program",
    "grade": 4
  },
  {
    "id": 3001,
    "traditional": "系列",
    "simplified": "系列",
    "reading": "xìliè",
    "meaning": "succession, set, spectrum, course, train, series",
    "grade": 4
  },
  {
    "id": 3002,
    "traditional": "細",
    "simplified": "细",
    "reading": "xì",
    "meaning": "fine, exquisite, minute, in small particles, meticulous, thin and soft, thin, careful, light, trifling, slender, delicate, detailed",
    "grade": 4
  },
  {
    "id": 3003,
    "traditional": "細節",
    "simplified": "细节",
    "reading": "xìjié",
    "meaning": "circumstance, minutiae, nicety, minutia, speciality, item, detail, particulars, punctilio, particular, point, specific, respect, regard, details",
    "grade": 4
  },
  {
    "id": 3004,
    "traditional": "細緻",
    "simplified": "细致",
    "reading": "xìzhì",
    "meaning": "careful, meticulous, painstaking",
    "grade": 4
  },
  {
    "id": 3005,
    "traditional": "下降",
    "simplified": "下降",
    "reading": "xiàjiàng",
    "meaning": "descend, go/come down, drop, fall, decline",
    "grade": 4
  },
  {
    "id": 3006,
    "traditional": "下個月",
    "simplified": "下个月",
    "reading": "xiàgèyuè",
    "meaning": "next month",
    "grade": 4
  },
  {
    "id": 3007,
    "traditional": "下樓",
    "simplified": "下楼",
    "reading": "xiàlóu",
    "meaning": "Go downstairs",
    "grade": 4
  },
  {
    "id": 3008,
    "traditional": "夏季",
    "simplified": "夏季",
    "reading": "xiàjì",
    "meaning": "summer",
    "grade": 4
  },
  {
    "id": 3009,
    "traditional": "下載",
    "simplified": "下载",
    "reading": "xiàzài",
    "meaning": "to download, also pr. [xia4 zai4]",
    "grade": 4
  },
  {
    "id": 3010,
    "traditional": "鮮",
    "simplified": "鲜",
    "reading": "xiān",
    "meaning": "fish, old variant of 鮮|鲜[xian3], fish, old variant of 鮮|鲜[xian1]",
    "grade": 4
  },
  {
    "id": 3011,
    "traditional": "鮮花",
    "simplified": "鲜花",
    "reading": "xiānhuā",
    "meaning": "flower, fresh flowers, CL:朵[duo3]",
    "grade": 4
  },
  {
    "id": 3012,
    "traditional": "鮮明",
    "simplified": "鲜明",
    "reading": "xiānmíng",
    "meaning": "bright (of color), distinct, distinctive",
    "grade": 4
  },
  {
    "id": 3013,
    "traditional": "鹹",
    "simplified": "咸",
    "reading": "xián",
    "meaning": "surname Xian, salted, salty, stingy, miserly",
    "grade": 4
  },
  {
    "id": 3014,
    "traditional": "顯著",
    "simplified": "显著",
    "reading": "xiǎnzhù",
    "meaning": "outstand, striking, noticeable, remarkable, appear, marked, notable, outstanding, stand_out",
    "grade": 4
  },
  {
    "id": 3015,
    "traditional": "縣",
    "simplified": "县",
    "reading": "xiàn",
    "meaning": "county",
    "grade": 4
  },
  {
    "id": 3016,
    "traditional": "限制",
    "simplified": "限制",
    "reading": "xiànzhì",
    "meaning": "restrictiveness",
    "grade": 4
  },
  {
    "id": 3017,
    "traditional": "相處",
    "simplified": "相处",
    "reading": "xiāngchǔ",
    "meaning": "get along (with one another)",
    "grade": 4
  },
  {
    "id": 3018,
    "traditional": "相反",
    "simplified": "相反",
    "reading": "xiāngfǎn",
    "meaning": "offending, adverse, opposite, converse, opposite, contrary, oppose, reverse",
    "grade": 4
  },
  {
    "id": 3019,
    "traditional": "箱",
    "simplified": "箱",
    "reading": "xiāng",
    "meaning": "m.[container]",
    "grade": 4
  },
  {
    "id": 3020,
    "traditional": "箱子",
    "simplified": "箱子",
    "reading": "xiāngzi",
    "meaning": "suitcase, chest, box, case, trunk, CL:隻|只[zhi1],個|个[ge4]",
    "grade": 4
  },
  {
    "id": 3021,
    "traditional": "想念",
    "simplified": "想念",
    "reading": "xiǎngniàn",
    "meaning": "imagine, long to see again, remember with longing, think_of, miss, yearn",
    "grade": 4
  },
  {
    "id": 3022,
    "traditional": "想象",
    "simplified": "想象",
    "reading": "xiǎngxiàng",
    "meaning": "to imagine, to fancy, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 3023,
    "traditional": "項目",
    "simplified": "项目",
    "reading": "xiàngmù",
    "meaning": "event, head, item, particular, article, point, specific, entry, listing, clause, project",
    "grade": 4
  },
  {
    "id": 3024,
    "traditional": "項",
    "simplified": "项",
    "reading": "xiàng",
    "meaning": "m.[general]",
    "grade": 4
  },
  {
    "id": 3025,
    "traditional": "相片",
    "simplified": "相片",
    "reading": "xiàngpiàn",
    "meaning": "exposure, print, semblance, likeness, photo, photograph, photographic print",
    "grade": 4
  },
  {
    "id": 3026,
    "traditional": "銷售",
    "simplified": "销售",
    "reading": "xiāoshòu",
    "meaning": "sell, market",
    "grade": 4
  },
  {
    "id": 3027,
    "traditional": "消化",
    "simplified": "消化",
    "reading": "xiāohuà",
    "meaning": "digest",
    "grade": 4
  },
  {
    "id": 3028,
    "traditional": "小吃",
    "simplified": "小吃",
    "reading": "xiǎochī",
    "meaning": "snack, refreshments, CL:家[jia1]",
    "grade": 4
  },
  {
    "id": 3029,
    "traditional": "小伙子",
    "simplified": "小伙子",
    "reading": "xiǎohuǒzi",
    "meaning": "young man, young guy, lad, youngster, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 3030,
    "traditional": "小型",
    "simplified": "小型",
    "reading": "xiǎoxíng",
    "meaning": "spyglass, miniature, small-sized, bar, small-scale, pettiness",
    "grade": 4
  },
  {
    "id": 3031,
    "traditional": "效率",
    "simplified": "效率",
    "reading": "xiàolǜ",
    "meaning": "sufficiency, effectualness, effectuality, productiveness, usefulness, efficiency, effectivity, effectiveness",
    "grade": 4
  },
  {
    "id": 3032,
    "traditional": "些",
    "simplified": "些",
    "reading": "xiē",
    "meaning": "m.[proximation]",
    "grade": 4
  },
  {
    "id": 3033,
    "traditional": "心理",
    "simplified": "心理",
    "reading": "xīnlǐ",
    "meaning": "mental_state, psychology, mentality",
    "grade": 4
  },
  {
    "id": 3034,
    "traditional": "新郎",
    "simplified": "新郎",
    "reading": "xīnláng",
    "meaning": "groom, bridegroom",
    "grade": 4
  },
  {
    "id": 3035,
    "traditional": "新娘",
    "simplified": "新娘",
    "reading": "xīnniáng",
    "meaning": "bride",
    "grade": 4
  },
  {
    "id": 3036,
    "traditional": "新鮮",
    "simplified": "新鲜",
    "reading": "xīnxian",
    "meaning": "freshen, novel, fresh, new, strange",
    "grade": 4
  },
  {
    "id": 3037,
    "traditional": "新型",
    "simplified": "新型",
    "reading": "xīnxíng",
    "meaning": "pattern, novelty, of new type",
    "grade": 4
  },
  {
    "id": 3038,
    "traditional": "興奮",
    "simplified": "兴奋",
    "reading": "xīngfèn",
    "meaning": "excited",
    "grade": 4
  },
  {
    "id": 3039,
    "traditional": "形容",
    "simplified": "形容",
    "reading": "xíngróng",
    "meaning": "characterize, description, describe",
    "grade": 4
  },
  {
    "id": 3040,
    "traditional": "形勢",
    "simplified": "形势",
    "reading": "xíngshì",
    "meaning": "circumstances, situation, terrain, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 3041,
    "traditional": "型",
    "simplified": "型",
    "reading": "xíng",
    "meaning": "mold, type, style, model",
    "grade": 4
  },
  {
    "id": 3042,
    "traditional": "型號",
    "simplified": "型号",
    "reading": "xínghào",
    "meaning": "model number",
    "grade": 4
  },
  {
    "id": 3043,
    "traditional": "醒",
    "simplified": "醒",
    "reading": "xǐng",
    "meaning": "waken, come to, wake up, sober up, be clear in mind, regain consciousness, wake, be awake, awaken, arouse, come alive, awake, be striking to the eye",
    "grade": 4
  },
  {
    "id": 3044,
    "traditional": "興趣",
    "simplified": "兴趣",
    "reading": "xìngqù",
    "meaning": "relish, appetite, zestfulness, dish, cup of tea, pastime, hobby, interest, gusto, bag, avocation, zest",
    "grade": 4
  },
  {
    "id": 3045,
    "traditional": "性質",
    "simplified": "性质",
    "reading": "xìngzhì",
    "meaning": "kidney, attribute, fibre, description, affection, temper, nature, self, mold, temperament, property, composition, character, hair, bent, kind, disposition, habitude, meridian, dimension, quality, note, sort",
    "grade": 4
  },
  {
    "id": 3046,
    "traditional": "胸部",
    "simplified": "胸部",
    "reading": "xiōngbù",
    "meaning": "chest, breast, thorax",
    "grade": 4
  },
  {
    "id": 3047,
    "traditional": "兄弟",
    "simplified": "兄弟",
    "reading": "xiōngdi",
    "meaning": "brothers",
    "grade": 4
  },
  {
    "id": 3048,
    "traditional": "修理",
    "simplified": "修理",
    "reading": "xiūlǐ",
    "meaning": "repair, mend, fix",
    "grade": 4
  },
  {
    "id": 3049,
    "traditional": "選擇",
    "simplified": "选择",
    "reading": "xuǎnzé",
    "meaning": "select, opt",
    "grade": 4
  },
  {
    "id": 3050,
    "traditional": "學年",
    "simplified": "学年",
    "reading": "xuénián",
    "meaning": "school/academic year",
    "grade": 4
  },
  {
    "id": 3051,
    "traditional": "學分",
    "simplified": "学分",
    "reading": "xuéfēn",
    "meaning": "point, semester hour, course credit, credit",
    "grade": 4
  },
  {
    "id": 3052,
    "traditional": "學時",
    "simplified": "学时",
    "reading": "xuéshí",
    "meaning": "class hour, period",
    "grade": 4
  },
  {
    "id": 3053,
    "traditional": "學問",
    "simplified": "学问",
    "reading": "xuéwen",
    "meaning": "lear, ology, lore, knowledge, learning, acquirement, learnedness, cognition, erudition, letter, eruditeness, scholarship",
    "grade": 4
  },
  {
    "id": 3054,
    "traditional": "學術",
    "simplified": "学术",
    "reading": "xuéshù",
    "meaning": "ology, academic, systematic learning, science, learning",
    "grade": 4
  },
  {
    "id": 3055,
    "traditional": "尋找",
    "simplified": "寻找",
    "reading": "xúnzhǎo",
    "meaning": "comb, locate, quest, search, scout, chase, reconnoiter, look_around, look_for, look, look for, find_out, pick, reconnoitre, prospect, hunt, seek, rummage, shop",
    "grade": 4
  },
  {
    "id": 3056,
    "traditional": "牙",
    "simplified": "牙",
    "reading": "yá",
    "meaning": "ivory, cuspid, fang, broker, tooth-like thing, tusk, tooth",
    "grade": 4
  },
  {
    "id": 3057,
    "traditional": "迅速",
    "simplified": "迅速",
    "reading": "xùnsù",
    "meaning": "fast, speedy, rapid, prompt",
    "grade": 4
  },
  {
    "id": 3058,
    "traditional": "亞運會",
    "simplified": "亚运会",
    "reading": "Yàyùnhuì",
    "meaning": "Asian Games",
    "grade": 4
  },
  {
    "id": 3059,
    "traditional": "牙刷",
    "simplified": "牙刷",
    "reading": "yáshuā",
    "meaning": "toothbrush, CL:把[ba3]",
    "grade": 4
  },
  {
    "id": 3060,
    "traditional": "呀",
    "simplified": "呀",
    "reading": "ya",
    "meaning": "particle: replacing 啊 when preceding word ends in a, e, i, o, or (y)u; used in direct address and exclamation; indicating obviousness/impatience/suggestion; used for confirmation",
    "grade": 4
  },
  {
    "id": 3061,
    "traditional": "延長",
    "simplified": "延长",
    "reading": "yáncháng",
    "meaning": "lengthen, prolong, extend",
    "grade": 4
  },
  {
    "id": 3062,
    "traditional": "延期",
    "simplified": "延期",
    "reading": "yán qī",
    "meaning": "to delay, to extend, to postpone, to defer",
    "grade": 4
  },
  {
    "id": 3063,
    "traditional": "嚴格",
    "simplified": "严格",
    "reading": "yángé",
    "meaning": "rigorously enforce, rigorous, tight, grim, strict",
    "grade": 4
  },
  {
    "id": 3064,
    "traditional": "嚴",
    "simplified": "严",
    "reading": "yán",
    "meaning": "surname Yan, tight (closely sealed), stern, strict, rigorous, severe, father",
    "grade": 4
  },
  {
    "id": 3065,
    "traditional": "延續",
    "simplified": "延续",
    "reading": "yánxù",
    "meaning": "continue, go on, last",
    "grade": 4
  },
  {
    "id": 3066,
    "traditional": "嚴重",
    "simplified": "严重",
    "reading": "yánzhòng",
    "meaning": "serious, significant, acute, critical, severe, grave",
    "grade": 4
  },
  {
    "id": 3067,
    "traditional": "研究",
    "simplified": "研究",
    "reading": "yánjiū",
    "meaning": "study, research",
    "grade": 4
  },
  {
    "id": 3068,
    "traditional": "研究生",
    "simplified": "研究生",
    "reading": "yánjiūshēng",
    "meaning": "don, postgraduate, grad student, grad, graduate student, student, graduate_student",
    "grade": 4
  },
  {
    "id": 3069,
    "traditional": "研製",
    "simplified": "研制",
    "reading": "yánzhì",
    "meaning": "to manufacture, to develop",
    "grade": 4
  },
  {
    "id": 3070,
    "traditional": "鹽",
    "simplified": "盐",
    "reading": "yán",
    "meaning": "salt",
    "grade": 4
  },
  {
    "id": 3071,
    "traditional": "眼鏡",
    "simplified": "眼镜",
    "reading": "yǎnjìng",
    "meaning": "cheater, lorgnon, spectacles, specs, glasses, spectacle, barnacles, eyeglasses, barnacle, eyeglass, glass",
    "grade": 4
  },
  {
    "id": 3072,
    "traditional": "眼淚",
    "simplified": "眼泪",
    "reading": "yǎnlèi",
    "meaning": "tear, teardrop, tears, eyedrop, waterworks",
    "grade": 4
  },
  {
    "id": 3073,
    "traditional": "演講",
    "simplified": "演讲",
    "reading": "yǎnjiǎng",
    "meaning": "lecture, speech",
    "grade": 4
  },
  {
    "id": 3074,
    "traditional": "陽臺",
    "simplified": "阳台",
    "reading": "yángtái",
    "meaning": "veranda, pergola, patio, terrace, mirador, trysting place, balcony, gallery, piazza, gazebo, deck, verandah",
    "grade": 4
  },
  {
    "id": 3075,
    "traditional": "腰",
    "simplified": "腰",
    "reading": "yāo",
    "meaning": "isthmus, bend, haunch, middle, strategic pass, cingulum, reins, loin, pocket, small of the back, strait, waist of a garment, middle part, waist",
    "grade": 4
  },
  {
    "id": 3076,
    "traditional": "眼裡",
    "simplified": "眼里",
    "reading": "yǎnli",
    "meaning": "In the eyes",
    "grade": 4
  },
  {
    "id": 3077,
    "traditional": "養成",
    "simplified": "养成",
    "reading": "yǎngchéng",
    "meaning": "to cultivate, to raise, to form (a habit), to acquire",
    "grade": 4
  },
  {
    "id": 3078,
    "traditional": "藥物",
    "simplified": "药物",
    "reading": "yàowù",
    "meaning": "healer, pharmic, medicine, druggery, curative, drug, pharmaceuticals, medicines, medicaments, medicinal, medicament, pharmaceutical, medication, remedy, drugs",
    "grade": 4
  },
  {
    "id": 3079,
    "traditional": "搖",
    "simplified": "摇",
    "reading": "yáo",
    "meaning": "rock, agitate, wigwag, row, pan, swing, shake, wave, scull, turn",
    "grade": 4
  },
  {
    "id": 3080,
    "traditional": "要",
    "simplified": "要",
    "reading": "yào",
    "meaning": "want, need, must, should",
    "grade": 4
  },
  {
    "id": 3081,
    "traditional": "業餘",
    "simplified": "业余",
    "reading": "yèyú",
    "meaning": "unpaid, dilettante, amateur, recreational",
    "grade": 4
  },
  {
    "id": 3082,
    "traditional": "醫療",
    "simplified": "医疗",
    "reading": "yīliáo",
    "meaning": "treat, cure",
    "grade": 4
  },
  {
    "id": 3083,
    "traditional": "醫學",
    "simplified": "医学",
    "reading": "yīxué",
    "meaning": "medicine, practice of medicine, medical science, leechcraft, physic, iatrology, medical",
    "grade": 4
  },
  {
    "id": 3084,
    "traditional": "依靠",
    "simplified": "依靠",
    "reading": "yīkào",
    "meaning": "rely/depend on",
    "grade": 4
  },
  {
    "id": 3085,
    "traditional": "一律",
    "simplified": "一律",
    "reading": "yīlǜ",
    "meaning": "equally, without exception",
    "grade": 4
  },
  {
    "id": 3086,
    "traditional": "一致",
    "simplified": "一致",
    "reading": "yīzhì",
    "meaning": "symphonize, coheiress, identical, quadrate, accord, coincide, equate, homologize, answer, comport, concur, consist, unanimous, chime_in, agree, jibe, unite, square, correspond, consort",
    "grade": 4
  },
  {
    "id": 3087,
    "traditional": "葉子",
    "simplified": "叶子",
    "reading": "yèzi",
    "meaning": "foliage, frond, leafage, leaf, leave",
    "grade": 4
  },
  {
    "id": 3088,
    "traditional": "依然",
    "simplified": "依然",
    "reading": "yīrán",
    "meaning": "notwithstanding, natheless, withal, even so, yet, nonetheless, nevertheless, all the same, however, still, as before",
    "grade": 4
  },
  {
    "id": 3089,
    "traditional": "一再",
    "simplified": "一再",
    "reading": "yīzài",
    "meaning": "repeatedly, again and again, time and again, over_and_over",
    "grade": 4
  },
  {
    "id": 3090,
    "traditional": "移動",
    "simplified": "移动",
    "reading": "yídòng",
    "meaning": "move, shift",
    "grade": 4
  },
  {
    "id": 3091,
    "traditional": "移",
    "simplified": "移",
    "reading": "yí",
    "meaning": "removal, alteration, transform, shift, remove, movement, alter, change, move",
    "grade": 4
  },
  {
    "id": 3092,
    "traditional": "遺產",
    "simplified": "遗产",
    "reading": "yíchǎn",
    "meaning": "heritage, legacy, inheritance, bequest, CL:筆|笔[bi3]",
    "grade": 4
  },
  {
    "id": 3093,
    "traditional": "移民",
    "simplified": "移民",
    "reading": "yímín",
    "meaning": "emigrant/immigrant",
    "grade": 4
  },
  {
    "id": 3094,
    "traditional": "遺傳",
    "simplified": "遗传",
    "reading": "yíchuán",
    "meaning": "bequeath, pass on to next generation",
    "grade": 4
  },
  {
    "id": 3095,
    "traditional": "疑問",
    "simplified": "疑问",
    "reading": "yíwèn",
    "meaning": "interrogation, enquiry, question, interrogatory, demand, dubiousness, inquiry, interrogative, interrogative mood, doubtfulness, query, quaere, doubt",
    "grade": 4
  },
  {
    "id": 3096,
    "traditional": "以及",
    "simplified": "以及",
    "reading": "yǐjí",
    "meaning": "conj.: as well as, along with, and",
    "grade": 4
  },
  {
    "id": 3097,
    "traditional": "以內",
    "simplified": "以内",
    "reading": "yǐnèi",
    "meaning": "within, less than",
    "grade": 4
  },
  {
    "id": 3098,
    "traditional": "一般來說",
    "simplified": "一般来说",
    "reading": "yībānláishuō",
    "meaning": "generally speaking",
    "grade": 4
  },
  {
    "id": 3099,
    "traditional": "義務",
    "simplified": "义务",
    "reading": "yìwù",
    "meaning": "duty, obligation, volunteer duty",
    "grade": 4
  },
  {
    "id": 3100,
    "traditional": "議論",
    "simplified": "议论",
    "reading": "yìlùn",
    "meaning": "debate, discuss",
    "grade": 4
  },
  {
    "id": 3101,
    "traditional": "引",
    "simplified": "引",
    "reading": "yǐn",
    "meaning": "quote, cite",
    "grade": 4
  },
  {
    "id": 3102,
    "traditional": "引導",
    "simplified": "引导",
    "reading": "yǐndǎo",
    "meaning": "guide, lead",
    "grade": 4
  },
  {
    "id": 3103,
    "traditional": "引起",
    "simplified": "引起",
    "reading": "yǐnqǐ",
    "meaning": "elicit, beget, causation, give rise to, incite, pique, engender, bring_on, trigger, fire, compel, kick_up, superindividual, draw, spawn, invite, touch_off, occasion, lead, bring_about, procure, touch off, raise, enkindle, give, poke, produce, capture, generate, stir, arouse, excite, put forward, provoke, breed, bring, conjure up, grip, create, kindle, tempt, set_up, interest, give rise, enga",
    "grade": 4
  },
  {
    "id": 3104,
    "traditional": "引進",
    "simplified": "引进",
    "reading": "yǐnjìn",
    "meaning": "recommend, introduce from elsewhere",
    "grade": 4
  },
  {
    "id": 3105,
    "traditional": "應",
    "simplified": "应",
    "reading": "yīng",
    "meaning": "should, ought to",
    "grade": 4
  },
  {
    "id": 3106,
    "traditional": "英勇",
    "simplified": "英勇",
    "reading": "yīngyǒng",
    "meaning": "heroic, gallant, valiant",
    "grade": 4
  },
  {
    "id": 3107,
    "traditional": "營業",
    "simplified": "营业",
    "reading": "yíngyè",
    "meaning": "do business",
    "grade": 4
  },
  {
    "id": 3108,
    "traditional": "影子",
    "simplified": "影子",
    "reading": "yǐngzi",
    "meaning": "umbra, sign, silhouette, reflection, trace, shadow, vague impression",
    "grade": 4
  },
  {
    "id": 3109,
    "traditional": "勇敢",
    "simplified": "勇敢",
    "reading": "yǒnggǎn",
    "meaning": "brave, courageous",
    "grade": 4
  },
  {
    "id": 3110,
    "traditional": "贏得",
    "simplified": "赢得",
    "reading": "yíngdé",
    "meaning": "nail down, rake_in, carry_off, nail, peg, conquer, try_for, gain, turn, win, garner, claim, earn, carry, capture, take, notch, nobble",
    "grade": 4
  },
  {
    "id": 3111,
    "traditional": "用途",
    "simplified": "用途",
    "reading": "yòngtú",
    "meaning": "purpose, service, help, use, value, mileage, avail",
    "grade": 4
  },
  {
    "id": 3112,
    "traditional": "優良",
    "simplified": "优良",
    "reading": "yōuliáng",
    "meaning": "fine, good",
    "grade": 4
  },
  {
    "id": 3113,
    "traditional": "勇氣",
    "simplified": "勇气",
    "reading": "yǒngqì",
    "meaning": "gameness, pluck, guts, mettle, braveness, grit, heart, moxie, courage, backbone, boldness, pride, gutsiness, nerve, pecker, courageousness, valour, spirit, spunk, gallantry, valor, virtue, audacity, bravery, pluckiness, gumption, sand, gut, valiance",
    "grade": 4
  },
  {
    "id": 3114,
    "traditional": "優美",
    "simplified": "优美",
    "reading": "yōuměi",
    "meaning": "graceful, exquisite",
    "grade": 4
  },
  {
    "id": 3115,
    "traditional": "郵局",
    "simplified": "邮局",
    "reading": "yóujú",
    "meaning": "post office",
    "grade": 4
  },
  {
    "id": 3116,
    "traditional": "優秀",
    "simplified": "优秀",
    "reading": "yōuxiù",
    "meaning": "outstanding, excellent",
    "grade": 4
  },
  {
    "id": 3117,
    "traditional": "有勁兒",
    "simplified": "有劲儿",
    "reading": "yǒujìnr5",
    "meaning": "Strength",
    "grade": 4
  },
  {
    "id": 3118,
    "traditional": "有限",
    "simplified": "有限",
    "reading": "yǒuxiàn",
    "meaning": "limited, finite",
    "grade": 4
  },
  {
    "id": 3119,
    "traditional": "有趣",
    "simplified": "有趣",
    "reading": "yǒuqù",
    "meaning": "merry, fascinating, interesting, fun, amusive, comic, amusing",
    "grade": 4
  },
  {
    "id": 3120,
    "traditional": "幼兒園",
    "simplified": "幼儿园",
    "reading": "yòuéryuán",
    "meaning": "kindergarten, nursery school",
    "grade": 4
  },
  {
    "id": 3121,
    "traditional": "於是",
    "simplified": "于是",
    "reading": "yúshì",
    "meaning": "conj.: thereupon, hence, consequently, as a result",
    "grade": 4
  },
  {
    "id": 3122,
    "traditional": "語法",
    "simplified": "语法",
    "reading": "yǔfǎ",
    "meaning": "phraseology, parlance, language, syntax, locution, sentence structure, grammar, expression, wording, phrasing, phrase structure, diction",
    "grade": 4
  },
  {
    "id": 3123,
    "traditional": "玉",
    "simplified": "玉",
    "reading": "yù",
    "meaning": "jade",
    "grade": 4
  },
  {
    "id": 3124,
    "traditional": "語音",
    "simplified": "语音",
    "reading": "yǔyīn",
    "meaning": "spoken pronunciation of characters, pronunciation, spoken (vs. written) pronunciation of characters, speech sounds, phonetic",
    "grade": 4
  },
  {
    "id": 3125,
    "traditional": "預訂",
    "simplified": "预订",
    "reading": "yùdìng",
    "meaning": "to place an order, to book ahead",
    "grade": 4
  },
  {
    "id": 3126,
    "traditional": "玉米",
    "simplified": "玉米",
    "reading": "yùmǐ",
    "meaning": "corn, maize, CL:粒[li4]",
    "grade": 4
  },
  {
    "id": 3127,
    "traditional": "預測",
    "simplified": "预测",
    "reading": "yùcè",
    "meaning": "calculate, forecast",
    "grade": 4
  },
  {
    "id": 3128,
    "traditional": "遇",
    "simplified": "遇",
    "reading": "yù",
    "meaning": "meet, encounter, treat, receive",
    "grade": 4
  },
  {
    "id": 3129,
    "traditional": "遇見",
    "simplified": "遇见",
    "reading": "yù jian",
    "meaning": "to meet",
    "grade": 4
  },
  {
    "id": 3130,
    "traditional": "遇到",
    "simplified": "遇到",
    "reading": "yù dào",
    "meaning": "come across, run_into, receive, meet with, encounter, run into, meet, befall",
    "grade": 4
  },
  {
    "id": 3131,
    "traditional": "原料",
    "simplified": "原料",
    "reading": "yuánliào",
    "meaning": "making, stuff, feedstock, ingredient, primary, raw_material, fodder, material, staple, raw material",
    "grade": 4
  },
  {
    "id": 3132,
    "traditional": "圓",
    "simplified": "圆",
    "reading": "yuán",
    "meaning": "round, satisfactory, comprehensive",
    "grade": 4
  },
  {
    "id": 3133,
    "traditional": "原則",
    "simplified": "原则",
    "reading": "yuánzé",
    "meaning": "god, rule, formula, regulation, principle, fundamental, axiom, basis, linguistic rule, faith, principium",
    "grade": 4
  },
  {
    "id": 3134,
    "traditional": "圓滿",
    "simplified": "圆满",
    "reading": "yuánmǎn",
    "meaning": "satisfactory",
    "grade": 4
  },
  {
    "id": 3135,
    "traditional": "約會",
    "simplified": "约会",
    "reading": "yuēhui",
    "meaning": "appointment, engagement, date, CL:次[ci4],個|个[ge4], to arrange to meet",
    "grade": 4
  },
  {
    "id": 3136,
    "traditional": "月底",
    "simplified": "月底",
    "reading": "yuèdǐ",
    "meaning": "end of the month",
    "grade": 4
  },
  {
    "id": 3137,
    "traditional": "運動會",
    "simplified": "运动会",
    "reading": "yùndònghuì",
    "meaning": "sports meeting, sport, gymkhana, athletic meeting, fixture, gate, game, sports meet, games, sports_meeting, meet",
    "grade": 4
  },
  {
    "id": 3138,
    "traditional": "閱讀",
    "simplified": "阅读",
    "reading": "yuèdú",
    "meaning": "study, read, peruse",
    "grade": 4
  },
  {
    "id": 3139,
    "traditional": "運動員",
    "simplified": "运动员",
    "reading": "yùndòngyuán",
    "meaning": "athlete, CL:名[ming2],個|个[ge4]",
    "grade": 4
  },
  {
    "id": 3140,
    "traditional": "運氣",
    "simplified": "运气",
    "reading": "yùnqi",
    "meaning": "fortune, luck",
    "grade": 4
  },
  {
    "id": 3141,
    "traditional": "運用",
    "simplified": "运用",
    "reading": "yùnyòng",
    "meaning": "utilize, wield, apply",
    "grade": 4
  },
  {
    "id": 3142,
    "traditional": "在乎",
    "simplified": "在乎",
    "reading": "zàihu",
    "meaning": "care about, take ... to heart, lie in, rest with, worry, care, mind",
    "grade": 4
  },
  {
    "id": 3143,
    "traditional": "再三",
    "simplified": "再三",
    "reading": "zàisān",
    "meaning": "repeatedly, again and again, over and over again, thrice, time and again",
    "grade": 4
  },
  {
    "id": 3144,
    "traditional": "贊成",
    "simplified": "赞成",
    "reading": "zànchéng",
    "meaning": "endorsement, agree with, stand, assent, favor, cotton, uphold, subscribe, support, see, clear, approve, agree, sympathize, second, approve of, jibe, assist, indorse, optional, plump_for, favour, endorse",
    "grade": 4
  },
  {
    "id": 3145,
    "traditional": "在於",
    "simplified": "在于",
    "reading": "zàiyú",
    "meaning": "be at, on, lie, in, depend on, lie_in, rest with, lie in, be determined by, consist_in, consist in",
    "grade": 4
  },
  {
    "id": 3146,
    "traditional": "贊賞",
    "simplified": "赞赏",
    "reading": "zànshǎng",
    "meaning": "to admire, to praise, to appreciate",
    "grade": 4
  },
  {
    "id": 3147,
    "traditional": "贊助",
    "simplified": "赞助",
    "reading": "zànzhù",
    "meaning": "support, assist",
    "grade": 4
  },
  {
    "id": 3148,
    "traditional": "造型",
    "simplified": "造型",
    "reading": "zàoxíng",
    "meaning": "moulding, molding, style, mold, mold-making, fashioning, mould-making, modelling, model",
    "grade": 4
  },
  {
    "id": 3149,
    "traditional": "戰鬥",
    "simplified": "战斗",
    "reading": "zhàndòu",
    "meaning": "to fight, to battle, CL:場|场[chang2],次[ci4]",
    "grade": 4
  },
  {
    "id": 3150,
    "traditional": "戰士",
    "simplified": "战士",
    "reading": "zhànshì",
    "meaning": "fighter, soldier, warrior, CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 3151,
    "traditional": "戰勝",
    "simplified": "战胜",
    "reading": "zhànshèng",
    "meaning": "to prevail over, to defeat, to surmount",
    "grade": 4
  },
  {
    "id": 3152,
    "traditional": "戰爭",
    "simplified": "战争",
    "reading": "zhànzhēng",
    "meaning": "war, warfare",
    "grade": 4
  },
  {
    "id": 3153,
    "traditional": "丈夫",
    "simplified": "丈夫",
    "reading": "zhàngfu",
    "meaning": "masterman, man, papa, manliness, mister, goodman, married man, husband, hub, hubby",
    "grade": 4
  },
  {
    "id": 3154,
    "traditional": "著",
    "simplified": "着",
    "reading": "zhe",
    "meaning": "[aspect]",
    "grade": 4
  },
  {
    "id": 3155,
    "traditional": "招呼",
    "simplified": "招呼",
    "reading": "zhāohu",
    "meaning": "take care lest, receive, yoo-hoo, notify, take care of, recognize, say hello to, greet, halloo, speak, notification, come up to, hail, work hard, tell, beckon, get into a fight, address, accost, mind, ask, call",
    "grade": 4
  },
  {
    "id": 3156,
    "traditional": "著火",
    "simplified": "着火",
    "reading": "zháo huǒ",
    "meaning": "to ignite, to burn",
    "grade": 4
  },
  {
    "id": 3157,
    "traditional": "著急",
    "simplified": "着急",
    "reading": "zháo jí",
    "meaning": "worry, feel anxious",
    "grade": 4
  },
  {
    "id": 3158,
    "traditional": "召開",
    "simplified": "召开",
    "reading": "zhàokāi",
    "meaning": "convene, convoke",
    "grade": 4
  },
  {
    "id": 3159,
    "traditional": "折",
    "simplified": "折",
    "reading": "zhé",
    "meaning": "m.[general]",
    "grade": 4
  },
  {
    "id": 3160,
    "traditional": "針",
    "simplified": "针",
    "reading": "zhēn",
    "meaning": "needle, pin, stitch, injection, shot, acupuncture",
    "grade": 4
  },
  {
    "id": 3161,
    "traditional": "針對",
    "simplified": "针对",
    "reading": "zhēnduì",
    "meaning": "in the light of, in connection with",
    "grade": 4
  },
  {
    "id": 3162,
    "traditional": "陣",
    "simplified": "阵",
    "reading": "zhèn",
    "meaning": "m.[event]",
    "grade": 4
  },
  {
    "id": 3163,
    "traditional": "爭論",
    "simplified": "争论",
    "reading": "zhēnglùn",
    "meaning": "to argue, to debate, to contend, argument, contention, controversy, debate, CL:次[ci4],場|场[chang3]",
    "grade": 4
  },
  {
    "id": 3164,
    "traditional": "征服",
    "simplified": "征服",
    "reading": "zhēngfú",
    "meaning": "to conquer, to subdue, to vanquish",
    "grade": 4
  },
  {
    "id": 3165,
    "traditional": "政府",
    "simplified": "政府",
    "reading": "zhèngfǔ",
    "meaning": "sirkar, state, country, nation, governmental, body politic, government, administration, civil order, polity, commonwealth, regime, res publica, land",
    "grade": 4
  },
  {
    "id": 3166,
    "traditional": "徵求",
    "simplified": "征求",
    "reading": "zhēngqiú",
    "meaning": "ask_for, seek, solicit",
    "grade": 4
  },
  {
    "id": 3167,
    "traditional": "政治",
    "simplified": "政治",
    "reading": "zhèngzhì",
    "meaning": "paternalism, trouble, government, policy, political relation, political science, polity, politics, political affairs, political",
    "grade": 4
  },
  {
    "id": 3168,
    "traditional": "之前",
    "simplified": "之前",
    "reading": "zhīqián",
    "meaning": "before, prior to, ago",
    "grade": 4
  },
  {
    "id": 3169,
    "traditional": "之後",
    "simplified": "之后",
    "reading": "zhīhòu",
    "meaning": "later, behind, at the back of, after",
    "grade": 4
  },
  {
    "id": 3170,
    "traditional": "之一",
    "simplified": "之一",
    "reading": "zhīyī",
    "meaning": "one of (sth), one out of a multitude, one (third, quarter, percent etc)",
    "grade": 4
  },
  {
    "id": 3171,
    "traditional": "支",
    "simplified": "支",
    "reading": "zhī",
    "meaning": "m.[general]",
    "grade": 4
  },
  {
    "id": 3172,
    "traditional": "之間",
    "simplified": "之间",
    "reading": "zhījiān",
    "meaning": "among, between",
    "grade": 4
  },
  {
    "id": 3173,
    "traditional": "植物",
    "simplified": "植物",
    "reading": "zhíwù",
    "meaning": "vegetal, flora, herb, plant, grower, vegetable, plant life, vegetation, botanical, vegetive, dwarf, vegetative",
    "grade": 4
  },
  {
    "id": 3174,
    "traditional": "制訂",
    "simplified": "制订",
    "reading": "zhìdìng",
    "meaning": "work/map out, formulate",
    "grade": 4
  },
  {
    "id": 3175,
    "traditional": "指揮",
    "simplified": "指挥",
    "reading": "zhǐhuī",
    "meaning": "conduct, direct, command",
    "grade": 4
  },
  {
    "id": 3176,
    "traditional": "質量",
    "simplified": "质量",
    "reading": "zhìliàng",
    "meaning": "weight, molar, mass, quantity, quality",
    "grade": 4
  },
  {
    "id": 3177,
    "traditional": "治",
    "simplified": "治",
    "reading": "zhì",
    "meaning": "study, research, cure, govern, treat, government, eliminate, harness, rule, manage, wipe out, management, administration, administer, control, punish, treat (disease)",
    "grade": 4
  },
  {
    "id": 3178,
    "traditional": "治療",
    "simplified": "治疗",
    "reading": "zhìliáo",
    "meaning": "treatment, cure",
    "grade": 4
  },
  {
    "id": 3179,
    "traditional": "智力",
    "simplified": "智力",
    "reading": "zhìlì",
    "meaning": "intelligence, intellect",
    "grade": 4
  },
  {
    "id": 3180,
    "traditional": "智能",
    "simplified": "智能",
    "reading": "zhìnéng",
    "meaning": "intelligent, able, smart (phone, system, bomb etc)",
    "grade": 4
  },
  {
    "id": 3181,
    "traditional": "中介",
    "simplified": "中介",
    "reading": "zhōngjiè",
    "meaning": "to act as intermediary, to link, intermediate, inter-, agency, agent",
    "grade": 4
  },
  {
    "id": 3182,
    "traditional": "種類",
    "simplified": "种类",
    "reading": "zhǒnglèi",
    "meaning": "kidney, description, race, form, nature, manner, variety, feather, ordering, ilk, style, class, order, stamp, type, genus, category, stripe, kind, strain, cast, shape, denomination, species, persuasion, league, classification, breed, sort",
    "grade": 4
  },
  {
    "id": 3183,
    "traditional": "中獎",
    "simplified": "中奖",
    "reading": "zhòng jiǎng",
    "meaning": "to win a prize, a successful gamble",
    "grade": 4
  },
  {
    "id": 3184,
    "traditional": "種植",
    "simplified": "种植",
    "reading": "zhòngzhí",
    "meaning": "plant, grow",
    "grade": 4
  },
  {
    "id": 3185,
    "traditional": "種",
    "simplified": "种",
    "reading": "zhǒng",
    "meaning": "m.[kind]",
    "grade": 4
  },
  {
    "id": 3186,
    "traditional": "逐漸",
    "simplified": "逐渐",
    "reading": "zhújiàn",
    "meaning": "step_by_step, by degrees, gradually",
    "grade": 4
  },
  {
    "id": 3187,
    "traditional": "逐步",
    "simplified": "逐步",
    "reading": "zhúbù",
    "meaning": "step by step, progressively",
    "grade": 4
  },
  {
    "id": 3188,
    "traditional": "重量",
    "simplified": "重量",
    "reading": "zhòngliàng",
    "meaning": "avoirdupois weight, weight, ponderousness, heaviness, loading, heft, ponderosity, avoirdupois, heftiness, massiveness, gravity, lading",
    "grade": 4
  },
  {
    "id": 3189,
    "traditional": "主席",
    "simplified": "主席",
    "reading": "zhǔxí",
    "meaning": "chairwoman, presiding officer, president (of organization, chair, chair (of meeting), prolocutor, chairperson, subchairman, chairmanship, state), chairman, chair/president (of organization/state), moderator, president",
    "grade": 4
  },
  {
    "id": 3190,
    "traditional": "祝福",
    "simplified": "祝福",
    "reading": "zhùfú",
    "meaning": "invoke blessing, wish happiness to",
    "grade": 4
  },
  {
    "id": 3191,
    "traditional": "主題",
    "simplified": "主题",
    "reading": "zhǔtí",
    "meaning": "motif, lemma, musical theme, theme, thesis, idea, literary argument, argument, motive, leitmotiv, matter, subject_matter, keynote, issue, subject, topic, mythos, melodic theme",
    "grade": 4
  },
  {
    "id": 3192,
    "traditional": "著名",
    "simplified": "著名",
    "reading": "zhùmíng",
    "meaning": "famous, noted, well-known, celebrated",
    "grade": 4
  },
  {
    "id": 3193,
    "traditional": "抓緊",
    "simplified": "抓紧",
    "reading": "zhuā jǐn",
    "meaning": "to grasp firmly, to pay special attention to, to rush in, to make the most of",
    "grade": 4
  },
  {
    "id": 3194,
    "traditional": "專心",
    "simplified": "专心",
    "reading": "zhuānxīn",
    "meaning": "occupy, immerge, pore, zoom in, concentrate, betake, dive, pay attention, concentrate effort, advert, give ear, center, rivet, concentrate one's attention, focus, hang, pay heed, devote, apply, be absorbed, be absorbed in, centre, attend",
    "grade": 4
  },
  {
    "id": 3195,
    "traditional": "轉動",
    "simplified": "转动",
    "reading": "zhuǎn dòng",
    "meaning": "turn (round), move",
    "grade": 4
  },
  {
    "id": 3196,
    "traditional": "著作",
    "simplified": "著作",
    "reading": "zhùzuò",
    "meaning": "composition, writing, composing, oeuvre, work, opus, literature, writings, book, production, bookmaking, body of work",
    "grade": 4
  },
  {
    "id": 3197,
    "traditional": "轉告",
    "simplified": "转告",
    "reading": "zhuǎngào",
    "meaning": "to pass on, to communicate, to transmit",
    "grade": 4
  },
  {
    "id": 3198,
    "traditional": "轉身",
    "simplified": "转身",
    "reading": "zhuǎn shēn",
    "meaning": "turn round, go_about, face about, turn",
    "grade": 4
  },
  {
    "id": 3199,
    "traditional": "轉彎",
    "simplified": "转弯",
    "reading": "zhuǎn wān",
    "meaning": "to turn, to go around a corner",
    "grade": 4
  },
  {
    "id": 3200,
    "traditional": "裝修",
    "simplified": "装修",
    "reading": "zhuāngxiū",
    "meaning": "repair, renovate (house/etc.), decorate, renovate (house, etc.), renovate, furnish, renovation, fit up",
    "grade": 4
  },
  {
    "id": 3201,
    "traditional": "裝置",
    "simplified": "装置",
    "reading": "zhuāngzhì",
    "meaning": "install, fit",
    "grade": 4
  },
  {
    "id": 3202,
    "traditional": "轉移",
    "simplified": "转移",
    "reading": "zhuǎnyí",
    "meaning": "shift, transfer, divert, change, transform",
    "grade": 4
  },
  {
    "id": 3203,
    "traditional": "準時",
    "simplified": "准时",
    "reading": "zhǔnshí",
    "meaning": "on time, punctual, on schedule",
    "grade": 4
  },
  {
    "id": 3204,
    "traditional": "追求",
    "simplified": "追求",
    "reading": "zhuīqiú",
    "meaning": "seek, pursue, woo, court",
    "grade": 4
  },
  {
    "id": 3205,
    "traditional": "資料",
    "simplified": "资料",
    "reading": "zīliào",
    "meaning": "food, source, info, means, data, information, material, datum, data point, matter",
    "grade": 4
  },
  {
    "id": 3206,
    "traditional": "自",
    "simplified": "自",
    "reading": "zì",
    "meaning": "from, since",
    "grade": 4
  },
  {
    "id": 3207,
    "traditional": "資源",
    "simplified": "资源",
    "reading": "zīyuán",
    "meaning": "resource, natural resources, mine, resources",
    "grade": 4
  },
  {
    "id": 3208,
    "traditional": "字母",
    "simplified": "字母",
    "reading": "zìmǔ",
    "meaning": "letter (of the alphabet), CL:個|个[ge4]",
    "grade": 4
  },
  {
    "id": 3209,
    "traditional": "綜合",
    "simplified": "综合",
    "reading": "zōnghé",
    "meaning": "synthetical, comprehensive, multiple, composite",
    "grade": 4
  },
  {
    "id": 3210,
    "traditional": "自信",
    "simplified": "自信",
    "reading": "zìxìn",
    "meaning": "self-confidence",
    "grade": 4
  },
  {
    "id": 3211,
    "traditional": "總共",
    "simplified": "总共",
    "reading": "zǒnggòng",
    "meaning": "in all, all told, altogether",
    "grade": 4
  },
  {
    "id": 3212,
    "traditional": "總理",
    "simplified": "总理",
    "reading": "zǒnglǐ",
    "meaning": "premier, prime minister",
    "grade": 4
  },
  {
    "id": 3213,
    "traditional": "總統",
    "simplified": "总统",
    "reading": "zǒngtǒng",
    "meaning": "president (of a republic)",
    "grade": 4
  },
  {
    "id": 3214,
    "traditional": "總之",
    "simplified": "总之",
    "reading": "zǒngzhī",
    "meaning": "in a word, tout ensemble, on the whole, all in all, altogether, in short",
    "grade": 4
  },
  {
    "id": 3215,
    "traditional": "阻止",
    "simplified": "阻止",
    "reading": "zǔzhǐ",
    "meaning": "prevent, stop, prohibit",
    "grade": 4
  },
  {
    "id": 3216,
    "traditional": "嘴巴",
    "simplified": "嘴巴",
    "reading": "zuǐba",
    "meaning": "jaw, cheeks, kisser, mouth",
    "grade": 4
  },
  {
    "id": 3217,
    "traditional": "作出",
    "simplified": "作出",
    "reading": "zuòchū",
    "meaning": "make (decision/etc.)",
    "grade": 4
  },
  {
    "id": 3218,
    "traditional": "作為",
    "simplified": "作为",
    "reading": "zuòwéi",
    "meaning": "regard as, look on as, take for",
    "grade": 4
  },
  {
    "id": 3219,
    "traditional": "最初",
    "simplified": "最初",
    "reading": "zuìchū",
    "meaning": "first, primary, initial, original, at first, initially, originally",
    "grade": 4
  },
  {
    "id": 3220,
    "traditional": "做夢",
    "simplified": "做梦",
    "reading": "zuò mèng",
    "meaning": "to dream, to have a dream, fig. illusion, fantasy, pipe dream",
    "grade": 4
  },
  {
    "id": 3221,
    "traditional": "安慰",
    "simplified": "安慰",
    "reading": "ānwèi",
    "meaning": "comfort, console",
    "grade": 5
  },
  {
    "id": 3222,
    "traditional": "岸",
    "simplified": "岸",
    "reading": "àn",
    "meaning": "bank, shore, beach, coast, CL:個|个[ge4]",
    "grade": 5
  },
  {
    "id": 3223,
    "traditional": "岸上",
    "simplified": "岸上",
    "reading": "ànshàng",
    "meaning": "ashore, on the riverbank",
    "grade": 5
  },
  {
    "id": 3224,
    "traditional": "拔",
    "simplified": "拔",
    "reading": "bá",
    "meaning": "bare, tear, pluck, choose, take out, stand out among, displume, uproot, pull up, tweak, draw, pull off, deplumate, deplume, pull, surpass, get out, raise, suck out, select, seize, seizure, cool in water, lift, pull_out, pick, capture, yank, pull out, pick off",
    "grade": 5
  },
  {
    "id": 3225,
    "traditional": "按摩",
    "simplified": "按摩",
    "reading": "ànmó",
    "meaning": "massage",
    "grade": 5
  },
  {
    "id": 3226,
    "traditional": "白酒",
    "simplified": "白酒",
    "reading": "báijiǔ",
    "meaning": "spirit usually distilled from sorghum or maize, white spirit",
    "grade": 5
  },
  {
    "id": 3227,
    "traditional": "扮演",
    "simplified": "扮演",
    "reading": "bànyǎn",
    "meaning": "play part of, play part of",
    "grade": 5
  },
  {
    "id": 3228,
    "traditional": "拜訪",
    "simplified": "拜访",
    "reading": "bàifǎng",
    "meaning": "pay visit, call on",
    "grade": 5
  },
  {
    "id": 3229,
    "traditional": "包圍",
    "simplified": "包围",
    "reading": "bāowéi",
    "meaning": "to surround, to encircle, to hem in",
    "grade": 5
  },
  {
    "id": 3230,
    "traditional": "包裝",
    "simplified": "包装",
    "reading": "bāozhuāng",
    "meaning": "package, packing, packaging",
    "grade": 5
  },
  {
    "id": 3231,
    "traditional": "版",
    "simplified": "版",
    "reading": "bǎn",
    "meaning": "a register, block of printing, edition, version, page",
    "grade": 5
  },
  {
    "id": 3232,
    "traditional": "棒",
    "simplified": "棒",
    "reading": "bàng",
    "meaning": "good, fine, strong",
    "grade": 5
  },
  {
    "id": 3233,
    "traditional": "保衛",
    "simplified": "保卫",
    "reading": "bǎowèi",
    "meaning": "to defend, to safeguard",
    "grade": 5
  },
  {
    "id": 3234,
    "traditional": "報答",
    "simplified": "报答",
    "reading": "bàodá",
    "meaning": "to repay, to requite",
    "grade": 5
  },
  {
    "id": 3235,
    "traditional": "報警",
    "simplified": "报警",
    "reading": "bào jǐng",
    "meaning": "to sound an alarm, to report sth to the police",
    "grade": 5
  },
  {
    "id": 3236,
    "traditional": "背包",
    "simplified": "背包",
    "reading": "bēibāo",
    "meaning": "knapsack, rucksack, infantry pack, field pack, blanket roll, CL:個|个[ge4]",
    "grade": 5
  },
  {
    "id": 3237,
    "traditional": "保養",
    "simplified": "保养",
    "reading": "bǎoyǎng",
    "meaning": "take good care of one's health, maintain, keep in good repair",
    "grade": 5
  },
  {
    "id": 3238,
    "traditional": "悲傷",
    "simplified": "悲伤",
    "reading": "bēishāng",
    "meaning": "sad, sorrowful",
    "grade": 5
  },
  {
    "id": 3239,
    "traditional": "悲劇",
    "simplified": "悲剧",
    "reading": "bēijù",
    "meaning": "tragedy",
    "grade": 5
  },
  {
    "id": 3240,
    "traditional": "抱怨",
    "simplified": "抱怨",
    "reading": "bàoyuan",
    "meaning": "croak, kvetch, scold, quarrel, bitch, squeal, complain, bellyache, plain, grumble, whimper, natter, grizzle, repine, nark, complaint, sound off, nag, whine, quetch, grouch, kick, yammer, backbite, squawk, crab, beef, yawp, rail, rabbit, gripe, inveigh, grouse, moan, holler",
    "grade": 5
  },
  {
    "id": 3241,
    "traditional": "被動",
    "simplified": "被动",
    "reading": "bèidòng",
    "meaning": "passive",
    "grade": 5
  },
  {
    "id": 3242,
    "traditional": "輩",
    "simplified": "辈",
    "reading": "bèi",
    "meaning": "lifetime, generation, group of people, class, classifier for generations, (literary) classifier for people",
    "grade": 5
  },
  {
    "id": 3243,
    "traditional": "本人",
    "simplified": "本人",
    "reading": "běnrén",
    "meaning": "myself, principal, in person, I, me, oneself, identity",
    "grade": 5
  },
  {
    "id": 3244,
    "traditional": "鼻子",
    "simplified": "鼻子",
    "reading": "bízi",
    "meaning": "nasal, hooter, nozzle, snoot, sneezer, boko, schnozzle, conk, smeller, snout, beezer, proboscis, olfactory organ, snitch, nose, olfactory",
    "grade": 5
  },
  {
    "id": 3245,
    "traditional": "比方",
    "simplified": "比方",
    "reading": "bǐfang",
    "meaning": "analogy, instance, for instance",
    "grade": 5
  },
  {
    "id": 3246,
    "traditional": "比重",
    "simplified": "比重",
    "reading": "bǐzhòng",
    "meaning": "proportion, specific gravity",
    "grade": 5
  },
  {
    "id": 3247,
    "traditional": "北極",
    "simplified": "北极",
    "reading": "běijí",
    "meaning": "the North Pole, the Arctic Pole, the north magnetic pole",
    "grade": 5
  },
  {
    "id": 3248,
    "traditional": "必需",
    "simplified": "必需",
    "reading": "bìxū",
    "meaning": "need",
    "grade": 5
  },
  {
    "id": 3249,
    "traditional": "畢竟",
    "simplified": "毕竟",
    "reading": "bìjìng",
    "meaning": "after all, when all is said and done",
    "grade": 5
  },
  {
    "id": 3250,
    "traditional": "閉幕",
    "simplified": "闭幕",
    "reading": "bì mù",
    "meaning": "the curtain falls, lower the curtain, to come to an end (of a meeting)",
    "grade": 5
  },
  {
    "id": 3251,
    "traditional": "彼此",
    "simplified": "彼此",
    "reading": "bǐcǐ",
    "meaning": "each other, one another, I/me too",
    "grade": 5
  },
  {
    "id": 3252,
    "traditional": "閉幕式",
    "simplified": "闭幕式",
    "reading": "bìmùshì",
    "meaning": "closing ceremony",
    "grade": 5
  },
  {
    "id": 3253,
    "traditional": "編輯",
    "simplified": "编辑",
    "reading": "biānjí",
    "meaning": "edit, compile",
    "grade": 5
  },
  {
    "id": 3254,
    "traditional": "必",
    "simplified": "必",
    "reading": "bì",
    "meaning": "surely, must, certainly, have to, necessarily",
    "grade": 5
  },
  {
    "id": 3255,
    "traditional": "便利",
    "simplified": "便利",
    "reading": "biànlì",
    "meaning": "facilitate",
    "grade": 5
  },
  {
    "id": 3256,
    "traditional": "變動",
    "simplified": "变动",
    "reading": "biàndòng",
    "meaning": "change, fluctuate",
    "grade": 5
  },
  {
    "id": 3257,
    "traditional": "便條",
    "simplified": "便条",
    "reading": "biàntiáo",
    "meaning": "(informal) note, CL:張|张[zhang1],個|个[ge4]",
    "grade": 5
  },
  {
    "id": 3258,
    "traditional": "邊境",
    "simplified": "边境",
    "reading": "biānjìng",
    "meaning": "frontier, border",
    "grade": 5
  },
  {
    "id": 3259,
    "traditional": "便於",
    "simplified": "便于",
    "reading": "biànyú",
    "meaning": "easy to, convenient for",
    "grade": 5
  },
  {
    "id": 3260,
    "traditional": "賓館",
    "simplified": "宾馆",
    "reading": "bīnguǎn",
    "meaning": "guesthouse, lodge, hotel, CL:個|个[ge4],家[jia1]",
    "grade": 5
  },
  {
    "id": 3261,
    "traditional": "病毒",
    "simplified": "病毒",
    "reading": "bìngdú",
    "meaning": "worm, viral, virus",
    "grade": 5
  },
  {
    "id": 3262,
    "traditional": "餅乾",
    "simplified": "饼干",
    "reading": "bǐnggān",
    "meaning": "biscuit, cracker, cookie, CL:片[pian4],塊|块[kuai4]",
    "grade": 5
  },
  {
    "id": 3263,
    "traditional": "博客",
    "simplified": "博客",
    "reading": "bókè",
    "meaning": "blog (loanword), weblog, blogger",
    "grade": 5
  },
  {
    "id": 3264,
    "traditional": "餅",
    "simplified": "饼",
    "reading": "bǐng",
    "meaning": "round flat cake, dumpling, pancake, tortilla, goody, cake, sth. shaped like a cake, a round flat cake",
    "grade": 5
  },
  {
    "id": 3265,
    "traditional": "玻璃",
    "simplified": "玻璃",
    "reading": "bōli",
    "meaning": "plastic, vitrum, putty, vitreous, nylon, window, windowpane, cutter, glass",
    "grade": 5
  },
  {
    "id": 3266,
    "traditional": "博士",
    "simplified": "博士",
    "reading": "bóshì",
    "meaning": "Ph.D, master of any trade/craft, master of any craft, Dr., holder of doctoral degree, master of any trade, Erudite, doctoral, Doctor_of_Philosophy, Ph.D., court academician, craft, erudite, Doctor of Philosophy, doctor",
    "grade": 5
  },
  {
    "id": 3267,
    "traditional": "博覽會",
    "simplified": "博览会",
    "reading": "bólǎnhuì",
    "meaning": "expo, trade fair, exhibition, exposition, fair, Expo",
    "grade": 5
  },
  {
    "id": 3268,
    "traditional": "博物館",
    "simplified": "博物馆",
    "reading": "bówùguǎn",
    "meaning": "museum",
    "grade": 5
  },
  {
    "id": 3269,
    "traditional": "不利",
    "simplified": "不利",
    "reading": "bùlì",
    "meaning": "unfavorable, detrimental;unsuccessful",
    "grade": 5
  },
  {
    "id": 3270,
    "traditional": "薄弱",
    "simplified": "薄弱",
    "reading": "bóruò",
    "meaning": "weak, frail",
    "grade": 5
  },
  {
    "id": 3271,
    "traditional": "不耐煩",
    "simplified": "不耐烦",
    "reading": "bù nàifán",
    "meaning": "impatience, impatient",
    "grade": 5
  },
  {
    "id": 3272,
    "traditional": "不顧",
    "simplified": "不顾",
    "reading": "bùgù",
    "meaning": "ignore",
    "grade": 5
  },
  {
    "id": 3273,
    "traditional": "補償",
    "simplified": "补偿",
    "reading": "bǔcháng",
    "meaning": "compensate, make up",
    "grade": 5
  },
  {
    "id": 3274,
    "traditional": "不幸",
    "simplified": "不幸",
    "reading": "bùxìng",
    "meaning": "unfortunate",
    "grade": 5
  },
  {
    "id": 3275,
    "traditional": "不易",
    "simplified": "不易",
    "reading": "bùyì",
    "meaning": "uneasy",
    "grade": 5
  },
  {
    "id": 3276,
    "traditional": "不曾",
    "simplified": "不曾",
    "reading": "bùcéng",
    "meaning": "hasn't yet, hasn't ever",
    "grade": 5
  },
  {
    "id": 3277,
    "traditional": "不得了",
    "simplified": "不得了",
    "reading": "bù déliǎo",
    "meaning": "desperately serious, disastrous, extremely, exceedingly",
    "grade": 5
  },
  {
    "id": 3278,
    "traditional": "補貼",
    "simplified": "补贴",
    "reading": "bǔtiē",
    "meaning": "to subsidize, subsidy, allowance, to supplement (one's salary etc), benefit",
    "grade": 5
  },
  {
    "id": 3279,
    "traditional": "不敢當",
    "simplified": "不敢当",
    "reading": "bù gǎndāng",
    "meaning": "lit. I dare not (accept the honor), fig. I don't deserve your praise, you flatter me",
    "grade": 5
  },
  {
    "id": 3280,
    "traditional": "不免",
    "simplified": "不免",
    "reading": "bùmiǎn",
    "meaning": "unavoidably",
    "grade": 5
  },
  {
    "id": 3281,
    "traditional": "不時",
    "simplified": "不时",
    "reading": "bùshí",
    "meaning": "betweentimes, every so often, frequently, somewhile, at any time, every now and then, often",
    "grade": 5
  },
  {
    "id": 3282,
    "traditional": "不能不",
    "simplified": "不能不",
    "reading": "bùnéngbù",
    "meaning": "have to, cannot but",
    "grade": 5
  },
  {
    "id": 3283,
    "traditional": "不停",
    "simplified": "不停",
    "reading": "bùtíng",
    "meaning": "non-stop",
    "grade": 5
  },
  {
    "id": 3284,
    "traditional": "不許",
    "simplified": "不许",
    "reading": "bùxǔ",
    "meaning": "can't",
    "grade": 5
  },
  {
    "id": 3285,
    "traditional": "不良",
    "simplified": "不良",
    "reading": "bùliáng",
    "meaning": "unhealthy, harmful, bad",
    "grade": 5
  },
  {
    "id": 3286,
    "traditional": "部位",
    "simplified": "部位",
    "reading": "bùwèi",
    "meaning": "segment, part, capsule, aspect, position, region, situs, place",
    "grade": 5
  },
  {
    "id": 3287,
    "traditional": "不止",
    "simplified": "不止",
    "reading": "bùzhǐ",
    "meaning": "incessantly, without end, more than, not limited to",
    "grade": 5
  },
  {
    "id": 3288,
    "traditional": "猜測",
    "simplified": "猜测",
    "reading": "cāicè",
    "meaning": "guess, surmise",
    "grade": 5
  },
  {
    "id": 3289,
    "traditional": "不足",
    "simplified": "不足",
    "reading": "bùzú",
    "meaning": "not be enough, not be worth (doing sth.), cannot, should not",
    "grade": 5
  },
  {
    "id": 3290,
    "traditional": "採購",
    "simplified": "采购",
    "reading": "cǎigòu",
    "meaning": "choose and purchase",
    "grade": 5
  },
  {
    "id": 3291,
    "traditional": "裁判",
    "simplified": "裁判",
    "reading": "cáipàn",
    "meaning": "judge, referee, umpire",
    "grade": 5
  },
  {
    "id": 3292,
    "traditional": "猜",
    "simplified": "猜",
    "reading": "cāi",
    "meaning": "guess, conjecture, speculation, suspect, put_on, speculate",
    "grade": 5
  },
  {
    "id": 3293,
    "traditional": "餐館",
    "simplified": "餐馆",
    "reading": "cānguǎn",
    "meaning": "chophouse, restaurant, porterhouse, osteria, cafe, caff",
    "grade": 5
  },
  {
    "id": 3294,
    "traditional": "餐廳",
    "simplified": "餐厅",
    "reading": "cāntīng",
    "meaning": "dining hall, restaurant, eating place, eating house, hall, dining_room, rathskeller, brasserie, dining room/hall, refectory, dining room, lunchroom, diner, inn",
    "grade": 5
  },
  {
    "id": 3295,
    "traditional": "彩票",
    "simplified": "彩票",
    "reading": "cǎipiào",
    "meaning": "lottery ticket",
    "grade": 5
  },
  {
    "id": 3296,
    "traditional": "草原",
    "simplified": "草原",
    "reading": "cǎoyuán",
    "meaning": "veld, ley, steppe, plain, pastureland, pasture, grassland, lea, grass, campo, prairie, grazing land, grasslands",
    "grade": 5
  },
  {
    "id": 3297,
    "traditional": "層次",
    "simplified": "层次",
    "reading": "céngcì",
    "meaning": "administrative levels, rank order, arrangement, arrangement of ideas, stratification, levels, gradation, level, grade, hierarchy, coat, place",
    "grade": 5
  },
  {
    "id": 3298,
    "traditional": "冊",
    "simplified": "册",
    "reading": "cè",
    "meaning": "m.[general]",
    "grade": 5
  },
  {
    "id": 3299,
    "traditional": "餐飲",
    "simplified": "餐饮",
    "reading": "cānyǐn",
    "meaning": "catering, foods, table",
    "grade": 5
  },
  {
    "id": 3300,
    "traditional": "叉子",
    "simplified": "叉子",
    "reading": "chāzi",
    "meaning": "fork, CL:把[ba3]",
    "grade": 5
  },
  {
    "id": 3301,
    "traditional": "差別",
    "simplified": "差别",
    "reading": "chābié",
    "meaning": "disparity, contrast, difference, discrepancy, differentiation, distinction, differential",
    "grade": 5
  },
  {
    "id": 3302,
    "traditional": "差距",
    "simplified": "差距",
    "reading": "chājù",
    "meaning": "spatial difference",
    "grade": 5
  },
  {
    "id": 3303,
    "traditional": "查詢",
    "simplified": "查询",
    "reading": "cháxún",
    "meaning": "inquire into/about",
    "grade": 5
  },
  {
    "id": 3304,
    "traditional": "插",
    "simplified": "插",
    "reading": "chā",
    "meaning": "stuff, plug, get_in, insertion, insert, poke, thrust, tip, interposition, squeeze, stick, interpose, stick_in, stick in, shove",
    "grade": 5
  },
  {
    "id": 3305,
    "traditional": "叉",
    "simplified": "叉",
    "reading": "chā",
    "meaning": "fork, pitchfork, prong, pick, cross, intersect, 'X', to cross, be stuck, to diverge, to open (as legs)",
    "grade": 5
  },
  {
    "id": 3306,
    "traditional": "差點兒",
    "simplified": "差点儿",
    "reading": "chàdiǎnr5",
    "meaning": "not quite, not good enough, almost, barely, a bit different, just about to, nearly starting, on the verge of, erhua variant of 差點|差点[cha4 dian3]",
    "grade": 5
  },
  {
    "id": 3307,
    "traditional": "拆除",
    "simplified": "拆除",
    "reading": "chāichú",
    "meaning": "demolish, remove",
    "grade": 5
  },
  {
    "id": 3308,
    "traditional": "拆",
    "simplified": "拆",
    "reading": "chāi",
    "meaning": "section, disaffiliate, unseal, rip, dismantle, dismantlement, unweave, pull down, take apart, tear open",
    "grade": 5
  },
  {
    "id": 3309,
    "traditional": "長度",
    "simplified": "长度",
    "reading": "chángdù",
    "meaning": "long measure, extent, long_measure, measurement, length, yardage, longness, linear measure",
    "grade": 5
  },
  {
    "id": 3310,
    "traditional": "長壽",
    "simplified": "长寿",
    "reading": "chángshòu",
    "meaning": "Changshou suburban district of Chongqing municipality, formerly in Sichuan, longevity, ability to live long, long lived",
    "grade": 5
  },
  {
    "id": 3311,
    "traditional": "產業",
    "simplified": "产业",
    "reading": "chǎnyè",
    "meaning": "estate, manufacture, industry, domain, manufacturing, property",
    "grade": 5
  },
  {
    "id": 3312,
    "traditional": "腸",
    "simplified": "肠",
    "reading": "cháng",
    "meaning": "intestines, old variant of 腸|肠[chang2]",
    "grade": 5
  },
  {
    "id": 3313,
    "traditional": "嘗",
    "simplified": "尝",
    "reading": "cháng",
    "meaning": "to taste, to try, to experience, already, ever, once, old variant of 嘗|尝[chang2]",
    "grade": 5
  },
  {
    "id": 3314,
    "traditional": "嘗試",
    "simplified": "尝试",
    "reading": "chángshì",
    "meaning": "attempt, try",
    "grade": 5
  },
  {
    "id": 3315,
    "traditional": "廠長",
    "simplified": "厂长",
    "reading": "chǎngzhǎng",
    "meaning": "factory director",
    "grade": 5
  },
  {
    "id": 3316,
    "traditional": "場面",
    "simplified": "场面",
    "reading": "chǎngmiàn",
    "meaning": "occasion, tableaux, locale, front, theatre, appearance, facade, theater, scene, spectacle, scope",
    "grade": 5
  },
  {
    "id": 3317,
    "traditional": "超越",
    "simplified": "超越",
    "reading": "chāoyuè",
    "meaning": "overpass, get_ahead, outdo, overshoot, outstrip, overstep, excel, outgo, outgrow, outmatch, overpeer, surpass, tower, exceed, overrun, outrun, transcend, cut_across, forerun, outclass, soar, transgress, antecede, beggar, break, outdistance, surmount",
    "grade": 5
  },
  {
    "id": 3318,
    "traditional": "倡導",
    "simplified": "倡导",
    "reading": "chàngdǎo",
    "meaning": "to advocate, to initiate, to propose, to be a proponent of (an idea or school of thought)",
    "grade": 5
  },
  {
    "id": 3319,
    "traditional": "稱",
    "simplified": "称",
    "reading": "chēng",
    "meaning": "call",
    "grade": 5
  },
  {
    "id": 3320,
    "traditional": "車主",
    "simplified": "车主",
    "reading": "chēzhǔ",
    "meaning": "vehicle owner",
    "grade": 5
  },
  {
    "id": 3321,
    "traditional": "稱號",
    "simplified": "称号",
    "reading": "chēnghào",
    "meaning": "name, term of address, title",
    "grade": 5
  },
  {
    "id": 3322,
    "traditional": "成交",
    "simplified": "成交",
    "reading": "chéng jiāo",
    "meaning": "conclude a transaction, clinch a deal, strike a bargain, close a deal",
    "grade": 5
  },
  {
    "id": 3323,
    "traditional": "成效",
    "simplified": "成效",
    "reading": "chéngxiào",
    "meaning": "effect, result",
    "grade": 5
  },
  {
    "id": 3324,
    "traditional": "成本",
    "simplified": "成本",
    "reading": "chéngběn",
    "meaning": "cost",
    "grade": 5
  },
  {
    "id": 3325,
    "traditional": "成語",
    "simplified": "成语",
    "reading": "chéngyǔ",
    "meaning": "Chinese set expression, typically of 4 characters, often alluding to a story or historical quotation, idiom, proverb, saying, adage, CL:條|条[tiao2],本[ben3],句[ju4]",
    "grade": 5
  },
  {
    "id": 3326,
    "traditional": "承辦",
    "simplified": "承办",
    "reading": "chéngbàn",
    "meaning": "undertake",
    "grade": 5
  },
  {
    "id": 3327,
    "traditional": "城裡",
    "simplified": "城里",
    "reading": "chénglǐ",
    "meaning": "In the city",
    "grade": 5
  },
  {
    "id": 3328,
    "traditional": "乘",
    "simplified": "乘",
    "reading": "chéng",
    "meaning": "ride",
    "grade": 5
  },
  {
    "id": 3329,
    "traditional": "乘車",
    "simplified": "乘车",
    "reading": "chéngchē",
    "meaning": "to ride (in a car or carriage), to drive, to motor",
    "grade": 5
  },
  {
    "id": 3330,
    "traditional": "乘坐",
    "simplified": "乘坐",
    "reading": "chéngzuò",
    "meaning": "to ride (in a vehicle)",
    "grade": 5
  },
  {
    "id": 3331,
    "traditional": "吃力",
    "simplified": "吃力",
    "reading": "chīlì",
    "meaning": "to entail strenuous effort, to toil at a task, strenuous, laborious, strain",
    "grade": 5
  },
  {
    "id": 3332,
    "traditional": "池子",
    "simplified": "池子",
    "reading": "chízi",
    "meaning": "pond, bathhouse pool, dance floor of a ballroom, (old) stalls (front rows in a theater)",
    "grade": 5
  },
  {
    "id": 3333,
    "traditional": "乘客",
    "simplified": "乘客",
    "reading": "chéngkè",
    "meaning": "fare, rider, passenger",
    "grade": 5
  },
  {
    "id": 3334,
    "traditional": "衝動",
    "simplified": "冲动",
    "reading": "chōngdòng",
    "meaning": "get excited, be impetuous",
    "grade": 5
  },
  {
    "id": 3335,
    "traditional": "遲",
    "simplified": "迟",
    "reading": "chí",
    "meaning": "late",
    "grade": 5
  },
  {
    "id": 3336,
    "traditional": "充足",
    "simplified": "充足",
    "reading": "chōngzú",
    "meaning": "ample",
    "grade": 5
  },
  {
    "id": 3337,
    "traditional": "衝突",
    "simplified": "冲突",
    "reading": "chōngtū",
    "meaning": "conflict, clash",
    "grade": 5
  },
  {
    "id": 3338,
    "traditional": "愁",
    "simplified": "愁",
    "reading": "chóu",
    "meaning": "to worry about",
    "grade": 5
  },
  {
    "id": 3339,
    "traditional": "臭",
    "simplified": "臭",
    "reading": "chòu",
    "meaning": "whiff, foul, malodorous, disgraceful, stinking, odorous, disgusting",
    "grade": 5
  },
  {
    "id": 3340,
    "traditional": "醜",
    "simplified": "丑",
    "reading": "chǒu",
    "meaning": "surname Chou, shameful, ugly, disgraceful",
    "grade": 5
  },
  {
    "id": 3341,
    "traditional": "出差",
    "simplified": "出差",
    "reading": "chū chāi",
    "meaning": "to go on an official or business trip",
    "grade": 5
  },
  {
    "id": 3342,
    "traditional": "出版",
    "simplified": "出版",
    "reading": "chūbǎn",
    "meaning": "come off the press, publish, come out",
    "grade": 5
  },
  {
    "id": 3343,
    "traditional": "出汗",
    "simplified": "出汗",
    "reading": "chū hàn",
    "meaning": "to perspire, to sweat",
    "grade": 5
  },
  {
    "id": 3344,
    "traditional": "初期",
    "simplified": "初期",
    "reading": "chūqī",
    "meaning": "initial stage, beginning period",
    "grade": 5
  },
  {
    "id": 3345,
    "traditional": "出於",
    "simplified": "出于",
    "reading": "chūyú",
    "meaning": "proceed from, start from, stem from, start, proceed",
    "grade": 5
  },
  {
    "id": 3346,
    "traditional": "除非",
    "simplified": "除非",
    "reading": "chúfēi",
    "meaning": "conj.: only if/when, unless",
    "grade": 5
  },
  {
    "id": 3347,
    "traditional": "廚房",
    "simplified": "厨房",
    "reading": "chúfáng",
    "meaning": "office, cookery, cookhouse, kitchen, cookroom, but, caboose",
    "grade": 5
  },
  {
    "id": 3348,
    "traditional": "處罰",
    "simplified": "处罚",
    "reading": "chǔfá",
    "meaning": "punish, penalize",
    "grade": 5
  },
  {
    "id": 3349,
    "traditional": "除夕",
    "simplified": "除夕",
    "reading": "chúxī",
    "meaning": "lunar New Year's Eve",
    "grade": 5
  },
  {
    "id": 3350,
    "traditional": "處分",
    "simplified": "处分",
    "reading": "chǔfèn",
    "meaning": "punishment",
    "grade": 5
  },
  {
    "id": 3351,
    "traditional": "傳達",
    "simplified": "传达",
    "reading": "chuándá",
    "meaning": "pass on, transmit, communicate",
    "grade": 5
  },
  {
    "id": 3352,
    "traditional": "傳遞",
    "simplified": "传递",
    "reading": "chuándì",
    "meaning": "transmit, deliver, transfer",
    "grade": 5
  },
  {
    "id": 3353,
    "traditional": "傳真",
    "simplified": "传真",
    "reading": "chuánzhēn",
    "meaning": "portraiture, facsimile, fax",
    "grade": 5
  },
  {
    "id": 3354,
    "traditional": "窗簾",
    "simplified": "窗帘",
    "reading": "chuānglián",
    "meaning": "window curtains",
    "grade": 5
  },
  {
    "id": 3355,
    "traditional": "處在",
    "simplified": "处在",
    "reading": "chǔzài",
    "meaning": "be in, be (in a certain condition), be",
    "grade": 5
  },
  {
    "id": 3356,
    "traditional": "闖",
    "simplified": "闯",
    "reading": "chuǎng",
    "meaning": "rush, temper oneself, charge, broke, cause, battle through, break, dash, temper oneself (by battling difficulties)",
    "grade": 5
  },
  {
    "id": 3357,
    "traditional": "創立",
    "simplified": "创立",
    "reading": "chuànglì",
    "meaning": "found, originate",
    "grade": 5
  },
  {
    "id": 3358,
    "traditional": "辭典",
    "simplified": "辞典",
    "reading": "cídiǎn",
    "meaning": "dictionary (of Chinese compound words), also written 詞典|词典[ci2 dian3], CL:本[ben3],部[bu4]",
    "grade": 5
  },
  {
    "id": 3359,
    "traditional": "此後",
    "simplified": "此后",
    "reading": "cǐhòu",
    "meaning": "thenceforth, henceforth, since then, thereafter, after this, hereafter",
    "grade": 5
  },
  {
    "id": 3360,
    "traditional": "辭職",
    "simplified": "辞职",
    "reading": "cí zhí",
    "meaning": "resign",
    "grade": 5
  },
  {
    "id": 3361,
    "traditional": "聰明",
    "simplified": "聪明",
    "reading": "cōngming",
    "meaning": "intelligent, bright, clever",
    "grade": 5
  },
  {
    "id": 3362,
    "traditional": "此刻",
    "simplified": "此刻",
    "reading": "cǐkè",
    "meaning": "this moment, now, at present",
    "grade": 5
  },
  {
    "id": 3363,
    "traditional": "此時",
    "simplified": "此时",
    "reading": "cǐshí",
    "meaning": "now, this moment",
    "grade": 5
  },
  {
    "id": 3364,
    "traditional": "從而",
    "simplified": "从而",
    "reading": "cóngér",
    "meaning": "conj.: thus, thereby",
    "grade": 5
  },
  {
    "id": 3365,
    "traditional": "從中",
    "simplified": "从中",
    "reading": "cóngzhōng",
    "meaning": "out of, from among, therefrom",
    "grade": 5
  },
  {
    "id": 3366,
    "traditional": "脆",
    "simplified": "脆",
    "reading": "cuì",
    "meaning": "brittle, fragile, crisp, crunchy, clear and loud voice, neat",
    "grade": 5
  },
  {
    "id": 3367,
    "traditional": "寸",
    "simplified": "寸",
    "reading": "cùn",
    "meaning": "m.[standard]",
    "grade": 5
  },
  {
    "id": 3368,
    "traditional": "存款",
    "simplified": "存款",
    "reading": "cúnkuǎn",
    "meaning": "deposit, bank saving",
    "grade": 5
  },
  {
    "id": 3369,
    "traditional": "達成",
    "simplified": "达成",
    "reading": "dá chéng",
    "meaning": "reach (an agreement)",
    "grade": 5
  },
  {
    "id": 3370,
    "traditional": "答復",
    "simplified": "答复",
    "reading": "dáfu",
    "meaning": "answer, reply",
    "grade": 5
  },
  {
    "id": 3371,
    "traditional": "答",
    "simplified": "答",
    "reading": "dá",
    "meaning": "answer, reply, return (a call/etc.), reciprocate",
    "grade": 5
  },
  {
    "id": 3372,
    "traditional": "打",
    "simplified": "打",
    "reading": "dǎ",
    "meaning": "generalized verb of doing with specific meaning determined by its object, strike, hit, fight, construct, forge, mix",
    "grade": 5
  },
  {
    "id": 3373,
    "traditional": "打扮",
    "simplified": "打扮",
    "reading": "dǎban",
    "meaning": "dress/make up, deck out, pose as",
    "grade": 5
  },
  {
    "id": 3374,
    "traditional": "打擊",
    "simplified": "打击",
    "reading": "dǎjī",
    "meaning": "stun, frustration, blow",
    "grade": 5
  },
  {
    "id": 3375,
    "traditional": "打包",
    "simplified": "打包",
    "reading": "dǎ bāo",
    "meaning": "to wrap, to pack, to put leftovers in a doggy bag for take-out",
    "grade": 5
  },
  {
    "id": 3376,
    "traditional": "打擾",
    "simplified": "打扰",
    "reading": "dǎrǎo",
    "meaning": "to disturb, to bother, to trouble",
    "grade": 5
  },
  {
    "id": 3377,
    "traditional": "打架",
    "simplified": "打架",
    "reading": "dǎ jià",
    "meaning": "struggle, scrap, wrangle, fight, come to blows, box, scuffle, brawl",
    "grade": 5
  },
  {
    "id": 3378,
    "traditional": "大膽",
    "simplified": "大胆",
    "reading": "dàdǎn",
    "meaning": "courageous, audacious, venture",
    "grade": 5
  },
  {
    "id": 3379,
    "traditional": "大綱",
    "simplified": "大纲",
    "reading": "dàgāng",
    "meaning": "synopsis, outline, program, leading principles",
    "grade": 5
  },
  {
    "id": 3380,
    "traditional": "大都",
    "simplified": "大都",
    "reading": "Dàdū",
    "meaning": "for the most part, mostly",
    "grade": 5
  },
  {
    "id": 3381,
    "traditional": "大伙兒",
    "simplified": "大伙儿",
    "reading": "dàhuǒr5",
    "meaning": "everybody, everyone, we all, erhua variant of 大伙[da4 huo3]",
    "grade": 5
  },
  {
    "id": 3382,
    "traditional": "大獎賽",
    "simplified": "大奖赛",
    "reading": "dàjiǎngsài",
    "meaning": "Grand prize",
    "grade": 5
  },
  {
    "id": 3383,
    "traditional": "大腦",
    "simplified": "大脑",
    "reading": "dànǎo",
    "meaning": "brain, cerebrum",
    "grade": 5
  },
  {
    "id": 3384,
    "traditional": "大事",
    "simplified": "大事",
    "reading": "dàshì",
    "meaning": "important matter, nevermind, capper, overall situation, important events, major event, significant national events, major issue",
    "grade": 5
  },
  {
    "id": 3385,
    "traditional": "大廳",
    "simplified": "大厅",
    "reading": "dàtīng",
    "meaning": "big/main hall, parlor",
    "grade": 5
  },
  {
    "id": 3386,
    "traditional": "大象",
    "simplified": "大象",
    "reading": "dàxiàng",
    "meaning": "elephant",
    "grade": 5
  },
  {
    "id": 3387,
    "traditional": "大熊貓",
    "simplified": "大熊猫",
    "reading": "dàxióngmāo",
    "meaning": "giant panda (Ailuropoda melanoleuca)",
    "grade": 5
  },
  {
    "id": 3388,
    "traditional": "大於",
    "simplified": "大于",
    "reading": "dàyú",
    "meaning": "greater than, bigger than, more than, >",
    "grade": 5
  },
  {
    "id": 3389,
    "traditional": "呆",
    "simplified": "呆",
    "reading": "dāi",
    "meaning": "foolish, stupid, expressionless, blank, to stay",
    "grade": 5
  },
  {
    "id": 3390,
    "traditional": "大致",
    "simplified": "大致",
    "reading": "dàzhì",
    "meaning": "roughly",
    "grade": 5
  },
  {
    "id": 3391,
    "traditional": "代價",
    "simplified": "代价",
    "reading": "dàijià",
    "meaning": "reward, cost, cost (of doing sth.), forfeit, expense, damage, wages, payoff, terms, toll, price, wage",
    "grade": 5
  },
  {
    "id": 3392,
    "traditional": "待",
    "simplified": "待",
    "reading": "dài",
    "meaning": "till",
    "grade": 5
  },
  {
    "id": 3393,
    "traditional": "代理",
    "simplified": "代理",
    "reading": "dàilǐ",
    "meaning": "act as agent/proxy",
    "grade": 5
  },
  {
    "id": 3394,
    "traditional": "帶有",
    "simplified": "带有",
    "reading": "dàiyǒu",
    "meaning": "have, partake of",
    "grade": 5
  },
  {
    "id": 3395,
    "traditional": "貸款",
    "simplified": "贷款",
    "reading": "dàikuǎn",
    "meaning": "loan, credit",
    "grade": 5
  },
  {
    "id": 3396,
    "traditional": "單一",
    "simplified": "单一",
    "reading": "dānyī",
    "meaning": "one, unitary, singular, monolithic, single",
    "grade": 5
  },
  {
    "id": 3397,
    "traditional": "膽小",
    "simplified": "胆小",
    "reading": "dǎnxiǎo",
    "meaning": "cowardice, timid",
    "grade": 5
  },
  {
    "id": 3398,
    "traditional": "膽",
    "simplified": "胆",
    "reading": "dǎn",
    "meaning": "gall bladder, courage, guts, gall, inner container (e.g. bladder of a football, inner container of a thermos)",
    "grade": 5
  },
  {
    "id": 3399,
    "traditional": "當場",
    "simplified": "当场",
    "reading": "dāngchǎng",
    "meaning": "on the spot, then and there",
    "grade": 5
  },
  {
    "id": 3400,
    "traditional": "蛋糕",
    "simplified": "蛋糕",
    "reading": "dàngāo",
    "meaning": "cake, CL:塊|块[kuai4],個|个[ge4]",
    "grade": 5
  },
  {
    "id": 3401,
    "traditional": "當代",
    "simplified": "当代",
    "reading": "dāngdài",
    "meaning": "the present age, the contemporary era",
    "grade": 5
  },
  {
    "id": 3402,
    "traditional": "當年",
    "simplified": "当年",
    "reading": "dāngnián",
    "meaning": "in those days, then, in those years, during that time, that very same year",
    "grade": 5
  },
  {
    "id": 3403,
    "traditional": "當前",
    "simplified": "当前",
    "reading": "dāngqián",
    "meaning": "current, today's, modern, present, to be facing (us)",
    "grade": 5
  },
  {
    "id": 3404,
    "traditional": "當選",
    "simplified": "当选",
    "reading": "dāngxuǎn",
    "meaning": "get elected",
    "grade": 5
  },
  {
    "id": 3405,
    "traditional": "擋",
    "simplified": "挡",
    "reading": "dǎng",
    "meaning": "ward off, block, get in the way of",
    "grade": 5
  },
  {
    "id": 3406,
    "traditional": "到來",
    "simplified": "到来",
    "reading": "dàolái",
    "meaning": "arrive",
    "grade": 5
  },
  {
    "id": 3407,
    "traditional": "道德",
    "simplified": "道德",
    "reading": "dàodé",
    "meaning": "value orientation, value-system, moral, code, moral principle, morals, morale, ethical motive, ethic, virtue, morality_play, morality, ethics, virtuousness, moral excellence",
    "grade": 5
  },
  {
    "id": 3408,
    "traditional": "倒是",
    "simplified": "倒是",
    "reading": "dàoshì",
    "meaning": "actually, contrariwise",
    "grade": 5
  },
  {
    "id": 3409,
    "traditional": "得了",
    "simplified": "得了",
    "reading": "déle",
    "meaning": "all right!, that's enough!, (emphatically, in rhetorical questions) possible",
    "grade": 5
  },
  {
    "id": 3410,
    "traditional": "得以",
    "simplified": "得以",
    "reading": "déyǐ",
    "meaning": "can, so that...can(may)...",
    "grade": 5
  },
  {
    "id": 3411,
    "traditional": "等級",
    "simplified": "等级",
    "reading": "děngjí",
    "meaning": "graduated table, rate, degree, graduation, gradation, ordering, magnitude, caste, grade, social status, ranking, order, class, gree, scale of measurement, step, stream, estate, station, rating, value, echelon, order and degree, ordered series, scale, rubric, notch, division, rank, order of magnitude",
    "grade": 5
  },
  {
    "id": 3412,
    "traditional": "等候",
    "simplified": "等候",
    "reading": "děnghòu",
    "meaning": "hold back, wait, look, expect, abide, await, hold off, tarry, expectation, hang_on",
    "grade": 5
  },
  {
    "id": 3413,
    "traditional": "地帶",
    "simplified": "地带",
    "reading": "dìdài",
    "meaning": "belt, side, corridor, zone, geographical zone, terrain, region, district",
    "grade": 5
  },
  {
    "id": 3414,
    "traditional": "低于",
    "simplified": "低于",
    "reading": "dīyú",
    "meaning": "be lower than",
    "grade": 5
  },
  {
    "id": 3415,
    "traditional": "地形",
    "simplified": "地形",
    "reading": "dìxíng",
    "meaning": "topography, physique, landscape, terrain, chorography, landform",
    "grade": 5
  },
  {
    "id": 3416,
    "traditional": "遞",
    "simplified": "递",
    "reading": "dì",
    "meaning": "to hand over, to pass on sth, to gradually increase or decrease, progressively",
    "grade": 5
  },
  {
    "id": 3417,
    "traditional": "地震",
    "simplified": "地震",
    "reading": "dìzhèn",
    "meaning": "quake",
    "grade": 5
  },
  {
    "id": 3418,
    "traditional": "遞給",
    "simplified": "递给",
    "reading": "dìgěi",
    "meaning": "Handle",
    "grade": 5
  },
  {
    "id": 3419,
    "traditional": "典禮",
    "simplified": "典礼",
    "reading": "diǎnlǐ",
    "meaning": "religious rite, celebration, ceremony, ceremonial occasion, exercise, observance, function, ceremonial, ritual, order, rite",
    "grade": 5
  },
  {
    "id": 3420,
    "traditional": "點燃",
    "simplified": "点燃",
    "reading": "diǎnrán",
    "meaning": "light_up, light up, kindle, enkindle, burn, tind, light, inflame, ignition, set fire to, ignite, emblaze",
    "grade": 5
  },
  {
    "id": 3421,
    "traditional": "電池",
    "simplified": "电池",
    "reading": "diànchí",
    "meaning": "galvanic pile, electric battery, pile, D, battery, cell, (electric) cell, electric_battery, element, voltaic pile",
    "grade": 5
  },
  {
    "id": 3422,
    "traditional": "電子版",
    "simplified": "电子版",
    "reading": "diànzǐbǎn",
    "meaning": "e-edition",
    "grade": 5
  },
  {
    "id": 3423,
    "traditional": "電飯鍋",
    "simplified": "电饭锅",
    "reading": "diànfànguō",
    "meaning": "electric rice cooker",
    "grade": 5
  },
  {
    "id": 3424,
    "traditional": "丟",
    "simplified": "丢",
    "reading": "diū",
    "meaning": "discard, put aside",
    "grade": 5
  },
  {
    "id": 3425,
    "traditional": "調動",
    "simplified": "调动",
    "reading": "diàodòng",
    "meaning": "to transfer, to maneuver (troops etc), movement of personnel, to mobilize, to bring into play",
    "grade": 5
  },
  {
    "id": 3426,
    "traditional": "動手",
    "simplified": "动手",
    "reading": "dòng shǒu",
    "meaning": "attack, get to work, hit out, begin, strike, hit, start work, handle, raise a hand to strike, fall, start action, touch",
    "grade": 5
  },
  {
    "id": 3427,
    "traditional": "動機",
    "simplified": "动机",
    "reading": "dòngjī",
    "meaning": "motif, need, reason, instance, ground, incentive, signal, intention, motive, motivation, inducement, cause",
    "grade": 5
  },
  {
    "id": 3428,
    "traditional": "動態",
    "simplified": "动态",
    "reading": "dòngtài",
    "meaning": "situation, the movement (in a certain sphere of human activity), development, trend, dynamic state, dynamic, trends",
    "grade": 5
  },
  {
    "id": 3429,
    "traditional": "動員",
    "simplified": "动员",
    "reading": "dòngyuán",
    "meaning": "mobilize, arouse",
    "grade": 5
  },
  {
    "id": 3430,
    "traditional": "洞",
    "simplified": "洞",
    "reading": "dòng",
    "meaning": "aperture, hole in the ground, kennel, puncture, excavation, perforation, bore, cave, howe, opening, gap, hole, orifice, scoop, hollow, cavity",
    "grade": 5
  },
  {
    "id": 3431,
    "traditional": "凍",
    "simplified": "冻",
    "reading": "dòng",
    "meaning": "to freeze, to feel very cold, aspic or jelly",
    "grade": 5
  },
  {
    "id": 3432,
    "traditional": "豆製品",
    "simplified": "豆制品",
    "reading": "dòuzhìpǐn",
    "meaning": "Bean products",
    "grade": 5
  },
  {
    "id": 3433,
    "traditional": "毒",
    "simplified": "毒",
    "reading": "dú",
    "meaning": "poison, toxin, narcotics",
    "grade": 5
  },
  {
    "id": 3434,
    "traditional": "堆",
    "simplified": "堆",
    "reading": "duī",
    "meaning": "m.[proximation]",
    "grade": 5
  },
  {
    "id": 3435,
    "traditional": "對立",
    "simplified": "对立",
    "reading": "duìlì",
    "meaning": "oppose, set sth. against, be antagonist to",
    "grade": 5
  },
  {
    "id": 3436,
    "traditional": "對應",
    "simplified": "对应",
    "reading": "duìyìng",
    "meaning": "correspondence, harmonize, map, homologous, corresponding, mapping, agree, reciprocal, counterpart, represent, stand for, accord, equate, homologize, correspond",
    "grade": 5
  },
  {
    "id": 3437,
    "traditional": "朵",
    "simplified": "朵",
    "reading": "duǒ",
    "meaning": "m.[general]",
    "grade": 5
  },
  {
    "id": 3438,
    "traditional": "噸",
    "simplified": "吨",
    "reading": "dūn",
    "meaning": "metric ton, acre, ton, m.[standard], ,A, MT, tonne, t",
    "grade": 5
  },
  {
    "id": 3439,
    "traditional": "躲",
    "simplified": "躲",
    "reading": "duǒ",
    "meaning": "avoid, hide, hide oneself, dodge, hide (oneself)",
    "grade": 5
  },
  {
    "id": 3440,
    "traditional": "耳朵",
    "simplified": "耳朵",
    "reading": "ěrduo",
    "meaning": "auricle, lobe, lobule, ear, auditory, lap, lug, lappet",
    "grade": 5
  },
  {
    "id": 3441,
    "traditional": "兒女",
    "simplified": "儿女",
    "reading": "érnǚ",
    "meaning": "sons and daughters, young man and woman, children, young males and females, child",
    "grade": 5
  },
  {
    "id": 3442,
    "traditional": "二維碼",
    "simplified": "二维码",
    "reading": "èrwéimǎ",
    "meaning": "two-dimensional barcode, QR code",
    "grade": 5
  },
  {
    "id": 3443,
    "traditional": "發覺",
    "simplified": "发觉",
    "reading": "fājué",
    "meaning": "light upon, discovery, find, see, espy, discover, perceive, strike, come upon, detection, come across, happen upon, detect, chance upon, chance on",
    "grade": 5
  },
  {
    "id": 3444,
    "traditional": "發射",
    "simplified": "发射",
    "reading": "fāshè",
    "meaning": "launch, project, discharge, shoot, transmit, emit",
    "grade": 5
  },
  {
    "id": 3445,
    "traditional": "發行",
    "simplified": "发行",
    "reading": "fāháng",
    "meaning": "issue, publish, distribute, put on sale",
    "grade": 5
  },
  {
    "id": 3446,
    "traditional": "罰",
    "simplified": "罚",
    "reading": "fá",
    "meaning": "punish, penalize",
    "grade": 5
  },
  {
    "id": 3447,
    "traditional": "發布",
    "simplified": "发布",
    "reading": "fābù",
    "meaning": "issue, announce",
    "grade": 5
  },
  {
    "id": 3448,
    "traditional": "罰款",
    "simplified": "罚款",
    "reading": "fákuǎn",
    "meaning": "to fine, penalty, fine (monetary)",
    "grade": 5
  },
  {
    "id": 3449,
    "traditional": "法制",
    "simplified": "法制",
    "reading": "fǎzhì",
    "meaning": "legal system and institutions, made in France",
    "grade": 5
  },
  {
    "id": 3450,
    "traditional": "法規",
    "simplified": "法规",
    "reading": "fǎguī",
    "meaning": "rule of law, proscription, ordinance, codification, laws and regulations, law, legislation, interdiction, rule, code, statutes, statute, canon, legislative act, law and regulations, prescription, prohibition, ban",
    "grade": 5
  },
  {
    "id": 3451,
    "traditional": "返回",
    "simplified": "返回",
    "reading": "fǎnhuí",
    "meaning": "come back, recur, turn back, come, hark back, regress, turn vehicle around, revert, come/go back, enter, return, go back, recall, take back, backtrack",
    "grade": 5
  },
  {
    "id": 3452,
    "traditional": "繁榮",
    "simplified": "繁荣",
    "reading": "fánróng",
    "meaning": "flourishing, prosperous, booming",
    "grade": 5
  },
  {
    "id": 3453,
    "traditional": "放大",
    "simplified": "放大",
    "reading": "fàng dà",
    "meaning": "megascopic, blow_up, magnify, blow up, amplify, enhance, enlarge, enlargement",
    "grade": 5
  },
  {
    "id": 3454,
    "traditional": "防治",
    "simplified": "防治",
    "reading": "fángzhì",
    "meaning": "prevent and cure, prophylaxis and treatment",
    "grade": 5
  },
  {
    "id": 3455,
    "traditional": "放棄",
    "simplified": "放弃",
    "reading": "fàngqì",
    "meaning": "depart, chuck up the sponge, scuttle, dispense with, throw in the towel, abort, throw in, flunk, foreswear, abnegate, pass, free, lay_down, abandonment, disclaim, part_with, pass_up, abdicate, release, ditch, abjure, jettison, waive, surrender, leave, give, recant, desert, give up, divest, yield, forswear, break, abandon, unguard, back_down, yield up, demit, relinquish, break_with, drop",
    "grade": 5
  },
  {
    "id": 3456,
    "traditional": "分成",
    "simplified": "分成",
    "reading": "fēn chéng",
    "meaning": "divide, split a bonus, divide into, divide (into)",
    "grade": 5
  },
  {
    "id": 3457,
    "traditional": "分類",
    "simplified": "分类",
    "reading": "fēn lèi",
    "meaning": "sort",
    "grade": 5
  },
  {
    "id": 3458,
    "traditional": "分離",
    "simplified": "分离",
    "reading": "fēnlí",
    "meaning": "to separate",
    "grade": 5
  },
  {
    "id": 3459,
    "traditional": "分解",
    "simplified": "分解",
    "reading": "fēnjiě",
    "meaning": "to resolve, to decompose, to break down",
    "grade": 5
  },
  {
    "id": 3460,
    "traditional": "豐收",
    "simplified": "丰收",
    "reading": "fēngshōu",
    "meaning": "bumper harvest",
    "grade": 5
  },
  {
    "id": 3461,
    "traditional": "分享",
    "simplified": "分享",
    "reading": "fēnxiǎng",
    "meaning": "share (joy/rights/etc.), partake of",
    "grade": 5
  },
  {
    "id": 3462,
    "traditional": "分析",
    "simplified": "分析",
    "reading": "fēnxī",
    "meaning": "analyze",
    "grade": 5
  },
  {
    "id": 3463,
    "traditional": "風度",
    "simplified": "风度",
    "reading": "fēngdù",
    "meaning": "elegance (for men), elegant demeanor, grace, poise",
    "grade": 5
  },
  {
    "id": 3464,
    "traditional": "風光",
    "simplified": "风光",
    "reading": "fēngguang",
    "meaning": "scene, view, sight",
    "grade": 5
  },
  {
    "id": 3465,
    "traditional": "封",
    "simplified": "封",
    "reading": "fēng",
    "meaning": "m.[general]",
    "grade": 5
  },
  {
    "id": 3466,
    "traditional": "瘋狂",
    "simplified": "疯狂",
    "reading": "fēngkuáng",
    "meaning": "insane, frenzied, unbridled",
    "grade": 5
  },
  {
    "id": 3467,
    "traditional": "扶",
    "simplified": "扶",
    "reading": "fú",
    "meaning": "support, straighten ... up, support with the hand, place a hand on, help sb. up, relief, relieve, straighten sth. up, help ... up, help, support with hand",
    "grade": 5
  },
  {
    "id": 3468,
    "traditional": "瘋",
    "simplified": "疯",
    "reading": "fēng",
    "meaning": "insane, mad, wild",
    "grade": 5
  },
  {
    "id": 3469,
    "traditional": "服從",
    "simplified": "服从",
    "reading": "fúcóng",
    "meaning": "to obey (an order), to comply, to defer",
    "grade": 5
  },
  {
    "id": 3470,
    "traditional": "幅度",
    "simplified": "幅度",
    "reading": "fúdù",
    "meaning": "amplitude, breadth, extent, spread, range, spectrum, scope",
    "grade": 5
  },
  {
    "id": 3471,
    "traditional": "福利",
    "simplified": "福利",
    "reading": "fúlì",
    "meaning": "well-being, fringe_benefit, material benefits, social welfare, weal, welfare",
    "grade": 5
  },
  {
    "id": 3472,
    "traditional": "幅",
    "simplified": "幅",
    "reading": "fú",
    "meaning": "vector, width of cloth, size, piece, m.[general],",
    "grade": 5
  },
  {
    "id": 3473,
    "traditional": "輔助",
    "simplified": "辅助",
    "reading": "fǔzhù",
    "meaning": "assist",
    "grade": 5
  },
  {
    "id": 3474,
    "traditional": "負責人",
    "simplified": "负责人",
    "reading": "fùzérén",
    "meaning": "sponsor, point man, leading cadre, person in charge, undertaker, keeper",
    "grade": 5
  },
  {
    "id": 3475,
    "traditional": "附件",
    "simplified": "附件",
    "reading": "fùjiàn",
    "meaning": "enclosure, attachment (email), appendix",
    "grade": 5
  },
  {
    "id": 3476,
    "traditional": "改革",
    "simplified": "改革",
    "reading": "gǎigé",
    "meaning": "reform",
    "grade": 5
  },
  {
    "id": 3477,
    "traditional": "乾脆",
    "simplified": "干脆",
    "reading": "gāncuì",
    "meaning": "direct, forthright",
    "grade": 5
  },
  {
    "id": 3478,
    "traditional": "感想",
    "simplified": "感想",
    "reading": "gǎnxiǎng",
    "meaning": "sentiment, reflections, feeling, thoughts, impressions, impression",
    "grade": 5
  },
  {
    "id": 3479,
    "traditional": "干擾",
    "simplified": "干扰",
    "reading": "gānrǎo",
    "meaning": "disturb, interfere",
    "grade": 5
  },
  {
    "id": 3480,
    "traditional": "干預",
    "simplified": "干预",
    "reading": "gānyù",
    "meaning": "intervene, interfere",
    "grade": 5
  },
  {
    "id": 3481,
    "traditional": "鋼琴",
    "simplified": "钢琴",
    "reading": "gāngqín",
    "meaning": "forte-piano, piano, pianoforte",
    "grade": 5
  },
  {
    "id": 3482,
    "traditional": "鋼筆",
    "simplified": "钢笔",
    "reading": "gāngbǐ",
    "meaning": "fountain pen, CL:支[zhi1]",
    "grade": 5
  },
  {
    "id": 3483,
    "traditional": "高度",
    "simplified": "高度",
    "reading": "gāodù",
    "meaning": "highly, greatly",
    "grade": 5
  },
  {
    "id": 3484,
    "traditional": "高跟鞋",
    "simplified": "高跟鞋",
    "reading": "gāogēnxié",
    "meaning": "high-heeled shoes",
    "grade": 5
  },
  {
    "id": 3485,
    "traditional": "高大",
    "simplified": "高大",
    "reading": "gāodà",
    "meaning": "tall and big, great, massive",
    "grade": 5
  },
  {
    "id": 3486,
    "traditional": "高溫",
    "simplified": "高温",
    "reading": "gāowēn",
    "meaning": "heat, high temperature",
    "grade": 5
  },
  {
    "id": 3487,
    "traditional": "高原",
    "simplified": "高原",
    "reading": "gāoyuán",
    "meaning": "plateau, CL:片[pian4]",
    "grade": 5
  },
  {
    "id": 3488,
    "traditional": "高於",
    "simplified": "高于",
    "reading": "gāoyú",
    "meaning": "greater than, to exceed",
    "grade": 5
  },
  {
    "id": 3489,
    "traditional": "搞好",
    "simplified": "搞好",
    "reading": "gǎo hǎo",
    "meaning": "to do well at, to do a good job",
    "grade": 5
  },
  {
    "id": 3490,
    "traditional": "搞",
    "simplified": "搞",
    "reading": "gǎo",
    "meaning": "work, set up, work out, organize, cause, etc., produce a certain effect, secure, manage, get hold of, carry, produce, organization, generalized doing: do, start, get, do, be engaged in, generalized doing: work/manage/etc., make, generalized doing: do/work/manage/etc.",
    "grade": 5
  },
  {
    "id": 3491,
    "traditional": "歌曲",
    "simplified": "歌曲",
    "reading": "gēqǔ",
    "meaning": "melody, aria, song, madrigal, chant, lied, tune",
    "grade": 5
  },
  {
    "id": 3492,
    "traditional": "個兒",
    "simplified": "个儿",
    "reading": "gèr5",
    "meaning": "size, height, stature",
    "grade": 5
  },
  {
    "id": 3493,
    "traditional": "隔壁",
    "simplified": "隔壁",
    "reading": "gébì",
    "meaning": "next door",
    "grade": 5
  },
  {
    "id": 3494,
    "traditional": "跟前",
    "simplified": "跟前",
    "reading": "gēnqian",
    "meaning": "the front (of), (in) front, (in) sb's presence, just before (a date), (of children, parents etc) at one's side, living with one",
    "grade": 5
  },
  {
    "id": 3495,
    "traditional": "更換",
    "simplified": "更换",
    "reading": "gēnghuàn",
    "meaning": "change, replace",
    "grade": 5
  },
  {
    "id": 3496,
    "traditional": "跟隨",
    "simplified": "跟随",
    "reading": "gēnsuí",
    "meaning": "keep abreast, succeed, heel, come after, keep up, follow, tag, pursue",
    "grade": 5
  },
  {
    "id": 3497,
    "traditional": "工藝",
    "simplified": "工艺",
    "reading": "gōngyì",
    "meaning": "technology, craft",
    "grade": 5
  },
  {
    "id": 3498,
    "traditional": "工作日",
    "simplified": "工作日",
    "reading": "gōngzuòrì",
    "meaning": "workday, working day, weekday",
    "grade": 5
  },
  {
    "id": 3499,
    "traditional": "更新",
    "simplified": "更新",
    "reading": "gēngxīn",
    "meaning": "renew, replace",
    "grade": 5
  },
  {
    "id": 3500,
    "traditional": "公認",
    "simplified": "公认",
    "reading": "gōngrèn",
    "meaning": "publicly known (to be), accepted (as)",
    "grade": 5
  },
  {
    "id": 3501,
    "traditional": "公式",
    "simplified": "公式",
    "reading": "gōngshì",
    "meaning": "formula",
    "grade": 5
  },
  {
    "id": 3502,
    "traditional": "公告",
    "simplified": "公告",
    "reading": "gōnggào",
    "meaning": "declared, placard, declare, proclaim, herald, announce, bill, decree, advertise",
    "grade": 5
  },
  {
    "id": 3503,
    "traditional": "公正",
    "simplified": "公正",
    "reading": "gōngzhèng",
    "meaning": "impartial",
    "grade": 5
  },
  {
    "id": 3504,
    "traditional": "共計",
    "simplified": "共计",
    "reading": "gòngjì",
    "meaning": "to sum up to, to total",
    "grade": 5
  },
  {
    "id": 3505,
    "traditional": "共享",
    "simplified": "共享",
    "reading": "gòngxiǎng",
    "meaning": "enjoy together, share",
    "grade": 5
  },
  {
    "id": 3506,
    "traditional": "溝通",
    "simplified": "沟通",
    "reading": "gōutōng",
    "meaning": "communicate, link up",
    "grade": 5
  },
  {
    "id": 3507,
    "traditional": "溝",
    "simplified": "沟",
    "reading": "gōu",
    "meaning": "ditch, gutter, groove, gully, ravine, CL:道[dao4]",
    "grade": 5
  },
  {
    "id": 3508,
    "traditional": "鼓",
    "simplified": "鼓",
    "reading": "gǔ",
    "meaning": "drum, CL:通[tong4],面[mian4], to drum, to strike, to rouse, to bulge, to swell",
    "grade": 5
  },
  {
    "id": 3509,
    "traditional": "估計",
    "simplified": "估计",
    "reading": "gūjì",
    "meaning": "forecast, set, evaluate, rate, gauge, measure, count, assess, calculate, estimate, reckon, appraisal, compute, estimated, value, appraise, put, overrate, make, place, size_up",
    "grade": 5
  },
  {
    "id": 3510,
    "traditional": "古老",
    "simplified": "古老",
    "reading": "gǔlǎo",
    "meaning": "ancient, old",
    "grade": 5
  },
  {
    "id": 3511,
    "traditional": "鼓掌",
    "simplified": "鼓掌",
    "reading": "gǔ zhǎng",
    "meaning": "clap one's hands; applaud",
    "grade": 5
  },
  {
    "id": 3512,
    "traditional": "鼓勵",
    "simplified": "鼓励",
    "reading": "gǔlì",
    "meaning": "encourage, urge",
    "grade": 5
  },
  {
    "id": 3513,
    "traditional": "顧問",
    "simplified": "顾问",
    "reading": "gùwèn",
    "meaning": "counsellor, advisor, councillor, friend, consultant, counsellorship, counselorship, adviser, counselor",
    "grade": 5
  },
  {
    "id": 3514,
    "traditional": "關懷",
    "simplified": "关怀",
    "reading": "guānhuái",
    "meaning": "show loving care/concern for",
    "grade": 5
  },
  {
    "id": 3515,
    "traditional": "關鍵",
    "simplified": "关键",
    "reading": "guānjiàn",
    "meaning": "nub, turning_point, issue, crux, anchor, crux of the matter, juncture, hinge, mainstay, linchpin, lynchpin, key, backbone",
    "grade": 5
  },
  {
    "id": 3516,
    "traditional": "冠軍",
    "simplified": "冠军",
    "reading": "guànjūn",
    "meaning": "champion",
    "grade": 5
  },
  {
    "id": 3517,
    "traditional": "怪",
    "simplified": "怪",
    "reading": "guài",
    "meaning": "find sth. strange, blame",
    "grade": 5
  },
  {
    "id": 3518,
    "traditional": "光榮",
    "simplified": "光荣",
    "reading": "guāngróng",
    "meaning": "honor, glory, credit",
    "grade": 5
  },
  {
    "id": 3519,
    "traditional": "光線",
    "simplified": "光线",
    "reading": "guāngxiàn",
    "meaning": "ray_of_light, shaft, beam, beam of light, radiation, light beam, sunray, light, lighting, ray, ray of light, shaft of light",
    "grade": 5
  },
  {
    "id": 3520,
    "traditional": "廣",
    "simplified": "广",
    "reading": "guǎng",
    "meaning": "wide, spread, vast, expand, numerous",
    "grade": 5
  },
  {
    "id": 3521,
    "traditional": "廣泛",
    "simplified": "广泛",
    "reading": "guǎngfàn",
    "meaning": "extensive, wide-ranging",
    "grade": 5
  },
  {
    "id": 3522,
    "traditional": "鬼",
    "simplified": "鬼",
    "reading": "guǐ",
    "meaning": "sinister plot, apparition, dirty trick, sprite, spook, spectre, revenant, spirit, wraith, genie, bogle, specter, ghost",
    "grade": 5
  },
  {
    "id": 3523,
    "traditional": "規劃",
    "simplified": "规划",
    "reading": "guīhuà",
    "meaning": "plan, program",
    "grade": 5
  },
  {
    "id": 3524,
    "traditional": "櫃子",
    "simplified": "柜子",
    "reading": "guìzi",
    "meaning": "cupboard, cabinet",
    "grade": 5
  },
  {
    "id": 3525,
    "traditional": "滾",
    "simplified": "滚",
    "reading": "gǔn",
    "meaning": "to boil, to roll, to take a hike, Get lost!",
    "grade": 5
  },
  {
    "id": 3526,
    "traditional": "國籍",
    "simplified": "国籍",
    "reading": "guójí",
    "meaning": "nationality",
    "grade": 5
  },
  {
    "id": 3527,
    "traditional": "鍋",
    "simplified": "锅",
    "reading": "guō",
    "meaning": "pot, pan, boiler, CL:口[kou3],隻|只[zhi1]",
    "grade": 5
  },
  {
    "id": 3528,
    "traditional": "國民",
    "simplified": "国民",
    "reading": "guómín",
    "meaning": "citizen, country, citizenry, a people, nationality, nation, folk, subject, people, nationals, national, commonwealth, son, land",
    "grade": 5
  },
  {
    "id": 3529,
    "traditional": "過於",
    "simplified": "过于",
    "reading": "guòyú",
    "meaning": "to a fault, unduly, too_much, troppo, over-, excessively, too, too much",
    "grade": 5
  },
  {
    "id": 3530,
    "traditional": "過敏",
    "simplified": "过敏",
    "reading": "guòmǐn",
    "meaning": "oversensitive, allergic, allergy",
    "grade": 5
  },
  {
    "id": 3531,
    "traditional": "害",
    "simplified": "害",
    "reading": "hài",
    "meaning": "harm, injure, kill",
    "grade": 5
  },
  {
    "id": 3532,
    "traditional": "過度",
    "simplified": "过度",
    "reading": "guòdù",
    "meaning": "too, excessively",
    "grade": 5
  },
  {
    "id": 3533,
    "traditional": "好運",
    "simplified": "好运",
    "reading": "hǎoyùn",
    "meaning": "good luck",
    "grade": 5
  },
  {
    "id": 3534,
    "traditional": "號召",
    "simplified": "号召",
    "reading": "hàozhào",
    "meaning": "call, appeal (for supporters)",
    "grade": 5
  },
  {
    "id": 3535,
    "traditional": "汗",
    "simplified": "汗",
    "reading": "hàn",
    "meaning": "see 可汗[ke4 han2], 汗國|汗国[han2 guo2], perspiration, sweat, CL:滴[di1],頭|头[tou2],身[shen1], to be speechless (out of helplessness, embarrassment etc) (Internet slang used as an interjection)",
    "grade": 5
  },
  {
    "id": 3536,
    "traditional": "合併",
    "simplified": "合并",
    "reading": "hébìng",
    "meaning": "merge, amalgamate",
    "grade": 5
  },
  {
    "id": 3537,
    "traditional": "合成",
    "simplified": "合成",
    "reading": "héchéng",
    "meaning": "prefabricate, composition, compound, synthesis, synthesize, synthesise, synthetic, synergistic, composite, compose",
    "grade": 5
  },
  {
    "id": 3538,
    "traditional": "盒飯",
    "simplified": "盒饭",
    "reading": "héfàn",
    "meaning": "meal in a partitioned box",
    "grade": 5
  },
  {
    "id": 3539,
    "traditional": "盒",
    "simplified": "盒",
    "reading": "hé",
    "meaning": "small box, case",
    "grade": 5
  },
  {
    "id": 3540,
    "traditional": "賀卡",
    "simplified": "贺卡",
    "reading": "hèkǎ",
    "meaning": "greeting card, congratulation card",
    "grade": 5
  },
  {
    "id": 3541,
    "traditional": "恨",
    "simplified": "恨",
    "reading": "hèn",
    "meaning": "regret, hate, detest, be exasperated",
    "grade": 5
  },
  {
    "id": 3542,
    "traditional": "盒子",
    "simplified": "盒子",
    "reading": "hézi",
    "meaning": "box, case, casket",
    "grade": 5
  },
  {
    "id": 3543,
    "traditional": "後悔",
    "simplified": "后悔",
    "reading": "hòuhuǐ",
    "meaning": "regret, repent",
    "grade": 5
  },
  {
    "id": 3544,
    "traditional": "胡同兒",
    "simplified": "胡同儿",
    "reading": "hútòngr5",
    "meaning": "Alley",
    "grade": 5
  },
  {
    "id": 3545,
    "traditional": "鬍子",
    "simplified": "胡子",
    "reading": "húzi",
    "meaning": "beard, mustache or whiskers, facial hair, CL:撮[zuo3],根[gen1], (coll.) bandit",
    "grade": 5
  },
  {
    "id": 3546,
    "traditional": "猴",
    "simplified": "猴",
    "reading": "hóu",
    "meaning": "monkey, CL:隻|只[zhi1]",
    "grade": 5
  },
  {
    "id": 3547,
    "traditional": "華語",
    "simplified": "华语",
    "reading": "Huáyǔ",
    "meaning": "Chinese language",
    "grade": 5
  },
  {
    "id": 3548,
    "traditional": "虎",
    "simplified": "虎",
    "reading": "hǔ",
    "meaning": "tiger",
    "grade": 5
  },
  {
    "id": 3549,
    "traditional": "滑",
    "simplified": "滑",
    "reading": "huá",
    "meaning": "slippery, smooth, cunning, crafty",
    "grade": 5
  },
  {
    "id": 3550,
    "traditional": "化石",
    "simplified": "化石",
    "reading": "huàshí",
    "meaning": "fossil",
    "grade": 5
  },
  {
    "id": 3551,
    "traditional": "畫面",
    "simplified": "画面",
    "reading": "huàmiàn",
    "meaning": "frame, tableau, picture plane, picture, general appearance of a picture, picture_plane, appearance, TV screen, video",
    "grade": 5
  },
  {
    "id": 3552,
    "traditional": "劃分",
    "simplified": "划分",
    "reading": "huàfēn",
    "meaning": "divide, differentiate",
    "grade": 5
  },
  {
    "id": 3553,
    "traditional": "環節",
    "simplified": "环节",
    "reading": "huánjié",
    "meaning": "round segment, segment (of annelid worms), connection, link, sector, annular ring",
    "grade": 5
  },
  {
    "id": 3554,
    "traditional": "慌忙",
    "simplified": "慌忙",
    "reading": "huāngmáng",
    "meaning": "in a great rush, in a flurry",
    "grade": 5
  },
  {
    "id": 3555,
    "traditional": "慌",
    "simplified": "慌",
    "reading": "huāng",
    "meaning": "to get panicky, to lose one's head, (coll.) (after 得) unbearably, terribly",
    "grade": 5
  },
  {
    "id": 3556,
    "traditional": "灰色",
    "simplified": "灰色",
    "reading": "huīsè",
    "meaning": "pessimistic, grey, ashy, grayness, ambiguous, grizzle, gloomy, gray, greyness, obscure",
    "grade": 5
  },
  {
    "id": 3557,
    "traditional": "回報",
    "simplified": "回报",
    "reading": "huíbào",
    "meaning": "repay, reciprocate, retaliate",
    "grade": 5
  },
  {
    "id": 3558,
    "traditional": "回顧",
    "simplified": "回顾",
    "reading": "huígù",
    "meaning": "look back, review",
    "grade": 5
  },
  {
    "id": 3559,
    "traditional": "回收",
    "simplified": "回收",
    "reading": "huíshōu",
    "meaning": "recycle",
    "grade": 5
  },
  {
    "id": 3560,
    "traditional": "迴避",
    "simplified": "回避",
    "reading": "huíbì",
    "meaning": "to shun, to avoid (sb), to skirt, to evade (an issue), to step back, to withdraw, to recuse (a judge etc)",
    "grade": 5
  },
  {
    "id": 3561,
    "traditional": "回頭",
    "simplified": "回头",
    "reading": "huítóu",
    "meaning": "turn one's head, turn round, repent",
    "grade": 5
  },
  {
    "id": 3562,
    "traditional": "恢復",
    "simplified": "恢复",
    "reading": "huīfù",
    "meaning": "resume, renew, recover, regain, restore, reinstate, rehabilitate",
    "grade": 5
  },
  {
    "id": 3563,
    "traditional": "回信",
    "simplified": "回信",
    "reading": "huíxìn",
    "meaning": "to reply, to write back, letter written in reply, CL:封[feng1]",
    "grade": 5
  },
  {
    "id": 3564,
    "traditional": "匯款",
    "simplified": "汇款",
    "reading": "huìkuǎn",
    "meaning": "to remit money, remittance",
    "grade": 5
  },
  {
    "id": 3565,
    "traditional": "活力",
    "simplified": "活力",
    "reading": "huólì",
    "meaning": "juvenility, sprightliness, pulse, youthfulness, stamina, bounciness, birr, sparkle, will, vigor, life, energy, vinegar, zap, vim, viridity, pride, livingness, vis, starch, get-up-and-go, mercury, vitality, zing, ginger, sap, pepper, zip, bang, verve, verdure, lifeblood, devilry, dynamism, nature, oomph, vigour, flush, punch, ambition, virility, life-blood, liveliness, pazazz, peppiness, pep, p",
    "grade": 5
  },
  {
    "id": 3566,
    "traditional": "會談",
    "simplified": "会谈",
    "reading": "huìtán",
    "meaning": "talk",
    "grade": 5
  },
  {
    "id": 3567,
    "traditional": "活潑",
    "simplified": "活泼",
    "reading": "huópo",
    "meaning": "lively, vivacious, vivid",
    "grade": 5
  },
  {
    "id": 3568,
    "traditional": "迴憶",
    "simplified": "回忆",
    "reading": "huíyì",
    "meaning": "recollect, recall",
    "grade": 5
  },
  {
    "id": 3569,
    "traditional": "火柴",
    "simplified": "火柴",
    "reading": "huǒchái",
    "meaning": "match (for lighting fire), CL:根[gen1],盒[he2]",
    "grade": 5
  },
  {
    "id": 3570,
    "traditional": "火腿",
    "simplified": "火腿",
    "reading": "huǒtuǐ",
    "meaning": "ham, CL:個|个[ge4]",
    "grade": 5
  },
  {
    "id": 3571,
    "traditional": "或是",
    "simplified": "或是",
    "reading": "huòshì",
    "meaning": "conj.: or, perhaps",
    "grade": 5
  },
  {
    "id": 3572,
    "traditional": "機器人",
    "simplified": "机器人",
    "reading": "jīqìrén",
    "meaning": "mechanical person, robot, android",
    "grade": 5
  },
  {
    "id": 3573,
    "traditional": "火災",
    "simplified": "火灾",
    "reading": "huǒzāi",
    "meaning": "fire disaster, conflagration",
    "grade": 5
  },
  {
    "id": 3574,
    "traditional": "肌肉",
    "simplified": "肌肉",
    "reading": "jīròu",
    "meaning": "brawn, muscular, beef, flesh, muscle, thew, sinew",
    "grade": 5
  },
  {
    "id": 3575,
    "traditional": "機製",
    "simplified": "机制",
    "reading": "jīzhì",
    "meaning": "mechanism",
    "grade": 5
  },
  {
    "id": 3576,
    "traditional": "基金",
    "simplified": "基金",
    "reading": "jījīn",
    "meaning": "finances, endowment fund, foundation, fundation, treasury, fund, corpus, endowment, exchequer, investment firm, investment trust, investment company",
    "grade": 5
  },
  {
    "id": 3577,
    "traditional": "即使",
    "simplified": "即使",
    "reading": "jíshǐ",
    "meaning": "conj.: even, even if/though",
    "grade": 5
  },
  {
    "id": 3578,
    "traditional": "基地",
    "simplified": "基地",
    "reading": "jīdì",
    "meaning": "base",
    "grade": 5
  },
  {
    "id": 3579,
    "traditional": "擠",
    "simplified": "挤",
    "reading": "jǐ",
    "meaning": "squeeze, press",
    "grade": 5
  },
  {
    "id": 3580,
    "traditional": "記憶",
    "simplified": "记忆",
    "reading": "jìyì",
    "meaning": "memory",
    "grade": 5
  },
  {
    "id": 3581,
    "traditional": "集團",
    "simplified": "集团",
    "reading": "jítuán",
    "meaning": "population, aggregate, circle, clique, grouping, bloc, phalanx, group, knot, aggregation",
    "grade": 5
  },
  {
    "id": 3582,
    "traditional": "加熱",
    "simplified": "加热",
    "reading": "jiā rè",
    "meaning": "to heat",
    "grade": 5
  },
  {
    "id": 3583,
    "traditional": "繼承",
    "simplified": "继承",
    "reading": "jìchéng",
    "meaning": "inherit, carry on",
    "grade": 5
  },
  {
    "id": 3584,
    "traditional": "技能",
    "simplified": "技能",
    "reading": "jìnéng",
    "meaning": "know-how, mastery of a technique, ability, acquisition, acquirement, mastery of a skill, proficiency, skill, craft, technical ability, technique, mastery of a skill/technique, facility, quality, faculty",
    "grade": 5
  },
  {
    "id": 3585,
    "traditional": "加上",
    "simplified": "加上",
    "reading": "jiāshang",
    "meaning": "conj.: in addition (to)",
    "grade": 5
  },
  {
    "id": 3586,
    "traditional": "加速",
    "simplified": "加速",
    "reading": "jiāsù",
    "meaning": "quicken, accelerate, expedite",
    "grade": 5
  },
  {
    "id": 3587,
    "traditional": "夾",
    "simplified": "夹",
    "reading": "jiā",
    "meaning": "press from both sides, place in between, mix, mingle, intersperse, carry secretly",
    "grade": 5
  },
  {
    "id": 3588,
    "traditional": "加以",
    "simplified": "加以",
    "reading": "jiāyǐ",
    "meaning": "handle; treat (used before polysyllabic verbs or verbal nouns)",
    "grade": 5
  },
  {
    "id": 3589,
    "traditional": "駕駛",
    "simplified": "驾驶",
    "reading": "jiàshǐ",
    "meaning": "drive (vehicle), pilot (ship/plane)",
    "grade": 5
  },
  {
    "id": 3590,
    "traditional": "價",
    "simplified": "价",
    "reading": "jià",
    "meaning": "price, value, (chemistry) valence, great, good, middleman, servant",
    "grade": 5
  },
  {
    "id": 3591,
    "traditional": "駕照",
    "simplified": "驾照",
    "reading": "jiàzhào",
    "meaning": "driver's license",
    "grade": 5
  },
  {
    "id": 3592,
    "traditional": "堅定",
    "simplified": "坚定",
    "reading": "jiāndìng",
    "meaning": "stabilizing, firm, steadfast, staunch, strengthen",
    "grade": 5
  },
  {
    "id": 3593,
    "traditional": "艱苦",
    "simplified": "艰苦",
    "reading": "jiānkǔ",
    "meaning": "arduous",
    "grade": 5
  },
  {
    "id": 3594,
    "traditional": "艱難",
    "simplified": "艰难",
    "reading": "jiānnán",
    "meaning": "difficult, hard",
    "grade": 5
  },
  {
    "id": 3595,
    "traditional": "檢驗",
    "simplified": "检验",
    "reading": "jiǎnyàn",
    "meaning": "test, examine, inspect",
    "grade": 5
  },
  {
    "id": 3596,
    "traditional": "減輕",
    "simplified": "减轻",
    "reading": "jiǎnqīng",
    "meaning": "lighten, ease, mitigate",
    "grade": 5
  },
  {
    "id": 3597,
    "traditional": "肩",
    "simplified": "肩",
    "reading": "jiān",
    "meaning": "shoulder",
    "grade": 5
  },
  {
    "id": 3598,
    "traditional": "剪",
    "simplified": "剪",
    "reading": "jiǎn",
    "meaning": "surname Jian, scissors, shears, clippers, CL:把[ba3], to cut with scissors, to trim, to wipe out or exterminate",
    "grade": 5
  },
  {
    "id": 3599,
    "traditional": "剪刀",
    "simplified": "剪刀",
    "reading": "jiǎndāo",
    "meaning": "scissors, CL:把[ba3]",
    "grade": 5
  },
  {
    "id": 3600,
    "traditional": "剪子",
    "simplified": "剪子",
    "reading": "jiǎnzi",
    "meaning": "clippers, scissors, shears, CL:把[ba3]",
    "grade": 5
  },
  {
    "id": 3601,
    "traditional": "間接",
    "simplified": "间接",
    "reading": "jiànjiē",
    "meaning": "collaterally, indirect, collateral, indirectly, roundabout, secondhand",
    "grade": 5
  },
  {
    "id": 3602,
    "traditional": "建造",
    "simplified": "建造",
    "reading": "jiànzào",
    "meaning": "build, construct, make",
    "grade": 5
  },
  {
    "id": 3603,
    "traditional": "健全",
    "simplified": "健全",
    "reading": "jiànquán",
    "meaning": "sound, perfect",
    "grade": 5
  },
  {
    "id": 3604,
    "traditional": "甲",
    "simplified": "甲",
    "reading": "jiǎ",
    "meaning": "det.: first, first of the ten Heavenly Stems",
    "grade": 5
  },
  {
    "id": 3605,
    "traditional": "建築",
    "simplified": "建筑",
    "reading": "jiànzhù",
    "meaning": "build, construct, erect",
    "grade": 5
  },
  {
    "id": 3606,
    "traditional": "鍵盤",
    "simplified": "键盘",
    "reading": "jiànpán",
    "meaning": "keyboard",
    "grade": 5
  },
  {
    "id": 3607,
    "traditional": "鍵",
    "simplified": "键",
    "reading": "jiàn",
    "meaning": "key (on a piano or computer keyboard), button (on a mouse or other device), chemical bond, linchpin",
    "grade": 5
  },
  {
    "id": 3608,
    "traditional": "將",
    "simplified": "将",
    "reading": "jiāng",
    "meaning": "be about to",
    "grade": 5
  },
  {
    "id": 3609,
    "traditional": "獎勵",
    "simplified": "奖励",
    "reading": "jiǎnglì",
    "meaning": "reward, award",
    "grade": 5
  },
  {
    "id": 3610,
    "traditional": "將要",
    "simplified": "将要",
    "reading": "jiāngyào",
    "meaning": "will, shall, to be going to",
    "grade": 5
  },
  {
    "id": 3611,
    "traditional": "交代",
    "simplified": "交代",
    "reading": "jiāodài",
    "meaning": "hand over, explain, make clear, brief, tell, account for, justify oneself, confess",
    "grade": 5
  },
  {
    "id": 3612,
    "traditional": "膠帶",
    "simplified": "胶带",
    "reading": "jiāodài",
    "meaning": "adhesive tape, rubber belt, recording tape",
    "grade": 5
  },
  {
    "id": 3613,
    "traditional": "膠水",
    "simplified": "胶水",
    "reading": "jiāoshuǐ",
    "meaning": "glue",
    "grade": 5
  },
  {
    "id": 3614,
    "traditional": "郊區",
    "simplified": "郊区",
    "reading": "jiāoqū",
    "meaning": "suburban district, outskirts",
    "grade": 5
  },
  {
    "id": 3615,
    "traditional": "腳步",
    "simplified": "脚步",
    "reading": "jiǎobù",
    "meaning": "footfall, tread, pace, foot, footstep, step",
    "grade": 5
  },
  {
    "id": 3616,
    "traditional": "接連",
    "simplified": "接连",
    "reading": "jiēlián",
    "meaning": "on end, in a row, in succession",
    "grade": 5
  },
  {
    "id": 3617,
    "traditional": "解除",
    "simplified": "解除",
    "reading": "jiěchú",
    "meaning": "remove, relieve, get rid of",
    "grade": 5
  },
  {
    "id": 3618,
    "traditional": "接觸",
    "simplified": "接触",
    "reading": "jiēchù",
    "meaning": "come into contact with, get in touch with, engage, contact",
    "grade": 5
  },
  {
    "id": 3619,
    "traditional": "解放",
    "simplified": "解放",
    "reading": "jiěfàng",
    "meaning": "liberate, emancipate",
    "grade": 5
  },
  {
    "id": 3620,
    "traditional": "屆",
    "simplified": "届",
    "reading": "jiè",
    "meaning": "m.[event]",
    "grade": 5
  },
  {
    "id": 3621,
    "traditional": "今日",
    "simplified": "今日",
    "reading": "jīnrì",
    "meaning": "today",
    "grade": 5
  },
  {
    "id": 3622,
    "traditional": "戒",
    "simplified": "戒",
    "reading": "jiè",
    "meaning": "keep off, guard against, drop, keep from, stop, refrain from, desist from, forbear from, abstain from, exhort, avoid, admonition, give up, admonish, exhortation, warn",
    "grade": 5
  },
  {
    "id": 3623,
    "traditional": "緊緊",
    "simplified": "紧紧",
    "reading": "jǐnjǐn",
    "meaning": "closely, tightly",
    "grade": 5
  },
  {
    "id": 3624,
    "traditional": "儘可能",
    "simplified": "尽可能",
    "reading": "jǐnkěnéng",
    "meaning": "as far as possible, to do one's utmost",
    "grade": 5
  },
  {
    "id": 3625,
    "traditional": "儘管",
    "simplified": "尽管",
    "reading": "jǐnguǎn",
    "meaning": "conj.: even though, despite",
    "grade": 5
  },
  {
    "id": 3626,
    "traditional": "進化",
    "simplified": "进化",
    "reading": "jìnhuà",
    "meaning": "evolution, CL:個|个[ge4]",
    "grade": 5
  },
  {
    "id": 3627,
    "traditional": "近來",
    "simplified": "近来",
    "reading": "jìnlái",
    "meaning": "newly, of late, lately, fresh, new, latterly, in recent times, freshly, yesterday, late, recently",
    "grade": 5
  },
  {
    "id": 3628,
    "traditional": "經費",
    "simplified": "经费",
    "reading": "jīngfèi",
    "meaning": "finances, monetary resource, expenses, expense, expenditure, pecuniary resource, fund, outlay, funds, outgo, cash in hand",
    "grade": 5
  },
  {
    "id": 3629,
    "traditional": "景象",
    "simplified": "景象",
    "reading": "jǐngxiàng",
    "meaning": "prospects, spectacle, scene, picture, sight, show",
    "grade": 5
  },
  {
    "id": 3630,
    "traditional": "警告",
    "simplified": "警告",
    "reading": "jǐnggào",
    "meaning": "warn, admonish",
    "grade": 5
  },
  {
    "id": 3631,
    "traditional": "競賽",
    "simplified": "竞赛",
    "reading": "jìngsài",
    "meaning": "contest, competition",
    "grade": 5
  },
  {
    "id": 3632,
    "traditional": "競爭",
    "simplified": "竞争",
    "reading": "jìngzhēng",
    "meaning": "competition",
    "grade": 5
  },
  {
    "id": 3633,
    "traditional": "救災",
    "simplified": "救灾",
    "reading": "jiù zāi",
    "meaning": "to relieve disaster, to help disaster victims",
    "grade": 5
  },
  {
    "id": 3634,
    "traditional": "酒鬼",
    "simplified": "酒鬼",
    "reading": "jiǔguǐ",
    "meaning": "drunkard",
    "grade": 5
  },
  {
    "id": 3635,
    "traditional": "居然",
    "simplified": "居然",
    "reading": "jūrán",
    "meaning": "unexpectedly, to one's surprise, go so far as to",
    "grade": 5
  },
  {
    "id": 3636,
    "traditional": "局面",
    "simplified": "局面",
    "reading": "júmiàn",
    "meaning": "aspect, picture, state of affairs, situation, conjuncture, facet, position, phase",
    "grade": 5
  },
  {
    "id": 3637,
    "traditional": "局長",
    "simplified": "局长",
    "reading": "júzhǎng",
    "meaning": "chief, head of bureau, director (of...), superintendent, commissioner, director",
    "grade": 5
  },
  {
    "id": 3638,
    "traditional": "拒絕",
    "simplified": "拒绝",
    "reading": "jùjué",
    "meaning": "deny, rebut, bar, repel, naysay, scout, abnegate, overrule, reject, decline, disclaim, eliminate, refusal, deduct, turn away, snub, shut, thumb, debar, abjure, override, exclude, recant, turndown, withhold, renunciate, retract, repudiate, forswear, turn_down, turn down, nill, kick, brush_off, repulse, rule out, brush off, recoup, pass up, rebuff, rejection, renounce, refuse, nix, foreclose",
    "grade": 5
  },
  {
    "id": 3639,
    "traditional": "俱樂部",
    "simplified": "俱乐部",
    "reading": "jùlèbù",
    "meaning": "club, (social) club, casino, Verein",
    "grade": 5
  },
  {
    "id": 3640,
    "traditional": "劇本",
    "simplified": "剧本",
    "reading": "jùběn",
    "meaning": "part, playbook, drama, script, play, scenario, libretto",
    "grade": 5
  },
  {
    "id": 3641,
    "traditional": "舉動",
    "simplified": "举动",
    "reading": "jǔdòng",
    "meaning": "behaviour, demeanor, dealing, deportment, motion, proceeding, conduct, behavior, demeanour, movement, comportment, action, move",
    "grade": 5
  },
  {
    "id": 3642,
    "traditional": "絕望",
    "simplified": "绝望",
    "reading": "jué wàng",
    "meaning": "give up all hope, despair",
    "grade": 5
  },
  {
    "id": 3643,
    "traditional": "軍人",
    "simplified": "军人",
    "reading": "jūnrén",
    "meaning": "soldiery, soldier, swordsman, buffcoat, armyman, serviceman, military",
    "grade": 5
  },
  {
    "id": 3644,
    "traditional": "決不",
    "simplified": "决不",
    "reading": "juébù",
    "meaning": "nohow, definitely not, anywise, scarcely, noway, nothing, never, in no way",
    "grade": 5
  },
  {
    "id": 3645,
    "traditional": "開幕",
    "simplified": "开幕",
    "reading": "kāi mù",
    "meaning": "open, inaugurate (meeting/etc.), raise curtain",
    "grade": 5
  },
  {
    "id": 3646,
    "traditional": "開幕式",
    "simplified": "开幕式",
    "reading": "kāimùshì",
    "meaning": "opening ceremony",
    "grade": 5
  },
  {
    "id": 3647,
    "traditional": "看成",
    "simplified": "看成",
    "reading": "kànchéng",
    "meaning": "take for/as, as, look upon .. as, take for, take as, regard ... as",
    "grade": 5
  },
  {
    "id": 3648,
    "traditional": "看待",
    "simplified": "看待",
    "reading": "kàndài",
    "meaning": "apprehend, treatment, treat, look upon, regard",
    "grade": 5
  },
  {
    "id": 3649,
    "traditional": "看出",
    "simplified": "看出",
    "reading": "kàn chū",
    "meaning": "perceive, tell, see, espy, discover, discern, make out, descry",
    "grade": 5
  },
  {
    "id": 3650,
    "traditional": "烤肉",
    "simplified": "烤肉",
    "reading": "kǎoròu",
    "meaning": "barbecue (lit. roast meat)",
    "grade": 5
  },
  {
    "id": 3651,
    "traditional": "考核",
    "simplified": "考核",
    "reading": "kǎohé",
    "meaning": "to examine, to check up on, to assess, to review, appraisal, review, evaluation",
    "grade": 5
  },
  {
    "id": 3652,
    "traditional": "靠近",
    "simplified": "靠近",
    "reading": "kàojìn",
    "meaning": "come near, hug, stand_by, come on, nigh, snuggle, go up, be close to, near, be nearby, approach, draw near, cling, meet",
    "grade": 5
  },
  {
    "id": 3653,
    "traditional": "烤鴨",
    "simplified": "烤鸭",
    "reading": "kǎoyā",
    "meaning": "roast duck",
    "grade": 5
  },
  {
    "id": 3654,
    "traditional": "咳",
    "simplified": "咳",
    "reading": "hāi",
    "meaning": "sound of sighing, cough",
    "grade": 5
  },
  {
    "id": 3655,
    "traditional": "顆",
    "simplified": "颗",
    "reading": "kē",
    "meaning": "grain, m.[general],",
    "grade": 5
  },
  {
    "id": 3656,
    "traditional": "可憐",
    "simplified": "可怜",
    "reading": "kělián",
    "meaning": "condole with, have pity on, sympathize with, abject, compassionate, lugubrious, feel for, pity",
    "grade": 5
  },
  {
    "id": 3657,
    "traditional": "可",
    "simplified": "可",
    "reading": "kě",
    "meaning": "can, may",
    "grade": 5
  },
  {
    "id": 3658,
    "traditional": "可惜",
    "simplified": "可惜",
    "reading": "kěxī",
    "meaning": "it's a pity, unfortunately, it's too bad",
    "grade": 5
  },
  {
    "id": 3659,
    "traditional": "渴望",
    "simplified": "渴望",
    "reading": "kěwàng",
    "meaning": "to thirst for, to long for",
    "grade": 5
  },
  {
    "id": 3660,
    "traditional": "客戶",
    "simplified": "客户",
    "reading": "kèhù",
    "meaning": "clientage, custom, customer, client, business, patronage, clientele",
    "grade": 5
  },
  {
    "id": 3661,
    "traditional": "刻",
    "simplified": "刻",
    "reading": "kè",
    "meaning": "m.[standard]",
    "grade": 5
  },
  {
    "id": 3662,
    "traditional": "客氣",
    "simplified": "客气",
    "reading": "kèqi",
    "meaning": "be polite, stand on ceremony",
    "grade": 5
  },
  {
    "id": 3663,
    "traditional": "課題",
    "simplified": "课题",
    "reading": "kètí",
    "meaning": "task, problem, question for study, subject, topic, question for discussion, discussion, topical, question for study/discussion",
    "grade": 5
  },
  {
    "id": 3664,
    "traditional": "客廳",
    "simplified": "客厅",
    "reading": "kètīng",
    "meaning": "front room, livingroom, parlour, salon, family room, parlor, drawing_room, living_room, drawing room, sitting_room, living room, sitting room, drawing, drawing/living room",
    "grade": 5
  },
  {
    "id": 3665,
    "traditional": "空中",
    "simplified": "空中",
    "reading": "kōngzhōng",
    "meaning": "in_the_air, air, in the sky, sky, aerial, open air/sky, open air, space, open sky, midair",
    "grade": 5
  },
  {
    "id": 3666,
    "traditional": "口號",
    "simplified": "口号",
    "reading": "kǒuhào",
    "meaning": "motto, shibboleth, slogan, watch word, watchcry, catchword, war_cry, cant, watchword, buzzword",
    "grade": 5
  },
  {
    "id": 3667,
    "traditional": "控制",
    "simplified": "控制",
    "reading": "kòngzhì",
    "meaning": "control, dominate, command",
    "grade": 5
  },
  {
    "id": 3668,
    "traditional": "肯定",
    "simplified": "肯定",
    "reading": "kěndìng",
    "meaning": "affirm, approve, regard as positive",
    "grade": 5
  },
  {
    "id": 3669,
    "traditional": "庫",
    "simplified": "库",
    "reading": "kù",
    "meaning": "warehouse, storehouse, (file) library",
    "grade": 5
  },
  {
    "id": 3670,
    "traditional": "快活",
    "simplified": "快活",
    "reading": "kuàihuo",
    "meaning": "merry, cheerful, liven, jaunty, jolly, brighten, happy",
    "grade": 5
  },
  {
    "id": 3671,
    "traditional": "狂",
    "simplified": "狂",
    "reading": "kuáng",
    "meaning": "mad, wild, violent",
    "grade": 5
  },
  {
    "id": 3672,
    "traditional": "寬度",
    "simplified": "宽度",
    "reading": "kuāndù",
    "meaning": "width",
    "grade": 5
  },
  {
    "id": 3673,
    "traditional": "落",
    "simplified": "落",
    "reading": "luò",
    "meaning": "come down, have, remain where one is, go under, leave behind, lower, decline, stay behind, lag behind, dip, precipitate, go down, be missing, fall/leave/stay behind, belong to, set (of sun), drop, ebb (of tide), set, receive, set down, drop behind, light, rest with, obtain, fall, leave/stay behind, fall onto, turn over to, fall behind, sink, get, ebb, land, leave out",
    "grade": 5
  },
  {
    "id": 3674,
    "traditional": "困擾",
    "simplified": "困扰",
    "reading": "kùnrǎo",
    "meaning": "perplexity, puzzle",
    "grade": 5
  },
  {
    "id": 3675,
    "traditional": "虧",
    "simplified": "亏",
    "reading": "kuī",
    "meaning": "deficiency, deficit, luckily, it's lucky that..., (often ironically) fancy that...",
    "grade": 5
  },
  {
    "id": 3676,
    "traditional": "來信",
    "simplified": "来信",
    "reading": "láixìn",
    "meaning": "incoming letter, send a letter here",
    "grade": 5
  },
  {
    "id": 3677,
    "traditional": "爛",
    "simplified": "烂",
    "reading": "làn",
    "meaning": "rot, sodden, deteriorate, fester, mushy, mashed, dissolute, poor, worn-out, decayed, rotten",
    "grade": 5
  },
  {
    "id": 3678,
    "traditional": "朗讀",
    "simplified": "朗读",
    "reading": "lǎngdú",
    "meaning": "to read aloud",
    "grade": 5
  },
  {
    "id": 3679,
    "traditional": "浪漫",
    "simplified": "浪漫",
    "reading": "làngmàn",
    "meaning": "dissolute, debauched, romantic",
    "grade": 5
  },
  {
    "id": 3680,
    "traditional": "梨",
    "simplified": "梨",
    "reading": "lí",
    "meaning": "pear, CL:個|个[ge4]",
    "grade": 5
  },
  {
    "id": 3681,
    "traditional": "勞動",
    "simplified": "劳动",
    "reading": "láodong",
    "meaning": "work, labor",
    "grade": 5
  },
  {
    "id": 3682,
    "traditional": "禮拜",
    "simplified": "礼拜",
    "reading": "lǐbài",
    "meaning": "week",
    "grade": 5
  },
  {
    "id": 3683,
    "traditional": "禮貌",
    "simplified": "礼貌",
    "reading": "lǐmào",
    "meaning": "courtesy, politeness, manners",
    "grade": 5
  },
  {
    "id": 3684,
    "traditional": "禮",
    "simplified": "礼",
    "reading": "lǐ",
    "meaning": "gift, present",
    "grade": 5
  },
  {
    "id": 3685,
    "traditional": "厲害",
    "simplified": "厉害",
    "reading": "lìhai",
    "meaning": "terrible, devastating, tough, sharp",
    "grade": 5
  },
  {
    "id": 3686,
    "traditional": "立場",
    "simplified": "立场",
    "reading": "lìchǎng",
    "meaning": "view, stance, language, stand, ground, capacity, standing, situation, position, perspective, standpoint, stand(point)",
    "grade": 5
  },
  {
    "id": 3687,
    "traditional": "利潤",
    "simplified": "利润",
    "reading": "lìrùn",
    "meaning": "profit",
    "grade": 5
  },
  {
    "id": 3688,
    "traditional": "例外",
    "simplified": "例外",
    "reading": "lìwài",
    "meaning": "excepted",
    "grade": 5
  },
  {
    "id": 3689,
    "traditional": "連接",
    "simplified": "连接",
    "reading": "liánjiē",
    "meaning": "associate, coupled, conjoin, join, cable, interface, tie_in, joint, tie in, couple up, connect, bring together, couple, relate, conjunct, unite, couple_on, tie, couple on, attach, link",
    "grade": 5
  },
  {
    "id": 3690,
    "traditional": "聯想",
    "simplified": "联想",
    "reading": "liánxiǎng",
    "meaning": "abbr. for 聯想集團|联想集团[Lian2 xiang3 Ji2 tuan2], to associate (cognitively), to make an associative connection, mental association, word prediction and auto-complete functions of input method editing programs",
    "grade": 5
  },
  {
    "id": 3691,
    "traditional": "聯絡",
    "simplified": "联络",
    "reading": "liánluò",
    "meaning": "contact",
    "grade": 5
  },
  {
    "id": 3692,
    "traditional": "立",
    "simplified": "立",
    "reading": "lì",
    "meaning": "set up, erect",
    "grade": 5
  },
  {
    "id": 3693,
    "traditional": "臉盆",
    "simplified": "脸盆",
    "reading": "liǎnpén",
    "meaning": "washbowl, basin for washing hands and face, CL:個|个[ge4]",
    "grade": 5
  },
  {
    "id": 3694,
    "traditional": "戀愛",
    "simplified": "恋爱",
    "reading": "liànài",
    "meaning": "love, romantic attachment",
    "grade": 5
  },
  {
    "id": 3695,
    "traditional": "兩岸",
    "simplified": "两岸",
    "reading": "liǎngàn",
    "meaning": "both sides of a river, both sides of the Taiwan Straits (I.e., Chinese Mainland and Taiwan)",
    "grade": 5
  },
  {
    "id": 3696,
    "traditional": "臉色",
    "simplified": "脸色",
    "reading": "liǎnsè",
    "meaning": "brow, countenance, facial_expression, look, facial expression, colouring, complexion",
    "grade": 5
  },
  {
    "id": 3697,
    "traditional": "鈴聲",
    "simplified": "铃声",
    "reading": "língshēng",
    "meaning": "ring, ringtone, bell stroke, tintinnabulation",
    "grade": 5
  },
  {
    "id": 3698,
    "traditional": "鄰居",
    "simplified": "邻居",
    "reading": "línjū",
    "meaning": "neighbor",
    "grade": 5
  },
  {
    "id": 3699,
    "traditional": "領帶",
    "simplified": "领带",
    "reading": "lǐngdài",
    "meaning": "necktie, CL:條|条[tiao2]",
    "grade": 5
  },
  {
    "id": 3700,
    "traditional": "令",
    "simplified": "令",
    "reading": "lìng",
    "meaning": "make, cause",
    "grade": 5
  },
  {
    "id": 3701,
    "traditional": "流動",
    "simplified": "流动",
    "reading": "liúdòng",
    "meaning": "flow, go from place to place",
    "grade": 5
  },
  {
    "id": 3702,
    "traditional": "鈴",
    "simplified": "铃",
    "reading": "líng",
    "meaning": "(small) bell, CL:隻|只[zhi1]",
    "grade": 5
  },
  {
    "id": 3703,
    "traditional": "流通",
    "simplified": "流通",
    "reading": "liútōng",
    "meaning": "circulate",
    "grade": 5
  },
  {
    "id": 3704,
    "traditional": "漏洞",
    "simplified": "漏洞",
    "reading": "lòudòng",
    "meaning": "leak, flow, hole, loophole",
    "grade": 5
  },
  {
    "id": 3705,
    "traditional": "漏",
    "simplified": "漏",
    "reading": "lòu",
    "meaning": "to leak, to divulge, to leave out by mistake, waterclock or hourglass (old)",
    "grade": 5
  },
  {
    "id": 3706,
    "traditional": "邏輯",
    "simplified": "逻辑",
    "reading": "luóji",
    "meaning": "logic",
    "grade": 5
  },
  {
    "id": 3707,
    "traditional": "落實",
    "simplified": "落实",
    "reading": "luòshí",
    "meaning": "fix/decide in advance, ascertain, make sure, fulfill, implement, put into effect",
    "grade": 5
  },
  {
    "id": 3708,
    "traditional": "碼頭",
    "simplified": "码头",
    "reading": "mǎtou",
    "meaning": "commercial/transportation center, port city, landing, pier, marina, commercial center, quay, wharf, levee, landing place, jetty, commercial, stage, commercial and transportation center, transportation center, wharfage, staith, dock",
    "grade": 5
  },
  {
    "id": 3709,
    "traditional": "買賣",
    "simplified": "买卖",
    "reading": "mǎimai",
    "meaning": "buying and selling, business deal/transaction, (small) merchant enterprise",
    "grade": 5
  },
  {
    "id": 3710,
    "traditional": "漫長",
    "simplified": "漫长",
    "reading": "màncháng",
    "meaning": "very long, endless",
    "grade": 5
  },
  {
    "id": 3711,
    "traditional": "毛筆",
    "simplified": "毛笔",
    "reading": "máobǐ",
    "meaning": "writing brush, CL:枝[zhi1],管[guan3]",
    "grade": 5
  },
  {
    "id": 3712,
    "traditional": "漫畫",
    "simplified": "漫画",
    "reading": "mànhuà",
    "meaning": "takeoff, cartoon, caricature",
    "grade": 5
  },
  {
    "id": 3713,
    "traditional": "冒",
    "simplified": "冒",
    "reading": "mào",
    "meaning": "risk, brave",
    "grade": 5
  },
  {
    "id": 3714,
    "traditional": "矛盾",
    "simplified": "矛盾",
    "reading": "máodùn",
    "meaning": "contradictory",
    "grade": 5
  },
  {
    "id": 3715,
    "traditional": "罵",
    "simplified": "骂",
    "reading": "mà",
    "meaning": "chew_out, vituperate, dispraise, condemnation, condemn, baste, scold, rate, curse, tear_apart, chide, call names, abuse, reprove, rebuke, fling, thunder, reproof, swear",
    "grade": 5
  },
  {
    "id": 3716,
    "traditional": "貿易",
    "simplified": "贸易",
    "reading": "màoyì",
    "meaning": "deal, carriage trade, trade, business, commerce",
    "grade": 5
  },
  {
    "id": 3717,
    "traditional": "煤",
    "simplified": "煤",
    "reading": "méi",
    "meaning": "firing, coal",
    "grade": 5
  },
  {
    "id": 3718,
    "traditional": "煤氣",
    "simplified": "煤气",
    "reading": "méiqì",
    "meaning": "coal gas, gas (fuel)",
    "grade": 5
  },
  {
    "id": 3719,
    "traditional": "門診",
    "simplified": "门诊",
    "reading": "ménzhěn",
    "meaning": "outpatient service",
    "grade": 5
  },
  {
    "id": 3720,
    "traditional": "迷信",
    "simplified": "迷信",
    "reading": "míxìn",
    "meaning": "have blind faith in, make fetish of",
    "grade": 5
  },
  {
    "id": 3721,
    "traditional": "面貌",
    "simplified": "面貌",
    "reading": "miànmào",
    "meaning": "aspect, face, physiognomy, look, vestige, visage, appearance, features",
    "grade": 5
  },
  {
    "id": 3722,
    "traditional": "迷人",
    "simplified": "迷人",
    "reading": "mírén",
    "meaning": "beguile, enamor, charm, becharm, enchanting, charming, enwrap, attractive",
    "grade": 5
  },
  {
    "id": 3723,
    "traditional": "秒",
    "simplified": "秒",
    "reading": "miǎo",
    "meaning": "chronograph, m.[standard], ,s, sec, second",
    "grade": 5
  },
  {
    "id": 3724,
    "traditional": "敏感",
    "simplified": "敏感",
    "reading": "mǐngǎn",
    "meaning": "subtle, susceptible, vulnerable, be sensitive, sensitive",
    "grade": 5
  },
  {
    "id": 3725,
    "traditional": "明亮",
    "simplified": "明亮",
    "reading": "míngliàng",
    "meaning": "brighten, well-lit, shining, bright",
    "grade": 5
  },
  {
    "id": 3726,
    "traditional": "面子",
    "simplified": "面子",
    "reading": "miànzi",
    "meaning": "reputation, face, prestige",
    "grade": 5
  },
  {
    "id": 3727,
    "traditional": "明明",
    "simplified": "明明",
    "reading": "míngmíng",
    "meaning": "obviously, plainly",
    "grade": 5
  },
  {
    "id": 3728,
    "traditional": "命令",
    "simplified": "命令",
    "reading": "mìnglìng",
    "meaning": "commend, order",
    "grade": 5
  },
  {
    "id": 3729,
    "traditional": "模仿",
    "simplified": "模仿",
    "reading": "mófǎng",
    "meaning": "imitate, follow example of",
    "grade": 5
  },
  {
    "id": 3730,
    "traditional": "模範",
    "simplified": "模范",
    "reading": "mófàn",
    "meaning": "model, fine example",
    "grade": 5
  },
  {
    "id": 3731,
    "traditional": "模糊",
    "simplified": "模糊",
    "reading": "móhu",
    "meaning": "dim, fade, befog, blur, mix up, slur, fade out, blear, confuse, obscure",
    "grade": 5
  },
  {
    "id": 3732,
    "traditional": "模式",
    "simplified": "模式",
    "reading": "móshì",
    "meaning": "example, normal, rule, formula, pattern, form, shape, style, way, mode, convention, fashion, model",
    "grade": 5
  },
  {
    "id": 3733,
    "traditional": "摩擦",
    "simplified": "摩擦",
    "reading": "mócā",
    "meaning": "friction, rubbing, chafing, fig. disharmony, conflict, also written 磨擦",
    "grade": 5
  },
  {
    "id": 3734,
    "traditional": "摩託",
    "simplified": "摩托",
    "reading": "mótuō",
    "meaning": "motor (loanword), motorbike",
    "grade": 5
  },
  {
    "id": 3735,
    "traditional": "模樣",
    "simplified": "模样",
    "reading": "múyàng",
    "meaning": "sign, air, aspect, form, look, front, appearance, shape, suggestion",
    "grade": 5
  },
  {
    "id": 3736,
    "traditional": "目光",
    "simplified": "目光",
    "reading": "mùguāng",
    "meaning": "sight, vision, view, gaze, look",
    "grade": 5
  },
  {
    "id": 3737,
    "traditional": "男性",
    "simplified": "男性",
    "reading": "nánxìng",
    "meaning": "brother, male, him, man, male sex, adult male, mankind, masculine, mother's son, masculinity",
    "grade": 5
  },
  {
    "id": 3738,
    "traditional": "南北",
    "simplified": "南北",
    "reading": "nánběi",
    "meaning": "north and south, from east to west, north-south",
    "grade": 5
  },
  {
    "id": 3739,
    "traditional": "耐心",
    "simplified": "耐心",
    "reading": "nàixīn",
    "meaning": "nerves, forbearance, longanimity, endurance, patience",
    "grade": 5
  },
  {
    "id": 3740,
    "traditional": "南極",
    "simplified": "南极",
    "reading": "nánjí",
    "meaning": "south pole",
    "grade": 5
  },
  {
    "id": 3741,
    "traditional": "難得",
    "simplified": "难得",
    "reading": "nándé",
    "meaning": "hard to come by, rare, seldom",
    "grade": 5
  },
  {
    "id": 3742,
    "traditional": "難以",
    "simplified": "难以",
    "reading": "nányǐ",
    "meaning": "be difficult to",
    "grade": 5
  },
  {
    "id": 3743,
    "traditional": "能量",
    "simplified": "能量",
    "reading": "néngliàng",
    "meaning": "energy, capabilities",
    "grade": 5
  },
  {
    "id": 3744,
    "traditional": "內在",
    "simplified": "内在",
    "reading": "nèizài",
    "meaning": "inhesion, intrinsic, internality, inherent, internal, immanence, inherence",
    "grade": 5
  },
  {
    "id": 3745,
    "traditional": "年齡",
    "simplified": "年龄",
    "reading": "niánlíng",
    "meaning": "summer, age",
    "grade": 5
  },
  {
    "id": 3746,
    "traditional": "年度",
    "simplified": "年度",
    "reading": "niándù",
    "meaning": "(school/etc.) year",
    "grade": 5
  },
  {
    "id": 3747,
    "traditional": "年前",
    "simplified": "年前",
    "reading": "niánqián",
    "meaning": "...years ago",
    "grade": 5
  },
  {
    "id": 3748,
    "traditional": "腦子",
    "simplified": "脑子",
    "reading": "nǎozi",
    "meaning": "brain, mind, head",
    "grade": 5
  },
  {
    "id": 3749,
    "traditional": "牛仔褲",
    "simplified": "牛仔裤",
    "reading": "niúzǎikù",
    "meaning": "jeans, CL:條|条[tiao2]",
    "grade": 5
  },
  {
    "id": 3750,
    "traditional": "牛",
    "simplified": "牛",
    "reading": "niú",
    "meaning": "ox, cattle, cow",
    "grade": 5
  },
  {
    "id": 3751,
    "traditional": "農產品",
    "simplified": "农产品",
    "reading": "nóngchǎnpǐn",
    "meaning": "agricultural products, farm produce",
    "grade": 5
  },
  {
    "id": 3752,
    "traditional": "暖",
    "simplified": "暖",
    "reading": "nuǎn",
    "meaning": "warm, to warm, variant of 暖[nuan3], warm",
    "grade": 5
  },
  {
    "id": 3753,
    "traditional": "女性",
    "simplified": "女性",
    "reading": "nǚxìng",
    "meaning": "lady, female sex, womankind, feminie, female, sex, petticoat, femininity, female person, woman, she, patroness, womanhood, fair, feminine",
    "grade": 5
  },
  {
    "id": 3754,
    "traditional": "偶然",
    "simplified": "偶然",
    "reading": "ǒurán",
    "meaning": "accidentally, occasionally",
    "grade": 5
  },
  {
    "id": 3755,
    "traditional": "偶像",
    "simplified": "偶像",
    "reading": "ǒuxiàng",
    "meaning": "mammet, god, Baal, hoodoo, icon, idol, image, effigy, fetich, fetish, model, graven image, matinee idol, swami, iconology, voodoo, joss, obeah, pagod, juju",
    "grade": 5
  },
  {
    "id": 3756,
    "traditional": "偶爾",
    "simplified": "偶尔",
    "reading": "ǒuěr",
    "meaning": "once in a blue moon, from time to time, hardly ever, every so often, now and again, seldom, infrequently, on occasion, rarely, betweenwhiles, scarcely, unusually, occasionally, sporadically, every now and then, at times, now and then, once in a while",
    "grade": 5
  },
  {
    "id": 3757,
    "traditional": "排除",
    "simplified": "排除",
    "reading": "páichú",
    "meaning": "get rid of, remove, eliminate",
    "grade": 5
  },
  {
    "id": 3758,
    "traditional": "拍攝",
    "simplified": "拍摄",
    "reading": "pāishè",
    "meaning": "take (picture), shoot (film)",
    "grade": 5
  },
  {
    "id": 3759,
    "traditional": "陪",
    "simplified": "陪",
    "reading": "péi",
    "meaning": "companion, see, escort, accompany, keep ... company, keep sb. company, assist, keep company, compensate, company",
    "grade": 5
  },
  {
    "id": 3760,
    "traditional": "賠",
    "simplified": "赔",
    "reading": "péi",
    "meaning": "to compensate for loss, to indemnify, to suffer a financial loss",
    "grade": 5
  },
  {
    "id": 3761,
    "traditional": "賠償",
    "simplified": "赔偿",
    "reading": "péicháng",
    "meaning": "compensate, pay for",
    "grade": 5
  },
  {
    "id": 3762,
    "traditional": "配備",
    "simplified": "配备",
    "reading": "pèibèi",
    "meaning": "equipment",
    "grade": 5
  },
  {
    "id": 3763,
    "traditional": "旁",
    "simplified": "旁",
    "reading": "páng",
    "meaning": "side, by the side of, other",
    "grade": 5
  },
  {
    "id": 3764,
    "traditional": "配套",
    "simplified": "配套",
    "reading": "pèi tào",
    "meaning": "to form a complete set, coherent",
    "grade": 5
  },
  {
    "id": 3765,
    "traditional": "噴",
    "simplified": "喷",
    "reading": "pēn",
    "meaning": "splosh, spray, sprinkle, spout, puff, gush, splash, spurt",
    "grade": 5
  },
  {
    "id": 3766,
    "traditional": "盆",
    "simplified": "盆",
    "reading": "pén",
    "meaning": "basin, flower pot, unit of volume equal to 12 斗 and 8 升, approx 128 liters, CL:個|个[ge4]",
    "grade": 5
  },
  {
    "id": 3767,
    "traditional": "披",
    "simplified": "披",
    "reading": "pī",
    "meaning": "to drape over one's shoulders, to open, to unroll, to split open, to spread out",
    "grade": 5
  },
  {
    "id": 3768,
    "traditional": "皮膚",
    "simplified": "皮肤",
    "reading": "pífū",
    "meaning": "leather, chap, integument, hide, cutis, complexion, brunet, cutaneous, bark, fell, derma, brunette, skin, transplant",
    "grade": 5
  },
  {
    "id": 3769,
    "traditional": "脾氣",
    "simplified": "脾气",
    "reading": "píqi",
    "meaning": "kidney, dispose, irritation, surliness, behavior, temper, pique, habit, mould, irritability, bile, bad temper, biliousness, temperament, characteristic, dander, pettishness, disposition, stubborn disposition, blood, snappishness, proclivity, peevishness, grain",
    "grade": 5
  },
  {
    "id": 3770,
    "traditional": "皮鞋",
    "simplified": "皮鞋",
    "reading": "píxié",
    "meaning": "leather shoes",
    "grade": 5
  },
  {
    "id": 3771,
    "traditional": "騙",
    "simplified": "骗",
    "reading": "piàn",
    "meaning": "chisel, cozen, palter, green, cheat, slang, put one over, defraud, befool, trick, jockey, victimize, outjockey, take in, leap on a horse, gum, put on, pigeon, humbug, betray, fob, flimflam, dupe, gyp, illude, mulct, deceit, put one across, rook, gull, bunco, hoodwink, beguile, diddle, fox, bilk, swindle, cod, fool, do, nobble, sting, deceive, con",
    "grade": 5
  },
  {
    "id": 3772,
    "traditional": "騙子",
    "simplified": "骗子",
    "reading": "piànzi",
    "meaning": "swindler, a cheat",
    "grade": 5
  },
  {
    "id": 3773,
    "traditional": "頻道",
    "simplified": "频道",
    "reading": "píndào",
    "meaning": "channel, transmission channel, frequency channel",
    "grade": 5
  },
  {
    "id": 3774,
    "traditional": "拼",
    "simplified": "拼",
    "reading": "pīn",
    "meaning": "to piece together, to join together, to stake all, adventurous, at the risk of one's life, to spell",
    "grade": 5
  },
  {
    "id": 3775,
    "traditional": "頻繁",
    "simplified": "频繁",
    "reading": "pínfán",
    "meaning": "frequent, often",
    "grade": 5
  },
  {
    "id": 3776,
    "traditional": "品",
    "simplified": "品",
    "reading": "pǐn",
    "meaning": "article, commodity, product, goods, kind, grade, rank, character, disposition, nature, temperament, variety, to taste sth, to sample, to criticize, to comment, to judge, to size up",
    "grade": 5
  },
  {
    "id": 3777,
    "traditional": "品種",
    "simplified": "品种",
    "reading": "pǐnzhǒng",
    "meaning": "breed, variety, CL:個|个[ge4]",
    "grade": 5
  },
  {
    "id": 3778,
    "traditional": "匹",
    "simplified": "匹",
    "reading": "pǐ",
    "meaning": "m.[general]",
    "grade": 5
  },
  {
    "id": 3779,
    "traditional": "平坦",
    "simplified": "平坦",
    "reading": "píngtǎn",
    "meaning": "level, even, smooth, flat",
    "grade": 5
  },
  {
    "id": 3780,
    "traditional": "平原",
    "simplified": "平原",
    "reading": "píngyuán",
    "meaning": "champaign, plain, campagna, field, park, flatlands",
    "grade": 5
  },
  {
    "id": 3781,
    "traditional": "評論",
    "simplified": "评论",
    "reading": "pínglùn",
    "meaning": "comment, commentary, review",
    "grade": 5
  },
  {
    "id": 3782,
    "traditional": "評估",
    "simplified": "评估",
    "reading": "pínggū",
    "meaning": "estimate, evaluate",
    "grade": 5
  },
  {
    "id": 3783,
    "traditional": "憑",
    "simplified": "凭",
    "reading": "píng",
    "meaning": "based on, according to",
    "grade": 5
  },
  {
    "id": 3784,
    "traditional": "葡萄",
    "simplified": "葡萄",
    "reading": "pútao",
    "meaning": "grape",
    "grade": 5
  },
  {
    "id": 3785,
    "traditional": "潑",
    "simplified": "泼",
    "reading": "pō",
    "meaning": "to splash, to spill, rough and coarse, brutish",
    "grade": 5
  },
  {
    "id": 3786,
    "traditional": "葡萄酒",
    "simplified": "葡萄酒",
    "reading": "pútaojiǔ",
    "meaning": "(grape) wine",
    "grade": 5
  },
  {
    "id": 3787,
    "traditional": "其",
    "simplified": "其",
    "reading": "qí",
    "meaning": "det.: its, his, her, their, it, this, that",
    "grade": 5
  },
  {
    "id": 3788,
    "traditional": "啟動",
    "simplified": "启动",
    "reading": "qǐdòng",
    "meaning": "start",
    "grade": 5
  },
  {
    "id": 3789,
    "traditional": "齊全",
    "simplified": "齐全",
    "reading": "qíquán",
    "meaning": "all in readiness, assort, complete",
    "grade": 5
  },
  {
    "id": 3790,
    "traditional": "啟發",
    "simplified": "启发",
    "reading": "qǐfā",
    "meaning": "enlighten, stimulate",
    "grade": 5
  },
  {
    "id": 3791,
    "traditional": "啟事",
    "simplified": "启事",
    "reading": "qǐshì",
    "meaning": "announcement (written, on billboard, letter, newspaper or website), to post information, a notice",
    "grade": 5
  },
  {
    "id": 3792,
    "traditional": "起到",
    "simplified": "起到",
    "reading": "qǐdào",
    "meaning": "Play",
    "grade": 5
  },
  {
    "id": 3793,
    "traditional": "期望",
    "simplified": "期望",
    "reading": "qīwàng",
    "meaning": "expectation, hope",
    "grade": 5
  },
  {
    "id": 3794,
    "traditional": "氣象",
    "simplified": "气象",
    "reading": "qìxiàng",
    "meaning": "meteorologic, advection, isotherm, meteorology, cirrocumulus, meteorological, altostratus, atmosphere, prevailing spirit/atmosphere, scene, nimbus, prevailing atmosphere, climatic phenomenon, cirrostratus, cumulonimbus, prevailing spirit, meteorological phenomena, altocumulus",
    "grade": 5
  },
  {
    "id": 3795,
    "traditional": "簽訂",
    "simplified": "签订",
    "reading": "qiāndìng",
    "meaning": "conclude and sign (treaty/contract/etc.)",
    "grade": 5
  },
  {
    "id": 3796,
    "traditional": "簽",
    "simplified": "签",
    "reading": "qiān",
    "meaning": "sign, autograph",
    "grade": 5
  },
  {
    "id": 3797,
    "traditional": "起碼",
    "simplified": "起码",
    "reading": "qǐmǎ",
    "meaning": "at least",
    "grade": 5
  },
  {
    "id": 3798,
    "traditional": "簽名",
    "simplified": "签名",
    "reading": "qiānmíng",
    "meaning": "sign one's name, autograph",
    "grade": 5
  },
  {
    "id": 3799,
    "traditional": "簽證",
    "simplified": "签证",
    "reading": "qiānzhèng",
    "meaning": "visa",
    "grade": 5
  },
  {
    "id": 3800,
    "traditional": "簽約",
    "simplified": "签约",
    "reading": "qiān yuē",
    "meaning": "sign a contract",
    "grade": 5
  },
  {
    "id": 3801,
    "traditional": "簽字",
    "simplified": "签字",
    "reading": "qiān zì",
    "meaning": "to sign (one's name), signature",
    "grade": 5
  },
  {
    "id": 3802,
    "traditional": "前景",
    "simplified": "前景",
    "reading": "qiánjǐng",
    "meaning": "outlook, perspective, prospect, expectation, vista, foreground",
    "grade": 5
  },
  {
    "id": 3803,
    "traditional": "氣體",
    "simplified": "气体",
    "reading": "qìtǐ",
    "meaning": "exhaust, fume, gaseous, gas, exhaust fumes, pneumatic, gaseity, mofette, fumes",
    "grade": 5
  },
  {
    "id": 3804,
    "traditional": "欠",
    "simplified": "欠",
    "reading": "qiàn",
    "meaning": "deficient, to owe, to lack, yawn",
    "grade": 5
  },
  {
    "id": 3805,
    "traditional": "槍",
    "simplified": "枪",
    "reading": "qiāng",
    "meaning": "rifle, gun",
    "grade": 5
  },
  {
    "id": 3806,
    "traditional": "前提",
    "simplified": "前提",
    "reading": "qiántí",
    "meaning": "precondition, prerequisite, reason, premiss, antecedent, stipulation, premise, presupposition, hypothesis, induction, condition",
    "grade": 5
  },
  {
    "id": 3807,
    "traditional": "強度",
    "simplified": "强度",
    "reading": "qiángdù",
    "meaning": "strength, intension, kick, degree, emphasis, intensity, depth, intensiveness",
    "grade": 5
  },
  {
    "id": 3808,
    "traditional": "牆壁",
    "simplified": "墙壁",
    "reading": "qiángbì",
    "meaning": "wall",
    "grade": 5
  },
  {
    "id": 3809,
    "traditional": "搶救",
    "simplified": "抢救",
    "reading": "qiǎngjiù",
    "meaning": "rush to save",
    "grade": 5
  },
  {
    "id": 3810,
    "traditional": "強迫",
    "simplified": "强迫",
    "reading": "qiǎngpò",
    "meaning": "require, oust, press-gang, impose, obtrude, coerce, sandbag, coercion, force (sb. to do sth.), enforce, pressure, compel, force, compulsory, push out, hustle, constrain, blackmail, strain, starve, oblige, thrust out, thrust, bear, impel, bulldoze, dragoon, force-feed, blackjack, high-pressure",
    "grade": 5
  },
  {
    "id": 3811,
    "traditional": "悄悄",
    "simplified": "悄悄",
    "reading": "qiāoqiāo",
    "meaning": "silently, stealthily, quietly",
    "grade": 5
  },
  {
    "id": 3812,
    "traditional": "搶",
    "simplified": "抢",
    "reading": "qiǎng",
    "meaning": "pillage, loot, vie for (work/etc.)",
    "grade": 5
  },
  {
    "id": 3813,
    "traditional": "瞧",
    "simplified": "瞧",
    "reading": "qiáo",
    "meaning": "look at, lay eyes on, behold, see, look, glaum, regard",
    "grade": 5
  },
  {
    "id": 3814,
    "traditional": "敲",
    "simplified": "敲",
    "reading": "qiāo",
    "meaning": "to hit, to strike, to tap, to rap, to knock, to rip sb off, to overcharge",
    "grade": 5
  },
  {
    "id": 3815,
    "traditional": "勤奮",
    "simplified": "勤奋",
    "reading": "qínfèn",
    "meaning": "hardworking, diligent",
    "grade": 5
  },
  {
    "id": 3816,
    "traditional": "琴",
    "simplified": "琴",
    "reading": "qín",
    "meaning": "qin, a seven-stringed plucked instrument, general name for stringed instruments, zither-like instrument, general name for certain musical instruments",
    "grade": 5
  },
  {
    "id": 3817,
    "traditional": "青",
    "simplified": "青",
    "reading": "qīng",
    "meaning": "nature's color, green, blue, greenish black",
    "grade": 5
  },
  {
    "id": 3818,
    "traditional": "敲門",
    "simplified": "敲门",
    "reading": "qiāo mén",
    "meaning": "to knock on a door",
    "grade": 5
  },
  {
    "id": 3819,
    "traditional": "清理",
    "simplified": "清理",
    "reading": "qīnglǐ",
    "meaning": "settle (accounts/etc.), sort out affairs",
    "grade": 5
  },
  {
    "id": 3820,
    "traditional": "清晨",
    "simplified": "清晨",
    "reading": "qīngchén",
    "meaning": "early morning",
    "grade": 5
  },
  {
    "id": 3821,
    "traditional": "區域",
    "simplified": "区域",
    "reading": "qūyù",
    "meaning": "area, region, district",
    "grade": 5
  },
  {
    "id": 3822,
    "traditional": "晴朗",
    "simplified": "晴朗",
    "reading": "qínglǎng",
    "meaning": "sunny and cloudless",
    "grade": 5
  },
  {
    "id": 3823,
    "traditional": "全都",
    "simplified": "全都",
    "reading": "quándōu",
    "meaning": "all, without exception",
    "grade": 5
  },
  {
    "id": 3824,
    "traditional": "情節",
    "simplified": "情节",
    "reading": "qíngjié",
    "meaning": "plot, verisimilitude, buildup, scenario, literary argument, argument, circumstances, story, clue, intrigue, details, action",
    "grade": 5
  },
  {
    "id": 3825,
    "traditional": "全世界",
    "simplified": "全世界",
    "reading": "quánshìjiè",
    "meaning": "worldwide, entire world",
    "grade": 5
  },
  {
    "id": 3826,
    "traditional": "情形",
    "simplified": "情形",
    "reading": "qíngxing",
    "meaning": "circumstance, trimness, event, trim, instance, state of affairs, case, circumstances, status, situation, condition",
    "grade": 5
  },
  {
    "id": 3827,
    "traditional": "勸",
    "simplified": "劝",
    "reading": "quàn",
    "meaning": "encourage, urge, talk, advise, advice, persuade, prevail, encouragement, try to persuade, exhort",
    "grade": 5
  },
  {
    "id": 3828,
    "traditional": "泉",
    "simplified": "泉",
    "reading": "quán",
    "meaning": "spring (small stream), mouth of a spring, coin (archaic)",
    "grade": 5
  },
  {
    "id": 3829,
    "traditional": "缺乏",
    "simplified": "缺乏",
    "reading": "quēfá",
    "meaning": "be short of, lack",
    "grade": 5
  },
  {
    "id": 3830,
    "traditional": "確立",
    "simplified": "确立",
    "reading": "quèlì",
    "meaning": "radicate, entrench, establishment, build, establish, establish firmly",
    "grade": 5
  },
  {
    "id": 3831,
    "traditional": "群眾",
    "simplified": "群众",
    "reading": "qúnzhòng",
    "meaning": "swarm, posse, herd, huddle, ruck, concourse, crowd, varletry, the masses/People, lineup, throng, the masses, mob, multitude, press, mobility, clamjamfry, People, crush, jam, horde, the People, confluence, army, flock, mass",
    "grade": 5
  },
  {
    "id": 3832,
    "traditional": "群體",
    "simplified": "群体",
    "reading": "qúntǐ",
    "meaning": "population, community, society, grouping, group, colony, groups",
    "grade": 5
  },
  {
    "id": 3833,
    "traditional": "熱量",
    "simplified": "热量",
    "reading": "rèliàng",
    "meaning": "heat, quantity of heat, calorific value",
    "grade": 5
  },
  {
    "id": 3834,
    "traditional": "染",
    "simplified": "染",
    "reading": "rǎn",
    "meaning": "to dye, to catch (a disease), to acquire (bad habits etc), to contaminate, to add color washes to a painting",
    "grade": 5
  },
  {
    "id": 3835,
    "traditional": "熱門",
    "simplified": "热门",
    "reading": "rèmén",
    "meaning": "favorite, in great demand, popular",
    "grade": 5
  },
  {
    "id": 3836,
    "traditional": "人間",
    "simplified": "人间",
    "reading": "rénjiān",
    "meaning": "the human world",
    "grade": 5
  },
  {
    "id": 3837,
    "traditional": "人士",
    "simplified": "人士",
    "reading": "rénshì",
    "meaning": "public figure, personage",
    "grade": 5
  },
  {
    "id": 3838,
    "traditional": "人物",
    "simplified": "人物",
    "reading": "rénwù",
    "meaning": "character, persona, original, spirit, figure painting, character in literature, figure, person in literature, person, fish, personage, personality",
    "grade": 5
  },
  {
    "id": 3839,
    "traditional": "忍",
    "simplified": "忍",
    "reading": "rěn",
    "meaning": "forbear, endure, put up with, be hardhearted enough to, hold back, bear, bide, toleration, tolerate, have the heart to, tolerance",
    "grade": 5
  },
  {
    "id": 3840,
    "traditional": "人力",
    "simplified": "人力",
    "reading": "rénlì",
    "meaning": "strength, manpower, resources, manual labor",
    "grade": 5
  },
  {
    "id": 3841,
    "traditional": "忍不住",
    "simplified": "忍不住",
    "reading": "rěnbuzhù",
    "meaning": "unable to endure, can't help but do something, can't help but do sth., cannot help, unable to bear, can't bear",
    "grade": 5
  },
  {
    "id": 3842,
    "traditional": "繞",
    "simplified": "绕",
    "reading": "rào",
    "meaning": "circle, revolution, coil, wind, revolve around sth., entwine, weave, baffle, go around, confuse, go round, thread, move round, revolve, confusion, meander, make a detour, bypass, reel, be fuddled, detour, become entangled",
    "grade": 5
  },
  {
    "id": 3843,
    "traditional": "認",
    "simplified": "认",
    "reading": "rèn",
    "meaning": "to recognize, to know, to admit",
    "grade": 5
  },
  {
    "id": 3844,
    "traditional": "認定",
    "simplified": "认定",
    "reading": "rèndìng",
    "meaning": "firmly believe, set one's mind on sth.",
    "grade": 5
  },
  {
    "id": 3845,
    "traditional": "忍受",
    "simplified": "忍受",
    "reading": "rěnshòu",
    "meaning": "forbear, hack, dree, stomach, abide, hurt, aby, hold still for, stick, brave out, live on, ache, endure, cut, swallow, suffer, brave, brook, bide, thole, digest, receive, stand, absorb, go, put up, hold out, live, hold up, sustain, abye, support, stand_for, bear, stand for, survive, last, tolerate, weather",
    "grade": 5
  },
  {
    "id": 3846,
    "traditional": "扔",
    "simplified": "扔",
    "reading": "rēng",
    "meaning": "to throw, to throw away",
    "grade": 5
  },
  {
    "id": 3847,
    "traditional": "如此",
    "simplified": "如此",
    "reading": "rúcǐ",
    "meaning": "thus, like this, such",
    "grade": 5
  },
  {
    "id": 3848,
    "traditional": "仍舊",
    "simplified": "仍旧",
    "reading": "réngjiù",
    "meaning": "still, as before",
    "grade": 5
  },
  {
    "id": 3849,
    "traditional": "入門",
    "simplified": "入门",
    "reading": "rùmén",
    "meaning": "entrance door, to enter a door, introduction (to a subject)",
    "grade": 5
  },
  {
    "id": 3850,
    "traditional": "如同",
    "simplified": "如同",
    "reading": "rútóng",
    "meaning": "like, similar to",
    "grade": 5
  },
  {
    "id": 3851,
    "traditional": "軟件",
    "simplified": "软件",
    "reading": "ruǎnjiàn",
    "meaning": "(computer) software",
    "grade": 5
  },
  {
    "id": 3852,
    "traditional": "如下",
    "simplified": "如下",
    "reading": "rúxià",
    "meaning": "as follows",
    "grade": 5
  },
  {
    "id": 3853,
    "traditional": "軟",
    "simplified": "软",
    "reading": "ruǎn",
    "meaning": "soft, weak, pliant, poor in quality",
    "grade": 5
  },
  {
    "id": 3854,
    "traditional": "散",
    "simplified": "散",
    "reading": "sàn",
    "meaning": "break up",
    "grade": 5
  },
  {
    "id": 3855,
    "traditional": "灑",
    "simplified": "洒",
    "reading": "sǎ",
    "meaning": "to sprinkle, to spray, to spill, to shed",
    "grade": 5
  },
  {
    "id": 3856,
    "traditional": "殺毒",
    "simplified": "杀毒",
    "reading": "shā dú",
    "meaning": "Antivirus",
    "grade": 5
  },
  {
    "id": 3857,
    "traditional": "殺",
    "simplified": "杀",
    "reading": "shā",
    "meaning": "abatement, fight at close quarters, killing, go into battle, burn, reduction, slaughter, burn (of medicine), fight, smite, abate, massacre, diddle, reduce, weaken, liquidate, smash, kill, slay, smart",
    "grade": 5
  },
  {
    "id": 3858,
    "traditional": "沙漠",
    "simplified": "沙漠",
    "reading": "shāmò",
    "meaning": "desert",
    "grade": 5
  },
  {
    "id": 3859,
    "traditional": "散文",
    "simplified": "散文",
    "reading": "sǎnwén",
    "meaning": "prose",
    "grade": 5
  },
  {
    "id": 3860,
    "traditional": "山區",
    "simplified": "山区",
    "reading": "shānqū",
    "meaning": "mountain area",
    "grade": 5
  },
  {
    "id": 3861,
    "traditional": "傻",
    "simplified": "傻",
    "reading": "shǎ",
    "meaning": "stupid, muddleheaded, stunned, silly",
    "grade": 5
  },
  {
    "id": 3862,
    "traditional": "扇",
    "simplified": "扇",
    "reading": "shàn",
    "meaning": "m.[general]",
    "grade": 5
  },
  {
    "id": 3863,
    "traditional": "扇子",
    "simplified": "扇子",
    "reading": "shànzi",
    "meaning": "fan, CL:把[ba3]",
    "grade": 5
  },
  {
    "id": 3864,
    "traditional": "商標",
    "simplified": "商标",
    "reading": "shāngbiāo",
    "meaning": "marque, mark, trade name, brand, logotype, monotype, nameplate, label, idiograph, trademark",
    "grade": 5
  },
  {
    "id": 3865,
    "traditional": "上級",
    "simplified": "上级",
    "reading": "shàngjí",
    "meaning": "higher level/authority",
    "grade": 5
  },
  {
    "id": 3866,
    "traditional": "上下",
    "simplified": "上下",
    "reading": "shàngxià",
    "meaning": "above and below, up and down, high and low, superior and inferior, senior and junior, old and young, ruler and subject, heaven and earth",
    "grade": 5
  },
  {
    "id": 3867,
    "traditional": "上漲",
    "simplified": "上涨",
    "reading": "shàngzhǎng",
    "meaning": "rise, go up (of water level/prices/etc.)",
    "grade": 5
  },
  {
    "id": 3868,
    "traditional": "稍微",
    "simplified": "稍微",
    "reading": "shāowēi",
    "meaning": "kind_of, sort_of, a little bit, some, slightly, passably, somewhat, moderately, a little, ratherish, a bit",
    "grade": 5
  },
  {
    "id": 3869,
    "traditional": "蛇",
    "simplified": "蛇",
    "reading": "shé",
    "meaning": "ophidian, serpent, Indian python, snake, Python molurus, viper",
    "grade": 5
  },
  {
    "id": 3870,
    "traditional": "稍",
    "simplified": "稍",
    "reading": "shāo",
    "meaning": "a little bit, any, slightly, a little, mezzo, poco, a bit",
    "grade": 5
  },
  {
    "id": 3871,
    "traditional": "捨不得",
    "simplified": "舍不得",
    "reading": "shěbude",
    "meaning": "be grudge doing sth., spare, begrudge, loathe to part with",
    "grade": 5
  },
  {
    "id": 3872,
    "traditional": "捨得",
    "simplified": "舍得",
    "reading": "shě de",
    "meaning": "to be willing to part with sth",
    "grade": 5
  },
  {
    "id": 3873,
    "traditional": "設想",
    "simplified": "设想",
    "reading": "shèxiǎng",
    "meaning": "imagine, assume, have consideration for",
    "grade": 5
  },
  {
    "id": 3874,
    "traditional": "社",
    "simplified": "社",
    "reading": "shè",
    "meaning": "society, group, club, agency, (old) god of the land",
    "grade": 5
  },
  {
    "id": 3875,
    "traditional": "社區",
    "simplified": "社区",
    "reading": "shèqū",
    "meaning": "community",
    "grade": 5
  },
  {
    "id": 3876,
    "traditional": "射",
    "simplified": "射",
    "reading": "shè",
    "meaning": "loose, discharge in a jet, insinuate, go off, allude to, discharge, send out, fire, firing, shooting, emit, deliver, shoot",
    "grade": 5
  },
  {
    "id": 3877,
    "traditional": "射擊",
    "simplified": "射击",
    "reading": "shèjī",
    "meaning": "shoot, fire",
    "grade": 5
  },
  {
    "id": 3878,
    "traditional": "攝像",
    "simplified": "摄像",
    "reading": "shèxiàng",
    "meaning": "to videotape",
    "grade": 5
  },
  {
    "id": 3879,
    "traditional": "攝像機",
    "simplified": "摄像机",
    "reading": "shèxiàngjī",
    "meaning": "video camera, CL:部[bu4]",
    "grade": 5
  },
  {
    "id": 3880,
    "traditional": "攝影師",
    "simplified": "摄影师",
    "reading": "shèyǐngshī",
    "meaning": "photographer, cameraman",
    "grade": 5
  },
  {
    "id": 3881,
    "traditional": "伸",
    "simplified": "伸",
    "reading": "shēn",
    "meaning": "to stretch, to extend",
    "grade": 5
  },
  {
    "id": 3882,
    "traditional": "攝影",
    "simplified": "摄影",
    "reading": "shèyǐng",
    "meaning": "photography",
    "grade": 5
  },
  {
    "id": 3883,
    "traditional": "深度",
    "simplified": "深度",
    "reading": "shēndù",
    "meaning": "measurement, fullness, profundity, depth, level, deepness, fulness, profoundness",
    "grade": 5
  },
  {
    "id": 3884,
    "traditional": "神",
    "simplified": "神",
    "reading": "shén",
    "meaning": "god, supreme, architect, Thor, superpersonality, Providence, providence, Deus, immortal, energy, numen, Being, divine, being, Surname, maker, Allah, vitality, look, deity, spirit, gum, omnipotent, anthropomorphism, expression, mind, soul, omniscience, immortality, omniscient, kami, Elohim, divinity",
    "grade": 5
  },
  {
    "id": 3885,
    "traditional": "神經",
    "simplified": "神经",
    "reading": "shénjīng",
    "meaning": "nervus, nerve",
    "grade": 5
  },
  {
    "id": 3886,
    "traditional": "深處",
    "simplified": "深处",
    "reading": "shēnchù",
    "meaning": "depth, bowel, recesses, profound, oceanic abyss, depths, deep, recess",
    "grade": 5
  },
  {
    "id": 3887,
    "traditional": "神情",
    "simplified": "神情",
    "reading": "shénqíng",
    "meaning": "patina, way, expression, facial_expression, look, patine",
    "grade": 5
  },
  {
    "id": 3888,
    "traditional": "生成",
    "simplified": "生成",
    "reading": "shēngchéng",
    "meaning": "to generate, to produce, generated, produced",
    "grade": 5
  },
  {
    "id": 3889,
    "traditional": "昇高",
    "simplified": "升高",
    "reading": "shēnggāo",
    "meaning": "up, hike, ascend, come up, arise, move up, climb, go up, lift, wax, loft, soar, heighten, rise, mount, escalate",
    "grade": 5
  },
  {
    "id": 3890,
    "traditional": "聲",
    "simplified": "声",
    "reading": "shēng",
    "meaning": "m.[activity]",
    "grade": 5
  },
  {
    "id": 3891,
    "traditional": "神奇",
    "simplified": "神奇",
    "reading": "shénqí",
    "meaning": "magical, mystical, miraculous",
    "grade": 5
  },
  {
    "id": 3892,
    "traditional": "勝負",
    "simplified": "胜负",
    "reading": "shèng-fù",
    "meaning": "victory or defeat, the outcome of a battle",
    "grade": 5
  },
  {
    "id": 3893,
    "traditional": "剩",
    "simplified": "剩",
    "reading": "shèng",
    "meaning": "leave, be left (over), surplus, remnant, be left, remain",
    "grade": 5
  },
  {
    "id": 3894,
    "traditional": "剩下",
    "simplified": "剩下",
    "reading": "shèng xia",
    "meaning": "be left over, leave, be left (over), be left, remain",
    "grade": 5
  },
  {
    "id": 3895,
    "traditional": "失誤",
    "simplified": "失误",
    "reading": "shīwù",
    "meaning": "mistake",
    "grade": 5
  },
  {
    "id": 3896,
    "traditional": "師傅",
    "simplified": "师傅",
    "reading": "shīfu",
    "meaning": "emperor, tutor of king, tutor of emperor, professional, address for service workers, master, general term of address in late 7 0 s an, general term of address in late 70s and 80s, master worker, tutor of king/emperor",
    "grade": 5
  },
  {
    "id": 3897,
    "traditional": "詩歌",
    "simplified": "诗歌",
    "reading": "shīgē",
    "meaning": "poem, CL:本[ben3],首[shou3],段[duan4]",
    "grade": 5
  },
  {
    "id": 3898,
    "traditional": "時常",
    "simplified": "时常",
    "reading": "shícháng",
    "meaning": "now_and_again, always, frequently, from_time_to_time, somewhile, oft, often",
    "grade": 5
  },
  {
    "id": 3899,
    "traditional": "十足",
    "simplified": "十足",
    "reading": "shízú",
    "meaning": "1 0 0 percent;out-and-out;downright",
    "grade": 5
  },
  {
    "id": 3900,
    "traditional": "時光",
    "simplified": "时光",
    "reading": "shíguāng",
    "meaning": "years, times, days, hour, time",
    "grade": 5
  },
  {
    "id": 3901,
    "traditional": "時機",
    "simplified": "时机",
    "reading": "shíjī",
    "meaning": "moment, day, an occasion, occasion, go, an opportune moment, opportunity, conjunction, conjuncture, juncture, a suitable (or right) time, turn, opportune moment",
    "grade": 5
  },
  {
    "id": 3902,
    "traditional": "時事",
    "simplified": "时事",
    "reading": "shíshì",
    "meaning": "current trends, the present situation, how things are going",
    "grade": 5
  },
  {
    "id": 3903,
    "traditional": "實惠",
    "simplified": "实惠",
    "reading": "shíhuì",
    "meaning": "tangible benefit, material advantages, cheap, economical, advantageous (deal), substantial (discount)",
    "grade": 5
  },
  {
    "id": 3904,
    "traditional": "拾",
    "simplified": "拾",
    "reading": "shè",
    "meaning": "to ascend in light steps, to pick up, to collate or arrange, ten (banker's anti-fraud numeral)",
    "grade": 5
  },
  {
    "id": 3905,
    "traditional": "使得",
    "simplified": "使得",
    "reading": "shǐde",
    "meaning": "render, wreck, compel, cause, make",
    "grade": 5
  },
  {
    "id": 3906,
    "traditional": "示範",
    "simplified": "示范",
    "reading": "shìfàn",
    "meaning": "demonstrate, exemplify, exhibit",
    "grade": 5
  },
  {
    "id": 3907,
    "traditional": "式",
    "simplified": "式",
    "reading": "shì",
    "meaning": "m.[kind]",
    "grade": 5
  },
  {
    "id": 3908,
    "traditional": "勢力",
    "simplified": "势力",
    "reading": "shìli",
    "meaning": "strength, influence, potency, weight, hold, leverage, yoke, sway, force, steam, presence, power, puissance",
    "grade": 5
  },
  {
    "id": 3909,
    "traditional": "試圖",
    "simplified": "试图",
    "reading": "shìtú",
    "meaning": "attempt, pretend, set about, try, seek, undertake",
    "grade": 5
  },
  {
    "id": 3910,
    "traditional": "視頻",
    "simplified": "视频",
    "reading": "shìpín",
    "meaning": "video",
    "grade": 5
  },
  {
    "id": 3911,
    "traditional": "收購",
    "simplified": "收购",
    "reading": "shōugòu",
    "meaning": "purchase, buy",
    "grade": 5
  },
  {
    "id": 3912,
    "traditional": "收集",
    "simplified": "收集",
    "reading": "shōují",
    "meaning": "collect, gather",
    "grade": 5
  },
  {
    "id": 3913,
    "traditional": "收拾",
    "simplified": "收拾",
    "reading": "shōushi",
    "meaning": "put in order, tidy up",
    "grade": 5
  },
  {
    "id": 3914,
    "traditional": "手段",
    "simplified": "手段",
    "reading": "shǒuduàn",
    "meaning": "resource, recourse, vehicle, wile, stratagem, gambit, artifice, instrument, twist, road, trick, ploy, shift, resort, polity, step, instrumentation, plan of attack, communication, artillery, finesse, means, channel, approach, implement, medium, gimmick, attack, device, refuge, method, instrumentality, stepping-stone, gateway, ministry, measure, way, tactic, expedient, quomodo, machinery, t",
    "grade": 5
  },
  {
    "id": 3915,
    "traditional": "壽司",
    "simplified": "寿司",
    "reading": "shòusī",
    "meaning": "sushi",
    "grade": 5
  },
  {
    "id": 3916,
    "traditional": "手法",
    "simplified": "手法",
    "reading": "shǒufǎ",
    "meaning": "manoeuvering, artifice, manner, hanky-panky, legerdemain, tactical manoeuver, trick, tactical maneuver, maneuver, technique, play, tactic, manoeuvre, modus_operandi, prestidigitation, stroke, tact, skill, maneuvering, sleight, implement, touch, gimmick",
    "grade": 5
  },
  {
    "id": 3917,
    "traditional": "受災",
    "simplified": "受灾",
    "reading": "shòu zāi",
    "meaning": "disaster-stricken, to be hit by a natural calamity",
    "grade": 5
  },
  {
    "id": 3918,
    "traditional": "視為",
    "simplified": "视为",
    "reading": "shìwéi",
    "meaning": "regard_as, deem, count, regard as, consider as, repute, look_on, regard/consider as, look_at, call, regard",
    "grade": 5
  },
  {
    "id": 3919,
    "traditional": "瘦",
    "simplified": "瘦",
    "reading": "shòu",
    "meaning": "skinny, emaciated, thin, waste, lean (of meat)",
    "grade": 5
  },
  {
    "id": 3920,
    "traditional": "書法",
    "simplified": "书法",
    "reading": "shūfǎ",
    "meaning": "calligraphy",
    "grade": 5
  },
  {
    "id": 3921,
    "traditional": "書桌",
    "simplified": "书桌",
    "reading": "shūzhuō",
    "meaning": "desk, CL:張|张[zhang1]",
    "grade": 5
  },
  {
    "id": 3922,
    "traditional": "輸出",
    "simplified": "输出",
    "reading": "shūchū",
    "meaning": "export",
    "grade": 5
  },
  {
    "id": 3923,
    "traditional": "書櫃",
    "simplified": "书柜",
    "reading": "shūguì",
    "meaning": "bookcase",
    "grade": 5
  },
  {
    "id": 3924,
    "traditional": "鼠",
    "simplified": "鼠",
    "reading": "shǔ",
    "meaning": "rat, mouse, CL:隻|只[zhi1]",
    "grade": 5
  },
  {
    "id": 3925,
    "traditional": "蔬菜",
    "simplified": "蔬菜",
    "reading": "shūcài",
    "meaning": "vegetal, kale, greenstuff, veggie, greengrocery, kail, greens, vegetables, truck, veg, sass, vegetable, vegetative",
    "grade": 5
  },
  {
    "id": 3926,
    "traditional": "鼠標",
    "simplified": "鼠标",
    "reading": "shǔbiāo",
    "meaning": "mouse (computing)",
    "grade": 5
  },
  {
    "id": 3927,
    "traditional": "熟悉",
    "simplified": "熟悉",
    "reading": "shúxi",
    "meaning": "know sth. well, know sb. well, get into, ken, have an intimate knowledge of, acquaint, know sth., know ... well, familiar, know sth./sb. well, be familiar with, sb. well",
    "grade": 5
  },
  {
    "id": 3928,
    "traditional": "摔倒",
    "simplified": "摔倒",
    "reading": "shuāidǎo",
    "meaning": "to fall down, to slip and fall, to throw sb to the ground",
    "grade": 5
  },
  {
    "id": 3929,
    "traditional": "摔",
    "simplified": "摔",
    "reading": "shuāi",
    "meaning": "throw, cast/throw down/off, drop, plunge, lose one's balance, off, swing, fall, tumble, throw down, cause to fall and break, cast down, cast, hurtle down, throw off, move backward and forward, fling, break",
    "grade": 5
  },
  {
    "id": 3930,
    "traditional": "數目",
    "simplified": "数目",
    "reading": "shùmù",
    "meaning": "figure, count, amount, number",
    "grade": 5
  },
  {
    "id": 3931,
    "traditional": "雙手",
    "simplified": "双手",
    "reading": "shuāngshǒu",
    "meaning": "lily-white, both hands",
    "grade": 5
  },
  {
    "id": 3932,
    "traditional": "水產品",
    "simplified": "水产品",
    "reading": "shuǐchǎnpǐn",
    "meaning": "Water products",
    "grade": 5
  },
  {
    "id": 3933,
    "traditional": "率領",
    "simplified": "率领",
    "reading": "shuàilǐng",
    "meaning": "lead, head, command",
    "grade": 5
  },
  {
    "id": 3934,
    "traditional": "水分",
    "simplified": "水分",
    "reading": "shuǐfèn",
    "meaning": "moisture content, (fig.) overstatement, padding",
    "grade": 5
  },
  {
    "id": 3935,
    "traditional": "水庫",
    "simplified": "水库",
    "reading": "shuǐkù",
    "meaning": "reservoir",
    "grade": 5
  },
  {
    "id": 3936,
    "traditional": "水災",
    "simplified": "水灾",
    "reading": "shuǐzāi",
    "meaning": "flood, flood damage",
    "grade": 5
  },
  {
    "id": 3937,
    "traditional": "說法",
    "simplified": "说法",
    "reading": "shuōfa",
    "meaning": "way of saying sth., wording, formulation, statement, version, argument",
    "grade": 5
  },
  {
    "id": 3938,
    "traditional": "碩士",
    "simplified": "硕士",
    "reading": "shuòshì",
    "meaning": "Master (of Arts), M.A., eminent scholar",
    "grade": 5
  },
  {
    "id": 3939,
    "traditional": "私人",
    "simplified": "私人",
    "reading": "sīrén",
    "meaning": "one's own man, private, personal relative, personal friend/relative, personal friend, confidant",
    "grade": 5
  },
  {
    "id": 3940,
    "traditional": "睡眠",
    "simplified": "睡眠",
    "reading": "shuìmián",
    "meaning": "sleep",
    "grade": 5
  },
  {
    "id": 3941,
    "traditional": "搜",
    "simplified": "搜",
    "reading": "sōu",
    "meaning": "to search",
    "grade": 5
  },
  {
    "id": 3942,
    "traditional": "四周",
    "simplified": "四周",
    "reading": "sìzhōu",
    "meaning": "all around, fringe, surrounding, periphery, environment, outer boundary, on all sides, on every side, vicinity",
    "grade": 5
  },
  {
    "id": 3943,
    "traditional": "搜索",
    "simplified": "搜索",
    "reading": "sōusuǒ",
    "meaning": "to search (a place), to search (a database etc)",
    "grade": 5
  },
  {
    "id": 3944,
    "traditional": "宿舍",
    "simplified": "宿舍",
    "reading": "sùshè",
    "meaning": "berth, serai, hall, dorm room, lodging_house, dormitory room, dorm, hostel, billet, dormitory, living quarters, house",
    "grade": 5
  },
  {
    "id": 3945,
    "traditional": "酸甜苦辣",
    "simplified": "酸甜苦辣",
    "reading": "suān-tián-kǔ-là",
    "meaning": "sour, sweet, bitter and spicy hot, fig. the joys and sorrows of life",
    "grade": 5
  },
  {
    "id": 3946,
    "traditional": "隨意",
    "simplified": "随意",
    "reading": "suí yì",
    "meaning": "as one pleases",
    "grade": 5
  },
  {
    "id": 3947,
    "traditional": "隨後",
    "simplified": "随后",
    "reading": "suíhòu",
    "meaning": "soon after",
    "grade": 5
  },
  {
    "id": 3948,
    "traditional": "歲月",
    "simplified": "岁月",
    "reading": "suìyuè",
    "meaning": "precession, years, days, time",
    "grade": 5
  },
  {
    "id": 3949,
    "traditional": "思維",
    "simplified": "思维",
    "reading": "sīwéi",
    "meaning": "thought, thinking",
    "grade": 5
  },
  {
    "id": 3950,
    "traditional": "碎",
    "simplified": "碎",
    "reading": "suì",
    "meaning": "break to pieces, break ... to pieces, garrulous, smash, knap, crumble, gabby, fragmentary, batter, broken",
    "grade": 5
  },
  {
    "id": 3951,
    "traditional": "隨著",
    "simplified": "随着",
    "reading": "suízhe",
    "meaning": "along with, in the wake of, in pace with",
    "grade": 5
  },
  {
    "id": 3952,
    "traditional": "損害",
    "simplified": "损害",
    "reading": "sǔnhài",
    "meaning": "injury, damage",
    "grade": 5
  },
  {
    "id": 3953,
    "traditional": "所在",
    "simplified": "所在",
    "reading": "suǒzài",
    "meaning": "ubiety, location, place, locality",
    "grade": 5
  },
  {
    "id": 3954,
    "traditional": "鎖",
    "simplified": "锁",
    "reading": "suǒ",
    "meaning": "to lock up, to lock, lock, old variant of 鎖|锁[suo3]",
    "grade": 5
  },
  {
    "id": 3955,
    "traditional": "損失",
    "simplified": "损失",
    "reading": "sǔnshī",
    "meaning": "loss",
    "grade": 5
  },
  {
    "id": 3956,
    "traditional": "颱風",
    "simplified": "台风",
    "reading": "táifēng",
    "meaning": "typhoon",
    "grade": 5
  },
  {
    "id": 3957,
    "traditional": "抬頭",
    "simplified": "抬头",
    "reading": "tái tóu",
    "meaning": "raise one's head, gain ground, improve, go up (in price)",
    "grade": 5
  },
  {
    "id": 3958,
    "traditional": "太空",
    "simplified": "太空",
    "reading": "tàikōng",
    "meaning": "firmament, outer space",
    "grade": 5
  },
  {
    "id": 3959,
    "traditional": "抬",
    "simplified": "抬",
    "reading": "tái",
    "meaning": "lift, betray, carry (together), raise, carry, argue for the sake of arguing",
    "grade": 5
  },
  {
    "id": 3960,
    "traditional": "彈",
    "simplified": "弹",
    "reading": "dàn",
    "meaning": "pluck/play (musical instrument)",
    "grade": 5
  },
  {
    "id": 3961,
    "traditional": "逃跑",
    "simplified": "逃跑",
    "reading": "táopǎo",
    "meaning": "to flee from sth, to run away, to escape",
    "grade": 5
  },
  {
    "id": 3962,
    "traditional": "逃走",
    "simplified": "逃走",
    "reading": "táo zǒu",
    "meaning": "run away, flee",
    "grade": 5
  },
  {
    "id": 3963,
    "traditional": "逃",
    "simplified": "逃",
    "reading": "táo",
    "meaning": "guy, escape, egress, scarper, break away, shrink from, fly, take flight, evade, run away, evasion, flee, run, shirk, turn tail, bunk, lam, dodge",
    "grade": 5
  },
  {
    "id": 3964,
    "traditional": "桃",
    "simplified": "桃",
    "reading": "táo",
    "meaning": "peach",
    "grade": 5
  },
  {
    "id": 3965,
    "traditional": "桃樹",
    "simplified": "桃树",
    "reading": "táoshù",
    "meaning": "peach tree, CL:株[zhu1]",
    "grade": 5
  },
  {
    "id": 3966,
    "traditional": "討厭",
    "simplified": "讨厌",
    "reading": "tǎo yàn",
    "meaning": "disincline, to loathe, be disgusted with, to have a dislike of, scunner, to dislike, despise, keck, to take an aversion to, loathe, loathing, disrelish, hate, detest, dislike, disgusting, disfavor",
    "grade": 5
  },
  {
    "id": 3967,
    "traditional": "桃花",
    "simplified": "桃花",
    "reading": "táohuā",
    "meaning": "Peach flower",
    "grade": 5
  },
  {
    "id": 3968,
    "traditional": "特性",
    "simplified": "特性",
    "reading": "tèxìng",
    "meaning": "attribute, distinctive feature, tang, a character, appanage, a characteristic, nature, trait, differentiation, data, habit, lineament, shtick, tone, distinction, identity, property, characteristic, specificity, ring, specialty, hair, character, cast, idiosyncrasy, specific, diagnosis, chemistry, feature, peculiarity, quality",
    "grade": 5
  },
  {
    "id": 3969,
    "traditional": "特有",
    "simplified": "特有",
    "reading": "tèyǒu",
    "meaning": "characteristic, especial",
    "grade": 5
  },
  {
    "id": 3970,
    "traditional": "特定",
    "simplified": "特定",
    "reading": "tèdìng",
    "meaning": "specifically, ad-hoc, given, specially designated, specific, specialized, ad hoc, specified",
    "grade": 5
  },
  {
    "id": 3971,
    "traditional": "提倡",
    "simplified": "提倡",
    "reading": "tíchàng",
    "meaning": "advocate, encourage, recommend",
    "grade": 5
  },
  {
    "id": 3972,
    "traditional": "提起",
    "simplified": "提起",
    "reading": "tíqǐ",
    "meaning": "mention, speak of",
    "grade": 5
  },
  {
    "id": 3973,
    "traditional": "提示",
    "simplified": "提示",
    "reading": "tíshì",
    "meaning": "to point out, to remind (sb of sth), to suggest, suggestion, tip, reminder, notice",
    "grade": 5
  },
  {
    "id": 3974,
    "traditional": "體積",
    "simplified": "体积",
    "reading": "tǐjī",
    "meaning": "volume, bulk",
    "grade": 5
  },
  {
    "id": 3975,
    "traditional": "題材",
    "simplified": "题材",
    "reading": "tícái",
    "meaning": "subject_matter, theme, material, content, subject, subject matter, depicted object",
    "grade": 5
  },
  {
    "id": 3976,
    "traditional": "天才",
    "simplified": "天才",
    "reading": "tiāncái",
    "meaning": "genius, talent, gift",
    "grade": 5
  },
  {
    "id": 3977,
    "traditional": "天然氣",
    "simplified": "天然气",
    "reading": "tiānránqì",
    "meaning": "natural gas",
    "grade": 5
  },
  {
    "id": 3978,
    "traditional": "體力",
    "simplified": "体力",
    "reading": "tǐlì",
    "meaning": "breath, brawn, strength, bottom, main, thews, stamina, vigor, energy, physical strength, vigour, vim, physical power, strength of body, vitality, physical power/strength, thew, muscle, sinew",
    "grade": 5
  },
  {
    "id": 3979,
    "traditional": "天文",
    "simplified": "天文",
    "reading": "tiānwén",
    "meaning": "astronomy",
    "grade": 5
  },
  {
    "id": 3980,
    "traditional": "廳",
    "simplified": "厅",
    "reading": "tīng",
    "meaning": "department, provincial government department, office, lobby, salle, hall",
    "grade": 5
  },
  {
    "id": 3981,
    "traditional": "調解",
    "simplified": "调解",
    "reading": "tiáojiě",
    "meaning": "to mediate, to bring parties to an agreement",
    "grade": 5
  },
  {
    "id": 3982,
    "traditional": "通用",
    "simplified": "通用",
    "reading": "tōngyòng",
    "meaning": "common (use), interchangeable",
    "grade": 5
  },
  {
    "id": 3983,
    "traditional": "偷",
    "simplified": "偷",
    "reading": "tōu",
    "meaning": "steal",
    "grade": 5
  },
  {
    "id": 3984,
    "traditional": "調節",
    "simplified": "调节",
    "reading": "tiáojié",
    "meaning": "regulate, adjust",
    "grade": 5
  },
  {
    "id": 3985,
    "traditional": "停留",
    "simplified": "停留",
    "reading": "tíngliú",
    "meaning": "stay for a time or at a place",
    "grade": 5
  },
  {
    "id": 3986,
    "traditional": "偷偷",
    "simplified": "偷偷",
    "reading": "tōutōu",
    "meaning": "on the QT, secretly, stealthily, on the Q.T., in secret",
    "grade": 5
  },
  {
    "id": 3987,
    "traditional": "土豆",
    "simplified": "土豆",
    "reading": "tǔdòu",
    "meaning": "potato, CL:個|个[ge4], (Tw) peanut, CL:顆|颗[ke1]",
    "grade": 5
  },
  {
    "id": 3988,
    "traditional": "突破",
    "simplified": "突破",
    "reading": "tū pò",
    "meaning": "make a breakthrough, surmount",
    "grade": 5
  },
  {
    "id": 3989,
    "traditional": "吐",
    "simplified": "吐",
    "reading": "tǔ",
    "meaning": "articulation, utter, ptyalize, puke, articulate, vomit, spin, spew, tell, give up unwillingly, spit, speak out, spit up, disgorge, expectorate, cough up, spue, gob, throw up, say, cough out",
    "grade": 5
  },
  {
    "id": 3990,
    "traditional": "兔",
    "simplified": "兔",
    "reading": "tù",
    "meaning": "rabbit",
    "grade": 5
  },
  {
    "id": 3991,
    "traditional": "團長",
    "simplified": "团长",
    "reading": "tuánzhǎng",
    "meaning": "head of delegation, regimental commander, head of troupe/etc., chief of a delegation, head of a troupe, troupe, head of delegation/troupe/etc., chairman of a delegation, chief of a troupe, colonel, head of a delegation, etc.",
    "grade": 5
  },
  {
    "id": 3992,
    "traditional": "推行",
    "simplified": "推行",
    "reading": "tuīxíng",
    "meaning": "carry out, practice",
    "grade": 5
  },
  {
    "id": 3993,
    "traditional": "脫離",
    "simplified": "脱离",
    "reading": "tuōlí",
    "meaning": "depart, disaffiliate, sever, wander, disengage, renegade, defect, secede, splinter, break away, drop_out, free, opt, extricate, bolt, separate oneself from, break up, be divorced from, separate, deviate, swerve, break_loose, prescind, escape_from, pervert",
    "grade": 5
  },
  {
    "id": 3994,
    "traditional": "外界",
    "simplified": "外界",
    "reading": "wàijiè",
    "meaning": "external world, external/outside world, the outside world, outside, environment, outside world, externality, external, the external world",
    "grade": 5
  },
  {
    "id": 3995,
    "traditional": "完了",
    "simplified": "完了",
    "reading": "wánliǎo",
    "meaning": "to be finished, to be done for, ruined, gone to the dogs, oh no",
    "grade": 5
  },
  {
    "id": 3996,
    "traditional": "為難",
    "simplified": "为难",
    "reading": "wéinán",
    "meaning": "disoblige, feel awkward, make things difficult for, create difficulties, feel embarrassed, embarrass, create difficulties (for sb.), be in a quandary, snarl, torment, bewilder, ride, bewildering",
    "grade": 5
  },
  {
    "id": 3997,
    "traditional": "微博",
    "simplified": "微博",
    "reading": "wēibó",
    "meaning": "micro-blogging, microblog",
    "grade": 5
  },
  {
    "id": 3998,
    "traditional": "為期",
    "simplified": "为期",
    "reading": "wéiqī",
    "meaning": "by/lasting (a definite time)",
    "grade": 5
  },
  {
    "id": 3999,
    "traditional": "為止",
    "simplified": "为止",
    "reading": "wéizhǐ",
    "meaning": "until, (used in combination with words like 到[dao4] or 至[zhi4] in constructs of the form 到...為止|到...为止)",
    "grade": 5
  },
  {
    "id": 4000,
    "traditional": "違法",
    "simplified": "违法",
    "reading": "wéi fǎ",
    "meaning": "illegal, be illegal, offend, violate, violate law, transgress, illegitimate, break the law",
    "grade": 5
  },
  {
    "id": 4001,
    "traditional": "違反",
    "simplified": "违反",
    "reading": "wéifǎn",
    "meaning": "outrage, depart, offend, violate, go_against, deviate, infringe, diverge, vary, go against, violation, contravene, transgress, infringement, infract, breach, break, run counter to",
    "grade": 5
  },
  {
    "id": 4002,
    "traditional": "圍繞",
    "simplified": "围绕",
    "reading": "wéirào",
    "meaning": "to revolve around, to center on (an issue)",
    "grade": 5
  },
  {
    "id": 4003,
    "traditional": "違規",
    "simplified": "违规",
    "reading": "wéi guī",
    "meaning": "violate rule/regulation",
    "grade": 5
  },
  {
    "id": 4004,
    "traditional": "委託",
    "simplified": "委托",
    "reading": "wěituō",
    "meaning": "to entrust, to trust, to commission",
    "grade": 5
  },
  {
    "id": 4005,
    "traditional": "唯一",
    "simplified": "唯一",
    "reading": "wéiyī",
    "meaning": "sole, one and only, only, unique",
    "grade": 5
  },
  {
    "id": 4006,
    "traditional": "為主",
    "simplified": "为主",
    "reading": "wéizhǔ",
    "meaning": "give first place to",
    "grade": 5
  },
  {
    "id": 4007,
    "traditional": "衛星",
    "simplified": "卫星",
    "reading": "wèixīng",
    "meaning": "man-made satellite, artificial satellite, moon, acolyte, satellite",
    "grade": 5
  },
  {
    "id": 4008,
    "traditional": "溫和",
    "simplified": "温和",
    "reading": "wēnhé",
    "meaning": "mild, moderate, temperate, tame, gentle",
    "grade": 5
  },
  {
    "id": 4009,
    "traditional": "胃",
    "simplified": "胃",
    "reading": "wèi",
    "meaning": "ventriculus, solar_plexus, venter, kyte, gizzard, belly, tummy, paunch, stomach, craw, gastric, breadbasket, gut, tum, abomasum",
    "grade": 5
  },
  {
    "id": 4010,
    "traditional": "慰問",
    "simplified": "慰问",
    "reading": "wèiwèn",
    "meaning": "to express sympathy, greetings, consolation etc",
    "grade": 5
  },
  {
    "id": 4011,
    "traditional": "握",
    "simplified": "握",
    "reading": "wò",
    "meaning": "take hold of, agitate, grasp, double, bite, shake, hold",
    "grade": 5
  },
  {
    "id": 4012,
    "traditional": "文藝",
    "simplified": "文艺",
    "reading": "wényì",
    "meaning": "literature and art",
    "grade": 5
  },
  {
    "id": 4013,
    "traditional": "污水",
    "simplified": "污水",
    "reading": "wūshuǐ",
    "meaning": "slops, sewage, wastewater, sewerage, waste water, drainage, sewer water, slop, foul water, polluted water, waste, effluent",
    "grade": 5
  },
  {
    "id": 4014,
    "traditional": "臥室",
    "simplified": "卧室",
    "reading": "wòshì",
    "meaning": "bedroom, CL:間|间[jian1]",
    "grade": 5
  },
  {
    "id": 4015,
    "traditional": "無奈",
    "simplified": "无奈",
    "reading": "wúnài",
    "meaning": "be helpless",
    "grade": 5
  },
  {
    "id": 4016,
    "traditional": "污染",
    "simplified": "污染",
    "reading": "wūrǎn",
    "meaning": "pollute, contaminate",
    "grade": 5
  },
  {
    "id": 4017,
    "traditional": "舞",
    "simplified": "舞",
    "reading": "wǔ",
    "meaning": "dance",
    "grade": 5
  },
  {
    "id": 4018,
    "traditional": "無疑",
    "simplified": "无疑",
    "reading": "wúyí",
    "meaning": "surely, undoubtedly, beyond doubt, certainty, pardi, certainly, easily",
    "grade": 5
  },
  {
    "id": 4019,
    "traditional": "物價",
    "simplified": "物价",
    "reading": "wùjià",
    "meaning": "commodity prices",
    "grade": 5
  },
  {
    "id": 4020,
    "traditional": "屋",
    "simplified": "屋",
    "reading": "wū",
    "meaning": "house, room, CL:間|间[jian1],個|个[ge4]",
    "grade": 5
  },
  {
    "id": 4021,
    "traditional": "物業",
    "simplified": "物业",
    "reading": "wùyè",
    "meaning": "property, real estate, abbr. for 物業管理|物业管理[wu4 ye4 guan3 li3], property management",
    "grade": 5
  },
  {
    "id": 4022,
    "traditional": "物質",
    "simplified": "物质",
    "reading": "wùzhì",
    "meaning": "stuff, material, substance, materiality, matter",
    "grade": 5
  },
  {
    "id": 4023,
    "traditional": "誤解",
    "simplified": "误解",
    "reading": "wùjiě",
    "meaning": "misunderstanding",
    "grade": 5
  },
  {
    "id": 4024,
    "traditional": "西紅柿",
    "simplified": "西红柿",
    "reading": "xīhóngshì",
    "meaning": "tomato, CL:隻|只[zhi1]",
    "grade": 5
  },
  {
    "id": 4025,
    "traditional": "喜劇",
    "simplified": "喜剧",
    "reading": "xǐjù",
    "meaning": "Thalia, comedy, sock",
    "grade": 5
  },
  {
    "id": 4026,
    "traditional": "西裝",
    "simplified": "西装",
    "reading": "xīzhuāng",
    "meaning": "suit, Western-style clothes, CL:套[tao4]",
    "grade": 5
  },
  {
    "id": 4027,
    "traditional": "戲",
    "simplified": "戏",
    "reading": "xì",
    "meaning": "drama, play, show",
    "grade": 5
  },
  {
    "id": 4028,
    "traditional": "戲劇",
    "simplified": "戏剧",
    "reading": "xìjù",
    "meaning": "dramatic_work, dramatic composition, theatricals, dramatic work, stage, theatre, theater, drama, dramaturgy, play, dramatic art, boards, dramatics",
    "grade": 5
  },
  {
    "id": 4029,
    "traditional": "嚇",
    "simplified": "吓",
    "reading": "xià",
    "meaning": "bounce, intimidate, frighten, daunt, bludgeon, scare, cow, bluster, horrify, huff, threaten",
    "grade": 5
  },
  {
    "id": 4030,
    "traditional": "先前",
    "simplified": "先前",
    "reading": "xiānqián",
    "meaning": "before, previously",
    "grade": 5
  },
  {
    "id": 4031,
    "traditional": "鮮艷",
    "simplified": "鲜艳",
    "reading": "xiānyàn",
    "meaning": "bright-colored, gaily-colored",
    "grade": 5
  },
  {
    "id": 4032,
    "traditional": "顯",
    "simplified": "显",
    "reading": "xiǎn",
    "meaning": "demonstrate, be obvious, manifest, appear, display, show",
    "grade": 5
  },
  {
    "id": 4033,
    "traditional": "先後",
    "simplified": "先后",
    "reading": "xiānhòu",
    "meaning": "early or late, priority, in succession, one after another",
    "grade": 5
  },
  {
    "id": 4034,
    "traditional": "現有",
    "simplified": "现有",
    "reading": "xiànyǒu",
    "meaning": "now available, existing",
    "grade": 5
  },
  {
    "id": 4035,
    "traditional": "現狀",
    "simplified": "现状",
    "reading": "xiànzhuàng",
    "meaning": "circumstance, present conditions, actuality, current status, present situation, status_quo, status quo",
    "grade": 5
  },
  {
    "id": 4036,
    "traditional": "獻",
    "simplified": "献",
    "reading": "xiàn",
    "meaning": "to offer, to present, to dedicate, to donate, to show, to put on display, worthy person (old)",
    "grade": 5
  },
  {
    "id": 4037,
    "traditional": "閑",
    "simplified": "闲",
    "reading": "xián",
    "meaning": "variant of 閑|闲, idle, unoccupied, leisure, free time",
    "grade": 5
  },
  {
    "id": 4038,
    "traditional": "線索",
    "simplified": "线索",
    "reading": "xiànsuǒ",
    "meaning": "track, thread, cue, lead, handhold, purchase, scent, train of thought, clue, trail, key, clew, pointer",
    "grade": 5
  },
  {
    "id": 4039,
    "traditional": "鄉村",
    "simplified": "乡村",
    "reading": "xiāngcūn",
    "meaning": "country, countryside, campong, rural, rural area, village, Kampong, hamlet",
    "grade": 5
  },
  {
    "id": 4040,
    "traditional": "相等",
    "simplified": "相等",
    "reading": "xiāngděng",
    "meaning": "equal, equally, equivalent",
    "grade": 5
  },
  {
    "id": 4041,
    "traditional": "香腸",
    "simplified": "香肠",
    "reading": "xiāngcháng",
    "meaning": "sausage, CL:根[gen1]",
    "grade": 5
  },
  {
    "id": 4042,
    "traditional": "詳細",
    "simplified": "详细",
    "reading": "xiángxì",
    "meaning": "detailed, minute",
    "grade": 5
  },
  {
    "id": 4043,
    "traditional": "相應",
    "simplified": "相应",
    "reading": "xiāngying",
    "meaning": "to correspond, answering (one another), to agree (among the part), corresponding, relevant, appropriate, (modify) accordingly",
    "grade": 5
  },
  {
    "id": 4044,
    "traditional": "鄉",
    "simplified": "乡",
    "reading": "xiāng",
    "meaning": "home village/town, country, countryside, native place, rural area, town, village, township (under county), township, country(side), home village, hamlet, home town",
    "grade": 5
  },
  {
    "id": 4045,
    "traditional": "嚮導",
    "simplified": "向导",
    "reading": "xiàngdǎo",
    "meaning": "guide",
    "grade": 5
  },
  {
    "id": 4046,
    "traditional": "享受",
    "simplified": "享受",
    "reading": "xiǎngshòu",
    "meaning": "enjoy",
    "grade": 5
  },
  {
    "id": 4047,
    "traditional": "向上",
    "simplified": "向上",
    "reading": "xiàngshàng",
    "meaning": "upward, up, to advance, to try to improve oneself, to make progress",
    "grade": 5
  },
  {
    "id": 4048,
    "traditional": "象徵",
    "simplified": "象征",
    "reading": "xiàngzhēng",
    "meaning": "symbol, emblem, token",
    "grade": 5
  },
  {
    "id": 4049,
    "traditional": "相聲",
    "simplified": "相声",
    "reading": "xiàngsheng",
    "meaning": "comic dialog, sketch, crosstalk",
    "grade": 5
  },
  {
    "id": 4050,
    "traditional": "消毒",
    "simplified": "消毒",
    "reading": "xiāodú",
    "meaning": "to disinfect, to sterilize",
    "grade": 5
  },
  {
    "id": 4051,
    "traditional": "消除",
    "simplified": "消除",
    "reading": "xiāochú",
    "meaning": "smooth, lull, removal, cancel, eradicate, rase, to clear up, turn back, counteract, iron_out, wipe, drive off, abate, die, eliminate, drive away, obviate, avoid, exorcise, chase away, wipe out, annihilate, run off, unblock, drive out, stamp out, kill, lift, disarm, get rid of, slake, rid of, decimate, to remove, demagnetize, undo, to clear, sublate, carry off, blue-pencil, exterminate, sa",
    "grade": 5
  },
  {
    "id": 4052,
    "traditional": "向前",
    "simplified": "向前",
    "reading": "xiàngqián",
    "meaning": "forward, onward",
    "grade": 5
  },
  {
    "id": 4053,
    "traditional": "消防",
    "simplified": "消防",
    "reading": "xiāofáng",
    "meaning": "fight/prevent fire",
    "grade": 5
  },
  {
    "id": 4054,
    "traditional": "消費者",
    "simplified": "消费者",
    "reading": "xiāofèizhě",
    "meaning": "customer, buyer, consumer",
    "grade": 5
  },
  {
    "id": 4055,
    "traditional": "小偷兒",
    "simplified": "小偷儿",
    "reading": "xiǎotōur5",
    "meaning": "Thief",
    "grade": 5
  },
  {
    "id": 4056,
    "traditional": "消極",
    "simplified": "消极",
    "reading": "xiāojí",
    "meaning": "negative, passive, inactive",
    "grade": 5
  },
  {
    "id": 4057,
    "traditional": "歇",
    "simplified": "歇",
    "reading": "xiē",
    "meaning": "to rest, to take a break, to stop, to halt, (dialect) to sleep, a moment, a short while",
    "grade": 5
  },
  {
    "id": 4058,
    "traditional": "協議書",
    "simplified": "协议书",
    "reading": "xiéyìshū",
    "meaning": "contract, protocol",
    "grade": 5
  },
  {
    "id": 4059,
    "traditional": "斜",
    "simplified": "斜",
    "reading": "xié",
    "meaning": "oblique, inclined, tilted",
    "grade": 5
  },
  {
    "id": 4060,
    "traditional": "心態",
    "simplified": "心态",
    "reading": "xīntài",
    "meaning": "state_of_mind, state of mind, psychology",
    "grade": 5
  },
  {
    "id": 4061,
    "traditional": "心疼",
    "simplified": "心疼",
    "reading": "xīnténg",
    "meaning": "to love dearly, to feel sorry for sb, to regret, to grudge, to be distressed",
    "grade": 5
  },
  {
    "id": 4062,
    "traditional": "辛苦",
    "simplified": "辛苦",
    "reading": "xīnkǔ",
    "meaning": "try, endure hardship, work hard, tug, tote, lug",
    "grade": 5
  },
  {
    "id": 4063,
    "traditional": "協議",
    "simplified": "协议",
    "reading": "xiéyì",
    "meaning": "agreement",
    "grade": 5
  },
  {
    "id": 4064,
    "traditional": "欣賞",
    "simplified": "欣赏",
    "reading": "xīnshǎng",
    "meaning": "appreciate, enjoy, admire",
    "grade": 5
  },
  {
    "id": 4065,
    "traditional": "信箱",
    "simplified": "信箱",
    "reading": "xìnxiāng",
    "meaning": "post-office box, mailbox, letter_box, letter box, postbox, box",
    "grade": 5
  },
  {
    "id": 4066,
    "traditional": "行駛",
    "simplified": "行驶",
    "reading": "xíngshǐ",
    "meaning": "go (of vehicles/boats/etc.)",
    "grade": 5
  },
  {
    "id": 4067,
    "traditional": "信念",
    "simplified": "信念",
    "reading": "xìnniàn",
    "meaning": "confession, tenet, strong belief, belief, creed, persuasion, conviction, opinion, faith",
    "grade": 5
  },
  {
    "id": 4068,
    "traditional": "形態",
    "simplified": "形态",
    "reading": "xíngtài",
    "meaning": "configuration, figuration, pattern, conformation, form, modality, appearance, contour, shape, morphology, morph",
    "grade": 5
  },
  {
    "id": 4069,
    "traditional": "性能",
    "simplified": "性能",
    "reading": "xìngnéng",
    "meaning": "operation, function (of machine, functioning, natural capacity, etc.), capability, behavior, capacity, performance, function, funtion, property, function (of machine/etc.)",
    "grade": 5
  },
  {
    "id": 4070,
    "traditional": "雄偉",
    "simplified": "雄伟",
    "reading": "xióngwěi",
    "meaning": "grand, imposing, magnificent, majestic",
    "grade": 5
  },
  {
    "id": 4071,
    "traditional": "熊",
    "simplified": "熊",
    "reading": "xióng",
    "meaning": "ursine, Xiong, Surname, bear",
    "grade": 5
  },
  {
    "id": 4072,
    "traditional": "休閒",
    "simplified": "休闲",
    "reading": "xiūxián",
    "meaning": "leisure, relaxation, not working, idle, to enjoy leisure, to lie fallow",
    "grade": 5
  },
  {
    "id": 4073,
    "traditional": "修復",
    "simplified": "修复",
    "reading": "xiūfù",
    "meaning": "to restore, to renovate, restoration, (computing) to fix (a bug)",
    "grade": 5
  },
  {
    "id": 4074,
    "traditional": "修養",
    "simplified": "修养",
    "reading": "xiūyǎng",
    "meaning": "accomplishment, training, mastery, self-cultivation",
    "grade": 5
  },
  {
    "id": 4075,
    "traditional": "修建",
    "simplified": "修建",
    "reading": "xiūjiàn",
    "meaning": "to build, to construct",
    "grade": 5
  },
  {
    "id": 4076,
    "traditional": "虛心",
    "simplified": "虚心",
    "reading": "xūxīn",
    "meaning": "modest",
    "grade": 5
  },
  {
    "id": 4077,
    "traditional": "選修",
    "simplified": "选修",
    "reading": "xuǎnxiū",
    "meaning": "study, read, take ... as an elective course, take as an elective, take, learn",
    "grade": 5
  },
  {
    "id": 4078,
    "traditional": "學科",
    "simplified": "学科",
    "reading": "xuékē",
    "meaning": "study, area, school subject, science, subject field, subject, course, subject area, discipline, branch of learning, course of study, field of study, branch of knowledge, field",
    "grade": 5
  },
  {
    "id": 4079,
    "traditional": "學位",
    "simplified": "学位",
    "reading": "xuéwèi",
    "meaning": "academic_degree, degree, academic degree",
    "grade": 5
  },
  {
    "id": 4080,
    "traditional": "尋求",
    "simplified": "寻求",
    "reading": "xúnqiú",
    "meaning": "seek, explore",
    "grade": 5
  },
  {
    "id": 4081,
    "traditional": "學者",
    "simplified": "学者",
    "reading": "xuézhě",
    "meaning": "academic, literate, pandit, letters, litterateur, learned person, literati, philomath, pundit, academician, faculty member, scholar, bookman, clerk, student, scholarly person, man of letters, man of learning, speleologist, doctor, clerisy, initiate, swami, punditry, savant, learned man, sophist",
    "grade": 5
  },
  {
    "id": 4082,
    "traditional": "許可",
    "simplified": "许可",
    "reading": "xǔkě",
    "meaning": "permit, allow",
    "grade": 5
  },
  {
    "id": 4083,
    "traditional": "押金",
    "simplified": "押金",
    "reading": "yājīn",
    "meaning": "deposit, down payment",
    "grade": 5
  },
  {
    "id": 4084,
    "traditional": "詢問",
    "simplified": "询问",
    "reading": "xúnwèn",
    "meaning": "ask about, inquire",
    "grade": 5
  },
  {
    "id": 4085,
    "traditional": "延伸",
    "simplified": "延伸",
    "reading": "yánshēn",
    "meaning": "extend, stretch",
    "grade": 5
  },
  {
    "id": 4086,
    "traditional": "亞軍",
    "simplified": "亚军",
    "reading": "yàjūn",
    "meaning": "second place (in a sports contest), runner-up",
    "grade": 5
  },
  {
    "id": 4087,
    "traditional": "鴨子",
    "simplified": "鸭子",
    "reading": "yāzi",
    "meaning": "duck, male prostitute (slang)",
    "grade": 5
  },
  {
    "id": 4088,
    "traditional": "嚴厲",
    "simplified": "严厉",
    "reading": "yánlì",
    "meaning": "stern, toughen, maul, severe",
    "grade": 5
  },
  {
    "id": 4089,
    "traditional": "嚴肅",
    "simplified": "严肃",
    "reading": "yánsù",
    "meaning": "serious, solemn",
    "grade": 5
  },
  {
    "id": 4090,
    "traditional": "言語",
    "simplified": "言语",
    "reading": "yányu",
    "meaning": "spoken language, speech",
    "grade": 5
  },
  {
    "id": 4091,
    "traditional": "研究所",
    "simplified": "研究所",
    "reading": "yánjiūsuǒ",
    "meaning": "graduate_school, research institute, school, laboratory, graduate school, institute",
    "grade": 5
  },
  {
    "id": 4092,
    "traditional": "邀請",
    "simplified": "邀请",
    "reading": "yāoqǐng",
    "meaning": "invite",
    "grade": 5
  },
  {
    "id": 4093,
    "traditional": "眼光",
    "simplified": "眼光",
    "reading": "yǎnguāng",
    "meaning": "brainstorm, eye, penetration, view, brainwave, way of looking at things, foresight, discernment, insight, vision, judgment, sight, taste",
    "grade": 5
  },
  {
    "id": 4094,
    "traditional": "搖頭",
    "simplified": "摇头",
    "reading": "yáo tóu",
    "meaning": "shake one's head",
    "grade": 5
  },
  {
    "id": 4095,
    "traditional": "也好",
    "simplified": "也好",
    "reading": "yěhǎo",
    "meaning": "particle: showing hesitant acceptance; (in ~也好, ~也好/罷) showing the acceptance or introduction of choices",
    "grade": 5
  },
  {
    "id": 4096,
    "traditional": "咬",
    "simplified": "咬",
    "reading": "yǎo",
    "meaning": "incriminate, grip, articulation, incriminate sb. else (usu. falsely), be nitpicking (about use of words), incrimination, articulate, bark, snap at, squeak, crunch, gnaw, tooth, pronounce, gnaw_at, prick, snap, knap, nip, bite off, begnaw, pronounce distinctly, pronunciation, be nitpicking, take, bite, seize with teeth, sting, incriminate sb. else",
    "grade": 5
  },
  {
    "id": 4097,
    "traditional": "業務",
    "simplified": "业务",
    "reading": "yèwù",
    "meaning": "service, practice, vocational work, line of work, affair, line, occupation, professional work, business, transaction",
    "grade": 5
  },
  {
    "id": 4098,
    "traditional": "夜間",
    "simplified": "夜间",
    "reading": "yèjian",
    "meaning": "nighttime, evening or night (e.g. classes)",
    "grade": 5
  },
  {
    "id": 4099,
    "traditional": "依法",
    "simplified": "依法",
    "reading": "yīfǎ",
    "meaning": "in conformity with legal provisions, lawfully, in accordance with the law, wrongfully, legally, de jure, according to law",
    "grade": 5
  },
  {
    "id": 4100,
    "traditional": "一流",
    "simplified": "一流",
    "reading": "yīliú",
    "meaning": "top quality, front ranking",
    "grade": 5
  },
  {
    "id": 4101,
    "traditional": "依據",
    "simplified": "依据",
    "reading": "yījù",
    "meaning": "according to, on the basis of, judging by",
    "grade": 5
  },
  {
    "id": 4102,
    "traditional": "依舊",
    "simplified": "依旧",
    "reading": "yījiù",
    "meaning": "as_usual, as was common, as usual, still, as before",
    "grade": 5
  },
  {
    "id": 4103,
    "traditional": "一輩子",
    "simplified": "一辈子",
    "reading": "yībèizi",
    "meaning": "(for) a lifetime",
    "grade": 5
  },
  {
    "id": 4104,
    "traditional": "一帶",
    "simplified": "一带",
    "reading": "yīdài",
    "meaning": "neighborhood, area, region, district",
    "grade": 5
  },
  {
    "id": 4105,
    "traditional": "依照",
    "simplified": "依照",
    "reading": "yīzhào",
    "meaning": "according to, in light of",
    "grade": 5
  },
  {
    "id": 4106,
    "traditional": "一旦",
    "simplified": "一旦",
    "reading": "yīdàn",
    "meaning": "conj.: once, some time or other",
    "grade": 5
  },
  {
    "id": 4107,
    "traditional": "一句話",
    "simplified": "一句话",
    "reading": "yījùhuà",
    "meaning": "in a word, in short",
    "grade": 5
  },
  {
    "id": 4108,
    "traditional": "一下兒",
    "simplified": "一下儿",
    "reading": "yīxiàr5",
    "meaning": "(used after a verb) give it a go, to do (sth for a bit to give it a try), one time, once, in a while, all of a sudden, all at once, erhua variant of 一下[yi1 xia4]",
    "grade": 5
  },
  {
    "id": 4109,
    "traditional": "一下子",
    "simplified": "一下子",
    "reading": "yīxiàzi",
    "meaning": "in a short while, all at once, all of a sudden",
    "grade": 5
  },
  {
    "id": 4110,
    "traditional": "一路",
    "simplified": "一路",
    "reading": "yīlù",
    "meaning": "taking the same route, throughtout the journey, all the way, all along, going the same way, through",
    "grade": 5
  },
  {
    "id": 4111,
    "traditional": "一向",
    "simplified": "一向",
    "reading": "yīxiàng",
    "meaning": "typically, all_along, up to now, always, consistently, all along, usually, forever",
    "grade": 5
  },
  {
    "id": 4112,
    "traditional": "乙",
    "simplified": "乙",
    "reading": "yǐ",
    "meaning": "det.: second",
    "grade": 5
  },
  {
    "id": 4113,
    "traditional": "以便",
    "simplified": "以便",
    "reading": "yǐbiàn",
    "meaning": "conj.: so that, in order to, with the aim of",
    "grade": 5
  },
  {
    "id": 4114,
    "traditional": "以往",
    "simplified": "以往",
    "reading": "yǐwǎng",
    "meaning": "in the past, formerly",
    "grade": 5
  },
  {
    "id": 4115,
    "traditional": "一口氣",
    "simplified": "一口气",
    "reading": "yīkǒuqì",
    "meaning": "without a break, at one go, in one breath, holus-bolus",
    "grade": 5
  },
  {
    "id": 4116,
    "traditional": "一身",
    "simplified": "一身",
    "reading": "yīshēn",
    "meaning": "whole body, from head to toe, single person, a suit of clothes",
    "grade": 5
  },
  {
    "id": 4117,
    "traditional": "意識",
    "simplified": "意识",
    "reading": "yìshi",
    "meaning": "consciousness, witting, sensibility, conscious, conscience, ideology, awareness, subconscious, mentality",
    "grade": 5
  },
  {
    "id": 4118,
    "traditional": "意味著",
    "simplified": "意味着",
    "reading": "yìwèizhe",
    "meaning": "to signify, to mean, to imply",
    "grade": 5
  },
  {
    "id": 4119,
    "traditional": "意志",
    "simplified": "意志",
    "reading": "yìzhì",
    "meaning": "volition, will, zap, determination, pleasure, heart, willpower",
    "grade": 5
  },
  {
    "id": 4120,
    "traditional": "因而",
    "simplified": "因而",
    "reading": "yīnér",
    "meaning": "conj.: consequently",
    "grade": 5
  },
  {
    "id": 4121,
    "traditional": "飲料",
    "simplified": "饮料",
    "reading": "yǐnliào",
    "meaning": "stuff, bibation, drinkable, beverage, belly-wash, potation, quencher, soft_drink, slop, brewage, potable, drink",
    "grade": 5
  },
  {
    "id": 4122,
    "traditional": "印刷",
    "simplified": "印刷",
    "reading": "yìnshuā",
    "meaning": "print",
    "grade": 5
  },
  {
    "id": 4123,
    "traditional": "飲食",
    "simplified": "饮食",
    "reading": "yǐnshí",
    "meaning": "dietetic, fare, food and drink, trencher, refection, diet",
    "grade": 5
  },
  {
    "id": 4124,
    "traditional": "硬",
    "simplified": "硬",
    "reading": "yìng",
    "meaning": "hard, stiff, firm, tough, obstinate",
    "grade": 5
  },
  {
    "id": 4125,
    "traditional": "硬件",
    "simplified": "硬件",
    "reading": "yìngjiàn",
    "meaning": "hardware",
    "grade": 5
  },
  {
    "id": 4126,
    "traditional": "擁抱",
    "simplified": "拥抱",
    "reading": "yōngbào",
    "meaning": "embrace",
    "grade": 5
  },
  {
    "id": 4127,
    "traditional": "擁有",
    "simplified": "拥有",
    "reading": "yōngyǒu",
    "meaning": "occupy, have, receive, aught, hold back, keep, keep back, owe, retain, own, possess, possession, hold, seize, take, acquire, corral, hold on",
    "grade": 5
  },
  {
    "id": 4128,
    "traditional": "用不著",
    "simplified": "用不着",
    "reading": "yòngbuzháo",
    "meaning": "not need, have no use for",
    "grade": 5
  },
  {
    "id": 4129,
    "traditional": "用戶",
    "simplified": "用户",
    "reading": "yònghù",
    "meaning": "customer, subscriber, enjoyer, user, consumer",
    "grade": 5
  },
  {
    "id": 4130,
    "traditional": "應",
    "simplified": "应",
    "reading": "yīng",
    "meaning": "should, ought to",
    "grade": 5
  },
  {
    "id": 4131,
    "traditional": "用來",
    "simplified": "用来",
    "reading": "yònglái",
    "meaning": "in order to, so as to",
    "grade": 5
  },
  {
    "id": 4132,
    "traditional": "優惠",
    "simplified": "优惠",
    "reading": "yōuhuì",
    "meaning": "give preferential/special treatment",
    "grade": 5
  },
  {
    "id": 4133,
    "traditional": "優先",
    "simplified": "优先",
    "reading": "yōuxiān",
    "meaning": "preferential, preferred, priority",
    "grade": 5
  },
  {
    "id": 4134,
    "traditional": "用于",
    "simplified": "用于",
    "reading": "yòngyú",
    "meaning": "use in, use on, use for",
    "grade": 5
  },
  {
    "id": 4135,
    "traditional": "幽默",
    "simplified": "幽默",
    "reading": "yōumò",
    "meaning": "humor",
    "grade": 5
  },
  {
    "id": 4136,
    "traditional": "由此",
    "simplified": "由此",
    "reading": "yóucǐ",
    "meaning": "hereby, from this",
    "grade": 5
  },
  {
    "id": 4137,
    "traditional": "尤其",
    "simplified": "尤其",
    "reading": "yóuqí",
    "meaning": "especially",
    "grade": 5
  },
  {
    "id": 4138,
    "traditional": "猶豫",
    "simplified": "犹豫",
    "reading": "yóuyù",
    "meaning": "hesitant, fudge, oscillate, stagger, waver, be irresolute, scruple, irresolute, crane, pause, yo-yo, vacillate, hang, weaken, boggle, swither, dacker, balk, demur, swiver, hesitate",
    "grade": 5
  },
  {
    "id": 4139,
    "traditional": "游泳池",
    "simplified": "游泳池",
    "reading": "yóuyǒngchí",
    "meaning": "swimming pool, CL:場|场[chang3]",
    "grade": 5
  },
  {
    "id": 4140,
    "traditional": "有毒",
    "simplified": "有毒",
    "reading": "yǒudú",
    "meaning": "poisonous",
    "grade": 5
  },
  {
    "id": 4141,
    "traditional": "有害",
    "simplified": "有害",
    "reading": "yǒuhài",
    "meaning": "destructive, harmful, damaging",
    "grade": 5
  },
  {
    "id": 4142,
    "traditional": "友誼",
    "simplified": "友谊",
    "reading": "yǒuyì",
    "meaning": "fellowship, comity, communion, attachment, companionship, friendly relationship, sodality, association, friendship, good-fellowship, friendliness, company, palship",
    "grade": 5
  },
  {
    "id": 4143,
    "traditional": "有力",
    "simplified": "有力",
    "reading": "yǒulì",
    "meaning": "forceful, strong, potent, powerful",
    "grade": 5
  },
  {
    "id": 4144,
    "traditional": "有利於",
    "simplified": "有利于",
    "reading": "yǒulìyú",
    "meaning": "beneficial to",
    "grade": 5
  },
  {
    "id": 4145,
    "traditional": "有著",
    "simplified": "有着",
    "reading": "yǒuzhe",
    "meaning": "to have, to possess",
    "grade": 5
  },
  {
    "id": 4146,
    "traditional": "羽毛球",
    "simplified": "羽毛球",
    "reading": "yǔmáoqiú",
    "meaning": "shuttlecock, badminton, CL:個|个[ge4]",
    "grade": 5
  },
  {
    "id": 4147,
    "traditional": "預備",
    "simplified": "预备",
    "reading": "yùbèi",
    "meaning": "get ready, dight, set, ready, busk, preparation, store, set up, prepare, line, gear up, provide_for, provide, equip, outfit",
    "grade": 5
  },
  {
    "id": 4148,
    "traditional": "羽絨服",
    "simplified": "羽绒服",
    "reading": "yǔróngfú",
    "meaning": "down-filled garment",
    "grade": 5
  },
  {
    "id": 4149,
    "traditional": "雨水",
    "simplified": "雨水",
    "reading": "yǔshuǐ",
    "meaning": "rain, Rainwater, rainwater, Rainwater (second solar term), rainfall, Rain water (2nd solar term)",
    "grade": 5
  },
  {
    "id": 4150,
    "traditional": "元旦",
    "simplified": "元旦",
    "reading": "Yuándàn",
    "meaning": "New Year's Day",
    "grade": 5
  },
  {
    "id": 4151,
    "traditional": "預期",
    "simplified": "预期",
    "reading": "yùqī",
    "meaning": "expect, anticipate",
    "grade": 5
  },
  {
    "id": 4152,
    "traditional": "原理",
    "simplified": "原理",
    "reading": "yuánlǐ",
    "meaning": "principle, tenet",
    "grade": 5
  },
  {
    "id": 4153,
    "traditional": "園林",
    "simplified": "园林",
    "reading": "yuánlín",
    "meaning": "gardens, park, landscape garden",
    "grade": 5
  },
  {
    "id": 4154,
    "traditional": "原先",
    "simplified": "原先",
    "reading": "yuánxiān",
    "meaning": "ab initio, at_first, initially, at the start, in_the_beginning, at first, originally",
    "grade": 5
  },
  {
    "id": 4155,
    "traditional": "原始",
    "simplified": "原始",
    "reading": "yuánshǐ",
    "meaning": "original, firsthand, primeval, primitive",
    "grade": 5
  },
  {
    "id": 4156,
    "traditional": "怨",
    "simplified": "怨",
    "reading": "yuàn",
    "meaning": "to blame, to complain",
    "grade": 5
  },
  {
    "id": 4157,
    "traditional": "原有",
    "simplified": "原有",
    "reading": "yuányǒu",
    "meaning": "originally possess, previously exist",
    "grade": 5
  },
  {
    "id": 4158,
    "traditional": "遠處",
    "simplified": "远处",
    "reading": "yuǎnchù",
    "meaning": "distance, bottom, distant place, distant point",
    "grade": 5
  },
  {
    "id": 4159,
    "traditional": "願",
    "simplified": "愿",
    "reading": "yuàn",
    "meaning": "will, wish, may, desire",
    "grade": 5
  },
  {
    "id": 4160,
    "traditional": "約束",
    "simplified": "约束",
    "reading": "yuēshù",
    "meaning": "control, restrain, bind",
    "grade": 5
  },
  {
    "id": 4161,
    "traditional": "月餅",
    "simplified": "月饼",
    "reading": "yuèbing",
    "meaning": "moon cake",
    "grade": 5
  },
  {
    "id": 4162,
    "traditional": "閱覽室",
    "simplified": "阅览室",
    "reading": "yuèlǎnshì",
    "meaning": "reading room, CL:間|间[jian1]",
    "grade": 5
  },
  {
    "id": 4163,
    "traditional": "運行",
    "simplified": "运行",
    "reading": "yùnxíng",
    "meaning": "to move along one's course (of celestial bodies etc), (fig.) to function, to be in operation, (of a train service etc) to operate, to run, (of a computer) to run",
    "grade": 5
  },
  {
    "id": 4164,
    "traditional": "運",
    "simplified": "运",
    "reading": "yùn",
    "meaning": "transportation, wield, transport, revolve, utilize, use, dribble, bear, carry, utilization",
    "grade": 5
  },
  {
    "id": 4165,
    "traditional": "月球",
    "simplified": "月球",
    "reading": "yuèqiú",
    "meaning": "the moon",
    "grade": 5
  },
  {
    "id": 4166,
    "traditional": "災",
    "simplified": "灾",
    "reading": "zāi",
    "meaning": "disaster, calamity, old variant of 災|灾[zai1]",
    "grade": 5
  },
  {
    "id": 4167,
    "traditional": "災害",
    "simplified": "灾害",
    "reading": "zāihài",
    "meaning": "tragedy, cataclysm, visitation, fatality, ravage, catastrophe, disaster, suffering, calamity",
    "grade": 5
  },
  {
    "id": 4168,
    "traditional": "再次",
    "simplified": "再次",
    "reading": "zàicì",
    "meaning": "once again, once_more, second time, again, a second time, over, third, once more/again, once more",
    "grade": 5
  },
  {
    "id": 4169,
    "traditional": "災區",
    "simplified": "灾区",
    "reading": "zāiqū",
    "meaning": "disaster area, stricken region",
    "grade": 5
  },
  {
    "id": 4170,
    "traditional": "災難",
    "simplified": "灾难",
    "reading": "zāinàn",
    "meaning": "affliction, tragedy, adversity, catastrophe, wo, misadventure, calamitous, mishap, bad luck, tribulation, smashup, cataclysm, trouble, mischance, disaster, mucker, fate, calamity, scourge, plague, infliction, visitation, misfortune, casualty, suffering, melt-down",
    "grade": 5
  },
  {
    "id": 4171,
    "traditional": "在場",
    "simplified": "在场",
    "reading": "zàichǎng",
    "meaning": "present, be present, be on the spot, stand_by, spot, be on the scene",
    "grade": 5
  },
  {
    "id": 4172,
    "traditional": "在內",
    "simplified": "在内",
    "reading": "zàinèi",
    "meaning": "(included) in it, among them",
    "grade": 5
  },
  {
    "id": 4173,
    "traditional": "暫時",
    "simplified": "暂时",
    "reading": "zànshí",
    "meaning": "temporarily",
    "grade": 5
  },
  {
    "id": 4174,
    "traditional": "暫停",
    "simplified": "暂停",
    "reading": "zàntíng",
    "meaning": "suspense, suspended, suspend, intermit, set aside, discontinue, remit, stop, suspension, pause, break, time-out",
    "grade": 5
  },
  {
    "id": 4175,
    "traditional": "再也",
    "simplified": "再也",
    "reading": "zàiyě",
    "meaning": "(not) any more",
    "grade": 5
  },
  {
    "id": 4176,
    "traditional": "糟",
    "simplified": "糟",
    "reading": "zāo",
    "meaning": "massacre, muddle, spoiled (lit., flub, fig.), pickle, goof, bumble, rotten",
    "grade": 5
  },
  {
    "id": 4177,
    "traditional": "糟糕",
    "simplified": "糟糕",
    "reading": "zāogāo",
    "meaning": "terrible, Too bad!, What a mess!",
    "grade": 5
  },
  {
    "id": 4178,
    "traditional": "早期",
    "simplified": "早期",
    "reading": "zǎoqī",
    "meaning": "early period, early phase, early stage",
    "grade": 5
  },
  {
    "id": 4179,
    "traditional": "增",
    "simplified": "增",
    "reading": "zēng",
    "meaning": "add, increase, rise, gain",
    "grade": 5
  },
  {
    "id": 4180,
    "traditional": "增產",
    "simplified": "增产",
    "reading": "zēng chǎn",
    "meaning": "to increase production",
    "grade": 5
  },
  {
    "id": 4181,
    "traditional": "增大",
    "simplified": "增大",
    "reading": "zēngdà",
    "meaning": "to enlarge, to amplify, to magnify",
    "grade": 5
  },
  {
    "id": 4182,
    "traditional": "增多",
    "simplified": "增多",
    "reading": "zēngduō",
    "meaning": "increase (in number/quantity)",
    "grade": 5
  },
  {
    "id": 4183,
    "traditional": "增強",
    "simplified": "增强",
    "reading": "zēngqiáng",
    "meaning": "strengthen, enhance",
    "grade": 5
  },
  {
    "id": 4184,
    "traditional": "摘",
    "simplified": "摘",
    "reading": "zhāi",
    "meaning": "to take, to borrow, to pick (flowers, fruit etc), to pluck, to select, to remove, to take off (glasses, hat etc)",
    "grade": 5
  },
  {
    "id": 4185,
    "traditional": "贈",
    "simplified": "赠",
    "reading": "zèng",
    "meaning": "to give as a present, to repel, to bestow an honorary title after death (old)",
    "grade": 5
  },
  {
    "id": 4186,
    "traditional": "展覽",
    "simplified": "展览",
    "reading": "zhǎnlǎn",
    "meaning": "exhibit, show, display",
    "grade": 5
  },
  {
    "id": 4187,
    "traditional": "贈送",
    "simplified": "赠送",
    "reading": "zèngsòng",
    "meaning": "present, complimentary, ladle, give_away, present ... as a gift, proffer, gift, give, present as gift, donate, compliment, give ... as a present",
    "grade": 5
  },
  {
    "id": 4188,
    "traditional": "占領",
    "simplified": "占领",
    "reading": "zhànlǐng",
    "meaning": "to occupy (a territory), to hold",
    "grade": 5
  },
  {
    "id": 4189,
    "traditional": "展現",
    "simplified": "展现",
    "reading": "zhǎnxiàn",
    "meaning": "unfold before one's eyes",
    "grade": 5
  },
  {
    "id": 4190,
    "traditional": "展示",
    "simplified": "展示",
    "reading": "zhǎnshì",
    "meaning": "reveal, lay bare",
    "grade": 5
  },
  {
    "id": 4191,
    "traditional": "占有",
    "simplified": "占有",
    "reading": "zhànyǒu",
    "meaning": "own, have, occupy, hold",
    "grade": 5
  },
  {
    "id": 4192,
    "traditional": "漲",
    "simplified": "涨",
    "reading": "zhǎng",
    "meaning": "rise, go up (of water/prices/etc.)",
    "grade": 5
  },
  {
    "id": 4193,
    "traditional": "漲價",
    "simplified": "涨价",
    "reading": "zhǎng jià",
    "meaning": "rise in price",
    "grade": 5
  },
  {
    "id": 4194,
    "traditional": "掌握",
    "simplified": "掌握",
    "reading": "zhǎngwò",
    "meaning": "take ... into one's hands, know well, master, have in hand, grasp, command, possess, control",
    "grade": 5
  },
  {
    "id": 4195,
    "traditional": "招生",
    "simplified": "招生",
    "reading": "zhāo shēng",
    "meaning": "recruit students",
    "grade": 5
  },
  {
    "id": 4196,
    "traditional": "招手",
    "simplified": "招手",
    "reading": "zhāo shǒu",
    "meaning": "to wave, to beckon",
    "grade": 5
  },
  {
    "id": 4197,
    "traditional": "珍貴",
    "simplified": "珍贵",
    "reading": "zhēnguì",
    "meaning": "valuable, precious",
    "grade": 5
  },
  {
    "id": 4198,
    "traditional": "珍珠",
    "simplified": "珍珠",
    "reading": "zhēnzhū",
    "meaning": "pearl, CL:顆|颗[ke1]",
    "grade": 5
  },
  {
    "id": 4199,
    "traditional": "珍惜",
    "simplified": "珍惜",
    "reading": "zhēnxī",
    "meaning": "treasure, value, cherish, foster, appreciate, hold dear, prize, care for",
    "grade": 5
  },
  {
    "id": 4200,
    "traditional": "真誠",
    "simplified": "真诚",
    "reading": "zhēnchéng",
    "meaning": "sincere, genuine, true",
    "grade": 5
  },
  {
    "id": 4201,
    "traditional": "真相",
    "simplified": "真相",
    "reading": "zhēnxiàng",
    "meaning": "fact, bottom, low-down, the real facts, dinkum, truth, real/true situation, size, score, real, lowdown, real situation, the real facts/truth, verity, the actual state of affairs, true situation, the real truth, the real situation",
    "grade": 5
  },
  {
    "id": 4202,
    "traditional": "診斷",
    "simplified": "诊断",
    "reading": "zhěnduàn",
    "meaning": "diagnose",
    "grade": 5
  },
  {
    "id": 4203,
    "traditional": "真理",
    "simplified": "真理",
    "reading": "zhēnlǐ",
    "meaning": "god, true, gospel truth, verity, true statement, truism, gospel, truth, veritas",
    "grade": 5
  },
  {
    "id": 4204,
    "traditional": "振動",
    "simplified": "振动",
    "reading": "zhèndòng",
    "meaning": "to vibrate, to shake, vibration",
    "grade": 5
  },
  {
    "id": 4205,
    "traditional": "震驚",
    "simplified": "震惊",
    "reading": "zhènjīng",
    "meaning": "shock, amaze, astonish",
    "grade": 5
  },
  {
    "id": 4206,
    "traditional": "爭議",
    "simplified": "争议",
    "reading": "zhēngyì",
    "meaning": "controvert, controversy, dispute, disputation",
    "grade": 5
  },
  {
    "id": 4207,
    "traditional": "正版",
    "simplified": "正版",
    "reading": "zhèngbǎn",
    "meaning": "genuine, legal, see also 盜版|盗版[dao4 ban3]",
    "grade": 5
  },
  {
    "id": 4208,
    "traditional": "正如",
    "simplified": "正如",
    "reading": "zhèngrú",
    "meaning": "just as, precisely as",
    "grade": 5
  },
  {
    "id": 4209,
    "traditional": "正規",
    "simplified": "正规",
    "reading": "zhèngguī",
    "meaning": "regular, standard",
    "grade": 5
  },
  {
    "id": 4210,
    "traditional": "證實",
    "simplified": "证实",
    "reading": "zhèngshí",
    "meaning": "confirm, verify",
    "grade": 5
  },
  {
    "id": 4211,
    "traditional": "證書",
    "simplified": "证书",
    "reading": "zhèngshū",
    "meaning": "credentials, certificate",
    "grade": 5
  },
  {
    "id": 4212,
    "traditional": "正義",
    "simplified": "正义",
    "reading": "zhèngyì",
    "meaning": "justice",
    "grade": 5
  },
  {
    "id": 4213,
    "traditional": "掙錢",
    "simplified": "挣钱",
    "reading": "zhèng qián",
    "meaning": "to make money",
    "grade": 5
  },
  {
    "id": 4214,
    "traditional": "之內",
    "simplified": "之内",
    "reading": "zhīnèi",
    "meaning": "inside of, among, within",
    "grade": 5
  },
  {
    "id": 4215,
    "traditional": "掙",
    "simplified": "挣",
    "reading": "zhēng",
    "meaning": "see 掙扎|挣扎[zheng1 zha2], to struggle to get free, to strive to acquire, to make (money)",
    "grade": 5
  },
  {
    "id": 4216,
    "traditional": "之外",
    "simplified": "之外",
    "reading": "zhīwài",
    "meaning": "beyond, apart from, excluding",
    "grade": 5
  },
  {
    "id": 4217,
    "traditional": "之中",
    "simplified": "之中",
    "reading": "zhīzhōng",
    "meaning": "inside, among, within",
    "grade": 5
  },
  {
    "id": 4218,
    "traditional": "之下",
    "simplified": "之下",
    "reading": "zhīxià",
    "meaning": "under the condition of, under, beneath, below",
    "grade": 5
  },
  {
    "id": 4219,
    "traditional": "執行",
    "simplified": "执行",
    "reading": "zhíxíng",
    "meaning": "carry out, execute",
    "grade": 5
  },
  {
    "id": 4220,
    "traditional": "支出",
    "simplified": "支出",
    "reading": "zhīchū",
    "meaning": "defrayal, payout, outgo, outgoing, expense, disbursal, expenditure, outlay, disbursement",
    "grade": 5
  },
  {
    "id": 4221,
    "traditional": "直線",
    "simplified": "直线",
    "reading": "zhíxiàn",
    "meaning": "straight line, sharply (rise or fall)",
    "grade": 5
  },
  {
    "id": 4222,
    "traditional": "支配",
    "simplified": "支配",
    "reading": "zhīpèi",
    "meaning": "arrange, allocate, budget, control, dominate, govern",
    "grade": 5
  },
  {
    "id": 4223,
    "traditional": "值班",
    "simplified": "值班",
    "reading": "zhí bān",
    "meaning": "to work a shift, on duty",
    "grade": 5
  },
  {
    "id": 4224,
    "traditional": "職位",
    "simplified": "职位",
    "reading": "zhíwèi",
    "meaning": "slot, appointment, character, berth, post, archbishopric, job, ministry, situation, office, position, spot, billet, place, posting",
    "grade": 5
  },
  {
    "id": 4225,
    "traditional": "職能",
    "simplified": "职能",
    "reading": "zhínéng",
    "meaning": "function, role",
    "grade": 5
  },
  {
    "id": 4226,
    "traditional": "只不過",
    "simplified": "只不过",
    "reading": "zhǐbuguò",
    "meaning": "only, merely, nothing but, no more than, it's just that ...",
    "grade": 5
  },
  {
    "id": 4227,
    "traditional": "只見",
    "simplified": "只见",
    "reading": "zhǐjiàn",
    "meaning": "Only see",
    "grade": 5
  },
  {
    "id": 4228,
    "traditional": "指標",
    "simplified": "指标",
    "reading": "zhǐbiāo",
    "meaning": "directive, characteristic, guideline, quota, index, norm, indication, fingerboard, indicant, loadstar, guiding principle, target, needle, fingerpost, pointer, indicator",
    "grade": 5
  },
  {
    "id": 4229,
    "traditional": "職務",
    "simplified": "职务",
    "reading": "zhíwù",
    "meaning": "engagement, job, part, office, line of duty, contribution, ministration, task, responsibility, commission, post, line of work, affair, hat, function, situation, occupation, line, province, share, duties, business, duty",
    "grade": 5
  },
  {
    "id": 4230,
    "traditional": "指示",
    "simplified": "指示",
    "reading": "zhǐshì",
    "meaning": "indicate, point out, instruct",
    "grade": 5
  },
  {
    "id": 4231,
    "traditional": "指甲",
    "simplified": "指甲",
    "reading": "zhǐjia",
    "meaning": "fingernail",
    "grade": 5
  },
  {
    "id": 4232,
    "traditional": "指責",
    "simplified": "指责",
    "reading": "zhǐzé",
    "meaning": "censure, criticize",
    "grade": 5
  },
  {
    "id": 4233,
    "traditional": "製成",
    "simplified": "制成",
    "reading": "zhìchéng",
    "meaning": "hew, make, successfully complete",
    "grade": 5
  },
  {
    "id": 4234,
    "traditional": "至",
    "simplified": "至",
    "reading": "zhì",
    "meaning": "to, until, till",
    "grade": 5
  },
  {
    "id": 4235,
    "traditional": "治安",
    "simplified": "治安",
    "reading": "zhìān",
    "meaning": "peace, order, public security, police, public order, security, public order/security",
    "grade": 5
  },
  {
    "id": 4236,
    "traditional": "制約",
    "simplified": "制约",
    "reading": "zhìyuē",
    "meaning": "to restrict, condition",
    "grade": 5
  },
  {
    "id": 4237,
    "traditional": "中斷",
    "simplified": "中断",
    "reading": "zhōngduàn",
    "meaning": "to cut short, to break off, to discontinue, to interrupt",
    "grade": 5
  },
  {
    "id": 4238,
    "traditional": "中秋節",
    "simplified": "中秋节",
    "reading": "Zhōngqiūjié",
    "meaning": "the Mid-Autumn Festival on 15th of 8th lunar month",
    "grade": 5
  },
  {
    "id": 4239,
    "traditional": "治理",
    "simplified": "治理",
    "reading": "zhìlǐ",
    "meaning": "administer, govern, bring under control, put in order",
    "grade": 5
  },
  {
    "id": 4240,
    "traditional": "中央",
    "simplified": "中央",
    "reading": "zhōngyāng",
    "meaning": "central authorities (of state/party/etc.)",
    "grade": 5
  },
  {
    "id": 4241,
    "traditional": "中藥",
    "simplified": "中药",
    "reading": "zhōngyào",
    "meaning": "trad. Ch. medicine",
    "grade": 5
  },
  {
    "id": 4242,
    "traditional": "終點",
    "simplified": "终点",
    "reading": "zhōngdiǎn",
    "meaning": "terminal point, destination, finish line",
    "grade": 5
  },
  {
    "id": 4243,
    "traditional": "終身",
    "simplified": "终身",
    "reading": "zhōngshēn",
    "meaning": "lifetime, life, marriage, all one's life, lifelong",
    "grade": 5
  },
  {
    "id": 4244,
    "traditional": "終止",
    "simplified": "终止",
    "reading": "zhōngzhǐ",
    "meaning": "stop, end",
    "grade": 5
  },
  {
    "id": 4245,
    "traditional": "中毒",
    "simplified": "中毒",
    "reading": "zhòng dú",
    "meaning": "poison, taint, be poisoned",
    "grade": 5
  },
  {
    "id": 4246,
    "traditional": "週期",
    "simplified": "周期",
    "reading": "zhōuqī",
    "meaning": "period, cycle",
    "grade": 5
  },
  {
    "id": 4247,
    "traditional": "眾多",
    "simplified": "众多",
    "reading": "zhòngduō",
    "meaning": "multitudinous, numerous",
    "grade": 5
  },
  {
    "id": 4248,
    "traditional": "竹子",
    "simplified": "竹子",
    "reading": "zhúzi",
    "meaning": "bamboo",
    "grade": 5
  },
  {
    "id": 4249,
    "traditional": "主辦",
    "simplified": "主办",
    "reading": "zhǔbàn",
    "meaning": "sponsor, direct, direction, host, give",
    "grade": 5
  },
  {
    "id": 4250,
    "traditional": "主觀",
    "simplified": "主观",
    "reading": "zhǔguān",
    "meaning": "subjective",
    "grade": 5
  },
  {
    "id": 4251,
    "traditional": "主體",
    "simplified": "主体",
    "reading": "zhǔtǐ",
    "meaning": "main body, body, hypostasis, main body/part, subject, principal part, corpus, main part, part",
    "grade": 5
  },
  {
    "id": 4252,
    "traditional": "主管",
    "simplified": "主管",
    "reading": "zhǔguǎn",
    "meaning": "person in charge",
    "grade": 5
  },
  {
    "id": 4253,
    "traditional": "助理",
    "simplified": "助理",
    "reading": "zhùlǐ",
    "meaning": "aide, adjunct, coadjutant, assistant, ancillary, auxiliary",
    "grade": 5
  },
  {
    "id": 4254,
    "traditional": "助手",
    "simplified": "助手",
    "reading": "zhùshǒu",
    "meaning": "assistant, helper",
    "grade": 5
  },
  {
    "id": 4255,
    "traditional": "主導",
    "simplified": "主导",
    "reading": "zhǔdǎo",
    "meaning": "leading, dominant, guiding",
    "grade": 5
  },
  {
    "id": 4256,
    "traditional": "注視",
    "simplified": "注视",
    "reading": "zhùshì",
    "meaning": "to watch attentively, to gaze at",
    "grade": 5
  },
  {
    "id": 4257,
    "traditional": "注射",
    "simplified": "注射",
    "reading": "zhùshè",
    "meaning": "inject",
    "grade": 5
  },
  {
    "id": 4258,
    "traditional": "註冊",
    "simplified": "注册",
    "reading": "zhù cè",
    "meaning": "register",
    "grade": 5
  },
  {
    "id": 4259,
    "traditional": "注重",
    "simplified": "注重",
    "reading": "zhùzhòng",
    "meaning": "lay stress on, pay attention to",
    "grade": 5
  },
  {
    "id": 4260,
    "traditional": "祝賀",
    "simplified": "祝贺",
    "reading": "zhùhè",
    "meaning": "to congratulate, congratulations, CL:個|个[ge4]",
    "grade": 5
  },
  {
    "id": 4261,
    "traditional": "專輯",
    "simplified": "专辑",
    "reading": "zhuānjí",
    "meaning": "album, special issue, record album, special issue of periodical, short films, special collection of pamphlets, short films, etc., special collection of pamphlets, short f, etc.",
    "grade": 5
  },
  {
    "id": 4262,
    "traditional": "專利",
    "simplified": "专利",
    "reading": "zhuānlì",
    "meaning": "patent",
    "grade": 5
  },
  {
    "id": 4263,
    "traditional": "轉化",
    "simplified": "转化",
    "reading": "zhuǎnhuà",
    "meaning": "to change, to transform, isomerization (chemistry)",
    "grade": 5
  },
  {
    "id": 4264,
    "traditional": "轉換",
    "simplified": "转换",
    "reading": "zhuǎnhuàn",
    "meaning": "change, transform",
    "grade": 5
  },
  {
    "id": 4265,
    "traditional": "轉讓",
    "simplified": "转让",
    "reading": "zhuǎnràng",
    "meaning": "transfer (technology, goods etc), conveyancing (property)",
    "grade": 5
  },
  {
    "id": 4266,
    "traditional": "轉向",
    "simplified": "转向",
    "reading": "zhuǎn xiàng",
    "meaning": "to change direction, fig. to change one's stance, to get lost, to lose one's way",
    "grade": 5
  },
  {
    "id": 4267,
    "traditional": "裝飾",
    "simplified": "装饰",
    "reading": "zhuāngshì",
    "meaning": "ornament",
    "grade": 5
  },
  {
    "id": 4268,
    "traditional": "撞",
    "simplified": "撞",
    "reading": "zhuàng",
    "meaning": "to knock against, to bump into, to run into, to meet by accident",
    "grade": 5
  },
  {
    "id": 4269,
    "traditional": "資產",
    "simplified": "资产",
    "reading": "zīchǎn",
    "meaning": "resource, belongings, holding, material possession, asset, capital fund, competency, substance, property, capital, assets",
    "grade": 5
  },
  {
    "id": 4270,
    "traditional": "資本",
    "simplified": "资本",
    "reading": "zīběn",
    "meaning": "capitalization, principal, principal sum, finance, sth. capitalized on, corpus, working capital, capital",
    "grade": 5
  },
  {
    "id": 4271,
    "traditional": "子彈",
    "simplified": "子弹",
    "reading": "zǐdàn",
    "meaning": "bullet, CL:粒[li4],顆|颗[ke1],發|发[fa1]",
    "grade": 5
  },
  {
    "id": 4272,
    "traditional": "仔細",
    "simplified": "仔细",
    "reading": "zǐxì",
    "meaning": "careful, be careful, look out",
    "grade": 5
  },
  {
    "id": 4273,
    "traditional": "資助",
    "simplified": "资助",
    "reading": "zīzhù",
    "meaning": "to subsidize, to provide financial aid, subsidy",
    "grade": 5
  },
  {
    "id": 4274,
    "traditional": "自殺",
    "simplified": "自杀",
    "reading": "zìshā",
    "meaning": "commit suicide",
    "grade": 5
  },
  {
    "id": 4275,
    "traditional": "紫",
    "simplified": "紫",
    "reading": "zǐ",
    "meaning": "purple, violet, amethyst, Lithospermum erythrorhizon (flowering plant whose root provides red purple dye), Japanese: murasaki",
    "grade": 5
  },
  {
    "id": 4276,
    "traditional": "自豪",
    "simplified": "自豪",
    "reading": "zìháo",
    "meaning": "pride, pride oneself on",
    "grade": 5
  },
  {
    "id": 4277,
    "traditional": "總裁",
    "simplified": "总裁",
    "reading": "zǒngcái",
    "meaning": "head examiner, director-general, president, governor",
    "grade": 5
  },
  {
    "id": 4278,
    "traditional": "總數",
    "simplified": "总数",
    "reading": "zǒngshù",
    "meaning": "result, all, amount of money, aggregate, sum total, summation, amount, number, sum, total, tale, sum of money, totality, capita, quantity",
    "grade": 5
  },
  {
    "id": 4279,
    "traditional": "總體",
    "simplified": "总体",
    "reading": "zǒngtǐ",
    "meaning": "completely, totally, total, entire, overall, population (statistics)",
    "grade": 5
  },
  {
    "id": 4280,
    "traditional": "總算",
    "simplified": "总算",
    "reading": "zǒngsuàn",
    "meaning": "at long last, finally",
    "grade": 5
  },
  {
    "id": 4281,
    "traditional": "自願",
    "simplified": "自愿",
    "reading": "zìyuàn",
    "meaning": "voluntary, act voluntarily",
    "grade": 5
  },
  {
    "id": 4282,
    "traditional": "阻礙",
    "simplified": "阻碍",
    "reading": "zǔài",
    "meaning": "hinder, block, impede, bar",
    "grade": 5
  },
  {
    "id": 4283,
    "traditional": "組織",
    "simplified": "组织",
    "reading": "zǔzhī",
    "meaning": "tissue, nerve, weave, organization, organized system",
    "grade": 5
  },
  {
    "id": 4284,
    "traditional": "醉",
    "simplified": "醉",
    "reading": "zuì",
    "meaning": "intoxicated",
    "grade": 5
  },
  {
    "id": 4285,
    "traditional": "尊敬",
    "simplified": "尊敬",
    "reading": "zūnjìng",
    "meaning": "respect, honor, esteem",
    "grade": 5
  },
  {
    "id": 4286,
    "traditional": "尊重",
    "simplified": "尊重",
    "reading": "zūnzhòng",
    "meaning": "respect, value, esteem",
    "grade": 5
  },
  {
    "id": 4287,
    "traditional": "遵守",
    "simplified": "遵守",
    "reading": "zūnshǒu",
    "meaning": "honour, comply with, obey, keep, hold back, abide, keep back, comply, observe, abide_by, observance, abide by, respect, restrain, honor",
    "grade": 5
  },
  {
    "id": 4288,
    "traditional": "挨著",
    "simplified": "挨着",
    "reading": "āizhe",
    "meaning": "near",
    "grade": 6
  },
  {
    "id": 4289,
    "traditional": "挨",
    "simplified": "挨",
    "reading": "āi",
    "meaning": "in order, in sequence, close to, adjacent to, to suffer, to endure, to pull through (hard times), to delay, to stall, to play for time, to dawdle",
    "grade": 6
  },
  {
    "id": 4290,
    "traditional": "安檢",
    "simplified": "安检",
    "reading": "ānjiǎn",
    "meaning": "safety check",
    "grade": 6
  },
  {
    "id": 4291,
    "traditional": "罷工",
    "simplified": "罢工",
    "reading": "bàgōng",
    "meaning": "go on strike",
    "grade": 6
  },
  {
    "id": 4292,
    "traditional": "挨打",
    "simplified": "挨打",
    "reading": "ái dǎ",
    "meaning": "to take a beating, to get thrashed, to come under attack",
    "grade": 6
  },
  {
    "id": 4293,
    "traditional": "白領",
    "simplified": "白领",
    "reading": "báilǐng",
    "meaning": "white collar, business person",
    "grade": 6
  },
  {
    "id": 4294,
    "traditional": "辦公",
    "simplified": "办公",
    "reading": "bàn gōng",
    "meaning": "work (usu. in an office), transact, work, handle official business",
    "grade": 6
  },
  {
    "id": 4295,
    "traditional": "百分點",
    "simplified": "百分点",
    "reading": "bǎifēndiǎn",
    "meaning": "percentage point",
    "grade": 6
  },
  {
    "id": 4296,
    "traditional": "辦學",
    "simplified": "办学",
    "reading": "bàn xué",
    "meaning": "to run a school",
    "grade": 6
  },
  {
    "id": 4297,
    "traditional": "辦事處",
    "simplified": "办事处",
    "reading": "bànshìchù",
    "meaning": "office, agency",
    "grade": 6
  },
  {
    "id": 4298,
    "traditional": "半決賽",
    "simplified": "半决赛",
    "reading": "bànjuésài",
    "meaning": "semifinals",
    "grade": 6
  },
  {
    "id": 4299,
    "traditional": "罷了",
    "simplified": "罢了",
    "reading": "bàle",
    "meaning": "particle: elucidating a previous proposition so as to diminish its effect",
    "grade": 6
  },
  {
    "id": 4300,
    "traditional": "保健",
    "simplified": "保健",
    "reading": "bǎojiàn",
    "meaning": "health care, health protection, care for one's health",
    "grade": 6
  },
  {
    "id": 4301,
    "traditional": "報刊",
    "simplified": "报刊",
    "reading": "bàokān",
    "meaning": "newspapers and periodicals, the press",
    "grade": 6
  },
  {
    "id": 4302,
    "traditional": "傍晚",
    "simplified": "傍晚",
    "reading": "bàngwǎn",
    "meaning": "in the evening, when night falls, towards evening, at night fall, at dusk",
    "grade": 6
  },
  {
    "id": 4303,
    "traditional": "暴風雨",
    "simplified": "暴风雨",
    "reading": "bàofēngyǔ",
    "meaning": "rainstorm, storm, tempest",
    "grade": 6
  },
  {
    "id": 4304,
    "traditional": "抱歉",
    "simplified": "抱歉",
    "reading": "bàoqiàn",
    "meaning": "to be sorry, to feel apologetic, sorry!",
    "grade": 6
  },
  {
    "id": 4305,
    "traditional": "暴露",
    "simplified": "暴露",
    "reading": "bàolù",
    "meaning": "unmask, divulge, expose, develop, bewray, unhood, unhusk, uncover, lay bare, show_up, reveal, flash, uncase, debunk, discover, unkennel, revelation, betray, give_away, unveil",
    "grade": 6
  },
  {
    "id": 4306,
    "traditional": "報考",
    "simplified": "报考",
    "reading": "bàokǎo",
    "meaning": "enter oneself for an examination",
    "grade": 6
  },
  {
    "id": 4307,
    "traditional": "爆",
    "simplified": "爆",
    "reading": "bào",
    "meaning": "to explode or burst, to quick fry or quick boil",
    "grade": 6
  },
  {
    "id": 4308,
    "traditional": "暴雨",
    "simplified": "暴雨",
    "reading": "bàoyǔ",
    "meaning": "torrential rain, rainstorm, CL:場|场[chang2],陣|阵[zhen4]",
    "grade": 6
  },
  {
    "id": 4309,
    "traditional": "爆炸",
    "simplified": "爆炸",
    "reading": "bàozhà",
    "meaning": "explode, blow up",
    "grade": 6
  },
  {
    "id": 4310,
    "traditional": "暴力",
    "simplified": "暴力",
    "reading": "bàolì",
    "meaning": "violence, force",
    "grade": 6
  },
  {
    "id": 4311,
    "traditional": "悲慘",
    "simplified": "悲惨",
    "reading": "bēicǎn",
    "meaning": "tragic, pathetic, miserable",
    "grade": 6
  },
  {
    "id": 4312,
    "traditional": "爆發",
    "simplified": "爆发",
    "reading": "bàofā",
    "meaning": "erupt, burst/break out",
    "grade": 6
  },
  {
    "id": 4313,
    "traditional": "背著",
    "simplified": "背着",
    "reading": "bēizhe",
    "meaning": "Carry on",
    "grade": 6
  },
  {
    "id": 4314,
    "traditional": "背心",
    "simplified": "背心",
    "reading": "bèixīn",
    "meaning": "sleeveless garment (vest, waistcoat, singlet, tank top etc), CL:件[jian4]",
    "grade": 6
  },
  {
    "id": 4315,
    "traditional": "本",
    "simplified": "本",
    "reading": "běn",
    "meaning": "det.: this",
    "grade": 6
  },
  {
    "id": 4316,
    "traditional": "奔跑",
    "simplified": "奔跑",
    "reading": "bēnpǎo",
    "meaning": "to run",
    "grade": 6
  },
  {
    "id": 4317,
    "traditional": "被告",
    "simplified": "被告",
    "reading": "bèigào",
    "meaning": "tenant, indictee, accused, respondent, defendant, suspect, the accused, appellee",
    "grade": 6
  },
  {
    "id": 4318,
    "traditional": "本期",
    "simplified": "本期",
    "reading": "běnqī",
    "meaning": "the current period, this term (usually in finance)",
    "grade": 6
  },
  {
    "id": 4319,
    "traditional": "本身",
    "simplified": "本身",
    "reading": "běnshēn",
    "meaning": "per se, self, oneself, identity, itself",
    "grade": 6
  },
  {
    "id": 4320,
    "traditional": "本質",
    "simplified": "本质",
    "reading": "běnzhì",
    "meaning": "suchness, basic nature, intrinsic quality, substantial, essentiality, innate character, entity, essential quality, basics, inbeing, essential, nature, self, feather, intrinsic, quiddity, being, substance, metal, quintessence, principle, kind, hypostasis, essence, fiber, internal, spirit, inwardness, woof, basis, gist",
    "grade": 6
  },
  {
    "id": 4321,
    "traditional": "本土",
    "simplified": "本土",
    "reading": "běntǔ",
    "meaning": "mainland, native, one's native country, metropolitan territory, continent",
    "grade": 6
  },
  {
    "id": 4322,
    "traditional": "逼",
    "simplified": "逼",
    "reading": "bī",
    "meaning": "extort, press for, press on towards, drive, force, compel, close in on, press up to",
    "grade": 6
  },
  {
    "id": 4323,
    "traditional": "本地",
    "simplified": "本地",
    "reading": "běndì",
    "meaning": "local, this locality",
    "grade": 6
  },
  {
    "id": 4324,
    "traditional": "必將",
    "simplified": "必将",
    "reading": "bìjiāng",
    "meaning": "inevitably",
    "grade": 6
  },
  {
    "id": 4325,
    "traditional": "筆試",
    "simplified": "笔试",
    "reading": "bǐshì",
    "meaning": "written examination, paper test (for an applicant)",
    "grade": 6
  },
  {
    "id": 4326,
    "traditional": "邊緣",
    "simplified": "边缘",
    "reading": "biānyuán",
    "meaning": "margin, brink, periphery, bead, brim, borderline, skirt, lip, fringe, delimitation, flange, threshold, boundary line, perimeter, rand, outer boundary, hem, line, border, verge, marginality, marge, skirting, edging, edge, corner, rim",
    "grade": 6
  },
  {
    "id": 4327,
    "traditional": "必修",
    "simplified": "必修",
    "reading": "bìxiū",
    "meaning": "required/obligatory (course), obligatory, obligatory (course), required",
    "grade": 6
  },
  {
    "id": 4328,
    "traditional": "閉",
    "simplified": "闭",
    "reading": "bì",
    "meaning": "shut, stop, close, stop up, obstruct, switch off, obstruction, turn off, seel",
    "grade": 6
  },
  {
    "id": 4329,
    "traditional": "扁",
    "simplified": "扁",
    "reading": "biǎn",
    "meaning": "surname Pian, flat, (coll.) to beat (sb) up, old variant of 匾[bian3], small boat",
    "grade": 6
  },
  {
    "id": 4330,
    "traditional": "編制",
    "simplified": "编制",
    "reading": "biānzhì",
    "meaning": "authorized staff/force",
    "grade": 6
  },
  {
    "id": 4331,
    "traditional": "變更",
    "simplified": "变更",
    "reading": "biàngēng",
    "meaning": "change, modify",
    "grade": 6
  },
  {
    "id": 4332,
    "traditional": "變形",
    "simplified": "变形",
    "reading": "biàn xíng",
    "meaning": "deformation, to become deformed, to change shape, to morph",
    "grade": 6
  },
  {
    "id": 4333,
    "traditional": "變換",
    "simplified": "变换",
    "reading": "biànhuàn",
    "meaning": "to transform, to convert, to vary, to alternate, a transformation",
    "grade": 6
  },
  {
    "id": 4334,
    "traditional": "便是",
    "simplified": "便是",
    "reading": "biànshì",
    "meaning": "even if",
    "grade": 6
  },
  {
    "id": 4335,
    "traditional": "便",
    "simplified": "便",
    "reading": "biàn",
    "meaning": "as early as, soon afterwards, little as, as early, and then, and so, so, as early/little as, as little as, then, in that case",
    "grade": 6
  },
  {
    "id": 4336,
    "traditional": "遍地",
    "simplified": "遍地",
    "reading": "biàndì",
    "meaning": "everywhere, all over",
    "grade": 6
  },
  {
    "id": 4337,
    "traditional": "表面上",
    "simplified": "表面上",
    "reading": "biǎomiànshang",
    "meaning": "Surface on the surface",
    "grade": 6
  },
  {
    "id": 4338,
    "traditional": "病情",
    "simplified": "病情",
    "reading": "bìngqíng",
    "meaning": "patient's condition",
    "grade": 6
  },
  {
    "id": 4339,
    "traditional": "撥打",
    "simplified": "拨打",
    "reading": "bōdǎ",
    "meaning": "to call, to dial",
    "grade": 6
  },
  {
    "id": 4340,
    "traditional": "病房",
    "simplified": "病房",
    "reading": "bìngfáng",
    "meaning": "sickroom, ward, hospital ward, hospital room",
    "grade": 6
  },
  {
    "id": 4341,
    "traditional": "波浪",
    "simplified": "波浪",
    "reading": "bōlàng",
    "meaning": "wave",
    "grade": 6
  },
  {
    "id": 4342,
    "traditional": "播",
    "simplified": "播",
    "reading": "bō",
    "meaning": "to sow, to scatter, to spread, to broadcast, Taiwan pr. [bo4]",
    "grade": 6
  },
  {
    "id": 4343,
    "traditional": "波動",
    "simplified": "波动",
    "reading": "bōdòng",
    "meaning": "undulate, fluctuate",
    "grade": 6
  },
  {
    "id": 4344,
    "traditional": "不見",
    "simplified": "不见",
    "reading": "bùjiàn",
    "meaning": "not to see, not to meet, to have disappeared, to be missing",
    "grade": 6
  },
  {
    "id": 4345,
    "traditional": "不便",
    "simplified": "不便",
    "reading": "bùbiàn",
    "meaning": "inconvenient, inappropriate",
    "grade": 6
  },
  {
    "id": 4346,
    "traditional": "不再",
    "simplified": "不再",
    "reading": "bùzài",
    "meaning": "no more/longer",
    "grade": 6
  },
  {
    "id": 4347,
    "traditional": "不料",
    "simplified": "不料",
    "reading": "bùliào",
    "meaning": "unexpectedly",
    "grade": 6
  },
  {
    "id": 4348,
    "traditional": "不至於",
    "simplified": "不至于",
    "reading": "bùzhìyú",
    "meaning": "unlikely to go so far as to, not as bad as",
    "grade": 6
  },
  {
    "id": 4349,
    "traditional": "補考",
    "simplified": "补考",
    "reading": "bǔkǎo",
    "meaning": "to sit for a makeup exam, to resit an exam, makeup exam, resit",
    "grade": 6
  },
  {
    "id": 4350,
    "traditional": "補習",
    "simplified": "补习",
    "reading": "bǔxí",
    "meaning": "take lessons after school/work",
    "grade": 6
  },
  {
    "id": 4351,
    "traditional": "補課",
    "simplified": "补课",
    "reading": "bǔ kè",
    "meaning": "to make up missed lesson, to reschedule a class",
    "grade": 6
  },
  {
    "id": 4352,
    "traditional": "補助",
    "simplified": "补助",
    "reading": "bǔzhù",
    "meaning": "subsidize",
    "grade": 6
  },
  {
    "id": 4353,
    "traditional": "不成",
    "simplified": "不成",
    "reading": "bùchéng",
    "meaning": "won't do, unable to, (at the end of a rhetorical question) can that be?",
    "grade": 6
  },
  {
    "id": 4354,
    "traditional": "捕",
    "simplified": "捕",
    "reading": "bǔ",
    "meaning": "prawn, seizure, seize, arrest, catch",
    "grade": 6
  },
  {
    "id": 4355,
    "traditional": "不禁",
    "simplified": "不禁",
    "reading": "bùjīn",
    "meaning": "can't help (doing sth.)",
    "grade": 6
  },
  {
    "id": 4356,
    "traditional": "不僅僅",
    "simplified": "不仅仅",
    "reading": "bùjǐnjǐn",
    "meaning": "not only",
    "grade": 6
  },
  {
    "id": 4357,
    "traditional": "不通",
    "simplified": "不通",
    "reading": "bùtōng",
    "meaning": "to be obstructed, to be blocked up, to be impassable, to make no sense, to be illogical",
    "grade": 6
  },
  {
    "id": 4358,
    "traditional": "不怎麼樣",
    "simplified": "不怎么样",
    "reading": "bù zěnmeyàng",
    "meaning": "not up to much, very indifferent, nothing great about it, nothing good to be said about it",
    "grade": 6
  },
  {
    "id": 4359,
    "traditional": "不值",
    "simplified": "不值",
    "reading": "bùzhí",
    "meaning": "not worth",
    "grade": 6
  },
  {
    "id": 4360,
    "traditional": "不怎麼",
    "simplified": "不怎么",
    "reading": "bùzěnme",
    "meaning": "not very, not particularly",
    "grade": 6
  },
  {
    "id": 4361,
    "traditional": "布滿",
    "simplified": "布满",
    "reading": "bùmǎn",
    "meaning": "to be covered with, to be filled with",
    "grade": 6
  },
  {
    "id": 4362,
    "traditional": "部隊",
    "simplified": "部队",
    "reading": "bùduì",
    "meaning": "military personnel, troops, troop, regular army, soldiery, army, party, company",
    "grade": 6
  },
  {
    "id": 4363,
    "traditional": "採納",
    "simplified": "采纳",
    "reading": "cǎinà",
    "meaning": "to accept, to adopt",
    "grade": 6
  },
  {
    "id": 4364,
    "traditional": "參賽",
    "simplified": "参赛",
    "reading": "cān sài",
    "meaning": "take part in match, enter competition",
    "grade": 6
  },
  {
    "id": 4365,
    "traditional": "踩",
    "simplified": "踩",
    "reading": "cǎi",
    "meaning": "tread, tramp, tromp, scrunch, trample, walk, step, step on",
    "grade": 6
  },
  {
    "id": 4366,
    "traditional": "參展",
    "simplified": "参展",
    "reading": "cān zhǎn",
    "meaning": "participate in exhibition",
    "grade": 6
  },
  {
    "id": 4367,
    "traditional": "餐",
    "simplified": "餐",
    "reading": "cān",
    "meaning": "m.[event]",
    "grade": 6
  },
  {
    "id": 4368,
    "traditional": "殘疾",
    "simplified": "残疾",
    "reading": "cánji",
    "meaning": "disabled, handicapped, deformity on a person or animal",
    "grade": 6
  },
  {
    "id": 4369,
    "traditional": "殘疾人",
    "simplified": "残疾人",
    "reading": "cánjírén",
    "meaning": "disabled person",
    "grade": 6
  },
  {
    "id": 4370,
    "traditional": "殘酷",
    "simplified": "残酷",
    "reading": "cánkù",
    "meaning": "brutal, ruthless",
    "grade": 6
  },
  {
    "id": 4371,
    "traditional": "倉庫",
    "simplified": "仓库",
    "reading": "cāngkù",
    "meaning": "depot, storehouse, warehouse",
    "grade": 6
  },
  {
    "id": 4372,
    "traditional": "慘",
    "simplified": "惨",
    "reading": "cǎn",
    "meaning": "tragic, gloomy, savage, sorrowful, cruel, pitiful, miserable, dark",
    "grade": 6
  },
  {
    "id": 4373,
    "traditional": "藏",
    "simplified": "藏",
    "reading": "cáng",
    "meaning": "lay by, concealment, conceal, hide, store",
    "grade": 6
  },
  {
    "id": 4374,
    "traditional": "操縱",
    "simplified": "操纵",
    "reading": "cāozòng",
    "meaning": "operate, control, rig, manipulate",
    "grade": 6
  },
  {
    "id": 4375,
    "traditional": "側",
    "simplified": "侧",
    "reading": "cè",
    "meaning": "the side, to incline towards, to lean, inclined, lateral, side, lean on one side",
    "grade": 6
  },
  {
    "id": 4376,
    "traditional": "廁所",
    "simplified": "厕所",
    "reading": "cèsuǒ",
    "meaning": "john, W.C., can, jakes, lavatory, lav, cloaca, crapper, privy, water closet, bathroom, bog, washroom, closet, convenience, stool, rear, latrine, loo, facility, toilet, John, cloakroom",
    "grade": 6
  },
  {
    "id": 4377,
    "traditional": "測定",
    "simplified": "测定",
    "reading": "cèdìng",
    "meaning": "to survey and evaluate",
    "grade": 6
  },
  {
    "id": 4378,
    "traditional": "策劃",
    "simplified": "策划",
    "reading": "cèhuà",
    "meaning": "to plot, to scheme, to bring about, to engineer, planning, producer, planner",
    "grade": 6
  },
  {
    "id": 4379,
    "traditional": "策略",
    "simplified": "策略",
    "reading": "cèlüè",
    "meaning": "coup, resource, enginery, wile, stratagem, gambit, practice, plant, diplomacy, artifice, trap, twist, tactical manoeuver, policy, game, mechanism, game plan, ambush, chicanery, finesse, lying in wait, maneuvering, joker, tactics, gimmick, manoeuvering, plan, device, art, tactical maneuver, maneuver, measure, play, tactic, manoeuvre, mechanics, tack, ambuscade, trickery, strategy, setup, r",
    "grade": 6
  },
  {
    "id": 4380,
    "traditional": "層面",
    "simplified": "层面",
    "reading": "céngmiàn",
    "meaning": "storey, level, side, story, range, floor, coverage",
    "grade": 6
  },
  {
    "id": 4381,
    "traditional": "差異",
    "simplified": "差异",
    "reading": "chāyì",
    "meaning": "gap, departure, spread, variance, divergence, difference, diversity, discrepancy, imparity, disparity, contrast, interval, differentia, distinction",
    "grade": 6
  },
  {
    "id": 4382,
    "traditional": "查出",
    "simplified": "查出",
    "reading": "cháchū",
    "meaning": "find, look_up, smell, investigate, find_out, find out, smoke_out, detect, track_down, smoke out, examine",
    "grade": 6
  },
  {
    "id": 4383,
    "traditional": "查看",
    "simplified": "查看",
    "reading": "chákàn",
    "meaning": "to look over, to examine, to check up, to ferret out",
    "grade": 6
  },
  {
    "id": 4384,
    "traditional": "拆遷",
    "simplified": "拆迁",
    "reading": "chāiqiān",
    "meaning": "to demolish a building and relocate the inhabitants",
    "grade": 6
  },
  {
    "id": 4385,
    "traditional": "產量",
    "simplified": "产量",
    "reading": "chǎnliàng",
    "meaning": "turnout, turnoff, outturn, capacity, output, produce, product, fruitage, throughput, fruit, production, yield, crop",
    "grade": 6
  },
  {
    "id": 4386,
    "traditional": "昌盛",
    "simplified": "昌盛",
    "reading": "chāngshèng",
    "meaning": "prosperous",
    "grade": 6
  },
  {
    "id": 4387,
    "traditional": "長假",
    "simplified": "长假",
    "reading": "chángjià",
    "meaning": "long vacation, refers to one week national holiday in PRC starting 1st May and 1st Oct",
    "grade": 6
  },
  {
    "id": 4388,
    "traditional": "長短",
    "simplified": "长短",
    "reading": "chángduǎn",
    "meaning": "length, duration, accident, right and wrong, good and bad, long and short",
    "grade": 6
  },
  {
    "id": 4389,
    "traditional": "長跑",
    "simplified": "长跑",
    "reading": "chángpǎo",
    "meaning": "long-distance running",
    "grade": 6
  },
  {
    "id": 4390,
    "traditional": "長遠",
    "simplified": "长远",
    "reading": "chángyuǎn",
    "meaning": "long-range, long, long-term, far-reaching",
    "grade": 6
  },
  {
    "id": 4391,
    "traditional": "常規",
    "simplified": "常规",
    "reading": "chángguī",
    "meaning": "code of conduct, conventions, common practice, routine (medical procedure etc)",
    "grade": 6
  },
  {
    "id": 4392,
    "traditional": "長久",
    "simplified": "长久",
    "reading": "chángjiǔ",
    "meaning": "permanently, long, for a long time",
    "grade": 6
  },
  {
    "id": 4393,
    "traditional": "常年",
    "simplified": "常年",
    "reading": "chángnián",
    "meaning": "all year round, for years on end, average year",
    "grade": 6
  },
  {
    "id": 4394,
    "traditional": "場地",
    "simplified": "场地",
    "reading": "chǎngdì",
    "meaning": "grounds, site, ground, yard, precinct, space, course, place, park, property",
    "grade": 6
  },
  {
    "id": 4395,
    "traditional": "廠商",
    "simplified": "厂商",
    "reading": "chǎngshāng",
    "meaning": "factory owner, factories and stores, firm, maker, business organization, business concern, manufacturing business, concern, manufacturer, business",
    "grade": 6
  },
  {
    "id": 4396,
    "traditional": "場館",
    "simplified": "场馆",
    "reading": "chǎngguǎn",
    "meaning": "sporting venue, arena",
    "grade": 6
  },
  {
    "id": 4397,
    "traditional": "場景",
    "simplified": "场景",
    "reading": "chǎngjǐng",
    "meaning": "stage set, set, scenery, spectacle, scene, setting, set decoration",
    "grade": 6
  },
  {
    "id": 4398,
    "traditional": "暢通",
    "simplified": "畅通",
    "reading": "chàngtōng",
    "meaning": "unimpeded, free-flowing, straight path, unclogged, move without obstruction",
    "grade": 6
  },
  {
    "id": 4399,
    "traditional": "超出",
    "simplified": "超出",
    "reading": "chāo chū",
    "meaning": "to exceed, to overstep, to go too far, to encroach",
    "grade": 6
  },
  {
    "id": 4400,
    "traditional": "炒",
    "simplified": "炒",
    "reading": "chǎo",
    "meaning": "saute, stir-fry, roast, speculate, parch, fry, saut#2e, fire, saut#2 e, sack, scramble",
    "grade": 6
  },
  {
    "id": 4401,
    "traditional": "超",
    "simplified": "超",
    "reading": "chāo",
    "meaning": "to exceed, to overtake, to surpass, to transcend, to pass, to cross, ultra-, super-",
    "grade": 6
  },
  {
    "id": 4402,
    "traditional": "炒股",
    "simplified": "炒股",
    "reading": "chǎo gǔ",
    "meaning": "(coll.) to speculate in stocks",
    "grade": 6
  },
  {
    "id": 4403,
    "traditional": "車號",
    "simplified": "车号",
    "reading": "chēhào",
    "meaning": "Car number",
    "grade": 6
  },
  {
    "id": 4404,
    "traditional": "炒作",
    "simplified": "炒作",
    "reading": "chǎozuò",
    "meaning": "stir",
    "grade": 6
  },
  {
    "id": 4405,
    "traditional": "車展",
    "simplified": "车展",
    "reading": "chēzhǎn",
    "meaning": "motor show",
    "grade": 6
  },
  {
    "id": 4406,
    "traditional": "車牌",
    "simplified": "车牌",
    "reading": "chēpái",
    "meaning": "license plate",
    "grade": 6
  },
  {
    "id": 4407,
    "traditional": "撤銷",
    "simplified": "撤销",
    "reading": "chèxiāo",
    "meaning": "cancel, rescind, revoke",
    "grade": 6
  },
  {
    "id": 4408,
    "traditional": "撤離",
    "simplified": "撤离",
    "reading": "chèlí",
    "meaning": "to withdraw from, to evacuate",
    "grade": 6
  },
  {
    "id": 4409,
    "traditional": "撐",
    "simplified": "撑",
    "reading": "chēng",
    "meaning": "prop up, brace, push/move with a pole, maintain, keep up",
    "grade": 6
  },
  {
    "id": 4410,
    "traditional": "成分",
    "simplified": "成分",
    "reading": "chéngfen",
    "meaning": "constitution, class/economic status, composition, economic status, component_part, ingredient, one's profession or economic status, profession, component part, grammatical constituent, class, constituent, one's class status, class status, element, component, metal",
    "grade": 6
  },
  {
    "id": 4411,
    "traditional": "成品",
    "simplified": "成品",
    "reading": "chéngpǐn",
    "meaning": "end, end product, turnoff, end/finished product, finished product, facture",
    "grade": 6
  },
  {
    "id": 4412,
    "traditional": "承諾",
    "simplified": "承诺",
    "reading": "chéngnuò",
    "meaning": "promise",
    "grade": 6
  },
  {
    "id": 4413,
    "traditional": "城區",
    "simplified": "城区",
    "reading": "chéngqū",
    "meaning": "city district, urban area",
    "grade": 6
  },
  {
    "id": 4414,
    "traditional": "城鄉",
    "simplified": "城乡",
    "reading": "chéngxiāng",
    "meaning": "urban and rural, town and country",
    "grade": 6
  },
  {
    "id": 4415,
    "traditional": "城鎮",
    "simplified": "城镇",
    "reading": "chéngzhèn",
    "meaning": "cities and towns",
    "grade": 6
  },
  {
    "id": 4416,
    "traditional": "成",
    "simplified": "成",
    "reading": "chéng",
    "meaning": "become, turn into",
    "grade": 6
  },
  {
    "id": 4417,
    "traditional": "衝擊",
    "simplified": "冲击",
    "reading": "chōngjī",
    "meaning": "lash, pound, charge, assault",
    "grade": 6
  },
  {
    "id": 4418,
    "traditional": "持有",
    "simplified": "持有",
    "reading": "chíyǒu",
    "meaning": "boast, take, possess, hold, carry",
    "grade": 6
  },
  {
    "id": 4419,
    "traditional": "重建",
    "simplified": "重建",
    "reading": "chóngjiàn",
    "meaning": "to rebuild, to reestablish, reconstruction, rebuilding",
    "grade": 6
  },
  {
    "id": 4420,
    "traditional": "寵物",
    "simplified": "宠物",
    "reading": "chǒngwù",
    "meaning": "pet",
    "grade": 6
  },
  {
    "id": 4421,
    "traditional": "崇拜",
    "simplified": "崇拜",
    "reading": "chóngbài",
    "meaning": "worship, adore",
    "grade": 6
  },
  {
    "id": 4422,
    "traditional": "重組",
    "simplified": "重组",
    "reading": "chóngzǔ",
    "meaning": "to reorganize, to recombine, recombination",
    "grade": 6
  },
  {
    "id": 4423,
    "traditional": "沖",
    "simplified": "冲",
    "reading": "chōng",
    "meaning": "charge, rush, dash",
    "grade": 6
  },
  {
    "id": 4424,
    "traditional": "出場",
    "simplified": "出场",
    "reading": "chū chǎng",
    "meaning": "to appear (on stage, in a show, in a photo etc), to play (for a team), to enter (arena or stage), to send sb out (e.g. off the field for a foul)",
    "grade": 6
  },
  {
    "id": 4425,
    "traditional": "出動",
    "simplified": "出动",
    "reading": "chūdòng",
    "meaning": "to start out on a trip, to dispatch troops",
    "grade": 6
  },
  {
    "id": 4426,
    "traditional": "出訪",
    "simplified": "出访",
    "reading": "chūfǎng",
    "meaning": "to travel on business, to visit (a foreign country), same as 外出訪問|外出访问[wai4 chu1 fang3 wen4]",
    "grade": 6
  },
  {
    "id": 4427,
    "traditional": "出路",
    "simplified": "出路",
    "reading": "chūlù",
    "meaning": "way out, outlet, employment opportunities",
    "grade": 6
  },
  {
    "id": 4428,
    "traditional": "出面",
    "simplified": "出面",
    "reading": "chū miàn",
    "meaning": "act in one's own capacity or on behalf o, act in one's own capacity or on behalf of an organization, act in one's own capacity or on behalf of an organ, appear personally",
    "grade": 6
  },
  {
    "id": 4429,
    "traditional": "出入",
    "simplified": "出入",
    "reading": "chūrù",
    "meaning": "come in and go out",
    "grade": 6
  },
  {
    "id": 4430,
    "traditional": "出事",
    "simplified": "出事",
    "reading": "chū shì",
    "meaning": "to have an accident, to meet with a mishap",
    "grade": 6
  },
  {
    "id": 4431,
    "traditional": "出名",
    "simplified": "出名",
    "reading": "chū míng",
    "meaning": "lend one's name, out, fame, become well-known, become famous, famous, use the name of, be famous, well-known",
    "grade": 6
  },
  {
    "id": 4432,
    "traditional": "出臺",
    "simplified": "出台",
    "reading": "chū tái",
    "meaning": "to officially launch (a policy, program etc), to appear on stage, to appear publicly, (of a bar girl) to leave with a client",
    "grade": 6
  },
  {
    "id": 4433,
    "traditional": "初等",
    "simplified": "初等",
    "reading": "chūděng",
    "meaning": "elementary (i.e. easy)",
    "grade": 6
  },
  {
    "id": 4434,
    "traditional": "除",
    "simplified": "除",
    "reading": "chú",
    "meaning": "except",
    "grade": 6
  },
  {
    "id": 4435,
    "traditional": "出行",
    "simplified": "出行",
    "reading": "chūxíng",
    "meaning": "to set out on a long journey, to travel afar",
    "grade": 6
  },
  {
    "id": 4436,
    "traditional": "廚師",
    "simplified": "厨师",
    "reading": "chúshī",
    "meaning": "cook, chef",
    "grade": 6
  },
  {
    "id": 4437,
    "traditional": "儲存",
    "simplified": "储存",
    "reading": "chǔcún",
    "meaning": "lay in/up, store, stockpile",
    "grade": 6
  },
  {
    "id": 4438,
    "traditional": "處處",
    "simplified": "处处",
    "reading": "chùchù",
    "meaning": "everywhere, in all respects",
    "grade": 6
  },
  {
    "id": 4439,
    "traditional": "處長",
    "simplified": "处长",
    "reading": "chùzhǎng",
    "meaning": "department, section chief, commissioner, department/office head, department head, head of a department, office head",
    "grade": 6
  },
  {
    "id": 4440,
    "traditional": "傳輸",
    "simplified": "传输",
    "reading": "chuánshū",
    "meaning": "transmission, transmit",
    "grade": 6
  },
  {
    "id": 4441,
    "traditional": "傳媒",
    "simplified": "传媒",
    "reading": "chuánméi",
    "meaning": "media",
    "grade": 6
  },
  {
    "id": 4442,
    "traditional": "傳言",
    "simplified": "传言",
    "reading": "chuányán",
    "meaning": "rumor, hearsay",
    "grade": 6
  },
  {
    "id": 4443,
    "traditional": "傳出",
    "simplified": "传出",
    "reading": "chuánchū",
    "meaning": "to transmit outwards, to disseminate, efferent (nerve)",
    "grade": 6
  },
  {
    "id": 4444,
    "traditional": "船員",
    "simplified": "船员",
    "reading": "chuányuán",
    "meaning": "crew, sailorman, sailor, shipmate, crewman, tarpaulin, old salt, seaman, bargeman, tar, boatman, Jack-tar, mariner, sea dog, gob, waister, seafarer",
    "grade": 6
  },
  {
    "id": 4445,
    "traditional": "船隻",
    "simplified": "船只",
    "reading": "chuánzhī",
    "meaning": "boat, watercraft, marine, ship, vessel",
    "grade": 6
  },
  {
    "id": 4446,
    "traditional": "船長",
    "simplified": "船长",
    "reading": "chuánzhǎng",
    "meaning": "captain (of a boat), skipper",
    "grade": 6
  },
  {
    "id": 4447,
    "traditional": "串",
    "simplified": "串",
    "reading": "chuàn",
    "meaning": "to string together, to skewer, to connect wrongly, to gang up, to rove, string, bunch, skewer, classifier for things that are strung together, or in a bunch, or in a row: string of, bunch of, series of, to make a swift or abrupt linear movement (like a bead on an abacus), to move across",
    "grade": 6
  },
  {
    "id": 4448,
    "traditional": "窗口",
    "simplified": "窗口",
    "reading": "chuāngkǒu",
    "meaning": "window, wicket, ticket window",
    "grade": 6
  },
  {
    "id": 4449,
    "traditional": "創建",
    "simplified": "创建",
    "reading": "chuàngjiàn",
    "meaning": "to found, to establish",
    "grade": 6
  },
  {
    "id": 4450,
    "traditional": "創意",
    "simplified": "创意",
    "reading": "chuàngyì",
    "meaning": "create new meanings, get-up-and-go, unique idea, new idea",
    "grade": 6
  },
  {
    "id": 4451,
    "traditional": "創辦",
    "simplified": "创办",
    "reading": "chuàngbàn",
    "meaning": "found, launch, establishment, set up, establish",
    "grade": 6
  },
  {
    "id": 4452,
    "traditional": "此處",
    "simplified": "此处",
    "reading": "cǐchù",
    "meaning": "this place, here (literary)",
    "grade": 6
  },
  {
    "id": 4453,
    "traditional": "此前",
    "simplified": "此前",
    "reading": "cǐqián",
    "meaning": "before this, before then, previously",
    "grade": 6
  },
  {
    "id": 4454,
    "traditional": "此次",
    "simplified": "此次",
    "reading": "cǐcì",
    "meaning": "this time",
    "grade": 6
  },
  {
    "id": 4455,
    "traditional": "此事",
    "simplified": "此事",
    "reading": "cǐshì",
    "meaning": "This matter",
    "grade": 6
  },
  {
    "id": 4456,
    "traditional": "次數",
    "simplified": "次数",
    "reading": "cìshù",
    "meaning": "number of times, frequancy, frequency, absolute frequency, legal case, oftenness, lawsuit, time, frequence",
    "grade": 6
  },
  {
    "id": 4457,
    "traditional": "此致",
    "simplified": "此致",
    "reading": "cǐzhì",
    "meaning": "(used at the end of a letter to introduce a polite salutation)",
    "grade": 6
  },
  {
    "id": 4458,
    "traditional": "從不",
    "simplified": "从不",
    "reading": "cóngbù",
    "meaning": "ne'er, never",
    "grade": 6
  },
  {
    "id": 4459,
    "traditional": "從沒",
    "simplified": "从没",
    "reading": "cóngméi",
    "meaning": "never (in the past), never did",
    "grade": 6
  },
  {
    "id": 4460,
    "traditional": "醋",
    "simplified": "醋",
    "reading": "cù",
    "meaning": "vinegar, jealousy (in love rivalry)",
    "grade": 6
  },
  {
    "id": 4461,
    "traditional": "村莊",
    "simplified": "村庄",
    "reading": "cūnzhuāng",
    "meaning": "village, hamlet, CL:座[zuo4]",
    "grade": 6
  },
  {
    "id": 4462,
    "traditional": "搭",
    "simplified": "搭",
    "reading": "dā",
    "meaning": "put up, construct, join together, add (money/etc.)",
    "grade": 6
  },
  {
    "id": 4463,
    "traditional": "錯過",
    "simplified": "错过",
    "reading": "cuòguò",
    "meaning": "pass up, muff, throw_away, lose, let slip, miss, overslip, balk, overlook, cross, pass_up, slip",
    "grade": 6
  },
  {
    "id": 4464,
    "traditional": "搭配",
    "simplified": "搭配",
    "reading": "dāpèi",
    "meaning": "group, pair",
    "grade": 6
  },
  {
    "id": 4465,
    "traditional": "搭檔",
    "simplified": "搭档",
    "reading": "dādàng",
    "meaning": "to cooperate, partner",
    "grade": 6
  },
  {
    "id": 4466,
    "traditional": "打動",
    "simplified": "打动",
    "reading": "dǎdòng",
    "meaning": "to move (to pity), arousing (sympathy), touching",
    "grade": 6
  },
  {
    "id": 4467,
    "traditional": "打斷",
    "simplified": "打断",
    "reading": "dǎ duàn",
    "meaning": "to interrupt, to break off, to break (a bone)",
    "grade": 6
  },
  {
    "id": 4468,
    "traditional": "打牌",
    "simplified": "打牌",
    "reading": "dǎ pái",
    "meaning": "to play mahjong or cards",
    "grade": 6
  },
  {
    "id": 4469,
    "traditional": "打發",
    "simplified": "打发",
    "reading": "dǎfa",
    "meaning": "to dispatch sb to do sth, to make sb leave, to pass (the time), (old) to make arrangements, (old) to bestow (alms etc)",
    "grade": 6
  },
  {
    "id": 4470,
    "traditional": "打官司",
    "simplified": "打官司",
    "reading": "dǎ guānsi",
    "meaning": "to file a lawsuit, to sue, to dispute",
    "grade": 6
  },
  {
    "id": 4471,
    "traditional": "打印機",
    "simplified": "打印机",
    "reading": "dǎyìnjī",
    "meaning": "printer",
    "grade": 6
  },
  {
    "id": 4472,
    "traditional": "大道",
    "simplified": "大道",
    "reading": "dàdào",
    "meaning": "wide road, the way of virtue and justice",
    "grade": 6
  },
  {
    "id": 4473,
    "traditional": "打造",
    "simplified": "打造",
    "reading": "dǎzào",
    "meaning": "to create, to build, to develop, to forge (of metal)",
    "grade": 6
  },
  {
    "id": 4474,
    "traditional": "大街",
    "simplified": "大街",
    "reading": "dàjiē",
    "meaning": "street, main street, CL:條|条[tiao2]",
    "grade": 6
  },
  {
    "id": 4475,
    "traditional": "大米",
    "simplified": "大米",
    "reading": "dàmǐ",
    "meaning": "(husked) rice",
    "grade": 6
  },
  {
    "id": 4476,
    "traditional": "大批",
    "simplified": "大批",
    "reading": "dàpī",
    "meaning": "large quantities of",
    "grade": 6
  },
  {
    "id": 4477,
    "traditional": "大賽",
    "simplified": "大赛",
    "reading": "dàsài",
    "meaning": "race, game, tournament, contest",
    "grade": 6
  },
  {
    "id": 4478,
    "traditional": "大師",
    "simplified": "大师",
    "reading": "dàshī",
    "meaning": "great master, lord, workmaster, artiste, Very Reverend, overlord, master, priest, maestro, non-Christian priest, Great Master",
    "grade": 6
  },
  {
    "id": 4479,
    "traditional": "大力",
    "simplified": "大力",
    "reading": "dàlì",
    "meaning": "vigorously, energetically",
    "grade": 6
  },
  {
    "id": 4480,
    "traditional": "大使",
    "simplified": "大使",
    "reading": "dàshǐ",
    "meaning": "elchee, embassador, ambassadorial, nuncio, ambassadorship, ambassador",
    "grade": 6
  },
  {
    "id": 4481,
    "traditional": "待會兒",
    "simplified": "待会儿",
    "reading": "dāihuir5",
    "meaning": "in a moment, later, Taiwan pr. [dai1 hui3 r5]",
    "grade": 6
  },
  {
    "id": 4482,
    "traditional": "擔憂",
    "simplified": "担忧",
    "reading": "dānyōu",
    "meaning": "be apprehensive",
    "grade": 6
  },
  {
    "id": 4483,
    "traditional": "誕生",
    "simplified": "诞生",
    "reading": "dànshēng",
    "meaning": "be born, come into being, emerge",
    "grade": 6
  },
  {
    "id": 4484,
    "traditional": "單打",
    "simplified": "单打",
    "reading": "dāndǎ",
    "meaning": "play by oneself",
    "grade": 6
  },
  {
    "id": 4485,
    "traditional": "黨",
    "simplified": "党",
    "reading": "dǎng",
    "meaning": "section, Dang, political party, CCP, KMT, Ku_Klux_Klan, clique, The Party, party, tong, kinsfolk, the Party, faction, gang",
    "grade": 6
  },
  {
    "id": 4486,
    "traditional": "當",
    "simplified": "当",
    "reading": "dāng",
    "meaning": "when, while",
    "grade": 6
  },
  {
    "id": 4487,
    "traditional": "當天",
    "simplified": "当天",
    "reading": "dāngtiān",
    "meaning": "on that day, the same day",
    "grade": 6
  },
  {
    "id": 4488,
    "traditional": "當成",
    "simplified": "当成",
    "reading": "dàngchéng",
    "meaning": "regard as, take for, consider as, regard/consider as, treat as, regard",
    "grade": 6
  },
  {
    "id": 4489,
    "traditional": "檔",
    "simplified": "档",
    "reading": "dàng",
    "meaning": "(Tw) variant of 擋|挡[dang3], gear, official records, grade (of goods), file, records, shelves, slot, gap, crosspiece, classifier for crosspieces, classifier for events, affairs etc, Taiwan pr. [dang3]",
    "grade": 6
  },
  {
    "id": 4490,
    "traditional": "當作",
    "simplified": "当作",
    "reading": "dàngzuò",
    "meaning": "regard_as, regard as, look upon as, treat as, presume",
    "grade": 6
  },
  {
    "id": 4491,
    "traditional": "檔案",
    "simplified": "档案",
    "reading": "dàngàn",
    "meaning": "data file, documentation, record, archive, archives, file, dossier",
    "grade": 6
  },
  {
    "id": 4492,
    "traditional": "到期",
    "simplified": "到期",
    "reading": "dào qī",
    "meaning": "to fall due (loan etc), to expire (visa etc), to mature (investment bond etc)",
    "grade": 6
  },
  {
    "id": 4493,
    "traditional": "盜版",
    "simplified": "盗版",
    "reading": "dào bǎn",
    "meaning": "pirated, illegal, see also 正版[zheng4 ban3]",
    "grade": 6
  },
  {
    "id": 4494,
    "traditional": "島",
    "simplified": "岛",
    "reading": "dǎo",
    "meaning": "island, isle",
    "grade": 6
  },
  {
    "id": 4495,
    "traditional": "道教",
    "simplified": "道教",
    "reading": "Dàojiào",
    "meaning": "Taoism, Daoism (Chinese system of beliefs)",
    "grade": 6
  },
  {
    "id": 4496,
    "traditional": "道歉",
    "simplified": "道歉",
    "reading": "dào qiàn",
    "meaning": "apologize",
    "grade": 6
  },
  {
    "id": 4497,
    "traditional": "低溫",
    "simplified": "低温",
    "reading": "dīwēn",
    "meaning": "low temperature",
    "grade": 6
  },
  {
    "id": 4498,
    "traditional": "低頭",
    "simplified": "低头",
    "reading": "dī tóu",
    "meaning": "submission, surrender, submit, bow/hang one's head, bow, crouch, lower, yield, hang one's head, bow one's head, lower one's head",
    "grade": 6
  },
  {
    "id": 4499,
    "traditional": "抵達",
    "simplified": "抵达",
    "reading": "dǐdá",
    "meaning": "reach, arrive at",
    "grade": 6
  },
  {
    "id": 4500,
    "traditional": "抵抗",
    "simplified": "抵抗",
    "reading": "dǐkàng",
    "meaning": "resist, stand up to",
    "grade": 6
  },
  {
    "id": 4501,
    "traditional": "地名",
    "simplified": "地名",
    "reading": "dìmíng",
    "meaning": "place name, toponym",
    "grade": 6
  },
  {
    "id": 4502,
    "traditional": "地板",
    "simplified": "地板",
    "reading": "dìbǎn",
    "meaning": "boarding, planking, hatch, plat, floorboard, hatchway, floor board, flooring, planch, floor, footplate",
    "grade": 6
  },
  {
    "id": 4503,
    "traditional": "地下室",
    "simplified": "地下室",
    "reading": "dìxiàshì",
    "meaning": "rumpus_room, soutane, undercroft, rooms built below ground, ground_floor, serdab, cellar, hypogeum, crypt, subterranean, silo, subterrane, souterrain, vault, cellarage, basement",
    "grade": 6
  },
  {
    "id": 4504,
    "traditional": "電動",
    "simplified": "电动",
    "reading": "diàndòng",
    "meaning": "electric",
    "grade": 6
  },
  {
    "id": 4505,
    "traditional": "滴",
    "simplified": "滴",
    "reading": "dī",
    "meaning": "a drop, to drip",
    "grade": 6
  },
  {
    "id": 4506,
    "traditional": "電車",
    "simplified": "电车",
    "reading": "diànchē",
    "meaning": "trolleybus, CL:輛|辆[liang4]",
    "grade": 6
  },
  {
    "id": 4507,
    "traditional": "電器",
    "simplified": "电器",
    "reading": "diànqì",
    "meaning": "electrical equipment/appliance",
    "grade": 6
  },
  {
    "id": 4508,
    "traditional": "電力",
    "simplified": "电力",
    "reading": "diànlì",
    "meaning": "electrical line of force, electrical energy, power, electricity, potential, electric power",
    "grade": 6
  },
  {
    "id": 4509,
    "traditional": "吊",
    "simplified": "吊",
    "reading": "diào",
    "meaning": "a string of 100 cash (arch.), to lament, to condole with, variant of 吊[diao4]",
    "grade": 6
  },
  {
    "id": 4510,
    "traditional": "調研",
    "simplified": "调研",
    "reading": "diàoyán",
    "meaning": "to investigate and research, research, investigation",
    "grade": 6
  },
  {
    "id": 4511,
    "traditional": "定價",
    "simplified": "定价",
    "reading": "dìngjià",
    "meaning": "to set a price, to fix a price",
    "grade": 6
  },
  {
    "id": 4512,
    "traditional": "定時",
    "simplified": "定时",
    "reading": "dìngshí",
    "meaning": "to fix a time, fixed time, timed (of explosive etc)",
    "grade": 6
  },
  {
    "id": 4513,
    "traditional": "跌",
    "simplified": "跌",
    "reading": "diē",
    "meaning": "fall, tumble",
    "grade": 6
  },
  {
    "id": 4514,
    "traditional": "定位",
    "simplified": "定位",
    "reading": "dìngwèi",
    "meaning": "fixed position",
    "grade": 6
  },
  {
    "id": 4515,
    "traditional": "鬥爭",
    "simplified": "斗争",
    "reading": "dòuzhēng",
    "meaning": "battle, fight",
    "grade": 6
  },
  {
    "id": 4516,
    "traditional": "動畫",
    "simplified": "动画",
    "reading": "dònghuà",
    "meaning": "(animated) cartoon",
    "grade": 6
  },
  {
    "id": 4517,
    "traditional": "毒品",
    "simplified": "毒品",
    "reading": "dúpǐn",
    "meaning": "kif, kaif, narcotics, drug, weed, grass, narcotic drugs, drugs",
    "grade": 6
  },
  {
    "id": 4518,
    "traditional": "賭",
    "simplified": "赌",
    "reading": "dǔ",
    "meaning": "gamble, bet",
    "grade": 6
  },
  {
    "id": 4519,
    "traditional": "賭博",
    "simplified": "赌博",
    "reading": "dǔbó",
    "meaning": "gamble",
    "grade": 6
  },
  {
    "id": 4520,
    "traditional": "端",
    "simplified": "端",
    "reading": "duān",
    "meaning": "old variant of 端[duan1], start, origin",
    "grade": 6
  },
  {
    "id": 4521,
    "traditional": "都市",
    "simplified": "都市",
    "reading": "dūshì",
    "meaning": "city, metropolis",
    "grade": 6
  },
  {
    "id": 4522,
    "traditional": "端午節",
    "simplified": "端午节",
    "reading": "Duānwǔjié",
    "meaning": "Dragon Boat Festival (5th day of the 5th lunar month)",
    "grade": 6
  },
  {
    "id": 4523,
    "traditional": "短片",
    "simplified": "短片",
    "reading": "duǎnpiàn",
    "meaning": "short film, video clip",
    "grade": 6
  },
  {
    "id": 4524,
    "traditional": "渡",
    "simplified": "渡",
    "reading": "dù",
    "meaning": "cross (river/sea/etc.), tide over, ferry across",
    "grade": 6
  },
  {
    "id": 4525,
    "traditional": "隊伍",
    "simplified": "队伍",
    "reading": "duìwu",
    "meaning": "cue, procession, contingent, ranks, troops, gang",
    "grade": 6
  },
  {
    "id": 4526,
    "traditional": "對外",
    "simplified": "对外",
    "reading": "duìwài",
    "meaning": "external, foreign, pertaining to external or foreign (affairs)",
    "grade": 6
  },
  {
    "id": 4527,
    "traditional": "對抗",
    "simplified": "对抗",
    "reading": "duìkàng",
    "meaning": "baulk, pit, combat, antagonise, encounter, counterwork, countervail, counteract, opposition, resistance, cope, breast, defend, fight, stem, play, battle, fight down, meet, fight back, antagonize, face, confront, front, oppose, contradict, controvert, balk, resist, jib, countercheck, take on, counterbalance, match",
    "grade": 6
  },
  {
    "id": 4528,
    "traditional": "多半",
    "simplified": "多半",
    "reading": "duōbàn",
    "meaning": "det : the greater part",
    "grade": 6
  },
  {
    "id": 4529,
    "traditional": "多方面",
    "simplified": "多方面",
    "reading": "duōfāngmiàn",
    "meaning": "many-sided, in many aspects",
    "grade": 6
  },
  {
    "id": 4530,
    "traditional": "多媒體",
    "simplified": "多媒体",
    "reading": "duōméitǐ",
    "meaning": "multi-media",
    "grade": 6
  },
  {
    "id": 4531,
    "traditional": "奪",
    "simplified": "夺",
    "reading": "duó",
    "meaning": "to seize, to take away forcibly, to wrest control of, to compete or strive for, to force one's way through, to leave out, to lose",
    "grade": 6
  },
  {
    "id": 4532,
    "traditional": "蹲",
    "simplified": "蹲",
    "reading": "dūn",
    "meaning": "squat on heels, stay",
    "grade": 6
  },
  {
    "id": 4533,
    "traditional": "奪取",
    "simplified": "夺取",
    "reading": "duóqǔ",
    "meaning": "to seize, to capture, to wrest control of",
    "grade": 6
  },
  {
    "id": 4534,
    "traditional": "恩人",
    "simplified": "恩人",
    "reading": "ēnrén",
    "meaning": "a benefactor, a person who has significantly helped sb else",
    "grade": 6
  },
  {
    "id": 4535,
    "traditional": "兒科",
    "simplified": "儿科",
    "reading": "érkē",
    "meaning": "pediatrics",
    "grade": 6
  },
  {
    "id": 4536,
    "traditional": "發病",
    "simplified": "发病",
    "reading": "fā bìng",
    "meaning": "onset, outbreak (of a disease)",
    "grade": 6
  },
  {
    "id": 4537,
    "traditional": "發電",
    "simplified": "发电",
    "reading": "fā diàn",
    "meaning": "electricity generation, generate electricity",
    "grade": 6
  },
  {
    "id": 4538,
    "traditional": "發放",
    "simplified": "发放",
    "reading": "fāfàng",
    "meaning": "provide, grant, extend",
    "grade": 6
  },
  {
    "id": 4539,
    "traditional": "發起",
    "simplified": "发起",
    "reading": "fāqǐ",
    "meaning": "initiate, commence, stage, instigate, sparkplug, sponsor, inauguraion, start, launch, promote, inaugurate, initiation",
    "grade": 6
  },
  {
    "id": 4540,
    "traditional": "發怒",
    "simplified": "发怒",
    "reading": "fā nù",
    "meaning": "to get angry",
    "grade": 6
  },
  {
    "id": 4541,
    "traditional": "發言人",
    "simplified": "发言人",
    "reading": "fāyánrén",
    "meaning": "voice, spokesman, representative, interpreter, prophet, addresser, spokesperson, prolocutor, addressor, fugleman, coryphaeus",
    "grade": 6
  },
  {
    "id": 4542,
    "traditional": "法庭",
    "simplified": "法庭",
    "reading": "fǎtíng",
    "meaning": "judicature, areopagy, court, courtroom, tribunal, banc, forum, gate, banco, lockup, lawcourt, bailey",
    "grade": 6
  },
  {
    "id": 4543,
    "traditional": "發炎",
    "simplified": "发炎",
    "reading": "fā yán",
    "meaning": "inflame, inflammation, inflamed",
    "grade": 6
  },
  {
    "id": 4544,
    "traditional": "番",
    "simplified": "番",
    "reading": "fān",
    "meaning": "m.[event]",
    "grade": 6
  },
  {
    "id": 4545,
    "traditional": "番茄",
    "simplified": "番茄",
    "reading": "fānqié",
    "meaning": "tomato",
    "grade": 6
  },
  {
    "id": 4546,
    "traditional": "凡是",
    "simplified": "凡是",
    "reading": "fánshì",
    "meaning": "conj.: whatever",
    "grade": 6
  },
  {
    "id": 4547,
    "traditional": "法語",
    "simplified": "法语",
    "reading": "Fǎyǔ",
    "meaning": "French (language)",
    "grade": 6
  },
  {
    "id": 4548,
    "traditional": "繁殖",
    "simplified": "繁殖",
    "reading": "fánzhí",
    "meaning": "breed, reproduce, propagate",
    "grade": 6
  },
  {
    "id": 4549,
    "traditional": "反問",
    "simplified": "反问",
    "reading": "fǎnwèn",
    "meaning": "to ask (a question) in reply, to answer a question with a question, rhetorical question",
    "grade": 6
  },
  {
    "id": 4550,
    "traditional": "反響",
    "simplified": "反响",
    "reading": "fǎnxiǎng",
    "meaning": "repercussions, reaction, echo",
    "grade": 6
  },
  {
    "id": 4551,
    "traditional": "犯規",
    "simplified": "犯规",
    "reading": "fàn guī",
    "meaning": "to break the rules, an illegality, a foul",
    "grade": 6
  },
  {
    "id": 4552,
    "traditional": "犯",
    "simplified": "犯",
    "reading": "fàn",
    "meaning": "violate, offend, attack, assail, work against, commit, have recurrence (of old illness), revert (to bad habit)",
    "grade": 6
  },
  {
    "id": 4553,
    "traditional": "反抗",
    "simplified": "反抗",
    "reading": "fǎnkàng",
    "meaning": "revolt, resist",
    "grade": 6
  },
  {
    "id": 4554,
    "traditional": "犯罪",
    "simplified": "犯罪",
    "reading": "fàn zuì",
    "meaning": "commit crime/offense",
    "grade": 6
  },
  {
    "id": 4555,
    "traditional": "防守",
    "simplified": "防守",
    "reading": "fángshǒu",
    "meaning": "to defend, to protect (against)",
    "grade": 6
  },
  {
    "id": 4556,
    "traditional": "房價",
    "simplified": "房价",
    "reading": "fángjià",
    "meaning": "house/apartment purchase price, house purchase price, apartment purchase price, house",
    "grade": 6
  },
  {
    "id": 4557,
    "traditional": "防範",
    "simplified": "防范",
    "reading": "fángfàn",
    "meaning": "be on guard, keep lookout",
    "grade": 6
  },
  {
    "id": 4558,
    "traditional": "仿佛",
    "simplified": "仿佛",
    "reading": "fǎngfú",
    "meaning": "to seem, as if, alike, similar",
    "grade": 6
  },
  {
    "id": 4559,
    "traditional": "飛船",
    "simplified": "飞船",
    "reading": "fēichuán",
    "meaning": "airship, spaceship",
    "grade": 6
  },
  {
    "id": 4560,
    "traditional": "飛行員",
    "simplified": "飞行员",
    "reading": "fēixíngyuán",
    "meaning": "pilot, aviator",
    "grade": 6
  },
  {
    "id": 4561,
    "traditional": "肺",
    "simplified": "肺",
    "reading": "fèi",
    "meaning": "lung, CL:個|个[ge4]",
    "grade": 6
  },
  {
    "id": 4562,
    "traditional": "分工",
    "simplified": "分工",
    "reading": "fēn gōng",
    "meaning": "division of labour, divide the work",
    "grade": 6
  },
  {
    "id": 4563,
    "traditional": "憤怒",
    "simplified": "愤怒",
    "reading": "fènnù",
    "meaning": "indignant, angry",
    "grade": 6
  },
  {
    "id": 4564,
    "traditional": "分裂",
    "simplified": "分裂",
    "reading": "fēnliè",
    "meaning": "split, divide, break up",
    "grade": 6
  },
  {
    "id": 4565,
    "traditional": "峰會",
    "simplified": "峰会",
    "reading": "fēnghuì",
    "meaning": "summit meeting",
    "grade": 6
  },
  {
    "id": 4566,
    "traditional": "奉獻",
    "simplified": "奉献",
    "reading": "fèngxiàn",
    "meaning": "offer as tribute, present with all respect",
    "grade": 6
  },
  {
    "id": 4567,
    "traditional": "風暴",
    "simplified": "风暴",
    "reading": "fēngbào",
    "meaning": "tempest, roughness, firestorm, windstorm, squall, blow, tempestuousness, storm",
    "grade": 6
  },
  {
    "id": 4568,
    "traditional": "佛教",
    "simplified": "佛教",
    "reading": "Fójiào",
    "meaning": "Bodhisattva, Buddhist religion, Buddhism",
    "grade": 6
  },
  {
    "id": 4569,
    "traditional": "佛",
    "simplified": "佛",
    "reading": "fú",
    "meaning": "Buddha, Buddhism, image of Buddha",
    "grade": 6
  },
  {
    "id": 4570,
    "traditional": "服",
    "simplified": "服",
    "reading": "fú",
    "meaning": "clothes, dress, garment, to serve (in the military, a prison sentence etc), to obey, to convince, to admire, to acclimatize, to take (medicine), mourning clothes, to wear mourning clothes, dose (measure word for medicine), Taiwan pr. [fu2]",
    "grade": 6
  },
  {
    "id": 4571,
    "traditional": "父女",
    "simplified": "父女",
    "reading": "fùnǚ",
    "meaning": "father and daughter",
    "grade": 6
  },
  {
    "id": 4572,
    "traditional": "父子",
    "simplified": "父子",
    "reading": "fùzǐ",
    "meaning": "father and son",
    "grade": 6
  },
  {
    "id": 4573,
    "traditional": "負",
    "simplified": "负",
    "reading": "fù",
    "meaning": "betray, abandon, go against",
    "grade": 6
  },
  {
    "id": 4574,
    "traditional": "浮",
    "simplified": "浮",
    "reading": "fú",
    "meaning": "to float, superficial, floating, unstable, movable, provisional, temporary, transient, impetuous, hollow, inflated, to exceed, superfluous, excessive, surplus",
    "grade": 6
  },
  {
    "id": 4575,
    "traditional": "婦女",
    "simplified": "妇女",
    "reading": "fùnǚ",
    "meaning": "feme, womankind, female, femininity, cummer, woman, femme, quean, bevy, matron, toots, womanhood, feminine, womenfolk, women",
    "grade": 6
  },
  {
    "id": 4576,
    "traditional": "副",
    "simplified": "副",
    "reading": "fù",
    "meaning": "m.[general]",
    "grade": 6
  },
  {
    "id": 4577,
    "traditional": "復蘇",
    "simplified": "复苏",
    "reading": "fùsū",
    "meaning": "variant of 復甦|复苏[fu4 su1], to recover (health, economic), to resuscitate, anabiosis",
    "grade": 6
  },
  {
    "id": 4578,
    "traditional": "富人",
    "simplified": "富人",
    "reading": "fùrén",
    "meaning": "rich man",
    "grade": 6
  },
  {
    "id": 4579,
    "traditional": "改裝",
    "simplified": "改装",
    "reading": "gǎizhuāng",
    "meaning": "to change one's costume, to repackage, to remodel, to refit, to modify, to convert",
    "grade": 6
  },
  {
    "id": 4580,
    "traditional": "干涉",
    "simplified": "干涉",
    "reading": "gānshè",
    "meaning": "interfere, intervene, meddle",
    "grade": 6
  },
  {
    "id": 4581,
    "traditional": "肝",
    "simplified": "肝",
    "reading": "gān",
    "meaning": "hepatic, liver",
    "grade": 6
  },
  {
    "id": 4582,
    "traditional": "富有",
    "simplified": "富有",
    "reading": "fùyǒu",
    "meaning": "be rich/wealthy",
    "grade": 6
  },
  {
    "id": 4583,
    "traditional": "杆",
    "simplified": "杆",
    "reading": "gān",
    "meaning": "pole, CL:條|条[tiao2],根[gen1], stick, pole, lever, classifier for long objects such as guns",
    "grade": 6
  },
  {
    "id": 4584,
    "traditional": "趕不上",
    "simplified": "赶不上",
    "reading": "gǎnbushàng",
    "meaning": "can't keep up with, can't catch up with, cannot overtake",
    "grade": 6
  },
  {
    "id": 4585,
    "traditional": "趕上",
    "simplified": "赶上",
    "reading": "gǎn shàng",
    "meaning": "to keep up with, to catch up with, to overtake, to chance upon, in time for",
    "grade": 6
  },
  {
    "id": 4586,
    "traditional": "趕忙",
    "simplified": "赶忙",
    "reading": "gǎnmáng",
    "meaning": "to hurry, to hasten, to make haste",
    "grade": 6
  },
  {
    "id": 4587,
    "traditional": "剛好",
    "simplified": "刚好",
    "reading": "gānghǎo",
    "meaning": "exact, just right",
    "grade": 6
  },
  {
    "id": 4588,
    "traditional": "感人",
    "simplified": "感人",
    "reading": "gǎnrén",
    "meaning": "touching, moving",
    "grade": 6
  },
  {
    "id": 4589,
    "traditional": "崗位",
    "simplified": "岗位",
    "reading": "gǎngwèi",
    "meaning": "post, station",
    "grade": 6
  },
  {
    "id": 4590,
    "traditional": "港口",
    "simplified": "港口",
    "reading": "gǎngkǒu",
    "meaning": "harbour, port, seaport, haven, harbor",
    "grade": 6
  },
  {
    "id": 4591,
    "traditional": "高層",
    "simplified": "高层",
    "reading": "gāocéng",
    "meaning": "high-level",
    "grade": 6
  },
  {
    "id": 4592,
    "traditional": "敢於",
    "simplified": "敢于",
    "reading": "gǎnyú",
    "meaning": "to have the courage to do sth, to dare to, bold in",
    "grade": 6
  },
  {
    "id": 4593,
    "traditional": "高檔",
    "simplified": "高档",
    "reading": "gāodàng",
    "meaning": "superior quality, high grade, top grade",
    "grade": 6
  },
  {
    "id": 4594,
    "traditional": "高峰",
    "simplified": "高峰",
    "reading": "gāofēng",
    "meaning": "summit, apex, alp, vertex, acme, peak, height, pinnacle, high, all-time high",
    "grade": 6
  },
  {
    "id": 4595,
    "traditional": "高等",
    "simplified": "高等",
    "reading": "gāoděng",
    "meaning": "higher, advanced",
    "grade": 6
  },
  {
    "id": 4596,
    "traditional": "高考",
    "simplified": "高考",
    "reading": "gāokǎo",
    "meaning": "college entrance exam (especially as abbr. for 普通高等學校招生全國統一考試|普通高等学校招生全国统一考试[Pu3 tong1 Gao1 deng3 Xue2 xiao4 Zhao1 sheng1 Quan2 guo2 Tong3 yi1 Kao3 shi4]), entrance exam for senior government service posts (Taiwan)",
    "grade": 6
  },
  {
    "id": 4597,
    "traditional": "高科技",
    "simplified": "高科技",
    "reading": "gāokējì",
    "meaning": "high technology, high tech",
    "grade": 6
  },
  {
    "id": 4598,
    "traditional": "稿子",
    "simplified": "稿子",
    "reading": "gǎozi",
    "meaning": "draft of a document, script, manuscript, mental plan, precedent",
    "grade": 6
  },
  {
    "id": 4599,
    "traditional": "高手",
    "simplified": "高手",
    "reading": "gāoshǒu",
    "meaning": "dab, master-hand, expert, master, facility, proficient, ace",
    "grade": 6
  },
  {
    "id": 4600,
    "traditional": "歌詞",
    "simplified": "歌词",
    "reading": "gēcí",
    "meaning": "words of song, lyrics",
    "grade": 6
  },
  {
    "id": 4601,
    "traditional": "歌星",
    "simplified": "歌星",
    "reading": "gēxīng",
    "meaning": "singing star",
    "grade": 6
  },
  {
    "id": 4602,
    "traditional": "革新",
    "simplified": "革新",
    "reading": "géxīn",
    "meaning": "innovate",
    "grade": 6
  },
  {
    "id": 4603,
    "traditional": "工商",
    "simplified": "工商",
    "reading": "gōngshāng",
    "meaning": "industrial and commercial circles, business circles, industry and commerce",
    "grade": 6
  },
  {
    "id": 4604,
    "traditional": "公",
    "simplified": "公",
    "reading": "gōng",
    "meaning": "public, collectively owned, common, international (e.g. high seas, metric system, calendar), make public, fair, just, Duke, highest of five orders of nobility 五等爵位[wu3 deng3 jue2 wei4], honorable (gentlemen), father-in-law, male (animal)",
    "grade": 6
  },
  {
    "id": 4605,
    "traditional": "更是",
    "simplified": "更是",
    "reading": "gèngshì",
    "meaning": "even more",
    "grade": 6
  },
  {
    "id": 4606,
    "traditional": "公雞",
    "simplified": "公鸡",
    "reading": "gōngjī",
    "meaning": "cock, rooster",
    "grade": 6
  },
  {
    "id": 4607,
    "traditional": "公眾",
    "simplified": "公众",
    "reading": "gōngzhòng",
    "meaning": "community, the public, masses, the people, public",
    "grade": 6
  },
  {
    "id": 4608,
    "traditional": "公安",
    "simplified": "公安",
    "reading": "gōngān",
    "meaning": "(Ministry of) Public Security, public safety, public security",
    "grade": 6
  },
  {
    "id": 4609,
    "traditional": "歌唱",
    "simplified": "歌唱",
    "reading": "gēchàng",
    "meaning": "sing",
    "grade": 6
  },
  {
    "id": 4610,
    "traditional": "攻擊",
    "simplified": "攻击",
    "reading": "gōngjī",
    "meaning": "attack, assault, accuse, vilify",
    "grade": 6
  },
  {
    "id": 4611,
    "traditional": "供給",
    "simplified": "供给",
    "reading": "gōngjǐ",
    "meaning": "supply, provide, furnish",
    "grade": 6
  },
  {
    "id": 4612,
    "traditional": "宮",
    "simplified": "宫",
    "reading": "gōng",
    "meaning": "surname Gong, palace, temple, castration (as corporal punishment), first note in pentatonic scale",
    "grade": 6
  },
  {
    "id": 4613,
    "traditional": "公主",
    "simplified": "公主",
    "reading": "gōngzhǔ",
    "meaning": "princess",
    "grade": 6
  },
  {
    "id": 4614,
    "traditional": "鞏固",
    "simplified": "巩固",
    "reading": "gǒnggù",
    "meaning": "to consolidate, consolidation, to strengthen",
    "grade": 6
  },
  {
    "id": 4615,
    "traditional": "貢獻",
    "simplified": "贡献",
    "reading": "gòngxiàn",
    "meaning": "contribution",
    "grade": 6
  },
  {
    "id": 4616,
    "traditional": "孤獨",
    "simplified": "孤独",
    "reading": "gūdú",
    "meaning": "lonely, solitary",
    "grade": 6
  },
  {
    "id": 4617,
    "traditional": "孤兒",
    "simplified": "孤儿",
    "reading": "gūér",
    "meaning": "orphan",
    "grade": 6
  },
  {
    "id": 4618,
    "traditional": "古典",
    "simplified": "古典",
    "reading": "gǔdiǎn",
    "meaning": "classical",
    "grade": 6
  },
  {
    "id": 4619,
    "traditional": "構建",
    "simplified": "构建",
    "reading": "gòujiàn",
    "meaning": "to construct (sth abstract)",
    "grade": 6
  },
  {
    "id": 4620,
    "traditional": "股",
    "simplified": "股",
    "reading": "gǔ",
    "meaning": "m.[general]",
    "grade": 6
  },
  {
    "id": 4621,
    "traditional": "姑姑",
    "simplified": "姑姑",
    "reading": "gūgu",
    "meaning": "paternal aunt, CL:個|个[ge4]",
    "grade": 6
  },
  {
    "id": 4622,
    "traditional": "股票",
    "simplified": "股票",
    "reading": "gǔpiào",
    "meaning": "capital stock, share, stock certificate, security, share certificate, stock",
    "grade": 6
  },
  {
    "id": 4623,
    "traditional": "故障",
    "simplified": "故障",
    "reading": "gùzhàng",
    "meaning": "malfunction, break",
    "grade": 6
  },
  {
    "id": 4624,
    "traditional": "顧",
    "simplified": "顾",
    "reading": "gù",
    "meaning": "turn round and look at, attend to, look after, take into consideration, visit, call on",
    "grade": 6
  },
  {
    "id": 4625,
    "traditional": "刮",
    "simplified": "刮",
    "reading": "guā",
    "meaning": "to blow (of the wind)",
    "grade": 6
  },
  {
    "id": 4626,
    "traditional": "拐",
    "simplified": "拐",
    "reading": "guǎi",
    "meaning": "to turn (a corner etc), to kidnap, to swindle, to misappropriate, walking stick, crutch, seven (used as a substitute for 七[qi1])",
    "grade": 6
  },
  {
    "id": 4627,
    "traditional": "關愛",
    "simplified": "关爱",
    "reading": "guānài",
    "meaning": "care for",
    "grade": 6
  },
  {
    "id": 4628,
    "traditional": "關聯",
    "simplified": "关联",
    "reading": "guānlián",
    "meaning": "related, linked, affiliated",
    "grade": 6
  },
  {
    "id": 4629,
    "traditional": "觀光",
    "simplified": "观光",
    "reading": "guānguāng",
    "meaning": "rubberneck, take in, visit, sightsee, travel to, go sightseeing, tour",
    "grade": 6
  },
  {
    "id": 4630,
    "traditional": "官司",
    "simplified": "官司",
    "reading": "guānsi",
    "meaning": "lawsuit, CL:場|场[chang2]",
    "grade": 6
  },
  {
    "id": 4631,
    "traditional": "管道",
    "simplified": "管道",
    "reading": "guǎndào",
    "meaning": "pipeline, conduit, piping, channel (for communication/etc.)",
    "grade": 6
  },
  {
    "id": 4632,
    "traditional": "廣闊",
    "simplified": "广阔",
    "reading": "guǎngkuò",
    "meaning": "wide, vast",
    "grade": 6
  },
  {
    "id": 4633,
    "traditional": "股東",
    "simplified": "股东",
    "reading": "gǔdōng",
    "meaning": "raider, shareholder, interest, old fogy, shareowner, curio, antique, partner, stockholder, interest group",
    "grade": 6
  },
  {
    "id": 4634,
    "traditional": "光輝",
    "simplified": "光辉",
    "reading": "guānghuī",
    "meaning": "radiancy, irradiancy, blaze, sparkle, fire, shininess, shine, resplendency, glory, glare, magnificence, sheen, lustre, grandeur, splendor, lucidity, coruscation, refulgence, grandness, burnish, brilliance, flame, glow, luminosity, glitter, luster, refulgency, radiance, irradiance, glowing, halo, resplendence, splendour, effulgence",
    "grade": 6
  },
  {
    "id": 4635,
    "traditional": "軌道",
    "simplified": "轨道",
    "reading": "guǐdào",
    "meaning": "circle, path, trajectory, railroad track, orbital, course, proper way of doing things, trackage, track, orbit, flight, rail, railway, trackway, tramroad, career, orb",
    "grade": 6
  },
  {
    "id": 4636,
    "traditional": "跪",
    "simplified": "跪",
    "reading": "guì",
    "meaning": "to kneel",
    "grade": 6
  },
  {
    "id": 4637,
    "traditional": "國產",
    "simplified": "国产",
    "reading": "guóchǎn",
    "meaning": "made in China, made in our country, make domestically",
    "grade": 6
  },
  {
    "id": 4638,
    "traditional": "國會",
    "simplified": "国会",
    "reading": "guóhuì",
    "meaning": "Storthing, Oireachtas, Cortes, Congress, congress, diet, the Diet, duma, congressional, Capitol, parliament, house",
    "grade": 6
  },
  {
    "id": 4639,
    "traditional": "國旗",
    "simplified": "国旗",
    "reading": "guóqí",
    "meaning": "flag, ensign, national flag",
    "grade": 6
  },
  {
    "id": 4640,
    "traditional": "國王",
    "simplified": "国王",
    "reading": "guówáng",
    "meaning": "regal, crowned_head, Rex, crown, kingdom, Pharaoh, male monarch, roi, king",
    "grade": 6
  },
  {
    "id": 4641,
    "traditional": "果醬",
    "simplified": "果酱",
    "reading": "guǒjiàng",
    "meaning": "jam",
    "grade": 6
  },
  {
    "id": 4642,
    "traditional": "果樹",
    "simplified": "果树",
    "reading": "guǒshù",
    "meaning": "fruit tree, CL:棵[ke1]",
    "grade": 6
  },
  {
    "id": 4643,
    "traditional": "國歌",
    "simplified": "国歌",
    "reading": "guógē",
    "meaning": "national anthem",
    "grade": 6
  },
  {
    "id": 4644,
    "traditional": "過後",
    "simplified": "过后",
    "reading": "guòhòu",
    "meaning": "after the event",
    "grade": 6
  },
  {
    "id": 4645,
    "traditional": "過渡",
    "simplified": "过渡",
    "reading": "guòdù",
    "meaning": "to cross over (by ferry), transition, interim, caretaker (administration)",
    "grade": 6
  },
  {
    "id": 4646,
    "traditional": "海報",
    "simplified": "海报",
    "reading": "hǎibào",
    "meaning": "throwaway, poster, bill, flier, flyer, broadside, a playbill, a placard, circular, playbill, placard, broadsheet, handbill, a poster",
    "grade": 6
  },
  {
    "id": 4647,
    "traditional": "過時",
    "simplified": "过时",
    "reading": "guò shí",
    "meaning": "old-fashioned, out of date, to be later than the time stipulated or agreed upon",
    "grade": 6
  },
  {
    "id": 4648,
    "traditional": "海浪",
    "simplified": "海浪",
    "reading": "hǎilàng",
    "meaning": "sea wave",
    "grade": 6
  },
  {
    "id": 4649,
    "traditional": "海外",
    "simplified": "海外",
    "reading": "hǎiwài",
    "meaning": "oversea, overseas, abroad",
    "grade": 6
  },
  {
    "id": 4650,
    "traditional": "海底",
    "simplified": "海底",
    "reading": "hǎidǐ",
    "meaning": "seabed, seafloor, bottom of the ocean",
    "grade": 6
  },
  {
    "id": 4651,
    "traditional": "海軍",
    "simplified": "海军",
    "reading": "hǎijūn",
    "meaning": "marine, naval, admiralty, jolly, navy",
    "grade": 6
  },
  {
    "id": 4652,
    "traditional": "海洋",
    "simplified": "海洋",
    "reading": "hǎiyáng",
    "meaning": "briny, profound, main, deep, sea, oceanic, Neptune, brine, nautical, ocean, seas and oceans",
    "grade": 6
  },
  {
    "id": 4653,
    "traditional": "海灣",
    "simplified": "海湾",
    "reading": "hǎiwān",
    "meaning": "(Persian) Gulf, bay, gulf (body of water)",
    "grade": 6
  },
  {
    "id": 4654,
    "traditional": "好轉",
    "simplified": "好转",
    "reading": "hǎozhuǎn",
    "meaning": "to improve, to take a turn for the better, improvement",
    "grade": 6
  },
  {
    "id": 4655,
    "traditional": "好容易",
    "simplified": "好容易",
    "reading": "hǎoróngyi",
    "meaning": "with great difficulty, to have a hard time (convincing sb, relinquishing sth etc)",
    "grade": 6
  },
  {
    "id": 4656,
    "traditional": "好學",
    "simplified": "好学",
    "reading": "hàoxué",
    "meaning": "eager to study, studious, erudite",
    "grade": 6
  },
  {
    "id": 4657,
    "traditional": "好似",
    "simplified": "好似",
    "reading": "hǎosì",
    "meaning": "to seem, to be like",
    "grade": 6
  },
  {
    "id": 4658,
    "traditional": "合約",
    "simplified": "合约",
    "reading": "héyuē",
    "meaning": "contract, booking, obligation, treaty",
    "grade": 6
  },
  {
    "id": 4659,
    "traditional": "核心",
    "simplified": "核心",
    "reading": "héxīn",
    "meaning": "nub, nucleus, meat, heart, core group, substance, center, core, the center, crux, essence, root, kernel, nitty-gritty, inwardness, pith, sum, marrow, bosom, gist",
    "grade": 6
  },
  {
    "id": 4660,
    "traditional": "黑夜",
    "simplified": "黑夜",
    "reading": "hēiyè",
    "meaning": "night",
    "grade": 6
  },
  {
    "id": 4661,
    "traditional": "和諧",
    "simplified": "和谐",
    "reading": "héxié",
    "meaning": "harmonious, concordant",
    "grade": 6
  },
  {
    "id": 4662,
    "traditional": "很難說",
    "simplified": "很难说",
    "reading": "hěn nánshuō",
    "meaning": "Hard to say",
    "grade": 6
  },
  {
    "id": 4663,
    "traditional": "橫",
    "simplified": "横",
    "reading": "héng",
    "meaning": "horizontal, across, (horizontal character stroke), harsh and unreasonable, unexpected",
    "grade": 6
  },
  {
    "id": 4664,
    "traditional": "衡量",
    "simplified": "衡量",
    "reading": "héngliang",
    "meaning": "weigh, measure, judge",
    "grade": 6
  },
  {
    "id": 4665,
    "traditional": "狠",
    "simplified": "狠",
    "reading": "hěn",
    "meaning": "fierce, very",
    "grade": 6
  },
  {
    "id": 4666,
    "traditional": "宏大",
    "simplified": "宏大",
    "reading": "hóngdà",
    "meaning": "great, grand",
    "grade": 6
  },
  {
    "id": 4667,
    "traditional": "洪水",
    "simplified": "洪水",
    "reading": "hóngshuǐ",
    "meaning": "deluge, flood",
    "grade": 6
  },
  {
    "id": 4668,
    "traditional": "互動",
    "simplified": "互动",
    "reading": "hùdòng",
    "meaning": "relate",
    "grade": 6
  },
  {
    "id": 4669,
    "traditional": "忽略",
    "simplified": "忽略",
    "reading": "hūlüè",
    "meaning": "overpass, fail, pretermit, outlook, pass, overlook, neglectful, snub, cut, neglect, marginalize, miss, lose sight of, disregard, slur, ignore, forget",
    "grade": 6
  },
  {
    "id": 4670,
    "traditional": "戶外",
    "simplified": "户外",
    "reading": "hùwài",
    "meaning": "air, outdoor, open_air, out-of-doors, open, outdoors, open air, open-air",
    "grade": 6
  },
  {
    "id": 4671,
    "traditional": "護",
    "simplified": "护",
    "reading": "hù",
    "meaning": "to protect",
    "grade": 6
  },
  {
    "id": 4672,
    "traditional": "花瓶",
    "simplified": "花瓶",
    "reading": "huāpíng",
    "meaning": "flower vase, fig. just a pretty face, CL:對|对[dui4]",
    "grade": 6
  },
  {
    "id": 4673,
    "traditional": "花費",
    "simplified": "花费",
    "reading": "huāfei",
    "meaning": "spend, expend",
    "grade": 6
  },
  {
    "id": 4674,
    "traditional": "花生",
    "simplified": "花生",
    "reading": "huāshēng",
    "meaning": "peanut, groundnut, CL:粒[li4]",
    "grade": 6
  },
  {
    "id": 4675,
    "traditional": "化解",
    "simplified": "化解",
    "reading": "huàjiě",
    "meaning": "remove",
    "grade": 6
  },
  {
    "id": 4676,
    "traditional": "幻想",
    "simplified": "幻想",
    "reading": "huànxiǎng",
    "meaning": "imagine, daydream, reverie, stargaze, fantasticate, fancify, dream, visionary, fancy, illusion, fantasize, chimerical, woolgather, illusory, fantasy",
    "grade": 6
  },
  {
    "id": 4677,
    "traditional": "壺",
    "simplified": "壶",
    "reading": "hú",
    "meaning": "pot, classifier for bottled liquid",
    "grade": 6
  },
  {
    "id": 4678,
    "traditional": "患者",
    "simplified": "患者",
    "reading": "huànzhě",
    "meaning": "patient, subject, sufferer",
    "grade": 6
  },
  {
    "id": 4679,
    "traditional": "回應",
    "simplified": "回应",
    "reading": "huíyìng",
    "meaning": "echo, react, answer, respond",
    "grade": 6
  },
  {
    "id": 4680,
    "traditional": "皇帝",
    "simplified": "皇帝",
    "reading": "huángdì",
    "meaning": "emperor",
    "grade": 6
  },
  {
    "id": 4681,
    "traditional": "會見",
    "simplified": "会见",
    "reading": "huìjiàn",
    "meaning": "to meet with (sb who is paying a visit), CL:次[ci4]",
    "grade": 6
  },
  {
    "id": 4682,
    "traditional": "會長",
    "simplified": "会长",
    "reading": "huìzhǎng",
    "meaning": "president (of club/society/etc.)",
    "grade": 6
  },
  {
    "id": 4683,
    "traditional": "毀",
    "simplified": "毁",
    "reading": "huǐ",
    "meaning": "destruction, ruin, slay, damage, slander, spoil, bugger up, burn up, refashion, make over, destroy, defame, demolish",
    "grade": 6
  },
  {
    "id": 4684,
    "traditional": "混",
    "simplified": "混",
    "reading": "hùn",
    "meaning": "fool/play around together",
    "grade": 6
  },
  {
    "id": 4685,
    "traditional": "繪畫",
    "simplified": "绘画",
    "reading": "huìhuà",
    "meaning": "painting",
    "grade": 6
  },
  {
    "id": 4686,
    "traditional": "混亂",
    "simplified": "混乱",
    "reading": "hùnluàn",
    "meaning": "confused, chaotic",
    "grade": 6
  },
  {
    "id": 4687,
    "traditional": "混合",
    "simplified": "混合",
    "reading": "hùnhé",
    "meaning": "associate, immingle, muddle, incorporate, mixed, meld, confect, amalgamate, blend in, fuse, interblend, hybrid, admix, mingle, wuzzle, mix, shuffle, mercurify, engraft, concoct, interlace, interweave, immix, go, interfuse, mix_up, sophisticate, marry, combine, mix in, conflate, merge, intermix, jumble, commingle, decompound, intermingle, knead, coalesce, interflow, compound, commix, blend,",
    "grade": 6
  },
  {
    "id": 4688,
    "traditional": "活躍",
    "simplified": "活跃",
    "reading": "huóyuè",
    "meaning": "animate, kick around, active, invigoration, knock_about, cavort, enliven, vitalize, knock about, energize, flourish, invigorate, brighten, kick about, vitalization, vivify, move",
    "grade": 6
  },
  {
    "id": 4689,
    "traditional": "火箭",
    "simplified": "火箭",
    "reading": "huǒjiàn",
    "meaning": "rocket, CL:枚[mei2]",
    "grade": 6
  },
  {
    "id": 4690,
    "traditional": "機動車",
    "simplified": "机动车",
    "reading": "jīdòngchē",
    "meaning": "motor vehicle",
    "grade": 6
  },
  {
    "id": 4691,
    "traditional": "昏",
    "simplified": "昏",
    "reading": "hūn",
    "meaning": "muddle-headed, twilight, to faint, to lose consciousness, old variant of 昏[hun1]",
    "grade": 6
  },
  {
    "id": 4692,
    "traditional": "機關",
    "simplified": "机关",
    "reading": "jīguān",
    "meaning": "mechanism, stratagem, scheme, intrigue",
    "grade": 6
  },
  {
    "id": 4693,
    "traditional": "基督教",
    "simplified": "基督教",
    "reading": "Jīdūjiào",
    "meaning": "Christian religion, Christianity, Christendom, Christianism, Christian",
    "grade": 6
  },
  {
    "id": 4694,
    "traditional": "機械",
    "simplified": "机械",
    "reading": "jīxiè",
    "meaning": "mechanics, enginery, rig, machinery, mechanism, machine, appliance, mechanical",
    "grade": 6
  },
  {
    "id": 4695,
    "traditional": "激情",
    "simplified": "激情",
    "reading": "jīqíng",
    "meaning": "passion, fervor, enthusiasm, strong emotion",
    "grade": 6
  },
  {
    "id": 4696,
    "traditional": "吉祥",
    "simplified": "吉祥",
    "reading": "jíxiáng",
    "meaning": "lucky, auspicious, propitious",
    "grade": 6
  },
  {
    "id": 4697,
    "traditional": "極端",
    "simplified": "极端",
    "reading": "jíduān",
    "meaning": "extreme, exceeding",
    "grade": 6
  },
  {
    "id": 4698,
    "traditional": "急救",
    "simplified": "急救",
    "reading": "jíjiù",
    "meaning": "first aid, emergency treatment",
    "grade": 6
  },
  {
    "id": 4699,
    "traditional": "集",
    "simplified": "集",
    "reading": "jí",
    "meaning": "gather, collect",
    "grade": 6
  },
  {
    "id": 4700,
    "traditional": "疾病",
    "simplified": "疾病",
    "reading": "jíbìng",
    "meaning": "trouble, attack, evil, ailment, affection, misery, malfunction, complaint, infirmity, disease, ill, illness, malady, decline, sickness, condition",
    "grade": 6
  },
  {
    "id": 4701,
    "traditional": "加盟",
    "simplified": "加盟",
    "reading": "jiāméng",
    "meaning": "align",
    "grade": 6
  },
  {
    "id": 4702,
    "traditional": "吉利",
    "simplified": "吉利",
    "reading": "jílì",
    "meaning": "Geely, Chinese car make, auspicious, lucky, propitious",
    "grade": 6
  },
  {
    "id": 4703,
    "traditional": "家電",
    "simplified": "家电",
    "reading": "jiādiàn",
    "meaning": "electrical household appliance, electrical home appliance, household electrical appliances",
    "grade": 6
  },
  {
    "id": 4704,
    "traditional": "家園",
    "simplified": "家园",
    "reading": "jiāyuán",
    "meaning": "home, homestead, native heath, homestall, homeland",
    "grade": 6
  },
  {
    "id": 4705,
    "traditional": "嘉賓",
    "simplified": "嘉宾",
    "reading": "jiābīn",
    "meaning": "esteemed guest, honored guest, guest (on a show)",
    "grade": 6
  },
  {
    "id": 4706,
    "traditional": "給予",
    "simplified": "给予",
    "reading": "jǐyǔ",
    "meaning": "bring, feed, offer, afford, hold_out, impart, adduce, lend, grant, contribute, given, concede, give to, give, bestow, add, put, render, deal, ladle, administer, assign, give_in, allow",
    "grade": 6
  },
  {
    "id": 4707,
    "traditional": "假日",
    "simplified": "假日",
    "reading": "jiàrì",
    "meaning": "holiday, non-working day",
    "grade": 6
  },
  {
    "id": 4708,
    "traditional": "監督",
    "simplified": "监督",
    "reading": "jiāndū",
    "meaning": "supervise, superintend, control",
    "grade": 6
  },
  {
    "id": 4709,
    "traditional": "監測",
    "simplified": "监测",
    "reading": "jiāncè",
    "meaning": "monitor, supervise",
    "grade": 6
  },
  {
    "id": 4710,
    "traditional": "撿",
    "simplified": "捡",
    "reading": "jiǎn",
    "meaning": "to pick up, to collect, to gather",
    "grade": 6
  },
  {
    "id": 4711,
    "traditional": "簡介",
    "simplified": "简介",
    "reading": "jiǎnjiè",
    "meaning": "brief introduction, synopsis",
    "grade": 6
  },
  {
    "id": 4712,
    "traditional": "劍",
    "simplified": "剑",
    "reading": "jiàn",
    "meaning": "sword, skewer, fox, sabre, steel, snickersnee, brand, bilbo, saber, blade, glaive, falchion",
    "grade": 6
  },
  {
    "id": 4713,
    "traditional": "鑒定",
    "simplified": "鉴定",
    "reading": "jiàndìng",
    "meaning": "to appraise, to identify, to evaluate",
    "grade": 6
  },
  {
    "id": 4714,
    "traditional": "箭",
    "simplified": "箭",
    "reading": "jiàn",
    "meaning": "arrow, CL:支[zhi1]",
    "grade": 6
  },
  {
    "id": 4715,
    "traditional": "將軍",
    "simplified": "将军",
    "reading": "jiāngjūn",
    "meaning": "problem, mate, general, high-rank officer, marshal, full general, shogun, aga, warlord, tycoon, check",
    "grade": 6
  },
  {
    "id": 4716,
    "traditional": "講課",
    "simplified": "讲课",
    "reading": "jiǎng kè",
    "meaning": "teach, lecture",
    "grade": 6
  },
  {
    "id": 4717,
    "traditional": "醬",
    "simplified": "酱",
    "reading": "jiàng",
    "meaning": "thick paste of fermented soybean, marinated in soy paste, paste, jam",
    "grade": 6
  },
  {
    "id": 4718,
    "traditional": "驕傲",
    "simplified": "骄傲",
    "reading": "jiāoào",
    "meaning": "be proud, take pride in",
    "grade": 6
  },
  {
    "id": 4719,
    "traditional": "醬油",
    "simplified": "酱油",
    "reading": "jiàngyóu",
    "meaning": "soy sauce",
    "grade": 6
  },
  {
    "id": 4720,
    "traditional": "焦點",
    "simplified": "焦点",
    "reading": "jiāodiǎn",
    "meaning": "focal point, point at issue, focal, focus, focal_point, central issue",
    "grade": 6
  },
  {
    "id": 4721,
    "traditional": "尖",
    "simplified": "尖",
    "reading": "jiān",
    "meaning": "sharp-pointed, piercing, cuspidal, shrill, sharp",
    "grade": 6
  },
  {
    "id": 4722,
    "traditional": "腳印",
    "simplified": "脚印",
    "reading": "jiǎoyìn",
    "meaning": "footprint",
    "grade": 6
  },
  {
    "id": 4723,
    "traditional": "教堂",
    "simplified": "教堂",
    "reading": "jiàotáng",
    "meaning": "tabernacle, kirk, house_of_God, church, temple, fane, cathedral, church building, bema",
    "grade": 6
  },
  {
    "id": 4724,
    "traditional": "教育部",
    "simplified": "教育部",
    "reading": "Jiàoyùbù",
    "meaning": "Education, Ministry of Education, Department of Education, Education Department",
    "grade": 6
  },
  {
    "id": 4725,
    "traditional": "接收",
    "simplified": "接收",
    "reading": "jiēshōu",
    "meaning": "receive, take over (property/etc.)",
    "grade": 6
  },
  {
    "id": 4726,
    "traditional": "街頭",
    "simplified": "街头",
    "reading": "jiētóu",
    "meaning": "street corner, street_corner, street (corner), street",
    "grade": 6
  },
  {
    "id": 4727,
    "traditional": "覺",
    "simplified": "觉",
    "reading": "jué",
    "meaning": "sense, feel, wake (up), become aware/awakened, discover",
    "grade": 6
  },
  {
    "id": 4728,
    "traditional": "節",
    "simplified": "节",
    "reading": "jié",
    "meaning": "m.[event]",
    "grade": 6
  },
  {
    "id": 4729,
    "traditional": "節假日",
    "simplified": "节假日",
    "reading": "jiéjiàrì",
    "meaning": "public holiday",
    "grade": 6
  },
  {
    "id": 4730,
    "traditional": "節能",
    "simplified": "节能",
    "reading": "jié néng",
    "meaning": "to save energy, energy-saving",
    "grade": 6
  },
  {
    "id": 4731,
    "traditional": "傑出",
    "simplified": "杰出",
    "reading": "jiéchū",
    "meaning": "excel at, exceed, shine at, outstanding, stand_out",
    "grade": 6
  },
  {
    "id": 4732,
    "traditional": "揭",
    "simplified": "揭",
    "reading": "jiē",
    "meaning": "to take the lid off, to expose, to unmask",
    "grade": 6
  },
  {
    "id": 4733,
    "traditional": "截止",
    "simplified": "截止",
    "reading": "jiézhǐ",
    "meaning": "end, cut off, expire, close",
    "grade": 6
  },
  {
    "id": 4734,
    "traditional": "解",
    "simplified": "解",
    "reading": "jiě",
    "meaning": "separate, divide, cut apart, untie, undo, explain, interpret, dissolve",
    "grade": 6
  },
  {
    "id": 4735,
    "traditional": "解說",
    "simplified": "解说",
    "reading": "jiěshuō",
    "meaning": "explain orally, appease",
    "grade": 6
  },
  {
    "id": 4736,
    "traditional": "截至",
    "simplified": "截至",
    "reading": "jiézhì",
    "meaning": "by (specified time), up to",
    "grade": 6
  },
  {
    "id": 4737,
    "traditional": "界",
    "simplified": "界",
    "reading": "jiè",
    "meaning": "boundary, scope, extent, circles, group, kingdom (taxonomy)",
    "grade": 6
  },
  {
    "id": 4738,
    "traditional": "金額",
    "simplified": "金额",
    "reading": "jīné",
    "meaning": "amount of money, sum_of_money, sum, money, penny, amount/sum of money, sum of money, amount",
    "grade": 6
  },
  {
    "id": 4739,
    "traditional": "節奏",
    "simplified": "节奏",
    "reading": "jiézòu",
    "meaning": "pacing, cadency, musical rhythm, pulse, speech rhythm, rhythm, regular recurrence, pulsation, beat, pace, movement, tune, cadence, clip, tempo",
    "grade": 6
  },
  {
    "id": 4740,
    "traditional": "借鑒",
    "simplified": "借鉴",
    "reading": "jièjiàn",
    "meaning": "to use other people's experience, to borrow from a source, to use as reference",
    "grade": 6
  },
  {
    "id": 4741,
    "traditional": "金錢",
    "simplified": "金钱",
    "reading": "jīnqián",
    "meaning": "pewter, splosh, green, Oscar, siller, gelt, tin, coin, roll, dollar sign, funds, dollar mark, shekels, wealth, lucre, pecuniary, dinero, bankroll, dough, scratch, bread, means, moolah, shekel, shiner, cabbage, kale, spondulicks, pelf, oof, money, loot, wampum, brass, dollar",
    "grade": 6
  },
  {
    "id": 4742,
    "traditional": "儘",
    "simplified": "尽",
    "reading": "jǐn",
    "meaning": "use up, exhaust, try one's best, put to best use, end",
    "grade": 6
  },
  {
    "id": 4743,
    "traditional": "進攻",
    "simplified": "进攻",
    "reading": "jìngōng",
    "meaning": "attack, assault",
    "grade": 6
  },
  {
    "id": 4744,
    "traditional": "近日",
    "simplified": "近日",
    "reading": "jìnrì",
    "meaning": "in the past few days, recently, in the last few days",
    "grade": 6
  },
  {
    "id": 4745,
    "traditional": "近視",
    "simplified": "近视",
    "reading": "jìnshì",
    "meaning": "shortsighted, nearsighted, myopia",
    "grade": 6
  },
  {
    "id": 4746,
    "traditional": "驚人",
    "simplified": "惊人",
    "reading": "jīngrén",
    "meaning": "alarming, whacking, astounding, spectacular, amazing, astonishing",
    "grade": 6
  },
  {
    "id": 4747,
    "traditional": "精",
    "simplified": "精",
    "reading": "jīng",
    "meaning": "essence, extract, vitality, energy, semen, sperm, mythical goblin spirit, highly perfected, elite, the pick of sth, proficient (refined ability), extremely (fine), selected rice (archaic)",
    "grade": 6
  },
  {
    "id": 4748,
    "traditional": "精美",
    "simplified": "精美",
    "reading": "jīngměi",
    "meaning": "exquisite, elegant",
    "grade": 6
  },
  {
    "id": 4749,
    "traditional": "精品",
    "simplified": "精品",
    "reading": "jīngpǐn",
    "meaning": "plum, showpiece, fine work, quality goods, top quality article, article of fine quality",
    "grade": 6
  },
  {
    "id": 4750,
    "traditional": "井",
    "simplified": "井",
    "reading": "jǐng",
    "meaning": "Jing, well, Surname, something in the shape of a well",
    "grade": 6
  },
  {
    "id": 4751,
    "traditional": "驚喜",
    "simplified": "惊喜",
    "reading": "jīngxǐ",
    "meaning": "nice surprise, to be pleasantly surprised",
    "grade": 6
  },
  {
    "id": 4752,
    "traditional": "金融",
    "simplified": "金融",
    "reading": "jīnróng",
    "meaning": "finance, banking",
    "grade": 6
  },
  {
    "id": 4753,
    "traditional": "景點",
    "simplified": "景点",
    "reading": "jǐngdiǎn",
    "meaning": "scenic spot, place of interest (tourism)",
    "grade": 6
  },
  {
    "id": 4754,
    "traditional": "糾紛",
    "simplified": "纠纷",
    "reading": "jiūfēn",
    "meaning": "entanglement, embarrassment, cobweb, skein, embroilment, dissension, complication, issue, conflict, confusion, dispute, shindig, knottiness, dustup, aggro, tangle, betanglement, maze, imbroglio, strife",
    "grade": 6
  },
  {
    "id": 4755,
    "traditional": "糾正",
    "simplified": "纠正",
    "reading": "jiūzhèng",
    "meaning": "correct, redress",
    "grade": 6
  },
  {
    "id": 4756,
    "traditional": "淨",
    "simplified": "净",
    "reading": "jìng",
    "meaning": "clean, completely, only, net (income, exports etc), (Chinese opera) painted face male role",
    "grade": 6
  },
  {
    "id": 4757,
    "traditional": "酒水",
    "simplified": "酒水",
    "reading": "jiǔshuǐ",
    "meaning": "beverage, a drink",
    "grade": 6
  },
  {
    "id": 4758,
    "traditional": "救命",
    "simplified": "救命",
    "reading": "jiù mìng",
    "meaning": "to save sb's life, (interj.) Help!, Save me!",
    "grade": 6
  },
  {
    "id": 4759,
    "traditional": "救援",
    "simplified": "救援",
    "reading": "jiùyuán",
    "meaning": "to save, to support, to help, to assist",
    "grade": 6
  },
  {
    "id": 4760,
    "traditional": "救助",
    "simplified": "救助",
    "reading": "jiùzhù",
    "meaning": "to help sb in trouble, aid, assistance",
    "grade": 6
  },
  {
    "id": 4761,
    "traditional": "景",
    "simplified": "景",
    "reading": "jǐng",
    "meaning": "view, Jing, shot, routine, bit, act, number, situation, scene, scenery, Surname, turn, condition",
    "grade": 6
  },
  {
    "id": 4762,
    "traditional": "就算",
    "simplified": "就算",
    "reading": "jiùsuàn",
    "meaning": "conj.: even if, granted that",
    "grade": 6
  },
  {
    "id": 4763,
    "traditional": "就是說",
    "simplified": "就是说",
    "reading": "jiùshìshuō",
    "meaning": "that is to say, in other words, namely",
    "grade": 6
  },
  {
    "id": 4764,
    "traditional": "據",
    "simplified": "据",
    "reading": "jù",
    "meaning": "according to, on the grounds of",
    "grade": 6
  },
  {
    "id": 4765,
    "traditional": "捐",
    "simplified": "捐",
    "reading": "juān",
    "meaning": "donation, subscription, relinquish, abandonment, subscribe, contribution, donate, abandon, contribute",
    "grade": 6
  },
  {
    "id": 4766,
    "traditional": "局",
    "simplified": "局",
    "reading": "jú",
    "meaning": "m.[event]",
    "grade": 6
  },
  {
    "id": 4767,
    "traditional": "捐款",
    "simplified": "捐款",
    "reading": "juānkuǎn",
    "meaning": "to donate money, to contribute funds, donation, contribution (of money)",
    "grade": 6
  },
  {
    "id": 4768,
    "traditional": "捐贈",
    "simplified": "捐赠",
    "reading": "juānzèng",
    "meaning": "contribute (as gift), donate, present",
    "grade": 6
  },
  {
    "id": 4769,
    "traditional": "捐助",
    "simplified": "捐助",
    "reading": "juānzhù",
    "meaning": "to donate, to offer (aid), contribution, donation",
    "grade": 6
  },
  {
    "id": 4770,
    "traditional": "劇",
    "simplified": "剧",
    "reading": "jù",
    "meaning": "theatrical work (play, opera, TV series etc), dramatic (change, increase etc), acute, severe",
    "grade": 6
  },
  {
    "id": 4771,
    "traditional": "決策",
    "simplified": "决策",
    "reading": "juécè",
    "meaning": "policy decision",
    "grade": 6
  },
  {
    "id": 4772,
    "traditional": "覺悟",
    "simplified": "觉悟",
    "reading": "juéwù",
    "meaning": "to come to understand, to realize, consciousness, awareness, Buddhist enlightenment (Sanskrit: cittotpāda)",
    "grade": 6
  },
  {
    "id": 4773,
    "traditional": "絕",
    "simplified": "绝",
    "reading": "jué",
    "meaning": "extremely, most, absolutely, in the least, by any means, on any account",
    "grade": 6
  },
  {
    "id": 4774,
    "traditional": "絕大多數",
    "simplified": "绝大多数",
    "reading": "juédàduōshù",
    "meaning": "det.: most of, majority",
    "grade": 6
  },
  {
    "id": 4775,
    "traditional": "軍艦",
    "simplified": "军舰",
    "reading": "jūnjiàn",
    "meaning": "warship, military naval vessel, CL:艘[sou1]",
    "grade": 6
  },
  {
    "id": 4776,
    "traditional": "軍事",
    "simplified": "军事",
    "reading": "jūnshì",
    "meaning": "war, martial, military affairs, legion, military",
    "grade": 6
  },
  {
    "id": 4777,
    "traditional": "軍隊",
    "simplified": "军队",
    "reading": "jūnduì",
    "meaning": "military personnel, troops, armed forces, armament, military, soldiery, force, Sabaoth, array, army troops, military unit, host, military force, cohort, battalion, legion, troop, regular army, army, armed_forces, rank",
    "grade": 6
  },
  {
    "id": 4778,
    "traditional": "開關",
    "simplified": "开关",
    "reading": "kāiguān",
    "meaning": "power switch, to open a gate",
    "grade": 6
  },
  {
    "id": 4779,
    "traditional": "開創",
    "simplified": "开创",
    "reading": "kāichuàng",
    "meaning": "start, initiate",
    "grade": 6
  },
  {
    "id": 4780,
    "traditional": "開通",
    "simplified": "开通",
    "reading": "kāitong",
    "meaning": "to open up (windows for air, ideas for discussion, transport routes etc), open-minded",
    "grade": 6
  },
  {
    "id": 4781,
    "traditional": "開設",
    "simplified": "开设",
    "reading": "kāishè",
    "meaning": "open (shop/etc.), offer (course/etc.)",
    "grade": 6
  },
  {
    "id": 4782,
    "traditional": "開夜車",
    "simplified": "开夜车",
    "reading": "kāi yèchē",
    "meaning": "to burn the midnight oil, to work late into the night",
    "grade": 6
  },
  {
    "id": 4783,
    "traditional": "看得見",
    "simplified": "看得见",
    "reading": "kàndéjiàn",
    "meaning": "can see, visible",
    "grade": 6
  },
  {
    "id": 4784,
    "traditional": "開頭",
    "simplified": "开头",
    "reading": "kāitóu",
    "meaning": "beginning, to start",
    "grade": 6
  },
  {
    "id": 4785,
    "traditional": "看",
    "simplified": "看",
    "reading": "kàn",
    "meaning": "see, look at, watch, read, look upon, regard, look after, call on, visit",
    "grade": 6
  },
  {
    "id": 4786,
    "traditional": "看管",
    "simplified": "看管",
    "reading": "kānguǎn",
    "meaning": "to look after",
    "grade": 6
  },
  {
    "id": 4787,
    "traditional": "看得起",
    "simplified": "看得起",
    "reading": "kàndeqǐ",
    "meaning": "to show respect for, to think highly of",
    "grade": 6
  },
  {
    "id": 4788,
    "traditional": "看作",
    "simplified": "看作",
    "reading": "kànzuò",
    "meaning": "to look upon as, to regard as",
    "grade": 6
  },
  {
    "id": 4789,
    "traditional": "康復",
    "simplified": "康复",
    "reading": "kāngfù",
    "meaning": "to recuperate, to recover (health), to convalesce",
    "grade": 6
  },
  {
    "id": 4790,
    "traditional": "看好",
    "simplified": "看好",
    "reading": "kànhǎo",
    "meaning": "believe something/someone will be worthy in the future",
    "grade": 6
  },
  {
    "id": 4791,
    "traditional": "考場",
    "simplified": "考场",
    "reading": "kǎochǎng",
    "meaning": "exam room",
    "grade": 6
  },
  {
    "id": 4792,
    "traditional": "抗議",
    "simplified": "抗议",
    "reading": "kàngyì",
    "meaning": "protest",
    "grade": 6
  },
  {
    "id": 4793,
    "traditional": "科研",
    "simplified": "科研",
    "reading": "kēyán",
    "meaning": "(scientific) research",
    "grade": 6
  },
  {
    "id": 4794,
    "traditional": "客車",
    "simplified": "客车",
    "reading": "kèchē",
    "meaning": "coach, bus, passenger train",
    "grade": 6
  },
  {
    "id": 4795,
    "traditional": "考題",
    "simplified": "考题",
    "reading": "kǎotí",
    "meaning": "exam question",
    "grade": 6
  },
  {
    "id": 4796,
    "traditional": "空軍",
    "simplified": "空军",
    "reading": "kōngjūn",
    "meaning": "airforce, air_force, Luftwaffe, air force, squadron",
    "grade": 6
  },
  {
    "id": 4797,
    "traditional": "肯",
    "simplified": "肯",
    "reading": "kěn",
    "meaning": "be willing/ready to",
    "grade": 6
  },
  {
    "id": 4798,
    "traditional": "口試",
    "simplified": "口试",
    "reading": "kǒushì",
    "meaning": "oral examination, oral test",
    "grade": 6
  },
  {
    "id": 4799,
    "traditional": "扣",
    "simplified": "扣",
    "reading": "kòu",
    "meaning": "button",
    "grade": 6
  },
  {
    "id": 4800,
    "traditional": "跨",
    "simplified": "跨",
    "reading": "kuà",
    "meaning": "lead, go, bestride, straddle, go beyond, run, pass, extend, stride, ride, cut across, step",
    "grade": 6
  },
  {
    "id": 4801,
    "traditional": "快車",
    "simplified": "快车",
    "reading": "kuàichē",
    "meaning": "express (train, bus etc)",
    "grade": 6
  },
  {
    "id": 4802,
    "traditional": "酷",
    "simplified": "酷",
    "reading": "kù",
    "meaning": "ruthless, strong (e.g. of wine), (loanword) cool, hip",
    "grade": 6
  },
  {
    "id": 4803,
    "traditional": "礦",
    "simplified": "矿",
    "reading": "kuàng",
    "meaning": "ore, minevariant of 礦|矿[kuang4]",
    "grade": 6
  },
  {
    "id": 4804,
    "traditional": "寬闊",
    "simplified": "宽阔",
    "reading": "kuānkuò",
    "meaning": "expansive, wide, width, thickness",
    "grade": 6
  },
  {
    "id": 4805,
    "traditional": "闊",
    "simplified": "阔",
    "reading": "kuò",
    "meaning": "rich, wide, broad",
    "grade": 6
  },
  {
    "id": 4806,
    "traditional": "來往",
    "simplified": "来往",
    "reading": "láiwang",
    "meaning": "associate, contact",
    "grade": 6
  },
  {
    "id": 4807,
    "traditional": "賴",
    "simplified": "赖",
    "reading": "lài",
    "meaning": "to depend on, to hang on in a place, bad, to renege (on promise), to disclaim, to rat (on debts), rascally, to blame, to put the blame on, surname Lai, variant of 賴|赖[lai4]",
    "grade": 6
  },
  {
    "id": 4808,
    "traditional": "啦",
    "simplified": "啦",
    "reading": "la",
    "meaning": "particle: used when informing sb. of st.; indicating excitement/doubt/comfort/etc.",
    "grade": 6
  },
  {
    "id": 4809,
    "traditional": "藍天",
    "simplified": "蓝天",
    "reading": "lántiān",
    "meaning": "blue sky",
    "grade": 6
  },
  {
    "id": 4810,
    "traditional": "欄目",
    "simplified": "栏目",
    "reading": "lánmù",
    "meaning": "regular column or segment (in a publication or broadcast program), program (TV or radio)",
    "grade": 6
  },
  {
    "id": 4811,
    "traditional": "藍領",
    "simplified": "蓝领",
    "reading": "lánlǐng",
    "meaning": "blue collar, common laborer",
    "grade": 6
  },
  {
    "id": 4812,
    "traditional": "牢",
    "simplified": "牢",
    "reading": "láo",
    "meaning": "firm, sturdy, fold (for animals), sacrifice, prison",
    "grade": 6
  },
  {
    "id": 4813,
    "traditional": "老鄉",
    "simplified": "老乡",
    "reading": "lǎoxiāng",
    "meaning": "fellow townsman, fellow villager, sb from the same hometown",
    "grade": 6
  },
  {
    "id": 4814,
    "traditional": "冷氣",
    "simplified": "冷气",
    "reading": "lěngqì",
    "meaning": "air conditioning",
    "grade": 6
  },
  {
    "id": 4815,
    "traditional": "冷水",
    "simplified": "冷水",
    "reading": "lěngshuǐ",
    "meaning": "cold water, unboiled water, fig. not yet ready (of plans)",
    "grade": 6
  },
  {
    "id": 4816,
    "traditional": "理財",
    "simplified": "理财",
    "reading": "lǐ cái",
    "meaning": "financial management, finance",
    "grade": 6
  },
  {
    "id": 4817,
    "traditional": "禮堂",
    "simplified": "礼堂",
    "reading": "lǐtáng",
    "meaning": "assembly hall, auditorium, CL:座[zuo4],處|处[chu4]",
    "grade": 6
  },
  {
    "id": 4818,
    "traditional": "理",
    "simplified": "理",
    "reading": "lǐ",
    "meaning": "texture, grain (in wood/etc.), reason, logic, truth, natural science (esp. physics), law, principle, doctrine, theory",
    "grade": 6
  },
  {
    "id": 4819,
    "traditional": "力",
    "simplified": "力",
    "reading": "lì",
    "meaning": "power, strength, ability, force, physical strength",
    "grade": 6
  },
  {
    "id": 4820,
    "traditional": "理智",
    "simplified": "理智",
    "reading": "lǐzhì",
    "meaning": "reason, intellect, rationality, rational",
    "grade": 6
  },
  {
    "id": 4821,
    "traditional": "聯盟",
    "simplified": "联盟",
    "reading": "liánméng",
    "meaning": "conference, alliance, coalition, hookup, ally, Verein, confederacy, federacy, circuit, combination, Bund, confederation, federation, union, bloc, league, axis",
    "grade": 6
  },
  {
    "id": 4822,
    "traditional": "利",
    "simplified": "利",
    "reading": "lì",
    "meaning": "profit, interest, advantage, benefit",
    "grade": 6
  },
  {
    "id": 4823,
    "traditional": "懶",
    "simplified": "懒",
    "reading": "lǎn",
    "meaning": "lazy",
    "grade": 6
  },
  {
    "id": 4824,
    "traditional": "聯賽",
    "simplified": "联赛",
    "reading": "liánsài",
    "meaning": "loop, league matches, league match",
    "grade": 6
  },
  {
    "id": 4825,
    "traditional": "涼鞋",
    "simplified": "凉鞋",
    "reading": "liángxié",
    "meaning": "sandal",
    "grade": 6
  },
  {
    "id": 4826,
    "traditional": "兩側",
    "simplified": "两侧",
    "reading": "liǎngcè",
    "meaning": "two sides, both sides",
    "grade": 6
  },
  {
    "id": 4827,
    "traditional": "兩手",
    "simplified": "两手",
    "reading": "liǎngshǒu",
    "meaning": "Two hands",
    "grade": 6
  },
  {
    "id": 4828,
    "traditional": "聊天兒",
    "simplified": "聊天儿",
    "reading": "liáo tiānr5",
    "meaning": "to chat, to gossip, erhua variant of 聊天[liao2 tian1]",
    "grade": 6
  },
  {
    "id": 4829,
    "traditional": "聊",
    "simplified": "聊",
    "reading": "liáo",
    "meaning": "endure, rely/depend on, rely on, depend on, chat",
    "grade": 6
  },
  {
    "id": 4830,
    "traditional": "聯手",
    "simplified": "联手",
    "reading": "liánshǒu",
    "meaning": "lit. to join hands, to act together",
    "grade": 6
  },
  {
    "id": 4831,
    "traditional": "靈活",
    "simplified": "灵活",
    "reading": "línghuó",
    "meaning": "nimble, agile, flexible, elastic",
    "grade": 6
  },
  {
    "id": 4832,
    "traditional": "裂",
    "simplified": "裂",
    "reading": "liè",
    "meaning": "to split, to crack, to break open, to rend",
    "grade": 6
  },
  {
    "id": 4833,
    "traditional": "料",
    "simplified": "料",
    "reading": "liào",
    "meaning": "material, stuff, grain, feed, to expect, to anticipate, to guess",
    "grade": 6
  },
  {
    "id": 4834,
    "traditional": "領袖",
    "simplified": "领袖",
    "reading": "lǐngxiù",
    "meaning": "foreman, leader, head, guru, cob, doyen, cacique, Duce, duce, gaffer, chief, boss, soul, sovereign, honcho",
    "grade": 6
  },
  {
    "id": 4835,
    "traditional": "留言",
    "simplified": "留言",
    "reading": "liúyán",
    "meaning": "to leave a message, to leave one's comments, message",
    "grade": 6
  },
  {
    "id": 4836,
    "traditional": "另",
    "simplified": "另",
    "reading": "lìng",
    "meaning": "det.: another",
    "grade": 6
  },
  {
    "id": 4837,
    "traditional": "流感",
    "simplified": "流感",
    "reading": "liúgǎn",
    "meaning": "flu, influenza",
    "grade": 6
  },
  {
    "id": 4838,
    "traditional": "樓道",
    "simplified": "楼道",
    "reading": "lóudào",
    "meaning": "corridor, passageway (in storied building)",
    "grade": 6
  },
  {
    "id": 4839,
    "traditional": "樓房",
    "simplified": "楼房",
    "reading": "lóufáng",
    "meaning": "a building of two or more stories, CL:棟|栋[dong4],幢[zhuang4],座[zuo4]",
    "grade": 6
  },
  {
    "id": 4840,
    "traditional": "領取",
    "simplified": "领取",
    "reading": "lǐngqǔ",
    "meaning": "draw, receive",
    "grade": 6
  },
  {
    "id": 4841,
    "traditional": "陸軍",
    "simplified": "陆军",
    "reading": "lùjūn",
    "meaning": "army",
    "grade": 6
  },
  {
    "id": 4842,
    "traditional": "錄像",
    "simplified": "录像",
    "reading": "lùxiàng",
    "meaning": "to videotape, to videorecord, video recording, CL:盤|盘[pan2]",
    "grade": 6
  },
  {
    "id": 4843,
    "traditional": "露",
    "simplified": "露",
    "reading": "lù",
    "meaning": "betray, betrayal, appear, reveal, show",
    "grade": 6
  },
  {
    "id": 4844,
    "traditional": "錄音機",
    "simplified": "录音机",
    "reading": "lùyīnjī",
    "meaning": "(tape) recording machine, tape recorder, CL:臺|台[tai2]",
    "grade": 6
  },
  {
    "id": 4845,
    "traditional": "旅店",
    "simplified": "旅店",
    "reading": "lǚdiàn",
    "meaning": "inn, small hotel",
    "grade": 6
  },
  {
    "id": 4846,
    "traditional": "馬車",
    "simplified": "马车",
    "reading": "mǎchē",
    "meaning": "cart, chariot, carriage, buggy",
    "grade": 6
  },
  {
    "id": 4847,
    "traditional": "路過",
    "simplified": "路过",
    "reading": "lùguò",
    "meaning": "to pass by or through",
    "grade": 6
  },
  {
    "id": 4848,
    "traditional": "埋",
    "simplified": "埋",
    "reading": "mái",
    "meaning": "hide away, burial, bury, inter",
    "grade": 6
  },
  {
    "id": 4849,
    "traditional": "綠化",
    "simplified": "绿化",
    "reading": "lǜhuà",
    "meaning": "make (a place) green, afforest",
    "grade": 6
  },
  {
    "id": 4850,
    "traditional": "慢車",
    "simplified": "慢车",
    "reading": "mànchē",
    "meaning": "local bus or train, slow train with many stops",
    "grade": 6
  },
  {
    "id": 4851,
    "traditional": "饅頭",
    "simplified": "馒头",
    "reading": "mántou",
    "meaning": "steamed roll, steamed bun, steamed bread, CL:個|个[ge4]",
    "grade": 6
  },
  {
    "id": 4852,
    "traditional": "盲人",
    "simplified": "盲人",
    "reading": "mángrén",
    "meaning": "blind person",
    "grade": 6
  },
  {
    "id": 4853,
    "traditional": "嘛",
    "simplified": "嘛",
    "reading": "ma",
    "meaning": "particle: used to persuade someone to do something; used to emphasize the obvious",
    "grade": 6
  },
  {
    "id": 4854,
    "traditional": "梅花",
    "simplified": "梅花",
    "reading": "méihuā",
    "meaning": "plum blossom, clubs (on playing cards), wintersweet",
    "grade": 6
  },
  {
    "id": 4855,
    "traditional": "美容",
    "simplified": "美容",
    "reading": "měiróng",
    "meaning": "improve looks",
    "grade": 6
  },
  {
    "id": 4856,
    "traditional": "猛",
    "simplified": "猛",
    "reading": "měng",
    "meaning": "suddenly, abruptly",
    "grade": 6
  },
  {
    "id": 4857,
    "traditional": "蒙",
    "simplified": "蒙",
    "reading": "méng",
    "meaning": "to cover, ignorant, to suffer (misfortune), to receive (a favor), to cheat, (knocked) unconscious, dazed, stunned, surname Meng, Mongol ethnic group, abbr. for Mongolia 蒙古國|蒙古国[Meng3 gu3 guo2], Taiwan pr. [Meng2]",
    "grade": 6
  },
  {
    "id": 4858,
    "traditional": "免得",
    "simplified": "免得",
    "reading": "miǎnde",
    "meaning": "conj.: so as not to, so as to avoid",
    "grade": 6
  },
  {
    "id": 4859,
    "traditional": "棉",
    "simplified": "棉",
    "reading": "mián",
    "meaning": "generic term for cotton or kapok, cotton, padded or quilted with cotton",
    "grade": 6
  },
  {
    "id": 4860,
    "traditional": "面對面",
    "simplified": "面对面",
    "reading": "miànduìmiàn",
    "meaning": "face to face",
    "grade": 6
  },
  {
    "id": 4861,
    "traditional": "面嚮",
    "simplified": "面向",
    "reading": "miànxiàng",
    "meaning": "to face, to turn towards, to incline to, geared towards, catering for, -oriented, facial feature, appearance, aspect, facet",
    "grade": 6
  },
  {
    "id": 4862,
    "traditional": "妙",
    "simplified": "妙",
    "reading": "miào",
    "meaning": "wonderful, excellent, fine, ingenious, clever, subtle",
    "grade": 6
  },
  {
    "id": 4863,
    "traditional": "滅",
    "simplified": "灭",
    "reading": "miè",
    "meaning": "destruction, go out, put out, wipe out, extermination, put/go out, put, exterminate, turn off, destroy, extinguish",
    "grade": 6
  },
  {
    "id": 4864,
    "traditional": "民歌",
    "simplified": "民歌",
    "reading": "míngē",
    "meaning": "folk song, CL:支[zhi1],首[shou3]",
    "grade": 6
  },
  {
    "id": 4865,
    "traditional": "民工",
    "simplified": "民工",
    "reading": "míngōng",
    "meaning": "migrant worker, temporary worker enlisted on a public project",
    "grade": 6
  },
  {
    "id": 4866,
    "traditional": "民警",
    "simplified": "民警",
    "reading": "mínjǐng",
    "meaning": "civil police, PRC police, abbr. for 人民警察",
    "grade": 6
  },
  {
    "id": 4867,
    "traditional": "民主",
    "simplified": "民主",
    "reading": "mínzhǔ",
    "meaning": "democracy, democratic rights",
    "grade": 6
  },
  {
    "id": 4868,
    "traditional": "名勝",
    "simplified": "名胜",
    "reading": "míngshèng",
    "meaning": "a place famous for its scenery or historical relics, scenic spot, CL:處|处[chu4]",
    "grade": 6
  },
  {
    "id": 4869,
    "traditional": "名額",
    "simplified": "名额",
    "reading": "míngé",
    "meaning": "number of people allowed, quota of people",
    "grade": 6
  },
  {
    "id": 4870,
    "traditional": "民意",
    "simplified": "民意",
    "reading": "mínyì",
    "meaning": "public_opinion, popular will",
    "grade": 6
  },
  {
    "id": 4871,
    "traditional": "名譽",
    "simplified": "名誉",
    "reading": "míngyù",
    "meaning": "honour, laurel, celebrity, worship, character, odor, credit, repute, fame, name, rep, reputation, honorary, distinction, regard, honor",
    "grade": 6
  },
  {
    "id": 4872,
    "traditional": "名義",
    "simplified": "名义",
    "reading": "míngyì",
    "meaning": "title, name",
    "grade": 6
  },
  {
    "id": 4873,
    "traditional": "命",
    "simplified": "命",
    "reading": "mìng",
    "meaning": "life, lot, fate, destiny",
    "grade": 6
  },
  {
    "id": 4874,
    "traditional": "明日",
    "simplified": "明日",
    "reading": "míngrì",
    "meaning": "tomorrow",
    "grade": 6
  },
  {
    "id": 4875,
    "traditional": "磨",
    "simplified": "磨",
    "reading": "mó",
    "meaning": "grind, mill, turn round/about",
    "grade": 6
  },
  {
    "id": 4876,
    "traditional": "沒收",
    "simplified": "没收",
    "reading": "mòshōu",
    "meaning": "to confiscate, to seize",
    "grade": 6
  },
  {
    "id": 4877,
    "traditional": "膜",
    "simplified": "膜",
    "reading": "mó",
    "meaning": "membrane, film",
    "grade": 6
  },
  {
    "id": 4878,
    "traditional": "墨水",
    "simplified": "墨水",
    "reading": "mòshuǐ",
    "meaning": "ink, CL:瓶[ping2]",
    "grade": 6
  },
  {
    "id": 4879,
    "traditional": "母",
    "simplified": "母",
    "reading": "mǔ",
    "meaning": "mother, elderly female relative, origin, source, (of animals) female",
    "grade": 6
  },
  {
    "id": 4880,
    "traditional": "母雞",
    "simplified": "母鸡",
    "reading": "mǔjī",
    "meaning": "hen",
    "grade": 6
  },
  {
    "id": 4881,
    "traditional": "母女",
    "simplified": "母女",
    "reading": "mǔ-nǚ",
    "meaning": "mother-daughter",
    "grade": 6
  },
  {
    "id": 4882,
    "traditional": "墓",
    "simplified": "墓",
    "reading": "mù",
    "meaning": "grave, tomb, mausoleum",
    "grade": 6
  },
  {
    "id": 4883,
    "traditional": "拿走",
    "simplified": "拿走",
    "reading": "názǒu",
    "meaning": "to take away",
    "grade": 6
  },
  {
    "id": 4884,
    "traditional": "母子",
    "simplified": "母子",
    "reading": "mǔzǐ",
    "meaning": "mother and child, parent and subsidiary (companies), principal and interest",
    "grade": 6
  },
  {
    "id": 4885,
    "traditional": "奶粉",
    "simplified": "奶粉",
    "reading": "nǎifěn",
    "meaning": "powdered milk",
    "grade": 6
  },
  {
    "id": 4886,
    "traditional": "難忘",
    "simplified": "难忘",
    "reading": "nánwàng",
    "meaning": "unforgettable, memorable",
    "grade": 6
  },
  {
    "id": 4887,
    "traditional": "奶牛",
    "simplified": "奶牛",
    "reading": "nǎiniú",
    "meaning": "milk cow, dairy cow",
    "grade": 6
  },
  {
    "id": 4888,
    "traditional": "內外",
    "simplified": "内外",
    "reading": "nèiwài",
    "meaning": "inside and outside, domestic and foreign",
    "grade": 6
  },
  {
    "id": 4889,
    "traditional": "內地",
    "simplified": "内地",
    "reading": "nèidì",
    "meaning": "mainland China (PRC excluding Hong Kong and Macau, but including islands such as Hainan), Japan (used in Taiwan during Japanese colonization), inland, interior, hinterland",
    "grade": 6
  },
  {
    "id": 4890,
    "traditional": "內衣",
    "simplified": "内衣",
    "reading": "nèiyī",
    "meaning": "undergarment, underwear, CL:件[jian4]",
    "grade": 6
  },
  {
    "id": 4891,
    "traditional": "能否",
    "simplified": "能否",
    "reading": "néngfǒu",
    "meaning": "can or not",
    "grade": 6
  },
  {
    "id": 4892,
    "traditional": "泥",
    "simplified": "泥",
    "reading": "ní",
    "meaning": "mud, clay, paste, pulp, restrained",
    "grade": 6
  },
  {
    "id": 4893,
    "traditional": "扭",
    "simplified": "扭",
    "reading": "niǔ",
    "meaning": "to turn, to twist, to wring, to sprain, to swing one's hips",
    "grade": 6
  },
  {
    "id": 4894,
    "traditional": "排行榜",
    "simplified": "排行榜",
    "reading": "páihángbǎng",
    "meaning": "seniority among siblings",
    "grade": 6
  },
  {
    "id": 4895,
    "traditional": "判",
    "simplified": "判",
    "reading": "pàn",
    "meaning": "to judge, to sentence, to discriminate, to discern, obviously (different)",
    "grade": 6
  },
  {
    "id": 4896,
    "traditional": "派出",
    "simplified": "派出",
    "reading": "pàichū",
    "meaning": "send, dispatch",
    "grade": 6
  },
  {
    "id": 4897,
    "traditional": "盼望",
    "simplified": "盼望",
    "reading": "pànwàng",
    "meaning": "hope/long for, look forward to",
    "grade": 6
  },
  {
    "id": 4898,
    "traditional": "泡",
    "simplified": "泡",
    "reading": "pào",
    "meaning": "steep, soak, immerse",
    "grade": 6
  },
  {
    "id": 4899,
    "traditional": "陪同",
    "simplified": "陪同",
    "reading": "péitóng",
    "meaning": "accompany",
    "grade": 6
  },
  {
    "id": 4900,
    "traditional": "皮球",
    "simplified": "皮球",
    "reading": "píqiú",
    "meaning": "ball (made of rubber, leather etc)",
    "grade": 6
  },
  {
    "id": 4901,
    "traditional": "配置",
    "simplified": "配置",
    "reading": "pèizhì",
    "meaning": "dispose (troops/etc.), deploy",
    "grade": 6
  },
  {
    "id": 4902,
    "traditional": "偏",
    "simplified": "偏",
    "reading": "piān",
    "meaning": "stubbornly, on the contrary",
    "grade": 6
  },
  {
    "id": 4903,
    "traditional": "貧困",
    "simplified": "贫困",
    "reading": "pínkùn",
    "meaning": "impoverished, poverty",
    "grade": 6
  },
  {
    "id": 4904,
    "traditional": "聘請",
    "simplified": "聘请",
    "reading": "pìnqǐng",
    "meaning": "engage, invite",
    "grade": 6
  },
  {
    "id": 4905,
    "traditional": "炮",
    "simplified": "炮",
    "reading": "pào",
    "meaning": "to sauté, to fry, to dry by heating, to prepare herbal medicine by roasting or parching (in a pan), variant of 炮[pao4]",
    "grade": 6
  },
  {
    "id": 4906,
    "traditional": "品牌",
    "simplified": "品牌",
    "reading": "pǐnpái",
    "meaning": "brand, make, trademark",
    "grade": 6
  },
  {
    "id": 4907,
    "traditional": "平凡",
    "simplified": "平凡",
    "reading": "píngfán",
    "meaning": "ordinary, common",
    "grade": 6
  },
  {
    "id": 4908,
    "traditional": "平衡",
    "simplified": "平衡",
    "reading": "pínghéng",
    "meaning": "balance",
    "grade": 6
  },
  {
    "id": 4909,
    "traditional": "評",
    "simplified": "评",
    "reading": "píng",
    "meaning": "to discuss, to comment, to criticize, to judge, to choose (by public appraisal)",
    "grade": 6
  },
  {
    "id": 4910,
    "traditional": "平臺",
    "simplified": "平台",
    "reading": "píngtái",
    "meaning": "patio, terrace, hathpace, platform",
    "grade": 6
  },
  {
    "id": 4911,
    "traditional": "屏幕",
    "simplified": "屏幕",
    "reading": "píngmù",
    "meaning": "screen (TV, computer or movie)",
    "grade": 6
  },
  {
    "id": 4912,
    "traditional": "評選",
    "simplified": "评选",
    "reading": "píngxuǎn",
    "meaning": "to select on the basis of a vote or consensus",
    "grade": 6
  },
  {
    "id": 4913,
    "traditional": "撲",
    "simplified": "扑",
    "reading": "pū",
    "meaning": "pat, attack, throw oneself at, rush at, tap, beat, dab, bend over, pounce on, lie_down, throw oneself on, flap, flutter, fling self at",
    "grade": 6
  },
  {
    "id": 4914,
    "traditional": "平方米",
    "simplified": "平方米",
    "reading": "píngfāngmǐ",
    "meaning": "square meter",
    "grade": 6
  },
  {
    "id": 4915,
    "traditional": "鋪",
    "simplified": "铺",
    "reading": "pù",
    "meaning": "pave, spread, overlay, extend, unfold, lay, surface, set in order, bespread",
    "grade": 6
  },
  {
    "id": 4916,
    "traditional": "奇妙",
    "simplified": "奇妙",
    "reading": "qímiào",
    "meaning": "wonderful, marvelous",
    "grade": 6
  },
  {
    "id": 4917,
    "traditional": "坡",
    "simplified": "坡",
    "reading": "pō",
    "meaning": "slope, CL:個|个[ge4], sloping, slanted",
    "grade": 6
  },
  {
    "id": 4918,
    "traditional": "欺負",
    "simplified": "欺负",
    "reading": "qīfu",
    "meaning": "browbeat, take advantage of, pick on",
    "grade": 6
  },
  {
    "id": 4919,
    "traditional": "企圖",
    "simplified": "企图",
    "reading": "qǐtú",
    "meaning": "attempt, intend",
    "grade": 6
  },
  {
    "id": 4920,
    "traditional": "氣氛",
    "simplified": "气氛",
    "reading": "qìfēn",
    "meaning": "mood, air, look, feeling, aura, aurae, spirit, smell, genius loci, feel, flavor, atmosphere, tone, karma, ambiance, ambience",
    "grade": 6
  },
  {
    "id": 4921,
    "traditional": "恰當",
    "simplified": "恰当",
    "reading": "qiàdàng",
    "meaning": "appropriate, proper, suitable, fitting",
    "grade": 6
  },
  {
    "id": 4922,
    "traditional": "恰好",
    "simplified": "恰好",
    "reading": "qiàhǎo",
    "meaning": "as it turns out, by lucky coincidence, (of number, time, size etc) just right",
    "grade": 6
  },
  {
    "id": 4923,
    "traditional": "起訴",
    "simplified": "起诉",
    "reading": "qǐsù",
    "meaning": "to sue, to bring a lawsuit against, to prosecute",
    "grade": 6
  },
  {
    "id": 4924,
    "traditional": "恰恰",
    "simplified": "恰恰",
    "reading": "qiàqià",
    "meaning": "exactly, just, precisely",
    "grade": 6
  },
  {
    "id": 4925,
    "traditional": "鉛筆",
    "simplified": "铅笔",
    "reading": "qiānbǐ",
    "meaning": "(lead) pencil, CL:支[zhi1],枝[zhi1],桿|杆[gan3]",
    "grade": 6
  },
  {
    "id": 4926,
    "traditional": "謙虛",
    "simplified": "谦虚",
    "reading": "qiānxū",
    "meaning": "make modest remarks",
    "grade": 6
  },
  {
    "id": 4927,
    "traditional": "牽",
    "simplified": "牵",
    "reading": "qiān",
    "meaning": "lead along (by holding hand/halter/etc.), pull, involve, implicate",
    "grade": 6
  },
  {
    "id": 4928,
    "traditional": "起點",
    "simplified": "起点",
    "reading": "qǐdiǎn",
    "meaning": "starting point",
    "grade": 6
  },
  {
    "id": 4929,
    "traditional": "前方",
    "simplified": "前方",
    "reading": "qiánfāng",
    "meaning": "ahead, the front",
    "grade": 6
  },
  {
    "id": 4930,
    "traditional": "強盜",
    "simplified": "强盗",
    "reading": "qiángdào",
    "meaning": "hijacker, ravener, robber, rifler, freebooter, housebreaker, mugger, stickup, highwayman, brigand, ripoff, robbery, bandit, dacoit",
    "grade": 6
  },
  {
    "id": 4931,
    "traditional": "潛力",
    "simplified": "潜力",
    "reading": "qiánlì",
    "meaning": "bottom, possibility, ability, potency, capability, capableness, potentiality, potential, potential(ity), latent capacity",
    "grade": 6
  },
  {
    "id": 4932,
    "traditional": "前來",
    "simplified": "前来",
    "reading": "qiánlái",
    "meaning": "come",
    "grade": 6
  },
  {
    "id": 4933,
    "traditional": "強勢",
    "simplified": "强势",
    "reading": "qiángshì",
    "meaning": "powerful influence",
    "grade": 6
  },
  {
    "id": 4934,
    "traditional": "強壯",
    "simplified": "强壮",
    "reading": "qiángzhuàng",
    "meaning": "strong, sturdy, robust",
    "grade": 6
  },
  {
    "id": 4935,
    "traditional": "巧妙",
    "simplified": "巧妙",
    "reading": "qiǎomiào",
    "meaning": "felicitous, skillful, ingenious, clever, shrewd",
    "grade": 6
  },
  {
    "id": 4936,
    "traditional": "橋梁",
    "simplified": "桥梁",
    "reading": "qiáoliáng",
    "meaning": "bridge, fig. connection between two areas",
    "grade": 6
  },
  {
    "id": 4937,
    "traditional": "強化",
    "simplified": "强化",
    "reading": "qiánghuà",
    "meaning": "strengthen, intensify, consolidate",
    "grade": 6
  },
  {
    "id": 4938,
    "traditional": "切實",
    "simplified": "切实",
    "reading": "qièshí",
    "meaning": "feasible, realistic, practical, earnestly, conscientiously",
    "grade": 6
  },
  {
    "id": 4939,
    "traditional": "侵犯",
    "simplified": "侵犯",
    "reading": "qīnfàn",
    "meaning": "violate, infringe on (sb.'s rights)",
    "grade": 6
  },
  {
    "id": 4940,
    "traditional": "茄子",
    "simplified": "茄子",
    "reading": "qiézi",
    "meaning": "eggplant (Solanum melongena L.), aubergine, brinjal, Guinea squash, phonetic 'cheese' (when being photographed), equivalent of 'say cheese'",
    "grade": 6
  },
  {
    "id": 4941,
    "traditional": "親屬",
    "simplified": "亲属",
    "reading": "qīnshǔ",
    "meaning": "kin, kindred, relatives",
    "grade": 6
  },
  {
    "id": 4942,
    "traditional": "傾向",
    "simplified": "倾向",
    "reading": "qīngxiàng",
    "meaning": "tendency, trend, inclination",
    "grade": 6
  },
  {
    "id": 4943,
    "traditional": "清",
    "simplified": "清",
    "reading": "qīng",
    "meaning": "pure, clean, fresh, cool, lonely, poor",
    "grade": 6
  },
  {
    "id": 4944,
    "traditional": "清潔",
    "simplified": "清洁",
    "reading": "qīngjié",
    "meaning": "clean, sanitary",
    "grade": 6
  },
  {
    "id": 4945,
    "traditional": "清潔工",
    "simplified": "清洁工",
    "reading": "qīngjié gōng",
    "meaning": "cleaner, janitor, garbage collector",
    "grade": 6
  },
  {
    "id": 4946,
    "traditional": "親眼",
    "simplified": "亲眼",
    "reading": "qīnyǎn",
    "meaning": "with one's own eyes, personally",
    "grade": 6
  },
  {
    "id": 4947,
    "traditional": "清明節",
    "simplified": "清明节",
    "reading": "Qīngmíngjié",
    "meaning": "Qingming or Pure Brightness Festival or Tomb Sweeping Day, celebration for the dead (in early April)",
    "grade": 6
  },
  {
    "id": 4948,
    "traditional": "情緒",
    "simplified": "情绪",
    "reading": "qíngxù",
    "meaning": "form, pulse, humor, depression, temper, feeling, spirits, monkey, emotion, sentiment, humour, soulfulness, mood, heartbeat, heartstrings, sulks, chord, vein, feelings, emotional state, spirit, frame_of_mind, sentiments, moodiness, emotions, steam, tune, soul",
    "grade": 6
  },
  {
    "id": 4949,
    "traditional": "球拍",
    "simplified": "球拍",
    "reading": "qiúpāi",
    "meaning": "racket",
    "grade": 6
  },
  {
    "id": 4950,
    "traditional": "清洗",
    "simplified": "清洗",
    "reading": "qīngxǐ",
    "meaning": "to wash, to clean, to purge",
    "grade": 6
  },
  {
    "id": 4951,
    "traditional": "球星",
    "simplified": "球星",
    "reading": "qiúxīng",
    "meaning": "sports star (ball sport)",
    "grade": 6
  },
  {
    "id": 4952,
    "traditional": "求職",
    "simplified": "求职",
    "reading": "qiúzhí",
    "meaning": "seek for job",
    "grade": 6
  },
  {
    "id": 4953,
    "traditional": "球員",
    "simplified": "球员",
    "reading": "qiúyuán",
    "meaning": "ballplayer, player, ball team member",
    "grade": 6
  },
  {
    "id": 4954,
    "traditional": "渠道",
    "simplified": "渠道",
    "reading": "qúdào",
    "meaning": "irrigation ditch, (fig.) channel, means",
    "grade": 6
  },
  {
    "id": 4955,
    "traditional": "取款",
    "simplified": "取款",
    "reading": "qǔ kuǎn",
    "meaning": "to withdraw money from a bank",
    "grade": 6
  },
  {
    "id": 4956,
    "traditional": "去掉",
    "simplified": "去掉",
    "reading": "qùdiào",
    "meaning": "to get rid of, to exclude, to eliminate, to remove, to delete, to strip out, to extract",
    "grade": 6
  },
  {
    "id": 4957,
    "traditional": "區分",
    "simplified": "区分",
    "reading": "qūfēn",
    "meaning": "section, demarcate, compart, sectionalize, zone, screen, compartmentalize, individuate, differentiation, distinguish, mark off, secern, district, segment, separate, mark, sieve, differentiate, mark out, classify, divide, ramify, screen out, set apart, sort",
    "grade": 6
  },
  {
    "id": 4958,
    "traditional": "取款機",
    "simplified": "取款机",
    "reading": "qǔkuǎnjī",
    "meaning": "ATM",
    "grade": 6
  },
  {
    "id": 4959,
    "traditional": "權",
    "simplified": "权",
    "reading": "quán",
    "meaning": "surname Quan, authority, power, right, (literary) to weigh, expedient, temporary",
    "grade": 6
  },
  {
    "id": 4960,
    "traditional": "全力",
    "simplified": "全力",
    "reading": "quánlì",
    "meaning": "all one's strength, wholehearted dedication",
    "grade": 6
  },
  {
    "id": 4961,
    "traditional": "權力",
    "simplified": "权力",
    "reading": "quánlì",
    "meaning": "influence, rod, authoritativeness, clutches, whip_hand, dominance, say-so, reins, force, emirate, might, pretension, station, commission, grasp, leverage, danger, authority, place, power, arm, disposal, puissance",
    "grade": 6
  },
  {
    "id": 4962,
    "traditional": "全新",
    "simplified": "全新",
    "reading": "quánxīn",
    "meaning": "all new, completely new",
    "grade": 6
  },
  {
    "id": 4963,
    "traditional": "券",
    "simplified": "券",
    "reading": "quàn",
    "meaning": "bond (esp. document split in two, with each party holding one half), contract, deed (i.e. title deeds), ticket, voucher, certificate, variant of 券[quan4]",
    "grade": 6
  },
  {
    "id": 4964,
    "traditional": "缺陷",
    "simplified": "缺陷",
    "reading": "quēxiàn",
    "meaning": "gap, deficiency, trouble, flaw, blemishment, blemish, shortage, limitation, incapacity, defect, shortcoming, deformity, defacement, drawback, disfigurement, disadvantage",
    "grade": 6
  },
  {
    "id": 4965,
    "traditional": "讓座",
    "simplified": "让座",
    "reading": "ràng zuò",
    "meaning": "to give up one's seat for sb",
    "grade": 6
  },
  {
    "id": 4966,
    "traditional": "熱點",
    "simplified": "热点",
    "reading": "rèdiǎn",
    "meaning": "hot spot, point of special interest",
    "grade": 6
  },
  {
    "id": 4967,
    "traditional": "熱水",
    "simplified": "热水",
    "reading": "rèshuǐ",
    "meaning": "hot water",
    "grade": 6
  },
  {
    "id": 4968,
    "traditional": "熱水器",
    "simplified": "热水器",
    "reading": "rèshuǐqì",
    "meaning": "water heater",
    "grade": 6
  },
  {
    "id": 4969,
    "traditional": "熱線",
    "simplified": "热线",
    "reading": "rèxiàn",
    "meaning": "hotline (communications link)",
    "grade": 6
  },
  {
    "id": 4970,
    "traditional": "人權",
    "simplified": "人权",
    "reading": "rénquán",
    "meaning": "human rights",
    "grade": 6
  },
  {
    "id": 4971,
    "traditional": "日夜",
    "simplified": "日夜",
    "reading": "rìyè",
    "meaning": "day and night, around the clock",
    "grade": 6
  },
  {
    "id": 4972,
    "traditional": "卻是",
    "simplified": "却是",
    "reading": "quèshì",
    "meaning": "nevertheless, actually, the fact is ...",
    "grade": 6
  },
  {
    "id": 4973,
    "traditional": "認同",
    "simplified": "认同",
    "reading": "rèntóng",
    "meaning": "identify, have meeting of the minds",
    "grade": 6
  },
  {
    "id": 4974,
    "traditional": "融合",
    "simplified": "融合",
    "reading": "rónghé",
    "meaning": "mix together, fuse, stick together",
    "grade": 6
  },
  {
    "id": 4975,
    "traditional": "融入",
    "simplified": "融入",
    "reading": "róngrù",
    "meaning": "melt, thaw, blend, merge",
    "grade": 6
  },
  {
    "id": 4976,
    "traditional": "日語",
    "simplified": "日语",
    "reading": "Rìyǔ",
    "meaning": "Japanese language",
    "grade": 6
  },
  {
    "id": 4977,
    "traditional": "如",
    "simplified": "如",
    "reading": "rú",
    "meaning": "according to, in accordance with, such as, as if, like, for example",
    "grade": 6
  },
  {
    "id": 4978,
    "traditional": "如一",
    "simplified": "如一",
    "reading": "rúyī",
    "meaning": "As one",
    "grade": 6
  },
  {
    "id": 4979,
    "traditional": "乳製品",
    "simplified": "乳制品",
    "reading": "rǔzhìpǐn",
    "meaning": "dairy products",
    "grade": 6
  },
  {
    "id": 4980,
    "traditional": "入",
    "simplified": "入",
    "reading": "rù",
    "meaning": "agree with, set (of sun), set, take in, join, receive, be admitted into, become a member of, enter, descend, infiltrate, conform with, conform to, interlard",
    "grade": 6
  },
  {
    "id": 4981,
    "traditional": "入學",
    "simplified": "入学",
    "reading": "rù xué",
    "meaning": "enter a school, enrol, college, enter school, enter, start school, matriculate, enter college",
    "grade": 6
  },
  {
    "id": 4982,
    "traditional": "若",
    "simplified": "若",
    "reading": "ruò",
    "meaning": "conj.: if, as if",
    "grade": 6
  },
  {
    "id": 4983,
    "traditional": "塞",
    "simplified": "塞",
    "reading": "sāi",
    "meaning": "fill in, stuff in, stuff, fill, squeeze in, bung, spile, squeeze, line, stick, fill/stuff in",
    "grade": 6
  },
  {
    "id": 4984,
    "traditional": "賽",
    "simplified": "赛",
    "reading": "sài",
    "meaning": "to compete, competition, match, to surpass, better than, superior to, to excel",
    "grade": 6
  },
  {
    "id": 4985,
    "traditional": "賽場",
    "simplified": "赛场",
    "reading": "sàichǎng",
    "meaning": "racetrack, field (for athletics competition)",
    "grade": 6
  },
  {
    "id": 4986,
    "traditional": "三明治",
    "simplified": "三明治",
    "reading": "sānmíngzhì",
    "meaning": "sandwich (loanword), CL:個|个[ge4]",
    "grade": 6
  },
  {
    "id": 4987,
    "traditional": "山峰",
    "simplified": "山峰",
    "reading": "shānfēng",
    "meaning": "(mountain) peak",
    "grade": 6
  },
  {
    "id": 4988,
    "traditional": "山谷",
    "simplified": "山谷",
    "reading": "shāngǔ",
    "meaning": "mountain valley",
    "grade": 6
  },
  {
    "id": 4989,
    "traditional": "喪失",
    "simplified": "丧失",
    "reading": "sàngshī",
    "meaning": "cost, forfeiture, forfeit, give_away, lapse, loss, bereaved, lose, jump",
    "grade": 6
  },
  {
    "id": 4990,
    "traditional": "傷口",
    "simplified": "伤口",
    "reading": "shāngkǒu",
    "meaning": "wound, cut",
    "grade": 6
  },
  {
    "id": 4991,
    "traditional": "傷亡",
    "simplified": "伤亡",
    "reading": "shāngwáng",
    "meaning": "casualties, injuries and deaths",
    "grade": 6
  },
  {
    "id": 4992,
    "traditional": "山坡",
    "simplified": "山坡",
    "reading": "shānpō",
    "meaning": "hillside, mountain slope",
    "grade": 6
  },
  {
    "id": 4993,
    "traditional": "傷員",
    "simplified": "伤员",
    "reading": "shāngyuán",
    "meaning": "wounded person",
    "grade": 6
  },
  {
    "id": 4994,
    "traditional": "商城",
    "simplified": "商城",
    "reading": "shāngchéng",
    "meaning": "see 商城縣|商城县[Shang1 cheng2 xian4], shopping center, department store",
    "grade": 6
  },
  {
    "id": 4995,
    "traditional": "上市",
    "simplified": "上市",
    "reading": "shàng shì",
    "meaning": "go/appear on market",
    "grade": 6
  },
  {
    "id": 4996,
    "traditional": "上當",
    "simplified": "上当",
    "reading": "shàng dàng",
    "meaning": "be duped, be taken in, get_stuck, be fooled, be tricked",
    "grade": 6
  },
  {
    "id": 4997,
    "traditional": "上演",
    "simplified": "上演",
    "reading": "shàngyǎn",
    "meaning": "produce, bring on, put on stage, play, stage, mount, perform, bring out, performance",
    "grade": 6
  },
  {
    "id": 4998,
    "traditional": "上帝",
    "simplified": "上帝",
    "reading": "Shàngdì",
    "meaning": "Creator, god, Yahveh, Almighty, almighty, Godhead, Jehovah, Being, Supreme_Being, God Almighty, Maker, Allah, deity, omnipotent, king, abba, Omniscience, omniscient, God, creator, Providence, Omnipotence, Deus, providence, Divine, lording, Lord, lord, maker, Supreme Being, judge, Dominus, Heaven, All-father, Adonai, Father-God, divinity, Father",
    "grade": 6
  },
  {
    "id": 4999,
    "traditional": "上臺",
    "simplified": "上台",
    "reading": "shàng tái",
    "meaning": "enter",
    "grade": 6
  },
  {
    "id": 5000,
    "traditional": "勺",
    "simplified": "勺",
    "reading": "sháo",
    "meaning": "spoon, ladle, CL:把[ba3], abbr. for 公勺[gong1 shao2], centiliter (unit of volume)",
    "grade": 6
  },
  {
    "id": 5001,
    "traditional": "舌頭",
    "simplified": "舌头",
    "reading": "shétou",
    "meaning": "lingua, clack, tongue, clapper, ability to talk, glossa, enemy soldier captured to extract inform, enemy soldier captured to extract information, an enemy soldier captured for the purpose of extra",
    "grade": 6
  },
  {
    "id": 5002,
    "traditional": "少兒",
    "simplified": "少儿",
    "reading": "shàoér",
    "meaning": "child",
    "grade": 6
  },
  {
    "id": 5003,
    "traditional": "設計師",
    "simplified": "设计师",
    "reading": "shèjìshī",
    "meaning": "architect, designer, stylist",
    "grade": 6
  },
  {
    "id": 5004,
    "traditional": "深化",
    "simplified": "深化",
    "reading": "shēnhuà",
    "meaning": "to deepen, to intensify",
    "grade": 6
  },
  {
    "id": 5005,
    "traditional": "涉及",
    "simplified": "涉及",
    "reading": "shèjí",
    "meaning": "come to, pertain, involvement, implicate, entail, relate, cover, deal, concern, bear on, run_on, involve, touch on, refer, touch upon, deal with, touch, come_to, relate to",
    "grade": 6
  },
  {
    "id": 5006,
    "traditional": "深深",
    "simplified": "深深",
    "reading": "shēnshēn",
    "meaning": "deep, profound",
    "grade": 6
  },
  {
    "id": 5007,
    "traditional": "審查",
    "simplified": "审查",
    "reading": "shěnchá",
    "meaning": "examine, investigate",
    "grade": 6
  },
  {
    "id": 5008,
    "traditional": "升級",
    "simplified": "升级",
    "reading": "shēng jí",
    "meaning": "upgrade, go up (in grade/etc.), escalate",
    "grade": 6
  },
  {
    "id": 5009,
    "traditional": "升學",
    "simplified": "升学",
    "reading": "shēng xué",
    "meaning": "enter higher school",
    "grade": 6
  },
  {
    "id": 5010,
    "traditional": "生活費",
    "simplified": "生活费",
    "reading": "shēnghuófèi",
    "meaning": "cost of living, living expenses, alimony",
    "grade": 6
  },
  {
    "id": 5011,
    "traditional": "省錢",
    "simplified": "省钱",
    "reading": "shěngqián",
    "meaning": "to save money",
    "grade": 6
  },
  {
    "id": 5012,
    "traditional": "升值",
    "simplified": "升值",
    "reading": "shēngzhí",
    "meaning": "revalue, appreciate",
    "grade": 6
  },
  {
    "id": 5013,
    "traditional": "聖誕節",
    "simplified": "圣诞节",
    "reading": "Shèngdàn Jié",
    "meaning": "Christmas time, Christmas season, Christmas",
    "grade": 6
  },
  {
    "id": 5014,
    "traditional": "師父",
    "simplified": "师父",
    "reading": "shīfu",
    "meaning": "guru, address for nun/etc., teacher, tutor, address for monk, professional, master, nun, a polite form of address to a monk or nun, address for monk/nun/etc., etc.",
    "grade": 6
  },
  {
    "id": 5015,
    "traditional": "盛行",
    "simplified": "盛行",
    "reading": "shèngxíng",
    "meaning": "be current, be in vogue",
    "grade": 6
  },
  {
    "id": 5016,
    "traditional": "時節",
    "simplified": "时节",
    "reading": "shíjié",
    "meaning": "season, time",
    "grade": 6
  },
  {
    "id": 5017,
    "traditional": "師生",
    "simplified": "师生",
    "reading": "shī-shēng",
    "meaning": "teacher and student",
    "grade": 6
  },
  {
    "id": 5018,
    "traditional": "時時",
    "simplified": "时时",
    "reading": "shíshí",
    "meaning": "always, constantly, often",
    "grade": 6
  },
  {
    "id": 5019,
    "traditional": "時期",
    "simplified": "时期",
    "reading": "shíqī",
    "meaning": "epoch, season, date, period_of_time, phase, day, occasion, estate, period, length, period (of time), phase angle, stage, conjuncture, chapter, times, era, leg, guardianship",
    "grade": 6
  },
  {
    "id": 5020,
    "traditional": "時裝",
    "simplified": "时装",
    "reading": "shízhuāng",
    "meaning": "fashion, fashionable clothes",
    "grade": 6
  },
  {
    "id": 5021,
    "traditional": "時而",
    "simplified": "时而",
    "reading": "shíér",
    "meaning": "occasionally, from time to time",
    "grade": 6
  },
  {
    "id": 5022,
    "traditional": "識",
    "simplified": "识",
    "reading": "shí",
    "meaning": "know, recognize",
    "grade": 6
  },
  {
    "id": 5023,
    "traditional": "實踐",
    "simplified": "实践",
    "reading": "shíjiàn",
    "meaning": "practice",
    "grade": 6
  },
  {
    "id": 5024,
    "traditional": "食慾",
    "simplified": "食欲",
    "reading": "shíyù",
    "meaning": "appetite",
    "grade": 6
  },
  {
    "id": 5025,
    "traditional": "市民",
    "simplified": "市民",
    "reading": "shìmín",
    "meaning": "citizen, burgess, citizenry, metropolitan, townsman, townsfolk, town, towner, civic, residents of a city, townee, burgher, townspeople",
    "grade": 6
  },
  {
    "id": 5026,
    "traditional": "試點",
    "simplified": "试点",
    "reading": "shìdiǎn",
    "meaning": "test point, to carry out trial, pilot scheme",
    "grade": 6
  },
  {
    "id": 5027,
    "traditional": "適當",
    "simplified": "适当",
    "reading": "shìdàng",
    "meaning": "beseem, proper, suitable, suit",
    "grade": 6
  },
  {
    "id": 5028,
    "traditional": "事後",
    "simplified": "事后",
    "reading": "shìhòu",
    "meaning": "after the event, in hindsight, in retrospect",
    "grade": 6
  },
  {
    "id": 5029,
    "traditional": "收藏",
    "simplified": "收藏",
    "reading": "shōucáng",
    "meaning": "collect, store up",
    "grade": 6
  },
  {
    "id": 5030,
    "traditional": "識字",
    "simplified": "识字",
    "reading": "shí zì",
    "meaning": "to learn to read",
    "grade": 6
  },
  {
    "id": 5031,
    "traditional": "收養",
    "simplified": "收养",
    "reading": "shōuyǎng",
    "meaning": "to take in and care for (an elderly person, a dog etc), to adopt (a child), adoption",
    "grade": 6
  },
  {
    "id": 5032,
    "traditional": "手續費",
    "simplified": "手续费",
    "reading": "shǒuxùfèi",
    "meaning": "service charge, processing fee, commission",
    "grade": 6
  },
  {
    "id": 5033,
    "traditional": "收取",
    "simplified": "收取",
    "reading": "shōuqǔ",
    "meaning": "collect, gather",
    "grade": 6
  },
  {
    "id": 5034,
    "traditional": "首",
    "simplified": "首",
    "reading": "shǒu",
    "meaning": "m.[general]",
    "grade": 6
  },
  {
    "id": 5035,
    "traditional": "首腦",
    "simplified": "首脑",
    "reading": "shǒunǎo",
    "meaning": "head (of state), summit (meeting), leader",
    "grade": 6
  },
  {
    "id": 5036,
    "traditional": "首席",
    "simplified": "首席",
    "reading": "shǒuxí",
    "meaning": "chief (representative, correspondent etc)",
    "grade": 6
  },
  {
    "id": 5037,
    "traditional": "首次",
    "simplified": "首次",
    "reading": "shǒucì",
    "meaning": "first, first time, for the first time",
    "grade": 6
  },
  {
    "id": 5038,
    "traditional": "書房",
    "simplified": "书房",
    "reading": "shūfáng",
    "meaning": "study (room), studio, CL:間|间[jian1]",
    "grade": 6
  },
  {
    "id": 5039,
    "traditional": "首相",
    "simplified": "首相",
    "reading": "shǒuxiàng",
    "meaning": "prime minister (of Japan or UK etc)",
    "grade": 6
  },
  {
    "id": 5040,
    "traditional": "薯片",
    "simplified": "薯片",
    "reading": "shǔpiàn",
    "meaning": "fried potato chips",
    "grade": 6
  },
  {
    "id": 5041,
    "traditional": "雙打",
    "simplified": "双打",
    "reading": "shuāngdǎ",
    "meaning": "foursome, doubles",
    "grade": 6
  },
  {
    "id": 5042,
    "traditional": "爽",
    "simplified": "爽",
    "reading": "shuǎng",
    "meaning": "comfortable",
    "grade": 6
  },
  {
    "id": 5043,
    "traditional": "水泥",
    "simplified": "水泥",
    "reading": "shuǐní",
    "meaning": "cement",
    "grade": 6
  },
  {
    "id": 5044,
    "traditional": "稅",
    "simplified": "税",
    "reading": "shuì",
    "meaning": "droit, tax, geld, due, scot, exaction, capital levy, gabelle, duty, taxation, revenue enhancement",
    "grade": 6
  },
  {
    "id": 5045,
    "traditional": "順",
    "simplified": "顺",
    "reading": "shùn",
    "meaning": "to obey, to follow, to arrange, to make reasonable, along, favorable",
    "grade": 6
  },
  {
    "id": 5046,
    "traditional": "薯條",
    "simplified": "薯条",
    "reading": "shǔtiáo",
    "meaning": "french fries, french fried potatoes, chips",
    "grade": 6
  },
  {
    "id": 5047,
    "traditional": "說明書",
    "simplified": "说明书",
    "reading": "shuōmíngshū",
    "meaning": "(technical) manual, (book of) directions, synopsis (of play/etc.)",
    "grade": 6
  },
  {
    "id": 5048,
    "traditional": "司長",
    "simplified": "司长",
    "reading": "sīzhǎng",
    "meaning": "bureau chief",
    "grade": 6
  },
  {
    "id": 5049,
    "traditional": "說實話",
    "simplified": "说实话",
    "reading": "shuō shíhuà",
    "meaning": "to speak the truth, truth to tell, frankly",
    "grade": 6
  },
  {
    "id": 5050,
    "traditional": "死亡",
    "simplified": "死亡",
    "reading": "sǐwáng",
    "meaning": "be dead/doomed",
    "grade": 6
  },
  {
    "id": 5051,
    "traditional": "四處",
    "simplified": "四处",
    "reading": "sìchù",
    "meaning": "about, all around, everywhere, everyplace, all over, throughout, around",
    "grade": 6
  },
  {
    "id": 5052,
    "traditional": "寺",
    "simplified": "寺",
    "reading": "sì",
    "meaning": "Buddhist temple, mosque, government office (old)",
    "grade": 6
  },
  {
    "id": 5053,
    "traditional": "送禮",
    "simplified": "送礼",
    "reading": "sòng lǐ",
    "meaning": "to give a present",
    "grade": 6
  },
  {
    "id": 5054,
    "traditional": "送行",
    "simplified": "送行",
    "reading": "sòng xíng",
    "meaning": "to see someone off, to throw someone a send-off party",
    "grade": 6
  },
  {
    "id": 5055,
    "traditional": "素質",
    "simplified": "素质",
    "reading": "sùzhì",
    "meaning": "calibre, fibre, stuff, vocation, diathesis, caliber, character, strain, predisposition, quality, timber",
    "grade": 6
  },
  {
    "id": 5056,
    "traditional": "算是",
    "simplified": "算是",
    "reading": "suànshì",
    "meaning": "regard_as, considered to be",
    "grade": 6
  },
  {
    "id": 5057,
    "traditional": "雖",
    "simplified": "虽",
    "reading": "suī",
    "meaning": "conj.: though, although, even if",
    "grade": 6
  },
  {
    "id": 5058,
    "traditional": "算了",
    "simplified": "算了",
    "reading": "suànle",
    "meaning": "Forget about it., That's enough!",
    "grade": 6
  },
  {
    "id": 5059,
    "traditional": "踏實",
    "simplified": "踏实",
    "reading": "tāshi",
    "meaning": "dependable, at ease, solid",
    "grade": 6
  },
  {
    "id": 5060,
    "traditional": "歲數",
    "simplified": "岁数",
    "reading": "suìshu",
    "meaning": "age (number of years old)",
    "grade": 6
  },
  {
    "id": 5061,
    "traditional": "塔",
    "simplified": "塔",
    "reading": "tǎ",
    "meaning": "pagoda, stupa, tower",
    "grade": 6
  },
  {
    "id": 5062,
    "traditional": "踏",
    "simplified": "踏",
    "reading": "tà",
    "meaning": "tread, trample, stamp, step, step on",
    "grade": 6
  },
  {
    "id": 5063,
    "traditional": "所",
    "simplified": "所",
    "reading": "suǒ",
    "meaning": "that which",
    "grade": 6
  },
  {
    "id": 5064,
    "traditional": "檯燈",
    "simplified": "台灯",
    "reading": "táidēng",
    "meaning": "desk lamp, table lamp",
    "grade": 6
  },
  {
    "id": 5065,
    "traditional": "嘆氣",
    "simplified": "叹气",
    "reading": "tàn qì",
    "meaning": "to sigh, to heave a sigh",
    "grade": 6
  },
  {
    "id": 5066,
    "traditional": "探索",
    "simplified": "探索",
    "reading": "tànsuǒ",
    "meaning": "explore, probe",
    "grade": 6
  },
  {
    "id": 5067,
    "traditional": "太陽能",
    "simplified": "太阳能",
    "reading": "tàiyángnéng",
    "meaning": "solar energy",
    "grade": 6
  },
  {
    "id": 5068,
    "traditional": "探討",
    "simplified": "探讨",
    "reading": "tàntǎo",
    "meaning": "inquire into, explore",
    "grade": 6
  },
  {
    "id": 5069,
    "traditional": "趟",
    "simplified": "趟",
    "reading": "tàng",
    "meaning": "m.[activity]",
    "grade": 6
  },
  {
    "id": 5070,
    "traditional": "掏",
    "simplified": "掏",
    "reading": "tāo",
    "meaning": "to fish out (from pocket), to scoop, variant of 掏[tao1]",
    "grade": 6
  },
  {
    "id": 5071,
    "traditional": "特大",
    "simplified": "特大",
    "reading": "tèdà",
    "meaning": "Extraordinary",
    "grade": 6
  },
  {
    "id": 5072,
    "traditional": "特地",
    "simplified": "特地",
    "reading": "tèdì",
    "meaning": "purposely, specially, on purpose",
    "grade": 6
  },
  {
    "id": 5073,
    "traditional": "特",
    "simplified": "特",
    "reading": "tè",
    "meaning": "unusual, special, extraordinarily",
    "grade": 6
  },
  {
    "id": 5074,
    "traditional": "特快",
    "simplified": "特快",
    "reading": "tèkuài",
    "meaning": "express (train, delivery etc)",
    "grade": 6
  },
  {
    "id": 5075,
    "traditional": "疼痛",
    "simplified": "疼痛",
    "reading": "téngtòng",
    "meaning": "pain, soreness, fester, suffer, hurt, ache, sore",
    "grade": 6
  },
  {
    "id": 5076,
    "traditional": "特意",
    "simplified": "特意",
    "reading": "tèyì",
    "meaning": "specially, intentionally",
    "grade": 6
  },
  {
    "id": 5077,
    "traditional": "踢",
    "simplified": "踢",
    "reading": "tī",
    "meaning": "shin, spurn, drop-kick, prick, boot, kick, toe, dropkick, play, lunge, punt",
    "grade": 6
  },
  {
    "id": 5078,
    "traditional": "提交",
    "simplified": "提交",
    "reading": "tíjiāo",
    "meaning": "to submit (a report etc), to refer (a problem) to sb",
    "grade": 6
  },
  {
    "id": 5079,
    "traditional": "提升",
    "simplified": "提升",
    "reading": "tíshēng",
    "meaning": "promote, hoist, elevate",
    "grade": 6
  },
  {
    "id": 5080,
    "traditional": "天然",
    "simplified": "天然",
    "reading": "tiānrán",
    "meaning": "natural",
    "grade": 6
  },
  {
    "id": 5081,
    "traditional": "天堂",
    "simplified": "天堂",
    "reading": "tiāntáng",
    "meaning": "paradise, heaven",
    "grade": 6
  },
  {
    "id": 5082,
    "traditional": "田",
    "simplified": "田",
    "reading": "tián",
    "meaning": "farmland, glebe, cropland, Surname, field, plow, Tian",
    "grade": 6
  },
  {
    "id": 5083,
    "traditional": "天下",
    "simplified": "天下",
    "reading": "tiānxià",
    "meaning": "rule, world, China, domination, everywhere under heaven",
    "grade": 6
  },
  {
    "id": 5084,
    "traditional": "添",
    "simplified": "添",
    "reading": "tiān",
    "meaning": "add, fuel, appose, addition, increase, have a baby",
    "grade": 6
  },
  {
    "id": 5085,
    "traditional": "田徑",
    "simplified": "田径",
    "reading": "tiánjìng",
    "meaning": "track and field, track-and-field, athlete, athletics, sport",
    "grade": 6
  },
  {
    "id": 5086,
    "traditional": "跳水",
    "simplified": "跳水",
    "reading": "tiàoshuǐ",
    "meaning": "dive",
    "grade": 6
  },
  {
    "id": 5087,
    "traditional": "通報",
    "simplified": "通报",
    "reading": "tōngbào",
    "meaning": "to inform, to notify, to announce, circular, bulletin, (scientific) journal",
    "grade": 6
  },
  {
    "id": 5088,
    "traditional": "聽取",
    "simplified": "听取",
    "reading": "tīngqǔ",
    "meaning": "to hear (news), to listen to",
    "grade": 6
  },
  {
    "id": 5089,
    "traditional": "通道",
    "simplified": "通道",
    "reading": "tōngdào",
    "meaning": "path, scuttle, headway, corridor, passageway, gateway, gallery, close, walkway, areaway, hatchway, thoroughfare, lobby, winze, passage, aisle, opening, gangway, gap, passage(way), clearance, passing, headroom, enterclose, inlet, jackladder, access, byway",
    "grade": 6
  },
  {
    "id": 5090,
    "traditional": "通紅",
    "simplified": "通红",
    "reading": "tōnghóng",
    "meaning": "very red, red through and through, to blush (deep red)",
    "grade": 6
  },
  {
    "id": 5091,
    "traditional": "通行",
    "simplified": "通行",
    "reading": "tōngxíng",
    "meaning": "pass/go through, be current or of general use",
    "grade": 6
  },
  {
    "id": 5092,
    "traditional": "通話",
    "simplified": "通话",
    "reading": "tōnghuà",
    "meaning": "converse, communicate by telephone",
    "grade": 6
  },
  {
    "id": 5093,
    "traditional": "同",
    "simplified": "同",
    "reading": "tóng",
    "meaning": "det.: simialr, same",
    "grade": 6
  },
  {
    "id": 5094,
    "traditional": "同胞",
    "simplified": "同胞",
    "reading": "tóngbāo",
    "meaning": "offspring of same parents, paisano, neighbour, countrymen, countryfolk, fellow countryman, neighbor, compatriot, countryman, cousin",
    "grade": 6
  },
  {
    "id": 5095,
    "traditional": "同期",
    "simplified": "同期",
    "reading": "tóngqī",
    "meaning": "the corresponding time period (in a different year etc), concurrent, synchronous",
    "grade": 6
  },
  {
    "id": 5096,
    "traditional": "通訊",
    "simplified": "通讯",
    "reading": "tōngxùn",
    "meaning": "communication, news report, newsletter",
    "grade": 6
  },
  {
    "id": 5097,
    "traditional": "同行",
    "simplified": "同行",
    "reading": "tóngháng",
    "meaning": "person of the same profession, of the same trade, occupation or industry, to journey together",
    "grade": 6
  },
  {
    "id": 5098,
    "traditional": "銅牌",
    "simplified": "铜牌",
    "reading": "tóngpái",
    "meaning": "bronze medal, bronze plaque bearing a business name or logo etc, CL:枚[mei2]",
    "grade": 6
  },
  {
    "id": 5099,
    "traditional": "同一",
    "simplified": "同一",
    "reading": "tóngyī",
    "meaning": "identical, the same",
    "grade": 6
  },
  {
    "id": 5100,
    "traditional": "頭疼",
    "simplified": "头疼",
    "reading": "tóuténg",
    "meaning": "headache",
    "grade": 6
  },
  {
    "id": 5101,
    "traditional": "投票",
    "simplified": "投票",
    "reading": "tóu piào",
    "meaning": "ballot, tender, bid, poll, cast, cast a vote, vote",
    "grade": 6
  },
  {
    "id": 5102,
    "traditional": "圖書",
    "simplified": "图书",
    "reading": "túshu",
    "meaning": "picture_book, books",
    "grade": 6
  },
  {
    "id": 5103,
    "traditional": "透露",
    "simplified": "透露",
    "reading": "tòulù",
    "meaning": "expose, disclose, divulge, develop, impart, unrip, confide, sound, fall, disembosom, get around, reveal, let_on, disclosure, let on, leakage, divulgence, get out, discover, let out, leak, declare, break, give away, bring out",
    "grade": 6
  },
  {
    "id": 5104,
    "traditional": "途徑",
    "simplified": "途径",
    "reading": "tújìng",
    "meaning": "track, path, pathway, footpath, avenue, gateway, channel, road, way, pipeline, curriculum",
    "grade": 6
  },
  {
    "id": 5105,
    "traditional": "徒弟",
    "simplified": "徒弟",
    "reading": "túdi",
    "meaning": "apprentice, disciple",
    "grade": 6
  },
  {
    "id": 5106,
    "traditional": "土",
    "simplified": "土",
    "reading": "tǔ",
    "meaning": "soil, earth, clay, land",
    "grade": 6
  },
  {
    "id": 5107,
    "traditional": "團隊",
    "simplified": "团队",
    "reading": "tuánduì",
    "meaning": "team",
    "grade": 6
  },
  {
    "id": 5108,
    "traditional": "退票",
    "simplified": "退票",
    "reading": "tuìpiào",
    "meaning": "to bounce (a check), to return a ticket, ticket refund",
    "grade": 6
  },
  {
    "id": 5109,
    "traditional": "吞",
    "simplified": "吞",
    "reading": "tūn",
    "meaning": "to swallow, to take",
    "grade": 6
  },
  {
    "id": 5110,
    "traditional": "推出",
    "simplified": "推出",
    "reading": "tuīchū",
    "meaning": "present to public",
    "grade": 6
  },
  {
    "id": 5111,
    "traditional": "托",
    "simplified": "托",
    "reading": "tuō",
    "meaning": "hold in palm, support from under, serve as foil, set off, plead, give as a pretext, rely on, entrust, put lining in",
    "grade": 6
  },
  {
    "id": 5112,
    "traditional": "拖",
    "simplified": "拖",
    "reading": "tuō",
    "meaning": "pull, drag, haul",
    "grade": 6
  },
  {
    "id": 5113,
    "traditional": "拖鞋",
    "simplified": "拖鞋",
    "reading": "tuōxié",
    "meaning": "slippers, sandals, flip-flops, CL:雙|双[shuang1],隻|只[zhi1]",
    "grade": 6
  },
  {
    "id": 5114,
    "traditional": "挖",
    "simplified": "挖",
    "reading": "wā",
    "meaning": "scrape, scoop up, scoop, pick, excavate, excavation, grub, lift out, take up, scoop out, dig, grave",
    "grade": 6
  },
  {
    "id": 5115,
    "traditional": "娃娃",
    "simplified": "娃娃",
    "reading": "wáwa",
    "meaning": "mammet, baby, doll, child",
    "grade": 6
  },
  {
    "id": 5116,
    "traditional": "外幣",
    "simplified": "外币",
    "reading": "wàibì",
    "meaning": "foreign currency",
    "grade": 6
  },
  {
    "id": 5117,
    "traditional": "外部",
    "simplified": "外部",
    "reading": "wàibù",
    "meaning": "outer, surface, external, outwall, outside, exterior",
    "grade": 6
  },
  {
    "id": 5118,
    "traditional": "外出",
    "simplified": "外出",
    "reading": "wàichū",
    "meaning": "go out",
    "grade": 6
  },
  {
    "id": 5119,
    "traditional": "哇",
    "simplified": "哇",
    "reading": "wa",
    "meaning": "Wah!",
    "grade": 6
  },
  {
    "id": 5120,
    "traditional": "外科",
    "simplified": "外科",
    "reading": "wàikē",
    "meaning": "surgery, chirurgery, surgical, surgical department",
    "grade": 6
  },
  {
    "id": 5121,
    "traditional": "外觀",
    "simplified": "外观",
    "reading": "wàiguān",
    "meaning": "shell, outward appearance, show, externality, visual aspect, exterior, effect, superficies, look, likeness, appearance, facade, surface, presentment, apparel, impression, facies, aspect, complexion, resemblance, semblance, guise, frontage, outwardness, rind, garb, showing, face, veneer, outside, garment, getup",
    "grade": 6
  },
  {
    "id": 5122,
    "traditional": "外衣",
    "simplified": "外衣",
    "reading": "wàiyī",
    "meaning": "outer clothing, semblance, appearance",
    "grade": 6
  },
  {
    "id": 5123,
    "traditional": "外來",
    "simplified": "外来",
    "reading": "wàilái",
    "meaning": "foreign, adventive, externally, incoming, external, outside, exotic",
    "grade": 6
  },
  {
    "id": 5124,
    "traditional": "外資",
    "simplified": "外资",
    "reading": "wàizī",
    "meaning": "foreign capital",
    "grade": 6
  },
  {
    "id": 5125,
    "traditional": "外頭",
    "simplified": "外头",
    "reading": "wàitou",
    "meaning": "outside, out",
    "grade": 6
  },
  {
    "id": 5126,
    "traditional": "彎曲",
    "simplified": "弯曲",
    "reading": "wānqū",
    "meaning": "distort",
    "grade": 6
  },
  {
    "id": 5127,
    "traditional": "頑皮",
    "simplified": "顽皮",
    "reading": "wánpí",
    "meaning": "naughty",
    "grade": 6
  },
  {
    "id": 5128,
    "traditional": "頑強",
    "simplified": "顽强",
    "reading": "wánqiáng",
    "meaning": "tenacious, hard to defeat",
    "grade": 6
  },
  {
    "id": 5129,
    "traditional": "王后",
    "simplified": "王后",
    "reading": "wánghòu",
    "meaning": "queen, CL:個|个[ge4],位[wei4]",
    "grade": 6
  },
  {
    "id": 5130,
    "traditional": "王子",
    "simplified": "王子",
    "reading": "wángzǐ",
    "meaning": "king's son, prince",
    "grade": 6
  },
  {
    "id": 5131,
    "traditional": "往後",
    "simplified": "往后",
    "reading": "wǎnghòu",
    "meaning": "from now on, in the future, time to come",
    "grade": 6
  },
  {
    "id": 5132,
    "traditional": "網吧",
    "simplified": "网吧",
    "reading": "wǎngbā",
    "meaning": "Internet café",
    "grade": 6
  },
  {
    "id": 5133,
    "traditional": "網頁",
    "simplified": "网页",
    "reading": "wǎngyè",
    "meaning": "web page",
    "grade": 6
  },
  {
    "id": 5134,
    "traditional": "往年",
    "simplified": "往年",
    "reading": "wǎngnián",
    "meaning": "in former years, in previous years",
    "grade": 6
  },
  {
    "id": 5135,
    "traditional": "望見",
    "simplified": "望见",
    "reading": "wàngjiàn",
    "meaning": "to espy, to spot",
    "grade": 6
  },
  {
    "id": 5136,
    "traditional": "危機",
    "simplified": "危机",
    "reading": "wēijī",
    "meaning": "ambush, clutch, climacteric, crisis, precipice, hump, conjuncture, juncture, sharpness, gravity, edge",
    "grade": 6
  },
  {
    "id": 5137,
    "traditional": "威脅",
    "simplified": "威胁",
    "reading": "wēixié",
    "meaning": "threat",
    "grade": 6
  },
  {
    "id": 5138,
    "traditional": "往來",
    "simplified": "往来",
    "reading": "wǎnglái",
    "meaning": "contact, intercourse",
    "grade": 6
  },
  {
    "id": 5139,
    "traditional": "微波爐",
    "simplified": "微波炉",
    "reading": "wēibōlú",
    "meaning": "microwave oven, CL:臺|台[tai2]",
    "grade": 6
  },
  {
    "id": 5140,
    "traditional": "維生素",
    "simplified": "维生素",
    "reading": "wéishēngsù",
    "meaning": "vitamin",
    "grade": 6
  },
  {
    "id": 5141,
    "traditional": "為此",
    "simplified": "为此",
    "reading": "wèicǐ",
    "meaning": "for this reason, with regards to this, in this respect, in order to do this, to this end",
    "grade": 6
  },
  {
    "id": 5142,
    "traditional": "為何",
    "simplified": "为何",
    "reading": "wèihé",
    "meaning": "why, for what reason",
    "grade": 6
  },
  {
    "id": 5143,
    "traditional": "臥鋪",
    "simplified": "卧铺",
    "reading": "wòpù",
    "meaning": "a bed (on a train), a couchette",
    "grade": 6
  },
  {
    "id": 5144,
    "traditional": "文娛",
    "simplified": "文娱",
    "reading": "wényú",
    "meaning": "cultural recreation, entertainment",
    "grade": 6
  },
  {
    "id": 5145,
    "traditional": "無邊",
    "simplified": "无边",
    "reading": "wúbiān",
    "meaning": "without boundary, not bordered",
    "grade": 6
  },
  {
    "id": 5146,
    "traditional": "烏雲",
    "simplified": "乌云",
    "reading": "wūyún",
    "meaning": "black cloud",
    "grade": 6
  },
  {
    "id": 5147,
    "traditional": "無關",
    "simplified": "无关",
    "reading": "wúguān",
    "meaning": "have nothing to do with",
    "grade": 6
  },
  {
    "id": 5148,
    "traditional": "物品",
    "simplified": "物品",
    "reading": "wùpǐn",
    "meaning": "whatchamacallit, sundry, stuff, commodity, affair, physical object, goods, article, object, content, whatsis, res, sundries",
    "grade": 6
  },
  {
    "id": 5149,
    "traditional": "無效",
    "simplified": "无效",
    "reading": "wúxiào",
    "meaning": "of/to no avail, null and void",
    "grade": 6
  },
  {
    "id": 5150,
    "traditional": "誤",
    "simplified": "误",
    "reading": "wù",
    "meaning": "mistake, error, to miss, to harm, to delay, to neglect, mistakenly",
    "grade": 6
  },
  {
    "id": 5151,
    "traditional": "舞蹈",
    "simplified": "舞蹈",
    "reading": "wǔdǎo",
    "meaning": "dancing, saltation, nautch, dance, terpsichore, choreography, stage dancing",
    "grade": 6
  },
  {
    "id": 5152,
    "traditional": "西班牙語",
    "simplified": "西班牙语",
    "reading": "Xībānyáyǔ",
    "meaning": "Spanish language",
    "grade": 6
  },
  {
    "id": 5153,
    "traditional": "吸毒",
    "simplified": "吸毒",
    "reading": "xī dú",
    "meaning": "take drugs",
    "grade": 6
  },
  {
    "id": 5154,
    "traditional": "犧牲",
    "simplified": "牺牲",
    "reading": "xīshēng",
    "meaning": "sacrifice oneself, die martyr's death, do sth. at the expense of self or sb. else",
    "grade": 6
  },
  {
    "id": 5155,
    "traditional": "洗衣粉",
    "simplified": "洗衣粉",
    "reading": "xǐyīfěn",
    "meaning": "laundry detergent, washing powder",
    "grade": 6
  },
  {
    "id": 5156,
    "traditional": "細胞",
    "simplified": "细胞",
    "reading": "xìbāo",
    "meaning": "cell",
    "grade": 6
  },
  {
    "id": 5157,
    "traditional": "戲曲",
    "simplified": "戏曲",
    "reading": "xìqǔ",
    "meaning": "traditional opera, drama, trad. opera, singing parts in ^chuan2 qi2 ^ and ^za2, singing parts in ^chuan2qi2^ and ^za2ju4^",
    "grade": 6
  },
  {
    "id": 5158,
    "traditional": "細菌",
    "simplified": "细菌",
    "reading": "xìjūn",
    "meaning": "bacillus, bacilli, bacteria, B, bacteriological, microbe, contaminant, bacterium, clump, germ, microphyte, bacterial",
    "grade": 6
  },
  {
    "id": 5159,
    "traditional": "先鋒",
    "simplified": "先锋",
    "reading": "xiānfēng",
    "meaning": "vanguard, pioneer",
    "grade": 6
  },
  {
    "id": 5160,
    "traditional": "嫌",
    "simplified": "嫌",
    "reading": "xián",
    "meaning": "dislike, mind",
    "grade": 6
  },
  {
    "id": 5161,
    "traditional": "顯出",
    "simplified": "显出",
    "reading": "xiǎn chū",
    "meaning": "to express, to exhibit",
    "grade": 6
  },
  {
    "id": 5162,
    "traditional": "險",
    "simplified": "险",
    "reading": "xiǎn",
    "meaning": "danger, dangerous, rugged",
    "grade": 6
  },
  {
    "id": 5163,
    "traditional": "陷入",
    "simplified": "陷入",
    "reading": "xiànrù",
    "meaning": "plunge, be immersed in, land oneself in, slump, be caught in, lapse, launch, get_into, fall_in, invaginate, be deep in, engulf, run_into, fall_into, be lost/immersed in, be lost, sink into, tangle, fall into, immerse, sink, be lost in, bed, immersed in, get bogged down in, land, sink/fall into",
    "grade": 6
  },
  {
    "id": 5164,
    "traditional": "響聲",
    "simplified": "响声",
    "reading": "xiǎngshēng",
    "meaning": "noise",
    "grade": 6
  },
  {
    "id": 5165,
    "traditional": "線路",
    "simplified": "线路",
    "reading": "xiànlù",
    "meaning": "wiring, line, route, circuit",
    "grade": 6
  },
  {
    "id": 5166,
    "traditional": "想不到",
    "simplified": "想不到",
    "reading": "xiǎngbudào",
    "meaning": "unable to anticipate",
    "grade": 6
  },
  {
    "id": 5167,
    "traditional": "消耗",
    "simplified": "消耗",
    "reading": "xiāohào",
    "meaning": "consume, use up, deplete",
    "grade": 6
  },
  {
    "id": 5168,
    "traditional": "消滅",
    "simplified": "消灭",
    "reading": "xiāomiè",
    "meaning": "eradicate, die out, eliminate, abolish, wipe out, annihilate, kill, slake, decimate, carry off, disappear, exterminate, lapse, expunge, abolition, knock out, quell, destroy, pass away, kill off, deafen, extermination, detruncate, liquidate, snuff_out, snuff out, extirpate, snuff, elimination, perish, discreate, vanish, extinguish",
    "grade": 6
  },
  {
    "id": 5169,
    "traditional": "小費",
    "simplified": "小费",
    "reading": "xiǎofèi",
    "meaning": "tip, gratuity",
    "grade": 6
  },
  {
    "id": 5170,
    "traditional": "小麥",
    "simplified": "小麦",
    "reading": "xiǎomài",
    "meaning": "wheat, CL:粒[li4]",
    "grade": 6
  },
  {
    "id": 5171,
    "traditional": "小於",
    "simplified": "小于",
    "reading": "xiǎoyú",
    "meaning": "less than, <",
    "grade": 6
  },
  {
    "id": 5172,
    "traditional": "笑臉",
    "simplified": "笑脸",
    "reading": "xiàoliǎn",
    "meaning": "smiling face, smiley :) ☺, CL:副[fu4]",
    "grade": 6
  },
  {
    "id": 5173,
    "traditional": "曉得",
    "simplified": "晓得",
    "reading": "xiǎode",
    "meaning": "tell, know, ken",
    "grade": 6
  },
  {
    "id": 5174,
    "traditional": "笑容",
    "simplified": "笑容",
    "reading": "xiàoróng",
    "meaning": "grinning, smiling expression, laugh, smiling, a smile, a smiling face, smile, grin",
    "grade": 6
  },
  {
    "id": 5175,
    "traditional": "笑聲",
    "simplified": "笑声",
    "reading": "xiàoshēng",
    "meaning": "laughter",
    "grade": 6
  },
  {
    "id": 5176,
    "traditional": "協會",
    "simplified": "协会",
    "reading": "xiéhuì",
    "meaning": "an association, a society, CL:個|个[ge4],家[jia1]",
    "grade": 6
  },
  {
    "id": 5177,
    "traditional": "協商",
    "simplified": "协商",
    "reading": "xiéshāng",
    "meaning": "consult, talk things over",
    "grade": 6
  },
  {
    "id": 5178,
    "traditional": "協調",
    "simplified": "协调",
    "reading": "xiétiáo",
    "meaning": "coordinate, harmonize, bring into line",
    "grade": 6
  },
  {
    "id": 5179,
    "traditional": "寫字樓",
    "simplified": "写字楼",
    "reading": "xiězìlóu",
    "meaning": "office building",
    "grade": 6
  },
  {
    "id": 5180,
    "traditional": "協助",
    "simplified": "协助",
    "reading": "xiézhù",
    "meaning": "assist, help",
    "grade": 6
  },
  {
    "id": 5181,
    "traditional": "寫字檯",
    "simplified": "写字台",
    "reading": "xiězìtái",
    "meaning": "writing desk",
    "grade": 6
  },
  {
    "id": 5182,
    "traditional": "心願",
    "simplified": "心愿",
    "reading": "xīnyuàn",
    "meaning": "breathing",
    "grade": 6
  },
  {
    "id": 5183,
    "traditional": "心臟",
    "simplified": "心脏",
    "reading": "xīnzàng",
    "meaning": "ticker, cardiac, center, pump, heart",
    "grade": 6
  },
  {
    "id": 5184,
    "traditional": "心靈",
    "simplified": "心灵",
    "reading": "xīnlíng",
    "meaning": "psychical, mind, psyche, soul, spirit, mens, heart",
    "grade": 6
  },
  {
    "id": 5185,
    "traditional": "心臟病",
    "simplified": "心脏病",
    "reading": "xīnzàngbìng",
    "meaning": "heart disease",
    "grade": 6
  },
  {
    "id": 5186,
    "traditional": "新人",
    "simplified": "新人",
    "reading": "xīnrén",
    "meaning": "newlywed",
    "grade": 6
  },
  {
    "id": 5187,
    "traditional": "新興",
    "simplified": "新兴",
    "reading": "xīnxīng",
    "meaning": "burgeoning, new and developing, newly_arisen, rising",
    "grade": 6
  },
  {
    "id": 5188,
    "traditional": "薪水",
    "simplified": "薪水",
    "reading": "xīnshui",
    "meaning": "stipend, emolument, remuneration, screw, pay, wages, earnings, fee, compensation, pay_packet, salary, wage, living",
    "grade": 6
  },
  {
    "id": 5189,
    "traditional": "信仰",
    "simplified": "信仰",
    "reading": "xìnyǎng",
    "meaning": "belief",
    "grade": 6
  },
  {
    "id": 5190,
    "traditional": "信用",
    "simplified": "信用",
    "reading": "xìnyòng",
    "meaning": "honour, trustfulness, confidence, trustingness, trustworthiness, repute, affiance, credit, reposal, trust, tick, reputation, credence, jawbone, honor",
    "grade": 6
  },
  {
    "id": 5191,
    "traditional": "行程",
    "simplified": "行程",
    "reading": "xíngchéng",
    "meaning": "throw, travel, tenor, itinerary, route, journey, route/distance of travel, distance of travel, stroke, route of travel",
    "grade": 6
  },
  {
    "id": 5192,
    "traditional": "興旺",
    "simplified": "兴旺",
    "reading": "xīngwàng",
    "meaning": "prosperous, thriving, to prosper, to flourish",
    "grade": 6
  },
  {
    "id": 5193,
    "traditional": "形",
    "simplified": "形",
    "reading": "xíng",
    "meaning": "form, shape, body, entity",
    "grade": 6
  },
  {
    "id": 5194,
    "traditional": "凶手",
    "simplified": "凶手",
    "reading": "xiōngshǒu",
    "meaning": "murderer, assassin",
    "grade": 6
  },
  {
    "id": 5195,
    "traditional": "袖珍",
    "simplified": "袖珍",
    "reading": "xiùzhēn",
    "meaning": "pocket-sized, pocket (book etc)",
    "grade": 6
  },
  {
    "id": 5196,
    "traditional": "修車",
    "simplified": "修车",
    "reading": "xiūchē",
    "meaning": "to repair a bike (car etc)",
    "grade": 6
  },
  {
    "id": 5197,
    "traditional": "凶",
    "simplified": "凶",
    "reading": "xiōng",
    "meaning": "vicious, fierce, ominous, inauspicious, famine, variant of 兇|凶[xiong1]",
    "grade": 6
  },
  {
    "id": 5198,
    "traditional": "懸",
    "simplified": "悬",
    "reading": "xuán",
    "meaning": "to hang or suspend, to worry, public announcement, unresolved, baseless, without foundation",
    "grade": 6
  },
  {
    "id": 5199,
    "traditional": "旋轉",
    "simplified": "旋转",
    "reading": "xuánzhuǎn",
    "meaning": "revolve, gyrate, spin",
    "grade": 6
  },
  {
    "id": 5200,
    "traditional": "選拔",
    "simplified": "选拔",
    "reading": "xuǎnbá",
    "meaning": "to select the best",
    "grade": 6
  },
  {
    "id": 5201,
    "traditional": "學會",
    "simplified": "学会",
    "reading": "xué huì",
    "meaning": "learned society, (scholarly) association",
    "grade": 6
  },
  {
    "id": 5202,
    "traditional": "選舉",
    "simplified": "选举",
    "reading": "xuǎnjǔ",
    "meaning": "election",
    "grade": 6
  },
  {
    "id": 5203,
    "traditional": "學員",
    "simplified": "学员",
    "reading": "xuéyuán",
    "meaning": "trainee, member of institution of learning, student",
    "grade": 6
  },
  {
    "id": 5204,
    "traditional": "血液",
    "simplified": "血液",
    "reading": "xuèyè",
    "meaning": "blood",
    "grade": 6
  },
  {
    "id": 5205,
    "traditional": "血管",
    "simplified": "血管",
    "reading": "xuèguǎn",
    "meaning": "afferent, vas, vena, blood_vessel, vessel, vein, blood vessel",
    "grade": 6
  },
  {
    "id": 5206,
    "traditional": "壓迫",
    "simplified": "压迫",
    "reading": "yāpò",
    "meaning": "oppress, repress, constrict",
    "grade": 6
  },
  {
    "id": 5207,
    "traditional": "循環",
    "simplified": "循环",
    "reading": "xúnhuán",
    "meaning": "circulate, cycle.circulatory system",
    "grade": 6
  },
  {
    "id": 5208,
    "traditional": "煙花",
    "simplified": "烟花",
    "reading": "yānhuā",
    "meaning": "fireworks, prostitute (esp. in Yuan theater)",
    "grade": 6
  },
  {
    "id": 5209,
    "traditional": "沿海",
    "simplified": "沿海",
    "reading": "yánhǎi",
    "meaning": "coast, coastal, along the coast, littoral",
    "grade": 6
  },
  {
    "id": 5210,
    "traditional": "沿",
    "simplified": "沿",
    "reading": "yán",
    "meaning": "along",
    "grade": 6
  },
  {
    "id": 5211,
    "traditional": "沿著",
    "simplified": "沿着",
    "reading": "yánzhe",
    "meaning": "to go along, to follow",
    "grade": 6
  },
  {
    "id": 5212,
    "traditional": "研發",
    "simplified": "研发",
    "reading": "yánfā",
    "meaning": "research and develop, R&D, research and develop something, research and development",
    "grade": 6
  },
  {
    "id": 5213,
    "traditional": "眼看",
    "simplified": "眼看",
    "reading": "yǎnkàn",
    "meaning": "see sth. happen, watch helplessly",
    "grade": 6
  },
  {
    "id": 5214,
    "traditional": "演奏",
    "simplified": "演奏",
    "reading": "yǎnzòu",
    "meaning": "give instrumental performance",
    "grade": 6
  },
  {
    "id": 5215,
    "traditional": "宴會",
    "simplified": "宴会",
    "reading": "yànhuì",
    "meaning": "banquet, feast, dinner party, CL:席[xi2],個|个[ge4]",
    "grade": 6
  },
  {
    "id": 5216,
    "traditional": "仰",
    "simplified": "仰",
    "reading": "yǎng",
    "meaning": "surname Yang, to face upward, to look up, to admire, to rely on",
    "grade": 6
  },
  {
    "id": 5217,
    "traditional": "洋",
    "simplified": "洋",
    "reading": "yáng",
    "meaning": "ocean, vast, foreign, silver dollar or coin",
    "grade": 6
  },
  {
    "id": 5218,
    "traditional": "養老",
    "simplified": "养老",
    "reading": "yǎng lǎo",
    "meaning": "to provide for the elderly (family members), to enjoy a life in retirement",
    "grade": 6
  },
  {
    "id": 5219,
    "traditional": "樣",
    "simplified": "样",
    "reading": "yàng",
    "meaning": "m.[kind]",
    "grade": 6
  },
  {
    "id": 5220,
    "traditional": "氧氣",
    "simplified": "氧气",
    "reading": "yǎngqì",
    "meaning": "O, atomic number 8, oxygen",
    "grade": 6
  },
  {
    "id": 5221,
    "traditional": "藥品",
    "simplified": "药品",
    "reading": "yàopǐn",
    "meaning": "pharmaceutical, medicines and chemical reagents, drug, restorative",
    "grade": 6
  },
  {
    "id": 5222,
    "traditional": "要不然",
    "simplified": "要不然",
    "reading": "yàoburán",
    "meaning": "conj.: otherwise, or else, or",
    "grade": 6
  },
  {
    "id": 5223,
    "traditional": "要好",
    "simplified": "要好",
    "reading": "yàohǎo",
    "meaning": "to be on good terms, to be close friends, striving for self-improvement",
    "grade": 6
  },
  {
    "id": 5224,
    "traditional": "要麼",
    "simplified": "要么",
    "reading": "yàome",
    "meaning": "or, either one or the other",
    "grade": 6
  },
  {
    "id": 5225,
    "traditional": "要素",
    "simplified": "要素",
    "reading": "yàosù",
    "meaning": "basics, ingredient, essential, key element, constituent, fashioning, element, core, making, stuff, essential factor, factor, essence, strand, elemental, devising, chemistry, requisite, momentum",
    "grade": 6
  },
  {
    "id": 5226,
    "traditional": "野",
    "simplified": "野",
    "reading": "yě",
    "meaning": "wild, undomesticated",
    "grade": 6
  },
  {
    "id": 5227,
    "traditional": "野生",
    "simplified": "野生",
    "reading": "yěshēng",
    "meaning": "desert, wild, godforsaken, waste, feral, uncultivated",
    "grade": 6
  },
  {
    "id": 5228,
    "traditional": "醫藥",
    "simplified": "医药",
    "reading": "yīyào",
    "meaning": "medicament, medicine, curative, medication, remedy, physic",
    "grade": 6
  },
  {
    "id": 5229,
    "traditional": "依次",
    "simplified": "依次",
    "reading": "yīcì",
    "meaning": "in order, in succession",
    "grade": 6
  },
  {
    "id": 5230,
    "traditional": "依賴",
    "simplified": "依赖",
    "reading": "yīlài",
    "meaning": "rely/depend on",
    "grade": 6
  },
  {
    "id": 5231,
    "traditional": "一次性",
    "simplified": "一次性",
    "reading": "yīcìxìng",
    "meaning": "one-off (offer), one-time, single-use, disposable (goods)",
    "grade": 6
  },
  {
    "id": 5232,
    "traditional": "一道",
    "simplified": "一道",
    "reading": "yīdào",
    "meaning": "together",
    "grade": 6
  },
  {
    "id": 5233,
    "traditional": "一代",
    "simplified": "一代",
    "reading": "yīdài",
    "meaning": "generation",
    "grade": 6
  },
  {
    "id": 5234,
    "traditional": "一貫",
    "simplified": "一贯",
    "reading": "yīguàn",
    "meaning": "consistent, persistent, all along",
    "grade": 6
  },
  {
    "id": 5235,
    "traditional": "一路上",
    "simplified": "一路上",
    "reading": "yīlùshàng",
    "meaning": "All the way",
    "grade": 6
  },
  {
    "id": 5236,
    "traditional": "儀器",
    "simplified": "仪器",
    "reading": "yíqì",
    "meaning": "instrumentation, device, setup, apparatus, instrumental, instrument, armamentarium",
    "grade": 6
  },
  {
    "id": 5237,
    "traditional": "儀式",
    "simplified": "仪式",
    "reading": "yíshì",
    "meaning": "religious rite, ceremonial occasion, deeds, practice, form, rituality, office, formality, ritual, circumstance, ceremony, formalness, exercise, powwow, observance, ceremonial, rite, works",
    "grade": 6
  },
  {
    "id": 5238,
    "traditional": "一番",
    "simplified": "一番",
    "reading": "yì fān",
    "meaning": "Some",
    "grade": 6
  },
  {
    "id": 5239,
    "traditional": "遺憾",
    "simplified": "遗憾",
    "reading": "yíhàn",
    "meaning": "regret, pity",
    "grade": 6
  },
  {
    "id": 5240,
    "traditional": "一模一樣",
    "simplified": "一模一样",
    "reading": "yīmú-yīyàng",
    "meaning": "exactly the same (idiom), carbon copy, also pr. [yi1 mo2 yi1 yang4]",
    "grade": 6
  },
  {
    "id": 5241,
    "traditional": "一時",
    "simplified": "一时",
    "reading": "yīshí",
    "meaning": "a period of time, a while, for a short while, temporary, momentary, at the same time",
    "grade": 6
  },
  {
    "id": 5242,
    "traditional": "一同",
    "simplified": "一同",
    "reading": "yītóng",
    "meaning": "together",
    "grade": 6
  },
  {
    "id": 5243,
    "traditional": "一齊",
    "simplified": "一齐",
    "reading": "yīqí",
    "meaning": "simul, in unison, together, simultaneously",
    "grade": 6
  },
  {
    "id": 5244,
    "traditional": "一行",
    "simplified": "一行",
    "reading": "yīxíng",
    "meaning": "party, delegation",
    "grade": 6
  },
  {
    "id": 5245,
    "traditional": "藝人",
    "simplified": "艺人",
    "reading": "yìrén",
    "meaning": "creative person, artiste, entertainer, artist, artisan, cackler, actor, handcraftsman, performer",
    "grade": 6
  },
  {
    "id": 5246,
    "traditional": "議題",
    "simplified": "议题",
    "reading": "yìtí",
    "meaning": "item on the agenda, head, question, topic for discussion, topic for/under discussion, topic under discussion, subject under discussion, under discussion, topic for",
    "grade": 6
  },
  {
    "id": 5247,
    "traditional": "異常",
    "simplified": "异常",
    "reading": "yìcháng",
    "meaning": "unusual, extraordinary, abnormal",
    "grade": 6
  },
  {
    "id": 5248,
    "traditional": "意想不到",
    "simplified": "意想不到",
    "reading": "yìxiǎngbùdào",
    "meaning": "unexpected, previously unimagined",
    "grade": 6
  },
  {
    "id": 5249,
    "traditional": "意願",
    "simplified": "意愿",
    "reading": "yìyuàn",
    "meaning": "will, wish, desire, inclination, aspiration, request, pleasure",
    "grade": 6
  },
  {
    "id": 5250,
    "traditional": "因素",
    "simplified": "因素",
    "reading": "yīnsù",
    "meaning": "consideration, ingredient, constituent, factor, strand, component, element",
    "grade": 6
  },
  {
    "id": 5251,
    "traditional": "因",
    "simplified": "因",
    "reading": "yīn",
    "meaning": "conj.: because, as",
    "grade": 6
  },
  {
    "id": 5252,
    "traditional": "陰謀",
    "simplified": "阴谋",
    "reading": "yīnmóu",
    "meaning": "plot, conspiracy",
    "grade": 6
  },
  {
    "id": 5253,
    "traditional": "音量",
    "simplified": "音量",
    "reading": "yīnliàng",
    "meaning": "intensity, volume, quantity, loudness, volume of sound",
    "grade": 6
  },
  {
    "id": 5254,
    "traditional": "陰影",
    "simplified": "阴影",
    "reading": "yīnyǐng",
    "meaning": "umbrage, shadow, shadiness, hatching, spectre, hatch, blight, umbra, penumbra, cloud, umber, shade, shadowiness",
    "grade": 6
  },
  {
    "id": 5255,
    "traditional": "音像",
    "simplified": "音像",
    "reading": "yīnxiàng",
    "meaning": "audio and video, audiovisual",
    "grade": 6
  },
  {
    "id": 5256,
    "traditional": "隱私",
    "simplified": "隐私",
    "reading": "yǐnsī",
    "meaning": "secrets, private business, privacy",
    "grade": 6
  },
  {
    "id": 5257,
    "traditional": "隱藏",
    "simplified": "隐藏",
    "reading": "yǐncáng",
    "meaning": "cache, hold back, hidden, blot_out, suppress, disguise, concealment, remain under cover, lurk, secrete, reserve, recess, hoodwink, dissemble, stash, veil, hold in, bury, hide, conceal, occult, mask, ensconce, cover_up, palliate, obscure, hoard",
    "grade": 6
  },
  {
    "id": 5258,
    "traditional": "印",
    "simplified": "印",
    "reading": "yìn",
    "meaning": "print, engrave",
    "grade": 6
  },
  {
    "id": 5259,
    "traditional": "迎來",
    "simplified": "迎来",
    "reading": "yínglái",
    "meaning": "Ushered",
    "grade": 6
  },
  {
    "id": 5260,
    "traditional": "英雄",
    "simplified": "英雄",
    "reading": "yīngxióng",
    "meaning": "hero",
    "grade": 6
  },
  {
    "id": 5261,
    "traditional": "影迷",
    "simplified": "影迷",
    "reading": "yǐngmí",
    "meaning": "film enthusiast, movie fan, CL:個|个[ge4]",
    "grade": 6
  },
  {
    "id": 5262,
    "traditional": "影星",
    "simplified": "影星",
    "reading": "yǐngxīng",
    "meaning": "film star",
    "grade": 6
  },
  {
    "id": 5263,
    "traditional": "用處",
    "simplified": "用处",
    "reading": "yòngchu",
    "meaning": "use, value, practical application, function, purpose, point, usefulness, mileage, role",
    "grade": 6
  },
  {
    "id": 5264,
    "traditional": "應急",
    "simplified": "应急",
    "reading": "yìng jí",
    "meaning": "to respond to an emergency, to meet a contingency, (attributive) emergency",
    "grade": 6
  },
  {
    "id": 5265,
    "traditional": "用法",
    "simplified": "用法",
    "reading": "yòngfǎ",
    "meaning": "hang, usage, use",
    "grade": 6
  },
  {
    "id": 5266,
    "traditional": "用得著",
    "simplified": "用得着",
    "reading": "yòngdezháo",
    "meaning": "to be able to use, useable, to have a use for sth, (in interrogative sentence) to be necessary to",
    "grade": 6
  },
  {
    "id": 5267,
    "traditional": "應對",
    "simplified": "应对",
    "reading": "yìngduì",
    "meaning": "reply, answer",
    "grade": 6
  },
  {
    "id": 5268,
    "traditional": "用心",
    "simplified": "用心",
    "reading": "yòng xīn",
    "meaning": "be diligent/attentive",
    "grade": 6
  },
  {
    "id": 5269,
    "traditional": "優質",
    "simplified": "优质",
    "reading": "yōuzhì",
    "meaning": "excellent quality",
    "grade": 6
  },
  {
    "id": 5270,
    "traditional": "用品",
    "simplified": "用品",
    "reading": "yòngpǐn",
    "meaning": "succedaneum, articles for use, replacer, substitute",
    "grade": 6
  },
  {
    "id": 5271,
    "traditional": "遊玩",
    "simplified": "游玩",
    "reading": "yóuwán",
    "meaning": "to amuse oneself, to have fun, to go sightseeing, to take a stroll",
    "grade": 6
  },
  {
    "id": 5272,
    "traditional": "遊人",
    "simplified": "游人",
    "reading": "yóurén",
    "meaning": "a tourist",
    "grade": 6
  },
  {
    "id": 5273,
    "traditional": "遊戲機",
    "simplified": "游戏机",
    "reading": "yóuxìjī",
    "meaning": "video game, game machine",
    "grade": 6
  },
  {
    "id": 5274,
    "traditional": "遊行",
    "simplified": "游行",
    "reading": "yóuxíng",
    "meaning": "march, parade, demonstration",
    "grade": 6
  },
  {
    "id": 5275,
    "traditional": "有關",
    "simplified": "有关",
    "reading": "yǒuguān",
    "meaning": "be related",
    "grade": 6
  },
  {
    "id": 5276,
    "traditional": "有沒有",
    "simplified": "有没有",
    "reading": "yǒuméiyǒu",
    "meaning": "have or not have",
    "grade": 6
  },
  {
    "id": 5277,
    "traditional": "有事",
    "simplified": "有事",
    "reading": "yǒushì",
    "meaning": "be occupied/busy, if problems arise",
    "grade": 6
  },
  {
    "id": 5278,
    "traditional": "於",
    "simplified": "于",
    "reading": "yú",
    "meaning": "in, at, to, from, out of, by",
    "grade": 6
  },
  {
    "id": 5279,
    "traditional": "娛樂",
    "simplified": "娱乐",
    "reading": "yúlè",
    "meaning": "amusement, entertainment, recreation",
    "grade": 6
  },
  {
    "id": 5280,
    "traditional": "愉快",
    "simplified": "愉快",
    "reading": "yúkuài",
    "meaning": "be happy, be cheerful",
    "grade": 6
  },
  {
    "id": 5281,
    "traditional": "與",
    "simplified": "与",
    "reading": "yǔ",
    "meaning": "and, together with, to give",
    "grade": 6
  },
  {
    "id": 5282,
    "traditional": "宇航員",
    "simplified": "宇航员",
    "reading": "yǔhángyuán",
    "meaning": "astronaut",
    "grade": 6
  },
  {
    "id": 5283,
    "traditional": "預約",
    "simplified": "预约",
    "reading": "yùyuē",
    "meaning": "make an appointment",
    "grade": 6
  },
  {
    "id": 5284,
    "traditional": "雨衣",
    "simplified": "雨衣",
    "reading": "yǔyī",
    "meaning": "raincoat, CL:件[jian4]",
    "grade": 6
  },
  {
    "id": 5285,
    "traditional": "元素",
    "simplified": "元素",
    "reading": "yuánsù",
    "meaning": "element",
    "grade": 6
  },
  {
    "id": 5286,
    "traditional": "園",
    "simplified": "园",
    "reading": "yuán",
    "meaning": "surname Yuan, land used for growing plants, site used for public recreation",
    "grade": 6
  },
  {
    "id": 5287,
    "traditional": "園地",
    "simplified": "园地",
    "reading": "yuándì",
    "meaning": "special page in periodical, special section in periodical, park/garden area, special section, croft, page in periodical, garden area, special section/page in periodical, garden plot, park area, field, scope, park",
    "grade": 6
  },
  {
    "id": 5288,
    "traditional": "原",
    "simplified": "原",
    "reading": "yuán",
    "meaning": "originally, formerly",
    "grade": 6
  },
  {
    "id": 5289,
    "traditional": "原告",
    "simplified": "原告",
    "reading": "yuángào",
    "meaning": "complainant, plaintiff",
    "grade": 6
  },
  {
    "id": 5290,
    "traditional": "原諒",
    "simplified": "原谅",
    "reading": "yuánliàng",
    "meaning": "excuse, pardon",
    "grade": 6
  },
  {
    "id": 5291,
    "traditional": "圓珠筆",
    "simplified": "圆珠笔",
    "reading": "yuánzhūbǐ",
    "meaning": "ballpoint pen, CL:支[zhi1],枝[zhi1]",
    "grade": 6
  },
  {
    "id": 5292,
    "traditional": "緣故",
    "simplified": "缘故",
    "reading": "yuángù",
    "meaning": "score, grounds, reason, cause, sake",
    "grade": 6
  },
  {
    "id": 5293,
    "traditional": "援助",
    "simplified": "援助",
    "reading": "yuánzhù",
    "meaning": "support, aid",
    "grade": 6
  },
  {
    "id": 5294,
    "traditional": "遠遠",
    "simplified": "远远",
    "reading": "yuǎnyuǎn",
    "meaning": "distant",
    "grade": 6
  },
  {
    "id": 5295,
    "traditional": "遠離",
    "simplified": "远离",
    "reading": "yuǎnlí",
    "meaning": "be far away, stand aloof, be far off (the mark/etc.)",
    "grade": 6
  },
  {
    "id": 5296,
    "traditional": "遠方",
    "simplified": "远方",
    "reading": "yuǎnfāng",
    "meaning": "distance, beyond, beyondness, distant place, corner",
    "grade": 6
  },
  {
    "id": 5297,
    "traditional": "暈",
    "simplified": "晕",
    "reading": "yūn",
    "meaning": "confused, dizzy, giddy, to faint, to swoon, to lose consciousness, to pass out, dizzy, halo, ring around moon or sun",
    "grade": 6
  },
  {
    "id": 5298,
    "traditional": "約定",
    "simplified": "约定",
    "reading": "yuēdìng",
    "meaning": "agree upon",
    "grade": 6
  },
  {
    "id": 5299,
    "traditional": "運作",
    "simplified": "运作",
    "reading": "yùnzuò",
    "meaning": "operate, work",
    "grade": 6
  },
  {
    "id": 5300,
    "traditional": "樂曲",
    "simplified": "乐曲",
    "reading": "yuèqǔ",
    "meaning": "composition, piece of music, music, musical composition, piece, number, opus",
    "grade": 6
  },
  {
    "id": 5301,
    "traditional": "允許",
    "simplified": "允许",
    "reading": "yǔnxǔ",
    "meaning": "permit, allow",
    "grade": 6
  },
  {
    "id": 5302,
    "traditional": "暈車",
    "simplified": "晕车",
    "reading": "yùn chē",
    "meaning": "to be carsick",
    "grade": 6
  },
  {
    "id": 5303,
    "traditional": "雜",
    "simplified": "杂",
    "reading": "zá",
    "meaning": "mixed, miscellaneous, various, to mix",
    "grade": 6
  },
  {
    "id": 5304,
    "traditional": "再生",
    "simplified": "再生",
    "reading": "zàishēng",
    "meaning": "be reborn, regenerate",
    "grade": 6
  },
  {
    "id": 5305,
    "traditional": "再說",
    "simplified": "再说",
    "reading": "zàishuō",
    "meaning": "furthermore, besides",
    "grade": 6
  },
  {
    "id": 5306,
    "traditional": "遭到",
    "simplified": "遭到",
    "reading": "zāodào",
    "meaning": "endure, encounter, meet with, run into, suffer, soak_up",
    "grade": 6
  },
  {
    "id": 5307,
    "traditional": "遭受",
    "simplified": "遭受",
    "reading": "zāoshòu",
    "meaning": "suffer",
    "grade": 6
  },
  {
    "id": 5308,
    "traditional": "遭遇",
    "simplified": "遭遇",
    "reading": "zāoyù",
    "meaning": "meet with, encounter",
    "grade": 6
  },
  {
    "id": 5309,
    "traditional": "早晚",
    "simplified": "早晚",
    "reading": "zǎowǎn",
    "meaning": "morning and evening, sooner or later",
    "grade": 6
  },
  {
    "id": 5310,
    "traditional": "增進",
    "simplified": "增进",
    "reading": "zēngjìn",
    "meaning": "enhance, promote, further",
    "grade": 6
  },
  {
    "id": 5311,
    "traditional": "扎",
    "simplified": "扎",
    "reading": "zhā",
    "meaning": "variant of 紮|扎[zha1], penetrating (as of cold), struggle, to tie, to bind, classifier for flowers, banknotes etc: bundle, Taiwan pr. [zha2]",
    "grade": 6
  },
  {
    "id": 5312,
    "traditional": "增值",
    "simplified": "增值",
    "reading": "zēngzhí",
    "meaning": "to appreciate (financially), to increase in value, value-added (accountancy)",
    "grade": 6
  },
  {
    "id": 5313,
    "traditional": "炸彈",
    "simplified": "炸弹",
    "reading": "zhàdàn",
    "meaning": "bomb, CL:枚[mei2],顆|颗[ke1]",
    "grade": 6
  },
  {
    "id": 5314,
    "traditional": "扎實",
    "simplified": "扎实",
    "reading": "zhāshi",
    "meaning": "strong, solid, sturdy, firm, practical, see 扎實|扎实[zha1 shi5]",
    "grade": 6
  },
  {
    "id": 5315,
    "traditional": "炸",
    "simplified": "炸",
    "reading": "zhà",
    "meaning": "to deep fry, to explode",
    "grade": 6
  },
  {
    "id": 5316,
    "traditional": "債",
    "simplified": "债",
    "reading": "zhài",
    "meaning": "debt, CL:筆|笔[bi3]",
    "grade": 6
  },
  {
    "id": 5317,
    "traditional": "炸藥",
    "simplified": "炸药",
    "reading": "zhàyào",
    "meaning": "explosive (material)",
    "grade": 6
  },
  {
    "id": 5318,
    "traditional": "占據",
    "simplified": "占据",
    "reading": "zhànjù",
    "meaning": "inhabit, occupy, stand, assume, prevail, hold, invade, occupation, take, take up",
    "grade": 6
  },
  {
    "id": 5319,
    "traditional": "戰場",
    "simplified": "战场",
    "reading": "zhànchǎng",
    "meaning": "field of battle, plain, theatre, camp, cockpit, battlefield, theater, battleground, field, battlefront",
    "grade": 6
  },
  {
    "id": 5320,
    "traditional": "戰術",
    "simplified": "战术",
    "reading": "zhànshù",
    "meaning": "tactical, war, tactics, military tactics, tactic, swordcraft",
    "grade": 6
  },
  {
    "id": 5321,
    "traditional": "戰略",
    "simplified": "战略",
    "reading": "zhànlüè",
    "meaning": "stratagem, tactical, strategics, tactics, strategy",
    "grade": 6
  },
  {
    "id": 5322,
    "traditional": "戰友",
    "simplified": "战友",
    "reading": "zhànyǒu",
    "meaning": "comrade-in-arms, battle companion",
    "grade": 6
  },
  {
    "id": 5323,
    "traditional": "章",
    "simplified": "章",
    "reading": "zhāng",
    "meaning": "m.[general]",
    "grade": 6
  },
  {
    "id": 5324,
    "traditional": "站臺",
    "simplified": "站台",
    "reading": "zhàntái",
    "meaning": "platform (at a railway station)",
    "grade": 6
  },
  {
    "id": 5325,
    "traditional": "掌聲",
    "simplified": "掌声",
    "reading": "zhǎngshēng",
    "meaning": "clapping, applause",
    "grade": 6
  },
  {
    "id": 5326,
    "traditional": "長",
    "simplified": "长",
    "reading": "zhǎng",
    "meaning": "long, be strong/good (in/at)",
    "grade": 6
  },
  {
    "id": 5327,
    "traditional": "賬",
    "simplified": "账",
    "reading": "zhàng",
    "meaning": "account, bill, debt, CL:本[ben3],筆|笔[bi3]",
    "grade": 6
  },
  {
    "id": 5328,
    "traditional": "賬戶",
    "simplified": "账户",
    "reading": "zhànghù",
    "meaning": "bank account, online account",
    "grade": 6
  },
  {
    "id": 5329,
    "traditional": "漲",
    "simplified": "涨",
    "reading": "zhǎng",
    "meaning": "rise, go up (of water/prices/etc.)",
    "grade": 6
  },
  {
    "id": 5330,
    "traditional": "招",
    "simplified": "招",
    "reading": "zhāo",
    "meaning": "m.[general]",
    "grade": 6
  },
  {
    "id": 5331,
    "traditional": "障礙",
    "simplified": "障碍",
    "reading": "zhàngài",
    "meaning": "discouragement, bottleneck, clog, bar, encumbrance, embarrassment, malfunction, jam-up, baffle, impediment, obstruction, rub, roadblock, stay, rubber, disturbance, balk, holdback, bunker, stymy, remora, obstacle, barrier, stumbling_block, hitch, handicap, lesion, hedge, snag, drawback, let, wall, crab, difficulty, penalty, hang-up, facer, hindrance, hurdle",
    "grade": 6
  },
  {
    "id": 5332,
    "traditional": "照樣",
    "simplified": "照样",
    "reading": "zhàoyàng",
    "meaning": "as before, (same) as usual, in the same manner, still, nevertheless",
    "grade": 6
  },
  {
    "id": 5333,
    "traditional": "照耀",
    "simplified": "照耀",
    "reading": "zhàoyào",
    "meaning": "to shine, to illuminate",
    "grade": 6
  },
  {
    "id": 5334,
    "traditional": "招聘",
    "simplified": "招聘",
    "reading": "zhāopìn",
    "meaning": "to invite applications for a job, to recruit",
    "grade": 6
  },
  {
    "id": 5335,
    "traditional": "哲學",
    "simplified": "哲学",
    "reading": "zhéxué",
    "meaning": "ism, philosophic, philosophical, philosophy, school of thought, doctrine",
    "grade": 6
  },
  {
    "id": 5336,
    "traditional": "這就是說",
    "simplified": "这就是说",
    "reading": "zhèjiùshìshuō",
    "meaning": "This means that",
    "grade": 6
  },
  {
    "id": 5337,
    "traditional": "鎮",
    "simplified": "镇",
    "reading": "zhèn",
    "meaning": "town, garrison post, trading center",
    "grade": 6
  },
  {
    "id": 5338,
    "traditional": "爭奪",
    "simplified": "争夺",
    "reading": "zhēngduó",
    "meaning": "to fight over, to contest, to vie over",
    "grade": 6
  },
  {
    "id": 5339,
    "traditional": "整頓",
    "simplified": "整顿",
    "reading": "zhěngdùn",
    "meaning": "rectify, reorganize",
    "grade": 6
  },
  {
    "id": 5340,
    "traditional": "正當",
    "simplified": "正当",
    "reading": "zhèngdāng",
    "meaning": "proper, legitimate",
    "grade": 6
  },
  {
    "id": 5341,
    "traditional": "整治",
    "simplified": "整治",
    "reading": "zhěngzhì",
    "meaning": "renovate, repair, dredge (river/etc.), punish, discipline, do, work at, prepare, make ready, arrange, regulate, deal with problem or adversary",
    "grade": 6
  },
  {
    "id": 5342,
    "traditional": "政策",
    "simplified": "政策",
    "reading": "zhèngcè",
    "meaning": "statism, tack, line, policy, statecraft",
    "grade": 6
  },
  {
    "id": 5343,
    "traditional": "政權",
    "simplified": "政权",
    "reading": "zhèngquán",
    "meaning": "rein, political power, political/state power, state power, power, regime, political",
    "grade": 6
  },
  {
    "id": 5344,
    "traditional": "癥狀",
    "simplified": "症状",
    "reading": "zhèngzhuàng",
    "meaning": "symptom",
    "grade": 6
  },
  {
    "id": 5345,
    "traditional": "政黨",
    "simplified": "政党",
    "reading": "zhèngdǎng",
    "meaning": "political party",
    "grade": 6
  },
  {
    "id": 5346,
    "traditional": "之類",
    "simplified": "之类",
    "reading": "zhīlèi",
    "meaning": "conj.: such like",
    "grade": 6
  },
  {
    "id": 5347,
    "traditional": "支援",
    "simplified": "支援",
    "reading": "zhīyuán",
    "meaning": "backstop, support, side, help, patronize, root, stand by, bear, angel, hold out, back, see through, assist, assistance, sustain, back up",
    "grade": 6
  },
  {
    "id": 5348,
    "traditional": "支撐",
    "simplified": "支撑",
    "reading": "zhīcheng",
    "meaning": "prop up, sustain, support",
    "grade": 6
  },
  {
    "id": 5349,
    "traditional": "知名",
    "simplified": "知名",
    "reading": "zhīmíng",
    "meaning": "noted, famous",
    "grade": 6
  },
  {
    "id": 5350,
    "traditional": "織",
    "simplified": "织",
    "reading": "zhī",
    "meaning": "to weave, to knit",
    "grade": 6
  },
  {
    "id": 5351,
    "traditional": "枝",
    "simplified": "枝",
    "reading": "zhī",
    "meaning": "m.[general]",
    "grade": 6
  },
  {
    "id": 5352,
    "traditional": "直升機",
    "simplified": "直升机",
    "reading": "zhíshēngjī",
    "meaning": "helicopter, CL:架[jia4]",
    "grade": 6
  },
  {
    "id": 5353,
    "traditional": "職責",
    "simplified": "职责",
    "reading": "zhízé",
    "meaning": "duty, responsibility, obligation",
    "grade": 6
  },
  {
    "id": 5354,
    "traditional": "止",
    "simplified": "止",
    "reading": "zhǐ",
    "meaning": "demeanor, bearing",
    "grade": 6
  },
  {
    "id": 5355,
    "traditional": "只得",
    "simplified": "只得",
    "reading": "zhǐdé",
    "meaning": "have to",
    "grade": 6
  },
  {
    "id": 5356,
    "traditional": "只顧",
    "simplified": "只顾",
    "reading": "zhǐgù",
    "meaning": "solely preoccupied (with one thing), engrossed, focusing (on sth), to look after only one aspect",
    "grade": 6
  },
  {
    "id": 5357,
    "traditional": "只管",
    "simplified": "只管",
    "reading": "zhǐguǎn",
    "meaning": "solely engrossed in one thing, just (one thing, no need to worry about the rest), simply, by all means, please feel free, do not hesitate (to ask for sth)",
    "grade": 6
  },
  {
    "id": 5358,
    "traditional": "指數",
    "simplified": "指数",
    "reading": "zhǐshù",
    "meaning": "exponential, index, index number, index (number), indicant, exponential function, exponent, power, index_number, indicator",
    "grade": 6
  },
  {
    "id": 5359,
    "traditional": "指定",
    "simplified": "指定",
    "reading": "zhǐdìng",
    "meaning": "earmark, appointed, specify, set, domicile, appointment, ticket, fix, name, delegate, specified, express, named, consign, designate, assignment, state, depute, appoint, nominate, dictate, assign, destine, determine",
    "grade": 6
  },
  {
    "id": 5360,
    "traditional": "指頭",
    "simplified": "指头",
    "reading": "zhǐtou",
    "meaning": "finger, toe, CL:個|个[ge4]",
    "grade": 6
  },
  {
    "id": 5361,
    "traditional": "指著",
    "simplified": "指着",
    "reading": "zhǐzhe",
    "meaning": "Poke",
    "grade": 6
  },
  {
    "id": 5362,
    "traditional": "至於",
    "simplified": "至于",
    "reading": "zhìyú",
    "meaning": "as for/to",
    "grade": 6
  },
  {
    "id": 5363,
    "traditional": "智慧",
    "simplified": "智慧",
    "reading": "zhìhuì",
    "meaning": "lore, sapiency, sconce, wit, intelligence, gumption, savvy, wiseness, soundness, wisdom, grey_matter, sapience",
    "grade": 6
  },
  {
    "id": 5364,
    "traditional": "治病",
    "simplified": "治病",
    "reading": "zhìbìng",
    "meaning": "to treat an illness",
    "grade": 6
  },
  {
    "id": 5365,
    "traditional": "中華",
    "simplified": "中华",
    "reading": "Zhōnghuá",
    "meaning": "the Chinese nation, China",
    "grade": 6
  },
  {
    "id": 5366,
    "traditional": "中等",
    "simplified": "中等",
    "reading": "zhōngděng",
    "meaning": "medium",
    "grade": 6
  },
  {
    "id": 5367,
    "traditional": "中外",
    "simplified": "中外",
    "reading": "zhōngwài",
    "meaning": "China and foreign countries",
    "grade": 6
  },
  {
    "id": 5368,
    "traditional": "中期",
    "simplified": "中期",
    "reading": "zhōngqī",
    "meaning": "middle (of a period of time), medium-term (plan, forecast etc)",
    "grade": 6
  },
  {
    "id": 5369,
    "traditional": "忠心",
    "simplified": "忠心",
    "reading": "zhōngxīn",
    "meaning": "good faith, devotion, loyalty, dedication",
    "grade": 6
  },
  {
    "id": 5370,
    "traditional": "腫",
    "simplified": "肿",
    "reading": "zhǒng",
    "meaning": "to swell, swelling, swollen",
    "grade": 6
  },
  {
    "id": 5371,
    "traditional": "鐘頭",
    "simplified": "钟头",
    "reading": "zhōngtóu",
    "meaning": "hour",
    "grade": 6
  },
  {
    "id": 5372,
    "traditional": "種種",
    "simplified": "种种",
    "reading": "zhǒngzhǒng",
    "meaning": "all kinds of",
    "grade": 6
  },
  {
    "id": 5373,
    "traditional": "珠寶",
    "simplified": "珠宝",
    "reading": "zhūbǎo",
    "meaning": "pearls, jewels, precious stones",
    "grade": 6
  },
  {
    "id": 5374,
    "traditional": "諸位",
    "simplified": "诸位",
    "reading": "zhūwèi",
    "meaning": "(pron) everyone, Ladies and Gentlemen, Sirs",
    "grade": 6
  },
  {
    "id": 5375,
    "traditional": "主持人",
    "simplified": "主持人",
    "reading": "zhǔchírén",
    "meaning": "anchor, emcee, host, symposiarch, anchorman, director, impresario, chair, chairperson, linkman, presenter, compere, master of ceremonies, moderator",
    "grade": 6
  },
  {
    "id": 5376,
    "traditional": "粥",
    "simplified": "粥",
    "reading": "zhōu",
    "meaning": "see 葷粥|荤粥[Xun1 yu4], congee, gruel, porridge, CL:碗[wan3]",
    "grade": 6
  },
  {
    "id": 5377,
    "traditional": "主角",
    "simplified": "主角",
    "reading": "zhǔjué",
    "meaning": "leading role, title_role, star, protagonist, lead, principal, title role, name part",
    "grade": 6
  },
  {
    "id": 5378,
    "traditional": "煮",
    "simplified": "煮",
    "reading": "zhǔ",
    "meaning": "boil, scald, seethe, poach, cook",
    "grade": 6
  },
  {
    "id": 5379,
    "traditional": "主流",
    "simplified": "主流",
    "reading": "zhǔliú",
    "meaning": "main stream/current, essential/main aspect/trend",
    "grade": 6
  },
  {
    "id": 5380,
    "traditional": "住宅",
    "simplified": "住宅",
    "reading": "zhùzhái",
    "meaning": "shebang, dwelling_house, dwelling, roof, abode, residential, home, toft, howff, housing, habitation, homesite, tenement, place, residence, house, property",
    "grade": 6
  },
  {
    "id": 5381,
    "traditional": "駐",
    "simplified": "驻",
    "reading": "zhù",
    "meaning": "halt, stay, be stationed at",
    "grade": 6
  },
  {
    "id": 5382,
    "traditional": "柱子",
    "simplified": "柱子",
    "reading": "zhùzi",
    "meaning": "pillar, CL:根[gen1]",
    "grade": 6
  },
  {
    "id": 5383,
    "traditional": "專用",
    "simplified": "专用",
    "reading": "zhuānyòng",
    "meaning": "use for special purpose",
    "grade": 6
  },
  {
    "id": 5384,
    "traditional": "祝願",
    "simplified": "祝愿",
    "reading": "zhùyuàn",
    "meaning": "to wish",
    "grade": 6
  },
  {
    "id": 5385,
    "traditional": "轉動",
    "simplified": "转动",
    "reading": "zhuǎn dòng",
    "meaning": "turn (round), move",
    "grade": 6
  },
  {
    "id": 5386,
    "traditional": "轉",
    "simplified": "转",
    "reading": "zhuǎn",
    "meaning": "turn, revolve, rotate",
    "grade": 6
  },
  {
    "id": 5387,
    "traditional": "賺錢",
    "simplified": "赚钱",
    "reading": "zhuàn qián",
    "meaning": "profit, gain, make profit, cash in on, earn money, make money",
    "grade": 6
  },
  {
    "id": 5388,
    "traditional": "壯觀",
    "simplified": "壮观",
    "reading": "zhuàngguān",
    "meaning": "sublime, spectacular, magnificent",
    "grade": 6
  },
  {
    "id": 5389,
    "traditional": "賺",
    "simplified": "赚",
    "reading": "zhuàn",
    "meaning": "knock_up, garner, take in, clear, make profit, earn, gain, draw, realize, make a profit, do, bring_in, get, pull_in, make, bring in, pick_up, pull in",
    "grade": 6
  },
  {
    "id": 5390,
    "traditional": "追究",
    "simplified": "追究",
    "reading": "zhuījiū",
    "meaning": "to investigate, to look into",
    "grade": 6
  },
  {
    "id": 5391,
    "traditional": "裝備",
    "simplified": "装备",
    "reading": "zhuāngbèi",
    "meaning": "equipment, outfit",
    "grade": 6
  },
  {
    "id": 5392,
    "traditional": "自來水",
    "simplified": "自来水",
    "reading": "zìláishuǐ",
    "meaning": "running/tap water, running, tap_water, running water, tap water",
    "grade": 6
  },
  {
    "id": 5393,
    "traditional": "咨詢",
    "simplified": "咨询",
    "reading": "zīxún",
    "meaning": "to consult, to seek advice, consultation, (sales) inquiry (formal)",
    "grade": 6
  },
  {
    "id": 5394,
    "traditional": "捉",
    "simplified": "捉",
    "reading": "zhuō",
    "meaning": "clutch, cop, grasp, nip, hold, seize, capture, take hold of, hent, catch",
    "grade": 6
  },
  {
    "id": 5395,
    "traditional": "自我",
    "simplified": "自我",
    "reading": "zìwǒ",
    "meaning": "self, oneself",
    "grade": 6
  },
  {
    "id": 5396,
    "traditional": "自學",
    "simplified": "自学",
    "reading": "zìxué",
    "meaning": "self-study, to study on one's own",
    "grade": 6
  },
  {
    "id": 5397,
    "traditional": "自言自語",
    "simplified": "自言自语",
    "reading": "zìyán-zìyǔ",
    "meaning": "to talk to oneself, to think aloud, to soliloquize",
    "grade": 6
  },
  {
    "id": 5398,
    "traditional": "自在",
    "simplified": "自在",
    "reading": "zìzai",
    "meaning": "comfortable, unrestrained, at_ease, free",
    "grade": 6
  },
  {
    "id": 5399,
    "traditional": "宗教",
    "simplified": "宗教",
    "reading": "zōngjiào",
    "meaning": "theology, community, religious, cult, creed, faith, religion",
    "grade": 6
  },
  {
    "id": 5400,
    "traditional": "總部",
    "simplified": "总部",
    "reading": "zǒngbù",
    "meaning": "base of operations, head office, armory, base, headquarters",
    "grade": 6
  },
  {
    "id": 5401,
    "traditional": "總經理",
    "simplified": "总经理",
    "reading": "zǒngjīnglǐ",
    "meaning": "CEO, general_manager, chief executive officer, chief operating officer, executive, managing director, general manager, president",
    "grade": 6
  },
  {
    "id": 5402,
    "traditional": "總監",
    "simplified": "总监",
    "reading": "zǒngjiān",
    "meaning": "head, director (of an organizational unit), (police) commissioner, inspector-general, rank of local governor in Tang dynasty administration",
    "grade": 6
  },
  {
    "id": 5403,
    "traditional": "總量",
    "simplified": "总量",
    "reading": "zǒngliàng",
    "meaning": "total, overall amount",
    "grade": 6
  },
  {
    "id": 5404,
    "traditional": "走私",
    "simplified": "走私",
    "reading": "zǒu sī",
    "meaning": "smuggle",
    "grade": 6
  },
  {
    "id": 5405,
    "traditional": "奏",
    "simplified": "奏",
    "reading": "zòu",
    "meaning": "to play music, to achieve, to present a memorial to the emperor (old)",
    "grade": 6
  },
  {
    "id": 5406,
    "traditional": "租金",
    "simplified": "租金",
    "reading": "zūjīn",
    "meaning": "rent",
    "grade": 6
  },
  {
    "id": 5407,
    "traditional": "足",
    "simplified": "足",
    "reading": "zú",
    "meaning": "excessive, foot, to be sufficient, ample",
    "grade": 6
  },
  {
    "id": 5408,
    "traditional": "足以",
    "simplified": "足以",
    "reading": "zúyǐ",
    "meaning": "sufficient/enough to",
    "grade": 6
  },
  {
    "id": 5409,
    "traditional": "祖父",
    "simplified": "祖父",
    "reading": "zǔfù",
    "meaning": "grandad, gramps, grandfather, grandsire, (paternal) grandfather, granddad, grandpa, granddaddy",
    "grade": 6
  },
  {
    "id": 5410,
    "traditional": "族",
    "simplified": "族",
    "reading": "zú",
    "meaning": "race, nationality, ethnicity, clan, by extension, social group (e.g. office workers 上班族)",
    "grade": 6
  },
  {
    "id": 5411,
    "traditional": "祖國",
    "simplified": "祖国",
    "reading": "zǔguó",
    "meaning": "country, China (used only by Chinese), mother_country, China, old_country, fatherland, home, country of origin, mother country, old country, homeland, native land, motherland, metropolis",
    "grade": 6
  },
  {
    "id": 5412,
    "traditional": "祖母",
    "simplified": "祖母",
    "reading": "zǔmǔ",
    "meaning": "father's mother, paternal grandmother",
    "grade": 6
  },
  {
    "id": 5413,
    "traditional": "最終",
    "simplified": "最终",
    "reading": "zuìzhōng",
    "meaning": "final, ultimate",
    "grade": 6
  },
  {
    "id": 5414,
    "traditional": "罪",
    "simplified": "罪",
    "reading": "zuì",
    "meaning": "variant of 罪[zui4], crime",
    "grade": 6
  },
  {
    "id": 5415,
    "traditional": "鑽",
    "simplified": "钻",
    "reading": "zuān",
    "meaning": "an auger, diamond, to drill, to bore, to get into, to make one's way into, to enter (a hole), to thread one's way through, to study intensively, to dig into, to curry favor for personal gain",
    "grade": 6
  },
  {
    "id": 5416,
    "traditional": "罪惡",
    "simplified": "罪恶",
    "reading": "zuìè",
    "meaning": "crime, evil, sin",
    "grade": 6
  },
  {
    "id": 5417,
    "traditional": "作",
    "simplified": "作",
    "reading": "zuò",
    "meaning": "do, make, rise, write, compose, pretend, affect, feign",
    "grade": 6
  },
  {
    "id": 5418,
    "traditional": "最佳",
    "simplified": "最佳",
    "reading": "zuìjiā",
    "meaning": "first-rate, best, superlative, optimum",
    "grade": 6
  },
  {
    "id": 5419,
    "traditional": "作廢",
    "simplified": "作废",
    "reading": "zuòfèi",
    "meaning": "to become invalid, to cancel, to delete, to nullify",
    "grade": 6
  },
  {
    "id": 5420,
    "traditional": "座談會",
    "simplified": "座谈会",
    "reading": "zuòtánhuì",
    "meaning": "colloquium, symposiac, panel_discussion, an informal discussion meeting, symposium, teach-in, forum, symposia, conversazione, panel discussion",
    "grade": 6
  },
  {
    "id": 5421,
    "traditional": "作戰",
    "simplified": "作战",
    "reading": "zuò zhàn",
    "meaning": "do battle, war, fight, battle, conduct operations",
    "grade": 6
  },
  {
    "id": 5422,
    "traditional": "阿拉伯語",
    "simplified": "阿拉伯语",
    "reading": "Ālābóyǔ",
    "meaning": "Arabic (language)",
    "grade": 7
  },
  {
    "id": 5423,
    "traditional": "哎",
    "simplified": "哎",
    "reading": "āi",
    "meaning": "dissatisfaction, of surprise, swounds, warning",
    "grade": 7
  },
  {
    "id": 5424,
    "traditional": "哀求",
    "simplified": "哀求",
    "reading": "āiqiú",
    "meaning": "to entreat, to implore, to plead",
    "grade": 7
  },
  {
    "id": 5425,
    "traditional": "哎呀",
    "simplified": "哎呀",
    "reading": "āiyā",
    "meaning": "jeez, of wonder, alas, wirra, swounds, lummy, shock, admiration",
    "grade": 7
  },
  {
    "id": 5426,
    "traditional": "挨家挨戶",
    "simplified": "挨家挨户",
    "reading": "āijiā-āihù",
    "meaning": "to go from house to house, house-to-house (search)",
    "grade": 7
  },
  {
    "id": 5427,
    "traditional": "艾滋病",
    "simplified": "艾滋病",
    "reading": "àizībìng",
    "meaning": "AIDS (loanword)",
    "grade": 7
  },
  {
    "id": 5428,
    "traditional": "癌",
    "simplified": "癌",
    "reading": "ái",
    "meaning": "tumor, sarcoma, leukemia, cancerous, melanoma, malignant tumor, cancer, malignancy, neoplasm, carcinoma",
    "grade": 7
  },
  {
    "id": 5429,
    "traditional": "癌癥",
    "simplified": "癌症",
    "reading": "áizhèng",
    "meaning": "carcinomatosis, cancer",
    "grade": 7
  },
  {
    "id": 5430,
    "traditional": "愛不釋手",
    "simplified": "爱不释手",
    "reading": "àibùshìshǒu",
    "meaning": "to love sth too much to part with it (idiom), to fondle admiringly",
    "grade": 7
  },
  {
    "id": 5431,
    "traditional": "唉",
    "simplified": "唉",
    "reading": "āi",
    "meaning": "mild exclamation/sigh, Gosh!, My!",
    "grade": 7
  },
  {
    "id": 5432,
    "traditional": "愛理不理",
    "simplified": "爱理不理",
    "reading": "àilǐbùlǐ",
    "meaning": "to look cold and indifferent, standoffish",
    "grade": 7
  },
  {
    "id": 5433,
    "traditional": "愛面子",
    "simplified": "爱面子",
    "reading": "ài miànzi",
    "meaning": "to save face, to worry about losing face, proud of one's reputation, sensitive about losing prestige, sense of propriety",
    "grade": 7
  },
  {
    "id": 5434,
    "traditional": "愛惜",
    "simplified": "爱惜",
    "reading": "àixī",
    "meaning": "cherish, treasure",
    "grade": 7
  },
  {
    "id": 5435,
    "traditional": "礙事",
    "simplified": "碍事",
    "reading": "ài shì",
    "meaning": "(usu used in the negative) to be of importance or to matter, to be in the way, to be a hindrance",
    "grade": 7
  },
  {
    "id": 5436,
    "traditional": "安定",
    "simplified": "安定",
    "reading": "āndìng",
    "meaning": "stabilize",
    "grade": 7
  },
  {
    "id": 5437,
    "traditional": "安眠藥",
    "simplified": "安眠药",
    "reading": "ānmiányào",
    "meaning": "sleeping pill, CL:粒[li4]",
    "grade": 7
  },
  {
    "id": 5438,
    "traditional": "安撫",
    "simplified": "安抚",
    "reading": "ānfǔ",
    "meaning": "to placate, to pacify, to appease",
    "grade": 7
  },
  {
    "id": 5439,
    "traditional": "安穩",
    "simplified": "安稳",
    "reading": "ānwěn",
    "meaning": "smooth and steady",
    "grade": 7
  },
  {
    "id": 5440,
    "traditional": "安寧",
    "simplified": "安宁",
    "reading": "ānníng",
    "meaning": "peaceful, tranquil, calm, composed, free from worry",
    "grade": 7
  },
  {
    "id": 5441,
    "traditional": "按鍵",
    "simplified": "按键",
    "reading": "ànjiàn",
    "meaning": "button or key (on a device), keystroke, CL:個|个[ge4], to press a button",
    "grade": 7
  },
  {
    "id": 5442,
    "traditional": "按理說",
    "simplified": "按理说",
    "reading": "ànlǐshuō",
    "meaning": "it is reasonable to say that...",
    "grade": 7
  },
  {
    "id": 5443,
    "traditional": "安逸",
    "simplified": "安逸",
    "reading": "ānyì",
    "meaning": "easy and comfortable, easy",
    "grade": 7
  },
  {
    "id": 5444,
    "traditional": "安心",
    "simplified": "安心",
    "reading": "ānxīn",
    "meaning": "reassured, disburden, set one's mind at rest, feel at ease, reassuring, keep one's mind on sth., be relieved",
    "grade": 7
  },
  {
    "id": 5445,
    "traditional": "案件",
    "simplified": "案件",
    "reading": "ànjiàn",
    "meaning": "a police case, law case, case (of law/etc.), a legal case, case",
    "grade": 7
  },
  {
    "id": 5446,
    "traditional": "暗地裡",
    "simplified": "暗地里",
    "reading": "àndìli",
    "meaning": "secretly, inwardly, on the sly",
    "grade": 7
  },
  {
    "id": 5447,
    "traditional": "暗中",
    "simplified": "暗中",
    "reading": "ànzhōng",
    "meaning": "in the dark, in secret, on the sly, surreptitiously",
    "grade": 7
  },
  {
    "id": 5448,
    "traditional": "按說",
    "simplified": "按说",
    "reading": "ànshuō",
    "meaning": "in the ordinary course of events, ordinarily, normally",
    "grade": 7
  },
  {
    "id": 5449,
    "traditional": "凹",
    "simplified": "凹",
    "reading": "āo",
    "meaning": "depressed, sunken, indented, concave, female (connector etc), variant of 窪|洼[wa1], (used in names)",
    "grade": 7
  },
  {
    "id": 5450,
    "traditional": "昂貴",
    "simplified": "昂贵",
    "reading": "ángguì",
    "meaning": "cost, costly, expensive",
    "grade": 7
  },
  {
    "id": 5451,
    "traditional": "熬",
    "simplified": "熬",
    "reading": "áo",
    "meaning": "to boil, to simmer, to cook on a slow fire, to extract by heating, to decoct, to endure",
    "grade": 7
  },
  {
    "id": 5452,
    "traditional": "傲",
    "simplified": "傲",
    "reading": "ào",
    "meaning": "proud, arrogant, to despise, unyielding, to defy",
    "grade": 7
  },
  {
    "id": 5453,
    "traditional": "暗殺",
    "simplified": "暗杀",
    "reading": "ànshā",
    "meaning": "to assassinate",
    "grade": 7
  },
  {
    "id": 5454,
    "traditional": "熬夜",
    "simplified": "熬夜",
    "reading": "áo yè",
    "meaning": "to stay up late or all night",
    "grade": 7
  },
  {
    "id": 5455,
    "traditional": "奧祕",
    "simplified": "奥秘",
    "reading": "àomì",
    "meaning": "secret, mystery",
    "grade": 7
  },
  {
    "id": 5456,
    "traditional": "傲慢",
    "simplified": "傲慢",
    "reading": "àomàn",
    "meaning": "arrogant, haughty",
    "grade": 7
  },
  {
    "id": 5457,
    "traditional": "八卦",
    "simplified": "八卦",
    "reading": "bāguà",
    "meaning": "the eight divinatory trigrams of the Book of Changes 易經|易经[Yi4 jing1], gossip, gossipy",
    "grade": 7
  },
  {
    "id": 5458,
    "traditional": "巴不得",
    "simplified": "巴不得",
    "reading": "bābude",
    "meaning": "(coll.) to be eager for, to long for, to look forward to",
    "grade": 7
  },
  {
    "id": 5459,
    "traditional": "奧運會",
    "simplified": "奥运会",
    "reading": "Àoyùnhuì",
    "meaning": "abbr. for 奧林匹克運動會|奥林匹克运动会, Olympic Games, the Olympics",
    "grade": 7
  },
  {
    "id": 5460,
    "traditional": "扒",
    "simplified": "扒",
    "reading": "bā",
    "meaning": "to peel, to skin, to tear, to pull down, to cling to (sth on which one is climbing), to dig, to rake up, to steal, to braise, to crawl",
    "grade": 7
  },
  {
    "id": 5461,
    "traditional": "芭蕾",
    "simplified": "芭蕾",
    "reading": "bālěi",
    "meaning": "ballet (loanword)",
    "grade": 7
  },
  {
    "id": 5462,
    "traditional": "把柄",
    "simplified": "把柄",
    "reading": "bǎbǐng",
    "meaning": "handle, (fig.) information that can be used against sb",
    "grade": 7
  },
  {
    "id": 5463,
    "traditional": "把關",
    "simplified": "把关",
    "reading": "bǎ guān",
    "meaning": "to guard a pass, to check on sth",
    "grade": 7
  },
  {
    "id": 5464,
    "traditional": "把手",
    "simplified": "把手",
    "reading": "bǎshou",
    "meaning": "handle, grip, knob",
    "grade": 7
  },
  {
    "id": 5465,
    "traditional": "罷免",
    "simplified": "罢免",
    "reading": "bàmiǎn",
    "meaning": "to remove sb from their post, to dismiss",
    "grade": 7
  },
  {
    "id": 5466,
    "traditional": "靶子",
    "simplified": "靶子",
    "reading": "bǎzi",
    "meaning": "target",
    "grade": 7
  },
  {
    "id": 5467,
    "traditional": "壩",
    "simplified": "坝",
    "reading": "bà",
    "meaning": "dam, dike, embankment, CL:條|条[tiao2]",
    "grade": 7
  },
  {
    "id": 5468,
    "traditional": "罷休",
    "simplified": "罢休",
    "reading": "bàxiū",
    "meaning": "to give up, to abandon (a goal etc), to let sth go, forget it, let the matter drop",
    "grade": 7
  },
  {
    "id": 5469,
    "traditional": "掰",
    "simplified": "掰",
    "reading": "bāi",
    "meaning": "to break off or break open sth with one's hands, (fig.) to break off (a relationship)",
    "grade": 7
  },
  {
    "id": 5470,
    "traditional": "白白",
    "simplified": "白白",
    "reading": "báibái",
    "meaning": "in vain, to no purpose, for nothing, white",
    "grade": 7
  },
  {
    "id": 5471,
    "traditional": "霸占",
    "simplified": "霸占",
    "reading": "bàzhàn",
    "meaning": "to occupy by force, to seize, to dominate",
    "grade": 7
  },
  {
    "id": 5472,
    "traditional": "百分比",
    "simplified": "百分比",
    "reading": "bǎifēnbǐ",
    "meaning": "percent, per centum, pct, percentage, percent(age)",
    "grade": 7
  },
  {
    "id": 5473,
    "traditional": "百科全書",
    "simplified": "百科全书",
    "reading": "bǎikēquánshū",
    "meaning": "encyclopedia, CL:本[ben3],集[ji2]",
    "grade": 7
  },
  {
    "id": 5474,
    "traditional": "柏樹",
    "simplified": "柏树",
    "reading": "bǎishù",
    "meaning": "cypress tree, Taiwan pr. [bo2 shu4]",
    "grade": 7
  },
  {
    "id": 5475,
    "traditional": "百合",
    "simplified": "百合",
    "reading": "bǎihé",
    "meaning": "lily",
    "grade": 7
  },
  {
    "id": 5476,
    "traditional": "擺放",
    "simplified": "摆放",
    "reading": "bǎifàng",
    "meaning": "to set up, to arrange, to lay out",
    "grade": 7
  },
  {
    "id": 5477,
    "traditional": "擺設",
    "simplified": "摆设",
    "reading": "bǎishe",
    "meaning": "furnish and decorate (room)",
    "grade": 7
  },
  {
    "id": 5478,
    "traditional": "擺平",
    "simplified": "摆平",
    "reading": "bǎi píng",
    "meaning": "to be fair, to be impartial, to settle (a matter etc)",
    "grade": 7
  },
  {
    "id": 5479,
    "traditional": "拜會",
    "simplified": "拜会",
    "reading": "bàihuì",
    "meaning": "pay sb. a visit",
    "grade": 7
  },
  {
    "id": 5480,
    "traditional": "拜年",
    "simplified": "拜年",
    "reading": "bài nián",
    "meaning": "to pay a New Year call, to wish sb a Happy New Year",
    "grade": 7
  },
  {
    "id": 5481,
    "traditional": "拜託",
    "simplified": "拜托",
    "reading": "bàituō",
    "meaning": "to request sb to do sth, please!",
    "grade": 7
  },
  {
    "id": 5482,
    "traditional": "扳",
    "simplified": "扳",
    "reading": "bān",
    "meaning": "to pull, to turn (sth) around, to turn around (a situation), to recoup, variant of 攀[pan1]",
    "grade": 7
  },
  {
    "id": 5483,
    "traditional": "拜見",
    "simplified": "拜见",
    "reading": "bàijiàn",
    "meaning": "to pay a formal visit, to call to pay respects, to meet one's senior or superior",
    "grade": 7
  },
  {
    "id": 5484,
    "traditional": "頒發",
    "simplified": "颁发",
    "reading": "bānfā",
    "meaning": "issue, promulgate, award",
    "grade": 7
  },
  {
    "id": 5485,
    "traditional": "頒獎",
    "simplified": "颁奖",
    "reading": "bān jiǎng",
    "meaning": "present reward/honor",
    "grade": 7
  },
  {
    "id": 5486,
    "traditional": "頒布",
    "simplified": "颁布",
    "reading": "bānbù",
    "meaning": "to issue, to proclaim, to enact (laws, decrees etc)",
    "grade": 7
  },
  {
    "id": 5487,
    "traditional": "斑點",
    "simplified": "斑点",
    "reading": "bāndiǎn",
    "meaning": "spot, stain, speckle",
    "grade": 7
  },
  {
    "id": 5488,
    "traditional": "搬遷",
    "simplified": "搬迁",
    "reading": "bānqiān",
    "meaning": "to move, to relocate, removal",
    "grade": 7
  },
  {
    "id": 5489,
    "traditional": "辦不到",
    "simplified": "办不到",
    "reading": "bànbudào",
    "meaning": "impossible, can't be done, no can do, unable to accomplish",
    "grade": 7
  },
  {
    "id": 5490,
    "traditional": "半邊天",
    "simplified": "半边天",
    "reading": "bànbiāntiān",
    "meaning": "half the sky, women of the new society, womenfolk",
    "grade": 7
  },
  {
    "id": 5491,
    "traditional": "板塊",
    "simplified": "板块",
    "reading": "bǎnkuài",
    "meaning": "block, slab, tectonic plate, continental plate",
    "grade": 7
  },
  {
    "id": 5492,
    "traditional": "半場",
    "simplified": "半场",
    "reading": "bànchǎng",
    "meaning": "half of a game or contest, half-court",
    "grade": 7
  },
  {
    "id": 5493,
    "traditional": "半島",
    "simplified": "半岛",
    "reading": "bàndǎo",
    "meaning": "peninsula",
    "grade": 7
  },
  {
    "id": 5494,
    "traditional": "半路",
    "simplified": "半路",
    "reading": "bànlù",
    "meaning": "halfway, midway, on the way",
    "grade": 7
  },
  {
    "id": 5495,
    "traditional": "半數",
    "simplified": "半数",
    "reading": "bànshù",
    "meaning": "half, half (the number), det.: half",
    "grade": 7
  },
  {
    "id": 5496,
    "traditional": "半途而廢",
    "simplified": "半途而废",
    "reading": "bàntúérfèi",
    "meaning": "to give up halfway (idiom), leave sth unfinished",
    "grade": 7
  },
  {
    "id": 5497,
    "traditional": "半信半疑",
    "simplified": "半信半疑",
    "reading": "bànxìn-bànyí",
    "meaning": "half doubting, dubious, skeptical",
    "grade": 7
  },
  {
    "id": 5498,
    "traditional": "半真半假",
    "simplified": "半真半假",
    "reading": "bànzhēn-bànjiǎ",
    "meaning": "(idiom) half true and half false",
    "grade": 7
  },
  {
    "id": 5499,
    "traditional": "伴",
    "simplified": "伴",
    "reading": "bàn",
    "meaning": "accompany",
    "grade": 7
  },
  {
    "id": 5500,
    "traditional": "扮",
    "simplified": "扮",
    "reading": "bàn",
    "meaning": "to disguise oneself as, to dress up, to play (a role), to put on (an expression)",
    "grade": 7
  },
  {
    "id": 5501,
    "traditional": "伴侶",
    "simplified": "伴侣",
    "reading": "bànlǚ",
    "meaning": "companion, mate, helpmate, coachfellow, familiar, partner, fere, company",
    "grade": 7
  },
  {
    "id": 5502,
    "traditional": "伴隨",
    "simplified": "伴随",
    "reading": "bànsuí",
    "meaning": "accompany, follow",
    "grade": 7
  },
  {
    "id": 5503,
    "traditional": "伴奏",
    "simplified": "伴奏",
    "reading": "bànzòu",
    "meaning": "accompany (with musical instruments)",
    "grade": 7
  },
  {
    "id": 5504,
    "traditional": "幫手",
    "simplified": "帮手",
    "reading": "bāngshou",
    "meaning": "helper, assistant",
    "grade": 7
  },
  {
    "id": 5505,
    "traditional": "綁",
    "simplified": "绑",
    "reading": "bǎng",
    "meaning": "bind, tie, bind sb.'s hands behind him, truss up",
    "grade": 7
  },
  {
    "id": 5506,
    "traditional": "拌",
    "simplified": "拌",
    "reading": "bàn",
    "meaning": "to mix, to mix in, to toss (a salad)",
    "grade": 7
  },
  {
    "id": 5507,
    "traditional": "綁架",
    "simplified": "绑架",
    "reading": "bǎngjià",
    "meaning": "to kidnap, to abduct, to hijack, a kidnapping, abduction, staking",
    "grade": 7
  },
  {
    "id": 5508,
    "traditional": "榜樣",
    "simplified": "榜样",
    "reading": "bǎngyàng",
    "meaning": "example, model, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 5509,
    "traditional": "包袱",
    "simplified": "包袱",
    "reading": "bāofu",
    "meaning": "cloth wrapper, bundle wrapped in cloth, burden, millstone round one's neck, punch line (in cross-talk)",
    "grade": 7
  },
  {
    "id": 5510,
    "traditional": "棒球",
    "simplified": "棒球",
    "reading": "bàngqiú",
    "meaning": "no-hitter, apple, grounder, baseball game, homer, change-up, baseball, extra innings, curve, ball game, horsehide, infielder",
    "grade": 7
  },
  {
    "id": 5511,
    "traditional": "磅",
    "simplified": "磅",
    "reading": "bàng",
    "meaning": "see 磅秤 scale, platform balance, (loanword) pound (unit of weight, about 454 grams)",
    "grade": 7
  },
  {
    "id": 5512,
    "traditional": "包容",
    "simplified": "包容",
    "reading": "bāoróng",
    "meaning": "forgive, pardon, contain",
    "grade": 7
  },
  {
    "id": 5513,
    "traditional": "包扎",
    "simplified": "包扎",
    "reading": "bāozā",
    "meaning": "to wrap up, to pack, to bind up (a wound)",
    "grade": 7
  },
  {
    "id": 5514,
    "traditional": "剝",
    "simplified": "剥",
    "reading": "bō",
    "meaning": "to peel, to skin, to shell, to shuck, to peel, to skin, to flay, to shuck",
    "grade": 7
  },
  {
    "id": 5515,
    "traditional": "煲",
    "simplified": "煲",
    "reading": "bāo",
    "meaning": "to cook slowly over a low flame, pot, saucepan",
    "grade": 7
  },
  {
    "id": 5516,
    "traditional": "飽和",
    "simplified": "饱和",
    "reading": "bǎohé",
    "meaning": "saturation",
    "grade": 7
  },
  {
    "id": 5517,
    "traditional": "飽滿",
    "simplified": "饱满",
    "reading": "bǎomǎn",
    "meaning": "full, plump",
    "grade": 7
  },
  {
    "id": 5518,
    "traditional": "寶藏",
    "simplified": "宝藏",
    "reading": "bǎozàng",
    "meaning": "precious (mineral) deposits, (budd.) the treasure of Buddha's law",
    "grade": 7
  },
  {
    "id": 5519,
    "traditional": "保管",
    "simplified": "保管",
    "reading": "bǎoguǎn",
    "meaning": "to hold in safekeeping, to have in one's care, to guarantee, certainly, surely, custodian, curator",
    "grade": 7
  },
  {
    "id": 5520,
    "traditional": "保姆",
    "simplified": "保姆",
    "reading": "bǎomǔ",
    "meaning": "nanny, housekeeper",
    "grade": 7
  },
  {
    "id": 5521,
    "traditional": "寶庫",
    "simplified": "宝库",
    "reading": "bǎokù",
    "meaning": "treasure-house, treasury, treasure-trove (often fig., book of treasured wisdom)",
    "grade": 7
  },
  {
    "id": 5522,
    "traditional": "保暖",
    "simplified": "保暖",
    "reading": "bǎo nuǎn",
    "meaning": "to stay warm, to protect against the cold",
    "grade": 7
  },
  {
    "id": 5523,
    "traditional": "保鮮",
    "simplified": "保鲜",
    "reading": "bǎo xiān",
    "meaning": "Preservation",
    "grade": 7
  },
  {
    "id": 5524,
    "traditional": "保修",
    "simplified": "保修",
    "reading": "bǎoxiū",
    "meaning": "to promise to keep sth in good repair, guarantee, warranty",
    "grade": 7
  },
  {
    "id": 5525,
    "traditional": "保佑",
    "simplified": "保佑",
    "reading": "bǎoyòu",
    "meaning": "to bless and protect, blessing",
    "grade": 7
  },
  {
    "id": 5526,
    "traditional": "保障",
    "simplified": "保障",
    "reading": "bǎozhàng",
    "meaning": "ensure, guarantee, safeguard",
    "grade": 7
  },
  {
    "id": 5527,
    "traditional": "保質期",
    "simplified": "保质期",
    "reading": "bǎozhìqī",
    "meaning": "date of use (on foodstuffs), best before date",
    "grade": 7
  },
  {
    "id": 5528,
    "traditional": "保重",
    "simplified": "保重",
    "reading": "bǎozhòng",
    "meaning": "to take care of oneself",
    "grade": 7
  },
  {
    "id": 5529,
    "traditional": "報",
    "simplified": "报",
    "reading": "bào",
    "meaning": "newspaper, periodical, bulletin, telegram",
    "grade": 7
  },
  {
    "id": 5530,
    "traditional": "堡壘",
    "simplified": "堡垒",
    "reading": "bǎolěi",
    "meaning": "fort",
    "grade": 7
  },
  {
    "id": 5531,
    "traditional": "報仇",
    "simplified": "报仇",
    "reading": "bào chóu",
    "meaning": "to take revenge, to avenge",
    "grade": 7
  },
  {
    "id": 5532,
    "traditional": "報酬",
    "simplified": "报酬",
    "reading": "bàochou",
    "meaning": "emolument, reward, fee, recompense, earnings, gratuity, requital, retribution, price, harvest, remuneration, quittance, pay, consideration, salary, compensation, honorarium, payment, meed, gratification, wage, guerdon",
    "grade": 7
  },
  {
    "id": 5533,
    "traditional": "報廢",
    "simplified": "报废",
    "reading": "bào fèi",
    "meaning": "to scrap, to be written off",
    "grade": 7
  },
  {
    "id": 5534,
    "traditional": "報社",
    "simplified": "报社",
    "reading": "bàoshè",
    "meaning": "newspaper (i.e. a company), CL:家[jia1]",
    "grade": 7
  },
  {
    "id": 5535,
    "traditional": "報亭",
    "simplified": "报亭",
    "reading": "bàotíng",
    "meaning": "kiosk, newsstand",
    "grade": 7
  },
  {
    "id": 5536,
    "traditional": "報復",
    "simplified": "报复",
    "reading": "bàofu",
    "meaning": "to make reprisals, to retaliate, revenge, retaliation",
    "grade": 7
  },
  {
    "id": 5537,
    "traditional": "報銷",
    "simplified": "报销",
    "reading": "bào xiāo",
    "meaning": "to submit an expense account, to apply for reimbursement, to write off, to wipe out",
    "grade": 7
  },
  {
    "id": 5538,
    "traditional": "抱負",
    "simplified": "抱负",
    "reading": "bàofù",
    "meaning": "aspiration, ambition",
    "grade": 7
  },
  {
    "id": 5539,
    "traditional": "豹",
    "simplified": "豹",
    "reading": "bào",
    "meaning": "leopard, panther",
    "grade": 7
  },
  {
    "id": 5540,
    "traditional": "暴風驟雨",
    "simplified": "暴风骤雨",
    "reading": "bàofēng-zhòuyǔ",
    "meaning": "violent wind and rainstorm, hurricane, tempest",
    "grade": 7
  },
  {
    "id": 5541,
    "traditional": "暴利",
    "simplified": "暴利",
    "reading": "bàolì",
    "meaning": "sudden huge profits",
    "grade": 7
  },
  {
    "id": 5542,
    "traditional": "暴躁",
    "simplified": "暴躁",
    "reading": "bàozào",
    "meaning": "irascible, irritable, violent",
    "grade": 7
  },
  {
    "id": 5543,
    "traditional": "曝光",
    "simplified": "曝光",
    "reading": "bào guāng",
    "meaning": "expose",
    "grade": 7
  },
  {
    "id": 5544,
    "traditional": "爆冷門",
    "simplified": "爆冷门",
    "reading": "bàolěngmén",
    "meaning": "an upset (esp. in sports), unexpected turn of events, to pull off a coup, a breakthrough",
    "grade": 7
  },
  {
    "id": 5545,
    "traditional": "爆竹",
    "simplified": "爆竹",
    "reading": "bàozhú",
    "meaning": "firecracker",
    "grade": 7
  },
  {
    "id": 5546,
    "traditional": "悲哀",
    "simplified": "悲哀",
    "reading": "bēiāi",
    "meaning": "grieved, sorrowful",
    "grade": 7
  },
  {
    "id": 5547,
    "traditional": "爆滿",
    "simplified": "爆满",
    "reading": "bàomǎn",
    "meaning": "filled to capacity (of theater, stadium, gymnasium etc)",
    "grade": 7
  },
  {
    "id": 5548,
    "traditional": "悲觀",
    "simplified": "悲观",
    "reading": "bēiguān",
    "meaning": "pessimistic",
    "grade": 7
  },
  {
    "id": 5549,
    "traditional": "悲歡離合",
    "simplified": "悲欢离合",
    "reading": "bēihuān-líhé",
    "meaning": "joys and sorrows, partings and reunions, the vicissitudes of life",
    "grade": 7
  },
  {
    "id": 5550,
    "traditional": "卑鄙",
    "simplified": "卑鄙",
    "reading": "bēibǐ",
    "meaning": "base, mean, contemptible, despicable",
    "grade": 7
  },
  {
    "id": 5551,
    "traditional": "悲痛",
    "simplified": "悲痛",
    "reading": "bēitòng",
    "meaning": "grieved, sorrowful",
    "grade": 7
  },
  {
    "id": 5552,
    "traditional": "碑",
    "simplified": "碑",
    "reading": "bēi",
    "meaning": "a monument, an upright stone tablet, stele, CL:塊|块[kuai4],面[mian4]",
    "grade": 7
  },
  {
    "id": 5553,
    "traditional": "貝殼",
    "simplified": "贝壳",
    "reading": "bèiké",
    "meaning": "cowrie, shell, cameo, conch, seashell, valve, clamshell",
    "grade": 7
  },
  {
    "id": 5554,
    "traditional": "備課",
    "simplified": "备课",
    "reading": "bèi kè",
    "meaning": "(of a teacher) to prepare lessons",
    "grade": 7
  },
  {
    "id": 5555,
    "traditional": "備受",
    "simplified": "备受",
    "reading": "bèishòu",
    "meaning": "fully experience (good or bad)",
    "grade": 7
  },
  {
    "id": 5556,
    "traditional": "背面",
    "simplified": "背面",
    "reading": "bèimiàn",
    "meaning": "the back, the reverse side, the wrong side",
    "grade": 7
  },
  {
    "id": 5557,
    "traditional": "備用",
    "simplified": "备用",
    "reading": "bèiyòng",
    "meaning": "reserve, spare, alternate, backup",
    "grade": 7
  },
  {
    "id": 5558,
    "traditional": "背誦",
    "simplified": "背诵",
    "reading": "bèisòng",
    "meaning": "to recite, to repeat from memory",
    "grade": 7
  },
  {
    "id": 5559,
    "traditional": "背叛",
    "simplified": "背叛",
    "reading": "bèipàn",
    "meaning": "to betray",
    "grade": 7
  },
  {
    "id": 5560,
    "traditional": "被捕",
    "simplified": "被捕",
    "reading": "bèibǔ",
    "meaning": "to be arrested, under arrest",
    "grade": 7
  },
  {
    "id": 5561,
    "traditional": "奔赴",
    "simplified": "奔赴",
    "reading": "bēnfù",
    "meaning": "to rush to, to hurry to",
    "grade": 7
  },
  {
    "id": 5562,
    "traditional": "奔波",
    "simplified": "奔波",
    "reading": "bēnbō",
    "meaning": "rush about, be busy running about",
    "grade": 7
  },
  {
    "id": 5563,
    "traditional": "本分",
    "simplified": "本分",
    "reading": "běnfèn",
    "meaning": "(to play) one's part, one's role, one's duty, (to stay within) one's bounds, dutiful, keeping to one's role",
    "grade": 7
  },
  {
    "id": 5564,
    "traditional": "本能",
    "simplified": "本能",
    "reading": "běnnéng",
    "meaning": "whim, appetence, vagary, instinct, instinctive, native ability, inherent aptitude, impulse, caprice, faculty",
    "grade": 7
  },
  {
    "id": 5565,
    "traditional": "本錢",
    "simplified": "本钱",
    "reading": "běnqián",
    "meaning": "principal, capital",
    "grade": 7
  },
  {
    "id": 5566,
    "traditional": "本性",
    "simplified": "本性",
    "reading": "běnxìng",
    "meaning": "natural instincts, nature, inherent quality",
    "grade": 7
  },
  {
    "id": 5567,
    "traditional": "本著",
    "simplified": "本着",
    "reading": "běnzhe",
    "meaning": "based on..., in conformance with.., taking as one's main principle",
    "grade": 7
  },
  {
    "id": 5568,
    "traditional": "本意",
    "simplified": "本意",
    "reading": "běnyì",
    "meaning": "original idea, real intention, etymon",
    "grade": 7
  },
  {
    "id": 5569,
    "traditional": "奔",
    "simplified": "奔",
    "reading": "bēn",
    "meaning": "hurry, rush, be getting on for, head for, flee, run, run quickly, approach, hasten, be getting close to, go straight towards",
    "grade": 7
  },
  {
    "id": 5570,
    "traditional": "笨蛋",
    "simplified": "笨蛋",
    "reading": "bèndàn",
    "meaning": "fool, idiot",
    "grade": 7
  },
  {
    "id": 5571,
    "traditional": "笨重",
    "simplified": "笨重",
    "reading": "bènzhòng",
    "meaning": "heavy, cumbersome, unwieldy",
    "grade": 7
  },
  {
    "id": 5572,
    "traditional": "崩潰",
    "simplified": "崩溃",
    "reading": "bēngkuì",
    "meaning": "collapse, fall apart",
    "grade": 7
  },
  {
    "id": 5573,
    "traditional": "繃帶",
    "simplified": "绷带",
    "reading": "bēngdài",
    "meaning": "bandage (loanword)",
    "grade": 7
  },
  {
    "id": 5574,
    "traditional": "繃",
    "simplified": "绷",
    "reading": "běng",
    "meaning": "to stretch, taut, to tie, to bind, to have a taut face",
    "grade": 7
  },
  {
    "id": 5575,
    "traditional": "本色",
    "simplified": "本色",
    "reading": "běnsè",
    "meaning": "inherent qualities, natural qualities, distinctive character, true qualities, natural color",
    "grade": 7
  },
  {
    "id": 5576,
    "traditional": "逼近",
    "simplified": "逼近",
    "reading": "bījìn",
    "meaning": "press on towards, approach",
    "grade": 7
  },
  {
    "id": 5577,
    "traditional": "蹦",
    "simplified": "蹦",
    "reading": "bèng",
    "meaning": "to jump, to bounce, to hop",
    "grade": 7
  },
  {
    "id": 5578,
    "traditional": "逼迫",
    "simplified": "逼迫",
    "reading": "bīpò",
    "meaning": "to force, to compel, to coerce",
    "grade": 7
  },
  {
    "id": 5579,
    "traditional": "比比皆是",
    "simplified": "比比皆是",
    "reading": "bǐbǐ-jiēshì",
    "meaning": "can be found everywhere",
    "grade": 7
  },
  {
    "id": 5580,
    "traditional": "逼真",
    "simplified": "逼真",
    "reading": "bīzhēn",
    "meaning": "lifelike, true to life, distinctly, clearly",
    "grade": 7
  },
  {
    "id": 5581,
    "traditional": "鼻涕",
    "simplified": "鼻涕",
    "reading": "bítì",
    "meaning": "nasal mucus, snivel",
    "grade": 7
  },
  {
    "id": 5582,
    "traditional": "比不上",
    "simplified": "比不上",
    "reading": "bǐbùshàng",
    "meaning": "not compare with",
    "grade": 7
  },
  {
    "id": 5583,
    "traditional": "比起",
    "simplified": "比起",
    "reading": "bǐqǐ",
    "meaning": "compared with",
    "grade": 7
  },
  {
    "id": 5584,
    "traditional": "比試",
    "simplified": "比试",
    "reading": "bǐshi",
    "meaning": "to have a competition, to measure with one's hand or arm, to make a gesture of measuring",
    "grade": 7
  },
  {
    "id": 5585,
    "traditional": "比喻",
    "simplified": "比喻",
    "reading": "bǐyù",
    "meaning": "metaphor, analogy",
    "grade": 7
  },
  {
    "id": 5586,
    "traditional": "鄙視",
    "simplified": "鄙视",
    "reading": "bǐshì",
    "meaning": "to despise, to disdain, to look down upon",
    "grade": 7
  },
  {
    "id": 5587,
    "traditional": "必不可少",
    "simplified": "必不可少",
    "reading": "bìbùkěshǎo",
    "meaning": "absolutely necessary, indispensable, essential",
    "grade": 7
  },
  {
    "id": 5588,
    "traditional": "必定",
    "simplified": "必定",
    "reading": "bìdìng",
    "meaning": "inevitably, sure, for sure, by all means, needs, necessarily, sure enough, sure as shooting, be sure to, surely, certainly, of necessity, for certain",
    "grade": 7
  },
  {
    "id": 5589,
    "traditional": "碧綠",
    "simplified": "碧绿",
    "reading": "bìlǜ",
    "meaning": "dark green",
    "grade": 7
  },
  {
    "id": 5590,
    "traditional": "弊病",
    "simplified": "弊病",
    "reading": "bìbìng",
    "meaning": "malady, evil, malpractice, drawback, disadvantage",
    "grade": 7
  },
  {
    "id": 5591,
    "traditional": "弊端",
    "simplified": "弊端",
    "reading": "bìduān",
    "meaning": "malpractice, abuse, corrupt practice",
    "grade": 7
  },
  {
    "id": 5592,
    "traditional": "避難",
    "simplified": "避难",
    "reading": "bì nàn",
    "meaning": "refuge, to take refuge, to seek asylum (political etc)",
    "grade": 7
  },
  {
    "id": 5593,
    "traditional": "壁畫",
    "simplified": "壁画",
    "reading": "bìhuà",
    "meaning": "mural (painting), fresco",
    "grade": 7
  },
  {
    "id": 5594,
    "traditional": "避暑",
    "simplified": "避暑",
    "reading": "bì shǔ",
    "meaning": "to be away for the summer holidays, to spend a holiday at a summer resort, to prevent sunstroke",
    "grade": 7
  },
  {
    "id": 5595,
    "traditional": "邊界",
    "simplified": "边界",
    "reading": "biānjiè",
    "meaning": "confine, margin, bounds, marchland, boundary, demarcation line, demarcation, borderline, precinct, skirt, frontier, list, borderland, delimitation, butting, bound, boundary line, perimeter, outskirts, coast, march, line, border, mete, limit, skirting, edge",
    "grade": 7
  },
  {
    "id": 5596,
    "traditional": "邊疆",
    "simplified": "边疆",
    "reading": "biānjiāng",
    "meaning": "border area, borderland, frontier, frontier region",
    "grade": 7
  },
  {
    "id": 5597,
    "traditional": "邊遠",
    "simplified": "边远",
    "reading": "biānyuǎn",
    "meaning": "far from the center, remote, outlying",
    "grade": 7
  },
  {
    "id": 5598,
    "traditional": "編劇",
    "simplified": "编剧",
    "reading": "biānjù",
    "meaning": "to write a play, scenario, dramatist, screenwriter",
    "grade": 7
  },
  {
    "id": 5599,
    "traditional": "編號",
    "simplified": "编号",
    "reading": "biānhào",
    "meaning": "to number, numbering, serial number",
    "grade": 7
  },
  {
    "id": 5600,
    "traditional": "編寫",
    "simplified": "编写",
    "reading": "biānxiě",
    "meaning": "to compile",
    "grade": 7
  },
  {
    "id": 5601,
    "traditional": "編造",
    "simplified": "编造",
    "reading": "biānzào",
    "meaning": "to compile, to draw up, to fabricate, to invent, to concoct, to make up, to cook up",
    "grade": 7
  },
  {
    "id": 5602,
    "traditional": "編排",
    "simplified": "编排",
    "reading": "biānpái",
    "meaning": "to arrange, to lay out",
    "grade": 7
  },
  {
    "id": 5603,
    "traditional": "鞭策",
    "simplified": "鞭策",
    "reading": "biāncè",
    "meaning": "to spur on, to urge on, to encourage sb (e.g. to make progress)",
    "grade": 7
  },
  {
    "id": 5604,
    "traditional": "鞭炮",
    "simplified": "鞭炮",
    "reading": "biānpào",
    "meaning": "firecrackers, string of small firecrackers, CL:枚[mei2]",
    "grade": 7
  },
  {
    "id": 5605,
    "traditional": "變革",
    "simplified": "变革",
    "reading": "biàngé",
    "meaning": "change, revolution",
    "grade": 7
  },
  {
    "id": 5606,
    "traditional": "貶值",
    "simplified": "贬值",
    "reading": "biǎn zhí",
    "meaning": "to become devaluated, to devaluate, to depreciate",
    "grade": 7
  },
  {
    "id": 5607,
    "traditional": "變遷",
    "simplified": "变迁",
    "reading": "biànqiān",
    "meaning": "vicissitudinary, change",
    "grade": 7
  },
  {
    "id": 5608,
    "traditional": "變異",
    "simplified": "变异",
    "reading": "biànyì",
    "meaning": "variation",
    "grade": 7
  },
  {
    "id": 5609,
    "traditional": "變幻莫測",
    "simplified": "变幻莫测",
    "reading": "biànhuànmòcè",
    "meaning": "to change unpredictably, unpredictable, erratic, treacherous",
    "grade": 7
  },
  {
    "id": 5610,
    "traditional": "便道",
    "simplified": "便道",
    "reading": "biàndào",
    "meaning": "pavement, sidewalk, shortcut, makeshift road",
    "grade": 7
  },
  {
    "id": 5611,
    "traditional": "變質",
    "simplified": "变质",
    "reading": "biànzhì",
    "meaning": "to degenerate, to go bad, to deteriorate, metamorphosis",
    "grade": 7
  },
  {
    "id": 5612,
    "traditional": "便飯",
    "simplified": "便饭",
    "reading": "biànfàn",
    "meaning": "an ordinary meal, simple home cooking",
    "grade": 7
  },
  {
    "id": 5613,
    "traditional": "便利店",
    "simplified": "便利店",
    "reading": "biànlìdiàn",
    "meaning": "convenience store",
    "grade": 7
  },
  {
    "id": 5614,
    "traditional": "便捷",
    "simplified": "便捷",
    "reading": "biànjié",
    "meaning": "convenient and fast",
    "grade": 7
  },
  {
    "id": 5615,
    "traditional": "辨別",
    "simplified": "辨别",
    "reading": "biànbié",
    "meaning": "to differentiate, to distinguish, to discriminate",
    "grade": 7
  },
  {
    "id": 5616,
    "traditional": "遍布",
    "simplified": "遍布",
    "reading": "biànbù",
    "meaning": "to cover the whole (area), to be found throughout",
    "grade": 7
  },
  {
    "id": 5617,
    "traditional": "辨認",
    "simplified": "辨认",
    "reading": "biànrèn",
    "meaning": "to recognize, to identify",
    "grade": 7
  },
  {
    "id": 5618,
    "traditional": "辯",
    "simplified": "辩",
    "reading": "biàn",
    "meaning": "to dispute, to debate, to argue, to discuss",
    "grade": 7
  },
  {
    "id": 5619,
    "traditional": "辯護",
    "simplified": "辩护",
    "reading": "biànhù",
    "meaning": "to speak in defense of, to argue in favor of, to defend, to plead",
    "grade": 7
  },
  {
    "id": 5620,
    "traditional": "辮子",
    "simplified": "辫子",
    "reading": "biànzi",
    "meaning": "plait, braid, pigtail, a mistake or shortcoming that may be exploited by an opponent, handle, CL:根[gen1],條|条[tiao2]",
    "grade": 7
  },
  {
    "id": 5621,
    "traditional": "辯解",
    "simplified": "辩解",
    "reading": "biànjiě",
    "meaning": "to explain, to justify, to defend (a point of view etc), to provide an explanation, to try to defend oneself",
    "grade": 7
  },
  {
    "id": 5622,
    "traditional": "標榜",
    "simplified": "标榜",
    "reading": "biāobǎng",
    "meaning": "excessively praise, parade, glorify, advertisement, boast, boost, advertise, flaunt",
    "grade": 7
  },
  {
    "id": 5623,
    "traditional": "標",
    "simplified": "标",
    "reading": "biāo",
    "meaning": "mark, sign, label, to mark with a symbol, label, lettering etc, to bear (a brand name, registration number etc), prize, award, bid, target, quota, (old) the topmost branches of a tree, visible symptom, classifier for military units",
    "grade": 7
  },
  {
    "id": 5624,
    "traditional": "標本",
    "simplified": "标本",
    "reading": "biāoběn",
    "meaning": "example, representative, pattern, specimen, root cause and symptoms of disease, sample, model",
    "grade": 7
  },
  {
    "id": 5625,
    "traditional": "標簽",
    "simplified": "标签",
    "reading": "biāoqiān",
    "meaning": "mark, ticket, gummed label, tab, markup, sticker, label, tally, tag",
    "grade": 7
  },
  {
    "id": 5626,
    "traditional": "標示",
    "simplified": "标示",
    "reading": "biāoshì",
    "meaning": "label",
    "grade": 7
  },
  {
    "id": 5627,
    "traditional": "標語",
    "simplified": "标语",
    "reading": "biāoyǔ",
    "meaning": "written slogan, placard, CL:幅[fu2],張|张[zhang1],條|条[tiao2]",
    "grade": 7
  },
  {
    "id": 5628,
    "traditional": "飆升",
    "simplified": "飙升",
    "reading": "biāoshēng",
    "meaning": "to rise rapidly, to soar",
    "grade": 7
  },
  {
    "id": 5629,
    "traditional": "標致",
    "simplified": "标致",
    "reading": "biāozhi",
    "meaning": "Peugeot, beautiful (of woman), pretty",
    "grade": 7
  },
  {
    "id": 5630,
    "traditional": "表白",
    "simplified": "表白",
    "reading": "biǎobái",
    "meaning": "to explain oneself, to express, to reveal one's thoughts or feelings, declaration, confession",
    "grade": 7
  },
  {
    "id": 5631,
    "traditional": "表決",
    "simplified": "表决",
    "reading": "biǎojué",
    "meaning": "(decide by) vote",
    "grade": 7
  },
  {
    "id": 5632,
    "traditional": "表述",
    "simplified": "表述",
    "reading": "biǎoshù",
    "meaning": "to formulate, enunciation, to explain sth precisely",
    "grade": 7
  },
  {
    "id": 5633,
    "traditional": "表率",
    "simplified": "表率",
    "reading": "biǎoshuài",
    "meaning": "example, model",
    "grade": 7
  },
  {
    "id": 5634,
    "traditional": "表彰",
    "simplified": "表彰",
    "reading": "biǎozhāng",
    "meaning": "to honor, to commend, to cite (in dispatches)",
    "grade": 7
  },
  {
    "id": 5635,
    "traditional": "憋",
    "simplified": "憋",
    "reading": "biē",
    "meaning": "to choke, to stifle, to restrain, to hold back, to hold in (urine), to hold (one's breath)",
    "grade": 7
  },
  {
    "id": 5636,
    "traditional": "別具匠心",
    "simplified": "别具匠心",
    "reading": "biéjù-jiàngxīn",
    "meaning": "Unique",
    "grade": 7
  },
  {
    "id": 5637,
    "traditional": "別看",
    "simplified": "别看",
    "reading": "biékàn",
    "meaning": "Don't look at",
    "grade": 7
  },
  {
    "id": 5638,
    "traditional": "表態",
    "simplified": "表态",
    "reading": "biǎo tài",
    "meaning": "to declare one's position, to say where one stands",
    "grade": 7
  },
  {
    "id": 5639,
    "traditional": "別墅",
    "simplified": "别墅",
    "reading": "biéshù",
    "meaning": "villa",
    "grade": 7
  },
  {
    "id": 5640,
    "traditional": "別說",
    "simplified": "别说",
    "reading": "biéshuō",
    "meaning": "Don't say",
    "grade": 7
  },
  {
    "id": 5641,
    "traditional": "別提了",
    "simplified": "别提了",
    "reading": "biétíle",
    "meaning": "say no more, don't bring it up, drop the subject",
    "grade": 7
  },
  {
    "id": 5642,
    "traditional": "別扭",
    "simplified": "别扭",
    "reading": "bièniu",
    "meaning": "awkward, difficult, uncomfortable, not agreeing, at loggerheads, gauche",
    "grade": 7
  },
  {
    "id": 5643,
    "traditional": "別致",
    "simplified": "别致",
    "reading": "biézhì",
    "meaning": "unusual, unique, variant of 別緻|别致[bie2 zhi4]",
    "grade": 7
  },
  {
    "id": 5644,
    "traditional": "彬彬有禮",
    "simplified": "彬彬有礼",
    "reading": "bīnbīn-yǒulǐ",
    "meaning": "refined and courteous, urbane",
    "grade": 7
  },
  {
    "id": 5645,
    "traditional": "繽紛",
    "simplified": "缤纷",
    "reading": "bīnfēn",
    "meaning": "vast and various, rich and diverse",
    "grade": 7
  },
  {
    "id": 5646,
    "traditional": "冰棍兒",
    "simplified": "冰棍儿",
    "reading": "bīnggùnr5",
    "meaning": "ice lolly, popsicle",
    "grade": 7
  },
  {
    "id": 5647,
    "traditional": "濱海",
    "simplified": "滨海",
    "reading": "bīnhǎi",
    "meaning": "Binhai (place name), Binhai New District, subprovincial district of Tianjin, Binhai county in Yancheng 鹽城|盐城[Yan2 cheng2], Jiangsu, fictitious city Binhai in political satire, coastal, bordering the sea",
    "grade": 7
  },
  {
    "id": 5648,
    "traditional": "丙",
    "simplified": "丙",
    "reading": "bǐng",
    "meaning": "third of the ten Heavenly Stems 十天干[shi2 tian1 gan1], third in order, letter 'C' or roman 'III' in list 'A, B, C', or 'I, II, III' etc, ancient Chinese compass point: 165°, propyl",
    "grade": 7
  },
  {
    "id": 5649,
    "traditional": "秉承",
    "simplified": "秉承",
    "reading": "bǐngchéng",
    "meaning": "to take orders, to receive commands, to carry on (a tradition)",
    "grade": 7
  },
  {
    "id": 5650,
    "traditional": "冰山",
    "simplified": "冰山",
    "reading": "bīngshān",
    "meaning": "iceberg, CL:座[zuo4]",
    "grade": 7
  },
  {
    "id": 5651,
    "traditional": "併購",
    "simplified": "并购",
    "reading": "bìnggòu",
    "meaning": "merge into",
    "grade": 7
  },
  {
    "id": 5652,
    "traditional": "並列",
    "simplified": "并列",
    "reading": "bìngliè",
    "meaning": "appose, collocate, stand side by side, be juxtaposed, juxtapose",
    "grade": 7
  },
  {
    "id": 5653,
    "traditional": "並非",
    "simplified": "并非",
    "reading": "bìngfēi",
    "meaning": "really isn't",
    "grade": 7
  },
  {
    "id": 5654,
    "traditional": "病床",
    "simplified": "病床",
    "reading": "bìngchuáng",
    "meaning": "hospital bed, sickbed",
    "grade": 7
  },
  {
    "id": 5655,
    "traditional": "並行",
    "simplified": "并行",
    "reading": "bìngxíng",
    "meaning": "to proceed in parallel, side by side (of two processes, developments, thoughts etc)",
    "grade": 7
  },
  {
    "id": 5656,
    "traditional": "撥",
    "simplified": "拨",
    "reading": "bō",
    "meaning": "move with hand/foot/stick/etc., turn, stir, poke",
    "grade": 7
  },
  {
    "id": 5657,
    "traditional": "病癥",
    "simplified": "病症",
    "reading": "bìngzhèng",
    "meaning": "disease, illness",
    "grade": 7
  },
  {
    "id": 5658,
    "traditional": "撥款",
    "simplified": "拨款",
    "reading": "bōkuǎn",
    "meaning": "to allocate funds, appropriation",
    "grade": 7
  },
  {
    "id": 5659,
    "traditional": "波及",
    "simplified": "波及",
    "reading": "bōjí",
    "meaning": "affect, spread to",
    "grade": 7
  },
  {
    "id": 5660,
    "traditional": "撥通",
    "simplified": "拨通",
    "reading": "bōtōng",
    "meaning": "Dial",
    "grade": 7
  },
  {
    "id": 5661,
    "traditional": "波瀾",
    "simplified": "波澜",
    "reading": "bōlán",
    "meaning": "billows, great waves (fig. of a story with great momentum)",
    "grade": 7
  },
  {
    "id": 5662,
    "traditional": "波折",
    "simplified": "波折",
    "reading": "bōzhé",
    "meaning": "twists and turns",
    "grade": 7
  },
  {
    "id": 5663,
    "traditional": "剝奪",
    "simplified": "剥夺",
    "reading": "bōduó",
    "meaning": "to deprive, to expropriate, to strip (sb of his property)",
    "grade": 7
  },
  {
    "id": 5664,
    "traditional": "波濤",
    "simplified": "波涛",
    "reading": "bōtāo",
    "meaning": "great waves, billows",
    "grade": 7
  },
  {
    "id": 5665,
    "traditional": "伯伯",
    "simplified": "伯伯",
    "reading": "bóbo",
    "meaning": "uncle-in-law, father's elder brother, uncle",
    "grade": 7
  },
  {
    "id": 5666,
    "traditional": "剝削",
    "simplified": "剥削",
    "reading": "bōxuē",
    "meaning": "to exploit, exploitation",
    "grade": 7
  },
  {
    "id": 5667,
    "traditional": "伯母",
    "simplified": "伯母",
    "reading": "bómǔ",
    "meaning": "wife of father's elder brother, aunt, (polite form of address for a woman who is about the age of one's mother), CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 5668,
    "traditional": "駁回",
    "simplified": "驳回",
    "reading": "bóhuí",
    "meaning": "to reject, to turn down, to overrule",
    "grade": 7
  },
  {
    "id": 5669,
    "traditional": "脖子",
    "simplified": "脖子",
    "reading": "bózi",
    "meaning": "cervix, neck",
    "grade": 7
  },
  {
    "id": 5670,
    "traditional": "伯父",
    "simplified": "伯父",
    "reading": "bófù",
    "meaning": "father's elder brother, term of respect for older man, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 5671,
    "traditional": "搏鬥",
    "simplified": "搏斗",
    "reading": "bódòu",
    "meaning": "to wrestle, to fight, to struggle",
    "grade": 7
  },
  {
    "id": 5672,
    "traditional": "不見得",
    "simplified": "不见得",
    "reading": "bùjiànde",
    "meaning": "not likely, not necessarily/likely, likely, unlikely, not necessarily",
    "grade": 7
  },
  {
    "id": 5673,
    "traditional": "不定",
    "simplified": "不定",
    "reading": "bùdìng",
    "meaning": "uncertain, indeterminate, indefinite, undetermined",
    "grade": 7
  },
  {
    "id": 5674,
    "traditional": "不慎",
    "simplified": "不慎",
    "reading": "bùshèn",
    "meaning": "incautious, inattentive",
    "grade": 7
  },
  {
    "id": 5675,
    "traditional": "不利於",
    "simplified": "不利于",
    "reading": "bù lìyú",
    "meaning": "is harmful to",
    "grade": 7
  },
  {
    "id": 5676,
    "traditional": "不算",
    "simplified": "不算",
    "reading": "bùsuàn",
    "meaning": "Not count",
    "grade": 7
  },
  {
    "id": 5677,
    "traditional": "不像話",
    "simplified": "不像话",
    "reading": "bù xiànghuà",
    "meaning": "unreasonable, shocking, outrageous",
    "grade": 7
  },
  {
    "id": 5678,
    "traditional": "不屑",
    "simplified": "不屑",
    "reading": "bùxiè",
    "meaning": "to disdain to do sth, to think sth not worth doing, to feel it beneath one's dignity",
    "grade": 7
  },
  {
    "id": 5679,
    "traditional": "不適",
    "simplified": "不适",
    "reading": "bùshì",
    "meaning": "unwell, indisposed",
    "grade": 7
  },
  {
    "id": 5680,
    "traditional": "不亞於",
    "simplified": "不亚于",
    "reading": "bùyàyú",
    "meaning": "no less than, not inferior to",
    "grade": 7
  },
  {
    "id": 5681,
    "traditional": "不亦樂乎",
    "simplified": "不亦乐乎",
    "reading": "bùyìlèhū",
    "meaning": "lit. isn't that a joy? (quote from Confucius), fig. (jocularly) extremely, awfully",
    "grade": 7
  },
  {
    "id": 5682,
    "traditional": "不懈",
    "simplified": "不懈",
    "reading": "bùxiè",
    "meaning": "untiring, unremitting, indefatigable",
    "grade": 7
  },
  {
    "id": 5683,
    "traditional": "不翼而飛",
    "simplified": "不翼而飞",
    "reading": "bùyìérfēi",
    "meaning": "to disappear without trace, to vanish all of a sudden, to spread fast, to spread like wildfire",
    "grade": 7
  },
  {
    "id": 5684,
    "traditional": "不正之風",
    "simplified": "不正之风",
    "reading": "bùzhèngzhīfēng",
    "meaning": "unhealthy tendency",
    "grade": 7
  },
  {
    "id": 5685,
    "traditional": "補給",
    "simplified": "补给",
    "reading": "bǔjǐ",
    "meaning": "supply, replenishment, to replenish",
    "grade": 7
  },
  {
    "id": 5686,
    "traditional": "不用說",
    "simplified": "不用说",
    "reading": "bùyòngshuō",
    "meaning": "Needless to say",
    "grade": 7
  },
  {
    "id": 5687,
    "traditional": "捕捉",
    "simplified": "捕捉",
    "reading": "bǔzhuō",
    "meaning": "seizure, seize, tackle, capture, snare, nobble, get, catch",
    "grade": 7
  },
  {
    "id": 5688,
    "traditional": "哺育",
    "simplified": "哺育",
    "reading": "bǔyù",
    "meaning": "to feed, to nurture, to foster",
    "grade": 7
  },
  {
    "id": 5689,
    "traditional": "不恥下問",
    "simplified": "不耻下问",
    "reading": "bùchǐ-xiàwèn",
    "meaning": "not feel ashamed to ask and learn from one's subordinates",
    "grade": 7
  },
  {
    "id": 5690,
    "traditional": "不辭而別",
    "simplified": "不辞而别",
    "reading": "bùcíérbié",
    "meaning": "to leave without saying good-bye",
    "grade": 7
  },
  {
    "id": 5691,
    "traditional": "補救",
    "simplified": "补救",
    "reading": "bǔjiù",
    "meaning": "to remedy",
    "grade": 7
  },
  {
    "id": 5692,
    "traditional": "不得而知",
    "simplified": "不得而知",
    "reading": "bùdéérzhī",
    "meaning": "unknown, unable to find out",
    "grade": 7
  },
  {
    "id": 5693,
    "traditional": "不妨",
    "simplified": "不妨",
    "reading": "bùfáng",
    "meaning": "there is no harm in, might as well",
    "grade": 7
  },
  {
    "id": 5694,
    "traditional": "不得已",
    "simplified": "不得已",
    "reading": "bùdéyǐ",
    "meaning": "to act against one's will, to have no alternative but to, to have to, to have no choice, must",
    "grade": 7
  },
  {
    "id": 5695,
    "traditional": "不服氣",
    "simplified": "不服气",
    "reading": "bùfúqì",
    "meaning": "Uncomfortable",
    "grade": 7
  },
  {
    "id": 5696,
    "traditional": "不假思索",
    "simplified": "不假思索",
    "reading": "bùjiǎ-sīsuǒ",
    "meaning": "to act without taking time to think (idiom), to react instantly, to fire from the hip",
    "grade": 7
  },
  {
    "id": 5697,
    "traditional": "不解",
    "simplified": "不解",
    "reading": "bùjiě",
    "meaning": "not understand",
    "grade": 7
  },
  {
    "id": 5698,
    "traditional": "不經意",
    "simplified": "不经意",
    "reading": "bùjīngyì",
    "meaning": "not paying attention, carelessly, by accident",
    "grade": 7
  },
  {
    "id": 5699,
    "traditional": "不景氣",
    "simplified": "不景气",
    "reading": "bù jǐngqì",
    "meaning": "depressive, in depression, in depressing state, recession",
    "grade": 7
  },
  {
    "id": 5700,
    "traditional": "不堪",
    "simplified": "不堪",
    "reading": "bùkān",
    "meaning": "can't bear/stand",
    "grade": 7
  },
  {
    "id": 5701,
    "traditional": "不服",
    "simplified": "不服",
    "reading": "bùfú",
    "meaning": "not to accept sth, to want to have sth overruled or changed, to refuse to obey or comply, to refuse to accept as final, to remain unconvinced by, not to give in to",
    "grade": 7
  },
  {
    "id": 5702,
    "traditional": "不可思議",
    "simplified": "不可思议",
    "reading": "bùkě-sīyì",
    "meaning": "inconceivable (idiom), unimaginable, unfathomable",
    "grade": 7
  },
  {
    "id": 5703,
    "traditional": "不可避免",
    "simplified": "不可避免",
    "reading": "bùkěbìmiǎn",
    "meaning": "unavoidably",
    "grade": 7
  },
  {
    "id": 5704,
    "traditional": "不肯",
    "simplified": "不肯",
    "reading": "bù kěn",
    "meaning": "Refuse",
    "grade": 7
  },
  {
    "id": 5705,
    "traditional": "不理",
    "simplified": "不理",
    "reading": "bùlǐ",
    "meaning": "to refuse to acknowledge, to pay no attention to, to take no notice of, to ignore",
    "grade": 7
  },
  {
    "id": 5706,
    "traditional": "不了了之",
    "simplified": "不了了之",
    "reading": "bùliǎo-liǎozhī",
    "meaning": "to settle a matter by leaving it unsettled, to end up with nothing definite",
    "grade": 7
  },
  {
    "id": 5707,
    "traditional": "不難",
    "simplified": "不难",
    "reading": "bù nán",
    "meaning": "Not difficult",
    "grade": 7
  },
  {
    "id": 5708,
    "traditional": "不平",
    "simplified": "不平",
    "reading": "bùpíng",
    "meaning": "dissatisfaction",
    "grade": 7
  },
  {
    "id": 5709,
    "traditional": "不起眼",
    "simplified": "不起眼",
    "reading": "bùqǐyǎn",
    "meaning": "unremarkable, nothing out of the ordinary",
    "grade": 7
  },
  {
    "id": 5710,
    "traditional": "不如說",
    "simplified": "不如说",
    "reading": "bùrú shuō",
    "meaning": "rather say",
    "grade": 7
  },
  {
    "id": 5711,
    "traditional": "不同尋常",
    "simplified": "不同寻常",
    "reading": "bùtóngxúncháng",
    "meaning": "unusual",
    "grade": 7
  },
  {
    "id": 5712,
    "traditional": "不為人知",
    "simplified": "不为人知",
    "reading": "bùwéirénzhī",
    "meaning": "not known to anyone, secret, unknown",
    "grade": 7
  },
  {
    "id": 5713,
    "traditional": "不惜",
    "simplified": "不惜",
    "reading": "bùxī",
    "meaning": "not stint, not hesitate (to do sth.)",
    "grade": 7
  },
  {
    "id": 5714,
    "traditional": "不容",
    "simplified": "不容",
    "reading": "bùróng",
    "meaning": "must not, cannot, to not allow, cannot tolerate",
    "grade": 7
  },
  {
    "id": 5715,
    "traditional": "不相上下",
    "simplified": "不相上下",
    "reading": "bùxiāngshàngxià",
    "meaning": "equally matched, about the same",
    "grade": 7
  },
  {
    "id": 5716,
    "traditional": "不已",
    "simplified": "不已",
    "reading": "bùyǐ",
    "meaning": "incessantly, endlessly",
    "grade": 7
  },
  {
    "id": 5717,
    "traditional": "不宜",
    "simplified": "不宜",
    "reading": "bùyí",
    "meaning": "be unsuitable",
    "grade": 7
  },
  {
    "id": 5718,
    "traditional": "不以為然",
    "simplified": "不以为然",
    "reading": "bùyǐwéirán",
    "meaning": "disagree, not approve of, acceptable, object to, consider sth. not so, take exception to, right",
    "grade": 7
  },
  {
    "id": 5719,
    "traditional": "不由自主",
    "simplified": "不由自主",
    "reading": "bùyóu-zìzhǔ",
    "meaning": "can't help, involuntarily (idiom)",
    "grade": 7
  },
  {
    "id": 5720,
    "traditional": "不予",
    "simplified": "不予",
    "reading": "bùyǔ",
    "meaning": "to withhold, to refuse",
    "grade": 7
  },
  {
    "id": 5721,
    "traditional": "不由得",
    "simplified": "不由得",
    "reading": "bùyóude",
    "meaning": "can't help, cannot but",
    "grade": 7
  },
  {
    "id": 5722,
    "traditional": "不約而同",
    "simplified": "不约而同",
    "reading": "bùyuēértóng",
    "meaning": "happen to coincide, coincidentally do sth.",
    "grade": 7
  },
  {
    "id": 5723,
    "traditional": "不知",
    "simplified": "不知",
    "reading": "bùzhī",
    "meaning": "not to know, unaware, unknowingly, fig. not to admit (defeat, hardships, tiredness etc)",
    "grade": 7
  },
  {
    "id": 5724,
    "traditional": "不知不覺",
    "simplified": "不知不觉",
    "reading": "bùzhī-bùjué",
    "meaning": "unconsciously, unaware",
    "grade": 7
  },
  {
    "id": 5725,
    "traditional": "不准",
    "simplified": "不准",
    "reading": "bùzhǔn",
    "meaning": "not to allow, to forbid, to prohibit",
    "grade": 7
  },
  {
    "id": 5726,
    "traditional": "布局",
    "simplified": "布局",
    "reading": "bùjú",
    "meaning": "arrangement, composition, layout, opening (chess jargon)",
    "grade": 7
  },
  {
    "id": 5727,
    "traditional": "步入",
    "simplified": "步入",
    "reading": "bùrù",
    "meaning": "go into, walk into",
    "grade": 7
  },
  {
    "id": 5728,
    "traditional": "步驟",
    "simplified": "步骤",
    "reading": "bùzhòu",
    "meaning": "process, instance, demarche, method, measure, approach, procedure, place, step, move",
    "grade": 7
  },
  {
    "id": 5729,
    "traditional": "部件",
    "simplified": "部件",
    "reading": "bùjiàn",
    "meaning": "part, component",
    "grade": 7
  },
  {
    "id": 5730,
    "traditional": "步伐",
    "simplified": "步伐",
    "reading": "bùfá",
    "meaning": "pace, (measured) step, march",
    "grade": 7
  },
  {
    "id": 5731,
    "traditional": "部署",
    "simplified": "部署",
    "reading": "bùshǔ",
    "meaning": "dispose, deploy",
    "grade": 7
  },
  {
    "id": 5732,
    "traditional": "猜謎",
    "simplified": "猜谜",
    "reading": "cāi mí",
    "meaning": "Guess",
    "grade": 7
  },
  {
    "id": 5733,
    "traditional": "猜想",
    "simplified": "猜想",
    "reading": "cāixiǎng",
    "meaning": "to guess, to conjecture, to suppose, to suspect",
    "grade": 7
  },
  {
    "id": 5734,
    "traditional": "財經",
    "simplified": "财经",
    "reading": "cáijīng",
    "meaning": "finance and economics",
    "grade": 7
  },
  {
    "id": 5735,
    "traditional": "財力",
    "simplified": "财力",
    "reading": "cáilì",
    "meaning": "financial resources",
    "grade": 7
  },
  {
    "id": 5736,
    "traditional": "財務",
    "simplified": "财务",
    "reading": "cáiwù",
    "meaning": "finance, financial affairs, financial",
    "grade": 7
  },
  {
    "id": 5737,
    "traditional": "才華",
    "simplified": "才华",
    "reading": "cáihuá",
    "meaning": "literary talent, artistic talent, literary, literacy, flair, literary/artistic talent",
    "grade": 7
  },
  {
    "id": 5738,
    "traditional": "財政",
    "simplified": "财政",
    "reading": "cáizhèng",
    "meaning": "finance",
    "grade": 7
  },
  {
    "id": 5739,
    "traditional": "裁",
    "simplified": "裁",
    "reading": "cái",
    "meaning": "to cut out (as a dress), to cut, to trim, to reduce, to diminish, to cut back (e.g. on staff), decision, judgment",
    "grade": 7
  },
  {
    "id": 5740,
    "traditional": "財物",
    "simplified": "财物",
    "reading": "cáiwù",
    "meaning": "property, belongings",
    "grade": 7
  },
  {
    "id": 5741,
    "traditional": "裁定",
    "simplified": "裁定",
    "reading": "cáidìng",
    "meaning": "ruling",
    "grade": 7
  },
  {
    "id": 5742,
    "traditional": "採",
    "simplified": "采",
    "reading": "cǎi",
    "meaning": "pick, gather",
    "grade": 7
  },
  {
    "id": 5743,
    "traditional": "裁決",
    "simplified": "裁决",
    "reading": "cáijué",
    "meaning": "ruling, adjudication",
    "grade": 7
  },
  {
    "id": 5744,
    "traditional": "採礦",
    "simplified": "采矿",
    "reading": "cǎi kuàng",
    "meaning": "mining",
    "grade": 7
  },
  {
    "id": 5745,
    "traditional": "彩虹",
    "simplified": "彩虹",
    "reading": "cǎihóng",
    "meaning": "rainbow, CL:道[dao4]",
    "grade": 7
  },
  {
    "id": 5746,
    "traditional": "彩電",
    "simplified": "彩电",
    "reading": "cǎidiàn",
    "meaning": "color TV",
    "grade": 7
  },
  {
    "id": 5747,
    "traditional": "彩霞",
    "simplified": "彩霞",
    "reading": "cǎixiá",
    "meaning": "clouds tinged with sunset hues",
    "grade": 7
  },
  {
    "id": 5748,
    "traditional": "菜市場",
    "simplified": "菜市场",
    "reading": "càishìchǎng",
    "meaning": "food market",
    "grade": 7
  },
  {
    "id": 5749,
    "traditional": "採集",
    "simplified": "采集",
    "reading": "cǎijí",
    "meaning": "oyster, collect, gather, pick up and put together",
    "grade": 7
  },
  {
    "id": 5750,
    "traditional": "參見",
    "simplified": "参见",
    "reading": "cānjiàn",
    "meaning": "to refer to, see also, confer (cf.), to pay respect to",
    "grade": 7
  },
  {
    "id": 5751,
    "traditional": "參謀",
    "simplified": "参谋",
    "reading": "cānmóu",
    "meaning": "staff officer, to give advice",
    "grade": 7
  },
  {
    "id": 5752,
    "traditional": "參軍",
    "simplified": "参军",
    "reading": "cān jūn",
    "meaning": "to join the army",
    "grade": 7
  },
  {
    "id": 5753,
    "traditional": "餐桌",
    "simplified": "餐桌",
    "reading": "cānzhuō",
    "meaning": "dining table, dinner table",
    "grade": 7
  },
  {
    "id": 5754,
    "traditional": "參照",
    "simplified": "参照",
    "reading": "cānzhào",
    "meaning": "to consult a reference, to refer to (another document)",
    "grade": 7
  },
  {
    "id": 5755,
    "traditional": "殘留",
    "simplified": "残留",
    "reading": "cánliú",
    "meaning": "to remain, left over, surplus, remnant",
    "grade": 7
  },
  {
    "id": 5756,
    "traditional": "殘缺",
    "simplified": "残缺",
    "reading": "cánquē",
    "meaning": "badly damaged, shattered",
    "grade": 7
  },
  {
    "id": 5757,
    "traditional": "殘",
    "simplified": "残",
    "reading": "cán",
    "meaning": "to destroy, to spoil, to ruin, to injure, cruel, oppressive, savage, brutal, incomplete, disabled, to remain, to survive, remnant, surplus",
    "grade": 7
  },
  {
    "id": 5758,
    "traditional": "殘忍",
    "simplified": "残忍",
    "reading": "cánrěn",
    "meaning": "cruel, mean, merciless, ruthless",
    "grade": 7
  },
  {
    "id": 5759,
    "traditional": "慚愧",
    "simplified": "惭愧",
    "reading": "cánkuì",
    "meaning": "ashamed",
    "grade": 7
  },
  {
    "id": 5760,
    "traditional": "慘痛",
    "simplified": "惨痛",
    "reading": "cǎntòng",
    "meaning": "bitter, painful, deeply distressed",
    "grade": 7
  },
  {
    "id": 5761,
    "traditional": "慘重",
    "simplified": "惨重",
    "reading": "cǎnzhòng",
    "meaning": "disastrous",
    "grade": 7
  },
  {
    "id": 5762,
    "traditional": "慘白",
    "simplified": "惨白",
    "reading": "cǎnbái",
    "meaning": "deathly pale",
    "grade": 7
  },
  {
    "id": 5763,
    "traditional": "蒼蠅",
    "simplified": "苍蝇",
    "reading": "cāngying",
    "meaning": "housefly, CL:隻|只[zhi1]",
    "grade": 7
  },
  {
    "id": 5764,
    "traditional": "滄桑",
    "simplified": "沧桑",
    "reading": "cāngsāng",
    "meaning": "great changes, abbr. of 滄海桑田|沧海桑田[cang1 hai3 sang1 tian2]",
    "grade": 7
  },
  {
    "id": 5765,
    "traditional": "艙",
    "simplified": "舱",
    "reading": "cāng",
    "meaning": "cabin, the hold of a ship or airplane",
    "grade": 7
  },
  {
    "id": 5766,
    "traditional": "燦爛",
    "simplified": "灿烂",
    "reading": "cànlàn",
    "meaning": "brilliant, glitter, splendid, resplend, resplendent, magnificent",
    "grade": 7
  },
  {
    "id": 5767,
    "traditional": "藏品",
    "simplified": "藏品",
    "reading": "cángpǐn",
    "meaning": "museum piece, collector's item, precious object",
    "grade": 7
  },
  {
    "id": 5768,
    "traditional": "藏匿",
    "simplified": "藏匿",
    "reading": "cángnì",
    "meaning": "to cover up, to conceal, to go into hiding",
    "grade": 7
  },
  {
    "id": 5769,
    "traditional": "藏身",
    "simplified": "藏身",
    "reading": "cángshēn",
    "meaning": "to hide, to go into hiding, to take refuge",
    "grade": 7
  },
  {
    "id": 5770,
    "traditional": "操心",
    "simplified": "操心",
    "reading": "cāo xīn",
    "meaning": "to worry about",
    "grade": 7
  },
  {
    "id": 5771,
    "traditional": "操勞",
    "simplified": "操劳",
    "reading": "cāoláo",
    "meaning": "to work hard, to look after",
    "grade": 7
  },
  {
    "id": 5772,
    "traditional": "操控",
    "simplified": "操控",
    "reading": "cāokòng",
    "meaning": "to control, to manipulate",
    "grade": 7
  },
  {
    "id": 5773,
    "traditional": "槽",
    "simplified": "槽",
    "reading": "cáo",
    "meaning": "trough, manger, groove, channel, (Tw) (computing) hard drive",
    "grade": 7
  },
  {
    "id": 5774,
    "traditional": "草案",
    "simplified": "草案",
    "reading": "cǎoàn",
    "meaning": "ground plan, plan, blueprint, a protocol, skeleton, a draft plan, a draft, sketch, rough, an outline, draft",
    "grade": 7
  },
  {
    "id": 5775,
    "traditional": "草坪",
    "simplified": "草坪",
    "reading": "cǎopíng",
    "meaning": "lawn",
    "grade": 7
  },
  {
    "id": 5776,
    "traditional": "側面",
    "simplified": "侧面",
    "reading": "cèmiàn",
    "meaning": "lateral side, side, aspect, profile",
    "grade": 7
  },
  {
    "id": 5777,
    "traditional": "側重",
    "simplified": "侧重",
    "reading": "cèzhòng",
    "meaning": "to place particular emphasis on",
    "grade": 7
  },
  {
    "id": 5778,
    "traditional": "測算",
    "simplified": "测算",
    "reading": "cèsuàn",
    "meaning": "to take measurements and calculate",
    "grade": 7
  },
  {
    "id": 5779,
    "traditional": "測驗",
    "simplified": "测验",
    "reading": "cèyàn",
    "meaning": "test",
    "grade": 7
  },
  {
    "id": 5780,
    "traditional": "層出不窮",
    "simplified": "层出不穷",
    "reading": "céngchū-bùqióng",
    "meaning": "more and more emerge, innumerable succession, breeding like flies (idiom)",
    "grade": 7
  },
  {
    "id": 5781,
    "traditional": "蹭",
    "simplified": "蹭",
    "reading": "cèng",
    "meaning": "to rub against, to walk slowly, to freeload (colloquial)",
    "grade": 7
  },
  {
    "id": 5782,
    "traditional": "差錯",
    "simplified": "差错",
    "reading": "chācuò",
    "meaning": "mistake, slip-up, fault, error (in data transmission), accident, mishap",
    "grade": 7
  },
  {
    "id": 5783,
    "traditional": "差額",
    "simplified": "差额",
    "reading": "chāé",
    "meaning": "balance (financial), discrepancy (in a sum or quota), difference",
    "grade": 7
  },
  {
    "id": 5784,
    "traditional": "插手",
    "simplified": "插手",
    "reading": "chā shǒu",
    "meaning": "to get involved in, to meddle, interference",
    "grade": 7
  },
  {
    "id": 5785,
    "traditional": "插圖",
    "simplified": "插图",
    "reading": "chātú",
    "meaning": "illustration",
    "grade": 7
  },
  {
    "id": 5786,
    "traditional": "插嘴",
    "simplified": "插嘴",
    "reading": "chā zuǐ",
    "meaning": "to interrupt (sb talking), to butt in, to cut into a conversation",
    "grade": 7
  },
  {
    "id": 5787,
    "traditional": "茶道",
    "simplified": "茶道",
    "reading": "chádào",
    "meaning": "Japanese tea ceremony, sado",
    "grade": 7
  },
  {
    "id": 5788,
    "traditional": "茶館兒",
    "simplified": "茶馆儿",
    "reading": "cháguǎnr5",
    "meaning": "a teashop",
    "grade": 7
  },
  {
    "id": 5789,
    "traditional": "查處",
    "simplified": "查处",
    "reading": "cháchǔ",
    "meaning": "to investigate and handle (a criminal case)",
    "grade": 7
  },
  {
    "id": 5790,
    "traditional": "查明",
    "simplified": "查明",
    "reading": "chámíng",
    "meaning": "to investigate and find out, to ascertain",
    "grade": 7
  },
  {
    "id": 5791,
    "traditional": "查找",
    "simplified": "查找",
    "reading": "cházhǎo",
    "meaning": "to search for, to look up",
    "grade": 7
  },
  {
    "id": 5792,
    "traditional": "察覺",
    "simplified": "察觉",
    "reading": "chájué",
    "meaning": "realize, be aware",
    "grade": 7
  },
  {
    "id": 5793,
    "traditional": "察看",
    "simplified": "察看",
    "reading": "chákàn",
    "meaning": "to watch, to look carefully at",
    "grade": 7
  },
  {
    "id": 5794,
    "traditional": "詫異",
    "simplified": "诧异",
    "reading": "chàyì",
    "meaning": "flabbergasted, astonished",
    "grade": 7
  },
  {
    "id": 5795,
    "traditional": "摻",
    "simplified": "掺",
    "reading": "càn",
    "meaning": "variant of 攙|搀[chan1], to mix, to grasp",
    "grade": 7
  },
  {
    "id": 5796,
    "traditional": "攙",
    "simplified": "搀",
    "reading": "chān",
    "meaning": "to take by the arm and assist, to mix, to blend, to dilute, to adulterate",
    "grade": 7
  },
  {
    "id": 5797,
    "traditional": "饞",
    "simplified": "馋",
    "reading": "chán",
    "meaning": "gluttonous, greedy, to have a craving",
    "grade": 7
  },
  {
    "id": 5798,
    "traditional": "禪杖",
    "simplified": "禅杖",
    "reading": "chánzhàng",
    "meaning": "the staff of a Buddhist monk",
    "grade": 7
  },
  {
    "id": 5799,
    "traditional": "纏",
    "simplified": "缠",
    "reading": "chán",
    "meaning": "to wind around, to wrap round, to coil, tangle, to involve, to bother, to annoy",
    "grade": 7
  },
  {
    "id": 5800,
    "traditional": "產",
    "simplified": "产",
    "reading": "chǎn",
    "meaning": "give birth to, be delivered of, produce",
    "grade": 7
  },
  {
    "id": 5801,
    "traditional": "產地",
    "simplified": "产地",
    "reading": "chǎndì",
    "meaning": "the source (of a product), place of origin, manufacturing location",
    "grade": 7
  },
  {
    "id": 5802,
    "traditional": "產物",
    "simplified": "产物",
    "reading": "chǎnwù",
    "meaning": "result, development, child, offspring, product, outcome, fruit, progeny, spawn",
    "grade": 7
  },
  {
    "id": 5803,
    "traditional": "產值",
    "simplified": "产值",
    "reading": "chǎnzhí",
    "meaning": "value of output, output value",
    "grade": 7
  },
  {
    "id": 5804,
    "traditional": "铲",
    "simplified": "铲",
    "reading": "chǎn",
    "meaning": "to shovel, to remove, spade, shovel",
    "grade": 7
  },
  {
    "id": 5805,
    "traditional": "鏟子",
    "simplified": "铲子",
    "reading": "chǎnzi",
    "meaning": "shovel, spade, trowel, spatula (kitchen utensil), CL:把[ba3]",
    "grade": 7
  },
  {
    "id": 5806,
    "traditional": "闡述",
    "simplified": "阐述",
    "reading": "chǎnshù",
    "meaning": "to expound (a position), to elaborate (on a topic), to treat (a subject)",
    "grade": 7
  },
  {
    "id": 5807,
    "traditional": "顫抖",
    "simplified": "颤抖",
    "reading": "chàndǒu",
    "meaning": "to shudder, to shiver, to shake, to tremble",
    "grade": 7
  },
  {
    "id": 5808,
    "traditional": "猖狂",
    "simplified": "猖狂",
    "reading": "chāngkuáng",
    "meaning": "savage, furious",
    "grade": 7
  },
  {
    "id": 5809,
    "traditional": "長達",
    "simplified": "长达",
    "reading": "chángdá",
    "meaning": "lengthen out to",
    "grade": 7
  },
  {
    "id": 5810,
    "traditional": "長期以來",
    "simplified": "长期以来",
    "reading": "chángqīyǐlái",
    "meaning": "ever since a long time ago",
    "grade": 7
  },
  {
    "id": 5811,
    "traditional": "長效",
    "simplified": "长效",
    "reading": "chángxiào",
    "meaning": "to be effective over an extended period",
    "grade": 7
  },
  {
    "id": 5812,
    "traditional": "長征",
    "simplified": "长征",
    "reading": "chángzhēng",
    "meaning": "Long March (retreat of the Red Army 1934-1935), expedition, long journey",
    "grade": 7
  },
  {
    "id": 5813,
    "traditional": "長足",
    "simplified": "长足",
    "reading": "chángzú",
    "meaning": "remarkable (progress, improvement, expansion etc)",
    "grade": 7
  },
  {
    "id": 5814,
    "traditional": "常理",
    "simplified": "常理",
    "reading": "chánglǐ",
    "meaning": "common sense, conventional reasoning and morals",
    "grade": 7
  },
  {
    "id": 5815,
    "traditional": "常人",
    "simplified": "常人",
    "reading": "chángrén",
    "meaning": "ordinary person",
    "grade": 7
  },
  {
    "id": 5816,
    "traditional": "常態",
    "simplified": "常态",
    "reading": "chángtài",
    "meaning": "normal, normalcy, normal conditions, normality, order, conditions, normal behavior/conditions, normal behavior",
    "grade": 7
  },
  {
    "id": 5817,
    "traditional": "常溫",
    "simplified": "常温",
    "reading": "chángwēn",
    "meaning": "room temperature, ordinary temperatures",
    "grade": 7
  },
  {
    "id": 5818,
    "traditional": "償還",
    "simplified": "偿还",
    "reading": "chánghuán",
    "meaning": "to repay, to reimburse",
    "grade": 7
  },
  {
    "id": 5819,
    "traditional": "嫦娥",
    "simplified": "嫦娥",
    "reading": "Chángé",
    "meaning": "Chang'e, the lady in the moon (Chinese mythology), one of the Chang'e series of PRC lunar spacecraft",
    "grade": 7
  },
  {
    "id": 5820,
    "traditional": "廠家",
    "simplified": "厂家",
    "reading": "chǎngjiā",
    "meaning": "factory, factory owners",
    "grade": 7
  },
  {
    "id": 5821,
    "traditional": "敞開",
    "simplified": "敞开",
    "reading": "chǎng kāi",
    "meaning": "to open wide, unrestrictedly",
    "grade": 7
  },
  {
    "id": 5822,
    "traditional": "暢談",
    "simplified": "畅谈",
    "reading": "chàngtán",
    "meaning": "to chat, a long talk, verbose, to talk freely to one's heart's content",
    "grade": 7
  },
  {
    "id": 5823,
    "traditional": "暢銷",
    "simplified": "畅销",
    "reading": "chàngxiāo",
    "meaning": "sell well",
    "grade": 7
  },
  {
    "id": 5824,
    "traditional": "倡議",
    "simplified": "倡议",
    "reading": "chàngyì",
    "meaning": "to suggest, to initiate, proposal, initiative",
    "grade": 7
  },
  {
    "id": 5825,
    "traditional": "抄襲",
    "simplified": "抄袭",
    "reading": "chāoxí",
    "meaning": "to plagiarize, to copy, to attack the flank or rear of an enemy",
    "grade": 7
  },
  {
    "id": 5826,
    "traditional": "鈔票",
    "simplified": "钞票",
    "reading": "chāopiào",
    "meaning": "bank note, paper money, bill",
    "grade": 7
  },
  {
    "id": 5827,
    "traditional": "超標",
    "simplified": "超标",
    "reading": "chāo biāo",
    "meaning": "to cross the limit, to be over the accepted norm, excessive",
    "grade": 7
  },
  {
    "id": 5828,
    "traditional": "超車",
    "simplified": "超车",
    "reading": "chāo chē",
    "meaning": "to overtake (another car)",
    "grade": 7
  },
  {
    "id": 5829,
    "traditional": "超前",
    "simplified": "超前",
    "reading": "chāoqián",
    "meaning": "to be ahead of one's time, to surpass or outdo one's predecessors, to be ahead of the pack, to take the lead, advanced",
    "grade": 7
  },
  {
    "id": 5830,
    "traditional": "超速",
    "simplified": "超速",
    "reading": "chāosù",
    "meaning": "to exceed the speed limit, to speed, high-speed",
    "grade": 7
  },
  {
    "id": 5831,
    "traditional": "朝代",
    "simplified": "朝代",
    "reading": "cháodài",
    "meaning": "dynasty, reign (of a king)",
    "grade": 7
  },
  {
    "id": 5832,
    "traditional": "朝著",
    "simplified": "朝着",
    "reading": "cháozhe",
    "meaning": "facing, advancing (towards)",
    "grade": 7
  },
  {
    "id": 5833,
    "traditional": "嘲弄",
    "simplified": "嘲弄",
    "reading": "cháonòng",
    "meaning": "to tease, to poke fun at, to make fun of",
    "grade": 7
  },
  {
    "id": 5834,
    "traditional": "嘲笑",
    "simplified": "嘲笑",
    "reading": "cháoxiào",
    "meaning": "to jeer at, to deride, to ridicule, mockery, derision",
    "grade": 7
  },
  {
    "id": 5835,
    "traditional": "吵嘴",
    "simplified": "吵嘴",
    "reading": "chǎo zuǐ",
    "meaning": "to quarrel",
    "grade": 7
  },
  {
    "id": 5836,
    "traditional": "車道",
    "simplified": "车道",
    "reading": "chēdào",
    "meaning": "traffic lane, driveway",
    "grade": 7
  },
  {
    "id": 5837,
    "traditional": "車禍",
    "simplified": "车祸",
    "reading": "chēhuò",
    "meaning": "smash-up, road accident, traffic accident",
    "grade": 7
  },
  {
    "id": 5838,
    "traditional": "車間",
    "simplified": "车间",
    "reading": "chējiān",
    "meaning": "workshop, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 5839,
    "traditional": "車速",
    "simplified": "车速",
    "reading": "chēsù",
    "meaning": "vehicle speed",
    "grade": 7
  },
  {
    "id": 5840,
    "traditional": "車輪",
    "simplified": "车轮",
    "reading": "chēlún",
    "meaning": "wheel",
    "grade": 7
  },
  {
    "id": 5841,
    "traditional": "車位",
    "simplified": "车位",
    "reading": "chēwèi",
    "meaning": "parking spot, unloading point, garage place, stand for taxi",
    "grade": 7
  },
  {
    "id": 5842,
    "traditional": "車廂",
    "simplified": "车厢",
    "reading": "chēxiāng",
    "meaning": "carriage, CL:節|节[jie2]",
    "grade": 7
  },
  {
    "id": 5843,
    "traditional": "車型",
    "simplified": "车型",
    "reading": "chēxíng",
    "meaning": "Model",
    "grade": 7
  },
  {
    "id": 5844,
    "traditional": "車軸",
    "simplified": "车轴",
    "reading": "chēzhóu",
    "meaning": "axle, CL:根[gen1]",
    "grade": 7
  },
  {
    "id": 5845,
    "traditional": "扯",
    "simplified": "扯",
    "reading": "chě",
    "meaning": "variant of 扯[che3], to pull, to tear",
    "grade": 7
  },
  {
    "id": 5846,
    "traditional": "徹夜",
    "simplified": "彻夜",
    "reading": "chèyè",
    "meaning": "the whole night",
    "grade": 7
  },
  {
    "id": 5847,
    "traditional": "撤",
    "simplified": "撤",
    "reading": "chè",
    "meaning": "to remove, to take away, to withdraw",
    "grade": 7
  },
  {
    "id": 5848,
    "traditional": "撤換",
    "simplified": "撤换",
    "reading": "chèhuàn",
    "meaning": "recall, dismiss and replace",
    "grade": 7
  },
  {
    "id": 5849,
    "traditional": "沉甸甸",
    "simplified": "沉甸甸",
    "reading": "chéndiàndiàn",
    "meaning": "heavy",
    "grade": 7
  },
  {
    "id": 5850,
    "traditional": "沉澱",
    "simplified": "沉淀",
    "reading": "chéndiàn",
    "meaning": "to settle, to precipitate (solid sediment out of a solution)",
    "grade": 7
  },
  {
    "id": 5851,
    "traditional": "沉浸",
    "simplified": "沉浸",
    "reading": "chénjìn",
    "meaning": "to soak, to permeate, to immerse",
    "grade": 7
  },
  {
    "id": 5852,
    "traditional": "沉悶",
    "simplified": "沉闷",
    "reading": "chénmèn",
    "meaning": "oppressive (of weather), heavy, depressed, not happy, (of sound) dull, muffled",
    "grade": 7
  },
  {
    "id": 5853,
    "traditional": "沉迷",
    "simplified": "沉迷",
    "reading": "chénmí",
    "meaning": "to be engrossed, to be absorbed with, to lose oneself in, to be addicted to",
    "grade": 7
  },
  {
    "id": 5854,
    "traditional": "沉思",
    "simplified": "沉思",
    "reading": "chénsī",
    "meaning": "to contemplate, to ponder, contemplation, meditation",
    "grade": 7
  },
  {
    "id": 5855,
    "traditional": "沉穩",
    "simplified": "沉稳",
    "reading": "chénwěn",
    "meaning": "steady, calm, unflustered",
    "grade": 7
  },
  {
    "id": 5856,
    "traditional": "沉著",
    "simplified": "沉着",
    "reading": "chénzhuó",
    "meaning": "steady, calm and collected, not nervous",
    "grade": 7
  },
  {
    "id": 5857,
    "traditional": "陳舊",
    "simplified": "陈旧",
    "reading": "chénjiù",
    "meaning": "old-fashioned",
    "grade": 7
  },
  {
    "id": 5858,
    "traditional": "陳列",
    "simplified": "陈列",
    "reading": "chénliè",
    "meaning": "display, exhibit",
    "grade": 7
  },
  {
    "id": 5859,
    "traditional": "陳述",
    "simplified": "陈述",
    "reading": "chénshù",
    "meaning": "an assertion, to declare, to state",
    "grade": 7
  },
  {
    "id": 5860,
    "traditional": "襯托",
    "simplified": "衬托",
    "reading": "chèntuō",
    "meaning": "to set off",
    "grade": 7
  },
  {
    "id": 5861,
    "traditional": "趁",
    "simplified": "趁",
    "reading": "chèn",
    "meaning": "while",
    "grade": 7
  },
  {
    "id": 5862,
    "traditional": "趁機",
    "simplified": "趁机",
    "reading": "chènjī",
    "meaning": "seizing the chance, take advantage of occasion, taking advantage of the occasion, seize chance",
    "grade": 7
  },
  {
    "id": 5863,
    "traditional": "趁早",
    "simplified": "趁早",
    "reading": "chènzǎo",
    "meaning": "as soon as possible, at the first opportunity, the sooner the better, before it's too late",
    "grade": 7
  },
  {
    "id": 5864,
    "traditional": "趁著",
    "simplified": "趁着",
    "reading": "chènzhe",
    "meaning": "while",
    "grade": 7
  },
  {
    "id": 5865,
    "traditional": "稱呼",
    "simplified": "称呼",
    "reading": "chēnghu",
    "meaning": "call, address",
    "grade": 7
  },
  {
    "id": 5866,
    "traditional": "稱作",
    "simplified": "称作",
    "reading": "chēngzuò",
    "meaning": "to be called, to be known as",
    "grade": 7
  },
  {
    "id": 5867,
    "traditional": "成才",
    "simplified": "成才",
    "reading": "chéng cái",
    "meaning": "to make sth of oneself, to become a person who is worthy of respect",
    "grade": 7
  },
  {
    "id": 5868,
    "traditional": "成家",
    "simplified": "成家",
    "reading": "chéng jiā",
    "meaning": "to settle down and get married (of a man), to become a recognized expert",
    "grade": 7
  },
  {
    "id": 5869,
    "traditional": "成年",
    "simplified": "成年",
    "reading": "chéng nián",
    "meaning": "to grow to adulthood, fully grown, the whole year",
    "grade": 7
  },
  {
    "id": 5870,
    "traditional": "成千上萬",
    "simplified": "成千上万",
    "reading": "chéngqiān-shàngwàn",
    "meaning": "lit. by the thousands and tens of thousands (idiom), untold numbers, innumerable, thousands upon thousands",
    "grade": 7
  },
  {
    "id": 5871,
    "traditional": "成群結隊",
    "simplified": "成群结队",
    "reading": "chéngqún-jiéduì",
    "meaning": "making up a group, forming a troupe (idiom), in large numbers, as a large crowd",
    "grade": 7
  },
  {
    "id": 5872,
    "traditional": "成天",
    "simplified": "成天",
    "reading": "chéngtiān",
    "meaning": "(coll.) all day long, all the time",
    "grade": 7
  },
  {
    "id": 5873,
    "traditional": "成問題",
    "simplified": "成问题",
    "reading": "chéng wèntí",
    "meaning": "Problem",
    "grade": 7
  },
  {
    "id": 5874,
    "traditional": "成型",
    "simplified": "成型",
    "reading": "chéngxíng",
    "meaning": "to become shaped, to become formed",
    "grade": 7
  },
  {
    "id": 5875,
    "traditional": "呈現",
    "simplified": "呈现",
    "reading": "chéngxiàn",
    "meaning": "assume, crop out, dish, appearance, show, lay out, present, basset, appear, take_on, emergence, emerge, come forth",
    "grade": 7
  },
  {
    "id": 5876,
    "traditional": "誠懇",
    "simplified": "诚恳",
    "reading": "chéngkěn",
    "meaning": "sincere",
    "grade": 7
  },
  {
    "id": 5877,
    "traditional": "誠心誠意",
    "simplified": "诚心诚意",
    "reading": "chéngxīn-chéngyì",
    "meaning": "earnestly and sincerely (idiom), with all sincerity",
    "grade": 7
  },
  {
    "id": 5878,
    "traditional": "誠意",
    "simplified": "诚意",
    "reading": "chéngyì",
    "meaning": "candour, singleness, sincerity, good faith, candor",
    "grade": 7
  },
  {
    "id": 5879,
    "traditional": "誠摯",
    "simplified": "诚挚",
    "reading": "chéngzhì",
    "meaning": "sincere, cordial",
    "grade": 7
  },
  {
    "id": 5880,
    "traditional": "承包",
    "simplified": "承包",
    "reading": "chéngbāo",
    "meaning": "to contract, to undertake (a job)",
    "grade": 7
  },
  {
    "id": 5881,
    "traditional": "承載",
    "simplified": "承载",
    "reading": "chéngzài",
    "meaning": "to bear the weight, to sustain",
    "grade": 7
  },
  {
    "id": 5882,
    "traditional": "城牆",
    "simplified": "城墙",
    "reading": "chéngqiáng",
    "meaning": "enceinte, city wall, rampart",
    "grade": 7
  },
  {
    "id": 5883,
    "traditional": "乘人之危",
    "simplified": "乘人之危",
    "reading": "chéngrénzhīwēi",
    "meaning": "to take advantage of sb's precarious position",
    "grade": 7
  },
  {
    "id": 5884,
    "traditional": "盛",
    "simplified": "盛",
    "reading": "shèng",
    "meaning": "flourishing, prosperous, vigorous, energetic, magnificent, grand, abundant, plentiful, popular, widespread",
    "grade": 7
  },
  {
    "id": 5885,
    "traditional": "懲處",
    "simplified": "惩处",
    "reading": "chéngchǔ",
    "meaning": "to punish, to administer justice",
    "grade": 7
  },
  {
    "id": 5886,
    "traditional": "懲罰",
    "simplified": "惩罚",
    "reading": "chéngfá",
    "meaning": "penalty, punishment, to punish",
    "grade": 7
  },
  {
    "id": 5887,
    "traditional": "澄清",
    "simplified": "澄清",
    "reading": "chéngqīng",
    "meaning": "clear up, clarify",
    "grade": 7
  },
  {
    "id": 5888,
    "traditional": "橙汁",
    "simplified": "橙汁",
    "reading": "chéngzhī",
    "meaning": "orange juice, CL:瓶[ping2],杯[bei1],罐[guan4],盒[he2]",
    "grade": 7
  },
  {
    "id": 5889,
    "traditional": "逞能",
    "simplified": "逞能",
    "reading": "chěng néng",
    "meaning": "to show off one's ability, to boast one's merits",
    "grade": 7
  },
  {
    "id": 5890,
    "traditional": "逞強",
    "simplified": "逞强",
    "reading": "chěng qiáng",
    "meaning": "to show off, to try to be brave",
    "grade": 7
  },
  {
    "id": 5891,
    "traditional": "秤",
    "simplified": "秤",
    "reading": "chèng",
    "meaning": "variant of 稱|称[cheng1], to weigh, steelyard, Roman balance, CL:臺|台[tai2]",
    "grade": 7
  },
  {
    "id": 5892,
    "traditional": "吃不上",
    "simplified": "吃不上",
    "reading": "chībushàng",
    "meaning": "unable to get anything to eat, to miss a meal",
    "grade": 7
  },
  {
    "id": 5893,
    "traditional": "吃喝玩樂",
    "simplified": "吃喝玩乐",
    "reading": "chīhē-wánlè",
    "meaning": "to eat, drink and be merry (idiom), to abandon oneself to a life of pleasure",
    "grade": 7
  },
  {
    "id": 5894,
    "traditional": "吃苦",
    "simplified": "吃苦",
    "reading": "chī kǔ",
    "meaning": "to bear hardships",
    "grade": 7
  },
  {
    "id": 5895,
    "traditional": "吃虧",
    "simplified": "吃亏",
    "reading": "chī kuī",
    "meaning": "get the worst of it, come to grief, suffer loss, suffer losses",
    "grade": 7
  },
  {
    "id": 5896,
    "traditional": "痴呆",
    "simplified": "痴呆",
    "reading": "chīdāi",
    "meaning": "imbecility, dementia",
    "grade": 7
  },
  {
    "id": 5897,
    "traditional": "痴迷",
    "simplified": "痴迷",
    "reading": "chīmí",
    "meaning": "infatuated, obsessed",
    "grade": 7
  },
  {
    "id": 5898,
    "traditional": "痴心",
    "simplified": "痴心",
    "reading": "chīxīn",
    "meaning": "infatuation",
    "grade": 7
  },
  {
    "id": 5899,
    "traditional": "池塘",
    "simplified": "池塘",
    "reading": "chítáng",
    "meaning": "pool, pond",
    "grade": 7
  },
  {
    "id": 5900,
    "traditional": "馳名",
    "simplified": "驰名",
    "reading": "chímíng",
    "meaning": "famous",
    "grade": 7
  },
  {
    "id": 5901,
    "traditional": "遲遲",
    "simplified": "迟迟",
    "reading": "chíchí",
    "meaning": "late (with a task etc), slow",
    "grade": 7
  },
  {
    "id": 5902,
    "traditional": "遲疑",
    "simplified": "迟疑",
    "reading": "chíyí",
    "meaning": "to hesitate",
    "grade": 7
  },
  {
    "id": 5903,
    "traditional": "遲早",
    "simplified": "迟早",
    "reading": "chízǎo",
    "meaning": "sooner or later",
    "grade": 7
  },
  {
    "id": 5904,
    "traditional": "持",
    "simplified": "持",
    "reading": "chí",
    "meaning": "hold, grasp",
    "grade": 7
  },
  {
    "id": 5905,
    "traditional": "持久",
    "simplified": "持久",
    "reading": "chíjiǔ",
    "meaning": "lasting, enduring, persistent, permanent, protracted, endurance, persistence, to last long",
    "grade": 7
  },
  {
    "id": 5906,
    "traditional": "持之以恆",
    "simplified": "持之以恒",
    "reading": "chízhīyǐhéng",
    "meaning": "to pursue unremittingly (idiom), to persevere",
    "grade": 7
  },
  {
    "id": 5907,
    "traditional": "尺度",
    "simplified": "尺度",
    "reading": "chǐdù",
    "meaning": "measure, scale",
    "grade": 7
  },
  {
    "id": 5908,
    "traditional": "恥辱",
    "simplified": "耻辱",
    "reading": "chǐrǔ",
    "meaning": "disgrace, shame, humiliation",
    "grade": 7
  },
  {
    "id": 5909,
    "traditional": "恥笑",
    "simplified": "耻笑",
    "reading": "chǐxiào",
    "meaning": "to sneer at sb, to ridicule",
    "grade": 7
  },
  {
    "id": 5910,
    "traditional": "赤字",
    "simplified": "赤字",
    "reading": "chìzì",
    "meaning": "(financial) deficit, red letter",
    "grade": 7
  },
  {
    "id": 5911,
    "traditional": "翅膀",
    "simplified": "翅膀",
    "reading": "chìbǎng",
    "meaning": "pinion, vane, plumage, pennon, flier, wing",
    "grade": 7
  },
  {
    "id": 5912,
    "traditional": "衝刺",
    "simplified": "冲刺",
    "reading": "chōngcì",
    "meaning": "to sprint, to spurt, to dash, big effort",
    "grade": 7
  },
  {
    "id": 5913,
    "traditional": "衝浪",
    "simplified": "冲浪",
    "reading": "chōnglàng",
    "meaning": "to surf, surfing",
    "grade": 7
  },
  {
    "id": 5914,
    "traditional": "沖洗",
    "simplified": "冲洗",
    "reading": "chōngxǐ",
    "meaning": "to rinse, to wash, to develop (photographic film)",
    "grade": 7
  },
  {
    "id": 5915,
    "traditional": "衝撞",
    "simplified": "冲撞",
    "reading": "chōngzhuàng",
    "meaning": "to collide, jerking motion, to impinge, to offend, to provoke",
    "grade": 7
  },
  {
    "id": 5916,
    "traditional": "充",
    "simplified": "充",
    "reading": "chōng",
    "meaning": "to fill, to satisfy, to fulfill, to act in place of, substitute, sufficient, full",
    "grade": 7
  },
  {
    "id": 5917,
    "traditional": "充當",
    "simplified": "充当",
    "reading": "chōngdāng",
    "meaning": "to serve as, to act as, to play the role of",
    "grade": 7
  },
  {
    "id": 5918,
    "traditional": "充沛",
    "simplified": "充沛",
    "reading": "chōngpèi",
    "meaning": "abundant, plentiful, vigorous",
    "grade": 7
  },
  {
    "id": 5919,
    "traditional": "充實",
    "simplified": "充实",
    "reading": "chōngshí",
    "meaning": "substantiate, enrich, replenish",
    "grade": 7
  },
  {
    "id": 5920,
    "traditional": "重播",
    "simplified": "重播",
    "reading": "chóngbō",
    "meaning": "Replay",
    "grade": 7
  },
  {
    "id": 5921,
    "traditional": "重疊",
    "simplified": "重叠",
    "reading": "chóngdié",
    "meaning": "telescope, duplicate, superpose, overlap, repeated, lap",
    "grade": 7
  },
  {
    "id": 5922,
    "traditional": "重返",
    "simplified": "重返",
    "reading": "chóngfǎn",
    "meaning": "to return to",
    "grade": 7
  },
  {
    "id": 5923,
    "traditional": "重申",
    "simplified": "重申",
    "reading": "chóngshēn",
    "meaning": "to reaffirm, to reiterate",
    "grade": 7
  },
  {
    "id": 5924,
    "traditional": "重現",
    "simplified": "重现",
    "reading": "chóngxiàn",
    "meaning": "to reappear",
    "grade": 7
  },
  {
    "id": 5925,
    "traditional": "重合",
    "simplified": "重合",
    "reading": "chónghé",
    "meaning": "to match up, to coincide",
    "grade": 7
  },
  {
    "id": 5926,
    "traditional": "崇高",
    "simplified": "崇高",
    "reading": "chónggāo",
    "meaning": "lofty, sublime",
    "grade": 7
  },
  {
    "id": 5927,
    "traditional": "崇尚",
    "simplified": "崇尚",
    "reading": "chóngshàng",
    "meaning": "to hold up (as an model), to hold in esteem, to revere, to advocate",
    "grade": 7
  },
  {
    "id": 5928,
    "traditional": "寵",
    "simplified": "宠",
    "reading": "chǒng",
    "meaning": "to love, to pamper, to spoil, to favor",
    "grade": 7
  },
  {
    "id": 5929,
    "traditional": "寵愛",
    "simplified": "宠爱",
    "reading": "chǒngài",
    "meaning": "to dote on sb",
    "grade": 7
  },
  {
    "id": 5930,
    "traditional": "抽簽",
    "simplified": "抽签",
    "reading": "chōu qiān",
    "meaning": "to perform divination with sticks, to draw lots, a ballot (in share dealing)",
    "grade": 7
  },
  {
    "id": 5931,
    "traditional": "抽屜",
    "simplified": "抽屉",
    "reading": "chōuti",
    "meaning": "drawer",
    "grade": 7
  },
  {
    "id": 5932,
    "traditional": "抽象",
    "simplified": "抽象",
    "reading": "chōuxiàng",
    "meaning": "abstract",
    "grade": 7
  },
  {
    "id": 5933,
    "traditional": "仇",
    "simplified": "仇",
    "reading": "chóu",
    "meaning": "surname Qiu, hatred, animosity, enmity, foe, enemy, to feel animosity toward (the wealthy, foreigners etc), spouse, companion",
    "grade": 7
  },
  {
    "id": 5934,
    "traditional": "仇恨",
    "simplified": "仇恨",
    "reading": "chóuhèn",
    "meaning": "to hate, hatred, enmity, hostility",
    "grade": 7
  },
  {
    "id": 5935,
    "traditional": "仇人",
    "simplified": "仇人",
    "reading": "chóurén",
    "meaning": "foe, one's personal enemy",
    "grade": 7
  },
  {
    "id": 5936,
    "traditional": "稠",
    "simplified": "稠",
    "reading": "chóu",
    "meaning": "dense, crowded, thick, many",
    "grade": 7
  },
  {
    "id": 5937,
    "traditional": "稠密",
    "simplified": "稠密",
    "reading": "chóumì",
    "meaning": "dense",
    "grade": 7
  },
  {
    "id": 5938,
    "traditional": "愁眉苦臉",
    "simplified": "愁眉苦脸",
    "reading": "chóuméi-kǔliǎn",
    "meaning": "to look anxious (idiom), to look miserable",
    "grade": 7
  },
  {
    "id": 5939,
    "traditional": "籌",
    "simplified": "筹",
    "reading": "chóu",
    "meaning": "chip (in gambling), token (for counting), ticket, to prepare, to plan, to raise (funds), resource, means",
    "grade": 7
  },
  {
    "id": 5940,
    "traditional": "籌辦",
    "simplified": "筹办",
    "reading": "chóubàn",
    "meaning": "to arrange, to make preparations",
    "grade": 7
  },
  {
    "id": 5941,
    "traditional": "籌措",
    "simplified": "筹措",
    "reading": "chóucuò",
    "meaning": "to raise (money)",
    "grade": 7
  },
  {
    "id": 5942,
    "traditional": "籌劃",
    "simplified": "筹划",
    "reading": "chóuhuà",
    "meaning": "to plan and prepare",
    "grade": 7
  },
  {
    "id": 5943,
    "traditional": "籌碼",
    "simplified": "筹码",
    "reading": "chóumǎ",
    "meaning": "counter, jetton, dib, poker chip, chip, bargaining chip, medium_of_exchange",
    "grade": 7
  },
  {
    "id": 5944,
    "traditional": "籌備",
    "simplified": "筹备",
    "reading": "chóubèi",
    "meaning": "prepare, arrange, plan",
    "grade": 7
  },
  {
    "id": 5945,
    "traditional": "籌集",
    "simplified": "筹集",
    "reading": "chóují",
    "meaning": "to collect money, to raise funds",
    "grade": 7
  },
  {
    "id": 5946,
    "traditional": "醜惡",
    "simplified": "丑恶",
    "reading": "chǒuè",
    "meaning": "ugly, repulsive",
    "grade": 7
  },
  {
    "id": 5947,
    "traditional": "醜陋",
    "simplified": "丑陋",
    "reading": "chǒulòu",
    "meaning": "ugly",
    "grade": 7
  },
  {
    "id": 5948,
    "traditional": "醜聞",
    "simplified": "丑闻",
    "reading": "chǒuwén",
    "meaning": "scandal",
    "grade": 7
  },
  {
    "id": 5949,
    "traditional": "瞅",
    "simplified": "瞅",
    "reading": "chǒu",
    "meaning": "(dialect) to look at, old variant of 瞅[chou3]",
    "grade": 7
  },
  {
    "id": 5950,
    "traditional": "出版社",
    "simplified": "出版社",
    "reading": "chūbǎnshè",
    "meaning": "publisher, press",
    "grade": 7
  },
  {
    "id": 5951,
    "traditional": "出廠",
    "simplified": "出厂",
    "reading": "chū chǎng",
    "meaning": "to leave the factory (of finished goods)",
    "grade": 7
  },
  {
    "id": 5952,
    "traditional": "出醜",
    "simplified": "出丑",
    "reading": "chū chǒu",
    "meaning": "shameful, scandalous, to be humiliated, to make a fool of sb or oneself, to make sb lose face",
    "grade": 7
  },
  {
    "id": 5953,
    "traditional": "出道",
    "simplified": "出道",
    "reading": "chū dào",
    "meaning": "to make one's first public performance (of an entertainer etc), to start one's career",
    "grade": 7
  },
  {
    "id": 5954,
    "traditional": "出發點",
    "simplified": "出发点",
    "reading": "chūfādiǎn",
    "meaning": "terminus a quo, base, takeoff, starting_point, start, starting_post, beachhead, springboard, starting, starting point, starting post",
    "grade": 7
  },
  {
    "id": 5955,
    "traditional": "出風頭",
    "simplified": "出风头",
    "reading": "chū fēngtou",
    "meaning": "to push oneself forward, to seek fame, to be in the limelight, same as 出鋒頭|出锋头[chu1 feng1 tou5]",
    "grade": 7
  },
  {
    "id": 5956,
    "traditional": "出境",
    "simplified": "出境",
    "reading": "chū jìng",
    "meaning": "to leave a country or region, emigration, outbound (tourism)",
    "grade": 7
  },
  {
    "id": 5957,
    "traditional": "出局",
    "simplified": "出局",
    "reading": "chū jú",
    "meaning": "out, out of game",
    "grade": 7
  },
  {
    "id": 5958,
    "traditional": "出具",
    "simplified": "出具",
    "reading": "chūjù",
    "meaning": "to issue (document, certificate etc), to provide",
    "grade": 7
  },
  {
    "id": 5959,
    "traditional": "出口成章",
    "simplified": "出口成章",
    "reading": "chūkǒu-chéngzhāng",
    "meaning": "to speak like a printed book, quick and clever talking, the gift of the gab",
    "grade": 7
  },
  {
    "id": 5960,
    "traditional": "出賣",
    "simplified": "出卖",
    "reading": "chūmài",
    "meaning": "sell_out, traffic, sell out, barter_away, peach, sell sb. down the river, lead astray, betray, sell, betrayal, go_back_on, offer for sale, double-cross, deceive",
    "grade": 7
  },
  {
    "id": 5961,
    "traditional": "出毛病",
    "simplified": "出毛病",
    "reading": "chū máobing",
    "meaning": "a problem appears, to break down",
    "grade": 7
  },
  {
    "id": 5962,
    "traditional": "出難題",
    "simplified": "出难题",
    "reading": "chū nántí",
    "meaning": "to raise a tough question",
    "grade": 7
  },
  {
    "id": 5963,
    "traditional": "出人意料",
    "simplified": "出人意料",
    "reading": "chūrényìliào",
    "meaning": "unexpected (idiom), surprising",
    "grade": 7
  },
  {
    "id": 5964,
    "traditional": "出任",
    "simplified": "出任",
    "reading": "chūrèn",
    "meaning": "take up the post of",
    "grade": 7
  },
  {
    "id": 5965,
    "traditional": "出山",
    "simplified": "出山",
    "reading": "chū shān",
    "meaning": "to leave the mountain (of a hermit), to come out of obscurity to a government job, to take a leading position",
    "grade": 7
  },
  {
    "id": 5966,
    "traditional": "出身",
    "simplified": "出身",
    "reading": "chūshēn",
    "meaning": "class origin, family background;one's previous experience/occupation",
    "grade": 7
  },
  {
    "id": 5967,
    "traditional": "出示",
    "simplified": "出示",
    "reading": "chūshì",
    "meaning": "to show, to take out and show to others, to display",
    "grade": 7
  },
  {
    "id": 5968,
    "traditional": "出手",
    "simplified": "出手",
    "reading": "chū shǒu",
    "meaning": "to dispose of, to spend (money), to undertake a task",
    "grade": 7
  },
  {
    "id": 5969,
    "traditional": "出頭",
    "simplified": "出头",
    "reading": "chū tóu",
    "meaning": "to get out of a predicament, to stick out, to take the initiative, remaining odd fraction after a division, a little more than",
    "grade": 7
  },
  {
    "id": 5970,
    "traditional": "出土",
    "simplified": "出土",
    "reading": "chū tǔ",
    "meaning": "be unearthed, come out of the ground",
    "grade": 7
  },
  {
    "id": 5971,
    "traditional": "出息",
    "simplified": "出息",
    "reading": "chūxi",
    "meaning": "to yield interest, profit etc, to exhale (Buddhism), future prospects, profit, to mature, to grow up",
    "grade": 7
  },
  {
    "id": 5972,
    "traditional": "出血",
    "simplified": "出血",
    "reading": "chūxiě",
    "meaning": "shed blood, have a hemorrhage, bleeding, bleed, spend money, phlebotomize, hemorrhage, hemorrhagic, leech",
    "grade": 7
  },
  {
    "id": 5973,
    "traditional": "出演",
    "simplified": "出演",
    "reading": "chūyǎn",
    "meaning": "to appear (in a show etc), an appearance (on stage etc)",
    "grade": 7
  },
  {
    "id": 5974,
    "traditional": "出洋相",
    "simplified": "出洋相",
    "reading": "chū yángxiàng",
    "meaning": "to make a fool of oneself",
    "grade": 7
  },
  {
    "id": 5975,
    "traditional": "出遊",
    "simplified": "出游",
    "reading": "chūyóu",
    "meaning": "to go on a tour, to have an outing",
    "grade": 7
  },
  {
    "id": 5976,
    "traditional": "出眾",
    "simplified": "出众",
    "reading": "chūzhòng",
    "meaning": "to stand out, outstanding",
    "grade": 7
  },
  {
    "id": 5977,
    "traditional": "出主意",
    "simplified": "出主意",
    "reading": "chū zhǔyì",
    "meaning": "Idea",
    "grade": 7
  },
  {
    "id": 5978,
    "traditional": "出資",
    "simplified": "出资",
    "reading": "chū zī",
    "meaning": "to fund, to put money into sth, to invest",
    "grade": 7
  },
  {
    "id": 5979,
    "traditional": "出自",
    "simplified": "出自",
    "reading": "chūzì",
    "meaning": "come from, be out of",
    "grade": 7
  },
  {
    "id": 5980,
    "traditional": "初次",
    "simplified": "初次",
    "reading": "chūcì",
    "meaning": "for the first time, first (meeting, attempt etc)",
    "grade": 7
  },
  {
    "id": 5981,
    "traditional": "出走",
    "simplified": "出走",
    "reading": "chūzǒu",
    "meaning": "leave, run away, flee",
    "grade": 7
  },
  {
    "id": 5982,
    "traditional": "初衷",
    "simplified": "初衷",
    "reading": "chūzhōng",
    "meaning": "original intention or aspiration",
    "grade": 7
  },
  {
    "id": 5983,
    "traditional": "除此之外",
    "simplified": "除此之外",
    "reading": "chúcǐzhīwài",
    "meaning": "additionally, in addition, with the exception of this, in_addition, to boot, excepting this",
    "grade": 7
  },
  {
    "id": 5984,
    "traditional": "除去",
    "simplified": "除去",
    "reading": "chúqù",
    "meaning": "eliminate",
    "grade": 7
  },
  {
    "id": 5985,
    "traditional": "除外",
    "simplified": "除外",
    "reading": "chúwài",
    "meaning": "to exclude, not including sth (when counting or listing), except for",
    "grade": 7
  },
  {
    "id": 5986,
    "traditional": "處方",
    "simplified": "处方",
    "reading": "chǔfāng",
    "meaning": "medical prescription, recipe, formula",
    "grade": 7
  },
  {
    "id": 5987,
    "traditional": "處境",
    "simplified": "处境",
    "reading": "chǔjìng",
    "meaning": "situation, site, plight, situation (usu. unfavorable), predicament, circumstances",
    "grade": 7
  },
  {
    "id": 5988,
    "traditional": "處置",
    "simplified": "处置",
    "reading": "chǔzhì",
    "meaning": "deal with, manage, dispose of, punish",
    "grade": 7
  },
  {
    "id": 5989,
    "traditional": "儲備",
    "simplified": "储备",
    "reading": "chǔbèi",
    "meaning": "reserves, to store up",
    "grade": 7
  },
  {
    "id": 5990,
    "traditional": "儲蓄",
    "simplified": "储蓄",
    "reading": "chǔxù",
    "meaning": "to deposit money, to save, savings",
    "grade": 7
  },
  {
    "id": 5991,
    "traditional": "觸動",
    "simplified": "触动",
    "reading": "chùdòng",
    "meaning": "to touch, to stir up (trouble or emotions), to move (sb's emotions or worry)",
    "grade": 7
  },
  {
    "id": 5992,
    "traditional": "觸犯",
    "simplified": "触犯",
    "reading": "chùfàn",
    "meaning": "to offend",
    "grade": 7
  },
  {
    "id": 5993,
    "traditional": "觸覺",
    "simplified": "触觉",
    "reading": "chùjué",
    "meaning": "touch, sense of touch",
    "grade": 7
  },
  {
    "id": 5994,
    "traditional": "觸摸",
    "simplified": "触摸",
    "reading": "chùmō",
    "meaning": "to touch",
    "grade": 7
  },
  {
    "id": 5995,
    "traditional": "觸目驚心",
    "simplified": "触目惊心",
    "reading": "chùmù-jīngxīn",
    "meaning": "lit. shocks the eye, astonishes the heart (idiom), shocking, horrible to see, a ghastly sight",
    "grade": 7
  },
  {
    "id": 5996,
    "traditional": "揣",
    "simplified": "揣",
    "reading": "chuāi",
    "meaning": "to put into (one's pockets, clothes), Taiwan pr. [chuai3], to estimate, to guess, to figure, to surmise",
    "grade": 7
  },
  {
    "id": 5997,
    "traditional": "揣測",
    "simplified": "揣测",
    "reading": "chuǎicè",
    "meaning": "to guess, to conjecture",
    "grade": 7
  },
  {
    "id": 5998,
    "traditional": "揣摩",
    "simplified": "揣摩",
    "reading": "chuǎimó",
    "meaning": "to analyze, to try to figure out, to try to fathom",
    "grade": 7
  },
  {
    "id": 5999,
    "traditional": "踹",
    "simplified": "踹",
    "reading": "chuài",
    "meaning": "to kick, to trample, to tread on",
    "grade": 7
  },
  {
    "id": 6000,
    "traditional": "川流不息",
    "simplified": "川流不息",
    "reading": "chuānliú-bùxī",
    "meaning": "the stream flows without stopping (idiom), unending flow",
    "grade": 7
  },
  {
    "id": 6001,
    "traditional": "穿過",
    "simplified": "穿过",
    "reading": "chuān guò",
    "meaning": "thread, pass through, traverse, penetrate, hook, go through, riddle, pierce, twist, go across, cleave, pass, lace, put_through, lace up, cut_through, cut across, cross, break, shoot",
    "grade": 7
  },
  {
    "id": 6002,
    "traditional": "穿小鞋",
    "simplified": "穿小鞋",
    "reading": "chuān xiǎoxié",
    "meaning": "lit. to make sb wear tight shoes (idiom), to make life difficult for sb",
    "grade": 7
  },
  {
    "id": 6003,
    "traditional": "穿越",
    "simplified": "穿越",
    "reading": "chuānyuè",
    "meaning": "cut, pass through, cut across, cross",
    "grade": 7
  },
  {
    "id": 6004,
    "traditional": "穿著",
    "simplified": "穿着",
    "reading": "chuānzhuó",
    "meaning": "dress, apparel",
    "grade": 7
  },
  {
    "id": 6005,
    "traditional": "傳承",
    "simplified": "传承",
    "reading": "chuánchéng",
    "meaning": "pass down through generations",
    "grade": 7
  },
  {
    "id": 6006,
    "traditional": "傳奇",
    "simplified": "传奇",
    "reading": "chuánqí",
    "meaning": "legendry, romance, Tang and Song short stories, Ming and Qing poetic dramas, fable, short_story, legend",
    "grade": 7
  },
  {
    "id": 6007,
    "traditional": "傳染",
    "simplified": "传染",
    "reading": "chuánrǎn",
    "meaning": "infect, be contagious",
    "grade": 7
  },
  {
    "id": 6008,
    "traditional": "傳染病",
    "simplified": "传染病",
    "reading": "chuánrǎnbìng",
    "meaning": "infectious disease, contagious disease, pestilence",
    "grade": 7
  },
  {
    "id": 6009,
    "traditional": "傳人",
    "simplified": "传人",
    "reading": "chuánrén",
    "meaning": "to teach, to impart, a disciple, descendant",
    "grade": 7
  },
  {
    "id": 6010,
    "traditional": "傳授",
    "simplified": "传授",
    "reading": "chuánshòu",
    "meaning": "to impart, to pass on, to teach",
    "grade": 7
  },
  {
    "id": 6011,
    "traditional": "傳聞",
    "simplified": "传闻",
    "reading": "chuánwén",
    "meaning": "rumor",
    "grade": 7
  },
  {
    "id": 6012,
    "traditional": "船舶",
    "simplified": "船舶",
    "reading": "chuánbó",
    "meaning": "shipping, boats",
    "grade": 7
  },
  {
    "id": 6013,
    "traditional": "船槳",
    "simplified": "船桨",
    "reading": "chuánjiǎng",
    "meaning": "Marker",
    "grade": 7
  },
  {
    "id": 6014,
    "traditional": "喘",
    "simplified": "喘",
    "reading": "chuǎn",
    "meaning": "to gasp, to pant, asthma",
    "grade": 7
  },
  {
    "id": 6015,
    "traditional": "喘息",
    "simplified": "喘息",
    "reading": "chuǎnxī",
    "meaning": "to gasp for breath, to take a breather",
    "grade": 7
  },
  {
    "id": 6016,
    "traditional": "串門",
    "simplified": "串门",
    "reading": "chuàn mén",
    "meaning": "to call on sb, to drop in, to visit sb's home",
    "grade": 7
  },
  {
    "id": 6017,
    "traditional": "創傷",
    "simplified": "创伤",
    "reading": "chuāngshāng",
    "meaning": "wound, injury, trauma",
    "grade": 7
  },
  {
    "id": 6018,
    "traditional": "床位",
    "simplified": "床位",
    "reading": "chuángwèi",
    "meaning": "bed (in hospital, hotel, train etc), berth, bunk",
    "grade": 7
  },
  {
    "id": 6019,
    "traditional": "創",
    "simplified": "创",
    "reading": "chuàng",
    "meaning": "initiate, create, start doing, achieve ... for the first time, initiate (sth.)",
    "grade": 7
  },
  {
    "id": 6020,
    "traditional": "創始人",
    "simplified": "创始人",
    "reading": "chuàngshǐrén",
    "meaning": "creator, founder, initiator",
    "grade": 7
  },
  {
    "id": 6021,
    "traditional": "吹了",
    "simplified": "吹了",
    "reading": "chuīle",
    "meaning": "failed, busted, to have not succeeded, to have died, to have parted company, to have chilled (of a relationship)",
    "grade": 7
  },
  {
    "id": 6022,
    "traditional": "吹牛",
    "simplified": "吹牛",
    "reading": "chuī niú",
    "meaning": "to talk big, to shoot off one's mouth, to chat (dialect)",
    "grade": 7
  },
  {
    "id": 6023,
    "traditional": "吹捧",
    "simplified": "吹捧",
    "reading": "chuīpěng",
    "meaning": "to flatter, to laud sb's accomplishments, adulation",
    "grade": 7
  },
  {
    "id": 6024,
    "traditional": "垂",
    "simplified": "垂",
    "reading": "chuí",
    "meaning": "to hang (down), droop, dangle, bend down, hand down, bequeath, nearly, almost, to approach",
    "grade": 7
  },
  {
    "id": 6025,
    "traditional": "垂頭喪氣",
    "simplified": "垂头丧气",
    "reading": "chuítóu-sàngqì",
    "meaning": "hanging one's head dispiritedly (idiom), dejected, crestfallen",
    "grade": 7
  },
  {
    "id": 6026,
    "traditional": "捶",
    "simplified": "捶",
    "reading": "chuí",
    "meaning": "to beat with the fist, to hammer, to cudgel, variant of 捶[chui2]",
    "grade": 7
  },
  {
    "id": 6027,
    "traditional": "錘子",
    "simplified": "锤子",
    "reading": "chuízi",
    "meaning": "hammer, CL:把[ba3]",
    "grade": 7
  },
  {
    "id": 6028,
    "traditional": "純粹",
    "simplified": "纯粹",
    "reading": "chúncuì",
    "meaning": "unmixed, unalloyed, pure, unadulterated",
    "grade": 7
  },
  {
    "id": 6029,
    "traditional": "純潔",
    "simplified": "纯洁",
    "reading": "chúnjié",
    "meaning": "pure, clean and honest, to purify",
    "grade": 7
  },
  {
    "id": 6030,
    "traditional": "純樸",
    "simplified": "纯朴",
    "reading": "chúnpǔ",
    "meaning": "simple and honest, unsophisticated, guileless, variant of 淳樸|淳朴[chun2 pu3]",
    "grade": 7
  },
  {
    "id": 6031,
    "traditional": "醇厚",
    "simplified": "醇厚",
    "reading": "chúnhòu",
    "meaning": "mellow and rich, simple and kind",
    "grade": 7
  },
  {
    "id": 6032,
    "traditional": "蠢",
    "simplified": "蠢",
    "reading": "chǔn",
    "meaning": "stupid, sluggish, clumsy, to wiggle (of worms), to move in a disorderly fashion",
    "grade": 7
  },
  {
    "id": 6033,
    "traditional": "戳",
    "simplified": "戳",
    "reading": "chuō",
    "meaning": "to jab, to poke, to stab, to sprain, to blunt, to fuck (vulgar), to stand sth upright",
    "grade": 7
  },
  {
    "id": 6034,
    "traditional": "綽號",
    "simplified": "绰号",
    "reading": "chuòhào",
    "meaning": "nickname",
    "grade": 7
  },
  {
    "id": 6035,
    "traditional": "瓷",
    "simplified": "瓷",
    "reading": "cí",
    "meaning": "chinaware, porcelain, china",
    "grade": 7
  },
  {
    "id": 6036,
    "traditional": "瓷器",
    "simplified": "瓷器",
    "reading": "cíqì",
    "meaning": "porcelain, chinaware",
    "grade": 7
  },
  {
    "id": 6037,
    "traditional": "辭",
    "simplified": "辞",
    "reading": "cí",
    "meaning": "to resign, to dismiss, to decline, to take leave, ballad (archaic poetic genre), variant of 詞|词[ci2]",
    "grade": 7
  },
  {
    "id": 6038,
    "traditional": "辭呈",
    "simplified": "辞呈",
    "reading": "cíchéng",
    "meaning": "(written) resignation",
    "grade": 7
  },
  {
    "id": 6039,
    "traditional": "辭去",
    "simplified": "辞去",
    "reading": "cíqù",
    "meaning": "to resign, to quit",
    "grade": 7
  },
  {
    "id": 6040,
    "traditional": "辭退",
    "simplified": "辞退",
    "reading": "cítuì",
    "meaning": "to dismiss, to discharge, to fire",
    "grade": 7
  },
  {
    "id": 6041,
    "traditional": "慈善",
    "simplified": "慈善",
    "reading": "císhàn",
    "meaning": "benevolent, charitable, philanthropic, benevolently",
    "grade": 7
  },
  {
    "id": 6042,
    "traditional": "慈祥",
    "simplified": "慈祥",
    "reading": "cíxiáng",
    "meaning": "kindly, benevolent (often of older person)",
    "grade": 7
  },
  {
    "id": 6043,
    "traditional": "磁帶",
    "simplified": "磁带",
    "reading": "cídài",
    "meaning": "magnetic tape, CL:盤|盘[pan2],盒[he2]",
    "grade": 7
  },
  {
    "id": 6044,
    "traditional": "磁卡",
    "simplified": "磁卡",
    "reading": "cíkǎ",
    "meaning": "magnetic card, IC Card (telephone)",
    "grade": 7
  },
  {
    "id": 6045,
    "traditional": "磁盤",
    "simplified": "磁盘",
    "reading": "cípán",
    "meaning": "(computer) disk",
    "grade": 7
  },
  {
    "id": 6046,
    "traditional": "此起彼伏",
    "simplified": "此起彼伏",
    "reading": "cǐqǐ-bǐfú",
    "meaning": "up here, down there (idiom), to rise and fall in succession, no sooner one subsides, the next arises, repeating continuously, occurring again and again (of applause, fires, waves, protests, conflicts, uprisings etc)",
    "grade": 7
  },
  {
    "id": 6047,
    "traditional": "次日",
    "simplified": "次日",
    "reading": "cìrì",
    "meaning": "next day, the morrow",
    "grade": 7
  },
  {
    "id": 6048,
    "traditional": "伺候",
    "simplified": "伺候",
    "reading": "cìhou",
    "meaning": "to serve, to wait upon",
    "grade": 7
  },
  {
    "id": 6049,
    "traditional": "刺耳",
    "simplified": "刺耳",
    "reading": "cìěr",
    "meaning": "ear-piercing",
    "grade": 7
  },
  {
    "id": 6050,
    "traditional": "刺骨",
    "simplified": "刺骨",
    "reading": "cìgǔ",
    "meaning": "piercing, cutting, bone-chilling, penetrating (cold)",
    "grade": 7
  },
  {
    "id": 6051,
    "traditional": "刺繡",
    "simplified": "刺绣",
    "reading": "cìxiù",
    "meaning": "to embroider, embroidery",
    "grade": 7
  },
  {
    "id": 6052,
    "traditional": "賜",
    "simplified": "赐",
    "reading": "cì",
    "meaning": "favor, award, favour, grant",
    "grade": 7
  },
  {
    "id": 6053,
    "traditional": "賜教",
    "simplified": "赐教",
    "reading": "cìjiào",
    "meaning": "Enlighten me",
    "grade": 7
  },
  {
    "id": 6054,
    "traditional": "匆匆",
    "simplified": "匆匆",
    "reading": "cōngcōng",
    "meaning": "hurriedly",
    "grade": 7
  },
  {
    "id": 6055,
    "traditional": "匆忙",
    "simplified": "匆忙",
    "reading": "cōngmáng",
    "meaning": "hasty, hurried",
    "grade": 7
  },
  {
    "id": 6056,
    "traditional": "蔥",
    "simplified": "葱",
    "reading": "cōng",
    "meaning": "scallion, green onion",
    "grade": 7
  },
  {
    "id": 6057,
    "traditional": "從今以後",
    "simplified": "从今以后",
    "reading": "cóngjīnyǐhòu",
    "meaning": "From now on",
    "grade": 7
  },
  {
    "id": 6058,
    "traditional": "從來不",
    "simplified": "从来不",
    "reading": "cóngláibù",
    "meaning": "never",
    "grade": 7
  },
  {
    "id": 6059,
    "traditional": "從容",
    "simplified": "从容",
    "reading": "cóngróng",
    "meaning": "calm, unhurried, leisurely",
    "grade": 7
  },
  {
    "id": 6060,
    "traditional": "從容不迫",
    "simplified": "从容不迫",
    "reading": "cóngróng-bùpò",
    "meaning": "calm, unruffled",
    "grade": 7
  },
  {
    "id": 6061,
    "traditional": "從頭",
    "simplified": "从头",
    "reading": "cóngtóu",
    "meaning": "anew, from the start",
    "grade": 7
  },
  {
    "id": 6062,
    "traditional": "從未",
    "simplified": "从未",
    "reading": "cóngwèi",
    "meaning": "ne'er, never",
    "grade": 7
  },
  {
    "id": 6063,
    "traditional": "從業",
    "simplified": "从业",
    "reading": "cóngyè",
    "meaning": "to practice (a trade)",
    "grade": 7
  },
  {
    "id": 6064,
    "traditional": "從早到晚",
    "simplified": "从早到晚",
    "reading": "cóngzǎodàowǎn",
    "meaning": "From early as late",
    "grade": 7
  },
  {
    "id": 6065,
    "traditional": "叢林",
    "simplified": "丛林",
    "reading": "cónglín",
    "meaning": "underbrush, chaparral, bosquet, brake, bosk, jungle, forest, copse, jungly, brushwood, grove, thickset, underwood, thicket, mott, Budd. monastery",
    "grade": 7
  },
  {
    "id": 6066,
    "traditional": "湊",
    "simplified": "凑",
    "reading": "còu",
    "meaning": "to gather together, pool or collect, to happen by chance, to move close to, to exploit an opportunity",
    "grade": 7
  },
  {
    "id": 6067,
    "traditional": "湊合",
    "simplified": "凑合",
    "reading": "còuhe",
    "meaning": "to bring together, to make do in a bad situation, to just get by, to improvise, passable, not too bad",
    "grade": 7
  },
  {
    "id": 6068,
    "traditional": "湊巧",
    "simplified": "凑巧",
    "reading": "còuqiǎo",
    "meaning": "fortuitously, luckily, as chance has it",
    "grade": 7
  },
  {
    "id": 6069,
    "traditional": "粗暴",
    "simplified": "粗暴",
    "reading": "cūbào",
    "meaning": "rough, cruel",
    "grade": 7
  },
  {
    "id": 6070,
    "traditional": "粗糙",
    "simplified": "粗糙",
    "reading": "cūcāo",
    "meaning": "crude, gruff, rough, coarse",
    "grade": 7
  },
  {
    "id": 6071,
    "traditional": "粗魯",
    "simplified": "粗鲁",
    "reading": "cūlu",
    "meaning": "coarse, crude (in one's manner), boorish",
    "grade": 7
  },
  {
    "id": 6072,
    "traditional": "粗略",
    "simplified": "粗略",
    "reading": "cūlüè",
    "meaning": "rough (not precise or accurate), cursory",
    "grade": 7
  },
  {
    "id": 6073,
    "traditional": "粗心大意",
    "simplified": "粗心大意",
    "reading": "cūxīn-dàyì",
    "meaning": "negligent, careless, inadvertent",
    "grade": 7
  },
  {
    "id": 6074,
    "traditional": "促成",
    "simplified": "促成",
    "reading": "cùchéng",
    "meaning": "facilitate, effect",
    "grade": 7
  },
  {
    "id": 6075,
    "traditional": "簇擁",
    "simplified": "簇拥",
    "reading": "cùyōng",
    "meaning": "to crowd around, to escort",
    "grade": 7
  },
  {
    "id": 6076,
    "traditional": "竄",
    "simplified": "窜",
    "reading": "cuàn",
    "meaning": "to flee, to scuttle, to exile or banish, to amend or edit",
    "grade": 7
  },
  {
    "id": 6077,
    "traditional": "催",
    "simplified": "催",
    "reading": "cuī",
    "meaning": "urge, hurry, press, hasten, expedite",
    "grade": 7
  },
  {
    "id": 6078,
    "traditional": "催促",
    "simplified": "催促",
    "reading": "cuīcù",
    "meaning": "to urge",
    "grade": 7
  },
  {
    "id": 6079,
    "traditional": "催眠",
    "simplified": "催眠",
    "reading": "cuīmián",
    "meaning": "lull, hypnotize, mesmerize, hypnotic, lull (to sleep)",
    "grade": 7
  },
  {
    "id": 6080,
    "traditional": "摧毀",
    "simplified": "摧毁",
    "reading": "cuīhuǐ",
    "meaning": "take_out, blot_out, bang up, blast, devastate, mow_down, zap, tobreak, wreck, smash up, bust up, smite, destroy, bust, demolish, knock_down, destruction, ravage, unbuild, liquidate, smash, deflower, knock_off, break, wrack",
    "grade": 7
  },
  {
    "id": 6081,
    "traditional": "脆弱",
    "simplified": "脆弱",
    "reading": "cuìruò",
    "meaning": "fragile, frail, weak",
    "grade": 7
  },
  {
    "id": 6082,
    "traditional": "翠綠",
    "simplified": "翠绿",
    "reading": "cuìlǜ",
    "meaning": "greenish-blue, emerald green",
    "grade": 7
  },
  {
    "id": 6083,
    "traditional": "存放",
    "simplified": "存放",
    "reading": "cúnfàng",
    "meaning": "leave with, leave in sb.'s care, deposit (money)",
    "grade": 7
  },
  {
    "id": 6084,
    "traditional": "存心",
    "simplified": "存心",
    "reading": "cúnxīn",
    "meaning": "deliberately",
    "grade": 7
  },
  {
    "id": 6085,
    "traditional": "存折",
    "simplified": "存折",
    "reading": "cúnzhé",
    "meaning": "passbook, bankbook",
    "grade": 7
  },
  {
    "id": 6086,
    "traditional": "搓",
    "simplified": "搓",
    "reading": "cuō",
    "meaning": "to rub or roll between the hands or fingers, to twist",
    "grade": 7
  },
  {
    "id": 6087,
    "traditional": "磋商",
    "simplified": "磋商",
    "reading": "cuōshāng",
    "meaning": "to consult, to discuss seriously, to negotiate, to confer, negotiations, consultations",
    "grade": 7
  },
  {
    "id": 6088,
    "traditional": "挫折",
    "simplified": "挫折",
    "reading": "cuòzhé",
    "meaning": "frustratio, rebuff, defeat, founder, frustration, reverse, breakdown, blight, setback, throwback, abortion, backset, flivver, dash, cross",
    "grade": 7
  },
  {
    "id": 6089,
    "traditional": "措手不及",
    "simplified": "措手不及",
    "reading": "cuòshǒu-bùjí",
    "meaning": "no time to deal with it (idiom), caught unprepared",
    "grade": 7
  },
  {
    "id": 6090,
    "traditional": "錯覺",
    "simplified": "错觉",
    "reading": "cuòjué",
    "meaning": "misconception, illusion, misperception",
    "grade": 7
  },
  {
    "id": 6091,
    "traditional": "錯位",
    "simplified": "错位",
    "reading": "cuò wèi",
    "meaning": "to misplace, displacement (e.g. of broken bones), out of alignment, faulty contact, erroneous judgment, inversion (medical, e.g. breach delivery)",
    "grade": 7
  },
  {
    "id": 6092,
    "traditional": "錯綜複雜",
    "simplified": "错综复杂",
    "reading": "cuòzōng-fùzá",
    "meaning": "tangled and complicated (idiom)",
    "grade": 7
  },
  {
    "id": 6093,
    "traditional": "錯別字",
    "simplified": "错别字",
    "reading": "cuòbiézì",
    "meaning": "incorrectly written or mispronounced characters",
    "grade": 7
  },
  {
    "id": 6094,
    "traditional": "搭乘",
    "simplified": "搭乘",
    "reading": "dāchéng",
    "meaning": "travel by, travel, travel (by a conveyance), jet, take, fly, hitching, get_on",
    "grade": 7
  },
  {
    "id": 6095,
    "traditional": "搭建",
    "simplified": "搭建",
    "reading": "dājiàn",
    "meaning": "to build (esp. with simple materials), to knock together (a temporary shed), to rig up",
    "grade": 7
  },
  {
    "id": 6096,
    "traditional": "達標",
    "simplified": "达标",
    "reading": "dábiāo",
    "meaning": "to reach a set standard",
    "grade": 7
  },
  {
    "id": 6097,
    "traditional": "答辯",
    "simplified": "答辩",
    "reading": "dábiàn",
    "meaning": "to reply (to an accusation), to defend one's dissertation",
    "grade": 7
  },
  {
    "id": 6098,
    "traditional": "打岔",
    "simplified": "打岔",
    "reading": "dǎ chà",
    "meaning": "interruption, to interrupt (esp. talk), to change the subject",
    "grade": 7
  },
  {
    "id": 6099,
    "traditional": "打倒",
    "simplified": "打倒",
    "reading": "dǎ dǎo",
    "meaning": "to overthrow, to knock down, Down with ... !",
    "grade": 7
  },
  {
    "id": 6100,
    "traditional": "打盹兒",
    "simplified": "打盹儿",
    "reading": "dǎ dǔnr5",
    "meaning": "to doze off, erhua variant of 打盹[da3 dun3]",
    "grade": 7
  },
  {
    "id": 6101,
    "traditional": "打交道",
    "simplified": "打交道",
    "reading": "dǎ jiāodao",
    "meaning": "to come into contact with, to have dealings",
    "grade": 7
  },
  {
    "id": 6102,
    "traditional": "打攪",
    "simplified": "打搅",
    "reading": "dǎjiǎo",
    "meaning": "to disturb, to trouble",
    "grade": 7
  },
  {
    "id": 6103,
    "traditional": "打撈",
    "simplified": "打捞",
    "reading": "dǎlāo",
    "meaning": "to salvage, to dredge, to fish out (person or object from the sea)",
    "grade": 7
  },
  {
    "id": 6104,
    "traditional": "打量",
    "simplified": "打量",
    "reading": "dǎliang",
    "meaning": "estimate, conjecture, size up, reckon, look up and down, suppose, give the once over, think, look ... up and down, measure with the eye, give the eye, calculate",
    "grade": 7
  },
  {
    "id": 6105,
    "traditional": "打獵",
    "simplified": "打猎",
    "reading": "dǎ liè",
    "meaning": "to go hunting",
    "grade": 7
  },
  {
    "id": 6106,
    "traditional": "打磨",
    "simplified": "打磨",
    "reading": "dǎmo",
    "meaning": "polish, grind",
    "grade": 7
  },
  {
    "id": 6107,
    "traditional": "打通",
    "simplified": "打通",
    "reading": "dǎ tōng",
    "meaning": "to open access, to establish contact, to remove a block, to put through (a phone connection)",
    "grade": 7
  },
  {
    "id": 6108,
    "traditional": "打仗",
    "simplified": "打仗",
    "reading": "dǎ zhàng",
    "meaning": "fight battle, outplay, war, make war, fight, wage war, battle, go to war",
    "grade": 7
  },
  {
    "id": 6109,
    "traditional": "打招呼",
    "simplified": "打招呼",
    "reading": "dǎ zhāohu",
    "meaning": "to greet sb by word or action, to give prior notice",
    "grade": 7
  },
  {
    "id": 6110,
    "traditional": "大包大攬",
    "simplified": "大包大揽",
    "reading": "dàbāodàlǎn",
    "meaning": "to take complete charge (idiom)",
    "grade": 7
  },
  {
    "id": 6111,
    "traditional": "大筆",
    "simplified": "大笔",
    "reading": "dàbǐ",
    "meaning": "Large",
    "grade": 7
  },
  {
    "id": 6112,
    "traditional": "大臣",
    "simplified": "大臣",
    "reading": "dàchén",
    "meaning": "courtier, court, secretary, referendary, vizier, government minister, viziership, high officials, cabinet ministers, president, chancellor, minister",
    "grade": 7
  },
  {
    "id": 6113,
    "traditional": "大吃一驚",
    "simplified": "大吃一惊",
    "reading": "dàchī-yījīng",
    "meaning": "to have a surprise (idiom), shocked or startled, gobsmacked",
    "grade": 7
  },
  {
    "id": 6114,
    "traditional": "大大咧咧",
    "simplified": "大大咧咧",
    "reading": "dàda-liēliē",
    "meaning": "carefree, offhand, casual",
    "grade": 7
  },
  {
    "id": 6115,
    "traditional": "大地",
    "simplified": "大地",
    "reading": "dàdì",
    "meaning": "terra_firma, mother earth, mold, earth, soli",
    "grade": 7
  },
  {
    "id": 6116,
    "traditional": "大隊",
    "simplified": "大队",
    "reading": "dàduì",
    "meaning": "a military group, production brigade, a large number (of)",
    "grade": 7
  },
  {
    "id": 6117,
    "traditional": "大幅度",
    "simplified": "大幅度",
    "reading": "dàfúdù",
    "meaning": "by a wide margin, substantial",
    "grade": 7
  },
  {
    "id": 6118,
    "traditional": "大公無私",
    "simplified": "大公无私",
    "reading": "dàgōng-wúsī",
    "meaning": "selfless, impartial",
    "grade": 7
  },
  {
    "id": 6119,
    "traditional": "大家庭",
    "simplified": "大家庭",
    "reading": "dàjiātíng",
    "meaning": "extended family",
    "grade": 7
  },
  {
    "id": 6120,
    "traditional": "大街小巷",
    "simplified": "大街小巷",
    "reading": "dàjiē-xiǎoxiàng",
    "meaning": "great streets and small alleys (idiom), everywhere in the city",
    "grade": 7
  },
  {
    "id": 6121,
    "traditional": "大驚小怪",
    "simplified": "大惊小怪",
    "reading": "dàjīng-xiǎoguài",
    "meaning": "to make a fuss about nothing (idiom)",
    "grade": 7
  },
  {
    "id": 6122,
    "traditional": "大局",
    "simplified": "大局",
    "reading": "dàjú",
    "meaning": "general situation, present conditions",
    "grade": 7
  },
  {
    "id": 6123,
    "traditional": "大款",
    "simplified": "大款",
    "reading": "dàkuǎn",
    "meaning": "very wealthy person",
    "grade": 7
  },
  {
    "id": 6124,
    "traditional": "大面積",
    "simplified": "大面积",
    "reading": "dàmiànjī",
    "meaning": "large area",
    "grade": 7
  },
  {
    "id": 6125,
    "traditional": "大名鼎鼎",
    "simplified": "大名鼎鼎",
    "reading": "dàmíng-dǐngdǐng",
    "meaning": "grand reputation, renowned, famous",
    "grade": 7
  },
  {
    "id": 6126,
    "traditional": "大模大樣",
    "simplified": "大模大样",
    "reading": "dàmú-dàyàng",
    "meaning": "Large model",
    "grade": 7
  },
  {
    "id": 6127,
    "traditional": "大棚",
    "simplified": "大棚",
    "reading": "dàpéng",
    "meaning": "Greenhouse",
    "grade": 7
  },
  {
    "id": 6128,
    "traditional": "大片",
    "simplified": "大片",
    "reading": "dàpiàn",
    "meaning": "wide expanse, large area, vast stretch, extending widely, blockbuster movie",
    "grade": 7
  },
  {
    "id": 6129,
    "traditional": "大氣",
    "simplified": "大气",
    "reading": "dàqì",
    "meaning": "atmosphere, air, grand air, heavy breathing",
    "grade": 7
  },
  {
    "id": 6130,
    "traditional": "大廈",
    "simplified": "大厦",
    "reading": "dàshà",
    "meaning": "building, mansion",
    "grade": 7
  },
  {
    "id": 6131,
    "traditional": "大數據",
    "simplified": "大数据",
    "reading": "dàshùjù",
    "meaning": "Big Data",
    "grade": 7
  },
  {
    "id": 6132,
    "traditional": "大肆",
    "simplified": "大肆",
    "reading": "dàsì",
    "meaning": "wantonly, without restraint (of enemy or malefactor), unbridled",
    "grade": 7
  },
  {
    "id": 6133,
    "traditional": "大體",
    "simplified": "大体",
    "reading": "dàtǐ",
    "meaning": "in general, more or less, in rough terms, basically, on the whole, overall situation, the big picture, (Tw) remains (of a dead person)",
    "grade": 7
  },
  {
    "id": 6134,
    "traditional": "大體上",
    "simplified": "大体上",
    "reading": "dàtǐshàng",
    "meaning": "overall, in general terms",
    "grade": 7
  },
  {
    "id": 6135,
    "traditional": "大同小異",
    "simplified": "大同小异",
    "reading": "dàtóng-xiǎoyì",
    "meaning": "virtually the same, differing only on small points",
    "grade": 7
  },
  {
    "id": 6136,
    "traditional": "大腕兒",
    "simplified": "大腕儿",
    "reading": "dàwànr5",
    "meaning": "Big wrist",
    "grade": 7
  },
  {
    "id": 6137,
    "traditional": "大選",
    "simplified": "大选",
    "reading": "dàxuǎn",
    "meaning": "general election",
    "grade": 7
  },
  {
    "id": 6138,
    "traditional": "大雁",
    "simplified": "大雁",
    "reading": "dàyàn",
    "meaning": "wild goose, CL:隻|只[zhi1]",
    "grade": 7
  },
  {
    "id": 6139,
    "traditional": "大意",
    "simplified": "大意",
    "reading": "dàyi",
    "meaning": "general idea, main idea, careless",
    "grade": 7
  },
  {
    "id": 6140,
    "traditional": "大有可為",
    "simplified": "大有可为",
    "reading": "dàyǒu-kěwéi",
    "meaning": "with great prospects for the future (idiom), well worth doing",
    "grade": 7
  },
  {
    "id": 6141,
    "traditional": "大宗",
    "simplified": "大宗",
    "reading": "dàzōng",
    "meaning": "large amount, staple, influential family of long standing",
    "grade": 7
  },
  {
    "id": 6142,
    "traditional": "歹徒",
    "simplified": "歹徒",
    "reading": "dǎitú",
    "meaning": "evildoer, ruffian, scoundrel",
    "grade": 7
  },
  {
    "id": 6143,
    "traditional": "逮",
    "simplified": "逮",
    "reading": "dǎi",
    "meaning": "(coll.) to catch, to seize, (literary) to arrest, to seize, to overtake, until",
    "grade": 7
  },
  {
    "id": 6144,
    "traditional": "代號",
    "simplified": "代号",
    "reading": "dàihào",
    "meaning": "code name",
    "grade": 7
  },
  {
    "id": 6145,
    "traditional": "代理人",
    "simplified": "代理人",
    "reading": "dàilǐrén",
    "meaning": "agent",
    "grade": 7
  },
  {
    "id": 6146,
    "traditional": "代言人",
    "simplified": "代言人",
    "reading": "dàiyánrén",
    "meaning": "spokesperson",
    "grade": 7
  },
  {
    "id": 6147,
    "traditional": "帶隊",
    "simplified": "带队",
    "reading": "dài duì",
    "meaning": "Lead",
    "grade": 7
  },
  {
    "id": 6148,
    "traditional": "帶路",
    "simplified": "带路",
    "reading": "dài lù",
    "meaning": "to lead the way, to guide, to show the way, fig. to instruct",
    "grade": 7
  },
  {
    "id": 6149,
    "traditional": "帶頭",
    "simplified": "带头",
    "reading": "dài tóu",
    "meaning": "to take the lead, to be the first, to set an example",
    "grade": 7
  },
  {
    "id": 6150,
    "traditional": "帶頭人",
    "simplified": "带头人",
    "reading": "dàitóurén",
    "meaning": "Take the leader",
    "grade": 7
  },
  {
    "id": 6151,
    "traditional": "待",
    "simplified": "待",
    "reading": "dài",
    "meaning": "till",
    "grade": 7
  },
  {
    "id": 6152,
    "traditional": "怠工",
    "simplified": "怠工",
    "reading": "dài gōng",
    "meaning": "to slacken off in one's work, to go slow (as a form of strike)",
    "grade": 7
  },
  {
    "id": 6153,
    "traditional": "怠慢",
    "simplified": "怠慢",
    "reading": "dàimàn",
    "meaning": "to slight, to neglect",
    "grade": 7
  },
  {
    "id": 6154,
    "traditional": "逮捕",
    "simplified": "逮捕",
    "reading": "dàibǔ",
    "meaning": "make an arrest",
    "grade": 7
  },
  {
    "id": 6155,
    "traditional": "擔",
    "simplified": "担",
    "reading": "dān",
    "meaning": "to undertake, to carry, to shoulder, to take responsibility, picul (100 catties, 50 kg), two buckets full, carrying pole and its load, classifier for loads carried on a shoulder pole",
    "grade": 7
  },
  {
    "id": 6156,
    "traditional": "擔當",
    "simplified": "担当",
    "reading": "dāndāng",
    "meaning": "to take upon oneself, to assume",
    "grade": 7
  },
  {
    "id": 6157,
    "traditional": "擔負",
    "simplified": "担负",
    "reading": "dānfù",
    "meaning": "bear, shoulder, take on, be charged with",
    "grade": 7
  },
  {
    "id": 6158,
    "traditional": "單邊",
    "simplified": "单边",
    "reading": "dānbiān",
    "meaning": "unilateral",
    "grade": 7
  },
  {
    "id": 6159,
    "traditional": "單薄",
    "simplified": "单薄",
    "reading": "dānbó",
    "meaning": "weak, frail, thin, flimsy",
    "grade": 7
  },
  {
    "id": 6160,
    "traditional": "單方面",
    "simplified": "单方面",
    "reading": "dānfāngmiàn",
    "meaning": "unilateral",
    "grade": 7
  },
  {
    "id": 6161,
    "traditional": "單身",
    "simplified": "单身",
    "reading": "dānshēn",
    "meaning": "unmarried, single",
    "grade": 7
  },
  {
    "id": 6162,
    "traditional": "耽擱",
    "simplified": "耽搁",
    "reading": "dānge",
    "meaning": "to tarry, to delay, to stop over",
    "grade": 7
  },
  {
    "id": 6163,
    "traditional": "耽誤",
    "simplified": "耽误",
    "reading": "dānwu",
    "meaning": "to delay, to hold up, to waste time, to interfere with",
    "grade": 7
  },
  {
    "id": 6164,
    "traditional": "膽怯",
    "simplified": "胆怯",
    "reading": "dǎnqiè",
    "meaning": "timid, cowardly",
    "grade": 7
  },
  {
    "id": 6165,
    "traditional": "膽子",
    "simplified": "胆子",
    "reading": "dǎnzi",
    "meaning": "courage, nerve, guts",
    "grade": 7
  },
  {
    "id": 6166,
    "traditional": "但願",
    "simplified": "但愿",
    "reading": "dànyuàn",
    "meaning": "wish, if only..., I wish that..., may, might",
    "grade": 7
  },
  {
    "id": 6167,
    "traditional": "擔子",
    "simplified": "担子",
    "reading": "dànzi",
    "meaning": "carrying pole and the loads on it, burden, task, responsibility, CL:副[fu4]",
    "grade": 7
  },
  {
    "id": 6168,
    "traditional": "誕辰",
    "simplified": "诞辰",
    "reading": "dànchén",
    "meaning": "birthday",
    "grade": 7
  },
  {
    "id": 6169,
    "traditional": "淡化",
    "simplified": "淡化",
    "reading": "dànhuà",
    "meaning": "desalinate",
    "grade": 7
  },
  {
    "id": 6170,
    "traditional": "淡季",
    "simplified": "淡季",
    "reading": "dànjì",
    "meaning": "off season, slow business season, see also 旺季[wang4 ji4]",
    "grade": 7
  },
  {
    "id": 6171,
    "traditional": "蛋白質",
    "simplified": "蛋白质",
    "reading": "dànbáizhì",
    "meaning": "albuminoid, proteide, proteid, albumin, albumen, protein",
    "grade": 7
  },
  {
    "id": 6172,
    "traditional": "當即",
    "simplified": "当即",
    "reading": "dāngjí",
    "meaning": "at once, on the spot",
    "grade": 7
  },
  {
    "id": 6173,
    "traditional": "當今",
    "simplified": "当今",
    "reading": "dāngjīn",
    "meaning": "current, present, now, nowadays",
    "grade": 7
  },
  {
    "id": 6174,
    "traditional": "當面",
    "simplified": "当面",
    "reading": "dāng miàn",
    "meaning": "directly, face_to_face, face to face, in one's presence",
    "grade": 7
  },
  {
    "id": 6175,
    "traditional": "當日",
    "simplified": "当日",
    "reading": "dāngrì",
    "meaning": "on that day, that very day, the same day",
    "grade": 7
  },
  {
    "id": 6176,
    "traditional": "當事人",
    "simplified": "当事人",
    "reading": "dāngshìrén",
    "meaning": "interested parties, those directly involved, principal, client, the party concerned, privy, party, litigant, person concerned",
    "grade": 7
  },
  {
    "id": 6177,
    "traditional": "當務之急",
    "simplified": "当务之急",
    "reading": "dāngwùzhījí",
    "meaning": "top priority job, matter of vital importance",
    "grade": 7
  },
  {
    "id": 6178,
    "traditional": "當下",
    "simplified": "当下",
    "reading": "dāngxià",
    "meaning": "immediately, at once, at that moment, at the moment",
    "grade": 7
  },
  {
    "id": 6179,
    "traditional": "當心",
    "simplified": "当心",
    "reading": "dāngxīn",
    "meaning": "to take care, to look out",
    "grade": 7
  },
  {
    "id": 6180,
    "traditional": "當著",
    "simplified": "当着",
    "reading": "dāngzhe",
    "meaning": "in front of, in the presence of",
    "grade": 7
  },
  {
    "id": 6181,
    "traditional": "當之無愧",
    "simplified": "当之无愧",
    "reading": "dāngzhī-wúkuì",
    "meaning": "fully deserving, without any reservations (idiom), entirely worthy (of a title, honor etc)",
    "grade": 7
  },
  {
    "id": 6182,
    "traditional": "當眾",
    "simplified": "当众",
    "reading": "dāngzhòng",
    "meaning": "in public, in front of everybody",
    "grade": 7
  },
  {
    "id": 6183,
    "traditional": "當晚",
    "simplified": "当晚",
    "reading": "dàngwǎn",
    "meaning": "on that evening, the same evening",
    "grade": 7
  },
  {
    "id": 6184,
    "traditional": "當真",
    "simplified": "当真",
    "reading": "dàngzhēn",
    "meaning": "to take seriously, serious, No joking, really!",
    "grade": 7
  },
  {
    "id": 6185,
    "traditional": "蕩漾",
    "simplified": "荡漾",
    "reading": "dàngyàng",
    "meaning": "to ripple, to undulate",
    "grade": 7
  },
  {
    "id": 6186,
    "traditional": "檔次",
    "simplified": "档次",
    "reading": "dàngcì",
    "meaning": "grade, class, quality, level",
    "grade": 7
  },
  {
    "id": 6187,
    "traditional": "導彈",
    "simplified": "导弹",
    "reading": "dǎodàn",
    "meaning": "guided missile, cruise missile, missile, CL:枚[mei2]",
    "grade": 7
  },
  {
    "id": 6188,
    "traditional": "導航",
    "simplified": "导航",
    "reading": "dǎoháng",
    "meaning": "navigation",
    "grade": 7
  },
  {
    "id": 6189,
    "traditional": "導火索",
    "simplified": "导火索",
    "reading": "dǎohuǒsuǒ",
    "meaning": "a fuse (for explosive)",
    "grade": 7
  },
  {
    "id": 6190,
    "traditional": "導師",
    "simplified": "导师",
    "reading": "dǎoshī",
    "meaning": "don, guide of a great cause, hierophant, teacher, tutor, imam, supervisor, leader in a cause, preceptor, professor, preceptorship, adviser, teacher in charge of class, mentor, counselor",
    "grade": 7
  },
  {
    "id": 6191,
    "traditional": "導向",
    "simplified": "导向",
    "reading": "dǎoxiàng",
    "meaning": "orientation",
    "grade": 7
  },
  {
    "id": 6192,
    "traditional": "島嶼",
    "simplified": "岛屿",
    "reading": "dǎoyǔ",
    "meaning": "island, islands, islands and islets, isle",
    "grade": 7
  },
  {
    "id": 6193,
    "traditional": "搗亂",
    "simplified": "捣乱",
    "reading": "dǎo luàn",
    "meaning": "to disturb, to look for trouble, to stir up a row, to bother sb intentionally",
    "grade": 7
  },
  {
    "id": 6194,
    "traditional": "倒賣",
    "simplified": "倒卖",
    "reading": "dǎomài",
    "meaning": "to resell at a profit, to speculate",
    "grade": 7
  },
  {
    "id": 6195,
    "traditional": "倒霉",
    "simplified": "倒霉",
    "reading": "dǎo méi",
    "meaning": "to have bad luck, to be out of luck",
    "grade": 7
  },
  {
    "id": 6196,
    "traditional": "倒塌",
    "simplified": "倒塌",
    "reading": "dǎotā",
    "meaning": "to collapse (of building), to topple over",
    "grade": 7
  },
  {
    "id": 6197,
    "traditional": "倒下",
    "simplified": "倒下",
    "reading": "dǎoxià",
    "meaning": "to collapse, to topple over",
    "grade": 7
  },
  {
    "id": 6198,
    "traditional": "到頭來",
    "simplified": "到头来",
    "reading": "dàotóulái",
    "meaning": "in the end, finally, as a result",
    "grade": 7
  },
  {
    "id": 6199,
    "traditional": "到位",
    "simplified": "到位",
    "reading": "dàowèi",
    "meaning": "to get to the intended location, to be in place, to be in position, precise, well (done)",
    "grade": 7
  },
  {
    "id": 6200,
    "traditional": "倒計時",
    "simplified": "倒计时",
    "reading": "dàojìshí",
    "meaning": "to count down, countdown",
    "grade": 7
  },
  {
    "id": 6201,
    "traditional": "倒數",
    "simplified": "倒数",
    "reading": "dàoshǔ",
    "meaning": "to count backwards (from 10 down to 0), to count down, from the bottom (lines on a page), from the back (rows of seats), inverse number, reciprocal (math.)",
    "grade": 7
  },
  {
    "id": 6202,
    "traditional": "盜",
    "simplified": "盗",
    "reading": "dào",
    "meaning": "to steal, to rob, to plunder, thief, bandit, robber",
    "grade": 7
  },
  {
    "id": 6203,
    "traditional": "盜竊",
    "simplified": "盗窃",
    "reading": "dàoqiè",
    "meaning": "to steal",
    "grade": 7
  },
  {
    "id": 6204,
    "traditional": "悼念",
    "simplified": "悼念",
    "reading": "dàoniàn",
    "meaning": "to grieve",
    "grade": 7
  },
  {
    "id": 6205,
    "traditional": "道具",
    "simplified": "道具",
    "reading": "dàojù",
    "meaning": "stage props",
    "grade": 7
  },
  {
    "id": 6206,
    "traditional": "稻草",
    "simplified": "稻草",
    "reading": "dàocǎo",
    "meaning": "rice straw",
    "grade": 7
  },
  {
    "id": 6207,
    "traditional": "得不償失",
    "simplified": "得不偿失",
    "reading": "débùchángshī",
    "meaning": "the gains do not make up for the losses (idiom)",
    "grade": 7
  },
  {
    "id": 6208,
    "traditional": "得當",
    "simplified": "得当",
    "reading": "dédàng",
    "meaning": "appropriate, suitable",
    "grade": 7
  },
  {
    "id": 6209,
    "traditional": "得力",
    "simplified": "得力",
    "reading": "dé lì",
    "meaning": "able, capable, competent, efficient",
    "grade": 7
  },
  {
    "id": 6210,
    "traditional": "得失",
    "simplified": "得失",
    "reading": "déshī",
    "meaning": "gains and losses, success and failure, merits and demerits",
    "grade": 7
  },
  {
    "id": 6211,
    "traditional": "得手",
    "simplified": "得手",
    "reading": "dé shǒu",
    "meaning": "to go smoothly, to come off, to succeed",
    "grade": 7
  },
  {
    "id": 6212,
    "traditional": "得體",
    "simplified": "得体",
    "reading": "détǐ",
    "meaning": "appropriate to the occasion, fitting",
    "grade": 7
  },
  {
    "id": 6213,
    "traditional": "得天獨厚",
    "simplified": "得天独厚",
    "reading": "détiān-dúhòu",
    "meaning": "blessed by heaven (idiom), enjoying exceptional advantages, favored by nature",
    "grade": 7
  },
  {
    "id": 6214,
    "traditional": "得益於",
    "simplified": "得益于",
    "reading": "déyìyú",
    "meaning": "Benefited from",
    "grade": 7
  },
  {
    "id": 6215,
    "traditional": "得意揚揚",
    "simplified": "得意扬扬",
    "reading": "déyì-yángyáng",
    "meaning": "joyfully satisfied, to be immensely proud of oneself, proudly, an air of complacency, variant of 得意洋洋[de2 yi4 yang2 yang2]",
    "grade": 7
  },
  {
    "id": 6216,
    "traditional": "得知",
    "simplified": "得知",
    "reading": "dézhī",
    "meaning": "find, hear, see, have, succeed, learn, get_onto, know, have learned of",
    "grade": 7
  },
  {
    "id": 6217,
    "traditional": "得罪",
    "simplified": "得罪",
    "reading": "dézui",
    "meaning": "disoblige, offend, displease, affront",
    "grade": 7
  },
  {
    "id": 6218,
    "traditional": "德",
    "simplified": "德",
    "reading": "dé",
    "meaning": "Germany",
    "grade": 7
  },
  {
    "id": 6219,
    "traditional": "燈籠",
    "simplified": "灯笼",
    "reading": "dēnglong",
    "meaning": "lantern",
    "grade": 7
  },
  {
    "id": 6220,
    "traditional": "燈泡",
    "simplified": "灯泡",
    "reading": "dēngpào",
    "meaning": "light bulb, see also 電燈泡|电灯泡[dian4 deng1 pao4], third-wheel or unwanted third party spoiling a couple's date (slang), CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 6221,
    "traditional": "登機",
    "simplified": "登机",
    "reading": "dēng jī",
    "meaning": "to board a plane",
    "grade": 7
  },
  {
    "id": 6222,
    "traditional": "登陸",
    "simplified": "登陆",
    "reading": "dēng lù",
    "meaning": "to land, to come ashore, to make landfall (of typhoon etc), to log in",
    "grade": 7
  },
  {
    "id": 6223,
    "traditional": "蹬",
    "simplified": "蹬",
    "reading": "dēng",
    "meaning": "to step on, to tread on, to wear (shoes), (slang) to dump (sb), Taiwan pr. [deng4]",
    "grade": 7
  },
  {
    "id": 6224,
    "traditional": "凳子",
    "simplified": "凳子",
    "reading": "dèngzi",
    "meaning": "stool, small seat",
    "grade": 7
  },
  {
    "id": 6225,
    "traditional": "瞪",
    "simplified": "瞪",
    "reading": "dèng",
    "meaning": "open wide, glom, stare, open (one's eyes) wide, stare at, glower, open one's eyes wide, glare",
    "grade": 7
  },
  {
    "id": 6226,
    "traditional": "低調",
    "simplified": "低调",
    "reading": "dīdiào",
    "meaning": "low pitch, quiet (voice), subdued, low-key, low-profile",
    "grade": 7
  },
  {
    "id": 6227,
    "traditional": "低估",
    "simplified": "低估",
    "reading": "dīgū",
    "meaning": "to underestimate, to underrate",
    "grade": 7
  },
  {
    "id": 6228,
    "traditional": "低谷",
    "simplified": "低谷",
    "reading": "dīgǔ",
    "meaning": "valley, trough (as opposed to peaks), fig. low point, lowest ebb, nadir of one's fortunes",
    "grade": 7
  },
  {
    "id": 6229,
    "traditional": "低價",
    "simplified": "低价",
    "reading": "dījià",
    "meaning": "cheap, song, low, low price",
    "grade": 7
  },
  {
    "id": 6230,
    "traditional": "低迷",
    "simplified": "低迷",
    "reading": "dīmí",
    "meaning": "low",
    "grade": 7
  },
  {
    "id": 6231,
    "traditional": "低碳",
    "simplified": "低碳",
    "reading": "dītàn",
    "meaning": "Low carbon",
    "grade": 7
  },
  {
    "id": 6232,
    "traditional": "低下",
    "simplified": "低下",
    "reading": "dīxià",
    "meaning": "low status, lowly, to lower (one's head)",
    "grade": 7
  },
  {
    "id": 6233,
    "traditional": "堤",
    "simplified": "堤",
    "reading": "dī",
    "meaning": "dike, Taiwan pr. [ti2], variant of 堤[di1]",
    "grade": 7
  },
  {
    "id": 6234,
    "traditional": "堤壩",
    "simplified": "堤坝",
    "reading": "dībà",
    "meaning": "dam",
    "grade": 7
  },
  {
    "id": 6235,
    "traditional": "提防",
    "simplified": "提防",
    "reading": "dīfang",
    "meaning": "to guard against, to be vigilant, watch you don't (slip), also pr. [ti2 fang2]",
    "grade": 7
  },
  {
    "id": 6236,
    "traditional": "笛子",
    "simplified": "笛子",
    "reading": "dízi",
    "meaning": "bamboo flute, CL:管[guan3]",
    "grade": 7
  },
  {
    "id": 6237,
    "traditional": "抵觸",
    "simplified": "抵触",
    "reading": "dǐchù",
    "meaning": "to conflict, to contradict",
    "grade": 7
  },
  {
    "id": 6238,
    "traditional": "抵擋",
    "simplified": "抵挡",
    "reading": "dǐdǎng",
    "meaning": "to resist, to hold back, to stop, to ward off, to withstand",
    "grade": 7
  },
  {
    "id": 6239,
    "traditional": "抵消",
    "simplified": "抵消",
    "reading": "dǐxiāo",
    "meaning": "to counteract, to cancel out, to offset",
    "grade": 7
  },
  {
    "id": 6240,
    "traditional": "抵押",
    "simplified": "抵押",
    "reading": "dǐyā",
    "meaning": "to provide (an asset) as security for a loan, to put up collateral",
    "grade": 7
  },
  {
    "id": 6241,
    "traditional": "抵禦",
    "simplified": "抵御",
    "reading": "dǐyù",
    "meaning": "to resist, to withstand",
    "grade": 7
  },
  {
    "id": 6242,
    "traditional": "抵制",
    "simplified": "抵制",
    "reading": "dǐzhì",
    "meaning": "boycott",
    "grade": 7
  },
  {
    "id": 6243,
    "traditional": "底層",
    "simplified": "底层",
    "reading": "dǐcéng",
    "meaning": "first floor, understratum, bottom, underside, the lowest rung, first, undersurface, underlayer, ground level, ground floor, first/ground floor, substratum, first_floor, basement",
    "grade": 7
  },
  {
    "id": 6244,
    "traditional": "底線",
    "simplified": "底线",
    "reading": "dǐxiàn",
    "meaning": "to underline, bottom line, base line (in sports), baseline, minimum, spy, plant",
    "grade": 7
  },
  {
    "id": 6245,
    "traditional": "底蘊",
    "simplified": "底蕴",
    "reading": "dǐyùn",
    "meaning": "inside information, concrete details",
    "grade": 7
  },
  {
    "id": 6246,
    "traditional": "底子",
    "simplified": "底子",
    "reading": "dǐzi",
    "meaning": "base, foundation, bottom",
    "grade": 7
  },
  {
    "id": 6247,
    "traditional": "地步",
    "simplified": "地步",
    "reading": "dìbù",
    "meaning": "state, situation, extent, plight, condition",
    "grade": 7
  },
  {
    "id": 6248,
    "traditional": "地道",
    "simplified": "地道",
    "reading": "dìdao",
    "meaning": "tunnel, causeway, authentic, genuine, typical, from a place known for the product, thorough, conscientious",
    "grade": 7
  },
  {
    "id": 6249,
    "traditional": "地段",
    "simplified": "地段",
    "reading": "dìduàn",
    "meaning": "section, district",
    "grade": 7
  },
  {
    "id": 6250,
    "traditional": "地毯",
    "simplified": "地毯",
    "reading": "dìtǎn",
    "meaning": "carpet, footcloth, rug, carpeting, tapis",
    "grade": 7
  },
  {
    "id": 6251,
    "traditional": "地理",
    "simplified": "地理",
    "reading": "dìlǐ",
    "meaning": "geographical, geographic, geographics, geographical features of a place, geography",
    "grade": 7
  },
  {
    "id": 6252,
    "traditional": "地下水",
    "simplified": "地下水",
    "reading": "dìxiàshuǐ",
    "meaning": "groundwater",
    "grade": 7
  },
  {
    "id": 6253,
    "traditional": "地獄",
    "simplified": "地狱",
    "reading": "dìyù",
    "meaning": "the pit, hell, infernal, pandemonium, abyss, underworld, ballyhack, Hades, hades, blaze, Sheol, infernal region, Gehenna, Tartarus, Avernus, Topheth, Tophet, heck, nether region, Scheol, Acheron, perdition, the lower regions, Abaddon, netherworld, Hel, Inferno, inferno, Hell",
    "grade": 7
  },
  {
    "id": 6254,
    "traditional": "地域",
    "simplified": "地域",
    "reading": "dìyù",
    "meaning": "tract, area, latitude, quarter, territory, climate, region, scope, district, terrain, climactic",
    "grade": 7
  },
  {
    "id": 6255,
    "traditional": "地質",
    "simplified": "地质",
    "reading": "dìzhì",
    "meaning": "geology",
    "grade": 7
  },
  {
    "id": 6256,
    "traditional": "弟子",
    "simplified": "弟子",
    "reading": "dìzǐ",
    "meaning": "scholar, pupil, disciple, student, follower, child, adherent, youngster",
    "grade": 7
  },
  {
    "id": 6257,
    "traditional": "帝國",
    "simplified": "帝国",
    "reading": "dìguó",
    "meaning": "Reich, imperial, empery, empire",
    "grade": 7
  },
  {
    "id": 6258,
    "traditional": "帝國主義",
    "simplified": "帝国主义",
    "reading": "dìguózhǔyì",
    "meaning": "imperialism",
    "grade": 7
  },
  {
    "id": 6259,
    "traditional": "遞交",
    "simplified": "递交",
    "reading": "dìjiāo",
    "meaning": "to present, to give, to hand over, to hand in, to lay before",
    "grade": 7
  },
  {
    "id": 6260,
    "traditional": "第一手",
    "simplified": "第一手",
    "reading": "dìyīshǒu",
    "meaning": "first-hand",
    "grade": 7
  },
  {
    "id": 6261,
    "traditional": "第一線",
    "simplified": "第一线",
    "reading": "dìyīxiàn",
    "meaning": "forefront, front/first line",
    "grade": 7
  },
  {
    "id": 6262,
    "traditional": "顛倒",
    "simplified": "颠倒",
    "reading": "diāndǎo",
    "meaning": "to turn upside down, to reverse, back to front, confused, deranged, crazy",
    "grade": 7
  },
  {
    "id": 6263,
    "traditional": "顛覆",
    "simplified": "颠覆",
    "reading": "diānfù",
    "meaning": "overturn, subvert",
    "grade": 7
  },
  {
    "id": 6264,
    "traditional": "巔峰",
    "simplified": "巅峰",
    "reading": "diānfēng",
    "meaning": "summit, apex, pinnacle (of one's career etc), peak (of a civilization etc)",
    "grade": 7
  },
  {
    "id": 6265,
    "traditional": "點火",
    "simplified": "点火",
    "reading": "diǎn huǒ",
    "meaning": "to ignite, to light a fire, to agitate, to start an engine, ignition, fig. to stir up trouble",
    "grade": 7
  },
  {
    "id": 6266,
    "traditional": "點擊率",
    "simplified": "点击率",
    "reading": "diǎnjīlǜ",
    "meaning": "click-through rate (CTR) (Internet)",
    "grade": 7
  },
  {
    "id": 6267,
    "traditional": "典範",
    "simplified": "典范",
    "reading": "diǎnfàn",
    "meaning": "quintessence, nonpareil, example, specimen, nonsuch, apotheosis, ideal, nonesuch, prototype, monument, paragon, role model, soul, saint, model",
    "grade": 7
  },
  {
    "id": 6268,
    "traditional": "點評",
    "simplified": "点评",
    "reading": "diǎnpíng",
    "meaning": "to comment, a point by point commentary",
    "grade": 7
  },
  {
    "id": 6269,
    "traditional": "點心",
    "simplified": "点心",
    "reading": "diǎnxin",
    "meaning": "light refreshments, pastry, dimsum (in Cantonese cooking), dessert",
    "grade": 7
  },
  {
    "id": 6270,
    "traditional": "點綴",
    "simplified": "点缀",
    "reading": "diǎnzhuì",
    "meaning": "to decorate, to adorn, sprinkled, studded, only for show",
    "grade": 7
  },
  {
    "id": 6271,
    "traditional": "點子",
    "simplified": "点子",
    "reading": "diǎnzi",
    "meaning": "drop (of liquid), drop, idea, hint, dot, beat (of percussion instruments), beat, spot, key point, speck, pointer",
    "grade": 7
  },
  {
    "id": 6272,
    "traditional": "電報",
    "simplified": "电报",
    "reading": "diànbào",
    "meaning": "telegram, cable, telegraph, CL:封[feng1],份[fen4]",
    "grade": 7
  },
  {
    "id": 6273,
    "traditional": "電線",
    "simplified": "电线",
    "reading": "diànxiàn",
    "meaning": "wire, power cord, CL:根[gen1]",
    "grade": 7
  },
  {
    "id": 6274,
    "traditional": "電網",
    "simplified": "电网",
    "reading": "diànwǎng",
    "meaning": "electrical network",
    "grade": 7
  },
  {
    "id": 6275,
    "traditional": "電鈴",
    "simplified": "电铃",
    "reading": "diànlíng",
    "meaning": "electric bell",
    "grade": 7
  },
  {
    "id": 6276,
    "traditional": "電信",
    "simplified": "电信",
    "reading": "diànxìn",
    "meaning": "telegraphy, telecommunications, telecommunication, telecom",
    "grade": 7
  },
  {
    "id": 6277,
    "traditional": "電訊",
    "simplified": "电讯",
    "reading": "diànxùn",
    "meaning": "telegraph, telecommunications, telecommunication, flash, telegraphic dispatch",
    "grade": 7
  },
  {
    "id": 6278,
    "traditional": "墊",
    "simplified": "垫",
    "reading": "diàn",
    "meaning": "pad, cushion, mat, to pad out, to fill a gap, to pay for sb, to advance (money)",
    "grade": 7
  },
  {
    "id": 6279,
    "traditional": "墊底",
    "simplified": "垫底",
    "reading": "diàndǐ",
    "meaning": "Bottom",
    "grade": 7
  },
  {
    "id": 6280,
    "traditional": "墊子",
    "simplified": "垫子",
    "reading": "diànzi",
    "meaning": "cushion, mat, pad",
    "grade": 7
  },
  {
    "id": 6281,
    "traditional": "澱粉",
    "simplified": "淀粉",
    "reading": "diànfěn",
    "meaning": "starch, amylum C6H10O5",
    "grade": 7
  },
  {
    "id": 6282,
    "traditional": "惦記",
    "simplified": "惦记",
    "reading": "diànji",
    "meaning": "to think of, to keep thinking about, to be concerned about",
    "grade": 7
  },
  {
    "id": 6283,
    "traditional": "奠定",
    "simplified": "奠定",
    "reading": "diàndìng",
    "meaning": "establish, settle",
    "grade": 7
  },
  {
    "id": 6284,
    "traditional": "殿堂",
    "simplified": "殿堂",
    "reading": "diàntáng",
    "meaning": "mansion, manse, hall, palace, temple, residence, mansion house",
    "grade": 7
  },
  {
    "id": 6285,
    "traditional": "刁難",
    "simplified": "刁难",
    "reading": "diāonàn",
    "meaning": "to be hard on sb, to deliberately make things difficult",
    "grade": 7
  },
  {
    "id": 6286,
    "traditional": "叼",
    "simplified": "叼",
    "reading": "diāo",
    "meaning": "to hold with one's mouth (as a smoker with a cigarette or a dog with a bone)",
    "grade": 7
  },
  {
    "id": 6287,
    "traditional": "雕",
    "simplified": "雕",
    "reading": "diāo",
    "meaning": "bird of prey",
    "grade": 7
  },
  {
    "id": 6288,
    "traditional": "雕刻",
    "simplified": "雕刻",
    "reading": "diāokè",
    "meaning": "statue",
    "grade": 7
  },
  {
    "id": 6289,
    "traditional": "雕塑",
    "simplified": "雕塑",
    "reading": "diāosù",
    "meaning": "a statue, a Buddhist image, sculpture, to carve",
    "grade": 7
  },
  {
    "id": 6290,
    "traditional": "吊銷",
    "simplified": "吊销",
    "reading": "diàoxiāo",
    "meaning": "to suspend (an agreement), to revoke",
    "grade": 7
  },
  {
    "id": 6291,
    "traditional": "釣魚",
    "simplified": "钓鱼",
    "reading": "diào yú",
    "meaning": "go fishing, fish, go angling, angle",
    "grade": 7
  },
  {
    "id": 6292,
    "traditional": "調度",
    "simplified": "调度",
    "reading": "diàodù",
    "meaning": "to dispatch (vehicles, staff etc), to schedule, to manage, dispatcher, scheduler",
    "grade": 7
  },
  {
    "id": 6293,
    "traditional": "掉隊",
    "simplified": "掉队",
    "reading": "diào duì",
    "meaning": "to fall behind, to drop out",
    "grade": 7
  },
  {
    "id": 6294,
    "traditional": "掉頭",
    "simplified": "掉头",
    "reading": "diào tóu",
    "meaning": "to turn one's head, to turn round, to turn about",
    "grade": 7
  },
  {
    "id": 6295,
    "traditional": "爹",
    "simplified": "爹",
    "reading": "diē",
    "meaning": "old man, father, papa, pappa, daddy, pa, pop, dada, dad, pater",
    "grade": 7
  },
  {
    "id": 6296,
    "traditional": "迭起",
    "simplified": "迭起",
    "reading": "diéqǐ",
    "meaning": "continuously arising, to arise repeatedly",
    "grade": 7
  },
  {
    "id": 6297,
    "traditional": "疊",
    "simplified": "叠",
    "reading": "dié",
    "meaning": "to fold, to fold over in layers, to furl, to layer, to pile up, to repeat, to duplicate",
    "grade": 7
  },
  {
    "id": 6298,
    "traditional": "丁",
    "simplified": "丁",
    "reading": "dīng",
    "meaning": "surname Ding, fourth of the ten Heavenly Stems 十天干[shi2 tian1 gan1], fourth in order, letter 'D' or roman 'IV' in list 'A, B, C', or 'I, II, III' etc, ancient Chinese compass point: 195°, butyl, cubes (of food)",
    "grade": 7
  },
  {
    "id": 6299,
    "traditional": "叮囑",
    "simplified": "叮嘱",
    "reading": "dīngzhǔ",
    "meaning": "to warn repeatedly, to urge, to exhort again and again",
    "grade": 7
  },
  {
    "id": 6300,
    "traditional": "釘子",
    "simplified": "钉子",
    "reading": "dīngzi",
    "meaning": "nail, snag, saboteur",
    "grade": 7
  },
  {
    "id": 6301,
    "traditional": "盯",
    "simplified": "盯",
    "reading": "dīng",
    "meaning": "to watch attentively, to fix one's attention on, to stare at, to gaze at",
    "grade": 7
  },
  {
    "id": 6302,
    "traditional": "頂多",
    "simplified": "顶多",
    "reading": "dǐngduō",
    "meaning": "at most, at best",
    "grade": 7
  },
  {
    "id": 6303,
    "traditional": "頂級",
    "simplified": "顶级",
    "reading": "dǐngjí",
    "meaning": "top-notch, first-rate",
    "grade": 7
  },
  {
    "id": 6304,
    "traditional": "頂尖",
    "simplified": "顶尖",
    "reading": "dǐngjiān",
    "meaning": "towering",
    "grade": 7
  },
  {
    "id": 6305,
    "traditional": "訂單",
    "simplified": "订单",
    "reading": "dìngdān",
    "meaning": "order_form, order, order form, order form for goods, purchase order, indent",
    "grade": 7
  },
  {
    "id": 6306,
    "traditional": "訂購",
    "simplified": "订购",
    "reading": "dìnggòu",
    "meaning": "to place an order, to subscribe",
    "grade": 7
  },
  {
    "id": 6307,
    "traditional": "訂婚",
    "simplified": "订婚",
    "reading": "dìng hūn",
    "meaning": "to get engaged",
    "grade": 7
  },
  {
    "id": 6308,
    "traditional": "訂立",
    "simplified": "订立",
    "reading": "dìnglì",
    "meaning": "to conclude (treaty, contract, agreement etc), to set up (a rule etc)",
    "grade": 7
  },
  {
    "id": 6309,
    "traditional": "釘",
    "simplified": "钉",
    "reading": "dīng",
    "meaning": "nail, to follow closely, to keep at sb (to do sth), variant of 盯[ding1], to join things together by fixing them in place at one or more points, to nail, to pin, to staple, to sew on",
    "grade": 7
  },
  {
    "id": 6310,
    "traditional": "定金",
    "simplified": "定金",
    "reading": "dìngjīn",
    "meaning": "down payment, advance payment",
    "grade": 7
  },
  {
    "id": 6311,
    "traditional": "定居",
    "simplified": "定居",
    "reading": "dìng jū",
    "meaning": "settle down",
    "grade": 7
  },
  {
    "id": 6312,
    "traditional": "定論",
    "simplified": "定论",
    "reading": "dìnglùn",
    "meaning": "final conclusion, accepted argument",
    "grade": 7
  },
  {
    "id": 6313,
    "traditional": "定為",
    "simplified": "定为",
    "reading": "dìng wèi",
    "meaning": "set to",
    "grade": 7
  },
  {
    "id": 6314,
    "traditional": "定向",
    "simplified": "定向",
    "reading": "dìngxiàng",
    "meaning": "to orientate, directional, directed, orienteering",
    "grade": 7
  },
  {
    "id": 6315,
    "traditional": "定心丸",
    "simplified": "定心丸",
    "reading": "dìngxīnwán",
    "meaning": "tranquilizer, sth that sets one's mind at ease",
    "grade": 7
  },
  {
    "id": 6316,
    "traditional": "定做",
    "simplified": "定做",
    "reading": "dìngzuò",
    "meaning": "to have something made to order",
    "grade": 7
  },
  {
    "id": 6317,
    "traditional": "丟掉",
    "simplified": "丢掉",
    "reading": "diūdiào",
    "meaning": "to lose, to throw away, to discard, to cast away",
    "grade": 7
  },
  {
    "id": 6318,
    "traditional": "定義",
    "simplified": "定义",
    "reading": "dìngyì",
    "meaning": "definition",
    "grade": 7
  },
  {
    "id": 6319,
    "traditional": "丟臉",
    "simplified": "丢脸",
    "reading": "diū liǎn",
    "meaning": "to lose face, humiliation",
    "grade": 7
  },
  {
    "id": 6320,
    "traditional": "丟棄",
    "simplified": "丢弃",
    "reading": "diūqì",
    "meaning": "to discard, to abandon",
    "grade": 7
  },
  {
    "id": 6321,
    "traditional": "丟人",
    "simplified": "丢人",
    "reading": "diū rén",
    "meaning": "to lose face",
    "grade": 7
  },
  {
    "id": 6322,
    "traditional": "丟失",
    "simplified": "丢失",
    "reading": "diūshī",
    "meaning": "to lose, lost",
    "grade": 7
  },
  {
    "id": 6323,
    "traditional": "東奔西走",
    "simplified": "东奔西走",
    "reading": "dōngbēnxīzǒu",
    "meaning": "to run this way and that (idiom), to rush about busily, to bustle about, to hopscotch, also 東跑西顛|东跑西颠[dong1 pao3 xi1 dian1]",
    "grade": 7
  },
  {
    "id": 6324,
    "traditional": "東道主",
    "simplified": "东道主",
    "reading": "dōngdàozhǔ",
    "meaning": "host, official host (e.g. venue for games or a conference)",
    "grade": 7
  },
  {
    "id": 6325,
    "traditional": "東張西望",
    "simplified": "东张西望",
    "reading": "dōngzhāng-xīwàng",
    "meaning": "to look in all directions (idiom), to glance around",
    "grade": 7
  },
  {
    "id": 6326,
    "traditional": "董事",
    "simplified": "董事",
    "reading": "dǒngshì",
    "meaning": "director, trustee",
    "grade": 7
  },
  {
    "id": 6327,
    "traditional": "董事會",
    "simplified": "董事会",
    "reading": "dǒngshìhuì",
    "meaning": "board, board of directors, board of administration, syndicate, directorate, board of regents, board of trustees, board_of_directors, directory",
    "grade": 7
  },
  {
    "id": 6328,
    "traditional": "董事長",
    "simplified": "董事长",
    "reading": "dǒngshìzhǎng",
    "meaning": "board chair",
    "grade": 7
  },
  {
    "id": 6329,
    "traditional": "懂事",
    "simplified": "懂事",
    "reading": "dǒng shì",
    "meaning": "sensible, thoughtful, intelligent",
    "grade": 7
  },
  {
    "id": 6330,
    "traditional": "動不動",
    "simplified": "动不动",
    "reading": "dòngbudòng",
    "meaning": "apt to happen (usually of sth undesirable), frequently, happening easily (e.g. accident or illness)",
    "grade": 7
  },
  {
    "id": 6331,
    "traditional": "動蕩",
    "simplified": "动荡",
    "reading": "dòngdàng",
    "meaning": "unrest (social or political), turmoil, upheaval, commotion",
    "grade": 7
  },
  {
    "id": 6332,
    "traditional": "動感",
    "simplified": "动感",
    "reading": "dònggǎn",
    "meaning": "innervation, dynamism",
    "grade": 7
  },
  {
    "id": 6333,
    "traditional": "動工",
    "simplified": "动工",
    "reading": "dòng gōng",
    "meaning": "begin construction",
    "grade": 7
  },
  {
    "id": 6334,
    "traditional": "動靜",
    "simplified": "动静",
    "reading": "dòngjing",
    "meaning": "sound of activity or people talking, news of activity",
    "grade": 7
  },
  {
    "id": 6335,
    "traditional": "動脈",
    "simplified": "动脉",
    "reading": "dòngmài",
    "meaning": "artery",
    "grade": 7
  },
  {
    "id": 6336,
    "traditional": "動身",
    "simplified": "动身",
    "reading": "dòng shēn",
    "meaning": "to go on a journey, to leave",
    "grade": 7
  },
  {
    "id": 6337,
    "traditional": "動彈",
    "simplified": "动弹",
    "reading": "dòngtan",
    "meaning": "to budge",
    "grade": 7
  },
  {
    "id": 6338,
    "traditional": "動聽",
    "simplified": "动听",
    "reading": "dòngtīng",
    "meaning": "pleasant to listen to",
    "grade": 7
  },
  {
    "id": 6339,
    "traditional": "動向",
    "simplified": "动向",
    "reading": "dòngxiàng",
    "meaning": "set, development, tendency, trend, movement, drift",
    "grade": 7
  },
  {
    "id": 6340,
    "traditional": "動用",
    "simplified": "动用",
    "reading": "dòngyòng",
    "meaning": "put to use, draw on, put ... to use, use, employ, employment",
    "grade": 7
  },
  {
    "id": 6341,
    "traditional": "凍結",
    "simplified": "冻结",
    "reading": "dòngjié",
    "meaning": "to freeze (loan, wage, price etc)",
    "grade": 7
  },
  {
    "id": 6342,
    "traditional": "棟",
    "simplified": "栋",
    "reading": "dòng",
    "meaning": "ridgepole, m.[general],",
    "grade": 7
  },
  {
    "id": 6343,
    "traditional": "棟梁",
    "simplified": "栋梁",
    "reading": "dòngliáng",
    "meaning": "ridgepole, ridgepole and beams, person able to bear heavy responsibility, mainstay (of organization), pillar (of state)",
    "grade": 7
  },
  {
    "id": 6344,
    "traditional": "兜",
    "simplified": "兜",
    "reading": "dōu",
    "meaning": "pocket, bag, to wrap up or hold in a bag, to move in a circle, to canvas or solicit, to take responsibility for, to disclose in detail, combat armor (old), old variant of 兜[dou1]",
    "grade": 7
  },
  {
    "id": 6345,
    "traditional": "兜兒",
    "simplified": "兜儿",
    "reading": "dōur5",
    "meaning": "pocket, bag, to wrap up or hold in a bag, to move in a circle, to canvas or solicit, to take responsibility for, to disclose in detail, combat armor (old), erhua variant of 兜[dou1]",
    "grade": 7
  },
  {
    "id": 6346,
    "traditional": "兜售",
    "simplified": "兜售",
    "reading": "dōushòu",
    "meaning": "to hawk, to peddle",
    "grade": 7
  },
  {
    "id": 6347,
    "traditional": "陡",
    "simplified": "陡",
    "reading": "dǒu",
    "meaning": "steep, precipitous, abrubtly, suddenly, unexpectedly",
    "grade": 7
  },
  {
    "id": 6348,
    "traditional": "抖",
    "simplified": "抖",
    "reading": "dǒu",
    "meaning": "to tremble, to shake out, to reveal, to make it in the world",
    "grade": 7
  },
  {
    "id": 6349,
    "traditional": "鬥",
    "simplified": "斗",
    "reading": "dòu",
    "meaning": "abbr. for the Big Dipper constellation 北斗星[Bei3 dou3 xing1], dry measure for grain equal to ten 升[sheng1] or one-tenth of a 石[dan4], decaliter, peck, cup or dipper shaped object, old variant of 陡[dou3], variant of 鬥|斗[dou4]",
    "grade": 7
  },
  {
    "id": 6350,
    "traditional": "鬥志",
    "simplified": "斗志",
    "reading": "dòuzhì",
    "meaning": "will to fight, fighting spirit",
    "grade": 7
  },
  {
    "id": 6351,
    "traditional": "豆漿",
    "simplified": "豆浆",
    "reading": "dòujiāng",
    "meaning": "soy milk",
    "grade": 7
  },
  {
    "id": 6352,
    "traditional": "豆子",
    "simplified": "豆子",
    "reading": "dòuzi",
    "meaning": "bean, pea, CL:顆|颗[ke1]",
    "grade": 7
  },
  {
    "id": 6353,
    "traditional": "逗",
    "simplified": "逗",
    "reading": "dòu",
    "meaning": "to stay, to stop, to tease (play with), amusing, short pause in reading aloud, equivalent to comma (also written 讀|读[dou4])",
    "grade": 7
  },
  {
    "id": 6354,
    "traditional": "都會",
    "simplified": "都会",
    "reading": "dūhuì",
    "meaning": "big city, city, metropolis",
    "grade": 7
  },
  {
    "id": 6355,
    "traditional": "督促",
    "simplified": "督促",
    "reading": "dūcù",
    "meaning": "to supervise and urge completion of a task, to urge on",
    "grade": 7
  },
  {
    "id": 6356,
    "traditional": "獨",
    "simplified": "独",
    "reading": "dú",
    "meaning": "by oneself, alone, in solitude, only",
    "grade": 7
  },
  {
    "id": 6357,
    "traditional": "獨唱",
    "simplified": "独唱",
    "reading": "dúchàng",
    "meaning": "(in singing) solo, to solo",
    "grade": 7
  },
  {
    "id": 6358,
    "traditional": "獨家",
    "simplified": "独家",
    "reading": "dújiā",
    "meaning": "exclusive",
    "grade": 7
  },
  {
    "id": 6359,
    "traditional": "獨立自主",
    "simplified": "独立自主",
    "reading": "dúlì-zìzhǔ",
    "meaning": "independent and autonomous (idiom), self-determination, to act independently, to maintain control over one's own affairs",
    "grade": 7
  },
  {
    "id": 6360,
    "traditional": "獨身",
    "simplified": "独身",
    "reading": "dúshēn",
    "meaning": "unmarried, single",
    "grade": 7
  },
  {
    "id": 6361,
    "traditional": "獨一無二",
    "simplified": "独一无二",
    "reading": "dúyī-wúèr",
    "meaning": "unique and unmatched (idiom), unrivalled, nothing compares with it",
    "grade": 7
  },
  {
    "id": 6362,
    "traditional": "堵塞",
    "simplified": "堵塞",
    "reading": "dǔsè",
    "meaning": "to clog up, blockage",
    "grade": 7
  },
  {
    "id": 6363,
    "traditional": "杜絕",
    "simplified": "杜绝",
    "reading": "dùjué",
    "meaning": "to put an end to",
    "grade": 7
  },
  {
    "id": 6364,
    "traditional": "妒忌",
    "simplified": "妒忌",
    "reading": "dùjì",
    "meaning": "to be jealous of (sb's achievements etc), to be envious, envy",
    "grade": 7
  },
  {
    "id": 6365,
    "traditional": "度",
    "simplified": "度",
    "reading": "dù",
    "meaning": "m.[activity]",
    "grade": 7
  },
  {
    "id": 6366,
    "traditional": "度假",
    "simplified": "度假",
    "reading": "dù jià",
    "meaning": "spend one's holidays",
    "grade": 7
  },
  {
    "id": 6367,
    "traditional": "渡過",
    "simplified": "渡过",
    "reading": "dùguò",
    "meaning": "pass, spend (time/etc.)",
    "grade": 7
  },
  {
    "id": 6368,
    "traditional": "端正",
    "simplified": "端正",
    "reading": "duānzhèng",
    "meaning": "upright, regular, proper, correct",
    "grade": 7
  },
  {
    "id": 6369,
    "traditional": "短缺",
    "simplified": "短缺",
    "reading": "duǎnquē",
    "meaning": "shortage",
    "grade": 7
  },
  {
    "id": 6370,
    "traditional": "短暫",
    "simplified": "短暂",
    "reading": "duǎnzàn",
    "meaning": "fleet, of short duration, brief, transient",
    "grade": 7
  },
  {
    "id": 6371,
    "traditional": "段落",
    "simplified": "段落",
    "reading": "duànluò",
    "meaning": "phase, time interval, paragraph, (written) passage",
    "grade": 7
  },
  {
    "id": 6372,
    "traditional": "斷斷續續",
    "simplified": "断断续续",
    "reading": "duànduàn-xùxù",
    "meaning": "intermittent, off and on, discontinuous, stop-go, stammering, disjointed, inarticulate",
    "grade": 7
  },
  {
    "id": 6373,
    "traditional": "斷定",
    "simplified": "断定",
    "reading": "duàndìng",
    "meaning": "to conclude, to determine, to come to a judgment",
    "grade": 7
  },
  {
    "id": 6374,
    "traditional": "斷裂",
    "simplified": "断裂",
    "reading": "duànliè",
    "meaning": "fracture, rupture, to break apart",
    "grade": 7
  },
  {
    "id": 6375,
    "traditional": "堆砌",
    "simplified": "堆砌",
    "reading": "duīqì",
    "meaning": "lit. to pile up (bricks), to pack, fig. to pad out (writing with fancy phrases), ornate rhetoric",
    "grade": 7
  },
  {
    "id": 6376,
    "traditional": "隊形",
    "simplified": "队形",
    "reading": "duìxíng",
    "meaning": "formation",
    "grade": 7
  },
  {
    "id": 6377,
    "traditional": "對白",
    "simplified": "对白",
    "reading": "duìbái",
    "meaning": "stage dialog",
    "grade": 7
  },
  {
    "id": 6378,
    "traditional": "對策",
    "simplified": "对策",
    "reading": "duìcè",
    "meaning": "expedient, cure, countermeasure, antidote, way to deal with a situation, countermove",
    "grade": 7
  },
  {
    "id": 6379,
    "traditional": "對稱",
    "simplified": "对称",
    "reading": "duìchèn",
    "meaning": "symmetry, symmetrical",
    "grade": 7
  },
  {
    "id": 6380,
    "traditional": "對得起",
    "simplified": "对得起",
    "reading": "duìdeqǐ",
    "meaning": "not to let sb down, to treat sb fairly, be worthy of",
    "grade": 7
  },
  {
    "id": 6381,
    "traditional": "對聯",
    "simplified": "对联",
    "reading": "duìlián",
    "meaning": "rhyming couplet, pair of lines of verse written vertically down the sides of a doorway, CL:副[fu4],幅[fu2]",
    "grade": 7
  },
  {
    "id": 6382,
    "traditional": "對弈",
    "simplified": "对弈",
    "reading": "duìyì",
    "meaning": "to play go, chess etc",
    "grade": 7
  },
  {
    "id": 6383,
    "traditional": "對照",
    "simplified": "对照",
    "reading": "duìzhào",
    "meaning": "contrast, compare",
    "grade": 7
  },
  {
    "id": 6384,
    "traditional": "對峙",
    "simplified": "对峙",
    "reading": "duìzhì",
    "meaning": "to stand opposite, to confront, confrontation",
    "grade": 7
  },
  {
    "id": 6385,
    "traditional": "對準",
    "simplified": "对准",
    "reading": "duì zhǔn",
    "meaning": "to aim at, to target, to point at, to be directed at, registration, alignment (mechanical engineering)",
    "grade": 7
  },
  {
    "id": 6386,
    "traditional": "兌換",
    "simplified": "兑换",
    "reading": "duìhuàn",
    "meaning": "to convert, to exchange",
    "grade": 7
  },
  {
    "id": 6387,
    "traditional": "兌現",
    "simplified": "兑现",
    "reading": "duìxiàn",
    "meaning": "(of a check etc) to cash, to honor a commitment",
    "grade": 7
  },
  {
    "id": 6388,
    "traditional": "敦厚",
    "simplified": "敦厚",
    "reading": "dūnhòu",
    "meaning": "genuine, honest and sincere",
    "grade": 7
  },
  {
    "id": 6389,
    "traditional": "敦促",
    "simplified": "敦促",
    "reading": "dūncù",
    "meaning": "to press, to urge, to hasten",
    "grade": 7
  },
  {
    "id": 6390,
    "traditional": "頓時",
    "simplified": "顿时",
    "reading": "dùnshí",
    "meaning": "suddenly, at once, immediately",
    "grade": 7
  },
  {
    "id": 6391,
    "traditional": "多邊",
    "simplified": "多边",
    "reading": "duōbiān",
    "meaning": "multilateral",
    "grade": 7
  },
  {
    "id": 6392,
    "traditional": "炖",
    "simplified": "炖",
    "reading": "dùn",
    "meaning": "to stew",
    "grade": 7
  },
  {
    "id": 6393,
    "traditional": "多功能",
    "simplified": "多功能",
    "reading": "duōgōngnéng",
    "meaning": "multi-functional, multi-function",
    "grade": 7
  },
  {
    "id": 6394,
    "traditional": "多勞多得",
    "simplified": "多劳多得",
    "reading": "duōláo-duōdé",
    "meaning": "work more and get more",
    "grade": 7
  },
  {
    "id": 6395,
    "traditional": "多年來",
    "simplified": "多年来",
    "reading": "duōniánlái",
    "meaning": "for the past many years",
    "grade": 7
  },
  {
    "id": 6396,
    "traditional": "多心",
    "simplified": "多心",
    "reading": "duōxīn",
    "meaning": "oversensitive, suspicious",
    "grade": 7
  },
  {
    "id": 6397,
    "traditional": "多虧",
    "simplified": "多亏",
    "reading": "duōkuī",
    "meaning": "thanks to, luckily",
    "grade": 7
  },
  {
    "id": 6398,
    "traditional": "多元",
    "simplified": "多元",
    "reading": "duōyuán",
    "meaning": "multiplex, multicomponent, multivariate, poly-",
    "grade": 7
  },
  {
    "id": 6399,
    "traditional": "哆嗦",
    "simplified": "哆嗦",
    "reading": "duōsuo",
    "meaning": "to tremble, to shiver, uncontrolled shaking of the body",
    "grade": 7
  },
  {
    "id": 6400,
    "traditional": "多餘",
    "simplified": "多余",
    "reading": "duōyú",
    "meaning": "spare, redundant, unnecessary, superfluous, uncalled-for, surplus",
    "grade": 7
  },
  {
    "id": 6401,
    "traditional": "奪魁",
    "simplified": "夺魁",
    "reading": "duókuí",
    "meaning": "to seize, to win",
    "grade": 7
  },
  {
    "id": 6402,
    "traditional": "奪冠",
    "simplified": "夺冠",
    "reading": "duó guàn",
    "meaning": "to seize the crown, fig. to win a championship, to win gold medal",
    "grade": 7
  },
  {
    "id": 6403,
    "traditional": "躲藏",
    "simplified": "躲藏",
    "reading": "duǒcáng",
    "meaning": "to conceal oneself, to go into hiding, to take cover",
    "grade": 7
  },
  {
    "id": 6404,
    "traditional": "舵手",
    "simplified": "舵手",
    "reading": "duòshǒu",
    "meaning": "helmsman",
    "grade": 7
  },
  {
    "id": 6405,
    "traditional": "躲避",
    "simplified": "躲避",
    "reading": "duǒbì",
    "meaning": "hide (oneself), elude, dodge",
    "grade": 7
  },
  {
    "id": 6406,
    "traditional": "墮落",
    "simplified": "堕落",
    "reading": "duòluò",
    "meaning": "degenerate, sink low",
    "grade": 7
  },
  {
    "id": 6407,
    "traditional": "俄語",
    "simplified": "俄语",
    "reading": "Éyǔ",
    "meaning": "Russian (language)",
    "grade": 7
  },
  {
    "id": 6408,
    "traditional": "訛詐",
    "simplified": "讹诈",
    "reading": "ézhà",
    "meaning": "to extort under false pretenses, to blackmail, to bluff, to defraud",
    "grade": 7
  },
  {
    "id": 6409,
    "traditional": "鵝",
    "simplified": "鹅",
    "reading": "é",
    "meaning": "goose, variant of 鵝|鹅[e2]",
    "grade": 7
  },
  {
    "id": 6410,
    "traditional": "額外",
    "simplified": "额外",
    "reading": "éwài",
    "meaning": "extra, added, additional",
    "grade": 7
  },
  {
    "id": 6411,
    "traditional": "惡",
    "simplified": "恶",
    "reading": "è",
    "meaning": "evil, wicked, vicious",
    "grade": 7
  },
  {
    "id": 6412,
    "traditional": "厄運",
    "simplified": "厄运",
    "reading": "èyùn",
    "meaning": "bad luck, misfortune, adversity",
    "grade": 7
  },
  {
    "id": 6413,
    "traditional": "惡劣",
    "simplified": "恶劣",
    "reading": "èliè",
    "meaning": "vile, nasty",
    "grade": 7
  },
  {
    "id": 6414,
    "traditional": "惡化",
    "simplified": "恶化",
    "reading": "èhuà",
    "meaning": "worsen",
    "grade": 7
  },
  {
    "id": 6415,
    "traditional": "惡性",
    "simplified": "恶性",
    "reading": "èxìng",
    "meaning": "malignant, pernicious, vicious, deadly, malignancy, viciousness, lethal, venomous, fatal, virulent",
    "grade": 7
  },
  {
    "id": 6416,
    "traditional": "惡意",
    "simplified": "恶意",
    "reading": "èyì",
    "meaning": "malice, evil intention",
    "grade": 7
  },
  {
    "id": 6417,
    "traditional": "遏制",
    "simplified": "遏制",
    "reading": "èzhì",
    "meaning": "to check, to contain, to hold back, to keep within limits, to constrain, to restrain",
    "grade": 7
  },
  {
    "id": 6418,
    "traditional": "鱷魚",
    "simplified": "鳄鱼",
    "reading": "èyú",
    "meaning": "alligator, crocodile",
    "grade": 7
  },
  {
    "id": 6419,
    "traditional": "恩賜",
    "simplified": "恩赐",
    "reading": "ēncì",
    "meaning": "favor, to give charity to sb out of pity",
    "grade": 7
  },
  {
    "id": 6420,
    "traditional": "恩惠",
    "simplified": "恩惠",
    "reading": "ēnhuì",
    "meaning": "favor, grace",
    "grade": 7
  },
  {
    "id": 6421,
    "traditional": "恩情",
    "simplified": "恩情",
    "reading": "ēnqíng",
    "meaning": "kindness, affection, grace, favor",
    "grade": 7
  },
  {
    "id": 6422,
    "traditional": "而已",
    "simplified": "而已",
    "reading": "éryǐ",
    "meaning": "particle: imparting finality (that's all)",
    "grade": 7
  },
  {
    "id": 6423,
    "traditional": "耳光",
    "simplified": "耳光",
    "reading": "ěrguāng",
    "meaning": "a slap on the face, CL:記|记[ji4]",
    "grade": 7
  },
  {
    "id": 6424,
    "traditional": "恩怨",
    "simplified": "恩怨",
    "reading": "ēnyuàn",
    "meaning": "gratitude and grudges, resentment, grudges, grievances",
    "grade": 7
  },
  {
    "id": 6425,
    "traditional": "耳目一新",
    "simplified": "耳目一新",
    "reading": "ěrmù-yīxīn",
    "meaning": "a pleasant change, a breath of fresh air, refreshing",
    "grade": 7
  },
  {
    "id": 6426,
    "traditional": "耳熟能詳",
    "simplified": "耳熟能详",
    "reading": "ěrshú-néngxiáng",
    "meaning": "what's frequently heard can be repeated in detail (idiom)",
    "grade": 7
  },
  {
    "id": 6427,
    "traditional": "耳聞目睹",
    "simplified": "耳闻目睹",
    "reading": "ěrwén-mùdǔ",
    "meaning": "to witness personally",
    "grade": 7
  },
  {
    "id": 6428,
    "traditional": "二手車",
    "simplified": "二手车",
    "reading": "èrshǒuchē",
    "meaning": "second-hand car",
    "grade": 7
  },
  {
    "id": 6429,
    "traditional": "二氧化碳",
    "simplified": "二氧化碳",
    "reading": "èryǎnghuàtàn",
    "meaning": "carbon dioxide CO2",
    "grade": 7
  },
  {
    "id": 6430,
    "traditional": "發佈會",
    "simplified": "发布会",
    "reading": "fābùhuì",
    "meaning": "news conference, briefing",
    "grade": 7
  },
  {
    "id": 6431,
    "traditional": "發財",
    "simplified": "发财",
    "reading": "fācái",
    "meaning": "to get rich",
    "grade": 7
  },
  {
    "id": 6432,
    "traditional": "發愁",
    "simplified": "发愁",
    "reading": "fā chóu",
    "meaning": "to worry, to fret, to be anxious, to become sad",
    "grade": 7
  },
  {
    "id": 6433,
    "traditional": "發電機",
    "simplified": "发电机",
    "reading": "fādiànjī",
    "meaning": "electricity generator, dynamo",
    "grade": 7
  },
  {
    "id": 6434,
    "traditional": "發抖",
    "simplified": "发抖",
    "reading": "fādǒu",
    "meaning": "to tremble, to shake, to shiver",
    "grade": 7
  },
  {
    "id": 6435,
    "traditional": "發光",
    "simplified": "发光",
    "reading": "fāguāng",
    "meaning": "to shine",
    "grade": 7
  },
  {
    "id": 6436,
    "traditional": "發憤圖強",
    "simplified": "发愤图强",
    "reading": "fāfèn-túqiáng",
    "meaning": "to be strongly determined to succeed (idiom)",
    "grade": 7
  },
  {
    "id": 6437,
    "traditional": "發酵",
    "simplified": "发酵",
    "reading": "fājiào",
    "meaning": "to ferment, fermentation",
    "grade": 7
  },
  {
    "id": 6438,
    "traditional": "發掘",
    "simplified": "发掘",
    "reading": "fājué",
    "meaning": "excavate, unearth, explore",
    "grade": 7
  },
  {
    "id": 6439,
    "traditional": "發火",
    "simplified": "发火",
    "reading": "fā huǒ",
    "meaning": "to catch fire, to ignite, to detonate, to get angry",
    "grade": 7
  },
  {
    "id": 6440,
    "traditional": "發脾氣",
    "simplified": "发脾气",
    "reading": "fā píqi",
    "meaning": "to get angry",
    "grade": 7
  },
  {
    "id": 6441,
    "traditional": "發起人",
    "simplified": "发起人",
    "reading": "fāqǐrén",
    "meaning": "proposer, initiator, founding member",
    "grade": 7
  },
  {
    "id": 6442,
    "traditional": "發熱",
    "simplified": "发热",
    "reading": "fā rè",
    "meaning": "to have a high temperature, feverish, unable to think calmly, to emit heat",
    "grade": 7
  },
  {
    "id": 6443,
    "traditional": "發誓",
    "simplified": "发誓",
    "reading": "fā shì",
    "meaning": "to vow, to pledge, to swear",
    "grade": 7
  },
  {
    "id": 6444,
    "traditional": "發愣",
    "simplified": "发愣",
    "reading": "fālèng",
    "meaning": "to daydream, in a daze",
    "grade": 7
  },
  {
    "id": 6445,
    "traditional": "發揚",
    "simplified": "发扬",
    "reading": "fāyáng",
    "meaning": "to develop, to make full use of",
    "grade": 7
  },
  {
    "id": 6446,
    "traditional": "發揚光大",
    "simplified": "发扬光大",
    "reading": "fāyáng-guāngdà",
    "meaning": "to develop and promote, to carry forward, to bring to great height of development",
    "grade": 7
  },
  {
    "id": 6447,
    "traditional": "發育",
    "simplified": "发育",
    "reading": "fāyù",
    "meaning": "grow, develop",
    "grade": 7
  },
  {
    "id": 6448,
    "traditional": "發源地",
    "simplified": "发源地",
    "reading": "fāyuándì",
    "meaning": "place of origin, birthplace, source",
    "grade": 7
  },
  {
    "id": 6449,
    "traditional": "發泄",
    "simplified": "发泄",
    "reading": "fāxiè",
    "meaning": "to give vent to (one's feelings)",
    "grade": 7
  },
  {
    "id": 6450,
    "traditional": "發作",
    "simplified": "发作",
    "reading": "fāzuò",
    "meaning": "break out, flare up, explode, have fit of anger, get_into, have a fit of anger, show effect",
    "grade": 7
  },
  {
    "id": 6451,
    "traditional": "閥門",
    "simplified": "阀门",
    "reading": "fámén",
    "meaning": "valve (mechanical)",
    "grade": 7
  },
  {
    "id": 6452,
    "traditional": "髮型",
    "simplified": "发型",
    "reading": "fàxíng",
    "meaning": "hairstyle, coiffure, hairdo",
    "grade": 7
  },
  {
    "id": 6453,
    "traditional": "帆",
    "simplified": "帆",
    "reading": "fān",
    "meaning": "to gallop, Taiwan pr. [fan2], variant of 帆[fan1]",
    "grade": 7
  },
  {
    "id": 6454,
    "traditional": "帆船",
    "simplified": "帆船",
    "reading": "fānchuán",
    "meaning": "sailboat",
    "grade": 7
  },
  {
    "id": 6455,
    "traditional": "翻番",
    "simplified": "翻番",
    "reading": "fān fān",
    "meaning": "to double, to increase by a certain number of times",
    "grade": 7
  },
  {
    "id": 6456,
    "traditional": "翻來覆去",
    "simplified": "翻来覆去",
    "reading": "fānlái-fùqù",
    "meaning": "to toss and turn (sleeplessly), again and again",
    "grade": 7
  },
  {
    "id": 6457,
    "traditional": "翻天覆地",
    "simplified": "翻天覆地",
    "reading": "fāntiān-fùdì",
    "meaning": "sky and the earth turning upside down (idiom), fig. complete confusion, everything turned on its head",
    "grade": 7
  },
  {
    "id": 6458,
    "traditional": "凡",
    "simplified": "凡",
    "reading": "fán",
    "meaning": "conj.: whatever",
    "grade": 7
  },
  {
    "id": 6459,
    "traditional": "煩悶",
    "simplified": "烦闷",
    "reading": "fánmèn",
    "meaning": "moody, gloomy",
    "grade": 7
  },
  {
    "id": 6460,
    "traditional": "煩惱",
    "simplified": "烦恼",
    "reading": "fánnǎo",
    "meaning": "vexation",
    "grade": 7
  },
  {
    "id": 6461,
    "traditional": "煩躁",
    "simplified": "烦躁",
    "reading": "fánzào",
    "meaning": "jittery, twitchy, fidgety",
    "grade": 7
  },
  {
    "id": 6462,
    "traditional": "繁華",
    "simplified": "繁华",
    "reading": "fánhuá",
    "meaning": "flourishing, bustling, busy",
    "grade": 7
  },
  {
    "id": 6463,
    "traditional": "繁忙",
    "simplified": "繁忙",
    "reading": "fánmáng",
    "meaning": "busy",
    "grade": 7
  },
  {
    "id": 6464,
    "traditional": "繁體字",
    "simplified": "繁体字",
    "reading": "fántǐzì",
    "meaning": "traditional Chinese character",
    "grade": 7
  },
  {
    "id": 6465,
    "traditional": "繁重",
    "simplified": "繁重",
    "reading": "fánzhòng",
    "meaning": "heavy, burdensome, heavy-duty, arduous, onerous",
    "grade": 7
  },
  {
    "id": 6466,
    "traditional": "反駁",
    "simplified": "反驳",
    "reading": "fǎnbó",
    "meaning": "refute, retort, negate",
    "grade": 7
  },
  {
    "id": 6467,
    "traditional": "反差",
    "simplified": "反差",
    "reading": "fǎnchā",
    "meaning": "contrast, discrepancy",
    "grade": 7
  },
  {
    "id": 6468,
    "traditional": "反常",
    "simplified": "反常",
    "reading": "fǎncháng",
    "meaning": "unusual, abnormal",
    "grade": 7
  },
  {
    "id": 6469,
    "traditional": "反倒",
    "simplified": "反倒",
    "reading": "fǎndào",
    "meaning": "conj.: on the contrary, instead",
    "grade": 7
  },
  {
    "id": 6470,
    "traditional": "反感",
    "simplified": "反感",
    "reading": "fǎngǎn",
    "meaning": "to be disgusted with, to dislike, bad reaction, antipathy",
    "grade": 7
  },
  {
    "id": 6471,
    "traditional": "反過來",
    "simplified": "反过来",
    "reading": "fǎnguolai",
    "meaning": "conversely, in reverse order, in an opposite direction",
    "grade": 7
  },
  {
    "id": 6472,
    "traditional": "反擊",
    "simplified": "反击",
    "reading": "fǎnjī",
    "meaning": "to strike back, to beat back, to counterattack",
    "grade": 7
  },
  {
    "id": 6473,
    "traditional": "反饋",
    "simplified": "反馈",
    "reading": "fǎnkuì",
    "meaning": "to send back information, feedback",
    "grade": 7
  },
  {
    "id": 6474,
    "traditional": "反面",
    "simplified": "反面",
    "reading": "fǎnmiàn",
    "meaning": "reverse side, backside, the other side (of a problem etc), negative, bad",
    "grade": 7
  },
  {
    "id": 6475,
    "traditional": "反思",
    "simplified": "反思",
    "reading": "fǎnsī",
    "meaning": "to think back over sth, to review, to revisit, to rethink, reflection, reassessment",
    "grade": 7
  },
  {
    "id": 6476,
    "traditional": "反彈",
    "simplified": "反弹",
    "reading": "fǎntán",
    "meaning": "rebound",
    "grade": 7
  },
  {
    "id": 6477,
    "traditional": "反省",
    "simplified": "反省",
    "reading": "fǎnxǐng",
    "meaning": "introspect, self-questioning",
    "grade": 7
  },
  {
    "id": 6478,
    "traditional": "返還",
    "simplified": "返还",
    "reading": "fǎnhuán",
    "meaning": "restitution, return of something to its original owner, remittance",
    "grade": 7
  },
  {
    "id": 6479,
    "traditional": "犯愁",
    "simplified": "犯愁",
    "reading": "fàn chóu",
    "meaning": "to worry, to be anxious",
    "grade": 7
  },
  {
    "id": 6480,
    "traditional": "飯碗",
    "simplified": "饭碗",
    "reading": "fànwǎn",
    "meaning": "rice bowl, fig. livelihood, job, way of making a living",
    "grade": 7
  },
  {
    "id": 6481,
    "traditional": "泛濫",
    "simplified": "泛滥",
    "reading": "fànlàn",
    "meaning": "to be in flood, to overflow (the banks), to inundate, to spread unchecked",
    "grade": 7
  },
  {
    "id": 6482,
    "traditional": "範疇",
    "simplified": "范畴",
    "reading": "fànchóu",
    "meaning": "category",
    "grade": 7
  },
  {
    "id": 6483,
    "traditional": "販賣",
    "simplified": "贩卖",
    "reading": "fànmài",
    "meaning": "peddle, sell",
    "grade": 7
  },
  {
    "id": 6484,
    "traditional": "方方面面",
    "simplified": "方方面面",
    "reading": "fāngfāng-miànmiàn",
    "meaning": "all sides, all aspects, multi-faceted",
    "grade": 7
  },
  {
    "id": 6485,
    "traditional": "方向盤",
    "simplified": "方向盘",
    "reading": "fāngxiàngpán",
    "meaning": "steering wheel",
    "grade": 7
  },
  {
    "id": 6486,
    "traditional": "方言",
    "simplified": "方言",
    "reading": "fāngyán",
    "meaning": "the first Chinese dialect dictionary, edited by Yang Xiong 揚雄|扬雄[Yang2 Xiong2] in 1st century, containing over 9000 characters, topolect, dialect",
    "grade": 7
  },
  {
    "id": 6487,
    "traditional": "防盜",
    "simplified": "防盗",
    "reading": "fángdào",
    "meaning": "to guard against theft, anti-theft",
    "grade": 7
  },
  {
    "id": 6488,
    "traditional": "防盜門",
    "simplified": "防盗门",
    "reading": "fángdàomén",
    "meaning": "entrance door (for apartment)",
    "grade": 7
  },
  {
    "id": 6489,
    "traditional": "防護",
    "simplified": "防护",
    "reading": "fánghù",
    "meaning": "to defend, to protect",
    "grade": 7
  },
  {
    "id": 6490,
    "traditional": "防火牆",
    "simplified": "防火墙",
    "reading": "fánghuǒqiáng",
    "meaning": "firewall, CL:堵[du3]",
    "grade": 7
  },
  {
    "id": 6491,
    "traditional": "防衛",
    "simplified": "防卫",
    "reading": "fángwèi",
    "meaning": "defend",
    "grade": 7
  },
  {
    "id": 6492,
    "traditional": "防汛",
    "simplified": "防汛",
    "reading": "fángxùn",
    "meaning": "flood control, anti-flood (precautions)",
    "grade": 7
  },
  {
    "id": 6493,
    "traditional": "防疫",
    "simplified": "防疫",
    "reading": "fángyì",
    "meaning": "disease prevention, protection against epidemic",
    "grade": 7
  },
  {
    "id": 6494,
    "traditional": "防禦",
    "simplified": "防御",
    "reading": "fángyù",
    "meaning": "defense, to defend",
    "grade": 7
  },
  {
    "id": 6495,
    "traditional": "妨礙",
    "simplified": "妨碍",
    "reading": "fángài",
    "meaning": "preclude, clog, set_back, prevent, trammel, arrest, counteract, set back, impede, foul, hamper, discourage, thwart, obstruction, impediment, stymie, hinder, deter, debar, halter, intercept, interfere, retard, jam, detain, spoke, hobble, resist, balk, countercheck, stymy, blockade, stonewall, stop, beset, embarrass, hamstring, forbid, kibosh, handicap, block, prohibit, snag, fox, cramp, encumb",
    "grade": 7
  },
  {
    "id": 6496,
    "traditional": "房地產",
    "simplified": "房地产",
    "reading": "fángdìchǎn",
    "meaning": "blockbuster, real estate, realty, real_estate, real property, property",
    "grade": 7
  },
  {
    "id": 6497,
    "traditional": "妨害",
    "simplified": "妨害",
    "reading": "fánghài",
    "meaning": "traverse, impair, bar, cumber, be harmful to, trammel, encumber, sabotage, molest, obstruct, blanket, impairment, disturb, jeopardize, annoy, put in jeopardy",
    "grade": 7
  },
  {
    "id": 6498,
    "traditional": "仿",
    "simplified": "仿",
    "reading": "fǎng",
    "meaning": "seemingly",
    "grade": 7
  },
  {
    "id": 6499,
    "traditional": "仿製",
    "simplified": "仿制",
    "reading": "fǎngzhì",
    "meaning": "to copy, to imitate, to make by imitating a model",
    "grade": 7
  },
  {
    "id": 6500,
    "traditional": "訪談",
    "simplified": "访谈",
    "reading": "fǎngtán",
    "meaning": "interview",
    "grade": 7
  },
  {
    "id": 6501,
    "traditional": "紡織",
    "simplified": "纺织",
    "reading": "fǎngzhī",
    "meaning": "spinning and weaving, textile",
    "grade": 7
  },
  {
    "id": 6502,
    "traditional": "放過",
    "simplified": "放过",
    "reading": "fàngguò",
    "meaning": "let off, let slip, forgive, let off/slip, slip",
    "grade": 7
  },
  {
    "id": 6503,
    "traditional": "放水",
    "simplified": "放水",
    "reading": "fàng shuǐ",
    "meaning": "to turn on the water, to let water out, to throw a game (sports)",
    "grade": 7
  },
  {
    "id": 6504,
    "traditional": "放肆",
    "simplified": "放肆",
    "reading": "fàngsì",
    "meaning": "wanton, unbridled, presumptuous, impudent",
    "grade": 7
  },
  {
    "id": 6505,
    "traditional": "放映",
    "simplified": "放映",
    "reading": "fàngyìng",
    "meaning": "to show (a movie), to screen",
    "grade": 7
  },
  {
    "id": 6506,
    "traditional": "放置",
    "simplified": "放置",
    "reading": "fàngzhì",
    "meaning": "dispose, feed, set, locate, go, plant, pose, sit, lay, stick, shove, repose, bestow, sock, lay aside, perch, put, position, deposit, depose, get, lay up, mount, place, localize",
    "grade": 7
  },
  {
    "id": 6507,
    "traditional": "放縱",
    "simplified": "放纵",
    "reading": "fàngzòng",
    "meaning": "to indulge, to pamper, to connive at, permissive, indulgent, self-indulgent, unrestrained, undisciplined, uncultured, boorish",
    "grade": 7
  },
  {
    "id": 6508,
    "traditional": "飛速",
    "simplified": "飞速",
    "reading": "fēisù",
    "meaning": "flying speed, rapid (growth)",
    "grade": 7
  },
  {
    "id": 6509,
    "traditional": "飛往",
    "simplified": "飞往",
    "reading": "fēi wǎng",
    "meaning": "Go to",
    "grade": 7
  },
  {
    "id": 6510,
    "traditional": "飛翔",
    "simplified": "飞翔",
    "reading": "fēixiáng",
    "meaning": "to circle in the air, to soar",
    "grade": 7
  },
  {
    "id": 6511,
    "traditional": "飛躍",
    "simplified": "飞跃",
    "reading": "fēiyuè",
    "meaning": "to leap",
    "grade": 7
  },
  {
    "id": 6512,
    "traditional": "非",
    "simplified": "非",
    "reading": "fēi",
    "meaning": "not",
    "grade": 7
  },
  {
    "id": 6513,
    "traditional": "非得",
    "simplified": "非得",
    "reading": "fēiděi",
    "meaning": "(followed by a verb phrase, then – optionally – 不可, or 不行 etc) must",
    "grade": 7
  },
  {
    "id": 6514,
    "traditional": "非法",
    "simplified": "非法",
    "reading": "fēifǎ",
    "meaning": "illegal, wrongfully, wrongful, unlawful, illegitimately, illicitly, unlawfully, lawlessly, nefariously, nefarious, illegally, illegitimate, lawless, illicit",
    "grade": 7
  },
  {
    "id": 6515,
    "traditional": "肥料",
    "simplified": "肥料",
    "reading": "féiliào",
    "meaning": "fertilizer, manure",
    "grade": 7
  },
  {
    "id": 6516,
    "traditional": "非凡",
    "simplified": "非凡",
    "reading": "fēifán",
    "meaning": "out of the ordinary, unusually (good, talented etc)",
    "grade": 7
  },
  {
    "id": 6517,
    "traditional": "肥胖",
    "simplified": "肥胖",
    "reading": "féipàng",
    "meaning": "fat, corpulent",
    "grade": 7
  },
  {
    "id": 6518,
    "traditional": "緋聞",
    "simplified": "绯闻",
    "reading": "fēiwén",
    "meaning": "sex scandal",
    "grade": 7
  },
  {
    "id": 6519,
    "traditional": "肥沃",
    "simplified": "肥沃",
    "reading": "féiwò",
    "meaning": "fertile",
    "grade": 7
  },
  {
    "id": 6520,
    "traditional": "肥皂",
    "simplified": "肥皂",
    "reading": "féizào",
    "meaning": "soap, CL:塊|块[kuai4],條|条[tiao2]",
    "grade": 7
  },
  {
    "id": 6521,
    "traditional": "誹謗",
    "simplified": "诽谤",
    "reading": "fěibàng",
    "meaning": "to slander, to libel",
    "grade": 7
  },
  {
    "id": 6522,
    "traditional": "廢",
    "simplified": "废",
    "reading": "fèi",
    "meaning": "variant of 廢|废[fei4], disabled",
    "grade": 7
  },
  {
    "id": 6523,
    "traditional": "廢除",
    "simplified": "废除",
    "reading": "fèichú",
    "meaning": "abolish, abrogate, repeal",
    "grade": 7
  },
  {
    "id": 6524,
    "traditional": "廢話",
    "simplified": "废话",
    "reading": "fèihuà",
    "meaning": "nonsense, rubbish, superfluous words, You don't say!, No kidding! (gently sarcastic)",
    "grade": 7
  },
  {
    "id": 6525,
    "traditional": "廢品",
    "simplified": "废品",
    "reading": "fèipǐn",
    "meaning": "production rejects, seconds, scrap, discarded material",
    "grade": 7
  },
  {
    "id": 6526,
    "traditional": "廢寢忘食",
    "simplified": "废寝忘食",
    "reading": "fèiqǐn-wàngshí",
    "meaning": "to neglect sleep and forget about food (idiom), to skip one's sleep and meals, to be completely wrapped up in one's work",
    "grade": 7
  },
  {
    "id": 6527,
    "traditional": "廢物",
    "simplified": "废物",
    "reading": "fèiwu",
    "meaning": "crap, plug, wastage, dreg, crock, garbage, weed, riffraff, obsolete, ejecta, trashery, rubbish, trash, cur, chaff, offal, food waste, recrement, rejectamenta, waste matter, lumber, sullage, write-off, waste material, sordes, dud, good-for-nothing, black sheep, culch, ruck, wastefulness, slag, scapegrace, dissipation, cultch, raffle, flotsam, dreck, off-scum, no-account, waste, offscouring",
    "grade": 7
  },
  {
    "id": 6528,
    "traditional": "廢墟",
    "simplified": "废墟",
    "reading": "fèixū",
    "meaning": "ruins",
    "grade": 7
  },
  {
    "id": 6529,
    "traditional": "沸沸揚揚",
    "simplified": "沸沸扬扬",
    "reading": "fèifèi-yángyáng",
    "meaning": "bubbling and gurgling, hubbubing, abuzz",
    "grade": 7
  },
  {
    "id": 6530,
    "traditional": "沸騰",
    "simplified": "沸腾",
    "reading": "fèiténg",
    "meaning": "(of a liquid) to boil, (of sentiments etc) to boil over, to flare up, to be impassioned",
    "grade": 7
  },
  {
    "id": 6531,
    "traditional": "分辨",
    "simplified": "分辨",
    "reading": "fēnbiàn",
    "meaning": "distinguish, differentiate",
    "grade": 7
  },
  {
    "id": 6532,
    "traditional": "費勁",
    "simplified": "费劲",
    "reading": "fèi jìn",
    "meaning": "to require effort, strenuous",
    "grade": 7
  },
  {
    "id": 6533,
    "traditional": "分寸",
    "simplified": "分寸",
    "reading": "fēncun",
    "meaning": "propriety, appropriate behavior, proper speech or action, within the norms",
    "grade": 7
  },
  {
    "id": 6534,
    "traditional": "分擔",
    "simplified": "分担",
    "reading": "fēndān",
    "meaning": "to share (a burden, a cost, a responsibility)",
    "grade": 7
  },
  {
    "id": 6535,
    "traditional": "分割",
    "simplified": "分割",
    "reading": "fēngē",
    "meaning": "cut apart, break up, partition",
    "grade": 7
  },
  {
    "id": 6536,
    "traditional": "分紅",
    "simplified": "分红",
    "reading": "fēn hóng",
    "meaning": "dividend, to award a bonus",
    "grade": 7
  },
  {
    "id": 6537,
    "traditional": "分化",
    "simplified": "分化",
    "reading": "fēnhuà",
    "meaning": "disintegrate",
    "grade": 7
  },
  {
    "id": 6538,
    "traditional": "分泌",
    "simplified": "分泌",
    "reading": "fēnmì",
    "meaning": "to secrete, secretion",
    "grade": 7
  },
  {
    "id": 6539,
    "traditional": "分明",
    "simplified": "分明",
    "reading": "fēnmíng",
    "meaning": "clear, distinct, evidently, clearly",
    "grade": 7
  },
  {
    "id": 6540,
    "traditional": "分歧",
    "simplified": "分歧",
    "reading": "fēnqí",
    "meaning": "divergent, difference (of opinion, position), disagreement, bifurcation (math.)",
    "grade": 7
  },
  {
    "id": 6541,
    "traditional": "分贓",
    "simplified": "分赃",
    "reading": "fēn zāng",
    "meaning": "to share the booty, to divide ill-gotten gains",
    "grade": 7
  },
  {
    "id": 6542,
    "traditional": "分支",
    "simplified": "分支",
    "reading": "fēnzhī",
    "meaning": "branch (of company, river etc), to branch, to diverge, to ramify, to subdivide",
    "grade": 7
  },
  {
    "id": 6543,
    "traditional": "芬芳",
    "simplified": "芬芳",
    "reading": "fēnfāng",
    "meaning": "perfume, fragrant",
    "grade": 7
  },
  {
    "id": 6544,
    "traditional": "氛圍",
    "simplified": "氛围",
    "reading": "fēnwéi",
    "meaning": "ambience, atmosphere",
    "grade": 7
  },
  {
    "id": 6545,
    "traditional": "吩咐",
    "simplified": "吩咐",
    "reading": "fēnfu",
    "meaning": "to tell, to instruct, to command",
    "grade": 7
  },
  {
    "id": 6546,
    "traditional": "墳",
    "simplified": "坟",
    "reading": "fén",
    "meaning": "grave, tomb, CL:座[zuo4], embankment, mound, ancient book",
    "grade": 7
  },
  {
    "id": 6547,
    "traditional": "墳墓",
    "simplified": "坟墓",
    "reading": "fénmù",
    "meaning": "grave, tomb",
    "grade": 7
  },
  {
    "id": 6548,
    "traditional": "焚燒",
    "simplified": "焚烧",
    "reading": "fénshāo",
    "meaning": "to burn, to set on fire",
    "grade": 7
  },
  {
    "id": 6549,
    "traditional": "粉",
    "simplified": "粉",
    "reading": "fěn",
    "meaning": "powder, cosmetic face powder, food prepared from starch, noodles or pasta made from any kind of flour, whitewash, white, pink",
    "grade": 7
  },
  {
    "id": 6550,
    "traditional": "粉絲",
    "simplified": "粉丝",
    "reading": "fěnsī",
    "meaning": "bean vermicelli, mung bean starch noodles, Chinese vermicelli, cellophane noodles, CL:把[ba3], fan (loanword), enthusiast for sb or sth",
    "grade": 7
  },
  {
    "id": 6551,
    "traditional": "粉碎",
    "simplified": "粉碎",
    "reading": "fěnsuì",
    "meaning": "to crush, to smash, to shatter",
    "grade": 7
  },
  {
    "id": 6552,
    "traditional": "分量",
    "simplified": "分量",
    "reading": "fènliang",
    "meaning": "(vector) component, quantity, weight, measure",
    "grade": 7
  },
  {
    "id": 6553,
    "traditional": "分外",
    "simplified": "分外",
    "reading": "fènwài",
    "meaning": "exceptionally, not one's responsibility or job",
    "grade": 7
  },
  {
    "id": 6554,
    "traditional": "份額",
    "simplified": "份额",
    "reading": "fèné",
    "meaning": "share, portion",
    "grade": 7
  },
  {
    "id": 6555,
    "traditional": "奮力",
    "simplified": "奋力",
    "reading": "fènlì",
    "meaning": "to do everything one can, to spare no effort, to strive",
    "grade": 7
  },
  {
    "id": 6556,
    "traditional": "奮勇",
    "simplified": "奋勇",
    "reading": "fènyǒng",
    "meaning": "dauntless, to summon up courage and determination, using extreme force of will",
    "grade": 7
  },
  {
    "id": 6557,
    "traditional": "糞",
    "simplified": "粪",
    "reading": "fèn",
    "meaning": "manure, dung",
    "grade": 7
  },
  {
    "id": 6558,
    "traditional": "糞便",
    "simplified": "粪便",
    "reading": "fènbiàn",
    "meaning": "excrement, feces, night soil",
    "grade": 7
  },
  {
    "id": 6559,
    "traditional": "豐富多彩",
    "simplified": "丰富多彩",
    "reading": "fēngfù-duōcǎi",
    "meaning": "richly colorful",
    "grade": 7
  },
  {
    "id": 6560,
    "traditional": "豐厚",
    "simplified": "丰厚",
    "reading": "fēnghòu",
    "meaning": "generous, ample",
    "grade": 7
  },
  {
    "id": 6561,
    "traditional": "豐滿",
    "simplified": "丰满",
    "reading": "fēngmǎn",
    "meaning": "Fengman district of Jilin city 吉林市, Jilin province, ample, well developed, fully rounded",
    "grade": 7
  },
  {
    "id": 6562,
    "traditional": "豐碩",
    "simplified": "丰硕",
    "reading": "fēngshuò",
    "meaning": "plentiful, substantial, rich (in resources etc)",
    "grade": 7
  },
  {
    "id": 6563,
    "traditional": "豐盛",
    "simplified": "丰盛",
    "reading": "fēngshèng",
    "meaning": "rich, sumptuous",
    "grade": 7
  },
  {
    "id": 6564,
    "traditional": "風波",
    "simplified": "风波",
    "reading": "fēngbō",
    "meaning": "crisis, disturbance",
    "grade": 7
  },
  {
    "id": 6565,
    "traditional": "風采",
    "simplified": "风采",
    "reading": "fēngcǎi",
    "meaning": "carriage, graceful bearing, colour, mien, elegant demeanour, literary grace, elegant demeanor",
    "grade": 7
  },
  {
    "id": 6566,
    "traditional": "風餐露宿",
    "simplified": "风餐露宿",
    "reading": "fēngcān-lùsù",
    "meaning": "Wild meal",
    "grade": 7
  },
  {
    "id": 6567,
    "traditional": "風和日麗",
    "simplified": "风和日丽",
    "reading": "fēnghé-rìlì",
    "meaning": "moderate wind, beautiful sun (idiom), fine sunny weather, esp. in springtime",
    "grade": 7
  },
  {
    "id": 6568,
    "traditional": "風風雨雨",
    "simplified": "风风雨雨",
    "reading": "fēngfēng-yǔyǔ",
    "meaning": "Wind and rain",
    "grade": 7
  },
  {
    "id": 6569,
    "traditional": "風浪",
    "simplified": "风浪",
    "reading": "fēnglàng",
    "meaning": "wind and waves, stormy sea",
    "grade": 7
  },
  {
    "id": 6570,
    "traditional": "風力",
    "simplified": "风力",
    "reading": "fēnglì",
    "meaning": "wind force, wind power",
    "grade": 7
  },
  {
    "id": 6571,
    "traditional": "風範",
    "simplified": "风范",
    "reading": "fēngfàn",
    "meaning": "air, manner, model, paragon, demeanor",
    "grade": 7
  },
  {
    "id": 6572,
    "traditional": "風流",
    "simplified": "风流",
    "reading": "fēngliú",
    "meaning": "distinguished and accomplished, outstanding, talented in letters and unconventional in lifestyle, romantic, dissolute, loose",
    "grade": 7
  },
  {
    "id": 6573,
    "traditional": "風貌",
    "simplified": "风貌",
    "reading": "fēngmào",
    "meaning": "view, scene, elegant appearance and bearing, appearance, style and features, style and feature",
    "grade": 7
  },
  {
    "id": 6574,
    "traditional": "風氣",
    "simplified": "风气",
    "reading": "fēngqì",
    "meaning": "mores, ethos, order_of_the_day, climate, established practice, genius, genius loci, atmosphere, tone, general mood, common practice, fashion",
    "grade": 7
  },
  {
    "id": 6575,
    "traditional": "風情",
    "simplified": "风情",
    "reading": "fēngqíng",
    "meaning": "demeanour, flavor, information about wind, local customs, bearing, feelings, flirtatious expressions, amorous feelings",
    "grade": 7
  },
  {
    "id": 6576,
    "traditional": "風尚",
    "simplified": "风尚",
    "reading": "fēngshàng",
    "meaning": "current custom, current way of doing things",
    "grade": 7
  },
  {
    "id": 6577,
    "traditional": "風趣",
    "simplified": "风趣",
    "reading": "fēngqù",
    "meaning": "charm, humor, wit, humorous, witty",
    "grade": 7
  },
  {
    "id": 6578,
    "traditional": "風沙",
    "simplified": "风沙",
    "reading": "fēngshā",
    "meaning": "sand blown by wind, sandstorm",
    "grade": 7
  },
  {
    "id": 6579,
    "traditional": "風水",
    "simplified": "风水",
    "reading": "fēngshui",
    "meaning": "feng shui, geomancy",
    "grade": 7
  },
  {
    "id": 6580,
    "traditional": "風味",
    "simplified": "风味",
    "reading": "fēngwèi",
    "meaning": "race, gust, special flavour, savor, flavor, savour, local color, relish, sapor, spice, tasting, local colour, smack, gusto, snack, savoriness, special flavor, flavorsomeness, zest, taste",
    "grade": 7
  },
  {
    "id": 6581,
    "traditional": "風雨",
    "simplified": "风雨",
    "reading": "fēngyǔ",
    "meaning": "wind and rain, the elements, trials and hardships",
    "grade": 7
  },
  {
    "id": 6582,
    "traditional": "風雲",
    "simplified": "风云",
    "reading": "fēngyún",
    "meaning": "weather, unstable situation",
    "grade": 7
  },
  {
    "id": 6583,
    "traditional": "風箏",
    "simplified": "风筝",
    "reading": "fēngzheng",
    "meaning": "kite",
    "grade": 7
  },
  {
    "id": 6584,
    "traditional": "封頂",
    "simplified": "封顶",
    "reading": "fēng dǐng",
    "meaning": "to put a roof (on a building), to cap the roof (finishing a building project), fig. to put a ceiling (on spending, prize, ambition etc), to top off, fig. to reach the highest point (of growth, profit, interest rates), to stop growing (of plant bud or branch)",
    "grade": 7
  },
  {
    "id": 6585,
    "traditional": "封面",
    "simplified": "封面",
    "reading": "fēngmiàn",
    "meaning": "lid, book binding, title page of thread-bound book, front cover, front and back cover of book, back, cover, binding, title page",
    "grade": 7
  },
  {
    "id": 6586,
    "traditional": "封建",
    "simplified": "封建",
    "reading": "fēngjiàn",
    "meaning": "system of enfeoffment, feudalism, feudal, feudalistic",
    "grade": 7
  },
  {
    "id": 6587,
    "traditional": "封鎖",
    "simplified": "封锁",
    "reading": "fēngsuǒ",
    "meaning": "to blockade, to seal off",
    "grade": 7
  },
  {
    "id": 6588,
    "traditional": "峰迴路轉",
    "simplified": "峰回路转",
    "reading": "fēnghuí-lùzhuǎn",
    "meaning": "the mountain road twists around each new peak (idiom), (of a mountain road) twisting and turning, fig. an opportunity has come unexpectedly, things have taken a new turn",
    "grade": 7
  },
  {
    "id": 6589,
    "traditional": "瘋子",
    "simplified": "疯子",
    "reading": "fēngzi",
    "meaning": "madman, lunatic",
    "grade": 7
  },
  {
    "id": 6590,
    "traditional": "蜂蜜",
    "simplified": "蜂蜜",
    "reading": "fēngmì",
    "meaning": "honey",
    "grade": 7
  },
  {
    "id": 6591,
    "traditional": "逢",
    "simplified": "逢",
    "reading": "féng",
    "meaning": "meet, come upon",
    "grade": 7
  },
  {
    "id": 6592,
    "traditional": "縫",
    "simplified": "缝",
    "reading": "fèng",
    "meaning": "to sew, to stitch, seam, crack, narrow slit, CL:道[dao4]",
    "grade": 7
  },
  {
    "id": 6593,
    "traditional": "縫合",
    "simplified": "缝合",
    "reading": "fénghé",
    "meaning": "to sew together, suture (in surgery), to sew up (a wound)",
    "grade": 7
  },
  {
    "id": 6594,
    "traditional": "諷刺",
    "simplified": "讽刺",
    "reading": "fěngcì",
    "meaning": "satirize, mock",
    "grade": 7
  },
  {
    "id": 6595,
    "traditional": "鳳凰",
    "simplified": "凤凰",
    "reading": "fènghuáng",
    "meaning": "Fenghuang county in Xiangxi Tujia and Miao autonomous prefecture 湘西土家族苗族自治州[Xiang1 xi1 Tu3 jia1 zu2 Miao2 zu2 zi4 zhi4 zhou1], phoenix",
    "grade": 7
  },
  {
    "id": 6596,
    "traditional": "孵化",
    "simplified": "孵化",
    "reading": "fūhuà",
    "meaning": "breeding, to incubate, innovation (esp. in commerce and marketing)",
    "grade": 7
  },
  {
    "id": 6597,
    "traditional": "否決",
    "simplified": "否决",
    "reading": "fǒujué",
    "meaning": "to veto, to overrule",
    "grade": 7
  },
  {
    "id": 6598,
    "traditional": "扶持",
    "simplified": "扶持",
    "reading": "fúchí",
    "meaning": "to help, to assist",
    "grade": 7
  },
  {
    "id": 6599,
    "traditional": "敷",
    "simplified": "敷",
    "reading": "fū",
    "meaning": "to spread, to lay out, to apply (powder, ointment etc), sufficient (to cover), enough",
    "grade": 7
  },
  {
    "id": 6600,
    "traditional": "服飾",
    "simplified": "服饰",
    "reading": "fúshì",
    "meaning": "neckline, attire, furnishings, costume, dress, trappings, finery, rig-out, apparel, dress and personal adornment",
    "grade": 7
  },
  {
    "id": 6601,
    "traditional": "服務器",
    "simplified": "服务器",
    "reading": "fúwùqì",
    "meaning": "server (computer), CL:臺|台[tai2]",
    "grade": 7
  },
  {
    "id": 6602,
    "traditional": "服用",
    "simplified": "服用",
    "reading": "fúyòng",
    "meaning": "take, use, take (medicine)",
    "grade": 7
  },
  {
    "id": 6603,
    "traditional": "俘獲",
    "simplified": "俘获",
    "reading": "fúhuò",
    "meaning": "to capture (enemy property or personnel), capture (physics: absorption of subatomic particle by an atom or nucleus)",
    "grade": 7
  },
  {
    "id": 6604,
    "traditional": "俘虜",
    "simplified": "俘虏",
    "reading": "fúlǔ",
    "meaning": "captive",
    "grade": 7
  },
  {
    "id": 6605,
    "traditional": "浮力",
    "simplified": "浮力",
    "reading": "fúlì",
    "meaning": "buoyancy",
    "grade": 7
  },
  {
    "id": 6606,
    "traditional": "浮現",
    "simplified": "浮现",
    "reading": "fúxiàn",
    "meaning": "appear before one's eyes, float, suggest, come up, rise up, surface, ray, rise, appear, emerge",
    "grade": 7
  },
  {
    "id": 6607,
    "traditional": "浮躁",
    "simplified": "浮躁",
    "reading": "fúzào",
    "meaning": "fickle and impatient, restless, giddy, scatterbrained",
    "grade": 7
  },
  {
    "id": 6608,
    "traditional": "輻射",
    "simplified": "辐射",
    "reading": "fúshè",
    "meaning": "radiancy, Geigers, radiation, radiant",
    "grade": 7
  },
  {
    "id": 6609,
    "traditional": "福氣",
    "simplified": "福气",
    "reading": "fúqi",
    "meaning": "good fortune, a blessing",
    "grade": 7
  },
  {
    "id": 6610,
    "traditional": "撫摸",
    "simplified": "抚摸",
    "reading": "fǔmō",
    "meaning": "to gently caress and stroke, to pet, to fondle",
    "grade": 7
  },
  {
    "id": 6611,
    "traditional": "撫恤",
    "simplified": "抚恤",
    "reading": "fǔxù",
    "meaning": "(of an organization that has a duty of care) to give financial support to relatives of sb who has died or suffered serious injury",
    "grade": 7
  },
  {
    "id": 6612,
    "traditional": "撫養",
    "simplified": "抚养",
    "reading": "fǔyǎng",
    "meaning": "to foster, to bring up, to raise",
    "grade": 7
  },
  {
    "id": 6613,
    "traditional": "撫養費",
    "simplified": "抚养费",
    "reading": "fǔyǎngfèi",
    "meaning": "child support payment (after a divorce)",
    "grade": 7
  },
  {
    "id": 6614,
    "traditional": "斧子",
    "simplified": "斧子",
    "reading": "fǔzi",
    "meaning": "axe, hatchet, CL:把[ba3]",
    "grade": 7
  },
  {
    "id": 6615,
    "traditional": "俯首",
    "simplified": "俯首",
    "reading": "fǔshǒu",
    "meaning": "to bend one's head",
    "grade": 7
  },
  {
    "id": 6616,
    "traditional": "輔導",
    "simplified": "辅导",
    "reading": "fǔdǎo",
    "meaning": "coach, tutor",
    "grade": 7
  },
  {
    "id": 6617,
    "traditional": "腐敗",
    "simplified": "腐败",
    "reading": "fǔbài",
    "meaning": "rotten, putrid, decayed, corrupt",
    "grade": 7
  },
  {
    "id": 6618,
    "traditional": "腐化",
    "simplified": "腐化",
    "reading": "fǔhuà",
    "meaning": "to rot, to decay, to become corrupt",
    "grade": 7
  },
  {
    "id": 6619,
    "traditional": "腐爛",
    "simplified": "腐烂",
    "reading": "fǔlàn",
    "meaning": "to rot, to putrefy, (fig.) corrupt",
    "grade": 7
  },
  {
    "id": 6620,
    "traditional": "腐蝕",
    "simplified": "腐蚀",
    "reading": "fǔshí",
    "meaning": "corrosion, to corrode (degrade chemically), to rot, corruption",
    "grade": 7
  },
  {
    "id": 6621,
    "traditional": "腐朽",
    "simplified": "腐朽",
    "reading": "fǔxiǔ",
    "meaning": "rotten, decayed, decadent, degenerate",
    "grade": 7
  },
  {
    "id": 6622,
    "traditional": "付費",
    "simplified": "付费",
    "reading": "fù fèi",
    "meaning": "fee, expense, pay",
    "grade": 7
  },
  {
    "id": 6623,
    "traditional": "負面",
    "simplified": "负面",
    "reading": "fùmiàn",
    "meaning": "negative, negativeness, reverse (side), reverse",
    "grade": 7
  },
  {
    "id": 6624,
    "traditional": "付款",
    "simplified": "付款",
    "reading": "fù kuǎn",
    "meaning": "ante_up, ante up, foot, pay out, pay up, pay, shell_out, cash, pay a sum of money, disburse",
    "grade": 7
  },
  {
    "id": 6625,
    "traditional": "負有",
    "simplified": "负有",
    "reading": "fùyǒu",
    "meaning": "to be responsible for",
    "grade": 7
  },
  {
    "id": 6626,
    "traditional": "附",
    "simplified": "附",
    "reading": "fù",
    "meaning": "comply with, agree to, appose, attachment, enclose, get close to, enclosure, depend on, add, be near, rely on, attach",
    "grade": 7
  },
  {
    "id": 6627,
    "traditional": "附帶",
    "simplified": "附带",
    "reading": "fùdài",
    "meaning": "supplementary, incidentally, in parentheses, by chance, in passing, additionally, secondary, subsidiary, to attach",
    "grade": 7
  },
  {
    "id": 6628,
    "traditional": "附和",
    "simplified": "附和",
    "reading": "fùhè",
    "meaning": "to agree, to go along with, to echo (what sb says)",
    "grade": 7
  },
  {
    "id": 6629,
    "traditional": "附加",
    "simplified": "附加",
    "reading": "fùjiā",
    "meaning": "hang on, supervene, suffix, additional, affix, superimpose, inset, tack on, tack, attachment, tag on, append, add, subjoin, annex, engraft, attach",
    "grade": 7
  },
  {
    "id": 6630,
    "traditional": "附屬",
    "simplified": "附属",
    "reading": "fùshǔ",
    "meaning": "adjunct, belong, pertain, appertain, affiliated, be attached, attached, adjective, auxiliary, be affiliated, subsidiary",
    "grade": 7
  },
  {
    "id": 6631,
    "traditional": "復查",
    "simplified": "复查",
    "reading": "fùchá",
    "meaning": "rechecking, re-inspection, reexamination",
    "grade": 7
  },
  {
    "id": 6632,
    "traditional": "赴",
    "simplified": "赴",
    "reading": "fù",
    "meaning": "repair, attendance, go, go to, come, seek, attend, betake",
    "grade": 7
  },
  {
    "id": 6633,
    "traditional": "復發",
    "simplified": "复发",
    "reading": "fùfā",
    "meaning": "to recur (of a disease), to reappear, to relapse (into a former bad state)",
    "grade": 7
  },
  {
    "id": 6634,
    "traditional": "複合",
    "simplified": "复合",
    "reading": "fùhé",
    "meaning": "complex, compound, composite, hybrid",
    "grade": 7
  },
  {
    "id": 6635,
    "traditional": "復活",
    "simplified": "复活",
    "reading": "fùhuó",
    "meaning": "resurrection",
    "grade": 7
  },
  {
    "id": 6636,
    "traditional": "復興",
    "simplified": "复兴",
    "reading": "fùxīng",
    "meaning": "Fuxing district of Handan city 邯鄲市|邯郸市[Han2 dan1 shi4], Hebei, Fuxing or Fuhsing township in Taoyuan county 桃園縣|桃园县[Tao2 yuan2 xian4], north Taiwan, to revive, to rejuvenate",
    "grade": 7
  },
  {
    "id": 6637,
    "traditional": "復原",
    "simplified": "复原",
    "reading": "fù yuán",
    "meaning": "to restore (sth) to (its) former condition, to recover from illness, recovery",
    "grade": 7
  },
  {
    "id": 6638,
    "traditional": "副作用",
    "simplified": "副作用",
    "reading": "fùzuòyòng",
    "meaning": "side-effect",
    "grade": 7
  },
  {
    "id": 6639,
    "traditional": "富含",
    "simplified": "富含",
    "reading": "fùhán",
    "meaning": "Rich",
    "grade": 7
  },
  {
    "id": 6640,
    "traditional": "賦予",
    "simplified": "赋予",
    "reading": "fùyǔ",
    "meaning": "indue, endow, lend, girt, gift, endue, entrust, inspire, give, impart, confer, grant",
    "grade": 7
  },
  {
    "id": 6641,
    "traditional": "富豪",
    "simplified": "富豪",
    "reading": "fùháo",
    "meaning": "rich and powerful person",
    "grade": 7
  },
  {
    "id": 6642,
    "traditional": "富強",
    "simplified": "富强",
    "reading": "fùqiáng",
    "meaning": "rich and powerful",
    "grade": 7
  },
  {
    "id": 6643,
    "traditional": "富翁",
    "simplified": "富翁",
    "reading": "fùwēng",
    "meaning": "rich person, millionaire, billionaire",
    "grade": 7
  },
  {
    "id": 6644,
    "traditional": "富裕",
    "simplified": "富裕",
    "reading": "fùyù",
    "meaning": "plentiful, abundant, rich",
    "grade": 7
  },
  {
    "id": 6645,
    "traditional": "腹部",
    "simplified": "腹部",
    "reading": "fùbù",
    "meaning": "abdomen, belly, flank",
    "grade": 7
  },
  {
    "id": 6646,
    "traditional": "腹瀉",
    "simplified": "腹泻",
    "reading": "fùxiè",
    "meaning": "diarrhea, to have the runs",
    "grade": 7
  },
  {
    "id": 6647,
    "traditional": "富足",
    "simplified": "富足",
    "reading": "fùzú",
    "meaning": "rich, plentiful",
    "grade": 7
  },
  {
    "id": 6648,
    "traditional": "覆蓋",
    "simplified": "覆盖",
    "reading": "fùgài",
    "meaning": "to cover",
    "grade": 7
  },
  {
    "id": 6649,
    "traditional": "該",
    "simplified": "该",
    "reading": "gāi",
    "meaning": "det.: this, that",
    "grade": 7
  },
  {
    "id": 6650,
    "traditional": "改版",
    "simplified": "改版",
    "reading": "gǎi bǎn",
    "meaning": "to revise the current edition, revised edition",
    "grade": 7
  },
  {
    "id": 6651,
    "traditional": "改編",
    "simplified": "改编",
    "reading": "gǎibiān",
    "meaning": "adapt, rearrange, revise",
    "grade": 7
  },
  {
    "id": 6652,
    "traditional": "改革開放",
    "simplified": "改革开放",
    "reading": "gǎigé-kāifàng",
    "meaning": "to reform and open to the outside world, refers to Deng Xiaoping's policies from around 1980",
    "grade": 7
  },
  {
    "id": 6653,
    "traditional": "改動",
    "simplified": "改动",
    "reading": "gǎidòng",
    "meaning": "to alter, to modify, to revise",
    "grade": 7
  },
  {
    "id": 6654,
    "traditional": "改良",
    "simplified": "改良",
    "reading": "gǎiliáng",
    "meaning": "improve, ameliorate",
    "grade": 7
  },
  {
    "id": 6655,
    "traditional": "改名",
    "simplified": "改名",
    "reading": "gǎimíng",
    "meaning": "to change one's name",
    "grade": 7
  },
  {
    "id": 6656,
    "traditional": "改日",
    "simplified": "改日",
    "reading": "gǎirì",
    "meaning": "another day, some other day",
    "grade": 7
  },
  {
    "id": 6657,
    "traditional": "改為",
    "simplified": "改为",
    "reading": "gǎiwéi",
    "meaning": "change ... into, change to",
    "grade": 7
  },
  {
    "id": 6658,
    "traditional": "改邪歸正",
    "simplified": "改邪归正",
    "reading": "gǎixié-guīzhèng",
    "meaning": "to mend one's ways (idiom), to turn over a new leaf",
    "grade": 7
  },
  {
    "id": 6659,
    "traditional": "鈣",
    "simplified": "钙",
    "reading": "gài",
    "meaning": "atomic number 20, calcium, Ca, calcic",
    "grade": 7
  },
  {
    "id": 6660,
    "traditional": "蓋子",
    "simplified": "盖子",
    "reading": "gàizi",
    "meaning": "cover, lid, shell",
    "grade": 7
  },
  {
    "id": 6661,
    "traditional": "概況",
    "simplified": "概况",
    "reading": "gàikuàng",
    "meaning": "general situation, summary",
    "grade": 7
  },
  {
    "id": 6662,
    "traditional": "概率",
    "simplified": "概率",
    "reading": "gàilǜ",
    "meaning": "probability (math.)",
    "grade": 7
  },
  {
    "id": 6663,
    "traditional": "概論",
    "simplified": "概论",
    "reading": "gàilùn",
    "meaning": "outline, introduction, survey, general discussion",
    "grade": 7
  },
  {
    "id": 6664,
    "traditional": "干戈",
    "simplified": "干戈",
    "reading": "gāngē",
    "meaning": "weapons of war, arms",
    "grade": 7
  },
  {
    "id": 6665,
    "traditional": "乾旱",
    "simplified": "干旱",
    "reading": "gānhàn",
    "meaning": "drought, arid, dry",
    "grade": 7
  },
  {
    "id": 6666,
    "traditional": "乾燥",
    "simplified": "干燥",
    "reading": "gānzào",
    "meaning": "dry, arid",
    "grade": 7
  },
  {
    "id": 6667,
    "traditional": "甘心",
    "simplified": "甘心",
    "reading": "gānxīn",
    "meaning": "be reconciled, be willing, pleased, resigned to, be content with, be reconciled to, be reconciled/resigned to, be resigned to, resign oneself to",
    "grade": 7
  },
  {
    "id": 6668,
    "traditional": "尷尬",
    "simplified": "尴尬",
    "reading": "gāngà",
    "meaning": "awkward, embarrassed",
    "grade": 7
  },
  {
    "id": 6669,
    "traditional": "肝臟",
    "simplified": "肝脏",
    "reading": "gānzàng",
    "meaning": "liver",
    "grade": 7
  },
  {
    "id": 6670,
    "traditional": "趕赴",
    "simplified": "赶赴",
    "reading": "gǎnfù",
    "meaning": "to hurry, to rush",
    "grade": 7
  },
  {
    "id": 6671,
    "traditional": "趕往",
    "simplified": "赶往",
    "reading": "gǎnwǎng",
    "meaning": "to hurry to (somewhere)",
    "grade": 7
  },
  {
    "id": 6672,
    "traditional": "敢情",
    "simplified": "敢情",
    "reading": "gǎnqing",
    "meaning": "actually, as it turns out, indeed, of course",
    "grade": 7
  },
  {
    "id": 6673,
    "traditional": "感",
    "simplified": "感",
    "reading": "gǎn",
    "meaning": "feel, sense, move, touch, be affected (by cold)",
    "grade": 7
  },
  {
    "id": 6674,
    "traditional": "感觸",
    "simplified": "感触",
    "reading": "gǎnchù",
    "meaning": "feel, texture, sentiment, thoughts and feelings, feeling, tactile property",
    "grade": 7
  },
  {
    "id": 6675,
    "traditional": "感恩",
    "simplified": "感恩",
    "reading": "gǎn ēn",
    "meaning": "to be grateful",
    "grade": 7
  },
  {
    "id": 6676,
    "traditional": "感激",
    "simplified": "感激",
    "reading": "gǎnjī",
    "meaning": "feel grateful/indebted",
    "grade": 7
  },
  {
    "id": 6677,
    "traditional": "感慨",
    "simplified": "感慨",
    "reading": "gǎnkǎi",
    "meaning": "sigh with emotion",
    "grade": 7
  },
  {
    "id": 6678,
    "traditional": "感染",
    "simplified": "感染",
    "reading": "gǎnrǎn",
    "meaning": "infect, influence, affect",
    "grade": 7
  },
  {
    "id": 6679,
    "traditional": "感染力",
    "simplified": "感染力",
    "reading": "gǎnrǎnlì",
    "meaning": "inspiration, infectious (enthusiasm)",
    "grade": 7
  },
  {
    "id": 6680,
    "traditional": "感嘆",
    "simplified": "感叹",
    "reading": "gǎntàn",
    "meaning": "to sigh (with feeling), to lament",
    "grade": 7
  },
  {
    "id": 6681,
    "traditional": "感性",
    "simplified": "感性",
    "reading": "gǎnxìng",
    "meaning": "sensitive",
    "grade": 7
  },
  {
    "id": 6682,
    "traditional": "幹部",
    "simplified": "干部",
    "reading": "gànbù",
    "meaning": "organizer, staff, cadre",
    "grade": 7
  },
  {
    "id": 6683,
    "traditional": "幹事",
    "simplified": "干事",
    "reading": "gànshi",
    "meaning": "administrator, executive secretary",
    "grade": 7
  },
  {
    "id": 6684,
    "traditional": "綱領",
    "simplified": "纲领",
    "reading": "gānglǐng",
    "meaning": "summary, creed, programme, political program, political platform, guiding principle, digest, outline, program, platform",
    "grade": 7
  },
  {
    "id": 6685,
    "traditional": "剛毅",
    "simplified": "刚毅",
    "reading": "gāngyì",
    "meaning": "resolute, steadfast, stalwart",
    "grade": 7
  },
  {
    "id": 6686,
    "traditional": "綱要",
    "simplified": "纲要",
    "reading": "gāngyào",
    "meaning": "outline, essential points",
    "grade": 7
  },
  {
    "id": 6687,
    "traditional": "鋼",
    "simplified": "钢",
    "reading": "gāng",
    "meaning": "steel",
    "grade": 7
  },
  {
    "id": 6688,
    "traditional": "缸",
    "simplified": "缸",
    "reading": "gāng",
    "meaning": "jar, vat, classifier for loads of laundry, CL:口[kou3]",
    "grade": 7
  },
  {
    "id": 6689,
    "traditional": "杠鈴",
    "simplified": "杠铃",
    "reading": "gànglíng",
    "meaning": "barbell",
    "grade": 7
  },
  {
    "id": 6690,
    "traditional": "港",
    "simplified": "港",
    "reading": "gǎng",
    "meaning": "Hong Kong",
    "grade": 7
  },
  {
    "id": 6691,
    "traditional": "高昂",
    "simplified": "高昂",
    "reading": "gāoáng",
    "meaning": "held high (head/etc.), high, elated, exalted",
    "grade": 7
  },
  {
    "id": 6692,
    "traditional": "高傲",
    "simplified": "高傲",
    "reading": "gāoào",
    "meaning": "arrogant, haughty, proud",
    "grade": 7
  },
  {
    "id": 6693,
    "traditional": "高超",
    "simplified": "高超",
    "reading": "gāochāo",
    "meaning": "excellent, superlative",
    "grade": 7
  },
  {
    "id": 6694,
    "traditional": "高低",
    "simplified": "高低",
    "reading": "gāodī",
    "meaning": "height, altitude (aviation), pitch (music), ups and downs (success or failure), whether sth is right or wrong, comparative strength, weight, depth, stature, (spoken interjection) anyway, whatever, eventually, in the end",
    "grade": 7
  },
  {
    "id": 6695,
    "traditional": "高調",
    "simplified": "高调",
    "reading": "gāodiào",
    "meaning": "high-sounding speech, bombast, high-profile",
    "grade": 7
  },
  {
    "id": 6696,
    "traditional": "高額",
    "simplified": "高额",
    "reading": "gāoé",
    "meaning": "high quota, large amount",
    "grade": 7
  },
  {
    "id": 6697,
    "traditional": "高爾夫球",
    "simplified": "高尔夫球",
    "reading": "gāoěrfūqiú",
    "meaning": "golf ball",
    "grade": 7
  },
  {
    "id": 6698,
    "traditional": "高貴",
    "simplified": "高贵",
    "reading": "gāoguì",
    "meaning": "noble, high",
    "grade": 7
  },
  {
    "id": 6699,
    "traditional": "高峰期",
    "simplified": "高峰期",
    "reading": "gāofēngqī",
    "meaning": "peak period, rush hour",
    "grade": 7
  },
  {
    "id": 6700,
    "traditional": "高空",
    "simplified": "高空",
    "reading": "gāokōng",
    "meaning": "high altitude, upper air",
    "grade": 7
  },
  {
    "id": 6701,
    "traditional": "高齡",
    "simplified": "高龄",
    "reading": "gāolíng",
    "meaning": "elderly",
    "grade": 7
  },
  {
    "id": 6702,
    "traditional": "高明",
    "simplified": "高明",
    "reading": "gāomíng",
    "meaning": "brilliant, wise",
    "grade": 7
  },
  {
    "id": 6703,
    "traditional": "高山",
    "simplified": "高山",
    "reading": "gāoshān",
    "meaning": "jebel, high mountain, mountain, alp, mount, ben",
    "grade": 7
  },
  {
    "id": 6704,
    "traditional": "高效",
    "simplified": "高效",
    "reading": "gāoxiào",
    "meaning": "efficient, highly effective",
    "grade": 7
  },
  {
    "id": 6705,
    "traditional": "高新技術",
    "simplified": "高新技术",
    "reading": "gāoxīn jìshù",
    "meaning": "High-tech",
    "grade": 7
  },
  {
    "id": 6706,
    "traditional": "高血壓",
    "simplified": "高血压",
    "reading": "gāoxuèyā",
    "meaning": "high_blood_pressure, high blood pressure, hypertension",
    "grade": 7
  },
  {
    "id": 6707,
    "traditional": "高壓",
    "simplified": "高压",
    "reading": "gāoyā",
    "meaning": "high pressure, high voltage/tension, coercion, tyranny",
    "grade": 7
  },
  {
    "id": 6708,
    "traditional": "高雅",
    "simplified": "高雅",
    "reading": "gāoyǎ",
    "meaning": "dainty, elegance, elegant",
    "grade": 7
  },
  {
    "id": 6709,
    "traditional": "高漲",
    "simplified": "高涨",
    "reading": "gāozhàng",
    "meaning": "to surge up, to rise, (of tensions etc) to run high",
    "grade": 7
  },
  {
    "id": 6710,
    "traditional": "搞鬼",
    "simplified": "搞鬼",
    "reading": "gǎo guǐ",
    "meaning": "to make mischief, to play tricks in secret",
    "grade": 7
  },
  {
    "id": 6711,
    "traditional": "搞笑",
    "simplified": "搞笑",
    "reading": "gǎoxiào",
    "meaning": "funny, hilarious",
    "grade": 7
  },
  {
    "id": 6712,
    "traditional": "告",
    "simplified": "告",
    "reading": "gào",
    "meaning": "accuse, go to law against",
    "grade": 7
  },
  {
    "id": 6713,
    "traditional": "告辭",
    "simplified": "告辞",
    "reading": "gàocí",
    "meaning": "to say goodbye, to take one's leave",
    "grade": 7
  },
  {
    "id": 6714,
    "traditional": "告誡",
    "simplified": "告诫",
    "reading": "gàojiè",
    "meaning": "to warn, to admonish",
    "grade": 7
  },
  {
    "id": 6715,
    "traditional": "告示",
    "simplified": "告示",
    "reading": "gàoshi",
    "meaning": "announcement",
    "grade": 7
  },
  {
    "id": 6716,
    "traditional": "告知",
    "simplified": "告知",
    "reading": "gàozhī",
    "meaning": "acknowledge, expose, divulge, disclose, intimation, notify, impart, inform, communicate, apprize, notification, advise, give notice, assure, reveal, let on, tell, discover, acquaint, let out, send word, declare, instruct, apprise, break, give away, bring out",
    "grade": 7
  },
  {
    "id": 6717,
    "traditional": "告狀",
    "simplified": "告状",
    "reading": "gào zhuàng",
    "meaning": "to tell on sb, to complain (to a teacher, a superior etc), to bring a lawsuit",
    "grade": 7
  },
  {
    "id": 6718,
    "traditional": "戈壁",
    "simplified": "戈壁",
    "reading": "gēbì",
    "meaning": "Gobi (desert)",
    "grade": 7
  },
  {
    "id": 6719,
    "traditional": "胳膊",
    "simplified": "胳膊",
    "reading": "gēbo",
    "meaning": "arm, CL:隻|只[zhi1],條|条[tiao2],雙|双[shuang1]",
    "grade": 7
  },
  {
    "id": 6720,
    "traditional": "鴿子",
    "simplified": "鸽子",
    "reading": "gēzi",
    "meaning": "pigeon, dove, various birds of the family Columbidae",
    "grade": 7
  },
  {
    "id": 6721,
    "traditional": "擱",
    "simplified": "搁",
    "reading": "gē",
    "meaning": "to place, to put aside, to shelve, to bear, to stand, to endure",
    "grade": 7
  },
  {
    "id": 6722,
    "traditional": "擱置",
    "simplified": "搁置",
    "reading": "gēzhì",
    "meaning": "to shelve, to set aside",
    "grade": 7
  },
  {
    "id": 6723,
    "traditional": "擱淺",
    "simplified": "搁浅",
    "reading": "gē qiǎn",
    "meaning": "to be stranded (of ship), to run aground, fig. to run into difficulties and stop",
    "grade": 7
  },
  {
    "id": 6724,
    "traditional": "割",
    "simplified": "割",
    "reading": "gē",
    "meaning": "hack, cut, fissure, slit, crop, slice, mow, carbonado",
    "grade": 7
  },
  {
    "id": 6725,
    "traditional": "歌劇",
    "simplified": "歌剧",
    "reading": "gējù",
    "meaning": "revue, melodrama, opus, operatic, opera",
    "grade": 7
  },
  {
    "id": 6726,
    "traditional": "歌頌",
    "simplified": "歌颂",
    "reading": "gēsòng",
    "meaning": "to sing the praises of, to extol, to eulogize",
    "grade": 7
  },
  {
    "id": 6727,
    "traditional": "歌舞",
    "simplified": "歌舞",
    "reading": "gēwǔ",
    "meaning": "singing and dancing",
    "grade": 7
  },
  {
    "id": 6728,
    "traditional": "歌詠",
    "simplified": "歌咏",
    "reading": "gēyǒng",
    "meaning": "singing",
    "grade": 7
  },
  {
    "id": 6729,
    "traditional": "革命",
    "simplified": "革命",
    "reading": "gémìng",
    "meaning": "revolt",
    "grade": 7
  },
  {
    "id": 6730,
    "traditional": "格",
    "simplified": "格",
    "reading": "gé",
    "meaning": "lattice, grid, squares",
    "grade": 7
  },
  {
    "id": 6731,
    "traditional": "格格不入",
    "simplified": "格格不入",
    "reading": "gégé-bùrù",
    "meaning": "(idiom) inharmonious, incompatible",
    "grade": 7
  },
  {
    "id": 6732,
    "traditional": "格局",
    "simplified": "格局",
    "reading": "géjú",
    "meaning": "pattern, setup, structure",
    "grade": 7
  },
  {
    "id": 6733,
    "traditional": "格式",
    "simplified": "格式",
    "reading": "géshi",
    "meaning": "pattern, form, a style, a format, etiquette, format, style, the form of characters or letters",
    "grade": 7
  },
  {
    "id": 6734,
    "traditional": "隔閡",
    "simplified": "隔阂",
    "reading": "géhé",
    "meaning": "misunderstanding, estrangement, (language etc) barrier",
    "grade": 7
  },
  {
    "id": 6735,
    "traditional": "隔離",
    "simplified": "隔离",
    "reading": "gélí",
    "meaning": "keep apart, segregate, isolate",
    "grade": 7
  },
  {
    "id": 6736,
    "traditional": "個頭兒",
    "simplified": "个头儿",
    "reading": "gètóur5",
    "meaning": "size, height, stature",
    "grade": 7
  },
  {
    "id": 6737,
    "traditional": "個案",
    "simplified": "个案",
    "reading": "gèàn",
    "meaning": "case (in law/etc.)",
    "grade": 7
  },
  {
    "id": 6738,
    "traditional": "各奔前程",
    "simplified": "各奔前程",
    "reading": "gèbèn-qiánchéng",
    "meaning": "each goes his own way (idiom), each person has his own life to lead",
    "grade": 7
  },
  {
    "id": 6739,
    "traditional": "各式各樣",
    "simplified": "各式各样",
    "reading": "gèshì-gèyàng",
    "meaning": "various, kinds, sundry, all sorts, assorted",
    "grade": 7
  },
  {
    "id": 6740,
    "traditional": "根深蒂固",
    "simplified": "根深蒂固",
    "reading": "gēnshēn-dìgù",
    "meaning": "deep-rooted (problem etc)",
    "grade": 7
  },
  {
    "id": 6741,
    "traditional": "根源",
    "simplified": "根源",
    "reading": "gēnyuán",
    "meaning": "rootage, parent, origin, spore, source, rootstock, germ, springhead, cradle, whence, paternity, spawn, mother, root, seed, fount, fountainhead",
    "grade": 7
  },
  {
    "id": 6742,
    "traditional": "根基",
    "simplified": "根基",
    "reading": "gēnjī",
    "meaning": "foundation",
    "grade": 7
  },
  {
    "id": 6743,
    "traditional": "根治",
    "simplified": "根治",
    "reading": "gēnzhì",
    "meaning": "to bring under permanent control, to effect a radical cure",
    "grade": 7
  },
  {
    "id": 6744,
    "traditional": "跟上",
    "simplified": "跟上",
    "reading": "gēn shàng",
    "meaning": "to catch up with, to keep pace with",
    "grade": 7
  },
  {
    "id": 6745,
    "traditional": "跟蹤",
    "simplified": "跟踪",
    "reading": "gēnzōng",
    "meaning": "track",
    "grade": 7
  },
  {
    "id": 6746,
    "traditional": "跟不上",
    "simplified": "跟不上",
    "reading": "gēnbushàng",
    "meaning": "not able to keep up with",
    "grade": 7
  },
  {
    "id": 6747,
    "traditional": "更衣室",
    "simplified": "更衣室",
    "reading": "gēngyīshì",
    "meaning": "change room, dressing room, locker room, toilet",
    "grade": 7
  },
  {
    "id": 6748,
    "traditional": "更改",
    "simplified": "更改",
    "reading": "gēnggǎi",
    "meaning": "change, alter",
    "grade": 7
  },
  {
    "id": 6749,
    "traditional": "耕地",
    "simplified": "耕地",
    "reading": "gēngdì",
    "meaning": "arable land, to plow land",
    "grade": 7
  },
  {
    "id": 6750,
    "traditional": "耿直",
    "simplified": "耿直",
    "reading": "gěngzhí",
    "meaning": "honest, frank, candid",
    "grade": 7
  },
  {
    "id": 6751,
    "traditional": "工地",
    "simplified": "工地",
    "reading": "gōngdì",
    "meaning": "construction site",
    "grade": 7
  },
  {
    "id": 6752,
    "traditional": "工會",
    "simplified": "工会",
    "reading": "gōnghuì",
    "meaning": "trade/labor union",
    "grade": 7
  },
  {
    "id": 6753,
    "traditional": "工商界",
    "simplified": "工商界",
    "reading": "gōngshāngjiè",
    "meaning": "industrial and commercial circles",
    "grade": 7
  },
  {
    "id": 6754,
    "traditional": "工科",
    "simplified": "工科",
    "reading": "gōngkē",
    "meaning": "engineering as an academic subject",
    "grade": 7
  },
  {
    "id": 6755,
    "traditional": "工序",
    "simplified": "工序",
    "reading": "gōngxù",
    "meaning": "working procedure, process",
    "grade": 7
  },
  {
    "id": 6756,
    "traditional": "工整",
    "simplified": "工整",
    "reading": "gōngzhěng",
    "meaning": "fine work, carefully and neatly done",
    "grade": 7
  },
  {
    "id": 6757,
    "traditional": "工作量",
    "simplified": "工作量",
    "reading": "gōngzuòliàng",
    "meaning": "workload, volume of work",
    "grade": 7
  },
  {
    "id": 6758,
    "traditional": "公車",
    "simplified": "公车",
    "reading": "gōngchē",
    "meaning": "bus, cars provided by an organization",
    "grade": 7
  },
  {
    "id": 6759,
    "traditional": "公安局",
    "simplified": "公安局",
    "reading": "gōngānjú",
    "meaning": "public security bureau (government office similar in function to a police station)",
    "grade": 7
  },
  {
    "id": 6760,
    "traditional": "公道",
    "simplified": "公道",
    "reading": "gōngdao",
    "meaning": "justice, fairness, public highway, fair, equitable",
    "grade": 7
  },
  {
    "id": 6761,
    "traditional": "公費",
    "simplified": "公费",
    "reading": "gōngfèi",
    "meaning": "at public expense",
    "grade": 7
  },
  {
    "id": 6762,
    "traditional": "公共場所",
    "simplified": "公共场所",
    "reading": "gōnggòng chǎngsuǒ",
    "meaning": "Public places",
    "grade": 7
  },
  {
    "id": 6763,
    "traditional": "公關",
    "simplified": "公关",
    "reading": "gōngguān",
    "meaning": "person in public relations",
    "grade": 7
  },
  {
    "id": 6764,
    "traditional": "弓",
    "simplified": "弓",
    "reading": "gōng",
    "meaning": "surname Gong, a bow (weapon), CL:張|张[zhang1], to bend, to arch (one's back etc)",
    "grade": 7
  },
  {
    "id": 6765,
    "traditional": "公函",
    "simplified": "公函",
    "reading": "gōnghán",
    "meaning": "official letter",
    "grade": 7
  },
  {
    "id": 6766,
    "traditional": "公積金",
    "simplified": "公积金",
    "reading": "gōngjījīn",
    "meaning": "official reserves, accumulated fund",
    "grade": 7
  },
  {
    "id": 6767,
    "traditional": "公開信",
    "simplified": "公开信",
    "reading": "gōngkāixìn",
    "meaning": "open letter",
    "grade": 7
  },
  {
    "id": 6768,
    "traditional": "公墓",
    "simplified": "公墓",
    "reading": "gōngmù",
    "meaning": "public cemetery",
    "grade": 7
  },
  {
    "id": 6769,
    "traditional": "公款",
    "simplified": "公款",
    "reading": "gōngkuǎn",
    "meaning": "public money",
    "grade": 7
  },
  {
    "id": 6770,
    "traditional": "公僕",
    "simplified": "公仆",
    "reading": "gōngpú",
    "meaning": "public servant, CL:個|个[ge4],位[wei4]",
    "grade": 7
  },
  {
    "id": 6771,
    "traditional": "公頃",
    "simplified": "公顷",
    "reading": "gōngqǐng",
    "meaning": "m.[standard]",
    "grade": 7
  },
  {
    "id": 6772,
    "traditional": "公然",
    "simplified": "公然",
    "reading": "gōngrán",
    "meaning": "openly, publicly, undisguised",
    "grade": 7
  },
  {
    "id": 6773,
    "traditional": "公示",
    "simplified": "公示",
    "reading": "gōngshì",
    "meaning": "Publicity",
    "grade": 7
  },
  {
    "id": 6774,
    "traditional": "公事",
    "simplified": "公事",
    "reading": "gōngshì",
    "meaning": "public affairs, official (matters, duties etc)",
    "grade": 7
  },
  {
    "id": 6775,
    "traditional": "公益",
    "simplified": "公益",
    "reading": "gōngyì",
    "meaning": "public good/welfare",
    "grade": 7
  },
  {
    "id": 6776,
    "traditional": "公務",
    "simplified": "公务",
    "reading": "gōngwù",
    "meaning": "official business",
    "grade": 7
  },
  {
    "id": 6777,
    "traditional": "公益性",
    "simplified": "公益性",
    "reading": "gōngyì xìng",
    "meaning": "Public welfare",
    "grade": 7
  },
  {
    "id": 6778,
    "traditional": "公立",
    "simplified": "公立",
    "reading": "gōnglì",
    "meaning": "public, established and maintained by the government",
    "grade": 7
  },
  {
    "id": 6779,
    "traditional": "公寓",
    "simplified": "公寓",
    "reading": "gōngyù",
    "meaning": "flats, apartment house, public housing",
    "grade": 7
  },
  {
    "id": 6780,
    "traditional": "公約",
    "simplified": "公约",
    "reading": "gōngyuē",
    "meaning": "convention, pact, joint pledge",
    "grade": 7
  },
  {
    "id": 6781,
    "traditional": "公用",
    "simplified": "公用",
    "reading": "gōngyòng",
    "meaning": "be for public use",
    "grade": 7
  },
  {
    "id": 6782,
    "traditional": "公證",
    "simplified": "公证",
    "reading": "gōngzhèng",
    "meaning": "notarization, notarized, acknowledgement",
    "grade": 7
  },
  {
    "id": 6783,
    "traditional": "功",
    "simplified": "功",
    "reading": "gōng",
    "meaning": "meritorious service, exploit, achievement, result, effect, skill",
    "grade": 7
  },
  {
    "id": 6784,
    "traditional": "功臣",
    "simplified": "功臣",
    "reading": "gōngchén",
    "meaning": "minister who has given outstanding service",
    "grade": 7
  },
  {
    "id": 6785,
    "traditional": "功底",
    "simplified": "功底",
    "reading": "gōngdǐ",
    "meaning": "training in the basic skills, knowledge of the fundamentals",
    "grade": 7
  },
  {
    "id": 6786,
    "traditional": "公職",
    "simplified": "公职",
    "reading": "gōngzhí",
    "meaning": "public office/employment",
    "grade": 7
  },
  {
    "id": 6787,
    "traditional": "功力",
    "simplified": "功力",
    "reading": "gōnglì",
    "meaning": "effect, efficacy, craftsmanship, skill, capability, touch",
    "grade": 7
  },
  {
    "id": 6788,
    "traditional": "功勞",
    "simplified": "功劳",
    "reading": "gōngláo",
    "meaning": "contribution, meritorious service, credit",
    "grade": 7
  },
  {
    "id": 6789,
    "traditional": "功效",
    "simplified": "功效",
    "reading": "gōngxiào",
    "meaning": "efficacy, effect",
    "grade": 7
  },
  {
    "id": 6790,
    "traditional": "攻讀",
    "simplified": "攻读",
    "reading": "gōngdú",
    "meaning": "to major (in a field), to study a specialty to obtain a higher degree",
    "grade": 7
  },
  {
    "id": 6791,
    "traditional": "攻",
    "simplified": "攻",
    "reading": "gōng",
    "meaning": "assault, attack, censure, accuse",
    "grade": 7
  },
  {
    "id": 6792,
    "traditional": "攻關",
    "simplified": "攻关",
    "reading": "gōng guān",
    "meaning": "to storm a strategic pass, fig. to tackle a key problem",
    "grade": 7
  },
  {
    "id": 6793,
    "traditional": "功率",
    "simplified": "功率",
    "reading": "gōnglǜ",
    "meaning": "rate of work, power (output)",
    "grade": 7
  },
  {
    "id": 6794,
    "traditional": "供不應求",
    "simplified": "供不应求",
    "reading": "gōngbùyìngqiú",
    "meaning": "supply does not meet demand",
    "grade": 7
  },
  {
    "id": 6795,
    "traditional": "供",
    "simplified": "供",
    "reading": "gōng",
    "meaning": "supply, feed, be for (the use/convenience of)",
    "grade": 7
  },
  {
    "id": 6796,
    "traditional": "供暖",
    "simplified": "供暖",
    "reading": "gōngnuǎn",
    "meaning": "Heating",
    "grade": 7
  },
  {
    "id": 6797,
    "traditional": "宮殿",
    "simplified": "宫殿",
    "reading": "gōngdiàn",
    "meaning": "palatium, dome, alcazar, palace",
    "grade": 7
  },
  {
    "id": 6798,
    "traditional": "供求",
    "simplified": "供求",
    "reading": "gōngqiú",
    "meaning": "supply and demand (economics)",
    "grade": 7
  },
  {
    "id": 6799,
    "traditional": "宮廷",
    "simplified": "宫廷",
    "reading": "gōngtíng",
    "meaning": "royal, royal/imperial court, cupbearer, court, royal or imperial court, royal court, imperial court, palace",
    "grade": 7
  },
  {
    "id": 6800,
    "traditional": "恭維",
    "simplified": "恭维",
    "reading": "gōngwei",
    "meaning": "to praise, to speak highly of, compliment, praise",
    "grade": 7
  },
  {
    "id": 6801,
    "traditional": "恭喜",
    "simplified": "恭喜",
    "reading": "gōngxǐ",
    "meaning": "congratulate",
    "grade": 7
  },
  {
    "id": 6802,
    "traditional": "拱",
    "simplified": "拱",
    "reading": "gǒng",
    "meaning": "to cup one's hands in salute, to surround, to arch, to dig earth with the snout, arched",
    "grade": 7
  },
  {
    "id": 6803,
    "traditional": "共鳴",
    "simplified": "共鸣",
    "reading": "gòngmíng",
    "meaning": "sympathy, fellow feeling, vibration, consonance, resonance, sympathetic response",
    "grade": 7
  },
  {
    "id": 6804,
    "traditional": "共識",
    "simplified": "共识",
    "reading": "gòngshí",
    "meaning": "wavelength, common understanding, consensus",
    "grade": 7
  },
  {
    "id": 6805,
    "traditional": "共性",
    "simplified": "共性",
    "reading": "gòngxìng",
    "meaning": "overall character",
    "grade": 7
  },
  {
    "id": 6806,
    "traditional": "供奉",
    "simplified": "供奉",
    "reading": "gòngfèng",
    "meaning": "to consecrate, to enshrine and worship, an offering (to one's ancestors), a sacrifice (to a God)",
    "grade": 7
  },
  {
    "id": 6807,
    "traditional": "共同體",
    "simplified": "共同体",
    "reading": "gòngtóngtǐ",
    "meaning": "community, integration",
    "grade": 7
  },
  {
    "id": 6808,
    "traditional": "勾畫",
    "simplified": "勾画",
    "reading": "gōuhuà",
    "meaning": "to sketch out, to delineate",
    "grade": 7
  },
  {
    "id": 6809,
    "traditional": "勾",
    "simplified": "勾",
    "reading": "gōu",
    "meaning": "surname Gou, to attract, to arouse, to tick, to strike out, to delineate, to collude, variant of 鉤|钩[gou1], hook, see 勾當|勾当[gou4 dang4]",
    "grade": 7
  },
  {
    "id": 6810,
    "traditional": "勾結",
    "simplified": "勾结",
    "reading": "gōujié",
    "meaning": "to collude with, to collaborate with, to gang up with",
    "grade": 7
  },
  {
    "id": 6811,
    "traditional": "鉤",
    "simplified": "钩",
    "reading": "gōu",
    "meaning": "to hook, to sew, to crochet, hook, check mark or tick, window catch",
    "grade": 7
  },
  {
    "id": 6812,
    "traditional": "鉤子",
    "simplified": "钩子",
    "reading": "gōuzi",
    "meaning": "hook",
    "grade": 7
  },
  {
    "id": 6813,
    "traditional": "構思",
    "simplified": "构思",
    "reading": "gòusī",
    "meaning": "to design, to plot, to plan out, to compose, to draw a mental sketch, conception, plan, idea, composition",
    "grade": 7
  },
  {
    "id": 6814,
    "traditional": "構想",
    "simplified": "构想",
    "reading": "gòuxiǎng",
    "meaning": "proposition",
    "grade": 7
  },
  {
    "id": 6815,
    "traditional": "購",
    "simplified": "购",
    "reading": "gòu",
    "meaning": "buy",
    "grade": 7
  },
  {
    "id": 6816,
    "traditional": "夠嗆",
    "simplified": "够呛",
    "reading": "gòuqiàng",
    "meaning": "unbearable, terrible, enough, unlikely",
    "grade": 7
  },
  {
    "id": 6817,
    "traditional": "估算",
    "simplified": "估算",
    "reading": "gūsuàn",
    "meaning": "assessment, evaluation",
    "grade": 7
  },
  {
    "id": 6818,
    "traditional": "沽名釣譽",
    "simplified": "沽名钓誉",
    "reading": "gūmíng-diàoyù",
    "meaning": "to angle for fame (idiom), to fish for compliments",
    "grade": 7
  },
  {
    "id": 6819,
    "traditional": "孤單",
    "simplified": "孤单",
    "reading": "gūdān",
    "meaning": "lone, lonely, loneliness",
    "grade": 7
  },
  {
    "id": 6820,
    "traditional": "孤立",
    "simplified": "孤立",
    "reading": "gūlì",
    "meaning": "isolate, isolated",
    "grade": 7
  },
  {
    "id": 6821,
    "traditional": "孤零零",
    "simplified": "孤零零",
    "reading": "gūlínglíng",
    "meaning": "lone, isolated and without help, all alone, solitary",
    "grade": 7
  },
  {
    "id": 6822,
    "traditional": "孤陋寡聞",
    "simplified": "孤陋寡闻",
    "reading": "gūlòu-guǎwén",
    "meaning": "ignorant and inexperienced, ill-informed and narrow-minded",
    "grade": 7
  },
  {
    "id": 6823,
    "traditional": "辜負",
    "simplified": "辜负",
    "reading": "gūfù",
    "meaning": "to fail to live up (to expectations), unworthy (of trust), to let down, to betray (hopes), to disappoint",
    "grade": 7
  },
  {
    "id": 6824,
    "traditional": "古董",
    "simplified": "古董",
    "reading": "gǔdǒng",
    "meaning": "old fogey, curio, antique, virtu, vertu, bygone, bric-a-brac",
    "grade": 7
  },
  {
    "id": 6825,
    "traditional": "古怪",
    "simplified": "古怪",
    "reading": "gǔguài",
    "meaning": "eccentric, grotesque, oddly, queer",
    "grade": 7
  },
  {
    "id": 6826,
    "traditional": "古跡",
    "simplified": "古迹",
    "reading": "gǔjì",
    "meaning": "places of historic interest, historical sites, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 6827,
    "traditional": "古今中外",
    "simplified": "古今中外",
    "reading": "gǔjīn-zhōngwài",
    "meaning": "at all times and in all places (idiom)",
    "grade": 7
  },
  {
    "id": 6828,
    "traditional": "古樸",
    "simplified": "古朴",
    "reading": "gǔpǔ",
    "meaning": "simple and unadorned (of art, architecture etc)",
    "grade": 7
  },
  {
    "id": 6829,
    "traditional": "古人",
    "simplified": "古人",
    "reading": "gǔrén",
    "meaning": "forefathers, our forefather, ancient, ancients, antiquity",
    "grade": 7
  },
  {
    "id": 6830,
    "traditional": "股份",
    "simplified": "股份",
    "reading": "gǔfèn",
    "meaning": "equity, concern, share, stake, stock, interest",
    "grade": 7
  },
  {
    "id": 6831,
    "traditional": "股民",
    "simplified": "股民",
    "reading": "gǔmín",
    "meaning": "stock investor, share trader",
    "grade": 7
  },
  {
    "id": 6832,
    "traditional": "股市",
    "simplified": "股市",
    "reading": "gǔshì",
    "meaning": "stock exchange, securities market, stock market, stock_market",
    "grade": 7
  },
  {
    "id": 6833,
    "traditional": "骨幹",
    "simplified": "骨干",
    "reading": "gǔgàn",
    "meaning": "diaphysis (long segment of a bone), fig. backbone",
    "grade": 7
  },
  {
    "id": 6834,
    "traditional": "骨折",
    "simplified": "骨折",
    "reading": "gǔzhé",
    "meaning": "to suffer a fracture, (of a bone) to break, fracture",
    "grade": 7
  },
  {
    "id": 6835,
    "traditional": "骨氣",
    "simplified": "骨气",
    "reading": "gǔqì",
    "meaning": "unyielding character, courageous spirit, integrity, moral backbone",
    "grade": 7
  },
  {
    "id": 6836,
    "traditional": "鼓舞",
    "simplified": "鼓舞",
    "reading": "gǔwǔ",
    "meaning": "inspire, hearten, dance for joy, rejoice",
    "grade": 7
  },
  {
    "id": 6837,
    "traditional": "鼓動",
    "simplified": "鼓动",
    "reading": "gǔdòng",
    "meaning": "to agitate, to arouse, to instigate, to encite",
    "grade": 7
  },
  {
    "id": 6838,
    "traditional": "固然",
    "simplified": "固然",
    "reading": "gùrán",
    "meaning": "conj.: though, although",
    "grade": 7
  },
  {
    "id": 6839,
    "traditional": "固執",
    "simplified": "固执",
    "reading": "gùzhi",
    "meaning": "obstinate, stubborn",
    "grade": 7
  },
  {
    "id": 6840,
    "traditional": "故",
    "simplified": "故",
    "reading": "gù",
    "meaning": "conj.: hence, so, therefore, as a result",
    "grade": 7
  },
  {
    "id": 6841,
    "traditional": "顧不得",
    "simplified": "顾不得",
    "reading": "gùbude",
    "meaning": "unable to change sth, unable to deal with",
    "grade": 7
  },
  {
    "id": 6842,
    "traditional": "顧不上",
    "simplified": "顾不上",
    "reading": "gùbushàng",
    "meaning": "cannot attend to or manage",
    "grade": 7
  },
  {
    "id": 6843,
    "traditional": "顧及",
    "simplified": "顾及",
    "reading": "gù jí",
    "meaning": "study, give consideration to, consider, take into account, consult, attend to, allow_for",
    "grade": 7
  },
  {
    "id": 6844,
    "traditional": "顧慮",
    "simplified": "顾虑",
    "reading": "gùlǜ",
    "meaning": "misgivings, apprehensions",
    "grade": 7
  },
  {
    "id": 6845,
    "traditional": "雇",
    "simplified": "雇",
    "reading": "gù",
    "meaning": "to employ, to hire, to rent",
    "grade": 7
  },
  {
    "id": 6846,
    "traditional": "顧全大局",
    "simplified": "顾全大局",
    "reading": "gùquándàjú",
    "meaning": "to take the big picture into consideration (idiom), to work for the benefits of all",
    "grade": 7
  },
  {
    "id": 6847,
    "traditional": "雇傭",
    "simplified": "雇佣",
    "reading": "gùyōng",
    "meaning": "to employ, to hire",
    "grade": 7
  },
  {
    "id": 6848,
    "traditional": "雇員",
    "simplified": "雇员",
    "reading": "gùyuán",
    "meaning": "employee",
    "grade": 7
  },
  {
    "id": 6849,
    "traditional": "雇主",
    "simplified": "雇主",
    "reading": "gùzhǔ",
    "meaning": "gaffer, beanfeast, master, governor, boss, employer, hirer",
    "grade": 7
  },
  {
    "id": 6850,
    "traditional": "瓜分",
    "simplified": "瓜分",
    "reading": "guāfēn",
    "meaning": "to partition, to divide up",
    "grade": 7
  },
  {
    "id": 6851,
    "traditional": "瓜子",
    "simplified": "瓜子",
    "reading": "guāzǐ",
    "meaning": "melon seeds",
    "grade": 7
  },
  {
    "id": 6852,
    "traditional": "寡婦",
    "simplified": "寡妇",
    "reading": "guǎfu",
    "meaning": "widow",
    "grade": 7
  },
  {
    "id": 6853,
    "traditional": "颳風",
    "simplified": "刮风",
    "reading": "guāfēng",
    "meaning": "to be windy",
    "grade": 7
  },
  {
    "id": 6854,
    "traditional": "掛鉤",
    "simplified": "挂钩",
    "reading": "guàgōu",
    "meaning": "hook (on which to hang sth), to couple, to link together, to establish contact with, hook, coupling links (e.g. between two railway coaches)",
    "grade": 7
  },
  {
    "id": 6855,
    "traditional": "掛號",
    "simplified": "挂号",
    "reading": "guà hào",
    "meaning": "to register (at a hospital etc), to send by registered mail",
    "grade": 7
  },
  {
    "id": 6856,
    "traditional": "掛念",
    "simplified": "挂念",
    "reading": "guàniàn",
    "meaning": "concerned",
    "grade": 7
  },
  {
    "id": 6857,
    "traditional": "掛失",
    "simplified": "挂失",
    "reading": "guà shī",
    "meaning": "to report the loss of something",
    "grade": 7
  },
  {
    "id": 6858,
    "traditional": "乖",
    "simplified": "乖",
    "reading": "guāi",
    "meaning": "be at variance, well-behaved, obedient, oppose",
    "grade": 7
  },
  {
    "id": 6859,
    "traditional": "乖巧",
    "simplified": "乖巧",
    "reading": "guāiqiǎo",
    "meaning": "clever (child), smart, lovable, cute",
    "grade": 7
  },
  {
    "id": 6860,
    "traditional": "拐彎",
    "simplified": "拐弯",
    "reading": "guǎiwān",
    "meaning": "to go round a curve, to turn a corner, fig. a new direction",
    "grade": 7
  },
  {
    "id": 6861,
    "traditional": "拐杖",
    "simplified": "拐杖",
    "reading": "guǎizhàng",
    "meaning": "crutches, crutch, walking stick",
    "grade": 7
  },
  {
    "id": 6862,
    "traditional": "怪不得",
    "simplified": "怪不得",
    "reading": "guàibude",
    "meaning": "no wonder!, so that's why!",
    "grade": 7
  },
  {
    "id": 6863,
    "traditional": "怪物",
    "simplified": "怪物",
    "reading": "guàiwu",
    "meaning": "monster, freak, eccentric person",
    "grade": 7
  },
  {
    "id": 6864,
    "traditional": "怪異",
    "simplified": "怪异",
    "reading": "guàiyì",
    "meaning": "monstrous, strange, strange phenomenon",
    "grade": 7
  },
  {
    "id": 6865,
    "traditional": "關掉",
    "simplified": "关掉",
    "reading": "guāndiào",
    "meaning": "to switch off, to shut off",
    "grade": 7
  },
  {
    "id": 6866,
    "traditional": "關節",
    "simplified": "关节",
    "reading": "guānjié",
    "meaning": "joint",
    "grade": 7
  },
  {
    "id": 6867,
    "traditional": "關稅",
    "simplified": "关税",
    "reading": "guānshuì",
    "meaning": "customs duty, tariff",
    "grade": 7
  },
  {
    "id": 6868,
    "traditional": "關頭",
    "simplified": "关头",
    "reading": "guāntóu",
    "meaning": "juncture, moment",
    "grade": 7
  },
  {
    "id": 6869,
    "traditional": "關照",
    "simplified": "关照",
    "reading": "guānzhào",
    "meaning": "to take care, to keep an eye on, to look after, to tell, to remind",
    "grade": 7
  },
  {
    "id": 6870,
    "traditional": "觀測",
    "simplified": "观测",
    "reading": "guāncè",
    "meaning": "observe, survey",
    "grade": 7
  },
  {
    "id": 6871,
    "traditional": "觀感",
    "simplified": "观感",
    "reading": "guāngǎn",
    "meaning": "one's impressions, observations",
    "grade": 7
  },
  {
    "id": 6872,
    "traditional": "觀摩",
    "simplified": "观摩",
    "reading": "guānmó",
    "meaning": "view and emulate",
    "grade": 7
  },
  {
    "id": 6873,
    "traditional": "觀賞",
    "simplified": "观赏",
    "reading": "guānshǎng",
    "meaning": "enjoy sight of, see and enjoy",
    "grade": 7
  },
  {
    "id": 6874,
    "traditional": "觀望",
    "simplified": "观望",
    "reading": "guānwàng",
    "meaning": "straddle, pussyfoot, look on (from sidelines), swiver, hesitate, waver, look on, wait and see",
    "grade": 7
  },
  {
    "id": 6875,
    "traditional": "官兵",
    "simplified": "官兵",
    "reading": "guānbīng",
    "meaning": "officers and men, government troops",
    "grade": 7
  },
  {
    "id": 6876,
    "traditional": "官吏",
    "simplified": "官吏",
    "reading": "guānlì",
    "meaning": "bureaucrat, official",
    "grade": 7
  },
  {
    "id": 6877,
    "traditional": "官僚",
    "simplified": "官僚",
    "reading": "guānliáo",
    "meaning": "bureaucrats, bureaucracy",
    "grade": 7
  },
  {
    "id": 6878,
    "traditional": "官僚主義",
    "simplified": "官僚主义",
    "reading": "guānliáozhǔyì",
    "meaning": "bureaucracy",
    "grade": 7
  },
  {
    "id": 6879,
    "traditional": "官員",
    "simplified": "官员",
    "reading": "guānyuán",
    "meaning": "beg, official, mandarinate, administrative official, office-bearer, officer, bureaucrat, placeman, federal agent, government officials, agent, officialism, functionary, director, officeholder, officialdom, myrmidon",
    "grade": 7
  },
  {
    "id": 6880,
    "traditional": "棺材",
    "simplified": "棺材",
    "reading": "guāncai",
    "meaning": "coffin, CL:具[ju4],口[kou3]",
    "grade": 7
  },
  {
    "id": 6881,
    "traditional": "管家",
    "simplified": "管家",
    "reading": "guǎnjiā",
    "meaning": "housekeeper, butler, manage one's household",
    "grade": 7
  },
  {
    "id": 6882,
    "traditional": "管教",
    "simplified": "管教",
    "reading": "guǎnjiào",
    "meaning": "subject sb. to discipline, restrain and instruct",
    "grade": 7
  },
  {
    "id": 6883,
    "traditional": "管理費",
    "simplified": "管理费",
    "reading": "guǎnlǐfèi",
    "meaning": "management fee",
    "grade": 7
  },
  {
    "id": 6884,
    "traditional": "管轄",
    "simplified": "管辖",
    "reading": "guǎnxiá",
    "meaning": "to administer, to have jurisdiction (over)",
    "grade": 7
  },
  {
    "id": 6885,
    "traditional": "管用",
    "simplified": "管用",
    "reading": "guǎn yòng",
    "meaning": "efficacious, useful",
    "grade": 7
  },
  {
    "id": 6886,
    "traditional": "管子",
    "simplified": "管子",
    "reading": "guǎnzi",
    "meaning": "Guanzi or Guan Zhong 管仲 (-645 BC), famous politician of Qi 齊國|齐国 of Spring and Autumn period, Guanzi, classical book containing writings of Guan Zhong and his school, tube, pipe, drinking straw, CL:根[gen1]",
    "grade": 7
  },
  {
    "id": 6887,
    "traditional": "貫徹",
    "simplified": "贯彻",
    "reading": "guànchè",
    "meaning": "carry out, implement",
    "grade": 7
  },
  {
    "id": 6888,
    "traditional": "貫穿",
    "simplified": "贯穿",
    "reading": "guànchuān",
    "meaning": "to run through, a connecting thread from beginning to end, to link",
    "grade": 7
  },
  {
    "id": 6889,
    "traditional": "貫通",
    "simplified": "贯通",
    "reading": "guàntōng",
    "meaning": "to link up, to thread together",
    "grade": 7
  },
  {
    "id": 6890,
    "traditional": "慣",
    "simplified": "惯",
    "reading": "guàn",
    "meaning": "accustomed to, used to, indulge, to spoil (a child)",
    "grade": 7
  },
  {
    "id": 6891,
    "traditional": "慣例",
    "simplified": "惯例",
    "reading": "guànlì",
    "meaning": "custom, pattern, practice, manner, tradition, routine, law, wont, habit, codex, ritual, convention, convenance, consuetude, usual practice, rule, use, observance, institution, modus operandi, precedent, conventionality, rite, praxis, natural law",
    "grade": 7
  },
  {
    "id": 6892,
    "traditional": "慣性",
    "simplified": "惯性",
    "reading": "guànxìng",
    "meaning": "inertia",
    "grade": 7
  },
  {
    "id": 6893,
    "traditional": "灌",
    "simplified": "灌",
    "reading": "guàn",
    "meaning": "to irrigate, to pour, to install (software), to record (music)",
    "grade": 7
  },
  {
    "id": 6894,
    "traditional": "灌溉",
    "simplified": "灌溉",
    "reading": "guàngài",
    "meaning": "irrigate",
    "grade": 7
  },
  {
    "id": 6895,
    "traditional": "灌輸",
    "simplified": "灌输",
    "reading": "guànshū",
    "meaning": "to imbue with, to inculcate, to instill into, to teach, to impart, to channel water to another place",
    "grade": 7
  },
  {
    "id": 6896,
    "traditional": "罐",
    "simplified": "罐",
    "reading": "guàn",
    "meaning": "can, jar, pot, variant of 罐[guan4]",
    "grade": 7
  },
  {
    "id": 6897,
    "traditional": "罐頭",
    "simplified": "罐头",
    "reading": "guàntou",
    "meaning": "tin, can, canned goods",
    "grade": 7
  },
  {
    "id": 6898,
    "traditional": "光彩",
    "simplified": "光彩",
    "reading": "guāngcǎi",
    "meaning": "luster, splendor, radiance, brilliance",
    "grade": 7
  },
  {
    "id": 6899,
    "traditional": "光碟",
    "simplified": "光碟",
    "reading": "guāngdié",
    "meaning": "compact disk, compact disc, CD ROM, compact_disk, CD",
    "grade": 7
  },
  {
    "id": 6900,
    "traditional": "光顧",
    "simplified": "光顾",
    "reading": "guānggù",
    "meaning": "to visit (as a customer)",
    "grade": 7
  },
  {
    "id": 6901,
    "traditional": "光滑",
    "simplified": "光滑",
    "reading": "guānghua",
    "meaning": "glossy, sleek, smooth",
    "grade": 7
  },
  {
    "id": 6902,
    "traditional": "光環",
    "simplified": "光环",
    "reading": "guānghuán",
    "meaning": "halo, ring of light",
    "grade": 7
  },
  {
    "id": 6903,
    "traditional": "光纜",
    "simplified": "光缆",
    "reading": "guānglǎn",
    "meaning": "optical cable",
    "grade": 7
  },
  {
    "id": 6904,
    "traditional": "光芒",
    "simplified": "光芒",
    "reading": "guāngmáng",
    "meaning": "ray_of_light, rays of light, brilliant ray, radiance",
    "grade": 7
  },
  {
    "id": 6905,
    "traditional": "光明磊落",
    "simplified": "光明磊落",
    "reading": "guāngmíng-lěiluò",
    "meaning": "open and candid (idiom), straightforward and upright",
    "grade": 7
  },
  {
    "id": 6906,
    "traditional": "光澤",
    "simplified": "光泽",
    "reading": "guāngzé",
    "meaning": "Guangze county in Nanping 南平[Nan2 ping2] Fujian, luster, gloss",
    "grade": 7
  },
  {
    "id": 6907,
    "traditional": "廣義",
    "simplified": "广义",
    "reading": "guǎngyì",
    "meaning": "broad sense",
    "grade": 7
  },
  {
    "id": 6908,
    "traditional": "歸根到底",
    "simplified": "归根到底",
    "reading": "guīgēndàodǐ",
    "meaning": "after all, in the final analysis, ultimately",
    "grade": 7
  },
  {
    "id": 6909,
    "traditional": "歸還",
    "simplified": "归还",
    "reading": "guīhuán",
    "meaning": "to return sth, to revert",
    "grade": 7
  },
  {
    "id": 6910,
    "traditional": "歸結",
    "simplified": "归结",
    "reading": "guījié",
    "meaning": "to sum up, to conclude, in a nutshell, the end (of a story)",
    "grade": 7
  },
  {
    "id": 6911,
    "traditional": "歸來",
    "simplified": "归来",
    "reading": "guīlái",
    "meaning": "come back, be back, return, go back to",
    "grade": 7
  },
  {
    "id": 6912,
    "traditional": "歸納",
    "simplified": "归纳",
    "reading": "guīnà",
    "meaning": "sum up, conclude, induce",
    "grade": 7
  },
  {
    "id": 6913,
    "traditional": "歸屬",
    "simplified": "归属",
    "reading": "guīshǔ",
    "meaning": "belong to",
    "grade": 7
  },
  {
    "id": 6914,
    "traditional": "歸宿",
    "simplified": "归宿",
    "reading": "guīsù",
    "meaning": "place to return to, home, final destination, ending",
    "grade": 7
  },
  {
    "id": 6915,
    "traditional": "龜",
    "simplified": "龟",
    "reading": "guī",
    "meaning": "tortoise, turtle",
    "grade": 7
  },
  {
    "id": 6916,
    "traditional": "規格",
    "simplified": "规格",
    "reading": "guīgé",
    "meaning": "quality requirements, requirement, specification, standard, spec, criterion, measure, touchstone, specifications",
    "grade": 7
  },
  {
    "id": 6917,
    "traditional": "規矩",
    "simplified": "规矩",
    "reading": "guīju",
    "meaning": "rule, custom",
    "grade": 7
  },
  {
    "id": 6918,
    "traditional": "閨女",
    "simplified": "闺女",
    "reading": "guīnü",
    "meaning": "maiden, unmarried woman, daughter",
    "grade": 7
  },
  {
    "id": 6919,
    "traditional": "瑰寶",
    "simplified": "瑰宝",
    "reading": "guībǎo",
    "meaning": "gem, (fig.) rare and valuable item, gem, treasure",
    "grade": 7
  },
  {
    "id": 6920,
    "traditional": "軌跡",
    "simplified": "轨迹",
    "reading": "guǐjì",
    "meaning": "track, orbit, trail, locus",
    "grade": 7
  },
  {
    "id": 6921,
    "traditional": "櫃檯",
    "simplified": "柜台",
    "reading": "guìtái",
    "meaning": "sales counter, front desk, bar, (of markets, medicines etc) OTC (over-the-counter), variant of 櫃檯|柜台[gui4 tai2]",
    "grade": 7
  },
  {
    "id": 6922,
    "traditional": "貴賓",
    "simplified": "贵宾",
    "reading": "guìbīn",
    "meaning": "honored guest, VIP, visiting fireman, visitant, honoured guest, distinguished guest, visiting_fireman, honored/distinguished guest, honored",
    "grade": 7
  },
  {
    "id": 6923,
    "traditional": "貴重",
    "simplified": "贵重",
    "reading": "guìzhòng",
    "meaning": "precious",
    "grade": 7
  },
  {
    "id": 6924,
    "traditional": "貴族",
    "simplified": "贵族",
    "reading": "guìzú",
    "meaning": "baron, aristocratism, atheling, nobleman, jarl, grandee, raja, aristocracy, patrician, blue blood, lord, magnifico, noble, aristocrat, baronage, peer, mogul, nobility, patricianhood, peerage, sirdar, amir, nobles",
    "grade": 7
  },
  {
    "id": 6925,
    "traditional": "桂花",
    "simplified": "桂花",
    "reading": "guìhuā",
    "meaning": "osmanthus flowers, Osmanthus fragrans",
    "grade": 7
  },
  {
    "id": 6926,
    "traditional": "滾動",
    "simplified": "滚动",
    "reading": "gǔndòng",
    "meaning": "to roll, (to do sth) in a loop, to scroll (computing), to progressively expand (economics), to rumble (of thunder)",
    "grade": 7
  },
  {
    "id": 6927,
    "traditional": "棍",
    "simplified": "棍",
    "reading": "gùn",
    "meaning": "stick, rod, truncheon",
    "grade": 7
  },
  {
    "id": 6928,
    "traditional": "棍子",
    "simplified": "棍子",
    "reading": "gùnzi",
    "meaning": "stick, rod",
    "grade": 7
  },
  {
    "id": 6929,
    "traditional": "國寶",
    "simplified": "国宝",
    "reading": "guóbǎo",
    "meaning": "national treasure",
    "grade": 7
  },
  {
    "id": 6930,
    "traditional": "國防",
    "simplified": "国防",
    "reading": "guófáng",
    "meaning": "national defence, national defense",
    "grade": 7
  },
  {
    "id": 6931,
    "traditional": "國畫",
    "simplified": "国画",
    "reading": "guóhuà",
    "meaning": "trad. Ch. painting",
    "grade": 7
  },
  {
    "id": 6932,
    "traditional": "國徽",
    "simplified": "国徽",
    "reading": "guóhuī",
    "meaning": "national emblem, coat of arms",
    "grade": 7
  },
  {
    "id": 6933,
    "traditional": "國情",
    "simplified": "国情",
    "reading": "guóqíng",
    "meaning": "current state of a country, national conditions, (US) State of the Union",
    "grade": 7
  },
  {
    "id": 6934,
    "traditional": "國土",
    "simplified": "国土",
    "reading": "guótǔ",
    "meaning": "territorial dominion, state, country, colony, soil, nation, realm, soli, province, territory, mandate, dominion, land",
    "grade": 7
  },
  {
    "id": 6935,
    "traditional": "國學",
    "simplified": "国学",
    "reading": "guóxué",
    "meaning": "Chinese national culture, studies of ancient Chinese civilization, the Imperial College (history)",
    "grade": 7
  },
  {
    "id": 6936,
    "traditional": "國有",
    "simplified": "国有",
    "reading": "guóyǒu",
    "meaning": "belong to the state, nationalized, state-owned",
    "grade": 7
  },
  {
    "id": 6937,
    "traditional": "果斷",
    "simplified": "果断",
    "reading": "guǒduàn",
    "meaning": "firm, decisive",
    "grade": 7
  },
  {
    "id": 6938,
    "traditional": "果園",
    "simplified": "果园",
    "reading": "guǒyuán",
    "meaning": "arbour, garden, orchard",
    "grade": 7
  },
  {
    "id": 6939,
    "traditional": "果真",
    "simplified": "果真",
    "reading": "guǒzhēn",
    "meaning": "really, as expected",
    "grade": 7
  },
  {
    "id": 6940,
    "traditional": "裹",
    "simplified": "裹",
    "reading": "guǒ",
    "meaning": "to bind, to wrap, a bundle, a parcel",
    "grade": 7
  },
  {
    "id": 6941,
    "traditional": "過半",
    "simplified": "过半",
    "reading": "guòbàn",
    "meaning": "over fifty percent, more than half",
    "grade": 7
  },
  {
    "id": 6942,
    "traditional": "過不去",
    "simplified": "过不去",
    "reading": "guòbuqù",
    "meaning": "to make life difficult for, to embarrass, unable to make it through",
    "grade": 7
  },
  {
    "id": 6943,
    "traditional": "過錯",
    "simplified": "过错",
    "reading": "guòcuò",
    "meaning": "mistake, fault, responsibility (for a fault)",
    "grade": 7
  },
  {
    "id": 6944,
    "traditional": "過關",
    "simplified": "过关",
    "reading": "guò guān",
    "meaning": "reach standard, pass a barrier, reach a standard, pass test, pass barrier, pass, go through ordeal, pass a test, go through an ordeal",
    "grade": 7
  },
  {
    "id": 6945,
    "traditional": "過道",
    "simplified": "过道",
    "reading": "guòdào",
    "meaning": "passageway, corridor, aisle",
    "grade": 7
  },
  {
    "id": 6946,
    "traditional": "過獎",
    "simplified": "过奖",
    "reading": "guòjiǎng",
    "meaning": "to overpraise, to flatter",
    "grade": 7
  },
  {
    "id": 6947,
    "traditional": "過節",
    "simplified": "过节",
    "reading": "guò jié",
    "meaning": "to celebrate a festival, after the celebrations (i.e. once the festival is over)",
    "grade": 7
  },
  {
    "id": 6948,
    "traditional": "過境",
    "simplified": "过境",
    "reading": "guò jìng",
    "meaning": "to pass through a country's territory, transit",
    "grade": 7
  },
  {
    "id": 6949,
    "traditional": "過濾",
    "simplified": "过滤",
    "reading": "guòlǜ",
    "meaning": "to filter, filter",
    "grade": 7
  },
  {
    "id": 6950,
    "traditional": "過期",
    "simplified": "过期",
    "reading": "guò qī",
    "meaning": "to be overdue, to exceed the time limit, to expire (as in expiration date)",
    "grade": 7
  },
  {
    "id": 6951,
    "traditional": "過剩",
    "simplified": "过剩",
    "reading": "guòshèng",
    "meaning": "surplus, excess",
    "grade": 7
  },
  {
    "id": 6952,
    "traditional": "過失",
    "simplified": "过失",
    "reading": "guòshī",
    "meaning": "error, fault, (law) negligence, delinquency",
    "grade": 7
  },
  {
    "id": 6953,
    "traditional": "過日子",
    "simplified": "过日子",
    "reading": "guò rìzi",
    "meaning": "live, get along, practice economy",
    "grade": 7
  },
  {
    "id": 6954,
    "traditional": "過頭",
    "simplified": "过头",
    "reading": "guòtóu",
    "meaning": "to overdo it, to overstep the limit, excessively, above one's head, overhead",
    "grade": 7
  },
  {
    "id": 6955,
    "traditional": "過往",
    "simplified": "过往",
    "reading": "guòwǎng",
    "meaning": "to come and go, to have friendly relations with, in the past, previous",
    "grade": 7
  },
  {
    "id": 6956,
    "traditional": "過意不去",
    "simplified": "过意不去",
    "reading": "guòyìbùqù",
    "meaning": "to feel very apologetic",
    "grade": 7
  },
  {
    "id": 6957,
    "traditional": "過癮",
    "simplified": "过瘾",
    "reading": "guò yǐn",
    "meaning": "to satisfy a craving, to get a kick out of sth, gratifying, immensely enjoyable, satisfying, fulfilling",
    "grade": 7
  },
  {
    "id": 6958,
    "traditional": "過硬",
    "simplified": "过硬",
    "reading": "guòyìng",
    "meaning": "to have perfect mastery of sth, to be up to the mark",
    "grade": 7
  },
  {
    "id": 6959,
    "traditional": "過早",
    "simplified": "过早",
    "reading": "guòzǎo",
    "meaning": "premature, untimely",
    "grade": 7
  },
  {
    "id": 6960,
    "traditional": "海岸",
    "simplified": "海岸",
    "reading": "hǎiàn",
    "meaning": "seashore, coastal, seaside, tidewater, seacoast, shore, coast, staith, rivage, seaboard, sea, beach",
    "grade": 7
  },
  {
    "id": 6961,
    "traditional": "海拔",
    "simplified": "海拔",
    "reading": "hǎibá",
    "meaning": "natural elevation, ALT, height above sea level, altitude, sea_level, height, EL, above sea level, sea level, elevation",
    "grade": 7
  },
  {
    "id": 6962,
    "traditional": "海濱",
    "simplified": "海滨",
    "reading": "hǎibīn",
    "meaning": "shore, seaside",
    "grade": 7
  },
  {
    "id": 6963,
    "traditional": "海盜",
    "simplified": "海盗",
    "reading": "hǎidào",
    "meaning": "pirate",
    "grade": 7
  },
  {
    "id": 6964,
    "traditional": "海量",
    "simplified": "海量",
    "reading": "hǎiliàng",
    "meaning": "huge volume",
    "grade": 7
  },
  {
    "id": 6965,
    "traditional": "海綿",
    "simplified": "海绵",
    "reading": "hǎimián",
    "meaning": "sponge (zoology), sponge (piece of absorbent material, either natural or made from plastic etc), foam rubber",
    "grade": 7
  },
  {
    "id": 6966,
    "traditional": "海面",
    "simplified": "海面",
    "reading": "hǎimiàn",
    "meaning": "offing, sea surface, offshore, sea level, rim",
    "grade": 7
  },
  {
    "id": 6967,
    "traditional": "海內外",
    "simplified": "海内外",
    "reading": "hǎinèiwài",
    "meaning": "domestic and international, at home and abroad",
    "grade": 7
  },
  {
    "id": 6968,
    "traditional": "海灘",
    "simplified": "海滩",
    "reading": "hǎitān",
    "meaning": "seabeach, seaside, sea beach, foreshore, beach",
    "grade": 7
  },
  {
    "id": 6969,
    "traditional": "海峽",
    "simplified": "海峡",
    "reading": "hǎixiá",
    "meaning": "narrow, channel, straits, gullet, sound, strait, gut",
    "grade": 7
  },
  {
    "id": 6970,
    "traditional": "海嘯",
    "simplified": "海啸",
    "reading": "hǎixiào",
    "meaning": "tsunami",
    "grade": 7
  },
  {
    "id": 6971,
    "traditional": "海域",
    "simplified": "海域",
    "reading": "hǎiyù",
    "meaning": "water, waters, maritime space, sea area",
    "grade": 7
  },
  {
    "id": 6972,
    "traditional": "海運",
    "simplified": "海运",
    "reading": "hǎiyùn",
    "meaning": "sea transportation, ocean shipping",
    "grade": 7
  },
  {
    "id": 6973,
    "traditional": "海藻",
    "simplified": "海藻",
    "reading": "hǎizǎo",
    "meaning": "seaweed, marine alga, kelp",
    "grade": 7
  },
  {
    "id": 6974,
    "traditional": "駭人聽聞",
    "simplified": "骇人听闻",
    "reading": "hàirén-tīngwén",
    "meaning": "shocking, horrifying, atrocious, terrible",
    "grade": 7
  },
  {
    "id": 6975,
    "traditional": "害蟲",
    "simplified": "害虫",
    "reading": "hàichóng",
    "meaning": "injurious insect, pest",
    "grade": 7
  },
  {
    "id": 6976,
    "traditional": "害臊",
    "simplified": "害臊",
    "reading": "hài sào",
    "meaning": "to be bashful, to feel ashamed",
    "grade": 7
  },
  {
    "id": 6977,
    "traditional": "害羞",
    "simplified": "害羞",
    "reading": "hài xiū",
    "meaning": "be shy, bashful, coy, be bashful, shy, blush",
    "grade": 7
  },
  {
    "id": 6978,
    "traditional": "酣暢",
    "simplified": "酣畅",
    "reading": "hānchàng",
    "meaning": "unrestrained, cheerful lack of inhibition, esp. for drinking or sleeping, to drink with abandon",
    "grade": 7
  },
  {
    "id": 6979,
    "traditional": "酣睡",
    "simplified": "酣睡",
    "reading": "hānshuì",
    "meaning": "to sleep soundly, to fall into a deep sleep",
    "grade": 7
  },
  {
    "id": 6980,
    "traditional": "含糊",
    "simplified": "含糊",
    "reading": "hánhu",
    "meaning": "ambiguous, vague, careless, perfunctory",
    "grade": 7
  },
  {
    "id": 6981,
    "traditional": "含蓄",
    "simplified": "含蓄",
    "reading": "hánxù",
    "meaning": "to contain, to hold, (of a person or style etc) reserved, restrained, (of words, writings) full of hidden meaning, implicit, veiled (criticism)",
    "grade": 7
  },
  {
    "id": 6982,
    "traditional": "函授",
    "simplified": "函授",
    "reading": "hánshòu",
    "meaning": "to teach by correspondence",
    "grade": 7
  },
  {
    "id": 6983,
    "traditional": "涵蓋",
    "simplified": "涵盖",
    "reading": "hángài",
    "meaning": "cover, include",
    "grade": 7
  },
  {
    "id": 6984,
    "traditional": "涵義",
    "simplified": "涵义",
    "reading": "hányì",
    "meaning": "content, meaning, connotation, implication",
    "grade": 7
  },
  {
    "id": 6985,
    "traditional": "罕見",
    "simplified": "罕见",
    "reading": "hǎnjiàn",
    "meaning": "rare, rarely seen",
    "grade": 7
  },
  {
    "id": 6986,
    "traditional": "汗水",
    "simplified": "汗水",
    "reading": "hànshuǐ",
    "meaning": "sweat, perspiration",
    "grade": 7
  },
  {
    "id": 6987,
    "traditional": "旱",
    "simplified": "旱",
    "reading": "hàn",
    "meaning": "drought",
    "grade": 7
  },
  {
    "id": 6988,
    "traditional": "旱災",
    "simplified": "旱灾",
    "reading": "hànzāi",
    "meaning": "drought",
    "grade": 7
  },
  {
    "id": 6989,
    "traditional": "焊",
    "simplified": "焊",
    "reading": "hàn",
    "meaning": "to weld, to solder, variant of 焊[han4]",
    "grade": 7
  },
  {
    "id": 6990,
    "traditional": "捍衛",
    "simplified": "捍卫",
    "reading": "hànwèi",
    "meaning": "to defend, to uphold, to safeguard",
    "grade": 7
  },
  {
    "id": 6991,
    "traditional": "行家",
    "simplified": "行家",
    "reading": "hángjia",
    "meaning": "connoisseur, expert, veteran",
    "grade": 7
  },
  {
    "id": 6992,
    "traditional": "行列",
    "simplified": "行列",
    "reading": "hángliè",
    "meaning": "parade, procession, ranks, waiting line, cortege, promenade, row, single file, Indian file, column, queue, lineup, file, rank",
    "grade": 7
  },
  {
    "id": 6993,
    "traditional": "行情",
    "simplified": "行情",
    "reading": "hángqíng",
    "meaning": "prices, quotation, (market) quotations, market, price, quotations",
    "grade": 7
  },
  {
    "id": 6994,
    "traditional": "航海",
    "simplified": "航海",
    "reading": "hánghǎi",
    "meaning": "sailing, navigation, voyage by sea",
    "grade": 7
  },
  {
    "id": 6995,
    "traditional": "航天",
    "simplified": "航天",
    "reading": "hángtiān",
    "meaning": "space flight",
    "grade": 7
  },
  {
    "id": 6996,
    "traditional": "航天員",
    "simplified": "航天员",
    "reading": "hángtiānyuán",
    "meaning": "astronaut",
    "grade": 7
  },
  {
    "id": 6997,
    "traditional": "航運",
    "simplified": "航运",
    "reading": "hángyùn",
    "meaning": "shipping, transport",
    "grade": 7
  },
  {
    "id": 6998,
    "traditional": "航行",
    "simplified": "航行",
    "reading": "hángxíng",
    "meaning": "sail, fly",
    "grade": 7
  },
  {
    "id": 6999,
    "traditional": "毫不",
    "simplified": "毫不",
    "reading": "hàobù",
    "meaning": "nohow, not at all, no, no more, none, nowise, nothing",
    "grade": 7
  },
  {
    "id": 7000,
    "traditional": "毫不猶豫",
    "simplified": "毫不犹豫",
    "reading": "háobù yóuyù",
    "meaning": "without the slightest hesitation",
    "grade": 7
  },
  {
    "id": 7001,
    "traditional": "毫無",
    "simplified": "毫无",
    "reading": "háowú",
    "meaning": "completely lack",
    "grade": 7
  },
  {
    "id": 7002,
    "traditional": "豪華",
    "simplified": "豪华",
    "reading": "háohuá",
    "meaning": "luxurious, sumptuous",
    "grade": 7
  },
  {
    "id": 7003,
    "traditional": "好歹",
    "simplified": "好歹",
    "reading": "hǎodǎi",
    "meaning": "good and bad, most unfortunate occurrence, in any case, whatever",
    "grade": 7
  },
  {
    "id": 7004,
    "traditional": "好比",
    "simplified": "好比",
    "reading": "hǎobǐ",
    "meaning": "like, may be likened to, be just like, can be compared to",
    "grade": 7
  },
  {
    "id": 7005,
    "traditional": "好感",
    "simplified": "好感",
    "reading": "hǎogǎn",
    "meaning": "good opinion, favorable impression",
    "grade": 7
  },
  {
    "id": 7006,
    "traditional": "好壞",
    "simplified": "好坏",
    "reading": "hǎo-huài",
    "meaning": "good or bad, good and bad, standard, quality",
    "grade": 7
  },
  {
    "id": 7007,
    "traditional": "好家伙",
    "simplified": "好家伙",
    "reading": "hǎojiāhuo",
    "meaning": "my God!, oh boy!, man!",
    "grade": 7
  },
  {
    "id": 7008,
    "traditional": "好評",
    "simplified": "好评",
    "reading": "hǎopíng",
    "meaning": "reputation, high opinion, favorable comment, conceit, favorable review, esteem",
    "grade": 7
  },
  {
    "id": 7009,
    "traditional": "好說",
    "simplified": "好说",
    "reading": "hǎoshuō",
    "meaning": "easy to deal with, not a problem, (polite answer) you flatter me",
    "grade": 7
  },
  {
    "id": 7010,
    "traditional": "好笑",
    "simplified": "好笑",
    "reading": "hǎoxiào",
    "meaning": "laughable, funny",
    "grade": 7
  },
  {
    "id": 7011,
    "traditional": "好心",
    "simplified": "好心",
    "reading": "hǎoxīn",
    "meaning": "kindness, good intentions",
    "grade": 7
  },
  {
    "id": 7012,
    "traditional": "好心人",
    "simplified": "好心人",
    "reading": "hǎoxīnrén",
    "meaning": "Good people",
    "grade": 7
  },
  {
    "id": 7013,
    "traditional": "好意",
    "simplified": "好意",
    "reading": "hǎoyì",
    "meaning": "good intention, kindness",
    "grade": 7
  },
  {
    "id": 7014,
    "traditional": "好在",
    "simplified": "好在",
    "reading": "hǎozài",
    "meaning": "luckily, fortunately",
    "grade": 7
  },
  {
    "id": 7015,
    "traditional": "號稱",
    "simplified": "号称",
    "reading": "hàochēng",
    "meaning": "be known as, claim to be",
    "grade": 7
  },
  {
    "id": 7016,
    "traditional": "好客",
    "simplified": "好客",
    "reading": "hàokè",
    "meaning": "hospitality, to treat guests well, to enjoy having guests, hospitable, friendly",
    "grade": 7
  },
  {
    "id": 7017,
    "traditional": "好奇心",
    "simplified": "好奇心",
    "reading": "hàoqíxīn",
    "meaning": "interest in sth, curiosity, inquisitive",
    "grade": 7
  },
  {
    "id": 7018,
    "traditional": "耗",
    "simplified": "耗",
    "reading": "hào",
    "meaning": "to waste, to spend, to consume, to squander, news, (coll.) to delay, to dilly-dally",
    "grade": 7
  },
  {
    "id": 7019,
    "traditional": "耗費",
    "simplified": "耗费",
    "reading": "hàofèi",
    "meaning": "to waste, to spend, to consume, to squander",
    "grade": 7
  },
  {
    "id": 7020,
    "traditional": "耗時",
    "simplified": "耗时",
    "reading": "hàoshí",
    "meaning": "time-consuming, to take a period of (x amount of time)",
    "grade": 7
  },
  {
    "id": 7021,
    "traditional": "浩劫",
    "simplified": "浩劫",
    "reading": "hàojié",
    "meaning": "calamity, catastrophe, apocalypse",
    "grade": 7
  },
  {
    "id": 7022,
    "traditional": "呵護",
    "simplified": "呵护",
    "reading": "hēhù",
    "meaning": "to bless, to cherish, to take good care of, to conserve",
    "grade": 7
  },
  {
    "id": 7023,
    "traditional": "禾苗",
    "simplified": "禾苗",
    "reading": "hémiáo",
    "meaning": "seedling (of rice or other grain), CL:棵[ke1]",
    "grade": 7
  },
  {
    "id": 7024,
    "traditional": "合唱",
    "simplified": "合唱",
    "reading": "héchàng",
    "meaning": "choir, do a chorus, sing in a chorus, chorus, descant",
    "grade": 7
  },
  {
    "id": 7025,
    "traditional": "合伙",
    "simplified": "合伙",
    "reading": "héhuǒ",
    "meaning": "to act jointly, to form a partnership",
    "grade": 7
  },
  {
    "id": 7026,
    "traditional": "合乎",
    "simplified": "合乎",
    "reading": "héhū",
    "meaning": "conform with/to, accord, tally with, correspond to, accord with, conform_to, conform with, conform to, accord/tally with, to",
    "grade": 7
  },
  {
    "id": 7027,
    "traditional": "合計",
    "simplified": "合计",
    "reading": "héji",
    "meaning": "to add up the total, to figure what sth amounts to, to consider",
    "grade": 7
  },
  {
    "id": 7028,
    "traditional": "合情合理",
    "simplified": "合情合理",
    "reading": "héqíng-hélǐ",
    "meaning": "reasonable and fair (idiom)",
    "grade": 7
  },
  {
    "id": 7029,
    "traditional": "合影",
    "simplified": "合影",
    "reading": "héyǐng",
    "meaning": "to take a joint photo, group photo",
    "grade": 7
  },
  {
    "id": 7030,
    "traditional": "合資",
    "simplified": "合资",
    "reading": "hézī",
    "meaning": "joint venture, collect money, pool capital, raise funds, enter into partnership",
    "grade": 7
  },
  {
    "id": 7031,
    "traditional": "合作社",
    "simplified": "合作社",
    "reading": "hézuòshè",
    "meaning": "cooperative, cooperation, co-op, artel",
    "grade": 7
  },
  {
    "id": 7032,
    "traditional": "何必",
    "simplified": "何必",
    "reading": "hébì",
    "meaning": "why must...?, there is no need",
    "grade": 7
  },
  {
    "id": 7033,
    "traditional": "何處",
    "simplified": "何处",
    "reading": "héchù",
    "meaning": "where",
    "grade": 7
  },
  {
    "id": 7034,
    "traditional": "何苦",
    "simplified": "何苦",
    "reading": "hékǔ",
    "meaning": "why bother?, is it worth the trouble?",
    "grade": 7
  },
  {
    "id": 7035,
    "traditional": "何況",
    "simplified": "何况",
    "reading": "hékuàng",
    "meaning": "conj.: much less, let alone, all the more",
    "grade": 7
  },
  {
    "id": 7036,
    "traditional": "何時",
    "simplified": "何时",
    "reading": "héshí",
    "meaning": "when",
    "grade": 7
  },
  {
    "id": 7037,
    "traditional": "和藹",
    "simplified": "和蔼",
    "reading": "héǎi",
    "meaning": "kindly, nice, amiable",
    "grade": 7
  },
  {
    "id": 7038,
    "traditional": "和解",
    "simplified": "和解",
    "reading": "héjiě",
    "meaning": "become reconciled",
    "grade": 7
  },
  {
    "id": 7039,
    "traditional": "和睦",
    "simplified": "和睦",
    "reading": "hémù",
    "meaning": "peaceful relations, harmonious",
    "grade": 7
  },
  {
    "id": 7040,
    "traditional": "和平共處",
    "simplified": "和平共处",
    "reading": "hépínggòngchǔ",
    "meaning": "peaceful coexistence of nations, societies etc",
    "grade": 7
  },
  {
    "id": 7041,
    "traditional": "和氣",
    "simplified": "和气",
    "reading": "héqi",
    "meaning": "friendly, polite, amiable",
    "grade": 7
  },
  {
    "id": 7042,
    "traditional": "和尚",
    "simplified": "和尚",
    "reading": "héshang",
    "meaning": "bonze, talapoin, monk, Bhikku, Buddhist monk",
    "grade": 7
  },
  {
    "id": 7043,
    "traditional": "河流",
    "simplified": "河流",
    "reading": "héliú",
    "meaning": "freshet, rivers, river",
    "grade": 7
  },
  {
    "id": 7044,
    "traditional": "河畔",
    "simplified": "河畔",
    "reading": "hépàn",
    "meaning": "riverside, river plain",
    "grade": 7
  },
  {
    "id": 7045,
    "traditional": "荷花",
    "simplified": "荷花",
    "reading": "héhuā",
    "meaning": "lotus",
    "grade": 7
  },
  {
    "id": 7046,
    "traditional": "核",
    "simplified": "核",
    "reading": "hé",
    "meaning": "pit, nuclear, nucleus, nut, sth. resembling a fruit stone, stone, core, nucleolus, hilum, kernel",
    "grade": 7
  },
  {
    "id": 7047,
    "traditional": "核電站",
    "simplified": "核电站",
    "reading": "hédiànzhàn",
    "meaning": "nuclear power plant",
    "grade": 7
  },
  {
    "id": 7048,
    "traditional": "核對",
    "simplified": "核对",
    "reading": "héduì",
    "meaning": "to check, to verify, to audit, to examine",
    "grade": 7
  },
  {
    "id": 7049,
    "traditional": "核能",
    "simplified": "核能",
    "reading": "hénéng",
    "meaning": "nuclear energy",
    "grade": 7
  },
  {
    "id": 7050,
    "traditional": "核桃",
    "simplified": "核桃",
    "reading": "hétao",
    "meaning": "walnut, CL:個|个[ge4],棵[ke1]",
    "grade": 7
  },
  {
    "id": 7051,
    "traditional": "核實",
    "simplified": "核实",
    "reading": "héshí",
    "meaning": "to verify, to check",
    "grade": 7
  },
  {
    "id": 7052,
    "traditional": "核武器",
    "simplified": "核武器",
    "reading": "héwǔqì",
    "meaning": "nuclear weapon",
    "grade": 7
  },
  {
    "id": 7053,
    "traditional": "賀信",
    "simplified": "贺信",
    "reading": "hèxìn",
    "meaning": "Congratulate",
    "grade": 7
  },
  {
    "id": 7054,
    "traditional": "賀電",
    "simplified": "贺电",
    "reading": "hèdiàn",
    "meaning": "congratulatory telegram",
    "grade": 7
  },
  {
    "id": 7055,
    "traditional": "喝彩",
    "simplified": "喝彩",
    "reading": "hè cǎi",
    "meaning": "to acclaim, to cheer",
    "grade": 7
  },
  {
    "id": 7056,
    "traditional": "赫然",
    "simplified": "赫然",
    "reading": "hèrán",
    "meaning": "with astonishment, with a shock, awe-inspiringly, impressively, furiously (angry)",
    "grade": 7
  },
  {
    "id": 7057,
    "traditional": "鶴立雞群",
    "simplified": "鹤立鸡群",
    "reading": "hèlì-jīqún",
    "meaning": "a crane in a flock of chicken (idiom), way above the common, manifestly superior",
    "grade": 7
  },
  {
    "id": 7058,
    "traditional": "黑客",
    "simplified": "黑客",
    "reading": "hēikè",
    "meaning": "hacker (computing) (loanword)",
    "grade": 7
  },
  {
    "id": 7059,
    "traditional": "黑馬",
    "simplified": "黑马",
    "reading": "hēimǎ",
    "meaning": "dark horse, fig. unexpected winner",
    "grade": 7
  },
  {
    "id": 7060,
    "traditional": "黑白",
    "simplified": "黑白",
    "reading": "hēibái",
    "meaning": "black and white, right and wrong, good and bad",
    "grade": 7
  },
  {
    "id": 7061,
    "traditional": "黑手",
    "simplified": "黑手",
    "reading": "hēishǒu",
    "meaning": "Blackman",
    "grade": 7
  },
  {
    "id": 7062,
    "traditional": "黑心",
    "simplified": "黑心",
    "reading": "hēixīn",
    "meaning": "ruthless and lacking in conscience, vicious mind full of hatred and jealousy, black core (flaw in pottery)",
    "grade": 7
  },
  {
    "id": 7063,
    "traditional": "嘿",
    "simplified": "嘿",
    "reading": "hēi",
    "meaning": "hey!",
    "grade": 7
  },
  {
    "id": 7064,
    "traditional": "痕跡",
    "simplified": "痕迹",
    "reading": "hénjì",
    "meaning": "spark, taint, scrape, show, imprint, trace, vestige, spoor, sign, track, nick, mark, print, scar, scratch, scent, ash, vestigial, trail, impression",
    "grade": 7
  },
  {
    "id": 7065,
    "traditional": "恨不得",
    "simplified": "恨不得",
    "reading": "hènbude",
    "meaning": "wishing one could do sth, to hate to be unable, itching to do sth",
    "grade": 7
  },
  {
    "id": 7066,
    "traditional": "哼",
    "simplified": "哼",
    "reading": "hēng",
    "meaning": "humph!",
    "grade": 7
  },
  {
    "id": 7067,
    "traditional": "橫七豎八",
    "simplified": "横七竖八",
    "reading": "héngqī-shùbā",
    "meaning": "in disorder, at sixes and sevens (idiom)",
    "grade": 7
  },
  {
    "id": 7068,
    "traditional": "橫向",
    "simplified": "横向",
    "reading": "héngxiàng",
    "meaning": "horizontal, orthogonal, perpendicular, lateral, crosswise",
    "grade": 7
  },
  {
    "id": 7069,
    "traditional": "橫",
    "simplified": "横",
    "reading": "héng",
    "meaning": "horizontal, across, (horizontal character stroke), harsh and unreasonable, unexpected",
    "grade": 7
  },
  {
    "id": 7070,
    "traditional": "轟",
    "simplified": "轰",
    "reading": "hōng",
    "meaning": "explosion, bang, boom, rumble, to attack, to shoo away, to expel",
    "grade": 7
  },
  {
    "id": 7071,
    "traditional": "轟動",
    "simplified": "轰动",
    "reading": "hōngdòng",
    "meaning": "to cause a sensation, to create a stir in (a place), commotion, controversy",
    "grade": 7
  },
  {
    "id": 7072,
    "traditional": "轟炸",
    "simplified": "轰炸",
    "reading": "hōngzhà",
    "meaning": "bomb",
    "grade": 7
  },
  {
    "id": 7073,
    "traditional": "哄",
    "simplified": "哄",
    "reading": "hōng",
    "meaning": "roar of laughter (onom.), hubbub, to roar (as a crowd), to deceive, to coax, to amuse (a child), tumult, uproar, commotion, disturbance",
    "grade": 7
  },
  {
    "id": 7074,
    "traditional": "哄堂大笑",
    "simplified": "哄堂大笑",
    "reading": "hōngtáng-dàxiào",
    "meaning": "the whole room roaring with laughter (idiom)",
    "grade": 7
  },
  {
    "id": 7075,
    "traditional": "烘乾",
    "simplified": "烘干",
    "reading": "hōng gān",
    "meaning": "to dry over a stove",
    "grade": 7
  },
  {
    "id": 7076,
    "traditional": "烘托",
    "simplified": "烘托",
    "reading": "hōngtuō",
    "meaning": "background (of a painting), backdrop, a foil (to set off something to advantage), to offset (something to advantage)",
    "grade": 7
  },
  {
    "id": 7077,
    "traditional": "弘揚",
    "simplified": "弘扬",
    "reading": "hóngyáng",
    "meaning": "to enhance, to promote, to enrich",
    "grade": 7
  },
  {
    "id": 7078,
    "traditional": "紅燈",
    "simplified": "红灯",
    "reading": "hóngdēng",
    "meaning": "red light",
    "grade": 7
  },
  {
    "id": 7079,
    "traditional": "紅火",
    "simplified": "红火",
    "reading": "hónghuo",
    "meaning": "prosperous",
    "grade": 7
  },
  {
    "id": 7080,
    "traditional": "紅撲撲",
    "simplified": "红扑扑",
    "reading": "hóngpūpū",
    "meaning": "red, rosy, flushed",
    "grade": 7
  },
  {
    "id": 7081,
    "traditional": "紅潤",
    "simplified": "红润",
    "reading": "hóngrùn",
    "meaning": "ruddy, rosy, florid",
    "grade": 7
  },
  {
    "id": 7082,
    "traditional": "紅薯",
    "simplified": "红薯",
    "reading": "hóngshǔ",
    "meaning": "sweet potato",
    "grade": 7
  },
  {
    "id": 7083,
    "traditional": "紅眼",
    "simplified": "红眼",
    "reading": "hóngyǎn",
    "meaning": "Red eye",
    "grade": 7
  },
  {
    "id": 7084,
    "traditional": "宏觀",
    "simplified": "宏观",
    "reading": "hóngguān",
    "meaning": "macro-, macroscopic, holistic",
    "grade": 7
  },
  {
    "id": 7085,
    "traditional": "宏偉",
    "simplified": "宏伟",
    "reading": "hóngwěi",
    "meaning": "grand, imposing, magnificent",
    "grade": 7
  },
  {
    "id": 7086,
    "traditional": "洪亮",
    "simplified": "洪亮",
    "reading": "hóngliàng",
    "meaning": "loud and clear, resonant",
    "grade": 7
  },
  {
    "id": 7087,
    "traditional": "喉嚨",
    "simplified": "喉咙",
    "reading": "hóulong",
    "meaning": "throat",
    "grade": 7
  },
  {
    "id": 7088,
    "traditional": "吼",
    "simplified": "吼",
    "reading": "hǒu",
    "meaning": "to roar, to howl, to shriek, roar or howl of an animal, bellow of rage",
    "grade": 7
  },
  {
    "id": 7089,
    "traditional": "後備",
    "simplified": "后备",
    "reading": "hòubèi",
    "meaning": "reserve, backup",
    "grade": 7
  },
  {
    "id": 7090,
    "traditional": "後備箱",
    "simplified": "后备箱",
    "reading": "hòubèixiāng",
    "meaning": "trunk, boot (of a car)",
    "grade": 7
  },
  {
    "id": 7091,
    "traditional": "後代",
    "simplified": "后代",
    "reading": "hòudài",
    "meaning": "descendant, posterity, descendants, later generations, ages, limb, later ages, get, descendent, later periods, offspring, later periods in history, progeny",
    "grade": 7
  },
  {
    "id": 7092,
    "traditional": "後盾",
    "simplified": "后盾",
    "reading": "hòudùn",
    "meaning": "support, backing",
    "grade": 7
  },
  {
    "id": 7093,
    "traditional": "後顧之憂",
    "simplified": "后顾之忧",
    "reading": "hòugùzhīyōu",
    "meaning": "fears of trouble in the rear (idiom), family worries (obstructing freedom of action), worries about the future consequences, often in negative expressions, meaning 'no worries about anything'",
    "grade": 7
  },
  {
    "id": 7094,
    "traditional": "後期",
    "simplified": "后期",
    "reading": "hòuqī",
    "meaning": "late stage, later period",
    "grade": 7
  },
  {
    "id": 7095,
    "traditional": "後勤",
    "simplified": "后勤",
    "reading": "hòuqín",
    "meaning": "logistics",
    "grade": 7
  },
  {
    "id": 7096,
    "traditional": "後人",
    "simplified": "后人",
    "reading": "hòurén",
    "meaning": "posperity, descendants, posterity, later generations",
    "grade": 7
  },
  {
    "id": 7097,
    "traditional": "後臺",
    "simplified": "后台",
    "reading": "hòutái",
    "meaning": "backstage area, behind-the-scenes supporter, (computing) back-end, background",
    "grade": 7
  },
  {
    "id": 7098,
    "traditional": "後退",
    "simplified": "后退",
    "reading": "hòutuì",
    "meaning": "to recoil, to draw back, to fall back, to retreat",
    "grade": 7
  },
  {
    "id": 7099,
    "traditional": "後續",
    "simplified": "后续",
    "reading": "hòuxù",
    "meaning": "follow up",
    "grade": 7
  },
  {
    "id": 7100,
    "traditional": "後遺癥",
    "simplified": "后遗症",
    "reading": "hòuyízhèng",
    "meaning": "aftereffect, hangover, sequela, sequelae",
    "grade": 7
  },
  {
    "id": 7101,
    "traditional": "後裔",
    "simplified": "后裔",
    "reading": "hòuyì",
    "meaning": "descendant",
    "grade": 7
  },
  {
    "id": 7102,
    "traditional": "後者",
    "simplified": "后者",
    "reading": "hòuzhě",
    "meaning": "latter, the latter",
    "grade": 7
  },
  {
    "id": 7103,
    "traditional": "厚道",
    "simplified": "厚道",
    "reading": "hòudao",
    "meaning": "kind and honest, generous, sincere",
    "grade": 7
  },
  {
    "id": 7104,
    "traditional": "厚度",
    "simplified": "厚度",
    "reading": "hòudù",
    "meaning": "thickness",
    "grade": 7
  },
  {
    "id": 7105,
    "traditional": "候選人",
    "simplified": "候选人",
    "reading": "hòuxuǎnrén",
    "meaning": "aspirant, nominee, campaigner, candidate, candidate (for election)",
    "grade": 7
  },
  {
    "id": 7106,
    "traditional": "呼風喚雨",
    "simplified": "呼风唤雨",
    "reading": "hūfēng-huànyǔ",
    "meaning": "to call the wind and summon the rain (idiom), to exercise magical powers, fig. to stir up troubles",
    "grade": 7
  },
  {
    "id": 7107,
    "traditional": "呼喚",
    "simplified": "呼唤",
    "reading": "hūhuàn",
    "meaning": "to call out (a name etc), to shout",
    "grade": 7
  },
  {
    "id": 7108,
    "traditional": "呼救",
    "simplified": "呼救",
    "reading": "hūjiù",
    "meaning": "to call for help",
    "grade": 7
  },
  {
    "id": 7109,
    "traditional": "呼聲",
    "simplified": "呼声",
    "reading": "hūshēng",
    "meaning": "a shout, fig. opinion or demand, esp. expressed by a group",
    "grade": 7
  },
  {
    "id": 7110,
    "traditional": "呼應",
    "simplified": "呼应",
    "reading": "hūyìng",
    "meaning": "to conform (with), to echo, to correlate well, (linguistics) agreement",
    "grade": 7
  },
  {
    "id": 7111,
    "traditional": "呼吁",
    "simplified": "呼吁",
    "reading": "hūyù",
    "meaning": "to call on (sb to do sth), to appeal (to), an appeal",
    "grade": 7
  },
  {
    "id": 7112,
    "traditional": "忽高忽低",
    "simplified": "忽高忽低",
    "reading": "hūgāohūdī",
    "meaning": "I suddenly",
    "grade": 7
  },
  {
    "id": 7113,
    "traditional": "忽悠",
    "simplified": "忽悠",
    "reading": "hūyou",
    "meaning": "to rock, to sway, to flicker (e.g. of lights reflected on water), to flutter (e.g. of a flag), to trick sb into doing sth, to dupe, to con",
    "grade": 7
  },
  {
    "id": 7114,
    "traditional": "胡鬧",
    "simplified": "胡闹",
    "reading": "húnào",
    "meaning": "to act willfully and make a scene, to make trouble",
    "grade": 7
  },
  {
    "id": 7115,
    "traditional": "胡說",
    "simplified": "胡说",
    "reading": "húshuō",
    "meaning": "to talk nonsense, drivel",
    "grade": 7
  },
  {
    "id": 7116,
    "traditional": "胡思亂想",
    "simplified": "胡思乱想",
    "reading": "húsī-luànxiǎng",
    "meaning": "to indulge in flights of fancy (idiom), to let one's imagination run wild",
    "grade": 7
  },
  {
    "id": 7117,
    "traditional": "湖泊",
    "simplified": "湖泊",
    "reading": "húpō",
    "meaning": "lake",
    "grade": 7
  },
  {
    "id": 7118,
    "traditional": "糊",
    "simplified": "糊",
    "reading": "hū",
    "meaning": "congee, making a living, paste, cream",
    "grade": 7
  },
  {
    "id": 7119,
    "traditional": "糊塗",
    "simplified": "糊涂",
    "reading": "hútu",
    "meaning": "muddled, silly, confused",
    "grade": 7
  },
  {
    "id": 7120,
    "traditional": "互補",
    "simplified": "互补",
    "reading": "hùbǔ",
    "meaning": "complementary, to complement each other",
    "grade": 7
  },
  {
    "id": 7121,
    "traditional": "互訪",
    "simplified": "互访",
    "reading": "hùfǎng",
    "meaning": "exchange visits",
    "grade": 7
  },
  {
    "id": 7122,
    "traditional": "互信",
    "simplified": "互信",
    "reading": "hùxìn",
    "meaning": "mutual trust",
    "grade": 7
  },
  {
    "id": 7123,
    "traditional": "互助",
    "simplified": "互助",
    "reading": "hùzhù",
    "meaning": "Huzhu Tuzu autonomous county in Haidong prefecture 海東地區|海东地区[Hai3 dong1 di4 qu1], Qinghai, to help each other",
    "grade": 7
  },
  {
    "id": 7124,
    "traditional": "護理",
    "simplified": "护理",
    "reading": "hùlǐ",
    "meaning": "nursing",
    "grade": 7
  },
  {
    "id": 7125,
    "traditional": "花瓣",
    "simplified": "花瓣",
    "reading": "huābàn",
    "meaning": "petal, CL:片[pian4]",
    "grade": 7
  },
  {
    "id": 7126,
    "traditional": "花卉",
    "simplified": "花卉",
    "reading": "huāhuì",
    "meaning": "flowers and plants",
    "grade": 7
  },
  {
    "id": 7127,
    "traditional": "花紋",
    "simplified": "花纹",
    "reading": "huāwén",
    "meaning": "decorative design",
    "grade": 7
  },
  {
    "id": 7128,
    "traditional": "花樣",
    "simplified": "花样",
    "reading": "huāyàng",
    "meaning": "pattern, variety",
    "grade": 7
  },
  {
    "id": 7129,
    "traditional": "華麗",
    "simplified": "华丽",
    "reading": "huálì",
    "meaning": "magnificent, gorgeous",
    "grade": 7
  },
  {
    "id": 7130,
    "traditional": "划算",
    "simplified": "划算",
    "reading": "huásuàn",
    "meaning": "to calculate, to weigh (pros and cons), to view as profitable, worthwhile, value for money, cost-effective",
    "grade": 7
  },
  {
    "id": 7131,
    "traditional": "華僑",
    "simplified": "华侨",
    "reading": "huáqiáo",
    "meaning": "overseas Chinese",
    "grade": 7
  },
  {
    "id": 7132,
    "traditional": "華裔",
    "simplified": "华裔",
    "reading": "huáyì",
    "meaning": "foreign citizen of Ch. origin",
    "grade": 7
  },
  {
    "id": 7133,
    "traditional": "嘩變",
    "simplified": "哗变",
    "reading": "huábiàn",
    "meaning": "mutiny, rebellion",
    "grade": 7
  },
  {
    "id": 7134,
    "traditional": "嘩然",
    "simplified": "哗然",
    "reading": "huárán",
    "meaning": "in uproar, commotion, causing a storm of protest, tumultuous, variant of 嘩然|哗然[hua2 ran2]",
    "grade": 7
  },
  {
    "id": 7135,
    "traditional": "滑冰",
    "simplified": "滑冰",
    "reading": "huá bīng",
    "meaning": "to skate, skating",
    "grade": 7
  },
  {
    "id": 7136,
    "traditional": "滑稽",
    "simplified": "滑稽",
    "reading": "huáji",
    "meaning": "comical, funny, amusing, old pr. [gu3 ji1], huaji, a form of comedy performance popular in Shanghai, Jiangsu and Zhejiang",
    "grade": 7
  },
  {
    "id": 7137,
    "traditional": "滑梯",
    "simplified": "滑梯",
    "reading": "huátī",
    "meaning": "(children's) sliding board, a slide",
    "grade": 7
  },
  {
    "id": 7138,
    "traditional": "滑雪",
    "simplified": "滑雪",
    "reading": "huá xuě",
    "meaning": "to ski, skiing",
    "grade": 7
  },
  {
    "id": 7139,
    "traditional": "化肥",
    "simplified": "化肥",
    "reading": "huàféi",
    "meaning": "fertilizer",
    "grade": 7
  },
  {
    "id": 7140,
    "traditional": "化身",
    "simplified": "化身",
    "reading": "huàshēn",
    "meaning": "incarnation, reincarnation, embodiment (of abstract idea), personification",
    "grade": 7
  },
  {
    "id": 7141,
    "traditional": "化纖",
    "simplified": "化纤",
    "reading": "huàxiān",
    "meaning": "synthetic fiber",
    "grade": 7
  },
  {
    "id": 7142,
    "traditional": "化險為夷",
    "simplified": "化险为夷",
    "reading": "huàxiǎn-wéiyí",
    "meaning": "to turn peril into safety (idiom), to avert disaster",
    "grade": 7
  },
  {
    "id": 7143,
    "traditional": "化驗",
    "simplified": "化验",
    "reading": "huàyàn",
    "meaning": "laboratory test, chemical experiment, assay",
    "grade": 7
  },
  {
    "id": 7144,
    "traditional": "化妝",
    "simplified": "化妆",
    "reading": "huà zhuāng",
    "meaning": "make_up, bepaint, masquerade, put on make-up, make up, apply makeup, titivate",
    "grade": 7
  },
  {
    "id": 7145,
    "traditional": "劃時代",
    "simplified": "划时代",
    "reading": "huàshídài",
    "meaning": "epoch-marking",
    "grade": 7
  },
  {
    "id": 7146,
    "traditional": "畫冊",
    "simplified": "画册",
    "reading": "huàcè",
    "meaning": "picture album",
    "grade": 7
  },
  {
    "id": 7147,
    "traditional": "畫龍點睛",
    "simplified": "画龙点睛",
    "reading": "huàlóng-diǎnjīng",
    "meaning": "to paint a dragon and dot in the eyes (idiom), fig. to add the vital finishing touch, the crucial point that brings the subject to life, a few words to clinch the point",
    "grade": 7
  },
  {
    "id": 7148,
    "traditional": "畫蛇添足",
    "simplified": "画蛇添足",
    "reading": "huàshé-tiānzú",
    "meaning": "lit. draw legs on a snake (idiom), fig. to ruin the effect by adding sth superfluous, to overdo it",
    "grade": 7
  },
  {
    "id": 7149,
    "traditional": "畫展",
    "simplified": "画展",
    "reading": "huàzhǎn",
    "meaning": "art/paintings exhibition, art_exhibition, exhibition of paintings, art exhibition, art, paintings exhibition",
    "grade": 7
  },
  {
    "id": 7150,
    "traditional": "話費",
    "simplified": "话费",
    "reading": "huàfèi",
    "meaning": "call charge",
    "grade": 7
  },
  {
    "id": 7151,
    "traditional": "話筒",
    "simplified": "话筒",
    "reading": "huàtǒng",
    "meaning": "microphone, (telephone) receiver, handset",
    "grade": 7
  },
  {
    "id": 7152,
    "traditional": "話語",
    "simplified": "话语",
    "reading": "huàyǔ",
    "meaning": "utterance, discourse, word, speech, spoken language, endearment, remark",
    "grade": 7
  },
  {
    "id": 7153,
    "traditional": "懷抱",
    "simplified": "怀抱",
    "reading": "huáibào",
    "meaning": "ambition, embrace",
    "grade": 7
  },
  {
    "id": 7154,
    "traditional": "懷舊",
    "simplified": "怀旧",
    "reading": "huáijiù",
    "meaning": "fond remembrance of times past, nostalgia",
    "grade": 7
  },
  {
    "id": 7155,
    "traditional": "懷裡",
    "simplified": "怀里",
    "reading": "huáilǐ",
    "meaning": "embrace, bosom",
    "grade": 7
  },
  {
    "id": 7156,
    "traditional": "懷孕",
    "simplified": "怀孕",
    "reading": "huái yùn",
    "meaning": "gravid, expect, in a family way, with child, pregnant, be pregnant, gestate, impregnate, carry, bear, carry to term, be expecting, conceive",
    "grade": 7
  },
  {
    "id": 7157,
    "traditional": "懷著",
    "simplified": "怀着",
    "reading": "huáizhe",
    "meaning": "Be with",
    "grade": 7
  },
  {
    "id": 7158,
    "traditional": "槐樹",
    "simplified": "槐树",
    "reading": "huáishù",
    "meaning": "locust tree (Sophora japonica)",
    "grade": 7
  },
  {
    "id": 7159,
    "traditional": "壞事",
    "simplified": "坏事",
    "reading": "huàishì",
    "meaning": "rascality, evildoing, misdoing, villainy, roguery, bad thing, malfeasance, evil deed, caper",
    "grade": 7
  },
  {
    "id": 7160,
    "traditional": "歡呼",
    "simplified": "欢呼",
    "reading": "huānhū",
    "meaning": "to cheer for, to acclaim",
    "grade": 7
  },
  {
    "id": 7161,
    "traditional": "歡聚",
    "simplified": "欢聚",
    "reading": "huānjù",
    "meaning": "to get together socially, to celebrate, party, celebration",
    "grade": 7
  },
  {
    "id": 7162,
    "traditional": "歡快",
    "simplified": "欢快",
    "reading": "huānkuài",
    "meaning": "cheerful and lighthearted, lively",
    "grade": 7
  },
  {
    "id": 7163,
    "traditional": "歡聲笑語",
    "simplified": "欢声笑语",
    "reading": "huānshēng-xiàoyǔ",
    "meaning": "Laughter",
    "grade": 7
  },
  {
    "id": 7164,
    "traditional": "還款",
    "simplified": "还款",
    "reading": "huán kuǎn",
    "meaning": "repayment, to pay back money",
    "grade": 7
  },
  {
    "id": 7165,
    "traditional": "還原",
    "simplified": "还原",
    "reading": "huán yuán",
    "meaning": "to restore to the original state, to reconstruct (an event), reduction (chemistry)",
    "grade": 7
  },
  {
    "id": 7166,
    "traditional": "環球",
    "simplified": "环球",
    "reading": "huánqiú",
    "meaning": "around the world, worldwide",
    "grade": 7
  },
  {
    "id": 7167,
    "traditional": "環繞",
    "simplified": "环绕",
    "reading": "huánrào",
    "meaning": "circle, environ, encincture, encirclement, hoop, surround, cincture, gird, circumvolute, sphere, skirt, encircle, circumfluent, enwreathe, revolve around, border, girdle, girth, enring, rim",
    "grade": 7
  },
  {
    "id": 7168,
    "traditional": "緩",
    "simplified": "缓",
    "reading": "huǎn",
    "meaning": "slow, unhurried, sluggish, gradual, not tense, relaxed, to postpone, to defer, to stall, to stave off, to revive, to recuperate",
    "grade": 7
  },
  {
    "id": 7169,
    "traditional": "緩和",
    "simplified": "缓和",
    "reading": "huǎnhé",
    "meaning": "relax, ease up, mitigate, appease",
    "grade": 7
  },
  {
    "id": 7170,
    "traditional": "緩緩",
    "simplified": "缓缓",
    "reading": "huǎnhuǎn",
    "meaning": "postpone, postponement, little by little, slowly, put off, gradually, delay",
    "grade": 7
  },
  {
    "id": 7171,
    "traditional": "幻覺",
    "simplified": "幻觉",
    "reading": "huànjué",
    "meaning": "illusion, hallucination, figment of one's imagination",
    "grade": 7
  },
  {
    "id": 7172,
    "traditional": "幻影",
    "simplified": "幻影",
    "reading": "huànyǐng",
    "meaning": "phantom, mirage",
    "grade": 7
  },
  {
    "id": 7173,
    "traditional": "換成",
    "simplified": "换成",
    "reading": "huànchéng",
    "meaning": "change to",
    "grade": 7
  },
  {
    "id": 7174,
    "traditional": "緩慢",
    "simplified": "缓慢",
    "reading": "huǎnmàn",
    "meaning": "largo, slow",
    "grade": 7
  },
  {
    "id": 7175,
    "traditional": "換位",
    "simplified": "换位",
    "reading": "huàn wèi",
    "meaning": "transposition",
    "grade": 7
  },
  {
    "id": 7176,
    "traditional": "換言之",
    "simplified": "换言之",
    "reading": "huànyánzhī",
    "meaning": "in other words",
    "grade": 7
  },
  {
    "id": 7177,
    "traditional": "換取",
    "simplified": "换取",
    "reading": "huànqǔ",
    "meaning": "get in return, buy, exchange ... for, barter ... for, exchange sth. for, exchange",
    "grade": 7
  },
  {
    "id": 7178,
    "traditional": "患",
    "simplified": "患",
    "reading": "huàn",
    "meaning": "contract (an illness)",
    "grade": 7
  },
  {
    "id": 7179,
    "traditional": "喚起",
    "simplified": "唤起",
    "reading": "huànqǐ",
    "meaning": "arouse, call, recall",
    "grade": 7
  },
  {
    "id": 7180,
    "traditional": "患病",
    "simplified": "患病",
    "reading": "huàn bìng",
    "meaning": "to fall ill",
    "grade": 7
  },
  {
    "id": 7181,
    "traditional": "患有",
    "simplified": "患有",
    "reading": "huànyǒu",
    "meaning": "to contract (an illness), to be afflicted with, to suffer from",
    "grade": 7
  },
  {
    "id": 7182,
    "traditional": "煥發",
    "simplified": "焕发",
    "reading": "huànfā",
    "meaning": "to shine, to glow, to irradiate, to flash",
    "grade": 7
  },
  {
    "id": 7183,
    "traditional": "荒誕",
    "simplified": "荒诞",
    "reading": "huāngdàn",
    "meaning": "beyond belief, incredible, preposterous, fantastic",
    "grade": 7
  },
  {
    "id": 7184,
    "traditional": "荒",
    "simplified": "荒",
    "reading": "huāng",
    "meaning": "desolate, shortage, scarce, out of practice, absurd, uncultivated, to neglect",
    "grade": 7
  },
  {
    "id": 7185,
    "traditional": "荒涼",
    "simplified": "荒凉",
    "reading": "huāngliáng",
    "meaning": "desolate",
    "grade": 7
  },
  {
    "id": 7186,
    "traditional": "慌亂",
    "simplified": "慌乱",
    "reading": "huāngluàn",
    "meaning": "frenetic, hurried",
    "grade": 7
  },
  {
    "id": 7187,
    "traditional": "荒謬",
    "simplified": "荒谬",
    "reading": "huāngmiù",
    "meaning": "absurd, preposterous",
    "grade": 7
  },
  {
    "id": 7188,
    "traditional": "慌張",
    "simplified": "慌张",
    "reading": "huāngzhang",
    "meaning": "confused, flustered",
    "grade": 7
  },
  {
    "id": 7189,
    "traditional": "皇宮",
    "simplified": "皇宫",
    "reading": "huánggōng",
    "meaning": "imperial palace",
    "grade": 7
  },
  {
    "id": 7190,
    "traditional": "皇室",
    "simplified": "皇室",
    "reading": "huángshì",
    "meaning": "imperial family/house, royalty, imperial house, royal_family, imperial family, royal house, royal line, house, royal family",
    "grade": 7
  },
  {
    "id": 7191,
    "traditional": "皇上",
    "simplified": "皇上",
    "reading": "huángshang",
    "meaning": "the emperor, Your majesty the emperor, His imperial majesty",
    "grade": 7
  },
  {
    "id": 7192,
    "traditional": "皇后",
    "simplified": "皇后",
    "reading": "huánghòu",
    "meaning": "kaiserin, queen consort, queen, empress, queen_consort, Kaiserin",
    "grade": 7
  },
  {
    "id": 7193,
    "traditional": "恍然大悟",
    "simplified": "恍然大悟",
    "reading": "huǎngrán-dàwù",
    "meaning": "to suddenly realize, to suddenly see the light",
    "grade": 7
  },
  {
    "id": 7194,
    "traditional": "晃",
    "simplified": "晃",
    "reading": "huǎng",
    "meaning": "to dazzle, to flash past, to sway, to shake, to wander about",
    "grade": 7
  },
  {
    "id": 7195,
    "traditional": "黃昏",
    "simplified": "黄昏",
    "reading": "huánghūn",
    "meaning": "dusk, evening, nightfall",
    "grade": 7
  },
  {
    "id": 7196,
    "traditional": "謊言",
    "simplified": "谎言",
    "reading": "huǎngyán",
    "meaning": "lie",
    "grade": 7
  },
  {
    "id": 7197,
    "traditional": "謊話",
    "simplified": "谎话",
    "reading": "huǎnghuà",
    "meaning": "lie",
    "grade": 7
  },
  {
    "id": 7198,
    "traditional": "灰",
    "simplified": "灰",
    "reading": "huī",
    "meaning": "ash, dust",
    "grade": 7
  },
  {
    "id": 7199,
    "traditional": "灰塵",
    "simplified": "灰尘",
    "reading": "huīchén",
    "meaning": "dust",
    "grade": 7
  },
  {
    "id": 7200,
    "traditional": "晃蕩",
    "simplified": "晃荡",
    "reading": "huàngdang",
    "meaning": "to rock, to sway, to shake",
    "grade": 7
  },
  {
    "id": 7201,
    "traditional": "揮",
    "simplified": "挥",
    "reading": "huī",
    "meaning": "to wave, to brandish, to command, to conduct, to scatter, to disperse",
    "grade": 7
  },
  {
    "id": 7202,
    "traditional": "灰心",
    "simplified": "灰心",
    "reading": "huī xīn",
    "meaning": "to lose heart, to be discouraged",
    "grade": 7
  },
  {
    "id": 7203,
    "traditional": "輝煌",
    "simplified": "辉煌",
    "reading": "huīhuáng",
    "meaning": "glorious, effulgent, brilliant",
    "grade": 7
  },
  {
    "id": 7204,
    "traditional": "回扣",
    "simplified": "回扣",
    "reading": "huíkòu",
    "meaning": "brokerage, a commission paid to a middleman, euphemism for a bribe, a kickback",
    "grade": 7
  },
  {
    "id": 7205,
    "traditional": "回饋",
    "simplified": "回馈",
    "reading": "huíkuì",
    "meaning": "reward, feedback",
    "grade": 7
  },
  {
    "id": 7206,
    "traditional": "回落",
    "simplified": "回落",
    "reading": "huíluò",
    "meaning": "to fall back, to return to low level after a rise (in water level, price etc)",
    "grade": 7
  },
  {
    "id": 7207,
    "traditional": "迴首",
    "simplified": "回首",
    "reading": "huíshǒu",
    "meaning": "to turn around, to look back, to recollect",
    "grade": 7
  },
  {
    "id": 7208,
    "traditional": "回升",
    "simplified": "回升",
    "reading": "huíshēng",
    "meaning": "rise again (after a fall), pick up",
    "grade": 7
  },
  {
    "id": 7209,
    "traditional": "回味",
    "simplified": "回味",
    "reading": "huíwèi",
    "meaning": "to reflect on, to ponder over, aftertaste",
    "grade": 7
  },
  {
    "id": 7210,
    "traditional": "回歸",
    "simplified": "回归",
    "reading": "huíguī",
    "meaning": "reunification, return, regressive, regress",
    "grade": 7
  },
  {
    "id": 7211,
    "traditional": "回想",
    "simplified": "回想",
    "reading": "huíxiǎng",
    "meaning": "look_back, retrospect, recur, recollection, cut back, think back, echo, remember, reflect, recollect, recall, recapture, go back, flash_back, commemorate, retroject, retrace, flash back",
    "grade": 7
  },
  {
    "id": 7212,
    "traditional": "回憶錄",
    "simplified": "回忆录",
    "reading": "huíyìlù",
    "meaning": "memoir",
    "grade": 7
  },
  {
    "id": 7213,
    "traditional": "悔恨",
    "simplified": "悔恨",
    "reading": "huǐhèn",
    "meaning": "remorse, repentance",
    "grade": 7
  },
  {
    "id": 7214,
    "traditional": "毀壞",
    "simplified": "毁坏",
    "reading": "huǐhuài",
    "meaning": "to damage, to devastate, to vandalize, damage, destruction",
    "grade": 7
  },
  {
    "id": 7215,
    "traditional": "匯合",
    "simplified": "汇合",
    "reading": "huìhé",
    "meaning": "confluence, to converge, to join, to fuse, fusion",
    "grade": 7
  },
  {
    "id": 7216,
    "traditional": "彙集",
    "simplified": "汇集",
    "reading": "huìjí",
    "meaning": "to collect, to compile, to converge",
    "grade": 7
  },
  {
    "id": 7217,
    "traditional": "毀滅",
    "simplified": "毁灭",
    "reading": "huǐmiè",
    "meaning": "to perish, to ruin, to destroy",
    "grade": 7
  },
  {
    "id": 7218,
    "traditional": "會場",
    "simplified": "会场",
    "reading": "huìchǎng",
    "meaning": "assembly_hall, fraternity house, conference, assembly hall, conference/assembly hall, theater, meeting-place, chapterhouse, meeting place, venue, conference hall, frat house",
    "grade": 7
  },
  {
    "id": 7219,
    "traditional": "會面",
    "simplified": "会面",
    "reading": "huì miàn",
    "meaning": "to meet with, meeting",
    "grade": 7
  },
  {
    "id": 7220,
    "traditional": "會聚",
    "simplified": "汇聚",
    "reading": "huìjù",
    "meaning": "convergence, to come together",
    "grade": 7
  },
  {
    "id": 7221,
    "traditional": "會晤",
    "simplified": "会晤",
    "reading": "huìwù",
    "meaning": "to meet, meeting, conference",
    "grade": 7
  },
  {
    "id": 7222,
    "traditional": "會意",
    "simplified": "会意",
    "reading": "huìyì",
    "meaning": "combined ideogram (one of the Six Methods 六書|六书[liu4 shu1] of forming Chinese characters), Chinese character that combines the meanings of existing elements, also known as joint ideogram or associative compounds, to comprehend without being told explicitly, to cotton on, knowing (smile, glance etc)",
    "grade": 7
  },
  {
    "id": 7223,
    "traditional": "會診",
    "simplified": "会诊",
    "reading": "huì zhěn",
    "meaning": "consultation (medical), to meet for diagnosis, (by extension) consultation of different specialists",
    "grade": 7
  },
  {
    "id": 7224,
    "traditional": "繪聲繪色",
    "simplified": "绘声绘色",
    "reading": "huìshēng-huìsè",
    "meaning": "vivid and colorful (idiom), true to life, lively and realistic",
    "grade": 7
  },
  {
    "id": 7225,
    "traditional": "賄賂",
    "simplified": "贿赂",
    "reading": "huìlù",
    "meaning": "to bribe, a bribe",
    "grade": 7
  },
  {
    "id": 7226,
    "traditional": "婚紗",
    "simplified": "婚纱",
    "reading": "hūnshā",
    "meaning": "gown, wedding dress",
    "grade": 7
  },
  {
    "id": 7227,
    "traditional": "婚姻",
    "simplified": "婚姻",
    "reading": "hūnyīn",
    "meaning": "connubiality, wedlock, marital, union, marriage, married couple, matrimonial, match, man and wife, matrimony",
    "grade": 7
  },
  {
    "id": 7228,
    "traditional": "渾身",
    "simplified": "浑身",
    "reading": "húnshēn",
    "meaning": "all over, from head to foot",
    "grade": 7
  },
  {
    "id": 7229,
    "traditional": "魂",
    "simplified": "魂",
    "reading": "hún",
    "meaning": "soul, spirit, immortal soul, i.e. that can be detached from the body",
    "grade": 7
  },
  {
    "id": 7230,
    "traditional": "昏迷",
    "simplified": "昏迷",
    "reading": "hūnmí",
    "meaning": "to lose consciousness, to be in a coma, stupor, coma, stunned, disoriented",
    "grade": 7
  },
  {
    "id": 7231,
    "traditional": "混凝土",
    "simplified": "混凝土",
    "reading": "hùnníngtǔ",
    "meaning": "concrete",
    "grade": 7
  },
  {
    "id": 7232,
    "traditional": "混淆",
    "simplified": "混淆",
    "reading": "hùnxiáo",
    "meaning": "to obscure, to confuse, to mix up, to blur, to mislead",
    "grade": 7
  },
  {
    "id": 7233,
    "traditional": "混濁",
    "simplified": "混浊",
    "reading": "hùnzhuó",
    "meaning": "turbid, muddy, dirty",
    "grade": 7
  },
  {
    "id": 7234,
    "traditional": "豁",
    "simplified": "豁",
    "reading": "huō",
    "meaning": "to play Chinese finger-guessing game, opening, stake all, sacrifice, crack, slit, clear, open, exempt (from), liberal-minded",
    "grade": 7
  },
  {
    "id": 7235,
    "traditional": "豁出去",
    "simplified": "豁出去",
    "reading": "huō chuqu",
    "meaning": "to throw caution to the wind, to press one's luck, to go for broke",
    "grade": 7
  },
  {
    "id": 7236,
    "traditional": "活該",
    "simplified": "活该",
    "reading": "huógāi",
    "meaning": "(coll.) serve sb right, deservedly, ought, should",
    "grade": 7
  },
  {
    "id": 7237,
    "traditional": "活期",
    "simplified": "活期",
    "reading": "huóqī",
    "meaning": "(banking) current (account), checking (account), demand (deposit etc)",
    "grade": 7
  },
  {
    "id": 7238,
    "traditional": "活兒",
    "simplified": "活儿",
    "reading": "huór5",
    "meaning": "work, (lots of) things to do",
    "grade": 7
  },
  {
    "id": 7239,
    "traditional": "火暴",
    "simplified": "火暴",
    "reading": "huǒbào",
    "meaning": "fiery (temper), popular, flourishing, prosperous, lively, variant of 火爆[huo3 bao4]",
    "grade": 7
  },
  {
    "id": 7240,
    "traditional": "火鍋",
    "simplified": "火锅",
    "reading": "huǒguō",
    "meaning": "hotpot",
    "grade": 7
  },
  {
    "id": 7241,
    "traditional": "火候",
    "simplified": "火候",
    "reading": "huǒhou",
    "meaning": "heat control, maturity, crucial moment",
    "grade": 7
  },
  {
    "id": 7242,
    "traditional": "火花",
    "simplified": "火花",
    "reading": "huǒhuā",
    "meaning": "spark, sparkle",
    "grade": 7
  },
  {
    "id": 7243,
    "traditional": "火炬",
    "simplified": "火炬",
    "reading": "huǒjù",
    "meaning": "a torch, CL:把[ba3]",
    "grade": 7
  },
  {
    "id": 7244,
    "traditional": "火辣辣",
    "simplified": "火辣辣",
    "reading": "huǒlàlà",
    "meaning": "painful heat, scorching, painful heat, rude and forthright, provocative, hot, sexy",
    "grade": 7
  },
  {
    "id": 7245,
    "traditional": "火熱",
    "simplified": "火热",
    "reading": "huǒrè",
    "meaning": "fiery, burning, fervent, ardent, passionate",
    "grade": 7
  },
  {
    "id": 7246,
    "traditional": "火山",
    "simplified": "火山",
    "reading": "huǒshān",
    "meaning": "volcano",
    "grade": 7
  },
  {
    "id": 7247,
    "traditional": "火速",
    "simplified": "火速",
    "reading": "huǒsù",
    "meaning": "at top speed, at a tremendous lick",
    "grade": 7
  },
  {
    "id": 7248,
    "traditional": "火焰",
    "simplified": "火焰",
    "reading": "huǒyàn",
    "meaning": "blaze, flame",
    "grade": 7
  },
  {
    "id": 7249,
    "traditional": "火藥",
    "simplified": "火药",
    "reading": "huǒyào",
    "meaning": "gunpowder",
    "grade": 7
  },
  {
    "id": 7250,
    "traditional": "伙食",
    "simplified": "伙食",
    "reading": "huǒshi",
    "meaning": "food, meals",
    "grade": 7
  },
  {
    "id": 7251,
    "traditional": "或多或少",
    "simplified": "或多或少",
    "reading": "huòduōhuòshǎo",
    "meaning": "more or less",
    "grade": 7
  },
  {
    "id": 7252,
    "traditional": "貨幣",
    "simplified": "货币",
    "reading": "huòbì",
    "meaning": "coinage, mintage, tin, coin, currency, specie, monetary, money, metal money, piece",
    "grade": 7
  },
  {
    "id": 7253,
    "traditional": "貨車",
    "simplified": "货车",
    "reading": "huòchē",
    "meaning": "truck, van, freight wagon",
    "grade": 7
  },
  {
    "id": 7254,
    "traditional": "貨物",
    "simplified": "货物",
    "reading": "huòwù",
    "meaning": "portage, freight, hotshot, shipment, transit, payload, lading, load, commodity, traffic, salework, shipload, bale, consignment, ware, store, cargo, goods, loading, article, merchandise, invoice, charge, freightage",
    "grade": 7
  },
  {
    "id": 7255,
    "traditional": "貨運",
    "simplified": "货运",
    "reading": "huòyùn",
    "meaning": "freight transport, cargo, transported goods",
    "grade": 7
  },
  {
    "id": 7256,
    "traditional": "獲勝",
    "simplified": "获胜",
    "reading": "huò shèng",
    "meaning": "triumphant, triumph, beat, win victory, be victorious, conquer, gain, prevail, win",
    "grade": 7
  },
  {
    "id": 7257,
    "traditional": "獲悉",
    "simplified": "获悉",
    "reading": "huòxī",
    "meaning": "have, understand, get_onto, learn, learn (of an event)",
    "grade": 7
  },
  {
    "id": 7258,
    "traditional": "禍害",
    "simplified": "祸害",
    "reading": "huòhai",
    "meaning": "disaster, harm, scourge, bad person, to damage, to harm, to wreck",
    "grade": 7
  },
  {
    "id": 7259,
    "traditional": "霍亂",
    "simplified": "霍乱",
    "reading": "huòluàn",
    "meaning": "cholera",
    "grade": 7
  },
  {
    "id": 7260,
    "traditional": "豁達",
    "simplified": "豁达",
    "reading": "huòdá",
    "meaning": "optimistic, sanguine, generous, magnanimous, open-minded",
    "grade": 7
  },
  {
    "id": 7261,
    "traditional": "譏笑",
    "simplified": "讥笑",
    "reading": "jīxiào",
    "meaning": "to sneer",
    "grade": 7
  },
  {
    "id": 7262,
    "traditional": "飢餓",
    "simplified": "饥饿",
    "reading": "jīè",
    "meaning": "hunger, starvation, famine",
    "grade": 7
  },
  {
    "id": 7263,
    "traditional": "幾率",
    "simplified": "几率",
    "reading": "jīlǜ",
    "meaning": "probability, odds",
    "grade": 7
  },
  {
    "id": 7264,
    "traditional": "機艙",
    "simplified": "机舱",
    "reading": "jīcāng",
    "meaning": "cabin of a plane",
    "grade": 7
  },
  {
    "id": 7265,
    "traditional": "機動",
    "simplified": "机动",
    "reading": "jīdòng",
    "meaning": "locomotive, motorized, power-driven, adaptable, flexible (use, treatment, timing etc)",
    "grade": 7
  },
  {
    "id": 7266,
    "traditional": "機密",
    "simplified": "机密",
    "reading": "jīmì",
    "meaning": "secret, classified (information)",
    "grade": 7
  },
  {
    "id": 7267,
    "traditional": "機靈",
    "simplified": "机灵",
    "reading": "jīling",
    "meaning": "clever, quick-witted",
    "grade": 7
  },
  {
    "id": 7268,
    "traditional": "機智",
    "simplified": "机智",
    "reading": "jīzhì",
    "meaning": "quick-witted, resourceful",
    "grade": 7
  },
  {
    "id": 7269,
    "traditional": "肌膚",
    "simplified": "肌肤",
    "reading": "jīfū",
    "meaning": "skin",
    "grade": 7
  },
  {
    "id": 7270,
    "traditional": "積",
    "simplified": "积",
    "reading": "jī",
    "meaning": "accumulate, amass, accumulation, store up",
    "grade": 7
  },
  {
    "id": 7271,
    "traditional": "積澱",
    "simplified": "积淀",
    "reading": "jīdiàn",
    "meaning": "deposits accumulated over long periods, fig. valuable experience, accumulated wisdom",
    "grade": 7
  },
  {
    "id": 7272,
    "traditional": "積蓄",
    "simplified": "积蓄",
    "reading": "jīxù",
    "meaning": "to save, to put aside, savings",
    "grade": 7
  },
  {
    "id": 7273,
    "traditional": "基本功",
    "simplified": "基本功",
    "reading": "jīběngōng",
    "meaning": "basic skills, fundamentals",
    "grade": 7
  },
  {
    "id": 7274,
    "traditional": "基層",
    "simplified": "基层",
    "reading": "jīcéng",
    "meaning": "grass-roots unit, basic/primary level",
    "grade": 7
  },
  {
    "id": 7275,
    "traditional": "基因",
    "simplified": "基因",
    "reading": "jīyīn",
    "meaning": "gene",
    "grade": 7
  },
  {
    "id": 7276,
    "traditional": "基準",
    "simplified": "基准",
    "reading": "jīzhǔn",
    "meaning": "guideline, foundation, base, norm, standard, criterion, postulate, fundament, principle, groundwork, modulus, yardstick, benchmark, standard criterion, module, cornerstone, touchstone, datum, basis, fiducial",
    "grade": 7
  },
  {
    "id": 7277,
    "traditional": "基於",
    "simplified": "基于",
    "reading": "jīyú",
    "meaning": "on the basis of, in view of, because of",
    "grade": 7
  },
  {
    "id": 7278,
    "traditional": "畸形",
    "simplified": "畸形",
    "reading": "jīxíng",
    "meaning": "deformity, abnormality",
    "grade": 7
  },
  {
    "id": 7279,
    "traditional": "激發",
    "simplified": "激发",
    "reading": "jīfā",
    "meaning": "spark, incite, suggest, explode, excitation, foster, motivate, arouse, stir, draw on, set off, energize, excite, stimulation, stimulate",
    "grade": 7
  },
  {
    "id": 7280,
    "traditional": "激光",
    "simplified": "激光",
    "reading": "jīguāng",
    "meaning": "laser",
    "grade": 7
  },
  {
    "id": 7281,
    "traditional": "激化",
    "simplified": "激化",
    "reading": "jīhuà",
    "meaning": "intensify",
    "grade": 7
  },
  {
    "id": 7282,
    "traditional": "激活",
    "simplified": "激活",
    "reading": "jīhuó",
    "meaning": "to activate",
    "grade": 7
  },
  {
    "id": 7283,
    "traditional": "激勵",
    "simplified": "激励",
    "reading": "jīlì",
    "meaning": "encourage, impel, urge",
    "grade": 7
  },
  {
    "id": 7284,
    "traditional": "激起",
    "simplified": "激起",
    "reading": "jī qǐ",
    "meaning": "elicit, feed, animate, stir up, arise, pique, enliven, fire, fan, inspire, galvanize, raise, enkindle, stir, arouse, wind up, excite, turn on, provoke, spark, kindle, sex, prompt, evoke, uprouse, ferment, whip_up, awake, rouse, evocation, invigorate, exalt",
    "grade": 7
  },
  {
    "id": 7285,
    "traditional": "及",
    "simplified": "及",
    "reading": "jí",
    "meaning": "to reach, to catch up, and",
    "grade": 7
  },
  {
    "id": 7286,
    "traditional": "及其",
    "simplified": "及其",
    "reading": "jíqí",
    "meaning": "and, as well as",
    "grade": 7
  },
  {
    "id": 7287,
    "traditional": "激素",
    "simplified": "激素",
    "reading": "jīsù",
    "meaning": "hormone",
    "grade": 7
  },
  {
    "id": 7288,
    "traditional": "及早",
    "simplified": "及早",
    "reading": "jízǎo",
    "meaning": "at an early date, as soon as possible",
    "grade": 7
  },
  {
    "id": 7289,
    "traditional": "吉普",
    "simplified": "吉普",
    "reading": "jípǔ",
    "meaning": "Jeep (car brand)",
    "grade": 7
  },
  {
    "id": 7290,
    "traditional": "吉他",
    "simplified": "吉他",
    "reading": "jítā",
    "meaning": "guitar (loanword), CL:把[ba3]",
    "grade": 7
  },
  {
    "id": 7291,
    "traditional": "吉祥物",
    "simplified": "吉祥物",
    "reading": "jíxiángwù",
    "meaning": "mascot",
    "grade": 7
  },
  {
    "id": 7292,
    "traditional": "級別",
    "simplified": "级别",
    "reading": "jíbié",
    "meaning": "(military) rank, level, grade",
    "grade": 7
  },
  {
    "id": 7293,
    "traditional": "極度",
    "simplified": "极度",
    "reading": "jídù",
    "meaning": "extremely, plaguey, exceedingly, deadly, vitally, to the utmost, passing, plaguily, badly, plaguy",
    "grade": 7
  },
  {
    "id": 7294,
    "traditional": "極力",
    "simplified": "极力",
    "reading": "jílì",
    "meaning": "sparing no effort, spare no effort, doing one's utmost, do one's utmost to",
    "grade": 7
  },
  {
    "id": 7295,
    "traditional": "極少數",
    "simplified": "极少数",
    "reading": "jíshǎoshù",
    "meaning": "extremely few, a small minority",
    "grade": 7
  },
  {
    "id": 7296,
    "traditional": "極為",
    "simplified": "极为",
    "reading": "jíwéi",
    "meaning": "all, absolutely, extremely, dead, utterly, entirely, eminently, exceedingly, vitally, helluva, definitely, superlatively, totally, completely",
    "grade": 7
  },
  {
    "id": 7297,
    "traditional": "極限",
    "simplified": "极限",
    "reading": "jíxiàn",
    "meaning": "breaking point, level best, utmost, limit/maximum, maximum, bounds, extreme, limitation, outside, boundary, uttermost, breaking_point, extremity, upper limit, limit",
    "grade": 7
  },
  {
    "id": 7298,
    "traditional": "即",
    "simplified": "即",
    "reading": "jí",
    "meaning": "at present, in the immediate future, then, promptly, at once",
    "grade": 7
  },
  {
    "id": 7299,
    "traditional": "即便",
    "simplified": "即便",
    "reading": "jíbiàn",
    "meaning": "even if, even though, right away, immediately",
    "grade": 7
  },
  {
    "id": 7300,
    "traditional": "即可",
    "simplified": "即可",
    "reading": "jíkě",
    "meaning": "then could do something",
    "grade": 7
  },
  {
    "id": 7301,
    "traditional": "急劇",
    "simplified": "急剧",
    "reading": "jíjù",
    "meaning": "rapid, sudden",
    "grade": 7
  },
  {
    "id": 7302,
    "traditional": "急迫",
    "simplified": "急迫",
    "reading": "jípò",
    "meaning": "urgent, pressing, imperative",
    "grade": 7
  },
  {
    "id": 7303,
    "traditional": "急性",
    "simplified": "急性",
    "reading": "jíxìng",
    "meaning": "acute (of diseases)",
    "grade": 7
  },
  {
    "id": 7304,
    "traditional": "急於",
    "simplified": "急于",
    "reading": "jíyú",
    "meaning": "anxious, anxious to (do sth.), eager, desperate",
    "grade": 7
  },
  {
    "id": 7305,
    "traditional": "急診",
    "simplified": "急诊",
    "reading": "jízhěn",
    "meaning": "emergency call, emergency (medical) treatment",
    "grade": 7
  },
  {
    "id": 7306,
    "traditional": "急轉彎",
    "simplified": "急转弯",
    "reading": "jízhuǎnwān",
    "meaning": "to make a sudden turn",
    "grade": 7
  },
  {
    "id": 7307,
    "traditional": "急需",
    "simplified": "急需",
    "reading": "jíxū",
    "meaning": "to urgently need, urgent need",
    "grade": 7
  },
  {
    "id": 7308,
    "traditional": "棘手",
    "simplified": "棘手",
    "reading": "jíshǒu",
    "meaning": "thorny (problem), intractable",
    "grade": 7
  },
  {
    "id": 7309,
    "traditional": "集結",
    "simplified": "集结",
    "reading": "jíjié",
    "meaning": "to assemble, to concentrate, to mass, to build up, to marshal",
    "grade": 7
  },
  {
    "id": 7310,
    "traditional": "集郵",
    "simplified": "集邮",
    "reading": "jí yóu",
    "meaning": "stamp collecting, philately",
    "grade": 7
  },
  {
    "id": 7311,
    "traditional": "集裝箱",
    "simplified": "集装箱",
    "reading": "jízhuāngxiāng",
    "meaning": "container (for shipping)",
    "grade": 7
  },
  {
    "id": 7312,
    "traditional": "集會",
    "simplified": "集会",
    "reading": "jíhuì",
    "meaning": "assemble, rally, gather",
    "grade": 7
  },
  {
    "id": 7313,
    "traditional": "嫉妒",
    "simplified": "嫉妒",
    "reading": "jídù",
    "meaning": "to be jealous, to envy, to hate",
    "grade": 7
  },
  {
    "id": 7314,
    "traditional": "脊梁",
    "simplified": "脊梁",
    "reading": "jǐliang",
    "meaning": "backbone, spine",
    "grade": 7
  },
  {
    "id": 7315,
    "traditional": "集資",
    "simplified": "集资",
    "reading": "jízī",
    "meaning": "to raise money, to accumulate funds",
    "grade": 7
  },
  {
    "id": 7316,
    "traditional": "計",
    "simplified": "计",
    "reading": "jì",
    "meaning": "surname Ji, to calculate, to compute, to count, to regard as important, to plan, ruse, meter, gauge",
    "grade": 7
  },
  {
    "id": 7317,
    "traditional": "擠壓",
    "simplified": "挤压",
    "reading": "jǐyā",
    "meaning": "to squeeze, to press, to extrude",
    "grade": 7
  },
  {
    "id": 7318,
    "traditional": "計較",
    "simplified": "计较",
    "reading": "jìjiào",
    "meaning": "argue, arguement, wrangle, care, dispute, think over, haggle over, haggling over, mind, fuss about, discuss in minute detail",
    "grade": 7
  },
  {
    "id": 7319,
    "traditional": "記號",
    "simplified": "记号",
    "reading": "jìhao",
    "meaning": "earmark, symbolism, reader, impress, indicium, symbol, notation, tick, token, marker, type, sign, score, signature, mark, chip, character, seal, crack, marking, dent, ostent, indicia, check, impression",
    "grade": 7
  },
  {
    "id": 7320,
    "traditional": "計時",
    "simplified": "计时",
    "reading": "jìshí",
    "meaning": "to measure time, to time, to reckon by time",
    "grade": 7
  },
  {
    "id": 7321,
    "traditional": "記憶猶新",
    "simplified": "记忆犹新",
    "reading": "jìyì-yóuxīn",
    "meaning": "to remain fresh in one's memory (idiom)",
    "grade": 7
  },
  {
    "id": 7322,
    "traditional": "紀錄片",
    "simplified": "纪录片",
    "reading": "jìlùpiàn",
    "meaning": "newsreel, documentary (film or TV program), CL:部[bu4]",
    "grade": 7
  },
  {
    "id": 7323,
    "traditional": "紀念碑",
    "simplified": "纪念碑",
    "reading": "jìniànbēi",
    "meaning": "monument",
    "grade": 7
  },
  {
    "id": 7324,
    "traditional": "紀念館",
    "simplified": "纪念馆",
    "reading": "jìniànguǎn",
    "meaning": "memorial museum, museum in memory of sb., memorial hall, monument, memorial hall/museum, museum, memorial",
    "grade": 7
  },
  {
    "id": 7325,
    "traditional": "紀念日",
    "simplified": "纪念日",
    "reading": "jìniànrì",
    "meaning": "day of commemoration, memorial day",
    "grade": 7
  },
  {
    "id": 7326,
    "traditional": "紀實",
    "simplified": "纪实",
    "reading": "jìshí",
    "meaning": "record of actual events, documentary (factual rather than fictional)",
    "grade": 7
  },
  {
    "id": 7327,
    "traditional": "計策",
    "simplified": "计策",
    "reading": "jìcè",
    "meaning": "stratagem",
    "grade": 7
  },
  {
    "id": 7328,
    "traditional": "技藝",
    "simplified": "技艺",
    "reading": "jìyì",
    "meaning": "mechanical arts, science, feat, skill, craft, craftsmanship, artistry, craftship, workmanship",
    "grade": 7
  },
  {
    "id": 7329,
    "traditional": "忌",
    "simplified": "忌",
    "reading": "jì",
    "meaning": "to be jealous of, fear, dread, scruple, to avoid or abstain from, to quit, to give up sth",
    "grade": 7
  },
  {
    "id": 7330,
    "traditional": "忌諱",
    "simplified": "忌讳",
    "reading": "jìhui",
    "meaning": "taboo, to avoid as taboo, to abstain from",
    "grade": 7
  },
  {
    "id": 7331,
    "traditional": "忌口",
    "simplified": "忌口",
    "reading": "jì kǒu",
    "meaning": "abstain from certain food (as when ill), avoid certain foods, be on a diet",
    "grade": 7
  },
  {
    "id": 7332,
    "traditional": "劑",
    "simplified": "剂",
    "reading": "jì",
    "meaning": "dose (medicine)",
    "grade": 7
  },
  {
    "id": 7333,
    "traditional": "跡象",
    "simplified": "迹象",
    "reading": "jìxiàng",
    "meaning": "breath, gleam, spark, intimation, indication, trace, vestige, glint, evidence, sign, showing, mark, shadow, glimmer, appearance, relic, hint, phenomenon, augury",
    "grade": 7
  },
  {
    "id": 7334,
    "traditional": "繼",
    "simplified": "继",
    "reading": "jì",
    "meaning": "then, afterwards",
    "grade": 7
  },
  {
    "id": 7335,
    "traditional": "繼而",
    "simplified": "继而",
    "reading": "jìér",
    "meaning": "then, afterwards",
    "grade": 7
  },
  {
    "id": 7336,
    "traditional": "繼父",
    "simplified": "继父",
    "reading": "jìfù",
    "meaning": "stepfather",
    "grade": 7
  },
  {
    "id": 7337,
    "traditional": "繼母",
    "simplified": "继母",
    "reading": "jìmǔ",
    "meaning": "stepmother",
    "grade": 7
  },
  {
    "id": 7338,
    "traditional": "祭",
    "simplified": "祭",
    "reading": "jì",
    "meaning": "surname Zhai, to offer sacrifice, festive occasion",
    "grade": 7
  },
  {
    "id": 7339,
    "traditional": "祭奠",
    "simplified": "祭奠",
    "reading": "jìdiàn",
    "meaning": "to offer sacrifices (to one's ancestors), to hold or attend a memorial service",
    "grade": 7
  },
  {
    "id": 7340,
    "traditional": "祭祀",
    "simplified": "祭祀",
    "reading": "jìsi",
    "meaning": "offer sacrifices to gods/ancestors",
    "grade": 7
  },
  {
    "id": 7341,
    "traditional": "寄託",
    "simplified": "寄托",
    "reading": "jìtuō",
    "meaning": "to entrust (to sb), to place (one's hope, energy etc) in, a thing in which you invest (your hope, energy etc)",
    "grade": 7
  },
  {
    "id": 7342,
    "traditional": "寂靜",
    "simplified": "寂静",
    "reading": "jìjìng",
    "meaning": "quiet",
    "grade": 7
  },
  {
    "id": 7343,
    "traditional": "寂寞",
    "simplified": "寂寞",
    "reading": "jìmò",
    "meaning": "be lonely",
    "grade": 7
  },
  {
    "id": 7344,
    "traditional": "加緊",
    "simplified": "加紧",
    "reading": "jiājǐn",
    "meaning": "to intensify, to speed up, to step up",
    "grade": 7
  },
  {
    "id": 7345,
    "traditional": "加劇",
    "simplified": "加剧",
    "reading": "jiājù",
    "meaning": "to intensify, to sharpen, to accelerate, to aggravate, to exacerbate, to embitter",
    "grade": 7
  },
  {
    "id": 7346,
    "traditional": "加深",
    "simplified": "加深",
    "reading": "jiāshēn",
    "meaning": "to deepen",
    "grade": 7
  },
  {
    "id": 7347,
    "traditional": "加重",
    "simplified": "加重",
    "reading": "jiāzhòng",
    "meaning": "make heavier, punctuate, increase, make or become more serious, weight, become more serious, exacerbate, aggravate, exasperate, make more serious, become heavier, increase the weight of, make",
    "grade": 7
  },
  {
    "id": 7348,
    "traditional": "佳節",
    "simplified": "佳节",
    "reading": "jiājié",
    "meaning": "festive day, holiday",
    "grade": 7
  },
  {
    "id": 7349,
    "traditional": "家伙",
    "simplified": "家伙",
    "reading": "jiāhuo",
    "meaning": "household dish, implement or furniture, domestic animal, (coll.) guy, chap, weapon",
    "grade": 7
  },
  {
    "id": 7350,
    "traditional": "家家戶戶",
    "simplified": "家家户户",
    "reading": "jiājiā-hùhù",
    "meaning": "each and every family (idiom), every household",
    "grade": 7
  },
  {
    "id": 7351,
    "traditional": "家教",
    "simplified": "家教",
    "reading": "jiājiào",
    "meaning": "tutor",
    "grade": 7
  },
  {
    "id": 7352,
    "traditional": "家境",
    "simplified": "家境",
    "reading": "jiājìng",
    "meaning": "family financial situation, family circumstances",
    "grade": 7
  },
  {
    "id": 7353,
    "traditional": "家用",
    "simplified": "家用",
    "reading": "jiāyòng",
    "meaning": "family used",
    "grade": 7
  },
  {
    "id": 7354,
    "traditional": "家禽",
    "simplified": "家禽",
    "reading": "jiāqín",
    "meaning": "poultry, domestic fowl",
    "grade": 7
  },
  {
    "id": 7355,
    "traditional": "家喻戶曉",
    "simplified": "家喻户晓",
    "reading": "jiāyù-hùxiǎo",
    "meaning": "understood by everyone (idiom), well known, a household name",
    "grade": 7
  },
  {
    "id": 7356,
    "traditional": "家政",
    "simplified": "家政",
    "reading": "jiāzhèng",
    "meaning": "housekeeping",
    "grade": 7
  },
  {
    "id": 7357,
    "traditional": "家族",
    "simplified": "家族",
    "reading": "jiāzú",
    "meaning": "tribe, covey, family line, race, household, menage, ilk, kinfolk, kin, clan, kinsfolk, phratry, kinship group, kindred, folk, people, kin group, sept, family",
    "grade": 7
  },
  {
    "id": 7358,
    "traditional": "嘉年華",
    "simplified": "嘉年华",
    "reading": "jiāniánhuá",
    "meaning": "carnival (loanword)",
    "grade": 7
  },
  {
    "id": 7359,
    "traditional": "假冒",
    "simplified": "假冒",
    "reading": "jiǎmào",
    "meaning": "to impersonate, to pose as (someone else), to counterfeit, to palm off (a fake as a genuine)",
    "grade": 7
  },
  {
    "id": 7360,
    "traditional": "假設",
    "simplified": "假设",
    "reading": "jiǎshè",
    "meaning": "suppose, assume, presume",
    "grade": 7
  },
  {
    "id": 7361,
    "traditional": "假定",
    "simplified": "假定",
    "reading": "jiǎdìng",
    "meaning": "to assume, to suppose, supposed, so-called, assumption, hypothesis",
    "grade": 7
  },
  {
    "id": 7362,
    "traditional": "價位",
    "simplified": "价位",
    "reading": "jiàwèi",
    "meaning": "price",
    "grade": 7
  },
  {
    "id": 7363,
    "traditional": "假裝",
    "simplified": "假装",
    "reading": "jiǎzhuāng",
    "meaning": "pretend, feign, simulate",
    "grade": 7
  },
  {
    "id": 7364,
    "traditional": "價值觀",
    "simplified": "价值观",
    "reading": "jiàzhíguān",
    "meaning": "values, valuation",
    "grade": 7
  },
  {
    "id": 7365,
    "traditional": "假使",
    "simplified": "假使",
    "reading": "jiǎshǐ",
    "meaning": "conj.: if, in case, in the event that",
    "grade": 7
  },
  {
    "id": 7366,
    "traditional": "駕車",
    "simplified": "驾车",
    "reading": "jiàchē",
    "meaning": "drive, drive a vehicle, drive a car",
    "grade": 7
  },
  {
    "id": 7367,
    "traditional": "駕馭",
    "simplified": "驾驭",
    "reading": "jiàyù",
    "meaning": "to urge on (of horse), to drive, to steer, to handle, to manage, to master, to dominate",
    "grade": 7
  },
  {
    "id": 7368,
    "traditional": "駕",
    "simplified": "驾",
    "reading": "jià",
    "meaning": "harness, draw (cart/etc.), drive, pilot, sail",
    "grade": 7
  },
  {
    "id": 7369,
    "traditional": "架勢",
    "simplified": "架势",
    "reading": "jiàshi",
    "meaning": "attitude, position (on an issue etc)",
    "grade": 7
  },
  {
    "id": 7370,
    "traditional": "嫁",
    "simplified": "嫁",
    "reading": "jià",
    "meaning": "marry (of a woman)",
    "grade": 7
  },
  {
    "id": 7371,
    "traditional": "架子",
    "simplified": "架子",
    "reading": "jiàzi",
    "meaning": "shelf, frame, stand, framework, airs, arrogance",
    "grade": 7
  },
  {
    "id": 7372,
    "traditional": "嫁妝",
    "simplified": "嫁妆",
    "reading": "jiàzhuang",
    "meaning": "dowry",
    "grade": 7
  },
  {
    "id": 7373,
    "traditional": "尖端",
    "simplified": "尖端",
    "reading": "jiānduān",
    "meaning": "sharp pointed end, the tip, the cusp, tip-top, most advanced and sophisticated, highest peak, the best",
    "grade": 7
  },
  {
    "id": 7374,
    "traditional": "尖銳",
    "simplified": "尖锐",
    "reading": "jiānruì",
    "meaning": "sharp-pointed, sharpen, penetrate, acuminate, shrill, sharp, sharpened, intense, penetrating, piercing, incisive, acute",
    "grade": 7
  },
  {
    "id": 7375,
    "traditional": "奸詐",
    "simplified": "奸诈",
    "reading": "jiānzhà",
    "meaning": "treachery, devious, a rogue",
    "grade": 7
  },
  {
    "id": 7376,
    "traditional": "堅持不懈",
    "simplified": "坚持不懈",
    "reading": "jiānchí-bùxiè",
    "meaning": "to persevere unremittingly (idiom), to keep going until the end",
    "grade": 7
  },
  {
    "id": 7377,
    "traditional": "堅韌",
    "simplified": "坚韧",
    "reading": "jiānrèn",
    "meaning": "tough and durable, tenacious",
    "grade": 7
  },
  {
    "id": 7378,
    "traditional": "殲滅",
    "simplified": "歼灭",
    "reading": "jiānmiè",
    "meaning": "to wipe out, to crush, to annihilate",
    "grade": 7
  },
  {
    "id": 7379,
    "traditional": "堅實",
    "simplified": "坚实",
    "reading": "jiānshí",
    "meaning": "firm and substantial, solid",
    "grade": 7
  },
  {
    "id": 7380,
    "traditional": "堅守",
    "simplified": "坚守",
    "reading": "jiānshǒu",
    "meaning": "to hold fast to, to stick to",
    "grade": 7
  },
  {
    "id": 7381,
    "traditional": "堅信",
    "simplified": "坚信",
    "reading": "jiānxìn",
    "meaning": "to believe firmly, without any doubt",
    "grade": 7
  },
  {
    "id": 7382,
    "traditional": "堅硬",
    "simplified": "坚硬",
    "reading": "jiānyìng",
    "meaning": "hard, solid",
    "grade": 7
  },
  {
    "id": 7383,
    "traditional": "肩膀",
    "simplified": "肩膀",
    "reading": "jiānbǎng",
    "meaning": "shoulder",
    "grade": 7
  },
  {
    "id": 7384,
    "traditional": "肩負",
    "simplified": "肩负",
    "reading": "jiānfù",
    "meaning": "to shoulder (a burden), to bear, to suffer (a disadvantage)",
    "grade": 7
  },
  {
    "id": 7385,
    "traditional": "艱巨",
    "simplified": "艰巨",
    "reading": "jiānjù",
    "meaning": "arduous, terrible (task), very difficult, formidable",
    "grade": 7
  },
  {
    "id": 7386,
    "traditional": "艱苦奮鬥",
    "simplified": "艰苦奋斗",
    "reading": "jiānkǔ-fèndòu",
    "meaning": "to struggle arduously",
    "grade": 7
  },
  {
    "id": 7387,
    "traditional": "艱險",
    "simplified": "艰险",
    "reading": "jiānxiǎn",
    "meaning": "difficult and dangerous, hardships and perils",
    "grade": 7
  },
  {
    "id": 7388,
    "traditional": "艱辛",
    "simplified": "艰辛",
    "reading": "jiānxīn",
    "meaning": "hardships, arduous, difficult",
    "grade": 7
  },
  {
    "id": 7389,
    "traditional": "監察",
    "simplified": "监察",
    "reading": "jiānchá",
    "meaning": "to supervise, to control",
    "grade": 7
  },
  {
    "id": 7390,
    "traditional": "監管",
    "simplified": "监管",
    "reading": "jiānguǎn",
    "meaning": "to oversee, to take charge of, to supervise, to administer, supervisory, supervision",
    "grade": 7
  },
  {
    "id": 7391,
    "traditional": "監護",
    "simplified": "监护",
    "reading": "jiānhù",
    "meaning": "to act as a guardian",
    "grade": 7
  },
  {
    "id": 7392,
    "traditional": "監控",
    "simplified": "监控",
    "reading": "jiānkòng",
    "meaning": "to monitor",
    "grade": 7
  },
  {
    "id": 7393,
    "traditional": "監視",
    "simplified": "监视",
    "reading": "jiānshì",
    "meaning": "to monitor, to keep a close watch over, surveillance",
    "grade": 7
  },
  {
    "id": 7394,
    "traditional": "監獄",
    "simplified": "监狱",
    "reading": "jiānyù",
    "meaning": "prison, jail",
    "grade": 7
  },
  {
    "id": 7395,
    "traditional": "兼",
    "simplified": "兼",
    "reading": "jiān",
    "meaning": "hold two or more jobs concurrently, do concurrently",
    "grade": 7
  },
  {
    "id": 7396,
    "traditional": "兼顧",
    "simplified": "兼顾",
    "reading": "jiāngù",
    "meaning": "take account of, give consideration to, deal with two or more things",
    "grade": 7
  },
  {
    "id": 7397,
    "traditional": "兼任",
    "simplified": "兼任",
    "reading": "jiānrèn",
    "meaning": "hold a concurrent post, part-time, hold concurrent post",
    "grade": 7
  },
  {
    "id": 7398,
    "traditional": "兼容",
    "simplified": "兼容",
    "reading": "jiānróng",
    "meaning": "compatible",
    "grade": 7
  },
  {
    "id": 7399,
    "traditional": "兼職",
    "simplified": "兼职",
    "reading": "jiānzhí",
    "meaning": "to hold concurrent posts, concurrent job, moonlighting",
    "grade": 7
  },
  {
    "id": 7400,
    "traditional": "煎",
    "simplified": "煎",
    "reading": "jiān",
    "meaning": "to pan fry, to sauté",
    "grade": 7
  },
  {
    "id": 7401,
    "traditional": "揀",
    "simplified": "拣",
    "reading": "jiǎn",
    "meaning": "to choose, to pick, to sort out, to pick up",
    "grade": 7
  },
  {
    "id": 7402,
    "traditional": "檢察",
    "simplified": "检察",
    "reading": "jiǎnchá",
    "meaning": "to inspect, (law) to prosecute, to investigate",
    "grade": 7
  },
  {
    "id": 7403,
    "traditional": "檢討",
    "simplified": "检讨",
    "reading": "jiǎntǎo",
    "meaning": "discuss thoroughly, self-criticize",
    "grade": 7
  },
  {
    "id": 7404,
    "traditional": "減免",
    "simplified": "减免",
    "reading": "jiǎnmiǎn",
    "meaning": "to reduce or waive (taxes, punishment, rent, tuition etc)",
    "grade": 7
  },
  {
    "id": 7405,
    "traditional": "減弱",
    "simplified": "减弱",
    "reading": "jiǎnruò",
    "meaning": "to weaken, to fall off",
    "grade": 7
  },
  {
    "id": 7406,
    "traditional": "減速",
    "simplified": "减速",
    "reading": "jiǎn sù",
    "meaning": "to reduce speed, to slow down, to take it easy",
    "grade": 7
  },
  {
    "id": 7407,
    "traditional": "減壓",
    "simplified": "减压",
    "reading": "jiǎn yā",
    "meaning": "to reduce pressure, to relax",
    "grade": 7
  },
  {
    "id": 7408,
    "traditional": "簡稱",
    "simplified": "简称",
    "reading": "jiǎnchēng",
    "meaning": "be called sth. for short",
    "grade": 7
  },
  {
    "id": 7409,
    "traditional": "簡短",
    "simplified": "简短",
    "reading": "jiǎnduǎn",
    "meaning": "brief, short-duration",
    "grade": 7
  },
  {
    "id": 7410,
    "traditional": "簡化",
    "simplified": "简化",
    "reading": "jiǎnhuà",
    "meaning": "simplify",
    "grade": 7
  },
  {
    "id": 7411,
    "traditional": "簡潔",
    "simplified": "简洁",
    "reading": "jiǎnjié",
    "meaning": "concise, succinct, pithy",
    "grade": 7
  },
  {
    "id": 7412,
    "traditional": "簡陋",
    "simplified": "简陋",
    "reading": "jiǎnlòu",
    "meaning": "simple and crude",
    "grade": 7
  },
  {
    "id": 7413,
    "traditional": "簡體字",
    "simplified": "简体字",
    "reading": "jiǎntǐzì",
    "meaning": "simplified Chinese character, as opposed to traditional Chinese character 繁體字|繁体字[fan2 ti3 zi4]",
    "grade": 7
  },
  {
    "id": 7414,
    "traditional": "簡要",
    "simplified": "简要",
    "reading": "jiǎnyào",
    "meaning": "concise, brief",
    "grade": 7
  },
  {
    "id": 7415,
    "traditional": "簡易",
    "simplified": "简易",
    "reading": "jiǎnyì",
    "meaning": "simple and easy, simply constructed, unsophisticated",
    "grade": 7
  },
  {
    "id": 7416,
    "traditional": "見錢眼開",
    "simplified": "见钱眼开",
    "reading": "jiànqián-yǎnkāi",
    "meaning": "to open one's eyes wide at the sight of profit (idiom), thinking of nothing but personal gain, money-grubbing",
    "grade": 7
  },
  {
    "id": 7417,
    "traditional": "見解",
    "simplified": "见解",
    "reading": "jiànjiě",
    "meaning": "eyeshot, view, horizon, understanding, outlook, light, eyes, spectacle, judgment, sentiment, eyesight, opinion, thought, sight, standpoint, bet, hypothesis, eye, viewpoint, theory, possibility, judgement, slant, doxy, purview, ruling, thinking, supposal, position, persuasion, mind",
    "grade": 7
  },
  {
    "id": 7418,
    "traditional": "見仁見智",
    "simplified": "见仁见智",
    "reading": "jiànrén-jiànzhì",
    "meaning": "opinions differ (idiom)",
    "grade": 7
  },
  {
    "id": 7419,
    "traditional": "見識",
    "simplified": "见识",
    "reading": "jiànshi",
    "meaning": "knowledge and experience, to increase one's knowledge",
    "grade": 7
  },
  {
    "id": 7420,
    "traditional": "見外",
    "simplified": "见外",
    "reading": "jiànwài",
    "meaning": "to regard somebody as an outsider",
    "grade": 7
  },
  {
    "id": 7421,
    "traditional": "見效",
    "simplified": "见效",
    "reading": "jiàn xiào",
    "meaning": "to have the desired effect",
    "grade": 7
  },
  {
    "id": 7422,
    "traditional": "見義勇為",
    "simplified": "见义勇为",
    "reading": "jiànyì-yǒngwéi",
    "meaning": "to see what is right and act courageously (idiom, from Analects), to stand up bravely for the truth, acting heroically in a just cause",
    "grade": 7
  },
  {
    "id": 7423,
    "traditional": "見證",
    "simplified": "见证",
    "reading": "jiànzhèng",
    "meaning": "to be witness to, witness, evidence",
    "grade": 7
  },
  {
    "id": 7424,
    "traditional": "間諜",
    "simplified": "间谍",
    "reading": "jiàndié",
    "meaning": "spy",
    "grade": 7
  },
  {
    "id": 7425,
    "traditional": "間斷",
    "simplified": "间断",
    "reading": "jiànduàn",
    "meaning": "disconnected, interrupted, suspended",
    "grade": 7
  },
  {
    "id": 7426,
    "traditional": "間隔",
    "simplified": "间隔",
    "reading": "jiàngé",
    "meaning": "compartment, gap, interval, to divide",
    "grade": 7
  },
  {
    "id": 7427,
    "traditional": "間隙",
    "simplified": "间隙",
    "reading": "jiànxì",
    "meaning": "interval, gap, clearance",
    "grade": 7
  },
  {
    "id": 7428,
    "traditional": "建交",
    "simplified": "建交",
    "reading": "jiàn jiāo",
    "meaning": "to establish diplomatic relations",
    "grade": 7
  },
  {
    "id": 7429,
    "traditional": "建樹",
    "simplified": "建树",
    "reading": "jiànshù",
    "meaning": "to make a contribution, to establish, to found, contribution",
    "grade": 7
  },
  {
    "id": 7430,
    "traditional": "建築師",
    "simplified": "建筑师",
    "reading": "jiànzhùshī",
    "meaning": "surveyor, architect",
    "grade": 7
  },
  {
    "id": 7431,
    "traditional": "建築物",
    "simplified": "建筑物",
    "reading": "jiànzhùwù",
    "meaning": "foundation, shebang, erection, superstructure, buildup, architecture, construct, construction, edifice, building, structure, place, tenement, property, house, fabric",
    "grade": 7
  },
  {
    "id": 7432,
    "traditional": "健美",
    "simplified": "健美",
    "reading": "jiànměi",
    "meaning": "healthy and beautiful, to do fitness exercises, abbr. for 健美運動|健美运动[jian4 mei3 yun4 dong4]",
    "grade": 7
  },
  {
    "id": 7433,
    "traditional": "賤",
    "simplified": "贱",
    "reading": "jiàn",
    "meaning": "inexpensive, lowly",
    "grade": 7
  },
  {
    "id": 7434,
    "traditional": "健壯",
    "simplified": "健壮",
    "reading": "jiànzhuàng",
    "meaning": "robust, healthy, sturdy",
    "grade": 7
  },
  {
    "id": 7435,
    "traditional": "濺",
    "simplified": "溅",
    "reading": "jiàn",
    "meaning": "to splash",
    "grade": 7
  },
  {
    "id": 7436,
    "traditional": "鑒別",
    "simplified": "鉴别",
    "reading": "jiànbié",
    "meaning": "to differentiate, to distinguish",
    "grade": 7
  },
  {
    "id": 7437,
    "traditional": "鑒賞",
    "simplified": "鉴赏",
    "reading": "jiànshǎng",
    "meaning": "to appreciate",
    "grade": 7
  },
  {
    "id": 7438,
    "traditional": "姜",
    "simplified": "姜",
    "reading": "jiāng",
    "meaning": "surname Jiang, ginger",
    "grade": 7
  },
  {
    "id": 7439,
    "traditional": "鑒於",
    "simplified": "鉴于",
    "reading": "jiànyú",
    "meaning": "conj.: in view of, seeing that",
    "grade": 7
  },
  {
    "id": 7440,
    "traditional": "僵",
    "simplified": "僵",
    "reading": "jiāng",
    "meaning": "rigid, deadlock, stiff (corpse), variant of 僵[jiang1]",
    "grade": 7
  },
  {
    "id": 7441,
    "traditional": "僵化",
    "simplified": "僵化",
    "reading": "jiānghuà",
    "meaning": "to become rigid",
    "grade": 7
  },
  {
    "id": 7442,
    "traditional": "僵局",
    "simplified": "僵局",
    "reading": "jiāngjú",
    "meaning": "impasse, deadlock",
    "grade": 7
  },
  {
    "id": 7443,
    "traditional": "講解",
    "simplified": "讲解",
    "reading": "jiǎngjiě",
    "meaning": "to explain",
    "grade": 7
  },
  {
    "id": 7444,
    "traditional": "講述",
    "simplified": "讲述",
    "reading": "jiǎngshù",
    "meaning": "to talk about, to narrate, to give an account",
    "grade": 7
  },
  {
    "id": 7445,
    "traditional": "講學",
    "simplified": "讲学",
    "reading": "jiǎng xué",
    "meaning": "to lecture (on branch of learning)",
    "grade": 7
  },
  {
    "id": 7446,
    "traditional": "獎杯",
    "simplified": "奖杯",
    "reading": "jiǎngbēi",
    "meaning": "trophy cup",
    "grade": 7
  },
  {
    "id": 7447,
    "traditional": "獎牌",
    "simplified": "奖牌",
    "reading": "jiǎngpái",
    "meaning": "medal (awarded as a prize)",
    "grade": 7
  },
  {
    "id": 7448,
    "traditional": "獎品",
    "simplified": "奖品",
    "reading": "jiǎngpǐn",
    "meaning": "accolade, gainings, trophy, award, prize, prix, gree",
    "grade": 7
  },
  {
    "id": 7449,
    "traditional": "獎項",
    "simplified": "奖项",
    "reading": "jiǎngxiàng",
    "meaning": "award, prize, CL:項|项[xiang4]",
    "grade": 7
  },
  {
    "id": 7450,
    "traditional": "降臨",
    "simplified": "降临",
    "reading": "jiànglín",
    "meaning": "to descend, to arrive, to come",
    "grade": 7
  },
  {
    "id": 7451,
    "traditional": "交叉",
    "simplified": "交叉",
    "reading": "jiāochā",
    "meaning": "cut, alternate, intercross, stagger, decussate, intersect, overlap, overlapping, intersection, cross, crisscross",
    "grade": 7
  },
  {
    "id": 7452,
    "traditional": "交鋒",
    "simplified": "交锋",
    "reading": "jiāo fēng",
    "meaning": "to cross swords, to have a confrontation (with sb)",
    "grade": 7
  },
  {
    "id": 7453,
    "traditional": "交付",
    "simplified": "交付",
    "reading": "jiāofù",
    "meaning": "to hand over, to deliver",
    "grade": 7
  },
  {
    "id": 7454,
    "traditional": "交集",
    "simplified": "交集",
    "reading": "jiāojí",
    "meaning": "intersection (symbol ∩) (set theory)",
    "grade": 7
  },
  {
    "id": 7455,
    "traditional": "交接",
    "simplified": "交接",
    "reading": "jiāojiē",
    "meaning": "(of two things) to come into contact, to meet, to hand over to, to take over from, to associate with, to have friendly relations with, to have sexual intercourse",
    "grade": 7
  },
  {
    "id": 7456,
    "traditional": "交界",
    "simplified": "交界",
    "reading": "jiāojiè",
    "meaning": "common boundary, common border",
    "grade": 7
  },
  {
    "id": 7457,
    "traditional": "交情",
    "simplified": "交情",
    "reading": "jiāoqing",
    "meaning": "friendship, friendly relations",
    "grade": 7
  },
  {
    "id": 7458,
    "traditional": "交納",
    "simplified": "交纳",
    "reading": "jiāonà",
    "meaning": "to pay (taxes or dues)",
    "grade": 7
  },
  {
    "id": 7459,
    "traditional": "交涉",
    "simplified": "交涉",
    "reading": "jiāoshè",
    "meaning": "to negotiate, relating to",
    "grade": 7
  },
  {
    "id": 7460,
    "traditional": "交談",
    "simplified": "交谈",
    "reading": "jiāotán",
    "meaning": "converse, chat",
    "grade": 7
  },
  {
    "id": 7461,
    "traditional": "交替",
    "simplified": "交替",
    "reading": "jiāotì",
    "meaning": "to replace, alternately, in turn",
    "grade": 7
  },
  {
    "id": 7462,
    "traditional": "交頭接耳",
    "simplified": "交头接耳",
    "reading": "jiāotóu-jiēěr",
    "meaning": "to whisper to one another's ear",
    "grade": 7
  },
  {
    "id": 7463,
    "traditional": "交響樂",
    "simplified": "交响乐",
    "reading": "jiāoxiǎngyuè",
    "meaning": "symphony",
    "grade": 7
  },
  {
    "id": 7464,
    "traditional": "郊遊",
    "simplified": "郊游",
    "reading": "jiāoyóu",
    "meaning": "to go for an outing, to go on an excursion",
    "grade": 7
  },
  {
    "id": 7465,
    "traditional": "郊外",
    "simplified": "郊外",
    "reading": "jiāowài",
    "meaning": "outskirts",
    "grade": 7
  },
  {
    "id": 7466,
    "traditional": "澆",
    "simplified": "浇",
    "reading": "jiāo",
    "meaning": "to pour liquid, to irrigate (using waterwheel), to water, to cast (molten metal), to mold",
    "grade": 7
  },
  {
    "id": 7467,
    "traditional": "嬌慣",
    "simplified": "娇惯",
    "reading": "jiāoguàn",
    "meaning": "to pamper, to coddle, to spoil",
    "grade": 7
  },
  {
    "id": 7468,
    "traditional": "嬌氣",
    "simplified": "娇气",
    "reading": "jiāoqi",
    "meaning": "delicate, squeamish, finicky",
    "grade": 7
  },
  {
    "id": 7469,
    "traditional": "膠囊",
    "simplified": "胶囊",
    "reading": "jiāonáng",
    "meaning": "capsule (pharm.), caplet",
    "grade": 7
  },
  {
    "id": 7470,
    "traditional": "膠片",
    "simplified": "胶片",
    "reading": "jiāopiàn",
    "meaning": "(photographic) film",
    "grade": 7
  },
  {
    "id": 7471,
    "traditional": "焦",
    "simplified": "焦",
    "reading": "jiāo",
    "meaning": "surname Jiao, burnt, scorched, charred, worried, anxious, coke",
    "grade": 7
  },
  {
    "id": 7472,
    "traditional": "焦距",
    "simplified": "焦距",
    "reading": "jiāojù",
    "meaning": "focal length, focal distance",
    "grade": 7
  },
  {
    "id": 7473,
    "traditional": "焦急",
    "simplified": "焦急",
    "reading": "jiāojí",
    "meaning": "anxiety, anxious",
    "grade": 7
  },
  {
    "id": 7474,
    "traditional": "焦慮",
    "simplified": "焦虑",
    "reading": "jiāolǜ",
    "meaning": "anxious, apprehensive",
    "grade": 7
  },
  {
    "id": 7475,
    "traditional": "焦躁",
    "simplified": "焦躁",
    "reading": "jiāozào",
    "meaning": "fretful, impatient",
    "grade": 7
  },
  {
    "id": 7476,
    "traditional": "嚼",
    "simplified": "嚼",
    "reading": "jué",
    "meaning": "to chew, also pr. [jue2]",
    "grade": 7
  },
  {
    "id": 7477,
    "traditional": "礁石",
    "simplified": "礁石",
    "reading": "jiāoshí",
    "meaning": "reef",
    "grade": 7
  },
  {
    "id": 7478,
    "traditional": "絞",
    "simplified": "绞",
    "reading": "jiǎo",
    "meaning": "to twist (strands into a thread), to entangle, to wring, to hang (by the neck), to turn, to wind, classifier for skeins of yarn",
    "grade": 7
  },
  {
    "id": 7479,
    "traditional": "狡猾",
    "simplified": "狡猾",
    "reading": "jiǎohuá",
    "meaning": "crafty, cunning, sly",
    "grade": 7
  },
  {
    "id": 7480,
    "traditional": "角落",
    "simplified": "角落",
    "reading": "jiǎoluò",
    "meaning": "nook, quoin, angle, corner",
    "grade": 7
  },
  {
    "id": 7481,
    "traditional": "矯正",
    "simplified": "矫正",
    "reading": "jiǎozhèng",
    "meaning": "to correct, to rectify (e.g. a physical defect such as hearing or vision), to cure, rectification, correction, to straighten",
    "grade": 7
  },
  {
    "id": 7482,
    "traditional": "攪拌",
    "simplified": "搅拌",
    "reading": "jiǎobàn",
    "meaning": "to stir, to agitate",
    "grade": 7
  },
  {
    "id": 7483,
    "traditional": "攪",
    "simplified": "搅",
    "reading": "jiǎo",
    "meaning": "to disturb, to annoy, to mix, to stir",
    "grade": 7
  },
  {
    "id": 7484,
    "traditional": "繳",
    "simplified": "缴",
    "reading": "jiǎo",
    "meaning": "turn_in, hand in, hand over/in, capture, pay, in, shell_out, hand over",
    "grade": 7
  },
  {
    "id": 7485,
    "traditional": "繳費",
    "simplified": "缴费",
    "reading": "jiǎofèi",
    "meaning": "to pay a fee",
    "grade": 7
  },
  {
    "id": 7486,
    "traditional": "繳納",
    "simplified": "缴纳",
    "reading": "jiǎonà",
    "meaning": "to pay (taxes etc)",
    "grade": 7
  },
  {
    "id": 7487,
    "traditional": "叫板",
    "simplified": "叫板",
    "reading": "jiàobǎn",
    "meaning": "to signal the musicians (in Chinese opera, by prolonging a spoken word before attacking a song), (coll.) to challenge",
    "grade": 7
  },
  {
    "id": 7488,
    "traditional": "叫好",
    "simplified": "叫好",
    "reading": "jiào hǎo",
    "meaning": "to applaud, to cheer",
    "grade": 7
  },
  {
    "id": 7489,
    "traditional": "轎車",
    "simplified": "轿车",
    "reading": "jiàochē",
    "meaning": "(horse-drawn) carriage, saloon, passenger car, car, limousine, carriage, coach, bus, sedan",
    "grade": 7
  },
  {
    "id": 7490,
    "traditional": "較勁",
    "simplified": "较劲",
    "reading": "jiào jìn",
    "meaning": "to match one's strength with, to compete, more competitive, to set oneself against sb, disobliging, to make a special effort",
    "grade": 7
  },
  {
    "id": 7491,
    "traditional": "較量",
    "simplified": "较量",
    "reading": "jiàoliàng",
    "meaning": "to have a contest with sb, to cross swords, to measure up against, to compete with, to haggle, to quibble",
    "grade": 7
  },
  {
    "id": 7492,
    "traditional": "教條",
    "simplified": "教条",
    "reading": "jiàotiáo",
    "meaning": "creed, doctrine, religious dogma",
    "grade": 7
  },
  {
    "id": 7493,
    "traditional": "教科書",
    "simplified": "教科书",
    "reading": "jiàokēshū",
    "meaning": "textbook",
    "grade": 7
  },
  {
    "id": 7494,
    "traditional": "教養",
    "simplified": "教养",
    "reading": "jiàoyǎng",
    "meaning": "to train, to educate, to bring up, to nurture, education, culture, upbringing, early conditioning",
    "grade": 7
  },
  {
    "id": 7495,
    "traditional": "階層",
    "simplified": "阶层",
    "reading": "jiēcéng",
    "meaning": "section, circle, degree, social stratum, stratum, echelon, hierarchical, walk in life, walk_of_life, rank",
    "grade": 7
  },
  {
    "id": 7496,
    "traditional": "階級",
    "simplified": "阶级",
    "reading": "jiējí",
    "meaning": "state, social class, degree, people, gradation, echelon, persuasion, position, order, class, remove, social status, notch, parity, social rank, sort, step, condition, rank, social station",
    "grade": 7
  },
  {
    "id": 7497,
    "traditional": "皆",
    "simplified": "皆",
    "reading": "jiē",
    "meaning": "all, everyone",
    "grade": 7
  },
  {
    "id": 7498,
    "traditional": "階梯",
    "simplified": "阶梯",
    "reading": "jiētī",
    "meaning": "flight of steps, (fig.) a means of advancement, stepping stone",
    "grade": 7
  },
  {
    "id": 7499,
    "traditional": "結",
    "simplified": "结",
    "reading": "jié",
    "meaning": "tie, knit, knot, weave, congeal, form, forge, cement, settle, conclude",
    "grade": 7
  },
  {
    "id": 7500,
    "traditional": "結果",
    "simplified": "结果",
    "reading": "jiē guǒ",
    "meaning": "result, outcome, consequence",
    "grade": 7
  },
  {
    "id": 7501,
    "traditional": "接班人",
    "simplified": "接班人",
    "reading": "jiēbān rén",
    "meaning": "successor",
    "grade": 7
  },
  {
    "id": 7502,
    "traditional": "接班",
    "simplified": "接班",
    "reading": "jiē bān",
    "meaning": "to take over (from those working the previous shift), to take over (in a leadership role etc), to succeed sb",
    "grade": 7
  },
  {
    "id": 7503,
    "traditional": "接軌",
    "simplified": "接轨",
    "reading": "jiē guǐ",
    "meaning": "railtrack connection, to integrate into sth, to dock, to connect, to be in step with, to bring into line with, to align",
    "grade": 7
  },
  {
    "id": 7504,
    "traditional": "接二連三",
    "simplified": "接二连三",
    "reading": "jiēèr-liánsān",
    "meaning": "one after another (idiom), in quick succession",
    "grade": 7
  },
  {
    "id": 7505,
    "traditional": "接濟",
    "simplified": "接济",
    "reading": "jiējì",
    "meaning": "to give material assistance to",
    "grade": 7
  },
  {
    "id": 7506,
    "traditional": "接見",
    "simplified": "接见",
    "reading": "jiējiàn",
    "meaning": "to receive sb, to grant an interview",
    "grade": 7
  },
  {
    "id": 7507,
    "traditional": "接力",
    "simplified": "接力",
    "reading": "jiēlì",
    "meaning": "relay",
    "grade": 7
  },
  {
    "id": 7508,
    "traditional": "接納",
    "simplified": "接纳",
    "reading": "jiēnà",
    "meaning": "accept",
    "grade": 7
  },
  {
    "id": 7509,
    "traditional": "接手",
    "simplified": "接手",
    "reading": "jiēshǒu",
    "meaning": "to take over (duties etc), catcher (baseball etc)",
    "grade": 7
  },
  {
    "id": 7510,
    "traditional": "接送",
    "simplified": "接送",
    "reading": "jiēsòng",
    "meaning": "picking up and dropping off, greeting and sending off, shuttle (transport service)",
    "grade": 7
  },
  {
    "id": 7511,
    "traditional": "接聽",
    "simplified": "接听",
    "reading": "jiētīng",
    "meaning": "to answer the phone",
    "grade": 7
  },
  {
    "id": 7512,
    "traditional": "接通",
    "simplified": "接通",
    "reading": "jiē tōng",
    "meaning": "to connect, to put through",
    "grade": 7
  },
  {
    "id": 7513,
    "traditional": "揭發",
    "simplified": "揭发",
    "reading": "jiēfā",
    "meaning": "to expose, to bring to light, to disclose, revelation",
    "grade": 7
  },
  {
    "id": 7514,
    "traditional": "接替",
    "simplified": "接替",
    "reading": "jiētì",
    "meaning": "to replace, to take over (a position or post)",
    "grade": 7
  },
  {
    "id": 7515,
    "traditional": "揭露",
    "simplified": "揭露",
    "reading": "jiēlù",
    "meaning": "to expose, to unmask, to ferret out, to disclose, disclosure",
    "grade": 7
  },
  {
    "id": 7516,
    "traditional": "揭曉",
    "simplified": "揭晓",
    "reading": "jiēxiǎo",
    "meaning": "to announce publicly, to publish, to make known, to disclose",
    "grade": 7
  },
  {
    "id": 7517,
    "traditional": "揭示",
    "simplified": "揭示",
    "reading": "jiēshì",
    "meaning": "to show, to make known",
    "grade": 7
  },
  {
    "id": 7518,
    "traditional": "節儉",
    "simplified": "节俭",
    "reading": "jiéjiǎn",
    "meaning": "frugal, economical",
    "grade": 7
  },
  {
    "id": 7519,
    "traditional": "節水",
    "simplified": "节水",
    "reading": "jié shuǐ",
    "meaning": "to save water",
    "grade": 7
  },
  {
    "id": 7520,
    "traditional": "節衣縮食",
    "simplified": "节衣缩食",
    "reading": "jiéyī-suōshí",
    "meaning": "to save on food and clothing (idiom), to live frugally",
    "grade": 7
  },
  {
    "id": 7521,
    "traditional": "節氣",
    "simplified": "节气",
    "reading": "jiéqi",
    "meaning": "solar term",
    "grade": 7
  },
  {
    "id": 7522,
    "traditional": "劫",
    "simplified": "劫",
    "reading": "jié",
    "meaning": "to rob, to plunder, to seize by force, to coerce, calamity, abbr. for kalpa 劫波[jie2 bo1]",
    "grade": 7
  },
  {
    "id": 7523,
    "traditional": "劫持",
    "simplified": "劫持",
    "reading": "jiéchí",
    "meaning": "to kidnap, to hijack, to abduct, to hold under duress",
    "grade": 7
  },
  {
    "id": 7524,
    "traditional": "潔淨",
    "simplified": "洁净",
    "reading": "jiéjìng",
    "meaning": "clean, to cleanse",
    "grade": 7
  },
  {
    "id": 7525,
    "traditional": "結冰",
    "simplified": "结冰",
    "reading": "jié bīng",
    "meaning": "to freeze",
    "grade": 7
  },
  {
    "id": 7526,
    "traditional": "結晶",
    "simplified": "结晶",
    "reading": "jiéjīng",
    "meaning": "to crystallize, crystallization, crystal, crystalline, (fig.) the fruit (of labor etc)",
    "grade": 7
  },
  {
    "id": 7527,
    "traditional": "結局",
    "simplified": "结局",
    "reading": "jiéjú",
    "meaning": "final result, outcome, ending",
    "grade": 7
  },
  {
    "id": 7528,
    "traditional": "結識",
    "simplified": "结识",
    "reading": "jiéshí",
    "meaning": "to get to know sb, to meet sb for the first time",
    "grade": 7
  },
  {
    "id": 7529,
    "traditional": "結尾",
    "simplified": "结尾",
    "reading": "jiéwěi",
    "meaning": "ending, coda, to wind up",
    "grade": 7
  },
  {
    "id": 7530,
    "traditional": "截",
    "simplified": "截",
    "reading": "jié",
    "meaning": "m.[proximation]",
    "grade": 7
  },
  {
    "id": 7531,
    "traditional": "截然不同",
    "simplified": "截然不同",
    "reading": "jiérán-bùtóng",
    "meaning": "entirely different, different as black and white",
    "grade": 7
  },
  {
    "id": 7532,
    "traditional": "竭盡全力",
    "simplified": "竭尽全力",
    "reading": "jiéjìn-quánlì",
    "meaning": "to spare no effort (idiom), to do one's utmost",
    "grade": 7
  },
  {
    "id": 7533,
    "traditional": "竭力",
    "simplified": "竭力",
    "reading": "jiélì",
    "meaning": "to do one's utmost",
    "grade": 7
  },
  {
    "id": 7534,
    "traditional": "解答",
    "simplified": "解答",
    "reading": "jiědá",
    "meaning": "answer, explain",
    "grade": 7
  },
  {
    "id": 7535,
    "traditional": "解讀",
    "simplified": "解读",
    "reading": "jiědú",
    "meaning": "to decipher, to decode, to interpret",
    "grade": 7
  },
  {
    "id": 7536,
    "traditional": "解救",
    "simplified": "解救",
    "reading": "jiějiù",
    "meaning": "to rescue, to help out of difficulties, to save the situation",
    "grade": 7
  },
  {
    "id": 7537,
    "traditional": "解剖",
    "simplified": "解剖",
    "reading": "jiěpōu",
    "meaning": "dissect",
    "grade": 7
  },
  {
    "id": 7538,
    "traditional": "解雇",
    "simplified": "解雇",
    "reading": "jiěgù",
    "meaning": "to fire, to sack, to dismiss, to terminate employment",
    "grade": 7
  },
  {
    "id": 7539,
    "traditional": "解散",
    "simplified": "解散",
    "reading": "jiěsàn",
    "meaning": "disperse, dismiss, separate, disband, disincorporate, demobilize, disbandment, dissolve, usher out, let, unmerge, dismissal, break_up, demob, dissolution, break up",
    "grade": 7
  },
  {
    "id": 7540,
    "traditional": "解脫",
    "simplified": "解脱",
    "reading": "jiětuō",
    "meaning": "free (extricate) oneself, release from worldly cares",
    "grade": 7
  },
  {
    "id": 7541,
    "traditional": "解體",
    "simplified": "解体",
    "reading": "jiě tǐ",
    "meaning": "to break up into components, to disintegrate, to collapse, to crumble",
    "grade": 7
  },
  {
    "id": 7542,
    "traditional": "解圍",
    "simplified": "解围",
    "reading": "jiě wéi",
    "meaning": "to lift a siege, to help sb out of trouble or embarrassment",
    "grade": 7
  },
  {
    "id": 7543,
    "traditional": "解析",
    "simplified": "解析",
    "reading": "jiěxī",
    "meaning": "to analyze, to resolve, (math.) analysis, analytic",
    "grade": 7
  },
  {
    "id": 7544,
    "traditional": "介入",
    "simplified": "介入",
    "reading": "jièrù",
    "meaning": "intervene, interpose, get involved",
    "grade": 7
  },
  {
    "id": 7545,
    "traditional": "介意",
    "simplified": "介意",
    "reading": "jiè yì",
    "meaning": "to care about, to take offense, to mind",
    "grade": 7
  },
  {
    "id": 7546,
    "traditional": "戒備",
    "simplified": "戒备",
    "reading": "jièbèi",
    "meaning": "to take precautions, to guard against (emergency)",
    "grade": 7
  },
  {
    "id": 7547,
    "traditional": "戒煙",
    "simplified": "戒烟",
    "reading": "jiè yān",
    "meaning": "to give up smoking",
    "grade": 7
  },
  {
    "id": 7548,
    "traditional": "介於",
    "simplified": "介于",
    "reading": "jièyú",
    "meaning": "between, intermediate, to lie between",
    "grade": 7
  },
  {
    "id": 7549,
    "traditional": "界定",
    "simplified": "界定",
    "reading": "jièdìng",
    "meaning": "define",
    "grade": 7
  },
  {
    "id": 7550,
    "traditional": "戒指",
    "simplified": "戒指",
    "reading": "jièzhi",
    "meaning": "(finger) ring",
    "grade": 7
  },
  {
    "id": 7551,
    "traditional": "屆時",
    "simplified": "届时",
    "reading": "jièshí",
    "meaning": "at appointed time, on the occasion",
    "grade": 7
  },
  {
    "id": 7552,
    "traditional": "界線",
    "simplified": "界线",
    "reading": "jièxiàn",
    "meaning": "limits, bounds, dividing line",
    "grade": 7
  },
  {
    "id": 7553,
    "traditional": "藉口",
    "simplified": "借口",
    "reading": "jièkǒu",
    "meaning": "to use as an excuse, on the pretext, excuse, pretext",
    "grade": 7
  },
  {
    "id": 7554,
    "traditional": "界限",
    "simplified": "界限",
    "reading": "jièxiàn",
    "meaning": "compass, ceiling, bounds, demarcation line, boundary, bourn, tether, purlieu, demarcation, precinct, pale, circumscription, borderland, limits, bound, ambit, butting, Rubicon, terminus, terminal point, confines, end, dividing line, extent, verge, terminus ad quem, limit, division, marge",
    "grade": 7
  },
  {
    "id": 7555,
    "traditional": "借條",
    "simplified": "借条",
    "reading": "jiètiáo",
    "meaning": "receipt for a loan, IOU",
    "grade": 7
  },
  {
    "id": 7556,
    "traditional": "借助",
    "simplified": "借助",
    "reading": "jièzhù",
    "meaning": "to draw support from, with the help of",
    "grade": 7
  },
  {
    "id": 7557,
    "traditional": "借用",
    "simplified": "借用",
    "reading": "jièyòng",
    "meaning": "use sth. for another purpose, have the loan of, use ... for another purpose, borrow",
    "grade": 7
  },
  {
    "id": 7558,
    "traditional": "金字塔",
    "simplified": "金字塔",
    "reading": "jīnzìtǎ",
    "meaning": "pyramid (building or structure)",
    "grade": 7
  },
  {
    "id": 7559,
    "traditional": "金屬",
    "simplified": "金属",
    "reading": "jīnshǔ",
    "meaning": "spangle, metallic, metalline, tincture, malleability, metal, metals in general",
    "grade": 7
  },
  {
    "id": 7560,
    "traditional": "金子",
    "simplified": "金子",
    "reading": "jīnzi",
    "meaning": "Kaneko (Japanese surname), gold",
    "grade": 7
  },
  {
    "id": 7561,
    "traditional": "津貼",
    "simplified": "津贴",
    "reading": "jīntiē",
    "meaning": "allowance",
    "grade": 7
  },
  {
    "id": 7562,
    "traditional": "津津有味",
    "simplified": "津津有味",
    "reading": "jīnjīn-yǒuwèi",
    "meaning": "with keen interest pleasure (idiom), with gusto, to relish, eagerly, with great interest",
    "grade": 7
  },
  {
    "id": 7563,
    "traditional": "筋",
    "simplified": "筋",
    "reading": "jīn",
    "meaning": "muscle, tendon",
    "grade": 7
  },
  {
    "id": 7564,
    "traditional": "禁不住",
    "simplified": "禁不住",
    "reading": "jīnbuzhù",
    "meaning": "can't help it, can't bear it",
    "grade": 7
  },
  {
    "id": 7565,
    "traditional": "僅次於",
    "simplified": "仅次于",
    "reading": "jǐn cìyú",
    "meaning": "second only to..., (in second place) preceded only by...",
    "grade": 7
  },
  {
    "id": 7566,
    "traditional": "儘",
    "simplified": "尽",
    "reading": "jǐn",
    "meaning": "use up, exhaust, try one's best, put to best use, end",
    "grade": 7
  },
  {
    "id": 7567,
    "traditional": "盡早",
    "simplified": "尽早",
    "reading": "jǐnzǎo",
    "meaning": "as early as possible",
    "grade": 7
  },
  {
    "id": 7568,
    "traditional": "緊迫",
    "simplified": "紧迫",
    "reading": "jǐnpò",
    "meaning": "pressing, urgent",
    "grade": 7
  },
  {
    "id": 7569,
    "traditional": "緊接著",
    "simplified": "紧接着",
    "reading": "jǐnjiēzhe",
    "meaning": "follow close behind",
    "grade": 7
  },
  {
    "id": 7570,
    "traditional": "緊湊",
    "simplified": "紧凑",
    "reading": "jǐncòu",
    "meaning": "compact, terse, tight (schedule)",
    "grade": 7
  },
  {
    "id": 7571,
    "traditional": "緊缺",
    "simplified": "紧缺",
    "reading": "jǐnquē",
    "meaning": "in short supply, scarce",
    "grade": 7
  },
  {
    "id": 7572,
    "traditional": "緊縮",
    "simplified": "紧缩",
    "reading": "jǐnsuō",
    "meaning": "(economics) to reduce, to curtail, to cut back, to tighten, austerity, tightening, crunch",
    "grade": 7
  },
  {
    "id": 7573,
    "traditional": "錦旗",
    "simplified": "锦旗",
    "reading": "jǐnqí",
    "meaning": "Banner",
    "grade": 7
  },
  {
    "id": 7574,
    "traditional": "盡情",
    "simplified": "尽情",
    "reading": "jìnqíng",
    "meaning": "fully, as much as one likes",
    "grade": 7
  },
  {
    "id": 7575,
    "traditional": "盡頭",
    "simplified": "尽头",
    "reading": "jìntóu",
    "meaning": "end, extremity, limit",
    "grade": 7
  },
  {
    "id": 7576,
    "traditional": "謹慎",
    "simplified": "谨慎",
    "reading": "jǐnshèn",
    "meaning": "cautious, circumspect",
    "grade": 7
  },
  {
    "id": 7577,
    "traditional": "進程",
    "simplified": "进程",
    "reading": "jìnchéng",
    "meaning": "process, course",
    "grade": 7
  },
  {
    "id": 7578,
    "traditional": "進場",
    "simplified": "进场",
    "reading": "jìn chǎng",
    "meaning": "march into arena",
    "grade": 7
  },
  {
    "id": 7579,
    "traditional": "進出",
    "simplified": "进出",
    "reading": "jìnchū",
    "meaning": "go in and out",
    "grade": 7
  },
  {
    "id": 7580,
    "traditional": "進度",
    "simplified": "进度",
    "reading": "jìndù",
    "meaning": "pace, rate of advance, schedule, rate, planned speed, gradation, tempo",
    "grade": 7
  },
  {
    "id": 7581,
    "traditional": "進出口",
    "simplified": "进出口",
    "reading": "jìnchūkǒu",
    "meaning": "import and export",
    "grade": 7
  },
  {
    "id": 7582,
    "traditional": "進而",
    "simplified": "进而",
    "reading": "jìnér",
    "meaning": "conj.: proceeding to the next step",
    "grade": 7
  },
  {
    "id": 7583,
    "traditional": "進修",
    "simplified": "进修",
    "reading": "jìnxiū",
    "meaning": "engage in advanced studies, take refresher course",
    "grade": 7
  },
  {
    "id": 7584,
    "traditional": "近年來",
    "simplified": "近年来",
    "reading": "jìnniánlái",
    "meaning": "for the past few years",
    "grade": 7
  },
  {
    "id": 7585,
    "traditional": "勁頭",
    "simplified": "劲头",
    "reading": "jìntóu",
    "meaning": "enthusiasm, zeal, vigor, strength",
    "grade": 7
  },
  {
    "id": 7586,
    "traditional": "晉升",
    "simplified": "晋升",
    "reading": "jìnshēng",
    "meaning": "to promote to a higher position",
    "grade": 7
  },
  {
    "id": 7587,
    "traditional": "浸泡",
    "simplified": "浸泡",
    "reading": "jìnpào",
    "meaning": "to steep, to soak, to immerse",
    "grade": 7
  },
  {
    "id": 7588,
    "traditional": "禁忌",
    "simplified": "禁忌",
    "reading": "jìnjì",
    "meaning": "tabu, vetanda, taboo, nono, contraindication",
    "grade": 7
  },
  {
    "id": 7589,
    "traditional": "禁區",
    "simplified": "禁区",
    "reading": "jìnqū",
    "meaning": "restricted area, forbidden region",
    "grade": 7
  },
  {
    "id": 7590,
    "traditional": "莖",
    "simplified": "茎",
    "reading": "jīng",
    "meaning": "stalk, stem, CL:條|条[tiao2]",
    "grade": 7
  },
  {
    "id": 7591,
    "traditional": "經",
    "simplified": "经",
    "reading": "jīng",
    "meaning": "as a result of, through, after",
    "grade": 7
  },
  {
    "id": 7592,
    "traditional": "經度",
    "simplified": "经度",
    "reading": "jīngdù",
    "meaning": "longitude",
    "grade": 7
  },
  {
    "id": 7593,
    "traditional": "經久不息",
    "simplified": "经久不息",
    "reading": "jīngjiǔ-bùxī",
    "meaning": "Last forever",
    "grade": 7
  },
  {
    "id": 7594,
    "traditional": "經貿",
    "simplified": "经贸",
    "reading": "jīngmào",
    "meaning": "economy and trade",
    "grade": 7
  },
  {
    "id": 7595,
    "traditional": "經商",
    "simplified": "经商",
    "reading": "jīng shāng",
    "meaning": "to trade, to carry out commercial activities, in business",
    "grade": 7
  },
  {
    "id": 7596,
    "traditional": "經受",
    "simplified": "经受",
    "reading": "jīngshòu",
    "meaning": "to undergo (hardship), to endure, to withstand",
    "grade": 7
  },
  {
    "id": 7597,
    "traditional": "荊棘",
    "simplified": "荆棘",
    "reading": "jīngjí",
    "meaning": "thistles and thorns, brambles, thorny undergrowth",
    "grade": 7
  },
  {
    "id": 7598,
    "traditional": "驚",
    "simplified": "惊",
    "reading": "jīng",
    "meaning": "to start, to be frightened, to be scared, alarm",
    "grade": 7
  },
  {
    "id": 7599,
    "traditional": "驚詫",
    "simplified": "惊诧",
    "reading": "jīngchà",
    "meaning": "to be surprised, to be amazed, to be stunned",
    "grade": 7
  },
  {
    "id": 7600,
    "traditional": "驚慌",
    "simplified": "惊慌",
    "reading": "jīnghuāng",
    "meaning": "to panic, to be alarmed",
    "grade": 7
  },
  {
    "id": 7601,
    "traditional": "驚慌失措",
    "simplified": "惊慌失措",
    "reading": "jīnghuāng-shīcuò",
    "meaning": "to lose one's head out of fear (idiom)",
    "grade": 7
  },
  {
    "id": 7602,
    "traditional": "驚奇",
    "simplified": "惊奇",
    "reading": "jīngqí",
    "meaning": "wonder, be surprised/amazed",
    "grade": 7
  },
  {
    "id": 7603,
    "traditional": "驚嘆",
    "simplified": "惊叹",
    "reading": "jīngtàn",
    "meaning": "to exclaim in admiration, a gasp of surprise",
    "grade": 7
  },
  {
    "id": 7604,
    "traditional": "驚天動地",
    "simplified": "惊天动地",
    "reading": "jīngtiān-dòngdì",
    "meaning": "world-shaking (idiom)",
    "grade": 7
  },
  {
    "id": 7605,
    "traditional": "驚險",
    "simplified": "惊险",
    "reading": "jīngxiǎn",
    "meaning": "thrilling, a thriller",
    "grade": 7
  },
  {
    "id": 7606,
    "traditional": "驚心動魄",
    "simplified": "惊心动魄",
    "reading": "jīngxīn-dòngpò",
    "meaning": "shaking one to the core, extremely disturbing, hair-raising (idiom)",
    "grade": 7
  },
  {
    "id": 7607,
    "traditional": "驚醒",
    "simplified": "惊醒",
    "reading": "jīngxing",
    "meaning": "to rouse, to be woken by sth, to wake with a start, to sleep lightly",
    "grade": 7
  },
  {
    "id": 7608,
    "traditional": "驚訝",
    "simplified": "惊讶",
    "reading": "jīngyà",
    "meaning": "amazed, astounded",
    "grade": 7
  },
  {
    "id": 7609,
    "traditional": "晶瑩",
    "simplified": "晶莹",
    "reading": "jīngyíng",
    "meaning": "sparkling and translucent",
    "grade": 7
  },
  {
    "id": 7610,
    "traditional": "兢兢業業",
    "simplified": "兢兢业业",
    "reading": "jīngjīng-yèyè",
    "meaning": "cautious and conscientious",
    "grade": 7
  },
  {
    "id": 7611,
    "traditional": "精打細算",
    "simplified": "精打细算",
    "reading": "jīngdǎ-xìsuàn",
    "meaning": "meticulous planning and careful accounting (idiom)",
    "grade": 7
  },
  {
    "id": 7612,
    "traditional": "精華",
    "simplified": "精华",
    "reading": "jīnghuá",
    "meaning": "elite, prime, cream, plum, wale, goodness, distillation, substance, pink, quintessence, distillate, flower, extract, essence, gem, pick, marrow, soul, elixir",
    "grade": 7
  },
  {
    "id": 7613,
    "traditional": "精簡",
    "simplified": "精简",
    "reading": "jīngjiǎn",
    "meaning": "to simplify, to reduce",
    "grade": 7
  },
  {
    "id": 7614,
    "traditional": "精練",
    "simplified": "精练",
    "reading": "jīngliàn",
    "meaning": "(textiles) to scour, to degum (silk), variant of 精煉|精炼[jing1 lian4]",
    "grade": 7
  },
  {
    "id": 7615,
    "traditional": "精妙",
    "simplified": "精妙",
    "reading": "jīngmiào",
    "meaning": "exquisite, fine and delicate (usu. of works of art)",
    "grade": 7
  },
  {
    "id": 7616,
    "traditional": "精明",
    "simplified": "精明",
    "reading": "jīngmíng",
    "meaning": "astute, shrewd",
    "grade": 7
  },
  {
    "id": 7617,
    "traditional": "精疲力竭",
    "simplified": "精疲力竭",
    "reading": "jīngpí-lìjié",
    "meaning": "spirit weary, strength exhausted (idiom), spent, drained, washed out",
    "grade": 7
  },
  {
    "id": 7618,
    "traditional": "精確",
    "simplified": "精确",
    "reading": "jīngquè",
    "meaning": "elegant, accurate, precise, exact",
    "grade": 7
  },
  {
    "id": 7619,
    "traditional": "精神病",
    "simplified": "精神病",
    "reading": "jīngshénbìng",
    "meaning": "mental disorder, psychosis",
    "grade": 7
  },
  {
    "id": 7620,
    "traditional": "精髓",
    "simplified": "精髓",
    "reading": "jīngsuǐ",
    "meaning": "marrow, pith, quintessence, essence",
    "grade": 7
  },
  {
    "id": 7621,
    "traditional": "精細",
    "simplified": "精细",
    "reading": "jīngxì",
    "meaning": "fine, meticulous, careful",
    "grade": 7
  },
  {
    "id": 7622,
    "traditional": "精通",
    "simplified": "精通",
    "reading": "jīngtōng",
    "meaning": "to be proficient in, to master (a subject)",
    "grade": 7
  },
  {
    "id": 7623,
    "traditional": "精心",
    "simplified": "精心",
    "reading": "jīngxīn",
    "meaning": "with utmost care, fine, meticulous, detailed",
    "grade": 7
  },
  {
    "id": 7624,
    "traditional": "精益求精",
    "simplified": "精益求精",
    "reading": "jīngyìqiújīng",
    "meaning": "to perfect sth that is already outstanding (idiom), constantly improving",
    "grade": 7
  },
  {
    "id": 7625,
    "traditional": "精英",
    "simplified": "精英",
    "reading": "jīngyīng",
    "meaning": "quintessence, flower, broth of a boy, elite, broth of a man, aristocracy, essence, cream, powerhouse",
    "grade": 7
  },
  {
    "id": 7626,
    "traditional": "精緻",
    "simplified": "精致",
    "reading": "jīngzhì",
    "meaning": "fine, exquisite, delicate",
    "grade": 7
  },
  {
    "id": 7627,
    "traditional": "景觀",
    "simplified": "景观",
    "reading": "jǐngguān",
    "meaning": "view, perspective, aspect, scene, prospect, vista, panorama, landscape",
    "grade": 7
  },
  {
    "id": 7628,
    "traditional": "頸部",
    "simplified": "颈部",
    "reading": "jǐngbù",
    "meaning": "neck",
    "grade": 7
  },
  {
    "id": 7629,
    "traditional": "景區",
    "simplified": "景区",
    "reading": "jǐngqū",
    "meaning": "scenic area",
    "grade": 7
  },
  {
    "id": 7630,
    "traditional": "警車",
    "simplified": "警车",
    "reading": "jǐngchē",
    "meaning": "police car",
    "grade": 7
  },
  {
    "id": 7631,
    "traditional": "警官",
    "simplified": "警官",
    "reading": "jǐngguān",
    "meaning": "constable, police officer",
    "grade": 7
  },
  {
    "id": 7632,
    "traditional": "警惕",
    "simplified": "警惕",
    "reading": "jǐngtì",
    "meaning": "to be on the alert, vigilant, alert, on guard, to warn",
    "grade": 7
  },
  {
    "id": 7633,
    "traditional": "警鐘",
    "simplified": "警钟",
    "reading": "jǐngzhōng",
    "meaning": "alarm bell",
    "grade": 7
  },
  {
    "id": 7634,
    "traditional": "淨化",
    "simplified": "净化",
    "reading": "jìnghuà",
    "meaning": "to purify",
    "grade": 7
  },
  {
    "id": 7635,
    "traditional": "競技",
    "simplified": "竞技",
    "reading": "jìngjì",
    "meaning": "competition of skill (e.g. sports), athletics tournament",
    "grade": 7
  },
  {
    "id": 7636,
    "traditional": "競選",
    "simplified": "竞选",
    "reading": "jìngxuǎn",
    "meaning": "campaign (for office), run for",
    "grade": 7
  },
  {
    "id": 7637,
    "traditional": "競相",
    "simplified": "竞相",
    "reading": "jìngxiāng",
    "meaning": "competitive, eagerly, to vie",
    "grade": 7
  },
  {
    "id": 7638,
    "traditional": "竟敢",
    "simplified": "竟敢",
    "reading": "jìng gǎn",
    "meaning": "to have the impertinence, to have the cheek to",
    "grade": 7
  },
  {
    "id": 7639,
    "traditional": "竟",
    "simplified": "竟",
    "reading": "jìng",
    "meaning": "unexpectedly",
    "grade": 7
  },
  {
    "id": 7640,
    "traditional": "敬",
    "simplified": "敬",
    "reading": "jìng",
    "meaning": "to respect, to venerate, to salute, to offer",
    "grade": 7
  },
  {
    "id": 7641,
    "traditional": "敬愛",
    "simplified": "敬爱",
    "reading": "jìngài",
    "meaning": "respect and love",
    "grade": 7
  },
  {
    "id": 7642,
    "traditional": "敬而遠之",
    "simplified": "敬而远之",
    "reading": "jìngéryuǎnzhī",
    "meaning": "to show respect from a distance (idiom), to remain at a respectful distance",
    "grade": 7
  },
  {
    "id": 7643,
    "traditional": "敬酒",
    "simplified": "敬酒",
    "reading": "jìng jiǔ",
    "meaning": "to toast, to propose a toast",
    "grade": 7
  },
  {
    "id": 7644,
    "traditional": "敬禮",
    "simplified": "敬礼",
    "reading": "jìng lǐ",
    "meaning": "to salute, salute",
    "grade": 7
  },
  {
    "id": 7645,
    "traditional": "敬佩",
    "simplified": "敬佩",
    "reading": "jìngpèi",
    "meaning": "to esteem, to admire",
    "grade": 7
  },
  {
    "id": 7646,
    "traditional": "敬請",
    "simplified": "敬请",
    "reading": "jìngqǐng",
    "meaning": "please (do sth) (deferential form)",
    "grade": 7
  },
  {
    "id": 7647,
    "traditional": "敬業",
    "simplified": "敬业",
    "reading": "jìngyè",
    "meaning": "to be dedicated to one's work, to respect one's work",
    "grade": 7
  },
  {
    "id": 7648,
    "traditional": "敬意",
    "simplified": "敬意",
    "reading": "jìngyì",
    "meaning": "respect, tribute",
    "grade": 7
  },
  {
    "id": 7649,
    "traditional": "敬重",
    "simplified": "敬重",
    "reading": "jìngzhòng",
    "meaning": "to respect deeply, to revere, to esteem",
    "grade": 7
  },
  {
    "id": 7650,
    "traditional": "靜止",
    "simplified": "静止",
    "reading": "jìngzhǐ",
    "meaning": "still, immobile, static, stationary",
    "grade": 7
  },
  {
    "id": 7651,
    "traditional": "境地",
    "simplified": "境地",
    "reading": "jìngdì",
    "meaning": "circumstances",
    "grade": 7
  },
  {
    "id": 7652,
    "traditional": "境界",
    "simplified": "境界",
    "reading": "jìngjiè",
    "meaning": "confine, state, delimitation, extent reached, ambit, bourne, realm, hem, boundary, bourn, plane attained, region, precinct, mete, division",
    "grade": 7
  },
  {
    "id": 7653,
    "traditional": "境內",
    "simplified": "境内",
    "reading": "jìngnèi",
    "meaning": "within the borders, internal (to a country, province, city etc), domestic",
    "grade": 7
  },
  {
    "id": 7654,
    "traditional": "境外",
    "simplified": "境外",
    "reading": "jìngwài",
    "meaning": "outside (a country's) borders",
    "grade": 7
  },
  {
    "id": 7655,
    "traditional": "境遇",
    "simplified": "境遇",
    "reading": "jìngyù",
    "meaning": "circumstance",
    "grade": 7
  },
  {
    "id": 7656,
    "traditional": "窘迫",
    "simplified": "窘迫",
    "reading": "jiǒngpò",
    "meaning": "poverty-stricken, very poor, hard-pressed, in a predicament, embarrassed",
    "grade": 7
  },
  {
    "id": 7657,
    "traditional": "糾纏",
    "simplified": "纠缠",
    "reading": "jiūchán",
    "meaning": "to be in a tangle, to nag",
    "grade": 7
  },
  {
    "id": 7658,
    "traditional": "揪",
    "simplified": "揪",
    "reading": "jiū",
    "meaning": "to seize, to clutch, to hold tight, to grip",
    "grade": 7
  },
  {
    "id": 7659,
    "traditional": "久違",
    "simplified": "久违",
    "reading": "jiǔwéi",
    "meaning": "(haven't done sth) for a long time, a long time since we last met",
    "grade": 7
  },
  {
    "id": 7660,
    "traditional": "久仰",
    "simplified": "久仰",
    "reading": "jiǔyǎng",
    "meaning": "honorific: I've long looked forward to meeting you., It's an honor to meet you at last.",
    "grade": 7
  },
  {
    "id": 7661,
    "traditional": "酒精",
    "simplified": "酒精",
    "reading": "jiǔjīng",
    "meaning": "ethanol, alcoholic, spirits of wine, alcohol, ardent spirits, ethyl alcohol, spirit, aqua vitae",
    "grade": 7
  },
  {
    "id": 7662,
    "traditional": "酒樓",
    "simplified": "酒楼",
    "reading": "jiǔlóu",
    "meaning": "restaurant",
    "grade": 7
  },
  {
    "id": 7663,
    "traditional": "救護車",
    "simplified": "救护车",
    "reading": "jiùhùchē",
    "meaning": "ambulance, CL:輛|辆[liang4]",
    "grade": 7
  },
  {
    "id": 7664,
    "traditional": "救濟",
    "simplified": "救济",
    "reading": "jiùjì",
    "meaning": "emergency relief, to help the needy with cash or goods",
    "grade": 7
  },
  {
    "id": 7665,
    "traditional": "救治",
    "simplified": "救治",
    "reading": "jiùzhì",
    "meaning": "to rescue and give medical treatment",
    "grade": 7
  },
  {
    "id": 7666,
    "traditional": "就餐",
    "simplified": "就餐",
    "reading": "jiù cān",
    "meaning": "to dine",
    "grade": 7
  },
  {
    "id": 7667,
    "traditional": "就地",
    "simplified": "就地",
    "reading": "jiùdì",
    "meaning": "locally, on the spot",
    "grade": 7
  },
  {
    "id": 7668,
    "traditional": "就讀",
    "simplified": "就读",
    "reading": "jiùdú",
    "meaning": "study, attend school",
    "grade": 7
  },
  {
    "id": 7669,
    "traditional": "就近",
    "simplified": "就近",
    "reading": "jiùjìn",
    "meaning": "nearby, in a close neighborhood",
    "grade": 7
  },
  {
    "id": 7670,
    "traditional": "就任",
    "simplified": "就任",
    "reading": "jiùrèn",
    "meaning": "to take office, to assume a post",
    "grade": 7
  },
  {
    "id": 7671,
    "traditional": "就醫",
    "simplified": "就医",
    "reading": "jiù yī",
    "meaning": "seek medical advice",
    "grade": 7
  },
  {
    "id": 7672,
    "traditional": "就診",
    "simplified": "就诊",
    "reading": "jiù zhěn",
    "meaning": "to see a doctor, to seek medical advice",
    "grade": 7
  },
  {
    "id": 7673,
    "traditional": "就職",
    "simplified": "就职",
    "reading": "jiù zhí",
    "meaning": "to take office, to assume a post",
    "grade": 7
  },
  {
    "id": 7674,
    "traditional": "就座",
    "simplified": "就座",
    "reading": "jiù zuò",
    "meaning": "Seating seat",
    "grade": 7
  },
  {
    "id": 7675,
    "traditional": "舅舅",
    "simplified": "舅舅",
    "reading": "jiùjiu",
    "meaning": "mother's brother, maternal uncle (informal), CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 7676,
    "traditional": "拘留",
    "simplified": "拘留",
    "reading": "jūliú",
    "meaning": "to detain (a prisoner), to keep sb in custody",
    "grade": 7
  },
  {
    "id": 7677,
    "traditional": "拘束",
    "simplified": "拘束",
    "reading": "jūshù",
    "meaning": "to restrict, to restrain, constrained, awkward, ill at ease, uncomfortable, reticent",
    "grade": 7
  },
  {
    "id": 7678,
    "traditional": "居高臨下",
    "simplified": "居高临下",
    "reading": "jūgāo-línxià",
    "meaning": "to live high and look down (idiom), to overlook, to tower above, to occupy the high ground, fig. arrogance based on one's social position",
    "grade": 7
  },
  {
    "id": 7679,
    "traditional": "居民樓",
    "simplified": "居民楼",
    "reading": "jūmínlóu",
    "meaning": "Residential building",
    "grade": 7
  },
  {
    "id": 7680,
    "traditional": "鞠躬",
    "simplified": "鞠躬",
    "reading": "jūgōng",
    "meaning": "to bow, (literary) to bend down",
    "grade": 7
  },
  {
    "id": 7681,
    "traditional": "局部",
    "simplified": "局部",
    "reading": "júbù",
    "meaning": "partial, department, section, parts, part, det.: part, maculation, region, spot, dapple, fleck, patch, division, speckle",
    "grade": 7
  },
  {
    "id": 7682,
    "traditional": "局勢",
    "simplified": "局势",
    "reading": "júshì",
    "meaning": "situation",
    "grade": 7
  },
  {
    "id": 7683,
    "traditional": "局限",
    "simplified": "局限",
    "reading": "júxiàn",
    "meaning": "to limit, to confine, to restrict sth within set boundaries",
    "grade": 7
  },
  {
    "id": 7684,
    "traditional": "菊花",
    "simplified": "菊花",
    "reading": "júhuā",
    "meaning": "chrysanthemum, (slang) anus",
    "grade": 7
  },
  {
    "id": 7685,
    "traditional": "橘子",
    "simplified": "橘子",
    "reading": "júzi",
    "meaning": "tangerine, CL:個|个[ge4],瓣[ban4]",
    "grade": 7
  },
  {
    "id": 7686,
    "traditional": "沮喪",
    "simplified": "沮丧",
    "reading": "jǔsàng",
    "meaning": "depressed, disheartened",
    "grade": 7
  },
  {
    "id": 7687,
    "traditional": "舉報",
    "simplified": "举报",
    "reading": "jǔbào",
    "meaning": "to report (malefactors to the police), to denounce",
    "grade": 7
  },
  {
    "id": 7688,
    "traditional": "舉措",
    "simplified": "举措",
    "reading": "jǔcuò",
    "meaning": "to move, to act, action, decision, conduct, manner",
    "grade": 7
  },
  {
    "id": 7689,
    "traditional": "舉例",
    "simplified": "举例",
    "reading": "jǔ lì",
    "meaning": "instance, quote, adduce, give an example",
    "grade": 7
  },
  {
    "id": 7690,
    "traditional": "舉世聞名",
    "simplified": "举世闻名",
    "reading": "jǔshì-wénmíng",
    "meaning": "world-famous (idiom)",
    "grade": 7
  },
  {
    "id": 7691,
    "traditional": "舉世無雙",
    "simplified": "举世无双",
    "reading": "jǔshì-wúshuāng",
    "meaning": "unrivaled (idiom), world number one, unique, unequaled",
    "grade": 7
  },
  {
    "id": 7692,
    "traditional": "舉世矚目",
    "simplified": "举世瞩目",
    "reading": "jǔshì-zhǔmù",
    "meaning": "to receive worldwide attention",
    "grade": 7
  },
  {
    "id": 7693,
    "traditional": "舉一反三",
    "simplified": "举一反三",
    "reading": "jǔyī-fǎnsān",
    "meaning": "to raise one and infer three, to deduce many things from one case (idiom)",
    "grade": 7
  },
  {
    "id": 7694,
    "traditional": "舉止",
    "simplified": "举止",
    "reading": "jǔzhǐ",
    "meaning": "bearing, manner, mien",
    "grade": 7
  },
  {
    "id": 7695,
    "traditional": "舉重",
    "simplified": "举重",
    "reading": "jǔzhòng",
    "meaning": "to lift weights, weight-lifting (sports)",
    "grade": 7
  },
  {
    "id": 7696,
    "traditional": "巨額",
    "simplified": "巨额",
    "reading": "jùé",
    "meaning": "large sum (of money), a huge amount",
    "grade": 7
  },
  {
    "id": 7697,
    "traditional": "巨人",
    "simplified": "巨人",
    "reading": "jùrén",
    "meaning": "colossus, whale, Titan, Goliath, titan, bouncer, darb, goliath, Brobdingnagian, heavyweight, monster, behemoth, giant, Heracles, hulk",
    "grade": 7
  },
  {
    "id": 7698,
    "traditional": "巨頭",
    "simplified": "巨头",
    "reading": "jùtóu",
    "meaning": "tycoon, magnate, big player (including company, country, school etc), big shot",
    "grade": 7
  },
  {
    "id": 7699,
    "traditional": "巨星",
    "simplified": "巨星",
    "reading": "jùxīng",
    "meaning": "giant, giant star, superstar",
    "grade": 7
  },
  {
    "id": 7700,
    "traditional": "巨型",
    "simplified": "巨型",
    "reading": "jùxíng",
    "meaning": "giant, enormous",
    "grade": 7
  },
  {
    "id": 7701,
    "traditional": "劇烈",
    "simplified": "剧烈",
    "reading": "jùliè",
    "meaning": "violent, fierce",
    "grade": 7
  },
  {
    "id": 7702,
    "traditional": "劇目",
    "simplified": "剧目",
    "reading": "jùmù",
    "meaning": "repertoire, list of plays or operas",
    "grade": 7
  },
  {
    "id": 7703,
    "traditional": "劇情",
    "simplified": "剧情",
    "reading": "jùqíng",
    "meaning": "story line, story, plot, gut",
    "grade": 7
  },
  {
    "id": 7704,
    "traditional": "劇團",
    "simplified": "剧团",
    "reading": "jùtuán",
    "meaning": "opera troupe, theater company, showman, rep, theatrical company, troupe, company",
    "grade": 7
  },
  {
    "id": 7705,
    "traditional": "劇院",
    "simplified": "剧院",
    "reading": "jùyuàn",
    "meaning": "theater",
    "grade": 7
  },
  {
    "id": 7706,
    "traditional": "據此",
    "simplified": "据此",
    "reading": "jùcǐ",
    "meaning": "according to this, on the ground of the above, (formally introduces reported speech in writing)",
    "grade": 7
  },
  {
    "id": 7707,
    "traditional": "劇組",
    "simplified": "剧组",
    "reading": "jùzǔ",
    "meaning": "cast and crew, performers and production team",
    "grade": 7
  },
  {
    "id": 7708,
    "traditional": "據悉",
    "simplified": "据悉",
    "reading": "jùxī",
    "meaning": "according to reports, it is reported (that)",
    "grade": 7
  },
  {
    "id": 7709,
    "traditional": "距",
    "simplified": "距",
    "reading": "jù",
    "meaning": "be apart/away from",
    "grade": 7
  },
  {
    "id": 7710,
    "traditional": "鋸",
    "simplified": "锯",
    "reading": "jù",
    "meaning": "variant of 鋦|锔[ju1], a saw, to cut with a saw",
    "grade": 7
  },
  {
    "id": 7711,
    "traditional": "聚集",
    "simplified": "聚集",
    "reading": "jùjí",
    "meaning": "gather, assemble, collect",
    "grade": 7
  },
  {
    "id": 7712,
    "traditional": "聚精會神",
    "simplified": "聚精会神",
    "reading": "jùjīng-huìshén",
    "meaning": "to concentrate one's attention (idiom)",
    "grade": 7
  },
  {
    "id": 7713,
    "traditional": "捐獻",
    "simplified": "捐献",
    "reading": "juānxiàn",
    "meaning": "to donate, to contribute, donation, contribution",
    "grade": 7
  },
  {
    "id": 7714,
    "traditional": "捲入",
    "simplified": "卷入",
    "reading": "juǎnrù",
    "meaning": "be drawn into, be involved in",
    "grade": 7
  },
  {
    "id": 7715,
    "traditional": "卷子",
    "simplified": "卷子",
    "reading": "juǎnzi",
    "meaning": "steamed roll, spring roll, test paper, examination paper",
    "grade": 7
  },
  {
    "id": 7716,
    "traditional": "圈",
    "simplified": "圈",
    "reading": "quān",
    "meaning": "m.[activity]",
    "grade": 7
  },
  {
    "id": 7717,
    "traditional": "決議",
    "simplified": "决议",
    "reading": "juéyì",
    "meaning": "resolution",
    "grade": 7
  },
  {
    "id": 7718,
    "traditional": "訣別",
    "simplified": "诀别",
    "reading": "juébié",
    "meaning": "to bid farewell, to part (usually with little hope of meeting again)",
    "grade": 7
  },
  {
    "id": 7719,
    "traditional": "訣竅",
    "simplified": "诀窍",
    "reading": "juéqiào",
    "meaning": "secret, trick, knack, key",
    "grade": 7
  },
  {
    "id": 7720,
    "traditional": "角逐",
    "simplified": "角逐",
    "reading": "juézhú",
    "meaning": "contend, tussle",
    "grade": 7
  },
  {
    "id": 7721,
    "traditional": "覺醒",
    "simplified": "觉醒",
    "reading": "juéxǐng",
    "meaning": "to awaken, to come to realize, awakened to the truth, the truth dawns upon one, scales fall from the eyes, to become aware",
    "grade": 7
  },
  {
    "id": 7722,
    "traditional": "絕技",
    "simplified": "绝技",
    "reading": "juéjì",
    "meaning": "consummate skill, supreme feat, tour-de-force, stunt",
    "grade": 7
  },
  {
    "id": 7723,
    "traditional": "絕緣",
    "simplified": "绝缘",
    "reading": "juéyuán",
    "meaning": "to have no contact with, to be cut off from, (electricity) to insulate",
    "grade": 7
  },
  {
    "id": 7724,
    "traditional": "絕招",
    "simplified": "绝招",
    "reading": "juézhāo",
    "meaning": "unique skill, unexpected tricky move (as a last resort), masterstroke, finishing blow",
    "grade": 7
  },
  {
    "id": 7725,
    "traditional": "崛起",
    "simplified": "崛起",
    "reading": "juéqǐ",
    "meaning": "to rise abruptly (to a towering position), to tower over, to spring up, to emerge suddenly, the emergence (e.g. of a power)",
    "grade": 7
  },
  {
    "id": 7726,
    "traditional": "倔強",
    "simplified": "倔强",
    "reading": "juéjiàng",
    "meaning": "stubborn, obstinate, unbending",
    "grade": 7
  },
  {
    "id": 7727,
    "traditional": "倔",
    "simplified": "倔",
    "reading": "jué",
    "meaning": "crabby, tough",
    "grade": 7
  },
  {
    "id": 7728,
    "traditional": "爵士",
    "simplified": "爵士",
    "reading": "juéshì",
    "meaning": "knight, Sir, (loanword) jazz",
    "grade": 7
  },
  {
    "id": 7729,
    "traditional": "軍官",
    "simplified": "军官",
    "reading": "jūnguān",
    "meaning": "officer (military)",
    "grade": 7
  },
  {
    "id": 7730,
    "traditional": "均衡",
    "simplified": "均衡",
    "reading": "jūnhéng",
    "meaning": "balance",
    "grade": 7
  },
  {
    "id": 7731,
    "traditional": "均勻",
    "simplified": "均匀",
    "reading": "jūnyún",
    "meaning": "even, well-distributed, homogeneous, well-proportioned (figure, body etc)",
    "grade": 7
  },
  {
    "id": 7732,
    "traditional": "君子",
    "simplified": "君子",
    "reading": "jūnzǐ",
    "meaning": "a man of noble character, man of noble character, gentleman, man of worth, sovereign",
    "grade": 7
  },
  {
    "id": 7733,
    "traditional": "俊",
    "simplified": "俊",
    "reading": "jùn",
    "meaning": "variant of 俊[jun4], (dialectal pronunciation of 俊[jun4]), cool, neat",
    "grade": 7
  },
  {
    "id": 7734,
    "traditional": "俊俏",
    "simplified": "俊俏",
    "reading": "jùnqiào",
    "meaning": "attractive and intelligent, charming, elegant",
    "grade": 7
  },
  {
    "id": 7735,
    "traditional": "駿馬",
    "simplified": "骏马",
    "reading": "jùnmǎ",
    "meaning": "fine horse, steed",
    "grade": 7
  },
  {
    "id": 7736,
    "traditional": "竣工",
    "simplified": "竣工",
    "reading": "jùn gōng",
    "meaning": "to complete a project",
    "grade": 7
  },
  {
    "id": 7737,
    "traditional": "卡車",
    "simplified": "卡车",
    "reading": "kǎchē",
    "meaning": "teamster, motortruck, lorry, camion, truck",
    "grade": 7
  },
  {
    "id": 7738,
    "traditional": "卡片",
    "simplified": "卡片",
    "reading": "kǎpiàn",
    "meaning": "card",
    "grade": 7
  },
  {
    "id": 7739,
    "traditional": "卡通",
    "simplified": "卡通",
    "reading": "kǎtōng",
    "meaning": "cartoon",
    "grade": 7
  },
  {
    "id": 7740,
    "traditional": "開辦",
    "simplified": "开办",
    "reading": "kāibàn",
    "meaning": "open, set up, start (business/etc.)",
    "grade": 7
  },
  {
    "id": 7741,
    "traditional": "開采",
    "simplified": "开采",
    "reading": "kāicǎi",
    "meaning": "mine, extract, exploit",
    "grade": 7
  },
  {
    "id": 7742,
    "traditional": "開場",
    "simplified": "开场",
    "reading": "kāi chǎng",
    "meaning": "to begin, to open, to start, beginning of an event",
    "grade": 7
  },
  {
    "id": 7743,
    "traditional": "開場白",
    "simplified": "开场白",
    "reading": "kāichǎngbái",
    "meaning": "prologue of play, opening remarks, preamble (of speeches, articles etc)",
    "grade": 7
  },
  {
    "id": 7744,
    "traditional": "開動",
    "simplified": "开动",
    "reading": "kāi dòng",
    "meaning": "to start, to set in motion, to move, to march, to dig in (eating), to tuck in (eating)",
    "grade": 7
  },
  {
    "id": 7745,
    "traditional": "開除",
    "simplified": "开除",
    "reading": "kāichú",
    "meaning": "to expel",
    "grade": 7
  },
  {
    "id": 7746,
    "traditional": "開發區",
    "simplified": "开发区",
    "reading": "kāifāqū",
    "meaning": "development zone",
    "grade": 7
  },
  {
    "id": 7747,
    "traditional": "開發商",
    "simplified": "开发商",
    "reading": "kāifāshāng",
    "meaning": "developer (of real estate, a commercial product etc)",
    "grade": 7
  },
  {
    "id": 7748,
    "traditional": "開工",
    "simplified": "开工",
    "reading": "kāi gōng",
    "meaning": "to begin work (of a factory or engineering operation), to start a construction job",
    "grade": 7
  },
  {
    "id": 7749,
    "traditional": "開墾",
    "simplified": "开垦",
    "reading": "kāikěn",
    "meaning": "to clear a wild area for cultivation, to put under the plow",
    "grade": 7
  },
  {
    "id": 7750,
    "traditional": "開口",
    "simplified": "开口",
    "reading": "kāi kǒu",
    "meaning": "uncork, open one's mouth, sharpen knife, vent, put the first edge on a knife, broach, speak_up, laugh out loudly, breach a dam, speak, start to talk",
    "grade": 7
  },
  {
    "id": 7751,
    "traditional": "開闊",
    "simplified": "开阔",
    "reading": "kāikuò",
    "meaning": "wide, open (spaces), to open up",
    "grade": 7
  },
  {
    "id": 7752,
    "traditional": "開朗",
    "simplified": "开朗",
    "reading": "kāilǎng",
    "meaning": "clear up (of weather)",
    "grade": 7
  },
  {
    "id": 7753,
    "traditional": "開辟",
    "simplified": "开辟",
    "reading": "kāipì",
    "meaning": "to open up, to set up, to establish",
    "grade": 7
  },
  {
    "id": 7754,
    "traditional": "開啟",
    "simplified": "开启",
    "reading": "kāiqǐ",
    "meaning": "open",
    "grade": 7
  },
  {
    "id": 7755,
    "traditional": "開槍",
    "simplified": "开枪",
    "reading": "kāi qiāng",
    "meaning": "to open fire, to shoot a gun",
    "grade": 7
  },
  {
    "id": 7756,
    "traditional": "開天辟地",
    "simplified": "开天辟地",
    "reading": "kāitiān-pìdì",
    "meaning": "to split heaven and earth apart (idiom), refers to the Pangu 盤古|盘古[Pan2 gu3] creation myth",
    "grade": 7
  },
  {
    "id": 7757,
    "traditional": "開拓",
    "simplified": "开拓",
    "reading": "kāituò",
    "meaning": "open up, develop, enlarge/expand (territory/etc.)",
    "grade": 7
  },
  {
    "id": 7758,
    "traditional": "開銷",
    "simplified": "开销",
    "reading": "kāixiao",
    "meaning": "disbursal, expense, spending, outlay, disbursement, expense account",
    "grade": 7
  },
  {
    "id": 7759,
    "traditional": "開張",
    "simplified": "开张",
    "reading": "kāi zhāng",
    "meaning": "to open a business, first transaction of a business day",
    "grade": 7
  },
  {
    "id": 7760,
    "traditional": "開支",
    "simplified": "开支",
    "reading": "kāizhī",
    "meaning": "expenditures, expenses, CL:筆|笔[bi3], 項|项[xiang4], to spend money, (coll.) to pay wages",
    "grade": 7
  },
  {
    "id": 7761,
    "traditional": "凱歌",
    "simplified": "凯歌",
    "reading": "kǎigē",
    "meaning": "triumphal hymn, victory song, paean",
    "grade": 7
  },
  {
    "id": 7762,
    "traditional": "楷模",
    "simplified": "楷模",
    "reading": "kǎimó",
    "meaning": "model, example",
    "grade": 7
  },
  {
    "id": 7763,
    "traditional": "刊登",
    "simplified": "刊登",
    "reading": "kāndēng",
    "meaning": "publish in periodical",
    "grade": 7
  },
  {
    "id": 7764,
    "traditional": "刊物",
    "simplified": "刊物",
    "reading": "kānwù",
    "meaning": "publication, periodical",
    "grade": 7
  },
  {
    "id": 7765,
    "traditional": "勘探",
    "simplified": "勘探",
    "reading": "kāntàn",
    "meaning": "to explore, to survey, to prospect (for oil etc), prospecting",
    "grade": 7
  },
  {
    "id": 7766,
    "traditional": "看護",
    "simplified": "看护",
    "reading": "kānhù",
    "meaning": "to nurse, to look after, to watch over, (old) hospital nurse",
    "grade": 7
  },
  {
    "id": 7767,
    "traditional": "堪稱",
    "simplified": "堪称",
    "reading": "kānchēng",
    "meaning": "may be rated as, can be rated as...",
    "grade": 7
  },
  {
    "id": 7768,
    "traditional": "砍",
    "simplified": "砍",
    "reading": "kǎn",
    "meaning": "ax, hack, cut, shear, hew, chop_down, whack, carbonado, throw sth. at, hag, fall, chop",
    "grade": 7
  },
  {
    "id": 7769,
    "traditional": "侃大山",
    "simplified": "侃大山",
    "reading": "kǎn dàshān",
    "meaning": "to chatter idly, to gossip, to boast or brag",
    "grade": 7
  },
  {
    "id": 7770,
    "traditional": "看得出",
    "simplified": "看得出",
    "reading": "kàndechū",
    "meaning": "Look",
    "grade": 7
  },
  {
    "id": 7771,
    "traditional": "看似",
    "simplified": "看似",
    "reading": "kànsì",
    "meaning": "looks like",
    "grade": 7
  },
  {
    "id": 7772,
    "traditional": "看熱鬧",
    "simplified": "看热闹",
    "reading": "kàn rènao",
    "meaning": "to enjoy watching a bustling scene, to go where the crowds are",
    "grade": 7
  },
  {
    "id": 7773,
    "traditional": "看臺",
    "simplified": "看台",
    "reading": "kàntái",
    "meaning": "terrace, spectator's grandstand, viewing platform",
    "grade": 7
  },
  {
    "id": 7774,
    "traditional": "看樣子",
    "simplified": "看样子",
    "reading": "kàn yàngzi",
    "meaning": "it seems, it looks as if",
    "grade": 7
  },
  {
    "id": 7775,
    "traditional": "看中",
    "simplified": "看中",
    "reading": "kàn zhòng",
    "meaning": "to have a preference for, to fancy, to choose after consideration, to settle on",
    "grade": 7
  },
  {
    "id": 7776,
    "traditional": "慷慨",
    "simplified": "慷慨",
    "reading": "kāngkǎi",
    "meaning": "vehement, fervent, generous, giving, liberal",
    "grade": 7
  },
  {
    "id": 7777,
    "traditional": "看重",
    "simplified": "看重",
    "reading": "kàn zhòng",
    "meaning": "to regard as important, to care about",
    "grade": 7
  },
  {
    "id": 7778,
    "traditional": "扛",
    "simplified": "扛",
    "reading": "káng",
    "meaning": "carry on the shoulder, carry on shoulder, carry together, lift with both hands, shoulder",
    "grade": 7
  },
  {
    "id": 7779,
    "traditional": "抗衡",
    "simplified": "抗衡",
    "reading": "kànghéng",
    "meaning": "contend against",
    "grade": 7
  },
  {
    "id": 7780,
    "traditional": "抗拒",
    "simplified": "抗拒",
    "reading": "kàngjù",
    "meaning": "resist, defy",
    "grade": 7
  },
  {
    "id": 7781,
    "traditional": "抗爭",
    "simplified": "抗争",
    "reading": "kàngzhēng",
    "meaning": "resistance, struggle, resist, contend, make a stand against, make stand against, oppose",
    "grade": 7
  },
  {
    "id": 7782,
    "traditional": "抗生素",
    "simplified": "抗生素",
    "reading": "kàngshēngsù",
    "meaning": "antibiotic",
    "grade": 7
  },
  {
    "id": 7783,
    "traditional": "考量",
    "simplified": "考量",
    "reading": "kǎoliang",
    "meaning": "consider, think",
    "grade": 7
  },
  {
    "id": 7784,
    "traditional": "烤",
    "simplified": "烤",
    "reading": "kǎo",
    "meaning": "oven broil, warm (hands, bake, feet) near fire, torrefy, roast, scallop, warm (hands/feet) near fire, parch, crisp, grill, warm near fire, broil, toast",
    "grade": 7
  },
  {
    "id": 7785,
    "traditional": "靠攏",
    "simplified": "靠拢",
    "reading": "kàolǒng",
    "meaning": "to draw close to",
    "grade": 7
  },
  {
    "id": 7786,
    "traditional": "苛刻",
    "simplified": "苛刻",
    "reading": "kēkè",
    "meaning": "harsh, severe, demanding",
    "grade": 7
  },
  {
    "id": 7787,
    "traditional": "科幻",
    "simplified": "科幻",
    "reading": "kēhuàn",
    "meaning": "science fiction, abbr. for 科學幻想|科学幻想[ke1 xue2 huan4 xiang3]",
    "grade": 7
  },
  {
    "id": 7788,
    "traditional": "科目",
    "simplified": "科目",
    "reading": "kēmù",
    "meaning": "discipline, headings in account book, school subject, school course, school subject/course, subject, category of subjects, course",
    "grade": 7
  },
  {
    "id": 7789,
    "traditional": "科普",
    "simplified": "科普",
    "reading": "kēpǔ",
    "meaning": "popular science, popularization of science, abbr. of 科學普及|科学普及",
    "grade": 7
  },
  {
    "id": 7790,
    "traditional": "磕",
    "simplified": "磕",
    "reading": "kē",
    "meaning": "to tap, to knock (against sth hard), to knock (mud from boots, ashes from a pipe etc), variant of 嗑[ke4]",
    "grade": 7
  },
  {
    "id": 7791,
    "traditional": "殼",
    "simplified": "壳",
    "reading": "ké",
    "meaning": "plate, crust, shell, case, test, theca, hard surface, exuviae, eggshell, hull, rind, casing, cod, valve, scale, housing, husk, shuck",
    "grade": 7
  },
  {
    "id": 7792,
    "traditional": "咳嗽",
    "simplified": "咳嗽",
    "reading": "késou",
    "meaning": "to cough, CL:陣|阵[zhen4]",
    "grade": 7
  },
  {
    "id": 7793,
    "traditional": "可悲",
    "simplified": "可悲",
    "reading": "kěbēi",
    "meaning": "lamentable",
    "grade": 7
  },
  {
    "id": 7794,
    "traditional": "可不是",
    "simplified": "可不是",
    "reading": "kěbushì",
    "meaning": "that's just the way it is, exactly!",
    "grade": 7
  },
  {
    "id": 7795,
    "traditional": "可乘之機",
    "simplified": "可乘之机",
    "reading": "kěchéngzhījī",
    "meaning": "Can",
    "grade": 7
  },
  {
    "id": 7796,
    "traditional": "可恥",
    "simplified": "可耻",
    "reading": "kěchǐ",
    "meaning": "shameful, disgraceful, ignominious",
    "grade": 7
  },
  {
    "id": 7797,
    "traditional": "可歌可泣",
    "simplified": "可歌可泣",
    "reading": "kěgē-kěqì",
    "meaning": "lit. you can sing or you can cry (idiom), fig. deeply moving, happy and sad, inspiring and tragic",
    "grade": 7
  },
  {
    "id": 7798,
    "traditional": "可觀",
    "simplified": "可观",
    "reading": "kěguān",
    "meaning": "goodly, substantial",
    "grade": 7
  },
  {
    "id": 7799,
    "traditional": "可貴",
    "simplified": "可贵",
    "reading": "kěguì",
    "meaning": "valuable, praiseworthy",
    "grade": 7
  },
  {
    "id": 7800,
    "traditional": "可口",
    "simplified": "可口",
    "reading": "kěkǒu",
    "meaning": "tasty, to taste good",
    "grade": 7
  },
  {
    "id": 7801,
    "traditional": "可謂",
    "simplified": "可谓",
    "reading": "kěwèi",
    "meaning": "it could even be said",
    "grade": 7
  },
  {
    "id": 7802,
    "traditional": "可惡",
    "simplified": "可恶",
    "reading": "kěwù",
    "meaning": "hateful, abominable",
    "grade": 7
  },
  {
    "id": 7803,
    "traditional": "可想而知",
    "simplified": "可想而知",
    "reading": "kěxiǎngérzhī",
    "meaning": "it is obvious that..., as one can well imagine...",
    "grade": 7
  },
  {
    "id": 7804,
    "traditional": "可笑",
    "simplified": "可笑",
    "reading": "kěxiào",
    "meaning": "funny, ridiculous",
    "grade": 7
  },
  {
    "id": 7805,
    "traditional": "可信",
    "simplified": "可信",
    "reading": "kěxìn",
    "meaning": "trustworthy",
    "grade": 7
  },
  {
    "id": 7806,
    "traditional": "可行",
    "simplified": "可行",
    "reading": "kěxíng",
    "meaning": "feasible",
    "grade": 7
  },
  {
    "id": 7807,
    "traditional": "可疑",
    "simplified": "可疑",
    "reading": "kěyí",
    "meaning": "suspicious, dubious",
    "grade": 7
  },
  {
    "id": 7808,
    "traditional": "克隆",
    "simplified": "克隆",
    "reading": "kèlóng",
    "meaning": "clone (loanword)",
    "grade": 7
  },
  {
    "id": 7809,
    "traditional": "克制",
    "simplified": "克制",
    "reading": "kèzhì",
    "meaning": "to restrain, to control, restraint, self-control",
    "grade": 7
  },
  {
    "id": 7810,
    "traditional": "刻意",
    "simplified": "刻意",
    "reading": "kèyì",
    "meaning": "fastidiously, sedulously, conscious, purposely, painstakingly",
    "grade": 7
  },
  {
    "id": 7811,
    "traditional": "刻苦",
    "simplified": "刻苦",
    "reading": "kèkǔ",
    "meaning": "hardworking, assiduous",
    "grade": 7
  },
  {
    "id": 7812,
    "traditional": "客房",
    "simplified": "客房",
    "reading": "kèfáng",
    "meaning": "guest room",
    "grade": 7
  },
  {
    "id": 7813,
    "traditional": "刻舟求劍",
    "simplified": "刻舟求剑",
    "reading": "kèzhōu-qiújiàn",
    "meaning": "lit. a notch on the side of a boat to locate a sword dropped overboard (idiom), fig. an action made pointless by changed circumstances",
    "grade": 7
  },
  {
    "id": 7814,
    "traditional": "客機",
    "simplified": "客机",
    "reading": "kèjī",
    "meaning": "passenger plane",
    "grade": 7
  },
  {
    "id": 7815,
    "traditional": "客流",
    "simplified": "客流",
    "reading": "kèliú",
    "meaning": "Passenger flow",
    "grade": 7
  },
  {
    "id": 7816,
    "traditional": "客運",
    "simplified": "客运",
    "reading": "kèyùn",
    "meaning": "passenger traffic, (Tw) intercity bus",
    "grade": 7
  },
  {
    "id": 7817,
    "traditional": "懇求",
    "simplified": "恳求",
    "reading": "kěnqiú",
    "meaning": "to beg, to beseech, to entreat, entreaty",
    "grade": 7
  },
  {
    "id": 7818,
    "traditional": "啃",
    "simplified": "啃",
    "reading": "kěn",
    "meaning": "to gnaw, to nibble, to bite",
    "grade": 7
  },
  {
    "id": 7819,
    "traditional": "坑",
    "simplified": "坑",
    "reading": "kēng",
    "meaning": "variant of 坑[keng1], pit, hole",
    "grade": 7
  },
  {
    "id": 7820,
    "traditional": "空難",
    "simplified": "空难",
    "reading": "kōngnàn",
    "meaning": "air crash, aviation accident or incident",
    "grade": 7
  },
  {
    "id": 7821,
    "traditional": "空蕩蕩",
    "simplified": "空荡荡",
    "reading": "kōngdàngdàng",
    "meaning": "absolutely empty (space), complete vacuum",
    "grade": 7
  },
  {
    "id": 7822,
    "traditional": "空前",
    "simplified": "空前",
    "reading": "kōngqián",
    "meaning": "unprecedented",
    "grade": 7
  },
  {
    "id": 7823,
    "traditional": "空想",
    "simplified": "空想",
    "reading": "kōngxiǎng",
    "meaning": "daydream, fantasy, to fantasize",
    "grade": 7
  },
  {
    "id": 7824,
    "traditional": "空虛",
    "simplified": "空虚",
    "reading": "kōngxū",
    "meaning": "hollow, emptiness, meaningless",
    "grade": 7
  },
  {
    "id": 7825,
    "traditional": "恐怖",
    "simplified": "恐怖",
    "reading": "kǒngbù",
    "meaning": "fearful, horrible",
    "grade": 7
  },
  {
    "id": 7826,
    "traditional": "恐嚇",
    "simplified": "恐吓",
    "reading": "kǒnghè",
    "meaning": "threaten, intimidate",
    "grade": 7
  },
  {
    "id": 7827,
    "traditional": "恐慌",
    "simplified": "恐慌",
    "reading": "kǒnghuāng",
    "meaning": "panic",
    "grade": 7
  },
  {
    "id": 7828,
    "traditional": "恐懼",
    "simplified": "恐惧",
    "reading": "kǒngjù",
    "meaning": "fear, dread",
    "grade": 7
  },
  {
    "id": 7829,
    "traditional": "恐龍",
    "simplified": "恐龙",
    "reading": "kǒnglóng",
    "meaning": "carnosaur, tyrannosaurus, Titanosaurus, dinosaur, dinosaurian",
    "grade": 7
  },
  {
    "id": 7830,
    "traditional": "空白",
    "simplified": "空白",
    "reading": "kòngbái",
    "meaning": "blank space",
    "grade": 7
  },
  {
    "id": 7831,
    "traditional": "空地",
    "simplified": "空地",
    "reading": "kōng-dì",
    "meaning": "gap, area, open, vacant lot, clear, open ground/space, vacancy, open ground, building site, open space, space, frontage, sandlot, clearing, opening",
    "grade": 7
  },
  {
    "id": 7832,
    "traditional": "空隙",
    "simplified": "空隙",
    "reading": "kòngxì",
    "meaning": "crack, gap between two objects, gap in time between two events",
    "grade": 7
  },
  {
    "id": 7833,
    "traditional": "控告",
    "simplified": "控告",
    "reading": "kònggào",
    "meaning": "charge, accuse",
    "grade": 7
  },
  {
    "id": 7834,
    "traditional": "摳",
    "simplified": "抠",
    "reading": "kōu",
    "meaning": "to dig out, to pick out (with one's fingers), to carve, to cut, to study meticulously, to lift one's clothes, stingy, miserly",
    "grade": 7
  },
  {
    "id": 7835,
    "traditional": "口碑",
    "simplified": "口碑",
    "reading": "kǒubēi",
    "meaning": "public praise, public reputation, commonly held opinions, current idiom",
    "grade": 7
  },
  {
    "id": 7836,
    "traditional": "口才",
    "simplified": "口才",
    "reading": "kǒucái",
    "meaning": "eloquence",
    "grade": 7
  },
  {
    "id": 7837,
    "traditional": "口吃",
    "simplified": "口吃",
    "reading": "kǒuchī",
    "meaning": "to stammer, to stutter, also pr. [kou3 ji2]",
    "grade": 7
  },
  {
    "id": 7838,
    "traditional": "口感",
    "simplified": "口感",
    "reading": "kǒugǎn",
    "meaning": "taste, texture (of food), how food feels in the mouth",
    "grade": 7
  },
  {
    "id": 7839,
    "traditional": "口徑",
    "simplified": "口径",
    "reading": "kǒujìng",
    "meaning": "caliber, diameter of opening",
    "grade": 7
  },
  {
    "id": 7840,
    "traditional": "口令",
    "simplified": "口令",
    "reading": "kǒulìng",
    "meaning": "oral command, a word of command (used in drilling troops or gymnasts), password (used by sentry)",
    "grade": 7
  },
  {
    "id": 7841,
    "traditional": "口腔",
    "simplified": "口腔",
    "reading": "kǒuqiāng",
    "meaning": "oral cavity",
    "grade": 7
  },
  {
    "id": 7842,
    "traditional": "口氣",
    "simplified": "口气",
    "reading": "kǒuqi",
    "meaning": "tone, note, manner of speaking, tone of voice, implication",
    "grade": 7
  },
  {
    "id": 7843,
    "traditional": "口哨",
    "simplified": "口哨",
    "reading": "kǒushào",
    "meaning": "whistle",
    "grade": 7
  },
  {
    "id": 7844,
    "traditional": "口水",
    "simplified": "口水",
    "reading": "kǒushuǐ",
    "meaning": "spit, slobber, drivel, spittle, saliva, slaver, drool, dribble",
    "grade": 7
  },
  {
    "id": 7845,
    "traditional": "口頭",
    "simplified": "口头",
    "reading": "kǒutou",
    "meaning": "oral, verbal",
    "grade": 7
  },
  {
    "id": 7846,
    "traditional": "口味",
    "simplified": "口味",
    "reading": "kǒuwèi",
    "meaning": "a person's taste, flavor of food",
    "grade": 7
  },
  {
    "id": 7847,
    "traditional": "口香糖",
    "simplified": "口香糖",
    "reading": "kǒuxiāngtáng",
    "meaning": "chewing gum",
    "grade": 7
  },
  {
    "id": 7848,
    "traditional": "口音",
    "simplified": "口音",
    "reading": "kǒuyīn",
    "meaning": "oral speech sounds (linguistics), voice, accent",
    "grade": 7
  },
  {
    "id": 7849,
    "traditional": "口罩",
    "simplified": "口罩",
    "reading": "kǒuzhào",
    "meaning": "mask (surgical etc)",
    "grade": 7
  },
  {
    "id": 7850,
    "traditional": "口子",
    "simplified": "口子",
    "reading": "kǒuzi",
    "meaning": "hole, opening, cut, gap, gash, my husband or wife, classifier for people (used for indicating the number of people in a family etc), precedent",
    "grade": 7
  },
  {
    "id": 7851,
    "traditional": "扣除",
    "simplified": "扣除",
    "reading": "kòuchú",
    "meaning": "to deduct",
    "grade": 7
  },
  {
    "id": 7852,
    "traditional": "扣人心弦",
    "simplified": "扣人心弦",
    "reading": "kòurénxīnxián",
    "meaning": "to excite, to thrill, exciting, thrilling, cliff-hanging",
    "grade": 7
  },
  {
    "id": 7853,
    "traditional": "扣留",
    "simplified": "扣留",
    "reading": "kòuliú",
    "meaning": "to detain, to arrest, to hold, to confiscate",
    "grade": 7
  },
  {
    "id": 7854,
    "traditional": "扣押",
    "simplified": "扣押",
    "reading": "kòuyā",
    "meaning": "to detain, to hold in custody, to distrain, to seize property",
    "grade": 7
  },
  {
    "id": 7855,
    "traditional": "枯燥",
    "simplified": "枯燥",
    "reading": "kūzào",
    "meaning": "dry and dull, uninteresting, dry-as-dust",
    "grade": 7
  },
  {
    "id": 7856,
    "traditional": "哭泣",
    "simplified": "哭泣",
    "reading": "kūqì",
    "meaning": "to weep",
    "grade": 7
  },
  {
    "id": 7857,
    "traditional": "哭笑不得",
    "simplified": "哭笑不得",
    "reading": "kūxiào-bùdé",
    "meaning": "lit. not to know whether to laugh or cry (idiom), both funny and extremely embarrassing, between laughter and tears",
    "grade": 7
  },
  {
    "id": 7858,
    "traditional": "窟窿",
    "simplified": "窟窿",
    "reading": "kūlong",
    "meaning": "hole, pocket, cavity, loophole, debt",
    "grade": 7
  },
  {
    "id": 7859,
    "traditional": "苦力",
    "simplified": "苦力",
    "reading": "kǔlì",
    "meaning": "bitter work, hard toil, (loanword) coolie, unskilled Chinese laborer in colonial times",
    "grade": 7
  },
  {
    "id": 7860,
    "traditional": "苦練",
    "simplified": "苦练",
    "reading": "kǔliàn",
    "meaning": "to train hard, to practice diligently, hard work, blood, sweat, and tears",
    "grade": 7
  },
  {
    "id": 7861,
    "traditional": "苦難",
    "simplified": "苦难",
    "reading": "kǔnàn",
    "meaning": "suffering",
    "grade": 7
  },
  {
    "id": 7862,
    "traditional": "苦笑",
    "simplified": "苦笑",
    "reading": "kǔxiào",
    "meaning": "to force a smile, a bitter laugh",
    "grade": 7
  },
  {
    "id": 7863,
    "traditional": "苦惱",
    "simplified": "苦恼",
    "reading": "kǔnǎo",
    "meaning": "vexed, worried",
    "grade": 7
  },
  {
    "id": 7864,
    "traditional": "苦心",
    "simplified": "苦心",
    "reading": "kǔxīn",
    "meaning": "painstaking effort, to take a lot of trouble, laborious at pains",
    "grade": 7
  },
  {
    "id": 7865,
    "traditional": "酷似",
    "simplified": "酷似",
    "reading": "kùsì",
    "meaning": "to strikingly resemble",
    "grade": 7
  },
  {
    "id": 7866,
    "traditional": "誇",
    "simplified": "夸",
    "reading": "kuā",
    "meaning": "to boast, to exaggerate, to praise",
    "grade": 7
  },
  {
    "id": 7867,
    "traditional": "誇大",
    "simplified": "夸大",
    "reading": "kuādà",
    "meaning": "to exaggerate",
    "grade": 7
  },
  {
    "id": 7868,
    "traditional": "誇獎",
    "simplified": "夸奖",
    "reading": "kuājiǎng",
    "meaning": "to praise, to applaud, to compliment",
    "grade": 7
  },
  {
    "id": 7869,
    "traditional": "誇誇其談",
    "simplified": "夸夸其谈",
    "reading": "kuākuā-qítán",
    "meaning": "to talk big, to sound off, bombastic, grandiloquent",
    "grade": 7
  },
  {
    "id": 7870,
    "traditional": "誇耀",
    "simplified": "夸耀",
    "reading": "kuāyào",
    "meaning": "to brag about, to flaunt",
    "grade": 7
  },
  {
    "id": 7871,
    "traditional": "誇張",
    "simplified": "夸张",
    "reading": "kuāzhāng",
    "meaning": "overpitch, overact, vapor, overpaint, overcolor, overstate, overplay, overdraw, overblow, hyperbolize, overcharge, stretch, amplify, enhance, aggrandize, ham, highfalutin, vaunt, magnify, boast, dilate, overdo, exaggerate",
    "grade": 7
  },
  {
    "id": 7872,
    "traditional": "垮",
    "simplified": "垮",
    "reading": "kuǎ",
    "meaning": "to collapse, to break or wear down, to defeat",
    "grade": 7
  },
  {
    "id": 7873,
    "traditional": "挎",
    "simplified": "挎",
    "reading": "kuà",
    "meaning": "to carry (esp. slung over the arm, shoulder or side)",
    "grade": 7
  },
  {
    "id": 7874,
    "traditional": "跨國",
    "simplified": "跨国",
    "reading": "kuàguó",
    "meaning": "transnationa, transnational, multinational",
    "grade": 7
  },
  {
    "id": 7875,
    "traditional": "跨越",
    "simplified": "跨越",
    "reading": "kuàyuè",
    "meaning": "leap over, bestride, straddle, overstretch, span, stride, soar, spraddle, cut across, stride across",
    "grade": 7
  },
  {
    "id": 7876,
    "traditional": "快捷",
    "simplified": "快捷",
    "reading": "kuàijié",
    "meaning": "quick, fast, nimble, agile, (computer) shortcut",
    "grade": 7
  },
  {
    "id": 7877,
    "traditional": "寬敞",
    "simplified": "宽敞",
    "reading": "kuānchang",
    "meaning": "spacious, wide",
    "grade": 7
  },
  {
    "id": 7878,
    "traditional": "寬泛",
    "simplified": "宽泛",
    "reading": "kuānfàn",
    "meaning": "wide-ranging",
    "grade": 7
  },
  {
    "id": 7879,
    "traditional": "寬厚",
    "simplified": "宽厚",
    "reading": "kuānhòu",
    "meaning": "tolerant, generous, magnanimous, thick and broad (build), thick and deep (voice)",
    "grade": 7
  },
  {
    "id": 7880,
    "traditional": "寬容",
    "simplified": "宽容",
    "reading": "kuānróng",
    "meaning": "lenient, tolerant, indulgent, charitable, to forgive",
    "grade": 7
  },
  {
    "id": 7881,
    "traditional": "寬鬆",
    "simplified": "宽松",
    "reading": "kuānsong",
    "meaning": "to relax (policy), relaxed",
    "grade": 7
  },
  {
    "id": 7882,
    "traditional": "款式",
    "simplified": "款式",
    "reading": "kuǎnshì",
    "meaning": "pattern, style, design, CL:種|种[zhong3], elegant, elegance, good taste",
    "grade": 7
  },
  {
    "id": 7883,
    "traditional": "寬恕",
    "simplified": "宽恕",
    "reading": "kuānshù",
    "meaning": "to forgive, forgiveness",
    "grade": 7
  },
  {
    "id": 7884,
    "traditional": "款項",
    "simplified": "款项",
    "reading": "kuǎnxiàng",
    "meaning": "a sum of money, fund",
    "grade": 7
  },
  {
    "id": 7885,
    "traditional": "筐",
    "simplified": "筐",
    "reading": "kuāng",
    "meaning": "basket, CL:隻|只[zhi1]",
    "grade": 7
  },
  {
    "id": 7886,
    "traditional": "狂歡",
    "simplified": "狂欢",
    "reading": "kuánghuān",
    "meaning": "party, carousal, hilarity, merriment, whoopee, to carouse",
    "grade": 7
  },
  {
    "id": 7887,
    "traditional": "狂歡節",
    "simplified": "狂欢节",
    "reading": "Kuánghuānjié",
    "meaning": "carnival",
    "grade": 7
  },
  {
    "id": 7888,
    "traditional": "狂熱",
    "simplified": "狂热",
    "reading": "kuángrè",
    "meaning": "zealotry, fanatical, feverish",
    "grade": 7
  },
  {
    "id": 7889,
    "traditional": "曠課",
    "simplified": "旷课",
    "reading": "kuàng kè",
    "meaning": "to play truant, to cut classes",
    "grade": 7
  },
  {
    "id": 7890,
    "traditional": "況且",
    "simplified": "况且",
    "reading": "kuàngqiě",
    "meaning": "conj.: moreover, besides, in addition",
    "grade": 7
  },
  {
    "id": 7891,
    "traditional": "礦藏",
    "simplified": "矿藏",
    "reading": "kuàngcáng",
    "meaning": "mineral resources",
    "grade": 7
  },
  {
    "id": 7892,
    "traditional": "框",
    "simplified": "框",
    "reading": "kuàng",
    "meaning": "frame (e.g. door frame), casing, fig. framework, template, to circle (i.e. draw a circle around sth), to frame, to restrict, Taiwan pr. [kuang1]",
    "grade": 7
  },
  {
    "id": 7893,
    "traditional": "框架",
    "simplified": "框架",
    "reading": "kuàngjià",
    "meaning": "frame, framework, fig. pattern, outline, organizing plan",
    "grade": 7
  },
  {
    "id": 7894,
    "traditional": "虧本",
    "simplified": "亏本",
    "reading": "kuī běn",
    "meaning": "to make a loss",
    "grade": 7
  },
  {
    "id": 7895,
    "traditional": "虧損",
    "simplified": "亏损",
    "reading": "kuīsǔn",
    "meaning": "deficit, (financial) loss",
    "grade": 7
  },
  {
    "id": 7896,
    "traditional": "昆蟲",
    "simplified": "昆虫",
    "reading": "kūnchóng",
    "meaning": "bug, creeper, hexapod, coreid, insect, dor, coreid bug",
    "grade": 7
  },
  {
    "id": 7897,
    "traditional": "捆",
    "simplified": "捆",
    "reading": "kǔn",
    "meaning": "a bunch, to tie together, bundle, variant of 捆[kun3]",
    "grade": 7
  },
  {
    "id": 7898,
    "traditional": "困惑",
    "simplified": "困惑",
    "reading": "kùnhuò",
    "meaning": "perplexed, puzzled",
    "grade": 7
  },
  {
    "id": 7899,
    "traditional": "困境",
    "simplified": "困境",
    "reading": "kùnjìng",
    "meaning": "hell, soup, muddle, hell on earth, kettle of fish, embarrassment, mess, straits, stymie, hot potato, jam, hole, swamp, howdo-you-do, dilemma, spot, inferno, corner, difficult position, adversity, scrape, lurch, the pits, mire, box, fix, hot_potato, hardship, squeeze, pickle, dire straits, extremity, bind, puzzledom, morass, difficulty, quagmire, predicament",
    "grade": 7
  },
  {
    "id": 7900,
    "traditional": "擴",
    "simplified": "扩",
    "reading": "kuò",
    "meaning": "enlarge",
    "grade": 7
  },
  {
    "id": 7901,
    "traditional": "擴建",
    "simplified": "扩建",
    "reading": "kuòjiàn",
    "meaning": "extend (factory/mine/etc.)",
    "grade": 7
  },
  {
    "id": 7902,
    "traditional": "擴散",
    "simplified": "扩散",
    "reading": "kuòsàn",
    "meaning": "to spread, to proliferate, to diffuse, spread, proliferation, diffusion",
    "grade": 7
  },
  {
    "id": 7903,
    "traditional": "擴張",
    "simplified": "扩张",
    "reading": "kuòzhāng",
    "meaning": "expand, enlarge, extend, dilate",
    "grade": 7
  },
  {
    "id": 7904,
    "traditional": "括弧",
    "simplified": "括弧",
    "reading": "kuòhú",
    "meaning": "parenthesis",
    "grade": 7
  },
  {
    "id": 7905,
    "traditional": "闊綽",
    "simplified": "阔绰",
    "reading": "kuòchuò",
    "meaning": "ostentatious, extravagant, liberal with money",
    "grade": 7
  },
  {
    "id": 7906,
    "traditional": "拉動",
    "simplified": "拉动",
    "reading": "lā dòng",
    "meaning": "Pull",
    "grade": 7
  },
  {
    "id": 7907,
    "traditional": "拉攏",
    "simplified": "拉拢",
    "reading": "lālong",
    "meaning": "to rope in, fig. to involve sb, to entice",
    "grade": 7
  },
  {
    "id": 7908,
    "traditional": "拉鎖",
    "simplified": "拉锁",
    "reading": "lāsuǒ",
    "meaning": "zipper",
    "grade": 7
  },
  {
    "id": 7909,
    "traditional": "啦啦隊",
    "simplified": "啦啦队",
    "reading": "lālāduì",
    "meaning": "support team, cheering squad, cheerleader",
    "grade": 7
  },
  {
    "id": 7910,
    "traditional": "喇叭",
    "simplified": "喇叭",
    "reading": "lǎba",
    "meaning": "horn (automobile etc), loudspeaker, brass wind instrument, trumpet, suona 鎖吶|锁呐[suo3 na4]",
    "grade": 7
  },
  {
    "id": 7911,
    "traditional": "臘月",
    "simplified": "腊月",
    "reading": "làyuè",
    "meaning": "twelfth lunar month",
    "grade": 7
  },
  {
    "id": 7912,
    "traditional": "蠟",
    "simplified": "蜡",
    "reading": "là",
    "meaning": "candle, wax",
    "grade": 7
  },
  {
    "id": 7913,
    "traditional": "蠟燭",
    "simplified": "蜡烛",
    "reading": "làzhú",
    "meaning": "candle",
    "grade": 7
  },
  {
    "id": 7914,
    "traditional": "辣椒",
    "simplified": "辣椒",
    "reading": "làjiāo",
    "meaning": "hot pepper, chili",
    "grade": 7
  },
  {
    "id": 7915,
    "traditional": "來賓",
    "simplified": "来宾",
    "reading": "láibīn",
    "meaning": "guest, visitant, invitee, visitor",
    "grade": 7
  },
  {
    "id": 7916,
    "traditional": "來電",
    "simplified": "来电",
    "reading": "láidiàn",
    "meaning": "incoming telegram or telephone call, your telegram, telephone call, or message, to send a telegram or make a telephone call here (i.e. to the speaker), to have instant attraction to sb, to have chemistry with sb, to come back (of electricity, after an outage)",
    "grade": 7
  },
  {
    "id": 7917,
    "traditional": "來訪",
    "simplified": "来访",
    "reading": "láifǎng",
    "meaning": "come to visit/call",
    "grade": 7
  },
  {
    "id": 7918,
    "traditional": "來回",
    "simplified": "来回",
    "reading": "láihuí",
    "meaning": "go to a place and come back, make a round trip, make a return journey, move to and fro, move back and forth, go to and fro",
    "grade": 7
  },
  {
    "id": 7919,
    "traditional": "來歷",
    "simplified": "来历",
    "reading": "láilì",
    "meaning": "history, antecedents, origin",
    "grade": 7
  },
  {
    "id": 7920,
    "traditional": "來臨",
    "simplified": "来临",
    "reading": "láilín",
    "meaning": "arrive, come, approach",
    "grade": 7
  },
  {
    "id": 7921,
    "traditional": "來龍去脈",
    "simplified": "来龙去脉",
    "reading": "láilóng-qùmài",
    "meaning": "the rise and fall of the terrain (idiom), (fig.) the whole sequence of events, causes and effects",
    "grade": 7
  },
  {
    "id": 7922,
    "traditional": "來年",
    "simplified": "来年",
    "reading": "láinián",
    "meaning": "next year, the coming year",
    "grade": 7
  },
  {
    "id": 7923,
    "traditional": "來源於",
    "simplified": "来源于",
    "reading": "láiyuányú",
    "meaning": "to originate in",
    "grade": 7
  },
  {
    "id": 7924,
    "traditional": "攔",
    "simplified": "拦",
    "reading": "lán",
    "meaning": "to block sb's path, to obstruct, to flag down (a taxi)",
    "grade": 7
  },
  {
    "id": 7925,
    "traditional": "欄",
    "simplified": "栏",
    "reading": "lán",
    "meaning": "fence, railing, hurdle, column or box (of text or other data)",
    "grade": 7
  },
  {
    "id": 7926,
    "traditional": "欄杆",
    "simplified": "栏杆",
    "reading": "lángān",
    "meaning": "railing, banister",
    "grade": 7
  },
  {
    "id": 7927,
    "traditional": "藍圖",
    "simplified": "蓝图",
    "reading": "lántú",
    "meaning": "pattern, blueprint, cyanotype, design, project outline",
    "grade": 7
  },
  {
    "id": 7928,
    "traditional": "攬",
    "simplified": "揽",
    "reading": "lǎn",
    "meaning": "to monopolize, to seize, to take into one's arms, to embrace, to fasten (with a rope etc), to take on (responsibility etc), to canvass",
    "grade": 7
  },
  {
    "id": 7929,
    "traditional": "纜車",
    "simplified": "缆车",
    "reading": "lǎnchē",
    "meaning": "cable car",
    "grade": 7
  },
  {
    "id": 7930,
    "traditional": "懶得",
    "simplified": "懒得",
    "reading": "lǎnde",
    "meaning": "not to feel like (doing sth), disinclined to",
    "grade": 7
  },
  {
    "id": 7931,
    "traditional": "懶惰",
    "simplified": "懒惰",
    "reading": "lǎnduò",
    "meaning": "idle, lazy",
    "grade": 7
  },
  {
    "id": 7932,
    "traditional": "濫用",
    "simplified": "滥用",
    "reading": "lànyòng",
    "meaning": "abuse, misuse",
    "grade": 7
  },
  {
    "id": 7933,
    "traditional": "狼",
    "simplified": "狼",
    "reading": "láng",
    "meaning": "wolf, greedy and cruel person",
    "grade": 7
  },
  {
    "id": 7934,
    "traditional": "狼狽",
    "simplified": "狼狈",
    "reading": "lángbèi",
    "meaning": "in a difficult situation, to cut a sorry figure, scoundrel! (derog.)",
    "grade": 7
  },
  {
    "id": 7935,
    "traditional": "朗誦",
    "simplified": "朗诵",
    "reading": "lǎngsòng",
    "meaning": "to read aloud with expression, to recite, to declaim",
    "grade": 7
  },
  {
    "id": 7936,
    "traditional": "浪",
    "simplified": "浪",
    "reading": "làng",
    "meaning": "wave, breaker, unrestrained, dissipated",
    "grade": 7
  },
  {
    "id": 7937,
    "traditional": "撈",
    "simplified": "捞",
    "reading": "lāo",
    "meaning": "to fish up, to dredge up",
    "grade": 7
  },
  {
    "id": 7938,
    "traditional": "勞動力",
    "simplified": "劳动力",
    "reading": "láodònglì",
    "meaning": "labor force, labor, capacity for physical labor, able-bodied person",
    "grade": 7
  },
  {
    "id": 7939,
    "traditional": "勞累",
    "simplified": "劳累",
    "reading": "láolèi",
    "meaning": "tired, exhausted, worn out, to toil",
    "grade": 7
  },
  {
    "id": 7940,
    "traditional": "勞務",
    "simplified": "劳务",
    "reading": "láowù",
    "meaning": "service (work done for money), services (as in 'goods and services')",
    "grade": 7
  },
  {
    "id": 7941,
    "traditional": "牢固",
    "simplified": "牢固",
    "reading": "láogù",
    "meaning": "firm, secure",
    "grade": 7
  },
  {
    "id": 7942,
    "traditional": "牢牢",
    "simplified": "牢牢",
    "reading": "láoláo",
    "meaning": "firmly, safely",
    "grade": 7
  },
  {
    "id": 7943,
    "traditional": "嘮叨",
    "simplified": "唠叨",
    "reading": "láodao",
    "meaning": "to prattle, to chatter away, to nag, garrulous, nagging",
    "grade": 7
  },
  {
    "id": 7944,
    "traditional": "牢記",
    "simplified": "牢记",
    "reading": "láojì",
    "meaning": "to keep in mind, to remember",
    "grade": 7
  },
  {
    "id": 7945,
    "traditional": "老伴兒",
    "simplified": "老伴儿",
    "reading": "lǎobànr5",
    "meaning": "(of an elderly couple) husband or wife, erhua variant of 老伴[lao3 ban4]",
    "grade": 7
  },
  {
    "id": 7946,
    "traditional": "老大",
    "simplified": "老大",
    "reading": "lǎodà",
    "meaning": "leader, eldest child (in a family), guvnor, old, governor, boss, eldest child, master of a sailing vessel",
    "grade": 7
  },
  {
    "id": 7947,
    "traditional": "老漢",
    "simplified": "老汉",
    "reading": "lǎohàn",
    "meaning": "old man, I (an old man referring to himself)",
    "grade": 7
  },
  {
    "id": 7948,
    "traditional": "老化",
    "simplified": "老化",
    "reading": "lǎohuà",
    "meaning": "to age (of person or object), becoming old",
    "grade": 7
  },
  {
    "id": 7949,
    "traditional": "老人家",
    "simplified": "老人家",
    "reading": "lǎorenjia",
    "meaning": "parent, parents, a respectful form of address for an old person, old person",
    "grade": 7
  },
  {
    "id": 7950,
    "traditional": "老實說",
    "simplified": "老实说",
    "reading": "lǎoshíshuō",
    "meaning": "honestly",
    "grade": 7
  },
  {
    "id": 7951,
    "traditional": "老遠",
    "simplified": "老远",
    "reading": "lǎoyuǎn",
    "meaning": "very far away",
    "grade": 7
  },
  {
    "id": 7952,
    "traditional": "老字號",
    "simplified": "老字号",
    "reading": "lǎozìhao",
    "meaning": "shop, firm, or brand of merchandise with a long-established reputation",
    "grade": 7
  },
  {
    "id": 7953,
    "traditional": "姥姥",
    "simplified": "姥姥",
    "reading": "lǎolao",
    "meaning": "(coll.) mother's mother, maternal grandmother",
    "grade": 7
  },
  {
    "id": 7954,
    "traditional": "姥爺",
    "simplified": "姥爷",
    "reading": "lǎoye",
    "meaning": "maternal grandfather (dialectal)",
    "grade": 7
  },
  {
    "id": 7955,
    "traditional": "澇",
    "simplified": "涝",
    "reading": "lào",
    "meaning": "flooded",
    "grade": 7
  },
  {
    "id": 7956,
    "traditional": "樂意",
    "simplified": "乐意",
    "reading": "lèyì",
    "meaning": "to be willing to do sth, to be ready to do sth, to be happy to do sth, content, satisfied",
    "grade": 7
  },
  {
    "id": 7957,
    "traditional": "樂園",
    "simplified": "乐园",
    "reading": "lèyuán",
    "meaning": "Elysian Fields, paradise, Valhalla, Eden, playground, Elysium, fairyland",
    "grade": 7
  },
  {
    "id": 7958,
    "traditional": "勒",
    "simplified": "勒",
    "reading": "lēi",
    "meaning": "to rein in, to compel, to force, to carve, to engrave, (literary) to command, to lead, lux (unit of illumination), (literary) bridle, to strap tightly, to bind",
    "grade": 7
  },
  {
    "id": 7959,
    "traditional": "雷同",
    "simplified": "雷同",
    "reading": "léitóng",
    "meaning": "mirroring others, identical",
    "grade": 7
  },
  {
    "id": 7960,
    "traditional": "累積",
    "simplified": "累积",
    "reading": "lěijī",
    "meaning": "accumulate",
    "grade": 7
  },
  {
    "id": 7961,
    "traditional": "累計",
    "simplified": "累计",
    "reading": "lěijì",
    "meaning": "to accumulate, cumulative",
    "grade": 7
  },
  {
    "id": 7962,
    "traditional": "類別",
    "simplified": "类别",
    "reading": "lèibié",
    "meaning": "category, cubbyhole, categorization, race, kind, categorisation, reduction, persuasion, regimentation, rubric, classification, sort",
    "grade": 7
  },
  {
    "id": 7963,
    "traditional": "棱角",
    "simplified": "棱角",
    "reading": "léngjiǎo",
    "meaning": "edge and corner, protrusion, sharpness (of a protrusion), craggy, ridge corner",
    "grade": 7
  },
  {
    "id": 7964,
    "traditional": "冷淡",
    "simplified": "冷淡",
    "reading": "lěngdàn",
    "meaning": "cold, indifferent",
    "grade": 7
  },
  {
    "id": 7965,
    "traditional": "冷凍",
    "simplified": "冷冻",
    "reading": "lěngdòng",
    "meaning": "to freeze, to deep-freeze",
    "grade": 7
  },
  {
    "id": 7966,
    "traditional": "冷酷",
    "simplified": "冷酷",
    "reading": "lěngkù",
    "meaning": "grim, unfeeling, callous",
    "grade": 7
  },
  {
    "id": 7967,
    "traditional": "冷酷無情",
    "simplified": "冷酷无情",
    "reading": "lěngkù-wúqíng",
    "meaning": "cold-hearted, unfeeling, callous",
    "grade": 7
  },
  {
    "id": 7968,
    "traditional": "冷落",
    "simplified": "冷落",
    "reading": "lěngluò",
    "meaning": "desolate, unfrequented, to treat sb coldly, to snub, to cold shoulder",
    "grade": 7
  },
  {
    "id": 7969,
    "traditional": "冷門",
    "simplified": "冷门",
    "reading": "lěngmén",
    "meaning": "a neglected branch (of arts, science, sports etc), fig. a complete unknown who wins a competition",
    "grade": 7
  },
  {
    "id": 7970,
    "traditional": "冷漠",
    "simplified": "冷漠",
    "reading": "lěngmò",
    "meaning": "cold and detached, unconcerned, indifferent",
    "grade": 7
  },
  {
    "id": 7971,
    "traditional": "冷戰",
    "simplified": "冷战",
    "reading": "lěngzhan",
    "meaning": "cold war",
    "grade": 7
  },
  {
    "id": 7972,
    "traditional": "冷笑",
    "simplified": "冷笑",
    "reading": "lěngxiào",
    "meaning": "to sneer, to laugh grimly, grin of dissatisfaction (bitterness, helplessness, indignation etc), bitter, grim, sarcastic or angry smile",
    "grade": 7
  },
  {
    "id": 7973,
    "traditional": "離譜兒",
    "simplified": "离谱儿",
    "reading": "lí pǔr5",
    "meaning": "Scientific",
    "grade": 7
  },
  {
    "id": 7974,
    "traditional": "愣",
    "simplified": "愣",
    "reading": "lèng",
    "meaning": "to look distracted, to stare blankly, distracted, blank, (coll.) unexpectedly, rash, rashly",
    "grade": 7
  },
  {
    "id": 7975,
    "traditional": "離奇",
    "simplified": "离奇",
    "reading": "líqí",
    "meaning": "odd, bizarre",
    "grade": 7
  },
  {
    "id": 7976,
    "traditional": "離職",
    "simplified": "离职",
    "reading": "lízhí",
    "meaning": "to retire, to leave office, to quit a job",
    "grade": 7
  },
  {
    "id": 7977,
    "traditional": "黎明",
    "simplified": "黎明",
    "reading": "límíng",
    "meaning": "dawn, daybreak",
    "grade": 7
  },
  {
    "id": 7978,
    "traditional": "禮服",
    "simplified": "礼服",
    "reading": "lǐfú",
    "meaning": "ceremonial robe, plumage, trappings, ceremonial dress, tux, robe, full_dress, black tie, full dress, gown, ceremonial robe or dress, dress, ball gown, dinner jacket, vestment, ceremonial robe/dress, formal attire, tuxedo, full-dress",
    "grade": 7
  },
  {
    "id": 7979,
    "traditional": "禮品",
    "simplified": "礼品",
    "reading": "lǐpǐn",
    "meaning": "present, beneficence, gift",
    "grade": 7
  },
  {
    "id": 7980,
    "traditional": "禮儀",
    "simplified": "礼仪",
    "reading": "lǐyí",
    "meaning": "etiquette, ceremony",
    "grade": 7
  },
  {
    "id": 7981,
    "traditional": "里程碑",
    "simplified": "里程碑",
    "reading": "lǐchéngbēi",
    "meaning": "milestone",
    "grade": 7
  },
  {
    "id": 7982,
    "traditional": "理睬",
    "simplified": "理睬",
    "reading": "lǐcǎi",
    "meaning": "to heed, to pay attention to",
    "grade": 7
  },
  {
    "id": 7983,
    "traditional": "理會",
    "simplified": "理会",
    "reading": "lǐhuì",
    "meaning": "understand, comprehend, take notice of, pay attention to",
    "grade": 7
  },
  {
    "id": 7984,
    "traditional": "理科",
    "simplified": "理科",
    "reading": "lǐkē",
    "meaning": "the sciences (as opposed to the humanities 文科[wen2 ke1])",
    "grade": 7
  },
  {
    "id": 7985,
    "traditional": "理念",
    "simplified": "理念",
    "reading": "lǐniàn",
    "meaning": "sense, conception, idea",
    "grade": 7
  },
  {
    "id": 7986,
    "traditional": "理事",
    "simplified": "理事",
    "reading": "lǐshì",
    "meaning": "member of council",
    "grade": 7
  },
  {
    "id": 7987,
    "traditional": "理所當然",
    "simplified": "理所当然",
    "reading": "lǐsuǒdāngrán",
    "meaning": "of course, naturally",
    "grade": 7
  },
  {
    "id": 7988,
    "traditional": "理性",
    "simplified": "理性",
    "reading": "lǐxìng",
    "meaning": "reason",
    "grade": 7
  },
  {
    "id": 7989,
    "traditional": "理直氣壯",
    "simplified": "理直气壮",
    "reading": "lǐzhí-qìzhuàng",
    "meaning": "in the right and self-confident (idiom), bold and confident with justice on one's side, to have the courage of one's convictions, just and forceful",
    "grade": 7
  },
  {
    "id": 7990,
    "traditional": "力不從心",
    "simplified": "力不从心",
    "reading": "lìbùcóngxīn",
    "meaning": "less capable than desirable (idiom), not as strong as one would wish, the spirit is willing but the flesh is weak",
    "grade": 7
  },
  {
    "id": 7991,
    "traditional": "力求",
    "simplified": "力求",
    "reading": "lìqiú",
    "meaning": "strive for, make every effort to, do one's best to, strive to",
    "grade": 7
  },
  {
    "id": 7992,
    "traditional": "力度",
    "simplified": "力度",
    "reading": "lìdù",
    "meaning": "strength, vigor, efforts, (music) dynamics",
    "grade": 7
  },
  {
    "id": 7993,
    "traditional": "力所能及",
    "simplified": "力所能及",
    "reading": "lìsuǒnéngjí",
    "meaning": "as far as one's capabilities extend (idiom), to the best of one's ability, within one's powers",
    "grade": 7
  },
  {
    "id": 7994,
    "traditional": "力爭",
    "simplified": "力争",
    "reading": "lìzhēng",
    "meaning": "to work hard for, to do all one can, to contend strongly",
    "grade": 7
  },
  {
    "id": 7995,
    "traditional": "歷屆",
    "simplified": "历届",
    "reading": "lìjiè",
    "meaning": "all previous (meetings, sessions etc)",
    "grade": 7
  },
  {
    "id": 7996,
    "traditional": "歷程",
    "simplified": "历程",
    "reading": "lìchéng",
    "meaning": "appendage, kinetics, process, journey, course, outgrowth",
    "grade": 7
  },
  {
    "id": 7997,
    "traditional": "歷經",
    "simplified": "历经",
    "reading": "lìjīng",
    "meaning": "have experienced",
    "grade": 7
  },
  {
    "id": 7998,
    "traditional": "歷來",
    "simplified": "历来",
    "reading": "lìlái",
    "meaning": "always, throughout (a period of time), (of) all-time",
    "grade": 7
  },
  {
    "id": 7999,
    "traditional": "立方米",
    "simplified": "立方米",
    "reading": "lìfāngmǐ",
    "meaning": "cubic meter (unit of volume)",
    "grade": 7
  },
  {
    "id": 8000,
    "traditional": "立方",
    "simplified": "立方",
    "reading": "lìfāng",
    "meaning": "cube (math.), abbr. for 立方體|立方体[li4 fang1 ti3], abbr. for 立方米[li4 fang1 mi3]",
    "grade": 7
  },
  {
    "id": 8001,
    "traditional": "立功",
    "simplified": "立功",
    "reading": "lì gōng",
    "meaning": "Stand up",
    "grade": 7
  },
  {
    "id": 8002,
    "traditional": "歷時",
    "simplified": "历时",
    "reading": "lìshí",
    "meaning": "to last, to take (time), period, diachronic",
    "grade": 7
  },
  {
    "id": 8003,
    "traditional": "立交橋",
    "simplified": "立交桥",
    "reading": "lìjiāoqiáo",
    "meaning": "overpass, flyover",
    "grade": 7
  },
  {
    "id": 8004,
    "traditional": "立足",
    "simplified": "立足",
    "reading": "lì zú",
    "meaning": "to stand, to have a footing, to be established, to base oneself on",
    "grade": 7
  },
  {
    "id": 8005,
    "traditional": "立體",
    "simplified": "立体",
    "reading": "lìtǐ",
    "meaning": "three-dimensional, stereoscopic",
    "grade": 7
  },
  {
    "id": 8006,
    "traditional": "利率",
    "simplified": "利率",
    "reading": "lìlǜ",
    "meaning": "profit, interest rate, rate_of_interest, rate of interest",
    "grade": 7
  },
  {
    "id": 8007,
    "traditional": "勵志",
    "simplified": "励志",
    "reading": "lìzhì",
    "meaning": "to encourage, encouragement",
    "grade": 7
  },
  {
    "id": 8008,
    "traditional": "粒",
    "simplified": "粒",
    "reading": "lì",
    "meaning": "granule, grain, kernel, pellet, m.[general],",
    "grade": 7
  },
  {
    "id": 8009,
    "traditional": "利害",
    "simplified": "利害",
    "reading": "lìhai",
    "meaning": "pros and cons, advantages and disadvantages, gains and losses, terrible, formidable, serious, devastating, tough, capable, sharp, severe, fierce",
    "grade": 7
  },
  {
    "id": 8010,
    "traditional": "連綿",
    "simplified": "连绵",
    "reading": "liánmián",
    "meaning": "continuous, unbroken, uninterrupted, extending forever into the distance (of mountain range, river etc)",
    "grade": 7
  },
  {
    "id": 8011,
    "traditional": "連滾帶爬",
    "simplified": "连滚带爬",
    "reading": "liángǔndàipá",
    "meaning": "rolling and crawling, trying frantically to escape (idiom)",
    "grade": 7
  },
  {
    "id": 8012,
    "traditional": "利索",
    "simplified": "利索",
    "reading": "lìsuo",
    "meaning": "nimble",
    "grade": 7
  },
  {
    "id": 8013,
    "traditional": "連鎖",
    "simplified": "连锁",
    "reading": "liánsuǒ",
    "meaning": "to interlock, to be linked, chain (store etc)",
    "grade": 7
  },
  {
    "id": 8014,
    "traditional": "連任",
    "simplified": "连任",
    "reading": "lián rèn",
    "meaning": "to continue in (a political) office, to serve for another term of office",
    "grade": 7
  },
  {
    "id": 8015,
    "traditional": "連鎖店",
    "simplified": "连锁店",
    "reading": "liánsuǒdiàn",
    "meaning": "chain store",
    "grade": 7
  },
  {
    "id": 8016,
    "traditional": "憐惜",
    "simplified": "怜惜",
    "reading": "liánxī",
    "meaning": "to take pity on, to feel tenderness toward",
    "grade": 7
  },
  {
    "id": 8017,
    "traditional": "簾子",
    "simplified": "帘子",
    "reading": "liánzi",
    "meaning": "curtain",
    "grade": 7
  },
  {
    "id": 8018,
    "traditional": "連夜",
    "simplified": "连夜",
    "reading": "liányè",
    "meaning": "that very night, through the night, for several nights in a row",
    "grade": 7
  },
  {
    "id": 8019,
    "traditional": "蓮子",
    "simplified": "莲子",
    "reading": "liánzǐ",
    "meaning": "lotus seed",
    "grade": 7
  },
  {
    "id": 8020,
    "traditional": "聯邦",
    "simplified": "联邦",
    "reading": "liánbāng",
    "meaning": "federacy, union, federal, nation, confederation, commonwealth, federation, confederacy",
    "grade": 7
  },
  {
    "id": 8021,
    "traditional": "聯歡",
    "simplified": "联欢",
    "reading": "liánhuān",
    "meaning": "to have a get-together, celebration, party",
    "grade": 7
  },
  {
    "id": 8022,
    "traditional": "聯網",
    "simplified": "联网",
    "reading": "lián wǎng",
    "meaning": "network, cyber-",
    "grade": 7
  },
  {
    "id": 8023,
    "traditional": "廉價",
    "simplified": "廉价",
    "reading": "liánjià",
    "meaning": "cheaply-priced, low-cost",
    "grade": 7
  },
  {
    "id": 8024,
    "traditional": "廉正",
    "simplified": "廉正",
    "reading": "liánzhèng",
    "meaning": "integrity",
    "grade": 7
  },
  {
    "id": 8025,
    "traditional": "廉潔",
    "simplified": "廉洁",
    "reading": "liánjié",
    "meaning": "honest, not coercive, honesty, integrity, incorruptible",
    "grade": 7
  },
  {
    "id": 8026,
    "traditional": "廉政",
    "simplified": "廉政",
    "reading": "liánzhèng",
    "meaning": "honest or clean politics",
    "grade": 7
  },
  {
    "id": 8027,
    "traditional": "煉",
    "simplified": "炼",
    "reading": "liàn",
    "meaning": "variant of 鏈|链[lian4], chain, variant of 煉|炼[lian4]",
    "grade": 7
  },
  {
    "id": 8028,
    "traditional": "臉頰",
    "simplified": "脸颊",
    "reading": "liǎnjiá",
    "meaning": "cheeks",
    "grade": 7
  },
  {
    "id": 8029,
    "traditional": "戀戀不捨",
    "simplified": "恋恋不舍",
    "reading": "liànliàn-bùshě",
    "meaning": "reluctant to part",
    "grade": 7
  },
  {
    "id": 8030,
    "traditional": "良",
    "simplified": "良",
    "reading": "liáng",
    "meaning": "good, very, very much",
    "grade": 7
  },
  {
    "id": 8031,
    "traditional": "良心",
    "simplified": "良心",
    "reading": "liángxīn",
    "meaning": "sense of right and wrong, conscientiousness, conscience, moral sense, scruples",
    "grade": 7
  },
  {
    "id": 8032,
    "traditional": "良性",
    "simplified": "良性",
    "reading": "liángxìng",
    "meaning": "plus, benign, positive",
    "grade": 7
  },
  {
    "id": 8033,
    "traditional": "涼爽",
    "simplified": "凉爽",
    "reading": "liángshuǎng",
    "meaning": "cool and refreshing",
    "grade": 7
  },
  {
    "id": 8034,
    "traditional": "兩口子",
    "simplified": "两口子",
    "reading": "liǎngkǒuzi",
    "meaning": "husband and wife",
    "grade": 7
  },
  {
    "id": 8035,
    "traditional": "兩棲",
    "simplified": "两栖",
    "reading": "liǎngqī",
    "meaning": "amphibious, dual-talented, able to work in two different lines",
    "grade": 7
  },
  {
    "id": 8036,
    "traditional": "亮點",
    "simplified": "亮点",
    "reading": "liàngdiǎn",
    "meaning": "highlight, bright spot",
    "grade": 7
  },
  {
    "id": 8037,
    "traditional": "亮麗",
    "simplified": "亮丽",
    "reading": "liànglì",
    "meaning": "beautiful, remarkable, brilliant, splendid, smart",
    "grade": 7
  },
  {
    "id": 8038,
    "traditional": "亮相",
    "simplified": "亮相",
    "reading": "liàng xiàng",
    "meaning": "to strike a pose (Chinese opera), (fig.) to make a public appearance, to come out in public (revealing one's true personality, opinions etc), (of a product) to appear on the market or at a trade show etc",
    "grade": 7
  },
  {
    "id": 8039,
    "traditional": "遼闊",
    "simplified": "辽阔",
    "reading": "liáokuò",
    "meaning": "vast, extensive",
    "grade": 7
  },
  {
    "id": 8040,
    "traditional": "諒解",
    "simplified": "谅解",
    "reading": "liàngjiě",
    "meaning": "to understand, to make allowances for, understanding",
    "grade": 7
  },
  {
    "id": 8041,
    "traditional": "療法",
    "simplified": "疗法",
    "reading": "liáofǎ",
    "meaning": "therapy, cure, treatment, therapeutics",
    "grade": 7
  },
  {
    "id": 8042,
    "traditional": "療效",
    "simplified": "疗效",
    "reading": "liáoxiào",
    "meaning": "curative effect",
    "grade": 7
  },
  {
    "id": 8043,
    "traditional": "潦草",
    "simplified": "潦草",
    "reading": "liáocǎo",
    "meaning": "careless, slovenly, illegible (of handwriting)",
    "grade": 7
  },
  {
    "id": 8044,
    "traditional": "寥寥無幾",
    "simplified": "寥寥无几",
    "reading": "liáoliáo-wújǐ",
    "meaning": "just a very few (idiom), tiny number, not many at all, You count them on your fingers.",
    "grade": 7
  },
  {
    "id": 8045,
    "traditional": "了卻",
    "simplified": "了却",
    "reading": "liǎoquè",
    "meaning": "to resolve, to settle",
    "grade": 7
  },
  {
    "id": 8046,
    "traditional": "料到",
    "simplified": "料到",
    "reading": "liào dào",
    "meaning": "to foresee, to anticipate",
    "grade": 7
  },
  {
    "id": 8047,
    "traditional": "了結",
    "simplified": "了结",
    "reading": "liǎojié",
    "meaning": "to settle, to finish, to conclude, to wind up",
    "grade": 7
  },
  {
    "id": 8048,
    "traditional": "咧嘴",
    "simplified": "咧嘴",
    "reading": "liě zuǐ",
    "meaning": "to grin",
    "grade": 7
  },
  {
    "id": 8049,
    "traditional": "料理",
    "simplified": "料理",
    "reading": "liàolǐ",
    "meaning": "to arrange, to handle, to cook, cuisine, art of cooking",
    "grade": 7
  },
  {
    "id": 8050,
    "traditional": "列舉",
    "simplified": "列举",
    "reading": "lièjǔ",
    "meaning": "chronicle, cite item by item, specify, list, marshal, number, enumeration, article, particularize, rehearse, count, recite, enumerate, itemize",
    "grade": 7
  },
  {
    "id": 8051,
    "traditional": "劣勢",
    "simplified": "劣势",
    "reading": "lièshì",
    "meaning": "inferior, disadvantaged",
    "grade": 7
  },
  {
    "id": 8052,
    "traditional": "劣質",
    "simplified": "劣质",
    "reading": "lièzhì",
    "meaning": "shoddy, of poor quality",
    "grade": 7
  },
  {
    "id": 8053,
    "traditional": "獵犬",
    "simplified": "猎犬",
    "reading": "lièquǎn",
    "meaning": "hound, hunting dog",
    "grade": 7
  },
  {
    "id": 8054,
    "traditional": "獵人",
    "simplified": "猎人",
    "reading": "lièrén",
    "meaning": "shikaree, nimrod, yager, chasseur, chaser, jager, huntsman, jaeger, venerer, hunter",
    "grade": 7
  },
  {
    "id": 8055,
    "traditional": "烈士",
    "simplified": "烈士",
    "reading": "lièshì",
    "meaning": "martyr",
    "grade": 7
  },
  {
    "id": 8056,
    "traditional": "裂縫",
    "simplified": "裂缝",
    "reading": "lièfèng",
    "meaning": "crack, crevice, CL:道[dao4]",
    "grade": 7
  },
  {
    "id": 8057,
    "traditional": "裂痕",
    "simplified": "裂痕",
    "reading": "lièhén",
    "meaning": "crack, gap, split",
    "grade": 7
  },
  {
    "id": 8058,
    "traditional": "鄰國",
    "simplified": "邻国",
    "reading": "línguó",
    "meaning": "bordering country, neighbor country, neighboring countries, surrounding countries",
    "grade": 7
  },
  {
    "id": 8059,
    "traditional": "臨",
    "simplified": "临",
    "reading": "lín",
    "meaning": "facing",
    "grade": 7
  },
  {
    "id": 8060,
    "traditional": "臨街",
    "simplified": "临街",
    "reading": "línjiē",
    "meaning": "facing the street",
    "grade": 7
  },
  {
    "id": 8061,
    "traditional": "臨近",
    "simplified": "临近",
    "reading": "línjìn",
    "meaning": "close to, approaching",
    "grade": 7
  },
  {
    "id": 8062,
    "traditional": "臨床",
    "simplified": "临床",
    "reading": "línchuáng",
    "meaning": "clinical",
    "grade": 7
  },
  {
    "id": 8063,
    "traditional": "靈",
    "simplified": "灵",
    "reading": "líng",
    "meaning": "quick, alert, efficacious, effective, to come true, spirit, departed soul, coffin",
    "grade": 7
  },
  {
    "id": 8064,
    "traditional": "拎",
    "simplified": "拎",
    "reading": "līn",
    "meaning": "to lift, to carry in one's hand, Taiwan pr. [ling1]",
    "grade": 7
  },
  {
    "id": 8065,
    "traditional": "淋",
    "simplified": "淋",
    "reading": "lín",
    "meaning": "to sprinkle, to drip, to pour, to drench, to filter, to strain, to drain, gonorrhea, (TCM) strangury",
    "grade": 7
  },
  {
    "id": 8066,
    "traditional": "靈感",
    "simplified": "灵感",
    "reading": "línggǎn",
    "meaning": "motive, afflatus, rage, breathing, brainwave, muse, inspiration, brainchild",
    "grade": 7
  },
  {
    "id": 8067,
    "traditional": "靈魂",
    "simplified": "灵魂",
    "reading": "línghún",
    "meaning": "god, anima, Psyche, psyche, spirit, pneuma, ghost, manes, soul",
    "grade": 7
  },
  {
    "id": 8068,
    "traditional": "靈機一動",
    "simplified": "灵机一动",
    "reading": "língjī yī dòng",
    "meaning": "a bright idea suddenly occurs (idiom), to hit upon an inspiration, to be struck by a brainwave",
    "grade": 7
  },
  {
    "id": 8069,
    "traditional": "靈敏",
    "simplified": "灵敏",
    "reading": "língmǐn",
    "meaning": "smart, clever, sensitive, keen, quick, sharp",
    "grade": 7
  },
  {
    "id": 8070,
    "traditional": "靈巧",
    "simplified": "灵巧",
    "reading": "língqiǎo",
    "meaning": "deft, nimble, ingenious",
    "grade": 7
  },
  {
    "id": 8071,
    "traditional": "凌晨",
    "simplified": "凌晨",
    "reading": "língchén",
    "meaning": "very early in the morning, in the wee hours",
    "grade": 7
  },
  {
    "id": 8072,
    "traditional": "零花錢",
    "simplified": "零花钱",
    "reading": "línghuāqián",
    "meaning": "pocket money, allowance",
    "grade": 7
  },
  {
    "id": 8073,
    "traditional": "零件",
    "simplified": "零件",
    "reading": "língjiàn",
    "meaning": "section, part, components, spares, spare parts, parts, accessory",
    "grade": 7
  },
  {
    "id": 8074,
    "traditional": "零錢",
    "simplified": "零钱",
    "reading": "língqián",
    "meaning": "change (of money), small change, pocket money",
    "grade": 7
  },
  {
    "id": 8075,
    "traditional": "零售",
    "simplified": "零售",
    "reading": "língshòu",
    "meaning": "retail, (sell) retail",
    "grade": 7
  },
  {
    "id": 8076,
    "traditional": "靈通",
    "simplified": "灵通",
    "reading": "língtōng",
    "meaning": "fast and abundant (news), clever, effective",
    "grade": 7
  },
  {
    "id": 8077,
    "traditional": "領會",
    "simplified": "领会",
    "reading": "lǐnghuì",
    "meaning": "to understand, to comprehend, to grasp",
    "grade": 7
  },
  {
    "id": 8078,
    "traditional": "領軍",
    "simplified": "领军",
    "reading": "lǐng jūn",
    "meaning": "to lead troups, (fig.) to lead, leading",
    "grade": 7
  },
  {
    "id": 8079,
    "traditional": "領略",
    "simplified": "领略",
    "reading": "lǐnglüè",
    "meaning": "to have a taste of, to realize, to appreciate",
    "grade": 7
  },
  {
    "id": 8080,
    "traditional": "領事",
    "simplified": "领事",
    "reading": "lǐngshì",
    "meaning": "consul",
    "grade": 7
  },
  {
    "id": 8081,
    "traditional": "領事館",
    "simplified": "领事馆",
    "reading": "lǐngshìguǎn",
    "meaning": "consulate",
    "grade": 7
  },
  {
    "id": 8082,
    "traditional": "領隊",
    "simplified": "领队",
    "reading": "lǐngduì",
    "meaning": "leader of a group/team/etc.",
    "grade": 7
  },
  {
    "id": 8083,
    "traditional": "領土",
    "simplified": "领土",
    "reading": "lǐngtǔ",
    "meaning": "demesne, country, nation, realm, soli, landgrave, princedom, territory, emirate, domain, state, seigneury, estate, seignory, territoriality, acres, possession, signory, territorial, province, landed estate, dominion, land",
    "grade": 7
  },
  {
    "id": 8084,
    "traditional": "領悟",
    "simplified": "领悟",
    "reading": "lǐngwù",
    "meaning": "to understand, to comprehend",
    "grade": 7
  },
  {
    "id": 8085,
    "traditional": "領養",
    "simplified": "领养",
    "reading": "lǐngyǎng",
    "meaning": "adoption, to adopt (a child)",
    "grade": 7
  },
  {
    "id": 8086,
    "traditional": "溜達",
    "simplified": "溜达",
    "reading": "liūda",
    "meaning": "to stroll, to go for a walk",
    "grade": 7
  },
  {
    "id": 8087,
    "traditional": "領域",
    "simplified": "领域",
    "reading": "lǐngyù",
    "meaning": "demesne, line of business, field of operation, country, background, terrain, realm, part, arena, sphere of influence, setting, territory, precinct, radius, scope, domain, sphere, frontier, orbit, preserve, area, world, kingdom, ambit, signory, province, region, field, universe, hemisphere",
    "grade": 7
  },
  {
    "id": 8088,
    "traditional": "瀏覽器",
    "simplified": "浏览器",
    "reading": "liúlǎnqì",
    "meaning": "browser (software)",
    "grade": 7
  },
  {
    "id": 8089,
    "traditional": "留戀",
    "simplified": "留恋",
    "reading": "liúliàn",
    "meaning": "reluctant to leave, to hate to have to go, to recall fondly",
    "grade": 7
  },
  {
    "id": 8090,
    "traditional": "溜",
    "simplified": "溜",
    "reading": "liū",
    "meaning": "to slip away, to escape in stealth, to skate",
    "grade": 7
  },
  {
    "id": 8091,
    "traditional": "瀏覽",
    "simplified": "浏览",
    "reading": "liúlǎn",
    "meaning": "thumb, glance, riff, scan, riffle, leaf, riffle through, pass over, browse, dip_into, skim, skim through, run_through, flick, flip, dip into, glance over, peruse",
    "grade": 7
  },
  {
    "id": 8092,
    "traditional": "留念",
    "simplified": "留念",
    "reading": "liúniàn",
    "meaning": "to keep as a souvenir, to recall fondly",
    "grade": 7
  },
  {
    "id": 8093,
    "traditional": "留心",
    "simplified": "留心",
    "reading": "liú xīn",
    "meaning": "to be careful, to pay attention to",
    "grade": 7
  },
  {
    "id": 8094,
    "traditional": "留神",
    "simplified": "留神",
    "reading": "liú shén",
    "meaning": "to take care, to be careful",
    "grade": 7
  },
  {
    "id": 8095,
    "traditional": "留意",
    "simplified": "留意",
    "reading": "liú yì",
    "meaning": "be careful, watch, see, insure, see to it, pay attention to, keep one's eyes open, take notice, listen, heed, mind, ensure, advert, assure, control, look out, check, ascertain, regard",
    "grade": 7
  },
  {
    "id": 8096,
    "traditional": "流暢",
    "simplified": "流畅",
    "reading": "liúchàng",
    "meaning": "flowing (of speech, writing), fluent, smooth and easy",
    "grade": 7
  },
  {
    "id": 8097,
    "traditional": "流程",
    "simplified": "流程",
    "reading": "liúchéng",
    "meaning": "workflow, course (of river, technological process, process, driftage, manufacturing/technological process/workflow, etc.), course (of river/etc.), course, manufacturing, manufacturing process, technological workflow, circuit, flow",
    "grade": 7
  },
  {
    "id": 8098,
    "traditional": "流浪",
    "simplified": "流浪",
    "reading": "liúlàng",
    "meaning": "lead vagrant life",
    "grade": 7
  },
  {
    "id": 8099,
    "traditional": "流淚",
    "simplified": "流泪",
    "reading": "liú lèi",
    "meaning": "to shed tears",
    "grade": 7
  },
  {
    "id": 8100,
    "traditional": "流量",
    "simplified": "流量",
    "reading": "liúliàng",
    "meaning": "flow rate, rate, throughput, site traffic (Internet)",
    "grade": 7
  },
  {
    "id": 8101,
    "traditional": "流露",
    "simplified": "流露",
    "reading": "liúlù",
    "meaning": "betray/reveal unintentionally",
    "grade": 7
  },
  {
    "id": 8102,
    "traditional": "流氓",
    "simplified": "流氓",
    "reading": "liúmáng",
    "meaning": "roughscuff, rip, yobbo, badmash, swashbuckler, rascal, riffraff, scamp, raff, rapscallion, rogue, rowdy, hooliganism, cur, picaroon, varmint, yobo, ruffian, hector, roughneck, gaolbird, scalawag, gorilla, no-good, scallywag, hoodlum, limmer, tearaway, blackguard, picaro, hooligan, hessian, bullyboy, rascallion, indecency, bully, plug-ugly, shoulder-hitter, dingbat, caird, tough, varlet, sc",
    "grade": 7
  },
  {
    "id": 8103,
    "traditional": "流入",
    "simplified": "流入",
    "reading": "liúrù",
    "meaning": "to flow into, to drift into, influx, inflow",
    "grade": 7
  },
  {
    "id": 8104,
    "traditional": "流失",
    "simplified": "流失",
    "reading": "liúshī",
    "meaning": "run off, be washed away",
    "grade": 7
  },
  {
    "id": 8105,
    "traditional": "流水",
    "simplified": "流水",
    "reading": "liúshuǐ",
    "meaning": "presto, turnover, flowing water, turnover (in business), flowing/running water, flowing, running water",
    "grade": 7
  },
  {
    "id": 8106,
    "traditional": "流向",
    "simplified": "流向",
    "reading": "liúxiàng",
    "meaning": "Flow",
    "grade": 7
  },
  {
    "id": 8107,
    "traditional": "流淌",
    "simplified": "流淌",
    "reading": "liútǎng",
    "meaning": "to flow",
    "grade": 7
  },
  {
    "id": 8108,
    "traditional": "流血",
    "simplified": "流血",
    "reading": "liúxuè",
    "meaning": "shed blood, hemorrhage, bloody, bleed",
    "grade": 7
  },
  {
    "id": 8109,
    "traditional": "流轉",
    "simplified": "流转",
    "reading": "liúzhuǎn",
    "meaning": "to be on the move, to roam or wander, to circulate (of goods or capital)",
    "grade": 7
  },
  {
    "id": 8110,
    "traditional": "柳樹",
    "simplified": "柳树",
    "reading": "liǔshù",
    "meaning": "willow",
    "grade": 7
  },
  {
    "id": 8111,
    "traditional": "遛",
    "simplified": "遛",
    "reading": "liù",
    "meaning": "to stroll, to walk (an animal)",
    "grade": 7
  },
  {
    "id": 8112,
    "traditional": "流域",
    "simplified": "流域",
    "reading": "liúyù",
    "meaning": "river basin, valley, drainage area",
    "grade": 7
  },
  {
    "id": 8113,
    "traditional": "龍舟",
    "simplified": "龙舟",
    "reading": "lóngzhōu",
    "meaning": "dragon boat, imperial boat",
    "grade": 7
  },
  {
    "id": 8114,
    "traditional": "聾人",
    "simplified": "聋人",
    "reading": "lóngrén",
    "meaning": "Deaf",
    "grade": 7
  },
  {
    "id": 8115,
    "traditional": "籠子",
    "simplified": "笼子",
    "reading": "lóngzi",
    "meaning": "cage, coop, basket, container",
    "grade": 7
  },
  {
    "id": 8116,
    "traditional": "聾",
    "simplified": "聋",
    "reading": "lóng",
    "meaning": "deaf",
    "grade": 7
  },
  {
    "id": 8117,
    "traditional": "隆重",
    "simplified": "隆重",
    "reading": "lóngzhòng",
    "meaning": "grand, prosperous, ceremonious, solemn",
    "grade": 7
  },
  {
    "id": 8118,
    "traditional": "壟斷",
    "simplified": "垄断",
    "reading": "lǒngduàn",
    "meaning": "monopolize",
    "grade": 7
  },
  {
    "id": 8119,
    "traditional": "籠罩",
    "simplified": "笼罩",
    "reading": "lǒngzhào",
    "meaning": "envelop, shroud",
    "grade": 7
  },
  {
    "id": 8120,
    "traditional": "籠統",
    "simplified": "笼统",
    "reading": "lǒngtǒng",
    "meaning": "general, broad, sweeping, lacking in detail, vague",
    "grade": 7
  },
  {
    "id": 8121,
    "traditional": "摟",
    "simplified": "搂",
    "reading": "lǒu",
    "meaning": "to draw towards oneself, to gather, to gather up (one's gown, sleeves etc), to grab (money), to extort, to hug, to embrace, to hold in one's arms",
    "grade": 7
  },
  {
    "id": 8122,
    "traditional": "露面",
    "simplified": "露面",
    "reading": "lòu miàn",
    "meaning": "to show one's face, to appear (in public)",
    "grade": 7
  },
  {
    "id": 8123,
    "traditional": "蘆花",
    "simplified": "芦花",
    "reading": "lúhuā",
    "meaning": "Reed",
    "grade": 7
  },
  {
    "id": 8124,
    "traditional": "爐灶",
    "simplified": "炉灶",
    "reading": "lúzào",
    "meaning": "stove",
    "grade": 7
  },
  {
    "id": 8125,
    "traditional": "鹵味",
    "simplified": "卤味",
    "reading": "lǔwèi",
    "meaning": "food prepared by stewing in soy sauce and spices, variant of 滷味|卤味[lu3 wei4]",
    "grade": 7
  },
  {
    "id": 8126,
    "traditional": "魯莽",
    "simplified": "鲁莽",
    "reading": "lǔmǎng",
    "meaning": "hot-headed, impulsive, reckless",
    "grade": 7
  },
  {
    "id": 8127,
    "traditional": "爐子",
    "simplified": "炉子",
    "reading": "lúzi",
    "meaning": "stove, oven, furnace",
    "grade": 7
  },
  {
    "id": 8128,
    "traditional": "錄製",
    "simplified": "录制",
    "reading": "lùzhì",
    "meaning": "to record (video or audio)",
    "grade": 7
  },
  {
    "id": 8129,
    "traditional": "路程",
    "simplified": "路程",
    "reading": "lùchéng",
    "meaning": "distance travelled, journey",
    "grade": 7
  },
  {
    "id": 8130,
    "traditional": "鹿",
    "simplified": "鹿",
    "reading": "lù",
    "meaning": "cervine, deer, European elk, elk, moose, Alces alces, cervid",
    "grade": 7
  },
  {
    "id": 8131,
    "traditional": "路段",
    "simplified": "路段",
    "reading": "lùduàn",
    "meaning": "Section",
    "grade": 7
  },
  {
    "id": 8132,
    "traditional": "路況",
    "simplified": "路况",
    "reading": "lùkuàng",
    "meaning": "road condition(s) (e.g. surface, traffic flow etc)",
    "grade": 7
  },
  {
    "id": 8133,
    "traditional": "路面",
    "simplified": "路面",
    "reading": "lùmiàn",
    "meaning": "pavement",
    "grade": 7
  },
  {
    "id": 8134,
    "traditional": "路人",
    "simplified": "路人",
    "reading": "lùrén",
    "meaning": "passer-by, stranger",
    "grade": 7
  },
  {
    "id": 8135,
    "traditional": "路燈",
    "simplified": "路灯",
    "reading": "lùdēng",
    "meaning": "street lamp, street light",
    "grade": 7
  },
  {
    "id": 8136,
    "traditional": "路途",
    "simplified": "路途",
    "reading": "lùtú",
    "meaning": "Route",
    "grade": 7
  },
  {
    "id": 8137,
    "traditional": "露天",
    "simplified": "露天",
    "reading": "lùtiān",
    "meaning": "in the open air, outdoors",
    "grade": 7
  },
  {
    "id": 8138,
    "traditional": "旅程",
    "simplified": "旅程",
    "reading": "lǚchéng",
    "meaning": "travel plan, journeying, itinerary, route, stage, peregrination, journey",
    "grade": 7
  },
  {
    "id": 8139,
    "traditional": "旅途",
    "simplified": "旅途",
    "reading": "lǚtú",
    "meaning": "way, travel, route, traveling, travelling, trip, journey",
    "grade": 7
  },
  {
    "id": 8140,
    "traditional": "路子",
    "simplified": "路子",
    "reading": "lùzi",
    "meaning": "method, way, approach",
    "grade": 7
  },
  {
    "id": 8141,
    "traditional": "鋁",
    "simplified": "铝",
    "reading": "lǚ",
    "meaning": "aluminum (chemistry)",
    "grade": 7
  },
  {
    "id": 8142,
    "traditional": "屢次",
    "simplified": "屡次",
    "reading": "lǚcì",
    "meaning": "repeatedly, time and again",
    "grade": 7
  },
  {
    "id": 8143,
    "traditional": "縷",
    "simplified": "缕",
    "reading": "lǚ",
    "meaning": "strand, thread, detailed, in detail, classifier for wisps (of smoke, mist or vapor), strands, locks (of hair)",
    "grade": 7
  },
  {
    "id": 8144,
    "traditional": "屢",
    "simplified": "屡",
    "reading": "lǚ",
    "meaning": "time and again, repeatedly, frequently",
    "grade": 7
  },
  {
    "id": 8145,
    "traditional": "履行",
    "simplified": "履行",
    "reading": "lǚxíng",
    "meaning": "to fulfill (one's obligations), to carry out (a task), to implement (an agreement), to perform",
    "grade": 7
  },
  {
    "id": 8146,
    "traditional": "率",
    "simplified": "率",
    "reading": "lǜ",
    "meaning": "lead, command",
    "grade": 7
  },
  {
    "id": 8147,
    "traditional": "綠燈",
    "simplified": "绿灯",
    "reading": "lǜdēng",
    "meaning": "green light",
    "grade": 7
  },
  {
    "id": 8148,
    "traditional": "孿生",
    "simplified": "孪生",
    "reading": "luánshēng",
    "meaning": "twin",
    "grade": 7
  },
  {
    "id": 8149,
    "traditional": "綠地",
    "simplified": "绿地",
    "reading": "lǜdì",
    "meaning": "greenness, greenery patches, meadow, grassland",
    "grade": 7
  },
  {
    "id": 8150,
    "traditional": "卵",
    "simplified": "卵",
    "reading": "luǎn",
    "meaning": "egg, ovum, spawn",
    "grade": 7
  },
  {
    "id": 8151,
    "traditional": "掠奪",
    "simplified": "掠夺",
    "reading": "lüèduó",
    "meaning": "to plunder, to rob",
    "grade": 7
  },
  {
    "id": 8152,
    "traditional": "亂七八糟",
    "simplified": "乱七八糟",
    "reading": "luànqībāzāo",
    "meaning": "everything in disorder (idiom), in a hideous mess, at sixes and sevens",
    "grade": 7
  },
  {
    "id": 8153,
    "traditional": "略微",
    "simplified": "略微",
    "reading": "lüèwēi",
    "meaning": "a little bit, slightly",
    "grade": 7
  },
  {
    "id": 8154,
    "traditional": "掄",
    "simplified": "抡",
    "reading": "lūn",
    "meaning": "to swing (one's arms, a heavy object), to wave (a sword, one's fists), to fling (money), to select",
    "grade": 7
  },
  {
    "id": 8155,
    "traditional": "略",
    "simplified": "略",
    "reading": "lüè",
    "meaning": "a little bit, cursorily, any, something, slightly, somewhat, a little, poco, a bit",
    "grade": 7
  },
  {
    "id": 8156,
    "traditional": "倫理",
    "simplified": "伦理",
    "reading": "lúnlǐ",
    "meaning": "value orientation, value-system, moral, moral principle, morals, ethical, ethical motive, ethic, moral principles, morality, ethics",
    "grade": 7
  },
  {
    "id": 8157,
    "traditional": "輪換",
    "simplified": "轮换",
    "reading": "lúnhuàn",
    "meaning": "duty roster",
    "grade": 7
  },
  {
    "id": 8158,
    "traditional": "輪流",
    "simplified": "轮流",
    "reading": "lúnliú",
    "meaning": "by turns, in turn",
    "grade": 7
  },
  {
    "id": 8159,
    "traditional": "輪胎",
    "simplified": "轮胎",
    "reading": "lúntāi",
    "meaning": "tire, pneumatic tire",
    "grade": 7
  },
  {
    "id": 8160,
    "traditional": "輪廓",
    "simplified": "轮廓",
    "reading": "lúnkuò",
    "meaning": "outline, contour, rough sketch",
    "grade": 7
  },
  {
    "id": 8161,
    "traditional": "論壇",
    "simplified": "论坛",
    "reading": "lùntán",
    "meaning": "forum (for discussion)",
    "grade": 7
  },
  {
    "id": 8162,
    "traditional": "論證",
    "simplified": "论证",
    "reading": "lùnzhèng",
    "meaning": "to prove a point, to expound on, to demonstrate or prove (through argument), proof",
    "grade": 7
  },
  {
    "id": 8163,
    "traditional": "羅",
    "simplified": "罗",
    "reading": "luó",
    "meaning": "surname Luo, gauze, to collect, to gather, to catch, to sift",
    "grade": 7
  },
  {
    "id": 8164,
    "traditional": "論述",
    "simplified": "论述",
    "reading": "lùnshù",
    "meaning": "exposition and argumentation",
    "grade": 7
  },
  {
    "id": 8165,
    "traditional": "蘿卜",
    "simplified": "萝卜",
    "reading": "luóbo",
    "meaning": "radish (Raphanus sativus), esp. white radish 白蘿蔔|白萝卜[bai2 luo2 bo5], CL:條|条[tiao2]",
    "grade": 7
  },
  {
    "id": 8166,
    "traditional": "螺絲",
    "simplified": "螺丝",
    "reading": "luósī",
    "meaning": "screw",
    "grade": 7
  },
  {
    "id": 8167,
    "traditional": "絡繹不絕",
    "simplified": "络绎不绝",
    "reading": "luòyì-bùjué",
    "meaning": "continuously, in an endless stream (idiom)",
    "grade": 7
  },
  {
    "id": 8168,
    "traditional": "裸露",
    "simplified": "裸露",
    "reading": "luǒlù",
    "meaning": "naked, bare, uncovered, exposed",
    "grade": 7
  },
  {
    "id": 8169,
    "traditional": "落差",
    "simplified": "落差",
    "reading": "luòchā",
    "meaning": "drop in elevation, (fig.) gap (in wages, expectations etc), disparity",
    "grade": 7
  },
  {
    "id": 8170,
    "traditional": "落地",
    "simplified": "落地",
    "reading": "luò dì",
    "meaning": "be born (of babies), born, fail, fall to the ground, be born",
    "grade": 7
  },
  {
    "id": 8171,
    "traditional": "裸",
    "simplified": "裸",
    "reading": "luǒ",
    "meaning": "naked, variant of 裸[luo3]",
    "grade": 7
  },
  {
    "id": 8172,
    "traditional": "落戶",
    "simplified": "落户",
    "reading": "luò hù",
    "meaning": "to settle, to set up home",
    "grade": 7
  },
  {
    "id": 8173,
    "traditional": "落下",
    "simplified": "落下",
    "reading": "luòxià",
    "meaning": "fall/lag behind",
    "grade": 7
  },
  {
    "id": 8174,
    "traditional": "麻",
    "simplified": "麻",
    "reading": "má",
    "meaning": "generic name for hemp, flax etc, hemp or flax fiber for textile materials, sesame, CL:縷|缕[lu:3], (of materials) rough or coarse, pocked, pitted, to have pins and needles or tingling, to feel numb, surname Ma",
    "grade": 7
  },
  {
    "id": 8175,
    "traditional": "麻痹",
    "simplified": "麻痹",
    "reading": "mábì",
    "meaning": "paralysis, palsy, numbness, to benumb, (fig.) to lull, negligent, apathetic",
    "grade": 7
  },
  {
    "id": 8176,
    "traditional": "麻木",
    "simplified": "麻木",
    "reading": "mámù",
    "meaning": "numb, insensitive, apathetic",
    "grade": 7
  },
  {
    "id": 8177,
    "traditional": "麻將",
    "simplified": "麻将",
    "reading": "májiàng",
    "meaning": "mahjong, CL:副[fu4]",
    "grade": 7
  },
  {
    "id": 8178,
    "traditional": "馬後炮",
    "simplified": "马后炮",
    "reading": "mǎhòupào",
    "meaning": "lit. firing after the horse, fig. belated action, giving advice in hindsight",
    "grade": 7
  },
  {
    "id": 8179,
    "traditional": "麻醉",
    "simplified": "麻醉",
    "reading": "mázuì",
    "meaning": "anesthesize, poison, drug",
    "grade": 7
  },
  {
    "id": 8180,
    "traditional": "馬力",
    "simplified": "马力",
    "reading": "mǎlì",
    "meaning": "horsepower",
    "grade": 7
  },
  {
    "id": 8181,
    "traditional": "麻辣",
    "simplified": "麻辣",
    "reading": "málà",
    "meaning": "hot and numbing",
    "grade": 7
  },
  {
    "id": 8182,
    "traditional": "馬桶",
    "simplified": "马桶",
    "reading": "mǎtǒng",
    "meaning": "chamber pot, wooden pan used as toilet, toilet bowl",
    "grade": 7
  },
  {
    "id": 8183,
    "traditional": "馬戲",
    "simplified": "马戏",
    "reading": "mǎxì",
    "meaning": "circus",
    "grade": 7
  },
  {
    "id": 8184,
    "traditional": "馬虎",
    "simplified": "马虎",
    "reading": "mǎhu",
    "meaning": "careless, sloppy, negligent, skimpy",
    "grade": 7
  },
  {
    "id": 8185,
    "traditional": "埋藏",
    "simplified": "埋藏",
    "reading": "máicáng",
    "meaning": "to bury, to hide by burying, hidden",
    "grade": 7
  },
  {
    "id": 8186,
    "traditional": "碼",
    "simplified": "码",
    "reading": "mǎ",
    "meaning": "m.[standard]",
    "grade": 7
  },
  {
    "id": 8187,
    "traditional": "埋伏",
    "simplified": "埋伏",
    "reading": "máifu",
    "meaning": "to ambush, to lie in wait for, to lie low, ambush",
    "grade": 7
  },
  {
    "id": 8188,
    "traditional": "埋沒",
    "simplified": "埋没",
    "reading": "máimò",
    "meaning": "to engulf, to bury, to overlook, to stifle, to neglect, to fall into oblivion",
    "grade": 7
  },
  {
    "id": 8189,
    "traditional": "邁",
    "simplified": "迈",
    "reading": "mài",
    "meaning": "to take a step, to stride",
    "grade": 7
  },
  {
    "id": 8190,
    "traditional": "買不起",
    "simplified": "买不起",
    "reading": "mǎibuqǐ",
    "meaning": "cannot afford, can't afford buying",
    "grade": 7
  },
  {
    "id": 8191,
    "traditional": "邁進",
    "simplified": "迈进",
    "reading": "màijìn",
    "meaning": "forge ahead, stride forward, advance with big strides",
    "grade": 7
  },
  {
    "id": 8192,
    "traditional": "脈搏",
    "simplified": "脉搏",
    "reading": "màibó",
    "meaning": "pulse (both medical and figurative)",
    "grade": 7
  },
  {
    "id": 8193,
    "traditional": "賣弄",
    "simplified": "卖弄",
    "reading": "màinong",
    "meaning": "to show off, to make a display of",
    "grade": 7
  },
  {
    "id": 8194,
    "traditional": "脈絡",
    "simplified": "脉络",
    "reading": "màiluò",
    "meaning": "arteries and veins, network of blood vessels, vascular system (of a plant or animal), (fig.) fabric (i.e. underlying structure, as in 'social fabric'), overall context",
    "grade": 7
  },
  {
    "id": 8195,
    "traditional": "埋怨",
    "simplified": "埋怨",
    "reading": "mányuàn",
    "meaning": "to complain, to grumble (about), to reproach, to blame",
    "grade": 7
  },
  {
    "id": 8196,
    "traditional": "瞞",
    "simplified": "瞒",
    "reading": "mán",
    "meaning": "to conceal from, to keep (sb) in the dark",
    "grade": 7
  },
  {
    "id": 8197,
    "traditional": "蠻",
    "simplified": "蛮",
    "reading": "mán",
    "meaning": "pretty, very, quite, more or less, pretty much, fairly, rather, somewhat, to some extent",
    "grade": 7
  },
  {
    "id": 8198,
    "traditional": "滿懷",
    "simplified": "满怀",
    "reading": "mǎnhuái",
    "meaning": "to have one's heart filled with, (to collide) full on, (of farm animals) heavy with young",
    "grade": 7
  },
  {
    "id": 8199,
    "traditional": "漫",
    "simplified": "漫",
    "reading": "màn",
    "meaning": "free, unrestrained, to inundate",
    "grade": 7
  },
  {
    "id": 8200,
    "traditional": "蔓延",
    "simplified": "蔓延",
    "reading": "mànyán",
    "meaning": "spread out, extend",
    "grade": 7
  },
  {
    "id": 8201,
    "traditional": "漫遊",
    "simplified": "漫游",
    "reading": "mànyóu",
    "meaning": "to travel around, to roam, (mobile telephony) roaming",
    "grade": 7
  },
  {
    "id": 8202,
    "traditional": "忙碌",
    "simplified": "忙碌",
    "reading": "mánglù",
    "meaning": "be busy, bustle about",
    "grade": 7
  },
  {
    "id": 8203,
    "traditional": "慢慢來",
    "simplified": "慢慢来",
    "reading": "mànmànlái",
    "meaning": "take your time, take it easy",
    "grade": 7
  },
  {
    "id": 8204,
    "traditional": "忙亂",
    "simplified": "忙乱",
    "reading": "mángluàn",
    "meaning": "rushed and muddled",
    "grade": 7
  },
  {
    "id": 8205,
    "traditional": "慢性",
    "simplified": "慢性",
    "reading": "mànxìng",
    "meaning": "chronic, slow (in taking effect)",
    "grade": 7
  },
  {
    "id": 8206,
    "traditional": "忙活",
    "simplified": "忙活",
    "reading": "mánghuo",
    "meaning": "to be really busy, pressing business",
    "grade": 7
  },
  {
    "id": 8207,
    "traditional": "盲目",
    "simplified": "盲目",
    "reading": "mángmù",
    "meaning": "blind",
    "grade": 7
  },
  {
    "id": 8208,
    "traditional": "茫然",
    "simplified": "茫然",
    "reading": "mángrán",
    "meaning": "ignorant, in the dark",
    "grade": 7
  },
  {
    "id": 8209,
    "traditional": "茅臺",
    "simplified": "茅台",
    "reading": "Máotái",
    "meaning": "Maotai town in Renhuai county, Guizhou, Maotai liquor 茅臺酒|茅台酒",
    "grade": 7
  },
  {
    "id": 8210,
    "traditional": "茂密",
    "simplified": "茂密",
    "reading": "màomì",
    "meaning": "dense (of plant growth), lush",
    "grade": 7
  },
  {
    "id": 8211,
    "traditional": "矛頭",
    "simplified": "矛头",
    "reading": "máotóu",
    "meaning": "spearhead, barb, an attack or criticism",
    "grade": 7
  },
  {
    "id": 8212,
    "traditional": "茂盛",
    "simplified": "茂盛",
    "reading": "màoshèng",
    "meaning": "lush",
    "grade": 7
  },
  {
    "id": 8213,
    "traditional": "冒犯",
    "simplified": "冒犯",
    "reading": "màofàn",
    "meaning": "to offend",
    "grade": 7
  },
  {
    "id": 8214,
    "traditional": "冒充",
    "simplified": "冒充",
    "reading": "màochōng",
    "meaning": "to feign, to pretend to be, to pass oneself off as",
    "grade": 7
  },
  {
    "id": 8215,
    "traditional": "冒昧",
    "simplified": "冒昧",
    "reading": "màomèi",
    "meaning": "bold, presumptuous, to take the liberty of",
    "grade": 7
  },
  {
    "id": 8216,
    "traditional": "冒險",
    "simplified": "冒险",
    "reading": "mào xiǎn",
    "meaning": "take risks/chances",
    "grade": 7
  },
  {
    "id": 8217,
    "traditional": "沒說的",
    "simplified": "没说的",
    "reading": "méishuōde",
    "meaning": "really good",
    "grade": 7
  },
  {
    "id": 8218,
    "traditional": "沒勁",
    "simplified": "没劲",
    "reading": "méi jìn",
    "meaning": "to have no strength, to feel weak, exhausted, feeling listless, boring, of no interest",
    "grade": 7
  },
  {
    "id": 8219,
    "traditional": "沒轍",
    "simplified": "没辙",
    "reading": "méizhé",
    "meaning": "at one's wit's end, unable to find a way out",
    "grade": 7
  },
  {
    "id": 8220,
    "traditional": "沒完沒了",
    "simplified": "没完没了",
    "reading": "méiwán-méiliǎo",
    "meaning": "without end, incessantly, on and on",
    "grade": 7
  },
  {
    "id": 8221,
    "traditional": "玫瑰",
    "simplified": "玫瑰",
    "reading": "méigui",
    "meaning": "rosebush, rose",
    "grade": 7
  },
  {
    "id": 8222,
    "traditional": "沒意思",
    "simplified": "没意思",
    "reading": "méi yìsi",
    "meaning": "boring, of no interest",
    "grade": 7
  },
  {
    "id": 8223,
    "traditional": "沒準兒",
    "simplified": "没准儿",
    "reading": "méizhǔnr5",
    "meaning": "not sure, maybe",
    "grade": 7
  },
  {
    "id": 8224,
    "traditional": "眉開眼笑",
    "simplified": "眉开眼笑",
    "reading": "méikāi-yǎnxiào",
    "meaning": "brows raised in delight, eyes laughing (idiom), beaming with joy, all smiles",
    "grade": 7
  },
  {
    "id": 8225,
    "traditional": "枚",
    "simplified": "枚",
    "reading": "méi",
    "meaning": "enzyme, flare, ferment, school, mine, Surname, stick used as mouth gag, piece, rocket, m.[general],",
    "grade": 7
  },
  {
    "id": 8226,
    "traditional": "眉毛",
    "simplified": "眉毛",
    "reading": "méimao",
    "meaning": "eyebrow, brow",
    "grade": 7
  },
  {
    "id": 8227,
    "traditional": "煤礦",
    "simplified": "煤矿",
    "reading": "méikuàng",
    "meaning": "coal mine, coal seam",
    "grade": 7
  },
  {
    "id": 8228,
    "traditional": "每當",
    "simplified": "每当",
    "reading": "měidāng",
    "meaning": "whenever, every time",
    "grade": 7
  },
  {
    "id": 8229,
    "traditional": "每逢",
    "simplified": "每逢",
    "reading": "měiféng",
    "meaning": "every time, on each occasion, whenever",
    "grade": 7
  },
  {
    "id": 8230,
    "traditional": "煤炭",
    "simplified": "煤炭",
    "reading": "méitàn",
    "meaning": "coal",
    "grade": 7
  },
  {
    "id": 8231,
    "traditional": "美觀",
    "simplified": "美观",
    "reading": "měiguān",
    "meaning": "pleasing to the eye",
    "grade": 7
  },
  {
    "id": 8232,
    "traditional": "美德",
    "simplified": "美德",
    "reading": "měidé",
    "meaning": "good, virtue, merit, goodness, virture, moral excellence, excellency",
    "grade": 7
  },
  {
    "id": 8233,
    "traditional": "美化",
    "simplified": "美化",
    "reading": "měihuà",
    "meaning": "beautify, embellish",
    "grade": 7
  },
  {
    "id": 8234,
    "traditional": "美景",
    "simplified": "美景",
    "reading": "měijǐng",
    "meaning": "vision, beauty_spot, beautiful sight, beautiful scenery",
    "grade": 7
  },
  {
    "id": 8235,
    "traditional": "美滿",
    "simplified": "美满",
    "reading": "měimǎn",
    "meaning": "happy, blissful",
    "grade": 7
  },
  {
    "id": 8236,
    "traditional": "美人",
    "simplified": "美人",
    "reading": "měirén",
    "meaning": "beauty, belle",
    "grade": 7
  },
  {
    "id": 8237,
    "traditional": "美妙",
    "simplified": "美妙",
    "reading": "měimiào",
    "meaning": "beautiful, wonderful, splendid",
    "grade": 7
  },
  {
    "id": 8238,
    "traditional": "美中不足",
    "simplified": "美中不足",
    "reading": "měizhōng-bùzú",
    "meaning": "everything is fine except for one small defect (idiom), the fly in the ointment",
    "grade": 7
  },
  {
    "id": 8239,
    "traditional": "美味",
    "simplified": "美味",
    "reading": "měiwèi",
    "meaning": "delicious, dainty",
    "grade": 7
  },
  {
    "id": 8240,
    "traditional": "美滋滋",
    "simplified": "美滋滋",
    "reading": "měizīzī",
    "meaning": "very happy, elated",
    "grade": 7
  },
  {
    "id": 8241,
    "traditional": "魅力",
    "simplified": "魅力",
    "reading": "mèilì",
    "meaning": "personal appeal, allurement, charisma, fascination, spell, attractive feature, glitter, witchery, lure, appeal, grace, loveliness, attraction, allure, enticement, seduction, glamour, pazazz, charm, pishogue, prestige, enchantment, glamor, witchcraft, magnetism, attractiveness, appealingness, hypnotism, bewitchment, invitation, personal magnetism, captivation",
    "grade": 7
  },
  {
    "id": 8242,
    "traditional": "門當戶對",
    "simplified": "门当户对",
    "reading": "méndāng-hùduì",
    "meaning": "the families are well-matched in terms of social status (idiom), (of a prospective marriage partner) an appropriate match",
    "grade": 7
  },
  {
    "id": 8243,
    "traditional": "門檻",
    "simplified": "门槛",
    "reading": "ménkǎn",
    "meaning": "doorstep, sill, threshold, fig. knack or trick (esp. scheme to get sth cheaper)",
    "grade": 7
  },
  {
    "id": 8244,
    "traditional": "門鈴",
    "simplified": "门铃",
    "reading": "ménlíng",
    "meaning": "doorbell",
    "grade": 7
  },
  {
    "id": 8245,
    "traditional": "悶",
    "simplified": "闷",
    "reading": "mèn",
    "meaning": "stuffy, shut indoors, to smother, to cover tightly, bored, depressed, melancholy, sealed, airtight, tightly closed",
    "grade": 7
  },
  {
    "id": 8246,
    "traditional": "門路",
    "simplified": "门路",
    "reading": "ménlu",
    "meaning": "way of doing sth, the right social connection",
    "grade": 7
  },
  {
    "id": 8247,
    "traditional": "萌發",
    "simplified": "萌发",
    "reading": "méngfā",
    "meaning": "to sprout, to shoot, to bud",
    "grade": 7
  },
  {
    "id": 8248,
    "traditional": "盟友",
    "simplified": "盟友",
    "reading": "méngyǒu",
    "meaning": "ally",
    "grade": 7
  },
  {
    "id": 8249,
    "traditional": "萌芽",
    "simplified": "萌芽",
    "reading": "méngyá",
    "meaning": "to sprout (lit. or fig.), to bud, to germinate, germ, seed, bud",
    "grade": 7
  },
  {
    "id": 8250,
    "traditional": "朦朧",
    "simplified": "朦胧",
    "reading": "ménglóng",
    "meaning": "hazy",
    "grade": 7
  },
  {
    "id": 8251,
    "traditional": "猛然",
    "simplified": "猛然",
    "reading": "měngrán",
    "meaning": "suddenly, abruptly",
    "grade": 7
  },
  {
    "id": 8252,
    "traditional": "夢幻",
    "simplified": "梦幻",
    "reading": "mènghuàn",
    "meaning": "dream, illusion, reverie",
    "grade": 7
  },
  {
    "id": 8253,
    "traditional": "猛烈",
    "simplified": "猛烈",
    "reading": "měngliè",
    "meaning": "fierce, violent (criticism etc)",
    "grade": 7
  },
  {
    "id": 8254,
    "traditional": "彌漫",
    "simplified": "弥漫",
    "reading": "mímàn",
    "meaning": "to pervade, to fill the air, diffuse, everywhere present, about to inundate (water), permeated by (smoke), filled with (dust), to saturate (the air with fog, smoke etc), variant of 彌漫|弥漫[mi2 man4]",
    "grade": 7
  },
  {
    "id": 8255,
    "traditional": "迷惑",
    "simplified": "迷惑",
    "reading": "míhuo",
    "meaning": "puzzle, confuse, baffle",
    "grade": 7
  },
  {
    "id": 8256,
    "traditional": "迷惑不解",
    "simplified": "迷惑不解",
    "reading": "míhuòbùjiě",
    "meaning": "to feel puzzled",
    "grade": 7
  },
  {
    "id": 8257,
    "traditional": "彌補",
    "simplified": "弥补",
    "reading": "míbǔ",
    "meaning": "supply, atone, make_up, renew, make up, remedy, redeem, make good, compensate, recuperate, counterbalance",
    "grade": 7
  },
  {
    "id": 8258,
    "traditional": "迷戀",
    "simplified": "迷恋",
    "reading": "míliàn",
    "meaning": "to be infatuated with, to indulge in",
    "grade": 7
  },
  {
    "id": 8259,
    "traditional": "迷路",
    "simplified": "迷路",
    "reading": "mí lù",
    "meaning": "to lose the way, lost, labyrinth, labyrinthus vestibularis (of the inner ear)",
    "grade": 7
  },
  {
    "id": 8260,
    "traditional": "迷失",
    "simplified": "迷失",
    "reading": "míshī",
    "meaning": "to lose (one's bearings), to get lost",
    "grade": 7
  },
  {
    "id": 8261,
    "traditional": "謎",
    "simplified": "谜",
    "reading": "mèi",
    "meaning": "puzzler, puzzle, rebus, crux, brain-teaser, mystery, conundrum, riddle, puzzlement, sticker, enigma, parable",
    "grade": 7
  },
  {
    "id": 8262,
    "traditional": "謎底",
    "simplified": "谜底",
    "reading": "mídǐ",
    "meaning": "answer to a riddle",
    "grade": 7
  },
  {
    "id": 8263,
    "traditional": "謎團",
    "simplified": "谜团",
    "reading": "mítuán",
    "meaning": "riddle, enigma, unpredictable situation, elusive matters",
    "grade": 7
  },
  {
    "id": 8264,
    "traditional": "祕方",
    "simplified": "秘方",
    "reading": "mìfāng",
    "meaning": "secret recipe",
    "grade": 7
  },
  {
    "id": 8265,
    "traditional": "謎語",
    "simplified": "谜语",
    "reading": "míyǔ",
    "meaning": "riddle, conundrum, CL:條|条[tiao2]",
    "grade": 7
  },
  {
    "id": 8266,
    "traditional": "祕訣",
    "simplified": "秘诀",
    "reading": "mìjué",
    "meaning": "secret know-how, key (to longevity), secret (of happiness), recipe (for success)",
    "grade": 7
  },
  {
    "id": 8267,
    "traditional": "密封",
    "simplified": "密封",
    "reading": "mìfēng",
    "meaning": "to seal up",
    "grade": 7
  },
  {
    "id": 8268,
    "traditional": "密不可分",
    "simplified": "密不可分",
    "reading": "mìbùkěfēn",
    "meaning": "inextricably linked (idiom), inseparable",
    "grade": 7
  },
  {
    "id": 8269,
    "traditional": "密度",
    "simplified": "密度",
    "reading": "mìdù",
    "meaning": "density, texture, thickness, consistency, compactness, concentration, denseness",
    "grade": 7
  },
  {
    "id": 8270,
    "traditional": "蜜蜂",
    "simplified": "蜜蜂",
    "reading": "mìfēng",
    "meaning": "bee, honeybee, CL:隻|只[zhi1],群[qun2]",
    "grade": 7
  },
  {
    "id": 8271,
    "traditional": "蜜",
    "simplified": "蜜",
    "reading": "mì",
    "meaning": "honey",
    "grade": 7
  },
  {
    "id": 8272,
    "traditional": "密集",
    "simplified": "密集",
    "reading": "mìjí",
    "meaning": "concentrated, crowded together",
    "grade": 7
  },
  {
    "id": 8273,
    "traditional": "蜜月",
    "simplified": "蜜月",
    "reading": "mìyuè",
    "meaning": "honeymoon",
    "grade": 7
  },
  {
    "id": 8274,
    "traditional": "棉花",
    "simplified": "棉花",
    "reading": "miánhua",
    "meaning": "cotton",
    "grade": 7
  },
  {
    "id": 8275,
    "traditional": "免",
    "simplified": "免",
    "reading": "miǎn",
    "meaning": "duty-free, be not allowed",
    "grade": 7
  },
  {
    "id": 8276,
    "traditional": "免不了",
    "simplified": "免不了",
    "reading": "miǎnbuliǎo",
    "meaning": "unavoidably",
    "grade": 7
  },
  {
    "id": 8277,
    "traditional": "免除",
    "simplified": "免除",
    "reading": "miǎnchú",
    "meaning": "to prevent, to avoid, to excuse, to exempt, to relieve, (of a debt) to remit",
    "grade": 7
  },
  {
    "id": 8278,
    "traditional": "免疫",
    "simplified": "免疫",
    "reading": "miǎnyì",
    "meaning": "immunize, immune",
    "grade": 7
  },
  {
    "id": 8279,
    "traditional": "免職",
    "simplified": "免职",
    "reading": "miǎn zhí",
    "meaning": "to relieve sb of his post, to sack, to demote, dismissal, sacking",
    "grade": 7
  },
  {
    "id": 8280,
    "traditional": "勉強",
    "simplified": "勉强",
    "reading": "miǎnqiǎng",
    "meaning": "do with difficulty",
    "grade": 7
  },
  {
    "id": 8281,
    "traditional": "緬懷",
    "simplified": "缅怀",
    "reading": "miǎnhuái",
    "meaning": "to commemorate, to recall fondly, to think of the past",
    "grade": 7
  },
  {
    "id": 8282,
    "traditional": "麵粉",
    "simplified": "面粉",
    "reading": "miànfěn",
    "meaning": "flour",
    "grade": 7
  },
  {
    "id": 8283,
    "traditional": "面部",
    "simplified": "面部",
    "reading": "miànbù",
    "meaning": "face (body part)",
    "grade": 7
  },
  {
    "id": 8284,
    "traditional": "面紅耳赤",
    "simplified": "面红耳赤",
    "reading": "miànhóng-ěrchì",
    "meaning": "flushed with anger (or excitement)",
    "grade": 7
  },
  {
    "id": 8285,
    "traditional": "面面俱到",
    "simplified": "面面俱到",
    "reading": "miànmiàn-jùdào",
    "meaning": "(idiom) take care of everything, handle everything",
    "grade": 7
  },
  {
    "id": 8286,
    "traditional": "苗條",
    "simplified": "苗条",
    "reading": "miáotiao",
    "meaning": "(of a woman) slim, slender, graceful",
    "grade": 7
  },
  {
    "id": 8287,
    "traditional": "面目全非",
    "simplified": "面目全非",
    "reading": "miànmù-quánfēi",
    "meaning": "nothing remains the same (idiom), change beyond recognition",
    "grade": 7
  },
  {
    "id": 8288,
    "traditional": "苗頭",
    "simplified": "苗头",
    "reading": "miáotou",
    "meaning": "first signs, development (of a situation)",
    "grade": 7
  },
  {
    "id": 8289,
    "traditional": "苗",
    "simplified": "苗",
    "reading": "miáo",
    "meaning": "Hmong or Miao ethnic group of southwest China, surname Miao, sprout",
    "grade": 7
  },
  {
    "id": 8290,
    "traditional": "描繪",
    "simplified": "描绘",
    "reading": "miáohuì",
    "meaning": "depict, describe, portray",
    "grade": 7
  },
  {
    "id": 8291,
    "traditional": "瞄準",
    "simplified": "瞄准",
    "reading": "miáo zhǔn",
    "meaning": "to take aim at, to target",
    "grade": 7
  },
  {
    "id": 8292,
    "traditional": "廟",
    "simplified": "庙",
    "reading": "miào",
    "meaning": "shrine, sanctorium, joss house, temple fair, temple",
    "grade": 7
  },
  {
    "id": 8293,
    "traditional": "渺小",
    "simplified": "渺小",
    "reading": "miǎoxiǎo",
    "meaning": "minute, tiny, negligible, insignificant",
    "grade": 7
  },
  {
    "id": 8294,
    "traditional": "廟會",
    "simplified": "庙会",
    "reading": "miàohuì",
    "meaning": "temple fair",
    "grade": 7
  },
  {
    "id": 8295,
    "traditional": "滅亡",
    "simplified": "灭亡",
    "reading": "mièwáng",
    "meaning": "to be destroyed, to become extinct, to perish, to die out, to destroy, to exterminate",
    "grade": 7
  },
  {
    "id": 8296,
    "traditional": "滅絕",
    "simplified": "灭绝",
    "reading": "mièjué",
    "meaning": "to extinguish, to become extinct, to die out",
    "grade": 7
  },
  {
    "id": 8297,
    "traditional": "民辦",
    "simplified": "民办",
    "reading": "mínbàn",
    "meaning": "run by the local people, privately operated",
    "grade": 7
  },
  {
    "id": 8298,
    "traditional": "民俗",
    "simplified": "民俗",
    "reading": "mínsú",
    "meaning": "folklore, folk, folk custom, folkways",
    "grade": 7
  },
  {
    "id": 8299,
    "traditional": "民用",
    "simplified": "民用",
    "reading": "mínyòng",
    "meaning": "(for) civilian use",
    "grade": 7
  },
  {
    "id": 8300,
    "traditional": "民眾",
    "simplified": "民众",
    "reading": "mínzhòng",
    "meaning": "civil, crowd, masses, populace, rank_and_file, the masses, the masses of the people, the populace, demos, mob, multitude, plebs, commons, the common people, the people, million, public",
    "grade": 7
  },
  {
    "id": 8301,
    "traditional": "敏捷",
    "simplified": "敏捷",
    "reading": "mǐnjié",
    "meaning": "nimble, quick, shrewd",
    "grade": 7
  },
  {
    "id": 8302,
    "traditional": "名副其實",
    "simplified": "名副其实",
    "reading": "míngfùqíshí",
    "meaning": "not just in name only, but also in reality (idiom)",
    "grade": 7
  },
  {
    "id": 8303,
    "traditional": "名貴",
    "simplified": "名贵",
    "reading": "míngguì",
    "meaning": "famous and valuable, rare, precious",
    "grade": 7
  },
  {
    "id": 8304,
    "traditional": "敏銳",
    "simplified": "敏锐",
    "reading": "mǐnruì",
    "meaning": "keen, incisive, acute, sharp",
    "grade": 7
  },
  {
    "id": 8305,
    "traditional": "名利",
    "simplified": "名利",
    "reading": "mínglì",
    "meaning": "fame and gain, fame and wealth",
    "grade": 7
  },
  {
    "id": 8306,
    "traditional": "名聲",
    "simplified": "名声",
    "reading": "míngshēng",
    "meaning": "reputation",
    "grade": 7
  },
  {
    "id": 8307,
    "traditional": "名言",
    "simplified": "名言",
    "reading": "míngyán",
    "meaning": "saying, famous remark",
    "grade": 7
  },
  {
    "id": 8308,
    "traditional": "名氣",
    "simplified": "名气",
    "reading": "míngqi",
    "meaning": "reputation, fame",
    "grade": 7
  },
  {
    "id": 8309,
    "traditional": "明朗",
    "simplified": "明朗",
    "reading": "mínglǎng",
    "meaning": "bright, clear, obvious, forthright, open-minded, bright and cheerful",
    "grade": 7
  },
  {
    "id": 8310,
    "traditional": "明媚",
    "simplified": "明媚",
    "reading": "míngmèi",
    "meaning": "bright and beautiful",
    "grade": 7
  },
  {
    "id": 8311,
    "traditional": "名著",
    "simplified": "名著",
    "reading": "míngzhù",
    "meaning": "masterpiece",
    "grade": 7
  },
  {
    "id": 8312,
    "traditional": "銘記",
    "simplified": "铭记",
    "reading": "míngjì",
    "meaning": "to engrave in one's memory",
    "grade": 7
  },
  {
    "id": 8313,
    "traditional": "明智",
    "simplified": "明智",
    "reading": "míngzhì",
    "meaning": "sensible, wise, judicious, sagacious",
    "grade": 7
  },
  {
    "id": 8314,
    "traditional": "命",
    "simplified": "命",
    "reading": "mìng",
    "meaning": "life, lot, fate, destiny",
    "grade": 7
  },
  {
    "id": 8315,
    "traditional": "命名",
    "simplified": "命名",
    "reading": "mìng míng",
    "meaning": "to give a name to, to dub, to christen, to designate, named after, naming",
    "grade": 7
  },
  {
    "id": 8316,
    "traditional": "命題",
    "simplified": "命题",
    "reading": "mìng tí",
    "meaning": "proposition (logic, math.), to assign an essay topic",
    "grade": 7
  },
  {
    "id": 8317,
    "traditional": "摸索",
    "simplified": "摸索",
    "reading": "mōsuo",
    "meaning": "grope, feel about, fumble, try to find out",
    "grade": 7
  },
  {
    "id": 8318,
    "traditional": "模擬",
    "simplified": "模拟",
    "reading": "mónǐ",
    "meaning": "imitate, simulate",
    "grade": 7
  },
  {
    "id": 8319,
    "traditional": "磨難",
    "simplified": "磨难",
    "reading": "mónàn",
    "meaning": "a torment, a trial, tribulation, a cross (to bear), well-tried",
    "grade": 7
  },
  {
    "id": 8320,
    "traditional": "磨損",
    "simplified": "磨损",
    "reading": "mósǔn",
    "meaning": "wear and tear, abrasion",
    "grade": 7
  },
  {
    "id": 8321,
    "traditional": "磨合",
    "simplified": "磨合",
    "reading": "móhé",
    "meaning": "to break in, to wear in",
    "grade": 7
  },
  {
    "id": 8322,
    "traditional": "蘑菇",
    "simplified": "蘑菇",
    "reading": "mógu",
    "meaning": "mushroom, to pester, to dawdle",
    "grade": 7
  },
  {
    "id": 8323,
    "traditional": "魔鬼",
    "simplified": "魔鬼",
    "reading": "móguǐ",
    "meaning": "devil",
    "grade": 7
  },
  {
    "id": 8324,
    "traditional": "魔術",
    "simplified": "魔术",
    "reading": "móshù",
    "meaning": "magic",
    "grade": 7
  },
  {
    "id": 8325,
    "traditional": "抹",
    "simplified": "抹",
    "reading": "mǒ",
    "meaning": "brush/wipe off, exclude",
    "grade": 7
  },
  {
    "id": 8326,
    "traditional": "沒落",
    "simplified": "没落",
    "reading": "mòluò",
    "meaning": "to decline, to wane",
    "grade": 7
  },
  {
    "id": 8327,
    "traditional": "末日",
    "simplified": "末日",
    "reading": "mòrì",
    "meaning": "Judgment Day (in Christian eschatology), last day, end, final days, doomsday",
    "grade": 7
  },
  {
    "id": 8328,
    "traditional": "陌生",
    "simplified": "陌生",
    "reading": "mòshēng",
    "meaning": "strange, unfamiliar",
    "grade": 7
  },
  {
    "id": 8329,
    "traditional": "莫非",
    "simplified": "莫非",
    "reading": "mòfēi",
    "meaning": "can it be possible that, could it be",
    "grade": 7
  },
  {
    "id": 8330,
    "traditional": "莫過於",
    "simplified": "莫过于",
    "reading": "mòguòyú",
    "meaning": "nothing is more...than",
    "grade": 7
  },
  {
    "id": 8331,
    "traditional": "莫名其妙",
    "simplified": "莫名其妙",
    "reading": "mòmíng-qímiào",
    "meaning": "unfathomable mystery (idiom), subtle and ineffable, unable to make head or tail of it, boring (e.g. movie)",
    "grade": 7
  },
  {
    "id": 8332,
    "traditional": "漠然",
    "simplified": "漠然",
    "reading": "mòrán",
    "meaning": "indifferent, apathetic, cold",
    "grade": 7
  },
  {
    "id": 8333,
    "traditional": "墨",
    "simplified": "墨",
    "reading": "mò",
    "meaning": "learning, Chinese ink, ink stick, Mohist School, Mexico, Mohism, ink, handwriting, ink cake, Surname, China ink",
    "grade": 7
  },
  {
    "id": 8334,
    "traditional": "默讀",
    "simplified": "默读",
    "reading": "mòdú",
    "meaning": "to read in silence",
    "grade": 7
  },
  {
    "id": 8335,
    "traditional": "默默無聞",
    "simplified": "默默无闻",
    "reading": "mòmò-wúwén",
    "meaning": "obscure and unknown (idiom), an outsider without any reputation, a nobody, an unknown quantity",
    "grade": 7
  },
  {
    "id": 8336,
    "traditional": "謀害",
    "simplified": "谋害",
    "reading": "móuhài",
    "meaning": "to conspire to murder, to plot against sb's life",
    "grade": 7
  },
  {
    "id": 8337,
    "traditional": "謀求",
    "simplified": "谋求",
    "reading": "móuqiú",
    "meaning": "go for, be in quest of, seek to gain, strive for, endeavor, try for, seek",
    "grade": 7
  },
  {
    "id": 8338,
    "traditional": "默契",
    "simplified": "默契",
    "reading": "mòqì",
    "meaning": "tacit/secret agreement, tacit agreement, understanding, secret agreement, privity, tacit",
    "grade": 7
  },
  {
    "id": 8339,
    "traditional": "謀生",
    "simplified": "谋生",
    "reading": "móushēng",
    "meaning": "to seek one's livelihood, to work to support oneself, to earn a living",
    "grade": 7
  },
  {
    "id": 8340,
    "traditional": "畝",
    "simplified": "亩",
    "reading": "mǔ",
    "meaning": "acre, m.[standard],",
    "grade": 7
  },
  {
    "id": 8341,
    "traditional": "牡丹",
    "simplified": "牡丹",
    "reading": "mǔdan",
    "meaning": "Mudan District of Heze City 菏澤市|菏泽市[He2 ze2 Shi4], Shandong, Mutan township in Pingtung County 屏東縣|屏东县[Ping2 dong1 Xian4], Taiwan, tree peony (Paeonia suffruticosa)",
    "grade": 7
  },
  {
    "id": 8342,
    "traditional": "木板",
    "simplified": "木板",
    "reading": "mùbǎn",
    "meaning": "batten, deal, board, plank, planch, softwood",
    "grade": 7
  },
  {
    "id": 8343,
    "traditional": "木材",
    "simplified": "木材",
    "reading": "mùcái",
    "meaning": "patina, heartwood, wood, stuff, lumber, cabinet wood, timber",
    "grade": 7
  },
  {
    "id": 8344,
    "traditional": "木匠",
    "simplified": "木匠",
    "reading": "mùjiang",
    "meaning": "carpenter",
    "grade": 7
  },
  {
    "id": 8345,
    "traditional": "目不轉睛",
    "simplified": "目不转睛",
    "reading": "mùbùzhuǎnjīng",
    "meaning": "unable to take one's eyes off (idiom), to gaze steadily, to stare",
    "grade": 7
  },
  {
    "id": 8346,
    "traditional": "木偶",
    "simplified": "木偶",
    "reading": "mùǒu",
    "meaning": "puppet",
    "grade": 7
  },
  {
    "id": 8347,
    "traditional": "目瞪口呆",
    "simplified": "目瞪口呆",
    "reading": "mùdèng-kǒudāi",
    "meaning": "dumbstruck (idiom), stupefied, stunned",
    "grade": 7
  },
  {
    "id": 8348,
    "traditional": "目睹",
    "simplified": "目睹",
    "reading": "mùdǔ",
    "meaning": "behold, see with one's own eyes, witness",
    "grade": 7
  },
  {
    "id": 8349,
    "traditional": "目的地",
    "simplified": "目的地",
    "reading": "mùdìdì",
    "meaning": "bourn, goal, destination, terminus, bourne",
    "grade": 7
  },
  {
    "id": 8350,
    "traditional": "目錄",
    "simplified": "目录",
    "reading": "mùlù",
    "meaning": "beadroll, docket, catalog, contents, listing, catalogue, directory, muster, list, table of contents, bill, inventory, tabulation, cataloguer, table, cataloger, tabular matter, schedule",
    "grade": 7
  },
  {
    "id": 8351,
    "traditional": "目中無人",
    "simplified": "目中无人",
    "reading": "mùzhōng-wúrén",
    "meaning": "to consider everyone else beneath one (idiom), so arrogant that no-one else matters, condescending, to go about with one's nose in the air",
    "grade": 7
  },
  {
    "id": 8352,
    "traditional": "沐浴露",
    "simplified": "沐浴露",
    "reading": "mùyùlù",
    "meaning": "shower gel",
    "grade": 7
  },
  {
    "id": 8353,
    "traditional": "牧場",
    "simplified": "牧场",
    "reading": "mùchǎng",
    "meaning": "pasture, grazing land, ranch",
    "grade": 7
  },
  {
    "id": 8354,
    "traditional": "募捐",
    "simplified": "募捐",
    "reading": "mù juān",
    "meaning": "to solicit contributions, to collect donations",
    "grade": 7
  },
  {
    "id": 8355,
    "traditional": "牧民",
    "simplified": "牧民",
    "reading": "mùmín",
    "meaning": "herdsman",
    "grade": 7
  },
  {
    "id": 8356,
    "traditional": "墓地",
    "simplified": "墓地",
    "reading": "mùdì",
    "meaning": "cemetery, graveyard",
    "grade": 7
  },
  {
    "id": 8357,
    "traditional": "墓碑",
    "simplified": "墓碑",
    "reading": "mùbēi",
    "meaning": "gravestone, tombstone",
    "grade": 7
  },
  {
    "id": 8358,
    "traditional": "穆斯林",
    "simplified": "穆斯林",
    "reading": "Mùsīlín",
    "meaning": "Muslim",
    "grade": 7
  },
  {
    "id": 8359,
    "traditional": "拿手",
    "simplified": "拿手",
    "reading": "náshǒu",
    "meaning": "expert in, good at",
    "grade": 7
  },
  {
    "id": 8360,
    "traditional": "幕後",
    "simplified": "幕后",
    "reading": "mùhòu",
    "meaning": "behind the scenes",
    "grade": 7
  },
  {
    "id": 8361,
    "traditional": "幕",
    "simplified": "幕",
    "reading": "mù",
    "meaning": "m.[event]",
    "grade": 7
  },
  {
    "id": 8362,
    "traditional": "吶喊",
    "simplified": "呐喊",
    "reading": "nàhǎn",
    "meaning": "shout, rallying cry, cheering, to shout",
    "grade": 7
  },
  {
    "id": 8363,
    "traditional": "納悶兒",
    "simplified": "纳闷儿",
    "reading": "nàmènr5",
    "meaning": "puzzled, bewildered, erhua variant of 納悶|纳闷[na4 men4]",
    "grade": 7
  },
  {
    "id": 8364,
    "traditional": "哪知道",
    "simplified": "哪知道",
    "reading": "nǎzhīdào",
    "meaning": "Where to know",
    "grade": 7
  },
  {
    "id": 8365,
    "traditional": "納稅",
    "simplified": "纳税",
    "reading": "nà shuì",
    "meaning": "to pay taxes",
    "grade": 7
  },
  {
    "id": 8366,
    "traditional": "納入",
    "simplified": "纳入",
    "reading": "nàrù",
    "meaning": "take_in, bring ... into, to intake, integrate, to subsume, channel ... into, incorporate, channel into, bring into, to bring into, to fit into",
    "grade": 7
  },
  {
    "id": 8367,
    "traditional": "納稅人",
    "simplified": "纳税人",
    "reading": "nàshuìrén",
    "meaning": "taxpayer",
    "grade": 7
  },
  {
    "id": 8368,
    "traditional": "乃至",
    "simplified": "乃至",
    "reading": "nǎizhì",
    "meaning": "conj.: even, go so far as to",
    "grade": 7
  },
  {
    "id": 8369,
    "traditional": "耐",
    "simplified": "耐",
    "reading": "nài",
    "meaning": "endure, durable, stand, withstand, be able to bear or endure, bear, resistance, resist",
    "grade": 7
  },
  {
    "id": 8370,
    "traditional": "耐人尋味",
    "simplified": "耐人寻味",
    "reading": "nàirénxúnwèi",
    "meaning": "thought-provoking, worth thinking over, to provide food for thought",
    "grade": 7
  },
  {
    "id": 8371,
    "traditional": "乃",
    "simplified": "乃",
    "reading": "nǎi",
    "meaning": "therefore",
    "grade": 7
  },
  {
    "id": 8372,
    "traditional": "耐性",
    "simplified": "耐性",
    "reading": "nàixìng",
    "meaning": "patience, endurance",
    "grade": 7
  },
  {
    "id": 8373,
    "traditional": "難處",
    "simplified": "难处",
    "reading": "nánchu",
    "meaning": "trouble, difficulty, problem",
    "grade": 7
  },
  {
    "id": 8374,
    "traditional": "南瓜",
    "simplified": "南瓜",
    "reading": "nángua",
    "meaning": "pumpkin",
    "grade": 7
  },
  {
    "id": 8375,
    "traditional": "難得一見",
    "simplified": "难得一见",
    "reading": "nándéyījiàn",
    "meaning": "rarely seen",
    "grade": 7
  },
  {
    "id": 8376,
    "traditional": "難怪",
    "simplified": "难怪",
    "reading": "nánguài",
    "meaning": "no wonder",
    "grade": 7
  },
  {
    "id": 8377,
    "traditional": "難點",
    "simplified": "难点",
    "reading": "nándiǎn",
    "meaning": "difficulty",
    "grade": 7
  },
  {
    "id": 8378,
    "traditional": "難堪",
    "simplified": "难堪",
    "reading": "nánkān",
    "meaning": "hard to take, embarrassed",
    "grade": 7
  },
  {
    "id": 8379,
    "traditional": "難關",
    "simplified": "难关",
    "reading": "nánguān",
    "meaning": "difficulty, crisis",
    "grade": 7
  },
  {
    "id": 8380,
    "traditional": "難為情",
    "simplified": "难为情",
    "reading": "nánwéiqíng",
    "meaning": "embarrassed",
    "grade": 7
  },
  {
    "id": 8381,
    "traditional": "難以想象",
    "simplified": "难以想象",
    "reading": "nányǐxiǎngxiàng",
    "meaning": "Unimaginable",
    "grade": 7
  },
  {
    "id": 8382,
    "traditional": "難說",
    "simplified": "难说",
    "reading": "nánshuō",
    "meaning": "Hard to say",
    "grade": 7
  },
  {
    "id": 8383,
    "traditional": "撓",
    "simplified": "挠",
    "reading": "náo",
    "meaning": "to scratch, to thwart, to yield",
    "grade": 7
  },
  {
    "id": 8384,
    "traditional": "難以置信",
    "simplified": "难以置信",
    "reading": "nányǐzhìxìn",
    "meaning": "hard to believe, incredible",
    "grade": 7
  },
  {
    "id": 8385,
    "traditional": "惱羞成怒",
    "simplified": "恼羞成怒",
    "reading": "nǎoxiū-chéngnù",
    "meaning": "to fly into a rage out of humiliation, to be ashamed into anger (idiom)",
    "grade": 7
  },
  {
    "id": 8386,
    "traditional": "腦海",
    "simplified": "脑海",
    "reading": "nǎohǎi",
    "meaning": "brain, mind",
    "grade": 7
  },
  {
    "id": 8387,
    "traditional": "鬧事",
    "simplified": "闹事",
    "reading": "nào shì",
    "meaning": "to cause trouble, to create a disturbance",
    "grade": 7
  },
  {
    "id": 8388,
    "traditional": "腦筋",
    "simplified": "脑筋",
    "reading": "nǎojīn",
    "meaning": "way of thinking, ideas, head, mind, skull, brains",
    "grade": 7
  },
  {
    "id": 8389,
    "traditional": "鬧著玩兒",
    "simplified": "闹着玩儿",
    "reading": "nàozhewánr5",
    "meaning": "to play games, to joke around, to play a joke on sb",
    "grade": 7
  },
  {
    "id": 8390,
    "traditional": "內閣",
    "simplified": "内阁",
    "reading": "nèigé",
    "meaning": "cabinet",
    "grade": 7
  },
  {
    "id": 8391,
    "traditional": "內存",
    "simplified": "内存",
    "reading": "nèicún",
    "meaning": "internal storage, computer memory, random access memory (RAM)",
    "grade": 7
  },
  {
    "id": 8392,
    "traditional": "內行",
    "simplified": "内行",
    "reading": "nèiháng",
    "meaning": "expert, adept, experienced, an expert, a professional",
    "grade": 7
  },
  {
    "id": 8393,
    "traditional": "內涵",
    "simplified": "内涵",
    "reading": "nèihán",
    "meaning": "intension, comprehension, intention, connotation",
    "grade": 7
  },
  {
    "id": 8394,
    "traditional": "內幕",
    "simplified": "内幕",
    "reading": "nèimù",
    "meaning": "inside story, non-public information, behind the scenes, internal",
    "grade": 7
  },
  {
    "id": 8395,
    "traditional": "內向",
    "simplified": "内向",
    "reading": "nèixiàng",
    "meaning": "reserved (personality), introverted, (economics etc) domestic-oriented",
    "grade": 7
  },
  {
    "id": 8396,
    "traditional": "嫩",
    "simplified": "嫩",
    "reading": "nèn",
    "meaning": "old variant of 嫩[nen4], tender, delicate",
    "grade": 7
  },
  {
    "id": 8397,
    "traditional": "內需",
    "simplified": "内需",
    "reading": "nèixū",
    "meaning": "domestic demand",
    "grade": 7
  },
  {
    "id": 8398,
    "traditional": "能耗",
    "simplified": "能耗",
    "reading": "nénghào",
    "meaning": "Energy consumption",
    "grade": 7
  },
  {
    "id": 8399,
    "traditional": "能人",
    "simplified": "能人",
    "reading": "néngrén",
    "meaning": "capable person, Homo habilis, extinct species of upright East African hominid",
    "grade": 7
  },
  {
    "id": 8400,
    "traditional": "能源",
    "simplified": "能源",
    "reading": "néngyuán",
    "meaning": "energy sources, energy, juice, power sources, energy resources, the sources of energy, sources of energy",
    "grade": 7
  },
  {
    "id": 8401,
    "traditional": "能耐",
    "simplified": "能耐",
    "reading": "néngnai",
    "meaning": "ability, capability",
    "grade": 7
  },
  {
    "id": 8402,
    "traditional": "泥潭",
    "simplified": "泥潭",
    "reading": "nítán",
    "meaning": "quagmire",
    "grade": 7
  },
  {
    "id": 8403,
    "traditional": "尼龍",
    "simplified": "尼龙",
    "reading": "nílóng",
    "meaning": "nylon (loanword)",
    "grade": 7
  },
  {
    "id": 8404,
    "traditional": "泥土",
    "simplified": "泥土",
    "reading": "nítǔ",
    "meaning": "dirt, muck, earth, marl, soil, soli, clay, clart",
    "grade": 7
  },
  {
    "id": 8405,
    "traditional": "擬定",
    "simplified": "拟定",
    "reading": "nǐdìng",
    "meaning": "formulate, conjecture",
    "grade": 7
  },
  {
    "id": 8406,
    "traditional": "擬",
    "simplified": "拟",
    "reading": "nǐ",
    "meaning": "intend, plan",
    "grade": 7
  },
  {
    "id": 8407,
    "traditional": "匿名",
    "simplified": "匿名",
    "reading": "nìmíng",
    "meaning": "anonymous",
    "grade": 7
  },
  {
    "id": 8408,
    "traditional": "逆",
    "simplified": "逆",
    "reading": "nì",
    "meaning": "contrary, opposite, backwards, to go against, to oppose, to betray, to rebel",
    "grade": 7
  },
  {
    "id": 8409,
    "traditional": "年畫",
    "simplified": "年画",
    "reading": "niánhuà",
    "meaning": "New Year picture, New Year pictures",
    "grade": 7
  },
  {
    "id": 8410,
    "traditional": "年限",
    "simplified": "年限",
    "reading": "niánxiàn",
    "meaning": "age limit, fixed number of years",
    "grade": 7
  },
  {
    "id": 8411,
    "traditional": "年薪",
    "simplified": "年薪",
    "reading": "niánxīn",
    "meaning": "annual salary",
    "grade": 7
  },
  {
    "id": 8412,
    "traditional": "年夜飯",
    "simplified": "年夜饭",
    "reading": "niányèfàn",
    "meaning": "New Year's Eve family dinner",
    "grade": 7
  },
  {
    "id": 8413,
    "traditional": "年邁",
    "simplified": "年迈",
    "reading": "niánmài",
    "meaning": "old, aged",
    "grade": 7
  },
  {
    "id": 8414,
    "traditional": "年終",
    "simplified": "年终",
    "reading": "niánzhōng",
    "meaning": "end of the year",
    "grade": 7
  },
  {
    "id": 8415,
    "traditional": "念念不忘",
    "simplified": "念念不忘",
    "reading": "niànniàn-bùwàng",
    "meaning": "to keep in mind constantly (idiom)",
    "grade": 7
  },
  {
    "id": 8416,
    "traditional": "黏",
    "simplified": "黏",
    "reading": "nián",
    "meaning": "sticky, glutinous, to adhere, to stick",
    "grade": 7
  },
  {
    "id": 8417,
    "traditional": "念書",
    "simplified": "念书",
    "reading": "niàn shū",
    "meaning": "read, study",
    "grade": 7
  },
  {
    "id": 8418,
    "traditional": "念頭",
    "simplified": "念头",
    "reading": "niàntou",
    "meaning": "thing, purport, spirit, intention, idea, vision, notion, thought, train, intent",
    "grade": 7
  },
  {
    "id": 8419,
    "traditional": "釀造",
    "simplified": "酿造",
    "reading": "niàngzào",
    "meaning": "to brew, to make (wine, vinegar, soybean paste etc) by fermentation",
    "grade": 7
  },
  {
    "id": 8420,
    "traditional": "娘",
    "simplified": "娘",
    "reading": "niáng",
    "meaning": "mother, young lady, (coll.) effeminate, variant of 娘[niang2]",
    "grade": 7
  },
  {
    "id": 8421,
    "traditional": "鳥巢",
    "simplified": "鸟巢",
    "reading": "niǎocháo",
    "meaning": "bird's nest, nickname for Beijing 2008 Olympic stadium",
    "grade": 7
  },
  {
    "id": 8422,
    "traditional": "捏",
    "simplified": "捏",
    "reading": "niē",
    "meaning": "to hold between the thumb and fingers, to pinch, to mold (using the fingers), to hold (lit. in one's hand and fig.), to join together, to fabricate (a story, a report, etc), variant of 捏[nie1]",
    "grade": 7
  },
  {
    "id": 8423,
    "traditional": "尿",
    "simplified": "尿",
    "reading": "niào",
    "meaning": "to urinate, urine, CL:泡[pao1], see 尿[niao4]",
    "grade": 7
  },
  {
    "id": 8424,
    "traditional": "凝聚",
    "simplified": "凝聚",
    "reading": "níngjù",
    "meaning": "condense (of vapor), coalesce, concentrate",
    "grade": 7
  },
  {
    "id": 8425,
    "traditional": "凝固",
    "simplified": "凝固",
    "reading": "nínggù",
    "meaning": "to freeze, to solidify, to congeal, fig. with rapt attention",
    "grade": 7
  },
  {
    "id": 8426,
    "traditional": "寧可",
    "simplified": "宁可",
    "reading": "nìngkě",
    "meaning": "(would) rather, better",
    "grade": 7
  },
  {
    "id": 8427,
    "traditional": "擰",
    "simplified": "拧",
    "reading": "níng",
    "meaning": "to pinch, wring, mistake, to twist, stubborn",
    "grade": 7
  },
  {
    "id": 8428,
    "traditional": "寧願",
    "simplified": "宁愿",
    "reading": "nìngyuàn",
    "meaning": "prefer, (would) rather, better, choose",
    "grade": 7
  },
  {
    "id": 8429,
    "traditional": "扭曲",
    "simplified": "扭曲",
    "reading": "niǔqū",
    "meaning": "distort",
    "grade": 7
  },
  {
    "id": 8430,
    "traditional": "扭頭",
    "simplified": "扭头",
    "reading": "niǔ tóu",
    "meaning": "to turn one's head, to turn around",
    "grade": 7
  },
  {
    "id": 8431,
    "traditional": "扭轉",
    "simplified": "扭转",
    "reading": "niǔzhuǎn",
    "meaning": "turn round/back, reverse, remedy (a situation)",
    "grade": 7
  },
  {
    "id": 8432,
    "traditional": "紐帶",
    "simplified": "纽带",
    "reading": "niǔdài",
    "meaning": "tie, link, bond",
    "grade": 7
  },
  {
    "id": 8433,
    "traditional": "紐扣",
    "simplified": "纽扣",
    "reading": "niǔkòu",
    "meaning": "button",
    "grade": 7
  },
  {
    "id": 8434,
    "traditional": "農場",
    "simplified": "农场",
    "reading": "nóngchǎng",
    "meaning": "rancher, pen, farming, wick, ranch, hacienda, grange, farm, homestead, farmstead, plantation, steading, land",
    "grade": 7
  },
  {
    "id": 8435,
    "traditional": "農曆",
    "simplified": "农历",
    "reading": "nónglì",
    "meaning": "farmer's_calendar, lunar calendar, lunar_calendar",
    "grade": 7
  },
  {
    "id": 8436,
    "traditional": "農民工",
    "simplified": "农民工",
    "reading": "nóngmíngōng",
    "meaning": "migrant workers",
    "grade": 7
  },
  {
    "id": 8437,
    "traditional": "濃縮",
    "simplified": "浓缩",
    "reading": "nóngsuō",
    "meaning": "to concentrate (a liquid), concentration, espresso coffee, abbr. for 意式濃縮咖啡|意式浓缩咖啡",
    "grade": 7
  },
  {
    "id": 8438,
    "traditional": "農作物",
    "simplified": "农作物",
    "reading": "nóngzuòwù",
    "meaning": "(farm) crops",
    "grade": 7
  },
  {
    "id": 8439,
    "traditional": "濃郁",
    "simplified": "浓郁",
    "reading": "nóngyù",
    "meaning": "rich, strong, heavy (fragrance), dense, full-bodied, intense",
    "grade": 7
  },
  {
    "id": 8440,
    "traditional": "濃厚",
    "simplified": "浓厚",
    "reading": "nónghòu",
    "meaning": "thick, strong, dense, rich, pronounced",
    "grade": 7
  },
  {
    "id": 8441,
    "traditional": "濃重",
    "simplified": "浓重",
    "reading": "nóngzhòng",
    "meaning": "dense, thick, strong, rich (colors), heavy (aroma), deep (friendship), profound (effect)",
    "grade": 7
  },
  {
    "id": 8442,
    "traditional": "弄虛作假",
    "simplified": "弄虚作假",
    "reading": "nòngxū-zuòjiǎ",
    "meaning": "to practice fraud (idiom), by trickery",
    "grade": 7
  },
  {
    "id": 8443,
    "traditional": "奴隸",
    "simplified": "奴隶",
    "reading": "núlì",
    "meaning": "slave",
    "grade": 7
  },
  {
    "id": 8444,
    "traditional": "暖烘烘",
    "simplified": "暖烘烘",
    "reading": "nuǎnhōnghōng",
    "meaning": "nice and warm, cosy, heartwarming",
    "grade": 7
  },
  {
    "id": 8445,
    "traditional": "虐待",
    "simplified": "虐待",
    "reading": "nüèdài",
    "meaning": "to mistreat, to maltreat, to abuse, mistreatment, maltreatment",
    "grade": 7
  },
  {
    "id": 8446,
    "traditional": "女婿",
    "simplified": "女婿",
    "reading": "nǚxu",
    "meaning": "daughter's husband, son-in-law",
    "grade": 7
  },
  {
    "id": 8447,
    "traditional": "諾言",
    "simplified": "诺言",
    "reading": "nuòyán",
    "meaning": "promise",
    "grade": 7
  },
  {
    "id": 8448,
    "traditional": "哦",
    "simplified": "哦",
    "reading": "ó",
    "meaning": "particle: used when informing sb. of st. (to draw people's attention); indicating mild imperative/suggestion/exclamation/guess/etc.",
    "grade": 7
  },
  {
    "id": 8449,
    "traditional": "挪",
    "simplified": "挪",
    "reading": "nuó",
    "meaning": "to shift, to move",
    "grade": 7
  },
  {
    "id": 8450,
    "traditional": "毆打",
    "simplified": "殴打",
    "reading": "ōudǎ",
    "meaning": "beat up, come to blows",
    "grade": 7
  },
  {
    "id": 8451,
    "traditional": "嘔吐",
    "simplified": "呕吐",
    "reading": "ǒutù",
    "meaning": "heave, barf, sick, puke, gag, vomit, cat, upchuck, reject, spew, vomitive, disgorge, bring_up, be sick, regorge, heave_up, throw up",
    "grade": 7
  },
  {
    "id": 8452,
    "traditional": "拍板",
    "simplified": "拍板",
    "reading": "pāibǎn",
    "meaning": "clapper-board, auctioneer's hammer, to beat time with clappers",
    "grade": 7
  },
  {
    "id": 8453,
    "traditional": "拍賣",
    "simplified": "拍卖",
    "reading": "pāimài",
    "meaning": "to auction, auction sale, to sell at a reduced price",
    "grade": 7
  },
  {
    "id": 8454,
    "traditional": "趴",
    "simplified": "趴",
    "reading": "pā",
    "meaning": "to lie on one's stomach, to lean forward, resting one's upper body (on a desktop etc), (Taiwan) percent",
    "grade": 7
  },
  {
    "id": 8455,
    "traditional": "排放",
    "simplified": "排放",
    "reading": "páifàng",
    "meaning": "discharge, drain off (gas/etc.)",
    "grade": 7
  },
  {
    "id": 8456,
    "traditional": "拍戲",
    "simplified": "拍戏",
    "reading": "pāi xì",
    "meaning": "to shoot a movie",
    "grade": 7
  },
  {
    "id": 8457,
    "traditional": "排練",
    "simplified": "排练",
    "reading": "páiliàn",
    "meaning": "to rehearse, rehearsal",
    "grade": 7
  },
  {
    "id": 8458,
    "traditional": "排斥",
    "simplified": "排斥",
    "reading": "páichì",
    "meaning": "repel, exclude, reject",
    "grade": 7
  },
  {
    "id": 8459,
    "traditional": "徘徊",
    "simplified": "徘徊",
    "reading": "páihuái",
    "meaning": "perambulate, fluctuate, roll, hesitation, mosey, pace up and down, tarry, divagate, peripatetic, roam, stray, vacillate, linger, hover, rove, cast, pace back and forth, cruise, stravage, dally, maunder, wander, loiter, stroll, stooge, waver, drift, on the prowl, range, swan, hang_around, prowl, vagabond, fluctuation, ramble, hesitate, traik, skulk",
    "grade": 7
  },
  {
    "id": 8460,
    "traditional": "派別",
    "simplified": "派别",
    "reading": "pàibié",
    "meaning": "denomination, group, school, faction, school of thought",
    "grade": 7
  },
  {
    "id": 8461,
    "traditional": "牌照",
    "simplified": "牌照",
    "reading": "páizhào",
    "meaning": "(business) licence, vehicle licence, car registration, licence plate",
    "grade": 7
  },
  {
    "id": 8462,
    "traditional": "派遣",
    "simplified": "派遣",
    "reading": "pàiqiǎn",
    "meaning": "to send (on a mission), to dispatch",
    "grade": 7
  },
  {
    "id": 8463,
    "traditional": "攀昇",
    "simplified": "攀升",
    "reading": "pānshēng",
    "meaning": "to clamber up, (of prices etc) to rise",
    "grade": 7
  },
  {
    "id": 8464,
    "traditional": "盤算",
    "simplified": "盘算",
    "reading": "pánsuan",
    "meaning": "to plot, to scheme, to calculate",
    "grade": 7
  },
  {
    "id": 8465,
    "traditional": "盤",
    "simplified": "盘",
    "reading": "pán",
    "meaning": "m.[event]",
    "grade": 7
  },
  {
    "id": 8466,
    "traditional": "判處",
    "simplified": "判处",
    "reading": "pànchǔ",
    "meaning": "to sentence, to condemn",
    "grade": 7
  },
  {
    "id": 8467,
    "traditional": "判定",
    "simplified": "判定",
    "reading": "pàndìng",
    "meaning": "to judge, to decide, judgment, determination",
    "grade": 7
  },
  {
    "id": 8468,
    "traditional": "盼",
    "simplified": "盼",
    "reading": "pàn",
    "meaning": "hope/long for, expect",
    "grade": 7
  },
  {
    "id": 8469,
    "traditional": "判決",
    "simplified": "判决",
    "reading": "pànjué",
    "meaning": "adjudge",
    "grade": 7
  },
  {
    "id": 8470,
    "traditional": "叛逆",
    "simplified": "叛逆",
    "reading": "pànnì",
    "meaning": "to rebel, to revolt, a rebel",
    "grade": 7
  },
  {
    "id": 8471,
    "traditional": "龐大",
    "simplified": "庞大",
    "reading": "pángdà",
    "meaning": "huge, immense",
    "grade": 7
  },
  {
    "id": 8472,
    "traditional": "旁觀",
    "simplified": "旁观",
    "reading": "pángguān",
    "meaning": "spectator, non-participant",
    "grade": 7
  },
  {
    "id": 8473,
    "traditional": "拋",
    "simplified": "抛",
    "reading": "pāo",
    "meaning": "to throw, to toss, to fling, to cast, to abandon",
    "grade": 7
  },
  {
    "id": 8474,
    "traditional": "拋開",
    "simplified": "抛开",
    "reading": "pāokāi",
    "meaning": "to throw out, to get rid of",
    "grade": 7
  },
  {
    "id": 8475,
    "traditional": "刨",
    "simplified": "刨",
    "reading": "páo",
    "meaning": "variant of 刨[bao4], to dig, to excavate, (coll.) to exclude, not to count, to deduct, to subtract",
    "grade": 7
  },
  {
    "id": 8476,
    "traditional": "拋棄",
    "simplified": "抛弃",
    "reading": "pāoqì",
    "meaning": "dispose, cast aside, toss away, toss, lurch, throw out, put away, reject, abandonment, chuck, ditch, jettison, slough, renounce, flee, throw away, give up, desert, cast out, desolate, doff, chuck_out, fling, jilt, forgo, chuck out, discard, abandon, toss out, cast away, forsake",
    "grade": 7
  },
  {
    "id": 8477,
    "traditional": "跑車",
    "simplified": "跑车",
    "reading": "pǎochē",
    "meaning": "racing bicycle, sporting bicycle, sports car, logging truck, on the job (of a train conductor)",
    "grade": 7
  },
  {
    "id": 8478,
    "traditional": "跑道",
    "simplified": "跑道",
    "reading": "pǎodào",
    "meaning": "track, lane, starting-rail, runway, course, chicane, tract, racetrack, racecourse, raceway",
    "grade": 7
  },
  {
    "id": 8479,
    "traditional": "跑龍套",
    "simplified": "跑龙套",
    "reading": "pǎo lóngtào",
    "meaning": "to play a small role",
    "grade": 7
  },
  {
    "id": 8480,
    "traditional": "泡沫",
    "simplified": "泡沫",
    "reading": "pàomò",
    "meaning": "foam, (soap) bubble, (economic) bubble",
    "grade": 7
  },
  {
    "id": 8481,
    "traditional": "陪伴",
    "simplified": "陪伴",
    "reading": "péibàn",
    "meaning": "to accompany",
    "grade": 7
  },
  {
    "id": 8482,
    "traditional": "陪葬",
    "simplified": "陪葬",
    "reading": "péizàng",
    "meaning": "to be buried with or next to dead person (of deceased's partner, or of funerary objects)",
    "grade": 7
  },
  {
    "id": 8483,
    "traditional": "賠錢",
    "simplified": "赔钱",
    "reading": "péi qián",
    "meaning": "to lose money, to pay for damages",
    "grade": 7
  },
  {
    "id": 8484,
    "traditional": "胚胎",
    "simplified": "胚胎",
    "reading": "pēitāi",
    "meaning": "embryo",
    "grade": 7
  },
  {
    "id": 8485,
    "traditional": "佩服",
    "simplified": "佩服",
    "reading": "pèifu",
    "meaning": "admire",
    "grade": 7
  },
  {
    "id": 8486,
    "traditional": "配件",
    "simplified": "配件",
    "reading": "pèijiàn",
    "meaning": "component, part, fitting, accessory, replacement part",
    "grade": 7
  },
  {
    "id": 8487,
    "traditional": "配送",
    "simplified": "配送",
    "reading": "pèisòng",
    "meaning": "distribution, delivery",
    "grade": 7
  },
  {
    "id": 8488,
    "traditional": "配偶",
    "simplified": "配偶",
    "reading": "pèiǒu",
    "meaning": "spouse",
    "grade": 7
  },
  {
    "id": 8489,
    "traditional": "配音",
    "simplified": "配音",
    "reading": "pèi yīn",
    "meaning": "dubbing (filmmaking)",
    "grade": 7
  },
  {
    "id": 8490,
    "traditional": "噴泉",
    "simplified": "喷泉",
    "reading": "pēnquán",
    "meaning": "fountain",
    "grade": 7
  },
  {
    "id": 8491,
    "traditional": "抨擊",
    "simplified": "抨击",
    "reading": "pēngjī",
    "meaning": "attack (in speech/writing), assail",
    "grade": 7
  },
  {
    "id": 8492,
    "traditional": "烹調",
    "simplified": "烹调",
    "reading": "pēngtiáo",
    "meaning": "to cook, cooking",
    "grade": 7
  },
  {
    "id": 8493,
    "traditional": "鵬程萬里",
    "simplified": "鹏程万里",
    "reading": "péngchéng-wànlǐ",
    "meaning": "the fabled roc flies ten thousand miles (idiom), one's future prospects are brilliant",
    "grade": 7
  },
  {
    "id": 8494,
    "traditional": "蓬勃",
    "simplified": "蓬勃",
    "reading": "péngbó",
    "meaning": "full of vitality, vigorous, rising, flourishing",
    "grade": 7
  },
  {
    "id": 8495,
    "traditional": "膨脹",
    "simplified": "膨胀",
    "reading": "péngzhàng",
    "meaning": "expand, swell, inflate",
    "grade": 7
  },
  {
    "id": 8496,
    "traditional": "捧",
    "simplified": "捧",
    "reading": "pěng",
    "meaning": "hold/carry sth. level in both hands, boost, boast, exalt, flatter",
    "grade": 7
  },
  {
    "id": 8497,
    "traditional": "捧場",
    "simplified": "捧场",
    "reading": "pěng chǎng",
    "meaning": "to cheer on (originally esp. as paid stooge), to root for sb, to sing sb's praises, to flatter",
    "grade": 7
  },
  {
    "id": 8498,
    "traditional": "碰釘子",
    "simplified": "碰钉子",
    "reading": "pèng dīngzi",
    "meaning": "to meet with a rebuff",
    "grade": 7
  },
  {
    "id": 8499,
    "traditional": "碰巧",
    "simplified": "碰巧",
    "reading": "pèngqiǎo",
    "meaning": "by chance, by coincidence, to happen to",
    "grade": 7
  },
  {
    "id": 8500,
    "traditional": "碰上",
    "simplified": "碰上",
    "reading": "pèngshàng",
    "meaning": "to run into, to come upon, to meet",
    "grade": 7
  },
  {
    "id": 8501,
    "traditional": "碰撞",
    "simplified": "碰撞",
    "reading": "pèngzhuàng",
    "meaning": "to collide, collision",
    "grade": 7
  },
  {
    "id": 8502,
    "traditional": "批發",
    "simplified": "批发",
    "reading": "pīfā",
    "meaning": "wholesale, bulk trade, distribution",
    "grade": 7
  },
  {
    "id": 8503,
    "traditional": "批判",
    "simplified": "批判",
    "reading": "pīpàn",
    "meaning": "criticize",
    "grade": 7
  },
  {
    "id": 8504,
    "traditional": "劈",
    "simplified": "劈",
    "reading": "pī",
    "meaning": "to hack, to chop, to split open, (of lightning) to strike, to split in two, to divide",
    "grade": 7
  },
  {
    "id": 8505,
    "traditional": "披露",
    "simplified": "披露",
    "reading": "pīlù",
    "meaning": "to reveal, to publish, to make public, to announce",
    "grade": 7
  },
  {
    "id": 8506,
    "traditional": "皮帶",
    "simplified": "皮带",
    "reading": "pídài",
    "meaning": "strap, leather belt, CL:條|条[tiao2],根[gen1]",
    "grade": 7
  },
  {
    "id": 8507,
    "traditional": "疲憊不堪",
    "simplified": "疲惫不堪",
    "reading": "píbèibùkān",
    "meaning": "Exhausted",
    "grade": 7
  },
  {
    "id": 8508,
    "traditional": "疲憊",
    "simplified": "疲惫",
    "reading": "píbèi",
    "meaning": "beaten, exhausted, tired",
    "grade": 7
  },
  {
    "id": 8509,
    "traditional": "疲倦",
    "simplified": "疲倦",
    "reading": "píjuàn",
    "meaning": "to tire, tired",
    "grade": 7
  },
  {
    "id": 8510,
    "traditional": "疲勞",
    "simplified": "疲劳",
    "reading": "píláo",
    "meaning": "fatigue, wearily, weariness, weary",
    "grade": 7
  },
  {
    "id": 8511,
    "traditional": "脾",
    "simplified": "脾",
    "reading": "pí",
    "meaning": "spleen",
    "grade": 7
  },
  {
    "id": 8512,
    "traditional": "匹配",
    "simplified": "匹配",
    "reading": "pǐpèi",
    "meaning": "to mate or marry, to match, matching, compatible",
    "grade": 7
  },
  {
    "id": 8513,
    "traditional": "譬如",
    "simplified": "譬如",
    "reading": "pìrú",
    "meaning": "for example, such as",
    "grade": 7
  },
  {
    "id": 8514,
    "traditional": "媲美",
    "simplified": "媲美",
    "reading": "pìměi",
    "meaning": "to match, is comparable with",
    "grade": 7
  },
  {
    "id": 8515,
    "traditional": "譬如說",
    "simplified": "譬如说",
    "reading": "pìrúshuō",
    "meaning": "for example, such as",
    "grade": 7
  },
  {
    "id": 8516,
    "traditional": "僻靜",
    "simplified": "僻静",
    "reading": "pìjìng",
    "meaning": "lonely, secluded",
    "grade": 7
  },
  {
    "id": 8517,
    "traditional": "偏差",
    "simplified": "偏差",
    "reading": "piānchā",
    "meaning": "deviation, error",
    "grade": 7
  },
  {
    "id": 8518,
    "traditional": "偏方",
    "simplified": "偏方",
    "reading": "piānfāng",
    "meaning": "folk remedy, home remedy",
    "grade": 7
  },
  {
    "id": 8519,
    "traditional": "偏見",
    "simplified": "偏见",
    "reading": "piānjiàn",
    "meaning": "prejudice",
    "grade": 7
  },
  {
    "id": 8520,
    "traditional": "片子",
    "simplified": "片子",
    "reading": "piānzi",
    "meaning": "a roll of film, film, movie, record, album",
    "grade": 7
  },
  {
    "id": 8521,
    "traditional": "偏僻",
    "simplified": "偏僻",
    "reading": "piānpì",
    "meaning": "remote, desolate, far from the city",
    "grade": 7
  },
  {
    "id": 8522,
    "traditional": "偏偏",
    "simplified": "偏偏",
    "reading": "piānpiān",
    "meaning": "just, but, only",
    "grade": 7
  },
  {
    "id": 8523,
    "traditional": "偏向",
    "simplified": "偏向",
    "reading": "piānxiàng",
    "meaning": "partial towards sth, to prefer, to incline, erroneous tendencies (Leftist or Revisionist deviation)",
    "grade": 7
  },
  {
    "id": 8524,
    "traditional": "篇幅",
    "simplified": "篇幅",
    "reading": "piānfu",
    "meaning": "length (of a piece of writing), space occupied on a printed page",
    "grade": 7
  },
  {
    "id": 8525,
    "traditional": "偏遠",
    "simplified": "偏远",
    "reading": "piānyuǎn",
    "meaning": "remote, faraway",
    "grade": 7
  },
  {
    "id": 8526,
    "traditional": "騙人",
    "simplified": "骗人",
    "reading": "piàn rén",
    "meaning": "to cheat sb, a scam",
    "grade": 7
  },
  {
    "id": 8527,
    "traditional": "片段",
    "simplified": "片段",
    "reading": "piànduàn",
    "meaning": "part, extract, fragment",
    "grade": 7
  },
  {
    "id": 8528,
    "traditional": "漂",
    "simplified": "漂",
    "reading": "piào",
    "meaning": "to float, to drift, to bleach, elegant, polished",
    "grade": 7
  },
  {
    "id": 8529,
    "traditional": "飄",
    "simplified": "飘",
    "reading": "piāo",
    "meaning": "blow about, float, blow/drift about, flutter, wave, blow, drift about",
    "grade": 7
  },
  {
    "id": 8530,
    "traditional": "票房",
    "simplified": "票房",
    "reading": "piàofáng",
    "meaning": "box office",
    "grade": 7
  },
  {
    "id": 8531,
    "traditional": "撇",
    "simplified": "撇",
    "reading": "piē",
    "meaning": "to cast away, to fling aside, to throw, to cast, left-slanting downward brush stroke (calligraphy)",
    "grade": 7
  },
  {
    "id": 8532,
    "traditional": "拼搏",
    "simplified": "拼搏",
    "reading": "pīnbó",
    "meaning": "to struggle, to wrestle",
    "grade": 7
  },
  {
    "id": 8533,
    "traditional": "貧富",
    "simplified": "贫富",
    "reading": "pínfù",
    "meaning": "poor and rich",
    "grade": 7
  },
  {
    "id": 8534,
    "traditional": "拼命",
    "simplified": "拼命",
    "reading": "pīn mìng",
    "meaning": "go all out regardless of danger to one's life, fight desperately, exerting the utmost strength, risk life, make death-defying effort, defy death, risk one's life, with all one's might",
    "grade": 7
  },
  {
    "id": 8535,
    "traditional": "貧窮",
    "simplified": "贫穷",
    "reading": "pínqióng",
    "meaning": "poor, needy",
    "grade": 7
  },
  {
    "id": 8536,
    "traditional": "頻頻",
    "simplified": "频频",
    "reading": "pínpín",
    "meaning": "again and again, repeatedly",
    "grade": 7
  },
  {
    "id": 8537,
    "traditional": "品嘗",
    "simplified": "品尝",
    "reading": "pǐncháng",
    "meaning": "to taste a small amount, to sample",
    "grade": 7
  },
  {
    "id": 8538,
    "traditional": "頻率",
    "simplified": "频率",
    "reading": "pínlǜ",
    "meaning": "relative frequency, frequency, periodicity",
    "grade": 7
  },
  {
    "id": 8539,
    "traditional": "品德",
    "simplified": "品德",
    "reading": "pǐndé",
    "meaning": "moral character",
    "grade": 7
  },
  {
    "id": 8540,
    "traditional": "品行",
    "simplified": "品行",
    "reading": "pǐnxíng",
    "meaning": "behavior, moral conduct",
    "grade": 7
  },
  {
    "id": 8541,
    "traditional": "品位",
    "simplified": "品位",
    "reading": "pǐnwèi",
    "meaning": "rank, grade, quality, (aesthetic) taste",
    "grade": 7
  },
  {
    "id": 8542,
    "traditional": "聘用",
    "simplified": "聘用",
    "reading": "pìnyòng",
    "meaning": "to employ, to hire",
    "grade": 7
  },
  {
    "id": 8543,
    "traditional": "乒乓球",
    "simplified": "乒乓球",
    "reading": "pīngpāngqiú",
    "meaning": "table tennis, ping-pong, table tennis ball, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 8544,
    "traditional": "聘",
    "simplified": "聘",
    "reading": "pìn",
    "meaning": "engage, invite",
    "grade": 7
  },
  {
    "id": 8545,
    "traditional": "聘任",
    "simplified": "聘任",
    "reading": "pìnrèn",
    "meaning": "to appoint (to a position), appointed",
    "grade": 7
  },
  {
    "id": 8546,
    "traditional": "平常心",
    "simplified": "平常心",
    "reading": "píngchángxīn",
    "meaning": "Ordinary heart",
    "grade": 7
  },
  {
    "id": 8547,
    "traditional": "平淡",
    "simplified": "平淡",
    "reading": "píngdàn",
    "meaning": "flat, dull, ordinary, nothing special",
    "grade": 7
  },
  {
    "id": 8548,
    "traditional": "平和",
    "simplified": "平和",
    "reading": "pínghé",
    "meaning": "calm, pacify, composed, moderate, gentle",
    "grade": 7
  },
  {
    "id": 8549,
    "traditional": "平價",
    "simplified": "平价",
    "reading": "píngjià",
    "meaning": "reasonably priced, inexpensive, to keep prices down, (currency exchange) parity",
    "grade": 7
  },
  {
    "id": 8550,
    "traditional": "平面",
    "simplified": "平面",
    "reading": "píngmiàn",
    "meaning": "smooth, planar, planeness, plane, tabulate, level, flatness, two-dimensionality, table, sheet, horizontal surface, flat",
    "grade": 7
  },
  {
    "id": 8551,
    "traditional": "平民",
    "simplified": "平民",
    "reading": "píngmín",
    "meaning": "vulgus, ragtag, commonality, third estate, plebe, populace, civvy, commoner, common people, rabble, the populace, multitude, ceorl, citizen, commonage, commonalty, common person, mister, roturier, plebeian, people, plebs, democracy, commons, the common people, mass, pleb, civilian, common man",
    "grade": 7
  },
  {
    "id": 8552,
    "traditional": "平息",
    "simplified": "平息",
    "reading": "píngxī",
    "meaning": "to settle (a dispute), to quieten down, to suppress",
    "grade": 7
  },
  {
    "id": 8553,
    "traditional": "評定",
    "simplified": "评定",
    "reading": "píngdìng",
    "meaning": "to evaluate, to make one's judgment",
    "grade": 7
  },
  {
    "id": 8554,
    "traditional": "評論員",
    "simplified": "评论员",
    "reading": "pínglùnyuán",
    "meaning": "commentator",
    "grade": 7
  },
  {
    "id": 8555,
    "traditional": "平日",
    "simplified": "平日",
    "reading": "píngrì",
    "meaning": "ordinary day, everyday, ordinarily, usually",
    "grade": 7
  },
  {
    "id": 8556,
    "traditional": "評判",
    "simplified": "评判",
    "reading": "píngpàn",
    "meaning": "to judge (a competition), to appraise",
    "grade": 7
  },
  {
    "id": 8557,
    "traditional": "評審",
    "simplified": "评审",
    "reading": "píngshěn",
    "meaning": "judge, referee, umpire",
    "grade": 7
  },
  {
    "id": 8558,
    "traditional": "憑借",
    "simplified": "凭借",
    "reading": "píngjiè",
    "meaning": "to rely on, to depend on, by means of, thanks to, sth that one relies on",
    "grade": 7
  },
  {
    "id": 8559,
    "traditional": "憑著",
    "simplified": "凭着",
    "reading": "píngzhe",
    "meaning": "Depend",
    "grade": 7
  },
  {
    "id": 8560,
    "traditional": "評委",
    "simplified": "评委",
    "reading": "píngwěi",
    "meaning": "evaluation committee, judging panel, judging panel member, adjudicator, abbr. for 評選委員會委員|评选委员会委员[ping2 xuan3 wei3 yuan2 hui4 wei3 yuan2]",
    "grade": 7
  },
  {
    "id": 8561,
    "traditional": "憑證",
    "simplified": "凭证",
    "reading": "píngzhèng",
    "meaning": "proof, certificate, receipt, voucher",
    "grade": 7
  },
  {
    "id": 8562,
    "traditional": "萍水相逢",
    "simplified": "萍水相逢",
    "reading": "píngshuǐ-xiāngféng",
    "meaning": "strangers coming together by chance (idiom)",
    "grade": 7
  },
  {
    "id": 8563,
    "traditional": "瓶頸",
    "simplified": "瓶颈",
    "reading": "píngjǐng",
    "meaning": "bottleneck",
    "grade": 7
  },
  {
    "id": 8564,
    "traditional": "潑冷水",
    "simplified": "泼冷水",
    "reading": "pō lěngshuǐ",
    "meaning": "lit. to pour cold water on, fig. to dampen one's enthusiasm",
    "grade": 7
  },
  {
    "id": 8565,
    "traditional": "迫不及待",
    "simplified": "迫不及待",
    "reading": "pòbùjídài",
    "meaning": "too impatient to wait",
    "grade": 7
  },
  {
    "id": 8566,
    "traditional": "頗",
    "simplified": "颇",
    "reading": "pō",
    "meaning": "pretty, quite an, very, quite, jolly, fearfully, quite a, molto, middling, considerably, greatly",
    "grade": 7
  },
  {
    "id": 8567,
    "traditional": "迫害",
    "simplified": "迫害",
    "reading": "pòhài",
    "meaning": "to persecute, persecution",
    "grade": 7
  },
  {
    "id": 8568,
    "traditional": "破案",
    "simplified": "破案",
    "reading": "pò àn",
    "meaning": "to solve a case, shabby old table",
    "grade": 7
  },
  {
    "id": 8569,
    "traditional": "破除",
    "simplified": "破除",
    "reading": "pòchú",
    "meaning": "to eliminate, to do away with, to get rid of",
    "grade": 7
  },
  {
    "id": 8570,
    "traditional": "破解",
    "simplified": "破解",
    "reading": "pòjiě",
    "meaning": "to break (a bond, constraint etc), to explain, to unravel, to decipher, to decode",
    "grade": 7
  },
  {
    "id": 8571,
    "traditional": "破舊",
    "simplified": "破旧",
    "reading": "pòjiù",
    "meaning": "shabby",
    "grade": 7
  },
  {
    "id": 8572,
    "traditional": "迫使",
    "simplified": "迫使",
    "reading": "pòshǐ",
    "meaning": "distress, obligate, intrude, exact, haze, enforce, feeze, pressure, tie_down, force, compel, enjoin, flog, insist, condemn, oblige, thrust, impel, necessitate, drive, hurl, sell_up, nail_down, make, corner",
    "grade": 7
  },
  {
    "id": 8573,
    "traditional": "破裂",
    "simplified": "破裂",
    "reading": "pòliè",
    "meaning": "split up, flaw, rip, rend, splinter, part, break away, come_apart, chip off, come apart, bust, broken, break up, separate, chip, fracture, fall apart, split, break_down, crack, rupture, break off, come off, check, break, burst",
    "grade": 7
  },
  {
    "id": 8574,
    "traditional": "破碎",
    "simplified": "破碎",
    "reading": "pòsuì",
    "meaning": "to smash to pieces, to shatter",
    "grade": 7
  },
  {
    "id": 8575,
    "traditional": "魄力",
    "simplified": "魄力",
    "reading": "pòlì",
    "meaning": "courage, daring, boldness, resolution, drive",
    "grade": 7
  },
  {
    "id": 8576,
    "traditional": "破滅",
    "simplified": "破灭",
    "reading": "pòmiè",
    "meaning": "to be shattered, to be annihilated (of hope, illusions etc)",
    "grade": 7
  },
  {
    "id": 8577,
    "traditional": "撲面而來",
    "simplified": "扑面而来",
    "reading": "pūmiànérlái",
    "meaning": "lit. sth hits one in the face, directly in one's face, sth assaults the senses, blatant (advertising), eye catching, (a smell) assaults the nostrils",
    "grade": 7
  },
  {
    "id": 8578,
    "traditional": "撲克",
    "simplified": "扑克",
    "reading": "pūkè",
    "meaning": "poker (game) (loanword), deck of playing cards",
    "grade": 7
  },
  {
    "id": 8579,
    "traditional": "鋪路",
    "simplified": "铺路",
    "reading": "pū lù",
    "meaning": "to pave (with paving stones), to lay a road, to give a present to sb to ensure success",
    "grade": 7
  },
  {
    "id": 8580,
    "traditional": "樸實",
    "simplified": "朴实",
    "reading": "pǔshí",
    "meaning": "plain, simple, guileless, down-to-earth, sincere and honest",
    "grade": 7
  },
  {
    "id": 8581,
    "traditional": "菩薩",
    "simplified": "菩萨",
    "reading": "púsà",
    "meaning": "Buddhist idol, a term applied to a kindhearted person, kindhearted person, Bodhisattva, joss, Buddha, Boddhisatva",
    "grade": 7
  },
  {
    "id": 8582,
    "traditional": "樸素",
    "simplified": "朴素",
    "reading": "pǔsù",
    "meaning": "plain and simple, unadorned, simple living, not frivolous",
    "grade": 7
  },
  {
    "id": 8583,
    "traditional": "普通人",
    "simplified": "普通人",
    "reading": "pǔtōngrén",
    "meaning": "ordinary person, private citizen, people, the person in the street",
    "grade": 7
  },
  {
    "id": 8584,
    "traditional": "譜",
    "simplified": "谱",
    "reading": "pǔ",
    "meaning": "musical notation, table, chart, design, guide",
    "grade": 7
  },
  {
    "id": 8585,
    "traditional": "瀑布",
    "simplified": "瀑布",
    "reading": "pùbù",
    "meaning": "waterfall",
    "grade": 7
  },
  {
    "id": 8586,
    "traditional": "七嘴八舌",
    "simplified": "七嘴八舌",
    "reading": "qīzuǐ-bāshé",
    "meaning": "lively discussion with everybody talking at once",
    "grade": 7
  },
  {
    "id": 8587,
    "traditional": "沏",
    "simplified": "沏",
    "reading": "qī",
    "meaning": "to steep (tea)",
    "grade": 7
  },
  {
    "id": 8588,
    "traditional": "凄涼",
    "simplified": "凄凉",
    "reading": "qīliáng",
    "meaning": "desolate (place)",
    "grade": 7
  },
  {
    "id": 8589,
    "traditional": "期盼",
    "simplified": "期盼",
    "reading": "qīpàn",
    "meaning": "look forward to",
    "grade": 7
  },
  {
    "id": 8590,
    "traditional": "欺騙",
    "simplified": "欺骗",
    "reading": "qīpiàn",
    "meaning": "cheat, dupe",
    "grade": 7
  },
  {
    "id": 8591,
    "traditional": "漆",
    "simplified": "漆",
    "reading": "qī",
    "meaning": "paint, lacquer, CL:道[dao4], to paint (furniture, walls etc)",
    "grade": 7
  },
  {
    "id": 8592,
    "traditional": "欺詐",
    "simplified": "欺诈",
    "reading": "qīzhà",
    "meaning": "to cheat",
    "grade": 7
  },
  {
    "id": 8593,
    "traditional": "齊心協力",
    "simplified": "齐心协力",
    "reading": "qíxīn-xiélì",
    "meaning": "to work with a common purpose (idiom), to make concerted efforts, to pull together, to work as one",
    "grade": 7
  },
  {
    "id": 8594,
    "traditional": "其間",
    "simplified": "其间",
    "reading": "qíjiān",
    "meaning": "in between, within that interval, in the meantime",
    "grade": 7
  },
  {
    "id": 8595,
    "traditional": "其後",
    "simplified": "其后",
    "reading": "qíhòu",
    "meaning": "next, later, after that",
    "grade": 7
  },
  {
    "id": 8596,
    "traditional": "奇花異草",
    "simplified": "奇花异草",
    "reading": "qíhuāyìcǎo",
    "meaning": "very rarely seen, unusual (idiom)",
    "grade": 7
  },
  {
    "id": 8597,
    "traditional": "奇跡",
    "simplified": "奇迹",
    "reading": "qíjì",
    "meaning": "miracle, miraculous, wonder, marvel",
    "grade": 7
  },
  {
    "id": 8598,
    "traditional": "歧視",
    "simplified": "歧视",
    "reading": "qíshì",
    "meaning": "treat with bias, despise",
    "grade": 7
  },
  {
    "id": 8599,
    "traditional": "奇特",
    "simplified": "奇特",
    "reading": "qítè",
    "meaning": "unusual, geezer, queer, exotic, peculiar",
    "grade": 7
  },
  {
    "id": 8600,
    "traditional": "祈禱",
    "simplified": "祈祷",
    "reading": "qídǎo",
    "meaning": "pray",
    "grade": 7
  },
  {
    "id": 8601,
    "traditional": "棋",
    "simplified": "棋",
    "reading": "qí",
    "meaning": "chess, chess-like game, a game of chess, variant of 棋[qi2]",
    "grade": 7
  },
  {
    "id": 8602,
    "traditional": "棋子",
    "simplified": "棋子",
    "reading": "qízǐ",
    "meaning": "chess piece, CL:個|个[ge4],顆|颗[ke1]",
    "grade": 7
  },
  {
    "id": 8603,
    "traditional": "旗袍",
    "simplified": "旗袍",
    "reading": "qípáo",
    "meaning": "Chinese-style dress, cheongsam",
    "grade": 7
  },
  {
    "id": 8604,
    "traditional": "旗幟",
    "simplified": "旗帜",
    "reading": "qízhì",
    "meaning": "flag, stand, banner, colors, standard, colours, pennon, ensign, streamer, national flag, rallying point, signal flag",
    "grade": 7
  },
  {
    "id": 8605,
    "traditional": "乞丐",
    "simplified": "乞丐",
    "reading": "qǐgài",
    "meaning": "beggar",
    "grade": 7
  },
  {
    "id": 8606,
    "traditional": "乞討",
    "simplified": "乞讨",
    "reading": "qǐtǎo",
    "meaning": "to beg, to go begging",
    "grade": 7
  },
  {
    "id": 8607,
    "traditional": "豈有此理",
    "simplified": "岂有此理",
    "reading": "qǐyǒu-cǐlǐ",
    "meaning": "how can this be so? (idiom), preposterous, ridiculous, absurd",
    "grade": 7
  },
  {
    "id": 8608,
    "traditional": "乞求",
    "simplified": "乞求",
    "reading": "qǐqiú",
    "meaning": "to beg",
    "grade": 7
  },
  {
    "id": 8609,
    "traditional": "啟蒙",
    "simplified": "启蒙",
    "reading": "qǐ méng",
    "meaning": "to instruct the young, to initiate, to awake sb from ignorance, to free sb from prejudice or superstition, primer, enlightened, the Enlightenment, Western learning from the late Qing dynasty",
    "grade": 7
  },
  {
    "id": 8610,
    "traditional": "啟迪",
    "simplified": "启迪",
    "reading": "qǐdí",
    "meaning": "to edify, enlightenment",
    "grade": 7
  },
  {
    "id": 8611,
    "traditional": "啟示",
    "simplified": "启示",
    "reading": "qǐshì",
    "meaning": "apocalypse, illumination, revelation",
    "grade": 7
  },
  {
    "id": 8612,
    "traditional": "起步",
    "simplified": "起步",
    "reading": "qǐ bù",
    "meaning": "start (a task)",
    "grade": 7
  },
  {
    "id": 8613,
    "traditional": "起草",
    "simplified": "起草",
    "reading": "qǐ cǎo",
    "meaning": "to make a draft, to draw up (plans)",
    "grade": 7
  },
  {
    "id": 8614,
    "traditional": "起程",
    "simplified": "起程",
    "reading": "qǐ chéng",
    "meaning": "to set out, to leave",
    "grade": 7
  },
  {
    "id": 8615,
    "traditional": "起初",
    "simplified": "起初",
    "reading": "qǐchū",
    "meaning": "initio, ab initio, first, primarily, early, in_the_beginning, originally, at_first, in the beginning, initially, at the start, at first, early on",
    "grade": 7
  },
  {
    "id": 8616,
    "traditional": "起勁",
    "simplified": "起劲",
    "reading": "qǐjìn",
    "meaning": "vigorously, energetically, enthusiastically",
    "grade": 7
  },
  {
    "id": 8617,
    "traditional": "起跑線",
    "simplified": "起跑线",
    "reading": "qǐpǎoxiàn",
    "meaning": "the starting line (of a race), scratch line (in a relay race)",
    "grade": 7
  },
  {
    "id": 8618,
    "traditional": "起源",
    "simplified": "起源",
    "reading": "qǐyuán",
    "meaning": "origin",
    "grade": 7
  },
  {
    "id": 8619,
    "traditional": "起伏",
    "simplified": "起伏",
    "reading": "qǐfú",
    "meaning": "rise and fall, undulate",
    "grade": 7
  },
  {
    "id": 8620,
    "traditional": "氣憤",
    "simplified": "气愤",
    "reading": "qìfèn",
    "meaning": "indignant, furious",
    "grade": 7
  },
  {
    "id": 8621,
    "traditional": "氣管",
    "simplified": "气管",
    "reading": "qìguǎn",
    "meaning": "windpipe, trachea, respiratory tract, air duct, gas pipe",
    "grade": 7
  },
  {
    "id": 8622,
    "traditional": "氣餒",
    "simplified": "气馁",
    "reading": "qìněi",
    "meaning": "to be discouraged",
    "grade": 7
  },
  {
    "id": 8623,
    "traditional": "氣泡",
    "simplified": "气泡",
    "reading": "qìpào",
    "meaning": "bubble, blister (in metal), (of beverages) sparkling, carbonated",
    "grade": 7
  },
  {
    "id": 8624,
    "traditional": "氣派",
    "simplified": "气派",
    "reading": "qìpài",
    "meaning": "imposing manner or style",
    "grade": 7
  },
  {
    "id": 8625,
    "traditional": "氣勢",
    "simplified": "气势",
    "reading": "qìshì",
    "meaning": "air, powder, imposing manner, aura, manner, pith, vigor, atmosphere, vigour, verve, vehemence, fervor, momentum",
    "grade": 7
  },
  {
    "id": 8626,
    "traditional": "氣味",
    "simplified": "气味",
    "reading": "qìwèi",
    "meaning": "tang, wind, odor, savor, aurae, tint, flavor, leavening, savour, flavour, leaven, odour, aura, smatch, tinge, smell, smack, scent, taste",
    "grade": 7
  },
  {
    "id": 8627,
    "traditional": "氣息",
    "simplified": "气息",
    "reading": "qìxī",
    "meaning": "breath, flatus, look, feeling, spirit, smell, snuff, feel, breathing, flavor, tone, taste, flavour",
    "grade": 7
  },
  {
    "id": 8628,
    "traditional": "氣魄",
    "simplified": "气魄",
    "reading": "qìpò",
    "meaning": "spirit, boldness, positive outlook, imposing attitude",
    "grade": 7
  },
  {
    "id": 8629,
    "traditional": "氣質",
    "simplified": "气质",
    "reading": "qìzhì",
    "meaning": "kidney, fibre, charisma, crasis, mettle, humor, temper, predisposition, habit, mould, colour, class, streak, metal, temperament, disposition, strain, habitude, sensitivity, blood, proclivity, karma, gaseity, grain, makings, presence",
    "grade": 7
  },
  {
    "id": 8630,
    "traditional": "迄今為止",
    "simplified": "迄今为止",
    "reading": "qìjīnwéizhǐ",
    "meaning": "so far, up to now, still (not)",
    "grade": 7
  },
  {
    "id": 8631,
    "traditional": "契約",
    "simplified": "契约",
    "reading": "qìyuē",
    "meaning": "legal instrument, charter, bond, bargain, covenant, legal document, compact, stipulation, agreement, instrument, bargaining, pact, obligation, clause, paction, booking, affiance, indenture, promise, article, contract, concordat, official document, deed, handfast",
    "grade": 7
  },
  {
    "id": 8632,
    "traditional": "契機",
    "simplified": "契机",
    "reading": "qìjī",
    "meaning": "moment, critical point, turning, turning point, juncture, moment o turning point, turning/critical point, momentum",
    "grade": 7
  },
  {
    "id": 8633,
    "traditional": "迄今",
    "simplified": "迄今",
    "reading": "qìjīn",
    "meaning": "so far, to date, until now",
    "grade": 7
  },
  {
    "id": 8634,
    "traditional": "器材",
    "simplified": "器材",
    "reading": "qìcái",
    "meaning": "materials, materiel, equipment",
    "grade": 7
  },
  {
    "id": 8635,
    "traditional": "器械",
    "simplified": "器械",
    "reading": "qìxiè",
    "meaning": "apparatus, instrument, equipment, weapon",
    "grade": 7
  },
  {
    "id": 8636,
    "traditional": "卡",
    "simplified": "卡",
    "reading": "kǎ",
    "meaning": "to stop, to block, card, CL:張|张[zhang1],片[pian4], calorie, cassette, (computing) (coll.) slow, to block, to be stuck, to be wedged, customs station, a clip, a fastener, a checkpost, Taiwan pr. [ka3]",
    "grade": 7
  },
  {
    "id": 8637,
    "traditional": "掐",
    "simplified": "掐",
    "reading": "qiā",
    "meaning": "to pick (flowers), to pinch, to nip, to pinch off, to clutch, (slang) to fight",
    "grade": 7
  },
  {
    "id": 8638,
    "traditional": "洽談",
    "simplified": "洽谈",
    "reading": "qiàtán",
    "meaning": "hold trade talk, negotiate",
    "grade": 7
  },
  {
    "id": 8639,
    "traditional": "恰到好處",
    "simplified": "恰到好处",
    "reading": "qiàdào-hǎochù",
    "meaning": "it's just perfect, it's just right",
    "grade": 7
  },
  {
    "id": 8640,
    "traditional": "卡子",
    "simplified": "卡子",
    "reading": "qiǎzi",
    "meaning": "clip, hair fastener, checkpoint",
    "grade": 7
  },
  {
    "id": 8641,
    "traditional": "恰恰相反",
    "simplified": "恰恰相反",
    "reading": "qiàqiàxiāngfǎn",
    "meaning": "Contrary",
    "grade": 7
  },
  {
    "id": 8642,
    "traditional": "恰如其分",
    "simplified": "恰如其分",
    "reading": "qiàrú-qífèn",
    "meaning": "to say or do sth appropriate (idiom)",
    "grade": 7
  },
  {
    "id": 8643,
    "traditional": "恰巧",
    "simplified": "恰巧",
    "reading": "qiàqiǎo",
    "meaning": "fortunately, unexpectedly, by coincidence",
    "grade": 7
  },
  {
    "id": 8644,
    "traditional": "千變萬化",
    "simplified": "千变万化",
    "reading": "qiānbiàn-wànhuà",
    "meaning": "countless changes, constant permutation",
    "grade": 7
  },
  {
    "id": 8645,
    "traditional": "千家萬戶",
    "simplified": "千家万户",
    "reading": "qiānjiā-wànhù",
    "meaning": "every family (idiom)",
    "grade": 7
  },
  {
    "id": 8646,
    "traditional": "千方百計",
    "simplified": "千方百计",
    "reading": "qiānfāng-bǎijì",
    "meaning": "lit. thousand ways, a hundred plans (idiom), by every possible means",
    "grade": 7
  },
  {
    "id": 8647,
    "traditional": "千鈞一髮",
    "simplified": "千钧一发",
    "reading": "qiānjūn-yīfà",
    "meaning": "a thousand pounds hangs by a thread (idiom), imminent peril, a matter of life or death",
    "grade": 7
  },
  {
    "id": 8648,
    "traditional": "千軍萬馬",
    "simplified": "千军万马",
    "reading": "qiānjūn-wànmǎ",
    "meaning": "magnificent army with thousands of men and horses (idiom), impressive display of manpower, all the King's horses and all the King's men",
    "grade": 7
  },
  {
    "id": 8649,
    "traditional": "遷就",
    "simplified": "迁就",
    "reading": "qiānjiù",
    "meaning": "to yield, to adapt to, to accommodate to (sth)",
    "grade": 7
  },
  {
    "id": 8650,
    "traditional": "遷",
    "simplified": "迁",
    "reading": "qiān",
    "meaning": "movement, change, move",
    "grade": 7
  },
  {
    "id": 8651,
    "traditional": "遷移",
    "simplified": "迁移",
    "reading": "qiānyí",
    "meaning": "move, remove, migrate",
    "grade": 7
  },
  {
    "id": 8652,
    "traditional": "牽扯",
    "simplified": "牵扯",
    "reading": "qiānchě",
    "meaning": "to involve, to implicate",
    "grade": 7
  },
  {
    "id": 8653,
    "traditional": "牽涉",
    "simplified": "牵涉",
    "reading": "qiānshè",
    "meaning": "involve, drag in",
    "grade": 7
  },
  {
    "id": 8654,
    "traditional": "牽掛",
    "simplified": "牵挂",
    "reading": "qiānguà",
    "meaning": "to worry about, to be concerned about",
    "grade": 7
  },
  {
    "id": 8655,
    "traditional": "牽頭",
    "simplified": "牵头",
    "reading": "qiān tóu",
    "meaning": "to lead (an animal by the head), to take the lead, to coordinate (a combined operation), to mediate, a go-between (e.g. marriage broker)",
    "grade": 7
  },
  {
    "id": 8656,
    "traditional": "鉛",
    "simplified": "铅",
    "reading": "qiān",
    "meaning": "lead (chemistry)",
    "grade": 7
  },
  {
    "id": 8657,
    "traditional": "牽制",
    "simplified": "牵制",
    "reading": "qiānzhì",
    "meaning": "to control, to curb, to restrict, to impede, to pin down (enemy troops)",
    "grade": 7
  },
  {
    "id": 8658,
    "traditional": "謙遜",
    "simplified": "谦逊",
    "reading": "qiānxùn",
    "meaning": "humble, modest, unpretentious, modesty",
    "grade": 7
  },
  {
    "id": 8659,
    "traditional": "簽署",
    "simplified": "签署",
    "reading": "qiānshǔ",
    "meaning": "sign/initial (a document)",
    "grade": 7
  },
  {
    "id": 8660,
    "traditional": "前輩",
    "simplified": "前辈",
    "reading": "qiánbèi",
    "meaning": "senior, older generation, precursor",
    "grade": 7
  },
  {
    "id": 8661,
    "traditional": "前不久",
    "simplified": "前不久",
    "reading": "qiánbùjiǔ",
    "meaning": "not long ago, not long before",
    "grade": 7
  },
  {
    "id": 8662,
    "traditional": "前赴後繼",
    "simplified": "前赴后继",
    "reading": "qiánfù-hòujì",
    "meaning": "to advance dauntlessly in wave upon wave (idiom)",
    "grade": 7
  },
  {
    "id": 8663,
    "traditional": "簽",
    "simplified": "签",
    "reading": "qiān",
    "meaning": "sign, autograph",
    "grade": 7
  },
  {
    "id": 8664,
    "traditional": "前期",
    "simplified": "前期",
    "reading": "qiánqī",
    "meaning": "preceding period, early stage",
    "grade": 7
  },
  {
    "id": 8665,
    "traditional": "前所未有",
    "simplified": "前所未有",
    "reading": "qiánsuǒwèiyǒu",
    "meaning": "unprecedented",
    "grade": 7
  },
  {
    "id": 8666,
    "traditional": "前任",
    "simplified": "前任",
    "reading": "qiánrèn",
    "meaning": "predecessor",
    "grade": 7
  },
  {
    "id": 8667,
    "traditional": "前無古人",
    "simplified": "前无古人",
    "reading": "qiánwúgǔrén",
    "meaning": "Before no ancient people",
    "grade": 7
  },
  {
    "id": 8668,
    "traditional": "前夕",
    "simplified": "前夕",
    "reading": "qiánxī",
    "meaning": "eve, the day before",
    "grade": 7
  },
  {
    "id": 8669,
    "traditional": "前臺",
    "simplified": "前台",
    "reading": "qiántái",
    "meaning": "stage, proscenium, foreground in politics etc (sometimes derog.), front desk, reception desk, (computing) front-end, foreground",
    "grade": 7
  },
  {
    "id": 8670,
    "traditional": "前線",
    "simplified": "前线",
    "reading": "qiánxiàn",
    "meaning": "front line, military front, workface, cutting edge",
    "grade": 7
  },
  {
    "id": 8671,
    "traditional": "前仰後合",
    "simplified": "前仰后合",
    "reading": "qiányǎng-hòuhé",
    "meaning": "to sway to and fro, to rock back and forth",
    "grade": 7
  },
  {
    "id": 8672,
    "traditional": "前者",
    "simplified": "前者",
    "reading": "qiánzhě",
    "meaning": "predecessor, the former, former",
    "grade": 7
  },
  {
    "id": 8673,
    "traditional": "前沿",
    "simplified": "前沿",
    "reading": "qiányán",
    "meaning": "front-line, forward position, outpost, extending ahead, frontier (of science, technology etc)",
    "grade": 7
  },
  {
    "id": 8674,
    "traditional": "虔誠",
    "simplified": "虔诚",
    "reading": "qiánchéng",
    "meaning": "pious, devout, sincere",
    "grade": 7
  },
  {
    "id": 8675,
    "traditional": "鉗子",
    "simplified": "钳子",
    "reading": "qiánzi",
    "meaning": "pliers, pincers, tongs, forceps, vise, clamp, claw (of a crab etc), CL:把[ba3], (dialect) earring",
    "grade": 7
  },
  {
    "id": 8676,
    "traditional": "錢財",
    "simplified": "钱财",
    "reading": "qiáncái",
    "meaning": "wealth, money",
    "grade": 7
  },
  {
    "id": 8677,
    "traditional": "潛能",
    "simplified": "潜能",
    "reading": "qiánnéng",
    "meaning": "latent, possibility, latent energy, potency, ability, latent ability, ergal, potentiality, latent energy/ability, potential",
    "grade": 7
  },
  {
    "id": 8678,
    "traditional": "潛艇",
    "simplified": "潜艇",
    "reading": "qiántǐng",
    "meaning": "submarine",
    "grade": 7
  },
  {
    "id": 8679,
    "traditional": "潛移默化",
    "simplified": "潜移默化",
    "reading": "qiányí-mòhuà",
    "meaning": "imperceptible influence, to influence secretly",
    "grade": 7
  },
  {
    "id": 8680,
    "traditional": "潛在",
    "simplified": "潜在",
    "reading": "qiánzài",
    "meaning": "potential, latent",
    "grade": 7
  },
  {
    "id": 8681,
    "traditional": "潛水",
    "simplified": "潜水",
    "reading": "qiánshuǐ",
    "meaning": "dive, go under water",
    "grade": 7
  },
  {
    "id": 8682,
    "traditional": "譴責",
    "simplified": "谴责",
    "reading": "qiǎnzé",
    "meaning": "condemn, denounce, censure",
    "grade": 7
  },
  {
    "id": 8683,
    "traditional": "欠缺",
    "simplified": "欠缺",
    "reading": "qiànquē",
    "meaning": "lack, be deficient, be short of",
    "grade": 7
  },
  {
    "id": 8684,
    "traditional": "欠條",
    "simplified": "欠条",
    "reading": "qiàntiáo",
    "meaning": "IOU, certificate of indebtedness",
    "grade": 7
  },
  {
    "id": 8685,
    "traditional": "嗆",
    "simplified": "呛",
    "reading": "qiāng",
    "meaning": "to choke (because of swallowing the wrong way), to irritate the nose, to choke (of smoke, smell etc), pungent, (coll.) (Tw) to shout at sb, to scold, to speak out against sb",
    "grade": 7
  },
  {
    "id": 8686,
    "traditional": "歉意",
    "simplified": "歉意",
    "reading": "qiànyì",
    "meaning": "apology, regret",
    "grade": 7
  },
  {
    "id": 8687,
    "traditional": "槍斃",
    "simplified": "枪毙",
    "reading": "qiāngbì",
    "meaning": "to execute by firing squad, to shoot dead, fig. to discard, to get rid of",
    "grade": 7
  },
  {
    "id": 8688,
    "traditional": "強加",
    "simplified": "强加",
    "reading": "qiángjiā",
    "meaning": "to impose, to force upon",
    "grade": 7
  },
  {
    "id": 8689,
    "traditional": "強勁",
    "simplified": "强劲",
    "reading": "qiángjìng",
    "meaning": "strong, forceful, sturdy, powerful",
    "grade": 7
  },
  {
    "id": 8690,
    "traditional": "強項",
    "simplified": "强项",
    "reading": "qiángxiàng",
    "meaning": "key strength, strong suit, specialty",
    "grade": 7
  },
  {
    "id": 8691,
    "traditional": "強硬",
    "simplified": "强硬",
    "reading": "qiángyìng",
    "meaning": "tough, unyielding, hard-line",
    "grade": 7
  },
  {
    "id": 8692,
    "traditional": "強行",
    "simplified": "强行",
    "reading": "qiángxíng",
    "meaning": "force",
    "grade": 7
  },
  {
    "id": 8693,
    "traditional": "腔",
    "simplified": "腔",
    "reading": "qiāng",
    "meaning": "cavity of body, barrel (e.g. engine cylinder), compartment, tune, accent of speech",
    "grade": 7
  },
  {
    "id": 8694,
    "traditional": "強占",
    "simplified": "强占",
    "reading": "qiángzhàn",
    "meaning": "to occupy by force",
    "grade": 7
  },
  {
    "id": 8695,
    "traditional": "強制",
    "simplified": "强制",
    "reading": "qiángzhì",
    "meaning": "to enforce, enforcement, forcibly, compulsory",
    "grade": 7
  },
  {
    "id": 8696,
    "traditional": "搶奪",
    "simplified": "抢夺",
    "reading": "qiǎngduó",
    "meaning": "to plunder, to pillage, to forcibly take",
    "grade": 7
  },
  {
    "id": 8697,
    "traditional": "搶眼",
    "simplified": "抢眼",
    "reading": "qiǎngyǎn",
    "meaning": "eye-catching",
    "grade": 7
  },
  {
    "id": 8698,
    "traditional": "敲邊鼓",
    "simplified": "敲边鼓",
    "reading": "qiāo biāngǔ",
    "meaning": "to back sb up, to support sb in an argument, (lit. to beat nearby drum)",
    "grade": 7
  },
  {
    "id": 8699,
    "traditional": "搶劫",
    "simplified": "抢劫",
    "reading": "qiǎngjié",
    "meaning": "to rob, looting",
    "grade": 7
  },
  {
    "id": 8700,
    "traditional": "敲詐",
    "simplified": "敲诈",
    "reading": "qiāozhà",
    "meaning": "to rip off, to extort (money), extortion, blackmail",
    "grade": 7
  },
  {
    "id": 8701,
    "traditional": "喬裝",
    "simplified": "乔装",
    "reading": "qiáozhuāng",
    "meaning": "to pretend, to feign, to disguise oneself",
    "grade": 7
  },
  {
    "id": 8702,
    "traditional": "瞧不起",
    "simplified": "瞧不起",
    "reading": "qiáobuqǐ",
    "meaning": "to look down upon, to hold in contempt",
    "grade": 7
  },
  {
    "id": 8703,
    "traditional": "竅門",
    "simplified": "窍门",
    "reading": "qiàomén",
    "meaning": "a trick, an ingenious method, know-how, the knack (of doing sth)",
    "grade": 7
  },
  {
    "id": 8704,
    "traditional": "巧合",
    "simplified": "巧合",
    "reading": "qiǎohé",
    "meaning": "coincidence, coincidental, to coincide",
    "grade": 7
  },
  {
    "id": 8705,
    "traditional": "翹",
    "simplified": "翘",
    "reading": "qiào",
    "meaning": "outstanding, to raise, to stick up, to rise on one end, to tilt",
    "grade": 7
  },
  {
    "id": 8706,
    "traditional": "切除",
    "simplified": "切除",
    "reading": "qiēchú",
    "meaning": "excise, resect",
    "grade": 7
  },
  {
    "id": 8707,
    "traditional": "切斷",
    "simplified": "切断",
    "reading": "qiē duàn",
    "meaning": "sever, shut_off, amputate, disconnect, cut_off, joint, cut off, mutilate, cutting_off, lop, hang_up, interrupt, hang up, guillotine",
    "grade": 7
  },
  {
    "id": 8708,
    "traditional": "切割",
    "simplified": "切割",
    "reading": "qiēgē",
    "meaning": "to cut",
    "grade": 7
  },
  {
    "id": 8709,
    "traditional": "撬",
    "simplified": "撬",
    "reading": "qiào",
    "meaning": "to lift, to pry open, to lever open",
    "grade": 7
  },
  {
    "id": 8710,
    "traditional": "且",
    "simplified": "且",
    "reading": "qiě",
    "meaning": "conj.:",
    "grade": 7
  },
  {
    "id": 8711,
    "traditional": "切身",
    "simplified": "切身",
    "reading": "qièshēn",
    "meaning": "direct, concerning oneself, personal",
    "grade": 7
  },
  {
    "id": 8712,
    "traditional": "竊取",
    "simplified": "窃取",
    "reading": "qièqǔ",
    "meaning": "to steal, to seize",
    "grade": 7
  },
  {
    "id": 8713,
    "traditional": "欽佩",
    "simplified": "钦佩",
    "reading": "qīnpèi",
    "meaning": "to admire, to look up to, to respect sb greatly",
    "grade": 7
  },
  {
    "id": 8714,
    "traditional": "侵害",
    "simplified": "侵害",
    "reading": "qīnhài",
    "meaning": "encroach on (other's rights), violate",
    "grade": 7
  },
  {
    "id": 8715,
    "traditional": "侵略",
    "simplified": "侵略",
    "reading": "qīnlüè",
    "meaning": "invade",
    "grade": 7
  },
  {
    "id": 8716,
    "traditional": "侵占",
    "simplified": "侵占",
    "reading": "qīnzhàn",
    "meaning": "to invade and occupy (territory)",
    "grade": 7
  },
  {
    "id": 8717,
    "traditional": "親和力",
    "simplified": "亲和力",
    "reading": "qīnhélì",
    "meaning": "(personal) warmth, approachability, accessibility, (in a product) user friendliness, (chemistry) affinity",
    "grade": 7
  },
  {
    "id": 8718,
    "traditional": "侵權",
    "simplified": "侵权",
    "reading": "qīnquán",
    "meaning": "to infringe the rights of, to violate, infringement",
    "grade": 7
  },
  {
    "id": 8719,
    "traditional": "親朋好友",
    "simplified": "亲朋好友",
    "reading": "qīnpénghǎoyǒu",
    "meaning": "friends and family, kith and kin",
    "grade": 7
  },
  {
    "id": 8720,
    "traditional": "親近",
    "simplified": "亲近",
    "reading": "qīnjìn",
    "meaning": "hobnob, hang out, affiliate, close, be close to, know, be on intimate terms with, intimate",
    "grade": 7
  },
  {
    "id": 8721,
    "traditional": "親戚",
    "simplified": "亲戚",
    "reading": "qīnqi",
    "meaning": "belongings, relation, kinswoman, kinsman, kith, kindred, cousinry, sib, ally, relative, relatives, kin, connection, kinsfolk, cousin",
    "grade": 7
  },
  {
    "id": 8722,
    "traditional": "親情",
    "simplified": "亲情",
    "reading": "qīnqíng",
    "meaning": "affection, family love, love, esp. within a married couple or between parents and children",
    "grade": 7
  },
  {
    "id": 8723,
    "traditional": "親熱",
    "simplified": "亲热",
    "reading": "qīnrè",
    "meaning": "affectionate, intimate, warmhearted, to get intimate with sb",
    "grade": 7
  },
  {
    "id": 8724,
    "traditional": "親身",
    "simplified": "亲身",
    "reading": "qīnshēn",
    "meaning": "in person, personally, first-hand",
    "grade": 7
  },
  {
    "id": 8725,
    "traditional": "親手",
    "simplified": "亲手",
    "reading": "qīnshǒu",
    "meaning": "personally, with one's own hands",
    "grade": 7
  },
  {
    "id": 8726,
    "traditional": "親生",
    "simplified": "亲生",
    "reading": "qīnshēng",
    "meaning": "one's own (child) (i.e. one's child by birth), biological (parents), birth (parents)",
    "grade": 7
  },
  {
    "id": 8727,
    "traditional": "親友",
    "simplified": "亲友",
    "reading": "qīnyǒu",
    "meaning": "gossip, kith, hail-fellow, relatives and friends, kith and kin, sidekick, familiar, inseparable",
    "grade": 7
  },
  {
    "id": 8728,
    "traditional": "勤工儉學",
    "simplified": "勤工俭学",
    "reading": "qíngōng-jiǎnxué",
    "meaning": "to work part time while studying, work-study program",
    "grade": 7
  },
  {
    "id": 8729,
    "traditional": "勤勞",
    "simplified": "勤劳",
    "reading": "qínláo",
    "meaning": "hardworking, ply, industrious",
    "grade": 7
  },
  {
    "id": 8730,
    "traditional": "勤快",
    "simplified": "勤快",
    "reading": "qínkuai",
    "meaning": "diligent, hardworking",
    "grade": 7
  },
  {
    "id": 8731,
    "traditional": "寢室",
    "simplified": "寝室",
    "reading": "qǐnshì",
    "meaning": "bedchamber, sleeping room, roost, chamber, dormitory, bower, lying, bedroom",
    "grade": 7
  },
  {
    "id": 8732,
    "traditional": "青春期",
    "simplified": "青春期",
    "reading": "qīngchūnqī",
    "meaning": "puberty, adolescence",
    "grade": 7
  },
  {
    "id": 8733,
    "traditional": "青蛙",
    "simplified": "青蛙",
    "reading": "qīngwā",
    "meaning": "frog, CL:隻|只[zhi1], (slang) ugly guy",
    "grade": 7
  },
  {
    "id": 8734,
    "traditional": "輕而易舉",
    "simplified": "轻而易举",
    "reading": "qīngéryìjǔ",
    "meaning": "easy, with no difficulty",
    "grade": 7
  },
  {
    "id": 8735,
    "traditional": "輕蔑",
    "simplified": "轻蔑",
    "reading": "qīngmiè",
    "meaning": "to contempt, to disdain, pejorative",
    "grade": 7
  },
  {
    "id": 8736,
    "traditional": "輕型",
    "simplified": "轻型",
    "reading": "qīngxíng",
    "meaning": "light (machinery, aircraft etc)",
    "grade": 7
  },
  {
    "id": 8737,
    "traditional": "輕微",
    "simplified": "轻微",
    "reading": "qīngwēi",
    "meaning": "light, trifling, trivial",
    "grade": 7
  },
  {
    "id": 8738,
    "traditional": "傾家蕩產",
    "simplified": "倾家荡产",
    "reading": "qīngjiā-dàngchǎn",
    "meaning": "to lose a family fortune (idiom)",
    "grade": 7
  },
  {
    "id": 8739,
    "traditional": "傾聽",
    "simplified": "倾听",
    "reading": "qīngtīng",
    "meaning": "listen attentively to",
    "grade": 7
  },
  {
    "id": 8740,
    "traditional": "傾銷",
    "simplified": "倾销",
    "reading": "qīngxiāo",
    "meaning": "to dump (goods, products)",
    "grade": 7
  },
  {
    "id": 8741,
    "traditional": "傾訴",
    "simplified": "倾诉",
    "reading": "qīngsù",
    "meaning": "to say everything (that is on one's mind)",
    "grade": 7
  },
  {
    "id": 8742,
    "traditional": "傾斜",
    "simplified": "倾斜",
    "reading": "qīngxié",
    "meaning": "to incline, to lean, to slant, to slope, to tilt",
    "grade": 7
  },
  {
    "id": 8743,
    "traditional": "清單",
    "simplified": "清单",
    "reading": "qīngdān",
    "meaning": "list of items",
    "grade": 7
  },
  {
    "id": 8744,
    "traditional": "清除",
    "simplified": "清除",
    "reading": "qīngchú",
    "meaning": "eliminate, get rid of",
    "grade": 7
  },
  {
    "id": 8745,
    "traditional": "清脆",
    "simplified": "清脆",
    "reading": "qīngcuì",
    "meaning": "sharp and clear, crisp, melodious, ringing, tinkling, silvery (of sound), fragile, frail, also written 輕脆|轻脆",
    "grade": 7
  },
  {
    "id": 8746,
    "traditional": "清靜",
    "simplified": "清静",
    "reading": "qīngjìng",
    "meaning": "quiet, peaceful and quiet",
    "grade": 7
  },
  {
    "id": 8747,
    "traditional": "清涼",
    "simplified": "清凉",
    "reading": "qīngliáng",
    "meaning": "fresh and cool, refreshing",
    "grade": 7
  },
  {
    "id": 8748,
    "traditional": "清淡",
    "simplified": "清淡",
    "reading": "qīngdàn",
    "meaning": "light (of food, not greasy or strongly flavored), insipid, slack (sales)",
    "grade": 7
  },
  {
    "id": 8749,
    "traditional": "清明",
    "simplified": "清明",
    "reading": "qīngmíng",
    "meaning": "Qingming or Pure Brightness, 5th of the 24 solar terms 二十四節氣|二十四节气[er4 shi2 si4 jie2 qi5] 5th-19th April, Pure Brightness Festival or Tomb Sweeping Day (in early April), clear and bright, sober and calm, (of a government or administration) well ordered",
    "grade": 7
  },
  {
    "id": 8750,
    "traditional": "清晰",
    "simplified": "清晰",
    "reading": "qīngxī",
    "meaning": "distinct, clear (of sound/view)",
    "grade": 7
  },
  {
    "id": 8751,
    "traditional": "清新",
    "simplified": "清新",
    "reading": "qīngxīn",
    "meaning": "pure and fresh, refreshing (of style/fashion/etc.)",
    "grade": 7
  },
  {
    "id": 8752,
    "traditional": "清真寺",
    "simplified": "清真寺",
    "reading": "qīngzhēnsì",
    "meaning": "mosque",
    "grade": 7
  },
  {
    "id": 8753,
    "traditional": "情報",
    "simplified": "情报",
    "reading": "qíngbào",
    "meaning": "poop, intelligence, intelligencer, tip-off, information, info, gen, dope",
    "grade": 7
  },
  {
    "id": 8754,
    "traditional": "情",
    "simplified": "情",
    "reading": "qíng",
    "meaning": "sensibility, soil moisture content, affection, feeling, state of affairs, circumstances, interest, emotion, sentiment, condition, mood, situation, kindness, love, inclination, favour, passion",
    "grade": 7
  },
  {
    "id": 8755,
    "traditional": "情不自禁",
    "simplified": "情不自禁",
    "reading": "qíngbùzìjīn",
    "meaning": "unable to restrain emotions, cannot help",
    "grade": 7
  },
  {
    "id": 8756,
    "traditional": "情懷",
    "simplified": "情怀",
    "reading": "qínghuái",
    "meaning": "feelings, mood",
    "grade": 7
  },
  {
    "id": 8757,
    "traditional": "情調",
    "simplified": "情调",
    "reading": "qíngdiào",
    "meaning": "sentiment, tone and mood, taste",
    "grade": 7
  },
  {
    "id": 8758,
    "traditional": "情結",
    "simplified": "情结",
    "reading": "qíngjié",
    "meaning": "complex",
    "grade": 7
  },
  {
    "id": 8759,
    "traditional": "情侶",
    "simplified": "情侣",
    "reading": "qínglǚ",
    "meaning": "sweethearts, lovers",
    "grade": 7
  },
  {
    "id": 8760,
    "traditional": "情人",
    "simplified": "情人",
    "reading": "qíngrén",
    "meaning": "inamorato, steady, sweetie, Gill, jo, girlfriend, swain, valentine, paramour, turtledove, jill, girl, sweetling, mouse, heart-throb, lassie, truelove, soul mate, flame, ladybird, ladylove, lover, sweetheart, mistress, admirer, hon, lovey, inamorata, honeybunch, turtle, sweeting, bedmate",
    "grade": 7
  },
  {
    "id": 8761,
    "traditional": "情誼",
    "simplified": "情谊",
    "reading": "qíngyì",
    "meaning": "friendship, camaraderie",
    "grade": 7
  },
  {
    "id": 8762,
    "traditional": "情願",
    "simplified": "情愿",
    "reading": "qíngyuàn",
    "meaning": "willingness, would rather (agree to X than Y)",
    "grade": 7
  },
  {
    "id": 8763,
    "traditional": "請帖",
    "simplified": "请帖",
    "reading": "qǐngtiě",
    "meaning": "invitation card, written invitation",
    "grade": 7
  },
  {
    "id": 8764,
    "traditional": "請柬",
    "simplified": "请柬",
    "reading": "qǐngjiǎn",
    "meaning": "invitation card, written invitation",
    "grade": 7
  },
  {
    "id": 8765,
    "traditional": "慶典",
    "simplified": "庆典",
    "reading": "qìngdiǎn",
    "meaning": "celebration",
    "grade": 7
  },
  {
    "id": 8766,
    "traditional": "慶幸",
    "simplified": "庆幸",
    "reading": "qìngxìng",
    "meaning": "rejoice at a good outcome",
    "grade": 7
  },
  {
    "id": 8767,
    "traditional": "慶賀",
    "simplified": "庆贺",
    "reading": "qìnghè",
    "meaning": "to congratulate, to celebrate",
    "grade": 7
  },
  {
    "id": 8768,
    "traditional": "丘陵",
    "simplified": "丘陵",
    "reading": "qiūlíng",
    "meaning": "hills",
    "grade": 7
  },
  {
    "id": 8769,
    "traditional": "囚犯",
    "simplified": "囚犯",
    "reading": "qiúfàn",
    "meaning": "prisoner, convict",
    "grade": 7
  },
  {
    "id": 8770,
    "traditional": "求婚",
    "simplified": "求婚",
    "reading": "qiú hūn",
    "meaning": "to propose marriage",
    "grade": 7
  },
  {
    "id": 8771,
    "traditional": "求學",
    "simplified": "求学",
    "reading": "qiúxué",
    "meaning": "study, attend school",
    "grade": 7
  },
  {
    "id": 8772,
    "traditional": "求救",
    "simplified": "求救",
    "reading": "qiújiù",
    "meaning": "to cry for help",
    "grade": 7
  },
  {
    "id": 8773,
    "traditional": "求證",
    "simplified": "求证",
    "reading": "qiúzhèng",
    "meaning": "to seek proof, to seek confirmation",
    "grade": 7
  },
  {
    "id": 8774,
    "traditional": "求醫",
    "simplified": "求医",
    "reading": "qiúyī",
    "meaning": "to seek medical treatment, to see a doctor",
    "grade": 7
  },
  {
    "id": 8775,
    "traditional": "曲線",
    "simplified": "曲线",
    "reading": "qūxiàn",
    "meaning": "curve, curved line, indirect, in a roundabout way",
    "grade": 7
  },
  {
    "id": 8776,
    "traditional": "曲折",
    "simplified": "曲折",
    "reading": "qūzhé",
    "meaning": "tortuous, winding, complicated",
    "grade": 7
  },
  {
    "id": 8777,
    "traditional": "求助",
    "simplified": "求助",
    "reading": "qiúzhù",
    "meaning": "invoke, resort, seek help, recur, bring_in, turn to ... for help, appeal",
    "grade": 7
  },
  {
    "id": 8778,
    "traditional": "驅動",
    "simplified": "驱动",
    "reading": "qūdòng",
    "meaning": "prompt, urge, spur on, drive",
    "grade": 7
  },
  {
    "id": 8779,
    "traditional": "屈服",
    "simplified": "屈服",
    "reading": "qūfú",
    "meaning": "to surrender, to yield",
    "grade": 7
  },
  {
    "id": 8780,
    "traditional": "驅逐",
    "simplified": "驱逐",
    "reading": "qūzhú",
    "meaning": "to expel, to deport, banishment",
    "grade": 7
  },
  {
    "id": 8781,
    "traditional": "曲",
    "simplified": "曲",
    "reading": "qū",
    "meaning": "surname Qu, yeast, Aspergillus (includes many common molds), Taiwan pr. [qu2], tune, song, CL:支[zhi1]",
    "grade": 7
  },
  {
    "id": 8782,
    "traditional": "趨於",
    "simplified": "趋于",
    "reading": "qūyú",
    "meaning": "incline to, trend, tend towards, tend",
    "grade": 7
  },
  {
    "id": 8783,
    "traditional": "取代",
    "simplified": "取代",
    "reading": "qǔdài",
    "meaning": "replace, substitute for, replacement, supersede, substitute, supersession, supplant, displace, preempt, outplace, take the place of, displacement",
    "grade": 7
  },
  {
    "id": 8784,
    "traditional": "取而代之",
    "simplified": "取而代之",
    "reading": "qǔérdàizhī",
    "meaning": "to substitute for sb, to remove and replace",
    "grade": 7
  },
  {
    "id": 8785,
    "traditional": "取經",
    "simplified": "取经",
    "reading": "qǔ jīng",
    "meaning": "to journey to India on a quest for the Buddhist scriptures, to learn by studying another's experience",
    "grade": 7
  },
  {
    "id": 8786,
    "traditional": "取決於",
    "simplified": "取决于",
    "reading": "qǔjuéyú",
    "meaning": "depending on",
    "grade": 7
  },
  {
    "id": 8787,
    "traditional": "取締",
    "simplified": "取缔",
    "reading": "qǔdì",
    "meaning": "ban (publication old custom etc.), punish violator",
    "grade": 7
  },
  {
    "id": 8788,
    "traditional": "取暖",
    "simplified": "取暖",
    "reading": "qǔ nuǎn",
    "meaning": "to warm oneself (by a fire etc)",
    "grade": 7
  },
  {
    "id": 8789,
    "traditional": "取笑",
    "simplified": "取笑",
    "reading": "qǔxiào",
    "meaning": "to tease, to make fun of",
    "grade": 7
  },
  {
    "id": 8790,
    "traditional": "取勝",
    "simplified": "取胜",
    "reading": "qǔshèng",
    "meaning": "to score a victory, to prevail over one's opponents",
    "grade": 7
  },
  {
    "id": 8791,
    "traditional": "娶",
    "simplified": "娶",
    "reading": "qǔ",
    "meaning": "get married, conjoin, espouse, wive, take to wife, marry, get hitched with, hook up with, wed, take a wife",
    "grade": 7
  },
  {
    "id": 8792,
    "traditional": "去除",
    "simplified": "去除",
    "reading": "qùchú",
    "meaning": "to remove, to dislodge",
    "grade": 7
  },
  {
    "id": 8793,
    "traditional": "去向",
    "simplified": "去向",
    "reading": "qùxiàng",
    "meaning": "the position of sth, whereabouts",
    "grade": 7
  },
  {
    "id": 8794,
    "traditional": "去處",
    "simplified": "去处",
    "reading": "qùchù",
    "meaning": "place, destination",
    "grade": 7
  },
  {
    "id": 8795,
    "traditional": "圈套",
    "simplified": "圈套",
    "reading": "quāntào",
    "meaning": "trap, snare, trick",
    "grade": 7
  },
  {
    "id": 8796,
    "traditional": "趣味",
    "simplified": "趣味",
    "reading": "qùwèi",
    "meaning": "playfulness, merriment, savor, camp, interest, salt, delight, savour, liking, fun, gout, preference, spice, lust, gusto, palate, taste, delectation",
    "grade": 7
  },
  {
    "id": 8797,
    "traditional": "權衡",
    "simplified": "权衡",
    "reading": "quánhéng",
    "meaning": "to consider, to weigh (a matter), to balance (pros and cons)",
    "grade": 7
  },
  {
    "id": 8798,
    "traditional": "權威",
    "simplified": "权威",
    "reading": "quánwēi",
    "meaning": "authoritativeness, sanction, pontiff, prince, pundit, a person of authority, authority, princedom, muscle, power, pope",
    "grade": 7
  },
  {
    "id": 8799,
    "traditional": "圈子",
    "simplified": "圈子",
    "reading": "quānzi",
    "meaning": "circle, ring, (social) circle",
    "grade": 7
  },
  {
    "id": 8800,
    "traditional": "權益",
    "simplified": "权益",
    "reading": "quányì",
    "meaning": "rights and interests",
    "grade": 7
  },
  {
    "id": 8801,
    "traditional": "全程",
    "simplified": "全程",
    "reading": "quánchéng",
    "meaning": "the whole distance, from beginning to end",
    "grade": 7
  },
  {
    "id": 8802,
    "traditional": "全長",
    "simplified": "全长",
    "reading": "quáncháng",
    "meaning": "overall length, span",
    "grade": 7
  },
  {
    "id": 8803,
    "traditional": "全方位",
    "simplified": "全方位",
    "reading": "quánfāngwèi",
    "meaning": "all around, omni-directional, complete, holistic, comprehensive",
    "grade": 7
  },
  {
    "id": 8804,
    "traditional": "全局",
    "simplified": "全局",
    "reading": "quánjú",
    "meaning": "overall situation",
    "grade": 7
  },
  {
    "id": 8805,
    "traditional": "全力以赴",
    "simplified": "全力以赴",
    "reading": "quánlìyǐfù",
    "meaning": "to do at all costs, to make an all-out effort",
    "grade": 7
  },
  {
    "id": 8806,
    "traditional": "全能",
    "simplified": "全能",
    "reading": "quánnéng",
    "meaning": "omnipotent, all-round, strong in every area",
    "grade": 7
  },
  {
    "id": 8807,
    "traditional": "全心全意",
    "simplified": "全心全意",
    "reading": "quánxīn-quányì",
    "meaning": "heart and soul, wholeheartedly",
    "grade": 7
  },
  {
    "id": 8808,
    "traditional": "拳",
    "simplified": "拳",
    "reading": "quán",
    "meaning": "fist, boxing",
    "grade": 7
  },
  {
    "id": 8809,
    "traditional": "全文",
    "simplified": "全文",
    "reading": "quánwén",
    "meaning": "entire text, full text",
    "grade": 7
  },
  {
    "id": 8810,
    "traditional": "勸告",
    "simplified": "劝告",
    "reading": "quàngào",
    "meaning": "to advise, to urge, to exhort, exhortation, advice, CL:席[xi2]",
    "grade": 7
  },
  {
    "id": 8811,
    "traditional": "勸說",
    "simplified": "劝说",
    "reading": "quànshuō",
    "meaning": "to persuade, persuasion, to advise",
    "grade": 7
  },
  {
    "id": 8812,
    "traditional": "勸阻",
    "simplified": "劝阻",
    "reading": "quànzǔ",
    "meaning": "to advise against, to dissuade",
    "grade": 7
  },
  {
    "id": 8813,
    "traditional": "缺口",
    "simplified": "缺口",
    "reading": "quēkǒu",
    "meaning": "nick, jag, gap, shortfall",
    "grade": 7
  },
  {
    "id": 8814,
    "traditional": "拳頭",
    "simplified": "拳头",
    "reading": "quántou",
    "meaning": "fist, clenched fist, CL:個|个[ge4], competitive (product)",
    "grade": 7
  },
  {
    "id": 8815,
    "traditional": "確切",
    "simplified": "确切",
    "reading": "quèqiè",
    "meaning": "correct, accurate, clear and unambiguous, precise, exact, definite, tangible",
    "grade": 7
  },
  {
    "id": 8816,
    "traditional": "缺失",
    "simplified": "缺失",
    "reading": "quēshī",
    "meaning": "lacuna, drawback, gaps and omissions, deletion, defect, hole",
    "grade": 7
  },
  {
    "id": 8817,
    "traditional": "確信",
    "simplified": "确信",
    "reading": "quèxìn",
    "meaning": "to be convinced, to be sure, to firmly believe, to be positive that, definite news",
    "grade": 7
  },
  {
    "id": 8818,
    "traditional": "缺席",
    "simplified": "缺席",
    "reading": "quē xí",
    "meaning": "be absent (from meeting/etc.)",
    "grade": 7
  },
  {
    "id": 8819,
    "traditional": "確診",
    "simplified": "确诊",
    "reading": "quèzhěn",
    "meaning": "to make a definite diagnosis",
    "grade": 7
  },
  {
    "id": 8820,
    "traditional": "確鑿",
    "simplified": "确凿",
    "reading": "quèzáo",
    "meaning": "definite, conclusive, undeniable, authentic, also pr. [que4 zuo4]",
    "grade": 7
  },
  {
    "id": 8821,
    "traditional": "燃放",
    "simplified": "燃放",
    "reading": "ránfàng",
    "meaning": "to light, to set off (firecrackers etc)",
    "grade": 7
  },
  {
    "id": 8822,
    "traditional": "燃氣",
    "simplified": "燃气",
    "reading": "ránqì",
    "meaning": "natural gas",
    "grade": 7
  },
  {
    "id": 8823,
    "traditional": "燃油",
    "simplified": "燃油",
    "reading": "rányóu",
    "meaning": "fuel oil",
    "grade": 7
  },
  {
    "id": 8824,
    "traditional": "讓步",
    "simplified": "让步",
    "reading": "ràng bù",
    "meaning": "to concede, to give in, to yield, a concession",
    "grade": 7
  },
  {
    "id": 8825,
    "traditional": "嚷",
    "simplified": "嚷",
    "reading": "rǎng",
    "meaning": "to blurt out, to shout",
    "grade": 7
  },
  {
    "id": 8826,
    "traditional": "饒",
    "simplified": "饶",
    "reading": "ráo",
    "meaning": "surname Rao, rich, abundant, exuberant, to add for free, to throw in as bonus, to spare, to forgive, despite, although",
    "grade": 7
  },
  {
    "id": 8827,
    "traditional": "擾亂",
    "simplified": "扰乱",
    "reading": "rǎoluàn",
    "meaning": "to disturb, to perturb, to harass",
    "grade": 7
  },
  {
    "id": 8828,
    "traditional": "饒恕",
    "simplified": "饶恕",
    "reading": "ráoshù",
    "meaning": "to forgive, to pardon, to spare",
    "grade": 7
  },
  {
    "id": 8829,
    "traditional": "繞行",
    "simplified": "绕行",
    "reading": "ràoxíng",
    "meaning": "detour, long way around",
    "grade": 7
  },
  {
    "id": 8830,
    "traditional": "惹",
    "simplified": "惹",
    "reading": "rě",
    "meaning": "provoke",
    "grade": 7
  },
  {
    "id": 8831,
    "traditional": "熱潮",
    "simplified": "热潮",
    "reading": "rècháo",
    "meaning": "rush, surge, spate, upsurge",
    "grade": 7
  },
  {
    "id": 8832,
    "traditional": "熱氣",
    "simplified": "热气",
    "reading": "rèqì",
    "meaning": "steam, heat, CL:股[gu3]",
    "grade": 7
  },
  {
    "id": 8833,
    "traditional": "熱帶",
    "simplified": "热带",
    "reading": "rèdài",
    "meaning": "tropical zone, Torrid Zone, savanna, torrid, the tropics, the torrid zone, tropical_zone, tropical, tropic, tropics",
    "grade": 7
  },
  {
    "id": 8834,
    "traditional": "熱騰騰",
    "simplified": "热腾腾",
    "reading": "rèténgténg",
    "meaning": "Fever",
    "grade": 7
  },
  {
    "id": 8835,
    "traditional": "熱衷",
    "simplified": "热衷",
    "reading": "rèzhōng",
    "meaning": "to feel strongly about, to be fond of, obsession, deep commitment",
    "grade": 7
  },
  {
    "id": 8836,
    "traditional": "熱氣球",
    "simplified": "热气球",
    "reading": "rèqìqiú",
    "meaning": "hot air balloon",
    "grade": 7
  },
  {
    "id": 8837,
    "traditional": "人道",
    "simplified": "人道",
    "reading": "réndào",
    "meaning": "human sympathy",
    "grade": 7
  },
  {
    "id": 8838,
    "traditional": "人次",
    "simplified": "人次",
    "reading": "réncì",
    "meaning": "man-times, man-times (analogous to \"man-hours\")",
    "grade": 7
  },
  {
    "id": 8839,
    "traditional": "人格",
    "simplified": "人格",
    "reading": "réngé",
    "meaning": "lot, fibre, fiber, character, spirit, selfhood, manhood, personality",
    "grade": 7
  },
  {
    "id": 8840,
    "traditional": "人工智能",
    "simplified": "人工智能",
    "reading": "réngōngzhìnéng",
    "meaning": "artificial intelligence (AI)",
    "grade": 7
  },
  {
    "id": 8841,
    "traditional": "人均",
    "simplified": "人均",
    "reading": "rénjūn",
    "meaning": "per capita",
    "grade": 7
  },
  {
    "id": 8842,
    "traditional": "人品",
    "simplified": "人品",
    "reading": "rénpǐn",
    "meaning": "moral standing, moral quality, character, personality, appearance, looks (colloquial), bearing",
    "grade": 7
  },
  {
    "id": 8843,
    "traditional": "人情",
    "simplified": "人情",
    "reading": "rénqíng",
    "meaning": "human feelings, favor, gift, present",
    "grade": 7
  },
  {
    "id": 8844,
    "traditional": "人氣",
    "simplified": "人气",
    "reading": "rénqì",
    "meaning": "popularity, personality, character",
    "grade": 7
  },
  {
    "id": 8845,
    "traditional": "人身",
    "simplified": "人身",
    "reading": "rénshēn",
    "meaning": "person, personal, human body",
    "grade": 7
  },
  {
    "id": 8846,
    "traditional": "人事",
    "simplified": "人事",
    "reading": "rénshì",
    "meaning": "personnel, ways of the world, what is humanly possible, human affairs, occurrences in human life, consciousness of the outside world, personnel matters",
    "grade": 7
  },
  {
    "id": 8847,
    "traditional": "人手",
    "simplified": "人手",
    "reading": "rénshǒu",
    "meaning": "manpower, staff, human hand",
    "grade": 7
  },
  {
    "id": 8848,
    "traditional": "人體",
    "simplified": "人体",
    "reading": "réntǐ",
    "meaning": "soma, form, body, build, human body, anatomy, figure, chassis, physique, bod, carcass, shape, frame, physical body, material body, flesh, anthropometry",
    "grade": 7
  },
  {
    "id": 8849,
    "traditional": "人為",
    "simplified": "人为",
    "reading": "rénwéi",
    "meaning": "man-made, artificial",
    "grade": 7
  },
  {
    "id": 8850,
    "traditional": "人文",
    "simplified": "人文",
    "reading": "rénwén",
    "meaning": "letters, humanity, humanities",
    "grade": 7
  },
  {
    "id": 8851,
    "traditional": "人行道",
    "simplified": "人行道",
    "reading": "rénxíngdào",
    "meaning": "sidewalk",
    "grade": 7
  },
  {
    "id": 8852,
    "traditional": "人選",
    "simplified": "人选",
    "reading": "rénxuǎn",
    "meaning": "choice among persons",
    "grade": 7
  },
  {
    "id": 8853,
    "traditional": "人緣兒",
    "simplified": "人缘儿",
    "reading": "rényuánr5",
    "meaning": "relations with other people, erhua variant of 人緣|人缘[ren2 yuan2]",
    "grade": 7
  },
  {
    "id": 8854,
    "traditional": "人造",
    "simplified": "人造",
    "reading": "rénzào",
    "meaning": "man-made, synthetic, artificial",
    "grade": 7
  },
  {
    "id": 8855,
    "traditional": "人性",
    "simplified": "人性",
    "reading": "rénxing",
    "meaning": "reason, hypostasis, humanitarianism, normal human feelings, humanness, humanity, humanism, human nature, flesh, humanistic, manhood",
    "grade": 7
  },
  {
    "id": 8856,
    "traditional": "人質",
    "simplified": "人质",
    "reading": "rénzhì",
    "meaning": "hostage",
    "grade": 7
  },
  {
    "id": 8857,
    "traditional": "仁慈",
    "simplified": "仁慈",
    "reading": "réncí",
    "meaning": "benevolent, charitable, kind, kindly, kindness, merciful",
    "grade": 7
  },
  {
    "id": 8858,
    "traditional": "忍飢挨餓",
    "simplified": "忍饥挨饿",
    "reading": "rěnjī-áiè",
    "meaning": "starving, famished",
    "grade": 7
  },
  {
    "id": 8859,
    "traditional": "忍耐",
    "simplified": "忍耐",
    "reading": "rěnnài",
    "meaning": "forbear, stand, go, stomach, abide, put up, hold_out, suppress, sustain, brave out, persevere, remain, support, endure, put up with, exercise restraint, suffer, restrain oneself, brave, bear, brook, resist, exercise patience, tolerate, thole, weather",
    "grade": 7
  },
  {
    "id": 8860,
    "traditional": "忍心",
    "simplified": "忍心",
    "reading": "rěn xīn",
    "meaning": "to have the heart to do sth, to steel oneself to a task",
    "grade": 7
  },
  {
    "id": 8861,
    "traditional": "認錯",
    "simplified": "认错",
    "reading": "rèn cuò",
    "meaning": "to admit an error, to acknowledge one's mistake",
    "grade": 7
  },
  {
    "id": 8862,
    "traditional": "認證",
    "simplified": "认证",
    "reading": "rènzhèng",
    "meaning": "to authenticate, to approve",
    "grade": 7
  },
  {
    "id": 8863,
    "traditional": "任命",
    "simplified": "任命",
    "reading": "rènmìng",
    "meaning": "to appoint and nominate, (job) appointment, CL:紙|纸[zhi3]",
    "grade": 7
  },
  {
    "id": 8864,
    "traditional": "認知",
    "simplified": "认知",
    "reading": "rènzhī",
    "meaning": "cognize, perceive",
    "grade": 7
  },
  {
    "id": 8865,
    "traditional": "任期",
    "simplified": "任期",
    "reading": "rènqī",
    "meaning": "term of office",
    "grade": 7
  },
  {
    "id": 8866,
    "traditional": "任人宰割",
    "simplified": "任人宰割",
    "reading": "rènrén-zǎigē",
    "meaning": "to get trampled on (idiom), to be taken advantage of",
    "grade": 7
  },
  {
    "id": 8867,
    "traditional": "任意",
    "simplified": "任意",
    "reading": "rènyì",
    "meaning": "willfully, at_will, arbitrarily",
    "grade": 7
  },
  {
    "id": 8868,
    "traditional": "任職",
    "simplified": "任职",
    "reading": "rèn zhí",
    "meaning": "assignment, be in office, serve, hold a position, hold a post",
    "grade": 7
  },
  {
    "id": 8869,
    "traditional": "韌性",
    "simplified": "韧性",
    "reading": "rènxìng",
    "meaning": "toughness",
    "grade": 7
  },
  {
    "id": 8870,
    "traditional": "日程",
    "simplified": "日程",
    "reading": "rìchéng",
    "meaning": "schedule, itinerary, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 8871,
    "traditional": "日復一日",
    "simplified": "日复一日",
    "reading": "rìfùyīrì",
    "meaning": "day after day",
    "grade": 7
  },
  {
    "id": 8872,
    "traditional": "日前",
    "simplified": "日前",
    "reading": "rìqián",
    "meaning": "the other day, a few days ago",
    "grade": 7
  },
  {
    "id": 8873,
    "traditional": "日後",
    "simplified": "日后",
    "reading": "rìhòu",
    "meaning": "in the future",
    "grade": 7
  },
  {
    "id": 8874,
    "traditional": "日趨",
    "simplified": "日趋",
    "reading": "rìqū",
    "meaning": "gradually, day_by_day",
    "grade": 7
  },
  {
    "id": 8875,
    "traditional": "日新月異",
    "simplified": "日新月异",
    "reading": "rìxīn-yuèyì",
    "meaning": "daily renewal, monthly change (idiom), every day sees new developments, rapid progress",
    "grade": 7
  },
  {
    "id": 8876,
    "traditional": "日益",
    "simplified": "日益",
    "reading": "rìyì",
    "meaning": "increasingly",
    "grade": 7
  },
  {
    "id": 8877,
    "traditional": "榮獲",
    "simplified": "荣获",
    "reading": "rónghuò",
    "meaning": "have the honor to win",
    "grade": 7
  },
  {
    "id": 8878,
    "traditional": "榮幸",
    "simplified": "荣幸",
    "reading": "róngxìng",
    "meaning": "honored (to have the privilege of ...)",
    "grade": 7
  },
  {
    "id": 8879,
    "traditional": "榮譽",
    "simplified": "荣誉",
    "reading": "róngyù",
    "meaning": "honour, accolade, palm, commendation, credit, lustre, kudos, luster, bay, garland, izzat, laurels, glorification, glory, distinction, honor",
    "grade": 7
  },
  {
    "id": 8880,
    "traditional": "容光煥發",
    "simplified": "容光焕发",
    "reading": "róngguāng-huànfā",
    "meaning": "face glowing (idiom), looking radiant, all smiles",
    "grade": 7
  },
  {
    "id": 8881,
    "traditional": "容忍",
    "simplified": "容忍",
    "reading": "róngrěn",
    "meaning": "tolerate, condone",
    "grade": 7
  },
  {
    "id": 8882,
    "traditional": "容量",
    "simplified": "容量",
    "reading": "róngliàng",
    "meaning": "volume, measure of capacity, can, capability, capacity (of container), capacity, bulk, volumetric, canful, content",
    "grade": 7
  },
  {
    "id": 8883,
    "traditional": "容納",
    "simplified": "容纳",
    "reading": "róngnà",
    "meaning": "have capacity for, receive, admit, take, accommodate, store, recipient, seat, hold, accept, carry, bear, contain, have a capacity of",
    "grade": 7
  },
  {
    "id": 8884,
    "traditional": "容顏",
    "simplified": "容颜",
    "reading": "róngyán",
    "meaning": "mien, complexion",
    "grade": 7
  },
  {
    "id": 8885,
    "traditional": "容許",
    "simplified": "容许",
    "reading": "róngxǔ",
    "meaning": "let_in, have, receive, can, permission, permit, allow for, may, adhibit, might, admissive, provide for, admit, concede, cede, leave, suffer, tolerate, allow",
    "grade": 7
  },
  {
    "id": 8886,
    "traditional": "溶解",
    "simplified": "溶解",
    "reading": "róngjiě",
    "meaning": "to dissolve",
    "grade": 7
  },
  {
    "id": 8887,
    "traditional": "融",
    "simplified": "融",
    "reading": "róng",
    "meaning": "to melt, to thaw, to blend, to merge, to be in harmony, old variant of 融[rong2]",
    "grade": 7
  },
  {
    "id": 8888,
    "traditional": "融化",
    "simplified": "融化",
    "reading": "rónghuà",
    "meaning": "to melt, to thaw, to dissolve, to blend into, to combine, to fuse",
    "grade": 7
  },
  {
    "id": 8889,
    "traditional": "冗長",
    "simplified": "冗长",
    "reading": "rǒngcháng",
    "meaning": "long and tedious, redundant, superfluous, supernumerary, verbose (of writing)",
    "grade": 7
  },
  {
    "id": 8890,
    "traditional": "融洽",
    "simplified": "融洽",
    "reading": "róngqià",
    "meaning": "harmonious, friendly relations, on good terms with one another",
    "grade": 7
  },
  {
    "id": 8891,
    "traditional": "柔和",
    "simplified": "柔和",
    "reading": "róuhé",
    "meaning": "gentle, soft",
    "grade": 7
  },
  {
    "id": 8892,
    "traditional": "柔軟",
    "simplified": "柔软",
    "reading": "róuruǎn",
    "meaning": "lithe, whippy, soft",
    "grade": 7
  },
  {
    "id": 8893,
    "traditional": "如果說",
    "simplified": "如果说",
    "reading": "rúguǒshuō",
    "meaning": "conj.: if",
    "grade": 7
  },
  {
    "id": 8894,
    "traditional": "揉",
    "simplified": "揉",
    "reading": "róu",
    "meaning": "to knead, to massage, to rub",
    "grade": 7
  },
  {
    "id": 8895,
    "traditional": "如實",
    "simplified": "如实",
    "reading": "rúshí",
    "meaning": "as things really are, realistic",
    "grade": 7
  },
  {
    "id": 8896,
    "traditional": "如意",
    "simplified": "如意",
    "reading": "rú yì",
    "meaning": "as one wishes",
    "grade": 7
  },
  {
    "id": 8897,
    "traditional": "如願以償",
    "simplified": "如愿以偿",
    "reading": "rúyuàn-yǐcháng",
    "meaning": "to have one's wish fulfilled",
    "grade": 7
  },
  {
    "id": 8898,
    "traditional": "儒家",
    "simplified": "儒家",
    "reading": "Rújiā",
    "meaning": "Confucian school",
    "grade": 7
  },
  {
    "id": 8899,
    "traditional": "如醉如痴",
    "simplified": "如醉如痴",
    "reading": "rúzuì-rúchī",
    "meaning": "lit. as if drunk and stupefied (idiom), intoxicated by sth, obsessed with, mad about sth, also written 如癡如醉|如痴如醉[ru2 chi1 ru2 zui4]",
    "grade": 7
  },
  {
    "id": 8900,
    "traditional": "入場",
    "simplified": "入场",
    "reading": "rù chǎng",
    "meaning": "to enter the venue for a meeting, to enter into an examination, to enter a stadium, arena etc",
    "grade": 7
  },
  {
    "id": 8901,
    "traditional": "儒學",
    "simplified": "儒学",
    "reading": "rúxué",
    "meaning": "Confucianism",
    "grade": 7
  },
  {
    "id": 8902,
    "traditional": "入場券",
    "simplified": "入场券",
    "reading": "rùchǎngquàn",
    "meaning": "admission ticket",
    "grade": 7
  },
  {
    "id": 8903,
    "traditional": "入侵",
    "simplified": "入侵",
    "reading": "rùqīn",
    "meaning": "invade",
    "grade": 7
  },
  {
    "id": 8904,
    "traditional": "入境",
    "simplified": "入境",
    "reading": "rù jìng",
    "meaning": "enter a country",
    "grade": 7
  },
  {
    "id": 8905,
    "traditional": "入選",
    "simplified": "入选",
    "reading": "rù xuǎn",
    "meaning": "select, be selected, be chosen",
    "grade": 7
  },
  {
    "id": 8906,
    "traditional": "軟弱",
    "simplified": "软弱",
    "reading": "ruǎnruò",
    "meaning": "weak, feeble, flabby",
    "grade": 7
  },
  {
    "id": 8907,
    "traditional": "軟實力",
    "simplified": "软实力",
    "reading": "ruǎnshílì",
    "meaning": "soft power (i.e. media influence, propaganda and cultural ties in lieu of military power)",
    "grade": 7
  },
  {
    "id": 8908,
    "traditional": "瑞雪",
    "simplified": "瑞雪",
    "reading": "ruìxuě",
    "meaning": "timely snow",
    "grade": 7
  },
  {
    "id": 8909,
    "traditional": "潤",
    "simplified": "润",
    "reading": "rùn",
    "meaning": "to moisten, to lubricate, to embellish, moist, glossy, sleek",
    "grade": 7
  },
  {
    "id": 8910,
    "traditional": "若干",
    "simplified": "若干",
    "reading": "ruògān",
    "meaning": "det.: a certain number, how many",
    "grade": 7
  },
  {
    "id": 8911,
    "traditional": "弱勢",
    "simplified": "弱势",
    "reading": "ruòshì",
    "meaning": "the weak",
    "grade": 7
  },
  {
    "id": 8912,
    "traditional": "弱點",
    "simplified": "弱点",
    "reading": "ruòdiǎn",
    "meaning": "Achilles' heel, soft spot, failing, weakness, underbelly, defect, foible, infirmity, weak_point, blot, frailty, drawback, Achilles'_heel, soft_spot, weak point, vulnerability",
    "grade": 7
  },
  {
    "id": 8913,
    "traditional": "撒謊",
    "simplified": "撒谎",
    "reading": "sā huǎng",
    "meaning": "to tell lies",
    "grade": 7
  },
  {
    "id": 8914,
    "traditional": "撒",
    "simplified": "撒",
    "reading": "sā",
    "meaning": "drop, throw off all restraint, strow, shower, spill, release, spread, sprinkle, let oneself go, asperse, cast, let out, bestrewn, dredge, sow, scatter, bescatter, let go",
    "grade": 7
  },
  {
    "id": 8915,
    "traditional": "賽車",
    "simplified": "赛车",
    "reading": "sàichē",
    "meaning": "auto race, cycle race, race car",
    "grade": 7
  },
  {
    "id": 8916,
    "traditional": "三番五次",
    "simplified": "三番五次",
    "reading": "sānfān-wǔcì",
    "meaning": "over and over again (idiom)",
    "grade": 7
  },
  {
    "id": 8917,
    "traditional": "賽跑",
    "simplified": "赛跑",
    "reading": "sàipǎo",
    "meaning": "race (running), to race (running)",
    "grade": 7
  },
  {
    "id": 8918,
    "traditional": "三角",
    "simplified": "三角",
    "reading": "sānjiǎo",
    "meaning": "triangle",
    "grade": 7
  },
  {
    "id": 8919,
    "traditional": "三維",
    "simplified": "三维",
    "reading": "sānwéi",
    "meaning": "three-dimensional, 3D",
    "grade": 7
  },
  {
    "id": 8920,
    "traditional": "散布",
    "simplified": "散布",
    "reading": "sànbù",
    "meaning": "to disseminate",
    "grade": 7
  },
  {
    "id": 8921,
    "traditional": "桑拿",
    "simplified": "桑拿",
    "reading": "sāngná",
    "meaning": "sauna (loanword)",
    "grade": 7
  },
  {
    "id": 8922,
    "traditional": "嗓子",
    "simplified": "嗓子",
    "reading": "sǎngzi",
    "meaning": "throat, voice, CL:把[ba3]",
    "grade": 7
  },
  {
    "id": 8923,
    "traditional": "散發",
    "simplified": "散发",
    "reading": "sànfā",
    "meaning": "spray, radiate, emission, send out, give forth, exhale, distribution, diffuse, distribute, emit, issue, diffusion, emanate, send forth, sent out, give out, reek, furnace",
    "grade": 7
  },
  {
    "id": 8924,
    "traditional": "喪生",
    "simplified": "丧生",
    "reading": "sàng shēng",
    "meaning": "to die, to lose one's life",
    "grade": 7
  },
  {
    "id": 8925,
    "traditional": "騷亂",
    "simplified": "骚乱",
    "reading": "sāoluàn",
    "meaning": "disturbance, riot, to create a disturbance",
    "grade": 7
  },
  {
    "id": 8926,
    "traditional": "騷擾",
    "simplified": "骚扰",
    "reading": "sāorǎo",
    "meaning": "harass, molest",
    "grade": 7
  },
  {
    "id": 8927,
    "traditional": "掃除",
    "simplified": "扫除",
    "reading": "sǎochú",
    "meaning": "to sweep, to clean with a brush, to sweep away (often fig.)",
    "grade": 7
  },
  {
    "id": 8928,
    "traditional": "掃描",
    "simplified": "扫描",
    "reading": "sǎomiáo",
    "meaning": "scan",
    "grade": 7
  },
  {
    "id": 8929,
    "traditional": "掃興",
    "simplified": "扫兴",
    "reading": "sǎo xìng",
    "meaning": "to have one's spirits dampened, to feel disappointed",
    "grade": 7
  },
  {
    "id": 8930,
    "traditional": "嫂子",
    "simplified": "嫂子",
    "reading": "sǎozi",
    "meaning": "(coll.) older brother's wife, sister-in-law, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 8931,
    "traditional": "掃墓",
    "simplified": "扫墓",
    "reading": "sǎo mù",
    "meaning": "to sweep the tombs (of one's ancestors), the Qingming festival",
    "grade": 7
  },
  {
    "id": 8932,
    "traditional": "殺害",
    "simplified": "杀害",
    "reading": "shāhài",
    "meaning": "cut_down, liquidate, killing, kill, slay, cut_off, grease, remove, murder, destroy, do in, waste, ice, knock off",
    "grade": 7
  },
  {
    "id": 8933,
    "traditional": "僧人",
    "simplified": "僧人",
    "reading": "sēngrén",
    "meaning": "monk",
    "grade": 7
  },
  {
    "id": 8934,
    "traditional": "殺手",
    "simplified": "杀手",
    "reading": "shāshǒu",
    "meaning": "killer, murderer, hit man, (sports) formidable player",
    "grade": 7
  },
  {
    "id": 8935,
    "traditional": "沙龍",
    "simplified": "沙龙",
    "reading": "shālóng",
    "meaning": "salon (loanword)",
    "grade": 7
  },
  {
    "id": 8936,
    "traditional": "紗",
    "simplified": "纱",
    "reading": "shā",
    "meaning": "cotton yarn, muslin",
    "grade": 7
  },
  {
    "id": 8937,
    "traditional": "沙灘",
    "simplified": "沙滩",
    "reading": "shātān",
    "meaning": "sandy beach",
    "grade": 7
  },
  {
    "id": 8938,
    "traditional": "砂糖",
    "simplified": "砂糖",
    "reading": "shātáng",
    "meaning": "granulated sugar",
    "grade": 7
  },
  {
    "id": 8939,
    "traditional": "剎車",
    "simplified": "刹车",
    "reading": "shāchē",
    "meaning": "to brake (when driving), to stop, to switch off, to check (bad habits), a brake",
    "grade": 7
  },
  {
    "id": 8940,
    "traditional": "鯊魚",
    "simplified": "鲨鱼",
    "reading": "shāyú",
    "meaning": "shark",
    "grade": 7
  },
  {
    "id": 8941,
    "traditional": "傻瓜",
    "simplified": "傻瓜",
    "reading": "shǎguā",
    "meaning": "idiot, fool",
    "grade": 7
  },
  {
    "id": 8942,
    "traditional": "篩",
    "simplified": "筛",
    "reading": "shāi",
    "meaning": "to filter, to sift, to sieve",
    "grade": 7
  },
  {
    "id": 8943,
    "traditional": "曬太陽",
    "simplified": "晒太阳",
    "reading": "shài tàiyang",
    "meaning": "Sun",
    "grade": 7
  },
  {
    "id": 8944,
    "traditional": "山川",
    "simplified": "山川",
    "reading": "shānchuān",
    "meaning": "Mountainous",
    "grade": 7
  },
  {
    "id": 8945,
    "traditional": "篩選",
    "simplified": "筛选",
    "reading": "shāixuǎn",
    "meaning": "to filter",
    "grade": 7
  },
  {
    "id": 8946,
    "traditional": "山岡",
    "simplified": "山冈",
    "reading": "shāngāng",
    "meaning": "mound, small hill",
    "grade": 7
  },
  {
    "id": 8947,
    "traditional": "山嶺",
    "simplified": "山岭",
    "reading": "shānlǐng",
    "meaning": "mountain ridge",
    "grade": 7
  },
  {
    "id": 8948,
    "traditional": "山頂",
    "simplified": "山顶",
    "reading": "shāndǐng",
    "meaning": "hilltop",
    "grade": 7
  },
  {
    "id": 8949,
    "traditional": "山寨",
    "simplified": "山寨",
    "reading": "shānzhài",
    "meaning": "fortified hill village, mountain stronghold (esp. of bandits), (fig.) knockoff (goods), counterfeit, imitation",
    "grade": 7
  },
  {
    "id": 8950,
    "traditional": "山路",
    "simplified": "山路",
    "reading": "shānlù",
    "meaning": "nek, pass, mountain road, mountain trail, ghaut, ghat",
    "grade": 7
  },
  {
    "id": 8951,
    "traditional": "刪除",
    "simplified": "删除",
    "reading": "shānchú",
    "meaning": "delete, leave out",
    "grade": 7
  },
  {
    "id": 8952,
    "traditional": "刪",
    "simplified": "删",
    "reading": "shān",
    "meaning": "to delete",
    "grade": 7
  },
  {
    "id": 8953,
    "traditional": "煽動",
    "simplified": "煽动",
    "reading": "shāndòng",
    "meaning": "to incite, to instigate",
    "grade": 7
  },
  {
    "id": 8954,
    "traditional": "閃爍",
    "simplified": "闪烁",
    "reading": "shǎnshuò",
    "meaning": "twinkle, flicker, glisten",
    "grade": 7
  },
  {
    "id": 8955,
    "traditional": "善意",
    "simplified": "善意",
    "reading": "shànyì",
    "meaning": "good_faith, grace, good intention, benevolence, love, goodwill, good will",
    "grade": 7
  },
  {
    "id": 8956,
    "traditional": "善",
    "simplified": "善",
    "reading": "shàn",
    "meaning": "good (virtuous), benevolent, well-disposed, good at sth, to improve or perfect",
    "grade": 7
  },
  {
    "id": 8957,
    "traditional": "擅長",
    "simplified": "擅长",
    "reading": "shàncháng",
    "meaning": "excel, surpass, stand out, be good at, be skilled in, be expert in",
    "grade": 7
  },
  {
    "id": 8958,
    "traditional": "擅自",
    "simplified": "擅自",
    "reading": "shànzì",
    "meaning": "without permission, of one's own initiative",
    "grade": 7
  },
  {
    "id": 8959,
    "traditional": "膳食",
    "simplified": "膳食",
    "reading": "shànshí",
    "meaning": "meal",
    "grade": 7
  },
  {
    "id": 8960,
    "traditional": "贍養",
    "simplified": "赡养",
    "reading": "shànyǎng",
    "meaning": "to support, to provide support for, to maintain",
    "grade": 7
  },
  {
    "id": 8961,
    "traditional": "傷殘",
    "simplified": "伤残",
    "reading": "shāngcán",
    "meaning": "disabled, maimed, crippled, (of objects) damaged",
    "grade": 7
  },
  {
    "id": 8962,
    "traditional": "傷痕",
    "simplified": "伤痕",
    "reading": "shānghén",
    "meaning": "scar, bruise",
    "grade": 7
  },
  {
    "id": 8963,
    "traditional": "傷腦筋",
    "simplified": "伤脑筋",
    "reading": "shāng nǎojīn",
    "meaning": "to be a real headache, to find sth a real headache, to beat one's brains",
    "grade": 7
  },
  {
    "id": 8964,
    "traditional": "傷勢",
    "simplified": "伤势",
    "reading": "shāngshì",
    "meaning": "condition of an injury",
    "grade": 7
  },
  {
    "id": 8965,
    "traditional": "傷感",
    "simplified": "伤感",
    "reading": "shānggǎn",
    "meaning": "sad, emotional, sentimental, pathos",
    "grade": 7
  },
  {
    "id": 8966,
    "traditional": "商販",
    "simplified": "商贩",
    "reading": "shāngfàn",
    "meaning": "trader, peddler",
    "grade": 7
  },
  {
    "id": 8967,
    "traditional": "商討",
    "simplified": "商讨",
    "reading": "shāngtǎo",
    "meaning": "to discuss, to deliberate",
    "grade": 7
  },
  {
    "id": 8968,
    "traditional": "商賈",
    "simplified": "商贾",
    "reading": "shānggǔ",
    "meaning": "merchant",
    "grade": 7
  },
  {
    "id": 8969,
    "traditional": "上報",
    "simplified": "上报",
    "reading": "shàng bào",
    "meaning": "to report to one's superiors, to appear in the news, to reply to a letter",
    "grade": 7
  },
  {
    "id": 8970,
    "traditional": "上場",
    "simplified": "上场",
    "reading": "shàng chǎng",
    "meaning": "appear on the stage, enter, appear, enter the playing court, enter the playing field, enter the stage, appear on stage",
    "grade": 7
  },
  {
    "id": 8971,
    "traditional": "上方",
    "simplified": "上方",
    "reading": "shàngfāng",
    "meaning": "Above",
    "grade": 7
  },
  {
    "id": 8972,
    "traditional": "上崗",
    "simplified": "上岗",
    "reading": "shàng gǎng",
    "meaning": "to take up one's post, to be given a job",
    "grade": 7
  },
  {
    "id": 8973,
    "traditional": "上火",
    "simplified": "上火",
    "reading": "shàng huǒ",
    "meaning": "to get angry, to suffer from excessive internal heat (TCM)",
    "grade": 7
  },
  {
    "id": 8974,
    "traditional": "上空",
    "simplified": "上空",
    "reading": "shàngkōng",
    "meaning": "in the sky, overhead",
    "grade": 7
  },
  {
    "id": 8975,
    "traditional": "上流",
    "simplified": "上流",
    "reading": "shàngliú",
    "meaning": "upper class",
    "grade": 7
  },
  {
    "id": 8976,
    "traditional": "上任",
    "simplified": "上任",
    "reading": "shàng rèn",
    "meaning": "take up an official post, assume post, incept, assume office",
    "grade": 7
  },
  {
    "id": 8977,
    "traditional": "上期",
    "simplified": "上期",
    "reading": "shàngqī",
    "meaning": "Previous period",
    "grade": 7
  },
  {
    "id": 8978,
    "traditional": "上述",
    "simplified": "上述",
    "reading": "shàngshù",
    "meaning": "above-mentioned",
    "grade": 7
  },
  {
    "id": 8979,
    "traditional": "上司",
    "simplified": "上司",
    "reading": "shàngsi",
    "meaning": "superior, boss",
    "grade": 7
  },
  {
    "id": 8980,
    "traditional": "上訴",
    "simplified": "上诉",
    "reading": "shàngsù",
    "meaning": "to appeal (a judicial case), appeal",
    "grade": 7
  },
  {
    "id": 8981,
    "traditional": "上調",
    "simplified": "上调",
    "reading": "shàngdiào",
    "meaning": "to raise (prices), to adjust upwards",
    "grade": 7
  },
  {
    "id": 8982,
    "traditional": "上頭",
    "simplified": "上头",
    "reading": "shàngtou",
    "meaning": "(of alcohol) to go to one's head, (old) (of a bride-to-be) to bind one's hair into a bun, (of a prostitute) to receive a patron for the first time, above, on top of, on the surface of",
    "grade": 7
  },
  {
    "id": 8983,
    "traditional": "上限",
    "simplified": "上限",
    "reading": "shàngxiàn",
    "meaning": "cap, upper limit",
    "grade": 7
  },
  {
    "id": 8984,
    "traditional": "上旬",
    "simplified": "上旬",
    "reading": "shàngxún",
    "meaning": "first third of a month",
    "grade": 7
  },
  {
    "id": 8985,
    "traditional": "上癮",
    "simplified": "上瘾",
    "reading": "shàng yǐn",
    "meaning": "to get into a habit, to become addicted",
    "grade": 7
  },
  {
    "id": 8986,
    "traditional": "上游",
    "simplified": "上游",
    "reading": "shàngyóu",
    "meaning": "upper reaches of river, advanced position",
    "grade": 7
  },
  {
    "id": 8987,
    "traditional": "上映",
    "simplified": "上映",
    "reading": "shàngyìng",
    "meaning": "to show (a movie), to screen",
    "grade": 7
  },
  {
    "id": 8988,
    "traditional": "尚",
    "simplified": "尚",
    "reading": "shàng",
    "meaning": "still, yet, fairly, rather",
    "grade": 7
  },
  {
    "id": 8989,
    "traditional": "尚未",
    "simplified": "尚未",
    "reading": "shàngwèi",
    "meaning": "as yet, til now, heretofore, yet, thus far, so far, until now, up to now, hitherto, not_yet, not yet",
    "grade": 7
  },
  {
    "id": 8990,
    "traditional": "捎",
    "simplified": "捎",
    "reading": "shāo",
    "meaning": "to bring sth to sb, to deliver",
    "grade": 7
  },
  {
    "id": 8991,
    "traditional": "燒毀",
    "simplified": "烧毁",
    "reading": "shāohuǐ",
    "meaning": "to burn, to burn down",
    "grade": 7
  },
  {
    "id": 8992,
    "traditional": "燒烤",
    "simplified": "烧烤",
    "reading": "shāokǎo",
    "meaning": "barbecue, to roast",
    "grade": 7
  },
  {
    "id": 8993,
    "traditional": "稍後",
    "simplified": "稍后",
    "reading": "shāohòu",
    "meaning": "later on",
    "grade": 7
  },
  {
    "id": 8994,
    "traditional": "稍稍",
    "simplified": "稍稍",
    "reading": "shāoshāo",
    "meaning": "somewhat, a little, slightly",
    "grade": 7
  },
  {
    "id": 8995,
    "traditional": "少見",
    "simplified": "少见",
    "reading": "shǎojiàn",
    "meaning": "rare, seldom seen, unique",
    "grade": 7
  },
  {
    "id": 8996,
    "traditional": "少不了",
    "simplified": "少不了",
    "reading": "shǎobuliǎo",
    "meaning": "cannot do without, to be unavoidable, are bound to be many",
    "grade": 7
  },
  {
    "id": 8997,
    "traditional": "少量",
    "simplified": "少量",
    "reading": "shǎoliàng",
    "meaning": "dribblet, morsel, modicum, scattering, a few, whit, inch, small, daub, det.: a few, slug, element, dash, haet, dearth, fleabite, sprinkle, little, shred, smidgen, soupcon, a little/few, bit, suspicion, mouthful, tot, littleness, trifle, snack, small quantity, spot, few, groat, paucity, nibble, smattering, small amount, ounce, rag, lick, spatter, tad, skerrick, pennyworth, small amount/quanti",
    "grade": 7
  },
  {
    "id": 8998,
    "traditional": "稍候",
    "simplified": "稍候",
    "reading": "shāohòu",
    "meaning": "Wait a moment",
    "grade": 7
  },
  {
    "id": 8999,
    "traditional": "少林寺",
    "simplified": "少林寺",
    "reading": "Shàolínsì",
    "meaning": "Shaolin Temple, Buddhist monastery famous for its kung fu monks",
    "grade": 7
  },
  {
    "id": 9000,
    "traditional": "少有",
    "simplified": "少有",
    "reading": "shǎoyǒu",
    "meaning": "rare, infrequent",
    "grade": 7
  },
  {
    "id": 9001,
    "traditional": "奢侈",
    "simplified": "奢侈",
    "reading": "shēchǐ",
    "meaning": "luxurious, extravagant",
    "grade": 7
  },
  {
    "id": 9002,
    "traditional": "少女",
    "simplified": "少女",
    "reading": "shàonǚ",
    "meaning": "signorina, tomato, puss, teens, Gill, bobbysocker, gill, trick, nymph, chick, pussy, missy, maiden, wench, bobbysoxer, demoiselle, girlish, damsel, girl, sheila, miss, jeune fille, squab, wren, pigeon, colleen, maid, burd, bud, lassie, virgin, girlishness, number, gal, skirt, fille, teenager, quean, lass, quail, shrimp, young girl, maidish",
    "grade": 7
  },
  {
    "id": 9003,
    "traditional": "奢望",
    "simplified": "奢望",
    "reading": "shēwàng",
    "meaning": "an extravagant hope, to have excessive expectations",
    "grade": 7
  },
  {
    "id": 9004,
    "traditional": "設",
    "simplified": "设",
    "reading": "shè",
    "meaning": "set up, found",
    "grade": 7
  },
  {
    "id": 9005,
    "traditional": "設定",
    "simplified": "设定",
    "reading": "shèdìng",
    "meaning": "frame, time",
    "grade": 7
  },
  {
    "id": 9006,
    "traditional": "設法",
    "simplified": "设法",
    "reading": "shèfǎ",
    "meaning": "contrive, think up a method, devise, try",
    "grade": 7
  },
  {
    "id": 9007,
    "traditional": "社交",
    "simplified": "社交",
    "reading": "shèjiāo",
    "meaning": "social_intercourse, converse, social interaction, social contact, interaction, social contact/interaction, coterie, sociality, commerce",
    "grade": 7
  },
  {
    "id": 9008,
    "traditional": "社會主義",
    "simplified": "社会主义",
    "reading": "shèhuìzhǔyì",
    "meaning": "socialist, socialism",
    "grade": 7
  },
  {
    "id": 9009,
    "traditional": "社論",
    "simplified": "社论",
    "reading": "shèlùn",
    "meaning": "editorial (in a newspaper), CL:篇[pian1]",
    "grade": 7
  },
  {
    "id": 9010,
    "traditional": "社團",
    "simplified": "社团",
    "reading": "shètuán",
    "meaning": "gild, college, lodge, association, organization, club, body of persons, society, mass organization, order, league, guild, confraternity, corporation",
    "grade": 7
  },
  {
    "id": 9011,
    "traditional": "攝氏度",
    "simplified": "摄氏度",
    "reading": "shèshìdù",
    "meaning": "degrees centigrade",
    "grade": 7
  },
  {
    "id": 9012,
    "traditional": "誰知道",
    "simplified": "谁知道",
    "reading": "shuí zhīdào",
    "meaning": "God knows..., Who would have imagined...?",
    "grade": 7
  },
  {
    "id": 9013,
    "traditional": "涉嫌",
    "simplified": "涉嫌",
    "reading": "shèxián",
    "meaning": "alleged, be a suspect, be suspected of being involved",
    "grade": 7
  },
  {
    "id": 9014,
    "traditional": "申辦",
    "simplified": "申办",
    "reading": "shēnbàn",
    "meaning": "apply for",
    "grade": 7
  },
  {
    "id": 9015,
    "traditional": "申領",
    "simplified": "申领",
    "reading": "shēnlǐng",
    "meaning": "to apply (for license, visa etc)",
    "grade": 7
  },
  {
    "id": 9016,
    "traditional": "伸手",
    "simplified": "伸手",
    "reading": "shēn shǒu",
    "meaning": "ask for help, stretch one's hand, meddle in, stretch, hold out one's hand, stretch out one's hand, reach",
    "grade": 7
  },
  {
    "id": 9017,
    "traditional": "伸縮",
    "simplified": "伸缩",
    "reading": "shēnsuō",
    "meaning": "to lengthen and shorten, flexible, adjustable, retractable, extensible, telescoping (collapsible)",
    "grade": 7
  },
  {
    "id": 9018,
    "traditional": "申報",
    "simplified": "申报",
    "reading": "shēnbào",
    "meaning": "report to higher body, declare (dutiable goods)",
    "grade": 7
  },
  {
    "id": 9019,
    "traditional": "伸張",
    "simplified": "伸张",
    "reading": "shēnzhāng",
    "meaning": "to uphold (e.g. justice or virtue), to promote",
    "grade": 7
  },
  {
    "id": 9020,
    "traditional": "身不由己",
    "simplified": "身不由己",
    "reading": "shēnbùyóujǐ",
    "meaning": "without the freedom to act independently (idiom), involuntary, not of one's own volition, in spite of oneself",
    "grade": 7
  },
  {
    "id": 9021,
    "traditional": "身軀",
    "simplified": "身躯",
    "reading": "shēnqū",
    "meaning": "body",
    "grade": 7
  },
  {
    "id": 9022,
    "traditional": "身價",
    "simplified": "身价",
    "reading": "shēnjià",
    "meaning": "social status, price of a slave, price of a person (a sportsman etc), worth, value (of stocks, valuables etc)",
    "grade": 7
  },
  {
    "id": 9023,
    "traditional": "身心",
    "simplified": "身心",
    "reading": "shēnxīn",
    "meaning": "body and mind",
    "grade": 7
  },
  {
    "id": 9024,
    "traditional": "身影",
    "simplified": "身影",
    "reading": "shēnyǐng",
    "meaning": "figure, silhouette, form",
    "grade": 7
  },
  {
    "id": 9025,
    "traditional": "身子",
    "simplified": "身子",
    "reading": "shēnzi",
    "meaning": "body",
    "grade": 7
  },
  {
    "id": 9026,
    "traditional": "紳士",
    "simplified": "绅士",
    "reading": "shēnshì",
    "meaning": "gentleman",
    "grade": 7
  },
  {
    "id": 9027,
    "traditional": "深切",
    "simplified": "深切",
    "reading": "shēnqiè",
    "meaning": "heartfelt, deep, profound",
    "grade": 7
  },
  {
    "id": 9028,
    "traditional": "深情",
    "simplified": "深情",
    "reading": "shēnqíng",
    "meaning": "deep emotion, deep feeling, deep love",
    "grade": 7
  },
  {
    "id": 9029,
    "traditional": "深奧",
    "simplified": "深奥",
    "reading": "shēnào",
    "meaning": "profound, abstruse, recondite, profoundly",
    "grade": 7
  },
  {
    "id": 9030,
    "traditional": "深入人心",
    "simplified": "深入人心",
    "reading": "shēnrù-rénxīn",
    "meaning": "to enter deeply into people's hearts, to have a real impact on the people (idiom)",
    "grade": 7
  },
  {
    "id": 9031,
    "traditional": "深受",
    "simplified": "深受",
    "reading": "shēnshòu",
    "meaning": "passive signifier",
    "grade": 7
  },
  {
    "id": 9032,
    "traditional": "深信",
    "simplified": "深信",
    "reading": "shēnxìn",
    "meaning": "to believe firmly",
    "grade": 7
  },
  {
    "id": 9033,
    "traditional": "深思",
    "simplified": "深思",
    "reading": "shēnsī",
    "meaning": "ruminate, politic, chew, ponder deeply, consider carefully, speculate, bethink, rumination, chew the cud, perpend",
    "grade": 7
  },
  {
    "id": 9034,
    "traditional": "深遠",
    "simplified": "深远",
    "reading": "shēnyuǎn",
    "meaning": "profound and far-reaching",
    "grade": 7
  },
  {
    "id": 9035,
    "traditional": "深夜",
    "simplified": "深夜",
    "reading": "shēnyè",
    "meaning": "very late at night",
    "grade": 7
  },
  {
    "id": 9036,
    "traditional": "神氣",
    "simplified": "神气",
    "reading": "shénqì",
    "meaning": "expression, manner, vigorous, impressive, lofty, pretentious",
    "grade": 7
  },
  {
    "id": 9037,
    "traditional": "神聖",
    "simplified": "神圣",
    "reading": "shénshèng",
    "meaning": "sacred, holy",
    "grade": 7
  },
  {
    "id": 9038,
    "traditional": "神仙",
    "simplified": "神仙",
    "reading": "shénxian",
    "meaning": "Daoist immortal, supernatural entity, (in modern fiction) fairy, elf, leprechaun etc, fig. lighthearted person",
    "grade": 7
  },
  {
    "id": 9039,
    "traditional": "神態",
    "simplified": "神态",
    "reading": "shéntài",
    "meaning": "appearance, manner, bearing, deportment, look, expression, mien",
    "grade": 7
  },
  {
    "id": 9040,
    "traditional": "審定",
    "simplified": "审定",
    "reading": "shěndìng",
    "meaning": "to examine and approve, to finalize",
    "grade": 7
  },
  {
    "id": 9041,
    "traditional": "審核",
    "simplified": "审核",
    "reading": "shěnhé",
    "meaning": "examine and verify",
    "grade": 7
  },
  {
    "id": 9042,
    "traditional": "審美",
    "simplified": "审美",
    "reading": "shěnměi",
    "meaning": "esthetics, appreciating the arts, taste",
    "grade": 7
  },
  {
    "id": 9043,
    "traditional": "審批",
    "simplified": "审批",
    "reading": "shěnpī",
    "meaning": "to examine and approve, to endorse",
    "grade": 7
  },
  {
    "id": 9044,
    "traditional": "審判",
    "simplified": "审判",
    "reading": "shěnpàn",
    "meaning": "a trial, to try sb",
    "grade": 7
  },
  {
    "id": 9045,
    "traditional": "腎",
    "simplified": "肾",
    "reading": "shèn",
    "meaning": "kidney",
    "grade": 7
  },
  {
    "id": 9046,
    "traditional": "審視",
    "simplified": "审视",
    "reading": "shěnshì",
    "meaning": "to look closely at, to examine",
    "grade": 7
  },
  {
    "id": 9047,
    "traditional": "甚至於",
    "simplified": "甚至于",
    "reading": "shènzhìyú",
    "meaning": "even (to the point of), so much so that",
    "grade": 7
  },
  {
    "id": 9048,
    "traditional": "滲透",
    "simplified": "渗透",
    "reading": "shèntòu",
    "meaning": "to permeate, to infiltrate, to pervade, osmosis",
    "grade": 7
  },
  {
    "id": 9049,
    "traditional": "慎重",
    "simplified": "慎重",
    "reading": "shènzhòng",
    "meaning": "careful, cautious, serious, discreet",
    "grade": 7
  },
  {
    "id": 9050,
    "traditional": "滲",
    "simplified": "渗",
    "reading": "shèn",
    "meaning": "to seep, to ooze, to horrify",
    "grade": 7
  },
  {
    "id": 9051,
    "traditional": "升溫",
    "simplified": "升温",
    "reading": "shēng wēn",
    "meaning": "to become hot, temperature rise, (fig.) to intensify, to hot up, to escalate, to get a boost",
    "grade": 7
  },
  {
    "id": 9052,
    "traditional": "生理",
    "simplified": "生理",
    "reading": "shēnglǐ",
    "meaning": "physiological, peristalsis, autacoid, endocrinology, chyle, diastole, chyme, physiology, phagocyte",
    "grade": 7
  },
  {
    "id": 9053,
    "traditional": "生機",
    "simplified": "生机",
    "reading": "shēngjī",
    "meaning": "animation, verdure, vitality, vital_force, viability, life, verve, lease of life, new lease on life",
    "grade": 7
  },
  {
    "id": 9054,
    "traditional": "生命線",
    "simplified": "生命线",
    "reading": "shēngmìngxiàn",
    "meaning": "lifeline",
    "grade": 7
  },
  {
    "id": 9055,
    "traditional": "生平",
    "simplified": "生平",
    "reading": "shēngpíng",
    "meaning": "life, all one's life, all one's life (of deceased)",
    "grade": 7
  },
  {
    "id": 9056,
    "traditional": "生怕",
    "simplified": "生怕",
    "reading": "shēngpà",
    "meaning": "to fear, afraid, extremely nervous, for fear that, to avoid, so as not to",
    "grade": 7
  },
  {
    "id": 9057,
    "traditional": "生前",
    "simplified": "生前",
    "reading": "shēngqián",
    "meaning": "during lifetime (of deceased)",
    "grade": 7
  },
  {
    "id": 9058,
    "traditional": "生態",
    "simplified": "生态",
    "reading": "shēngtài",
    "meaning": "ecological, ecosystem, biology, ecology, biota",
    "grade": 7
  },
  {
    "id": 9059,
    "traditional": "生死",
    "simplified": "生死",
    "reading": "shēng-sǐ",
    "meaning": "or death, life or death, life and/or death, life and death, life and",
    "grade": 7
  },
  {
    "id": 9060,
    "traditional": "生效",
    "simplified": "生效",
    "reading": "shēng xiào",
    "meaning": "to take effect, to go into effect",
    "grade": 7
  },
  {
    "id": 9061,
    "traditional": "生物",
    "simplified": "生物",
    "reading": "shēngwù",
    "meaning": "living thing, organism",
    "grade": 7
  },
  {
    "id": 9062,
    "traditional": "生涯",
    "simplified": "生涯",
    "reading": "shēngyá",
    "meaning": "career, pilgrimage, profession, time",
    "grade": 7
  },
  {
    "id": 9063,
    "traditional": "生硬",
    "simplified": "生硬",
    "reading": "shēngyìng",
    "meaning": "stiff, harsh",
    "grade": 7
  },
  {
    "id": 9064,
    "traditional": "生育",
    "simplified": "生育",
    "reading": "shēngyù",
    "meaning": "to bear, to give birth, to grow, to rear, to bring up (children)",
    "grade": 7
  },
  {
    "id": 9065,
    "traditional": "聲稱",
    "simplified": "声称",
    "reading": "shēngchēng",
    "meaning": "to claim, to state, to proclaim, to assert",
    "grade": 7
  },
  {
    "id": 9066,
    "traditional": "聲譽",
    "simplified": "声誉",
    "reading": "shēngyù",
    "meaning": "reputation, fame",
    "grade": 7
  },
  {
    "id": 9067,
    "traditional": "聲望",
    "simplified": "声望",
    "reading": "shēngwàng",
    "meaning": "mana, celebrity, character, prestige, odour, repute, fame, name, prestigiousness, caste, renown, reputation, popularity",
    "grade": 7
  },
  {
    "id": 9068,
    "traditional": "繩子",
    "simplified": "绳子",
    "reading": "shéngzi",
    "meaning": "cord, string, rope, CL:條|条[tiao2]",
    "grade": 7
  },
  {
    "id": 9069,
    "traditional": "牲畜",
    "simplified": "牲畜",
    "reading": "shēngchù",
    "meaning": "domesticated animals, livestock",
    "grade": 7
  },
  {
    "id": 9070,
    "traditional": "省略",
    "simplified": "省略",
    "reading": "shěnglüè",
    "meaning": "to leave out, an omission",
    "grade": 7
  },
  {
    "id": 9071,
    "traditional": "省事",
    "simplified": "省事",
    "reading": "shěngshì",
    "meaning": "to simplify matters, to save trouble, to handle administrative work",
    "grade": 7
  },
  {
    "id": 9072,
    "traditional": "勝出",
    "simplified": "胜出",
    "reading": "shèngchū",
    "meaning": "Win",
    "grade": 7
  },
  {
    "id": 9073,
    "traditional": "聖賢",
    "simplified": "圣贤",
    "reading": "shèngxián",
    "meaning": "a sage, wise and holy man, virtuous ruler, Buddhist lama, wine",
    "grade": 7
  },
  {
    "id": 9074,
    "traditional": "勝任",
    "simplified": "胜任",
    "reading": "shèngrèn",
    "meaning": "qualified, competent (professionally), to be up to a task",
    "grade": 7
  },
  {
    "id": 9075,
    "traditional": "盛大",
    "simplified": "盛大",
    "reading": "shèngdà",
    "meaning": "grand, majestic, magnificent, Shanda Entertainment (PRC computer game company)",
    "grade": 7
  },
  {
    "id": 9076,
    "traditional": "盛會",
    "simplified": "盛会",
    "reading": "shènghuì",
    "meaning": "pageant, distinguished meeting",
    "grade": 7
  },
  {
    "id": 9077,
    "traditional": "盛氣凌人",
    "simplified": "盛气凌人",
    "reading": "shèngqì-língrén",
    "meaning": "overbearing, arrogant bully",
    "grade": 7
  },
  {
    "id": 9078,
    "traditional": "剩餘",
    "simplified": "剩余",
    "reading": "shèngyú",
    "meaning": "remainder, surplus",
    "grade": 7
  },
  {
    "id": 9079,
    "traditional": "盛開",
    "simplified": "盛开",
    "reading": "shèngkāi",
    "meaning": "blooming, in full flower",
    "grade": 7
  },
  {
    "id": 9080,
    "traditional": "失傳",
    "simplified": "失传",
    "reading": "shīchuán",
    "meaning": "(of skills etc) to die out, lost, extinct",
    "grade": 7
  },
  {
    "id": 9081,
    "traditional": "屍體",
    "simplified": "尸体",
    "reading": "shītǐ",
    "meaning": "remains, body, corps, corpus, mort, cadaver, stiff, cadaverine, deader, carcass, corpse, ashes, dust, clay, ash, dead body, corse, bones",
    "grade": 7
  },
  {
    "id": 9082,
    "traditional": "失控",
    "simplified": "失控",
    "reading": "shī kòng",
    "meaning": "to go out of control",
    "grade": 7
  },
  {
    "id": 9083,
    "traditional": "失利",
    "simplified": "失利",
    "reading": "shī lì",
    "meaning": "to lose, to suffer defeat",
    "grade": 7
  },
  {
    "id": 9084,
    "traditional": "失戀",
    "simplified": "失恋",
    "reading": "shī liàn",
    "meaning": "to lose one's love, to break up (in a romantic relationship), to feel jilted",
    "grade": 7
  },
  {
    "id": 9085,
    "traditional": "失靈",
    "simplified": "失灵",
    "reading": "shī líng",
    "meaning": "out of order (of machine), not working properly, a failing (of a system)",
    "grade": 7
  },
  {
    "id": 9086,
    "traditional": "失眠",
    "simplified": "失眠",
    "reading": "shī mián",
    "meaning": "to suffer from insomnia",
    "grade": 7
  },
  {
    "id": 9087,
    "traditional": "失明",
    "simplified": "失明",
    "reading": "shī míng",
    "meaning": "to lose one's eyesight, to become blind, blindness",
    "grade": 7
  },
  {
    "id": 9088,
    "traditional": "失落",
    "simplified": "失落",
    "reading": "shīluò",
    "meaning": "lose",
    "grade": 7
  },
  {
    "id": 9089,
    "traditional": "失效",
    "simplified": "失效",
    "reading": "shī xiào",
    "meaning": "to fail, to lose effectiveness",
    "grade": 7
  },
  {
    "id": 9090,
    "traditional": "失蹤",
    "simplified": "失踪",
    "reading": "shī zōng",
    "meaning": "be missing",
    "grade": 7
  },
  {
    "id": 9091,
    "traditional": "失業率",
    "simplified": "失业率",
    "reading": "shīyèlǜ",
    "meaning": "unemployment rate",
    "grade": 7
  },
  {
    "id": 9092,
    "traditional": "師範",
    "simplified": "师范",
    "reading": "shīfàn",
    "meaning": "teacher-training, pedagogical",
    "grade": 7
  },
  {
    "id": 9093,
    "traditional": "師長",
    "simplified": "师长",
    "reading": "shīzhǎng",
    "meaning": "teacher",
    "grade": 7
  },
  {
    "id": 9094,
    "traditional": "獅子",
    "simplified": "狮子",
    "reading": "shīzi",
    "meaning": "leonine, Panthera leo, king of beasts, lion",
    "grade": 7
  },
  {
    "id": 9095,
    "traditional": "師資",
    "simplified": "师资",
    "reading": "shīzī",
    "meaning": "persons qualifies to teach, teachers, person qualified to teach",
    "grade": 7
  },
  {
    "id": 9096,
    "traditional": "施工",
    "simplified": "施工",
    "reading": "shī gōng",
    "meaning": "work on (of construction)",
    "grade": 7
  },
  {
    "id": 9097,
    "traditional": "施加",
    "simplified": "施加",
    "reading": "shījiā",
    "meaning": "to exert (effort or pressure)",
    "grade": 7
  },
  {
    "id": 9098,
    "traditional": "施行",
    "simplified": "施行",
    "reading": "shīxíng",
    "meaning": "put in force, execute, implement, apply, perform",
    "grade": 7
  },
  {
    "id": 9099,
    "traditional": "施壓",
    "simplified": "施压",
    "reading": "shīyā",
    "meaning": "to pressure",
    "grade": 7
  },
  {
    "id": 9100,
    "traditional": "濕潤",
    "simplified": "湿润",
    "reading": "shīrùn",
    "meaning": "moist",
    "grade": 7
  },
  {
    "id": 9101,
    "traditional": "濕度",
    "simplified": "湿度",
    "reading": "shīdù",
    "meaning": "humidity level",
    "grade": 7
  },
  {
    "id": 9102,
    "traditional": "十字路口",
    "simplified": "十字路口",
    "reading": "shízì lùkǒu",
    "meaning": "crossroads, intersection",
    "grade": 7
  },
  {
    "id": 9103,
    "traditional": "時段",
    "simplified": "时段",
    "reading": "shíduàn",
    "meaning": "slot, time slot, period of time",
    "grade": 7
  },
  {
    "id": 9104,
    "traditional": "時不時",
    "simplified": "时不时",
    "reading": "shíbùshí",
    "meaning": "from time to time",
    "grade": 7
  },
  {
    "id": 9105,
    "traditional": "時隔",
    "simplified": "时隔",
    "reading": "shígé",
    "meaning": "separated in time (usu. followed by a quantity of time)",
    "grade": 7
  },
  {
    "id": 9106,
    "traditional": "時間表",
    "simplified": "时间表",
    "reading": "shíjiānbiǎo",
    "meaning": "time_sheet, schedule, timetable, time sheet",
    "grade": 7
  },
  {
    "id": 9107,
    "traditional": "時好時壞",
    "simplified": "时好时坏",
    "reading": "shíhǎoshíhuài",
    "meaning": "sometimes good, sometimes bad",
    "grade": 7
  },
  {
    "id": 9108,
    "traditional": "時髦",
    "simplified": "时髦",
    "reading": "shímáo",
    "meaning": "in vogue, fashionable",
    "grade": 7
  },
  {
    "id": 9109,
    "traditional": "時尚",
    "simplified": "时尚",
    "reading": "shíshàng",
    "meaning": "fashion, fad, fashionable",
    "grade": 7
  },
  {
    "id": 9110,
    "traditional": "時空",
    "simplified": "时空",
    "reading": "shíkōng",
    "meaning": "time and space, space-time",
    "grade": 7
  },
  {
    "id": 9111,
    "traditional": "時速",
    "simplified": "时速",
    "reading": "shísù",
    "meaning": "speed per hour",
    "grade": 7
  },
  {
    "id": 9112,
    "traditional": "識別",
    "simplified": "识别",
    "reading": "shíbié",
    "meaning": "to distinguish, to discern",
    "grade": 7
  },
  {
    "id": 9113,
    "traditional": "實話",
    "simplified": "实话",
    "reading": "shíhuà",
    "meaning": "truth",
    "grade": 7
  },
  {
    "id": 9114,
    "traditional": "實地",
    "simplified": "实地",
    "reading": "shídì",
    "meaning": "concretely, on the ground, on the ground/spot, spot, on the spot",
    "grade": 7
  },
  {
    "id": 9115,
    "traditional": "實話實說",
    "simplified": "实话实说",
    "reading": "shíhuà-shíshuō",
    "meaning": "to tell the truth, to tell it as it is",
    "grade": 7
  },
  {
    "id": 9116,
    "traditional": "實事求是",
    "simplified": "实事求是",
    "reading": "shíshì-qiúshì",
    "meaning": "to seek truth from facts (idiom), to be practical and realistic",
    "grade": 7
  },
  {
    "id": 9117,
    "traditional": "實體",
    "simplified": "实体",
    "reading": "shítǐ",
    "meaning": "essence, reality, entity, substantive, substantiality, substantialness, existence, substratum, noumenon, substance, materiality",
    "grade": 7
  },
  {
    "id": 9118,
    "traditional": "實況",
    "simplified": "实况",
    "reading": "shíkuàng",
    "meaning": "actual situation/happening",
    "grade": 7
  },
  {
    "id": 9119,
    "traditional": "實質",
    "simplified": "实质",
    "reading": "shízhì",
    "meaning": "quintessence, substantial, essence, meat, parenchyma, kernel, substantiality, measure, being, quiddity, solidity, substance, matter",
    "grade": 7
  },
  {
    "id": 9120,
    "traditional": "實物",
    "simplified": "实物",
    "reading": "shíwù",
    "meaning": "material object, concrete object, original object, in kind, object for practical use, definite thing, reality, matter (physics)",
    "grade": 7
  },
  {
    "id": 9121,
    "traditional": "食宿",
    "simplified": "食宿",
    "reading": "shí-sù",
    "meaning": "board and lodging, room and board",
    "grade": 7
  },
  {
    "id": 9122,
    "traditional": "食用",
    "simplified": "食用",
    "reading": "shíyòng",
    "meaning": "food product, to use as food, edible",
    "grade": 7
  },
  {
    "id": 9123,
    "traditional": "史無前例",
    "simplified": "史无前例",
    "reading": "shǐwúqiánlì",
    "meaning": "unprecedented in history",
    "grade": 7
  },
  {
    "id": 9124,
    "traditional": "使喚",
    "simplified": "使唤",
    "reading": "shǐhuan",
    "meaning": "to order sb about, to use sb",
    "grade": 7
  },
  {
    "id": 9125,
    "traditional": "使命",
    "simplified": "使命",
    "reading": "shǐmìng",
    "meaning": "vocation, calling, military mission, mission, embassy, errand",
    "grade": 7
  },
  {
    "id": 9126,
    "traditional": "使者",
    "simplified": "使者",
    "reading": "shǐzhě",
    "meaning": "emissary, envoy",
    "grade": 7
  },
  {
    "id": 9127,
    "traditional": "士氣",
    "simplified": "士气",
    "reading": "shìqì",
    "meaning": "morale",
    "grade": 7
  },
  {
    "id": 9128,
    "traditional": "示威",
    "simplified": "示威",
    "reading": "shì wēi",
    "meaning": "put on show of force, demonstrate, march",
    "grade": 7
  },
  {
    "id": 9129,
    "traditional": "示意",
    "simplified": "示意",
    "reading": "shìyì",
    "meaning": "to hint, to indicate (an idea to sb)",
    "grade": 7
  },
  {
    "id": 9130,
    "traditional": "世代",
    "simplified": "世代",
    "reading": "shìdài",
    "meaning": "generation, period, epoch",
    "grade": 7
  },
  {
    "id": 9131,
    "traditional": "世界級",
    "simplified": "世界级",
    "reading": "shìjièjí",
    "meaning": "world-class",
    "grade": 7
  },
  {
    "id": 9132,
    "traditional": "世故",
    "simplified": "世故",
    "reading": "shìgu",
    "meaning": "the ways of the world, sophisticated, worldly-wise",
    "grade": 7
  },
  {
    "id": 9133,
    "traditional": "世襲",
    "simplified": "世袭",
    "reading": "shìxí",
    "meaning": "succession, inheritance, hereditary",
    "grade": 7
  },
  {
    "id": 9134,
    "traditional": "市場經濟",
    "simplified": "市场经济",
    "reading": "shìchǎng jīngjì",
    "meaning": "market economy",
    "grade": 7
  },
  {
    "id": 9135,
    "traditional": "勢必",
    "simplified": "势必",
    "reading": "shìbì",
    "meaning": "certainly will, be bound to",
    "grade": 7
  },
  {
    "id": 9136,
    "traditional": "勢頭",
    "simplified": "势头",
    "reading": "shìtou",
    "meaning": "power, momentum, tendency, impetus, situation, the look of things",
    "grade": 7
  },
  {
    "id": 9137,
    "traditional": "勢不可當",
    "simplified": "势不可当",
    "reading": "shìbùkědāng",
    "meaning": "impossible to resist (idiom), an irresistible force",
    "grade": 7
  },
  {
    "id": 9138,
    "traditional": "事務",
    "simplified": "事务",
    "reading": "shìwù",
    "meaning": "pursuit, shebang, routine, affair, work, concern, general affairs, desk, business, matter, transaction",
    "grade": 7
  },
  {
    "id": 9139,
    "traditional": "事態",
    "simplified": "事态",
    "reading": "shìtài",
    "meaning": "situation, existing state of affairs",
    "grade": 7
  },
  {
    "id": 9140,
    "traditional": "事務所",
    "simplified": "事务所",
    "reading": "shìwùsuǒ",
    "meaning": "office, business office, business premises",
    "grade": 7
  },
  {
    "id": 9141,
    "traditional": "事項",
    "simplified": "事项",
    "reading": "shìxiàng",
    "meaning": "issue, item, topic, subject, particular, transaction, matter",
    "grade": 7
  },
  {
    "id": 9142,
    "traditional": "事跡",
    "simplified": "事迹",
    "reading": "shìjì",
    "meaning": "deed, past achievement, important event of the past",
    "grade": 7
  },
  {
    "id": 9143,
    "traditional": "侍候",
    "simplified": "侍候",
    "reading": "shìhòu",
    "meaning": "to serve, to wait upon",
    "grade": 7
  },
  {
    "id": 9144,
    "traditional": "試探",
    "simplified": "试探",
    "reading": "shìtan",
    "meaning": "to sound out, to probe, to feel out, to try out",
    "grade": 7
  },
  {
    "id": 9145,
    "traditional": "事宜",
    "simplified": "事宜",
    "reading": "shìyí",
    "meaning": "arrangements, matters concerned, affairs, arrangement, personal business",
    "grade": 7
  },
  {
    "id": 9146,
    "traditional": "試行",
    "simplified": "试行",
    "reading": "shìxíng",
    "meaning": "to try out, to test",
    "grade": 7
  },
  {
    "id": 9147,
    "traditional": "試用期",
    "simplified": "试用期",
    "reading": "shìyòngqī",
    "meaning": "trial period, probation",
    "grade": 7
  },
  {
    "id": 9148,
    "traditional": "試用",
    "simplified": "试用",
    "reading": "shìyòng",
    "meaning": "try out",
    "grade": 7
  },
  {
    "id": 9149,
    "traditional": "視角",
    "simplified": "视角",
    "reading": "shìjiǎo",
    "meaning": "viewpoint, angle on sth, perspective",
    "grade": 7
  },
  {
    "id": 9150,
    "traditional": "視覺",
    "simplified": "视觉",
    "reading": "shìjué",
    "meaning": "seeing, vision, visual sense, visual sensation, ocular, eyesight, sight, visual",
    "grade": 7
  },
  {
    "id": 9151,
    "traditional": "視察",
    "simplified": "视察",
    "reading": "shìchá",
    "meaning": "to inspect, an investigation",
    "grade": 7
  },
  {
    "id": 9152,
    "traditional": "視線",
    "simplified": "视线",
    "reading": "shìxiàn",
    "meaning": "line of sight",
    "grade": 7
  },
  {
    "id": 9153,
    "traditional": "視野",
    "simplified": "视野",
    "reading": "shìyě",
    "meaning": "visual_field, eyeshot, view, ken, horizon, visual field, field of regard, purview, field of view, coast, sight, eyesight, field of vision, field, sight-shot, eyereach",
    "grade": 7
  },
  {
    "id": 9154,
    "traditional": "視力",
    "simplified": "视力",
    "reading": "shìlì",
    "meaning": "eyeshot, eye, seeing, visual sense, view, beholding, visual perception, vision, visual modality, light, visual sensation, eyesight, sight, sightedness",
    "grade": 7
  },
  {
    "id": 9155,
    "traditional": "是非",
    "simplified": "是非",
    "reading": "shìfēi",
    "meaning": "right and wrong, quarrel, dispute",
    "grade": 7
  },
  {
    "id": 9156,
    "traditional": "適度",
    "simplified": "适度",
    "reading": "shìdù",
    "meaning": "moderate",
    "grade": 7
  },
  {
    "id": 9157,
    "traditional": "柿子",
    "simplified": "柿子",
    "reading": "shìzi",
    "meaning": "persimmon, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 9158,
    "traditional": "適宜",
    "simplified": "适宜",
    "reading": "shìyí",
    "meaning": "suitable, appropriate",
    "grade": 7
  },
  {
    "id": 9159,
    "traditional": "適時",
    "simplified": "适时",
    "reading": "shìshí",
    "meaning": "at right moment, early, timely, betimes",
    "grade": 7
  },
  {
    "id": 9160,
    "traditional": "釋放",
    "simplified": "释放",
    "reading": "shìfàng",
    "meaning": "release, set free",
    "grade": 7
  },
  {
    "id": 9161,
    "traditional": "適量",
    "simplified": "适量",
    "reading": "shìliàng",
    "meaning": "appropriate amount",
    "grade": 7
  },
  {
    "id": 9162,
    "traditional": "嗜好",
    "simplified": "嗜好",
    "reading": "shìhào",
    "meaning": "hobby, indulgence, habit, addiction",
    "grade": 7
  },
  {
    "id": 9163,
    "traditional": "收復",
    "simplified": "收复",
    "reading": "shōufù",
    "meaning": "to recover (lost territory etc), to recapture",
    "grade": 7
  },
  {
    "id": 9164,
    "traditional": "收據",
    "simplified": "收据",
    "reading": "shōujù",
    "meaning": "receipt, CL:張|张[zhang1]",
    "grade": 7
  },
  {
    "id": 9165,
    "traditional": "收斂",
    "simplified": "收敛",
    "reading": "shōuliǎn",
    "meaning": "to vanish, to moderate, to exercise restraint, to curb (one's mirth, arrogance etc), to astringe, (math.) to converge",
    "grade": 7
  },
  {
    "id": 9166,
    "traditional": "收買",
    "simplified": "收买",
    "reading": "shōumǎi",
    "meaning": "to purchase, to bribe",
    "grade": 7
  },
  {
    "id": 9167,
    "traditional": "逝世",
    "simplified": "逝世",
    "reading": "shìshì",
    "meaning": "to pass away, to die",
    "grade": 7
  },
  {
    "id": 9168,
    "traditional": "收留",
    "simplified": "收留",
    "reading": "shōuliú",
    "meaning": "to offer shelter, to have sb in one's care",
    "grade": 7
  },
  {
    "id": 9169,
    "traditional": "收縮",
    "simplified": "收缩",
    "reading": "shōusuō",
    "meaning": "to pull back, to shrink, to contract, (physiology) systole",
    "grade": 7
  },
  {
    "id": 9170,
    "traditional": "收視率",
    "simplified": "收视率",
    "reading": "shōushìlǜ",
    "meaning": "ratings",
    "grade": 7
  },
  {
    "id": 9171,
    "traditional": "收支",
    "simplified": "收支",
    "reading": "shōuzhī",
    "meaning": "cash flow, financial balance, income and expenditure",
    "grade": 7
  },
  {
    "id": 9172,
    "traditional": "手臂",
    "simplified": "手臂",
    "reading": "shǒubì",
    "meaning": "arm",
    "grade": 7
  },
  {
    "id": 9173,
    "traditional": "手冊",
    "simplified": "手册",
    "reading": "shǒucè",
    "meaning": "companion, enchiridion, pamphlet, guidebook, notebook, vade_mecum, leaflet, handbook, booklet, brochure, folder, manual, vade mecum, workbook, guide",
    "grade": 7
  },
  {
    "id": 9174,
    "traditional": "手動",
    "simplified": "手动",
    "reading": "shǒudòng",
    "meaning": "manual, manually operated, manual gear-change",
    "grade": 7
  },
  {
    "id": 9175,
    "traditional": "手帕",
    "simplified": "手帕",
    "reading": "shǒupà",
    "meaning": "handkerchief",
    "grade": 7
  },
  {
    "id": 9176,
    "traditional": "手腳",
    "simplified": "手脚",
    "reading": "shǒujiǎo",
    "meaning": "hand and foot, motions (of hands/feet), underhand method, trick",
    "grade": 7
  },
  {
    "id": 9177,
    "traditional": "手槍",
    "simplified": "手枪",
    "reading": "shǒuqiāng",
    "meaning": "pistol",
    "grade": 7
  },
  {
    "id": 9178,
    "traditional": "手勢",
    "simplified": "手势",
    "reading": "shǒushì",
    "meaning": "sign, pantomime, gesticulation, motion, sign_language, gesture, signal",
    "grade": 7
  },
  {
    "id": 9179,
    "traditional": "手術室",
    "simplified": "手术室",
    "reading": "shǒushùshì",
    "meaning": "Operating room",
    "grade": 7
  },
  {
    "id": 9180,
    "traditional": "手腕",
    "simplified": "手腕",
    "reading": "shǒuwàn",
    "meaning": "wrist",
    "grade": 7
  },
  {
    "id": 9181,
    "traditional": "手頭",
    "simplified": "手头",
    "reading": "shǒutóu",
    "meaning": "in hand (e.g. cash)",
    "grade": 7
  },
  {
    "id": 9182,
    "traditional": "手藝",
    "simplified": "手艺",
    "reading": "shǒuyì",
    "meaning": "craftmanship, workmanship, handicraft, trade",
    "grade": 7
  },
  {
    "id": 9183,
    "traditional": "手掌",
    "simplified": "手掌",
    "reading": "shǒuzhǎng",
    "meaning": "palm",
    "grade": 7
  },
  {
    "id": 9184,
    "traditional": "守候",
    "simplified": "守候",
    "reading": "shǒuhòu",
    "meaning": "to wait for, to expect, to keep watch, to watch over, to nurse",
    "grade": 7
  },
  {
    "id": 9185,
    "traditional": "守株待兔",
    "simplified": "守株待兔",
    "reading": "shǒuzhū-dàitù",
    "meaning": "lit. to guard a tree-stump, waiting for rabbits (idiom), to wait idly for opportunities, to trust to chance rather than show initiative",
    "grade": 7
  },
  {
    "id": 9186,
    "traditional": "首創",
    "simplified": "首创",
    "reading": "shǒuchuàng",
    "meaning": "to create, original creation, to be the first to do sth",
    "grade": 7
  },
  {
    "id": 9187,
    "traditional": "守護",
    "simplified": "守护",
    "reading": "shǒuhù",
    "meaning": "to guard, to protect",
    "grade": 7
  },
  {
    "id": 9188,
    "traditional": "首府",
    "simplified": "首府",
    "reading": "shǒufǔ",
    "meaning": "capital city of an autonomous region",
    "grade": 7
  },
  {
    "id": 9189,
    "traditional": "首批",
    "simplified": "首批",
    "reading": "shǒupī",
    "meaning": "First batch",
    "grade": 7
  },
  {
    "id": 9190,
    "traditional": "首飾",
    "simplified": "首饰",
    "reading": "shǒushi",
    "meaning": "jewelry, head ornament",
    "grade": 7
  },
  {
    "id": 9191,
    "traditional": "首要",
    "simplified": "首要",
    "reading": "shǒuyào",
    "meaning": "chief, paramountcy, premiership, primacy, of first importance",
    "grade": 7
  },
  {
    "id": 9192,
    "traditional": "受過",
    "simplified": "受过",
    "reading": "shòu guò",
    "meaning": "Suffer",
    "grade": 7
  },
  {
    "id": 9193,
    "traditional": "壽命",
    "simplified": "寿命",
    "reading": "shòumìng",
    "meaning": "life",
    "grade": 7
  },
  {
    "id": 9194,
    "traditional": "受害人",
    "simplified": "受害人",
    "reading": "shòuhàirén",
    "meaning": "victim",
    "grade": 7
  },
  {
    "id": 9195,
    "traditional": "受害",
    "simplified": "受害",
    "reading": "shòu hài",
    "meaning": "suffer injury, suffer loss, fall victim, loss, suffer, be affected, victimize, be victimized",
    "grade": 7
  },
  {
    "id": 9196,
    "traditional": "受賄",
    "simplified": "受贿",
    "reading": "shòu huì",
    "meaning": "to accept a bribe, bribery",
    "grade": 7
  },
  {
    "id": 9197,
    "traditional": "受驚",
    "simplified": "受惊",
    "reading": "shòu jīng",
    "meaning": "startled",
    "grade": 7
  },
  {
    "id": 9198,
    "traditional": "受苦",
    "simplified": "受苦",
    "reading": "shòu kǔ",
    "meaning": "to suffer hardship",
    "grade": 7
  },
  {
    "id": 9199,
    "traditional": "受理",
    "simplified": "受理",
    "reading": "shòulǐ",
    "meaning": "accept and hear a case",
    "grade": 7
  },
  {
    "id": 9200,
    "traditional": "受益",
    "simplified": "受益",
    "reading": "shòu yì",
    "meaning": "to benefit from, profit",
    "grade": 7
  },
  {
    "id": 9201,
    "traditional": "授權",
    "simplified": "授权",
    "reading": "shòuquán",
    "meaning": "empower, authorize",
    "grade": 7
  },
  {
    "id": 9202,
    "traditional": "受騙",
    "simplified": "受骗",
    "reading": "shòu piàn",
    "meaning": "be taken in, be fooled, be cheated, be deceived, bite",
    "grade": 7
  },
  {
    "id": 9203,
    "traditional": "售價",
    "simplified": "售价",
    "reading": "shòujià",
    "meaning": "asking price, selling price, selling_price, sell price, price",
    "grade": 7
  },
  {
    "id": 9204,
    "traditional": "授予",
    "simplified": "授予",
    "reading": "shòuyǔ",
    "meaning": "to award, to confer",
    "grade": 7
  },
  {
    "id": 9205,
    "traditional": "售票",
    "simplified": "售票",
    "reading": "shòu piào",
    "meaning": "Ticket sales",
    "grade": 7
  },
  {
    "id": 9206,
    "traditional": "書櫥",
    "simplified": "书橱",
    "reading": "shūchú",
    "meaning": "bookcase",
    "grade": 7
  },
  {
    "id": 9207,
    "traditional": "書籍",
    "simplified": "书籍",
    "reading": "shūjí",
    "meaning": "volume, page, classic, books, fascicule, works, literature",
    "grade": 7
  },
  {
    "id": 9208,
    "traditional": "書記",
    "simplified": "书记",
    "reading": "shūji",
    "meaning": "scribe, penpusher, clerkship, amanuensis, secretarial assistant, pencil pusher, actuary, recorder, secretary, secretariat, clerk, scrivener",
    "grade": 7
  },
  {
    "id": 9209,
    "traditional": "書面",
    "simplified": "书面",
    "reading": "shūmiàn",
    "meaning": "written, in written form, in writing",
    "grade": 7
  },
  {
    "id": 9210,
    "traditional": "書寫",
    "simplified": "书写",
    "reading": "shūxiě",
    "meaning": "write",
    "grade": 7
  },
  {
    "id": 9211,
    "traditional": "抒情",
    "simplified": "抒情",
    "reading": "shūqíng",
    "meaning": "to express emotion, lyric",
    "grade": 7
  },
  {
    "id": 9212,
    "traditional": "梳",
    "simplified": "梳",
    "reading": "shū",
    "meaning": "a comb, to comb",
    "grade": 7
  },
  {
    "id": 9213,
    "traditional": "樞紐",
    "simplified": "枢纽",
    "reading": "shūniǔ",
    "meaning": "hub (e.g. of traffic network), hinge, pivot, fulcrum",
    "grade": 7
  },
  {
    "id": 9214,
    "traditional": "梳子",
    "simplified": "梳子",
    "reading": "shūzi",
    "meaning": "comb, CL:把[ba3]",
    "grade": 7
  },
  {
    "id": 9215,
    "traditional": "梳理",
    "simplified": "梳理",
    "reading": "shūlǐ",
    "meaning": "to comb, fig. to sort out",
    "grade": 7
  },
  {
    "id": 9216,
    "traditional": "舒暢",
    "simplified": "舒畅",
    "reading": "shūchàng",
    "meaning": "happy, entirely free from worry",
    "grade": 7
  },
  {
    "id": 9217,
    "traditional": "疏導",
    "simplified": "疏导",
    "reading": "shūdǎo",
    "meaning": "to dredge, to open up a path for, to remove obstructions, to clear the way, to enlighten, persuasion",
    "grade": 7
  },
  {
    "id": 9218,
    "traditional": "疏散",
    "simplified": "疏散",
    "reading": "shūsàn",
    "meaning": "to scatter, to disperse, to evacuate, scattered, to relax",
    "grade": 7
  },
  {
    "id": 9219,
    "traditional": "疏通",
    "simplified": "疏通",
    "reading": "shūtōng",
    "meaning": "to unblock, to dredge, to clear the way, to get things flowing, to facilitate, to mediate, to lobby, to explicate (a text)",
    "grade": 7
  },
  {
    "id": 9220,
    "traditional": "輸家",
    "simplified": "输家",
    "reading": "shūjia",
    "meaning": "loser",
    "grade": 7
  },
  {
    "id": 9221,
    "traditional": "疏忽",
    "simplified": "疏忽",
    "reading": "shūhu",
    "meaning": "ignore, neglect",
    "grade": 7
  },
  {
    "id": 9222,
    "traditional": "輸送",
    "simplified": "输送",
    "reading": "shūsòng",
    "meaning": "to transport, to convey, to deliver",
    "grade": 7
  },
  {
    "id": 9223,
    "traditional": "輸血",
    "simplified": "输血",
    "reading": "shū xuè",
    "meaning": "to transfuse blood, to give aid and support",
    "grade": 7
  },
  {
    "id": 9224,
    "traditional": "贖",
    "simplified": "赎",
    "reading": "shú",
    "meaning": "to redeem, to ransom",
    "grade": 7
  },
  {
    "id": 9225,
    "traditional": "輸液",
    "simplified": "输液",
    "reading": "shū yè",
    "meaning": "intravenous infusion, to get put on an IV",
    "grade": 7
  },
  {
    "id": 9226,
    "traditional": "屬性",
    "simplified": "属性",
    "reading": "shǔxìng",
    "meaning": "attribute, property",
    "grade": 7
  },
  {
    "id": 9227,
    "traditional": "曙光",
    "simplified": "曙光",
    "reading": "shǔguāng",
    "meaning": "dawn, fig. the dawn of a new era",
    "grade": 7
  },
  {
    "id": 9228,
    "traditional": "暑期",
    "simplified": "暑期",
    "reading": "shǔqī",
    "meaning": "summer vacation time",
    "grade": 7
  },
  {
    "id": 9229,
    "traditional": "束縛",
    "simplified": "束缚",
    "reading": "shùfù",
    "meaning": "tie up, fetter",
    "grade": 7
  },
  {
    "id": 9230,
    "traditional": "樹木",
    "simplified": "树木",
    "reading": "shùmù",
    "meaning": "trees",
    "grade": 7
  },
  {
    "id": 9231,
    "traditional": "樹立",
    "simplified": "树立",
    "reading": "shùlì",
    "meaning": "to set up, to establish",
    "grade": 7
  },
  {
    "id": 9232,
    "traditional": "樹梢",
    "simplified": "树梢",
    "reading": "shùshāo",
    "meaning": "the tip of a tree, treetop",
    "grade": 7
  },
  {
    "id": 9233,
    "traditional": "樹蔭",
    "simplified": "树荫",
    "reading": "shùyīn",
    "meaning": "shade of a tree",
    "grade": 7
  },
  {
    "id": 9234,
    "traditional": "竪",
    "simplified": "竖",
    "reading": "shù",
    "meaning": "to erect, vertical, vertical stroke (in Chinese characters)",
    "grade": 7
  },
  {
    "id": 9235,
    "traditional": "樹枝",
    "simplified": "树枝",
    "reading": "shùzhī",
    "meaning": "branch, twig",
    "grade": 7
  },
  {
    "id": 9236,
    "traditional": "數據庫",
    "simplified": "数据库",
    "reading": "shùjùkù",
    "meaning": "database",
    "grade": 7
  },
  {
    "id": 9237,
    "traditional": "刷新",
    "simplified": "刷新",
    "reading": "shuāxīn",
    "meaning": "to renovate, to refurbish, to refresh (computer window), to write a new page (in history), to break (a record)",
    "grade": 7
  },
  {
    "id": 9238,
    "traditional": "耍",
    "simplified": "耍",
    "reading": "shuǎ",
    "meaning": "surname Shua, to play with, to wield, to act (cool etc), to display (a skill, one's temper etc)",
    "grade": 7
  },
  {
    "id": 9239,
    "traditional": "數額",
    "simplified": "数额",
    "reading": "shùé",
    "meaning": "amount, sum of money, fixed number",
    "grade": 7
  },
  {
    "id": 9240,
    "traditional": "耍賴",
    "simplified": "耍赖",
    "reading": "shuǎlài",
    "meaning": "to act shamelessly, to refuse to acknowledge that one has lost the game, or made a promise etc, to act dumb, to act as if sth never happened",
    "grade": 7
  },
  {
    "id": 9241,
    "traditional": "衰減",
    "simplified": "衰减",
    "reading": "shuāijiǎn",
    "meaning": "to weaken, to attenuate",
    "grade": 7
  },
  {
    "id": 9242,
    "traditional": "衰竭",
    "simplified": "衰竭",
    "reading": "shuāijié",
    "meaning": "organ failure, exhaustion, prostration (medicine)",
    "grade": 7
  },
  {
    "id": 9243,
    "traditional": "衰弱",
    "simplified": "衰弱",
    "reading": "shuāiruò",
    "meaning": "weak, feeble",
    "grade": 7
  },
  {
    "id": 9244,
    "traditional": "衰老",
    "simplified": "衰老",
    "reading": "shuāilǎo",
    "meaning": "to age, to deteriorate with age, old and weak",
    "grade": 7
  },
  {
    "id": 9245,
    "traditional": "衰退",
    "simplified": "衰退",
    "reading": "shuāituì",
    "meaning": "fail, decline",
    "grade": 7
  },
  {
    "id": 9246,
    "traditional": "摔跤",
    "simplified": "摔跤",
    "reading": "shuāi jiāo",
    "meaning": "to trip and fall, to wrestle, wrestling (sports)",
    "grade": 7
  },
  {
    "id": 9247,
    "traditional": "甩",
    "simplified": "甩",
    "reading": "shuǎi",
    "meaning": "to throw, to fling, to swing, to leave behind, to throw off, to dump (sb)",
    "grade": 7
  },
  {
    "id": 9248,
    "traditional": "涮",
    "simplified": "涮",
    "reading": "shuàn",
    "meaning": "to rinse, to trick, to fool sb, to cook by dipping finely sliced ingredients briefly in boiling water or soup (generally done at the dining table)",
    "grade": 7
  },
  {
    "id": 9249,
    "traditional": "雙胞胎",
    "simplified": "双胞胎",
    "reading": "shuāngbāotāi",
    "meaning": "twin, CL:對|对[dui4]",
    "grade": 7
  },
  {
    "id": 9250,
    "traditional": "拴",
    "simplified": "拴",
    "reading": "shuān",
    "meaning": "to tie up",
    "grade": 7
  },
  {
    "id": 9251,
    "traditional": "雙邊",
    "simplified": "双边",
    "reading": "shuāngbiān",
    "meaning": "bilateral",
    "grade": 7
  },
  {
    "id": 9252,
    "traditional": "雙重",
    "simplified": "双重",
    "reading": "shuāngchóng",
    "meaning": "twofold, dual, diploid, double",
    "grade": 7
  },
  {
    "id": 9253,
    "traditional": "雙向",
    "simplified": "双向",
    "reading": "shuāngxiàng",
    "meaning": "bipartite, double-edged, two-sided, bidirectional, double-faced",
    "grade": 7
  },
  {
    "id": 9254,
    "traditional": "霜",
    "simplified": "霜",
    "reading": "shuāng",
    "meaning": "frost, white powder or cream spread over a surface, frosting, (skin) cream",
    "grade": 7
  },
  {
    "id": 9255,
    "traditional": "雙贏",
    "simplified": "双赢",
    "reading": "shuāngyíng",
    "meaning": "profitable to both sides, a win-win situation",
    "grade": 7
  },
  {
    "id": 9256,
    "traditional": "爽快",
    "simplified": "爽快",
    "reading": "shuǎngkuai",
    "meaning": "refreshed, rejuvenated, frank and straightforward",
    "grade": 7
  },
  {
    "id": 9257,
    "traditional": "水稻",
    "simplified": "水稻",
    "reading": "shuǐdào",
    "meaning": "rice, paddy, CL:株[zhu1]",
    "grade": 7
  },
  {
    "id": 9258,
    "traditional": "水管",
    "simplified": "水管",
    "reading": "shuǐguǎn",
    "meaning": "water pipe",
    "grade": 7
  },
  {
    "id": 9259,
    "traditional": "水壺",
    "simplified": "水壶",
    "reading": "shuǐhú",
    "meaning": "kettle, canteen, watering can",
    "grade": 7
  },
  {
    "id": 9260,
    "traditional": "水槽",
    "simplified": "水槽",
    "reading": "shuǐcáo",
    "meaning": "sink",
    "grade": 7
  },
  {
    "id": 9261,
    "traditional": "水晶",
    "simplified": "水晶",
    "reading": "shuǐjīng",
    "meaning": "quartz, rock crystal, rock_crystal, rhinestone, crystal, pebble",
    "grade": 7
  },
  {
    "id": 9262,
    "traditional": "水利",
    "simplified": "水利",
    "reading": "shuǐlì",
    "meaning": "water conservancy, irrigation works",
    "grade": 7
  },
  {
    "id": 9263,
    "traditional": "水貨",
    "simplified": "水货",
    "reading": "shuǐhuò",
    "meaning": "smuggled goods, unauthorized goods",
    "grade": 7
  },
  {
    "id": 9264,
    "traditional": "水靈靈",
    "simplified": "水灵灵",
    "reading": "shuǐlínglíng",
    "meaning": "vivid, full of life, fresh",
    "grade": 7
  },
  {
    "id": 9265,
    "traditional": "水落石出",
    "simplified": "水落石出",
    "reading": "shuǐluò-shíchū",
    "meaning": "as the water recedes, the rocks appear (idiom), the truth comes to light",
    "grade": 7
  },
  {
    "id": 9266,
    "traditional": "水面",
    "simplified": "水面",
    "reading": "shuǐmiàn",
    "meaning": "water surface",
    "grade": 7
  },
  {
    "id": 9267,
    "traditional": "水龍頭",
    "simplified": "水龙头",
    "reading": "shuǐlóngtóu",
    "meaning": "faucet, tap",
    "grade": 7
  },
  {
    "id": 9268,
    "traditional": "水手",
    "simplified": "水手",
    "reading": "shuǐshǒu",
    "meaning": "mariner, sailor, seaman",
    "grade": 7
  },
  {
    "id": 9269,
    "traditional": "水溫",
    "simplified": "水温",
    "reading": "shuǐwēn",
    "meaning": "water temperature",
    "grade": 7
  },
  {
    "id": 9270,
    "traditional": "水源",
    "simplified": "水源",
    "reading": "shuǐyuán",
    "meaning": "headwaters, waterhead, source of water",
    "grade": 7
  },
  {
    "id": 9271,
    "traditional": "水漲船高",
    "simplified": "水涨船高",
    "reading": "shuǐzhǎng-chuángāo",
    "meaning": "the tide rises, the boat floats (idiom), fig. to change with the overall trend, to develop according to the situation",
    "grade": 7
  },
  {
    "id": 9272,
    "traditional": "水域",
    "simplified": "水域",
    "reading": "shuǐyù",
    "meaning": "water, waters, water area, body of water",
    "grade": 7
  },
  {
    "id": 9273,
    "traditional": "水準",
    "simplified": "水准",
    "reading": "shuǐzhǔn",
    "meaning": "level, plane, standard",
    "grade": 7
  },
  {
    "id": 9274,
    "traditional": "稅收",
    "simplified": "税收",
    "reading": "shuìshōu",
    "meaning": "taxation",
    "grade": 7
  },
  {
    "id": 9275,
    "traditional": "稅務",
    "simplified": "税务",
    "reading": "shuìwù",
    "meaning": "taxation services, state revenue service",
    "grade": 7
  },
  {
    "id": 9276,
    "traditional": "睡袋",
    "simplified": "睡袋",
    "reading": "shuìdài",
    "meaning": "sleeping bag",
    "grade": 7
  },
  {
    "id": 9277,
    "traditional": "順便",
    "simplified": "顺便",
    "reading": "shùnbiàn",
    "meaning": "incidentally, conveniently, en passant, obiter, apropos, in passing, handily",
    "grade": 7
  },
  {
    "id": 9278,
    "traditional": "順暢",
    "simplified": "顺畅",
    "reading": "shùnchàng",
    "meaning": "smooth and unhindered, fluent",
    "grade": 7
  },
  {
    "id": 9279,
    "traditional": "順差",
    "simplified": "顺差",
    "reading": "shùnchā",
    "meaning": "(trade or budget) surplus",
    "grade": 7
  },
  {
    "id": 9280,
    "traditional": "順從",
    "simplified": "顺从",
    "reading": "shùncóng",
    "meaning": "obedient, to comply, to submit, to defer",
    "grade": 7
  },
  {
    "id": 9281,
    "traditional": "順理成章",
    "simplified": "顺理成章",
    "reading": "shùnlǐ-chéngzhāng",
    "meaning": "logical, only to be expected, rational and clearly structured (of text)",
    "grade": 7
  },
  {
    "id": 9282,
    "traditional": "順其自然",
    "simplified": "顺其自然",
    "reading": "shùnqízìrán",
    "meaning": "to let nature take its course (idiom)",
    "grade": 7
  },
  {
    "id": 9283,
    "traditional": "順路",
    "simplified": "顺路",
    "reading": "shùnlù",
    "meaning": "by the way, while out doing sth else, conveniently",
    "grade": 7
  },
  {
    "id": 9284,
    "traditional": "順勢",
    "simplified": "顺势",
    "reading": "shùnshì",
    "meaning": "to take advantage, to seize an opportunity, in passing, without taking extra trouble, conveniently",
    "grade": 7
  },
  {
    "id": 9285,
    "traditional": "順手",
    "simplified": "顺手",
    "reading": "shùnshǒu",
    "meaning": "easily, without trouble, while one is at it, in passing, handy",
    "grade": 7
  },
  {
    "id": 9286,
    "traditional": "順心",
    "simplified": "顺心",
    "reading": "shùn xīn",
    "meaning": "happy, satisfactory",
    "grade": 7
  },
  {
    "id": 9287,
    "traditional": "順著",
    "simplified": "顺着",
    "reading": "shùnzhe",
    "meaning": "to follow, following, along",
    "grade": 7
  },
  {
    "id": 9288,
    "traditional": "順應",
    "simplified": "顺应",
    "reading": "shùnyìng",
    "meaning": "to comply, to conform to, in tune with, adapting to, to adjust to",
    "grade": 7
  },
  {
    "id": 9289,
    "traditional": "說白了",
    "simplified": "说白了",
    "reading": "shuōbáile",
    "meaning": "to speak frankly",
    "grade": 7
  },
  {
    "id": 9290,
    "traditional": "說不上",
    "simplified": "说不上",
    "reading": "shuōbushàng",
    "meaning": "to be unable to say or tell, to not be worth mentioning",
    "grade": 7
  },
  {
    "id": 9291,
    "traditional": "說到底",
    "simplified": "说到底",
    "reading": "shuōdàodǐ",
    "meaning": "Conclude",
    "grade": 7
  },
  {
    "id": 9292,
    "traditional": "說道",
    "simplified": "说道",
    "reading": "shuōdao",
    "meaning": "talk over, say",
    "grade": 7
  },
  {
    "id": 9293,
    "traditional": "瞬間",
    "simplified": "瞬间",
    "reading": "shùnjiān",
    "meaning": "moment, momentary, in a flash",
    "grade": 7
  },
  {
    "id": 9294,
    "traditional": "說幹就幹",
    "simplified": "说干就干",
    "reading": "shuōgànjiùgàn",
    "meaning": "Talk to dry",
    "grade": 7
  },
  {
    "id": 9295,
    "traditional": "說謊",
    "simplified": "说谎",
    "reading": "shuō huǎng",
    "meaning": "prevaricate, fable, lie, tell a lie, falsify",
    "grade": 7
  },
  {
    "id": 9296,
    "traditional": "說老實話",
    "simplified": "说老实话",
    "reading": "shuōlǎoshihuà",
    "meaning": "Truth",
    "grade": 7
  },
  {
    "id": 9297,
    "traditional": "說起來",
    "simplified": "说起来",
    "reading": "shuō qǐlái",
    "meaning": "Say",
    "grade": 7
  },
  {
    "id": 9298,
    "traditional": "說閒話",
    "simplified": "说闲话",
    "reading": "shuō xiánhuà",
    "meaning": "gossip",
    "grade": 7
  },
  {
    "id": 9299,
    "traditional": "說情",
    "simplified": "说情",
    "reading": "shuō qíng",
    "meaning": "to intercede, to plead for sb else",
    "grade": 7
  },
  {
    "id": 9300,
    "traditional": "說真的",
    "simplified": "说真的",
    "reading": "shuōzhēnde",
    "meaning": "honestly",
    "grade": 7
  },
  {
    "id": 9301,
    "traditional": "碩果",
    "simplified": "硕果",
    "reading": "shuòguǒ",
    "meaning": "major achievement, great work, triumphant success",
    "grade": 7
  },
  {
    "id": 9302,
    "traditional": "司空見慣",
    "simplified": "司空见惯",
    "reading": "sīkōng-jiànguàn",
    "meaning": "a common occurrence (idiom)",
    "grade": 7
  },
  {
    "id": 9303,
    "traditional": "司令",
    "simplified": "司令",
    "reading": "sīlìng",
    "meaning": "commanding officer",
    "grade": 7
  },
  {
    "id": 9304,
    "traditional": "司法",
    "simplified": "司法",
    "reading": "sīfǎ",
    "meaning": "justice, judicature, administration of justice, judicatory, judiciary, judgeship, judicial, judgship",
    "grade": 7
  },
  {
    "id": 9305,
    "traditional": "絲綢",
    "simplified": "丝绸",
    "reading": "sīchóu",
    "meaning": "silk cloth, silk",
    "grade": 7
  },
  {
    "id": 9306,
    "traditional": "絲",
    "simplified": "丝",
    "reading": "sī",
    "meaning": "m.[proximation]",
    "grade": 7
  },
  {
    "id": 9307,
    "traditional": "絲毫",
    "simplified": "丝毫",
    "reading": "sīháo",
    "meaning": "scintilla, shred, shadow, vestige, det.: in the slightest amount, degree",
    "grade": 7
  },
  {
    "id": 9308,
    "traditional": "私房錢",
    "simplified": "私房钱",
    "reading": "sīfangqián",
    "meaning": "secret purse, secret stash of money",
    "grade": 7
  },
  {
    "id": 9309,
    "traditional": "私家車",
    "simplified": "私家车",
    "reading": "sījiāchē",
    "meaning": "private car",
    "grade": 7
  },
  {
    "id": 9310,
    "traditional": "私立",
    "simplified": "私立",
    "reading": "sīlì",
    "meaning": "privately run, private",
    "grade": 7
  },
  {
    "id": 9311,
    "traditional": "私下",
    "simplified": "私下",
    "reading": "sīxià",
    "meaning": "tete-a-tete, in private/secret, in private, privately, in secret, secret",
    "grade": 7
  },
  {
    "id": 9312,
    "traditional": "私事",
    "simplified": "私事",
    "reading": "sīshì",
    "meaning": "personal matters",
    "grade": 7
  },
  {
    "id": 9313,
    "traditional": "私營",
    "simplified": "私营",
    "reading": "sīyíng",
    "meaning": "privately-owned, private",
    "grade": 7
  },
  {
    "id": 9314,
    "traditional": "私有",
    "simplified": "私有",
    "reading": "sīyǒu",
    "meaning": "private, privately-owned",
    "grade": 7
  },
  {
    "id": 9315,
    "traditional": "私自",
    "simplified": "私自",
    "reading": "sīzì",
    "meaning": "private, personal, secretly, without explicit approval",
    "grade": 7
  },
  {
    "id": 9316,
    "traditional": "思念",
    "simplified": "思念",
    "reading": "sīniàn",
    "meaning": "to think of, to long for, to miss",
    "grade": 7
  },
  {
    "id": 9317,
    "traditional": "思前想後",
    "simplified": "思前想后",
    "reading": "sīqián-xiǎnghòu",
    "meaning": "to consider past cause and future effect (idiom), to think over the past and future, to ponder over reasons and connection",
    "grade": 7
  },
  {
    "id": 9318,
    "traditional": "思路",
    "simplified": "思路",
    "reading": "sīlù",
    "meaning": "train of thought, thinking, reason, reasoning",
    "grade": 7
  },
  {
    "id": 9319,
    "traditional": "思索",
    "simplified": "思索",
    "reading": "sīsuǒ",
    "meaning": "study, puzzle, deliberate, cogitate, devise, think deeply, speculate, consider, contemplate, meditate, ponder",
    "grade": 7
  },
  {
    "id": 9320,
    "traditional": "撕",
    "simplified": "撕",
    "reading": "sī",
    "meaning": "to tear",
    "grade": 7
  },
  {
    "id": 9321,
    "traditional": "死心",
    "simplified": "死心",
    "reading": "sǐ xīn",
    "meaning": "to give up, to admit failure, to drop the matter, to reconcile oneself to loss, to have no more illusions about",
    "grade": 7
  },
  {
    "id": 9322,
    "traditional": "死心塌地",
    "simplified": "死心塌地",
    "reading": "sǐxīn-tādì",
    "meaning": "to be hell-bent on, dead set on sth, unswerving",
    "grade": 7
  },
  {
    "id": 9323,
    "traditional": "四合院",
    "simplified": "四合院",
    "reading": "sìhéyuàn",
    "meaning": "courtyard house (type of Chinese residence)",
    "grade": 7
  },
  {
    "id": 9324,
    "traditional": "四面八方",
    "simplified": "四面八方",
    "reading": "sìmiàn-bāfāng",
    "meaning": "in all directions, all around, far and near",
    "grade": 7
  },
  {
    "id": 9325,
    "traditional": "寺廟",
    "simplified": "寺庙",
    "reading": "sìmiào",
    "meaning": "temple, monastery, shrine",
    "grade": 7
  },
  {
    "id": 9326,
    "traditional": "似曾相識",
    "simplified": "似曾相识",
    "reading": "sìcéngxiāngshí",
    "meaning": "déjà vu (the experience of seeing exactly the same situation a second time), seemingly familiar, apparently already acquainted",
    "grade": 7
  },
  {
    "id": 9327,
    "traditional": "四季",
    "simplified": "四季",
    "reading": "sìjì",
    "meaning": "four seasons, namely: spring 春, summer 夏, autumn 秋 and winter 冬",
    "grade": 7
  },
  {
    "id": 9328,
    "traditional": "似是而非",
    "simplified": "似是而非",
    "reading": "sìshìérfēi",
    "meaning": "apparently right but actually wrong, specious (idiom)",
    "grade": 7
  },
  {
    "id": 9329,
    "traditional": "伺機",
    "simplified": "伺机",
    "reading": "sìjī",
    "meaning": "to wait for an opportunity, to watch for one's chance",
    "grade": 7
  },
  {
    "id": 9330,
    "traditional": "飼料",
    "simplified": "饲料",
    "reading": "sìliào",
    "meaning": "feed, fodder",
    "grade": 7
  },
  {
    "id": 9331,
    "traditional": "飼養",
    "simplified": "饲养",
    "reading": "sìyǎng",
    "meaning": "raise (animals/etc.)",
    "grade": 7
  },
  {
    "id": 9332,
    "traditional": "鬆綁",
    "simplified": "松绑",
    "reading": "sōng bǎng",
    "meaning": "to untie, (fig.) to ease restrictions",
    "grade": 7
  },
  {
    "id": 9333,
    "traditional": "聳立",
    "simplified": "耸立",
    "reading": "sǒnglì",
    "meaning": "to stand tall, to tower aloft",
    "grade": 7
  },
  {
    "id": 9334,
    "traditional": "鬆弛",
    "simplified": "松弛",
    "reading": "sōngchí",
    "meaning": "to relax, relaxed, limp, lax",
    "grade": 7
  },
  {
    "id": 9335,
    "traditional": "送別",
    "simplified": "送别",
    "reading": "sòng bié",
    "meaning": "farewell",
    "grade": 7
  },
  {
    "id": 9336,
    "traditional": "搜集",
    "simplified": "搜集",
    "reading": "sōují",
    "meaning": "to gather, to collect",
    "grade": 7
  },
  {
    "id": 9337,
    "traditional": "搜查",
    "simplified": "搜查",
    "reading": "sōuchá",
    "meaning": "to search",
    "grade": 7
  },
  {
    "id": 9338,
    "traditional": "搜救",
    "simplified": "搜救",
    "reading": "sōujiù",
    "meaning": "search and rescue",
    "grade": 7
  },
  {
    "id": 9339,
    "traditional": "艘",
    "simplified": "艘",
    "reading": "sōu",
    "meaning": "m.[general]",
    "grade": 7
  },
  {
    "id": 9340,
    "traditional": "搜尋",
    "simplified": "搜寻",
    "reading": "sōuxún",
    "meaning": "search/look for, seek",
    "grade": 7
  },
  {
    "id": 9341,
    "traditional": "酥",
    "simplified": "酥",
    "reading": "sū",
    "meaning": "flaky pastry, crunchy, limp, soft, silky",
    "grade": 7
  },
  {
    "id": 9342,
    "traditional": "蘇醒",
    "simplified": "苏醒",
    "reading": "sūxǐng",
    "meaning": "to come to, to awaken, to regain consciousness",
    "grade": 7
  },
  {
    "id": 9343,
    "traditional": "俗話",
    "simplified": "俗话",
    "reading": "súhuà",
    "meaning": "common saying, proverb",
    "grade": 7
  },
  {
    "id": 9344,
    "traditional": "俗話說",
    "simplified": "俗话说",
    "reading": "súhuàshuō",
    "meaning": "as the proverb says, as they say...",
    "grade": 7
  },
  {
    "id": 9345,
    "traditional": "俗",
    "simplified": "俗",
    "reading": "sú",
    "meaning": "custom, convention, popular, common, coarse, vulgar, secular",
    "grade": 7
  },
  {
    "id": 9346,
    "traditional": "訴苦",
    "simplified": "诉苦",
    "reading": "sù kǔ",
    "meaning": "to grumble, to complain, grievance",
    "grade": 7
  },
  {
    "id": 9347,
    "traditional": "訴說",
    "simplified": "诉说",
    "reading": "sùshuō",
    "meaning": "tell, relate, recount",
    "grade": 7
  },
  {
    "id": 9348,
    "traditional": "俗語",
    "simplified": "俗语",
    "reading": "súyǔ",
    "meaning": "common saying, proverb, colloquial speech",
    "grade": 7
  },
  {
    "id": 9349,
    "traditional": "訴訟",
    "simplified": "诉讼",
    "reading": "sùsòng",
    "meaning": "lawsuit",
    "grade": 7
  },
  {
    "id": 9350,
    "traditional": "素",
    "simplified": "素",
    "reading": "sù",
    "meaning": "raw silk, white, plain, unadorned, vegetarian (food), essence, nature, element, constituent, usually, always, ever",
    "grade": 7
  },
  {
    "id": 9351,
    "traditional": "素不相識",
    "simplified": "素不相识",
    "reading": "sùbùxiāngshí",
    "meaning": "to be total strangers (idiom)",
    "grade": 7
  },
  {
    "id": 9352,
    "traditional": "素材",
    "simplified": "素材",
    "reading": "sùcái",
    "meaning": "material, source material, medium",
    "grade": 7
  },
  {
    "id": 9353,
    "traditional": "素描",
    "simplified": "素描",
    "reading": "sùmiáo",
    "meaning": "sketch",
    "grade": 7
  },
  {
    "id": 9354,
    "traditional": "素養",
    "simplified": "素养",
    "reading": "sùyǎng",
    "meaning": "(personal) accomplishment, attainment in self-cultivation",
    "grade": 7
  },
  {
    "id": 9355,
    "traditional": "塑造",
    "simplified": "塑造",
    "reading": "sùzào",
    "meaning": "model, mold, portray",
    "grade": 7
  },
  {
    "id": 9356,
    "traditional": "蒜",
    "simplified": "蒜",
    "reading": "suàn",
    "meaning": "garlic, CL:頭|头[tou2],瓣[ban4]",
    "grade": 7
  },
  {
    "id": 9357,
    "traditional": "素食",
    "simplified": "素食",
    "reading": "sùshí",
    "meaning": "vegetables, vegetarian food",
    "grade": 7
  },
  {
    "id": 9358,
    "traditional": "算計",
    "simplified": "算计",
    "reading": "suànji",
    "meaning": "to reckon, to calculate, to plan, to expect, to scheme",
    "grade": 7
  },
  {
    "id": 9359,
    "traditional": "算盤",
    "simplified": "算盘",
    "reading": "suànpan",
    "meaning": "abacus, CL:把[ba3], plan, scheme",
    "grade": 7
  },
  {
    "id": 9360,
    "traditional": "算賬",
    "simplified": "算账",
    "reading": "suàn zhàng",
    "meaning": "Calculate account",
    "grade": 7
  },
  {
    "id": 9361,
    "traditional": "隨處可見",
    "simplified": "随处可见",
    "reading": "suíchùkějiàn",
    "meaning": "Everywhere",
    "grade": 7
  },
  {
    "id": 9362,
    "traditional": "隨大溜",
    "simplified": "随大溜",
    "reading": "suídàliù",
    "meaning": "Slider",
    "grade": 7
  },
  {
    "id": 9363,
    "traditional": "隨機",
    "simplified": "随机",
    "reading": "suíjī",
    "meaning": "according to the situation, pragmatic, random",
    "grade": 7
  },
  {
    "id": 9364,
    "traditional": "雖說",
    "simplified": "虽说",
    "reading": "suīshuō",
    "meaning": "conj.: though, although",
    "grade": 7
  },
  {
    "id": 9365,
    "traditional": "隨即",
    "simplified": "随即",
    "reading": "suíjí",
    "meaning": "therewith, thereupon, presently, hereupon, soon after that, immediately",
    "grade": 7
  },
  {
    "id": 9366,
    "traditional": "隨身",
    "simplified": "随身",
    "reading": "suíshēn",
    "meaning": "to (carry) on one's person, to (take) with one",
    "grade": 7
  },
  {
    "id": 9367,
    "traditional": "隨時隨地",
    "simplified": "随时随地",
    "reading": "suíshí-suídì",
    "meaning": "anytime and anywhere",
    "grade": 7
  },
  {
    "id": 9368,
    "traditional": "隨心所欲",
    "simplified": "随心所欲",
    "reading": "suíxīnsuǒyù",
    "meaning": "to follow one's heart's desires, to do as one pleases (idiom)",
    "grade": 7
  },
  {
    "id": 9369,
    "traditional": "遂心",
    "simplified": "遂心",
    "reading": "suì xīn",
    "meaning": "to one's liking",
    "grade": 7
  },
  {
    "id": 9370,
    "traditional": "隧道",
    "simplified": "隧道",
    "reading": "suìdào",
    "meaning": "tube, tunnel",
    "grade": 7
  },
  {
    "id": 9371,
    "traditional": "損壞",
    "simplified": "损坏",
    "reading": "sǔnhuài",
    "meaning": "to damage, to injure",
    "grade": 7
  },
  {
    "id": 9372,
    "traditional": "損人利己",
    "simplified": "损人利己",
    "reading": "sǔnrén-lìjǐ",
    "meaning": "harming others for one's personal benefit (idiom), personal gain to the detriment of others",
    "grade": 7
  },
  {
    "id": 9373,
    "traditional": "損傷",
    "simplified": "损伤",
    "reading": "sǔnshāng",
    "meaning": "to harm, to damage, to injure, impairment, loss, disability",
    "grade": 7
  },
  {
    "id": 9374,
    "traditional": "損",
    "simplified": "损",
    "reading": "sǔn",
    "meaning": "to decrease, to lose, to damage, to harm, (coll.) to speak sarcastically, to deride, caustic, mean, one of the 64 trigrams of the Book of Changes (old)",
    "grade": 7
  },
  {
    "id": 9375,
    "traditional": "縮",
    "simplified": "缩",
    "reading": "suō",
    "meaning": "to withdraw, to pull back, to contract, to shrink, to reduce, abbreviation, also pr. [su4]",
    "grade": 7
  },
  {
    "id": 9376,
    "traditional": "縮水",
    "simplified": "缩水",
    "reading": "suō shuǐ",
    "meaning": "to shrink (in the wash), fig. to shrink (of profits etc)",
    "grade": 7
  },
  {
    "id": 9377,
    "traditional": "縮影",
    "simplified": "缩影",
    "reading": "suōyǐng",
    "meaning": "miniature version of sth, microcosm, epitome, (Tw) to microfilm",
    "grade": 7
  },
  {
    "id": 9378,
    "traditional": "所屬",
    "simplified": "所属",
    "reading": "suǒshǔ",
    "meaning": "subsidiary",
    "grade": 7
  },
  {
    "id": 9379,
    "traditional": "所謂",
    "simplified": "所谓",
    "reading": "suǒwèi",
    "meaning": "so-called",
    "grade": 7
  },
  {
    "id": 9380,
    "traditional": "所作所為",
    "simplified": "所作所为",
    "reading": "suǒzuò-suǒwéi",
    "meaning": "one's conduct and deeds",
    "grade": 7
  },
  {
    "id": 9381,
    "traditional": "索賠",
    "simplified": "索赔",
    "reading": "suǒpéi",
    "meaning": "to ask for compensation, to claim damages, claim for damages",
    "grade": 7
  },
  {
    "id": 9382,
    "traditional": "索性",
    "simplified": "索性",
    "reading": "suǒxìng",
    "meaning": "you might as well (do it), simply, just",
    "grade": 7
  },
  {
    "id": 9383,
    "traditional": "鎖定",
    "simplified": "锁定",
    "reading": "suǒdìng",
    "meaning": "to lock (a door), to close with a latch, to lock into place, a lock, a latch, to lock a computer file (to prevent it being overwritten), to lock (denying access to a computer system or device or files, e.g. by password-protection), to focus attention on, to target",
    "grade": 7
  },
  {
    "id": 9384,
    "traditional": "他人",
    "simplified": "他人",
    "reading": "tārén",
    "meaning": "others, another person",
    "grade": 7
  },
  {
    "id": 9385,
    "traditional": "索取",
    "simplified": "索取",
    "reading": "suǒqǔ",
    "meaning": "scrounge, extort, subpoena, obtain, charge, demand, exact, ask for",
    "grade": 7
  },
  {
    "id": 9386,
    "traditional": "胎",
    "simplified": "胎",
    "reading": "tāi",
    "meaning": "fetus, litter, tire, abbr. of 輪胎|轮胎",
    "grade": 7
  },
  {
    "id": 9387,
    "traditional": "踏上",
    "simplified": "踏上",
    "reading": "tàshàng",
    "meaning": "to set foot on, to step on or into",
    "grade": 7
  },
  {
    "id": 9388,
    "traditional": "塌",
    "simplified": "塌",
    "reading": "tā",
    "meaning": "to collapse, to droop, to settle down",
    "grade": 7
  },
  {
    "id": 9389,
    "traditional": "檯球",
    "simplified": "台球",
    "reading": "táiqiú",
    "meaning": "billiards",
    "grade": 7
  },
  {
    "id": 9390,
    "traditional": "太極",
    "simplified": "太极",
    "reading": "tàijí",
    "meaning": "the Absolute or Supreme Ultimate, the source of all things according to some interpretations of Chinese mythology",
    "grade": 7
  },
  {
    "id": 9391,
    "traditional": "太極拳",
    "simplified": "太极拳",
    "reading": "tàijíquán",
    "meaning": "shadowboxing or Taiji, T'aichi or T'aichichuan, traditional form of physical exercise or relaxation, a martial art",
    "grade": 7
  },
  {
    "id": 9392,
    "traditional": "胎兒",
    "simplified": "胎儿",
    "reading": "tāiér",
    "meaning": "unborn child, fetus, embryo",
    "grade": 7
  },
  {
    "id": 9393,
    "traditional": "太平",
    "simplified": "太平",
    "reading": "tàipíng",
    "meaning": "place name, peace and security",
    "grade": 7
  },
  {
    "id": 9394,
    "traditional": "泰斗",
    "simplified": "泰斗",
    "reading": "tàidǒu",
    "meaning": "leading scholar of his time, magnate",
    "grade": 7
  },
  {
    "id": 9395,
    "traditional": "貪",
    "simplified": "贪",
    "reading": "tān",
    "meaning": "be corrupt, practice graft, covet, hanker after",
    "grade": 7
  },
  {
    "id": 9396,
    "traditional": "貪婪",
    "simplified": "贪婪",
    "reading": "tānlán",
    "meaning": "avaricious, greedy, rapacious, insatiable, avid",
    "grade": 7
  },
  {
    "id": 9397,
    "traditional": "貪玩兒",
    "simplified": "贪玩儿",
    "reading": "tān wánr5",
    "meaning": "Greedy",
    "grade": 7
  },
  {
    "id": 9398,
    "traditional": "攤",
    "simplified": "摊",
    "reading": "tān",
    "meaning": "to spread out, vendor's stand",
    "grade": 7
  },
  {
    "id": 9399,
    "traditional": "貪污",
    "simplified": "贪污",
    "reading": "tānwū",
    "meaning": "corrupt, venal",
    "grade": 7
  },
  {
    "id": 9400,
    "traditional": "癱",
    "simplified": "瘫",
    "reading": "tān",
    "meaning": "paralyzed",
    "grade": 7
  },
  {
    "id": 9401,
    "traditional": "癱瘓",
    "simplified": "瘫痪",
    "reading": "tānhuàn",
    "meaning": "paralysis, be paralyzed (body, transportation, etc)",
    "grade": 7
  },
  {
    "id": 9402,
    "traditional": "談不上",
    "simplified": "谈不上",
    "reading": "tánbushàng",
    "meaning": "to be out of the question",
    "grade": 7
  },
  {
    "id": 9403,
    "traditional": "罈",
    "simplified": "坛",
    "reading": "tán",
    "meaning": "earthen jar",
    "grade": 7
  },
  {
    "id": 9404,
    "traditional": "談到",
    "simplified": "谈到",
    "reading": "tándào",
    "meaning": "cite, speak_to, name, hit, about, speak, refer, speak about, mention, advert, allude, speak_of, speak of, talk about, bring up, touch_on, touch on, touch",
    "grade": 7
  },
  {
    "id": 9405,
    "traditional": "談論",
    "simplified": "谈论",
    "reading": "tánlùn",
    "meaning": "discuss",
    "grade": 7
  },
  {
    "id": 9406,
    "traditional": "彈性",
    "simplified": "弹性",
    "reading": "tánxìng",
    "meaning": "elasticity",
    "grade": 7
  },
  {
    "id": 9407,
    "traditional": "談起",
    "simplified": "谈起",
    "reading": "tánqǐ",
    "meaning": "speak of, talk about, mention",
    "grade": 7
  },
  {
    "id": 9408,
    "traditional": "痰",
    "simplified": "痰",
    "reading": "tán",
    "meaning": "phlegm, spittle",
    "grade": 7
  },
  {
    "id": 9409,
    "traditional": "坦誠",
    "simplified": "坦诚",
    "reading": "tǎnchéng",
    "meaning": "candid, frank, plain dealing",
    "grade": 7
  },
  {
    "id": 9410,
    "traditional": "坦白",
    "simplified": "坦白",
    "reading": "tǎnbái",
    "meaning": "frank, candid",
    "grade": 7
  },
  {
    "id": 9411,
    "traditional": "坦克",
    "simplified": "坦克",
    "reading": "tǎnkè",
    "meaning": "tank (military vehicle) (loanword)",
    "grade": 7
  },
  {
    "id": 9412,
    "traditional": "坦然",
    "simplified": "坦然",
    "reading": "tǎnrán",
    "meaning": "calm, undisturbed",
    "grade": 7
  },
  {
    "id": 9413,
    "traditional": "坦率",
    "simplified": "坦率",
    "reading": "tǎnshuài",
    "meaning": "frank (discussion), blunt, open",
    "grade": 7
  },
  {
    "id": 9414,
    "traditional": "探",
    "simplified": "探",
    "reading": "tàn",
    "meaning": "explore, stretch forward, visit, look for, call on, sound, stretch, stretch/pop forward, try to find out, survey, exploration, sounding, pop forward, pay a call on",
    "grade": 7
  },
  {
    "id": 9415,
    "traditional": "毯子",
    "simplified": "毯子",
    "reading": "tǎnzi",
    "meaning": "blanket, CL:條|条[tiao2],張|张[zhang1],床[chuang2],面[mian4]",
    "grade": 7
  },
  {
    "id": 9416,
    "traditional": "炭",
    "simplified": "炭",
    "reading": "tàn",
    "meaning": "wood charcoal, coal",
    "grade": 7
  },
  {
    "id": 9417,
    "traditional": "探測",
    "simplified": "探测",
    "reading": "tàncè",
    "meaning": "to probe, to take readings, to explore, exploration",
    "grade": 7
  },
  {
    "id": 9418,
    "traditional": "探親",
    "simplified": "探亲",
    "reading": "tàn qīn",
    "meaning": "go home to visit one's family, visit family, go to visit one's relatives",
    "grade": 7
  },
  {
    "id": 9419,
    "traditional": "探求",
    "simplified": "探求",
    "reading": "tànqiú",
    "meaning": "to seek, to pursue, to investigate",
    "grade": 7
  },
  {
    "id": 9420,
    "traditional": "探望",
    "simplified": "探望",
    "reading": "tànwàng",
    "meaning": "look about, visit",
    "grade": 7
  },
  {
    "id": 9421,
    "traditional": "探險",
    "simplified": "探险",
    "reading": "tàn xiǎn",
    "meaning": "explore, adventure",
    "grade": 7
  },
  {
    "id": 9422,
    "traditional": "湯圓",
    "simplified": "汤圆",
    "reading": "tāngyuán",
    "meaning": "boiled balls of glutinous rice flour, eaten during the Lantern Festival",
    "grade": 7
  },
  {
    "id": 9423,
    "traditional": "堂",
    "simplified": "堂",
    "reading": "táng",
    "meaning": "m.[event]",
    "grade": 7
  },
  {
    "id": 9424,
    "traditional": "碳",
    "simplified": "碳",
    "reading": "tàn",
    "meaning": "carbon (chemistry)",
    "grade": 7
  },
  {
    "id": 9425,
    "traditional": "糖尿病",
    "simplified": "糖尿病",
    "reading": "tángniàobìng",
    "meaning": "diabetic, diabetes, glycosuria",
    "grade": 7
  },
  {
    "id": 9426,
    "traditional": "糖果",
    "simplified": "糖果",
    "reading": "tángguǒ",
    "meaning": "candy, CL:粒[li4]",
    "grade": 7
  },
  {
    "id": 9427,
    "traditional": "倘若",
    "simplified": "倘若",
    "reading": "tǎngruò",
    "meaning": "conj.: if, supposing, in case",
    "grade": 7
  },
  {
    "id": 9428,
    "traditional": "淌",
    "simplified": "淌",
    "reading": "tǎng",
    "meaning": "to drip, to trickle, to shed (tears)",
    "grade": 7
  },
  {
    "id": 9429,
    "traditional": "掏錢",
    "simplified": "掏钱",
    "reading": "tāoqián",
    "meaning": "Save money",
    "grade": 7
  },
  {
    "id": 9430,
    "traditional": "燙",
    "simplified": "烫",
    "reading": "tàng",
    "meaning": "to scald, to burn (by scalding), to blanch (cooking), to heat (sth) up in hot water, to perm, to iron, scalding hot",
    "grade": 7
  },
  {
    "id": 9431,
    "traditional": "滔滔不絕",
    "simplified": "滔滔不绝",
    "reading": "tāotāo-bùjué",
    "meaning": "unceasing torrent (idiom), talking non-stop, gabbling forty to the dozen",
    "grade": 7
  },
  {
    "id": 9432,
    "traditional": "逃生",
    "simplified": "逃生",
    "reading": "táoshēng",
    "meaning": "to flee for one's life",
    "grade": 7
  },
  {
    "id": 9433,
    "traditional": "逃避",
    "simplified": "逃避",
    "reading": "táobì",
    "meaning": "get by, sidestep, get_around, weasel, fly, avoid, evade, ditch, shirk, evasive, fudge, escape, retreat, elude, put off, get off, skive, hedge, welsh, circumvent, wangle, duck, skirt, get away, get out, bilk, hide, ware, evasion, flee, parry, dodge",
    "grade": 7
  },
  {
    "id": 9434,
    "traditional": "逃亡",
    "simplified": "逃亡",
    "reading": "táowáng",
    "meaning": "to flee, flight (from danger), fugitive",
    "grade": 7
  },
  {
    "id": 9435,
    "traditional": "陶冶",
    "simplified": "陶冶",
    "reading": "táoyě",
    "meaning": "mold/shape character/taste/etc.",
    "grade": 7
  },
  {
    "id": 9436,
    "traditional": "陶瓷",
    "simplified": "陶瓷",
    "reading": "táocí",
    "meaning": "pottery and porcelain, ceramic, ceramics, china",
    "grade": 7
  },
  {
    "id": 9437,
    "traditional": "陶醉",
    "simplified": "陶醉",
    "reading": "táozuì",
    "meaning": "to be infatuated with, to be drunk with, to be enchanted with, to revel in",
    "grade": 7
  },
  {
    "id": 9438,
    "traditional": "淘",
    "simplified": "淘",
    "reading": "táo",
    "meaning": "to wash, to clean out, to cleanse, to eliminate, to dredge",
    "grade": 7
  },
  {
    "id": 9439,
    "traditional": "淘氣",
    "simplified": "淘气",
    "reading": "táo qì",
    "meaning": "naughty, mischievous",
    "grade": 7
  },
  {
    "id": 9440,
    "traditional": "淘汰",
    "simplified": "淘汰",
    "reading": "táotài",
    "meaning": "weed out, competition, eliminate through selection, supersede, superannuate, weed, eliminate through selection or competition, screen_out, eliminate through competition, eliminate through selection/competition, fall into disuse, die out, eliminate, comb out, weed_out, selective",
    "grade": 7
  },
  {
    "id": 9441,
    "traditional": "討好",
    "simplified": "讨好",
    "reading": "tǎo hǎo",
    "meaning": "conciliate, have one's labor rewarded, blandish, accommodate, toady, oblige, toady to, curry favour with, grease, massage, propitiate, woo, fawn on, sugar, soft-soap, ingratiate, ingratiate oneself with",
    "grade": 7
  },
  {
    "id": 9442,
    "traditional": "討價還價",
    "simplified": "讨价还价",
    "reading": "tǎojià-huánjià",
    "meaning": "to haggle over price",
    "grade": 7
  },
  {
    "id": 9443,
    "traditional": "特產",
    "simplified": "特产",
    "reading": "tèchǎn",
    "meaning": "special local product, (regional) specialty",
    "grade": 7
  },
  {
    "id": 9444,
    "traditional": "討人喜歡",
    "simplified": "讨人喜欢",
    "reading": "tǎorénxǐhuan",
    "meaning": "to attract people's affection, charming, delightful",
    "grade": 7
  },
  {
    "id": 9445,
    "traditional": "討",
    "simplified": "讨",
    "reading": "tǎo",
    "meaning": "demand, dun, marry (a woman)",
    "grade": 7
  },
  {
    "id": 9446,
    "traditional": "特長",
    "simplified": "特长",
    "reading": "tècháng",
    "meaning": "personal strength, one's special ability or strong points",
    "grade": 7
  },
  {
    "id": 9447,
    "traditional": "特例",
    "simplified": "特例",
    "reading": "tèlì",
    "meaning": "special case, isolated example",
    "grade": 7
  },
  {
    "id": 9448,
    "traditional": "特權",
    "simplified": "特权",
    "reading": "tèquán",
    "meaning": "regalia, privilege, charter, freedom, vested interest, exclusive right, franchise, perquisite, liberty, peculiar, exclusive, prerogative, vested_interest, faculty, indulgence",
    "grade": 7
  },
  {
    "id": 9449,
    "traditional": "特質",
    "simplified": "特质",
    "reading": "tèzhì",
    "meaning": "attribute, distinctive feature, make-up, particularity, ethos, a speciality, point, lineament, colour, identification, property, distinction, thing, speciality, difference, specialty, character, idiosyncrasy, feeling of identity, distinctiveness, specialness, makeup, spot, dimension, peculiarity, quality",
    "grade": 7
  },
  {
    "id": 9450,
    "traditional": "特製",
    "simplified": "特制",
    "reading": "tèzhì",
    "meaning": "special, unique",
    "grade": 7
  },
  {
    "id": 9451,
    "traditional": "藤椅",
    "simplified": "藤椅",
    "reading": "téngyǐ",
    "meaning": "rattan chair",
    "grade": 7
  },
  {
    "id": 9452,
    "traditional": "騰",
    "simplified": "腾",
    "reading": "téng",
    "meaning": "to soar, to gallop, to prance, to turn over, to vacate, to clear",
    "grade": 7
  },
  {
    "id": 9453,
    "traditional": "特邀",
    "simplified": "特邀",
    "reading": "tèyāo",
    "meaning": "special invitation",
    "grade": 7
  },
  {
    "id": 9454,
    "traditional": "梯子",
    "simplified": "梯子",
    "reading": "tīzi",
    "meaning": "ladder, stepladder",
    "grade": 7
  },
  {
    "id": 9455,
    "traditional": "剔除",
    "simplified": "剔除",
    "reading": "tīchú",
    "meaning": "to reject, to discard, to get rid of",
    "grade": 7
  },
  {
    "id": 9456,
    "traditional": "提拔",
    "simplified": "提拔",
    "reading": "tíba",
    "meaning": "to promote to a higher job, to select for promotion",
    "grade": 7
  },
  {
    "id": 9457,
    "traditional": "提名",
    "simplified": "提名",
    "reading": "tí míng",
    "meaning": "nominate",
    "grade": 7
  },
  {
    "id": 9458,
    "traditional": "提煉",
    "simplified": "提炼",
    "reading": "tíliàn",
    "meaning": "to extract (ore, minerals etc), to refine, to purify, to process",
    "grade": 7
  },
  {
    "id": 9459,
    "traditional": "提速",
    "simplified": "提速",
    "reading": "tí sù",
    "meaning": "to increase the specified cruising speed, to pick up speed, to speed up",
    "grade": 7
  },
  {
    "id": 9460,
    "traditional": "提心吊膽",
    "simplified": "提心吊胆",
    "reading": "tíxīn-diàodǎn",
    "meaning": "(saying) to be very scared and on edge",
    "grade": 7
  },
  {
    "id": 9461,
    "traditional": "提議",
    "simplified": "提议",
    "reading": "tíyì",
    "meaning": "propose, suggest",
    "grade": 7
  },
  {
    "id": 9462,
    "traditional": "提早",
    "simplified": "提早",
    "reading": "tízǎo",
    "meaning": "earlier than planned or expected, in advance, shift to an earlier time",
    "grade": 7
  },
  {
    "id": 9463,
    "traditional": "體面",
    "simplified": "体面",
    "reading": "tǐmian",
    "meaning": "dignity, face (as in 'losing face'), honorable, creditable, pretty",
    "grade": 7
  },
  {
    "id": 9464,
    "traditional": "體諒",
    "simplified": "体谅",
    "reading": "tǐliàng",
    "meaning": "make allowances for",
    "grade": 7
  },
  {
    "id": 9465,
    "traditional": "體貼",
    "simplified": "体贴",
    "reading": "tǐtiē",
    "meaning": "be considerate, be thoughtful",
    "grade": 7
  },
  {
    "id": 9466,
    "traditional": "體能",
    "simplified": "体能",
    "reading": "tǐnéng",
    "meaning": "physical capability, stamina",
    "grade": 7
  },
  {
    "id": 9467,
    "traditional": "體系",
    "simplified": "体系",
    "reading": "tǐxì",
    "meaning": "scheme, setup, system of rules, system, superstructure, cosmos, schema",
    "grade": 7
  },
  {
    "id": 9468,
    "traditional": "體溫",
    "simplified": "体温",
    "reading": "tǐwēn",
    "meaning": "(body) temperature",
    "grade": 7
  },
  {
    "id": 9469,
    "traditional": "體制",
    "simplified": "体制",
    "reading": "tǐzhì",
    "meaning": "frame, organization, system of organization, framework, system, apparatus, framing, organisation",
    "grade": 7
  },
  {
    "id": 9470,
    "traditional": "剃",
    "simplified": "剃",
    "reading": "tì",
    "meaning": "to shave, variant of 剃[ti4]",
    "grade": 7
  },
  {
    "id": 9471,
    "traditional": "替換",
    "simplified": "替换",
    "reading": "tìhuàn",
    "meaning": "to exchange, to replace, to substitute for, to switch",
    "grade": 7
  },
  {
    "id": 9472,
    "traditional": "體質",
    "simplified": "体质",
    "reading": "tǐzhì",
    "meaning": "habit, constitution, crasis, habitus, health, habitude, physique",
    "grade": 7
  },
  {
    "id": 9473,
    "traditional": "替身",
    "simplified": "替身",
    "reading": "tìshēn",
    "meaning": "stand-in, substitute, body double, stuntman, scapegoat, fall guy, to stand in for sb else",
    "grade": 7
  },
  {
    "id": 9474,
    "traditional": "天長地久",
    "simplified": "天长地久",
    "reading": "tiāncháng-dìjiǔ",
    "meaning": "enduring while the world lasts (idiom), eternal",
    "grade": 7
  },
  {
    "id": 9475,
    "traditional": "天地",
    "simplified": "天地",
    "reading": "tiāndì",
    "meaning": "heaven and earth, world, scope, field of activity",
    "grade": 7
  },
  {
    "id": 9476,
    "traditional": "天鵝",
    "simplified": "天鹅",
    "reading": "tiāné",
    "meaning": "swan",
    "grade": 7
  },
  {
    "id": 9477,
    "traditional": "天分",
    "simplified": "天分",
    "reading": "tiānfèn",
    "meaning": "natural gift, talent",
    "grade": 7
  },
  {
    "id": 9478,
    "traditional": "天經地義",
    "simplified": "天经地义",
    "reading": "tiānjīng-dìyì",
    "meaning": "lit. heaven's law and earth's principle (idiom), fig. right and proper, right and unalterable, a matter of course",
    "grade": 7
  },
  {
    "id": 9479,
    "traditional": "天平",
    "simplified": "天平",
    "reading": "tiānpíng",
    "meaning": "scales (to weigh things)",
    "grade": 7
  },
  {
    "id": 9480,
    "traditional": "天賦",
    "simplified": "天赋",
    "reading": "tiānfù",
    "meaning": "gift, innate skill",
    "grade": 7
  },
  {
    "id": 9481,
    "traditional": "天橋",
    "simplified": "天桥",
    "reading": "tiānqiáo",
    "meaning": "Tianqiao district of Ji'nan city 濟南市|济南市[Ji3 nan2 shi4], Shandong, Tianqiao district in Beijing, formerly a center of folk culture, overhead walkway, pedestrian bridge",
    "grade": 7
  },
  {
    "id": 9482,
    "traditional": "天生",
    "simplified": "天生",
    "reading": "tiānshēng",
    "meaning": "inbred, born",
    "grade": 7
  },
  {
    "id": 9483,
    "traditional": "天線",
    "simplified": "天线",
    "reading": "tiānxiàn",
    "meaning": "antenna, mast, connection with high-ranking officials",
    "grade": 7
  },
  {
    "id": 9484,
    "traditional": "天使",
    "simplified": "天使",
    "reading": "tiānshǐ",
    "meaning": "angel",
    "grade": 7
  },
  {
    "id": 9485,
    "traditional": "天性",
    "simplified": "天性",
    "reading": "tiānxìng",
    "meaning": "natural instincts, nature",
    "grade": 7
  },
  {
    "id": 9486,
    "traditional": "天主教",
    "simplified": "天主教",
    "reading": "Tiānzhǔjiào",
    "meaning": "Catholicism",
    "grade": 7
  },
  {
    "id": 9487,
    "traditional": "甜美",
    "simplified": "甜美",
    "reading": "tiánměi",
    "meaning": "sweet, pleasant, happy",
    "grade": 7
  },
  {
    "id": 9488,
    "traditional": "甜蜜",
    "simplified": "甜蜜",
    "reading": "tiánmì",
    "meaning": "sweet, happy",
    "grade": 7
  },
  {
    "id": 9489,
    "traditional": "添加",
    "simplified": "添加",
    "reading": "tiānjiā",
    "meaning": "to add, to increase",
    "grade": 7
  },
  {
    "id": 9490,
    "traditional": "填補",
    "simplified": "填补",
    "reading": "tiánbǔ",
    "meaning": "to fill a gap, to fill in a blank (on a form), to overcome a deficiency",
    "grade": 7
  },
  {
    "id": 9491,
    "traditional": "填充",
    "simplified": "填充",
    "reading": "tiánchōng",
    "meaning": "pad, padding",
    "grade": 7
  },
  {
    "id": 9492,
    "traditional": "填寫",
    "simplified": "填写",
    "reading": "tiánxiě",
    "meaning": "fill in a form",
    "grade": 7
  },
  {
    "id": 9493,
    "traditional": "舔",
    "simplified": "舔",
    "reading": "tiǎn",
    "meaning": "to lick, to lap",
    "grade": 7
  },
  {
    "id": 9494,
    "traditional": "甜頭",
    "simplified": "甜头",
    "reading": "tiántou",
    "meaning": "sweet taste (of power, success etc), benefit",
    "grade": 7
  },
  {
    "id": 9495,
    "traditional": "挑剔",
    "simplified": "挑剔",
    "reading": "tiāoti",
    "meaning": "picky, fussy",
    "grade": 7
  },
  {
    "id": 9496,
    "traditional": "條款",
    "simplified": "条款",
    "reading": "tiáokuǎn",
    "meaning": "title, item, purview, detail, statute title, stipulation, proviso, point, article, provisions, provision, term, clause, condition",
    "grade": 7
  },
  {
    "id": 9497,
    "traditional": "條例",
    "simplified": "条例",
    "reading": "tiáolì",
    "meaning": "rules, regulation, enactment, regulations, ordonnance, ordinance, statute, act, prescription, ordinances",
    "grade": 7
  },
  {
    "id": 9498,
    "traditional": "條約",
    "simplified": "条约",
    "reading": "tiáoyuē",
    "meaning": "accord, understanding, pact, convention, concord, agreement, treaty",
    "grade": 7
  },
  {
    "id": 9499,
    "traditional": "調侃",
    "simplified": "调侃",
    "reading": "tiáokǎn",
    "meaning": "to ridicule, to tease, to mock, idle talk, chitchat",
    "grade": 7
  },
  {
    "id": 9500,
    "traditional": "調控",
    "simplified": "调控",
    "reading": "tiáokòng",
    "meaning": "to regulate, to control",
    "grade": 7
  },
  {
    "id": 9501,
    "traditional": "調料",
    "simplified": "调料",
    "reading": "tiáoliào",
    "meaning": "condiment, seasoning, flavoring",
    "grade": 7
  },
  {
    "id": 9502,
    "traditional": "調試",
    "simplified": "调试",
    "reading": "tiáoshì",
    "meaning": "to debug, to adjust components during testing, debugging",
    "grade": 7
  },
  {
    "id": 9503,
    "traditional": "挑起",
    "simplified": "挑起",
    "reading": "tiǎoqǐ",
    "meaning": "to provoke, to stir up, to incite",
    "grade": 7
  },
  {
    "id": 9504,
    "traditional": "挑釁",
    "simplified": "挑衅",
    "reading": "tiǎoxìn",
    "meaning": "to provoke, provocation",
    "grade": 7
  },
  {
    "id": 9505,
    "traditional": "跳槽",
    "simplified": "跳槽",
    "reading": "tiào cáo",
    "meaning": "to change jobs, job-hopping",
    "grade": 7
  },
  {
    "id": 9506,
    "traditional": "跳傘",
    "simplified": "跳伞",
    "reading": "tiàosǎn",
    "meaning": "to parachute, to bail out, parachute jumping",
    "grade": 7
  },
  {
    "id": 9507,
    "traditional": "跳動",
    "simplified": "跳动",
    "reading": "tiàodòng",
    "meaning": "to throb, to pulse, to bounce, to jiggle, to jump about",
    "grade": 7
  },
  {
    "id": 9508,
    "traditional": "跳躍",
    "simplified": "跳跃",
    "reading": "tiàoyuè",
    "meaning": "to jump, to leap, to bound, to skip",
    "grade": 7
  },
  {
    "id": 9509,
    "traditional": "貼切",
    "simplified": "贴切",
    "reading": "tiēqiè",
    "meaning": "close-fitting, closest (translation)",
    "grade": 7
  },
  {
    "id": 9510,
    "traditional": "帖子",
    "simplified": "帖子",
    "reading": "tiězi",
    "meaning": "card, invitation, message, (forum) post",
    "grade": 7
  },
  {
    "id": 9511,
    "traditional": "貼近",
    "simplified": "贴近",
    "reading": "tiējìn",
    "meaning": "to press close to, to snuggle close, intimate",
    "grade": 7
  },
  {
    "id": 9512,
    "traditional": "聽從",
    "simplified": "听从",
    "reading": "tīngcóng",
    "meaning": "to listen and obey, to comply with, to heed, to hearken",
    "grade": 7
  },
  {
    "id": 9513,
    "traditional": "停泊",
    "simplified": "停泊",
    "reading": "tíngbó",
    "meaning": "to anchor, anchorage, mooring (of a ship)",
    "grade": 7
  },
  {
    "id": 9514,
    "traditional": "聽話",
    "simplified": "听话",
    "reading": "tīng huà",
    "meaning": "to do what one is told, obedient",
    "grade": 7
  },
  {
    "id": 9515,
    "traditional": "停電",
    "simplified": "停电",
    "reading": "tíng diàn",
    "meaning": "power cut",
    "grade": 7
  },
  {
    "id": 9516,
    "traditional": "停車位",
    "simplified": "停车位",
    "reading": "tíngchēwèi",
    "meaning": "parking spot",
    "grade": 7
  },
  {
    "id": 9517,
    "traditional": "停頓",
    "simplified": "停顿",
    "reading": "tíngdùn",
    "meaning": "to halt, to break off, pause (in speech)",
    "grade": 7
  },
  {
    "id": 9518,
    "traditional": "停放",
    "simplified": "停放",
    "reading": "tíngfàng",
    "meaning": "park, place",
    "grade": 7
  },
  {
    "id": 9519,
    "traditional": "通暢",
    "simplified": "通畅",
    "reading": "tōngchàng",
    "meaning": "unobstructed, clear",
    "grade": 7
  },
  {
    "id": 9520,
    "traditional": "停業",
    "simplified": "停业",
    "reading": "tíng yè",
    "meaning": "to cease trading (temporarily or permanently), to close down",
    "grade": 7
  },
  {
    "id": 9521,
    "traditional": "通車",
    "simplified": "通车",
    "reading": "tōng chē",
    "meaning": "be open to traffic, have transport service",
    "grade": 7
  },
  {
    "id": 9522,
    "traditional": "通告",
    "simplified": "通告",
    "reading": "tōnggào",
    "meaning": "to announce, to give notice",
    "grade": 7
  },
  {
    "id": 9523,
    "traditional": "通風",
    "simplified": "通风",
    "reading": "tōngfēng",
    "meaning": "airy, ventilation, to ventilate, to disclose information",
    "grade": 7
  },
  {
    "id": 9524,
    "traditional": "通順",
    "simplified": "通顺",
    "reading": "tōngshùn",
    "meaning": "smooth, clear and coherent",
    "grade": 7
  },
  {
    "id": 9525,
    "traditional": "通俗",
    "simplified": "通俗",
    "reading": "tōngsú",
    "meaning": "pop, common, popular",
    "grade": 7
  },
  {
    "id": 9526,
    "traditional": "通緝",
    "simplified": "通缉",
    "reading": "tōngjī",
    "meaning": "to order the arrest of sb as criminal, to list as wanted",
    "grade": 7
  },
  {
    "id": 9527,
    "traditional": "通往",
    "simplified": "通往",
    "reading": "tōngwǎng",
    "meaning": "to lead to",
    "grade": 7
  },
  {
    "id": 9528,
    "traditional": "通通",
    "simplified": "通通",
    "reading": "tōngtōng",
    "meaning": "all, entire, complete",
    "grade": 7
  },
  {
    "id": 9529,
    "traditional": "通宵",
    "simplified": "通宵",
    "reading": "tōngxiāo",
    "meaning": "all night, throughout the night",
    "grade": 7
  },
  {
    "id": 9530,
    "traditional": "通行證",
    "simplified": "通行证",
    "reading": "tōngxíngzhèng",
    "meaning": "a pass (authorization through a checkpoint), a laissez-passer or safe conduct, to authenticate, to confirm name and password on a website, fig. a condition giving access to benefits (e.g. a diploma as a pass to a career)",
    "grade": 7
  },
  {
    "id": 9531,
    "traditional": "同伴",
    "simplified": "同伴",
    "reading": "tóngbàn",
    "meaning": "associate, friend, associator, chum, gentry, cully, familiar, fellow traveller, accompanist, fellow traveler, stablemate, companion, mate, compeer, peer, pard, bunkie, consociate, fellow, buddy, campmate, comrade",
    "grade": 7
  },
  {
    "id": 9532,
    "traditional": "同等",
    "simplified": "同等",
    "reading": "tóngděng",
    "meaning": "coequal, status, of same rank/status, on an equal footing, on an equal basis, of same class/rank/status, of same class, rank",
    "grade": 7
  },
  {
    "id": 9533,
    "traditional": "同感",
    "simplified": "同感",
    "reading": "tónggǎn",
    "meaning": "(have the) same feeling, similar impression, common feeling",
    "grade": 7
  },
  {
    "id": 9534,
    "traditional": "同伙",
    "simplified": "同伙",
    "reading": "tónghuǒ",
    "meaning": "colleague, co-conspirator, accomplice",
    "grade": 7
  },
  {
    "id": 9535,
    "traditional": "同步",
    "simplified": "同步",
    "reading": "tóngbù",
    "meaning": "synchronize",
    "grade": 7
  },
  {
    "id": 9536,
    "traditional": "同類",
    "simplified": "同类",
    "reading": "tónglèi",
    "meaning": "similar, same type, alike",
    "grade": 7
  },
  {
    "id": 9537,
    "traditional": "同盟",
    "simplified": "同盟",
    "reading": "tóngméng",
    "meaning": "alliance",
    "grade": 7
  },
  {
    "id": 9538,
    "traditional": "同年",
    "simplified": "同年",
    "reading": "tóngnián",
    "meaning": "the same year",
    "grade": 7
  },
  {
    "id": 9539,
    "traditional": "同人",
    "simplified": "同人",
    "reading": "tóngrén",
    "meaning": "colleagues, fellowship (among people)",
    "grade": 7
  },
  {
    "id": 9540,
    "traditional": "同志",
    "simplified": "同志",
    "reading": "tóngzhì",
    "meaning": "brother, pal, chum, crony, coterie, confrere, colleague, frater, tovarisch, compeer, paisano, brotherhood, copemate, brethren, sidekick, fellow, buddy, tavarish, comrade",
    "grade": 7
  },
  {
    "id": 9541,
    "traditional": "同舟共濟",
    "simplified": "同舟共济",
    "reading": "tóngzhōu-gòngjì",
    "meaning": "cross a river in the same boat (idiom), fig. having common interests, obliged to collaborate towards common goals",
    "grade": 7
  },
  {
    "id": 9542,
    "traditional": "統籌",
    "simplified": "统筹",
    "reading": "tǒngchóu",
    "meaning": "plan as a whole",
    "grade": 7
  },
  {
    "id": 9543,
    "traditional": "銅",
    "simplified": "铜",
    "reading": "tóng",
    "meaning": "Cu, cuprum, atomic number 29, copper, verdigris",
    "grade": 7
  },
  {
    "id": 9544,
    "traditional": "統統",
    "simplified": "统统",
    "reading": "tǒngtǒng",
    "meaning": "all, entirely, totally, completely",
    "grade": 7
  },
  {
    "id": 9545,
    "traditional": "統治",
    "simplified": "统治",
    "reading": "tǒngzhì",
    "meaning": "dominate, rule",
    "grade": 7
  },
  {
    "id": 9546,
    "traditional": "捅",
    "simplified": "捅",
    "reading": "tǒng",
    "meaning": "to stab, to poke, to prod, to nudge, to disclose",
    "grade": 7
  },
  {
    "id": 9547,
    "traditional": "桶",
    "simplified": "桶",
    "reading": "tǒng",
    "meaning": "bucket, (trash) can, barrel (of oil etc), CL:個|个[ge4],隻|只[zhi1]",
    "grade": 7
  },
  {
    "id": 9548,
    "traditional": "筒",
    "simplified": "筒",
    "reading": "tǒng",
    "meaning": "tube, cylinder, to encase in sth cylindrical (such as hands in sleeves etc), variant of 筒[tong3]",
    "grade": 7
  },
  {
    "id": 9549,
    "traditional": "痛心",
    "simplified": "痛心",
    "reading": "tòngxīn",
    "meaning": "lacerate, pained, harrowing, grieved, distressed, deplore",
    "grade": 7
  },
  {
    "id": 9550,
    "traditional": "偷看",
    "simplified": "偷看",
    "reading": "tōukàn",
    "meaning": "to peep, to peek, to steal a glance",
    "grade": 7
  },
  {
    "id": 9551,
    "traditional": "痛",
    "simplified": "痛",
    "reading": "tòng",
    "meaning": "ache, pain",
    "grade": 7
  },
  {
    "id": 9552,
    "traditional": "偷窺",
    "simplified": "偷窥",
    "reading": "tōukuī",
    "meaning": "to peep, to peek, to act as voyeur",
    "grade": 7
  },
  {
    "id": 9553,
    "traditional": "偷懶",
    "simplified": "偷懒",
    "reading": "tōu lǎn",
    "meaning": "to goof off, to be lazy",
    "grade": 7
  },
  {
    "id": 9554,
    "traditional": "頭頂",
    "simplified": "头顶",
    "reading": "tóudǐng",
    "meaning": "crown of head, head, crown, top/crown of head, top of head, top, vertex, calvaria, pate, poll",
    "grade": 7
  },
  {
    "id": 9555,
    "traditional": "頭部",
    "simplified": "头部",
    "reading": "tóubù",
    "meaning": "costard, crest, caput, head, nose, top section",
    "grade": 7
  },
  {
    "id": 9556,
    "traditional": "頭號",
    "simplified": "头号",
    "reading": "tóuhào",
    "meaning": "first rate, top rank, number one",
    "grade": 7
  },
  {
    "id": 9557,
    "traditional": "頭條",
    "simplified": "头条",
    "reading": "tóutiáo",
    "meaning": "lead story (on the news)",
    "grade": 7
  },
  {
    "id": 9558,
    "traditional": "頭頭是道",
    "simplified": "头头是道",
    "reading": "tóutóu-shìdào",
    "meaning": "clear and logical",
    "grade": 7
  },
  {
    "id": 9559,
    "traditional": "頭暈",
    "simplified": "头晕",
    "reading": "tóu yūn",
    "meaning": "dizzy",
    "grade": 7
  },
  {
    "id": 9560,
    "traditional": "投奔",
    "simplified": "投奔",
    "reading": "tóubèn",
    "meaning": "to seek shelter, to seek asylum",
    "grade": 7
  },
  {
    "id": 9561,
    "traditional": "頭銜",
    "simplified": "头衔",
    "reading": "tóuxián",
    "meaning": "title, rank, appellation",
    "grade": 7
  },
  {
    "id": 9562,
    "traditional": "投稿",
    "simplified": "投稿",
    "reading": "tóu gǎo",
    "meaning": "to submit articles for publication, to contribute (writing)",
    "grade": 7
  },
  {
    "id": 9563,
    "traditional": "投機",
    "simplified": "投机",
    "reading": "tóujī",
    "meaning": "speculate, be opportunistic",
    "grade": 7
  },
  {
    "id": 9564,
    "traditional": "投射",
    "simplified": "投射",
    "reading": "tóushè",
    "meaning": "to throw (a projectile), to cast (light)",
    "grade": 7
  },
  {
    "id": 9565,
    "traditional": "投身",
    "simplified": "投身",
    "reading": "tóushēn",
    "meaning": "to throw oneself into sth",
    "grade": 7
  },
  {
    "id": 9566,
    "traditional": "投降",
    "simplified": "投降",
    "reading": "tóuxiáng",
    "meaning": "to surrender, surrender",
    "grade": 7
  },
  {
    "id": 9567,
    "traditional": "透徹",
    "simplified": "透彻",
    "reading": "tòuchè",
    "meaning": "penetrating, thorough, incisive",
    "grade": 7
  },
  {
    "id": 9568,
    "traditional": "透過",
    "simplified": "透过",
    "reading": "tòuguò",
    "meaning": "via",
    "grade": 7
  },
  {
    "id": 9569,
    "traditional": "透支",
    "simplified": "透支",
    "reading": "tòuzhī",
    "meaning": "(bank) overdraft",
    "grade": 7
  },
  {
    "id": 9570,
    "traditional": "透氣",
    "simplified": "透气",
    "reading": "tòu qì",
    "meaning": "to flow freely (of air), to ventilate, to breathe (of fabric etc), to take a breath of fresh air, to divulge",
    "grade": 7
  },
  {
    "id": 9571,
    "traditional": "凸",
    "simplified": "凸",
    "reading": "tū",
    "meaning": "to stick out, protruding, convex, male (connector etc), Taiwan pr. [tu2]",
    "grade": 7
  },
  {
    "id": 9572,
    "traditional": "凸顯",
    "simplified": "凸显",
    "reading": "tūxiǎn",
    "meaning": "relieve, to make prominent or effective by contrast",
    "grade": 7
  },
  {
    "id": 9573,
    "traditional": "突發",
    "simplified": "突发",
    "reading": "tūfā",
    "meaning": "burst out or occur suddenly",
    "grade": 7
  },
  {
    "id": 9574,
    "traditional": "突破口",
    "simplified": "突破口",
    "reading": "tūpòkǒu",
    "meaning": "Breakout",
    "grade": 7
  },
  {
    "id": 9575,
    "traditional": "禿",
    "simplified": "秃",
    "reading": "tū",
    "meaning": "bald, blunt",
    "grade": 7
  },
  {
    "id": 9576,
    "traditional": "突擊",
    "simplified": "突击",
    "reading": "tūjī",
    "meaning": "sudden and violent attack, assault, fig. rushed job, concentrated effort to finish a job quickly",
    "grade": 7
  },
  {
    "id": 9577,
    "traditional": "圖表",
    "simplified": "图表",
    "reading": "túbiǎo",
    "meaning": "chart, diagram",
    "grade": 7
  },
  {
    "id": 9578,
    "traditional": "圖像",
    "simplified": "图像",
    "reading": "túxiàng",
    "meaning": "picture, graph, icon, image",
    "grade": 7
  },
  {
    "id": 9579,
    "traditional": "突如其來",
    "simplified": "突如其来",
    "reading": "tūrúqílái",
    "meaning": "to arise abruptly, to arrive suddenly, happening suddenly",
    "grade": 7
  },
  {
    "id": 9580,
    "traditional": "圖形",
    "simplified": "图形",
    "reading": "túxíng",
    "meaning": "motif, delineation, pattern, picture, fig, icon, graphical, graph, design, figure, sketch, construct, diagram, drawing",
    "grade": 7
  },
  {
    "id": 9581,
    "traditional": "圖紙",
    "simplified": "图纸",
    "reading": "túzhǐ",
    "meaning": "blueprint, drawing, design plans, graph paper",
    "grade": 7
  },
  {
    "id": 9582,
    "traditional": "塗",
    "simplified": "涂",
    "reading": "tú",
    "meaning": "to apply (paint etc), to smear, to daub, to blot out, to scribble, to scrawl, (literary) mud, street, variant of 途[tu2], surname Tu",
    "grade": 7
  },
  {
    "id": 9583,
    "traditional": "徒步",
    "simplified": "徒步",
    "reading": "túbù",
    "meaning": "to be on foot",
    "grade": 7
  },
  {
    "id": 9584,
    "traditional": "屠殺",
    "simplified": "屠杀",
    "reading": "túshā",
    "meaning": "to massacre, massacre, bloodbath, carnage",
    "grade": 7
  },
  {
    "id": 9585,
    "traditional": "土匪",
    "simplified": "土匪",
    "reading": "tǔfěi",
    "meaning": "bandit",
    "grade": 7
  },
  {
    "id": 9586,
    "traditional": "土壤",
    "simplified": "土壤",
    "reading": "tǔrǎng",
    "meaning": "soil",
    "grade": 7
  },
  {
    "id": 9587,
    "traditional": "土生土長",
    "simplified": "土生土长",
    "reading": "tǔshēng-tǔzhǎng",
    "meaning": "locally born and bred, indigenous, home-grown",
    "grade": 7
  },
  {
    "id": 9588,
    "traditional": "團聚",
    "simplified": "团聚",
    "reading": "tuánjù",
    "meaning": "to reunite, to have a reunion",
    "grade": 7
  },
  {
    "id": 9589,
    "traditional": "團伙",
    "simplified": "团伙",
    "reading": "tuánhuǒ",
    "meaning": "(criminal) gang, gang member, accomplice, crony",
    "grade": 7
  },
  {
    "id": 9590,
    "traditional": "團員",
    "simplified": "团员",
    "reading": "tuányuán",
    "meaning": "member (of delegation/organization/etc.)",
    "grade": 7
  },
  {
    "id": 9591,
    "traditional": "推測",
    "simplified": "推测",
    "reading": "tuīcè",
    "meaning": "infer, conjecture, guess",
    "grade": 7
  },
  {
    "id": 9592,
    "traditional": "團圓",
    "simplified": "团圆",
    "reading": "tuányuán",
    "meaning": "to have a reunion",
    "grade": 7
  },
  {
    "id": 9593,
    "traditional": "推斷",
    "simplified": "推断",
    "reading": "tuīduàn",
    "meaning": "to infer, to deduce, to predict, to extrapolate",
    "grade": 7
  },
  {
    "id": 9594,
    "traditional": "推翻",
    "simplified": "推翻",
    "reading": "tuī fān",
    "meaning": "overturn, topple, repudiate, reverse",
    "grade": 7
  },
  {
    "id": 9595,
    "traditional": "推辭",
    "simplified": "推辞",
    "reading": "tuīcí",
    "meaning": "to decline (an appointment, invitation etc)",
    "grade": 7
  },
  {
    "id": 9596,
    "traditional": "推薦",
    "simplified": "推荐",
    "reading": "tuījiàn",
    "meaning": "recommend",
    "grade": 7
  },
  {
    "id": 9597,
    "traditional": "推理",
    "simplified": "推理",
    "reading": "tuīlǐ",
    "meaning": "reasoning, speculative, inference",
    "grade": 7
  },
  {
    "id": 9598,
    "traditional": "推敲",
    "simplified": "推敲",
    "reading": "tuīqiāo",
    "meaning": "to think over",
    "grade": 7
  },
  {
    "id": 9599,
    "traditional": "推卸",
    "simplified": "推卸",
    "reading": "tuīxiè",
    "meaning": "to avoid (esp. responsibility), to shift (the blame), to pass the buck",
    "grade": 7
  },
  {
    "id": 9600,
    "traditional": "推算",
    "simplified": "推算",
    "reading": "tuīsuàn",
    "meaning": "to calculate, to reckon, to extrapolate (in calculation)",
    "grade": 7
  },
  {
    "id": 9601,
    "traditional": "推選",
    "simplified": "推选",
    "reading": "tuīxuǎn",
    "meaning": "to elect, to choose, to nominate",
    "grade": 7
  },
  {
    "id": 9602,
    "traditional": "推移",
    "simplified": "推移",
    "reading": "tuīyí",
    "meaning": "(of time) to elapse or pass, (of a situation) to develop or evolve",
    "grade": 7
  },
  {
    "id": 9603,
    "traditional": "頹廢",
    "simplified": "颓废",
    "reading": "tuífèi",
    "meaning": "decadent, dispirited, depressed, dejected",
    "grade": 7
  },
  {
    "id": 9604,
    "traditional": "退回",
    "simplified": "退回",
    "reading": "tuì huí",
    "meaning": "to return (an item), to send back, to go back",
    "grade": 7
  },
  {
    "id": 9605,
    "traditional": "退卻",
    "simplified": "退却",
    "reading": "tuìquè",
    "meaning": "to retreat, to shrink back",
    "grade": 7
  },
  {
    "id": 9606,
    "traditional": "退讓",
    "simplified": "退让",
    "reading": "tuìràng",
    "meaning": "to move aside, to get out of the way, to back down, to concede",
    "grade": 7
  },
  {
    "id": 9607,
    "traditional": "退學",
    "simplified": "退学",
    "reading": "tuì xué",
    "meaning": "to quit school",
    "grade": 7
  },
  {
    "id": 9608,
    "traditional": "退休金",
    "simplified": "退休金",
    "reading": "tuìxiūjīn",
    "meaning": "retirement pay, pension",
    "grade": 7
  },
  {
    "id": 9609,
    "traditional": "退縮",
    "simplified": "退缩",
    "reading": "tuìsuō",
    "meaning": "to shrink back, to cower",
    "grade": 7
  },
  {
    "id": 9610,
    "traditional": "退役",
    "simplified": "退役",
    "reading": "tuì yì",
    "meaning": "to retire from the military, sport etc, to demobilize, to decommission, retired from use",
    "grade": 7
  },
  {
    "id": 9611,
    "traditional": "屯",
    "simplified": "屯",
    "reading": "tún",
    "meaning": "to station (soldiers), to store up, village, difficult, stingy",
    "grade": 7
  },
  {
    "id": 9612,
    "traditional": "拖累",
    "simplified": "拖累",
    "reading": "tuōlěi",
    "meaning": "to encumber, to be a burden on, to implicate",
    "grade": 7
  },
  {
    "id": 9613,
    "traditional": "拖欠",
    "simplified": "拖欠",
    "reading": "tuōqiàn",
    "meaning": "in arrears, behind in payments, to default on one's debts",
    "grade": 7
  },
  {
    "id": 9614,
    "traditional": "託付",
    "simplified": "托付",
    "reading": "tuōfù",
    "meaning": "to entrust",
    "grade": 7
  },
  {
    "id": 9615,
    "traditional": "拖延",
    "simplified": "拖延",
    "reading": "tuōyán",
    "meaning": "delay, put off",
    "grade": 7
  },
  {
    "id": 9616,
    "traditional": "脫節",
    "simplified": "脱节",
    "reading": "tuō jié",
    "meaning": "to come apart",
    "grade": 7
  },
  {
    "id": 9617,
    "traditional": "脫口而出",
    "simplified": "脱口而出",
    "reading": "tuōkǒuérchū",
    "meaning": "to blurt out, to let slip (an indiscreet remark)",
    "grade": 7
  },
  {
    "id": 9618,
    "traditional": "脫身",
    "simplified": "脱身",
    "reading": "tuō shēn",
    "meaning": "to get away, to escape (from obligations), to free oneself, to disengage",
    "grade": 7
  },
  {
    "id": 9619,
    "traditional": "脫落",
    "simplified": "脱落",
    "reading": "tuōluò",
    "meaning": "to drop off, to come off, to lose (hair etc), to omit (a character when writing)",
    "grade": 7
  },
  {
    "id": 9620,
    "traditional": "脫穎而出",
    "simplified": "脱颖而出",
    "reading": "tuōyǐngérchū",
    "meaning": "stand out, leap out, come to the fore, jump out, jump, show itself",
    "grade": 7
  },
  {
    "id": 9621,
    "traditional": "馱",
    "simplified": "驮",
    "reading": "tuó",
    "meaning": "to carry on one's back, load carried by a pack animal",
    "grade": 7
  },
  {
    "id": 9622,
    "traditional": "妥當",
    "simplified": "妥当",
    "reading": "tuǒdang",
    "meaning": "appropriate, proper, ready",
    "grade": 7
  },
  {
    "id": 9623,
    "traditional": "妥善",
    "simplified": "妥善",
    "reading": "tuǒshàn",
    "meaning": "appropriate, proper",
    "grade": 7
  },
  {
    "id": 9624,
    "traditional": "妥",
    "simplified": "妥",
    "reading": "tuǒ",
    "meaning": "suitable, adequate, ready, settled",
    "grade": 7
  },
  {
    "id": 9625,
    "traditional": "妥協",
    "simplified": "妥协",
    "reading": "tuǒxié",
    "meaning": "come to terms, compromise",
    "grade": 7
  },
  {
    "id": 9626,
    "traditional": "拓寬",
    "simplified": "拓宽",
    "reading": "tuòkuān",
    "meaning": "broaden",
    "grade": 7
  },
  {
    "id": 9627,
    "traditional": "唾液",
    "simplified": "唾液",
    "reading": "tuòyè",
    "meaning": "saliva",
    "grade": 7
  },
  {
    "id": 9628,
    "traditional": "拓展",
    "simplified": "拓展",
    "reading": "tuòzhǎn",
    "meaning": "expand",
    "grade": 7
  },
  {
    "id": 9629,
    "traditional": "挖掘",
    "simplified": "挖掘",
    "reading": "wājué",
    "meaning": "excavate, unearth",
    "grade": 7
  },
  {
    "id": 9630,
    "traditional": "瓦",
    "simplified": "瓦",
    "reading": "wǎ",
    "meaning": "roof tile, abbr. for 瓦特[wa3 te4], watt (loan)",
    "grade": 7
  },
  {
    "id": 9631,
    "traditional": "挖苦",
    "simplified": "挖苦",
    "reading": "wāku",
    "meaning": "to speak sarcastically, to make cutting remarks",
    "grade": 7
  },
  {
    "id": 9632,
    "traditional": "歪",
    "simplified": "歪",
    "reading": "wāi",
    "meaning": "askew, at a crooked angle, devious, noxious, (coll.) to lie on one's side, to sprain (one's ankle) (Tw)",
    "grade": 7
  },
  {
    "id": 9633,
    "traditional": "歪曲",
    "simplified": "歪曲",
    "reading": "wāiqū",
    "meaning": "to distort, to misrepresent",
    "grade": 7
  },
  {
    "id": 9634,
    "traditional": "外表",
    "simplified": "外表",
    "reading": "wàibiǎo",
    "meaning": "shell, outward appearance, outwall, mess, presentation, resemblance, externality, semblance, guise, visual aspect, outwardness, exterior, rind, showing, look, superficies, likeness, mien, appearance, vizard, surface, garment, external, getup",
    "grade": 7
  },
  {
    "id": 9635,
    "traditional": "外公",
    "simplified": "外公",
    "reading": "wàigōng",
    "meaning": "maternal grandfather",
    "grade": 7
  },
  {
    "id": 9636,
    "traditional": "外行",
    "simplified": "外行",
    "reading": "wàiháng",
    "meaning": "layman, amateur",
    "grade": 7
  },
  {
    "id": 9637,
    "traditional": "外號",
    "simplified": "外号",
    "reading": "wàihào",
    "meaning": "nickname",
    "grade": 7
  },
  {
    "id": 9638,
    "traditional": "外籍",
    "simplified": "外籍",
    "reading": "wàijí",
    "meaning": "foreign nationality",
    "grade": 7
  },
  {
    "id": 9639,
    "traditional": "外貿",
    "simplified": "外贸",
    "reading": "wàimào",
    "meaning": "foreign trade",
    "grade": 7
  },
  {
    "id": 9640,
    "traditional": "外貌",
    "simplified": "外貌",
    "reading": "wàimào",
    "meaning": "profile, appearance",
    "grade": 7
  },
  {
    "id": 9641,
    "traditional": "外企",
    "simplified": "外企",
    "reading": "wàiqǐ",
    "meaning": "foreign enterprise, company established in mainland China with direct investment from foreign entities or from investors in Taiwan, Macao or Hong Kong, abbr. for 外資企業|外资企业",
    "grade": 7
  },
  {
    "id": 9642,
    "traditional": "外星人",
    "simplified": "外星人",
    "reading": "wàixīngrén",
    "meaning": "space alien, extraterrestrial",
    "grade": 7
  },
  {
    "id": 9643,
    "traditional": "外形",
    "simplified": "外形",
    "reading": "wàixíng",
    "meaning": "figure, shape, external form, contour",
    "grade": 7
  },
  {
    "id": 9644,
    "traditional": "外婆",
    "simplified": "外婆",
    "reading": "wàipó",
    "meaning": "granny, maternal grandmother, grandma, grandmother",
    "grade": 7
  },
  {
    "id": 9645,
    "traditional": "丸",
    "simplified": "丸",
    "reading": "wán",
    "meaning": "ball, pellet, pill",
    "grade": 7
  },
  {
    "id": 9646,
    "traditional": "完備",
    "simplified": "完备",
    "reading": "wánbèi",
    "meaning": "perfect",
    "grade": 7
  },
  {
    "id": 9647,
    "traditional": "外援",
    "simplified": "外援",
    "reading": "wàiyuán",
    "meaning": "external help, foreign aid",
    "grade": 7
  },
  {
    "id": 9648,
    "traditional": "完蛋",
    "simplified": "完蛋",
    "reading": "wán dàn",
    "meaning": "(coll.) to be done for",
    "grade": 7
  },
  {
    "id": 9649,
    "traditional": "完畢",
    "simplified": "完毕",
    "reading": "wánbì",
    "meaning": "end, conclude, complete, completion, finish",
    "grade": 7
  },
  {
    "id": 9650,
    "traditional": "完好",
    "simplified": "完好",
    "reading": "wánhǎo",
    "meaning": "intact, in good condition",
    "grade": 7
  },
  {
    "id": 9651,
    "traditional": "玩耍",
    "simplified": "玩耍",
    "reading": "wánshuǎ",
    "meaning": "to play (as children do), to amuse oneself",
    "grade": 7
  },
  {
    "id": 9652,
    "traditional": "玩意兒",
    "simplified": "玩意儿",
    "reading": "wányìr5",
    "meaning": "toy, plaything, thing, act, trick (in a performance, stage show, acrobatics etc), erhua variant of 玩意[wan2 yi4]",
    "grade": 7
  },
  {
    "id": 9653,
    "traditional": "頑固",
    "simplified": "顽固",
    "reading": "wángù",
    "meaning": "stubborn, obstinate",
    "grade": 7
  },
  {
    "id": 9654,
    "traditional": "挽回",
    "simplified": "挽回",
    "reading": "wǎn huí",
    "meaning": "retrieval, recover, retrieve, save, redeem, deliver",
    "grade": 7
  },
  {
    "id": 9655,
    "traditional": "挽",
    "simplified": "挽",
    "reading": "wǎn",
    "meaning": "variant of 挽[wan3], to draw (a cart), to lament the dead",
    "grade": 7
  },
  {
    "id": 9656,
    "traditional": "挽救",
    "simplified": "挽救",
    "reading": "wǎnjiù",
    "meaning": "to save, to remedy, to rescue",
    "grade": 7
  },
  {
    "id": 9657,
    "traditional": "晚間",
    "simplified": "晚间",
    "reading": "wǎnjiān",
    "meaning": "(in the) evening, night, in the evening, evening, (at) night, at night",
    "grade": 7
  },
  {
    "id": 9658,
    "traditional": "晚期",
    "simplified": "晚期",
    "reading": "wǎnqī",
    "meaning": "later period, end stage, terminal",
    "grade": 7
  },
  {
    "id": 9659,
    "traditional": "萬分",
    "simplified": "万分",
    "reading": "wànfēn",
    "meaning": "very much, extremely, one ten thousandth part",
    "grade": 7
  },
  {
    "id": 9660,
    "traditional": "惋惜",
    "simplified": "惋惜",
    "reading": "wǎnxī",
    "meaning": "to regret, to feel that it is a great pity, to feel sorry for sb",
    "grade": 7
  },
  {
    "id": 9661,
    "traditional": "萬古長青",
    "simplified": "万古长青",
    "reading": "wàngǔ-chángqīng",
    "meaning": "remain fresh, last forever, eternal",
    "grade": 7
  },
  {
    "id": 9662,
    "traditional": "萬能",
    "simplified": "万能",
    "reading": "wànnéng",
    "meaning": "omnipotent, all-purpose, universal",
    "grade": 7
  },
  {
    "id": 9663,
    "traditional": "晚年",
    "simplified": "晚年",
    "reading": "wǎnnián",
    "meaning": "one's later years",
    "grade": 7
  },
  {
    "id": 9664,
    "traditional": "萬無一失",
    "simplified": "万无一失",
    "reading": "wànwúyīshī",
    "meaning": "surefire, absolutely safe (idiom)",
    "grade": 7
  },
  {
    "id": 9665,
    "traditional": "萬萬",
    "simplified": "万万",
    "reading": "wànwàn",
    "meaning": "absolutely, wholly",
    "grade": 7
  },
  {
    "id": 9666,
    "traditional": "汪洋",
    "simplified": "汪洋",
    "reading": "wāngyáng",
    "meaning": "vast body of water, CL:片[pian4]",
    "grade": 7
  },
  {
    "id": 9667,
    "traditional": "亡羊補牢",
    "simplified": "亡羊补牢",
    "reading": "wángyáng-bǔláo",
    "meaning": "lit. to mend the pen after sheep are lost (idiom), fig. to act belatedly, better late than never, to lock the stable door after the horse has bolted",
    "grade": 7
  },
  {
    "id": 9668,
    "traditional": "王國",
    "simplified": "王国",
    "reading": "wángguó",
    "meaning": "kingdom, realm, domain",
    "grade": 7
  },
  {
    "id": 9669,
    "traditional": "網點",
    "simplified": "网点",
    "reading": "wǎngdiǎn",
    "meaning": "node in a network, branch, website",
    "grade": 7
  },
  {
    "id": 9670,
    "traditional": "網民",
    "simplified": "网民",
    "reading": "wǎngmín",
    "meaning": "web user, netizen",
    "grade": 7
  },
  {
    "id": 9671,
    "traditional": "往常",
    "simplified": "往常",
    "reading": "wǎngcháng",
    "meaning": "habitually (in the past), as one used to do formerly, as it used to be",
    "grade": 7
  },
  {
    "id": 9672,
    "traditional": "往返",
    "simplified": "往返",
    "reading": "wǎngfǎn",
    "meaning": "to go back and forth, to go to and fro, round trip",
    "grade": 7
  },
  {
    "id": 9673,
    "traditional": "王牌",
    "simplified": "王牌",
    "reading": "wángpái",
    "meaning": "trump card",
    "grade": 7
  },
  {
    "id": 9674,
    "traditional": "往事",
    "simplified": "往事",
    "reading": "wǎngshì",
    "meaning": "past, the past, past events",
    "grade": 7
  },
  {
    "id": 9675,
    "traditional": "妄想",
    "simplified": "妄想",
    "reading": "wàngxiǎng",
    "meaning": "to attempt vainly, a vain attempt, delusion",
    "grade": 7
  },
  {
    "id": 9676,
    "traditional": "往日",
    "simplified": "往日",
    "reading": "wǎngrì",
    "meaning": "in former days",
    "grade": 7
  },
  {
    "id": 9677,
    "traditional": "忘掉",
    "simplified": "忘掉",
    "reading": "wàng diào",
    "meaning": "to forget",
    "grade": 7
  },
  {
    "id": 9678,
    "traditional": "忘不了",
    "simplified": "忘不了",
    "reading": "wàngbùliǎo",
    "meaning": "cannot forget",
    "grade": 7
  },
  {
    "id": 9679,
    "traditional": "旺季",
    "simplified": "旺季",
    "reading": "wàngjì",
    "meaning": "busy season, peak period, see also 淡季[dan4 ji4]",
    "grade": 7
  },
  {
    "id": 9680,
    "traditional": "旺盛",
    "simplified": "旺盛",
    "reading": "wàngshèng",
    "meaning": "vigorous, prosperous, exuberant",
    "grade": 7
  },
  {
    "id": 9681,
    "traditional": "望",
    "simplified": "望",
    "reading": "wàng",
    "meaning": "gaze into distance, observe",
    "grade": 7
  },
  {
    "id": 9682,
    "traditional": "旺",
    "simplified": "旺",
    "reading": "wàng",
    "meaning": "prosperous, flourishing, (of flowers) blooming, (of fire) roaring",
    "grade": 7
  },
  {
    "id": 9683,
    "traditional": "危及",
    "simplified": "危及",
    "reading": "wēijí",
    "meaning": "to endanger, to jeopardize, a danger (to life, national security etc)",
    "grade": 7
  },
  {
    "id": 9684,
    "traditional": "望遠鏡",
    "simplified": "望远镜",
    "reading": "wàngyuǎnjìng",
    "meaning": "telescope",
    "grade": 7
  },
  {
    "id": 9685,
    "traditional": "危急",
    "simplified": "危急",
    "reading": "wēijí",
    "meaning": "critical, desperate (situation)",
    "grade": 7
  },
  {
    "id": 9686,
    "traditional": "威風",
    "simplified": "威风",
    "reading": "wēifēng",
    "meaning": "might, awe-inspiring authority, impressive",
    "grade": 7
  },
  {
    "id": 9687,
    "traditional": "威懾",
    "simplified": "威慑",
    "reading": "wēishè",
    "meaning": "to cower by military force, deterrence",
    "grade": 7
  },
  {
    "id": 9688,
    "traditional": "威力",
    "simplified": "威力",
    "reading": "wēilì",
    "meaning": "world power, superpower, major power, rod, great power, prowess, tooth, might, power",
    "grade": 7
  },
  {
    "id": 9689,
    "traditional": "威信",
    "simplified": "威信",
    "reading": "wēixìn",
    "meaning": "Weixin county in Zhaotong 昭通[Zhao1 tong1], Yunnan, prestige, reputation, trust, credit with the people",
    "grade": 7
  },
  {
    "id": 9690,
    "traditional": "微不足道",
    "simplified": "微不足道",
    "reading": "wēibùzúdào",
    "meaning": "negligible, insignificant",
    "grade": 7
  },
  {
    "id": 9691,
    "traditional": "微觀",
    "simplified": "微观",
    "reading": "wēiguān",
    "meaning": "micro-, sub-atomic",
    "grade": 7
  },
  {
    "id": 9692,
    "traditional": "微妙",
    "simplified": "微妙",
    "reading": "wēimiào",
    "meaning": "subtle",
    "grade": 7
  },
  {
    "id": 9693,
    "traditional": "微型",
    "simplified": "微型",
    "reading": "wēixíng",
    "meaning": "miniature, micro-, tiny",
    "grade": 7
  },
  {
    "id": 9694,
    "traditional": "微弱",
    "simplified": "微弱",
    "reading": "wēiruò",
    "meaning": "faint, feeble, weak",
    "grade": 7
  },
  {
    "id": 9695,
    "traditional": "為人",
    "simplified": "为人",
    "reading": "wéirén",
    "meaning": "to conduct oneself, behavior, conduct, personal character, for sb, for others' interest",
    "grade": 7
  },
  {
    "id": 9696,
    "traditional": "違約",
    "simplified": "违约",
    "reading": "wéi yuē",
    "meaning": "to break a promise, to violate an agreement, to default (on a loan or contract)",
    "grade": 7
  },
  {
    "id": 9697,
    "traditional": "違章",
    "simplified": "违章",
    "reading": "wéi zhāng",
    "meaning": "to break the rules, to violate regulations",
    "grade": 7
  },
  {
    "id": 9698,
    "traditional": "違背",
    "simplified": "违背",
    "reading": "wéibèi",
    "meaning": "violate, sin, go_against, infringe, go against, violation, belie, transgress, contravene, disobey, breach, break, run counter to",
    "grade": 7
  },
  {
    "id": 9699,
    "traditional": "圍牆",
    "simplified": "围墙",
    "reading": "wéiqiáng",
    "meaning": "enclosing wall",
    "grade": 7
  },
  {
    "id": 9700,
    "traditional": "唯",
    "simplified": "唯",
    "reading": "wéi",
    "meaning": "only, alone, but",
    "grade": 7
  },
  {
    "id": 9701,
    "traditional": "唯獨",
    "simplified": "唯独",
    "reading": "wéidú",
    "meaning": "only, just (i.e. it is only that...), all except, unique",
    "grade": 7
  },
  {
    "id": 9702,
    "traditional": "偽造",
    "simplified": "伪造",
    "reading": "wěizào",
    "meaning": "to forge, to fake, to counterfeit",
    "grade": 7
  },
  {
    "id": 9703,
    "traditional": "偽裝",
    "simplified": "伪装",
    "reading": "wěizhuāng",
    "meaning": "to pretend to be (asleep etc), to disguise oneself as, pretense, disguise, (military) to camouflage, camouflage",
    "grade": 7
  },
  {
    "id": 9704,
    "traditional": "尾聲",
    "simplified": "尾声",
    "reading": "wěishēng",
    "meaning": "coda, epilogue, end",
    "grade": 7
  },
  {
    "id": 9705,
    "traditional": "尾氣",
    "simplified": "尾气",
    "reading": "wěiqì",
    "meaning": "exhaust (i.e. waste gas from engine), emissions",
    "grade": 7
  },
  {
    "id": 9706,
    "traditional": "緯度",
    "simplified": "纬度",
    "reading": "wěidù",
    "meaning": "latitude",
    "grade": 7
  },
  {
    "id": 9707,
    "traditional": "委婉",
    "simplified": "委婉",
    "reading": "wěiwǎn",
    "meaning": "tactful, euphemistic, (of voice etc) suave, soft",
    "grade": 7
  },
  {
    "id": 9708,
    "traditional": "委屈",
    "simplified": "委屈",
    "reading": "wěiqu",
    "meaning": "feel wronged, be obstructed (in career), inconvenience (sb.)",
    "grade": 7
  },
  {
    "id": 9709,
    "traditional": "委員",
    "simplified": "委员",
    "reading": "wěiyuán",
    "meaning": "commissar, committee man, committee, committee member, commissioner, committeeman, delegate, board member, commissary",
    "grade": 7
  },
  {
    "id": 9710,
    "traditional": "委員會",
    "simplified": "委员会",
    "reading": "wěiyuánhuì",
    "meaning": "board, soviet, commission, committee, court, citizens committee, mission, delegacy, deputation, delegation, council",
    "grade": 7
  },
  {
    "id": 9711,
    "traditional": "萎縮",
    "simplified": "萎缩",
    "reading": "wěisuō",
    "meaning": "wither, shrivel, shrink, sag, atrophy",
    "grade": 7
  },
  {
    "id": 9712,
    "traditional": "未",
    "simplified": "未",
    "reading": "wèi",
    "meaning": "or not (at end of sentence), have not yet, or not, not",
    "grade": 7
  },
  {
    "id": 9713,
    "traditional": "衛視",
    "simplified": "卫视",
    "reading": "wèishì",
    "meaning": "satellite TV, abbr. of 衛星電視|卫星电视",
    "grade": 7
  },
  {
    "id": 9714,
    "traditional": "未成年人",
    "simplified": "未成年人",
    "reading": "wèichéngniánrén",
    "meaning": "minor (i.e. person under 18)",
    "grade": 7
  },
  {
    "id": 9715,
    "traditional": "未經",
    "simplified": "未经",
    "reading": "wèijīng",
    "meaning": "not having undergone, without (having gone though a certain process)",
    "grade": 7
  },
  {
    "id": 9716,
    "traditional": "未免",
    "simplified": "未免",
    "reading": "wèimiǎn",
    "meaning": "unavoidably, can't help, really, rather",
    "grade": 7
  },
  {
    "id": 9717,
    "traditional": "未知數",
    "simplified": "未知数",
    "reading": "wèizhīshù",
    "meaning": "unknown quantity",
    "grade": 7
  },
  {
    "id": 9718,
    "traditional": "味精",
    "simplified": "味精",
    "reading": "wèijīng",
    "meaning": "monosodium glutamate (MSG)",
    "grade": 7
  },
  {
    "id": 9719,
    "traditional": "位子",
    "simplified": "位子",
    "reading": "wèizi",
    "meaning": "seat, place",
    "grade": 7
  },
  {
    "id": 9720,
    "traditional": "畏懼",
    "simplified": "畏惧",
    "reading": "wèijù",
    "meaning": "to fear, to dread, foreboding",
    "grade": 7
  },
  {
    "id": 9721,
    "traditional": "畏縮",
    "simplified": "畏缩",
    "reading": "wèisuō",
    "meaning": "to cower, to flinch, to quail, to recoil",
    "grade": 7
  },
  {
    "id": 9722,
    "traditional": "喂養",
    "simplified": "喂养",
    "reading": "wèiyǎng",
    "meaning": "to feed (a child, domestic animal etc), to keep, to raise (an animal)",
    "grade": 7
  },
  {
    "id": 9723,
    "traditional": "慰勞",
    "simplified": "慰劳",
    "reading": "wèiláo",
    "meaning": "to show appreciation (by kind words, small gifts etc), to comfort",
    "grade": 7
  },
  {
    "id": 9724,
    "traditional": "胃口",
    "simplified": "胃口",
    "reading": "wèikou",
    "meaning": "appetite, liking",
    "grade": 7
  },
  {
    "id": 9725,
    "traditional": "溫度計",
    "simplified": "温度计",
    "reading": "wēndùjì",
    "meaning": "thermometer, thermograph",
    "grade": 7
  },
  {
    "id": 9726,
    "traditional": "溫柔",
    "simplified": "温柔",
    "reading": "wēnróu",
    "meaning": "gentle and soft",
    "grade": 7
  },
  {
    "id": 9727,
    "traditional": "溫泉",
    "simplified": "温泉",
    "reading": "wēnquán",
    "meaning": "hot_spring, watering_place, thermae, thermal spring, hot spring",
    "grade": 7
  },
  {
    "id": 9728,
    "traditional": "溫習",
    "simplified": "温习",
    "reading": "wēnxí",
    "meaning": "to review (a lesson etc)",
    "grade": 7
  },
  {
    "id": 9729,
    "traditional": "溫室",
    "simplified": "温室",
    "reading": "wēnshì",
    "meaning": "greenhouse",
    "grade": 7
  },
  {
    "id": 9730,
    "traditional": "溫馨",
    "simplified": "温馨",
    "reading": "wēnxīn",
    "meaning": "warm and fragrant",
    "grade": 7
  },
  {
    "id": 9731,
    "traditional": "文",
    "simplified": "文",
    "reading": "wén",
    "meaning": "surname Wen, language, culture, writing, formal, literary, gentle, (old) classifier for coins, Kangxi radical 67",
    "grade": 7
  },
  {
    "id": 9732,
    "traditional": "瘟疫",
    "simplified": "瘟疫",
    "reading": "wēnyì",
    "meaning": "epidemic, plague, pestilence, diseased",
    "grade": 7
  },
  {
    "id": 9733,
    "traditional": "文具",
    "simplified": "文具",
    "reading": "wénjù",
    "meaning": "stationery, item of stationery (pen, pencil, eraser, pencil sharpener etc)",
    "grade": 7
  },
  {
    "id": 9734,
    "traditional": "文科",
    "simplified": "文科",
    "reading": "wénkē",
    "meaning": "liberal arts, humanities",
    "grade": 7
  },
  {
    "id": 9735,
    "traditional": "文憑",
    "simplified": "文凭",
    "reading": "wénpíng",
    "meaning": "diploma",
    "grade": 7
  },
  {
    "id": 9736,
    "traditional": "文盲",
    "simplified": "文盲",
    "reading": "wénmáng",
    "meaning": "illiterate",
    "grade": 7
  },
  {
    "id": 9737,
    "traditional": "文人",
    "simplified": "文人",
    "reading": "wénrén",
    "meaning": "man of letters, scholar, literati, civilian (vs. military)",
    "grade": 7
  },
  {
    "id": 9738,
    "traditional": "文獻",
    "simplified": "文献",
    "reading": "wénxiàn",
    "meaning": "document, bibliography, data related to a country's history, bibliographic, literature, data, data/documents related to a country's history/culture/etc., documents related to a country's culture, documents related to a country's history, culture, etc.",
    "grade": 7
  },
  {
    "id": 9739,
    "traditional": "文雅",
    "simplified": "文雅",
    "reading": "wényǎ",
    "meaning": "elegant, refined",
    "grade": 7
  },
  {
    "id": 9740,
    "traditional": "聞名",
    "simplified": "闻名",
    "reading": "wénmíng",
    "meaning": "known, famous, know sb. by repute, be familiar with sb.'s name, well-known, famed",
    "grade": 7
  },
  {
    "id": 9741,
    "traditional": "蚊帳",
    "simplified": "蚊帐",
    "reading": "wénzhàng",
    "meaning": "mosquito net, CL:頂|顶[ding3]",
    "grade": 7
  },
  {
    "id": 9742,
    "traditional": "文物",
    "simplified": "文物",
    "reading": "wénwù",
    "meaning": "historical relics, cultural objects, artifact, historical relic, cultural relic, cultural/historical objects/relics, cultural, historical objects, relics",
    "grade": 7
  },
  {
    "id": 9743,
    "traditional": "蚊子",
    "simplified": "蚊子",
    "reading": "wénzi",
    "meaning": "culex, mosquito, punkie",
    "grade": 7
  },
  {
    "id": 9744,
    "traditional": "吻",
    "simplified": "吻",
    "reading": "wěn",
    "meaning": "kiss, to kiss, mouth, variant of 吻[wen3]",
    "grade": 7
  },
  {
    "id": 9745,
    "traditional": "吻合",
    "simplified": "吻合",
    "reading": "wěnhé",
    "meaning": "to be a good fit, to be identical with, to adjust oneself to, to fit in",
    "grade": 7
  },
  {
    "id": 9746,
    "traditional": "紊亂",
    "simplified": "紊乱",
    "reading": "wěnluàn",
    "meaning": "disorder, chaos",
    "grade": 7
  },
  {
    "id": 9747,
    "traditional": "穩固",
    "simplified": "稳固",
    "reading": "wěngù",
    "meaning": "stable, steady, firm, to stabilize",
    "grade": 7
  },
  {
    "id": 9748,
    "traditional": "穩健",
    "simplified": "稳健",
    "reading": "wěnjiàn",
    "meaning": "firm, stable and steady",
    "grade": 7
  },
  {
    "id": 9749,
    "traditional": "穩重",
    "simplified": "稳重",
    "reading": "wěnzhòng",
    "meaning": "steady, earnest, staid",
    "grade": 7
  },
  {
    "id": 9750,
    "traditional": "穩妥",
    "simplified": "稳妥",
    "reading": "wěntuǒ",
    "meaning": "dependable",
    "grade": 7
  },
  {
    "id": 9751,
    "traditional": "問卷",
    "simplified": "问卷",
    "reading": "wènjuàn",
    "meaning": "questionnaire",
    "grade": 7
  },
  {
    "id": 9752,
    "traditional": "窩",
    "simplified": "窝",
    "reading": "wō",
    "meaning": "nest, pit or hollow on the human body, lair, den, place, to harbor or shelter, to hold in check, to bend, classifier for litters and broods",
    "grade": 7
  },
  {
    "id": 9753,
    "traditional": "問世",
    "simplified": "问世",
    "reading": "wènshì",
    "meaning": "to be published, to come out",
    "grade": 7
  },
  {
    "id": 9754,
    "traditional": "臥",
    "simplified": "卧",
    "reading": "wò",
    "meaning": "to lie, to crouch",
    "grade": 7
  },
  {
    "id": 9755,
    "traditional": "污穢",
    "simplified": "污秽",
    "reading": "wūhuì",
    "meaning": "nasty, sordid, filthy",
    "grade": 7
  },
  {
    "id": 9756,
    "traditional": "巫婆",
    "simplified": "巫婆",
    "reading": "wūpó",
    "meaning": "witch, sorceress, female shaman",
    "grade": 7
  },
  {
    "id": 9757,
    "traditional": "嗚咽",
    "simplified": "呜咽",
    "reading": "wūyè",
    "meaning": "to sob, to whimper",
    "grade": 7
  },
  {
    "id": 9758,
    "traditional": "無比",
    "simplified": "无比",
    "reading": "wúbǐ",
    "meaning": "unparalleled, matchless",
    "grade": 7
  },
  {
    "id": 9759,
    "traditional": "屋頂",
    "simplified": "屋顶",
    "reading": "wūdǐng",
    "meaning": "roofing, rigging, rooftree, cap, roof top, house top, rooftop, attic, roof, housetop",
    "grade": 7
  },
  {
    "id": 9760,
    "traditional": "無不",
    "simplified": "无不",
    "reading": "wúbù",
    "meaning": "without exception, invariably",
    "grade": 7
  },
  {
    "id": 9761,
    "traditional": "無償",
    "simplified": "无偿",
    "reading": "wúcháng",
    "meaning": "free, no charge, at no cost",
    "grade": 7
  },
  {
    "id": 9762,
    "traditional": "無敵",
    "simplified": "无敌",
    "reading": "wúdí",
    "meaning": "unequalled, without rival, a paragon",
    "grade": 7
  },
  {
    "id": 9763,
    "traditional": "無惡不作",
    "simplified": "无恶不作",
    "reading": "wúè-bùzuò",
    "meaning": "not to shrink from any crime (idiom), to commit any imaginable misdeed",
    "grade": 7
  },
  {
    "id": 9764,
    "traditional": "無非",
    "simplified": "无非",
    "reading": "wúfēi",
    "meaning": "nothing but, than, simply, only",
    "grade": 7
  },
  {
    "id": 9765,
    "traditional": "無辜",
    "simplified": "无辜",
    "reading": "wúgū",
    "meaning": "innocent, innocence, not guilty (law)",
    "grade": 7
  },
  {
    "id": 9766,
    "traditional": "無關緊要",
    "simplified": "无关紧要",
    "reading": "wúguān-jǐnyào",
    "meaning": "indifferent, insignificant",
    "grade": 7
  },
  {
    "id": 9767,
    "traditional": "無故",
    "simplified": "无故",
    "reading": "wúgù",
    "meaning": "without cause or reason",
    "grade": 7
  },
  {
    "id": 9768,
    "traditional": "無話可說",
    "simplified": "无话可说",
    "reading": "wúhuàkěshuō",
    "meaning": "to have nothing to say (idiom)",
    "grade": 7
  },
  {
    "id": 9769,
    "traditional": "無家可歸",
    "simplified": "无家可归",
    "reading": "wújiā-kěguī",
    "meaning": "homeless",
    "grade": 7
  },
  {
    "id": 9770,
    "traditional": "無濟於事",
    "simplified": "无济于事",
    "reading": "wújì-yúshì",
    "meaning": "to no avail, of no use",
    "grade": 7
  },
  {
    "id": 9771,
    "traditional": "無精打採",
    "simplified": "无精打采",
    "reading": "wújīng-dǎcǎi",
    "meaning": "dispirited and downcast (idiom), listless, in low spirits, washed out",
    "grade": 7
  },
  {
    "id": 9772,
    "traditional": "無可奉告",
    "simplified": "无可奉告",
    "reading": "wúkě-fènggào",
    "meaning": "(idiom) 'no comment'",
    "grade": 7
  },
  {
    "id": 9773,
    "traditional": "無可厚非",
    "simplified": "无可厚非",
    "reading": "wúkě-hòufēi",
    "meaning": "see 未可厚非[wei4 ke3 hou4 fei1]",
    "grade": 7
  },
  {
    "id": 9774,
    "traditional": "無理",
    "simplified": "无理",
    "reading": "wúlǐ",
    "meaning": "irrational, unreasonable",
    "grade": 7
  },
  {
    "id": 9775,
    "traditional": "無可奈何",
    "simplified": "无可奈何",
    "reading": "wúkě-nàihé",
    "meaning": "have no way out, have no alternative, abbr. to 無奈|无奈[wu2 nai4]",
    "grade": 7
  },
  {
    "id": 9776,
    "traditional": "無力",
    "simplified": "无力",
    "reading": "wúlì",
    "meaning": "powerless, feel weak, lack strength, incapable",
    "grade": 7
  },
  {
    "id": 9777,
    "traditional": "無論如何",
    "simplified": "无论如何",
    "reading": "wúlùnrúhé",
    "meaning": "disregarding, aught, in any event, anyway, in any case, ought, at any rate, no matter what happens, irrespective, no matter, however, leastwise, anyhow, anywise, irregardless, leastways, whatever may come, disregardless, no matter what, regardless, come hell or high water, in_any_case",
    "grade": 7
  },
  {
    "id": 9778,
    "traditional": "無能為力",
    "simplified": "无能为力",
    "reading": "wúnéngwéilì",
    "meaning": "impotent (idiom), powerless, helpless",
    "grade": 7
  },
  {
    "id": 9779,
    "traditional": "無情",
    "simplified": "无情",
    "reading": "wúqíng",
    "meaning": "merciless, harden, heartless, ruthless",
    "grade": 7
  },
  {
    "id": 9780,
    "traditional": "無情無義",
    "simplified": "无情无义",
    "reading": "wúqíngwúyì",
    "meaning": "completely lacking any feeling or sense of justice (idiom), cold and ruthless",
    "grade": 7
  },
  {
    "id": 9781,
    "traditional": "無窮",
    "simplified": "无穷",
    "reading": "wúqióng",
    "meaning": "infinite, endless, inexhaustible",
    "grade": 7
  },
  {
    "id": 9782,
    "traditional": "無私",
    "simplified": "无私",
    "reading": "wúsī",
    "meaning": "selfless, unselfish, disinterested, altruistic",
    "grade": 7
  },
  {
    "id": 9783,
    "traditional": "無所事事",
    "simplified": "无所事事",
    "reading": "wúsuǒshìshì",
    "meaning": "to have nothing to do, to idle one's time away (idiom)",
    "grade": 7
  },
  {
    "id": 9784,
    "traditional": "無所作為",
    "simplified": "无所作为",
    "reading": "wúsuǒzuòwéi",
    "meaning": "attempting nothing and accomplishing nothing (idiom), without any initiative or drive, feckless",
    "grade": 7
  },
  {
    "id": 9785,
    "traditional": "無條件",
    "simplified": "无条件",
    "reading": "wútiáojiàn",
    "meaning": "unconditional",
    "grade": 7
  },
  {
    "id": 9786,
    "traditional": "無微不至",
    "simplified": "无微不至",
    "reading": "wúwēi-bùzhì",
    "meaning": "in every possible way (idiom), meticulous",
    "grade": 7
  },
  {
    "id": 9787,
    "traditional": "無能",
    "simplified": "无能",
    "reading": "wúnéng",
    "meaning": "incompetent, incapable",
    "grade": 7
  },
  {
    "id": 9788,
    "traditional": "無線",
    "simplified": "无线",
    "reading": "wúxiàn",
    "meaning": "wireless",
    "grade": 7
  },
  {
    "id": 9789,
    "traditional": "無線電",
    "simplified": "无线电",
    "reading": "wúxiàndiàn",
    "meaning": "radio",
    "grade": 7
  },
  {
    "id": 9790,
    "traditional": "無形",
    "simplified": "无形",
    "reading": "wúxíng",
    "meaning": "invisible",
    "grade": 7
  },
  {
    "id": 9791,
    "traditional": "無形中",
    "simplified": "无形中",
    "reading": "wúxíngzhōng",
    "meaning": "imperceptibly, virtually",
    "grade": 7
  },
  {
    "id": 9792,
    "traditional": "無須",
    "simplified": "无须",
    "reading": "wúxū",
    "meaning": "need not, not obliged to, not necessarily",
    "grade": 7
  },
  {
    "id": 9793,
    "traditional": "無意",
    "simplified": "无意",
    "reading": "wúyì",
    "meaning": "have no intention, not be inclined to, have no intention to",
    "grade": 7
  },
  {
    "id": 9794,
    "traditional": "無憂無慮",
    "simplified": "无忧无虑",
    "reading": "wúyōu-wúlǜ",
    "meaning": "carefree and without worries (idiom)",
    "grade": 7
  },
  {
    "id": 9795,
    "traditional": "無緣",
    "simplified": "无缘",
    "reading": "wúyuán",
    "meaning": "to have no opportunity, no way (of doing sth), no chance, no connection, not placed (in a competition), (in pop lyrics) no chance of love, no place to be together etc",
    "grade": 7
  },
  {
    "id": 9796,
    "traditional": "無足輕重",
    "simplified": "无足轻重",
    "reading": "wúzúqīngzhòng",
    "meaning": "insignificant",
    "grade": 7
  },
  {
    "id": 9797,
    "traditional": "五花八門",
    "simplified": "五花八门",
    "reading": "wǔhuā-bāmén",
    "meaning": "myriad, all kinds of, all sorts of",
    "grade": 7
  },
  {
    "id": 9798,
    "traditional": "無知",
    "simplified": "无知",
    "reading": "wúzhī",
    "meaning": "ignorant, stupid",
    "grade": 7
  },
  {
    "id": 9799,
    "traditional": "五星級",
    "simplified": "五星级",
    "reading": "wǔxīngjí",
    "meaning": "five-star (hotel)",
    "grade": 7
  },
  {
    "id": 9800,
    "traditional": "武力",
    "simplified": "武力",
    "reading": "wǔlì",
    "meaning": "military unit, armory, armoury, armed force, saber, arsenal, military force, armed strength, armed might, force, bayonet, force of arms",
    "grade": 7
  },
  {
    "id": 9801,
    "traditional": "武裝",
    "simplified": "武装",
    "reading": "wǔzhuāng",
    "meaning": "arm",
    "grade": 7
  },
  {
    "id": 9802,
    "traditional": "捂",
    "simplified": "捂",
    "reading": "wǔ",
    "meaning": "to enclose, to cover with the hand (one's eyes, nose or ears), to cover up (an affair), contrary, to contradict",
    "grade": 7
  },
  {
    "id": 9803,
    "traditional": "侮辱",
    "simplified": "侮辱",
    "reading": "wǔrǔ",
    "meaning": "to insult, to humiliate, dishonor",
    "grade": 7
  },
  {
    "id": 9804,
    "traditional": "舞廳",
    "simplified": "舞厅",
    "reading": "wǔtīng",
    "meaning": "dance hall, ballroom, CL:間|间[jian1]",
    "grade": 7
  },
  {
    "id": 9805,
    "traditional": "務必",
    "simplified": "务必",
    "reading": "wùbì",
    "meaning": "by_any_means, always, must, should, be",
    "grade": 7
  },
  {
    "id": 9806,
    "traditional": "務實",
    "simplified": "务实",
    "reading": "wùshí",
    "meaning": "deal with concrete matters relating to work, try to be practical/pragmatic, pragmatic, try to be pragmatic, try to be practical, deal with concrete matters",
    "grade": 7
  },
  {
    "id": 9807,
    "traditional": "勿",
    "simplified": "勿",
    "reading": "wù",
    "meaning": "do not",
    "grade": 7
  },
  {
    "id": 9808,
    "traditional": "物證",
    "simplified": "物证",
    "reading": "wùzhèng",
    "meaning": "material evidence",
    "grade": 7
  },
  {
    "id": 9809,
    "traditional": "物流",
    "simplified": "物流",
    "reading": "wùliú",
    "meaning": "distribution (business), logistics",
    "grade": 7
  },
  {
    "id": 9810,
    "traditional": "物體",
    "simplified": "物体",
    "reading": "wùtǐ",
    "meaning": "object, body, thrust, substance, matter, physical object",
    "grade": 7
  },
  {
    "id": 9811,
    "traditional": "誤差",
    "simplified": "误差",
    "reading": "wùchā",
    "meaning": "difference, error, inaccuracy",
    "grade": 7
  },
  {
    "id": 9812,
    "traditional": "誤導",
    "simplified": "误导",
    "reading": "wùdǎo",
    "meaning": "to mislead, to misguide, misleading",
    "grade": 7
  },
  {
    "id": 9813,
    "traditional": "物資",
    "simplified": "物资",
    "reading": "wùzī",
    "meaning": "goods, supplies",
    "grade": 7
  },
  {
    "id": 9814,
    "traditional": "誤區",
    "simplified": "误区",
    "reading": "wùqū",
    "meaning": "mistaken ideas, misconceptions, the error of one's ways",
    "grade": 7
  },
  {
    "id": 9815,
    "traditional": "霧",
    "simplified": "雾",
    "reading": "wù",
    "meaning": "(soft) rime",
    "grade": 7
  },
  {
    "id": 9816,
    "traditional": "吸納",
    "simplified": "吸纳",
    "reading": "xīnà",
    "meaning": "to take in, to absorb, to admit, to accept",
    "grade": 7
  },
  {
    "id": 9817,
    "traditional": "吸取",
    "simplified": "吸取",
    "reading": "xīqǔ",
    "meaning": "to absorb, to draw (a lesson, insight etc), to assimilate",
    "grade": 7
  },
  {
    "id": 9818,
    "traditional": "息息相關",
    "simplified": "息息相关",
    "reading": "xīxī-xiāngguān",
    "meaning": "be closely linked, be closely bound up",
    "grade": 7
  },
  {
    "id": 9819,
    "traditional": "昔日",
    "simplified": "昔日",
    "reading": "xīrì",
    "meaning": "formerly, in olden days",
    "grade": 7
  },
  {
    "id": 9820,
    "traditional": "稀",
    "simplified": "稀",
    "reading": "xī",
    "meaning": "rare, uncommon, watery, sparse",
    "grade": 7
  },
  {
    "id": 9821,
    "traditional": "稀罕",
    "simplified": "稀罕",
    "reading": "xīhan",
    "meaning": "rare, uncommon, rarity, to value as a rarity, to cherish, Taiwan pr. [xi1 han3]",
    "grade": 7
  },
  {
    "id": 9822,
    "traditional": "稀少",
    "simplified": "稀少",
    "reading": "xīshǎo",
    "meaning": "sparse, rare",
    "grade": 7
  },
  {
    "id": 9823,
    "traditional": "稀奇",
    "simplified": "稀奇",
    "reading": "xīqí",
    "meaning": "rare, strange",
    "grade": 7
  },
  {
    "id": 9824,
    "traditional": "熙熙攘攘",
    "simplified": "熙熙攘攘",
    "reading": "xīxī-rǎngrǎng",
    "meaning": "bustling with activity (idiom)",
    "grade": 7
  },
  {
    "id": 9825,
    "traditional": "熄火",
    "simplified": "熄火",
    "reading": "xī huǒ",
    "meaning": "(of fire, lamp etc) to go out, to put out (fire), (fig.) to die down, (of a vehicle) to stall",
    "grade": 7
  },
  {
    "id": 9826,
    "traditional": "錫",
    "simplified": "锡",
    "reading": "xī",
    "meaning": "tin (chemistry), to bestow, to confer, to grant, Taiwan pr. [xi2]",
    "grade": 7
  },
  {
    "id": 9827,
    "traditional": "膝蓋",
    "simplified": "膝盖",
    "reading": "xīgài",
    "meaning": "knee",
    "grade": 7
  },
  {
    "id": 9828,
    "traditional": "嬉笑",
    "simplified": "嬉笑",
    "reading": "xīxiào",
    "meaning": "to be laughing and playing, to giggle",
    "grade": 7
  },
  {
    "id": 9829,
    "traditional": "習俗",
    "simplified": "习俗",
    "reading": "xísú",
    "meaning": "mores, custom, form, consuetudinary, institute, social custom, Sunna, convention, convenance, consuetude, conventionalism, ways, respectability, usage, manners, consuetudinal, conventionality",
    "grade": 7
  },
  {
    "id": 9830,
    "traditional": "席",
    "simplified": "席",
    "reading": "xí",
    "meaning": "m.[general]",
    "grade": 7
  },
  {
    "id": 9831,
    "traditional": "席位",
    "simplified": "席位",
    "reading": "xíwèi",
    "meaning": "a seat (in a theater, stadium etc), parliamentary or congressional seat",
    "grade": 7
  },
  {
    "id": 9832,
    "traditional": "襲擊",
    "simplified": "袭击",
    "reading": "xíjī",
    "meaning": "attack (esp. surprise attack), raid, to attack",
    "grade": 7
  },
  {
    "id": 9833,
    "traditional": "洗滌劑",
    "simplified": "洗涤剂",
    "reading": "xǐdíjì",
    "meaning": "cleaning agent, detergent",
    "grade": 7
  },
  {
    "id": 9834,
    "traditional": "媳婦",
    "simplified": "媳妇",
    "reading": "xífù",
    "meaning": "the wife of a relative of the younger generation's, wife of sb. of younger generation, daughter-in-law, son's wife",
    "grade": 7
  },
  {
    "id": 9835,
    "traditional": "喜出望外",
    "simplified": "喜出望外",
    "reading": "xǐchūwàngwài",
    "meaning": "to be pleased beyond one's expectations (idiom), overjoyed at the turn of events",
    "grade": 7
  },
  {
    "id": 9836,
    "traditional": "洗禮",
    "simplified": "洗礼",
    "reading": "xǐlǐ",
    "meaning": "baptism (lit. or fig.)",
    "grade": 7
  },
  {
    "id": 9837,
    "traditional": "喜好",
    "simplified": "喜好",
    "reading": "xǐhào",
    "meaning": "to like, fond of, to prefer, to love, one's tastes, preference",
    "grade": 7
  },
  {
    "id": 9838,
    "traditional": "喜怒哀樂",
    "simplified": "喜怒哀乐",
    "reading": "xǐ-nù-āi-lè",
    "meaning": "four types of human emotions, namely: happiness 歡喜|欢喜[huan1 xi3], anger 憤怒|愤怒[fen4 nu4], sorrow 悲哀[bei1 ai1] and joy 快樂|快乐[kuai4 le4]",
    "grade": 7
  },
  {
    "id": 9839,
    "traditional": "喜酒",
    "simplified": "喜酒",
    "reading": "xǐjiǔ",
    "meaning": "wedding feast, liquor drunk at a wedding feast",
    "grade": 7
  },
  {
    "id": 9840,
    "traditional": "喜事",
    "simplified": "喜事",
    "reading": "xǐshì",
    "meaning": "happy occasion, wedding",
    "grade": 7
  },
  {
    "id": 9841,
    "traditional": "喜慶",
    "simplified": "喜庆",
    "reading": "xǐqìng",
    "meaning": "jubilation, festive",
    "grade": 7
  },
  {
    "id": 9842,
    "traditional": "喜洋洋",
    "simplified": "喜洋洋",
    "reading": "xǐyángyáng",
    "meaning": "radiant with joy",
    "grade": 7
  },
  {
    "id": 9843,
    "traditional": "喜糖",
    "simplified": "喜糖",
    "reading": "xǐtáng",
    "meaning": "sweet given on a happy occasion (esp. wedding)",
    "grade": 7
  },
  {
    "id": 9844,
    "traditional": "喜悅",
    "simplified": "喜悦",
    "reading": "xǐyuè",
    "meaning": "happy, joyous",
    "grade": 7
  },
  {
    "id": 9845,
    "traditional": "細微",
    "simplified": "细微",
    "reading": "xìwēi",
    "meaning": "tiny, minute, fine, subtle, sensitive (instruments)",
    "grade": 7
  },
  {
    "id": 9846,
    "traditional": "細心",
    "simplified": "细心",
    "reading": "xìxīn",
    "meaning": "careful, attentive",
    "grade": 7
  },
  {
    "id": 9847,
    "traditional": "蝦",
    "simplified": "虾",
    "reading": "xiā",
    "meaning": "see 蝦蟆|虾蟆[ha2 ma5], shrimp, prawn",
    "grade": 7
  },
  {
    "id": 9848,
    "traditional": "細膩",
    "simplified": "细腻",
    "reading": "xìnì",
    "meaning": "fine and smooth, exquisite, minute",
    "grade": 7
  },
  {
    "id": 9849,
    "traditional": "俠義",
    "simplified": "侠义",
    "reading": "xiáyì",
    "meaning": "chivalrous, chivalry, knight-errantry",
    "grade": 7
  },
  {
    "id": 9850,
    "traditional": "瞎",
    "simplified": "瞎",
    "reading": "xiā",
    "meaning": "blind, groundlessly, foolishly, to no purpose",
    "grade": 7
  },
  {
    "id": 9851,
    "traditional": "峽谷",
    "simplified": "峡谷",
    "reading": "xiágǔ",
    "meaning": "canyon, gill, ravine",
    "grade": 7
  },
  {
    "id": 9852,
    "traditional": "狹隘",
    "simplified": "狭隘",
    "reading": "xiáài",
    "meaning": "narrow, tight, narrow minded, lacking in experience",
    "grade": 7
  },
  {
    "id": 9853,
    "traditional": "狹小",
    "simplified": "狭小",
    "reading": "xiáxiǎo",
    "meaning": "narrow and small, narrow",
    "grade": 7
  },
  {
    "id": 9854,
    "traditional": "狹窄",
    "simplified": "狭窄",
    "reading": "xiázhǎi",
    "meaning": "narrow, bottleneck, narrow and limited, cramped",
    "grade": 7
  },
  {
    "id": 9855,
    "traditional": "下跌",
    "simplified": "下跌",
    "reading": "xiàdiē",
    "meaning": "fall, decline, decrease",
    "grade": 7
  },
  {
    "id": 9856,
    "traditional": "下場",
    "simplified": "下场",
    "reading": "xiàchang",
    "meaning": "to leave (the stage, an exam room, the playing field etc), to take part in some activity, to take an examination (in the imperial examination system), the end, to conclude",
    "grade": 7
  },
  {
    "id": 9857,
    "traditional": "下崗",
    "simplified": "下岗",
    "reading": "xià gǎng",
    "meaning": "to come off sentry duty, to lay off (a worker), laid-off",
    "grade": 7
  },
  {
    "id": 9858,
    "traditional": "下功夫",
    "simplified": "下功夫",
    "reading": "xià gōngfu",
    "meaning": "see 下工夫[xia4 gong1 fu5]",
    "grade": 7
  },
  {
    "id": 9859,
    "traditional": "下海",
    "simplified": "下海",
    "reading": "xià hǎi",
    "meaning": "to go out to sea, to enter the sea (to swim etc), (fig.) to take the plunge (e.g. leave a secure job, or enter prostitution etc)",
    "grade": 7
  },
  {
    "id": 9860,
    "traditional": "下決心",
    "simplified": "下决心",
    "reading": "xià juéxīn",
    "meaning": "to determine, to resolve",
    "grade": 7
  },
  {
    "id": 9861,
    "traditional": "下令",
    "simplified": "下令",
    "reading": "xià lìng",
    "meaning": "give orders, order, give order, call_out, appoint, prescribe, call out, dictate",
    "grade": 7
  },
  {
    "id": 9862,
    "traditional": "下級",
    "simplified": "下级",
    "reading": "xiàjí",
    "meaning": "low ranking, low level, underclass, subordinate",
    "grade": 7
  },
  {
    "id": 9863,
    "traditional": "下落",
    "simplified": "下落",
    "reading": "xiàluò",
    "meaning": "whereabouts, to drop, to fall",
    "grade": 7
  },
  {
    "id": 9864,
    "traditional": "下棋",
    "simplified": "下棋",
    "reading": "xià qí",
    "meaning": "to play chess",
    "grade": 7
  },
  {
    "id": 9865,
    "traditional": "下山",
    "simplified": "下山",
    "reading": "xià shān",
    "meaning": "descend mountain, descend hill",
    "grade": 7
  },
  {
    "id": 9866,
    "traditional": "下手",
    "simplified": "下手",
    "reading": "xià shǒu",
    "meaning": "to start, to put one's hand to, to set about, the seat to the right of the main guest",
    "grade": 7
  },
  {
    "id": 9867,
    "traditional": "下期",
    "simplified": "下期",
    "reading": "xiàqī",
    "meaning": "next time",
    "grade": 7
  },
  {
    "id": 9868,
    "traditional": "下屬",
    "simplified": "下属",
    "reading": "xiàshǔ",
    "meaning": "subordinate, underling",
    "grade": 7
  },
  {
    "id": 9869,
    "traditional": "下臺",
    "simplified": "下台",
    "reading": "xià tái",
    "meaning": "fall out of power, leave office, step down from the stage/platform",
    "grade": 7
  },
  {
    "id": 9870,
    "traditional": "下調",
    "simplified": "下调",
    "reading": "xiàdiào",
    "meaning": "to demote, to pass down to a lower unit, to adjust downwards, to lower (prices, wages etc)",
    "grade": 7
  },
  {
    "id": 9871,
    "traditional": "下旬",
    "simplified": "下旬",
    "reading": "xiàxún",
    "meaning": "last third of the month",
    "grade": 7
  },
  {
    "id": 9872,
    "traditional": "下鄉",
    "simplified": "下乡",
    "reading": "xià xiāng",
    "meaning": "to go to the countryside",
    "grade": 7
  },
  {
    "id": 9873,
    "traditional": "下一代",
    "simplified": "下一代",
    "reading": "xiàyīdài",
    "meaning": "the next generation",
    "grade": 7
  },
  {
    "id": 9874,
    "traditional": "下游",
    "simplified": "下游",
    "reading": "xiàyóu",
    "meaning": "lower reaches (of river), lower level",
    "grade": 7
  },
  {
    "id": 9875,
    "traditional": "下意識",
    "simplified": "下意识",
    "reading": "xiàyìshi",
    "meaning": "subconscious mind",
    "grade": 7
  },
  {
    "id": 9876,
    "traditional": "下墜",
    "simplified": "下坠",
    "reading": "xiàzhuì",
    "meaning": "Fall",
    "grade": 7
  },
  {
    "id": 9877,
    "traditional": "嚇唬",
    "simplified": "吓唬",
    "reading": "xiàhu",
    "meaning": "to scare, to frighten",
    "grade": 7
  },
  {
    "id": 9878,
    "traditional": "嚇人",
    "simplified": "吓人",
    "reading": "xià rén",
    "meaning": "Scary",
    "grade": 7
  },
  {
    "id": 9879,
    "traditional": "夏令營",
    "simplified": "夏令营",
    "reading": "xiàlìngyíng",
    "meaning": "summer camp",
    "grade": 7
  },
  {
    "id": 9880,
    "traditional": "仙鶴",
    "simplified": "仙鹤",
    "reading": "xiānhè",
    "meaning": "red-crowned crane (Grus japonensis)",
    "grade": 7
  },
  {
    "id": 9881,
    "traditional": "仙女",
    "simplified": "仙女",
    "reading": "xiānnǚ",
    "meaning": "fairy",
    "grade": 7
  },
  {
    "id": 9882,
    "traditional": "先天",
    "simplified": "先天",
    "reading": "xiāntiān",
    "meaning": "a priori, congenital, natural, innately, innate, inborn",
    "grade": 7
  },
  {
    "id": 9883,
    "traditional": "先例",
    "simplified": "先例",
    "reading": "xiānlì",
    "meaning": "antecedent, precedent",
    "grade": 7
  },
  {
    "id": 9884,
    "traditional": "纖維",
    "simplified": "纤维",
    "reading": "xiānwéi",
    "meaning": "fiber, staple",
    "grade": 7
  },
  {
    "id": 9885,
    "traditional": "掀",
    "simplified": "掀",
    "reading": "xiān",
    "meaning": "to lift (a lid), to rock, to convulse",
    "grade": 7
  },
  {
    "id": 9886,
    "traditional": "掀起",
    "simplified": "掀起",
    "reading": "xiānqǐ",
    "meaning": "lift, raise, surge, cause to surge, start (movement/etc.)",
    "grade": 7
  },
  {
    "id": 9887,
    "traditional": "鮮活",
    "simplified": "鲜活",
    "reading": "xiānhuó",
    "meaning": "vivid, lively, (of food ingredients) live or fresh",
    "grade": 7
  },
  {
    "id": 9888,
    "traditional": "鮮美",
    "simplified": "鲜美",
    "reading": "xiānměi",
    "meaning": "delicious, tasty",
    "grade": 7
  },
  {
    "id": 9889,
    "traditional": "鮮血",
    "simplified": "鲜血",
    "reading": "xiānxuè",
    "meaning": "blood",
    "grade": 7
  },
  {
    "id": 9890,
    "traditional": "弦",
    "simplified": "弦",
    "reading": "xián",
    "meaning": "bow string, string of musical instrument, watchspring, chord (segment of curve), hypotenuse, CL:根[gen1]",
    "grade": 7
  },
  {
    "id": 9891,
    "traditional": "銜接",
    "simplified": "衔接",
    "reading": "xiánjiē",
    "meaning": "to join together, to combine",
    "grade": 7
  },
  {
    "id": 9892,
    "traditional": "嫌棄",
    "simplified": "嫌弃",
    "reading": "xiánqì",
    "meaning": "to avoid sb (out of dislike), to turn one's back on sb, to ignore",
    "grade": 7
  },
  {
    "id": 9893,
    "traditional": "顯而易見",
    "simplified": "显而易见",
    "reading": "xiǎnéryìjiàn",
    "meaning": "clearly and easy to see (idiom), obviously, clearly, it goes without saying",
    "grade": 7
  },
  {
    "id": 9894,
    "traditional": "嫌疑",
    "simplified": "嫌疑",
    "reading": "xiányí",
    "meaning": "cloud, suspicion",
    "grade": 7
  },
  {
    "id": 9895,
    "traditional": "顯赫",
    "simplified": "显赫",
    "reading": "xiǎnhè",
    "meaning": "illustrious, celebrated",
    "grade": 7
  },
  {
    "id": 9896,
    "traditional": "顯示器",
    "simplified": "显示器",
    "reading": "xiǎnshìqì",
    "meaning": "monitor, display, caller ID, indicator",
    "grade": 7
  },
  {
    "id": 9897,
    "traditional": "顯現",
    "simplified": "显现",
    "reading": "xiǎnxiàn",
    "meaning": "demonstrate, come on, show, uncover, show_up, express, come out, manifest oneself, show up, turn up, appear, reveal, reveal oneself, emerge, show_off, appearance, run, evince, surface, kithe, take_on, unveil, break, bring out",
    "grade": 7
  },
  {
    "id": 9898,
    "traditional": "現成",
    "simplified": "现成",
    "reading": "xiànchéng",
    "meaning": "ready-made, readily available",
    "grade": 7
  },
  {
    "id": 9899,
    "traditional": "顯眼",
    "simplified": "显眼",
    "reading": "xiǎnyǎn",
    "meaning": "conspicuous, eye-catching, glamorous",
    "grade": 7
  },
  {
    "id": 9900,
    "traditional": "現任",
    "simplified": "现任",
    "reading": "xiànrèn",
    "meaning": "be incumbent, be currently in office",
    "grade": 7
  },
  {
    "id": 9901,
    "traditional": "現行",
    "simplified": "现行",
    "reading": "xiànxíng",
    "meaning": "operation, in force/operation, currently in operation, in effect, force, currently in effect, in effect/force/operation, active, current",
    "grade": 7
  },
  {
    "id": 9902,
    "traditional": "限定",
    "simplified": "限定",
    "reading": "xiàndìng",
    "meaning": "prescribe limit to, demarcate, specify, qualify, restrict, restriction, set limit to, specialize, prescribe a limit to, prescribe, prescribe/set limit to, set a limit to, restrain, delimitate, define, terminate, ration, determine, limit, delineate, delimit",
    "grade": 7
  },
  {
    "id": 9903,
    "traditional": "限",
    "simplified": "限",
    "reading": "xiàn",
    "meaning": "set a limit, restrict",
    "grade": 7
  },
  {
    "id": 9904,
    "traditional": "限度",
    "simplified": "限度",
    "reading": "xiàndù",
    "meaning": "limitation, limit",
    "grade": 7
  },
  {
    "id": 9905,
    "traditional": "線條",
    "simplified": "线条",
    "reading": "xiàntiáo",
    "meaning": "line (in drawing, bar, etc.), printing, line (in drawing/printing/etc.), contour, lines, striation, stria, line, trace, streak, tracing",
    "grade": 7
  },
  {
    "id": 9906,
    "traditional": "憲法",
    "simplified": "宪法",
    "reading": "xiànfǎ",
    "meaning": "constitution, charter",
    "grade": 7
  },
  {
    "id": 9907,
    "traditional": "陷",
    "simplified": "陷",
    "reading": "xiàn",
    "meaning": "pitfall, trap, to get stuck, to sink, to cave in, to frame (false charge), to capture (a city in battle), to fall (to the enemy), defect",
    "grade": 7
  },
  {
    "id": 9908,
    "traditional": "限於",
    "simplified": "限于",
    "reading": "xiànyú",
    "meaning": "be confined, restrict, be limited to, be confined to, limited to",
    "grade": 7
  },
  {
    "id": 9909,
    "traditional": "陷阱",
    "simplified": "陷阱",
    "reading": "xiànjǐng",
    "meaning": "sweetener, pit, pitfall, hook, lure, trap, cobweb, shoal, trapan, booby_trap, noose, come-on, booby-trap, fall-trap, ambush, net, snare, quicksand, springe, decoy, trepan, mesh, bait, gin",
    "grade": 7
  },
  {
    "id": 9910,
    "traditional": "餡兒",
    "simplified": "馅儿",
    "reading": "xiànr5",
    "meaning": "stuffing, forcemeat, filling, erhua variant of 餡|馅[xian4]",
    "grade": 7
  },
  {
    "id": 9911,
    "traditional": "羨慕",
    "simplified": "羡慕",
    "reading": "xiànmù",
    "meaning": "to envy, to admire",
    "grade": 7
  },
  {
    "id": 9912,
    "traditional": "獻血",
    "simplified": "献血",
    "reading": "xiàn xiě",
    "meaning": "to donate blood",
    "grade": 7
  },
  {
    "id": 9913,
    "traditional": "腺",
    "simplified": "腺",
    "reading": "xiàn",
    "meaning": "gland",
    "grade": 7
  },
  {
    "id": 9914,
    "traditional": "鄉親",
    "simplified": "乡亲",
    "reading": "xiāngqīn",
    "meaning": "fellow countryman (from the same village), local people, villager, the folks back home",
    "grade": 7
  },
  {
    "id": 9915,
    "traditional": "鄉下",
    "simplified": "乡下",
    "reading": "xiāngxia",
    "meaning": "country, countryside, rural, village, province, country(side), mofussil, back_country",
    "grade": 7
  },
  {
    "id": 9916,
    "traditional": "相伴",
    "simplified": "相伴",
    "reading": "xiāngbàn",
    "meaning": "to accompany sb, to accompany each other",
    "grade": 7
  },
  {
    "id": 9917,
    "traditional": "相比之下",
    "simplified": "相比之下",
    "reading": "xiāngbǐzhīxià",
    "meaning": "by comparison",
    "grade": 7
  },
  {
    "id": 9918,
    "traditional": "相傳",
    "simplified": "相传",
    "reading": "xiāngchuán",
    "meaning": "tradition has it that..., according to legend, pass on from one to another, hand down from one to another, hand down or pass on from one to another",
    "grade": 7
  },
  {
    "id": 9919,
    "traditional": "相差",
    "simplified": "相差",
    "reading": "xiāngchà",
    "meaning": "differ",
    "grade": 7
  },
  {
    "id": 9920,
    "traditional": "相當於",
    "simplified": "相当于",
    "reading": "xiāngdāngyú",
    "meaning": "muster, be equal to, equivalent, amount",
    "grade": 7
  },
  {
    "id": 9921,
    "traditional": "相對而言",
    "simplified": "相对而言",
    "reading": "xiāngduì éryán",
    "meaning": "Relatively speaking",
    "grade": 7
  },
  {
    "id": 9922,
    "traditional": "相對",
    "simplified": "相对",
    "reading": "xiāngduì",
    "meaning": "be opposite, be face to face",
    "grade": 7
  },
  {
    "id": 9923,
    "traditional": "相輔相成",
    "simplified": "相辅相成",
    "reading": "xiāngfǔ-xiāngchéng",
    "meaning": "to complement one another (idiom)",
    "grade": 7
  },
  {
    "id": 9924,
    "traditional": "相連",
    "simplified": "相连",
    "reading": "xiānglián",
    "meaning": "communicate, be joined, interconnect, be linked together, intercommunicate, interlink, meet, attach",
    "grade": 7
  },
  {
    "id": 9925,
    "traditional": "相繼",
    "simplified": "相继",
    "reading": "xiāngjì",
    "meaning": "about, one_after_another, one after another, in succession",
    "grade": 7
  },
  {
    "id": 9926,
    "traditional": "相識",
    "simplified": "相识",
    "reading": "xiāngshí",
    "meaning": "to get to know each other, acquaintance",
    "grade": 7
  },
  {
    "id": 9927,
    "traditional": "相提並論",
    "simplified": "相提并论",
    "reading": "xiāngtí-bìnglùn",
    "meaning": "to discuss two disparate things together (idiom), to mention on equal terms, to place on a par with, (often with negatives: impossible to mention X in the same breath as Y)",
    "grade": 7
  },
  {
    "id": 9928,
    "traditional": "相依為命",
    "simplified": "相依为命",
    "reading": "xiāngyī-wéimìng",
    "meaning": "mutually dependent for life (idiom), to rely upon one another for survival, interdependent",
    "grade": 7
  },
  {
    "id": 9929,
    "traditional": "相通",
    "simplified": "相通",
    "reading": "xiāngtōng",
    "meaning": "interlinked, connected, communicating, in communication, accommodating",
    "grade": 7
  },
  {
    "id": 9930,
    "traditional": "相約",
    "simplified": "相约",
    "reading": "xiāngyuē",
    "meaning": "to agree (on a meeting place, date etc), to reach agreement, to make an appointment",
    "grade": 7
  },
  {
    "id": 9931,
    "traditional": "香料",
    "simplified": "香料",
    "reading": "xiāngliào",
    "meaning": "spice, flavoring, condiment, perfume",
    "grade": 7
  },
  {
    "id": 9932,
    "traditional": "香水",
    "simplified": "香水",
    "reading": "xiāngshuǐ",
    "meaning": "perfumery, cologne, aroma, perfume, fragrance, scent, rosewood, fragrancy",
    "grade": 7
  },
  {
    "id": 9933,
    "traditional": "相遇",
    "simplified": "相遇",
    "reading": "xiāngyù",
    "meaning": "to meet, to encounter, to come across",
    "grade": 7
  },
  {
    "id": 9934,
    "traditional": "香味",
    "simplified": "香味",
    "reading": "xiāngwèi",
    "meaning": "spicery, incense, redolence, odor, aroma, spiciness, sweetness, fragrance, flavor, bouquet, sapidness, flavour, sapidity, perfume, spice, smell, snuff, scent, fragrancy, balm, nose, zest",
    "grade": 7
  },
  {
    "id": 9935,
    "traditional": "香煙",
    "simplified": "香烟",
    "reading": "xiāngyān",
    "meaning": "cigarette, incense smoke",
    "grade": 7
  },
  {
    "id": 9936,
    "traditional": "香油",
    "simplified": "香油",
    "reading": "xiāngyóu",
    "meaning": "sesame oil, perfumed oil",
    "grade": 7
  },
  {
    "id": 9937,
    "traditional": "鑲",
    "simplified": "镶",
    "reading": "xiāng",
    "meaning": "to inlay, to embed, ridge, border",
    "grade": 7
  },
  {
    "id": 9938,
    "traditional": "鑲嵌",
    "simplified": "镶嵌",
    "reading": "xiāngqiàn",
    "meaning": "to inlay, to embed, to set (e.g. a jewel in a ring), tiling, tesselation",
    "grade": 7
  },
  {
    "id": 9939,
    "traditional": "享",
    "simplified": "享",
    "reading": "xiǎng",
    "meaning": "to enjoy, to benefit, to have the use of, old variant of 享[xiang3]",
    "grade": 7
  },
  {
    "id": 9940,
    "traditional": "詳盡",
    "simplified": "详尽",
    "reading": "xiángjìn",
    "meaning": "thorough and detailed, exhaustive, the tedious details in full",
    "grade": 7
  },
  {
    "id": 9941,
    "traditional": "享有",
    "simplified": "享有",
    "reading": "xiǎngyǒu",
    "meaning": "enjoy (right/etc.)",
    "grade": 7
  },
  {
    "id": 9942,
    "traditional": "響亮",
    "simplified": "响亮",
    "reading": "xiǎngliàng",
    "meaning": "loud and clear, resounding",
    "grade": 7
  },
  {
    "id": 9943,
    "traditional": "祥和",
    "simplified": "祥和",
    "reading": "xiánghé",
    "meaning": "auspicious and peaceful",
    "grade": 7
  },
  {
    "id": 9944,
    "traditional": "響應",
    "simplified": "响应",
    "reading": "xiǎngyìng",
    "meaning": "to respond to, answer, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 9945,
    "traditional": "響起",
    "simplified": "响起",
    "reading": "xiǎngqǐ",
    "meaning": "ring_out, ring out, speak, sound, start to ring",
    "grade": 7
  },
  {
    "id": 9946,
    "traditional": "想方設法",
    "simplified": "想方设法",
    "reading": "xiǎngfāng-shèfǎ",
    "meaning": "to think up every possible method (idiom), to devise ways and means, to try this, that and the other",
    "grade": 7
  },
  {
    "id": 9947,
    "traditional": "向來",
    "simplified": "向来",
    "reading": "xiànglái",
    "meaning": "typically, heretofore, always, all along, forever",
    "grade": 7
  },
  {
    "id": 9948,
    "traditional": "嚮往",
    "simplified": "向往",
    "reading": "xiàngwǎng",
    "meaning": "to yearn for, to look forward to",
    "grade": 7
  },
  {
    "id": 9949,
    "traditional": "項鏈",
    "simplified": "项链",
    "reading": "xiàngliàn",
    "meaning": "necklace, CL:條|条[tiao2]",
    "grade": 7
  },
  {
    "id": 9950,
    "traditional": "像",
    "simplified": "像",
    "reading": "xiàng",
    "meaning": "be like",
    "grade": 7
  },
  {
    "id": 9951,
    "traditional": "向著",
    "simplified": "向着",
    "reading": "xiàngzhe",
    "meaning": "Toward",
    "grade": 7
  },
  {
    "id": 9952,
    "traditional": "像樣",
    "simplified": "像样",
    "reading": "xiàngyàng",
    "meaning": "presentable, decent, up to par",
    "grade": 7
  },
  {
    "id": 9953,
    "traditional": "橡皮",
    "simplified": "橡皮",
    "reading": "xiàngpí",
    "meaning": "rubber, an eraser, CL:塊|块[kuai4]",
    "grade": 7
  },
  {
    "id": 9954,
    "traditional": "橡膠",
    "simplified": "橡胶",
    "reading": "xiàngjiāo",
    "meaning": "rubber, caoutchouc",
    "grade": 7
  },
  {
    "id": 9955,
    "traditional": "消",
    "simplified": "消",
    "reading": "xiāo",
    "meaning": "to disappear, to vanish, to eliminate, to spend (time), have to, need",
    "grade": 7
  },
  {
    "id": 9956,
    "traditional": "消沉",
    "simplified": "消沉",
    "reading": "xiāochén",
    "meaning": "depressed, bad mood, low spirit",
    "grade": 7
  },
  {
    "id": 9957,
    "traditional": "削",
    "simplified": "削",
    "reading": "xuē",
    "meaning": "to peel with a knife, to pare, to cut (a ball at tennis etc), to pare, to reduce, to remove, Taiwan pr. [xue4]",
    "grade": 7
  },
  {
    "id": 9958,
    "traditional": "消遣",
    "simplified": "消遣",
    "reading": "xiāoqiǎn",
    "meaning": "to while the time away, amusement, pastime, recreation, to make sport of",
    "grade": 7
  },
  {
    "id": 9959,
    "traditional": "蕭條",
    "simplified": "萧条",
    "reading": "xiāotiáo",
    "meaning": "bleak, desolate, (economic) depression or slump",
    "grade": 7
  },
  {
    "id": 9960,
    "traditional": "銷",
    "simplified": "销",
    "reading": "xiāo",
    "meaning": "to melt (metal), to cancel or annul, to sell, to spend, to fasten with a bolt, bolt or pin",
    "grade": 7
  },
  {
    "id": 9961,
    "traditional": "銷毀",
    "simplified": "销毁",
    "reading": "xiāohuǐ",
    "meaning": "to destroy (by melting or burning), to obliterate",
    "grade": 7
  },
  {
    "id": 9962,
    "traditional": "銷量",
    "simplified": "销量",
    "reading": "xiāoliàng",
    "meaning": "sales volume",
    "grade": 7
  },
  {
    "id": 9963,
    "traditional": "瀟灑",
    "simplified": "潇洒",
    "reading": "xiāosǎ",
    "meaning": "confident and at ease, free and easy",
    "grade": 7
  },
  {
    "id": 9964,
    "traditional": "小醜",
    "simplified": "小丑",
    "reading": "xiǎochǒu",
    "meaning": "clown",
    "grade": 7
  },
  {
    "id": 9965,
    "traditional": "小販",
    "simplified": "小贩",
    "reading": "xiǎofàn",
    "meaning": "peddler, hawker",
    "grade": 7
  },
  {
    "id": 9966,
    "traditional": "小看",
    "simplified": "小看",
    "reading": "xiǎokàn",
    "meaning": "to look down on, to underestimate",
    "grade": 7
  },
  {
    "id": 9967,
    "traditional": "小康",
    "simplified": "小康",
    "reading": "xiǎokāng",
    "meaning": "Xiaokang, a Confucian near-ideal state of society, second only to Datong 大同[Da4 tong2], moderately affluent, well-off, a period of peace and prosperity",
    "grade": 7
  },
  {
    "id": 9968,
    "traditional": "小路",
    "simplified": "小路",
    "reading": "xiǎolù",
    "meaning": "minor road, lane, pathway, trail",
    "grade": 7
  },
  {
    "id": 9969,
    "traditional": "小品",
    "simplified": "小品",
    "reading": "xiǎopǐn",
    "meaning": "short, simple literary or artistic creation, essay, skit",
    "grade": 7
  },
  {
    "id": 9970,
    "traditional": "小氣",
    "simplified": "小气",
    "reading": "xiǎoqi",
    "meaning": "stingy, petty, miserly, narrow-minded",
    "grade": 7
  },
  {
    "id": 9971,
    "traditional": "小區",
    "simplified": "小区",
    "reading": "xiǎoqū",
    "meaning": "neighborhood, district",
    "grade": 7
  },
  {
    "id": 9972,
    "traditional": "小曲",
    "simplified": "小曲",
    "reading": "xiǎoqǔ",
    "meaning": "popular song, folk tune, ballad",
    "grade": 7
  },
  {
    "id": 9973,
    "traditional": "小人",
    "simplified": "小人",
    "reading": "xiǎorén",
    "meaning": "person of low social status (old), I, me (used to refer humbly to oneself), nasty person, vile character",
    "grade": 7
  },
  {
    "id": 9974,
    "traditional": "小溪",
    "simplified": "小溪",
    "reading": "xiǎoxī",
    "meaning": "brook, streamlet",
    "grade": 7
  },
  {
    "id": 9975,
    "traditional": "小提琴",
    "simplified": "小提琴",
    "reading": "xiǎotíqín",
    "meaning": "violin",
    "grade": 7
  },
  {
    "id": 9976,
    "traditional": "小心翼翼",
    "simplified": "小心翼翼",
    "reading": "xiǎoxīn-yìyì",
    "meaning": "with great care, cautiously",
    "grade": 7
  },
  {
    "id": 9977,
    "traditional": "孝敬",
    "simplified": "孝敬",
    "reading": "xiàojìng",
    "meaning": "to show filial respect, to give presents (to one's elders or superiors), to support one's aged parents",
    "grade": 7
  },
  {
    "id": 9978,
    "traditional": "小卒",
    "simplified": "小卒",
    "reading": "xiǎozú",
    "meaning": "foot soldier, minor figure, a nobody, (chess) pawn",
    "grade": 7
  },
  {
    "id": 9979,
    "traditional": "孝順",
    "simplified": "孝顺",
    "reading": "xiàoshun",
    "meaning": "filial, show filial obedience",
    "grade": 7
  },
  {
    "id": 9980,
    "traditional": "效仿",
    "simplified": "效仿",
    "reading": "xiàofǎng",
    "meaning": "see 仿傚|仿效[fang3 xiao4]",
    "grade": 7
  },
  {
    "id": 9981,
    "traditional": "肖像",
    "simplified": "肖像",
    "reading": "xiàoxiàng",
    "meaning": "portrait (painting, photo etc), (in a general sense) representation of a person, likeness",
    "grade": 7
  },
  {
    "id": 9982,
    "traditional": "效益",
    "simplified": "效益",
    "reading": "xiàoyì",
    "meaning": "beneficial result, benefit",
    "grade": 7
  },
  {
    "id": 9983,
    "traditional": "效應",
    "simplified": "效应",
    "reading": "xiàoyìng",
    "meaning": "effect",
    "grade": 7
  },
  {
    "id": 9984,
    "traditional": "協定",
    "simplified": "协定",
    "reading": "xiédìng",
    "meaning": "agreement, accord, pact",
    "grade": 7
  },
  {
    "id": 9985,
    "traditional": "效力",
    "simplified": "效力",
    "reading": "xiàolì",
    "meaning": "effect",
    "grade": 7
  },
  {
    "id": 9986,
    "traditional": "協同",
    "simplified": "协同",
    "reading": "xiétóng",
    "meaning": "to cooperate, in coordination with, coordinated, collaborate, collaboration, collaborative",
    "grade": 7
  },
  {
    "id": 9987,
    "traditional": "邪",
    "simplified": "邪",
    "reading": "xié",
    "meaning": "demonic, iniquitous, nefarious, evil, unhealthy influences that cause disease (Chinese medicine)",
    "grade": 7
  },
  {
    "id": 9988,
    "traditional": "協作",
    "simplified": "协作",
    "reading": "xiézuò",
    "meaning": "cooperation, coordination",
    "grade": 7
  },
  {
    "id": 9989,
    "traditional": "邪惡",
    "simplified": "邪恶",
    "reading": "xiéè",
    "meaning": "sinister, vicious, wicked, evil",
    "grade": 7
  },
  {
    "id": 9990,
    "traditional": "挾持",
    "simplified": "挟持",
    "reading": "xiéchí",
    "meaning": "to seize",
    "grade": 7
  },
  {
    "id": 9991,
    "traditional": "攜帶",
    "simplified": "携带",
    "reading": "xiédài",
    "meaning": "bring, schlep, schlepp, take along, carry",
    "grade": 7
  },
  {
    "id": 9992,
    "traditional": "攜手",
    "simplified": "携手",
    "reading": "xié shǒu",
    "meaning": "hand in hand, to join hands, to collaborate",
    "grade": 7
  },
  {
    "id": 9993,
    "traditional": "寫照",
    "simplified": "写照",
    "reading": "xiězhào",
    "meaning": "portrayal",
    "grade": 7
  },
  {
    "id": 9994,
    "traditional": "泄",
    "simplified": "泄",
    "reading": "xiè",
    "meaning": "to leak (of water or gas), to drip, to drain, to discharge, to leak out, to divulge (secrets), to give vent (to anger, spite etc), to disperse, to reduce, variant of 泄[xie4]",
    "grade": 7
  },
  {
    "id": 9995,
    "traditional": "泄露",
    "simplified": "泄露",
    "reading": "xièlù",
    "meaning": "to leak (information), to divulge, also pr. [xie4 lou4]",
    "grade": 7
  },
  {
    "id": 9996,
    "traditional": "泄密",
    "simplified": "泄密",
    "reading": "xiè mì",
    "meaning": "to leak secrets",
    "grade": 7
  },
  {
    "id": 9997,
    "traditional": "泄氣",
    "simplified": "泄气",
    "reading": "xièqì",
    "meaning": "to leak (gas), to be discouraged, to despair, (disparaging) pathetic, to vent one's anger, (of a tire) to be flat",
    "grade": 7
  },
  {
    "id": 9998,
    "traditional": "泄漏",
    "simplified": "泄漏",
    "reading": "xièlòu",
    "meaning": "(of a liquid or gas) to leak, to divulge (a secret), to leak",
    "grade": 7
  },
  {
    "id": 9999,
    "traditional": "瀉",
    "simplified": "泻",
    "reading": "xiè",
    "meaning": "to flow out swiftly, to flood, a torrent, diarrhea, laxative",
    "grade": 7
  },
  {
    "id": 10000,
    "traditional": "心愛",
    "simplified": "心爱",
    "reading": "xīnài",
    "meaning": "love, treasure",
    "grade": 7
  },
  {
    "id": 10001,
    "traditional": "心安理得",
    "simplified": "心安理得",
    "reading": "xīnān-lǐdé",
    "meaning": "to have a clear conscience, to have no qualms about sth",
    "grade": 7
  },
  {
    "id": 10002,
    "traditional": "心病",
    "simplified": "心病",
    "reading": "xīnbìng",
    "meaning": "anxiety, sore point, secret worry, mental disorder, heart disease (medicine)",
    "grade": 7
  },
  {
    "id": 10003,
    "traditional": "心腸",
    "simplified": "心肠",
    "reading": "xīncháng",
    "meaning": "heart, intention, one's inclination, state of mind, to have the heart for sth, mood",
    "grade": 7
  },
  {
    "id": 10004,
    "traditional": "卸",
    "simplified": "卸",
    "reading": "xiè",
    "meaning": "to unload, to unhitch, to remove or strip, to get rid of",
    "grade": 7
  },
  {
    "id": 10005,
    "traditional": "心得",
    "simplified": "心得",
    "reading": "xīndé",
    "meaning": "what one has learned from work/study/etc., study, what one has learned from work, insight, acquaintanceship, what one has learned from study/etc., etc., what one has learned from work, study, etc.",
    "grade": 7
  },
  {
    "id": 10006,
    "traditional": "心急如焚",
    "simplified": "心急如焚",
    "reading": "xīnjí-rúfén",
    "meaning": "Anxious",
    "grade": 7
  },
  {
    "id": 10007,
    "traditional": "心慌",
    "simplified": "心慌",
    "reading": "xīn huāng",
    "meaning": "to be flustered, (dialect) irregular heart-beat",
    "grade": 7
  },
  {
    "id": 10008,
    "traditional": "心裡話",
    "simplified": "心里话",
    "reading": "xīnlihuà",
    "meaning": "(to express one's) true feelings, what is on one's mind, secret mind",
    "grade": 7
  },
  {
    "id": 10009,
    "traditional": "心靈手巧",
    "simplified": "心灵手巧",
    "reading": "xīnlíngshǒuqiǎo",
    "meaning": "capable, clever, dexterous",
    "grade": 7
  },
  {
    "id": 10010,
    "traditional": "心目",
    "simplified": "心目",
    "reading": "xīnmù",
    "meaning": "mind, view",
    "grade": 7
  },
  {
    "id": 10011,
    "traditional": "心聲",
    "simplified": "心声",
    "reading": "xīnshēng",
    "meaning": "heartfelt wish, aspiration, thinking",
    "grade": 7
  },
  {
    "id": 10012,
    "traditional": "心思",
    "simplified": "心思",
    "reading": "xīnsi",
    "meaning": "thought, idea, thinking, state of mind, mood",
    "grade": 7
  },
  {
    "id": 10013,
    "traditional": "心事",
    "simplified": "心事",
    "reading": "xīnshi",
    "meaning": "weight on one's mind, worry",
    "grade": 7
  },
  {
    "id": 10014,
    "traditional": "心酸",
    "simplified": "心酸",
    "reading": "xīn suān",
    "meaning": "to feel sad",
    "grade": 7
  },
  {
    "id": 10015,
    "traditional": "心胸",
    "simplified": "心胸",
    "reading": "xīnxiōng",
    "meaning": "breadth of mind",
    "grade": 7
  },
  {
    "id": 10016,
    "traditional": "心想事成",
    "simplified": "心想事成",
    "reading": "xīnxiǎng-shìchéng",
    "meaning": "to have one's wishes come true, wish you the best!",
    "grade": 7
  },
  {
    "id": 10017,
    "traditional": "心眼兒",
    "simplified": "心眼儿",
    "reading": "xīnyǎnr5",
    "meaning": "one's thoughts, mind, intention, willingness to accept new ideas, baseless suspicions",
    "grade": 7
  },
  {
    "id": 10018,
    "traditional": "心血",
    "simplified": "心血",
    "reading": "xīnxuè",
    "meaning": "painstaking care/effort",
    "grade": 7
  },
  {
    "id": 10019,
    "traditional": "心意",
    "simplified": "心意",
    "reading": "xīnyì",
    "meaning": "regard, kindly feeling, intention, purpose",
    "grade": 7
  },
  {
    "id": 10020,
    "traditional": "芯片",
    "simplified": "芯片",
    "reading": "xīnpiàn",
    "meaning": "computer chip, microchip",
    "grade": 7
  },
  {
    "id": 10021,
    "traditional": "辛酸",
    "simplified": "辛酸",
    "reading": "xīnsuān",
    "meaning": "pungent (taste), bitter, fig. sad, miserable",
    "grade": 7
  },
  {
    "id": 10022,
    "traditional": "欣慰",
    "simplified": "欣慰",
    "reading": "xīnwèi",
    "meaning": "delighted, be gratified",
    "grade": 7
  },
  {
    "id": 10023,
    "traditional": "辛勤",
    "simplified": "辛勤",
    "reading": "xīnqín",
    "meaning": "hardworking, industrious",
    "grade": 7
  },
  {
    "id": 10024,
    "traditional": "欣欣向榮",
    "simplified": "欣欣向荣",
    "reading": "xīnxīn-xiàngróng",
    "meaning": "luxuriant growth (idiom), flourishing, thriving",
    "grade": 7
  },
  {
    "id": 10025,
    "traditional": "新潮",
    "simplified": "新潮",
    "reading": "xīncháo",
    "meaning": "modern, fashionable",
    "grade": 7
  },
  {
    "id": 10026,
    "traditional": "欣喜",
    "simplified": "欣喜",
    "reading": "xīnxǐ",
    "meaning": "happy",
    "grade": 7
  },
  {
    "id": 10027,
    "traditional": "新陳代謝",
    "simplified": "新陈代谢",
    "reading": "xīnchén-dàixiè",
    "meaning": "metabolism (biology), the new replaces the old (idiom)",
    "grade": 7
  },
  {
    "id": 10028,
    "traditional": "新款",
    "simplified": "新款",
    "reading": "xīnkuǎn",
    "meaning": "new style, latest fashion, new model",
    "grade": 7
  },
  {
    "id": 10029,
    "traditional": "新房",
    "simplified": "新房",
    "reading": "xīnfáng",
    "meaning": "brand new house, bridal chamber",
    "grade": 7
  },
  {
    "id": 10030,
    "traditional": "新奇",
    "simplified": "新奇",
    "reading": "xīnqí",
    "meaning": "novelty, new and odd",
    "grade": 7
  },
  {
    "id": 10031,
    "traditional": "新生",
    "simplified": "新生",
    "reading": "xīnshēng",
    "meaning": "new student",
    "grade": 7
  },
  {
    "id": 10032,
    "traditional": "新手",
    "simplified": "新手",
    "reading": "xīnshǒu",
    "meaning": "new hand, novice, raw recruit",
    "grade": 7
  },
  {
    "id": 10033,
    "traditional": "新穎",
    "simplified": "新颖",
    "reading": "xīnyǐng",
    "meaning": "new and original, novel",
    "grade": 7
  },
  {
    "id": 10034,
    "traditional": "新式",
    "simplified": "新式",
    "reading": "xīnshì",
    "meaning": "new style, latest type",
    "grade": 7
  },
  {
    "id": 10035,
    "traditional": "信貸",
    "simplified": "信贷",
    "reading": "xìndài",
    "meaning": "credit, borrowed money",
    "grade": 7
  },
  {
    "id": 10036,
    "traditional": "信件",
    "simplified": "信件",
    "reading": "xìnjiàn",
    "meaning": "letter (sent by mail)",
    "grade": 7
  },
  {
    "id": 10037,
    "traditional": "信譽",
    "simplified": "信誉",
    "reading": "xìnyù",
    "meaning": "prestige, distinction, reputation, trust",
    "grade": 7
  },
  {
    "id": 10038,
    "traditional": "信賴",
    "simplified": "信赖",
    "reading": "xìnlài",
    "meaning": "trust, count on, have faith in",
    "grade": 7
  },
  {
    "id": 10039,
    "traditional": "興建",
    "simplified": "兴建",
    "reading": "xīngjiàn",
    "meaning": "build, construct",
    "grade": 7
  },
  {
    "id": 10040,
    "traditional": "興奮劑",
    "simplified": "兴奋剂",
    "reading": "xīngfènjì",
    "meaning": "stimulant, doping (in athletics)",
    "grade": 7
  },
  {
    "id": 10041,
    "traditional": "興起",
    "simplified": "兴起",
    "reading": "xīngqǐ",
    "meaning": "rise, spring up, be on the upgrade",
    "grade": 7
  },
  {
    "id": 10042,
    "traditional": "猩猩",
    "simplified": "猩猩",
    "reading": "xīngxing",
    "meaning": "orangutan",
    "grade": 7
  },
  {
    "id": 10043,
    "traditional": "星座",
    "simplified": "星座",
    "reading": "xīngzuò",
    "meaning": "constellation, astrological sign, CL:張|张[zhang1]",
    "grade": 7
  },
  {
    "id": 10044,
    "traditional": "腥",
    "simplified": "腥",
    "reading": "xīng",
    "meaning": "fishy (smell)",
    "grade": 7
  },
  {
    "id": 10045,
    "traditional": "刑法",
    "simplified": "刑法",
    "reading": "xíngfa",
    "meaning": "criminal law",
    "grade": 7
  },
  {
    "id": 10046,
    "traditional": "行使",
    "simplified": "行使",
    "reading": "xíngshǐ",
    "meaning": "exercise, perform",
    "grade": 7
  },
  {
    "id": 10047,
    "traditional": "行政",
    "simplified": "行政",
    "reading": "xíngzhèng",
    "meaning": "administration",
    "grade": 7
  },
  {
    "id": 10048,
    "traditional": "行走",
    "simplified": "行走",
    "reading": "xíngzǒu",
    "meaning": "mooch, tread, go, make, navigate, go on foot, walk",
    "grade": 7
  },
  {
    "id": 10049,
    "traditional": "形形色色",
    "simplified": "形形色色",
    "reading": "xíngxíng-sèsè",
    "meaning": "all kinds of, all sorts of, every (different) kind of",
    "grade": 7
  },
  {
    "id": 10050,
    "traditional": "形影不離",
    "simplified": "形影不离",
    "reading": "xíngyǐng-bùlí",
    "meaning": "inseparable (as form and shadow)",
    "grade": 7
  },
  {
    "id": 10051,
    "traditional": "醒來",
    "simplified": "醒来",
    "reading": "xǐnglái",
    "meaning": "waken, awaken, rouse, wake up, wake",
    "grade": 7
  },
  {
    "id": 10052,
    "traditional": "醒悟",
    "simplified": "醒悟",
    "reading": "xǐngwù",
    "meaning": "to come to oneself, to come to realize, to come to see the truth, to wake up to reality",
    "grade": 7
  },
  {
    "id": 10053,
    "traditional": "興高采烈",
    "simplified": "兴高采烈",
    "reading": "xìnggāo-cǎiliè",
    "meaning": "happy and excited (idiom), in high spirits, in great delight",
    "grade": 7
  },
  {
    "id": 10054,
    "traditional": "醒目",
    "simplified": "醒目",
    "reading": "xǐngmù",
    "meaning": "eye-grabbing (headline), striking (illustration)",
    "grade": 7
  },
  {
    "id": 10055,
    "traditional": "興致",
    "simplified": "兴致",
    "reading": "xìngzhì",
    "meaning": "mood, spirits, interest",
    "grade": 7
  },
  {
    "id": 10056,
    "traditional": "幸存",
    "simplified": "幸存",
    "reading": "xìngcún",
    "meaning": "survivor (of a disaster)",
    "grade": 7
  },
  {
    "id": 10057,
    "traditional": "幸好",
    "simplified": "幸好",
    "reading": "xìnghǎo",
    "meaning": "fortunately, luckily",
    "grade": 7
  },
  {
    "id": 10058,
    "traditional": "幸虧",
    "simplified": "幸亏",
    "reading": "xìngkuī",
    "meaning": "fortunately, luckily",
    "grade": 7
  },
  {
    "id": 10059,
    "traditional": "幸免",
    "simplified": "幸免",
    "reading": "xìngmiǎn",
    "meaning": "narrowly and luckily escape",
    "grade": 7
  },
  {
    "id": 10060,
    "traditional": "性價比",
    "simplified": "性价比",
    "reading": "xìngjiàbǐ",
    "meaning": "quality-price ratio",
    "grade": 7
  },
  {
    "id": 10061,
    "traditional": "性情",
    "simplified": "性情",
    "reading": "xìngqíng",
    "meaning": "constitution, fibre, aptness, acerbity, fiber, affection, temper, disposition, individuality, blood, streak, temperament",
    "grade": 7
  },
  {
    "id": 10062,
    "traditional": "性命",
    "simplified": "性命",
    "reading": "xìngmìng",
    "meaning": "life",
    "grade": 7
  },
  {
    "id": 10063,
    "traditional": "姓氏",
    "simplified": "姓氏",
    "reading": "xìngshì",
    "meaning": "family name",
    "grade": 7
  },
  {
    "id": 10064,
    "traditional": "凶殘",
    "simplified": "凶残",
    "reading": "xiōngcán",
    "meaning": "savage",
    "grade": 7
  },
  {
    "id": 10065,
    "traditional": "凶惡",
    "simplified": "凶恶",
    "reading": "xiōngè",
    "meaning": "variant of 兇惡|凶恶, fierce, ferocious, fiendish, frightening",
    "grade": 7
  },
  {
    "id": 10066,
    "traditional": "凶猛",
    "simplified": "凶猛",
    "reading": "xiōngměng",
    "meaning": "fierce, violent, ferocious",
    "grade": 7
  },
  {
    "id": 10067,
    "traditional": "洶涌",
    "simplified": "汹涌",
    "reading": "xiōngyǒng",
    "meaning": "to surge up violently (of ocean, river, lake etc), turbulent",
    "grade": 7
  },
  {
    "id": 10068,
    "traditional": "胸膛",
    "simplified": "胸膛",
    "reading": "xiōngtáng",
    "meaning": "chest",
    "grade": 7
  },
  {
    "id": 10069,
    "traditional": "凶狠",
    "simplified": "凶狠",
    "reading": "xiōnghěn",
    "meaning": "cruel, vicious, fierce and malicious, vengeful",
    "grade": 7
  },
  {
    "id": 10070,
    "traditional": "胸有成竹",
    "simplified": "胸有成竹",
    "reading": "xiōngyǒuchéngzhú",
    "meaning": "to plan in advance (idiom), a card up one's sleeve, forewarned is forearmed",
    "grade": 7
  },
  {
    "id": 10071,
    "traditional": "雄厚",
    "simplified": "雄厚",
    "reading": "xiónghòu",
    "meaning": "robust, strong and solid",
    "grade": 7
  },
  {
    "id": 10072,
    "traditional": "休克",
    "simplified": "休克",
    "reading": "xiūkè",
    "meaning": "shock (loanword), to go into shock",
    "grade": 7
  },
  {
    "id": 10073,
    "traditional": "休眠",
    "simplified": "休眠",
    "reading": "xiūmián",
    "meaning": "to be dormant (biology), inactive (volcano), to hibernate (computing)",
    "grade": 7
  },
  {
    "id": 10074,
    "traditional": "休想",
    "simplified": "休想",
    "reading": "xiūxiǎng",
    "meaning": "don't think (that), don't imagine (that)",
    "grade": 7
  },
  {
    "id": 10075,
    "traditional": "休養",
    "simplified": "休养",
    "reading": "xiūyǎng",
    "meaning": "to recuperate, to recover, to convalesce",
    "grade": 7
  },
  {
    "id": 10076,
    "traditional": "修補",
    "simplified": "修补",
    "reading": "xiūbǔ",
    "meaning": "to mend",
    "grade": 7
  },
  {
    "id": 10077,
    "traditional": "修長",
    "simplified": "修长",
    "reading": "xiūcháng",
    "meaning": "slender, lanky, tall and thin",
    "grade": 7
  },
  {
    "id": 10078,
    "traditional": "修正",
    "simplified": "修正",
    "reading": "xiūzhèng",
    "meaning": "revise, amend, correct",
    "grade": 7
  },
  {
    "id": 10079,
    "traditional": "羞愧",
    "simplified": "羞愧",
    "reading": "xiūkuì",
    "meaning": "ashamed",
    "grade": 7
  },
  {
    "id": 10080,
    "traditional": "修訂",
    "simplified": "修订",
    "reading": "xiūdìng",
    "meaning": "revise",
    "grade": 7
  },
  {
    "id": 10081,
    "traditional": "秀麗",
    "simplified": "秀丽",
    "reading": "xiùlì",
    "meaning": "pretty, beautiful",
    "grade": 7
  },
  {
    "id": 10082,
    "traditional": "修路",
    "simplified": "修路",
    "reading": "xiūlù",
    "meaning": "to repair a road",
    "grade": 7
  },
  {
    "id": 10083,
    "traditional": "秀美",
    "simplified": "秀美",
    "reading": "xiùměi",
    "meaning": "elegant, graceful",
    "grade": 7
  },
  {
    "id": 10084,
    "traditional": "袖手旁觀",
    "simplified": "袖手旁观",
    "reading": "xiùshǒu-pángguān",
    "meaning": "to watch with folded arms (idiom), to look on without lifting a finger",
    "grade": 7
  },
  {
    "id": 10085,
    "traditional": "鏽",
    "simplified": "锈",
    "reading": "xiù",
    "meaning": "to corrode, to rust",
    "grade": 7
  },
  {
    "id": 10086,
    "traditional": "嗅覺",
    "simplified": "嗅觉",
    "reading": "xiùjué",
    "meaning": "sense of smell",
    "grade": 7
  },
  {
    "id": 10087,
    "traditional": "繡",
    "simplified": "绣",
    "reading": "xiù",
    "meaning": "to embroider, embroidery",
    "grade": 7
  },
  {
    "id": 10088,
    "traditional": "須",
    "simplified": "须",
    "reading": "xū",
    "meaning": "must, have to",
    "grade": 7
  },
  {
    "id": 10089,
    "traditional": "虛",
    "simplified": "虚",
    "reading": "xū",
    "meaning": "emptiness, void, abstract theory or guiding principles, empty or unoccupied, diffident or timid, false, humble or modest, (of health) weak, virtual, in vain",
    "grade": 7
  },
  {
    "id": 10090,
    "traditional": "虛構",
    "simplified": "虚构",
    "reading": "xūgòu",
    "meaning": "to make up, fabrication, fictional, imaginary",
    "grade": 7
  },
  {
    "id": 10091,
    "traditional": "虛幻",
    "simplified": "虚幻",
    "reading": "xūhuàn",
    "meaning": "imaginary, illusory",
    "grade": 7
  },
  {
    "id": 10092,
    "traditional": "虛擬",
    "simplified": "虚拟",
    "reading": "xūnǐ",
    "meaning": "conjecture, fictitious, pseudo, suppositional, fantasy",
    "grade": 7
  },
  {
    "id": 10093,
    "traditional": "虛假",
    "simplified": "虚假",
    "reading": "xūjiǎ",
    "meaning": "false, phony, pretense",
    "grade": 7
  },
  {
    "id": 10094,
    "traditional": "虛弱",
    "simplified": "虚弱",
    "reading": "xūruò",
    "meaning": "weak, in poor health",
    "grade": 7
  },
  {
    "id": 10095,
    "traditional": "虛偽",
    "simplified": "虚伪",
    "reading": "xūwěi",
    "meaning": "false, hypocritical, artificial, sham",
    "grade": 7
  },
  {
    "id": 10096,
    "traditional": "徐徐",
    "simplified": "徐徐",
    "reading": "xúxú",
    "meaning": "slowly, gently",
    "grade": 7
  },
  {
    "id": 10097,
    "traditional": "許",
    "simplified": "许",
    "reading": "xǔ",
    "meaning": "det.: or so",
    "grade": 7
  },
  {
    "id": 10098,
    "traditional": "需",
    "simplified": "需",
    "reading": "xū",
    "meaning": "need",
    "grade": 7
  },
  {
    "id": 10099,
    "traditional": "許可證",
    "simplified": "许可证",
    "reading": "xǔkězhèng",
    "meaning": "license, authorization, permit",
    "grade": 7
  },
  {
    "id": 10100,
    "traditional": "旭日",
    "simplified": "旭日",
    "reading": "xùrì",
    "meaning": "the rising sun",
    "grade": 7
  },
  {
    "id": 10101,
    "traditional": "序",
    "simplified": "序",
    "reading": "xù",
    "meaning": "order, sequence, preface",
    "grade": 7
  },
  {
    "id": 10102,
    "traditional": "序幕",
    "simplified": "序幕",
    "reading": "xùmù",
    "meaning": "prologue",
    "grade": 7
  },
  {
    "id": 10103,
    "traditional": "敘述",
    "simplified": "叙述",
    "reading": "xùshù",
    "meaning": "narrate, recount, relate",
    "grade": 7
  },
  {
    "id": 10104,
    "traditional": "酗酒",
    "simplified": "酗酒",
    "reading": "xùjiǔ",
    "meaning": "heavy drinking, to get drunk, to drink to excess",
    "grade": 7
  },
  {
    "id": 10105,
    "traditional": "宣稱",
    "simplified": "宣称",
    "reading": "xuānchēng",
    "meaning": "predicate, enounce, vouch, avow, make_out, lay_down, uphold, assert, declaration, make out, swan, pronounce, aver, swear, assertion, proclaim, certify, give_out, profession, declare, affirm, put forward, profess",
    "grade": 7
  },
  {
    "id": 10106,
    "traditional": "續",
    "simplified": "续",
    "reading": "xù",
    "meaning": "to continue, to replenish",
    "grade": 7
  },
  {
    "id": 10107,
    "traditional": "絮叨",
    "simplified": "絮叨",
    "reading": "xùdao",
    "meaning": "long-winded, garrulous, to talk endlessly without getting to the point",
    "grade": 7
  },
  {
    "id": 10108,
    "traditional": "宣讀",
    "simplified": "宣读",
    "reading": "xuāndú",
    "meaning": "to read out loud to an audience, a prepared speech (e.g. to a party conference)",
    "grade": 7
  },
  {
    "id": 10109,
    "traditional": "宣告",
    "simplified": "宣告",
    "reading": "xuāngào",
    "meaning": "declare, proclaim",
    "grade": 7
  },
  {
    "id": 10110,
    "traditional": "宣誓",
    "simplified": "宣誓",
    "reading": "xuān shì",
    "meaning": "to swear an oath (of office), to make a vow",
    "grade": 7
  },
  {
    "id": 10111,
    "traditional": "宣言",
    "simplified": "宣言",
    "reading": "xuānyán",
    "meaning": "declaration, manifesto",
    "grade": 7
  },
  {
    "id": 10112,
    "traditional": "宣泄",
    "simplified": "宣泄",
    "reading": "xuānxiè",
    "meaning": "to drain (by leading off water), to unburden oneself, to divulge, to leak a secret",
    "grade": 7
  },
  {
    "id": 10113,
    "traditional": "宣揚",
    "simplified": "宣扬",
    "reading": "xuānyáng",
    "meaning": "to proclaim, to make public or well known",
    "grade": 7
  },
  {
    "id": 10114,
    "traditional": "喧嘩",
    "simplified": "喧哗",
    "reading": "xuānhuá",
    "meaning": "hubbub, clamor, to make a racket",
    "grade": 7
  },
  {
    "id": 10115,
    "traditional": "喧鬧",
    "simplified": "喧闹",
    "reading": "xuānnào",
    "meaning": "to make a noise, noisy",
    "grade": 7
  },
  {
    "id": 10116,
    "traditional": "玄",
    "simplified": "玄",
    "reading": "xuán",
    "meaning": "black, mysterious",
    "grade": 7
  },
  {
    "id": 10117,
    "traditional": "玄機",
    "simplified": "玄机",
    "reading": "xuánjī",
    "meaning": "profound theory (in Daoism and Buddhism), mysterious principles",
    "grade": 7
  },
  {
    "id": 10118,
    "traditional": "懸掛",
    "simplified": "悬挂",
    "reading": "xuánguà",
    "meaning": "to suspend, to hang, (vehicle) suspension",
    "grade": 7
  },
  {
    "id": 10119,
    "traditional": "懸念",
    "simplified": "悬念",
    "reading": "xuánniàn",
    "meaning": "suspense in a movie, play etc, concern for sb's welfare",
    "grade": 7
  },
  {
    "id": 10120,
    "traditional": "懸殊",
    "simplified": "悬殊",
    "reading": "xuánshū",
    "meaning": "widely different, large disparity",
    "grade": 7
  },
  {
    "id": 10121,
    "traditional": "懸崖",
    "simplified": "悬崖",
    "reading": "xuányá",
    "meaning": "precipice, overhanging cliff",
    "grade": 7
  },
  {
    "id": 10122,
    "traditional": "旋律",
    "simplified": "旋律",
    "reading": "xuánlǜ",
    "meaning": "air, chant, canto, melodic line, rhythm, strain, melodic phrase, melodic, melody, line, aria, movement, cantus, tonal pattern, tune, descant, note",
    "grade": 7
  },
  {
    "id": 10123,
    "traditional": "選民",
    "simplified": "选民",
    "reading": "xuǎnmín",
    "meaning": "elector, voter, constituent, constituency, vote, electorate",
    "grade": 7
  },
  {
    "id": 10124,
    "traditional": "選項",
    "simplified": "选项",
    "reading": "xuǎnxiàng",
    "meaning": "to make a choice (between several alternatives), a choice, an option, an alternative",
    "grade": 7
  },
  {
    "id": 10125,
    "traditional": "旋渦",
    "simplified": "旋涡",
    "reading": "xuánwō",
    "meaning": "spiral, whirlpool, eddy, vortex",
    "grade": 7
  },
  {
    "id": 10126,
    "traditional": "選用",
    "simplified": "选用",
    "reading": "xuǎnyòng",
    "meaning": "select and use/apply",
    "grade": 7
  },
  {
    "id": 10127,
    "traditional": "炫耀",
    "simplified": "炫耀",
    "reading": "xuànyào",
    "meaning": "show_off, prank, flourish, boast, show off, strut, sport, splurge, swank, flaunt, make a display of, feature, brandish, flash",
    "grade": 7
  },
  {
    "id": 10128,
    "traditional": "削弱",
    "simplified": "削弱",
    "reading": "xuēruò",
    "meaning": "to weaken, to impair, to cripple",
    "grade": 7
  },
  {
    "id": 10129,
    "traditional": "靴子",
    "simplified": "靴子",
    "reading": "xuēzi",
    "meaning": "boots",
    "grade": 7
  },
  {
    "id": 10130,
    "traditional": "穴位",
    "simplified": "穴位",
    "reading": "xuéwèi",
    "meaning": "acupuncture point",
    "grade": 7
  },
  {
    "id": 10131,
    "traditional": "學歷",
    "simplified": "学历",
    "reading": "xuélì",
    "meaning": "education, record of formal schooling, antecedents",
    "grade": 7
  },
  {
    "id": 10132,
    "traditional": "學士",
    "simplified": "学士",
    "reading": "xuéshì",
    "meaning": "bachelor's degree, person holding a university degree",
    "grade": 7
  },
  {
    "id": 10133,
    "traditional": "學說",
    "simplified": "学说",
    "reading": "xuéshuō",
    "meaning": "theory, system, philosophy, school of thought, ism, doctrine, doctrinal, hypothesis",
    "grade": 7
  },
  {
    "id": 10134,
    "traditional": "學堂",
    "simplified": "学堂",
    "reading": "xuétáng",
    "meaning": "college, school (old)",
    "grade": 7
  },
  {
    "id": 10135,
    "traditional": "學藝",
    "simplified": "学艺",
    "reading": "xuéyì",
    "meaning": "to learn a skill or art",
    "grade": 7
  },
  {
    "id": 10136,
    "traditional": "學業",
    "simplified": "学业",
    "reading": "xuéyè",
    "meaning": "one's studies, schoolwork",
    "grade": 7
  },
  {
    "id": 10137,
    "traditional": "學子",
    "simplified": "学子",
    "reading": "xuézǐ",
    "meaning": "student, disciple",
    "grade": 7
  },
  {
    "id": 10138,
    "traditional": "雪山",
    "simplified": "雪山",
    "reading": "xuěshān",
    "meaning": "Snow mountain",
    "grade": 7
  },
  {
    "id": 10139,
    "traditional": "雪上加霜",
    "simplified": "雪上加霜",
    "reading": "xuěshàng-jiāshuāng",
    "meaning": "to add hail to snow (idiom), one disaster on top of another, to make things worse in a bad situation",
    "grade": 7
  },
  {
    "id": 10140,
    "traditional": "血脈",
    "simplified": "血脉",
    "reading": "xuèmài",
    "meaning": "blood vessels",
    "grade": 7
  },
  {
    "id": 10141,
    "traditional": "血栓",
    "simplified": "血栓",
    "reading": "xuèshuān",
    "meaning": "blood clot, thrombosis",
    "grade": 7
  },
  {
    "id": 10142,
    "traditional": "血壓",
    "simplified": "血压",
    "reading": "xuèyā",
    "meaning": "blood pressure",
    "grade": 7
  },
  {
    "id": 10143,
    "traditional": "血緣",
    "simplified": "血缘",
    "reading": "xuèyuán",
    "meaning": "bloodline",
    "grade": 7
  },
  {
    "id": 10144,
    "traditional": "勛章",
    "simplified": "勋章",
    "reading": "xūnzhāng",
    "meaning": "medal, decoration",
    "grade": 7
  },
  {
    "id": 10145,
    "traditional": "薰陶",
    "simplified": "熏陶",
    "reading": "xūntáo",
    "meaning": "to seep in, to influence, to nurture, influence, training",
    "grade": 7
  },
  {
    "id": 10146,
    "traditional": "熏",
    "simplified": "熏",
    "reading": "xūn",
    "meaning": "fragrance, warm, to educate, variant of 熏[xun1], to smoke, to fumigate",
    "grade": 7
  },
  {
    "id": 10147,
    "traditional": "尋",
    "simplified": "寻",
    "reading": "xún",
    "meaning": "try to find, look for, seek, search",
    "grade": 7
  },
  {
    "id": 10148,
    "traditional": "尋覓",
    "simplified": "寻觅",
    "reading": "xúnmì",
    "meaning": "to look for",
    "grade": 7
  },
  {
    "id": 10149,
    "traditional": "尋常",
    "simplified": "寻常",
    "reading": "xúncháng",
    "meaning": "usual, common, ordinary",
    "grade": 7
  },
  {
    "id": 10150,
    "traditional": "巡邏",
    "simplified": "巡逻",
    "reading": "xúnluó",
    "meaning": "patrol",
    "grade": 7
  },
  {
    "id": 10151,
    "traditional": "循序漸進",
    "simplified": "循序渐进",
    "reading": "xúnxù-jiànjìn",
    "meaning": "in sequence, step by step (idiom), to make steady progress incrementally",
    "grade": 7
  },
  {
    "id": 10152,
    "traditional": "馴",
    "simplified": "驯",
    "reading": "xùn",
    "meaning": "to attain gradually, to tame, Taiwan pr. [xun2]",
    "grade": 7
  },
  {
    "id": 10153,
    "traditional": "遜色",
    "simplified": "逊色",
    "reading": "xùnsè",
    "meaning": "inferior (often in the combination 毫無遜色|毫无逊色, not in the least inferior)",
    "grade": 7
  },
  {
    "id": 10154,
    "traditional": "丫頭",
    "simplified": "丫头",
    "reading": "yātou",
    "meaning": "girl, servant girl, (used deprecatingly, but sometimes also as a term of endearment)",
    "grade": 7
  },
  {
    "id": 10155,
    "traditional": "訓",
    "simplified": "训",
    "reading": "xùn",
    "meaning": "to teach, to train, to admonish, instruction (from superiors), teachings, rule",
    "grade": 7
  },
  {
    "id": 10156,
    "traditional": "壓倒",
    "simplified": "压倒",
    "reading": "yā dǎo",
    "meaning": "to overwhelm, to overpower, overwhelming",
    "grade": 7
  },
  {
    "id": 10157,
    "traditional": "壓抑",
    "simplified": "压抑",
    "reading": "yāyì",
    "meaning": "constrain, inhibit",
    "grade": 7
  },
  {
    "id": 10158,
    "traditional": "壓縮",
    "simplified": "压缩",
    "reading": "yāsuō",
    "meaning": "reduce, pack together, compact, encapsulate, cut down, compressed, constrict, reduction, shrink, condensation, compress, condense, strangulate, compression",
    "grade": 7
  },
  {
    "id": 10159,
    "traditional": "壓制",
    "simplified": "压制",
    "reading": "yāzhì",
    "meaning": "suppress, stifle, inhibit, press",
    "grade": 7
  },
  {
    "id": 10160,
    "traditional": "押",
    "simplified": "押",
    "reading": "yā",
    "meaning": "to mortgage, to pawn, to detain in custody, to escort and protect, (literary) to sign",
    "grade": 7
  },
  {
    "id": 10161,
    "traditional": "鴉雀無聲",
    "simplified": "鸦雀无声",
    "reading": "yāquè-wúshēng",
    "meaning": "lit. crow and peacock make no sound, absolute silence (idiom), not a single voice can be heard, absolute silence",
    "grade": 7
  },
  {
    "id": 10162,
    "traditional": "牙齒",
    "simplified": "牙齿",
    "reading": "yáchǐ",
    "meaning": "ivory, grinder, snapper, chopper, masticator, dental, teeth, pearly, peg, dens, tooth",
    "grade": 7
  },
  {
    "id": 10163,
    "traditional": "牙膏",
    "simplified": "牙膏",
    "reading": "yágāo",
    "meaning": "toothpaste, CL:管[guan3]",
    "grade": 7
  },
  {
    "id": 10164,
    "traditional": "啞",
    "simplified": "哑",
    "reading": "yǎ",
    "meaning": "(onom.) sound of cawing, sound of infant learning to talk, variant of 呀[ya1], dumb, mute, hoarse, husky, unexploded (of artillery shell etc)",
    "grade": 7
  },
  {
    "id": 10165,
    "traditional": "咽喉",
    "simplified": "咽喉",
    "reading": "yānhóu",
    "meaning": "throat",
    "grade": 7
  },
  {
    "id": 10166,
    "traditional": "芽",
    "simplified": "芽",
    "reading": "yá",
    "meaning": "bud, sprout",
    "grade": 7
  },
  {
    "id": 10167,
    "traditional": "煙囪",
    "simplified": "烟囱",
    "reading": "yāncōng",
    "meaning": "chimney",
    "grade": 7
  },
  {
    "id": 10168,
    "traditional": "煙火",
    "simplified": "烟火",
    "reading": "yānhuo",
    "meaning": "smoke and fire, fireworks",
    "grade": 7
  },
  {
    "id": 10169,
    "traditional": "淹",
    "simplified": "淹",
    "reading": "yān",
    "meaning": "to flood, to submerge, to drown, to irritate the skin (of liquids), to delay",
    "grade": 7
  },
  {
    "id": 10170,
    "traditional": "延",
    "simplified": "延",
    "reading": "yán",
    "meaning": "surname Yan, to prolong, to extend, to delay",
    "grade": 7
  },
  {
    "id": 10171,
    "traditional": "延緩",
    "simplified": "延缓",
    "reading": "yánhuǎn",
    "meaning": "to defer, to postpone, to put off, to retard, to slow sth down",
    "grade": 7
  },
  {
    "id": 10172,
    "traditional": "延誤",
    "simplified": "延误",
    "reading": "yánwù",
    "meaning": "to delay, to be held up, to miss (an opportunity), delay, holdup",
    "grade": 7
  },
  {
    "id": 10173,
    "traditional": "嚴謹",
    "simplified": "严谨",
    "reading": "yánjǐn",
    "meaning": "rigorous, strict, compact, well-knit",
    "grade": 7
  },
  {
    "id": 10174,
    "traditional": "嚴禁",
    "simplified": "严禁",
    "reading": "yánjìn",
    "meaning": "to strictly prohibit",
    "grade": 7
  },
  {
    "id": 10175,
    "traditional": "嚴峻",
    "simplified": "严峻",
    "reading": "yánjùn",
    "meaning": "grim, severe, rigorous",
    "grade": 7
  },
  {
    "id": 10176,
    "traditional": "言辭",
    "simplified": "言辞",
    "reading": "yáncí",
    "meaning": "words, expression, what one says",
    "grade": 7
  },
  {
    "id": 10177,
    "traditional": "嚴密",
    "simplified": "严密",
    "reading": "yánmì",
    "meaning": "strict, tight (organization, surveillance etc)",
    "grade": 7
  },
  {
    "id": 10178,
    "traditional": "言論",
    "simplified": "言论",
    "reading": "yánlùn",
    "meaning": "lecture, open discussion, talking to, speech",
    "grade": 7
  },
  {
    "id": 10179,
    "traditional": "言行",
    "simplified": "言行",
    "reading": "yánxíng",
    "meaning": "words and deeds",
    "grade": 7
  },
  {
    "id": 10180,
    "traditional": "岩石",
    "simplified": "岩石",
    "reading": "yánshí",
    "meaning": "muck, chimney, rock, petrous, stone",
    "grade": 7
  },
  {
    "id": 10181,
    "traditional": "炎癥",
    "simplified": "炎症",
    "reading": "yánzhèng",
    "meaning": "inflammation",
    "grade": 7
  },
  {
    "id": 10182,
    "traditional": "炎熱",
    "simplified": "炎热",
    "reading": "yánrè",
    "meaning": "scorching/blazing hot",
    "grade": 7
  },
  {
    "id": 10183,
    "traditional": "沿途",
    "simplified": "沿途",
    "reading": "yántú",
    "meaning": "on the way, throughout a journey",
    "grade": 7
  },
  {
    "id": 10184,
    "traditional": "沿線",
    "simplified": "沿线",
    "reading": "yánxiàn",
    "meaning": "along the line (e.g. railway), the region near the line",
    "grade": 7
  },
  {
    "id": 10185,
    "traditional": "沿岸",
    "simplified": "沿岸",
    "reading": "yánàn",
    "meaning": "coast, banks, seacoast, bank",
    "grade": 7
  },
  {
    "id": 10186,
    "traditional": "閻王",
    "simplified": "阎王",
    "reading": "Yánwang",
    "meaning": "same as 閻羅王|阎罗王, Yama, King of Hell, translation of Sanskrit: Yama Raja",
    "grade": 7
  },
  {
    "id": 10187,
    "traditional": "研討",
    "simplified": "研讨",
    "reading": "yántǎo",
    "meaning": "deliberate, discuss",
    "grade": 7
  },
  {
    "id": 10188,
    "traditional": "衍生",
    "simplified": "衍生",
    "reading": "yǎnshēng",
    "meaning": "derive, form",
    "grade": 7
  },
  {
    "id": 10189,
    "traditional": "掩蓋",
    "simplified": "掩盖",
    "reading": "yǎngài",
    "meaning": "to conceal, to hide behind, to cover up",
    "grade": 7
  },
  {
    "id": 10190,
    "traditional": "掩護",
    "simplified": "掩护",
    "reading": "yǎnhù",
    "meaning": "to screen, to shield, to cover, protection, cover, CL:面[mian4]",
    "grade": 7
  },
  {
    "id": 10191,
    "traditional": "掩飾",
    "simplified": "掩饰",
    "reading": "yǎnshì",
    "meaning": "cover up, gloss over",
    "grade": 7
  },
  {
    "id": 10192,
    "traditional": "眼紅",
    "simplified": "眼红",
    "reading": "yǎnhóng",
    "meaning": "to covet, envious, jealous, green with envy, infuriated, furious",
    "grade": 7
  },
  {
    "id": 10193,
    "traditional": "眼色",
    "simplified": "眼色",
    "reading": "yǎnsè",
    "meaning": "signal made with one's eyes, meaningful glance",
    "grade": 7
  },
  {
    "id": 10194,
    "traditional": "眼界",
    "simplified": "眼界",
    "reading": "yǎnjiè",
    "meaning": "ken, scope",
    "grade": 7
  },
  {
    "id": 10195,
    "traditional": "眼神",
    "simplified": "眼神",
    "reading": "yǎnshén",
    "meaning": "eye, expression in one's eyes, sight, eyesight",
    "grade": 7
  },
  {
    "id": 10196,
    "traditional": "眼下",
    "simplified": "眼下",
    "reading": "yǎnxià",
    "meaning": "now, at present, subocular (medicine)",
    "grade": 7
  },
  {
    "id": 10197,
    "traditional": "演變",
    "simplified": "演变",
    "reading": "yǎnbiàn",
    "meaning": "transmute",
    "grade": 7
  },
  {
    "id": 10198,
    "traditional": "演播室",
    "simplified": "演播室",
    "reading": "yǎnbōshì",
    "meaning": "broadcasting studio",
    "grade": 7
  },
  {
    "id": 10199,
    "traditional": "演練",
    "simplified": "演练",
    "reading": "yǎnliàn",
    "meaning": "drill, practice",
    "grade": 7
  },
  {
    "id": 10200,
    "traditional": "演技",
    "simplified": "演技",
    "reading": "yǎnjì",
    "meaning": "acting, performing skills",
    "grade": 7
  },
  {
    "id": 10201,
    "traditional": "演示",
    "simplified": "演示",
    "reading": "yǎnshì",
    "meaning": "to demonstrate, to show, presentation, demonstration",
    "grade": 7
  },
  {
    "id": 10202,
    "traditional": "演說",
    "simplified": "演说",
    "reading": "yǎnshuō",
    "meaning": "speech, address",
    "grade": 7
  },
  {
    "id": 10203,
    "traditional": "演習",
    "simplified": "演习",
    "reading": "yǎnxí",
    "meaning": "maneuver, exercise, practice, to maneuver",
    "grade": 7
  },
  {
    "id": 10204,
    "traditional": "演戲",
    "simplified": "演戏",
    "reading": "yǎn xì",
    "meaning": "pretence, roleplay, playact, act, act in a play, playacting, put on a play, put on or act in a play, pretend, play act, play",
    "grade": 7
  },
  {
    "id": 10205,
    "traditional": "演藝圈",
    "simplified": "演艺圈",
    "reading": "yǎnyìquān",
    "meaning": "show business",
    "grade": 7
  },
  {
    "id": 10206,
    "traditional": "演繹",
    "simplified": "演绎",
    "reading": "yǎnyì",
    "meaning": "(of a story etc) to unfold, to play out, to develop (a technique etc), to enact, (logic) to deduce, to infer",
    "grade": 7
  },
  {
    "id": 10207,
    "traditional": "厭煩",
    "simplified": "厌烦",
    "reading": "yànfán",
    "meaning": "bored, fed up with sth, sick of sth",
    "grade": 7
  },
  {
    "id": 10208,
    "traditional": "厭倦",
    "simplified": "厌倦",
    "reading": "yànjuàn",
    "meaning": "to be weary of, to be fed up with, tedious",
    "grade": 7
  },
  {
    "id": 10209,
    "traditional": "咽",
    "simplified": "咽",
    "reading": "yàn",
    "meaning": "narrow pass, throat, pharynx, to swallow, to choke (in crying)",
    "grade": 7
  },
  {
    "id": 10210,
    "traditional": "艷麗",
    "simplified": "艳丽",
    "reading": "yànlì",
    "meaning": "gorgeous, garish and beautiful",
    "grade": 7
  },
  {
    "id": 10211,
    "traditional": "驗收",
    "simplified": "验收",
    "reading": "yànshōu",
    "meaning": "to inspect and accept, acceptance",
    "grade": 7
  },
  {
    "id": 10212,
    "traditional": "驗",
    "simplified": "验",
    "reading": "yàn",
    "meaning": "to examine, to test, to check",
    "grade": 7
  },
  {
    "id": 10213,
    "traditional": "驗證",
    "simplified": "验证",
    "reading": "yànzhèng",
    "meaning": "test and verify",
    "grade": 7
  },
  {
    "id": 10214,
    "traditional": "燕子",
    "simplified": "燕子",
    "reading": "yànzi",
    "meaning": "barn_swallow, swallow",
    "grade": 7
  },
  {
    "id": 10215,
    "traditional": "焰火",
    "simplified": "焰火",
    "reading": "yànhuǒ",
    "meaning": "fireworks",
    "grade": 7
  },
  {
    "id": 10216,
    "traditional": "秧歌",
    "simplified": "秧歌",
    "reading": "yāngge",
    "meaning": "Yangge, a popular rural folk dance",
    "grade": 7
  },
  {
    "id": 10217,
    "traditional": "陽性",
    "simplified": "阳性",
    "reading": "yángxìng",
    "meaning": "positive, masculine",
    "grade": 7
  },
  {
    "id": 10218,
    "traditional": "揚",
    "simplified": "扬",
    "reading": "yáng",
    "meaning": "to raise, to hoist, the action of tossing or winnowing, scattering (in the wind), to flutter, to propagate, abbr. for 揚州|扬州[Yang2 zhou1], surname Yang, variant of 揚|扬[yang2]",
    "grade": 7
  },
  {
    "id": 10219,
    "traditional": "楊樹",
    "simplified": "杨树",
    "reading": "yángshù",
    "meaning": "poplar tree, various trees of genus Populus",
    "grade": 7
  },
  {
    "id": 10220,
    "traditional": "洋溢",
    "simplified": "洋溢",
    "reading": "yángyì",
    "meaning": "reek, brim with, be permeated with, overflow",
    "grade": 7
  },
  {
    "id": 10221,
    "traditional": "養老金",
    "simplified": "养老金",
    "reading": "yǎnglǎojīn",
    "meaning": "pension",
    "grade": 7
  },
  {
    "id": 10222,
    "traditional": "養老院",
    "simplified": "养老院",
    "reading": "yǎnglǎoyuàn",
    "meaning": "nursing home",
    "grade": 7
  },
  {
    "id": 10223,
    "traditional": "養活",
    "simplified": "养活",
    "reading": "yǎnghuo",
    "meaning": "to provide for, to keep (animals, a family etc), to raise animals, to feed and clothe, support, the necessities of life, to give birth",
    "grade": 7
  },
  {
    "id": 10224,
    "traditional": "養生",
    "simplified": "养生",
    "reading": "yǎngshēng",
    "meaning": "to maintain good health, to raise a child or animal, curing (of concrete etc)",
    "grade": 7
  },
  {
    "id": 10225,
    "traditional": "氧",
    "simplified": "氧",
    "reading": "yǎng",
    "meaning": "oxygen (chemistry)",
    "grade": 7
  },
  {
    "id": 10226,
    "traditional": "養殖",
    "simplified": "养殖",
    "reading": "yǎngzhí",
    "meaning": "to cultivate, cultivation, to further, to encourage",
    "grade": 7
  },
  {
    "id": 10227,
    "traditional": "癢",
    "simplified": "痒",
    "reading": "yǎng",
    "meaning": "to itch, to tickle",
    "grade": 7
  },
  {
    "id": 10228,
    "traditional": "樣本",
    "simplified": "样本",
    "reading": "yàngběn",
    "meaning": "sample, specimen",
    "grade": 7
  },
  {
    "id": 10229,
    "traditional": "樣品",
    "simplified": "样品",
    "reading": "yàngpǐn",
    "meaning": "sample, specimen",
    "grade": 7
  },
  {
    "id": 10230,
    "traditional": "妖怪",
    "simplified": "妖怪",
    "reading": "yāoguài",
    "meaning": "monster, devil",
    "grade": 7
  },
  {
    "id": 10231,
    "traditional": "邀",
    "simplified": "邀",
    "reading": "yāo",
    "meaning": "invite",
    "grade": 7
  },
  {
    "id": 10232,
    "traditional": "謠言",
    "simplified": "谣言",
    "reading": "yáoyán",
    "meaning": "rumor",
    "grade": 7
  },
  {
    "id": 10233,
    "traditional": "窯",
    "simplified": "窑",
    "reading": "yáo",
    "meaning": "kiln, oven, coal pit, cave dwelling, (coll.) brothel, variant of 窯|窑[yao2]",
    "grade": 7
  },
  {
    "id": 10234,
    "traditional": "搖擺",
    "simplified": "摇摆",
    "reading": "yáobǎi",
    "meaning": "to sway, to wobble, to waver",
    "grade": 7
  },
  {
    "id": 10235,
    "traditional": "搖滾",
    "simplified": "摇滚",
    "reading": "yáogǔn",
    "meaning": "rock 'n' roll (music), to rock, to fall off",
    "grade": 7
  },
  {
    "id": 10236,
    "traditional": "搖晃",
    "simplified": "摇晃",
    "reading": "yáohuang",
    "meaning": "to rock, to shake, to sway",
    "grade": 7
  },
  {
    "id": 10237,
    "traditional": "搖搖欲墜",
    "simplified": "摇摇欲坠",
    "reading": "yáoyáo-yùzhuì",
    "meaning": "tottering, on the verge of collapse",
    "grade": 7
  },
  {
    "id": 10238,
    "traditional": "搖籃",
    "simplified": "摇篮",
    "reading": "yáolán",
    "meaning": "cradle",
    "grade": 7
  },
  {
    "id": 10239,
    "traditional": "遙控",
    "simplified": "遥控",
    "reading": "yáokòng",
    "meaning": "remote control",
    "grade": 7
  },
  {
    "id": 10240,
    "traditional": "遙遠",
    "simplified": "遥远",
    "reading": "yáoyuǎn",
    "meaning": "distant, faraway, remote",
    "grade": 7
  },
  {
    "id": 10241,
    "traditional": "藥材",
    "simplified": "药材",
    "reading": "yàocái",
    "meaning": "drug ingredients",
    "grade": 7
  },
  {
    "id": 10242,
    "traditional": "藥方",
    "simplified": "药方",
    "reading": "yàofāng",
    "meaning": "prescription",
    "grade": 7
  },
  {
    "id": 10243,
    "traditional": "要不是",
    "simplified": "要不是",
    "reading": "yàobushi",
    "meaning": "if it were not for, but for",
    "grade": 7
  },
  {
    "id": 10244,
    "traditional": "要不",
    "simplified": "要不",
    "reading": "yàobù",
    "meaning": "otherwise, or else, how about...?, either... (or...)",
    "grade": 7
  },
  {
    "id": 10245,
    "traditional": "要點",
    "simplified": "要点",
    "reading": "yàodiǎn",
    "meaning": "nub, head, essentials, highlight, core, effect, message, issue, detail, key strongpoint, kernel, hinge, upshot, best, capitulation, item, essential, point, salient point, substance, outline, main points, crux, essence, burden, knot, salience, pith, pivot, sum, marrow, gist",
    "grade": 7
  },
  {
    "id": 10246,
    "traditional": "要害",
    "simplified": "要害",
    "reading": "yàohài",
    "meaning": "vital part, (fig.) key point, crucial",
    "grade": 7
  },
  {
    "id": 10247,
    "traditional": "要緊",
    "simplified": "要紧",
    "reading": "yàojǐn",
    "meaning": "important, urgent",
    "grade": 7
  },
  {
    "id": 10248,
    "traditional": "要領",
    "simplified": "要领",
    "reading": "yàolǐng",
    "meaning": "main aspects, essentials, gist",
    "grade": 7
  },
  {
    "id": 10249,
    "traditional": "要強",
    "simplified": "要强",
    "reading": "yàoqiáng",
    "meaning": "eager to excel, eager to get ahead in life, strong-minded",
    "grade": 7
  },
  {
    "id": 10250,
    "traditional": "鑰匙",
    "simplified": "钥匙",
    "reading": "yàoshi",
    "meaning": "key",
    "grade": 7
  },
  {
    "id": 10251,
    "traditional": "要命",
    "simplified": "要命",
    "reading": "yàomìng",
    "meaning": "to cause sb's death, very, extremely, frightening, annoying",
    "grade": 7
  },
  {
    "id": 10252,
    "traditional": "耀眼",
    "simplified": "耀眼",
    "reading": "yàoyǎn",
    "meaning": "to dazzle, dazzling",
    "grade": 7
  },
  {
    "id": 10253,
    "traditional": "椰子",
    "simplified": "椰子",
    "reading": "yēzi",
    "meaning": "coconut",
    "grade": 7
  },
  {
    "id": 10254,
    "traditional": "也就是說",
    "simplified": "也就是说",
    "reading": "yějiùshìshuō",
    "meaning": "i.e.",
    "grade": 7
  },
  {
    "id": 10255,
    "traditional": "野炊",
    "simplified": "野炊",
    "reading": "yěchuī",
    "meaning": "Picnic",
    "grade": 7
  },
  {
    "id": 10256,
    "traditional": "野餐",
    "simplified": "野餐",
    "reading": "yěcān",
    "meaning": "picnic, to have a picnic",
    "grade": 7
  },
  {
    "id": 10257,
    "traditional": "野蠻",
    "simplified": "野蛮",
    "reading": "yěmán",
    "meaning": "barbarous, uncivilized",
    "grade": 7
  },
  {
    "id": 10258,
    "traditional": "野獸",
    "simplified": "野兽",
    "reading": "yěshòu",
    "meaning": "beast, wild animal",
    "grade": 7
  },
  {
    "id": 10259,
    "traditional": "野外",
    "simplified": "野外",
    "reading": "yěwài",
    "meaning": "air, out-of-doors, open, outdoors, open air, open country, balefire, field",
    "grade": 7
  },
  {
    "id": 10260,
    "traditional": "野心",
    "simplified": "野心",
    "reading": "yěxīn",
    "meaning": "greed, careerism, ambition, ambitiousness, wild ambition",
    "grade": 7
  },
  {
    "id": 10261,
    "traditional": "野營",
    "simplified": "野营",
    "reading": "yěyíng",
    "meaning": "to camp, field lodgings",
    "grade": 7
  },
  {
    "id": 10262,
    "traditional": "業",
    "simplified": "业",
    "reading": "yè",
    "meaning": "surname Ye, line of business, industry, occupation, job, employment, school studies, enterprise, property, (Buddhism) karma, deed, to engage in, already",
    "grade": 7
  },
  {
    "id": 10263,
    "traditional": "夜班",
    "simplified": "夜班",
    "reading": "yèbān",
    "meaning": "night shift",
    "grade": 7
  },
  {
    "id": 10264,
    "traditional": "業績",
    "simplified": "业绩",
    "reading": "yèjì",
    "meaning": "achievement",
    "grade": 7
  },
  {
    "id": 10265,
    "traditional": "夜市",
    "simplified": "夜市",
    "reading": "yèshì",
    "meaning": "night market",
    "grade": 7
  },
  {
    "id": 10266,
    "traditional": "夜校",
    "simplified": "夜校",
    "reading": "yèxiào",
    "meaning": "evening school, night school",
    "grade": 7
  },
  {
    "id": 10267,
    "traditional": "夜晚",
    "simplified": "夜晚",
    "reading": "yèwǎn",
    "meaning": "night, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 10268,
    "traditional": "夜以繼日",
    "simplified": "夜以继日",
    "reading": "yèyǐjìrì",
    "meaning": "night and day (idiom), continuous strenuous effort",
    "grade": 7
  },
  {
    "id": 10269,
    "traditional": "夜總會",
    "simplified": "夜总会",
    "reading": "yèzǒnghuì",
    "meaning": "nightclub, nightspot",
    "grade": 7
  },
  {
    "id": 10270,
    "traditional": "液晶",
    "simplified": "液晶",
    "reading": "yèjīng",
    "meaning": "liquid crystal",
    "grade": 7
  },
  {
    "id": 10271,
    "traditional": "一把手",
    "simplified": "一把手",
    "reading": "yībǎshǒu",
    "meaning": "working hand, member of a work team, participant",
    "grade": 7
  },
  {
    "id": 10272,
    "traditional": "液體",
    "simplified": "液体",
    "reading": "yètǐ",
    "meaning": "liquid",
    "grade": 7
  },
  {
    "id": 10273,
    "traditional": "一線",
    "simplified": "一线",
    "reading": "yīxiàn",
    "meaning": "front line",
    "grade": 7
  },
  {
    "id": 10274,
    "traditional": "一一",
    "simplified": "一一",
    "reading": "yīyī",
    "meaning": "one_by_one, one after another, one at a time, one by one",
    "grade": 7
  },
  {
    "id": 10275,
    "traditional": "伊斯蘭教",
    "simplified": "伊斯兰教",
    "reading": "Yīsīlánjiào",
    "meaning": "Islam",
    "grade": 7
  },
  {
    "id": 10276,
    "traditional": "衣食住行",
    "simplified": "衣食住行",
    "reading": "yī-shí-zhù-xíng",
    "meaning": "clothing, food, housing and transport (idiom), people's basic needs",
    "grade": 7
  },
  {
    "id": 10277,
    "traditional": "依",
    "simplified": "依",
    "reading": "yī",
    "meaning": "according to, judging by",
    "grade": 7
  },
  {
    "id": 10278,
    "traditional": "醫務",
    "simplified": "医务",
    "reading": "yīwù",
    "meaning": "medical affairs",
    "grade": 7
  },
  {
    "id": 10279,
    "traditional": "依託",
    "simplified": "依托",
    "reading": "yītuō",
    "meaning": "to rely on, to depend on",
    "grade": 7
  },
  {
    "id": 10280,
    "traditional": "依依不捨",
    "simplified": "依依不舍",
    "reading": "yīyī-bùshě",
    "meaning": "reluctant to part (idiom), broken-hearted at having to leave",
    "grade": 7
  },
  {
    "id": 10281,
    "traditional": "一不小心",
    "simplified": "一不小心",
    "reading": "yībùxiǎoxīn",
    "meaning": "Unclear",
    "grade": 7
  },
  {
    "id": 10282,
    "traditional": "一剎那",
    "simplified": "一刹那",
    "reading": "yīchànà",
    "meaning": "a very short time, tick, trice, a moment, shake",
    "grade": 7
  },
  {
    "id": 10283,
    "traditional": "一動不動",
    "simplified": "一动不动",
    "reading": "yīdòngbùdòng",
    "meaning": "motionless",
    "grade": 7
  },
  {
    "id": 10284,
    "traditional": "一度",
    "simplified": "一度",
    "reading": "yīdù",
    "meaning": "once",
    "grade": 7
  },
  {
    "id": 10285,
    "traditional": "一大早",
    "simplified": "一大早",
    "reading": "yīdàzǎo",
    "meaning": "at dawn, at first light, first thing in the morning",
    "grade": 7
  },
  {
    "id": 10286,
    "traditional": "一概",
    "simplified": "一概",
    "reading": "yīgài",
    "meaning": "all, without any exceptions, categorically",
    "grade": 7
  },
  {
    "id": 10287,
    "traditional": "一概而論",
    "simplified": "一概而论",
    "reading": "yīgàiérlùn",
    "meaning": "to lump different matters together (idiom)",
    "grade": 7
  },
  {
    "id": 10288,
    "traditional": "一個勁兒",
    "simplified": "一个劲儿",
    "reading": "yīgejìnr5",
    "meaning": "continuously, persistently, incessantly, erhua variant of 一個勁|一个劲[yi1 ge4 jin4]",
    "grade": 7
  },
  {
    "id": 10289,
    "traditional": "一技之長",
    "simplified": "一技之长",
    "reading": "yījìzhīcháng",
    "meaning": "proficiency in a particular field (idiom), skill in a specialized area (idiom)",
    "grade": 7
  },
  {
    "id": 10290,
    "traditional": "一面",
    "simplified": "一面",
    "reading": "yīmiàn",
    "meaning": "at the same time, simultaneously",
    "grade": 7
  },
  {
    "id": 10291,
    "traditional": "一晃",
    "simplified": "一晃",
    "reading": "yīhuǎng",
    "meaning": "(of passing time) in an instant, (of a sight) in a flash",
    "grade": 7
  },
  {
    "id": 10292,
    "traditional": "一目了然",
    "simplified": "一目了然",
    "reading": "yīmù-liǎorán",
    "meaning": "obvious at a glance (idiom)",
    "grade": 7
  },
  {
    "id": 10293,
    "traditional": "一事無成",
    "simplified": "一事无成",
    "reading": "yīshì-wúchéng",
    "meaning": "to have achieved nothing, to be a total failure, to get nowhere",
    "grade": 7
  },
  {
    "id": 10294,
    "traditional": "一瞬間",
    "simplified": "一瞬间",
    "reading": "yīshùnjiān",
    "meaning": "split second",
    "grade": 7
  },
  {
    "id": 10295,
    "traditional": "一味",
    "simplified": "一味",
    "reading": "yīwèi",
    "meaning": "blindly, stubbornly",
    "grade": 7
  },
  {
    "id": 10296,
    "traditional": "一系列",
    "simplified": "一系列",
    "reading": "yīxìliè",
    "meaning": "a series of, a string of",
    "grade": 7
  },
  {
    "id": 10297,
    "traditional": "一陣",
    "simplified": "一阵",
    "reading": "yīzhèn",
    "meaning": "a burst, a fit, a peal, a spell (period of time)",
    "grade": 7
  },
  {
    "id": 10298,
    "traditional": "儀表",
    "simplified": "仪表",
    "reading": "yíbiǎo",
    "meaning": "appearance, bearing, meter (i.e. measuring instrument)",
    "grade": 7
  },
  {
    "id": 10299,
    "traditional": "移交",
    "simplified": "移交",
    "reading": "yíjiāo",
    "meaning": "to transfer, to hand over",
    "grade": 7
  },
  {
    "id": 10300,
    "traditional": "移植",
    "simplified": "移植",
    "reading": "yízhí",
    "meaning": "transplant, graft",
    "grade": 7
  },
  {
    "id": 10301,
    "traditional": "姨",
    "simplified": "姨",
    "reading": "yí",
    "meaning": "mother's sister, aunt",
    "grade": 7
  },
  {
    "id": 10302,
    "traditional": "遺留",
    "simplified": "遗留",
    "reading": "yíliú",
    "meaning": "to leave behind, to hand down",
    "grade": 7
  },
  {
    "id": 10303,
    "traditional": "遺棄",
    "simplified": "遗弃",
    "reading": "yíqì",
    "meaning": "to leave, to abandon",
    "grade": 7
  },
  {
    "id": 10304,
    "traditional": "遺忘",
    "simplified": "遗忘",
    "reading": "yíwàng",
    "meaning": "misplace, bury, neglect, leave behind, escape, leave, elude, mislay, leave_behind, forget",
    "grade": 7
  },
  {
    "id": 10305,
    "traditional": "遺物",
    "simplified": "遗物",
    "reading": "yíwù",
    "meaning": "hangover, legacy, survival, memento, survivor, things left behind by the deceased, holdover, souvenir, keepsake, reliquiae, things left by deceased, remnant",
    "grade": 7
  },
  {
    "id": 10306,
    "traditional": "遺體",
    "simplified": "遗体",
    "reading": "yítǐ",
    "meaning": "remains (of a dead person)",
    "grade": 7
  },
  {
    "id": 10307,
    "traditional": "遺囑",
    "simplified": "遗嘱",
    "reading": "yízhǔ",
    "meaning": "testament, will",
    "grade": 7
  },
  {
    "id": 10308,
    "traditional": "遺願",
    "simplified": "遗愿",
    "reading": "yíyuàn",
    "meaning": "final wishes of the departed",
    "grade": 7
  },
  {
    "id": 10309,
    "traditional": "疑點",
    "simplified": "疑点",
    "reading": "yídiǎn",
    "meaning": "a doubtful point",
    "grade": 7
  },
  {
    "id": 10310,
    "traditional": "遺址",
    "simplified": "遗址",
    "reading": "yízhǐ",
    "meaning": "ruins, remains, monument, relics",
    "grade": 7
  },
  {
    "id": 10311,
    "traditional": "疑惑",
    "simplified": "疑惑",
    "reading": "yíhuò",
    "meaning": "be perplexed, be uncertain",
    "grade": 7
  },
  {
    "id": 10312,
    "traditional": "疑慮",
    "simplified": "疑虑",
    "reading": "yílǜ",
    "meaning": "misgivings, doubts",
    "grade": 7
  },
  {
    "id": 10313,
    "traditional": "以",
    "simplified": "以",
    "reading": "yǐ",
    "meaning": "using, taking, from a point on, because of",
    "grade": 7
  },
  {
    "id": 10314,
    "traditional": "以身作則",
    "simplified": "以身作则",
    "reading": "yǐshēn-zuòzé",
    "meaning": "to set an example (idiom), to serve as a model",
    "grade": 7
  },
  {
    "id": 10315,
    "traditional": "以免",
    "simplified": "以免",
    "reading": "yǐmiǎn",
    "meaning": "conj.: in order to avoid, so as not to",
    "grade": 7
  },
  {
    "id": 10316,
    "traditional": "以至於",
    "simplified": "以至于",
    "reading": "yǐzhìyú",
    "meaning": "conj.: down/up to, to such an extent as to..., so...that...",
    "grade": 7
  },
  {
    "id": 10317,
    "traditional": "矣",
    "simplified": "矣",
    "reading": "yǐ",
    "meaning": "particle: used in classical Chinese to express exclamation",
    "grade": 7
  },
  {
    "id": 10318,
    "traditional": "以致",
    "simplified": "以致",
    "reading": "yǐzhì",
    "meaning": "conj.: so that, with the result that",
    "grade": 7
  },
  {
    "id": 10319,
    "traditional": "倚",
    "simplified": "倚",
    "reading": "yǐ",
    "meaning": "to lean on, to rely upon",
    "grade": 7
  },
  {
    "id": 10320,
    "traditional": "一成不變",
    "simplified": "一成不变",
    "reading": "yīchéng-bùbiàn",
    "meaning": "nothing much changes (idiom), always the same, stuck in a rut",
    "grade": 7
  },
  {
    "id": 10321,
    "traditional": "一長一短",
    "simplified": "一长一短",
    "reading": "yī cháng yī duǎn",
    "meaning": "A long one",
    "grade": 7
  },
  {
    "id": 10322,
    "traditional": "一籌莫展",
    "simplified": "一筹莫展",
    "reading": "yīchóu-mòzhǎn",
    "meaning": "to be unable to find a solution, to be at wits' end",
    "grade": 7
  },
  {
    "id": 10323,
    "traditional": "一帆風順",
    "simplified": "一帆风顺",
    "reading": "yīfān-fēngshùn",
    "meaning": "propitious wind throughout the journey (idiom), plain sailing, to go smoothly, have a nice trip!",
    "grade": 7
  },
  {
    "id": 10324,
    "traditional": "一乾二淨",
    "simplified": "一干二净",
    "reading": "yīgān-èrjìng",
    "meaning": "thoroughly (idiom), completely, one and all, very clean",
    "grade": 7
  },
  {
    "id": 10325,
    "traditional": "一鼓作氣",
    "simplified": "一鼓作气",
    "reading": "yīgǔ-zuòqì",
    "meaning": "in a spurt of energy",
    "grade": 7
  },
  {
    "id": 10326,
    "traditional": "一鍋粥",
    "simplified": "一锅粥",
    "reading": "yīguōzhōu",
    "meaning": "(lit.) a pot of porridge, (fig.) a complete mess",
    "grade": 7
  },
  {
    "id": 10327,
    "traditional": "一回事",
    "simplified": "一回事",
    "reading": "yīhuíshì",
    "meaning": "one thing, the same as",
    "grade": 7
  },
  {
    "id": 10328,
    "traditional": "一家人",
    "simplified": "一家人",
    "reading": "yījiārén",
    "meaning": "house",
    "grade": 7
  },
  {
    "id": 10329,
    "traditional": "一舉",
    "simplified": "一举",
    "reading": "yījǔ",
    "meaning": "a move, an action, in one move, at a stroke, in one go",
    "grade": 7
  },
  {
    "id": 10330,
    "traditional": "一經",
    "simplified": "一经",
    "reading": "yījīng",
    "meaning": "Once",
    "grade": 7
  },
  {
    "id": 10331,
    "traditional": "一舉一動",
    "simplified": "一举一动",
    "reading": "yījǔ-yīdòng",
    "meaning": "every movement, each and every move",
    "grade": 7
  },
  {
    "id": 10332,
    "traditional": "一卡通",
    "simplified": "一卡通",
    "reading": "yīkǎtōng",
    "meaning": "Yikatong (Beijing public transport smart card)",
    "grade": 7
  },
  {
    "id": 10333,
    "traditional": "一攬子",
    "simplified": "一揽子",
    "reading": "yīlǎnzi",
    "meaning": "all-inclusive, undiscriminating",
    "grade": 7
  },
  {
    "id": 10334,
    "traditional": "一連",
    "simplified": "一连",
    "reading": "yīlián",
    "meaning": "in a row, in succession, running",
    "grade": 7
  },
  {
    "id": 10335,
    "traditional": "一連串",
    "simplified": "一连串",
    "reading": "yīliánchuàn",
    "meaning": "a string of, in a row, a series of, a chain of, a succession of, in succession",
    "grade": 7
  },
  {
    "id": 10336,
    "traditional": "一毛不拔",
    "simplified": "一毛不拔",
    "reading": "yīmáo-bùbá",
    "meaning": "stingy (idiom)",
    "grade": 7
  },
  {
    "id": 10337,
    "traditional": "一年到頭",
    "simplified": "一年到头",
    "reading": "yīnián-dàotóu",
    "meaning": "all year round",
    "grade": 7
  },
  {
    "id": 10338,
    "traditional": "一如既往",
    "simplified": "一如既往",
    "reading": "yīrú-jìwǎng",
    "meaning": "just as in the past (idiom), as before, continuing as always",
    "grade": 7
  },
  {
    "id": 10339,
    "traditional": "一旁",
    "simplified": "一旁",
    "reading": "yīpáng",
    "meaning": "aside, to the side of",
    "grade": 7
  },
  {
    "id": 10340,
    "traditional": "一聲不吭",
    "simplified": "一声不吭",
    "reading": "yīshēngbùkēng",
    "meaning": "to not say a word",
    "grade": 7
  },
  {
    "id": 10341,
    "traditional": "一塌糊塗",
    "simplified": "一塌糊涂",
    "reading": "yītāhútú",
    "meaning": "muddled and completely collapsing (idiom), in an awful condition, complete shambles, a total mess",
    "grade": 7
  },
  {
    "id": 10342,
    "traditional": "一體",
    "simplified": "一体",
    "reading": "yītǐ",
    "meaning": "an organic/integral whole, all concerned",
    "grade": 7
  },
  {
    "id": 10343,
    "traditional": "一手",
    "simplified": "一手",
    "reading": "yīshǒu",
    "meaning": "a skill, mastery of a trade, by oneself, without outside help",
    "grade": 7
  },
  {
    "id": 10344,
    "traditional": "一天到晚",
    "simplified": "一天到晚",
    "reading": "yītiān-dàowǎn",
    "meaning": "all day long",
    "grade": 7
  },
  {
    "id": 10345,
    "traditional": "一頭",
    "simplified": "一头",
    "reading": "yītóu",
    "meaning": "one head, a head full of sth, one end (of a stick), one side, headlong, directly, rapidly, simultaneously",
    "grade": 7
  },
  {
    "id": 10346,
    "traditional": "一無所有",
    "simplified": "一无所有",
    "reading": "yīwú-suǒyǒu",
    "meaning": "not having anything at all (idiom), utterly lacking, without two sticks to rub together",
    "grade": 7
  },
  {
    "id": 10347,
    "traditional": "一無所知",
    "simplified": "一无所知",
    "reading": "yīwú-suǒzhī",
    "meaning": "not knowing anything at all (idiom), completely ignorant, without an inkling",
    "grade": 7
  },
  {
    "id": 10348,
    "traditional": "一心",
    "simplified": "一心",
    "reading": "yīxīn",
    "meaning": "wholeheartedly, heart and soul",
    "grade": 7
  },
  {
    "id": 10349,
    "traditional": "一心一意",
    "simplified": "一心一意",
    "reading": "yīxīn-yīyì",
    "meaning": "concentrating one's thoughts and efforts, single-minded, bent on, intently",
    "grade": 7
  },
  {
    "id": 10350,
    "traditional": "一言不發",
    "simplified": "一言不发",
    "reading": "yīyán-bùfā",
    "meaning": "to not say a word (idiom)",
    "grade": 7
  },
  {
    "id": 10351,
    "traditional": "一言一行",
    "simplified": "一言一行",
    "reading": "yīyán-yìxíng",
    "meaning": "every word and action (idiom)",
    "grade": 7
  },
  {
    "id": 10352,
    "traditional": "一應俱全",
    "simplified": "一应俱全",
    "reading": "yīyīng-jùquán",
    "meaning": "with everything needed available",
    "grade": 7
  },
  {
    "id": 10353,
    "traditional": "一早",
    "simplified": "一早",
    "reading": "yīzǎo",
    "meaning": "early in the morning, at dawn",
    "grade": 7
  },
  {
    "id": 10354,
    "traditional": "一眼",
    "simplified": "一眼",
    "reading": "yīyǎn",
    "meaning": "a glance, a quick look, a glimpse",
    "grade": 7
  },
  {
    "id": 10355,
    "traditional": "義工",
    "simplified": "义工",
    "reading": "yìgōng",
    "meaning": "volunteer",
    "grade": 7
  },
  {
    "id": 10356,
    "traditional": "議程",
    "simplified": "议程",
    "reading": "yìchéng",
    "meaning": "agenda",
    "grade": 7
  },
  {
    "id": 10357,
    "traditional": "議會",
    "simplified": "议会",
    "reading": "yìhuì",
    "meaning": "Sanhedrin, general assembly, Cortes, Knesset, diet, senate, Majlis, duma, law-makers, forum, junta, parliament, legislature, meeting place, parliamentary, assembly, president, house, legislative assembly",
    "grade": 7
  },
  {
    "id": 10358,
    "traditional": "議",
    "simplified": "议",
    "reading": "yì",
    "meaning": "to comment on, to discuss, to suggest",
    "grade": 7
  },
  {
    "id": 10359,
    "traditional": "議員",
    "simplified": "议员",
    "reading": "yìyuán",
    "meaning": "councillor, member of legislative body, deputy, councilman, council member, legislator, solon, Congresswoman, Member of Parliament, assemblyman, Congressman",
    "grade": 7
  },
  {
    "id": 10360,
    "traditional": "屹立",
    "simplified": "屹立",
    "reading": "yìlì",
    "meaning": "to tower, to stand straight (of person's bearing)",
    "grade": 7
  },
  {
    "id": 10361,
    "traditional": "亦",
    "simplified": "亦",
    "reading": "yì",
    "meaning": "also, too",
    "grade": 7
  },
  {
    "id": 10362,
    "traditional": "異想天開",
    "simplified": "异想天开",
    "reading": "yìxiǎng-tiānkāi",
    "meaning": "to imagine the wildest thing, to indulge in fantasy",
    "grade": 7
  },
  {
    "id": 10363,
    "traditional": "異性",
    "simplified": "异性",
    "reading": "yìxìng",
    "meaning": "metamerism, isomerism, heterosexual, opposite sex, different nature",
    "grade": 7
  },
  {
    "id": 10364,
    "traditional": "異議",
    "simplified": "异议",
    "reading": "yìyì",
    "meaning": "remonstrance, dissent, expostulation, exception, dissidence, protestation, demur, objection, demurrer",
    "grade": 7
  },
  {
    "id": 10365,
    "traditional": "異口同聲",
    "simplified": "异口同声",
    "reading": "yìkǒu-tóngshēng",
    "meaning": "different mouths, same voice, to speak in unison (idiom)",
    "grade": 7
  },
  {
    "id": 10366,
    "traditional": "抑鬱",
    "simplified": "抑郁",
    "reading": "yìyù",
    "meaning": "depressed, despondent, gloomy, depression",
    "grade": 7
  },
  {
    "id": 10367,
    "traditional": "抑揚頓挫",
    "simplified": "抑扬顿挫",
    "reading": "yìyáng-dùncuò",
    "meaning": "see 頓挫抑揚|顿挫抑扬[dun4 cuo4 yi4 yang2]",
    "grade": 7
  },
  {
    "id": 10368,
    "traditional": "抑制",
    "simplified": "抑制",
    "reading": "yìzhì",
    "meaning": "forbear, chasten, last out, govern, forebear, help, baste, cumber, rein, hamshackle, damp, disable, harness, restrain, muffle, stay, rule, hold in, bottle_up, throttle, constrain, retard, moderate, cork, swallow, hold, mortify, smother, rebuke, withhold, refrain, soften, control, break, curb, oppress, inhibit, ride out, coerce, dominate, bridle, constrict, choke, inhibition, dampen, restraint",
    "grade": 7
  },
  {
    "id": 10369,
    "traditional": "抑鬱症",
    "simplified": "抑郁症",
    "reading": "yìyùzhèng",
    "meaning": "clinical depression",
    "grade": 7
  },
  {
    "id": 10370,
    "traditional": "譯",
    "simplified": "译",
    "reading": "yì",
    "meaning": "to translate, to interpret",
    "grade": 7
  },
  {
    "id": 10371,
    "traditional": "疫苗",
    "simplified": "疫苗",
    "reading": "yìmiáo",
    "meaning": "vaccine",
    "grade": 7
  },
  {
    "id": 10372,
    "traditional": "易拉罐",
    "simplified": "易拉罐",
    "reading": "yìlāguàn",
    "meaning": "pull-top can, easy-open can (with ring-pull)",
    "grade": 7
  },
  {
    "id": 10373,
    "traditional": "意料",
    "simplified": "意料",
    "reading": "yìliào",
    "meaning": "to anticipate, to expect",
    "grade": 7
  },
  {
    "id": 10374,
    "traditional": "意料之外",
    "simplified": "意料之外",
    "reading": "yìliàozhīwài",
    "meaning": "contrary to expectation, unexpected",
    "grade": 7
  },
  {
    "id": 10375,
    "traditional": "意圖",
    "simplified": "意图",
    "reading": "yìtú",
    "meaning": "intention, intent",
    "grade": 7
  },
  {
    "id": 10376,
    "traditional": "意向",
    "simplified": "意向",
    "reading": "yìxiàng",
    "meaning": "intention, purpose, intent, inclination, disposition",
    "grade": 7
  },
  {
    "id": 10377,
    "traditional": "益處",
    "simplified": "益处",
    "reading": "yìchu",
    "meaning": "benefit",
    "grade": 7
  },
  {
    "id": 10378,
    "traditional": "溢",
    "simplified": "溢",
    "reading": "yì",
    "meaning": "to overflow, (literary) excessive, old variant of 鎰|镒[yi4]",
    "grade": 7
  },
  {
    "id": 10379,
    "traditional": "毅然",
    "simplified": "毅然",
    "reading": "yìrán",
    "meaning": "firmly, resolutely, without hesitation",
    "grade": 7
  },
  {
    "id": 10380,
    "traditional": "毅力",
    "simplified": "毅力",
    "reading": "yìlì",
    "meaning": "perseverance, willpower",
    "grade": 7
  },
  {
    "id": 10381,
    "traditional": "陰暗",
    "simplified": "阴暗",
    "reading": "yīnàn",
    "meaning": "dim, dark, overcast, darkness, shadow, (fig.) dismal, gloomy, somber, murky, shadowy (side)",
    "grade": 7
  },
  {
    "id": 10382,
    "traditional": "因人而異",
    "simplified": "因人而异",
    "reading": "yīnrénéryì",
    "meaning": "varying from person to person (idiom), different for each individual",
    "grade": 7
  },
  {
    "id": 10383,
    "traditional": "音響",
    "simplified": "音响",
    "reading": "yīnxiǎng",
    "meaning": "clank, acoustics, music center, sound, hi-fi set, stereo, audio, hi-fi, acoustic, hi-fi stereo component system",
    "grade": 7
  },
  {
    "id": 10384,
    "traditional": "陰性",
    "simplified": "阴性",
    "reading": "yīnxìng",
    "meaning": "negative, feminine",
    "grade": 7
  },
  {
    "id": 10385,
    "traditional": "殷勤",
    "simplified": "殷勤",
    "reading": "yīnqín",
    "meaning": "politely, solicitously, eagerly attentive",
    "grade": 7
  },
  {
    "id": 10386,
    "traditional": "銀幕",
    "simplified": "银幕",
    "reading": "yínmù",
    "meaning": "movie screen",
    "grade": 7
  },
  {
    "id": 10387,
    "traditional": "引發",
    "simplified": "引发",
    "reading": "yǐnfā",
    "meaning": "trigger, motivate, set, evoke, initiate, evoke emotion, initiation",
    "grade": 7
  },
  {
    "id": 10388,
    "traditional": "引經據典",
    "simplified": "引经据典",
    "reading": "yǐnjīng-jùdiǎn",
    "meaning": "lit. to quote the classics, to quote chapter and verse (idiom)",
    "grade": 7
  },
  {
    "id": 10389,
    "traditional": "引擎",
    "simplified": "引擎",
    "reading": "yǐnqíng",
    "meaning": "engine",
    "grade": 7
  },
  {
    "id": 10390,
    "traditional": "引領",
    "simplified": "引领",
    "reading": "yǐnlǐng",
    "meaning": "to crane one's neck, to await eagerly, to lead, to show the way",
    "grade": 7
  },
  {
    "id": 10391,
    "traditional": "引入",
    "simplified": "引入",
    "reading": "yǐnrù",
    "meaning": "to draw into, to pull into, to introduce",
    "grade": 7
  },
  {
    "id": 10392,
    "traditional": "引人注目",
    "simplified": "引人注目",
    "reading": "yǐnrén-zhùmù",
    "meaning": "to attract attention, eye-catching, conspicuous",
    "grade": 7
  },
  {
    "id": 10393,
    "traditional": "引誘",
    "simplified": "引诱",
    "reading": "yǐnyòu",
    "meaning": "lure, seduce",
    "grade": 7
  },
  {
    "id": 10394,
    "traditional": "引用",
    "simplified": "引用",
    "reading": "yǐnyòng",
    "meaning": "quote, cite, recommend, appoint",
    "grade": 7
  },
  {
    "id": 10395,
    "traditional": "飲水",
    "simplified": "饮水",
    "reading": "yǐnshuǐ",
    "meaning": "drinking water",
    "grade": 7
  },
  {
    "id": 10396,
    "traditional": "飲用水",
    "simplified": "饮用水",
    "reading": "yǐnyòngshuǐ",
    "meaning": "drinking water, potable water",
    "grade": 7
  },
  {
    "id": 10397,
    "traditional": "隱蔽",
    "simplified": "隐蔽",
    "reading": "yǐnbì",
    "meaning": "to conceal, to hide, covert, under cover",
    "grade": 7
  },
  {
    "id": 10398,
    "traditional": "隱患",
    "simplified": "隐患",
    "reading": "yǐnhuàn",
    "meaning": "a danger concealed within sth, hidden damage, misfortune not visible from the surface",
    "grade": 7
  },
  {
    "id": 10399,
    "traditional": "隱情",
    "simplified": "隐情",
    "reading": "yǐnqíng",
    "meaning": "sth one wishes to keep secret, ulterior motive, a subject best avoided",
    "grade": 7
  },
  {
    "id": 10400,
    "traditional": "隱瞞",
    "simplified": "隐瞒",
    "reading": "yǐnmán",
    "meaning": "to conceal, to hide (a taboo subject), to cover up the truth",
    "grade": 7
  },
  {
    "id": 10401,
    "traditional": "隱身",
    "simplified": "隐身",
    "reading": "yǐnshēn",
    "meaning": "to hide oneself, invisible (person or online status)",
    "grade": 7
  },
  {
    "id": 10402,
    "traditional": "隱形",
    "simplified": "隐形",
    "reading": "yǐnxíng",
    "meaning": "invisible",
    "grade": 7
  },
  {
    "id": 10403,
    "traditional": "隱性",
    "simplified": "隐性",
    "reading": "yǐnxìng",
    "meaning": "hidden, crypto-, recessive (gene)",
    "grade": 7
  },
  {
    "id": 10404,
    "traditional": "隱約",
    "simplified": "隐约",
    "reading": "yǐnyuē",
    "meaning": "vague, faint, indistinct",
    "grade": 7
  },
  {
    "id": 10405,
    "traditional": "癮",
    "simplified": "瘾",
    "reading": "yǐn",
    "meaning": "addiction, craving",
    "grade": 7
  },
  {
    "id": 10406,
    "traditional": "印章",
    "simplified": "印章",
    "reading": "yìnzhāng",
    "meaning": "seal, signet, chop, stamp, CL:方[fang1]",
    "grade": 7
  },
  {
    "id": 10407,
    "traditional": "印刷術",
    "simplified": "印刷术",
    "reading": "yìnshuāshù",
    "meaning": "printing, printing technology",
    "grade": 7
  },
  {
    "id": 10408,
    "traditional": "印證",
    "simplified": "印证",
    "reading": "yìnzhèng",
    "meaning": "confirm, verify",
    "grade": 7
  },
  {
    "id": 10409,
    "traditional": "應有盡有",
    "simplified": "应有尽有",
    "reading": "yīngyǒu-jìnyǒu",
    "meaning": "everything that should be here is here (idiom), all one can think of is on hand, to have all one needs",
    "grade": 7
  },
  {
    "id": 10410,
    "traditional": "英俊",
    "simplified": "英俊",
    "reading": "yīngjùn",
    "meaning": "handsome",
    "grade": 7
  },
  {
    "id": 10411,
    "traditional": "英鎊",
    "simplified": "英镑",
    "reading": "yīngbàng",
    "meaning": "pound sterling",
    "grade": 7
  },
  {
    "id": 10412,
    "traditional": "嬰兒",
    "simplified": "婴儿",
    "reading": "yīngér",
    "meaning": "babe, baby infant, wean, neonate, papoose, nestling, parvulus, baby, cheeper, child, bantling, nursling, bambino, infant, nurseling, infantile, suckling, chrisom",
    "grade": 7
  },
  {
    "id": 10413,
    "traditional": "鷹",
    "simplified": "鹰",
    "reading": "yīng",
    "meaning": "hawk, eagle, falcon",
    "grade": 7
  },
  {
    "id": 10414,
    "traditional": "迎合",
    "simplified": "迎合",
    "reading": "yínghé",
    "meaning": "to cater to, to pander to",
    "grade": 7
  },
  {
    "id": 10415,
    "traditional": "迎",
    "simplified": "迎",
    "reading": "yíng",
    "meaning": "greet, go to meet, reception, meet face to face, face, receive, welcome, move towards, meet",
    "grade": 7
  },
  {
    "id": 10416,
    "traditional": "熒光",
    "simplified": "荧光",
    "reading": "yíngguāng",
    "meaning": "fluorescence, fluorescent",
    "grade": 7
  },
  {
    "id": 10417,
    "traditional": "盈利",
    "simplified": "盈利",
    "reading": "yínglì",
    "meaning": "profit, gain",
    "grade": 7
  },
  {
    "id": 10418,
    "traditional": "營救",
    "simplified": "营救",
    "reading": "yíngjiù",
    "meaning": "to rescue",
    "grade": 7
  },
  {
    "id": 10419,
    "traditional": "贏家",
    "simplified": "赢家",
    "reading": "yíngjia",
    "meaning": "winner",
    "grade": 7
  },
  {
    "id": 10420,
    "traditional": "營造",
    "simplified": "营造",
    "reading": "yíngzào",
    "meaning": "construct, build",
    "grade": 7
  },
  {
    "id": 10421,
    "traditional": "影像",
    "simplified": "影像",
    "reading": "yǐngxiàng",
    "meaning": "simulacrum, blip, mental image, picture, portrait, video, image",
    "grade": 7
  },
  {
    "id": 10422,
    "traditional": "應酬",
    "simplified": "应酬",
    "reading": "yìngchou",
    "meaning": "social niceties, social interaction, a dinner party",
    "grade": 7
  },
  {
    "id": 10423,
    "traditional": "應付",
    "simplified": "应付",
    "reading": "yīngfù",
    "meaning": "hack, cope_with, treat, stand, bridge_over, come_on, do sth. perfunctorily, cope with, handle, cope, do ... perfunctorily, deal/cope with, cut, make do, deal, deal with, respond",
    "grade": 7
  },
  {
    "id": 10424,
    "traditional": "應聘",
    "simplified": "应聘",
    "reading": "yìngpìn",
    "meaning": "to accept a job offer, to apply for an advertised position",
    "grade": 7
  },
  {
    "id": 10425,
    "traditional": "應邀",
    "simplified": "应邀",
    "reading": "yìngyāo",
    "meaning": "on invitation, at sb.'s invitation, receive an invitation",
    "grade": 7
  },
  {
    "id": 10426,
    "traditional": "硬幣",
    "simplified": "硬币",
    "reading": "yìngbì",
    "meaning": "coin, CL:枚[mei2]",
    "grade": 7
  },
  {
    "id": 10427,
    "traditional": "映",
    "simplified": "映",
    "reading": "yìng",
    "meaning": "to reflect (light), to shine, to project (an image onto a screen etc., old variant of 映[ying4]",
    "grade": 7
  },
  {
    "id": 10428,
    "traditional": "硬盤",
    "simplified": "硬盘",
    "reading": "yìngpán",
    "meaning": "hard disk",
    "grade": 7
  },
  {
    "id": 10429,
    "traditional": "擁護",
    "simplified": "拥护",
    "reading": "yōnghù",
    "meaning": "to endorse, to support",
    "grade": 7
  },
  {
    "id": 10430,
    "traditional": "擁擠",
    "simplified": "拥挤",
    "reading": "yōngjǐ",
    "meaning": "crowded, pushed and squeezed together",
    "grade": 7
  },
  {
    "id": 10431,
    "traditional": "庸俗",
    "simplified": "庸俗",
    "reading": "yōngsú",
    "meaning": "filthy, vulgar, debased",
    "grade": 7
  },
  {
    "id": 10432,
    "traditional": "硬朗",
    "simplified": "硬朗",
    "reading": "yìnglang",
    "meaning": "robust, healthy",
    "grade": 7
  },
  {
    "id": 10433,
    "traditional": "永恆",
    "simplified": "永恒",
    "reading": "yǒnghéng",
    "meaning": "eternalize, perpetual, eternize, eternal",
    "grade": 7
  },
  {
    "id": 10434,
    "traditional": "永久",
    "simplified": "永久",
    "reading": "yǒngjiǔ",
    "meaning": "everlasting, perpetual, lasting, forever, permanent",
    "grade": 7
  },
  {
    "id": 10435,
    "traditional": "永不",
    "simplified": "永不",
    "reading": "yǒngbù",
    "meaning": "never, will never",
    "grade": 7
  },
  {
    "id": 10436,
    "traditional": "勇往直前",
    "simplified": "勇往直前",
    "reading": "yǒngwǎng-zhíqián",
    "meaning": "to advance bravely",
    "grade": 7
  },
  {
    "id": 10437,
    "traditional": "勇於",
    "simplified": "勇于",
    "reading": "yǒngyú",
    "meaning": "be bold in, have the courage to, have courage to, be brave in",
    "grade": 7
  },
  {
    "id": 10438,
    "traditional": "涌",
    "simplified": "涌",
    "reading": "yǒng",
    "meaning": "(used in place names), to bubble up, to rush forth",
    "grade": 7
  },
  {
    "id": 10439,
    "traditional": "湧入",
    "simplified": "涌入",
    "reading": "yǒngrù",
    "meaning": "Influence",
    "grade": 7
  },
  {
    "id": 10440,
    "traditional": "涌現",
    "simplified": "涌现",
    "reading": "yǒngxiàn",
    "meaning": "to emerge in large numbers, to spring up, to emerge prominently",
    "grade": 7
  },
  {
    "id": 10441,
    "traditional": "用餐",
    "simplified": "用餐",
    "reading": "yòng cān",
    "meaning": "to eat a meal",
    "grade": 7
  },
  {
    "id": 10442,
    "traditional": "踴躍",
    "simplified": "踊跃",
    "reading": "yǒngyuè",
    "meaning": "enthusiastical, leap, jump, eager",
    "grade": 7
  },
  {
    "id": 10443,
    "traditional": "用人",
    "simplified": "用人",
    "reading": "yòngren",
    "meaning": "make use of personnel, employ people",
    "grade": 7
  },
  {
    "id": 10444,
    "traditional": "用力",
    "simplified": "用力",
    "reading": "yòng lì",
    "meaning": "exert self, exert oneself, put forth one's strenght, exert self (physically)",
    "grade": 7
  },
  {
    "id": 10445,
    "traditional": "用功",
    "simplified": "用功",
    "reading": "yònggōng",
    "meaning": "study, studious, hardworking, niggle, grind",
    "grade": 7
  },
  {
    "id": 10446,
    "traditional": "優",
    "simplified": "优",
    "reading": "yōu",
    "meaning": "excellent, superior",
    "grade": 7
  },
  {
    "id": 10447,
    "traditional": "用意",
    "simplified": "用意",
    "reading": "yòngyì",
    "meaning": "intention, purpose",
    "grade": 7
  },
  {
    "id": 10448,
    "traditional": "優化",
    "simplified": "优化",
    "reading": "yōuhuà",
    "meaning": "optimization, to optimize, to make superior",
    "grade": 7
  },
  {
    "id": 10449,
    "traditional": "優異",
    "simplified": "优异",
    "reading": "yōuyì",
    "meaning": "excellent, outstanding",
    "grade": 7
  },
  {
    "id": 10450,
    "traditional": "優越",
    "simplified": "优越",
    "reading": "yōuyuè",
    "meaning": "excel, exceed, superior, outstanding, transcend",
    "grade": 7
  },
  {
    "id": 10451,
    "traditional": "優雅",
    "simplified": "优雅",
    "reading": "yōuyǎ",
    "meaning": "graceful, elegant, gracious",
    "grade": 7
  },
  {
    "id": 10452,
    "traditional": "憂愁",
    "simplified": "忧愁",
    "reading": "yōuchóu",
    "meaning": "to be worried",
    "grade": 7
  },
  {
    "id": 10453,
    "traditional": "憂慮",
    "simplified": "忧虑",
    "reading": "yōulǜ",
    "meaning": "to worry, anxiety (about)",
    "grade": 7
  },
  {
    "id": 10454,
    "traditional": "悠久",
    "simplified": "悠久",
    "reading": "yōujiǔ",
    "meaning": "long in time",
    "grade": 7
  },
  {
    "id": 10455,
    "traditional": "憂鬱",
    "simplified": "忧郁",
    "reading": "yōuyù",
    "meaning": "sullen, depressed, melancholy, dejected",
    "grade": 7
  },
  {
    "id": 10456,
    "traditional": "悠閒",
    "simplified": "悠闲",
    "reading": "yōuxián",
    "meaning": "leisurely, carefree, relaxed",
    "grade": 7
  },
  {
    "id": 10457,
    "traditional": "尤為",
    "simplified": "尤为",
    "reading": "yóuwéi",
    "meaning": "especially",
    "grade": 7
  },
  {
    "id": 10458,
    "traditional": "由此看來",
    "simplified": "由此看来",
    "reading": "yóucǐkànlái",
    "meaning": "It seems that it seems",
    "grade": 7
  },
  {
    "id": 10459,
    "traditional": "由來",
    "simplified": "由来",
    "reading": "yóulái",
    "meaning": "origin",
    "grade": 7
  },
  {
    "id": 10460,
    "traditional": "由此可見",
    "simplified": "由此可见",
    "reading": "yóucǐ-kějiàn",
    "meaning": "from this, it can be seen that...",
    "grade": 7
  },
  {
    "id": 10461,
    "traditional": "郵編",
    "simplified": "邮编",
    "reading": "yóubiān",
    "meaning": "postal code, zip code",
    "grade": 7
  },
  {
    "id": 10462,
    "traditional": "由衷",
    "simplified": "由衷",
    "reading": "yóuzhōng",
    "meaning": "heartfelt, sincere, unfeigned",
    "grade": 7
  },
  {
    "id": 10463,
    "traditional": "郵政",
    "simplified": "邮政",
    "reading": "yóuzhèng",
    "meaning": "postal service, postal",
    "grade": 7
  },
  {
    "id": 10464,
    "traditional": "猶豫不決",
    "simplified": "犹豫不决",
    "reading": "yóuyù-bùjué",
    "meaning": "hesitancy, indecision, to waver",
    "grade": 7
  },
  {
    "id": 10465,
    "traditional": "猶如",
    "simplified": "犹如",
    "reading": "yóurú",
    "meaning": "like, as, just as, be just as, be just like/as, be just like",
    "grade": 7
  },
  {
    "id": 10466,
    "traditional": "油畫",
    "simplified": "油画",
    "reading": "yóuhuà",
    "meaning": "canvas, painting, canvass, oil, oil painting, oil_painting, oil paint",
    "grade": 7
  },
  {
    "id": 10467,
    "traditional": "遊船",
    "simplified": "游船",
    "reading": "yóuchuán",
    "meaning": "Boat",
    "grade": 7
  },
  {
    "id": 10468,
    "traditional": "遊覽",
    "simplified": "游览",
    "reading": "yóulǎn",
    "meaning": "go sight-seeing, tour, visit",
    "grade": 7
  },
  {
    "id": 10469,
    "traditional": "友情",
    "simplified": "友情",
    "reading": "yǒuqíng",
    "meaning": "friendly feelings, friendship",
    "grade": 7
  },
  {
    "id": 10470,
    "traditional": "友人",
    "simplified": "友人",
    "reading": "yǒurén",
    "meaning": "friend",
    "grade": 7
  },
  {
    "id": 10471,
    "traditional": "友善",
    "simplified": "友善",
    "reading": "yǒushàn",
    "meaning": "friendly",
    "grade": 7
  },
  {
    "id": 10472,
    "traditional": "有待",
    "simplified": "有待",
    "reading": "yǒudài",
    "meaning": "be pending, await, remain (to be done), remain",
    "grade": 7
  },
  {
    "id": 10473,
    "traditional": "有的放矢",
    "simplified": "有的放矢",
    "reading": "yǒudì-fàngshǐ",
    "meaning": "Title",
    "grade": 7
  },
  {
    "id": 10474,
    "traditional": "有機",
    "simplified": "有机",
    "reading": "yǒujī",
    "meaning": "organic",
    "grade": 7
  },
  {
    "id": 10475,
    "traditional": "有口無心",
    "simplified": "有口无心",
    "reading": "yǒukǒu-wúxīn",
    "meaning": "to speak harshly but without any bad intent (idiom)",
    "grade": 7
  },
  {
    "id": 10476,
    "traditional": "有聲有色",
    "simplified": "有声有色",
    "reading": "yǒushēng-yǒusè",
    "meaning": "having sound and color (idiom), vivid, dazzling",
    "grade": 7
  },
  {
    "id": 10477,
    "traditional": "有兩下子",
    "simplified": "有两下子",
    "reading": "yǒu liǎngxiàzi",
    "meaning": "to have real skill, to know one's stuff",
    "grade": 7
  },
  {
    "id": 10478,
    "traditional": "有所不同",
    "simplified": "有所不同",
    "reading": "yǒusuǒbùtóng",
    "meaning": "to differ to some extent (idiom)",
    "grade": 7
  },
  {
    "id": 10479,
    "traditional": "有所",
    "simplified": "有所",
    "reading": "yǒusuǒ",
    "meaning": "somewhat, to some extent",
    "grade": 7
  },
  {
    "id": 10480,
    "traditional": "有望",
    "simplified": "有望",
    "reading": "yǒuwàng",
    "meaning": "hopeful, promising",
    "grade": 7
  },
  {
    "id": 10481,
    "traditional": "有效期",
    "simplified": "有效期",
    "reading": "yǒuxiàoqī",
    "meaning": "period of validity, sell-by date",
    "grade": 7
  },
  {
    "id": 10482,
    "traditional": "有幸",
    "simplified": "有幸",
    "reading": "yǒuxìng",
    "meaning": "fortunately",
    "grade": 7
  },
  {
    "id": 10483,
    "traditional": "有序",
    "simplified": "有序",
    "reading": "yǒuxù",
    "meaning": "regular, orderly, successive, in order",
    "grade": 7
  },
  {
    "id": 10484,
    "traditional": "有益",
    "simplified": "有益",
    "reading": "yǒuyì",
    "meaning": "profit, benefit, conduce, advantageous, be helpful, conducive, subserve, avail, suit",
    "grade": 7
  },
  {
    "id": 10485,
    "traditional": "有意",
    "simplified": "有意",
    "reading": "yǒuyì",
    "meaning": "have a mind to, be interested in",
    "grade": 7
  },
  {
    "id": 10486,
    "traditional": "有朝一日",
    "simplified": "有朝一日",
    "reading": "yǒuzhāo-yīrì",
    "meaning": "one day, sometime in the future",
    "grade": 7
  },
  {
    "id": 10487,
    "traditional": "幼稚",
    "simplified": "幼稚",
    "reading": "yòuzhì",
    "meaning": "young, childish, puerile",
    "grade": 7
  },
  {
    "id": 10488,
    "traditional": "有助於",
    "simplified": "有助于",
    "reading": "yǒuzhùyú",
    "meaning": "helpful to, lead, advantage, redound, be conducive, conduce, be conducive to, conduce to, sell, contribute to, subserve, avail, contribute",
    "grade": 7
  },
  {
    "id": 10489,
    "traditional": "誘餌",
    "simplified": "诱饵",
    "reading": "yòuěr",
    "meaning": "bait",
    "grade": 7
  },
  {
    "id": 10490,
    "traditional": "誘惑",
    "simplified": "诱惑",
    "reading": "yòuhuò",
    "meaning": "tempt, seduce, lure",
    "grade": 7
  },
  {
    "id": 10491,
    "traditional": "誘發",
    "simplified": "诱发",
    "reading": "yòufā",
    "meaning": "to induce, to cause, to elicit, to trigger",
    "grade": 7
  },
  {
    "id": 10492,
    "traditional": "誘人",
    "simplified": "诱人",
    "reading": "yòurén",
    "meaning": "attractive, alluring, captivating, to attract, to captivate",
    "grade": 7
  },
  {
    "id": 10493,
    "traditional": "餘地",
    "simplified": "余地",
    "reading": "yúdì",
    "meaning": "margin, leeway",
    "grade": 7
  },
  {
    "id": 10494,
    "traditional": "餘額",
    "simplified": "余额",
    "reading": "yúé",
    "meaning": "vacancies yet to be filled, remains, balance, remaining sum",
    "grade": 7
  },
  {
    "id": 10495,
    "traditional": "余",
    "simplified": "余",
    "reading": "yú",
    "meaning": "det.: odd, plus",
    "grade": 7
  },
  {
    "id": 10496,
    "traditional": "漁船",
    "simplified": "渔船",
    "reading": "yúchuán",
    "meaning": "fisherman, fishermen, fisherboat, fisher, fishing boat, wherry",
    "grade": 7
  },
  {
    "id": 10497,
    "traditional": "漁民",
    "simplified": "渔民",
    "reading": "yúmín",
    "meaning": "fisherman, peterman, fisherfolk",
    "grade": 7
  },
  {
    "id": 10498,
    "traditional": "逾期",
    "simplified": "逾期",
    "reading": "yú qī",
    "meaning": "to be overdue, to fail to meet a deadline, to be behind in doing sth",
    "grade": 7
  },
  {
    "id": 10499,
    "traditional": "愚蠢",
    "simplified": "愚蠢",
    "reading": "yúchǔn",
    "meaning": "silly, stupid",
    "grade": 7
  },
  {
    "id": 10500,
    "traditional": "愚公移山",
    "simplified": "愚公移山",
    "reading": "Yúgōng-yíshān",
    "meaning": "the old man moves mountains (idiom), fig. where there's a will, there's a way",
    "grade": 7
  },
  {
    "id": 10501,
    "traditional": "輿論",
    "simplified": "舆论",
    "reading": "yúlùn",
    "meaning": "sense, vox_populi, popular opinion, public opinion, opinion, public_opinion, consensus, vox populi",
    "grade": 7
  },
  {
    "id": 10502,
    "traditional": "與此同時",
    "simplified": "与此同时",
    "reading": "yǔcǐtóngshí",
    "meaning": "at the same time, meanwhile",
    "grade": 7
  },
  {
    "id": 10503,
    "traditional": "與其",
    "simplified": "与其",
    "reading": "yǔqí",
    "meaning": "rather than",
    "grade": 7
  },
  {
    "id": 10504,
    "traditional": "與否",
    "simplified": "与否",
    "reading": "yǔfǒu",
    "meaning": "particle: meaning \"whether or not\"",
    "grade": 7
  },
  {
    "id": 10505,
    "traditional": "與眾不同",
    "simplified": "与众不同",
    "reading": "yǔzhòng-bùtóng",
    "meaning": "to stand out from the masses (idiom)",
    "grade": 7
  },
  {
    "id": 10506,
    "traditional": "與日俱增",
    "simplified": "与日俱增",
    "reading": "yǔrì-jùzēng",
    "meaning": "to increase steadily, to grow with each passing day",
    "grade": 7
  },
  {
    "id": 10507,
    "traditional": "與時俱進",
    "simplified": "与时俱进",
    "reading": "yǔshí-jùjìn",
    "meaning": "abreast of modern developments, to keep up with the times, progressive, timely",
    "grade": 7
  },
  {
    "id": 10508,
    "traditional": "宇宙",
    "simplified": "宇宙",
    "reading": "yǔzhòu",
    "meaning": "all, cosmic, existence, world, creation, system, macrocosm, metagalactic, nature, cosmos, universal, universe",
    "grade": 7
  },
  {
    "id": 10509,
    "traditional": "予以",
    "simplified": "予以",
    "reading": "yǔyǐ",
    "meaning": "give",
    "grade": 7
  },
  {
    "id": 10510,
    "traditional": "浴室",
    "simplified": "浴室",
    "reading": "yùshì",
    "meaning": "W.C., bathroom, shower room, balneary, water closet, loo, toilet, closet, bath",
    "grade": 7
  },
  {
    "id": 10511,
    "traditional": "預定",
    "simplified": "预定",
    "reading": "yùdìng",
    "meaning": "predetermination, predetermine, forespeak, preordain, engage, budget, reserve, phase, slate, fix in advance, predestine, state, preconcert, prearrange, take, schedule, destine, bespeak, foreordain, predestinate",
    "grade": 7
  },
  {
    "id": 10512,
    "traditional": "預感",
    "simplified": "预感",
    "reading": "yùgǎn",
    "meaning": "to have a premonition, premonition",
    "grade": 7
  },
  {
    "id": 10513,
    "traditional": "語氣",
    "simplified": "语气",
    "reading": "yǔqì",
    "meaning": "mood, tenor, strain, modality, manner of speaking, tone, mode, tone of voice, note",
    "grade": 7
  },
  {
    "id": 10514,
    "traditional": "預告",
    "simplified": "预告",
    "reading": "yùgào",
    "meaning": "to forecast, to predict, advance notice",
    "grade": 7
  },
  {
    "id": 10515,
    "traditional": "預見",
    "simplified": "预见",
    "reading": "yùjiàn",
    "meaning": "envision, predict, foresee, previse, foreknow, prefigure, envisage",
    "grade": 7
  },
  {
    "id": 10516,
    "traditional": "預料",
    "simplified": "预料",
    "reading": "yùliào",
    "meaning": "expect, predict, anticipate",
    "grade": 7
  },
  {
    "id": 10517,
    "traditional": "預示",
    "simplified": "预示",
    "reading": "yùshì",
    "meaning": "to indicate, to foretell, to forebode, to betoken",
    "grade": 7
  },
  {
    "id": 10518,
    "traditional": "預賽",
    "simplified": "预赛",
    "reading": "yùsài",
    "meaning": "preliminary contest",
    "grade": 7
  },
  {
    "id": 10519,
    "traditional": "預售",
    "simplified": "预售",
    "reading": "yùshòu",
    "meaning": "sell in advance",
    "grade": 7
  },
  {
    "id": 10520,
    "traditional": "預算",
    "simplified": "预算",
    "reading": "yùsuàn",
    "meaning": "estimate, forethought, budget, estimation, budgetary",
    "grade": 7
  },
  {
    "id": 10521,
    "traditional": "預先",
    "simplified": "预先",
    "reading": "yùxiān",
    "meaning": "beforehand, in advance",
    "grade": 7
  },
  {
    "id": 10522,
    "traditional": "預言",
    "simplified": "预言",
    "reading": "yùyán",
    "meaning": "to predict, prophecy",
    "grade": 7
  },
  {
    "id": 10523,
    "traditional": "慾望",
    "simplified": "欲望",
    "reading": "yùwàng",
    "meaning": "appetence, indirect request, wish, appetite, stomach, concupiscence, desire, Kama, appetency, hunger, thirst, lech, orexis, rage, passion",
    "grade": 7
  },
  {
    "id": 10524,
    "traditional": "遇難",
    "simplified": "遇难",
    "reading": "yù nàn",
    "meaning": "to perish, to be killed",
    "grade": 7
  },
  {
    "id": 10525,
    "traditional": "遇險",
    "simplified": "遇险",
    "reading": "yù xiǎn",
    "meaning": "to get into difficulties, to meet with danger",
    "grade": 7
  },
  {
    "id": 10526,
    "traditional": "寓言",
    "simplified": "寓言",
    "reading": "yùyán",
    "meaning": "fable, CL:則|则[ze2]",
    "grade": 7
  },
  {
    "id": 10527,
    "traditional": "預兆",
    "simplified": "预兆",
    "reading": "yùzhào",
    "meaning": "omen, sign (of sth yet to occur), prior indication, to foreshadow",
    "grade": 7
  },
  {
    "id": 10528,
    "traditional": "遇上",
    "simplified": "遇上",
    "reading": "yùshàng",
    "meaning": "Encounter",
    "grade": 7
  },
  {
    "id": 10529,
    "traditional": "寓意",
    "simplified": "寓意",
    "reading": "yùyì",
    "meaning": "moral (of a story), lesson to be learned, implication, message, import, metaphorical meaning",
    "grade": 7
  },
  {
    "id": 10530,
    "traditional": "愈來愈",
    "simplified": "愈来愈",
    "reading": "yùláiyù",
    "meaning": "even more, more_and_more, more and more",
    "grade": 7
  },
  {
    "id": 10531,
    "traditional": "愈演愈烈",
    "simplified": "愈演愈烈",
    "reading": "yùyǎn-yùliè",
    "meaning": "ever more critical, problems get more and more intense",
    "grade": 7
  },
  {
    "id": 10532,
    "traditional": "癒合",
    "simplified": "愈合",
    "reading": "yùhé",
    "meaning": "to heal, to fuse",
    "grade": 7
  },
  {
    "id": 10533,
    "traditional": "淵源",
    "simplified": "渊源",
    "reading": "yuānyuán",
    "meaning": "origin, source",
    "grade": 7
  },
  {
    "id": 10534,
    "traditional": "冤",
    "simplified": "冤",
    "reading": "yuān",
    "meaning": "injustice, grievance, wrong, old variant of 冤[yuan1]",
    "grade": 7
  },
  {
    "id": 10535,
    "traditional": "元老",
    "simplified": "元老",
    "reading": "yuánlǎo",
    "meaning": "senior figure, elder, doyen",
    "grade": 7
  },
  {
    "id": 10536,
    "traditional": "元首",
    "simplified": "元首",
    "reading": "yuánshǒu",
    "meaning": "head of state",
    "grade": 7
  },
  {
    "id": 10537,
    "traditional": "元宵節",
    "simplified": "元宵节",
    "reading": "Yuánxiāojié",
    "meaning": "Lantern Festival, the final event of the Spring Festival 春節|春节, on 15th of first month of the lunar calendar",
    "grade": 7
  },
  {
    "id": 10538,
    "traditional": "原本",
    "simplified": "原本",
    "reading": "yuánběn",
    "meaning": "originally, formerly",
    "grade": 7
  },
  {
    "id": 10539,
    "traditional": "冤枉",
    "simplified": "冤枉",
    "reading": "yuānwang",
    "meaning": "to accuse wrongly, to treat unjustly, injustice, wronged, not worthwhile",
    "grade": 7
  },
  {
    "id": 10540,
    "traditional": "原材料",
    "simplified": "原材料",
    "reading": "yuáncáiliào",
    "meaning": "raw materials, unprocessed materials",
    "grade": 7
  },
  {
    "id": 10541,
    "traditional": "原地",
    "simplified": "原地",
    "reading": "yuándì",
    "meaning": "(in) the original place, the place where one currently is, place of origin, local (product)",
    "grade": 7
  },
  {
    "id": 10542,
    "traditional": "原創",
    "simplified": "原创",
    "reading": "yuánchuàng",
    "meaning": "originality, creativity, innovation",
    "grade": 7
  },
  {
    "id": 10543,
    "traditional": "原型",
    "simplified": "原型",
    "reading": "yuánxíng",
    "meaning": "model, prototype, archetype",
    "grade": 7
  },
  {
    "id": 10544,
    "traditional": "原裝",
    "simplified": "原装",
    "reading": "yuánzhuāng",
    "meaning": "genuine, intact in original packaging (not locally assembled and packaged)",
    "grade": 7
  },
  {
    "id": 10545,
    "traditional": "原汁原味",
    "simplified": "原汁原味",
    "reading": "yuánzhī-yuánwèi",
    "meaning": "original, authentic",
    "grade": 7
  },
  {
    "id": 10546,
    "traditional": "圓形",
    "simplified": "圆形",
    "reading": "yuánxíng",
    "meaning": "round, circular",
    "grade": 7
  },
  {
    "id": 10547,
    "traditional": "緣分",
    "simplified": "缘分",
    "reading": "yuánfen",
    "meaning": "fate or chance that brings people together, predestined affinity or relationship, (Budd.) destiny",
    "grade": 7
  },
  {
    "id": 10548,
    "traditional": "源泉",
    "simplified": "源泉",
    "reading": "yuánquán",
    "meaning": "fountainhead, well-spring, water source, fig. origin",
    "grade": 7
  },
  {
    "id": 10549,
    "traditional": "源於",
    "simplified": "源于",
    "reading": "yuányú",
    "meaning": "has its origins in",
    "grade": 7
  },
  {
    "id": 10550,
    "traditional": "源頭",
    "simplified": "源头",
    "reading": "yuántóu",
    "meaning": "source, fountainhead",
    "grade": 7
  },
  {
    "id": 10551,
    "traditional": "源源不斷",
    "simplified": "源源不断",
    "reading": "yuányuánbùduàn",
    "meaning": "a steady flow (idiom), an unending stream",
    "grade": 7
  },
  {
    "id": 10552,
    "traditional": "遠程",
    "simplified": "远程",
    "reading": "yuǎnchéng",
    "meaning": "remote, long distance, long range",
    "grade": 7
  },
  {
    "id": 10553,
    "traditional": "遠見",
    "simplified": "远见",
    "reading": "yuǎnjiàn",
    "meaning": "vision",
    "grade": 7
  },
  {
    "id": 10554,
    "traditional": "遠近聞名",
    "simplified": "远近闻名",
    "reading": "yuǎnjìnwénmíng",
    "meaning": "Far-known",
    "grade": 7
  },
  {
    "id": 10555,
    "traditional": "怨恨",
    "simplified": "怨恨",
    "reading": "yuànhèn",
    "meaning": "to resent, to harbor a grudge against, to loathe, resentment, rancor",
    "grade": 7
  },
  {
    "id": 10556,
    "traditional": "怨言",
    "simplified": "怨言",
    "reading": "yuànyán",
    "meaning": "complaint",
    "grade": 7
  },
  {
    "id": 10557,
    "traditional": "怨氣",
    "simplified": "怨气",
    "reading": "yuànqì",
    "meaning": "grievance, resentment, complaint",
    "grade": 7
  },
  {
    "id": 10558,
    "traditional": "院士",
    "simplified": "院士",
    "reading": "yuànshì",
    "meaning": "academician",
    "grade": 7
  },
  {
    "id": 10559,
    "traditional": "約定俗成",
    "simplified": "约定俗成",
    "reading": "yuēdìng-súchéng",
    "meaning": "established by popular usage (idiom), common usage agreement, customary convention",
    "grade": 7
  },
  {
    "id": 10560,
    "traditional": "月初",
    "simplified": "月初",
    "reading": "yuèchū",
    "meaning": "start of month, early in the month",
    "grade": 7
  },
  {
    "id": 10561,
    "traditional": "曰",
    "simplified": "曰",
    "reading": "yuē",
    "meaning": "say, speak",
    "grade": 7
  },
  {
    "id": 10562,
    "traditional": "月票",
    "simplified": "月票",
    "reading": "yuèpiào",
    "meaning": "monthly ticket",
    "grade": 7
  },
  {
    "id": 10563,
    "traditional": "樂器",
    "simplified": "乐器",
    "reading": "yuèqì",
    "meaning": "chime, axe, musical instrument, instrument",
    "grade": 7
  },
  {
    "id": 10564,
    "traditional": "岳父",
    "simplified": "岳父",
    "reading": "yuèfù",
    "meaning": "wife's father, father-in-law",
    "grade": 7
  },
  {
    "id": 10565,
    "traditional": "岳母",
    "simplified": "岳母",
    "reading": "yuèmǔ",
    "meaning": "wife's mother, mother-in-law",
    "grade": 7
  },
  {
    "id": 10566,
    "traditional": "悅耳",
    "simplified": "悦耳",
    "reading": "yuèěr",
    "meaning": "sweet-sounding, beautiful (of sound)",
    "grade": 7
  },
  {
    "id": 10567,
    "traditional": "越發",
    "simplified": "越发",
    "reading": "yuèfā",
    "meaning": "increasingly, more and more, ever more, all the more",
    "grade": 7
  },
  {
    "id": 10568,
    "traditional": "閱歷",
    "simplified": "阅历",
    "reading": "yuèlì",
    "meaning": "to experience, experience",
    "grade": 7
  },
  {
    "id": 10569,
    "traditional": "越過",
    "simplified": "越过",
    "reading": "yuè guò",
    "meaning": "to cross over, to transcend, to cover distance, to overcome, to rise above",
    "grade": 7
  },
  {
    "id": 10570,
    "traditional": "暈倒",
    "simplified": "晕倒",
    "reading": "yūndǎo",
    "meaning": "to faint, to swoon, to black out, to become unconscious",
    "grade": 7
  },
  {
    "id": 10571,
    "traditional": "孕婦",
    "simplified": "孕妇",
    "reading": "yùnfù",
    "meaning": "pregnant woman",
    "grade": 7
  },
  {
    "id": 10572,
    "traditional": "隕石",
    "simplified": "陨石",
    "reading": "yǔnshí",
    "meaning": "meteorite, meteor, aerolite, stony meteorite",
    "grade": 7
  },
  {
    "id": 10573,
    "traditional": "孕育",
    "simplified": "孕育",
    "reading": "yùnyù",
    "meaning": "to be pregnant, to produce offspring, to nurture (a development, school of thought, artwork etc), fig. replete with (culture etc)",
    "grade": 7
  },
  {
    "id": 10574,
    "traditional": "運送",
    "simplified": "运送",
    "reading": "yùnsòng",
    "meaning": "transport, convey",
    "grade": 7
  },
  {
    "id": 10575,
    "traditional": "運河",
    "simplified": "运河",
    "reading": "yùnhé",
    "meaning": "canal",
    "grade": 7
  },
  {
    "id": 10576,
    "traditional": "運營",
    "simplified": "运营",
    "reading": "yùnyíng",
    "meaning": "to be in operation, to do business, (of train, bus etc) to be in service, operation, service",
    "grade": 7
  },
  {
    "id": 10577,
    "traditional": "運轉",
    "simplified": "运转",
    "reading": "yùnzhuǎn",
    "meaning": "revolve, turn round, work, operate",
    "grade": 7
  },
  {
    "id": 10578,
    "traditional": "韻味",
    "simplified": "韵味",
    "reading": "yùnwèi",
    "meaning": "implicit charm in rhyme or sound, hinted appeal, interest",
    "grade": 7
  },
  {
    "id": 10579,
    "traditional": "醞釀",
    "simplified": "酝酿",
    "reading": "yùnniàng",
    "meaning": "(of alcohol) to ferment, (of a crisis) to be brewing, to mull over (an issue), to hold exploratory discussions",
    "grade": 7
  },
  {
    "id": 10580,
    "traditional": "蘊涵",
    "simplified": "蕴涵",
    "reading": "yùnhán",
    "meaning": "to contain, to accumulate, to embrace, implicit condition, implication, entailment",
    "grade": 7
  },
  {
    "id": 10581,
    "traditional": "蘊藏",
    "simplified": "蕴藏",
    "reading": "yùncáng",
    "meaning": "to hold in store, to contain (untapped reserves etc)",
    "grade": 7
  },
  {
    "id": 10582,
    "traditional": "雜亂無章",
    "simplified": "杂乱无章",
    "reading": "záluàn-wúzhāng",
    "meaning": "disordered and in a mess (idiom), all mixed up and chaotic",
    "grade": 7
  },
  {
    "id": 10583,
    "traditional": "雜技",
    "simplified": "杂技",
    "reading": "zájì",
    "meaning": "acrobatics, CL:場|场[chang3]",
    "grade": 7
  },
  {
    "id": 10584,
    "traditional": "雜交",
    "simplified": "杂交",
    "reading": "zájiāo",
    "meaning": "to hybridize, to crossbreed, promiscuity",
    "grade": 7
  },
  {
    "id": 10585,
    "traditional": "砸",
    "simplified": "砸",
    "reading": "zá",
    "meaning": "tamp, bungle, failure, fall through, smash, pound, fail, break, be bungled, ram",
    "grade": 7
  },
  {
    "id": 10586,
    "traditional": "栽",
    "simplified": "栽",
    "reading": "zāi",
    "meaning": "to grow, to plant, to insert, to force on sb, to fall head first",
    "grade": 7
  },
  {
    "id": 10587,
    "traditional": "栽培",
    "simplified": "栽培",
    "reading": "zāipéi",
    "meaning": "cultivate, grow, foster, train, educate, give/receive patronage",
    "grade": 7
  },
  {
    "id": 10588,
    "traditional": "再度",
    "simplified": "再度",
    "reading": "zàidù",
    "meaning": "once again, anew, eftsoons, second time, again, a second time, once more/again, once more",
    "grade": 7
  },
  {
    "id": 10589,
    "traditional": "宰",
    "simplified": "宰",
    "reading": "zǎi",
    "meaning": "to slaughter livestock, to govern or rule, to cheat customers, imperial official in dynastic China",
    "grade": 7
  },
  {
    "id": 10590,
    "traditional": "再現",
    "simplified": "再现",
    "reading": "zàixiàn",
    "meaning": "to recreate, to reconstruct (a historical relic)",
    "grade": 7
  },
  {
    "id": 10591,
    "traditional": "在線",
    "simplified": "在线",
    "reading": "zàixiàn",
    "meaning": "online",
    "grade": 7
  },
  {
    "id": 10592,
    "traditional": "在意",
    "simplified": "在意",
    "reading": "zài yì",
    "meaning": "take notice of, care about, pay attention to, take ... to heart, care, mind",
    "grade": 7
  },
  {
    "id": 10593,
    "traditional": "載體",
    "simplified": "载体",
    "reading": "zàitǐ",
    "meaning": "carrier (chemistry), vector (epidemiology), vehicle or medium",
    "grade": 7
  },
  {
    "id": 10594,
    "traditional": "在職",
    "simplified": "在职",
    "reading": "zàizhí",
    "meaning": "be on the job",
    "grade": 7
  },
  {
    "id": 10595,
    "traditional": "暫",
    "simplified": "暂",
    "reading": "zàn",
    "meaning": "to scurry, variant of 暫|暂[zan4]",
    "grade": 7
  },
  {
    "id": 10596,
    "traditional": "攢",
    "simplified": "攒",
    "reading": "zǎn",
    "meaning": "to bring together, to collect, to hoard, to accumulate, to save",
    "grade": 7
  },
  {
    "id": 10597,
    "traditional": "贊不絕口",
    "simplified": "赞不绝口",
    "reading": "zànbùjuékǒu",
    "meaning": "to praise without cease (idiom), praise sb to high heaven",
    "grade": 7
  },
  {
    "id": 10598,
    "traditional": "贊嘆",
    "simplified": "赞叹",
    "reading": "zàntàn",
    "meaning": "to exclaim in admiration",
    "grade": 7
  },
  {
    "id": 10599,
    "traditional": "贊嘆不已",
    "simplified": "赞叹不已",
    "reading": "Zàntànbùyǐ",
    "meaning": "to be full of praise (idiom)",
    "grade": 7
  },
  {
    "id": 10600,
    "traditional": "贊同",
    "simplified": "赞同",
    "reading": "zàntóng",
    "meaning": "approve of, endorse",
    "grade": 7
  },
  {
    "id": 10601,
    "traditional": "贊許",
    "simplified": "赞许",
    "reading": "zànxǔ",
    "meaning": "to praise, to laud",
    "grade": 7
  },
  {
    "id": 10602,
    "traditional": "贊美",
    "simplified": "赞美",
    "reading": "zànměi",
    "meaning": "to admire, to praise, to eulogize",
    "grade": 7
  },
  {
    "id": 10603,
    "traditional": "贊揚",
    "simplified": "赞扬",
    "reading": "zànyáng",
    "meaning": "to praise, to approve of, to show approval",
    "grade": 7
  },
  {
    "id": 10604,
    "traditional": "葬禮",
    "simplified": "葬礼",
    "reading": "zànglǐ",
    "meaning": "burial, funeral",
    "grade": 7
  },
  {
    "id": 10605,
    "traditional": "遭殃",
    "simplified": "遭殃",
    "reading": "zāo yāng",
    "meaning": "to suffer a calamity",
    "grade": 7
  },
  {
    "id": 10606,
    "traditional": "鑿",
    "simplified": "凿",
    "reading": "záo",
    "meaning": "chisel, to bore a hole, to chisel or dig, certain, authentic, irrefutable, also pr. [zuo4]",
    "grade": 7
  },
  {
    "id": 10607,
    "traditional": "葬",
    "simplified": "葬",
    "reading": "zàng",
    "meaning": "to bury (the dead), to inter",
    "grade": 7
  },
  {
    "id": 10608,
    "traditional": "早日",
    "simplified": "早日",
    "reading": "zǎorì",
    "meaning": "at an early date, early, soon",
    "grade": 7
  },
  {
    "id": 10609,
    "traditional": "早年",
    "simplified": "早年",
    "reading": "zǎonián",
    "meaning": "early years, adolescence",
    "grade": 7
  },
  {
    "id": 10610,
    "traditional": "棗",
    "simplified": "枣",
    "reading": "zǎo",
    "meaning": "jujube, dates",
    "grade": 7
  },
  {
    "id": 10611,
    "traditional": "造假",
    "simplified": "造假",
    "reading": "zàojiǎ",
    "meaning": "to counterfeit, to pass off a fake as genuine",
    "grade": 7
  },
  {
    "id": 10612,
    "traditional": "造價",
    "simplified": "造价",
    "reading": "zàojià",
    "meaning": "construction cost",
    "grade": 7
  },
  {
    "id": 10613,
    "traditional": "造福",
    "simplified": "造福",
    "reading": "zàofú",
    "meaning": "to benefit (e.g. the people)",
    "grade": 7
  },
  {
    "id": 10614,
    "traditional": "造就",
    "simplified": "造就",
    "reading": "zàojiù",
    "meaning": "bring up, train",
    "grade": 7
  },
  {
    "id": 10615,
    "traditional": "噪聲",
    "simplified": "噪声",
    "reading": "zàoshēng",
    "meaning": "noise",
    "grade": 7
  },
  {
    "id": 10616,
    "traditional": "造紙術",
    "simplified": "造纸术",
    "reading": "zàozhǐshù",
    "meaning": "Papermaking",
    "grade": 7
  },
  {
    "id": 10617,
    "traditional": "則",
    "simplified": "则",
    "reading": "zé",
    "meaning": "then, in that case",
    "grade": 7
  },
  {
    "id": 10618,
    "traditional": "噪音",
    "simplified": "噪音",
    "reading": "zàoyīn",
    "meaning": "dissonance, row, rumour, noise, discord, squeal, discordance, mush, racket",
    "grade": 7
  },
  {
    "id": 10619,
    "traditional": "責備",
    "simplified": "责备",
    "reading": "zébèi",
    "meaning": "to blame, to criticize, condemnation, reproach",
    "grade": 7
  },
  {
    "id": 10620,
    "traditional": "責怪",
    "simplified": "责怪",
    "reading": "zéguài",
    "meaning": "to blame, to rebuke",
    "grade": 7
  },
  {
    "id": 10621,
    "traditional": "增收",
    "simplified": "增收",
    "reading": "zēngshōu",
    "meaning": "Increasing",
    "grade": 7
  },
  {
    "id": 10622,
    "traditional": "賊",
    "simplified": "贼",
    "reading": "zéi",
    "meaning": "thief, traitor, wily, deceitful, evil, extremely",
    "grade": 7
  },
  {
    "id": 10623,
    "traditional": "扎根",
    "simplified": "扎根",
    "reading": "zhā gēn",
    "meaning": "to take root",
    "grade": 7
  },
  {
    "id": 10624,
    "traditional": "增添",
    "simplified": "增添",
    "reading": "zēngtiān",
    "meaning": "increase, accession, throw in, point_up, add, subjoin, addition, lend",
    "grade": 7
  },
  {
    "id": 10625,
    "traditional": "閘",
    "simplified": "闸",
    "reading": "zhá",
    "meaning": "gear, brake, sluice, lock (on waterway), electric switch or circuit breaker",
    "grade": 7
  },
  {
    "id": 10626,
    "traditional": "渣子",
    "simplified": "渣子",
    "reading": "zhāzi",
    "meaning": "Slag",
    "grade": 7
  },
  {
    "id": 10627,
    "traditional": "眨眼",
    "simplified": "眨眼",
    "reading": "zhǎ yǎn",
    "meaning": "to blink, to wink, in the twinkling of an eye",
    "grade": 7
  },
  {
    "id": 10628,
    "traditional": "炸",
    "simplified": "炸",
    "reading": "zhà",
    "meaning": "to deep fry, to explode",
    "grade": 7
  },
  {
    "id": 10629,
    "traditional": "詐騙",
    "simplified": "诈骗",
    "reading": "zhàpiàn",
    "meaning": "to defraud, to swindle, to blackmail",
    "grade": 7
  },
  {
    "id": 10630,
    "traditional": "榨",
    "simplified": "榨",
    "reading": "zhà",
    "meaning": "to press, to extract (juice), device for extracting juice, oils etc",
    "grade": 7
  },
  {
    "id": 10631,
    "traditional": "窄",
    "simplified": "窄",
    "reading": "zhǎi",
    "meaning": "narrow, narrow-minded, badly off",
    "grade": 7
  },
  {
    "id": 10632,
    "traditional": "債務",
    "simplified": "债务",
    "reading": "zhàiwù",
    "meaning": "debt, liabilities",
    "grade": 7
  },
  {
    "id": 10633,
    "traditional": "沾",
    "simplified": "沾",
    "reading": "zhān",
    "meaning": "moisten, daub, get ... out of association, soak, partake, profit from, touch, be stained with",
    "grade": 7
  },
  {
    "id": 10634,
    "traditional": "占卜",
    "simplified": "占卜",
    "reading": "zhānbǔ",
    "meaning": "to divine",
    "grade": 7
  },
  {
    "id": 10635,
    "traditional": "沾光",
    "simplified": "沾光",
    "reading": "zhān guāng",
    "meaning": "to bask in the light, fig. to benefit from association with sb or sth, reflected glory",
    "grade": 7
  },
  {
    "id": 10636,
    "traditional": "瞻仰",
    "simplified": "瞻仰",
    "reading": "zhānyǎng",
    "meaning": "to revere, to admire",
    "grade": 7
  },
  {
    "id": 10637,
    "traditional": "粘",
    "simplified": "粘",
    "reading": "zhān",
    "meaning": "glutinous, sticky, to stick, to adhere, variant of 黏[nian2], to glue, to stick, to paste",
    "grade": 7
  },
  {
    "id": 10638,
    "traditional": "斬",
    "simplified": "斩",
    "reading": "zhǎn",
    "meaning": "to behead (as form of capital punishment), to chop",
    "grade": 7
  },
  {
    "id": 10639,
    "traditional": "斬草除根",
    "simplified": "斩草除根",
    "reading": "zhǎncǎo-chúgēn",
    "meaning": "to cut weeds and eliminate the roots (idiom), to destroy root and branch, to eliminate completely",
    "grade": 7
  },
  {
    "id": 10640,
    "traditional": "展出",
    "simplified": "展出",
    "reading": "zhǎnchū",
    "meaning": "exhibit",
    "grade": 7
  },
  {
    "id": 10641,
    "traditional": "盞",
    "simplified": "盏",
    "reading": "zhǎn",
    "meaning": "m.[general]",
    "grade": 7
  },
  {
    "id": 10642,
    "traditional": "展覽會",
    "simplified": "展览会",
    "reading": "zhǎnlǎnhuì",
    "meaning": "exhibition, show, CL:個|个[ge4]",
    "grade": 7
  },
  {
    "id": 10643,
    "traditional": "展望",
    "simplified": "展望",
    "reading": "zhǎnwàng",
    "meaning": "look into the distance/future",
    "grade": 7
  },
  {
    "id": 10644,
    "traditional": "占用",
    "simplified": "占用",
    "reading": "zhànyòng",
    "meaning": "occupy, take over",
    "grade": 7
  },
  {
    "id": 10645,
    "traditional": "嶄新",
    "simplified": "崭新",
    "reading": "zhǎnxīn",
    "meaning": "newly, bran-new, spick-and-span, spic-and-span, brand-new, spick and span, completely new",
    "grade": 7
  },
  {
    "id": 10646,
    "traditional": "站立",
    "simplified": "站立",
    "reading": "zhànlì",
    "meaning": "to stand, standing, on one's feet",
    "grade": 7
  },
  {
    "id": 10647,
    "traditional": "綻放",
    "simplified": "绽放",
    "reading": "zhànfàng",
    "meaning": "to blossom",
    "grade": 7
  },
  {
    "id": 10648,
    "traditional": "張燈結彩",
    "simplified": "张灯结彩",
    "reading": "zhāngdēng-jiécǎi",
    "meaning": "to be decorated with lanterns and colored banners (idiom)",
    "grade": 7
  },
  {
    "id": 10649,
    "traditional": "蘸",
    "simplified": "蘸",
    "reading": "zhàn",
    "meaning": "to dip in (ink, sauce etc)",
    "grade": 7
  },
  {
    "id": 10650,
    "traditional": "張貼",
    "simplified": "张贴",
    "reading": "zhāngtiē",
    "meaning": "to post (a notice), to advertise",
    "grade": 7
  },
  {
    "id": 10651,
    "traditional": "張揚",
    "simplified": "张扬",
    "reading": "zhāngyáng",
    "meaning": "Zhang Yang (1967-), PRC film director and screenwriter, to display ostentatiously, to bring out into the open, to make public, to spread around, flamboyant, brash",
    "grade": 7
  },
  {
    "id": 10652,
    "traditional": "長相",
    "simplified": "长相",
    "reading": "zhǎngxiàng",
    "meaning": "appearance, looks, profile, countenance",
    "grade": 7
  },
  {
    "id": 10653,
    "traditional": "掌管",
    "simplified": "掌管",
    "reading": "zhǎngguǎn",
    "meaning": "in charge of, to control",
    "grade": 7
  },
  {
    "id": 10654,
    "traditional": "帳篷",
    "simplified": "帐篷",
    "reading": "zhàngpeng",
    "meaning": "tent, CL:頂|顶[ding3],座[zuo4]",
    "grade": 7
  },
  {
    "id": 10655,
    "traditional": "長輩",
    "simplified": "长辈",
    "reading": "zhǎngbèi",
    "meaning": "seniority, better, elder, superior, senior, elder member of family, eldership, elder generation, superordinate, higher-up",
    "grade": 7
  },
  {
    "id": 10656,
    "traditional": "帳子",
    "simplified": "帐子",
    "reading": "zhàngzi",
    "meaning": "mosquito net, CL:頂|顶[ding3]",
    "grade": 7
  },
  {
    "id": 10657,
    "traditional": "賬單",
    "simplified": "账单",
    "reading": "zhàngdān",
    "meaning": "bill",
    "grade": 7
  },
  {
    "id": 10658,
    "traditional": "脹",
    "simplified": "胀",
    "reading": "zhàng",
    "meaning": "dropsical, swollen, to swell, to be bloated",
    "grade": 7
  },
  {
    "id": 10659,
    "traditional": "賬號",
    "simplified": "账号",
    "reading": "zhànghào",
    "meaning": "account, username",
    "grade": 7
  },
  {
    "id": 10660,
    "traditional": "招標",
    "simplified": "招标",
    "reading": "zhāo biāo",
    "meaning": "invite tenders/bids",
    "grade": 7
  },
  {
    "id": 10661,
    "traditional": "招待",
    "simplified": "招待",
    "reading": "zhāodài",
    "meaning": "receive (guests), serve (customers)",
    "grade": 7
  },
  {
    "id": 10662,
    "traditional": "招待會",
    "simplified": "招待会",
    "reading": "zhāodàihuì",
    "meaning": "reception, CL:個|个[ge4],次[ci4]",
    "grade": 7
  },
  {
    "id": 10663,
    "traditional": "招募",
    "simplified": "招募",
    "reading": "zhāomù",
    "meaning": "recruit, enlist",
    "grade": 7
  },
  {
    "id": 10664,
    "traditional": "招攬",
    "simplified": "招揽",
    "reading": "zhāolǎn",
    "meaning": "to attract (customers), to drum up (trade)",
    "grade": 7
  },
  {
    "id": 10665,
    "traditional": "招牌",
    "simplified": "招牌",
    "reading": "zhāopai",
    "meaning": "plate, sign, shop sign, fascia, signboard, placard, facia, nameboard",
    "grade": 7
  },
  {
    "id": 10666,
    "traditional": "朝氣蓬勃",
    "simplified": "朝气蓬勃",
    "reading": "zhāoqì-péngbó",
    "meaning": "full of youthful energy (idiom), vigorous, energetic, a bright spark",
    "grade": 7
  },
  {
    "id": 10667,
    "traditional": "招數",
    "simplified": "招数",
    "reading": "zhāoshù",
    "meaning": "move (in chess, on stage, in martial arts), gambit, trick, scheme, movement, same as 著數|着数[zhao1 shu4]",
    "grade": 7
  },
  {
    "id": 10668,
    "traditional": "朝三暮四",
    "simplified": "朝三暮四",
    "reading": "zhāosān-mùsì",
    "meaning": "lit. say three in the morning but four in the evening (idiom), to change sth that is already settled upon, indecisive, to blow hot and cold",
    "grade": 7
  },
  {
    "id": 10669,
    "traditional": "朝夕相處",
    "simplified": "朝夕相处",
    "reading": "zhāoxīxiāngchǔ",
    "meaning": "Get along the eve",
    "grade": 7
  },
  {
    "id": 10670,
    "traditional": "招收",
    "simplified": "招收",
    "reading": "zhāoshōu",
    "meaning": "recruit, take in",
    "grade": 7
  },
  {
    "id": 10671,
    "traditional": "著迷",
    "simplified": "着迷",
    "reading": "zháo mí",
    "meaning": "to be fascinated, to be captivated",
    "grade": 7
  },
  {
    "id": 10672,
    "traditional": "沼澤",
    "simplified": "沼泽",
    "reading": "zhǎozé",
    "meaning": "marsh, swamp, wetlands, glade",
    "grade": 7
  },
  {
    "id": 10673,
    "traditional": "兆頭",
    "simplified": "兆头",
    "reading": "zhàotou",
    "meaning": "omen, portent, sign",
    "grade": 7
  },
  {
    "id": 10674,
    "traditional": "召集",
    "simplified": "召集",
    "reading": "zhàojí",
    "meaning": "call together, convene",
    "grade": 7
  },
  {
    "id": 10675,
    "traditional": "照辦",
    "simplified": "照办",
    "reading": "zhào bàn",
    "meaning": "to follow the rules, to do as instructed, to play by the book, to comply with a request",
    "grade": 7
  },
  {
    "id": 10676,
    "traditional": "照常",
    "simplified": "照常",
    "reading": "zhàocháng",
    "meaning": "as usual",
    "grade": 7
  },
  {
    "id": 10677,
    "traditional": "照例",
    "simplified": "照例",
    "reading": "zhàolì",
    "meaning": "as a rule, as usual, usually",
    "grade": 7
  },
  {
    "id": 10678,
    "traditional": "照料",
    "simplified": "照料",
    "reading": "zhàoliào",
    "meaning": "to tend, to take care of sb",
    "grade": 7
  },
  {
    "id": 10679,
    "traditional": "罩",
    "simplified": "罩",
    "reading": "zhào",
    "meaning": "cover, fish trap (basket), shade",
    "grade": 7
  },
  {
    "id": 10680,
    "traditional": "照明",
    "simplified": "照明",
    "reading": "zhàomíng",
    "meaning": "illuminate",
    "grade": 7
  },
  {
    "id": 10681,
    "traditional": "肇事",
    "simplified": "肇事",
    "reading": "zhàoshì",
    "meaning": "to cause trouble, to provoke a disturbance",
    "grade": 7
  },
  {
    "id": 10682,
    "traditional": "折騰",
    "simplified": "折腾",
    "reading": "zhēteng",
    "meaning": "to toss from side to side (e.g. sleeplessly), to repeat sth over and over again, to torment sb, to play crazy, to squander (time, money)",
    "grade": 7
  },
  {
    "id": 10683,
    "traditional": "遮",
    "simplified": "遮",
    "reading": "zhē",
    "meaning": "to cover up (a shortcoming), to screen off, to hide, to conceal",
    "grade": 7
  },
  {
    "id": 10684,
    "traditional": "遮蓋",
    "simplified": "遮盖",
    "reading": "zhēgài",
    "meaning": "to hide, to cover (one's tracks)",
    "grade": 7
  },
  {
    "id": 10685,
    "traditional": "折合",
    "simplified": "折合",
    "reading": "zhéhé",
    "meaning": "to convert into, to amount to, to be equivalent to",
    "grade": 7
  },
  {
    "id": 10686,
    "traditional": "折疊",
    "simplified": "折叠",
    "reading": "zhédié",
    "meaning": "to fold, collapsible, folding (bicycle, antenna, bed etc)",
    "grade": 7
  },
  {
    "id": 10687,
    "traditional": "折磨",
    "simplified": "折磨",
    "reading": "zhémo",
    "meaning": "to persecute, to torment",
    "grade": 7
  },
  {
    "id": 10688,
    "traditional": "折扣",
    "simplified": "折扣",
    "reading": "zhékòu",
    "meaning": "allowance, rebate, price reduction, discount, agio, reduction, deduction, percentage",
    "grade": 7
  },
  {
    "id": 10689,
    "traditional": "折射",
    "simplified": "折射",
    "reading": "zhéshè",
    "meaning": "to refract, refraction, to reflect (in the figurative sense: to show the nature of)",
    "grade": 7
  },
  {
    "id": 10690,
    "traditional": "這會兒",
    "simplified": "这会儿",
    "reading": "zhèhuìr5",
    "meaning": "(coll.) now, this moment, also pr. [zhe4 hui3 r5]",
    "grade": 7
  },
  {
    "id": 10691,
    "traditional": "這樣一來",
    "simplified": "这样一来",
    "reading": "zhèyàngyīlái",
    "meaning": "thus",
    "grade": 7
  },
  {
    "id": 10692,
    "traditional": "針鋒相對",
    "simplified": "针锋相对",
    "reading": "zhēnfēng-xiāngduì",
    "meaning": "to oppose each other with equal harshness (idiom), tit for tat, measure for measure",
    "grade": 7
  },
  {
    "id": 10693,
    "traditional": "偵察",
    "simplified": "侦察",
    "reading": "zhēnchá",
    "meaning": "to investigate a crime, to scout, to reconnoiter, reconnaissance, detection, a scout",
    "grade": 7
  },
  {
    "id": 10694,
    "traditional": "針灸",
    "simplified": "针灸",
    "reading": "zhēnjiǔ",
    "meaning": "acupuncture and moxibustion, to give or have acupuncture and moxibustion",
    "grade": 7
  },
  {
    "id": 10695,
    "traditional": "珍藏",
    "simplified": "珍藏",
    "reading": "zhēncáng",
    "meaning": "collection, to collect (valuables)",
    "grade": 7
  },
  {
    "id": 10696,
    "traditional": "珍重",
    "simplified": "珍重",
    "reading": "zhēnzhòng",
    "meaning": "precious, extremely valuable, (honorific) Please take good care of yourself!",
    "grade": 7
  },
  {
    "id": 10697,
    "traditional": "珍視",
    "simplified": "珍视",
    "reading": "zhēnshì",
    "meaning": "to place great importance on, to treasure",
    "grade": 7
  },
  {
    "id": 10698,
    "traditional": "真空",
    "simplified": "真空",
    "reading": "zhēnkōng",
    "meaning": "vacuum",
    "grade": 7
  },
  {
    "id": 10699,
    "traditional": "真假",
    "simplified": "真假",
    "reading": "zhēnjiǎ",
    "meaning": "genuine or fake, true or false",
    "grade": 7
  },
  {
    "id": 10700,
    "traditional": "真情",
    "simplified": "真情",
    "reading": "zhēnqíng",
    "meaning": "real situation, the truth",
    "grade": 7
  },
  {
    "id": 10701,
    "traditional": "真心",
    "simplified": "真心",
    "reading": "zhēnxīn",
    "meaning": "sincere, genuine, true",
    "grade": 7
  },
  {
    "id": 10702,
    "traditional": "真是的",
    "simplified": "真是的",
    "reading": "zhēnshide",
    "meaning": "Really! (interj. of annoyance or frustration)",
    "grade": 7
  },
  {
    "id": 10703,
    "traditional": "診所",
    "simplified": "诊所",
    "reading": "zhěnsuǒ",
    "meaning": "clinic",
    "grade": 7
  },
  {
    "id": 10704,
    "traditional": "真摯",
    "simplified": "真挚",
    "reading": "zhēnzhì",
    "meaning": "sincere, sincerity",
    "grade": 7
  },
  {
    "id": 10705,
    "traditional": "枕頭",
    "simplified": "枕头",
    "reading": "zhěntou",
    "meaning": "pillow",
    "grade": 7
  },
  {
    "id": 10706,
    "traditional": "陣營",
    "simplified": "阵营",
    "reading": "zhènyíng",
    "meaning": "group of people, camp, faction, sides in a dispute",
    "grade": 7
  },
  {
    "id": 10707,
    "traditional": "陣容",
    "simplified": "阵容",
    "reading": "zhènróng",
    "meaning": "troop arrangement, battle formation, lineup (of a sports team etc)",
    "grade": 7
  },
  {
    "id": 10708,
    "traditional": "振興",
    "simplified": "振兴",
    "reading": "zhènxīng",
    "meaning": "Zhengxing district of Dandong city 丹東市|丹东市[Dan1 dong1 shi4], Liaoning, to revive, to revitalize, to invigorate, to re-energize",
    "grade": 7
  },
  {
    "id": 10709,
    "traditional": "振作",
    "simplified": "振作",
    "reading": "zhènzuò",
    "meaning": "to bestir oneself, to pull oneself together, to cheer up, to uplift, to stimulate",
    "grade": 7
  },
  {
    "id": 10710,
    "traditional": "振奮",
    "simplified": "振奋",
    "reading": "zhènfèn",
    "meaning": "to stir oneself up, to raise one's spirits, to inspire",
    "grade": 7
  },
  {
    "id": 10711,
    "traditional": "震動",
    "simplified": "震动",
    "reading": "zhèndòng",
    "meaning": "shake, shock, vibrate",
    "grade": 7
  },
  {
    "id": 10712,
    "traditional": "震",
    "simplified": "震",
    "reading": "zhèn",
    "meaning": "to shake, to vibrate, to jolt, to quake, excited, shocked, one of the Eight Trigrams 八卦[ba1 gua4], symbolizing thunder, ☳",
    "grade": 7
  },
  {
    "id": 10713,
    "traditional": "震撼",
    "simplified": "震撼",
    "reading": "zhènhàn",
    "meaning": "shock, shake",
    "grade": 7
  },
  {
    "id": 10714,
    "traditional": "鎮定",
    "simplified": "镇定",
    "reading": "zhèndìng",
    "meaning": "calm, unperturbed, cool",
    "grade": 7
  },
  {
    "id": 10715,
    "traditional": "爭吵",
    "simplified": "争吵",
    "reading": "zhēngchǎo",
    "meaning": "quarrel, wrangle",
    "grade": 7
  },
  {
    "id": 10716,
    "traditional": "爭端",
    "simplified": "争端",
    "reading": "zhēngduān",
    "meaning": "dispute, controversy, conflict",
    "grade": 7
  },
  {
    "id": 10717,
    "traditional": "爭分奪秒",
    "simplified": "争分夺秒",
    "reading": "zhēngfēn-duómiǎo",
    "meaning": "lit. fight minutes, snatch seconds (idiom), a race against time, making every second count",
    "grade": 7
  },
  {
    "id": 10718,
    "traditional": "爭光",
    "simplified": "争光",
    "reading": "zhēng guāng",
    "meaning": "to win an honor, to strive to win a prize",
    "grade": 7
  },
  {
    "id": 10719,
    "traditional": "爭氣",
    "simplified": "争气",
    "reading": "zhēng qì",
    "meaning": "to work hard for sth, to resolve on improvement, determined not to fall short",
    "grade": 7
  },
  {
    "id": 10720,
    "traditional": "爭先恐後",
    "simplified": "争先恐后",
    "reading": "zhēngxiān-kǒnghòu",
    "meaning": "striving to be first and fearing to be last (idiom), outdoing one another",
    "grade": 7
  },
  {
    "id": 10721,
    "traditional": "征",
    "simplified": "征",
    "reading": "zhēng",
    "meaning": "levy, impose, collect, solicit, call-up, ask for, draft, enrollenrol",
    "grade": 7
  },
  {
    "id": 10722,
    "traditional": "爭執",
    "simplified": "争执",
    "reading": "zhēngzhí",
    "meaning": "to dispute, to disagree, to argue opinionatedly, to wrangle",
    "grade": 7
  },
  {
    "id": 10723,
    "traditional": "徵收",
    "simplified": "征收",
    "reading": "zhēngshōu",
    "meaning": "levy, collect",
    "grade": 7
  },
  {
    "id": 10724,
    "traditional": "徵集",
    "simplified": "征集",
    "reading": "zhēngjí",
    "meaning": "to collect, to recruit",
    "grade": 7
  },
  {
    "id": 10725,
    "traditional": "癥結",
    "simplified": "症结",
    "reading": "zhēngjié",
    "meaning": "hard lump in the abdomen (in Chinese medicine), crux of an issue, main point in an argument, sticking point, deadlock in negotiations",
    "grade": 7
  },
  {
    "id": 10726,
    "traditional": "睜",
    "simplified": "睁",
    "reading": "zhēng",
    "meaning": "open, open (the eyes)",
    "grade": 7
  },
  {
    "id": 10727,
    "traditional": "掙扎",
    "simplified": "挣扎",
    "reading": "zhēngzhá",
    "meaning": "pull, buffet, flounder, labour, scrabble, struggle, worry, twist, grapple, tug, baffle, touse, wallow, flounce, writhe, welter, agonize",
    "grade": 7
  },
  {
    "id": 10728,
    "traditional": "拯救",
    "simplified": "拯救",
    "reading": "zhěngjiù",
    "meaning": "spare, help, extricate, rescue, save, deliver",
    "grade": 7
  },
  {
    "id": 10729,
    "traditional": "蒸",
    "simplified": "蒸",
    "reading": "zhēng",
    "meaning": "to evaporate, (of cooking) to steam, torch made from hemp stalks or bamboo (old), finely chopped firewood (old)",
    "grade": 7
  },
  {
    "id": 10730,
    "traditional": "整合",
    "simplified": "整合",
    "reading": "zhěnghé",
    "meaning": "integrate",
    "grade": 7
  },
  {
    "id": 10731,
    "traditional": "整潔",
    "simplified": "整洁",
    "reading": "zhěngjié",
    "meaning": "neatly, tidy",
    "grade": 7
  },
  {
    "id": 10732,
    "traditional": "正面",
    "simplified": "正面",
    "reading": "zhèngmiàn",
    "meaning": "right side, head, head-on, face, front, the right side, facade, obverse/right side, the obverse side, frontispiece, frontal, frontage, obverse side, obverse",
    "grade": 7
  },
  {
    "id": 10733,
    "traditional": "正能量",
    "simplified": "正能量",
    "reading": "zhèngnéngliàng",
    "meaning": "positive energy",
    "grade": 7
  },
  {
    "id": 10734,
    "traditional": "正視",
    "simplified": "正视",
    "reading": "zhèngshì",
    "meaning": "face squarely, face, face up to, look squarely at, envisage",
    "grade": 7
  },
  {
    "id": 10735,
    "traditional": "正直",
    "simplified": "正直",
    "reading": "zhèngzhí",
    "meaning": "upright, upstanding, honest",
    "grade": 7
  },
  {
    "id": 10736,
    "traditional": "正宗",
    "simplified": "正宗",
    "reading": "zhèngzōng",
    "meaning": "orthodox school, fig. traditional, old school, authentic, genuine",
    "grade": 7
  },
  {
    "id": 10737,
    "traditional": "整數",
    "simplified": "整数",
    "reading": "zhěngshù",
    "meaning": "whole number, integer (math.), round figure",
    "grade": 7
  },
  {
    "id": 10738,
    "traditional": "鄭重",
    "simplified": "郑重",
    "reading": "zhèngzhòng",
    "meaning": "serious, solemn",
    "grade": 7
  },
  {
    "id": 10739,
    "traditional": "之",
    "simplified": "之",
    "reading": "zhī",
    "meaning": "it, him, her, this",
    "grade": 7
  },
  {
    "id": 10740,
    "traditional": "證人",
    "simplified": "证人",
    "reading": "zhèngren",
    "meaning": "witness",
    "grade": 7
  },
  {
    "id": 10741,
    "traditional": "支票",
    "simplified": "支票",
    "reading": "zhīpiào",
    "meaning": "check (bank), cheque, CL:本[ben3]",
    "grade": 7
  },
  {
    "id": 10742,
    "traditional": "之所以",
    "simplified": "之所以",
    "reading": "zhīsuǒyǐ",
    "meaning": "conj.: why",
    "grade": 7
  },
  {
    "id": 10743,
    "traditional": "汁",
    "simplified": "汁",
    "reading": "zhī",
    "meaning": "juice",
    "grade": 7
  },
  {
    "id": 10744,
    "traditional": "支柱",
    "simplified": "支柱",
    "reading": "zhīzhù",
    "meaning": "mainstay, pillar, prop, backbone",
    "grade": 7
  },
  {
    "id": 10745,
    "traditional": "芝麻",
    "simplified": "芝麻",
    "reading": "zhīma",
    "meaning": "sesame (seed)",
    "grade": 7
  },
  {
    "id": 10746,
    "traditional": "芝士",
    "simplified": "芝士",
    "reading": "zhīshì",
    "meaning": "cheese (loanword)",
    "grade": 7
  },
  {
    "id": 10747,
    "traditional": "知覺",
    "simplified": "知觉",
    "reading": "zhījué",
    "meaning": "perception, consciousness",
    "grade": 7
  },
  {
    "id": 10748,
    "traditional": "知己",
    "simplified": "知己",
    "reading": "zhījǐ",
    "meaning": "to know oneself, to be intimate or close, intimate friend",
    "grade": 7
  },
  {
    "id": 10749,
    "traditional": "知識分子",
    "simplified": "知识分子",
    "reading": "zhīshifènzǐ",
    "meaning": "wig, intellectual, educated person, educated person (high school or college equivalent), intellect, intelligentsia, clerisy",
    "grade": 7
  },
  {
    "id": 10750,
    "traditional": "知足",
    "simplified": "知足",
    "reading": "zhīzú",
    "meaning": "content with one's situation, to know contentment (hence happiness)",
    "grade": 7
  },
  {
    "id": 10751,
    "traditional": "脂肪",
    "simplified": "脂肪",
    "reading": "zhīfáng",
    "meaning": "grease, fat, axunge, adipose, fatness, avoirdupois, fattiness, adipose tissue, fatty tissue",
    "grade": 7
  },
  {
    "id": 10752,
    "traditional": "肢體",
    "simplified": "肢体",
    "reading": "zhītǐ",
    "meaning": "member, limbs and trunk, solid body substance, body, subfigure, limbs",
    "grade": 7
  },
  {
    "id": 10753,
    "traditional": "執意",
    "simplified": "执意",
    "reading": "zhíyì",
    "meaning": "insist on, be bent on",
    "grade": 7
  },
  {
    "id": 10754,
    "traditional": "執照",
    "simplified": "执照",
    "reading": "zhízhào",
    "meaning": "endorsement, charter, countenance, imprimatur, permit, warrant, sanction, qualification, certificate, licence, license",
    "grade": 7
  },
  {
    "id": 10755,
    "traditional": "執法",
    "simplified": "执法",
    "reading": "zhífǎ",
    "meaning": "to enforce a law, law enforcement",
    "grade": 7
  },
  {
    "id": 10756,
    "traditional": "執著",
    "simplified": "执着",
    "reading": "zhízhuó",
    "meaning": "rigid, punctilious",
    "grade": 7
  },
  {
    "id": 10757,
    "traditional": "直奔",
    "simplified": "直奔",
    "reading": "zhíbèn",
    "meaning": "to go straight to, to make a beeline for",
    "grade": 7
  },
  {
    "id": 10758,
    "traditional": "直觀",
    "simplified": "直观",
    "reading": "zhíguān",
    "meaning": "direct observation, directly perceived through the senses, intuitive, audiovisual",
    "grade": 7
  },
  {
    "id": 10759,
    "traditional": "直達",
    "simplified": "直达",
    "reading": "zhídá",
    "meaning": "to go nonstop to, through (as in 'through train'), nonstop",
    "grade": 7
  },
  {
    "id": 10760,
    "traditional": "直徑",
    "simplified": "直径",
    "reading": "zhíjìng",
    "meaning": "diameter",
    "grade": 7
  },
  {
    "id": 10761,
    "traditional": "直視",
    "simplified": "直视",
    "reading": "zhíshì",
    "meaning": "to look straight at",
    "grade": 7
  },
  {
    "id": 10762,
    "traditional": "直覺",
    "simplified": "直觉",
    "reading": "zhíjué",
    "meaning": "intuition",
    "grade": 7
  },
  {
    "id": 10763,
    "traditional": "值錢",
    "simplified": "值钱",
    "reading": "zhíqián",
    "meaning": "valuable, costly, expensive",
    "grade": 7
  },
  {
    "id": 10764,
    "traditional": "職權",
    "simplified": "职权",
    "reading": "zhíquán",
    "meaning": "authority, power over others",
    "grade": 7
  },
  {
    "id": 10765,
    "traditional": "直至",
    "simplified": "直至",
    "reading": "zhízhì",
    "meaning": "lasting until, up till (the present)",
    "grade": 7
  },
  {
    "id": 10766,
    "traditional": "職業病",
    "simplified": "职业病",
    "reading": "zhíyèbìng",
    "meaning": "occupational disease",
    "grade": 7
  },
  {
    "id": 10767,
    "traditional": "止步",
    "simplified": "止步",
    "reading": "zhǐ bù",
    "meaning": "to halt, to stop, to go no farther",
    "grade": 7
  },
  {
    "id": 10768,
    "traditional": "職員",
    "simplified": "职员",
    "reading": "zhíyuán",
    "meaning": "employee, servant, official, staff member, retainer, functionary, operative, office employees, officeholder, personnel, officer, staff_member, office worker, clerk, staffer",
    "grade": 7
  },
  {
    "id": 10769,
    "traditional": "止血",
    "simplified": "止血",
    "reading": "zhǐxuè",
    "meaning": "to staunch (bleeding), hemostatic (drug)",
    "grade": 7
  },
  {
    "id": 10770,
    "traditional": "止咳",
    "simplified": "止咳",
    "reading": "zhǐké",
    "meaning": "to suppress coughing",
    "grade": 7
  },
  {
    "id": 10771,
    "traditional": "旨在",
    "simplified": "旨在",
    "reading": "zhǐzài",
    "meaning": "to have as its purpose, to be intended to, to aim to (do sth)",
    "grade": 7
  },
  {
    "id": 10772,
    "traditional": "指點",
    "simplified": "指点",
    "reading": "zhǐdiǎn",
    "meaning": "to point out, to indicate, to give directions, to show how (to do sth), to censure, to pick at",
    "grade": 7
  },
  {
    "id": 10773,
    "traditional": "指令",
    "simplified": "指令",
    "reading": "zhǐlìng",
    "meaning": "directive, instruction, dictation, bid, decree, rescript, fiat, injunction, dictate, instructions, order, bidding, mandate, command, edict",
    "grade": 7
  },
  {
    "id": 10774,
    "traditional": "指教",
    "simplified": "指教",
    "reading": "zhǐjiào",
    "meaning": "to give advice or comments",
    "grade": 7
  },
  {
    "id": 10775,
    "traditional": "指南針",
    "simplified": "指南针",
    "reading": "zhǐnánzhēn",
    "meaning": "compass",
    "grade": 7
  },
  {
    "id": 10776,
    "traditional": "指手畫腳",
    "simplified": "指手画脚",
    "reading": "zhǐshǒu-huàjiǎo",
    "meaning": "to gesticulate while talking (idiom), to explain by waving one's hands, to criticize or give orders summarily",
    "grade": 7
  },
  {
    "id": 10777,
    "traditional": "指望",
    "simplified": "指望",
    "reading": "zhǐwang",
    "meaning": "to hope for sth, to count on, hope",
    "grade": 7
  },
  {
    "id": 10778,
    "traditional": "指南",
    "simplified": "指南",
    "reading": "zhǐnán",
    "meaning": "to guide, guidebook",
    "grade": 7
  },
  {
    "id": 10779,
    "traditional": "指向",
    "simplified": "指向",
    "reading": "zhǐxiàng",
    "meaning": "head, point to, hold, point, maneuver, direct, steer, gear, pitch, manouevre, guide",
    "grade": 7
  },
  {
    "id": 10780,
    "traditional": "指引",
    "simplified": "指引",
    "reading": "zhǐyǐn",
    "meaning": "to guide, to show, to point (the way), directions, guidance, guidelines",
    "grade": 7
  },
  {
    "id": 10781,
    "traditional": "至此",
    "simplified": "至此",
    "reading": "zhìcǐ",
    "meaning": "up until now, so far",
    "grade": 7
  },
  {
    "id": 10782,
    "traditional": "至關重要",
    "simplified": "至关重要",
    "reading": "zhìguānzhòngyào",
    "meaning": "extremely important, vital, crucial, essential",
    "grade": 7
  },
  {
    "id": 10783,
    "traditional": "製",
    "simplified": "制",
    "reading": "zhì",
    "meaning": "make, manufacture, create",
    "grade": 7
  },
  {
    "id": 10784,
    "traditional": "志氣",
    "simplified": "志气",
    "reading": "zhìqi",
    "meaning": "ambition, resolve, backbone, drive, spirit",
    "grade": 7
  },
  {
    "id": 10785,
    "traditional": "制服",
    "simplified": "制服",
    "reading": "zhìfú",
    "meaning": "uniform",
    "grade": 7
  },
  {
    "id": 10786,
    "traditional": "制裁",
    "simplified": "制裁",
    "reading": "zhìcái",
    "meaning": "sanction, punish",
    "grade": 7
  },
  {
    "id": 10787,
    "traditional": "制止",
    "simplified": "制止",
    "reading": "zhìzhǐ",
    "meaning": "forbear, restrict, oppress, prevention, hold back, inhibit, allay, stop, prevent, staunch, turn back, arrest, stanch, suppress, interdict, discourage, pull_up, abate, blink, quell, restrain, deter, repress, crush, omit, shush, countercheck, check, curb, contain",
    "grade": 7
  },
  {
    "id": 10788,
    "traditional": "製品",
    "simplified": "制品",
    "reading": "zhìpǐn",
    "meaning": "manufacture, ware, facture, manufactured goods, products",
    "grade": 7
  },
  {
    "id": 10789,
    "traditional": "質地",
    "simplified": "质地",
    "reading": "zhìdì",
    "meaning": "texture, background (texture), grain, quality, character, disposition",
    "grade": 7
  },
  {
    "id": 10790,
    "traditional": "質問",
    "simplified": "质问",
    "reading": "zhìwèn",
    "meaning": "interrogate, bombard, pose, heckle, call to account, inquire, interrogation, oppugn, question, quiz, ask",
    "grade": 7
  },
  {
    "id": 10791,
    "traditional": "質樸",
    "simplified": "质朴",
    "reading": "zhìpǔ",
    "meaning": "simple, plain, unadorned, unaffected, unsophisticated, rustic, earthy",
    "grade": 7
  },
  {
    "id": 10792,
    "traditional": "質疑",
    "simplified": "质疑",
    "reading": "zhìyí",
    "meaning": "call in question, challenge",
    "grade": 7
  },
  {
    "id": 10793,
    "traditional": "治學",
    "simplified": "治学",
    "reading": "zhìxué",
    "meaning": "scholarship, high-level study, to do scholarly research, to pursue a high level of study",
    "grade": 7
  },
  {
    "id": 10794,
    "traditional": "治癒",
    "simplified": "治愈",
    "reading": "zhìyù",
    "meaning": "to cure",
    "grade": 7
  },
  {
    "id": 10795,
    "traditional": "致敬",
    "simplified": "致敬",
    "reading": "zhìjìng",
    "meaning": "to greet, to pay respects to",
    "grade": 7
  },
  {
    "id": 10796,
    "traditional": "致",
    "simplified": "致",
    "reading": "zhì",
    "meaning": "incur, cause",
    "grade": 7
  },
  {
    "id": 10797,
    "traditional": "致辭",
    "simplified": "致辞",
    "reading": "zhì cí",
    "meaning": "to express in words or writing, to make a speech (esp. short introduction, vote of thanks, afterword, funeral homily etc), to address (an audience), same as 致詞|致词",
    "grade": 7
  },
  {
    "id": 10798,
    "traditional": "致力於",
    "simplified": "致力于",
    "reading": "zhìlìyú",
    "meaning": "Dedicate",
    "grade": 7
  },
  {
    "id": 10799,
    "traditional": "致富",
    "simplified": "致富",
    "reading": "zhìfù",
    "meaning": "to become rich",
    "grade": 7
  },
  {
    "id": 10800,
    "traditional": "致命",
    "simplified": "致命",
    "reading": "zhìmìng",
    "meaning": "take off, fatal, deadly, mortal",
    "grade": 7
  },
  {
    "id": 10801,
    "traditional": "致使",
    "simplified": "致使",
    "reading": "zhìshǐ",
    "meaning": "become, render, go, get, result in, cause, make",
    "grade": 7
  },
  {
    "id": 10802,
    "traditional": "窒息",
    "simplified": "窒息",
    "reading": "zhìxī",
    "meaning": "to choke, to stifle, to suffocate",
    "grade": 7
  },
  {
    "id": 10803,
    "traditional": "秩序",
    "simplified": "秩序",
    "reading": "zhìxù",
    "meaning": "order, sequence",
    "grade": 7
  },
  {
    "id": 10804,
    "traditional": "智商",
    "simplified": "智商",
    "reading": "zhìshāng",
    "meaning": "IQ (intelligence quotient)",
    "grade": 7
  },
  {
    "id": 10805,
    "traditional": "滯後",
    "simplified": "滞后",
    "reading": "zhìhòu",
    "meaning": "to lag behind",
    "grade": 7
  },
  {
    "id": 10806,
    "traditional": "滯留",
    "simplified": "滞留",
    "reading": "zhìliú",
    "meaning": "to detain, retention",
    "grade": 7
  },
  {
    "id": 10807,
    "traditional": "置",
    "simplified": "置",
    "reading": "zhì",
    "meaning": "to install, to place, to put, to buy",
    "grade": 7
  },
  {
    "id": 10808,
    "traditional": "中國畫",
    "simplified": "中国画",
    "reading": "Zhōngguóhuà",
    "meaning": "Chinese painting",
    "grade": 7
  },
  {
    "id": 10809,
    "traditional": "中途",
    "simplified": "中途",
    "reading": "zhōngtú",
    "meaning": "halfway, midstream, midway",
    "grade": 7
  },
  {
    "id": 10810,
    "traditional": "中立",
    "simplified": "中立",
    "reading": "zhōnglì",
    "meaning": "neutral",
    "grade": 7
  },
  {
    "id": 10811,
    "traditional": "中型",
    "simplified": "中型",
    "reading": "zhōngxíng",
    "meaning": "medium sized",
    "grade": 7
  },
  {
    "id": 10812,
    "traditional": "中性",
    "simplified": "中性",
    "reading": "zhōngxìng",
    "meaning": "neutral",
    "grade": 7
  },
  {
    "id": 10813,
    "traditional": "中旬",
    "simplified": "中旬",
    "reading": "zhōngxún",
    "meaning": "middle third of a month",
    "grade": 7
  },
  {
    "id": 10814,
    "traditional": "中庸",
    "simplified": "中庸",
    "reading": "zhōngyōng",
    "meaning": "the Doctrine of the Mean, one of the Four Books 四書|四书[Si4 shu1], golden mean (Confucianism), (literary) (of person) mediocre, ordinary",
    "grade": 7
  },
  {
    "id": 10815,
    "traditional": "忠誠",
    "simplified": "忠诚",
    "reading": "zhōngchéng",
    "meaning": "devoted, loyal, fidelity, loyalty",
    "grade": 7
  },
  {
    "id": 10816,
    "traditional": "忠實",
    "simplified": "忠实",
    "reading": "zhōngshí",
    "meaning": "leal, faithful, true",
    "grade": 7
  },
  {
    "id": 10817,
    "traditional": "中止",
    "simplified": "中止",
    "reading": "zhōngzhǐ",
    "meaning": "to cease, to suspend, to break off, to stop, to discontinue",
    "grade": 7
  },
  {
    "id": 10818,
    "traditional": "忠於",
    "simplified": "忠于",
    "reading": "zhōngyú",
    "meaning": "to be loyal to",
    "grade": 7
  },
  {
    "id": 10819,
    "traditional": "終結",
    "simplified": "终结",
    "reading": "zhōngjié",
    "meaning": "end, conclusion, to come to an end, to terminate (sth)",
    "grade": 7
  },
  {
    "id": 10820,
    "traditional": "忠貞",
    "simplified": "忠贞",
    "reading": "zhōngzhēn",
    "meaning": "loyal and dependable",
    "grade": 7
  },
  {
    "id": 10821,
    "traditional": "終生",
    "simplified": "终生",
    "reading": "zhōngshēng",
    "meaning": "all one's life",
    "grade": 7
  },
  {
    "id": 10822,
    "traditional": "終究",
    "simplified": "终究",
    "reading": "zhōngjiū",
    "meaning": "eventually, in the end, after all",
    "grade": 7
  },
  {
    "id": 10823,
    "traditional": "腫瘤",
    "simplified": "肿瘤",
    "reading": "zhǒngliú",
    "meaning": "tumor",
    "grade": 7
  },
  {
    "id": 10824,
    "traditional": "衷心",
    "simplified": "衷心",
    "reading": "zhōngxīn",
    "meaning": "heartfelt, wholehearted, cordial",
    "grade": 7
  },
  {
    "id": 10825,
    "traditional": "種族",
    "simplified": "种族",
    "reading": "zhǒngzú",
    "meaning": "tribe, citizenry, race, nation, phyle, stem, stirp, phylon, ethnos, nationality, ethnic, folk, people, stirps, racial, ethnic group, family",
    "grade": 7
  },
  {
    "id": 10826,
    "traditional": "仲裁",
    "simplified": "仲裁",
    "reading": "zhòngcái",
    "meaning": "arbitration",
    "grade": 7
  },
  {
    "id": 10827,
    "traditional": "眾所週知",
    "simplified": "众所周知",
    "reading": "zhòngsuǒzhōuzhī",
    "meaning": "see 眾所周知|众所周知[zhong4 suo3 zhou1 zhi1]",
    "grade": 7
  },
  {
    "id": 10828,
    "traditional": "眾志成城",
    "simplified": "众志成城",
    "reading": "zhòngzhì-chéngchéng",
    "meaning": "unity of will is an impregnable stronghold (idiom)",
    "grade": 7
  },
  {
    "id": 10829,
    "traditional": "眾人",
    "simplified": "众人",
    "reading": "zhòngrén",
    "meaning": "everybody",
    "grade": 7
  },
  {
    "id": 10830,
    "traditional": "重創",
    "simplified": "重创",
    "reading": "zhòngchuāng",
    "meaning": "to inflict heavy losses, to inflict serious damage",
    "grade": 7
  },
  {
    "id": 10831,
    "traditional": "重量級",
    "simplified": "重量级",
    "reading": "zhòngliàngjí",
    "meaning": "heavyweight (boxing etc)",
    "grade": 7
  },
  {
    "id": 10832,
    "traditional": "重傷",
    "simplified": "重伤",
    "reading": "zhòngshāng",
    "meaning": "seriously hurt, serious injury",
    "grade": 7
  },
  {
    "id": 10833,
    "traditional": "重任",
    "simplified": "重任",
    "reading": "zhòngrèn",
    "meaning": "heavy responsibility",
    "grade": 7
  },
  {
    "id": 10834,
    "traditional": "重型",
    "simplified": "重型",
    "reading": "zhòngxíng",
    "meaning": "heavy, heavy duty, large caliber",
    "grade": 7
  },
  {
    "id": 10835,
    "traditional": "重中之重",
    "simplified": "重中之重",
    "reading": "zhòngzhōngzhīzhòng",
    "meaning": "of the utmost importance, of highest priority",
    "grade": 7
  },
  {
    "id": 10836,
    "traditional": "周邊",
    "simplified": "周边",
    "reading": "zhōubiān",
    "meaning": "circumference, computer peripheral, environs, peripheral device, precinct, peripheral, periphery, perimeter",
    "grade": 7
  },
  {
    "id": 10837,
    "traditional": "週到",
    "simplified": "周到",
    "reading": "zhōudào",
    "meaning": "thoughtful, considerate, attentive, thorough, also pr. [zhou1 dao5]",
    "grade": 7
  },
  {
    "id": 10838,
    "traditional": "重心",
    "simplified": "重心",
    "reading": "zhòngxīn",
    "meaning": "focus, fulcrum, heart, focusing, focussing, barycenter, centroid, centre of gravity, centering, centre_of_gravity, center of gravity, center_of_gravity, core",
    "grade": 7
  },
  {
    "id": 10839,
    "traditional": "周旋",
    "simplified": "周旋",
    "reading": "zhōuxuán",
    "meaning": "to mix with others, to socialize, to deal with, to contend",
    "grade": 7
  },
  {
    "id": 10840,
    "traditional": "周密",
    "simplified": "周密",
    "reading": "zhōumì",
    "meaning": "careful, thorough, meticulous, dense, impenetrable",
    "grade": 7
  },
  {
    "id": 10841,
    "traditional": "晝夜",
    "simplified": "昼夜",
    "reading": "zhòuyè",
    "meaning": "day and night, period of 24 hours, continuously, without stop",
    "grade": 7
  },
  {
    "id": 10842,
    "traditional": "驟然",
    "simplified": "骤然",
    "reading": "zhòurán",
    "meaning": "suddenly, abruptly",
    "grade": 7
  },
  {
    "id": 10843,
    "traditional": "皺",
    "simplified": "皱",
    "reading": "zhòu",
    "meaning": "to wrinkle, wrinkled, to crease",
    "grade": 7
  },
  {
    "id": 10844,
    "traditional": "朱紅",
    "simplified": "朱红",
    "reading": "zhūhóng",
    "meaning": "vermilion",
    "grade": 7
  },
  {
    "id": 10845,
    "traditional": "株",
    "simplified": "株",
    "reading": "zhū",
    "meaning": "stub, individual plant, stem of a plant, plant, trunk of tree, stem of plant, trunk of a tree, m.[general],",
    "grade": 7
  },
  {
    "id": 10846,
    "traditional": "諸多",
    "simplified": "诸多",
    "reading": "zhūduō",
    "meaning": "det.: a good deal, a lot of",
    "grade": 7
  },
  {
    "id": 10847,
    "traditional": "諸如此類",
    "simplified": "诸如此类",
    "reading": "zhūrúcǐlèi",
    "meaning": "things like this (idiom), and so on, and the rest, etc",
    "grade": 7
  },
  {
    "id": 10848,
    "traditional": "逐年",
    "simplified": "逐年",
    "reading": "zhúnián",
    "meaning": "all the year round, for years, all year, year after year, year in year out",
    "grade": 7
  },
  {
    "id": 10849,
    "traditional": "主",
    "simplified": "主",
    "reading": "zhǔ",
    "meaning": "owner, master, host, individual or party concerned, God, Lord, main, to indicate or signify, trump card (in card games)",
    "grade": 7
  },
  {
    "id": 10850,
    "traditional": "竹竿",
    "simplified": "竹竿",
    "reading": "zhúgān",
    "meaning": "bamboo",
    "grade": 7
  },
  {
    "id": 10851,
    "traditional": "主婦",
    "simplified": "主妇",
    "reading": "zhǔfù",
    "meaning": "housewife, hostess",
    "grade": 7
  },
  {
    "id": 10852,
    "traditional": "主編",
    "simplified": "主编",
    "reading": "zhǔbiān",
    "meaning": "editor in chief",
    "grade": 7
  },
  {
    "id": 10853,
    "traditional": "主力",
    "simplified": "主力",
    "reading": "zhǔlì",
    "meaning": "brunt, main force, backbone",
    "grade": 7
  },
  {
    "id": 10854,
    "traditional": "主人公",
    "simplified": "主人公",
    "reading": "zhǔréngōng",
    "meaning": "hero (of a novel or film), main protagonist",
    "grade": 7
  },
  {
    "id": 10855,
    "traditional": "主食",
    "simplified": "主食",
    "reading": "zhǔshí",
    "meaning": "main food, staple (rice and noodles)",
    "grade": 7
  },
  {
    "id": 10856,
    "traditional": "主題歌",
    "simplified": "主题歌",
    "reading": "zhǔtígē",
    "meaning": "Theme song",
    "grade": 7
  },
  {
    "id": 10857,
    "traditional": "主權",
    "simplified": "主权",
    "reading": "zhǔquán",
    "meaning": "imperium, ascendance, reign, ascendency, ascendancy, possession, empire, majesty, signory, paramountcy, lordship, sovereign rights, sovereignty, raj, supremacy, dominion, ownership",
    "grade": 7
  },
  {
    "id": 10858,
    "traditional": "主頁",
    "simplified": "主页",
    "reading": "zhǔyè",
    "meaning": "home page",
    "grade": 7
  },
  {
    "id": 10859,
    "traditional": "主義",
    "simplified": "主义",
    "reading": "zhǔyì",
    "meaning": "tenet, principle, hang, system, ticket, creed, philosophy, school of thought, evangel, ism, gospel, masonry, maxim, doctrine, -ism",
    "grade": 7
  },
  {
    "id": 10860,
    "traditional": "主宰",
    "simplified": "主宰",
    "reading": "zhǔzǎi",
    "meaning": "dominate, dictate, decide",
    "grade": 7
  },
  {
    "id": 10861,
    "traditional": "主演",
    "simplified": "主演",
    "reading": "zhǔyǎn",
    "meaning": "act leading role (in play/film)",
    "grade": 7
  },
  {
    "id": 10862,
    "traditional": "拄",
    "simplified": "拄",
    "reading": "zhǔ",
    "meaning": "to lean on, to prop on",
    "grade": 7
  },
  {
    "id": 10863,
    "traditional": "囑咐",
    "simplified": "嘱咐",
    "reading": "zhǔfu",
    "meaning": "to tell, to exhort, injunction",
    "grade": 7
  },
  {
    "id": 10864,
    "traditional": "矚目",
    "simplified": "瞩目",
    "reading": "zhǔmù",
    "meaning": "fix eyes on",
    "grade": 7
  },
  {
    "id": 10865,
    "traditional": "助威",
    "simplified": "助威",
    "reading": "zhù wēi",
    "meaning": "to cheer for, to encourage, to boost the morale of",
    "grade": 7
  },
  {
    "id": 10866,
    "traditional": "住戶",
    "simplified": "住户",
    "reading": "zhùhù",
    "meaning": "denizen, resident, inhabitant, building, household, dweller",
    "grade": 7
  },
  {
    "id": 10867,
    "traditional": "住處",
    "simplified": "住处",
    "reading": "zhùchu",
    "meaning": "domicile, hangout, dwelling, lodging, residency, domiciliation, diggings, abode, digs, quarters, habitation, lodgings, roost, living quarters, pad, residence",
    "grade": 7
  },
  {
    "id": 10868,
    "traditional": "住址",
    "simplified": "住址",
    "reading": "zhùzhǐ",
    "meaning": "address",
    "grade": 7
  },
  {
    "id": 10869,
    "traditional": "貯藏",
    "simplified": "贮藏",
    "reading": "zhùcáng",
    "meaning": "to store up, to hoard, deposits",
    "grade": 7
  },
  {
    "id": 10870,
    "traditional": "注",
    "simplified": "注",
    "reading": "zhù",
    "meaning": "explanatory note, annotation",
    "grade": 7
  },
  {
    "id": 10871,
    "traditional": "住宿",
    "simplified": "住宿",
    "reading": "zhùsù",
    "meaning": "stay, put up, get accommodations",
    "grade": 7
  },
  {
    "id": 10872,
    "traditional": "註定",
    "simplified": "注定",
    "reading": "zhùdìng",
    "meaning": "to foreordain, to be bound to, to be destined to, to be doomed to, inevitably",
    "grade": 7
  },
  {
    "id": 10873,
    "traditional": "鑄造",
    "simplified": "铸造",
    "reading": "zhùzào",
    "meaning": "to cast (pour metal into a mold)",
    "grade": 7
  },
  {
    "id": 10874,
    "traditional": "築",
    "simplified": "筑",
    "reading": "zhù",
    "meaning": "short name for Guiyang 貴陽|贵阳[Gui4 yang2], to build, to construct, to ram, to hit, Taiwan pr. [zhu2]",
    "grade": 7
  },
  {
    "id": 10875,
    "traditional": "注入",
    "simplified": "注入",
    "reading": "zhùrù",
    "meaning": "inject, mainline, infuse, teem, impregnate, inflood, implant, pump, pour, funnel, pour into, inspire, pour/empty into, influx, empty into, breathe, empty, immit",
    "grade": 7
  },
  {
    "id": 10876,
    "traditional": "拽",
    "simplified": "拽",
    "reading": "yè",
    "meaning": "to drag, to haul, to throw, to fling, alternate writing of 跩[zhuai3], to pull, to tug at (sth)",
    "grade": 7
  },
  {
    "id": 10877,
    "traditional": "爪子",
    "simplified": "爪子",
    "reading": "zhuǎzi",
    "meaning": "(animal's) claw",
    "grade": 7
  },
  {
    "id": 10878,
    "traditional": "專長",
    "simplified": "专长",
    "reading": "zhuāncháng",
    "meaning": "department, excellence, metier, knowledge, speciality, specialty, specialism, specialisation, special skill/knowledge, special knowledge, adept, expertise, special skill, medium, forte, specialization",
    "grade": 7
  },
  {
    "id": 10879,
    "traditional": "專程",
    "simplified": "专程",
    "reading": "zhuānchéng",
    "meaning": "special trip",
    "grade": 7
  },
  {
    "id": 10880,
    "traditional": "專欄",
    "simplified": "专栏",
    "reading": "zhuānlán",
    "meaning": "column, special column, spot, feature, feature article, columnar",
    "grade": 7
  },
  {
    "id": 10881,
    "traditional": "專賣店",
    "simplified": "专卖店",
    "reading": "zhuānmàidiàn",
    "meaning": "specialty store",
    "grade": 7
  },
  {
    "id": 10882,
    "traditional": "專人",
    "simplified": "专人",
    "reading": "zhuānrén",
    "meaning": "person specially assigned for a task/job",
    "grade": 7
  },
  {
    "id": 10883,
    "traditional": "專制",
    "simplified": "专制",
    "reading": "zhuānzhì",
    "meaning": "autocracy, dictatorship",
    "grade": 7
  },
  {
    "id": 10884,
    "traditional": "專職",
    "simplified": "专职",
    "reading": "zhuānzhí",
    "meaning": "special duty, assigned full time to a task",
    "grade": 7
  },
  {
    "id": 10885,
    "traditional": "專注",
    "simplified": "专注",
    "reading": "zhuānzhù",
    "meaning": "be absorbed in",
    "grade": 7
  },
  {
    "id": 10886,
    "traditional": "專著",
    "simplified": "专著",
    "reading": "zhuānzhù",
    "meaning": "monograph, specialized text",
    "grade": 7
  },
  {
    "id": 10887,
    "traditional": "磚",
    "simplified": "砖",
    "reading": "zhuān",
    "meaning": "brick",
    "grade": 7
  },
  {
    "id": 10888,
    "traditional": "轉播",
    "simplified": "转播",
    "reading": "zhuǎnbō",
    "meaning": "relay (radio/TV broadcast)",
    "grade": 7
  },
  {
    "id": 10889,
    "traditional": "轉達",
    "simplified": "转达",
    "reading": "zhuǎndá",
    "meaning": "to pass on, to convey, to communicate",
    "grade": 7
  },
  {
    "id": 10890,
    "traditional": "轉交",
    "simplified": "转交",
    "reading": "zhuǎnjiāo",
    "meaning": "to pass on to sb, to carry and give to sb else",
    "grade": 7
  },
  {
    "id": 10891,
    "traditional": "專櫃",
    "simplified": "专柜",
    "reading": "zhuānguì",
    "meaning": "shoppe",
    "grade": 7
  },
  {
    "id": 10892,
    "traditional": "轉型",
    "simplified": "转型",
    "reading": "zhuǎn xíng",
    "meaning": "change, transform",
    "grade": 7
  },
  {
    "id": 10893,
    "traditional": "轉學",
    "simplified": "转学",
    "reading": "zhuǎn xué",
    "meaning": "to change schools, to transfer to another college",
    "grade": 7
  },
  {
    "id": 10894,
    "traditional": "轉機",
    "simplified": "转机",
    "reading": "zhuǎnjī",
    "meaning": "turn for better",
    "grade": 7
  },
  {
    "id": 10895,
    "traditional": "轉載",
    "simplified": "转载",
    "reading": "zhuǎnzǎi",
    "meaning": "to forward (a shipment), to reprint sth published elsewhere, Taiwan pr. [zhuan3 zai4]",
    "grade": 7
  },
  {
    "id": 10896,
    "traditional": "轉折",
    "simplified": "转折",
    "reading": "zhuǎnzhé",
    "meaning": "shift in the trend of events, turnaround, plot shift in a book, turn in the conversation",
    "grade": 7
  },
  {
    "id": 10897,
    "traditional": "轉折點",
    "simplified": "转折点",
    "reading": "zhuǎnzhédiǎn",
    "meaning": "turning point, breaking point",
    "grade": 7
  },
  {
    "id": 10898,
    "traditional": "傳",
    "simplified": "传",
    "reading": "chuán",
    "meaning": "pass (on), hand down, impart, teach, spread, transmit",
    "grade": 7
  },
  {
    "id": 10899,
    "traditional": "傳記",
    "simplified": "传记",
    "reading": "zhuànjì",
    "meaning": "biography, CL:篇[pian1],部[bu4]",
    "grade": 7
  },
  {
    "id": 10900,
    "traditional": "轉悠",
    "simplified": "转悠",
    "reading": "zhuànyou",
    "meaning": "to roll, to wander around, to appear repeatedly",
    "grade": 7
  },
  {
    "id": 10901,
    "traditional": "撰寫",
    "simplified": "撰写",
    "reading": "zhuànxiě",
    "meaning": "write",
    "grade": 7
  },
  {
    "id": 10902,
    "traditional": "轉眼",
    "simplified": "转眼",
    "reading": "zhuǎnyǎn",
    "meaning": "in a flash, in the blink of an eye, to glance",
    "grade": 7
  },
  {
    "id": 10903,
    "traditional": "莊稼",
    "simplified": "庄稼",
    "reading": "zhuāngjia",
    "meaning": "farm crop, CL:種|种[zhong3]",
    "grade": 7
  },
  {
    "id": 10904,
    "traditional": "莊嚴",
    "simplified": "庄严",
    "reading": "zhuāngyán",
    "meaning": "solemn, dignified, stately",
    "grade": 7
  },
  {
    "id": 10905,
    "traditional": "莊園",
    "simplified": "庄园",
    "reading": "zhuāngyuán",
    "meaning": "a manor, feudal land, a villa and park",
    "grade": 7
  },
  {
    "id": 10906,
    "traditional": "壯",
    "simplified": "壮",
    "reading": "zhuàng",
    "meaning": "strong, make better, sturdy, magnificent, robust, make stronger, strengthen, make better/stronger",
    "grade": 7
  },
  {
    "id": 10907,
    "traditional": "裝扮",
    "simplified": "装扮",
    "reading": "zhuāngbàn",
    "meaning": "to decorate, to adorn, to dress up, to disguise oneself",
    "grade": 7
  },
  {
    "id": 10908,
    "traditional": "壯大",
    "simplified": "壮大",
    "reading": "zhuàngdà",
    "meaning": "to expand, to strengthen",
    "grade": 7
  },
  {
    "id": 10909,
    "traditional": "壯膽",
    "simplified": "壮胆",
    "reading": "zhuàng dǎn",
    "meaning": "Vigorous",
    "grade": 7
  },
  {
    "id": 10910,
    "traditional": "壯麗",
    "simplified": "壮丽",
    "reading": "zhuànglì",
    "meaning": "magnificence, magnificent, majestic, glorious",
    "grade": 7
  },
  {
    "id": 10911,
    "traditional": "狀元",
    "simplified": "状元",
    "reading": "zhuàngyuan",
    "meaning": "top scorer in the palace examination (highest rank of the Imperial examination system), see 榜眼[bang3 yan3] and 探花[tan4 hua1], top scorer in college entrance examination 高考[gao1 kao3], (fig.) the most brilliantly talented person in the field, leading light",
    "grade": 7
  },
  {
    "id": 10912,
    "traditional": "撞擊",
    "simplified": "撞击",
    "reading": "zhuàngjī",
    "meaning": "to strike, to hit, to ram",
    "grade": 7
  },
  {
    "id": 10913,
    "traditional": "幢",
    "simplified": "幢",
    "reading": "chuáng",
    "meaning": "Buddhist stone pillar, ancient streamer, ancient pennant, ancient pennant/streamer, m.[general],",
    "grade": 7
  },
  {
    "id": 10914,
    "traditional": "追悼會",
    "simplified": "追悼会",
    "reading": "zhuīdàohuì",
    "meaning": "a memorial service, a funeral service",
    "grade": 7
  },
  {
    "id": 10915,
    "traditional": "追趕",
    "simplified": "追赶",
    "reading": "zhuīgǎn",
    "meaning": "to pursue, to chase after, to accelerate, to catch up with, to overtake",
    "grade": 7
  },
  {
    "id": 10916,
    "traditional": "追溯",
    "simplified": "追溯",
    "reading": "zhuīsù",
    "meaning": "lit. to go upstream, to trace sth back to, to date from",
    "grade": 7
  },
  {
    "id": 10917,
    "traditional": "壯實",
    "simplified": "壮实",
    "reading": "zhuàngshi",
    "meaning": "robust, sturdy",
    "grade": 7
  },
  {
    "id": 10918,
    "traditional": "追隨",
    "simplified": "追随",
    "reading": "zhuīsuí",
    "meaning": "follow",
    "grade": 7
  },
  {
    "id": 10919,
    "traditional": "追尾",
    "simplified": "追尾",
    "reading": "zhuī wěi",
    "meaning": "to tailgate, to hit the car in front as a result of tailgating",
    "grade": 7
  },
  {
    "id": 10920,
    "traditional": "追問",
    "simplified": "追问",
    "reading": "zhuīwèn",
    "meaning": "to question closely, to investigate in detail, to examine minutely, to get to the heart of the matter",
    "grade": 7
  },
  {
    "id": 10921,
    "traditional": "追蹤",
    "simplified": "追踪",
    "reading": "zhuīzōng",
    "meaning": "follow trail, track",
    "grade": 7
  },
  {
    "id": 10922,
    "traditional": "墜",
    "simplified": "坠",
    "reading": "zhuì",
    "meaning": "to fall, to drop, to weigh down",
    "grade": 7
  },
  {
    "id": 10923,
    "traditional": "準許",
    "simplified": "准许",
    "reading": "zhǔnxǔ",
    "meaning": "to allow, to grant, to permit",
    "grade": 7
  },
  {
    "id": 10924,
    "traditional": "樁",
    "simplified": "桩",
    "reading": "zhuāng",
    "meaning": "m.[general]",
    "grade": 7
  },
  {
    "id": 10925,
    "traditional": "拙劣",
    "simplified": "拙劣",
    "reading": "zhuōliè",
    "meaning": "clumsy, botched",
    "grade": 7
  },
  {
    "id": 10926,
    "traditional": "灼熱",
    "simplified": "灼热",
    "reading": "zhuórè",
    "meaning": "burning hot, scorching, worried",
    "grade": 7
  },
  {
    "id": 10927,
    "traditional": "捉迷藏",
    "simplified": "捉迷藏",
    "reading": "zhuō mícáng",
    "meaning": "to play hide-and-seek",
    "grade": 7
  },
  {
    "id": 10928,
    "traditional": "追逐",
    "simplified": "追逐",
    "reading": "zhuīzhú",
    "meaning": "pursue, chase, seek",
    "grade": 7
  },
  {
    "id": 10929,
    "traditional": "準則",
    "simplified": "准则",
    "reading": "zhǔnzé",
    "meaning": "guideline, formula, principle, practice, norm, rule of thumb, standard, verity, criterion, canon, touchstone, basis, guidepost, maxim",
    "grade": 7
  },
  {
    "id": 10930,
    "traditional": "酌情",
    "simplified": "酌情",
    "reading": "zhuóqíng",
    "meaning": "to use discretion, to take circumstances into account, to make allowances pertinent to a situation",
    "grade": 7
  },
  {
    "id": 10931,
    "traditional": "著力",
    "simplified": "着力",
    "reading": "zhuó lì",
    "meaning": "to put effort into sth, to try really hard",
    "grade": 7
  },
  {
    "id": 10932,
    "traditional": "著落",
    "simplified": "着落",
    "reading": "zhuóluò",
    "meaning": "whereabouts, place to settle, reliable source (of funds etc), (of responsibility for a matter) to rest with sb, settlement, solution",
    "grade": 7
  },
  {
    "id": 10933,
    "traditional": "著實",
    "simplified": "着实",
    "reading": "zhuóshí",
    "meaning": "truly, indeed, severely, harshly",
    "grade": 7
  },
  {
    "id": 10934,
    "traditional": "著手",
    "simplified": "着手",
    "reading": "zhuóshǒu",
    "meaning": "put hand to, initiatory, begin, set to, set_out, institute, proceed, start up, turn_to, fall, spring-clean, embark, go_about, undertake, accede to, enter upon, get to work, set about, commence, enter, put one's hand to, start",
    "grade": 7
  },
  {
    "id": 10935,
    "traditional": "著想",
    "simplified": "着想",
    "reading": "zhuóxiǎng",
    "meaning": "to give thought (to others), to consider (other people's needs), also pr. [zhao2 xiang3]",
    "grade": 7
  },
  {
    "id": 10936,
    "traditional": "著眼",
    "simplified": "着眼",
    "reading": "zhuóyǎn",
    "meaning": "to have one's eyes on (a goal), having sth in mind, to concentrate",
    "grade": 7
  },
  {
    "id": 10937,
    "traditional": "著眼於",
    "simplified": "着眼于",
    "reading": "zhuóyǎn yú",
    "meaning": "Focus on",
    "grade": 7
  },
  {
    "id": 10938,
    "traditional": "著重",
    "simplified": "着重",
    "reading": "zhuózhòng",
    "meaning": "underscore, accent, concentrate, emphasize, attach importance, stress, set off, underline, accentuate, bring out",
    "grade": 7
  },
  {
    "id": 10939,
    "traditional": "姿態",
    "simplified": "姿态",
    "reading": "zītài",
    "meaning": "mannerism, set, stance, pose, attitude, affectation, port, shadow, likeness, carriage, posture, position, movement, gesture, tournure, sort",
    "grade": 7
  },
  {
    "id": 10940,
    "traditional": "茲",
    "simplified": "兹",
    "reading": "cí",
    "meaning": "now, here, this, time, year",
    "grade": 7
  },
  {
    "id": 10941,
    "traditional": "資本主義",
    "simplified": "资本主义",
    "reading": "zīběnzhǔyì",
    "meaning": "capitalistic, capitalist economy, capitalism",
    "grade": 7
  },
  {
    "id": 10942,
    "traditional": "資歷",
    "simplified": "资历",
    "reading": "zīlì",
    "meaning": "qualifications, experience, seniority",
    "grade": 7
  },
  {
    "id": 10943,
    "traditional": "姿勢",
    "simplified": "姿势",
    "reading": "zīshì",
    "meaning": "mannerism, plant, poise, motion, pose, attitude, sign, affectation, port, likeness, carriage, posture, position, setup, gesticulation, gesture, seat",
    "grade": 7
  },
  {
    "id": 10944,
    "traditional": "資深",
    "simplified": "资深",
    "reading": "zīshēn",
    "meaning": "senior",
    "grade": 7
  },
  {
    "id": 10945,
    "traditional": "卓越",
    "simplified": "卓越",
    "reading": "zhuóyuè",
    "meaning": "excel, excel at, brilliant, shine, surpassing, paramount, shine at, outstanding",
    "grade": 7
  },
  {
    "id": 10946,
    "traditional": "滋潤",
    "simplified": "滋润",
    "reading": "zīrùn",
    "meaning": "moist, humid, to moisten, to provide moisture, comfortably off",
    "grade": 7
  },
  {
    "id": 10947,
    "traditional": "滋味",
    "simplified": "滋味",
    "reading": "zīwèi",
    "meaning": "relish, richness, tang, sapidity, sapor, tinge, mouthful, savor, tasting, smack, flavor, savour, zest, taste, flavour",
    "grade": 7
  },
  {
    "id": 10948,
    "traditional": "子弟",
    "simplified": "子弟",
    "reading": "zǐdì",
    "meaning": "sons and younger brothers, children, juniors",
    "grade": 7
  },
  {
    "id": 10949,
    "traditional": "子孫",
    "simplified": "子孙",
    "reading": "zǐsūn",
    "meaning": "posterity, rod, scion, materialization, progeniture, offspring, descendant, children and grandchildren, spawn, son, generation, issue, increase, descendent, seed, child, descendants, fruit, get, progeny",
    "grade": 7
  },
  {
    "id": 10950,
    "traditional": "自稱",
    "simplified": "自称",
    "reading": "zìchēng",
    "meaning": "purport, claim to be, give_out, profess, call oneself",
    "grade": 7
  },
  {
    "id": 10951,
    "traditional": "自卑",
    "simplified": "自卑",
    "reading": "zìbēi",
    "meaning": "feel oneself inferior, have low self-esteem",
    "grade": 7
  },
  {
    "id": 10952,
    "traditional": "資訊",
    "simplified": "资讯",
    "reading": "zīxùn",
    "meaning": "stuff, message, information, news, info",
    "grade": 7
  },
  {
    "id": 10953,
    "traditional": "自費",
    "simplified": "自费",
    "reading": "zìfèi",
    "meaning": "at one's own expense, be at one's own expense",
    "grade": 7
  },
  {
    "id": 10954,
    "traditional": "自發",
    "simplified": "自发",
    "reading": "zìfā",
    "meaning": "spontaneous",
    "grade": 7
  },
  {
    "id": 10955,
    "traditional": "自負",
    "simplified": "自负",
    "reading": "zìfù",
    "meaning": "conceited, to take responsibility",
    "grade": 7
  },
  {
    "id": 10956,
    "traditional": "自理",
    "simplified": "自理",
    "reading": "zìlǐ",
    "meaning": "to take care of oneself, to provide for oneself",
    "grade": 7
  },
  {
    "id": 10957,
    "traditional": "自力更生",
    "simplified": "自力更生",
    "reading": "zìlì-gēngshēng",
    "meaning": "regeneration through one's own effort (idiom), self-reliance",
    "grade": 7
  },
  {
    "id": 10958,
    "traditional": "自強不息",
    "simplified": "自强不息",
    "reading": "zìqiáng-bùxī",
    "meaning": "to strive unremittingly, self-improvement",
    "grade": 7
  },
  {
    "id": 10959,
    "traditional": "自然而然",
    "simplified": "自然而然",
    "reading": "zìránérrán",
    "meaning": "involuntary, automatically",
    "grade": 7
  },
  {
    "id": 10960,
    "traditional": "自立",
    "simplified": "自立",
    "reading": "zìlì",
    "meaning": "independent, self-reliant, self-sustaining, to stand on one's own feet, to support oneself",
    "grade": 7
  },
  {
    "id": 10961,
    "traditional": "自然界",
    "simplified": "自然界",
    "reading": "zìránjiè",
    "meaning": "nature, the natural world",
    "grade": 7
  },
  {
    "id": 10962,
    "traditional": "自始至終",
    "simplified": "自始至终",
    "reading": "zìshǐ-zhìzhōng",
    "meaning": "from start to finish (idiom)",
    "grade": 7
  },
  {
    "id": 10963,
    "traditional": "自如",
    "simplified": "自如",
    "reading": "zìrú",
    "meaning": "freedom of action, unobstructed, unconstrained, smoothly, with ease, freely",
    "grade": 7
  },
  {
    "id": 10964,
    "traditional": "自衛",
    "simplified": "自卫",
    "reading": "zìwèi",
    "meaning": "self-defense",
    "grade": 7
  },
  {
    "id": 10965,
    "traditional": "自相矛盾",
    "simplified": "自相矛盾",
    "reading": "zìxiāng-máodùn",
    "meaning": "to contradict oneself, self-contradictory, inconsistent",
    "grade": 7
  },
  {
    "id": 10966,
    "traditional": "自私自利",
    "simplified": "自私自利",
    "reading": "zìsī-zìlì",
    "meaning": "everything for self and selfish profit (idiom), with no regard for others, selfish, mercenary",
    "grade": 7
  },
  {
    "id": 10967,
    "traditional": "自信心",
    "simplified": "自信心",
    "reading": "zìxìnxīn",
    "meaning": "self-confidence",
    "grade": 7
  },
  {
    "id": 10968,
    "traditional": "自行",
    "simplified": "自行",
    "reading": "zìxíng",
    "meaning": "voluntary, autonomous, by oneself, self-",
    "grade": 7
  },
  {
    "id": 10969,
    "traditional": "自以為是",
    "simplified": "自以为是",
    "reading": "zìyǐwéishì",
    "meaning": "to believe oneself infallible (idiom), to be opinionated",
    "grade": 7
  },
  {
    "id": 10970,
    "traditional": "自由自在",
    "simplified": "自由自在",
    "reading": "zìyóu-zìzài",
    "meaning": "free and easy (idiom), carefree, leisurely",
    "grade": 7
  },
  {
    "id": 10971,
    "traditional": "自責",
    "simplified": "自责",
    "reading": "zìzé",
    "meaning": "to blame oneself",
    "grade": 7
  },
  {
    "id": 10972,
    "traditional": "自助",
    "simplified": "自助",
    "reading": "zìzhù",
    "meaning": "bootstrap, self-service, self-helf",
    "grade": 7
  },
  {
    "id": 10973,
    "traditional": "自尊",
    "simplified": "自尊",
    "reading": "zìzūn",
    "meaning": "self-worth, self-regard, pridefulness, pride, self-pride, self-respect, self-importance, self-esteem",
    "grade": 7
  },
  {
    "id": 10974,
    "traditional": "自尊心",
    "simplified": "自尊心",
    "reading": "zìzūnxīn",
    "meaning": "self-respect, self-esteem, ego",
    "grade": 7
  },
  {
    "id": 10975,
    "traditional": "字跡",
    "simplified": "字迹",
    "reading": "zìjì",
    "meaning": "handwriting",
    "grade": 7
  },
  {
    "id": 10976,
    "traditional": "字體",
    "simplified": "字体",
    "reading": "zìtǐ",
    "meaning": "calligraphic style, typeface, font",
    "grade": 7
  },
  {
    "id": 10977,
    "traditional": "字眼",
    "simplified": "字眼",
    "reading": "zìyǎn",
    "meaning": "wording",
    "grade": 7
  },
  {
    "id": 10978,
    "traditional": "宗",
    "simplified": "宗",
    "reading": "zōng",
    "meaning": "m.[general]",
    "grade": 7
  },
  {
    "id": 10979,
    "traditional": "宗旨",
    "simplified": "宗旨",
    "reading": "zōngzhǐ",
    "meaning": "animus, object, purpose, aim, religion, bearing",
    "grade": 7
  },
  {
    "id": 10980,
    "traditional": "字幕",
    "simplified": "字幕",
    "reading": "zìmù",
    "meaning": "caption, subtitle",
    "grade": 7
  },
  {
    "id": 10981,
    "traditional": "綜上所述",
    "simplified": "综上所述",
    "reading": "zōngshàngsuǒshù",
    "meaning": "to summarize, a round-up, in summary ...",
    "grade": 7
  },
  {
    "id": 10982,
    "traditional": "自私",
    "simplified": "自私",
    "reading": "zìsī",
    "meaning": "selfish, self-centered",
    "grade": 7
  },
  {
    "id": 10983,
    "traditional": "總額",
    "simplified": "总额",
    "reading": "zǒngé",
    "meaning": "gross, sum, rental, total, sum total, totality, amount",
    "grade": 7
  },
  {
    "id": 10984,
    "traditional": "總的來說",
    "simplified": "总的来说",
    "reading": "zǒngdeláishuō",
    "meaning": "generally speaking, to sum up, in summary, in short",
    "grade": 7
  },
  {
    "id": 10985,
    "traditional": "總計",
    "simplified": "总计",
    "reading": "zǒngjì",
    "meaning": "(grand) total",
    "grade": 7
  },
  {
    "id": 10986,
    "traditional": "縱觀",
    "simplified": "纵观",
    "reading": "zòngguān",
    "meaning": "to survey comprehensively, an overall survey",
    "grade": 7
  },
  {
    "id": 10987,
    "traditional": "縱橫交錯",
    "simplified": "纵横交错",
    "reading": "zònghéng-jiāocuò",
    "meaning": "criss-crossed (idiom)",
    "grade": 7
  },
  {
    "id": 10988,
    "traditional": "縱然",
    "simplified": "纵然",
    "reading": "zòngrán",
    "meaning": "even if, even though",
    "grade": 7
  },
  {
    "id": 10989,
    "traditional": "縱容",
    "simplified": "纵容",
    "reading": "zòngróng",
    "meaning": "to indulge, to connive at",
    "grade": 7
  },
  {
    "id": 10990,
    "traditional": "縱深",
    "simplified": "纵深",
    "reading": "zòngshēn",
    "meaning": "depth (from front to rear), depth (into a territory), span (of time), (fig.) depth (of deployment, progress, development etc)",
    "grade": 7
  },
  {
    "id": 10991,
    "traditional": "粽子",
    "simplified": "粽子",
    "reading": "zòngzi",
    "meaning": "glutinous rice and choice of filling wrapped in leaves and boiled",
    "grade": 7
  },
  {
    "id": 10992,
    "traditional": "走過場",
    "simplified": "走过场",
    "reading": "zǒu guòchǎng",
    "meaning": "to go through the motions",
    "grade": 7
  },
  {
    "id": 10993,
    "traditional": "總而言之",
    "simplified": "总而言之",
    "reading": "zǒngéryánzhī",
    "meaning": "in short, in a word, in brief",
    "grade": 7
  },
  {
    "id": 10994,
    "traditional": "走近",
    "simplified": "走近",
    "reading": "zǒujìn",
    "meaning": "approach",
    "grade": 7
  },
  {
    "id": 10995,
    "traditional": "走廊",
    "simplified": "走廊",
    "reading": "zǒuláng",
    "meaning": "slype, porch, hallway, foyer, corridor, hall, passageway, gallery, walkway, piazza, vestibule, ambulatory, verandah, alure, passage, aisle",
    "grade": 7
  },
  {
    "id": 10996,
    "traditional": "走投無路",
    "simplified": "走投无路",
    "reading": "zǒutóu-wúlù",
    "meaning": "to be at an impasse (idiom), in a tight spot, at the end of one's rope, desperate",
    "grade": 7
  },
  {
    "id": 10997,
    "traditional": "走彎路",
    "simplified": "走弯路",
    "reading": "zǒu wānlù",
    "meaning": "to take an indirect route, to waste one's time by using an inappropriate method",
    "grade": 7
  },
  {
    "id": 10998,
    "traditional": "走後門",
    "simplified": "走后门",
    "reading": "zǒu hòumén",
    "meaning": "lit. to enter by the back door, fig. to gain influence by pull or unofficial channels, back door or under the counter connections",
    "grade": 7
  },
  {
    "id": 10999,
    "traditional": "揍",
    "simplified": "揍",
    "reading": "zòu",
    "meaning": "to beat up, to break to pieces",
    "grade": 7
  },
  {
    "id": 11000,
    "traditional": "租賃",
    "simplified": "租赁",
    "reading": "zūlìn",
    "meaning": "to rent, to lease, to hire",
    "grade": 7
  },
  {
    "id": 11001,
    "traditional": "足智多謀",
    "simplified": "足智多谋",
    "reading": "zúzhì-duōmóu",
    "meaning": "resourceful, full of stratagems",
    "grade": 7
  },
  {
    "id": 11002,
    "traditional": "阻擋",
    "simplified": "阻挡",
    "reading": "zǔdǎng",
    "meaning": "to stop, to resist, to obstruct",
    "grade": 7
  },
  {
    "id": 11003,
    "traditional": "阻攔",
    "simplified": "阻拦",
    "reading": "zǔlán",
    "meaning": "to stop, to obstruct",
    "grade": 7
  },
  {
    "id": 11004,
    "traditional": "奏效",
    "simplified": "奏效",
    "reading": "zòu xiào",
    "meaning": "to show results, effective",
    "grade": 7
  },
  {
    "id": 11005,
    "traditional": "阻力",
    "simplified": "阻力",
    "reading": "zǔlì",
    "meaning": "resistance, drag, impedimenta, impediment, obstruction, traction, pullback, retarding force",
    "grade": 7
  },
  {
    "id": 11006,
    "traditional": "阻撓",
    "simplified": "阻挠",
    "reading": "zǔnáo",
    "meaning": "to thwart, to obstruct (sth)",
    "grade": 7
  },
  {
    "id": 11007,
    "traditional": "組裝",
    "simplified": "组装",
    "reading": "zǔzhuāng",
    "meaning": "to assemble and install",
    "grade": 7
  },
  {
    "id": 11008,
    "traditional": "祖傳",
    "simplified": "祖传",
    "reading": "zǔchuán",
    "meaning": "passed on from ancestors, handed down from generation to generation",
    "grade": 7
  },
  {
    "id": 11009,
    "traditional": "祖籍",
    "simplified": "祖籍",
    "reading": "zǔjí",
    "meaning": "ancestral hometown, original domicile (and civil registration)",
    "grade": 7
  },
  {
    "id": 11010,
    "traditional": "祖先",
    "simplified": "祖先",
    "reading": "zǔxiān",
    "meaning": "parent, father, forbear, forefathers, origin, progenitor, propositus, pedigree, forebear, forefather, antecedent, ascendent, grandfather, ancestor, forerunner, grandsire, stirp, root, ancestry, grandmother, antecedents, forbears, stirps, ascendant, primogenitor, ancestral, ancestors, sire, stock, forebears",
    "grade": 7
  },
  {
    "id": 11011,
    "traditional": "祖宗",
    "simplified": "祖宗",
    "reading": "zǔzong",
    "meaning": "ancestor, forebear",
    "grade": 7
  },
  {
    "id": 11012,
    "traditional": "鑽空子",
    "simplified": "钻空子",
    "reading": "zuān kòngzi",
    "meaning": "lit. to drill a hole, to take advantage of a loophole, to exploit an advantage, to seize the opportunity (esp. to do sth bad)",
    "grade": 7
  },
  {
    "id": 11013,
    "traditional": "鑽研",
    "simplified": "钻研",
    "reading": "zuānyán",
    "meaning": "to study meticulously, to delve into",
    "grade": 7
  },
  {
    "id": 11014,
    "traditional": "組建",
    "simplified": "组建",
    "reading": "zǔjiàn",
    "meaning": "to organize, to set up, to establish",
    "grade": 7
  },
  {
    "id": 11015,
    "traditional": "鑽石",
    "simplified": "钻石",
    "reading": "zuànshí",
    "meaning": "diamond, watch jewel",
    "grade": 7
  },
  {
    "id": 11016,
    "traditional": "足跡",
    "simplified": "足迹",
    "reading": "zújì",
    "meaning": "slot, track, footprint, footstep, wake, footmark, trace, trail, spoor, step",
    "grade": 7
  },
  {
    "id": 11017,
    "traditional": "嘴唇",
    "simplified": "嘴唇",
    "reading": "zuǐchún",
    "meaning": "lip, lips, kisser",
    "grade": 7
  },
  {
    "id": 11018,
    "traditional": "罪魁禍首",
    "simplified": "罪魁祸首",
    "reading": "zuìkuí-huòshǒu",
    "meaning": "criminal ringleader, main offender (idiom), main culprit, fig. main cause of a disaster",
    "grade": 7
  },
  {
    "id": 11019,
    "traditional": "尊嚴",
    "simplified": "尊严",
    "reading": "zūnyán",
    "meaning": "honour, dignity, loftiness, face, worship, self-regard, majesty, sanctity, self-respect, dignified, stateliness, self-esteem, honor",
    "grade": 7
  },
  {
    "id": 11020,
    "traditional": "尊貴",
    "simplified": "尊贵",
    "reading": "zūnguì",
    "meaning": "respected, respectable, honorable",
    "grade": 7
  },
  {
    "id": 11021,
    "traditional": "遵循",
    "simplified": "遵循",
    "reading": "zūnxún",
    "meaning": "track, abide by, follow, conform to, adhere to",
    "grade": 7
  },
  {
    "id": 11022,
    "traditional": "遵照",
    "simplified": "遵照",
    "reading": "zūnzhào",
    "meaning": "in accordance with, to follow (the rules)",
    "grade": 7
  },
  {
    "id": 11023,
    "traditional": "琢磨",
    "simplified": "琢磨",
    "reading": "zhuómó",
    "meaning": "to carve and polish (jade), to polish and refine a literary work, to ponder, to mull over, to think through, Taiwan pr. [zhuo2 mo2]",
    "grade": 7
  },
  {
    "id": 11024,
    "traditional": "罪犯",
    "simplified": "罪犯",
    "reading": "zuìfàn",
    "meaning": "criminal",
    "grade": 7
  },
  {
    "id": 11025,
    "traditional": "左顧右盼",
    "simplified": "左顾右盼",
    "reading": "zuǒgù-yòupàn",
    "meaning": "glancing to left and right (idiom), to look all around",
    "grade": 7
  },
  {
    "id": 11026,
    "traditional": "佐料",
    "simplified": "佐料",
    "reading": "zuǒliào",
    "meaning": "condiments, seasoning",
    "grade": 7
  },
  {
    "id": 11027,
    "traditional": "作對",
    "simplified": "作对",
    "reading": "zuò duì",
    "meaning": "to set oneself against, to oppose, to make a pair",
    "grade": 7
  },
  {
    "id": 11028,
    "traditional": "作風",
    "simplified": "作风",
    "reading": "zuòfēng",
    "meaning": "path, way of life, idiom, style, way, style of work, expressive style, fashion",
    "grade": 7
  },
  {
    "id": 11029,
    "traditional": "作物",
    "simplified": "作物",
    "reading": "zuòwù",
    "meaning": "crop",
    "grade": 7
  },
  {
    "id": 11030,
    "traditional": "坐落",
    "simplified": "坐落",
    "reading": "zuòluò",
    "meaning": "to be situated, to be located (of a building)",
    "grade": 7
  },
  {
    "id": 11031,
    "traditional": "作弊",
    "simplified": "作弊",
    "reading": "zuò bì",
    "meaning": "indulge in corrupt practices, crib, cheat, fix, practice corruption, exam, misbehave, cheat on test, cheat on exam, corruption, practice fraud, practise fraud",
    "grade": 7
  },
  {
    "id": 11032,
    "traditional": "座右銘",
    "simplified": "座右铭",
    "reading": "zuòyòumíng",
    "meaning": "motto, maxim",
    "grade": 7
  },
  {
    "id": 11033,
    "traditional": "座談",
    "simplified": "座谈",
    "reading": "zuòtán",
    "meaning": "forum, symposium",
    "grade": 7
  },
  {
    "id": 11034,
    "traditional": "做生意",
    "simplified": "做生意",
    "reading": "zuò shēngyi",
    "meaning": "merchandise, deal, trade, sell, do business",
    "grade": 7
  },
  {
    "id": 11035,
    "traditional": "做證",
    "simplified": "做证",
    "reading": "zuò zhèng",
    "meaning": "Do one",
    "grade": 7
  },
  {
    "id": 11036,
    "traditional": "作客",
    "simplified": "作客",
    "reading": "zuò kè",
    "meaning": "to live somewhere as a visitor, to stay with sb as a guest, to sojourn",
    "grade": 7
  }
]
