
// @ts-nocheck


export type HanziUnitItem = {
  Character: string
  Pinyin: string | undefined
  Definition: string | undefined
  Radical: string | undefined
  RadicalIndex: string | undefined
  StrokeCount: number | undefined
  HSK: number | undefined
  GeneralStandard: number
  FrequencyRank: number | undefined
}

export const hanzi : HanziUnitItem[] = [
  {
    "Character": "一",
    "Pinyin": "yī",
    "Definition": "one; a, an; alone",
    "Radical": "一",
    "RadicalIndex": "1",
    "StrokeCount": 1,
    "HSK": 1,
    "GeneralStandard": 1,
    "FrequencyRank": 2
  },
  {
    "Character": "七",
    "Pinyin": "qī",
    "Definition": "seven",
    "Radical": "一",
    "RadicalIndex": "1.1",
    "StrokeCount": 2,
    "HSK": 1,
    "GeneralStandard": 7,
    "FrequencyRank": 530
  },
  {
    "Character": "人",
    "Pinyin": "rén",
    "Definition": "man; people; mankind; someone else",
    "Radical": "人",
    "RadicalIndex": "9",
    "StrokeCount": 2,
    "HSK": 1,
    "GeneralStandard": 10,
    "FrequencyRank": 7
  },
  {
    "Character": "儿",
    "Pinyin": "ér",
    "Definition": "son, child; KangXi radical 10",
    "Radical": "儿",
    "RadicalIndex": "10",
    "StrokeCount": 2,
    "HSK": 1,
    "GeneralStandard": 12,
    "FrequencyRank": 192
  },
  {
    "Character": "了",
    "Pinyin": "le",
    "Definition": "to finish; particle of completed action",
    "Radical": "亅",
    "RadicalIndex": "6.1",
    "StrokeCount": 2,
    "HSK": 1,
    "GeneralStandard": 17,
    "FrequencyRank": 5
  },
  {
    "Character": "九",
    "Pinyin": "jiǔ",
    "Definition": "nine",
    "Radical": "乙",
    "RadicalIndex": "5.1",
    "StrokeCount": 2,
    "HSK": 1,
    "GeneralStandard": 15,
    "FrequencyRank": 445
  },
  {
    "Character": "八",
    "Pinyin": "bā",
    "Definition": "eight; all around, all sides",
    "Radical": "八",
    "RadicalIndex": "12",
    "StrokeCount": 2,
    "HSK": 1,
    "GeneralStandard": 9,
    "FrequencyRank": 451
  },
  {
    "Character": "几",
    "Pinyin": "jǐ",
    "Definition": "small table",
    "Radical": "几",
    "RadicalIndex": "16",
    "StrokeCount": 2,
    "HSK": 1,
    "GeneralStandard": 14,
    "FrequencyRank": 211
  },
  {
    "Character": "二",
    "Pinyin": "èr",
    "Definition": "two; twice",
    "Radical": "二",
    "RadicalIndex": "7",
    "StrokeCount": 2,
    "HSK": 1,
    "GeneralStandard": 3,
    "FrequencyRank": 157
  },
  {
    "Character": "十",
    "Pinyin": "shí",
    "Definition": "ten, tenth; complete; perfect",
    "Radical": "十",
    "RadicalIndex": "24",
    "StrokeCount": 2,
    "HSK": 1,
    "GeneralStandard": 4,
    "FrequencyRank": 112
  },
  {
    "Character": "习",
    "Pinyin": "xí",
    "Definition": "practice; flapping wings",
    "Radical": "冫",
    "RadicalIndex": "15.1",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 70,
    "FrequencyRank": 676
  },
  {
    "Character": "个",
    "Pinyin": "gè",
    "Definition": "numerary adjunct, piece; single",
    "Radical": "丨",
    "RadicalIndex": "2.2",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 45,
    "FrequencyRank": 12
  },
  {
    "Character": "三",
    "Pinyin": "sān",
    "Definition": "three",
    "Radical": "一",
    "RadicalIndex": "1.2",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 22,
    "FrequencyRank": 125
  },
  {
    "Character": "上",
    "Pinyin": "shàng",
    "Definition": "top; superior, highest; go up, send up",
    "Radical": "一",
    "RadicalIndex": "1.2",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 36,
    "FrequencyRank": 16
  },
  {
    "Character": "么",
    "Pinyin": "me",
    "Definition": "interrogative particle; repetition of a tune small; tender",
    "Radical": "丿",
    "RadicalIndex": "4.2",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 48,
    "FrequencyRank": 63
  },
  {
    "Character": "小",
    "Pinyin": "xiǎo",
    "Definition": "small, tiny, insignificant",
    "Radical": "小",
    "RadicalIndex": "42",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 37,
    "FrequencyRank": 83
  },
  {
    "Character": "门",
    "Pinyin": "mén",
    "Definition": "gate, door, entrance, opening",
    "Radical": "門",
    "RadicalIndex": "169",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 55,
    "FrequencyRank": 185
  },
  {
    "Character": "下",
    "Pinyin": "xià",
    "Definition": "under, underneath, below; down; inferior; bring down",
    "Radical": "一",
    "RadicalIndex": "1.2",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 30,
    "FrequencyRank": 42
  },
  {
    "Character": "子",
    "Pinyin": "zi",
    "Definition": "offspring, child; fruit, seed of; 1st terrestrial branch",
    "Radical": "子",
    "RadicalIndex": "39",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 64,
    "FrequencyRank": 37
  },
  {
    "Character": "口",
    "Pinyin": "kǒu",
    "Definition": "mouth; open end; entrance, gate",
    "Radical": "口",
    "RadicalIndex": "30",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 38,
    "FrequencyRank": 212
  },
  {
    "Character": "女",
    "Pinyin": "nǚ",
    "Definition": "woman, girl; feminine; rad. 38",
    "Radical": "女",
    "RadicalIndex": "38",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 67,
    "FrequencyRank": 224
  },
  {
    "Character": "飞",
    "Pinyin": "fēi",
    "Definition": "fly; go quickly; dart; high",
    "Radical": "乙",
    "RadicalIndex": "5.2",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 69,
    "FrequencyRank": 347
  },
  {
    "Character": "大",
    "Pinyin": "dà",
    "Definition": "big, great, vast, large, high",
    "Radical": "大",
    "RadicalIndex": "37",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 32,
    "FrequencyRank": 17
  },
  {
    "Character": "工",
    "Pinyin": "gōng",
    "Definition": "labor, work; worker, laborer",
    "Radical": "工",
    "RadicalIndex": "48",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 26,
    "FrequencyRank": 118
  },
  {
    "Character": "也",
    "Pinyin": "yě",
    "Definition": "also; classical final particle of strong affirmation or identity",
    "Radical": "乙",
    "RadicalIndex": "5.2",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 66,
    "FrequencyRank": 31
  },
  {
    "Character": "马",
    "Pinyin": "mǎ",
    "Definition": "horse; surname; KangXi radical 187",
    "Radical": "馬",
    "RadicalIndex": "187",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 72,
    "FrequencyRank": 276
  },
  {
    "Character": "山",
    "Pinyin": "shān",
    "Definition": "mountain, hill, peak",
    "Radical": "山",
    "RadicalIndex": "46",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 39,
    "FrequencyRank": 259
  },
  {
    "Character": "干",
    "Pinyin": "gàn",
    "Definition": "oppose, offend; invade; dried",
    "Radical": "干",
    "RadicalIndex": "51",
    "StrokeCount": 3,
    "HSK": 1,
    "GeneralStandard": 23,
    "FrequencyRank": 353
  },
  {
    "Character": "月",
    "Pinyin": "yuè",
    "Definition": "moon; month; KangXi radical 74",
    "Radical": "月",
    "RadicalIndex": "74",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 150,
    "FrequencyRank": 169
  },
  {
    "Character": "六",
    "Pinyin": "liù",
    "Definition": "number six",
    "Radical": "八",
    "RadicalIndex": "12.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 160,
    "FrequencyRank": 478
  },
  {
    "Character": "午",
    "Pinyin": "wǔ",
    "Definition": "noon; 7th terrestrial branch",
    "Radical": "十",
    "RadicalIndex": "24.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 119,
    "FrequencyRank": 1004
  },
  {
    "Character": "毛",
    "Pinyin": "máo",
    "Definition": "hair, fur, feathers; coarse",
    "Radical": "毛",
    "RadicalIndex": "82",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 123,
    "FrequencyRank": 623
  },
  {
    "Character": "元",
    "Pinyin": "yuán",
    "Definition": "first; dollar; origin; head",
    "Radical": "儿",
    "RadicalIndex": "10.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 80,
    "FrequencyRank": 370
  },
  {
    "Character": "日",
    "Pinyin": "rì",
    "Definition": "sun; day; daytime",
    "Radical": "日",
    "RadicalIndex": "72",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 112,
    "FrequencyRank": 101
  },
  {
    "Character": "五",
    "Pinyin": "wǔ",
    "Definition": "five; surname",
    "Radical": "二",
    "RadicalIndex": "7.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 88,
    "FrequencyRank": 279
  },
  {
    "Character": "气",
    "Pinyin": "qì",
    "Definition": "steam, vapor; KangXi radical 84",
    "Radical": "气",
    "RadicalIndex": "84",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 122,
    "FrequencyRank": 217
  },
  {
    "Character": "介",
    "Pinyin": "jiè",
    "Definition": "forerunner, herald, harbinger; to lie between; sea shell; to wear armor",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 140,
    "FrequencyRank": 831
  },
  {
    "Character": "方",
    "Pinyin": "fāng",
    "Definition": "a square, rectangle; a region; local",
    "Radical": "方",
    "RadicalIndex": "70",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 163,
    "FrequencyRank": 60
  },
  {
    "Character": "什",
    "Pinyin": "shén",
    "Definition": "file of ten soldiers; mixed, miscellaneous",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 129,
    "FrequencyRank": 156
  },
  {
    "Character": "少",
    "Pinyin": "shǎo",
    "Definition": "few, less, inadequate",
    "Radical": "小",
    "RadicalIndex": "42.1",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 110,
    "FrequencyRank": 233
  },
  {
    "Character": "不",
    "Pinyin": "bù",
    "Definition": "no, not; un-; negative prefix",
    "Radical": "一",
    "RadicalIndex": "1.3",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 91,
    "FrequencyRank": 4
  },
  {
    "Character": "友",
    "Pinyin": "yǒu",
    "Definition": "friend, companion; fraternity",
    "Radical": "又",
    "RadicalIndex": "29.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 97,
    "FrequencyRank": 594
  },
  {
    "Character": "天",
    "Pinyin": "tiān",
    "Definition": "sky, heaven; god, celestial",
    "Radical": "大",
    "RadicalIndex": "37.1",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 78,
    "FrequencyRank": 78
  },
  {
    "Character": "火",
    "Pinyin": "huǒ",
    "Definition": "fire, flame; burn; anger, rage",
    "Radical": "火",
    "RadicalIndex": "86",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 164,
    "FrequencyRank": 433
  },
  {
    "Character": "手",
    "Pinyin": "shǒu",
    "Definition": "hand",
    "Radical": "手",
    "RadicalIndex": "64",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 121,
    "FrequencyRank": 143
  },
  {
    "Character": "水",
    "Pinyin": "shuǐ",
    "Definition": "water, liquid, lotion, juice",
    "Radical": "水",
    "RadicalIndex": "85",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 117,
    "FrequencyRank": 202
  },
  {
    "Character": "书",
    "Pinyin": "shū",
    "Definition": "book, letter, document; writings",
    "Radical": "丨",
    "RadicalIndex": "2.3",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 188,
    "FrequencyRank": 282
  },
  {
    "Character": "见",
    "Pinyin": "jiàn",
    "Definition": "see, observe, behold; perceive",
    "Radical": "見",
    "RadicalIndex": "147",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 118,
    "FrequencyRank": 153
  },
  {
    "Character": "比",
    "Pinyin": "bǐ",
    "Definition": "to compare, liken; comparison; than",
    "Radical": "比",
    "RadicalIndex": "81",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 105,
    "FrequencyRank": 199
  },
  {
    "Character": "今",
    "Pinyin": "jīn",
    "Definition": "now, today, modern era",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 144,
    "FrequencyRank": 336
  },
  {
    "Character": "车",
    "Pinyin": "chē",
    "Definition": "cart, vehicle; carry in cart",
    "Radical": "車",
    "RadicalIndex": "159",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 100,
    "FrequencyRank": 361
  },
  {
    "Character": "认",
    "Pinyin": "rèn",
    "Definition": "recognize, know, understand",
    "Radical": "言",
    "RadicalIndex": "149.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 171,
    "FrequencyRank": 213
  },
  {
    "Character": "风",
    "Pinyin": "fēng",
    "Definition": "wind; air; manners, atmosphere",
    "Radical": "風",
    "RadicalIndex": "182",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 154,
    "FrequencyRank": 348
  },
  {
    "Character": "从",
    "Pinyin": "cóng",
    "Definition": "from, by, since, whence, through",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 142,
    "FrequencyRank": 98
  },
  {
    "Character": "开",
    "Pinyin": "kāi",
    "Definition": "open; initiate, begin, start",
    "Radical": "廾",
    "RadicalIndex": "55.1",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 76,
    "FrequencyRank": 94
  },
  {
    "Character": "牛",
    "Pinyin": "niú",
    "Definition": "cow, ox, bull; KangXi radical93",
    "Radical": "牛",
    "RadicalIndex": "93",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 120,
    "FrequencyRank": 1018
  },
  {
    "Character": "分",
    "Pinyin": "fēn",
    "Definition": "divide; small unit of time etc.",
    "Radical": "刀",
    "RadicalIndex": "18.2",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 146,
    "FrequencyRank": 79
  },
  {
    "Character": "太",
    "Pinyin": "tài",
    "Definition": "very, too, much; big; extreme",
    "Radical": "大",
    "RadicalIndex": "37.1",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 93,
    "FrequencyRank": 240
  },
  {
    "Character": "文",
    "Pinyin": "wén",
    "Definition": "literature, culture, writing",
    "Radical": "文",
    "RadicalIndex": "67",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 161,
    "FrequencyRank": 148
  },
  {
    "Character": "中",
    "Pinyin": "zhōng",
    "Definition": "central; center, middle; in the midst of; hit (target); attain",
    "Radical": "丨",
    "RadicalIndex": "2.3",
    "StrokeCount": 4,
    "HSK": 1,
    "GeneralStandard": 113,
    "FrequencyRank": 14
  },
  {
    "Character": "包",
    "Pinyin": "bāo",
    "Definition": "wrap, pack, bundle; package",
    "Radical": "勹",
    "RadicalIndex": "20.3",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 298,
    "FrequencyRank": 454
  },
  {
    "Character": "右",
    "Pinyin": "yòu",
    "Definition": "right; west; right-wing",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 217,
    "FrequencyRank": 783
  },
  {
    "Character": "他",
    "Pinyin": "tā",
    "Definition": "other, another; he, she, it",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 277,
    "FrequencyRank": 10
  },
  {
    "Character": "打",
    "Pinyin": "dǎ",
    "Definition": "strike, hit, beat; fight; attack",
    "Radical": "手",
    "RadicalIndex": "64.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 196,
    "FrequencyRank": 223
  },
  {
    "Character": "写",
    "Pinyin": "xiě",
    "Definition": "write; draw, sketch; compose",
    "Radical": "冖",
    "RadicalIndex": "14.3",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 316,
    "FrequencyRank": 448
  },
  {
    "Character": "电",
    "Pinyin": "diàn",
    "Definition": "electricity; electric; lightning",
    "Radical": "曰",
    "RadicalIndex": "73.1",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 242,
    "FrequencyRank": 230
  },
  {
    "Character": "四",
    "Pinyin": "sì",
    "Definition": "four",
    "Radical": "囗",
    "RadicalIndex": "31.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 262,
    "FrequencyRank": 226
  },
  {
    "Character": "对",
    "Pinyin": "duì",
    "Definition": "correct, right; facing, opposed",
    "Radical": "寸",
    "RadicalIndex": "41.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 341,
    "FrequencyRank": 33
  },
  {
    "Character": "用",
    "Pinyin": "yòng",
    "Definition": "use, employ, apply, operate; use",
    "Radical": "用",
    "RadicalIndex": "101",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 283,
    "FrequencyRank": 51
  },
  {
    "Character": "叫",
    "Pinyin": "jiào",
    "Definition": "cry, shout; hail, greet, call",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 253,
    "FrequencyRank": 387
  },
  {
    "Character": "出",
    "Pinyin": "chū",
    "Definition": "go out, send out; stand; produce",
    "Radical": "凵",
    "RadicalIndex": "17.3",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 330,
    "FrequencyRank": 28
  },
  {
    "Character": "生",
    "Pinyin": "shēng",
    "Definition": "life, living, lifetime; birth",
    "Radical": "生",
    "RadicalIndex": "100",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 263,
    "FrequencyRank": 34
  },
  {
    "Character": "白",
    "Pinyin": "bái",
    "Definition": "white; pure, unblemished; bright",
    "Radical": "白",
    "RadicalIndex": "106",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 275,
    "FrequencyRank": 286
  },
  {
    "Character": "北",
    "Pinyin": "běi",
    "Definition": "north; northern; northward",
    "Radical": "匕",
    "RadicalIndex": "21.3",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 227,
    "FrequencyRank": 315
  },
  {
    "Character": "东",
    "Pinyin": "dōng",
    "Definition": "east, eastern, eastward",
    "Radical": "一",
    "RadicalIndex": "1.4",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 225,
    "FrequencyRank": 194
  },
  {
    "Character": "外",
    "Pinyin": "wài",
    "Definition": "out, outside, external; foreign",
    "Radical": "夕",
    "RadicalIndex": "36.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 293,
    "FrequencyRank": 131
  },
  {
    "Character": "汉",
    "Pinyin": "hàn",
    "Definition": "Chinese people; Chinese language",
    "Radical": "水",
    "RadicalIndex": "85.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 311,
    "FrequencyRank": 711
  },
  {
    "Character": "们",
    "Pinyin": "men",
    "Definition": "adjunct pronoun indicate plural",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 273,
    "FrequencyRank": 13
  },
  {
    "Character": "号",
    "Pinyin": "hào",
    "Definition": "mark, sign; symbol; number",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 243,
    "FrequencyRank": 487
  },
  {
    "Character": "边",
    "Pinyin": "biān",
    "Definition": "edge, margin, side, border",
    "Radical": "辵",
    "RadicalIndex": "162.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 337,
    "FrequencyRank": 316
  },
  {
    "Character": "本",
    "Pinyin": "běn",
    "Definition": "root, origin, source; basis",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 210,
    "FrequencyRank": 92
  },
  {
    "Character": "记",
    "Pinyin": "jì",
    "Definition": "record; keep in mind, remember",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 323,
    "FrequencyRank": 306
  },
  {
    "Character": "左",
    "Pinyin": "zuǒ",
    "Definition": "left; east; unorthodox, improper",
    "Radical": "工",
    "RadicalIndex": "48.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 214,
    "FrequencyRank": 782
  },
  {
    "Character": "正",
    "Pinyin": "zhèng",
    "Definition": "right, proper, correct",
    "Radical": "止",
    "RadicalIndex": "77.1",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 198,
    "FrequencyRank": 129
  },
  {
    "Character": "半",
    "Pinyin": "bàn",
    "Definition": "half",
    "Radical": "十",
    "RadicalIndex": "24.3",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 307,
    "FrequencyRank": 513
  },
  {
    "Character": "去",
    "Pinyin": "qù",
    "Definition": "go away, leave, depart",
    "Radical": "厶",
    "RadicalIndex": "28.3",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 204,
    "FrequencyRank": 64
  },
  {
    "Character": "奶",
    "Pinyin": "nǎi",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.2",
    "StrokeCount": 5,
    "HSK": 1,
    "GeneralStandard": 332,
    "FrequencyRank": 1278
  },
  {
    "Character": "好",
    "Pinyin": "hǎo",
    "Definition": "good, excellent, fine; well",
    "Radical": "女",
    "RadicalIndex": "38.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 581,
    "FrequencyRank": 82
  },
  {
    "Character": "字",
    "Pinyin": "zì",
    "Definition": "letter, character, word",
    "Radical": "子",
    "RadicalIndex": "39.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 548,
    "FrequencyRank": 393
  },
  {
    "Character": "买",
    "Pinyin": "mǎi",
    "Definition": "buy, purchase; bribe, persuade",
    "Radical": "大",
    "RadicalIndex": "37.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 588,
    "FrequencyRank": 758
  },
  {
    "Character": "次",
    "Pinyin": "cì",
    "Definition": "order, sequence; next",
    "Radical": "欠",
    "RadicalIndex": "76.2",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 521,
    "FrequencyRank": 183
  },
  {
    "Character": "她",
    "Pinyin": "tā",
    "Definition": "she, her",
    "Radical": "女",
    "RadicalIndex": "38.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 582,
    "FrequencyRank": 91
  },
  {
    "Character": "网",
    "Pinyin": "wǎng",
    "Definition": "net; network; KangXi radical 122",
    "Radical": "网",
    "RadicalIndex": "122",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 442,
    "FrequencyRank": 605
  },
  {
    "Character": "关",
    "Pinyin": "guān",
    "Definition": "frontier pass; close; relation",
    "Radical": "八",
    "RadicalIndex": "12.4",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 532,
    "FrequencyRank": 127
  },
  {
    "Character": "先",
    "Pinyin": "xiān",
    "Definition": "first, former, previous",
    "Radical": "儿",
    "RadicalIndex": "10.4",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 446,
    "FrequencyRank": 188
  },
  {
    "Character": "再",
    "Pinyin": "zài",
    "Definition": "again, twice, re-",
    "Radical": "冂",
    "RadicalIndex": "13.4",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 382,
    "FrequencyRank": 242
  },
  {
    "Character": "场",
    "Pinyin": "chǎng",
    "Definition": "open space, field, market",
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 367,
    "FrequencyRank": 249
  },
  {
    "Character": "行",
    "Pinyin": "xíng",
    "Definition": "go; walk; move, travel; circulate",
    "Radical": "行",
    "RadicalIndex": "144",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 483,
    "FrequencyRank": 53
  },
  {
    "Character": "名",
    "Pinyin": "míng",
    "Definition": "name, rank, title, position",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 505,
    "FrequencyRank": 203
  },
  {
    "Character": "吗",
    "Pinyin": "ma",
    "Definition": "final interrogative particle",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 432,
    "FrequencyRank": 453
  },
  {
    "Character": "同",
    "Pinyin": "tóng",
    "Definition": "same, similar; together with",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 427,
    "FrequencyRank": 69
  },
  {
    "Character": "忙",
    "Pinyin": "máng",
    "Definition": "busy, pressed for time; hustling",
    "Radical": "心",
    "RadicalIndex": "61.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 543,
    "FrequencyRank": 827
  },
  {
    "Character": "吃",
    "Pinyin": "chī",
    "Definition": "eat; drink; suffer, endure, bear",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 429,
    "FrequencyRank": 475
  },
  {
    "Character": "动",
    "Pinyin": "dòng",
    "Definition": "move, happen; movement, action",
    "Radical": "力",
    "RadicalIndex": "19.4",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 353,
    "FrequencyRank": 73
  },
  {
    "Character": "那",
    "Pinyin": "nà",
    "Definition": "that, that one, those",
    "Radical": "邑",
    "RadicalIndex": "163.4",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 564,
    "FrequencyRank": 38
  },
  {
    "Character": "多",
    "Pinyin": "duō",
    "Definition": "much, many; more than, over",
    "Radical": "夕",
    "RadicalIndex": "36.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 507,
    "FrequencyRank": 61
  },
  {
    "Character": "爷",
    "Pinyin": "yé",
    "Definition": "father, grandfather",
    "Radical": "父",
    "RadicalIndex": "88.2",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 492,
    "FrequencyRank": 1007
  },
  {
    "Character": "地",
    "Pinyin": "de",
    "Definition": "earth; soil, ground; region",
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 366,
    "FrequencyRank": 21
  },
  {
    "Character": "考",
    "Pinyin": "kǎo",
    "Definition": "examine, test; investigate",
    "Radical": "老",
    "RadicalIndex": "125",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 358,
    "FrequencyRank": 495
  },
  {
    "Character": "妈",
    "Pinyin": "mā",
    "Definition": "mother, mama",
    "Radical": "女",
    "RadicalIndex": "38.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 583,
    "FrequencyRank": 750
  },
  {
    "Character": "问",
    "Pinyin": "wèn",
    "Definition": "ask about, inquire after",
    "Radical": "門",
    "RadicalIndex": "169.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 528,
    "FrequencyRank": 137
  },
  {
    "Character": "衣",
    "Pinyin": "yī",
    "Definition": "clothes, clothing; cover, skin",
    "Radical": "衣",
    "RadicalIndex": "145",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 520,
    "FrequencyRank": 725
  },
  {
    "Character": "后",
    "Pinyin": "hòu",
    "Definition": "queen, empress, sovereign; (simp. for 後) behind, rear, after",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 482,
    "FrequencyRank": 48
  },
  {
    "Character": "会",
    "Pinyin": "huì",
    "Definition": "assemble, meet together; meeting",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 486,
    "FrequencyRank": 29
  },
  {
    "Character": "在",
    "Pinyin": "zài",
    "Definition": "be at, in, on; consist in, rest",
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 388,
    "FrequencyRank": 6
  },
  {
    "Character": "过",
    "Pinyin": "guò",
    "Definition": "pass, pass through, go across",
    "Radical": "辵",
    "RadicalIndex": "162.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 379,
    "FrequencyRank": 46
  },
  {
    "Character": "肉",
    "Pinyin": "ròu",
    "Definition": "flesh; meat; KangXi radical 130",
    "Radical": "肉",
    "RadicalIndex": "130",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 443,
    "FrequencyRank": 1009
  },
  {
    "Character": "休",
    "Pinyin": "xiū",
    "Definition": "rest, stop; retire; do not!",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 458,
    "FrequencyRank": 1082
  },
  {
    "Character": "年",
    "Pinyin": "nián",
    "Definition": undefined,
    "Radical": "干",
    "RadicalIndex": "51.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 444,
    "FrequencyRank": 45
  },
  {
    "Character": "师",
    "Pinyin": "shī",
    "Definition": "teacher, master, specialist",
    "Radical": "巾",
    "RadicalIndex": "50.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 413,
    "FrequencyRank": 333
  },
  {
    "Character": "西",
    "Pinyin": "xī",
    "Definition": "west(ern); westward, occident",
    "Radical": "襾",
    "RadicalIndex": "146",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 384,
    "FrequencyRank": 167
  },
  {
    "Character": "回",
    "Pinyin": "huí",
    "Definition": "return, turn around; a time",
    "Radical": "囗",
    "RadicalIndex": "31.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 438,
    "FrequencyRank": 172
  },
  {
    "Character": "有",
    "Pinyin": "yǒu",
    "Definition": "have, own, possess; exist",
    "Radical": "月",
    "RadicalIndex": "74.2",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 390,
    "FrequencyRank": 8
  },
  {
    "Character": "早",
    "Pinyin": "zǎo",
    "Definition": "early; soon; morning",
    "Radical": "日",
    "RadicalIndex": "72.2",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 419,
    "FrequencyRank": 462
  },
  {
    "Character": "兴",
    "Pinyin": "xìng",
    "Definition": "thrive, prosper, flourish",
    "Radical": "八",
    "RadicalIndex": "12.4",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 544,
    "FrequencyRank": 531
  },
  {
    "Character": "机",
    "Pinyin": "jī",
    "Definition": "desk; machine; moment",
    "Radical": "木",
    "RadicalIndex": "75.2",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 377,
    "FrequencyRank": 111
  },
  {
    "Character": "岁",
    "Pinyin": "suì",
    "Definition": "year; age; harvest",
    "Radical": "山",
    "RadicalIndex": "46.3",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 436,
    "FrequencyRank": 772
  },
  {
    "Character": "欢",
    "Pinyin": "huān",
    "Definition": "happy, pleased, glad; joy; enjoy",
    "Radical": "欠",
    "RadicalIndex": "76.2",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 587,
    "FrequencyRank": 685
  },
  {
    "Character": "米",
    "Pinyin": "mǐ",
    "Definition": "hulled or husked uncooked rice",
    "Radical": "米",
    "RadicalIndex": "119",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 533,
    "FrequencyRank": 575
  },
  {
    "Character": "老",
    "Pinyin": "lǎo",
    "Definition": "old, aged; experienced",
    "Radical": "老",
    "RadicalIndex": "125",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 360,
    "FrequencyRank": 179
  },
  {
    "Character": "页",
    "Pinyin": "yè",
    "Definition": "page, sheet, leaf; rad. no. 181",
    "Radical": "頁",
    "RadicalIndex": "181",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 393,
    "FrequencyRank": 1128
  },
  {
    "Character": "百",
    "Pinyin": "bǎi",
    "Definition": "one hundred; numerous, many",
    "Radical": "白",
    "RadicalIndex": "106.1",
    "StrokeCount": 6,
    "HSK": 1,
    "GeneralStandard": 389,
    "FrequencyRank": 407
  },
  {
    "Character": "时",
    "Pinyin": "shí",
    "Definition": "time, season; era, age, period",
    "Radical": "日",
    "RadicalIndex": "72.3",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 717,
    "FrequencyRank": 25
  },
  {
    "Character": "条",
    "Pinyin": "tiáo",
    "Definition": "clause, condition; string, stripe",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 816,
    "FrequencyRank": 214
  },
  {
    "Character": "别",
    "Pinyin": "bié",
    "Definition": "separate, other; do not",
    "Radical": "刀",
    "RadicalIndex": "18.5",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 751,
    "FrequencyRank": 222
  },
  {
    "Character": "冷",
    "Pinyin": "lěng",
    "Definition": "cold, cool; lonely",
    "Radical": "冫",
    "RadicalIndex": "15.5",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 838,
    "FrequencyRank": 700
  },
  {
    "Character": "还",
    "Pinyin": "hái",
    "Definition": "still, yet, also, besides",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 704,
    "FrequencyRank": 80
  },
  {
    "Character": "作",
    "Pinyin": "zuò",
    "Definition": "make; work; compose, write; act, perform",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 777,
    "FrequencyRank": 49
  },
  {
    "Character": "听",
    "Pinyin": "tīng",
    "Definition": "hear; understand; obey, comply",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 739,
    "FrequencyRank": 285
  },
  {
    "Character": "进",
    "Pinyin": "jìn",
    "Definition": "advance, make progress, enter",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 605,
    "FrequencyRank": 81
  },
  {
    "Character": "诉",
    "Pinyin": "sù",
    "Definition": "accuse; sue; inform; narrate",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 894,
    "FrequencyRank": 595
  },
  {
    "Character": "没",
    "Pinyin": "méi",
    "Definition": "not, have not, none; drown, sink",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 867,
    "FrequencyRank": 72
  },
  {
    "Character": "弟",
    "Pinyin": "dì",
    "Definition": "young brother; junior; i, me",
    "Radical": "弓",
    "RadicalIndex": "57.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 854,
    "FrequencyRank": 816
  },
  {
    "Character": "忘",
    "Pinyin": "wàng",
    "Definition": "forget; neglect; miss, omit",
    "Radical": "心",
    "RadicalIndex": "61.3",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 844,
    "FrequencyRank": 1056
  },
  {
    "Character": "床",
    "Pinyin": "chuáng",
    "Definition": "bed, couch; framework, chassis",
    "Radical": "广",
    "RadicalIndex": "53.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 831,
    "FrequencyRank": 1068
  },
  {
    "Character": "系",
    "Pinyin": "xì",
    "Definition": "system; line, link, connection",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 825,
    "FrequencyRank": 216
  },
  {
    "Character": "远",
    "Pinyin": "yuǎn",
    "Definition": "distant, remote, far; profound",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 608,
    "FrequencyRank": 386
  },
  {
    "Character": "块",
    "Pinyin": "kuài",
    "Definition": "piece, lump; dollar",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 652,
    "FrequencyRank": 793
  },
  {
    "Character": "身",
    "Pinyin": "shēn",
    "Definition": "body; trunk, hull; rad. no. 158",
    "Radical": "身",
    "RadicalIndex": "158",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 786,
    "FrequencyRank": 164
  },
  {
    "Character": "汽",
    "Pinyin": "qì",
    "Definition": "steam, vapor, gas",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 861,
    "FrequencyRank": 1200
  },
  {
    "Character": "你",
    "Pinyin": "nǐ",
    "Definition": "you, second person pronoun",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 782,
    "FrequencyRank": 32
  },
  {
    "Character": "坐",
    "Pinyin": "zuò",
    "Definition": "sit; seat; ride, travel by",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 797,
    "FrequencyRank": 611
  },
  {
    "Character": "医",
    "Pinyin": "yī",
    "Definition": "cure, heal; doctor, medical",
    "Radical": "匸",
    "RadicalIndex": "23.5",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 700,
    "FrequencyRank": 482
  },
  {
    "Character": "吧",
    "Pinyin": "ba",
    "Definition": "emphatic final particle",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 747,
    "FrequencyRank": 470
  },
  {
    "Character": "来",
    "Pinyin": "lái",
    "Definition": "come, coming; return, returning",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 707,
    "FrequencyRank": 15
  },
  {
    "Character": "我",
    "Pinyin": "wǒ",
    "Definition": "our, us, i, me, my, we",
    "Radical": "戈",
    "RadicalIndex": "62.3",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 761,
    "FrequencyRank": 9
  },
  {
    "Character": "两",
    "Pinyin": "liǎng",
    "Definition": "two, both, pair, couple; ounce",
    "Radical": "一",
    "RadicalIndex": "1.6",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 697,
    "FrequencyRank": 133
  },
  {
    "Character": "找",
    "Pinyin": "zhǎo",
    "Definition": "search, seek, look for; find",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 621,
    "FrequencyRank": 466
  },
  {
    "Character": "快",
    "Pinyin": "kuài",
    "Definition": "rapid, quick, speedy, fast; soon",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 876,
    "FrequencyRank": 366
  },
  {
    "Character": "男",
    "Pinyin": "nán",
    "Definition": "male, man; son; baron; surname",
    "Radical": "田",
    "RadicalIndex": "102.2",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 733,
    "FrequencyRank": 602
  },
  {
    "Character": "识",
    "Pinyin": "shí",
    "Definition": "recognize, understand, know",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 892,
    "FrequencyRank": 340
  },
  {
    "Character": "饭",
    "Pinyin": "fàn",
    "Definition": "cooked rice; food, meal",
    "Radical": "食",
    "RadicalIndex": "184.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 823,
    "FrequencyRank": 935
  },
  {
    "Character": "体",
    "Pinyin": "tǐ",
    "Definition": "body; group, class, body, unit; inferior",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 770,
    "FrequencyRank": 149
  },
  {
    "Character": "坏",
    "Pinyin": "huài",
    "Definition": "rotten, spoilt, bad, broken down",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 616,
    "FrequencyRank": 832
  },
  {
    "Character": "鸡",
    "Pinyin": "jī",
    "Definition": "chicken",
    "Radical": "鳥",
    "RadicalIndex": "196.2",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 928,
    "FrequencyRank": 1391
  },
  {
    "Character": "这",
    "Pinyin": "zhè",
    "Definition": "this, the, here",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 837,
    "FrequencyRank": 11
  },
  {
    "Character": "住",
    "Pinyin": "zhù",
    "Definition": "reside, live at, dwell, lodge; stop",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 783,
    "FrequencyRank": 309
  },
  {
    "Character": "花",
    "Pinyin": "huā",
    "Definition": "flower; blossoms",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 665,
    "FrequencyRank": 410
  },
  {
    "Character": "告",
    "Pinyin": "gào",
    "Definition": "tell, announce, inform; accuse",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 760,
    "FrequencyRank": 310
  },
  {
    "Character": "间",
    "Pinyin": "jiān",
    "Definition": "midpoint, space; place, locality",
    "Radical": "門",
    "RadicalIndex": "169.4",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 847,
    "FrequencyRank": 135
  },
  {
    "Character": "走",
    "Pinyin": "zǒu",
    "Definition": "walk, go on foot; run; leave",
    "Radical": "走",
    "RadicalIndex": "156",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 625,
    "FrequencyRank": 207
  },
  {
    "Character": "里",
    "Pinyin": "lǐ",
    "Definition": "unit of distance; village; lane",
    "Radical": "里",
    "RadicalIndex": "166",
    "StrokeCount": 7,
    "HSK": 1,
    "GeneralStandard": 721,
    "FrequencyRank": 50
  },
  {
    "Character": "备",
    "Pinyin": "bèi",
    "Definition": "prepare, ready, perfect",
    "Radical": "夂",
    "RadicalIndex": "34.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1200,
    "FrequencyRank": 397
  },
  {
    "Character": "果",
    "Pinyin": "guǒ",
    "Definition": "fruit; result",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1079,
    "FrequencyRank": 165
  },
  {
    "Character": "和",
    "Pinyin": "hé",
    "Definition": "harmony, peace; peaceful, calm",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1131,
    "FrequencyRank": 19
  },
  {
    "Character": "到",
    "Pinyin": "dào",
    "Definition": "go to, arrive, been to",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1063,
    "FrequencyRank": 22
  },
  {
    "Character": "学",
    "Pinyin": "xué",
    "Definition": "learning, knowledge; school",
    "Radical": "子",
    "RadicalIndex": "39.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1273,
    "FrequencyRank": 66
  },
  {
    "Character": "杯",
    "Pinyin": "bēi",
    "Definition": "cup, glass",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1020,
    "FrequencyRank": 1396
  },
  {
    "Character": "非",
    "Pinyin": "fēi",
    "Definition": "not, negative, non-; oppose",
    "Radical": "非",
    "RadicalIndex": "175",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1064,
    "FrequencyRank": 283
  },
  {
    "Character": "图",
    "Pinyin": "tú",
    "Definition": "diagram; chart, map, picture",
    "Radical": "囗",
    "RadicalIndex": "31.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1119,
    "FrequencyRank": 476
  },
  {
    "Character": "妹",
    "Pinyin": "mèi",
    "Definition": "younger sister",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1324,
    "FrequencyRank": 1185
  },
  {
    "Character": "爸",
    "Pinyin": "bà",
    "Definition": "father, papa",
    "Radical": "父",
    "RadicalIndex": "88.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1171,
    "FrequencyRank": 1050
  },
  {
    "Character": "净",
    "Pinyin": "jìng",
    "Definition": "clean, pure; cleanse",
    "Radical": "冫",
    "RadicalIndex": "15.6",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1221,
    "FrequencyRank": 1377
  },
  {
    "Character": "视",
    "Pinyin": "shì",
    "Definition": "look at, inspect, observe, see",
    "Radical": "見",
    "RadicalIndex": "147.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1294,
    "FrequencyRank": 438
  },
  {
    "Character": "现",
    "Pinyin": "xiàn",
    "Definition": "appear, manifest, become visible",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 949,
    "FrequencyRank": 70
  },
  {
    "Character": "房",
    "Pinyin": "fáng",
    "Definition": "house, building; room",
    "Radical": "戶",
    "RadicalIndex": "63.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1290,
    "FrequencyRank": 512
  },
  {
    "Character": "京",
    "Pinyin": "jīng",
    "Definition": "capital city",
    "Radical": "亠",
    "RadicalIndex": "8.6",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1205,
    "FrequencyRank": 566
  },
  {
    "Character": "服",
    "Pinyin": "fú",
    "Definition": "clothes; wear, dress",
    "Radical": "月",
    "RadicalIndex": "74.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1190,
    "FrequencyRank": 365
  },
  {
    "Character": "知",
    "Pinyin": "zhī",
    "Definition": "know, perceive, comprehend",
    "Radical": "矢",
    "RadicalIndex": "111.3",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1122,
    "FrequencyRank": 123
  },
  {
    "Character": "事",
    "Pinyin": "shì",
    "Definition": "affair, matter, business; to serve; accident, incident",
    "Radical": "亅",
    "RadicalIndex": "6.7",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1038,
    "FrequencyRank": 58
  },
  {
    "Character": "雨",
    "Pinyin": "yǔ",
    "Definition": "rain; rainy; KangXi radical 173",
    "Radical": "雨",
    "RadicalIndex": "173",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1041,
    "FrequencyRank": 928
  },
  {
    "Character": "明",
    "Pinyin": "míng",
    "Definition": "bright, light, brilliant; clear",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1087,
    "FrequencyRank": 121
  },
  {
    "Character": "姐",
    "Pinyin": "jiě",
    "Definition": "elder sister, young lady",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1326,
    "FrequencyRank": 830
  },
  {
    "Character": "些",
    "Pinyin": "xiē",
    "Definition": "little, few; rather, somewhat",
    "Radical": "二",
    "RadicalIndex": "7.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1069,
    "FrequencyRank": 86
  },
  {
    "Character": "的",
    "Pinyin": "de",
    "Definition": "possessive, adjectival suffix",
    "Radical": "白",
    "RadicalIndex": "106.3",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1155,
    "FrequencyRank": 1
  },
  {
    "Character": "朋",
    "Pinyin": "péng",
    "Definition": "friend, pal, acquaintance",
    "Radical": "月",
    "RadicalIndex": "74.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1185,
    "FrequencyRank": 882
  },
  {
    "Character": "放",
    "Pinyin": "fàng",
    "Definition": "put, release, free, liberate",
    "Radical": "攴",
    "RadicalIndex": "66.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1223,
    "FrequencyRank": 291
  },
  {
    "Character": "话",
    "Pinyin": "huà",
    "Definition": "speech, talk, language; dialect",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1296,
    "FrequencyRank": 170
  },
  {
    "Character": "呢",
    "Pinyin": "ne",
    "Definition": "interrogative or emphatic final; (Cant.) this",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1102,
    "FrequencyRank": 383
  },
  {
    "Character": "国",
    "Pinyin": "guó",
    "Definition": "nation, country, nation-state",
    "Radical": "囗",
    "RadicalIndex": "31.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1081,
    "FrequencyRank": 20
  },
  {
    "Character": "试",
    "Pinyin": "shì",
    "Definition": "test, try, experiment",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1286,
    "FrequencyRank": 643
  },
  {
    "Character": "店",
    "Pinyin": "diàn",
    "Definition": "shop, store; inn, hotel",
    "Radical": "广",
    "RadicalIndex": "53.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1208,
    "FrequencyRank": 1041
  },
  {
    "Character": "绍",
    "Pinyin": "shào",
    "Definition": "continue, carry on; join",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 1348,
    "FrequencyRank": 1234
  },
  {
    "Character": "玩",
    "Pinyin": "wán",
    "Definition": "play with, joke, enjoy",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": 1,
    "GeneralStandard": 944,
    "FrequencyRank": 1072
  },
  {
    "Character": "觉",
    "Pinyin": "jué",
    "Definition": "to wake up from sleep; conscious",
    "Radical": "見",
    "RadicalIndex": "147.5",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1694,
    "FrequencyRank": 327
  },
  {
    "Character": "昨",
    "Pinyin": "zuó",
    "Definition": "yesterday; in former times, past",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1489,
    "FrequencyRank": 1475
  },
  {
    "Character": "洗",
    "Pinyin": "xǐ",
    "Definition": "wash, rinse; clean, purify",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1671,
    "FrequencyRank": 1247
  },
  {
    "Character": "南",
    "Pinyin": "nán",
    "Definition": "south; southern part; southward",
    "Radical": "十",
    "RadicalIndex": "24.7",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1422,
    "FrequencyRank": 307
  },
  {
    "Character": "客",
    "Pinyin": "kè",
    "Definition": "guest, traveller; customer",
    "Radical": "宀",
    "RadicalIndex": "40.6",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1703,
    "FrequencyRank": 583
  },
  {
    "Character": "茶",
    "Pinyin": "chá",
    "Definition": "tea",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1411,
    "FrequencyRank": 1272
  },
  {
    "Character": "贵",
    "Pinyin": "guì",
    "Definition": "expensive, costly, valuable",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1495,
    "FrequencyRank": 873
  },
  {
    "Character": "树",
    "Pinyin": "shù",
    "Definition": "tree; plant; set up, establish",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1439,
    "FrequencyRank": 697
  },
  {
    "Character": "点",
    "Pinyin": "diǎn",
    "Definition": "dot, speck, spot; point, degree",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1469,
    "FrequencyRank": 128
  },
  {
    "Character": "孩",
    "Pinyin": "hái",
    "Definition": "baby, child; children",
    "Radical": "子",
    "RadicalIndex": "39.6",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1730,
    "FrequencyRank": 533
  },
  {
    "Character": "很",
    "Pinyin": "hěn",
    "Definition": "very, quite, much",
    "Radical": "彳",
    "RadicalIndex": "60.6",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1584,
    "FrequencyRank": 138
  },
  {
    "Character": "星",
    "Pinyin": "xīng",
    "Definition": "a star, planet; any point of light",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1488,
    "FrequencyRank": 537
  },
  {
    "Character": "重",
    "Pinyin": "zhòng",
    "Definition": "heavy, weighty; double",
    "Radical": "里",
    "RadicalIndex": "166.2",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1552,
    "FrequencyRank": 140
  },
  {
    "Character": "看",
    "Pinyin": "kàn",
    "Definition": "look, see; examine, scrutinize",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1539,
    "FrequencyRank": 76
  },
  {
    "Character": "怎",
    "Pinyin": "zěn",
    "Definition": "what? why? how?",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1543,
    "FrequencyRank": 382
  },
  {
    "Character": "说",
    "Pinyin": "shuō",
    "Definition": "speak, say, talk; scold, upbraid",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1717,
    "FrequencyRank": 24
  },
  {
    "Character": "面",
    "Pinyin": "miàn",
    "Definition": "face; surface; plane; side, dimension",
    "Radical": "面",
    "RadicalIndex": "176",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1455,
    "FrequencyRank": 74
  },
  {
    "Character": "院",
    "Pinyin": "yuàn",
    "Definition": "courtyard, yard, court; school",
    "Radical": "阜",
    "RadicalIndex": "170.7",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1734,
    "FrequencyRank": 338
  },
  {
    "Character": "是",
    "Pinyin": "shì",
    "Definition": "indeed, yes, right; to be; demonstrative pronoun, this, that",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1479,
    "FrequencyRank": 3
  },
  {
    "Character": "要",
    "Pinyin": "yào",
    "Definition": "necessary, essential; necessity",
    "Radical": "襾",
    "RadicalIndex": "146.3",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1441,
    "FrequencyRank": 26
  },
  {
    "Character": "给",
    "Pinyin": "gěi",
    "Definition": "give; by, for",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1758,
    "FrequencyRank": 180
  },
  {
    "Character": "穿",
    "Pinyin": "chuān",
    "Definition": "penetrate, pierce, drill; wear",
    "Radical": "穴",
    "RadicalIndex": "116.4",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1701,
    "FrequencyRank": 785
  },
  {
    "Character": "帮",
    "Pinyin": "bāng",
    "Definition": "help, assist; defend; shoe upper",
    "Radical": "巾",
    "RadicalIndex": "50.7",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1356,
    "FrequencyRank": 769
  },
  {
    "Character": "差",
    "Pinyin": "chà",
    "Definition": "to differ; different, wrong; nearly, almost; an officer",
    "Radical": "工",
    "RadicalIndex": "48.7",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1640,
    "FrequencyRank": 732
  },
  {
    "Character": "前",
    "Pinyin": "qián",
    "Definition": "in front, forward; preceding",
    "Radical": "刀",
    "RadicalIndex": "18.7",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1650,
    "FrequencyRank": 93
  },
  {
    "Character": "语",
    "Pinyin": "yǔ",
    "Definition": "language, words; saying, expression",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1707,
    "FrequencyRank": 493
  },
  {
    "Character": "送",
    "Pinyin": "sòng",
    "Definition": "see off, send off; dispatch, give",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1645,
    "FrequencyRank": 656
  },
  {
    "Character": "哪",
    "Pinyin": "nǎ",
    "Definition": "which? where? how?",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 9,
    "HSK": 1,
    "GeneralStandard": 1515,
    "FrequencyRank": 652
  },
  {
    "Character": "哥",
    "Pinyin": "gē",
    "Definition": "elder brother",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1852,
    "FrequencyRank": 804
  },
  {
    "Character": "病",
    "Pinyin": "bìng",
    "Definition": "illness, sickness, disease",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2027,
    "FrequencyRank": 427
  },
  {
    "Character": "班",
    "Pinyin": "bān",
    "Definition": "class, group, grade; squad; job",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1774,
    "FrequencyRank": 884
  },
  {
    "Character": "热",
    "Pinyin": "rè",
    "Definition": "hot; heat; fever; restless; zeal",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1809,
    "FrequencyRank": 606
  },
  {
    "Character": "息",
    "Pinyin": "xī",
    "Definition": "rest, put stop to, end, cease",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1973,
    "FrequencyRank": 428
  },
  {
    "Character": "拿",
    "Pinyin": "ná",
    "Definition": "take, hold, grasp; bring; with",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1983,
    "FrequencyRank": 645
  },
  {
    "Character": "都",
    "Pinyin": "dōu",
    "Definition": "metropolis, capital; all, the whole; elegant, refined",
    "Radical": "邑",
    "RadicalIndex": "163.9",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1801,
    "FrequencyRank": 68
  },
  {
    "Character": "起",
    "Pinyin": "qǐ",
    "Definition": "rise, stand up; go up; begin",
    "Radical": "走",
    "RadicalIndex": "156.3",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1789,
    "FrequencyRank": 75
  },
  {
    "Character": "桌",
    "Pinyin": "zhuō",
    "Definition": "table, desk, stand",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1881,
    "FrequencyRank": 1193
  },
  {
    "Character": "钱",
    "Pinyin": "qián",
    "Definition": "money, currency, coins",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1928,
    "FrequencyRank": 603
  },
  {
    "Character": "真",
    "Pinyin": "zhēn",
    "Definition": "real, actual, true, genuine",
    "Radical": "目",
    "RadicalIndex": "109.5",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1832,
    "FrequencyRank": 204
  },
  {
    "Character": "脑",
    "Pinyin": "nǎo",
    "Definition": "brain",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2001,
    "FrequencyRank": 646
  },
  {
    "Character": "课",
    "Pinyin": "kè",
    "Definition": "lesson; course; classwork",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2120,
    "FrequencyRank": 1208
  },
  {
    "Character": "准",
    "Pinyin": "zhǔn",
    "Definition": "approve, allow, permit; in accord",
    "Radical": "冫",
    "RadicalIndex": "15.8",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2024,
    "FrequencyRank": 379
  },
  {
    "Character": "难",
    "Pinyin": "nán",
    "Definition": "difficult, arduous, hard; unable",
    "Radical": "又",
    "RadicalIndex": "29.8",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2147,
    "FrequencyRank": 295
  },
  {
    "Character": "能",
    "Pinyin": "néng",
    "Definition": "to be able; can, permitted to; ability",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2146,
    "FrequencyRank": 35
  },
  {
    "Character": "校",
    "Pinyin": "xiào",
    "Definition": "school; military field officer",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1847,
    "FrequencyRank": 633
  },
  {
    "Character": "家",
    "Pinyin": "jiā",
    "Definition": "house, home, residence; family",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2099,
    "FrequencyRank": 56
  },
  {
    "Character": "爱",
    "Pinyin": "ài",
    "Definition": "love, be fond of, like",
    "Radical": "爪",
    "RadicalIndex": "87.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1987,
    "FrequencyRank": 394
  },
  {
    "Character": "高",
    "Pinyin": "gāo",
    "Definition": "high, tall; lofty, elevated",
    "Radical": "高",
    "RadicalIndex": "189",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2021,
    "FrequencyRank": 134
  },
  {
    "Character": "谁",
    "Pinyin": "shuí",
    "Definition": "who? whom? whose? anyone?",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2122,
    "FrequencyRank": 648
  },
  {
    "Character": "笑",
    "Pinyin": "xiào",
    "Definition": "smile, laugh, giggle; snicker",
    "Radical": "竹",
    "RadicalIndex": "118.4",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1952,
    "FrequencyRank": 346
  },
  {
    "Character": "站",
    "Pinyin": "zhàn",
    "Definition": "stand up; a stand, station",
    "Radical": "立",
    "RadicalIndex": "117.5",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2041,
    "FrequencyRank": 544
  },
  {
    "Character": "候",
    "Pinyin": "hòu",
    "Definition": "wait; expect; visit; greet",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1964,
    "FrequencyRank": 341
  },
  {
    "Character": "样",
    "Pinyin": "yàng",
    "Definition": "shape, form, pattern, style",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 1849,
    "FrequencyRank": 88
  },
  {
    "Character": "饿",
    "Pinyin": "è",
    "Definition": "hungry; greedy for; hunger",
    "Radical": "食",
    "RadicalIndex": "184.7",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2012,
    "FrequencyRank": 1911
  },
  {
    "Character": "旁",
    "Pinyin": "páng",
    "Definition": "side; by side, close by, near",
    "Radical": "方",
    "RadicalIndex": "70.6",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2045,
    "FrequencyRank": 981
  },
  {
    "Character": "读",
    "Pinyin": "dú",
    "Definition": "read, study; pronounce",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2112,
    "FrequencyRank": 752
  },
  {
    "Character": "请",
    "Pinyin": "qǐng",
    "Definition": "ask, request; invite; please",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 1,
    "GeneralStandard": 2108,
    "FrequencyRank": 421
  },
  {
    "Character": "晚",
    "Pinyin": "wǎn",
    "Definition": "night, evening; late",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2270,
    "FrequencyRank": 641
  },
  {
    "Character": "常",
    "Pinyin": "cháng",
    "Definition": "common, normal, frequent, regular",
    "Radical": "巾",
    "RadicalIndex": "50.8",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2257,
    "FrequencyRank": 187
  },
  {
    "Character": "您",
    "Pinyin": "nín",
    "Definition": undefined,
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2331,
    "FrequencyRank": 726
  },
  {
    "Character": "着",
    "Pinyin": "zhe",
    "Definition": "make move, take action",
    "Radical": "目",
    "RadicalIndex": "109.6",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2398,
    "FrequencyRank": 41
  },
  {
    "Character": "累",
    "Pinyin": "lèi",
    "Definition": "tired; implicate, involve; bother",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2282,
    "FrequencyRank": 1323
  },
  {
    "Character": "做",
    "Pinyin": "zuò",
    "Definition": "work, make; act",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2324,
    "FrequencyRank": 246
  },
  {
    "Character": "商",
    "Pinyin": "shāng",
    "Definition": "commerce, business, trade",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2391,
    "FrequencyRank": 402
  },
  {
    "Character": "票",
    "Pinyin": "piào",
    "Definition": "slip of paper or bamboo; ticket",
    "Radical": "示",
    "RadicalIndex": "113.6",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2235,
    "FrequencyRank": 910
  },
  {
    "Character": "第",
    "Pinyin": "dì",
    "Definition": "sequence, number; grade, degree",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2322,
    "FrequencyRank": 114
  },
  {
    "Character": "馆",
    "Pinyin": "guǎn",
    "Definition": "public building",
    "Radical": "食",
    "RadicalIndex": "184.8",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2373,
    "FrequencyRank": 1011
  },
  {
    "Character": "蛋",
    "Pinyin": "dàn",
    "Definition": "eggs; term of abuse",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2477,
    "FrequencyRank": 1387
  },
  {
    "Character": "菜",
    "Pinyin": "cài",
    "Definition": "vegetables; dish, order; food",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2207,
    "FrequencyRank": 1266
  },
  {
    "Character": "得",
    "Pinyin": "dé",
    "Definition": "obtain, get, gain, acquire",
    "Radical": "彳",
    "RadicalIndex": "60.8",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2341,
    "FrequencyRank": 39
  },
  {
    "Character": "教",
    "Pinyin": "jiào",
    "Definition": "teach, class",
    "Radical": "攴",
    "RadicalIndex": "66.7",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2179,
    "FrequencyRank": 191
  },
  {
    "Character": "假",
    "Pinyin": "jiǎ",
    "Definition": "falsehood, deception; vacation",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2337,
    "FrequencyRank": 636
  },
  {
    "Character": "唱",
    "Pinyin": "chàng",
    "Definition": "sing, chant, call; ditty, song",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2284,
    "FrequencyRank": 1252
  },
  {
    "Character": "球",
    "Pinyin": "qiú",
    "Definition": "ball, sphere, globe; round",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": 1,
    "GeneralStandard": 2155,
    "FrequencyRank": 628
  },
  {
    "Character": "喝",
    "Pinyin": "hē",
    "Definition": "drink; shout, call out",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2639,
    "FrequencyRank": 983
  },
  {
    "Character": "期",
    "Pinyin": "qī",
    "Definition": "period of time; date; time limit",
    "Radical": "月",
    "RadicalIndex": "74.8",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2542,
    "FrequencyRank": 253
  },
  {
    "Character": "答",
    "Pinyin": "dá",
    "Definition": "answer, reply; return; assent to",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2687,
    "FrequencyRank": 559
  },
  {
    "Character": "谢",
    "Pinyin": "xiè",
    "Definition": "thank; decline",
    "Radical": "言",
    "RadicalIndex": "149.1",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2795,
    "FrequencyRank": 897
  },
  {
    "Character": "等",
    "Pinyin": "děng",
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2681,
    "FrequencyRank": 158
  },
  {
    "Character": "喜",
    "Pinyin": "xǐ",
    "Definition": "like, love, enjoy; joyful thing",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2523,
    "FrequencyRank": 668
  },
  {
    "Character": "道",
    "Pinyin": "dào",
    "Definition": "path, road, street; method, way",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2745,
    "FrequencyRank": 52
  },
  {
    "Character": "跑",
    "Pinyin": "pǎo",
    "Definition": "run, flee, leave in hurry",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2631,
    "FrequencyRank": 864
  },
  {
    "Character": "就",
    "Pinyin": "jiù",
    "Definition": "just, simply; to come, go to; to approach, near",
    "Radical": "尢",
    "RadicalIndex": "43.9",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2728,
    "FrequencyRank": 27
  },
  {
    "Character": "渴",
    "Pinyin": "kě",
    "Definition": "thirsty, parched; yearn, pine",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2758,
    "FrequencyRank": 1972
  },
  {
    "Character": "最",
    "Pinyin": "zuì",
    "Definition": "most, extremely, exceedingly",
    "Radical": "曰",
    "RadicalIndex": "73.8",
    "StrokeCount": 12,
    "HSK": 1,
    "GeneralStandard": 2614,
    "FrequencyRank": 139
  },
  {
    "Character": "想",
    "Pinyin": "xiǎng",
    "Definition": "think, speculate, plan, consider",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 13,
    "HSK": 1,
    "GeneralStandard": 2867,
    "FrequencyRank": 99
  },
  {
    "Character": "新",
    "Pinyin": "xīn",
    "Definition": "new, recent, fresh, modern",
    "Radical": "斤",
    "RadicalIndex": "69.9",
    "StrokeCount": 13,
    "HSK": 1,
    "GeneralStandard": 2994,
    "FrequencyRank": 161
  },
  {
    "Character": "跟",
    "Pinyin": "gēn",
    "Definition": "heel; to follow, accompany; with",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": 1,
    "GeneralStandard": 2920,
    "FrequencyRank": 541
  },
  {
    "Character": "路",
    "Pinyin": "lù",
    "Definition": "road, path, street; journey",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": 1,
    "GeneralStandard": 2918,
    "FrequencyRank": 305
  },
  {
    "Character": "楼",
    "Pinyin": "lóu",
    "Definition": "building of two or more stories",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": 1,
    "GeneralStandard": 2870,
    "FrequencyRank": 876
  },
  {
    "Character": "错",
    "Pinyin": "cuò",
    "Definition": "error, blunder, mistake; wrong",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": 1,
    "GeneralStandard": 2936,
    "FrequencyRank": 638
  },
  {
    "Character": "零",
    "Pinyin": "líng",
    "Definition": "zero; fragment, fraction",
    "Radical": "雨",
    "RadicalIndex": "173.5",
    "StrokeCount": 13,
    "HSK": 1,
    "GeneralStandard": 2885,
    "FrequencyRank": 1342
  },
  {
    "Character": "睡",
    "Pinyin": "shuì",
    "Definition": "sleep, doze",
    "Radical": "目",
    "RadicalIndex": "109.8",
    "StrokeCount": 13,
    "HSK": 1,
    "GeneralStandard": 2900,
    "FrequencyRank": 964
  },
  {
    "Character": "慢",
    "Pinyin": "màn",
    "Definition": "slow(ly), leisurely, sluggish",
    "Radical": "心",
    "RadicalIndex": "61.11",
    "StrokeCount": 14,
    "HSK": 1,
    "GeneralStandard": 3169,
    "FrequencyRank": 822
  },
  {
    "Character": "歌",
    "Pinyin": "gē",
    "Definition": "song, lyrics; sing, chant; praise",
    "Radical": "欠",
    "RadicalIndex": "76.1",
    "StrokeCount": 14,
    "HSK": 1,
    "GeneralStandard": 3084,
    "FrequencyRank": 1040
  },
  {
    "Character": "影",
    "Pinyin": "yǐng",
    "Definition": "shadow; image, reflection; photograph",
    "Radical": "彡",
    "RadicalIndex": "59.12",
    "StrokeCount": 15,
    "HSK": 1,
    "GeneralStandard": 3240,
    "FrequencyRank": 390
  },
  {
    "Character": "又",
    "Pinyin": "yòu",
    "Definition": "and, also, again, in addition",
    "Radical": "又",
    "RadicalIndex": "29",
    "StrokeCount": 2,
    "HSK": 2,
    "GeneralStandard": 21,
    "FrequencyRank": 126
  },
  {
    "Character": "力",
    "Pinyin": "lì",
    "Definition": "power, capability, influence",
    "Radical": "力",
    "RadicalIndex": "19",
    "StrokeCount": 2,
    "HSK": 2,
    "GeneralStandard": 19,
    "FrequencyRank": 106
  },
  {
    "Character": "入",
    "Pinyin": "rù",
    "Definition": "enter, come in(to), join",
    "Radical": "入",
    "RadicalIndex": "11",
    "StrokeCount": 2,
    "HSK": 2,
    "GeneralStandard": 11,
    "FrequencyRank": 210
  },
  {
    "Character": "己",
    "Pinyin": "jǐ",
    "Definition": "self, oneself; personal, private; 6th heavenly stem",
    "Radical": "己",
    "RadicalIndex": "49",
    "StrokeCount": 3,
    "HSK": 2,
    "GeneralStandard": 60,
    "FrequencyRank": 162
  },
  {
    "Character": "亿",
    "Pinyin": "yì",
    "Definition": "hundred million; many",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 3,
    "HSK": 2,
    "GeneralStandard": 44,
    "FrequencyRank": 1057
  },
  {
    "Character": "已",
    "Pinyin": "yǐ",
    "Definition": "already; finished; stop",
    "Radical": "己",
    "RadicalIndex": "49",
    "StrokeCount": 3,
    "HSK": 2,
    "GeneralStandard": 61,
    "FrequencyRank": 117
  },
  {
    "Character": "千",
    "Pinyin": "qiān",
    "Definition": "thousand; many, numerous; very; (Cant.) a cheater, swindler",
    "Radical": "十",
    "RadicalIndex": "24.1",
    "StrokeCount": 3,
    "HSK": 2,
    "GeneralStandard": 41,
    "FrequencyRank": 599
  },
  {
    "Character": "万",
    "Pinyin": "wàn",
    "Definition": "ten thousand; innumerable",
    "Radical": "一",
    "RadicalIndex": "1.2",
    "StrokeCount": 3,
    "HSK": 2,
    "GeneralStandard": 35,
    "FrequencyRank": 322
  },
  {
    "Character": "广",
    "Pinyin": "guǎng",
    "Definition": "wide, extensive, broad; rad. 53",
    "Radical": "广",
    "RadicalIndex": "53",
    "StrokeCount": 3,
    "HSK": 2,
    "GeneralStandard": 53,
    "FrequencyRank": 468
  },
  {
    "Character": "久",
    "Pinyin": "jiǔ",
    "Definition": "long time (ago); time passage, grow late",
    "Radical": "丿",
    "RadicalIndex": "4.2",
    "StrokeCount": 3,
    "HSK": 2,
    "GeneralStandard": 47,
    "FrequencyRank": 637
  },
  {
    "Character": "于",
    "Pinyin": "yú",
    "Definition": "in, on, at; go to; surname",
    "Radical": "二",
    "RadicalIndex": "7.1",
    "StrokeCount": 3,
    "HSK": 2,
    "GeneralStandard": 24,
    "FrequencyRank": 40
  },
  {
    "Character": "才",
    "Pinyin": "cái",
    "Definition": "talent, ability; just, only",
    "Radical": "手",
    "RadicalIndex": "64",
    "StrokeCount": 3,
    "HSK": 2,
    "GeneralStandard": 29,
    "FrequencyRank": 235
  },
  {
    "Character": "办",
    "Pinyin": "bàn",
    "Definition": "manage, do, handle; deal with",
    "Radical": "力",
    "RadicalIndex": "19.2",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 181,
    "FrequencyRank": 367
  },
  {
    "Character": "长",
    "Pinyin": "zhǎng",
    "Definition": "long; length; excel in; leader",
    "Radical": "長",
    "RadicalIndex": "168",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 127,
    "FrequencyRank": 109
  },
  {
    "Character": "王",
    "Pinyin": "wáng",
    "Definition": "king, ruler; royal; surname",
    "Radical": "玉",
    "RadicalIndex": "96",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 75,
    "FrequencyRank": 299
  },
  {
    "Character": "片",
    "Pinyin": "piàn",
    "Definition": "slice, splinter, strip; rad. 91",
    "Radical": "片",
    "RadicalIndex": "91",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 130,
    "FrequencyRank": 455
  },
  {
    "Character": "心",
    "Pinyin": "xīn",
    "Definition": "heart; mind, intelligence; soul",
    "Radical": "心",
    "RadicalIndex": "61",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 174,
    "FrequencyRank": 90
  },
  {
    "Character": "计",
    "Pinyin": "jì",
    "Definition": "plan, plot; strategem; scheme",
    "Radical": "言",
    "RadicalIndex": "149.2",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 168,
    "FrequencyRank": 251
  },
  {
    "Character": "斤",
    "Pinyin": "jīn",
    "Definition": "a catty (approximately 500 g); an axe; keen, shrewd; KangXi radical number 69",
    "Radical": "斤",
    "RadicalIndex": "69",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 137,
    "FrequencyRank": 1866
  },
  {
    "Character": "云",
    "Pinyin": "yún",
    "Definition": "say, speak; clouds",
    "Radical": "二",
    "RadicalIndex": "7.2",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 82,
    "FrequencyRank": 692
  },
  {
    "Character": "为",
    "Pinyin": "wèi",
    "Definition": "do, handle, govern, act; be",
    "Radical": "丶",
    "RadicalIndex": "3.3",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 165,
    "FrequencyRank": 18
  },
  {
    "Character": "以",
    "Pinyin": "yǐ",
    "Definition": "by means of; thereby, therefore; consider as; in order to",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 182,
    "FrequencyRank": 23
  },
  {
    "Character": "公",
    "Pinyin": "gōng",
    "Definition": "fair, equitable; public; duke",
    "Radical": "八",
    "RadicalIndex": "12.2",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 148,
    "FrequencyRank": 115
  },
  {
    "Character": "队",
    "Pinyin": "duì",
    "Definition": "team, group; army unit",
    "Radical": "阜",
    "RadicalIndex": "170.2",
    "StrokeCount": 4,
    "HSK": 2,
    "GeneralStandard": 180,
    "FrequencyRank": 268
  },
  {
    "Character": "卡",
    "Pinyin": "kǎ",
    "Definition": "card, punch card; calorie",
    "Radical": "卜",
    "RadicalIndex": "25.3",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 226,
    "FrequencyRank": 717
  },
  {
    "Character": "句",
    "Pinyin": "jù",
    "Definition": "sentence",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 288,
    "FrequencyRank": 707
  },
  {
    "Character": "由",
    "Pinyin": "yóu",
    "Definition": "cause, reason; from",
    "Radical": "田",
    "RadicalIndex": "102",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 245,
    "FrequencyRank": 136
  },
  {
    "Character": "让",
    "Pinyin": "ràng",
    "Definition": "allow, permit, yield, concede",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 317,
    "FrequencyRank": 339
  },
  {
    "Character": "鸟",
    "Pinyin": "niǎo",
    "Definition": "bird; KangXi radical 196",
    "Radical": "鳥",
    "RadicalIndex": "196",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 296,
    "FrequencyRank": 1263
  },
  {
    "Character": "只",
    "Pinyin": "zhǐ",
    "Definition": "only, just, simply",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 246,
    "FrequencyRank": 97
  },
  {
    "Character": "讨",
    "Pinyin": "tǎo",
    "Definition": "to discuss; ask for, beg; demand; dun; marry",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 315,
    "FrequencyRank": 833
  },
  {
    "Character": "冬",
    "Pinyin": "dōng",
    "Definition": "winter, 11th lunar month",
    "Radical": "冫",
    "RadicalIndex": "15.3",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 295,
    "FrequencyRank": 1384
  },
  {
    "Character": "处",
    "Pinyin": "chù",
    "Definition": "place, locale; department",
    "Radical": "夂",
    "RadicalIndex": "34.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 294,
    "FrequencyRank": 206
  },
  {
    "Character": "头",
    "Pinyin": "tóu",
    "Definition": "head; top; chief, first; boss",
    "Radical": "大",
    "RadicalIndex": "37.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 310,
    "FrequencyRank": 147
  },
  {
    "Character": "发",
    "Pinyin": "fā",
    "Definition": "issue, dispatch, send out; hair",
    "Radical": "又",
    "RadicalIndex": "29.3",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 339,
    "FrequencyRank": 47
  },
  {
    "Character": "可",
    "Pinyin": "kě",
    "Definition": "may, can, -able; possibly",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 212,
    "FrequencyRank": 30
  },
  {
    "Character": "平",
    "Pinyin": "píng",
    "Definition": "flat, level, even; peaceful",
    "Radical": "干",
    "RadicalIndex": "51.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 222,
    "FrequencyRank": 215
  },
  {
    "Character": "礼",
    "Pinyin": "lǐ",
    "Definition": "social custom; manners; courtesy",
    "Radical": "示",
    "RadicalIndex": "113.1",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 318,
    "FrequencyRank": 926
  },
  {
    "Character": "末",
    "Pinyin": "mò",
    "Definition": "final, last, end; insignificant",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 193,
    "FrequencyRank": 1164
  },
  {
    "Character": "乐",
    "Pinyin": "lè",
    "Definition": "happy, glad; enjoyable; music",
    "Radical": "丿",
    "RadicalIndex": "4.4",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 287,
    "FrequencyRank": 619
  },
  {
    "Character": "市",
    "Pinyin": "shì",
    "Definition": "market, fair; city, town; trade",
    "Radical": "巾",
    "RadicalIndex": "50.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 301,
    "FrequencyRank": 254
  },
  {
    "Character": "且",
    "Pinyin": "qiě",
    "Definition": "moreover, also (post-subject); about to, will soon (pre-verb)",
    "Radical": "一",
    "RadicalIndex": "1.4",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 237,
    "FrequencyRank": 296
  },
  {
    "Character": "必",
    "Pinyin": "bì",
    "Definition": "surely, most certainly; must",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 321,
    "FrequencyRank": 248
  },
  {
    "Character": "业",
    "Pinyin": "yè",
    "Definition": "profession, business; GB radical 111",
    "Radical": "一",
    "RadicalIndex": "1.4",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 231,
    "FrequencyRank": 130
  },
  {
    "Character": "示",
    "Pinyin": "shì",
    "Definition": "show, manifest; demonstrate",
    "Radical": "示",
    "RadicalIndex": "113",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 194,
    "FrequencyRank": 425
  },
  {
    "Character": "印",
    "Pinyin": "yìn",
    "Definition": "print, seal, stamp, chop, mark",
    "Radical": "卩",
    "RadicalIndex": "26.4",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 285,
    "FrequencyRank": 640
  },
  {
    "Character": "节",
    "Pinyin": "jié",
    "Definition": "knot, node, joint; section",
    "Radical": "艸",
    "RadicalIndex": "140.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 209,
    "FrequencyRank": 514
  },
  {
    "Character": "占",
    "Pinyin": "zhàn",
    "Definition": "divine; observe; versify",
    "Radical": "卜",
    "RadicalIndex": "25.3",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 228,
    "FrequencyRank": 737
  },
  {
    "Character": "加",
    "Pinyin": "jiā",
    "Definition": "add to, increase, augment",
    "Radical": "力",
    "RadicalIndex": "19.3",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 335,
    "FrequencyRank": 166
  },
  {
    "Character": "永",
    "Pinyin": "yǒng",
    "Definition": "long, perpetual, eternal, forever",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 324,
    "FrequencyRank": 842
  },
  {
    "Character": "务",
    "Pinyin": "wù",
    "Definition": "affairs, business; must, should",
    "Radical": "夂",
    "RadicalIndex": "34.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 297,
    "FrequencyRank": 245
  },
  {
    "Character": "主",
    "Pinyin": "zhǔ",
    "Definition": "master, chief owner; host; lord",
    "Radical": "丶",
    "RadicalIndex": "3.4",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 300,
    "FrequencyRank": 87
  },
  {
    "Character": "司",
    "Pinyin": "sī",
    "Definition": "take charge of, control, manage; officer",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 325,
    "FrequencyRank": 278
  },
  {
    "Character": "它",
    "Pinyin": "tā",
    "Definition": "it; other",
    "Radical": "宀",
    "RadicalIndex": "40.2",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 314,
    "FrequencyRank": 107
  },
  {
    "Character": "目",
    "Pinyin": "mù",
    "Definition": "eye; look, see; division, topic",
    "Radical": "目",
    "RadicalIndex": "109",
    "StrokeCount": 5,
    "HSK": 2,
    "GeneralStandard": 236,
    "FrequencyRank": 239
  },
  {
    "Character": "灯",
    "Pinyin": "dēng",
    "Definition": "lantern, lamp",
    "Radical": "火",
    "RadicalIndex": "86.2",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 534,
    "FrequencyRank": 1115
  },
  {
    "Character": "阳",
    "Pinyin": "yáng",
    "Definition": undefined,
    "Radical": "阜",
    "RadicalIndex": "170.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 572,
    "FrequencyRank": 650
  },
  {
    "Character": "共",
    "Pinyin": "gòng",
    "Definition": "together with, all, total; to share",
    "Radical": "八",
    "RadicalIndex": "12.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 371,
    "FrequencyRank": 330
  },
  {
    "Character": "级",
    "Pinyin": "jí",
    "Definition": "level, rank, class; grade",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 594,
    "FrequencyRank": 415
  },
  {
    "Character": "划",
    "Pinyin": "huà",
    "Definition": "to row or paddle boat; to scratch",
    "Radical": "刀",
    "RadicalIndex": "18.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 407,
    "FrequencyRank": 522
  },
  {
    "Character": "自",
    "Pinyin": "zì",
    "Definition": "self, private, personal; from",
    "Radical": "自",
    "RadicalIndex": "132",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 477,
    "FrequencyRank": 43
  },
  {
    "Character": "当",
    "Pinyin": "dāng",
    "Definition": "bear, accept, undertake; just",
    "Radical": "小",
    "RadicalIndex": "42.3",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 418,
    "FrequencyRank": 71
  },
  {
    "Character": "许",
    "Pinyin": "xǔ",
    "Definition": "allow, permit; promise; betroth",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 554,
    "FrequencyRank": 263
  },
  {
    "Character": "色",
    "Pinyin": "sè",
    "Definition": "color, tint, hue, shade; form, body; beauty, desire for beauty",
    "Radical": "色",
    "RadicalIndex": "139",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 509,
    "FrequencyRank": 304
  },
  {
    "Character": "交",
    "Pinyin": "jiāo",
    "Definition": "mix; intersect; exchange, communicate; deliver",
    "Radical": "亠",
    "RadicalIndex": "8.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 519,
    "FrequencyRank": 320
  },
  {
    "Character": "讲",
    "Pinyin": "jiǎng",
    "Definition": "explain; discuss; talk",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 550,
    "FrequencyRank": 555
  },
  {
    "Character": "观",
    "Pinyin": "guān",
    "Definition": "see, observe, view; appearance",
    "Radical": "見",
    "RadicalIndex": "147.2",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 586,
    "FrequencyRank": 334
  },
  {
    "Character": "而",
    "Pinyin": "ér",
    "Definition": "and; and then; and yet; but",
    "Radical": "而",
    "RadicalIndex": "126",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 392,
    "FrequencyRank": 36
  },
  {
    "Character": "红",
    "Pinyin": "hóng",
    "Definition": "red, vermillion; blush, flush",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 589,
    "FrequencyRank": 502
  },
  {
    "Character": "份",
    "Pinyin": "fèn",
    "Definition": "portion, part; duty",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 471,
    "FrequencyRank": 784
  },
  {
    "Character": "如",
    "Pinyin": "rú",
    "Definition": "if, supposing; as if; like, as",
    "Radical": "女",
    "RadicalIndex": "38.3",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 578,
    "FrequencyRank": 67
  },
  {
    "Character": "刚",
    "Pinyin": "gāng",
    "Definition": "hard, tough, rigid, strong",
    "Radical": "刀",
    "RadicalIndex": "18.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 441,
    "FrequencyRank": 621
  },
  {
    "Character": "合",
    "Pinyin": "hé",
    "Definition": "combine, unite, join; gather",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 488,
    "FrequencyRank": 171
  },
  {
    "Character": "安",
    "Pinyin": "ān",
    "Definition": "peaceful, tranquil, quiet",
    "Radical": "宀",
    "RadicalIndex": "40.3",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 549,
    "FrequencyRank": 232
  },
  {
    "Character": "成",
    "Pinyin": "chéng",
    "Definition": "completed, finished, fixed",
    "Radical": "戈",
    "RadicalIndex": "62.3",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 401,
    "FrequencyRank": 59
  },
  {
    "Character": "件",
    "Pinyin": "jiàn",
    "Definition": "numerary adjunct for article; matter",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 466,
    "FrequencyRank": 250
  },
  {
    "Character": "因",
    "Pinyin": "yīn",
    "Definition": "cause, reason; by; because (of)",
    "Radical": "囗",
    "RadicalIndex": "31.3",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 430,
    "FrequencyRank": 96
  },
  {
    "Character": "阴",
    "Pinyin": "yīn",
    "Definition": undefined,
    "Radical": "阜",
    "RadicalIndex": "170.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 575,
    "FrequencyRank": 987
  },
  {
    "Character": "论",
    "Pinyin": "lùn",
    "Definition": "debate; discuss; discourse",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 556,
    "FrequencyRank": 205
  },
  {
    "Character": "全",
    "Pinyin": "quán",
    "Definition": "maintain, keep whole or intact",
    "Radical": "入",
    "RadicalIndex": "11.4",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 485,
    "FrequencyRank": 124
  },
  {
    "Character": "向",
    "Pinyin": "xiàng",
    "Definition": "toward, direction, trend",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 480,
    "FrequencyRank": 146
  },
  {
    "Character": "收",
    "Pinyin": "shōu",
    "Definition": "gather together, collect; harvest",
    "Radical": "攴",
    "RadicalIndex": "66.2",
    "StrokeCount": 6,
    "HSK": 2,
    "GeneralStandard": 573,
    "FrequencyRank": 351
  },
  {
    "Character": "言",
    "Pinyin": "yán",
    "Definition": "words, speech; speak, say",
    "Radical": "言",
    "RadicalIndex": "149",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 826,
    "FrequencyRank": 355
  },
  {
    "Character": "园",
    "Pinyin": "yuán",
    "Definition": "garden; park, orchard",
    "Radical": "囗",
    "RadicalIndex": "31.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 726,
    "FrequencyRank": 988
  },
  {
    "Character": "求",
    "Pinyin": "qiú",
    "Definition": "seek; demand, request; beseech",
    "Radical": "水",
    "RadicalIndex": "85.2",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 690,
    "FrequencyRank": 312
  },
  {
    "Character": "迎",
    "Pinyin": "yíng",
    "Definition": "receive, welcome, greet",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 822,
    "FrequencyRank": 1069
  },
  {
    "Character": "助",
    "Pinyin": "zhù",
    "Definition": "help, aid, assist",
    "Radical": "力",
    "RadicalIndex": "19.5",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 719,
    "FrequencyRank": 607
  },
  {
    "Character": "应",
    "Pinyin": "yīng",
    "Definition": "should, ought to, must",
    "Radical": "广",
    "RadicalIndex": "53.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 836,
    "FrequencyRank": 144
  },
  {
    "Character": "纸",
    "Pinyin": "zhǐ",
    "Definition": "paper",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 938,
    "FrequencyRank": 1020
  },
  {
    "Character": "层",
    "Pinyin": "céng",
    "Definition": "storey, layer, floor, stratum",
    "Radical": "尸",
    "RadicalIndex": "44.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 902,
    "FrequencyRank": 699
  },
  {
    "Character": "位",
    "Pinyin": "wèi",
    "Definition": "throne; position, post; rank, status; seat",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 784,
    "FrequencyRank": 182
  },
  {
    "Character": "但",
    "Pinyin": "dàn",
    "Definition": "only; but, however, yet, still",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 774,
    "FrequencyRank": 95
  },
  {
    "Character": "利",
    "Pinyin": "lì",
    "Definition": "gains, advantage, profit, merit",
    "Radical": "刀",
    "RadicalIndex": "18.5",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 763,
    "FrequencyRank": 155
  },
  {
    "Character": "完",
    "Pinyin": "wán",
    "Definition": "complete, finish, settle; whole",
    "Radical": "宀",
    "RadicalIndex": "40.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 877,
    "FrequencyRank": 301
  },
  {
    "Character": "弄",
    "Pinyin": "nòng",
    "Definition": "do, play or fiddle with; alley",
    "Radical": "廾",
    "RadicalIndex": "55.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 600,
    "FrequencyRank": 1053
  },
  {
    "Character": "更",
    "Pinyin": "gèng",
    "Definition": "more, still further, much more",
    "Radical": "曰",
    "RadicalIndex": "73.3",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 693,
    "FrequencyRank": 221
  },
  {
    "Character": "际",
    "Pinyin": "jì",
    "Definition": "border, boundary, juncture",
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 911,
    "FrequencyRank": 423
  },
  {
    "Character": "角",
    "Pinyin": "jiǎo",
    "Definition": "horn; angle, corner; point",
    "Radical": "角",
    "RadicalIndex": "148",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 814,
    "FrequencyRank": 736
  },
  {
    "Character": "声",
    "Pinyin": "shēng",
    "Definition": "sound, voice, noise; tone; music",
    "Radical": "士",
    "RadicalIndex": "33.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 654,
    "FrequencyRank": 195
  },
  {
    "Character": "克",
    "Pinyin": "kè",
    "Definition": "gram; overcome; transliteration",
    "Radical": "儿",
    "RadicalIndex": "10.5",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 675,
    "FrequencyRank": 262
  },
  {
    "Character": "近",
    "Pinyin": "jìn",
    "Definition": "near, close; approach; intimate",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 791,
    "FrequencyRank": 374
  },
  {
    "Character": "词",
    "Pinyin": "cí",
    "Definition": "words; phrase, expression",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 897,
    "FrequencyRank": 959
  },
  {
    "Character": "护",
    "Pinyin": "hù",
    "Definition": "protect, guard, defend, shelter",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 649,
    "FrequencyRank": 529
  },
  {
    "Character": "改",
    "Pinyin": "gǎi",
    "Definition": "change, alter; improve, remodel",
    "Radical": "攴",
    "RadicalIndex": "66.3",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 908,
    "FrequencyRank": 350
  },
  {
    "Character": "报",
    "Pinyin": "bào",
    "Definition": "report, tell, announce",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 656,
    "FrequencyRank": 234
  },
  {
    "Character": "吹",
    "Pinyin": "chuī",
    "Definition": "blow; puff; brag, boast",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 744,
    "FrequencyRank": 1390
  },
  {
    "Character": "努",
    "Pinyin": "nǔ",
    "Definition": "to exert, strive, make an effort; to pout",
    "Radical": "力",
    "RadicalIndex": "19.5",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 924,
    "FrequencyRank": 1081
  },
  {
    "Character": "低",
    "Pinyin": "dī",
    "Definition": "low; to lower, hang, bend, bow",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 781,
    "FrequencyRank": 592
  },
  {
    "Character": "运",
    "Pinyin": "yùn",
    "Definition": "run; simplified form of 運 luck, fortune; ship, transport",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 2,
    "GeneralStandard": 611,
    "FrequencyRank": 345
  },
  {
    "Character": "英",
    "Pinyin": "yīng",
    "Definition": "petal, flower, leaf; brave, a hero; England, English",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1006,
    "FrequencyRank": 371
  },
  {
    "Character": "直",
    "Pinyin": "zhí",
    "Definition": "straight, erect, vertical",
    "Radical": "目",
    "RadicalIndex": "109.3",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1011,
    "FrequencyRank": 255
  },
  {
    "Character": "忽",
    "Pinyin": "hū",
    "Definition": "suddenly, abruptly; neglect",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1197,
    "FrequencyRank": 912
  },
  {
    "Character": "典",
    "Pinyin": "diǎn",
    "Definition": "law, canon; documentation; classic, scripture",
    "Radical": "八",
    "RadicalIndex": "12.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1092,
    "FrequencyRank": 1044
  },
  {
    "Character": "例",
    "Pinyin": "lì",
    "Definition": "precedent, example; regulation",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1140,
    "FrequencyRank": 547
  },
  {
    "Character": "者",
    "Pinyin": "zhě",
    "Definition": "that which; he who; those who",
    "Radical": "老",
    "RadicalIndex": "125.4",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 969,
    "FrequencyRank": 103
  },
  {
    "Character": "表",
    "Pinyin": "biǎo",
    "Definition": "show, express, manifest, display",
    "Radical": "衣",
    "RadicalIndex": "145.3",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 951,
    "FrequencyRank": 177
  },
  {
    "Character": "油",
    "Pinyin": "yóu",
    "Definition": "oil, fat, grease, lard; paints",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1248,
    "FrequencyRank": 948
  },
  {
    "Character": "板",
    "Pinyin": "bǎn",
    "Definition": "plank, board; iron or tin plate",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1025,
    "FrequencyRank": 930
  },
  {
    "Character": "夜",
    "Pinyin": "yè",
    "Definition": "night, dark; in night; by night",
    "Radical": "夕",
    "RadicalIndex": "36.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1209,
    "FrequencyRank": 665
  },
  {
    "Character": "该",
    "Pinyin": "gāi",
    "Definition": "should, ought to, need to",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1300,
    "FrequencyRank": 319
  },
  {
    "Character": "爬",
    "Pinyin": "pá",
    "Definition": "crawl, creep; climb; scramble",
    "Radical": "爪",
    "RadicalIndex": "87.4",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1161,
    "FrequencyRank": 1426
  },
  {
    "Character": "态",
    "Pinyin": "tài",
    "Definition": "manner, bearing, attitude",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1052,
    "FrequencyRank": 528
  },
  {
    "Character": "饱",
    "Pinyin": "bǎo",
    "Definition": undefined,
    "Radical": "食",
    "RadicalIndex": "184.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1202,
    "FrequencyRank": 1998
  },
  {
    "Character": "鱼",
    "Pinyin": "yú",
    "Definition": "fish; surname; KangXi radical 195",
    "Radical": "魚",
    "RadicalIndex": "195",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1194,
    "FrequencyRank": 852
  },
  {
    "Character": "味",
    "Pinyin": "wèi",
    "Definition": "taste, smell, odor; delicacy",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1078,
    "FrequencyRank": 844
  },
  {
    "Character": "受",
    "Pinyin": "shòu",
    "Definition": "receive, accept, get; bear, stand",
    "Radical": "又",
    "RadicalIndex": "29.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1174,
    "FrequencyRank": 238
  },
  {
    "Character": "卖",
    "Pinyin": "mài",
    "Definition": "sell; betray; show off",
    "Radical": "十",
    "RadicalIndex": "24.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1042,
    "FrequencyRank": 974
  },
  {
    "Character": "往",
    "Pinyin": "wǎng",
    "Definition": "go, depart; past, formerly",
    "Radical": "彳",
    "RadicalIndex": "60.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1160,
    "FrequencyRank": 369
  },
  {
    "Character": "所",
    "Pinyin": "suǒ",
    "Definition": "place, location; numerary adjunct",
    "Radical": "戶",
    "RadicalIndex": "63.4",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1164,
    "FrequencyRank": 54
  },
  {
    "Character": "青",
    "Pinyin": "qīng",
    "Definition": "blue, green, black; young",
    "Radical": "青",
    "RadicalIndex": "174",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 947,
    "FrequencyRank": 497
  },
  {
    "Character": "育",
    "Pinyin": "yù",
    "Definition": "produce, give birth to; educate",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1225,
    "FrequencyRank": 609
  },
  {
    "Character": "使",
    "Pinyin": "shǐ",
    "Definition": "cause, send on a mission, order; envoy, messenger, ambassador",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1139,
    "FrequencyRank": 119
  },
  {
    "Character": "或",
    "Pinyin": "huò",
    "Definition": "or, either, else; perhaps, maybe",
    "Radical": "戈",
    "RadicalIndex": "62.4",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1035,
    "FrequencyRank": 160
  },
  {
    "Character": "刻",
    "Pinyin": "kè",
    "Definition": "carve, engrave; quarter hour",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1224,
    "FrequencyRank": 618
  },
  {
    "Character": "实",
    "Pinyin": "shí",
    "Definition": "real, true; honest, sincere",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1285,
    "FrequencyRank": 100
  },
  {
    "Character": "法",
    "Pinyin": "fǎ",
    "Definition": "law, rule, regulation, statute; France, French",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1241,
    "FrequencyRank": 65
  },
  {
    "Character": "经",
    "Pinyin": "jīng",
    "Definition": "classic works; pass through",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1350,
    "FrequencyRank": 62
  },
  {
    "Character": "狗",
    "Pinyin": "gǒu",
    "Definition": "dog, canis familiaris",
    "Radical": "犬",
    "RadicalIndex": "94.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1198,
    "FrequencyRank": 1281
  },
  {
    "Character": "空",
    "Pinyin": "kōng",
    "Definition": "empty, hollow, bare, deserted",
    "Radical": "穴",
    "RadicalIndex": "116.3",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1282,
    "FrequencyRank": 272
  },
  {
    "Character": "怕",
    "Pinyin": "pà",
    "Definition": "to fear, be afraid of; apprehensive",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1269,
    "FrequencyRank": 631
  },
  {
    "Character": "变",
    "Pinyin": "biàn",
    "Definition": "change, transform, alter; rebel",
    "Radical": "又",
    "RadicalIndex": "29.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1204,
    "FrequencyRank": 225
  },
  {
    "Character": "周",
    "Pinyin": "zhōu",
    "Definition": "Zhou dynasty; circumference",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1192,
    "FrequencyRank": 490
  },
  {
    "Character": "画",
    "Pinyin": "huà",
    "Definition": "a painting, picture, drawing; to draw",
    "Radical": "田",
    "RadicalIndex": "102.3",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1036,
    "FrequencyRank": 883
  },
  {
    "Character": "姓",
    "Pinyin": "xìng",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1327,
    "FrequencyRank": 1149
  },
  {
    "Character": "单",
    "Pinyin": "dān",
    "Definition": "single, individual, only; lone",
    "Radical": "八",
    "RadicalIndex": "12.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1232,
    "FrequencyRank": 389
  },
  {
    "Character": "组",
    "Pinyin": "zǔ",
    "Definition": "class; section, department",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1338,
    "FrequencyRank": 358
  },
  {
    "Character": "物",
    "Pinyin": "wù",
    "Definition": "thing, substance, creature",
    "Radical": "牛",
    "RadicalIndex": "93.4",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1127,
    "FrequencyRank": 142
  },
  {
    "Character": "练",
    "Pinyin": "liàn",
    "Definition": "to practice, drill, exercise, train",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1337,
    "FrequencyRank": 1005
  },
  {
    "Character": "参",
    "Pinyin": "cān",
    "Definition": "take part in, intervene; ginseng",
    "Radical": "厶",
    "RadicalIndex": "28.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1334,
    "FrequencyRank": 507
  },
  {
    "Character": "宜",
    "Pinyin": "yí",
    "Definition": "suitable, right, fitting, proper",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1278,
    "FrequencyRank": 1290
  },
  {
    "Character": "取",
    "Pinyin": "qǔ",
    "Definition": "take, receive, obtain; select",
    "Radical": "又",
    "RadicalIndex": "29.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 997,
    "FrequencyRank": 323
  },
  {
    "Character": "其",
    "Pinyin": "qí",
    "Definition": "his, her, its, their; that",
    "Radical": "八",
    "RadicalIndex": "12.6",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 996,
    "FrequencyRank": 85
  },
  {
    "Character": "定",
    "Pinyin": "dìng",
    "Definition": "decide, settle, fix",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1276,
    "FrequencyRank": 77
  },
  {
    "Character": "河",
    "Pinyin": "hé",
    "Definition": "river; stream; yellow river",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 1244,
    "FrequencyRank": 574
  },
  {
    "Character": "拉",
    "Pinyin": "lā",
    "Definition": "pull, drag; seize, hold; lengthen",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 2,
    "GeneralStandard": 981,
    "FrequencyRank": 324
  },
  {
    "Character": "省",
    "Pinyin": "shěng",
    "Definition": "province; save, economize",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1474,
    "FrequencyRank": 666
  },
  {
    "Character": "须",
    "Pinyin": "xū",
    "Definition": "must, have to, necessary",
    "Radical": "彡",
    "RadicalIndex": "59.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1585,
    "FrequencyRank": 444
  },
  {
    "Character": "故",
    "Pinyin": "gù",
    "Definition": "ancient, old; reason, because",
    "Radical": "攴",
    "RadicalIndex": "66.5",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1418,
    "FrequencyRank": 572
  },
  {
    "Character": "亮",
    "Pinyin": "liàng",
    "Definition": "bright, brilliant, radiant, light",
    "Radical": "亠",
    "RadicalIndex": "8.7",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1621,
    "FrequencyRank": 840
  },
  {
    "Character": "草",
    "Pinyin": "cǎo",
    "Definition": "grass, straw, thatch, herbs",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1408,
    "FrequencyRank": 789
  },
  {
    "Character": "室",
    "Pinyin": "shì",
    "Definition": "room, home, house, chamber",
    "Radical": "宀",
    "RadicalIndex": "40.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1697,
    "FrequencyRank": 708
  },
  {
    "Character": "举",
    "Pinyin": "jǔ",
    "Definition": "raise, lift up; recommend",
    "Radical": "丶",
    "RadicalIndex": "3.8",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1693,
    "FrequencyRank": 586
  },
  {
    "Character": "背",
    "Pinyin": "bèi",
    "Definition": "back; back side; behind; betray",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1467,
    "FrequencyRank": 787
  },
  {
    "Character": "药",
    "Pinyin": "yào",
    "Definition": "leaf of Dahurian angelica plant; medicine",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1423,
    "FrequencyRank": 662
  },
  {
    "Character": "选",
    "Pinyin": "xuǎn",
    "Definition": "choose, select; elect; election",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1545,
    "FrequencyRank": 499
  },
  {
    "Character": "钟",
    "Pinyin": "zhōng",
    "Definition": "clock; bell",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1528,
    "FrequencyRank": 905
  },
  {
    "Character": "急",
    "Pinyin": "jí",
    "Definition": "quick, quickly; urgent, pressing",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1609,
    "FrequencyRank": 657
  },
  {
    "Character": "秋",
    "Pinyin": "qiū",
    "Definition": "autumn, fall; year",
    "Radical": "禾",
    "RadicalIndex": "115.4",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1550,
    "FrequencyRank": 1151
  },
  {
    "Character": "庭",
    "Pinyin": "tíng",
    "Definition": "courtyard; spacious hall or yard",
    "Radical": "广",
    "RadicalIndex": "53.7",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1624,
    "FrequencyRank": 931
  },
  {
    "Character": "思",
    "Pinyin": "sī",
    "Definition": "think, consider, ponder; final particle",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1500,
    "FrequencyRank": 298
  },
  {
    "Character": "结",
    "Pinyin": "jié",
    "Definition": "knot, tie; join, connect",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1754,
    "FrequencyRank": 236
  },
  {
    "Character": "适",
    "Pinyin": "shì",
    "Definition": "go, reach; appropriate, suitable",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1546,
    "FrequencyRank": 663
  },
  {
    "Character": "春",
    "Pinyin": "chūn",
    "Definition": "spring; wanton",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1355,
    "FrequencyRank": 921
  },
  {
    "Character": "咱",
    "Pinyin": "zán",
    "Definition": "we, us",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1508,
    "FrequencyRank": 1265
  },
  {
    "Character": "轻",
    "Pinyin": "qīng",
    "Definition": "light; easy, simple; gentle",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1463,
    "FrequencyRank": 460
  },
  {
    "Character": "顺",
    "Pinyin": "shùn",
    "Definition": "obey, submit to, go along with",
    "Radical": "頁",
    "RadicalIndex": "181.3",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1559,
    "FrequencyRank": 938
  },
  {
    "Character": "复",
    "Pinyin": "fù",
    "Definition": "return; repeat; repeatedly",
    "Radical": "夊",
    "RadicalIndex": "35.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1553,
    "FrequencyRank": 426
  },
  {
    "Character": "科",
    "Pinyin": "kē",
    "Definition": "section, department, science",
    "Radical": "禾",
    "RadicalIndex": "115.4",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1551,
    "FrequencyRank": 277
  },
  {
    "Character": "响",
    "Pinyin": "xiǎng",
    "Definition": "make sound, make noise; sound",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1509,
    "FrequencyRank": 503
  },
  {
    "Character": "挺",
    "Pinyin": "tǐng",
    "Definition": "to stand upright, straighten; rigid",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1382,
    "FrequencyRank": 1467
  },
  {
    "Character": "封",
    "Pinyin": "fēng",
    "Definition": "letter, envelope; feudal",
    "Radical": "寸",
    "RadicalIndex": "41.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1366,
    "FrequencyRank": 871
  },
  {
    "Character": "养",
    "Pinyin": "yǎng",
    "Definition": "raise, rear, bring up; support",
    "Radical": "八",
    "RadicalIndex": "12.7",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1641,
    "FrequencyRank": 760
  },
  {
    "Character": "活",
    "Pinyin": "huó",
    "Definition": "live, exist, survive; lively",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1672,
    "FrequencyRank": 219
  },
  {
    "Character": "虽",
    "Pinyin": "suī",
    "Definition": "although, even if",
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1502,
    "FrequencyRank": 504
  },
  {
    "Character": "饺",
    "Pinyin": "jiǎo",
    "Definition": "stuffed dumplings",
    "Radical": "食",
    "RadicalIndex": "184.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1613,
    "FrequencyRank": 3650
  },
  {
    "Character": "便",
    "Pinyin": "biàn",
    "Definition": "convenience, ease; expedient",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1556,
    "FrequencyRank": 271
  },
  {
    "Character": "查",
    "Pinyin": "chá",
    "Definition": "investigate, examine, seek into",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1431,
    "FrequencyRank": 459
  },
  {
    "Character": "食",
    "Pinyin": "shí",
    "Definition": "eat; meal; food; KangXi radical number 184",
    "Radical": "食",
    "RadicalIndex": "184",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1589,
    "FrequencyRank": 671
  },
  {
    "Character": "音",
    "Pinyin": "yīn",
    "Definition": "sound, tone, pitch, pronunciation",
    "Radical": "音",
    "RadicalIndex": "180",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1632,
    "FrequencyRank": 540
  },
  {
    "Character": "相",
    "Pinyin": "xiāng",
    "Definition": "mutual, reciprocal, each other",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1430,
    "FrequencyRank": 152
  },
  {
    "Character": "闻",
    "Pinyin": "wén",
    "Definition": "hear; smell; make known; news",
    "Radical": "門",
    "RadicalIndex": "169.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1636,
    "FrequencyRank": 825
  },
  {
    "Character": "度",
    "Pinyin": "dù",
    "Definition": "degree, system; manner; to consider",
    "Radical": "广",
    "RadicalIndex": "53.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1622,
    "FrequencyRank": 184
  },
  {
    "Character": "段",
    "Pinyin": "duàn",
    "Definition": "section, piece, division",
    "Radical": "殳",
    "RadicalIndex": "79.5",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1555,
    "FrequencyRank": 567
  },
  {
    "Character": "信",
    "Pinyin": "xìn",
    "Definition": "trust, believe; letter",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1570,
    "FrequencyRank": 176
  },
  {
    "Character": "带",
    "Pinyin": "dài",
    "Definition": "belt, girdle, band, strap, zone",
    "Radical": "巾",
    "RadicalIndex": "50.6",
    "StrokeCount": 9,
    "HSK": 2,
    "GeneralStandard": 1407,
    "FrequencyRank": 342
  },
  {
    "Character": "脏",
    "Pinyin": "zàng",
    "Definition": "internal organs, viscera; dirty",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1998,
    "FrequencyRank": 1634
  },
  {
    "Character": "般",
    "Pinyin": "bān",
    "Definition": "sort, manner, kind, category",
    "Radical": "舟",
    "RadicalIndex": "137.4",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1980,
    "FrequencyRank": 629
  },
  {
    "Character": "健",
    "Pinyin": "jiàn",
    "Definition": "strong, robust, healthy; strength",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1969,
    "FrequencyRank": 979
  },
  {
    "Character": "离",
    "Pinyin": "lí",
    "Definition": "rare beast; strange; elegant",
    "Radical": "禸",
    "RadicalIndex": "114.6",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2035,
    "FrequencyRank": 418
  },
  {
    "Character": "顾",
    "Pinyin": "gù",
    "Definition": "look back; look at; look after",
    "Radical": "頁",
    "RadicalIndex": "181.4",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1874,
    "FrequencyRank": 815
  },
  {
    "Character": "特",
    "Pinyin": "tè",
    "Definition": "special, unique, distinguished",
    "Radical": "牛",
    "RadicalIndex": "93.6",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1938,
    "FrequencyRank": 173
  },
  {
    "Character": "哭",
    "Pinyin": "kū",
    "Definition": "weep, cry, wail",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1907,
    "FrequencyRank": 1210
  },
  {
    "Character": "凉",
    "Pinyin": "liáng",
    "Definition": "cool, cold; disheartened",
    "Radical": "冫",
    "RadicalIndex": "15.8",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2040,
    "FrequencyRank": 1581
  },
  {
    "Character": "夏",
    "Pinyin": "xià",
    "Definition": "summer; great, grand, big",
    "Radical": "夊",
    "RadicalIndex": "35.7",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1862,
    "FrequencyRank": 1126
  },
  {
    "Character": "瓶",
    "Pinyin": "píng",
    "Definition": "jug, pitcher, vase, jar, bottle",
    "Radical": "瓦",
    "RadicalIndex": "98.6",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2051,
    "FrequencyRank": 1703
  },
  {
    "Character": "借",
    "Pinyin": "jiè",
    "Definition": "borrow; lend; make pretext of",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1955,
    "FrequencyRank": 984
  },
  {
    "Character": "笔",
    "Pinyin": "bǐ",
    "Definition": "writing brush; write; stroke",
    "Radical": "竹",
    "RadicalIndex": "118.4",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1951,
    "FrequencyRank": 956
  },
  {
    "Character": "海",
    "Pinyin": "hǎi",
    "Definition": "sea, ocean; maritime",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2074,
    "FrequencyRank": 189
  },
  {
    "Character": "倒",
    "Pinyin": "dào",
    "Definition": "fall over; lie down; take turns",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1960,
    "FrequencyRank": 608
  },
  {
    "Character": "部",
    "Pinyin": "bù",
    "Definition": "part, division, section",
    "Radical": "邑",
    "RadicalIndex": "163.8",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2044,
    "FrequencyRank": 84
  },
  {
    "Character": "疼",
    "Pinyin": "téng",
    "Definition": "aches, pains; be fond of; love",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2031,
    "FrequencyRank": 1710
  },
  {
    "Character": "流",
    "Pinyin": "liú",
    "Definition": "flow, circulate, drift; class",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2080,
    "FrequencyRank": 396
  },
  {
    "Character": "换",
    "Pinyin": "huàn",
    "Definition": "change",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1806,
    "FrequencyRank": 824
  },
  {
    "Character": "啊",
    "Pinyin": "a",
    "Definition": "exclamatory particle",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1915,
    "FrequencyRank": 753
  },
  {
    "Character": "通",
    "Pinyin": "tōng",
    "Definition": "pass through, common, communicate",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2145,
    "FrequencyRank": 190
  },
  {
    "Character": "原",
    "Pinyin": "yuán",
    "Definition": "source, origin, beginning",
    "Radical": "厂",
    "RadicalIndex": "27.8",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1868,
    "FrequencyRank": 193
  },
  {
    "Character": "酒",
    "Pinyin": "jiǔ",
    "Definition": "wine, spirits, liquor, alcoholic beverage",
    "Radical": "酉",
    "RadicalIndex": "164.3",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2069,
    "FrequencyRank": 797
  },
  {
    "Character": "套",
    "Pinyin": "tào",
    "Definition": "case, cover, wrapper, envelope",
    "Radical": "大",
    "RadicalIndex": "37.7",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1869,
    "FrequencyRank": 1091
  },
  {
    "Character": "留",
    "Pinyin": "liú",
    "Definition": "stop, halt; stay, detain, keep",
    "Radical": "田",
    "RadicalIndex": "102.5",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2009,
    "FrequencyRank": 554
  },
  {
    "Character": "座",
    "Pinyin": "zuò",
    "Definition": "seat; stand, base",
    "Radical": "广",
    "RadicalIndex": "53.7",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2025,
    "FrequencyRank": 812
  },
  {
    "Character": "称",
    "Pinyin": "chēng",
    "Definition": "call; name, brand; address; say",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1948,
    "FrequencyRank": 449
  },
  {
    "Character": "铁",
    "Pinyin": "tiě",
    "Definition": "iron; strong, solid, firm",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1932,
    "FrequencyRank": 779
  },
  {
    "Character": "旅",
    "Pinyin": "lǚ",
    "Definition": "trip, journey; travel; traveler",
    "Radical": "方",
    "RadicalIndex": "70.6",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 2046,
    "FrequencyRank": 950
  },
  {
    "Character": "租",
    "Pinyin": "zū",
    "Definition": "rent, lease; rental; tax",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 10,
    "HSK": 2,
    "GeneralStandard": 1944,
    "FrequencyRank": 1397
  },
  {
    "Character": "情",
    "Pinyin": "qíng",
    "Definition": "feeling, sentiment, emotion",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2438,
    "FrequencyRank": 120
  },
  {
    "Character": "惯",
    "Pinyin": "guàn",
    "Definition": "habit, custom; habitually, usual",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2450,
    "FrequencyRank": 1226
  },
  {
    "Character": "接",
    "Pinyin": "jiē",
    "Definition": "receive; continue; catch; connect",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2185,
    "FrequencyRank": 247
  },
  {
    "Character": "掉",
    "Pinyin": "diào",
    "Definition": "turn, move; shake, wag; drop down",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2170,
    "FrequencyRank": 849
  },
  {
    "Character": "眼",
    "Pinyin": "yǎn",
    "Definition": "eye; eyelet, hole, opening",
    "Radical": "目",
    "RadicalIndex": "109.6",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2263,
    "FrequencyRank": 281
  },
  {
    "Character": "检",
    "Pinyin": "jiǎn",
    "Definition": "check; examine",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2227,
    "FrequencyRank": 731
  },
  {
    "Character": "康",
    "Pinyin": "kāng",
    "Definition": "peaceful, quiet; happy, healthy",
    "Radical": "广",
    "RadicalIndex": "53.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2385,
    "FrequencyRank": 900
  },
  {
    "Character": "脸",
    "Pinyin": "liǎn",
    "Definition": "face; cheek; reputation",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2359,
    "FrequencyRank": 615
  },
  {
    "Character": "船",
    "Pinyin": "chuán",
    "Definition": "ship, boat, vessel",
    "Radical": "舟",
    "RadicalIndex": "137.5",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2345,
    "FrequencyRank": 614
  },
  {
    "Character": "绿",
    "Pinyin": "lǜ",
    "Definition": "green; chlorine",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2498,
    "FrequencyRank": 1088
  },
  {
    "Character": "停",
    "Pinyin": "tíng",
    "Definition": "stop, suspend, delay; suitable",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2333,
    "FrequencyRank": 693
  },
  {
    "Character": "脚",
    "Pinyin": "jiǎo",
    "Definition": "leg, foot; base, leg, foundation",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2355,
    "FrequencyRank": 790
  },
  {
    "Character": "清",
    "Pinyin": "qīng",
    "Definition": "clear, pure, clean; peaceful",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2410,
    "FrequencyRank": 335
  },
  {
    "Character": "猫",
    "Pinyin": "māo",
    "Definition": "cat",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2367,
    "FrequencyRank": 1673
  },
  {
    "Character": "黄",
    "Pinyin": "huáng",
    "Definition": "yellow; surname",
    "Radical": "黃",
    "RadicalIndex": "201",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2201,
    "FrequencyRank": 561
  },
  {
    "Character": "够",
    "Pinyin": "gòu",
    "Definition": "enough, adequate",
    "Radical": "夕",
    "RadicalIndex": "36.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2362,
    "FrequencyRank": 538
  },
  {
    "Character": "随",
    "Pinyin": "suí",
    "Definition": "follow, listen to, submit to",
    "Radical": "阜",
    "RadicalIndex": "170.1",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2476,
    "FrequencyRank": 498
  },
  {
    "Character": "推",
    "Pinyin": "tuī",
    "Definition": "push, expel; push forward",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2174,
    "FrequencyRank": 505
  },
  {
    "Character": "理",
    "Pinyin": "lǐ",
    "Definition": "reason, logic; manage",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2157,
    "FrequencyRank": 89
  },
  {
    "Character": "晨",
    "Pinyin": "chén",
    "Definition": "early morning, daybreak",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2260,
    "FrequencyRank": 1388
  },
  {
    "Character": "银",
    "Pinyin": "yín",
    "Definition": "silver; cash, money, wealth",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2309,
    "FrequencyRank": 757
  },
  {
    "Character": "堂",
    "Pinyin": "táng",
    "Definition": "hall; government office",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2256,
    "FrequencyRank": 980
  },
  {
    "Character": "雪",
    "Pinyin": "xuě",
    "Definition": "snow; wipe away shame, avenge",
    "Radical": "雨",
    "RadicalIndex": "173.3",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2249,
    "FrequencyRank": 1003
  },
  {
    "Character": "绩",
    "Pinyin": "jī",
    "Definition": "spin; achievements",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2486,
    "FrequencyRank": 1547
  },
  {
    "Character": "骑",
    "Pinyin": "qí",
    "Definition": "ride horseback; mount; cavalry",
    "Radical": "馬",
    "RadicalIndex": "187.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2489,
    "FrequencyRank": 1398
  },
  {
    "Character": "辆",
    "Pinyin": "liàng",
    "Definition": "numerary adjunct for vehicles",
    "Radical": "車",
    "RadicalIndex": "159.7",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2251,
    "FrequencyRank": 1375
  },
  {
    "Character": "排",
    "Pinyin": "pái",
    "Definition": "row, rank, line",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 2,
    "GeneralStandard": 2168,
    "FrequencyRank": 682
  },
  {
    "Character": "喂",
    "Pinyin": "wèi",
    "Definition": "interjection to call attention",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2641,
    "FrequencyRank": 1988
  },
  {
    "Character": "温",
    "Pinyin": "wēn",
    "Definition": "lukewarm",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2757,
    "FrequencyRank": 867
  },
  {
    "Character": "喊",
    "Pinyin": "hǎn",
    "Definition": "shout, call out, yell; howl; cry",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2623,
    "FrequencyRank": 1183
  },
  {
    "Character": "椅",
    "Pinyin": "yǐ",
    "Definition": "chair, seat",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2569,
    "FrequencyRank": 1663
  },
  {
    "Character": "遍",
    "Pinyin": "biàn",
    "Definition": "everywhere, all over, throughout",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2788,
    "FrequencyRank": 1012
  },
  {
    "Character": "装",
    "Pinyin": "zhuāng",
    "Definition": "dress, clothes, attire; fill",
    "Radical": "衣",
    "RadicalIndex": "145.6",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2726,
    "FrequencyRank": 467
  },
  {
    "Character": "街",
    "Pinyin": "jiē",
    "Definition": "street, road, thoroughfare",
    "Radical": "行",
    "RadicalIndex": "144.6",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2702,
    "FrequencyRank": 1101
  },
  {
    "Character": "确",
    "Pinyin": "què",
    "Definition": "sure, certain; real, true",
    "Radical": "石",
    "RadicalIndex": "112.7",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2591,
    "FrequencyRank": 331
  },
  {
    "Character": "普",
    "Pinyin": "pǔ",
    "Definition": "universal, general, widespread",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2741,
    "FrequencyRank": 630
  },
  {
    "Character": "黑",
    "Pinyin": "hēi",
    "Definition": "black; dark; evil, sinister",
    "Radical": "黑",
    "RadicalIndex": "203",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2655,
    "FrequencyRank": 519
  },
  {
    "Character": "游",
    "Pinyin": "yóu",
    "Definition": "to swim; float, drift; wander, roam",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2766,
    "FrequencyRank": 695
  },
  {
    "Character": "量",
    "Pinyin": "liàng",
    "Definition": "measure, quantity, capacity",
    "Radical": "里",
    "RadicalIndex": "166.5",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2616,
    "FrequencyRank": 241
  },
  {
    "Character": "提",
    "Pinyin": "tí",
    "Definition": "hold in hand; lift in hand",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2520,
    "FrequencyRank": 196
  },
  {
    "Character": "晴",
    "Pinyin": "qíng",
    "Definition": "clear weather, fine weather",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2611,
    "FrequencyRank": 1968
  },
  {
    "Character": "越",
    "Pinyin": "yuè",
    "Definition": "exceed, go beyond; the more ...",
    "Radical": "走",
    "RadicalIndex": "156.5",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2514,
    "FrequencyRank": 440
  },
  {
    "Character": "舒",
    "Pinyin": "shū",
    "Definition": "open up, unfold, stretch out; comfortable, easy",
    "Radical": "舌",
    "RadicalIndex": "135.6",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2707,
    "FrequencyRank": 1459
  },
  {
    "Character": "短",
    "Pinyin": "duǎn",
    "Definition": "short; brief; deficient, lacking",
    "Radical": "矢",
    "RadicalIndex": "111.7",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2669,
    "FrequencyRank": 889
  },
  {
    "Character": "超",
    "Pinyin": "chāo",
    "Definition": "jump over, leap over; surpass",
    "Radical": "走",
    "RadicalIndex": "156.5",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2517,
    "FrequencyRank": 754
  },
  {
    "Character": "然",
    "Pinyin": "rán",
    "Definition": "yes, certainly; pledge, promise",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2723,
    "FrequencyRank": 55
  },
  {
    "Character": "湖",
    "Pinyin": "hú",
    "Definition": "a lake; Hubei, Hunan; bluish-green",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 2,
    "GeneralStandard": 2751,
    "FrequencyRank": 918
  },
  {
    "Character": "意",
    "Pinyin": "yì",
    "Definition": "thought, idea, opinion; think",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2996,
    "FrequencyRank": 104
  },
  {
    "Character": "楚",
    "Pinyin": "chǔ",
    "Definition": "name of feudal state; clear",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2864,
    "FrequencyRank": 859
  },
  {
    "Character": "腿",
    "Pinyin": "tuǐ",
    "Definition": "legs, thighs",
    "Radical": "肉",
    "RadicalIndex": "130.1",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2976,
    "FrequencyRank": 1351
  },
  {
    "Character": "睛",
    "Pinyin": "jīng",
    "Definition": "eyeball; pupil of eye",
    "Radical": "目",
    "RadicalIndex": "109.8",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2895,
    "FrequencyRank": 934
  },
  {
    "Character": "碗",
    "Pinyin": "wǎn",
    "Definition": "bowl, small dish",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2881,
    "FrequencyRank": 1939
  },
  {
    "Character": "照",
    "Pinyin": "zhào",
    "Definition": "shine, illumine, reflect",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2911,
    "FrequencyRank": 443
  },
  {
    "Character": "碰",
    "Pinyin": "pèng",
    "Definition": "collide, bump into",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2880,
    "FrequencyRank": 1371
  },
  {
    "Character": "像",
    "Pinyin": "xiàng",
    "Definition": "a picture, image, figure; to resemble",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2961,
    "FrequencyRank": 294
  },
  {
    "Character": "筷",
    "Pinyin": "kuài",
    "Definition": "chopsticks",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2955,
    "FrequencyRank": 3110
  },
  {
    "Character": "数",
    "Pinyin": "shù",
    "Definition": "number; several, count; fate",
    "Radical": "攴",
    "RadicalIndex": "66.9",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2999,
    "FrequencyRank": 231
  },
  {
    "Character": "蓝",
    "Pinyin": "lán",
    "Definition": "blue; indigo plant; surname",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2852,
    "FrequencyRank": 1190
  },
  {
    "Character": "满",
    "Pinyin": "mǎn",
    "Definition": "fill; full, satisfied",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 3005,
    "FrequencyRank": 436
  },
  {
    "Character": "感",
    "Pinyin": "gǎn",
    "Definition": "feel, perceive, emotion",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 13,
    "HSK": 2,
    "GeneralStandard": 2875,
    "FrequencyRank": 243
  },
  {
    "Character": "墙",
    "Pinyin": "qiáng",
    "Definition": "wall",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": 2,
    "GeneralStandard": 3054,
    "FrequencyRank": 1212
  },
  {
    "Character": "愿",
    "Pinyin": "yuàn",
    "Definition": "sincere, honest, virtuous",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 14,
    "HSK": 2,
    "GeneralStandard": 3094,
    "FrequencyRank": 598
  },
  {
    "Character": "静",
    "Pinyin": "jìng",
    "Definition": "quiet, still, motionless; gentle",
    "Radical": "青",
    "RadicalIndex": "174.6",
    "StrokeCount": 14,
    "HSK": 2,
    "GeneralStandard": 3049,
    "FrequencyRank": 722
  },
  {
    "Character": "漂",
    "Pinyin": "piào",
    "Definition": "float, drift; tossed about",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": 2,
    "GeneralStandard": 3163,
    "FrequencyRank": 1568
  },
  {
    "Character": "算",
    "Pinyin": "suàn",
    "Definition": "count, calculate, figure; plan",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": 2,
    "GeneralStandard": 3121,
    "FrequencyRank": 403
  },
  {
    "Character": "鞋",
    "Pinyin": "xié",
    "Definition": "shoes, footwear in general",
    "Radical": "革",
    "RadicalIndex": "177.6",
    "StrokeCount": 15,
    "HSK": 2,
    "GeneralStandard": 3207,
    "FrequencyRank": 1638
  },
  {
    "Character": "题",
    "Pinyin": "tí",
    "Definition": "forehead; title, headline; theme",
    "Radical": "頁",
    "RadicalIndex": "181.9",
    "StrokeCount": 15,
    "HSK": 2,
    "GeneralStandard": 3233,
    "FrequencyRank": 218
  },
  {
    "Character": "懂",
    "Pinyin": "dǒng",
    "Definition": "understand, comprehend",
    "Radical": "心",
    "RadicalIndex": "61.13",
    "StrokeCount": 15,
    "HSK": 2,
    "GeneralStandard": 3300,
    "FrequencyRank": 1211
  },
  {
    "Character": "靠",
    "Pinyin": "kào",
    "Definition": "lean on, trust, depend on; near",
    "Radical": "非",
    "RadicalIndex": "175.7",
    "StrokeCount": 15,
    "HSK": 2,
    "GeneralStandard": 3259,
    "FrequencyRank": 808
  },
  {
    "Character": "颜",
    "Pinyin": "yán",
    "Definition": "face, facial appearance",
    "Radical": "頁",
    "RadicalIndex": "181.9",
    "StrokeCount": 15,
    "HSK": 2,
    "GeneralStandard": 3285,
    "FrequencyRank": 1545
  },
  {
    "Character": "熟",
    "Pinyin": "shú",
    "Definition": "well-cooked; ripe; familiar with",
    "Radical": "火",
    "RadicalIndex": "86.11",
    "StrokeCount": 15,
    "HSK": 2,
    "GeneralStandard": 3278,
    "FrequencyRank": 1035
  },
  {
    "Character": "篇",
    "Pinyin": "piān",
    "Definition": "chapter, section, essay, article",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 15,
    "HSK": 2,
    "GeneralStandard": 3268,
    "FrequencyRank": 1008
  },
  {
    "Character": "嘴",
    "Pinyin": "zuǐ",
    "Definition": "mouth, lips",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 16,
    "HSK": 2,
    "GeneralStandard": 3342,
    "FrequencyRank": 1010
  },
  {
    "Character": "餐",
    "Pinyin": "cān",
    "Definition": "eat, dine; meal; food",
    "Radical": "食",
    "RadicalIndex": "184.7",
    "StrokeCount": 16,
    "HSK": 2,
    "GeneralStandard": 3341,
    "FrequencyRank": 1465
  },
  {
    "Character": "篮",
    "Pinyin": "lán",
    "Definition": "basket",
    "Radical": "竹",
    "RadicalIndex": "118.1",
    "StrokeCount": 16,
    "HSK": 2,
    "GeneralStandard": 3357,
    "FrequencyRank": 2357
  },
  {
    "Character": "澡",
    "Pinyin": "zǎo",
    "Definition": "wash, bathe",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": 2,
    "GeneralStandard": 3378,
    "FrequencyRank": 2695
  },
  {
    "Character": "刀",
    "Pinyin": "dāo",
    "Definition": "knife; old coin; measure",
    "Radical": "刀",
    "RadicalIndex": "18",
    "StrokeCount": 2,
    "HSK": 3,
    "GeneralStandard": 18,
    "FrequencyRank": 1067
  },
  {
    "Character": "厂",
    "Pinyin": "chǎng",
    "Definition": "factory, workshop; radical 27",
    "Radical": "厂",
    "RadicalIndex": "27",
    "StrokeCount": 2,
    "HSK": 3,
    "GeneralStandard": 6,
    "FrequencyRank": 963
  },
  {
    "Character": "卫",
    "Pinyin": "wèi",
    "Definition": "guard, protect, defend",
    "Radical": "乙",
    "RadicalIndex": "5.2",
    "StrokeCount": 3,
    "HSK": 3,
    "GeneralStandard": 65,
    "FrequencyRank": 669
  },
  {
    "Character": "义",
    "Pinyin": "yì",
    "Definition": "right conduct, righteousness",
    "Radical": "丿",
    "RadicalIndex": "4.2",
    "StrokeCount": 3,
    "HSK": 3,
    "GeneralStandard": 57,
    "FrequencyRank": 208
  },
  {
    "Character": "及",
    "Pinyin": "jí",
    "Definition": "extend; reach; come up to; and",
    "Radical": "又",
    "RadicalIndex": "29.2",
    "StrokeCount": 3,
    "HSK": 3,
    "GeneralStandard": 52,
    "FrequencyRank": 198
  },
  {
    "Character": "乡",
    "Pinyin": "xiāng",
    "Definition": "country; rural; village",
    "Radical": "幺",
    "RadicalIndex": "52",
    "StrokeCount": 3,
    "HSK": 3,
    "GeneralStandard": 73,
    "FrequencyRank": 922
  },
  {
    "Character": "土",
    "Pinyin": "tǔ",
    "Definition": "soil, earth; items made of earth",
    "Radical": "土",
    "RadicalIndex": "32",
    "StrokeCount": 3,
    "HSK": 3,
    "GeneralStandard": 27,
    "FrequencyRank": 515
  },
  {
    "Character": "订",
    "Pinyin": "dìng",
    "Definition": "draw up agreement; arrange",
    "Radical": "言",
    "RadicalIndex": "149.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 169,
    "FrequencyRank": 1176
  },
  {
    "Character": "历",
    "Pinyin": "lì",
    "Definition": "history; calendar",
    "Radical": "厂",
    "RadicalIndex": "27.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 95,
    "FrequencyRank": 480
  },
  {
    "Character": "丰",
    "Pinyin": "fēng",
    "Definition": "abundant, lush, bountiful, plenty",
    "Radical": "丨",
    "RadicalIndex": "2.3",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 74,
    "FrequencyRank": 1189
  },
  {
    "Character": "艺",
    "Pinyin": "yì",
    "Definition": "art; talent, ability; craft",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 86,
    "FrequencyRank": 786
  },
  {
    "Character": "止",
    "Pinyin": "zhǐ",
    "Definition": "stop, halt, desist; detain",
    "Radical": "止",
    "RadicalIndex": "77",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 109,
    "FrequencyRank": 596
  },
  {
    "Character": "支",
    "Pinyin": "zhī",
    "Definition": "disperse, pay; support; branch",
    "Radical": "支",
    "RadicalIndex": "65",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 89,
    "FrequencyRank": 437
  },
  {
    "Character": "专",
    "Pinyin": "zhuān",
    "Definition": "monopolize, take sole possession",
    "Radical": "一",
    "RadicalIndex": "1.3",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 83,
    "FrequencyRank": 485
  },
  {
    "Character": "木",
    "Pinyin": "mù",
    "Definition": "tree; wood, lumber; wooden",
    "Radical": "木",
    "RadicalIndex": "75",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 87,
    "FrequencyRank": 694
  },
  {
    "Character": "仅",
    "Pinyin": "jǐn",
    "Definition": "only, merely, solely, just",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 136,
    "FrequencyRank": 494
  },
  {
    "Character": "夫",
    "Pinyin": "fū",
    "Definition": "man, male adult, husband; those",
    "Radical": "大",
    "RadicalIndex": "37.1",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 79,
    "FrequencyRank": 377
  },
  {
    "Character": "币",
    "Pinyin": "bì",
    "Definition": "currency, coins, legal tender",
    "Radical": "巾",
    "RadicalIndex": "50.1",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 134,
    "FrequencyRank": 1188
  },
  {
    "Character": "互",
    "Pinyin": "hù",
    "Definition": "mutually, reciprocally",
    "Radical": "二",
    "RadicalIndex": "7.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 106,
    "FrequencyRank": 819
  },
  {
    "Character": "双",
    "Pinyin": "shuāng",
    "Definition": "set of two, pair, couple; both",
    "Radical": "又",
    "RadicalIndex": "29.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 187,
    "FrequencyRank": 581
  },
  {
    "Character": "化",
    "Pinyin": "huà",
    "Definition": "change, convert, reform; -ize",
    "Radical": "匕",
    "RadicalIndex": "21.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 132,
    "FrequencyRank": 178
  },
  {
    "Character": "仍",
    "Pinyin": "réng",
    "Definition": "yet, still, as ever; again; keep -ing, continuing",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 135,
    "FrequencyRank": 601
  },
  {
    "Character": "内",
    "Pinyin": "nèi",
    "Definition": "inside",
    "Radical": "入",
    "RadicalIndex": "11.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 116,
    "FrequencyRank": 175
  },
  {
    "Character": "父",
    "Pinyin": "fù",
    "Definition": "father; KangXi radical 88",
    "Radical": "父",
    "RadicalIndex": "88",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 141,
    "FrequencyRank": 589
  },
  {
    "Character": "反",
    "Pinyin": "fǎn",
    "Definition": "reverse, opposite, contrary, anti",
    "Radical": "又",
    "RadicalIndex": "29.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 139,
    "FrequencyRank": 237
  },
  {
    "Character": "升",
    "Pinyin": "shēng",
    "Definition": "arise, go up; hoist; advance",
    "Radical": "十",
    "RadicalIndex": "24.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 125,
    "FrequencyRank": 837
  },
  {
    "Character": "区",
    "Pinyin": "qū",
    "Definition": "area, district, region, ward",
    "Radical": "匸",
    "RadicalIndex": "23.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 94,
    "FrequencyRank": 265
  },
  {
    "Character": "切",
    "Pinyin": "qiè",
    "Definition": "cut, mince, slice, carve",
    "Radical": "刀",
    "RadicalIndex": "18.2",
    "StrokeCount": 4,
    "HSK": 3,
    "GeneralStandard": 107,
    "FrequencyRank": 337
  },
  {
    "Character": "付",
    "Pinyin": "fù",
    "Definition": "give, deliver, pay, hand over; entrust",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 269,
    "FrequencyRank": 820
  },
  {
    "Character": "布",
    "Pinyin": "bù",
    "Definition": "cotton cloth, textiles, linen",
    "Radical": "巾",
    "RadicalIndex": "50.2",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 218,
    "FrequencyRank": 380
  },
  {
    "Character": "术",
    "Pinyin": "shù",
    "Definition": "art, skill, special feat; method, technique",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 211,
    "FrequencyRank": 328
  },
  {
    "Character": "巧",
    "Pinyin": "qiǎo",
    "Definition": "skillful, ingenious, clever",
    "Radical": "工",
    "RadicalIndex": "48.2",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 197,
    "FrequencyRank": 1219
  },
  {
    "Character": "世",
    "Pinyin": "shì",
    "Definition": "generation; world; era",
    "Radical": "一",
    "RadicalIndex": "1.4",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 206,
    "FrequencyRank": 181
  },
  {
    "Character": "龙",
    "Pinyin": "lóng",
    "Definition": "dragon; symbolic of emperor",
    "Radical": "龍",
    "RadicalIndex": "212",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 221,
    "FrequencyRank": 696
  },
  {
    "Character": "母",
    "Pinyin": "mǔ",
    "Definition": "mother; female elders; female",
    "Radical": "毋",
    "RadicalIndex": "80.1",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 345,
    "FrequencyRank": 565
  },
  {
    "Character": "训",
    "Pinyin": "xùn",
    "Definition": "teach, instruct; exegesis",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 319,
    "FrequencyRank": 1022
  },
  {
    "Character": "失",
    "Pinyin": "shī",
    "Definition": "lose; make mistake, neglect",
    "Radical": "大",
    "RadicalIndex": "37.2",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 265,
    "FrequencyRank": 375
  },
  {
    "Character": "古",
    "Pinyin": "gǔ",
    "Definition": "old, classic, ancient",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 208,
    "FrequencyRank": 509
  },
  {
    "Character": "石",
    "Pinyin": "shí",
    "Definition": "stone, rock, mineral; rad. 112",
    "Radical": "石",
    "RadicalIndex": "112",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 216,
    "FrequencyRank": 414
  },
  {
    "Character": "立",
    "Pinyin": "lì",
    "Definition": "stand; let stand; establish, set",
    "Radical": "立",
    "RadicalIndex": "117",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 302,
    "FrequencyRank": 197
  },
  {
    "Character": "旧",
    "Pinyin": "jiù",
    "Definition": "old, ancient; former, past",
    "Radical": "日",
    "RadicalIndex": "72.1",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 232,
    "FrequencyRank": 915
  },
  {
    "Character": "台",
    "Pinyin": "tái",
    "Definition": "platform; unit; term of address",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 342,
    "FrequencyRank": 388
  },
  {
    "Character": "议",
    "Pinyin": "yì",
    "Definition": "consult, talk over, discuss",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 320,
    "FrequencyRank": 368
  },
  {
    "Character": "皮",
    "Pinyin": "pí",
    "Definition": "skin, hide, fur, feather; outer",
    "Radical": "皮",
    "RadicalIndex": "107",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 336,
    "FrequencyRank": 741
  },
  {
    "Character": "汁",
    "Pinyin": "zhī",
    "Definition": "juice, liquor, fluid, sap, gravy, sauce",
    "Radical": "水",
    "RadicalIndex": "85.2",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 308,
    "FrequencyRank": 2540
  },
  {
    "Character": "另",
    "Pinyin": "lìng",
    "Definition": "another, separate, other",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 256,
    "FrequencyRank": 489
  },
  {
    "Character": "功",
    "Pinyin": "gōng",
    "Definition": "achievement, merit, good result",
    "Radical": "力",
    "RadicalIndex": "19.3",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 202,
    "FrequencyRank": 452
  },
  {
    "Character": "代",
    "Pinyin": "dài",
    "Definition": "replace, replacement (of person or generation); era, generation",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 271,
    "FrequencyRank": 174
  },
  {
    "Character": "民",
    "Pinyin": "mín",
    "Definition": "people, subjects, citizens",
    "Radical": "氏",
    "RadicalIndex": "83.1",
    "StrokeCount": 5,
    "HSK": 3,
    "GeneralStandard": 327,
    "FrequencyRank": 113
  },
  {
    "Character": "传",
    "Pinyin": "chuán",
    "Definition": "summon; propagate, transmit",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 455,
    "FrequencyRank": 332
  },
  {
    "Character": "杂",
    "Pinyin": "zá",
    "Definition": "mixed, blended; mix, mingle",
    "Radical": "木",
    "RadicalIndex": "75.2",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 498,
    "FrequencyRank": 853
  },
  {
    "Character": "导",
    "Pinyin": "dǎo",
    "Definition": "direct, guide, lead, conduct",
    "Radical": "寸",
    "RadicalIndex": "41.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 567,
    "FrequencyRank": 343
  },
  {
    "Character": "约",
    "Pinyin": "yuē",
    "Definition": "treaty, agreement, covenant",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 593,
    "FrequencyRank": 424
  },
  {
    "Character": "戏",
    "Pinyin": "xì",
    "Definition": "theatrical play, show",
    "Radical": "戈",
    "RadicalIndex": "62.2",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 584,
    "FrequencyRank": 1033
  },
  {
    "Character": "设",
    "Pinyin": "shè",
    "Definition": "build; establish; display",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 560,
    "FrequencyRank": 302
  },
  {
    "Character": "似",
    "Pinyin": "shì",
    "Definition": "resemble, similar to; as if, seem",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 481,
    "FrequencyRank": 431
  },
  {
    "Character": "伟",
    "Pinyin": "wěi",
    "Definition": "great, robust; extraordinary",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 454,
    "FrequencyRank": 1107
  },
  {
    "Character": "齐",
    "Pinyin": "qí",
    "Definition": "even, uniform, of equal length",
    "Radical": "齊",
    "RadicalIndex": "210",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 518,
    "FrequencyRank": 1063
  },
  {
    "Character": "血",
    "Pinyin": "xuè",
    "Definition": "blood; radical number 143",
    "Radical": "血",
    "RadicalIndex": "143",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 479,
    "FrequencyRank": 658
  },
  {
    "Character": "尽",
    "Pinyin": "jǐn",
    "Definition": "exhaust, use up; deplete",
    "Radical": "尸",
    "RadicalIndex": "44.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 566,
    "FrequencyRank": 488
  },
  {
    "Character": "创",
    "Pinyin": "chuàng",
    "Definition": "establish, create; knife cut",
    "Radical": "刀",
    "RadicalIndex": "18.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 494,
    "FrequencyRank": 635
  },
  {
    "Character": "至",
    "Pinyin": "zhì",
    "Definition": "reach, arrive; extremely, very",
    "Radical": "至",
    "RadicalIndex": "133",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 410,
    "FrequencyRank": 267
  },
  {
    "Character": "价",
    "Pinyin": "jià",
    "Definition": "price, value",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 469,
    "FrequencyRank": 409
  },
  {
    "Character": "压",
    "Pinyin": "yā",
    "Definition": "press; oppress; crush; pressure",
    "Radical": "厂",
    "RadicalIndex": "27.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 385,
    "FrequencyRank": 756
  },
  {
    "Character": "伤",
    "Pinyin": "shāng",
    "Definition": "wound, injury; fall ill from",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 468,
    "FrequencyRank": 660
  },
  {
    "Character": "存",
    "Pinyin": "cún",
    "Definition": "exist, live, be; survive; remain",
    "Radical": "子",
    "RadicalIndex": "39.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 391,
    "FrequencyRank": 384
  },
  {
    "Character": "充",
    "Pinyin": "chōng",
    "Definition": "fill, be full, supply",
    "Radical": "儿",
    "RadicalIndex": "10.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 525,
    "FrequencyRank": 690
  },
  {
    "Character": "防",
    "Pinyin": "fáng",
    "Definition": "defend; prevent; embankment",
    "Radical": "阜",
    "RadicalIndex": "170.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 576,
    "FrequencyRank": 548
  },
  {
    "Character": "并",
    "Pinyin": "bìng",
    "Definition": undefined,
    "Radical": "干",
    "RadicalIndex": "51.5",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 531,
    "FrequencyRank": 141
  },
  {
    "Character": "式",
    "Pinyin": "shì",
    "Definition": "style, system, formula, rule",
    "Radical": "弋",
    "RadicalIndex": "56.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 349,
    "FrequencyRank": 303
  },
  {
    "Character": "此",
    "Pinyin": "cǐ",
    "Definition": "this, these; in this case, then",
    "Radical": "止",
    "RadicalIndex": "77.2",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 411,
    "FrequencyRank": 116
  },
  {
    "Character": "纪",
    "Pinyin": "jì",
    "Definition": "record, annal, historical account",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 595,
    "FrequencyRank": 584
  },
  {
    "Character": "优",
    "Pinyin": "yōu",
    "Definition": "superior, excellent; actor",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 461,
    "FrequencyRank": 774
  },
  {
    "Character": "农",
    "Pinyin": "nóng",
    "Definition": "agriculture, farming; farmer",
    "Radical": "冖",
    "RadicalIndex": "14.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 558,
    "FrequencyRank": 465
  },
  {
    "Character": "任",
    "Pinyin": "rèn",
    "Definition": "trust to, rely on, appoint; to bear, duty, office; allow",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 467,
    "FrequencyRank": 186
  },
  {
    "Character": "光",
    "Pinyin": "guāng",
    "Definition": "light, brilliant, shine; only",
    "Radical": "儿",
    "RadicalIndex": "10.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 417,
    "FrequencyRank": 290
  },
  {
    "Character": "死",
    "Pinyin": "sǐ",
    "Definition": "die; dead; death",
    "Radical": "歹",
    "RadicalIndex": "78.2",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 400,
    "FrequencyRank": 317
  },
  {
    "Character": "众",
    "Pinyin": "zhòng",
    "Definition": "multitude, crowd; masses, public",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 491,
    "FrequencyRank": 510
  },
  {
    "Character": "庆",
    "Pinyin": "qìng",
    "Definition": "congratulate, celebrate",
    "Radical": "广",
    "RadicalIndex": "53.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 515,
    "FrequencyRank": 1269
  },
  {
    "Character": "危",
    "Pinyin": "wēi",
    "Definition": "dangerous, precarious; high",
    "Radical": "卩",
    "RadicalIndex": "26.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 499,
    "FrequencyRank": 826
  },
  {
    "Character": "达",
    "Pinyin": "dá",
    "Definition": "arrive at, reach; intelligent; smooth, slippery",
    "Radical": "辵",
    "RadicalIndex": "162.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 398,
    "FrequencyRank": 289
  },
  {
    "Character": "决",
    "Pinyin": "jué",
    "Definition": "decide, determine, judge",
    "Radical": "冫",
    "RadicalIndex": "15.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 523,
    "FrequencyRank": 273
  },
  {
    "Character": "争",
    "Pinyin": "zhēng",
    "Definition": "dispute, fight, contend, strive",
    "Radical": "亅",
    "RadicalIndex": "6.5",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 508,
    "FrequencyRank": 344
  },
  {
    "Character": "羊",
    "Pinyin": "yáng",
    "Definition": "sheep, goat; KangXi radical 123",
    "Radical": "羊",
    "RadicalIndex": "123",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 530,
    "FrequencyRank": 1337
  },
  {
    "Character": "负",
    "Pinyin": "fù",
    "Definition": "load, burden; carry, bear",
    "Radical": "貝",
    "RadicalIndex": "154.2",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 503,
    "FrequencyRank": 691
  },
  {
    "Character": "团",
    "Pinyin": "tuán",
    "Definition": "sphere, ball, circle; mass, lump",
    "Radical": "囗",
    "RadicalIndex": "31.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 425,
    "FrequencyRank": 405
  },
  {
    "Character": "华",
    "Pinyin": "huá",
    "Definition": "flowery; illustrious; Chinese",
    "Radical": "十",
    "RadicalIndex": "24.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 472,
    "FrequencyRank": 412
  },
  {
    "Character": "产",
    "Pinyin": "chǎn",
    "Definition": "give birth, bring forth, produce",
    "Radical": "立",
    "RadicalIndex": "117.1",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 522,
    "FrequencyRank": 159
  },
  {
    "Character": "各",
    "Pinyin": "gè",
    "Definition": "each, individually, every, all",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 506,
    "FrequencyRank": 209
  },
  {
    "Character": "汤",
    "Pinyin": "tāng",
    "Definition": "hot water; soup, gravy, broth",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 542,
    "FrequencyRank": 1618
  },
  {
    "Character": "访",
    "Pinyin": "fǎng",
    "Definition": "visit; ask, inquire",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 3,
    "GeneralStandard": 561,
    "FrequencyRank": 1037
  },
  {
    "Character": "每",
    "Pinyin": "měi",
    "Definition": "every, each",
    "Radical": "毋",
    "RadicalIndex": "80.3",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 767,
    "FrequencyRank": 359
  },
  {
    "Character": "邮",
    "Pinyin": "yóu",
    "Definition": "postal, mail; post office",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 732,
    "FrequencyRank": 1652
  },
  {
    "Character": "材",
    "Pinyin": "cái",
    "Definition": "material, stuff; timber; talent",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 681,
    "FrequencyRank": 952
  },
  {
    "Character": "极",
    "Pinyin": "jí",
    "Definition": "extreme, utmost, furthest, final",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 687,
    "FrequencyRank": 363
  },
  {
    "Character": "形",
    "Pinyin": "xíng",
    "Definition": "form, shape, appearance",
    "Radical": "彡",
    "RadicalIndex": "59.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 604,
    "FrequencyRank": 269
  },
  {
    "Character": "张",
    "Pinyin": "zhāng",
    "Definition": "stretch, extend, expand; sheet",
    "Radical": "弓",
    "RadicalIndex": "57.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 909,
    "FrequencyRank": 318
  },
  {
    "Character": "围",
    "Pinyin": "wéi",
    "Definition": "surround, encircle, corral",
    "Radical": "囗",
    "RadicalIndex": "31.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 728,
    "FrequencyRank": 576
  },
  {
    "Character": "否",
    "Pinyin": "fǒu",
    "Definition": "not, no, negative; final particle",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 703,
    "FrequencyRank": 620
  },
  {
    "Character": "乱",
    "Pinyin": "luàn",
    "Definition": "confusion, state of chaos, revolt",
    "Radical": "乙",
    "RadicalIndex": "5.6",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 762,
    "FrequencyRank": 765
  },
  {
    "Character": "技",
    "Pinyin": "jì",
    "Definition": "skill, ability, talent, ingenuity",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 615,
    "FrequencyRank": 422
  },
  {
    "Character": "连",
    "Pinyin": "lián",
    "Definition": "join, connect; continuous; even",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 708,
    "FrequencyRank": 399
  },
  {
    "Character": "补",
    "Pinyin": "bǔ",
    "Definition": "mend, patch, fix, repair, restore",
    "Radical": "衣",
    "RadicalIndex": "145.2",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 888,
    "FrequencyRank": 944
  },
  {
    "Character": "员",
    "Pinyin": "yuán",
    "Definition": "member; personnel, staff member",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 737,
    "FrequencyRank": 200
  },
  {
    "Character": "况",
    "Pinyin": "kuàng",
    "Definition": "condition, situation; furthermore",
    "Radical": "冫",
    "RadicalIndex": "15.5",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 830,
    "FrequencyRank": 419
  },
  {
    "Character": "吵",
    "Pinyin": "chǎo",
    "Definition": "argue, dispute; disturb, annoy",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 735,
    "FrequencyRank": 2040
  },
  {
    "Character": "村",
    "Pinyin": "cūn",
    "Definition": "village, hamlet; uncouth, vulgar",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 682,
    "FrequencyRank": 712
  },
  {
    "Character": "困",
    "Pinyin": "kùn",
    "Definition": "to surround, beseige; to be surrounded; difficult",
    "Radical": "囗",
    "RadicalIndex": "31.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 734,
    "FrequencyRank": 868
  },
  {
    "Character": "把",
    "Pinyin": "bǎ",
    "Definition": "hold, take; guard; regard as",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 655,
    "FrequencyRank": 110
  },
  {
    "Character": "何",
    "Pinyin": "hé",
    "Definition": "what, why, where, which, how",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 771,
    "FrequencyRank": 229
  },
  {
    "Character": "初",
    "Pinyin": "chū",
    "Definition": "beginning, initial, primary",
    "Radical": "刀",
    "RadicalIndex": "18.5",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 889,
    "FrequencyRank": 667
  },
  {
    "Character": "沙",
    "Pinyin": "shā",
    "Definition": "sand, gravel, pebbles; granulated",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 860,
    "FrequencyRank": 848
  },
  {
    "Character": "坚",
    "Pinyin": "jiān",
    "Definition": "hard, strong, firm; resolute",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 712,
    "FrequencyRank": 748
  },
  {
    "Character": "状",
    "Pinyin": "zhuàng",
    "Definition": "form; appearance; shape; official",
    "Radical": "犬",
    "RadicalIndex": "94.3",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 828,
    "FrequencyRank": 624
  },
  {
    "Character": "丽",
    "Pinyin": "lì",
    "Definition": "beautiful, magnificent, elegant",
    "Radical": "丶",
    "RadicalIndex": "3.7",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 699,
    "FrequencyRank": 834
  },
  {
    "Character": "抓",
    "Pinyin": "zhuā",
    "Definition": "scratch; clutch, seize, grab",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 633,
    "FrequencyRank": 992
  },
  {
    "Character": "步",
    "Pinyin": "bù",
    "Definition": "step, pace; walk, stroll",
    "Radical": "止",
    "RadicalIndex": "77.3",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 710,
    "FrequencyRank": 349
  },
  {
    "Character": "评",
    "Pinyin": "píng",
    "Definition": "appraise, criticize, evaluate",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 887,
    "FrequencyRank": 809
  },
  {
    "Character": "社",
    "Pinyin": "shè",
    "Definition": "god of the soil and altars to him; group of families; company, society",
    "Radical": "示",
    "RadicalIndex": "113.3",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 890,
    "FrequencyRank": 270
  },
  {
    "Character": "足",
    "Pinyin": "zú",
    "Definition": "foot; attain, satisfy, enough",
    "Radical": "足",
    "RadicalIndex": "157",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 731,
    "FrequencyRank": 527
  },
  {
    "Character": "志",
    "Pinyin": "zhì",
    "Definition": "purpose, will, determination; annals",
    "Radical": "心",
    "RadicalIndex": "61.3",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 651,
    "FrequencyRank": 542
  },
  {
    "Character": "证",
    "Pinyin": "zhèng",
    "Definition": "prove, confirm, verify; proof",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 885,
    "FrequencyRank": 373
  },
  {
    "Character": "束",
    "Pinyin": "shù",
    "Definition": "bind, control, restrain; bale",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 694,
    "FrequencyRank": 998
  },
  {
    "Character": "批",
    "Pinyin": "pī",
    "Definition": "comment, criticize; wholesale",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 622,
    "FrequencyRank": 569
  },
  {
    "Character": "希",
    "Pinyin": "xī",
    "Definition": "rare; hope, expect, strive for",
    "Radical": "巾",
    "RadicalIndex": "50.4",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 796,
    "FrequencyRank": 508
  },
  {
    "Character": "李",
    "Pinyin": "lǐ",
    "Definition": "plum; judge; surname",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 688,
    "FrequencyRank": 472
  },
  {
    "Character": "判",
    "Pinyin": "pàn",
    "Definition": "judge; discriminate; conclude",
    "Radical": "刀",
    "RadicalIndex": "18.5",
    "StrokeCount": 7,
    "HSK": 3,
    "GeneralStandard": 849,
    "FrequencyRank": 719
  },
  {
    "Character": "势",
    "Pinyin": "shì",
    "Definition": "power, force; tendency",
    "Radical": "力",
    "RadicalIndex": "19.6",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 977,
    "FrequencyRank": 506
  },
  {
    "Character": "注",
    "Pinyin": "zhù",
    "Definition": "concentrate, focus, direct",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1252,
    "FrequencyRank": 492
  },
  {
    "Character": "怪",
    "Pinyin": "guài",
    "Definition": "strange, unusual, peculiar",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1271,
    "FrequencyRank": 775
  },
  {
    "Character": "金",
    "Pinyin": "jīn",
    "Definition": "gold; metals in general; money",
    "Radical": "金",
    "RadicalIndex": "167",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1166,
    "FrequencyRank": 260
  },
  {
    "Character": "念",
    "Pinyin": "niàn",
    "Definition": "think of, recall, study",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1177,
    "FrequencyRank": 477
  },
  {
    "Character": "武",
    "Pinyin": "wǔ",
    "Definition": "military; martial, warlike",
    "Radical": "止",
    "RadicalIndex": "77.4",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 946,
    "FrequencyRank": 501
  },
  {
    "Character": "责",
    "Pinyin": "zé",
    "Definition": undefined,
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 948,
    "FrequencyRank": 535
  },
  {
    "Character": "苹",
    "Pinyin": "píng",
    "Definition": "artemisia; duckweed; apple",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1004,
    "FrequencyRank": 2478
  },
  {
    "Character": "幸",
    "Pinyin": "xìng",
    "Definition": "luck(ily), favor, fortunately",
    "Radical": "干",
    "RadicalIndex": "51.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 983,
    "FrequencyRank": 902
  },
  {
    "Character": "命",
    "Pinyin": "mìng",
    "Definition": "life; destiny, fate, luck; an order, instruction",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1168,
    "FrequencyRank": 258
  },
  {
    "Character": "规",
    "Pinyin": "guī",
    "Definition": "rules, regulations, customs, law",
    "Radical": "見",
    "RadicalIndex": "147.4",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 952,
    "FrequencyRank": 321
  },
  {
    "Character": "奇",
    "Pinyin": "qí",
    "Definition": "strange, unusual, uncanny, occult",
    "Radical": "大",
    "RadicalIndex": "37.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1050,
    "FrequencyRank": 563
  },
  {
    "Character": "具",
    "Pinyin": "jù",
    "Definition": "tool, implement; draw up, write",
    "Radical": "八",
    "RadicalIndex": "12.6",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1077,
    "FrequencyRank": 391
  },
  {
    "Character": "制",
    "Pinyin": "zhì",
    "Definition": "system; establish; overpower",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1121,
    "FrequencyRank": 163
  },
  {
    "Character": "始",
    "Pinyin": "shǐ",
    "Definition": "begin, start; then, only then",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1329,
    "FrequencyRank": 381
  },
  {
    "Character": "采",
    "Pinyin": "cǎi",
    "Definition": "collect, gather; pick, pluck",
    "Radical": "釆",
    "RadicalIndex": "165.1",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1172,
    "FrequencyRank": 585
  },
  {
    "Character": "苦",
    "Pinyin": "kǔ",
    "Definition": "bitter; hardship, suffering",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 999,
    "FrequencyRank": 634
  },
  {
    "Character": "录",
    "Pinyin": "lù",
    "Definition": "copy, write down, record",
    "Radical": "彐",
    "RadicalIndex": "58.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1304,
    "FrequencyRank": 919
  },
  {
    "Character": "环",
    "Pinyin": "huán",
    "Definition": "jade ring or bracelet; ring",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 945,
    "FrequencyRank": 681
  },
  {
    "Character": "姑",
    "Pinyin": "gū",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1325,
    "FrequencyRank": 994
  },
  {
    "Character": "终",
    "Pinyin": "zhōng",
    "Definition": "end; finally, in the end",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1344,
    "FrequencyRank": 558
  },
  {
    "Character": "建",
    "Pinyin": "jiàn",
    "Definition": "build, establish, erect, found",
    "Radical": "廴",
    "RadicalIndex": "54.6",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1302,
    "FrequencyRank": 244
  },
  {
    "Character": "衬",
    "Pinyin": "chèn",
    "Definition": "underwear; inner garments",
    "Radical": "衣",
    "RadicalIndex": "145.3",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1292,
    "FrequencyRank": 2502
  },
  {
    "Character": "转",
    "Pinyin": "zhuǎn",
    "Definition": "shift, move, turn",
    "Radical": "車",
    "RadicalIndex": "159.4",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1059,
    "FrequencyRank": 376
  },
  {
    "Character": "衫",
    "Pinyin": "shān",
    "Definition": "shirt; robe; gown; jacket",
    "Radical": "衣",
    "RadicalIndex": "145.3",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1293,
    "FrequencyRank": 2153
  },
  {
    "Character": "性",
    "Pinyin": "xìng",
    "Definition": "nature, character, sex",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1268,
    "FrequencyRank": 122
  },
  {
    "Character": "底",
    "Pinyin": "dǐ",
    "Definition": "bottom, underneath, underside",
    "Radical": "广",
    "RadicalIndex": "53.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1212,
    "FrequencyRank": 543
  },
  {
    "Character": "泳",
    "Pinyin": "yǒng",
    "Definition": "dive; swim",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1257,
    "FrequencyRank": 2607
  },
  {
    "Character": "拍",
    "Pinyin": "pāi",
    "Definition": "clap, tap, beat; beat or rhythm",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 970,
    "FrequencyRank": 1167
  },
  {
    "Character": "易",
    "Pinyin": "yì",
    "Definition": "change; easy",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1088,
    "FrequencyRank": 461
  },
  {
    "Character": "咖",
    "Pinyin": "kā",
    "Definition": "coffee; a phonetic",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1104,
    "FrequencyRank": 2379
  },
  {
    "Character": "线",
    "Pinyin": "xiàn",
    "Definition": "line, thread, wire; clue",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": 3,
    "GeneralStandard": 1336,
    "FrequencyRank": 430
  },
  {
    "Character": "总",
    "Pinyin": "zǒng",
    "Definition": "collect; overall, altogether",
    "Radical": "八",
    "RadicalIndex": "12.7",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1654,
    "FrequencyRank": 228
  },
  {
    "Character": "将",
    "Pinyin": "jiāng",
    "Definition": "will, going to, future; general",
    "Radical": "爿",
    "RadicalIndex": "90.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1617,
    "FrequencyRank": 132
  },
  {
    "Character": "险",
    "Pinyin": "xiǎn",
    "Definition": "narrow pass, strategic point",
    "Radical": "阜",
    "RadicalIndex": "170.7",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1733,
    "FrequencyRank": 672
  },
  {
    "Character": "冒",
    "Pinyin": "mào",
    "Definition": "risk, brave, dare",
    "Radical": "冂",
    "RadicalIndex": "13.7",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1486,
    "FrequencyRank": 1222
  },
  {
    "Character": "费",
    "Pinyin": "fèi",
    "Definition": "expenses, expenditures, fee",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1726,
    "FrequencyRank": 486
  },
  {
    "Character": "显",
    "Pinyin": "xiǎn",
    "Definition": "manifest, display; evident, clear",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1485,
    "FrequencyRank": 469
  },
  {
    "Character": "持",
    "Pinyin": "chí",
    "Definition": "sustain, support; hold, grasp",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1367,
    "FrequencyRank": 357
  },
  {
    "Character": "除",
    "Pinyin": "chú",
    "Definition": "eliminate, remove, except",
    "Radical": "阜",
    "RadicalIndex": "170.7",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1732,
    "FrequencyRank": 464
  },
  {
    "Character": "标",
    "Pinyin": "biāo",
    "Definition": "mark, symbol, label, sign; stand the bole of a tree",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1424,
    "FrequencyRank": 473
  },
  {
    "Character": "种",
    "Pinyin": "zhǒng",
    "Definition": "seed; race, breed; to plant",
    "Radical": "禾",
    "RadicalIndex": "115.4",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1549,
    "FrequencyRank": 57
  },
  {
    "Character": "类",
    "Pinyin": "lèi",
    "Definition": "class, group, kind, category",
    "Radical": "大",
    "RadicalIndex": "37.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1646,
    "FrequencyRank": 311
  },
  {
    "Character": "屋",
    "Pinyin": "wū",
    "Definition": "house; room; building, shelter",
    "Radical": "尸",
    "RadicalIndex": "44.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1722,
    "FrequencyRank": 863
  },
  {
    "Character": "胖",
    "Pinyin": "pàng",
    "Definition": "fat, obese; fatty",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1596,
    "FrequencyRank": 1893
  },
  {
    "Character": "误",
    "Pinyin": "wù",
    "Definition": "err, make mistake; interfere",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1714,
    "FrequencyRank": 854
  },
  {
    "Character": "架",
    "Pinyin": "jià",
    "Definition": "rack, stand, prop; prop up",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1743,
    "FrequencyRank": 846
  },
  {
    "Character": "迷",
    "Pinyin": "mí",
    "Definition": "bewitch, charm, infatuate",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1647,
    "FrequencyRank": 1153
  },
  {
    "Character": "亲",
    "Pinyin": "qīn",
    "Definition": "relatives, parents; intimate the hazel nut or filbert tree a thorny tree",
    "Radical": "立",
    "RadicalIndex": "117.4",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1631,
    "FrequencyRank": 362
  },
  {
    "Character": "宣",
    "Pinyin": "xuān",
    "Definition": "declare, announce, proclaim",
    "Radical": "宀",
    "RadicalIndex": "40.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1695,
    "FrequencyRank": 770
  },
  {
    "Character": "保",
    "Pinyin": "bǎo",
    "Definition": "protect, safeguard, defend, care",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1562,
    "FrequencyRank": 266
  },
  {
    "Character": "界",
    "Pinyin": "jiè",
    "Definition": "boundary, limit; domain; society; the world",
    "Radical": "田",
    "RadicalIndex": "102.4",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1496,
    "FrequencyRank": 288
  },
  {
    "Character": "哈",
    "Pinyin": "hā",
    "Definition": "sound of laughter",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1510,
    "FrequencyRank": 713
  },
  {
    "Character": "退",
    "Pinyin": "tuì",
    "Definition": "step back, retreat, withdraw",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1720,
    "FrequencyRank": 723
  },
  {
    "Character": "修",
    "Pinyin": "xiū",
    "Definition": "study; repair; cultivate",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1560,
    "FrequencyRank": 740
  },
  {
    "Character": "指",
    "Pinyin": "zhǐ",
    "Definition": "finger, toe; point, indicate",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1389,
    "FrequencyRank": 261
  },
  {
    "Character": "美",
    "Pinyin": "měi",
    "Definition": "beautiful, pretty; pleasing",
    "Radical": "羊",
    "RadicalIndex": "123.3",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1642,
    "FrequencyRank": 151
  },
  {
    "Character": "胜",
    "Pinyin": "shèng",
    "Definition": "victory; excel, be better than",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1594,
    "FrequencyRank": 743
  },
  {
    "Character": "济",
    "Pinyin": "jì",
    "Definition": "help, aid, relieve; ferry, cross",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1678,
    "FrequencyRank": 360
  },
  {
    "Character": "挂",
    "Pinyin": "guà",
    "Definition": "hang, suspend; suspense",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1365,
    "FrequencyRank": 1232
  },
  {
    "Character": "首",
    "Pinyin": "shǒu",
    "Definition": "head; first; leader, chief; a poem",
    "Radical": "首",
    "RadicalIndex": "185",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1651,
    "FrequencyRank": 481
  },
  {
    "Character": "绝",
    "Pinyin": "jué",
    "Definition": "to cut, sever, break off, terminate",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1762,
    "FrequencyRank": 562
  },
  {
    "Character": "祝",
    "Pinyin": "zhù",
    "Definition": "pray for happiness or blessings",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1712,
    "FrequencyRank": 1651
  },
  {
    "Character": "追",
    "Pinyin": "zhuī",
    "Definition": "pursue, chase after; expel",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1577,
    "FrequencyRank": 768
  },
  {
    "Character": "神",
    "Pinyin": "shén",
    "Definition": "spirit, god, supernatural being",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1711,
    "FrequencyRank": 227
  },
  {
    "Character": "派",
    "Pinyin": "pài",
    "Definition": "school of thought, sect, branch",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1673,
    "FrequencyRank": 532
  },
  {
    "Character": "某",
    "Pinyin": "mǒu",
    "Definition": "certain thing or person",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1399,
    "FrequencyRank": 517
  },
  {
    "Character": "按",
    "Pinyin": "àn",
    "Definition": "put hand on, press down with hand",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1395,
    "FrequencyRank": 573
  },
  {
    "Character": "品",
    "Pinyin": "pǐn",
    "Definition": "article, product, commodity",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1503,
    "FrequencyRank": 308
  },
  {
    "Character": "香",
    "Pinyin": "xiāng",
    "Definition": "fragrant, sweet smelling, incense",
    "Radical": "香",
    "RadicalIndex": "186",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1548,
    "FrequencyRank": 776
  },
  {
    "Character": "待",
    "Pinyin": "dài",
    "Definition": "treat, entertain, receive; wait",
    "Radical": "彳",
    "RadicalIndex": "60.6",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1580,
    "FrequencyRank": 673
  },
  {
    "Character": "城",
    "Pinyin": "chéng",
    "Definition": "castle; city, town; municipality",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1373,
    "FrequencyRank": 413
  },
  {
    "Character": "突",
    "Pinyin": "tū",
    "Definition": "suddenly, abruptly, unexpectedly",
    "Radical": "穴",
    "RadicalIndex": "116.4",
    "StrokeCount": 9,
    "HSK": 3,
    "GeneralStandard": 1700,
    "FrequencyRank": 484
  },
  {
    "Character": "烈",
    "Pinyin": "liè",
    "Definition": "fiery, violent, vehement, ardent",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1871,
    "FrequencyRank": 802
  },
  {
    "Character": "恐",
    "Pinyin": "kǒng",
    "Definition": "fear; fearful, apprehensive",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1810,
    "FrequencyRank": 891
  },
  {
    "Character": "展",
    "Pinyin": "zhǎn",
    "Definition": "open, unfold; stretch, extend",
    "Radical": "尸",
    "RadicalIndex": "44.7",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2131,
    "FrequencyRank": 275
  },
  {
    "Character": "效",
    "Pinyin": "xiào",
    "Definition": "result, effect; effectiveness",
    "Radical": "攴",
    "RadicalIndex": "66.6",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2034,
    "FrequencyRank": 551
  },
  {
    "Character": "预",
    "Pinyin": "yù",
    "Definition": "prepare, arrange; in advance",
    "Radical": "頁",
    "RadicalIndex": "181.4",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2148,
    "FrequencyRank": 647
  },
  {
    "Character": "顿",
    "Pinyin": "dùn",
    "Definition": "pause, stop; bow, kowtow; arrange",
    "Radical": "頁",
    "RadicalIndex": "181.4",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1877,
    "FrequencyRank": 794
  },
  {
    "Character": "继",
    "Pinyin": "jì",
    "Definition": "continue, maintain, carry on",
    "Radical": "糸",
    "RadicalIndex": "120.7",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2153,
    "FrequencyRank": 655
  },
  {
    "Character": "谈",
    "Pinyin": "tán",
    "Definition": "talk; conversation; surname",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2127,
    "FrequencyRank": 474
  },
  {
    "Character": "配",
    "Pinyin": "pèi",
    "Definition": "match, pair; equal; blend",
    "Radical": "酉",
    "RadicalIndex": "164.3",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1858,
    "FrequencyRank": 738
  },
  {
    "Character": "消",
    "Pinyin": "xiāo",
    "Definition": "vanish, die out; melt away",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2071,
    "FrequencyRank": 439
  },
  {
    "Character": "娘",
    "Pinyin": "niáng",
    "Definition": "mother; young girl; woman; wife",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2144,
    "FrequencyRank": 881
  },
  {
    "Character": "烟",
    "Pinyin": "yān",
    "Definition": "smoke, soot; opium; tobacco, cigarettes",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2062,
    "FrequencyRank": 967
  },
  {
    "Character": "验",
    "Pinyin": "yàn",
    "Definition": "test, examine, inspect; verify",
    "Radical": "馬",
    "RadicalIndex": "187.7",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2152,
    "FrequencyRank": 534
  },
  {
    "Character": "剧",
    "Pinyin": "jù",
    "Definition": "theatrical plays, opera, drama",
    "Radical": "刀",
    "RadicalIndex": "18.8",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2132,
    "FrequencyRank": 909
  },
  {
    "Character": "值",
    "Pinyin": "zhí",
    "Definition": "price",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1956,
    "FrequencyRank": 600
  },
  {
    "Character": "容",
    "Pinyin": "róng",
    "Definition": "looks, appearance; figure, form",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2105,
    "FrequencyRank": 442
  },
  {
    "Character": "破",
    "Pinyin": "pò",
    "Definition": "break, ruin, destroy; rout",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1867,
    "FrequencyRank": 604
  },
  {
    "Character": "被",
    "Pinyin": "bèi",
    "Definition": undefined,
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2118,
    "FrequencyRank": 154
  },
  {
    "Character": "格",
    "Pinyin": "gé",
    "Definition": "pattern, standard, form; style",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1845,
    "FrequencyRank": 325
  },
  {
    "Character": "较",
    "Pinyin": "jiào",
    "Definition": "compare; comparatively, more",
    "Radical": "車",
    "RadicalIndex": "159.6",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1876,
    "FrequencyRank": 491
  },
  {
    "Character": "浪",
    "Pinyin": "làng",
    "Definition": "wave; wasteful, reckless",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2084,
    "FrequencyRank": 1186
  },
  {
    "Character": "造",
    "Pinyin": "zào",
    "Definition": "construct, build, make, begin; prepare",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1940,
    "FrequencyRank": 354
  },
  {
    "Character": "积",
    "Pinyin": "jī",
    "Definition": "accumulate, store up, amass",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1945,
    "FrequencyRank": 728
  },
  {
    "Character": "资",
    "Pinyin": "zī",
    "Definition": "property; wealth; capital",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2039,
    "FrequencyRank": 257
  },
  {
    "Character": "紧",
    "Pinyin": "jǐn",
    "Definition": "tense, tight, taut; firm, secure",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1884,
    "FrequencyRank": 560
  },
  {
    "Character": "调",
    "Pinyin": "diào",
    "Definition": "transfer, move, change; tune",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2123,
    "FrequencyRank": 400
  },
  {
    "Character": "础",
    "Pinyin": "chǔ",
    "Definition": "foundation stone, plinth",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1866,
    "FrequencyRank": 1014
  },
  {
    "Character": "根",
    "Pinyin": "gēn",
    "Definition": "root, base(d on); foundation",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1850,
    "FrequencyRank": 352
  },
  {
    "Character": "桥",
    "Pinyin": "qiáo",
    "Definition": "bridge; beam, crosspiece",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1841,
    "FrequencyRank": 1292
  },
  {
    "Character": "速",
    "Pinyin": "sù",
    "Definition": "quick, prompt, speedy",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1853,
    "FrequencyRank": 617
  },
  {
    "Character": "害",
    "Pinyin": "hài",
    "Definition": "injure, harm; destroy, kill",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2097,
    "FrequencyRank": 579
  },
  {
    "Character": "赶",
    "Pinyin": "gǎn",
    "Definition": "pursue, follow; expel, drive away",
    "Radical": "走",
    "RadicalIndex": "156.3",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1788,
    "FrequencyRank": 908
  },
  {
    "Character": "缺",
    "Pinyin": "quē",
    "Definition": "be short of, lack; gap, deficit",
    "Radical": "缶",
    "RadicalIndex": "121.4",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 1935,
    "FrequencyRank": 875
  },
  {
    "Character": "烦",
    "Pinyin": "fán",
    "Definition": "bother, vex, trouble; troublesome",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": 3,
    "GeneralStandard": 2059,
    "FrequencyRank": 1253
  },
  {
    "Character": "族",
    "Pinyin": "zú",
    "Definition": "a family clan, ethnic group, tribe",
    "Radical": "方",
    "RadicalIndex": "70.7",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2392,
    "FrequencyRank": 549
  },
  {
    "Character": "续",
    "Pinyin": "xù",
    "Definition": "continue, carry on; succeed",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2488,
    "FrequencyRank": 552
  },
  {
    "Character": "麻",
    "Pinyin": "má",
    "Definition": "hemp, jute, flax; sesame",
    "Radical": "麻",
    "RadicalIndex": "200",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2379,
    "FrequencyRank": 1108
  },
  {
    "Character": "营",
    "Pinyin": "yíng",
    "Definition": "encampment, barracks; manage",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2214,
    "FrequencyRank": 536
  },
  {
    "Character": "章",
    "Pinyin": "zhāng",
    "Definition": "composition; chapter, section",
    "Radical": "立",
    "RadicalIndex": "117.6",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2389,
    "FrequencyRank": 539
  },
  {
    "Character": "望",
    "Pinyin": "wàng",
    "Definition": "to look at, look forward; to hope, expect",
    "Radical": "月",
    "RadicalIndex": "74.7",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2394,
    "FrequencyRank": 326
  },
  {
    "Character": "据",
    "Pinyin": "jù",
    "Definition": "to occupy, take possession of; a base, position",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2189,
    "FrequencyRank": 313
  },
  {
    "Character": "职",
    "Pinyin": "zhí",
    "Definition": "duty, profession; office, post",
    "Radical": "耳",
    "RadicalIndex": "128.5",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2192,
    "FrequencyRank": 616
  },
  {
    "Character": "断",
    "Pinyin": "duàn",
    "Definition": "sever, cut off; interrupt",
    "Radical": "斤",
    "RadicalIndex": "69.7",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2405,
    "FrequencyRank": 434
  },
  {
    "Character": "救",
    "Pinyin": "jiù",
    "Definition": "save, rescue, relieve; help, aid",
    "Radical": "攴",
    "RadicalIndex": "66.7",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2232,
    "FrequencyRank": 872
  },
  {
    "Character": "婚",
    "Pinyin": "hūn",
    "Definition": "get married; marriage, wedding",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2481,
    "FrequencyRank": 942
  },
  {
    "Character": "啤",
    "Pinyin": "pí",
    "Definition": "beer",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2289,
    "FrequencyRank": 2849
  },
  {
    "Character": "猪",
    "Pinyin": "zhū",
    "Definition": "pig, hog, wild boar",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2365,
    "FrequencyRank": 1762
  },
  {
    "Character": "啡",
    "Pinyin": "fēi",
    "Definition": "morphine; coffee",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2272,
    "FrequencyRank": 2326
  },
  {
    "Character": "彩",
    "Pinyin": "cǎi",
    "Definition": "hue, color; variegated colors",
    "Radical": "彡",
    "RadicalIndex": "59.8",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2353,
    "FrequencyRank": 1177
  },
  {
    "Character": "深",
    "Pinyin": "shēn",
    "Definition": "deep; depth; far; very, extreme",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2432,
    "FrequencyRank": 401
  },
  {
    "Character": "象",
    "Pinyin": "xiàng",
    "Definition": "elephant; ivory; figure, image",
    "Radical": "豕",
    "RadicalIndex": "152.5",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2361,
    "FrequencyRank": 300
  },
  {
    "Character": "敢",
    "Pinyin": "gǎn",
    "Definition": "to dare, venture; bold, brave",
    "Radical": "攴",
    "RadicalIndex": "66.8",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2470,
    "FrequencyRank": 795
  },
  {
    "Character": "领",
    "Pinyin": "lǐng",
    "Definition": "neck; collar; lead, guide",
    "Radical": "頁",
    "RadicalIndex": "181.5",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2354,
    "FrequencyRank": 329
  },
  {
    "Character": "甜",
    "Pinyin": "tián",
    "Definition": "sweet, sweetness",
    "Radical": "甘",
    "RadicalIndex": "99.6",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2311,
    "FrequencyRank": 2020
  },
  {
    "Character": "基",
    "Pinyin": "jī",
    "Definition": "foundation, base",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": 3,
    "GeneralStandard": 2193,
    "FrequencyRank": 280
  },
  {
    "Character": "牌",
    "Pinyin": "pái",
    "Definition": "signboard, placard",
    "Radical": "片",
    "RadicalIndex": "91.8",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2692,
    "FrequencyRank": 1261
  },
  {
    "Character": "善",
    "Pinyin": "shàn",
    "Definition": "good, virtuous, charitable, kind",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2738,
    "FrequencyRank": 749
  },
  {
    "Character": "程",
    "Pinyin": "chéng",
    "Definition": "journey, trip; schedule, agenda",
    "Radical": "禾",
    "RadicalIndex": "115.7",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2677,
    "FrequencyRank": 314
  },
  {
    "Character": "曾",
    "Pinyin": "céng",
    "Definition": "already; sign of past",
    "Radical": "曰",
    "RadicalIndex": "73.8",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2747,
    "FrequencyRank": 463
  },
  {
    "Character": "属",
    "Pinyin": "shǔ",
    "Definition": "class, category, type; belong to",
    "Radical": "尸",
    "RadicalIndex": "44.9",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2800,
    "FrequencyRank": 610
  },
  {
    "Character": "痛",
    "Pinyin": "tòng",
    "Definition": "pain, ache; sorry, sad; bitter",
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2734,
    "FrequencyRank": 730
  },
  {
    "Character": "朝",
    "Pinyin": "cháo",
    "Definition": "dynasty; morning",
    "Radical": "月",
    "RadicalIndex": "74.8",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2559,
    "FrequencyRank": 593
  },
  {
    "Character": "强",
    "Pinyin": "qiáng",
    "Definition": "strong, powerful, energetic",
    "Radical": "弓",
    "RadicalIndex": "57.9",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2802,
    "FrequencyRank": 292
  },
  {
    "Character": "集",
    "Pinyin": "jí",
    "Definition": "assemble, collect together",
    "Radical": "隹",
    "RadicalIndex": "172.4",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2694,
    "FrequencyRank": 406
  },
  {
    "Character": "握",
    "Pinyin": "wò",
    "Definition": "grasp, hold fast, take by hand",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2538,
    "FrequencyRank": 1032
  },
  {
    "Character": "富",
    "Pinyin": "fù",
    "Definition": "abundant, ample; rich, wealthy",
    "Radical": "宀",
    "RadicalIndex": "40.9",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2781,
    "FrequencyRank": 733
  },
  {
    "Character": "裙",
    "Pinyin": "qún",
    "Definition": "skirt, apron, petticoat",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2792,
    "FrequencyRank": 2431
  },
  {
    "Character": "媒",
    "Pinyin": "méi",
    "Definition": "go-between, matchmaker; medium",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2808,
    "FrequencyRank": 1506
  },
  {
    "Character": "景",
    "Pinyin": "jǐng",
    "Definition": "scenery, view; conditions",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2626,
    "FrequencyRank": 814
  },
  {
    "Character": "联",
    "Pinyin": "lián",
    "Definition": "connect, join; associate, ally",
    "Radical": "耳",
    "RadicalIndex": "128.6",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2544,
    "FrequencyRank": 356
  },
  {
    "Character": "落",
    "Pinyin": "luò",
    "Definition": "fall, drop; net income, surplus",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2557,
    "FrequencyRank": 496
  },
  {
    "Character": "散",
    "Pinyin": "sàn",
    "Definition": "scatter, disperse, break up",
    "Radical": "攴",
    "RadicalIndex": "66.8",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2546,
    "FrequencyRank": 866
  },
  {
    "Character": "裤",
    "Pinyin": "kù",
    "Definition": "trousers, pants",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 12,
    "HSK": 3,
    "GeneralStandard": 2791,
    "FrequencyRank": 2090
  },
  {
    "Character": "群",
    "Pinyin": "qún",
    "Definition": "(same as 羣) group, crowd, multitude, mob",
    "Radical": "羊",
    "RadicalIndex": "123.7",
    "StrokeCount": 13,
    "HSK": 3,
    "GeneralStandard": 3035,
    "FrequencyRank": 570
  },
  {
    "Character": "解",
    "Pinyin": "jiě",
    "Definition": "loosen, unfasten, untie; explain",
    "Radical": "角",
    "RadicalIndex": "148.6",
    "StrokeCount": 13,
    "HSK": 3,
    "GeneralStandard": 2981,
    "FrequencyRank": 201
  },
  {
    "Character": "概",
    "Pinyin": "gài",
    "Definition": "generally, approximately",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": 3,
    "GeneralStandard": 2871,
    "FrequencyRank": 791
  },
  {
    "Character": "输",
    "Pinyin": "shū",
    "Definition": "transport, carry, haul",
    "Radical": "車",
    "RadicalIndex": "159.9",
    "StrokeCount": 13,
    "HSK": 3,
    "GeneralStandard": 2890,
    "FrequencyRank": 939
  },
  {
    "Character": "福",
    "Pinyin": "fú",
    "Definition": "happiness, good fortune, blessing",
    "Radical": "示",
    "RadicalIndex": "113.9",
    "StrokeCount": 13,
    "HSK": 3,
    "GeneralStandard": 3033,
    "FrequencyRank": 683
  },
  {
    "Character": "跳",
    "Pinyin": "tiào",
    "Definition": "jump, leap, vault, bounce; dance",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": 3,
    "GeneralStandard": 2915,
    "FrequencyRank": 999
  },
  {
    "Character": "暖",
    "Pinyin": "nuǎn",
    "Definition": "warm, genial",
    "Radical": "日",
    "RadicalIndex": "72.9",
    "StrokeCount": 13,
    "HSK": 3,
    "GeneralStandard": 2906,
    "FrequencyRank": 1745
  },
  {
    "Character": "搬",
    "Pinyin": "bān",
    "Definition": "transfer, move, remove, shift",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": 3,
    "GeneralStandard": 2840,
    "FrequencyRank": 1766
  },
  {
    "Character": "简",
    "Pinyin": "jiǎn",
    "Definition": "simple, terse, succinct, a letter",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": 3,
    "GeneralStandard": 2954,
    "FrequencyRank": 716
  },
  {
    "Character": "赛",
    "Pinyin": "sài",
    "Definition": "compete, contend; contest, race",
    "Radical": "宀",
    "RadicalIndex": "40.11",
    "StrokeCount": 14,
    "HSK": 3,
    "GeneralStandard": 3172,
    "FrequencyRank": 1064
  },
  {
    "Character": "需",
    "Pinyin": "xū",
    "Definition": "need, require, must",
    "Radical": "雨",
    "RadicalIndex": "173.6",
    "StrokeCount": 14,
    "HSK": 3,
    "GeneralStandard": 3095,
    "FrequencyRank": 408
  },
  {
    "Character": "演",
    "Pinyin": "yǎn",
    "Definition": "perform, put on; exercise",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": 3,
    "GeneralStandard": 3167,
    "FrequencyRank": 715
  },
  {
    "Character": "境",
    "Pinyin": "jìng",
    "Definition": "boundary, frontier; area, region",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": 3,
    "GeneralStandard": 3061,
    "FrequencyRank": 582
  },
  {
    "Character": "舞",
    "Pinyin": "wǔ",
    "Definition": "dance, posture, prance; brandish",
    "Radical": "舛",
    "RadicalIndex": "136.8",
    "StrokeCount": 14,
    "HSK": 3,
    "GeneralStandard": 3116,
    "FrequencyRank": 1144
  },
  {
    "Character": "管",
    "Pinyin": "guǎn",
    "Definition": "pipe, tube, duct; woodwind music",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": 3,
    "GeneralStandard": 3123,
    "FrequencyRank": 252
  },
  {
    "Character": "察",
    "Pinyin": "chá",
    "Definition": "examine, investigate; notice",
    "Radical": "宀",
    "RadicalIndex": "40.11",
    "StrokeCount": 14,
    "HSK": 3,
    "GeneralStandard": 3174,
    "FrequencyRank": 564
  },
  {
    "Character": "精",
    "Pinyin": "jīng",
    "Definition": "essence; semen; spirit",
    "Radical": "米",
    "RadicalIndex": "119.8",
    "StrokeCount": 14,
    "HSK": 3,
    "GeneralStandard": 3153,
    "FrequencyRank": 435
  },
  {
    "Character": "播",
    "Pinyin": "bō",
    "Definition": "sow, spread; broadcast; cast away, reject",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": 3,
    "GeneralStandard": 3199,
    "FrequencyRank": 1275
  },
  {
    "Character": "增",
    "Pinyin": "zēng",
    "Definition": "increase, add to, augment",
    "Radical": "土",
    "RadicalIndex": "32.12",
    "StrokeCount": 15,
    "HSK": 3,
    "GeneralStandard": 3204,
    "FrequencyRank": 446
  },
  {
    "Character": "箱",
    "Pinyin": "xiāng",
    "Definition": "case, box, chest, trunk",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 15,
    "HSK": 3,
    "GeneralStandard": 3265,
    "FrequencyRank": 1453
  },
  {
    "Character": "蕉",
    "Pinyin": "jiāo",
    "Definition": "banana; plantain",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 15,
    "HSK": 3,
    "GeneralStandard": 3209,
    "FrequencyRank": 3104
  },
  {
    "Character": "器",
    "Pinyin": "qì",
    "Definition": "receptacle, vessel; instrument",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 16,
    "HSK": 3,
    "GeneralStandard": 3348,
    "FrequencyRank": 441
  },
  {
    "Character": "糖",
    "Pinyin": "táng",
    "Definition": "sugar; candy; sweets",
    "Radical": "米",
    "RadicalIndex": "119.1",
    "StrokeCount": 16,
    "HSK": 3,
    "GeneralStandard": 3374,
    "FrequencyRank": 1746
  },
  {
    "Character": "整",
    "Pinyin": "zhěng",
    "Definition": "orderly, neat, tidy; whole",
    "Radical": "攴",
    "RadicalIndex": "66.11",
    "StrokeCount": 16,
    "HSK": 3,
    "GeneralStandard": 3333,
    "FrequencyRank": 416
  },
  {
    "Character": "赢",
    "Pinyin": "yíng",
    "Definition": "win; surplus, gain, profit",
    "Radical": "亠",
    "RadicalIndex": "8.15",
    "StrokeCount": 17,
    "HSK": 3,
    "GeneralStandard": 3427,
    "FrequencyRank": 1836
  },
  {
    "Character": "警",
    "Pinyin": "jǐng",
    "Definition": "guard, watch; alert, alarm",
    "Radical": "言",
    "RadicalIndex": "149.13",
    "StrokeCount": 19,
    "HSK": 3,
    "GeneralStandard": 3453,
    "FrequencyRank": 687
  },
  {
    "Character": "范",
    "Pinyin": "fàn",
    "Definition": "surname; bee-like insect",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": "8 9",
    "HSK": 3,
    "GeneralStandard": 1010,
    "FrequencyRank": 705
  },
  {
    "Character": "士",
    "Pinyin": "shì",
    "Definition": "scholar, gentleman; soldier",
    "Radical": "士",
    "RadicalIndex": "33",
    "StrokeCount": 3,
    "HSK": 4,
    "GeneralStandard": 28,
    "FrequencyRank": 372
  },
  {
    "Character": "与",
    "Pinyin": "yǔ",
    "Definition": "and; with; to; for; give, grant",
    "Radical": "一",
    "RadicalIndex": "1.3",
    "StrokeCount": 3,
    "HSK": 4,
    "GeneralStandard": 34,
    "FrequencyRank": 108
  },
  {
    "Character": "丈",
    "Pinyin": "zhàng",
    "Definition": "unit of length equal 3.3 meters; gentleman, man, husband",
    "Radical": "一",
    "RadicalIndex": "1.2",
    "StrokeCount": 3,
    "HSK": 4,
    "GeneralStandard": 33,
    "FrequencyRank": 1242
  },
  {
    "Character": "之",
    "Pinyin": "zhī",
    "Definition": "marks preceding phrase as modifier of following phrase; it, him her, them; go to",
    "Radical": "丿",
    "RadicalIndex": "4.3",
    "StrokeCount": 3,
    "HSK": 4,
    "GeneralStandard": 58,
    "FrequencyRank": 44
  },
  {
    "Character": "寸",
    "Pinyin": "cùn",
    "Definition": "inch; small, tiny; KangXi radical 41",
    "Radical": "寸",
    "RadicalIndex": "41",
    "StrokeCount": 3,
    "HSK": 4,
    "GeneralStandard": 31,
    "FrequencyRank": 1904
  },
  {
    "Character": "巾",
    "Pinyin": "jīn",
    "Definition": "kerchief; towel; turban; KangXi radical number 50",
    "Radical": "巾",
    "RadicalIndex": "50",
    "StrokeCount": 3,
    "HSK": 4,
    "GeneralStandard": 40,
    "FrequencyRank": 2281
  },
  {
    "Character": "贝",
    "Pinyin": "bèi",
    "Definition": "sea shell; money, currency",
    "Radical": "貝",
    "RadicalIndex": "154",
    "StrokeCount": 4,
    "HSK": 4,
    "GeneralStandard": 114,
    "FrequencyRank": 1133
  },
  {
    "Character": "巨",
    "Pinyin": "jù",
    "Definition": "large, great, enormous; chief",
    "Radical": "工",
    "RadicalIndex": "48.2",
    "StrokeCount": 4,
    "HSK": 4,
    "GeneralStandard": 101,
    "FrequencyRank": 913
  },
  {
    "Character": "尺",
    "Pinyin": "chǐ",
    "Definition": undefined,
    "Radical": "尸",
    "RadicalIndex": "44.1",
    "StrokeCount": 4,
    "HSK": 4,
    "GeneralStandard": 175,
    "FrequencyRank": 1474
  },
  {
    "Character": "户",
    "Pinyin": "hù",
    "Definition": "door; family",
    "Radical": "戶",
    "RadicalIndex": "63",
    "StrokeCount": 4,
    "HSK": 4,
    "GeneralStandard": 170,
    "FrequencyRank": 801
  },
  {
    "Character": "无",
    "Pinyin": "wú",
    "Definition": "negative, no, not; KangXi radical 71",
    "Radical": "无",
    "RadicalIndex": "71",
    "StrokeCount": 4,
    "HSK": 4,
    "GeneralStandard": 81,
    "FrequencyRank": 105
  },
  {
    "Character": "牙",
    "Pinyin": "yá",
    "Definition": "tooth, molars, teeth; serrated",
    "Radical": "牙",
    "RadicalIndex": "92",
    "StrokeCount": 4,
    "HSK": 4,
    "GeneralStandard": 102,
    "FrequencyRank": 997
  },
  {
    "Character": "斗",
    "Pinyin": "dòu",
    "Definition": "Chinese peck; liquid measure",
    "Radical": "斗",
    "RadicalIndex": "68",
    "StrokeCount": 4,
    "HSK": 4,
    "GeneralStandard": 166,
    "FrequencyRank": 580
  },
  {
    "Character": "巴",
    "Pinyin": "bā",
    "Definition": "greatly desire, anxiously hope",
    "Radical": "己",
    "RadicalIndex": "49.1",
    "StrokeCount": 4,
    "HSK": 4,
    "GeneralStandard": 178,
    "FrequencyRank": 546
  },
  {
    "Character": "引",
    "Pinyin": "yǐn",
    "Definition": "to pull, draw out, attract; to stretch",
    "Radical": "弓",
    "RadicalIndex": "57.1",
    "StrokeCount": 4,
    "HSK": 4,
    "GeneralStandard": 176,
    "FrequencyRank": 479
  },
  {
    "Character": "未",
    "Pinyin": "wèi",
    "Definition": "not yet; 8th terrestrial branch",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 192,
    "FrequencyRank": 385
  },
  {
    "Character": "乎",
    "Pinyin": "hū",
    "Definition": "interrogative or exclamatory final particle",
    "Radical": "丿",
    "RadicalIndex": "4.4",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 280,
    "FrequencyRank": 458
  },
  {
    "Character": "幼",
    "Pinyin": "yòu",
    "Definition": "infant, young child; immature",
    "Radical": "幺",
    "RadicalIndex": "52.2",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 346,
    "FrequencyRank": 1577
  },
  {
    "Character": "帅",
    "Pinyin": "shuài",
    "Definition": "commander, commander-in-chief",
    "Radical": "巾",
    "RadicalIndex": "50.2",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 233,
    "FrequencyRank": 1888
  },
  {
    "Character": "玉",
    "Pinyin": "yù",
    "Definition": "jade, precious stone, gem",
    "Radical": "玉",
    "RadicalIndex": "96",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 190,
    "FrequencyRank": 1001
  },
  {
    "Character": "申",
    "Pinyin": "shēn",
    "Definition": "to state to a superior, report; extend; 9th terrestrial branch",
    "Radical": "田",
    "RadicalIndex": "102",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 240,
    "FrequencyRank": 1110
  },
  {
    "Character": "叶",
    "Pinyin": "yè",
    "Definition": "to harmonize, to rhyme; to unite; (borrowed for) leaf",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 238,
    "FrequencyRank": 920
  },
  {
    "Character": "瓜",
    "Pinyin": "guā",
    "Definition": "melon, gourd, cucumber; rad. 97",
    "Radical": "瓜",
    "RadicalIndex": "97",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 279,
    "FrequencyRank": 1827
  },
  {
    "Character": "归",
    "Pinyin": "guī",
    "Definition": "return; return to, revert to",
    "Radical": "彐",
    "RadicalIndex": "58.2",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 234,
    "FrequencyRank": 933
  },
  {
    "Character": "召",
    "Pinyin": "zhào",
    "Definition": "imperial decree; summon",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 334,
    "FrequencyRank": 1214
  },
  {
    "Character": "宁",
    "Pinyin": "níng",
    "Definition": "calm, peaceful, serene; healthy",
    "Radical": "宀",
    "RadicalIndex": "40.2",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 312,
    "FrequencyRank": 1019
  },
  {
    "Character": "闪",
    "Pinyin": "shǎn",
    "Definition": "flash; avoid, dodge, evade",
    "Radical": "門",
    "RadicalIndex": "169.2",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 305,
    "FrequencyRank": 1113
  },
  {
    "Character": "汇",
    "Pinyin": "huì",
    "Definition": "concourse; flow together, gather",
    "Radical": "水",
    "RadicalIndex": "85.2",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 309,
    "FrequencyRank": 1187
  },
  {
    "Character": "史",
    "Pinyin": "shǐ",
    "Definition": "history, chronicle, annals",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 248,
    "FrequencyRank": 456
  },
  {
    "Character": "兄",
    "Pinyin": "xiōng",
    "Definition": "elder brother",
    "Radical": "儿",
    "RadicalIndex": "10.3",
    "StrokeCount": 5,
    "HSK": 4,
    "GeneralStandard": 250,
    "FrequencyRank": 1089
  },
  {
    "Character": "妇",
    "Pinyin": "fù",
    "Definition": "married women; woman; wife",
    "Radical": "女",
    "RadicalIndex": "38.3",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 579,
    "FrequencyRank": 932
  },
  {
    "Character": "企",
    "Pinyin": "qǐ",
    "Definition": "plan a project; stand on tiptoe",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 490,
    "FrequencyRank": 450
  },
  {
    "Character": "圾",
    "Pinyin": "jī",
    "Definition": "garbage, rubbish; shaking; danger",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 362,
    "FrequencyRank": 2634
  },
  {
    "Character": "扫",
    "Pinyin": "sǎo",
    "Definition": "sweep, clear away; exterminate",
    "Radical": "手",
    "RadicalIndex": "64.3",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 365,
    "FrequencyRank": 1435
  },
  {
    "Character": "延",
    "Pinyin": "yán",
    "Definition": "delay, postpone, defer",
    "Radical": "廴",
    "RadicalIndex": "54.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 464,
    "FrequencyRank": 1103
  },
  {
    "Character": "列",
    "Pinyin": "liè",
    "Definition": "a line; to arrange in order, classify",
    "Radical": "刀",
    "RadicalIndex": "18.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 399,
    "FrequencyRank": 500
  },
  {
    "Character": "闭",
    "Pinyin": "bì",
    "Definition": "shut, close; obstruct, block up",
    "Radical": "門",
    "RadicalIndex": "169.3",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 527,
    "FrequencyRank": 1267
  },
  {
    "Character": "伙",
    "Pinyin": "huǒ",
    "Definition": "companion, colleague; utensils",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 475,
    "FrequencyRank": 1047
  },
  {
    "Character": "亚",
    "Pinyin": "yà",
    "Definition": "Asia; second",
    "Radical": "一",
    "RadicalIndex": "1.5",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 373,
    "FrequencyRank": 420
  },
  {
    "Character": "虫",
    "Pinyin": "chóng",
    "Definition": "insects, worms; KangXi radical 142",
    "Radical": "虫",
    "RadicalIndex": "142",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 423,
    "FrequencyRank": 1287
  },
  {
    "Character": "权",
    "Pinyin": "quán",
    "Definition": "power, right, authority",
    "Radical": "木",
    "RadicalIndex": "75.2",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 378,
    "FrequencyRank": 297
  },
  {
    "Character": "迅",
    "Pinyin": "xùn",
    "Definition": "quick, hasty, rapid, sudden",
    "Radical": "辵",
    "RadicalIndex": "162.3",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 565,
    "FrequencyRank": 1090
  },
  {
    "Character": "守",
    "Pinyin": "shǒu",
    "Definition": "defend, protect, guard, conserve",
    "Radical": "宀",
    "RadicalIndex": "40.3",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 546,
    "FrequencyRank": 796
  },
  {
    "Character": "则",
    "Pinyin": "zé",
    "Definition": "rule, law, regulation; grades",
    "Radical": "刀",
    "RadicalIndex": "18.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 440,
    "FrequencyRank": 284
  },
  {
    "Character": "孙",
    "Pinyin": "sūn",
    "Definition": "grandchild, descendent; surname",
    "Radical": "子",
    "RadicalIndex": "39.3",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 570,
    "FrequencyRank": 995
  },
  {
    "Character": "耳",
    "Pinyin": "ěr",
    "Definition": "ear; merely, only; handle",
    "Radical": "耳",
    "RadicalIndex": "128",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 369,
    "FrequencyRank": 887
  },
  {
    "Character": "扬",
    "Pinyin": "yáng",
    "Definition": "scatter, spread; praise",
    "Radical": "手",
    "RadicalIndex": "64.3",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 368,
    "FrequencyRank": 1084
  },
  {
    "Character": "毕",
    "Pinyin": "bì",
    "Definition": "end, finish, conclude; completed",
    "Radical": "比",
    "RadicalIndex": "81.2",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 409,
    "FrequencyRank": 1093
  },
  {
    "Character": "阵",
    "Pinyin": "zhèn",
    "Definition": "column, row or file of troops",
    "Radical": "阜",
    "RadicalIndex": "170.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 571,
    "FrequencyRank": 788
  },
  {
    "Character": "吸",
    "Pinyin": "xī",
    "Definition": "inhale, suck in; absorb; attract",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 431,
    "FrequencyRank": 924
  },
  {
    "Character": "寻",
    "Pinyin": "xún",
    "Definition": "seek, search, look for; ancient",
    "Radical": "彐",
    "RadicalIndex": "58.3",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 563,
    "FrequencyRank": 962
  },
  {
    "Character": "扩",
    "Pinyin": "kuò",
    "Definition": "expand, enlarge, stretch",
    "Radical": "手",
    "RadicalIndex": "64.3",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 364,
    "FrequencyRank": 1051
  },
  {
    "Character": "冰",
    "Pinyin": "bīng",
    "Definition": "ice; ice-cold",
    "Radical": "冫",
    "RadicalIndex": "15.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 513,
    "FrequencyRank": 1070
  },
  {
    "Character": "阶",
    "Pinyin": "jiē",
    "Definition": "stairs, steps; rank, degree",
    "Radical": "阜",
    "RadicalIndex": "170.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 574,
    "FrequencyRank": 745
  },
  {
    "Character": "江",
    "Pinyin": "jiāng",
    "Definition": "large river; yangzi; surname",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 538,
    "FrequencyRank": 577
  },
  {
    "Character": "伞",
    "Pinyin": "sǎn",
    "Definition": "umbrella, parasol, parachute",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 493,
    "FrequencyRank": 2202
  },
  {
    "Character": "冲",
    "Pinyin": "chōng",
    "Definition": "soar; pour boiling water over",
    "Radical": "冫",
    "RadicalIndex": "15.4",
    "StrokeCount": 6,
    "HSK": 4,
    "GeneralStandard": 511,
    "FrequencyRank": 702
  },
  {
    "Character": "良",
    "Pinyin": "liáng",
    "Definition": "good, virtuous, respectable",
    "Radical": "艮",
    "RadicalIndex": "138.1",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 884,
    "FrequencyRank": 835
  },
  {
    "Character": "怀",
    "Pinyin": "huái",
    "Definition": "bosom, breast; carry in bosom",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 873,
    "FrequencyRank": 762
  },
  {
    "Character": "阿",
    "Pinyin": "ā",
    "Definition": undefined,
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 913,
    "FrequencyRank": 471
  },
  {
    "Character": "阻",
    "Pinyin": "zǔ",
    "Definition": "impede, hinder, obstruct; oppose",
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 915,
    "FrequencyRank": 1175
  },
  {
    "Character": "附",
    "Pinyin": "fù",
    "Definition": "adhere to, append; rely on near to",
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 916,
    "FrequencyRank": 923
  },
  {
    "Character": "陆",
    "Pinyin": "lù",
    "Definition": "land, continental; army",
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 912,
    "FrequencyRank": 675
  },
  {
    "Character": "纷",
    "Pinyin": "fēn",
    "Definition": "in disorder, scattered, tangled",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 937,
    "FrequencyRank": 1181
  },
  {
    "Character": "含",
    "Pinyin": "hán",
    "Definition": "hold in mouth; cherish; contain",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 800,
    "FrequencyRank": 937
  },
  {
    "Character": "投",
    "Pinyin": "tóu",
    "Definition": "throw, cast, fling, pitch; jump",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 643,
    "FrequencyRank": 516
  },
  {
    "Character": "余",
    "Pinyin": "yú",
    "Definition": "I, my, me; surname; surplus",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 795,
    "FrequencyRank": 729
  },
  {
    "Character": "呀",
    "Pinyin": "ya",
    "Definition": "particle used to express surprise or mild emphasis; (Cant.) slurred form of the number ten",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 729,
    "FrequencyRank": 929
  },
  {
    "Character": "抄",
    "Pinyin": "chāo",
    "Definition": "copy, confiscate, seize",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 626,
    "FrequencyRank": 2213
  },
  {
    "Character": "兵",
    "Pinyin": "bīng",
    "Definition": "soldier, troops",
    "Radical": "八",
    "RadicalIndex": "12.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 768,
    "FrequencyRank": 398
  },
  {
    "Character": "局",
    "Pinyin": "jú",
    "Definition": "bureau, office; circumstance",
    "Radical": "尸",
    "RadicalIndex": "44.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 907,
    "FrequencyRank": 483
  },
  {
    "Character": "财",
    "Pinyin": "cái",
    "Definition": "wealth, valuables, riches",
    "Radical": "貝",
    "RadicalIndex": "154.3",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 756,
    "FrequencyRank": 680
  },
  {
    "Character": "严",
    "Pinyin": "yán",
    "Definition": "strict, rigorous, rigid; stern",
    "Radical": "一",
    "RadicalIndex": "1.6",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 671,
    "FrequencyRank": 545
  },
  {
    "Character": "究",
    "Pinyin": "jiū",
    "Definition": "examine, investigate",
    "Radical": "穴",
    "RadicalIndex": "116.2",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 881,
    "FrequencyRank": 429
  },
  {
    "Character": "迟",
    "Pinyin": "chí",
    "Definition": "late, tardy; slow; delay",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 906,
    "FrequencyRank": 1374
  },
  {
    "Character": "穷",
    "Pinyin": "qióng",
    "Definition": "poor, destitute, impoverished",
    "Radical": "穴",
    "RadicalIndex": "116.2",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 882,
    "FrequencyRank": 1343
  },
  {
    "Character": "县",
    "Pinyin": "xiàn",
    "Definition": "county, district, subdivision",
    "Radical": "厶",
    "RadicalIndex": "28.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 720,
    "FrequencyRank": 877
  },
  {
    "Character": "折",
    "Pinyin": "zhé",
    "Definition": "break off, snap; bend",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 632,
    "FrequencyRank": 1131
  },
  {
    "Character": "址",
    "Pinyin": "zhǐ",
    "Definition": "site, location, land for house",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 623,
    "FrequencyRank": 1848
  },
  {
    "Character": "即",
    "Pinyin": "jí",
    "Definition": "promptly, quickly, immediately",
    "Radical": "卩",
    "RadicalIndex": "26.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 901,
    "FrequencyRank": 293
  },
  {
    "Character": "豆",
    "Pinyin": "dòu",
    "Definition": "beans, peas; bean-shaped",
    "Radical": "豆",
    "RadicalIndex": "151",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 696,
    "FrequencyRank": 1793
  },
  {
    "Character": "伴",
    "Pinyin": "bàn",
    "Definition": "companion, comrade, partner; accompany",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 785,
    "FrequencyRank": 1298
  },
  {
    "Character": "劲",
    "Pinyin": "jìn",
    "Definition": "strong, unyielding, tough, power",
    "Radical": "力",
    "RadicalIndex": "19.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 926,
    "FrequencyRank": 1358
  },
  {
    "Character": "序",
    "Pinyin": "xù",
    "Definition": "series, serial order, sequence",
    "Radical": "广",
    "RadicalIndex": "53.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 840,
    "FrequencyRank": 836
  },
  {
    "Character": "疗",
    "Pinyin": "liáo",
    "Definition": "be healed, cured, recover",
    "Radical": "疒",
    "RadicalIndex": "104.2",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 834,
    "FrequencyRank": 949
  },
  {
    "Character": "译",
    "Pinyin": "yì",
    "Definition": "translate; decode; encode",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 898,
    "FrequencyRank": 1198
  },
  {
    "Character": "均",
    "Pinyin": "jūn",
    "Definition": "equal, even, fair; all, also",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 640,
    "FrequencyRank": 903
  },
  {
    "Character": "却",
    "Pinyin": "què",
    "Definition": "still, but; decline; retreat",
    "Radical": "卩",
    "RadicalIndex": "26.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 658,
    "FrequencyRank": 287
  },
  {
    "Character": "秀",
    "Pinyin": "xiù",
    "Definition": "ear of grain; flowering, luxuriant; refined, elegant, graceful",
    "Radical": "禾",
    "RadicalIndex": "115.2",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 765,
    "FrequencyRank": 1136
  },
  {
    "Character": "纯",
    "Pinyin": "chún",
    "Definition": "pure, clean, simple",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 931,
    "FrequencyRank": 1125
  },
  {
    "Character": "肚",
    "Pinyin": "dù",
    "Definition": "belly; abdomen; bowels",
    "Radical": "肉",
    "RadicalIndex": "130.3",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 805,
    "FrequencyRank": 1800
  },
  {
    "Character": "沉",
    "Pinyin": "chén",
    "Definition": "sink, submerge; addicted to",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 871,
    "FrequencyRank": 747
  },
  {
    "Character": "彻",
    "Pinyin": "chè",
    "Definition": "penetrate, pervade; penetrating",
    "Radical": "彳",
    "RadicalIndex": "60.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 792,
    "FrequencyRank": 1240
  },
  {
    "Character": "免",
    "Pinyin": "miǎn",
    "Definition": "spare; excuse from; evade",
    "Radical": "儿",
    "RadicalIndex": "10.5",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 810,
    "FrequencyRank": 755
  },
  {
    "Character": "尾",
    "Pinyin": "wěi",
    "Definition": "tail, extremity; end, stern",
    "Radical": "尸",
    "RadicalIndex": "44.4",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 905,
    "FrequencyRank": 1282
  },
  {
    "Character": "针",
    "Pinyin": "zhēn",
    "Definition": "needle; pin; tack; acupuncture",
    "Radical": "金",
    "RadicalIndex": "167.2",
    "StrokeCount": 7,
    "HSK": 4,
    "GeneralStandard": 757,
    "FrequencyRank": 1116
  },
  {
    "Character": "林",
    "Pinyin": "lín",
    "Definition": "forest, grove; surname",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1018,
    "FrequencyRank": 364
  },
  {
    "Character": "供",
    "Pinyin": "gōng",
    "Definition": "supply, provide for; offer in worship",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1138,
    "FrequencyRank": 550
  },
  {
    "Character": "抽",
    "Pinyin": "chōu",
    "Definition": "draw out, pull out; sprout",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 966,
    "FrequencyRank": 1178
  },
  {
    "Character": "居",
    "Pinyin": "jū",
    "Definition": "live, dwell, reside, sit",
    "Radical": "尸",
    "RadicalIndex": "44.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1308,
    "FrequencyRank": 678
  },
  {
    "Character": "败",
    "Pinyin": "bài",
    "Definition": "be defeated, decline, fail",
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1113,
    "FrequencyRank": 862
  },
  {
    "Character": "诚",
    "Pinyin": "chéng",
    "Definition": "sincere, honest; true, real",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1291,
    "FrequencyRank": 1154
  },
  {
    "Character": "细",
    "Pinyin": "xì",
    "Definition": "fine, tiny; slender, thin",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1340,
    "FrequencyRank": 597
  },
  {
    "Character": "货",
    "Pinyin": "huò",
    "Definition": "goods, commodities, products",
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1151,
    "FrequencyRank": 818
  },
  {
    "Character": "季",
    "Pinyin": "jì",
    "Definition": "quarter of year; season; surname",
    "Radical": "子",
    "RadicalIndex": "39.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1132,
    "FrequencyRank": 1279
  },
  {
    "Character": "治",
    "Pinyin": "zhì",
    "Definition": "govern, regulate, administer",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1264,
    "FrequencyRank": 274
  },
  {
    "Character": "码",
    "Pinyin": "mǎ",
    "Definition": "number, numerals; yard; symbol",
    "Radical": "石",
    "RadicalIndex": "112.3",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1046,
    "FrequencyRank": 1345
  },
  {
    "Character": "抱",
    "Pinyin": "bào",
    "Definition": "embrace, hold in arms, enfold",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 978,
    "FrequencyRank": 1122
  },
  {
    "Character": "泪",
    "Pinyin": "lèi",
    "Definition": "tears; weep, cry",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1246,
    "FrequencyRank": 1271
  },
  {
    "Character": "承",
    "Pinyin": "chéng",
    "Definition": "inherit, receive; succeed",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1315,
    "FrequencyRank": 639
  },
  {
    "Character": "浅",
    "Pinyin": "qiǎn",
    "Definition": "shallow, not deep; superficial",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1240,
    "FrequencyRank": 1721
  },
  {
    "Character": "卷",
    "Pinyin": "juǎn",
    "Definition": "scroll; curl; make a comeback",
    "Radical": "卩",
    "RadicalIndex": "26.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1231,
    "FrequencyRank": 1016
  },
  {
    "Character": "固",
    "Pinyin": "gù",
    "Definition": "to become solid, solidify; strength",
    "Radical": "囗",
    "RadicalIndex": "31.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1093,
    "FrequencyRank": 893
  },
  {
    "Character": "招",
    "Pinyin": "zhāo",
    "Definition": "beckon, summon; recruit, levy",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 988,
    "FrequencyRank": 941
  },
  {
    "Character": "担",
    "Pinyin": "dān",
    "Definition": "carry, bear, undertake",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 963,
    "FrequencyRank": 720
  },
  {
    "Character": "限",
    "Pinyin": "xiàn",
    "Definition": "boundary, limit, line",
    "Radical": "阜",
    "RadicalIndex": "170.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1323,
    "FrequencyRank": 613
  },
  {
    "Character": "妻",
    "Pinyin": "qī",
    "Definition": "wife",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1056,
    "FrequencyRank": 1076
  },
  {
    "Character": "叔",
    "Pinyin": "shū",
    "Definition": undefined,
    "Radical": "又",
    "RadicalIndex": "29.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1065,
    "FrequencyRank": 1422
  },
  {
    "Character": "刷",
    "Pinyin": "shuā",
    "Definition": "brush; clean with brush, scrub",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1310,
    "FrequencyRank": 1824
  },
  {
    "Character": "肥",
    "Pinyin": "féi",
    "Definition": "fat, plump, obese; fertile",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1189,
    "FrequencyRank": 1620
  },
  {
    "Character": "矿",
    "Pinyin": "kuàng",
    "Definition": "mine; mineral, ore",
    "Radical": "石",
    "RadicalIndex": "112.3",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1045,
    "FrequencyRank": 1385
  },
  {
    "Character": "质",
    "Pinyin": "zhì",
    "Definition": "matter, material, substance",
    "Radical": "厂",
    "RadicalIndex": "27.4",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1157,
    "FrequencyRank": 404
  },
  {
    "Character": "轮",
    "Pinyin": "lún",
    "Definition": "wheel; revolve, turn; recur",
    "Radical": "車",
    "RadicalIndex": "159.4",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1061,
    "FrequencyRank": 1096
  },
  {
    "Character": "垃",
    "Pinyin": "lā",
    "Definition": "garbage, refuse, waste",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 980,
    "FrequencyRank": 2628
  },
  {
    "Character": "构",
    "Pinyin": "gòu",
    "Definition": "frame, building, structure",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1029,
    "FrequencyRank": 511
  },
  {
    "Character": "诗",
    "Pinyin": "shī",
    "Definition": "poetry; poem, verse, ode",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1288,
    "FrequencyRank": 906
  },
  {
    "Character": "郎",
    "Pinyin": "láng",
    "Definition": "gentleman, young man; husband",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1287,
    "FrequencyRank": 1457
  },
  {
    "Character": "宝",
    "Pinyin": "bǎo",
    "Definition": "treasure, jewel; precious, rare",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1274,
    "FrequencyRank": 811
  },
  {
    "Character": "刺",
    "Pinyin": "cì",
    "Definition": "stab; prick, irritate; prod",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1039,
    "FrequencyRank": 1058
  },
  {
    "Character": "述",
    "Pinyin": "shù",
    "Definition": "narrate, state, express",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1032,
    "FrequencyRank": 674
  },
  {
    "Character": "顶",
    "Pinyin": "dǐng",
    "Definition": "top, summit, peak; to carry on the head",
    "Radical": "頁",
    "RadicalIndex": "181.2",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 971,
    "FrequencyRank": 1000
  },
  {
    "Character": "迫",
    "Pinyin": "pò",
    "Definition": "coerce, force, compel; urgent",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1156,
    "FrequencyRank": 1006
  },
  {
    "Character": "闹",
    "Pinyin": "nào",
    "Definition": "quarrel; dispute hotly",
    "Radical": "門",
    "RadicalIndex": "169.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1228,
    "FrequencyRank": 1336
  },
  {
    "Character": "征",
    "Pinyin": "zhēng",
    "Definition": "invade, attack, conquer",
    "Radical": "彳",
    "RadicalIndex": "60.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1159,
    "FrequencyRank": 739
  },
  {
    "Character": "奋",
    "Pinyin": "fèn",
    "Definition": "strive, exert effort; arouse",
    "Radical": "大",
    "RadicalIndex": "37.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1051,
    "FrequencyRank": 1268
  },
  {
    "Character": "择",
    "Pinyin": "zé",
    "Definition": "select, choose, pick out",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 992,
    "FrequencyRank": 961
  },
  {
    "Character": "尚",
    "Pinyin": "shàng",
    "Definition": "still, yet; even; fairly, rather",
    "Radical": "小",
    "RadicalIndex": "42.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1075,
    "FrequencyRank": 878
  },
  {
    "Character": "降",
    "Pinyin": "jiàng",
    "Definition": "descend, fall, drop; lower, down",
    "Radical": "阜",
    "RadicalIndex": "170.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1321,
    "FrequencyRank": 744
  },
  {
    "Character": "官",
    "Pinyin": "guān",
    "Definition": "official, public servant",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1281,
    "FrequencyRank": 432
  },
  {
    "Character": "松",
    "Pinyin": "sōng",
    "Definition": "pine tree; fir tree",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1026,
    "FrequencyRank": 895
  },
  {
    "Character": "府",
    "Pinyin": "fǔ",
    "Definition": "prefecture; prefect; government",
    "Radical": "广",
    "RadicalIndex": "53.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1211,
    "FrequencyRank": 417
  },
  {
    "Character": "购",
    "Pinyin": "gòu",
    "Definition": "buy, purchase; hire",
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1117,
    "FrequencyRank": 1078
  },
  {
    "Character": "呼",
    "Pinyin": "hū",
    "Definition": "breathe sigh, exhale; call, shout",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1099,
    "FrequencyRank": 843
  },
  {
    "Character": "依",
    "Pinyin": "yī",
    "Definition": "rely on, be set in; consent, obey a wish",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 4,
    "GeneralStandard": 1153,
    "FrequencyRank": 568
  },
  {
    "Character": "咸",
    "Pinyin": "xián",
    "Definition": "together; all, completely; united",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1443,
    "FrequencyRank": 2525
  },
  {
    "Character": "独",
    "Pinyin": "dú",
    "Definition": "alone, single, solitary, only",
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1602,
    "FrequencyRank": 627
  },
  {
    "Character": "型",
    "Pinyin": "xíng",
    "Definition": "pattern, model, type; law; mold",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1363,
    "FrequencyRank": 556
  },
  {
    "Character": "挥",
    "Pinyin": "huī",
    "Definition": "direct; wipe away; squander",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1396,
    "FrequencyRank": 742
  },
  {
    "Character": "律",
    "Pinyin": "lǜ",
    "Definition": "statute, principle, regulation",
    "Radical": "彳",
    "RadicalIndex": "60.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1583,
    "FrequencyRank": 526
  },
  {
    "Character": "测",
    "Pinyin": "cè",
    "Definition": "measure, estimate, conjecture",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1670,
    "FrequencyRank": 861
  },
  {
    "Character": "厚",
    "Pinyin": "hòu",
    "Definition": "thick; substantial; greatly",
    "Radical": "厂",
    "RadicalIndex": "27.7",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1449,
    "FrequencyRank": 1235
  },
  {
    "Character": "战",
    "Pinyin": "zhàn",
    "Definition": "war, fighting, battle",
    "Radical": "戈",
    "RadicalIndex": "62.5",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1468,
    "FrequencyRank": 145
  },
  {
    "Character": "项",
    "Pinyin": "xiàng",
    "Definition": "neck, nape of neck; sum; funds",
    "Radical": "頁",
    "RadicalIndex": "181.3",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1370,
    "FrequencyRank": 571
  },
  {
    "Character": "映",
    "Pinyin": "yìng",
    "Definition": "project; reflect light",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1487,
    "FrequencyRank": 1316
  },
  {
    "Character": "施",
    "Pinyin": "shī",
    "Definition": "grant, bestow; give; act; name",
    "Radical": "方",
    "RadicalIndex": "70.5",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1634,
    "FrequencyRank": 553
  },
  {
    "Character": "姨",
    "Pinyin": "yí",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1737,
    "FrequencyRank": 2287
  },
  {
    "Character": "统",
    "Pinyin": "tǒng",
    "Definition": "govern, command, control; unite",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1765,
    "FrequencyRank": 264
  },
  {
    "Character": "浓",
    "Pinyin": "nóng",
    "Definition": "thick, strong, concentrated",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1682,
    "FrequencyRank": 1585
  },
  {
    "Character": "临",
    "Pinyin": "lín",
    "Definition": "draw near, approach; descend",
    "Radical": "丨",
    "RadicalIndex": "2.8",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1471,
    "FrequencyRank": 839
  },
  {
    "Character": "挑",
    "Pinyin": "tiāo",
    "Definition": "a load carried on the shoulders; to carry",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1387,
    "FrequencyRank": 1309
  },
  {
    "Character": "络",
    "Pinyin": "luò",
    "Definition": "enmesh, wrap around; web, net",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1761,
    "FrequencyRank": 1118
  },
  {
    "Character": "俗",
    "Pinyin": "sú",
    "Definition": "social customs; vulgar, unrefined",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1568,
    "FrequencyRank": 1354
  },
  {
    "Character": "俩",
    "Pinyin": "liǎ",
    "Definition": "two, pair",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1557,
    "FrequencyRank": 1574
  },
  {
    "Character": "弯",
    "Pinyin": "wān",
    "Definition": "bend, curve",
    "Radical": "弓",
    "RadicalIndex": "57.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1616,
    "FrequencyRank": 1662
  },
  {
    "Character": "奖",
    "Pinyin": "jiǎng",
    "Definition": "prize, reward; give award to",
    "Radical": "大",
    "RadicalIndex": "37.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1618,
    "FrequencyRank": 1233
  },
  {
    "Character": "既",
    "Pinyin": "jì",
    "Definition": "already; de facto; since; then",
    "Radical": "无",
    "RadicalIndex": "71.5",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1721,
    "FrequencyRank": 724
  },
  {
    "Character": "泉",
    "Pinyin": "quán",
    "Definition": "spring, fountain; wealth, money",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1572,
    "FrequencyRank": 1641
  },
  {
    "Character": "研",
    "Pinyin": "yán",
    "Definition": "grind, rub; study, research",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1446,
    "FrequencyRank": 447
  },
  {
    "Character": "贴",
    "Pinyin": "tiē",
    "Definition": "paste to, stick on; attached to",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1521,
    "FrequencyRank": 1454
  },
  {
    "Character": "政",
    "Pinyin": "zhèng",
    "Definition": "government, political affairs",
    "Radical": "攴",
    "RadicalIndex": "66.4",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1376,
    "FrequencyRank": 150
  },
  {
    "Character": "括",
    "Pinyin": "kuò",
    "Definition": "include, embrace, enclose",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1383,
    "FrequencyRank": 850
  },
  {
    "Character": "勇",
    "Pinyin": "yǒng",
    "Definition": "brave, courageous, fierce",
    "Radical": "力",
    "RadicalIndex": "19.7",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1746,
    "FrequencyRank": 1206
  },
  {
    "Character": "甚",
    "Pinyin": "shén",
    "Definition": "great extent; considerably",
    "Radical": "甘",
    "RadicalIndex": "99.4",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1400,
    "FrequencyRank": 626
  },
  {
    "Character": "炼",
    "Pinyin": "liàn",
    "Definition": "smelt, refine; distill, condense",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1655,
    "FrequencyRank": 1763
  },
  {
    "Character": "促",
    "Pinyin": "cù",
    "Definition": "urge, press, hurry; close",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1563,
    "FrequencyRank": 1102
  },
  {
    "Character": "厘",
    "Pinyin": "lí",
    "Definition": "thousandth part of tael",
    "Radical": "厂",
    "RadicalIndex": "27.7",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1448,
    "FrequencyRank": 2262
  },
  {
    "Character": "骨",
    "Pinyin": "gǔ",
    "Definition": "bone; skeleton; frame, framework",
    "Radical": "骨",
    "RadicalIndex": "188",
    "StrokeCount": 9,
    "HSK": 4,
    "GeneralStandard": 1523,
    "FrequencyRank": 1036
  },
  {
    "Character": "透",
    "Pinyin": "tòu",
    "Definition": "penetrate, pass through",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1950,
    "FrequencyRank": 1077
  },
  {
    "Character": "秘",
    "Pinyin": "mì",
    "Definition": "secret, mysterious, abstruse",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1949,
    "FrequencyRank": 896
  },
  {
    "Character": "阅",
    "Pinyin": "yuè",
    "Definition": "examine, inspect, review, read",
    "Radical": "門",
    "RadicalIndex": "169.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2048,
    "FrequencyRank": 1489
  },
  {
    "Character": "虑",
    "Pinyin": "lǜ",
    "Definition": "be concerned, worry about",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1882,
    "FrequencyRank": 901
  },
  {
    "Character": "案",
    "Pinyin": "àn",
    "Definition": "table, bench; legal case",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2107,
    "FrequencyRank": 518
  },
  {
    "Character": "逛",
    "Pinyin": "guàng",
    "Definition": "ramble, stroll, roam, wander",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2003,
    "FrequencyRank": 2642
  },
  {
    "Character": "圆",
    "Pinyin": "yuán",
    "Definition": "circle; round, circular; complete",
    "Radical": "囗",
    "RadicalIndex": "31.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1922,
    "FrequencyRank": 1145
  },
  {
    "Character": "席",
    "Pinyin": "xí",
    "Definition": "seat; mat; take seat; banquet",
    "Radical": "巾",
    "RadicalIndex": "50.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2023,
    "FrequencyRank": 894
  },
  {
    "Character": "递",
    "Pinyin": "dì",
    "Definition": "hand over, deliver; substitute",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2064,
    "FrequencyRank": 1538
  },
  {
    "Character": "逐",
    "Pinyin": "zhú",
    "Definition": "chase, expel; one by one",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1870,
    "FrequencyRank": 954
  },
  {
    "Character": "途",
    "Pinyin": "tú",
    "Definition": "way, road, path, journey; course",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1982,
    "FrequencyRank": 1085
  },
  {
    "Character": "料",
    "Pinyin": "liào",
    "Definition": "consider, conjecture; materials, ingredients",
    "Radical": "斗",
    "RadicalIndex": "68.6",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2054,
    "FrequencyRank": 557
  },
  {
    "Character": "胸",
    "Pinyin": "xiōng",
    "Definition": "breast, bosom, chest; thorax",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1996,
    "FrequencyRank": 1356
  },
  {
    "Character": "盐",
    "Pinyin": "yán",
    "Definition": "salt",
    "Radical": "皿",
    "RadicalIndex": "108.5",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1790,
    "FrequencyRank": 1885
  },
  {
    "Character": "益",
    "Pinyin": "yì",
    "Definition": "profit, benefit; advantage",
    "Radical": "皿",
    "RadicalIndex": "108.5",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2055,
    "FrequencyRank": 649
  },
  {
    "Character": "宽",
    "Pinyin": "kuān",
    "Definition": "broad, wide; spacious, vast",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2098,
    "FrequencyRank": 1155
  },
  {
    "Character": "殊",
    "Pinyin": "shū",
    "Definition": "different, special, unusual",
    "Radical": "歹",
    "RadicalIndex": "78.6",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1872,
    "FrequencyRank": 1191
  },
  {
    "Character": "袜",
    "Pinyin": "wà",
    "Definition": "socks, stockings",
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2115,
    "FrequencyRank": 3045
  },
  {
    "Character": "致",
    "Pinyin": "zhì",
    "Definition": "send, deliver, present; cause",
    "Radical": "至",
    "RadicalIndex": "133.3",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1879,
    "FrequencyRank": 524
  },
  {
    "Character": "倍",
    "Pinyin": "bèi",
    "Definition": "times, fold, multiple times",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1967,
    "FrequencyRank": 1392
  },
  {
    "Character": "敌",
    "Pinyin": "dí",
    "Definition": "enemy, foe, rival; resist",
    "Radical": "舌",
    "RadicalIndex": "135.4",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1942,
    "FrequencyRank": 523
  },
  {
    "Character": "烧",
    "Pinyin": "shāo",
    "Definition": "burn; bake; heat; roast",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2060,
    "FrequencyRank": 1201
  },
  {
    "Character": "晒",
    "Pinyin": "shài",
    "Definition": "dry in sun, expose to sun",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1887,
    "FrequencyRank": 2630
  },
  {
    "Character": "恶",
    "Pinyin": "è",
    "Definition": "evil, wicked, bad, foul",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1829,
    "FrequencyRank": 792
  },
  {
    "Character": "弱",
    "Pinyin": "ruò",
    "Definition": "weak; fragile, delicate",
    "Radical": "弓",
    "RadicalIndex": "57.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 2134,
    "FrequencyRank": 1038
  },
  {
    "Character": "载",
    "Pinyin": "zài",
    "Definition": "load; carry; transport, convey",
    "Radical": "戈",
    "RadicalIndex": "62.6",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1787,
    "FrequencyRank": 977
  },
  {
    "Character": "获",
    "Pinyin": "huò",
    "Definition": "obtain, get, receive; seize",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1827,
    "FrequencyRank": 688
  },
  {
    "Character": "航",
    "Pinyin": "háng",
    "Definition": "sail; navigate; ship, boat",
    "Radical": "舟",
    "RadicalIndex": "137.4",
    "StrokeCount": 10,
    "HSK": 4,
    "GeneralStandard": 1981,
    "FrequencyRank": 773
  },
  {
    "Character": "描",
    "Pinyin": "miáo",
    "Definition": "copy, trace, sketch; depict",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2163,
    "FrequencyRank": 1246
  },
  {
    "Character": "密",
    "Pinyin": "mì",
    "Definition": "dense, thick, close; intimate",
    "Radical": "宀",
    "RadicalIndex": "40.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2458,
    "FrequencyRank": 591
  },
  {
    "Character": "盖",
    "Pinyin": "gài",
    "Definition": "cover, hide, protect",
    "Radical": "皿",
    "RadicalIndex": "108.6",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2400,
    "FrequencyRank": 1052
  },
  {
    "Character": "竟",
    "Pinyin": "jìng",
    "Definition": "finally, after all, at last",
    "Radical": "立",
    "RadicalIndex": "117.6",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2390,
    "FrequencyRank": 706
  },
  {
    "Character": "措",
    "Pinyin": "cuò",
    "Definition": "place; collect, arrange; employ",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2162,
    "FrequencyRank": 1148
  },
  {
    "Character": "圈",
    "Pinyin": "quān",
    "Definition": "to circle; a circle; corral",
    "Radical": "囗",
    "RadicalIndex": "31.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2302,
    "FrequencyRank": 1366
  },
  {
    "Character": "移",
    "Pinyin": "yí",
    "Definition": "change place, shift; move about",
    "Radical": "禾",
    "RadicalIndex": "115.6",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2316,
    "FrequencyRank": 880
  },
  {
    "Character": "毫",
    "Pinyin": "háo",
    "Definition": "fine hair; measure of length",
    "Radical": "毛",
    "RadicalIndex": "82.7",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2376,
    "FrequencyRank": 879
  },
  {
    "Character": "授",
    "Pinyin": "shòu",
    "Definition": "give to, transmit; confer",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2177,
    "FrequencyRank": 968
  },
  {
    "Character": "率",
    "Pinyin": "lǜ",
    "Definition": "to lead; ratio; rate; limit",
    "Radical": "玄",
    "RadicalIndex": "95.6",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2395,
    "FrequencyRank": 625
  },
  {
    "Character": "脱",
    "Pinyin": "tuō",
    "Definition": "take off",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2360,
    "FrequencyRank": 943
  },
  {
    "Character": "谓",
    "Pinyin": "wèi",
    "Definition": "say, tell; call, name; be called",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2466,
    "FrequencyRank": 945
  },
  {
    "Character": "淡",
    "Pinyin": "dàn",
    "Definition": "weak, watery; insipid, tasteless",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2430,
    "FrequencyRank": 1293
  },
  {
    "Character": "距",
    "Pinyin": "jù",
    "Definition": undefined,
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2273,
    "FrequencyRank": 1202
  },
  {
    "Character": "盘",
    "Pinyin": "pán",
    "Definition": "tray, plate, dish; examine",
    "Radical": "皿",
    "RadicalIndex": "108.6",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2343,
    "FrequencyRank": 1049
  },
  {
    "Character": "堵",
    "Pinyin": "dǔ",
    "Definition": "wall; stop, prevent; stop up",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2161,
    "FrequencyRank": 2284
  },
  {
    "Character": "聊",
    "Pinyin": "liáo",
    "Definition": "somewhat, slightly, at least",
    "Radical": "耳",
    "RadicalIndex": "128.5",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2196,
    "FrequencyRank": 1932
  },
  {
    "Character": "梯",
    "Pinyin": "tī",
    "Definition": "ladder, steps, stairs; lean",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2229,
    "FrequencyRank": 1672
  },
  {
    "Character": "粗",
    "Pinyin": "cū",
    "Definition": "rough, thick, course; rude",
    "Radical": "米",
    "RadicalIndex": "119.5",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2403,
    "FrequencyRank": 1414
  },
  {
    "Character": "惊",
    "Pinyin": "jīng",
    "Definition": "frighten, surprise, startle",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2445,
    "FrequencyRank": 659
  },
  {
    "Character": "寄",
    "Pinyin": "jì",
    "Definition": "send, transmit, mail; rely on",
    "Radical": "宀",
    "RadicalIndex": "40.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2453,
    "FrequencyRank": 1611
  },
  {
    "Character": "婆",
    "Pinyin": "pó",
    "Definition": "old woman; grandmother",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2435,
    "FrequencyRank": 1347
  },
  {
    "Character": "维",
    "Pinyin": "wéi",
    "Definition": "maintain, preserve, safeguard",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2492,
    "FrequencyRank": 520
  },
  {
    "Character": "笨",
    "Pinyin": "bèn",
    "Definition": "foolish, stupid, dull; awkward",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2317,
    "FrequencyRank": 2323
  },
  {
    "Character": "培",
    "Pinyin": "péi",
    "Definition": "bank up with dirt; cultivate",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2184,
    "FrequencyRank": 1152
  },
  {
    "Character": "渐",
    "Pinyin": "jiàn",
    "Definition": "gradually",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2417,
    "FrequencyRank": 870
  },
  {
    "Character": "袋",
    "Pinyin": "dài",
    "Definition": "pocket, bag, sack, pouch",
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2325,
    "FrequencyRank": 1310
  },
  {
    "Character": "综",
    "Pinyin": "zōng",
    "Definition": "arrange threads for weaving",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2496,
    "FrequencyRank": 1425
  },
  {
    "Character": "符",
    "Pinyin": "fú",
    "Definition": "i.d. tag, tally, symbol, charm",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2321,
    "FrequencyRank": 1205
  },
  {
    "Character": "减",
    "Pinyin": "jiǎn",
    "Definition": "decrease, subtract, diminish",
    "Radical": "冫",
    "RadicalIndex": "15.9",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2375,
    "FrequencyRank": 857
  },
  {
    "Character": "著",
    "Pinyin": "zhe",
    "Definition": "manifest; (Cant.) to wear",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2198,
    "FrequencyRank": 777
  },
  {
    "Character": "梦",
    "Pinyin": "mèng",
    "Definition": "dream; visionary; wishful",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2221,
    "FrequencyRank": 865
  },
  {
    "Character": "售",
    "Pinyin": "shòu",
    "Definition": "sell",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 4,
    "GeneralStandard": 2332,
    "FrequencyRank": 1114
  },
  {
    "Character": "森",
    "Pinyin": "sēn",
    "Definition": "forest; luxuriant vegetation",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2567,
    "FrequencyRank": 1029
  },
  {
    "Character": "赏",
    "Pinyin": "shǎng",
    "Definition": "reward, grant, bestow; appreciate",
    "Radical": "貝",
    "RadicalIndex": "154.8",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2609,
    "FrequencyRank": 1450
  },
  {
    "Character": "湿",
    "Pinyin": "shī",
    "Definition": "wet, moist, humid, damp; illness",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2756,
    "FrequencyRank": 1743
  },
  {
    "Character": "棵",
    "Pinyin": "kē",
    "Definition": "numerary adjunct for trees",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2571,
    "FrequencyRank": 2108
  },
  {
    "Character": "寒",
    "Pinyin": "hán",
    "Definition": "cold, wintry, chilly",
    "Radical": "宀",
    "RadicalIndex": "40.9",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2780,
    "FrequencyRank": 1297
  },
  {
    "Character": "替",
    "Pinyin": "tì",
    "Definition": "change, replace, substitute for",
    "Radical": "曰",
    "RadicalIndex": "73.8",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2506,
    "FrequencyRank": 1079
  },
  {
    "Character": "遇",
    "Pinyin": "yù",
    "Definition": "meet, come across, encounter",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2622,
    "FrequencyRank": 899
  },
  {
    "Character": "帽",
    "Pinyin": "mào",
    "Definition": "hat, cap; cap-like tops",
    "Radical": "巾",
    "RadicalIndex": "50.9",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2649,
    "FrequencyRank": 1750
  },
  {
    "Character": "编",
    "Pinyin": "biān",
    "Definition": "knit, weave; arrange; compile",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2822,
    "FrequencyRank": 858
  },
  {
    "Character": "童",
    "Pinyin": "tóng",
    "Definition": "child, boy, servant boy; virgin",
    "Radical": "立",
    "RadicalIndex": "117.7",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2735,
    "FrequencyRank": 1229
  },
  {
    "Character": "窗",
    "Pinyin": "chuāng",
    "Definition": "window",
    "Radical": "穴",
    "RadicalIndex": "116.7",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2786,
    "FrequencyRank": 1074
  },
  {
    "Character": "缓",
    "Pinyin": "huǎn",
    "Definition": "slow, leisurely; to postpone, delay",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2818,
    "FrequencyRank": 1111
  },
  {
    "Character": "隔",
    "Pinyin": "gé",
    "Definition": "separate, partition",
    "Radical": "阜",
    "RadicalIndex": "170.1",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2805,
    "FrequencyRank": 1319
  },
  {
    "Character": "趋",
    "Pinyin": "qū",
    "Definition": "hasten, hurry; be attracted to",
    "Radical": "走",
    "RadicalIndex": "156.5",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2516,
    "FrequencyRank": 1486
  },
  {
    "Character": "智",
    "Pinyin": "zhì",
    "Definition": "wisdom, knowledge, intelligence",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2670,
    "FrequencyRank": 885
  },
  {
    "Character": "登",
    "Pinyin": "dēng",
    "Definition": "rise, mount, board, climb",
    "Radical": "癶",
    "RadicalIndex": "105.7",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2813,
    "FrequencyRank": 817
  },
  {
    "Character": "暑",
    "Pinyin": "shǔ",
    "Definition": "hot",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2613,
    "FrequencyRank": 2743
  },
  {
    "Character": "销",
    "Pinyin": "xiāo",
    "Definition": "melt, fuse; market, sell",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2659,
    "FrequencyRank": 904
  },
  {
    "Character": "遗",
    "Pinyin": "yí",
    "Definition": "lose; articles lost; omit",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2633,
    "FrequencyRank": 892
  },
  {
    "Character": "植",
    "Pinyin": "zhí",
    "Definition": "plant, trees, plants; grow",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2566,
    "FrequencyRank": 1124
  },
  {
    "Character": "释",
    "Pinyin": "shì",
    "Definition": "interprete, elucidate; release",
    "Radical": "釆",
    "RadicalIndex": "165.5",
    "StrokeCount": 12,
    "HSK": 4,
    "GeneralStandard": 2710,
    "FrequencyRank": 813
  },
  {
    "Character": "微",
    "Pinyin": "wēi",
    "Definition": "small, prefix micro-, trifling",
    "Radical": "彳",
    "RadicalIndex": "60.1",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2965,
    "FrequencyRank": 653
  },
  {
    "Character": "塑",
    "Pinyin": "sù",
    "Definition": "model in clay, sculpt; plastics",
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 3001,
    "FrequencyRank": 2038
  },
  {
    "Character": "摆",
    "Pinyin": "bǎi",
    "Definition": "put, place; display; swing, sway",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2838,
    "FrequencyRank": 1158
  },
  {
    "Character": "粮",
    "Pinyin": "liáng",
    "Definition": "food, grain, provisions",
    "Radical": "米",
    "RadicalIndex": "119.7",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2998,
    "FrequencyRank": 1303
  },
  {
    "Character": "暗",
    "Pinyin": "àn",
    "Definition": "dark; obscure; in secret, covert",
    "Radical": "日",
    "RadicalIndex": "72.9",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2909,
    "FrequencyRank": 829
  },
  {
    "Character": "摸",
    "Pinyin": "mō",
    "Definition": "gently touch with hand; caress",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2833,
    "FrequencyRank": 1367
  },
  {
    "Character": "禁",
    "Pinyin": "jìn",
    "Definition": "restrict, prohibit, forbid",
    "Radical": "示",
    "RadicalIndex": "113.8",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2863,
    "FrequencyRank": 986
  },
  {
    "Character": "填",
    "Pinyin": "tián",
    "Definition": "fill in, fill up; make good",
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2834,
    "FrequencyRank": 1970
  },
  {
    "Character": "摇",
    "Pinyin": "yáo",
    "Definition": "wag, swing, wave; shake; scull",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2841,
    "FrequencyRank": 940
  },
  {
    "Character": "腰",
    "Pinyin": "yāo",
    "Definition": "waist; kidney",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2969,
    "FrequencyRank": 1458
  },
  {
    "Character": "源",
    "Pinyin": "yuán",
    "Definition": "spring; source, head; surname",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 3008,
    "FrequencyRank": 670
  },
  {
    "Character": "矮",
    "Pinyin": "ǎi",
    "Definition": "short, dwarf; low",
    "Radical": "矢",
    "RadicalIndex": "111.8",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2946,
    "FrequencyRank": 2027
  },
  {
    "Character": "雷",
    "Pinyin": "léi",
    "Definition": "thunder",
    "Radical": "雨",
    "RadicalIndex": "173.5",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2884,
    "FrequencyRank": 686
  },
  {
    "Character": "置",
    "Pinyin": "zhì",
    "Definition": "place, lay out; set aside",
    "Radical": "网",
    "RadicalIndex": "122.8",
    "StrokeCount": 13,
    "HSK": 4,
    "GeneralStandard": 2931,
    "FrequencyRank": 677
  },
  {
    "Character": "酸",
    "Pinyin": "suān",
    "Definition": "tart, sour; acid; stiff; spoiled",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3089,
    "FrequencyRank": 1456
  },
  {
    "Character": "腐",
    "Pinyin": "fǔ",
    "Definition": "rot, decay, spoil; rotten",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3144,
    "FrequencyRank": 1576
  },
  {
    "Character": "裹",
    "Pinyin": "guǒ",
    "Definition": "wrap, bind; encircle, confine",
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3139,
    "FrequencyRank": 2387
  },
  {
    "Character": "鲜",
    "Pinyin": "xiān",
    "Definition": "fresh; delicious; attractive",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3135,
    "FrequencyRank": 958
  },
  {
    "Character": "模",
    "Pinyin": "mó",
    "Definition": "model, standard, pattern; copy",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3078,
    "FrequencyRank": 689
  },
  {
    "Character": "辣",
    "Pinyin": "là",
    "Definition": "peppery, pungent, hot; cruel",
    "Radical": "辛",
    "RadicalIndex": "160.7",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3148,
    "FrequencyRank": 2420
  },
  {
    "Character": "疑",
    "Pinyin": "yí",
    "Definition": "doubt, question, suspect",
    "Radical": "疋",
    "RadicalIndex": "103.9",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3136,
    "FrequencyRank": 698
  },
  {
    "Character": "稳",
    "Pinyin": "wěn",
    "Definition": "stable, firm, solid, steady",
    "Radical": "禾",
    "RadicalIndex": "115.9",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3118,
    "FrequencyRank": 1055
  },
  {
    "Character": "聚",
    "Pinyin": "jù",
    "Definition": "assemble, meet together, collect",
    "Radical": "耳",
    "RadicalIndex": "128.8",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3065,
    "FrequencyRank": 1306
  },
  {
    "Character": "缩",
    "Pinyin": "suō",
    "Definition": "contract, draw in, reduce",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3188,
    "FrequencyRank": 1304
  },
  {
    "Character": "锻",
    "Pinyin": "duàn",
    "Definition": "forge metal; temper, refine",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": 4,
    "GeneralStandard": 3114,
    "FrequencyRank": 2531
  },
  {
    "Character": "躺",
    "Pinyin": "tǎng",
    "Definition": "lie down, recline",
    "Radical": "身",
    "RadicalIndex": "158.8",
    "StrokeCount": 15,
    "HSK": 4,
    "GeneralStandard": 3270,
    "FrequencyRank": 1608
  },
  {
    "Character": "趣",
    "Pinyin": "qù",
    "Definition": undefined,
    "Radical": "走",
    "RadicalIndex": "156.8",
    "StrokeCount": 15,
    "HSK": 4,
    "GeneralStandard": 3194,
    "FrequencyRank": 1065
  },
  {
    "Character": "潮",
    "Pinyin": "cháo",
    "Definition": "tide; moist, wet, damp; flow",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": 4,
    "GeneralStandard": 3292,
    "FrequencyRank": 1302
  },
  {
    "Character": "避",
    "Pinyin": "bì",
    "Definition": "avoid; turn aside; escape; hide",
    "Radical": "辵",
    "RadicalIndex": "162.13",
    "StrokeCount": 16,
    "HSK": 4,
    "GeneralStandard": 3385,
    "FrequencyRank": 991
  },
  {
    "Character": "醒",
    "Pinyin": "xǐng",
    "Definition": "wake up; sober up; startle",
    "Radical": "酉",
    "RadicalIndex": "164.9",
    "StrokeCount": 16,
    "HSK": 4,
    "GeneralStandard": 3336,
    "FrequencyRank": 1075
  },
  {
    "Character": "镜",
    "Pinyin": "jìng",
    "Definition": "mirror; lens; glass; glasses",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 16,
    "HSK": 4,
    "GeneralStandard": 3354,
    "FrequencyRank": 1251
  },
  {
    "Character": "操",
    "Pinyin": "cāo",
    "Definition": "conduct, run, control, manage",
    "Radical": "手",
    "RadicalIndex": "64.13",
    "StrokeCount": 16,
    "HSK": 4,
    "GeneralStandard": 3317,
    "FrequencyRank": 1173
  },
  {
    "Character": "薄",
    "Pinyin": "báo",
    "Definition": "thin, slight, weak; poor, stingy",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": 4,
    "GeneralStandard": 3326,
    "FrequencyRank": 1433
  },
  {
    "Character": "默",
    "Pinyin": "mò",
    "Definition": "silent; quiet, still; dark",
    "Radical": "黑",
    "RadicalIndex": "203.4",
    "StrokeCount": 16,
    "HSK": 4,
    "GeneralStandard": 3352,
    "FrequencyRank": 1031
  },
  {
    "Character": "激",
    "Pinyin": "jī",
    "Definition": "arouse, excite, incite; quickly",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": 4,
    "GeneralStandard": 3379,
    "FrequencyRank": 710
  },
  {
    "Character": "燃",
    "Pinyin": "rán",
    "Definition": "burn; light fire, ignite",
    "Radical": "火",
    "RadicalIndex": "86.12",
    "StrokeCount": 16,
    "HSK": 4,
    "GeneralStandard": 3376,
    "FrequencyRank": 1553
  },
  {
    "Character": "赞",
    "Pinyin": "zàn",
    "Definition": "help, support, assist, aid",
    "Radical": "貝",
    "RadicalIndex": "154.12",
    "StrokeCount": 16,
    "HSK": 4,
    "GeneralStandard": 3355,
    "FrequencyRank": 1179
  },
  {
    "Character": "辩",
    "Pinyin": "biàn",
    "Definition": "dispute, argue, debate, discuss",
    "Radical": "辛",
    "RadicalIndex": "160.9",
    "StrokeCount": 16,
    "HSK": 4,
    "GeneralStandard": 3372,
    "FrequencyRank": 1355
  },
  {
    "Character": "戴",
    "Pinyin": "dài",
    "Definition": "wear on top; support",
    "Radical": "戈",
    "RadicalIndex": "62.14",
    "StrokeCount": 17,
    "HSK": 4,
    "GeneralStandard": 3388,
    "FrequencyRank": 1228
  },
  {
    "Character": "擦",
    "Pinyin": "cā",
    "Definition": "wipe, scrub, rub, scour; brush",
    "Radical": "手",
    "RadicalIndex": "64.14",
    "StrokeCount": 17,
    "HSK": 4,
    "GeneralStandard": 3389,
    "FrequencyRank": 1761
  },
  {
    "Character": "翻",
    "Pinyin": "fān",
    "Definition": "flip over, upset, capsize",
    "Radical": "羽",
    "RadicalIndex": "124.12",
    "StrokeCount": 18,
    "HSK": 4,
    "GeneralStandard": 3445,
    "FrequencyRank": 1027
  },
  {
    "Character": "乙",
    "Pinyin": "yǐ",
    "Definition": "second; 2nd heavenly stem",
    "Radical": "乙",
    "RadicalIndex": "5",
    "StrokeCount": 1,
    "HSK": 5,
    "GeneralStandard": 2,
    "FrequencyRank": 1872
  },
  {
    "Character": "叉",
    "Pinyin": "chā",
    "Definition": "crotch; fork, prong",
    "Radical": "又",
    "RadicalIndex": "29.1",
    "StrokeCount": 3,
    "HSK": 5,
    "GeneralStandard": 71,
    "FrequencyRank": 2231
  },
  {
    "Character": "夕",
    "Pinyin": "xī",
    "Definition": "evening, night, dusk; slanted",
    "Radical": "夕",
    "RadicalIndex": "36",
    "StrokeCount": 3,
    "HSK": 5,
    "GeneralStandard": 46,
    "FrequencyRank": 2003
  },
  {
    "Character": "亏",
    "Pinyin": "kuī",
    "Definition": "lose, fail; damage; deficient",
    "Radical": "二",
    "RadicalIndex": "7.1",
    "StrokeCount": 3,
    "HSK": 5,
    "GeneralStandard": 25,
    "FrequencyRank": 1738
  },
  {
    "Character": "尤",
    "Pinyin": "yóu",
    "Definition": "especially, particularly",
    "Radical": "尢",
    "RadicalIndex": "43.1",
    "StrokeCount": 4,
    "HSK": 5,
    "GeneralStandard": 98,
    "FrequencyRank": 1099
  },
  {
    "Character": "忆",
    "Pinyin": "yì",
    "Definition": "remember, reflect upon; memory",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 4,
    "HSK": 5,
    "GeneralStandard": 167,
    "FrequencyRank": 1333
  },
  {
    "Character": "匹",
    "Pinyin": "pǐ",
    "Definition": "bolt of cloth; counter for horses",
    "Radical": "匸",
    "RadicalIndex": "23.2",
    "StrokeCount": 4,
    "HSK": 5,
    "GeneralStandard": 99,
    "FrequencyRank": 1908
  },
  {
    "Character": "丑",
    "Pinyin": "chǒu",
    "Definition": "clown, comedian; 2nd terrestrial branch",
    "Radical": "一",
    "RadicalIndex": "1.3",
    "StrokeCount": 4,
    "HSK": 5,
    "GeneralStandard": 177,
    "FrequencyRank": 1901
  },
  {
    "Character": "欠",
    "Pinyin": "qiàn",
    "Definition": "owe, lack, be deficient; KangXi radical number 76",
    "Radical": "欠",
    "RadicalIndex": "76",
    "StrokeCount": 4,
    "HSK": 5,
    "GeneralStandard": 153,
    "FrequencyRank": 1948
  },
  {
    "Character": "乏",
    "Pinyin": "fá",
    "Definition": "lack; poor",
    "Radical": "丿",
    "RadicalIndex": "4.4",
    "StrokeCount": 4,
    "HSK": 5,
    "GeneralStandard": 147,
    "FrequencyRank": 1399
  },
  {
    "Character": "厅",
    "Pinyin": "tīng",
    "Definition": "hall, central room",
    "Radical": "厂",
    "RadicalIndex": "27.2",
    "StrokeCount": 4,
    "HSK": 5,
    "GeneralStandard": 90,
    "FrequencyRank": 1217
  },
  {
    "Character": "劝",
    "Pinyin": "quàn",
    "Definition": "recommend, advise, urge",
    "Radical": "力",
    "RadicalIndex": "19.2",
    "StrokeCount": 4,
    "HSK": 5,
    "GeneralStandard": 186,
    "FrequencyRank": 1562
  },
  {
    "Character": "册",
    "Pinyin": "cè",
    "Definition": "book, volume, register, list",
    "Radical": "冂",
    "RadicalIndex": "13.3",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 290,
    "FrequencyRank": 1525
  },
  {
    "Character": "尔",
    "Pinyin": "ěr",
    "Definition": "you; that, those; final particle",
    "Radical": "小",
    "RadicalIndex": "42.2",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 286,
    "FrequencyRank": 220
  },
  {
    "Character": "甲",
    "Pinyin": "jiǎ",
    "Definition": "armor, shell; fingernails; 1st heavenly stem",
    "Radical": "田",
    "RadicalIndex": "102",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 239,
    "FrequencyRank": 1106
  },
  {
    "Character": "厉",
    "Pinyin": "lì",
    "Definition": "whetstone; grind, sharpen; whet",
    "Radical": "厂",
    "RadicalIndex": "27.3",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 215,
    "FrequencyRank": 1339
  },
  {
    "Character": "矛",
    "Pinyin": "máo",
    "Definition": "spear, lance; KangXi radical 110",
    "Radical": "矛",
    "RadicalIndex": "110",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 343,
    "FrequencyRank": 1441
  },
  {
    "Character": "仔",
    "Pinyin": "zǐ",
    "Definition": "small thing, child; young animal",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 276,
    "FrequencyRank": 1572
  },
  {
    "Character": "令",
    "Pinyin": "lìng",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 282,
    "FrequencyRank": 378
  },
  {
    "Character": "旦",
    "Pinyin": "dàn",
    "Definition": "dawn; morning; day",
    "Radical": "日",
    "RadicalIndex": "72.1",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 235,
    "FrequencyRank": 1300
  },
  {
    "Character": "击",
    "Pinyin": "jī",
    "Definition": "strike, hit, beat; attack, fight",
    "Radical": "凵",
    "RadicalIndex": "17.3",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 195,
    "FrequencyRank": 395
  },
  {
    "Character": "扔",
    "Pinyin": "rēng",
    "Definition": "throw, hurl; throw away, cast",
    "Radical": "手",
    "RadicalIndex": "64.2",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 203,
    "FrequencyRank": 1905
  },
  {
    "Character": "央",
    "Pinyin": "yāng",
    "Definition": "center, conclude, run out; beg",
    "Radical": "大",
    "RadicalIndex": "37.2",
    "StrokeCount": 5,
    "HSK": 5,
    "GeneralStandard": 249,
    "FrequencyRank": 800
  },
  {
    "Character": "污",
    "Pinyin": "wū",
    "Definition": "filthy, dirty, impure, polluted",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 537,
    "FrequencyRank": 1405
  },
  {
    "Character": "执",
    "Pinyin": "zhí",
    "Definition": "hold in hand; keep; carry out",
    "Radical": "手",
    "RadicalIndex": "64.3",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 363,
    "FrequencyRank": 763
  },
  {
    "Character": "曲",
    "Pinyin": "qū",
    "Definition": "crooked, bent; wrong, false",
    "Radical": "曰",
    "RadicalIndex": "73.2",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 424,
    "FrequencyRank": 1066
  },
  {
    "Character": "夹",
    "Pinyin": "jiā",
    "Definition": "be wedged or inserted between",
    "Radical": "一",
    "RadicalIndex": "1.5",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 402,
    "FrequencyRank": 1758
  },
  {
    "Character": "灰",
    "Pinyin": "huī",
    "Definition": "ashes; dust; lime, mortar",
    "Radical": "火",
    "RadicalIndex": "86.2",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 397,
    "FrequencyRank": 1311
  },
  {
    "Character": "丢",
    "Pinyin": "diū",
    "Definition": "discard",
    "Radical": "一",
    "RadicalIndex": "1.5",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 447,
    "FrequencyRank": 1639
  },
  {
    "Character": "托",
    "Pinyin": "tuō",
    "Definition": "to hold up with palm; to support, rely on",
    "Radical": "手",
    "RadicalIndex": "64.3",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 359,
    "FrequencyRank": 799
  },
  {
    "Character": "肌",
    "Pinyin": "jī",
    "Definition": "muscle tissue; meat on bones",
    "Radical": "肉",
    "RadicalIndex": "130.2",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 495,
    "FrequencyRank": 1935
  },
  {
    "Character": "厌",
    "Pinyin": "yàn",
    "Definition": "dislike, detest, reject; satiate",
    "Radical": "厂",
    "RadicalIndex": "27.4",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 386,
    "FrequencyRank": 1633
  },
  {
    "Character": "吐",
    "Pinyin": "tǔ",
    "Definition": "vomit, spew out, cough up",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 421,
    "FrequencyRank": 1653
  },
  {
    "Character": "闯",
    "Pinyin": "chuǎng",
    "Definition": "rush in, burst in, charge in",
    "Radical": "門",
    "RadicalIndex": "169.3",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 529,
    "FrequencyRank": 2129
  },
  {
    "Character": "吓",
    "Pinyin": "xià",
    "Definition": "scare, frighten; intimidate",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 422,
    "FrequencyRank": 1466
  },
  {
    "Character": "杀",
    "Pinyin": "shā",
    "Definition": "kill, slaughter, murder; hurt",
    "Radical": "木",
    "RadicalIndex": "75.2",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 487,
    "FrequencyRank": 587
  },
  {
    "Character": "朵",
    "Pinyin": "duǒ",
    "Definition": "cluster of flowers; earlobe",
    "Radical": "木",
    "RadicalIndex": "75.2",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 497,
    "FrequencyRank": 1571
  },
  {
    "Character": "池",
    "Pinyin": "chí",
    "Definition": "pool, pond; moat; cistern",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 540,
    "FrequencyRank": 1709
  },
  {
    "Character": "军",
    "Pinyin": "jūn",
    "Definition": "army, military; soldiers, troops",
    "Radical": "車",
    "RadicalIndex": "159.2",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 552,
    "FrequencyRank": 102
  },
  {
    "Character": "羽",
    "Pinyin": "yǔ",
    "Definition": "feather, plume; wings; rad. 124",
    "Radical": "羽",
    "RadicalIndex": "124",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 585,
    "FrequencyRank": 1865
  },
  {
    "Character": "仿",
    "Pinyin": "fǎng",
    "Definition": "imitate, copy; as if",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 474,
    "FrequencyRank": 1259
  },
  {
    "Character": "竹",
    "Pinyin": "zhú",
    "Definition": "bamboo; flute; KangXi radical 118",
    "Radical": "竹",
    "RadicalIndex": "118",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 450,
    "FrequencyRank": 1588
  },
  {
    "Character": "协",
    "Pinyin": "xié",
    "Definition": "be united; cooperate",
    "Radical": "十",
    "RadicalIndex": "24.4",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 383,
    "FrequencyRank": 735
  },
  {
    "Character": "汗",
    "Pinyin": "hàn",
    "Definition": "perspiration, sweat",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": 5,
    "GeneralStandard": 536,
    "FrequencyRank": 1490
  },
  {
    "Character": "启",
    "Pinyin": "qǐ",
    "Definition": "open; begin, commence; explain",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 886,
    "FrequencyRank": 1320
  },
  {
    "Character": "伸",
    "Pinyin": "shēn",
    "Definition": "extend, stretch out, open up; trust",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 775,
    "FrequencyRank": 1161
  },
  {
    "Character": "狂",
    "Pinyin": "kuáng",
    "Definition": "insane, mad; violent; wild",
    "Radical": "犬",
    "RadicalIndex": "94.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 811,
    "FrequencyRank": 1147
  },
  {
    "Character": "私",
    "Pinyin": "sī",
    "Definition": "private, personal; secret",
    "Radical": "禾",
    "RadicalIndex": "115.2",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 766,
    "FrequencyRank": 1023
  },
  {
    "Character": "拒",
    "Pinyin": "jù",
    "Definition": "ward off with hand, defend",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 620,
    "FrequencyRank": 1331
  },
  {
    "Character": "犹",
    "Pinyin": "yóu",
    "Definition": "like, similar to, just like, as",
    "Radical": "犬",
    "RadicalIndex": "94.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 812,
    "FrequencyRank": 1230
  },
  {
    "Character": "灾",
    "Pinyin": "zāi",
    "Definition": "calamity, disaster, catastrophe",
    "Radical": "火",
    "RadicalIndex": "86.3",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 883,
    "FrequencyRank": 1349
  },
  {
    "Character": "邻",
    "Pinyin": "lín",
    "Definition": "neighbor; neighborhood",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 801,
    "FrequencyRank": 1659
  },
  {
    "Character": "冻",
    "Pinyin": "dòng",
    "Definition": "freeze; cold, congeal; jelly",
    "Radical": "冫",
    "RadicalIndex": "15.5",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 827,
    "FrequencyRank": 2127
  },
  {
    "Character": "辛",
    "Pinyin": "xīn",
    "Definition": "bitter; toilsome, laborious; 8th heavenly stem",
    "Radical": "辛",
    "RadicalIndex": "160",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 841,
    "FrequencyRank": 1463
  },
  {
    "Character": "抢",
    "Pinyin": "qiǎng",
    "Definition": "plunder, rob, take by force",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 637,
    "FrequencyRank": 1412
  },
  {
    "Character": "扮",
    "Pinyin": "bàn",
    "Definition": "dress up; dress up as",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 636,
    "FrequencyRank": 1974
  },
  {
    "Character": "戒",
    "Pinyin": "jiè",
    "Definition": "warn, caution, admonish",
    "Radical": "戈",
    "RadicalIndex": "62.3",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 606,
    "FrequencyRank": 1566
  },
  {
    "Character": "库",
    "Pinyin": "kù",
    "Definition": "armory, treasury, storehouse",
    "Radical": "广",
    "RadicalIndex": "53.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 832,
    "FrequencyRank": 1097
  },
  {
    "Character": "弃",
    "Pinyin": "qì",
    "Definition": "reject, abandon, discard",
    "Radical": "廾",
    "RadicalIndex": "55.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 842,
    "FrequencyRank": 1105
  },
  {
    "Character": "肠",
    "Pinyin": "cháng",
    "Definition": "intestines; emotions; sausage",
    "Radical": "肉",
    "RadicalIndex": "130.3",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 807,
    "FrequencyRank": 1921
  },
  {
    "Character": "寿",
    "Pinyin": "shòu",
    "Definition": "old age, long life; lifespan",
    "Radical": "寸",
    "RadicalIndex": "41.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 599,
    "FrequencyRank": 1615
  },
  {
    "Character": "劳",
    "Pinyin": "láo",
    "Definition": "labor, toil, do manual work",
    "Radical": "力",
    "RadicalIndex": "19.5",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 674,
    "FrequencyRank": 679
  },
  {
    "Character": "估",
    "Pinyin": "gū",
    "Definition": "merchant; estimate, guess, presume",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 769,
    "FrequencyRank": 1326
  },
  {
    "Character": "饮",
    "Pinyin": "yǐn",
    "Definition": "drink; swallow; kind of drink",
    "Radical": "食",
    "RadicalIndex": "184.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 824,
    "FrequencyRank": 1569
  },
  {
    "Character": "励",
    "Pinyin": "lì",
    "Definition": "strive; encourage",
    "Radical": "力",
    "RadicalIndex": "19.5",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 702,
    "FrequencyRank": 1468
  },
  {
    "Character": "呆",
    "Pinyin": "dāi",
    "Definition": "dull; dull-minded, simple, stupid",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 722,
    "FrequencyRank": 1338
  },
  {
    "Character": "扶",
    "Pinyin": "fú",
    "Definition": "support, help; protect; hold on",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 612,
    "FrequencyRank": 1612
  },
  {
    "Character": "返",
    "Pinyin": "fǎn",
    "Definition": "return, revert to, restore",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 794,
    "FrequencyRank": 1430
  },
  {
    "Character": "吨",
    "Pinyin": "dūn",
    "Definition": "metric ton; (Cant.) to babble, gibberish",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 730,
    "FrequencyRank": 1700
  },
  {
    "Character": "忍",
    "Pinyin": "rěn",
    "Definition": "endure, bear, suffer; forbear",
    "Radical": "心",
    "RadicalIndex": "61.3",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 925,
    "FrequencyRank": 1127
  },
  {
    "Character": "扰",
    "Pinyin": "rǎo",
    "Definition": "disturb, annoy, agitate",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 618,
    "FrequencyRank": 1483
  },
  {
    "Character": "诊",
    "Pinyin": "zhěn",
    "Definition": "examine patient, diagnose",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 896,
    "FrequencyRank": 1401
  },
  {
    "Character": "闲",
    "Pinyin": "xián",
    "Definition": "fence, guard; defend; idle time",
    "Radical": "門",
    "RadicalIndex": "169.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 846,
    "FrequencyRank": 1529
  },
  {
    "Character": "沟",
    "Pinyin": "gōu",
    "Definition": "ditch, drain, narrow waterway",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 868,
    "FrequencyRank": 1610
  },
  {
    "Character": "泛",
    "Pinyin": "fàn",
    "Definition": "to drift, float; careless, reckless",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 865,
    "FrequencyRank": 1327
  },
  {
    "Character": "违",
    "Pinyin": "wéi",
    "Definition": "disobey, violate, defy; be apart from",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 609,
    "FrequencyRank": 1184
  },
  {
    "Character": "纲",
    "Pinyin": "gāng",
    "Definition": "heavy rope, hawser; main points",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 5,
    "GeneralStandard": 933,
    "FrequencyRank": 1934
  },
  {
    "Character": "怜",
    "Pinyin": "lián",
    "Definition": "pity, sympathize",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1270,
    "FrequencyRank": 1599
  },
  {
    "Character": "详",
    "Pinyin": "xiáng",
    "Definition": "detailed, complete, thorough",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1301,
    "FrequencyRank": 1439
  },
  {
    "Character": "肤",
    "Pinyin": "fū",
    "Definition": "skin; superficial, shallow",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1180,
    "FrequencyRank": 1790
  },
  {
    "Character": "织",
    "Pinyin": "zhī",
    "Definition": "weave, knit; organize, unite",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1342,
    "FrequencyRank": 578
  },
  {
    "Character": "肃",
    "Pinyin": "sù",
    "Definition": "pay respects; reverently",
    "Radical": "聿",
    "RadicalIndex": "129.4",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1303,
    "FrequencyRank": 1518
  },
  {
    "Character": "坦",
    "Pinyin": "tǎn",
    "Definition": "flat, smooth; self-possessed",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 962,
    "FrequencyRank": 1017
  },
  {
    "Character": "虎",
    "Pinyin": "hǔ",
    "Definition": "tiger; brave, fierce; surname",
    "Radical": "虍",
    "RadicalIndex": "141.2",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1071,
    "FrequencyRank": 1083
  },
  {
    "Character": "拔",
    "Pinyin": "bá",
    "Definition": "uproot, pull out",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 959,
    "FrequencyRank": 1502
  },
  {
    "Character": "享",
    "Pinyin": "xiǎng",
    "Definition": "enjoy",
    "Radical": "亠",
    "RadicalIndex": "8.6",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1206,
    "FrequencyRank": 1227
  },
  {
    "Character": "押",
    "Pinyin": "yā",
    "Definition": "mortgage, pledge; deposit; to pawn; to arrest, detain, guard",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 965,
    "FrequencyRank": 1775
  },
  {
    "Character": "届",
    "Pinyin": "jiè",
    "Definition": "numerary adjunct for time, term",
    "Radical": "尸",
    "RadicalIndex": "44.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1309,
    "FrequencyRank": 1522
  },
  {
    "Character": "肩",
    "Pinyin": "jiān",
    "Definition": "shoulders; to shoulder; bear",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1289,
    "FrequencyRank": 1415
  },
  {
    "Character": "凭",
    "Pinyin": "píng",
    "Definition": "lean on, depend on, rely on",
    "Radical": "几",
    "RadicalIndex": "16.6",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1148,
    "FrequencyRank": 1410
  },
  {
    "Character": "饰",
    "Pinyin": "shì",
    "Definition": "decorate, ornament, adorn",
    "Radical": "食",
    "RadicalIndex": "184.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1201,
    "FrequencyRank": 1604
  },
  {
    "Character": "舍",
    "Pinyin": "shě",
    "Definition": "house, dwelling; dwell, reside",
    "Radical": "舌",
    "RadicalIndex": "135.2",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1165,
    "FrequencyRank": 1344
  },
  {
    "Character": "卧",
    "Pinyin": "wò",
    "Definition": "lie down; crouch",
    "Radical": "臣",
    "RadicalIndex": "131.2",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1037,
    "FrequencyRank": 1944
  },
  {
    "Character": "驶",
    "Pinyin": "shǐ",
    "Definition": "sail, drive, pilot; fast, quick",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1341,
    "FrequencyRank": 1520
  },
  {
    "Character": "软",
    "Pinyin": "ruǎn",
    "Definition": "soft, flexible, pliable; weak",
    "Radical": "車",
    "RadicalIndex": "159.4",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1062,
    "FrequencyRank": 1043
  },
  {
    "Character": "披",
    "Pinyin": "pī",
    "Definition": "wear; split; crack",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 990,
    "FrequencyRank": 2032
  },
  {
    "Character": "析",
    "Pinyin": "xī",
    "Definition": "split wood; break apart; divide",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1024,
    "FrequencyRank": 1073
  },
  {
    "Character": "驾",
    "Pinyin": "jià",
    "Definition": "to drive, sail, fly; a cart, carriage",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1332,
    "FrequencyRank": 1567
  },
  {
    "Character": "兔",
    "Pinyin": "tù",
    "Definition": "rabbit, hare",
    "Radical": "儿",
    "RadicalIndex": "10.6",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1195,
    "FrequencyRank": 2364
  },
  {
    "Character": "肯",
    "Pinyin": "kěn",
    "Definition": "willing; consent to, permit",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1067,
    "FrequencyRank": 860
  },
  {
    "Character": "柜",
    "Pinyin": "guì",
    "Definition": "cabinet, cupboard; shop counter",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1022,
    "FrequencyRank": 1994
  },
  {
    "Character": "帘",
    "Pinyin": "lián",
    "Definition": "flag-sign of a tavern",
    "Radical": "巾",
    "RadicalIndex": "50.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1283,
    "FrequencyRank": 2458
  },
  {
    "Character": "奈",
    "Pinyin": "nài",
    "Definition": "but, how; bear, stand, endure",
    "Radical": "大",
    "RadicalIndex": "37.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1048,
    "FrequencyRank": 1624
  },
  {
    "Character": "彼",
    "Pinyin": "bǐ",
    "Definition": "that, there, those",
    "Radical": "彳",
    "RadicalIndex": "60.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1162,
    "FrequencyRank": 1256
  },
  {
    "Character": "枪",
    "Pinyin": "qiāng",
    "Definition": "spear, lance; gun, rifle",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1027,
    "FrequencyRank": 874
  },
  {
    "Character": "岸",
    "Pinyin": "àn",
    "Definition": "bank, shore; beach, coast",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1105,
    "FrequencyRank": 971
  },
  {
    "Character": "拥",
    "Pinyin": "yōng",
    "Definition": "embrace, hug, squeeze; crowd",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 974,
    "FrequencyRank": 1059
  },
  {
    "Character": "询",
    "Pinyin": "xún",
    "Definition": "inquire into, ask about; consult",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1299,
    "FrequencyRank": 1617
  },
  {
    "Character": "泼",
    "Pinyin": "pō",
    "Definition": "pour, splash, water, sprinkle",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1262,
    "FrequencyRank": 2327
  },
  {
    "Character": "抬",
    "Pinyin": "tái",
    "Definition": "lift, carry",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 993,
    "FrequencyRank": 1386
  },
  {
    "Character": "欣",
    "Pinyin": "xīn",
    "Definition": "happy, joyous, delighted",
    "Radical": "欠",
    "RadicalIndex": "76.4",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1158,
    "FrequencyRank": 1523
  },
  {
    "Character": "郊",
    "Pinyin": "jiāo",
    "Definition": "suburbs; waste land, open spaces",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1218,
    "FrequencyRank": 2351
  },
  {
    "Character": "拆",
    "Pinyin": "chāi",
    "Definition": "to break up, split apart, rip open; to destroy",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 972,
    "FrequencyRank": 2076
  },
  {
    "Character": "委",
    "Pinyin": "wěi",
    "Definition": "appoint, send, commission",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1133,
    "FrequencyRank": 457
  },
  {
    "Character": "艰",
    "Pinyin": "jiān",
    "Definition": "difficult, hard; distressing",
    "Radical": "又",
    "RadicalIndex": "29.6",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1335,
    "FrequencyRank": 1647
  },
  {
    "Character": "版",
    "Pinyin": "bǎn",
    "Definition": "printing blocks; edition",
    "Radical": "片",
    "RadicalIndex": "91.4",
    "StrokeCount": 8,
    "HSK": 5,
    "GeneralStandard": 1143,
    "FrequencyRank": 810
  },
  {
    "Character": "洞",
    "Pinyin": "dòng",
    "Definition": "cave, grotto; ravine; hole",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1669,
    "FrequencyRank": 1015
  },
  {
    "Character": "胃",
    "Pinyin": "wèi",
    "Definition": "stomach; gizzard of fowl",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1494,
    "FrequencyRank": 1957
  },
  {
    "Character": "秒",
    "Pinyin": "miǎo",
    "Definition": "beard of grain or corn; a second",
    "Radical": "禾",
    "RadicalIndex": "115.4",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1547,
    "FrequencyRank": 2028
  },
  {
    "Character": "鬼",
    "Pinyin": "guǐ",
    "Definition": "ghost; spirit of dead; devil",
    "Radical": "鬼",
    "RadicalIndex": "194",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1573,
    "FrequencyRank": 1042
  },
  {
    "Character": "绒",
    "Pinyin": "róng",
    "Definition": "silk, cotton, or woolen fabric",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1753,
    "FrequencyRank": 2678
  },
  {
    "Character": "珍",
    "Pinyin": "zhēn",
    "Definition": "precious, valuable, rare",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1358,
    "FrequencyRank": 1314
  },
  {
    "Character": "拾",
    "Pinyin": "shí",
    "Definition": "pick up, collect, tidy up; accounting form of the numeral ten",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1386,
    "FrequencyRank": 1961
  },
  {
    "Character": "尝",
    "Pinyin": "cháng",
    "Definition": "taste; experience, experiment",
    "Radical": "小",
    "RadicalIndex": "42.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1476,
    "FrequencyRank": 1621
  },
  {
    "Character": "烂",
    "Pinyin": "làn",
    "Definition": "rotten, spoiled, decayed",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1660,
    "FrequencyRank": 1754
  },
  {
    "Character": "罚",
    "Pinyin": "fá",
    "Definition": "penalty, fine; punish, penalize",
    "Radical": "网",
    "RadicalIndex": "122.4",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1519,
    "FrequencyRank": 1215
  },
  {
    "Character": "柿",
    "Pinyin": "shì",
    "Definition": "persimmon",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1436,
    "FrequencyRank": 3355
  },
  {
    "Character": "玻",
    "Pinyin": "bō",
    "Definition": "glass",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1361,
    "FrequencyRank": 1769
  },
  {
    "Character": "咳",
    "Pinyin": "hāi",
    "Definition": "cough",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1513,
    "FrequencyRank": 2373
  },
  {
    "Character": "胡",
    "Pinyin": "hú",
    "Definition": "recklessly, foolishly; wildly",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1419,
    "FrequencyRank": 806
  },
  {
    "Character": "挤",
    "Pinyin": "jǐ",
    "Definition": "crowd, squeeze, push against",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1392,
    "FrequencyRank": 1661
  },
  {
    "Character": "绕",
    "Pinyin": "rào",
    "Definition": "entwine; wind around; surround",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1755,
    "FrequencyRank": 1485
  },
  {
    "Character": "毒",
    "Pinyin": "dú",
    "Definition": "poison, venom; poisonous",
    "Radical": "毋",
    "RadicalIndex": "80.4",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1362,
    "FrequencyRank": 947
  },
  {
    "Character": "洒",
    "Pinyin": "sǎ",
    "Definition": "sprinkle; scatter; pour; to wipe away; to shiver",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1665,
    "FrequencyRank": 2283
  },
  {
    "Character": "革",
    "Pinyin": "gé",
    "Definition": "leather, animal hides; rad. 177",
    "Radical": "革",
    "RadicalIndex": "177",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1403,
    "FrequencyRank": 521
  },
  {
    "Character": "钢",
    "Pinyin": "gāng",
    "Definition": "steel; hard, strong, tough",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1529,
    "FrequencyRank": 1609
  },
  {
    "Character": "荣",
    "Pinyin": "róng",
    "Definition": "glory, honor; flourish, prosper",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1415,
    "FrequencyRank": 993
  },
  {
    "Character": "贸",
    "Pinyin": "mào",
    "Definition": "trade, barter; mixed; rashly",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1607,
    "FrequencyRank": 1092
  },
  {
    "Character": "贺",
    "Pinyin": "hè",
    "Definition": "congratulate; send present",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1744,
    "FrequencyRank": 1690
  },
  {
    "Character": "幽",
    "Pinyin": "yōu",
    "Definition": "quiet, secluded, tranquil; dark",
    "Radical": "幺",
    "RadicalIndex": "52.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1524,
    "FrequencyRank": 1636
  },
  {
    "Character": "染",
    "Pinyin": "rǎn",
    "Definition": "dye; be contagious; infect",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1675,
    "FrequencyRank": 1141
  },
  {
    "Character": "拼",
    "Pinyin": "pīn",
    "Definition": "join together, link, incorporate",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1393,
    "FrequencyRank": 1820
  },
  {
    "Character": "饼",
    "Pinyin": "bǐng",
    "Definition": "rice-cakes, biscuits",
    "Radical": "食",
    "RadicalIndex": "184.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1614,
    "FrequencyRank": 2359
  },
  {
    "Character": "胆",
    "Pinyin": "dǎn",
    "Definition": "gall bladder; bravery, courage",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1593,
    "FrequencyRank": 1352
  },
  {
    "Character": "览",
    "Pinyin": "lǎn",
    "Definition": "look at, inspect; perceive",
    "Radical": "見",
    "RadicalIndex": "147.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1472,
    "FrequencyRank": 1886
  },
  {
    "Character": "盆",
    "Pinyin": "pén",
    "Definition": "basin, tub, pot, bowl",
    "Radical": "皿",
    "RadicalIndex": "108.4",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1590,
    "FrequencyRank": 2035
  },
  {
    "Character": "咬",
    "Pinyin": "yǎo",
    "Definition": "bite, gnaw",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1512,
    "FrequencyRank": 1658
  },
  {
    "Character": "挡",
    "Pinyin": "dǎng",
    "Definition": "obstruct, impede; stop; resist",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1379,
    "FrequencyRank": 1933
  },
  {
    "Character": "骂",
    "Pinyin": "mà",
    "Definition": "accuse, blame, curse, scold",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1505,
    "FrequencyRank": 1449
  },
  {
    "Character": "贷",
    "Pinyin": "dài",
    "Definition": "lend; borrow; pardon",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1558,
    "FrequencyRank": 1605
  },
  {
    "Character": "怨",
    "Pinyin": "yuàn",
    "Definition": "hatred, enmity, resentment",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1608,
    "FrequencyRank": 1637
  },
  {
    "Character": "冠",
    "Pinyin": "guān",
    "Definition": "cap, crown, headgear",
    "Radical": "冖",
    "RadicalIndex": "14.7",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1705,
    "FrequencyRank": 1713
  },
  {
    "Character": "挣",
    "Pinyin": "zhēng",
    "Definition": "strive, endeavor, struggle",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1391,
    "FrequencyRank": 1875
  },
  {
    "Character": "恢",
    "Pinyin": "huī",
    "Definition": "restore; big, great, immense, vast",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1686,
    "FrequencyRank": 1364
  },
  {
    "Character": "恨",
    "Pinyin": "hèn",
    "Definition": "hatred, dislike; resent, hate",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1692,
    "FrequencyRank": 1295
  },
  {
    "Character": "疯",
    "Pinyin": "fēng",
    "Definition": "crazy, insane, mentally ill",
    "Radical": "疒",
    "RadicalIndex": "104.4",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1626,
    "FrequencyRank": 1549
  },
  {
    "Character": "逃",
    "Pinyin": "táo",
    "Definition": "escape, flee; abscond, dodge",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1588,
    "FrequencyRank": 996
  },
  {
    "Character": "耐",
    "Pinyin": "nài",
    "Definition": "endure, bear; resist; patient",
    "Radical": "而",
    "RadicalIndex": "126.3",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1456,
    "FrequencyRank": 1409
  },
  {
    "Character": "盾",
    "Pinyin": "dùn",
    "Definition": "shield, shield-shaped object; currency sign for Dutch guilder",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1579,
    "FrequencyRank": 1395
  },
  {
    "Character": "拜",
    "Pinyin": "bài",
    "Definition": "do obeisance, bow, kowtow",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 9,
    "HSK": 5,
    "GeneralStandard": 1538,
    "FrequencyRank": 1218
  },
  {
    "Character": "脆",
    "Pinyin": "cuì",
    "Definition": "crisp; fragile, frail; brittle",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1994,
    "FrequencyRank": 1976
  },
  {
    "Character": "胶",
    "Pinyin": "jiāo",
    "Definition": "glue, gum, resin, rubber; sound; shin bone",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2000,
    "FrequencyRank": 2170
  },
  {
    "Character": "珠",
    "Pinyin": "zhū",
    "Definition": "precious stone, gem, jewel, pearl",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1773,
    "FrequencyRank": 1286
  },
  {
    "Character": "谊",
    "Pinyin": "yì",
    "Definition": "friendship; appropriate, suitable",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2128,
    "FrequencyRank": 2136
  },
  {
    "Character": "润",
    "Pinyin": "rùn",
    "Definition": "soft, moist; sleek; freshen",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2081,
    "FrequencyRank": 1369
  },
  {
    "Character": "悄",
    "Pinyin": "qiāo",
    "Definition": "silent, quiet, still; anxious",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2092,
    "FrequencyRank": 1434
  },
  {
    "Character": "核",
    "Pinyin": "hé",
    "Definition": "seed, kernel, core, nut; atom",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1848,
    "FrequencyRank": 828
  },
  {
    "Character": "乘",
    "Pinyin": "chéng",
    "Definition": "ride, ascend; avail oneself of; numerary adjunct for vehicles",
    "Radical": "丿",
    "RadicalIndex": "4.9",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1941,
    "FrequencyRank": 1231
  },
  {
    "Character": "宾",
    "Pinyin": "bīn",
    "Definition": "guest, visitor; surname; submit",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2102,
    "FrequencyRank": 1534
  },
  {
    "Character": "损",
    "Pinyin": "sǔn",
    "Definition": "diminish; impair; injure",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1798,
    "FrequencyRank": 911
  },
  {
    "Character": "桔",
    "Pinyin": "jú",
    "Definition": "Chinese bellflower; well-swept; the inner fibers of corn-stalks",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1836,
    "FrequencyRank": 3304
  },
  {
    "Character": "鸭",
    "Pinyin": "yā",
    "Definition": "duck; Anas species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1892,
    "FrequencyRank": 2133
  },
  {
    "Character": "竞",
    "Pinyin": "jìng",
    "Definition": "contend, vie, compete",
    "Radical": "立",
    "RadicalIndex": "117.5",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2043,
    "FrequencyRank": 1100
  },
  {
    "Character": "悔",
    "Pinyin": "huǐ",
    "Definition": "repent, show remorse, regret",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2094,
    "FrequencyRank": 1853
  },
  {
    "Character": "艳",
    "Pinyin": "yàn",
    "Definition": "beautiful, sexy, voluptuous",
    "Radical": "色",
    "RadicalIndex": "139.4",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1770,
    "FrequencyRank": 2104
  },
  {
    "Character": "铃",
    "Pinyin": "líng",
    "Definition": "bell",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1933,
    "FrequencyRank": 1969
  },
  {
    "Character": "振",
    "Pinyin": "zhèn",
    "Definition": "raise, excite, arouse action",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1786,
    "FrequencyRank": 1140
  },
  {
    "Character": "柴",
    "Pinyin": "chái",
    "Definition": "firewood, faggots, fuel",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1880,
    "FrequencyRank": 1981
  },
  {
    "Character": "眠",
    "Pinyin": "mián",
    "Definition": "close eyes, sleep; hibernate",
    "Radical": "目",
    "RadicalIndex": "109.5",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1888,
    "FrequencyRank": 1986
  },
  {
    "Character": "涨",
    "Pinyin": "zhǎng",
    "Definition": "rise in price",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2086,
    "FrequencyRank": 1803
  },
  {
    "Character": "射",
    "Pinyin": "shè",
    "Definition": "shoot, eject, issue forth, emit",
    "Radical": "寸",
    "RadicalIndex": "41.7",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1971,
    "FrequencyRank": 703
  },
  {
    "Character": "索",
    "Pinyin": "suǒ",
    "Definition": "large rope, cable; rules, laws; to demand, to exact; to search, inquire; isolated",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1851,
    "FrequencyRank": 805
  },
  {
    "Character": "扇",
    "Pinyin": "shàn",
    "Definition": "fan; door panel",
    "Radical": "戶",
    "RadicalIndex": "63.6",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2113,
    "FrequencyRank": 1993
  },
  {
    "Character": "臭",
    "Pinyin": "chòu",
    "Definition": "smell, stink, emit foul odor",
    "Radical": "自",
    "RadicalIndex": "132.4",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1970,
    "FrequencyRank": 2105
  },
  {
    "Character": "朗",
    "Pinyin": "lǎng",
    "Definition": "clear, bright; distinct",
    "Radical": "月",
    "RadicalIndex": "74.7",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2109,
    "FrequencyRank": 1276
  },
  {
    "Character": "恋",
    "Pinyin": "liàn",
    "Definition": "love; long for, yearn for; love",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2016,
    "FrequencyRank": 1464
  },
  {
    "Character": "倡",
    "Pinyin": "chàng",
    "Definition": "guide, leader; lead, introduce",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1963,
    "FrequencyRank": 2185
  },
  {
    "Character": "桃",
    "Pinyin": "táo",
    "Definition": "peach; marriage; surname",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1844,
    "FrequencyRank": 1839
  },
  {
    "Character": "俱",
    "Pinyin": "jù",
    "Definition": "all, together; accompany",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 1962,
    "FrequencyRank": 1909
  },
  {
    "Character": "烤",
    "Pinyin": "kǎo",
    "Definition": "bake, roast, toast, cook",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2057,
    "FrequencyRank": 2629
  },
  {
    "Character": "陪",
    "Pinyin": "péi",
    "Definition": "accompany, be with, keep company",
    "Radical": "阜",
    "RadicalIndex": "170.8",
    "StrokeCount": 10,
    "HSK": 5,
    "GeneralStandard": 2139,
    "FrequencyRank": 1664
  },
  {
    "Character": "偶",
    "Pinyin": "ǒu",
    "Definition": "accidentally, coincidently; an idol",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2328,
    "FrequencyRank": 1361
  },
  {
    "Character": "硕",
    "Pinyin": "shuò",
    "Definition": "great, eminent; large, big",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2241,
    "FrequencyRank": 2304
  },
  {
    "Character": "弹",
    "Pinyin": "dàn",
    "Definition": "pellet, bullet, shot, shell",
    "Radical": "弓",
    "RadicalIndex": "57.8",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2473,
    "FrequencyRank": 632
  },
  {
    "Character": "敏",
    "Pinyin": "mǐn",
    "Definition": "fast, quick, clever, smart",
    "Radical": "攴",
    "RadicalIndex": "66.7",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2323,
    "FrequencyRank": 1436
  },
  {
    "Character": "剪",
    "Pinyin": "jiǎn",
    "Definition": "scissors; cut, divide, separate",
    "Radical": "刀",
    "RadicalIndex": "18.9",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2406,
    "FrequencyRank": 2227
  },
  {
    "Character": "蛇",
    "Pinyin": "shé",
    "Definition": "snake",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2280,
    "FrequencyRank": 1689
  },
  {
    "Character": "唯",
    "Pinyin": "wéi",
    "Definition": "only; yes",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2288,
    "FrequencyRank": 1094
  },
  {
    "Character": "辅",
    "Pinyin": "fǔ",
    "Definition": "cheek bone; protective; assist",
    "Radical": "車",
    "RadicalIndex": "159.7",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2250,
    "FrequencyRank": 2033
  },
  {
    "Character": "控",
    "Pinyin": "kòng",
    "Definition": "accuse, charge; control",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2187,
    "FrequencyRank": 780
  },
  {
    "Character": "域",
    "Pinyin": "yù",
    "Definition": "district, region, boundary; land",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2164,
    "FrequencyRank": 847
  },
  {
    "Character": "悉",
    "Pinyin": "xī",
    "Definition": "know, learn about, comprehend",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2351,
    "FrequencyRank": 1428
  },
  {
    "Character": "偿",
    "Pinyin": "cháng",
    "Definition": "repay, recompense; restitution",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2327,
    "FrequencyRank": 1329
  },
  {
    "Character": "偷",
    "Pinyin": "tōu",
    "Definition": "to steal, burglar, thief",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2330,
    "FrequencyRank": 1284
  },
  {
    "Character": "宿",
    "Pinyin": "sù",
    "Definition": "stop, rest, lodge, stay overnight; constellation",
    "Radical": "宀",
    "RadicalIndex": "40.8",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2455,
    "FrequencyRank": 1752
  },
  {
    "Character": "逻",
    "Pinyin": "luó",
    "Definition": "patrol; inspect; watch; patrol",
    "Radical": "辵",
    "RadicalIndex": "162.8",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2295,
    "FrequencyRank": 1591
  },
  {
    "Character": "盒",
    "Pinyin": "hé",
    "Definition": "small box or case; casket",
    "Radical": "皿",
    "RadicalIndex": "108.6",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2348,
    "FrequencyRank": 2067
  },
  {
    "Character": "斜",
    "Pinyin": "xié",
    "Definition": "slanting, sloping, inclined",
    "Radical": "斗",
    "RadicalIndex": "68.7",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2347,
    "FrequencyRank": 1786
  },
  {
    "Character": "梨",
    "Pinyin": "lí",
    "Definition": "pear; opera; cut, slash",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2313,
    "FrequencyRank": 3004
  },
  {
    "Character": "猜",
    "Pinyin": "cāi",
    "Definition": "guess, conjecture, suppose; feel",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2364,
    "FrequencyRank": 1598
  },
  {
    "Character": "虚",
    "Pinyin": "xū",
    "Definition": "FALSE",
    "Radical": "虍",
    "RadicalIndex": "141.5",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2253,
    "FrequencyRank": 1071
  },
  {
    "Character": "萄",
    "Pinyin": "táo",
    "Definition": "grapes",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2208,
    "FrequencyRank": 2210
  },
  {
    "Character": "堆",
    "Pinyin": "duī",
    "Definition": "heap, pile, crowd; pile up",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2173,
    "FrequencyRank": 1370
  },
  {
    "Character": "惜",
    "Pinyin": "xī",
    "Definition": "pity, regret, rue, begrudge",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 5,
    "GeneralStandard": 2439,
    "FrequencyRank": 1512
  },
  {
    "Character": "慌",
    "Pinyin": "huāng",
    "Definition": "nervous, panicky, frantic",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2771,
    "FrequencyRank": 1650
  },
  {
    "Character": "厨",
    "Pinyin": "chú",
    "Definition": "kitchen; closet; cupboard",
    "Radical": "厂",
    "RadicalIndex": "27.1",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2587,
    "FrequencyRank": 2132
  },
  {
    "Character": "辈",
    "Pinyin": "bèi",
    "Definition": "generation, lifetime; class",
    "Radical": "非",
    "RadicalIndex": "175.4",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2602,
    "FrequencyRank": 1477
  },
  {
    "Character": "滑",
    "Pinyin": "huá",
    "Definition": "slip, slide; slippery, polished",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2761,
    "FrequencyRank": 1480
  },
  {
    "Character": "骗",
    "Pinyin": "piàn",
    "Definition": "swindle, cheat out of, defraud",
    "Radical": "馬",
    "RadicalIndex": "187.9",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2821,
    "FrequencyRank": 1503
  },
  {
    "Character": "掌",
    "Pinyin": "zhǎng",
    "Definition": "palm of hand, sole of foot, paw",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2610,
    "FrequencyRank": 890
  },
  {
    "Character": "傅",
    "Pinyin": "fù",
    "Definition": "tutor, teacher; assist; surname",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2691,
    "FrequencyRank": 1887
  },
  {
    "Character": "脾",
    "Pinyin": "pí",
    "Definition": "spleen, pancreas; disposition",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2713,
    "FrequencyRank": 2223
  },
  {
    "Character": "赔",
    "Pinyin": "péi",
    "Definition": "indemnify, suffer loss",
    "Radical": "貝",
    "RadicalIndex": "154.8",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2654,
    "FrequencyRank": 1734
  },
  {
    "Character": "裁",
    "Pinyin": "cái",
    "Definition": "cut out; decrease",
    "Radical": "衣",
    "RadicalIndex": "145.6",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2532,
    "FrequencyRank": 1203
  },
  {
    "Character": "葡",
    "Pinyin": "pú",
    "Definition": "grapes",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2552,
    "FrequencyRank": 2130
  },
  {
    "Character": "悲",
    "Pinyin": "bēi",
    "Definition": "sorrow, grief; sorry, sad",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2603,
    "FrequencyRank": 1166
  },
  {
    "Character": "棒",
    "Pinyin": "bàng",
    "Definition": "stick, club, truncheon; hit",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2562,
    "FrequencyRank": 2288
  },
  {
    "Character": "硬",
    "Pinyin": "yìng",
    "Definition": "hard, firm, strong; obstinate",
    "Radical": "石",
    "RadicalIndex": "112.7",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2589,
    "FrequencyRank": 1170
  },
  {
    "Character": "尊",
    "Pinyin": "zūn",
    "Definition": "respect, revere, venerate; honor",
    "Radical": "寸",
    "RadicalIndex": "41.9",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2743,
    "FrequencyRank": 1134
  },
  {
    "Character": "暂",
    "Pinyin": "zàn",
    "Definition": "temporary",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2599,
    "FrequencyRank": 1325
  },
  {
    "Character": "紫",
    "Pinyin": "zǐ",
    "Definition": "purple, violet; amethyst; surname",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2604,
    "FrequencyRank": 1646
  },
  {
    "Character": "插",
    "Pinyin": "chā",
    "Definition": "insert, stick into; plant",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2526,
    "FrequencyRank": 1495
  },
  {
    "Character": "喷",
    "Pinyin": "pēn",
    "Definition": "spurt, blow out, puff out",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2618,
    "FrequencyRank": 1729
  },
  {
    "Character": "博",
    "Pinyin": "bó",
    "Definition": "gamble, play games; wide, broad",
    "Radical": "十",
    "RadicalIndex": "24.1",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2521,
    "FrequencyRank": 965
  },
  {
    "Character": "幅",
    "Pinyin": "fú",
    "Definition": "piece, strip, breadth of, hem",
    "Radical": "巾",
    "RadicalIndex": "50.9",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2648,
    "FrequencyRank": 1444
  },
  {
    "Character": "锅",
    "Pinyin": "guō",
    "Definition": "cooking-pot, saucepan",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2662,
    "FrequencyRank": 2074
  },
  {
    "Character": "敬",
    "Pinyin": "jìng",
    "Definition": "respect, honor; respectfully",
    "Radical": "攴",
    "RadicalIndex": "66.9",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2553,
    "FrequencyRank": 1209
  },
  {
    "Character": "稍",
    "Pinyin": "shāo",
    "Definition": "little, slightly; rather",
    "Radical": "禾",
    "RadicalIndex": "115.7",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2676,
    "FrequencyRank": 1373
  },
  {
    "Character": "搜",
    "Pinyin": "sōu",
    "Definition": "search, seek; investigate",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2528,
    "FrequencyRank": 1564
  },
  {
    "Character": "琴",
    "Pinyin": "qín",
    "Definition": "Chinese lute or guitar",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2501,
    "FrequencyRank": 1701
  },
  {
    "Character": "锁",
    "Pinyin": "suǒ",
    "Definition": "lock, padlock; shackles, chains",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2660,
    "FrequencyRank": 1558
  },
  {
    "Character": "剩",
    "Pinyin": "shèng",
    "Definition": "leftovers, residue, remains",
    "Radical": "刀",
    "RadicalIndex": "18.1",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2675,
    "FrequencyRank": 1446
  },
  {
    "Character": "惠",
    "Pinyin": "huì",
    "Definition": "favor, benefit, confer kindness",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2580,
    "FrequencyRank": 1524
  },
  {
    "Character": "筑",
    "Pinyin": "zhù",
    "Definition": "ancient lute; build",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2682,
    "FrequencyRank": 1130
  },
  {
    "Character": "雄",
    "Pinyin": "xióng",
    "Definition": "male of species; hero; manly",
    "Radical": "隹",
    "RadicalIndex": "172.4",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2596,
    "FrequencyRank": 1054
  },
  {
    "Character": "款",
    "Pinyin": "kuǎn",
    "Definition": "item, article; clause; fund",
    "Radical": "欠",
    "RadicalIndex": "76.8",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2508,
    "FrequencyRank": 807
  },
  {
    "Character": "猴",
    "Pinyin": "hóu",
    "Definition": "monkey, ape; monkey-like",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 12,
    "HSK": 5,
    "GeneralStandard": 2721,
    "FrequencyRank": 2187
  },
  {
    "Character": "鼓",
    "Pinyin": "gǔ",
    "Definition": "drum; beat, top, strike",
    "Radical": "鼓",
    "RadicalIndex": "207",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2837,
    "FrequencyRank": 1123
  },
  {
    "Character": "献",
    "Pinyin": "xiàn",
    "Definition": "offer, present; show, display",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2861,
    "FrequencyRank": 1192
  },
  {
    "Character": "歇",
    "Pinyin": "xiē",
    "Definition": "rest, stop, lodge",
    "Radical": "欠",
    "RadicalIndex": "76.9",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2908,
    "FrequencyRank": 1828
  },
  {
    "Character": "煤",
    "Pinyin": "méi",
    "Definition": "coal, coke, charcoal, carbon",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 3003,
    "FrequencyRank": 1740
  },
  {
    "Character": "躲",
    "Pinyin": "duǒ",
    "Definition": "hide, secrete; avoid, escape",
    "Radical": "身",
    "RadicalIndex": "158.6",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2962,
    "FrequencyRank": 1644
  },
  {
    "Character": "鼠",
    "Pinyin": "shǔ",
    "Definition": "rat, mouse; KangXi radical 208",
    "Radical": "鼠",
    "RadicalIndex": "208",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2958,
    "FrequencyRank": 1693
  },
  {
    "Character": "频",
    "Pinyin": "pín",
    "Definition": "frequently, again and again",
    "Radical": "頁",
    "RadicalIndex": "181.7",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2892,
    "FrequencyRank": 1535
  },
  {
    "Character": "嗯",
    "Pinyin": "ń",
    "Definition": "final particle",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 5467,
    "FrequencyRank": 2042
  },
  {
    "Character": "漠",
    "Pinyin": "mò",
    "Definition": "desert; aloof, indifferent, cool",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 3006,
    "FrequencyRank": 1777
  },
  {
    "Character": "签",
    "Pinyin": "qiān",
    "Definition": "sign, endorse; slip of paper",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2953,
    "FrequencyRank": 1254
  },
  {
    "Character": "键",
    "Pinyin": "jiàn",
    "Definition": "door bolt, lock bolt; key",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2943,
    "FrequencyRank": 1471
  },
  {
    "Character": "幕",
    "Pinyin": "mù",
    "Definition": "curtain, screen, tent",
    "Radical": "巾",
    "RadicalIndex": "50.11",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2854,
    "FrequencyRank": 1315
  },
  {
    "Character": "辞",
    "Pinyin": "cí",
    "Definition": "words, speech, expression, phrase",
    "Radical": "舌",
    "RadicalIndex": "135.7",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2947,
    "FrequencyRank": 1469
  },
  {
    "Character": "愁",
    "Pinyin": "chóu",
    "Definition": "anxiety; to worry about, be anxious",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2951,
    "FrequencyRank": 2113
  },
  {
    "Character": "碍",
    "Pinyin": "ài",
    "Definition": "obstruct, hinder, block, deter",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2876,
    "FrequencyRank": 1437
  },
  {
    "Character": "摄",
    "Pinyin": "shè",
    "Definition": "take in, absorb; act as deputy",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2832,
    "FrequencyRank": 1403
  },
  {
    "Character": "勤",
    "Pinyin": "qín",
    "Definition": "industrious, diligent, attentive",
    "Radical": "力",
    "RadicalIndex": "19.11",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2848,
    "FrequencyRank": 1565
  },
  {
    "Character": "辑",
    "Pinyin": "jí",
    "Definition": "gather up, collect; edit, compile",
    "Radical": "車",
    "RadicalIndex": "159.9",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2889,
    "FrequencyRank": 1362
  },
  {
    "Character": "龄",
    "Pinyin": "líng",
    "Definition": "age; years",
    "Radical": "齒",
    "RadicalIndex": "211.5",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2893,
    "FrequencyRank": 1510
  },
  {
    "Character": "滚",
    "Pinyin": "gǔn",
    "Definition": "turn",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 3015,
    "FrequencyRank": 1542
  },
  {
    "Character": "触",
    "Pinyin": "chù",
    "Definition": "butt, ram, gore; touch",
    "Radical": "角",
    "RadicalIndex": "148.6",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2980,
    "FrequencyRank": 1207
  },
  {
    "Character": "碎",
    "Pinyin": "suì",
    "Definition": "break, smash; broken, busted",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2879,
    "FrequencyRank": 1420
  },
  {
    "Character": "傻",
    "Pinyin": "shǎ",
    "Definition": "foolish, silly, stupid; an imbecile",
    "Radical": "人",
    "RadicalIndex": "9.11",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2960,
    "FrequencyRank": 1989
  },
  {
    "Character": "搞",
    "Pinyin": "gǎo",
    "Definition": "to clear, clarify",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": 5,
    "GeneralStandard": 2842,
    "FrequencyRank": 1146
  },
  {
    "Character": "豪",
    "Pinyin": "háo",
    "Definition": "brave, heroic, chivalrous",
    "Radical": "豕",
    "RadicalIndex": "152.7",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3141,
    "FrequencyRank": 1513
  },
  {
    "Character": "瘦",
    "Pinyin": "shòu",
    "Definition": "thin, emaciated, lean; meager",
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3147,
    "FrequencyRank": 1841
  },
  {
    "Character": "璃",
    "Pinyin": "lí",
    "Definition": "glass",
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3051,
    "FrequencyRank": 1894
  },
  {
    "Character": "鼻",
    "Pinyin": "bí",
    "Definition": "nose; first; KangXi radical 209",
    "Radical": "鼻",
    "RadicalIndex": "209",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3128,
    "FrequencyRank": 1335
  },
  {
    "Character": "敲",
    "Pinyin": "qiāo",
    "Definition": "strike, beat, pound, hammer; rap",
    "Radical": "攴",
    "RadicalIndex": "66.1",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3140,
    "FrequencyRank": 1732
  },
  {
    "Character": "漏",
    "Pinyin": "lòu",
    "Definition": "leak, drip; funnel; hour glass",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3168,
    "FrequencyRank": 2052
  },
  {
    "Character": "貌",
    "Pinyin": "mào",
    "Definition": "countenance, appearance",
    "Radical": "豸",
    "RadicalIndex": "153.7",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3131,
    "FrequencyRank": 1595
  },
  {
    "Character": "熊",
    "Pinyin": "xióng",
    "Definition": "a bear; brilliant; bright; surname",
    "Radical": "火",
    "RadicalIndex": "86.1",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3185,
    "FrequencyRank": 1741
  },
  {
    "Character": "摘",
    "Pinyin": "zhāi",
    "Definition": "pluck, pick; select; specify",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3062,
    "FrequencyRank": 2026
  },
  {
    "Character": "漫",
    "Pinyin": "màn",
    "Definition": "overflow of water; spreading",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3164,
    "FrequencyRank": 1455
  },
  {
    "Character": "颗",
    "Pinyin": "kē",
    "Definition": "grain, kernel",
    "Radical": "頁",
    "RadicalIndex": "181.8",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3100,
    "FrequencyRank": 1448
  },
  {
    "Character": "摔",
    "Pinyin": "shuāi",
    "Definition": "fall ground, stumble, trip",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": 5,
    "GeneralStandard": 3063,
    "FrequencyRank": 2270
  },
  {
    "Character": "蝶",
    "Pinyin": "dié",
    "Definition": "butterfly",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3245,
    "FrequencyRank": 2306
  },
  {
    "Character": "遵",
    "Pinyin": "zūn",
    "Definition": "obey, comply with, follow; honor",
    "Radical": "辵",
    "RadicalIndex": "162.12",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3288,
    "FrequencyRank": 1593
  },
  {
    "Character": "慰",
    "Pinyin": "wèi",
    "Definition": "comfort, console, calm",
    "Radical": "心",
    "RadicalIndex": "61.11",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3310,
    "FrequencyRank": 1632
  },
  {
    "Character": "糊",
    "Pinyin": "hú",
    "Definition": "paste, stick on with paste",
    "Radical": "米",
    "RadicalIndex": "119.9",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3287,
    "FrequencyRank": 1393
  },
  {
    "Character": "蔬",
    "Pinyin": "shū",
    "Definition": "vegetables, greens",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3211,
    "FrequencyRank": 2721
  },
  {
    "Character": "豫",
    "Pinyin": "yù",
    "Definition": "relaxed, comfortable, at ease",
    "Radical": "豕",
    "RadicalIndex": "152.9",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3313,
    "FrequencyRank": 1956
  },
  {
    "Character": "醉",
    "Pinyin": "zuì",
    "Definition": "intoxicated, drunk; addicted to",
    "Radical": "酉",
    "RadicalIndex": "164.8",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3224,
    "FrequencyRank": 1783
  },
  {
    "Character": "蝴",
    "Pinyin": "hú",
    "Definition": "butterfly",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3246,
    "FrequencyRank": 2964
  },
  {
    "Character": "摩",
    "Pinyin": "mó",
    "Definition": "rub, scour, grind; friction",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3279,
    "FrequencyRank": 1162
  },
  {
    "Character": "聪",
    "Pinyin": "cōng",
    "Definition": "intelligent, clever, bright",
    "Radical": "耳",
    "RadicalIndex": "128.9",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3206,
    "FrequencyRank": 1669
  },
  {
    "Character": "震",
    "Pinyin": "zhèn",
    "Definition": "shake, quake, tremor; excite",
    "Radical": "雨",
    "RadicalIndex": "173.7",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3229,
    "FrequencyRank": 1104
  },
  {
    "Character": "撞",
    "Pinyin": "zhuàng",
    "Definition": "knock against, bump into, collide",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3202,
    "FrequencyRank": 1540
  },
  {
    "Character": "德",
    "Pinyin": "dé",
    "Definition": "ethics, morality, virtue",
    "Radical": "彳",
    "RadicalIndex": "60.12",
    "StrokeCount": 15,
    "HSK": 5,
    "GeneralStandard": 3272,
    "FrequencyRank": 256
  },
  {
    "Character": "赠",
    "Pinyin": "zèng",
    "Definition": "give present; bestow, confer",
    "Radical": "貝",
    "RadicalIndex": "154.12",
    "StrokeCount": 16,
    "HSK": 5,
    "GeneralStandard": 3351,
    "FrequencyRank": 2123
  },
  {
    "Character": "邀",
    "Pinyin": "yāo",
    "Definition": "invite, welcome; meet, intercept",
    "Radical": "辵",
    "RadicalIndex": "162.13",
    "StrokeCount": 16,
    "HSK": 5,
    "GeneralStandard": 3362,
    "FrequencyRank": 1854
  },
  {
    "Character": "糕",
    "Pinyin": "gāo",
    "Definition": "cakes, pastry",
    "Radical": "米",
    "RadicalIndex": "119.1",
    "StrokeCount": 16,
    "HSK": 5,
    "GeneralStandard": 3375,
    "FrequencyRank": 2413
  },
  {
    "Character": "壁",
    "Pinyin": "bì",
    "Definition": "partition wall; walls of a house",
    "Radical": "土",
    "RadicalIndex": "32.13",
    "StrokeCount": 16,
    "HSK": 5,
    "GeneralStandard": 3384,
    "FrequencyRank": 1380
  },
  {
    "Character": "瞧",
    "Pinyin": "qiáo",
    "Definition": "glance at, look at, see",
    "Radical": "目",
    "RadicalIndex": "109.12",
    "StrokeCount": 17,
    "HSK": 5,
    "GeneralStandard": 3402,
    "FrequencyRank": 1551
  },
  {
    "Character": "糟",
    "Pinyin": "zāo",
    "Definition": "sediment, dregs; pickle",
    "Radical": "米",
    "RadicalIndex": "119.11",
    "StrokeCount": 17,
    "HSK": 5,
    "GeneralStandard": 3428,
    "FrequencyRank": 1918
  },
  {
    "Character": "繁",
    "Pinyin": "fán",
    "Definition": "complicated, complex, difficult",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 17,
    "HSK": 5,
    "GeneralStandard": 3419,
    "FrequencyRank": 1296
  },
  {
    "Character": "嚏",
    "Pinyin": "tì",
    "Definition": "sneeze",
    "Radical": "口",
    "RadicalIndex": "30.14",
    "StrokeCount": 17,
    "HSK": 5,
    "GeneralStandard": 6225,
    "FrequencyRank": 3897
  },
  {
    "Character": "籍",
    "Pinyin": "jí",
    "Definition": "record, register, list; census",
    "Radical": "竹",
    "RadicalIndex": "118.14",
    "StrokeCount": 20,
    "HSK": 5,
    "GeneralStandard": 3482,
    "FrequencyRank": 1579
  },
  {
    "Character": "勺",
    "Pinyin": "sháo",
    "Definition": "spoon, ladle; unit of volume",
    "Radical": "勹",
    "RadicalIndex": "20.1",
    "StrokeCount": 3,
    "HSK": 6,
    "GeneralStandard": 49,
    "FrequencyRank": 3275
  },
  {
    "Character": "亡",
    "Pinyin": "wáng",
    "Definition": "death, destroyed; lose, perish",
    "Radical": "亠",
    "RadicalIndex": "8.1",
    "StrokeCount": 3,
    "HSK": 6,
    "GeneralStandard": 54,
    "FrequencyRank": 957
  },
  {
    "Character": "凡",
    "Pinyin": "fán",
    "Definition": "all, any, every; ordinary, common",
    "Radical": "几",
    "RadicalIndex": "16.1",
    "StrokeCount": 3,
    "HSK": 6,
    "GeneralStandard": 50,
    "FrequencyRank": 1013
  },
  {
    "Character": "乌",
    "Pinyin": "wū",
    "Definition": "crow, rook, raven; black, dark",
    "Radical": "丿",
    "RadicalIndex": "4.3",
    "StrokeCount": 4,
    "HSK": 6,
    "GeneralStandard": 157,
    "FrequencyRank": 1244
  },
  {
    "Character": "井",
    "Pinyin": "jǐng",
    "Definition": "well, mine shaft, pit",
    "Radical": "二",
    "RadicalIndex": "7.2",
    "StrokeCount": 4,
    "HSK": 6,
    "GeneralStandard": 77,
    "FrequencyRank": 1431
  },
  {
    "Character": "凶",
    "Pinyin": "xiōng",
    "Definition": "culprit; murder; bad, sad",
    "Radical": "凵",
    "RadicalIndex": "17.2",
    "StrokeCount": 4,
    "HSK": 6,
    "GeneralStandard": 145,
    "FrequencyRank": 1378
  },
  {
    "Character": "允",
    "Pinyin": "yǔn",
    "Definition": "to grant, to allow, to consent",
    "Radical": "儿",
    "RadicalIndex": "10.2",
    "StrokeCount": 4,
    "HSK": 6,
    "GeneralStandard": 183,
    "FrequencyRank": 1443
  },
  {
    "Character": "幻",
    "Pinyin": "huàn",
    "Definition": "illusion, fantasy, mirage",
    "Radical": "幺",
    "RadicalIndex": "52.1",
    "StrokeCount": 4,
    "HSK": 6,
    "GeneralStandard": 189,
    "FrequencyRank": 1417
  },
  {
    "Character": "予",
    "Pinyin": "yǔ",
    "Definition": "I, me; to give",
    "Radical": "亅",
    "RadicalIndex": "6.3",
    "StrokeCount": 4,
    "HSK": 6,
    "GeneralStandard": 184,
    "FrequencyRank": 925
  },
  {
    "Character": "扎",
    "Pinyin": "zhā",
    "Definition": "pull up; pierce; struggle free",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 4,
    "HSK": 6,
    "GeneralStandard": 85,
    "FrequencyRank": 1411
  },
  {
    "Character": "仓",
    "Pinyin": "cāng",
    "Definition": "granary; berth; sea",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": 6,
    "GeneralStandard": 149,
    "FrequencyRank": 1882
  },
  {
    "Character": "田",
    "Pinyin": "tián",
    "Definition": "field, arable land, cultivated",
    "Radical": "田",
    "RadicalIndex": "102",
    "StrokeCount": 5,
    "HSK": 6,
    "GeneralStandard": 244,
    "FrequencyRank": 778
  },
  {
    "Character": "刊",
    "Pinyin": "kān",
    "Definition": "publication, periodical; publish",
    "Radical": "刀",
    "RadicalIndex": "18.3",
    "StrokeCount": 5,
    "HSK": 6,
    "GeneralStandard": 191,
    "FrequencyRank": 1241
  },
  {
    "Character": "扑",
    "Pinyin": "pū",
    "Definition": "pound, beat, strike; attack",
    "Radical": "手",
    "RadicalIndex": "64.2",
    "StrokeCount": 5,
    "HSK": 6,
    "GeneralStandard": 199,
    "FrequencyRank": 1509
  },
  {
    "Character": "仪",
    "Pinyin": "yí",
    "Definition": "ceremony, rites gifts; admire",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": 6,
    "GeneralStandard": 274,
    "FrequencyRank": 1283
  },
  {
    "Character": "纠",
    "Pinyin": "jiū",
    "Definition": "investigate, inspect",
    "Radical": "糸",
    "RadicalIndex": "120.2",
    "StrokeCount": 5,
    "HSK": 6,
    "GeneralStandard": 344,
    "FrequencyRank": 1723
  },
  {
    "Character": "灭",
    "Pinyin": "miè",
    "Definition": "extinguish; wipe out, exterminate",
    "Radical": "火",
    "RadicalIndex": "86.1",
    "StrokeCount": 5,
    "HSK": 6,
    "GeneralStandard": 223,
    "FrequencyRank": 953
  },
  {
    "Character": "犯",
    "Pinyin": "fàn",
    "Definition": "commit crime, violate; criminal",
    "Radical": "犬",
    "RadicalIndex": "94.2",
    "StrokeCount": 5,
    "HSK": 6,
    "GeneralStandard": 292,
    "FrequencyRank": 767
  },
  {
    "Character": "圣",
    "Pinyin": "shèng",
    "Definition": "holy, sacred",
    "Radical": "土",
    "RadicalIndex": "32.2",
    "StrokeCount": 5,
    "HSK": 6,
    "GeneralStandard": 340,
    "FrequencyRank": 960
  },
  {
    "Character": "叹",
    "Pinyin": "tàn",
    "Definition": "sigh, admire",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 6,
    "GeneralStandard": 257,
    "FrequencyRank": 1299
  },
  {
    "Character": "讯",
    "Pinyin": "xùn",
    "Definition": "inquire; ask; examine; reproach",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": 6,
    "GeneralStandard": 322,
    "FrequencyRank": 1238
  },
  {
    "Character": "吊",
    "Pinyin": "diào",
    "Definition": "condole, mourn, pity; hang",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 428,
    "FrequencyRank": 2050
  },
  {
    "Character": "宇",
    "Pinyin": "yǔ",
    "Definition": "house; building, structure; eaves",
    "Radical": "宀",
    "RadicalIndex": "40.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 545,
    "FrequencyRank": 1156
  },
  {
    "Character": "吉",
    "Pinyin": "jí",
    "Definition": "lucky, propitious, good",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 356,
    "FrequencyRank": 856
  },
  {
    "Character": "壮",
    "Pinyin": "zhuàng",
    "Definition": "big, large; robust; name of tribe",
    "Radical": "士",
    "RadicalIndex": "33.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 510,
    "FrequencyRank": 1432
  },
  {
    "Character": "尖",
    "Pinyin": "jiān",
    "Definition": "sharp, pointed, acute, keen",
    "Radical": "小",
    "RadicalIndex": "42.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 415,
    "FrequencyRank": 1321
  },
  {
    "Character": "庄",
    "Pinyin": "zhuāng",
    "Definition": "village, hamlet; villa, manor",
    "Radical": "广",
    "RadicalIndex": "53.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 514,
    "FrequencyRank": 1024
  },
  {
    "Character": "寺",
    "Pinyin": "sì",
    "Definition": "court, office; temple, monastery",
    "Radical": "寸",
    "RadicalIndex": "41.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 355,
    "FrequencyRank": 1892
  },
  {
    "Character": "舌",
    "Pinyin": "shé",
    "Definition": "tongue; clapper of bell; KangXi radical 135",
    "Radical": "舌",
    "RadicalIndex": "135",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 449,
    "FrequencyRank": 1958
  },
  {
    "Character": "轨",
    "Pinyin": "guǐ",
    "Definition": "track, rut, path",
    "Radical": "車",
    "RadicalIndex": "159.2",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 404,
    "FrequencyRank": 1883
  },
  {
    "Character": "宅",
    "Pinyin": "zhái",
    "Definition": "residence, dwelling, home; grave",
    "Radical": "宀",
    "RadicalIndex": "40.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 547,
    "FrequencyRank": 1858
  },
  {
    "Character": "伍",
    "Pinyin": "wǔ",
    "Definition": "five, company of five; troops",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 459,
    "FrequencyRank": 1472
  },
  {
    "Character": "迁",
    "Pinyin": "qiān",
    "Definition": "move, shift, change; transfer; relocate a capital city",
    "Radical": "辵",
    "RadicalIndex": "162.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 451,
    "FrequencyRank": 1630
  },
  {
    "Character": "异",
    "Pinyin": "yì",
    "Definition": "different, unusual, strange",
    "Radical": "廾",
    "RadicalIndex": "55.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 568,
    "FrequencyRank": 709
  },
  {
    "Character": "巩",
    "Pinyin": "gǒng",
    "Definition": "bind; firm, secure, strong",
    "Radical": "工",
    "RadicalIndex": "48.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 361,
    "FrequencyRank": 2384
  },
  {
    "Character": "扣",
    "Pinyin": "kòu",
    "Definition": "knock, strike, rap, tap; button",
    "Radical": "手",
    "RadicalIndex": "64.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 357,
    "FrequencyRank": 1625
  },
  {
    "Character": "夺",
    "Pinyin": "duó",
    "Definition": "take by force, rob, snatch",
    "Radical": "大",
    "RadicalIndex": "37.3",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 396,
    "FrequencyRank": 1199
  },
  {
    "Character": "仰",
    "Pinyin": "yǎng",
    "Definition": "raise the head to look; look up to, rely on, admire",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 6,
    "GeneralStandard": 473,
    "FrequencyRank": 1368
  },
  {
    "Character": "吝",
    "Pinyin": "lìn",
    "Definition": "stingy, miserly, parsimonious",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 835,
    "FrequencyRank": 3507
  },
  {
    "Character": "抗",
    "Pinyin": "kàng",
    "Definition": "resist, oppose, defy, reject",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 646,
    "FrequencyRank": 766
  },
  {
    "Character": "妙",
    "Pinyin": "miào",
    "Definition": "mysterious, subtle; exquisite",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 919,
    "FrequencyRank": 1250
  },
  {
    "Character": "贡",
    "Pinyin": "gòng",
    "Definition": "offer tribute; tribute, gifts",
    "Radical": "貝",
    "RadicalIndex": "154.3",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 627,
    "FrequencyRank": 1548
  },
  {
    "Character": "纳",
    "Pinyin": "nà",
    "Definition": "admit, take, receive, accept",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 934,
    "FrequencyRank": 684
  },
  {
    "Character": "庇",
    "Pinyin": "bì",
    "Definition": "cover, shield, shelter, protect",
    "Radical": "广",
    "RadicalIndex": "53.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 833,
    "FrequencyRank": 2793
  },
  {
    "Character": "灵",
    "Pinyin": "líng",
    "Definition": "spirit, soul; spiritual world",
    "Radical": "彐",
    "RadicalIndex": "58.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 900,
    "FrequencyRank": 734
  },
  {
    "Character": "攻",
    "Pinyin": "gōng",
    "Definition": "attack, assault; criticize",
    "Radical": "攴",
    "RadicalIndex": "66.3",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 630,
    "FrequencyRank": 588
  },
  {
    "Character": "饪",
    "Pinyin": "rèn",
    "Definition": "cooked food; cook until well done",
    "Radical": "食",
    "RadicalIndex": "184.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 3752,
    "FrequencyRank": 4491
  },
  {
    "Character": "佛",
    "Pinyin": "fú",
    "Definition": "Buddha; of Buddhism; merciful person; Buddhist image; the dead (Jap.)",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 789,
    "FrequencyRank": 771
  },
  {
    "Character": "麦",
    "Pinyin": "mài",
    "Definition": "wheat, barley, oats; simplified form of KangXi radical number 199",
    "Radical": "麥",
    "RadicalIndex": "199",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 601,
    "FrequencyRank": 1171
  },
  {
    "Character": "岛",
    "Pinyin": "dǎo",
    "Definition": "island",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 820,
    "FrequencyRank": 798
  },
  {
    "Character": "吞",
    "Pinyin": "tūn",
    "Definition": "swallow; absorb, annex, engulf",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 607,
    "FrequencyRank": 1797
  },
  {
    "Character": "忧",
    "Pinyin": "yōu",
    "Definition": "sad, grieved; grief, melancholy",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 874,
    "FrequencyRank": 1461
  },
  {
    "Character": "伶",
    "Pinyin": "líng",
    "Definition": "lonely, solitary; actor",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 779,
    "FrequencyRank": 2906
  },
  {
    "Character": "扭",
    "Pinyin": "niǔ",
    "Definition": "turn, twist, wrench; seize, grasp",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 653,
    "FrequencyRank": 1805
  },
  {
    "Character": "宏",
    "Pinyin": "hóng",
    "Definition": "wide, spacious, great, vast",
    "Radical": "宀",
    "RadicalIndex": "40.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 879,
    "FrequencyRank": 1798
  },
  {
    "Character": "屁",
    "Pinyin": "pì",
    "Definition": "break wind; fart; buttocks",
    "Radical": "尸",
    "RadicalIndex": "44.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 903,
    "FrequencyRank": 2295
  },
  {
    "Character": "杆",
    "Pinyin": "gān",
    "Definition": "pole; shaft of spear",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 678,
    "FrequencyRank": 2011
  },
  {
    "Character": "纵",
    "Pinyin": "zòng",
    "Definition": "indulge in, give free reign to",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 936,
    "FrequencyRank": 1236
  },
  {
    "Character": "吟",
    "Pinyin": "yín",
    "Definition": "sing, hum; recite; type of poetry",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 740,
    "FrequencyRank": 2015
  },
  {
    "Character": "肝",
    "Pinyin": "gān",
    "Definition": "liver",
    "Radical": "肉",
    "RadicalIndex": "130.3",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 803,
    "FrequencyRank": 1760
  },
  {
    "Character": "谷",
    "Pinyin": "gǔ",
    "Definition": "valley, gorge, ravine",
    "Radical": "谷",
    "RadicalIndex": "150",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 798,
    "FrequencyRank": 1095
  },
  {
    "Character": "串",
    "Pinyin": "chuàn",
    "Definition": "string; relatives; conspire",
    "Radical": "丨",
    "RadicalIndex": "2.6",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 736,
    "FrequencyRank": 1832
  },
  {
    "Character": "岗",
    "Pinyin": "gǎng",
    "Definition": "post; position",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 754,
    "FrequencyRank": 1795
  },
  {
    "Character": "牢",
    "Pinyin": "láo",
    "Definition": "prison; stable, pen; secure",
    "Radical": "牛",
    "RadicalIndex": "93.3",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 880,
    "FrequencyRank": 1696
  },
  {
    "Character": "苏",
    "Pinyin": "sū",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": 6,
    "GeneralStandard": 677,
    "FrequencyRank": 590
  },
  {
    "Character": "审",
    "Pinyin": "shěn",
    "Definition": "examine, investigate; judge",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1279,
    "FrequencyRank": 746
  },
  {
    "Character": "宠",
    "Pinyin": "chǒng",
    "Definition": "favorite, concubine; favor",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1277,
    "FrequencyRank": 2422
  },
  {
    "Character": "径",
    "Pinyin": "jìng",
    "Definition": "narrow path; diameter; direct",
    "Radical": "彳",
    "RadicalIndex": "60.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1163,
    "FrequencyRank": 1307
  },
  {
    "Character": "肺",
    "Pinyin": "fèi",
    "Definition": "lungs",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1181,
    "FrequencyRank": 2044
  },
  {
    "Character": "贫",
    "Pinyin": "pín",
    "Definition": "poor, impoverished, needy",
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1178,
    "FrequencyRank": 1359
  },
  {
    "Character": "咋",
    "Pinyin": "zǎ",
    "Definition": "question-forming particle, why? how? what?; to bite; loud",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1097,
    "FrequencyRank": 2830
  },
  {
    "Character": "肿",
    "Pinyin": "zhǒng",
    "Definition": "swell; swollen; swelling",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1183,
    "FrequencyRank": 1792
  },
  {
    "Character": "佳",
    "Pinyin": "jiā",
    "Definition": "good, auspicious; beautiful; delightful",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1135,
    "FrequencyRank": 1583
  },
  {
    "Character": "肴",
    "Pinyin": "yáo",
    "Definition": "cooked or prepared meat",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1169,
    "FrequencyRank": 3513
  },
  {
    "Character": "刮",
    "Pinyin": "guā",
    "Definition": "shave, pare off, scrape",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1129,
    "FrequencyRank": 2350
  },
  {
    "Character": "炒",
    "Pinyin": "chǎo",
    "Definition": "fry, saute, roast, boil, cook",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1234,
    "FrequencyRank": 2590
  },
  {
    "Character": "忠",
    "Pinyin": "zhōng",
    "Definition": "loyalty, devotion, fidelity",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1094,
    "FrequencyRank": 1322
  },
  {
    "Character": "诞",
    "Pinyin": "dàn",
    "Definition": "bear children, give birth; birth",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1297,
    "FrequencyRank": 1997
  },
  {
    "Character": "疙",
    "Pinyin": "gē",
    "Definition": "pimple, sore, boil, wart, pustule",
    "Radical": "疒",
    "RadicalIndex": "104.3",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1214,
    "FrequencyRank": 3527
  },
  {
    "Character": "侧",
    "Pinyin": "cè",
    "Definition": "side; incline, slant, lean",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1147,
    "FrequencyRank": 1220
  },
  {
    "Character": "贯",
    "Pinyin": "guàn",
    "Definition": "a string of 1000 coins; to go through",
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1351,
    "FrequencyRank": 1478
  },
  {
    "Character": "泥",
    "Pinyin": "ní",
    "Definition": "mud, mire; earth, clay; plaster",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1258,
    "FrequencyRank": 1499
  },
  {
    "Character": "奔",
    "Pinyin": "bēn",
    "Definition": "run fast, flee; rush about; run",
    "Radical": "大",
    "RadicalIndex": "37.6",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1049,
    "FrequencyRank": 1285
  },
  {
    "Character": "炎",
    "Pinyin": "yán",
    "Definition": "flame, blaze; hot",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1237,
    "FrequencyRank": 1324
  },
  {
    "Character": "奉",
    "Pinyin": "fèng",
    "Definition": "offer; receive; serve; respect",
    "Radical": "大",
    "RadicalIndex": "37.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 943,
    "FrequencyRank": 1382
  },
  {
    "Character": "驻",
    "Pinyin": "zhù",
    "Definition": "to be stationed at, reside at; to stop",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1345,
    "FrequencyRank": 1288
  },
  {
    "Character": "昌",
    "Pinyin": "chāng",
    "Definition": "light of sun; good, proper",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1084,
    "FrequencyRank": 1606
  },
  {
    "Character": "杰",
    "Pinyin": "jié",
    "Definition": "hero; heroic, outstanding",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1031,
    "FrequencyRank": 1129
  },
  {
    "Character": "乳",
    "Pinyin": "rǔ",
    "Definition": "breast, nipples; milk, suckle",
    "Radical": "乙",
    "RadicalIndex": "5.7",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1175,
    "FrequencyRank": 1831
  },
  {
    "Character": "波",
    "Pinyin": "bō",
    "Definition": "waves, breakers; undulations",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1261,
    "FrequencyRank": 664
  },
  {
    "Character": "茄",
    "Pinyin": "jiā",
    "Definition": "eggplant",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1013,
    "FrequencyRank": 3136
  },
  {
    "Character": "账",
    "Pinyin": "zhàng",
    "Definition": "accounts; bill, debt; credit",
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1114,
    "FrequencyRank": 2179
  },
  {
    "Character": "昏",
    "Pinyin": "hūn",
    "Definition": "dusk, nightfall, twilight, dark",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1193,
    "FrequencyRank": 1561
  },
  {
    "Character": "抵",
    "Pinyin": "dǐ",
    "Definition": "resist, oppose; deny; off-set",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 975,
    "FrequencyRank": 1119
  },
  {
    "Character": "厕",
    "Pinyin": "cè",
    "Definition": "mingle with; toilet, lavatory",
    "Radical": "厂",
    "RadicalIndex": "27.6",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1047,
    "FrequencyRank": 2920
  },
  {
    "Character": "拨",
    "Pinyin": "bō",
    "Definition": "move; dispel; distribute",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 991,
    "FrequencyRank": 1807
  },
  {
    "Character": "枝",
    "Pinyin": "zhī",
    "Definition": "branches, limbs; branch off",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1019,
    "FrequencyRank": 1491
  },
  {
    "Character": "拐",
    "Pinyin": "guǎi",
    "Definition": "kidnap, abduct; turn",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 967,
    "FrequencyRank": 2232
  },
  {
    "Character": "侥",
    "Pinyin": "jiǎo",
    "Definition": "be lucky; by chance, by luck",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1142,
    "FrequencyRank": 3359
  },
  {
    "Character": "泡",
    "Pinyin": "pào",
    "Definition": "bubbles, suds; blister; soak",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1251,
    "FrequencyRank": 2000
  },
  {
    "Character": "胁",
    "Pinyin": "xié",
    "Definition": "ribs, armpits; flank; threaten",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1191,
    "FrequencyRank": 1494
  },
  {
    "Character": "券",
    "Pinyin": "quàn",
    "Definition": "certificate, ticket; title deeds",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1230,
    "FrequencyRank": 1481
  },
  {
    "Character": "畅",
    "Pinyin": "chàng",
    "Definition": "smoothly, freely, unrestrained",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1086,
    "FrequencyRank": 2122
  },
  {
    "Character": "侄",
    "Pinyin": "zhí",
    "Definition": "nephew",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1144,
    "FrequencyRank": 2664
  },
  {
    "Character": "苟",
    "Pinyin": "gǒu",
    "Definition": "careless, frivolous; illicit; grammatical particle: if, but, if only; surname; grass name; distinguish DKW 30853 (ji4)",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1007,
    "FrequencyRank": 2886
  },
  {
    "Character": "孤",
    "Pinyin": "gū",
    "Definition": "orphan, fatherless; solitary",
    "Radical": "子",
    "RadicalIndex": "39.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1319,
    "FrequencyRank": 1334
  },
  {
    "Character": "若",
    "Pinyin": "ruò",
    "Definition": "if, supposing, assuming; similar",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1002,
    "FrequencyRank": 651
  },
  {
    "Character": "拖",
    "Pinyin": "tuō",
    "Definition": "drag, tow, haul; delay, prolong",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 968,
    "FrequencyRank": 1492
  },
  {
    "Character": "咀",
    "Pinyin": "jǔ",
    "Definition": "suck; chew, masticate",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 3911,
    "FrequencyRank": 3498
  },
  {
    "Character": "氛",
    "Pinyin": "fēn",
    "Definition": "gas, vapor, air",
    "Radical": "气",
    "RadicalIndex": "84.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1124,
    "FrequencyRank": 2009
  },
  {
    "Character": "旺",
    "Pinyin": "wàng",
    "Definition": "prosper; prosperous; increase",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1076,
    "FrequencyRank": 2198
  },
  {
    "Character": "丧",
    "Pinyin": "sàng",
    "Definition": "mourning; mourn; funeral",
    "Radical": "十",
    "RadicalIndex": "24.6",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1034,
    "FrequencyRank": 1404
  },
  {
    "Character": "盲",
    "Pinyin": "máng",
    "Definition": "blind; unperceptive, shortsighted",
    "Radical": "目",
    "RadicalIndex": "109.3",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1222,
    "FrequencyRank": 2079
  },
  {
    "Character": "炉",
    "Pinyin": "lú",
    "Definition": "fireplace, stove, oven, furnace",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1238,
    "FrequencyRank": 1980
  },
  {
    "Character": "呻",
    "Pinyin": "shēn",
    "Definition": "groan, moan; recite with intonation",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1095,
    "FrequencyRank": 2929
  },
  {
    "Character": "宗",
    "Pinyin": "zōng",
    "Definition": "lineage, ancestry; ancestor, clan",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1275,
    "FrequencyRank": 727
  },
  {
    "Character": "股",
    "Pinyin": "gǔ",
    "Definition": "thighs, haunches, rump; share",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1186,
    "FrequencyRank": 644
  },
  {
    "Character": "坡",
    "Pinyin": "pō",
    "Definition": "slope, bank, hillside",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 989,
    "FrequencyRank": 1550
  },
  {
    "Character": "沿",
    "Pinyin": "yán",
    "Definition": "follow a course, go along",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1250,
    "FrequencyRank": 1182
  },
  {
    "Character": "废",
    "Pinyin": "fèi",
    "Definition": "abrogate, terminate, discard",
    "Radical": "广",
    "RadicalIndex": "53.5",
    "StrokeCount": 8,
    "HSK": 6,
    "GeneralStandard": 1220,
    "FrequencyRank": 1376
  },
  {
    "Character": "咨",
    "Pinyin": "zī",
    "Definition": "inquire, consult, discuss; plan",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1629,
    "FrequencyRank": 2408
  },
  {
    "Character": "炮",
    "Pinyin": "pào",
    "Definition": "large gun, cannon; artillery",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1658,
    "FrequencyRank": 914
  },
  {
    "Character": "荤",
    "Pinyin": "hūn",
    "Definition": "meat diet; strong smelling",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1416,
    "FrequencyRank": 4295
  },
  {
    "Character": "洪",
    "Pinyin": "hóng",
    "Definition": "vast, immense; flood, deluge",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1664,
    "FrequencyRank": 1350
  },
  {
    "Character": "奏",
    "Pinyin": "zòu",
    "Definition": "memorialize emperor; report",
    "Radical": "大",
    "RadicalIndex": "37.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1354,
    "FrequencyRank": 1543
  },
  {
    "Character": "皇",
    "Pinyin": "huáng",
    "Definition": "royal, imperial; ruler, superior",
    "Radical": "白",
    "RadicalIndex": "106.4",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1571,
    "FrequencyRank": 759
  },
  {
    "Character": "疤",
    "Pinyin": "bā",
    "Definition": "scar, cicatrix; birthmark",
    "Radical": "疒",
    "RadicalIndex": "104.4",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1628,
    "FrequencyRank": 3055
  },
  {
    "Character": "侵",
    "Pinyin": "qīn",
    "Definition": "invade, encroach upon, raid",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1574,
    "FrequencyRank": 1086
  },
  {
    "Character": "绘",
    "Pinyin": "huì",
    "Definition": "draw, sketch, paint",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1757,
    "FrequencyRank": 1809
  },
  {
    "Character": "怒",
    "Pinyin": "nù",
    "Definition": "anger, rage, passion; angry",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1742,
    "FrequencyRank": 1143
  },
  {
    "Character": "盼",
    "Pinyin": "pàn",
    "Definition": "look, gaze; expect, hope for",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1480,
    "FrequencyRank": 2174
  },
  {
    "Character": "剑",
    "Pinyin": "jiàn",
    "Definition": "sword, dagger, saber",
    "Radical": "刀",
    "RadicalIndex": "18.7",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1587,
    "FrequencyRank": 869
  },
  {
    "Character": "俐",
    "Pinyin": "lì",
    "Definition": "smooth; active; clever, sharp",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1565,
    "FrequencyRank": 3564
  },
  {
    "Character": "扁",
    "Pinyin": "biǎn",
    "Definition": "flat; tablet, signboard",
    "Radical": "戶",
    "RadicalIndex": "63.5",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1708,
    "FrequencyRank": 1737
  },
  {
    "Character": "胞",
    "Pinyin": "bāo",
    "Definition": "womb, placenta, fetal membrane",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1595,
    "FrequencyRank": 1341
  },
  {
    "Character": "洋",
    "Pinyin": "yáng",
    "Definition": "ocean, sea; foreign; western",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1679,
    "FrequencyRank": 803
  },
  {
    "Character": "残",
    "Pinyin": "cán",
    "Definition": "injure, spoil; oppress; broken",
    "Radical": "歹",
    "RadicalIndex": "78.5",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1460,
    "FrequencyRank": 1150
  },
  {
    "Character": "迸",
    "Pinyin": "bèng",
    "Definition": "gush out; burst forth; split",
    "Radical": "辵",
    "RadicalIndex": "162.8",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 4321,
    "FrequencyRank": 3290
  },
  {
    "Character": "牵",
    "Pinyin": "qiān",
    "Definition": "drag, pull, lead by hand",
    "Radical": "大",
    "RadicalIndex": "37.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1458,
    "FrequencyRank": 1679
  },
  {
    "Character": "牲",
    "Pinyin": "shēng",
    "Definition": "sacrificial animal; animal",
    "Radical": "牛",
    "RadicalIndex": "93.5",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1544,
    "FrequencyRank": 1582
  },
  {
    "Character": "诬",
    "Pinyin": "wū",
    "Definition": "make false accusation; defame",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1706,
    "FrequencyRank": 3069
  },
  {
    "Character": "哇",
    "Pinyin": "wa",
    "Definition": "vomit; cry of an infant",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1482,
    "FrequencyRank": 2433
  },
  {
    "Character": "祖",
    "Pinyin": "zǔ",
    "Definition": "ancestor, forefather; grandfather",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1710,
    "FrequencyRank": 1025
  },
  {
    "Character": "栏",
    "Pinyin": "lán",
    "Definition": "railing, balustrade; animal pan",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1437,
    "FrequencyRank": 2101
  },
  {
    "Character": "甭",
    "Pinyin": "béng",
    "Definition": "there is no need",
    "Radical": "用",
    "RadicalIndex": "101.4",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 4164,
    "FrequencyRank": 3396
  },
  {
    "Character": "柱",
    "Pinyin": "zhù",
    "Definition": "pillar, post; support; lean on",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1435,
    "FrequencyRank": 1691
  },
  {
    "Character": "钝",
    "Pinyin": "dùn",
    "Definition": "blunt, obtuse; dull; flat; dull-witted",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1526,
    "FrequencyRank": 3059
  },
  {
    "Character": "蚂",
    "Pinyin": "mǎ",
    "Definition": "ant; leech",
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1501,
    "FrequencyRank": 2888
  },
  {
    "Character": "娃",
    "Pinyin": "wá",
    "Definition": "baby; doll; pretty girl",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1735,
    "FrequencyRank": 1833
  },
  {
    "Character": "蚁",
    "Pinyin": "yǐ",
    "Definition": "ants",
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1499,
    "FrequencyRank": 2517
  },
  {
    "Character": "狠",
    "Pinyin": "hěn",
    "Definition": "vicious, cruel; severely, extreme",
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1606,
    "FrequencyRank": 1654
  },
  {
    "Character": "威",
    "Pinyin": "wēi",
    "Definition": "pomp, power; powerful; dominate",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1444,
    "FrequencyRank": 622
  },
  {
    "Character": "恰",
    "Pinyin": "qià",
    "Definition": "just, exactly, precisely; proper",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1690,
    "FrequencyRank": 1308
  },
  {
    "Character": "洁",
    "Pinyin": "jié",
    "Definition": "clean, purify, pure",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1663,
    "FrequencyRank": 1531
  },
  {
    "Character": "帝",
    "Pinyin": "dì",
    "Definition": "supreme ruler, emperor; god",
    "Radical": "巾",
    "RadicalIndex": "50.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1633,
    "FrequencyRank": 612
  },
  {
    "Character": "炸",
    "Pinyin": "zhà",
    "Definition": "to fry in oil; to scald; to explode",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1656,
    "FrequencyRank": 976
  },
  {
    "Character": "挖",
    "Pinyin": "wā",
    "Definition": "dig, dig out, gouge out, scoop",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1394,
    "FrequencyRank": 1860
  },
  {
    "Character": "屏",
    "Pinyin": "píng",
    "Definition": "folding screen, shield",
    "Radical": "尸",
    "RadicalIndex": "44.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1724,
    "FrequencyRank": 1891
  },
  {
    "Character": "宫",
    "Pinyin": "gōng",
    "Definition": "palace",
    "Radical": "宀",
    "RadicalIndex": "40.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1698,
    "FrequencyRank": 982
  },
  {
    "Character": "骄",
    "Pinyin": "jiāo",
    "Definition": "spirited horse; haughty",
    "Radical": "馬",
    "RadicalIndex": "187.6",
    "StrokeCount": 9,
    "HSK": 6,
    "GeneralStandard": 1756,
    "FrequencyRank": 2248
  },
  {
    "Character": "壶",
    "Pinyin": "hú",
    "Definition": "jar, pot, jug, vase; surname",
    "Radical": "士",
    "RadicalIndex": "33.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1812,
    "FrequencyRank": 2518
  },
  {
    "Character": "牺",
    "Pinyin": "xī",
    "Definition": "sacrifice, give up; sacrificial",
    "Radical": "牛",
    "RadicalIndex": "93.6",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1939,
    "FrequencyRank": 1814
  },
  {
    "Character": "症",
    "Pinyin": "zhèng",
    "Definition": "disease, illness, ailment",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2026,
    "FrequencyRank": 1258
  },
  {
    "Character": "捉",
    "Pinyin": "zhuō",
    "Definition": "grasp, clutch; catch, seize",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1795,
    "FrequencyRank": 1822
  },
  {
    "Character": "悟",
    "Pinyin": "wù",
    "Definition": "to apprehend, realize, become aware",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2091,
    "FrequencyRank": 1668
  },
  {
    "Character": "罢",
    "Pinyin": "bà",
    "Definition": "cease, finish, stop; give up",
    "Radical": "网",
    "RadicalIndex": "122.5",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1918,
    "FrequencyRank": 1305
  },
  {
    "Character": "钻",
    "Pinyin": "zuān",
    "Definition": "drill, bore; pierce; diamond",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1930,
    "FrequencyRank": 1724
  },
  {
    "Character": "宴",
    "Pinyin": "yàn",
    "Definition": "to entertain, feast; a feast, banquet",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2101,
    "FrequencyRank": 2155
  },
  {
    "Character": "涉",
    "Pinyin": "shè",
    "Definition": "ford stream, wade across",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2070,
    "FrequencyRank": 1239
  },
  {
    "Character": "谅",
    "Pinyin": "liàng",
    "Definition": "excuse, forgive; guess, presume",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2125,
    "FrequencyRank": 2120
  },
  {
    "Character": "唆",
    "Pinyin": "suō",
    "Definition": "make mischief, incite, instigate",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1917,
    "FrequencyRank": 3654
  },
  {
    "Character": "晕",
    "Pinyin": "yūn",
    "Definition": "halo in sky; fog; dizzy, faint",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1897,
    "FrequencyRank": 2215
  },
  {
    "Character": "捡",
    "Pinyin": "jiǎn",
    "Definition": "to pick up",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1804,
    "FrequencyRank": 2753
  },
  {
    "Character": "耗",
    "Pinyin": "hào",
    "Definition": "consume, use up; waste, squander",
    "Radical": "耒",
    "RadicalIndex": "127.4",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1768,
    "FrequencyRank": 1730
  },
  {
    "Character": "晓",
    "Pinyin": "xiǎo",
    "Definition": "dawn, daybreak; clear, explicit",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1889,
    "FrequencyRank": 1357
  },
  {
    "Character": "峭",
    "Pinyin": "qiào",
    "Definition": "steep, precipitous, rugged",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1919,
    "FrequencyRank": 2990
  },
  {
    "Character": "素",
    "Pinyin": "sù",
    "Definition": "white (silk); plain; vegetarian; formerly; normally",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1775,
    "FrequencyRank": 661
  },
  {
    "Character": "祥",
    "Pinyin": "xiáng",
    "Definition": "good luck, good omen; happiness",
    "Radical": "示",
    "RadicalIndex": "113.6",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2119,
    "FrequencyRank": 1674
  },
  {
    "Character": "涩",
    "Pinyin": "sè",
    "Definition": "astringent; harsh; uneven, rough",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2088,
    "FrequencyRank": 2836
  },
  {
    "Character": "浮",
    "Pinyin": "fú",
    "Definition": "to float, drift, waft; to exceed; superfluous",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2077,
    "FrequencyRank": 1462
  },
  {
    "Character": "娱",
    "Pinyin": "yú",
    "Definition": "pleasure, enjoyment, amusement",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2140,
    "FrequencyRank": 2493
  },
  {
    "Character": "粉",
    "Pinyin": "fěn",
    "Definition": "powder, face powder; plaster",
    "Radical": "米",
    "RadicalIndex": "119.4",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2053,
    "FrequencyRank": 1498
  },
  {
    "Character": "徒",
    "Pinyin": "tú",
    "Definition": "disciple, follower; go on foot",
    "Radical": "彳",
    "RadicalIndex": "60.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1975,
    "FrequencyRank": 1060
  },
  {
    "Character": "氧",
    "Pinyin": "yǎng",
    "Definition": "oxygen",
    "Radical": "气",
    "RadicalIndex": "84.6",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1936,
    "FrequencyRank": 1863
  },
  {
    "Character": "铅",
    "Pinyin": "qiān",
    "Definition": "lead",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1934,
    "FrequencyRank": 2641
  },
  {
    "Character": "疾",
    "Pinyin": "jí",
    "Definition": "illness, disease, sickness; to hate",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2028,
    "FrequencyRank": 1273
  },
  {
    "Character": "舰",
    "Pinyin": "jiàn",
    "Definition": "warship",
    "Radical": "舟",
    "RadicalIndex": "137.4",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1978,
    "FrequencyRank": 851
  },
  {
    "Character": "诸",
    "Pinyin": "zhū",
    "Definition": "various, all, these; surname",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2110,
    "FrequencyRank": 1021
  },
  {
    "Character": "债",
    "Pinyin": "zhài",
    "Definition": "debt, loan, liabilities",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1954,
    "FrequencyRank": 1223
  },
  {
    "Character": "捐",
    "Pinyin": "juān",
    "Definition": "contribute; give up, renounce",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1797,
    "FrequencyRank": 2235
  },
  {
    "Character": "哲",
    "Pinyin": "zhé",
    "Definition": "wise, sagacious; wise man, sage",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1802,
    "FrequencyRank": 1117
  },
  {
    "Character": "袖",
    "Pinyin": "xiù",
    "Definition": "sleeve; put something in sleeve",
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2116,
    "FrequencyRank": 1686
  },
  {
    "Character": "诺",
    "Pinyin": "nuò",
    "Definition": "promise; assent, approve",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2111,
    "FrequencyRank": 969
  },
  {
    "Character": "峰",
    "Pinyin": "fēng",
    "Definition": "peak, summit; hump of camel",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1921,
    "FrequencyRank": 1473
  },
  {
    "Character": "挨",
    "Pinyin": "āi",
    "Definition": "near, close by, next to, towards, against; to wait; to lean on",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1815,
    "FrequencyRank": 2012
  },
  {
    "Character": "捕",
    "Pinyin": "bǔ",
    "Definition": "arrest, catch, seize",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1783,
    "FrequencyRank": 1312
  },
  {
    "Character": "埋",
    "Pinyin": "mái",
    "Definition": "bury, secrete, conceal",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1794,
    "FrequencyRank": 1640
  },
  {
    "Character": "陷",
    "Pinyin": "xiàn",
    "Definition": "submerge, sink, plunge; trap",
    "Radical": "阜",
    "RadicalIndex": "170.8",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 2138,
    "FrequencyRank": 1262
  },
  {
    "Character": "倾",
    "Pinyin": "qīng",
    "Definition": "upset, pour out, overflow",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1959,
    "FrequencyRank": 1260
  },
  {
    "Character": "顽",
    "Pinyin": "wán",
    "Definition": "obstinate, stubborn; recalcitrant",
    "Radical": "頁",
    "RadicalIndex": "181.4",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1778,
    "FrequencyRank": 2024
  },
  {
    "Character": "党",
    "Pinyin": "dǎng",
    "Definition": "political party, gang, faction",
    "Radical": "儿",
    "RadicalIndex": "10.8",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1885,
    "FrequencyRank": 411
  },
  {
    "Character": "恩",
    "Pinyin": "ēn",
    "Definition": "kindness, mercy, charity",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1909,
    "FrequencyRank": 888
  },
  {
    "Character": "档",
    "Pinyin": "dàng",
    "Definition": "shelf; frame, crosspiece",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1838,
    "FrequencyRank": 1787
  },
  {
    "Character": "监",
    "Pinyin": "jiān",
    "Definition": "supervise, control, direct",
    "Radical": "皿",
    "RadicalIndex": "108.5",
    "StrokeCount": 10,
    "HSK": 6,
    "GeneralStandard": 1883,
    "FrequencyRank": 838
  },
  {
    "Character": "掷",
    "Pinyin": "zhì",
    "Definition": "throw, hurl, cast, fling",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2186,
    "FrequencyRank": 2614
  },
  {
    "Character": "铜",
    "Pinyin": "tóng",
    "Definition": "copper, brass, bronze",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2306,
    "FrequencyRank": 1772
  },
  {
    "Character": "惮",
    "Pinyin": "dàn",
    "Definition": "dread, shrink from, shirk, fear",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 4992,
    "FrequencyRank": 3601
  },
  {
    "Character": "崇",
    "Pinyin": "chóng",
    "Definition": "esteem, honor, revere, venerate",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2299,
    "FrequencyRank": 1482
  },
  {
    "Character": "啦",
    "Pinyin": "la",
    "Definition": "final particle of assertion",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2267,
    "FrequencyRank": 1194
  },
  {
    "Character": "略",
    "Pinyin": "lüè",
    "Definition": "approximately, roughly; outline",
    "Radical": "田",
    "RadicalIndex": "102.6",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2277,
    "FrequencyRank": 704
  },
  {
    "Character": "绪",
    "Pinyin": "xù",
    "Definition": "end of thread; thread, clue",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2487,
    "FrequencyRank": 1413
  },
  {
    "Character": "盗",
    "Pinyin": "dào",
    "Definition": "rob, steal; thief, bandit",
    "Radical": "皿",
    "RadicalIndex": "108.6",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2388,
    "FrequencyRank": 1619
  },
  {
    "Character": "械",
    "Pinyin": "xiè",
    "Definition": "weapons; implements, instruments",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2219,
    "FrequencyRank": 1657
  },
  {
    "Character": "悬",
    "Pinyin": "xuán",
    "Definition": "hang, suspend, hoist; be hung",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2264,
    "FrequencyRank": 1879
  },
  {
    "Character": "眶",
    "Pinyin": "kuàng",
    "Definition": "eye socket; rim of eye",
    "Radical": "目",
    "RadicalIndex": "109.6",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2258,
    "FrequencyRank": 3191
  },
  {
    "Character": "混",
    "Pinyin": "hùn",
    "Definition": "to mix, blend, mingle; to bumble along",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2420,
    "FrequencyRank": 1137
  },
  {
    "Character": "野",
    "Pinyin": "yě",
    "Definition": "open country, field; wilderness",
    "Radical": "里",
    "RadicalIndex": "166.4",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2265,
    "FrequencyRank": 845
  },
  {
    "Character": "徙",
    "Pinyin": "xǐ",
    "Definition": undefined,
    "Radical": "彳",
    "RadicalIndex": "60.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2340,
    "FrequencyRank": 3411
  },
  {
    "Character": "偏",
    "Pinyin": "piān",
    "Definition": "inclined one side; slanting",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2334,
    "FrequencyRank": 1204
  },
  {
    "Character": "梁",
    "Pinyin": "liáng",
    "Definition": "bridge; beam; rafters; surname",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2436,
    "FrequencyRank": 1416
  },
  {
    "Character": "欲",
    "Pinyin": "yù",
    "Definition": "desire, want, long for; intend",
    "Radical": "欠",
    "RadicalIndex": "76.7",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2352,
    "FrequencyRank": 1045
  },
  {
    "Character": "爽",
    "Pinyin": "shuǎng",
    "Definition": "happy, cheerful; refreshing",
    "Radical": "爻",
    "RadicalIndex": "89.7",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2244,
    "FrequencyRank": 2378
  },
  {
    "Character": "谋",
    "Pinyin": "móu",
    "Definition": "plan, scheme; strategem",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2459,
    "FrequencyRank": 989
  },
  {
    "Character": "隐",
    "Pinyin": "yǐn",
    "Definition": "hide, conceal; hidden, secret",
    "Radical": "阜",
    "RadicalIndex": "170.9",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2480,
    "FrequencyRank": 1034
  },
  {
    "Character": "盛",
    "Pinyin": "shèng",
    "Definition": "abundant, flourishing; contain; fill",
    "Radical": "皿",
    "RadicalIndex": "108.7",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2247,
    "FrequencyRank": 1142
  },
  {
    "Character": "菌",
    "Pinyin": "jūn",
    "Definition": "mushroom; germ, microbe",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2205,
    "FrequencyRank": 1733
  },
  {
    "Character": "谐",
    "Pinyin": "xié",
    "Definition": "harmonize, agree; joke, jest",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2462,
    "FrequencyRank": 2416
  },
  {
    "Character": "液",
    "Pinyin": "yè",
    "Definition": "sap, juice, liquid, fluid",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2428,
    "FrequencyRank": 1451
  },
  {
    "Character": "添",
    "Pinyin": "tiān",
    "Definition": "append, add to; increase",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2411,
    "FrequencyRank": 1859
  },
  {
    "Character": "跃",
    "Pinyin": "yuè",
    "Definition": "skip, jump, frolic",
    "Radical": "足",
    "RadicalIndex": "157.4",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2276,
    "FrequencyRank": 1516
  },
  {
    "Character": "猛",
    "Pinyin": "měng",
    "Definition": "violent, savage, cruel; bold",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2370,
    "FrequencyRank": 1157
  },
  {
    "Character": "惨",
    "Pinyin": "cǎn",
    "Definition": "sad, pitiful, wretched; cruel",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2449,
    "FrequencyRank": 1530
  },
  {
    "Character": "啥",
    "Pinyin": "shà",
    "Definition": "what?",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2290,
    "FrequencyRank": 2082
  },
  {
    "Character": "啬",
    "Pinyin": "sè",
    "Definition": "miserly, thrifty; stingy",
    "Radical": "十",
    "RadicalIndex": "24.9",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 4780,
    "FrequencyRank": 3860
  },
  {
    "Character": "患",
    "Pinyin": "huàn",
    "Definition": "suffer, worry about; suffering",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2285,
    "FrequencyRank": 1249
  },
  {
    "Character": "眯",
    "Pinyin": "mī",
    "Definition": "be blinded",
    "Radical": "目",
    "RadicalIndex": "109.6",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2262,
    "FrequencyRank": 2955
  },
  {
    "Character": "渠",
    "Pinyin": "qú",
    "Definition": "ditch, canal, channel, gutter",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2416,
    "FrequencyRank": 2315
  },
  {
    "Character": "梅",
    "Pinyin": "méi",
    "Definition": "plums; prunes; surname",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2226,
    "FrequencyRank": 1159
  },
  {
    "Character": "探",
    "Pinyin": "tàn",
    "Definition": "find, locate; search, grope for",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2188,
    "FrequencyRank": 917
  },
  {
    "Character": "掏",
    "Pinyin": "tāo",
    "Definition": "take out; pull out; clean out",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2180,
    "FrequencyRank": 2218
  },
  {
    "Character": "旋",
    "Pinyin": "xuán",
    "Definition": "revolve, move in orbit; return",
    "Radical": "方",
    "RadicalIndex": "70.7",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2393,
    "FrequencyRank": 1383
  },
  {
    "Character": "副",
    "Pinyin": "fù",
    "Definition": "assist; supplement; assistant",
    "Radical": "刀",
    "RadicalIndex": "18.9",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2234,
    "FrequencyRank": 764
  },
  {
    "Character": "啰",
    "Pinyin": "luō",
    "Definition": "phonetic",
    "Radical": "网",
    "RadicalIndex": "122.3",
    "StrokeCount": 11,
    "HSK": 6,
    "GeneralStandard": 2286,
    "FrequencyRank": undefined
  },
  {
    "Character": "椭",
    "Pinyin": "tuǒ",
    "Definition": "oval-shaped, elliptical, tubular",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2579,
    "FrequencyRank": 3312
  },
  {
    "Character": "裂",
    "Pinyin": "liè",
    "Definition": "split, crack, break open; rend",
    "Radical": "衣",
    "RadicalIndex": "145.6",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2595,
    "FrequencyRank": 1317
  },
  {
    "Character": "塔",
    "Pinyin": "tǎ",
    "Definition": "tower, spire, tall building",
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2510,
    "FrequencyRank": 1030
  },
  {
    "Character": "棕",
    "Pinyin": "zōng",
    "Definition": "hemp palm; palm tree",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2576,
    "FrequencyRank": 2774
  },
  {
    "Character": "殖",
    "Pinyin": "zhí",
    "Definition": "breed, spawn; increase; prosper",
    "Radical": "歹",
    "RadicalIndex": "78.8",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2594,
    "FrequencyRank": 1438
  },
  {
    "Character": "赌",
    "Pinyin": "dǔ",
    "Definition": "bet, gamble, wager; compete",
    "Radical": "貝",
    "RadicalIndex": "154.8",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2651,
    "FrequencyRank": 2037
  },
  {
    "Character": "谦",
    "Pinyin": "qiān",
    "Definition": "humble, modest",
    "Radical": "言",
    "RadicalIndex": "149.1",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2798,
    "FrequencyRank": 2345
  },
  {
    "Character": "粥",
    "Pinyin": "zhōu",
    "Definition": "rice gruel, congee",
    "Radical": "米",
    "RadicalIndex": "119.6",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2803,
    "FrequencyRank": 3021
  },
  {
    "Character": "港",
    "Pinyin": "gǎng",
    "Definition": "port, harbor; small stream; bay",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2749,
    "FrequencyRank": 927
  },
  {
    "Character": "储",
    "Pinyin": "chǔ",
    "Definition": "save money, store, reserve; heir",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2697,
    "FrequencyRank": 1526
  },
  {
    "Character": "番",
    "Pinyin": "fān",
    "Definition": "to take turns; a turn, a time; to repeat",
    "Radical": "田",
    "RadicalIndex": "102.7",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2709,
    "FrequencyRank": 1452
  },
  {
    "Character": "锋",
    "Pinyin": "fēng",
    "Definition": "point of spear, sharp point",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2664,
    "FrequencyRank": 1671
  },
  {
    "Character": "焦",
    "Pinyin": "jiāo",
    "Definition": "burned, scorched; anxious, vexed",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2695,
    "FrequencyRank": 1554
  },
  {
    "Character": "策",
    "Pinyin": "cè",
    "Definition": "scheme, plan; to whip; urge",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2683,
    "FrequencyRank": 714
  },
  {
    "Character": "湾",
    "Pinyin": "wān",
    "Definition": "bay, cove, inlet, bend of stream",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2764,
    "FrequencyRank": 855
  },
  {
    "Character": "晾",
    "Pinyin": "liàng",
    "Definition": "air-dry; sun-dry",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2625,
    "FrequencyRank": 3909
  },
  {
    "Character": "辉",
    "Pinyin": "huī",
    "Definition": "brightness, luster, brilliance",
    "Radical": "小",
    "RadicalIndex": "42.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2606,
    "FrequencyRank": 1623
  },
  {
    "Character": "铺",
    "Pinyin": "pù",
    "Definition": "shop, store; place sleep, bed",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2657,
    "FrequencyRank": 1613
  },
  {
    "Character": "渡",
    "Pinyin": "dù",
    "Definition": "cross, ferry over; ferry",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2765,
    "FrequencyRank": 1406
  },
  {
    "Character": "椎",
    "Pinyin": "chuí",
    "Definition": "hammer, mallet; vertebra",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2573,
    "FrequencyRank": 2455
  },
  {
    "Character": "逼",
    "Pinyin": "bī",
    "Definition": "compel, pressure, force; bother",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2582,
    "FrequencyRank": 1559
  },
  {
    "Character": "税",
    "Pinyin": "shuì",
    "Definition": "taxes",
    "Radical": "禾",
    "RadicalIndex": "115.7",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2679,
    "FrequencyRank": 781
  },
  {
    "Character": "缘",
    "Pinyin": "yuán",
    "Definition": "hem, margin; reason, cause; fate",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2824,
    "FrequencyRank": 1274
  },
  {
    "Character": "煮",
    "Pinyin": "zhǔ",
    "Definition": "cook",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2529,
    "FrequencyRank": 2582
  },
  {
    "Character": "愤",
    "Pinyin": "fèn",
    "Definition": "resent, hate; indignant",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2770,
    "FrequencyRank": 1508
  },
  {
    "Character": "阔",
    "Pinyin": "kuò",
    "Definition": "broad, ample, wide; be apart",
    "Radical": "門",
    "RadicalIndex": "169.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2737,
    "FrequencyRank": 1675
  },
  {
    "Character": "棉",
    "Pinyin": "mián",
    "Definition": "cotton; cotton padded",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2574,
    "FrequencyRank": 1967
  },
  {
    "Character": "揭",
    "Pinyin": "jiē",
    "Definition": "raise, lift up; surname",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2522,
    "FrequencyRank": 1666
  },
  {
    "Character": "傍",
    "Pinyin": "bàng",
    "Definition": "by side of, beside, near, close",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2696,
    "FrequencyRank": 2558
  },
  {
    "Character": "跌",
    "Pinyin": "diē",
    "Definition": "stumble, slip, fall down; stamp",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2630,
    "FrequencyRank": 1590
  },
  {
    "Character": "愉",
    "Pinyin": "yú",
    "Definition": "pleasant, delightful; please",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2777,
    "FrequencyRank": 1982
  },
  {
    "Character": "践",
    "Pinyin": "jiàn",
    "Definition": "trample, tread upon, walk on",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2628,
    "FrequencyRank": 1578
  },
  {
    "Character": "搭",
    "Pinyin": "dā",
    "Definition": "join together, attach to; add to",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2511,
    "FrequencyRank": 1842
  },
  {
    "Character": "欺",
    "Pinyin": "qī",
    "Definition": "cheat, double-cross, deceive",
    "Radical": "欠",
    "RadicalIndex": "76.8",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2543,
    "FrequencyRank": 1699
  },
  {
    "Character": "援",
    "Pinyin": "yuán",
    "Definition": "aid, assist; lead; cite",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2530,
    "FrequencyRank": 1197
  },
  {
    "Character": "循",
    "Pinyin": "xún",
    "Definition": "obey, comply with, follow",
    "Radical": "彳",
    "RadicalIndex": "60.9",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2705,
    "FrequencyRank": 1744
  },
  {
    "Character": "傲",
    "Pinyin": "ào",
    "Definition": "proud, haughty, overbearing",
    "Radical": "人",
    "RadicalIndex": "9.11",
    "StrokeCount": 12,
    "HSK": 6,
    "GeneralStandard": 2690,
    "FrequencyRank": 1960
  },
  {
    "Character": "墓",
    "Pinyin": "mù",
    "Definition": "grave, tomb",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2853,
    "FrequencyRank": 1816
  },
  {
    "Character": "赖",
    "Pinyin": "lài",
    "Definition": "rely, depend on; accuse falsely",
    "Radical": "貝",
    "RadicalIndex": "154.9",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2872,
    "FrequencyRank": 1365
  },
  {
    "Character": "誉",
    "Pinyin": "yù",
    "Definition": "fame, reputation; praise",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 3024,
    "FrequencyRank": 1676
  },
  {
    "Character": "罪",
    "Pinyin": "zuì",
    "Definition": "crime, sin, vice; evil; hardship",
    "Radical": "网",
    "RadicalIndex": "122.8",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2932,
    "FrequencyRank": 718
  },
  {
    "Character": "鉴",
    "Pinyin": "jiàn",
    "Definition": "mirror, looking glass; reflect",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2894,
    "FrequencyRank": 1655
  },
  {
    "Character": "毁",
    "Pinyin": "huǐ",
    "Definition": "destroy",
    "Radical": "殳",
    "RadicalIndex": "79.9",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2956,
    "FrequencyRank": 1160
  },
  {
    "Character": "嗨",
    "Pinyin": "hāi",
    "Definition": "an exclamation",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 5473,
    "FrequencyRank": 3294
  },
  {
    "Character": "盟",
    "Pinyin": "méng",
    "Definition": "swear; oath, covenant, alliance",
    "Radical": "皿",
    "RadicalIndex": "108.8",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2907,
    "FrequencyRank": 1163
  },
  {
    "Character": "塞",
    "Pinyin": "sāi",
    "Definition": "stop up, block, seal, cork; pass, frontier; fortress",
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 3025,
    "FrequencyRank": 1080
  },
  {
    "Character": "跨",
    "Pinyin": "kuà",
    "Definition": "straddle, bestride, ride; carry",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2913,
    "FrequencyRank": 1774
  },
  {
    "Character": "嫌",
    "Pinyin": "xián",
    "Definition": "hate, detest; suspect; criticize",
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 3041,
    "FrequencyRank": 1826
  },
  {
    "Character": "斟",
    "Pinyin": "zhēn",
    "Definition": "to pour wine or tea into a cup; to gauge",
    "Radical": "斗",
    "RadicalIndex": "68.9",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2846,
    "FrequencyRank": 3167
  },
  {
    "Character": "蒙",
    "Pinyin": "méng",
    "Definition": "cover; ignorant; suffer; mongolia",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2859,
    "FrequencyRank": 1039
  },
  {
    "Character": "雹",
    "Pinyin": "báo",
    "Definition": "hail",
    "Radical": "雨",
    "RadicalIndex": "173.5",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2887,
    "FrequencyRank": 4037
  },
  {
    "Character": "酱",
    "Pinyin": "jiàng",
    "Definition": "any jam-like or paste-like food",
    "Radical": "酉",
    "RadicalIndex": "164.6",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2986,
    "FrequencyRank": 3100
  },
  {
    "Character": "聘",
    "Pinyin": "pìn",
    "Definition": "engage, employ; betroth",
    "Radical": "耳",
    "RadicalIndex": "128.7",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2845,
    "FrequencyRank": 2253
  },
  {
    "Character": "督",
    "Pinyin": "dū",
    "Definition": "supervise, oversee, direct",
    "Radical": "目",
    "RadicalIndex": "109.8",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2891,
    "FrequencyRank": 946
  },
  {
    "Character": "障",
    "Pinyin": "zhàng",
    "Definition": "separate; shield; barricade",
    "Radical": "阜",
    "RadicalIndex": "170.11",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 3038,
    "FrequencyRank": 1237
  },
  {
    "Character": "跪",
    "Pinyin": "guì",
    "Definition": "kneel",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 2917,
    "FrequencyRank": 2128
  },
  {
    "Character": "暄",
    "Pinyin": "xuān",
    "Definition": "warm, comfortable; warm, genial",
    "Radical": "日",
    "RadicalIndex": "72.9",
    "StrokeCount": 13,
    "HSK": 6,
    "GeneralStandard": 5449,
    "FrequencyRank": 3886
  },
  {
    "Character": "辕",
    "Pinyin": "yuán",
    "Definition": undefined,
    "Radical": "車",
    "RadicalIndex": "159.1",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 5686,
    "FrequencyRank": 3831
  },
  {
    "Character": "暧",
    "Pinyin": "ài",
    "Definition": "obscure, dim, ambiguous, vague",
    "Radical": "日",
    "RadicalIndex": "72.1",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 5700,
    "FrequencyRank": 3802
  },
  {
    "Character": "旗",
    "Pinyin": "qí",
    "Definition": "banner, flag, streamer",
    "Radical": "方",
    "RadicalIndex": "70.1",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3152,
    "FrequencyRank": 1407
  },
  {
    "Character": "嘈",
    "Pinyin": "cáo",
    "Definition": "noisy",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 5696,
    "FrequencyRank": 3446
  },
  {
    "Character": "歉",
    "Pinyin": "qiàn",
    "Definition": "deficient, lacking, insufficient",
    "Radical": "欠",
    "RadicalIndex": "76.1",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3155,
    "FrequencyRank": 2149
  },
  {
    "Character": "赚",
    "Pinyin": "zhuàn",
    "Definition": "make money, earn; gain, profit",
    "Radical": "貝",
    "RadicalIndex": "154.1",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3112,
    "FrequencyRank": 2182
  },
  {
    "Character": "膜",
    "Pinyin": "mó",
    "Definition": "membrane; to kneel and worship",
    "Radical": "肉",
    "RadicalIndex": "130.11",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3132,
    "FrequencyRank": 1945
  },
  {
    "Character": "雌",
    "Pinyin": "cí",
    "Definition": "female; feminine; gentle, soft",
    "Radical": "隹",
    "RadicalIndex": "172.5",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3098,
    "FrequencyRank": 2382
  },
  {
    "Character": "锲",
    "Pinyin": "qiè",
    "Definition": "sickle; cut, carve, engrave",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 5733,
    "FrequencyRank": 4771
  },
  {
    "Character": "嘛",
    "Pinyin": "ma",
    "Definition": "final exclamatory particle",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3110,
    "FrequencyRank": 1628
  },
  {
    "Character": "嘉",
    "Pinyin": "jiā",
    "Definition": "excellent; joyful; auspicious",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3056,
    "FrequencyRank": 1845
  },
  {
    "Character": "榜",
    "Pinyin": "bǎng",
    "Definition": "placard; list of successful exam candidates",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3081,
    "FrequencyRank": 2556
  },
  {
    "Character": "馒",
    "Pinyin": "mán",
    "Definition": "steamed bread; steamed dumplings",
    "Radical": "食",
    "RadicalIndex": "184.11",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3138,
    "FrequencyRank": 3628
  },
  {
    "Character": "滴",
    "Pinyin": "dī",
    "Definition": "drip; drop of water",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3165,
    "FrequencyRank": 1896
  },
  {
    "Character": "蔚",
    "Pinyin": "wèi",
    "Definition": "luxuriant, thick; ornamental",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3076,
    "FrequencyRank": 3188
  },
  {
    "Character": "裳",
    "Pinyin": "shang",
    "Definition": "clothes; skirt; beautiful",
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3099,
    "FrequencyRank": 2861
  },
  {
    "Character": "截",
    "Pinyin": "jié",
    "Definition": "cut off, stop, obstruct, intersect",
    "Radical": "戈",
    "RadicalIndex": "62.1",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3059,
    "FrequencyRank": 1552
  },
  {
    "Character": "遭",
    "Pinyin": "zāo",
    "Definition": "come across, meet with, encounter",
    "Radical": "辵",
    "RadicalIndex": "162.11",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3085,
    "FrequencyRank": 1048
  },
  {
    "Character": "瘩",
    "Pinyin": "da",
    "Definition": "pimples",
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3145,
    "FrequencyRank": 3549
  },
  {
    "Character": "酷",
    "Pinyin": "kù",
    "Definition": "strong, stimulating; very",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3087,
    "FrequencyRank": 1867
  },
  {
    "Character": "端",
    "Pinyin": "duān",
    "Definition": "end, extreme; head; beginning",
    "Radical": "立",
    "RadicalIndex": "117.9",
    "StrokeCount": 14,
    "HSK": 6,
    "GeneralStandard": 3151,
    "FrequencyRank": 916
  },
  {
    "Character": "额",
    "Pinyin": "é",
    "Definition": "forehead; tablet, plaque; fixed",
    "Radical": "頁",
    "RadicalIndex": "181.9",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3304,
    "FrequencyRank": 936
  },
  {
    "Character": "踩",
    "Pinyin": "cǎi",
    "Definition": "step on",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3243,
    "FrequencyRank": 2570
  },
  {
    "Character": "醋",
    "Pinyin": "cù",
    "Definition": "vinegar; jealousy, envy",
    "Radical": "酉",
    "RadicalIndex": "164.8",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3222,
    "FrequencyRank": 3076
  },
  {
    "Character": "慧",
    "Pinyin": "huì",
    "Definition": "bright, intelligent; intelligence",
    "Radical": "心",
    "RadicalIndex": "61.11",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3189,
    "FrequencyRank": 1505
  },
  {
    "Character": "熨",
    "Pinyin": "yùn",
    "Definition": "iron, press",
    "Radical": "火",
    "RadicalIndex": "86.11",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 6015,
    "FrequencyRank": 4498
  },
  {
    "Character": "横",
    "Pinyin": "héng",
    "Definition": "across",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3213,
    "FrequencyRank": 1330
  },
  {
    "Character": "稿",
    "Pinyin": "gǎo",
    "Definition": "draft, manuscript, rough copy",
    "Radical": "禾",
    "RadicalIndex": "115.1",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3263,
    "FrequencyRank": 1587
  },
  {
    "Character": "澈",
    "Pinyin": "chè",
    "Definition": "thoroughly, completely",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3297,
    "FrequencyRank": 3365
  },
  {
    "Character": "撤",
    "Pinyin": "chè",
    "Definition": "omit; remove; withdraw",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3203,
    "FrequencyRank": 1270
  },
  {
    "Character": "镇",
    "Pinyin": "zhèn",
    "Definition": "town, market place; suppress",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3256,
    "FrequencyRank": 1002
  },
  {
    "Character": "撑",
    "Pinyin": "chēng",
    "Definition": "prop up, support; brace",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3196,
    "FrequencyRank": 2183
  },
  {
    "Character": "潜",
    "Pinyin": "qián",
    "Definition": "hide; hidden, secret, latent",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3290,
    "FrequencyRank": 1112
  },
  {
    "Character": "趟",
    "Pinyin": "tàng",
    "Definition": "time, occasion; take journey",
    "Radical": "走",
    "RadicalIndex": "156.8",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3195,
    "FrequencyRank": 2457
  },
  {
    "Character": "踢",
    "Pinyin": "tī",
    "Definition": "kick",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3241,
    "FrequencyRank": 2444
  },
  {
    "Character": "暴",
    "Pinyin": "bào",
    "Definition": "violent, brutal, tyrannical",
    "Radical": "日",
    "RadicalIndex": "72.11",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3234,
    "FrequencyRank": 1028
  },
  {
    "Character": "箭",
    "Pinyin": "jiàn",
    "Definition": "arrow; type of bamboo",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3267,
    "FrequencyRank": 1496
  },
  {
    "Character": "墨",
    "Pinyin": "mò",
    "Definition": "ink; writing",
    "Radical": "土",
    "RadicalIndex": "32.12",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3255,
    "FrequencyRank": 1493
  },
  {
    "Character": "踏",
    "Pinyin": "tà",
    "Definition": "step on, trample, tread on, walk",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": 6,
    "GeneralStandard": 3242,
    "FrequencyRank": 1753
  },
  {
    "Character": "磨",
    "Pinyin": "mó",
    "Definition": "grind; polish; rub; wear out; a millstone",
    "Radical": "石",
    "RadicalIndex": "112.11",
    "StrokeCount": 16,
    "HSK": 6,
    "GeneralStandard": 3367,
    "FrequencyRank": 1537
  },
  {
    "Character": "薪",
    "Pinyin": "xīn",
    "Definition": "fuel, firewood; salary",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": 6,
    "GeneralStandard": 3325,
    "FrequencyRank": 2167
  },
  {
    "Character": "濒",
    "Pinyin": "bīn",
    "Definition": "approach, be on verge of; near",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": 6,
    "GeneralStandard": 3377,
    "FrequencyRank": 3153
  },
  {
    "Character": "瘸",
    "Pinyin": "qué",
    "Definition": "lameness, paralysis of hands, leg",
    "Radical": "疒",
    "RadicalIndex": "104.11",
    "StrokeCount": 16,
    "HSK": 6,
    "GeneralStandard": 3369,
    "FrequencyRank": 3943
  },
  {
    "Character": "薯",
    "Pinyin": "shǔ",
    "Definition": "yam, tuber, potato",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 16,
    "HSK": 6,
    "GeneralStandard": 3321,
    "FrequencyRank": 3363
  },
  {
    "Character": "衡",
    "Pinyin": "héng",
    "Definition": "measure, weigh, judge, consider",
    "Radical": "行",
    "RadicalIndex": "144.1",
    "StrokeCount": 16,
    "HSK": 6,
    "GeneralStandard": 3363,
    "FrequencyRank": 1340
  },
  {
    "Character": "懒",
    "Pinyin": "lǎn",
    "Definition": "lazy, languid, listless",
    "Radical": "心",
    "RadicalIndex": "61.13",
    "StrokeCount": 16,
    "HSK": 6,
    "GeneralStandard": 3380,
    "FrequencyRank": 2230
  },
  {
    "Character": "融",
    "Pinyin": "róng",
    "Definition": "melt, fuse; blend, harmonize",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 16,
    "HSK": 6,
    "GeneralStandard": 3334,
    "FrequencyRank": 1225
  },
  {
    "Character": "憾",
    "Pinyin": "hàn",
    "Definition": "to regret, remorse; dissatisfied",
    "Radical": "心",
    "RadicalIndex": "61.13",
    "StrokeCount": 16,
    "HSK": 6,
    "GeneralStandard": 3381,
    "FrequencyRank": 2269
  },
  {
    "Character": "蹈",
    "Pinyin": "dǎo",
    "Definition": "stamp feet; dance",
    "Radical": "足",
    "RadicalIndex": "157.1",
    "StrokeCount": 17,
    "HSK": 6,
    "GeneralStandard": 3409,
    "FrequencyRank": 2659
  },
  {
    "Character": "蹋",
    "Pinyin": "tà",
    "Definition": "step on, tread on; stumble, slip",
    "Radical": "足",
    "RadicalIndex": "157.1",
    "StrokeCount": 17,
    "HSK": 6,
    "GeneralStandard": 3408,
    "FrequencyRank": 3678
  },
  {
    "Character": "藐",
    "Pinyin": "miǎo",
    "Definition": "disregard, slight, disdain",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 17,
    "HSK": 6,
    "GeneralStandard": 3393,
    "FrequencyRank": 3755
  },
  {
    "Character": "藏",
    "Pinyin": "cáng",
    "Definition": "hide, conceal; hoard, store up",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 17,
    "HSK": 6,
    "GeneralStandard": 3392,
    "FrequencyRank": 907
  },
  {
    "Character": "蹲",
    "Pinyin": "dūn",
    "Definition": "squat, crouch; idle about",
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": 6,
    "GeneralStandard": 3458,
    "FrequencyRank": 2497
  },
  {
    "Character": "簸",
    "Pinyin": "bǒ",
    "Definition": "winnower",
    "Radical": "竹",
    "RadicalIndex": "118.13",
    "StrokeCount": 19,
    "HSK": 6,
    "GeneralStandard": 3462,
    "FrequencyRank": 3586
  },
  {
    "Character": "爆",
    "Pinyin": "bào",
    "Definition": "crackle, pop, burst, explode",
    "Radical": "火",
    "RadicalIndex": "86.15",
    "StrokeCount": 19,
    "HSK": 6,
    "GeneralStandard": 3471,
    "FrequencyRank": 1243
  },
  {
    "Character": "耀",
    "Pinyin": "yào",
    "Definition": "shine, sparkle, dazzle; glory",
    "Radical": "羽",
    "RadicalIndex": "124.14",
    "StrokeCount": 20,
    "HSK": 6,
    "GeneralStandard": 3476,
    "FrequencyRank": 1804
  },
  {
    "Character": "露",
    "Pinyin": "lù",
    "Definition": "dew; bare, open, exposed",
    "Radical": "雨",
    "RadicalIndex": "173.12",
    "StrokeCount": 21,
    "HSK": 6,
    "GeneralStandard": 3490,
    "FrequencyRank": 841
  },
  {
    "Character": "丁",
    "Pinyin": "dīng",
    "Definition": "male adult; robust, vigorous; 4th heavenly stem",
    "Radical": "一",
    "RadicalIndex": "1.1",
    "StrokeCount": 2,
    "HSK": 7,
    "GeneralStandard": 5,
    "FrequencyRank": 1168
  },
  {
    "Character": "丫",
    "Pinyin": "yā",
    "Definition": "forked; bifurcation",
    "Radical": "丨",
    "RadicalIndex": "2.2",
    "StrokeCount": 3,
    "HSK": 7,
    "GeneralStandard": 56,
    "FrequencyRank": 2682
  },
  {
    "Character": "尸",
    "Pinyin": "shī",
    "Definition": "corpse; to impersonate the dead; to preside; KangXi radical 44",
    "Radical": "尸",
    "RadicalIndex": "44",
    "StrokeCount": 3,
    "HSK": 7,
    "GeneralStandard": 59,
    "FrequencyRank": 1517
  },
  {
    "Character": "仁",
    "Pinyin": "rén",
    "Definition": "humaneness, benevolence, kindness",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": 7,
    "GeneralStandard": 128,
    "FrequencyRank": 1360
  },
  {
    "Character": "勾",
    "Pinyin": "gōu",
    "Definition": "hook, join, connect; entice",
    "Radical": "勹",
    "RadicalIndex": "20.2",
    "StrokeCount": 4,
    "HSK": 7,
    "GeneralStandard": 158,
    "FrequencyRank": 2034
  },
  {
    "Character": "瓦",
    "Pinyin": "wǎ",
    "Definition": "tile; earthenware pottery; girl",
    "Radical": "瓦",
    "RadicalIndex": "98",
    "StrokeCount": 4,
    "HSK": 7,
    "GeneralStandard": 108,
    "FrequencyRank": 1195
  },
  {
    "Character": "凤",
    "Pinyin": "fèng",
    "Definition": "male phoenix; symbol of joy",
    "Radical": "几",
    "RadicalIndex": "16.2",
    "StrokeCount": 4,
    "HSK": 7,
    "GeneralStandard": 159,
    "FrequencyRank": 1504
  },
  {
    "Character": "仇",
    "Pinyin": "chóu",
    "Definition": "enemy, hate, hatred, enmity",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": 7,
    "GeneralStandard": 133,
    "FrequencyRank": 1536
  },
  {
    "Character": "奴",
    "Pinyin": "nú",
    "Definition": "slave, servant",
    "Radical": "女",
    "RadicalIndex": "38.2",
    "StrokeCount": 5,
    "HSK": 7,
    "GeneralStandard": 333,
    "FrequencyRank": 1402
  },
  {
    "Character": "孕",
    "Pinyin": "yùn",
    "Definition": "be pregnant, pregnancy",
    "Radical": "子",
    "RadicalIndex": "39.2",
    "StrokeCount": 5,
    "HSK": 7,
    "GeneralStandard": 338,
    "FrequencyRank": 2094
  },
  {
    "Character": "仗",
    "Pinyin": "zhàng",
    "Definition": "rely upon; protector; fight; war, weaponry",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": 7,
    "GeneralStandard": 270,
    "FrequencyRank": 1712
  },
  {
    "Character": "囚",
    "Pinyin": "qiú",
    "Definition": "prisoner, convict; confine",
    "Radical": "囗",
    "RadicalIndex": "31.2",
    "StrokeCount": 5,
    "HSK": 7,
    "GeneralStandard": 261,
    "FrequencyRank": 2594
  },
  {
    "Character": "丛",
    "Pinyin": "cóng",
    "Definition": "bush, shrub; thicket; collection",
    "Radical": "一",
    "RadicalIndex": "1.4",
    "StrokeCount": 5,
    "HSK": 7,
    "GeneralStandard": 281,
    "FrequencyRank": 1678
  },
  {
    "Character": "饥",
    "Pinyin": "jī",
    "Definition": "hunger, starving; hungry; a famine",
    "Radical": "食",
    "RadicalIndex": "184.2",
    "StrokeCount": 5,
    "HSK": 7,
    "GeneralStandard": 299,
    "FrequencyRank": 2173
  },
  {
    "Character": "甩",
    "Pinyin": "shuǎi",
    "Definition": "throw away, discard",
    "Radical": "用",
    "RadicalIndex": "101",
    "StrokeCount": 5,
    "HSK": 7,
    "GeneralStandard": 284,
    "FrequencyRank": 2603
  },
  {
    "Character": "叨",
    "Pinyin": "dāo",
    "Definition": "talkative; quarrelous",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 7,
    "GeneralStandard": 255,
    "FrequencyRank": 2844
  },
  {
    "Character": "丝",
    "Pinyin": "sī",
    "Definition": "silk; fine thread; wire; strings",
    "Radical": "一",
    "RadicalIndex": "1.4",
    "StrokeCount": 5,
    "HSK": 7,
    "GeneralStandard": 347,
    "FrequencyRank": 1026
  },
  {
    "Character": "讽",
    "Pinyin": "fěng",
    "Definition": "recite, incant; satirize",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 559,
    "FrequencyRank": 2322
  },
  {
    "Character": "伪",
    "Pinyin": "wěi",
    "Definition": "false, counterfeit, bogus",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 476,
    "FrequencyRank": 1556
  },
  {
    "Character": "讶",
    "Pinyin": "yà",
    "Definition": "express surprise, be surprised",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 553,
    "FrequencyRank": 2162
  },
  {
    "Character": "阱",
    "Pinyin": "jǐng",
    "Definition": "pitfall, trap, snare",
    "Radical": "阜",
    "RadicalIndex": "170.4",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 3638,
    "FrequencyRank": 3224
  },
  {
    "Character": "邪",
    "Pinyin": "xié",
    "Definition": "wrong, evil, depraved, vicious, perverse, heterodox",
    "Radical": "邑",
    "RadicalIndex": "163.4",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 405,
    "FrequencyRank": 1539
  },
  {
    "Character": "妆",
    "Pinyin": "zhuāng",
    "Definition": "to adorn oneself, dress up, use make-up",
    "Radical": "女",
    "RadicalIndex": "38.3",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 512,
    "FrequencyRank": 2639
  },
  {
    "Character": "夸",
    "Pinyin": "kuā",
    "Definition": "extravagant, luxurious; handsome",
    "Radical": "大",
    "RadicalIndex": "37.3",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 395,
    "FrequencyRank": 1955
  },
  {
    "Character": "巡",
    "Pinyin": "xún",
    "Definition": "patrol, go on circuit, cruise",
    "Radical": "巛",
    "RadicalIndex": "47.4",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 598,
    "FrequencyRank": 1544
  },
  {
    "Character": "邦",
    "Pinyin": "bāng",
    "Definition": "nation, country, state",
    "Radical": "邑",
    "RadicalIndex": "163.4",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 348,
    "FrequencyRank": 1363
  },
  {
    "Character": "妄",
    "Pinyin": "wàng",
    "Definition": "absurd, foolish, reckless; false",
    "Radical": "女",
    "RadicalIndex": "38.3",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 526,
    "FrequencyRank": 2097
  },
  {
    "Character": "纤",
    "Pinyin": "xiān",
    "Definition": "fine, delicate; minute; graceful",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 591,
    "FrequencyRank": 2106
  },
  {
    "Character": "讼",
    "Pinyin": "sòng",
    "Definition": "accuse; argue, dispute; litigate",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 557,
    "FrequencyRank": 1862
  },
  {
    "Character": "芒",
    "Pinyin": "máng",
    "Definition": "Miscanthus sinensis",
    "Radical": "艸",
    "RadicalIndex": "140.3",
    "StrokeCount": 6,
    "HSK": 7,
    "GeneralStandard": 372,
    "FrequencyRank": 2251
  },
  {
    "Character": "驱",
    "Pinyin": "qū",
    "Definition": "spur a horse on; expel, drive away",
    "Radical": "馬",
    "RadicalIndex": "187.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 930,
    "FrequencyRank": 1511
  },
  {
    "Character": "盯",
    "Pinyin": "dīng",
    "Definition": "rivet gaze upon, keep eyes on",
    "Radical": "目",
    "RadicalIndex": "109.2",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 715,
    "FrequencyRank": 1906
  },
  {
    "Character": "拟",
    "Pinyin": "nǐ",
    "Definition": "draft; intend, plan, propose",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 657,
    "FrequencyRank": 1541
  },
  {
    "Character": "删",
    "Pinyin": "shān",
    "Definition": "to cut; delete",
    "Radical": "刀",
    "RadicalIndex": "18.5",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 815,
    "FrequencyRank": 2780
  },
  {
    "Character": "妒",
    "Pinyin": "dù",
    "Definition": "jealous, envious",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 923,
    "FrequencyRank": 2598
  },
  {
    "Character": "呕",
    "Pinyin": "ǒu",
    "Definition": "vomit; annoy, enrage",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 725,
    "FrequencyRank": 2855
  },
  {
    "Character": "坟",
    "Pinyin": "fén",
    "Definition": "grave, mound; bulge; bulging",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 644,
    "FrequencyRank": 2370
  },
  {
    "Character": "苍",
    "Pinyin": "cāng",
    "Definition": "blue; green",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 669,
    "FrequencyRank": 1728
  },
  {
    "Character": "纱",
    "Pinyin": "shā",
    "Definition": "gauze, thin silk; yarn, thread",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 932,
    "FrequencyRank": 2430
  },
  {
    "Character": "皂",
    "Pinyin": "zào",
    "Definition": "soap; black; menial servant",
    "Radical": "白",
    "RadicalIndex": "106.2",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 787,
    "FrequencyRank": 3087
  },
  {
    "Character": "芭",
    "Pinyin": "bā",
    "Definition": "plantain or banana palm; fragrant",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 676,
    "FrequencyRank": 3126
  },
  {
    "Character": "陈",
    "Pinyin": "chén",
    "Definition": "exhibit, display; plead; a surname",
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 914,
    "FrequencyRank": 525
  },
  {
    "Character": "吩",
    "Pinyin": "fēn",
    "Definition": "order, command, instruct",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 741,
    "FrequencyRank": 2299
  },
  {
    "Character": "芯",
    "Pinyin": "xīn",
    "Definition": "pith from rush (juncus effusus)",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 673,
    "FrequencyRank": 2539
  },
  {
    "Character": "抑",
    "Pinyin": "yì",
    "Definition": "press down, repress; curb, hinder",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 641,
    "FrequencyRank": 1748
  },
  {
    "Character": "妨",
    "Pinyin": "fáng",
    "Definition": "interfere with, impede, obstruct",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 922,
    "FrequencyRank": 1873
  },
  {
    "Character": "吻",
    "Pinyin": "wěn",
    "Definition": "kiss; the lips; coinciding",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 743,
    "FrequencyRank": 1973
  },
  {
    "Character": "妥",
    "Pinyin": "tuǒ",
    "Definition": "satisfactory, appropriate",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 799,
    "FrequencyRank": 1840
  },
  {
    "Character": "闷",
    "Pinyin": "mèn",
    "Definition": "gloomy, depressed, melancholy",
    "Radical": "門",
    "RadicalIndex": "169.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 848,
    "FrequencyRank": 1830
  },
  {
    "Character": "坑",
    "Pinyin": "kēng",
    "Definition": "pit, hole; bury, trap; harry",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 645,
    "FrequencyRank": 2242
  },
  {
    "Character": "呈",
    "Pinyin": "chéng",
    "Definition": "submit, show; appear; petition",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 716,
    "FrequencyRank": 1563
  },
  {
    "Character": "抛",
    "Pinyin": "pāo",
    "Definition": "throw (away)",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 642,
    "FrequencyRank": 1692
  },
  {
    "Character": "汰",
    "Pinyin": "tài",
    "Definition": "excessive; scour, wash out",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 858,
    "FrequencyRank": 2969
  },
  {
    "Character": "吼",
    "Pinyin": "hǒu",
    "Definition": "roar, shout; bark, howl",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 749,
    "FrequencyRank": 2151
  },
  {
    "Character": "佣",
    "Pinyin": "yōng",
    "Definition": "commission fee",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 780,
    "FrequencyRank": 2546
  },
  {
    "Character": "钉",
    "Pinyin": "dīng",
    "Definition": "nail, spike; pursue closely",
    "Radical": "金",
    "RadicalIndex": "167.2",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 758,
    "FrequencyRank": 2267
  },
  {
    "Character": "帐",
    "Pinyin": "zhàng",
    "Definition": "tent; screen, mosquito net",
    "Radical": "巾",
    "RadicalIndex": "50.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 755,
    "FrequencyRank": 1603
  },
  {
    "Character": "抚",
    "Pinyin": "fǔ",
    "Definition": "pat, console, comfort; pacify",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 613,
    "FrequencyRank": 1720
  },
  {
    "Character": "抖",
    "Pinyin": "dǒu",
    "Definition": "tremble, shake, rouse; give shake",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 648,
    "FrequencyRank": 1757
  },
  {
    "Character": "尿",
    "Pinyin": "niào",
    "Definition": "urine; urinate",
    "Radical": "尸",
    "RadicalIndex": "44.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 904,
    "FrequencyRank": 1877
  },
  {
    "Character": "佑",
    "Pinyin": "yòu",
    "Definition": "help, protect, bless",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 773,
    "FrequencyRank": 2775
  },
  {
    "Character": "尬",
    "Pinyin": "gà",
    "Definition": "limp, staggering gait; embarrass",
    "Radical": "尢",
    "RadicalIndex": "43.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 705,
    "FrequencyRank": 2729
  },
  {
    "Character": "劫",
    "Pinyin": "jié",
    "Definition": "take by force, coerce; disaster",
    "Radical": "力",
    "RadicalIndex": "19.5",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 660,
    "FrequencyRank": 1825
  },
  {
    "Character": "扯",
    "Pinyin": "chě",
    "Definition": "rip up, tear down; raise; haul",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 7,
    "GeneralStandard": 624,
    "FrequencyRank": 2084
  },
  {
    "Character": "齿",
    "Pinyin": "chǐ",
    "Definition": "teeth; gears, cogs; age; simplified form of the KangXi radical number 211",
    "Radical": "齒",
    "RadicalIndex": "211",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1068,
    "FrequencyRank": 1773
  },
  {
    "Character": "沮",
    "Pinyin": "jǔ",
    "Definition": "stop, prevent; defeated, dejected",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1247,
    "FrequencyRank": 2878
  },
  {
    "Character": "炬",
    "Pinyin": "jù",
    "Definition": "torch",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1233,
    "FrequencyRank": 3429
  },
  {
    "Character": "屉",
    "Pinyin": "tì",
    "Definition": "drawer; tray; pad; screen",
    "Radical": "尸",
    "RadicalIndex": "44.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1307,
    "FrequencyRank": 3127
  },
  {
    "Character": "卦",
    "Pinyin": "guà",
    "Definition": "fortune telling; tell fortunes",
    "Radical": "卜",
    "RadicalIndex": "25.6",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 954,
    "FrequencyRank": 3119
  },
  {
    "Character": "绎",
    "Pinyin": "yì",
    "Definition": "to unravel or unreel silk; to interpret",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1349,
    "FrequencyRank": 3015
  },
  {
    "Character": "陋",
    "Pinyin": "lòu",
    "Definition": "narrow; crude, coarse; ugly",
    "Radical": "阜",
    "RadicalIndex": "170.6",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1317,
    "FrequencyRank": 2660
  },
  {
    "Character": "拦",
    "Pinyin": "lán",
    "Definition": "obstruct, impede, bar, hinder",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 982,
    "FrequencyRank": 1996
  },
  {
    "Character": "剂",
    "Pinyin": "jì",
    "Definition": "medicinal preparation",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1216,
    "FrequencyRank": 1546
  },
  {
    "Character": "姆",
    "Pinyin": "mǔ",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1330,
    "FrequencyRank": 1061
  },
  {
    "Character": "肾",
    "Pinyin": "shèn",
    "Definition": "kidneys; testes, gizzard",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1073,
    "FrequencyRank": 2361
  },
  {
    "Character": "陌",
    "Pinyin": "mò",
    "Definition": "foot path between rice fields",
    "Radical": "阜",
    "RadicalIndex": "170.6",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1318,
    "FrequencyRank": 2243
  },
  {
    "Character": "卑",
    "Pinyin": "bēi",
    "Definition": "humble, low, inferior; despise",
    "Radical": "十",
    "RadicalIndex": "24.6",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1154,
    "FrequencyRank": 2059
  },
  {
    "Character": "氓",
    "Pinyin": "máng",
    "Definition": "people; subjects; vassals",
    "Radical": "氏",
    "RadicalIndex": "83.4",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1226,
    "FrequencyRank": 3060
  },
  {
    "Character": "贪",
    "Pinyin": "tān",
    "Definition": "be greedy, covet; covetous",
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1176,
    "FrequencyRank": 1874
  },
  {
    "Character": "拘",
    "Pinyin": "jū",
    "Definition": "restrain, seize, detain",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 976,
    "FrequencyRank": 2098
  },
  {
    "Character": "钓",
    "Pinyin": "diào",
    "Definition": "fish; fishhook; tempt, lure",
    "Radical": "金",
    "RadicalIndex": "167.3",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1120,
    "FrequencyRank": 2305
  },
  {
    "Character": "祈",
    "Pinyin": "qí",
    "Definition": "pray; entreat, beseech",
    "Radical": "示",
    "RadicalIndex": "113.4",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1295,
    "FrequencyRank": 2443
  },
  {
    "Character": "宙",
    "Pinyin": "zhòu",
    "Definition": "time as concept; infinite time",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1280,
    "FrequencyRank": 1421
  },
  {
    "Character": "郁",
    "Pinyin": "yù",
    "Definition": "sweet smelling, rich in aroma; (Cant.) to move, hit",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1043,
    "FrequencyRank": 1915
  },
  {
    "Character": "玫",
    "Pinyin": "méi",
    "Definition": "rose",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 950,
    "FrequencyRank": 2303
  },
  {
    "Character": "沾",
    "Pinyin": "zhān",
    "Definition": "moisten, wet, soak; touch",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1245,
    "FrequencyRank": 2402
  },
  {
    "Character": "弥",
    "Pinyin": "mí",
    "Definition": "extensive, full; fill; complete",
    "Radical": "弓",
    "RadicalIndex": "57.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1313,
    "FrequencyRank": 1785
  },
  {
    "Character": "咐",
    "Pinyin": "fù",
    "Definition": "instruct, order",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1098,
    "FrequencyRank": 2093
  },
  {
    "Character": "哎",
    "Pinyin": "āi",
    "Definition": "interjection of surprise",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1082,
    "FrequencyRank": 2294
  },
  {
    "Character": "隶",
    "Pinyin": "lì",
    "Definition": "subservient; servant; KangXi radical 171",
    "Radical": "隶",
    "RadicalIndex": "171",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1305,
    "FrequencyRank": 1801
  },
  {
    "Character": "歧",
    "Pinyin": "qí",
    "Definition": "fork of road; branching off",
    "Radical": "止",
    "RadicalIndex": "77.4",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1066,
    "FrequencyRank": 2071
  },
  {
    "Character": "枕",
    "Pinyin": "zhěn",
    "Definition": "pillow",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1033,
    "FrequencyRank": 2605
  },
  {
    "Character": "枚",
    "Pinyin": "méi",
    "Definition": "stalk of shrub, trunk of tree",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1023,
    "FrequencyRank": 1884
  },
  {
    "Character": "泄",
    "Pinyin": "xiè",
    "Definition": "leak; vent; flow; reveal",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1242,
    "FrequencyRank": 2010
  },
  {
    "Character": "轰",
    "Pinyin": "hōng",
    "Definition": "rumble, explosion, blast",
    "Radical": "車",
    "RadicalIndex": "159.4",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1057,
    "FrequencyRank": 1264
  },
  {
    "Character": "侣",
    "Pinyin": "lǚ",
    "Definition": "companion; associate with",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1146,
    "FrequencyRank": 2661
  },
  {
    "Character": "抹",
    "Pinyin": "mǒ",
    "Definition": "smear, apply, wipe off, erase",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 953,
    "FrequencyRank": 2087
  },
  {
    "Character": "岩",
    "Pinyin": "yán",
    "Definition": "cliff; rocks; mountain",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1106,
    "FrequencyRank": 1423
  },
  {
    "Character": "绅",
    "Pinyin": "shēn",
    "Definition": "girdle; tie, bind; gentry",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1339,
    "FrequencyRank": 2554
  },
  {
    "Character": "坪",
    "Pinyin": "píng",
    "Definition": "level ground; Japanese measure",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 960,
    "FrequencyRank": 2716
  },
  {
    "Character": "肪",
    "Pinyin": "fáng",
    "Definition": "animal fat",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1188,
    "FrequencyRank": 3005
  },
  {
    "Character": "乖",
    "Pinyin": "guāi",
    "Definition": "rebel; crafty, shrewd",
    "Radical": "丿",
    "RadicalIndex": "4.7",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1128,
    "FrequencyRank": 2470
  },
  {
    "Character": "咙",
    "Pinyin": "lóng",
    "Definition": "throat",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1089,
    "FrequencyRank": 3027
  },
  {
    "Character": "怖",
    "Pinyin": "bù",
    "Definition": "terror, fear; frighten; terrified",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1267,
    "FrequencyRank": 1776
  },
  {
    "Character": "刹",
    "Pinyin": "shā",
    "Definition": "temple, shrine, monastary",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1167,
    "FrequencyRank": 2249
  },
  {
    "Character": "泣",
    "Pinyin": "qì",
    "Definition": "cry, sob, weep",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1253,
    "FrequencyRank": 2449
  },
  {
    "Character": "罗",
    "Pinyin": "luō",
    "Definition": "net for catching birds; gauze",
    "Radical": "网",
    "RadicalIndex": "122.3",
    "StrokeCount": 8,
    "HSK": 7,
    "GeneralStandard": 1108,
    "FrequencyRank": 392
  },
  {
    "Character": "炫",
    "Pinyin": "xuàn",
    "Definition": "shine, glitter; show off, flaunt",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1659,
    "FrequencyRank": 3292
  },
  {
    "Character": "垫",
    "Pinyin": "diàn",
    "Definition": "advance money, pay for another",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1390,
    "FrequencyRank": 2597
  },
  {
    "Character": "峡",
    "Pinyin": "xiá",
    "Definition": "gorge, strait, ravine; isthmus",
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1518,
    "FrequencyRank": 1727
  },
  {
    "Character": "钩",
    "Pinyin": "gōu",
    "Definition": "hook, barb; sickle; stroke with",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1534,
    "FrequencyRank": 2254
  },
  {
    "Character": "虹",
    "Pinyin": "hóng",
    "Definition": "rainbow",
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1497,
    "FrequencyRank": 2176
  },
  {
    "Character": "挪",
    "Pinyin": "nuó",
    "Definition": "move, shift on one side",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1397,
    "FrequencyRank": 2204
  },
  {
    "Character": "荒",
    "Pinyin": "huāng",
    "Definition": "wasteland, desert; uncultivated",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1412,
    "FrequencyRank": 1328
  },
  {
    "Character": "绑",
    "Pinyin": "bǎng",
    "Definition": "bind, tie, fasten",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1752,
    "FrequencyRank": 2421
  },
  {
    "Character": "拯",
    "Pinyin": "zhěng",
    "Definition": "help, save, aid; lift, raise",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1398,
    "FrequencyRank": 2754
  },
  {
    "Character": "脉",
    "Pinyin": "mài",
    "Definition": "blood vessels, veins, arteries",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1597,
    "FrequencyRank": 1594
  },
  {
    "Character": "剃",
    "Pinyin": "tì",
    "Definition": "shave",
    "Radical": "刀",
    "RadicalIndex": "18.7",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1661,
    "FrequencyRank": 3289
  },
  {
    "Character": "姜",
    "Pinyin": "jiāng",
    "Definition": "surname; ginger",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1643,
    "FrequencyRank": 2394
  },
  {
    "Character": "姻",
    "Pinyin": "yīn",
    "Definition": "relatives by marriage",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1738,
    "FrequencyRank": 2007
  },
  {
    "Character": "柔",
    "Pinyin": "róu",
    "Definition": "soft; gentle, pliant",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1750,
    "FrequencyRank": 1573
  },
  {
    "Character": "侮",
    "Pinyin": "wǔ",
    "Definition": "insult, ridicule, disgrace",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1566,
    "FrequencyRank": 2621
  },
  {
    "Character": "矩",
    "Pinyin": "jǔ",
    "Definition": undefined,
    "Radical": "矢",
    "RadicalIndex": "111.5",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1540,
    "FrequencyRank": 2160
  },
  {
    "Character": "浑",
    "Pinyin": "hún",
    "Definition": "muddy, turbid; blend, merge, mix",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1681,
    "FrequencyRank": 1991
  },
  {
    "Character": "虐",
    "Pinyin": "nüè",
    "Definition": "cruel, harsh, oppressive",
    "Radical": "虍",
    "RadicalIndex": "141.3",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1470,
    "FrequencyRank": 2665
  },
  {
    "Character": "栋",
    "Pinyin": "dòng",
    "Definition": "main beams supporting house",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1429,
    "FrequencyRank": 2710
  },
  {
    "Character": "宪",
    "Pinyin": "xiàn",
    "Definition": "constitution, statute, law",
    "Radical": "宀",
    "RadicalIndex": "40.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1699,
    "FrequencyRank": 1484
  },
  {
    "Character": "虾",
    "Pinyin": "xiā",
    "Definition": "shrimp, prawn",
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1498,
    "FrequencyRank": 2847
  },
  {
    "Character": "勉",
    "Pinyin": "miǎn",
    "Definition": "endeavor, make effort; urge",
    "Radical": "力",
    "RadicalIndex": "19.7",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1599,
    "FrequencyRank": 2078
  },
  {
    "Character": "胎",
    "Pinyin": "tāi",
    "Definition": "unborn child, embryo, fetus",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1598,
    "FrequencyRank": 1984
  },
  {
    "Character": "叛",
    "Pinyin": "pàn",
    "Definition": "rebel; rebellion; rebellious",
    "Radical": "又",
    "RadicalIndex": "29.7",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1644,
    "FrequencyRank": 1779
  },
  {
    "Character": "兹",
    "Pinyin": "zī",
    "Definition": "now, here; this; time, year",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1653,
    "FrequencyRank": 1781
  },
  {
    "Character": "饶",
    "Pinyin": "ráo",
    "Definition": "bountiful, abundant, plentiful",
    "Radical": "食",
    "RadicalIndex": "184.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1611,
    "FrequencyRank": 2266
  },
  {
    "Character": "恼",
    "Pinyin": "nǎo",
    "Definition": "angered, filled with hate",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1691,
    "FrequencyRank": 1704
  },
  {
    "Character": "窃",
    "Pinyin": "qiè",
    "Definition": "secretly, stealthily; steal; thief",
    "Radical": "穴",
    "RadicalIndex": "116.4",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1702,
    "FrequencyRank": 2103
  },
  {
    "Character": "恒",
    "Pinyin": "héng",
    "Definition": "constant, regular, persistent",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1685,
    "FrequencyRank": 1764
  },
  {
    "Character": "狮",
    "Pinyin": "shī",
    "Definition": "lion",
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1601,
    "FrequencyRank": 2211
  },
  {
    "Character": "趴",
    "Pinyin": "pā",
    "Definition": "lying prone, leaning over",
    "Radical": "足",
    "RadicalIndex": "157.2",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1493,
    "FrequencyRank": 2977
  },
  {
    "Character": "荐",
    "Pinyin": "jiàn",
    "Definition": "repeat, reoccur; recommend",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1405,
    "FrequencyRank": 2367
  },
  {
    "Character": "姿",
    "Pinyin": "zī",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1630,
    "FrequencyRank": 1899
  },
  {
    "Character": "贱",
    "Pinyin": "jiàn",
    "Definition": "mean, low; cheap, worthless",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1520,
    "FrequencyRank": 2589
  },
  {
    "Character": "狡",
    "Pinyin": "jiǎo",
    "Definition": "cunning, deceitful, treacherous",
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1604,
    "FrequencyRank": 2670
  },
  {
    "Character": "俊",
    "Pinyin": "jùn",
    "Definition": "talented, capable; handsome",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1578,
    "FrequencyRank": 1847
  },
  {
    "Character": "钞",
    "Pinyin": "chāo",
    "Definition": "paper money, bank notes; copy",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1527,
    "FrequencyRank": 2647
  },
  {
    "Character": "哄",
    "Pinyin": "hōng",
    "Definition": "coax; beguile, cheat, deceive",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1483,
    "FrequencyRank": 2513
  },
  {
    "Character": "迹",
    "Pinyin": "jī",
    "Definition": "traces, impressions, footprints",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1623,
    "FrequencyRank": 1098
  },
  {
    "Character": "拽",
    "Pinyin": "zhuāi",
    "Definition": "drag, tow; throw; twist",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1380,
    "FrequencyRank": 3248
  },
  {
    "Character": "狱",
    "Pinyin": "yù",
    "Definition": "prison, jail; case; lawsuit",
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1605,
    "FrequencyRank": 1597
  },
  {
    "Character": "耍",
    "Pinyin": "shuǎ",
    "Definition": "frolic, play, amuse, play with",
    "Radical": "而",
    "RadicalIndex": "126.3",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1457,
    "FrequencyRank": 2260
  },
  {
    "Character": "垮",
    "Pinyin": "kuǎ",
    "Definition": "be defeated, fail, collapse",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1371,
    "FrequencyRank": 2674
  },
  {
    "Character": "钥",
    "Pinyin": "yào",
    "Definition": "key; lock",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1531,
    "FrequencyRank": 2481
  },
  {
    "Character": "皆",
    "Pinyin": "jiē",
    "Definition": "all, every, everybody",
    "Radical": "白",
    "RadicalIndex": "106.4",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1465,
    "FrequencyRank": 1419
  },
  {
    "Character": "砍",
    "Pinyin": "kǎn",
    "Definition": "hack, chop, cut, fell",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1454,
    "FrequencyRank": 2217
  },
  {
    "Character": "勋",
    "Pinyin": "xūn",
    "Definition": "meritorious deed; merits; rank",
    "Radical": "力",
    "RadicalIndex": "19.7",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1506,
    "FrequencyRank": 2349
  },
  {
    "Character": "诱",
    "Pinyin": "yòu",
    "Definition": "persuade, entice, induce; guide",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1715,
    "FrequencyRank": 1707
  },
  {
    "Character": "歪",
    "Pinyin": "wāi",
    "Definition": "slant; inclined; askewd, awry",
    "Radical": "止",
    "RadicalIndex": "77.5",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1445,
    "FrequencyRank": 2239
  },
  {
    "Character": "哀",
    "Pinyin": "āi",
    "Definition": "sad, mournful, pitiful; pity",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 7,
    "GeneralStandard": 1619,
    "FrequencyRank": 1751
  },
  {
    "Character": "捞",
    "Pinyin": "lāo",
    "Definition": "scoop out of water; dredge, fish",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1781,
    "FrequencyRank": 2508
  },
  {
    "Character": "狼",
    "Pinyin": "láng",
    "Definition": "wolf",
    "Radical": "犬",
    "RadicalIndex": "94.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2005,
    "FrequencyRank": 1708
  },
  {
    "Character": "轿",
    "Pinyin": "jiào",
    "Definition": "sedan-chair, palanquin",
    "Radical": "車",
    "RadicalIndex": "159.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1875,
    "FrequencyRank": 2447
  },
  {
    "Character": "桩",
    "Pinyin": "zhuāng",
    "Definition": "stake, post; affair, matter",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1846,
    "FrequencyRank": 2491
  },
  {
    "Character": "耽",
    "Pinyin": "dān",
    "Definition": "indulge in; be negligent",
    "Radical": "耳",
    "RadicalIndex": "128.4",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1818,
    "FrequencyRank": 2658
  },
  {
    "Character": "颁",
    "Pinyin": "bān",
    "Definition": "confer, bestow; publish, promulgate",
    "Radical": "頁",
    "RadicalIndex": "181.4",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1990,
    "FrequencyRank": 2319
  },
  {
    "Character": "挽",
    "Pinyin": "wǎn",
    "Definition": "pull; pull back, draw back",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1807,
    "FrequencyRank": 2222
  },
  {
    "Character": "晃",
    "Pinyin": "huǎng",
    "Definition": "bright, dazzling; to sway, shake",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1893,
    "FrequencyRank": 1796
  },
  {
    "Character": "秩",
    "Pinyin": "zhì",
    "Definition": "order; orderly; salary; decade",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1947,
    "FrequencyRank": 1749
  },
  {
    "Character": "砸",
    "Pinyin": "zá",
    "Definition": "smash, crush, break; pound, mash",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1863,
    "FrequencyRank": 2456
  },
  {
    "Character": "拳",
    "Pinyin": "quán",
    "Definition": "fist; various forms of boxing",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2052,
    "FrequencyRank": 1784
  },
  {
    "Character": "涂",
    "Pinyin": "tú",
    "Definition": "surname; name of certain rivers",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2075,
    "FrequencyRank": 1735
  },
  {
    "Character": "捏",
    "Pinyin": "niē",
    "Definition": "pick with fingers; knead; mold",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1793,
    "FrequencyRank": 2472
  },
  {
    "Character": "耻",
    "Pinyin": "chǐ",
    "Definition": "shame, humiliation; ashamed",
    "Radical": "耳",
    "RadicalIndex": "128.4",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1816,
    "FrequencyRank": 2169
  },
  {
    "Character": "翁",
    "Pinyin": "wēng",
    "Definition": "old man; father, father-in-law",
    "Radical": "羽",
    "RadicalIndex": "124.4",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1992,
    "FrequencyRank": 2112
  },
  {
    "Character": "烫",
    "Pinyin": "tàng",
    "Definition": "scald, heat; wash; iron clothes",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2087,
    "FrequencyRank": 2903
  },
  {
    "Character": "剥",
    "Pinyin": "bō",
    "Definition": "peel",
    "Radical": "刀",
    "RadicalIndex": "18.8",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2129,
    "FrequencyRank": 1959
  },
  {
    "Character": "兼",
    "Pinyin": "jiān",
    "Definition": "unite, combine; connect; and",
    "Radical": "八",
    "RadicalIndex": "12.8",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2056,
    "FrequencyRank": 1515
  },
  {
    "Character": "翅",
    "Pinyin": "chì",
    "Definition": "wings; fin",
    "Radical": "羽",
    "RadicalIndex": "124.4",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1859,
    "FrequencyRank": 2066
  },
  {
    "Character": "辱",
    "Pinyin": "rǔ",
    "Definition": "humiliate, insult, abuse",
    "Radical": "辰",
    "RadicalIndex": "161.3",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1860,
    "FrequencyRank": 1907
  },
  {
    "Character": "捆",
    "Pinyin": "kǔn",
    "Definition": "tie up; bind, truss up; bundle",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1796,
    "FrequencyRank": 2640
  },
  {
    "Character": "烛",
    "Pinyin": "zhú",
    "Definition": "candle, taper; shine, illuminate",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2061,
    "FrequencyRank": 2537
  },
  {
    "Character": "宰",
    "Pinyin": "zǎi",
    "Definition": "to slaughter; to rule",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2106,
    "FrequencyRank": 2080
  },
  {
    "Character": "浴",
    "Pinyin": "yù",
    "Definition": "bathe, wash; bath",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2076,
    "FrequencyRank": 2290
  },
  {
    "Character": "剖",
    "Pinyin": "pōu",
    "Definition": "split in two, slice; dissect",
    "Radical": "刀",
    "RadicalIndex": "18.8",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2042,
    "FrequencyRank": 2484
  },
  {
    "Character": "捅",
    "Pinyin": "tǒng",
    "Definition": "jab",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1813,
    "FrequencyRank": 3361
  },
  {
    "Character": "哦",
    "Pinyin": "ó",
    "Definition": "oh? really? is that so?",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1908,
    "FrequencyRank": 1913
  },
  {
    "Character": "捣",
    "Pinyin": "dǎo",
    "Definition": "hull; thresh; beat, attack",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1811,
    "FrequencyRank": 2723
  },
  {
    "Character": "唉",
    "Pinyin": "āi",
    "Definition": "alas, exclamation of surprise or pain",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1916,
    "FrequencyRank": 2091
  },
  {
    "Character": "赂",
    "Pinyin": "lù",
    "Definition": "bribe; give present",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1926,
    "FrequencyRank": 3270
  },
  {
    "Character": "贼",
    "Pinyin": "zéi",
    "Definition": "thief, traitor; kill",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1924,
    "FrequencyRank": 2001
  },
  {
    "Character": "舱",
    "Pinyin": "cāng",
    "Definition": "hold of ship; cabin",
    "Radical": "舟",
    "RadicalIndex": "137.4",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1979,
    "FrequencyRank": 1846
  },
  {
    "Character": "哼",
    "Pinyin": "hēng",
    "Definition": "hum; sing softly; groan, moan; (Cant.) an interjecting indicating disapproval",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1913,
    "FrequencyRank": 1966
  },
  {
    "Character": "倦",
    "Pinyin": "juàn",
    "Definition": "be tired of, weary",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1968,
    "FrequencyRank": 2329
  },
  {
    "Character": "凌",
    "Pinyin": "líng",
    "Definition": "pure; virtuous; insult; maltreat",
    "Radical": "冫",
    "RadicalIndex": "15.8",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2014,
    "FrequencyRank": 1731
  },
  {
    "Character": "贿",
    "Pinyin": "huì",
    "Definition": "bribe; bribes; riches, wealth",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1925,
    "FrequencyRank": 2569
  },
  {
    "Character": "脂",
    "Pinyin": "zhī",
    "Definition": "fat, grease, lard; grease",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1995,
    "FrequencyRank": 2206
  },
  {
    "Character": "爹",
    "Pinyin": "diē",
    "Definition": "father, daddy",
    "Radical": "父",
    "RadicalIndex": "88.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1985,
    "FrequencyRank": 2004
  },
  {
    "Character": "恭",
    "Pinyin": "gōng",
    "Definition": "respectful, polite, reverent",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1820,
    "FrequencyRank": 1742
  },
  {
    "Character": "匿",
    "Pinyin": "nì",
    "Definition": "hide; go into hiding",
    "Radical": "匸",
    "RadicalIndex": "23.9",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1776,
    "FrequencyRank": 2804
  },
  {
    "Character": "虔",
    "Pinyin": "qián",
    "Definition": "act with reverence; reverent",
    "Radical": "虍",
    "RadicalIndex": "141.4",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 4491,
    "FrequencyRank": 2832
  },
  {
    "Character": "逗",
    "Pinyin": "dòu",
    "Definition": "tempt, allure, arouse, stir",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1854,
    "FrequencyRank": 2587
  },
  {
    "Character": "恕",
    "Pinyin": "shù",
    "Definition": "forgive, excuse, show mercy",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2142,
    "FrequencyRank": 2314
  },
  {
    "Character": "唠",
    "Pinyin": "láo",
    "Definition": "chat, jaw, gossip, talk",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1891,
    "FrequencyRank": 3399
  },
  {
    "Character": "羞",
    "Pinyin": "xiū",
    "Definition": "disgrace, shame; ashamed; shy",
    "Radical": "羊",
    "RadicalIndex": "123.5",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 2049,
    "FrequencyRank": 2048
  },
  {
    "Character": "胳",
    "Pinyin": "gē",
    "Definition": "armpit, arms",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1997,
    "FrequencyRank": 2490
  },
  {
    "Character": "唇",
    "Pinyin": "chún",
    "Definition": "lips",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 7,
    "GeneralStandard": 1861,
    "FrequencyRank": 1880
  },
  {
    "Character": "粒",
    "Pinyin": "lì",
    "Definition": "grain; small particle",
    "Radical": "米",
    "RadicalIndex": "119.5",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2404,
    "FrequencyRank": 1714
  },
  {
    "Character": "寂",
    "Pinyin": "jì",
    "Definition": "still, silent, quiet, desolate",
    "Radical": "宀",
    "RadicalIndex": "40.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2454,
    "FrequencyRank": 1739
  },
  {
    "Character": "鹿",
    "Pinyin": "lù",
    "Definition": "deer; surname; KangXi radical 198",
    "Radical": "鹿",
    "RadicalIndex": "198",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2387,
    "FrequencyRank": 2056
  },
  {
    "Character": "猎",
    "Pinyin": "liè",
    "Definition": "hunt; field sports",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2366,
    "FrequencyRank": 1687
  },
  {
    "Character": "婪",
    "Pinyin": "lán",
    "Definition": "covet; covetous, avaricious",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2222,
    "FrequencyRank": 3112
  },
  {
    "Character": "唬",
    "Pinyin": "hǔ",
    "Definition": "to intimidate; to scare",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2281,
    "FrequencyRank": 3161
  },
  {
    "Character": "鸽",
    "Pinyin": "gē",
    "Definition": "pigeon, dove; Columba species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.6",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2349,
    "FrequencyRank": 2694
  },
  {
    "Character": "堕",
    "Pinyin": "duò",
    "Definition": "fall, sink, let fall; degenerate",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2475,
    "FrequencyRank": 2666
  },
  {
    "Character": "崩",
    "Pinyin": "bēng",
    "Definition": "rupture, split apart, collapse",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2298,
    "FrequencyRank": 2178
  },
  {
    "Character": "绳",
    "Pinyin": "shéng",
    "Definition": "rope, string, cord; control",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2491,
    "FrequencyRank": 1983
  },
  {
    "Character": "谍",
    "Pinyin": "dié",
    "Definition": "an intelligence report; to spy; spying",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2460,
    "FrequencyRank": 2391
  },
  {
    "Character": "掐",
    "Pinyin": "qiā",
    "Definition": "hold; gather with hand; choke",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2181,
    "FrequencyRank": 3494
  },
  {
    "Character": "婴",
    "Pinyin": "yīng",
    "Definition": "baby, infant; bother",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2301,
    "FrequencyRank": 2115
  },
  {
    "Character": "惕",
    "Pinyin": "tì",
    "Definition": "be cautious, careful, alert",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2443,
    "FrequencyRank": 2742
  },
  {
    "Character": "痕",
    "Pinyin": "hén",
    "Definition": "scar; mark; trace",
    "Radical": "疒",
    "RadicalIndex": "104.6",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2383,
    "FrequencyRank": 1938
  },
  {
    "Character": "隆",
    "Pinyin": "lóng",
    "Definition": "prosperous, plentiful, abundant",
    "Radical": "阜",
    "RadicalIndex": "170.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2479,
    "FrequencyRank": 1400
  },
  {
    "Character": "凰",
    "Pinyin": "huáng",
    "Definition": "female phoenix",
    "Radical": "几",
    "RadicalIndex": "16.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2368,
    "FrequencyRank": 2453
  },
  {
    "Character": "袭",
    "Pinyin": "xí",
    "Definition": "raid, attack; inherit",
    "Radical": "龍",
    "RadicalIndex": "212.6",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2246,
    "FrequencyRank": 1213
  },
  {
    "Character": "屠",
    "Pinyin": "tú",
    "Definition": "butcher, slaughter, massacre",
    "Radical": "尸",
    "RadicalIndex": "44.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2472,
    "FrequencyRank": 2171
  },
  {
    "Character": "凑",
    "Pinyin": "còu",
    "Definition": "piece together, assemble",
    "Radical": "冫",
    "RadicalIndex": "15.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2374,
    "FrequencyRank": 2193
  },
  {
    "Character": "戚",
    "Pinyin": "qī",
    "Definition": "relative; be related to; sad",
    "Radical": "戈",
    "RadicalIndex": "62.7",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2239,
    "FrequencyRank": 2316
  },
  {
    "Character": "廊",
    "Pinyin": "láng",
    "Definition": "corridor, porch, veranda",
    "Radical": "广",
    "RadicalIndex": "53.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2384,
    "FrequencyRank": 2075
  },
  {
    "Character": "笼",
    "Pinyin": "lóng",
    "Definition": "cage; cage-like basket",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2318,
    "FrequencyRank": 1928
  },
  {
    "Character": "衔",
    "Pinyin": "xián",
    "Definition": "bit; hold in mouth, bite; gag",
    "Radical": "彳",
    "RadicalIndex": "60.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2342,
    "FrequencyRank": 2328
  },
  {
    "Character": "掘",
    "Pinyin": "jué",
    "Definition": "dig, excavate; excavate cave",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2190,
    "FrequencyRank": 2212
  },
  {
    "Character": "桶",
    "Pinyin": "tǒng",
    "Definition": "pail, bucket, tub; cask, keg",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2230,
    "FrequencyRank": 2411
  },
  {
    "Character": "窒",
    "Pinyin": "zhì",
    "Definition": "stop up, obstruct",
    "Radical": "穴",
    "RadicalIndex": "116.6",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2456,
    "FrequencyRank": 3235
  },
  {
    "Character": "祷",
    "Pinyin": "dǎo",
    "Definition": "pray; entreat, beg, plead; prayer",
    "Radical": "示",
    "RadicalIndex": "113.7",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2464,
    "FrequencyRank": 2483
  },
  {
    "Character": "匙",
    "Pinyin": "shi",
    "Definition": "spoon; surname",
    "Radical": "匕",
    "RadicalIndex": "21.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2259,
    "FrequencyRank": 2476
  },
  {
    "Character": "睁",
    "Pinyin": "zhēng",
    "Definition": "open eyes; stare",
    "Radical": "目",
    "RadicalIndex": "109.6",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2261,
    "FrequencyRank": 1963
  },
  {
    "Character": "粘",
    "Pinyin": "zhān",
    "Definition": "viscous, mucous; glutinous",
    "Radical": "米",
    "RadicalIndex": "119.5",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2402,
    "FrequencyRank": 2240
  },
  {
    "Character": "祸",
    "Pinyin": "huò",
    "Definition": "misfortune, calamity, disaster",
    "Radical": "示",
    "RadicalIndex": "113.7",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2465,
    "FrequencyRank": 1870
  },
  {
    "Character": "掩",
    "Pinyin": "yǎn",
    "Definition": "to cover (with the hand); shut, conceal; ambush",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2166,
    "FrequencyRank": 1514
  },
  {
    "Character": "谎",
    "Pinyin": "huǎng",
    "Definition": "lie",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2461,
    "FrequencyRank": 2207
  },
  {
    "Character": "惧",
    "Pinyin": "jù",
    "Definition": "fear, be afraid of, dread",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2442,
    "FrequencyRank": 1616
  },
  {
    "Character": "祭",
    "Pinyin": "jì",
    "Definition": "sacrifice to, worship",
    "Radical": "示",
    "RadicalIndex": "113.6",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2371,
    "FrequencyRank": 1782
  },
  {
    "Character": "勒",
    "Pinyin": "lēi",
    "Definition": "strangle, tighten",
    "Radical": "力",
    "RadicalIndex": "19.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2200,
    "FrequencyRank": 966
  },
  {
    "Character": "淘",
    "Pinyin": "táo",
    "Definition": "wash in sieve; weed out",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2426,
    "FrequencyRank": 2499
  },
  {
    "Character": "涯",
    "Pinyin": "yá",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2414,
    "FrequencyRank": 2604
  },
  {
    "Character": "娶",
    "Pinyin": "qǔ",
    "Definition": "marry, take wife",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2197,
    "FrequencyRank": 2494
  },
  {
    "Character": "酗",
    "Pinyin": "xù",
    "Definition": "drunk, to become violent under the influence of alcohol",
    "Radical": "酉",
    "RadicalIndex": "164.4",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2237,
    "FrequencyRank": 4193
  },
  {
    "Character": "逮",
    "Pinyin": "dǎi",
    "Definition": "seize, catch; reach, arrive",
    "Radical": "辵",
    "RadicalIndex": "162.8",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2469,
    "FrequencyRank": 2216
  },
  {
    "Character": "痒",
    "Pinyin": "yǎng",
    "Definition": "itch",
    "Radical": "疒",
    "RadicalIndex": "104.6",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2382,
    "FrequencyRank": 2646
  },
  {
    "Character": "脖",
    "Pinyin": "bó",
    "Definition": "neck",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2356,
    "FrequencyRank": 2175
  },
  {
    "Character": "兽",
    "Pinyin": "shòu",
    "Definition": "beast, animal; bestial",
    "Radical": "八",
    "RadicalIndex": "12.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2407,
    "FrequencyRank": 1602
  },
  {
    "Character": "谜",
    "Pinyin": "mí",
    "Definition": "riddle, conundrum; puzzle",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": 7,
    "GeneralStandard": 2468,
    "FrequencyRank": 2471
  },
  {
    "Character": "疏",
    "Pinyin": "shū",
    "Definition": "neglect; careless, lax",
    "Radical": "疋",
    "RadicalIndex": "103.7",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2804,
    "FrequencyRank": 1897
  },
  {
    "Character": "寓",
    "Pinyin": "yù",
    "Definition": "residence; lodge; dwell",
    "Radical": "宀",
    "RadicalIndex": "40.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2782,
    "FrequencyRank": 2199
  },
  {
    "Character": "敞",
    "Pinyin": "chǎng",
    "Definition": "roomy, spacious, open, broad",
    "Radical": "攴",
    "RadicalIndex": "66.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2607,
    "FrequencyRank": 2592
  },
  {
    "Character": "揍",
    "Pinyin": "zòu",
    "Definition": "hit, beat; smash, break",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2507,
    "FrequencyRank": 3540
  },
  {
    "Character": "喉",
    "Pinyin": "hóu",
    "Definition": "throat, gullet, larynx; guttural",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2643,
    "FrequencyRank": 2096
  },
  {
    "Character": "窝",
    "Pinyin": "wō",
    "Definition": "nest; cave, den; hiding place",
    "Radical": "穴",
    "RadicalIndex": "116.7",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2784,
    "FrequencyRank": 1962
  },
  {
    "Character": "斯",
    "Pinyin": "sī",
    "Definition": "this, thus, such; to lop off; emphatic particle",
    "Radical": "斤",
    "RadicalIndex": "69.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2541,
    "FrequencyRank": 168
  },
  {
    "Character": "棍",
    "Pinyin": "gùn",
    "Definition": "stick, cudgel; scoundrel",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2572,
    "FrequencyRank": 2255
  },
  {
    "Character": "喘",
    "Pinyin": "chuǎn",
    "Definition": "pant, gasp, breathe heavily",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2642,
    "FrequencyRank": 1977
  },
  {
    "Character": "焰",
    "Pinyin": "yàn",
    "Definition": "flame, blaze; glowing, blazing",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2748,
    "FrequencyRank": 2259
  },
  {
    "Character": "堪",
    "Pinyin": "kān",
    "Definition": "adequately capable of, worthy of",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2509,
    "FrequencyRank": 1811
  },
  {
    "Character": "链",
    "Pinyin": "liàn",
    "Definition": "chain, wire, cable; chain, shack",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2658,
    "FrequencyRank": 2099
  },
  {
    "Character": "御",
    "Pinyin": "yù",
    "Definition": "drive, ride; chariot; manage",
    "Radical": "彳",
    "RadicalIndex": "60.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2704,
    "FrequencyRank": 1381
  },
  {
    "Character": "棺",
    "Pinyin": "guān",
    "Definition": "coffin",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2577,
    "FrequencyRank": 2686
  },
  {
    "Character": "搅",
    "Pinyin": "jiǎo",
    "Definition": "disturb, agitate, stir up",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2536,
    "FrequencyRank": 2679
  },
  {
    "Character": "棘",
    "Pinyin": "jí",
    "Definition": "jujube tree; thorns, brambles",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2584,
    "FrequencyRank": 2870
  },
  {
    "Character": "董",
    "Pinyin": "dǒng",
    "Definition": "direct, supervise; surname",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2551,
    "FrequencyRank": 1629
  },
  {
    "Character": "慨",
    "Pinyin": "kǎi",
    "Definition": "sigh, regret; generous",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2778,
    "FrequencyRank": 2208
  },
  {
    "Character": "惑",
    "Pinyin": "huò",
    "Definition": "confuse, mislead, baffle; doubt",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2581,
    "FrequencyRank": 1600
  },
  {
    "Character": "赋",
    "Pinyin": "fù",
    "Definition": "tax; give; endow; army; diffuse",
    "Radical": "貝",
    "RadicalIndex": "154.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2650,
    "FrequencyRank": 1747
  },
  {
    "Character": "赎",
    "Pinyin": "shú",
    "Definition": "buy, redeem; ransome; atone for",
    "Radical": "貝",
    "RadicalIndex": "154.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2652,
    "FrequencyRank": 2863
  },
  {
    "Character": "辜",
    "Pinyin": "gū",
    "Definition": "crime, criminal offense",
    "Radical": "辛",
    "RadicalIndex": "160.5",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2560,
    "FrequencyRank": 2596
  },
  {
    "Character": "毯",
    "Pinyin": "tǎn",
    "Definition": "rug, carpet, blanket",
    "Radical": "毛",
    "RadicalIndex": "82.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2672,
    "FrequencyRank": 2711
  },
  {
    "Character": "晶",
    "Pinyin": "jīng",
    "Definition": "crystal; clear, bright; radiant",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2620,
    "FrequencyRank": 1725
  },
  {
    "Character": "蛙",
    "Pinyin": "wā",
    "Definition": "frog",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2634,
    "FrequencyRank": 2904
  },
  {
    "Character": "羡",
    "Pinyin": "xiàn",
    "Definition": "envy, admire; praise; covet",
    "Radical": "羊",
    "RadicalIndex": "123.6",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2740,
    "FrequencyRank": 2732
  },
  {
    "Character": "蛮",
    "Pinyin": "mán",
    "Definition": "barbarians; barbarous, savage",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2727,
    "FrequencyRank": 2068
  },
  {
    "Character": "愧",
    "Pinyin": "kuì",
    "Definition": "ashamed, conscience-stricken",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2776,
    "FrequencyRank": 2275
  },
  {
    "Character": "猾",
    "Pinyin": "huá",
    "Definition": "crafty, cunning, shrewd; deceitful",
    "Radical": "犬",
    "RadicalIndex": "94.1",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2720,
    "FrequencyRank": 3083
  },
  {
    "Character": "葬",
    "Pinyin": "zàng",
    "Definition": "bury, inter",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2548,
    "FrequencyRank": 2060
  },
  {
    "Character": "晰",
    "Pinyin": "xī",
    "Definition": "clear, evident; clearly",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2615,
    "FrequencyRank": 2220
  },
  {
    "Character": "滋",
    "Pinyin": "zī",
    "Definition": "grow, multiply, increase; thrive",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2767,
    "FrequencyRank": 1931
  },
  {
    "Character": "割",
    "Pinyin": "gē",
    "Definition": "cut, divide, partition; cede",
    "Radical": "刀",
    "RadicalIndex": "18.1",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2779,
    "FrequencyRank": 1665
  },
  {
    "Character": "鲁",
    "Pinyin": "lǔ",
    "Definition": "foolish, stupid, rash; vulgar",
    "Radical": "魚",
    "RadicalIndex": "195.4",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2717,
    "FrequencyRank": 898
  },
  {
    "Character": "惩",
    "Pinyin": "chéng",
    "Definition": "punish, reprimand; warn",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2703,
    "FrequencyRank": 1821
  },
  {
    "Character": "谣",
    "Pinyin": "yáo",
    "Definition": "sing; folksong, ballad; rumor",
    "Radical": "言",
    "RadicalIndex": "149.1",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2796,
    "FrequencyRank": 2600
  },
  {
    "Character": "赐",
    "Pinyin": "cì",
    "Definition": "give, bestow favors; appoint",
    "Radical": "貝",
    "RadicalIndex": "154.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2653,
    "FrequencyRank": 2072
  },
  {
    "Character": "厦",
    "Pinyin": "shà",
    "Definition": "big building, mansion",
    "Radical": "厂",
    "RadicalIndex": "27.1",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2588,
    "FrequencyRank": 2264
  },
  {
    "Character": "雇",
    "Pinyin": "gù",
    "Definition": "employ, to hire",
    "Radical": "隹",
    "RadicalIndex": "172.4",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2789,
    "FrequencyRank": 1817
  },
  {
    "Character": "腕",
    "Pinyin": "wàn",
    "Definition": "wrist",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2716,
    "FrequencyRank": 2581
  },
  {
    "Character": "翘",
    "Pinyin": "qiào",
    "Definition": "turn up, lift, elevate, raise",
    "Radical": "羽",
    "RadicalIndex": "124.6",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2601,
    "FrequencyRank": 2992
  },
  {
    "Character": "惹",
    "Pinyin": "rě",
    "Definition": "irritate, vex, offend, incite",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2547,
    "FrequencyRank": 2272
  },
  {
    "Character": "溃",
    "Pinyin": "kuì",
    "Definition": "flooding river; militarily defeat",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2759,
    "FrequencyRank": 1916
  },
  {
    "Character": "雅",
    "Pinyin": "yǎ",
    "Definition": "elegant, graceful, refined",
    "Radical": "隹",
    "RadicalIndex": "172.4",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2600,
    "FrequencyRank": 1139
  },
  {
    "Character": "骚",
    "Pinyin": "sāo",
    "Definition": "harass, bother, annoy, disturb",
    "Radical": "馬",
    "RadicalIndex": "187.9",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2823,
    "FrequencyRank": 2237
  },
  {
    "Character": "趁",
    "Pinyin": "chèn",
    "Definition": "take advantage of, avail oneself",
    "Radical": "走",
    "RadicalIndex": "156.5",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2515,
    "FrequencyRank": 2109
  },
  {
    "Character": "筒",
    "Pinyin": "tǒng",
    "Definition": "thick piece of bamboo; pipe",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": 7,
    "GeneralStandard": 2685,
    "FrequencyRank": 2088
  },
  {
    "Character": "蜂",
    "Pinyin": "fēng",
    "Definition": "bee, wasp, hornet",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2925,
    "FrequencyRank": 1912
  },
  {
    "Character": "雾",
    "Pinyin": "wù",
    "Definition": "fog, mist, vapor, fine spray",
    "Radical": "雨",
    "RadicalIndex": "173.5",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2886,
    "FrequencyRank": 1670
  },
  {
    "Character": "缠",
    "Pinyin": "chán",
    "Definition": "wrap, wind around; tie, bind",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3046,
    "FrequencyRank": 2046
  },
  {
    "Character": "慎",
    "Pinyin": "shèn",
    "Definition": "act with care, be cautious",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3023,
    "FrequencyRank": 1765
  },
  {
    "Character": "嫁",
    "Pinyin": "jià",
    "Definition": "to marry, give a daughter in marriage",
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3042,
    "FrequencyRank": 2063
  },
  {
    "Character": "靴",
    "Pinyin": "xuē",
    "Definition": "boots",
    "Radical": "革",
    "RadicalIndex": "177.4",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2849,
    "FrequencyRank": 3091
  },
  {
    "Character": "寞",
    "Pinyin": "mò",
    "Definition": "silent, still, lonely, solitary",
    "Radical": "宀",
    "RadicalIndex": "40.11",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3026,
    "FrequencyRank": 2601
  },
  {
    "Character": "慈",
    "Pinyin": "cí",
    "Definition": "kind, charitable, benevolent",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3002,
    "FrequencyRank": 1487
  },
  {
    "Character": "嫉",
    "Pinyin": "jí",
    "Definition": "jealousy; be jealous of",
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3040,
    "FrequencyRank": 2690
  },
  {
    "Character": "跤",
    "Pinyin": "jiāo",
    "Definition": "stumble, fall down; wrestle",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2919,
    "FrequencyRank": 3715
  },
  {
    "Character": "塌",
    "Pinyin": "tā",
    "Definition": "fall in ruins, collapse",
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2836,
    "FrequencyRank": 2465
  },
  {
    "Character": "催",
    "Pinyin": "cuī",
    "Definition": "press, urge",
    "Radical": "人",
    "RadicalIndex": "9.11",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2959,
    "FrequencyRank": 2064
  },
  {
    "Character": "筹",
    "Pinyin": "chóu",
    "Definition": "chip, tally, token; raise money",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2952,
    "FrequencyRank": 1677
  },
  {
    "Character": "辐",
    "Pinyin": "fú",
    "Definition": "spokes of wheel",
    "Radical": "車",
    "RadicalIndex": "159.9",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2888,
    "FrequencyRank": 2428
  },
  {
    "Character": "缝",
    "Pinyin": "fèng",
    "Definition": "sew, mend",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3045,
    "FrequencyRank": 1852
  },
  {
    "Character": "稚",
    "Pinyin": "zhì",
    "Definition": "young, immature; childhood",
    "Radical": "禾",
    "RadicalIndex": "115.8",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2948,
    "FrequencyRank": 2720
  },
  {
    "Character": "遥",
    "Pinyin": "yáo",
    "Definition": "far away, distant, remote",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2967,
    "FrequencyRank": 1715
  },
  {
    "Character": "煎",
    "Pinyin": "jiān",
    "Definition": "fry in fat or oil; boil in water",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3000,
    "FrequencyRank": 2893
  },
  {
    "Character": "锯",
    "Pinyin": "jù",
    "Definition": "a saw; to saw; amputate",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2944,
    "FrequencyRank": 3078
  },
  {
    "Character": "睹",
    "Pinyin": "dǔ",
    "Definition": "look at, gaze at; observe",
    "Radical": "目",
    "RadicalIndex": "109.9",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2896,
    "FrequencyRank": 2568
  },
  {
    "Character": "携",
    "Pinyin": "xié",
    "Definition": "lead by hand, take with; carry",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2839,
    "FrequencyRank": 1964
  },
  {
    "Character": "裸",
    "Pinyin": "luǒ",
    "Definition": "bare, nude; undress, strip",
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3032,
    "FrequencyRank": 2164
  },
  {
    "Character": "魂",
    "Pinyin": "hún",
    "Definition": "soul, spirit",
    "Radical": "鬼",
    "RadicalIndex": "194.4",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2830,
    "FrequencyRank": 1348
  },
  {
    "Character": "罩",
    "Pinyin": "zhào",
    "Definition": "basket for catching fish; cover",
    "Radical": "网",
    "RadicalIndex": "122.8",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2933,
    "FrequencyRank": 1978
  },
  {
    "Character": "滩",
    "Pinyin": "tān",
    "Definition": "bank, a sandbar, shoal; rapids",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3022,
    "FrequencyRank": 2062
  },
  {
    "Character": "谨",
    "Pinyin": "jǐn",
    "Definition": "prudent, cautious; attentive",
    "Radical": "言",
    "RadicalIndex": "149.11",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3030,
    "FrequencyRank": 1917
  },
  {
    "Character": "愈",
    "Pinyin": "yù",
    "Definition": "more and more, even more",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2966,
    "FrequencyRank": 1301
  },
  {
    "Character": "搏",
    "Pinyin": "bó",
    "Definition": "seize; spring upon; strike",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2835,
    "FrequencyRank": 2372
  },
  {
    "Character": "谬",
    "Pinyin": "miù",
    "Definition": "error, exaggeration; erroneous",
    "Radical": "言",
    "RadicalIndex": "149.11",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3034,
    "FrequencyRank": 2390
  },
  {
    "Character": "廉",
    "Pinyin": "lián",
    "Definition": "upright, honorable, honest",
    "Radical": "广",
    "RadicalIndex": "53.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2992,
    "FrequencyRank": 1818
  },
  {
    "Character": "愚",
    "Pinyin": "yú",
    "Definition": "stupid, doltish, foolish",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2905,
    "FrequencyRank": 1895
  },
  {
    "Character": "锤",
    "Pinyin": "chuí",
    "Definition": "balance weight on scale; hammer",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2940,
    "FrequencyRank": 2937
  },
  {
    "Character": "鄙",
    "Pinyin": "bǐ",
    "Definition": "mean; low",
    "Radical": "邑",
    "RadicalIndex": "163.11",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2903,
    "FrequencyRank": 2510
  },
  {
    "Character": "尴",
    "Pinyin": "gān",
    "Definition": "embarrassed; ill at ease",
    "Radical": "尢",
    "RadicalIndex": "43.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2883,
    "FrequencyRank": 2726
  },
  {
    "Character": "瑰",
    "Pinyin": "guī",
    "Definition": "extraordinary, fabulous; rose",
    "Radical": "玉",
    "RadicalIndex": "96.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2828,
    "FrequencyRank": 2492
  },
  {
    "Character": "署",
    "Pinyin": "shǔ",
    "Definition": "public office",
    "Radical": "网",
    "RadicalIndex": "122.8",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2930,
    "FrequencyRank": 1379
  },
  {
    "Character": "溜",
    "Pinyin": "liū",
    "Definition": "slide, glide, slip; slippery",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 3013,
    "FrequencyRank": 1923
  },
  {
    "Character": "瞄",
    "Pinyin": "miáo",
    "Definition": "take aim at; look at",
    "Radical": "目",
    "RadicalIndex": "109.9",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2898,
    "FrequencyRank": 2565
  },
  {
    "Character": "酬",
    "Pinyin": "chóu",
    "Definition": "toast; reward, recompense",
    "Radical": "酉",
    "RadicalIndex": "164.6",
    "StrokeCount": 13,
    "HSK": 7,
    "GeneralStandard": 2874,
    "FrequencyRank": 2005
  },
  {
    "Character": "慕",
    "Pinyin": "mù",
    "Definition": "long for, desire; admire",
    "Radical": "心",
    "RadicalIndex": "61.11",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3066,
    "FrequencyRank": 1990
  },
  {
    "Character": "蜜",
    "Pinyin": "mì",
    "Definition": "honey; sweet; nectar",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3175,
    "FrequencyRank": 2014
  },
  {
    "Character": "舔",
    "Pinyin": "tiǎn",
    "Definition": "lick with tongue; taste",
    "Radical": "舌",
    "RadicalIndex": "135.8",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3117,
    "FrequencyRank": 3348
  },
  {
    "Character": "隧",
    "Pinyin": "suì",
    "Definition": "tunnel, underground passageway, path to a tomb",
    "Radical": "阜",
    "RadicalIndex": "170.13",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3182,
    "FrequencyRank": 3342
  },
  {
    "Character": "膊",
    "Pinyin": "bó",
    "Definition": "shoulders, upper arms",
    "Radical": "肉",
    "RadicalIndex": "130.1",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3133,
    "FrequencyRank": 2440
  },
  {
    "Character": "蜡",
    "Pinyin": "là",
    "Definition": "wax; candle; waxy, glazed; maggot; as a non-simplified form sometimes used as an equivalent to 䄍, meaning imperial harvest",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3106,
    "FrequencyRank": 2593
  },
  {
    "Character": "漆",
    "Pinyin": "qī",
    "Definition": "varnish, lacquer, paint",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3161,
    "FrequencyRank": 2296
  },
  {
    "Character": "蝇",
    "Pinyin": "yíng",
    "Definition": "flies",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3107,
    "FrequencyRank": 2722
  },
  {
    "Character": "弊",
    "Pinyin": "bì",
    "Definition": "evil, wrong, bad; criminal",
    "Radical": "廾",
    "RadicalIndex": "55.12",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3156,
    "FrequencyRank": 2348
  },
  {
    "Character": "膀",
    "Pinyin": "bǎng",
    "Definition": "upper arm; shoulder; wing",
    "Radical": "肉",
    "RadicalIndex": "130.1",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3134,
    "FrequencyRank": 1941
  },
  {
    "Character": "熬",
    "Pinyin": "áo",
    "Definition": "cook down, to boil; endure",
    "Radical": "火",
    "RadicalIndex": "86.11",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3053,
    "FrequencyRank": 2705
  },
  {
    "Character": "魅",
    "Pinyin": "mèi",
    "Definition": "kind of forest demon, elf",
    "Radical": "鬼",
    "RadicalIndex": "194.5",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3130,
    "FrequencyRank": 2662
  },
  {
    "Character": "粹",
    "Pinyin": "cuì",
    "Definition": "pure; unadulterated; select",
    "Radical": "米",
    "RadicalIndex": "119.8",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3154,
    "FrequencyRank": 1736
  },
  {
    "Character": "磁",
    "Pinyin": "cí",
    "Definition": "magnetic; magnetism; porcelain",
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3093,
    "FrequencyRank": 1771
  },
  {
    "Character": "誓",
    "Pinyin": "shì",
    "Definition": "swear, pledge; oath",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3060,
    "FrequencyRank": 2095
  },
  {
    "Character": "髦",
    "Pinyin": "máo",
    "Definition": "flowing hair of young child",
    "Radical": "髟",
    "RadicalIndex": "190.4",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 5630,
    "FrequencyRank": 3149
  },
  {
    "Character": "熏",
    "Pinyin": "xūn",
    "Definition": "smoke, fog, vapor; smoke, cure",
    "Radical": "火",
    "RadicalIndex": "86.1",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3119,
    "FrequencyRank": 2997
  },
  {
    "Character": "墅",
    "Pinyin": "shù",
    "Definition": "villa, country house",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3102,
    "FrequencyRank": 2901
  },
  {
    "Character": "摧",
    "Pinyin": "cuī",
    "Definition": "destroy, break, injure",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3057,
    "FrequencyRank": 2166
  },
  {
    "Character": "慷",
    "Pinyin": "kāng",
    "Definition": "ardent; generous, magnanimous",
    "Radical": "心",
    "RadicalIndex": "61.11",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3170,
    "FrequencyRank": 2935
  },
  {
    "Character": "寡",
    "Pinyin": "guǎ",
    "Definition": "widowed; alone, friendless",
    "Radical": "宀",
    "RadicalIndex": "40.11",
    "StrokeCount": 14,
    "HSK": 7,
    "GeneralStandard": 3173,
    "FrequencyRank": 2268
  },
  {
    "Character": "磅",
    "Pinyin": "bàng",
    "Definition": "pound; weigh",
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3227,
    "FrequencyRank": 2941
  },
  {
    "Character": "嘱",
    "Pinyin": "zhǔ",
    "Definition": "order, tell, instruct, leave word",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3253,
    "FrequencyRank": 2156
  },
  {
    "Character": "镑",
    "Pinyin": "bàng",
    "Definition": "pound sterling",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3258,
    "FrequencyRank": 2515
  },
  {
    "Character": "嘲",
    "Pinyin": "cháo",
    "Definition": "ridicule, deride, scorn, jeer at",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3238,
    "FrequencyRank": 2325
  },
  {
    "Character": "撬",
    "Pinyin": "qiào",
    "Definition": "lift, raise; pry open",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3198,
    "FrequencyRank": 3613
  },
  {
    "Character": "磕",
    "Pinyin": "kē",
    "Definition": "hit; collide, knock into; sound",
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3225,
    "FrequencyRank": 2991
  },
  {
    "Character": "瞎",
    "Pinyin": "xiā",
    "Definition": "blind, reckless; rash",
    "Radical": "目",
    "RadicalIndex": "109.1",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3235,
    "FrequencyRank": 2395
  },
  {
    "Character": "飘",
    "Pinyin": "piāo",
    "Definition": "whirlwind, cyclone; floating",
    "Radical": "風",
    "RadicalIndex": "182.11",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3221,
    "FrequencyRank": 1527
  },
  {
    "Character": "幢",
    "Pinyin": "chuáng",
    "Definition": "carriage curtain; sun screen",
    "Radical": "巾",
    "RadicalIndex": "50.12",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3254,
    "FrequencyRank": 2566
  },
  {
    "Character": "膝",
    "Pinyin": "xī",
    "Definition": "knee",
    "Radical": "肉",
    "RadicalIndex": "130.11",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3274,
    "FrequencyRank": 2307
  },
  {
    "Character": "撕",
    "Pinyin": "sī",
    "Definition": "rip, tear; buy cloth",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3191,
    "FrequencyRank": 2334
  },
  {
    "Character": "瞒",
    "Pinyin": "mán",
    "Definition": "deceive, lie; eyes half-closed",
    "Radical": "目",
    "RadicalIndex": "109.1",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3232,
    "FrequencyRank": 2405
  },
  {
    "Character": "霉",
    "Pinyin": "méi",
    "Definition": "mildew, mold; moldy, mildewed",
    "Radical": "雨",
    "RadicalIndex": "173.7",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3231,
    "FrequencyRank": 2425
  },
  {
    "Character": "艘",
    "Pinyin": "sōu",
    "Definition": "counter for ships, vessels",
    "Radical": "舟",
    "RadicalIndex": "137.1",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3273,
    "FrequencyRank": 1470
  },
  {
    "Character": "瘤",
    "Pinyin": "liú",
    "Definition": "tumor, lump, goiter",
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3282,
    "FrequencyRank": 2141
  },
  {
    "Character": "踪",
    "Pinyin": "zōng",
    "Definition": "footprints, traces, tracks",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3244,
    "FrequencyRank": 1532
  },
  {
    "Character": "撒",
    "Pinyin": "sā",
    "Definition": "release, cast away, let go; disperse; relax",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3192,
    "FrequencyRank": 1445
  },
  {
    "Character": "鲨",
    "Pinyin": "shā",
    "Definition": "shark",
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3294,
    "FrequencyRank": 3664
  },
  {
    "Character": "憋",
    "Pinyin": "biē",
    "Definition": "to suppress inner feelings; hasty",
    "Radical": "心",
    "RadicalIndex": "61.12",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3289,
    "FrequencyRank": 3141
  },
  {
    "Character": "稽",
    "Pinyin": "jī",
    "Definition": "examine, investigate; delay",
    "Radical": "禾",
    "RadicalIndex": "115.1",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3260,
    "FrequencyRank": 2427
  },
  {
    "Character": "嘿",
    "Pinyin": "hēi",
    "Definition": "be silent, be quiet",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": 7,
    "GeneralStandard": 3252,
    "FrequencyRank": 2423
  },
  {
    "Character": "蕾",
    "Pinyin": "lěi",
    "Definition": "buds, unopened flowers",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": 7,
    "GeneralStandard": 3320,
    "FrequencyRank": 3329
  },
  {
    "Character": "穆",
    "Pinyin": "mù",
    "Definition": "majestic, solemn, reverent; calm",
    "Radical": "禾",
    "RadicalIndex": "115.11",
    "StrokeCount": 16,
    "HSK": 7,
    "GeneralStandard": 3356,
    "FrequencyRank": 1683
  },
  {
    "Character": "噪",
    "Pinyin": "zào",
    "Definition": "be noisy; chirp loudly",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 16,
    "HSK": 7,
    "GeneralStandard": 3349,
    "FrequencyRank": 2799
  },
  {
    "Character": "篷",
    "Pinyin": "péng",
    "Definition": "awning, covering; sail; boat",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 16,
    "HSK": 7,
    "GeneralStandard": 3359,
    "FrequencyRank": 2579
  },
  {
    "Character": "擅",
    "Pinyin": "shàn",
    "Definition": "monopolize; claim; arbitrarily; to dare",
    "Radical": "手",
    "RadicalIndex": "64.13",
    "StrokeCount": 16,
    "HSK": 7,
    "GeneralStandard": 3318,
    "FrequencyRank": 2377
  },
  {
    "Character": "擎",
    "Pinyin": "qíng",
    "Definition": "lift up, hold up, support",
    "Radical": "手",
    "RadicalIndex": "64.13",
    "StrokeCount": 16,
    "HSK": 7,
    "GeneralStandard": 3324,
    "FrequencyRank": 3052
  },
  {
    "Character": "撼",
    "Pinyin": "hàn",
    "Definition": "move, shake; (Cant.) to fight",
    "Radical": "手",
    "RadicalIndex": "64.13",
    "StrokeCount": 16,
    "HSK": 7,
    "GeneralStandard": 3315,
    "FrequencyRank": 2987
  },
  {
    "Character": "瘾",
    "Pinyin": "yǐn",
    "Definition": "rash; addiction, craving, habit",
    "Radical": "疒",
    "RadicalIndex": "104.11",
    "StrokeCount": 16,
    "HSK": 7,
    "GeneralStandard": 3368,
    "FrequencyRank": 3258
  },
  {
    "Character": "癌",
    "Pinyin": "ái",
    "Definition": "cancer; marmoset",
    "Radical": "疒",
    "RadicalIndex": "104.12",
    "StrokeCount": 17,
    "HSK": 7,
    "GeneralStandard": 3425,
    "FrequencyRank": 1799
  },
  {
    "Character": "爵",
    "Pinyin": "jué",
    "Definition": "feudal title or rank",
    "Radical": "爪",
    "RadicalIndex": "87.14",
    "StrokeCount": 17,
    "HSK": 7,
    "GeneralStandard": 3421,
    "FrequencyRank": 1925
  },
  {
    "Character": "鳄",
    "Pinyin": "è",
    "Definition": "alligator",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": 7,
    "GeneralStandard": 3424,
    "FrequencyRank": 3781
  },
  {
    "Character": "臂",
    "Pinyin": "bì",
    "Definition": "arm",
    "Radical": "肉",
    "RadicalIndex": "130.13",
    "StrokeCount": 17,
    "HSK": 7,
    "GeneralStandard": 3434,
    "FrequencyRank": 1688
  },
  {
    "Character": "瞬",
    "Pinyin": "shùn",
    "Definition": "wink, blink; in a wink, a flash",
    "Radical": "目",
    "RadicalIndex": "109.12",
    "StrokeCount": 17,
    "HSK": 7,
    "GeneralStandard": 3403,
    "FrequencyRank": 2114
  },
  {
    "Character": "鹰",
    "Pinyin": "yīng",
    "Definition": "falcon; Accipiter species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.13",
    "StrokeCount": 18,
    "HSK": 7,
    "GeneralStandard": 3447,
    "FrequencyRank": 1927
  },
  {
    "Character": "蹦",
    "Pinyin": "bèng",
    "Definition": "hop, leap, jump; bright",
    "Radical": "足",
    "RadicalIndex": "157.11",
    "StrokeCount": 18,
    "HSK": 7,
    "GeneralStandard": 3442,
    "FrequencyRank": 2839
  },
  {
    "Character": "戳",
    "Pinyin": "chuō",
    "Definition": "prick, stab; stamp, seal, chop",
    "Radical": "戈",
    "RadicalIndex": "62.14",
    "StrokeCount": 18,
    "HSK": 7,
    "GeneralStandard": 3451,
    "FrequencyRank": 2944
  },
  {
    "Character": "曝",
    "Pinyin": "pù",
    "Definition": "sun, air in sun, expose or dry in the sun",
    "Radical": "日",
    "RadicalIndex": "72.15",
    "StrokeCount": 19,
    "HSK": 7,
    "GeneralStandard": 3457,
    "FrequencyRank": 3431
  },
  {
    "Character": "灌",
    "Pinyin": "guàn",
    "Definition": "pour; water; irrigate, flood",
    "Radical": "水",
    "RadicalIndex": "85.18",
    "StrokeCount": 20,
    "HSK": 7,
    "GeneralStandard": 3486,
    "FrequencyRank": 2077
  },
  {
    "Character": "魔",
    "Pinyin": "mó",
    "Definition": "demon, evil spirits; magic power",
    "Radical": "鬼",
    "RadicalIndex": "194.11",
    "StrokeCount": 20,
    "HSK": 7,
    "GeneralStandard": 3484,
    "FrequencyRank": 1180
  },
  {
    "Character": "嚼",
    "Pinyin": "jué",
    "Definition": "prattle, be glib",
    "Radical": "口",
    "RadicalIndex": "30.18",
    "StrokeCount": 20,
    "HSK": 7,
    "GeneralStandard": 3479,
    "FrequencyRank": 2731
  },
  {
    "Character": "蠢",
    "Pinyin": "chǔn",
    "Definition": "wriggle; stupid; silly; fat",
    "Radical": "虫",
    "RadicalIndex": "142.15",
    "StrokeCount": 21,
    "HSK": 7,
    "GeneralStandard": 3488,
    "FrequencyRank": 2195
  },
  {
    "Character": "罐",
    "Pinyin": "guàn",
    "Definition": "jar, jug, pitcher, pot",
    "Radical": "缶",
    "RadicalIndex": "121.18",
    "StrokeCount": 23,
    "HSK": 7,
    "GeneralStandard": 3499,
    "FrequencyRank": 2410
  },
  {
    "Character": "卜",
    "Pinyin": "bo",
    "Definition": "fortune telling; prophesy",
    "Radical": "卜",
    "RadicalIndex": "25",
    "StrokeCount": 2,
    "HSK": 8,
    "GeneralStandard": 8,
    "FrequencyRank": 1979
  },
  {
    "Character": "乃",
    "Pinyin": "nǎi",
    "Definition": "then; really, indeed; as it turned out, after all; namely",
    "Radical": "丿",
    "RadicalIndex": "4.1",
    "StrokeCount": 2,
    "HSK": 8,
    "GeneralStandard": 20,
    "FrequencyRank": 1165
  },
  {
    "Character": "乞",
    "Pinyin": "qǐ",
    "Definition": "beg; request",
    "Radical": "乙",
    "RadicalIndex": "5.2",
    "StrokeCount": 3,
    "HSK": 8,
    "GeneralStandard": 42,
    "FrequencyRank": 2429
  },
  {
    "Character": "弓",
    "Pinyin": "gōng",
    "Definition": "bow; curved, arched; KangXi radical number 57",
    "Radical": "弓",
    "RadicalIndex": "57",
    "StrokeCount": 3,
    "HSK": 8,
    "GeneralStandard": 63,
    "FrequencyRank": 2229
  },
  {
    "Character": "丸",
    "Pinyin": "wán",
    "Definition": "small round object; pellet, pill",
    "Radical": "丶",
    "RadicalIndex": "3.2",
    "StrokeCount": 3,
    "HSK": 8,
    "GeneralStandard": 51,
    "FrequencyRank": 2462
  },
  {
    "Character": "犬",
    "Pinyin": "quǎn",
    "Definition": "dog; radical number 94",
    "Radical": "犬",
    "RadicalIndex": "94",
    "StrokeCount": 4,
    "HSK": 8,
    "GeneralStandard": 92,
    "FrequencyRank": 2649
  },
  {
    "Character": "爪",
    "Pinyin": "zhǎo",
    "Definition": "claw, nail, talon; animal feet",
    "Radical": "爪",
    "RadicalIndex": "87",
    "StrokeCount": 4,
    "HSK": 8,
    "GeneralStandard": 138,
    "FrequencyRank": 2363
  },
  {
    "Character": "歹",
    "Pinyin": "dǎi",
    "Definition": "bad, vicious, depraved, wicked",
    "Radical": "歹",
    "RadicalIndex": "78",
    "StrokeCount": 4,
    "HSK": 8,
    "GeneralStandard": 96,
    "FrequencyRank": 2905
  },
  {
    "Character": "勿",
    "Pinyin": "wù",
    "Definition": "must not, do not; without, never",
    "Radical": "勹",
    "RadicalIndex": "20.2",
    "StrokeCount": 4,
    "HSK": 8,
    "GeneralStandard": 152,
    "FrequencyRank": 2563
  },
  {
    "Character": "氏",
    "Pinyin": "shì",
    "Definition": "clan, family; mister",
    "Radical": "氏",
    "RadicalIndex": "83",
    "StrokeCount": 4,
    "HSK": 8,
    "GeneralStandard": 151,
    "FrequencyRank": 1500
  },
  {
    "Character": "丐",
    "Pinyin": "gài",
    "Definition": "beggar; beg; give",
    "Radical": "一",
    "RadicalIndex": "1.3",
    "StrokeCount": 4,
    "HSK": 8,
    "GeneralStandard": 84,
    "FrequencyRank": 2606
  },
  {
    "Character": "厄",
    "Pinyin": "è",
    "Definition": "adversity, difficulty, distress",
    "Radical": "厂",
    "RadicalIndex": "27.2",
    "StrokeCount": 4,
    "HSK": 8,
    "GeneralStandard": 3514,
    "FrequencyRank": 2407
  },
  {
    "Character": "艾",
    "Pinyin": "ài",
    "Definition": "artemisia, mugwort; translit.",
    "Radical": "艸",
    "RadicalIndex": "140.2",
    "StrokeCount": 5,
    "HSK": 8,
    "GeneralStandard": 207,
    "FrequencyRank": 1291
  },
  {
    "Character": "丙",
    "Pinyin": "bǐng",
    "Definition": "third; 3rd heavenly stem",
    "Radical": "一",
    "RadicalIndex": "1.4",
    "StrokeCount": 5,
    "HSK": 8,
    "GeneralStandard": 213,
    "FrequencyRank": 2645
  },
  {
    "Character": "扒",
    "Pinyin": "bā",
    "Definition": "scratch; dig up; crawl; crouch",
    "Radical": "手",
    "RadicalIndex": "64.2",
    "StrokeCount": 5,
    "HSK": 8,
    "GeneralStandard": 201,
    "FrequencyRank": 3121
  },
  {
    "Character": "驭",
    "Pinyin": "yù",
    "Definition": "drive, ride; manage, control",
    "Radical": "馬",
    "RadicalIndex": "187.2",
    "StrokeCount": 5,
    "HSK": 8,
    "GeneralStandard": 3562,
    "FrequencyRank": 3424
  },
  {
    "Character": "匆",
    "Pinyin": "cōng",
    "Definition": "hastily, in haste, hurriedly",
    "Radical": "勹",
    "RadicalIndex": "20.3",
    "StrokeCount": 5,
    "HSK": 8,
    "GeneralStandard": 289,
    "FrequencyRank": 1622
  },
  {
    "Character": "斥",
    "Pinyin": "chì",
    "Definition": "to scold, upbraid, accuse, reproach",
    "Radical": "斤",
    "RadicalIndex": "69.1",
    "StrokeCount": 5,
    "HSK": 8,
    "GeneralStandard": 278,
    "FrequencyRank": 1857
  },
  {
    "Character": "凹",
    "Pinyin": "āo",
    "Definition": "concave, hollow, depressed; a pass, valley",
    "Radical": "凵",
    "RadicalIndex": "17.3",
    "StrokeCount": 5,
    "HSK": 8,
    "GeneralStandard": 260,
    "FrequencyRank": 2862
  },
  {
    "Character": "叭",
    "Pinyin": "bā",
    "Definition": "trumpet",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 8,
    "GeneralStandard": 247,
    "FrequencyRank": 2704
  },
  {
    "Character": "仙",
    "Pinyin": "xiān",
    "Definition": "Taoist super-being, transcendent, immortal",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": 8,
    "GeneralStandard": 272,
    "FrequencyRank": 1255
  },
  {
    "Character": "叼",
    "Pinyin": "diāo",
    "Definition": "holding in mouth",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 8,
    "GeneralStandard": 252,
    "FrequencyRank": 3703
  },
  {
    "Character": "臣",
    "Pinyin": "chén",
    "Definition": "minister, statesman, official",
    "Radical": "臣",
    "RadicalIndex": "131",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 380,
    "FrequencyRank": 1138
  },
  {
    "Character": "芝",
    "Pinyin": "zhī",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 374,
    "FrequencyRank": 2150
  },
  {
    "Character": "朽",
    "Pinyin": "xiǔ",
    "Definition": "decayed, rotten; rot, decay",
    "Radical": "木",
    "RadicalIndex": "75.2",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 375,
    "FrequencyRank": 2544
  },
  {
    "Character": "匠",
    "Pinyin": "jiàng",
    "Definition": "craftsman, artisan; workman",
    "Radical": "匚",
    "RadicalIndex": "22.4",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 394,
    "FrequencyRank": 2110
  },
  {
    "Character": "尘",
    "Pinyin": "chén",
    "Definition": "dust, dirt, ashes, cinders",
    "Radical": "小",
    "RadicalIndex": "42.3",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 414,
    "FrequencyRank": 1718
  },
  {
    "Character": "扛",
    "Pinyin": "káng",
    "Definition": "carry on shoulders; lift",
    "Radical": "手",
    "RadicalIndex": "64.3",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 354,
    "FrequencyRank": 2989
  },
  {
    "Character": "迈",
    "Pinyin": "mài",
    "Definition": "take a big stride; pass by",
    "Radical": "辵",
    "RadicalIndex": "162.3",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 408,
    "FrequencyRank": 1698
  },
  {
    "Character": "乒",
    "Pinyin": "pīng",
    "Definition": "used with pong for ping pong",
    "Radical": "丿",
    "RadicalIndex": "4.5",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 456,
    "FrequencyRank": 3447
  },
  {
    "Character": "帆",
    "Pinyin": "fān",
    "Definition": "sail; boat",
    "Radical": "巾",
    "RadicalIndex": "50.3",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 437,
    "FrequencyRank": 2459
  },
  {
    "Character": "伐",
    "Pinyin": "fá",
    "Definition": "cut down, subjugate, attack",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 463,
    "FrequencyRank": 1810
  },
  {
    "Character": "兆",
    "Pinyin": "zhào",
    "Definition": "omen; million; mega; also trillion. China = million; Japan and Taiwan = trillion",
    "Radical": "儿",
    "RadicalIndex": "10.4",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 489,
    "FrequencyRank": 2246
  },
  {
    "Character": "迄",
    "Pinyin": "qì",
    "Definition": "extend, reach; until; till",
    "Radical": "辵",
    "RadicalIndex": "162.3",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 453,
    "FrequencyRank": 2549
  },
  {
    "Character": "乓",
    "Pinyin": "pāng",
    "Definition": "used with ping for ping pong",
    "Radical": "丿",
    "RadicalIndex": "4.5",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 457,
    "FrequencyRank": 3502
  },
  {
    "Character": "诀",
    "Pinyin": "jué",
    "Definition": "take leave of, bid farewell",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 562,
    "FrequencyRank": 2845
  },
  {
    "Character": "屿",
    "Pinyin": "yǔ",
    "Definition": "island",
    "Radical": "山",
    "RadicalIndex": "46.3",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 434,
    "FrequencyRank": 2636
  },
  {
    "Character": "亦",
    "Pinyin": "yì",
    "Definition": "also, too; likewise",
    "Radical": "亠",
    "RadicalIndex": "8.4",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 516,
    "FrequencyRank": 886
  },
  {
    "Character": "驯",
    "Pinyin": "xún",
    "Definition": "tame, docile, obedient",
    "Radical": "馬",
    "RadicalIndex": "187.3",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 592,
    "FrequencyRank": 2820
  },
  {
    "Character": "伏",
    "Pinyin": "fú",
    "Definition": "crouch, crawl, lie hidden, conceal",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 460,
    "FrequencyRank": 1389
  },
  {
    "Character": "吁",
    "Pinyin": "xū",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 420,
    "FrequencyRank": 2138
  },
  {
    "Character": "劣",
    "Pinyin": "liè",
    "Definition": "bad, inferior; slightly",
    "Radical": "力",
    "RadicalIndex": "19.4",
    "StrokeCount": 6,
    "HSK": 8,
    "GeneralStandard": 416,
    "FrequencyRank": 1902
  },
  {
    "Character": "罕",
    "Pinyin": "hǎn",
    "Definition": "rare, scarce; surname",
    "Radical": "网",
    "RadicalIndex": "122.3",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 895,
    "FrequencyRank": 2221
  },
  {
    "Character": "卵",
    "Pinyin": "luǎn",
    "Definition": "egg; ovum; roe; spawn",
    "Radical": "卩",
    "RadicalIndex": "26.5",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 818,
    "FrequencyRank": 2008
  },
  {
    "Character": "诈",
    "Pinyin": "zhà",
    "Definition": "cheat, defraud, swindle; trick",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 893,
    "FrequencyRank": 2403
  },
  {
    "Character": "扳",
    "Pinyin": "bān",
    "Definition": "pull; drag; right itself",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 634,
    "FrequencyRank": 3169
  },
  {
    "Character": "驳",
    "Pinyin": "bó",
    "Definition": "varicolored, variegated; mixed",
    "Radical": "馬",
    "RadicalIndex": "187.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 935,
    "FrequencyRank": 2022
  },
  {
    "Character": "囱",
    "Pinyin": "cōng",
    "Definition": "chimney",
    "Radical": "囗",
    "RadicalIndex": "31.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 790,
    "FrequencyRank": 3555
  },
  {
    "Character": "忌",
    "Pinyin": "jì",
    "Definition": "jealous, envious; fear",
    "Radical": "心",
    "RadicalIndex": "61.3",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 910,
    "FrequencyRank": 1922
  },
  {
    "Character": "纽",
    "Pinyin": "niǔ",
    "Definition": "knot; button; handle, knob; tie",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 942,
    "FrequencyRank": 1767
  },
  {
    "Character": "坠",
    "Pinyin": "zhuì",
    "Definition": "fall down, drop, sink, go to ruin",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 917,
    "FrequencyRank": 2279
  },
  {
    "Character": "呛",
    "Pinyin": "qiāng",
    "Definition": "choke by smoke; irritates nose",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 742,
    "FrequencyRank": 3418
  },
  {
    "Character": "呐",
    "Pinyin": "nà",
    "Definition": "raise voice, yell out loud, shout; stammer",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 738,
    "FrequencyRank": 2899
  },
  {
    "Character": "役",
    "Pinyin": "yì",
    "Definition": "service; a servant, laborer; to serve",
    "Radical": "彳",
    "RadicalIndex": "60.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 793,
    "FrequencyRank": 1245
  },
  {
    "Character": "君",
    "Pinyin": "jūn",
    "Definition": "sovereign, monarch, ruler, chief, prince",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 899,
    "FrequencyRank": 985
  },
  {
    "Character": "肖",
    "Pinyin": "xiào",
    "Definition": "look like, resemble, be like",
    "Radical": "肉",
    "RadicalIndex": "130.3",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 713,
    "FrequencyRank": 2019
  },
  {
    "Character": "坛",
    "Pinyin": "tán",
    "Definition": "altar; arena, examination hall",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 614,
    "FrequencyRank": 1806
  },
  {
    "Character": "杖",
    "Pinyin": "zhàng",
    "Definition": "cane, walking stick",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 683,
    "FrequencyRank": 2435
  },
  {
    "Character": "妖",
    "Pinyin": "yāo",
    "Definition": "strange, weird, supernatural",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 920,
    "FrequencyRank": 1869
  },
  {
    "Character": "灿",
    "Pinyin": "càn",
    "Definition": "vivid, illuminating; bright",
    "Radical": "火",
    "RadicalIndex": "86.3",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 852,
    "FrequencyRank": 2591
  },
  {
    "Character": "巫",
    "Pinyin": "wū",
    "Definition": "wizard, sorcerer, witch, shaman",
    "Radical": "工",
    "RadicalIndex": "48.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 686,
    "FrequencyRank": 2189
  },
  {
    "Character": "壳",
    "Pinyin": "ké",
    "Definition": "casing, shell, husk",
    "Radical": "士",
    "RadicalIndex": "33.4",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 650,
    "FrequencyRank": 1937
  },
  {
    "Character": "伺",
    "Pinyin": "cì",
    "Definition": "serve, wait upon, attend; examine",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 788,
    "FrequencyRank": 2782
  },
  {
    "Character": "龟",
    "Pinyin": "guī",
    "Definition": "turtle or tortoise; cuckold",
    "Radical": "龜",
    "RadicalIndex": "213",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 808,
    "FrequencyRank": 2392
  },
  {
    "Character": "兑",
    "Pinyin": "duì",
    "Definition": "cash; exchange",
    "Radical": "儿",
    "RadicalIndex": "10.5",
    "StrokeCount": 7,
    "HSK": 8,
    "GeneralStandard": 850,
    "FrequencyRank": 2424
  },
  {
    "Character": "拎",
    "Pinyin": "līn",
    "Definition": "to haul; to lift; to take",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 973,
    "FrequencyRank": 3380
  },
  {
    "Character": "拌",
    "Pinyin": "bàn",
    "Definition": "mix",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 984,
    "FrequencyRank": 3570
  },
  {
    "Character": "抨",
    "Pinyin": "pēng",
    "Definition": "impeach, censure; attack",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 3837,
    "FrequencyRank": 3397
  },
  {
    "Character": "觅",
    "Pinyin": "mì",
    "Definition": "seek; search",
    "Radical": "爪",
    "RadicalIndex": "87.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1173,
    "FrequencyRank": 2825
  },
  {
    "Character": "鸣",
    "Pinyin": "míng",
    "Definition": "cry of bird or animal; make sound",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1100,
    "FrequencyRank": 1680
  },
  {
    "Character": "贩",
    "Pinyin": "fàn",
    "Definition": "peddler, hawker, street merchant",
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1115,
    "FrequencyRank": 2466
  },
  {
    "Character": "牧",
    "Pinyin": "mù",
    "Definition": "tend cattle, shepherd",
    "Radical": "牛",
    "RadicalIndex": "93.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1126,
    "FrequencyRank": 1580
  },
  {
    "Character": "拣",
    "Pinyin": "jiǎn",
    "Definition": "choose; select; pick up; gather",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 961,
    "FrequencyRank": 2965
  },
  {
    "Character": "郑",
    "Pinyin": "zhèng",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1229,
    "FrequencyRank": 1132
  },
  {
    "Character": "肢",
    "Pinyin": "zhī",
    "Definition": "human limbs; animal feet",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1182,
    "FrequencyRank": 2335
  },
  {
    "Character": "虏",
    "Pinyin": "lǔ",
    "Definition": "to capture, imprison, seize; a prison",
    "Radical": "虍",
    "RadicalIndex": "141.2",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1072,
    "FrequencyRank": 2475
  },
  {
    "Character": "岳",
    "Pinyin": "yuè",
    "Definition": "mountain peak; surname",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1137,
    "FrequencyRank": 1844
  },
  {
    "Character": "沫",
    "Pinyin": "mò",
    "Definition": "froth, foam, bubbles, suds",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1239,
    "FrequencyRank": 2495
  },
  {
    "Character": "斩",
    "Pinyin": "zhǎn",
    "Definition": "cut, chop, sever; behead",
    "Radical": "車",
    "RadicalIndex": "159.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1060,
    "FrequencyRank": 2463
  },
  {
    "Character": "卓",
    "Pinyin": "zhuō",
    "Definition": "profound, brilliant, lofty",
    "Radical": "十",
    "RadicalIndex": "24.6",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1070,
    "FrequencyRank": 1942
  },
  {
    "Character": "佩",
    "Pinyin": "pèi",
    "Definition": "belt ornament, pendant; wear at waist, tie to the belt; respect",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1150,
    "FrequencyRank": 1507
  },
  {
    "Character": "泽",
    "Pinyin": "zé",
    "Definition": "marsh, swamp; grace, brilliance",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1263,
    "FrequencyRank": 951
  },
  {
    "Character": "胀",
    "Pinyin": "zhàng",
    "Definition": "swell, inflate, expand",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1184,
    "FrequencyRank": 2083
  },
  {
    "Character": "怯",
    "Pinyin": "qiè",
    "Definition": "lacking in courage, afraid",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1266,
    "FrequencyRank": 2488
  },
  {
    "Character": "苛",
    "Pinyin": "kē",
    "Definition": "small, petty; harsh, rigorous",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1001,
    "FrequencyRank": 3095
  },
  {
    "Character": "侦",
    "Pinyin": "zhēn",
    "Definition": "spy, reconnoiter; detective",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1145,
    "FrequencyRank": 1479
  },
  {
    "Character": "枉",
    "Pinyin": "wǎng",
    "Definition": "useless, in vain; bent, crooked",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1017,
    "FrequencyRank": 2918
  },
  {
    "Character": "弦",
    "Pinyin": "xián",
    "Definition": "string; hypotenuse, crescent",
    "Radical": "弓",
    "RadicalIndex": "57.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1314,
    "FrequencyRank": 2258
  },
  {
    "Character": "昆",
    "Pinyin": "kūn",
    "Definition": "elder brother; descendants",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1080,
    "FrequencyRank": 1759
  },
  {
    "Character": "帕",
    "Pinyin": "pà",
    "Definition": "turban, kerchief, veil; wrap",
    "Radical": "巾",
    "RadicalIndex": "50.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1110,
    "FrequencyRank": 1519
  },
  {
    "Character": "庞",
    "Pinyin": "páng",
    "Definition": "disorderly, messy; huge, big",
    "Radical": "广",
    "RadicalIndex": "53.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1207,
    "FrequencyRank": 1849
  },
  {
    "Character": "苗",
    "Pinyin": "miáo",
    "Definition": "sprouts; Miao nationality",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1005,
    "FrequencyRank": 1920
  },
  {
    "Character": "侈",
    "Pinyin": "chǐ",
    "Definition": "luxurious, extravagant",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1152,
    "FrequencyRank": 3081
  },
  {
    "Character": "斧",
    "Pinyin": "fǔ",
    "Definition": "axe, hatchet; chop, hew",
    "Radical": "斤",
    "RadicalIndex": "69.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1170,
    "FrequencyRank": 2771
  },
  {
    "Character": "沼",
    "Pinyin": "zhǎo",
    "Definition": "lake, fishpond, swamps",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1260,
    "FrequencyRank": 2480
  },
  {
    "Character": "帜",
    "Pinyin": "zhì",
    "Definition": "flag, pennant; sign; fasten",
    "Radical": "巾",
    "RadicalIndex": "50.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1109,
    "FrequencyRank": 2813
  },
  {
    "Character": "泌",
    "Pinyin": "mì",
    "Definition": "to seep out, excrete",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1256,
    "FrequencyRank": 2609
  },
  {
    "Character": "昂",
    "Pinyin": "áng",
    "Definition": "rise, raise; proud, bold; upright",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1090,
    "FrequencyRank": 1952
  },
  {
    "Character": "沸",
    "Pinyin": "fèi",
    "Definition": "boil, bubble up, gush",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1259,
    "FrequencyRank": 2698
  },
  {
    "Character": "炖",
    "Pinyin": "dùn",
    "Definition": "heat with fire; stew",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 4005,
    "FrequencyRank": 3857
  },
  {
    "Character": "屈",
    "Pinyin": "qū",
    "Definition": "bend, flex; bent, crooked; crouch",
    "Radical": "尸",
    "RadicalIndex": "44.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1311,
    "FrequencyRank": 1684
  },
  {
    "Character": "殴",
    "Pinyin": "ōu",
    "Definition": "beat, fight with fists, hit",
    "Radical": "殳",
    "RadicalIndex": "79.4",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1054,
    "FrequencyRank": 3354
  },
  {
    "Character": "垂",
    "Pinyin": "chuí",
    "Definition": "let down; suspend, hand; down",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1125,
    "FrequencyRank": 1592
  },
  {
    "Character": "拧",
    "Pinyin": "níng",
    "Definition": "pinch; twist, wring; determined",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 985,
    "FrequencyRank": 3178
  },
  {
    "Character": "庙",
    "Pinyin": "miào",
    "Definition": "temple, shrine; imperial court",
    "Radical": "广",
    "RadicalIndex": "53.5",
    "StrokeCount": 8,
    "HSK": 8,
    "GeneralStandard": 1210,
    "FrequencyRank": 1889
  },
  {
    "Character": "津",
    "Pinyin": "jīn",
    "Definition": "ferry; saliva; ford",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1683,
    "FrequencyRank": 1353
  },
  {
    "Character": "眨",
    "Pinyin": "zhǎ",
    "Definition": "wink",
    "Radical": "目",
    "RadicalIndex": "109.5",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1481,
    "FrequencyRank": 2371
  },
  {
    "Character": "阁",
    "Pinyin": "gé",
    "Definition": "chamber, pavilion; cabinet",
    "Radical": "門",
    "RadicalIndex": "169.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1639,
    "FrequencyRank": 1682
  },
  {
    "Character": "挟",
    "Pinyin": "xié",
    "Definition": "clasp under arm; hold to bosom",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1374,
    "FrequencyRank": 2816
  },
  {
    "Character": "烁",
    "Pinyin": "shuò",
    "Definition": "shine, glitter, sparkle",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1657,
    "FrequencyRank": 2702
  },
  {
    "Character": "绞",
    "Pinyin": "jiǎo",
    "Definition": "twist, wring; intertwine; winch",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1763,
    "FrequencyRank": 2672
  },
  {
    "Character": "契",
    "Pinyin": "qì",
    "Definition": "deed, contract, bond; engrave",
    "Radical": "大",
    "RadicalIndex": "37.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1352,
    "FrequencyRank": 1819
  },
  {
    "Character": "钙",
    "Pinyin": "gài",
    "Definition": "calcium",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1525,
    "FrequencyRank": 2951
  },
  {
    "Character": "俘",
    "Pinyin": "fú",
    "Definition": "prisoner of war; take as prisoner",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1569,
    "FrequencyRank": 2057
  },
  {
    "Character": "浇",
    "Pinyin": "jiāo",
    "Definition": "spray, water, sprinkle",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1667,
    "FrequencyRank": 2866
  },
  {
    "Character": "缸",
    "Pinyin": "gāng",
    "Definition": "earthen jug, crock, cistern",
    "Radical": "缶",
    "RadicalIndex": "121.3",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1537,
    "FrequencyRank": 2817
  },
  {
    "Character": "卸",
    "Pinyin": "xiè",
    "Definition": "lay down; retire from office",
    "Radical": "卩",
    "RadicalIndex": "26.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1536,
    "FrequencyRank": 2479
  },
  {
    "Character": "蚀",
    "Pinyin": "shí",
    "Definition": "nibble away; erode; eclipse",
    "Radical": "食",
    "RadicalIndex": "184.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1612,
    "FrequencyRank": 2625
  },
  {
    "Character": "洽",
    "Pinyin": "qià",
    "Definition": "to blend with, be in harmony; to penetrate; to cover; a river in Shenxi",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1674,
    "FrequencyRank": 3039
  },
  {
    "Character": "竖",
    "Pinyin": "shù",
    "Definition": "perpendicular, vertical; erect",
    "Radical": "立",
    "RadicalIndex": "117.4",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1473,
    "FrequencyRank": 2505
  },
  {
    "Character": "砖",
    "Pinyin": "zhuān",
    "Definition": "tile, brick",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1447,
    "FrequencyRank": 2355
  },
  {
    "Character": "殃",
    "Pinyin": "yāng",
    "Definition": "misfortune, disaster, calamity",
    "Radical": "歹",
    "RadicalIndex": "78.5",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1461,
    "FrequencyRank": 3562
  },
  {
    "Character": "畏",
    "Pinyin": "wèi",
    "Definition": "fear, dread, awe, reverence",
    "Radical": "田",
    "RadicalIndex": "102.4",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1492,
    "FrequencyRank": 2039
  },
  {
    "Character": "俄",
    "Pinyin": "é",
    "Definition": "sudden(ly), soon; Russian",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1564,
    "FrequencyRank": 975
  },
  {
    "Character": "饵",
    "Pinyin": "ěr",
    "Definition": "bait; bait, entice; dumplings",
    "Radical": "食",
    "RadicalIndex": "184.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1610,
    "FrequencyRank": 3135
  },
  {
    "Character": "削",
    "Pinyin": "xuē",
    "Definition": "scrape off, pare, trim",
    "Radical": "刀",
    "RadicalIndex": "18.7",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1475,
    "FrequencyRank": 1794
  },
  {
    "Character": "柄",
    "Pinyin": "bǐng",
    "Definition": "handle, lever, knob; authority",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1428,
    "FrequencyRank": 2241
  },
  {
    "Character": "浏",
    "Pinyin": "liú",
    "Definition": "clear; bright; whistling",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1677,
    "FrequencyRank": 2841
  },
  {
    "Character": "垒",
    "Pinyin": "lěi",
    "Definition": "rampart, military wall",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1751,
    "FrequencyRank": 2417
  },
  {
    "Character": "昧",
    "Pinyin": "mèi",
    "Definition": "obscure, dark; darken",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1477,
    "FrequencyRank": 2376
  },
  {
    "Character": "疫",
    "Pinyin": "yì",
    "Definition": "epidemic, plague, pestilence",
    "Radical": "疒",
    "RadicalIndex": "104.4",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1627,
    "FrequencyRank": 1791
  },
  {
    "Character": "咽",
    "Pinyin": "yàn",
    "Definition": "throat; pharynx",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1504,
    "FrequencyRank": 2031
  },
  {
    "Character": "眉",
    "Pinyin": "méi",
    "Definition": "eyebrows; upper margin of book",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1729,
    "FrequencyRank": 1460
  },
  {
    "Character": "哑",
    "Pinyin": "yǎ",
    "Definition": "dumb, mute; become hoarse",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1484,
    "FrequencyRank": 2368
  },
  {
    "Character": "逆",
    "Pinyin": "nì",
    "Definition": "disobey, rebel; rebel, traitor",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1652,
    "FrequencyRank": 1975
  },
  {
    "Character": "赴",
    "Pinyin": "fù",
    "Definition": "go to; attend, be present",
    "Radical": "走",
    "RadicalIndex": "156.2",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1377,
    "FrequencyRank": 1843
  },
  {
    "Character": "徊",
    "Pinyin": "huái",
    "Definition": "linger, walk to and fro, hesitain",
    "Radical": "彳",
    "RadicalIndex": "60.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1581,
    "FrequencyRank": 3003
  },
  {
    "Character": "挠",
    "Pinyin": "náo",
    "Definition": "scratch; disturb, bother; submit",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1375,
    "FrequencyRank": 2794
  },
  {
    "Character": "枯",
    "Pinyin": "kū",
    "Definition": "dried out, withered, decayed",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1427,
    "FrequencyRank": 2188
  },
  {
    "Character": "诫",
    "Pinyin": "jiè",
    "Definition": "warn, admonish; warning",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1704,
    "FrequencyRank": 2770
  },
  {
    "Character": "陨",
    "Pinyin": "yǔn",
    "Definition": "fall, slip; let fall; die",
    "Radical": "阜",
    "RadicalIndex": "170.7",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1731,
    "FrequencyRank": 3295
  },
  {
    "Character": "峙",
    "Pinyin": "zhì",
    "Definition": "stand erect, stand up; pile up",
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 4230,
    "FrequencyRank": 3046
  },
  {
    "Character": "姥",
    "Pinyin": "lǎo",
    "Definition": "maternal grandmother; midwife",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1736,
    "FrequencyRank": 2874
  },
  {
    "Character": "拴",
    "Pinyin": "shuān",
    "Definition": "bind with rope, fasten",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1385,
    "FrequencyRank": 3170
  },
  {
    "Character": "柬",
    "Pinyin": "jiǎn",
    "Definition": "letter, invitation; choose",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1442,
    "FrequencyRank": 3047
  },
  {
    "Character": "钦",
    "Pinyin": "qīn",
    "Definition": "respect, admire; respectful",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1532,
    "FrequencyRank": 2147
  },
  {
    "Character": "叙",
    "Pinyin": "xù",
    "Definition": "express, state, relate, narrate",
    "Radical": "又",
    "RadicalIndex": "29.7",
    "StrokeCount": 9,
    "HSK": 8,
    "GeneralStandard": 1586,
    "FrequencyRank": 1607
  },
  {
    "Character": "浸",
    "Pinyin": "jìn",
    "Definition": "soak, immerse, dip, percolate",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2085,
    "FrequencyRank": 2354
  },
  {
    "Character": "赁",
    "Pinyin": "lìn",
    "Definition": "rent, hire; hired person",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1965,
    "FrequencyRank": 3101
  },
  {
    "Character": "盏",
    "Pinyin": "zhǎn",
    "Definition": "small cup or container",
    "Radical": "皿",
    "RadicalIndex": "108.5",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1779,
    "FrequencyRank": 2803
  },
  {
    "Character": "唤",
    "Pinyin": "huàn",
    "Definition": "call",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1911,
    "FrequencyRank": 1835
  },
  {
    "Character": "桑",
    "Pinyin": "sāng",
    "Definition": "mulberry tree; surname",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2149,
    "FrequencyRank": 1528
  },
  {
    "Character": "窄",
    "Pinyin": "zhǎi",
    "Definition": "narrow, tight; narrow-minded",
    "Radical": "穴",
    "RadicalIndex": "116.5",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2104,
    "FrequencyRank": 2244
  },
  {
    "Character": "皱",
    "Pinyin": "zhòu",
    "Definition": "wrinkles, creases, folds",
    "Radical": "皮",
    "RadicalIndex": "107.5",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2011,
    "FrequencyRank": 1954
  },
  {
    "Character": "挚",
    "Pinyin": "zhì",
    "Definition": "sincere, warm, cordial; surname",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1808,
    "FrequencyRank": 3012
  },
  {
    "Character": "畜",
    "Pinyin": "chù",
    "Definition": "livestock, domestic animals",
    "Radical": "田",
    "RadicalIndex": "102.5",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2047,
    "FrequencyRank": 2030
  },
  {
    "Character": "悦",
    "Pinyin": "yuè",
    "Definition": "pleased, contented, gratified",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2096,
    "FrequencyRank": 1995
  },
  {
    "Character": "剔",
    "Pinyin": "tī",
    "Definition": "pick out; scrape off; scrape meat",
    "Radical": "刀",
    "RadicalIndex": "18.8",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1896,
    "FrequencyRank": 3156
  },
  {
    "Character": "宵",
    "Pinyin": "xiāo",
    "Definition": "night, evening, dark",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2100,
    "FrequencyRank": 3131
  },
  {
    "Character": "晋",
    "Pinyin": "jìn",
    "Definition": "advance, increase; promote",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1828,
    "FrequencyRank": 1645
  },
  {
    "Character": "峻",
    "Pinyin": "jùn",
    "Definition": "high, steep, towering; stern",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1923,
    "FrequencyRank": 2506
  },
  {
    "Character": "瓷",
    "Pinyin": "cí",
    "Definition": "crockery, porcelain, chinaware",
    "Radical": "瓦",
    "RadicalIndex": "98.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2038,
    "FrequencyRank": 2285
  },
  {
    "Character": "紊",
    "Pinyin": "wěn",
    "Definition": "confused, disorder",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2036,
    "FrequencyRank": 3113
  },
  {
    "Character": "涌",
    "Pinyin": "yǒng",
    "Definition": "surge up, bubble up, gush forth",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2089,
    "FrequencyRank": 1642
  },
  {
    "Character": "莽",
    "Pinyin": "mǎng",
    "Definition": "thicket, underbrush; poisonous",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1821,
    "FrequencyRank": 2714
  },
  {
    "Character": "钳",
    "Pinyin": "qián",
    "Definition": "pincers, pliers, tongs; to compress",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1929,
    "FrequencyRank": 3344
  },
  {
    "Character": "疲",
    "Pinyin": "pí",
    "Definition": "feel tired, be exhausted; weak",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2032,
    "FrequencyRank": 1778
  },
  {
    "Character": "框",
    "Pinyin": "kuāng",
    "Definition": "frame; framework; door frame",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1833,
    "FrequencyRank": 2144
  },
  {
    "Character": "蚊",
    "Pinyin": "wén",
    "Definition": "mosquito; gnat",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1901,
    "FrequencyRank": 2940
  },
  {
    "Character": "衷",
    "Pinyin": "zhōng",
    "Definition": undefined,
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2020,
    "FrequencyRank": 2157
  },
  {
    "Character": "莫",
    "Pinyin": "mò",
    "Definition": "do not, is not, can not; negative",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1824,
    "FrequencyRank": 955
  },
  {
    "Character": "陶",
    "Pinyin": "táo",
    "Definition": "pottery, ceramics",
    "Radical": "阜",
    "RadicalIndex": "170.8",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2137,
    "FrequencyRank": 1601
  },
  {
    "Character": "逢",
    "Pinyin": "féng",
    "Definition": "come upon, happen meet; flatter",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2007,
    "FrequencyRank": 2181
  },
  {
    "Character": "涕",
    "Pinyin": "tì",
    "Definition": "tear; snivel, nasal mucus",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2083,
    "FrequencyRank": 3006
  },
  {
    "Character": "捎",
    "Pinyin": "shāo",
    "Definition": "to select; to take; to carry",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1791,
    "FrequencyRank": 3603
  },
  {
    "Character": "毙",
    "Pinyin": "bì",
    "Definition": "kill; die violent death",
    "Radical": "比",
    "RadicalIndex": "81.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1878,
    "FrequencyRank": 2534
  },
  {
    "Character": "躬",
    "Pinyin": "gōng",
    "Definition": "body; personally, in person",
    "Radical": "身",
    "RadicalIndex": "158.3",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1972,
    "FrequencyRank": 2650
  },
  {
    "Character": "诽",
    "Pinyin": "fěi",
    "Definition": "slander, vilify, condemn",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2114,
    "FrequencyRank": 3541
  },
  {
    "Character": "窍",
    "Pinyin": "qiào",
    "Definition": "hole, opening, aperture",
    "Radical": "穴",
    "RadicalIndex": "116.5",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2103,
    "FrequencyRank": 3020
  },
  {
    "Character": "烘",
    "Pinyin": "hōng",
    "Definition": "bake, roast; dry by fire",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2058,
    "FrequencyRank": 3266
  },
  {
    "Character": "衰",
    "Pinyin": "shuāi",
    "Definition": "decline, falter, decrease; weaken",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2019,
    "FrequencyRank": 1702
  },
  {
    "Character": "哨",
    "Pinyin": "shào",
    "Definition": "whistle, blow whistle; chirp",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1904,
    "FrequencyRank": 2383
  },
  {
    "Character": "捍",
    "Pinyin": "hàn",
    "Definition": "ward off, guard against, defend",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1792,
    "FrequencyRank": 3255
  },
  {
    "Character": "栽",
    "Pinyin": "zāi",
    "Definition": "to cultivate, plant; to care for plants",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1782,
    "FrequencyRank": 2401
  },
  {
    "Character": "冤",
    "Pinyin": "yuān",
    "Definition": "grievance, injustice, wrong",
    "Radical": "冖",
    "RadicalIndex": "14.8",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2124,
    "FrequencyRank": 2396
  },
  {
    "Character": "恳",
    "Pinyin": "kěn",
    "Definition": "sincere, earnest, cordial",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 2130,
    "FrequencyRank": 2265
  },
  {
    "Character": "挫",
    "Pinyin": "cuò",
    "Definition": "push down; chop down; grind",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1805,
    "FrequencyRank": 2426
  },
  {
    "Character": "株",
    "Pinyin": "zhū",
    "Definition": "numerary adjunct for trees; root",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1840,
    "FrequencyRank": 2482
  },
  {
    "Character": "豹",
    "Pinyin": "bào",
    "Definition": "leopard, panther; surname",
    "Radical": "豸",
    "RadicalIndex": "153.3",
    "StrokeCount": 10,
    "HSK": 8,
    "GeneralStandard": 1989,
    "FrequencyRank": 2507
  },
  {
    "Character": "秽",
    "Pinyin": "huì",
    "Definition": "dirty, unclean; immoral, obscene",
    "Radical": "禾",
    "RadicalIndex": "115.6",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2315,
    "FrequencyRank": 3096
  },
  {
    "Character": "袱",
    "Pinyin": "fú",
    "Definition": "a piece of cloth used wrap bundles",
    "Radical": "衣",
    "RadicalIndex": "145.6",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2463,
    "FrequencyRank": 3352
  },
  {
    "Character": "绰",
    "Pinyin": "chuò",
    "Definition": "graceful, delicate; spacious",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2490,
    "FrequencyRank": 2930
  },
  {
    "Character": "敛",
    "Pinyin": "liǎn",
    "Definition": "draw back, fold back; collect",
    "Radical": "攴",
    "RadicalIndex": "66.7",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2350,
    "FrequencyRank": 2925
  },
  {
    "Character": "矫",
    "Pinyin": "jiǎo",
    "Definition": "correct, rectify, straighten out",
    "Radical": "矢",
    "RadicalIndex": "111.6",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2310,
    "FrequencyRank": 2916
  },
  {
    "Character": "涮",
    "Pinyin": "shuàn",
    "Definition": "rinse; cook or boil in juice",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2433,
    "FrequencyRank": 4527
  },
  {
    "Character": "铲",
    "Pinyin": "chǎn",
    "Definition": "spade, shovel, trowel, scoop",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2308,
    "FrequencyRank": 3057
  },
  {
    "Character": "崖",
    "Pinyin": "yá",
    "Definition": "cliff, precipice; precipitous",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2292,
    "FrequencyRank": 2247
  },
  {
    "Character": "掺",
    "Pinyin": "càn",
    "Definition": "mix, blend, adulterate",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2191,
    "FrequencyRank": 3308
  },
  {
    "Character": "掀",
    "Pinyin": "xiān",
    "Definition": "lift, raise; stir",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2176,
    "FrequencyRank": 2468
  },
  {
    "Character": "绽",
    "Pinyin": "zhàn",
    "Definition": "ripped seam, rend, crack",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2497,
    "FrequencyRank": 3130
  },
  {
    "Character": "厢",
    "Pinyin": "xiāng",
    "Definition": "side-room, wing; theatre box",
    "Radical": "厂",
    "RadicalIndex": "27.9",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2238,
    "FrequencyRank": 2467
  },
  {
    "Character": "崛",
    "Pinyin": "jué",
    "Definition": "towering, eminent; rise abruptly",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2300,
    "FrequencyRank": 3068
  },
  {
    "Character": "渗",
    "Pinyin": "shèn",
    "Definition": "soak through, infiltrate",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2437,
    "FrequencyRank": 2520
  },
  {
    "Character": "悠",
    "Pinyin": "yōu",
    "Definition": "long, far, remote, distant; liesurely",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2326,
    "FrequencyRank": 2055
  },
  {
    "Character": "焊",
    "Pinyin": "hàn",
    "Definition": "weld, solder",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2408,
    "FrequencyRank": 4087
  },
  {
    "Character": "惭",
    "Pinyin": "cán",
    "Definition": "ashamed, humiliated; shameful",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2440,
    "FrequencyRank": 2943
  },
  {
    "Character": "萝",
    "Pinyin": "luó",
    "Definition": "type of creeping plant; turnip",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2204,
    "FrequencyRank": 2745
  },
  {
    "Character": "悼",
    "Pinyin": "dào",
    "Definition": "grieve, mourn, lament; grieved",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2441,
    "FrequencyRank": 2963
  },
  {
    "Character": "淌",
    "Pinyin": "tǎng",
    "Definition": "trickle; flow down; drip",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2419,
    "FrequencyRank": 2994
  },
  {
    "Character": "淋",
    "Pinyin": "lín",
    "Definition": "drip, soak, drench; perfectly",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2413,
    "FrequencyRank": 2168
  },
  {
    "Character": "酝",
    "Pinyin": "yùn",
    "Definition": "liquor, spirits, wine; ferment",
    "Radical": "酉",
    "RadicalIndex": "164.4",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2236,
    "FrequencyRank": 3362
  },
  {
    "Character": "唾",
    "Pinyin": "tuò",
    "Definition": "spit, spit on; saliva",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2287,
    "FrequencyRank": 3040
  },
  {
    "Character": "淆",
    "Pinyin": "xiáo",
    "Definition": "confused, in disarray, mixed up",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2422,
    "FrequencyRank": 3263
  },
  {
    "Character": "奢",
    "Pinyin": "shē",
    "Definition": "extravagant, wasteful; exaggerate",
    "Radical": "大",
    "RadicalIndex": "37.9",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2242,
    "FrequencyRank": 2708
  },
  {
    "Character": "铭",
    "Pinyin": "míng",
    "Definition": "inscribe, engrave",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2307,
    "FrequencyRank": 2061
  },
  {
    "Character": "梳",
    "Pinyin": "shū",
    "Definition": "comb; brush",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2228,
    "FrequencyRank": 2717
  },
  {
    "Character": "绵",
    "Pinyin": "mián",
    "Definition": "cotton wad; wool; soft, downy",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2493,
    "FrequencyRank": 2186
  },
  {
    "Character": "捧",
    "Pinyin": "pěng",
    "Definition": "hold up in two hands",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2160,
    "FrequencyRank": 2353
  },
  {
    "Character": "捷",
    "Pinyin": "jié",
    "Definition": "win, victory, triumph",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2167,
    "FrequencyRank": 1789
  },
  {
    "Character": "颇",
    "Pinyin": "pō",
    "Definition": "lean one side; very, rather",
    "Radical": "頁",
    "RadicalIndex": "181.5",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2484,
    "FrequencyRank": 1560
  },
  {
    "Character": "淹",
    "Pinyin": "yān",
    "Definition": "drown; cover with liquid, steep",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2415,
    "FrequencyRank": 2580
  },
  {
    "Character": "掠",
    "Pinyin": "lüè",
    "Definition": "rob, ransack, plunder; pass by",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2182,
    "FrequencyRank": 1868
  },
  {
    "Character": "菇",
    "Pinyin": "gū",
    "Definition": "mushrooms",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2218,
    "FrequencyRank": 3287
  },
  {
    "Character": "聋",
    "Pinyin": "lóng",
    "Definition": "deaf",
    "Radical": "耳",
    "RadicalIndex": "128.5",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2245,
    "FrequencyRank": 2873
  },
  {
    "Character": "兜",
    "Pinyin": "dōu",
    "Definition": "pouch",
    "Radical": "儿",
    "RadicalIndex": "10.9",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2336,
    "FrequencyRank": 2512
  },
  {
    "Character": "绸",
    "Pinyin": "chóu",
    "Definition": "silk cloth, satin damask",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2495,
    "FrequencyRank": 2635
  },
  {
    "Character": "婉",
    "Pinyin": "wǎn",
    "Definition": "amiable, congenial; restrained",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2483,
    "FrequencyRank": 2798
  },
  {
    "Character": "崭",
    "Pinyin": "zhǎn",
    "Definition": "high, steep, precipitous; new",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2294,
    "FrequencyRank": 3035
  },
  {
    "Character": "绯",
    "Pinyin": "fēi",
    "Definition": "scarlet, dark red, crimson; purpl",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 5030,
    "FrequencyRank": 3862
  },
  {
    "Character": "啃",
    "Pinyin": "kěn",
    "Definition": "gnaw, chew, bite",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2275,
    "FrequencyRank": 3345
  },
  {
    "Character": "绷",
    "Pinyin": "běng",
    "Definition": "bind, draw firmly, strap",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2494,
    "FrequencyRank": 2978
  },
  {
    "Character": "萧",
    "Pinyin": "xiāo",
    "Definition": "common artemisia; dejected",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2216,
    "FrequencyRank": 1898
  },
  {
    "Character": "徘",
    "Pinyin": "pái",
    "Definition": "walk back and forth, hesitate",
    "Radical": "彳",
    "RadicalIndex": "60.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2339,
    "FrequencyRank": 3084
  },
  {
    "Character": "铝",
    "Pinyin": "lǚ",
    "Definition": "aluminum",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2305,
    "FrequencyRank": 3217
  },
  {
    "Character": "惦",
    "Pinyin": "diàn",
    "Definition": "think of, remember, miss",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2446,
    "FrequencyRank": 3752
  },
  {
    "Character": "涵",
    "Pinyin": "hán",
    "Definition": "soak, wet; tolerate, be lenient",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2434,
    "FrequencyRank": 2330
  },
  {
    "Character": "衅",
    "Pinyin": "xìn",
    "Definition": "consecrate sacrificial vessels by smearing blood; rift",
    "Radical": "血",
    "RadicalIndex": "143.5",
    "StrokeCount": 11,
    "HSK": 8,
    "GeneralStandard": 2338,
    "FrequencyRank": 3146
  },
  {
    "Character": "焚",
    "Pinyin": "fén",
    "Definition": "burn",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2568,
    "FrequencyRank": 2498
  },
  {
    "Character": "惫",
    "Pinyin": "bèi",
    "Definition": "tired, weary, fatigued",
    "Radical": "夂",
    "RadicalIndex": "34.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2722,
    "FrequencyRank": 3007
  },
  {
    "Character": "窜",
    "Pinyin": "cuàn",
    "Definition": "run away; revise, edit; expel",
    "Radical": "穴",
    "RadicalIndex": "116.7",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2783,
    "FrequencyRank": 2461
  },
  {
    "Character": "凿",
    "Pinyin": "záo",
    "Definition": "chisel; bore, pierce",
    "Radical": "凵",
    "RadicalIndex": "17.1",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2605,
    "FrequencyRank": 2696
  },
  {
    "Character": "媚",
    "Pinyin": "mèi",
    "Definition": "charming, attractive; flatter",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2811,
    "FrequencyRank": 2291
  },
  {
    "Character": "翔",
    "Pinyin": "xiáng",
    "Definition": "soar, glide, hover; detailed",
    "Radical": "羽",
    "RadicalIndex": "124.6",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2739,
    "FrequencyRank": 2184
  },
  {
    "Character": "搂",
    "Pinyin": "lǒu",
    "Definition": "hug, embrace; drag, pull",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2535,
    "FrequencyRank": 2795
  },
  {
    "Character": "堡",
    "Pinyin": "bǎo",
    "Definition": "fort, fortress; town, village",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2693,
    "FrequencyRank": 1521
  },
  {
    "Character": "颊",
    "Pinyin": "jiá",
    "Definition": "cheeks, jaw",
    "Radical": "頁",
    "RadicalIndex": "181.6",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2597,
    "FrequencyRank": 2655
  },
  {
    "Character": "募",
    "Pinyin": "mù",
    "Definition": "levy, raise; summon; recruit",
    "Radical": "力",
    "RadicalIndex": "19.11",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2549,
    "FrequencyRank": 2657
  },
  {
    "Character": "筋",
    "Pinyin": "jīn",
    "Definition": "muscles; tendons",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2688,
    "FrequencyRank": 2252
  },
  {
    "Character": "艇",
    "Pinyin": "tǐng",
    "Definition": "small boat, dugout, punt",
    "Radical": "舟",
    "RadicalIndex": "137.7",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2706,
    "FrequencyRank": 1372
  },
  {
    "Character": "斑",
    "Pinyin": "bān",
    "Definition": "mottled, striped, freckle",
    "Radical": "文",
    "RadicalIndex": "67.8",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2505,
    "FrequencyRank": 2053
  },
  {
    "Character": "痪",
    "Pinyin": "huàn",
    "Definition": "paralysis, numbness of limbs",
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2733,
    "FrequencyRank": 3128
  },
  {
    "Character": "裕",
    "Pinyin": "yù",
    "Definition": "abundant, rich, plentiful",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2790,
    "FrequencyRank": 2023
  },
  {
    "Character": "掰",
    "Pinyin": "bāi",
    "Definition": "(Cant.) to tear, to rip",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2668,
    "FrequencyRank": 4253
  },
  {
    "Character": "琢",
    "Pinyin": "zuó",
    "Definition": "polish jade; cut jade",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2503,
    "FrequencyRank": 2921
  },
  {
    "Character": "喻",
    "Pinyin": "yù",
    "Definition": "metaphor, analogy; example; like",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2644,
    "FrequencyRank": 2273
  },
  {
    "Character": "愣",
    "Pinyin": "lèng",
    "Definition": "be in a daze",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2774,
    "FrequencyRank": 2263
  },
  {
    "Character": "溅",
    "Pinyin": "jiàn",
    "Definition": "sprinkle, spray; spill, splash",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2760,
    "FrequencyRank": 2949
  },
  {
    "Character": "酥",
    "Pinyin": "sū",
    "Definition": "butter; flaky, crispy, light, fluffy",
    "Radical": "酉",
    "RadicalIndex": "164.5",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2586,
    "FrequencyRank": 3516
  },
  {
    "Character": "揣",
    "Pinyin": "chuāi",
    "Definition": "put things under clothes",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2525,
    "FrequencyRank": 3034
  },
  {
    "Character": "渺",
    "Pinyin": "miǎo",
    "Definition": "endlessly long, boundless, vast",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2755,
    "FrequencyRank": 2966
  },
  {
    "Character": "喇",
    "Pinyin": "lǎ",
    "Definition": "horn, bugle; lama; final particle",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2621,
    "FrequencyRank": 2677
  },
  {
    "Character": "椒",
    "Pinyin": "jiāo",
    "Definition": "pepper, spices",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2570,
    "FrequencyRank": 3187
  },
  {
    "Character": "椰",
    "Pinyin": "yē",
    "Definition": "palm tree, coconut palm",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2565,
    "FrequencyRank": 3932
  },
  {
    "Character": "腔",
    "Pinyin": "qiāng",
    "Definition": "chest cavity; hollow in body",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2715,
    "FrequencyRank": 1914
  },
  {
    "Character": "葱",
    "Pinyin": "cōng",
    "Definition": "scallions, leeks, green onions",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2554,
    "FrequencyRank": 2907
  },
  {
    "Character": "猩",
    "Pinyin": "xīng",
    "Definition": "species of orangutan",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2718,
    "FrequencyRank": 2016
  },
  {
    "Character": "稀",
    "Pinyin": "xī",
    "Definition": "rare, unusual, scarce; sparse",
    "Radical": "禾",
    "RadicalIndex": "115.7",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2678,
    "FrequencyRank": 1788
  },
  {
    "Character": "揪",
    "Pinyin": "jiū",
    "Definition": "grasp with hand, pinch",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2527,
    "FrequencyRank": 3065
  },
  {
    "Character": "筝",
    "Pinyin": "zhēng",
    "Definition": "stringed musical instrument; kite",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2689,
    "FrequencyRank": 3436
  },
  {
    "Character": "惰",
    "Pinyin": "duò",
    "Definition": "indolent, careless, lazy, idle",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2772,
    "FrequencyRank": 3310
  },
  {
    "Character": "锈",
    "Pinyin": "xiù",
    "Definition": "rust, corrode",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2663,
    "FrequencyRank": 3366
  },
  {
    "Character": "锐",
    "Pinyin": "ruì",
    "Definition": "sharp, keen, acute, pointed",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2666,
    "FrequencyRank": 1851
  },
  {
    "Character": "筛",
    "Pinyin": "shāi",
    "Definition": "sieve, filter, screen; sift",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2684,
    "FrequencyRank": 3388
  },
  {
    "Character": "馈",
    "Pinyin": "kuì",
    "Definition": "offer food superior; send gift",
    "Radical": "食",
    "RadicalIndex": "184.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2724,
    "FrequencyRank": 2947
  },
  {
    "Character": "棋",
    "Pinyin": "qí",
    "Definition": "chess; any game similar to chess",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2564,
    "FrequencyRank": 2089
  },
  {
    "Character": "婿",
    "Pinyin": "xù",
    "Definition": "son-in-law; husband",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2812,
    "FrequencyRank": 3115
  },
  {
    "Character": "奥",
    "Pinyin": "ào",
    "Definition": "mysterious, obscure, profound",
    "Radical": "大",
    "RadicalIndex": "37.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2701,
    "FrequencyRank": 972
  },
  {
    "Character": "谤",
    "Pinyin": "bàng",
    "Definition": "slander, libel",
    "Radical": "言",
    "RadicalIndex": "149.1",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2797,
    "FrequencyRank": 3253
  },
  {
    "Character": "揽",
    "Pinyin": "lǎn",
    "Definition": "grasp, take hold of; monopolize",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2518,
    "FrequencyRank": 2807
  },
  {
    "Character": "缉",
    "Pinyin": "jī",
    "Definition": "to sew in close stitches",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2816,
    "FrequencyRank": 3165
  },
  {
    "Character": "堤",
    "Pinyin": "dī",
    "Definition": "dike",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2519,
    "FrequencyRank": 2464
  },
  {
    "Character": "搓",
    "Pinyin": "cuō",
    "Definition": "to rub or roll between the hands",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2534,
    "FrequencyRank": 3098
  },
  {
    "Character": "搁",
    "Pinyin": "gē",
    "Definition": "place, put, lay down; delay",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2533,
    "FrequencyRank": 2380
  },
  {
    "Character": "鹅",
    "Pinyin": "é",
    "Definition": "goose",
    "Radical": "鳥",
    "RadicalIndex": "196.7",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2674,
    "FrequencyRank": 2172
  },
  {
    "Character": "粪",
    "Pinyin": "fèn",
    "Definition": "manure, dung, shit, excrement, night soil",
    "Radical": "米",
    "RadicalIndex": "119.6",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2742,
    "FrequencyRank": 2884
  },
  {
    "Character": "揉",
    "Pinyin": "róu",
    "Definition": "rub, massage; crush by hand",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 8,
    "GeneralStandard": 2540,
    "FrequencyRank": 2818
  },
  {
    "Character": "煌",
    "Pinyin": "huáng",
    "Definition": "bright, shining, luminous",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3004,
    "FrequencyRank": 2276
  },
  {
    "Character": "痰",
    "Pinyin": "tán",
    "Definition": "phlegm, mucus, spittle",
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2991,
    "FrequencyRank": 3044
  },
  {
    "Character": "腹",
    "Pinyin": "fù",
    "Definition": "stomach, belly, abdomen; inside",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2972,
    "FrequencyRank": 1589
  },
  {
    "Character": "碑",
    "Pinyin": "bēi",
    "Definition": "stone tablet; gravestone",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2878,
    "FrequencyRank": 2203
  },
  {
    "Character": "肆",
    "Pinyin": "sì",
    "Definition": "indulge; excess; numeral four; particle meaning now, therefore; shop",
    "Radical": "聿",
    "RadicalIndex": "129.7",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2831,
    "FrequencyRank": 2445
  },
  {
    "Character": "畸",
    "Pinyin": "jī",
    "Definition": "odd, fractional, remainder, odds",
    "Radical": "田",
    "RadicalIndex": "102.8",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2912,
    "FrequencyRank": 3155
  },
  {
    "Character": "颖",
    "Pinyin": "yǐng",
    "Definition": "rice tassel; sharp point; clever",
    "Radical": "頁",
    "RadicalIndex": "181.7",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2979,
    "FrequencyRank": 2514
  },
  {
    "Character": "锡",
    "Pinyin": "xī",
    "Definition": "tin, stannum; bestow, confer",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2938,
    "FrequencyRank": 2058
  },
  {
    "Character": "摊",
    "Pinyin": "tān",
    "Definition": "spread out, open; apportion",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2844,
    "FrequencyRank": 2154
  },
  {
    "Character": "嗜",
    "Pinyin": "shì",
    "Definition": "be fond of, have weakness for",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2902,
    "FrequencyRank": 2988
  },
  {
    "Character": "腾",
    "Pinyin": "téng",
    "Definition": "fly; gallop; run; prance; rise",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2975,
    "FrequencyRank": 1635
  },
  {
    "Character": "睬",
    "Pinyin": "cǎi",
    "Definition": "notice; pay attention to",
    "Radical": "目",
    "RadicalIndex": "109.8",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2901,
    "FrequencyRank": 3250
  },
  {
    "Character": "溯",
    "Pinyin": "sù",
    "Definition": "go upstream, go against current; formerly",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3017,
    "FrequencyRank": 2762
  },
  {
    "Character": "靶",
    "Pinyin": "bǎ",
    "Definition": "target; splashboard on chariot",
    "Radical": "革",
    "RadicalIndex": "177.4",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2850,
    "FrequencyRank": 3237
  },
  {
    "Character": "嗓",
    "Pinyin": "sǎng",
    "Definition": "voice; throat",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2929,
    "FrequencyRank": 2496
  },
  {
    "Character": "蒜",
    "Pinyin": "suàn",
    "Definition": "garlic",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2847,
    "FrequencyRank": 3444
  },
  {
    "Character": "遛",
    "Pinyin": "liú",
    "Definition": "take walk, stroll",
    "Radical": "辵",
    "RadicalIndex": "162.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 5536,
    "FrequencyRank": 4552
  },
  {
    "Character": "滤",
    "Pinyin": "lǜ",
    "Definition": "strain out, filter",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3009,
    "FrequencyRank": 3249
  },
  {
    "Character": "舅",
    "Pinyin": "jiù",
    "Definition": undefined,
    "Radical": "臼",
    "RadicalIndex": "134.7",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2957,
    "FrequencyRank": 2375
  },
  {
    "Character": "廓",
    "Pinyin": "kuò",
    "Definition": "broad, wide, open, empty; to expand",
    "Radical": "广",
    "RadicalIndex": "53.11",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2989,
    "FrequencyRank": 2911
  },
  {
    "Character": "魁",
    "Pinyin": "kuí",
    "Definition": "chief; leader; best; monstrous",
    "Radical": "鬼",
    "RadicalIndex": "194.4",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2963,
    "FrequencyRank": 2599
  },
  {
    "Character": "溪",
    "Pinyin": "xī",
    "Definition": "mountain stream, creek",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3012,
    "FrequencyRank": 2194
  },
  {
    "Character": "缚",
    "Pinyin": "fù",
    "Definition": "to tie",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3044,
    "FrequencyRank": 2577
  },
  {
    "Character": "滨",
    "Pinyin": "bīn",
    "Definition": "beach, sea coast; river bank",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3018,
    "FrequencyRank": 2474
  },
  {
    "Character": "蒸",
    "Pinyin": "zhēng",
    "Definition": "steam; evaporate",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2860,
    "FrequencyRank": 2358
  },
  {
    "Character": "窥",
    "Pinyin": "kuī",
    "Definition": "peep, watch, spy on, pry",
    "Radical": "穴",
    "RadicalIndex": "116.8",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3027,
    "FrequencyRank": 2748
  },
  {
    "Character": "痹",
    "Pinyin": "bì",
    "Definition": "paralysis, numbness",
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2988,
    "FrequencyRank": 3391
  },
  {
    "Character": "寝",
    "Pinyin": "qǐn",
    "Definition": "sleep, rest; bed chamber",
    "Radical": "宀",
    "RadicalIndex": "40.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3029,
    "FrequencyRank": 2806
  },
  {
    "Character": "叠",
    "Pinyin": "dié",
    "Definition": "pile; be piled up; fold up",
    "Radical": "又",
    "RadicalIndex": "29.11",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3043,
    "FrequencyRank": 2271
  },
  {
    "Character": "嗅",
    "Pinyin": "xiù",
    "Definition": "smell, scent, sniff; olfactive",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2927,
    "FrequencyRank": 2703
  },
  {
    "Character": "痴",
    "Pinyin": "chī",
    "Definition": "foolish, stupid, dumb, silly",
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2990,
    "FrequencyRank": 2225
  },
  {
    "Character": "裔",
    "Pinyin": "yì",
    "Definition": "progeny, descendants, posterity",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 5548,
    "FrequencyRank": 2842
  },
  {
    "Character": "蓄",
    "Pinyin": "xù",
    "Definition": "store, save, hoard, gather",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2856,
    "FrequencyRank": 1985
  },
  {
    "Character": "睦",
    "Pinyin": "mù",
    "Definition": "friendly, amiable, peaceful",
    "Radical": "目",
    "RadicalIndex": "109.8",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2897,
    "FrequencyRank": 3019
  },
  {
    "Character": "滥",
    "Pinyin": "làn",
    "Definition": "flood, overflow; excessive",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3010,
    "FrequencyRank": 2282
  },
  {
    "Character": "碌",
    "Pinyin": "lù",
    "Definition": "rough, uneven, rocky; mediocre",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2882,
    "FrequencyRank": 2548
  },
  {
    "Character": "殿",
    "Pinyin": "diàn",
    "Definition": "hall; palace; temple",
    "Radical": "殳",
    "RadicalIndex": "79.9",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 3036,
    "FrequencyRank": 1555
  },
  {
    "Character": "塘",
    "Pinyin": "táng",
    "Definition": "pond; tank; dike, embankment",
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2843,
    "FrequencyRank": 2436
  },
  {
    "Character": "遣",
    "Pinyin": "qiǎn",
    "Definition": "send, dispatch; send off, exile",
    "Radical": "辵",
    "RadicalIndex": "162.1",
    "StrokeCount": 13,
    "HSK": 8,
    "GeneralStandard": 2921,
    "FrequencyRank": 1780
  },
  {
    "Character": "遮",
    "Pinyin": "zhē",
    "Definition": "cover, shield, protect",
    "Radical": "辵",
    "RadicalIndex": "162.11",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3143,
    "FrequencyRank": 2135
  },
  {
    "Character": "蔼",
    "Pinyin": "ǎi",
    "Definition": "lush; affable, friendly",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3074,
    "FrequencyRank": 3460
  },
  {
    "Character": "谱",
    "Pinyin": "pǔ",
    "Definition": "list, table; musical score",
    "Radical": "言",
    "RadicalIndex": "149.12",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3181,
    "FrequencyRank": 2274
  },
  {
    "Character": "膏",
    "Pinyin": "gāo",
    "Definition": "grease, fat; paste, ointment",
    "Radical": "肉",
    "RadicalIndex": "130.1",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3142,
    "FrequencyRank": 2673
  },
  {
    "Character": "潇",
    "Pinyin": "xiāo",
    "Definition": "sound of beating wind and rain",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3160,
    "FrequencyRank": 3216
  },
  {
    "Character": "嗽",
    "Pinyin": "sòu",
    "Definition": "cough, gargle, clear throat",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3103,
    "FrequencyRank": 2815
  },
  {
    "Character": "蔓",
    "Pinyin": "màn",
    "Definition": "creeping plants, tendrils, vines",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3069,
    "FrequencyRank": 2829
  },
  {
    "Character": "凳",
    "Pinyin": "dèng",
    "Definition": "bench; stool",
    "Radical": "几",
    "RadicalIndex": "16.12",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3186,
    "FrequencyRank": 2680
  },
  {
    "Character": "肇",
    "Pinyin": "zhào",
    "Definition": "begin, commence, originate",
    "Radical": "聿",
    "RadicalIndex": "129.8",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3178,
    "FrequencyRank": 3206
  },
  {
    "Character": "煽",
    "Pinyin": "shān",
    "Definition": "stir up, incite, agitate, provoke",
    "Radical": "火",
    "RadicalIndex": "86.1",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3159,
    "FrequencyRank": 3086
  },
  {
    "Character": "墟",
    "Pinyin": "xū",
    "Definition": "high mound; hilly countryside; wasteland",
    "Radical": "土",
    "RadicalIndex": "32.12",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3055,
    "FrequencyRank": 2958
  },
  {
    "Character": "榨",
    "Pinyin": "zhà",
    "Definition": "to press or extract juices; a press to extract juices; a vegetable",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3082,
    "FrequencyRank": 3657
  },
  {
    "Character": "嫩",
    "Pinyin": "nèn",
    "Definition": "soft, tender, delicate; young",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3183,
    "FrequencyRank": 2500
  },
  {
    "Character": "碳",
    "Pinyin": "tàn",
    "Definition": "carbon",
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3092,
    "FrequencyRank": 2740
  },
  {
    "Character": "辖",
    "Pinyin": "xiá",
    "Definition": "linchpin of wheel; control",
    "Radical": "車",
    "RadicalIndex": "159.1",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3096,
    "FrequencyRank": 1643
  },
  {
    "Character": "酿",
    "Pinyin": "niàng",
    "Definition": "brew, ferment",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3088,
    "FrequencyRank": 2764
  },
  {
    "Character": "竭",
    "Pinyin": "jié",
    "Definition": "put forth great effort; exhaust",
    "Radical": "立",
    "RadicalIndex": "117.9",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3150,
    "FrequencyRank": 1987
  },
  {
    "Character": "僚",
    "Pinyin": "liáo",
    "Definition": "companion, colleague; officials; bureaucracy; a pretty face",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3126,
    "FrequencyRank": 2234
  },
  {
    "Character": "瘟",
    "Pinyin": "wēn",
    "Definition": "epidemic, plague, pestilence",
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3146,
    "FrequencyRank": 3285
  },
  {
    "Character": "撇",
    "Pinyin": "piē",
    "Definition": "discard, abandon, throw away",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3064,
    "FrequencyRank": 2882
  },
  {
    "Character": "蔽",
    "Pinyin": "bì",
    "Definition": "cover, hide, conceal; shelter",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3073,
    "FrequencyRank": 2121
  },
  {
    "Character": "舆",
    "Pinyin": "yú",
    "Definition": "cart, palanquin, sedan chair",
    "Radical": "八",
    "RadicalIndex": "12.12",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3125,
    "FrequencyRank": 2341
  },
  {
    "Character": "熄",
    "Pinyin": "xī",
    "Definition": "put out, extinguish, quash",
    "Radical": "火",
    "RadicalIndex": "86.1",
    "StrokeCount": 14,
    "HSK": 8,
    "GeneralStandard": 3157,
    "FrequencyRank": 2858
  },
  {
    "Character": "稻",
    "Pinyin": "dào",
    "Definition": "rice growing in field, rice plant",
    "Radical": "禾",
    "RadicalIndex": "115.1",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3261,
    "FrequencyRank": 2529
  },
  {
    "Character": "澄",
    "Pinyin": "chéng",
    "Definition": "purify water by allowing sediment to settle; clear, pure",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3299,
    "FrequencyRank": 2756
  },
  {
    "Character": "劈",
    "Pinyin": "pī",
    "Definition": "cut apart, split, chop",
    "Radical": "刀",
    "RadicalIndex": "18.13",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3311,
    "FrequencyRank": 2487
  },
  {
    "Character": "敷",
    "Pinyin": "fū",
    "Definition": "spread, diffuse; apply, paint",
    "Radical": "攴",
    "RadicalIndex": "66.11",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3219,
    "FrequencyRank": 2788
  },
  {
    "Character": "瘫",
    "Pinyin": "tān",
    "Definition": "paralysis, palsy, numbness",
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3283,
    "FrequencyRank": 2823
  },
  {
    "Character": "僻",
    "Pinyin": "pì",
    "Definition": "out-of-the-way, remote; unorthodox",
    "Radical": "人",
    "RadicalIndex": "9.13",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3271,
    "FrequencyRank": 2827
  },
  {
    "Character": "稼",
    "Pinyin": "jià",
    "Definition": "sow grain; sheaves of grain",
    "Radical": "禾",
    "RadicalIndex": "115.1",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3264,
    "FrequencyRank": 2800
  },
  {
    "Character": "谴",
    "Pinyin": "qiǎn",
    "Definition": "reprimand, scold, abuse",
    "Radical": "言",
    "RadicalIndex": "149.13",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3307,
    "FrequencyRank": 2527
  },
  {
    "Character": "黎",
    "Pinyin": "lí",
    "Definition": "surname; numerous, many; black",
    "Radical": "黍",
    "RadicalIndex": "202.3",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3262,
    "FrequencyRank": 1476
  },
  {
    "Character": "膛",
    "Pinyin": "táng",
    "Definition": "chest; hollow space, cavity",
    "Radical": "肉",
    "RadicalIndex": "130.11",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3275,
    "FrequencyRank": 2865
  },
  {
    "Character": "橡",
    "Pinyin": "xiàng",
    "Definition": "chestnut oak; rubber tree; rubber",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3216,
    "FrequencyRank": 2871
  },
  {
    "Character": "槽",
    "Pinyin": "cáo",
    "Definition": "trough; manger; vat, tank; groove; a distillery",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3214,
    "FrequencyRank": 2993
  },
  {
    "Character": "履",
    "Pinyin": "lǚ",
    "Definition": "footwear, shoes; walk on, tread",
    "Radical": "尸",
    "RadicalIndex": "44.12",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3312,
    "FrequencyRank": 1802
  },
  {
    "Character": "僵",
    "Pinyin": "jiāng",
    "Definition": "stiff and motionless, stock still",
    "Radical": "人",
    "RadicalIndex": "9.13",
    "StrokeCount": 15,
    "HSK": 8,
    "GeneralStandard": 3269,
    "FrequencyRank": 2333
  },
  {
    "Character": "辨",
    "Pinyin": "biàn",
    "Definition": "distinguish, discriminate",
    "Radical": "辛",
    "RadicalIndex": "160.9",
    "StrokeCount": 16,
    "HSK": 8,
    "GeneralStandard": 3371,
    "FrequencyRank": 1910
  },
  {
    "Character": "踹",
    "Pinyin": "chuài",
    "Definition": "trample, tread on, kick; to crush",
    "Radical": "足",
    "RadicalIndex": "157.9",
    "StrokeCount": 16,
    "HSK": 8,
    "GeneralStandard": 6092,
    "FrequencyRank": 4485
  },
  {
    "Character": "缴",
    "Pinyin": "jiǎo",
    "Definition": "deliver, submit; hand over",
    "Radical": "糸",
    "RadicalIndex": "120.13",
    "StrokeCount": 16,
    "HSK": 8,
    "GeneralStandard": 3387,
    "FrequencyRank": 1834
  },
  {
    "Character": "橘",
    "Pinyin": "jú",
    "Definition": "orange, tangerine",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": 8,
    "GeneralStandard": 3332,
    "FrequencyRank": 3614
  },
  {
    "Character": "糙",
    "Pinyin": "cāo",
    "Definition": "coarse, harsh, rough, unpolished rice",
    "Radical": "米",
    "RadicalIndex": "119.11",
    "StrokeCount": 16,
    "HSK": 8,
    "GeneralStandard": 3373,
    "FrequencyRank": 3118
  },
  {
    "Character": "凝",
    "Pinyin": "níng",
    "Definition": "coagulate; congeal; freeze",
    "Radical": "冫",
    "RadicalIndex": "15.14",
    "StrokeCount": 16,
    "HSK": 8,
    "GeneralStandard": 3370,
    "FrequencyRank": 1631
  },
  {
    "Character": "雕",
    "Pinyin": "diāo",
    "Definition": "engrave, inlay, carve; exhaust; used for 鵰 an eagle, vulture",
    "Radical": "隹",
    "RadicalIndex": "172.8",
    "StrokeCount": 16,
    "HSK": 8,
    "GeneralStandard": 3365,
    "FrequencyRank": 1829
  },
  {
    "Character": "橙",
    "Pinyin": "chéng",
    "Definition": "orange",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": 8,
    "GeneralStandard": 3331,
    "FrequencyRank": 3571
  },
  {
    "Character": "颠",
    "Pinyin": "diān",
    "Definition": "top, peak, summit; upset",
    "Radical": "頁",
    "RadicalIndex": "181.1",
    "StrokeCount": 16,
    "HSK": 8,
    "GeneralStandard": 3327,
    "FrequencyRank": 2134
  },
  {
    "Character": "膨",
    "Pinyin": "péng",
    "Definition": "to swell; swollen, bloated, inflated",
    "Radical": "肉",
    "RadicalIndex": "130.12",
    "StrokeCount": 16,
    "HSK": 8,
    "GeneralStandard": 3364,
    "FrequencyRank": 2415
  },
  {
    "Character": "燥",
    "Pinyin": "zào",
    "Definition": "dry, parched, arid; quick-tempered",
    "Radical": "火",
    "RadicalIndex": "86.13",
    "StrokeCount": 17,
    "HSK": 8,
    "GeneralStandard": 3430,
    "FrequencyRank": 2613
  },
  {
    "Character": "瞩",
    "Pinyin": "zhǔ",
    "Definition": "watch carefully, stare at, focus on",
    "Radical": "目",
    "RadicalIndex": "109.12",
    "StrokeCount": 17,
    "HSK": 8,
    "GeneralStandard": 3405,
    "FrequencyRank": 3320
  },
  {
    "Character": "螺",
    "Pinyin": "luó",
    "Definition": "spiral shell; conch; spiral",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": 8,
    "GeneralStandard": 3410,
    "FrequencyRank": 2386
  },
  {
    "Character": "辫",
    "Pinyin": "biàn",
    "Definition": "braid; pigtail, plait, queue",
    "Radical": "辛",
    "RadicalIndex": "160.1",
    "StrokeCount": 17,
    "HSK": 8,
    "GeneralStandard": 3426,
    "FrequencyRank": 3240
  },
  {
    "Character": "鞠",
    "Pinyin": "jū",
    "Definition": "bow, bend; rear, raise, nourish",
    "Radical": "革",
    "RadicalIndex": "177.8",
    "StrokeCount": 17,
    "HSK": 8,
    "GeneralStandard": 3391,
    "FrequencyRank": 3037
  },
  {
    "Character": "骤",
    "Pinyin": "zhòu",
    "Definition": "procedure; gallop; sudden(ly)",
    "Radical": "馬",
    "RadicalIndex": "187.14",
    "StrokeCount": 17,
    "HSK": 8,
    "GeneralStandard": 3436,
    "FrequencyRank": 2045
  },
  {
    "Character": "瞪",
    "Pinyin": "dèng",
    "Definition": "stare at",
    "Radical": "目",
    "RadicalIndex": "109.12",
    "StrokeCount": 17,
    "HSK": 8,
    "GeneralStandard": 3406,
    "FrequencyRank": 2049
  },
  {
    "Character": "翼",
    "Pinyin": "yì",
    "Definition": "wings; fins on fish; shelter",
    "Radical": "羽",
    "RadicalIndex": "124.12",
    "StrokeCount": 17,
    "HSK": 8,
    "GeneralStandard": 3435,
    "FrequencyRank": 1294
  },
  {
    "Character": "黏",
    "Pinyin": "nián",
    "Definition": "stick to; glutinous, sticky; glue",
    "Radical": "黍",
    "RadicalIndex": "202.5",
    "StrokeCount": 17,
    "HSK": 8,
    "GeneralStandard": 6254,
    "FrequencyRank": 3645
  },
  {
    "Character": "霜",
    "Pinyin": "shuāng",
    "Definition": "frost; crystallized; candied",
    "Radical": "雨",
    "RadicalIndex": "173.9",
    "StrokeCount": 17,
    "HSK": 8,
    "GeneralStandard": 3399,
    "FrequencyRank": 2631
  },
  {
    "Character": "覆",
    "Pinyin": "fù",
    "Definition": "cover; tip over; return; reply",
    "Radical": "襾",
    "RadicalIndex": "146.12",
    "StrokeCount": 18,
    "HSK": 8,
    "GeneralStandard": 3440,
    "FrequencyRank": 1823
  },
  {
    "Character": "瀑",
    "Pinyin": "pù",
    "Definition": "waterfall, cascade; heavy rain",
    "Radical": "水",
    "RadicalIndex": "85.15",
    "StrokeCount": 18,
    "HSK": 8,
    "GeneralStandard": 3448,
    "FrequencyRank": 3195
  },
  {
    "Character": "攀",
    "Pinyin": "pān",
    "Definition": "climb; pull; hang on to",
    "Radical": "手",
    "RadicalIndex": "64.15",
    "StrokeCount": 19,
    "HSK": 8,
    "GeneralStandard": 3456,
    "FrequencyRank": 2439
  },
  {
    "Character": "巅",
    "Pinyin": "diān",
    "Definition": "summit of mountain, mountain top",
    "Radical": "山",
    "RadicalIndex": "46.16",
    "StrokeCount": 19,
    "HSK": 8,
    "GeneralStandard": 3461,
    "FrequencyRank": 3775
  },
  {
    "Character": "颤",
    "Pinyin": "chàn",
    "Definition": "shiver, tremble; trembling",
    "Radical": "頁",
    "RadicalIndex": "181.13",
    "StrokeCount": 19,
    "HSK": 8,
    "GeneralStandard": 3465,
    "FrequencyRank": 1900
  },
  {
    "Character": "蘑",
    "Pinyin": "mó",
    "Definition": "type of edible mushroom",
    "Radical": "艸",
    "RadicalIndex": "140.16",
    "StrokeCount": 19,
    "HSK": 8,
    "GeneralStandard": 3454,
    "FrequencyRank": 3528
  },
  {
    "Character": "蹬",
    "Pinyin": "dēng",
    "Definition": "step on, tread on; lose energy",
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": 8,
    "GeneralStandard": 3460,
    "FrequencyRank": 3349
  },
  {
    "Character": "攒",
    "Pinyin": "zǎn",
    "Definition": "save, hoard",
    "Radical": "手",
    "RadicalIndex": "64.16",
    "StrokeCount": 19,
    "HSK": 8,
    "GeneralStandard": 6368,
    "FrequencyRank": 3548
  },
  {
    "Character": "藻",
    "Pinyin": "zǎo",
    "Definition": "splendid, magnificent; algae",
    "Radical": "艸",
    "RadicalIndex": "140.16",
    "StrokeCount": 19,
    "HSK": 8,
    "GeneralStandard": 3455,
    "FrequencyRank": 2652
  },
  {
    "Character": "瓣",
    "Pinyin": "bàn",
    "Definition": "petal; segment; valves",
    "Radical": "瓜",
    "RadicalIndex": "97.14",
    "StrokeCount": 19,
    "HSK": 8,
    "GeneralStandard": 3468,
    "FrequencyRank": 2915
  },
  {
    "Character": "蹭",
    "Pinyin": "cèng",
    "Definition": "to shuffle, procrastinate, dilly-dally",
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": 8,
    "GeneralStandard": 3459,
    "FrequencyRank": 3707
  },
  {
    "Character": "嚷",
    "Pinyin": "rǎng",
    "Definition": "shout, brawl, make uproar, cry",
    "Radical": "口",
    "RadicalIndex": "30.17",
    "StrokeCount": 20,
    "HSK": 8,
    "GeneralStandard": 3480,
    "FrequencyRank": 2256
  },
  {
    "Character": "壤",
    "Pinyin": "rǎng",
    "Definition": "soil, loam, earth; rich",
    "Radical": "土",
    "RadicalIndex": "32.17",
    "StrokeCount": 20,
    "HSK": 8,
    "GeneralStandard": 3474,
    "FrequencyRank": 2412
  },
  {
    "Character": "馨",
    "Pinyin": "xīn",
    "Definition": "fragrant, aromatic; distant fragrance",
    "Radical": "香",
    "RadicalIndex": "186.11",
    "StrokeCount": 20,
    "HSK": 8,
    "GeneralStandard": 3475,
    "FrequencyRank": 2586
  },
  {
    "Character": "躁",
    "Pinyin": "zào",
    "Definition": "tense, excited, irritable",
    "Radical": "足",
    "RadicalIndex": "157.13",
    "StrokeCount": 20,
    "HSK": 8,
    "GeneralStandard": 3477,
    "FrequencyRank": 2451
  },
  {
    "Character": "髓",
    "Pinyin": "suǐ",
    "Definition": "bone marrow; essences, substances",
    "Radical": "骨",
    "RadicalIndex": "188.13",
    "StrokeCount": 21,
    "HSK": 8,
    "GeneralStandard": 3494,
    "FrequencyRank": 2808
  },
  {
    "Character": "蘸",
    "Pinyin": "zhàn",
    "Definition": "dip (a brush); remarry",
    "Radical": "艸",
    "RadicalIndex": "140.19",
    "StrokeCount": 22,
    "HSK": 8,
    "GeneralStandard": 6466,
    "FrequencyRank": 3944
  },
  {
    "Character": "镶",
    "Pinyin": "xiāng",
    "Definition": "insert, inlay, set, mount; fill",
    "Radical": "金",
    "RadicalIndex": "167.17",
    "StrokeCount": 22,
    "HSK": 8,
    "GeneralStandard": 3497,
    "FrequencyRank": 2974
  },
  {
    "Character": "刁",
    "Pinyin": "diāo",
    "Definition": "tricky, sly, crafty, cunning",
    "Radical": "刀",
    "RadicalIndex": "18",
    "StrokeCount": 2,
    "HSK": 9,
    "GeneralStandard": 16,
    "FrequencyRank": 3635
  },
  {
    "Character": "川",
    "Pinyin": "chuān",
    "Definition": "stream, river; flow; boil",
    "Radical": "巛",
    "RadicalIndex": "47",
    "StrokeCount": 3,
    "HSK": 9,
    "GeneralStandard": 43,
    "FrequencyRank": 1109
  },
  {
    "Character": "讥",
    "Pinyin": "jī",
    "Definition": "ridicule, jeer, mock; inspect",
    "Radical": "言",
    "RadicalIndex": "149.2",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 173,
    "FrequencyRank": 2922
  },
  {
    "Character": "邓",
    "Pinyin": "dèng",
    "Definition": "surname",
    "Radical": "邑",
    "RadicalIndex": "163.2",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 185,
    "FrequencyRank": 1614
  },
  {
    "Character": "曰",
    "Pinyin": "yuē",
    "Definition": "say; KangXi radical 73",
    "Radical": "曰",
    "RadicalIndex": "73",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 111,
    "FrequencyRank": 1656
  },
  {
    "Character": "屯",
    "Pinyin": "tún",
    "Definition": "village, hamlet; camp; station",
    "Radical": "屮",
    "RadicalIndex": "45.1",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 103,
    "FrequencyRank": 2864
  },
  {
    "Character": "孔",
    "Pinyin": "kǒng",
    "Definition": "opening, hole, orifice; great",
    "Radical": "子",
    "RadicalIndex": "39.1",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 179,
    "FrequencyRank": 1289
  },
  {
    "Character": "仆",
    "Pinyin": "pū",
    "Definition": "fall forward; lie prostrate, prone; servant",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 131,
    "FrequencyRank": 1812
  },
  {
    "Character": "戈",
    "Pinyin": "gē",
    "Definition": "halberd, spear, lance; rad. 62",
    "Radical": "戈",
    "RadicalIndex": "62",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 104,
    "FrequencyRank": 1695
  },
  {
    "Character": "丹",
    "Pinyin": "dān",
    "Definition": "cinnabar (native HgS); vermilion (artificial HgS used as pigment)",
    "Radical": "丶",
    "RadicalIndex": "3.3",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 155,
    "FrequencyRank": 1280
  },
  {
    "Character": "匀",
    "Pinyin": "yún",
    "Definition": "equal",
    "Radical": "勹",
    "RadicalIndex": "20.2",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 156,
    "FrequencyRank": 2692
  },
  {
    "Character": "冗",
    "Pinyin": "rǒng",
    "Definition": "excessive; superfluous",
    "Radical": "冖",
    "RadicalIndex": "14.2",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 172,
    "FrequencyRank": 3642
  },
  {
    "Character": "冈",
    "Pinyin": "gāng",
    "Definition": "ridge or crest of hill",
    "Radical": "冂",
    "RadicalIndex": "13.2",
    "StrokeCount": 4,
    "HSK": 9,
    "GeneralStandard": 115,
    "FrequencyRank": 2047
  },
  {
    "Character": "玄",
    "Pinyin": "xuán",
    "Definition": "deep, profound, abstruse; KangXi radical 95",
    "Radical": "玄",
    "RadicalIndex": "95",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 304,
    "FrequencyRank": 1717
  },
  {
    "Character": "禾",
    "Pinyin": "hé",
    "Definition": "grain still on stalk; rice plant",
    "Radical": "禾",
    "RadicalIndex": "115",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 267,
    "FrequencyRank": 3587
  },
  {
    "Character": "兰",
    "Pinyin": "lán",
    "Definition": "orchid; elegant, graceful",
    "Radical": "八",
    "RadicalIndex": "12.3",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 306,
    "FrequencyRank": 642
  },
  {
    "Character": "冯",
    "Pinyin": "féng",
    "Definition": "surname; gallop; by dint of",
    "Radical": "馬",
    "RadicalIndex": "187.2",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 303,
    "FrequencyRank": 1719
  },
  {
    "Character": "辽",
    "Pinyin": "liáo",
    "Definition": "distant, far",
    "Radical": "辵",
    "RadicalIndex": "162.2",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 331,
    "FrequencyRank": 2086
  },
  {
    "Character": "穴",
    "Pinyin": "xué",
    "Definition": "cave, den, hole; KangXi radical 116",
    "Radical": "穴",
    "RadicalIndex": "116",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 313,
    "FrequencyRank": 1940
  },
  {
    "Character": "叮",
    "Pinyin": "dīng",
    "Definition": "exhort or enjoin repeatedly",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 241,
    "FrequencyRank": 2611
  },
  {
    "Character": "丘",
    "Pinyin": "qiū",
    "Definition": "hill; elder; empty; a name",
    "Radical": "一",
    "RadicalIndex": "1.4",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 268,
    "FrequencyRank": 1929
  },
  {
    "Character": "甘",
    "Pinyin": "gān",
    "Definition": "sweetness; sweet, tasty",
    "Radical": "甘",
    "RadicalIndex": "99",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 205,
    "FrequencyRank": 1408
  },
  {
    "Character": "卉",
    "Pinyin": "huì",
    "Definition": "general term for plants; myriads",
    "Radical": "十",
    "RadicalIndex": "24.3",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 200,
    "FrequencyRank": 3341
  },
  {
    "Character": "尼",
    "Pinyin": "ní",
    "Definition": "Buddhist nun; transliteration",
    "Radical": "尸",
    "RadicalIndex": "44.2",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 326,
    "FrequencyRank": 654
  },
  {
    "Character": "弘",
    "Pinyin": "hóng",
    "Definition": "enlarge, expand; liberal, great",
    "Radical": "弓",
    "RadicalIndex": "57.2",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 329,
    "FrequencyRank": 2619
  },
  {
    "Character": "矢",
    "Pinyin": "shǐ",
    "Definition": "arrow, dart; vow, swear",
    "Radical": "矢",
    "RadicalIndex": "111",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 264,
    "FrequencyRank": 2811
  },
  {
    "Character": "凸",
    "Pinyin": "tū",
    "Definition": "protrude, bulge out, convex",
    "Radical": "凵",
    "RadicalIndex": "17.3",
    "StrokeCount": 5,
    "HSK": 9,
    "GeneralStandard": 229,
    "FrequencyRank": 2857
  },
  {
    "Character": "伦",
    "Pinyin": "lún",
    "Definition": "normal human relationships",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 470,
    "FrequencyRank": 970
  },
  {
    "Character": "旬",
    "Pinyin": "xún",
    "Definition": "ten-day period; period of time",
    "Radical": "日",
    "RadicalIndex": "72.2",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 500,
    "FrequencyRank": 2332
  },
  {
    "Character": "夷",
    "Pinyin": "yí",
    "Definition": "ancient barbarian tribes",
    "Radical": "大",
    "RadicalIndex": "37.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 403,
    "FrequencyRank": 2337
  },
  {
    "Character": "讳",
    "Pinyin": "huì",
    "Definition": "conceal; shun; regard as taboo",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 551,
    "FrequencyRank": 2912
  },
  {
    "Character": "舟",
    "Pinyin": "zhōu",
    "Definition": "boat, ship; KangXi radical 137",
    "Radical": "舟",
    "RadicalIndex": "137",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 484,
    "FrequencyRank": 2224
  },
  {
    "Character": "奸",
    "Pinyin": "jiān",
    "Definition": "crafty, villainous, false",
    "Radical": "女",
    "RadicalIndex": "38.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 577,
    "FrequencyRank": 1992
  },
  {
    "Character": "驰",
    "Pinyin": "chí",
    "Definition": "go quickly or swiftly; hurry",
    "Radical": "馬",
    "RadicalIndex": "187.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 596,
    "FrequencyRank": 2100
  },
  {
    "Character": "刘",
    "Pinyin": "liú",
    "Definition": "surname; kill, destroy",
    "Radical": "刀",
    "RadicalIndex": "18.4",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 517,
    "FrequencyRank": 751
  },
  {
    "Character": "旭",
    "Pinyin": "xù",
    "Definition": "rising sun; brilliance; radiant",
    "Radical": "日",
    "RadicalIndex": "72.2",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 502,
    "FrequencyRank": 2846
  },
  {
    "Character": "汛",
    "Pinyin": "xùn",
    "Definition": "high water, flood tides",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 539,
    "FrequencyRank": 3759
  },
  {
    "Character": "乔",
    "Pinyin": "qiáo",
    "Definition": "tall, lofty; proud, stately",
    "Radical": "大",
    "RadicalIndex": "37.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 452,
    "FrequencyRank": 1488
  },
  {
    "Character": "驮",
    "Pinyin": "tuó",
    "Definition": "carry on back",
    "Radical": "馬",
    "RadicalIndex": "187.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 590,
    "FrequencyRank": 3427
  },
  {
    "Character": "弛",
    "Pinyin": "chí",
    "Definition": "loosen, relax, unstring a bow",
    "Radical": "弓",
    "RadicalIndex": "57.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 569,
    "FrequencyRank": 3358
  },
  {
    "Character": "吏",
    "Pinyin": "lì",
    "Definition": "government official, magistrate",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 381,
    "FrequencyRank": 2311
  },
  {
    "Character": "廷",
    "Pinyin": "tíng",
    "Definition": "court",
    "Radical": "廴",
    "RadicalIndex": "54.4",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 448,
    "FrequencyRank": 1626
  },
  {
    "Character": "伊",
    "Pinyin": "yī",
    "Definition": "third person pronoun; he, she, this, that",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 478,
    "FrequencyRank": 761
  },
  {
    "Character": "屹",
    "Pinyin": "yì",
    "Definition": "to rise high; to stand erect",
    "Radical": "山",
    "RadicalIndex": "46.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 435,
    "FrequencyRank": 3851
  },
  {
    "Character": "仲",
    "Pinyin": "zhòng",
    "Definition": "middle brother; go between, mediator; surname",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 465,
    "FrequencyRank": 1706
  },
  {
    "Character": "州",
    "Pinyin": "zhōu",
    "Definition": "administrative division, state",
    "Radical": "巛",
    "RadicalIndex": "47.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 535,
    "FrequencyRank": 721
  },
  {
    "Character": "旨",
    "Pinyin": "zhǐ",
    "Definition": "purpose, aim; excellent",
    "Radical": "日",
    "RadicalIndex": "72.2",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 501,
    "FrequencyRank": 1685
  },
  {
    "Character": "朴",
    "Pinyin": "pǔ",
    "Definition": "simple, unadorned; sincere; surname; a tree",
    "Radical": "木",
    "RadicalIndex": "75.2",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 376,
    "FrequencyRank": 2092
  },
  {
    "Character": "朱",
    "Pinyin": "zhū",
    "Definition": "cinnabar, vermilion; surname",
    "Radical": "木",
    "RadicalIndex": "75.2",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 445,
    "FrequencyRank": 1120
  },
  {
    "Character": "岂",
    "Pinyin": "qǐ",
    "Definition": "how? what?",
    "Radical": "山",
    "RadicalIndex": "46.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 439,
    "FrequencyRank": 1726
  },
  {
    "Character": "讹",
    "Pinyin": "é",
    "Definition": "swindle, cheat; erroneous, wrong",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 555,
    "FrequencyRank": 3782
  },
  {
    "Character": "吕",
    "Pinyin": "lǚ",
    "Definition": "surname; a musical note",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 426,
    "FrequencyRank": 1716
  },
  {
    "Character": "刑",
    "Pinyin": "xíng",
    "Definition": "punishment, penalty; law",
    "Radical": "刀",
    "RadicalIndex": "18.4",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 351,
    "FrequencyRank": 1087
  },
  {
    "Character": "贞",
    "Pinyin": "zhēn",
    "Definition": "virtuous, chaste, pure; loyal",
    "Radical": "卜",
    "RadicalIndex": "25.4",
    "StrokeCount": 6,
    "HSK": 9,
    "GeneralStandard": 412,
    "FrequencyRank": 2366
  },
  {
    "Character": "呜",
    "Pinyin": "wū",
    "Definition": "sound of crying, sobbing",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 745,
    "FrequencyRank": 2572
  },
  {
    "Character": "宋",
    "Pinyin": "sòng",
    "Definition": "Song dynasty; surname",
    "Radical": "宀",
    "RadicalIndex": "40.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 878,
    "FrequencyRank": 990
  },
  {
    "Character": "芬",
    "Pinyin": "fēn",
    "Definition": "fragrance, aroma; perfume",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 668,
    "FrequencyRank": 1890
  },
  {
    "Character": "沧",
    "Pinyin": "cāng",
    "Definition": "blue, dark green; cold",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 866,
    "FrequencyRank": 3023
  },
  {
    "Character": "杜",
    "Pinyin": "dù",
    "Definition": "stop, prevent; restrict; surname",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 680,
    "FrequencyRank": 1277
  },
  {
    "Character": "赤",
    "Pinyin": "chì",
    "Definition": undefined,
    "Radical": "赤",
    "RadicalIndex": "155",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 631,
    "FrequencyRank": 1660
  },
  {
    "Character": "佐",
    "Pinyin": "zuǒ",
    "Definition": "assist, aid, second; subordinate",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 772,
    "FrequencyRank": 2081
  },
  {
    "Character": "纺",
    "Pinyin": "fǎng",
    "Definition": "spin, reel, weave; reeled pongee",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 940,
    "FrequencyRank": 2409
  },
  {
    "Character": "卤",
    "Pinyin": "lǔ",
    "Definition": "salt",
    "Radical": "卜",
    "RadicalIndex": "25.5",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 711,
    "FrequencyRank": 3747
  },
  {
    "Character": "孝",
    "Pinyin": "xiào",
    "Definition": "filial piety, obedience; mourning",
    "Radical": "子",
    "RadicalIndex": "39.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 638,
    "FrequencyRank": 2116
  },
  {
    "Character": "抒",
    "Pinyin": "shū",
    "Definition": "express; eliminate; relieve",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 659,
    "FrequencyRank": 3071
  },
  {
    "Character": "抡",
    "Pinyin": "lūn",
    "Definition": "swing, brandish, flourish",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 635,
    "FrequencyRank": 3869
  },
  {
    "Character": "刨",
    "Pinyin": "páo",
    "Definition": undefined,
    "Radical": "刀",
    "RadicalIndex": "18.5",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 821,
    "FrequencyRank": 3623
  },
  {
    "Character": "沏",
    "Pinyin": "qī",
    "Definition": "infuse",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 3771,
    "FrequencyRank": 4487
  },
  {
    "Character": "纹",
    "Pinyin": "wén",
    "Definition": "line, streak, stripe; wrinkle",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 939,
    "FrequencyRank": 1768
  },
  {
    "Character": "牡",
    "Pinyin": "mǔ",
    "Definition": "male of animals; bolt of door",
    "Radical": "牛",
    "RadicalIndex": "93.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 759,
    "FrequencyRank": 3001
  },
  {
    "Character": "岔",
    "Pinyin": "chà",
    "Definition": "diverge, branch off; fork in road",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 802,
    "FrequencyRank": 3134
  },
  {
    "Character": "祀",
    "Pinyin": "sì",
    "Definition": "to sacrifice, worship",
    "Radical": "示",
    "RadicalIndex": "113.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 891,
    "FrequencyRank": 3123
  },
  {
    "Character": "秃",
    "Pinyin": "tū",
    "Definition": "bald",
    "Radical": "禾",
    "RadicalIndex": "115.2",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 764,
    "FrequencyRank": 2521
  },
  {
    "Character": "杠",
    "Pinyin": "gāng",
    "Definition": "lever, pole, crowbar; sharpen",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 679,
    "FrequencyRank": 3066
  },
  {
    "Character": "沐",
    "Pinyin": "mù",
    "Definition": "bathe, cleanse, wash, shampoo",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 856,
    "FrequencyRank": 3464
  },
  {
    "Character": "纬",
    "Pinyin": "wěi",
    "Definition": "woof; parallels of latitude",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 929,
    "FrequencyRank": 2697
  },
  {
    "Character": "芳",
    "Pinyin": "fāng",
    "Definition": "fragrant; virtuous; beautiful",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 670,
    "FrequencyRank": 1586
  },
  {
    "Character": "伯",
    "Pinyin": "bó",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 778,
    "FrequencyRank": 821
  },
  {
    "Character": "杨",
    "Pinyin": "yáng",
    "Definition": "willow, poplar, aspen; surname",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 689,
    "FrequencyRank": 1062
  },
  {
    "Character": "灶",
    "Pinyin": "zào",
    "Definition": "kitchen stove, cooking stove",
    "Radical": "火",
    "RadicalIndex": "86.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 851,
    "FrequencyRank": 2691
  },
  {
    "Character": "沃",
    "Pinyin": "wò",
    "Definition": "water, irrigate; fertile, rich",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 862,
    "FrequencyRank": 1808
  },
  {
    "Character": "矣",
    "Pinyin": "yǐ",
    "Definition": "particle of completed action",
    "Radical": "矢",
    "RadicalIndex": "111.2",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 927,
    "FrequencyRank": 2069
  },
  {
    "Character": "芽",
    "Pinyin": "yá",
    "Definition": "bud, sprout, shoot",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 664,
    "FrequencyRank": 2737
  },
  {
    "Character": "汹",
    "Pinyin": "xiōng",
    "Definition": "turbulent; noisy, restless",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 864,
    "FrequencyRank": 2746
  },
  {
    "Character": "冶",
    "Pinyin": "yě",
    "Definition": "smelt, fuse metals; cast, found",
    "Radical": "冫",
    "RadicalIndex": "15.5",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 843,
    "FrequencyRank": 2792
  },
  {
    "Character": "辰",
    "Pinyin": "chén",
    "Definition": "early morning; 5th terrestrial branch",
    "Radical": "辰",
    "RadicalIndex": "161",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 701,
    "FrequencyRank": 2317
  },
  {
    "Character": "旱",
    "Pinyin": "hàn",
    "Definition": "drought; dry; dry land",
    "Radical": "日",
    "RadicalIndex": "72.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 714,
    "FrequencyRank": 2523
  },
  {
    "Character": "亩",
    "Pinyin": "mǔ",
    "Definition": "Chinese land measure; fields",
    "Radical": "亠",
    "RadicalIndex": "8.5",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 829,
    "FrequencyRank": 2360
  },
  {
    "Character": "韧",
    "Pinyin": "rèn",
    "Definition": "strong and pliable, resilient",
    "Radical": "韋",
    "RadicalIndex": "178.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 610,
    "FrequencyRank": 3137
  },
  {
    "Character": "灼",
    "Pinyin": "zhuó",
    "Definition": "burn; broil; cauterize; bright",
    "Radical": "火",
    "RadicalIndex": "86.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 853,
    "FrequencyRank": 2867
  },
  {
    "Character": "沛",
    "Pinyin": "pèi",
    "Definition": "abundant, full, copious; sudden",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 857,
    "FrequencyRank": 3032
  },
  {
    "Character": "灸",
    "Pinyin": "jiǔ",
    "Definition": "cauterize with moxa; moxibustion",
    "Radical": "火",
    "RadicalIndex": "86.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 819,
    "FrequencyRank": 4024
  },
  {
    "Character": "歼",
    "Pinyin": "jiān",
    "Definition": "annihilate, wipe out, kill off",
    "Radical": "歹",
    "RadicalIndex": "78.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 706,
    "FrequencyRank": 1815
  },
  {
    "Character": "沪",
    "Pinyin": "hù",
    "Definition": "Shanghai; river near Shanghai",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 869,
    "FrequencyRank": 2734
  },
  {
    "Character": "吴",
    "Pinyin": "wú",
    "Definition": "name of warring state; surname",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 718,
    "FrequencyRank": 1135
  },
  {
    "Character": "坝",
    "Pinyin": "bà",
    "Definition": "embankment; dam",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 629,
    "FrequencyRank": 2786
  },
  {
    "Character": "狈",
    "Pinyin": "bèi",
    "Definition": "a legendary animal with short forelegs which rode a wolf",
    "Radical": "犬",
    "RadicalIndex": "94.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 813,
    "FrequencyRank": 3090
  },
  {
    "Character": "抠",
    "Pinyin": "kōu",
    "Definition": "raise, lift up; tight-fisted",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 617,
    "FrequencyRank": 4103
  },
  {
    "Character": "甸",
    "Pinyin": "diān",
    "Definition": "suburbs of capital; govern; crops",
    "Radical": "田",
    "RadicalIndex": "102.2",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 809,
    "FrequencyRank": 2588
  },
  {
    "Character": "芦",
    "Pinyin": "lú",
    "Definition": "rushes, reeds",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 672,
    "FrequencyRank": 2477
  },
  {
    "Character": "汪",
    "Pinyin": "wāng",
    "Definition": "vast, extensive, deep; surname",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 855,
    "FrequencyRank": 1876
  },
  {
    "Character": "吭",
    "Pinyin": "kēng",
    "Definition": "throat",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 746,
    "FrequencyRank": 3067
  },
  {
    "Character": "旷",
    "Pinyin": "kuàng",
    "Definition": "extensive, wide, broad; empty",
    "Radical": "日",
    "RadicalIndex": "72.3",
    "StrokeCount": 7,
    "HSK": 9,
    "GeneralStandard": 727,
    "FrequencyRank": 2735
  },
  {
    "Character": "拓",
    "Pinyin": "tà",
    "Definition": "expand; open up; support or push",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 957,
    "FrequencyRank": 2238
  },
  {
    "Character": "侠",
    "Pinyin": "xiá",
    "Definition": "chivalrous person; knight-errant",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1141,
    "FrequencyRank": 1216
  },
  {
    "Character": "拄",
    "Pinyin": "zhǔ",
    "Definition": "lean on; post; prod; ridicule",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 979,
    "FrequencyRank": 4240
  },
  {
    "Character": "岭",
    "Pinyin": "lǐng",
    "Definition": "mountain ridge, mountain peak",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1111,
    "FrequencyRank": 2214
  },
  {
    "Character": "垄",
    "Pinyin": "lǒng",
    "Definition": "grave, mound; ridge in field",
    "Radical": "龍",
    "RadicalIndex": "212.3",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1055,
    "FrequencyRank": 2336
  },
  {
    "Character": "侍",
    "Pinyin": "shì",
    "Definition": "serve, attend upon; attendant, servant; samurai",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1136,
    "FrequencyRank": 1871
  },
  {
    "Character": "昔",
    "Pinyin": "xī",
    "Definition": "formerly; ancient; in beginning",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1000,
    "FrequencyRank": 2388
  },
  {
    "Character": "呵",
    "Pinyin": "ā",
    "Definition": "scold; laughing sound; yawn",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1085,
    "FrequencyRank": 1861
  },
  {
    "Character": "顷",
    "Pinyin": "qǐng",
    "Definition": "a moment; a measure of area equal to 100 mu or 6.7 hectares; to lean",
    "Radical": "頁",
    "RadicalIndex": "181.2",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1058,
    "FrequencyRank": 2724
  },
  {
    "Character": "闸",
    "Pinyin": "zhá",
    "Definition": "sluice; flood gate, canal lock",
    "Radical": "門",
    "RadicalIndex": "169.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1227,
    "FrequencyRank": 3280
  },
  {
    "Character": "枢",
    "Pinyin": "shū",
    "Definition": "door hinge; pivot; center of power",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1021,
    "FrequencyRank": 2616
  },
  {
    "Character": "侃",
    "Pinyin": "kǎn",
    "Definition": "upright and strong; amiable",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 3958,
    "FrequencyRank": 3315
  },
  {
    "Character": "秉",
    "Pinyin": "bǐng",
    "Definition": "grasp, hold; bundle; authority",
    "Radical": "禾",
    "RadicalIndex": "115.3",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1134,
    "FrequencyRank": 2809
  },
  {
    "Character": "茎",
    "Pinyin": "jīng",
    "Definition": "stem, stalk",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1014,
    "FrequencyRank": 2675
  },
  {
    "Character": "杭",
    "Pinyin": "háng",
    "Definition": "cross stream; navigate",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1030,
    "FrequencyRank": 2346
  },
  {
    "Character": "拙",
    "Pinyin": "zhuō",
    "Definition": "stupid, clumsy, crude; convention",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 987,
    "FrequencyRank": 2709
  },
  {
    "Character": "炊",
    "Pinyin": "chuī",
    "Definition": "cook; meal",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1235,
    "FrequencyRank": 3333
  },
  {
    "Character": "枣",
    "Pinyin": "zǎo",
    "Definition": "date tree; dates, jujubes; surname",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1040,
    "FrequencyRank": 2851
  },
  {
    "Character": "帖",
    "Pinyin": "tiē",
    "Definition": "invitation card; notice",
    "Radical": "巾",
    "RadicalIndex": "50.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1107,
    "FrequencyRank": 2892
  },
  {
    "Character": "饲",
    "Pinyin": "sì",
    "Definition": "raise animals; feed; nourish",
    "Radical": "食",
    "RadicalIndex": "184.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1203,
    "FrequencyRank": 1971
  },
  {
    "Character": "函",
    "Pinyin": "hán",
    "Definition": "correspondence; a case; a box",
    "Radical": "凵",
    "RadicalIndex": "17.6",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1322,
    "FrequencyRank": 1950
  },
  {
    "Character": "泊",
    "Pinyin": "pō",
    "Definition": "anchor vessel; lie at anchor",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1249,
    "FrequencyRank": 2043
  },
  {
    "Character": "拢",
    "Pinyin": "lǒng",
    "Definition": "collect, bring together",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 958,
    "FrequencyRank": 2374
  },
  {
    "Character": "茂",
    "Pinyin": "mào",
    "Definition": "thick, lush, dense; talented",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1003,
    "FrequencyRank": 2309
  },
  {
    "Character": "迪",
    "Pinyin": "dí",
    "Definition": "enlighten, advance; progress",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1091,
    "FrequencyRank": 1440
  },
  {
    "Character": "怡",
    "Pinyin": "yí",
    "Definition": "harmony; pleasure, joy; be glad",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1272,
    "FrequencyRank": 2519
  },
  {
    "Character": "孟",
    "Pinyin": "mèng",
    "Definition": "first in series; great, eminent",
    "Radical": "子",
    "RadicalIndex": "39.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1316,
    "FrequencyRank": 1575
  },
  {
    "Character": "欧",
    "Pinyin": "ōu",
    "Definition": "translit.: Europe; ohm; surname",
    "Radical": "欠",
    "RadicalIndex": "76.4",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1053,
    "FrequencyRank": 823
  },
  {
    "Character": "沽",
    "Pinyin": "gū",
    "Definition": "buy and sell; inferior in quality",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1243,
    "FrequencyRank": 3492
  },
  {
    "Character": "弧",
    "Pinyin": "hú",
    "Definition": "wooden bow; arc, crescent",
    "Radical": "弓",
    "RadicalIndex": "57.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1312,
    "FrequencyRank": 3062
  },
  {
    "Character": "凯",
    "Pinyin": "kǎi",
    "Definition": "triumphant; triumph, victory",
    "Radical": "几",
    "RadicalIndex": "16.6",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1112,
    "FrequencyRank": 1447
  },
  {
    "Character": "诧",
    "Pinyin": "chà",
    "Definition": "be surprised, be shocked",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 4057,
    "FrequencyRank": 2728
  },
  {
    "Character": "迭",
    "Pinyin": "dié",
    "Definition": "repeatedly, frequently",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1123,
    "FrequencyRank": 3018
  },
  {
    "Character": "侨",
    "Pinyin": "qiáo",
    "Definition": "sojourn, lodge",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1149,
    "FrequencyRank": 2340
  },
  {
    "Character": "贮",
    "Pinyin": "zhù",
    "Definition": "store, stockpile, hoard",
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1118,
    "FrequencyRank": 2824
  },
  {
    "Character": "卒",
    "Pinyin": "zú",
    "Definition": "soldier; servant; at last, finally",
    "Radical": "十",
    "RadicalIndex": "24.6",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1217,
    "FrequencyRank": 2584
  },
  {
    "Character": "贬",
    "Pinyin": "biǎn",
    "Definition": "decrease, lower; censure, criticize",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1116,
    "FrequencyRank": 2536
  },
  {
    "Character": "咏",
    "Pinyin": "yǒng",
    "Definition": "sing song or poem, hum, chant",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1101,
    "FrequencyRank": 3469
  },
  {
    "Character": "茅",
    "Pinyin": "máo",
    "Definition": "reeds, rushes, grass; surname",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1016,
    "FrequencyRank": 2339
  },
  {
    "Character": "贤",
    "Pinyin": "xián",
    "Definition": "virtuous, worthy, good; able",
    "Radical": "貝",
    "RadicalIndex": "154.4",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1074,
    "FrequencyRank": 1943
  },
  {
    "Character": "泻",
    "Pinyin": "xiè",
    "Definition": "drain off, leak; flow, pour down",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": 9,
    "GeneralStandard": 1255,
    "FrequencyRank": 2715
  },
  {
    "Character": "俭",
    "Pinyin": "jiǎn",
    "Definition": "temperate, frugal, economical",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1567,
    "FrequencyRank": 2891
  },
  {
    "Character": "拱",
    "Pinyin": "gǒng",
    "Definition": "fold hands on breast; bow, salute",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1369,
    "FrequencyRank": 2573
  },
  {
    "Character": "荆",
    "Pinyin": "jīng",
    "Definition": "thorns; brambles; my wife; cane",
    "Radical": "刀",
    "RadicalIndex": "18.8",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1401,
    "FrequencyRank": 2551
  },
  {
    "Character": "阀",
    "Pinyin": "fá",
    "Definition": "powerful and influential group",
    "Radical": "門",
    "RadicalIndex": "169.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1638,
    "FrequencyRank": 2671
  },
  {
    "Character": "逊",
    "Pinyin": "xùn",
    "Definition": "humble, modest; yield",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1728,
    "FrequencyRank": 1697
  },
  {
    "Character": "荡",
    "Pinyin": "dàng",
    "Definition": "pond, pool; wash away, cleanse",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1414,
    "FrequencyRank": 1424
  },
  {
    "Character": "盈",
    "Pinyin": "yíng",
    "Definition": "fill; full, overflowing; surplus",
    "Radical": "皿",
    "RadicalIndex": "108.4",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1745,
    "FrequencyRank": 2111
  },
  {
    "Character": "赵",
    "Pinyin": "zhào",
    "Definition": "surname; ancient state",
    "Radical": "走",
    "RadicalIndex": "156.2",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1378,
    "FrequencyRank": 1169
  },
  {
    "Character": "诵",
    "Pinyin": "sòng",
    "Definition": "recite, chant, repeat",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1718,
    "FrequencyRank": 2557
  },
  {
    "Character": "恤",
    "Pinyin": "xù",
    "Definition": "show pity; relieve, help",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1689,
    "FrequencyRank": 3214
  },
  {
    "Character": "竿",
    "Pinyin": "gān",
    "Definition": "bamboo pole; penis",
    "Radical": "竹",
    "RadicalIndex": "118.3",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1554,
    "FrequencyRank": 2934
  },
  {
    "Character": "荧",
    "Pinyin": "yíng",
    "Definition": "shine, shimmer; shining, dazzling",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1417,
    "FrequencyRank": 3400
  },
  {
    "Character": "衍",
    "Pinyin": "yǎn",
    "Definition": "overflow, spill over, spread out",
    "Radical": "行",
    "RadicalIndex": "144.3",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1582,
    "FrequencyRank": 2437
  },
  {
    "Character": "柏",
    "Pinyin": "bǎi",
    "Definition": "cypress, cedar",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1432,
    "FrequencyRank": 1596
  },
  {
    "Character": "狭",
    "Pinyin": "xiá",
    "Definition": "narrow, limited; narrow-minded",
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1600,
    "FrequencyRank": 2018
  },
  {
    "Character": "挎",
    "Pinyin": "kuà",
    "Definition": "carry",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1372,
    "FrequencyRank": 4005
  },
  {
    "Character": "勃",
    "Pinyin": "bó",
    "Definition": "suddenly, sudden, quick",
    "Radical": "力",
    "RadicalIndex": "19.7",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1440,
    "FrequencyRank": 1533
  },
  {
    "Character": "咧",
    "Pinyin": "liě",
    "Definition": "stretch mouth, grimace, grin",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1490,
    "FrequencyRank": 2741
  },
  {
    "Character": "胚",
    "Pinyin": "pēi",
    "Definition": "embryo; unfinished things",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1591,
    "FrequencyRank": 2928
  },
  {
    "Character": "砌",
    "Pinyin": "qì",
    "Definition": "to build, pile up; stone steps, brick walk",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1450,
    "FrequencyRank": 3221
  },
  {
    "Character": "昼",
    "Pinyin": "zhòu",
    "Definition": "daytime, daylight",
    "Radical": "尸",
    "RadicalIndex": "44.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1723,
    "FrequencyRank": 2651
  },
  {
    "Character": "亭",
    "Pinyin": "tíng",
    "Definition": "pavilion; erect",
    "Radical": "亠",
    "RadicalIndex": "8.7",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1620,
    "FrequencyRank": 2002
  },
  {
    "Character": "孪",
    "Pinyin": "luán",
    "Definition": "twins",
    "Radical": "子",
    "RadicalIndex": "39.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 4303,
    "FrequencyRank": 4292
  },
  {
    "Character": "钧",
    "Pinyin": "jūn",
    "Definition": "unit of measure equivalent to thirty catties",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1533,
    "FrequencyRank": 2504
  },
  {
    "Character": "巷",
    "Pinyin": "xiàng",
    "Definition": "alley, lane",
    "Radical": "己",
    "RadicalIndex": "49.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1406,
    "FrequencyRank": 2399
  },
  {
    "Character": "俏",
    "Pinyin": "qiào",
    "Definition": "like, similar; resemble; pretty",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1561,
    "FrequencyRank": 2880
  },
  {
    "Character": "砂",
    "Pinyin": "shā",
    "Definition": "sand; pebbles, gravel; gritty",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1451,
    "FrequencyRank": 2713
  },
  {
    "Character": "茫",
    "Pinyin": "máng",
    "Definition": "vast, boundless, widespread",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1413,
    "FrequencyRank": 1951
  },
  {
    "Character": "鸦",
    "Pinyin": "yā",
    "Definition": "crow; Corvus species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.4",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1464,
    "FrequencyRank": 2397
  },
  {
    "Character": "炭",
    "Pinyin": "tàn",
    "Definition": "charcoal; coal; carbon",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1517,
    "FrequencyRank": 2369
  },
  {
    "Character": "轴",
    "Pinyin": "zhóu",
    "Definition": "axle, axletree; pivot; axis",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1462,
    "FrequencyRank": 2438
  },
  {
    "Character": "弈",
    "Pinyin": "yì",
    "Definition": "Chinese chess",
    "Radical": "廾",
    "RadicalIndex": "55.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 4305,
    "FrequencyRank": 4421
  },
  {
    "Character": "盹",
    "Pinyin": "dǔn",
    "Definition": "to doze; to nap; to nod",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1478,
    "FrequencyRank": 4220
  },
  {
    "Character": "胧",
    "Pinyin": "lóng",
    "Definition": "condition or appearance of moon",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1592,
    "FrequencyRank": 2837
  },
  {
    "Character": "洲",
    "Pinyin": "zhōu",
    "Definition": "continent; island; islet",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1680,
    "FrequencyRank": 701
  },
  {
    "Character": "哆",
    "Pinyin": "duō",
    "Definition": "tremble, shiver, shudder, quiver",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1511,
    "FrequencyRank": 3231
  },
  {
    "Character": "闺",
    "Pinyin": "guī",
    "Definition": undefined,
    "Radical": "門",
    "RadicalIndex": "169.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1635,
    "FrequencyRank": 3347
  },
  {
    "Character": "娇",
    "Pinyin": "jiāo",
    "Definition": "seductive and loveable; tender",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1739,
    "FrequencyRank": 2158
  },
  {
    "Character": "阂",
    "Pinyin": "hé",
    "Definition": "blocked or separated; to prevent",
    "Radical": "門",
    "RadicalIndex": "169.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 4319,
    "FrequencyRank": 4004
  },
  {
    "Character": "荫",
    "Pinyin": "yīn",
    "Definition": "shade, shelter; protect",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1420,
    "FrequencyRank": 2669
  },
  {
    "Character": "骇",
    "Pinyin": "hài",
    "Definition": "terrify, frighten, scare; shock",
    "Radical": "馬",
    "RadicalIndex": "187.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1764,
    "FrequencyRank": 2538
  },
  {
    "Character": "哗",
    "Pinyin": "huā",
    "Definition": "rushing sound; gush forward",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1507,
    "FrequencyRank": 2486
  },
  {
    "Character": "恍",
    "Pinyin": "huǎng",
    "Definition": "seemingly; absent-minded",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1687,
    "FrequencyRank": 2535
  },
  {
    "Character": "陡",
    "Pinyin": "dǒu",
    "Definition": "steep, sloping; abruptly, sudden",
    "Radical": "阜",
    "RadicalIndex": "170.7",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1727,
    "FrequencyRank": 2362
  },
  {
    "Character": "浊",
    "Pinyin": "zhuó",
    "Definition": "muddy, turbid, dirty, filthy",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1668,
    "FrequencyRank": 2985
  },
  {
    "Character": "柳",
    "Pinyin": "liǔ",
    "Definition": "willow tree; pleasure",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1434,
    "FrequencyRank": 1557
  },
  {
    "Character": "垦",
    "Pinyin": "kěn",
    "Definition": "cultivate, reclaim, to farm land",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1719,
    "FrequencyRank": 3038
  },
  {
    "Character": "怠",
    "Pinyin": "dài",
    "Definition": "idle, remiss, negligent; neglect",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 9,
    "HSK": 9,
    "GeneralStandard": 1747,
    "FrequencyRank": 3024
  },
  {
    "Character": "匪",
    "Pinyin": "fěi",
    "Definition": "bandits, robbers, gangsters",
    "Radical": "匚",
    "RadicalIndex": "22.8",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1780,
    "FrequencyRank": 2201
  },
  {
    "Character": "秦",
    "Pinyin": "qín",
    "Definition": undefined,
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1772,
    "FrequencyRank": 1394
  },
  {
    "Character": "袍",
    "Pinyin": "páo",
    "Definition": "long gown, robe, cloak",
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2117,
    "FrequencyRank": 2324
  },
  {
    "Character": "畔",
    "Pinyin": "pàn",
    "Definition": "boundary path dividing fields",
    "Radical": "田",
    "RadicalIndex": "102.5",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1899,
    "FrequencyRank": 2833
  },
  {
    "Character": "浦",
    "Pinyin": "pǔ",
    "Definition": "bank of river, shore; surname",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2068,
    "FrequencyRank": 2161
  },
  {
    "Character": "莲",
    "Pinyin": "lián",
    "Definition": "lotus, water lily; paradise",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1823,
    "FrequencyRank": 1837
  },
  {
    "Character": "泰",
    "Pinyin": "tài",
    "Definition": "great, exalted, superior; big",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1771,
    "FrequencyRank": 1318
  },
  {
    "Character": "逞",
    "Pinyin": "chěng",
    "Definition": "indulge oneself; brag, show off",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1886,
    "FrequencyRank": 3175
  },
  {
    "Character": "郭",
    "Pinyin": "guō",
    "Definition": "outer part (of a city); surname",
    "Radical": "邑",
    "RadicalIndex": "163.8",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2022,
    "FrequencyRank": 1813
  },
  {
    "Character": "袁",
    "Pinyin": "yuán",
    "Definition": "robe; surname",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1799,
    "FrequencyRank": 1755
  },
  {
    "Character": "栖",
    "Pinyin": "qī",
    "Definition": "perch; roost; stay",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1837,
    "FrequencyRank": 2460
  },
  {
    "Character": "绣",
    "Pinyin": "xiù",
    "Definition": "embroider; embroidery; ornament",
    "Radical": "糸",
    "RadicalIndex": "120.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2151,
    "FrequencyRank": 2434
  },
  {
    "Character": "脊",
    "Pinyin": "jí",
    "Definition": "spine, backbone; ridge",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2033,
    "FrequencyRank": 2228
  },
  {
    "Character": "浙",
    "Pinyin": "zhè",
    "Definition": "Zhejiang province; river",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2066,
    "FrequencyRank": 2119
  },
  {
    "Character": "捂",
    "Pinyin": "wǔ",
    "Definition": "resist",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1785,
    "FrequencyRank": 3092
  },
  {
    "Character": "俯",
    "Pinyin": "fǔ",
    "Definition": "bow down, face down, look down",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1966,
    "FrequencyRank": 2261
  },
  {
    "Character": "逝",
    "Pinyin": "shì",
    "Definition": "pass away; die",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1803,
    "FrequencyRank": 2102
  },
  {
    "Character": "栓",
    "Pinyin": "shuān",
    "Definition": "wooden peg, post or stick",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1843,
    "FrequencyRank": 2938
  },
  {
    "Character": "唐",
    "Pinyin": "táng",
    "Definition": "Tang dynasty; Chinese",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2037,
    "FrequencyRank": 973
  },
  {
    "Character": "浩",
    "Pinyin": "hào",
    "Definition": "great, numerous, vast, abundant",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2073,
    "FrequencyRank": 1864
  },
  {
    "Character": "耸",
    "Pinyin": "sǒng",
    "Definition": "urge on; rise up; stir, excite",
    "Radical": "耳",
    "RadicalIndex": "128.4",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1984,
    "FrequencyRank": 2163
  },
  {
    "Character": "骏",
    "Pinyin": "jùn",
    "Definition": "excellent horse, noble steed",
    "Radical": "馬",
    "RadicalIndex": "187.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2154,
    "FrequencyRank": 3273
  },
  {
    "Character": "秧",
    "Pinyin": "yāng",
    "Definition": "rice seedlings, young rice plants",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1946,
    "FrequencyRank": 3522
  },
  {
    "Character": "倔",
    "Pinyin": "jué",
    "Definition": "stubborn, obstinate, intransigent; firm",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1974,
    "FrequencyRank": 3542
  },
  {
    "Character": "桨",
    "Pinyin": "jiǎng",
    "Definition": "oar, paddle",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2017,
    "FrequencyRank": 2939
  },
  {
    "Character": "浆",
    "Pinyin": "jiāng",
    "Definition": "any thick fluid; starch; broth",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2018,
    "FrequencyRank": 2626
  },
  {
    "Character": "桂",
    "Pinyin": "guì",
    "Definition": "cassia or cinnamon",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1835,
    "FrequencyRank": 1930
  },
  {
    "Character": "颂",
    "Pinyin": "sòng",
    "Definition": "laud, acclaim; hymn; ode",
    "Radical": "頁",
    "RadicalIndex": "181.4",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1991,
    "FrequencyRank": 2414
  },
  {
    "Character": "馁",
    "Pinyin": "něi",
    "Definition": "hungry, starving, famished",
    "Radical": "食",
    "RadicalIndex": "184.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2013,
    "FrequencyRank": 4162
  },
  {
    "Character": "秤",
    "Pinyin": "chèng",
    "Definition": "balance, scale, steelyard",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1943,
    "FrequencyRank": 3737
  },
  {
    "Character": "徐",
    "Pinyin": "xú",
    "Definition": "slowly, quietly, calmly; composed, dignified",
    "Radical": "彳",
    "RadicalIndex": "60.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1976,
    "FrequencyRank": 1313
  },
  {
    "Character": "涡",
    "Pinyin": "wō",
    "Definition": "swirl, whirlpool, eddy",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2072,
    "FrequencyRank": 2759
  },
  {
    "Character": "哺",
    "Pinyin": "bǔ",
    "Definition": "chew food; feed",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1894,
    "FrequencyRank": 2810
  },
  {
    "Character": "倘",
    "Pinyin": "tǎng",
    "Definition": "if, supposing, in event of",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1961,
    "FrequencyRank": 2118
  },
  {
    "Character": "酌",
    "Pinyin": "zhuó",
    "Definition": "serve wine; feast; deliberate",
    "Radical": "酉",
    "RadicalIndex": "164.3",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1857,
    "FrequencyRank": 2984
  },
  {
    "Character": "荷",
    "Pinyin": "hé",
    "Definition": "lotus, water lily, holland",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1826,
    "FrequencyRank": 1427
  },
  {
    "Character": "耕",
    "Pinyin": "gēng",
    "Definition": "plow, cultivate",
    "Radical": "耒",
    "RadicalIndex": "127.4",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1766,
    "FrequencyRank": 1850
  },
  {
    "Character": "莹",
    "Pinyin": "yíng",
    "Definition": "luster of gems; bright, lustrous",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1830,
    "FrequencyRank": 2668
  },
  {
    "Character": "涛",
    "Pinyin": "tāo",
    "Definition": "large waves",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2065,
    "FrequencyRank": 2054
  },
  {
    "Character": "赃",
    "Pinyin": "zāng",
    "Definition": "booty, loot, stolen goods; bribe",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1927,
    "FrequencyRank": 3296
  },
  {
    "Character": "倚",
    "Pinyin": "yǐ",
    "Definition": "rely on, depend on; lean heavily",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1957,
    "FrequencyRank": 2620
  },
  {
    "Character": "凄",
    "Pinyin": "qī",
    "Definition": "bitter cold, miserable, dreary",
    "Radical": "冫",
    "RadicalIndex": "15.8",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2015,
    "FrequencyRank": 2352
  },
  {
    "Character": "娥",
    "Pinyin": "é",
    "Definition": "be beautiful; good; surname",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2143,
    "FrequencyRank": 2784
  },
  {
    "Character": "屑",
    "Pinyin": "xiè",
    "Definition": "bits, scraps, crumbs, fragments",
    "Radical": "尸",
    "RadicalIndex": "44.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2133,
    "FrequencyRank": 2516
  },
  {
    "Character": "涤",
    "Pinyin": "dí",
    "Definition": "wash, cleanse, purify; sweep",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2079,
    "FrequencyRank": 3218
  },
  {
    "Character": "殷",
    "Pinyin": "yīn",
    "Definition": "many, great; abundant, flourishing",
    "Radical": "殳",
    "RadicalIndex": "79.6",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1977,
    "FrequencyRank": 2365
  },
  {
    "Character": "涝",
    "Pinyin": "lào",
    "Definition": "inundate, flood; torrent",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2067,
    "FrequencyRank": 4179
  },
  {
    "Character": "陵",
    "Pinyin": "líng",
    "Definition": "hill, mound; mausoleum",
    "Radical": "阜",
    "RadicalIndex": "170.8",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 2135,
    "FrequencyRank": 1965
  },
  {
    "Character": "耿",
    "Pinyin": "gěng",
    "Definition": "bright, shining; have guts",
    "Radical": "耳",
    "RadicalIndex": "128.4",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1817,
    "FrequencyRank": 2687
  },
  {
    "Character": "贾",
    "Pinyin": "jiǎ",
    "Definition": "surname; merchant; buy, trade",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": 9,
    "GeneralStandard": 1856,
    "FrequencyRank": 2051
  },
  {
    "Character": "惋",
    "Pinyin": "wǎn",
    "Definition": "regret, be sorry; alarmed",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2448,
    "FrequencyRank": 3592
  },
  {
    "Character": "缀",
    "Pinyin": "zhuì",
    "Definition": "patch together, link, connect",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2499,
    "FrequencyRank": 3002
  },
  {
    "Character": "捶",
    "Pinyin": "chuí",
    "Definition": "strike with stick, lash, beat",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2171,
    "FrequencyRank": 3478
  },
  {
    "Character": "舶",
    "Pinyin": "bó",
    "Definition": "large, ocean-going vessel",
    "Radical": "舟",
    "RadicalIndex": "137.5",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2344,
    "FrequencyRank": 2318
  },
  {
    "Character": "舵",
    "Pinyin": "duò",
    "Definition": "rudder, helm",
    "Radical": "舟",
    "RadicalIndex": "137.5",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2346,
    "FrequencyRank": 3088
  },
  {
    "Character": "淀",
    "Pinyin": "diàn",
    "Definition": "shallow water, swamp; swampy",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2431,
    "FrequencyRank": 3159
  },
  {
    "Character": "雀",
    "Pinyin": "què",
    "Definition": "sparrow",
    "Radical": "隹",
    "RadicalIndex": "172.3",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2255,
    "FrequencyRank": 2331
  },
  {
    "Character": "阎",
    "Pinyin": "yán",
    "Definition": "village gate; surname",
    "Radical": "門",
    "RadicalIndex": "169.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2396,
    "FrequencyRank": 2473
  },
  {
    "Character": "曹",
    "Pinyin": "cáo",
    "Definition": "ministry officials; surname",
    "Radical": "曰",
    "RadicalIndex": "73.7",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2233,
    "FrequencyRank": 1570
  },
  {
    "Character": "淮",
    "Pinyin": "huái",
    "Definition": "river in Anhui province",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2421,
    "FrequencyRank": 2293
  },
  {
    "Character": "阐",
    "Pinyin": "chǎn",
    "Definition": "explain, clarify, elucidate",
    "Radical": "門",
    "RadicalIndex": "169.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2397,
    "FrequencyRank": 2197
  },
  {
    "Character": "馅",
    "Pinyin": "xiàn",
    "Definition": "filling, stuffing; secret",
    "Radical": "食",
    "RadicalIndex": "184.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2372,
    "FrequencyRank": 3765
  },
  {
    "Character": "菊",
    "Pinyin": "jú",
    "Definition": "chrysanthemum",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2209,
    "FrequencyRank": 2509
  },
  {
    "Character": "啸",
    "Pinyin": "xiào",
    "Definition": "roar, howl, scream; whistle",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2291,
    "FrequencyRank": 2301
  },
  {
    "Character": "萎",
    "Pinyin": "wēi",
    "Definition": "wither, wilt",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2206,
    "FrequencyRank": 2757
  },
  {
    "Character": "萍",
    "Pinyin": "píng",
    "Definition": "duckweed; wandering, traveling",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2211,
    "FrequencyRank": 2180
  },
  {
    "Character": "躯",
    "Pinyin": "qū",
    "Definition": "body",
    "Radical": "身",
    "RadicalIndex": "158.4",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2335,
    "FrequencyRank": 2310
  },
  {
    "Character": "巢",
    "Pinyin": "cháo",
    "Definition": "nest, living quarter in tree",
    "Radical": "巛",
    "RadicalIndex": "47.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2500,
    "FrequencyRank": 2298
  },
  {
    "Character": "窑",
    "Pinyin": "yáo",
    "Definition": "kiln; coal mine pit",
    "Radical": "穴",
    "RadicalIndex": "116.6",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2457,
    "FrequencyRank": 2342
  },
  {
    "Character": "菩",
    "Pinyin": "pú",
    "Definition": "herb, aromatic plant",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2210,
    "FrequencyRank": 2209
  },
  {
    "Character": "逸",
    "Pinyin": "yì",
    "Definition": "flee, escape, break loose",
    "Radical": "辵",
    "RadicalIndex": "162.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2363,
    "FrequencyRank": 2338
  },
  {
    "Character": "渔",
    "Pinyin": "yú",
    "Definition": "to fish; seize; pursue; surname",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2425,
    "FrequencyRank": 1770
  },
  {
    "Character": "颈",
    "Pinyin": "jǐng",
    "Definition": "neck, throat",
    "Radical": "頁",
    "RadicalIndex": "181.5",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2485,
    "FrequencyRank": 2117
  },
  {
    "Character": "晤",
    "Pinyin": "wù",
    "Definition": "have interview with; meet",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 4813,
    "FrequencyRank": 2719
  },
  {
    "Character": "庸",
    "Pinyin": "yōng",
    "Definition": "usual, common, ordinary, mediocre",
    "Radical": "广",
    "RadicalIndex": "53.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2386,
    "FrequencyRank": 2148
  },
  {
    "Character": "焕",
    "Pinyin": "huàn",
    "Definition": "shining",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2409,
    "FrequencyRank": 2773
  },
  {
    "Character": "勘",
    "Pinyin": "kān",
    "Definition": "investigate; compare; collate",
    "Radical": "力",
    "RadicalIndex": "19.9",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2195,
    "FrequencyRank": 2585
  },
  {
    "Character": "萨",
    "Pinyin": "sà",
    "Definition": "Buddhist gods or immortals",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2217,
    "FrequencyRank": 1046
  },
  {
    "Character": "笛",
    "Pinyin": "dí",
    "Definition": "bamboo flute; whistle",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2319,
    "FrequencyRank": 2561
  },
  {
    "Character": "梢",
    "Pinyin": "shāo",
    "Definition": "pointed tip of something long like a branch; rudder",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2225,
    "FrequencyRank": 2785
  },
  {
    "Character": "萌",
    "Pinyin": "méng",
    "Definition": "bud, sprout",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2203,
    "FrequencyRank": 2879
  },
  {
    "Character": "烹",
    "Pinyin": "pēng",
    "Definition": "boil, cook; quick fry, stir fry",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2377,
    "FrequencyRank": 3461
  },
  {
    "Character": "渊",
    "Pinyin": "yuān",
    "Definition": "surge up, bubble up, gush forth",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2423,
    "FrequencyRank": 2192
  },
  {
    "Character": "猖",
    "Pinyin": "chāng",
    "Definition": "mad, wild, reckless, unruly",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2369,
    "FrequencyRank": 3392
  },
  {
    "Character": "彬",
    "Pinyin": "bīn",
    "Definition": "cultivated, well-bred",
    "Radical": "彡",
    "RadicalIndex": "59.8",
    "StrokeCount": 11,
    "HSK": 9,
    "GeneralStandard": 2220,
    "FrequencyRank": 2736
  },
  {
    "Character": "隘",
    "Pinyin": "ài",
    "Definition": "narrow, confined; a strategic pass",
    "Radical": "阜",
    "RadicalIndex": "170.1",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2807,
    "FrequencyRank": 3152
  },
  {
    "Character": "敦",
    "Pinyin": "dūn",
    "Definition": "esteem; honest, candid, sincere",
    "Radical": "攴",
    "RadicalIndex": "66.8",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2729,
    "FrequencyRank": 1722
  },
  {
    "Character": "溉",
    "Pinyin": "gài",
    "Definition": "water, irrigate, flood; wash",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2769,
    "FrequencyRank": 3524
  },
  {
    "Character": "腊",
    "Pinyin": "là",
    "Definition": "year end sacrifice; dried meat",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2712,
    "FrequencyRank": 1497
  },
  {
    "Character": "嫂",
    "Pinyin": "sǎo",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2810,
    "FrequencyRank": 2142
  },
  {
    "Character": "缆",
    "Pinyin": "lǎn",
    "Definition": "hawser, heavy-duty rope, cable",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2815,
    "FrequencyRank": 2821
  },
  {
    "Character": "蒂",
    "Pinyin": "dì",
    "Definition": "peduncle or stem of plants",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2556,
    "FrequencyRank": 1429
  },
  {
    "Character": "嵌",
    "Pinyin": "qiàn",
    "Definition": "inlay, set in; fall into; rugged",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2647,
    "FrequencyRank": 2831
  },
  {
    "Character": "遏",
    "Pinyin": "è",
    "Definition": "stop, suppress, curb, check; a bar",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2624,
    "FrequencyRank": 2701
  },
  {
    "Character": "缔",
    "Pinyin": "dì",
    "Definition": "tie, join, connect; connection",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2819,
    "FrequencyRank": 2688
  },
  {
    "Character": "渝",
    "Pinyin": "yú",
    "Definition": "change; chongqing",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2763,
    "FrequencyRank": 3641
  },
  {
    "Character": "畴",
    "Pinyin": "chóu",
    "Definition": "farmland, arable land; category",
    "Radical": "田",
    "RadicalIndex": "102.7",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2627,
    "FrequencyRank": 2699
  },
  {
    "Character": "奠",
    "Pinyin": "diàn",
    "Definition": "pay respect; settle",
    "Radical": "大",
    "RadicalIndex": "37.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2744,
    "FrequencyRank": 2656
  },
  {
    "Character": "逾",
    "Pinyin": "yú",
    "Definition": "go over, pass over, exceed",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2708,
    "FrequencyRank": 2610
  },
  {
    "Character": "酣",
    "Pinyin": "hān",
    "Definition": "enjoy intoxicants",
    "Radical": "酉",
    "RadicalIndex": "164.5",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2585,
    "FrequencyRank": 3582
  },
  {
    "Character": "搀",
    "Pinyin": "chān",
    "Definition": "give helping hand",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2531,
    "FrequencyRank": 3384
  },
  {
    "Character": "禽",
    "Pinyin": "qín",
    "Definition": "birds, fowl; surname;; capture",
    "Radical": "禸",
    "RadicalIndex": "114.8",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2711,
    "FrequencyRank": 2562
  },
  {
    "Character": "铸",
    "Pinyin": "zhù",
    "Definition": "melt, cast; coin, mint",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2656,
    "FrequencyRank": 2530
  },
  {
    "Character": "渣",
    "Pinyin": "zhā",
    "Definition": "refuse, dregs, lees, sediment",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2753,
    "FrequencyRank": 3196
  },
  {
    "Character": "喧",
    "Pinyin": "xuān",
    "Definition": "lively, noisy; clamor, talk loudly",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2646,
    "FrequencyRank": 2778
  },
  {
    "Character": "鼎",
    "Pinyin": "dǐng",
    "Definition": "large, three-legged bronze caldron",
    "Radical": "鼎",
    "RadicalIndex": "206",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2617,
    "FrequencyRank": 2356
  },
  {
    "Character": "粤",
    "Pinyin": "yuè",
    "Definition": "Guangdong and Guangxi provinces; initial particle",
    "Radical": "米",
    "RadicalIndex": "119.6",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2700,
    "FrequencyRank": 3151
  },
  {
    "Character": "竣",
    "Pinyin": "jùn",
    "Definition": "terminate, end, finish; quit",
    "Radical": "立",
    "RadicalIndex": "117.7",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2736,
    "FrequencyRank": 3704
  },
  {
    "Character": "缅",
    "Pinyin": "miǎn",
    "Definition": "distant, remote; think of",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2814,
    "FrequencyRank": 2676
  },
  {
    "Character": "窘",
    "Pinyin": "jiǒng",
    "Definition": "embarrassed; hard-pressed",
    "Radical": "穴",
    "RadicalIndex": "116.7",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2787,
    "FrequencyRank": 2979
  },
  {
    "Character": "筐",
    "Pinyin": "kuāng",
    "Definition": "bamboo basket or chest",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2680,
    "FrequencyRank": 3419
  },
  {
    "Character": "屡",
    "Pinyin": "lǚ",
    "Definition": "frequently, often, again and again",
    "Radical": "尸",
    "RadicalIndex": "44.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2801,
    "FrequencyRank": 2450
  },
  {
    "Character": "棱",
    "Pinyin": "léng",
    "Definition": "squared timber; angle, edge",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2563,
    "FrequencyRank": 2897
  },
  {
    "Character": "雁",
    "Pinyin": "yàn",
    "Definition": "wild goose",
    "Radical": "隹",
    "RadicalIndex": "172.4",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2593,
    "FrequencyRank": 2553
  },
  {
    "Character": "禅",
    "Pinyin": "chán",
    "Definition": undefined,
    "Radical": "示",
    "RadicalIndex": "113.8",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2793,
    "FrequencyRank": 2196
  },
  {
    "Character": "缕",
    "Pinyin": "lǚ",
    "Definition": "thread; detailed, precise",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2820,
    "FrequencyRank": 2894
  },
  {
    "Character": "滞",
    "Pinyin": "zhì",
    "Definition": "block up, obstruct; stagnant",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2750,
    "FrequencyRank": 2302
  },
  {
    "Character": "棚",
    "Pinyin": "péng",
    "Definition": "tent, awning; booth; shed",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2575,
    "FrequencyRank": 2448
  },
  {
    "Character": "遂",
    "Pinyin": "suì",
    "Definition": "comply with, follow along; thereupon",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2746,
    "FrequencyRank": 2017
  },
  {
    "Character": "馋",
    "Pinyin": "chán",
    "Definition": "gluttonous, greedy; lewd, lecherous",
    "Radical": "食",
    "RadicalIndex": "184.9",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2725,
    "FrequencyRank": 3844
  },
  {
    "Character": "隙",
    "Pinyin": "xì",
    "Definition": "crack, split, fissure; grudge",
    "Radical": "阜",
    "RadicalIndex": "170.1",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2806,
    "FrequencyRank": 2419
  },
  {
    "Character": "韩",
    "Pinyin": "hán",
    "Definition": "fence; surname; Korea",
    "Radical": "韋",
    "RadicalIndex": "178.8",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2558,
    "FrequencyRank": 1221
  },
  {
    "Character": "絮",
    "Pinyin": "xù",
    "Definition": "waste cotton, raw silk or cotton",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 12,
    "HSK": 9,
    "GeneralStandard": 2809,
    "FrequencyRank": 3125
  },
  {
    "Character": "腥",
    "Pinyin": "xīng",
    "Definition": "raw meat; rank, strong-smelling",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2970,
    "FrequencyRank": 2747
  },
  {
    "Character": "颓",
    "Pinyin": "tuí",
    "Definition": "ruined, decayed; disintegrate",
    "Radical": "頁",
    "RadicalIndex": "181.7",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2950,
    "FrequencyRank": 2996
  },
  {
    "Character": "窟",
    "Pinyin": "kū",
    "Definition": "hole, cave; cellar; underground",
    "Radical": "穴",
    "RadicalIndex": "116.8",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 3028,
    "FrequencyRank": 2924
  },
  {
    "Character": "楷",
    "Pinyin": "kǎi",
    "Definition": "model style of Chinese writing",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2865,
    "FrequencyRank": 3530
  },
  {
    "Character": "煲",
    "Pinyin": "bāo",
    "Definition": "to heat; to boil a saucepan",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 5506,
    "FrequencyRank": 4865
  },
  {
    "Character": "慑",
    "Pinyin": "shè",
    "Definition": "afraid, scared, fearful",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 5589,
    "FrequencyRank": 2970
  },
  {
    "Character": "溢",
    "Pinyin": "yì",
    "Definition": "overflow, brim over; full",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 3016,
    "FrequencyRank": 2454
  },
  {
    "Character": "韵",
    "Pinyin": "yùn",
    "Definition": "rhyme; vowel",
    "Radical": "音",
    "RadicalIndex": "180.4",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2995,
    "FrequencyRank": 2501
  },
  {
    "Character": "滔",
    "Pinyin": "tāo",
    "Definition": "overflow; rushing water, a torrent",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 3011,
    "FrequencyRank": 2760
  },
  {
    "Character": "溶",
    "Pinyin": "róng",
    "Definition": "to melt, dissolve; overflowing with",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 3019,
    "FrequencyRank": 2278
  },
  {
    "Character": "嗦",
    "Pinyin": "suo",
    "Definition": "suck",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2904,
    "FrequencyRank": 3089
  },
  {
    "Character": "媳",
    "Pinyin": "xí",
    "Definition": "daughter-in-law",
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 3039,
    "FrequencyRank": 2393
  },
  {
    "Character": "槐",
    "Pinyin": "huái",
    "Definition": "locust tree",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2868,
    "FrequencyRank": 3120
  },
  {
    "Character": "缤",
    "Pinyin": "bīn",
    "Definition": "flourishing, thriving, abundant",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 3047,
    "FrequencyRank": 3795
  },
  {
    "Character": "稠",
    "Pinyin": "chóu",
    "Definition": "dense, crowded, packed; soupy",
    "Radical": "禾",
    "RadicalIndex": "115.8",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2949,
    "FrequencyRank": 3367
  },
  {
    "Character": "瑞",
    "Pinyin": "ruì",
    "Definition": "felicitous omen; auspicious",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2827,
    "FrequencyRank": 1332
  },
  {
    "Character": "腺",
    "Pinyin": "xiàn",
    "Definition": "gland",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2973,
    "FrequencyRank": 2257
  },
  {
    "Character": "腻",
    "Pinyin": "nì",
    "Definition": "greasy, oily, dirty; smooth",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2968,
    "FrequencyRank": 2819
  },
  {
    "Character": "蓬",
    "Pinyin": "péng",
    "Definition": "type of raspberry; fairyland",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2855,
    "FrequencyRank": 2398
  },
  {
    "Character": "鹏",
    "Pinyin": "péng",
    "Definition": "fabulous bird of enormous size",
    "Radical": "鳥",
    "RadicalIndex": "196.8",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2974,
    "FrequencyRank": 1926
  },
  {
    "Character": "锦",
    "Pinyin": "jǐn",
    "Definition": "brocade, tapestry; embroidered",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2942,
    "FrequencyRank": 1947
  },
  {
    "Character": "辟",
    "Pinyin": "pì",
    "Definition": "law, rule; open up, develop",
    "Radical": "辛",
    "RadicalIndex": "160.6",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 3037,
    "FrequencyRank": 2140
  },
  {
    "Character": "蜀",
    "Pinyin": "shǔ",
    "Definition": "name of an ancient state",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 2934,
    "FrequencyRank": 2602
  },
  {
    "Character": "媲",
    "Pinyin": "pì",
    "Definition": "marry off, pair, match; compare",
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": 9,
    "GeneralStandard": 5606,
    "FrequencyRank": 3911
  },
  {
    "Character": "孵",
    "Pinyin": "fū",
    "Definition": "sit on eggs, hatch",
    "Radical": "子",
    "RadicalIndex": "39.11",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3137,
    "FrequencyRank": 3256
  },
  {
    "Character": "兢",
    "Pinyin": "jīng",
    "Definition": "fearful, cautious, wary",
    "Radical": "儿",
    "RadicalIndex": "10.12",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3077,
    "FrequencyRank": 3117
  },
  {
    "Character": "蔑",
    "Pinyin": "miè",
    "Definition": "disdain, disregard; slight",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3070,
    "FrequencyRank": 2575
  },
  {
    "Character": "槛",
    "Pinyin": "kǎn",
    "Definition": "threshold, door-sill",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3079,
    "FrequencyRank": 3379
  },
  {
    "Character": "寥",
    "Pinyin": "liáo",
    "Definition": "few, scarce; empty, deserted",
    "Radical": "宀",
    "RadicalIndex": "40.11",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3176,
    "FrequencyRank": 3116
  },
  {
    "Character": "寨",
    "Pinyin": "zhài",
    "Definition": "stockade, stronghold, outpost; brothel",
    "Radical": "宀",
    "RadicalIndex": "40.11",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3171,
    "FrequencyRank": 2313
  },
  {
    "Character": "翠",
    "Pinyin": "cuì",
    "Definition": "color green; kingfisher",
    "Radical": "羽",
    "RadicalIndex": "124.8",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3184,
    "FrequencyRank": 2177
  },
  {
    "Character": "磋",
    "Pinyin": "cuō",
    "Definition": "polish, buff; scrutinize",
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 5680,
    "FrequencyRank": 3261
  },
  {
    "Character": "彰",
    "Pinyin": "zhāng",
    "Definition": "clear, manifest, obvious",
    "Radical": "彡",
    "RadicalIndex": "59.11",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3149,
    "FrequencyRank": 2953
  },
  {
    "Character": "碟",
    "Pinyin": "dié",
    "Definition": "small dish, plate",
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3090,
    "FrequencyRank": 2835
  },
  {
    "Character": "嫦",
    "Pinyin": "cháng",
    "Definition": "name of a moon goddess",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 5833,
    "FrequencyRank": 3166
  },
  {
    "Character": "粽",
    "Pinyin": "zòng",
    "Definition": "dumpling made of glutinous rice",
    "Radical": "米",
    "RadicalIndex": "119.8",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 5794,
    "FrequencyRank": 5076
  },
  {
    "Character": "漾",
    "Pinyin": "yàng",
    "Definition": "overflow; swirl, ripple; to be tosssed by waves",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3166,
    "FrequencyRank": 3697
  },
  {
    "Character": "碧",
    "Pinyin": "bì",
    "Definition": "jade; green, blue",
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3050,
    "FrequencyRank": 2165
  },
  {
    "Character": "踊",
    "Pinyin": "yǒng",
    "Definition": "leap, jump",
    "Radical": "足",
    "RadicalIndex": "157.7",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3104,
    "FrequencyRank": 3884
  },
  {
    "Character": "赫",
    "Pinyin": "hè",
    "Definition": "bright, radiant, glowing",
    "Radical": "赤",
    "RadicalIndex": "155.7",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3058,
    "FrequencyRank": 1346
  },
  {
    "Character": "僧",
    "Pinyin": "sēng",
    "Definition": "Buddhist priest, monk; san of Sanskrit sangha",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3127,
    "FrequencyRank": 2013
  },
  {
    "Character": "熙",
    "Pinyin": "xī",
    "Definition": "bright, splendid, glorious",
    "Radical": "火",
    "RadicalIndex": "86.1",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3075,
    "FrequencyRank": 2485
  },
  {
    "Character": "瞅",
    "Pinyin": "chǒu",
    "Definition": "see, look, gaze at",
    "Radical": "目",
    "RadicalIndex": "109.9",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3101,
    "FrequencyRank": 2767
  },
  {
    "Character": "魄",
    "Pinyin": "pò",
    "Definition": "vigor; body; dark part of moon",
    "Radical": "鬼",
    "RadicalIndex": "194.5",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3129,
    "FrequencyRank": 2511
  },
  {
    "Character": "暮",
    "Pinyin": "mù",
    "Definition": "evening, dusk, sunset; ending",
    "Radical": "日",
    "RadicalIndex": "72.11",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3067,
    "FrequencyRank": 2768
  },
  {
    "Character": "酵",
    "Pinyin": "jiào",
    "Definition": "yeast, leaven",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": 9,
    "GeneralStandard": 3086,
    "FrequencyRank": 3531
  },
  {
    "Character": "澳",
    "Pinyin": "ào",
    "Definition": "inlet, bay; dock, bank",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 3295,
    "FrequencyRank": 1627
  },
  {
    "Character": "撰",
    "Pinyin": "zhuàn",
    "Definition": "compose, write, compile",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 3205,
    "FrequencyRank": 2300
  },
  {
    "Character": "潭",
    "Pinyin": "tán",
    "Definition": "deep pool, lake; deep, profound",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 3293,
    "FrequencyRank": 2750
  },
  {
    "Character": "蕴",
    "Pinyin": "yùn",
    "Definition": "to collect, gather, store; profound",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 3212,
    "FrequencyRank": 2633
  },
  {
    "Character": "嬉",
    "Pinyin": "xī",
    "Definition": "enjoy; play, amuse oneself",
    "Radical": "女",
    "RadicalIndex": "38.12",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 6017,
    "FrequencyRank": 3375
  },
  {
    "Character": "磊",
    "Pinyin": "lěi",
    "Definition": "pile of rocks or stones; great",
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 3226,
    "FrequencyRank": 3423
  },
  {
    "Character": "鹤",
    "Pinyin": "hè",
    "Definition": "crane; Grus species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.1",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 3308,
    "FrequencyRank": 2624
  },
  {
    "Character": "毅",
    "Pinyin": "yì",
    "Definition": "resolute, decisive, firm, persist",
    "Radical": "殳",
    "RadicalIndex": "79.11",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 3286,
    "FrequencyRank": 1946
  },
  {
    "Character": "潦",
    "Pinyin": "lǎo",
    "Definition": "to flood; a puddle; without care",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 6000,
    "FrequencyRank": 3739
  },
  {
    "Character": "潘",
    "Pinyin": "pān",
    "Definition": "surname; water in which rice has been rinsed; a river that flows into the Han",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 3296,
    "FrequencyRank": 2070
  },
  {
    "Character": "澜",
    "Pinyin": "lán",
    "Definition": "overflowing; waves, ripples",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 3298,
    "FrequencyRank": 2789
  },
  {
    "Character": "醇",
    "Pinyin": "chún",
    "Definition": "rich, good as wine; pure, unmixed",
    "Radical": "酉",
    "RadicalIndex": "164.8",
    "StrokeCount": 15,
    "HSK": 9,
    "GeneralStandard": 3223,
    "FrequencyRank": 2560
  },
  {
    "Character": "儒",
    "Pinyin": "rú",
    "Definition": "Confucian scholar",
    "Radical": "人",
    "RadicalIndex": "9.14",
    "StrokeCount": 16,
    "HSK": 9,
    "GeneralStandard": 3361,
    "FrequencyRank": 2006
  },
  {
    "Character": "辙",
    "Pinyin": "zhé",
    "Definition": "wagon ruts, wheel tracks",
    "Radical": "車",
    "RadicalIndex": "159.12",
    "StrokeCount": 16,
    "HSK": 9,
    "GeneralStandard": 3339,
    "FrequencyRank": 3208
  },
  {
    "Character": "窿",
    "Pinyin": "lóng",
    "Definition": "mine shaft; cavity, hole",
    "Radical": "穴",
    "RadicalIndex": "116.12",
    "StrokeCount": 16,
    "HSK": 9,
    "GeneralStandard": 3383,
    "FrequencyRank": 3845
  },
  {
    "Character": "飙",
    "Pinyin": "biāo",
    "Definition": "whirlwind, stormy gale",
    "Radical": "風",
    "RadicalIndex": "182.12",
    "StrokeCount": 16,
    "HSK": 9,
    "GeneralStandard": 6074,
    "FrequencyRank": 4091
  },
  {
    "Character": "懈",
    "Pinyin": "xiè",
    "Definition": "idle, relaxed, remiss",
    "Radical": "心",
    "RadicalIndex": "61.13",
    "StrokeCount": 16,
    "HSK": 9,
    "GeneralStandard": 3382,
    "FrequencyRank": 2689
  },
  {
    "Character": "霍",
    "Pinyin": "huò",
    "Definition": "quickly, suddenly; surname",
    "Radical": "雨",
    "RadicalIndex": "173.8",
    "StrokeCount": 16,
    "HSK": 9,
    "GeneralStandard": 3337,
    "FrequencyRank": 1442
  },
  {
    "Character": "燕",
    "Pinyin": "yàn",
    "Definition": "swallow (bird); comfort, enjoy",
    "Radical": "火",
    "RadicalIndex": "86.12",
    "StrokeCount": 16,
    "HSK": 9,
    "GeneralStandard": 3319,
    "FrequencyRank": 1705
  },
  {
    "Character": "膳",
    "Pinyin": "shàn",
    "Definition": "meals, provisions, board",
    "Radical": "肉",
    "RadicalIndex": "130.12",
    "StrokeCount": 16,
    "HSK": 9,
    "GeneralStandard": 6136,
    "FrequencyRank": 3300
  },
  {
    "Character": "橱",
    "Pinyin": "chú",
    "Definition": "cabinet, wardrobe, cupboard",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": 9,
    "GeneralStandard": 3330,
    "FrequencyRank": 3041
  },
  {
    "Character": "豁",
    "Pinyin": "huō",
    "Definition": "open up, clear; exempt",
    "Radical": "谷",
    "RadicalIndex": "150.1",
    "StrokeCount": 17,
    "HSK": 9,
    "GeneralStandard": 3432,
    "FrequencyRank": 2954
  },
  {
    "Character": "赡",
    "Pinyin": "shàn",
    "Definition": "support, aid; rich, elegant",
    "Radical": "貝",
    "RadicalIndex": "154.13",
    "StrokeCount": 17,
    "HSK": 9,
    "GeneralStandard": 3414,
    "FrequencyRank": 3902
  },
  {
    "Character": "曙",
    "Pinyin": "shǔ",
    "Definition": "bright, light of rising sun",
    "Radical": "日",
    "RadicalIndex": "72.14",
    "StrokeCount": 17,
    "HSK": 9,
    "GeneralStandard": 3407,
    "FrequencyRank": 3385
  },
  {
    "Character": "朦",
    "Pinyin": "méng",
    "Definition": "condition or appearance of moon",
    "Radical": "月",
    "RadicalIndex": "74.14",
    "StrokeCount": 17,
    "HSK": 9,
    "GeneralStandard": 3422,
    "FrequencyRank": 2826
  },
  {
    "Character": "臊",
    "Pinyin": "sāo",
    "Definition": "rank; rancid; frowzy, fetid; bashful",
    "Radical": "肉",
    "RadicalIndex": "130.13",
    "StrokeCount": 17,
    "HSK": 9,
    "GeneralStandard": 3423,
    "FrequencyRank": 4389
  },
  {
    "Character": "礁",
    "Pinyin": "jiāo",
    "Definition": "reef; jetty; submerged rocks",
    "Radical": "石",
    "RadicalIndex": "112.12",
    "StrokeCount": 17,
    "HSK": 9,
    "GeneralStandard": 3397,
    "FrequencyRank": 2952
  },
  {
    "Character": "霞",
    "Pinyin": "xiá",
    "Definition": "rosy clouds",
    "Radical": "雨",
    "RadicalIndex": "173.9",
    "StrokeCount": 17,
    "HSK": 9,
    "GeneralStandard": 3400,
    "FrequencyRank": 2021
  },
  {
    "Character": "簇",
    "Pinyin": "cù",
    "Definition": "swarm, crowd together, cluster",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 17,
    "HSK": 9,
    "GeneralStandard": 3418,
    "FrequencyRank": 2896
  },
  {
    "Character": "魏",
    "Pinyin": "wèi",
    "Definition": "kingdom of Wei; surname",
    "Radical": "鬼",
    "RadicalIndex": "194.8",
    "StrokeCount": 17,
    "HSK": 9,
    "GeneralStandard": 3416,
    "FrequencyRank": 1648
  },
  {
    "Character": "徽",
    "Pinyin": "huī",
    "Definition": "a badge, insignia",
    "Radical": "彳",
    "RadicalIndex": "60.14",
    "StrokeCount": 17,
    "HSK": 9,
    "GeneralStandard": 3420,
    "FrequencyRank": 2277
  },
  {
    "Character": "鞭",
    "Pinyin": "biān",
    "Definition": "whip; whip; string of firecrackers",
    "Radical": "革",
    "RadicalIndex": "177.9",
    "StrokeCount": 18,
    "HSK": 9,
    "GeneralStandard": 3438,
    "FrequencyRank": 2250
  },
  {
    "Character": "藤",
    "Pinyin": "téng",
    "Definition": "rattan, cane; creeper plant",
    "Radical": "艸",
    "RadicalIndex": "140.15",
    "StrokeCount": 18,
    "HSK": 9,
    "GeneralStandard": 3439,
    "FrequencyRank": 2200
  },
  {
    "Character": "瞻",
    "Pinyin": "zhān",
    "Definition": "look; look out for; respect",
    "Radical": "目",
    "RadicalIndex": "109.13",
    "StrokeCount": 18,
    "HSK": 9,
    "GeneralStandard": 3441,
    "FrequencyRank": 2960
  },
  {
    "Character": "疆",
    "Pinyin": "jiāng",
    "Definition": "boundary, border, frontier",
    "Radical": "田",
    "RadicalIndex": "102.14",
    "StrokeCount": 19,
    "HSK": 9,
    "GeneralStandard": 3472,
    "FrequencyRank": 2036
  },
  {
    "Character": "譬",
    "Pinyin": "pì",
    "Definition": "metaphor, simile, example",
    "Radical": "言",
    "RadicalIndex": "149.13",
    "StrokeCount": 20,
    "HSK": 9,
    "GeneralStandard": 3487,
    "FrequencyRank": 2441
  },
  {
    "Character": "攘",
    "Pinyin": "rǎng",
    "Definition": "seize, take by force; repel",
    "Radical": "手",
    "RadicalIndex": "64.17",
    "StrokeCount": 20,
    "HSK": 9,
    "GeneralStandard": 6421,
    "FrequencyRank": 3404
  },
  {
    "Character": "霸",
    "Pinyin": "bà",
    "Definition": "rule by might rather than right",
    "Radical": "雨",
    "RadicalIndex": "173.13",
    "StrokeCount": 21,
    "HSK": 9,
    "GeneralStandard": 3489,
    "FrequencyRank": 1838
  },
  {
    "Character": "囊",
    "Pinyin": "náng",
    "Definition": "bag, purse, sack; put in bag",
    "Radical": "口",
    "RadicalIndex": "30.19",
    "StrokeCount": 22,
    "HSK": 9,
    "GeneralStandard": 3496,
    "FrequencyRank": 2041
  },
  {
    "Character": "乜",
    "Pinyin": "miē",
    "Definition": "to squint; (Cant.) what? huh?",
    "Radical": "乙",
    "RadicalIndex": "5.1",
    "StrokeCount": 2,
    "HSK": undefined,
    "GeneralStandard": 3502,
    "FrequencyRank": 5265
  },
  {
    "Character": "乂",
    "Pinyin": "yì",
    "Definition": "govern, control, manage; nurture",
    "Radical": "丿",
    "RadicalIndex": "4.1",
    "StrokeCount": 2,
    "HSK": undefined,
    "GeneralStandard": 3501,
    "FrequencyRank": undefined
  },
  {
    "Character": "匕",
    "Pinyin": "bǐ",
    "Definition": "spoon, ladle; knife, dirk",
    "Radical": "匕",
    "RadicalIndex": "21",
    "StrokeCount": 2,
    "HSK": undefined,
    "GeneralStandard": 13,
    "FrequencyRank": 3252
  },
  {
    "Character": "彳",
    "Pinyin": "chì",
    "Definition": "step with left foot; rad. no 60",
    "Radical": "彳",
    "RadicalIndex": "60",
    "StrokeCount": 3,
    "HSK": undefined,
    "GeneralStandard": 6503,
    "FrequencyRank": undefined
  },
  {
    "Character": "巳",
    "Pinyin": "sì",
    "Definition": "the hours from 9 to 11; 6th terrestrial branch",
    "Radical": "己",
    "RadicalIndex": "49",
    "StrokeCount": 3,
    "HSK": undefined,
    "GeneralStandard": 62,
    "FrequencyRank": 3381
  },
  {
    "Character": "兀",
    "Pinyin": "wù",
    "Definition": "to cut off the feet",
    "Radical": "儿",
    "RadicalIndex": "10.1",
    "StrokeCount": 3,
    "HSK": undefined,
    "GeneralStandard": 3503,
    "FrequencyRank": 2859
  },
  {
    "Character": "孓",
    "Pinyin": "jué",
    "Definition": "beautiful",
    "Radical": "子",
    "RadicalIndex": "39",
    "StrokeCount": 3,
    "HSK": undefined,
    "GeneralStandard": 3506,
    "FrequencyRank": 6370
  },
  {
    "Character": "弋",
    "Pinyin": "yì",
    "Definition": "catch, arrest; shoot with bow",
    "Radical": "弋",
    "RadicalIndex": "56",
    "StrokeCount": 3,
    "HSK": undefined,
    "GeneralStandard": 3504,
    "FrequencyRank": 4265
  },
  {
    "Character": "刃",
    "Pinyin": "rèn",
    "Definition": "edged tool, cutlery, knife edge",
    "Radical": "刀",
    "RadicalIndex": "18.1",
    "StrokeCount": 3,
    "HSK": undefined,
    "GeneralStandard": 68,
    "FrequencyRank": 2700
  },
  {
    "Character": "亍",
    "Pinyin": "chù",
    "Definition": "to take small steps; Korean place name",
    "Radical": "二",
    "RadicalIndex": "7.1",
    "StrokeCount": 3,
    "HSK": undefined,
    "GeneralStandard": 6501,
    "FrequencyRank": undefined
  },
  {
    "Character": "幺",
    "Pinyin": "yāo",
    "Definition": "one; tiny, small",
    "Radical": "幺",
    "RadicalIndex": "52",
    "StrokeCount": 3,
    "HSK": undefined,
    "GeneralStandard": 3507,
    "FrequencyRank": 4505
  },
  {
    "Character": "尢",
    "Pinyin": "yóu",
    "Definition": "weak; KangXi radical 43",
    "Radical": "尢",
    "RadicalIndex": "43",
    "StrokeCount": 3,
    "HSK": undefined,
    "GeneralStandard": 6502,
    "FrequencyRank": 6516
  },
  {
    "Character": "孑",
    "Pinyin": "jié",
    "Definition": "remaining, left-over; lonely",
    "Radical": "子",
    "RadicalIndex": "39",
    "StrokeCount": 3,
    "HSK": undefined,
    "GeneralStandard": 3505,
    "FrequencyRank": 4691
  },
  {
    "Character": "仑",
    "Pinyin": "lún",
    "Definition": "logical reasons, logical order",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 143,
    "FrequencyRank": 2139
  },
  {
    "Character": "亓",
    "Pinyin": "qí",
    "Definition": "(archaic form) his, her, its, their; that",
    "Radical": "二",
    "RadicalIndex": "7.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3508,
    "FrequencyRank": 5651
  },
  {
    "Character": "韦",
    "Pinyin": "wéi",
    "Definition": "tanned leather; surname; simplified form of KangXi radical number 178",
    "Radical": "韋",
    "RadicalIndex": "178",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3509,
    "FrequencyRank": 1667
  },
  {
    "Character": "殳",
    "Pinyin": "shū",
    "Definition": "name of old weapon; kill; rad. 79",
    "Radical": "殳",
    "RadicalIndex": "79",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 6505,
    "FrequencyRank": 6005
  },
  {
    "Character": "兮",
    "Pinyin": "xī",
    "Definition": "exclamatory particle",
    "Radical": "八",
    "RadicalIndex": "12.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3518,
    "FrequencyRank": 2772
  },
  {
    "Character": "毌",
    "Pinyin": "guàn",
    "Definition": "old form of 貫; component in 贯,貫,實, etc.; not to be confused with 毋",
    "Radical": "毋",
    "RadicalIndex": "80",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 6507,
    "FrequencyRank": undefined
  },
  {
    "Character": "夬",
    "Pinyin": "guài",
    "Definition": "parted; fork; certain",
    "Radical": "大",
    "RadicalIndex": "37.1",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3525,
    "FrequencyRank": undefined
  },
  {
    "Character": "卬",
    "Pinyin": "áng",
    "Definition": "lofty; high; raise; high-priced",
    "Radical": "卩",
    "RadicalIndex": "26.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 6504,
    "FrequencyRank": undefined
  },
  {
    "Character": "卞",
    "Pinyin": "biàn",
    "Definition": "to be impatient, in a hurry; excitable",
    "Radical": "卜",
    "RadicalIndex": "25.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3521,
    "FrequencyRank": 4534
  },
  {
    "Character": "亢",
    "Pinyin": "kàng",
    "Definition": "high, proud; violent, excessive; skilled; name",
    "Radical": "亠",
    "RadicalIndex": "8.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 162,
    "FrequencyRank": 3268
  },
  {
    "Character": "𠙶",
    "Pinyin": "ǒu",
    "Definition": undefined,
    "Radical": "凵",
    "RadicalIndex": "17.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 6506,
    "FrequencyRank": undefined
  },
  {
    "Character": "刈",
    "Pinyin": "yì",
    "Definition": "cut off, reap, mow; sickle",
    "Radical": "刀",
    "RadicalIndex": "18.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3519,
    "FrequencyRank": 4738
  },
  {
    "Character": "丏",
    "Pinyin": "miǎn",
    "Definition": "parapet; invisible",
    "Radical": "一",
    "RadicalIndex": "1.3",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3511,
    "FrequencyRank": undefined
  },
  {
    "Character": "仄",
    "Pinyin": "zè",
    "Definition": "slanting, oblique; oblique tones",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3513,
    "FrequencyRank": 4282
  },
  {
    "Character": "仉",
    "Pinyin": "zhǎng",
    "Definition": "surname of the mother of Mencius",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3516,
    "FrequencyRank": 7373
  },
  {
    "Character": "爻",
    "Pinyin": "yáo",
    "Definition": "diagrams for divination",
    "Radical": "爻",
    "RadicalIndex": "89",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3520,
    "FrequencyRank": 4680
  },
  {
    "Character": "仃",
    "Pinyin": "dīng",
    "Definition": "lonely, solitary",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3515,
    "FrequencyRank": 4973
  },
  {
    "Character": "爿",
    "Pinyin": "pán",
    "Definition": "half of tree trunk; KangXi radical 90",
    "Radical": "爿",
    "RadicalIndex": "90",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3526,
    "FrequencyRank": undefined
  },
  {
    "Character": "闩",
    "Pinyin": "shuān",
    "Definition": "bolt, latch, crossbar",
    "Radical": "門",
    "RadicalIndex": "169.1",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3522,
    "FrequencyRank": 4110
  },
  {
    "Character": "尹",
    "Pinyin": "yǐn",
    "Definition": "govern; oversee; director",
    "Radical": "尸",
    "RadicalIndex": "44.1",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3524,
    "FrequencyRank": 2885
  },
  {
    "Character": "毋",
    "Pinyin": "wú",
    "Definition": "do not; not; surname; rad. 80",
    "Radical": "毋",
    "RadicalIndex": "80",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3527,
    "FrequencyRank": 2876
  },
  {
    "Character": "仂",
    "Pinyin": "lè",
    "Definition": "surplus or excess; remainder",
    "Radical": "人",
    "RadicalIndex": "9.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3517,
    "FrequencyRank": 6946
  },
  {
    "Character": "卅",
    "Pinyin": "sà",
    "Definition": "thirty, thirtieth",
    "Radical": "十",
    "RadicalIndex": "24.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3512,
    "FrequencyRank": 4090
  },
  {
    "Character": "廿",
    "Pinyin": "niàn",
    "Definition": "twenty, twentieth",
    "Radical": "廾",
    "RadicalIndex": "55.1",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3510,
    "FrequencyRank": 3383
  },
  {
    "Character": "壬",
    "Pinyin": "rén",
    "Definition": "9th heavenly stem",
    "Radical": "士",
    "RadicalIndex": "33.1",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 124,
    "FrequencyRank": 4029
  },
  {
    "Character": "讣",
    "Pinyin": "fù",
    "Definition": "obituary; give notice of death",
    "Radical": "言",
    "RadicalIndex": "149.2",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 3523,
    "FrequencyRank": 4666
  },
  {
    "Character": "夭",
    "Pinyin": "yāo",
    "Definition": "young, fresh-looking; die young",
    "Radical": "大",
    "RadicalIndex": "37.1",
    "StrokeCount": 4,
    "HSK": undefined,
    "GeneralStandard": 126,
    "FrequencyRank": 3079
  },
  {
    "Character": "仡",
    "Pinyin": "gē",
    "Definition": "strong; valiant",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3544,
    "FrequencyRank": 6006
  },
  {
    "Character": "伋",
    "Pinyin": "jí",
    "Definition": "deceptive",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6512,
    "FrequencyRank": undefined
  },
  {
    "Character": "夯",
    "Pinyin": "hāng",
    "Definition": "heavy load, burden; lift up",
    "Radical": "大",
    "RadicalIndex": "37.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 219,
    "FrequencyRank": 4574
  },
  {
    "Character": "冮",
    "Pinyin": "gāng",
    "Definition": undefined,
    "Radical": "冫",
    "RadicalIndex": "15.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6514,
    "FrequencyRank": undefined
  },
  {
    "Character": "邘",
    "Pinyin": "yú",
    "Definition": "state in Henan province",
    "Radical": "邑",
    "RadicalIndex": "163.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6508,
    "FrequencyRank": 7989
  },
  {
    "Character": "卯",
    "Pinyin": "mǎo",
    "Definition": "4th terrestrial branch; period from 5-7 a.m.",
    "Radical": "卩",
    "RadicalIndex": "26.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 291,
    "FrequencyRank": 3926
  },
  {
    "Character": "阡",
    "Pinyin": "qiān",
    "Definition": "footpaths between fields; paths",
    "Radical": "阜",
    "RadicalIndex": "170.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3559,
    "FrequencyRank": 5147
  },
  {
    "Character": "氐",
    "Pinyin": "dī",
    "Definition": "name of an ancient tribe",
    "Radical": "氏",
    "RadicalIndex": "83.1",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3548,
    "FrequencyRank": 4355
  },
  {
    "Character": "艿",
    "Pinyin": "nǎi",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3531,
    "FrequencyRank": 4668
  },
  {
    "Character": "忉",
    "Pinyin": "dāo",
    "Definition": "grieved; distressed in mind",
    "Radical": "心",
    "RadicalIndex": "61.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6518,
    "FrequencyRank": 6315
  },
  {
    "Character": "札",
    "Pinyin": "zhá",
    "Definition": "letter, note; correspondence",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3532,
    "FrequencyRank": 3211
  },
  {
    "Character": "仕",
    "Pinyin": "shì",
    "Definition": "official; serve government",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3542,
    "FrequencyRank": 3042
  },
  {
    "Character": "邝",
    "Pinyin": "kuàng",
    "Definition": "surname",
    "Radical": "邑",
    "RadicalIndex": "163.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3551,
    "FrequencyRank": 4667
  },
  {
    "Character": "讱",
    "Pinyin": "rèn",
    "Definition": "hesitate to say, reluctant to speak",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6521,
    "FrequencyRank": 8057
  },
  {
    "Character": "圢",
    "Pinyin": "tǐng",
    "Definition": "paths between fields",
    "Radical": "土",
    "RadicalIndex": "32.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6510,
    "FrequencyRank": undefined
  },
  {
    "Character": "尻",
    "Pinyin": "kāo",
    "Definition": "end of spine; buttocks, sacrum",
    "Radical": "尸",
    "RadicalIndex": "44.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3558,
    "FrequencyRank": 6155
  },
  {
    "Character": "劢",
    "Pinyin": "mài",
    "Definition": "put forth effort, strive forward",
    "Radical": "力",
    "RadicalIndex": "19.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3537,
    "FrequencyRank": 5570
  },
  {
    "Character": "讪",
    "Pinyin": "shàn",
    "Definition": "abuse, slander; vilify; ridicule",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3556,
    "FrequencyRank": 3679
  },
  {
    "Character": "讫",
    "Pinyin": "qì",
    "Definition": "finish; conclude, stop; exhaust",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3557,
    "FrequencyRank": 4521
  },
  {
    "Character": "叻",
    "Pinyin": "lè",
    "Definition": "used in place names; (Cant.) smart, clever",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3540,
    "FrequencyRank": 6223
  },
  {
    "Character": "氕",
    "Pinyin": "piē",
    "Definition": "hydrogen-1, protium",
    "Radical": "气",
    "RadicalIndex": "84.1",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6511,
    "FrequencyRank": 7374
  },
  {
    "Character": "戋",
    "Pinyin": "jiān",
    "Definition": "small, narrow, tiny, little",
    "Radical": "戈",
    "RadicalIndex": "62.1",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6509,
    "FrequencyRank": 6653
  },
  {
    "Character": "卟",
    "Pinyin": "bǔ",
    "Definition": "a chemical compound; divination; to consider",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3538,
    "FrequencyRank": 5213
  },
  {
    "Character": "犰",
    "Pinyin": "qiú",
    "Definition": "armadillo",
    "Radical": "犬",
    "RadicalIndex": "94.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3549,
    "FrequencyRank": 6643
  },
  {
    "Character": "匝",
    "Pinyin": "zā",
    "Definition": "full circle; encircle",
    "Radical": "匚",
    "RadicalIndex": "22.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3534,
    "FrequencyRank": 4170
  },
  {
    "Character": "戊",
    "Pinyin": "wù",
    "Definition": "5th heavenly stem",
    "Radical": "戈",
    "RadicalIndex": "62.1",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 220,
    "FrequencyRank": 3689
  },
  {
    "Character": "冉",
    "Pinyin": "rǎn",
    "Definition": "tender; weak; proceed gradually",
    "Radical": "冂",
    "RadicalIndex": "13.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 258,
    "FrequencyRank": 3070
  },
  {
    "Character": "氿",
    "Pinyin": "guǐ",
    "Definition": "spring",
    "Radical": "水",
    "RadicalIndex": "85.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6515,
    "FrequencyRank": undefined
  },
  {
    "Character": "弁",
    "Pinyin": "biàn",
    "Definition": "conical cap worn during Zhou dynasty",
    "Radical": "廾",
    "RadicalIndex": "55.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3561,
    "FrequencyRank": 4544
  },
  {
    "Character": "卮",
    "Pinyin": "zhī",
    "Definition": "measuring cup; wine container",
    "Radical": "卩",
    "RadicalIndex": "26.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3547,
    "FrequencyRank": 5316
  },
  {
    "Character": "仞",
    "Pinyin": "rèn",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3546,
    "FrequencyRank": 5516
  },
  {
    "Character": "叽",
    "Pinyin": "jī",
    "Definition": "sigh in disapproval; take small",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 251,
    "FrequencyRank": 3198
  },
  {
    "Character": "卢",
    "Pinyin": "lú",
    "Definition": "cottage, hut; surname; black",
    "Radical": "卜",
    "RadicalIndex": "25.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 230,
    "FrequencyRank": 1711
  },
  {
    "Character": "氾",
    "Pinyin": "fàn",
    "Definition": "overflow, flood, inundate",
    "Radical": "水",
    "RadicalIndex": "85.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6517,
    "FrequencyRank": undefined
  },
  {
    "Character": "邛",
    "Pinyin": "qióng",
    "Definition": "in distress; a mound",
    "Radical": "邑",
    "RadicalIndex": "163.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3529,
    "FrequencyRank": 4885
  },
  {
    "Character": "丕",
    "Pinyin": "pī",
    "Definition": "great, grand, glorious, distinguished",
    "Radical": "一",
    "RadicalIndex": "1.4",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3535,
    "FrequencyRank": 3748
  },
  {
    "Character": "叵",
    "Pinyin": "pǒ",
    "Definition": "cannot, be unable do, improbable; thereupon",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3533,
    "FrequencyRank": 4318
  },
  {
    "Character": "汈",
    "Pinyin": "diāo",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6516,
    "FrequencyRank": undefined
  },
  {
    "Character": "宄",
    "Pinyin": "guǐ",
    "Definition": "a traitor; a villain",
    "Radical": "宀",
    "RadicalIndex": "40.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6519,
    "FrequencyRank": 5518
  },
  {
    "Character": "艽",
    "Pinyin": "jiāo",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3530,
    "FrequencyRank": 5680
  },
  {
    "Character": "叱",
    "Pinyin": "chì",
    "Definition": "scold, shout at, bawl out",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3539,
    "FrequencyRank": 3181
  },
  {
    "Character": "仫",
    "Pinyin": "mù",
    "Definition": "tribe",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3545,
    "FrequencyRank": 5893
  },
  {
    "Character": "叩",
    "Pinyin": "kòu",
    "Definition": "knock, ask; kowtow, bow",
    "Radical": "口",
    "RadicalIndex": "30.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 254,
    "FrequencyRank": 2898
  },
  {
    "Character": "仨",
    "Pinyin": "sā",
    "Definition": "(coll.) three (cannot be followed by a measure word)",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3541,
    "FrequencyRank": 4685
  },
  {
    "Character": "尕",
    "Pinyin": "gǎ",
    "Definition": "small (used in place names)",
    "Radical": "小",
    "RadicalIndex": "42.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3560,
    "FrequencyRank": 6013
  },
  {
    "Character": "乍",
    "Pinyin": "zhà",
    "Definition": "first time, for the first time",
    "Radical": "丿",
    "RadicalIndex": "4.4",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 266,
    "FrequencyRank": 2914
  },
  {
    "Character": "讦",
    "Pinyin": "jié",
    "Definition": undefined,
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3554,
    "FrequencyRank": 5195
  },
  {
    "Character": "邙",
    "Pinyin": "máng",
    "Definition": "mountain in Henan province",
    "Radical": "邑",
    "RadicalIndex": "163.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3552,
    "FrequencyRank": 4092
  },
  {
    "Character": "仟",
    "Pinyin": "qiān",
    "Definition": "one thousand; leader of one thousand men",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3543,
    "FrequencyRank": 5443
  },
  {
    "Character": "刍",
    "Pinyin": "chú",
    "Definition": "mow, cut grass; hay, fodder",
    "Radical": "彐",
    "RadicalIndex": "58.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3550,
    "FrequencyRank": 4465
  },
  {
    "Character": "皿",
    "Pinyin": "mǐn",
    "Definition": "shallow container; rad. no. 108",
    "Radical": "皿",
    "RadicalIndex": "108",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 259,
    "FrequencyRank": 3763
  },
  {
    "Character": "仝",
    "Pinyin": "tóng",
    "Definition": "together, same; surname",
    "Radical": "人",
    "RadicalIndex": "9.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 6513,
    "FrequencyRank": 6887
  },
  {
    "Character": "汀",
    "Pinyin": "tīng",
    "Definition": "sandbar, beach, bank, shore",
    "Radical": "水",
    "RadicalIndex": "85.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3553,
    "FrequencyRank": 3163
  },
  {
    "Character": "讧",
    "Pinyin": "hòng",
    "Definition": "confusion; internal strife",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3555,
    "FrequencyRank": 5205
  },
  {
    "Character": "邗",
    "Pinyin": "hán",
    "Definition": "an ancient place in the state of Wu",
    "Radical": "邑",
    "RadicalIndex": "163.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3528,
    "FrequencyRank": 6669
  },
  {
    "Character": "轧",
    "Pinyin": "yà",
    "Definition": "crush by weight; grind",
    "Radical": "車",
    "RadicalIndex": "159.1",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 224,
    "FrequencyRank": 3269
  },
  {
    "Character": "匜",
    "Pinyin": "yí",
    "Definition": "basin; container for wine",
    "Radical": "匚",
    "RadicalIndex": "22.3",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 3536,
    "FrequencyRank": undefined
  },
  {
    "Character": "弗",
    "Pinyin": "fú",
    "Definition": "not, negative",
    "Radical": "弓",
    "RadicalIndex": "57.2",
    "StrokeCount": 5,
    "HSK": undefined,
    "GeneralStandard": 328,
    "FrequencyRank": 1257
  },
  {
    "Character": "牝",
    "Pinyin": "pìn",
    "Definition": "female of species; deep gorge",
    "Radical": "牛",
    "RadicalIndex": "93.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3601,
    "FrequencyRank": 5026
  },
  {
    "Character": "圲",
    "Pinyin": "qiān",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6523,
    "FrequencyRank": undefined
  },
  {
    "Character": "亥",
    "Pinyin": "hài",
    "Definition": "12th terrestrial branch",
    "Radical": "亠",
    "RadicalIndex": "8.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 524,
    "FrequencyRank": 3179
  },
  {
    "Character": "闫",
    "Pinyin": "yàn",
    "Definition": "village gate",
    "Radical": "門",
    "RadicalIndex": "169.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6546,
    "FrequencyRank": 5593
  },
  {
    "Character": "凼",
    "Pinyin": "dàng",
    "Definition": "ditch; pool",
    "Radical": "水",
    "RadicalIndex": "85.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3595,
    "FrequencyRank": 5490
  },
  {
    "Character": "厾",
    "Pinyin": "dū",
    "Definition": "lightly tap; through away; drop; sentence final particle",
    "Radical": "乙",
    "RadicalIndex": "5.5",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3637,
    "FrequencyRank": undefined
  },
  {
    "Character": "伫",
    "Pinyin": "zhù",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3610,
    "FrequencyRank": 4074
  },
  {
    "Character": "伉",
    "Pinyin": "kàng",
    "Definition": "compare, match; pair; spouse",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3609,
    "FrequencyRank": 4989
  },
  {
    "Character": "孖",
    "Pinyin": "mā",
    "Definition": "twins",
    "Radical": "子",
    "RadicalIndex": "39.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6552,
    "FrequencyRank": undefined
  },
  {
    "Character": "吒",
    "Pinyin": "zhā",
    "Definition": "shout, roar, bellow; scold",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6531,
    "FrequencyRank": 5303
  },
  {
    "Character": "邠",
    "Pinyin": "bīn",
    "Definition": "county in Shaanxi province",
    "Radical": "邑",
    "RadicalIndex": "163.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6542,
    "FrequencyRank": 6508
  },
  {
    "Character": "吆",
    "Pinyin": "yāo",
    "Definition": "bawl, yell, shout, cry out",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 433,
    "FrequencyRank": 3551
  },
  {
    "Character": "祁",
    "Pinyin": "qí",
    "Definition": "pray; numerous, ample, abundant",
    "Radical": "示",
    "RadicalIndex": "113.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3633,
    "FrequencyRank": 3477
  },
  {
    "Character": "凫",
    "Pinyin": "fú",
    "Definition": "wild duck, teal; swim",
    "Radical": "几",
    "RadicalIndex": "16.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3620,
    "FrequencyRank": 5226
  },
  {
    "Character": "邨",
    "Pinyin": "cūn",
    "Definition": "village, hamlet; rustic",
    "Radical": "邑",
    "RadicalIndex": "163.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6530,
    "FrequencyRank": 5514
  },
  {
    "Character": "汲",
    "Pinyin": "jí",
    "Definition": "draw water from well; imbibe",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3626,
    "FrequencyRank": 3374
  },
  {
    "Character": "忏",
    "Pinyin": "chàn",
    "Definition": "regret, repent; confess sins",
    "Radical": "心",
    "RadicalIndex": "61.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3630,
    "FrequencyRank": 3177
  },
  {
    "Character": "圮",
    "Pinyin": "pǐ",
    "Definition": "destroyed, ruined; to subvert to injure",
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3576,
    "FrequencyRank": 5444
  },
  {
    "Character": "扞",
    "Pinyin": "gǎn",
    "Definition": "ward off, withstand, resist",
    "Radical": "手",
    "RadicalIndex": "64.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6522,
    "FrequencyRank": undefined
  },
  {
    "Character": "钇",
    "Pinyin": "yǐ",
    "Definition": "yttrium",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3597,
    "FrequencyRank": 5910
  },
  {
    "Character": "伎",
    "Pinyin": "jì",
    "Definition": "talent, skill, ability",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3602,
    "FrequencyRank": 3332
  },
  {
    "Character": "臼",
    "Pinyin": "jiù",
    "Definition": "mortar; bone joint socket",
    "Radical": "臼",
    "RadicalIndex": "134",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 462,
    "FrequencyRank": 4066
  },
  {
    "Character": "吖",
    "Pinyin": "yā",
    "Definition": "used in translation; (Cant.) final particle",
    "Radical": "口",
    "RadicalIndex": "30.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6532,
    "FrequencyRank": 6243
  },
  {
    "Character": "戎",
    "Pinyin": "róng",
    "Definition": "arms, armaments; military affair",
    "Radical": "戈",
    "RadicalIndex": "62.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 352,
    "FrequencyRank": 3205
  },
  {
    "Character": "犴",
    "Pinyin": "àn",
    "Definition": "a wild dog, a not very large lock-up",
    "Radical": "犬",
    "RadicalIndex": "94.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6543,
    "FrequencyRank": 6407
  },
  {
    "Character": "邡",
    "Pinyin": "fāng",
    "Definition": "name of a district in Sichuan",
    "Radical": "邑",
    "RadicalIndex": "163.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6545,
    "FrequencyRank": 6905
  },
  {
    "Character": "屾",
    "Pinyin": "shēn",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6534,
    "FrequencyRank": undefined
  },
  {
    "Character": "纣",
    "Pinyin": "zhòu",
    "Definition": "name of an emperor; saddle part",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3645,
    "FrequencyRank": 4329
  },
  {
    "Character": "曳",
    "Pinyin": "yè",
    "Definition": "trail, tow, drag, pull",
    "Radical": "曰",
    "RadicalIndex": "73.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3592,
    "FrequencyRank": 3364
  },
  {
    "Character": "朳",
    "Pinyin": "bā",
    "Definition": "tree",
    "Radical": "木",
    "RadicalIndex": "75.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6527,
    "FrequencyRank": undefined
  },
  {
    "Character": "夼",
    "Pinyin": "kuǎng",
    "Definition": undefined,
    "Radical": "大",
    "RadicalIndex": "37.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3587,
    "FrequencyRank": 6814
  },
  {
    "Character": "忖",
    "Pinyin": "cǔn",
    "Definition": "guess, suppose, conjecture",
    "Radical": "心",
    "RadicalIndex": "61.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3629,
    "FrequencyRank": 3000
  },
  {
    "Character": "芋",
    "Pinyin": "yù",
    "Definition": "taro",
    "Radical": "艸",
    "RadicalIndex": "140.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 370,
    "FrequencyRank": 4246
  },
  {
    "Character": "芃",
    "Pinyin": "péng",
    "Definition": "luxuriant growth",
    "Radical": "艸",
    "RadicalIndex": "140.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6526,
    "FrequencyRank": 7971
  },
  {
    "Character": "汕",
    "Pinyin": "shàn",
    "Definition": "basket for catching fish; bamboo; Swatow (Shantou)",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3623,
    "FrequencyRank": 3878
  },
  {
    "Character": "芍",
    "Pinyin": "sháo",
    "Definition": "peony; water chestnuts",
    "Radical": "艸",
    "RadicalIndex": "140.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3579,
    "FrequencyRank": 4361
  },
  {
    "Character": "伧",
    "Pinyin": "cāng",
    "Definition": "vulgar person, country man",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3608,
    "FrequencyRank": 5659
  },
  {
    "Character": "圩",
    "Pinyin": "wéi",
    "Definition": "dike, embankment",
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3568,
    "FrequencyRank": 4291
  },
  {
    "Character": "圪",
    "Pinyin": "gē",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3572,
    "FrequencyRank": 4139
  },
  {
    "Character": "妃",
    "Pinyin": "fēi",
    "Definition": "wife, spouse; imperial concubine",
    "Radical": "女",
    "RadicalIndex": "38.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 580,
    "FrequencyRank": 2574
  },
  {
    "Character": "耒",
    "Pinyin": "lěi",
    "Definition": "handle of plow; plow; rad. 127",
    "Radical": "耒",
    "RadicalIndex": "127",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3564,
    "FrequencyRank": 5701
  },
  {
    "Character": "汜",
    "Pinyin": "sì",
    "Definition": "a stream which leaves the main branch then later returns",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3627,
    "FrequencyRank": 5816
  },
  {
    "Character": "匡",
    "Pinyin": "kuāng",
    "Definition": "correct, restore, revise",
    "Radical": "匚",
    "RadicalIndex": "22.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3563,
    "FrequencyRank": 3260
  },
  {
    "Character": "丞",
    "Pinyin": "chéng",
    "Definition": "assist, aid, rescue",
    "Radical": "一",
    "RadicalIndex": "1.5",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3641,
    "FrequencyRank": 3340
  },
  {
    "Character": "迂",
    "Pinyin": "yū",
    "Definition": "doctrinaire, abstruse, unrealistic",
    "Radical": "辵",
    "RadicalIndex": "162.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 350,
    "FrequencyRank": 2959
  },
  {
    "Character": "岌",
    "Pinyin": "jí",
    "Definition": "perilous, hazardous; high, steep",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3593,
    "FrequencyRank": 4205
  },
  {
    "Character": "伛",
    "Pinyin": "yǔ",
    "Definition": "humpback; stoop",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3603,
    "FrequencyRank": 6109
  },
  {
    "Character": "聿",
    "Pinyin": "yù",
    "Definition": "writing brush, pencil; thereupon",
    "Radical": "聿",
    "RadicalIndex": "129",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3635,
    "FrequencyRank": 3526
  },
  {
    "Character": "旯",
    "Pinyin": "lá",
    "Definition": "nook, corner",
    "Radical": "日",
    "RadicalIndex": "72.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3591,
    "FrequencyRank": 5243
  },
  {
    "Character": "芑",
    "Pinyin": "qǐ",
    "Definition": "white millet",
    "Radical": "艸",
    "RadicalIndex": "140.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3582,
    "FrequencyRank": 5895
  },
  {
    "Character": "扦",
    "Pinyin": "qiān",
    "Definition": "probe, poke, prick, pierce",
    "Radical": "手",
    "RadicalIndex": "64.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3571,
    "FrequencyRank": 5757
  },
  {
    "Character": "囟",
    "Pinyin": "xìn",
    "Definition": "top of the head; skull",
    "Radical": "囗",
    "RadicalIndex": "31.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3611,
    "FrequencyRank": 5175
  },
  {
    "Character": "芨",
    "Pinyin": "jī",
    "Definition": "name of a plant, the roots are used for various purposes",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3581,
    "FrequencyRank": 4928
  },
  {
    "Character": "仵",
    "Pinyin": "wǔ",
    "Definition": "similar",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3606,
    "FrequencyRank": 6393
  },
  {
    "Character": "纩",
    "Pinyin": "kuàng",
    "Definition": "cotton; silk",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6554,
    "FrequencyRank": 6831
  },
  {
    "Character": "芊",
    "Pinyin": "qiān",
    "Definition": "exuberant and vigorous foliage",
    "Radical": "艸",
    "RadicalIndex": "140.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3578,
    "FrequencyRank": 5642
  },
  {
    "Character": "伈",
    "Pinyin": "xǐn",
    "Definition": "nervous, fearful",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6539,
    "FrequencyRank": undefined
  },
  {
    "Character": "圹",
    "Pinyin": "kuàng",
    "Definition": "tomb, grave: prairie; open wilderness",
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3574,
    "FrequencyRank": 6492
  },
  {
    "Character": "伣",
    "Pinyin": "xiàn",
    "Definition": "like",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6538,
    "FrequencyRank": undefined
  },
  {
    "Character": "肋",
    "Pinyin": "lē",
    "Definition": "ribs; chest",
    "Radical": "肉",
    "RadicalIndex": "130.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 496,
    "FrequencyRank": 3324
  },
  {
    "Character": "戌",
    "Pinyin": "xū",
    "Definition": "11th terrestrial branch",
    "Radical": "戈",
    "RadicalIndex": "62.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 387,
    "FrequencyRank": 3921
  },
  {
    "Character": "氘",
    "Pinyin": "dāo",
    "Definition": "deuterium",
    "Radical": "气",
    "RadicalIndex": "84.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3599,
    "FrequencyRank": 5280
  },
  {
    "Character": "朸",
    "Pinyin": "lì",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6528,
    "FrequencyRank": undefined
  },
  {
    "Character": "伢",
    "Pinyin": "yá",
    "Definition": "child",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3604,
    "FrequencyRank": 3346
  },
  {
    "Character": "纡",
    "Pinyin": "yū",
    "Definition": "bend, turn, twist; distort",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3644,
    "FrequencyRank": 5285
  },
  {
    "Character": "癿",
    "Pinyin": "qié",
    "Definition": undefined,
    "Radical": "白",
    "RadicalIndex": "106.1",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6540,
    "FrequencyRank": undefined
  },
  {
    "Character": "匈",
    "Pinyin": "xiōng",
    "Definition": undefined,
    "Radical": "勹",
    "RadicalIndex": "20.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 504,
    "FrequencyRank": 2503
  },
  {
    "Character": "芗",
    "Pinyin": "xiāng",
    "Definition": "fragrant smell of grain; aromatic",
    "Radical": "艸",
    "RadicalIndex": "140.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3584,
    "FrequencyRank": 5156
  },
  {
    "Character": "旮",
    "Pinyin": "gā",
    "Definition": "nook, corner",
    "Radical": "日",
    "RadicalIndex": "72.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3615,
    "FrequencyRank": 5287
  },
  {
    "Character": "圯",
    "Pinyin": "yí",
    "Definition": "bridge, bank",
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3577,
    "FrequencyRank": 6485
  },
  {
    "Character": "阪",
    "Pinyin": "bǎn",
    "Definition": "hillside farmland; slope",
    "Radical": "阜",
    "RadicalIndex": "170.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3640,
    "FrequencyRank": 3595
  },
  {
    "Character": "妁",
    "Pinyin": "shuò",
    "Definition": "act as go-between",
    "Radical": "女",
    "RadicalIndex": "38.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3642,
    "FrequencyRank": 6334
  },
  {
    "Character": "扪",
    "Pinyin": "mén",
    "Definition": "stoke, pat, feel by hand, grope",
    "Radical": "手",
    "RadicalIndex": "64.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3575,
    "FrequencyRank": 4416
  },
  {
    "Character": "夙",
    "Pinyin": "sù",
    "Definition": "early in morning, dawn; previous",
    "Radical": "夕",
    "RadicalIndex": "36.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3614,
    "FrequencyRank": 4303
  },
  {
    "Character": "阮",
    "Pinyin": "ruǎn",
    "Definition": "ancient musical instrument: surname",
    "Radical": "阜",
    "RadicalIndex": "170.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3639,
    "FrequencyRank": 3210
  },
  {
    "Character": "乩",
    "Pinyin": "jī",
    "Definition": "to divine",
    "Radical": "乙",
    "RadicalIndex": "5.5",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3590,
    "FrequencyRank": 4609
  },
  {
    "Character": "牟",
    "Pinyin": "móu",
    "Definition": "make; seek, get; barley; low",
    "Radical": "牛",
    "RadicalIndex": "93.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3643,
    "FrequencyRank": 2854
  },
  {
    "Character": "佤",
    "Pinyin": "wǎ",
    "Definition": "the Va (Wa) nationality, living in Yunnan",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3605,
    "FrequencyRank": 5502
  },
  {
    "Character": "圳",
    "Pinyin": "zhèn",
    "Definition": "furrow in field, small drainage ditch",
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3573,
    "FrequencyRank": 2137
  },
  {
    "Character": "艮",
    "Pinyin": "gěn",
    "Definition": "seventh of eight diagrams",
    "Radical": "艮",
    "RadicalIndex": "138",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3636,
    "FrequencyRank": 4564
  },
  {
    "Character": "饧",
    "Pinyin": "táng",
    "Definition": "sugar, syrup; malt sugar; sticky",
    "Radical": "食",
    "RadicalIndex": "184.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3622,
    "FrequencyRank": 5006
  },
  {
    "Character": "讷",
    "Pinyin": "nè",
    "Definition": "slow of speech; mumble; stammer",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3634,
    "FrequencyRank": 3916
  },
  {
    "Character": "纨",
    "Pinyin": "wán",
    "Definition": "white silk, fine silk; gauze",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3647,
    "FrequencyRank": 5335
  },
  {
    "Character": "汊",
    "Pinyin": "chà",
    "Definition": "a branching stream",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3628,
    "FrequencyRank": 5283
  },
  {
    "Character": "邬",
    "Pinyin": "wū",
    "Definition": "various place names; surname",
    "Radical": "邑",
    "RadicalIndex": "163.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3621,
    "FrequencyRank": 5197
  },
  {
    "Character": "汝",
    "Pinyin": "rǔ",
    "Definition": "you",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 541,
    "FrequencyRank": 2547
  },
  {
    "Character": "汐",
    "Pinyin": "xī",
    "Definition": "night tides, evening ebb tide",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3625,
    "FrequencyRank": 4186
  },
  {
    "Character": "戍",
    "Pinyin": "shù",
    "Definition": "defend borders, guard frontiers",
    "Radical": "戈",
    "RadicalIndex": "62.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3588,
    "FrequencyRank": 3767
  },
  {
    "Character": "芏",
    "Pinyin": "dù",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6525,
    "FrequencyRank": 5111
  },
  {
    "Character": "刎",
    "Pinyin": "wěn",
    "Definition": "behead, cut throat",
    "Radical": "刀",
    "RadicalIndex": "18.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3616,
    "FrequencyRank": 5110
  },
  {
    "Character": "玎",
    "Pinyin": "dīng",
    "Definition": "jingling, tinkling",
    "Radical": "玉",
    "RadicalIndex": "96.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3565,
    "FrequencyRank": 5921
  },
  {
    "Character": "圫",
    "Pinyin": "yù",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6524,
    "FrequencyRank": undefined
  },
  {
    "Character": "纫",
    "Pinyin": "rèn",
    "Definition": "thread needle, sew stitch, string",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 597,
    "FrequencyRank": 4173
  },
  {
    "Character": "囡",
    "Pinyin": "nān",
    "Definition": undefined,
    "Radical": "囗",
    "RadicalIndex": "31.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3596,
    "FrequencyRank": 4759
  },
  {
    "Character": "纥",
    "Pinyin": "gē",
    "Definition": "inferior silk; tassel, fringe",
    "Radical": "糸",
    "RadicalIndex": "120.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3646,
    "FrequencyRank": 4854
  },
  {
    "Character": "芄",
    "Pinyin": "wán",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3580,
    "FrequencyRank": 5136
  },
  {
    "Character": "冱",
    "Pinyin": "hù",
    "Definition": "freezing; stopped up, closed off",
    "Radical": "冫",
    "RadicalIndex": "15.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6544,
    "FrequencyRank": 8997
  },
  {
    "Character": "犸",
    "Pinyin": "mà",
    "Definition": "mammoth",
    "Radical": "犬",
    "RadicalIndex": "94.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3618,
    "FrequencyRank": 5838
  },
  {
    "Character": "讻",
    "Pinyin": "xiōng",
    "Definition": "noisily; uproarious; troublesome",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6550,
    "FrequencyRank": undefined
  },
  {
    "Character": "辿",
    "Pinyin": "chān",
    "Definition": "follow, pursue",
    "Radical": "辵",
    "RadicalIndex": "162.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6535,
    "FrequencyRank": undefined
  },
  {
    "Character": "屼",
    "Pinyin": "wù",
    "Definition": "mountain; a bare hill",
    "Radical": "山",
    "RadicalIndex": "46.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6533,
    "FrequencyRank": undefined
  },
  {
    "Character": "厍",
    "Pinyin": "shè",
    "Definition": "surname",
    "Radical": "厂",
    "RadicalIndex": "27.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3586,
    "FrequencyRank": 5493
  },
  {
    "Character": "汔",
    "Pinyin": "qì",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3624,
    "FrequencyRank": 6852
  },
  {
    "Character": "舛",
    "Pinyin": "chuǎn",
    "Definition": "oppose, deviate, be contrary to",
    "Radical": "舛",
    "RadicalIndex": "136",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3619,
    "FrequencyRank": 4694
  },
  {
    "Character": "圬",
    "Pinyin": "wū",
    "Definition": "plaster over with layer of mud",
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3569,
    "FrequencyRank": 6985
  },
  {
    "Character": "芎",
    "Pinyin": "qiōng",
    "Definition": "a kind of herb",
    "Radical": "艸",
    "RadicalIndex": "140.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3583,
    "FrequencyRank": 5116
  },
  {
    "Character": "刖",
    "Pinyin": "yuè",
    "Definition": "cutting off feet as form of punishment",
    "Radical": "刀",
    "RadicalIndex": "18.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3613,
    "FrequencyRank": 5475
  },
  {
    "Character": "犷",
    "Pinyin": "guǎng",
    "Definition": "fierce, rude, uncivilized",
    "Radical": "犬",
    "RadicalIndex": "94.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3617,
    "FrequencyRank": 4624
  },
  {
    "Character": "伥",
    "Pinyin": "chāng",
    "Definition": "ghost of one devoured by tiger",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3607,
    "FrequencyRank": 5589
  },
  {
    "Character": "𨙸",
    "Pinyin": "qí",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6529,
    "FrequencyRank": undefined
  },
  {
    "Character": "氖",
    "Pinyin": "nǎi",
    "Definition": "neon",
    "Radical": "气",
    "RadicalIndex": "84.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3600,
    "FrequencyRank": 3602
  },
  {
    "Character": "钆",
    "Pinyin": "gá",
    "Definition": "gadolinium",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6536,
    "FrequencyRank": 6410
  },
  {
    "Character": "亘",
    "Pinyin": "gèn",
    "Definition": "extend across, through; from",
    "Radical": "二",
    "RadicalIndex": "7.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3585,
    "FrequencyRank": 3874
  },
  {
    "Character": "屺",
    "Pinyin": "qǐ",
    "Definition": "a hill with trees or grass-- some give the opposite meaning",
    "Radical": "山",
    "RadicalIndex": "46.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3594,
    "FrequencyRank": 8365
  },
  {
    "Character": "仳",
    "Pinyin": "pǐ",
    "Definition": "separate, part company",
    "Radical": "人",
    "RadicalIndex": "9.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6537,
    "FrequencyRank": 7350
  },
  {
    "Character": "尧",
    "Pinyin": "yáo",
    "Definition": "a legendary ancient emperor-sage",
    "Radical": "儿",
    "RadicalIndex": "10.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 406,
    "FrequencyRank": 3264
  },
  {
    "Character": "汋",
    "Pinyin": "zhuó",
    "Definition": "to pour",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6548,
    "FrequencyRank": undefined
  },
  {
    "Character": "讵",
    "Pinyin": "jù",
    "Definition": "an interjection used express surprise",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3632,
    "FrequencyRank": 5264
  },
  {
    "Character": "圭",
    "Pinyin": "guī",
    "Definition": "jade pointed at top",
    "Radical": "土",
    "RadicalIndex": "32.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3570,
    "FrequencyRank": 3537
  },
  {
    "Character": "汆",
    "Pinyin": "cuān",
    "Definition": "to parboil; hot water kettle; to boil",
    "Radical": "水",
    "RadicalIndex": "85.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3612,
    "FrequencyRank": 6885
  },
  {
    "Character": "甪",
    "Pinyin": "lù",
    "Definition": "to loose, take off, get rid of",
    "Radical": "用",
    "RadicalIndex": "101.1",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 6541,
    "FrequencyRank": undefined
  },
  {
    "Character": "缶",
    "Pinyin": "fǒu",
    "Definition": "earthen crock or jar; rad. 121",
    "Radical": "缶",
    "RadicalIndex": "121",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3598,
    "FrequencyRank": 5328
  },
  {
    "Character": "尥",
    "Pinyin": "liào",
    "Definition": undefined,
    "Radical": "尢",
    "RadicalIndex": "43.3",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3589,
    "FrequencyRank": 5851
  },
  {
    "Character": "讴",
    "Pinyin": "ōu",
    "Definition": "sing; songs",
    "Radical": "言",
    "RadicalIndex": "149.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3631,
    "FrequencyRank": 4770
  },
  {
    "Character": "邢",
    "Pinyin": "xíng",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.4",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3567,
    "FrequencyRank": 3382
  },
  {
    "Character": "玑",
    "Pinyin": "jī",
    "Definition": "pearl that is not quite round",
    "Radical": "玉",
    "RadicalIndex": "96.2",
    "StrokeCount": 6,
    "HSK": undefined,
    "GeneralStandard": 3566,
    "FrequencyRank": 2107
  },
  {
    "Character": "苊",
    "Pinyin": "è",
    "Definition": "an organic compound",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6571,
    "FrequencyRank": 5825
  },
  {
    "Character": "妊",
    "Pinyin": "rèn",
    "Definition": "conceive, be pregnant",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3810,
    "FrequencyRank": 3523
  },
  {
    "Character": "邸",
    "Pinyin": "dǐ",
    "Definition": "official residence, residence of",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3743,
    "FrequencyRank": 3186
  },
  {
    "Character": "氚",
    "Pinyin": "chuān",
    "Definition": "tritium",
    "Radical": "气",
    "RadicalIndex": "84.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3726,
    "FrequencyRank": 5050
  },
  {
    "Character": "祃",
    "Pinyin": "mà",
    "Definition": "a sacrifice at the beginning of a military campaign",
    "Radical": "示",
    "RadicalIndex": "113.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6624,
    "FrequencyRank": 8319
  },
  {
    "Character": "芪",
    "Pinyin": "qí",
    "Definition": "celery",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3672,
    "FrequencyRank": 4507
  },
  {
    "Character": "坒",
    "Pinyin": "bì",
    "Definition": "to compare; to match; to equal",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6587,
    "FrequencyRank": undefined
  },
  {
    "Character": "扺",
    "Pinyin": "zhǐ",
    "Definition": undefined,
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6566,
    "FrequencyRank": undefined
  },
  {
    "Character": "邹",
    "Pinyin": "zōu",
    "Definition": "name of an ancient state; surname",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3749,
    "FrequencyRank": 3139
  },
  {
    "Character": "囤",
    "Pinyin": "dùn",
    "Definition": "grain basket, bin for grain",
    "Radical": "囗",
    "RadicalIndex": "31.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 750,
    "FrequencyRank": 4543
  },
  {
    "Character": "闰",
    "Pinyin": "rùn",
    "Definition": "intercalary; extra, surplus",
    "Radical": "門",
    "RadicalIndex": "169.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 845,
    "FrequencyRank": 4183
  },
  {
    "Character": "旴",
    "Pinyin": "xū",
    "Definition": "dawn",
    "Radical": "日",
    "RadicalIndex": "72.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6589,
    "FrequencyRank": undefined
  },
  {
    "Character": "芫",
    "Pinyin": "yán",
    "Definition": "daphne genkwa, poisonous plant",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3659,
    "FrequencyRank": 5350
  },
  {
    "Character": "泐",
    "Pinyin": "lè",
    "Definition": "to write; to indite; veins in minerals",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3782,
    "FrequencyRank": 6610
  },
  {
    "Character": "迓",
    "Pinyin": "yà",
    "Definition": "to go to meet, to receive, as a guest",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3692,
    "FrequencyRank": 5992
  },
  {
    "Character": "忪",
    "Pinyin": "sōng",
    "Definition": "quiet, calm, tranquil, peaceful",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3790,
    "FrequencyRank": 5274
  },
  {
    "Character": "佖",
    "Pinyin": "bì",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6604,
    "FrequencyRank": undefined
  },
  {
    "Character": "芤",
    "Pinyin": "kōu",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6577,
    "FrequencyRank": 5635
  },
  {
    "Character": "纻",
    "Pinyin": "zhù",
    "Definition": "ramie; sack cloth",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6641,
    "FrequencyRank": 6378
  },
  {
    "Character": "沆",
    "Pinyin": "hàng",
    "Definition": "a ferry; fog; flowing",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3780,
    "FrequencyRank": 5873
  },
  {
    "Character": "忸",
    "Pinyin": "niǔ",
    "Definition": "blush, be bashful, be ashamed",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3793,
    "FrequencyRank": 4813
  },
  {
    "Character": "饨",
    "Pinyin": "tún",
    "Definition": "stuffed dumplings",
    "Radical": "食",
    "RadicalIndex": "184.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3750,
    "FrequencyRank": 4332
  },
  {
    "Character": "姒",
    "Pinyin": "sì",
    "Definition": "wife of elder brother",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3814,
    "FrequencyRank": 5214
  },
  {
    "Character": "佁",
    "Pinyin": "yǐ",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6606,
    "FrequencyRank": undefined
  },
  {
    "Character": "沨",
    "Pinyin": "fēng",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3777,
    "FrequencyRank": undefined
  },
  {
    "Character": "忤",
    "Pinyin": "wǔ",
    "Definition": "insubordinate, stubborn; wrong",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3786,
    "FrequencyRank": 5039
  },
  {
    "Character": "屃",
    "Pinyin": "xì",
    "Definition": "gigantic strength; hercules",
    "Radical": "尸",
    "RadicalIndex": "44.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6629,
    "FrequencyRank": undefined
  },
  {
    "Character": "酉",
    "Pinyin": "yǒu",
    "Definition": "10th terrestrial branch; a wine vessel",
    "Radical": "酉",
    "RadicalIndex": "164",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 698,
    "FrequencyRank": 3839
  },
  {
    "Character": "诏",
    "Pinyin": "zhào",
    "Definition": "decree, proclaim; imperial decree",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3799,
    "FrequencyRank": 2684
  },
  {
    "Character": "岘",
    "Pinyin": "xiàn",
    "Definition": "steep hill; mountain in Hubei",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3715,
    "FrequencyRank": 5210
  },
  {
    "Character": "庐",
    "Pinyin": "lú",
    "Definition": "hut, cottage; name of a mountain",
    "Radical": "广",
    "RadicalIndex": "53.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 839,
    "FrequencyRank": 2927
  },
  {
    "Character": "芩",
    "Pinyin": "qín",
    "Definition": "salt marsh plant",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3671,
    "FrequencyRank": 4642
  },
  {
    "Character": "纮",
    "Pinyin": "hóng",
    "Definition": "string; vast, expansive",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6638,
    "FrequencyRank": 6474
  },
  {
    "Character": "伾",
    "Pinyin": "pī",
    "Definition": "mighty",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6601,
    "FrequencyRank": undefined
  },
  {
    "Character": "欤",
    "Pinyin": "yú",
    "Definition": "a final particle used to express admiration, doubt, surprise, or to mark a question",
    "Radical": "欠",
    "RadicalIndex": "76.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3690,
    "FrequencyRank": 3879
  },
  {
    "Character": "苣",
    "Pinyin": "jù",
    "Definition": "kind of lettuce",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3664,
    "FrequencyRank": 4756
  },
  {
    "Character": "抟",
    "Pinyin": "tuán",
    "Definition": "roll around with hand; model",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3650,
    "FrequencyRank": 4659
  },
  {
    "Character": "呖",
    "Pinyin": "lì",
    "Definition": "used in onomatopoetic expressions",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3702,
    "FrequencyRank": 5864
  },
  {
    "Character": "闳",
    "Pinyin": "hóng",
    "Definition": "gate, barrier; wide, vast, expand",
    "Radical": "門",
    "RadicalIndex": "169.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3762,
    "FrequencyRank": 5087
  },
  {
    "Character": "芮",
    "Pinyin": "ruì",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3666,
    "FrequencyRank": 4108
  },
  {
    "Character": "伽",
    "Pinyin": "jiā",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3735,
    "FrequencyRank": 2532
  },
  {
    "Character": "冏",
    "Pinyin": "jiǒng",
    "Definition": "(archaic form of 炯) light, bright",
    "Radical": "冂",
    "RadicalIndex": "13.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6598,
    "FrequencyRank": undefined
  },
  {
    "Character": "纶",
    "Pinyin": "lún",
    "Definition": "green silk thread or tassel",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3824,
    "FrequencyRank": 3560
  },
  {
    "Character": "𣲗",
    "Pinyin": "wéi",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6613,
    "FrequencyRank": undefined
  },
  {
    "Character": "汶",
    "Pinyin": "wèn",
    "Definition": "a river in Shandong province",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3779,
    "FrequencyRank": 3719
  },
  {
    "Character": "芜",
    "Pinyin": "wú",
    "Definition": "luxurious growth of weeds",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 662,
    "FrequencyRank": 3239
  },
  {
    "Character": "沇",
    "Pinyin": "yǎn",
    "Definition": "flowing and engulfing; brimming",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6619,
    "FrequencyRank": undefined
  },
  {
    "Character": "佗",
    "Pinyin": "tuó",
    "Definition": "other, he; surname; a load",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3734,
    "FrequencyRank": 4569
  },
  {
    "Character": "汴",
    "Pinyin": "biàn",
    "Definition": "name of a river in Henan; Henan",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3778,
    "FrequencyRank": 4359
  },
  {
    "Character": "沂",
    "Pinyin": "yí",
    "Definition": "river in southeast Shandong",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3775,
    "FrequencyRank": 3787
  },
  {
    "Character": "杏",
    "Pinyin": "xìng",
    "Definition": "apricot; almond",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 684,
    "FrequencyRank": 2790
  },
  {
    "Character": "纼",
    "Pinyin": "zhèn",
    "Definition": "a rope for leading cattle or horse",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6644,
    "FrequencyRank": undefined
  },
  {
    "Character": "吮",
    "Pinyin": "shǔn",
    "Definition": "suck with mouth, sip, lick",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 752,
    "FrequencyRank": 3699
  },
  {
    "Character": "芼",
    "Pinyin": "mào",
    "Definition": "to choose; to select; greens",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3668,
    "FrequencyRank": 9815
  },
  {
    "Character": "玖",
    "Pinyin": "jiǔ",
    "Definition": "black-colored jade",
    "Radical": "玉",
    "RadicalIndex": "96.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 602,
    "FrequencyRank": 5072
  },
  {
    "Character": "狁",
    "Pinyin": "yǔn",
    "Definition": "a tribe of Scythian nomads",
    "Radical": "犬",
    "RadicalIndex": "94.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3747,
    "FrequencyRank": 6520
  },
  {
    "Character": "吱",
    "Pinyin": "zhī",
    "Definition": "chirping, squeaking, hissing",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 723,
    "FrequencyRank": 2805
  },
  {
    "Character": "吲",
    "Pinyin": "yǐn",
    "Definition": "smile at; sneer at",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3711,
    "FrequencyRank": 6349
  },
  {
    "Character": "诎",
    "Pinyin": "qū",
    "Definition": "bend, stoop, crouch; to yield",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6627,
    "FrequencyRank": 5378
  },
  {
    "Character": "刭",
    "Pinyin": "jǐng",
    "Definition": "cut throat",
    "Radical": "刀",
    "RadicalIndex": "18.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3818,
    "FrequencyRank": 7433
  },
  {
    "Character": "汩",
    "Pinyin": "gǔ",
    "Definition": "run swiftly",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3773,
    "FrequencyRank": 4342
  },
  {
    "Character": "𣲘",
    "Pinyin": "wǔ",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6612,
    "FrequencyRank": undefined
  },
  {
    "Character": "甫",
    "Pinyin": "fǔ",
    "Definition": "begin; man, father; great; a distance of ten li",
    "Radical": "用",
    "RadicalIndex": "101.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 691,
    "FrequencyRank": 2152
  },
  {
    "Character": "彷",
    "Pinyin": "fǎng",
    "Definition": "like, resembling; resemble",
    "Radical": "彳",
    "RadicalIndex": "60.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3736,
    "FrequencyRank": 3487
  },
  {
    "Character": "妤",
    "Pinyin": "yú",
    "Definition": "beautiful, fair, handsome",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3815,
    "FrequencyRank": 6893
  },
  {
    "Character": "岑",
    "Pinyin": "cén",
    "Definition": "steep, precipitous; peak",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3716,
    "FrequencyRank": 3466
  },
  {
    "Character": "佝",
    "Pinyin": "gōu",
    "Definition": "rickets",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3732,
    "FrequencyRank": 5065
  },
  {
    "Character": "羌",
    "Pinyin": "qiāng",
    "Definition": "Qiang nationality; surname",
    "Radical": "羊",
    "RadicalIndex": "123.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3764,
    "FrequencyRank": 3509
  },
  {
    "Character": "坋",
    "Pinyin": "bèn",
    "Definition": "dust, earth; a bank of earth; to dig; to bring together",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6565,
    "FrequencyRank": undefined
  },
  {
    "Character": "汾",
    "Pinyin": "fén",
    "Definition": "river in Shanxi province",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3776,
    "FrequencyRank": 3766
  },
  {
    "Character": "诅",
    "Pinyin": "zǔ",
    "Definition": "curse; swear, pledge",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3796,
    "FrequencyRank": 3171
  },
  {
    "Character": "迕",
    "Pinyin": "wù",
    "Definition": "obstinate, perverse",
    "Radical": "辵",
    "RadicalIndex": "162.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3724,
    "FrequencyRank": 5882
  },
  {
    "Character": "肟",
    "Pinyin": "wò",
    "Definition": "an organic compound",
    "Radical": "肉",
    "RadicalIndex": "130.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3742,
    "FrequencyRank": 5512
  },
  {
    "Character": "劭",
    "Pinyin": "shào",
    "Definition": "encourage; to excel; excellent",
    "Radical": "力",
    "RadicalIndex": "19.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3817,
    "FrequencyRank": 5012
  },
  {
    "Character": "沌",
    "Pinyin": "dùn",
    "Definition": "chaotic, confused; turbid, murky",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3770,
    "FrequencyRank": 3309
  },
  {
    "Character": "诒",
    "Pinyin": "yí",
    "Definition": "bequeath, pass on to future generations",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3800,
    "FrequencyRank": 4352
  },
  {
    "Character": "岐",
    "Pinyin": "qí",
    "Definition": "high; majestic; fork in road",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3713,
    "FrequencyRank": 3928
  },
  {
    "Character": "坍",
    "Pinyin": "tān",
    "Definition": "collapse; landslide",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3654,
    "FrequencyRank": 3515
  },
  {
    "Character": "佘",
    "Pinyin": "shé",
    "Definition": "surname",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3737,
    "FrequencyRank": 4761
  },
  {
    "Character": "毐",
    "Pinyin": "ǎi",
    "Definition": "person of reprehensible morals; immoral; adulterer",
    "Radical": "毋",
    "RadicalIndex": "80.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6568,
    "FrequencyRank": undefined
  },
  {
    "Character": "邵",
    "Pinyin": "shào",
    "Definition": "surname; various place names",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3816,
    "FrequencyRank": 2814
  },
  {
    "Character": "旵",
    "Pinyin": "chǎn",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6590,
    "FrequencyRank": undefined
  },
  {
    "Character": "杧",
    "Pinyin": "máng",
    "Definition": "mango (Mangifera indica)",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6581,
    "FrequencyRank": undefined
  },
  {
    "Character": "沔",
    "Pinyin": "miǎn",
    "Definition": "flood; overflowing",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3768,
    "FrequencyRank": 6433
  },
  {
    "Character": "忒",
    "Pinyin": "tè",
    "Definition": "excessive; too; very-usually of objectionable things; to err; to mistake; changeable",
    "Radical": "心",
    "RadicalIndex": "61.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3689,
    "FrequencyRank": 3144
  },
  {
    "Character": "沩",
    "Pinyin": "wéi",
    "Definition": "name of a river in Shanxi",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3781,
    "FrequencyRank": 7923
  },
  {
    "Character": "邶",
    "Pinyin": "bèi",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3693,
    "FrequencyRank": 7183
  },
  {
    "Character": "忮",
    "Pinyin": "zhì",
    "Definition": "stubborn; perverse; aggressive",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6620,
    "FrequencyRank": 6632
  },
  {
    "Character": "杞",
    "Pinyin": "qǐ",
    "Definition": "willow; medlar tree; a small feudal state (Qi)",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3680,
    "FrequencyRank": 3833
  },
  {
    "Character": "钌",
    "Pinyin": "liǎo",
    "Definition": "ruthenium",
    "Radical": "金",
    "RadicalIndex": "167.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3723,
    "FrequencyRank": 5742
  },
  {
    "Character": "兕",
    "Pinyin": "sì",
    "Definition": "a female rhinoceros",
    "Radical": "儿",
    "RadicalIndex": "10.6",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3718,
    "FrequencyRank": 6937
  },
  {
    "Character": "纭",
    "Pinyin": "yún",
    "Definition": "confused, in disorder; numerous",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3821,
    "FrequencyRank": 4262
  },
  {
    "Character": "邱",
    "Pinyin": "qiū",
    "Definition": "surname; hill; mound; grave",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3729,
    "FrequencyRank": 2452
  },
  {
    "Character": "庑",
    "Pinyin": "wǔ",
    "Definition": "corridor, hallway; luxuriant",
    "Radical": "广",
    "RadicalIndex": "53.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3756,
    "FrequencyRank": 4417
  },
  {
    "Character": "岠",
    "Pinyin": "jù",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6595,
    "FrequencyRank": undefined
  },
  {
    "Character": "呔",
    "Pinyin": "dāi",
    "Definition": "(Cant.) a necktie, a tire",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3701,
    "FrequencyRank": 5137
  },
  {
    "Character": "庋",
    "Pinyin": "guǐ",
    "Definition": "a cupboard or pantry to store",
    "Radical": "广",
    "RadicalIndex": "53.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3757,
    "FrequencyRank": 4422
  },
  {
    "Character": "妣",
    "Pinyin": "bǐ",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3809,
    "FrequencyRank": 6159
  },
  {
    "Character": "闱",
    "Pinyin": "wéi",
    "Definition": "gate, door; living quarters",
    "Radical": "門",
    "RadicalIndex": "169.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3761,
    "FrequencyRank": 4575
  },
  {
    "Character": "杉",
    "Pinyin": "shān",
    "Definition": "various species of pine and fir",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 685,
    "FrequencyRank": 2718
  },
  {
    "Character": "孛",
    "Pinyin": "bèi",
    "Definition": "comet",
    "Radical": "子",
    "RadicalIndex": "39.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3683,
    "FrequencyRank": 4394
  },
  {
    "Character": "𫘜",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6640,
    "FrequencyRank": undefined
  },
  {
    "Character": "钊",
    "Pinyin": "zhāo",
    "Definition": "endeavor, strive; encourage; cut",
    "Radical": "金",
    "RadicalIndex": "167.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3721,
    "FrequencyRank": 3334
  },
  {
    "Character": "诋",
    "Pinyin": "dǐ",
    "Definition": "slander, comdemn, reproach",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3797,
    "FrequencyRank": 4464
  },
  {
    "Character": "芙",
    "Pinyin": "fú",
    "Definition": "hibiscus",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 661,
    "FrequencyRank": 2627
  },
  {
    "Character": "诃",
    "Pinyin": "hē",
    "Definition": "scold loudly, curse, abuse",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3795,
    "FrequencyRank": 3687
  },
  {
    "Character": "芣",
    "Pinyin": "fú",
    "Definition": "medicinal plant",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6570,
    "FrequencyRank": undefined
  },
  {
    "Character": "怆",
    "Pinyin": "chuàng",
    "Definition": "sad, broken-hearted, disconsolate",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3791,
    "FrequencyRank": 4096
  },
  {
    "Character": "闶",
    "Pinyin": "kāng",
    "Definition": "door",
    "Radical": "門",
    "RadicalIndex": "169.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6609,
    "FrequencyRank": 4797
  },
  {
    "Character": "炀",
    "Pinyin": "yáng",
    "Definition": "roast; scorch; melt; flame",
    "Radical": "火",
    "RadicalIndex": "86.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3765,
    "FrequencyRank": 4395
  },
  {
    "Character": "纾",
    "Pinyin": "shū",
    "Definition": "loosen, relax, relieve; extricate",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3825,
    "FrequencyRank": 4392
  },
  {
    "Character": "怅",
    "Pinyin": "chàng",
    "Definition": "disappointed, dissatisfied",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3788,
    "FrequencyRank": 3409
  },
  {
    "Character": "呙",
    "Pinyin": "guō",
    "Definition": "chat, jaw, gossip, talk; mouth",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6591,
    "FrequencyRank": 6323
  },
  {
    "Character": "驴",
    "Pinyin": "lǘ",
    "Definition": "donkey, ass",
    "Radical": "馬",
    "RadicalIndex": "187.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 941,
    "FrequencyRank": 2543
  },
  {
    "Character": "妓",
    "Pinyin": "jì",
    "Definition": "prostitute",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 918,
    "FrequencyRank": 2777
  },
  {
    "Character": "芹",
    "Pinyin": "qín",
    "Definition": "celery",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 666,
    "FrequencyRank": 3740
  },
  {
    "Character": "匣",
    "Pinyin": "xiá",
    "Definition": "small box, case, coffer",
    "Radical": "匚",
    "RadicalIndex": "22.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 692,
    "FrequencyRank": 3061
  },
  {
    "Character": "沤",
    "Pinyin": "ōu",
    "Definition": "soak, steep; sodden or soaked",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3769,
    "FrequencyRank": 4900
  },
  {
    "Character": "吡",
    "Pinyin": "bǐ",
    "Definition": "blame",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3705,
    "FrequencyRank": 5056
  },
  {
    "Character": "疖",
    "Pinyin": "jiē",
    "Definition": "pimple, sore, boil",
    "Radical": "疒",
    "RadicalIndex": "104.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3759,
    "FrequencyRank": 5416
  },
  {
    "Character": "怄",
    "Pinyin": "òu",
    "Definition": "annoyed",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3784,
    "FrequencyRank": 5536
  },
  {
    "Character": "鸠",
    "Pinyin": "jiū",
    "Definition": "pigeon; collect, assemble",
    "Radical": "鳥",
    "RadicalIndex": "196.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3748,
    "FrequencyRank": 3442
  },
  {
    "Character": "岚",
    "Pinyin": "lán",
    "Definition": "mountain mist, mountain haze",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3717,
    "FrequencyRank": 2663
  },
  {
    "Character": "彤",
    "Pinyin": "tóng",
    "Definition": "red, vermilion; name of ancient",
    "Radical": "彡",
    "RadicalIndex": "59.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 817,
    "FrequencyRank": 4075
  },
  {
    "Character": "杕",
    "Pinyin": "dì",
    "Definition": "alone",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6578,
    "FrequencyRank": undefined
  },
  {
    "Character": "佞",
    "Pinyin": "nìng",
    "Definition": "flattery; glib",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3728,
    "FrequencyRank": 4690
  },
  {
    "Character": "呒",
    "Pinyin": "fǔ",
    "Definition": "unclear; an expletive",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3699,
    "FrequencyRank": 5781
  },
  {
    "Character": "诂",
    "Pinyin": "gǔ",
    "Definition": "exegesis, explanation; explain",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3794,
    "FrequencyRank": 5077
  },
  {
    "Character": "佥",
    "Pinyin": "qiān",
    "Definition": "all, together, unanimous",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3738,
    "FrequencyRank": 5613
  },
  {
    "Character": "妘",
    "Pinyin": "yún",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6636,
    "FrequencyRank": undefined
  },
  {
    "Character": "㧐",
    "Pinyin": "sǒng",
    "Definition": "(simplified form of 㩳) to hold; to grasp, to detain, to uphold, to push, to stand upright",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3658,
    "FrequencyRank": undefined
  },
  {
    "Character": "肛",
    "Pinyin": "gāng",
    "Definition": "anus",
    "Radical": "肉",
    "RadicalIndex": "130.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 804,
    "FrequencyRank": 3394
  },
  {
    "Character": "㕮",
    "Pinyin": "fǔ",
    "Definition": "to chew; to masticate, to dwell on, Chinese medicine term",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6592,
    "FrequencyRank": undefined
  },
  {
    "Character": "岍",
    "Pinyin": "qiān",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6593,
    "FrequencyRank": 9783
  },
  {
    "Character": "玛",
    "Pinyin": "mǎ",
    "Definition": "agate; cornelian",
    "Radical": "玉",
    "RadicalIndex": "96.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 603,
    "FrequencyRank": 1248
  },
  {
    "Character": "坜",
    "Pinyin": "lì",
    "Definition": "a hole, pit",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6561,
    "FrequencyRank": 7159
  },
  {
    "Character": "陉",
    "Pinyin": "xíng",
    "Definition": "defile, mountain pass, gorge",
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3805,
    "FrequencyRank": 5710
  },
  {
    "Character": "豸",
    "Pinyin": "zhì",
    "Definition": "KangXi radical 153; legless insects; a legendary beast",
    "Radical": "豸",
    "RadicalIndex": "153",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3740,
    "FrequencyRank": undefined
  },
  {
    "Character": "岜",
    "Pinyin": "bā",
    "Definition": "rock mountain",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6596,
    "FrequencyRank": 6187
  },
  {
    "Character": "沣",
    "Pinyin": "fēng",
    "Definition": "river in Shanxi province",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3766,
    "FrequencyRank": 4376
  },
  {
    "Character": "岖",
    "Pinyin": "qū",
    "Definition": "steep, sheer; rugged, rough",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 753,
    "FrequencyRank": 4065
  },
  {
    "Character": "邳",
    "Pinyin": "pī",
    "Definition": "a department in the State of Lu; in what is now Shantung or N. China",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3685,
    "FrequencyRank": 6080
  },
  {
    "Character": "佚",
    "Pinyin": "yì",
    "Definition": "indulge in pleasures; flee",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3731,
    "FrequencyRank": 3805
  },
  {
    "Character": "抃",
    "Pinyin": "biàn",
    "Definition": "to clap hands; to cheer",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3656,
    "FrequencyRank": undefined
  },
  {
    "Character": "忡",
    "Pinyin": "chōng",
    "Definition": "a sad, uneasy countenance",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3785,
    "FrequencyRank": 3326
  },
  {
    "Character": "狄",
    "Pinyin": "dí",
    "Definition": "tribe from northern china; surnam",
    "Radical": "犬",
    "RadicalIndex": "94.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3746,
    "FrequencyRank": 2219
  },
  {
    "Character": "苈",
    "Pinyin": "lì",
    "Definition": "a kind of plant",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3663,
    "FrequencyRank": 6209
  },
  {
    "Character": "忻",
    "Pinyin": "xīn",
    "Definition": "delightful, joyful, pleasant",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3789,
    "FrequencyRank": 4346
  },
  {
    "Character": "伲",
    "Pinyin": "nì",
    "Definition": "we (Shanghai dialect)",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6605,
    "FrequencyRank": 4454
  },
  {
    "Character": "妗",
    "Pinyin": "jìn",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3811,
    "FrequencyRank": 5150
  },
  {
    "Character": "沦",
    "Pinyin": "lún",
    "Definition": "be lost; sink, be submerged",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 863,
    "FrequencyRank": 2733
  },
  {
    "Character": "亨",
    "Pinyin": "hēng",
    "Definition": "smoothly, progressing, no trouble",
    "Radical": "亠",
    "RadicalIndex": "8.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3755,
    "FrequencyRank": 2146
  },
  {
    "Character": "陀",
    "Pinyin": "tuó",
    "Definition": "steep bank, rough terrain",
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3803,
    "FrequencyRank": 2143
  },
  {
    "Character": "肓",
    "Pinyin": "huāng",
    "Definition": "region between heart and diaphragm",
    "Radical": "肉",
    "RadicalIndex": "130.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3760,
    "FrequencyRank": 4838
  },
  {
    "Character": "忑",
    "Pinyin": "tè",
    "Definition": "fearful; nervous; timid",
    "Radical": "心",
    "RadicalIndex": "61.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3682,
    "FrequencyRank": 4165
  },
  {
    "Character": "妫",
    "Pinyin": "guī",
    "Definition": "family name",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3812,
    "FrequencyRank": 4474
  },
  {
    "Character": "奂",
    "Pinyin": "huàn",
    "Definition": "be numerous, brilliant",
    "Radical": "大",
    "RadicalIndex": "37.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3744,
    "FrequencyRank": 3774
  },
  {
    "Character": "忐",
    "Pinyin": "tǎn",
    "Definition": "timorous; nervous",
    "Radical": "心",
    "RadicalIndex": "61.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3694,
    "FrequencyRank": 4164
  },
  {
    "Character": "芘",
    "Pinyin": "pí",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6573,
    "FrequencyRank": 6550
  },
  {
    "Character": "㳇",
    "Pinyin": "fù",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6618,
    "FrequencyRank": undefined
  },
  {
    "Character": "邴",
    "Pinyin": "bǐng",
    "Definition": "name of a city in the ancient state of Song, in what is now Shandong; pleased",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3684,
    "FrequencyRank": 6824
  },
  {
    "Character": "杓",
    "Pinyin": "biāo",
    "Definition": "handle of cup, ladle, spoon; name of a constellation",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3679,
    "FrequencyRank": 5690
  },
  {
    "Character": "邰",
    "Pinyin": "tái",
    "Definition": "surname; state in modern Shanxi",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3820,
    "FrequencyRank": 5824
  },
  {
    "Character": "刬",
    "Pinyin": "chǎn",
    "Definition": "to level off; to trim; to pare down",
    "Radical": "刀",
    "RadicalIndex": "18.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6559,
    "FrequencyRank": undefined
  },
  {
    "Character": "伭",
    "Pinyin": "xián",
    "Definition": "dark, somber; deep, profound",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6603,
    "FrequencyRank": undefined
  },
  {
    "Character": "邑",
    "Pinyin": "yì",
    "Definition": "area, district, city, state",
    "Radical": "邑",
    "RadicalIndex": "163",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 748,
    "FrequencyRank": 3132
  },
  {
    "Character": "汭",
    "Pinyin": "ruì",
    "Definition": "confluence of two streams; bend",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6617,
    "FrequencyRank": undefined
  },
  {
    "Character": "苁",
    "Pinyin": "cōng",
    "Definition": "medicinal herb",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3670,
    "FrequencyRank": 4704
  },
  {
    "Character": "㑇",
    "Pinyin": "zhòu",
    "Definition": "(a simplified form) clever; ingenious; cute; pretty",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6602,
    "FrequencyRank": undefined
  },
  {
    "Character": "帏",
    "Pinyin": "wéi",
    "Definition": "curtain that forms wall",
    "Radical": "巾",
    "RadicalIndex": "50.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3712,
    "FrequencyRank": 5809
  },
  {
    "Character": "忺",
    "Pinyin": "xiān",
    "Definition": undefined,
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6622,
    "FrequencyRank": undefined
  },
  {
    "Character": "呗",
    "Pinyin": "bei",
    "Definition": "final particle of assertion",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3708,
    "FrequencyRank": 3962
  },
  {
    "Character": "钋",
    "Pinyin": "pō",
    "Definition": "polonium",
    "Radical": "金",
    "RadicalIndex": "167.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3722,
    "FrequencyRank": 5626
  },
  {
    "Character": "囵",
    "Pinyin": "lún",
    "Definition": "all, complete, entire",
    "Radical": "囗",
    "RadicalIndex": "31.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3719,
    "FrequencyRank": 5249
  },
  {
    "Character": "闵",
    "Pinyin": "mǐn",
    "Definition": "mourn, grieve; urge on, incite",
    "Radical": "門",
    "RadicalIndex": "169.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3763,
    "FrequencyRank": 3643
  },
  {
    "Character": "氙",
    "Pinyin": "xiān",
    "Definition": "xenon",
    "Radical": "气",
    "RadicalIndex": "84.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3725,
    "FrequencyRank": 6030
  },
  {
    "Character": "岈",
    "Pinyin": "yá",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3714,
    "FrequencyRank": 7083
  },
  {
    "Character": "阼",
    "Pinyin": "zuò",
    "Definition": "the steps leading to the eastern door",
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6634,
    "FrequencyRank": 6676
  },
  {
    "Character": "芴",
    "Pinyin": "wù",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6574,
    "FrequencyRank": 6128
  },
  {
    "Character": "沥",
    "Pinyin": "lì",
    "Definition": "trickle, drip; strain; dregs",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 859,
    "FrequencyRank": 3598
  },
  {
    "Character": "玓",
    "Pinyin": "dì",
    "Definition": "pearly",
    "Radical": "玉",
    "RadicalIndex": "96.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6556,
    "FrequencyRank": undefined
  },
  {
    "Character": "忭",
    "Pinyin": "biàn",
    "Definition": "delighted; pleased",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3792,
    "FrequencyRank": 6891
  },
  {
    "Character": "佃",
    "Pinyin": "diàn",
    "Definition": "tenant farmer; be a tenant farmer",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 776,
    "FrequencyRank": 3245
  },
  {
    "Character": "忳",
    "Pinyin": "tún",
    "Definition": undefined,
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6621,
    "FrequencyRank": undefined
  },
  {
    "Character": "坉",
    "Pinyin": "tún",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6562,
    "FrequencyRank": undefined
  },
  {
    "Character": "扼",
    "Pinyin": "è",
    "Definition": "grasp, clutch; choke, strangle",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 619,
    "FrequencyRank": 2931
  },
  {
    "Character": "妞",
    "Pinyin": "niū",
    "Definition": "girl",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3813,
    "FrequencyRank": 3200
  },
  {
    "Character": "𫐄",
    "Pinyin": "yuè",
    "Definition": "cross-bar at the end of the poles of a cart",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6586,
    "FrequencyRank": undefined
  },
  {
    "Character": "妍",
    "Pinyin": "yán",
    "Definition": "beautiful, handsome; seductive",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3806,
    "FrequencyRank": 4708
  },
  {
    "Character": "扽",
    "Pinyin": "dèn",
    "Definition": "to move, to shake",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6563,
    "FrequencyRank": undefined
  },
  {
    "Character": "陇",
    "Pinyin": "lǒng",
    "Definition": "mountain located between Shanxi",
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3802,
    "FrequencyRank": 3353
  },
  {
    "Character": "汨",
    "Pinyin": "mì",
    "Definition": "Mi(luo) river in Hunan province where Qu Yuan drowned himself; to sink; used (erroneously) for 汩",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3774,
    "FrequencyRank": 5089
  },
  {
    "Character": "驲",
    "Pinyin": "rì",
    "Definition": "post horse, relay horse",
    "Radical": "馬",
    "RadicalIndex": "187.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6639,
    "FrequencyRank": undefined
  },
  {
    "Character": "妧",
    "Pinyin": "wàn",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6635,
    "FrequencyRank": undefined
  },
  {
    "Character": "玙",
    "Pinyin": "yú",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3649,
    "FrequencyRank": undefined
  },
  {
    "Character": "诐",
    "Pinyin": "bì",
    "Definition": "argue; biased; one-sided",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6628,
    "FrequencyRank": 7349
  },
  {
    "Character": "牤",
    "Pinyin": "māng",
    "Definition": "a bull",
    "Radical": "牛",
    "RadicalIndex": "93.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3727,
    "FrequencyRank": undefined
  },
  {
    "Character": "抉",
    "Pinyin": "jué",
    "Definition": "choose, select; gouge, pluck out",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3657,
    "FrequencyRank": 3575
  },
  {
    "Character": "玕",
    "Pinyin": "gān",
    "Definition": "inferior variety of gem",
    "Radical": "玉",
    "RadicalIndex": "96.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3648,
    "FrequencyRank": undefined
  },
  {
    "Character": "妩",
    "Pinyin": "wǔ",
    "Definition": "charming, enchanting",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3807,
    "FrequencyRank": 4073
  },
  {
    "Character": "邺",
    "Pinyin": "yè",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3696,
    "FrequencyRank": 4431
  },
  {
    "Character": "芸",
    "Pinyin": "yún",
    "Definition": "rue, herb used to keep insects away; to cut grass or weeds; art, talent, ability, craft, technique, performance, acting, trick, stunt",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3661,
    "FrequencyRank": 3099
  },
  {
    "Character": "芷",
    "Pinyin": "zhǐ",
    "Definition": "angelica, type of iris",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3665,
    "FrequencyRank": 4466
  },
  {
    "Character": "苇",
    "Pinyin": "wěi",
    "Definition": "reed",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 663,
    "FrequencyRank": 3233
  },
  {
    "Character": "饩",
    "Pinyin": "xì",
    "Definition": "sacrficial victim; gift; grain",
    "Radical": "食",
    "RadicalIndex": "184.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3751,
    "FrequencyRank": 5163
  },
  {
    "Character": "轫",
    "Pinyin": "rèn",
    "Definition": "a block that keeps a wheel from moving",
    "Radical": "車",
    "RadicalIndex": "159.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3691,
    "FrequencyRank": 5963
  },
  {
    "Character": "岊",
    "Pinyin": "jié",
    "Definition": "foothill",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6631,
    "FrequencyRank": undefined
  },
  {
    "Character": "苌",
    "Pinyin": "cháng",
    "Definition": "averrhora carambola; surname",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3669,
    "FrequencyRank": 5130
  },
  {
    "Character": "豕",
    "Pinyin": "shǐ",
    "Definition": "a pig, boar; KangXi radical 152",
    "Radical": "豕",
    "RadicalIndex": "152",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3688,
    "FrequencyRank": 4953
  },
  {
    "Character": "芡",
    "Pinyin": "qiàn",
    "Definition": "plant allied to the waterlily",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3673,
    "FrequencyRank": 5200
  },
  {
    "Character": "芰",
    "Pinyin": "jì",
    "Definition": "water caltrop",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6569,
    "FrequencyRank": 5332
  },
  {
    "Character": "甬",
    "Pinyin": "yǒng",
    "Definition": "path; river in Ningbo; Ningbo",
    "Radical": "用",
    "RadicalIndex": "101.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3819,
    "FrequencyRank": 4038
  },
  {
    "Character": "吣",
    "Pinyin": "qìn",
    "Definition": "vomit",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3710,
    "FrequencyRank": 6073
  },
  {
    "Character": "抔",
    "Pinyin": "póu",
    "Definition": "take or hold up in both hands",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3651,
    "FrequencyRank": undefined
  },
  {
    "Character": "吠",
    "Pinyin": "fèi",
    "Definition": "bark",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 724,
    "FrequencyRank": 3426
  },
  {
    "Character": "杌",
    "Pinyin": "wù",
    "Definition": "the stump f a tree, hence, sterility; a square stool",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3678,
    "FrequencyRank": 6170
  },
  {
    "Character": "杄",
    "Pinyin": "qiān",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6580,
    "FrequencyRank": undefined
  },
  {
    "Character": "虬",
    "Pinyin": "qiú",
    "Definition": "young dragon",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3707,
    "FrequencyRank": 4242
  },
  {
    "Character": "㧑",
    "Pinyin": "huī",
    "Definition": "(a simplified form 撝) to wave, to assist",
    "Radical": "手",
    "RadicalIndex": "64.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6567,
    "FrequencyRank": undefined
  },
  {
    "Character": "佟",
    "Pinyin": "tóng",
    "Definition": "a name",
    "Radical": "人",
    "RadicalIndex": "9.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3733,
    "FrequencyRank": 3180
  },
  {
    "Character": "坌",
    "Pinyin": "bèn",
    "Definition": "dust, earth; a bank of earth; to dig; to bring together",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3741,
    "FrequencyRank": 6582
  },
  {
    "Character": "狃",
    "Pinyin": "niǔ",
    "Definition": "to covet; to be accustomed",
    "Radical": "犬",
    "RadicalIndex": "94.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6608,
    "FrequencyRank": 6861
  },
  {
    "Character": "呓",
    "Pinyin": "yì",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3700,
    "FrequencyRank": 4470
  },
  {
    "Character": "阽",
    "Pinyin": "diàn",
    "Definition": undefined,
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6632,
    "FrequencyRank": 6938
  },
  {
    "Character": "矶",
    "Pinyin": "jī",
    "Definition": "jetty; submerged rock; eddy",
    "Radical": "石",
    "RadicalIndex": "112.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3686,
    "FrequencyRank": 3232
  },
  {
    "Character": "纴",
    "Pinyin": "rèn",
    "Definition": "weave, lay warp",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3823,
    "FrequencyRank": 8406
  },
  {
    "Character": "芈",
    "Pinyin": "mǐ",
    "Definition": "bleat of sheep; surname",
    "Radical": "羊",
    "RadicalIndex": "123.1",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6588,
    "FrequencyRank": 6208
  },
  {
    "Character": "沈",
    "Pinyin": "chén",
    "Definition": "sink, submerge; addicted to; surname",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 870,
    "FrequencyRank": 1681
  },
  {
    "Character": "呋",
    "Pinyin": "fū",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3698,
    "FrequencyRank": 5177
  },
  {
    "Character": "苄",
    "Pinyin": "biàn",
    "Definition": "benzyl",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3675,
    "FrequencyRank": 5318
  },
  {
    "Character": "吽",
    "Pinyin": "hōng",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3709,
    "FrequencyRank": undefined
  },
  {
    "Character": "玘",
    "Pinyin": "qǐ",
    "Definition": "court-dress jewels",
    "Radical": "玉",
    "RadicalIndex": "96.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6557,
    "FrequencyRank": undefined
  },
  {
    "Character": "妪",
    "Pinyin": "yù",
    "Definition": "old woman, hag",
    "Radical": "女",
    "RadicalIndex": "38.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3808,
    "FrequencyRank": 4872
  },
  {
    "Character": "芠",
    "Pinyin": "wén",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6575,
    "FrequencyRank": undefined
  },
  {
    "Character": "邯",
    "Pinyin": "hán",
    "Definition": "city in Hebei province; various",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3660,
    "FrequencyRank": 3900
  },
  {
    "Character": "奁",
    "Pinyin": "lián",
    "Definition": undefined,
    "Radical": "大",
    "RadicalIndex": "37.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3687,
    "FrequencyRank": 5517
  },
  {
    "Character": "苋",
    "Pinyin": "xiàn",
    "Definition": "amaranth",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3667,
    "FrequencyRank": 4820
  },
  {
    "Character": "忱",
    "Pinyin": "chén",
    "Definition": "truth, sincerity; sincere",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 875,
    "FrequencyRank": 3328
  },
  {
    "Character": "沄",
    "Pinyin": "yún",
    "Definition": "billows",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6614,
    "FrequencyRank": undefined
  },
  {
    "Character": "邲",
    "Pinyin": "bì",
    "Definition": "good-looking; name of a place",
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6626,
    "FrequencyRank": 7020
  },
  {
    "Character": "怃",
    "Pinyin": "wǔ",
    "Definition": "regretful, disappointed",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3783,
    "FrequencyRank": 6296
  },
  {
    "Character": "玚",
    "Pinyin": "chàng",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6558,
    "FrequencyRank": undefined
  },
  {
    "Character": "杙",
    "Pinyin": "yì",
    "Definition": "a tiny wooden post; peg",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6579,
    "FrequencyRank": undefined
  },
  {
    "Character": "饫",
    "Pinyin": "yù",
    "Definition": "surfeited, satiated; confer",
    "Radical": "食",
    "RadicalIndex": "184.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3753,
    "FrequencyRank": 5141
  },
  {
    "Character": "沅",
    "Pinyin": "yuán",
    "Definition": "name of a river in western Hunan that flows into Dongting lake",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3767,
    "FrequencyRank": 4212
  },
  {
    "Character": "旰",
    "Pinyin": "gàn",
    "Definition": "sunset, dusk; evening",
    "Radical": "日",
    "RadicalIndex": "72.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3697,
    "FrequencyRank": 6183
  },
  {
    "Character": "岙",
    "Pinyin": "ào",
    "Definition": "island",
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6600,
    "FrequencyRank": 6495
  },
  {
    "Character": "圻",
    "Pinyin": "qí",
    "Definition": "border, boundary",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3652,
    "FrequencyRank": 5001
  },
  {
    "Character": "町",
    "Pinyin": "tīng",
    "Definition": "raised path between fields",
    "Radical": "田",
    "RadicalIndex": "102.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3706,
    "FrequencyRank": 4001
  },
  {
    "Character": "旸",
    "Pinyin": "yáng",
    "Definition": "rising sun; sunshine",
    "Radical": "日",
    "RadicalIndex": "72.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3704,
    "FrequencyRank": undefined
  },
  {
    "Character": "觃",
    "Pinyin": "yàn",
    "Definition": "place name",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6599,
    "FrequencyRank": undefined
  },
  {
    "Character": "攸",
    "Pinyin": "yōu",
    "Definition": "distant, far; adverbial prefix",
    "Radical": "攴",
    "RadicalIndex": "66.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3730,
    "FrequencyRank": 3416
  },
  {
    "Character": "苡",
    "Pinyin": "yǐ",
    "Definition": "barley",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3677,
    "FrequencyRank": 4874
  },
  {
    "Character": "汧",
    "Pinyin": "qiān",
    "Definition": "name of a river in Shangdong; marsh; float",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6610,
    "FrequencyRank": undefined
  },
  {
    "Character": "呇",
    "Pinyin": "qǐ",
    "Definition": "star",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6597,
    "FrequencyRank": undefined
  },
  {
    "Character": "孚",
    "Pinyin": "fú",
    "Definition": "brood over eggs; have confidence",
    "Radical": "子",
    "RadicalIndex": "39.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3739,
    "FrequencyRank": 3473
  },
  {
    "Character": "诌",
    "Pinyin": "zhōu",
    "Definition": "play with words, quip; talk nonse",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3798,
    "FrequencyRank": 4780
  },
  {
    "Character": "呃",
    "Pinyin": "è",
    "Definition": "belch; hiccup",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3703,
    "FrequencyRank": 3173
  },
  {
    "Character": "轪",
    "Pinyin": "dài",
    "Definition": undefined,
    "Radical": "車",
    "RadicalIndex": "159.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6585,
    "FrequencyRank": 7828
  },
  {
    "Character": "劬",
    "Pinyin": "qú",
    "Definition": "be diligent, toil, endeavor",
    "Radical": "力",
    "RadicalIndex": "19.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3745,
    "FrequencyRank": 5106
  },
  {
    "Character": "芥",
    "Pinyin": "jiè",
    "Definition": "mustard plant; mustard; tiny",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 667,
    "FrequencyRank": 4002
  },
  {
    "Character": "坂",
    "Pinyin": "bǎn",
    "Definition": "hillside, inconvenient places",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3653,
    "FrequencyRank": 4000
  },
  {
    "Character": "姊",
    "Pinyin": "zǐ",
    "Definition": "elder sister",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 921,
    "FrequencyRank": 2236
  },
  {
    "Character": "飏",
    "Pinyin": "yáng",
    "Definition": "soar, fly, float; scatter",
    "Radical": "風",
    "RadicalIndex": "182.9",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6607,
    "FrequencyRank": 6116
  },
  {
    "Character": "尨",
    "Pinyin": "máng",
    "Definition": "shaggy haired dog; variegated",
    "Radical": "尢",
    "RadicalIndex": "43.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6584,
    "FrequencyRank": undefined
  },
  {
    "Character": "沘",
    "Pinyin": "bǐ",
    "Definition": "name of a river",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6615,
    "FrequencyRank": undefined
  },
  {
    "Character": "忾",
    "Pinyin": "kài",
    "Definition": "anger, wrath, hatred, enmity",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3787,
    "FrequencyRank": 5024
  },
  {
    "Character": "沚",
    "Pinyin": "zhǐ",
    "Definition": "islet in stream; small sandbar",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3772,
    "FrequencyRank": undefined
  },
  {
    "Character": "饬",
    "Pinyin": "chì",
    "Definition": "order; command; give command",
    "Radical": "食",
    "RadicalIndex": "184.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3754,
    "FrequencyRank": 3742
  },
  {
    "Character": "纰",
    "Pinyin": "pī",
    "Definition": "spoiled silk; hem of dress; mista",
    "Radical": "糸",
    "RadicalIndex": "120.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3822,
    "FrequencyRank": 5277
  },
  {
    "Character": "坊",
    "Pinyin": "fāng",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 647,
    "FrequencyRank": 2542
  },
  {
    "Character": "芟",
    "Pinyin": "shān",
    "Definition": "mow, cut; weed out; scythe",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3674,
    "FrequencyRank": 5445
  },
  {
    "Character": "芾",
    "Pinyin": "fèi",
    "Definition": "flower; small; little; lush",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3662,
    "FrequencyRank": 5082
  },
  {
    "Character": "轩",
    "Pinyin": "xuān",
    "Definition": "carriage; high; wide; balcony",
    "Radical": "車",
    "RadicalIndex": "159.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 709,
    "FrequencyRank": 2389
  },
  {
    "Character": "肘",
    "Pinyin": "zhǒu",
    "Definition": "the elbow; help a person shoulder a load",
    "Radical": "肉",
    "RadicalIndex": "130.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 806,
    "FrequencyRank": 3010
  },
  {
    "Character": "苉",
    "Pinyin": "pǐ",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6572,
    "FrequencyRank": undefined
  },
  {
    "Character": "苎",
    "Pinyin": "zhù",
    "Definition": "china grass, ramie",
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3676,
    "FrequencyRank": 5453
  },
  {
    "Character": "坎",
    "Pinyin": "kǎn",
    "Definition": "pit, hole; snare, trap; crisis",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 639,
    "FrequencyRank": 2446
  },
  {
    "Character": "孜",
    "Pinyin": "zī",
    "Definition": "be as diligent as possible",
    "Radical": "子",
    "RadicalIndex": "39.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3801,
    "FrequencyRank": 3150
  },
  {
    "Character": "𫘝",
    "Pinyin": "jué",
    "Definition": "gallop",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6643,
    "FrequencyRank": undefined
  },
  {
    "Character": "囫",
    "Pinyin": "hú",
    "Definition": "entire, whole",
    "Radical": "囗",
    "RadicalIndex": "31.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3720,
    "FrequencyRank": 5108
  },
  {
    "Character": "疔",
    "Pinyin": "dīng",
    "Definition": "carbuncle, boil, ulcer",
    "Radical": "疒",
    "RadicalIndex": "104.2",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3758,
    "FrequencyRank": 6359
  },
  {
    "Character": "玒",
    "Pinyin": "hóng",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6555,
    "FrequencyRank": undefined
  },
  {
    "Character": "汫",
    "Pinyin": "jǐng",
    "Definition": "a pit, trap; the appearance of a small, meandering stream",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6611,
    "FrequencyRank": undefined
  },
  {
    "Character": "沁",
    "Pinyin": "qìn",
    "Definition": "soak into, seep in, percolate",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 872,
    "FrequencyRank": 3025
  },
  {
    "Character": "坞",
    "Pinyin": "wù",
    "Definition": "entrenchment, bank, low wall",
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3655,
    "FrequencyRank": 3157
  },
  {
    "Character": "汞",
    "Pinyin": "gǒng",
    "Definition": "element mercury",
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 628,
    "FrequencyRank": 3772
  },
  {
    "Character": "陂",
    "Pinyin": "bēi",
    "Definition": "dam, embankment; reservoir",
    "Radical": "阜",
    "RadicalIndex": "170.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3804,
    "FrequencyRank": 4796
  },
  {
    "Character": "杩",
    "Pinyin": "mà",
    "Definition": "headboard",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6582,
    "FrequencyRank": 6335
  },
  {
    "Character": "杈",
    "Pinyin": "chā",
    "Definition": "fork of a tree; a pitchfork",
    "Radical": "木",
    "RadicalIndex": "75.3",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3681,
    "FrequencyRank": 4529
  },
  {
    "Character": "卣",
    "Pinyin": "yǒu",
    "Definition": "wine pot",
    "Radical": "卜",
    "RadicalIndex": "25.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 3695,
    "FrequencyRank": 5459
  },
  {
    "Character": "尪",
    "Pinyin": "wāng",
    "Definition": "lame",
    "Radical": "尢",
    "RadicalIndex": "43.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6583,
    "FrequencyRank": undefined
  },
  {
    "Character": "诇",
    "Pinyin": "xiòng",
    "Definition": "to spy; to give information; shrewd",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6625,
    "FrequencyRank": 8485
  },
  {
    "Character": "𨚕",
    "Pinyin": "biàn",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 6637,
    "FrequencyRank": undefined
  },
  {
    "Character": "吾",
    "Pinyin": "wú",
    "Definition": "i, my, our; resist, impede",
    "Radical": "口",
    "RadicalIndex": "30.4",
    "StrokeCount": 7,
    "HSK": undefined,
    "GeneralStandard": 695,
    "FrequencyRank": 1649
  },
  {
    "Character": "苕",
    "Pinyin": "sháo",
    "Definition": "rush plant",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3873,
    "FrequencyRank": 5319
  },
  {
    "Character": "泱",
    "Pinyin": "yāng",
    "Definition": "great, expansive; agitated",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4013,
    "FrequencyRank": 4801
  },
  {
    "Character": "泷",
    "Pinyin": "lóng",
    "Definition": "raining; wet; soaked; a river in Guangdong",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4011,
    "FrequencyRank": 4977
  },
  {
    "Character": "狉",
    "Pinyin": "pī",
    "Definition": "fox-cub",
    "Radical": "犬",
    "RadicalIndex": "94.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6712,
    "FrequencyRank": undefined
  },
  {
    "Character": "岞",
    "Pinyin": "zuò",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6684,
    "FrequencyRank": undefined
  },
  {
    "Character": "拤",
    "Pinyin": "qiá",
    "Definition": undefined,
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3838,
    "FrequencyRank": undefined
  },
  {
    "Character": "㭎",
    "Pinyin": "gāng",
    "Definition": "(simplified form of 棡) a tall tree; a large tree, (interchangeable 扛) to carry on the shoulders of two of more men",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6664,
    "FrequencyRank": 9038
  },
  {
    "Character": "坬",
    "Pinyin": "guà",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6653,
    "FrequencyRank": undefined
  },
  {
    "Character": "驸",
    "Pinyin": "fù",
    "Definition": "extra horse; imperial son-in-law",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4078,
    "FrequencyRank": 4218
  },
  {
    "Character": "驺",
    "Pinyin": "zōu",
    "Definition": "mounted escort; groom",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6751,
    "FrequencyRank": 5979
  },
  {
    "Character": "苧",
    "Pinyin": "níng",
    "Definition": "China grass, ramie (Boehmeria nivea)",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6660,
    "FrequencyRank": 7800
  },
  {
    "Character": "妯",
    "Pinyin": "zhóu",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4065,
    "FrequencyRank": 5343
  },
  {
    "Character": "呶",
    "Pinyin": "náo",
    "Definition": "talkative; clamour; hubbub",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3919,
    "FrequencyRank": 4718
  },
  {
    "Character": "㑊",
    "Pinyin": "yì",
    "Definition": "a kind of disease",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6700,
    "FrequencyRank": undefined
  },
  {
    "Character": "峃",
    "Pinyin": "xué",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.13",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6726,
    "FrequencyRank": undefined
  },
  {
    "Character": "肸",
    "Pinyin": "xī",
    "Definition": "spread out; smilling or laughing",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6710,
    "FrequencyRank": 9102
  },
  {
    "Character": "苜",
    "Pinyin": "mù",
    "Definition": "clover",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3859,
    "FrequencyRank": 5032
  },
  {
    "Character": "呤",
    "Pinyin": "lìng",
    "Definition": "purine",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3915,
    "FrequencyRank": 4807
  },
  {
    "Character": "怙",
    "Pinyin": "hù",
    "Definition": "rely on, presume on; persist in",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4025,
    "FrequencyRank": 5675
  },
  {
    "Character": "诣",
    "Pinyin": "yì",
    "Definition": "reach; achievement, accomplishment",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4055,
    "FrequencyRank": 3475
  },
  {
    "Character": "沺",
    "Pinyin": "tián",
    "Definition": "turbulent",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6720,
    "FrequencyRank": undefined
  },
  {
    "Character": "沓",
    "Pinyin": "dá",
    "Definition": "connected, joined; repeated",
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3936,
    "FrequencyRank": 3806
  },
  {
    "Character": "泫",
    "Pinyin": "xuàn",
    "Definition": "weep; cry; shine, glisten",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4019,
    "FrequencyRank": 5843
  },
  {
    "Character": "杲",
    "Pinyin": "gǎo",
    "Definition": "bright sun; brilliant; high",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3899,
    "FrequencyRank": 4837
  },
  {
    "Character": "咂",
    "Pinyin": "zā",
    "Definition": "to suck, to smack the lips; (Cant.) to cheat",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3901,
    "FrequencyRank": 4167
  },
  {
    "Character": "郅",
    "Pinyin": "zhì",
    "Definition": "to go up to. flourishing a superlative",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3894,
    "FrequencyRank": 4264
  },
  {
    "Character": "枋",
    "Pinyin": "fāng",
    "Definition": "sandalwood; tree used as timber",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3882,
    "FrequencyRank": 5260
  },
  {
    "Character": "泸",
    "Pinyin": "lú",
    "Definition": "river in Jiangxi province",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4012,
    "FrequencyRank": 4736
  },
  {
    "Character": "玟",
    "Pinyin": "wén",
    "Definition": "streaks in jade; gem",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6648,
    "FrequencyRank": 6534
  },
  {
    "Character": "苔",
    "Pinyin": "tái",
    "Definition": "moss, lichen",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1015,
    "FrequencyRank": 3106
  },
  {
    "Character": "佯",
    "Pinyin": "yáng",
    "Definition": "pretend, feign; false, deceitful",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3965,
    "FrequencyRank": 3393
  },
  {
    "Character": "戽",
    "Pinyin": "hù",
    "Definition": "to bale out water",
    "Radical": "戶",
    "RadicalIndex": "63.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4044,
    "FrequencyRank": 6351
  },
  {
    "Character": "郃",
    "Pinyin": "hé",
    "Definition": "county in Shanxi province",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6707,
    "FrequencyRank": 5282
  },
  {
    "Character": "祊",
    "Pinyin": "bēng",
    "Definition": "river in Shandong; space inside",
    "Radical": "示",
    "RadicalIndex": "113.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6729,
    "FrequencyRank": undefined
  },
  {
    "Character": "狍",
    "Pinyin": "páo",
    "Definition": "species of deer found in north China",
    "Radical": "犬",
    "RadicalIndex": "94.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3987,
    "FrequencyRank": 5804
  },
  {
    "Character": "祎",
    "Pinyin": "yī",
    "Definition": "excellent",
    "Radical": "示",
    "RadicalIndex": "113.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4048,
    "FrequencyRank": 6124
  },
  {
    "Character": "籴",
    "Pinyin": "dí",
    "Definition": "purchase grains; store grain",
    "Radical": "米",
    "RadicalIndex": "119.2",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3974,
    "FrequencyRank": 5564
  },
  {
    "Character": "乸",
    "Pinyin": "nǎ",
    "Definition": "(Cant.) feminine suffix",
    "Radical": "毋",
    "RadicalIndex": "80.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6741,
    "FrequencyRank": undefined
  },
  {
    "Character": "炅",
    "Pinyin": "jiǒng",
    "Definition": "brilliance",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3907,
    "FrequencyRank": 5817
  },
  {
    "Character": "咝",
    "Pinyin": "sī",
    "Definition": "hiss; call to come; syllable",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3922,
    "FrequencyRank": 4367
  },
  {
    "Character": "旻",
    "Pinyin": "mín",
    "Definition": "heaven",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3905,
    "FrequencyRank": undefined
  },
  {
    "Character": "姗",
    "Pinyin": "shān",
    "Definition": "slander; ridicule; proceed slowly",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4066,
    "FrequencyRank": 3883
  },
  {
    "Character": "昉",
    "Pinyin": "fǎng",
    "Definition": "bright dawn, daybreak; appear",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3906,
    "FrequencyRank": undefined
  },
  {
    "Character": "绉",
    "Pinyin": "zhòu",
    "Definition": "crepe, crape, wrinkles, creases",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4079,
    "FrequencyRank": 4672
  },
  {
    "Character": "诜",
    "Pinyin": "shēn",
    "Definition": "inquire, question; numerous",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4052,
    "FrequencyRank": 4571
  },
  {
    "Character": "枭",
    "Pinyin": "xiāo",
    "Definition": "owl thus, something evil",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3991,
    "FrequencyRank": 3885
  },
  {
    "Character": "攽",
    "Pinyin": "bān",
    "Definition": undefined,
    "Radical": "攴",
    "RadicalIndex": "66.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6708,
    "FrequencyRank": undefined
  },
  {
    "Character": "饳",
    "Pinyin": "duò",
    "Definition": undefined,
    "Radical": "食",
    "RadicalIndex": "184.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6714,
    "FrequencyRank": 9646
  },
  {
    "Character": "垆",
    "Pinyin": "lú",
    "Definition": "black clods of earth; shop, hut",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3841,
    "FrequencyRank": 6454
  },
  {
    "Character": "佼",
    "Pinyin": "jiǎo",
    "Definition": "beautiful, handsome, good-looking",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3964,
    "FrequencyRank": 3993
  },
  {
    "Character": "卺",
    "Pinyin": "jǐn",
    "Definition": "(nuptial) winecups",
    "Radical": "卩",
    "RadicalIndex": "26.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6740,
    "FrequencyRank": 6635
  },
  {
    "Character": "戕",
    "Pinyin": "qiāng",
    "Definition": "kill, slay; wound, injure, hurt",
    "Radical": "戈",
    "RadicalIndex": "62.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4060,
    "FrequencyRank": 4825
  },
  {
    "Character": "驽",
    "Pinyin": "nú",
    "Definition": "tired, old horse; old, weak",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4070,
    "FrequencyRank": 5972
  },
  {
    "Character": "匼",
    "Pinyin": "kē",
    "Definition": undefined,
    "Radical": "匸",
    "RadicalIndex": "23.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6669,
    "FrequencyRank": undefined
  },
  {
    "Character": "坳",
    "Pinyin": "ào",
    "Definition": "a hollow in the ground, a cavity, depression; undulating, depressed",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3852,
    "FrequencyRank": 4450
  },
  {
    "Character": "剁",
    "Pinyin": "duò",
    "Definition": "chop by pounding, mince, hash",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3982,
    "FrequencyRank": 4434
  },
  {
    "Character": "剀",
    "Pinyin": "kǎi",
    "Definition": "sharpen; carefully, thorough",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3933,
    "FrequencyRank": 6294
  },
  {
    "Character": "炆",
    "Pinyin": "wén",
    "Definition": "(Cant.) to simmer, cook over a slow fire",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6718,
    "FrequencyRank": undefined
  },
  {
    "Character": "钎",
    "Pinyin": "qiān",
    "Definition": "tool for boring holes",
    "Radical": "金",
    "RadicalIndex": "167.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3940,
    "FrequencyRank": 5894
  },
  {
    "Character": "兖",
    "Pinyin": "yǎn",
    "Definition": "establish; one of nine empire divisions",
    "Radical": "儿",
    "RadicalIndex": "10.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4000,
    "FrequencyRank": 4893
  },
  {
    "Character": "枞",
    "Pinyin": "cōng",
    "Definition": "fir tree",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3881,
    "FrequencyRank": 4497
  },
  {
    "Character": "苒",
    "Pinyin": "rǎn",
    "Definition": "lush; successively, in order",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3861,
    "FrequencyRank": 6094
  },
  {
    "Character": "殁",
    "Pinyin": "mò",
    "Definition": "die; death; dead",
    "Radical": "歹",
    "RadicalIndex": "78.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3891,
    "FrequencyRank": 4457
  },
  {
    "Character": "茉",
    "Pinyin": "mò",
    "Definition": "white jasmine",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 998,
    "FrequencyRank": 4064
  },
  {
    "Character": "坽",
    "Pinyin": "líng",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6654,
    "FrequencyRank": undefined
  },
  {
    "Character": "炔",
    "Pinyin": "guì",
    "Definition": "acetylene",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4008,
    "FrequencyRank": 4970
  },
  {
    "Character": "疠",
    "Pinyin": "lì",
    "Definition": "a sore, ulcer; pestilence",
    "Radical": "疒",
    "RadicalIndex": "104.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3997,
    "FrequencyRank": 5812
  },
  {
    "Character": "侹",
    "Pinyin": "tǐng",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6696,
    "FrequencyRank": undefined
  },
  {
    "Character": "咕",
    "Pinyin": "gū",
    "Definition": "mumble, mutter, murmur; rumble",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1083,
    "FrequencyRank": 2418
  },
  {
    "Character": "轭",
    "Pinyin": "è",
    "Definition": "yoke, collar",
    "Radical": "車",
    "RadicalIndex": "159.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3893,
    "FrequencyRank": 4880
  },
  {
    "Character": "岱",
    "Pinyin": "dài",
    "Definition": "Daishan, one of the Five Sacred Mountains in China",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3956,
    "FrequencyRank": 4088
  },
  {
    "Character": "牦",
    "Pinyin": "máo",
    "Definition": "tail; hair; yak",
    "Radical": "牛",
    "RadicalIndex": "93.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3947,
    "FrequencyRank": 5337
  },
  {
    "Character": "耶",
    "Pinyin": "yé",
    "Definition": "used in transliteration",
    "Radical": "耳",
    "RadicalIndex": "128.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3853,
    "FrequencyRank": 1174
  },
  {
    "Character": "舠",
    "Pinyin": "dāo",
    "Definition": "long narrow knife-shaped boat",
    "Radical": "舟",
    "RadicalIndex": "137.2",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6705,
    "FrequencyRank": undefined
  },
  {
    "Character": "郐",
    "Pinyin": "kuài",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6706,
    "FrequencyRank": 7228
  },
  {
    "Character": "钖",
    "Pinyin": "yáng",
    "Definition": "frontlet",
    "Radical": "金",
    "RadicalIndex": "167.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6691,
    "FrequencyRank": 8104
  },
  {
    "Character": "泗",
    "Pinyin": "sì",
    "Definition": "mucous; nasal flow; sniffle; river in Shandong",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4015,
    "FrequencyRank": 4388
  },
  {
    "Character": "岿",
    "Pinyin": "kuī",
    "Definition": "grand, stately; secure, lasting",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3924,
    "FrequencyRank": 5768
  },
  {
    "Character": "刳",
    "Pinyin": "kū",
    "Definition": "cut out, dig, rip up, scoop out",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3888,
    "FrequencyRank": 6607
  },
  {
    "Character": "佰",
    "Pinyin": "bǎi",
    "Definition": "hundred",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3952,
    "FrequencyRank": 5437
  },
  {
    "Character": "抻",
    "Pinyin": "chēn",
    "Definition": "pull",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3842,
    "FrequencyRank": 5131
  },
  {
    "Character": "迢",
    "Pinyin": "tiáo",
    "Definition": "far; distant",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1331,
    "FrequencyRank": 3898
  },
  {
    "Character": "茆",
    "Pinyin": "máo",
    "Definition": "species of grass, water mallows",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3867,
    "FrequencyRank": 6629
  },
  {
    "Character": "疝",
    "Pinyin": "shàn",
    "Definition": "hernia, rupture",
    "Radical": "疒",
    "RadicalIndex": "104.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3998,
    "FrequencyRank": 5387
  },
  {
    "Character": "迥",
    "Pinyin": "jiǒng",
    "Definition": "distant, far; separated; different",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3931,
    "FrequencyRank": 3638
  },
  {
    "Character": "祋",
    "Pinyin": "duì",
    "Definition": undefined,
    "Radical": "示",
    "RadicalIndex": "113.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6728,
    "FrequencyRank": 7964
  },
  {
    "Character": "茚",
    "Pinyin": "yìn",
    "Definition": "an organic compound",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3866,
    "FrequencyRank": 7764
  },
  {
    "Character": "驼",
    "Pinyin": "tuó",
    "Definition": "a camel; humpbacked; to carry on the back",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1347,
    "FrequencyRank": 2638
  },
  {
    "Character": "沱",
    "Pinyin": "tuó",
    "Definition": "rivers, streams, waterways; flow",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4021,
    "FrequencyRank": 4382
  },
  {
    "Character": "咆",
    "Pinyin": "páo",
    "Definition": "roar",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3917,
    "FrequencyRank": 3369
  },
  {
    "Character": "拃",
    "Pinyin": "zhǎ",
    "Definition": "grope, press, span",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3844,
    "FrequencyRank": undefined
  },
  {
    "Character": "驵",
    "Pinyin": "zǎng",
    "Definition": "excellent horse, noble steed",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6748,
    "FrequencyRank": 6988
  },
  {
    "Character": "怛",
    "Pinyin": "dá",
    "Definition": "grieved, saddened; worried",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4028,
    "FrequencyRank": 5641
  },
  {
    "Character": "疡",
    "Pinyin": "yáng",
    "Definition": "ulcers, sores; infection",
    "Radical": "疒",
    "RadicalIndex": "104.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3999,
    "FrequencyRank": 3372
  },
  {
    "Character": "枨",
    "Pinyin": "chéng",
    "Definition": "door stop, door jam; touch; (Cant.) to use the body to move someone",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3880,
    "FrequencyRank": 6327
  },
  {
    "Character": "玭",
    "Pinyin": "pín",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3828,
    "FrequencyRank": undefined
  },
  {
    "Character": "奄",
    "Pinyin": "yǎn",
    "Definition": "ere long; remain, tarry; feeble",
    "Radical": "大",
    "RadicalIndex": "37.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3889,
    "FrequencyRank": 3462
  },
  {
    "Character": "驹",
    "Pinyin": "jū",
    "Definition": "colt; fleet, swift; sun; surname",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1343,
    "FrequencyRank": 3691
  },
  {
    "Character": "茓",
    "Pinyin": "xué",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3869,
    "FrequencyRank": undefined
  },
  {
    "Character": "饯",
    "Pinyin": "jiàn",
    "Definition": "farewell party; see off, send off",
    "Radical": "食",
    "RadicalIndex": "184.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3992,
    "FrequencyRank": 4658
  },
  {
    "Character": "诙",
    "Pinyin": "huī",
    "Definition": "tease, joke with; ridicule, mock",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4043,
    "FrequencyRank": 3978
  },
  {
    "Character": "枫",
    "Pinyin": "fēng",
    "Definition": "maple tree",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1028,
    "FrequencyRank": 3229
  },
  {
    "Character": "佺",
    "Pinyin": "quán",
    "Definition": "fairy",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6698,
    "FrequencyRank": undefined
  },
  {
    "Character": "狐",
    "Pinyin": "hú",
    "Definition": "species of fox",
    "Radical": "犬",
    "RadicalIndex": "94.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1196,
    "FrequencyRank": 2321
  },
  {
    "Character": "岽",
    "Pinyin": "dōng",
    "Definition": "place name in Guangxi province",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6682,
    "FrequencyRank": 6272
  },
  {
    "Character": "怦",
    "Pinyin": "pēng",
    "Definition": "eager, ardent, impulsive; anxious",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4027,
    "FrequencyRank": 3636
  },
  {
    "Character": "侁",
    "Pinyin": "shēn",
    "Definition": "crowd",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6695,
    "FrequencyRank": undefined
  },
  {
    "Character": "肱",
    "Pinyin": "gōng",
    "Definition": "forearm",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3980,
    "FrequencyRank": 4921
  },
  {
    "Character": "刿",
    "Pinyin": "guì",
    "Definition": "to cut, injure, stab, stick on",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3930,
    "FrequencyRank": 6008
  },
  {
    "Character": "妲",
    "Pinyin": "dá",
    "Definition": "concubine of last ruler of the Shang dynasty",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4064,
    "FrequencyRank": 4830
  },
  {
    "Character": "冽",
    "Pinyin": "liè",
    "Definition": "cold and raw; pure, clear",
    "Radical": "冫",
    "RadicalIndex": "15.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3994,
    "FrequencyRank": 4356
  },
  {
    "Character": "竺",
    "Pinyin": "zhú",
    "Definition": "India; bamboo; surname",
    "Radical": "竹",
    "RadicalIndex": "118.2",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3948,
    "FrequencyRank": 3439
  },
  {
    "Character": "绊",
    "Pinyin": "bàn",
    "Definition": "loop, catch; fetter, shackle",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1346,
    "FrequencyRank": 3183
  },
  {
    "Character": "弆",
    "Pinyin": "jǔ",
    "Definition": undefined,
    "Radical": "廾",
    "RadicalIndex": "55.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6655,
    "FrequencyRank": undefined
  },
  {
    "Character": "炌",
    "Pinyin": "kài",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6717,
    "FrequencyRank": undefined
  },
  {
    "Character": "诤",
    "Pinyin": "zhēng",
    "Definition": "to expostulate; to remonstrate",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4056,
    "FrequencyRank": 4449
  },
  {
    "Character": "郓",
    "Pinyin": "yùn",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4045,
    "FrequencyRank": 5342
  },
  {
    "Character": "怿",
    "Pinyin": "yì",
    "Definition": "enjoy, be glad, be pleased",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4034,
    "FrequencyRank": 6297
  },
  {
    "Character": "钐",
    "Pinyin": "shān",
    "Definition": "samarium",
    "Radical": "金",
    "RadicalIndex": "167.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6689,
    "FrequencyRank": 6188
  },
  {
    "Character": "泺",
    "Pinyin": "luò",
    "Definition": "river in Shandong province",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4017,
    "FrequencyRank": 6739
  },
  {
    "Character": "坨",
    "Pinyin": "tuó",
    "Definition": "a lump, heap",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3849,
    "FrequencyRank": 5617
  },
  {
    "Character": "侩",
    "Pinyin": "kuài",
    "Definition": "go-between, broker, proxy",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3960,
    "FrequencyRank": 5225
  },
  {
    "Character": "㟃",
    "Pinyin": "sī",
    "Definition": "(corrupted form of 司) to have charge of; to preside over, a (governmental) department",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6686,
    "FrequencyRank": undefined
  },
  {
    "Character": "杻",
    "Pinyin": "chǒu",
    "Definition": "ligustrum sinenese, tree",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3883,
    "FrequencyRank": undefined
  },
  {
    "Character": "狞",
    "Pinyin": "níng",
    "Definition": "ferocious appearance; hideous",
    "Radical": "犬",
    "RadicalIndex": "94.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1199,
    "FrequencyRank": 3457
  },
  {
    "Character": "呸",
    "Pinyin": "pēi",
    "Definition": "expression of reprimand",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3902,
    "FrequencyRank": 3868
  },
  {
    "Character": "佻",
    "Pinyin": "tiāo",
    "Definition": "frivolous; unsteady; delay",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3961,
    "FrequencyRank": 5161
  },
  {
    "Character": "岬",
    "Pinyin": "jiǎ",
    "Definition": "cape; promontory, headland",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3925,
    "FrequencyRank": 4077
  },
  {
    "Character": "陑",
    "Pinyin": "ér",
    "Definition": undefined,
    "Radical": "阜",
    "RadicalIndex": "170.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6736,
    "FrequencyRank": undefined
  },
  {
    "Character": "垈",
    "Pinyin": "dài",
    "Definition": "used in place names; Japanese -nuta; Korean -dae",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6694,
    "FrequencyRank": undefined
  },
  {
    "Character": "隹",
    "Pinyin": "zhuī",
    "Definition": "bird; KangXi radical 172",
    "Radical": "隹",
    "RadicalIndex": "172",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6699,
    "FrequencyRank": 5837
  },
  {
    "Character": "侉",
    "Pinyin": "kuǎ",
    "Definition": "to speak with an accent; big and clumsy",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3954,
    "FrequencyRank": 6199
  },
  {
    "Character": "忝",
    "Pinyin": "tiǎn",
    "Definition": "disgraced; ashamed; self-deprecating",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3834,
    "FrequencyRank": 4729
  },
  {
    "Character": "迨",
    "Pinyin": "dài",
    "Definition": "until, when; seize, arrest",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4073,
    "FrequencyRank": 4517
  },
  {
    "Character": "怵",
    "Pinyin": "chù",
    "Definition": "fear, be afraid; shy, timid",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4026,
    "FrequencyRank": 4588
  },
  {
    "Character": "迤",
    "Pinyin": "yí",
    "Definition": "wind; walk out of straight path",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3949,
    "FrequencyRank": 4732
  },
  {
    "Character": "陕",
    "Pinyin": "shǎn",
    "Definition": "mountain pass; Shanxi province",
    "Radical": "阜",
    "RadicalIndex": "170.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1320,
    "FrequencyRank": 2159
  },
  {
    "Character": "佸",
    "Pinyin": "huó",
    "Definition": "meet",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6697,
    "FrequencyRank": undefined
  },
  {
    "Character": "㤘",
    "Pinyin": "zhòu",
    "Definition": "(simplified form of 㥮) obstinate; stubborn; opinionated; obstinacy; stubbornness; intransigent, truculent; savage, ferocious; fierce",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4031,
    "FrequencyRank": undefined
  },
  {
    "Character": "诡",
    "Pinyin": "guǐ",
    "Definition": "deceive, cheat, defraud; sly",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1298,
    "FrequencyRank": 2578
  },
  {
    "Character": "忿",
    "Pinyin": "fèn",
    "Definition": "get angry; fury, exasperation",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1179,
    "FrequencyRank": 3145
  },
  {
    "Character": "坻",
    "Pinyin": "chí",
    "Definition": "an islet, a rock in a river; an embankment; to stop",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3847,
    "FrequencyRank": 6455
  },
  {
    "Character": "诠",
    "Pinyin": "quán",
    "Definition": "explain, expound, comment on",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4054,
    "FrequencyRank": 3683
  },
  {
    "Character": "泾",
    "Pinyin": "jīng",
    "Definition": "name of a river",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4024,
    "FrequencyRank": 4459
  },
  {
    "Character": "坥",
    "Pinyin": "qū",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6651,
    "FrequencyRank": undefined
  },
  {
    "Character": "苯",
    "Pinyin": "běn",
    "Definition": "benzene; luxuriant",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3855,
    "FrequencyRank": 3686
  },
  {
    "Character": "昇",
    "Pinyin": "shēng",
    "Definition": "rise, ascent; peaceful; peace",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6674,
    "FrequencyRank": undefined
  },
  {
    "Character": "甾",
    "Pinyin": "zāi",
    "Definition": "ground that has been under cultivation for one year; evil, calamity",
    "Radical": "田",
    "RadicalIndex": "102.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4083,
    "FrequencyRank": 5907
  },
  {
    "Character": "戗",
    "Pinyin": "qiāng",
    "Definition": "support",
    "Radical": "戈",
    "RadicalIndex": "62.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3976,
    "FrequencyRank": 5957
  },
  {
    "Character": "宕",
    "Pinyin": "dàng",
    "Definition": "stone quarry; cave dwelling",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4035,
    "FrequencyRank": 4231
  },
  {
    "Character": "邽",
    "Pinyin": "guī",
    "Definition": "name of a county in Han dynasty",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6649,
    "FrequencyRank": 6509
  },
  {
    "Character": "祉",
    "Pinyin": "zhǐ",
    "Definition": "happiness, blessings, good luck",
    "Radical": "示",
    "RadicalIndex": "113.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4049,
    "FrequencyRank": 4484
  },
  {
    "Character": "匦",
    "Pinyin": "guǐ",
    "Definition": "small box; chest, casket",
    "Radical": "匚",
    "RadicalIndex": "22.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3835,
    "FrequencyRank": 4956
  },
  {
    "Character": "䢺",
    "Pinyin": "chū",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6633,
    "FrequencyRank": undefined
  },
  {
    "Character": "玠",
    "Pinyin": "jiè",
    "Definition": "large jade tablet used by officials at court to indicate their ranks",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3829,
    "FrequencyRank": undefined
  },
  {
    "Character": "茁",
    "Pinyin": "zhuó",
    "Definition": "to sprout, flourish; sprouts appearing above-ground; vigorous",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1012,
    "FrequencyRank": 4472
  },
  {
    "Character": "疚",
    "Pinyin": "jiù",
    "Definition": "chronic disease, chronic illness; sorrow",
    "Radical": "疒",
    "RadicalIndex": "104.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1215,
    "FrequencyRank": 3203
  },
  {
    "Character": "玥",
    "Pinyin": "yuè",
    "Definition": "mysterious gem; pearl",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3831,
    "FrequencyRank": undefined
  },
  {
    "Character": "坰",
    "Pinyin": "jiōng",
    "Definition": "wilds",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6652,
    "FrequencyRank": undefined
  },
  {
    "Character": "枘",
    "Pinyin": "ruì",
    "Definition": "the handle of tools",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6665,
    "FrequencyRank": 6312
  },
  {
    "Character": "呦",
    "Pinyin": "yōu",
    "Definition": "the bleating of the deer",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3921,
    "FrequencyRank": 5390
  },
  {
    "Character": "炙",
    "Pinyin": "zhì",
    "Definition": "roast, broil; toast; cauterize",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3990,
    "FrequencyRank": 3672
  },
  {
    "Character": "坯",
    "Pinyin": "pī",
    "Definition": "dam, embankment; weir",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 956,
    "FrequencyRank": 3788
  },
  {
    "Character": "畀",
    "Pinyin": "bì",
    "Definition": "to give",
    "Radical": "田",
    "RadicalIndex": "102.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3909,
    "FrequencyRank": 5859
  },
  {
    "Character": "坤",
    "Pinyin": "kūn",
    "Definition": "earth; feminine, female",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 964,
    "FrequencyRank": 2910
  },
  {
    "Character": "泓",
    "Pinyin": "hóng",
    "Definition": "clear, deep pool of water",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4023,
    "FrequencyRank": 4409
  },
  {
    "Character": "鸤",
    "Pinyin": "shī",
    "Definition": "turtledove, cuckoo",
    "Radical": "鳥",
    "RadicalIndex": "196.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6733,
    "FrequencyRank": undefined
  },
  {
    "Character": "钍",
    "Pinyin": "tǔ",
    "Definition": "thorium",
    "Radical": "金",
    "RadicalIndex": "167.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3939,
    "FrequencyRank": 4649
  },
  {
    "Character": "砀",
    "Pinyin": "dàng",
    "Definition": "brilliantly colored stone with veined patterns in it",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3887,
    "FrequencyRank": 5826
  },
  {
    "Character": "陔",
    "Pinyin": "gāi",
    "Definition": "a step, a terrace, a grade a ledge",
    "Radical": "阜",
    "RadicalIndex": "170.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4063,
    "FrequencyRank": 6581
  },
  {
    "Character": "枅",
    "Pinyin": "jī",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6663,
    "FrequencyRank": undefined
  },
  {
    "Character": "坩",
    "Pinyin": "gān",
    "Definition": "earthenware, earthenware vessel",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3836,
    "FrequencyRank": 6305
  },
  {
    "Character": "黾",
    "Pinyin": "miǎn",
    "Definition": "to strive; to endeavor",
    "Radical": "黽",
    "RadicalIndex": "205",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3913,
    "FrequencyRank": 5223
  },
  {
    "Character": "刽",
    "Pinyin": "guì",
    "Definition": "amputate, cut off",
    "Radical": "刀",
    "RadicalIndex": "18.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3971,
    "FrequencyRank": 3959
  },
  {
    "Character": "坷",
    "Pinyin": "kě",
    "Definition": "clod of earth, lump of soil",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 955,
    "FrequencyRank": 3872
  },
  {
    "Character": "诔",
    "Pinyin": "lěi",
    "Definition": "eulogize, praise the dead",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4039,
    "FrequencyRank": 5227
  },
  {
    "Character": "侔",
    "Pinyin": "móu",
    "Definition": "equal",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3969,
    "FrequencyRank": 5769
  },
  {
    "Character": "炜",
    "Pinyin": "wěi",
    "Definition": "brilliant red; glowing",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4003,
    "FrequencyRank": 4864
  },
  {
    "Character": "帑",
    "Pinyin": "tǎng",
    "Definition": "a treasury; public funds",
    "Radical": "巾",
    "RadicalIndex": "50.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4067,
    "FrequencyRank": 5546
  },
  {
    "Character": "旿",
    "Pinyin": "wǔ",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6673,
    "FrequencyRank": undefined
  },
  {
    "Character": "祇",
    "Pinyin": "qí",
    "Definition": "the spirit of the earth; (used for 只) only, merely, but",
    "Radical": "示",
    "RadicalIndex": "113.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4050,
    "FrequencyRank": 5064
  },
  {
    "Character": "驿",
    "Pinyin": "yì",
    "Definition": "relay station",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4081,
    "FrequencyRank": 3648
  },
  {
    "Character": "坼",
    "Pinyin": "chè",
    "Definition": "split, tear, open",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3846,
    "FrequencyRank": 6355
  },
  {
    "Character": "郄",
    "Pinyin": "qiè",
    "Definition": "surname",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3972,
    "FrequencyRank": 6803
  },
  {
    "Character": "岨",
    "Pinyin": "qū",
    "Definition": "uneven",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6683,
    "FrequencyRank": undefined
  },
  {
    "Character": "鸢",
    "Pinyin": "yuān",
    "Definition": "kite; Milvus species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3895,
    "FrequencyRank": 4922
  },
  {
    "Character": "咍",
    "Pinyin": "hāi",
    "Definition": "laugh",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6680,
    "FrequencyRank": undefined
  },
  {
    "Character": "佾",
    "Pinyin": "yì",
    "Definition": "a row or file of dancers",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3962,
    "FrequencyRank": 6518
  },
  {
    "Character": "侬",
    "Pinyin": "nóng",
    "Definition": "I; you; family name",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3966,
    "FrequencyRank": 3236
  },
  {
    "Character": "昊",
    "Pinyin": "hào",
    "Definition": "summer time; sky; heaven",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3897,
    "FrequencyRank": 3956
  },
  {
    "Character": "弢",
    "Pinyin": "tāo",
    "Definition": "bow case; scabbard; sheathe",
    "Radical": "弓",
    "RadicalIndex": "57.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6734,
    "FrequencyRank": undefined
  },
  {
    "Character": "咒",
    "Pinyin": "zhòu",
    "Definition": "curse, damn, incantation",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1096,
    "FrequencyRank": 2286
  },
  {
    "Character": "佴",
    "Pinyin": "èr",
    "Definition": "a second, an assistant",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6693,
    "FrequencyRank": 6265
  },
  {
    "Character": "迦",
    "Pinyin": "jiā",
    "Definition": "character for transliteration",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4072,
    "FrequencyRank": 2522
  },
  {
    "Character": "炝",
    "Pinyin": "qiàng",
    "Definition": "stir-fry or boil in water or oil then cook with a sauce",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4007,
    "FrequencyRank": 5423
  },
  {
    "Character": "骀",
    "Pinyin": "dài",
    "Definition": "an old, tired horse, a jade; tired, exhausted",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4082,
    "FrequencyRank": 6445
  },
  {
    "Character": "玤",
    "Pinyin": "bàng",
    "Definition": "a kind of gem inferior to jade",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6645,
    "FrequencyRank": undefined
  },
  {
    "Character": "疟",
    "Pinyin": "nüè",
    "Definition": "intermittent fever; malaria",
    "Radical": "疒",
    "RadicalIndex": "104.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1213,
    "FrequencyRank": 4153
  },
  {
    "Character": "泙",
    "Pinyin": "píng",
    "Definition": "roar",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6719,
    "FrequencyRank": undefined
  },
  {
    "Character": "苴",
    "Pinyin": "jū",
    "Definition": "sackcloth; female hemp plant",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3860,
    "FrequencyRank": 5491
  },
  {
    "Character": "泔",
    "Pinyin": "gān",
    "Definition": "water from washing rice to boil thick, as gruel",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4009,
    "FrequencyRank": 5880
  },
  {
    "Character": "钔",
    "Pinyin": "mén",
    "Definition": "mendelevium (Md)",
    "Radical": "金",
    "RadicalIndex": "167.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6690,
    "FrequencyRank": 5938
  },
  {
    "Character": "泯",
    "Pinyin": "mǐn",
    "Definition": "destroy, eliminate; perish",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4022,
    "FrequencyRank": 4320
  },
  {
    "Character": "茔",
    "Pinyin": "yíng",
    "Definition": "grave, tomb, cemetery",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3870,
    "FrequencyRank": 5697
  },
  {
    "Character": "茌",
    "Pinyin": "chí",
    "Definition": "name of a district in Shandong",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3863,
    "FrequencyRank": 6995
  },
  {
    "Character": "枇",
    "Pinyin": "pí",
    "Definition": "loquat",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3875,
    "FrequencyRank": 5358
  },
  {
    "Character": "帛",
    "Pinyin": "bó",
    "Definition": "silks, fabrics; wealth, property",
    "Radical": "巾",
    "RadicalIndex": "50.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3967,
    "FrequencyRank": 3682
  },
  {
    "Character": "陎",
    "Pinyin": "shū",
    "Definition": undefined,
    "Radical": "阜",
    "RadicalIndex": "170.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6738,
    "FrequencyRank": undefined
  },
  {
    "Character": "诘",
    "Pinyin": "jí",
    "Definition": "question, interrogate",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4041,
    "FrequencyRank": 3405
  },
  {
    "Character": "茀",
    "Pinyin": "fú",
    "Definition": "overgrown with grass, weedy",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3872,
    "FrequencyRank": 6476
  },
  {
    "Character": "苫",
    "Pinyin": "shān",
    "Definition": "rush or straw matting",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3858,
    "FrequencyRank": 4623
  },
  {
    "Character": "玦",
    "Pinyin": "jué",
    "Definition": "broken piece of jade",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3832,
    "FrequencyRank": undefined
  },
  {
    "Character": "坫",
    "Pinyin": "diàn",
    "Definition": "a stand on which to replace goblets after drinking",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3839,
    "FrequencyRank": 7160
  },
  {
    "Character": "咄",
    "Pinyin": "duō",
    "Definition": "noise of rage, cry out in anger",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1103,
    "FrequencyRank": 3325
  },
  {
    "Character": "矻",
    "Pinyin": "kū",
    "Definition": "toil, slave away; busy",
    "Radical": "石",
    "RadicalIndex": "112.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6668,
    "FrequencyRank": 6540
  },
  {
    "Character": "诨",
    "Pinyin": "hùn",
    "Definition": "jokes, obsene jests; joke",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4058,
    "FrequencyRank": 4629
  },
  {
    "Character": "杵",
    "Pinyin": "chǔ",
    "Definition": "pestle; baton used beat clothes",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3879,
    "FrequencyRank": 4776
  },
  {
    "Character": "肭",
    "Pinyin": "nà",
    "Definition": "fat; seal (moon radical DKW: 14342 is different)",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6709,
    "FrequencyRank": 5867
  },
  {
    "Character": "宓",
    "Pinyin": "mì",
    "Definition": "quiet, silent; in good health",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4037,
    "FrequencyRank": 4271
  },
  {
    "Character": "茋",
    "Pinyin": "dǐ",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6659,
    "FrequencyRank": undefined
  },
  {
    "Character": "苷",
    "Pinyin": "gān",
    "Definition": "licorice",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3854,
    "FrequencyRank": 4476
  },
  {
    "Character": "泜",
    "Pinyin": "zhī",
    "Definition": "a river in Hebei province",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6722,
    "FrequencyRank": undefined
  },
  {
    "Character": "诖",
    "Pinyin": "guà",
    "Definition": "error, mistake; deceive, mislead",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4040,
    "FrequencyRank": 4982
  },
  {
    "Character": "牥",
    "Pinyin": "fāng",
    "Definition": undefined,
    "Radical": "牛",
    "RadicalIndex": "93.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6692,
    "FrequencyRank": undefined
  },
  {
    "Character": "肼",
    "Pinyin": "jǐng",
    "Definition": "hydrazine",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3977,
    "FrequencyRank": 5202
  },
  {
    "Character": "杳",
    "Pinyin": "yǎo",
    "Definition": "obscure, dark, mysterious, deep",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3877,
    "FrequencyRank": 4293
  },
  {
    "Character": "亟",
    "Pinyin": "jí",
    "Definition": "urgently, immediately, extremely",
    "Radical": "二",
    "RadicalIndex": "7.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4062,
    "FrequencyRank": 3619
  },
  {
    "Character": "弩",
    "Pinyin": "nǔ",
    "Definition": "cross-bow, bow; downward stroke",
    "Radical": "弓",
    "RadicalIndex": "57.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4068,
    "FrequencyRank": 3470
  },
  {
    "Character": "峁",
    "Pinyin": "mǎo",
    "Definition": "yellow dirt mount",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3929,
    "FrequencyRank": 4812
  },
  {
    "Character": "𫍣",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6730,
    "FrequencyRank": undefined
  },
  {
    "Character": "玮",
    "Pinyin": "wěi",
    "Definition": "type of jade; rare, valuable",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3826,
    "FrequencyRank": 5058
  },
  {
    "Character": "劼",
    "Pinyin": "jié",
    "Definition": "be discreet, prudent, cautious",
    "Radical": "力",
    "RadicalIndex": "19.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3843,
    "FrequencyRank": undefined
  },
  {
    "Character": "苓",
    "Pinyin": "líng",
    "Definition": "fungus, tuber; licorice",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3865,
    "FrequencyRank": 4194
  },
  {
    "Character": "玢",
    "Pinyin": "bīn",
    "Definition": "(archaic) a kind of jade",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3830,
    "FrequencyRank": 5844
  },
  {
    "Character": "驷",
    "Pinyin": "sì",
    "Definition": "team of four horses; horses",
    "Radical": "馬",
    "RadicalIndex": "187.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4077,
    "FrequencyRank": 5344
  },
  {
    "Character": "秆",
    "Pinyin": "gǎn",
    "Definition": "straw; stalk of grain",
    "Radical": "禾",
    "RadicalIndex": "115.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1130,
    "FrequencyRank": 4132
  },
  {
    "Character": "肮",
    "Pinyin": "āng",
    "Definition": "dirty",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1187,
    "FrequencyRank": 3194
  },
  {
    "Character": "弨",
    "Pinyin": "chāo",
    "Definition": "bow",
    "Radical": "弓",
    "RadicalIndex": "57.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6735,
    "FrequencyRank": undefined
  },
  {
    "Character": "侘",
    "Pinyin": "chà",
    "Definition": "disappointed, forlorn",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6703,
    "FrequencyRank": undefined
  },
  {
    "Character": "拇",
    "Pinyin": "mǔ",
    "Definition": "thumb; big toe",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 994,
    "FrequencyRank": 3031
  },
  {
    "Character": "诟",
    "Pinyin": "gòu",
    "Definition": "abuse, scold, berate, insult",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4053,
    "FrequencyRank": 4844
  },
  {
    "Character": "泂",
    "Pinyin": "jiǒng",
    "Definition": "clear and deep (of water); wide",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6721,
    "FrequencyRank": undefined
  },
  {
    "Character": "呱",
    "Pinyin": "gū",
    "Definition": "wail, crying of child; swear at",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3914,
    "FrequencyRank": 3476
  },
  {
    "Character": "昙",
    "Pinyin": "tán",
    "Definition": "become cloudy, overcast",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3898,
    "FrequencyRank": 4272
  },
  {
    "Character": "绋",
    "Pinyin": "fú",
    "Definition": "large rope; rope attached to bier",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6753,
    "FrequencyRank": 6940
  },
  {
    "Character": "泅",
    "Pinyin": "qiú",
    "Definition": "swim, float, wade",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4014,
    "FrequencyRank": 4604
  },
  {
    "Character": "怊",
    "Pinyin": "chāo",
    "Definition": undefined,
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6725,
    "FrequencyRank": 9168
  },
  {
    "Character": "冼",
    "Pinyin": "xiǎn",
    "Definition": "a surname",
    "Radical": "冫",
    "RadicalIndex": "15.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3995,
    "FrequencyRank": 5800
  },
  {
    "Character": "肷",
    "Pinyin": "qiǎn",
    "Definition": "area between waist and hips",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6711,
    "FrequencyRank": 5712
  },
  {
    "Character": "臾",
    "Pinyin": "yú",
    "Definition": "moment, instant, short while",
    "Radical": "臼",
    "RadicalIndex": "134.2",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3955,
    "FrequencyRank": 4584
  },
  {
    "Character": "诓",
    "Pinyin": "kuāng",
    "Definition": "cheat, swindle, lie",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4038,
    "FrequencyRank": 4937
  },
  {
    "Character": "瓮",
    "Pinyin": "wèng",
    "Definition": "earthen jar; jar for ashes",
    "Radical": "瓦",
    "RadicalIndex": "98.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3975,
    "FrequencyRank": 3873
  },
  {
    "Character": "䏝",
    "Pinyin": "zhuān",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3978,
    "FrequencyRank": undefined
  },
  {
    "Character": "帚",
    "Pinyin": "zhǒu",
    "Definition": "broom, broomstick",
    "Radical": "巾",
    "RadicalIndex": "50.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1306,
    "FrequencyRank": 3630
  },
  {
    "Character": "泇",
    "Pinyin": "jiā",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6724,
    "FrequencyRank": undefined
  },
  {
    "Character": "佶",
    "Pinyin": "jí",
    "Definition": "strong, robust; exact, correct",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3950,
    "FrequencyRank": 5486
  },
  {
    "Character": "虱",
    "Pinyin": "shī",
    "Definition": "louse; bug; parasite",
    "Radical": "虫",
    "RadicalIndex": "142.2",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4071,
    "FrequencyRank": 4013
  },
  {
    "Character": "邿",
    "Pinyin": "shī",
    "Definition": "a state in Shandong province",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6650,
    "FrequencyRank": 7390
  },
  {
    "Character": "怩",
    "Pinyin": "ní",
    "Definition": "shy, timid, bashful; look ashamed",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4032,
    "FrequencyRank": 4766
  },
  {
    "Character": "泃",
    "Pinyin": "jū",
    "Definition": "river in Henan",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6723,
    "FrequencyRank": undefined
  },
  {
    "Character": "罔",
    "Pinyin": "wǎng",
    "Definition": "net; deceive; libel; negative",
    "Radical": "网",
    "RadicalIndex": "122.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3938,
    "FrequencyRank": 4200
  },
  {
    "Character": "绌",
    "Pinyin": "chù",
    "Definition": "sew, stitch; insufficient",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4080,
    "FrequencyRank": 4831
  },
  {
    "Character": "囹",
    "Pinyin": "líng",
    "Definition": "prison, enclosure",
    "Radical": "囗",
    "RadicalIndex": "31.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3937,
    "FrequencyRank": 5392
  },
  {
    "Character": "炕",
    "Pinyin": "kàng",
    "Definition": "the brick-bed in northern China",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1236,
    "FrequencyRank": 2975
  },
  {
    "Character": "侏",
    "Pinyin": "zhū",
    "Definition": "small, little, tiny, dwarf",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3959,
    "FrequencyRank": 3674
  },
  {
    "Character": "杪",
    "Pinyin": "miǎo",
    "Definition": "tip of twig, top of tree; twig",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3876,
    "FrequencyRank": 6035
  },
  {
    "Character": "盂",
    "Pinyin": "yú",
    "Definition": "basin; cup",
    "Radical": "皿",
    "RadicalIndex": "108.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3833,
    "FrequencyRank": 4100
  },
  {
    "Character": "沭",
    "Pinyin": "shù",
    "Definition": "river in Shantung",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4010,
    "FrequencyRank": 6379
  },
  {
    "Character": "虮",
    "Pinyin": "jǐ",
    "Definition": "louse eggs, nits",
    "Radical": "虫",
    "RadicalIndex": "142.2",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3910,
    "FrequencyRank": 5822
  },
  {
    "Character": "侂",
    "Pinyin": "tuō",
    "Definition": "to commission, to entrust to, to depute; to request, to ask",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6701,
    "FrequencyRank": undefined
  },
  {
    "Character": "侑",
    "Pinyin": "yòu",
    "Definition": "help, assist, repay kindness",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3953,
    "FrequencyRank": 6320
  },
  {
    "Character": "茑",
    "Pinyin": "niǎo",
    "Definition": "the convovulvus; parasitic plants such as mistletoe; Ribes ambiguum",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3868,
    "FrequencyRank": 4983
  },
  {
    "Character": "矾",
    "Pinyin": "fán",
    "Definition": "alum",
    "Radical": "石",
    "RadicalIndex": "112.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1044,
    "FrequencyRank": 4601
  },
  {
    "Character": "劾",
    "Pinyin": "hé",
    "Definition": "examine into, impeach, charge",
    "Radical": "力",
    "RadicalIndex": "19.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4002,
    "FrequencyRank": 3597
  },
  {
    "Character": "泖",
    "Pinyin": "mǎo",
    "Definition": "still waters; river in Jiangsu",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4018,
    "FrequencyRank": 7314
  },
  {
    "Character": "怏",
    "Pinyin": "yàng",
    "Definition": "discontented, dispirited, sad",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4029,
    "FrequencyRank": 4369
  },
  {
    "Character": "狒",
    "Pinyin": "fèi",
    "Definition": "baboon",
    "Radical": "犬",
    "RadicalIndex": "94.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3988,
    "FrequencyRank": 3281
  },
  {
    "Character": "昒",
    "Pinyin": "hū",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6676,
    "FrequencyRank": undefined
  },
  {
    "Character": "昕",
    "Pinyin": "xīn",
    "Definition": "dawn; early morning; day",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3903,
    "FrequencyRank": 4750
  },
  {
    "Character": "妮",
    "Pinyin": "nī",
    "Definition": "maid, servant girl; cute girl",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1328,
    "FrequencyRank": 2124
  },
  {
    "Character": "苑",
    "Pinyin": "yuàn",
    "Definition": "pasture, park, garden; mansion",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1008,
    "FrequencyRank": 2853
  },
  {
    "Character": "衩",
    "Pinyin": "chǎ",
    "Definition": "the open seam of a garment which allows freedom of movement",
    "Radical": "衣",
    "RadicalIndex": "145.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4046,
    "FrequencyRank": 4675
  },
  {
    "Character": "狝",
    "Pinyin": "xiǎn",
    "Definition": "hunt; autumn hunting; to capture with a fine net",
    "Radical": "犬",
    "RadicalIndex": "94.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6713,
    "FrequencyRank": undefined
  },
  {
    "Character": "迩",
    "Pinyin": "ěr",
    "Definition": "be near, be close; recently",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3983,
    "FrequencyRank": 4328
  },
  {
    "Character": "盱",
    "Pinyin": "xū",
    "Definition": "wide open eyes; to gaze in astonishment",
    "Radical": "目",
    "RadicalIndex": "109.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3896,
    "FrequencyRank": 5897
  },
  {
    "Character": "穹",
    "Pinyin": "qióng",
    "Definition": "high and vast; elevated; arched",
    "Radical": "穴",
    "RadicalIndex": "116.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4036,
    "FrequencyRank": 3521
  },
  {
    "Character": "囷",
    "Pinyin": "qūn",
    "Definition": "round-shaped storage bin for grain",
    "Radical": "囗",
    "RadicalIndex": "31.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6687,
    "FrequencyRank": undefined
  },
  {
    "Character": "佬",
    "Pinyin": "lǎo",
    "Definition": "(Cant.) man, person; mature",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3951,
    "FrequencyRank": 3323
  },
  {
    "Character": "怂",
    "Pinyin": "sǒng",
    "Definition": "instigate, arouse, incite",
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3973,
    "FrequencyRank": 3702
  },
  {
    "Character": "诩",
    "Pinyin": "xǔ",
    "Definition": "boast, brag; popular, well-known",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4059,
    "FrequencyRank": 3861
  },
  {
    "Character": "怫",
    "Pinyin": "fú",
    "Definition": "sorry, anxious; depressed",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4033,
    "FrequencyRank": 5716
  },
  {
    "Character": "玱",
    "Pinyin": "qiāng",
    "Definition": "tinkling sound tinkling of pendant gems",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6647,
    "FrequencyRank": undefined
  },
  {
    "Character": "帔",
    "Pinyin": "pèi",
    "Definition": "a skirt; long robe for women, having no sleeves and fasten down the front",
    "Radical": "巾",
    "RadicalIndex": "50.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3934,
    "FrequencyRank": 5722
  },
  {
    "Character": "钗",
    "Pinyin": "chāi",
    "Definition": "ornamental hairpin",
    "Radical": "金",
    "RadicalIndex": "167.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3944,
    "FrequencyRank": 3631
  },
  {
    "Character": "郈",
    "Pinyin": "hòu",
    "Definition": "place in Shandong province",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6704,
    "FrequencyRank": 8017
  },
  {
    "Character": "岣",
    "Pinyin": "gǒu",
    "Definition": "a hill in Hunan",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3928,
    "FrequencyRank": 5933
  },
  {
    "Character": "佽",
    "Pinyin": "cì",
    "Definition": "aid; help",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6702,
    "FrequencyRank": undefined
  },
  {
    "Character": "㧟",
    "Pinyin": "kuǎi",
    "Definition": "(simplified form of 擓) to wipe; to scrub; to rub, to dust; to clean, (a dialect) to scratch with fingers lightly, (a dialect) to carry on the arm",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3848,
    "FrequencyRank": 7963
  },
  {
    "Character": "苠",
    "Pinyin": "mín",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6662,
    "FrequencyRank": 7744
  },
  {
    "Character": "钒",
    "Pinyin": "fán",
    "Definition": "vanadium",
    "Radical": "金",
    "RadicalIndex": "167.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3942,
    "FrequencyRank": 5140
  },
  {
    "Character": "叁",
    "Pinyin": "sān",
    "Definition": undefined,
    "Radical": "厶",
    "RadicalIndex": "28.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1333,
    "FrequencyRank": 4396
  },
  {
    "Character": "郇",
    "Pinyin": "huán",
    "Definition": "an ancient feudal State in Shaanxi",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3984,
    "FrequencyRank": 7096
  },
  {
    "Character": "岷",
    "Pinyin": "mín",
    "Definition": "min mountain, min river",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3932,
    "FrequencyRank": 4693
  },
  {
    "Character": "绐",
    "Pinyin": "dài",
    "Definition": "cheat, fool, pretend",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6754,
    "FrequencyRank": 6780
  },
  {
    "Character": "𫠊",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6752,
    "FrequencyRank": undefined
  },
  {
    "Character": "肽",
    "Pinyin": "tài",
    "Definition": "a chemical compound",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3979,
    "FrequencyRank": 4515
  },
  {
    "Character": "饴",
    "Pinyin": "yí",
    "Definition": "sweet-meats; sweet-cakes; syrup",
    "Radical": "食",
    "RadicalIndex": "184.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3993,
    "FrequencyRank": 5355
  },
  {
    "Character": "怍",
    "Pinyin": "zuò",
    "Definition": "to be ashamed",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4030,
    "FrequencyRank": 6390
  },
  {
    "Character": "昈",
    "Pinyin": "hù",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6677,
    "FrequencyRank": undefined
  },
  {
    "Character": "庖",
    "Pinyin": "páo",
    "Definition": "kitchen; cooking, cuisine",
    "Radical": "广",
    "RadicalIndex": "53.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3996,
    "FrequencyRank": 4149
  },
  {
    "Character": "宛",
    "Pinyin": "wǎn",
    "Definition": "seem, as if, crooked",
    "Radical": "宀",
    "RadicalIndex": "40.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1284,
    "FrequencyRank": 2528
  },
  {
    "Character": "狎",
    "Pinyin": "xiá",
    "Definition": "be familiar with; disrespect",
    "Radical": "犬",
    "RadicalIndex": "94.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3986,
    "FrequencyRank": 5592
  },
  {
    "Character": "杼",
    "Pinyin": "zhù",
    "Definition": "shuttle of loom; narrow; long-headed; a scrub oak; thin",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3885,
    "FrequencyRank": 5015
  },
  {
    "Character": "昀",
    "Pinyin": "yún",
    "Definition": "sun light; used in personal names",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3904,
    "FrequencyRank": 5412
  },
  {
    "Character": "咔",
    "Pinyin": "kā",
    "Definition": "used in translation",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3908,
    "FrequencyRank": 3754
  },
  {
    "Character": "庚",
    "Pinyin": "gēng",
    "Definition": "7th heavenly stem",
    "Radical": "广",
    "RadicalIndex": "53.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1219,
    "FrequencyRank": 3072
  },
  {
    "Character": "岢",
    "Pinyin": "kě",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3923,
    "FrequencyRank": 5078
  },
  {
    "Character": "岵",
    "Pinyin": "hù",
    "Definition": "hill",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6681,
    "FrequencyRank": 5713
  },
  {
    "Character": "炘",
    "Pinyin": "xīn",
    "Definition": "brilliant, shining, bright",
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4006,
    "FrequencyRank": undefined
  },
  {
    "Character": "绀",
    "Pinyin": "gàn",
    "Definition": "dark blue or reddish color",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4074,
    "FrequencyRank": 5122
  },
  {
    "Character": "玡",
    "Pinyin": "yá",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3827,
    "FrequencyRank": undefined
  },
  {
    "Character": "苤",
    "Pinyin": "piě",
    "Definition": "kohl rabi",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3856,
    "FrequencyRank": 5404
  },
  {
    "Character": "耵",
    "Pinyin": "dīng",
    "Definition": undefined,
    "Radical": "耳",
    "RadicalIndex": "128.2",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6656,
    "FrequencyRank": 6738
  },
  {
    "Character": "𫇭",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6576,
    "FrequencyRank": undefined
  },
  {
    "Character": "抿",
    "Pinyin": "mǐn",
    "Definition": "pucker up lips in smile; press",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3851,
    "FrequencyRank": 3729
  },
  {
    "Character": "坭",
    "Pinyin": "ní",
    "Definition": "mud, mire; to paste, to plaster",
    "Radical": "土",
    "RadicalIndex": "32.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3850,
    "FrequencyRank": 6808
  },
  {
    "Character": "枥",
    "Pinyin": "lì",
    "Definition": "type of oak; stable",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3874,
    "FrequencyRank": 5293
  },
  {
    "Character": "拈",
    "Pinyin": "niān",
    "Definition": "pick up with fingers; draw lots",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3840,
    "FrequencyRank": 3616
  },
  {
    "Character": "妭",
    "Pinyin": "bá",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6742,
    "FrequencyRank": undefined
  },
  {
    "Character": "咇",
    "Pinyin": "bié",
    "Definition": "fragrant; (Cant.) to eject",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6679,
    "FrequencyRank": undefined
  },
  {
    "Character": "泮",
    "Pinyin": "pàn",
    "Definition": "Zhou dynasty school; disperse; fall apart",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4020,
    "FrequencyRank": 5962
  },
  {
    "Character": "峂",
    "Pinyin": "tóng",
    "Definition": "name of place",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6685,
    "FrequencyRank": undefined
  },
  {
    "Character": "阜",
    "Pinyin": "fù",
    "Definition": "mound; abundant, ample, numerous",
    "Radical": "阜",
    "RadicalIndex": "170",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3968,
    "FrequencyRank": 3445
  },
  {
    "Character": "於",
    "Pinyin": "yú",
    "Definition": "in, at, on; interjection alas!",
    "Radical": "方",
    "RadicalIndex": "70.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6716,
    "FrequencyRank": 2025
  },
  {
    "Character": "郏",
    "Pinyin": "jiá",
    "Definition": "county in Henan province; surname",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3892,
    "FrequencyRank": 4554
  },
  {
    "Character": "呣",
    "Pinyin": "ḿ",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3920,
    "FrequencyRank": undefined
  },
  {
    "Character": "拊",
    "Pinyin": "fǔ",
    "Definition": "slap; pat; tap; handle of a vessel",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3845,
    "FrequencyRank": 5779
  },
  {
    "Character": "迮",
    "Pinyin": "zé",
    "Definition": "to rise; contracted; cramped",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3946,
    "FrequencyRank": 6576
  },
  {
    "Character": "钏",
    "Pinyin": "chuàn",
    "Definition": "bracelet, armlet",
    "Radical": "金",
    "RadicalIndex": "167.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3941,
    "FrequencyRank": 5164
  },
  {
    "Character": "帙",
    "Pinyin": "zhì",
    "Definition": "book cover; satchel or bag",
    "Radical": "巾",
    "RadicalIndex": "50.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3927,
    "FrequencyRank": 5374
  },
  {
    "Character": "侗",
    "Pinyin": "dòng",
    "Definition": "big; ignorant; rude, rustic",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3957,
    "FrequencyRank": 4406
  },
  {
    "Character": "苞",
    "Pinyin": "bāo",
    "Definition": "a variety of rush; firm, enduring; to burst forth",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1009,
    "FrequencyRank": 3798
  },
  {
    "Character": "枍",
    "Pinyin": "yì",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6666,
    "FrequencyRank": undefined
  },
  {
    "Character": "杷",
    "Pinyin": "pá",
    "Definition": "loquat",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3884,
    "FrequencyRank": 5215
  },
  {
    "Character": "肫",
    "Pinyin": "zhūn",
    "Definition": "the gizzard of a fowl; honest, sincere",
    "Radical": "肉",
    "RadicalIndex": "130.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3981,
    "FrequencyRank": 5513
  },
  {
    "Character": "诛",
    "Pinyin": "zhū",
    "Definition": "execute, kill, put to death; punish",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4051,
    "FrequencyRank": 3274
  },
  {
    "Character": "戾",
    "Pinyin": "lì",
    "Definition": "perverse, recalcitrant, rebellious",
    "Radical": "戶",
    "RadicalIndex": "63.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4042,
    "FrequencyRank": 4232
  },
  {
    "Character": "忞",
    "Pinyin": "mín",
    "Definition": undefined,
    "Radical": "心",
    "RadicalIndex": "61.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6715,
    "FrequencyRank": undefined
  },
  {
    "Character": "苻",
    "Pinyin": "fú",
    "Definition": "kind of herb; type of grass",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3864,
    "FrequencyRank": 4512
  },
  {
    "Character": "穸",
    "Pinyin": "xī",
    "Definition": "the gloom of the grave a tomb or grave; death",
    "Radical": "穴",
    "RadicalIndex": "116.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6727,
    "FrequencyRank": 5943
  },
  {
    "Character": "枧",
    "Pinyin": "jiǎn",
    "Definition": "bamboo tube, wooden peg; spout",
    "Radical": "木",
    "RadicalIndex": "75.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3878,
    "FrequencyRank": 7085
  },
  {
    "Character": "咉",
    "Pinyin": "yāng",
    "Definition": "an echo, a sound to vomit, as an infant",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6678,
    "FrequencyRank": undefined
  },
  {
    "Character": "侪",
    "Pinyin": "chái",
    "Definition": "a company, companion; together",
    "Radical": "人",
    "RadicalIndex": "9.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3963,
    "FrequencyRank": 5429
  },
  {
    "Character": "拗",
    "Pinyin": "ǎo",
    "Definition": "to pull, drag, break off, to pluck (a flower); bent, warped; perverse, obstinate",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 995,
    "FrequencyRank": 3629
  },
  {
    "Character": "瓯",
    "Pinyin": "ōu",
    "Definition": "bowl, cup; small tray",
    "Radical": "瓦",
    "RadicalIndex": "98.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3890,
    "FrequencyRank": 5180
  },
  {
    "Character": "孢",
    "Pinyin": "bāo",
    "Definition": "spore",
    "Radical": "子",
    "RadicalIndex": "39.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4061,
    "FrequencyRank": 4216
  },
  {
    "Character": "苘",
    "Pinyin": "qǐng",
    "Definition": "Indian mallow",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3862,
    "FrequencyRank": 7985
  },
  {
    "Character": "峄",
    "Pinyin": "yì",
    "Definition": "a range of peaks in Shandong and Jiangsu; the name of a mountain",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3935,
    "FrequencyRank": 5405
  },
  {
    "Character": "玞",
    "Pinyin": "fū",
    "Definition": "an inferior agate",
    "Radical": "玉",
    "RadicalIndex": "96.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6646,
    "FrequencyRank": undefined
  },
  {
    "Character": "茏",
    "Pinyin": "lóng",
    "Definition": "tall grass; water-weeds",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3857,
    "FrequencyRank": 4743
  },
  {
    "Character": "怔",
    "Pinyin": "zhēng",
    "Definition": "a disease resembling neurosis",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1265,
    "FrequencyRank": 2191
  },
  {
    "Character": "昃",
    "Pinyin": "zè",
    "Definition": "afternoon; the sun in the afternoon sky; to decline",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3900,
    "FrequencyRank": 6273
  },
  {
    "Character": "祆",
    "Pinyin": "xiān",
    "Definition": "Ormazda, god of the Zoroastrians; extended to god of the Manicheans",
    "Radical": "示",
    "RadicalIndex": "113.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4047,
    "FrequencyRank": 6108
  },
  {
    "Character": "呷",
    "Pinyin": "gā",
    "Definition": "suck, swallow, drink",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3912,
    "FrequencyRank": 4071
  },
  {
    "Character": "叕",
    "Pinyin": "zhuó",
    "Definition": "to connect",
    "Radical": "又",
    "RadicalIndex": "29.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6746,
    "FrequencyRank": undefined
  },
  {
    "Character": "岫",
    "Pinyin": "xiù",
    "Definition": "mountain peak; cave, cavern",
    "Radical": "山",
    "RadicalIndex": "46.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3926,
    "FrequencyRank": 4745
  },
  {
    "Character": "绁",
    "Pinyin": "xiè",
    "Definition": "to contract, draw in, reduce, shorten",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4075,
    "FrequencyRank": 6521
  },
  {
    "Character": "泠",
    "Pinyin": "líng",
    "Definition": "nice and cool, mild and comfortable",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4016,
    "FrequencyRank": 5602
  },
  {
    "Character": "徂",
    "Pinyin": "cú",
    "Definition": "go, advance; to; die",
    "Radical": "彳",
    "RadicalIndex": "60.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3970,
    "FrequencyRank": 6500
  },
  {
    "Character": "钕",
    "Pinyin": "nǚ",
    "Definition": "neodymium",
    "Radical": "金",
    "RadicalIndex": "167.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3943,
    "FrequencyRank": 5604
  },
  {
    "Character": "咛",
    "Pinyin": "níng",
    "Definition": "enjoin, instruct; charge",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3918,
    "FrequencyRank": 4256
  },
  {
    "Character": "咎",
    "Pinyin": "jiù",
    "Definition": "fault, defect; error, mistake",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3989,
    "FrequencyRank": 3225
  },
  {
    "Character": "咚",
    "Pinyin": "dōng",
    "Definition": "onomatopoetic, a thumping sound",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3916,
    "FrequencyRank": 2950
  },
  {
    "Character": "迳",
    "Pinyin": "jìng",
    "Definition": "pass by, approach; direct",
    "Radical": "辵",
    "RadicalIndex": "162.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6745,
    "FrequencyRank": 4495
  },
  {
    "Character": "孥",
    "Pinyin": "nú",
    "Definition": undefined,
    "Radical": "子",
    "RadicalIndex": "39.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4069,
    "FrequencyRank": 6085
  },
  {
    "Character": "泞",
    "Pinyin": "nìng",
    "Definition": "mud; miry, muddy, stagnant",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 1254,
    "FrequencyRank": 3936
  },
  {
    "Character": "矼",
    "Pinyin": "gāng",
    "Definition": "stone bridge; stepping stones",
    "Radical": "石",
    "RadicalIndex": "112.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6667,
    "FrequencyRank": undefined
  },
  {
    "Character": "昄",
    "Pinyin": "bǎn",
    "Definition": "big",
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6675,
    "FrequencyRank": undefined
  },
  {
    "Character": "拂",
    "Pinyin": "fú",
    "Definition": "shake off, brush away; dust",
    "Radical": "手",
    "RadicalIndex": "64.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 986,
    "FrequencyRank": 2623
  },
  {
    "Character": "绂",
    "Pinyin": "fú",
    "Definition": "ribbon or cord used attach ornaments",
    "Radical": "糸",
    "RadicalIndex": "120.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4076,
    "FrequencyRank": 6417
  },
  {
    "Character": "姈",
    "Pinyin": "líng",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6743,
    "FrequencyRank": undefined
  },
  {
    "Character": "苾",
    "Pinyin": "bì",
    "Definition": "smell, fragrance, aroma",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 6661,
    "FrequencyRank": 7247
  },
  {
    "Character": "狙",
    "Pinyin": "jū",
    "Definition": "an ape, monkey; to spy, watch for; to lie",
    "Radical": "犬",
    "RadicalIndex": "94.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3985,
    "FrequencyRank": 3621
  },
  {
    "Character": "邾",
    "Pinyin": "zhū",
    "Definition": "a feudal state which existed B.C.700-469; now in Shandong",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3945,
    "FrequencyRank": 6848
  },
  {
    "Character": "矸",
    "Pinyin": "gān",
    "Definition": "a rock or cliff",
    "Radical": "石",
    "RadicalIndex": "112.3",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3886,
    "FrequencyRank": 4999
  },
  {
    "Character": "茕",
    "Pinyin": "qióng",
    "Definition": "alone; without friends or relativ",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 3871,
    "FrequencyRank": 6194
  },
  {
    "Character": "妾",
    "Pinyin": "qiè",
    "Definition": "concubine",
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": 8,
    "HSK": undefined,
    "GeneralStandard": 4001,
    "FrequencyRank": 3242
  },
  {
    "Character": "牮",
    "Pinyin": "jiàn",
    "Definition": undefined,
    "Radical": "牛",
    "RadicalIndex": "93.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4262,
    "FrequencyRank": undefined
  },
  {
    "Character": "闿",
    "Pinyin": "kǎi",
    "Definition": "open; peaceful",
    "Radical": "門",
    "RadicalIndex": "169.1",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4318,
    "FrequencyRank": 4696
  },
  {
    "Character": "荑",
    "Pinyin": "tí",
    "Definition": "sprouts; tares",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4110,
    "FrequencyRank": 6285
  },
  {
    "Character": "郛",
    "Pinyin": "fú",
    "Definition": "outer walls of city; suburbs",
    "Radical": "邑",
    "RadicalIndex": "163.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4277,
    "FrequencyRank": 5069
  },
  {
    "Character": "茹",
    "Pinyin": "rú",
    "Definition": "roots; vegetables; eat; bear",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4136,
    "FrequencyRank": 3532
  },
  {
    "Character": "砑",
    "Pinyin": "yà",
    "Definition": "to grind, to calender, to polish; to roll with a stone roller",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6810,
    "FrequencyRank": 6292
  },
  {
    "Character": "莒",
    "Pinyin": "jǔ",
    "Definition": "hemp-like plant; taro; herb",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4113,
    "FrequencyRank": 5148
  },
  {
    "Character": "昶",
    "Pinyin": "chǎng",
    "Definition": "a long day. bright. extended. clear",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4374,
    "FrequencyRank": 4904
  },
  {
    "Character": "郗",
    "Pinyin": "xī",
    "Definition": "city under the Chou Dyn. a surname",
    "Radical": "邑",
    "RadicalIndex": "163.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4273,
    "FrequencyRank": 6459
  },
  {
    "Character": "垴",
    "Pinyin": "nǎo",
    "Definition": "small, head shaped hill, used in place names",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6776,
    "FrequencyRank": 6022
  },
  {
    "Character": "轸",
    "Pinyin": "zhěn",
    "Definition": "cross board at rear of carriage",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4182,
    "FrequencyRank": 5511
  },
  {
    "Character": "挦",
    "Pinyin": "xián",
    "Definition": "pull out, pluck; take hold of",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4106,
    "FrequencyRank": undefined
  },
  {
    "Character": "洎",
    "Pinyin": "jì",
    "Definition": "until; till; soup; to soak",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4340,
    "FrequencyRank": 6501
  },
  {
    "Character": "荙",
    "Pinyin": "dá",
    "Definition": "plantago major",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6785,
    "FrequencyRank": 9900
  },
  {
    "Character": "茜",
    "Pinyin": "qiàn",
    "Definition": "madder, rubia cordifolia; reeds",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4108,
    "FrequencyRank": 3097
  },
  {
    "Character": "姽",
    "Pinyin": "guǐ",
    "Definition": "good",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6915,
    "FrequencyRank": undefined
  },
  {
    "Character": "厖",
    "Pinyin": "páng",
    "Definition": "bulky; thick and large; confused",
    "Radical": "厂",
    "RadicalIndex": "27.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6808,
    "FrequencyRank": undefined
  },
  {
    "Character": "訇",
    "Pinyin": "hōng",
    "Definition": "the sound of a crash",
    "Radical": "言",
    "RadicalIndex": "149.2",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4296,
    "FrequencyRank": 5477
  },
  {
    "Character": "姮",
    "Pinyin": "héng",
    "Definition": "lady",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4383,
    "FrequencyRank": undefined
  },
  {
    "Character": "哝",
    "Pinyin": "nóng",
    "Definition": "whisper",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4227,
    "FrequencyRank": 3581
  },
  {
    "Character": "砘",
    "Pinyin": "dùn",
    "Definition": "a kind of farm tool",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4166,
    "FrequencyRank": 6160
  },
  {
    "Character": "宦",
    "Pinyin": "huàn",
    "Definition": "officialdom, government official",
    "Radical": "宀",
    "RadicalIndex": "40.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1696,
    "FrequencyRank": 2976
  },
  {
    "Character": "珐",
    "Pinyin": "fà",
    "Definition": "enamel, cloissoné",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4085,
    "FrequencyRank": 5432
  },
  {
    "Character": "疮",
    "Pinyin": "chuāng",
    "Definition": "tumor, boil, sore, wound",
    "Radical": "疒",
    "RadicalIndex": "104.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1625,
    "FrequencyRank": 3093
  },
  {
    "Character": "郧",
    "Pinyin": "yún",
    "Definition": "county in Hubei province",
    "Radical": "邑",
    "RadicalIndex": "163.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4217,
    "FrequencyRank": 4805
  },
  {
    "Character": "轺",
    "Pinyin": "yáo",
    "Definition": "small light carriage",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6818,
    "FrequencyRank": 6862
  },
  {
    "Character": "诳",
    "Pinyin": "kuáng",
    "Definition": "deceive, lie, delude, cheat",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4372,
    "FrequencyRank": 4488
  },
  {
    "Character": "钘",
    "Pinyin": "xíng",
    "Definition": "ancient wine vessel",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6843,
    "FrequencyRank": 7114
  },
  {
    "Character": "绛",
    "Pinyin": "jiàng",
    "Definition": "deep red; river in Shanxi provinc",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4397,
    "FrequencyRank": 3987
  },
  {
    "Character": "鸨",
    "Pinyin": "bǎo",
    "Definition": "bustard; procuress; Otis species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4289,
    "FrequencyRank": 5000
  },
  {
    "Character": "炷",
    "Pinyin": "zhù",
    "Definition": "candle wick, lamp wick; stick of incense",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4329,
    "FrequencyRank": 5424
  },
  {
    "Character": "𪨰",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6839,
    "FrequencyRank": undefined
  },
  {
    "Character": "茸",
    "Pinyin": "rōng",
    "Definition": "soft, downy; buds, sprouts",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1402,
    "FrequencyRank": 3343
  },
  {
    "Character": "砜",
    "Pinyin": "fēng",
    "Definition": "an organic compound",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4170,
    "FrequencyRank": 5995
  },
  {
    "Character": "殄",
    "Pinyin": "tiǎn",
    "Definition": "to end; to exterminate",
    "Radical": "歹",
    "RadicalIndex": "78.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4176,
    "FrequencyRank": 6018
  },
  {
    "Character": "朏",
    "Pinyin": "fěi",
    "Definition": "light of crescent moon",
    "Radical": "月",
    "RadicalIndex": "74.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6871,
    "FrequencyRank": undefined
  },
  {
    "Character": "咤",
    "Pinyin": "zhà",
    "Definition": "scold, bellow, shout at, roar",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4226,
    "FrequencyRank": 4730
  },
  {
    "Character": "咥",
    "Pinyin": "xì",
    "Definition": "sound of a cat; bite; laugh",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6831,
    "FrequencyRank": undefined
  },
  {
    "Character": "娆",
    "Pinyin": "ráo",
    "Definition": "graceful, charming, fascinating",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4384,
    "FrequencyRank": 5584
  },
  {
    "Character": "玲",
    "Pinyin": "líng",
    "Definition": "tinkling of jade",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1359,
    "FrequencyRank": 1855
  },
  {
    "Character": "韨",
    "Pinyin": "fú",
    "Definition": "a leather knee-pad worn during sacrifices",
    "Radical": "韋",
    "RadicalIndex": "178.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6766,
    "FrequencyRank": 8714
  },
  {
    "Character": "剐",
    "Pinyin": "guǎ",
    "Definition": "cut, cut flesh from bones",
    "Radical": "刀",
    "RadicalIndex": "18.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4216,
    "FrequencyRank": 4794
  },
  {
    "Character": "轵",
    "Pinyin": "zhǐ",
    "Definition": "end of axle; divergent",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6815,
    "FrequencyRank": 7264
  },
  {
    "Character": "咪",
    "Pinyin": "mī",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1514,
    "FrequencyRank": 3313
  },
  {
    "Character": "浈",
    "Pinyin": "zhēn",
    "Definition": "river in Guangdong province",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6886,
    "FrequencyRank": undefined
  },
  {
    "Character": "恹",
    "Pinyin": "yān",
    "Definition": "feeble, sickly; tranquil, calm",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4351,
    "FrequencyRank": 5184
  },
  {
    "Character": "柙",
    "Pinyin": "xiá",
    "Definition": "cage, pen for wild animals",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4147,
    "FrequencyRank": 6150
  },
  {
    "Character": "俫",
    "Pinyin": "lái",
    "Definition": "to induce to come; to encourage",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6854,
    "FrequencyRank": undefined
  },
  {
    "Character": "哌",
    "Pinyin": "pài",
    "Definition": "used in translation",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4221,
    "FrequencyRank": 5755
  },
  {
    "Character": "侴",
    "Pinyin": "chǒu",
    "Definition": "surname",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6863,
    "FrequencyRank": undefined
  },
  {
    "Character": "扂",
    "Pinyin": "diàn",
    "Definition": undefined,
    "Radical": "戶",
    "RadicalIndex": "63.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6902,
    "FrequencyRank": undefined
  },
  {
    "Character": "恪",
    "Pinyin": "kè",
    "Definition": "respectful, reverent",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4356,
    "FrequencyRank": 3483
  },
  {
    "Character": "咩",
    "Pinyin": "miē",
    "Definition": "the bleating of sheep; (Cant.) an interrogative particle",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4225,
    "FrequencyRank": 4752
  },
  {
    "Character": "茯",
    "Pinyin": "fú",
    "Definition": "china root; medicinal fungus",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4119,
    "FrequencyRank": 4744
  },
  {
    "Character": "贰",
    "Pinyin": "èr",
    "Definition": "number two",
    "Radical": "弋",
    "RadicalIndex": "56.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1353,
    "FrequencyRank": 3894
  },
  {
    "Character": "浉",
    "Pinyin": "shī",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6887,
    "FrequencyRank": undefined
  },
  {
    "Character": "垞",
    "Pinyin": "chá",
    "Definition": "small mound; place name; hillock",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6778,
    "FrequencyRank": undefined
  },
  {
    "Character": "洭",
    "Pinyin": "kuāng",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6880,
    "FrequencyRank": undefined
  },
  {
    "Character": "饻",
    "Pinyin": "xī",
    "Definition": undefined,
    "Radical": "食",
    "RadicalIndex": "184.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6874,
    "FrequencyRank": 9647
  },
  {
    "Character": "恺",
    "Pinyin": "kǎi",
    "Definition": "enjoy, be contented, joyful",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4353,
    "FrequencyRank": 3432
  },
  {
    "Character": "咻",
    "Pinyin": "xiū",
    "Definition": "shout",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4218,
    "FrequencyRank": 4347
  },
  {
    "Character": "荥",
    "Pinyin": "xíng",
    "Definition": "a county in Henan; the rising and dashing of waves",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4130,
    "FrequencyRank": 5047
  },
  {
    "Character": "洳",
    "Pinyin": "rù",
    "Definition": "damp, boggy, marshy",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4348,
    "FrequencyRank": 5622
  },
  {
    "Character": "拮",
    "Pinyin": "jié",
    "Definition": "laboring hard, occupied; pursue",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4093,
    "FrequencyRank": 4536
  },
  {
    "Character": "奎",
    "Pinyin": "kuí",
    "Definition": "stride of man; one of the twenty-eight lunar mansions",
    "Radical": "大",
    "RadicalIndex": "37.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4171,
    "FrequencyRank": 2608
  },
  {
    "Character": "哕",
    "Pinyin": "huì",
    "Definition": "belch; vomit",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4215,
    "FrequencyRank": 7236
  },
  {
    "Character": "埏",
    "Pinyin": "shān",
    "Definition": "a boundary, a limit",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6771,
    "FrequencyRank": 6998
  },
  {
    "Character": "炯",
    "Pinyin": "jiǒng",
    "Definition": "bright, brilliant, clear; hot",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4327,
    "FrequencyRank": 2933
  },
  {
    "Character": "羿",
    "Pinyin": "yì",
    "Definition": "legendary archer",
    "Radical": "羽",
    "RadicalIndex": "124.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4390,
    "FrequencyRank": 4860
  },
  {
    "Character": "烃",
    "Pinyin": "tīng",
    "Definition": "hydrocarbon",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4330,
    "FrequencyRank": 4918
  },
  {
    "Character": "迺",
    "Pinyin": "nǎi",
    "Definition": "then, thereupon, only then",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6807,
    "FrequencyRank": 7986
  },
  {
    "Character": "轷",
    "Pinyin": "hū",
    "Definition": "surname",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6816,
    "FrequencyRank": 9008
  },
  {
    "Character": "玿",
    "Pinyin": "sháo",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6765,
    "FrequencyRank": undefined
  },
  {
    "Character": "胗",
    "Pinyin": "zhēn",
    "Definition": "pustules of any kind, a rash or eruption.measles; various kinds of fever",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4285,
    "FrequencyRank": 5498
  },
  {
    "Character": "奓",
    "Pinyin": "zhā",
    "Definition": "extravagant",
    "Radical": "大",
    "RadicalIndex": "37.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6813,
    "FrequencyRank": undefined
  },
  {
    "Character": "竽",
    "Pinyin": "yú",
    "Definition": "ancient woodwind instrument",
    "Radical": "竹",
    "RadicalIndex": "118.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4253,
    "FrequencyRank": 4935
  },
  {
    "Character": "柘",
    "Pinyin": "zhè",
    "Definition": "a thorny tree; sugarcane",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4142,
    "FrequencyRank": 5583
  },
  {
    "Character": "姣",
    "Pinyin": "jiāo",
    "Definition": "beautiful, handsome, pretty",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4386,
    "FrequencyRank": 4676
  },
  {
    "Character": "钭",
    "Pinyin": "tǒu",
    "Definition": "a wine flagon",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6851,
    "FrequencyRank": 5717
  },
  {
    "Character": "衎",
    "Pinyin": "kàn",
    "Definition": "to give pleasure; pleased, happy",
    "Radical": "行",
    "RadicalIndex": "144.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6860,
    "FrequencyRank": undefined
  },
  {
    "Character": "枷",
    "Pinyin": "jiā",
    "Definition": "cangue scaffold",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4159,
    "FrequencyRank": 3663
  },
  {
    "Character": "祐",
    "Pinyin": "yòu",
    "Definition": "divine intervention, protection",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6905,
    "FrequencyRank": 4281
  },
  {
    "Character": "绗",
    "Pinyin": "háng",
    "Definition": "baste",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4396,
    "FrequencyRank": 9735
  },
  {
    "Character": "胝",
    "Pinyin": "zhī",
    "Definition": "callous, corn",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4286,
    "FrequencyRank": 4773
  },
  {
    "Character": "虸",
    "Pinyin": "zǐ",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6834,
    "FrequencyRank": undefined
  },
  {
    "Character": "洱",
    "Pinyin": "ěr",
    "Definition": "a lake in Yunnan",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4331,
    "FrequencyRank": 5109
  },
  {
    "Character": "珋",
    "Pinyin": "liǔ",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6762,
    "FrequencyRank": undefined
  },
  {
    "Character": "眈",
    "Pinyin": "dān",
    "Definition": "gloat, stare at; to delay, to loiter, to hinder",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4194,
    "FrequencyRank": 3979
  },
  {
    "Character": "砗",
    "Pinyin": "chē",
    "Definition": "giant clam, tridacna gigas",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4165,
    "FrequencyRank": 5603
  },
  {
    "Character": "氟",
    "Pinyin": "fú",
    "Definition": "fluorine",
    "Radical": "气",
    "RadicalIndex": "84.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4248,
    "FrequencyRank": 3701
  },
  {
    "Character": "帡",
    "Pinyin": "píng",
    "Definition": "shelter, screen, awning",
    "Radical": "巾",
    "RadicalIndex": "50.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6842,
    "FrequencyRank": undefined
  },
  {
    "Character": "郦",
    "Pinyin": "lì",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4163,
    "FrequencyRank": 5123
  },
  {
    "Character": "垌",
    "Pinyin": "dòng",
    "Definition": "a field; used in place names",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4102,
    "FrequencyRank": 9151
  },
  {
    "Character": "舢",
    "Pinyin": "shān",
    "Definition": "sampan",
    "Radical": "舟",
    "RadicalIndex": "137.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4271,
    "FrequencyRank": 5346
  },
  {
    "Character": "轱",
    "Pinyin": "gū",
    "Definition": "wheel; to turn; to revolve",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4178,
    "FrequencyRank": 4747
  },
  {
    "Character": "柊",
    "Pinyin": "zhōng",
    "Definition": "holly",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6800,
    "FrequencyRank": undefined
  },
  {
    "Character": "俙",
    "Pinyin": "xī",
    "Definition": "to pretend, appear as if",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6857,
    "FrequencyRank": undefined
  },
  {
    "Character": "怼",
    "Pinyin": "duì",
    "Definition": "hate, abhor; hatred, resentment",
    "Radical": "心",
    "RadicalIndex": "61.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4389,
    "FrequencyRank": 4748
  },
  {
    "Character": "恻",
    "Pinyin": "cè",
    "Definition": "feel anguish, feel compassion",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4354,
    "FrequencyRank": 3896
  },
  {
    "Character": "玹",
    "Pinyin": "xuán",
    "Definition": "jadelike precious stone; jade-colored",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6763,
    "FrequencyRank": undefined
  },
  {
    "Character": "洺",
    "Pinyin": "míng",
    "Definition": "river in Hebei province",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6893,
    "FrequencyRank": undefined
  },
  {
    "Character": "虷",
    "Pinyin": "hán",
    "Definition": "worm",
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6833,
    "FrequencyRank": undefined
  },
  {
    "Character": "郝",
    "Pinyin": "hǎo",
    "Definition": "surname; place in modern Shanxi",
    "Radical": "邑",
    "RadicalIndex": "163.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4103,
    "FrequencyRank": 3094
  },
  {
    "Character": "柈",
    "Pinyin": "bàn",
    "Definition": "container",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4157,
    "FrequencyRank": undefined
  },
  {
    "Character": "彖",
    "Pinyin": "tuàn",
    "Definition": "a hog; a hedgehog; a porcupine",
    "Radical": "彐",
    "RadicalIndex": "58.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6923,
    "FrequencyRank": 6434
  },
  {
    "Character": "衽",
    "Pinyin": "rèn",
    "Definition": "lapel; hidden lapel of coat",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4361,
    "FrequencyRank": 5736
  },
  {
    "Character": "闽",
    "Pinyin": "mǐn",
    "Definition": "fujian province; a river; a tribe",
    "Radical": "門",
    "RadicalIndex": "169.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1637,
    "FrequencyRank": 3306
  },
  {
    "Character": "洸",
    "Pinyin": "guāng",
    "Definition": "sparkle, glitter",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6888,
    "FrequencyRank": undefined
  },
  {
    "Character": "籽",
    "Pinyin": "zǐ",
    "Definition": "seed, pip, pit, stone",
    "Radical": "米",
    "RadicalIndex": "119.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1648,
    "FrequencyRank": 3567
  },
  {
    "Character": "胣",
    "Pinyin": "chǐ",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6870,
    "FrequencyRank": undefined
  },
  {
    "Character": "胍",
    "Pinyin": "guā",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4284,
    "FrequencyRank": 5090
  },
  {
    "Character": "疥",
    "Pinyin": "jiè",
    "Definition": "scabies, itch",
    "Radical": "疒",
    "RadicalIndex": "104.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4310,
    "FrequencyRank": 5031
  },
  {
    "Character": "栌",
    "Pinyin": "lú",
    "Definition": "supporting block; sumac, loquat",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4146,
    "FrequencyRank": 6001
  },
  {
    "Character": "浕",
    "Pinyin": "jìn",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.14",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4347,
    "FrequencyRank": undefined
  },
  {
    "Character": "柃",
    "Pinyin": "líng",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6799,
    "FrequencyRank": 7994
  },
  {
    "Character": "盷",
    "Pinyin": "tián",
    "Definition": undefined,
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6822,
    "FrequencyRank": undefined
  },
  {
    "Character": "挞",
    "Pinyin": "tà",
    "Definition": "flog, whip; chastise",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4097,
    "FrequencyRank": 4510
  },
  {
    "Character": "绔",
    "Pinyin": "kù",
    "Definition": "trousers",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4393,
    "FrequencyRank": 5259
  },
  {
    "Character": "哔",
    "Pinyin": "bì",
    "Definition": "used in transliterations",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4204,
    "FrequencyRank": 5434
  },
  {
    "Character": "砆",
    "Pinyin": "fū",
    "Definition": "1/2 pr stone",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6809,
    "FrequencyRank": undefined
  },
  {
    "Character": "飐",
    "Pinyin": "zhǎn",
    "Definition": "sway in wind",
    "Radical": "風",
    "RadicalIndex": "182.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6872,
    "FrequencyRank": 8370
  },
  {
    "Character": "郢",
    "Pinyin": "yǐng",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4191,
    "FrequencyRank": 4751
  },
  {
    "Character": "拭",
    "Pinyin": "shì",
    "Definition": "wipe away stains with cloth",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1364,
    "FrequencyRank": 3337
  },
  {
    "Character": "皈",
    "Pinyin": "guī",
    "Definition": "follow, comply with",
    "Radical": "白",
    "RadicalIndex": "106.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4265,
    "FrequencyRank": 3660
  },
  {
    "Character": "昵",
    "Pinyin": "nì",
    "Definition": "intimate, close; approach",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4201,
    "FrequencyRank": 4089
  },
  {
    "Character": "咺",
    "Pinyin": "xuǎn",
    "Definition": "majestic",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6824,
    "FrequencyRank": undefined
  },
  {
    "Character": "袆",
    "Pinyin": "huī",
    "Definition": "ceremonial gowns of a queen",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6903,
    "FrequencyRank": 6846
  },
  {
    "Character": "郜",
    "Pinyin": "gào",
    "Definition": "name of fief in Shantong bestowed on the eldest son of Wen Wang",
    "Radical": "邑",
    "RadicalIndex": "163.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4250,
    "FrequencyRank": 6218
  },
  {
    "Character": "郚",
    "Pinyin": "wú",
    "Definition": "towns in Shandong province",
    "Radical": "邑",
    "RadicalIndex": "163.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6804,
    "FrequencyRank": undefined
  },
  {
    "Character": "洄",
    "Pinyin": "huí",
    "Definition": "a back-water; an eddy a whirlpool",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4337,
    "FrequencyRank": 4184
  },
  {
    "Character": "峥",
    "Pinyin": "zhēng",
    "Definition": "high, lofty, noble; steep, perilous",
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4237,
    "FrequencyRank": 4958
  },
  {
    "Character": "姱",
    "Pinyin": "kuā",
    "Definition": "beautiful, handsome, elegant",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6912,
    "FrequencyRank": undefined
  },
  {
    "Character": "疢",
    "Pinyin": "chèn",
    "Definition": "aching of limbs, muscular pains",
    "Radical": "疒",
    "RadicalIndex": "104.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6876,
    "FrequencyRank": undefined
  },
  {
    "Character": "哐",
    "Pinyin": "kuāng",
    "Definition": "syllable",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4187,
    "FrequencyRank": 5013
  },
  {
    "Character": "籼",
    "Pinyin": "xiān",
    "Definition": "non-glutinous long grain rice",
    "Radical": "米",
    "RadicalIndex": "119.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4322,
    "FrequencyRank": 5925
  },
  {
    "Character": "烀",
    "Pinyin": "hū",
    "Definition": "simmer",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4328,
    "FrequencyRank": 6398
  },
  {
    "Character": "狩",
    "Pinyin": "shòu",
    "Definition": "winter hunting; imperial tour",
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4294,
    "FrequencyRank": 3190
  },
  {
    "Character": "珅",
    "Pinyin": "shēn",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6760,
    "FrequencyRank": undefined
  },
  {
    "Character": "昽",
    "Pinyin": "lóng",
    "Definition": "vague, dim; twilight",
    "Radical": "日",
    "RadicalIndex": "72.16",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6821,
    "FrequencyRank": undefined
  },
  {
    "Character": "茬",
    "Pinyin": "chá",
    "Definition": "harvest",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1404,
    "FrequencyRank": 3790
  },
  {
    "Character": "姶",
    "Pinyin": "è",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6914,
    "FrequencyRank": undefined
  },
  {
    "Character": "胪",
    "Pinyin": "lú",
    "Definition": "arrange in order; display",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4280,
    "FrequencyRank": 4734
  },
  {
    "Character": "柯",
    "Pinyin": "kē",
    "Definition": "axe-handle; stalk, bough; surname",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4141,
    "FrequencyRank": 1903
  },
  {
    "Character": "陧",
    "Pinyin": "niè",
    "Definition": "in disorder; a dangerous condition of the State",
    "Radical": "阜",
    "RadicalIndex": "170.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6908,
    "FrequencyRank": 7158
  },
  {
    "Character": "柠",
    "Pinyin": "níng",
    "Definition": "lemon",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1438,
    "FrequencyRank": 4252
  },
  {
    "Character": "柑",
    "Pinyin": "gān",
    "Definition": "tangerine, loose-skinned orange",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1426,
    "FrequencyRank": 4276
  },
  {
    "Character": "扃",
    "Pinyin": "jiōng",
    "Definition": "a door bar placed outside a door",
    "Radical": "戶",
    "RadicalIndex": "63.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4359,
    "FrequencyRank": 6457
  },
  {
    "Character": "㶲",
    "Pinyin": "yòng",
    "Definition": "measuring unit for thermodynamics",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6879,
    "FrequencyRank": undefined
  },
  {
    "Character": "祓",
    "Pinyin": "fú",
    "Definition": "exorcise, remove evil; clean",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4366,
    "FrequencyRank": 6423
  },
  {
    "Character": "狰",
    "Pinyin": "zhēng",
    "Definition": "fierce-looking, ferocious",
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1603,
    "FrequencyRank": 4047
  },
  {
    "Character": "骃",
    "Pinyin": "yīn",
    "Definition": "grey horse",
    "Radical": "馬",
    "RadicalIndex": "187.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6918,
    "FrequencyRank": 6951
  },
  {
    "Character": "柚",
    "Pinyin": "yòu",
    "Definition": "pomelo, grapefruit",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4149,
    "FrequencyRank": 4553
  },
  {
    "Character": "曷",
    "Pinyin": "hé",
    "Definition": "why? what? where?",
    "Radical": "曰",
    "RadicalIndex": "73.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4198,
    "FrequencyRank": 5256
  },
  {
    "Character": "胩",
    "Pinyin": "kǎ",
    "Definition": "an organic compound",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6869,
    "FrequencyRank": 6106
  },
  {
    "Character": "殇",
    "Pinyin": "shāng",
    "Definition": "die young; national mourning",
    "Radical": "歹",
    "RadicalIndex": "78.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4175,
    "FrequencyRank": 5198
  },
  {
    "Character": "骈",
    "Pinyin": "pián",
    "Definition": "team of horses; associate, join",
    "Radical": "馬",
    "RadicalIndex": "187.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4398,
    "FrequencyRank": 4562
  },
  {
    "Character": "瓴",
    "Pinyin": "líng",
    "Definition": "concave channels of tiling a long-necked jar",
    "Radical": "瓦",
    "RadicalIndex": "98.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4278,
    "FrequencyRank": 5714
  },
  {
    "Character": "疣",
    "Pinyin": "yóu",
    "Definition": "wart; tumor; goiter; papule",
    "Radical": "疒",
    "RadicalIndex": "104.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4309,
    "FrequencyRank": 3776
  },
  {
    "Character": "耔",
    "Pinyin": "zǐ",
    "Definition": "to hoe up the earth around plants",
    "Radical": "耒",
    "RadicalIndex": "127.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6756,
    "FrequencyRank": undefined
  },
  {
    "Character": "䢼",
    "Pinyin": "gōng",
    "Definition": "name of a state in old times, name of a pavilion",
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6657,
    "FrequencyRank": undefined
  },
  {
    "Character": "柒",
    "Pinyin": "qī",
    "Definition": "number seven",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1666,
    "FrequencyRank": 6786
  },
  {
    "Character": "栎",
    "Pinyin": "lì",
    "Definition": "chestnut-leaved oak; oak",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4155,
    "FrequencyRank": 4721
  },
  {
    "Character": "宥",
    "Pinyin": "yòu",
    "Definition": "forgive, pardon, indulge",
    "Radical": "宀",
    "RadicalIndex": "40.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4358,
    "FrequencyRank": 4707
  },
  {
    "Character": "峤",
    "Pinyin": "jiào",
    "Definition": "high pointed mountain",
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4235,
    "FrequencyRank": 5203
  },
  {
    "Character": "洨",
    "Pinyin": "xiáo",
    "Definition": "river in Hebei province",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6894,
    "FrequencyRank": undefined
  },
  {
    "Character": "娅",
    "Pinyin": "yà",
    "Definition": "mutual term of address used by sons-in-law; a brother-in-law",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4382,
    "FrequencyRank": 2877
  },
  {
    "Character": "酊",
    "Pinyin": "dīng",
    "Definition": "drunk, intoxicated",
    "Radical": "酉",
    "RadicalIndex": "164.2",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4162,
    "FrequencyRank": 4791
  },
  {
    "Character": "恫",
    "Pinyin": "dòng",
    "Definition": "in pain, sorrowful",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4352,
    "FrequencyRank": 4095
  },
  {
    "Character": "浍",
    "Pinyin": "huì",
    "Definition": "irrigation ditch, trench; river",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4342,
    "FrequencyRank": 6543
  },
  {
    "Character": "祕",
    "Pinyin": "mì",
    "Definition": "mysterious, secret, abstruse",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6906,
    "FrequencyRank": 5927
  },
  {
    "Character": "骉",
    "Pinyin": "biāo",
    "Definition": "running; herd of horses",
    "Radical": "馬",
    "RadicalIndex": "187.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6924,
    "FrequencyRank": undefined
  },
  {
    "Character": "胈",
    "Pinyin": "bá",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6868,
    "FrequencyRank": undefined
  },
  {
    "Character": "枳",
    "Pinyin": "zhǐ",
    "Definition": "trifoliate orange; hedge thorn; (Cant.) a plug",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4150,
    "FrequencyRank": 5304
  },
  {
    "Character": "宬",
    "Pinyin": "chéng",
    "Definition": "archives; surname",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6900,
    "FrequencyRank": undefined
  },
  {
    "Character": "祚",
    "Pinyin": "zuò",
    "Definition": "throne; bless; blessing, happiness",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4367,
    "FrequencyRank": 4799
  },
  {
    "Character": "炳",
    "Pinyin": "bǐng",
    "Definition": "bright, luminous; glorious",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4324,
    "FrequencyRank": 3227
  },
  {
    "Character": "哚",
    "Pinyin": "duǒ",
    "Definition": "chemical element; (Cant.) backing, support of someone powerful; a little",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4223,
    "FrequencyRank": 6558
  },
  {
    "Character": "拶",
    "Pinyin": "zā",
    "Definition": "press, squeeze hard; force",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6782,
    "FrequencyRank": 6608
  },
  {
    "Character": "俞",
    "Pinyin": "yú",
    "Definition": "surname; consent, approve",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4272,
    "FrequencyRank": 3251
  },
  {
    "Character": "垣",
    "Pinyin": "yuán",
    "Definition": "low wall",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4096,
    "FrequencyRank": 2948
  },
  {
    "Character": "哉",
    "Pinyin": "zāi",
    "Definition": "final exclamatory particle",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1381,
    "FrequencyRank": 2758
  },
  {
    "Character": "牁",
    "Pinyin": "kē",
    "Definition": "mooring stake; painter, mooring",
    "Radical": "爿",
    "RadicalIndex": "90.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4378,
    "FrequencyRank": undefined
  },
  {
    "Character": "垩",
    "Pinyin": "è",
    "Definition": "holy, sacred, sage; simplification of 堊 chalk; daub with chalk",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4129,
    "FrequencyRank": 4608
  },
  {
    "Character": "荨",
    "Pinyin": "xún",
    "Definition": "nettle",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4132,
    "FrequencyRank": 4259
  },
  {
    "Character": "娄",
    "Pinyin": "lóu",
    "Definition": "surname; a constellation; to wear",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1649,
    "FrequencyRank": 4052
  },
  {
    "Character": "荛",
    "Pinyin": "ráo",
    "Definition": "fuel, firewood; stubble; Wikstroemia japonica",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6786,
    "FrequencyRank": 5272
  },
  {
    "Character": "炽",
    "Pinyin": "chì",
    "Definition": "burning-hot, intense; to burn, blaze; splendid, illustrious",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4326,
    "FrequencyRank": 3277
  },
  {
    "Character": "斫",
    "Pinyin": "zhuó",
    "Definition": "cut, chop, lop off",
    "Radical": "斤",
    "RadicalIndex": "69.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4168,
    "FrequencyRank": 4677
  },
  {
    "Character": "茺",
    "Pinyin": "chōng",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6790,
    "FrequencyRank": 7250
  },
  {
    "Character": "砄",
    "Pinyin": "jué",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6811,
    "FrequencyRank": undefined
  },
  {
    "Character": "轶",
    "Pinyin": "yì",
    "Definition": "rush forth; surpass, excel",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4181,
    "FrequencyRank": 4046
  },
  {
    "Character": "郤",
    "Pinyin": "xì",
    "Definition": "crack, opening; surname",
    "Radical": "邑",
    "RadicalIndex": "163.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4275,
    "FrequencyRank": 6656
  },
  {
    "Character": "恃",
    "Pinyin": "shì",
    "Definition": "rely on, presume on, trust to",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1684,
    "FrequencyRank": 3219
  },
  {
    "Character": "祗",
    "Pinyin": "zhī",
    "Definition": "respect, look up to, revere",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4369,
    "FrequencyRank": 4203
  },
  {
    "Character": "咿",
    "Pinyin": "yī",
    "Definition": "descriptive of creaking; laugh",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4220,
    "FrequencyRank": 4740
  },
  {
    "Character": "胫",
    "Pinyin": "jìng",
    "Definition": "shinbone; calf of leg",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4288,
    "FrequencyRank": 4209
  },
  {
    "Character": "弭",
    "Pinyin": "mǐ",
    "Definition": "stop, desist, end, quell",
    "Radical": "弓",
    "RadicalIndex": "57.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4377,
    "FrequencyRank": 4871
  },
  {
    "Character": "垚",
    "Pinyin": "yáo",
    "Definition": "mound, roundish mass",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6767,
    "FrequencyRank": undefined
  },
  {
    "Character": "荇",
    "Pinyin": "xìng",
    "Definition": "a water plant, Nymphoides peltalum",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4121,
    "FrequencyRank": 6456
  },
  {
    "Character": "姘",
    "Pinyin": "pīn",
    "Definition": "illicit sexual relations",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4387,
    "FrequencyRank": 4942
  },
  {
    "Character": "浒",
    "Pinyin": "hǔ",
    "Definition": "riverbank, shore",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4345,
    "FrequencyRank": 3794
  },
  {
    "Character": "胤",
    "Pinyin": "yìn",
    "Definition": "heir, successor; progeny, posterity",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4302,
    "FrequencyRank": 3982
  },
  {
    "Character": "柖",
    "Pinyin": "sháo",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6803,
    "FrequencyRank": undefined
  },
  {
    "Character": "昫",
    "Pinyin": "xù",
    "Definition": "warm",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6829,
    "FrequencyRank": undefined
  },
  {
    "Character": "袄",
    "Pinyin": "ǎo",
    "Definition": "outer garments; coat, jacket",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1709,
    "FrequencyRank": 3480
  },
  {
    "Character": "胂",
    "Pinyin": "shèn",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4282,
    "FrequencyRank": 5607
  },
  {
    "Character": "枲",
    "Pinyin": "xǐ",
    "Definition": "male nettle-hemp",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6916,
    "FrequencyRank": undefined
  },
  {
    "Character": "诲",
    "Pinyin": "huì",
    "Definition": "teach, instruct; encourage, urge",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1716,
    "FrequencyRank": 3510
  },
  {
    "Character": "钠",
    "Pinyin": "nà",
    "Definition": "sodium, natrium; sharpen wood",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1530,
    "FrequencyRank": 3479
  },
  {
    "Character": "荓",
    "Pinyin": "píng",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6792,
    "FrequencyRank": undefined
  },
  {
    "Character": "姤",
    "Pinyin": "gòu",
    "Definition": "mate; copulate; good",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6913,
    "FrequencyRank": undefined
  },
  {
    "Character": "胄",
    "Pinyin": "zhòu",
    "Definition": "helmet, headpiece; descendant",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4208,
    "FrequencyRank": 4268
  },
  {
    "Character": "洈",
    "Pinyin": "wéi",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6891,
    "FrequencyRank": undefined
  },
  {
    "Character": "玳",
    "Pinyin": "dài",
    "Definition": "tortoise shell",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4088,
    "FrequencyRank": 5464
  },
  {
    "Character": "㳘",
    "Pinyin": "chōng",
    "Definition": "a fountain or spring flows downwards, sound of the flowing water",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6896,
    "FrequencyRank": undefined
  },
  {
    "Character": "涎",
    "Pinyin": "xián",
    "Definition": "saliva",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4339,
    "FrequencyRank": 3771
  },
  {
    "Character": "徉",
    "Pinyin": "yáng",
    "Definition": "wonder, rove, stray; hesitating",
    "Radical": "彳",
    "RadicalIndex": "60.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4270,
    "FrequencyRank": 5495
  },
  {
    "Character": "恬",
    "Pinyin": "tián",
    "Definition": "quiet, calm, tranquil, peaceful",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1688,
    "FrequencyRank": 3717
  },
  {
    "Character": "洌",
    "Pinyin": "liè",
    "Definition": "clear",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4334,
    "FrequencyRank": 4338
  },
  {
    "Character": "咣",
    "Pinyin": "guāng",
    "Definition": "the sound of large door closing",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4214,
    "FrequencyRank": 4546
  },
  {
    "Character": "珀",
    "Pinyin": "pò",
    "Definition": "amber",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4089,
    "FrequencyRank": 2981
  },
  {
    "Character": "洣",
    "Pinyin": "mǐ",
    "Definition": "Mi river in Hunan, tributary of Xiangjiang",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6898,
    "FrequencyRank": undefined
  },
  {
    "Character": "绚",
    "Pinyin": "xuàn",
    "Definition": "variegated, adorned; brilliant",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1759,
    "FrequencyRank": 3711
  },
  {
    "Character": "哓",
    "Pinyin": "xiāo",
    "Definition": "garrulous; disturbed; restless",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4203,
    "FrequencyRank": 6216
  },
  {
    "Character": "姹",
    "Pinyin": "chà",
    "Definition": "beautiful, colorful; girl",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4388,
    "FrequencyRank": 5276
  },
  {
    "Character": "囿",
    "Pinyin": "yòu",
    "Definition": "pen up; limit, constrain",
    "Radical": "囗",
    "RadicalIndex": "31.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4219,
    "FrequencyRank": 4603
  },
  {
    "Character": "峦",
    "Pinyin": "luán",
    "Definition": "mountain range; pointed mountain",
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1615,
    "FrequencyRank": 3789
  },
  {
    "Character": "茭",
    "Pinyin": "jiāo",
    "Definition": "an aquatic grass, the stalks of which are eaten as a vegetable",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4127,
    "FrequencyRank": 6064
  },
  {
    "Character": "荀",
    "Pinyin": "xún",
    "Definition": "surname; ancient state; plant",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4124,
    "FrequencyRank": 3690
  },
  {
    "Character": "耷",
    "Pinyin": "dā",
    "Definition": "ears hanging down",
    "Radical": "耳",
    "RadicalIndex": "128.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4172,
    "FrequencyRank": 4120
  },
  {
    "Character": "顸",
    "Pinyin": "hān",
    "Definition": "large face, flat face; stupid",
    "Radical": "干",
    "RadicalIndex": "51.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4090,
    "FrequencyRank": 6214
  },
  {
    "Character": "俣",
    "Pinyin": "yǔ",
    "Definition": "big",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4263,
    "FrequencyRank": 4919
  },
  {
    "Character": "洙",
    "Pinyin": "zhū",
    "Definition": "name of a river in Shandong",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4338,
    "FrequencyRank": 5496
  },
  {
    "Character": "荄",
    "Pinyin": "gāi",
    "Definition": "roots",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6789,
    "FrequencyRank": 7739
  },
  {
    "Character": "㳚",
    "Pinyin": "xù",
    "Definition": "flowing of the water",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6884,
    "FrequencyRank": undefined
  },
  {
    "Character": "骆",
    "Pinyin": "luò",
    "Definition": "a white horse with black mane; a camel",
    "Radical": "馬",
    "RadicalIndex": "187.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1760,
    "FrequencyRank": 2856
  },
  {
    "Character": "虺",
    "Pinyin": "huī",
    "Definition": "large poisonous snake",
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4173,
    "FrequencyRank": 5543
  },
  {
    "Character": "祏",
    "Pinyin": "shí",
    "Definition": "shrine",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6904,
    "FrequencyRank": 6380
  },
  {
    "Character": "荜",
    "Pinyin": "bì",
    "Definition": "species of bean; piper longtum",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4112,
    "FrequencyRank": 6477
  },
  {
    "Character": "枰",
    "Pinyin": "píng",
    "Definition": "smooth board; chessboard; chess",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4145,
    "FrequencyRank": 5099
  },
  {
    "Character": "胛",
    "Pinyin": "jiǎ",
    "Definition": "the shoulder, shoulder blade",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4281,
    "FrequencyRank": 4715
  },
  {
    "Character": "虿",
    "Pinyin": "chài",
    "Definition": "a kind of scorpion",
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4183,
    "FrequencyRank": 5554
  },
  {
    "Character": "眇",
    "Pinyin": "miǎo",
    "Definition": "blind in one eye; minute, minuscule",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4192,
    "FrequencyRank": 5993
  },
  {
    "Character": "栀",
    "Pinyin": "zhī",
    "Definition": "gardenia",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4153,
    "FrequencyRank": 5421
  },
  {
    "Character": "垏",
    "Pinyin": "lǜ",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6781,
    "FrequencyRank": undefined
  },
  {
    "Character": "虼",
    "Pinyin": "gè",
    "Definition": "a flea; species of beetle",
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4211,
    "FrequencyRank": 5063
  },
  {
    "Character": "垲",
    "Pinyin": "kǎi",
    "Definition": "high and dry place",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6770,
    "FrequencyRank": 9025
  },
  {
    "Character": "浃",
    "Pinyin": "jiā",
    "Definition": "saturate, drench; damp, wet",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4335,
    "FrequencyRank": 4960
  },
  {
    "Character": "荖",
    "Pinyin": "lǎo",
    "Definition": "betel pepper",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6783,
    "FrequencyRank": 8291
  },
  {
    "Character": "挝",
    "Pinyin": "wō",
    "Definition": "to beat; to strike",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4095,
    "FrequencyRank": 3710
  },
  {
    "Character": "茛",
    "Pinyin": "gèn",
    "Definition": "ranunculus",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6795,
    "FrequencyRank": 6443
  },
  {
    "Character": "炟",
    "Pinyin": "dá",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6878,
    "FrequencyRank": undefined
  },
  {
    "Character": "毖",
    "Pinyin": "bì",
    "Definition": "guard against, take care; caution",
    "Radical": "比",
    "RadicalIndex": "81.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4184,
    "FrequencyRank": 5054
  },
  {
    "Character": "𫓧",
    "Pinyin": "fū",
    "Definition": "an ax, hatchet",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6844,
    "FrequencyRank": undefined
  },
  {
    "Character": "帧",
    "Pinyin": "zhèng",
    "Definition": "picture, scroll; one of pair of",
    "Radical": "巾",
    "RadicalIndex": "50.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4233,
    "FrequencyRank": 3563
  },
  {
    "Character": "钚",
    "Pinyin": "bù",
    "Definition": "plutonium",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4239,
    "FrequencyRank": 4311
  },
  {
    "Character": "荭",
    "Pinyin": "hóng",
    "Definition": "herb",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6796,
    "FrequencyRank": 4500
  },
  {
    "Character": "垤",
    "Pinyin": "dié",
    "Definition": "ant-hill, small mound; hill",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4098,
    "FrequencyRank": 6898
  },
  {
    "Character": "㛃",
    "Pinyin": "jié",
    "Definition": "clean; pure; clear, usually used for names",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6757,
    "FrequencyRank": undefined
  },
  {
    "Character": "狯",
    "Pinyin": "kuài",
    "Definition": "sly, cunning, crafty",
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4292,
    "FrequencyRank": 5782
  },
  {
    "Character": "垛",
    "Pinyin": "duǒ",
    "Definition": "heap, pile; pile up, heap up",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1388,
    "FrequencyRank": 4009
  },
  {
    "Character": "垢",
    "Pinyin": "gòu",
    "Definition": "dirt, filth, stains; dirty",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1384,
    "FrequencyRank": 3284
  },
  {
    "Character": "俍",
    "Pinyin": "liáng",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6858,
    "FrequencyRank": undefined
  },
  {
    "Character": "洇",
    "Pinyin": "yīn",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4336,
    "FrequencyRank": 6225
  },
  {
    "Character": "泵",
    "Pinyin": "bèng",
    "Definition": "pump",
    "Radical": "水",
    "RadicalIndex": "85.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1452,
    "FrequencyRank": 3800
  },
  {
    "Character": "娀",
    "Pinyin": "sōng",
    "Definition": "name of a concubine of Di Ku, father of the mythical Yao",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6910,
    "FrequencyRank": undefined
  },
  {
    "Character": "叟",
    "Pinyin": "sǒu",
    "Definition": "old man; elder",
    "Radical": "又",
    "RadicalIndex": "29.8",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4260,
    "FrequencyRank": 3804
  },
  {
    "Character": "氡",
    "Pinyin": "dōng",
    "Definition": "radon",
    "Radical": "气",
    "RadicalIndex": "84.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4247,
    "FrequencyRank": 5497
  },
  {
    "Character": "窀",
    "Pinyin": "zhūn",
    "Definition": "to bury",
    "Radical": "穴",
    "RadicalIndex": "116.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6901,
    "FrequencyRank": 5718
  },
  {
    "Character": "觇",
    "Pinyin": "chān",
    "Definition": "peek; spy on, watch; investigate",
    "Radical": "見",
    "RadicalIndex": "147.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4185,
    "FrequencyRank": 6409
  },
  {
    "Character": "茗",
    "Pinyin": "míng",
    "Definition": "tea; tea plant",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4125,
    "FrequencyRank": 4030
  },
  {
    "Character": "剌",
    "Pinyin": "lá",
    "Definition": "slash, cut in two; contradict",
    "Radical": "刀",
    "RadicalIndex": "18.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4161,
    "FrequencyRank": 3577
  },
  {
    "Character": "昪",
    "Pinyin": "biàn",
    "Definition": "delighted; pleased",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6832,
    "FrequencyRank": undefined
  },
  {
    "Character": "殂",
    "Pinyin": "cú",
    "Definition": "to die",
    "Radical": "歹",
    "RadicalIndex": "78.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4174,
    "FrequencyRank": 6734
  },
  {
    "Character": "荩",
    "Pinyin": "jìn",
    "Definition": "a kind of weed; faithfulness",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4133,
    "FrequencyRank": 4408
  },
  {
    "Character": "洮",
    "Pinyin": "táo",
    "Definition": "cleanse; river in Gansu province",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4343,
    "FrequencyRank": 5637
  },
  {
    "Character": "剋",
    "Pinyin": "kè",
    "Definition": "subdue, overcome; cut down",
    "Radical": "刀",
    "RadicalIndex": "18.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4134,
    "FrequencyRank": undefined
  },
  {
    "Character": "朐",
    "Pinyin": "qú",
    "Definition": "warm",
    "Radical": "月",
    "RadicalIndex": "74.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4287,
    "FrequencyRank": 5482
  },
  {
    "Character": "祠",
    "Pinyin": "cí",
    "Definition": "ancestral temple; offer sacrifice",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1713,
    "FrequencyRank": 3319
  },
  {
    "Character": "徇",
    "Pinyin": "xùn",
    "Definition": "comply with, follow; display",
    "Radical": "彳",
    "RadicalIndex": "60.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4269,
    "FrequencyRank": 4085
  },
  {
    "Character": "逅",
    "Pinyin": "hòu",
    "Definition": "meet unexpectedly",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4268,
    "FrequencyRank": 4468
  },
  {
    "Character": "胨",
    "Pinyin": "dòng",
    "Definition": "a kind of protein",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4279,
    "FrequencyRank": 5397
  },
  {
    "Character": "俑",
    "Pinyin": "yǒng",
    "Definition": "wooden figure buried with dead",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4266,
    "FrequencyRank": 4381
  },
  {
    "Character": "哃",
    "Pinyin": "tóng",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6835,
    "FrequencyRank": undefined
  },
  {
    "Character": "莛",
    "Pinyin": "tíng",
    "Definition": "stalks of grasses, etc.",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4117,
    "FrequencyRank": 7235
  },
  {
    "Character": "陞",
    "Pinyin": "shēng",
    "Definition": "promote, rise, ascend",
    "Radical": "阜",
    "RadicalIndex": "170.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6909,
    "FrequencyRank": 7554
  },
  {
    "Character": "狨",
    "Pinyin": "róng",
    "Definition": undefined,
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4291,
    "FrequencyRank": 9822
  },
  {
    "Character": "茳",
    "Pinyin": "jiāng",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6793,
    "FrequencyRank": undefined
  },
  {
    "Character": "耑",
    "Pinyin": "duān",
    "Definition": "specialized; concentrated",
    "Radical": "而",
    "RadicalIndex": "126.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6837,
    "FrequencyRank": 9047
  },
  {
    "Character": "荔",
    "Pinyin": "lì",
    "Definition": "lichee",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1421,
    "FrequencyRank": 3848
  },
  {
    "Character": "珂",
    "Pinyin": "kē",
    "Definition": "inferior kind of jade",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4086,
    "FrequencyRank": 3622
  },
  {
    "Character": "咯",
    "Pinyin": "gē",
    "Definition": "final particle",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4224,
    "FrequencyRank": 2744
  },
  {
    "Character": "剅",
    "Pinyin": "lóu",
    "Definition": undefined,
    "Radical": "刀",
    "RadicalIndex": "18.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6805,
    "FrequencyRank": undefined
  },
  {
    "Character": "砉",
    "Pinyin": "huò",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6755,
    "FrequencyRank": 5216
  },
  {
    "Character": "秬",
    "Pinyin": "jù",
    "Definition": "black millet",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6853,
    "FrequencyRank": 7426
  },
  {
    "Character": "𦭜",
    "Pinyin": "zhī",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6658,
    "FrequencyRank": undefined
  },
  {
    "Character": "韭",
    "Pinyin": "jiǔ",
    "Definition": "scallion, leek; radical 179",
    "Radical": "韭",
    "RadicalIndex": "179",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1466,
    "FrequencyRank": 4227
  },
  {
    "Character": "胥",
    "Pinyin": "xū",
    "Definition": "all, together, mutually",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4379,
    "FrequencyRank": 3611
  },
  {
    "Character": "恔",
    "Pinyin": "jiǎo",
    "Definition": "cheerful; bright, sagacious",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6899,
    "FrequencyRank": undefined
  },
  {
    "Character": "洛",
    "Pinyin": "luò",
    "Definition": "river in Shanxi province; city",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1676,
    "FrequencyRank": 978
  },
  {
    "Character": "垠",
    "Pinyin": "yín",
    "Definition": "boundary, bank of stream or river",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4107,
    "FrequencyRank": 4331
  },
  {
    "Character": "赳",
    "Pinyin": "jiū",
    "Definition": "grand, valiant",
    "Radical": "走",
    "RadicalIndex": "156.2",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4099,
    "FrequencyRank": 4530
  },
  {
    "Character": "弇",
    "Pinyin": "yǎn",
    "Definition": "cover over, hide; narrow-necked",
    "Radical": "廾",
    "RadicalIndex": "55.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6862,
    "FrequencyRank": undefined
  },
  {
    "Character": "爰",
    "Pinyin": "yuán",
    "Definition": "lead on to; therefore, then",
    "Radical": "爪",
    "RadicalIndex": "87.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4276,
    "FrequencyRank": 4896
  },
  {
    "Character": "袂",
    "Pinyin": "mèi",
    "Definition": "sleeves",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4363,
    "FrequencyRank": 4479
  },
  {
    "Character": "䏡",
    "Pinyin": "shì",
    "Definition": "uncooked or raw meat, an organic compound; Proteose",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6865,
    "FrequencyRank": undefined
  },
  {
    "Character": "恓",
    "Pinyin": "xī",
    "Definition": "vexed",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4350,
    "FrequencyRank": undefined
  },
  {
    "Character": "胠",
    "Pinyin": "qū",
    "Definition": "open; throw away",
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6866,
    "FrequencyRank": 6997
  },
  {
    "Character": "泚",
    "Pinyin": "cǐ",
    "Definition": "clear; bright and brilliant; sweat",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6885,
    "FrequencyRank": undefined
  },
  {
    "Character": "俪",
    "Pinyin": "lì",
    "Definition": "spouse, couple, pair",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4259,
    "FrequencyRank": 4648
  },
  {
    "Character": "荞",
    "Pinyin": "qiáo",
    "Definition": "buckwheat",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4118,
    "FrequencyRank": 5033
  },
  {
    "Character": "狲",
    "Pinyin": "sūn",
    "Definition": "monkey",
    "Radical": "犬",
    "RadicalIndex": "94.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4295,
    "FrequencyRank": 5797
  },
  {
    "Character": "俚",
    "Pinyin": "lǐ",
    "Definition": "rustic, vulgar, unpolished; mean",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4264,
    "FrequencyRank": 4358
  },
  {
    "Character": "恂",
    "Pinyin": "xún",
    "Definition": "careful, sincere, honest; trust",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4355,
    "FrequencyRank": 5117
  },
  {
    "Character": "拷",
    "Pinyin": "kǎo",
    "Definition": "torture and interrogate; hit",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1368,
    "FrequencyRank": 3230
  },
  {
    "Character": "轲",
    "Pinyin": "kē",
    "Definition": "axle; personal name of mencius",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4179,
    "FrequencyRank": 3853
  },
  {
    "Character": "畎",
    "Pinyin": "quǎn",
    "Definition": "a drain between fields, irrigation; to flow",
    "Radical": "田",
    "RadicalIndex": "102.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4205,
    "FrequencyRank": 6342
  },
  {
    "Character": "珌",
    "Pinyin": "bì",
    "Definition": "ornament",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6764,
    "FrequencyRank": undefined
  },
  {
    "Character": "荠",
    "Pinyin": "jì",
    "Definition": "water-chestnuts; caltrop",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4126,
    "FrequencyRank": 5681
  },
  {
    "Character": "哟",
    "Pinyin": "yō",
    "Definition": "ah, final particle",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1516,
    "FrequencyRank": 2524
  },
  {
    "Character": "昳",
    "Pinyin": "dié",
    "Definition": "the declining sun in the west",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6825,
    "FrequencyRank": undefined
  },
  {
    "Character": "垡",
    "Pinyin": "fá",
    "Definition": "plow soil; place name",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4261,
    "FrequencyRank": 5671
  },
  {
    "Character": "俎",
    "Pinyin": "zǔ",
    "Definition": "chopping board or block; painted",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4274,
    "FrequencyRank": 4909
  },
  {
    "Character": "尜",
    "Pinyin": "gá",
    "Definition": "a child toy",
    "Radical": "小",
    "RadicalIndex": "42.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4186,
    "FrequencyRank": 9006
  },
  {
    "Character": "祜",
    "Pinyin": "hù",
    "Definition": "blessing, happiness, prosperity",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4365,
    "FrequencyRank": 4988
  },
  {
    "Character": "饷",
    "Pinyin": "xiǎng",
    "Definition": "rations and pay for soldiers",
    "Radical": "食",
    "RadicalIndex": "184.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4299,
    "FrequencyRank": 3103
  },
  {
    "Character": "鸧",
    "Pinyin": "cāng",
    "Definition": "oriole",
    "Radical": "鳥",
    "RadicalIndex": "196.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6864,
    "FrequencyRank": 9205
  },
  {
    "Character": "盅",
    "Pinyin": "zhōng",
    "Definition": "small cup or bowl",
    "Radical": "皿",
    "RadicalIndex": "108.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4213,
    "FrequencyRank": 3998
  },
  {
    "Character": "砚",
    "Pinyin": "yàn",
    "Definition": "inkstone",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1453,
    "FrequencyRank": 4121
  },
  {
    "Character": "茱",
    "Pinyin": "zhū",
    "Definition": "dogwood",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4116,
    "FrequencyRank": 5478
  },
  {
    "Character": "钬",
    "Pinyin": "huǒ",
    "Definition": "holmium",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6850,
    "FrequencyRank": 6713
  },
  {
    "Character": "栊",
    "Pinyin": "lóng",
    "Definition": "cage, pen; set of bars",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4143,
    "FrequencyRank": 6357
  },
  {
    "Character": "俦",
    "Pinyin": "chóu",
    "Definition": "companion, mate, colleague",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4256,
    "FrequencyRank": 4502
  },
  {
    "Character": "眍",
    "Pinyin": "kōu",
    "Definition": "sunken",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4189,
    "FrequencyRank": 6818
  },
  {
    "Character": "娈",
    "Pinyin": "luán",
    "Definition": "lovely, beautiful; docile, obedie",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4304,
    "FrequencyRank": 6289
  },
  {
    "Character": "茴",
    "Pinyin": "huí",
    "Definition": "fennel, aniseed",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4115,
    "FrequencyRank": 5388
  },
  {
    "Character": "禺",
    "Pinyin": "yú",
    "Definition": "district; mountain in Zhejiang",
    "Radical": "禸",
    "RadicalIndex": "114.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4195,
    "FrequencyRank": 3056
  },
  {
    "Character": "姞",
    "Pinyin": "jí",
    "Definition": "concubine",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6911,
    "FrequencyRank": undefined
  },
  {
    "Character": "珑",
    "Pinyin": "lóng",
    "Definition": "a gem cut like dragon",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4087,
    "FrequencyRank": 3797
  },
  {
    "Character": "昤",
    "Pinyin": "líng",
    "Definition": "sunshine",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6828,
    "FrequencyRank": undefined
  },
  {
    "Character": "钯",
    "Pinyin": "bǎ",
    "Definition": "palladium",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4246,
    "FrequencyRank": 5949
  },
  {
    "Character": "茧",
    "Pinyin": "jiǎn",
    "Definition": "cocoon; callus, blister",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1409,
    "FrequencyRank": 3608
  },
  {
    "Character": "飑",
    "Pinyin": "biāo",
    "Definition": "storm",
    "Radical": "風",
    "RadicalIndex": "182.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4293,
    "FrequencyRank": 7109
  },
  {
    "Character": "柷",
    "Pinyin": "chù",
    "Definition": "instrument",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6798,
    "FrequencyRank": undefined
  },
  {
    "Character": "彦",
    "Pinyin": "yàn",
    "Definition": "elegant",
    "Radical": "彡",
    "RadicalIndex": "59.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4314,
    "FrequencyRank": 2559
  },
  {
    "Character": "祛",
    "Pinyin": "qū",
    "Definition": "expel, disperse, exorcise",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4364,
    "FrequencyRank": 4176
  },
  {
    "Character": "洑",
    "Pinyin": "fú",
    "Definition": "whirlpool, undercurrent",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6889,
    "FrequencyRank": undefined
  },
  {
    "Character": "陟",
    "Pinyin": "zhì",
    "Definition": "climb, scale, ascend; proceed",
    "Radical": "阜",
    "RadicalIndex": "170.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4381,
    "FrequencyRank": 5764
  },
  {
    "Character": "炣",
    "Pinyin": "kě",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6877,
    "FrequencyRank": undefined
  },
  {
    "Character": "畋",
    "Pinyin": "tián",
    "Definition": "till land, cultivate; hunt",
    "Radical": "田",
    "RadicalIndex": "102.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4209,
    "FrequencyRank": 6067
  },
  {
    "Character": "茽",
    "Pinyin": "zhòng",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6788,
    "FrequencyRank": 8626
  },
  {
    "Character": "洼",
    "Pinyin": "wā",
    "Definition": "hollow; pit; depression; swamp",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1662,
    "FrequencyRank": 3226
  },
  {
    "Character": "罘",
    "Pinyin": "fú",
    "Definition": "screen used in ancient times",
    "Radical": "网",
    "RadicalIndex": "122.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4232,
    "FrequencyRank": 5614
  },
  {
    "Character": "柢",
    "Pinyin": "dǐ",
    "Definition": "root, base; bottom of object",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4154,
    "FrequencyRank": 5362
  },
  {
    "Character": "砒",
    "Pinyin": "pī",
    "Definition": "arsenic",
    "Radical": "石",
    "RadicalIndex": "112.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4167,
    "FrequencyRank": 4949
  },
  {
    "Character": "矜",
    "Pinyin": "jīn",
    "Definition": "pity, feel sorry for, show sympat",
    "Radical": "矛",
    "RadicalIndex": "110.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4392,
    "FrequencyRank": 3661
  },
  {
    "Character": "疬",
    "Pinyin": "lì",
    "Definition": "scrofulous lumps or swellings",
    "Radical": "疒",
    "RadicalIndex": "104.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4308,
    "FrequencyRank": 6585
  },
  {
    "Character": "侯",
    "Pinyin": "hóu",
    "Definition": "marquis, lord; target in archery",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1576,
    "FrequencyRank": 1756
  },
  {
    "Character": "舣",
    "Pinyin": "yǐ",
    "Definition": "to moor a boat to the bank",
    "Radical": "舟",
    "RadicalIndex": "137.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6861,
    "FrequencyRank": 8377
  },
  {
    "Character": "矧",
    "Pinyin": "shěn",
    "Definition": "much more, still more; the gums",
    "Radical": "矢",
    "RadicalIndex": "111.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6852,
    "FrequencyRank": 6671
  },
  {
    "Character": "垍",
    "Pinyin": "jì",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6772,
    "FrequencyRank": undefined
  },
  {
    "Character": "叚",
    "Pinyin": "jiǎ",
    "Definition": "FALSE",
    "Radical": "又",
    "RadicalIndex": "29.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6907,
    "FrequencyRank": undefined
  },
  {
    "Character": "峣",
    "Pinyin": "yáo",
    "Definition": "high or tall",
    "Radical": "山",
    "RadicalIndex": "46.12",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4231,
    "FrequencyRank": undefined
  },
  {
    "Character": "毗",
    "Pinyin": "pí",
    "Definition": "help, assist; connect, adjoin",
    "Radical": "田",
    "RadicalIndex": "102.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4206,
    "FrequencyRank": 3441
  },
  {
    "Character": "癸",
    "Pinyin": "guǐ",
    "Definition": "10th heavenly stem",
    "Radical": "癶",
    "RadicalIndex": "105.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1748,
    "FrequencyRank": 4414
  },
  {
    "Character": "浐",
    "Pinyin": "chǎn",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6895,
    "FrequencyRank": undefined
  },
  {
    "Character": "骁",
    "Pinyin": "xiāo",
    "Definition": "excellent horse; brave, valiant",
    "Radical": "馬",
    "RadicalIndex": "187.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4394,
    "FrequencyRank": 4404
  },
  {
    "Character": "笃",
    "Pinyin": "dǔ",
    "Definition": "deep, true, sincere, genuine",
    "Radical": "竹",
    "RadicalIndex": "118.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4255,
    "FrequencyRank": 3207
  },
  {
    "Character": "荪",
    "Pinyin": "sūn",
    "Definition": "aromatic grass; iris, flower",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4135,
    "FrequencyRank": 4051
  },
  {
    "Character": "洢",
    "Pinyin": "yī",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6890,
    "FrequencyRank": undefined
  },
  {
    "Character": "钤",
    "Pinyin": "qián",
    "Definition": "lock, latch; stamp, seal",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4243,
    "FrequencyRank": 5092
  },
  {
    "Character": "祢",
    "Pinyin": "mí",
    "Definition": undefined,
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4370,
    "FrequencyRank": 6020
  },
  {
    "Character": "柞",
    "Pinyin": "zhà",
    "Definition": "oak; spinous evergreen tree; to clear away trees",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4151,
    "FrequencyRank": 5247
  },
  {
    "Character": "飒",
    "Pinyin": "sà",
    "Definition": "the sound of the wind; bleak; melancholy",
    "Radical": "風",
    "RadicalIndex": "182.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4315,
    "FrequencyRank": 4300
  },
  {
    "Character": "洵",
    "Pinyin": "xún",
    "Definition": "true, real, truly, really",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4344,
    "FrequencyRank": 4163
  },
  {
    "Character": "舁",
    "Pinyin": "yú",
    "Definition": undefined,
    "Radical": "臼",
    "RadicalIndex": "134.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6855,
    "FrequencyRank": 6672
  },
  {
    "Character": "栉",
    "Pinyin": "zhì",
    "Definition": "comb; comb out; weed out, elimininate",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4140,
    "FrequencyRank": 4647
  },
  {
    "Character": "娜",
    "Pinyin": "nà",
    "Definition": "elegant, graceful, delicate",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1741,
    "FrequencyRank": 1584
  },
  {
    "Character": "荬",
    "Pinyin": "mǎi",
    "Definition": "a plant name",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4137,
    "FrequencyRank": 8245
  },
  {
    "Character": "眊",
    "Pinyin": "mào",
    "Definition": "dim-sighted; dull, bewildered",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4193,
    "FrequencyRank": 6854
  },
  {
    "Character": "峛",
    "Pinyin": "lǐ",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6838,
    "FrequencyRank": undefined
  },
  {
    "Character": "昴",
    "Pinyin": "mǎo",
    "Definition": "one of the 28 constellations",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4199,
    "FrequencyRank": 5682
  },
  {
    "Character": "庥",
    "Pinyin": "xiū",
    "Definition": "shade; shelter, protection",
    "Radical": "广",
    "RadicalIndex": "53.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4307,
    "FrequencyRank": 5292
  },
  {
    "Character": "俜",
    "Pinyin": "pīng",
    "Definition": "to trust to; send a message",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6856,
    "FrequencyRank": 6137
  },
  {
    "Character": "酋",
    "Pinyin": "qiú",
    "Definition": "chief of tribe, chieftain",
    "Radical": "酉",
    "RadicalIndex": "164.2",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4323,
    "FrequencyRank": 3403
  },
  {
    "Character": "垟",
    "Pinyin": "yáng",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6777,
    "FrequencyRank": undefined
  },
  {
    "Character": "洹",
    "Pinyin": "huán",
    "Definition": "river in Henan province",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4332,
    "FrequencyRank": 4646
  },
  {
    "Character": "挓",
    "Pinyin": "zhā",
    "Definition": "to open out; to expand",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6779,
    "FrequencyRank": undefined
  },
  {
    "Character": "荁",
    "Pinyin": "huán",
    "Definition": "celery",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6784,
    "FrequencyRank": undefined
  },
  {
    "Character": "秕",
    "Pinyin": "bǐ",
    "Definition": "empty grain or rice husk, chaff",
    "Radical": "禾",
    "RadicalIndex": "115.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4251,
    "FrequencyRank": 6313
  },
  {
    "Character": "昺",
    "Pinyin": "bǐng",
    "Definition": "bright, glorious; brilliant",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6819,
    "FrequencyRank": undefined
  },
  {
    "Character": "𦙶",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6867,
    "FrequencyRank": undefined
  },
  {
    "Character": "毡",
    "Pinyin": "zhān",
    "Definition": "felt; rug, carpet",
    "Radical": "毛",
    "RadicalIndex": "82.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1541,
    "FrequencyRank": 3451
  },
  {
    "Character": "昝",
    "Pinyin": "zǎn",
    "Definition": "a dual pronoun, I, you and me, we two",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4298,
    "FrequencyRank": 6447
  },
  {
    "Character": "栐",
    "Pinyin": "yǒng",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6802,
    "FrequencyRank": undefined
  },
  {
    "Character": "哙",
    "Pinyin": "kuài",
    "Definition": "swallow, gulp down; greedy",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4222,
    "FrequencyRank": 5981
  },
  {
    "Character": "洓",
    "Pinyin": "sè",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6882,
    "FrequencyRank": undefined
  },
  {
    "Character": "蚤",
    "Pinyin": "zǎo",
    "Definition": "flea; louse",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1749,
    "FrequencyRank": 4054
  },
  {
    "Character": "昡",
    "Pinyin": "xuàn",
    "Definition": "long day; extended; relaxed",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6830,
    "FrequencyRank": undefined
  },
  {
    "Character": "峋",
    "Pinyin": "xún",
    "Definition": "ranges of hills stretching on beyond another irregular peaks",
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4236,
    "FrequencyRank": 4478
  },
  {
    "Character": "栈",
    "Pinyin": "zhàn",
    "Definition": "warehouse; tavern, inn",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1425,
    "FrequencyRank": 3192
  },
  {
    "Character": "洿",
    "Pinyin": "wū",
    "Definition": "stagnant water; impure, filthy",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6883,
    "FrequencyRank": undefined
  },
  {
    "Character": "茨",
    "Pinyin": "cí",
    "Definition": "thatching; caltrop, Tribulus terrestris",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4128,
    "FrequencyRank": 1936
  },
  {
    "Character": "哒",
    "Pinyin": "dā",
    "Definition": "a sound made to get a horse to move forward",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6827,
    "FrequencyRank": 3741
  },
  {
    "Character": "茈",
    "Pinyin": "cí",
    "Definition": "a plant yielding a red dye",
    "Radical": "艸",
    "RadicalIndex": "140.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6787,
    "FrequencyRank": 7026
  },
  {
    "Character": "哏",
    "Pinyin": "gén",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4228,
    "FrequencyRank": 6052
  },
  {
    "Character": "轹",
    "Pinyin": "lì",
    "Definition": "run over something with vehicle",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6817,
    "FrequencyRank": 6816
  },
  {
    "Character": "哂",
    "Pinyin": "shěn",
    "Definition": "smile, laugh at, sneer at; (Cant.) a final particle indicating doing something to the full extent possible",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4196,
    "FrequencyRank": 4716
  },
  {
    "Character": "玷",
    "Pinyin": "diàn",
    "Definition": "flaw in gem; flaw in character",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1357,
    "FrequencyRank": 4069
  },
  {
    "Character": "呲",
    "Pinyin": "cī",
    "Definition": "give a talking-to",
    "Radical": "口",
    "RadicalIndex": "30.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4207,
    "FrequencyRank": 5364
  },
  {
    "Character": "垓",
    "Pinyin": "gāi",
    "Definition": "border, boundary, frontier",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4105,
    "FrequencyRank": 6093
  },
  {
    "Character": "钣",
    "Pinyin": "bǎn",
    "Definition": "plate",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4242,
    "FrequencyRank": 5705
  },
  {
    "Character": "荚",
    "Pinyin": "jiá",
    "Definition": "pods of leguminous plants; pods",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4109,
    "FrequencyRank": 4119
  },
  {
    "Character": "钮",
    "Pinyin": "niǔ",
    "Definition": "button, knob; surname",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1535,
    "FrequencyRank": 2685
  },
  {
    "Character": "栅",
    "Pinyin": "zhà",
    "Definition": "fence; palisade; grid",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1433,
    "FrequencyRank": 2995
  },
  {
    "Character": "炻",
    "Pinyin": "shí",
    "Definition": "a kind of china",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4325,
    "FrequencyRank": 5986
  },
  {
    "Character": "恽",
    "Pinyin": "yùn",
    "Definition": "devise, plan, deliberate; consult",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4357,
    "FrequencyRank": 4712
  },
  {
    "Character": "眄",
    "Pinyin": "miǎn",
    "Definition": "to look askance; looking dull",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4188,
    "FrequencyRank": 6853
  },
  {
    "Character": "虻",
    "Pinyin": "méng",
    "Definition": "horsefly, gadfly",
    "Radical": "虫",
    "RadicalIndex": "142.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4212,
    "FrequencyRank": 4580
  },
  {
    "Character": "昣",
    "Pinyin": "zhěn",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6826,
    "FrequencyRank": undefined
  },
  {
    "Character": "闾",
    "Pinyin": "lǘ",
    "Definition": "village of twenty-five families",
    "Radical": "門",
    "RadicalIndex": "169.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4317,
    "FrequencyRank": 4175
  },
  {
    "Character": "峧",
    "Pinyin": "jiāo",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6841,
    "FrequencyRank": undefined
  },
  {
    "Character": "钨",
    "Pinyin": "wū",
    "Definition": "tungsten, wolfram",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4244,
    "FrequencyRank": 4818
  },
  {
    "Character": "陛",
    "Pinyin": "bì",
    "Definition": "steps leading throne; throne",
    "Radical": "阜",
    "RadicalIndex": "170.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4380,
    "FrequencyRank": 2667
  },
  {
    "Character": "洴",
    "Pinyin": "píng",
    "Definition": "sound",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6897,
    "FrequencyRank": undefined
  },
  {
    "Character": "恸",
    "Pinyin": "tòng",
    "Definition": "sadness, grief; mourn; be moved",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4349,
    "FrequencyRank": 4516
  },
  {
    "Character": "荟",
    "Pinyin": "huì",
    "Definition": "luxuriant, flourishing, abundant",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4123,
    "FrequencyRank": 4007
  },
  {
    "Character": "咦",
    "Pinyin": "yí",
    "Definition": "exclamation of surprise",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4202,
    "FrequencyRank": 3080
  },
  {
    "Character": "俨",
    "Pinyin": "yǎn",
    "Definition": "grave, respectful, majestic",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4257,
    "FrequencyRank": 3559
  },
  {
    "Character": "庠",
    "Pinyin": "xiáng",
    "Definition": "village school; teach",
    "Radical": "广",
    "RadicalIndex": "53.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4312,
    "FrequencyRank": 4537
  },
  {
    "Character": "洘",
    "Pinyin": "kǎo",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6881,
    "FrequencyRank": undefined
  },
  {
    "Character": "洧",
    "Pinyin": "wěi",
    "Definition": "name of a river in honan",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4333,
    "FrequencyRank": 7099
  },
  {
    "Character": "畈",
    "Pinyin": "fàn",
    "Definition": "field; farm",
    "Radical": "田",
    "RadicalIndex": "102.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4210,
    "FrequencyRank": 5483
  },
  {
    "Character": "姚",
    "Pinyin": "yáo",
    "Definition": "handsome, elegant; surname",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1740,
    "FrequencyRank": 2308
  },
  {
    "Character": "珊",
    "Pinyin": "shān",
    "Definition": "coral",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1360,
    "FrequencyRank": 2555
  },
  {
    "Character": "屎",
    "Pinyin": "shǐ",
    "Definition": "excrement, shit, dung",
    "Radical": "尸",
    "RadicalIndex": "44.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1725,
    "FrequencyRank": 3336
  },
  {
    "Character": "殆",
    "Pinyin": "dài",
    "Definition": "dangerous, perilous; endanger",
    "Radical": "歹",
    "RadicalIndex": "78.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4177,
    "FrequencyRank": 3142
  },
  {
    "Character": "诮",
    "Pinyin": "qiào",
    "Definition": "criticize, scold, blame, ridicule",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4368,
    "FrequencyRank": 4613
  },
  {
    "Character": "柰",
    "Pinyin": "nài",
    "Definition": "crab-apple tree; endure, bear",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4139,
    "FrequencyRank": 6394
  },
  {
    "Character": "疭",
    "Pinyin": "zòng",
    "Definition": "spasms or convulsions in young children, caused by indigestion",
    "Radical": "疒",
    "RadicalIndex": "104.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4311,
    "FrequencyRank": undefined
  },
  {
    "Character": "珈",
    "Pinyin": "jiā",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4092,
    "FrequencyRank": 5061
  },
  {
    "Character": "饸",
    "Pinyin": "hé",
    "Definition": undefined,
    "Radical": "食",
    "RadicalIndex": "184.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4300,
    "FrequencyRank": 6790
  },
  {
    "Character": "姝",
    "Pinyin": "shū",
    "Definition": "a beautiful girl",
    "Radical": "女",
    "RadicalIndex": "38.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4385,
    "FrequencyRank": 6063
  },
  {
    "Character": "荃",
    "Pinyin": "quán",
    "Definition": "aromatic herb; fine cloth",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4122,
    "FrequencyRank": 3756
  },
  {
    "Character": "俅",
    "Pinyin": "qiú",
    "Definition": "ornamental cap",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4258,
    "FrequencyRank": 5634
  },
  {
    "Character": "贲",
    "Pinyin": "bēn",
    "Definition": "forge ahead; energetic; surname",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4100,
    "FrequencyRank": 4733
  },
  {
    "Character": "牯",
    "Pinyin": "gǔ",
    "Definition": "cow; bull; ox",
    "Radical": "牛",
    "RadicalIndex": "93.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4249,
    "FrequencyRank": 5439
  },
  {
    "Character": "柩",
    "Pinyin": "jiù",
    "Definition": "coffin which contains corpse",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4144,
    "FrequencyRank": 4568
  },
  {
    "Character": "轳",
    "Pinyin": "lú",
    "Definition": "pulley, windlass, capstan",
    "Radical": "車",
    "RadicalIndex": "159.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4180,
    "FrequencyRank": 5519
  },
  {
    "Character": "奕",
    "Pinyin": "yì",
    "Definition": "in sequence, orderly; abundant",
    "Radical": "大",
    "RadicalIndex": "37.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4306,
    "FrequencyRank": 3026
  },
  {
    "Character": "禹",
    "Pinyin": "yǔ",
    "Definition": "legendary hsia dynasty founder",
    "Radical": "禸",
    "RadicalIndex": "114.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1575,
    "FrequencyRank": 2889
  },
  {
    "Character": "贻",
    "Pinyin": "yí",
    "Definition": "give to, hand down, bequeath",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1522,
    "FrequencyRank": 3504
  },
  {
    "Character": "衿",
    "Pinyin": "jīn",
    "Definition": "collar or lapel of garment",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4362,
    "FrequencyRank": 5748
  },
  {
    "Character": "荮",
    "Pinyin": "zhòu",
    "Definition": "grass",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4138,
    "FrequencyRank": 9746
  },
  {
    "Character": "茼",
    "Pinyin": "tóng",
    "Definition": "Chrysanthemum coronarium",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4114,
    "FrequencyRank": 7098
  },
  {
    "Character": "垵",
    "Pinyin": "ǎn",
    "Definition": "cover with earth; a pit; a hole",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6780,
    "FrequencyRank": undefined
  },
  {
    "Character": "笈",
    "Pinyin": "jí",
    "Definition": "bamboo box used carry books",
    "Radical": "竹",
    "RadicalIndex": "118.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4254,
    "FrequencyRank": 3662
  },
  {
    "Character": "贳",
    "Pinyin": "shì",
    "Definition": "borrow; pardon; loan",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4111,
    "FrequencyRank": 6977
  },
  {
    "Character": "荏",
    "Pinyin": "rěn",
    "Definition": "beans; soft, pliable; herb",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4120,
    "FrequencyRank": 5660
  },
  {
    "Character": "茵",
    "Pinyin": "yīn",
    "Definition": "a cushion, mattress; wormwood; Skimmia japon",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1410,
    "FrequencyRank": 2765
  },
  {
    "Character": "砭",
    "Pinyin": "biān",
    "Definition": "stone probe; pierce; counsel",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4169,
    "FrequencyRank": 4774
  },
  {
    "Character": "枵",
    "Pinyin": "xiāo",
    "Definition": "hollo stump of a tree; empty, thin",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4148,
    "FrequencyRank": 5686
  },
  {
    "Character": "珇",
    "Pinyin": "zǔ",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6759,
    "FrequencyRank": undefined
  },
  {
    "Character": "浔",
    "Pinyin": "xún",
    "Definition": "steep bank by stream; jiujiang",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4346,
    "FrequencyRank": 4870
  },
  {
    "Character": "饹",
    "Pinyin": "le",
    "Definition": undefined,
    "Radical": "食",
    "RadicalIndex": "184.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4301,
    "FrequencyRank": 6819
  },
  {
    "Character": "垧",
    "Pinyin": "shǎng",
    "Definition": "variable unit of land measure",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4104,
    "FrequencyRank": 6275
  },
  {
    "Character": "柝",
    "Pinyin": "tuò",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4152,
    "FrequencyRank": 6680
  },
  {
    "Character": "钪",
    "Pinyin": "kàng",
    "Definition": "scandium",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6849,
    "FrequencyRank": 5440
  },
  {
    "Character": "垕",
    "Pinyin": "hòu",
    "Definition": "used in place names",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6859,
    "FrequencyRank": undefined
  },
  {
    "Character": "俟",
    "Pinyin": "qí",
    "Definition": "wait for, wait until, as soon as",
    "Radical": "人",
    "RadicalIndex": "9.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4267,
    "FrequencyRank": 3605
  },
  {
    "Character": "贶",
    "Pinyin": "kuàng",
    "Definition": "give, grant, bestow; surname",
    "Radical": "貝",
    "RadicalIndex": "154.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4238,
    "FrequencyRank": 6978
  },
  {
    "Character": "洚",
    "Pinyin": "jiàng",
    "Definition": "a flood",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6892,
    "FrequencyRank": 7337
  },
  {
    "Character": "诰",
    "Pinyin": "gào",
    "Definition": "inform, notify, admonish, order",
    "Radical": "言",
    "RadicalIndex": "149.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4371,
    "FrequencyRank": 3967
  },
  {
    "Character": "逄",
    "Pinyin": "páng",
    "Definition": "a surname",
    "Radical": "辵",
    "RadicalIndex": "162.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4297,
    "FrequencyRank": 5983
  },
  {
    "Character": "垱",
    "Pinyin": "dàng",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4101,
    "FrequencyRank": undefined
  },
  {
    "Character": "绖",
    "Pinyin": "dié",
    "Definition": "white hempen cloth worn by mourners",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6917,
    "FrequencyRank": 6969
  },
  {
    "Character": "耏",
    "Pinyin": "nài",
    "Definition": "whiskers",
    "Radical": "而",
    "RadicalIndex": "126.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6812,
    "FrequencyRank": undefined
  },
  {
    "Character": "垯",
    "Pinyin": "da",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6768,
    "FrequencyRank": undefined
  },
  {
    "Character": "珉",
    "Pinyin": "mín",
    "Definition": "stone resembling jade",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4091,
    "FrequencyRank": 6400
  },
  {
    "Character": "胙",
    "Pinyin": "zuò",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4283,
    "FrequencyRank": 5896
  },
  {
    "Character": "枸",
    "Pinyin": "gǒu",
    "Definition": "kind of aspen found in Sichuan",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4156,
    "FrequencyRank": 4578
  },
  {
    "Character": "氢",
    "Pinyin": "qīng",
    "Definition": "ammonia; hydrogen nitride",
    "Radical": "气",
    "RadicalIndex": "84.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1542,
    "FrequencyRank": 2637
  },
  {
    "Character": "昭",
    "Pinyin": "zhāo",
    "Definition": "bright, luminous; illustrious",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1491,
    "FrequencyRank": 2190
  },
  {
    "Character": "珏",
    "Pinyin": "jué",
    "Definition": "two pieces of jade joined together",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4084,
    "FrequencyRank": 5068
  },
  {
    "Character": "咴",
    "Pinyin": "huī",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4197,
    "FrequencyRank": 5558
  },
  {
    "Character": "钛",
    "Pinyin": "tài",
    "Definition": "titanium",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4240,
    "FrequencyRank": 4310
  },
  {
    "Character": "昱",
    "Pinyin": "yù",
    "Definition": "bright light, sunlight; dazzling",
    "Radical": "日",
    "RadicalIndex": "72.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4200,
    "FrequencyRank": 4596
  },
  {
    "Character": "匍",
    "Pinyin": "pú",
    "Definition": "crawl; lie prostrate",
    "Radical": "勹",
    "RadicalIndex": "20.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4290,
    "FrequencyRank": 4202
  },
  {
    "Character": "㭕",
    "Pinyin": "qū",
    "Definition": "a board (on the back of a donkey) for carrying things; saddle",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6797,
    "FrequencyRank": undefined
  },
  {
    "Character": "咫",
    "Pinyin": "zhǐ",
    "Definition": "foot measure of Zhou dynasty",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4376,
    "FrequencyRank": 4196
  },
  {
    "Character": "衲",
    "Pinyin": "nà",
    "Definition": "mend, sew, patch; line; quilt",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4360,
    "FrequencyRank": 4660
  },
  {
    "Character": "𪾢",
    "Pinyin": "xiàn",
    "Definition": "to look at fearfully; overly cautious",
    "Radical": "目",
    "RadicalIndex": "109.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6820,
    "FrequencyRank": undefined
  },
  {
    "Character": "炱",
    "Pinyin": "tái",
    "Definition": "soot",
    "Radical": "火",
    "RadicalIndex": "86.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4391,
    "FrequencyRank": 5965
  },
  {
    "Character": "钫",
    "Pinyin": "fāng",
    "Definition": "francium",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4245,
    "FrequencyRank": 5939
  },
  {
    "Character": "峘",
    "Pinyin": "huán",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6836,
    "FrequencyRank": undefined
  },
  {
    "Character": "郡",
    "Pinyin": "jùn",
    "Definition": "administrative division",
    "Radical": "邑",
    "RadicalIndex": "163.7",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4375,
    "FrequencyRank": 2289
  },
  {
    "Character": "钡",
    "Pinyin": "bèi",
    "Definition": "barium",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4241,
    "FrequencyRank": 3858
  },
  {
    "Character": "峗",
    "Pinyin": "wéi",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6840,
    "FrequencyRank": undefined
  },
  {
    "Character": "訄",
    "Pinyin": "qiú",
    "Definition": undefined,
    "Radical": "言",
    "RadicalIndex": "149.2",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6873,
    "FrequencyRank": 7110
  },
  {
    "Character": "闼",
    "Pinyin": "tà",
    "Definition": "door; gate",
    "Radical": "門",
    "RadicalIndex": "169.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4316,
    "FrequencyRank": 4824
  },
  {
    "Character": "峒",
    "Pinyin": "dòng",
    "Definition": "mountain in Gansu province",
    "Radical": "山",
    "RadicalIndex": "46.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4234,
    "FrequencyRank": 4633
  },
  {
    "Character": "荦",
    "Pinyin": "luò",
    "Definition": "brindled ox; brindled in color",
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4131,
    "FrequencyRank": 6111
  },
  {
    "Character": "秭",
    "Pinyin": "zǐ",
    "Definition": "one thousand millions, sometimes used for one hundred millions",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4252,
    "FrequencyRank": 6171
  },
  {
    "Character": "钜",
    "Pinyin": "jù",
    "Definition": "steel, iron; great",
    "Radical": "金",
    "RadicalIndex": "167.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6845,
    "FrequencyRank": 4606
  },
  {
    "Character": "洫",
    "Pinyin": "xù",
    "Definition": "to ditch; a moat",
    "Radical": "水",
    "RadicalIndex": "85.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4341,
    "FrequencyRank": 6901
  },
  {
    "Character": "垭",
    "Pinyin": "yā",
    "Definition": "character used in place names",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4094,
    "FrequencyRank": 5317
  },
  {
    "Character": "垙",
    "Pinyin": "guāng",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6769,
    "FrequencyRank": undefined
  },
  {
    "Character": "垎",
    "Pinyin": "hè",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6775,
    "FrequencyRank": undefined
  },
  {
    "Character": "玶",
    "Pinyin": "píng",
    "Definition": "name of one kind of jade",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6758,
    "FrequencyRank": undefined
  },
  {
    "Character": "庤",
    "Pinyin": "zhì",
    "Definition": "prepare",
    "Radical": "广",
    "RadicalIndex": "53.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6875,
    "FrequencyRank": undefined
  },
  {
    "Character": "耇",
    "Pinyin": "gǒu",
    "Definition": "wrinkled face of the elderly",
    "Radical": "老",
    "RadicalIndex": "125.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6773,
    "FrequencyRank": 8965
  },
  {
    "Character": "咡",
    "Pinyin": "èr",
    "Definition": "side of mouth",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6823,
    "FrequencyRank": undefined
  },
  {
    "Character": "枹",
    "Pinyin": "bāo",
    "Definition": "drumstick",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 6801,
    "FrequencyRank": undefined
  },
  {
    "Character": "鸩",
    "Pinyin": "zhèn",
    "Definition": "a bird resembling the secretary falcon",
    "Radical": "鳥",
    "RadicalIndex": "196.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4373,
    "FrequencyRank": 5144
  },
  {
    "Character": "柁",
    "Pinyin": "duò",
    "Definition": "large tie-beams",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4158,
    "FrequencyRank": 6974
  },
  {
    "Character": "羑",
    "Pinyin": "yǒu",
    "Definition": "a guide to goodness",
    "Radical": "羊",
    "RadicalIndex": "123.3",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4320,
    "FrequencyRank": 7224
  },
  {
    "Character": "骅",
    "Pinyin": "huá",
    "Definition": "an excellent horse",
    "Radical": "馬",
    "RadicalIndex": "187.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4395,
    "FrequencyRank": 4803
  },
  {
    "Character": "鸥",
    "Pinyin": "ōu",
    "Definition": "seagull, tern; Larus species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 1459,
    "FrequencyRank": 3371
  },
  {
    "Character": "竑",
    "Pinyin": "hóng",
    "Definition": "be vast and endless; broad",
    "Radical": "立",
    "RadicalIndex": "117.4",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4313,
    "FrequencyRank": 4654
  },
  {
    "Character": "柽",
    "Pinyin": "chēng",
    "Definition": "type of willow; tamarisk",
    "Radical": "木",
    "RadicalIndex": "75.5",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4160,
    "FrequencyRank": 6486
  },
  {
    "Character": "哞",
    "Pinyin": "mōu",
    "Definition": "moo",
    "Radical": "口",
    "RadicalIndex": "30.6",
    "StrokeCount": 9,
    "HSK": undefined,
    "GeneralStandard": 4229,
    "FrequencyRank": 5619
  },
  {
    "Character": "俸",
    "Pinyin": "fèng",
    "Definition": "wages, salary, official emolument",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4561,
    "FrequencyRank": 3910
  },
  {
    "Character": "脎",
    "Pinyin": "sà",
    "Definition": "an organic compound",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7030,
    "FrequencyRank": 8286
  },
  {
    "Character": "祟",
    "Pinyin": "suì",
    "Definition": "evil spirit; evil influence",
    "Radical": "示",
    "RadicalIndex": "113.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2136,
    "FrequencyRank": 3414
  },
  {
    "Character": "涔",
    "Pinyin": "cén",
    "Definition": "river in Shaanxi; murky torrent",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4654,
    "FrequencyRank": 4706
  },
  {
    "Character": "莎",
    "Pinyin": "shā",
    "Definition": "kind of sedge grass, used anciently for raincoats",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4442,
    "FrequencyRank": 2125
  },
  {
    "Character": "倧",
    "Pinyin": "zōng",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7021,
    "FrequencyRank": undefined
  },
  {
    "Character": "耙",
    "Pinyin": "bà",
    "Definition": "rake",
    "Radical": "耒",
    "RadicalIndex": "127.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1769,
    "FrequencyRank": 4126
  },
  {
    "Character": "胱",
    "Pinyin": "guāng",
    "Definition": "bladder",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4590,
    "FrequencyRank": 3847
  },
  {
    "Character": "诼",
    "Pinyin": "zhuó",
    "Definition": "slander; gossip; rumors",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4670,
    "FrequencyRank": 5699
  },
  {
    "Character": "蚓",
    "Pinyin": "yǐn",
    "Definition": "earthworm",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1903,
    "FrequencyRank": 4102
  },
  {
    "Character": "砣",
    "Pinyin": "tuó",
    "Definition": "a stone roller; a heavy stone, a weight, a plummet",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4481,
    "FrequencyRank": 4930
  },
  {
    "Character": "鸱",
    "Pinyin": "chī",
    "Definition": "kite, horned owl; wine cups",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4598,
    "FrequencyRank": 5542
  },
  {
    "Character": "崄",
    "Pinyin": "xiǎn",
    "Definition": "high, steep, precipitous",
    "Radical": "山",
    "RadicalIndex": "46.13",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7000,
    "FrequencyRank": undefined
  },
  {
    "Character": "胰",
    "Pinyin": "yí",
    "Definition": "pancreas; soap",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1993,
    "FrequencyRank": 3238
  },
  {
    "Character": "晌",
    "Pinyin": "shǎng",
    "Definition": "noon, midday; moment; unit land measurement equal to seven mou (畝), or the area that can be sown in one day",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1895,
    "FrequencyRank": 2822
  },
  {
    "Character": "琊",
    "Pinyin": "yá",
    "Definition": "a place in eastern Shandong",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6929,
    "FrequencyRank": 5112
  },
  {
    "Character": "桎",
    "Pinyin": "zhì",
    "Definition": "fetters, shackles, handcuffs",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4452,
    "FrequencyRank": 4722
  },
  {
    "Character": "脓",
    "Pinyin": "nóng",
    "Definition": "pus",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2002,
    "FrequencyRank": 3376
  },
  {
    "Character": "浼",
    "Pinyin": "měi",
    "Definition": "to request; to ask a favour of; to pollute, contaminate",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7064,
    "FrequencyRank": 5934
  },
  {
    "Character": "痂",
    "Pinyin": "jiā",
    "Definition": "scab",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4620,
    "FrequencyRank": 4897
  },
  {
    "Character": "冔",
    "Pinyin": "xǔ",
    "Definition": "cap worn during the Yin dynasty",
    "Radical": "冂",
    "RadicalIndex": "13.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6988,
    "FrequencyRank": undefined
  },
  {
    "Character": "彧",
    "Pinyin": "yù",
    "Definition": "refined, cultured, polished",
    "Radical": "彡",
    "RadicalIndex": "59.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4466,
    "FrequencyRank": undefined
  },
  {
    "Character": "娑",
    "Pinyin": "suō",
    "Definition": "dance, frolic; lounge; saunter",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4648,
    "FrequencyRank": 4335
  },
  {
    "Character": "𫄨",
    "Pinyin": "chī",
    "Definition": "fine linen, fine help cloth",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7094,
    "FrequencyRank": undefined
  },
  {
    "Character": "莉",
    "Pinyin": "lì",
    "Definition": "white jasmine",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1825,
    "FrequencyRank": 1878
  },
  {
    "Character": "桡",
    "Pinyin": "ráo",
    "Definition": "bent or twisted piece of wood",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4451,
    "FrequencyRank": 5406
  },
  {
    "Character": "烊",
    "Pinyin": "yáng",
    "Definition": "to smelt; to melt",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4640,
    "FrequencyRank": 4961
  },
  {
    "Character": "砟",
    "Pinyin": "zhǎ",
    "Definition": "stone tablet; monument",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4478,
    "FrequencyRank": 6429
  },
  {
    "Character": "祧",
    "Pinyin": "tiāo",
    "Definition": "an ancestral hall",
    "Radical": "示",
    "RadicalIndex": "113.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7080,
    "FrequencyRank": 6339
  },
  {
    "Character": "𨺙",
    "Pinyin": "nì",
    "Definition": undefined,
    "Radical": "阜",
    "RadicalIndex": "170.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7084,
    "FrequencyRank": undefined
  },
  {
    "Character": "逍",
    "Pinyin": "xiāo",
    "Definition": "ramble, stroll, jaunt, loiter",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4492,
    "FrequencyRank": 3556
  },
  {
    "Character": "浚",
    "Pinyin": "jùn",
    "Definition": "dredge",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4658,
    "FrequencyRank": 3753
  },
  {
    "Character": "铌",
    "Pinyin": "ní",
    "Definition": "niobium",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4544,
    "FrequencyRank": 6178
  },
  {
    "Character": "栝",
    "Pinyin": "guā",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4456,
    "FrequencyRank": 7263
  },
  {
    "Character": "浞",
    "Pinyin": "zhuó",
    "Definition": "to soak, to steep in water",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4651,
    "FrequencyRank": 5463
  },
  {
    "Character": "屙",
    "Pinyin": "ē",
    "Definition": "to ease nature",
    "Radical": "尸",
    "RadicalIndex": "44.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4680,
    "FrequencyRank": 5435
  },
  {
    "Character": "珲",
    "Pinyin": "huī",
    "Definition": "bright, glorious, splendid",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4410,
    "FrequencyRank": 5689
  },
  {
    "Character": "珽",
    "Pinyin": "tǐng",
    "Definition": "jade tablet",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6933,
    "FrequencyRank": undefined
  },
  {
    "Character": "谂",
    "Pinyin": "shěn",
    "Definition": "consult carefully with, counsel",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4676,
    "FrequencyRank": 6058
  },
  {
    "Character": "胴",
    "Pinyin": "dòng",
    "Definition": "the large intestine; the body",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4591,
    "FrequencyRank": 4782
  },
  {
    "Character": "晐",
    "Pinyin": "gāi",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6990,
    "FrequencyRank": undefined
  },
  {
    "Character": "鸶",
    "Pinyin": "sī",
    "Definition": "the eastern egret",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4708,
    "FrequencyRank": 5663
  },
  {
    "Character": "涓",
    "Pinyin": "juān",
    "Definition": "brook, stream; select; pure",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4652,
    "FrequencyRank": 4050
  },
  {
    "Character": "峱",
    "Pinyin": "náo",
    "Definition": "name of a mountain",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7036,
    "FrequencyRank": undefined
  },
  {
    "Character": "埗",
    "Pinyin": "bù",
    "Definition": "(same as 埠) a wharf, dock, jetty; a trading center, port; place name (e.g., 深水埗 in Hong Kong)",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6942,
    "FrequencyRank": undefined
  },
  {
    "Character": "窅",
    "Pinyin": "yǎo",
    "Definition": "far, deep; sunken eyes; sad",
    "Radical": "穴",
    "RadicalIndex": "116.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7072,
    "FrequencyRank": 8446
  },
  {
    "Character": "鸪",
    "Pinyin": "gū",
    "Definition": "species of Taiwan pigeon",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4445,
    "FrequencyRank": 5008
  },
  {
    "Character": "晔",
    "Pinyin": "yè",
    "Definition": "bright; radiant; thriving",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6989,
    "FrequencyRank": 4441
  },
  {
    "Character": "勐",
    "Pinyin": "měng",
    "Definition": "imperial degree; daoist magic",
    "Radical": "力",
    "RadicalIndex": "19.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4682,
    "FrequencyRank": 5460
  },
  {
    "Character": "颃",
    "Pinyin": "háng",
    "Definition": "fly down or downward",
    "Radical": "頁",
    "RadicalIndex": "181.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4624,
    "FrequencyRank": 6703
  },
  {
    "Character": "桀",
    "Pinyin": "jié",
    "Definition": "chicken roost; ancient emperor",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4607,
    "FrequencyRank": 4097
  },
  {
    "Character": "梃",
    "Pinyin": "tǐng",
    "Definition": "a club; a stalk; straight",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4455,
    "FrequencyRank": 6681
  },
  {
    "Character": "埌",
    "Pinyin": "làng",
    "Definition": "waste",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6947,
    "FrequencyRank": undefined
  },
  {
    "Character": "鸰",
    "Pinyin": "líng",
    "Definition": "species of lark; wagtail; Motacilla species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7029,
    "FrequencyRank": 6781
  },
  {
    "Character": "悍",
    "Pinyin": "hàn",
    "Definition": "courageous, brave; violent",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2093,
    "FrequencyRank": 3073
  },
  {
    "Character": "趸",
    "Pinyin": "dǔn",
    "Definition": "sell or buy wholesale; store",
    "Radical": "足",
    "RadicalIndex": "157.3",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4488,
    "FrequencyRank": 5347
  },
  {
    "Character": "砵",
    "Pinyin": "bō",
    "Definition": "(Cant.) port (Eng. loan-word), 西矺 a sideboard (Eng. loan-word)",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6968,
    "FrequencyRank": undefined
  },
  {
    "Character": "砷",
    "Pinyin": "shēn",
    "Definition": "arsenic",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4477,
    "FrequencyRank": 4452
  },
  {
    "Character": "垺",
    "Pinyin": "fū",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6944,
    "FrequencyRank": undefined
  },
  {
    "Character": "扆",
    "Pinyin": "yǐ",
    "Definition": "screen",
    "Radical": "戶",
    "RadicalIndex": "63.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7076,
    "FrequencyRank": undefined
  },
  {
    "Character": "辂",
    "Pinyin": "lù",
    "Definition": "a chariot, carriage; a carriage pull-bar",
    "Radical": "車",
    "RadicalIndex": "159.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4486,
    "FrequencyRank": 6201
  },
  {
    "Character": "逦",
    "Pinyin": "lǐ",
    "Definition": "meandering, winding",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4470,
    "FrequencyRank": 5471
  },
  {
    "Character": "硁",
    "Pinyin": "kēng",
    "Definition": "the sound of stones knocking together",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6972,
    "FrequencyRank": 7425
  },
  {
    "Character": "烝",
    "Pinyin": "zhēng",
    "Definition": "rise, steam; many, numerous",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7086,
    "FrequencyRank": undefined
  },
  {
    "Character": "阄",
    "Pinyin": "jiū",
    "Definition": "lots (to be drawn); draw lots",
    "Radical": "門",
    "RadicalIndex": "169.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4630,
    "FrequencyRank": 4798
  },
  {
    "Character": "笊",
    "Pinyin": "zhào",
    "Definition": "ladle, bamboo skimmer",
    "Radical": "竹",
    "RadicalIndex": "118.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4558,
    "FrequencyRank": 5353
  },
  {
    "Character": "骋",
    "Pinyin": "chěng",
    "Definition": "gallop horse; hasten, hurry",
    "Radical": "馬",
    "RadicalIndex": "187.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4702,
    "FrequencyRank": 4028
  },
  {
    "Character": "剕",
    "Pinyin": "fèi",
    "Definition": undefined,
    "Radical": "刀",
    "RadicalIndex": "18.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6980,
    "FrequencyRank": undefined
  },
  {
    "Character": "唏",
    "Pinyin": "xī",
    "Definition": "weep or sob; grieve",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4517,
    "FrequencyRank": 4453
  },
  {
    "Character": "𫗧",
    "Pinyin": "sù",
    "Definition": "a pot of cooked rice",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7039,
    "FrequencyRank": undefined
  },
  {
    "Character": "羖",
    "Pinyin": "gǔ",
    "Definition": "a black ram",
    "Radical": "羊",
    "RadicalIndex": "123.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7045,
    "FrequencyRank": undefined
  },
  {
    "Character": "鸮",
    "Pinyin": "xiāo",
    "Definition": "owl",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4503,
    "FrequencyRank": 6353
  },
  {
    "Character": "颀",
    "Pinyin": "qí",
    "Definition": "tall and slim",
    "Radical": "斤",
    "RadicalIndex": "69.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4583,
    "FrequencyRank": 5062
  },
  {
    "Character": "胼",
    "Pinyin": "pián",
    "Definition": "callus, calluses",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4594,
    "FrequencyRank": 5638
  },
  {
    "Character": "扅",
    "Pinyin": "yí",
    "Definition": "gate bar, bolt",
    "Radical": "戶",
    "RadicalIndex": "63.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7075,
    "FrequencyRank": undefined
  },
  {
    "Character": "挈",
    "Pinyin": "qiè",
    "Definition": "assist, help, lead by hand",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4400,
    "FrequencyRank": 5220
  },
  {
    "Character": "琤",
    "Pinyin": "chēng",
    "Definition": "jade of jade being; tinkle",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4409,
    "FrequencyRank": undefined
  },
  {
    "Character": "晊",
    "Pinyin": "zhì",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6984,
    "FrequencyRank": undefined
  },
  {
    "Character": "舭",
    "Pinyin": "bǐ",
    "Definition": undefined,
    "Radical": "舟",
    "RadicalIndex": "137.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7024,
    "FrequencyRank": 6512
  },
  {
    "Character": "赀",
    "Pinyin": "zī",
    "Definition": "property; wealth; to count",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6981,
    "FrequencyRank": 5889
  },
  {
    "Character": "倞",
    "Pinyin": "jìng",
    "Definition": "far",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7018,
    "FrequencyRank": undefined
  },
  {
    "Character": "骍",
    "Pinyin": "xīng",
    "Definition": "red, brown, bay; neat, harmonious",
    "Radical": "馬",
    "RadicalIndex": "187.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7096,
    "FrequencyRank": 8374
  },
  {
    "Character": "袅",
    "Pinyin": "niǎo",
    "Definition": "curling upwards; wavering gently",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4608,
    "FrequencyRank": 3517
  },
  {
    "Character": "耆",
    "Pinyin": "qí",
    "Definition": "man of sixty; aged, old",
    "Radical": "老",
    "RadicalIndex": "125.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4419,
    "FrequencyRank": 3779
  },
  {
    "Character": "栳",
    "Pinyin": "lǎo",
    "Definition": "a basket",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4448,
    "FrequencyRank": 6788
  },
  {
    "Character": "蚌",
    "Pinyin": "bàng",
    "Definition": "oysters, mussels; mother-of-pearl",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1898,
    "FrequencyRank": 3947
  },
  {
    "Character": "蚩",
    "Pinyin": "chī",
    "Definition": "worm; ignorant, rustic; laugh",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4685,
    "FrequencyRank": 4458
  },
  {
    "Character": "娲",
    "Pinyin": "wā",
    "Definition": "a goddess, the mythological sister and successor to Fuxi",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4691,
    "FrequencyRank": 4241
  },
  {
    "Character": "羓",
    "Pinyin": "bā",
    "Definition": "dried meat",
    "Radical": "羊",
    "RadicalIndex": "123.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7046,
    "FrequencyRank": undefined
  },
  {
    "Character": "姬",
    "Pinyin": "jī",
    "Definition": "beauty; imperial concubine",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4687,
    "FrequencyRank": 2683
  },
  {
    "Character": "砾",
    "Pinyin": "lì",
    "Definition": "gravel, pebbles",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1865,
    "FrequencyRank": 3511
  },
  {
    "Character": "狷",
    "Pinyin": "juàn",
    "Definition": "rash, impetuous, impulsive",
    "Radical": "犬",
    "RadicalIndex": "94.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4601,
    "FrequencyRank": 5730
  },
  {
    "Character": "赅",
    "Pinyin": "gāi",
    "Definition": "prepared for; inclusive",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4526,
    "FrequencyRank": 5401
  },
  {
    "Character": "罡",
    "Pinyin": "gāng",
    "Definition": "the name of a certain stars; the god who is supposed to live in them",
    "Radical": "网",
    "RadicalIndex": "122.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4522,
    "FrequencyRank": 3980
  },
  {
    "Character": "旄",
    "Pinyin": "máo",
    "Definition": "a kind of ancient flag; old",
    "Radical": "方",
    "RadicalIndex": "70.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4627,
    "FrequencyRank": 6107
  },
  {
    "Character": "涞",
    "Pinyin": "lái",
    "Definition": "river in Hebei province; creek",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4646,
    "FrequencyRank": 4483
  },
  {
    "Character": "钵",
    "Pinyin": "bō",
    "Definition": "earthenware basin; alms bowl",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4530,
    "FrequencyRank": 3584
  },
  {
    "Character": "𫄧",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6922,
    "FrequencyRank": undefined
  },
  {
    "Character": "莶",
    "Pinyin": "xiān",
    "Definition": "vine",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6953,
    "FrequencyRank": 6286
  },
  {
    "Character": "珣",
    "Pinyin": "xún",
    "Definition": "name of a kind of jade",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4407,
    "FrequencyRank": undefined
  },
  {
    "Character": "帱",
    "Pinyin": "chóu",
    "Definition": "cover up; curtain",
    "Radical": "巾",
    "RadicalIndex": "50.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6996,
    "FrequencyRank": 7770
  },
  {
    "Character": "疽",
    "Pinyin": "jū",
    "Definition": "ulcer, carbuncle, abscess",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4617,
    "FrequencyRank": 4182
  },
  {
    "Character": "眚",
    "Pinyin": "shěng",
    "Definition": "disease of the eyes; crime, fault",
    "Radical": "目",
    "RadicalIndex": "109.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7010,
    "FrequencyRank": 6682
  },
  {
    "Character": "桧",
    "Pinyin": "guì",
    "Definition": "Chinese cypress, Chinese juniper",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4459,
    "FrequencyRank": 4101
  },
  {
    "Character": "娉",
    "Pinyin": "pīng",
    "Definition": "beautiful, attractive, charming, graceful",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4690,
    "FrequencyRank": 5284
  },
  {
    "Character": "峨",
    "Pinyin": "é",
    "Definition": "lofty",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1920,
    "FrequencyRank": 3063
  },
  {
    "Character": "胺",
    "Pinyin": "àn",
    "Definition": "amine",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4597,
    "FrequencyRank": 3433
  },
  {
    "Character": "浣",
    "Pinyin": "huàn",
    "Definition": "to wash, to rinse",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4657,
    "FrequencyRank": 4343
  },
  {
    "Character": "崂",
    "Pinyin": "láo",
    "Definition": "Laoshan, mountain in Shandong",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4520,
    "FrequencyRank": 4592
  },
  {
    "Character": "狸",
    "Pinyin": "lí",
    "Definition": "fox",
    "Radical": "犬",
    "RadicalIndex": "94.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2004,
    "FrequencyRank": 2738
  },
  {
    "Character": "鬲",
    "Pinyin": "gé",
    "Definition": "type of caldron with three hollow legs; name of a state; KangXi radical number 193",
    "Radical": "鬲",
    "RadicalIndex": "193",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4467,
    "FrequencyRank": 6855
  },
  {
    "Character": "桢",
    "Pinyin": "zhēn",
    "Definition": "hardwood; supports, posts",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4453,
    "FrequencyRank": 3730
  },
  {
    "Character": "郯",
    "Pinyin": "tán",
    "Definition": "name of a small ancient principality which was situated in what is now part of Shantung and Kiangsu",
    "Radical": "邑",
    "RadicalIndex": "163.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4642,
    "FrequencyRank": 5228
  },
  {
    "Character": "烩",
    "Pinyin": "huì",
    "Definition": "ragout, cook, braise",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4639,
    "FrequencyRank": 4828
  },
  {
    "Character": "轼",
    "Pinyin": "shì",
    "Definition": "horizontal wooden bar in front",
    "Radical": "車",
    "RadicalIndex": "159.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4484,
    "FrequencyRank": 2926
  },
  {
    "Character": "梆",
    "Pinyin": "bāng",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1834,
    "FrequencyRank": 4062
  },
  {
    "Character": "衮",
    "Pinyin": "gǔn",
    "Definition": "ceremonial dress worn by the emperor",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4622,
    "FrequencyRank": 4843
  },
  {
    "Character": "畖",
    "Pinyin": "wā",
    "Definition": undefined,
    "Radical": "田",
    "RadicalIndex": "102.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6992,
    "FrequencyRank": undefined
  },
  {
    "Character": "竘",
    "Pinyin": "qǔ",
    "Definition": undefined,
    "Radical": "立",
    "RadicalIndex": "117.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7044,
    "FrequencyRank": undefined
  },
  {
    "Character": "砫",
    "Pinyin": "zhù",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6970,
    "FrequencyRank": 9388
  },
  {
    "Character": "哱",
    "Pinyin": "bō",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6987,
    "FrequencyRank": undefined
  },
  {
    "Character": "祯",
    "Pinyin": "zhēn",
    "Definition": "lucky, auspicious, good omen",
    "Radical": "示",
    "RadicalIndex": "113.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4673,
    "FrequencyRank": 3838
  },
  {
    "Character": "蚪",
    "Pinyin": "dǒu",
    "Definition": "tadpole",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1902,
    "FrequencyRank": 4043
  },
  {
    "Character": "埕",
    "Pinyin": "chéng",
    "Definition": "a large, pear-shaped earthenware jar",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4414,
    "FrequencyRank": 6958
  },
  {
    "Character": "厝",
    "Pinyin": "cuò",
    "Definition": "cut or engrave; a grave or tombstone",
    "Radical": "厂",
    "RadicalIndex": "27.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4471,
    "FrequencyRank": 4839
  },
  {
    "Character": "贽",
    "Pinyin": "zhì",
    "Definition": "gift superior; gift given",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4423,
    "FrequencyRank": 5402
  },
  {
    "Character": "倏",
    "Pinyin": "shū",
    "Definition": "hastily, suddenly, abruptly",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4568,
    "FrequencyRank": 2796
  },
  {
    "Character": "恝",
    "Pinyin": "jiá",
    "Definition": "carefree; indifferent manner; without sorrow",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6925,
    "FrequencyRank": 7163
  },
  {
    "Character": "趵",
    "Pinyin": "bào",
    "Definition": "noise of tramping feet",
    "Radical": "足",
    "RadicalIndex": "157.3",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4504,
    "FrequencyRank": 6118
  },
  {
    "Character": "疱",
    "Pinyin": "pào",
    "Definition": "acne",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4619,
    "FrequencyRank": 4113
  },
  {
    "Character": "倌",
    "Pinyin": "guān",
    "Definition": "assistant in wine shop, groom",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4576,
    "FrequencyRank": 4255
  },
  {
    "Character": "铍",
    "Pinyin": "pī",
    "Definition": "beryllium",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4545,
    "FrequencyRank": 5709
  },
  {
    "Character": "阃",
    "Pinyin": "kǔn",
    "Definition": undefined,
    "Radical": "門",
    "RadicalIndex": "169.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4629,
    "FrequencyRank": 5356
  },
  {
    "Character": "狳",
    "Pinyin": "yú",
    "Definition": undefined,
    "Radical": "犬",
    "RadicalIndex": "94.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4603,
    "FrequencyRank": 6644
  },
  {
    "Character": "釜",
    "Pinyin": "fǔ",
    "Definition": "cauldron, pot, kettle",
    "Radical": "金",
    "RadicalIndex": "167.2",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4586,
    "FrequencyRank": 3786
  },
  {
    "Character": "倬",
    "Pinyin": "zhuō",
    "Definition": "noticeable, large; clear, distinct",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4567,
    "FrequencyRank": 5720
  },
  {
    "Character": "胲",
    "Pinyin": "hǎi",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7032,
    "FrequencyRank": 7338
  },
  {
    "Character": "唑",
    "Pinyin": "zuò",
    "Definition": "(chem.) azole",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4519,
    "FrequencyRank": 4349
  },
  {
    "Character": "逖",
    "Pinyin": "tì",
    "Definition": "far, distant; keep at distance",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4606,
    "FrequencyRank": 5257
  },
  {
    "Character": "垾",
    "Pinyin": "hàn",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6943,
    "FrequencyRank": undefined
  },
  {
    "Character": "痃",
    "Pinyin": "xuán",
    "Definition": "indigestion; buboes, lymphatic inflammation",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7043,
    "FrequencyRank": 8942
  },
  {
    "Character": "畛",
    "Pinyin": "zhěn",
    "Definition": "border, boundary; raised path",
    "Radical": "田",
    "RadicalIndex": "102.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4506,
    "FrequencyRank": 5466
  },
  {
    "Character": "倓",
    "Pinyin": "tán",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7020,
    "FrequencyRank": undefined
  },
  {
    "Character": "铉",
    "Pinyin": "xuàn",
    "Definition": "device for carrying a tripod",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4541,
    "FrequencyRank": 4809
  },
  {
    "Character": "梠",
    "Pinyin": "lǚ",
    "Definition": "a small beam supporting the rafters at the eaves",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6961,
    "FrequencyRank": undefined
  },
  {
    "Character": "疴",
    "Pinyin": "kē",
    "Definition": "illness, sickness, disease; pain",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4615,
    "FrequencyRank": 5773
  },
  {
    "Character": "舀",
    "Pinyin": "yǎo",
    "Definition": "dip, ladle; ladle",
    "Radical": "臼",
    "RadicalIndex": "134.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1986,
    "FrequencyRank": 4302
  },
  {
    "Character": "凋",
    "Pinyin": "diāo",
    "Definition": "be withered, fallen; exhausted",
    "Radical": "冫",
    "RadicalIndex": "15.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4623,
    "FrequencyRank": 3675
  },
  {
    "Character": "栟",
    "Pinyin": "bēn",
    "Definition": "hemp palm",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4461,
    "FrequencyRank": undefined
  },
  {
    "Character": "涄",
    "Pinyin": "pīng",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7058,
    "FrequencyRank": undefined
  },
  {
    "Character": "垿",
    "Pinyin": "xù",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6946,
    "FrequencyRank": undefined
  },
  {
    "Character": "宧",
    "Pinyin": "yí",
    "Definition": "corner",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7071,
    "FrequencyRank": undefined
  },
  {
    "Character": "蚨",
    "Pinyin": "fú",
    "Definition": "a kind of water-beetle cash, then dollars and money generally",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4507,
    "FrequencyRank": 4967
  },
  {
    "Character": "秣",
    "Pinyin": "mò",
    "Definition": "fodder, horse feed; feed horse",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4553,
    "FrequencyRank": 4903
  },
  {
    "Character": "桊",
    "Pinyin": "juàn",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7047,
    "FrequencyRank": undefined
  },
  {
    "Character": "旃",
    "Pinyin": "zhān",
    "Definition": "silk banner with bent pole",
    "Radical": "方",
    "RadicalIndex": "70.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4628,
    "FrequencyRank": 5181
  },
  {
    "Character": "疸",
    "Pinyin": "dǎn",
    "Definition": "jaundice; disorders of stomach",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4616,
    "FrequencyRank": 4533
  },
  {
    "Character": "朓",
    "Pinyin": "tiǎo",
    "Definition": "sacrifice",
    "Radical": "月",
    "RadicalIndex": "74.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7031,
    "FrequencyRank": undefined
  },
  {
    "Character": "窈",
    "Pinyin": "yǎo",
    "Definition": "obscure, secluded; refined",
    "Radical": "穴",
    "RadicalIndex": "116.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4666,
    "FrequencyRank": 4541
  },
  {
    "Character": "涟",
    "Pinyin": "lián",
    "Definition": "flowing water; ripples; weeping",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4647,
    "FrequencyRank": 3889
  },
  {
    "Character": "卿",
    "Pinyin": "qīng",
    "Definition": "noble, high officer",
    "Radical": "卩",
    "RadicalIndex": "26.9",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2006,
    "FrequencyRank": 2029
  },
  {
    "Character": "朔",
    "Pinyin": "shuò",
    "Definition": "first day of lunar month; the north",
    "Radical": "月",
    "RadicalIndex": "74.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4635,
    "FrequencyRank": 3111
  },
  {
    "Character": "疰",
    "Pinyin": "zhù",
    "Definition": undefined,
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7042,
    "FrequencyRank": 7626
  },
  {
    "Character": "趿",
    "Pinyin": "tā",
    "Definition": "to tread on; slipshod",
    "Radical": "足",
    "RadicalIndex": "157.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4505,
    "FrequencyRank": 5238
  },
  {
    "Character": "凇",
    "Pinyin": "sōng",
    "Definition": "dewdrop; icicle",
    "Radical": "冫",
    "RadicalIndex": "15.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4610,
    "FrequencyRank": 4506
  },
  {
    "Character": "哧",
    "Pinyin": "chī",
    "Definition": "sound of ripping or giggling",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4498,
    "FrequencyRank": 3632
  },
  {
    "Character": "唛",
    "Pinyin": "mà",
    "Definition": "mark",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4494,
    "FrequencyRank": 6833
  },
  {
    "Character": "脒",
    "Pinyin": "mǐ",
    "Definition": "open; throw away",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4596,
    "FrequencyRank": 6699
  },
  {
    "Character": "铈",
    "Pinyin": "shì",
    "Definition": "cerium",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4540,
    "FrequencyRank": 6462
  },
  {
    "Character": "胭",
    "Pinyin": "yān",
    "Definition": "rouge, cosmetics",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4592,
    "FrequencyRank": 4247
  },
  {
    "Character": "涍",
    "Pinyin": "xiào",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7054,
    "FrequencyRank": undefined
  },
  {
    "Character": "罟",
    "Pinyin": "gǔ",
    "Definition": "net; snare; pressure come or go",
    "Radical": "网",
    "RadicalIndex": "122.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4523,
    "FrequencyRank": 5741
  },
  {
    "Character": "氤",
    "Pinyin": "yīn",
    "Definition": "hanging fog, misty",
    "Radical": "气",
    "RadicalIndex": "84.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4549,
    "FrequencyRank": 4841
  },
  {
    "Character": "袪",
    "Pinyin": "qū",
    "Definition": "sleeves; cuff",
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7077,
    "FrequencyRank": 6732
  },
  {
    "Character": "晅",
    "Pinyin": "xuǎn",
    "Definition": "light of the sun; to dry in the sun",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6983,
    "FrequencyRank": undefined
  },
  {
    "Character": "鸲",
    "Pinyin": "qú",
    "Definition": "mynah; Erithacus species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4600,
    "FrequencyRank": 6062
  },
  {
    "Character": "倮",
    "Pinyin": "luǒ",
    "Definition": "bare, naked, uncovered",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7016,
    "FrequencyRank": 5652
  },
  {
    "Character": "桄",
    "Pinyin": "guāng",
    "Definition": "a coir-palm",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6960,
    "FrequencyRank": 6990
  },
  {
    "Character": "绤",
    "Pinyin": "xì",
    "Definition": "cloth",
    "Radical": "糸",
    "RadicalIndex": "120.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7095,
    "FrequencyRank": 8618
  },
  {
    "Character": "砼",
    "Pinyin": "tóng",
    "Definition": "concrete",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4479,
    "FrequencyRank": 6246
  },
  {
    "Character": "𫐓",
    "Pinyin": "bù",
    "Definition": "outer rim of a wheel, felly",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7513,
    "FrequencyRank": undefined
  },
  {
    "Character": "涢",
    "Pinyin": "yún",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7059,
    "FrequencyRank": undefined
  },
  {
    "Character": "眢",
    "Pinyin": "yuān",
    "Definition": "eyes without brightness",
    "Radical": "目",
    "RadicalIndex": "109.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7038,
    "FrequencyRank": 6436
  },
  {
    "Character": "倻",
    "Pinyin": "yē",
    "Definition": "phonetic used in Korean place names",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7013,
    "FrequencyRank": undefined
  },
  {
    "Character": "玺",
    "Pinyin": "xǐ",
    "Definition": "imperial signet, royal signet",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4599,
    "FrequencyRank": 3758
  },
  {
    "Character": "哽",
    "Pinyin": "gěng",
    "Definition": "choke (with grief)",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4499,
    "FrequencyRank": 3694
  },
  {
    "Character": "辀",
    "Pinyin": "zhōu",
    "Definition": "shaft",
    "Radical": "車",
    "RadicalIndex": "159.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6977,
    "FrequencyRank": 6996
  },
  {
    "Character": "奘",
    "Pinyin": "zàng",
    "Definition": "large, powerful, stout, thick",
    "Radical": "大",
    "RadicalIndex": "37.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4683,
    "FrequencyRank": 4545
  },
  {
    "Character": "笏",
    "Pinyin": "hù",
    "Definition": "tablet held by someone having authority",
    "Radical": "竹",
    "RadicalIndex": "118.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4559,
    "FrequencyRank": 4859
  },
  {
    "Character": "衾",
    "Pinyin": "qīn",
    "Definition": "coverlet, quilt",
    "Radical": "衣",
    "RadicalIndex": "145.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4588,
    "FrequencyRank": 5103
  },
  {
    "Character": "谇",
    "Pinyin": "suì",
    "Definition": "speak ill of, vilify; berate",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4678,
    "FrequencyRank": 5590
  },
  {
    "Character": "绦",
    "Pinyin": "tāo",
    "Definition": "silk cord, ribbon",
    "Radical": "糸",
    "RadicalIndex": "120.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4704,
    "FrequencyRank": 4908
  },
  {
    "Character": "恣",
    "Pinyin": "zì",
    "Definition": "indulge oneself, unrestrained",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4625,
    "FrequencyRank": 3968
  },
  {
    "Character": "珫",
    "Pinyin": "chōng",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6935,
    "FrequencyRank": undefined
  },
  {
    "Character": "蚄",
    "Pinyin": "fāng",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6993,
    "FrequencyRank": undefined
  },
  {
    "Character": "氦",
    "Pinyin": "hài",
    "Definition": "helium",
    "Radical": "气",
    "RadicalIndex": "84.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4550,
    "FrequencyRank": 3972
  },
  {
    "Character": "狺",
    "Pinyin": "yín",
    "Definition": "the snarling of dogs",
    "Radical": "犬",
    "RadicalIndex": "94.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4605,
    "FrequencyRank": 6169
  },
  {
    "Character": "敉",
    "Pinyin": "mǐ",
    "Definition": "pacify, soothe, stabilize",
    "Radical": "攴",
    "RadicalIndex": "66.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7048,
    "FrequencyRank": 6086
  },
  {
    "Character": "钲",
    "Pinyin": "zhēng",
    "Definition": "kind of gong used in ancient times by troops on the march",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4528,
    "FrequencyRank": 6935
  },
  {
    "Character": "皋",
    "Pinyin": "gāo",
    "Definition": "the high land along a river",
    "Radical": "白",
    "RadicalIndex": "106.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4579,
    "FrequencyRank": 3983
  },
  {
    "Character": "屐",
    "Pinyin": "jī",
    "Definition": "wooden shoes, clogs",
    "Radical": "尸",
    "RadicalIndex": "44.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4679,
    "FrequencyRank": 4991
  },
  {
    "Character": "莆",
    "Pinyin": "pú",
    "Definition": "a kind of legendary tree",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4428,
    "FrequencyRank": 4927
  },
  {
    "Character": "涧",
    "Pinyin": "jiàn",
    "Definition": "brook, mountain stream",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2082,
    "FrequencyRank": 3828
  },
  {
    "Character": "烬",
    "Pinyin": "jìn",
    "Definition": "cinders, ashes, embers; remnants",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4643,
    "FrequencyRank": 3670
  },
  {
    "Character": "勍",
    "Pinyin": "qíng",
    "Definition": "strong, powerful, mighty; violent",
    "Radical": "力",
    "RadicalIndex": "19.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7040,
    "FrequencyRank": undefined
  },
  {
    "Character": "袗",
    "Pinyin": "zhěn",
    "Definition": "unlined garments; hem of garment",
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7078,
    "FrequencyRank": 8386
  },
  {
    "Character": "俶",
    "Pinyin": "chù",
    "Definition": "start, begin; beginning; arrange",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4566,
    "FrequencyRank": undefined
  },
  {
    "Character": "翃",
    "Pinyin": "hóng",
    "Definition": "fly",
    "Radical": "羽",
    "RadicalIndex": "124.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6974,
    "FrequencyRank": 7268
  },
  {
    "Character": "鸫",
    "Pinyin": "dōng",
    "Definition": "thrush; Turdus species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4487,
    "FrequencyRank": 5627
  },
  {
    "Character": "桓",
    "Pinyin": "huán",
    "Definition": "variety of tree; surname",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4450,
    "FrequencyRank": 2541
  },
  {
    "Character": "绠",
    "Pinyin": "gěng",
    "Definition": "well rope",
    "Radical": "糸",
    "RadicalIndex": "120.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4699,
    "FrequencyRank": 7162
  },
  {
    "Character": "崁",
    "Pinyin": "kàn",
    "Definition": "a place in Taiwan Tainan",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6997,
    "FrequencyRank": undefined
  },
  {
    "Character": "冥",
    "Pinyin": "míng",
    "Definition": "dark, gloomy, night; deep",
    "Radical": "冖",
    "RadicalIndex": "14.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2121,
    "FrequencyRank": 2739
  },
  {
    "Character": "觊",
    "Pinyin": "jì",
    "Definition": "covet, long for, desire",
    "Radical": "見",
    "RadicalIndex": "147.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4525,
    "FrequencyRank": 4591
  },
  {
    "Character": "笕",
    "Pinyin": "jiǎn",
    "Definition": "bamboo water pipe",
    "Radical": "竹",
    "RadicalIndex": "118.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4557,
    "FrequencyRank": 4867
  },
  {
    "Character": "珖",
    "Pinyin": "guàng",
    "Definition": "carat",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6931,
    "FrequencyRank": undefined
  },
  {
    "Character": "绨",
    "Pinyin": "tí",
    "Definition": "coarse pongee, kind of heavy silk",
    "Radical": "糸",
    "RadicalIndex": "120.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4705,
    "FrequencyRank": 5811
  },
  {
    "Character": "鬯",
    "Pinyin": "chàng",
    "Definition": "sacrificial wine; unhindered",
    "Radical": "鬯",
    "RadicalIndex": "192",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7028,
    "FrequencyRank": 5573
  },
  {
    "Character": "钽",
    "Pinyin": "tǎn",
    "Definition": "tantalum",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4533,
    "FrequencyRank": 6162
  },
  {
    "Character": "砠",
    "Pinyin": "jū",
    "Definition": "rocky, hilly, uneven",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6969,
    "FrequencyRank": undefined
  },
  {
    "Character": "逡",
    "Pinyin": "qūn",
    "Definition": "retreat, withdraw, fall back",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4698,
    "FrequencyRank": 4984
  },
  {
    "Character": "悛",
    "Pinyin": "quān",
    "Definition": "repent, reform",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4664,
    "FrequencyRank": 6250
  },
  {
    "Character": "铋",
    "Pinyin": "bì",
    "Definition": "bismuth",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4543,
    "FrequencyRank": 5886
  },
  {
    "Character": "酐",
    "Pinyin": "gān",
    "Definition": "anhydride",
    "Radical": "酉",
    "RadicalIndex": "164.3",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4469,
    "FrequencyRank": 5848
  },
  {
    "Character": "砹",
    "Pinyin": "ài",
    "Definition": "astatine",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4474,
    "FrequencyRank": 5772
  },
  {
    "Character": "娴",
    "Pinyin": "xián",
    "Definition": "elegant, refined; skillful",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4693,
    "FrequencyRank": 3209
  },
  {
    "Character": "鸬",
    "Pinyin": "lú",
    "Definition": "cormorant",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4490,
    "FrequencyRank": 4640
  },
  {
    "Character": "栗",
    "Pinyin": "lì",
    "Definition": "chestnut tree, chestnuts; surname",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1855,
    "FrequencyRank": 2595
  },
  {
    "Character": "栲",
    "Pinyin": "kǎo",
    "Definition": "mangrove",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4447,
    "FrequencyRank": 6418
  },
  {
    "Character": "旆",
    "Pinyin": "pèi",
    "Definition": "flag ornament; flags, banners",
    "Radical": "方",
    "RadicalIndex": "70.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4626,
    "FrequencyRank": 5436
  },
  {
    "Character": "浯",
    "Pinyin": "wú",
    "Definition": "name of a river in Shandong",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4645,
    "FrequencyRank": 5357
  },
  {
    "Character": "蚋",
    "Pinyin": "ruì",
    "Definition": "gnat, mosquito",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4510,
    "FrequencyRank": 5218
  },
  {
    "Character": "埆",
    "Pinyin": "què",
    "Definition": "stony",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6945,
    "FrequencyRank": undefined
  },
  {
    "Character": "珢",
    "Pinyin": "yín",
    "Definition": "precious stone",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6938,
    "FrequencyRank": undefined
  },
  {
    "Character": "哩",
    "Pinyin": "lī",
    "Definition": "mile",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1905,
    "FrequencyRank": 1949
  },
  {
    "Character": "峿",
    "Pinyin": "yǔ",
    "Definition": "mountain",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6998,
    "FrequencyRank": undefined
  },
  {
    "Character": "宸",
    "Pinyin": "chén",
    "Definition": "imperial; imperial palace",
    "Radical": "宀",
    "RadicalIndex": "40.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4665,
    "FrequencyRank": 4360
  },
  {
    "Character": "埂",
    "Pinyin": "gěng",
    "Definition": "ditches for irrigation; hole",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1784,
    "FrequencyRank": 4239
  },
  {
    "Character": "舯",
    "Pinyin": "zhōng",
    "Definition": undefined,
    "Radical": "舟",
    "RadicalIndex": "137.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7025,
    "FrequencyRank": 6172
  },
  {
    "Character": "娌",
    "Pinyin": "lǐ",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4689,
    "FrequencyRank": 5049
  },
  {
    "Character": "倪",
    "Pinyin": "ní",
    "Definition": "feeble, tiny, young and weak",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4571,
    "FrequencyRank": 3257
  },
  {
    "Character": "莞",
    "Pinyin": "guǎn",
    "Definition": "smiling; a kind of aquatic herb, clubrush, Scirpus lacustris",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4443,
    "FrequencyRank": 4260
  },
  {
    "Character": "袒",
    "Pinyin": "tǎn",
    "Definition": "strip; lay bare; bared; naked",
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4671,
    "FrequencyRank": 3566
  },
  {
    "Character": "栻",
    "Pinyin": "shì",
    "Definition": "tree",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6957,
    "FrequencyRank": undefined
  },
  {
    "Character": "莘",
    "Pinyin": "shēn",
    "Definition": "long; numerous; a marsh plant whose root is used for medicine",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4441,
    "FrequencyRank": 4432
  },
  {
    "Character": "悯",
    "Pinyin": "mǐn",
    "Definition": "pity, sympathize with, grieve for",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2095,
    "FrequencyRank": 2968
  },
  {
    "Character": "恚",
    "Pinyin": "huì",
    "Definition": "anger, rage",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4412,
    "FrequencyRank": 3951
  },
  {
    "Character": "砝",
    "Pinyin": "fá",
    "Definition": "balance weights",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4473,
    "FrequencyRank": 5036
  },
  {
    "Character": "眬",
    "Pinyin": "lóng",
    "Definition": "faint, fuzzy, blurred",
    "Radical": "目",
    "RadicalIndex": "109.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4493,
    "FrequencyRank": 6103
  },
  {
    "Character": "崃",
    "Pinyin": "lái",
    "Definition": "mountain in Sichuan province",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4521,
    "FrequencyRank": 5446
  },
  {
    "Character": "悃",
    "Pinyin": "kǔn",
    "Definition": "sincere, genuine, honest, loyal",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7068,
    "FrequencyRank": 6583
  },
  {
    "Character": "埙",
    "Pinyin": "xūn",
    "Definition": "instrument",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4416,
    "FrequencyRank": 5548
  },
  {
    "Character": "砮",
    "Pinyin": "nǔ",
    "Definition": "arrow-tip",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7087,
    "FrequencyRank": 8223
  },
  {
    "Character": "铂",
    "Pinyin": "bó",
    "Definition": "platinum; thin sheet of metal",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4537,
    "FrequencyRank": 4697
  },
  {
    "Character": "哿",
    "Pinyin": "gě",
    "Definition": "excellent; to commend to be able to",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7089,
    "FrequencyRank": 6050
  },
  {
    "Character": "烻",
    "Pinyin": "yàn",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7052,
    "FrequencyRank": undefined
  },
  {
    "Character": "砻",
    "Pinyin": "lóng",
    "Definition": "grind, sharpen; mill",
    "Radical": "龍",
    "RadicalIndex": "212.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4483,
    "FrequencyRank": 5566
  },
  {
    "Character": "狴",
    "Pinyin": "bì",
    "Definition": "a kind of tapir; a fierce beast depicted on the door of prisons",
    "Radical": "犬",
    "RadicalIndex": "94.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7035,
    "FrequencyRank": 6575
  },
  {
    "Character": "邕",
    "Pinyin": "yōng",
    "Definition": "former or literary name for Nanning (in Guangxi)",
    "Radical": "邑",
    "RadicalIndex": "163.3",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4707,
    "FrequencyRank": 4695
  },
  {
    "Character": "痄",
    "Pinyin": "zhà",
    "Definition": "scrofulous swellings and sores, mumps",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7041,
    "FrequencyRank": 7731
  },
  {
    "Character": "蚣",
    "Pinyin": "gōng",
    "Definition": "centipede",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1900,
    "FrequencyRank": 4109
  },
  {
    "Character": "辁",
    "Pinyin": "quán",
    "Definition": "cart wheel with no spokes",
    "Radical": "車",
    "RadicalIndex": "159.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6978,
    "FrequencyRank": 8504
  },
  {
    "Character": "铎",
    "Pinyin": "duó",
    "Definition": "bell; surname",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4547,
    "FrequencyRank": 3688
  },
  {
    "Character": "倩",
    "Pinyin": "qiàn",
    "Definition": "beautiful, lovely; son-in-law",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4562,
    "FrequencyRank": 2583
  },
  {
    "Character": "悚",
    "Pinyin": "sǒng",
    "Definition": "afraid, scared, frightened",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4659,
    "FrequencyRank": 3552
  },
  {
    "Character": "剟",
    "Pinyin": "duō",
    "Definition": "to prick; to cut blocks, to engrave",
    "Radical": "刀",
    "RadicalIndex": "18.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7092,
    "FrequencyRank": undefined
  },
  {
    "Character": "谄",
    "Pinyin": "chǎn",
    "Definition": "flatter, truckle, toady",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4677,
    "FrequencyRank": 4145
  },
  {
    "Character": "桉",
    "Pinyin": "ān",
    "Definition": "eucalyptus",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4462,
    "FrequencyRank": 5278
  },
  {
    "Character": "饽",
    "Pinyin": "bō",
    "Definition": "ferrule",
    "Radical": "食",
    "RadicalIndex": "184.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4609,
    "FrequencyRank": 4490
  },
  {
    "Character": "郫",
    "Pinyin": "pí",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4580,
    "FrequencyRank": 6636
  },
  {
    "Character": "娠",
    "Pinyin": "shēn",
    "Definition": "pregnant",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4688,
    "FrequencyRank": 3590
  },
  {
    "Character": "眩",
    "Pinyin": "xuàn",
    "Definition": "to confuse; dizzy, dazed, disoriented",
    "Radical": "目",
    "RadicalIndex": "109.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4496,
    "FrequencyRank": 2909
  },
  {
    "Character": "郸",
    "Pinyin": "dān",
    "Definition": "county in Hebei province",
    "Radical": "邑",
    "RadicalIndex": "163.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4636,
    "FrequencyRank": 4045
  },
  {
    "Character": "唣",
    "Pinyin": "zào",
    "Definition": "chatter",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4516,
    "FrequencyRank": 6753
  },
  {
    "Character": "埃",
    "Pinyin": "āi",
    "Definition": "fine dust, dirt",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1814,
    "FrequencyRank": 1121
  },
  {
    "Character": "瓞",
    "Pinyin": "dié",
    "Definition": "young melons just forming",
    "Radical": "瓜",
    "RadicalIndex": "97.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7027,
    "FrequencyRank": 8020
  },
  {
    "Character": "谆",
    "Pinyin": "zhūn",
    "Definition": "patient, earnest; earnestly",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2126,
    "FrequencyRank": 4027
  },
  {
    "Character": "钿",
    "Pinyin": "diàn",
    "Definition": "hairpin; gold inlaid work, filigree",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4535,
    "FrequencyRank": 5171
  },
  {
    "Character": "虓",
    "Pinyin": "xiāo",
    "Definition": "roar",
    "Radical": "虍",
    "RadicalIndex": "141.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7033,
    "FrequencyRank": undefined
  },
  {
    "Character": "唝",
    "Pinyin": "gòng",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6985,
    "FrequencyRank": undefined
  },
  {
    "Character": "烔",
    "Pinyin": "tóng",
    "Definition": "heat",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7050,
    "FrequencyRank": undefined
  },
  {
    "Character": "恙",
    "Pinyin": "yàng",
    "Definition": "illness, sickness; indisposition",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4633,
    "FrequencyRank": 3728
  },
  {
    "Character": "鸳",
    "Pinyin": "yuān",
    "Definition": "male mandarin duck (Aix galericulata)",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2010,
    "FrequencyRank": 3866
  },
  {
    "Character": "脩",
    "Pinyin": "xiū",
    "Definition": "dried meat (used as teachers payment in ancient times)",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7015,
    "FrequencyRank": 6588
  },
  {
    "Character": "烶",
    "Pinyin": "tǐng",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7051,
    "FrequencyRank": undefined
  },
  {
    "Character": "烙",
    "Pinyin": "lào",
    "Definition": "brand, burn; branding iron",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2063,
    "FrequencyRank": 3440
  },
  {
    "Character": "浥",
    "Pinyin": "yì",
    "Definition": "moist, wet, dampen",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4653,
    "FrequencyRank": undefined
  },
  {
    "Character": "浠",
    "Pinyin": "xī",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4656,
    "FrequencyRank": 5125
  },
  {
    "Character": "晖",
    "Pinyin": "huī",
    "Definition": "sunshine; light, bright, radiant",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6991,
    "FrequencyRank": 3489
  },
  {
    "Character": "涐",
    "Pinyin": "é",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7060,
    "FrequencyRank": undefined
  },
  {
    "Character": "砺",
    "Pinyin": "lì",
    "Definition": "whetstone; sharpen",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4475,
    "FrequencyRank": 4760
  },
  {
    "Character": "鸵",
    "Pinyin": "tuó",
    "Definition": "ostrich",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2008,
    "FrequencyRank": 4322
  },
  {
    "Character": "秫",
    "Pinyin": "shú",
    "Definition": "glutinous variety of millet",
    "Radical": "禾",
    "RadicalIndex": "115.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4554,
    "FrequencyRank": 5884
  },
  {
    "Character": "蚝",
    "Pinyin": "háo",
    "Definition": "hairy and poisonous caterpillars",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4512,
    "FrequencyRank": 4882
  },
  {
    "Character": "诿",
    "Pinyin": "wěi",
    "Definition": "pass buck, lay blame on others",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4674,
    "FrequencyRank": 4296
  },
  {
    "Character": "浭",
    "Pinyin": "gēng",
    "Definition": "river in Hebei province; (Cant.) a ford; to wade",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7056,
    "FrequencyRank": undefined
  },
  {
    "Character": "倨",
    "Pinyin": "jù",
    "Definition": "arrogant, haughty, rude",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4581,
    "FrequencyRank": 5167
  },
  {
    "Character": "荽",
    "Pinyin": "suī",
    "Definition": "coriander",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4438,
    "FrequencyRank": 5715
  },
  {
    "Character": "甡",
    "Pinyin": "shēn",
    "Definition": "abundant, numerous; crowd",
    "Radical": "生",
    "RadicalIndex": "100.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7011,
    "FrequencyRank": undefined
  },
  {
    "Character": "哢",
    "Pinyin": "lòng",
    "Definition": "syllable",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6982,
    "FrequencyRank": undefined
  },
  {
    "Character": "浡",
    "Pinyin": "bó",
    "Definition": "burst forth; rise; vigorous",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7055,
    "FrequencyRank": undefined
  },
  {
    "Character": "酎",
    "Pinyin": "zhòu",
    "Definition": "double-fermented wine, vintage wine",
    "Radical": "酉",
    "RadicalIndex": "164.3",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6965,
    "FrequencyRank": 7582
  },
  {
    "Character": "钹",
    "Pinyin": "bó",
    "Definition": "cymbals",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4531,
    "FrequencyRank": 5312
  },
  {
    "Character": "偌",
    "Pinyin": "ruò",
    "Definition": "thus, so, like, such",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4564,
    "FrequencyRank": 3985
  },
  {
    "Character": "埔",
    "Pinyin": "bù",
    "Definition": "plain, arena; port, market",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4413,
    "FrequencyRank": 3124
  },
  {
    "Character": "倴",
    "Pinyin": "bèn",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7014,
    "FrequencyRank": undefined
  },
  {
    "Character": "蚜",
    "Pinyin": "yá",
    "Definition": "plant louse, aphids",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4508,
    "FrequencyRank": 4415
  },
  {
    "Character": "峪",
    "Pinyin": "yù",
    "Definition": "valley, ravine",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4524,
    "FrequencyRank": 4323
  },
  {
    "Character": "珒",
    "Pinyin": "jīn",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6936,
    "FrequencyRank": undefined
  },
  {
    "Character": "隼",
    "Pinyin": "sǔn",
    "Definition": "aquiline (nose); a falcon",
    "Radical": "隹",
    "RadicalIndex": "172.2",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4574,
    "FrequencyRank": 4094
  },
  {
    "Character": "堲",
    "Pinyin": "cí",
    "Definition": "hate",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7082,
    "FrequencyRank": undefined
  },
  {
    "Character": "耄",
    "Pinyin": "mào",
    "Definition": "elderly person; very old, senile",
    "Radical": "老",
    "RadicalIndex": "125.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4420,
    "FrequencyRank": 4373
  },
  {
    "Character": "涠",
    "Pinyin": "wéi",
    "Definition": "still water",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4650,
    "FrequencyRank": 6083
  },
  {
    "Character": "莨",
    "Pinyin": "làng",
    "Definition": "herb, Scopolia japonica",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4444,
    "FrequencyRank": 6154
  },
  {
    "Character": "痉",
    "Pinyin": "jìng",
    "Definition": "convulsions, fits",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4621,
    "FrequencyRank": 3430
  },
  {
    "Character": "毪",
    "Pinyin": "mú",
    "Definition": "serge from Tibet",
    "Radical": "毛",
    "RadicalIndex": "82.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4551,
    "FrequencyRank": undefined
  },
  {
    "Character": "笄",
    "Pinyin": "jī",
    "Definition": "hairpin; fifteen year old girl",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4556,
    "FrequencyRank": 5703
  },
  {
    "Character": "俾",
    "Pinyin": "bǐ",
    "Definition": "so that, in order that; to cause; (Cant.) to give (synonymous with Mandarin 給)",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4572,
    "FrequencyRank": 3519
  },
  {
    "Character": "斋",
    "Pinyin": "zhāi",
    "Definition": "vegetarian diet; study",
    "Radical": "文",
    "RadicalIndex": "67.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2029,
    "FrequencyRank": 2404
  },
  {
    "Character": "朕",
    "Pinyin": "zhèn",
    "Definition": undefined,
    "Radical": "月",
    "RadicalIndex": "74.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4595,
    "FrequencyRank": 3054
  },
  {
    "Character": "诹",
    "Pinyin": "zōu",
    "Definition": "consult, confer; select, choose",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4668,
    "FrequencyRank": 5004
  },
  {
    "Character": "铊",
    "Pinyin": "tā",
    "Definition": "thallium",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4542,
    "FrequencyRank": 6491
  },
  {
    "Character": "砥",
    "Pinyin": "dǐ",
    "Definition": "a whetstone; to polish",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4480,
    "FrequencyRank": 4166
  },
  {
    "Character": "娟",
    "Pinyin": "juān",
    "Definition": "beautiful, graceful",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2141,
    "FrequencyRank": 2564
  },
  {
    "Character": "羔",
    "Pinyin": "gāo",
    "Definition": "lamb, kid",
    "Radical": "羊",
    "RadicalIndex": "123.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2050,
    "FrequencyRank": 3604
  },
  {
    "Character": "鱽",
    "Pinyin": "dāo",
    "Definition": "the mullet",
    "Radical": "魚",
    "RadicalIndex": "195.2",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7034,
    "FrequencyRank": 8736
  },
  {
    "Character": "猃",
    "Pinyin": "xiǎn",
    "Definition": "dog",
    "Radical": "犬",
    "RadicalIndex": "94.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4604,
    "FrequencyRank": 7439
  },
  {
    "Character": "酏",
    "Pinyin": "yǐ",
    "Definition": "millet wine",
    "Radical": "酉",
    "RadicalIndex": "164.3",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6966,
    "FrequencyRank": undefined
  },
  {
    "Character": "莴",
    "Pinyin": "wō",
    "Definition": "lettuce",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4430,
    "FrequencyRank": 5240
  },
  {
    "Character": "莓",
    "Pinyin": "méi",
    "Definition": "moss; edible berries",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4433,
    "FrequencyRank": 4076
  },
  {
    "Character": "隺",
    "Pinyin": "hú",
    "Definition": "a bird flying high ambition",
    "Radical": "隹",
    "RadicalIndex": "172.2",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7081,
    "FrequencyRank": 8800
  },
  {
    "Character": "骊",
    "Pinyin": "lí",
    "Definition": "a pure black horse; a pair of horses",
    "Radical": "馬",
    "RadicalIndex": "187.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4700,
    "FrequencyRank": 4826
  },
  {
    "Character": "𪟝",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "力",
    "RadicalIndex": "19.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6932,
    "FrequencyRank": undefined
  },
  {
    "Character": "晁",
    "Pinyin": "cháo",
    "Definition": "morning, dawn; surname",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4501,
    "FrequencyRank": 4393
  },
  {
    "Character": "砧",
    "Pinyin": "zhēn",
    "Definition": "anvil; flat stone; exe",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4476,
    "FrequencyRank": 4371
  },
  {
    "Character": "莠",
    "Pinyin": "yǒu",
    "Definition": "weeds, tares; undesirable, evil",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4432,
    "FrequencyRank": 5241
  },
  {
    "Character": "桠",
    "Pinyin": "yā",
    "Definition": "the forking branch of a tree",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6958,
    "FrequencyRank": 4832
  },
  {
    "Character": "氩",
    "Pinyin": "yà",
    "Definition": "argonium",
    "Radical": "气",
    "RadicalIndex": "84.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4548,
    "FrequencyRank": 4914
  },
  {
    "Character": "𫑡",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6995,
    "FrequencyRank": undefined
  },
  {
    "Character": "桕",
    "Pinyin": "jiù",
    "Definition": "tallow tree",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4457,
    "FrequencyRank": 6251
  },
  {
    "Character": "悝",
    "Pinyin": "kuī",
    "Definition": "to laugh at to pity; afflicted, sad",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4661,
    "FrequencyRank": 6177
  },
  {
    "Character": "蚬",
    "Pinyin": "xiǎn",
    "Definition": "a variety of bivalves",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4511,
    "FrequencyRank": 5664
  },
  {
    "Character": "悌",
    "Pinyin": "tì",
    "Definition": "brotherly, respectful",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4663,
    "FrequencyRank": 4941
  },
  {
    "Character": "悭",
    "Pinyin": "qiān",
    "Definition": "miserly, parsimonious, stingy",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4660,
    "FrequencyRank": 5480
  },
  {
    "Character": "盎",
    "Pinyin": "àng",
    "Definition": "cup; pot; bowl; abundant",
    "Radical": "皿",
    "RadicalIndex": "108.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4518,
    "FrequencyRank": 3410
  },
  {
    "Character": "垸",
    "Pinyin": "yuàn",
    "Definition": "a dyke, embankment",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4424,
    "FrequencyRank": 6677
  },
  {
    "Character": "莸",
    "Pinyin": "yóu",
    "Definition": "caryopteris divaricata",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4439,
    "FrequencyRank": 5354
  },
  {
    "Character": "浟",
    "Pinyin": "yóu",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7062,
    "FrequencyRank": undefined
  },
  {
    "Character": "剜",
    "Pinyin": "wān",
    "Definition": "cut, cut out, pick out, scoop out",
    "Radical": "刀",
    "RadicalIndex": "18.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4667,
    "FrequencyRank": 4671
  },
  {
    "Character": "莪",
    "Pinyin": "é",
    "Definition": "artemisia",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4431,
    "FrequencyRank": 5829
  },
  {
    "Character": "铄",
    "Pinyin": "shuò",
    "Definition": "melt, smelt; shine",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4538,
    "FrequencyRank": 5473
  },
  {
    "Character": "虒",
    "Pinyin": "sī",
    "Definition": "an amphibious beast resembling a tiger with one horn; place name",
    "Radical": "虍",
    "RadicalIndex": "141.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7023,
    "FrequencyRank": 7189
  },
  {
    "Character": "珛",
    "Pinyin": "xiù",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6927,
    "FrequencyRank": undefined
  },
  {
    "Character": "俵",
    "Pinyin": "biào",
    "Definition": "divide, distribute",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4563,
    "FrequencyRank": undefined
  },
  {
    "Character": "莺",
    "Pinyin": "yīng",
    "Definition": "oriole, green finch; Sylvia species (various)",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1831,
    "FrequencyRank": 2902
  },
  {
    "Character": "龀",
    "Pinyin": "chèn",
    "Definition": "lose baby teeth and get adult teeth",
    "Radical": "齒",
    "RadicalIndex": "211.2",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4489,
    "FrequencyRank": 5568
  },
  {
    "Character": "珹",
    "Pinyin": "chéng",
    "Definition": "type of jade; pearl",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6928,
    "FrequencyRank": undefined
  },
  {
    "Character": "娓",
    "Pinyin": "wěi",
    "Definition": "comply; complying, agreeable",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4695,
    "FrequencyRank": 3999
  },
  {
    "Character": "钰",
    "Pinyin": "yù",
    "Definition": "rare treasure",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4527,
    "FrequencyRank": 4518
  },
  {
    "Character": "轾",
    "Pinyin": "zhì",
    "Definition": "low rear portion of cart",
    "Radical": "車",
    "RadicalIndex": "159.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4485,
    "FrequencyRank": 6203
  },
  {
    "Character": "耘",
    "Pinyin": "yún",
    "Definition": "weed",
    "Radical": "耒",
    "RadicalIndex": "127.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1767,
    "FrequencyRank": 4035
  },
  {
    "Character": "珞",
    "Pinyin": "luò",
    "Definition": "kind of necklace",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4408,
    "FrequencyRank": 4586
  },
  {
    "Character": "衃",
    "Pinyin": "pēi",
    "Definition": undefined,
    "Radical": "血",
    "RadicalIndex": "143.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7022,
    "FrequencyRank": undefined
  },
  {
    "Character": "钺",
    "Pinyin": "yuè",
    "Definition": "broad-axe, a battle axe, halberd",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4532,
    "FrequencyRank": 5507
  },
  {
    "Character": "烨",
    "Pinyin": "yè",
    "Definition": "bright, glorious, splendid, flame",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4638,
    "FrequencyRank": 4538
  },
  {
    "Character": "烠",
    "Pinyin": "huí",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7049,
    "FrequencyRank": undefined
  },
  {
    "Character": "悢",
    "Pinyin": "liàng",
    "Definition": undefined,
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7069,
    "FrequencyRank": undefined
  },
  {
    "Character": "俺",
    "Pinyin": "ǎn",
    "Definition": "personal pronoun, I",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1958,
    "FrequencyRank": 2749
  },
  {
    "Character": "郴",
    "Pinyin": "chēn",
    "Definition": "county in Hunan province; surname",
    "Radical": "邑",
    "RadicalIndex": "163.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4449,
    "FrequencyRank": 5383
  },
  {
    "Character": "绢",
    "Pinyin": "juàn",
    "Definition": "kind of thick stiff silk",
    "Radical": "糸",
    "RadicalIndex": "120.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2150,
    "FrequencyRank": 2385
  },
  {
    "Character": "笆",
    "Pinyin": "bā",
    "Definition": "bamboo fence",
    "Radical": "竹",
    "RadicalIndex": "118.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4560,
    "FrequencyRank": 3617
  },
  {
    "Character": "疳",
    "Pinyin": "gān",
    "Definition": "childhood diseases",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4614,
    "FrequencyRank": 5988
  },
  {
    "Character": "盉",
    "Pinyin": "hé",
    "Definition": undefined,
    "Radical": "皿",
    "RadicalIndex": "108.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4555,
    "FrequencyRank": 9891
  },
  {
    "Character": "梴",
    "Pinyin": "chān",
    "Definition": "long",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6963,
    "FrequencyRank": undefined
  },
  {
    "Character": "逋",
    "Pinyin": "bū",
    "Definition": "flee, run away, leave debt unsettled",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4465,
    "FrequencyRank": 5074
  },
  {
    "Character": "蚕",
    "Pinyin": "cán",
    "Definition": "silkworms",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1777,
    "FrequencyRank": 2787
  },
  {
    "Character": "殉",
    "Pinyin": "xùn",
    "Definition": "die for cause, be martyr for",
    "Radical": "歹",
    "RadicalIndex": "78.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1873,
    "FrequencyRank": 3303
  },
  {
    "Character": "烜",
    "Pinyin": "xuǎn",
    "Definition": "light of the sun; to dry in the sun",
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4637,
    "FrequencyRank": undefined
  },
  {
    "Character": "莱",
    "Pinyin": "lái",
    "Definition": "goosefoot, weed; fallow field",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1822,
    "FrequencyRank": 1196
  },
  {
    "Character": "珙",
    "Pinyin": "gǒng",
    "Definition": "precious stone; county in Sichuan",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4402,
    "FrequencyRank": 6469
  },
  {
    "Character": "桁",
    "Pinyin": "héng",
    "Definition": "cross-beams of roof",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4458,
    "FrequencyRank": 5380
  },
  {
    "Character": "珕",
    "Pinyin": "lì",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6939,
    "FrequencyRank": undefined
  },
  {
    "Character": "蚍",
    "Pinyin": "pí",
    "Definition": "mussels; various shellfish",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4509,
    "FrequencyRank": 5945
  },
  {
    "Character": "胯",
    "Pinyin": "kuà",
    "Definition": "pelvis; groin; thighs",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4589,
    "FrequencyRank": 4526
  },
  {
    "Character": "莩",
    "Pinyin": "fú",
    "Definition": "membrane lining inside of reed",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4437,
    "FrequencyRank": 5953
  },
  {
    "Character": "牂",
    "Pinyin": "zāng",
    "Definition": "female sheep, ewe",
    "Radical": "爿",
    "RadicalIndex": "90.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4684,
    "FrequencyRank": undefined
  },
  {
    "Character": "唔",
    "Pinyin": "wú",
    "Definition": "hold in mouth; bite; (Cant.) not, negation",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4500,
    "FrequencyRank": 3202
  },
  {
    "Character": "哳",
    "Pinyin": "zhā",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6986,
    "FrequencyRank": 8303
  },
  {
    "Character": "衄",
    "Pinyin": "nǜ",
    "Definition": "epistaxis, nosebleed; to be defeated",
    "Radical": "血",
    "RadicalIndex": "143.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4582,
    "FrequencyRank": 5839
  },
  {
    "Character": "敖",
    "Pinyin": "áo",
    "Definition": "ramble, play about; leisurely; surname",
    "Radical": "攴",
    "RadicalIndex": "66.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4411,
    "FrequencyRank": 3573
  },
  {
    "Character": "桐",
    "Pinyin": "tóng",
    "Definition": "name applied various trees",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1839,
    "FrequencyRank": 2712
  },
  {
    "Character": "珰",
    "Pinyin": "dāng",
    "Definition": "pendant ornaments, earrings",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4404,
    "FrequencyRank": undefined
  },
  {
    "Character": "倕",
    "Pinyin": "chuí",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7017,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫠆",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6967,
    "FrequencyRank": undefined
  },
  {
    "Character": "桤",
    "Pinyin": "qī",
    "Definition": "alder",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4454,
    "FrequencyRank": 5984
  },
  {
    "Character": "钴",
    "Pinyin": "gǔ",
    "Definition": "cobalt; household iron",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4529,
    "FrequencyRank": 4783
  },
  {
    "Character": "钷",
    "Pinyin": "pǒ",
    "Definition": "promethium",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7005,
    "FrequencyRank": 7463
  },
  {
    "Character": "𠳐",
    "Pinyin": "bāng",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4190,
    "FrequencyRank": undefined
  },
  {
    "Character": "耖",
    "Pinyin": "chào",
    "Definition": undefined,
    "Radical": "耒",
    "RadicalIndex": "127.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4399,
    "FrequencyRank": 7181
  },
  {
    "Character": "舐",
    "Pinyin": "shì",
    "Definition": "lick with tongue",
    "Radical": "舌",
    "RadicalIndex": "135.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4552,
    "FrequencyRank": 4344
  },
  {
    "Character": "俳",
    "Pinyin": "pái",
    "Definition": "actor; vaudeville show; insincere",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4565,
    "FrequencyRank": 4981
  },
  {
    "Character": "哮",
    "Pinyin": "xiāo",
    "Definition": "cough; pant; roar",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1890,
    "FrequencyRank": 2908
  },
  {
    "Character": "狻",
    "Pinyin": "suān",
    "Definition": "a fabulous beast",
    "Radical": "犬",
    "RadicalIndex": "94.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7037,
    "FrequencyRank": 4637
  },
  {
    "Character": "疹",
    "Pinyin": "zhěn",
    "Definition": "measles; rash; fever",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2030,
    "FrequencyRank": 3215
  },
  {
    "Character": "逑",
    "Pinyin": "qiú",
    "Definition": "collect, unite; match, pair",
    "Radical": "辵",
    "RadicalIndex": "162.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4464,
    "FrequencyRank": 5854
  },
  {
    "Character": "悒",
    "Pinyin": "yì",
    "Definition": "sorrowful, depressed, unhappy",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4662,
    "FrequencyRank": 5550
  },
  {
    "Character": "冢",
    "Pinyin": "zhǒng",
    "Definition": "burial mound, mausoleum; grand",
    "Radical": "冖",
    "RadicalIndex": "14.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4669,
    "FrequencyRank": 2840
  },
  {
    "Character": "唧",
    "Pinyin": "jī",
    "Definition": "chirping of insects; pump; (Cant.) a final particle",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1914,
    "FrequencyRank": 3757
  },
  {
    "Character": "剞",
    "Pinyin": "jī",
    "Definition": "carving or engraving knife; grave",
    "Radical": "刀",
    "RadicalIndex": "18.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4482,
    "FrequencyRank": 7417
  },
  {
    "Character": "钼",
    "Pinyin": "mù",
    "Definition": "molybdenum",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4534,
    "FrequencyRank": 5522
  },
  {
    "Character": "莅",
    "Pinyin": "lì",
    "Definition": "attend, be present; arrive at",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4435,
    "FrequencyRank": 4840
  },
  {
    "Character": "唢",
    "Pinyin": "suǒ",
    "Definition": "flute-like musical instrument",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4514,
    "FrequencyRank": 6003
  },
  {
    "Character": "骎",
    "Pinyin": "qīn",
    "Definition": "galloping; speeding",
    "Radical": "馬",
    "RadicalIndex": "187.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4706,
    "FrequencyRank": 6593
  },
  {
    "Character": "莰",
    "Pinyin": "kǎn",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6949,
    "FrequencyRank": undefined
  },
  {
    "Character": "绥",
    "Pinyin": "suí",
    "Definition": "soothe, appease, pacify",
    "Radical": "糸",
    "RadicalIndex": "120.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4703,
    "FrequencyRank": 2791
  },
  {
    "Character": "埘",
    "Pinyin": "shí",
    "Definition": "roost",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4415,
    "FrequencyRank": 6804
  },
  {
    "Character": "顼",
    "Pinyin": "xū",
    "Definition": "grieved",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4403,
    "FrequencyRank": 3579
  },
  {
    "Character": "栒",
    "Pinyin": "xún",
    "Definition": "cross bar",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6964,
    "FrequencyRank": undefined
  },
  {
    "Character": "倜",
    "Pinyin": "tì",
    "Definition": "raise high; unrestrained",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4573,
    "FrequencyRank": 4806
  },
  {
    "Character": "浰",
    "Pinyin": "liàn",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7061,
    "FrequencyRank": undefined
  },
  {
    "Character": "脐",
    "Pinyin": "qí",
    "Definition": "abdominal area of crab; navel",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1999,
    "FrequencyRank": 3830
  },
  {
    "Character": "恁",
    "Pinyin": "nèn",
    "Definition": "that, like this, thus, so, such",
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4569,
    "FrequencyRank": 5173
  },
  {
    "Character": "浬",
    "Pinyin": "lǐ",
    "Definition": "nautical mile",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7057,
    "FrequencyRank": undefined
  },
  {
    "Character": "翀",
    "Pinyin": "chōng",
    "Definition": "fly upward",
    "Radical": "羽",
    "RadicalIndex": "124.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7090,
    "FrequencyRank": 7246
  },
  {
    "Character": "蚧",
    "Pinyin": "jiè",
    "Definition": "a red spotted lizard, used as medicine",
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4513,
    "FrequencyRank": 5153
  },
  {
    "Character": "悖",
    "Pinyin": "bèi",
    "Definition": "be contradictory to, go counter",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2090,
    "FrequencyRank": 3148
  },
  {
    "Character": "倥",
    "Pinyin": "kōng",
    "Definition": "boorish, ignorant; urgent, pressing",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4577,
    "FrequencyRank": 5678
  },
  {
    "Character": "帨",
    "Pinyin": "shuì",
    "Definition": "handkerchief, kerchief, shawl",
    "Radical": "巾",
    "RadicalIndex": "50.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7001,
    "FrequencyRank": undefined
  },
  {
    "Character": "窊",
    "Pinyin": "wā",
    "Definition": "pit, vault; store in pit; (Cant.) 手窊, the arm",
    "Radical": "穴",
    "RadicalIndex": "116.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7073,
    "FrequencyRank": 8322
  },
  {
    "Character": "聂",
    "Pinyin": "niè",
    "Definition": "whisper; surname",
    "Radical": "耳",
    "RadicalIndex": "128.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1819,
    "FrequencyRank": 2797
  },
  {
    "Character": "鄀",
    "Pinyin": "ruò",
    "Definition": "state in Henan province",
    "Radical": "邑",
    "RadicalIndex": "163.9",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6952,
    "FrequencyRank": undefined
  },
  {
    "Character": "浲",
    "Pinyin": "féng",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7065,
    "FrequencyRank": undefined
  },
  {
    "Character": "畚",
    "Pinyin": "běn",
    "Definition": "straw basket, hamper",
    "Radical": "田",
    "RadicalIndex": "102.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4697,
    "FrequencyRank": 5574
  },
  {
    "Character": "豇",
    "Pinyin": "jiāng",
    "Definition": "a small kidney bean",
    "Radical": "豆",
    "RadicalIndex": "151.3",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4468,
    "FrequencyRank": 6173
  },
  {
    "Character": "珦",
    "Pinyin": "xiàng",
    "Definition": "a kind of jade",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6934,
    "FrequencyRank": undefined
  },
  {
    "Character": "阆",
    "Pinyin": "láng",
    "Definition": "high door; high gate; high, lofty",
    "Radical": "門",
    "RadicalIndex": "169.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4632,
    "FrequencyRank": 5334
  },
  {
    "Character": "荸",
    "Pinyin": "bí",
    "Definition": "water chestnut",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4427,
    "FrequencyRank": 6095
  },
  {
    "Character": "奚",
    "Pinyin": "xī",
    "Definition": "where? what? how? why?; servant",
    "Radical": "大",
    "RadicalIndex": "37.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4587,
    "FrequencyRank": 3395
  },
  {
    "Character": "栾",
    "Pinyin": "luán",
    "Definition": "name of tree; a part of cornice",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4611,
    "FrequencyRank": 4377
  },
  {
    "Character": "氨",
    "Pinyin": "ān",
    "Definition": "ammonia; hydrogen nitride",
    "Radical": "气",
    "RadicalIndex": "84.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1937,
    "FrequencyRank": 3028
  },
  {
    "Character": "徕",
    "Pinyin": "lái",
    "Definition": "induce, encourage to come",
    "Radical": "彳",
    "RadicalIndex": "60.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4584,
    "FrequencyRank": 4781
  },
  {
    "Character": "粑",
    "Pinyin": "bā",
    "Definition": "tsamba (food in Tibet)",
    "Radical": "米",
    "RadicalIndex": "119.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4634,
    "FrequencyRank": 4587
  },
  {
    "Character": "娣",
    "Pinyin": "dì",
    "Definition": "younger sister, sister-in-law",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4694,
    "FrequencyRank": 4727
  },
  {
    "Character": "桅",
    "Pinyin": "wéi",
    "Definition": "mast of ship",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4460,
    "FrequencyRank": 3558
  },
  {
    "Character": "訚",
    "Pinyin": "yín",
    "Definition": "speak gently; respectful",
    "Radical": "言",
    "RadicalIndex": "149.3",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4631,
    "FrequencyRank": 7233
  },
  {
    "Character": "翂",
    "Pinyin": "fēn",
    "Definition": undefined,
    "Radical": "羽",
    "RadicalIndex": "124.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7091,
    "FrequencyRank": 7817
  },
  {
    "Character": "莼",
    "Pinyin": "chún",
    "Definition": "an edible water plant-brasenia",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4446,
    "FrequencyRank": 5653
  },
  {
    "Character": "晟",
    "Pinyin": "chéng",
    "Definition": "clear, bright; splendor, brightness",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4495,
    "FrequencyRank": 5488
  },
  {
    "Character": "婀",
    "Pinyin": "ē",
    "Definition": "be beautiful, be graceful",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4696,
    "FrequencyRank": 4585
  },
  {
    "Character": "浜",
    "Pinyin": "bāng",
    "Definition": "creek, stream; beach, sea coast",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4655,
    "FrequencyRank": 4339
  },
  {
    "Character": "郪",
    "Pinyin": "qī",
    "Definition": "name of a stream in Sichuan province",
    "Radical": "邑",
    "RadicalIndex": "163.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6975,
    "FrequencyRank": 7767
  },
  {
    "Character": "袢",
    "Pinyin": "pàn",
    "Definition": "robe",
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4672,
    "FrequencyRank": 5217
  },
  {
    "Character": "鸯",
    "Pinyin": "yāng",
    "Definition": "female mandarin duck (Aix galericulata)",
    "Radical": "鳥",
    "RadicalIndex": "196.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1910,
    "FrequencyRank": 3975
  },
  {
    "Character": "疍",
    "Pinyin": "dàn",
    "Definition": undefined,
    "Radical": "疋",
    "RadicalIndex": "103.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7083,
    "FrequencyRank": undefined
  },
  {
    "Character": "蚆",
    "Pinyin": "bā",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6994,
    "FrequencyRank": undefined
  },
  {
    "Character": "玼",
    "Pinyin": "cǐ",
    "Definition": "beautiful color of gem; flaw in",
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6930,
    "FrequencyRank": undefined
  },
  {
    "Character": "栴",
    "Pinyin": "zhān",
    "Definition": "sandalwood",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6962,
    "FrequencyRank": undefined
  },
  {
    "Character": "痈",
    "Pinyin": "yōng",
    "Definition": "carbuncle, sore, ulcer, abscess",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4618,
    "FrequencyRank": 4950
  },
  {
    "Character": "娩",
    "Pinyin": "miǎn",
    "Definition": "give birth child; complaisant",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4692,
    "FrequencyRank": 4144
  },
  {
    "Character": "莙",
    "Pinyin": "jūn",
    "Definition": "species of water plant",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6956,
    "FrequencyRank": 9902
  },
  {
    "Character": "盍",
    "Pinyin": "hé",
    "Definition": "what? why not?",
    "Radical": "皿",
    "RadicalIndex": "108.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4426,
    "FrequencyRank": 5625
  },
  {
    "Character": "圃",
    "Pinyin": "pǔ",
    "Definition": "garden, cultivated field",
    "Radical": "囗",
    "RadicalIndex": "31.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1906,
    "FrequencyRank": 3472
  },
  {
    "Character": "赆",
    "Pinyin": "jìn",
    "Definition": "farewell present",
    "Radical": "貝",
    "RadicalIndex": "154.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7003,
    "FrequencyRank": 7105
  },
  {
    "Character": "珥",
    "Pinyin": "ěr",
    "Definition": "ear ornament; stick, insert",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4401,
    "FrequencyRank": 3904
  },
  {
    "Character": "舫",
    "Pinyin": "fǎng",
    "Definition": "fancy boat, yacht",
    "Radical": "舟",
    "RadicalIndex": "137.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4585,
    "FrequencyRank": 4469
  },
  {
    "Character": "涑",
    "Pinyin": "sù",
    "Definition": "river in Shansi province",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4644,
    "FrequencyRank": 5462
  },
  {
    "Character": "莜",
    "Pinyin": "yóu",
    "Definition": "Avena nuda; a bamboo basket",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4434,
    "FrequencyRank": 5233
  },
  {
    "Character": "埚",
    "Pinyin": "guō",
    "Definition": "crucible",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4417,
    "FrequencyRank": 6530
  },
  {
    "Character": "挹",
    "Pinyin": "yì",
    "Definition": "to bale out; to decant liquids",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4418,
    "FrequencyRank": 5899
  },
  {
    "Character": "钾",
    "Pinyin": "jiǎ",
    "Definition": "potassium",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1931,
    "FrequencyRank": 3801
  },
  {
    "Character": "臬",
    "Pinyin": "niè",
    "Definition": "law, rule; door post",
    "Radical": "自",
    "RadicalIndex": "132.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4578,
    "FrequencyRank": 5135
  },
  {
    "Character": "挛",
    "Pinyin": "luán",
    "Definition": "tangled; entwined; crooked",
    "Radical": "手",
    "RadicalIndex": "64.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4612,
    "FrequencyRank": 3350
  },
  {
    "Character": "栩",
    "Pinyin": "xǔ",
    "Definition": "species of oak; be glad, be pleased",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4463,
    "FrequencyRank": 3743
  },
  {
    "Character": "陴",
    "Pinyin": "pí",
    "Definition": "a parapet on a city wall",
    "Radical": "阜",
    "RadicalIndex": "170.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7085,
    "FrequencyRank": 6750
  },
  {
    "Character": "珝",
    "Pinyin": "xǔ",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6940,
    "FrequencyRank": undefined
  },
  {
    "Character": "眙",
    "Pinyin": "yí",
    "Definition": "to gaze at",
    "Radical": "目",
    "RadicalIndex": "109.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4497,
    "FrequencyRank": 6130
  },
  {
    "Character": "砰",
    "Pinyin": "pēng",
    "Definition": "sound of crashing stones, bang!",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1864,
    "FrequencyRank": 2869
  },
  {
    "Character": "悈",
    "Pinyin": "jiè",
    "Definition": "to enjoin upon; urgent",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7067,
    "FrequencyRank": undefined
  },
  {
    "Character": "剡",
    "Pinyin": "shàn",
    "Definition": "sharp, sharp-pointed; sharpen",
    "Radical": "刀",
    "RadicalIndex": "18.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4641,
    "FrequencyRank": 6348
  },
  {
    "Character": "𪨶",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6999,
    "FrequencyRank": undefined
  },
  {
    "Character": "莳",
    "Pinyin": "shí",
    "Definition": "transplant; plant; dill, Anethum graveolens",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4429,
    "FrequencyRank": 6133
  },
  {
    "Character": "崀",
    "Pinyin": "làng",
    "Definition": "place name in hunan province",
    "Radical": "山",
    "RadicalIndex": "46.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7002,
    "FrequencyRank": undefined
  },
  {
    "Character": "㛚",
    "Pinyin": "tǒng",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7088,
    "FrequencyRank": undefined
  },
  {
    "Character": "唁",
    "Pinyin": "yàn",
    "Definition": "express condolence",
    "Radical": "口",
    "RadicalIndex": "30.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1912,
    "FrequencyRank": 4847
  },
  {
    "Character": "舥",
    "Pinyin": "pā",
    "Definition": undefined,
    "Radical": "舟",
    "RadicalIndex": "137.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7026,
    "FrequencyRank": undefined
  },
  {
    "Character": "埇",
    "Pinyin": "yǒng",
    "Definition": "name of bridge",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6948,
    "FrequencyRank": undefined
  },
  {
    "Character": "笫",
    "Pinyin": "zǐ",
    "Definition": "bed boards, sleeping mat",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7012,
    "FrequencyRank": 4923
  },
  {
    "Character": "砬",
    "Pinyin": "lá",
    "Definition": "big stone",
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6971,
    "FrequencyRank": 6038
  },
  {
    "Character": "亳",
    "Pinyin": "bó",
    "Definition": "name of district in Anhui; capital of Yin",
    "Radical": "亠",
    "RadicalIndex": "8.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4613,
    "FrequencyRank": 5679
  },
  {
    "Character": "恧",
    "Pinyin": "nǜ",
    "Definition": undefined,
    "Radical": "心",
    "RadicalIndex": "61.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6973,
    "FrequencyRank": 6784
  },
  {
    "Character": "豺",
    "Pinyin": "chái",
    "Definition": "wolf; cruel, wicked, mean",
    "Radical": "豸",
    "RadicalIndex": "153.3",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1988,
    "FrequencyRank": 4248
  },
  {
    "Character": "珧",
    "Pinyin": "yáo",
    "Definition": "mother-of-pearl",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4406,
    "FrequencyRank": 6976
  },
  {
    "Character": "荻",
    "Pinyin": "dí",
    "Definition": "reed, Miscanthus saccariflorus",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4440,
    "FrequencyRank": 4555
  },
  {
    "Character": "𨐈",
    "Pinyin": "guāng",
    "Definition": undefined,
    "Radical": "車",
    "RadicalIndex": "159.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6976,
    "FrequencyRank": undefined
  },
  {
    "Character": "孬",
    "Pinyin": "nāo",
    "Definition": "bad; cowardly",
    "Radical": "子",
    "RadicalIndex": "39.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4472,
    "FrequencyRank": 5588
  },
  {
    "Character": "袯",
    "Pinyin": "bó",
    "Definition": "raincoat",
    "Radical": "衣",
    "RadicalIndex": "145.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7079,
    "FrequencyRank": undefined
  },
  {
    "Character": "荼",
    "Pinyin": "tú",
    "Definition": "bitter vegetable",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4436,
    "FrequencyRank": 3930
  },
  {
    "Character": "茝",
    "Pinyin": "chǎi",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6950,
    "FrequencyRank": 7614
  },
  {
    "Character": "埒",
    "Pinyin": "liè",
    "Definition": "enclosure, dike, embankment",
    "Radical": "土",
    "RadicalIndex": "32.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4421,
    "FrequencyRank": 6590
  },
  {
    "Character": "脍",
    "Pinyin": "kuài",
    "Definition": "minced meat or fish",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4593,
    "FrequencyRank": 5101
  },
  {
    "Character": "莝",
    "Pinyin": "cuò",
    "Definition": "to chop straw fine for animals",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6954,
    "FrequencyRank": undefined
  },
  {
    "Character": "铀",
    "Pinyin": "yóu",
    "Definition": "uranium",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4536,
    "FrequencyRank": 3105
  },
  {
    "Character": "倭",
    "Pinyin": "wō",
    "Definition": "dwarf; dwarfish, short",
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4570,
    "FrequencyRank": 3390
  },
  {
    "Character": "捋",
    "Pinyin": "lǚ",
    "Definition": "to pluck; to gather in the fingers; to rub; to scrape off",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4422,
    "FrequencyRank": 4049
  },
  {
    "Character": "浛",
    "Pinyin": "hán",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7063,
    "FrequencyRank": undefined
  },
  {
    "Character": "捌",
    "Pinyin": "bā",
    "Definition": "break open, split open; an accounting version of the numeral eight",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1800,
    "FrequencyRank": 5580
  },
  {
    "Character": "绡",
    "Pinyin": "xiāo",
    "Definition": "raw silk fabric",
    "Radical": "糸",
    "RadicalIndex": "120.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4701,
    "FrequencyRank": 6191
  },
  {
    "Character": "窎",
    "Pinyin": "diào",
    "Definition": "deep; distant",
    "Radical": "穴",
    "RadicalIndex": "116.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7074,
    "FrequencyRank": 8796
  },
  {
    "Character": "圄",
    "Pinyin": "yǔ",
    "Definition": "prison, jail",
    "Radical": "囗",
    "RadicalIndex": "31.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4515,
    "FrequencyRank": 5456
  },
  {
    "Character": "桦",
    "Pinyin": "huà",
    "Definition": "type of birch",
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1842,
    "FrequencyRank": 3856
  },
  {
    "Character": "猁",
    "Pinyin": "lì",
    "Definition": "a kind of monkey",
    "Radical": "犬",
    "RadicalIndex": "94.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4602,
    "FrequencyRank": 6244
  },
  {
    "Character": "涘",
    "Pinyin": "sì",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 7066,
    "FrequencyRank": undefined
  },
  {
    "Character": "珪",
    "Pinyin": "guī",
    "Definition": "a jade table conferred upon feudal princes by the emperor as a symbol of dignity and authority",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 6926,
    "FrequencyRank": undefined
  },
  {
    "Character": "铆",
    "Pinyin": "mǎo",
    "Definition": "rivet",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4539,
    "FrequencyRank": 5360
  },
  {
    "Character": "谀",
    "Pinyin": "yú",
    "Definition": "flatter, truckle",
    "Radical": "言",
    "RadicalIndex": "149.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4675,
    "FrequencyRank": 3925
  },
  {
    "Character": "涅",
    "Pinyin": "niè",
    "Definition": "blacken; black mud, slime",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4649,
    "FrequencyRank": 2343
  },
  {
    "Character": "珩",
    "Pinyin": "háng",
    "Definition": "the top gem of the pendants",
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4405,
    "FrequencyRank": 5315
  },
  {
    "Character": "陬",
    "Pinyin": "zōu",
    "Definition": "corner, cranny, nook, niche",
    "Radical": "阜",
    "RadicalIndex": "170.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4681,
    "FrequencyRank": 6221
  },
  {
    "Character": "涣",
    "Pinyin": "huàn",
    "Definition": "scatter; scattered",
    "Radical": "水",
    "RadicalIndex": "85.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 2078,
    "FrequencyRank": 3832
  },
  {
    "Character": "晏",
    "Pinyin": "yàn",
    "Definition": "peaceful, quiet; clear; late in the day",
    "Radical": "日",
    "RadicalIndex": "72.6",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4502,
    "FrequencyRank": 3811
  },
  {
    "Character": "捃",
    "Pinyin": "jùn",
    "Definition": undefined,
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4425,
    "FrequencyRank": 7040
  },
  {
    "Character": "笋",
    "Pinyin": "sǔn",
    "Definition": "bamboo shoots",
    "Radical": "竹",
    "RadicalIndex": "118.4",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 1953,
    "FrequencyRank": 3455
  },
  {
    "Character": "隽",
    "Pinyin": "juàn",
    "Definition": "superior, outstanding, talented",
    "Radical": "隹",
    "RadicalIndex": "172.2",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4575,
    "FrequencyRank": 4236
  },
  {
    "Character": "陲",
    "Pinyin": "chuí",
    "Definition": "frontier, border",
    "Radical": "阜",
    "RadicalIndex": "170.8",
    "StrokeCount": 10,
    "HSK": undefined,
    "GeneralStandard": 4686,
    "FrequencyRank": 4804
  },
  {
    "Character": "舴",
    "Pinyin": "zé",
    "Definition": undefined,
    "Radical": "舟",
    "RadicalIndex": "137.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4917,
    "FrequencyRank": 6309
  },
  {
    "Character": "粕",
    "Pinyin": "pò",
    "Definition": "lees, dregs, sediments left after distilling liquor",
    "Radical": "米",
    "RadicalIndex": "119.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4954,
    "FrequencyRank": 4655
  },
  {
    "Character": "绹",
    "Pinyin": "táo",
    "Definition": "to braid, twist; twisted or braided",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7277,
    "FrequencyRank": 7355
  },
  {
    "Character": "捽",
    "Pinyin": "zuó",
    "Definition": "clutch, grasp; pull up; contradict",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4734,
    "FrequencyRank": undefined
  },
  {
    "Character": "徛",
    "Pinyin": "jì",
    "Definition": "to cross over",
    "Radical": "彳",
    "RadicalIndex": "60.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7201,
    "FrequencyRank": undefined
  },
  {
    "Character": "寁",
    "Pinyin": "zǎn",
    "Definition": "quick, fast",
    "Radical": "宀",
    "RadicalIndex": "40.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7247,
    "FrequencyRank": undefined
  },
  {
    "Character": "缁",
    "Pinyin": "zī",
    "Definition": "black silk",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5040,
    "FrequencyRank": 5299
  },
  {
    "Character": "脟",
    "Pinyin": "liè",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7212,
    "FrequencyRank": 9477
  },
  {
    "Character": "殒",
    "Pinyin": "yǔn",
    "Definition": "die, perish; vanish; fall",
    "Radical": "歹",
    "RadicalIndex": "78.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4802,
    "FrequencyRank": 4590
  },
  {
    "Character": "菹",
    "Pinyin": "jū",
    "Definition": "salted or pickled vegetables",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4763,
    "FrequencyRank": 5606
  },
  {
    "Character": "埯",
    "Pinyin": "ǎn",
    "Definition": "cover with earth; a pit; a hole",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4718,
    "FrequencyRank": 6755
  },
  {
    "Character": "耜",
    "Pinyin": "sì",
    "Definition": "spade-shaped tool; plow",
    "Radical": "耒",
    "RadicalIndex": "127.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4710,
    "FrequencyRank": 6213
  },
  {
    "Character": "硗",
    "Pinyin": "qiāo",
    "Definition": "barren land; sandy soil",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4793,
    "FrequencyRank": 5539
  },
  {
    "Character": "淅",
    "Pinyin": "xī",
    "Definition": "water used wash rice; to wash ric",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4966,
    "FrequencyRank": 4616
  },
  {
    "Character": "婕",
    "Pinyin": "jié",
    "Definition": "handsome",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5018,
    "FrequencyRank": 4945
  },
  {
    "Character": "颅",
    "Pinyin": "lú",
    "Definition": "skull",
    "Radical": "頁",
    "RadicalIndex": "181.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2252,
    "FrequencyRank": 2961
  },
  {
    "Character": "堍",
    "Pinyin": "tù",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4730,
    "FrequencyRank": 6889
  },
  {
    "Character": "𠅤",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "亠",
    "RadicalIndex": "8.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7219,
    "FrequencyRank": undefined
  },
  {
    "Character": "趼",
    "Pinyin": "jiǎn",
    "Definition": "callous skin on hands or feet. blisters",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7162,
    "FrequencyRank": 6206
  },
  {
    "Character": "冕",
    "Pinyin": "miǎn",
    "Definition": "crown; ceremonial cap",
    "Radical": "冂",
    "RadicalIndex": "13.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4825,
    "FrequencyRank": 3456
  },
  {
    "Character": "偬",
    "Pinyin": "zǒng",
    "Definition": "urgent",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4909,
    "FrequencyRank": 5862
  },
  {
    "Character": "埵",
    "Pinyin": "duǒ",
    "Definition": "hardened dirt or clay; cluster",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4723,
    "FrequencyRank": undefined
  },
  {
    "Character": "眭",
    "Pinyin": "suī",
    "Definition": "evil look of deep-set eyes",
    "Radical": "目",
    "RadicalIndex": "109.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4809,
    "FrequencyRank": 7179
  },
  {
    "Character": "舳",
    "Pinyin": "zhú",
    "Definition": "stern of ship",
    "Radical": "舟",
    "RadicalIndex": "137.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7203,
    "FrequencyRank": 6227
  },
  {
    "Character": "琀",
    "Pinyin": "hán",
    "Definition": "gems of pearls formerly put into the mouth of a corpse",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7104,
    "FrequencyRank": undefined
  },
  {
    "Character": "孰",
    "Pinyin": "shú",
    "Definition": "who? which? what? which one?",
    "Radical": "子",
    "RadicalIndex": "39.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4935,
    "FrequencyRank": 3568
  },
  {
    "Character": "铨",
    "Pinyin": "quán",
    "Definition": "weigh, measure; select officials",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4876,
    "FrequencyRank": 4749
  },
  {
    "Character": "趾",
    "Pinyin": "zhǐ",
    "Definition": "toe; tracks, footprints",
    "Radical": "足",
    "RadicalIndex": "157.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2274,
    "FrequencyRank": 3164
  },
  {
    "Character": "梼",
    "Pinyin": "táo",
    "Definition": "block of wood; blockhead; stupid",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7135,
    "FrequencyRank": undefined
  },
  {
    "Character": "鄅",
    "Pinyin": "yǔ",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7199,
    "FrequencyRank": undefined
  },
  {
    "Character": "婌",
    "Pinyin": "shú",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7268,
    "FrequencyRank": undefined
  },
  {
    "Character": "铢",
    "Pinyin": "zhū",
    "Definition": "unit of weight, one twenty-fourth of a Chinese ounce (liang)",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4872,
    "FrequencyRank": 3588
  },
  {
    "Character": "铟",
    "Pinyin": "yīn",
    "Definition": "indium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4869,
    "FrequencyRank": 6619
  },
  {
    "Character": "殓",
    "Pinyin": "liàn",
    "Definition": "dress corpse for burial",
    "Radical": "歹",
    "RadicalIndex": "78.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4803,
    "FrequencyRank": 4461
  },
  {
    "Character": "蚺",
    "Pinyin": "rán",
    "Definition": "a boa constrictor",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7167,
    "FrequencyRank": 6411
  },
  {
    "Character": "铏",
    "Pinyin": "xíng",
    "Definition": "sacrificial cauldron",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7176,
    "FrequencyRank": 7103
  },
  {
    "Character": "菥",
    "Pinyin": "xī",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7122,
    "FrequencyRank": 6532
  },
  {
    "Character": "勖",
    "Pinyin": "xù",
    "Definition": "enjoin, advise, preach to",
    "Radical": "力",
    "RadicalIndex": "19.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4821,
    "FrequencyRank": 4974
  },
  {
    "Character": "焆",
    "Pinyin": "juān",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7230,
    "FrequencyRank": undefined
  },
  {
    "Character": "埸",
    "Pinyin": "yì",
    "Definition": "a border; a limit; a dike; a frontier; a boundary",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4722,
    "FrequencyRank": 6702
  },
  {
    "Character": "掴",
    "Pinyin": "guāi",
    "Definition": undefined,
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4721,
    "FrequencyRank": 5461
  },
  {
    "Character": "铐",
    "Pinyin": "kào",
    "Definition": "shackles, manacles",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2303,
    "FrequencyRank": 3760
  },
  {
    "Character": "犁",
    "Pinyin": "lí",
    "Definition": "plow",
    "Radical": "牛",
    "RadicalIndex": "93.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2314,
    "FrequencyRank": 3176
  },
  {
    "Character": "掭",
    "Pinyin": "tiàn",
    "Definition": "to manipulate; a pricker for a lamp-wick",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7106,
    "FrequencyRank": 7987
  },
  {
    "Character": "舸",
    "Pinyin": "gě",
    "Definition": "large boat, barge",
    "Radical": "舟",
    "RadicalIndex": "137.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4915,
    "FrequencyRank": 4936
  },
  {
    "Character": "晗",
    "Pinyin": "hán",
    "Definition": "pre-dawn",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4824,
    "FrequencyRank": 4682
  },
  {
    "Character": "䎃",
    "Pinyin": "rǎn",
    "Definition": "fine down; floss; wool",
    "Radical": "羽",
    "RadicalIndex": "124.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7169,
    "FrequencyRank": undefined
  },
  {
    "Character": "鸼",
    "Pinyin": "zhōu",
    "Definition": undefined,
    "Radical": "鳥",
    "RadicalIndex": "196.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7205,
    "FrequencyRank": 8279
  },
  {
    "Character": "隈",
    "Pinyin": "wēi",
    "Definition": "cove, bay, inlet",
    "Radical": "阜",
    "RadicalIndex": "170.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5012,
    "FrequencyRank": 6472
  },
  {
    "Character": "萁",
    "Pinyin": "qí",
    "Definition": "kind of grasses",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4747,
    "FrequencyRank": 6321
  },
  {
    "Character": "渍",
    "Pinyin": "zì",
    "Definition": "soak, steep; dye; stains; sodden",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4963,
    "FrequencyRank": 3773
  },
  {
    "Character": "翌",
    "Pinyin": "yì",
    "Definition": "bright; daybreak, dawn; the next day",
    "Radical": "羽",
    "RadicalIndex": "124.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5024,
    "FrequencyRank": 3708
  },
  {
    "Character": "婤",
    "Pinyin": "chōu",
    "Definition": "lovely",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7270,
    "FrequencyRank": undefined
  },
  {
    "Character": "偎",
    "Pinyin": "wēi",
    "Definition": "cling to, cuddle, embrace, fondle",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2329,
    "FrequencyRank": 3656
  },
  {
    "Character": "硍",
    "Pinyin": "xiàn",
    "Definition": "strike",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7150,
    "FrequencyRank": undefined
  },
  {
    "Character": "崔",
    "Pinyin": "cuī",
    "Definition": "high, lofty, towering; surname",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2296,
    "FrequencyRank": 2552
  },
  {
    "Character": "赇",
    "Pinyin": "qiú",
    "Definition": "bribe",
    "Radical": "貝",
    "RadicalIndex": "154.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4862,
    "FrequencyRank": 7106
  },
  {
    "Character": "蛏",
    "Pinyin": "chēng",
    "Definition": "razor clam",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4840,
    "FrequencyRank": 5694
  },
  {
    "Character": "唰",
    "Pinyin": "shuā",
    "Definition": "swish, rustle",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4853,
    "FrequencyRank": 4471
  },
  {
    "Character": "掇",
    "Pinyin": "duō",
    "Definition": "collect, gather up, pick up",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4743,
    "FrequencyRank": 4486
  },
  {
    "Character": "桯",
    "Pinyin": "tīng",
    "Definition": "table",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7139,
    "FrequencyRank": undefined
  },
  {
    "Character": "绻",
    "Pinyin": "quǎn",
    "Definition": "affectionate, solicitous",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5037,
    "FrequencyRank": 4589
  },
  {
    "Character": "媖",
    "Pinyin": "yīng",
    "Definition": "beauty; beautiful",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7265,
    "FrequencyRank": undefined
  },
  {
    "Character": "淙",
    "Pinyin": "cóng",
    "Definition": "gurgling sound of water",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4979,
    "FrequencyRank": 4172
  },
  {
    "Character": "䏲",
    "Pinyin": "dié",
    "Definition": "(same as 胅) protruded bones, swelling, a crooked nose",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7213,
    "FrequencyRank": undefined
  },
  {
    "Character": "惆",
    "Pinyin": "chóu",
    "Definition": "distressed, regretful, sad",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4989,
    "FrequencyRank": 4079
  },
  {
    "Character": "𫍯",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7250,
    "FrequencyRank": undefined
  },
  {
    "Character": "畦",
    "Pinyin": "qí",
    "Definition": "sections in vegetable farm",
    "Radical": "田",
    "RadicalIndex": "102.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4827,
    "FrequencyRank": 4795
  },
  {
    "Character": "焘",
    "Pinyin": "dào",
    "Definition": "shine, illuminate; cover, envelope",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4711,
    "FrequencyRank": 3213
  },
  {
    "Character": "氪",
    "Pinyin": "kè",
    "Definition": "krypton",
    "Radical": "气",
    "RadicalIndex": "84.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4888,
    "FrequencyRank": 5279
  },
  {
    "Character": "猄",
    "Pinyin": "jīng",
    "Definition": undefined,
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7217,
    "FrequencyRank": undefined
  },
  {
    "Character": "笠",
    "Pinyin": "lì",
    "Definition": "bamboo hat; bamboo covering",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4898,
    "FrequencyRank": 3278
  },
  {
    "Character": "烽",
    "Pinyin": "fēng",
    "Definition": "signal fire; tower where signal",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4959,
    "FrequencyRank": 3991
  },
  {
    "Character": "铳",
    "Pinyin": "chòng",
    "Definition": "ancient weapon, blunderbuss",
    "Radical": "金",
    "RadicalIndex": "167.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4885,
    "FrequencyRank": 5787
  },
  {
    "Character": "骐",
    "Pinyin": "qí",
    "Definition": "piebald horse; excellent horse",
    "Radical": "馬",
    "RadicalIndex": "187.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5028,
    "FrequencyRank": 6298
  },
  {
    "Character": "菟",
    "Pinyin": "tú",
    "Definition": "dodder; creeper",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4759,
    "FrequencyRank": 5189
  },
  {
    "Character": "菅",
    "Pinyin": "jiān",
    "Definition": "coarse grass, themedia forskali",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4765,
    "FrequencyRank": 5235
  },
  {
    "Character": "谑",
    "Pinyin": "xuè",
    "Definition": "jeer",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4998,
    "FrequencyRank": 3668
  },
  {
    "Character": "舂",
    "Pinyin": "chōng",
    "Definition": "grind in mortar",
    "Radical": "臼",
    "RadicalIndex": "134.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4712,
    "FrequencyRank": 5145
  },
  {
    "Character": "弸",
    "Pinyin": "péng",
    "Definition": "a bow stretched to the full; tensely drawn",
    "Radical": "弓",
    "RadicalIndex": "57.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7258,
    "FrequencyRank": undefined
  },
  {
    "Character": "堃",
    "Pinyin": "kūn",
    "Definition": "compliance; obedience; female",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7226,
    "FrequencyRank": undefined
  },
  {
    "Character": "涫",
    "Pinyin": "guàn",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4980,
    "FrequencyRank": 5048
  },
  {
    "Character": "喵",
    "Pinyin": "miāo",
    "Definition": "the mew of the cat",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4819,
    "FrequencyRank": 4550
  },
  {
    "Character": "堑",
    "Pinyin": "qiàn",
    "Definition": "moat, trench, pit, cavity",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4808,
    "FrequencyRank": 3420
  },
  {
    "Character": "粝",
    "Pinyin": "lì",
    "Definition": "unpolished rice; brown rice",
    "Radical": "米",
    "RadicalIndex": "119.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4953,
    "FrequencyRank": 5733
  },
  {
    "Character": "翎",
    "Pinyin": "líng",
    "Definition": "feather; plume; wing",
    "Radical": "羽",
    "RadicalIndex": "124.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4920,
    "FrequencyRank": 3417
  },
  {
    "Character": "阉",
    "Pinyin": "yān",
    "Definition": "castrate; eunuch",
    "Radical": "門",
    "RadicalIndex": "169.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4947,
    "FrequencyRank": 3814
  },
  {
    "Character": "匐",
    "Pinyin": "fú",
    "Definition": "fall prostrate; crawl",
    "Radical": "勹",
    "RadicalIndex": "20.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4924,
    "FrequencyRank": 4278
  },
  {
    "Character": "郿",
    "Pinyin": "méi",
    "Definition": "county in Shaanxi province",
    "Radical": "邑",
    "RadicalIndex": "163.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5011,
    "FrequencyRank": 6426
  },
  {
    "Character": "恿",
    "Pinyin": "yǒng",
    "Definition": "instigate, incite; to alarm",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5025,
    "FrequencyRank": 3913
  },
  {
    "Character": "舷",
    "Pinyin": "xián",
    "Definition": "the sides of a boat, bulwarks; a gunwale",
    "Radical": "舟",
    "RadicalIndex": "137.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4918,
    "FrequencyRank": 3109
  },
  {
    "Character": "硌",
    "Pinyin": "gè",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4796,
    "FrequencyRank": 5552
  },
  {
    "Character": "彪",
    "Pinyin": "biāo",
    "Definition": "tiger; tiger stripes; tiger-like",
    "Radical": "彡",
    "RadicalIndex": "59.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2254,
    "FrequencyRank": 2406
  },
  {
    "Character": "惝",
    "Pinyin": "chǎng",
    "Definition": "alarmed, agitated",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4986,
    "FrequencyRank": 6553
  },
  {
    "Character": "珵",
    "Pinyin": "chéng",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7101,
    "FrequencyRank": undefined
  },
  {
    "Character": "堇",
    "Pinyin": "jǐn",
    "Definition": "yellow loam; clay; season; few",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4749,
    "FrequencyRank": 5758
  },
  {
    "Character": "啉",
    "Pinyin": "lín",
    "Definition": "stupid; slow",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4820,
    "FrequencyRank": 5643
  },
  {
    "Character": "萏",
    "Pinyin": "dàn",
    "Definition": "lotus",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4760,
    "FrequencyRank": 6533
  },
  {
    "Character": "袼",
    "Pinyin": "gē",
    "Definition": undefined,
    "Radical": "衣",
    "RadicalIndex": "145.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7251,
    "FrequencyRank": 5808
  },
  {
    "Character": "啕",
    "Pinyin": "táo",
    "Definition": "wail",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4843,
    "FrequencyRank": 4425
  },
  {
    "Character": "蚶",
    "Pinyin": "hān",
    "Definition": "kind of clam, arca inflata",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4831,
    "FrequencyRank": 5725
  },
  {
    "Character": "蚴",
    "Pinyin": "yòu",
    "Definition": "larva",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4841,
    "FrequencyRank": 4976
  },
  {
    "Character": "悱",
    "Pinyin": "fěi",
    "Definition": "to be desirous of speaking",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4985,
    "FrequencyRank": 5901
  },
  {
    "Character": "逵",
    "Pinyin": "kuí",
    "Definition": "thoroughfare, crossroads",
    "Radical": "辵",
    "RadicalIndex": "162.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4727,
    "FrequencyRank": 3778
  },
  {
    "Character": "埪",
    "Pinyin": "kōng",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7116,
    "FrequencyRank": undefined
  },
  {
    "Character": "硔",
    "Pinyin": "hóng",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7145,
    "FrequencyRank": undefined
  },
  {
    "Character": "崌",
    "Pinyin": "jū",
    "Definition": "mountain name",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7174,
    "FrequencyRank": undefined
  },
  {
    "Character": "埼",
    "Pinyin": "qí",
    "Definition": "headland",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7109,
    "FrequencyRank": undefined
  },
  {
    "Character": "琇",
    "Pinyin": "xiù",
    "Definition": "coarse variety of jasper or jade",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4714,
    "FrequencyRank": undefined
  },
  {
    "Character": "䝙",
    "Pinyin": "chū",
    "Definition": "a kind of animal like a tiger; fierce wild beasts",
    "Radical": "豸",
    "RadicalIndex": "153.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7209,
    "FrequencyRank": undefined
  },
  {
    "Character": "婠",
    "Pinyin": "wān",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7272,
    "FrequencyRank": undefined
  },
  {
    "Character": "牻",
    "Pinyin": "máng",
    "Definition": undefined,
    "Radical": "牛",
    "RadicalIndex": "93.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7187,
    "FrequencyRank": undefined
  },
  {
    "Character": "崆",
    "Pinyin": "kōng",
    "Definition": "Kongtong mountain",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4861,
    "FrequencyRank": 4996
  },
  {
    "Character": "竫",
    "Pinyin": "jìng",
    "Definition": undefined,
    "Radical": "立",
    "RadicalIndex": "117.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7225,
    "FrequencyRank": undefined
  },
  {
    "Character": "鸻",
    "Pinyin": "héng",
    "Definition": undefined,
    "Radical": "鳥",
    "RadicalIndex": "196.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4913,
    "FrequencyRank": 6392
  },
  {
    "Character": "眸",
    "Pinyin": "móu",
    "Definition": "pupil of eye; eye",
    "Radical": "目",
    "RadicalIndex": "109.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4816,
    "FrequencyRank": 3368
  },
  {
    "Character": "酚",
    "Pinyin": "fēn",
    "Definition": "carbolic acid; phenol",
    "Radical": "酉",
    "RadicalIndex": "164.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4787,
    "FrequencyRank": 4391
  },
  {
    "Character": "崮",
    "Pinyin": "gù",
    "Definition": "a mesa, hill with flat top and steep sides",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4859,
    "FrequencyRank": 5098
  },
  {
    "Character": "敔",
    "Pinyin": "yǔ",
    "Definition": "gavel",
    "Radical": "攴",
    "RadicalIndex": "66.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7143,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫘧",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7281,
    "FrequencyRank": undefined
  },
  {
    "Character": "菠",
    "Pinyin": "bō",
    "Definition": "spinach and similar greens",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2212,
    "FrequencyRank": 4540
  },
  {
    "Character": "骕",
    "Pinyin": "sù",
    "Definition": "name of a famous horse",
    "Radical": "馬",
    "RadicalIndex": "187.12",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7280,
    "FrequencyRank": 7108
  },
  {
    "Character": "䌹",
    "Pinyin": "jiǒng",
    "Definition": "(simplified form of 絅) (same as 褧) a garment of one colour with no lining, a dust coat",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 6750,
    "FrequencyRank": undefined
  },
  {
    "Character": "阇",
    "Pinyin": "dū",
    "Definition": "a Buddhist high priest",
    "Radical": "門",
    "RadicalIndex": "169.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4945,
    "FrequencyRank": 6460
  },
  {
    "Character": "啧",
    "Pinyin": "zé",
    "Definition": "interjection of approval or admiration",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4811,
    "FrequencyRank": 3698
  },
  {
    "Character": "莿",
    "Pinyin": "cì",
    "Definition": "thorn",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7123,
    "FrequencyRank": 7973
  },
  {
    "Character": "秾",
    "Pinyin": "nóng",
    "Definition": "thick, lush, in clusters",
    "Radical": "禾",
    "RadicalIndex": "115.13",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4891,
    "FrequencyRank": 6668
  },
  {
    "Character": "帷",
    "Pinyin": "wéi",
    "Definition": "tent; curtain, screen",
    "Radical": "巾",
    "RadicalIndex": "50.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2297,
    "FrequencyRank": 3448
  },
  {
    "Character": "偻",
    "Pinyin": "lóu",
    "Definition": "humpback; surname",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4910,
    "FrequencyRank": 4573
  },
  {
    "Character": "猝",
    "Pinyin": "cù",
    "Definition": "abruptly, suddenly; abrupt",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4928,
    "FrequencyRank": 3316
  },
  {
    "Character": "傀",
    "Pinyin": "guī",
    "Definition": "great, gigantic; puppet",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4908,
    "FrequencyRank": 3946
  },
  {
    "Character": "庶",
    "Pinyin": "shù",
    "Definition": "numerous, various; multitude",
    "Radical": "广",
    "RadicalIndex": "53.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2378,
    "FrequencyRank": 3016
  },
  {
    "Character": "菘",
    "Pinyin": "sōng",
    "Definition": "celery, cabbage",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4748,
    "FrequencyRank": 5999
  },
  {
    "Character": "铑",
    "Pinyin": "lǎo",
    "Definition": "rhodium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4865,
    "FrequencyRank": 6031
  },
  {
    "Character": "硐",
    "Pinyin": "dòng",
    "Definition": "variant of 洞, cave; chamber; pit",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4794,
    "FrequencyRank": 5425
  },
  {
    "Character": "猊",
    "Pinyin": "ní",
    "Definition": "lion; wild beast; wild horse",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7216,
    "FrequencyRank": 5928
  },
  {
    "Character": "淤",
    "Pinyin": "yū",
    "Definition": "mud, sediment; clog up, silt up",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2429,
    "FrequencyRank": 3576
  },
  {
    "Character": "绾",
    "Pinyin": "wǎn",
    "Definition": "to string together, to bind up",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5038,
    "FrequencyRank": 4528
  },
  {
    "Character": "蚱",
    "Pinyin": "zhà",
    "Definition": "grasshopper; (edible) locust; cicada",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4838,
    "FrequencyRank": 4410
  },
  {
    "Character": "阋",
    "Pinyin": "xì",
    "Definition": "feud, fight, quarrel",
    "Radical": "門",
    "RadicalIndex": "169.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4949,
    "FrequencyRank": 4894
  },
  {
    "Character": "尉",
    "Pinyin": "wèi",
    "Definition": "officer, military rank",
    "Radical": "寸",
    "RadicalIndex": "41.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2471,
    "FrequencyRank": 2126
  },
  {
    "Character": "堐",
    "Pinyin": "yá",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7108,
    "FrequencyRank": undefined
  },
  {
    "Character": "崎",
    "Pinyin": "qí",
    "Definition": "rough, uneven, jagged, rugged",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2293,
    "FrequencyRank": 2751
  },
  {
    "Character": "谝",
    "Pinyin": "piǎn",
    "Definition": "brag, boast; quibble",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5009,
    "FrequencyRank": 4370
  },
  {
    "Character": "堌",
    "Pinyin": "gù",
    "Definition": "dam, dike; used in place names",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7112,
    "FrequencyRank": undefined
  },
  {
    "Character": "祲",
    "Pinyin": "jìn",
    "Definition": "ominous or sinister spirit; vigor",
    "Radical": "示",
    "RadicalIndex": "113.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7253,
    "FrequencyRank": 8320
  },
  {
    "Character": "涸",
    "Pinyin": "hé",
    "Definition": "dried up; exhausted, tired; dry",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4973,
    "FrequencyRank": 3865
  },
  {
    "Character": "馄",
    "Pinyin": "hún",
    "Definition": "dumpling soup, wonton",
    "Radical": "食",
    "RadicalIndex": "184.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4933,
    "FrequencyRank": 4631
  },
  {
    "Character": "弶",
    "Pinyin": "jiàng",
    "Definition": "a snare",
    "Radical": "弓",
    "RadicalIndex": "57.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7259,
    "FrequencyRank": undefined
  },
  {
    "Character": "㿠",
    "Pinyin": "huàng",
    "Definition": undefined,
    "Radical": "白",
    "RadicalIndex": "106.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7198,
    "FrequencyRank": undefined
  },
  {
    "Character": "寅",
    "Pinyin": "yín",
    "Definition": "to respect, reverence; respectfully; 3rd terrestrial branch",
    "Radical": "宀",
    "RadicalIndex": "40.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2452,
    "FrequencyRank": 3422
  },
  {
    "Character": "蛎",
    "Pinyin": "lì",
    "Definition": "oyster",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4833,
    "FrequencyRank": 4426
  },
  {
    "Character": "眷",
    "Pinyin": "juàn",
    "Definition": "take interest in, care for",
    "Radical": "目",
    "RadicalIndex": "109.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2401,
    "FrequencyRank": 2967
  },
  {
    "Character": "珸",
    "Pinyin": "wú",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7100,
    "FrequencyRank": undefined
  },
  {
    "Character": "阊",
    "Pinyin": "chāng",
    "Definition": "gate of heaven; main gate of a palace",
    "Radical": "門",
    "RadicalIndex": "169.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4948,
    "FrequencyRank": 5926
  },
  {
    "Character": "硙",
    "Pinyin": "wéi",
    "Definition": "stone mill; grind; break apart",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7147,
    "FrequencyRank": 7916
  },
  {
    "Character": "梵",
    "Pinyin": "fàn",
    "Definition": "Buddhist, Sanskrit",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4770,
    "FrequencyRank": 3276
  },
  {
    "Character": "啫",
    "Pinyin": "zhě",
    "Definition": "(Cant.) interjection of warning; phonetic; penis",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7156,
    "FrequencyRank": undefined
  },
  {
    "Character": "硭",
    "Pinyin": "máng",
    "Definition": "a crude saltpetre",
    "Radical": "石",
    "RadicalIndex": "112.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4790,
    "FrequencyRank": 6039
  },
  {
    "Character": "琐",
    "Pinyin": "suǒ",
    "Definition": "trifling, petty; troublesome",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2156,
    "FrequencyRank": 2834
  },
  {
    "Character": "痓",
    "Pinyin": "chì",
    "Definition": undefined,
    "Radical": "疒",
    "RadicalIndex": "104.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7223,
    "FrequencyRank": undefined
  },
  {
    "Character": "桴",
    "Pinyin": "fú",
    "Definition": "raft; drum stick; ridge pole",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4774,
    "FrequencyRank": 6141
  },
  {
    "Character": "萦",
    "Pinyin": "yíng",
    "Definition": "entangle, entwine, coil",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4767,
    "FrequencyRank": 3903
  },
  {
    "Character": "啪",
    "Pinyin": "pā",
    "Definition": "syllable",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2266,
    "FrequencyRank": 2812
  },
  {
    "Character": "乾",
    "Pinyin": "gān",
    "Definition": "dry; first hexagram; warming principle of the sun, penetrating and fertilizing, heavenly generative principle (male)",
    "Radical": "乙",
    "RadicalIndex": "5.1",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2215,
    "FrequencyRank": 1999
  },
  {
    "Character": "崟",
    "Pinyin": "yín",
    "Definition": "cliffs",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7171,
    "FrequencyRank": undefined
  },
  {
    "Character": "淞",
    "Pinyin": "sōng",
    "Definition": "name of a river in Jiangsu",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4967,
    "FrequencyRank": 4040
  },
  {
    "Character": "谕",
    "Pinyin": "yù",
    "Definition": "proclaim, instruct; edict",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5004,
    "FrequencyRank": 2576
  },
  {
    "Character": "惛",
    "Pinyin": "hūn",
    "Definition": "confused, stupid, dull, senile",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7243,
    "FrequencyRank": undefined
  },
  {
    "Character": "逴",
    "Pinyin": "chuō",
    "Definition": "argue, quarrel, squabble; far",
    "Radical": "辵",
    "RadicalIndex": "162.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7154,
    "FrequencyRank": undefined
  },
  {
    "Character": "菖",
    "Pinyin": "chāng",
    "Definition": "iris, sweet flag, calamus",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4753,
    "FrequencyRank": 5212
  },
  {
    "Character": "铰",
    "Pinyin": "jiǎo",
    "Definition": "hinge; shears, scissors",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4883,
    "FrequencyRank": 4630
  },
  {
    "Character": "桹",
    "Pinyin": "láng",
    "Definition": "palm",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7142,
    "FrequencyRank": undefined
  },
  {
    "Character": "婍",
    "Pinyin": "qǐ",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7267,
    "FrequencyRank": undefined
  },
  {
    "Character": "笯",
    "Pinyin": "nú",
    "Definition": "a bird-cage",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7191,
    "FrequencyRank": undefined
  },
  {
    "Character": "蛊",
    "Pinyin": "gǔ",
    "Definition": "poison; venom; harm; bewitch",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4836,
    "FrequencyRank": 3808
  },
  {
    "Character": "渌",
    "Pinyin": "lù",
    "Definition": "strain",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4981,
    "FrequencyRank": 4814
  },
  {
    "Character": "淴",
    "Pinyin": "hū",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7237,
    "FrequencyRank": undefined
  },
  {
    "Character": "悫",
    "Pinyin": "què",
    "Definition": "sincerity, honesty; modest",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4740,
    "FrequencyRank": 6144
  },
  {
    "Character": "铤",
    "Pinyin": "dìng",
    "Definition": "ingots, bars of metal; hurry",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4874,
    "FrequencyRank": 4709
  },
  {
    "Character": "粜",
    "Pinyin": "tiào",
    "Definition": "sell grains",
    "Radical": "米",
    "RadicalIndex": "119.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5013,
    "FrequencyRank": 5687
  },
  {
    "Character": "舻",
    "Pinyin": "lú",
    "Definition": "bow or prow of boat",
    "Radical": "舟",
    "RadicalIndex": "137.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4916,
    "FrequencyRank": 5308
  },
  {
    "Character": "淏",
    "Pinyin": "hào",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7233,
    "FrequencyRank": undefined
  },
  {
    "Character": "笳",
    "Pinyin": "jiā",
    "Definition": "a reed leaf whistle",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4901,
    "FrequencyRank": 5219
  },
  {
    "Character": "绮",
    "Pinyin": "qǐ",
    "Definition": "fine thin silk; elegant, beautifu",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5029,
    "FrequencyRank": 3565
  },
  {
    "Character": "渎",
    "Pinyin": "dú",
    "Definition": "ditch, sluice, gutter, drain",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4968,
    "FrequencyRank": 3373
  },
  {
    "Character": "牾",
    "Pinyin": "wǔ",
    "Definition": "to oppose; to gore",
    "Radical": "牛",
    "RadicalIndex": "93.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4889,
    "FrequencyRank": 6522
  },
  {
    "Character": "棁",
    "Pinyin": "zhuō",
    "Definition": "joist; cane",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4777,
    "FrequencyRank": undefined
  },
  {
    "Character": "翊",
    "Pinyin": "yì",
    "Definition": "flying; assist, help; respect",
    "Radical": "羽",
    "RadicalIndex": "124.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4941,
    "FrequencyRank": 4429
  },
  {
    "Character": "菂",
    "Pinyin": "dì",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7128,
    "FrequencyRank": 7870
  },
  {
    "Character": "畤",
    "Pinyin": "zhì",
    "Definition": "place for worshipping the haven",
    "Radical": "田",
    "RadicalIndex": "102.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7160,
    "FrequencyRank": undefined
  },
  {
    "Character": "淳",
    "Pinyin": "chún",
    "Definition": "honest, simple, unsophisticated; cyanogen; ethane dinitrile",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2427,
    "FrequencyRank": 3147
  },
  {
    "Character": "皎",
    "Pinyin": "jiǎo",
    "Definition": "white; bright, brilliant; clear",
    "Radical": "白",
    "RadicalIndex": "106.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4912,
    "FrequencyRank": 4351
  },
  {
    "Character": "殍",
    "Pinyin": "piǎo",
    "Definition": "to starve to death",
    "Radical": "歹",
    "RadicalIndex": "78.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4804,
    "FrequencyRank": 6053
  },
  {
    "Character": "眦",
    "Pinyin": "zì",
    "Definition": "corner of the eyes, eyesockets",
    "Radical": "目",
    "RadicalIndex": "109.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4810,
    "FrequencyRank": 5790
  },
  {
    "Character": "笙",
    "Pinyin": "shēng",
    "Definition": "small gourd-shaped musical instrument",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2320,
    "FrequencyRank": 3891
  },
  {
    "Character": "萃",
    "Pinyin": "cuì",
    "Definition": "dense, thick, close-set; to collect together",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4761,
    "FrequencyRank": 3503
  },
  {
    "Character": "谔",
    "Pinyin": "è",
    "Definition": "honest speech, straightforward",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5003,
    "FrequencyRank": 4341
  },
  {
    "Character": "菲",
    "Pinyin": "fēi",
    "Definition": "fragrant, luxuriant; the Philippines",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2202,
    "FrequencyRank": 1418
  },
  {
    "Character": "鸺",
    "Pinyin": "xiū",
    "Definition": "horned owl, scops chinensis",
    "Radical": "鳥",
    "RadicalIndex": "196.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7194,
    "FrequencyRank": 5975
  },
  {
    "Character": "桲",
    "Pinyin": "po",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7137,
    "FrequencyRank": undefined
  },
  {
    "Character": "婞",
    "Pinyin": "xìng",
    "Definition": "hate",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7262,
    "FrequencyRank": undefined
  },
  {
    "Character": "雩",
    "Pinyin": "yú",
    "Definition": "offer sacrifice for rain",
    "Radical": "雨",
    "RadicalIndex": "173.3",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4806,
    "FrequencyRank": 6612
  },
  {
    "Character": "庳",
    "Pinyin": "bì",
    "Definition": "a low-built house",
    "Radical": "广",
    "RadicalIndex": "53.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7222,
    "FrequencyRank": 7254
  },
  {
    "Character": "铮",
    "Pinyin": "zhēng",
    "Definition": "clanging sound; small gong",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4881,
    "FrequencyRank": 3154
  },
  {
    "Character": "脞",
    "Pinyin": "cuǒ",
    "Definition": "minced meat; trifles",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7211,
    "FrequencyRank": 6735
  },
  {
    "Character": "崧",
    "Pinyin": "sōng",
    "Definition": "high mountain; lofty, eminent",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7170,
    "FrequencyRank": 5014
  },
  {
    "Character": "铠",
    "Pinyin": "kǎi",
    "Definition": "armor, chain mail",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4870,
    "FrequencyRank": 4314
  },
  {
    "Character": "惘",
    "Pinyin": "wǎng",
    "Definition": "disconcerted, dejected, discouraged",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4987,
    "FrequencyRank": 3425
  },
  {
    "Character": "烺",
    "Pinyin": "lǎng",
    "Definition": "(said of fire) bright",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7231,
    "FrequencyRank": undefined
  },
  {
    "Character": "㫰",
    "Pinyin": "làng",
    "Definition": "light; bright; brilliant; clean; clever, to expose to sunlight, to dry something in an opening for ventilation area or a cool place",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7158,
    "FrequencyRank": undefined
  },
  {
    "Character": "梧",
    "Pinyin": "wú",
    "Definition": "Chinese parasoltree, Sterculia platanifolia",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2224,
    "FrequencyRank": 3356
  },
  {
    "Character": "啭",
    "Pinyin": "zhuàn",
    "Definition": "sing, chirp, warble, twitter",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4826,
    "FrequencyRank": 5005
  },
  {
    "Character": "烷",
    "Pinyin": "wán",
    "Definition": "alkane",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4961,
    "FrequencyRank": 4379
  },
  {
    "Character": "笞",
    "Pinyin": "chī",
    "Definition": "bamboo rod used for beatings",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4903,
    "FrequencyRank": 4583
  },
  {
    "Character": "捩",
    "Pinyin": "liè",
    "Definition": "twist with hands; snap, tear",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4738,
    "FrequencyRank": 5827
  },
  {
    "Character": "𫍲",
    "Pinyin": "xiǎo",
    "Definition": "scold, censure; lead people toward virtuous ways; small, little",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7255,
    "FrequencyRank": undefined
  },
  {
    "Character": "秸",
    "Pinyin": "jiē",
    "Definition": "stalks of millet, corn",
    "Radical": "禾",
    "RadicalIndex": "115.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2312,
    "FrequencyRank": 3840
  },
  {
    "Character": "庹",
    "Pinyin": "tuǒ",
    "Definition": undefined,
    "Radical": "广",
    "RadicalIndex": "53.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4936,
    "FrequencyRank": 5918
  },
  {
    "Character": "羟",
    "Pinyin": "qiǎng",
    "Definition": "hydroxide",
    "Radical": "羊",
    "RadicalIndex": "123.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4952,
    "FrequencyRank": 5059
  },
  {
    "Character": "蛃",
    "Pinyin": "bǐng",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7164,
    "FrequencyRank": undefined
  },
  {
    "Character": "脬",
    "Pinyin": "pāo",
    "Definition": "a bladder",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4921,
    "FrequencyRank": 6817
  },
  {
    "Character": "鄄",
    "Pinyin": "juàn",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4785,
    "FrequencyRank": 5591
  },
  {
    "Character": "壸",
    "Pinyin": "kǔn",
    "Definition": "palace corridor or passageway",
    "Radical": "士",
    "RadicalIndex": "33.1",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7117,
    "FrequencyRank": undefined
  },
  {
    "Character": "梭",
    "Pinyin": "suō",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2231,
    "FrequencyRank": 2681
  },
  {
    "Character": "谞",
    "Pinyin": "xū",
    "Definition": "knowledge; discrimination; treachery",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7256,
    "FrequencyRank": 6758
  },
  {
    "Character": "惇",
    "Pinyin": "dūn",
    "Definition": "be kind, cordial, sincere",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4991,
    "FrequencyRank": undefined
  },
  {
    "Character": "悴",
    "Pinyin": "cuì",
    "Definition": "suffer, become emaciated, haggard",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2447,
    "FrequencyRank": 3474
  },
  {
    "Character": "蚰",
    "Pinyin": "yóu",
    "Definition": "millipede",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4835,
    "FrequencyRank": 5823
  },
  {
    "Character": "唳",
    "Pinyin": "lì",
    "Definition": "cry of bird; cry",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4852,
    "FrequencyRank": 5661
  },
  {
    "Character": "赧",
    "Pinyin": "nǎn",
    "Definition": "blush, turn red",
    "Radical": "赤",
    "RadicalIndex": "155.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4724,
    "FrequencyRank": 5467
  },
  {
    "Character": "铞",
    "Pinyin": "diào",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7184,
    "FrequencyRank": 7748
  },
  {
    "Character": "谗",
    "Pinyin": "chán",
    "Definition": "slander, defame, misrepresent",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5006,
    "FrequencyRank": 4437
  },
  {
    "Character": "焓",
    "Pinyin": "hán",
    "Definition": "(Cant.) onomatopoetic, the sound of a fiercely-burning fire",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4958,
    "FrequencyRank": 4902
  },
  {
    "Character": "𫘦",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7276,
    "FrequencyRank": undefined
  },
  {
    "Character": "骖",
    "Pinyin": "cān",
    "Definition": "two outside ones in three horse",
    "Radical": "馬",
    "RadicalIndex": "187.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5039,
    "FrequencyRank": 6338
  },
  {
    "Character": "涪",
    "Pinyin": "fú",
    "Definition": "river in Sichuan province",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4978,
    "FrequencyRank": 5245
  },
  {
    "Character": "唪",
    "Pinyin": "fěng",
    "Definition": "to recite, to intone or chant",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7155,
    "FrequencyRank": 7253
  },
  {
    "Character": "馗",
    "Pinyin": "kuí",
    "Definition": "cheekbone; path, road; intersection",
    "Radical": "首",
    "RadicalIndex": "185.2",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4931,
    "FrequencyRank": 4618
  },
  {
    "Character": "梗",
    "Pinyin": "gěng",
    "Definition": "stem of flower, branch of plant",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2223,
    "FrequencyRank": 2919
  },
  {
    "Character": "衒",
    "Pinyin": "xuàn",
    "Definition": "brag; show off, promote oneself",
    "Radical": "行",
    "RadicalIndex": "144.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7202,
    "FrequencyRank": 7270
  },
  {
    "Character": "笪",
    "Pinyin": "dá",
    "Definition": "a coarse mat of rushes or bamboo",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4896,
    "FrequencyRank": 5231
  },
  {
    "Character": "涿",
    "Pinyin": "zhuō",
    "Definition": "drip, dribble, trickle",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4969,
    "FrequencyRank": 4444
  },
  {
    "Character": "皑",
    "Pinyin": "ái",
    "Definition": "brilliant white",
    "Radical": "白",
    "RadicalIndex": "106.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4911,
    "FrequencyRank": 3952
  },
  {
    "Character": "笾",
    "Pinyin": "biān",
    "Definition": "bamboo container for food",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4902,
    "FrequencyRank": 5252
  },
  {
    "Character": "偕",
    "Pinyin": "xié",
    "Definition": "together; be in order",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4906,
    "FrequencyRank": 3705
  },
  {
    "Character": "谒",
    "Pinyin": "yè",
    "Definition": "visit, pay respects",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5002,
    "FrequencyRank": 3389
  },
  {
    "Character": "寇",
    "Pinyin": "kòu",
    "Definition": "bandits, thieves; enemy; invade",
    "Radical": "宀",
    "RadicalIndex": "40.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2451,
    "FrequencyRank": 2567
  },
  {
    "Character": "骓",
    "Pinyin": "zhuī",
    "Definition": "piebald horse",
    "Radical": "馬",
    "RadicalIndex": "187.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5034,
    "FrequencyRank": 5258
  },
  {
    "Character": "庼",
    "Pinyin": "qǐng",
    "Definition": undefined,
    "Radical": "广",
    "RadicalIndex": "53.11",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7221,
    "FrequencyRank": undefined
  },
  {
    "Character": "晦",
    "Pinyin": "huì",
    "Definition": "dark, unclear, obscure; night",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2269,
    "FrequencyRank": 3075
  },
  {
    "Character": "渚",
    "Pinyin": "zhǔ",
    "Definition": "small sand bank, islet",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4964,
    "FrequencyRank": 4879
  },
  {
    "Character": "眺",
    "Pinyin": "tiào",
    "Definition": "look at, gaze at, scan, survey",
    "Radical": "目",
    "RadicalIndex": "109.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4814,
    "FrequencyRank": 3351
  },
  {
    "Character": "偾",
    "Pinyin": "fèn",
    "Definition": "ruin, cause fail; overthrown",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4904,
    "FrequencyRank": 5778
  },
  {
    "Character": "笸",
    "Pinyin": "pǒ",
    "Definition": "flat basket for grain",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4895,
    "FrequencyRank": 5329
  },
  {
    "Character": "偡",
    "Pinyin": "zhàn",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7193,
    "FrequencyRank": undefined
  },
  {
    "Character": "铥",
    "Pinyin": "diū",
    "Definition": "thulium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7185,
    "FrequencyRank": 7445
  },
  {
    "Character": "硊",
    "Pinyin": "wěi",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7149,
    "FrequencyRank": 9069
  },
  {
    "Character": "厩",
    "Pinyin": "jiù",
    "Definition": "stable; barnyard",
    "Radical": "厂",
    "RadicalIndex": "27.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4800,
    "FrequencyRank": 4181
  },
  {
    "Character": "铗",
    "Pinyin": "jiá",
    "Definition": "tongs, pincers; dagger; sword",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4867,
    "FrequencyRank": 6536
  },
  {
    "Character": "绫",
    "Pinyin": "líng",
    "Definition": "thin silk, damask silk",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5027,
    "FrequencyRank": 3262
  },
  {
    "Character": "脘",
    "Pinyin": "wǎn",
    "Definition": "internal cavity of stomach",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4922,
    "FrequencyRank": 5766
  },
  {
    "Character": "桫",
    "Pinyin": "suō",
    "Definition": "horse chestnut",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4778,
    "FrequencyRank": 6255
  },
  {
    "Character": "硖",
    "Pinyin": "xiá",
    "Definition": "(archaic) town in Hebei province",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4792,
    "FrequencyRank": 5856
  },
  {
    "Character": "㥄",
    "Pinyin": "líng",
    "Definition": "to pity; to commiserate; to have sympathy or compassion for, scared; afraid; fearful",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7242,
    "FrequencyRank": undefined
  },
  {
    "Character": "胬",
    "Pinyin": "nǔ",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5022,
    "FrequencyRank": 6473
  },
  {
    "Character": "跂",
    "Pinyin": "qí",
    "Definition": "creeping, crawling",
    "Radical": "足",
    "RadicalIndex": "157.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7163,
    "FrequencyRank": 7621
  },
  {
    "Character": "谖",
    "Pinyin": "xuān",
    "Definition": "forget; lie, cheat, deceive",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5005,
    "FrequencyRank": 4576
  },
  {
    "Character": "脲",
    "Pinyin": "niào",
    "Definition": "urea",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4923,
    "FrequencyRank": 5394
  },
  {
    "Character": "逶",
    "Pinyin": "wēi",
    "Definition": "winding, curving; swagger",
    "Radical": "辵",
    "RadicalIndex": "162.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4892,
    "FrequencyRank": 5185
  },
  {
    "Character": "捻",
    "Pinyin": "niǎn",
    "Definition": "to twist or nip with the fingers",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2178,
    "FrequencyRank": 3471
  },
  {
    "Character": "婫",
    "Pinyin": "kūn",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7269,
    "FrequencyRank": undefined
  },
  {
    "Character": "铙",
    "Pinyin": "náo",
    "Definition": "cymbals; hand bell; disturb",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4868,
    "FrequencyRank": 6373
  },
  {
    "Character": "捯",
    "Pinyin": "dáo",
    "Definition": undefined,
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4719,
    "FrequencyRank": undefined
  },
  {
    "Character": "挲",
    "Pinyin": "sā",
    "Definition": "to feel or fondle with the fingers",
    "Radical": "手",
    "RadicalIndex": "64.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4971,
    "FrequencyRank": 5230
  },
  {
    "Character": "悆",
    "Pinyin": "yù",
    "Definition": "happy",
    "Radical": "心",
    "RadicalIndex": "61.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7206,
    "FrequencyRank": undefined
  },
  {
    "Character": "珺",
    "Pinyin": "jùn",
    "Definition": "beautiful jade",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7105,
    "FrequencyRank": undefined
  },
  {
    "Character": "梽",
    "Pinyin": "zhì",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7136,
    "FrequencyRank": undefined
  },
  {
    "Character": "馃",
    "Pinyin": "guǒ",
    "Definition": "cakes, biscuits, pastry",
    "Radical": "食",
    "RadicalIndex": "184.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4932,
    "FrequencyRank": 5533
  },
  {
    "Character": "痊",
    "Pinyin": "quán",
    "Definition": "be healed, be cured; recover",
    "Radical": "疒",
    "RadicalIndex": "104.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2381,
    "FrequencyRank": 3618
  },
  {
    "Character": "菁",
    "Pinyin": "jīng",
    "Definition": "flower of leek family; turnip",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4746,
    "FrequencyRank": 3321
  },
  {
    "Character": "啁",
    "Pinyin": "zhāo",
    "Definition": "chirp, twitter, twittering",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4842,
    "FrequencyRank": 5549
  },
  {
    "Character": "琏",
    "Pinyin": "liǎn",
    "Definition": "vessel used to hold grain offerings",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4713,
    "FrequencyRank": 3817
  },
  {
    "Character": "萤",
    "Pinyin": "yíng",
    "Definition": "glow-worm, luminous insect",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2213,
    "FrequencyRank": 3434
  },
  {
    "Character": "欸",
    "Pinyin": "āi",
    "Definition": "sigh; an exclamatory sound",
    "Radical": "欠",
    "RadicalIndex": "76.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5026,
    "FrequencyRank": undefined
  },
  {
    "Character": "琎",
    "Pinyin": "jìn",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7099,
    "FrequencyRank": undefined
  },
  {
    "Character": "勚",
    "Pinyin": "yì",
    "Definition": "work hard; belabored; toil",
    "Radical": "力",
    "RadicalIndex": "19.12",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7125,
    "FrequencyRank": undefined
  },
  {
    "Character": "掳",
    "Pinyin": "lǔ",
    "Definition": "capture, seize",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4720,
    "FrequencyRank": 3468
  },
  {
    "Character": "鸿",
    "Pinyin": "hóng",
    "Definition": "species of wild swan; vast",
    "Radical": "鳥",
    "RadicalIndex": "196.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2412,
    "FrequencyRank": 1924
  },
  {
    "Character": "揶",
    "Pinyin": "yé",
    "Definition": "make fun of, ridicule",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4716,
    "FrequencyRank": 5389
  },
  {
    "Character": "偰",
    "Pinyin": "xiè",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7192,
    "FrequencyRank": undefined
  },
  {
    "Character": "笥",
    "Pinyin": "sì",
    "Definition": "a hamper, wicker basket",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4899,
    "FrequencyRank": 4674
  },
  {
    "Character": "觋",
    "Pinyin": "xí",
    "Definition": "wizard",
    "Radical": "見",
    "RadicalIndex": "147.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4773,
    "FrequencyRank": 6452
  },
  {
    "Character": "鸷",
    "Pinyin": "zhì",
    "Definition": "hawk, vulture",
    "Radical": "鳥",
    "RadicalIndex": "196.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4732,
    "FrequencyRank": 5330
  },
  {
    "Character": "羕",
    "Pinyin": "yàng",
    "Definition": undefined,
    "Radical": "羊",
    "RadicalIndex": "123.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7229,
    "FrequencyRank": 8963
  },
  {
    "Character": "隋",
    "Pinyin": "suí",
    "Definition": "Sui dynasty; surname",
    "Radical": "阜",
    "RadicalIndex": "170.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2474,
    "FrequencyRank": 2828
  },
  {
    "Character": "惙",
    "Pinyin": "chuò",
    "Definition": "sad, melancholy, grieving, mournful",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7246,
    "FrequencyRank": undefined
  },
  {
    "Character": "菰",
    "Pinyin": "gū",
    "Definition": "wild rice; Zizania latifolia",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4768,
    "FrequencyRank": 6129
  },
  {
    "Character": "郾",
    "Pinyin": "yǎn",
    "Definition": "county in Henan province",
    "Radical": "邑",
    "RadicalIndex": "163.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4781,
    "FrequencyRank": 5770
  },
  {
    "Character": "掼",
    "Pinyin": "guàn",
    "Definition": "throw ground; know, be familiar",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4744,
    "FrequencyRank": 5206
  },
  {
    "Character": "萘",
    "Pinyin": "nài",
    "Definition": "naphthalene",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4750,
    "FrequencyRank": 5630
  },
  {
    "Character": "崤",
    "Pinyin": "xiáo",
    "Definition": "mountain in Henan",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4860,
    "FrequencyRank": 5905
  },
  {
    "Character": "阍",
    "Pinyin": "hūn",
    "Definition": "gatekeeper; gate, door",
    "Radical": "門",
    "RadicalIndex": "169.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4950,
    "FrequencyRank": 5275
  },
  {
    "Character": "袈",
    "Pinyin": "jiā",
    "Definition": "Buddhist cassock",
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5023,
    "FrequencyRank": 4833
  },
  {
    "Character": "彗",
    "Pinyin": "huì",
    "Definition": "broomstick; comet",
    "Radical": "彐",
    "RadicalIndex": "58.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4709,
    "FrequencyRank": 3591
  },
  {
    "Character": "崒",
    "Pinyin": "zú",
    "Definition": "rocky peaks; lofty and dangerous",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7173,
    "FrequencyRank": undefined
  },
  {
    "Character": "埴",
    "Pinyin": "zhí",
    "Definition": "soil with large clay content",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4717,
    "FrequencyRank": 6867
  },
  {
    "Character": "谛",
    "Pinyin": "dì",
    "Definition": "careful, attentive",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5008,
    "FrequencyRank": 3338
  },
  {
    "Character": "婢",
    "Pinyin": "bì",
    "Definition": "servant girl; your servant",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5020,
    "FrequencyRank": 3082
  },
  {
    "Character": "烯",
    "Pinyin": "xī",
    "Definition": "alkene",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4957,
    "FrequencyRank": 4277
  },
  {
    "Character": "笺",
    "Pinyin": "jiān",
    "Definition": "note, memo; stationery; comments",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4893,
    "FrequencyRank": 3731
  },
  {
    "Character": "猗",
    "Pinyin": "yī",
    "Definition": "exclamation of admiration",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4925,
    "FrequencyRank": 5853
  },
  {
    "Character": "啴",
    "Pinyin": "chǎn",
    "Definition": "pant; many",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7168,
    "FrequencyRank": undefined
  },
  {
    "Character": "琈",
    "Pinyin": "fú",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7103,
    "FrequencyRank": undefined
  },
  {
    "Character": "笱",
    "Pinyin": "gǒu",
    "Definition": "a basket trap for fish, placed in the opening of a weir",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7190,
    "FrequencyRank": 5449
  },
  {
    "Character": "菔",
    "Pinyin": "fú",
    "Definition": "turnip",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4758,
    "FrequencyRank": 5581
  },
  {
    "Character": "喏",
    "Pinyin": "nuò",
    "Definition": "respectful reply of assent to superiors",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4818,
    "FrequencyRank": 4174
  },
  {
    "Character": "𫟹",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7178,
    "FrequencyRank": undefined
  },
  {
    "Character": "萑",
    "Pinyin": "huán",
    "Definition": "grass used for making mats; Huanpu, place famous for robbers",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4756,
    "FrequencyRank": 5796
  },
  {
    "Character": "菡",
    "Pinyin": "hàn",
    "Definition": "buds, lotus buds",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4769,
    "FrequencyRank": 6167
  },
  {
    "Character": "惔",
    "Pinyin": "tán",
    "Definition": "cheerful",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7244,
    "FrequencyRank": undefined
  },
  {
    "Character": "淯",
    "Pinyin": "yù",
    "Definition": "name of river; old name of Baihe in Henan",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7238,
    "FrequencyRank": undefined
  },
  {
    "Character": "萆",
    "Pinyin": "bì",
    "Definition": "the castor-oil plant also used for other plants",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7127,
    "FrequencyRank": 6375
  },
  {
    "Character": "惬",
    "Pinyin": "qiè",
    "Definition": "be satisfied, be comfortable",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4983,
    "FrequencyRank": 3976
  },
  {
    "Character": "硇",
    "Pinyin": "náo",
    "Definition": "a kind of mineral",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4795,
    "FrequencyRank": 6047
  },
  {
    "Character": "啵",
    "Pinyin": "bo",
    "Definition": "phonetic",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4849,
    "FrequencyRank": 4244
  },
  {
    "Character": "焉",
    "Pinyin": "yān",
    "Definition": "thereupon, then; how? why? where?",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2169,
    "FrequencyRank": 2526
  },
  {
    "Character": "𫟅",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7278,
    "FrequencyRank": undefined
  },
  {
    "Character": "蛉",
    "Pinyin": "líng",
    "Definition": "dragonfly, libellulidae",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4839,
    "FrequencyRank": 4563
  },
  {
    "Character": "唷",
    "Pinyin": "yō",
    "Definition": "final particle",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4847,
    "FrequencyRank": 3964
  },
  {
    "Character": "晡",
    "Pinyin": "bū",
    "Definition": "late afternoon",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4812,
    "FrequencyRank": 6142
  },
  {
    "Character": "掊",
    "Pinyin": "póu",
    "Definition": "to extract; injure",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4735,
    "FrequencyRank": 6609
  },
  {
    "Character": "龁",
    "Pinyin": "hé",
    "Definition": "gnaw, bite, nibble",
    "Radical": "齒",
    "RadicalIndex": "211.3",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7153,
    "FrequencyRank": 6851
  },
  {
    "Character": "婵",
    "Pinyin": "chán",
    "Definition": "beautiful, lovely, pretty, graceful",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5021,
    "FrequencyRank": 3970
  },
  {
    "Character": "啜",
    "Pinyin": "chuài",
    "Definition": "sip, suck up; sob, weep",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4854,
    "FrequencyRank": 4008
  },
  {
    "Character": "梓",
    "Pinyin": "zǐ",
    "Definition": "catalpa ovata",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4776,
    "FrequencyRank": 3750
  },
  {
    "Character": "硎",
    "Pinyin": "xíng",
    "Definition": "whetstone",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4789,
    "FrequencyRank": 4849
  },
  {
    "Character": "龛",
    "Pinyin": "kān",
    "Definition": "niche, shrine",
    "Radical": "龍",
    "RadicalIndex": "212.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4919,
    "FrequencyRank": 4015
  },
  {
    "Character": "鸾",
    "Pinyin": "luán",
    "Definition": "fabulous bird",
    "Radical": "鳥",
    "RadicalIndex": "196.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4934,
    "FrequencyRank": 3912
  },
  {
    "Character": "淦",
    "Pinyin": "gàn",
    "Definition": "river in Jiangxi province; water leaking into a boat",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4975,
    "FrequencyRank": 4611
  },
  {
    "Character": "捭",
    "Pinyin": "bǎi",
    "Definition": "to open; to spread out",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4726,
    "FrequencyRank": 5900
  },
  {
    "Character": "赊",
    "Pinyin": "shē",
    "Definition": "buy and sell on credit, distant",
    "Radical": "貝",
    "RadicalIndex": "154.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4864,
    "FrequencyRank": 4501
  },
  {
    "Character": "辄",
    "Pinyin": "zhé",
    "Definition": "sides of chariot where weapons",
    "Radical": "車",
    "RadicalIndex": "159.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4807,
    "FrequencyRank": 3557
  },
  {
    "Character": "圉",
    "Pinyin": "yǔ",
    "Definition": "stable, corral, enclosure; frontier, border",
    "Radical": "囗",
    "RadicalIndex": "31.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4837,
    "FrequencyRank": 6542
  },
  {
    "Character": "淖",
    "Pinyin": "nào",
    "Definition": "slush; mud",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4970,
    "FrequencyRank": 3966
  },
  {
    "Character": "谙",
    "Pinyin": "ān",
    "Definition": "versed in, fully acquainted with",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5007,
    "FrequencyRank": 3927
  },
  {
    "Character": "铱",
    "Pinyin": "yī",
    "Definition": "iridium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4884,
    "FrequencyRank": 4787
  },
  {
    "Character": "唼",
    "Pinyin": "shà",
    "Definition": "speaking evil. gobbling sound made by ducks",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4846,
    "FrequencyRank": 7021
  },
  {
    "Character": "偓",
    "Pinyin": "wò",
    "Definition": "to fuss",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7200,
    "FrequencyRank": undefined
  },
  {
    "Character": "勔",
    "Pinyin": "miǎn",
    "Definition": "endeavor, make effort; urge",
    "Radical": "力",
    "RadicalIndex": "19.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7151,
    "FrequencyRank": undefined
  },
  {
    "Character": "菽",
    "Pinyin": "shū",
    "Definition": "beans and peas, collectively",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4752,
    "FrequencyRank": 5618
  },
  {
    "Character": "娼",
    "Pinyin": "chāng",
    "Definition": "prostitute, harlot",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5019,
    "FrequencyRank": 3578
  },
  {
    "Character": "菀",
    "Pinyin": "wǎn",
    "Definition": "luxuriance of growth",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4766,
    "FrequencyRank": 5320
  },
  {
    "Character": "匏",
    "Pinyin": "páo",
    "Definition": "gourd; musical instrument",
    "Radical": "勹",
    "RadicalIndex": "20.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4799,
    "FrequencyRank": 5454
  },
  {
    "Character": "婧",
    "Pinyin": "jìng",
    "Definition": "modest; supple",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5016,
    "FrequencyRank": 5759
  },
  {
    "Character": "铷",
    "Pinyin": "rú",
    "Definition": "rubidium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4887,
    "FrequencyRank": 6658
  },
  {
    "Character": "舲",
    "Pinyin": "líng",
    "Definition": "small boat with windows; houseboat",
    "Radical": "舟",
    "RadicalIndex": "137.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7204,
    "FrequencyRank": 7327
  },
  {
    "Character": "袤",
    "Pinyin": "mào",
    "Definition": "longitude, lengthwise; length",
    "Radical": "衣",
    "RadicalIndex": "145.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4944,
    "FrequencyRank": 4129
  },
  {
    "Character": "盔",
    "Pinyin": "kuī",
    "Definition": "helmet; bowl; basin",
    "Radical": "皿",
    "RadicalIndex": "108.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2243,
    "FrequencyRank": 3030
  },
  {
    "Character": "掎",
    "Pinyin": "jǐ",
    "Definition": "drag aside, pull; drag one foot",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7110,
    "FrequencyRank": 7551
  },
  {
    "Character": "趺",
    "Pinyin": "fū",
    "Definition": "sit cross-legged; back of the foo",
    "Radical": "足",
    "RadicalIndex": "157.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4828,
    "FrequencyRank": 4669
  },
  {
    "Character": "硅",
    "Pinyin": "guī",
    "Definition": "silicon",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2240,
    "FrequencyRank": 3331
  },
  {
    "Character": "唿",
    "Pinyin": "hū",
    "Definition": "sad; (Cant.) a bit, part",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4844,
    "FrequencyRank": 5771
  },
  {
    "Character": "𫓯",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7177,
    "FrequencyRank": undefined
  },
  {
    "Character": "惟",
    "Pinyin": "wéi",
    "Definition": "but, however, nevertheless; only",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2444,
    "FrequencyRank": 1856
  },
  {
    "Character": "啖",
    "Pinyin": "dàn",
    "Definition": "eat, feed; chew, bite; entice",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4848,
    "FrequencyRank": 5479
  },
  {
    "Character": "赈",
    "Pinyin": "zhèn",
    "Definition": "relieve, aid distressed; rich",
    "Radical": "貝",
    "RadicalIndex": "154.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4863,
    "FrequencyRank": 4123
  },
  {
    "Character": "萚",
    "Pinyin": "tuò",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.16",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7121,
    "FrequencyRank": 9117
  },
  {
    "Character": "菍",
    "Pinyin": "niè",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7129,
    "FrequencyRank": 7368
  },
  {
    "Character": "袷",
    "Pinyin": "jiá",
    "Definition": "lined garment",
    "Radical": "衣",
    "RadicalIndex": "145.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5000,
    "FrequencyRank": 5322
  },
  {
    "Character": "鸸",
    "Pinyin": "ér",
    "Definition": "swallow",
    "Radical": "鳥",
    "RadicalIndex": "196.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4797,
    "FrequencyRank": 6082
  },
  {
    "Character": "焌",
    "Pinyin": "jùn",
    "Definition": "to light",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7232,
    "FrequencyRank": undefined
  },
  {
    "Character": "梏",
    "Pinyin": "gù",
    "Definition": "handcuffs, manacles, fetters",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4772,
    "FrequencyRank": 4665
  },
  {
    "Character": "猡",
    "Pinyin": "luó",
    "Definition": "pig; Lolo aboringinal tribe",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4926,
    "FrequencyRank": 6045
  },
  {
    "Character": "窕",
    "Pinyin": "tiǎo",
    "Definition": "slender; quiet and modest, charming",
    "Radical": "穴",
    "RadicalIndex": "116.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4993,
    "FrequencyRank": 4684
  },
  {
    "Character": "晙",
    "Pinyin": "jùn",
    "Definition": "early; bright; respect",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7159,
    "FrequencyRank": undefined
  },
  {
    "Character": "笤",
    "Pinyin": "tiáo",
    "Definition": "a broom, a besom",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4900,
    "FrequencyRank": 4957
  },
  {
    "Character": "悰",
    "Pinyin": "cóng",
    "Definition": "enjoy, amuse, please; joy",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7245,
    "FrequencyRank": undefined
  },
  {
    "Character": "徜",
    "Pinyin": "cháng",
    "Definition": "walking and fro; lingering",
    "Radical": "彳",
    "RadicalIndex": "60.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4914,
    "FrequencyRank": 5620
  },
  {
    "Character": "痔",
    "Pinyin": "zhì",
    "Definition": "hemorrhoids, piles",
    "Radical": "疒",
    "RadicalIndex": "104.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4938,
    "FrequencyRank": 3768
  },
  {
    "Character": "娵",
    "Pinyin": "jū",
    "Definition": "star",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7263,
    "FrequencyRank": undefined
  },
  {
    "Character": "敕",
    "Pinyin": "chì",
    "Definition": "an imperial order or decree",
    "Radical": "攴",
    "RadicalIndex": "66.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4783,
    "FrequencyRank": 3435
  },
  {
    "Character": "悻",
    "Pinyin": "xìng",
    "Definition": "anger, vexation; angry, indignant",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4984,
    "FrequencyRank": 3677
  },
  {
    "Character": "脯",
    "Pinyin": "pú",
    "Definition": "dried meat; preserved fruits",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2357,
    "FrequencyRank": 3017
  },
  {
    "Character": "跄",
    "Pinyin": "qiāng",
    "Definition": "walk rapidly",
    "Radical": "足",
    "RadicalIndex": "157.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4830,
    "FrequencyRank": 3330
  },
  {
    "Character": "隍",
    "Pinyin": "huáng",
    "Definition": "dry ditch, dry moat",
    "Radical": "阜",
    "RadicalIndex": "170.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5014,
    "FrequencyRank": 4334
  },
  {
    "Character": "惚",
    "Pinyin": "hū",
    "Definition": "absent-minded, confused",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4990,
    "FrequencyRank": 3293
  },
  {
    "Character": "铖",
    "Pinyin": "chéng",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7181,
    "FrequencyRank": 6488
  },
  {
    "Character": "菪",
    "Pinyin": "dàng",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4764,
    "FrequencyRank": 6134
  },
  {
    "Character": "聃",
    "Pinyin": "dān",
    "Definition": "ears without rims; a personal name",
    "Radical": "耳",
    "RadicalIndex": "128.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4745,
    "FrequencyRank": 5598
  },
  {
    "Character": "铕",
    "Pinyin": "yǒu",
    "Definition": "europium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7179,
    "FrequencyRank": 6793
  },
  {
    "Character": "铪",
    "Pinyin": "hā",
    "Definition": "hafnium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4878,
    "FrequencyRank": 6874
  },
  {
    "Character": "猕",
    "Pinyin": "mí",
    "Definition": "macacus monkey",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4930,
    "FrequencyRank": 4681
  },
  {
    "Character": "脶",
    "Pinyin": "luó",
    "Definition": "lines",
    "Radical": "肉",
    "RadicalIndex": "130.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7210,
    "FrequencyRank": 7997
  },
  {
    "Character": "䜣",
    "Pinyin": "xīn",
    "Definition": "(simplified form of 訢) (same as 欣) joy; delight; happy",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 6549,
    "FrequencyRank": 6088
  },
  {
    "Character": "棂",
    "Pinyin": "líng",
    "Definition": "carved or patterned window sills",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4779,
    "FrequencyRank": 5016
  },
  {
    "Character": "斛",
    "Pinyin": "hú",
    "Definition": "dry measure equal to some five or ten dou (舒)",
    "Radical": "斗",
    "RadicalIndex": "68.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4929,
    "FrequencyRank": 4531
  },
  {
    "Character": "萸",
    "Pinyin": "yú",
    "Definition": "dogwood",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4755,
    "FrequencyRank": 5149
  },
  {
    "Character": "崞",
    "Pinyin": "guō",
    "Definition": "mountain in Shanxi",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7172,
    "FrequencyRank": 5793
  },
  {
    "Character": "裆",
    "Pinyin": "dāng",
    "Definition": "crotch or seat of pants; pants",
    "Radical": "衣",
    "RadicalIndex": "145.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4999,
    "FrequencyRank": 4099
  },
  {
    "Character": "菉",
    "Pinyin": "lù",
    "Definition": "the green bamboo; greenish bamboo",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7133,
    "FrequencyRank": 7469
  },
  {
    "Character": "绱",
    "Pinyin": "shàng",
    "Definition": "the piece of leather used for soles on Chinese shoes; a patch",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5031,
    "FrequencyRank": 5179
  },
  {
    "Character": "婊",
    "Pinyin": "biǎo",
    "Definition": "whore, prostitute",
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5017,
    "FrequencyRank": 4237
  },
  {
    "Character": "悸",
    "Pinyin": "jì",
    "Definition": "fearful, apprehensive, perturbed",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4988,
    "FrequencyRank": 3561
  },
  {
    "Character": "𦰡",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 6794,
    "FrequencyRank": undefined
  },
  {
    "Character": "淜",
    "Pinyin": "píng",
    "Definition": "roar of dashing waves",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7236,
    "FrequencyRank": undefined
  },
  {
    "Character": "啶",
    "Pinyin": "dìng",
    "Definition": "phonetic used in pyridine and pyrimidine",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4850,
    "FrequencyRank": 4785
  },
  {
    "Character": "艴",
    "Pinyin": "fú",
    "Definition": "the countenance changing",
    "Radical": "色",
    "RadicalIndex": "139.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7257,
    "FrequencyRank": 7992
  },
  {
    "Character": "焐",
    "Pinyin": "wù",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4956,
    "FrequencyRank": 5648
  },
  {
    "Character": "淑",
    "Pinyin": "shū",
    "Definition": "good, pure, virtuous, charming",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2418,
    "FrequencyRank": 2643
  },
  {
    "Character": "鄃",
    "Pinyin": "shū",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7207,
    "FrequencyRank": undefined
  },
  {
    "Character": "铵",
    "Pinyin": "ǎn",
    "Definition": "ammonium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4886,
    "FrequencyRank": 5095
  },
  {
    "Character": "裉",
    "Pinyin": "kèn",
    "Definition": "seam in a garment",
    "Radical": "衣",
    "RadicalIndex": "145.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5001,
    "FrequencyRank": 5732
  },
  {
    "Character": "崦",
    "Pinyin": "yān",
    "Definition": "a mountain in Kansu, where there is a cave into which the sun is said to sink at night",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4857,
    "FrequencyRank": 6168
  },
  {
    "Character": "晢",
    "Pinyin": "zhé",
    "Definition": "light of stars; shine",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7113,
    "FrequencyRank": undefined
  },
  {
    "Character": "崡",
    "Pinyin": "hán",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7175,
    "FrequencyRank": undefined
  },
  {
    "Character": "琄",
    "Pinyin": "xuàn",
    "Definition": "scabbard; traces, reins",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7102,
    "FrequencyRank": undefined
  },
  {
    "Character": "菱",
    "Pinyin": "líng",
    "Definition": "water-chestnut, water caltrop",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2199,
    "FrequencyRank": 2802
  },
  {
    "Character": "觖",
    "Pinyin": "jué",
    "Definition": "to long for; dissatisfied to criticize",
    "Radical": "角",
    "RadicalIndex": "148.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7218,
    "FrequencyRank": 7451
  },
  {
    "Character": "掞",
    "Pinyin": "shàn",
    "Definition": "easy, smooth; quiet; suave",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7115,
    "FrequencyRank": undefined
  },
  {
    "Character": "厣",
    "Pinyin": "yǎn",
    "Definition": "shell",
    "Radical": "厂",
    "RadicalIndex": "27.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7144,
    "FrequencyRank": 5160
  },
  {
    "Character": "硚",
    "Pinyin": "qiáo",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.12",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7148,
    "FrequencyRank": 7244
  },
  {
    "Character": "捺",
    "Pinyin": "nà",
    "Definition": "to press down heavily with the fingers",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2165,
    "FrequencyRank": 3841
  },
  {
    "Character": "铚",
    "Pinyin": "zhì",
    "Definition": "a sickle",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7183,
    "FrequencyRank": 7035
  },
  {
    "Character": "帼",
    "Pinyin": "guó",
    "Definition": undefined,
    "Radical": "巾",
    "RadicalIndex": "50.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4858,
    "FrequencyRank": 4778
  },
  {
    "Character": "铡",
    "Pinyin": "zhá",
    "Definition": "sickle for cutting grass or hay",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4871,
    "FrequencyRank": 4496
  },
  {
    "Character": "绺",
    "Pinyin": "liǔ",
    "Definition": "skein; tuft, lock; wrinkle",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5036,
    "FrequencyRank": 4492
  },
  {
    "Character": "铴",
    "Pinyin": "tāng",
    "Definition": "gong",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7186,
    "FrequencyRank": undefined
  },
  {
    "Character": "淠",
    "Pinyin": "pì",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4972,
    "FrequencyRank": 6341
  },
  {
    "Character": "菏",
    "Pinyin": "hé",
    "Definition": "river in Shandong province",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4762,
    "FrequencyRank": 4926
  },
  {
    "Character": "逭",
    "Pinyin": "huàn",
    "Definition": "to escape from; to flee, to avoid",
    "Radical": "辵",
    "RadicalIndex": "162.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7248,
    "FrequencyRank": 5955
  },
  {
    "Character": "偭",
    "Pinyin": "miǎn",
    "Definition": "transgress",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7195,
    "FrequencyRank": undefined
  },
  {
    "Character": "扈",
    "Pinyin": "hù",
    "Definition": "escort, retinue; insolent",
    "Radical": "戶",
    "RadicalIndex": "63.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4996,
    "FrequencyRank": 3917
  },
  {
    "Character": "铣",
    "Pinyin": "xǐ",
    "Definition": "mill",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4873,
    "FrequencyRank": 4786
  },
  {
    "Character": "埽",
    "Pinyin": "sào",
    "Definition": "broom; to sweep, clear away",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4742,
    "FrequencyRank": 4582
  },
  {
    "Character": "涴",
    "Pinyin": "wò",
    "Definition": "daub",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7240,
    "FrequencyRank": undefined
  },
  {
    "Character": "绶",
    "Pinyin": "shòu",
    "Definition": "silk ribbon attached as a seal",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5035,
    "FrequencyRank": 4098
  },
  {
    "Character": "谏",
    "Pinyin": "jiàn",
    "Definition": "remonstrate, admonish",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4995,
    "FrequencyRank": 3050
  },
  {
    "Character": "聆",
    "Pinyin": "líng",
    "Definition": "listen, hear",
    "Radical": "耳",
    "RadicalIndex": "128.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2194,
    "FrequencyRank": 3770
  },
  {
    "Character": "铩",
    "Pinyin": "shā",
    "Definition": "moult",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4877,
    "FrequencyRank": 4683
  },
  {
    "Character": "庾",
    "Pinyin": "yǔ",
    "Definition": "granary; storehouse",
    "Radical": "广",
    "RadicalIndex": "53.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4937,
    "FrequencyRank": 4997
  },
  {
    "Character": "菝",
    "Pinyin": "bá",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7120,
    "FrequencyRank": 6493
  },
  {
    "Character": "偈",
    "Pinyin": "jì",
    "Definition": "brave; martial; hasty; scudding",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4907,
    "FrequencyRank": 4283
  },
  {
    "Character": "疵",
    "Pinyin": "cī",
    "Definition": "flaw, fault, defect; disease",
    "Radical": "疒",
    "RadicalIndex": "104.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4940,
    "FrequencyRank": 3986
  },
  {
    "Character": "崚",
    "Pinyin": "léng",
    "Definition": "hilly, steep, rugged",
    "Radical": "山",
    "RadicalIndex": "46.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4856,
    "FrequencyRank": undefined
  },
  {
    "Character": "桷",
    "Pinyin": "jué",
    "Definition": "rafter; malus toringo",
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4775,
    "FrequencyRank": 6245
  },
  {
    "Character": "萜",
    "Pinyin": "tiē",
    "Definition": "C5H8",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4754,
    "FrequencyRank": 6551
  },
  {
    "Character": "梿",
    "Pinyin": "lián",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4771,
    "FrequencyRank": undefined
  },
  {
    "Character": "啷",
    "Pinyin": "lāng",
    "Definition": "a clanging or rattling sound",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4851,
    "FrequencyRank": 4570
  },
  {
    "Character": "麸",
    "Pinyin": "fū",
    "Definition": "bran",
    "Radical": "麥",
    "RadicalIndex": "199.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4715,
    "FrequencyRank": 5774
  },
  {
    "Character": "掂",
    "Pinyin": "diān",
    "Definition": "heft, hold or weigh in palm",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2183,
    "FrequencyRank": 4020
  },
  {
    "Character": "眵",
    "Pinyin": "chī",
    "Definition": "eyes diseased and dim",
    "Radical": "目",
    "RadicalIndex": "109.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4815,
    "FrequencyRank": 7318
  },
  {
    "Character": "㙍",
    "Pinyin": "duō",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7118,
    "FrequencyRank": undefined
  },
  {
    "Character": "隅",
    "Pinyin": "yú",
    "Definition": "corner, nook, remote place",
    "Radical": "阜",
    "RadicalIndex": "170.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2478,
    "FrequencyRank": 3387
  },
  {
    "Character": "酞",
    "Pinyin": "tài",
    "Definition": "phthalein",
    "Radical": "酉",
    "RadicalIndex": "164.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4786,
    "FrequencyRank": 6556
  },
  {
    "Character": "啮",
    "Pinyin": "niè",
    "Definition": "bite, gnaw",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4829,
    "FrequencyRank": 4400
  },
  {
    "Character": "淝",
    "Pinyin": "féi",
    "Definition": "name of an affluent of the Poyang Lake",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4976,
    "FrequencyRank": 5083
  },
  {
    "Character": "隗",
    "Pinyin": "kuí",
    "Definition": "high; lofty; surname",
    "Radical": "阜",
    "RadicalIndex": "170.1",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5015,
    "FrequencyRank": 4940
  },
  {
    "Character": "棻",
    "Pinyin": "fēn",
    "Definition": "kind of wood from which perfume",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4757,
    "FrequencyRank": undefined
  },
  {
    "Character": "掮",
    "Pinyin": "qián",
    "Definition": "to bear on the shoulders",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4739,
    "FrequencyRank": 4995
  },
  {
    "Character": "梣",
    "Pinyin": "cén",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7140,
    "FrequencyRank": undefined
  },
  {
    "Character": "圊",
    "Pinyin": "qīng",
    "Definition": "rest room",
    "Radical": "囗",
    "RadicalIndex": "31.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4817,
    "FrequencyRank": 8424
  },
  {
    "Character": "淄",
    "Pinyin": "zī",
    "Definition": "river in Shandong province",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4982,
    "FrequencyRank": 3649
  },
  {
    "Character": "帻",
    "Pinyin": "zé",
    "Definition": "turban; conical cap",
    "Radical": "巾",
    "RadicalIndex": "50.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4855,
    "FrequencyRank": 6563
  },
  {
    "Character": "铛",
    "Pinyin": "dāng",
    "Definition": "frying pan; warming vessel",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2304,
    "FrequencyRank": 4436
  },
  {
    "Character": "匮",
    "Pinyin": "kuì",
    "Definition": "to lack",
    "Radical": "匚",
    "RadicalIndex": "22.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4782,
    "FrequencyRank": 3727
  },
  {
    "Character": "皲",
    "Pinyin": "jūn",
    "Definition": "crack, chap",
    "Radical": "皮",
    "RadicalIndex": "107.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4997,
    "FrequencyRank": 6122
  },
  {
    "Character": "蛀",
    "Pinyin": "zhù",
    "Definition": "insects that eat books, clothes",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2279,
    "FrequencyRank": 3984
  },
  {
    "Character": "阈",
    "Pinyin": "yù",
    "Definition": "threshold; separated, confined",
    "Radical": "門",
    "RadicalIndex": "169.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4946,
    "FrequencyRank": 4701
  },
  {
    "Character": "埠",
    "Pinyin": "bù",
    "Definition": "port city",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2175,
    "FrequencyRank": 3443
  },
  {
    "Character": "骒",
    "Pinyin": "kè",
    "Definition": "mother horse",
    "Radical": "馬",
    "RadicalIndex": "187.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5032,
    "FrequencyRank": 7746
  },
  {
    "Character": "铯",
    "Pinyin": "sè",
    "Definition": "cesium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4882,
    "FrequencyRank": 5914
  },
  {
    "Character": "偃",
    "Pinyin": "yǎn",
    "Definition": "cease, lay off, lay down",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4905,
    "FrequencyRank": 4171
  },
  {
    "Character": "豚",
    "Pinyin": "tún",
    "Definition": "small pig, suckling pig; suffle",
    "Radical": "豕",
    "RadicalIndex": "152.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2358,
    "FrequencyRank": 3307
  },
  {
    "Character": "戛",
    "Pinyin": "jiá",
    "Definition": "lance; tap or strike lightly",
    "Radical": "戈",
    "RadicalIndex": "62.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4788,
    "FrequencyRank": 4503
  },
  {
    "Character": "稆",
    "Pinyin": "lǚ",
    "Definition": "wild grain",
    "Radical": "禾",
    "RadicalIndex": "115.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7189,
    "FrequencyRank": 8371
  },
  {
    "Character": "筇",
    "Pinyin": "qióng",
    "Definition": "bamboo name; bamboo staff",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4894,
    "FrequencyRank": 5976
  },
  {
    "Character": "谌",
    "Pinyin": "chén",
    "Definition": "sincere, faithful; surname",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4994,
    "FrequencyRank": 4130
  },
  {
    "Character": "绲",
    "Pinyin": "gǔn",
    "Definition": "cord; woven belt; hem; hemming",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5033,
    "FrequencyRank": 5646
  },
  {
    "Character": "堋",
    "Pinyin": "péng",
    "Definition": "bury",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4729,
    "FrequencyRank": 5290
  },
  {
    "Character": "偁",
    "Pinyin": "chēng",
    "Definition": "state",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7197,
    "FrequencyRank": undefined
  },
  {
    "Character": "唵",
    "Pinyin": "ǎn",
    "Definition": "used in Buddhist texts to transliterate non-Chinese sounds; to eat with the hand",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4823,
    "FrequencyRank": undefined
  },
  {
    "Character": "堉",
    "Pinyin": "yù",
    "Definition": "ground, fertile land",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4736,
    "FrequencyRank": undefined
  },
  {
    "Character": "埝",
    "Pinyin": "niàn",
    "Definition": "bank, dike; purtuberance from",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4728,
    "FrequencyRank": 6650
  },
  {
    "Character": "蛆",
    "Pinyin": "qū",
    "Definition": "maggots",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4834,
    "FrequencyRank": 4251
  },
  {
    "Character": "赦",
    "Pinyin": "shè",
    "Definition": "forgive, remit, pardon",
    "Radical": "赤",
    "RadicalIndex": "155.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2172,
    "FrequencyRank": 3107
  },
  {
    "Character": "铧",
    "Pinyin": "huá",
    "Definition": "spade, shovel, plowshare",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4875,
    "FrequencyRank": 5530
  },
  {
    "Character": "赉",
    "Pinyin": "lài",
    "Definition": "give, present, confer; surname",
    "Radical": "貝",
    "RadicalIndex": "154.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4805,
    "FrequencyRank": 6078
  },
  {
    "Character": "鸹",
    "Pinyin": "guā",
    "Definition": "the crow",
    "Radical": "鳥",
    "RadicalIndex": "196.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4890,
    "FrequencyRank": 5187
  },
  {
    "Character": "婶",
    "Pinyin": "shěn",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2482,
    "FrequencyRank": 2971
  },
  {
    "Character": "猞",
    "Pinyin": "shē",
    "Definition": "a wild cat; 猞猁, a lynx",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4927,
    "FrequencyRank": 6224
  },
  {
    "Character": "猇",
    "Pinyin": "xiāo",
    "Definition": "the scream or roar of a tiger; to intimidate; to scare",
    "Radical": "犬",
    "RadicalIndex": "94.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7215,
    "FrequencyRank": undefined
  },
  {
    "Character": "匾",
    "Pinyin": "biǎn",
    "Definition": "flat, round split-bamboo contain",
    "Radical": "匸",
    "RadicalIndex": "23.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2248,
    "FrequencyRank": 3501
  },
  {
    "Character": "掖",
    "Pinyin": "yē",
    "Definition": "support with the arms; stick in, tuck in; fold up",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4733,
    "FrequencyRank": 3863
  },
  {
    "Character": "蚯",
    "Pinyin": "qiū",
    "Definition": "earthworm",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2278,
    "FrequencyRank": 4116
  },
  {
    "Character": "婼",
    "Pinyin": "chuò",
    "Definition": "person",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7264,
    "FrequencyRank": undefined
  },
  {
    "Character": "啐",
    "Pinyin": "cuì",
    "Definition": "to taste, to sip; to spit; the sound of sipping; to surprise",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4845,
    "FrequencyRank": 4615
  },
  {
    "Character": "铫",
    "Pinyin": "diào",
    "Definition": "large hoe; surname; a spear",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4879,
    "FrequencyRank": 6706
  },
  {
    "Character": "淟",
    "Pinyin": "tiǎn",
    "Definition": "turbid; muddy",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7235,
    "FrequencyRank": undefined
  },
  {
    "Character": "鱾",
    "Pinyin": "jǐ",
    "Definition": "name of fish",
    "Radical": "魚",
    "RadicalIndex": "195.3",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7214,
    "FrequencyRank": 7398
  },
  {
    "Character": "渑",
    "Pinyin": "miǎn",
    "Definition": "name of a river in Shandong",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4974,
    "FrequencyRank": 5623
  },
  {
    "Character": "庱",
    "Pinyin": "chěng",
    "Definition": undefined,
    "Radical": "广",
    "RadicalIndex": "53.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7220,
    "FrequencyRank": undefined
  },
  {
    "Character": "谚",
    "Pinyin": "yàn",
    "Definition": "proverb, maxim",
    "Radical": "言",
    "RadicalIndex": "149.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2467,
    "FrequencyRank": 3625
  },
  {
    "Character": "翈",
    "Pinyin": "xiá",
    "Definition": undefined,
    "Radical": "羽",
    "RadicalIndex": "124.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7157,
    "FrequencyRank": 9241
  },
  {
    "Character": "逯",
    "Pinyin": "lù",
    "Definition": "leave without reason; surname",
    "Radical": "辵",
    "RadicalIndex": "162.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 5010,
    "FrequencyRank": 5855
  },
  {
    "Character": "裈",
    "Pinyin": "kūn",
    "Definition": "trousers, pants, shorts, drawers",
    "Radical": "衣",
    "RadicalIndex": "145.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7252,
    "FrequencyRank": 7190
  },
  {
    "Character": "聍",
    "Pinyin": "níng",
    "Definition": "earwax",
    "Radical": "耳",
    "RadicalIndex": "128.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7119,
    "FrequencyRank": 6657
  },
  {
    "Character": "豉",
    "Pinyin": "shì",
    "Definition": "fermented beans",
    "Radical": "豆",
    "RadicalIndex": "151.4",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4784,
    "FrequencyRank": 6021
  },
  {
    "Character": "庵",
    "Pinyin": "ān",
    "Definition": "Buddhist monastery or nunnery",
    "Radical": "广",
    "RadicalIndex": "53.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2380,
    "FrequencyRank": 3022
  },
  {
    "Character": "梾",
    "Pinyin": "lái",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7138,
    "FrequencyRank": undefined
  },
  {
    "Character": "痍",
    "Pinyin": "yí",
    "Definition": "wound, bruise, sore",
    "Radical": "疒",
    "RadicalIndex": "104.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4939,
    "FrequencyRank": 5413
  },
  {
    "Character": "琉",
    "Pinyin": "liú",
    "Definition": "sparkling stone; glazed, opaque",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2158,
    "FrequencyRank": 2962
  },
  {
    "Character": "蚲",
    "Pinyin": "píng",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7165,
    "FrequencyRank": undefined
  },
  {
    "Character": "淫",
    "Pinyin": "yín",
    "Definition": "obscene, licentious, lewd",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2424,
    "FrequencyRank": 2297
  },
  {
    "Character": "瓻",
    "Pinyin": "chī",
    "Definition": "jar",
    "Radical": "瓦",
    "RadicalIndex": "98.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7208,
    "FrequencyRank": undefined
  },
  {
    "Character": "敝",
    "Pinyin": "bì",
    "Definition": "break, destroy; broken, tattered",
    "Radical": "攴",
    "RadicalIndex": "66.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4955,
    "FrequencyRank": 3259
  },
  {
    "Character": "掸",
    "Pinyin": "dǎn",
    "Definition": "to dust; a duster",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4737,
    "FrequencyRank": 4402
  },
  {
    "Character": "阏",
    "Pinyin": "è",
    "Definition": "block, obstruct, stop up",
    "Radical": "門",
    "RadicalIndex": "169.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4951,
    "FrequencyRank": 5091
  },
  {
    "Character": "梌",
    "Pinyin": "tú",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7141,
    "FrequencyRank": undefined
  },
  {
    "Character": "铘",
    "Pinyin": "yé",
    "Definition": "sword",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7182,
    "FrequencyRank": undefined
  },
  {
    "Character": "萣",
    "Pinyin": "dìng",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7131,
    "FrequencyRank": undefined
  },
  {
    "Character": "笮",
    "Pinyin": "zé",
    "Definition": "boards which support tiles on roof",
    "Radical": "竹",
    "RadicalIndex": "118.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4897,
    "FrequencyRank": 5296
  },
  {
    "Character": "铬",
    "Pinyin": "gè",
    "Definition": "chromium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4880,
    "FrequencyRank": 4189
  },
  {
    "Character": "蛄",
    "Pinyin": "gū",
    "Definition": "mole cricket",
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4832,
    "FrequencyRank": 5027
  },
  {
    "Character": "掬",
    "Pinyin": "jū",
    "Definition": "to grasp or hold with both hands",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4731,
    "FrequencyRank": 4398
  },
  {
    "Character": "硒",
    "Pinyin": "xī",
    "Definition": "selenium",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4791,
    "FrequencyRank": 4846
  },
  {
    "Character": "晞",
    "Pinyin": "xī",
    "Definition": "dry, expose sun; dawn",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4822,
    "FrequencyRank": undefined
  },
  {
    "Character": "埤",
    "Pinyin": "pí",
    "Definition": "add, increase, attach; low fence",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4725,
    "FrequencyRank": 6740
  },
  {
    "Character": "淇",
    "Pinyin": "qí",
    "Definition": "river in Henan province",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4965,
    "FrequencyRank": 4010
  },
  {
    "Character": "啄",
    "Pinyin": "zhuó",
    "Definition": "to peck; (Cant.) to slander",
    "Radical": "口",
    "RadicalIndex": "30.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2271,
    "FrequencyRank": 3449
  },
  {
    "Character": "阌",
    "Pinyin": "wén",
    "Definition": "wen xiang, Henan province",
    "Radical": "門",
    "RadicalIndex": "169.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7227,
    "FrequencyRank": 5242
  },
  {
    "Character": "旌",
    "Pinyin": "jīng",
    "Definition": "banner or flag adorned with feathers; to signal",
    "Radical": "方",
    "RadicalIndex": "70.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4942,
    "FrequencyRank": 4214
  },
  {
    "Character": "琅",
    "Pinyin": "láng",
    "Definition": "a variety of white carnelian; pure",
    "Radical": "玉",
    "RadicalIndex": "96.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2159,
    "FrequencyRank": 3585
  },
  {
    "Character": "湴",
    "Pinyin": "bàn",
    "Definition": "(Cant.) mud, mire",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7239,
    "FrequencyRank": undefined
  },
  {
    "Character": "埭",
    "Pinyin": "dài",
    "Definition": "a dam, a jock; inclined plane on a canal, where boats can be hauled up or down",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4741,
    "FrequencyRank": 6970
  },
  {
    "Character": "牿",
    "Pinyin": "gù",
    "Definition": undefined,
    "Radical": "牛",
    "RadicalIndex": "93.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7188,
    "FrequencyRank": 8506
  },
  {
    "Character": "龚",
    "Pinyin": "gōng",
    "Definition": "give, present; reverential",
    "Radical": "龍",
    "RadicalIndex": "212.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4801,
    "FrequencyRank": 3184
  },
  {
    "Character": "羝",
    "Pinyin": "dī",
    "Definition": "ram, he-goat",
    "Radical": "羊",
    "RadicalIndex": "123.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7228,
    "FrequencyRank": 6337
  },
  {
    "Character": "淬",
    "Pinyin": "cuì",
    "Definition": "temper; dye; soak; change, alter",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4977,
    "FrequencyRank": 4939
  },
  {
    "Character": "䂮",
    "Pinyin": "lüè",
    "Definition": "stone, to sharpen a knife, sharp-pointed; sharp, vigorous; energetic; keen",
    "Radical": "石",
    "RadicalIndex": "112.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7098,
    "FrequencyRank": undefined
  },
  {
    "Character": "菼",
    "Pinyin": "tǎn",
    "Definition": "rush or sedge",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7130,
    "FrequencyRank": undefined
  },
  {
    "Character": "焗",
    "Pinyin": "jú",
    "Definition": "(Cant.) to roast, bake; to suffocate, stuffy",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4962,
    "FrequencyRank": undefined
  },
  {
    "Character": "鄂",
    "Pinyin": "è",
    "Definition": "Hubei province; startled",
    "Radical": "邑",
    "RadicalIndex": "163.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2283,
    "FrequencyRank": 2946
  },
  {
    "Character": "焖",
    "Pinyin": "mèn",
    "Definition": "simmer, cook over slow fire",
    "Radical": "火",
    "RadicalIndex": "86.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4960,
    "FrequencyRank": 5207
  },
  {
    "Character": "旎",
    "Pinyin": "nǐ",
    "Definition": "fluttering of flag; romantic",
    "Radical": "方",
    "RadicalIndex": "70.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4943,
    "FrequencyRank": 5041
  },
  {
    "Character": "隃",
    "Pinyin": "shù",
    "Definition": "to exceed; a state in Shanxi province",
    "Radical": "阜",
    "RadicalIndex": "170.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7261,
    "FrequencyRank": 7256
  },
  {
    "Character": "婳",
    "Pinyin": "huà",
    "Definition": "tranquil",
    "Radical": "女",
    "RadicalIndex": "38.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7266,
    "FrequencyRank": undefined
  },
  {
    "Character": "铒",
    "Pinyin": "ěr",
    "Definition": "erbium",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4866,
    "FrequencyRank": 5951
  },
  {
    "Character": "萋",
    "Pinyin": "qī",
    "Definition": "luxuriant foliage; crowded",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4751,
    "FrequencyRank": 5734
  },
  {
    "Character": "堎",
    "Pinyin": "lèng",
    "Definition": "character used for place names",
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7107,
    "FrequencyRank": undefined
  },
  {
    "Character": "曼",
    "Pinyin": "màn",
    "Definition": "long, extended, vast; beautiful",
    "Radical": "日",
    "RadicalIndex": "72.7",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2268,
    "FrequencyRank": 1224
  },
  {
    "Character": "瓠",
    "Pinyin": "hù",
    "Definition": "bottle gourd; calabash; pot",
    "Radical": "瓜",
    "RadicalIndex": "97.6",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 4798,
    "FrequencyRank": 5820
  },
  {
    "Character": "婘",
    "Pinyin": "quán",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7271,
    "FrequencyRank": undefined
  },
  {
    "Character": "羚",
    "Pinyin": "líng",
    "Definition": "species of antelope",
    "Radical": "羊",
    "RadicalIndex": "123.5",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 2399,
    "FrequencyRank": 3859
  },
  {
    "Character": "埫",
    "Pinyin": "chǒng",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7111,
    "FrequencyRank": undefined
  },
  {
    "Character": "偲",
    "Pinyin": "cāi",
    "Definition": "talented; urgent",
    "Radical": "人",
    "RadicalIndex": "9.9",
    "StrokeCount": 11,
    "HSK": undefined,
    "GeneralStandard": 7196,
    "FrequencyRank": undefined
  },
  {
    "Character": "傣",
    "Pinyin": "dǎi",
    "Definition": "the Dai minority living in South China",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5208,
    "FrequencyRank": 3836
  },
  {
    "Character": "傕",
    "Pinyin": "jué",
    "Definition": "used in old names",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7396,
    "FrequencyRank": undefined
  },
  {
    "Character": "锊",
    "Pinyin": "lüè",
    "Definition": "6 oz; ancient measurement",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7379,
    "FrequencyRank": 5923
  },
  {
    "Character": "阒",
    "Pinyin": "qù",
    "Definition": "alone; quiet, still",
    "Radical": "門",
    "RadicalIndex": "169.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5267,
    "FrequencyRank": 5142
  },
  {
    "Character": "猹",
    "Pinyin": "chá",
    "Definition": "wild animal mentioned in short story by Lu Xun",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5240,
    "FrequencyRank": 6954
  },
  {
    "Character": "蒉",
    "Pinyin": "kuì",
    "Definition": "edible amaranth; straw basket",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7310,
    "FrequencyRank": 7710
  },
  {
    "Character": "鹄",
    "Pinyin": "gǔ",
    "Definition": "target",
    "Radical": "鳥",
    "RadicalIndex": "196.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5199,
    "FrequencyRank": 4238
  },
  {
    "Character": "靬",
    "Pinyin": "qián",
    "Definition": undefined,
    "Radical": "革",
    "RadicalIndex": "177.3",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7305,
    "FrequencyRank": undefined
  },
  {
    "Character": "喃",
    "Pinyin": "nán",
    "Definition": "keep talking, chattering; mumble",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5130,
    "FrequencyRank": 2233
  },
  {
    "Character": "愃",
    "Pinyin": "xuān",
    "Definition": "relax",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7440,
    "FrequencyRank": undefined
  },
  {
    "Character": "湫",
    "Pinyin": "jiǎo",
    "Definition": "a small pond; a damp and narrow place",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5284,
    "FrequencyRank": 5470
  },
  {
    "Character": "幂",
    "Pinyin": "mì",
    "Definition": "cover-cloth, cover with cloth",
    "Radical": "冖",
    "RadicalIndex": "14.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5311,
    "FrequencyRank": 5384
  },
  {
    "Character": "萼",
    "Pinyin": "è",
    "Definition": "the stem and calyx of a flower; a younger brother",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5086,
    "FrequencyRank": 4072
  },
  {
    "Character": "飨",
    "Pinyin": "xiǎng",
    "Definition": "host banquet; banquet",
    "Radical": "食",
    "RadicalIndex": "184.3",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5338,
    "FrequencyRank": 4724
  },
  {
    "Character": "鄚",
    "Pinyin": "mào",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.11",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7309,
    "FrequencyRank": 7195
  },
  {
    "Character": "馊",
    "Pinyin": "sōu",
    "Definition": "spoiled, rotten, stale, rancid",
    "Radical": "食",
    "RadicalIndex": "184.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5249,
    "FrequencyRank": 4753
  },
  {
    "Character": "毵",
    "Pinyin": "sān",
    "Definition": "long feathers; scraggy",
    "Radical": "毛",
    "RadicalIndex": "82.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7454,
    "FrequencyRank": 6554
  },
  {
    "Character": "稌",
    "Pinyin": "tú",
    "Definition": "glutinous rice",
    "Radical": "禾",
    "RadicalIndex": "115.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7386,
    "FrequencyRank": 8526
  },
  {
    "Character": "缒",
    "Pinyin": "zhuì",
    "Definition": "climb down rope; hang by rope",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5336,
    "FrequencyRank": 5656
  },
  {
    "Character": "痧",
    "Pinyin": "shā",
    "Definition": "cholera; colic",
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5259,
    "FrequencyRank": 5801
  },
  {
    "Character": "睎",
    "Pinyin": "xī",
    "Definition": "to long for; to gaze at",
    "Radical": "目",
    "RadicalIndex": "109.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7350,
    "FrequencyRank": 9066
  },
  {
    "Character": "揕",
    "Pinyin": "zhèn",
    "Definition": "roll up the sleeves; fight bare-fisted; strike, stab",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7291,
    "FrequencyRank": undefined
  },
  {
    "Character": "搔",
    "Pinyin": "sāo",
    "Definition": "to scratch",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2539,
    "FrequencyRank": 3454
  },
  {
    "Character": "𫓶",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7378,
    "FrequencyRank": undefined
  },
  {
    "Character": "痢",
    "Pinyin": "lì",
    "Definition": "dysentery",
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2732,
    "FrequencyRank": 4107
  },
  {
    "Character": "辊",
    "Pinyin": "gǔn",
    "Definition": "turn round, revolve; roller",
    "Radical": "車",
    "RadicalIndex": "159.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5117,
    "FrequencyRank": 5845
  },
  {
    "Character": "靰",
    "Pinyin": "wù",
    "Definition": "leg warmer",
    "Radical": "革",
    "RadicalIndex": "177.3",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5081,
    "FrequencyRank": 7000
  },
  {
    "Character": "祼",
    "Pinyin": "guàn",
    "Definition": "libation",
    "Radical": "示",
    "RadicalIndex": "113.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7446,
    "FrequencyRank": 7219
  },
  {
    "Character": "媪",
    "Pinyin": "ǎo",
    "Definition": "old woman; lower-class woman",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5320,
    "FrequencyRank": 5196
  },
  {
    "Character": "腚",
    "Pinyin": "dìng",
    "Definition": "buttock",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5233,
    "FrequencyRank": 5672
  },
  {
    "Character": "铼",
    "Pinyin": "lái",
    "Definition": "rhenium",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5179,
    "FrequencyRank": 7005
  },
  {
    "Character": "𫐐",
    "Pinyin": "ní",
    "Definition": "the cross-bar at the end of a carriage pole; linchpin of a large carriage",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7343,
    "FrequencyRank": undefined
  },
  {
    "Character": "晫",
    "Pinyin": "zhuó",
    "Definition": "bright",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7351,
    "FrequencyRank": undefined
  },
  {
    "Character": "硝",
    "Pinyin": "xiāo",
    "Definition": "saltpeter, niter; to tan",
    "Radical": "石",
    "RadicalIndex": "112.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2590,
    "FrequencyRank": 3085
  },
  {
    "Character": "婻",
    "Pinyin": "nàn",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7447,
    "FrequencyRank": undefined
  },
  {
    "Character": "锇",
    "Pinyin": "é",
    "Definition": "osmium",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5184,
    "FrequencyRank": 6145
  },
  {
    "Character": "赑",
    "Pinyin": "bì",
    "Definition": "strong",
    "Radical": "貝",
    "RadicalIndex": "154.14",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7370,
    "FrequencyRank": 9283
  },
  {
    "Character": "揸",
    "Pinyin": "zhā",
    "Definition": "handful; to grasp, seize, pick up with fingers; (Cant.) to take, carry",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5062,
    "FrequencyRank": 6890
  },
  {
    "Character": "鹁",
    "Pinyin": "bó",
    "Definition": "species of pigeon",
    "Radical": "鳥",
    "RadicalIndex": "196.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5106,
    "FrequencyRank": 5577
  },
  {
    "Character": "耋",
    "Pinyin": "dié",
    "Definition": undefined,
    "Radical": "老",
    "RadicalIndex": "125.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5070,
    "FrequencyRank": 6231
  },
  {
    "Character": "斐",
    "Pinyin": "fěi",
    "Definition": "graceful, elegant, beautiful",
    "Radical": "文",
    "RadicalIndex": "67.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5122,
    "FrequencyRank": 2972
  },
  {
    "Character": "敩",
    "Pinyin": "xiào",
    "Definition": undefined,
    "Radical": "攴",
    "RadicalIndex": "66.16",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7441,
    "FrequencyRank": undefined
  },
  {
    "Character": "巯",
    "Pinyin": "qiú",
    "Definition": "an atom group",
    "Radical": "工",
    "RadicalIndex": "48.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5323,
    "FrequencyRank": 6242
  },
  {
    "Character": "喾",
    "Pinyin": "kù",
    "Definition": "emperor",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5301,
    "FrequencyRank": 5708
  },
  {
    "Character": "鱿",
    "Pinyin": "yóu",
    "Definition": "cuttlefish",
    "Radical": "魚",
    "RadicalIndex": "195.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5235,
    "FrequencyRank": 4622
  },
  {
    "Character": "犇",
    "Pinyin": "bēn",
    "Definition": "run fast, flee; rush about; run",
    "Radical": "牛",
    "RadicalIndex": "93.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7384,
    "FrequencyRank": undefined
  },
  {
    "Character": "揖",
    "Pinyin": "yī",
    "Definition": "salute, bow; defer to, yield",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5066,
    "FrequencyRank": 3505
  },
  {
    "Character": "彘",
    "Pinyin": "zhì",
    "Definition": "swine",
    "Radical": "彐",
    "RadicalIndex": "58.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5331,
    "FrequencyRank": 6104
  },
  {
    "Character": "䐃",
    "Pinyin": "jùn",
    "Definition": "fat in the abdomen or intestine, protuberances of the muscle, a fetus inside the belly",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7402,
    "FrequencyRank": undefined
  },
  {
    "Character": "喱",
    "Pinyin": "lí",
    "Definition": "gramme; syllable",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5131,
    "FrequencyRank": 5879
  },
  {
    "Character": "嵁",
    "Pinyin": "kān",
    "Definition": "rugged",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7360,
    "FrequencyRank": undefined
  },
  {
    "Character": "嵖",
    "Pinyin": "chá",
    "Definition": "name of a mountain; (Cant.) to obstruct",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5164,
    "FrequencyRank": undefined
  },
  {
    "Character": "锏",
    "Pinyin": "jiǎn",
    "Definition": "kind of rapier",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5186,
    "FrequencyRank": 4273
  },
  {
    "Character": "葴",
    "Pinyin": "zhēn",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7306,
    "FrequencyRank": 7311
  },
  {
    "Character": "貂",
    "Pinyin": "diāo",
    "Definition": "marten, sable, mink",
    "Radical": "豸",
    "RadicalIndex": "153.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5226,
    "FrequencyRank": 4306
  },
  {
    "Character": "㺄",
    "Pinyin": "yǔ",
    "Definition": "(same as 狳) (a variant of 貐) a kind of beast",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7410,
    "FrequencyRank": undefined
  },
  {
    "Character": "锓",
    "Pinyin": "qǐn",
    "Definition": "carve",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7383,
    "FrequencyRank": 6131
  },
  {
    "Character": "裎",
    "Pinyin": "chéng",
    "Definition": "to take off the clothes and expose the body. to carry in the girdle",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5306,
    "FrequencyRank": 5788
  },
  {
    "Character": "崿",
    "Pinyin": "è",
    "Definition": "cliffs",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7364,
    "FrequencyRank": undefined
  },
  {
    "Character": "畬",
    "Pinyin": "shē",
    "Definition": "reclaimed field",
    "Radical": "田",
    "RadicalIndex": "102.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7398,
    "FrequencyRank": undefined
  },
  {
    "Character": "棨",
    "Pinyin": "qǐ",
    "Definition": "tally or wooden pass",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7443,
    "FrequencyRank": undefined
  },
  {
    "Character": "蜓",
    "Pinyin": "tíng",
    "Definition": "dragonfly",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2636,
    "FrequencyRank": 4083
  },
  {
    "Character": "珷",
    "Pinyin": "wǔ",
    "Definition": "an inferior gem",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7283,
    "FrequencyRank": undefined
  },
  {
    "Character": "椋",
    "Pinyin": "liáng",
    "Definition": "fruit",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5101,
    "FrequencyRank": 4031
  },
  {
    "Character": "锔",
    "Pinyin": "jū",
    "Definition": "curium",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5189,
    "FrequencyRank": 6157
  },
  {
    "Character": "颍",
    "Pinyin": "yǐng",
    "Definition": "river in Anhui",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5238,
    "FrequencyRank": 3881
  },
  {
    "Character": "睇",
    "Pinyin": "dì",
    "Definition": "look at, glance at; stare at",
    "Radical": "目",
    "RadicalIndex": "109.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5125,
    "FrequencyRank": 5521
  },
  {
    "Character": "飓",
    "Pinyin": "jù",
    "Definition": "cyclone, typhoon, gale",
    "Radical": "風",
    "RadicalIndex": "182.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5242,
    "FrequencyRank": 4112
  },
  {
    "Character": "湎",
    "Pinyin": "miǎn",
    "Definition": "flushed with drink, drunk",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5280,
    "FrequencyRank": 4504
  },
  {
    "Character": "𫘨",
    "Pinyin": "tí",
    "Definition": "a horse, mule",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7457,
    "FrequencyRank": undefined
  },
  {
    "Character": "媆",
    "Pinyin": "ruǎn",
    "Definition": "young, tender, soft, delicate",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7448,
    "FrequencyRank": undefined
  },
  {
    "Character": "硪",
    "Pinyin": "wò",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7340,
    "FrequencyRank": 5369
  },
  {
    "Character": "堾",
    "Pinyin": "chūn",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7289,
    "FrequencyRank": undefined
  },
  {
    "Character": "脿",
    "Pinyin": "biāo",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7400,
    "FrequencyRank": 9480
  },
  {
    "Character": "瑛",
    "Pinyin": "yīng",
    "Definition": "luster of gem; crystal",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5046,
    "FrequencyRank": 2998
  },
  {
    "Character": "瓿",
    "Pinyin": "bù",
    "Definition": "jar, pot",
    "Radical": "瓦",
    "RadicalIndex": "98.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5262,
    "FrequencyRank": 6024
  },
  {
    "Character": "喑",
    "Pinyin": "yīn",
    "Definition": "to be dumb; the sobbing of infants",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5156,
    "FrequencyRank": 4868
  },
  {
    "Character": "蛴",
    "Pinyin": "qí",
    "Definition": "maggots, grubs",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5149,
    "FrequencyRank": 4978
  },
  {
    "Character": "犀",
    "Pinyin": "xī",
    "Definition": "rhinoceros; sharp, well-tempered",
    "Radical": "牛",
    "RadicalIndex": "93.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2799,
    "FrequencyRank": 3490
  },
  {
    "Character": "脔",
    "Pinyin": "luán",
    "Definition": "small lump of meat; sliced meat",
    "Radical": "肉",
    "RadicalIndex": "130.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5251,
    "FrequencyRank": 6099
  },
  {
    "Character": "溞",
    "Pinyin": "sāo",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7438,
    "FrequencyRank": undefined
  },
  {
    "Character": "缇",
    "Pinyin": "tí",
    "Definition": "reddish color, red, brown",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5332,
    "FrequencyRank": 5794
  },
  {
    "Character": "渥",
    "Pinyin": "wò",
    "Definition": "moisten, soak; great, deep; dye; to enrich",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5291,
    "FrequencyRank": 4191
  },
  {
    "Character": "锂",
    "Pinyin": "lǐ",
    "Definition": "lithium",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5182,
    "FrequencyRank": 4873
  },
  {
    "Character": "鹀",
    "Pinyin": "wú",
    "Definition": undefined,
    "Radical": "鳥",
    "RadicalIndex": "196.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7327,
    "FrequencyRank": 7073
  },
  {
    "Character": "雱",
    "Pinyin": "pāng",
    "Definition": "snowing heavily",
    "Radical": "雨",
    "RadicalIndex": "173.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5116,
    "FrequencyRank": 5375
  },
  {
    "Character": "跏",
    "Pinyin": "jiā",
    "Definition": "sit cross-legged; squat",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5140,
    "FrequencyRank": 5127
  },
  {
    "Character": "嵫",
    "Pinyin": "zī",
    "Definition": "a hill in Shantung",
    "Radical": "山",
    "RadicalIndex": "46.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5174,
    "FrequencyRank": 6140
  },
  {
    "Character": "腌",
    "Pinyin": "yān",
    "Definition": "salt, pickle; be dirty",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5228,
    "FrequencyRank": 4219
  },
  {
    "Character": "窖",
    "Pinyin": "jiào",
    "Definition": "pit, cellar",
    "Radical": "穴",
    "RadicalIndex": "116.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2785,
    "FrequencyRank": 3317
  },
  {
    "Character": "渲",
    "Pinyin": "xuàn",
    "Definition": "add repeated washes of color",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2768,
    "FrequencyRank": 3606
  },
  {
    "Character": "孱",
    "Pinyin": "càn",
    "Definition": "weak, unfit, frail, feeble",
    "Radical": "子",
    "RadicalIndex": "39.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5316,
    "FrequencyRank": 4985
  },
  {
    "Character": "𪣻",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7299,
    "FrequencyRank": undefined
  },
  {
    "Character": "觚",
    "Pinyin": "gū",
    "Definition": "jug, winecup, goblet; square",
    "Radical": "角",
    "RadicalIndex": "148.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5244,
    "FrequencyRank": 6138
  },
  {
    "Character": "葸",
    "Pinyin": "xǐ",
    "Definition": "afraid, bashful",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5085,
    "FrequencyRank": 6261
  },
  {
    "Character": "蒄",
    "Pinyin": "guān",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7319,
    "FrequencyRank": 8973
  },
  {
    "Character": "焯",
    "Pinyin": "chāo",
    "Definition": "boil vegetables for a very short time; scald",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5272,
    "FrequencyRank": 5673
  },
  {
    "Character": "𨱇",
    "Pinyin": "qiú",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7377,
    "FrequencyRank": undefined
  },
  {
    "Character": "葎",
    "Pinyin": "lǜ",
    "Definition": "Humulus japonicus",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7315,
    "FrequencyRank": 5650
  },
  {
    "Character": "蛰",
    "Pinyin": "zhé",
    "Definition": "to hibernate",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5073,
    "FrequencyRank": 4177
  },
  {
    "Character": "裥",
    "Pinyin": "jiǎn",
    "Definition": "folds",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5307,
    "FrequencyRank": 5531
  },
  {
    "Character": "谡",
    "Pinyin": "sù",
    "Definition": "rise up; raise up",
    "Radical": "言",
    "RadicalIndex": "149.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5312,
    "FrequencyRank": 4258
  },
  {
    "Character": "旐",
    "Pinyin": "zhào",
    "Definition": "an embroidered pennant",
    "Radical": "方",
    "RadicalIndex": "70.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7420,
    "FrequencyRank": undefined
  },
  {
    "Character": "遐",
    "Pinyin": "xiá",
    "Definition": "afar, distant; old, advanced in",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5315,
    "FrequencyRank": 3852
  },
  {
    "Character": "琨",
    "Pinyin": "kūn",
    "Definition": "beautiful jade, precious stones",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5049,
    "FrequencyRank": 4557
  },
  {
    "Character": "辎",
    "Pinyin": "zī",
    "Definition": "a supply cart, covered wagon, dray",
    "Radical": "車",
    "RadicalIndex": "159.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5121,
    "FrequencyRank": 4142
  },
  {
    "Character": "斝",
    "Pinyin": "jiǎ",
    "Definition": "small jade wine cup",
    "Radical": "斗",
    "RadicalIndex": "68.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7357,
    "FrequencyRank": undefined
  },
  {
    "Character": "㛹",
    "Pinyin": "pián",
    "Definition": "pretty; exquisite; fine",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7450,
    "FrequencyRank": undefined
  },
  {
    "Character": "趄",
    "Pinyin": "jū",
    "Definition": "weak, lame",
    "Radical": "走",
    "RadicalIndex": "156.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5065,
    "FrequencyRank": 4802
  },
  {
    "Character": "萹",
    "Pinyin": "biǎn",
    "Definition": "grass",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7320,
    "FrequencyRank": undefined
  },
  {
    "Character": "筘",
    "Pinyin": "kòu",
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7388,
    "FrequencyRank": 7303
  },
  {
    "Character": "骘",
    "Pinyin": "zhì",
    "Definition": "stallion; promote",
    "Radical": "馬",
    "RadicalIndex": "187.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5319,
    "FrequencyRank": 6077
  },
  {
    "Character": "筀",
    "Pinyin": "guì",
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7387,
    "FrequencyRank": undefined
  },
  {
    "Character": "飧",
    "Pinyin": "sūn",
    "Definition": "evening meal, supper; cooked food",
    "Radical": "食",
    "RadicalIndex": "184.3",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5247,
    "FrequencyRank": 4494
  },
  {
    "Character": "赍",
    "Pinyin": "jī",
    "Definition": "present",
    "Radical": "貝",
    "RadicalIndex": "154.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5100,
    "FrequencyRank": 5246
  },
  {
    "Character": "舄",
    "Pinyin": "xì",
    "Definition": "a shoe; the sole of a shoe; magpie",
    "Radical": "臼",
    "RadicalIndex": "134.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5210,
    "FrequencyRank": 6827
  },
  {
    "Character": "腙",
    "Pinyin": "zōng",
    "Definition": "an organic compound",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7403,
    "FrequencyRank": 7577
  },
  {
    "Character": "缂",
    "Pinyin": "kè",
    "Definition": "the woof of a woven item",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5328,
    "FrequencyRank": 5002
  },
  {
    "Character": "黹",
    "Pinyin": "zhǐ",
    "Definition": "embroidery, needlework; radical",
    "Radical": "黹",
    "RadicalIndex": "204",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7348,
    "FrequencyRank": 6783
  },
  {
    "Character": "翚",
    "Pinyin": "huī",
    "Definition": undefined,
    "Radical": "羽",
    "RadicalIndex": "124.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5324,
    "FrequencyRank": 8408
  },
  {
    "Character": "媭",
    "Pinyin": "xū",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.12",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5218,
    "FrequencyRank": undefined
  },
  {
    "Character": "㙘",
    "Pinyin": "yāo",
    "Definition": "used in naming a place",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7292,
    "FrequencyRank": undefined
  },
  {
    "Character": "塅",
    "Pinyin": "duàn",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7296,
    "FrequencyRank": undefined
  },
  {
    "Character": "媞",
    "Pinyin": "shì",
    "Definition": "at ease",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7449,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫟼",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7180,
    "FrequencyRank": undefined
  },
  {
    "Character": "粟",
    "Pinyin": "sù",
    "Definition": "unhusked millet; grain",
    "Radical": "米",
    "RadicalIndex": "119.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2583,
    "FrequencyRank": 2883
  },
  {
    "Character": "阑",
    "Pinyin": "lán",
    "Definition": "door screen; railing fence",
    "Radical": "門",
    "RadicalIndex": "169.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5266,
    "FrequencyRank": 3716
  },
  {
    "Character": "蛤",
    "Pinyin": "há",
    "Definition": "clam",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2638,
    "FrequencyRank": 3732
  },
  {
    "Character": "缎",
    "Pinyin": "duàn",
    "Definition": "satin",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2817,
    "FrequencyRank": 3108
  },
  {
    "Character": "氮",
    "Pinyin": "dàn",
    "Definition": "nitrogen",
    "Radical": "气",
    "RadicalIndex": "84.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2671,
    "FrequencyRank": 3482
  },
  {
    "Character": "塄",
    "Pinyin": "léng",
    "Definition": "elevated bank in field",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5068,
    "FrequencyRank": 6374
  },
  {
    "Character": "焱",
    "Pinyin": "yàn",
    "Definition": "flames",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5275,
    "FrequencyRank": 5359
  },
  {
    "Character": "堧",
    "Pinyin": "ruán",
    "Definition": "adjoin",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7293,
    "FrequencyRank": undefined
  },
  {
    "Character": "愔",
    "Pinyin": "yīn",
    "Definition": "comfortable, contented, peaceful",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5300,
    "FrequencyRank": undefined
  },
  {
    "Character": "棹",
    "Pinyin": "zhào",
    "Definition": "oar; boat",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5097,
    "FrequencyRank": 6328
  },
  {
    "Character": "腋",
    "Pinyin": "yè",
    "Definition": "armpit",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2714,
    "FrequencyRank": 3593
  },
  {
    "Character": "啻",
    "Pinyin": "chì",
    "Definition": "only, merely; just like; stop at",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5263,
    "FrequencyRank": 4249
  },
  {
    "Character": "淼",
    "Pinyin": "miǎo",
    "Definition": "a wide expanse of water",
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7371,
    "FrequencyRank": 4600
  },
  {
    "Character": "猥",
    "Pinyin": "wěi",
    "Definition": "vulgar, low, cheap; wanton; obscene",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5241,
    "FrequencyRank": 4270
  },
  {
    "Character": "酦",
    "Pinyin": "fā",
    "Definition": "fermentation, brewing",
    "Radical": "酉",
    "RadicalIndex": "164.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7336,
    "FrequencyRank": 7955
  },
  {
    "Character": "甦",
    "Pinyin": "sū",
    "Definition": "be reborn; resuscitate, revive",
    "Radical": "生",
    "RadicalIndex": "100.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7335,
    "FrequencyRank": undefined
  },
  {
    "Character": "圐",
    "Pinyin": "kū",
    "Definition": undefined,
    "Radical": "囗",
    "RadicalIndex": "31.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7369,
    "FrequencyRank": undefined
  },
  {
    "Character": "棰",
    "Pinyin": "chuí",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5099,
    "FrequencyRank": 6511
  },
  {
    "Character": "腈",
    "Pinyin": "jīng",
    "Definition": "an organic compound",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5227,
    "FrequencyRank": 6461
  },
  {
    "Character": "蛭",
    "Pinyin": "zhì",
    "Definition": "leech",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5144,
    "FrequencyRank": 4257
  },
  {
    "Character": "愠",
    "Pinyin": "yùn",
    "Definition": "angry",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5294,
    "FrequencyRank": 4535
  },
  {
    "Character": "堙",
    "Pinyin": "yīn",
    "Definition": "bury; dam, block up",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5064,
    "FrequencyRank": 6531
  },
  {
    "Character": "嗒",
    "Pinyin": "dā",
    "Definition": "absent-minded",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5129,
    "FrequencyRank": 3497
  },
  {
    "Character": "婺",
    "Pinyin": "wù",
    "Definition": "name of a star",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5326,
    "FrequencyRank": 5178
  },
  {
    "Character": "惶",
    "Pinyin": "huáng",
    "Definition": "fearful, afraid, anxious, nervous",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2775,
    "FrequencyRank": 2320
  },
  {
    "Character": "椑",
    "Pinyin": "bēi",
    "Definition": "oval",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7325,
    "FrequencyRank": undefined
  },
  {
    "Character": "絷",
    "Pinyin": "zhí",
    "Definition": "confine, tie up; imprison, shackle",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7298,
    "FrequencyRank": 6794
  },
  {
    "Character": "愐",
    "Pinyin": "miǎn",
    "Definition": "bashful; to consider",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7439,
    "FrequencyRank": undefined
  },
  {
    "Character": "翛",
    "Pinyin": "xiāo",
    "Definition": "look of rumpled feathers; bedraggled; hasty; rapid flight",
    "Radical": "羽",
    "RadicalIndex": "124.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7394,
    "FrequencyRank": 6829
  },
  {
    "Character": "扊",
    "Pinyin": "yǎn",
    "Definition": "upright bar for fastening door",
    "Radical": "戶",
    "RadicalIndex": "63.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7444,
    "FrequencyRank": undefined
  },
  {
    "Character": "筵",
    "Pinyin": "yán",
    "Definition": "bamboo mat; feast, banquet",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5206,
    "FrequencyRank": 3876
  },
  {
    "Character": "揿",
    "Pinyin": "qìn",
    "Definition": "press",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5069,
    "FrequencyRank": 4836
  },
  {
    "Character": "腓",
    "Pinyin": "féi",
    "Definition": "calf; avoid; be ill; wither",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5229,
    "FrequencyRank": 3543
  },
  {
    "Character": "𡎚",
    "Pinyin": "piǎn",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7300,
    "FrequencyRank": undefined
  },
  {
    "Character": "堨",
    "Pinyin": "yè",
    "Definition": "daub",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7295,
    "FrequencyRank": undefined
  },
  {
    "Character": "殛",
    "Pinyin": "jí",
    "Definition": "to put to death to imprison for life",
    "Radical": "歹",
    "RadicalIndex": "78.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5114,
    "FrequencyRank": 5731
  },
  {
    "Character": "稃",
    "Pinyin": "fū",
    "Definition": undefined,
    "Radical": "禾",
    "RadicalIndex": "115.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5203,
    "FrequencyRank": 7240
  },
  {
    "Character": "葙",
    "Pinyin": "xiāng",
    "Definition": "feather cockscomb (Celosia argentea)",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7304,
    "FrequencyRank": 7361
  },
  {
    "Character": "缑",
    "Pinyin": "gōu",
    "Definition": "cord binding on hilt of sword",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5335,
    "FrequencyRank": 6180
  },
  {
    "Character": "湲",
    "Pinyin": "yuán",
    "Definition": "flow",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5288,
    "FrequencyRank": undefined
  },
  {
    "Character": "鹃",
    "Pinyin": "juān",
    "Definition": "cuckoo",
    "Radical": "鳥",
    "RadicalIndex": "196.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2640,
    "FrequencyRank": 3051
  },
  {
    "Character": "禄",
    "Pinyin": "lù",
    "Definition": "blessing, happiness, prosperity",
    "Radical": "示",
    "RadicalIndex": "113.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2794,
    "FrequencyRank": 2381
  },
  {
    "Character": "牚",
    "Pinyin": "chēng",
    "Definition": undefined,
    "Radical": "牙",
    "RadicalIndex": "92.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7349,
    "FrequencyRank": undefined
  },
  {
    "Character": "靓",
    "Pinyin": "jìng",
    "Definition": "make up face; ornament; quiet",
    "Radical": "青",
    "RadicalIndex": "174.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5050,
    "FrequencyRank": 4636
  },
  {
    "Character": "傃",
    "Pinyin": "sù",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7392,
    "FrequencyRank": undefined
  },
  {
    "Character": "琵",
    "Pinyin": "pí",
    "Definition": "guitar-like instrument",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5043,
    "FrequencyRank": 3721
  },
  {
    "Character": "湃",
    "Pinyin": "pài",
    "Definition": "sound of waves; turbulent",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2762,
    "FrequencyRank": 3818
  },
  {
    "Character": "椆",
    "Pinyin": "chóu",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7328,
    "FrequencyRank": undefined
  },
  {
    "Character": "蛘",
    "Pinyin": "yáng",
    "Definition": "a weevil found in rice, etc.",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5151,
    "FrequencyRank": 5485
  },
  {
    "Character": "琪",
    "Pinyin": "qí",
    "Definition": "type of jade",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5045,
    "FrequencyRank": 3138
  },
  {
    "Character": "凓",
    "Pinyin": "lì",
    "Definition": "shiver; severe cold",
    "Radical": "冫",
    "RadicalIndex": "15.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7412,
    "FrequencyRank": undefined
  },
  {
    "Character": "湮",
    "Pinyin": "yān",
    "Definition": "bury, sink, block up; stain",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5279,
    "FrequencyRank": 4059
  },
  {
    "Character": "琡",
    "Pinyin": "chù",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7285,
    "FrequencyRank": undefined
  },
  {
    "Character": "筌",
    "Pinyin": "quán",
    "Definition": "bamboo fish trap",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5207,
    "FrequencyRank": 6087
  },
  {
    "Character": "硫",
    "Pinyin": "liú",
    "Definition": "sulfur",
    "Radical": "石",
    "RadicalIndex": "112.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2592,
    "FrequencyRank": 2730
  },
  {
    "Character": "痣",
    "Pinyin": "zhì",
    "Definition": "spots, moles; birthmark",
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5253,
    "FrequencyRank": 4143
  },
  {
    "Character": "戟",
    "Pinyin": "jǐ",
    "Definition": "halberd with crescent blade",
    "Radical": "戈",
    "RadicalIndex": "62.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5092,
    "FrequencyRank": 3726
  },
  {
    "Character": "傒",
    "Pinyin": "xī",
    "Definition": "personal name; servant",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7395,
    "FrequencyRank": undefined
  },
  {
    "Character": "鲂",
    "Pinyin": "fáng",
    "Definition": "bream",
    "Radical": "魚",
    "RadicalIndex": "195.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5237,
    "FrequencyRank": 4954
  },
  {
    "Character": "溁",
    "Pinyin": "yíng",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.14",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7427,
    "FrequencyRank": undefined
  },
  {
    "Character": "揄",
    "Pinyin": "yú",
    "Definition": "lift, raise; praise; hang; flap",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5071,
    "FrequencyRank": 4725
  },
  {
    "Character": "蒈",
    "Pinyin": "kǎi",
    "Definition": "an organic compound",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7308,
    "FrequencyRank": 8999
  },
  {
    "Character": "弑",
    "Pinyin": "shì",
    "Definition": undefined,
    "Radical": "弋",
    "RadicalIndex": "56.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5220,
    "FrequencyRank": 4763
  },
  {
    "Character": "湍",
    "Pinyin": "tuān",
    "Definition": "rapid water current, rapids",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5283,
    "FrequencyRank": 3850
  },
  {
    "Character": "廆",
    "Pinyin": "guī",
    "Definition": undefined,
    "Radical": "广",
    "RadicalIndex": "53.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7416,
    "FrequencyRank": undefined
  },
  {
    "Character": "舜",
    "Pinyin": "shùn",
    "Definition": "legendary ruler",
    "Radical": "舛",
    "RadicalIndex": "136.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5225,
    "FrequencyRank": 3182
  },
  {
    "Character": "跚",
    "Pinyin": "shān",
    "Definition": "stagger, limp",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5138,
    "FrequencyRank": 3888
  },
  {
    "Character": "葶",
    "Pinyin": "tíng",
    "Definition": "Draba nemerosa bebe carpa",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5089,
    "FrequencyRank": 6326
  },
  {
    "Character": "棼",
    "Pinyin": "fén",
    "Definition": "beams in the roof of a house confused, disordered",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5095,
    "FrequencyRank": 6395
  },
  {
    "Character": "蛛",
    "Pinyin": "zhū",
    "Definition": "spider",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2635,
    "FrequencyRank": 2653
  },
  {
    "Character": "遒",
    "Pinyin": "qiú",
    "Definition": "strong, unyielding, forceful",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5270,
    "FrequencyRank": 5596
  },
  {
    "Character": "嵅",
    "Pinyin": "hán",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7363,
    "FrequencyRank": undefined
  },
  {
    "Character": "廋",
    "Pinyin": "sōu",
    "Definition": "conceal, hide; search for, seek",
    "Radical": "广",
    "RadicalIndex": "53.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7415,
    "FrequencyRank": undefined
  },
  {
    "Character": "喋",
    "Pinyin": "dié",
    "Definition": "nag; chatter, babble, twitter",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5128,
    "FrequencyRank": 3633
  },
  {
    "Character": "遆",
    "Pinyin": "tí",
    "Definition": undefined,
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7419,
    "FrequencyRank": 7191
  },
  {
    "Character": "圌",
    "Pinyin": "chuán",
    "Definition": "fence",
    "Radical": "囗",
    "RadicalIndex": "31.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7368,
    "FrequencyRank": undefined
  },
  {
    "Character": "𧿹",
    "Pinyin": "mǔ",
    "Definition": "thumb, big toe",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7354,
    "FrequencyRank": undefined
  },
  {
    "Character": "琭",
    "Pinyin": "lù",
    "Definition": "jade like stone",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7288,
    "FrequencyRank": undefined
  },
  {
    "Character": "翕",
    "Pinyin": "xī",
    "Definition": "agree",
    "Radical": "羽",
    "RadicalIndex": "124.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5222,
    "FrequencyRank": 5066
  },
  {
    "Character": "辍",
    "Pinyin": "chuò",
    "Definition": "stop, suspend, halt",
    "Radical": "車",
    "RadicalIndex": "159.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5120,
    "FrequencyRank": 4060
  },
  {
    "Character": "甯",
    "Pinyin": "níng",
    "Definition": "peaceful",
    "Radical": "用",
    "RadicalIndex": "101.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7442,
    "FrequencyRank": 5935
  },
  {
    "Character": "阕",
    "Pinyin": "què",
    "Definition": "close, shut; watch tower",
    "Radical": "門",
    "RadicalIndex": "169.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5268,
    "FrequencyRank": 4929
  },
  {
    "Character": "喟",
    "Pinyin": "kuì",
    "Definition": "heave sigh, sigh",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5153,
    "FrequencyRank": 4423
  },
  {
    "Character": "湣",
    "Pinyin": "mǐn",
    "Definition": "mixed, confused; pity",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7436,
    "FrequencyRank": undefined
  },
  {
    "Character": "葭",
    "Pinyin": "jiā",
    "Definition": "bulrush, reed; flute, whistle",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5093,
    "FrequencyRank": 5735
  },
  {
    "Character": "颋",
    "Pinyin": "tǐng",
    "Definition": "straight",
    "Radical": "頁",
    "RadicalIndex": "181.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7385,
    "FrequencyRank": 7998
  },
  {
    "Character": "甥",
    "Pinyin": "shēng",
    "Definition": undefined,
    "Radical": "生",
    "RadicalIndex": "100.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2667,
    "FrequencyRank": 3809
  },
  {
    "Character": "嗟",
    "Pinyin": "jiē",
    "Definition": "sigh, alas",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5157,
    "FrequencyRank": 3875
  },
  {
    "Character": "犍",
    "Pinyin": "jiān",
    "Definition": "a bullock; a fabulous monster",
    "Radical": "牛",
    "RadicalIndex": "93.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5200,
    "FrequencyRank": 5551
  },
  {
    "Character": "筜",
    "Pinyin": "dāng",
    "Definition": "tall bamboo",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7389,
    "FrequencyRank": undefined
  },
  {
    "Character": "竦",
    "Pinyin": "sǒng",
    "Definition": "revere, respect, be in awe of",
    "Radical": "立",
    "RadicalIndex": "117.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5261,
    "FrequencyRank": 4627
  },
  {
    "Character": "堼",
    "Pinyin": "hèng",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7290,
    "FrequencyRank": undefined
  },
  {
    "Character": "葵",
    "Pinyin": "kuí",
    "Definition": "sunflower; measure",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2561,
    "FrequencyRank": 3580
  },
  {
    "Character": "扉",
    "Pinyin": "fēi",
    "Definition": "door panel",
    "Radical": "戶",
    "RadicalIndex": "63.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5304,
    "FrequencyRank": 4034
  },
  {
    "Character": "椪",
    "Pinyin": "pèng",
    "Definition": "Machilus nanmu, variety of evergreen",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5103,
    "FrequencyRank": undefined
  },
  {
    "Character": "棤",
    "Pinyin": "cuò",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7321,
    "FrequencyRank": undefined
  },
  {
    "Character": "壹",
    "Pinyin": "yī",
    "Definition": "number one",
    "Radical": "士",
    "RadicalIndex": "33.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2537,
    "FrequencyRank": 4652
  },
  {
    "Character": "喽",
    "Pinyin": "lóu",
    "Definition": "used in onomatopoetic expressions",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5158,
    "FrequencyRank": 3220
  },
  {
    "Character": "喙",
    "Pinyin": "huì",
    "Definition": "beak, bill, snout; pant",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5162,
    "FrequencyRank": 3990
  },
  {
    "Character": "𫛭",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7408,
    "FrequencyRank": undefined
  },
  {
    "Character": "皴",
    "Pinyin": "cūn",
    "Definition": "chapped, cracked",
    "Radical": "皮",
    "RadicalIndex": "107.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5325,
    "FrequencyRank": 5961
  },
  {
    "Character": "椟",
    "Pinyin": "dú",
    "Definition": "cabinet, wardrobe; closet",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5096,
    "FrequencyRank": 6535
  },
  {
    "Character": "蛩",
    "Pinyin": "qióng",
    "Definition": "cricket, locust; anxious",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5072,
    "FrequencyRank": 5009
  },
  {
    "Character": "觌",
    "Pinyin": "dí",
    "Definition": "see; interview; be admitted to audience",
    "Radical": "見",
    "RadicalIndex": "147.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7337,
    "FrequencyRank": 7086
  },
  {
    "Character": "酤",
    "Pinyin": "gū",
    "Definition": "to deal in spirits",
    "Radical": "酉",
    "RadicalIndex": "164.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5108,
    "FrequencyRank": 6479
  },
  {
    "Character": "赒",
    "Pinyin": "zhōu",
    "Definition": "give for charity",
    "Radical": "貝",
    "RadicalIndex": "154.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7372,
    "FrequencyRank": 6589
  },
  {
    "Character": "蛔",
    "Pinyin": "huí",
    "Definition": "tapeworm",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5147,
    "FrequencyRank": 4032
  },
  {
    "Character": "氯",
    "Pinyin": "lǜ",
    "Definition": "chlorine",
    "Radical": "气",
    "RadicalIndex": "84.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2673,
    "FrequencyRank": 3013
  },
  {
    "Character": "谠",
    "Pinyin": "dǎng",
    "Definition": "counsel, advice; speak out",
    "Radical": "言",
    "RadicalIndex": "149.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5310,
    "FrequencyRank": 5146
  },
  {
    "Character": "湉",
    "Pinyin": "tián",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5290,
    "FrequencyRank": undefined
  },
  {
    "Character": "溚",
    "Pinyin": "tǎ",
    "Definition": "(Cant.) to get wet by rain; to drip",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7426,
    "FrequencyRank": undefined
  },
  {
    "Character": "琮",
    "Pinyin": "cóng",
    "Definition": "octagonal piece of jade with hole in middle",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5052,
    "FrequencyRank": 5336
  },
  {
    "Character": "媂",
    "Pinyin": "dì",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7452,
    "FrequencyRank": undefined
  },
  {
    "Character": "愦",
    "Pinyin": "kuì",
    "Definition": "confused, troubled, muddle-headed",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5296,
    "FrequencyRank": 5744
  },
  {
    "Character": "傉",
    "Pinyin": "nù",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7393,
    "FrequencyRank": undefined
  },
  {
    "Character": "揆",
    "Pinyin": "kuí",
    "Definition": "prime minister; to guess, estimate",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5076,
    "FrequencyRank": 4614
  },
  {
    "Character": "掾",
    "Pinyin": "yuàn",
    "Definition": "a general designation of officials",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5077,
    "FrequencyRank": 5441
  },
  {
    "Character": "腒",
    "Pinyin": "jū",
    "Definition": "game",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7404,
    "FrequencyRank": undefined
  },
  {
    "Character": "晱",
    "Pinyin": "shǎn",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7353,
    "FrequencyRank": undefined
  },
  {
    "Character": "黍",
    "Pinyin": "shǔ",
    "Definition": "glutinous millet; KangXi radical number 202",
    "Radical": "黍",
    "RadicalIndex": "202",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5202,
    "FrequencyRank": 5081
  },
  {
    "Character": "琼",
    "Pinyin": "qióng",
    "Definition": "jade; rare, precious; elegant; (Cant.) to coagulate",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2504,
    "FrequencyRank": 2205
  },
  {
    "Character": "楗",
    "Pinyin": "jiàn",
    "Definition": "bar of door, bolt of lock",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7333,
    "FrequencyRank": 8305
  },
  {
    "Character": "聒",
    "Pinyin": "guā",
    "Definition": "clamor, din, hubbub",
    "Radical": "耳",
    "RadicalIndex": "128.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5078,
    "FrequencyRank": 5079
  },
  {
    "Character": "猰",
    "Pinyin": "yà",
    "Definition": undefined,
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7407,
    "FrequencyRank": undefined
  },
  {
    "Character": "溆",
    "Pinyin": "xù",
    "Definition": "river in Hunan",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5287,
    "FrequencyRank": 5366
  },
  {
    "Character": "缃",
    "Pinyin": "xiāng",
    "Definition": "light-yellow color",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5329,
    "FrequencyRank": 5487
  },
  {
    "Character": "粞",
    "Pinyin": "xī",
    "Definition": "to mash rice",
    "Radical": "米",
    "RadicalIndex": "119.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5269,
    "FrequencyRank": 6489
  },
  {
    "Character": "渼",
    "Pinyin": "měi",
    "Definition": "ripples",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7434,
    "FrequencyRank": undefined
  },
  {
    "Character": "琲",
    "Pinyin": "bèi",
    "Definition": "necklace",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7284,
    "FrequencyRank": undefined
  },
  {
    "Character": "跗",
    "Pinyin": "fū",
    "Definition": "the instep",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5136,
    "FrequencyRank": 5450
  },
  {
    "Character": "溠",
    "Pinyin": "zhà",
    "Definition": "river in Hubei province",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7433,
    "FrequencyRank": undefined
  },
  {
    "Character": "鹈",
    "Pinyin": "tí",
    "Definition": "pelican",
    "Radical": "鳥",
    "RadicalIndex": "196.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5276,
    "FrequencyRank": 5868
  },
  {
    "Character": "欹",
    "Pinyin": "yī",
    "Definition": "fierce dog; interjection of pleas; (Cant.) strange",
    "Radical": "欠",
    "RadicalIndex": "76.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7341,
    "FrequencyRank": 5908
  },
  {
    "Character": "猱",
    "Pinyin": "náo",
    "Definition": "a monkey with yellow hair",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5245,
    "FrequencyRank": 6431
  },
  {
    "Character": "腘",
    "Pinyin": "guó",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.11",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7401,
    "FrequencyRank": 6660
  },
  {
    "Character": "跛",
    "Pinyin": "bǒ",
    "Definition": "lame",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2632,
    "FrequencyRank": 3877
  },
  {
    "Character": "痨",
    "Pinyin": "láo",
    "Definition": "consumption; tuberculosis",
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5254,
    "FrequencyRank": 4886
  },
  {
    "Character": "覃",
    "Pinyin": "tán",
    "Definition": "reach to, spread to; extensive",
    "Radical": "襾",
    "RadicalIndex": "146.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5107,
    "FrequencyRank": 4889
  },
  {
    "Character": "矬",
    "Pinyin": "cuó",
    "Definition": "a dwarf",
    "Radical": "矢",
    "RadicalIndex": "111.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5192,
    "FrequencyRank": 5541
  },
  {
    "Character": "晷",
    "Pinyin": "guǐ",
    "Definition": "shadows of sun; time; sundial",
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5133,
    "FrequencyRank": 5799
  },
  {
    "Character": "鲀",
    "Pinyin": "tún",
    "Definition": "blowfish, globefish, Spheroides vermicular",
    "Radical": "魚",
    "RadicalIndex": "195.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5236,
    "FrequencyRank": 5586
  },
  {
    "Character": "棠",
    "Pinyin": "táng",
    "Definition": "crab apple tree; wild plums",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2608,
    "FrequencyRank": 2489
  },
  {
    "Character": "锕",
    "Pinyin": "ā",
    "Definition": "actinium (Ac)",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5190,
    "FrequencyRank": 5341
  },
  {
    "Character": "琯",
    "Pinyin": "guǎn",
    "Definition": "a jade tube used as an instrument",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5053,
    "FrequencyRank": undefined
  },
  {
    "Character": "跞",
    "Pinyin": "lì",
    "Definition": "walk, move",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5137,
    "FrequencyRank": 5301
  },
  {
    "Character": "奡",
    "Pinyin": "ào",
    "Definition": "arrogant",
    "Radical": "大",
    "RadicalIndex": "37.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7338,
    "FrequencyRank": undefined
  },
  {
    "Character": "亵",
    "Pinyin": "xiè",
    "Definition": "slight, insult, treat with disrespect",
    "Radical": "亠",
    "RadicalIndex": "8.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5250,
    "FrequencyRank": 3495
  },
  {
    "Character": "铿",
    "Pinyin": "kēng",
    "Definition": "strike, beat, stroke; jingling",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5180,
    "FrequencyRank": 4111
  },
  {
    "Character": "嗞",
    "Pinyin": "zī",
    "Definition": "to consult about, to plan; to report in writing to an equal; a despatch",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5159,
    "FrequencyRank": undefined
  },
  {
    "Character": "皓",
    "Pinyin": "hào",
    "Definition": "bright, luminous; clear; hoary",
    "Radical": "白",
    "RadicalIndex": "106.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2698,
    "FrequencyRank": 3053
  },
  {
    "Character": "孳",
    "Pinyin": "zī",
    "Definition": "breed in large numbers",
    "Radical": "子",
    "RadicalIndex": "39.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5271,
    "FrequencyRank": 4875
  },
  {
    "Character": "锑",
    "Pinyin": "tī",
    "Definition": "antimony",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5187,
    "FrequencyRank": 5396
  },
  {
    "Character": "嵚",
    "Pinyin": "qīn",
    "Definition": "lofty, towering",
    "Radical": "山",
    "RadicalIndex": "46.12",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7365,
    "FrequencyRank": undefined
  },
  {
    "Character": "氰",
    "Pinyin": "qíng",
    "Definition": "cyanogen; ethane dinitrile",
    "Radical": "气",
    "RadicalIndex": "84.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5193,
    "FrequencyRank": 4463
  },
  {
    "Character": "缌",
    "Pinyin": "sī",
    "Definition": "coarse cotton cloth used for mourning",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5334,
    "FrequencyRank": 6115
  },
  {
    "Character": "喈",
    "Pinyin": "jiē",
    "Definition": "music; melody",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5134,
    "FrequencyRank": 6652
  },
  {
    "Character": "嵋",
    "Pinyin": "méi",
    "Definition": "Omei mountain in Sichuan",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5176,
    "FrequencyRank": 3796
  },
  {
    "Character": "惺",
    "Pinyin": "xīng",
    "Definition": "intelligent, clever, astute",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5295,
    "FrequencyRank": 3357
  },
  {
    "Character": "湟",
    "Pinyin": "huáng",
    "Definition": "river in qinghai province",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5286,
    "FrequencyRank": 4862
  },
  {
    "Character": "觞",
    "Pinyin": "shāng",
    "Definition": "wine vessel; propose toast; feast",
    "Radical": "角",
    "RadicalIndex": "148.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5243,
    "FrequencyRank": 5139
  },
  {
    "Character": "痤",
    "Pinyin": "cuó",
    "Definition": "a swelling of the lymph nodes",
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5257,
    "FrequencyRank": 4924
  },
  {
    "Character": "畯",
    "Pinyin": "jùn",
    "Definition": "rustic; crude",
    "Radical": "田",
    "RadicalIndex": "102.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7356,
    "FrequencyRank": undefined
  },
  {
    "Character": "焜",
    "Pinyin": "kūn",
    "Definition": "fire, flames; bright, shining",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5273,
    "FrequencyRank": undefined
  },
  {
    "Character": "堰",
    "Pinyin": "yàn",
    "Definition": "dam; embankment, dike, bank",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2512,
    "FrequencyRank": 3486
  },
  {
    "Character": "锆",
    "Pinyin": "gào",
    "Definition": "zirconium",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5183,
    "FrequencyRank": 5501
  },
  {
    "Character": "遁",
    "Pinyin": "dùn",
    "Definition": "hide, conceal oneself; escape",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5216,
    "FrequencyRank": 3222
  },
  {
    "Character": "筥",
    "Pinyin": "jǔ",
    "Definition": "round-shaped bamboo basket for holding rice",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7390,
    "FrequencyRank": 8956
  },
  {
    "Character": "裣",
    "Pinyin": "liǎn",
    "Definition": undefined,
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7445,
    "FrequencyRank": 5885
  },
  {
    "Character": "锍",
    "Pinyin": "liǔ",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7380,
    "FrequencyRank": 6048
  },
  {
    "Character": "椀",
    "Pinyin": "wǎn",
    "Definition": "bowl; cup; dish",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7332,
    "FrequencyRank": undefined
  },
  {
    "Character": "铻",
    "Pinyin": "wú",
    "Definition": "misfit; disordant; music instrument; hoe",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5178,
    "FrequencyRank": 7726
  },
  {
    "Character": "湘",
    "Pinyin": "xiāng",
    "Definition": "Hunan province",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2752,
    "FrequencyRank": 2245
  },
  {
    "Character": "湄",
    "Pinyin": "méi",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5292,
    "FrequencyRank": 3639
  },
  {
    "Character": "嵛",
    "Pinyin": "yú",
    "Definition": "county in Shandong province",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5171,
    "FrequencyRank": 5858
  },
  {
    "Character": "翙",
    "Pinyin": "huì",
    "Definition": undefined,
    "Radical": "羽",
    "RadicalIndex": "124.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7366,
    "FrequencyRank": 6475
  },
  {
    "Character": "锌",
    "Pinyin": "xīn",
    "Definition": "zinc",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2665,
    "FrequencyRank": 3533
  },
  {
    "Character": "湓",
    "Pinyin": "pén",
    "Definition": "an affluent of the River Yangtze near Kiukiang",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7430,
    "FrequencyRank": 6011
  },
  {
    "Character": "蒐",
    "Pinyin": "sōu",
    "Definition": "collect, gather, assemble; seek; spring hunt; assemble for war",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7313,
    "FrequencyRank": 6707
  },
  {
    "Character": "椠",
    "Pinyin": "qiàn",
    "Definition": "wooden tablet; edition",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5119,
    "FrequencyRank": 6579
  },
  {
    "Character": "崽",
    "Pinyin": "zǎi",
    "Definition": "a child, a servant; a diminutive",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5169,
    "FrequencyRank": 3712
  },
  {
    "Character": "琚",
    "Pinyin": "jū",
    "Definition": "girdle ornaments",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5056,
    "FrequencyRank": 6604
  },
  {
    "Character": "嗖",
    "Pinyin": "sōu",
    "Definition": "whizzing sound",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5155,
    "FrequencyRank": 3751
  },
  {
    "Character": "遑",
    "Pinyin": "huáng",
    "Definition": "leisure, leisurely; hurry about",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5214,
    "FrequencyRank": 5020
  },
  {
    "Character": "湔",
    "Pinyin": "jiān",
    "Definition": "wash, cleanse; purge",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5289,
    "FrequencyRank": 5920
  },
  {
    "Character": "裒",
    "Pinyin": "póu",
    "Definition": "collect, gather, assemble; praise",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5252,
    "FrequencyRank": 6787
  },
  {
    "Character": "赓",
    "Pinyin": "gēng",
    "Definition": "continue",
    "Radical": "广",
    "RadicalIndex": "53.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5260,
    "FrequencyRank": 3803
  },
  {
    "Character": "猯",
    "Pinyin": "tuān",
    "Definition": undefined,
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7409,
    "FrequencyRank": undefined
  },
  {
    "Character": "椤",
    "Pinyin": "luó",
    "Definition": "tree",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5098,
    "FrequencyRank": 4399
  },
  {
    "Character": "巽",
    "Pinyin": "xùn",
    "Definition": "5th of the 8 trigrams; south-east; mild, modest, obedient",
    "Radical": "己",
    "RadicalIndex": "49.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5318,
    "FrequencyRank": 5255
  },
  {
    "Character": "鹆",
    "Pinyin": "yù",
    "Definition": "myna; manah; Acridotheres tristis",
    "Radical": "鳥",
    "RadicalIndex": "196.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5224,
    "FrequencyRank": 5371
  },
  {
    "Character": "舾",
    "Pinyin": "xī",
    "Definition": "(Cant.) equipment on a ship",
    "Radical": "舟",
    "RadicalIndex": "137.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7397,
    "FrequencyRank": 5924
  },
  {
    "Character": "蜒",
    "Pinyin": "yán",
    "Definition": "millipede",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2637,
    "FrequencyRank": 3655
  },
  {
    "Character": "筏",
    "Pinyin": "fá",
    "Definition": "raft",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2686,
    "FrequencyRank": 3785
  },
  {
    "Character": "欻",
    "Pinyin": "chuā",
    "Definition": "sudden, abrupt, quick",
    "Radical": "欠",
    "RadicalIndex": "76.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7424,
    "FrequencyRank": undefined
  },
  {
    "Character": "痞",
    "Pinyin": "pǐ",
    "Definition": "dyspepsia, spleen infection",
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5256,
    "FrequencyRank": 4093
  },
  {
    "Character": "葩",
    "Pinyin": "pā",
    "Definition": "flowers",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5088,
    "FrequencyRank": 5158
  },
  {
    "Character": "痦",
    "Pinyin": "wù",
    "Definition": undefined,
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5255,
    "FrequencyRank": 7308
  },
  {
    "Character": "惴",
    "Pinyin": "zhuì",
    "Definition": "afraid, apprehensive, nervous",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5297,
    "FrequencyRank": 3725
  },
  {
    "Character": "蒇",
    "Pinyin": "chǎn",
    "Definition": "finish, complete; solve; complete",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7307,
    "FrequencyRank": 7435
  },
  {
    "Character": "湛",
    "Pinyin": "zhàn",
    "Definition": "deep; profound; clear; tranquil, placid",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5277,
    "FrequencyRank": 3189
  },
  {
    "Character": "铽",
    "Pinyin": "tè",
    "Definition": "terbium",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7376,
    "FrequencyRank": 7654
  },
  {
    "Character": "惎",
    "Pinyin": "jì",
    "Definition": "injure, harm, murder",
    "Radical": "心",
    "RadicalIndex": "61.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7302,
    "FrequencyRank": undefined
  },
  {
    "Character": "葆",
    "Pinyin": "bǎo",
    "Definition": "reserve, preserve; conceal",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5087,
    "FrequencyRank": 3988
  },
  {
    "Character": "萱",
    "Pinyin": "xuān",
    "Definition": "day-lily, hemerocallisflava",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5091,
    "FrequencyRank": 4243
  },
  {
    "Character": "蛱",
    "Pinyin": "jiá",
    "Definition": "kind of butterfly",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5142,
    "FrequencyRank": 5381
  },
  {
    "Character": "骛",
    "Pinyin": "wù",
    "Definition": "gallop; rush about; pursue, run",
    "Radical": "馬",
    "RadicalIndex": "187.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5327,
    "FrequencyRank": 4621
  },
  {
    "Character": "酢",
    "Pinyin": "cù",
    "Definition": undefined,
    "Radical": "酉",
    "RadicalIndex": "164.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5109,
    "FrequencyRank": 4688
  },
  {
    "Character": "龂",
    "Pinyin": "yín",
    "Definition": "gums (of the teeth); to dispute",
    "Radical": "齒",
    "RadicalIndex": "211.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7346,
    "FrequencyRank": 6692
  },
  {
    "Character": "葫",
    "Pinyin": "hú",
    "Definition": "bottle-gourd",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2545,
    "FrequencyRank": 3246
  },
  {
    "Character": "葚",
    "Pinyin": "rèn",
    "Definition": "mulberry fruit",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5080,
    "FrequencyRank": 5969
  },
  {
    "Character": "蓇",
    "Pinyin": "gǔ",
    "Definition": "follicles of plants",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7311,
    "FrequencyRank": 7548
  },
  {
    "Character": "弼",
    "Pinyin": "bì",
    "Definition": "aid, assist, help; correct",
    "Radical": "弓",
    "RadicalIndex": "57.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5317,
    "FrequencyRank": 3647
  },
  {
    "Character": "痘",
    "Pinyin": "dòu",
    "Definition": "smallpox",
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2731,
    "FrequencyRank": 4106
  },
  {
    "Character": "稂",
    "Pinyin": "láng",
    "Definition": "grass; weeds",
    "Radical": "禾",
    "RadicalIndex": "115.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5204,
    "FrequencyRank": 8008
  },
  {
    "Character": "楮",
    "Pinyin": "chǔ",
    "Definition": "mulberry; paper",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5094,
    "FrequencyRank": 5994
  },
  {
    "Character": "锃",
    "Pinyin": "zèng",
    "Definition": "polish",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5181,
    "FrequencyRank": 4821
  },
  {
    "Character": "琳",
    "Pinyin": "lín",
    "Definition": "beautiful jade, gem",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2502,
    "FrequencyRank": 2400
  },
  {
    "Character": "毽",
    "Pinyin": "jiàn",
    "Definition": "a shuttlecock",
    "Radical": "毛",
    "RadicalIndex": "82.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5195,
    "FrequencyRank": 6291
  },
  {
    "Character": "鲃",
    "Pinyin": "bā",
    "Definition": "a kind of fish; a bonito, (same as 鮁) shark",
    "Radical": "魚",
    "RadicalIndex": "195.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7406,
    "FrequencyRank": 5861
  },
  {
    "Character": "遄",
    "Pinyin": "chuán",
    "Definition": "to hurry; to go to and fro",
    "Radical": "辵",
    "RadicalIndex": "162.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5166,
    "FrequencyRank": 5956
  },
  {
    "Character": "嵯",
    "Pinyin": "cuó",
    "Definition": "high, towering; irregular, rugged",
    "Radical": "山",
    "RadicalIndex": "46.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5172,
    "FrequencyRank": 5954
  },
  {
    "Character": "辌",
    "Pinyin": "liáng",
    "Definition": "a hearse; a carriage",
    "Radical": "車",
    "RadicalIndex": "159.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7344,
    "FrequencyRank": 7765
  },
  {
    "Character": "琬",
    "Pinyin": "wǎn",
    "Definition": "the virtue of a gentleman; jade",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5054,
    "FrequencyRank": 4635
  },
  {
    "Character": "腴",
    "Pinyin": "yú",
    "Definition": "fat; fertile, rich; plump, soft",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5231,
    "FrequencyRank": 4548
  },
  {
    "Character": "辇",
    "Pinyin": "niǎn",
    "Definition": "hand-cart; transport by carriage",
    "Radical": "車",
    "RadicalIndex": "159.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5057,
    "FrequencyRank": 5044
  },
  {
    "Character": "溲",
    "Pinyin": "sōu",
    "Definition": "urinate; soak, drench",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5285,
    "FrequencyRank": 6084
  },
  {
    "Character": "鼋",
    "Pinyin": "yuán",
    "Definition": "large turtle, sea turtle",
    "Radical": "龜",
    "RadicalIndex": "213.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5058,
    "FrequencyRank": 5232
  },
  {
    "Character": "厥",
    "Pinyin": "jué",
    "Definition": "personal pronoun he, she, it",
    "Radical": "厂",
    "RadicalIndex": "27.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5112,
    "FrequencyRank": 2755
  },
  {
    "Character": "赕",
    "Pinyin": "dǎn",
    "Definition": "fine",
    "Radical": "貝",
    "RadicalIndex": "154.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5177,
    "FrequencyRank": 6924
  },
  {
    "Character": "葑",
    "Pinyin": "fēng",
    "Definition": "the rape-turnip",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5079,
    "FrequencyRank": 5930
  },
  {
    "Character": "戢",
    "Pinyin": "jí",
    "Definition": "to put away; to cease; store up",
    "Radical": "戈",
    "RadicalIndex": "62.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5127,
    "FrequencyRank": 6205
  },
  {
    "Character": "谥",
    "Pinyin": "shì",
    "Definition": "to confer posthumous titles",
    "Radical": "言",
    "RadicalIndex": "149.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5313,
    "FrequencyRank": 4039
  },
  {
    "Character": "渤",
    "Pinyin": "bó",
    "Definition": "swelling; the Gulf of Hopei (Hebei)",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2754,
    "FrequencyRank": 3762
  },
  {
    "Character": "跋",
    "Pinyin": "bá",
    "Definition": "go by foot; epilogue, colophon",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2629,
    "FrequencyRank": 2917
  },
  {
    "Character": "皖",
    "Pinyin": "wǎn",
    "Definition": "Anhui province",
    "Radical": "白",
    "RadicalIndex": "106.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2699,
    "FrequencyRank": 3247
  },
  {
    "Character": "谧",
    "Pinyin": "mì",
    "Definition": "calm, quiet, still; cautious",
    "Radical": "言",
    "RadicalIndex": "149.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5314,
    "FrequencyRank": 4058
  },
  {
    "Character": "蛐",
    "Pinyin": "qū",
    "Definition": "cricket; worm",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5146,
    "FrequencyRank": 4146
  },
  {
    "Character": "锄",
    "Pinyin": "chú",
    "Definition": "hoe; eradicate",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2661,
    "FrequencyRank": 3545
  },
  {
    "Character": "晪",
    "Pinyin": "tiǎn",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7352,
    "FrequencyRank": undefined
  },
  {
    "Character": "萩",
    "Pinyin": "qiū",
    "Definition": "scandent hop; tree",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7312,
    "FrequencyRank": 7028
  },
  {
    "Character": "骙",
    "Pinyin": "kuí",
    "Definition": "(of a horse) lively; vigorous",
    "Radical": "馬",
    "RadicalIndex": "187.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7460,
    "FrequencyRank": 6594
  },
  {
    "Character": "寐",
    "Pinyin": "mèi",
    "Definition": "sleep; be asleep",
    "Radical": "宀",
    "RadicalIndex": "40.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5302,
    "FrequencyRank": 3825
  },
  {
    "Character": "塆",
    "Pinyin": "wān",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5074,
    "FrequencyRank": undefined
  },
  {
    "Character": "鄑",
    "Pinyin": "zī",
    "Definition": "kick; place in Shandong province",
    "Radical": "邑",
    "RadicalIndex": "163.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7316,
    "FrequencyRank": 9293
  },
  {
    "Character": "堞",
    "Pinyin": "dié",
    "Definition": "plate",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5060,
    "FrequencyRank": 5850
  },
  {
    "Character": "颎",
    "Pinyin": "jiǒng",
    "Definition": "bright",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5246,
    "FrequencyRank": 5669
  },
  {
    "Character": "跎",
    "Pinyin": "tuó",
    "Definition": "slip, stumble, falter; vacillate",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5139,
    "FrequencyRank": 5254
  },
  {
    "Character": "锎",
    "Pinyin": "kāi",
    "Definition": "caesium",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7381,
    "FrequencyRank": 6226
  },
  {
    "Character": "滁",
    "Pinyin": "chú",
    "Definition": "district in Anhui province",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5293,
    "FrequencyRank": 5777
  },
  {
    "Character": "媓",
    "Pinyin": "huáng",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7451,
    "FrequencyRank": undefined
  },
  {
    "Character": "愎",
    "Pinyin": "bì",
    "Definition": "obstinate, stubborn, headstrong",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5299,
    "FrequencyRank": 4663
  },
  {
    "Character": "棽",
    "Pinyin": "shēn",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7322,
    "FrequencyRank": undefined
  },
  {
    "Character": "摒",
    "Pinyin": "bǐng",
    "Definition": "expel, cast off; arrange",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5075,
    "FrequencyRank": 3791
  },
  {
    "Character": "揩",
    "Pinyin": "kāi",
    "Definition": "rub and wipe, dust, clean",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2513,
    "FrequencyRank": 3671
  },
  {
    "Character": "睃",
    "Pinyin": "suō",
    "Definition": undefined,
    "Radical": "目",
    "RadicalIndex": "109.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5126,
    "FrequencyRank": 5958
  },
  {
    "Character": "蛟",
    "Pinyin": "jiāo",
    "Definition": "scaly dragon with four legs",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5150,
    "FrequencyRank": 3467
  },
  {
    "Character": "颉",
    "Pinyin": "jié",
    "Definition": "fly upward, soar; contest",
    "Radical": "頁",
    "RadicalIndex": "181.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5067,
    "FrequencyRank": 3544
  },
  {
    "Character": "渫",
    "Pinyin": "xiè",
    "Definition": "beating of ocean; surging of water",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5278,
    "FrequencyRank": 6200
  },
  {
    "Character": "犄",
    "Pinyin": "jī",
    "Definition": "animal horns",
    "Radical": "牛",
    "RadicalIndex": "93.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5197,
    "FrequencyRank": 4656
  },
  {
    "Character": "喳",
    "Pinyin": "zhā",
    "Definition": "whispering",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2619,
    "FrequencyRank": 3311
  },
  {
    "Character": "鹂",
    "Pinyin": "lí",
    "Definition": "Chinese oriole; Oriolus oriolus",
    "Radical": "鳥",
    "RadicalIndex": "196.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5111,
    "FrequencyRank": 5427
  },
  {
    "Character": "猢",
    "Pinyin": "hú",
    "Definition": "a kind of monkey found in W.China",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5239,
    "FrequencyRank": 5783
  },
  {
    "Character": "睑",
    "Pinyin": "jiǎn",
    "Definition": "eyelid",
    "Radical": "目",
    "RadicalIndex": "109.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5124,
    "FrequencyRank": 3901
  },
  {
    "Character": "毳",
    "Pinyin": "cuì",
    "Definition": "fine hair or fur on animals",
    "Radical": "毛",
    "RadicalIndex": "82.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5194,
    "FrequencyRank": 7796
  },
  {
    "Character": "崴",
    "Pinyin": "wǎi",
    "Definition": "high, lofty; precipitous",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5165,
    "FrequencyRank": 4687
  },
  {
    "Character": "揳",
    "Pinyin": "xiē",
    "Definition": undefined,
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5059,
    "FrequencyRank": undefined
  },
  {
    "Character": "猬",
    "Pinyin": "wèi",
    "Definition": "vulgar; wanton; low; many; varied; a hedgehog, porcupine",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2719,
    "FrequencyRank": 4455
  },
  {
    "Character": "渰",
    "Pinyin": "yǎn",
    "Definition": "(of cloud) forming or rising",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7429,
    "FrequencyRank": undefined
  },
  {
    "Character": "搽",
    "Pinyin": "chá",
    "Definition": "smear; rub, wipe; anoint",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5061,
    "FrequencyRank": 4565
  },
  {
    "Character": "萳",
    "Pinyin": "nǎn",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7303,
    "FrequencyRank": undefined
  },
  {
    "Character": "傈",
    "Pinyin": "lì",
    "Definition": "tribe",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5209,
    "FrequencyRank": 5289
  },
  {
    "Character": "馇",
    "Pinyin": "chā",
    "Definition": "stir and cook",
    "Radical": "食",
    "RadicalIndex": "184.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5248,
    "FrequencyRank": 6000
  },
  {
    "Character": "蛞",
    "Pinyin": "kuò",
    "Definition": "snail; slug, mole cricket",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5148,
    "FrequencyRank": 4993
  },
  {
    "Character": "嵎",
    "Pinyin": "yú",
    "Definition": "mountain recess; canyon",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5168,
    "FrequencyRank": undefined
  },
  {
    "Character": "馉",
    "Pinyin": "gǔ",
    "Definition": undefined,
    "Radical": "食",
    "RadicalIndex": "184.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7411,
    "FrequencyRank": 8087
  },
  {
    "Character": "喤",
    "Pinyin": "huáng",
    "Definition": "ah; harmony",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7358,
    "FrequencyRank": undefined
  },
  {
    "Character": "犋",
    "Pinyin": "jù",
    "Definition": undefined,
    "Radical": "牛",
    "RadicalIndex": "93.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5198,
    "FrequencyRank": 7317
  },
  {
    "Character": "缊",
    "Pinyin": "yūn",
    "Definition": "tangled hemp, raveled silk; vague, confused",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7458,
    "FrequencyRank": 7546
  },
  {
    "Character": "祾",
    "Pinyin": "líng",
    "Definition": undefined,
    "Radical": "示",
    "RadicalIndex": "113.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5308,
    "FrequencyRank": 8954
  },
  {
    "Character": "腆",
    "Pinyin": "tiǎn",
    "Definition": "prosperous; good; protruding",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5230,
    "FrequencyRank": 4025
  },
  {
    "Character": "傩",
    "Pinyin": "nuó",
    "Definition": "rich",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5215,
    "FrequencyRank": 3777
  },
  {
    "Character": "葖",
    "Pinyin": "tū",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7318,
    "FrequencyRank": 8466
  },
  {
    "Character": "琔",
    "Pinyin": "diàn",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7287,
    "FrequencyRank": undefined
  },
  {
    "Character": "牍",
    "Pinyin": "dú",
    "Definition": "writing tablet; documents, books",
    "Radical": "片",
    "RadicalIndex": "91.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5211,
    "FrequencyRank": 4235
  },
  {
    "Character": "喀",
    "Pinyin": "kā",
    "Definition": "vomit; used in transliterations",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5160,
    "FrequencyRank": 2469
  },
  {
    "Character": "颌",
    "Pinyin": "hé",
    "Definition": "mouth; jaw",
    "Radical": "頁",
    "RadicalIndex": "181.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5221,
    "FrequencyRank": 3279
  },
  {
    "Character": "嵇",
    "Pinyin": "jī",
    "Definition": "mountain in Henan; surname",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5201,
    "FrequencyRank": 4138
  },
  {
    "Character": "葳",
    "Pinyin": "wēi",
    "Definition": "luxuriant, flourishing; used for various plants",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5083,
    "FrequencyRank": 6322
  },
  {
    "Character": "琶",
    "Pinyin": "pá",
    "Definition": "guitar-like instrument",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5044,
    "FrequencyRank": 3612
  },
  {
    "Character": "喁",
    "Pinyin": "yóng",
    "Definition": "the mouth of a fish at the surface of the water, gasping for breath",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5152,
    "FrequencyRank": 5739
  },
  {
    "Character": "腱",
    "Pinyin": "jiàn",
    "Definition": "tendons",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5234,
    "FrequencyRank": 5022
  },
  {
    "Character": "釉",
    "Pinyin": "yòu",
    "Definition": "glaze",
    "Radical": "釆",
    "RadicalIndex": "165.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5223,
    "FrequencyRank": 2923
  },
  {
    "Character": "雯",
    "Pinyin": "wén",
    "Definition": "cloud patterns, coloring of cloud",
    "Radical": "雨",
    "RadicalIndex": "173.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5115,
    "FrequencyRank": 2957
  },
  {
    "Character": "湜",
    "Pinyin": "shí",
    "Definition": "transparent, clear",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5281,
    "FrequencyRank": undefined
  },
  {
    "Character": "詟",
    "Pinyin": "zhé",
    "Definition": "fear; envy; loquacious",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7342,
    "FrequencyRank": 8417
  },
  {
    "Character": "湝",
    "Pinyin": "jiē",
    "Definition": "flow",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7428,
    "FrequencyRank": undefined
  },
  {
    "Character": "缄",
    "Pinyin": "jiān",
    "Definition": "seal, close; bind; letter",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5330,
    "FrequencyRank": 3908
  },
  {
    "Character": "辋",
    "Pinyin": "wǎng",
    "Definition": "exterior rim of wheel, felly",
    "Radical": "車",
    "RadicalIndex": "159.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5118,
    "FrequencyRank": 6362
  },
  {
    "Character": "堠",
    "Pinyin": "hòu",
    "Definition": "battlements, battlemented walls",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7297,
    "FrequencyRank": 6986
  },
  {
    "Character": "棫",
    "Pinyin": "yù",
    "Definition": "thorny shrub with yellow flowers; a kind of oak",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7323,
    "FrequencyRank": undefined
  },
  {
    "Character": "蛑",
    "Pinyin": "móu",
    "Definition": "a marine crab",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7355,
    "FrequencyRank": 5555
  },
  {
    "Character": "祺",
    "Pinyin": "qí",
    "Definition": "good luck, good fortune",
    "Radical": "示",
    "RadicalIndex": "113.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5309,
    "FrequencyRank": 3499
  },
  {
    "Character": "颏",
    "Pinyin": "kē",
    "Definition": "chin",
    "Radical": "頁",
    "RadicalIndex": "181.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5264,
    "FrequencyRank": 4876
  },
  {
    "Character": "椁",
    "Pinyin": "guǒ",
    "Definition": "outer-coffin; vault",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5102,
    "FrequencyRank": 5784
  },
  {
    "Character": "嵘",
    "Pinyin": "róng",
    "Definition": "high, steep; lofty, towering",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5163,
    "FrequencyRank": 4746
  },
  {
    "Character": "鄗",
    "Pinyin": "hào",
    "Definition": "county in Hebei province",
    "Radical": "邑",
    "RadicalIndex": "163.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7413,
    "FrequencyRank": 6552
  },
  {
    "Character": "椐",
    "Pinyin": "jū",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5105,
    "FrequencyRank": 6815
  },
  {
    "Character": "缗",
    "Pinyin": "mín",
    "Definition": "fishing-line; cord; string of coi",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5337,
    "FrequencyRank": 5538
  },
  {
    "Character": "犊",
    "Pinyin": "dú",
    "Definition": "calf; victim of sacrifice",
    "Radical": "牛",
    "RadicalIndex": "93.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5196,
    "FrequencyRank": 3915
  },
  {
    "Character": "鹇",
    "Pinyin": "xián",
    "Definition": "silver pheasant, Lophura nycthemera; Lophura species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5265,
    "FrequencyRank": 6307
  },
  {
    "Character": "斌",
    "Pinyin": "bīn",
    "Definition": "refined, having both appearance",
    "Radical": "文",
    "RadicalIndex": "67.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2730,
    "FrequencyRank": 2442
  },
  {
    "Character": "锉",
    "Pinyin": "cuò",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5185,
    "FrequencyRank": 4866
  },
  {
    "Character": "喔",
    "Pinyin": "ō",
    "Definition": "descriptive of crying or of crowing",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5161,
    "FrequencyRank": 2860
  },
  {
    "Character": "傧",
    "Pinyin": "bīn",
    "Definition": "entertain guests",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5213,
    "FrequencyRank": 5738
  },
  {
    "Character": "㴔",
    "Pinyin": "xī",
    "Definition": "(same as 潝) the noise of flowing water, swift flowing water (same as non-classical form of 澀) rough; harsh; not smooth, a slightly bitter taste",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7431,
    "FrequencyRank": undefined
  },
  {
    "Character": "媛",
    "Pinyin": "yuàn",
    "Definition": "beauty, beautiful woman",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5321,
    "FrequencyRank": 4078
  },
  {
    "Character": "琥",
    "Pinyin": "hǔ",
    "Definition": "jewel in shape of tiger; amber",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5048,
    "FrequencyRank": 4456
  },
  {
    "Character": "愀",
    "Pinyin": "qiǎo",
    "Definition": undefined,
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5298,
    "FrequencyRank": 5481
  },
  {
    "Character": "榔",
    "Pinyin": "láng",
    "Definition": "betel-nut tree",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2578,
    "FrequencyRank": 4337
  },
  {
    "Character": "琰",
    "Pinyin": "yǎn",
    "Definition": "jewel, gem; glitter of gems",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5051,
    "FrequencyRank": 5132
  },
  {
    "Character": "琟",
    "Pinyin": "wéi",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7286,
    "FrequencyRank": undefined
  },
  {
    "Character": "絜",
    "Pinyin": "jié",
    "Definition": "a marking line; ascertain, assess; measure",
    "Radical": "糸",
    "RadicalIndex": "120.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7282,
    "FrequencyRank": 6587
  },
  {
    "Character": "喆",
    "Pinyin": "zhé",
    "Definition": "a sage; wise; sagacious",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7294,
    "FrequencyRank": undefined
  },
  {
    "Character": "耠",
    "Pinyin": "huō",
    "Definition": "till, dig",
    "Radical": "耒",
    "RadicalIndex": "127.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5041,
    "FrequencyRank": undefined
  },
  {
    "Character": "琦",
    "Pinyin": "qí",
    "Definition": "gem, precious stone, jade",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5047,
    "FrequencyRank": 3288
  },
  {
    "Character": "矞",
    "Pinyin": "yù",
    "Definition": "bore with awl; bright, charming",
    "Radical": "矛",
    "RadicalIndex": "110.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7455,
    "FrequencyRank": 7243
  },
  {
    "Character": "棬",
    "Pinyin": "quān",
    "Definition": "bowl",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7330,
    "FrequencyRank": undefined
  },
  {
    "Character": "渭",
    "Pinyin": "wèi",
    "Definition": "name of a river in Shanxi",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5282,
    "FrequencyRank": 3620
  },
  {
    "Character": "葛",
    "Pinyin": "gé",
    "Definition": "edible bean; surname",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2550,
    "FrequencyRank": 1919
  },
  {
    "Character": "蛳",
    "Pinyin": "sī",
    "Definition": "kind of snail with spiral shell",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5145,
    "FrequencyRank": 4385
  },
  {
    "Character": "渟",
    "Pinyin": "tíng",
    "Definition": "(of water) not flowing; clear",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7432,
    "FrequencyRank": undefined
  },
  {
    "Character": "喹",
    "Pinyin": "kuí",
    "Definition": "chemical compound",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5132,
    "FrequencyRank": 6071
  },
  {
    "Character": "锒",
    "Pinyin": "láng",
    "Definition": "lock",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5188,
    "FrequencyRank": 5340
  },
  {
    "Character": "湑",
    "Pinyin": "xū",
    "Definition": "to strain spirits; river in Guangxi; abundant; bright",
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7437,
    "FrequencyRank": undefined
  },
  {
    "Character": "筚",
    "Pinyin": "bì",
    "Definition": "wicker, bamboo",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5205,
    "FrequencyRank": 6539
  },
  {
    "Character": "酡",
    "Pinyin": "tuó",
    "Definition": "flushed; rubicund",
    "Radical": "酉",
    "RadicalIndex": "164.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5110,
    "FrequencyRank": 6450
  },
  {
    "Character": "棣",
    "Pinyin": "dì",
    "Definition": "kerria japonica plant, cherry",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5104,
    "FrequencyRank": 4213
  },
  {
    "Character": "蛲",
    "Pinyin": "náo",
    "Definition": "worms",
    "Radical": "虫",
    "RadicalIndex": "142.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5143,
    "FrequencyRank": 5835
  },
  {
    "Character": "腑",
    "Pinyin": "fǔ",
    "Definition": "bowels, entrails, internal organs",
    "Radical": "肉",
    "RadicalIndex": "130.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5232,
    "FrequencyRank": 3491
  },
  {
    "Character": "睐",
    "Pinyin": "lài",
    "Definition": "squint at; sidelong glance",
    "Radical": "目",
    "RadicalIndex": "109.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2612,
    "FrequencyRank": 3969
  },
  {
    "Character": "棪",
    "Pinyin": "yǎn",
    "Definition": "tree",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7331,
    "FrequencyRank": undefined
  },
  {
    "Character": "傥",
    "Pinyin": "tǎng",
    "Definition": "if, supposing, in case",
    "Radical": "人",
    "RadicalIndex": "9.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5212,
    "FrequencyRank": 4829
  },
  {
    "Character": "徨",
    "Pinyin": "huáng",
    "Definition": "doubtful, irresolute, vacillating",
    "Radical": "彳",
    "RadicalIndex": "60.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5217,
    "FrequencyRank": 4133
  },
  {
    "Character": "缈",
    "Pinyin": "miǎo",
    "Definition": "indistinct, dim; minute; distant",
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5333,
    "FrequencyRank": 4549
  },
  {
    "Character": "幄",
    "Pinyin": "wò",
    "Definition": "tent; mosquito net",
    "Radical": "巾",
    "RadicalIndex": "50.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5175,
    "FrequencyRank": 4823
  },
  {
    "Character": "裢",
    "Pinyin": "lián",
    "Definition": "folding purse inserted in belt",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5305,
    "FrequencyRank": 5237
  },
  {
    "Character": "痫",
    "Pinyin": "xián",
    "Definition": "epilepsy, convulsions",
    "Radical": "疒",
    "RadicalIndex": "104.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5258,
    "FrequencyRank": 4019
  },
  {
    "Character": "媄",
    "Pinyin": "měi",
    "Definition": "beautiful",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7453,
    "FrequencyRank": undefined
  },
  {
    "Character": "睄",
    "Pinyin": "shào",
    "Definition": "(Cant.) to glance",
    "Radical": "目",
    "RadicalIndex": "109.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5123,
    "FrequencyRank": 7732
  },
  {
    "Character": "葜",
    "Pinyin": "qiā",
    "Definition": "smilax china",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7301,
    "FrequencyRank": 6009
  },
  {
    "Character": "掣",
    "Pinyin": "chè",
    "Definition": "drag, pull; hinder by pulling back",
    "Radical": "手",
    "RadicalIndex": "64.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5191,
    "FrequencyRank": 3596
  },
  {
    "Character": "溇",
    "Pinyin": "lóu",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7435,
    "FrequencyRank": undefined
  },
  {
    "Character": "雳",
    "Pinyin": "lì",
    "Definition": "thunderclap, crashing thunder",
    "Radical": "雨",
    "RadicalIndex": "173.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2598,
    "FrequencyRank": 3550
  },
  {
    "Character": "𣸣",
    "Pinyin": "fén",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7425,
    "FrequencyRank": undefined
  },
  {
    "Character": "谟",
    "Pinyin": "mó",
    "Definition": "scheme, plan; plan; be without",
    "Radical": "言",
    "RadicalIndex": "149.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5303,
    "FrequencyRank": 3172
  },
  {
    "Character": "缐",
    "Pinyin": "xiàn",
    "Definition": undefined,
    "Radical": "糸",
    "RadicalIndex": "120.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7459,
    "FrequencyRank": undefined
  },
  {
    "Character": "愕",
    "Pinyin": "è",
    "Definition": "startled, alarmed, astonished",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2773,
    "FrequencyRank": 2766
  },
  {
    "Character": "焙",
    "Pinyin": "bèi",
    "Definition": "dry over slow fire; bake; roast",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5274,
    "FrequencyRank": 3955
  },
  {
    "Character": "铹",
    "Pinyin": "láo",
    "Definition": "lawrencium (Lr)",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7374,
    "FrequencyRank": 8592
  },
  {
    "Character": "詈",
    "Pinyin": "lì",
    "Definition": "scold, abuse verbally, curse",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5167,
    "FrequencyRank": 4626
  },
  {
    "Character": "蒌",
    "Pinyin": "lóu",
    "Definition": "artemisia stelleriana",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5090,
    "FrequencyRank": 5887
  },
  {
    "Character": "焞",
    "Pinyin": "tūn",
    "Definition": "dim",
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7422,
    "FrequencyRank": undefined
  },
  {
    "Character": "靸",
    "Pinyin": "sǎ",
    "Definition": undefined,
    "Radical": "革",
    "RadicalIndex": "177.4",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5082,
    "FrequencyRank": 6857
  },
  {
    "Character": "崶",
    "Pinyin": "fēng",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7359,
    "FrequencyRank": undefined
  },
  {
    "Character": "琛",
    "Pinyin": "chēn",
    "Definition": "treasure, valuables",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5055,
    "FrequencyRank": 3360
  },
  {
    "Character": "葺",
    "Pinyin": "qì",
    "Definition": "thatch; fix, repair; pile up",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5084,
    "FrequencyRank": 4975
  },
  {
    "Character": "啼",
    "Pinyin": "tí",
    "Definition": "weep, whimper; howl, twitter",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2645,
    "FrequencyRank": 2913
  },
  {
    "Character": "棓",
    "Pinyin": "bàng",
    "Definition": "hit, strike",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7329,
    "FrequencyRank": undefined
  },
  {
    "Character": "棐",
    "Pinyin": "fěi",
    "Definition": "species of yew",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7345,
    "FrequencyRank": undefined
  },
  {
    "Character": "蒋",
    "Pinyin": "jiǎng",
    "Definition": "surname; hydropyrum latifalium",
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2555,
    "FrequencyRank": 1172
  },
  {
    "Character": "揠",
    "Pinyin": "yà",
    "Definition": "to pull up, to eradicate",
    "Radical": "手",
    "RadicalIndex": "64.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5063,
    "FrequencyRank": 6779
  },
  {
    "Character": "殚",
    "Pinyin": "dān",
    "Definition": "utmost, entirely, quite; use up",
    "Radical": "歹",
    "RadicalIndex": "78.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5113,
    "FrequencyRank": 5170
  },
  {
    "Character": "崾",
    "Pinyin": "yǎo",
    "Definition": "place name in Shanxi province",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7362,
    "FrequencyRank": 5365
  },
  {
    "Character": "跖",
    "Pinyin": "zhí",
    "Definition": "sole (of the foot)",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5135,
    "FrequencyRank": 6119
  },
  {
    "Character": "婷",
    "Pinyin": "tíng",
    "Definition": "pretty; attractive; graceful",
    "Radical": "女",
    "RadicalIndex": "38.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5322,
    "FrequencyRank": 3653
  },
  {
    "Character": "皕",
    "Pinyin": "bì",
    "Definition": undefined,
    "Radical": "白",
    "RadicalIndex": "106.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7339,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫖮",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7367,
    "FrequencyRank": undefined
  },
  {
    "Character": "椓",
    "Pinyin": "zhuó",
    "Definition": "strike, hit, beat, hammer",
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7324,
    "FrequencyRank": undefined
  },
  {
    "Character": "蒎",
    "Pinyin": "pài",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7317,
    "FrequencyRank": undefined
  },
  {
    "Character": "啾",
    "Pinyin": "jiū",
    "Definition": "wailing of child; chirp",
    "Radical": "口",
    "RadicalIndex": "30.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5154,
    "FrequencyRank": 4081
  },
  {
    "Character": "葰",
    "Pinyin": "suī",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7314,
    "FrequencyRank": 7095
  },
  {
    "Character": "跆",
    "Pinyin": "tái",
    "Definition": "trample",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5141,
    "FrequencyRank": 5524
  },
  {
    "Character": "嵝",
    "Pinyin": "lǒu",
    "Definition": "Goulou mountain peak in hunan",
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5173,
    "FrequencyRank": 5814
  },
  {
    "Character": "筅",
    "Pinyin": "xiǎn",
    "Definition": "bamboo brush; halberd",
    "Radical": "竹",
    "RadicalIndex": "118.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7391,
    "FrequencyRank": 7342
  },
  {
    "Character": "鄌",
    "Pinyin": "táng",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7417,
    "FrequencyRank": undefined
  },
  {
    "Character": "粢",
    "Pinyin": "zī",
    "Definition": "grain offered in ritual sacrifice; millet",
    "Radical": "米",
    "RadicalIndex": "119.6",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7418,
    "FrequencyRank": 5749
  },
  {
    "Character": "𫖯",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 7399,
    "FrequencyRank": undefined
  },
  {
    "Character": "畲",
    "Pinyin": "shē",
    "Definition": "to cultivate land by first setting fire to it",
    "Radical": "田",
    "RadicalIndex": "102.7",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5219,
    "FrequencyRank": 5102
  },
  {
    "Character": "琫",
    "Pinyin": "běng",
    "Definition": "ornament",
    "Radical": "玉",
    "RadicalIndex": "96.8",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5042,
    "FrequencyRank": undefined
  },
  {
    "Character": "嵬",
    "Pinyin": "wéi",
    "Definition": "high; rugged, rocky, precipitous",
    "Radical": "山",
    "RadicalIndex": "46.1",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 5170,
    "FrequencyRank": 5510
  },
  {
    "Character": "彭",
    "Pinyin": "péng",
    "Definition": "name of ancient country; surname",
    "Radical": "彡",
    "RadicalIndex": "59.9",
    "StrokeCount": 12,
    "HSK": undefined,
    "GeneralStandard": 2524,
    "FrequencyRank": 1501
  },
  {
    "Character": "滃",
    "Pinyin": "wēng",
    "Definition": "swelling, rising, dispersing",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5581,
    "FrequencyRank": undefined
  },
  {
    "Character": "嗫",
    "Pinyin": "niè",
    "Definition": "move lips as when speaking; hesitation",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5442,
    "FrequencyRank": 4125
  },
  {
    "Character": "酮",
    "Pinyin": "tóng",
    "Definition": "ketones",
    "Radical": "酉",
    "RadicalIndex": "164.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5412,
    "FrequencyRank": 3905
  },
  {
    "Character": "腼",
    "Pinyin": "miǎn",
    "Definition": "modest",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5519,
    "FrequencyRank": 4178
  },
  {
    "Character": "犏",
    "Pinyin": "piān",
    "Definition": "yak-ox",
    "Radical": "牛",
    "RadicalIndex": "93.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5495,
    "FrequencyRank": 8247
  },
  {
    "Character": "嗪",
    "Pinyin": "qín",
    "Definition": "character used in translation",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5432,
    "FrequencyRank": 4742
  },
  {
    "Character": "鲆",
    "Pinyin": "píng",
    "Definition": "sole",
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5526,
    "FrequencyRank": 5028
  },
  {
    "Character": "艅",
    "Pinyin": "yú",
    "Definition": "a despatch boat",
    "Radical": "舟",
    "RadicalIndex": "137.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7550,
    "FrequencyRank": undefined
  },
  {
    "Character": "谪",
    "Pinyin": "zhé",
    "Definition": "charge, blame; disgrace; demote",
    "Radical": "言",
    "RadicalIndex": "149.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5603,
    "FrequencyRank": 5115
  },
  {
    "Character": "榇",
    "Pinyin": "chèn",
    "Definition": "coffin; tung tree",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7499,
    "FrequencyRank": 5532
  },
  {
    "Character": "暇",
    "Pinyin": "xiá",
    "Definition": "leisure, relaxation, spare time",
    "Radical": "日",
    "RadicalIndex": "72.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2910,
    "FrequencyRank": 2761
  },
  {
    "Character": "歃",
    "Pinyin": "shà",
    "Definition": undefined,
    "Radical": "欠",
    "RadicalIndex": "76.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5496,
    "FrequencyRank": 6211
  },
  {
    "Character": "蜕",
    "Pinyin": "tuì",
    "Definition": "molt",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2926,
    "FrequencyRank": 3624
  },
  {
    "Character": "蓟",
    "Pinyin": "jì",
    "Definition": "circium, thistles; surname",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5380,
    "FrequencyRank": 4290
  },
  {
    "Character": "椹",
    "Pinyin": "shèn",
    "Definition": "a chopping board",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7492,
    "FrequencyRank": 6182
  },
  {
    "Character": "谩",
    "Pinyin": "mán",
    "Definition": "deceive, insult",
    "Radical": "言",
    "RadicalIndex": "149.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5602,
    "FrequencyRank": 3924
  },
  {
    "Character": "榄",
    "Pinyin": "lǎn",
    "Definition": "olive",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2866,
    "FrequencyRank": 3607
  },
  {
    "Character": "羧",
    "Pinyin": "suō",
    "Definition": "carboxyl group",
    "Radical": "羊",
    "RadicalIndex": "123.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5555,
    "FrequencyRank": 5676
  },
  {
    "Character": "搦",
    "Pinyin": "nuò",
    "Definition": "grasp, seize, take in hand",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5365,
    "FrequencyRank": 6562
  },
  {
    "Character": "遘",
    "Pinyin": "gòu",
    "Definition": "to meet; to come across",
    "Radical": "辵",
    "RadicalIndex": "162.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7475,
    "FrequencyRank": 7177
  },
  {
    "Character": "碉",
    "Pinyin": "diāo",
    "Definition": "room made of stone; watchtower",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5420,
    "FrequencyRank": 3659
  },
  {
    "Character": "慆",
    "Pinyin": "tāo",
    "Definition": "excessive, dissolute; delighted",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7600,
    "FrequencyRank": undefined
  },
  {
    "Character": "溺",
    "Pinyin": "nì",
    "Definition": "drown; submerge in water; indulge",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 3020,
    "FrequencyRank": 3291
  },
  {
    "Character": "蒨",
    "Pinyin": "qiàn",
    "Definition": "lush vegetation, luxuriant growth",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7485,
    "FrequencyRank": 6795
  },
  {
    "Character": "蒱",
    "Pinyin": "pú",
    "Definition": "gambling game with dice",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7484,
    "FrequencyRank": undefined
  },
  {
    "Character": "裾",
    "Pinyin": "jū",
    "Definition": "lapel, border of garment; skirt",
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5599,
    "FrequencyRank": 5428
  },
  {
    "Character": "禘",
    "Pinyin": "dì",
    "Definition": "imperial ancestor worship",
    "Radical": "示",
    "RadicalIndex": "113.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7606,
    "FrequencyRank": 7366
  },
  {
    "Character": "腨",
    "Pinyin": "shuàn",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7555,
    "FrequencyRank": 8884
  },
  {
    "Character": "筢",
    "Pinyin": "pá",
    "Definition": "rake",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5501,
    "FrequencyRank": 7320
  },
  {
    "Character": "粳",
    "Pinyin": "jīng",
    "Definition": "non-glutinous rice",
    "Radical": "米",
    "RadicalIndex": "119.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5557,
    "FrequencyRank": 5458
  },
  {
    "Character": "禀",
    "Pinyin": "bǐng",
    "Definition": "report to, petition",
    "Radical": "示",
    "RadicalIndex": "113.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2987,
    "FrequencyRank": 2945
  },
  {
    "Character": "跶",
    "Pinyin": "tà",
    "Definition": "stumble, slip",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5453,
    "FrequencyRank": 6059
  },
  {
    "Character": "塝",
    "Pinyin": "bàng",
    "Definition": "flat bank, plateau",
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7481,
    "FrequencyRank": undefined
  },
  {
    "Character": "楯",
    "Pinyin": "dùn",
    "Definition": "shield; horizontal bar",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5400,
    "FrequencyRank": undefined
  },
  {
    "Character": "锧",
    "Pinyin": "zhì",
    "Definition": "tungsten, wolfram",
    "Radical": "金",
    "RadicalIndex": "167.15",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7532,
    "FrequencyRank": 9314
  },
  {
    "Character": "趑",
    "Pinyin": "zī",
    "Definition": undefined,
    "Radical": "走",
    "RadicalIndex": "156.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5353,
    "FrequencyRank": 6413
  },
  {
    "Character": "鲈",
    "Pinyin": "lú",
    "Definition": "sea perch, sea bass",
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5528,
    "FrequencyRank": 4317
  },
  {
    "Character": "訾",
    "Pinyin": "zī",
    "Definition": "bad-mouth; criticize; defects",
    "Radical": "言",
    "RadicalIndex": "149.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5428,
    "FrequencyRank": 6132
  },
  {
    "Character": "蓓",
    "Pinyin": "bèi",
    "Definition": "bud",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5376,
    "FrequencyRank": 3922
  },
  {
    "Character": "裨",
    "Pinyin": "bì",
    "Definition": "aid, benefit, help; supplement",
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5598,
    "FrequencyRank": 4230
  },
  {
    "Character": "鲋",
    "Pinyin": "fù",
    "Definition": "carp, carassicus auratus",
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5530,
    "FrequencyRank": 5408
  },
  {
    "Character": "䥽",
    "Pinyin": "pō",
    "Definition": "(simplified form of 鏺) farm tool; blade in both side with a long handle used to cut weeds, to exterminate; to settle disorders",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 4546,
    "FrequencyRank": undefined
  },
  {
    "Character": "觟",
    "Pinyin": "huà",
    "Definition": undefined,
    "Radical": "角",
    "RadicalIndex": "148.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7567,
    "FrequencyRank": undefined
  },
  {
    "Character": "鄢",
    "Pinyin": "yān",
    "Definition": "name of a district in Honan",
    "Radical": "邑",
    "RadicalIndex": "163.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5351,
    "FrequencyRank": 5266
  },
  {
    "Character": "阘",
    "Pinyin": "dá",
    "Definition": "upper-story door or window",
    "Radical": "門",
    "RadicalIndex": "169.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7579,
    "FrequencyRank": 7178
  },
  {
    "Character": "锰",
    "Pinyin": "měng",
    "Definition": "manganese",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2945,
    "FrequencyRank": 4204
  },
  {
    "Character": "貉",
    "Pinyin": "háo",
    "Definition": "badger; raccoon dog",
    "Radical": "豸",
    "RadicalIndex": "153.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5515,
    "FrequencyRank": 5468
  },
  {
    "Character": "鹍",
    "Pinyin": "kūn",
    "Definition": "a bird resembling the crane",
    "Radical": "鳥",
    "RadicalIndex": "196.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7518,
    "FrequencyRank": 8004
  },
  {
    "Character": "蒲",
    "Pinyin": "pú",
    "Definition": "type of rush; vine",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2857,
    "FrequencyRank": 2344
  },
  {
    "Character": "馏",
    "Pinyin": "liú",
    "Definition": "distill, distillation",
    "Radical": "食",
    "RadicalIndex": "184.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2985,
    "FrequencyRank": 4700
  },
  {
    "Character": "腩",
    "Pinyin": "nǎn",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5518,
    "FrequencyRank": 6979
  },
  {
    "Character": "煊",
    "Pinyin": "xuān",
    "Definition": "warm",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5563,
    "FrequencyRank": 4185
  },
  {
    "Character": "𫘬",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7618,
    "FrequencyRank": undefined
  },
  {
    "Character": "鹔",
    "Pinyin": "sù",
    "Definition": "turquoise kingfisher",
    "Radical": "鳥",
    "RadicalIndex": "196.12",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7609,
    "FrequencyRank": undefined
  },
  {
    "Character": "瑳",
    "Pinyin": "cuō",
    "Definition": "luster of gem; lustrous, bright",
    "Radical": "玉",
    "RadicalIndex": "96.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7471,
    "FrequencyRank": undefined
  },
  {
    "Character": "裼",
    "Pinyin": "tì",
    "Definition": undefined,
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7603,
    "FrequencyRank": 5743
  },
  {
    "Character": "𫓹",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7529,
    "FrequencyRank": undefined
  },
  {
    "Character": "缟",
    "Pinyin": "gǎo",
    "Definition": "white raw silk",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5615,
    "FrequencyRank": 5286
  },
  {
    "Character": "䅟",
    "Pinyin": "cǎn",
    "Definition": "(simplified form of 穇) varieties of millet; panicled millet, ear of grain producing no fruit, short grains",
    "Radical": "禾",
    "RadicalIndex": "115.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7540,
    "FrequencyRank": undefined
  },
  {
    "Character": "麂",
    "Pinyin": "jǐ",
    "Definition": "species of deer",
    "Radical": "鹿",
    "RadicalIndex": "198.2",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5547,
    "FrequencyRank": 4713
  },
  {
    "Character": "溥",
    "Pinyin": "pǔ",
    "Definition": "big, great, vast, wide; widespread",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5571,
    "FrequencyRank": 3882
  },
  {
    "Character": "颐",
    "Pinyin": "yí",
    "Definition": "cheeks; jaw; chin; rear; to nourish",
    "Radical": "頁",
    "RadicalIndex": "181.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5391,
    "FrequencyRank": 2999
  },
  {
    "Character": "跱",
    "Pinyin": "zhì",
    "Definition": "stop",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7522,
    "FrequencyRank": 8487
  },
  {
    "Character": "锳",
    "Pinyin": "yīng",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7531,
    "FrequencyRank": 7957
  },
  {
    "Character": "愭",
    "Pinyin": "qí",
    "Definition": undefined,
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7598,
    "FrequencyRank": undefined
  },
  {
    "Character": "畹",
    "Pinyin": "wǎn",
    "Definition": undefined,
    "Radical": "田",
    "RadicalIndex": "102.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5464,
    "FrequencyRank": 4207
  },
  {
    "Character": "瑕",
    "Pinyin": "xiá",
    "Definition": "flaw in gem; fault, defect",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5345,
    "FrequencyRank": 3842
  },
  {
    "Character": "阖",
    "Pinyin": "hé",
    "Definition": "close; whole, entire; all; leaf",
    "Radical": "門",
    "RadicalIndex": "169.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5552,
    "FrequencyRank": 4228
  },
  {
    "Character": "塬",
    "Pinyin": "yuán",
    "Definition": "plateau",
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5350,
    "FrequencyRank": 5875
  },
  {
    "Character": "楩",
    "Pinyin": "pián",
    "Definition": "tree",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7498,
    "FrequencyRank": undefined
  },
  {
    "Character": "锪",
    "Pinyin": "huō",
    "Definition": "a kind of tool",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7533,
    "FrequencyRank": 6097
  },
  {
    "Character": "痼",
    "Pinyin": "gù",
    "Definition": "chronic disease",
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5542,
    "FrequencyRank": 5399
  },
  {
    "Character": "瑃",
    "Pinyin": "chūn",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7461,
    "FrequencyRank": undefined
  },
  {
    "Character": "鄘",
    "Pinyin": "yōng",
    "Definition": "state in Henan province",
    "Radical": "邑",
    "RadicalIndex": "163.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7574,
    "FrequencyRank": 7768
  },
  {
    "Character": "窠",
    "Pinyin": "kē",
    "Definition": "nest; hole, indention; den",
    "Radical": "穴",
    "RadicalIndex": "116.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5594,
    "FrequencyRank": 4118
  },
  {
    "Character": "滍",
    "Pinyin": "zhì",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7597,
    "FrequencyRank": undefined
  },
  {
    "Character": "锖",
    "Pinyin": "qiāng",
    "Definition": "the color of the a mineral",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7528,
    "FrequencyRank": 6146
  },
  {
    "Character": "毹",
    "Pinyin": "shū",
    "Definition": undefined,
    "Radical": "毛",
    "RadicalIndex": "82.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5512,
    "FrequencyRank": 7996
  },
  {
    "Character": "粲",
    "Pinyin": "càn",
    "Definition": "polish; bright, radiant; smiling",
    "Radical": "米",
    "RadicalIndex": "119.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5429,
    "FrequencyRank": 4810
  },
  {
    "Character": "慥",
    "Pinyin": "zào",
    "Definition": "sincere, earnest",
    "Radical": "心",
    "RadicalIndex": "61.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7599,
    "FrequencyRank": undefined
  },
  {
    "Character": "榉",
    "Pinyin": "jǔ",
    "Definition": "type of elm",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5404,
    "FrequencyRank": 4617
  },
  {
    "Character": "睚",
    "Pinyin": "yá",
    "Definition": "corner of eye; stare",
    "Radical": "目",
    "RadicalIndex": "109.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5431,
    "FrequencyRank": 6152
  },
  {
    "Character": "耢",
    "Pinyin": "lào",
    "Definition": "a kind of farm tool",
    "Radical": "耒",
    "RadicalIndex": "127.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5339,
    "FrequencyRank": 7749
  },
  {
    "Character": "旒",
    "Pinyin": "liú",
    "Definition": "fringes of pearls on crowns",
    "Radical": "方",
    "RadicalIndex": "70.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5550,
    "FrequencyRank": 6046
  },
  {
    "Character": "楸",
    "Pinyin": "qiū",
    "Definition": "mallotus japonicus, deciduous tre",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5397,
    "FrequencyRank": 5691
  },
  {
    "Character": "蓂",
    "Pinyin": "míng",
    "Definition": "lucky place",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7489,
    "FrequencyRank": 8044
  },
  {
    "Character": "锱",
    "Pinyin": "zī",
    "Definition": "8 oz; an ancient unit of weight",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5492,
    "FrequencyRank": 5967
  },
  {
    "Character": "溟",
    "Pinyin": "míng",
    "Definition": "drizzling rain; dark, obscure",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5586,
    "FrequencyRank": 4234
  },
  {
    "Character": "歅",
    "Pinyin": "yīn",
    "Definition": undefined,
    "Radical": "欠",
    "RadicalIndex": "76.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7502,
    "FrequencyRank": undefined
  },
  {
    "Character": "蓏",
    "Pinyin": "luǒ",
    "Definition": "fruit of plant; melon",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7486,
    "FrequencyRank": 7594
  },
  {
    "Character": "禒",
    "Pinyin": "xiǎn",
    "Definition": undefined,
    "Radical": "示",
    "RadicalIndex": "113.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7607,
    "FrequencyRank": undefined
  },
  {
    "Character": "嫄",
    "Pinyin": "yuán",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7612,
    "FrequencyRank": undefined
  },
  {
    "Character": "鹑",
    "Pinyin": "chún",
    "Definition": "quail; Turnix species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5538,
    "FrequencyRank": 4348
  },
  {
    "Character": "觜",
    "Pinyin": "zī",
    "Definition": "beak",
    "Radical": "角",
    "RadicalIndex": "148.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7515,
    "FrequencyRank": 7627
  },
  {
    "Character": "稗",
    "Pinyin": "bài",
    "Definition": "darnels, weeds, tares small",
    "Radical": "禾",
    "RadicalIndex": "115.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5498,
    "FrequencyRank": 4912
  },
  {
    "Character": "飕",
    "Pinyin": "sōu",
    "Definition": "sound of wind; blow chilly",
    "Radical": "風",
    "RadicalIndex": "182.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5534,
    "FrequencyRank": 4266
  },
  {
    "Character": "滉",
    "Pinyin": "huàng",
    "Definition": "deep",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7591,
    "FrequencyRank": undefined
  },
  {
    "Character": "彀",
    "Pinyin": "gòu",
    "Definition": "enough, adequate fully, quite",
    "Radical": "弓",
    "RadicalIndex": "57.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5363,
    "FrequencyRank": 5457
  },
  {
    "Character": "貆",
    "Pinyin": "huán",
    "Definition": "small badger; porcupine",
    "Radical": "豸",
    "RadicalIndex": "153.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7553,
    "FrequencyRank": undefined
  },
  {
    "Character": "跣",
    "Pinyin": "xiǎn",
    "Definition": "bare footed",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5456,
    "FrequencyRank": 5704
  },
  {
    "Character": "貅",
    "Pinyin": "xiū",
    "Definition": "brave, fierce, courageous",
    "Radical": "豸",
    "RadicalIndex": "153.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5514,
    "FrequencyRank": 6673
  },
  {
    "Character": "睢",
    "Pinyin": "suī",
    "Definition": "gaze at, stare at; uninhibited",
    "Radical": "目",
    "RadicalIndex": "109.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5437,
    "FrequencyRank": 4326
  },
  {
    "Character": "𫔶",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7580,
    "FrequencyRank": undefined
  },
  {
    "Character": "蓥",
    "Pinyin": "yíng",
    "Definition": "polish",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5390,
    "FrequencyRank": 6756
  },
  {
    "Character": "缡",
    "Pinyin": "lí",
    "Definition": "a bridal veil; to tie; to bind",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5616,
    "FrequencyRank": 5913
  },
  {
    "Character": "骞",
    "Pinyin": "qiān",
    "Definition": "raise, hold high; fly, soar",
    "Radical": "馬",
    "RadicalIndex": "187.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5592,
    "FrequencyRank": 4815
  },
  {
    "Character": "摁",
    "Pinyin": "èn",
    "Definition": "to press with the hand",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5355,
    "FrequencyRank": 4705
  },
  {
    "Character": "愆",
    "Pinyin": "qiān",
    "Definition": "a fault, mistake, error, transgression",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5509,
    "FrequencyRank": 5208
  },
  {
    "Character": "鹎",
    "Pinyin": "bēi",
    "Definition": "bird",
    "Radical": "鳥",
    "RadicalIndex": "196.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7548,
    "FrequencyRank": 6419
  },
  {
    "Character": "辒",
    "Pinyin": "wēn",
    "Definition": "hearse",
    "Radical": "車",
    "RadicalIndex": "159.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7511,
    "FrequencyRank": 7766
  },
  {
    "Character": "䓖",
    "Pinyin": "qióng",
    "Definition": "(simplified form of 藭) Cnidium officinale, a kind of medicinal herb",
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 6955,
    "FrequencyRank": 9764
  },
  {
    "Character": "肄",
    "Pinyin": "yì",
    "Definition": "learn, practice, study; toil",
    "Radical": "聿",
    "RadicalIndex": "129.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5532,
    "FrequencyRank": 5563
  },
  {
    "Character": "瑚",
    "Pinyin": "hú",
    "Definition": "coral; person of virtue",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5340,
    "FrequencyRank": 3140
  },
  {
    "Character": "跻",
    "Pinyin": "jī",
    "Definition": "ascend, go up, rise",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5458,
    "FrequencyRank": 4014
  },
  {
    "Character": "滓",
    "Pinyin": "zǐ",
    "Definition": "sediment, lees, dregs",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5585,
    "FrequencyRank": 4522
  },
  {
    "Character": "溹",
    "Pinyin": "suò",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7589,
    "FrequencyRank": undefined
  },
  {
    "Character": "嵊",
    "Pinyin": "shèng",
    "Definition": "district in Shaohsing, Chekiang",
    "Radical": "山",
    "RadicalIndex": "46.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5478,
    "FrequencyRank": 5236
  },
  {
    "Character": "嗳",
    "Pinyin": "āi",
    "Definition": "interjection; exclamation",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5470,
    "FrequencyRank": 3837
  },
  {
    "Character": "蓁",
    "Pinyin": "zhēn",
    "Definition": "abundant, luxuriant vegetation",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5367,
    "FrequencyRank": 6630
  },
  {
    "Character": "锭",
    "Pinyin": "dìng",
    "Definition": "spindle, slab, cake, tablet",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5491,
    "FrequencyRank": 4199
  },
  {
    "Character": "筼",
    "Pinyin": "yún",
    "Definition": "tall bamboo",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7543,
    "FrequencyRank": 7752
  },
  {
    "Character": "滢",
    "Pinyin": "yíng",
    "Definition": "clear, pure water; lucid; glossy",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5570,
    "FrequencyRank": 4634
  },
  {
    "Character": "楙",
    "Pinyin": "mào",
    "Definition": "name of plant; lush",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7501,
    "FrequencyRank": undefined
  },
  {
    "Character": "溏",
    "Pinyin": "táng",
    "Definition": "pool; not hardened, semi-soft",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5583,
    "FrequencyRank": 6068
  },
  {
    "Character": "漓",
    "Pinyin": "lí",
    "Definition": "river in Guangxi province; water dripping",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 3014,
    "FrequencyRank": 3413
  },
  {
    "Character": "蜉",
    "Pinyin": "fú",
    "Definition": "mayfly; kind of large insect",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5462,
    "FrequencyRank": 5707
  },
  {
    "Character": "嫫",
    "Pinyin": "mó",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5605,
    "FrequencyRank": 6350
  },
  {
    "Character": "缣",
    "Pinyin": "jiān",
    "Definition": "fine silk",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5618,
    "FrequencyRank": 5876
  },
  {
    "Character": "搡",
    "Pinyin": "sǎng",
    "Definition": "to push over or push back",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5366,
    "FrequencyRank": 4686
  },
  {
    "Character": "煦",
    "Pinyin": "xù",
    "Definition": "kind, gentle, gracious, genial",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5448,
    "FrequencyRank": 4124
  },
  {
    "Character": "筻",
    "Pinyin": "gàng",
    "Definition": "place name in Hunan province",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7542,
    "FrequencyRank": 8375
  },
  {
    "Character": "瑓",
    "Pinyin": "liàn",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7462,
    "FrequencyRank": undefined
  },
  {
    "Character": "滇",
    "Pinyin": "diān",
    "Definition": "Yunnan province",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 3007,
    "FrequencyRank": 3534
  },
  {
    "Character": "煞",
    "Pinyin": "shā",
    "Definition": "malignant deity; baleful, noxious; strike dead",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2982,
    "FrequencyRank": 2280
  },
  {
    "Character": "榅",
    "Pinyin": "wēn",
    "Definition": "pillar, cryptomeria",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7495,
    "FrequencyRank": undefined
  },
  {
    "Character": "罨",
    "Pinyin": "yǎn",
    "Definition": "medical compress; fish net",
    "Radical": "网",
    "RadicalIndex": "122.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5477,
    "FrequencyRank": 6156
  },
  {
    "Character": "嗔",
    "Pinyin": "chēn",
    "Definition": "be angry at, scold, rebuke",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5444,
    "FrequencyRank": 3546
  },
  {
    "Character": "𫘪",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7617,
    "FrequencyRank": undefined
  },
  {
    "Character": "槌",
    "Pinyin": "chuí",
    "Definition": "hammer, mallet; strike, beat",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5399,
    "FrequencyRank": 4105
  },
  {
    "Character": "缢",
    "Pinyin": "yì",
    "Definition": "hang, strangle",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5617,
    "FrequencyRank": 4965
  },
  {
    "Character": "雊",
    "Pinyin": "gòu",
    "Definition": "the crow of a male pheasant",
    "Radical": "隹",
    "RadicalIndex": "172.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7564,
    "FrequencyRank": 8368
  },
  {
    "Character": "溽",
    "Pinyin": "rù",
    "Definition": "moist, humid, muggy",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5573,
    "FrequencyRank": 6510
  },
  {
    "Character": "楪",
    "Pinyin": "yè",
    "Definition": "small dish; window",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7493,
    "FrequencyRank": undefined
  },
  {
    "Character": "嫔",
    "Pinyin": "pín",
    "Definition": "court lady; palace maid",
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5608,
    "FrequencyRank": 3995
  },
  {
    "Character": "瑖",
    "Pinyin": "duàn",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7466,
    "FrequencyRank": undefined
  },
  {
    "Character": "蒟",
    "Pinyin": "jǔ",
    "Definition": "betel pepper; Amorphophallus konjac",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5385,
    "FrequencyRank": 8535
  },
  {
    "Character": "阗",
    "Pinyin": "tián",
    "Definition": "a place in Xinjiang province",
    "Radical": "門",
    "RadicalIndex": "169.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5553,
    "FrequencyRank": 5190
  },
  {
    "Character": "滪",
    "Pinyin": "yù",
    "Definition": "place in Sichuan",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5587,
    "FrequencyRank": undefined
  },
  {
    "Character": "褚",
    "Pinyin": "chǔ",
    "Definition": "bag, valise; stuff, pad; surname",
    "Radical": "衣",
    "RadicalIndex": "145.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5597,
    "FrequencyRank": 3826
  },
  {
    "Character": "龆",
    "Pinyin": "tiáo",
    "Definition": "lose baby teeth and get adult teeth",
    "Radical": "齒",
    "RadicalIndex": "211.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7514,
    "FrequencyRank": 5134
  },
  {
    "Character": "谫",
    "Pinyin": "jiǎn",
    "Definition": "shallow; stupid",
    "Radical": "言",
    "RadicalIndex": "149.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7608,
    "FrequencyRank": 6760
  },
  {
    "Character": "瑂",
    "Pinyin": "méi",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7472,
    "FrequencyRank": undefined
  },
  {
    "Character": "瑑",
    "Pinyin": "zhuàn",
    "Definition": "carve, engrave, cut out, sculpt",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7474,
    "FrequencyRank": undefined
  },
  {
    "Character": "鲉",
    "Pinyin": "yóu",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7557,
    "FrequencyRank": 6882
  },
  {
    "Character": "塥",
    "Pinyin": "gé",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7477,
    "FrequencyRank": 8676
  },
  {
    "Character": "瑄",
    "Pinyin": "xuān",
    "Definition": "a ornamental piece of jade several inches across with a hole in the center",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5344,
    "FrequencyRank": undefined
  },
  {
    "Character": "蒺",
    "Pinyin": "jí",
    "Definition": "furze; gorse",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5383,
    "FrequencyRank": 5311
  },
  {
    "Character": "鲇",
    "Pinyin": "nián",
    "Definition": "sheatfish, parasilurus asotus",
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5527,
    "FrequencyRank": 4154
  },
  {
    "Character": "觎",
    "Pinyin": "yú",
    "Definition": "desire strongly, covet, long for",
    "Radical": "見",
    "RadicalIndex": "147.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5511,
    "FrequencyRank": 4520
  },
  {
    "Character": "筠",
    "Pinyin": "yún",
    "Definition": "bamboo skin; bamboo",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5500,
    "FrequencyRank": 4581
  },
  {
    "Character": "蜐",
    "Pinyin": "jié",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7523,
    "FrequencyRank": 8538
  },
  {
    "Character": "锫",
    "Pinyin": "péi",
    "Definition": "berkelium",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7535,
    "FrequencyRank": 6266
  },
  {
    "Character": "嗝",
    "Pinyin": "gé",
    "Definition": "cackling of fowls to gag, to vomit",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5445,
    "FrequencyRank": 4610
  },
  {
    "Character": "跷",
    "Pinyin": "qiāo",
    "Definition": undefined,
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2914,
    "FrequencyRank": 3720
  },
  {
    "Character": "煳",
    "Pinyin": "hú",
    "Definition": "be burned, to char (in cooking)",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5559,
    "FrequencyRank": 5818
  },
  {
    "Character": "戣",
    "Pinyin": "kuí",
    "Definition": "lance",
    "Radical": "戈",
    "RadicalIndex": "62.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7616,
    "FrequencyRank": undefined
  },
  {
    "Character": "嗬",
    "Pinyin": "hē",
    "Definition": "(Cant.) interrogative particle",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5443,
    "FrequencyRank": 4141
  },
  {
    "Character": "猺",
    "Pinyin": "yáo",
    "Definition": "jackal; name of a tribe",
    "Radical": "犬",
    "RadicalIndex": "94.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7565,
    "FrequencyRank": undefined
  },
  {
    "Character": "缜",
    "Pinyin": "zhěn",
    "Definition": "detailed, fine; closely woven",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5611,
    "FrequencyRank": 4383
  },
  {
    "Character": "氲",
    "Pinyin": "yūn",
    "Definition": "life giving influences of nature; spirit of harmony; prosperity",
    "Radical": "气",
    "RadicalIndex": "84.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5494,
    "FrequencyRank": 4298
  },
  {
    "Character": "嗡",
    "Pinyin": "wēng",
    "Definition": "sound of flying bees, airplanes",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2928,
    "FrequencyRank": 2783
  },
  {
    "Character": "蓢",
    "Pinyin": "lǎng",
    "Definition": "(Cant.) brake, fern",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7488,
    "FrequencyRank": undefined
  },
  {
    "Character": "辔",
    "Pinyin": "pèi",
    "Definition": "bridle of horse, reins",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5613,
    "FrequencyRank": 4619
  },
  {
    "Character": "痿",
    "Pinyin": "wěi",
    "Definition": "paralysis; impotence",
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5543,
    "FrequencyRank": 4653
  },
  {
    "Character": "嗷",
    "Pinyin": "áo",
    "Definition": "loud clamor; sound of wailing",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5434,
    "FrequencyRank": 4104
  },
  {
    "Character": "稣",
    "Pinyin": "sū",
    "Definition": "revive, to rise again; collect",
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5529,
    "FrequencyRank": 2292
  },
  {
    "Character": "㬊",
    "Pinyin": "huàn",
    "Definition": "light; bright, clear, intelligent; clever",
    "Radical": "日",
    "RadicalIndex": "72.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7520,
    "FrequencyRank": undefined
  },
  {
    "Character": "嗐",
    "Pinyin": "hài",
    "Definition": "alas!",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5474,
    "FrequencyRank": undefined
  },
  {
    "Character": "榈",
    "Pinyin": "lǘ",
    "Definition": "palm",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5402,
    "FrequencyRank": 3971
  },
  {
    "Character": "硿",
    "Pinyin": "kōng",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7509,
    "FrequencyRank": undefined
  },
  {
    "Character": "煺",
    "Pinyin": "tuì",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5565,
    "FrequencyRank": 6025
  },
  {
    "Character": "锬",
    "Pinyin": "tán",
    "Definition": "long spear",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7536,
    "FrequencyRank": 6189
  },
  {
    "Character": "滏",
    "Pinyin": "fǔ",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5580,
    "FrequencyRank": 6902
  },
  {
    "Character": "瑔",
    "Pinyin": "quán",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7468,
    "FrequencyRank": undefined
  },
  {
    "Character": "锜",
    "Pinyin": "qí",
    "Definition": "a kind of pen; a kind of chisel",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5484,
    "FrequencyRank": 5922
  },
  {
    "Character": "搪",
    "Pinyin": "táng",
    "Definition": "ward off, evade; parry; block",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5358,
    "FrequencyRank": 4057
  },
  {
    "Character": "筦",
    "Pinyin": "guǎn",
    "Definition": "a key; to be in charge; a pipe",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7545,
    "FrequencyRank": 7428
  },
  {
    "Character": "瑜",
    "Pinyin": "yú",
    "Definition": "flawless gem or jewel",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5342,
    "FrequencyRank": 2875
  },
  {
    "Character": "蒽",
    "Pinyin": "ēn",
    "Definition": "anthracene",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5375,
    "FrequencyRank": 6909
  },
  {
    "Character": "稙",
    "Pinyin": "zhī",
    "Definition": "grain ready for grinding",
    "Radical": "禾",
    "RadicalIndex": "115.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7539,
    "FrequencyRank": 9421
  },
  {
    "Character": "腧",
    "Pinyin": "shù",
    "Definition": "insertion point in acupuncture; acupoint",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5521,
    "FrequencyRank": 6299
  },
  {
    "Character": "禔",
    "Pinyin": "zhī",
    "Definition": "happiness; peace; good fortune",
    "Radical": "示",
    "RadicalIndex": "113.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7605,
    "FrequencyRank": 9028
  },
  {
    "Character": "馍",
    "Pinyin": "mó",
    "Definition": "bread",
    "Radical": "食",
    "RadicalIndex": "184.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2984,
    "FrequencyRank": 4070
  },
  {
    "Character": "瑆",
    "Pinyin": "xīng",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7464,
    "FrequencyRank": undefined
  },
  {
    "Character": "嗲",
    "Pinyin": "diǎ",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5469,
    "FrequencyRank": 4711
  },
  {
    "Character": "戡",
    "Pinyin": "kān",
    "Definition": "subjugate, subdue, quell; kill",
    "Radical": "戈",
    "RadicalIndex": "62.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5368,
    "FrequencyRank": 4817
  },
  {
    "Character": "骱",
    "Pinyin": "jiè",
    "Definition": undefined,
    "Radical": "骨",
    "RadicalIndex": "188.4",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7527,
    "FrequencyRank": 5665
  },
  {
    "Character": "鹐",
    "Pinyin": "qiān",
    "Definition": "to peck, as birds",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5533,
    "FrequencyRank": 8280
  },
  {
    "Character": "锛",
    "Pinyin": "bēn",
    "Definition": "adze",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5483,
    "FrequencyRank": 5806
  },
  {
    "Character": "嗄",
    "Pinyin": "á",
    "Definition": "hoarse of voice",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5447,
    "FrequencyRank": 5029
  },
  {
    "Character": "瑙",
    "Pinyin": "nǎo",
    "Definition": "agate; cornelian",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2829,
    "FrequencyRank": 3428
  },
  {
    "Character": "馌",
    "Pinyin": "yè",
    "Definition": "carry meal to workers in field",
    "Radical": "食",
    "RadicalIndex": "184.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7569,
    "FrequencyRank": undefined
  },
  {
    "Character": "媸",
    "Pinyin": "chī",
    "Definition": "an ugly woman",
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5609,
    "FrequencyRank": 4980
  },
  {
    "Character": "龃",
    "Pinyin": "jǔ",
    "Definition": "irregular teeth; discord",
    "Radical": "齒",
    "RadicalIndex": "211.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5426,
    "FrequencyRank": 5018
  },
  {
    "Character": "塍",
    "Pinyin": "chéng",
    "Definition": "a raised path between fields, a dike",
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5522,
    "FrequencyRank": 6584
  },
  {
    "Character": "楔",
    "Pinyin": "xiē",
    "Definition": "wedge; gatepost; foreword",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5392,
    "FrequencyRank": 3846
  },
  {
    "Character": "甄",
    "Pinyin": "zhēn",
    "Definition": "to examine, discern; to grade; a surname",
    "Radical": "瓦",
    "RadicalIndex": "98.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5411,
    "FrequencyRank": 3933
  },
  {
    "Character": "蓦",
    "Pinyin": "mò",
    "Definition": "suddenly, quickly, abruptly",
    "Radical": "馬",
    "RadicalIndex": "187.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5373,
    "FrequencyRank": 3160
  },
  {
    "Character": "雎",
    "Pinyin": "jū",
    "Definition": "osprey, fishhawk; hold back",
    "Radical": "隹",
    "RadicalIndex": "172.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5438,
    "FrequencyRank": 5407
  },
  {
    "Character": "𫌀",
    "Pinyin": "jī",
    "Definition": "a pleat, fold, tuck, crease",
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7602,
    "FrequencyRank": undefined
  },
  {
    "Character": "蓉",
    "Pinyin": "róng",
    "Definition": "hibiscus; Chengdu, Sichuan",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2858,
    "FrequencyRank": 2779
  },
  {
    "Character": "滟",
    "Pinyin": "yàn",
    "Definition": "overflowing, billowing; wavy",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5566,
    "FrequencyRank": 5594
  },
  {
    "Character": "嵲",
    "Pinyin": "niè",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7525,
    "FrequencyRank": undefined
  },
  {
    "Character": "溦",
    "Pinyin": "wēi",
    "Definition": "drizzle; (variant) valley; (variant) mold",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7592,
    "FrequencyRank": undefined
  },
  {
    "Character": "腠",
    "Pinyin": "còu",
    "Definition": "between the skin and the flesh",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5517,
    "FrequencyRank": 6999
  },
  {
    "Character": "衙",
    "Pinyin": "yá",
    "Definition": "public office; official residence",
    "Radical": "行",
    "RadicalIndex": "144.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2964,
    "FrequencyRank": 2769
  },
  {
    "Character": "裛",
    "Pinyin": "yì",
    "Definition": "to wrap and bind; damp; a book bag",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7570,
    "FrequencyRank": 8805
  },
  {
    "Character": "僇",
    "Pinyin": "lù",
    "Definition": "humiliate; treat with contempt",
    "Radical": "人",
    "RadicalIndex": "9.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7549,
    "FrequencyRank": undefined
  },
  {
    "Character": "鄣",
    "Pinyin": "zhāng",
    "Definition": "name of an ancient city in N. Jiangsu, near Shandong",
    "Radical": "邑",
    "RadicalIndex": "163.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7578,
    "FrequencyRank": 5176
  },
  {
    "Character": "蓑",
    "Pinyin": "suō",
    "Definition": "rain coat made of straw, coir, etc.",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5381,
    "FrequencyRank": 4481
  },
  {
    "Character": "鹒",
    "Pinyin": "gēng",
    "Definition": "oriole",
    "Radical": "鳥",
    "RadicalIndex": "196.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7575,
    "FrequencyRank": 8915
  },
  {
    "Character": "缙",
    "Pinyin": "jìn",
    "Definition": "red silk",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5610,
    "FrequencyRank": 4842
  },
  {
    "Character": "鲊",
    "Pinyin": "zhǎ",
    "Definition": "minced and salted fish; to preserve",
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7558,
    "FrequencyRank": 7344
  },
  {
    "Character": "瘃",
    "Pinyin": "zhú",
    "Definition": "sores from cold",
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5540,
    "FrequencyRank": undefined
  },
  {
    "Character": "溱",
    "Pinyin": "qín",
    "Definition": "river in Henan",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5567,
    "FrequencyRank": 6478
  },
  {
    "Character": "嗑",
    "Pinyin": "kē",
    "Definition": "eat seeds; reproach; loquacious",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5441,
    "FrequencyRank": 4594
  },
  {
    "Character": "酪",
    "Pinyin": "lào",
    "Definition": "cream, cheese; koumiss",
    "Radical": "酉",
    "RadicalIndex": "164.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2873,
    "FrequencyRank": 3667
  },
  {
    "Character": "鄜",
    "Pinyin": "fū",
    "Definition": "county in Shanxi province",
    "Radical": "邑",
    "RadicalIndex": "163.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7576,
    "FrequencyRank": 7602
  },
  {
    "Character": "廒",
    "Pinyin": "áo",
    "Definition": "a granary",
    "Radical": "广",
    "RadicalIndex": "53.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7571,
    "FrequencyRank": 7336
  },
  {
    "Character": "暕",
    "Pinyin": "jiǎn",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7517,
    "FrequencyRank": undefined
  },
  {
    "Character": "蜎",
    "Pinyin": "yuān",
    "Definition": "mosquito larva",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7524,
    "FrequencyRank": 8414
  },
  {
    "Character": "睥",
    "Pinyin": "pì",
    "Definition": "look askance at, glare at",
    "Radical": "目",
    "RadicalIndex": "109.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5439,
    "FrequencyRank": 5974
  },
  {
    "Character": "艉",
    "Pinyin": "wěi",
    "Definition": undefined,
    "Radical": "舟",
    "RadicalIndex": "137.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7551,
    "FrequencyRank": 5556
  },
  {
    "Character": "稞",
    "Pinyin": "kē",
    "Definition": "grain ready for grinding; grain",
    "Radical": "禾",
    "RadicalIndex": "115.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5497,
    "FrequencyRank": 5576
  },
  {
    "Character": "蒹",
    "Pinyin": "jiān",
    "Definition": "reed, phragmites communis",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5387,
    "FrequencyRank": 6704
  },
  {
    "Character": "榆",
    "Pinyin": "yú",
    "Definition": "elm tree",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2869,
    "FrequencyRank": 3272
  },
  {
    "Character": "腽",
    "Pinyin": "wà",
    "Definition": "fat",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7554,
    "FrequencyRank": 7694
  },
  {
    "Character": "貊",
    "Pinyin": "mò",
    "Definition": "leopard; ancient tribe in northeastern China",
    "Radical": "豸",
    "RadicalIndex": "153.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5513,
    "FrequencyRank": 6498
  },
  {
    "Character": "蒯",
    "Pinyin": "kuǎi",
    "Definition": "a rush, of which various things are made",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5379,
    "FrequencyRank": 5003
  },
  {
    "Character": "辏",
    "Pinyin": "còu",
    "Definition": "hubs of wheel; converge around",
    "Radical": "車",
    "RadicalIndex": "159.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5425,
    "FrequencyRank": 4354
  },
  {
    "Character": "猿",
    "Pinyin": "yuán",
    "Definition": "ape",
    "Radical": "犬",
    "RadicalIndex": "94.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2978,
    "FrequencyRank": 2312
  },
  {
    "Character": "溻",
    "Pinyin": "tā",
    "Definition": "wet",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5575,
    "FrequencyRank": 7229
  },
  {
    "Character": "遨",
    "Pinyin": "áo",
    "Definition": "ramble, roam; travel for pleasure",
    "Radical": "辵",
    "RadicalIndex": "162.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5346,
    "FrequencyRank": 4895
  },
  {
    "Character": "猷",
    "Pinyin": "yóu",
    "Definition": "plan, scheme; plan, plot; way",
    "Radical": "犬",
    "RadicalIndex": "94.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5558,
    "FrequencyRank": 4934
  },
  {
    "Character": "碚",
    "Pinyin": "bèi",
    "Definition": "suburb",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5421,
    "FrequencyRank": 5113
  },
  {
    "Character": "漭",
    "Pinyin": "mǎng",
    "Definition": "vast; expansive",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5569,
    "FrequencyRank": 8696
  },
  {
    "Character": "蓣",
    "Pinyin": "yù",
    "Definition": "yam",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7491,
    "FrequencyRank": 6796
  },
  {
    "Character": "蓖",
    "Pinyin": "bì",
    "Definition": "castor-oil plant, Ricinus commumis",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5377,
    "FrequencyRank": 4955
  },
  {
    "Character": "暌",
    "Pinyin": "kuí",
    "Definition": "in opposition; distant from; separated",
    "Radical": "日",
    "RadicalIndex": "72.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5451,
    "FrequencyRank": 5760
  },
  {
    "Character": "瑝",
    "Pinyin": "huáng",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7467,
    "FrequencyRank": undefined
  },
  {
    "Character": "楦",
    "Pinyin": "xuàn",
    "Definition": "a last for making shoes; to turn on a lathe",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5405,
    "FrequencyRank": 5692
  },
  {
    "Character": "豢",
    "Pinyin": "huàn",
    "Definition": "domestic animals; feed, raise",
    "Radical": "豕",
    "RadicalIndex": "152.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5556,
    "FrequencyRank": 3718
  },
  {
    "Character": "傺",
    "Pinyin": "chì",
    "Definition": "to hinder; to detain",
    "Radical": "人",
    "RadicalIndex": "9.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7547,
    "FrequencyRank": 6311
  },
  {
    "Character": "媾",
    "Pinyin": "gòu",
    "Definition": "marry; be on friendly terms with",
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5604,
    "FrequencyRank": 4524
  },
  {
    "Character": "滗",
    "Pinyin": "bì",
    "Definition": "drain",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5577,
    "FrequencyRank": 7237
  },
  {
    "Character": "媵",
    "Pinyin": "yìng",
    "Definition": "a maid who accompanies bride to her new home; to escort; a concubine",
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5523,
    "FrequencyRank": 6712
  },
  {
    "Character": "徭",
    "Pinyin": "yáo",
    "Definition": "conscript labor, compulsory labor",
    "Radical": "彳",
    "RadicalIndex": "60.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5508,
    "FrequencyRank": 4959
  },
  {
    "Character": "嗵",
    "Pinyin": "tōng",
    "Definition": "used for sound",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5476,
    "FrequencyRank": 4731
  },
  {
    "Character": "瑗",
    "Pinyin": "yuàn",
    "Definition": "large ring of fine jade",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5343,
    "FrequencyRank": 6181
  },
  {
    "Character": "韫",
    "Pinyin": "yùn",
    "Definition": "secrete, hide, conceal",
    "Radical": "韋",
    "RadicalIndex": "178.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5348,
    "FrequencyRank": 5668
  },
  {
    "Character": "嫒",
    "Pinyin": "ài",
    "Definition": "(your) daughter",
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5607,
    "FrequencyRank": 5874
  },
  {
    "Character": "虞",
    "Pinyin": "yú",
    "Definition": "concerned about, anxious, worried",
    "Radical": "虍",
    "RadicalIndex": "141.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5430,
    "FrequencyRank": 2936
  },
  {
    "Character": "鹋",
    "Pinyin": "miáo",
    "Definition": "emu",
    "Radical": "鳥",
    "RadicalIndex": "196.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5374,
    "FrequencyRank": 6324
  },
  {
    "Character": "瑀",
    "Pinyin": "yǔ",
    "Definition": "stone resembling jade; agate",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7469,
    "FrequencyRank": undefined
  },
  {
    "Character": "楝",
    "Pinyin": "liàn",
    "Definition": "Melia japonica",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5395,
    "FrequencyRank": 5723
  },
  {
    "Character": "蓠",
    "Pinyin": "lí",
    "Definition": "gracilaria verrucosa",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5384,
    "FrequencyRank": 7598
  },
  {
    "Character": "赗",
    "Pinyin": "fèng",
    "Definition": "gift",
    "Radical": "貝",
    "RadicalIndex": "154.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7526,
    "FrequencyRank": 7567
  },
  {
    "Character": "稔",
    "Pinyin": "rěn",
    "Definition": "ripe grain; harvest; to know, be familiar with",
    "Radical": "禾",
    "RadicalIndex": "115.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5499,
    "FrequencyRank": 5151
  },
  {
    "Character": "愫",
    "Pinyin": "sù",
    "Definition": "guileless, sincere, honest",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5588,
    "FrequencyRank": 3569
  },
  {
    "Character": "搋",
    "Pinyin": "chuāi",
    "Definition": "to thump",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5357,
    "FrequencyRank": 7334
  },
  {
    "Character": "筶",
    "Pinyin": "gào",
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7544,
    "FrequencyRank": 8857
  },
  {
    "Character": "瑟",
    "Pinyin": "sè",
    "Definition": "large stringed musical instrument; dignified, massive; sound of wind",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2825,
    "FrequencyRank": 1694
  },
  {
    "Character": "锩",
    "Pinyin": "juǎn",
    "Definition": "to bend iron",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5490,
    "FrequencyRank": 5553
  },
  {
    "Character": "瑁",
    "Pinyin": "mào",
    "Definition": "fine piece of jade",
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5341,
    "FrequencyRank": 4651
  },
  {
    "Character": "雏",
    "Pinyin": "chú",
    "Definition": "chick, fledging; infant, toddler",
    "Radical": "隹",
    "RadicalIndex": "172.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2983,
    "FrequencyRank": 3484
  },
  {
    "Character": "跐",
    "Pinyin": "cī",
    "Definition": "to trample, to step, to walk on the ball of the foot",
    "Radical": "足",
    "RadicalIndex": "157.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5455,
    "FrequencyRank": 7029
  },
  {
    "Character": "靖",
    "Pinyin": "jìng",
    "Definition": "pacify; appease; calm, peaceful",
    "Radical": "青",
    "RadicalIndex": "174.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2993,
    "FrequencyRank": 2545
  },
  {
    "Character": "瘆",
    "Pinyin": "shèn",
    "Definition": undefined,
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5546,
    "FrequencyRank": undefined
  },
  {
    "Character": "楒",
    "Pinyin": "sī",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7496,
    "FrequencyRank": undefined
  },
  {
    "Character": "窣",
    "Pinyin": "sū",
    "Definition": "to rush out of a den; rustling, whispering",
    "Radical": "穴",
    "RadicalIndex": "116.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5595,
    "FrequencyRank": 4907
  },
  {
    "Character": "滂",
    "Pinyin": "pāng",
    "Definition": "torrential; voluminous",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5584,
    "FrequencyRank": 4424
  },
  {
    "Character": "麀",
    "Pinyin": "yōu",
    "Definition": "female deer; roe, doe",
    "Radical": "鹿",
    "RadicalIndex": "198.2",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7577,
    "FrequencyRank": 7783
  },
  {
    "Character": "骜",
    "Pinyin": "ào",
    "Definition": "wild horse, mustang; wild",
    "Radical": "馬",
    "RadicalIndex": "187.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5347,
    "FrequencyRank": 5565
  },
  {
    "Character": "锨",
    "Pinyin": "xiān",
    "Definition": "shovel",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5489,
    "FrequencyRank": 4650
  },
  {
    "Character": "蒿",
    "Pinyin": "hāo",
    "Definition": "mugwort, artemisia; give off",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5382,
    "FrequencyRank": 4305
  },
  {
    "Character": "滫",
    "Pinyin": "xiǔ",
    "Definition": "water in which rice has been boiled",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5578,
    "FrequencyRank": undefined
  },
  {
    "Character": "阙",
    "Pinyin": "quē",
    "Definition": "watch tower; palace",
    "Radical": "門",
    "RadicalIndex": "169.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5554,
    "FrequencyRank": 3452
  },
  {
    "Character": "觥",
    "Pinyin": "gōng",
    "Definition": "a cup made of horn obstinate",
    "Radical": "角",
    "RadicalIndex": "148.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5535,
    "FrequencyRank": 4532
  },
  {
    "Character": "鹌",
    "Pinyin": "ān",
    "Definition": "quail; Coturnix coturnix",
    "Radical": "鳥",
    "RadicalIndex": "196.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5424,
    "FrequencyRank": 4741
  },
  {
    "Character": "溵",
    "Pinyin": "yīn",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7593,
    "FrequencyRank": undefined
  },
  {
    "Character": "筱",
    "Pinyin": "xiǎo",
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5504,
    "FrequencyRank": 4026
  },
  {
    "Character": "腮",
    "Pinyin": "sāi",
    "Definition": "lower part of face; jaw; gills of a fish",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2971,
    "FrequencyRank": 3185
  },
  {
    "Character": "剿",
    "Pinyin": "jiǎo",
    "Definition": "destroy, exterminate, annihilate",
    "Radical": "刀",
    "RadicalIndex": "18.14",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 3048,
    "FrequencyRank": 2632
  },
  {
    "Character": "硼",
    "Pinyin": "péng",
    "Definition": "borax, boron",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5419,
    "FrequencyRank": 4602
  },
  {
    "Character": "嗌",
    "Pinyin": "ài",
    "Definition": "the throat; to quarrel, choke",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5471,
    "FrequencyRank": 5391
  },
  {
    "Character": "煜",
    "Pinyin": "yù",
    "Definition": "bright, shining, brilliant",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5560,
    "FrequencyRank": 3834
  },
  {
    "Character": "鄠",
    "Pinyin": "hù",
    "Definition": "county in Shanxi province",
    "Radical": "邑",
    "RadicalIndex": "163.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7510,
    "FrequencyRank": 7769
  },
  {
    "Character": "椴",
    "Pinyin": "duàn",
    "Definition": "poplar, aspen",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5398,
    "FrequencyRank": 5472
  },
  {
    "Character": "摈",
    "Pinyin": "bìn",
    "Definition": "exclude, expel, reject; usher",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5362,
    "FrequencyRank": 4363
  },
  {
    "Character": "溧",
    "Pinyin": "lì",
    "Definition": "river in Anhui and Jiangsu provinces",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5572,
    "FrequencyRank": 5865
  },
  {
    "Character": "楫",
    "Pinyin": "jí",
    "Definition": "oar, paddle",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5396,
    "FrequencyRank": 4788
  },
  {
    "Character": "碈",
    "Pinyin": "mín",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7507,
    "FrequencyRank": undefined
  },
  {
    "Character": "锞",
    "Pinyin": "kè",
    "Definition": "ingot; acrobatic move",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5486,
    "FrequencyRank": 6117
  },
  {
    "Character": "椽",
    "Pinyin": "chuán",
    "Definition": "beams, rafters, supports",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5408,
    "FrequencyRank": 4405
  },
  {
    "Character": "雍",
    "Pinyin": "yōng",
    "Definition": "harmony, union; harmonious",
    "Radical": "隹",
    "RadicalIndex": "172.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5551,
    "FrequencyRank": 3327
  },
  {
    "Character": "皙",
    "Pinyin": "xī",
    "Definition": "white; kind of date",
    "Radical": "白",
    "RadicalIndex": "106.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5401,
    "FrequencyRank": 4315
  },
  {
    "Character": "漷",
    "Pinyin": "huǒ",
    "Definition": "river in Hebei province",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7594,
    "FrequencyRank": undefined
  },
  {
    "Character": "煁",
    "Pinyin": "chén",
    "Definition": "hearth; (Cant.) soft, tender",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7581,
    "FrequencyRank": undefined
  },
  {
    "Character": "塱",
    "Pinyin": "lǎng",
    "Definition": "place name in Guangdong province",
    "Radical": "土",
    "RadicalIndex": "32.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7601,
    "FrequencyRank": undefined
  },
  {
    "Character": "骰",
    "Pinyin": "tóu",
    "Definition": "die, dice",
    "Radical": "骨",
    "RadicalIndex": "188.4",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5481,
    "FrequencyRank": 4226
  },
  {
    "Character": "嗉",
    "Pinyin": "sù",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5435,
    "FrequencyRank": 5351
  },
  {
    "Character": "摅",
    "Pinyin": "shū",
    "Definition": "spread, disperse; vent, set forth",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5354,
    "FrequencyRank": 6689
  },
  {
    "Character": "锟",
    "Pinyin": "kūn",
    "Definition": "ancient treasured sword",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5487,
    "FrequencyRank": 4644
  },
  {
    "Character": "筲",
    "Pinyin": "shāo",
    "Definition": "basket, bucket",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5503,
    "FrequencyRank": 6158
  },
  {
    "Character": "煴",
    "Pinyin": "yūn",
    "Definition": "sultriness, stuffiness",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7583,
    "FrequencyRank": undefined
  },
  {
    "Character": "溍",
    "Pinyin": "jìn",
    "Definition": "water",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7588,
    "FrequencyRank": undefined
  },
  {
    "Character": "嗍",
    "Pinyin": "suō",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5472,
    "FrequencyRank": 5248
  },
  {
    "Character": "滘",
    "Pinyin": "jiào",
    "Definition": "a branching river (used in place names)",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7596,
    "FrequencyRank": undefined
  },
  {
    "Character": "戥",
    "Pinyin": "děng",
    "Definition": "a small steelyard for weighing money, etc.",
    "Radical": "戈",
    "RadicalIndex": "62.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5446,
    "FrequencyRank": 5977
  },
  {
    "Character": "雉",
    "Pinyin": "zhì",
    "Definition": "pheasant; crenellated wall",
    "Radical": "隹",
    "RadicalIndex": "172.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5493,
    "FrequencyRank": 4559
  },
  {
    "Character": "骟",
    "Pinyin": "shàn",
    "Definition": "geld, castrate",
    "Radical": "馬",
    "RadicalIndex": "187.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5619,
    "FrequencyRank": 7315
  },
  {
    "Character": "跹",
    "Pinyin": "xiān",
    "Definition": "wander about, walk around; revolve",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5457,
    "FrequencyRank": 5323
  },
  {
    "Character": "楹",
    "Pinyin": "yíng",
    "Definition": "column, pillar; numerary adjunct",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5407,
    "FrequencyRank": 4769
  },
  {
    "Character": "溘",
    "Pinyin": "kè",
    "Definition": "abruptly, suddenly, unexpectedly",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5568,
    "FrequencyRank": 5881
  },
  {
    "Character": "煋",
    "Pinyin": "xīng",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7584,
    "FrequencyRank": undefined
  },
  {
    "Character": "瘐",
    "Pinyin": "yǔ",
    "Definition": "to die in prison from cold and hunger; to treat with cruelty",
    "Radical": "疒",
    "RadicalIndex": "104.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5544,
    "FrequencyRank": 6763
  },
  {
    "Character": "碜",
    "Pinyin": "chěn",
    "Definition": "gritty",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5423,
    "FrequencyRank": 4931
  },
  {
    "Character": "碘",
    "Pinyin": "diǎn",
    "Definition": "iodine",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2877,
    "FrequencyRank": 3681
  },
  {
    "Character": "裰",
    "Pinyin": "duō",
    "Definition": "to mend clothes",
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5600,
    "FrequencyRank": 5251
  },
  {
    "Character": "瑅",
    "Pinyin": "tí",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7463,
    "FrequencyRank": undefined
  },
  {
    "Character": "煸",
    "Pinyin": "biān",
    "Definition": "to stir-fry before broiling or stewing",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5564,
    "FrequencyRank": 5609
  },
  {
    "Character": "裟",
    "Pinyin": "shā",
    "Definition": "a cassock or robe of a monk",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5574,
    "FrequencyRank": 4784
  },
  {
    "Character": "锣",
    "Pinyin": "luó",
    "Definition": "gong",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2939,
    "FrequencyRank": 3162
  },
  {
    "Character": "碓",
    "Pinyin": "duì",
    "Definition": "pestle",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5418,
    "FrequencyRank": 5370
  },
  {
    "Character": "鲅",
    "Pinyin": "bà",
    "Definition": "fish name",
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5525,
    "FrequencyRank": 5451
  },
  {
    "Character": "滠",
    "Pinyin": "shè",
    "Definition": "river in Hubei province",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7587,
    "FrequencyRank": 7100
  },
  {
    "Character": "碃",
    "Pinyin": "qìng",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7504,
    "FrequencyRank": 8027
  },
  {
    "Character": "骝",
    "Pinyin": "liú",
    "Definition": "famous horse",
    "Radical": "馬",
    "RadicalIndex": "187.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5614,
    "FrequencyRank": 6427
  },
  {
    "Character": "蜃",
    "Pinyin": "shèn",
    "Definition": "marine monster which can change its shape; water spouts; clams",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5416,
    "FrequencyRank": 4284
  },
  {
    "Character": "鹉",
    "Pinyin": "wǔ",
    "Definition": "species of parrot",
    "Radical": "鳥",
    "RadicalIndex": "196.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2826,
    "FrequencyRank": 3610
  },
  {
    "Character": "蛾",
    "Pinyin": "é",
    "Definition": "moth",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2924,
    "FrequencyRank": 3398
  },
  {
    "Character": "幌",
    "Pinyin": "huǎng",
    "Definition": "curtain, cloth screen",
    "Radical": "巾",
    "RadicalIndex": "50.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2935,
    "FrequencyRank": 3243
  },
  {
    "Character": "裱",
    "Pinyin": "biǎo",
    "Definition": "to mount maps or scrolls to paste",
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5596,
    "FrequencyRank": 4850
  },
  {
    "Character": "戤",
    "Pinyin": "gài",
    "Definition": "to infringe a trade-mark to pledge an article",
    "Radical": "戈",
    "RadicalIndex": "62.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7614,
    "FrequencyRank": 7747
  },
  {
    "Character": "𦝼",
    "Pinyin": "lǘ",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7568,
    "FrequencyRank": undefined
  },
  {
    "Character": "蒻",
    "Pinyin": "ruò",
    "Definition": "rushes",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7490,
    "FrequencyRank": 8803
  },
  {
    "Character": "禋",
    "Pinyin": "yīn",
    "Definition": "offer sacrifice; sacrifice",
    "Radical": "示",
    "RadicalIndex": "113.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7604,
    "FrequencyRank": 7245
  },
  {
    "Character": "裘",
    "Pinyin": "qiú",
    "Definition": "fur garments; surname",
    "Radical": "衣",
    "RadicalIndex": "145.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5409,
    "FrequencyRank": 2868
  },
  {
    "Character": "搒",
    "Pinyin": "bàng",
    "Definition": "pole; beat",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7482,
    "FrequencyRank": undefined
  },
  {
    "Character": "谼",
    "Pinyin": "hóng",
    "Definition": undefined,
    "Radical": "谷",
    "RadicalIndex": "150.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7552,
    "FrequencyRank": 9878
  },
  {
    "Character": "鄞",
    "Pinyin": "yín",
    "Definition": "county in Zhejiang province",
    "Radical": "邑",
    "RadicalIndex": "163.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5370,
    "FrequencyRank": 5863
  },
  {
    "Character": "蜗",
    "Pinyin": "wō",
    "Definition": "a snail, Eulota callizoma",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2923,
    "FrequencyRank": 3723
  },
  {
    "Character": "筤",
    "Pinyin": "láng",
    "Definition": "young bamboo",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7546,
    "FrequencyRank": undefined
  },
  {
    "Character": "髡",
    "Pinyin": "kūn",
    "Definition": "to shear tree; ancient punishment",
    "Radical": "髟",
    "RadicalIndex": "190.3",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5349,
    "FrequencyRank": 4754
  },
  {
    "Character": "锚",
    "Pinyin": "máo",
    "Definition": "anchor",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2937,
    "FrequencyRank": 3500
  },
  {
    "Character": "𤧛",
    "Pinyin": "dì",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7470,
    "FrequencyRank": undefined
  },
  {
    "Character": "勠",
    "Pinyin": "lù",
    "Definition": "join forces, unite",
    "Radical": "力",
    "RadicalIndex": "19.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7615,
    "FrequencyRank": undefined
  },
  {
    "Character": "跬",
    "Pinyin": "kuǐ",
    "Definition": "to step; a stride equal to half a pace",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5452,
    "FrequencyRank": 6120
  },
  {
    "Character": "缛",
    "Pinyin": "rù",
    "Definition": "decorative, adorned, elegant",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5612,
    "FrequencyRank": 5057
  },
  {
    "Character": "楣",
    "Pinyin": "méi",
    "Definition": "crossbeam above or under gate",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5406,
    "FrequencyRank": 4048
  },
  {
    "Character": "歆",
    "Pinyin": "xīn",
    "Definition": "like, admire; willingly, gladly; to quicken",
    "Radical": "欠",
    "RadicalIndex": "76.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5549,
    "FrequencyRank": 4418
  },
  {
    "Character": "褂",
    "Pinyin": "guà",
    "Definition": "jacket, overcoat, coat; gown",
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 3031,
    "FrequencyRank": 3197
  },
  {
    "Character": "禊",
    "Pinyin": "xì",
    "Definition": "semi-annual ceremony of purification",
    "Radical": "示",
    "RadicalIndex": "113.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5601,
    "FrequencyRank": 6727
  },
  {
    "Character": "蛸",
    "Pinyin": "shāo",
    "Definition": "long legged spider; octopus; chrysalis of mantis",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5459,
    "FrequencyRank": 6545
  },
  {
    "Character": "赪",
    "Pinyin": "chēng",
    "Definition": "deep red",
    "Radical": "赤",
    "RadicalIndex": "155.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7479,
    "FrequencyRank": 8358
  },
  {
    "Character": "嵴",
    "Pinyin": "jǐ",
    "Definition": "mountain ridge",
    "Radical": "山",
    "RadicalIndex": "46.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5480,
    "FrequencyRank": 5420
  },
  {
    "Character": "鲐",
    "Pinyin": "tái",
    "Definition": "globefish, tetraodon",
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5531,
    "FrequencyRank": 6742
  },
  {
    "Character": "蜍",
    "Pinyin": "chú",
    "Definition": "toad",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5461,
    "FrequencyRank": 5307
  },
  {
    "Character": "腭",
    "Pinyin": "è",
    "Definition": "palate, roof of mouth",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5520,
    "FrequencyRank": 5294
  },
  {
    "Character": "锢",
    "Pinyin": "gù",
    "Definition": "run metal into cracks; confine",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5488,
    "FrequencyRank": 3948
  },
  {
    "Character": "詹",
    "Pinyin": "zhān",
    "Definition": "surname; talk too much, verbose",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5524,
    "FrequencyRank": 2533
  },
  {
    "Character": "煅",
    "Pinyin": "duàn",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5562,
    "FrequencyRank": 5608
  },
  {
    "Character": "搐",
    "Pinyin": "chù",
    "Definition": "cramp, spasm, convulsion; twitch",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5359,
    "FrequencyRank": 3680
  },
  {
    "Character": "楂",
    "Pinyin": "zhā",
    "Definition": "a raft, to hew, to fell trees",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5394,
    "FrequencyRank": 4764
  },
  {
    "Character": "跸",
    "Pinyin": "bì",
    "Definition": "clear way, make room for; emp",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5454,
    "FrequencyRank": 4987
  },
  {
    "Character": "蛹",
    "Pinyin": "yǒng",
    "Definition": "chrysalis, larva",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5465,
    "FrequencyRank": 3974
  },
  {
    "Character": "瘀",
    "Pinyin": "yū",
    "Definition": "a hematoma, contusion; extravasted blood",
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7572,
    "FrequencyRank": 4157
  },
  {
    "Character": "楞",
    "Pinyin": "léng",
    "Definition": "used for Ceylon in Buddhist texts",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7497,
    "FrequencyRank": 3223
  },
  {
    "Character": "誊",
    "Pinyin": "téng",
    "Definition": "copy, transcribe",
    "Radical": "言",
    "RadicalIndex": "149.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2997,
    "FrequencyRank": 4971
  },
  {
    "Character": "慊",
    "Pinyin": "qiàn",
    "Definition": "to resent; contended; satisfied",
    "Radical": "心",
    "RadicalIndex": "61.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5590,
    "FrequencyRank": 5621
  },
  {
    "Character": "锘",
    "Pinyin": "nuò",
    "Definition": "nobelium (No)",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7530,
    "FrequencyRank": 6147
  },
  {
    "Character": "缞",
    "Pinyin": "cuī",
    "Definition": "sackcloth worn on breast during",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7619,
    "FrequencyRank": 6560
  },
  {
    "Character": "蜣",
    "Pinyin": "qiāng",
    "Definition": "dung beetle",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5463,
    "FrequencyRank": 5702
  },
  {
    "Character": "龅",
    "Pinyin": "bāo",
    "Definition": "teeth protrude",
    "Radical": "齒",
    "RadicalIndex": "211.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5427,
    "FrequencyRank": 4662
  },
  {
    "Character": "艄",
    "Pinyin": "shāo",
    "Definition": "stern of a vessel",
    "Radical": "舟",
    "RadicalIndex": "137.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5510,
    "FrequencyRank": 4898
  },
  {
    "Character": "髢",
    "Pinyin": "dí",
    "Definition": "wig",
    "Radical": "髟",
    "RadicalIndex": "190.3",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7476,
    "FrequencyRank": undefined
  },
  {
    "Character": "蒡",
    "Pinyin": "bàng",
    "Definition": "burdock, herb",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5386,
    "FrequencyRank": 6034
  },
  {
    "Character": "蒴",
    "Pinyin": "shuò",
    "Definition": "seed (capsule)",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5388,
    "FrequencyRank": 6043
  },
  {
    "Character": "亶",
    "Pinyin": "dǎn",
    "Definition": "sincere; real, true; truth",
    "Radical": "亠",
    "RadicalIndex": "8.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5539,
    "FrequencyRank": undefined
  },
  {
    "Character": "遢",
    "Pinyin": "tà",
    "Definition": "careless, negligent, slipshod",
    "Radical": "辵",
    "RadicalIndex": "162.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5450,
    "FrequencyRank": 5040
  },
  {
    "Character": "碛",
    "Pinyin": "qì",
    "Definition": "sand and gravel",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5417,
    "FrequencyRank": 4790
  },
  {
    "Character": "愍",
    "Pinyin": "mǐn",
    "Definition": "pity, sympathize with",
    "Radical": "心",
    "RadicalIndex": "61.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7611,
    "FrequencyRank": 5465
  },
  {
    "Character": "滦",
    "Pinyin": "luán",
    "Definition": "river and county in Hebei province",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5582,
    "FrequencyRank": 4407
  },
  {
    "Character": "粱",
    "Pinyin": "liáng",
    "Definition": "better varieties of millet",
    "Radical": "米",
    "RadicalIndex": "119.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 3021,
    "FrequencyRank": 3599
  },
  {
    "Character": "敫",
    "Pinyin": "jiǎo",
    "Definition": "ancient musical instrument",
    "Radical": "攴",
    "RadicalIndex": "66.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5507,
    "FrequencyRank": 5937
  },
  {
    "Character": "溷",
    "Pinyin": "hùn",
    "Definition": "privy, latrine; turbid, dirty",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5576,
    "FrequencyRank": 6559
  },
  {
    "Character": "蓊",
    "Pinyin": "wěng",
    "Definition": "luxuriant vegetation; lush",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5378,
    "FrequencyRank": 6287
  },
  {
    "Character": "滆",
    "Pinyin": "gé",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7590,
    "FrequencyRank": undefined
  },
  {
    "Character": "蜊",
    "Pinyin": "lí",
    "Definition": "clam",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5460,
    "FrequencyRank": 4822
  },
  {
    "Character": "堽",
    "Pinyin": "gāng",
    "Definition": "mound; used in place names",
    "Radical": "土",
    "RadicalIndex": "32.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7478,
    "FrequencyRank": undefined
  },
  {
    "Character": "䃅",
    "Pinyin": "dī",
    "Definition": "(simplified form 磾) dyestuff (black colored mineral)",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7508,
    "FrequencyRank": undefined
  },
  {
    "Character": "槎",
    "Pinyin": "chá",
    "Definition": "raft; time, occasion; to hew",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5403,
    "FrequencyRank": 5143
  },
  {
    "Character": "蒗",
    "Pinyin": "làng",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5389,
    "FrequencyRank": 6624
  },
  {
    "Character": "煃",
    "Pinyin": "kuǐ",
    "Definition": "remove, get rid of; scatter",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7582,
    "FrequencyRank": undefined
  },
  {
    "Character": "摛",
    "Pinyin": "chī",
    "Definition": "to spread (name, news); to be known",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7480,
    "FrequencyRank": undefined
  },
  {
    "Character": "榃",
    "Pinyin": "tán",
    "Definition": "raised path between fields",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7494,
    "FrequencyRank": undefined
  },
  {
    "Character": "溴",
    "Pinyin": "xiù",
    "Definition": "bromine",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5579,
    "FrequencyRank": 4990
  },
  {
    "Character": "腯",
    "Pinyin": "tú",
    "Definition": "strong",
    "Radical": "肉",
    "RadicalIndex": "130.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7556,
    "FrequencyRank": undefined
  },
  {
    "Character": "趔",
    "Pinyin": "liè",
    "Definition": "not progressing; to be checked",
    "Radical": "走",
    "RadicalIndex": "156.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5352,
    "FrequencyRank": 4969
  },
  {
    "Character": "鲎",
    "Pinyin": "hòu",
    "Definition": "king crab",
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5591,
    "FrequencyRank": 5084
  },
  {
    "Character": "煓",
    "Pinyin": "tuān",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7586,
    "FrequencyRank": undefined
  },
  {
    "Character": "煟",
    "Pinyin": "wèi",
    "Definition": "bright room",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7585,
    "FrequencyRank": undefined
  },
  {
    "Character": "酯",
    "Pinyin": "zhǐ",
    "Definition": "ester",
    "Radical": "酉",
    "RadicalIndex": "164.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5414,
    "FrequencyRank": 4412
  },
  {
    "Character": "鲏",
    "Pinyin": "pí",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7563,
    "FrequencyRank": 6623
  },
  {
    "Character": "锗",
    "Pinyin": "zhě",
    "Definition": "germanium",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5482,
    "FrequencyRank": 5747
  },
  {
    "Character": "酰",
    "Pinyin": "xiān",
    "Definition": "acyl",
    "Radical": "酉",
    "RadicalIndex": "164.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5413,
    "FrequencyRank": 4022
  },
  {
    "Character": "飔",
    "Pinyin": "sī",
    "Definition": "cool breeze of autumn",
    "Radical": "風",
    "RadicalIndex": "182.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7566,
    "FrequencyRank": 7790
  },
  {
    "Character": "楠",
    "Pinyin": "nán",
    "Definition": "name of tree, machilus nanmu",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5393,
    "FrequencyRank": 3700
  },
  {
    "Character": "睫",
    "Pinyin": "jié",
    "Definition": "eyelashes",
    "Radical": "目",
    "RadicalIndex": "109.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2899,
    "FrequencyRank": 3415
  },
  {
    "Character": "煨",
    "Pinyin": "wēi",
    "Definition": "to stew, simmer",
    "Radical": "火",
    "RadicalIndex": "86.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5561,
    "FrequencyRank": 4855
  },
  {
    "Character": "瘁",
    "Pinyin": "cuì",
    "Definition": "feel tired, be weary, be worn out",
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5545,
    "FrequencyRank": 4325
  },
  {
    "Character": "锝",
    "Pinyin": "dé",
    "Definition": "technetium",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5485,
    "FrequencyRank": 6015
  },
  {
    "Character": "嗤",
    "Pinyin": "chī",
    "Definition": "laugh at, ridicule, sneer; snort",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5475,
    "FrequencyRank": 3254
  },
  {
    "Character": "搛",
    "Pinyin": "jiān",
    "Definition": undefined,
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5360,
    "FrequencyRank": 6782
  },
  {
    "Character": "搠",
    "Pinyin": "shuò",
    "Definition": "to daub; to thrust",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5361,
    "FrequencyRank": 6541
  },
  {
    "Character": "馐",
    "Pinyin": "xiū",
    "Definition": "food, meal; eat; offer",
    "Radical": "食",
    "RadicalIndex": "184.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5537,
    "FrequencyRank": 4223
  },
  {
    "Character": "锥",
    "Pinyin": "zhuī",
    "Definition": "gimlet, awl, drill, auger; bore",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2941,
    "FrequencyRank": 3064
  },
  {
    "Character": "鲌",
    "Pinyin": "bà",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7559,
    "FrequencyRank": 6234
  },
  {
    "Character": "睨",
    "Pinyin": "nì",
    "Definition": "look askance at, glare at; squint",
    "Radical": "目",
    "RadicalIndex": "109.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5436,
    "FrequencyRank": 4448
  },
  {
    "Character": "筮",
    "Pinyin": "shì",
    "Definition": "divination with stalks of plants; divining rod",
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5502,
    "FrequencyRank": 5559
  },
  {
    "Character": "剽",
    "Pinyin": "piāo",
    "Definition": "rob, plunder; slice off; fast",
    "Radical": "刀",
    "RadicalIndex": "18.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5410,
    "FrequencyRank": 4386
  },
  {
    "Character": "痱",
    "Pinyin": "fèi",
    "Definition": "heat rash, prickly heat; ulcers",
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5541,
    "FrequencyRank": 5724
  },
  {
    "Character": "搌",
    "Pinyin": "zhǎn",
    "Definition": "to wipe tears; to bind",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7483,
    "FrequencyRank": 8301
  },
  {
    "Character": "跺",
    "Pinyin": "duò",
    "Definition": "stamp feet, step",
    "Radical": "足",
    "RadicalIndex": "157.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2916,
    "FrequencyRank": 3738
  },
  {
    "Character": "暅",
    "Pinyin": "gèng",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7521,
    "FrequencyRank": undefined
  },
  {
    "Character": "酩",
    "Pinyin": "mǐng",
    "Definition": "drunk; intoxicated; tipsy",
    "Radical": "酉",
    "RadicalIndex": "164.6",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5415,
    "FrequencyRank": 5183
  },
  {
    "Character": "椸",
    "Pinyin": "yí",
    "Definition": "rack",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7500,
    "FrequencyRank": undefined
  },
  {
    "Character": "嗣",
    "Pinyin": "sì",
    "Definition": "to connect, inherit; descendants, heirs",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5466,
    "FrequencyRank": 2693
  },
  {
    "Character": "嘟",
    "Pinyin": "dū",
    "Definition": "sound of horn tooting",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5440,
    "FrequencyRank": 2838
  },
  {
    "Character": "鲍",
    "Pinyin": "bào",
    "Definition": "abalone; dried fish; surname",
    "Radical": "魚",
    "RadicalIndex": "195.5",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2977,
    "FrequencyRank": 2073
  },
  {
    "Character": "碏",
    "Pinyin": "què",
    "Definition": "coloured",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7505,
    "FrequencyRank": undefined
  },
  {
    "Character": "蓍",
    "Pinyin": "shī",
    "Definition": "milfoil, plant used in divination",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5369,
    "FrequencyRank": 6185
  },
  {
    "Character": "媱",
    "Pinyin": "yáo",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7613,
    "FrequencyRank": undefined
  },
  {
    "Character": "嵩",
    "Pinyin": "sōng",
    "Definition": "high, lofty; one of the 5 peaks, situated in Hunan",
    "Radical": "山",
    "RadicalIndex": "46.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5479,
    "FrequencyRank": 3401
  },
  {
    "Character": "牒",
    "Pinyin": "dié",
    "Definition": "documents, records; dispatch",
    "Radical": "片",
    "RadicalIndex": "91.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5505,
    "FrequencyRank": 4122
  },
  {
    "Character": "鹊",
    "Pinyin": "què",
    "Definition": "magpie; Pica species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2851,
    "FrequencyRank": 3525
  },
  {
    "Character": "靳",
    "Pinyin": "jìn",
    "Definition": undefined,
    "Radical": "革",
    "RadicalIndex": "177.4",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5371,
    "FrequencyRank": 3973
  },
  {
    "Character": "𫖳",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7610,
    "FrequencyRank": undefined
  },
  {
    "Character": "窦",
    "Pinyin": "dòu",
    "Definition": "surname; hole, burrow; corrupt",
    "Radical": "穴",
    "RadicalIndex": "116.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5593,
    "FrequencyRank": 2895
  },
  {
    "Character": "稑",
    "Pinyin": "lù",
    "Definition": "rice",
    "Radical": "禾",
    "RadicalIndex": "115.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7538,
    "FrequencyRank": 9224
  },
  {
    "Character": "嶅",
    "Pinyin": "áo",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7473,
    "FrequencyRank": undefined
  },
  {
    "Character": "颔",
    "Pinyin": "hàn",
    "Definition": "chin, jowl; give nod",
    "Radical": "頁",
    "RadicalIndex": "181.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5516,
    "FrequencyRank": 4158
  },
  {
    "Character": "毂",
    "Pinyin": "gǔ",
    "Definition": "hub of wheel",
    "Radical": "殳",
    "RadicalIndex": "79.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5364,
    "FrequencyRank": 5052
  },
  {
    "Character": "蜇",
    "Pinyin": "zhē",
    "Definition": "jellyfish",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5356,
    "FrequencyRank": 4645
  },
  {
    "Character": "蜈",
    "Pinyin": "wú",
    "Definition": "centipede",
    "Radical": "虫",
    "RadicalIndex": "142.7",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2922,
    "FrequencyRank": 3918
  },
  {
    "Character": "瘅",
    "Pinyin": "dān",
    "Definition": "to hate bitterly; drought; dry",
    "Radical": "疒",
    "RadicalIndex": "104.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7573,
    "FrequencyRank": 7446
  },
  {
    "Character": "滧",
    "Pinyin": "xiao",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7595,
    "FrequencyRank": undefined
  },
  {
    "Character": "韪",
    "Pinyin": "wěi",
    "Definition": "right; proper; perpriety",
    "Radical": "韋",
    "RadicalIndex": "178.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5433,
    "FrequencyRank": 5229
  },
  {
    "Character": "蓐",
    "Pinyin": "rù",
    "Definition": "straw bed mat; rushes",
    "Radical": "艸",
    "RadicalIndex": "140.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5372,
    "FrequencyRank": 6647
  },
  {
    "Character": "碇",
    "Pinyin": "dìng",
    "Definition": "anchor",
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5422,
    "FrequencyRank": 5053
  },
  {
    "Character": "椿",
    "Pinyin": "chūn",
    "Definition": "father; a plant with white flowers",
    "Radical": "木",
    "RadicalIndex": "75.9",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 2862,
    "FrequencyRank": 4197
  },
  {
    "Character": "蔀",
    "Pinyin": "bù",
    "Definition": "screen",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 7487,
    "FrequencyRank": 6952
  },
  {
    "Character": "嗥",
    "Pinyin": "háo",
    "Definition": "roar, call out, wail; bark, yelp",
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": 13,
    "HSK": undefined,
    "GeneralStandard": 5468,
    "FrequencyRank": 4287
  },
  {
    "Character": "榍",
    "Pinyin": "xiè",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7652,
    "FrequencyRank": 6136
  },
  {
    "Character": "蓰",
    "Pinyin": "xǐ",
    "Definition": "to increase five-fold",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7643,
    "FrequencyRank": 7599
  },
  {
    "Character": "夐",
    "Pinyin": "xiòng",
    "Definition": "long, faraway; pre-eminent",
    "Radical": "夊",
    "RadicalIndex": "35.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7709,
    "FrequencyRank": undefined
  },
  {
    "Character": "艋",
    "Pinyin": "měng",
    "Definition": "small boat",
    "Radical": "舟",
    "RadicalIndex": "137.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5764,
    "FrequencyRank": 6513
  },
  {
    "Character": "僦",
    "Pinyin": "jiù",
    "Definition": "to heir; to rent",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7694,
    "FrequencyRank": 5438
  },
  {
    "Character": "骷",
    "Pinyin": "kū",
    "Definition": "skeleton",
    "Radical": "骨",
    "RadicalIndex": "188.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5730,
    "FrequencyRank": 3745
  },
  {
    "Character": "暝",
    "Pinyin": "míng",
    "Definition": "dark, obscure",
    "Radical": "日",
    "RadicalIndex": "72.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5701,
    "FrequencyRank": 6019
  },
  {
    "Character": "酲",
    "Pinyin": "chéng",
    "Definition": "hangover; uncomfortable",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7657,
    "FrequencyRank": 6850
  },
  {
    "Character": "凘",
    "Pinyin": "sī",
    "Definition": undefined,
    "Radical": "冫",
    "RadicalIndex": "15.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7713,
    "FrequencyRank": undefined
  },
  {
    "Character": "嗾",
    "Pinyin": "sǒu",
    "Definition": "to set a dog on; incite, instigate",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5721,
    "FrequencyRank": 5455
  },
  {
    "Character": "幔",
    "Pinyin": "màn",
    "Definition": "curtain, screen, tent",
    "Radical": "巾",
    "RadicalIndex": "50.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5725,
    "FrequencyRank": 3538
  },
  {
    "Character": "槜",
    "Pinyin": "zuì",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7651,
    "FrequencyRank": undefined
  },
  {
    "Character": "嘏",
    "Pinyin": "gǔ",
    "Definition": "felicity, prosperity; large and strong",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7646,
    "FrequencyRank": 5670
  },
  {
    "Character": "雒",
    "Pinyin": "luò",
    "Definition": "black horse with white mane",
    "Radical": "隹",
    "RadicalIndex": "172.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5775,
    "FrequencyRank": 5138
  },
  {
    "Character": "镅",
    "Pinyin": "méi",
    "Definition": "americium",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7687,
    "FrequencyRank": 6016
  },
  {
    "Character": "鹛",
    "Pinyin": "méi",
    "Definition": undefined,
    "Radical": "鳥",
    "RadicalIndex": "196.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5829,
    "FrequencyRank": 6336
  },
  {
    "Character": "锷",
    "Pinyin": "è",
    "Definition": "high, lofty; edge of knife",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5736,
    "FrequencyRank": 4710
  },
  {
    "Character": "韬",
    "Pinyin": "tāo",
    "Definition": "sheath, scabbard, bow case",
    "Radical": "韋",
    "RadicalIndex": "178.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5628,
    "FrequencyRank": 3652
  },
  {
    "Character": "嘀",
    "Pinyin": "dí",
    "Definition": "backbite",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3111,
    "FrequencyRank": 3299
  },
  {
    "Character": "蔫",
    "Pinyin": "niān",
    "Definition": "withered, faded, decayed; calm",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5643,
    "FrequencyRank": 4737
  },
  {
    "Character": "箐",
    "Pinyin": "qìng",
    "Definition": "to draw a bamboo bow or crossbow",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5742,
    "FrequencyRank": 6728
  },
  {
    "Character": "墉",
    "Pinyin": "yōng",
    "Definition": "wall; fortified wall; small wall",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5639,
    "FrequencyRank": 5754
  },
  {
    "Character": "鲗",
    "Pinyin": "zéi",
    "Definition": "cuttlefish",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7703,
    "FrequencyRank": 7910
  },
  {
    "Character": "锽",
    "Pinyin": "huáng",
    "Definition": "weapon",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7681,
    "FrequencyRank": 7316
  },
  {
    "Character": "镂",
    "Pinyin": "lòu",
    "Definition": "carve, inlay, engrave, tattoo",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5740,
    "FrequencyRank": 4301
  },
  {
    "Character": "鲕",
    "Pinyin": "ér",
    "Definition": "roe",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7700,
    "FrequencyRank": 6799
  },
  {
    "Character": "魃",
    "Pinyin": "bá",
    "Definition": "drought demon",
    "Radical": "鬼",
    "RadicalIndex": "194.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5761,
    "FrequencyRank": 5262
  },
  {
    "Character": "褕",
    "Pinyin": "yú",
    "Definition": "shirt",
    "Radical": "衣",
    "RadicalIndex": "145.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7738,
    "FrequencyRank": undefined
  },
  {
    "Character": "𡐓",
    "Pinyin": "kāng",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7635,
    "FrequencyRank": undefined
  },
  {
    "Character": "韶",
    "Pinyin": "sháo",
    "Definition": "music of the emperor Shun; beautiful",
    "Radical": "音",
    "RadicalIndex": "180.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5786,
    "FrequencyRank": 3458
  },
  {
    "Character": "耤",
    "Pinyin": "jí",
    "Definition": "plough",
    "Radical": "耒",
    "RadicalIndex": "127.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7620,
    "FrequencyRank": undefined
  },
  {
    "Character": "慬",
    "Pinyin": "qín",
    "Definition": "brave; cautious; sad",
    "Radical": "心",
    "RadicalIndex": "61.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7733,
    "FrequencyRank": undefined
  },
  {
    "Character": "臧",
    "Pinyin": "zāng",
    "Definition": "good, right, generous; command",
    "Radical": "臣",
    "RadicalIndex": "131.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5681,
    "FrequencyRank": 4304
  },
  {
    "Character": "漕",
    "Pinyin": "cáo",
    "Definition": "transport by water; canal transportation",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5801,
    "FrequencyRank": 4375
  },
  {
    "Character": "赘",
    "Pinyin": "zhuì",
    "Definition": "unnecessary, superfluous",
    "Radical": "貝",
    "RadicalIndex": "154.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3052,
    "FrequencyRank": 3574
  },
  {
    "Character": "鲞",
    "Pinyin": "xiǎng",
    "Definition": "dried fish",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5791,
    "FrequencyRank": 6806
  },
  {
    "Character": "墐",
    "Pinyin": "jìn",
    "Definition": "to build with soil, plaster over with mud",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7631,
    "FrequencyRank": undefined
  },
  {
    "Character": "嘡",
    "Pinyin": "tāng",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5716,
    "FrequencyRank": undefined
  },
  {
    "Character": "骢",
    "Pinyin": "cōng",
    "Definition": "horse with a bluish white color",
    "Radical": "馬",
    "RadicalIndex": "187.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5846,
    "FrequencyRank": 6675
  },
  {
    "Character": "箔",
    "Pinyin": "bó",
    "Definition": "reed screen; frame for growing silkworms",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5750,
    "FrequencyRank": 4366
  },
  {
    "Character": "骠",
    "Pinyin": "biāo",
    "Definition": "charger, steed; swift, valiant",
    "Radical": "馬",
    "RadicalIndex": "187.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5841,
    "FrequencyRank": 5321
  },
  {
    "Character": "漖",
    "Pinyin": "jiào",
    "Definition": "(Cant.) a branching river (used in toponyms)",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7723,
    "FrequencyRank": undefined
  },
  {
    "Character": "褐",
    "Pinyin": "hè",
    "Definition": "coarse woolen cloth; dull, dark",
    "Radical": "衣",
    "RadicalIndex": "145.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3179,
    "FrequencyRank": 2654
  },
  {
    "Character": "撖",
    "Pinyin": "hàn",
    "Definition": undefined,
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7637,
    "FrequencyRank": undefined
  },
  {
    "Character": "僳",
    "Pinyin": "sù",
    "Definition": "minority name",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5757,
    "FrequencyRank": 5386
  },
  {
    "Character": "禚",
    "Pinyin": "zhuó",
    "Definition": "place name",
    "Radical": "示",
    "RadicalIndex": "113.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7740,
    "FrequencyRank": undefined
  },
  {
    "Character": "墕",
    "Pinyin": "yàn",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7629,
    "FrequencyRank": undefined
  },
  {
    "Character": "窨",
    "Pinyin": "xūn",
    "Definition": "a cellar, a store-room",
    "Radical": "穴",
    "RadicalIndex": "116.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5815,
    "FrequencyRank": 5807
  },
  {
    "Character": "缨",
    "Pinyin": "yīng",
    "Definition": "a chin strap; tassel; to annoy, bother",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5845,
    "FrequencyRank": 4136
  },
  {
    "Character": "旖",
    "Pinyin": "yǐ",
    "Definition": "romantic; tender; charming",
    "Radical": "方",
    "RadicalIndex": "70.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5787,
    "FrequencyRank": 4845
  },
  {
    "Character": "墁",
    "Pinyin": "màn",
    "Definition": "to plaster; to pave",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5632,
    "FrequencyRank": 6678
  },
  {
    "Character": "窬",
    "Pinyin": "yú",
    "Definition": "a small door or window; a hole in the wall to cut through a wall",
    "Radical": "穴",
    "RadicalIndex": "116.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7734,
    "FrequencyRank": 6076
  },
  {
    "Character": "槊",
    "Pinyin": "shuò",
    "Definition": "spear, lance",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5796,
    "FrequencyRank": 5300
  },
  {
    "Character": "鄫",
    "Pinyin": "zēng",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7720,
    "FrequencyRank": 8178
  },
  {
    "Character": "褪",
    "Pinyin": "tuì",
    "Definition": "strip, undress; fall off; fade",
    "Radical": "衣",
    "RadicalIndex": "145.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3180,
    "FrequencyRank": 3339
  },
  {
    "Character": "嘌",
    "Pinyin": "piào",
    "Definition": "fast, speedy",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5697,
    "FrequencyRank": 5162
  },
  {
    "Character": "碥",
    "Pinyin": "biǎn",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7665,
    "FrequencyRank": 6363
  },
  {
    "Character": "阚",
    "Pinyin": "hǎn",
    "Definition": "glance, peep; roar, growl",
    "Radical": "門",
    "RadicalIndex": "169.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5789,
    "FrequencyRank": 6176
  },
  {
    "Character": "飗",
    "Pinyin": "liú",
    "Definition": "soughing of wind; noise",
    "Radical": "風",
    "RadicalIndex": "182.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7711,
    "FrequencyRank": 7164
  },
  {
    "Character": "翟",
    "Pinyin": "dí",
    "Definition": "surname; a kind of pheasant; plumes",
    "Radical": "羽",
    "RadicalIndex": "124.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5838,
    "FrequencyRank": 3919
  },
  {
    "Character": "缥",
    "Pinyin": "piāo",
    "Definition": "light blue silk; dim; misty",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5842,
    "FrequencyRank": 4460
  },
  {
    "Character": "瑢",
    "Pinyin": "róng",
    "Definition": "gem ornaments for belts",
    "Radical": "玉",
    "RadicalIndex": "96.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7626,
    "FrequencyRank": undefined
  },
  {
    "Character": "澉",
    "Pinyin": "gǎn",
    "Definition": "to wash name of a place",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5811,
    "FrequencyRank": 7101
  },
  {
    "Character": "蔟",
    "Pinyin": "cù",
    "Definition": "frame on which silkworms spin",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5651,
    "FrequencyRank": 7418
  },
  {
    "Character": "榴",
    "Pinyin": "liú",
    "Definition": "pomegranate",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3080,
    "FrequencyRank": 2644
  },
  {
    "Character": "鹜",
    "Pinyin": "wù",
    "Definition": "duck",
    "Radical": "鳥",
    "RadicalIndex": "196.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5840,
    "FrequencyRank": 5093
  },
  {
    "Character": "踅",
    "Pinyin": "xué",
    "Definition": "to walk around; turn back midway",
    "Radical": "足",
    "RadicalIndex": "157.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5637,
    "FrequencyRank": 5268
  },
  {
    "Character": "镀",
    "Pinyin": "dù",
    "Definition": "plate, coat, gild",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3115,
    "FrequencyRank": 3931
  },
  {
    "Character": "榕",
    "Pinyin": "róng",
    "Definition": "banyan tree",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3083,
    "FrequencyRank": 4127
  },
  {
    "Character": "瑧",
    "Pinyin": "zhēn",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7621,
    "FrequencyRank": undefined
  },
  {
    "Character": "霁",
    "Pinyin": "jì",
    "Definition": "to clear up after rain; to cease be angry",
    "Radical": "雨",
    "RadicalIndex": "173.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5685,
    "FrequencyRank": 4698
  },
  {
    "Character": "漪",
    "Pinyin": "yī",
    "Definition": "ripples on water; swirling",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5807,
    "FrequencyRank": 3676
  },
  {
    "Character": "熥",
    "Pinyin": "tēng",
    "Definition": "heat up by steaming",
    "Radical": "火",
    "RadicalIndex": "86.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5799,
    "FrequencyRank": undefined
  },
  {
    "Character": "蔷",
    "Pinyin": "qiáng",
    "Definition": "rose",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5644,
    "FrequencyRank": 3941
  },
  {
    "Character": "獒",
    "Pinyin": "áo",
    "Definition": "mastiff, large fierce dog",
    "Radical": "犬",
    "RadicalIndex": "94.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5624,
    "FrequencyRank": 6263
  },
  {
    "Character": "幛",
    "Pinyin": "zhàng",
    "Definition": "scroll of silk or cloth presente",
    "Radical": "巾",
    "RadicalIndex": "50.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5727,
    "FrequencyRank": 5932
  },
  {
    "Character": "瘙",
    "Pinyin": "sào",
    "Definition": "itch",
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5784,
    "FrequencyRank": 4657
  },
  {
    "Character": "嘁",
    "Pinyin": "qī",
    "Definition": "to be grieved; ashamed; used to imitate sounds",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5698,
    "FrequencyRank": 4513
  },
  {
    "Character": "嶂",
    "Pinyin": "zhàng",
    "Definition": "cliff; mountain barrier",
    "Radical": "山",
    "RadicalIndex": "46.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5726,
    "FrequencyRank": 4869
  },
  {
    "Character": "耥",
    "Pinyin": "tāng",
    "Definition": "farm tool",
    "Radical": "耒",
    "RadicalIndex": "127.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5620,
    "FrequencyRank": 8309
  },
  {
    "Character": "僮",
    "Pinyin": "tóng",
    "Definition": "page, boy servant",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5760,
    "FrequencyRank": 4523
  },
  {
    "Character": "蔊",
    "Pinyin": "hǎn",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7645,
    "FrequencyRank": 8790
  },
  {
    "Character": "劂",
    "Pinyin": "jué",
    "Definition": "chisel for engraving; engrave",
    "Radical": "刀",
    "RadicalIndex": "18.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7666,
    "FrequencyRank": 6368
  },
  {
    "Character": "嫘",
    "Pinyin": "léi",
    "Definition": "surname",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5835,
    "FrequencyRank": 5740
  },
  {
    "Character": "僰",
    "Pinyin": "bó",
    "Definition": "ancient aboriginal tribe",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5671,
    "FrequencyRank": undefined
  },
  {
    "Character": "嫣",
    "Pinyin": "yān",
    "Definition": "charming, fascinating; gay",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5830,
    "FrequencyRank": 3520
  },
  {
    "Character": "潴",
    "Pinyin": "zhū",
    "Definition": "pond; a pool",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5806,
    "FrequencyRank": 5367
  },
  {
    "Character": "僖",
    "Pinyin": "xī",
    "Definition": "joy, gladness, delight; surname",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5755,
    "FrequencyRank": 4739
  },
  {
    "Character": "鲝",
    "Pinyin": "zhǎ",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7719,
    "FrequencyRank": 7304
  },
  {
    "Character": "榰",
    "Pinyin": "zhī",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7647,
    "FrequencyRank": undefined
  },
  {
    "Character": "漻",
    "Pinyin": "liáo",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7732,
    "FrequencyRank": undefined
  },
  {
    "Character": "寤",
    "Pinyin": "wù",
    "Definition": "few, scarce; empty, deserted",
    "Radical": "宀",
    "RadicalIndex": "40.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5816,
    "FrequencyRank": 5537
  },
  {
    "Character": "瘗",
    "Pinyin": "yì",
    "Definition": "bury, inter",
    "Radical": "疒",
    "RadicalIndex": "104.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7716,
    "FrequencyRank": 6611
  },
  {
    "Character": "箧",
    "Pinyin": "qiè",
    "Definition": "rattan box, suitcase, or case",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5744,
    "FrequencyRank": 5474
  },
  {
    "Character": "箢",
    "Pinyin": "yuān",
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5752,
    "FrequencyRank": 7450
  },
  {
    "Character": "箩",
    "Pinyin": "luó",
    "Definition": "bamboo basket",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3122,
    "FrequencyRank": 4446
  },
  {
    "Character": "劁",
    "Pinyin": "qiāo",
    "Definition": undefined,
    "Radical": "刀",
    "RadicalIndex": "18.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5759,
    "FrequencyRank": 6471
  },
  {
    "Character": "蓼",
    "Pinyin": "liǎo",
    "Definition": "smartweed, polygonum",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5659,
    "FrequencyRank": 5931
  },
  {
    "Character": "嫖",
    "Pinyin": "piáo",
    "Definition": "patronize prostitutes, frequent",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5832,
    "FrequencyRank": 3463
  },
  {
    "Character": "碶",
    "Pinyin": "qì",
    "Definition": "a flood-gate, a water-gate",
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7659,
    "FrequencyRank": 6293
  },
  {
    "Character": "箨",
    "Pinyin": "tuò",
    "Definition": "bamboo sheath, bamboo shoots",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7690,
    "FrequencyRank": 6343
  },
  {
    "Character": "麽",
    "Pinyin": "mó",
    "Definition": "interrogative final particle; insignificant, small, tiny",
    "Radical": "麻",
    "RadicalIndex": "200.3",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5780,
    "FrequencyRank": 2725
  },
  {
    "Character": "璈",
    "Pinyin": "áo",
    "Definition": "ancient musical instrument",
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5621,
    "FrequencyRank": undefined
  },
  {
    "Character": "箫",
    "Pinyin": "xiāo",
    "Definition": "bamboo flute",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3124,
    "FrequencyRank": 3634
  },
  {
    "Character": "霆",
    "Pinyin": "tíng",
    "Definition": "a sudden peal of thunder",
    "Radical": "雨",
    "RadicalIndex": "173.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5684,
    "FrequencyRank": 2571
  },
  {
    "Character": "槚",
    "Pinyin": "jiǎ",
    "Definition": "small evergreen shrub",
    "Radical": "木",
    "RadicalIndex": "75.13",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7649,
    "FrequencyRank": undefined
  },
  {
    "Character": "镄",
    "Pinyin": "fèi",
    "Definition": "fermium",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7686,
    "FrequencyRank": 6098
  },
  {
    "Character": "褡",
    "Pinyin": "dā",
    "Definition": "girdle; loincloth; pouch, bag",
    "Radical": "衣",
    "RadicalIndex": "145.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5819,
    "FrequencyRank": 4887
  },
  {
    "Character": "嘧",
    "Pinyin": "mì",
    "Definition": "pyrimidine",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5722,
    "FrequencyRank": 5121
  },
  {
    "Character": "骶",
    "Pinyin": "dǐ",
    "Definition": "coccyx",
    "Radical": "骨",
    "RadicalIndex": "188.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5731,
    "FrequencyRank": 4811
  },
  {
    "Character": "碱",
    "Pinyin": "jiǎn",
    "Definition": "alkaline, alkali, lye, salt",
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3091,
    "FrequencyRank": 2872
  },
  {
    "Character": "瘊",
    "Pinyin": "hóu",
    "Definition": "warts, pimples",
    "Radical": "疒",
    "RadicalIndex": "104.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5782,
    "FrequencyRank": 7456
  },
  {
    "Character": "缧",
    "Pinyin": "léi",
    "Definition": "a chain or rope used bind criminals",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5844,
    "FrequencyRank": 6075
  },
  {
    "Character": "綮",
    "Pinyin": "qǐ",
    "Definition": "embroidered banner",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5817,
    "FrequencyRank": 6685
  },
  {
    "Character": "辗",
    "Pinyin": "niǎn",
    "Definition": "turn over, roll",
    "Radical": "車",
    "RadicalIndex": "159.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3097,
    "FrequencyRank": 3535
  },
  {
    "Character": "瘘",
    "Pinyin": "lòu",
    "Definition": "fistula, sore, ulcer, goiter",
    "Radical": "疒",
    "RadicalIndex": "104.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5783,
    "FrequencyRank": 5324
  },
  {
    "Character": "瑶",
    "Pinyin": "yáo",
    "Definition": "precious jade",
    "Radical": "玉",
    "RadicalIndex": "96.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5622,
    "FrequencyRank": 2900
  },
  {
    "Character": "锿",
    "Pinyin": "āi",
    "Definition": "einsteinium (Es)",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7684,
    "FrequencyRank": 5941
  },
  {
    "Character": "蜿",
    "Pinyin": "wān",
    "Definition": "creep, crawl",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5712,
    "FrequencyRank": 3914
  },
  {
    "Character": "獍",
    "Pinyin": "jìng",
    "Definition": "a mythical animal that eats its mother when it is born; Manchurian tiger",
    "Radical": "犬",
    "RadicalIndex": "94.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7710,
    "FrequencyRank": 6004
  },
  {
    "Character": "㽏",
    "Pinyin": "gàn",
    "Definition": undefined,
    "Radical": "甘",
    "RadicalIndex": "99.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7729,
    "FrequencyRank": undefined
  },
  {
    "Character": "榑",
    "Pinyin": "fú",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7648,
    "FrequencyRank": undefined
  },
  {
    "Character": "箦",
    "Pinyin": "zé",
    "Definition": "bed mat; mat",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5743,
    "FrequencyRank": 7036
  },
  {
    "Character": "墚",
    "Pinyin": "liáng",
    "Definition": "mountain range",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7636,
    "FrequencyRank": 8300
  },
  {
    "Character": "缪",
    "Pinyin": "móu",
    "Definition": "wind around, bind; prepare",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5847,
    "FrequencyRank": 3286
  },
  {
    "Character": "箪",
    "Pinyin": "dān",
    "Definition": "small bamboo basket for holding",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5749,
    "FrequencyRank": 5890
  },
  {
    "Character": "睽",
    "Pinyin": "kuí",
    "Definition": "staring",
    "Radical": "目",
    "RadicalIndex": "109.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5694,
    "FrequencyRank": 3958
  },
  {
    "Character": "嫪",
    "Pinyin": "lào",
    "Definition": "hanker",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7745,
    "FrequencyRank": undefined
  },
  {
    "Character": "嘞",
    "Pinyin": "lei",
    "Definition": "(Cant.) final particle used for polite refusal",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5695,
    "FrequencyRank": 5655
  },
  {
    "Character": "摞",
    "Pinyin": "luò",
    "Definition": "to pile up",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5634,
    "FrequencyRank": 4299
  },
  {
    "Character": "鼐",
    "Pinyin": "nài",
    "Definition": "incense tripod",
    "Radical": "鼎",
    "RadicalIndex": "206.2",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5837,
    "FrequencyRank": 4861
  },
  {
    "Character": "斡",
    "Pinyin": "wò",
    "Definition": "revolve, rotate, turn",
    "Radical": "斗",
    "RadicalIndex": "68.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5657,
    "FrequencyRank": 4117
  },
  {
    "Character": "螂",
    "Pinyin": "láng",
    "Definition": "mantis, dung beetle",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5713,
    "FrequencyRank": 3594
  },
  {
    "Character": "蜷",
    "Pinyin": "quán",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5711,
    "FrequencyRank": 3673
  },
  {
    "Character": "墒",
    "Pinyin": "shāng",
    "Definition": "wet tilth",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5640,
    "FrequencyRank": 4440
  },
  {
    "Character": "犒",
    "Pinyin": "kào",
    "Definition": "entertain victorious soldiers",
    "Radical": "牛",
    "RadicalIndex": "93.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5741,
    "FrequencyRank": 4699
  },
  {
    "Character": "镁",
    "Pinyin": "měi",
    "Definition": "magnesium",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5739,
    "FrequencyRank": 4023
  },
  {
    "Character": "摽",
    "Pinyin": "biāo",
    "Definition": "throw out; push out; strike",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5631,
    "FrequencyRank": undefined
  },
  {
    "Character": "嫡",
    "Pinyin": "dí",
    "Definition": "legal wife, child of legal wife",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5836,
    "FrequencyRank": 3746
  },
  {
    "Character": "碡",
    "Pinyin": "dú",
    "Definition": "stone roller used to level fields",
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5676,
    "FrequencyRank": 5540
  },
  {
    "Character": "裴",
    "Pinyin": "péi",
    "Definition": "surname; look of a flowing gown",
    "Radical": "衣",
    "RadicalIndex": "145.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5688,
    "FrequencyRank": 3122
  },
  {
    "Character": "锴",
    "Pinyin": "kǎi",
    "Definition": "high quality iron",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5734,
    "FrequencyRank": 4916
  },
  {
    "Character": "漱",
    "Pinyin": "shù",
    "Definition": "gargle, rinse; wash, scour",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3162,
    "FrequencyRank": 3402
  },
  {
    "Character": "慝",
    "Pinyin": "tè",
    "Definition": "do evil in secret; evil, vice",
    "Radical": "心",
    "RadicalIndex": "61.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5626,
    "FrequencyRank": 6523
  },
  {
    "Character": "箬",
    "Pinyin": "ruò",
    "Definition": "the cuticle of the bamboo a broad-leaved bamboo",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5747,
    "FrequencyRank": 6700
  },
  {
    "Character": "獐",
    "Pinyin": "zhāng",
    "Definition": "roebuck, hornless river deer",
    "Radical": "犬",
    "RadicalIndex": "94.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5773,
    "FrequencyRank": 5379
  },
  {
    "Character": "搴",
    "Pinyin": "qiān",
    "Definition": "extract; seize; pluck up",
    "Radical": "手",
    "RadicalIndex": "64.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5814,
    "FrequencyRank": 6012
  },
  {
    "Character": "膈",
    "Pinyin": "gé",
    "Definition": "diaphragm",
    "Radical": "肉",
    "RadicalIndex": "130.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5766,
    "FrequencyRank": 5398
  },
  {
    "Character": "屣",
    "Pinyin": "xǐ",
    "Definition": "straw sandals or slippers that have no heel-backs",
    "Radical": "尸",
    "RadicalIndex": "44.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5828,
    "FrequencyRank": 6262
  },
  {
    "Character": "鞁",
    "Pinyin": "bèi",
    "Definition": undefined,
    "Radical": "革",
    "RadicalIndex": "177.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7640,
    "FrequencyRank": undefined
  },
  {
    "Character": "鄯",
    "Pinyin": "shàn",
    "Definition": "district in Gansu during the Tang dynasty",
    "Radical": "邑",
    "RadicalIndex": "163.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5790,
    "FrequencyRank": 5199
  },
  {
    "Character": "熘",
    "Pinyin": "liū",
    "Definition": "to steam",
    "Radical": "火",
    "RadicalIndex": "86.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5798,
    "FrequencyRank": 5417
  },
  {
    "Character": "熇",
    "Pinyin": "hè",
    "Definition": "bake",
    "Radical": "火",
    "RadicalIndex": "86.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7721,
    "FrequencyRank": undefined
  },
  {
    "Character": "㬎",
    "Pinyin": "xiǎn",
    "Definition": "(an ancient form of 顯) motes in a sunbeam, bright, fibrous, to manifest; to display, to be illustrious, evident, to seem; to appear, cocoons; chrysalis, will not have a pleasant conversation",
    "Radical": "日",
    "RadicalIndex": "72.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7672,
    "FrequencyRank": undefined
  },
  {
    "Character": "榛",
    "Pinyin": "zhēn",
    "Definition": "hazelnut; thicket, underbrush",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5660,
    "FrequencyRank": 4595
  },
  {
    "Character": "褓",
    "Pinyin": "bǎo",
    "Definition": "swaddling cloth; infancy",
    "Radical": "衣",
    "RadicalIndex": "145.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5821,
    "FrequencyRank": 4966
  },
  {
    "Character": "嫭",
    "Pinyin": "hù",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7743,
    "FrequencyRank": undefined
  },
  {
    "Character": "褛",
    "Pinyin": "lǚ",
    "Definition": "lapel, collar; tattered, threadbare",
    "Radical": "衣",
    "RadicalIndex": "145.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5822,
    "FrequencyRank": 4250
  },
  {
    "Character": "蜘",
    "Pinyin": "zhī",
    "Definition": "spider",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3108,
    "FrequencyRank": 3043
  },
  {
    "Character": "谮",
    "Pinyin": "zèn",
    "Definition": "to slander",
    "Radical": "言",
    "RadicalIndex": "149.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5818,
    "FrequencyRank": 6361
  },
  {
    "Character": "箅",
    "Pinyin": "bì",
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5748,
    "FrequencyRank": 7449
  },
  {
    "Character": "䃎",
    "Pinyin": "zhà",
    "Definition": "name of a place (usually to be used in naming a place) (interchangeable 砟) small piece of coal",
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7660,
    "FrequencyRank": undefined
  },
  {
    "Character": "碲",
    "Pinyin": "dì",
    "Definition": "tellurium",
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5679,
    "FrequencyRank": 6376
  },
  {
    "Character": "廙",
    "Pinyin": "yì",
    "Definition": undefined,
    "Radical": "广",
    "RadicalIndex": "53.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7715,
    "FrequencyRank": undefined
  },
  {
    "Character": "嫕",
    "Pinyin": "yì",
    "Definition": "compliant, yielding; easy-going",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7742,
    "FrequencyRank": undefined
  },
  {
    "Character": "鹕",
    "Pinyin": "hú",
    "Definition": "pelican",
    "Radical": "鳥",
    "RadicalIndex": "196.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5658,
    "FrequencyRank": 5295
  },
  {
    "Character": "榫",
    "Pinyin": "sǔn",
    "Definition": "mortise and tenon; fit into",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5663,
    "FrequencyRank": 4910
  },
  {
    "Character": "魆",
    "Pinyin": "xū",
    "Definition": "black",
    "Radical": "鬼",
    "RadicalIndex": "194.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5762,
    "FrequencyRank": 6184
  },
  {
    "Character": "瘕",
    "Pinyin": "jiǎ",
    "Definition": "asthma; disese of the bowels",
    "Radical": "疒",
    "RadicalIndex": "104.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7718,
    "FrequencyRank": 7041
  },
  {
    "Character": "暨",
    "Pinyin": "jì",
    "Definition": "and; attain, reach; confines",
    "Radical": "日",
    "RadicalIndex": "72.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5827,
    "FrequencyRank": 3572
  },
  {
    "Character": "夤",
    "Pinyin": "yín",
    "Definition": "distant place; remote; deep",
    "Radical": "夕",
    "RadicalIndex": "36.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5776,
    "FrequencyRank": 5035
  },
  {
    "Character": "殡",
    "Pinyin": "bìn",
    "Definition": "encoffin; embalm; funeral",
    "Radical": "歹",
    "RadicalIndex": "78.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5683,
    "FrequencyRank": 3965
  },
  {
    "Character": "榧",
    "Pinyin": "fěi",
    "Definition": "type of yew",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5661,
    "FrequencyRank": 5250
  },
  {
    "Character": "膑",
    "Pinyin": "bìn",
    "Definition": "the kneecap",
    "Radical": "肉",
    "RadicalIndex": "130.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5767,
    "FrequencyRank": 4579
  },
  {
    "Character": "锸",
    "Pinyin": "chā",
    "Definition": "spade, shovel; marking pin",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5737,
    "FrequencyRank": 5426
  },
  {
    "Character": "蔗",
    "Pinyin": "zhè",
    "Definition": "sugar cane",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3072,
    "FrequencyRank": 3945
  },
  {
    "Character": "鲒",
    "Pinyin": "jié",
    "Definition": "clam; oyster",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7699,
    "FrequencyRank": 7583
  },
  {
    "Character": "蜞",
    "Pinyin": "qí",
    "Definition": "a kind of crab; worm, leech",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5704,
    "FrequencyRank": 7070
  },
  {
    "Character": "𪤗",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7638,
    "FrequencyRank": undefined
  },
  {
    "Character": "鹙",
    "Pinyin": "qiū",
    "Definition": "large waterfowl with naked head; Garrulus glandarius",
    "Radical": "鳥",
    "RadicalIndex": "196.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7689,
    "FrequencyRank": 9206
  },
  {
    "Character": "瘌",
    "Pinyin": "là",
    "Definition": "severe; poisonous; dangerous; itch and other skin diseases",
    "Radical": "疒",
    "RadicalIndex": "104.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5781,
    "FrequencyRank": 6264
  },
  {
    "Character": "箖",
    "Pinyin": "lín",
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7691,
    "FrequencyRank": 7172
  },
  {
    "Character": "罱",
    "Pinyin": "lǎn",
    "Definition": "fishing net",
    "Radical": "网",
    "RadicalIndex": "122.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5724,
    "FrequencyRank": 5761
  },
  {
    "Character": "瑱",
    "Pinyin": "zhèn",
    "Definition": "a gem used as ear plug; a jade earring",
    "Radical": "玉",
    "RadicalIndex": "96.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7624,
    "FrequencyRank": undefined
  },
  {
    "Character": "锶",
    "Pinyin": "sī",
    "Definition": "strontium",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5735,
    "FrequencyRank": 5182
  },
  {
    "Character": "僬",
    "Pinyin": "jiāo",
    "Definition": "clever; alert in mind pigmies",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7693,
    "FrequencyRank": 6121
  },
  {
    "Character": "睿",
    "Pinyin": "ruì",
    "Definition": "shrewd, astute, clever, keen",
    "Radical": "目",
    "RadicalIndex": "109.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5692,
    "FrequencyRank": 3407
  },
  {
    "Character": "潍",
    "Pinyin": "wéi",
    "Definition": "county in Shandong province",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5812,
    "FrequencyRank": 4775
  },
  {
    "Character": "睾",
    "Pinyin": "gāo",
    "Definition": "testicle",
    "Radical": "目",
    "RadicalIndex": "109.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5763,
    "FrequencyRank": 3529
  },
  {
    "Character": "廖",
    "Pinyin": "liào",
    "Definition": "surname; name of an ancient state",
    "Radical": "广",
    "RadicalIndex": "53.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5785,
    "FrequencyRank": 2890
  },
  {
    "Character": "漳",
    "Pinyin": "zhāng",
    "Definition": "name of a river in Henan",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5809,
    "FrequencyRank": 3813
  },
  {
    "Character": "蜻",
    "Pinyin": "qīng",
    "Definition": "dragonfly",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3105,
    "FrequencyRank": 4044
  },
  {
    "Character": "䓫",
    "Pinyin": "qí",
    "Definition": "name of a mountain, in Taiwan Province",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7124,
    "FrequencyRank": undefined
  },
  {
    "Character": "𩽾",
    "Pinyin": "ān",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7708,
    "FrequencyRank": undefined
  },
  {
    "Character": "锼",
    "Pinyin": "sōu",
    "Definition": "carve",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7680,
    "FrequencyRank": 5940
  },
  {
    "Character": "榖",
    "Pinyin": "gǔ",
    "Definition": "paper mulberry",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5641,
    "FrequencyRank": undefined
  },
  {
    "Character": "嫚",
    "Pinyin": "mān",
    "Definition": "scorn, despise; be rude, affront",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5834,
    "FrequencyRank": undefined
  },
  {
    "Character": "蔡",
    "Pinyin": "cài",
    "Definition": "surname; species of tortoise",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3071,
    "FrequencyRank": 2226
  },
  {
    "Character": "酽",
    "Pinyin": "yàn",
    "Definition": "thick, strong (beverage)",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5672,
    "FrequencyRank": 6040
  },
  {
    "Character": "粿",
    "Pinyin": "guǒ",
    "Definition": "rice cake",
    "Radical": "米",
    "RadicalIndex": "119.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5792,
    "FrequencyRank": 9770
  },
  {
    "Character": "僔",
    "Pinyin": "zǔn",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7695,
    "FrequencyRank": undefined
  },
  {
    "Character": "漉",
    "Pinyin": "lù",
    "Definition": "filter, strain; wet, dripping",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5808,
    "FrequencyRank": 3658
  },
  {
    "Character": "蔈",
    "Pinyin": "biāo",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7642,
    "FrequencyRank": undefined
  },
  {
    "Character": "豨",
    "Pinyin": "xī",
    "Definition": "pig, hog",
    "Radical": "豕",
    "RadicalIndex": "152.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5682,
    "FrequencyRank": 6603
  },
  {
    "Character": "踉",
    "Pinyin": "liáng",
    "Definition": "hop, jump; hurriedly, urgently",
    "Radical": "足",
    "RadicalIndex": "157.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5703,
    "FrequencyRank": 3244
  },
  {
    "Character": "瘥",
    "Pinyin": "chài",
    "Definition": "to recover from any disease; an epidemic",
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7717,
    "FrequencyRank": 6820
  },
  {
    "Character": "儆",
    "Pinyin": "jǐng",
    "Definition": "warn; warning",
    "Radical": "人",
    "RadicalIndex": "9.13",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5756,
    "FrequencyRank": 4519
  },
  {
    "Character": "嶍",
    "Pinyin": "xí",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7676,
    "FrequencyRank": undefined
  },
  {
    "Character": "瞀",
    "Pinyin": "mào",
    "Definition": "to look at closely; nearsighted; dim; indistinct vision",
    "Radical": "目",
    "RadicalIndex": "109.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5839,
    "FrequencyRank": 6487
  },
  {
    "Character": "撄",
    "Pinyin": "yīng",
    "Definition": "oppose, offend, run counter to",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5635,
    "FrequencyRank": 6139
  },
  {
    "Character": "缫",
    "Pinyin": "sāo",
    "Definition": "draw, reel",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5848,
    "FrequencyRank": 4547
  },
  {
    "Character": "碴",
    "Pinyin": "chá",
    "Definition": "chipped edge of a container",
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5677,
    "FrequencyRank": 4321
  },
  {
    "Character": "蜢",
    "Pinyin": "měng",
    "Definition": "grasshopper",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5714,
    "FrequencyRank": 5313
  },
  {
    "Character": "嫱",
    "Pinyin": "qiáng",
    "Definition": "lady",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5831,
    "FrequencyRank": 5645
  },
  {
    "Character": "摭",
    "Pinyin": "zhí",
    "Definition": "pick up, gather",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5638,
    "FrequencyRank": 5721
  },
  {
    "Character": "摏",
    "Pinyin": "chōng",
    "Definition": "pound; strike against; ram; (Cant.) to hit with the elbow",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7628,
    "FrequencyRank": undefined
  },
  {
    "Character": "塾",
    "Pinyin": "shú",
    "Definition": "village school; private tutorage",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5779,
    "FrequencyRank": 3867
  },
  {
    "Character": "碹",
    "Pinyin": "xuàn",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7664,
    "FrequencyRank": undefined
  },
  {
    "Character": "甍",
    "Pinyin": "méng",
    "Definition": "rafters supporting roof tiles",
    "Radical": "瓦",
    "RadicalIndex": "98.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5649,
    "FrequencyRank": 7161
  },
  {
    "Character": "䓨",
    "Pinyin": "yīng",
    "Definition": "(simplified form of 罃) long-necked bottle; an earthenware jar with a small mouth and two or four ears",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7132,
    "FrequencyRank": undefined
  },
  {
    "Character": "蔻",
    "Pinyin": "kòu",
    "Definition": "肉豆蔻 nutmeg, 豆蔻 cardamon",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5655,
    "FrequencyRank": 5601
  },
  {
    "Character": "膂",
    "Pinyin": "lǚ",
    "Definition": "backbone, spinal column",
    "Radical": "肉",
    "RadicalIndex": "130.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5788,
    "FrequencyRank": 6037
  },
  {
    "Character": "觫",
    "Pinyin": "sù",
    "Definition": "to start, tremble with fear",
    "Radical": "角",
    "RadicalIndex": "148.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5774,
    "FrequencyRank": 6605
  },
  {
    "Character": "圙",
    "Pinyin": "lüè",
    "Definition": undefined,
    "Radical": "囗",
    "RadicalIndex": "31.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7677,
    "FrequencyRank": undefined
  },
  {
    "Character": "鲚",
    "Pinyin": "jì",
    "Definition": "anchovy",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5770,
    "FrequencyRank": 5915
  },
  {
    "Character": "嫠",
    "Pinyin": "lí",
    "Definition": "a widow",
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5627,
    "FrequencyRank": 5201
  },
  {
    "Character": "蓿",
    "Pinyin": "xu",
    "Definition": "clover, lucerne",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5656,
    "FrequencyRank": 4151
  },
  {
    "Character": "摹",
    "Pinyin": "mó",
    "Definition": "trace, copy, duplicate; pattern",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3068,
    "FrequencyRank": 3008
  },
  {
    "Character": "漹",
    "Pinyin": "yān",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7722,
    "FrequencyRank": undefined
  },
  {
    "Character": "榭",
    "Pinyin": "xiè",
    "Definition": "kiosk, pavilion",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5664,
    "FrequencyRank": 4333
  },
  {
    "Character": "嘣",
    "Pinyin": "bēng",
    "Definition": "syllable",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5718,
    "FrequencyRank": 5034
  },
  {
    "Character": "酶",
    "Pinyin": "méi",
    "Definition": "enzymes; (Cant.) soft",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5673,
    "FrequencyRank": 3058
  },
  {
    "Character": "劄",
    "Pinyin": "zhā",
    "Definition": "brief note; official communique",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7692,
    "FrequencyRank": undefined
  },
  {
    "Character": "蜾",
    "Pinyin": "guǒ",
    "Definition": "the solitary wasp",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7674,
    "FrequencyRank": 6691
  },
  {
    "Character": "碨",
    "Pinyin": "wèi",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7662,
    "FrequencyRank": undefined
  },
  {
    "Character": "槁",
    "Pinyin": "gǎo",
    "Definition": "wither; withered, rotten, dead",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5667,
    "FrequencyRank": 4428
  },
  {
    "Character": "跽",
    "Pinyin": "jì",
    "Definition": "to kneel for a long time, to go down on hands and knees",
    "Radical": "足",
    "RadicalIndex": "157.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7673,
    "FrequencyRank": 6249
  },
  {
    "Character": "毓",
    "Pinyin": "yù",
    "Definition": "give birth to; bring up, educate",
    "Radical": "毋",
    "RadicalIndex": "80.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5754,
    "FrequencyRank": 2986
  },
  {
    "Character": "幖",
    "Pinyin": "biāo",
    "Definition": undefined,
    "Radical": "巾",
    "RadicalIndex": "50.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7675,
    "FrequencyRank": undefined
  },
  {
    "Character": "嫜",
    "Pinyin": "zhāng",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7744,
    "FrequencyRank": 6290
  },
  {
    "Character": "窭",
    "Pinyin": "jù",
    "Definition": "poor, impoverished",
    "Radical": "穴",
    "RadicalIndex": "116.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7735,
    "FrequencyRank": 5870
  },
  {
    "Character": "鲘",
    "Pinyin": "hòu",
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7704,
    "FrequencyRank": undefined
  },
  {
    "Character": "蔹",
    "Pinyin": "liǎn",
    "Definition": "wild vine, vitis pentaphylla",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7644,
    "FrequencyRank": undefined
  },
  {
    "Character": "瑷",
    "Pinyin": "ài",
    "Definition": "fine quality jade",
    "Radical": "玉",
    "RadicalIndex": "96.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7625,
    "FrequencyRank": 5936
  },
  {
    "Character": "蔌",
    "Pinyin": "sù",
    "Definition": "vegetables",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7641,
    "FrequencyRank": 6809
  },
  {
    "Character": "粼",
    "Pinyin": "lín",
    "Definition": undefined,
    "Radical": "米",
    "RadicalIndex": "119.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5793,
    "FrequencyRank": 4286
  },
  {
    "Character": "鹚",
    "Pinyin": "cí",
    "Definition": "cormorant",
    "Radical": "鳥",
    "RadicalIndex": "196.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5797,
    "FrequencyRank": 5133
  },
  {
    "Character": "鹗",
    "Pinyin": "è",
    "Definition": "osprey, fishhawk",
    "Radical": "鳥",
    "RadicalIndex": "196.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5717,
    "FrequencyRank": 4678
  },
  {
    "Character": "罂",
    "Pinyin": "yīng",
    "Definition": "long necked jar or bottle",
    "Radical": "缶",
    "RadicalIndex": "121.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5729,
    "FrequencyRank": 4374
  },
  {
    "Character": "鹘",
    "Pinyin": "gǔ",
    "Definition": "a kind of pigeon; treron permagna",
    "Radical": "鳥",
    "RadicalIndex": "196.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5732,
    "FrequencyRank": 4800
  },
  {
    "Character": "赙",
    "Pinyin": "fù",
    "Definition": "gift of money help pay funeral",
    "Radical": "貝",
    "RadicalIndex": "154.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5728,
    "FrequencyRank": 6654
  },
  {
    "Character": "谰",
    "Pinyin": "lán",
    "Definition": "accuse falsely; slander, libel",
    "Radical": "言",
    "RadicalIndex": "149.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5825,
    "FrequencyRank": 5587
  },
  {
    "Character": "滹",
    "Pinyin": "hū",
    "Definition": "the bank of a steam",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5802,
    "FrequencyRank": 6151
  },
  {
    "Character": "榷",
    "Pinyin": "què",
    "Definition": "footbridge; toll, levy; monopoly",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5670,
    "FrequencyRank": 4380
  },
  {
    "Character": "靺",
    "Pinyin": "mò",
    "Definition": "stocking; the Tungusic tribe",
    "Radical": "革",
    "RadicalIndex": "177.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5645,
    "FrequencyRank": 7443
  },
  {
    "Character": "蜩",
    "Pinyin": "tiáo",
    "Definition": "cicada, broad locust",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5710,
    "FrequencyRank": 6754
  },
  {
    "Character": "榻",
    "Pinyin": "tà",
    "Definition": "cot, couch, bed",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5662,
    "FrequencyRank": 3033
  },
  {
    "Character": "墈",
    "Pinyin": "kàn",
    "Definition": "cliff",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7630,
    "FrequencyRank": undefined
  },
  {
    "Character": "潋",
    "Pinyin": "liàn",
    "Definition": "waves, ripples; overflow",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5805,
    "FrequencyRank": 6369
  },
  {
    "Character": "撂",
    "Pinyin": "liào",
    "Definition": "put down, put aside; drop",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5633,
    "FrequencyRank": 3807
  },
  {
    "Character": "鄱",
    "Pinyin": "pó",
    "Definition": "county and lake in Jiangxi",
    "Radical": "邑",
    "RadicalIndex": "163.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5765,
    "FrequencyRank": 4447
  },
  {
    "Character": "褊",
    "Pinyin": "biǎn",
    "Definition": "cramped, narrow, crowded; mean",
    "Radical": "衣",
    "RadicalIndex": "145.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5823,
    "FrequencyRank": 5944
  },
  {
    "Character": "廑",
    "Pinyin": "jǐn",
    "Definition": "a hut; careful",
    "Radical": "广",
    "RadicalIndex": "53.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7714,
    "FrequencyRank": 6762
  },
  {
    "Character": "蔺",
    "Pinyin": "lìn",
    "Definition": "rush used for making mats; surnam",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5652,
    "FrequencyRank": 5291
  },
  {
    "Character": "锺",
    "Pinyin": "zhōng",
    "Definition": "cup, glass, goblet; surname",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7679,
    "FrequencyRank": 4201
  },
  {
    "Character": "罴",
    "Pinyin": "pí",
    "Definition": "brown bear, ursus arctos",
    "Radical": "网",
    "RadicalIndex": "122.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5723,
    "FrequencyRank": 5639
  },
  {
    "Character": "疐",
    "Pinyin": "zhì",
    "Definition": "fall, stumble, falter; hindered",
    "Radical": "疋",
    "RadicalIndex": "103.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7653,
    "FrequencyRank": undefined
  },
  {
    "Character": "漤",
    "Pinyin": "lǎn",
    "Definition": "marinate in salt",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7725,
    "FrequencyRank": 8388
  },
  {
    "Character": "蜴",
    "Pinyin": "yì",
    "Definition": "lizard",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5708,
    "FrequencyRank": 4597
  },
  {
    "Character": "缦",
    "Pinyin": "màn",
    "Definition": "plain silk; simple, plain",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5843,
    "FrequencyRank": 5186
  },
  {
    "Character": "龈",
    "Pinyin": "kěn",
    "Definition": "gums",
    "Radical": "齒",
    "RadicalIndex": "211.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5691,
    "FrequencyRank": 3997
  },
  {
    "Character": "瑭",
    "Pinyin": "táng",
    "Definition": "a kind of jade",
    "Radical": "玉",
    "RadicalIndex": "96.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5623,
    "FrequencyRank": 5267
  },
  {
    "Character": "戬",
    "Pinyin": "jiǎn",
    "Definition": "exterminate, destroy; blessing",
    "Radical": "戈",
    "RadicalIndex": "62.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5653,
    "FrequencyRank": 5096
  },
  {
    "Character": "漶",
    "Pinyin": "huàn",
    "Definition": "indecipherable",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5804,
    "FrequencyRank": 6496
  },
  {
    "Character": "锹",
    "Pinyin": "qiāo",
    "Definition": "shovel",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3113,
    "FrequencyRank": 4137
  },
  {
    "Character": "蜮",
    "Pinyin": "yù",
    "Definition": "a fabulous creature like a turtle; a toad",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5706,
    "FrequencyRank": 5567
  },
  {
    "Character": "鲙",
    "Pinyin": "kuài",
    "Definition": "minced fish; hash",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7705,
    "FrequencyRank": 7151
  },
  {
    "Character": "漩",
    "Pinyin": "xuán",
    "Definition": "eddy, whirlpool",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5810,
    "FrequencyRank": 3706
  },
  {
    "Character": "㙦",
    "Pinyin": "xié",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7698,
    "FrequencyRank": undefined
  },
  {
    "Character": "龇",
    "Pinyin": "zī",
    "Definition": "to show the teeth; crooked teeth",
    "Radical": "齒",
    "RadicalIndex": "211.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5690,
    "FrequencyRank": 4525
  },
  {
    "Character": "𣗋",
    "Pinyin": "dǎng",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7650,
    "FrequencyRank": undefined
  },
  {
    "Character": "箕",
    "Pinyin": "jī",
    "Definition": "sieve; dust pan, garbage bag",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3120,
    "FrequencyRank": 4357
  },
  {
    "Character": "谭",
    "Pinyin": "tán",
    "Definition": "surname",
    "Radical": "言",
    "RadicalIndex": "149.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3177,
    "FrequencyRank": 2145
  },
  {
    "Character": "靼",
    "Pinyin": "dá",
    "Definition": "tartars",
    "Radical": "革",
    "RadicalIndex": "177.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5646,
    "FrequencyRank": 4192
  },
  {
    "Character": "銮",
    "Pinyin": "luán",
    "Definition": "bells hung on horse; bells hung",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5778,
    "FrequencyRank": 4211
  },
  {
    "Character": "銎",
    "Pinyin": "qióng",
    "Definition": "eyehole to hang an axe by",
    "Radical": "金",
    "RadicalIndex": "167.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7634,
    "FrequencyRank": 5842
  },
  {
    "Character": "靽",
    "Pinyin": "bàn",
    "Definition": undefined,
    "Radical": "革",
    "RadicalIndex": "177.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7639,
    "FrequencyRank": undefined
  },
  {
    "Character": "䣘",
    "Pinyin": "táng",
    "Definition": "(ancient form of 堂) a hall, an office; a reception room, a court of justice",
    "Radical": "邑",
    "RadicalIndex": "163.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7516,
    "FrequencyRank": undefined
  },
  {
    "Character": "糁",
    "Pinyin": "sǎn",
    "Definition": "a grain of rice; rice gruel mixed with meat",
    "Radical": "米",
    "RadicalIndex": "119.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5795,
    "FrequencyRank": 5561
  },
  {
    "Character": "漯",
    "Pinyin": "luò",
    "Definition": "river in northern Shandong",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5803,
    "FrequencyRank": 6054
  },
  {
    "Character": "鲔",
    "Pinyin": "wěi",
    "Definition": "kind of sturgeon, tuna",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5769,
    "FrequencyRank": 6069
  },
  {
    "Character": "碣",
    "Pinyin": "jié",
    "Definition": "stone tablet",
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5678,
    "FrequencyRank": 4779
  },
  {
    "Character": "酴",
    "Pinyin": "tú",
    "Definition": "leaven, yeast; wine",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7658,
    "FrequencyRank": 7422
  },
  {
    "Character": "潆",
    "Pinyin": "yíng",
    "Definition": "tiny stream; swirl around",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7724,
    "FrequencyRank": 5088
  },
  {
    "Character": "鞅",
    "Pinyin": "yāng",
    "Definition": undefined,
    "Radical": "革",
    "RadicalIndex": "177.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5647,
    "FrequencyRank": 3412
  },
  {
    "Character": "僭",
    "Pinyin": "jiàn",
    "Definition": "assume, usurp",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5758,
    "FrequencyRank": 3820
  },
  {
    "Character": "墘",
    "Pinyin": "qián",
    "Definition": "(Cant.) halfway",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7632,
    "FrequencyRank": undefined
  },
  {
    "Character": "镃",
    "Pinyin": "zī",
    "Definition": "hoe; mattock",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7685,
    "FrequencyRank": 8705
  },
  {
    "Character": "慵",
    "Pinyin": "yōng",
    "Definition": "indolent, easy-going, lazy",
    "Radical": "心",
    "RadicalIndex": "61.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5813,
    "FrequencyRank": 4772
  },
  {
    "Character": "禛",
    "Pinyin": "zhēn",
    "Definition": "to receive blessings in a sincere spirit",
    "Radical": "示",
    "RadicalIndex": "113.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7739,
    "FrequencyRank": 6845
  },
  {
    "Character": "鲛",
    "Pinyin": "jiāo",
    "Definition": "shark",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5771,
    "FrequencyRank": 5194
  },
  {
    "Character": "箍",
    "Pinyin": "gū",
    "Definition": "hoop; bind, surround",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5745,
    "FrequencyRank": 3764
  },
  {
    "Character": "箜",
    "Pinyin": "kōng",
    "Definition": "ancient string music instrument",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5751,
    "FrequencyRank": 6683
  },
  {
    "Character": "隩",
    "Pinyin": "ào",
    "Definition": "profound, subtle; warm",
    "Radical": "阜",
    "RadicalIndex": "170.13",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7741,
    "FrequencyRank": 8936
  },
  {
    "Character": "嘎",
    "Pinyin": "gā",
    "Definition": "sound of laughter; bad, malevolent",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5699,
    "FrequencyRank": 2852
  },
  {
    "Character": "酾",
    "Pinyin": "shāi",
    "Definition": "strain",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7656,
    "FrequencyRank": 7780
  },
  {
    "Character": "蔸",
    "Pinyin": "dōu",
    "Definition": "counter for plant",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5650,
    "FrequencyRank": 7306
  },
  {
    "Character": "嘤",
    "Pinyin": "yīng",
    "Definition": "seek friends; also used in onomatopoetic expressions",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5719,
    "FrequencyRank": 4224
  },
  {
    "Character": "谯",
    "Pinyin": "qiáo",
    "Definition": "tower; surname",
    "Radical": "言",
    "RadicalIndex": "149.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5824,
    "FrequencyRank": 5155
  },
  {
    "Character": "蜚",
    "Pinyin": "fēi",
    "Definition": "cockroach",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5687,
    "FrequencyRank": 4632
  },
  {
    "Character": "鲑",
    "Pinyin": "guī",
    "Definition": "salmon; spheroides vermicularis",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5768,
    "FrequencyRank": 4340
  },
  {
    "Character": "摴",
    "Pinyin": "chū",
    "Definition": "to stretch out, to unroll; comfortable, easy (used for 舒); 摴蒲 is the name of a traditional play",
    "Radical": "手",
    "RadicalIndex": "64.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7633,
    "FrequencyRank": undefined
  },
  {
    "Character": "翥",
    "Pinyin": "zhù",
    "Definition": "to soar; to take off",
    "Radical": "羽",
    "RadicalIndex": "124.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5636,
    "FrequencyRank": 6123
  },
  {
    "Character": "夥",
    "Pinyin": "huǒ",
    "Definition": "companion; partner; assistant",
    "Radical": "夕",
    "RadicalIndex": "36.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7669,
    "FrequencyRank": 4577
  },
  {
    "Character": "褙",
    "Pinyin": "bèi",
    "Definition": "paper or cloth pasted together",
    "Radical": "衣",
    "RadicalIndex": "145.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5820,
    "FrequencyRank": 6230
  },
  {
    "Character": "翡",
    "Pinyin": "fěi",
    "Definition": "kingfisher; emerald, jade",
    "Radical": "羽",
    "RadicalIndex": "124.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5689,
    "FrequencyRank": 4135
  },
  {
    "Character": "潢",
    "Pinyin": "huáng",
    "Definition": "expanse of water, lake, pond",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5800,
    "FrequencyRank": 4551
  },
  {
    "Character": "槃",
    "Pinyin": "pán",
    "Definition": "tray; turn, rotate; search",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7697,
    "FrequencyRank": undefined
  },
  {
    "Character": "馝",
    "Pinyin": "bì",
    "Definition": "fragrance",
    "Radical": "香",
    "RadicalIndex": "186.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7688,
    "FrequencyRank": undefined
  },
  {
    "Character": "酹",
    "Pinyin": "lèi",
    "Definition": "to pour out a libation; to sprinkle",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5674,
    "FrequencyRank": 7091
  },
  {
    "Character": "蜥",
    "Pinyin": "xī",
    "Definition": "lizard",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5705,
    "FrequencyRank": 4307
  },
  {
    "Character": "靿",
    "Pinyin": "yào",
    "Definition": "the leg of a boot",
    "Radical": "革",
    "RadicalIndex": "177.5",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5648,
    "FrequencyRank": 7419
  },
  {
    "Character": "漴",
    "Pinyin": "zhuàng",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7728,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫚖",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7667,
    "FrequencyRank": undefined
  },
  {
    "Character": "酺",
    "Pinyin": "pú",
    "Definition": "drink with others; drink heavily",
    "Radical": "酉",
    "RadicalIndex": "164.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7655,
    "FrequencyRank": 6860
  },
  {
    "Character": "綦",
    "Pinyin": "qí",
    "Definition": "dark grey. variegated. superlative",
    "Radical": "糸",
    "RadicalIndex": "120.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5642,
    "FrequencyRank": 5599
  },
  {
    "Character": "𫚕",
    "Pinyin": "shī",
    "Definition": "a yellowtail (fish)",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7701,
    "FrequencyRank": undefined
  },
  {
    "Character": "僎",
    "Pinyin": "zhuàn",
    "Definition": "collect",
    "Radical": "人",
    "RadicalIndex": "9.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7696,
    "FrequencyRank": undefined
  },
  {
    "Character": "锾",
    "Pinyin": "huán",
    "Definition": "measure; money, coins",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7683,
    "FrequencyRank": 6247
  },
  {
    "Character": "嘚",
    "Pinyin": "dē",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5720,
    "FrequencyRank": undefined
  },
  {
    "Character": "瑨",
    "Pinyin": "jìn",
    "Definition": "jade look alike stone",
    "Radical": "玉",
    "RadicalIndex": "96.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7623,
    "FrequencyRank": undefined
  },
  {
    "Character": "鹖",
    "Pinyin": "hé",
    "Definition": "crossbill, species of nightingale",
    "Radical": "鳥",
    "RadicalIndex": "196.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7671,
    "FrequencyRank": 8523
  },
  {
    "Character": "嘘",
    "Pinyin": "xū",
    "Definition": "exhale; blow out; deep sigh; hiss; praise",
    "Radical": "口",
    "RadicalIndex": "30.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5715,
    "FrequencyRank": 2973
  },
  {
    "Character": "锵",
    "Pinyin": "qiāng",
    "Definition": "tinkle, clang, jingle",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5738,
    "FrequencyRank": 3953
  },
  {
    "Character": "潩",
    "Pinyin": "yì",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7726,
    "FrequencyRank": undefined
  },
  {
    "Character": "谲",
    "Pinyin": "jué",
    "Definition": "cunning, crafty, sly, wily",
    "Radical": "言",
    "RadicalIndex": "149.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5826,
    "FrequencyRank": 4819
  },
  {
    "Character": "槟",
    "Pinyin": "bīn",
    "Definition": "betel-nut, areca nut",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5668,
    "FrequencyRank": 4036
  },
  {
    "Character": "鲟",
    "Pinyin": "xún",
    "Definition": "sturgeon",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5772,
    "FrequencyRank": 4670
  },
  {
    "Character": "箸",
    "Pinyin": "zhù",
    "Definition": "chopsticks",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5746,
    "FrequencyRank": 5239
  },
  {
    "Character": "槔",
    "Pinyin": "gāo",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5665,
    "FrequencyRank": 5833
  },
  {
    "Character": "漼",
    "Pinyin": "cuǐ",
    "Definition": "having the appearance of depth",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7727,
    "FrequencyRank": undefined
  },
  {
    "Character": "蕖",
    "Pinyin": "qú",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5654,
    "FrequencyRank": 5803
  },
  {
    "Character": "䓛",
    "Pinyin": "qū",
    "Definition": "molecular formula (C18H12), to brush",
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7134,
    "FrequencyRank": undefined
  },
  {
    "Character": "榱",
    "Pinyin": "cuī",
    "Definition": "rafter",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5666,
    "FrequencyRank": 5785
  },
  {
    "Character": "蜱",
    "Pinyin": "pí",
    "Definition": "a tick, mite",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5709,
    "FrequencyRank": 6524
  },
  {
    "Character": "熔",
    "Pinyin": "róng",
    "Definition": "melt, smelt, fuse; mold",
    "Radical": "火",
    "RadicalIndex": "86.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3158,
    "FrequencyRank": 3011
  },
  {
    "Character": "斠",
    "Pinyin": "jiào",
    "Definition": "measure",
    "Radical": "斗",
    "RadicalIndex": "68.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7627,
    "FrequencyRank": undefined
  },
  {
    "Character": "箓",
    "Pinyin": "lù",
    "Definition": "book",
    "Radical": "竹",
    "RadicalIndex": "118.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5753,
    "FrequencyRank": 3864
  },
  {
    "Character": "漈",
    "Pinyin": "jì",
    "Definition": "shore; waterside",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7730,
    "FrequencyRank": undefined
  },
  {
    "Character": "觏",
    "Pinyin": "gòu",
    "Definition": "meet or see unexpectedly",
    "Radical": "見",
    "RadicalIndex": "147.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5625,
    "FrequencyRank": 6711
  },
  {
    "Character": "骡",
    "Pinyin": "luó",
    "Definition": "mule",
    "Radical": "馬",
    "RadicalIndex": "187.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3187,
    "FrequencyRank": 3377
  },
  {
    "Character": "漋",
    "Pinyin": "lóng",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7731,
    "FrequencyRank": undefined
  },
  {
    "Character": "蝉",
    "Pinyin": "chán",
    "Definition": "cicada; continuous",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 3109,
    "FrequencyRank": 3048
  },
  {
    "Character": "蝈",
    "Pinyin": "guō",
    "Definition": "small green frog; cicada",
    "Radical": "虫",
    "RadicalIndex": "142.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5707,
    "FrequencyRank": 4858
  },
  {
    "Character": "叆",
    "Pinyin": "ài",
    "Definition": "cloudy sky; dark, obscure",
    "Radical": "厶",
    "RadicalIndex": "28.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5629,
    "FrequencyRank": undefined
  },
  {
    "Character": "踌",
    "Pinyin": "chóu",
    "Definition": "hesitate, falter; smug, self-satisfied",
    "Radical": "足",
    "RadicalIndex": "157.7",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5702,
    "FrequencyRank": 3302
  },
  {
    "Character": "䁖",
    "Pinyin": "lōu",
    "Definition": "to see; to look at; to observe",
    "Radical": "目",
    "RadicalIndex": "109.9",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5693,
    "FrequencyRank": 8946
  },
  {
    "Character": "槠",
    "Pinyin": "zhū",
    "Definition": "Oak",
    "Radical": "木",
    "RadicalIndex": "75.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5669,
    "FrequencyRank": 6135
  },
  {
    "Character": "馑",
    "Pinyin": "jǐn",
    "Definition": "time of famine or crop failure",
    "Radical": "食",
    "RadicalIndex": "184.11",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5777,
    "FrequencyRank": 4473
  },
  {
    "Character": "瞍",
    "Pinyin": "sǒu",
    "Definition": "blind; no pupil in the eye",
    "Radical": "目",
    "RadicalIndex": "109.1",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7670,
    "FrequencyRank": 6705
  },
  {
    "Character": "鲖",
    "Pinyin": "tóng",
    "Definition": "snakefish",
    "Radical": "魚",
    "RadicalIndex": "195.6",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7702,
    "FrequencyRank": 9932
  },
  {
    "Character": "厮",
    "Pinyin": "sī",
    "Definition": "servant; to make a disturbance",
    "Radical": "厂",
    "RadicalIndex": "27.12",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 5675,
    "FrequencyRank": 3114
  },
  {
    "Character": "䓬",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.8",
    "StrokeCount": 14,
    "HSK": undefined,
    "GeneralStandard": 7126,
    "FrequencyRank": undefined
  },
  {
    "Character": "嶝",
    "Pinyin": "dèng",
    "Definition": "path leading up a mountain",
    "Radical": "山",
    "RadicalIndex": "46.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5942,
    "FrequencyRank": 5615
  },
  {
    "Character": "蝙",
    "Pinyin": "biān",
    "Definition": "bat",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3251,
    "FrequencyRank": 3829
  },
  {
    "Character": "墩",
    "Pinyin": "dūn",
    "Definition": "heap, mound, block of stone",
    "Radical": "土",
    "RadicalIndex": "32.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3201,
    "FrequencyRank": 3553
  },
  {
    "Character": "磉",
    "Pinyin": "sǎng",
    "Definition": "the stone base or plinth of a pillar",
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7763,
    "FrequencyRank": 6655
  },
  {
    "Character": "嶒",
    "Pinyin": "céng",
    "Definition": "towering, lofty, steep",
    "Radical": "山",
    "RadicalIndex": "46.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7784,
    "FrequencyRank": undefined
  },
  {
    "Character": "獠",
    "Pinyin": "liáo",
    "Definition": "to hunt at night by torches",
    "Radical": "犬",
    "RadicalIndex": "94.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5976,
    "FrequencyRank": 4625
  },
  {
    "Character": "豌",
    "Pinyin": "wān",
    "Definition": "peas",
    "Radical": "豆",
    "RadicalIndex": "151.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3220,
    "FrequencyRank": 4215
  },
  {
    "Character": "鲫",
    "Pinyin": "jì",
    "Definition": "Carassius auratus, crucian carp",
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3277,
    "FrequencyRank": 4198
  },
  {
    "Character": "噘",
    "Pinyin": "juē",
    "Definition": "pouting",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5910,
    "FrequencyRank": 4719
  },
  {
    "Character": "骣",
    "Pinyin": "chǎn",
    "Definition": "horse without saddle",
    "Radical": "馬",
    "RadicalIndex": "187.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6024,
    "FrequencyRank": 9914
  },
  {
    "Character": "薁",
    "Pinyin": "yù",
    "Definition": "vine",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7756,
    "FrequencyRank": undefined
  },
  {
    "Character": "鹝",
    "Pinyin": "yì",
    "Definition": "pheasant",
    "Radical": "鳥",
    "RadicalIndex": "196.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7761,
    "FrequencyRank": undefined
  },
  {
    "Character": "鲥",
    "Pinyin": "shí",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5972,
    "FrequencyRank": 6110
  },
  {
    "Character": "褟",
    "Pinyin": "tā",
    "Definition": "inner shirt or singlet",
    "Radical": "衣",
    "RadicalIndex": "145.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6012,
    "FrequencyRank": undefined
  },
  {
    "Character": "篑",
    "Pinyin": "kuì",
    "Definition": "a bamboo basket for carrying earth",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5956,
    "FrequencyRank": 5192
  },
  {
    "Character": "踝",
    "Pinyin": "huái",
    "Definition": "ankle",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5912,
    "FrequencyRank": 3893
  },
  {
    "Character": "瞢",
    "Pinyin": "méng",
    "Definition": "eyesight obscured; to feel ashamed",
    "Radical": "目",
    "RadicalIndex": "109.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5879,
    "FrequencyRank": 7031
  },
  {
    "Character": "蝎",
    "Pinyin": "xiē",
    "Definition": "scorpion",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3248,
    "FrequencyRank": 3929
  },
  {
    "Character": "蝤",
    "Pinyin": "qiú",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7775,
    "FrequencyRank": 7089
  },
  {
    "Character": "骺",
    "Pinyin": "hóu",
    "Definition": "tip of a long bone",
    "Radical": "骨",
    "RadicalIndex": "188.6",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5943,
    "FrequencyRank": 6277
  },
  {
    "Character": "撙",
    "Pinyin": "zǔn",
    "Definition": "economize; abide by, comply with",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5866,
    "FrequencyRank": 6490
  },
  {
    "Character": "嘻",
    "Pinyin": "xī",
    "Definition": "mirthful, happy; interjection",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3236,
    "FrequencyRank": 2432
  },
  {
    "Character": "鹠",
    "Pinyin": "liú",
    "Definition": "the owl",
    "Radical": "鳥",
    "RadicalIndex": "196.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7805,
    "FrequencyRank": 9349
  },
  {
    "Character": "遹",
    "Pinyin": "yù",
    "Definition": "comply with, obey; shun, avoid",
    "Radical": "辵",
    "RadicalIndex": "162.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7830,
    "FrequencyRank": 6626
  },
  {
    "Character": "鲦",
    "Pinyin": "tiáo",
    "Definition": "minnow",
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7800,
    "FrequencyRank": 6606
  },
  {
    "Character": "蝠",
    "Pinyin": "fú",
    "Definition": "kind of bat",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3247,
    "FrequencyRank": 3335
  },
  {
    "Character": "噌",
    "Pinyin": "cēng",
    "Definition": "scold, shout at",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5936,
    "FrequencyRank": 5644
  },
  {
    "Character": "麾",
    "Pinyin": "huī",
    "Definition": "a pennant, flag, banner; to signal to",
    "Radical": "麻",
    "RadicalIndex": "200.4",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5980,
    "FrequencyRank": 4353
  },
  {
    "Character": "樯",
    "Pinyin": "qiáng",
    "Definition": "mast, yard-arm, boom",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5884,
    "FrequencyRank": 5100
  },
  {
    "Character": "鹡",
    "Pinyin": "jí",
    "Definition": "wagtail",
    "Radical": "鳥",
    "RadicalIndex": "196.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7806,
    "FrequencyRank": 6932
  },
  {
    "Character": "魇",
    "Pinyin": "yǎn",
    "Definition": "nightmare, bad dreams",
    "Radical": "鬼",
    "RadicalIndex": "194.6",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5893,
    "FrequencyRank": 4294
  },
  {
    "Character": "璇",
    "Pinyin": "xuán",
    "Definition": "beautiful jade; star",
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5857,
    "FrequencyRank": 2065
  },
  {
    "Character": "澛",
    "Pinyin": "lǔ",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.15",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7817,
    "FrequencyRank": undefined
  },
  {
    "Character": "稷",
    "Pinyin": "jì",
    "Definition": "god of cereals; minister of agriculture",
    "Radical": "禾",
    "RadicalIndex": "115.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5954,
    "FrequencyRank": 3887
  },
  {
    "Character": "稹",
    "Pinyin": "zhěn",
    "Definition": undefined,
    "Radical": "禾",
    "RadicalIndex": "115.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7791,
    "FrequencyRank": 4947
  },
  {
    "Character": "幞",
    "Pinyin": "fú",
    "Definition": "turban",
    "Radical": "巾",
    "RadicalIndex": "50.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5939,
    "FrequencyRank": 6709
  },
  {
    "Character": "瞋",
    "Pinyin": "chēn",
    "Definition": "glare with anger",
    "Radical": "目",
    "RadicalIndex": "109.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5903,
    "FrequencyRank": 5846
  },
  {
    "Character": "艎",
    "Pinyin": "huáng",
    "Definition": "fast boat",
    "Radical": "舟",
    "RadicalIndex": "137.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7796,
    "FrequencyRank": 8532
  },
  {
    "Character": "儋",
    "Pinyin": "dān",
    "Definition": "a small jar; to bear a burden; a load of two",
    "Radical": "人",
    "RadicalIndex": "9.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5961,
    "FrequencyRank": 5810
  },
  {
    "Character": "儇",
    "Pinyin": "xuān",
    "Definition": "clever, nimble",
    "Radical": "人",
    "RadicalIndex": "9.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7792,
    "FrequencyRank": 6574
  },
  {
    "Character": "蝰",
    "Pinyin": "kuí",
    "Definition": "vipera russelii siamensis",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5923,
    "FrequencyRank": 6430
  },
  {
    "Character": "蕞",
    "Pinyin": "zuì",
    "Definition": "little, small, tiny; petty",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5877,
    "FrequencyRank": 6651
  },
  {
    "Character": "鞍",
    "Pinyin": "ān",
    "Definition": "saddle; any saddle-shaped object",
    "Radical": "革",
    "RadicalIndex": "177.6",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3208,
    "FrequencyRank": 3168
  },
  {
    "Character": "龊",
    "Pinyin": "chuò",
    "Definition": "narrow, small; dirty",
    "Radical": "齒",
    "RadicalIndex": "211.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5900,
    "FrequencyRank": 4430
  },
  {
    "Character": "噀",
    "Pinyin": "xùn",
    "Definition": "spirt out of the mouth",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7778,
    "FrequencyRank": undefined
  },
  {
    "Character": "耧",
    "Pinyin": "lóu",
    "Definition": "drill for sowing grain",
    "Radical": "耒",
    "RadicalIndex": "127.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5850,
    "FrequencyRank": 7069
  },
  {
    "Character": "磐",
    "Pinyin": "pán",
    "Definition": "large rock, boulder; firm",
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5963,
    "FrequencyRank": 3640
  },
  {
    "Character": "羯",
    "Pinyin": "jié",
    "Definition": "wether, castrated ram; deer skin",
    "Radical": "羊",
    "RadicalIndex": "123.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5987,
    "FrequencyRank": 4952
  },
  {
    "Character": "鹣",
    "Pinyin": "jiān",
    "Definition": "fabulous mythical bird",
    "Radical": "鳥",
    "RadicalIndex": "196.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7811,
    "FrequencyRank": 6308
  },
  {
    "Character": "嶙",
    "Pinyin": "lín",
    "Definition": "precipitous",
    "Radical": "山",
    "RadicalIndex": "46.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5941,
    "FrequencyRank": 4467
  },
  {
    "Character": "槿",
    "Pinyin": "jǐn",
    "Definition": "hibiscus",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5883,
    "FrequencyRank": 5118
  },
  {
    "Character": "餍",
    "Pinyin": "yàn",
    "Definition": undefined,
    "Radical": "食",
    "RadicalIndex": "184.6",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5894,
    "FrequencyRank": 5154
  },
  {
    "Character": "璜",
    "Pinyin": "huáng",
    "Definition": "a semicircular jade ornament used as a pendant",
    "Radical": "玉",
    "RadicalIndex": "96.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5852,
    "FrequencyRank": 4188
  },
  {
    "Character": "蕺",
    "Pinyin": "jí",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5878,
    "FrequencyRank": 6950
  },
  {
    "Character": "觭",
    "Pinyin": "jī",
    "Definition": "one turning up and one turning down",
    "Radical": "角",
    "RadicalIndex": "148.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7804,
    "FrequencyRank": 8479
  },
  {
    "Character": "麹",
    "Pinyin": "qū",
    "Definition": "yeast, leaven; surname",
    "Radical": "麥",
    "RadicalIndex": "199.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7748,
    "FrequencyRank": 7541
  },
  {
    "Character": "嘬",
    "Pinyin": "chuài",
    "Definition": "to lap; to suck",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5930,
    "FrequencyRank": 5917
  },
  {
    "Character": "遴",
    "Pinyin": "lín",
    "Definition": "select, choose; surname",
    "Radical": "辵",
    "RadicalIndex": "162.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5990,
    "FrequencyRank": 5067
  },
  {
    "Character": "蝌",
    "Pinyin": "kē",
    "Definition": "tadpole",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3249,
    "FrequencyRank": 4114
  },
  {
    "Character": "樘",
    "Pinyin": "táng",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5887,
    "FrequencyRank": 5948
  },
  {
    "Character": "髫",
    "Pinyin": "tiáo",
    "Definition": undefined,
    "Radical": "髟",
    "RadicalIndex": "190.5",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5860,
    "FrequencyRank": 6566
  },
  {
    "Character": "赭",
    "Pinyin": "zhě",
    "Definition": "reddish brown; hematite; ochre",
    "Radical": "赤",
    "RadicalIndex": "155.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5863,
    "FrequencyRank": 4789
  },
  {
    "Character": "鲪",
    "Pinyin": "jūn",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7801,
    "FrequencyRank": 7584
  },
  {
    "Character": "靥",
    "Pinyin": "yè",
    "Definition": "dimples",
    "Radical": "厂",
    "RadicalIndex": "27.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5892,
    "FrequencyRank": 4853
  },
  {
    "Character": "熜",
    "Pinyin": "cōng",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5994,
    "FrequencyRank": undefined
  },
  {
    "Character": "踮",
    "Pinyin": "diǎn",
    "Definition": "tip toe",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5916,
    "FrequencyRank": 4661
  },
  {
    "Character": "瘠",
    "Pinyin": "jí",
    "Definition": "thin, emaciated; barren",
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5985,
    "FrequencyRank": 4003
  },
  {
    "Character": "熠",
    "Pinyin": "yì",
    "Definition": "bright and sparkling",
    "Radical": "火",
    "RadicalIndex": "86.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5996,
    "FrequencyRank": 4210
  },
  {
    "Character": "噍",
    "Pinyin": "jiào",
    "Definition": "chew; eat; munch",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5932,
    "FrequencyRank": 6900
  },
  {
    "Character": "樗",
    "Pinyin": "chū",
    "Definition": "Ailanthus glandulosa or A. altissima, a kind of tree useless as timber",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5886,
    "FrequencyRank": 5525
  },
  {
    "Character": "瑬",
    "Pinyin": "liú",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7818,
    "FrequencyRank": undefined
  },
  {
    "Character": "滕",
    "Pinyin": "téng",
    "Definition": "an ancient state in Shandong province; water bursting forth",
    "Radical": "水",
    "RadicalIndex": "85.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5967,
    "FrequencyRank": 3892
  },
  {
    "Character": "噢",
    "Pinyin": "ō",
    "Definition": "moan; interjection for pain, sad",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5933,
    "FrequencyRank": 2347
  },
  {
    "Character": "蕰",
    "Pinyin": "wēn",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7757,
    "FrequencyRank": 6354
  },
  {
    "Character": "鲬",
    "Pinyin": "yǒng",
    "Definition": "a flathead (fish)",
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7802,
    "FrequencyRank": 8737
  },
  {
    "Character": "璎",
    "Pinyin": "yīng",
    "Definition": "a necklace made of precious stones",
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5854,
    "FrequencyRank": 5791
  },
  {
    "Character": "霅",
    "Pinyin": "zhà",
    "Definition": "thunder",
    "Radical": "雨",
    "RadicalIndex": "173.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7766,
    "FrequencyRank": 6863
  },
  {
    "Character": "墡",
    "Pinyin": "shàn",
    "Definition": "chalk",
    "Radical": "土",
    "RadicalIndex": "32.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7754,
    "FrequencyRank": undefined
  },
  {
    "Character": "蝼",
    "Pinyin": "lóu",
    "Definition": "a mole cricket, Gryllotalpa africana",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5928,
    "FrequencyRank": 5523
  },
  {
    "Character": "擒",
    "Pinyin": "qín",
    "Definition": "catch, capture, seize, arrest",
    "Radical": "手",
    "RadicalIndex": "64.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3200,
    "FrequencyRank": 2850
  },
  {
    "Character": "鋆",
    "Pinyin": "yún",
    "Definition": "gold; character used in personal name",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5865,
    "FrequencyRank": 3923
  },
  {
    "Character": "戭",
    "Pinyin": "yǎn",
    "Definition": "spear",
    "Radical": "戈",
    "RadicalIndex": "62.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7825,
    "FrequencyRank": undefined
  },
  {
    "Character": "璁",
    "Pinyin": "cōng",
    "Definition": "turquoise",
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5855,
    "FrequencyRank": 6903
  },
  {
    "Character": "镐",
    "Pinyin": "gǎo",
    "Definition": "stove; bright",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3257,
    "FrequencyRank": 4222
  },
  {
    "Character": "膘",
    "Pinyin": "biāo",
    "Definition": "fat; rump",
    "Radical": "肉",
    "RadicalIndex": "130.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5966,
    "FrequencyRank": 4884
  },
  {
    "Character": "鋈",
    "Pinyin": "wù",
    "Definition": "silver plating",
    "Radical": "金",
    "RadicalIndex": "167.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6002,
    "FrequencyRank": 4499
  },
  {
    "Character": "镉",
    "Pinyin": "gé",
    "Definition": "cadmium",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5947,
    "FrequencyRank": 5007
  },
  {
    "Character": "蕙",
    "Pinyin": "huì",
    "Definition": "species of fragrant orchid",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5872,
    "FrequencyRank": 4042
  },
  {
    "Character": "鲤",
    "Pinyin": "lǐ",
    "Definition": "carp",
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3276,
    "FrequencyRank": 3204
  },
  {
    "Character": "蝮",
    "Pinyin": "fù",
    "Definition": "venomous snake, viper",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5924,
    "FrequencyRank": 4419
  },
  {
    "Character": "髯",
    "Pinyin": "rán",
    "Definition": "beard; mustache",
    "Radical": "髟",
    "RadicalIndex": "190.5",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5859,
    "FrequencyRank": 4017
  },
  {
    "Character": "馔",
    "Pinyin": "zhuàn",
    "Definition": "to feed, support, provide for; food",
    "Radical": "食",
    "RadicalIndex": "184.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5979,
    "FrequencyRank": 4891
  },
  {
    "Character": "寮",
    "Pinyin": "liáo",
    "Definition": "shanty, hut, shack",
    "Radical": "宀",
    "RadicalIndex": "40.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6008,
    "FrequencyRank": 4567
  },
  {
    "Character": "㮾",
    "Pinyin": "lǎng",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7736,
    "FrequencyRank": undefined
  },
  {
    "Character": "撮",
    "Pinyin": "cuō",
    "Definition": "little bit, small amount, pinch",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3197,
    "FrequencyRank": 3485
  },
  {
    "Character": "潖",
    "Pinyin": "pá",
    "Definition": "name of a certain river",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7813,
    "FrequencyRank": undefined
  },
  {
    "Character": "缬",
    "Pinyin": "xié",
    "Definition": "patterned silk; tie knot",
    "Radical": "糸",
    "RadicalIndex": "120.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6021,
    "FrequencyRank": 5169
  },
  {
    "Character": "潵",
    "Pinyin": "sǎ",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7814,
    "FrequencyRank": undefined
  },
  {
    "Character": "踺",
    "Pinyin": "jiàn",
    "Definition": undefined,
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5918,
    "FrequencyRank": 7945
  },
  {
    "Character": "镒",
    "Pinyin": "yì",
    "Definition": "measure of weight for gold",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5951,
    "FrequencyRank": 5261
  },
  {
    "Character": "镎",
    "Pinyin": "ná",
    "Definition": "neptunium (Np)",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7788,
    "FrequencyRank": 6449
  },
  {
    "Character": "蝲",
    "Pinyin": "là",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7774,
    "FrequencyRank": undefined
  },
  {
    "Character": "暲",
    "Pinyin": "zhāng",
    "Definition": "bright; to rise (of sun)",
    "Radical": "日",
    "RadicalIndex": "72.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7768,
    "FrequencyRank": undefined
  },
  {
    "Character": "磔",
    "Pinyin": "zhé",
    "Definition": "to dismember, tear apart; downward stroke slanting right",
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5895,
    "FrequencyRank": 5597
  },
  {
    "Character": "橥",
    "Pinyin": "zhū",
    "Definition": "wooden peg, post or stick",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7803,
    "FrequencyRank": 6060
  },
  {
    "Character": "篌",
    "Pinyin": "hóu",
    "Definition": "ancient music instrument; harp",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5958,
    "FrequencyRank": 6633
  },
  {
    "Character": "谳",
    "Pinyin": "yàn",
    "Definition": "to decide, judge; a verdict, decision",
    "Radical": "言",
    "RadicalIndex": "149.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6010,
    "FrequencyRank": 6161
  },
  {
    "Character": "聩",
    "Pinyin": "kuì",
    "Definition": "deaf",
    "Radical": "耳",
    "RadicalIndex": "128.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5869,
    "FrequencyRank": 5209
  },
  {
    "Character": "懊",
    "Pinyin": "ào",
    "Definition": "vexed, worried, nervous; regret",
    "Radical": "心",
    "RadicalIndex": "61.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3302,
    "FrequencyRank": 3212
  },
  {
    "Character": "嶲",
    "Pinyin": "xī",
    "Definition": "a name of an old town in Sichuan; cuckoo; revolution of a wheel",
    "Radical": "山",
    "RadicalIndex": "46.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7780,
    "FrequencyRank": undefined
  },
  {
    "Character": "瘼",
    "Pinyin": "mò",
    "Definition": "sickness; distress",
    "Radical": "疒",
    "RadicalIndex": "104.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5983,
    "FrequencyRank": 7421
  },
  {
    "Character": "獗",
    "Pinyin": "jué",
    "Definition": "unruly, wild, violent, lawless",
    "Radical": "犬",
    "RadicalIndex": "94.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5975,
    "FrequencyRank": 3899
  },
  {
    "Character": "虢",
    "Pinyin": "guó",
    "Definition": "name of ancient feudal State in Shenxi and Hunan",
    "Radical": "虍",
    "RadicalIndex": "141.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5964,
    "FrequencyRank": 4827
  },
  {
    "Character": "糍",
    "Pinyin": "cí",
    "Definition": "food made of crushed and cooked rice",
    "Radical": "米",
    "RadicalIndex": "119.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5992,
    "FrequencyRank": 6391
  },
  {
    "Character": "赜",
    "Pinyin": "zé",
    "Definition": "abstruse, deep, profound",
    "Radical": "匚",
    "RadicalIndex": "22.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5882,
    "FrequencyRank": 4972
  },
  {
    "Character": "潏",
    "Pinyin": "yù",
    "Definition": "land reclaimed from a wetlands/river, dike/embankment/man-made island",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7821,
    "FrequencyRank": undefined
  },
  {
    "Character": "镋",
    "Pinyin": "tǎng",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.2",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7787,
    "FrequencyRank": undefined
  },
  {
    "Character": "奭",
    "Pinyin": "shì",
    "Definition": "red; anger; ire; surname",
    "Radical": "大",
    "RadicalIndex": "37.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5858,
    "FrequencyRank": undefined
  },
  {
    "Character": "瘛",
    "Pinyin": "chì",
    "Definition": undefined,
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5982,
    "FrequencyRank": 5042
  },
  {
    "Character": "霈",
    "Pinyin": "pèi",
    "Definition": "torrential rains, flow of water",
    "Radical": "雨",
    "RadicalIndex": "173.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5897,
    "FrequencyRank": 6300
  },
  {
    "Character": "撵",
    "Pinyin": "niǎn",
    "Definition": "drive away, expel, oust",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3190,
    "FrequencyRank": 3793
  },
  {
    "Character": "嘭",
    "Pinyin": "pēng",
    "Definition": "syllable; (Cant.) to chase, drive away",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5905,
    "FrequencyRank": 4443
  },
  {
    "Character": "憧",
    "Pinyin": "chōng",
    "Definition": "irresolute, indecisive; yearn for",
    "Radical": "心",
    "RadicalIndex": "61.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6007,
    "FrequencyRank": 3626
  },
  {
    "Character": "篁",
    "Pinyin": "huáng",
    "Definition": "bamboo grove; bamboo",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5957,
    "FrequencyRank": 5968
  },
  {
    "Character": "潸",
    "Pinyin": "shān",
    "Definition": "weep; tears flowing",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5999,
    "FrequencyRank": 5126
  },
  {
    "Character": "噔",
    "Pinyin": "dēng",
    "Definition": "syllable; (Cant.) for (a recipient of pity or sympathy)",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5937,
    "FrequencyRank": 4726
  },
  {
    "Character": "噎",
    "Pinyin": "yē",
    "Definition": "choke; hiccup",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5906,
    "FrequencyRank": 3961
  },
  {
    "Character": "㠇",
    "Pinyin": "jiù",
    "Definition": "name of a mountain ridge",
    "Radical": "山",
    "RadicalIndex": "46.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7782,
    "FrequencyRank": undefined
  },
  {
    "Character": "熛",
    "Pinyin": "biāo",
    "Definition": "blaze",
    "Radical": "火",
    "RadicalIndex": "86.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7812,
    "FrequencyRank": undefined
  },
  {
    "Character": "蝣",
    "Pinyin": "yóu",
    "Definition": "mayfly (Ephemera strigata)",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5927,
    "FrequencyRank": 5795
  },
  {
    "Character": "皞",
    "Pinyin": "hào",
    "Definition": "bright, brilliant",
    "Radical": "白",
    "RadicalIndex": "106.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7793,
    "FrequencyRank": undefined
  },
  {
    "Character": "憬",
    "Pinyin": "jǐng",
    "Definition": "rouse, awaken; become conscious",
    "Radical": "心",
    "RadicalIndex": "61.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6006,
    "FrequencyRank": 3693
  },
  {
    "Character": "暶",
    "Pinyin": "xuán",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7769,
    "FrequencyRank": undefined
  },
  {
    "Character": "镍",
    "Pinyin": "niè",
    "Definition": "nickel",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5949,
    "FrequencyRank": 4438
  },
  {
    "Character": "颛",
    "Pinyin": "zhuān",
    "Definition": "good, honest; simple; respectful",
    "Radical": "頁",
    "RadicalIndex": "181.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5938,
    "FrequencyRank": 5492
  },
  {
    "Character": "踦",
    "Pinyin": "yǐ",
    "Definition": "the shin; to pierce; to touch",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7770,
    "FrequencyRank": 7474
  },
  {
    "Character": "镕",
    "Pinyin": "róng",
    "Definition": "fuse, melt, smelt; mold",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7790,
    "FrequencyRank": 3283
  },
  {
    "Character": "嶓",
    "Pinyin": "bō",
    "Definition": "Boshan, mountain in Shaanxi",
    "Radical": "山",
    "RadicalIndex": "46.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7781,
    "FrequencyRank": undefined
  },
  {
    "Character": "凛",
    "Pinyin": "lǐn",
    "Definition": "to shiver with cold or fear",
    "Radical": "冫",
    "RadicalIndex": "15.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3284,
    "FrequencyRank": 3014
  },
  {
    "Character": "螋",
    "Pinyin": "sōu",
    "Definition": "spider millipede",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5925,
    "FrequencyRank": 6371
  },
  {
    "Character": "禤",
    "Pinyin": "xuān",
    "Definition": "surname Xuan",
    "Radical": "示",
    "RadicalIndex": "113.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7827,
    "FrequencyRank": 8847
  },
  {
    "Character": "鲡",
    "Pinyin": "lí",
    "Definition": "eel",
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5969,
    "FrequencyRank": 5600
  },
  {
    "Character": "罶",
    "Pinyin": "liǔ",
    "Definition": "fishing trap",
    "Radical": "网",
    "RadicalIndex": "122.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7779,
    "FrequencyRank": 7223
  },
  {
    "Character": "廛",
    "Pinyin": "chán",
    "Definition": "a store, shop; ground alloted to a retainer",
    "Radical": "广",
    "RadicalIndex": "53.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5981,
    "FrequencyRank": 5753
  },
  {
    "Character": "踒",
    "Pinyin": "wō",
    "Definition": "to slip and sprain a blimb",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5914,
    "FrequencyRank": undefined
  },
  {
    "Character": "潼",
    "Pinyin": "tóng",
    "Definition": "high, lofty; damp",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6004,
    "FrequencyRank": 4021
  },
  {
    "Character": "噗",
    "Pinyin": "pū",
    "Definition": "burst",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5929,
    "FrequencyRank": 3465
  },
  {
    "Character": "撸",
    "Pinyin": "lū",
    "Definition": undefined,
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5864,
    "FrequencyRank": 3937
  },
  {
    "Character": "撷",
    "Pinyin": "xié",
    "Definition": "pick up, gather up; hold in lap",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5861,
    "FrequencyRank": 4717
  },
  {
    "Character": "鲣",
    "Pinyin": "jiān",
    "Definition": "skipjack, bonito",
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5971,
    "FrequencyRank": 7261
  },
  {
    "Character": "憭",
    "Pinyin": "liǎo",
    "Definition": "clear; intelligible; severe; cold",
    "Radical": "心",
    "RadicalIndex": "61.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7822,
    "FrequencyRank": undefined
  },
  {
    "Character": "撺",
    "Pinyin": "cuān",
    "Definition": "hurry; to throw; to urge",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5867,
    "FrequencyRank": 5780
  },
  {
    "Character": "𥻗",
    "Pinyin": "chá",
    "Definition": undefined,
    "Radical": "米",
    "RadicalIndex": "119.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5989,
    "FrequencyRank": undefined
  },
  {
    "Character": "撅",
    "Pinyin": "juē",
    "Definition": "protrude; snap, break; dig",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5862,
    "FrequencyRank": 4115
  },
  {
    "Character": "镌",
    "Pinyin": "juān",
    "Definition": "engraving tool; carve, engrave",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5948,
    "FrequencyRank": 4233
  },
  {
    "Character": "艏",
    "Pinyin": "shǒu",
    "Definition": undefined,
    "Radical": "舟",
    "RadicalIndex": "137.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7797,
    "FrequencyRank": 5560
  },
  {
    "Character": "糈",
    "Pinyin": "xǔ",
    "Definition": "sacrificial rice; rations; pay",
    "Radical": "米",
    "RadicalIndex": "119.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7808,
    "FrequencyRank": 7257
  },
  {
    "Character": "𫍽",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7828,
    "FrequencyRank": undefined
  },
  {
    "Character": "蕊",
    "Pinyin": "ruǐ",
    "Definition": "unopened flowers, flower buds",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3210,
    "FrequencyRank": 3265
  },
  {
    "Character": "耦",
    "Pinyin": "ǒu",
    "Definition": "plow side by side; team of two",
    "Radical": "耒",
    "RadicalIndex": "127.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5849,
    "FrequencyRank": 4888
  },
  {
    "Character": "缭",
    "Pinyin": "liáo",
    "Definition": "wind round, rap around, bind",
    "Radical": "糸",
    "RadicalIndex": "120.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3314,
    "FrequencyRank": 3637
  },
  {
    "Character": "𫞩",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7622,
    "FrequencyRank": undefined
  },
  {
    "Character": "碾",
    "Pinyin": "niǎn",
    "Definition": "roller, crush; roll",
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3228,
    "FrequencyRank": 3684
  },
  {
    "Character": "噙",
    "Pinyin": "qín",
    "Definition": "hold in mouth; bite",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5934,
    "FrequencyRank": 4599
  },
  {
    "Character": "蕈",
    "Pinyin": "xùn",
    "Definition": "mushrooms, fungus; mildew, mold",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5874,
    "FrequencyRank": 6444
  },
  {
    "Character": "樱",
    "Pinyin": "yīng",
    "Definition": "cherry, cherry blossom",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3215,
    "FrequencyRank": 2781
  },
  {
    "Character": "镆",
    "Pinyin": "mò",
    "Definition": "sword",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7785,
    "FrequencyRank": 5657
  },
  {
    "Character": "谵",
    "Pinyin": "zhān",
    "Definition": "talkative; incoherent talk",
    "Radical": "言",
    "RadicalIndex": "149.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6014,
    "FrequencyRank": 5872
  },
  {
    "Character": "憕",
    "Pinyin": "chéng",
    "Definition": undefined,
    "Radical": "心",
    "RadicalIndex": "61.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7823,
    "FrequencyRank": undefined
  },
  {
    "Character": "嘶",
    "Pinyin": "sī",
    "Definition": "neighing of a horse; gravel voiced, husky throated; (Cant.) to hiccough",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3237,
    "FrequencyRank": 2776
  },
  {
    "Character": "樟",
    "Pinyin": "zhāng",
    "Definition": "camphor tree",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3217,
    "FrequencyRank": 4063
  },
  {
    "Character": "嶟",
    "Pinyin": "zūn",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7783,
    "FrequencyRank": undefined
  },
  {
    "Character": "镈",
    "Pinyin": "bó",
    "Definition": "large bell; hoe, spade",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7786,
    "FrequencyRank": 7221
  },
  {
    "Character": "𥔲",
    "Pinyin": "è",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7663,
    "FrequencyRank": undefined
  },
  {
    "Character": "嘹",
    "Pinyin": "liáo",
    "Definition": "used describe clarity of voice; resonant",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3239,
    "FrequencyRank": 4643
  },
  {
    "Character": "褯",
    "Pinyin": "jiè",
    "Definition": undefined,
    "Radical": "衣",
    "RadicalIndex": "145.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7826,
    "FrequencyRank": 7416
  },
  {
    "Character": "㻬",
    "Pinyin": "tū",
    "Definition": "a kind of jade",
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7747,
    "FrequencyRank": undefined
  },
  {
    "Character": "觐",
    "Pinyin": "jìn",
    "Definition": "have imperial audience",
    "Radical": "見",
    "RadicalIndex": "147.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5870,
    "FrequencyRank": 4435
  },
  {
    "Character": "蔃",
    "Pinyin": "qiáng",
    "Definition": "(Cant.) roots of plants",
    "Radical": "艸",
    "RadicalIndex": "140.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7758,
    "FrequencyRank": undefined
  },
  {
    "Character": "澌",
    "Pinyin": "sī",
    "Definition": "to exhaust; to drain dry",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5998,
    "FrequencyRank": 6217
  },
  {
    "Character": "缮",
    "Pinyin": "shàn",
    "Definition": "repair, mend; rewrite, transcribe",
    "Radical": "糸",
    "RadicalIndex": "120.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6022,
    "FrequencyRank": 3981
  },
  {
    "Character": "磙",
    "Pinyin": "gǔn",
    "Definition": "roller",
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5896,
    "FrequencyRank": 5987
  },
  {
    "Character": "漦",
    "Pinyin": "chí",
    "Definition": "saliva; spittle; flowing downstream",
    "Radical": "水",
    "RadicalIndex": "85.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7750,
    "FrequencyRank": undefined
  },
  {
    "Character": "墀",
    "Pinyin": "chí",
    "Definition": "porch; courtyard; steps leading",
    "Radical": "土",
    "RadicalIndex": "32.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5868,
    "FrequencyRank": 5847
  },
  {
    "Character": "齑",
    "Pinyin": "jī",
    "Definition": "break or smash into pieces, pulverize; hash",
    "Radical": "齊",
    "RadicalIndex": "210.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5986,
    "FrequencyRank": 5595
  },
  {
    "Character": "觯",
    "Pinyin": "zhì",
    "Definition": "wine goblet made from horn",
    "Radical": "角",
    "RadicalIndex": "148.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5977,
    "FrequencyRank": 4508
  },
  {
    "Character": "皛",
    "Pinyin": "xiǎo",
    "Definition": undefined,
    "Radical": "白",
    "RadicalIndex": "106.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7794,
    "FrequencyRank": undefined
  },
  {
    "Character": "㵐",
    "Pinyin": "jué",
    "Definition": "name of a river in Hubei Province, name of a state in ancient times",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7815,
    "FrequencyRank": undefined
  },
  {
    "Character": "憔",
    "Pinyin": "qiáo",
    "Definition": "be worn-out, emaciated, haggard",
    "Radical": "心",
    "RadicalIndex": "61.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3301,
    "FrequencyRank": 3508
  },
  {
    "Character": "璋",
    "Pinyin": "zhāng",
    "Definition": "jade plaything; jade ornament",
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5856,
    "FrequencyRank": 3267
  },
  {
    "Character": "踟",
    "Pinyin": "chí",
    "Definition": "hesitate, undecided; embarrassed",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5913,
    "FrequencyRank": 6041
  },
  {
    "Character": "镓",
    "Pinyin": "jiā",
    "Definition": "gallium",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5952,
    "FrequencyRank": 6079
  },
  {
    "Character": "翦",
    "Pinyin": "jiǎn",
    "Definition": "scissors; cut, clip; annhilate",
    "Radical": "羽",
    "RadicalIndex": "124.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7809,
    "FrequencyRank": 4765
  },
  {
    "Character": "糅",
    "Pinyin": "róu",
    "Definition": "blend, mix; mixed",
    "Radical": "米",
    "RadicalIndex": "119.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5993,
    "FrequencyRank": 5544
  },
  {
    "Character": "瘪",
    "Pinyin": "biě",
    "Definition": "shrivelled up, dried up; vexed",
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3281,
    "FrequencyRank": 3812
  },
  {
    "Character": "暵",
    "Pinyin": "hàn",
    "Definition": "dry by exposing sun",
    "Radical": "日",
    "RadicalIndex": "72.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7767,
    "FrequencyRank": undefined
  },
  {
    "Character": "箴",
    "Pinyin": "zhēn",
    "Definition": "needle, probe; admon",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5955,
    "FrequencyRank": 4285
  },
  {
    "Character": "篓",
    "Pinyin": "lǒu",
    "Definition": "bamboo basket",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3266,
    "FrequencyRank": 4012
  },
  {
    "Character": "骼",
    "Pinyin": "gé",
    "Definition": "bone; skeleton; corpse",
    "Radical": "骨",
    "RadicalIndex": "188.6",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5944,
    "FrequencyRank": 3459
  },
  {
    "Character": "橄",
    "Pinyin": "gǎn",
    "Definition": "olive",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3218,
    "FrequencyRank": 3493
  },
  {
    "Character": "慭",
    "Pinyin": "yìn",
    "Definition": "cautious; willing; but",
    "Radical": "心",
    "RadicalIndex": "61.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7765,
    "FrequencyRank": undefined
  },
  {
    "Character": "窳",
    "Pinyin": "yǔ",
    "Definition": "dirty, useless, weak, powerless; cracked, a flaw",
    "Radical": "穴",
    "RadicalIndex": "116.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6009,
    "FrequencyRank": 5821
  },
  {
    "Character": "蝻",
    "Pinyin": "nǎn",
    "Definition": "immature locusts",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5922,
    "FrequencyRank": 6301
  },
  {
    "Character": "䗖",
    "Pinyin": "dì",
    "Definition": "rainbow",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7772,
    "FrequencyRank": undefined
  },
  {
    "Character": "璆",
    "Pinyin": "qiú",
    "Definition": "beautiful jade; tinkle",
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7749,
    "FrequencyRank": undefined
  },
  {
    "Character": "瞌",
    "Pinyin": "kē",
    "Definition": "doze off; sleepy",
    "Radical": "目",
    "RadicalIndex": "109.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5902,
    "FrequencyRank": 4061
  },
  {
    "Character": "潲",
    "Pinyin": "shào",
    "Definition": "driving rain; to sprinkle",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6001,
    "FrequencyRank": 7084
  },
  {
    "Character": "鞒",
    "Pinyin": "qiáo",
    "Definition": "mud shoe, sledge for the feet",
    "Radical": "革",
    "RadicalIndex": "177.6",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5873,
    "FrequencyRank": 5500
  },
  {
    "Character": "镔",
    "Pinyin": "bīn",
    "Definition": "high quality iron",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5953,
    "FrequencyRank": 6399
  },
  {
    "Character": "鹢",
    "Pinyin": "yì",
    "Definition": "fishhawk bow or prow",
    "Radical": "鳥",
    "RadicalIndex": "196.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7810,
    "FrequencyRank": undefined
  },
  {
    "Character": "瘢",
    "Pinyin": "bān",
    "Definition": "scar, mole",
    "Radical": "疒",
    "RadicalIndex": "104.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5984,
    "FrequencyRank": 3714
  },
  {
    "Character": "潽",
    "Pinyin": "pū",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7819,
    "FrequencyRank": undefined
  },
  {
    "Character": "徵",
    "Pinyin": "zhēng",
    "Definition": "summon, recruit; musical note",
    "Radical": "彳",
    "RadicalIndex": "60.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5962,
    "FrequencyRank": 4086
  },
  {
    "Character": "鲩",
    "Pinyin": "huàn",
    "Definition": "carp",
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5974,
    "FrequencyRank": 6686
  },
  {
    "Character": "𨱏",
    "Pinyin": "dā",
    "Definition": "technetium",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7678,
    "FrequencyRank": undefined
  },
  {
    "Character": "暹",
    "Pinyin": "xiān",
    "Definition": "rise; advance, go forward",
    "Radical": "日",
    "RadicalIndex": "72.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5909,
    "FrequencyRank": 4620
  },
  {
    "Character": "噶",
    "Pinyin": "gá",
    "Definition": "used in transliterations",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5907,
    "FrequencyRank": 3644
  },
  {
    "Character": "蕨",
    "Pinyin": "jué",
    "Definition": "pteris aquilina, common bracken",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5875,
    "FrequencyRank": 4289
  },
  {
    "Character": "澎",
    "Pinyin": "pēng",
    "Definition": "splatter",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3291,
    "FrequencyRank": 3481
  },
  {
    "Character": "篆",
    "Pinyin": "zhuàn",
    "Definition": "seal script; seal, official stamp",
    "Radical": "竹",
    "RadicalIndex": "118.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5959,
    "FrequencyRank": 4254
  },
  {
    "Character": "龉",
    "Pinyin": "yǔ",
    "Definition": "uneven teeth; to disagree",
    "Radical": "齒",
    "RadicalIndex": "211.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5899,
    "FrequencyRank": 4917
  },
  {
    "Character": "糇",
    "Pinyin": "hóu",
    "Definition": "dried rice, dry provisions",
    "Radical": "米",
    "RadicalIndex": "119.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7807,
    "FrequencyRank": 5898
  },
  {
    "Character": "鞑",
    "Pinyin": "dá",
    "Definition": "tatars",
    "Radical": "革",
    "RadicalIndex": "177.6",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5871,
    "FrequencyRank": 4128
  },
  {
    "Character": "褒",
    "Pinyin": "bāo",
    "Definition": "praise, commend, honor, cite",
    "Radical": "衣",
    "RadicalIndex": "145.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3280,
    "FrequencyRank": 3539
  },
  {
    "Character": "殣",
    "Pinyin": "jìn",
    "Definition": "die; death; to starve to death",
    "Radical": "歹",
    "RadicalIndex": "78.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7764,
    "FrequencyRank": undefined
  },
  {
    "Character": "羰",
    "Pinyin": "tāng",
    "Definition": undefined,
    "Radical": "羊",
    "RadicalIndex": "123.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5988,
    "FrequencyRank": 5632
  },
  {
    "Character": "醌",
    "Pinyin": "kūn",
    "Definition": undefined,
    "Radical": "酉",
    "RadicalIndex": "164.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5890,
    "FrequencyRank": 6268
  },
  {
    "Character": "憎",
    "Pinyin": "zēng",
    "Definition": "hate, detest, abhor; hatred",
    "Radical": "心",
    "RadicalIndex": "61.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3303,
    "FrequencyRank": 2617
  },
  {
    "Character": "觑",
    "Pinyin": "qù",
    "Definition": "peep at; watch, spy on",
    "Radical": "見",
    "RadicalIndex": "147.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5901,
    "FrequencyRank": 3506
  },
  {
    "Character": "潺",
    "Pinyin": "chán",
    "Definition": "sound of flowing water",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6005,
    "FrequencyRank": 4033
  },
  {
    "Character": "墣",
    "Pinyin": "pú",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7752,
    "FrequencyRank": undefined
  },
  {
    "Character": "骸",
    "Pinyin": "hái",
    "Definition": "skeleton, body; leg bone",
    "Radical": "骨",
    "RadicalIndex": "188.6",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5945,
    "FrequencyRank": 2707
  },
  {
    "Character": "踬",
    "Pinyin": "zhì",
    "Definition": "stumble, totter; fail, be frustrated",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5915,
    "FrequencyRank": 6538
  },
  {
    "Character": "槭",
    "Pinyin": "qī",
    "Definition": "maple",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5885,
    "FrequencyRank": 4943
  },
  {
    "Character": "褴",
    "Pinyin": "lán",
    "Definition": "ragged, tattered, threadbare",
    "Radical": "衣",
    "RadicalIndex": "145.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6011,
    "FrequencyRank": 4288
  },
  {
    "Character": "颚",
    "Pinyin": "è",
    "Definition": "jaw",
    "Radical": "頁",
    "RadicalIndex": "181.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5931,
    "FrequencyRank": 3996
  },
  {
    "Character": "蝘",
    "Pinyin": "yǎn",
    "Definition": "gecko, kind of cicada",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7773,
    "FrequencyRank": 7358
  },
  {
    "Character": "勰",
    "Pinyin": "xié",
    "Definition": "peaceful, harmonious",
    "Radical": "力",
    "RadicalIndex": "19.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6018,
    "FrequencyRank": 5506
  },
  {
    "Character": "翩",
    "Pinyin": "piān",
    "Definition": "fly, flutter",
    "Radical": "羽",
    "RadicalIndex": "124.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3305,
    "FrequencyRank": 3234
  },
  {
    "Character": "屦",
    "Pinyin": "jù",
    "Definition": "straw sandals; tread on",
    "Radical": "尸",
    "RadicalIndex": "44.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6016,
    "FrequencyRank": 6987
  },
  {
    "Character": "踣",
    "Pinyin": "bó",
    "Definition": "to stumble and fall prone stiff in death",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7771,
    "FrequencyRank": 7037
  },
  {
    "Character": "蕲",
    "Pinyin": "qí",
    "Definition": "variety of artemisia seek",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5881,
    "FrequencyRank": 5333
  },
  {
    "Character": "鹞",
    "Pinyin": "yào",
    "Definition": "sparrow hawk; kite shaped like",
    "Radical": "鳥",
    "RadicalIndex": "196.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5965,
    "FrequencyRank": 4147
  },
  {
    "Character": "镏",
    "Pinyin": "liú",
    "Definition": "distil; lutetium; surname",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5950,
    "FrequencyRank": 4951
  },
  {
    "Character": "颙",
    "Pinyin": "yóng",
    "Definition": "solemn, large, grand, majestic",
    "Radical": "頁",
    "RadicalIndex": "181.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5908,
    "FrequencyRank": 6537
  },
  {
    "Character": "璀",
    "Pinyin": "cuǐ",
    "Definition": "lustre of gems; glitter; shine",
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5853,
    "FrequencyRank": 4692
  },
  {
    "Character": "樊",
    "Pinyin": "fán",
    "Definition": "a railing; a fence an enclosed place",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5888,
    "FrequencyRank": 3282
  },
  {
    "Character": "撩",
    "Pinyin": "liāo",
    "Definition": "lift up, raise; leave, depart",
    "Radical": "手",
    "RadicalIndex": "64.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3193,
    "FrequencyRank": 3514
  },
  {
    "Character": "褫",
    "Pinyin": "chǐ",
    "Definition": "strip, tear off, undress",
    "Radical": "衣",
    "RadicalIndex": "145.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6013,
    "FrequencyRank": 5631
  },
  {
    "Character": "槱",
    "Pinyin": "yǒu",
    "Definition": "firewood for sacrifice",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7760,
    "FrequencyRank": undefined
  },
  {
    "Character": "霄",
    "Pinyin": "xiāo",
    "Definition": "sky; clouds, mist; night",
    "Radical": "雨",
    "RadicalIndex": "173.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3230,
    "FrequencyRank": 3129
  },
  {
    "Character": "幡",
    "Pinyin": "fān",
    "Definition": "pennant, banner, streamer, flag",
    "Radical": "巾",
    "RadicalIndex": "50.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5940,
    "FrequencyRank": 3437
  },
  {
    "Character": "澂",
    "Pinyin": "chéng",
    "Definition": "clear and still water",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7816,
    "FrequencyRank": undefined
  },
  {
    "Character": "熵",
    "Pinyin": "shāng",
    "Definition": "entropy",
    "Radical": "火",
    "RadicalIndex": "86.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5995,
    "FrequencyRank": 4221
  },
  {
    "Character": "磏",
    "Pinyin": "lián",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7762,
    "FrequencyRank": undefined
  },
  {
    "Character": "蝾",
    "Pinyin": "róng",
    "Definition": "lizard",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5921,
    "FrequencyRank": 6148
  },
  {
    "Character": "辘",
    "Pinyin": "lù",
    "Definition": "windlass, pulley, capstan; wheel",
    "Radical": "車",
    "RadicalIndex": "159.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5898,
    "FrequencyRank": 3819
  },
  {
    "Character": "蝗",
    "Pinyin": "huáng",
    "Definition": "kind of locust",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3250,
    "FrequencyRank": 3271
  },
  {
    "Character": "鲢",
    "Pinyin": "lián",
    "Definition": "silver carp, hypophthalmiathys",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5970,
    "FrequencyRank": 5119
  },
  {
    "Character": "褥",
    "Pinyin": "rù",
    "Definition": "mattress, cushion; bedding",
    "Radical": "衣",
    "RadicalIndex": "145.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3306,
    "FrequencyRank": 3453
  },
  {
    "Character": "𩾃",
    "Pinyin": "miǎn",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7799,
    "FrequencyRank": undefined
  },
  {
    "Character": "踔",
    "Pinyin": "chuō",
    "Definition": "to get ahead; to stride; to excel",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5911,
    "FrequencyRank": 5159
  },
  {
    "Character": "蝥",
    "Pinyin": "máo",
    "Definition": "a fly which is used similarly to cantharides",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6020,
    "FrequencyRank": 6628
  },
  {
    "Character": "噇",
    "Pinyin": "chuáng",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7776,
    "FrequencyRank": undefined
  },
  {
    "Character": "镊",
    "Pinyin": "niè",
    "Definition": "tweezers, forceps, pincers",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5946,
    "FrequencyRank": 5395
  },
  {
    "Character": "醅",
    "Pinyin": "pēi",
    "Definition": "unstrained spirits",
    "Radical": "酉",
    "RadicalIndex": "164.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5891,
    "FrequencyRank": 7092
  },
  {
    "Character": "鹟",
    "Pinyin": "wēng",
    "Definition": undefined,
    "Radical": "鳥",
    "RadicalIndex": "196.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7798,
    "FrequencyRank": 7402
  },
  {
    "Character": "踯",
    "Pinyin": "zhí",
    "Definition": "waver, hesitate, be irresolute",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5917,
    "FrequencyRank": 5628
  },
  {
    "Character": "蝽",
    "Pinyin": "chūn",
    "Definition": "bedbug",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5920,
    "FrequencyRank": 5745
  },
  {
    "Character": "蝓",
    "Pinyin": "yú",
    "Definition": "snail",
    "Radical": "虫",
    "RadicalIndex": "142.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5926,
    "FrequencyRank": 6412
  },
  {
    "Character": "瞑",
    "Pinyin": "míng",
    "Definition": "close eyes",
    "Radical": "目",
    "RadicalIndex": "109.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5904,
    "FrequencyRank": 4229
  },
  {
    "Character": "憨",
    "Pinyin": "hān",
    "Definition": "foolish, silly, coquettish",
    "Radical": "心",
    "RadicalIndex": "61.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 3309,
    "FrequencyRank": 3722
  },
  {
    "Character": "糌",
    "Pinyin": "zān",
    "Definition": undefined,
    "Radical": "米",
    "RadicalIndex": "119.9",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5991,
    "FrequencyRank": 6149
  },
  {
    "Character": "噂",
    "Pinyin": "zǔn",
    "Definition": "meet",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7777,
    "FrequencyRank": undefined
  },
  {
    "Character": "叇",
    "Pinyin": "dài",
    "Definition": undefined,
    "Radical": "厶",
    "RadicalIndex": "28.13",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7751,
    "FrequencyRank": undefined
  },
  {
    "Character": "槲",
    "Pinyin": "hú",
    "Definition": "type of oak",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5889,
    "FrequencyRank": 5422
  },
  {
    "Character": "鲠",
    "Pinyin": "gěng",
    "Definition": "fish bones; honest, upright",
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5968,
    "FrequencyRank": 5612
  },
  {
    "Character": "瑾",
    "Pinyin": "jǐn",
    "Definition": "brilliance of gems; fine jade",
    "Radical": "玉",
    "RadicalIndex": "96.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5851,
    "FrequencyRank": 3799
  },
  {
    "Character": "馓",
    "Pinyin": "sǎn",
    "Definition": "fried round cakes of wheat flour",
    "Radical": "食",
    "RadicalIndex": "184.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5978,
    "FrequencyRank": 5269
  },
  {
    "Character": "墦",
    "Pinyin": "fán",
    "Definition": "tomb",
    "Radical": "土",
    "RadicalIndex": "32.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7753,
    "FrequencyRank": undefined
  },
  {
    "Character": "嫽",
    "Pinyin": "liáo",
    "Definition": "play with; (Cant.) to provoke",
    "Radical": "女",
    "RadicalIndex": "38.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7829,
    "FrequencyRank": undefined
  },
  {
    "Character": "噜",
    "Pinyin": "lū",
    "Definition": "verbose, talkative; mumbling",
    "Radical": "口",
    "RadicalIndex": "30.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5935,
    "FrequencyRank": 3143
  },
  {
    "Character": "潾",
    "Pinyin": "lín",
    "Definition": "clear water",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7820,
    "FrequencyRank": undefined
  },
  {
    "Character": "戮",
    "Pinyin": "lù",
    "Definition": "kill, massacre; oppress",
    "Radical": "戈",
    "RadicalIndex": "62.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6019,
    "FrequencyRank": 3318
  },
  {
    "Character": "䴓",
    "Pinyin": "shī",
    "Definition": "general term for a group of small birds",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6806,
    "FrequencyRank": 8751
  },
  {
    "Character": "鲧",
    "Pinyin": "gǔn",
    "Definition": "giant fish; father of emperor",
    "Radical": "魚",
    "RadicalIndex": "195.7",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5973,
    "FrequencyRank": 5120
  },
  {
    "Character": "潟",
    "Pinyin": "xì",
    "Definition": "land impregnated with salt from the tide",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6003,
    "FrequencyRank": undefined
  },
  {
    "Character": "蕤",
    "Pinyin": "ruí",
    "Definition": "drooping leaves; fringe soft, delicate",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5876,
    "FrequencyRank": 6222
  },
  {
    "Character": "畿",
    "Pinyin": "jī",
    "Definition": "imperial domain; area near capital",
    "Radical": "田",
    "RadicalIndex": "102.1",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6025,
    "FrequencyRank": 4401
  },
  {
    "Character": "踞",
    "Pinyin": "jù",
    "Definition": "crouch, squat; sit, occupy",
    "Radical": "足",
    "RadicalIndex": "157.8",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5919,
    "FrequencyRank": 3651
  },
  {
    "Character": "蕃",
    "Pinyin": "fān",
    "Definition": "foreign things",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5880,
    "FrequencyRank": 3646
  },
  {
    "Character": "牖",
    "Pinyin": "yǒu",
    "Definition": "window; lead enlightenment",
    "Radical": "片",
    "RadicalIndex": "91.11",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5960,
    "FrequencyRank": 5368
  },
  {
    "Character": "鼒",
    "Pinyin": "zī",
    "Definition": "large tripod caldron with small mouth",
    "Radical": "鼎",
    "RadicalIndex": "206.3",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7759,
    "FrequencyRank": undefined
  },
  {
    "Character": "澍",
    "Pinyin": "shù",
    "Definition": "timely rain, life-giving rain",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 5997,
    "FrequencyRank": 5667
  },
  {
    "Character": "劐",
    "Pinyin": "huō",
    "Definition": "destroy",
    "Radical": "刀",
    "RadicalIndex": "18.14",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 7755,
    "FrequencyRank": 7332
  },
  {
    "Character": "缯",
    "Pinyin": "zēng",
    "Definition": "silk fabrics; surname",
    "Radical": "糸",
    "RadicalIndex": "120.12",
    "StrokeCount": 15,
    "HSK": undefined,
    "GeneralStandard": 6023,
    "FrequencyRank": 5719
  },
  {
    "Character": "橑",
    "Pinyin": "lǎo",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7846,
    "FrequencyRank": undefined
  },
  {
    "Character": "鲰",
    "Pinyin": "zōu",
    "Definition": "small fish; small; minnow",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7887,
    "FrequencyRank": 5526
  },
  {
    "Character": "斓",
    "Pinyin": "lán",
    "Definition": "multicolored",
    "Radical": "文",
    "RadicalIndex": "67.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6161,
    "FrequencyRank": 3994
  },
  {
    "Character": "曈",
    "Pinyin": "tóng",
    "Definition": "twilight just before sunrise",
    "Radical": "日",
    "RadicalIndex": "72.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7861,
    "FrequencyRank": undefined
  },
  {
    "Character": "篪",
    "Pinyin": "chí",
    "Definition": "a bamboo flute with seven holes",
    "Radical": "竹",
    "RadicalIndex": "118.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6127,
    "FrequencyRank": 5585
  },
  {
    "Character": "橹",
    "Pinyin": "lǔ",
    "Definition": "oar, scull; row, scull",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6061,
    "FrequencyRank": 4134
  },
  {
    "Character": "噬",
    "Pinyin": "shì",
    "Definition": "bite; gnaw; snap at",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6105,
    "FrequencyRank": 3228
  },
  {
    "Character": "縠",
    "Pinyin": "hú",
    "Definition": "crepe",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6043,
    "FrequencyRank": 6778
  },
  {
    "Character": "螃",
    "Pinyin": "páng",
    "Definition": "crab",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3347,
    "FrequencyRank": 3938
  },
  {
    "Character": "璲",
    "Pinyin": "suì",
    "Definition": "pendant girdle ornament",
    "Radical": "玉",
    "RadicalIndex": "96.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7833,
    "FrequencyRank": undefined
  },
  {
    "Character": "橼",
    "Pinyin": "yuán",
    "Definition": "citrus",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6064,
    "FrequencyRank": 6751
  },
  {
    "Character": "噫",
    "Pinyin": "yī",
    "Definition": "belch; alas",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6106,
    "FrequencyRank": 4489
  },
  {
    "Character": "鲮",
    "Pinyin": "líng",
    "Definition": "carp",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6139,
    "FrequencyRank": 5476
  },
  {
    "Character": "豮",
    "Pinyin": "fén",
    "Definition": "castrate pig",
    "Radical": "豕",
    "RadicalIndex": "152.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7853,
    "FrequencyRank": undefined
  },
  {
    "Character": "澥",
    "Pinyin": "xiè",
    "Definition": "a blocked stream; gulf",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6179,
    "FrequencyRank": undefined
  },
  {
    "Character": "瘿",
    "Pinyin": "yǐng",
    "Definition": "swelling, goiter",
    "Radical": "疒",
    "RadicalIndex": "104.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6156,
    "FrequencyRank": 5515
  },
  {
    "Character": "曌",
    "Pinyin": "zhào",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7860,
    "FrequencyRank": undefined
  },
  {
    "Character": "蹁",
    "Pinyin": "pián",
    "Definition": "to walk with a limp",
    "Radical": "足",
    "RadicalIndex": "157.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6096,
    "FrequencyRank": 6646
  },
  {
    "Character": "嬴",
    "Pinyin": "yíng",
    "Definition": "to win; to have a surplus; surname",
    "Radical": "女",
    "RadicalIndex": "38.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6164,
    "FrequencyRank": 4511
  },
  {
    "Character": "燔",
    "Pinyin": "fán",
    "Definition": "to roast; to burn",
    "Radical": "火",
    "RadicalIndex": "86.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6172,
    "FrequencyRank": 4442
  },
  {
    "Character": "璠",
    "Pinyin": "fán",
    "Definition": "a piece of precious jade",
    "Radical": "玉",
    "RadicalIndex": "96.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6032,
    "FrequencyRank": undefined
  },
  {
    "Character": "羲",
    "Pinyin": "xī",
    "Definition": "ancient emperor; breath, vapor",
    "Radical": "羊",
    "RadicalIndex": "123.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6166,
    "FrequencyRank": 3942
  },
  {
    "Character": "鲭",
    "Pinyin": "qīng",
    "Definition": "mackerel",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7885,
    "FrequencyRank": 6310
  },
  {
    "Character": "嶦",
    "Pinyin": "zhān",
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7871,
    "FrequencyRank": undefined
  },
  {
    "Character": "噻",
    "Pinyin": "sāi",
    "Definition": "character used in translation",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6107,
    "FrequencyRank": 5857
  },
  {
    "Character": "螯",
    "Pinyin": "áo",
    "Definition": "nippers",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6035,
    "FrequencyRank": 5610
  },
  {
    "Character": "廨",
    "Pinyin": "xiè",
    "Definition": "government office, public office",
    "Radical": "广",
    "RadicalIndex": "53.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6152,
    "FrequencyRank": 6276
  },
  {
    "Character": "嚄",
    "Pinyin": "huō",
    "Definition": "roar",
    "Radical": "口",
    "RadicalIndex": "30.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6087,
    "FrequencyRank": undefined
  },
  {
    "Character": "癀",
    "Pinyin": "huáng",
    "Definition": "jaundice",
    "Radical": "疒",
    "RadicalIndex": "104.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7892,
    "FrequencyRank": 9333
  },
  {
    "Character": "噱",
    "Pinyin": "jué",
    "Definition": "laugh heartily, laugh aloud",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6104,
    "FrequencyRank": 5124
  },
  {
    "Character": "瘭",
    "Pinyin": "biāo",
    "Definition": "a whitlow",
    "Radical": "疒",
    "RadicalIndex": "104.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7893,
    "FrequencyRank": undefined
  },
  {
    "Character": "澽",
    "Pinyin": "jù",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7905,
    "FrequencyRank": undefined
  },
  {
    "Character": "醚",
    "Pinyin": "mí",
    "Definition": "ether",
    "Radical": "酉",
    "RadicalIndex": "164.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6071,
    "FrequencyRank": 5302
  },
  {
    "Character": "噩",
    "Pinyin": "è",
    "Definition": "bad, ill-omened, unlucky",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3329,
    "FrequencyRank": 3408
  },
  {
    "Character": "檎",
    "Pinyin": "qín",
    "Definition": "small red apple",
    "Radical": "木",
    "RadicalIndex": "75.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6060,
    "FrequencyRank": 6192
  },
  {
    "Character": "靛",
    "Pinyin": "diàn",
    "Definition": "indigo; any blue dye",
    "Radical": "青",
    "RadicalIndex": "174.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6031,
    "FrequencyRank": 5211
  },
  {
    "Character": "燏",
    "Pinyin": "yù",
    "Definition": "blaze",
    "Radical": "火",
    "RadicalIndex": "86.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7901,
    "FrequencyRank": undefined
  },
  {
    "Character": "鲯",
    "Pinyin": "qí",
    "Definition": "coryphaena hippurus",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7886,
    "FrequencyRank": 7528
  },
  {
    "Character": "糒",
    "Pinyin": "bèi",
    "Definition": "food for a journey; cakes",
    "Radical": "米",
    "RadicalIndex": "119.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7896,
    "FrequencyRank": undefined
  },
  {
    "Character": "翰",
    "Pinyin": "hàn",
    "Definition": "writing brush, pen, pencil",
    "Radical": "羽",
    "RadicalIndex": "124.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3328,
    "FrequencyRank": 1881
  },
  {
    "Character": "缳",
    "Pinyin": "huán",
    "Definition": "noose; hang death; tie, bind",
    "Radical": "糸",
    "RadicalIndex": "120.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6194,
    "FrequencyRank": 6810
  },
  {
    "Character": "磬",
    "Pinyin": "qìng",
    "Definition": "musical instrument; musical stone",
    "Radical": "石",
    "RadicalIndex": "112.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6044,
    "FrequencyRank": 5327
  },
  {
    "Character": "麇",
    "Pinyin": "jūn",
    "Definition": "general name for the hornless deer; to collect to band together",
    "Radical": "鹿",
    "RadicalIndex": "198.5",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6162,
    "FrequencyRank": 5947
  },
  {
    "Character": "甏",
    "Pinyin": "bèng",
    "Definition": "a squat jar for holding wine, sauces etc.",
    "Radical": "瓦",
    "RadicalIndex": "98.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6041,
    "FrequencyRank": 6306
  },
  {
    "Character": "镗",
    "Pinyin": "tāng",
    "Definition": "boring tool",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6113,
    "FrequencyRank": 6229
  },
  {
    "Character": "衠",
    "Pinyin": "zhūn",
    "Definition": undefined,
    "Radical": "行",
    "RadicalIndex": "144.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7881,
    "FrequencyRank": undefined
  },
  {
    "Character": "颞",
    "Pinyin": "niè",
    "Definition": "the temporal bone",
    "Radical": "頁",
    "RadicalIndex": "181.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6045,
    "FrequencyRank": 5037
  },
  {
    "Character": "檠",
    "Pinyin": "qíng",
    "Definition": "stand for lamp; frame for bow",
    "Radical": "木",
    "RadicalIndex": "75.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6051,
    "FrequencyRank": 6105
  },
  {
    "Character": "澼",
    "Pinyin": "pì",
    "Definition": "bleach; wash, clean",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7908,
    "FrequencyRank": undefined
  },
  {
    "Character": "霏",
    "Pinyin": "fēi",
    "Definition": "falling of snow and rain",
    "Radical": "雨",
    "RadicalIndex": "173.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6077,
    "FrequencyRank": 4768
  },
  {
    "Character": "磲",
    "Pinyin": "qú",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6072,
    "FrequencyRank": 6316
  },
  {
    "Character": "辚",
    "Pinyin": "lín",
    "Definition": "rumbling of vehicles; threshold",
    "Radical": "車",
    "RadicalIndex": "159.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6080,
    "FrequencyRank": 5973
  },
  {
    "Character": "醐",
    "Pinyin": "hú",
    "Definition": "purest cream",
    "Radical": "酉",
    "RadicalIndex": "164.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6069,
    "FrequencyRank": 6002
  },
  {
    "Character": "镠",
    "Pinyin": "liú",
    "Definition": "pure gold",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6119,
    "FrequencyRank": 6408
  },
  {
    "Character": "薢",
    "Pinyin": "xiè",
    "Definition": "woody climbing plant",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7843,
    "FrequencyRank": 7872
  },
  {
    "Character": "亸",
    "Pinyin": "duǒ",
    "Definition": "read aloud, recite, chant; droop",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7891,
    "FrequencyRank": undefined
  },
  {
    "Character": "澪",
    "Pinyin": "líng",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7904,
    "FrequencyRank": undefined
  },
  {
    "Character": "癃",
    "Pinyin": "lóng",
    "Definition": "weakness, infirmity retention of urine",
    "Radical": "疒",
    "RadicalIndex": "104.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6159,
    "FrequencyRank": 6904
  },
  {
    "Character": "篡",
    "Pinyin": "cuàn",
    "Definition": "usurp",
    "Radical": "竹",
    "RadicalIndex": "118.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3358,
    "FrequencyRank": 3406
  },
  {
    "Character": "遽",
    "Pinyin": "jù",
    "Definition": "suddenly, unexpectedly; at once",
    "Radical": "辵",
    "RadicalIndex": "162.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6082,
    "FrequencyRank": 3949
  },
  {
    "Character": "樵",
    "Pinyin": "qiáo",
    "Definition": "woodcutter; firewood; gather wood",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6059,
    "FrequencyRank": 3386
  },
  {
    "Character": "鹾",
    "Pinyin": "cuó",
    "Definition": "salty; salt",
    "Radical": "鹵",
    "RadicalIndex": "197.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7857,
    "FrequencyRank": 5836
  },
  {
    "Character": "膙",
    "Pinyin": "jiǎng",
    "Definition": "callous skin on the feet",
    "Radical": "肉",
    "RadicalIndex": "130.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6138,
    "FrequencyRank": undefined
  },
  {
    "Character": "鹨",
    "Pinyin": "liù",
    "Definition": "Anthus species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7914,
    "FrequencyRank": 5484
  },
  {
    "Character": "薅",
    "Pinyin": "hāo",
    "Definition": "to weed; to eradicate",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6055,
    "FrequencyRank": 6010
  },
  {
    "Character": "镞",
    "Pinyin": "zú",
    "Definition": "arrowhead, barb; swift, quick",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6118,
    "FrequencyRank": 5762
  },
  {
    "Character": "磡",
    "Pinyin": "kàn",
    "Definition": "cliff, ledge, bank; step",
    "Radical": "石",
    "RadicalIndex": "112.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7850,
    "FrequencyRank": 7733
  },
  {
    "Character": "缱",
    "Pinyin": "qiǎn",
    "Definition": "attached to, inseparable; entangl",
    "Radical": "糸",
    "RadicalIndex": "120.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6192,
    "FrequencyRank": 5798
  },
  {
    "Character": "擂",
    "Pinyin": "léi",
    "Definition": "rub, grind; grind with a mortar and pestle; triturate",
    "Radical": "手",
    "RadicalIndex": "64.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3316,
    "FrequencyRank": 3685
  },
  {
    "Character": "穄",
    "Pinyin": "jì",
    "Definition": "panicled millet",
    "Radical": "禾",
    "RadicalIndex": "115.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7876,
    "FrequencyRank": 9039
  },
  {
    "Character": "翱",
    "Pinyin": "áo",
    "Definition": "soar, roam",
    "Radical": "羽",
    "RadicalIndex": "124.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6131,
    "FrequencyRank": 4148
  },
  {
    "Character": "瘳",
    "Pinyin": "chōu",
    "Definition": "to be healed; to reform",
    "Radical": "疒",
    "RadicalIndex": "104.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6160,
    "FrequencyRank": 6993
  },
  {
    "Character": "橛",
    "Pinyin": "jué",
    "Definition": "a post, a stake; an axle",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6057,
    "FrequencyRank": 4998
  },
  {
    "Character": "噤",
    "Pinyin": "jìn",
    "Definition": "close; be silent, be unable speak",
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6089,
    "FrequencyRank": 4195
  },
  {
    "Character": "濩",
    "Pinyin": "huò",
    "Definition": "pour down, cascade down; look",
    "Radical": "水",
    "RadicalIndex": "85.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7902,
    "FrequencyRank": undefined
  },
  {
    "Character": "螭",
    "Pinyin": "chī",
    "Definition": "a dragon whose horns have not grown; cruel",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6101,
    "FrequencyRank": 5509
  },
  {
    "Character": "澶",
    "Pinyin": "chán",
    "Definition": "still water, placid, tranquil",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6180,
    "FrequencyRank": 5906
  },
  {
    "Character": "氅",
    "Pinyin": "chǎng",
    "Definition": "overcoat; down feathers",
    "Radical": "毛",
    "RadicalIndex": "82.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6083,
    "FrequencyRank": 5075
  },
  {
    "Character": "簉",
    "Pinyin": "zào",
    "Definition": "deputy, subordinate; concubine",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7879,
    "FrequencyRank": undefined
  },
  {
    "Character": "螣",
    "Pinyin": "tè",
    "Definition": "mythological wingless dragon of",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7883,
    "FrequencyRank": 7653
  },
  {
    "Character": "镘",
    "Pinyin": "màn",
    "Definition": "trowel",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6114,
    "FrequencyRank": 7778
  },
  {
    "Character": "熻",
    "Pinyin": "xī",
    "Definition": "to heat; to roast; to burn",
    "Radical": "火",
    "RadicalIndex": "86.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7898,
    "FrequencyRank": undefined
  },
  {
    "Character": "寰",
    "Pinyin": "huán",
    "Definition": "great domain, country, world",
    "Radical": "宀",
    "RadicalIndex": "40.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6183,
    "FrequencyRank": 3963
  },
  {
    "Character": "燋",
    "Pinyin": "jiāo",
    "Definition": "scorch, burn, scald; torch",
    "Radical": "火",
    "RadicalIndex": "86.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7897,
    "FrequencyRank": undefined
  },
  {
    "Character": "耨",
    "Pinyin": "nòu",
    "Definition": "hoe, rake; weed",
    "Radical": "耒",
    "RadicalIndex": "127.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6027,
    "FrequencyRank": 4899
  },
  {
    "Character": "鲷",
    "Pinyin": "diāo",
    "Definition": "pagrosomus major, porgy",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6145,
    "FrequencyRank": 6042
  },
  {
    "Character": "蹅",
    "Pinyin": "chǎ",
    "Definition": "to tread on, walk through",
    "Radical": "足",
    "RadicalIndex": "157.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7863,
    "FrequencyRank": 7030
  },
  {
    "Character": "髹",
    "Pinyin": "xiū",
    "Definition": "red lacquer; to lacquer",
    "Radical": "髟",
    "RadicalIndex": "190.6",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6038,
    "FrequencyRank": 6254
  },
  {
    "Character": "篥",
    "Pinyin": "lì",
    "Definition": "bugle, 觱篥 bìlì Tatar (Tartar) horn",
    "Radical": "竹",
    "RadicalIndex": "118.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6125,
    "FrequencyRank": 7025
  },
  {
    "Character": "幪",
    "Pinyin": "méng",
    "Definition": "cover, shelter, screen; protect",
    "Radical": "巾",
    "RadicalIndex": "50.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7869,
    "FrequencyRank": undefined
  },
  {
    "Character": "薮",
    "Pinyin": "sǒu",
    "Definition": "marsh, swamp; wild country",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6053,
    "FrequencyRank": 4835
  },
  {
    "Character": "醑",
    "Pinyin": "xǔ",
    "Definition": "to strain spirits",
    "Radical": "酉",
    "RadicalIndex": "164.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7848,
    "FrequencyRank": 8313
  },
  {
    "Character": "麈",
    "Pinyin": "zhǔ",
    "Definition": "species of deer",
    "Radical": "鹿",
    "RadicalIndex": "198.5",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6163,
    "FrequencyRank": 6586
  },
  {
    "Character": "篝",
    "Pinyin": "gōu",
    "Definition": "bamboo basket; bamboo frame",
    "Radical": "竹",
    "RadicalIndex": "118.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6124,
    "FrequencyRank": 4336
  },
  {
    "Character": "鹧",
    "Pinyin": "zhè",
    "Definition": "partridge",
    "Radical": "鳥",
    "RadicalIndex": "196.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6151,
    "FrequencyRank": 5306
  },
  {
    "Character": "劓",
    "Pinyin": "yì",
    "Definition": "cut off nose",
    "Radical": "刀",
    "RadicalIndex": "18.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6130,
    "FrequencyRank": 6733
  },
  {
    "Character": "黔",
    "Pinyin": "qián",
    "Definition": "black; Guizhou",
    "Radical": "黑",
    "RadicalIndex": "203.4",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3353,
    "FrequencyRank": 3989
  },
  {
    "Character": "鹦",
    "Pinyin": "yīng",
    "Definition": "parrot",
    "Radical": "鳥",
    "RadicalIndex": "196.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3350,
    "FrequencyRank": 3488
  },
  {
    "Character": "瞥",
    "Pinyin": "piē",
    "Definition": "take fleeting glance at",
    "Radical": "目",
    "RadicalIndex": "109.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6168,
    "FrequencyRank": 2706
  },
  {
    "Character": "鲺",
    "Pinyin": "shī",
    "Definition": "cat fish",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7888,
    "FrequencyRank": undefined
  },
  {
    "Character": "憩",
    "Pinyin": "qì",
    "Definition": "rest, take rest",
    "Radical": "心",
    "RadicalIndex": "61.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6122,
    "FrequencyRank": 4397
  },
  {
    "Character": "𪩘",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7870,
    "FrequencyRank": undefined
  },
  {
    "Character": "熹",
    "Pinyin": "xī",
    "Definition": "dim light, glimmer; warm, bright",
    "Radical": "火",
    "RadicalIndex": "86.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6040,
    "FrequencyRank": 3871
  },
  {
    "Character": "縢",
    "Pinyin": "téng",
    "Definition": "bind, tie up, restrain; cord",
    "Radical": "糸",
    "RadicalIndex": "120.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7884,
    "FrequencyRank": 8325
  },
  {
    "Character": "氆",
    "Pinyin": "pǔ",
    "Definition": "thick, rough serge from Tibet",
    "Radical": "毛",
    "RadicalIndex": "82.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6121,
    "FrequencyRank": 6397
  },
  {
    "Character": "鲵",
    "Pinyin": "ní",
    "Definition": "Cryptobranchus japonicus; a salamander",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6144,
    "FrequencyRank": 6174
  },
  {
    "Character": "薇",
    "Pinyin": "wēi",
    "Definition": "Osmunda regalis, a species of fern",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3323,
    "FrequencyRank": 3193
  },
  {
    "Character": "赝",
    "Pinyin": "yàn",
    "Definition": "false; counterfeit; bogus; sham",
    "Radical": "厂",
    "RadicalIndex": "27.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6073,
    "FrequencyRank": 4714
  },
  {
    "Character": "瞟",
    "Pinyin": "piǎo",
    "Definition": "glare at; look askance at; squint",
    "Radical": "目",
    "RadicalIndex": "109.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6084,
    "FrequencyRank": 3627
  },
  {
    "Character": "蹉",
    "Pinyin": "cuō",
    "Definition": "error, mistake, slip; failure",
    "Radical": "足",
    "RadicalIndex": "157.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6095,
    "FrequencyRank": 5828
  },
  {
    "Character": "澴",
    "Pinyin": "huán",
    "Definition": "river in Hubei province",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7906,
    "FrequencyRank": undefined
  },
  {
    "Character": "颡",
    "Pinyin": "sǎng",
    "Definition": "the forehead; to kowtow",
    "Radical": "頁",
    "RadicalIndex": "181.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6191,
    "FrequencyRank": 6253
  },
  {
    "Character": "蹂",
    "Pinyin": "róu",
    "Definition": "trample under foot, tread on",
    "Radical": "足",
    "RadicalIndex": "157.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3345,
    "FrequencyRank": 3815
  },
  {
    "Character": "蟆",
    "Pinyin": "má",
    "Definition": "frog, toad",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3346,
    "FrequencyRank": 3849
  },
  {
    "Character": "篚",
    "Pinyin": "fěi",
    "Definition": "round or oval covered-baskets with short legs",
    "Radical": "竹",
    "RadicalIndex": "118.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7877,
    "FrequencyRank": 7186
  },
  {
    "Character": "濑",
    "Pinyin": "lài",
    "Definition": "swift current; rapids",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6174,
    "FrequencyRank": 4161
  },
  {
    "Character": "甑",
    "Pinyin": "zèng",
    "Definition": "boiler for steaming rice, pot",
    "Radical": "瓦",
    "RadicalIndex": "98.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6169,
    "FrequencyRank": 4863
  },
  {
    "Character": "樨",
    "Pinyin": "xī",
    "Definition": "scrambled eggs",
    "Radical": "木",
    "RadicalIndex": "75.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6063,
    "FrequencyRank": 6446
  },
  {
    "Character": "瘰",
    "Pinyin": "luǒ",
    "Definition": "scrofula, swellings",
    "Radical": "疒",
    "RadicalIndex": "104.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6154,
    "FrequencyRank": 6360
  },
  {
    "Character": "䗛",
    "Pinyin": "xiū",
    "Definition": "a kind of insect",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7865,
    "FrequencyRank": undefined
  },
  {
    "Character": "鲸",
    "Pinyin": "jīng",
    "Definition": "whale",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3366,
    "FrequencyRank": 2618
  },
  {
    "Character": "徼",
    "Pinyin": "jiǎo",
    "Definition": "frontier, border; inspect, patrol",
    "Radical": "彳",
    "RadicalIndex": "60.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6134,
    "FrequencyRank": 6219
  },
  {
    "Character": "璘",
    "Pinyin": "lín",
    "Definition": "luster of jade",
    "Radical": "玉",
    "RadicalIndex": "96.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6033,
    "FrequencyRank": undefined
  },
  {
    "Character": "璒",
    "Pinyin": "dēng",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7834,
    "FrequencyRank": undefined
  },
  {
    "Character": "憺",
    "Pinyin": "dàn",
    "Definition": "peace",
    "Radical": "心",
    "RadicalIndex": "61.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7910,
    "FrequencyRank": undefined
  },
  {
    "Character": "憷",
    "Pinyin": "chù",
    "Definition": "painful; suffering; privation",
    "Radical": "心",
    "RadicalIndex": "61.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7909,
    "FrequencyRank": 5805
  },
  {
    "Character": "邂",
    "Pinyin": "xiè",
    "Definition": "unexpected meeting; encounter by",
    "Radical": "辵",
    "RadicalIndex": "162.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6150,
    "FrequencyRank": 4605
  },
  {
    "Character": "镚",
    "Pinyin": "bèng",
    "Definition": "small coin",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6115,
    "FrequencyRank": 7307
  },
  {
    "Character": "缰",
    "Pinyin": "jiāng",
    "Definition": "reins, bridle",
    "Radical": "糸",
    "RadicalIndex": "120.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3386,
    "FrequencyRank": 3855
  },
  {
    "Character": "薳",
    "Pinyin": "wěi",
    "Definition": "name of a herb; surname; (Cant.) a plant stem",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7838,
    "FrequencyRank": 7595
  },
  {
    "Character": "薜",
    "Pinyin": "bì",
    "Definition": "evergreen shrubs, ligusticum",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6054,
    "FrequencyRank": 5529
  },
  {
    "Character": "燊",
    "Pinyin": "shēn",
    "Definition": "luxuriant",
    "Radical": "火",
    "RadicalIndex": "86.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7899,
    "FrequencyRank": undefined
  },
  {
    "Character": "澧",
    "Pinyin": "lǐ",
    "Definition": "river in northern Hunan province",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6177,
    "FrequencyRank": 4755
  },
  {
    "Character": "罹",
    "Pinyin": "lí",
    "Definition": "sorrow, grief; incur, meet with",
    "Radical": "网",
    "RadicalIndex": "122.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6109,
    "FrequencyRank": 4168
  },
  {
    "Character": "擞",
    "Pinyin": "sǒu",
    "Definition": "shake, tremble, quake; flutter",
    "Radical": "手",
    "RadicalIndex": "64.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6042,
    "FrequencyRank": 3870
  },
  {
    "Character": "濉",
    "Pinyin": "suī",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6175,
    "FrequencyRank": 5191
  },
  {
    "Character": "璟",
    "Pinyin": "jǐng",
    "Definition": "luster of gem",
    "Radical": "玉",
    "RadicalIndex": "96.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6030,
    "FrequencyRank": undefined
  },
  {
    "Character": "嬖",
    "Pinyin": "bì",
    "Definition": "favorite; a minion",
    "Radical": "女",
    "RadicalIndex": "38.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6187,
    "FrequencyRank": 5700
  },
  {
    "Character": "瞠",
    "Pinyin": "chēng",
    "Definition": "look at, stare at, gaze at",
    "Radical": "目",
    "RadicalIndex": "109.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6085,
    "FrequencyRank": 4018
  },
  {
    "Character": "蕗",
    "Pinyin": "lù",
    "Definition": "leucacene",
    "Radical": "艸",
    "RadicalIndex": "140.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7842,
    "FrequencyRank": 7357
  },
  {
    "Character": "𥕢",
    "Pinyin": "cáo",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7851,
    "FrequencyRank": undefined
  },
  {
    "Character": "篙",
    "Pinyin": "gāo",
    "Definition": "pole used punt boat",
    "Radical": "竹",
    "RadicalIndex": "118.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6128,
    "FrequencyRank": 4628
  },
  {
    "Character": "篱",
    "Pinyin": "lí",
    "Definition": "bamboo or wooden fence; hedge",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3360,
    "FrequencyRank": 3158
  },
  {
    "Character": "薨",
    "Pinyin": "hōng",
    "Definition": "death of prince; swarming",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6050,
    "FrequencyRank": 5775
  },
  {
    "Character": "霖",
    "Pinyin": "lín",
    "Definition": "long spell of rain, copious rain",
    "Radical": "雨",
    "RadicalIndex": "173.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6076,
    "FrequencyRank": 3133
  },
  {
    "Character": "穑",
    "Pinyin": "sè",
    "Definition": "farm, harvest grain; stingy",
    "Radical": "禾",
    "RadicalIndex": "115.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6123,
    "FrequencyRank": 4068
  },
  {
    "Character": "篯",
    "Pinyin": "jiān",
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7878,
    "FrequencyRank": 5877
  },
  {
    "Character": "鲲",
    "Pinyin": "kūn",
    "Definition": "spawn; roe; fy",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6141,
    "FrequencyRank": 5578
  },
  {
    "Character": "薛",
    "Pinyin": "xuē",
    "Definition": "kind of marsh grass; feudal state",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3322,
    "FrequencyRank": 1953
  },
  {
    "Character": "褶",
    "Pinyin": "zhě",
    "Definition": "pleat, crease, wrinkles",
    "Radical": "衣",
    "RadicalIndex": "145.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6185,
    "FrequencyRank": 3769
  },
  {
    "Character": "螅",
    "Pinyin": "xī",
    "Definition": "intestinal worm",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6100,
    "FrequencyRank": 3724
  },
  {
    "Character": "嬗",
    "Pinyin": "shàn",
    "Definition": "succession to the throne",
    "Radical": "女",
    "RadicalIndex": "38.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6190,
    "FrequencyRank": 4848
  },
  {
    "Character": "螠",
    "Pinyin": "yì",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6102,
    "FrequencyRank": 9903
  },
  {
    "Character": "醛",
    "Pinyin": "quán",
    "Definition": "aldehyde",
    "Radical": "酉",
    "RadicalIndex": "164.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6068,
    "FrequencyRank": 4793
  },
  {
    "Character": "橐",
    "Pinyin": "tuó",
    "Definition": "a sack, a bag opening at both ends",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6066,
    "FrequencyRank": 4560
  },
  {
    "Character": "瓢",
    "Pinyin": "piáo",
    "Definition": "ladle made from dried gourd",
    "Radical": "瓜",
    "RadicalIndex": "97.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3335,
    "FrequencyRank": 3735
  },
  {
    "Character": "噼",
    "Pinyin": "pī",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6108,
    "FrequencyRank": 4309
  },
  {
    "Character": "嚆",
    "Pinyin": "hāo",
    "Definition": "give forth sound, make noise",
    "Radical": "口",
    "RadicalIndex": "30.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6088,
    "FrequencyRank": 6631
  },
  {
    "Character": "糗",
    "Pinyin": "qiǔ",
    "Definition": "parched wheat or rice; broken grain",
    "Radical": "米",
    "RadicalIndex": "119.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6167,
    "FrequencyRank": 6352
  },
  {
    "Character": "耪",
    "Pinyin": "pǎng",
    "Definition": "plow, cultivate",
    "Radical": "耒",
    "RadicalIndex": "127.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6028,
    "FrequencyRank": 5572
  },
  {
    "Character": "螨",
    "Pinyin": "mǎn",
    "Definition": "insect",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6097,
    "FrequencyRank": 4641
  },
  {
    "Character": "冀",
    "Pinyin": "jì",
    "Definition": "hope for; wish; Hebei province",
    "Radical": "八",
    "RadicalIndex": "12.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3340,
    "FrequencyRank": 2550
  },
  {
    "Character": "暿",
    "Pinyin": "xǐ",
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7859,
    "FrequencyRank": undefined
  },
  {
    "Character": "獴",
    "Pinyin": "měng",
    "Definition": "mongoose",
    "Radical": "犬",
    "RadicalIndex": "94.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6147,
    "FrequencyRank": undefined
  },
  {
    "Character": "橇",
    "Pinyin": "qiāo",
    "Definition": "a sledge for transportation",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6058,
    "FrequencyRank": 4006
  },
  {
    "Character": "氇",
    "Pinyin": "lu",
    "Definition": "thick rough serge from Tibet",
    "Radical": "毛",
    "RadicalIndex": "82.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6120,
    "FrequencyRank": 6396
  },
  {
    "Character": "廪",
    "Pinyin": "lǐn",
    "Definition": "granary; stockpile",
    "Radical": "广",
    "RadicalIndex": "53.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6155,
    "FrequencyRank": 5447
  },
  {
    "Character": "蹀",
    "Pinyin": "dié",
    "Definition": "to skip, to dance; to put the foot down",
    "Radical": "足",
    "RadicalIndex": "157.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6091,
    "FrequencyRank": 6027
  },
  {
    "Character": "璞",
    "Pinyin": "pú",
    "Definition": "unpolished gem, uncarved gem",
    "Radical": "玉",
    "RadicalIndex": "96.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6029,
    "FrequencyRank": 3906
  },
  {
    "Character": "镛",
    "Pinyin": "yōng",
    "Definition": "large bell used as musical instrument",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6116,
    "FrequencyRank": 5640
  },
  {
    "Character": "圜",
    "Pinyin": "huán",
    "Definition": "circle, surround; encircle",
    "Radical": "囗",
    "RadicalIndex": "31.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6110,
    "FrequencyRank": 5244
  },
  {
    "Character": "魈",
    "Pinyin": "xiāo",
    "Definition": "mischevious, one footed spirit that dwells in the mountains",
    "Radical": "鬼",
    "RadicalIndex": "194.7",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6133,
    "FrequencyRank": 5684
  },
  {
    "Character": "赟",
    "Pinyin": "yūn",
    "Definition": "affable, agreeable, pleasant",
    "Radical": "貝",
    "RadicalIndex": "154.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6153,
    "FrequencyRank": 6580
  },
  {
    "Character": "缲",
    "Pinyin": "qiāo",
    "Definition": "to reel",
    "Radical": "糸",
    "RadicalIndex": "120.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6193,
    "FrequencyRank": 7772
  },
  {
    "Character": "疁",
    "Pinyin": "liú",
    "Definition": undefined,
    "Radical": "田",
    "RadicalIndex": "102.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7867,
    "FrequencyRank": undefined
  },
  {
    "Character": "燎",
    "Pinyin": "liáo",
    "Definition": "to burn, set afire; to illuminate; a signal lamp",
    "Radical": "火",
    "RadicalIndex": "86.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6170,
    "FrequencyRank": 4041
  },
  {
    "Character": "擀",
    "Pinyin": "gǎn",
    "Definition": "roll flat",
    "Radical": "手",
    "RadicalIndex": "64.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6039,
    "FrequencyRank": 5469
  },
  {
    "Character": "踽",
    "Pinyin": "jǔ",
    "Definition": "to walk alone; self-reliant",
    "Radical": "足",
    "RadicalIndex": "157.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6094,
    "FrequencyRank": 5222
  },
  {
    "Character": "燧",
    "Pinyin": "suì",
    "Definition": "flintstone; beacon, signal fire; torch",
    "Radical": "火",
    "RadicalIndex": "86.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6173,
    "FrequencyRank": 4808
  },
  {
    "Character": "篦",
    "Pinyin": "bì",
    "Definition": "fine-toothed comb; comb hair",
    "Radical": "竹",
    "RadicalIndex": "118.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6126,
    "FrequencyRank": 5946
  },
  {
    "Character": "殪",
    "Pinyin": "yì",
    "Definition": "die; kill, exterminate",
    "Radical": "歹",
    "RadicalIndex": "78.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6075,
    "FrequencyRank": 7255
  },
  {
    "Character": "髭",
    "Pinyin": "zī",
    "Definition": "mustache",
    "Radical": "髟",
    "RadicalIndex": "190.5",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6037,
    "FrequencyRank": 4816
  },
  {
    "Character": "颟",
    "Pinyin": "mān",
    "Definition": "dawdling; thoughtless, careless",
    "Radical": "頁",
    "RadicalIndex": "181.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6048,
    "FrequencyRank": 6248
  },
  {
    "Character": "薤",
    "Pinyin": "xiè",
    "Definition": "allium bakeri; shallots, scallion",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6049,
    "FrequencyRank": 6805
  },
  {
    "Character": "蕹",
    "Pinyin": "wèng",
    "Definition": "Ipomoea aquatica used as a vegetable",
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7844,
    "FrequencyRank": 6186
  },
  {
    "Character": "踵",
    "Pinyin": "zhǒng",
    "Definition": "heel; follow; visit, call on",
    "Radical": "足",
    "RadicalIndex": "157.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6093,
    "FrequencyRank": 3695
  },
  {
    "Character": "𫄷",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7916,
    "FrequencyRank": undefined
  },
  {
    "Character": "翮",
    "Pinyin": "hé",
    "Definition": "stem of feather; quill",
    "Radical": "羽",
    "RadicalIndex": "124.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6067,
    "FrequencyRank": 6716
  },
  {
    "Character": "懔",
    "Pinyin": "lǐn",
    "Definition": "be afraid of, to be in awe of",
    "Radical": "心",
    "RadicalIndex": "61.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7911,
    "FrequencyRank": 4720
  },
  {
    "Character": "㬚",
    "Pinyin": "chè",
    "Definition": "light; bright, clear",
    "Radical": "日",
    "RadicalIndex": "72.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7862,
    "FrequencyRank": undefined
  },
  {
    "Character": "耩",
    "Pinyin": "jiǎng",
    "Definition": "to plough. to sow",
    "Radical": "耒",
    "RadicalIndex": "127.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6026,
    "FrequencyRank": 7319
  },
  {
    "Character": "镝",
    "Pinyin": "dī",
    "Definition": "dysprosium",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6117,
    "FrequencyRank": 6358
  },
  {
    "Character": "醍",
    "Pinyin": "tí",
    "Definition": "essential oil of butter",
    "Radical": "酉",
    "RadicalIndex": "164.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6070,
    "FrequencyRank": 5966
  },
  {
    "Character": "壅",
    "Pinyin": "yōng",
    "Definition": "to obstruct",
    "Radical": "土",
    "RadicalIndex": "32.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6165,
    "FrequencyRank": 5055
  },
  {
    "Character": "盥",
    "Pinyin": "guàn",
    "Definition": "wash",
    "Radical": "皿",
    "RadicalIndex": "108.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6129,
    "FrequencyRank": 4758
  },
  {
    "Character": "窸",
    "Pinyin": "xī",
    "Definition": "faint sound, whisper",
    "Radical": "穴",
    "RadicalIndex": "116.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6184,
    "FrequencyRank": 4963
  },
  {
    "Character": "橦",
    "Pinyin": "tóng",
    "Definition": "tree",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7847,
    "FrequencyRank": undefined
  },
  {
    "Character": "聱",
    "Pinyin": "áo",
    "Definition": "bent and twisted; too complicated",
    "Radical": "耳",
    "RadicalIndex": "128.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6034,
    "FrequencyRank": 6515
  },
  {
    "Character": "霎",
    "Pinyin": "shà",
    "Definition": "light rain, drizzle; an instant; passing",
    "Radical": "雨",
    "RadicalIndex": "173.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3338,
    "FrequencyRank": 3314
  },
  {
    "Character": "鞔",
    "Pinyin": "mán",
    "Definition": "sides or uppers of shoes to stretch a skin on a frame for a drum; (Cant.) to cover",
    "Radical": "革",
    "RadicalIndex": "177.7",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7839,
    "FrequencyRank": 5912
  },
  {
    "Character": "擐",
    "Pinyin": "huàn",
    "Definition": "to put on",
    "Radical": "手",
    "RadicalIndex": "64.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7836,
    "FrequencyRank": 6899
  },
  {
    "Character": "𫠜",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7856,
    "FrequencyRank": undefined
  },
  {
    "Character": "禧",
    "Pinyin": "xǐ",
    "Definition": "happiness; congratulations",
    "Radical": "示",
    "RadicalIndex": "113.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6186,
    "FrequencyRank": 2085
  },
  {
    "Character": "盦",
    "Pinyin": "ān",
    "Definition": "lid of a caldron; Buddhist cloister",
    "Radical": "皿",
    "RadicalIndex": "108.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7882,
    "FrequencyRank": 7810
  },
  {
    "Character": "澭",
    "Pinyin": "yōng",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7907,
    "FrequencyRank": undefined
  },
  {
    "Character": "螟",
    "Pinyin": "míng",
    "Definition": "kind of caterpillar, larva",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6103,
    "FrequencyRank": 5188
  },
  {
    "Character": "羱",
    "Pinyin": "yuán",
    "Definition": "sheep",
    "Radical": "羊",
    "RadicalIndex": "123.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7895,
    "FrequencyRank": undefined
  },
  {
    "Character": "螗",
    "Pinyin": "táng",
    "Definition": "a kind of cicada",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7866,
    "FrequencyRank": 7090
  },
  {
    "Character": "暾",
    "Pinyin": "tūn",
    "Definition": "morning sun, sunrise",
    "Radical": "日",
    "RadicalIndex": "72.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6090,
    "FrequencyRank": 6112
  },
  {
    "Character": "濋",
    "Pinyin": "chǔ",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7903,
    "FrequencyRank": undefined
  },
  {
    "Character": "橞",
    "Pinyin": "huì",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7845,
    "FrequencyRank": undefined
  },
  {
    "Character": "鲻",
    "Pinyin": "zī",
    "Definition": "mullet",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6146,
    "FrequencyRank": 6701
  },
  {
    "Character": "鼽",
    "Pinyin": "qiú",
    "Definition": "clogged nose",
    "Radical": "鼻",
    "RadicalIndex": "209.2",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7880,
    "FrequencyRank": undefined
  },
  {
    "Character": "䲟",
    "Pinyin": "yìn",
    "Definition": "(simplified form of 鮣) a kind of fish, live in the ocean, dark brown color with two white vertical marks; flat shaped head",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7560,
    "FrequencyRank": 8750
  },
  {
    "Character": "翯",
    "Pinyin": "hè",
    "Definition": "glistening plumage; reflection of the sun on water",
    "Radical": "羽",
    "RadicalIndex": "124.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7915,
    "FrequencyRank": 8081
  },
  {
    "Character": "薏",
    "Pinyin": "yì",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6052,
    "FrequencyRank": 4728
  },
  {
    "Character": "𫗴",
    "Pinyin": "zhān",
    "Definition": "gruel",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7890,
    "FrequencyRank": undefined
  },
  {
    "Character": "霓",
    "Pinyin": "ní",
    "Definition": "rainbow; variegated, colored",
    "Radical": "雨",
    "RadicalIndex": "173.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6078,
    "FrequencyRank": 3609
  },
  {
    "Character": "獭",
    "Pinyin": "tǎ",
    "Definition": "otter",
    "Radical": "犬",
    "RadicalIndex": "94.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6148,
    "FrequencyRank": 4427
  },
  {
    "Character": "潞",
    "Pinyin": "lù",
    "Definition": "river in northern china",
    "Radical": "水",
    "RadicalIndex": "85.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6176,
    "FrequencyRank": 4263
  },
  {
    "Character": "澹",
    "Pinyin": "dàn",
    "Definition": "calm, quiet, tranquil",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6178,
    "FrequencyRank": 4261
  },
  {
    "Character": "錾",
    "Pinyin": "zàn",
    "Definition": "engraving tool, chisel",
    "Radical": "金",
    "RadicalIndex": "167.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6079,
    "FrequencyRank": 4938
  },
  {
    "Character": "璥",
    "Pinyin": "jǐng",
    "Definition": "a kind of jade",
    "Radical": "玉",
    "RadicalIndex": "96.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7832,
    "FrequencyRank": undefined
  },
  {
    "Character": "蕻",
    "Pinyin": "hóng",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6046,
    "FrequencyRank": 6519
  },
  {
    "Character": "螈",
    "Pinyin": "yuán",
    "Definition": "a silkworm",
    "Radical": "虫",
    "RadicalIndex": "142.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6099,
    "FrequencyRank": 5393
  },
  {
    "Character": "踶",
    "Pinyin": "dì",
    "Definition": "paw",
    "Radical": "足",
    "RadicalIndex": "157.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7864,
    "FrequencyRank": undefined
  },
  {
    "Character": "馞",
    "Pinyin": "bó",
    "Definition": undefined,
    "Radical": "香",
    "RadicalIndex": "186.7",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7875,
    "FrequencyRank": undefined
  },
  {
    "Character": "镖",
    "Pinyin": "biāo",
    "Definition": "dart, spear, harpoon; escort",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6112,
    "FrequencyRank": 2727
  },
  {
    "Character": "鄹",
    "Pinyin": "zōu",
    "Definition": "name of a state; surname",
    "Radical": "邑",
    "RadicalIndex": "163.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7837,
    "FrequencyRank": 6166
  },
  {
    "Character": "鲹",
    "Pinyin": "shēn",
    "Definition": "a horse mackerel",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7889,
    "FrequencyRank": 7718
  },
  {
    "Character": "蟒",
    "Pinyin": "mǎng",
    "Definition": "python, boa constrictor",
    "Radical": "虫",
    "RadicalIndex": "142.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6098,
    "FrequencyRank": 3696
  },
  {
    "Character": "濂",
    "Pinyin": "lián",
    "Definition": "a waterfall; a river in Hunan",
    "Radical": "水",
    "RadicalIndex": "85.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6181,
    "FrequencyRank": 4313
  },
  {
    "Character": "蹄",
    "Pinyin": "tí",
    "Definition": "hoof; leg of pork; little witch",
    "Radical": "足",
    "RadicalIndex": "157.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3344,
    "FrequencyRank": 2648
  },
  {
    "Character": "燠",
    "Pinyin": "yù",
    "Definition": "warm; warmth",
    "Radical": "火",
    "RadicalIndex": "86.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6171,
    "FrequencyRank": 6212
  },
  {
    "Character": "褰",
    "Pinyin": "qiān",
    "Definition": undefined,
    "Radical": "衣",
    "RadicalIndex": "145.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6182,
    "FrequencyRank": 5964
  },
  {
    "Character": "膦",
    "Pinyin": "lìn",
    "Definition": undefined,
    "Radical": "肉",
    "RadicalIndex": "130.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6137,
    "FrequencyRank": 7087
  },
  {
    "Character": "虤",
    "Pinyin": "yán",
    "Definition": undefined,
    "Radical": "虍",
    "RadicalIndex": "141.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7858,
    "FrequencyRank": undefined
  },
  {
    "Character": "髻",
    "Pinyin": "jì",
    "Definition": "hair rolled up in a bun, topknot",
    "Radical": "髟",
    "RadicalIndex": "190.6",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6036,
    "FrequencyRank": 4140
  },
  {
    "Character": "黇",
    "Pinyin": "tiān",
    "Definition": undefined,
    "Radical": "黃",
    "RadicalIndex": "201.5",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7840,
    "FrequencyRank": 8438
  },
  {
    "Character": "樾",
    "Pinyin": "yuè",
    "Definition": "the shade of trees",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6056,
    "FrequencyRank": 4777
  },
  {
    "Character": "嬛",
    "Pinyin": "huán",
    "Definition": "apt, clever; sycophant, flatterer",
    "Radical": "女",
    "RadicalIndex": "38.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7913,
    "FrequencyRank": undefined
  },
  {
    "Character": "燚",
    "Pinyin": "yì",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7900,
    "FrequencyRank": undefined
  },
  {
    "Character": "鲳",
    "Pinyin": "chāng",
    "Definition": "the silvery pomfret, Stromateoides argenteus",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6142,
    "FrequencyRank": 6372
  },
  {
    "Character": "犟",
    "Pinyin": "jiàng",
    "Definition": "stubborn",
    "Radical": "牛",
    "RadicalIndex": "93.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6188,
    "FrequencyRank": 4639
  },
  {
    "Character": "樽",
    "Pinyin": "zūn",
    "Definition": "goblet; jar, jug; lush",
    "Radical": "木",
    "RadicalIndex": "75.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6062,
    "FrequencyRank": 4612
  },
  {
    "Character": "磜",
    "Pinyin": "qì",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7852,
    "FrequencyRank": 7711
  },
  {
    "Character": "鲴",
    "Pinyin": "gù",
    "Definition": "fish guts",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6143,
    "FrequencyRank": 6565
  },
  {
    "Character": "隰",
    "Pinyin": "xí",
    "Definition": "low, damp land, marsh, swamp",
    "Radical": "阜",
    "RadicalIndex": "170.14",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6189,
    "FrequencyRank": 6267
  },
  {
    "Character": "觱",
    "Pinyin": "bì",
    "Definition": "tartar horn; chilly wind",
    "Radical": "角",
    "RadicalIndex": "148.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7849,
    "FrequencyRank": 7300
  },
  {
    "Character": "黉",
    "Pinyin": "hóng",
    "Definition": "school",
    "Radical": "黃",
    "RadicalIndex": "201.5",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7912,
    "FrequencyRank": 7225
  },
  {
    "Character": "墼",
    "Pinyin": "jī",
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6065,
    "FrequencyRank": 6304
  },
  {
    "Character": "鲱",
    "Pinyin": "fēi",
    "Definition": "herring",
    "Radical": "魚",
    "RadicalIndex": "195.8",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6140,
    "FrequencyRank": 5489
  },
  {
    "Character": "鞘",
    "Pinyin": "qiào",
    "Definition": "scabbard, sheath",
    "Radical": "革",
    "RadicalIndex": "177.7",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6047,
    "FrequencyRank": 3036
  },
  {
    "Character": "㠓",
    "Pinyin": "méng",
    "Definition": "name of a mountain",
    "Radical": "山",
    "RadicalIndex": "46.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7868,
    "FrequencyRank": undefined
  },
  {
    "Character": "瞰",
    "Pinyin": "kàn",
    "Definition": "watch, spy; overlook; look down",
    "Radical": "目",
    "RadicalIndex": "109.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6086,
    "FrequencyRank": 3895
  },
  {
    "Character": "瘴",
    "Pinyin": "zhàng",
    "Definition": "malaria pestilential vapors",
    "Radical": "疒",
    "RadicalIndex": "104.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6158,
    "FrequencyRank": 4390
  },
  {
    "Character": "憙",
    "Pinyin": "xī",
    "Definition": "like, love, enjoy; joyful thing",
    "Radical": "心",
    "RadicalIndex": "61.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 7835,
    "FrequencyRank": undefined
  },
  {
    "Character": "臻",
    "Pinyin": "zhēn",
    "Definition": "reach, arrive; utmost, superior",
    "Radical": "至",
    "RadicalIndex": "133.1",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6081,
    "FrequencyRank": 3298
  },
  {
    "Character": "踱",
    "Pinyin": "duó",
    "Definition": "stroll, pace, walk slowly",
    "Radical": "足",
    "RadicalIndex": "157.9",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 3343,
    "FrequencyRank": 3009
  },
  {
    "Character": "獬",
    "Pinyin": "xiè",
    "Definition": "a fabulous monster",
    "Radical": "犬",
    "RadicalIndex": "94.13",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6149,
    "FrequencyRank": 5582
  },
  {
    "Character": "瘵",
    "Pinyin": "zhài",
    "Definition": "a wasting disease",
    "Radical": "疒",
    "RadicalIndex": "104.11",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6157,
    "FrequencyRank": 6821
  },
  {
    "Character": "歙",
    "Pinyin": "shè",
    "Definition": undefined,
    "Radical": "欠",
    "RadicalIndex": "76.12",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6135,
    "FrequencyRank": 4946
  },
  {
    "Character": "魉",
    "Pinyin": "liǎng",
    "Definition": "a kind of monster",
    "Radical": "鬼",
    "RadicalIndex": "194.7",
    "StrokeCount": 16,
    "HSK": undefined,
    "GeneralStandard": 6132,
    "FrequencyRank": 5043
  },
  {
    "Character": "鼾",
    "Pinyin": "hān",
    "Definition": "snore loudly",
    "Radical": "鼻",
    "RadicalIndex": "209.3",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6264,
    "FrequencyRank": 3822
  },
  {
    "Character": "謇",
    "Pinyin": "jiǎn",
    "Definition": "stutter; speak out boldly",
    "Radical": "言",
    "RadicalIndex": "149.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6299,
    "FrequencyRank": 4913
  },
  {
    "Character": "𨱑",
    "Pinyin": "huáng",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7873,
    "FrequencyRank": undefined
  },
  {
    "Character": "篾",
    "Pinyin": "miè",
    "Definition": "bamboo splints or slats",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6256,
    "FrequencyRank": 4892
  },
  {
    "Character": "䴕",
    "Pinyin": "liè",
    "Definition": "a woodpecker",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7152,
    "FrequencyRank": 8752
  },
  {
    "Character": "糜",
    "Pinyin": "mí",
    "Definition": "rice gruel, congee; mashed",
    "Radical": "米",
    "RadicalIndex": "119.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6287,
    "FrequencyRank": 3713
  },
  {
    "Character": "黻",
    "Pinyin": "fú",
    "Definition": "special pattern of embroidery",
    "Radical": "黹",
    "RadicalIndex": "204.5",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6224,
    "FrequencyRank": 6435
  },
  {
    "Character": "繇",
    "Pinyin": "yáo",
    "Definition": "reason, cause",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6268,
    "FrequencyRank": 5508
  },
  {
    "Character": "檬",
    "Pinyin": "méng",
    "Definition": "type of locust oracacia",
    "Radical": "木",
    "RadicalIndex": "75.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3394,
    "FrequencyRank": 3939
  },
  {
    "Character": "𨱔",
    "Pinyin": "zūn",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7944,
    "FrequencyRank": undefined
  },
  {
    "Character": "嬷",
    "Pinyin": "mā",
    "Definition": "mother",
    "Radical": "女",
    "RadicalIndex": "38.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6306,
    "FrequencyRank": 4920
  },
  {
    "Character": "蟋",
    "Pinyin": "xī",
    "Definition": "cricket",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3411,
    "FrequencyRank": 3780
  },
  {
    "Character": "黜",
    "Pinyin": "chù",
    "Definition": "dismiss; demote, downgrade",
    "Radical": "黑",
    "RadicalIndex": "203.5",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6241,
    "FrequencyRank": 4159
  },
  {
    "Character": "磴",
    "Pinyin": "dèng",
    "Definition": "steps on ledge, cliff, or hill",
    "Radical": "石",
    "RadicalIndex": "112.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6218,
    "FrequencyRank": 6014
  },
  {
    "Character": "簏",
    "Pinyin": "lù",
    "Definition": "a box, a basket",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7953,
    "FrequencyRank": 6684
  },
  {
    "Character": "魋",
    "Pinyin": "tuí",
    "Definition": "bear",
    "Radical": "鬼",
    "RadicalIndex": "194.8",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7955,
    "FrequencyRank": 7168
  },
  {
    "Character": "蟏",
    "Pinyin": "xiāo",
    "Definition": "a kind of spider with long legs",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7935,
    "FrequencyRank": undefined
  },
  {
    "Character": "璩",
    "Pinyin": "qú",
    "Definition": "jade ring; earrings; surname",
    "Radical": "玉",
    "RadicalIndex": "96.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6196,
    "FrequencyRank": 6081
  },
  {
    "Character": "蹐",
    "Pinyin": "jí",
    "Definition": "take short steps",
    "Radical": "足",
    "RadicalIndex": "157.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7934,
    "FrequencyRank": 6984
  },
  {
    "Character": "豳",
    "Pinyin": "bīn",
    "Definition": "a Zhou-dynasty state",
    "Radical": "豕",
    "RadicalIndex": "152.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6222,
    "FrequencyRank": 6279
  },
  {
    "Character": "懑",
    "Pinyin": "mèn",
    "Definition": "be sick at heart, sorrowful, sad",
    "Radical": "心",
    "RadicalIndex": "61.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6292,
    "FrequencyRank": 4480
  },
  {
    "Character": "濡",
    "Pinyin": "rú",
    "Definition": "immerse, moisten; wet, damp",
    "Radical": "水",
    "RadicalIndex": "85.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6293,
    "FrequencyRank": 4566
  },
  {
    "Character": "罾",
    "Pinyin": "zēng",
    "Definition": "a large square net, lowered and raised from the bank of the river",
    "Radical": "网",
    "RadicalIndex": "122.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6239,
    "FrequencyRank": 5786
  },
  {
    "Character": "鳈",
    "Pinyin": "quán",
    "Definition": "name of fish",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7967,
    "FrequencyRank": 6667
  },
  {
    "Character": "磹",
    "Pinyin": "tán",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7930,
    "FrequencyRank": 8596
  },
  {
    "Character": "臆",
    "Pinyin": "yì",
    "Definition": "chest, breast, bosom; thought",
    "Radical": "肉",
    "RadicalIndex": "130.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6274,
    "FrequencyRank": 3709
  },
  {
    "Character": "皤",
    "Pinyin": "pó",
    "Definition": "white, grey; corpulent",
    "Radical": "白",
    "RadicalIndex": "106.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6265,
    "FrequencyRank": 6051
  },
  {
    "Character": "黝",
    "Pinyin": "yǒu",
    "Definition": "black",
    "Radical": "黑",
    "RadicalIndex": "203.5",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6242,
    "FrequencyRank": 3518
  },
  {
    "Character": "鲾",
    "Pinyin": "bī",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7962,
    "FrequencyRank": 8391
  },
  {
    "Character": "燮",
    "Pinyin": "xiè",
    "Definition": "harmonize, blend; adjust",
    "Radical": "火",
    "RadicalIndex": "86.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6284,
    "FrequencyRank": 4433
  },
  {
    "Character": "鍪",
    "Pinyin": "móu",
    "Definition": "an iron pan; a metal cap",
    "Radical": "金",
    "RadicalIndex": "167.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6309,
    "FrequencyRank": 4735
  },
  {
    "Character": "螫",
    "Pinyin": "shì",
    "Definition": "poison; sting; poisonous insect",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6199,
    "FrequencyRank": 5017
  },
  {
    "Character": "檀",
    "Pinyin": "tán",
    "Definition": "sandalwood, hardwood; surname",
    "Radical": "木",
    "RadicalIndex": "75.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3396,
    "FrequencyRank": 3421
  },
  {
    "Character": "䴔",
    "Pinyin": "jiāo",
    "Definition": "the fishing cormorant",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7224,
    "FrequencyRank": 9020
  },
  {
    "Character": "瞳",
    "Pinyin": "tóng",
    "Definition": "pupil of eye",
    "Radical": "目",
    "RadicalIndex": "109.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3404,
    "FrequencyRank": 3733
  },
  {
    "Character": "檄",
    "Pinyin": "xí",
    "Definition": "call arms; urgency",
    "Radical": "木",
    "RadicalIndex": "75.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6212,
    "FrequencyRank": 4689
  },
  {
    "Character": "螵",
    "Pinyin": "piāo",
    "Definition": "a chrysalis",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6232,
    "FrequencyRank": 6842
  },
  {
    "Character": "癍",
    "Pinyin": "bān",
    "Definition": "unhealthy marks on the skin",
    "Radical": "疒",
    "RadicalIndex": "104.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6290,
    "FrequencyRank": 6730
  },
  {
    "Character": "檗",
    "Pinyin": "bò",
    "Definition": "tree",
    "Radical": "木",
    "RadicalIndex": "75.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6302,
    "FrequencyRank": 6614
  },
  {
    "Character": "鳂",
    "Pinyin": "wēi",
    "Definition": "name of fish",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7966,
    "FrequencyRank": 8831
  },
  {
    "Character": "穜",
    "Pinyin": "zhǒng",
    "Definition": "rice",
    "Radical": "禾",
    "RadicalIndex": "115.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7949,
    "FrequencyRank": 8855
  },
  {
    "Character": "擢",
    "Pinyin": "zhuó",
    "Definition": "pull up, draw up; select",
    "Radical": "手",
    "RadicalIndex": "64.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6204,
    "FrequencyRank": 4477
  },
  {
    "Character": "懋",
    "Pinyin": "mào",
    "Definition": "splendid, grand, majestic",
    "Radical": "心",
    "RadicalIndex": "61.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6214,
    "FrequencyRank": 4350
  },
  {
    "Character": "蹒",
    "Pinyin": "pán",
    "Definition": "to jump over; to limp",
    "Radical": "足",
    "RadicalIndex": "157.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6228,
    "FrequencyRank": 4016
  },
  {
    "Character": "簃",
    "Pinyin": "yí",
    "Definition": "side room",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7952,
    "FrequencyRank": 8450
  },
  {
    "Character": "醢",
    "Pinyin": "hǎi",
    "Definition": "minced pickled meat; mince",
    "Radical": "酉",
    "RadicalIndex": "164.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6215,
    "FrequencyRank": 5363
  },
  {
    "Character": "黛",
    "Pinyin": "dài",
    "Definition": "blacken eyebrows; black",
    "Radical": "黑",
    "RadicalIndex": "203.5",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6261,
    "FrequencyRank": 2612
  },
  {
    "Character": "擘",
    "Pinyin": "bāi",
    "Definition": "thumb; break, tear open, rip",
    "Radical": "手",
    "RadicalIndex": "64.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6303,
    "FrequencyRank": 5448
  },
  {
    "Character": "磷",
    "Pinyin": "lín",
    "Definition": "phosphorus; water rushing between",
    "Radical": "石",
    "RadicalIndex": "112.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3398,
    "FrequencyRank": 2956
  },
  {
    "Character": "濯",
    "Pinyin": "zhuó",
    "Definition": "wash out, rinse; cleanse",
    "Radical": "水",
    "RadicalIndex": "85.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6297,
    "FrequencyRank": 4757
  },
  {
    "Character": "擤",
    "Pinyin": "xǐng",
    "Definition": "to blow the nose with fingers",
    "Radical": "手",
    "RadicalIndex": "64.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6200,
    "FrequencyRank": 5361
  },
  {
    "Character": "罽",
    "Pinyin": "jì",
    "Definition": "a kind of woolen fabric",
    "Radical": "网",
    "RadicalIndex": "122.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6238,
    "FrequencyRank": 7032
  },
  {
    "Character": "龋",
    "Pinyin": "qǔ",
    "Definition": "tooth decay",
    "Radical": "齒",
    "RadicalIndex": "211.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6220,
    "FrequencyRank": 3496
  },
  {
    "Character": "邃",
    "Pinyin": "suì",
    "Definition": "profound, detailed; deep",
    "Radical": "辵",
    "RadicalIndex": "162.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6300,
    "FrequencyRank": 3950
  },
  {
    "Character": "龌",
    "Pinyin": "wò",
    "Definition": "narrow, small; dirty",
    "Radical": "齒",
    "RadicalIndex": "211.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6221,
    "FrequencyRank": 4509
  },
  {
    "Character": "醨",
    "Pinyin": "lí",
    "Definition": "dregs of wine",
    "Radical": "酉",
    "RadicalIndex": "164.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7928,
    "FrequencyRank": 7624
  },
  {
    "Character": "螱",
    "Pinyin": "wèi",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7975,
    "FrequencyRank": undefined
  },
  {
    "Character": "壕",
    "Pinyin": "háo",
    "Definition": "trench, ditch, channel, moat",
    "Radical": "土",
    "RadicalIndex": "32.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6201,
    "FrequencyRank": 3077
  },
  {
    "Character": "簖",
    "Pinyin": "duàn",
    "Definition": "bamboo trap for catching fish",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6258,
    "FrequencyRank": undefined
  },
  {
    "Character": "罄",
    "Pinyin": "qìng",
    "Definition": "exhaust, run out, use up; empty",
    "Radical": "缶",
    "RadicalIndex": "121.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6203,
    "FrequencyRank": 4542
  },
  {
    "Character": "螳",
    "Pinyin": "táng",
    "Definition": "mantis",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6234,
    "FrequencyRank": 4267
  },
  {
    "Character": "𫟦",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7854,
    "FrequencyRank": undefined
  },
  {
    "Character": "襁",
    "Pinyin": "qiǎng",
    "Definition": "swaddling clothes",
    "Radical": "衣",
    "RadicalIndex": "145.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6301,
    "FrequencyRank": 5023
  },
  {
    "Character": "甓",
    "Pinyin": "pì",
    "Definition": "glazed tiles, bricks",
    "Radical": "瓦",
    "RadicalIndex": "98.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7976,
    "FrequencyRank": 6096
  },
  {
    "Character": "鞬",
    "Pinyin": "jiān",
    "Definition": "a quiver on a horse; a store",
    "Radical": "革",
    "RadicalIndex": "177.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6207,
    "FrequencyRank": 6401
  },
  {
    "Character": "鳁",
    "Pinyin": "wēn",
    "Definition": "sardine",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7965,
    "FrequencyRank": 7152
  },
  {
    "Character": "襚",
    "Pinyin": "suì",
    "Definition": "grave clothes",
    "Radical": "衣",
    "RadicalIndex": "145.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7973,
    "FrequencyRank": undefined
  },
  {
    "Character": "髽",
    "Pinyin": "zhuā",
    "Definition": "to dress the hair",
    "Radical": "髟",
    "RadicalIndex": "190.7",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7921,
    "FrequencyRank": 9012
  },
  {
    "Character": "蹑",
    "Pinyin": "niè",
    "Definition": "tread, step on; follow, track",
    "Radical": "足",
    "RadicalIndex": "157.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6227,
    "FrequencyRank": 3665
  },
  {
    "Character": "薷",
    "Pinyin": "rú",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6208,
    "FrequencyRank": 6295
  },
  {
    "Character": "镪",
    "Pinyin": "qiāng",
    "Definition": "coins, money, wealth",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6251,
    "FrequencyRank": 6190
  },
  {
    "Character": "簕",
    "Pinyin": "lè",
    "Definition": "(Cant.) thorn",
    "Radical": "竹",
    "RadicalIndex": "118.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7951,
    "FrequencyRank": 8783
  },
  {
    "Character": "鲿",
    "Pinyin": "cháng",
    "Definition": "codfish",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7964,
    "FrequencyRank": 6723
  },
  {
    "Character": "疃",
    "Pinyin": "tuǎn",
    "Definition": "hamlet; area outside city",
    "Radical": "田",
    "RadicalIndex": "102.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6233,
    "FrequencyRank": 5693
  },
  {
    "Character": "蟑",
    "Pinyin": "zhāng",
    "Definition": "cockroach",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6235,
    "FrequencyRank": 4269
  },
  {
    "Character": "繄",
    "Pinyin": "yī",
    "Definition": "be; particle; sigh, alas",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7929,
    "FrequencyRank": undefined
  },
  {
    "Character": "镣",
    "Pinyin": "liào",
    "Definition": "fetters",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6247,
    "FrequencyRank": 4403
  },
  {
    "Character": "檐",
    "Pinyin": "yán",
    "Definition": "eaves of house; brim, rim",
    "Radical": "木",
    "RadicalIndex": "75.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3395,
    "FrequencyRank": 3102
  },
  {
    "Character": "礅",
    "Pinyin": "dūn",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6217,
    "FrequencyRank": 6453
  },
  {
    "Character": "鹩",
    "Pinyin": "liáo",
    "Definition": "wren",
    "Radical": "鳥",
    "RadicalIndex": "196.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6219,
    "FrequencyRank": 5345
  },
  {
    "Character": "蟥",
    "Pinyin": "huáng",
    "Definition": "leech",
    "Radical": "虫",
    "RadicalIndex": "142.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6230,
    "FrequencyRank": 6026
  },
  {
    "Character": "鹬",
    "Pinyin": "yù",
    "Definition": "snipe, kingfisher",
    "Radical": "鳥",
    "RadicalIndex": "196.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6308,
    "FrequencyRank": 4881
  },
  {
    "Character": "檞",
    "Pinyin": "jiě",
    "Definition": "a kind of oak",
    "Radical": "木",
    "RadicalIndex": "75.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7927,
    "FrequencyRank": undefined
  },
  {
    "Character": "镨",
    "Pinyin": "pǔ",
    "Definition": "praseodymium",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7942,
    "FrequencyRank": 6592
  },
  {
    "Character": "鳃",
    "Pinyin": "sāi",
    "Definition": "fish gills",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6279,
    "FrequencyRank": 3816
  },
  {
    "Character": "磻",
    "Pinyin": "pán",
    "Definition": "a tributary of the Wei river in Shanxi",
    "Radical": "石",
    "RadicalIndex": "112.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7931,
    "FrequencyRank": 6458
  },
  {
    "Character": "嬬",
    "Pinyin": "rú",
    "Definition": "mistress, concubine; weak",
    "Radical": "女",
    "RadicalIndex": "38.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7977,
    "FrequencyRank": undefined
  },
  {
    "Character": "膺",
    "Pinyin": "yīng",
    "Definition": "breast, chest; undertake, bear",
    "Radical": "肉",
    "RadicalIndex": "130.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6289,
    "FrequencyRank": 3783
  },
  {
    "Character": "篼",
    "Pinyin": "dōu",
    "Definition": "mountain sedan chair; (Cant.) a simple container without a cover",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6257,
    "FrequencyRank": 8311
  },
  {
    "Character": "簧",
    "Pinyin": "huáng",
    "Definition": "reed of woodwind instrument",
    "Radical": "竹",
    "RadicalIndex": "118.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3417,
    "FrequencyRank": 3370
  },
  {
    "Character": "艚",
    "Pinyin": "cáo",
    "Definition": "ship",
    "Radical": "舟",
    "RadicalIndex": "137.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7957,
    "FrequencyRank": 6017
  },
  {
    "Character": "隳",
    "Pinyin": "huī",
    "Definition": "to destroy; to overthrow",
    "Radical": "阜",
    "RadicalIndex": "170.15",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6305,
    "FrequencyRank": 5636
  },
  {
    "Character": "鹪",
    "Pinyin": "jiāo",
    "Definition": "wren",
    "Radical": "鳥",
    "RadicalIndex": "196.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6263,
    "FrequencyRank": 5911
  },
  {
    "Character": "臃",
    "Pinyin": "yōng",
    "Definition": "swell up; swelling; fat",
    "Radical": "肉",
    "RadicalIndex": "130.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6275,
    "FrequencyRank": 4439
  },
  {
    "Character": "膻",
    "Pinyin": "shān",
    "Definition": "a rank odor; a technical term from Chinese medicine for the center of the chest",
    "Radical": "肉",
    "RadicalIndex": "130.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6273,
    "FrequencyRank": 5647
  },
  {
    "Character": "璮",
    "Pinyin": "tǎn",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7920,
    "FrequencyRank": undefined
  },
  {
    "Character": "穙",
    "Pinyin": "pú",
    "Definition": undefined,
    "Radical": "禾",
    "RadicalIndex": "115.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7948,
    "FrequencyRank": 9075
  },
  {
    "Character": "嬥",
    "Pinyin": "tiǎo",
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7978,
    "FrequencyRank": undefined
  },
  {
    "Character": "鳊",
    "Pinyin": "biān",
    "Definition": "bream",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6282,
    "FrequencyRank": 5400
  },
  {
    "Character": "儦",
    "Pinyin": "biāo",
    "Definition": "milling",
    "Radical": "人",
    "RadicalIndex": "9.15",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7954,
    "FrequencyRank": undefined
  },
  {
    "Character": "櫆",
    "Pinyin": "kuí",
    "Definition": "Polaris, the north star",
    "Radical": "木",
    "RadicalIndex": "75.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7926,
    "FrequencyRank": undefined
  },
  {
    "Character": "镫",
    "Pinyin": "dèng",
    "Definition": "lamp; a kind of cooking vessel",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6252,
    "FrequencyRank": 5605
  },
  {
    "Character": "璱",
    "Pinyin": "sè",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7917,
    "FrequencyRank": undefined
  },
  {
    "Character": "璐",
    "Pinyin": "lù",
    "Definition": "beautiful variety of jade",
    "Radical": "玉",
    "RadicalIndex": "96.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6197,
    "FrequencyRank": 3810
  },
  {
    "Character": "貔",
    "Pinyin": "pí",
    "Definition": "fox, leopard, panther",
    "Radical": "豸",
    "RadicalIndex": "153.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6271,
    "FrequencyRank": 6414
  },
  {
    "Character": "薰",
    "Pinyin": "xūn",
    "Definition": "a medicinal herb; to cauterize",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6209,
    "FrequencyRank": 4055
  },
  {
    "Character": "濠",
    "Pinyin": "háo",
    "Definition": "moat, trench, ditch",
    "Radical": "水",
    "RadicalIndex": "85.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6296,
    "FrequencyRank": 5025
  },
  {
    "Character": "薿",
    "Pinyin": "nǐ",
    "Definition": "flower",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7923,
    "FrequencyRank": 7294
  },
  {
    "Character": "𫔍",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7940,
    "FrequencyRank": undefined
  },
  {
    "Character": "濮",
    "Pinyin": "pú",
    "Definition": "county in Henan province",
    "Radical": "水",
    "RadicalIndex": "85.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6294,
    "FrequencyRank": 4279
  },
  {
    "Character": "薹",
    "Pinyin": "tái",
    "Definition": "cyperus rotundus, type of sedge",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6205,
    "FrequencyRank": 5711
  },
  {
    "Character": "鳀",
    "Pinyin": "tí",
    "Definition": "anchovy",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6278,
    "FrequencyRank": 6931
  },
  {
    "Character": "髀",
    "Pinyin": "bì",
    "Definition": "buttocks; thigh, thigh bone",
    "Radical": "骨",
    "RadicalIndex": "188.8",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6244,
    "FrequencyRank": 5978
  },
  {
    "Character": "螽",
    "Pinyin": "zhōng",
    "Definition": "katydid",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6283,
    "FrequencyRank": 5830
  },
  {
    "Character": "璨",
    "Pinyin": "càn",
    "Definition": "gems; luster of gems; lustrous",
    "Radical": "玉",
    "RadicalIndex": "96.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6195,
    "FrequencyRank": 4638
  },
  {
    "Character": "襄",
    "Pinyin": "xiāng",
    "Definition": "aid, help, assist; undress",
    "Radical": "衣",
    "RadicalIndex": "145.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6286,
    "FrequencyRank": 2980
  },
  {
    "Character": "瞵",
    "Pinyin": "lín",
    "Definition": "to stare at",
    "Radical": "目",
    "RadicalIndex": "109.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7933,
    "FrequencyRank": 8509
  },
  {
    "Character": "鞡",
    "Pinyin": "la",
    "Definition": "leg warmer",
    "Radical": "革",
    "RadicalIndex": "177.8",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6206,
    "FrequencyRank": 7001
  },
  {
    "Character": "貘",
    "Pinyin": "mò",
    "Definition": "the panther; the tapir",
    "Radical": "豸",
    "RadicalIndex": "153.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6269,
    "FrequencyRank": 6325
  },
  {
    "Character": "魍",
    "Pinyin": "wǎng",
    "Definition": "demons, mountain spirits",
    "Radical": "鬼",
    "RadicalIndex": "194.8",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6266,
    "FrequencyRank": 5298
  },
  {
    "Character": "镤",
    "Pinyin": "pú",
    "Definition": "protactinium (Pa)",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7938,
    "FrequencyRank": 7837
  },
  {
    "Character": "蟀",
    "Pinyin": "shuài",
    "Definition": "cricket",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3412,
    "FrequencyRank": 3792
  },
  {
    "Character": "臌",
    "Pinyin": "gǔ",
    "Definition": "dropsical swelling; puffy bloated",
    "Radical": "肉",
    "RadicalIndex": "130.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6272,
    "FrequencyRank": 6980
  },
  {
    "Character": "鲽",
    "Pinyin": "dié",
    "Definition": "flatfish; flounder; sole",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6277,
    "FrequencyRank": 6555
  },
  {
    "Character": "矰",
    "Pinyin": "zēng",
    "Definition": "arrow with attached silk cord",
    "Radical": "矢",
    "RadicalIndex": "111.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7947,
    "FrequencyRank": 7812
  },
  {
    "Character": "濞",
    "Pinyin": "bì",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6295,
    "FrequencyRank": 5982
  },
  {
    "Character": "嚎",
    "Pinyin": "háo",
    "Definition": "cry loudly, yell, scream",
    "Radical": "口",
    "RadicalIndex": "30.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3413,
    "FrequencyRank": 3241
  },
  {
    "Character": "簌",
    "Pinyin": "sù",
    "Definition": "(of flower petals) falling",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6255,
    "FrequencyRank": 3954
  },
  {
    "Character": "瞫",
    "Pinyin": "shěn",
    "Definition": "look",
    "Radical": "目",
    "RadicalIndex": "109.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7932,
    "FrequencyRank": 8025
  },
  {
    "Character": "糠",
    "Pinyin": "kāng",
    "Definition": "chaff, bran, husks; poor",
    "Radical": "米",
    "RadicalIndex": "119.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3429,
    "FrequencyRank": 4169
  },
  {
    "Character": "翳",
    "Pinyin": "yì",
    "Definition": "shade, screen; to hide, screen",
    "Radical": "羽",
    "RadicalIndex": "124.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6216,
    "FrequencyRank": 4968
  },
  {
    "Character": "璬",
    "Pinyin": "jiǎo",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7919,
    "FrequencyRank": undefined
  },
  {
    "Character": "檩",
    "Pinyin": "lǐn",
    "Definition": "the bole of a tree; a cross beam; the combing round the hatches of a ship",
    "Radical": "木",
    "RadicalIndex": "75.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6213,
    "FrequencyRank": 5662
  },
  {
    "Character": "𦈡",
    "Pinyin": "xū",
    "Definition": undefined,
    "Radical": "糸",
    "RadicalIndex": "120.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7979,
    "FrequencyRank": undefined
  },
  {
    "Character": "藉",
    "Pinyin": "jí",
    "Definition": "mat, pad; rely on; pretext",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3390,
    "FrequencyRank": 2615
  },
  {
    "Character": "獯",
    "Pinyin": "xūn",
    "Definition": "a tribe of Scythians which invaded China during the Hsia dynasty",
    "Radical": "犬",
    "RadicalIndex": "94.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7969,
    "FrequencyRank": 5411
  },
  {
    "Character": "鲼",
    "Pinyin": "fèn",
    "Definition": "fish",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6276,
    "FrequencyRank": 7959
  },
  {
    "Character": "鹫",
    "Pinyin": "jiù",
    "Definition": "condor, vulture",
    "Radical": "鳥",
    "RadicalIndex": "196.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6285,
    "FrequencyRank": 4384
  },
  {
    "Character": "嶷",
    "Pinyin": "yí",
    "Definition": "a range of mountains in Hunan province",
    "Radical": "山",
    "RadicalIndex": "46.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6240,
    "FrequencyRank": 6333
  },
  {
    "Character": "鳉",
    "Pinyin": "jiāng",
    "Definition": "name of fish",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7968,
    "FrequencyRank": 7215
  },
  {
    "Character": "螬",
    "Pinyin": "cáo",
    "Definition": "grubs in fruit",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6231,
    "FrequencyRank": 5726
  },
  {
    "Character": "觳",
    "Pinyin": "hú",
    "Definition": "an ancient measure; a goblet; mean; frightened",
    "Radical": "角",
    "RadicalIndex": "148.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6202,
    "FrequencyRank": 5575
  },
  {
    "Character": "镢",
    "Pinyin": "jué",
    "Definition": "hoe",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6246,
    "FrequencyRank": 5288
  },
  {
    "Character": "璪",
    "Pinyin": "zǎo",
    "Definition": "pearl pendants on coronet",
    "Radical": "玉",
    "RadicalIndex": "96.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6198,
    "FrequencyRank": undefined
  },
  {
    "Character": "蟊",
    "Pinyin": "máo",
    "Definition": "a fly which is used similarly to cantharides",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6307,
    "FrequencyRank": 5414
  },
  {
    "Character": "藓",
    "Pinyin": "xiǎn",
    "Definition": "moss, lichen",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6210,
    "FrequencyRank": 4187
  },
  {
    "Character": "麋",
    "Pinyin": "mí",
    "Definition": "elk; surname",
    "Radical": "鹿",
    "RadicalIndex": "198.6",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6291,
    "FrequencyRank": 4702
  },
  {
    "Character": "镩",
    "Pinyin": "cuān",
    "Definition": "pick, poker",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6250,
    "FrequencyRank": 7068
  },
  {
    "Character": "擿",
    "Pinyin": "tī",
    "Definition": "to select, to pick out from, to discard",
    "Radical": "手",
    "RadicalIndex": "64.15",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7922,
    "FrequencyRank": undefined
  },
  {
    "Character": "罅",
    "Pinyin": "xià",
    "Definition": "crack, fissure, split",
    "Radical": "缶",
    "RadicalIndex": "121.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6253,
    "FrequencyRank": 4673
  },
  {
    "Character": "鼢",
    "Pinyin": "fén",
    "Definition": "a variety of mole",
    "Radical": "鼠",
    "RadicalIndex": "208.4",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6260,
    "FrequencyRank": 7423
  },
  {
    "Character": "羁",
    "Pinyin": "jī",
    "Definition": "halter; restrain, hold, control",
    "Radical": "网",
    "RadicalIndex": "122.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6237,
    "FrequencyRank": 3297
  },
  {
    "Character": "龠",
    "Pinyin": "yuè",
    "Definition": "flute; pipe, ancient measure",
    "Radical": "龠",
    "RadicalIndex": "214",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6267,
    "FrequencyRank": 6897
  },
  {
    "Character": "谿",
    "Pinyin": "xī",
    "Definition": "valley, gorge; mountain stream",
    "Radical": "谷",
    "RadicalIndex": "150.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7959,
    "FrequencyRank": 6347
  },
  {
    "Character": "薸",
    "Pinyin": "piáo",
    "Definition": "duckweed",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7924,
    "FrequencyRank": undefined
  },
  {
    "Character": "穗",
    "Pinyin": "suì",
    "Definition": "ear of grain; tassel; Guangzhou",
    "Radical": "禾",
    "RadicalIndex": "115.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3415,
    "FrequencyRank": 3378
  },
  {
    "Character": "鳅",
    "Pinyin": "qiū",
    "Definition": "loach",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6280,
    "FrequencyRank": 4462
  },
  {
    "Character": "𫔎",
    "Pinyin": "jué",
    "Definition": "hasp of a lock",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7946,
    "FrequencyRank": undefined
  },
  {
    "Character": "蹇",
    "Pinyin": "jiǎn",
    "Definition": "lame, crippled; unlucky",
    "Radical": "足",
    "RadicalIndex": "157.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6298,
    "FrequencyRank": 5073
  },
  {
    "Character": "䗪",
    "Pinyin": "zhè",
    "Definition": "a kind of worm; can be used in Chinese medicine",
    "Radical": "虫",
    "RadicalIndex": "142.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7970,
    "FrequencyRank": undefined
  },
  {
    "Character": "鳇",
    "Pinyin": "huáng",
    "Definition": "sturgeon",
    "Radical": "魚",
    "RadicalIndex": "195.9",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6281,
    "FrequencyRank": 5990
  },
  {
    "Character": "懦",
    "Pinyin": "nuò",
    "Definition": "weak, timid, cowardly",
    "Radical": "心",
    "RadicalIndex": "61.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3431,
    "FrequencyRank": 3174
  },
  {
    "Character": "蹊",
    "Pinyin": "qī",
    "Definition": "footpath, trail; track",
    "Radical": "足",
    "RadicalIndex": "157.1",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6229,
    "FrequencyRank": 3935
  },
  {
    "Character": "嚅",
    "Pinyin": "rú",
    "Definition": "talk indistinctly and falteringly",
    "Radical": "口",
    "RadicalIndex": "30.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6226,
    "FrequencyRank": 4156
  },
  {
    "Character": "镧",
    "Pinyin": "lán",
    "Definition": "lanthanum",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6249,
    "FrequencyRank": 5547
  },
  {
    "Character": "儡",
    "Pinyin": "lěi",
    "Definition": "puppet, dummy",
    "Radical": "人",
    "RadicalIndex": "9.15",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6262,
    "FrequencyRank": 3992
  },
  {
    "Character": "镦",
    "Pinyin": "duì",
    "Definition": "ferrule; castrate",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6248,
    "FrequencyRank": 7920
  },
  {
    "Character": "镥",
    "Pinyin": "lǔ",
    "Definition": "lutetium",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7941,
    "FrequencyRank": 7854
  },
  {
    "Character": "壑",
    "Pinyin": "hè",
    "Definition": "bed of torrent, narrow ravine",
    "Radical": "土",
    "RadicalIndex": "32.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6223,
    "FrequencyRank": 3880
  },
  {
    "Character": "簋",
    "Pinyin": "guǐ",
    "Definition": "a square basket of bamboo for holding grain used at sacrifices, feast",
    "Radical": "竹",
    "RadicalIndex": "118.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6259,
    "FrequencyRank": 6715
  },
  {
    "Character": "檑",
    "Pinyin": "léi",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7925,
    "FrequencyRank": 7574
  },
  {
    "Character": "邈",
    "Pinyin": "miǎo",
    "Definition": "far, distant, remote; slight",
    "Radical": "辵",
    "RadicalIndex": "162.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6270,
    "FrequencyRank": 4944
  },
  {
    "Character": "斶",
    "Pinyin": "chù",
    "Definition": undefined,
    "Radical": "斤",
    "RadicalIndex": "69.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7956,
    "FrequencyRank": undefined
  },
  {
    "Character": "嚓",
    "Pinyin": "cā",
    "Definition": "a cracking or snapping sound",
    "Radical": "口",
    "RadicalIndex": "30.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6236,
    "FrequencyRank": 3600
  },
  {
    "Character": "臀",
    "Pinyin": "tún",
    "Definition": "buttocks",
    "Radical": "肉",
    "RadicalIndex": "130.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3433,
    "FrequencyRank": 3199
  },
  {
    "Character": "穟",
    "Pinyin": "suì",
    "Definition": "ear of grain; ripe grain",
    "Radical": "禾",
    "RadicalIndex": "115.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7950,
    "FrequencyRank": 9863
  },
  {
    "Character": "𤩽",
    "Pinyin": "huán",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.13",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7918,
    "FrequencyRank": undefined
  },
  {
    "Character": "髁",
    "Pinyin": "kē",
    "Definition": "thigh bone, hipbone; kneecap",
    "Radical": "骨",
    "RadicalIndex": "188.8",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6243,
    "FrequencyRank": 5080
  },
  {
    "Character": "𫄸",
    "Pinyin": "xūn",
    "Definition": "light red, pink",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7980,
    "FrequencyRank": undefined
  },
  {
    "Character": "縻",
    "Pinyin": "mí",
    "Definition": "halter for ox; tie up, harness",
    "Radical": "糸",
    "RadicalIndex": "120.11",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6288,
    "FrequencyRank": 5629
  },
  {
    "Character": "馘",
    "Pinyin": "guó",
    "Definition": "cut off left ear; tally dead enemy",
    "Radical": "首",
    "RadicalIndex": "185.8",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7971,
    "FrequencyRank": 6759
  },
  {
    "Character": "藁",
    "Pinyin": "gǎo",
    "Definition": "straw, hay; dry, withered",
    "Radical": "艸",
    "RadicalIndex": "140.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6211,
    "FrequencyRank": 6044
  },
  {
    "Character": "襕",
    "Pinyin": "lán",
    "Definition": undefined,
    "Radical": "衣",
    "RadicalIndex": "145.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 7972,
    "FrequencyRank": 7248
  },
  {
    "Character": "镡",
    "Pinyin": "chán",
    "Definition": "dagger; small sword",
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6245,
    "FrequencyRank": 6591
  },
  {
    "Character": "瞭",
    "Pinyin": "liǎo",
    "Definition": "bright, clear; clear-sighted",
    "Radical": "目",
    "RadicalIndex": "109.12",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 3401,
    "FrequencyRank": 4345
  },
  {
    "Character": "孺",
    "Pinyin": "rú",
    "Definition": "child; blood relation; affection",
    "Radical": "子",
    "RadicalIndex": "39.14",
    "StrokeCount": 17,
    "HSK": undefined,
    "GeneralStandard": 6304,
    "FrequencyRank": 4084
  },
  {
    "Character": "镯",
    "Pinyin": "zhuó",
    "Definition": "bracelet, armband; small bell",
    "Radical": "金",
    "RadicalIndex": "167.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6346,
    "FrequencyRank": 3854
  },
  {
    "Character": "镰",
    "Pinyin": "lián",
    "Definition": "sickle",
    "Radical": "金",
    "RadicalIndex": "167.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 3444,
    "FrequencyRank": 3824
  },
  {
    "Character": "鳎",
    "Pinyin": "tǎ",
    "Definition": "sole",
    "Radical": "魚",
    "RadicalIndex": "195.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6353,
    "FrequencyRank": 4080
  },
  {
    "Character": "馧",
    "Pinyin": "yūn",
    "Definition": undefined,
    "Radical": "香",
    "RadicalIndex": "186.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8006,
    "FrequencyRank": 7644
  },
  {
    "Character": "皦",
    "Pinyin": "jiǎo",
    "Definition": "bright white; bright, clear",
    "Radical": "白",
    "RadicalIndex": "106.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8012,
    "FrequencyRank": undefined
  },
  {
    "Character": "藦",
    "Pinyin": "mò",
    "Definition": "edible mushrooms",
    "Radical": "艸",
    "RadicalIndex": "140.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7989,
    "FrequencyRank": 8339
  },
  {
    "Character": "醪",
    "Pinyin": "láo",
    "Definition": "unclear wine, wine with dregs",
    "Radical": "酉",
    "RadicalIndex": "164.11",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6323,
    "FrequencyRank": 5193
  },
  {
    "Character": "颢",
    "Pinyin": "hào",
    "Definition": "luminous; white, hoary",
    "Radical": "頁",
    "RadicalIndex": "181.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6330,
    "FrequencyRank": 5021
  },
  {
    "Character": "鬃",
    "Pinyin": "zōng",
    "Definition": "mane; neck bristles",
    "Radical": "髟",
    "RadicalIndex": "190.8",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6313,
    "FrequencyRank": 4327
  },
  {
    "Character": "雠",
    "Pinyin": "chóu",
    "Definition": "enemy, rival, opponent",
    "Radical": "隹",
    "RadicalIndex": "172.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6351,
    "FrequencyRank": 5545
  },
  {
    "Character": "鞮",
    "Pinyin": "dī",
    "Definition": "leather shoes",
    "Radical": "革",
    "RadicalIndex": "177.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7986,
    "FrequencyRank": 6826
  },
  {
    "Character": "燹",
    "Pinyin": "xiǎn",
    "Definition": "fire; wild fires",
    "Radical": "火",
    "RadicalIndex": "86.14",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6326,
    "FrequencyRank": 5929
  },
  {
    "Character": "鹱",
    "Pinyin": "hù",
    "Definition": undefined,
    "Radical": "鳥",
    "RadicalIndex": "196.14",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8017,
    "FrequencyRank": 5869
  },
  {
    "Character": "藠",
    "Pinyin": "jiào",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6321,
    "FrequencyRank": 7919
  },
  {
    "Character": "瞽",
    "Pinyin": "gǔ",
    "Definition": "blind; blind musician; stupid",
    "Radical": "目",
    "RadicalIndex": "109.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6314,
    "FrequencyRank": 5909
  },
  {
    "Character": "鼬",
    "Pinyin": "yòu",
    "Definition": "weasel, mustela itatis",
    "Radical": "鼠",
    "RadicalIndex": "208.5",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6350,
    "FrequencyRank": 5410
  },
  {
    "Character": "藟",
    "Pinyin": "lěi",
    "Definition": "vine, creeper; to wind",
    "Radical": "艸",
    "RadicalIndex": "140.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7988,
    "FrequencyRank": undefined
  },
  {
    "Character": "藜",
    "Pinyin": "lí",
    "Definition": "Chenopodium album, pigweed",
    "Radical": "艸",
    "RadicalIndex": "140.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6320,
    "FrequencyRank": 4556
  },
  {
    "Character": "鳐",
    "Pinyin": "yáo",
    "Definition": "the nautilus; the ray",
    "Radical": "魚",
    "RadicalIndex": "195.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6355,
    "FrequencyRank": 6344
  },
  {
    "Character": "馥",
    "Pinyin": "fù",
    "Definition": "fragrance, scent, aroma",
    "Radical": "香",
    "RadicalIndex": "186.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6347,
    "FrequencyRank": 4664
  },
  {
    "Character": "癖",
    "Pinyin": "pǐ",
    "Definition": "craving, weakness for; indigestion",
    "Radical": "疒",
    "RadicalIndex": "104.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6359,
    "FrequencyRank": 3669
  },
  {
    "Character": "鳍",
    "Pinyin": "qí",
    "Definition": "fin",
    "Radical": "魚",
    "RadicalIndex": "195.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 3446,
    "FrequencyRank": 2801
  },
  {
    "Character": "蹩",
    "Pinyin": "bié",
    "Definition": "to limp",
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6361,
    "FrequencyRank": 4308
  },
  {
    "Character": "蟠",
    "Pinyin": "pán",
    "Definition": "coil; coiling, curling; occupy",
    "Radical": "虫",
    "RadicalIndex": "142.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6337,
    "FrequencyRank": 4274
  },
  {
    "Character": "鞫",
    "Pinyin": "jū",
    "Definition": "interrogate, question",
    "Radical": "革",
    "RadicalIndex": "177.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6317,
    "FrequencyRank": 5409
  },
  {
    "Character": "鞳",
    "Pinyin": "tà",
    "Definition": undefined,
    "Radical": "革",
    "RadicalIndex": "177.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7985,
    "FrequencyRank": undefined
  },
  {
    "Character": "臑",
    "Pinyin": "nào",
    "Definition": "soft",
    "Radical": "肉",
    "RadicalIndex": "130.14",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8013,
    "FrequencyRank": 7348
  },
  {
    "Character": "鬈",
    "Pinyin": "quán",
    "Definition": "fine growth of hair curly hair",
    "Radical": "髟",
    "RadicalIndex": "190.8",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6312,
    "FrequencyRank": 5094
  },
  {
    "Character": "鳏",
    "Pinyin": "guān",
    "Definition": "huge fish; widower; bachelor",
    "Radical": "魚",
    "RadicalIndex": "195.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6354,
    "FrequencyRank": 5382
  },
  {
    "Character": "䎖",
    "Pinyin": "zēng",
    "Definition": "soaring up in the sky",
    "Radical": "羽",
    "RadicalIndex": "124.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8024,
    "FrequencyRank": undefined
  },
  {
    "Character": "瀔",
    "Pinyin": "gǔ",
    "Definition": "river name in Henan province",
    "Radical": "水",
    "RadicalIndex": "85.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8025,
    "FrequencyRank": undefined
  },
  {
    "Character": "嚣",
    "Pinyin": "xiāo",
    "Definition": "be noisy; treat with contempt",
    "Radical": "口",
    "RadicalIndex": "30.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 3443,
    "FrequencyRank": 2982
  },
  {
    "Character": "蟪",
    "Pinyin": "huì",
    "Definition": "a kind of cicada",
    "Radical": "虫",
    "RadicalIndex": "142.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6336,
    "FrequencyRank": 4911
  },
  {
    "Character": "镮",
    "Pinyin": "huán",
    "Definition": "metal ring; measure of currency",
    "Radical": "金",
    "RadicalIndex": "167.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8003,
    "FrequencyRank": 7462
  },
  {
    "Character": "𦒍",
    "Pinyin": "tóng",
    "Definition": undefined,
    "Radical": "羽",
    "RadicalIndex": "124.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8020,
    "FrequencyRank": undefined
  },
  {
    "Character": "髅",
    "Pinyin": "lóu",
    "Definition": "skull; skeleton",
    "Radical": "骨",
    "RadicalIndex": "188.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6342,
    "FrequencyRank": 3761
  },
  {
    "Character": "鏊",
    "Pinyin": "ào",
    "Definition": "a flat, iron cooking-plate for cakes",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6310,
    "FrequencyRank": 5309
  },
  {
    "Character": "藕",
    "Pinyin": "ǒu",
    "Definition": "lotus root",
    "Radical": "艸",
    "RadicalIndex": "140.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 3437,
    "FrequencyRank": 3920
  },
  {
    "Character": "鎏",
    "Pinyin": "liú",
    "Definition": "pure gold",
    "Radical": "金",
    "RadicalIndex": "167.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6362,
    "FrequencyRank": 5172
  },
  {
    "Character": "糨",
    "Pinyin": "jiàng",
    "Definition": "starch; paste. to starch",
    "Radical": "米",
    "RadicalIndex": "119.11",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6360,
    "FrequencyRank": 6314
  },
  {
    "Character": "蹚",
    "Pinyin": "tāng",
    "Definition": "tread through mud and water",
    "Radical": "足",
    "RadicalIndex": "157.11",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6333,
    "FrequencyRank": 5535
  },
  {
    "Character": "鹭",
    "Pinyin": "lù",
    "Definition": "heron, egret; Ardea species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6334,
    "FrequencyRank": 4451
  },
  {
    "Character": "黠",
    "Pinyin": "xiá",
    "Definition": "sly, cunning, shrewd; artful",
    "Radical": "黑",
    "RadicalIndex": "203.6",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6340,
    "FrequencyRank": 4160
  },
  {
    "Character": "瀍",
    "Pinyin": "chán",
    "Definition": "river in Henan province",
    "Radical": "水",
    "RadicalIndex": "85.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8026,
    "FrequencyRank": undefined
  },
  {
    "Character": "黡",
    "Pinyin": "yǎn",
    "Definition": "mole, scar, blemish",
    "Radical": "黑",
    "RadicalIndex": "203.6",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7993,
    "FrequencyRank": 8439
  },
  {
    "Character": "鞨",
    "Pinyin": "hé",
    "Definition": "tribe",
    "Radical": "革",
    "RadicalIndex": "177.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6316,
    "FrequencyRank": 7444
  },
  {
    "Character": "蟛",
    "Pinyin": "péng",
    "Definition": "a land-crab",
    "Radical": "虫",
    "RadicalIndex": "142.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6335,
    "FrequencyRank": 5270
  },
  {
    "Character": "簠",
    "Pinyin": "fǔ",
    "Definition": "an ancient vessel used for holding boiled grain",
    "Radical": "竹",
    "RadicalIndex": "118.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8007,
    "FrequencyRank": 9082
  },
  {
    "Character": "礌",
    "Pinyin": "léi",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7995,
    "FrequencyRank": 6502
  },
  {
    "Character": "鬶",
    "Pinyin": "guī",
    "Definition": undefined,
    "Radical": "鬲",
    "RadicalIndex": "193.8",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7983,
    "FrequencyRank": 8827
  },
  {
    "Character": "鹯",
    "Pinyin": "zhān",
    "Definition": "hawk; Butastur indicus",
    "Radical": "鳥",
    "RadicalIndex": "196.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8018,
    "FrequencyRank": 7585
  },
  {
    "Character": "鹮",
    "Pinyin": "huán",
    "Definition": "spoonbill; ibis; family Threskiornidae",
    "Radical": "鳥",
    "RadicalIndex": "196.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6339,
    "FrequencyRank": 5991
  },
  {
    "Character": "簝",
    "Pinyin": "liáo",
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8008,
    "FrequencyRank": undefined
  },
  {
    "Character": "镬",
    "Pinyin": "huò",
    "Definition": "cauldron, large iron pot",
    "Radical": "金",
    "RadicalIndex": "167.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6344,
    "FrequencyRank": 6061
  },
  {
    "Character": "檫",
    "Pinyin": "chá",
    "Definition": "sassafras tzumu",
    "Radical": "木",
    "RadicalIndex": "75.14",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7992,
    "FrequencyRank": 6428
  },
  {
    "Character": "癗",
    "Pinyin": "lěi",
    "Definition": undefined,
    "Radical": "疒",
    "RadicalIndex": "104.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8019,
    "FrequencyRank": undefined
  },
  {
    "Character": "礓",
    "Pinyin": "jiāng",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6325,
    "FrequencyRank": 6981
  },
  {
    "Character": "躇",
    "Pinyin": "chú",
    "Definition": "hesitate, falter, be undecided",
    "Radical": "足",
    "RadicalIndex": "157.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6332,
    "FrequencyRank": 3322
  },
  {
    "Character": "鞯",
    "Pinyin": "jiān",
    "Definition": "saddle blanket",
    "Radical": "革",
    "RadicalIndex": "177.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6315,
    "FrequencyRank": 4514
  },
  {
    "Character": "餮",
    "Pinyin": "tiè",
    "Definition": "a legendary animal; a greedy person",
    "Radical": "食",
    "RadicalIndex": "184.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6327,
    "FrequencyRank": 5263
  },
  {
    "Character": "翷",
    "Pinyin": "lín",
    "Definition": undefined,
    "Radical": "羽",
    "RadicalIndex": "124.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8022,
    "FrequencyRank": undefined
  },
  {
    "Character": "癔",
    "Pinyin": "yì",
    "Definition": "hysterical",
    "Radical": "疒",
    "RadicalIndex": "104.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6357,
    "FrequencyRank": 5942
  },
  {
    "Character": "鳑",
    "Pinyin": "páng",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8015,
    "FrequencyRank": 6602
  },
  {
    "Character": "鳒",
    "Pinyin": "jiān",
    "Definition": "big-mouthed flounder",
    "Radical": "魚",
    "RadicalIndex": "195.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8016,
    "FrequencyRank": 8077
  },
  {
    "Character": "髂",
    "Pinyin": "qià",
    "Definition": "the pelvis",
    "Radical": "骨",
    "RadicalIndex": "188.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6343,
    "FrequencyRank": 5297
  },
  {
    "Character": "髃",
    "Pinyin": "yú",
    "Definition": "shoulder bone",
    "Radical": "骨",
    "RadicalIndex": "188.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8002,
    "FrequencyRank": undefined
  },
  {
    "Character": "酂",
    "Pinyin": "cuó",
    "Definition": "collect; small administration",
    "Radical": "邑",
    "RadicalIndex": "163.16",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8005,
    "FrequencyRank": 7571
  },
  {
    "Character": "蹜",
    "Pinyin": "sù",
    "Definition": "walk carefully",
    "Radical": "足",
    "RadicalIndex": "157.11",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7998,
    "FrequencyRank": undefined
  },
  {
    "Character": "藨",
    "Pinyin": "biāo",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7990,
    "FrequencyRank": 9507
  },
  {
    "Character": "藩",
    "Pinyin": "fān",
    "Definition": "fence; boundary; outlying border",
    "Radical": "艸",
    "RadicalIndex": "140.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6322,
    "FrequencyRank": 2622
  },
  {
    "Character": "镱",
    "Pinyin": "yì",
    "Definition": "ytterbium",
    "Radical": "金",
    "RadicalIndex": "167.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8004,
    "FrequencyRank": 7166
  },
  {
    "Character": "癜",
    "Pinyin": "diàn",
    "Definition": "erythema",
    "Radical": "疒",
    "RadicalIndex": "104.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6358,
    "FrequencyRank": 4411
  },
  {
    "Character": "鞣",
    "Pinyin": "róu",
    "Definition": "tan, soften",
    "Radical": "革",
    "RadicalIndex": "177.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6319,
    "FrequencyRank": 5085
  },
  {
    "Character": "簟",
    "Pinyin": "diàn",
    "Definition": "bamboo mat",
    "Radical": "竹",
    "RadicalIndex": "118.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6348,
    "FrequencyRank": 5253
  },
  {
    "Character": "曛",
    "Pinyin": "xūn",
    "Definition": "twilight; sunset",
    "Radical": "日",
    "RadicalIndex": "72.14",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6329,
    "FrequencyRank": 5834
  },
  {
    "Character": "蟮",
    "Pinyin": "shàn",
    "Definition": "type of earthworm",
    "Radical": "虫",
    "RadicalIndex": "142.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6338,
    "FrequencyRank": 4280
  },
  {
    "Character": "艟",
    "Pinyin": "chōng",
    "Definition": "ancient warship",
    "Radical": "舟",
    "RadicalIndex": "137.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6352,
    "FrequencyRank": 6100
  },
  {
    "Character": "蹙",
    "Pinyin": "cù",
    "Definition": "urgent, suddenly; grieve, lament",
    "Radical": "足",
    "RadicalIndex": "157.11",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6324,
    "FrequencyRank": 3977
  },
  {
    "Character": "璧",
    "Pinyin": "bì",
    "Definition": "piece of jade with hole in it",
    "Radical": "玉",
    "RadicalIndex": "96.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 3450,
    "FrequencyRank": 3589
  },
  {
    "Character": "鼩",
    "Pinyin": "qú",
    "Definition": undefined,
    "Radical": "鼠",
    "RadicalIndex": "208.5",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8011,
    "FrequencyRank": 6596
  },
  {
    "Character": "礞",
    "Pinyin": "méng",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.14",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7994,
    "FrequencyRank": 7088
  },
  {
    "Character": "冁",
    "Pinyin": "chǎn",
    "Definition": "smile",
    "Radical": "八",
    "RadicalIndex": "12.16",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8023,
    "FrequencyRank": 7762
  },
  {
    "Character": "簪",
    "Pinyin": "zān",
    "Definition": "hairpin, clasp; wear in hair",
    "Radical": "竹",
    "RadicalIndex": "118.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6349,
    "FrequencyRank": 4297
  },
  {
    "Character": "彝",
    "Pinyin": "yí",
    "Definition": "Yi (nationality); tripod, wine vessel; rule",
    "Radical": "彐",
    "RadicalIndex": "58.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6364,
    "FrequencyRank": 3554
  },
  {
    "Character": "懵",
    "Pinyin": "měng",
    "Definition": "stupid, ignorant, dull",
    "Radical": "心",
    "RadicalIndex": "61.16",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6363,
    "FrequencyRank": 3823
  },
  {
    "Character": "𥖨",
    "Pinyin": "zào",
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7996,
    "FrequencyRank": undefined
  },
  {
    "Character": "镭",
    "Pinyin": "léi",
    "Definition": "radium",
    "Radical": "金",
    "RadicalIndex": "167.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6345,
    "FrequencyRank": 4217
  },
  {
    "Character": "釐",
    "Pinyin": "lí",
    "Definition": "manage, control; 1/1000 of a foot",
    "Radical": "里",
    "RadicalIndex": "166.11",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7982,
    "FrequencyRank": 6670
  },
  {
    "Character": "瞿",
    "Pinyin": "qú",
    "Definition": "surname",
    "Radical": "目",
    "RadicalIndex": "109.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6328,
    "FrequencyRank": 3305
  },
  {
    "Character": "蹢",
    "Pinyin": "dí",
    "Definition": "hoof; a falter; to hesitate",
    "Radical": "足",
    "RadicalIndex": "157.11",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7997,
    "FrequencyRank": undefined
  },
  {
    "Character": "簰",
    "Pinyin": "pái",
    "Definition": "bamboo raft",
    "Radical": "竹",
    "RadicalIndex": "118.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8009,
    "FrequencyRank": 7592
  },
  {
    "Character": "鹲",
    "Pinyin": "méng",
    "Definition": undefined,
    "Radical": "鳥",
    "RadicalIndex": "196.14",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7991,
    "FrequencyRank": undefined
  },
  {
    "Character": "鳌",
    "Pinyin": "áo",
    "Definition": "huge sea turtle",
    "Radical": "魚",
    "RadicalIndex": "195.1",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6311,
    "FrequencyRank": 3450
  },
  {
    "Character": "瓀",
    "Pinyin": "ruǎn",
    "Definition": "gem",
    "Radical": "玉",
    "RadicalIndex": "96.14",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7981,
    "FrequencyRank": undefined
  },
  {
    "Character": "鼫",
    "Pinyin": "shí",
    "Definition": "marmot; squirrel",
    "Radical": "鼠",
    "RadicalIndex": "208.5",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8010,
    "FrequencyRank": 8747
  },
  {
    "Character": "嚚",
    "Pinyin": "yín",
    "Definition": "argumentative, talkative",
    "Radical": "口",
    "RadicalIndex": "30.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8001,
    "FrequencyRank": undefined
  },
  {
    "Character": "㘎",
    "Pinyin": "hǎn",
    "Definition": "(simplified form) the roars of a tiger",
    "Radical": "口",
    "RadicalIndex": "30.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7936,
    "FrequencyRank": undefined
  },
  {
    "Character": "爇",
    "Pinyin": "ruò",
    "Definition": "burn",
    "Radical": "火",
    "RadicalIndex": "86.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7984,
    "FrequencyRank": undefined
  },
  {
    "Character": "曜",
    "Pinyin": "yào",
    "Definition": "glorious, as sun; daylight, sunlight; one of the seven planets of pre-modern astronomy (the sun, the moon, Mercury, Venus, Mars, Jupiter, and Saturn)",
    "Radical": "日",
    "RadicalIndex": "72.14",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6331,
    "FrequencyRank": 4180
  },
  {
    "Character": "襜",
    "Pinyin": "chān",
    "Definition": "flutter; shaking or vibrating",
    "Radical": "衣",
    "RadicalIndex": "145.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8028,
    "FrequencyRank": undefined
  },
  {
    "Character": "襟",
    "Pinyin": "jīn",
    "Definition": "lapel, collar",
    "Radical": "衣",
    "RadicalIndex": "145.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 3449,
    "FrequencyRank": 2843
  },
  {
    "Character": "邋",
    "Pinyin": "lā",
    "Definition": "rags",
    "Radical": "辵",
    "RadicalIndex": "162.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6365,
    "FrequencyRank": 4901
  },
  {
    "Character": "黟",
    "Pinyin": "yī",
    "Definition": "black and shining; ebony",
    "Radical": "黑",
    "RadicalIndex": "203.6",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6341,
    "FrequencyRank": 6765
  },
  {
    "Character": "癞",
    "Pinyin": "lài",
    "Definition": "leprosy, scabies, mange; shoddy",
    "Radical": "疒",
    "RadicalIndex": "104.13",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6356,
    "FrequencyRank": 4225
  },
  {
    "Character": "旞",
    "Pinyin": "suì",
    "Definition": undefined,
    "Radical": "方",
    "RadicalIndex": "70.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8021,
    "FrequencyRank": undefined
  },
  {
    "Character": "蟫",
    "Pinyin": "yín",
    "Definition": "silverfish",
    "Radical": "虫",
    "RadicalIndex": "142.12",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 7999,
    "FrequencyRank": 6949
  },
  {
    "Character": "瀌",
    "Pinyin": "biāo",
    "Definition": "plenty",
    "Radical": "水",
    "RadicalIndex": "85.15",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 8027,
    "FrequencyRank": undefined
  },
  {
    "Character": "鞧",
    "Pinyin": "qiū",
    "Definition": "a leather strap",
    "Radical": "革",
    "RadicalIndex": "177.9",
    "StrokeCount": 18,
    "HSK": undefined,
    "GeneralStandard": 6318,
    "FrequencyRank": 8248
  },
  {
    "Character": "蟹",
    "Pinyin": "xiè",
    "Definition": "crab, brachyura",
    "Radical": "虫",
    "RadicalIndex": "142.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 3464,
    "FrequencyRank": 3049
  },
  {
    "Character": "鳚",
    "Pinyin": "wèi",
    "Definition": "general term of a kind of fish, a snake-like fish",
    "Radical": "魚",
    "RadicalIndex": "195.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8044,
    "FrequencyRank": 5789
  },
  {
    "Character": "瀛",
    "Pinyin": "yíng",
    "Definition": "sea, ocean",
    "Radical": "水",
    "RadicalIndex": "85.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6414,
    "FrequencyRank": 3890
  },
  {
    "Character": "鞴",
    "Pinyin": "bèi",
    "Definition": "saddle up horse; drive horse",
    "Radical": "革",
    "RadicalIndex": "177.1",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6370,
    "FrequencyRank": 5415
  },
  {
    "Character": "鏖",
    "Pinyin": "áo",
    "Definition": "to fight to the end, engage in a fierce battle",
    "Radical": "金",
    "RadicalIndex": "167.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6409,
    "FrequencyRank": 4857
  },
  {
    "Character": "麒",
    "Pinyin": "qí",
    "Definition": "legendary auspicious animal",
    "Radical": "鹿",
    "RadicalIndex": "198.8",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6408,
    "FrequencyRank": 4703
  },
  {
    "Character": "鳘",
    "Pinyin": "mǐn",
    "Definition": "codfish",
    "Radical": "魚",
    "RadicalIndex": "195.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8038,
    "FrequencyRank": 7187
  },
  {
    "Character": "缵",
    "Pinyin": "zuǎn",
    "Definition": "continue, carry on, succeed",
    "Radical": "糸",
    "RadicalIndex": "120.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6419,
    "FrequencyRank": 6432
  },
  {
    "Character": "儴",
    "Pinyin": "ráng",
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.17",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8040,
    "FrequencyRank": undefined
  },
  {
    "Character": "嚭",
    "Pinyin": "pǐ",
    "Definition": "mound, lump; stealthily",
    "Radical": "口",
    "RadicalIndex": "30.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8031,
    "FrequencyRank": undefined
  },
  {
    "Character": "黼",
    "Pinyin": "fǔ",
    "Definition": "embroidered official or sacrificial robe",
    "Radical": "黹",
    "RadicalIndex": "204.7",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6381,
    "FrequencyRank": 6029
  },
  {
    "Character": "谶",
    "Pinyin": "chèn",
    "Definition": "prophecy, hint, omen",
    "Radical": "言",
    "RadicalIndex": "149.17",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6416,
    "FrequencyRank": 4762
  },
  {
    "Character": "魑",
    "Pinyin": "chī",
    "Definition": "a mountain demon resembling a tiger",
    "Radical": "鬼",
    "RadicalIndex": "194.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6401,
    "FrequencyRank": 4792
  },
  {
    "Character": "鳛",
    "Pinyin": "xí",
    "Definition": "the weather or dojo loach, Misgurnus anguillicaudatus",
    "Radical": "魚",
    "RadicalIndex": "195.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8045,
    "FrequencyRank": 8204
  },
  {
    "Character": "翾",
    "Pinyin": "xuān",
    "Definition": "fly; to flit",
    "Radical": "羽",
    "RadicalIndex": "124.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8037,
    "FrequencyRank": undefined
  },
  {
    "Character": "嚯",
    "Pinyin": "huò",
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6382,
    "FrequencyRank": 5852
  },
  {
    "Character": "霭",
    "Pinyin": "ǎi",
    "Definition": "cloudy sky, haze; calm, peaceful",
    "Radical": "雨",
    "RadicalIndex": "173.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6379,
    "FrequencyRank": 4316
  },
  {
    "Character": "癣",
    "Pinyin": "xuǎn",
    "Definition": "ringworms",
    "Radical": "疒",
    "RadicalIndex": "104.14",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 3467,
    "FrequencyRank": 4067
  },
  {
    "Character": "蠓",
    "Pinyin": "měng",
    "Definition": "midges; sandflies",
    "Radical": "虫",
    "RadicalIndex": "142.14",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6391,
    "FrequencyRank": 5165
  },
  {
    "Character": "鳖",
    "Pinyin": "biē",
    "Definition": "turtle",
    "Radical": "魚",
    "RadicalIndex": "195.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 3470,
    "FrequencyRank": 3960
  },
  {
    "Character": "𩾌",
    "Pinyin": "kāng",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8043,
    "FrequencyRank": undefined
  },
  {
    "Character": "骥",
    "Pinyin": "jì",
    "Definition": "thoroughbred horse; refined and",
    "Radical": "馬",
    "RadicalIndex": "187.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6418,
    "FrequencyRank": 3835
  },
  {
    "Character": "鳕",
    "Pinyin": "xuě",
    "Definition": "codfish",
    "Radical": "魚",
    "RadicalIndex": "195.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6405,
    "FrequencyRank": 5114
  },
  {
    "Character": "齁",
    "Pinyin": "hōu",
    "Definition": "snore loudly; very, extremely",
    "Radical": "鼻",
    "RadicalIndex": "209.5",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6400,
    "FrequencyRank": 7153
  },
  {
    "Character": "蹰",
    "Pinyin": "chú",
    "Definition": "hesitate, waver, falter",
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6383,
    "FrequencyRank": 5763
  },
  {
    "Character": "酃",
    "Pinyin": "líng",
    "Definition": "the spirit of a being, which acts upon others spirit; divine; efficacious",
    "Radical": "邑",
    "RadicalIndex": "163.17",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6377,
    "FrequencyRank": 5878
  },
  {
    "Character": "蘅",
    "Pinyin": "héng",
    "Definition": "a fragrant plant the root is medicinal",
    "Radical": "艸",
    "RadicalIndex": "140.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6373,
    "FrequencyRank": 5654
  },
  {
    "Character": "髋",
    "Pinyin": "kuān",
    "Definition": "hipbone; hip",
    "Radical": "骨",
    "RadicalIndex": "188.1",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6395,
    "FrequencyRank": 5086
  },
  {
    "Character": "蹾",
    "Pinyin": "dūn",
    "Definition": "to squat; to crouch",
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6388,
    "FrequencyRank": 7550
  },
  {
    "Character": "蹯",
    "Pinyin": "fán",
    "Definition": undefined,
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8035,
    "FrequencyRank": 6717
  },
  {
    "Character": "髌",
    "Pinyin": "bìn",
    "Definition": "kneecap",
    "Radical": "骨",
    "RadicalIndex": "188.1",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6396,
    "FrequencyRank": 5696
  },
  {
    "Character": "籁",
    "Pinyin": "lài",
    "Definition": "bamboo flute; pipe; various sound",
    "Radical": "竹",
    "RadicalIndex": "118.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6399,
    "FrequencyRank": 4150
  },
  {
    "Character": "藿",
    "Pinyin": "huò",
    "Definition": "lophanthus rugosus, betony",
    "Radical": "艸",
    "RadicalIndex": "140.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6371,
    "FrequencyRank": 5174
  },
  {
    "Character": "镲",
    "Pinyin": "chǎ",
    "Definition": "cymbals",
    "Radical": "金",
    "RadicalIndex": "167.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6397,
    "FrequencyRank": 7557
  },
  {
    "Character": "嬿",
    "Pinyin": "yàn",
    "Definition": "lovely",
    "Radical": "女",
    "RadicalIndex": "38.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8050,
    "FrequencyRank": undefined
  },
  {
    "Character": "蹴",
    "Pinyin": "cù",
    "Definition": "kick; tread on; leap; solemn",
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6387,
    "FrequencyRank": 4948
  },
  {
    "Character": "簿",
    "Pinyin": "bù",
    "Definition": "register, account book, notebook",
    "Radical": "竹",
    "RadicalIndex": "118.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 3463,
    "FrequencyRank": 2848
  },
  {
    "Character": "蹿",
    "Pinyin": "cuān",
    "Definition": "leap; to jump; to spurt out",
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6389,
    "FrequencyRank": 3784
  },
  {
    "Character": "醭",
    "Pinyin": "bú",
    "Definition": "molds on liquids; scum",
    "Radical": "酉",
    "RadicalIndex": "164.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8034,
    "FrequencyRank": 5989
  },
  {
    "Character": "蹽",
    "Pinyin": "liāo",
    "Definition": undefined,
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6385,
    "FrequencyRank": 8297
  },
  {
    "Character": "羹",
    "Pinyin": "gēng",
    "Definition": "soup, broth",
    "Radical": "羊",
    "RadicalIndex": "123.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 3469,
    "FrequencyRank": 3907
  },
  {
    "Character": "麖",
    "Pinyin": "jīng",
    "Definition": undefined,
    "Radical": "鹿",
    "RadicalIndex": "198.8",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8047,
    "FrequencyRank": 8005
  },
  {
    "Character": "蟾",
    "Pinyin": "chán",
    "Definition": "toad",
    "Radical": "虫",
    "RadicalIndex": "142.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6392,
    "FrequencyRank": 3934
  },
  {
    "Character": "鬷",
    "Pinyin": "zōng",
    "Definition": "pot",
    "Radical": "鬲",
    "RadicalIndex": "193.9",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8033,
    "FrequencyRank": undefined
  },
  {
    "Character": "醯",
    "Pinyin": "xī",
    "Definition": "vinegar; pickle; acid",
    "Radical": "酉",
    "RadicalIndex": "164.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6376,
    "FrequencyRank": 4445
  },
  {
    "Character": "襞",
    "Pinyin": "bì",
    "Definition": "fold, pleat, crease",
    "Radical": "衣",
    "RadicalIndex": "145.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6417,
    "FrequencyRank": 6470
  },
  {
    "Character": "鳙",
    "Pinyin": "yōng",
    "Definition": "bighead",
    "Radical": "魚",
    "RadicalIndex": "195.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6407,
    "FrequencyRank": 5892
  },
  {
    "Character": "孽",
    "Pinyin": "niè",
    "Definition": "evil; son of concubine; ghost",
    "Radical": "子",
    "RadicalIndex": "39.17",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 3452,
    "FrequencyRank": 2942
  },
  {
    "Character": "鳓",
    "Pinyin": "lè",
    "Definition": "Chinese herring; shad",
    "Radical": "魚",
    "RadicalIndex": "195.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6403,
    "FrequencyRank": 7046
  },
  {
    "Character": "䴖",
    "Pinyin": "jīng",
    "Definition": "a kind of water bird",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 7465,
    "FrequencyRank": 8771
  },
  {
    "Character": "襦",
    "Pinyin": "rú",
    "Definition": "short coat, jacket; fine silk fab",
    "Radical": "衣",
    "RadicalIndex": "145.14",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6415,
    "FrequencyRank": 6714
  },
  {
    "Character": "㸆",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.15",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6411,
    "FrequencyRank": undefined
  },
  {
    "Character": "鳔",
    "Pinyin": "biào",
    "Definition": "swimming bladder of fish",
    "Radical": "魚",
    "RadicalIndex": "195.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6404,
    "FrequencyRank": 5765
  },
  {
    "Character": "籀",
    "Pinyin": "zhòu",
    "Definition": "recite, read; style of calligraphy",
    "Radical": "竹",
    "RadicalIndex": "118.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6398,
    "FrequencyRank": 6220
  },
  {
    "Character": "靡",
    "Pinyin": "mí",
    "Definition": "divide, disperse, scatter",
    "Radical": "非",
    "RadicalIndex": "175.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 3466,
    "FrequencyRank": 3074
  },
  {
    "Character": "彟",
    "Pinyin": "yuē",
    "Definition": undefined,
    "Radical": "彐",
    "RadicalIndex": "58.23",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8049,
    "FrequencyRank": undefined
  },
  {
    "Character": "醮",
    "Pinyin": "jiào",
    "Definition": "religious service; Daoist or Buddhist ceremony",
    "Radical": "酉",
    "RadicalIndex": "164.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6375,
    "FrequencyRank": 4056
  },
  {
    "Character": "蠖",
    "Pinyin": "huò",
    "Definition": "inch-worm; looper caterpiller",
    "Radical": "虫",
    "RadicalIndex": "142.14",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6390,
    "FrequencyRank": 5010
  },
  {
    "Character": "鳗",
    "Pinyin": "mán",
    "Definition": "eel",
    "Radical": "魚",
    "RadicalIndex": "195.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6406,
    "FrequencyRank": 4152
  },
  {
    "Character": "蠋",
    "Pinyin": "zhú",
    "Definition": "caterpillar",
    "Radical": "虫",
    "RadicalIndex": "142.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8036,
    "FrequencyRank": 6934
  },
  {
    "Character": "麑",
    "Pinyin": "ní",
    "Definition": "fawn, young deer",
    "Radical": "鹿",
    "RadicalIndex": "198.8",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8046,
    "FrequencyRank": 7192
  },
  {
    "Character": "鼗",
    "Pinyin": "táo",
    "Definition": "small revolving drum with knobs",
    "Radical": "鼓",
    "RadicalIndex": "207.6",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8041,
    "FrequencyRank": 5729
  },
  {
    "Character": "攉",
    "Pinyin": "huō",
    "Definition": "to beckon; to urge",
    "Radical": "手",
    "RadicalIndex": "64.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6367,
    "FrequencyRank": 6726
  },
  {
    "Character": "霪",
    "Pinyin": "yín",
    "Definition": "long and heavy rain",
    "Radical": "雨",
    "RadicalIndex": "173.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6378,
    "FrequencyRank": 6731
  },
  {
    "Character": "䗴",
    "Pinyin": "tíng",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8000,
    "FrequencyRank": undefined
  },
  {
    "Character": "㰀",
    "Pinyin": "lí",
    "Definition": "a kind of tree",
    "Radical": "木",
    "RadicalIndex": "75.15",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8032,
    "FrequencyRank": undefined
  },
  {
    "Character": "儳",
    "Pinyin": "chán",
    "Definition": "obstinate; stupid; uneven; mix",
    "Radical": "人",
    "RadicalIndex": "9.17",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8039,
    "FrequencyRank": undefined
  },
  {
    "Character": "蠃",
    "Pinyin": "luǒ",
    "Definition": "the solitary wasp",
    "Radical": "虫",
    "RadicalIndex": "142.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 8048,
    "FrequencyRank": 6866
  },
  {
    "Character": "鬏",
    "Pinyin": "jiū",
    "Definition": "a coiffure on top of the head",
    "Radical": "髟",
    "RadicalIndex": "190.9",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6366,
    "FrequencyRank": 7322
  },
  {
    "Character": "瀚",
    "Pinyin": "hàn",
    "Definition": "vast, wide, extensive",
    "Radical": "水",
    "RadicalIndex": "85.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6412,
    "FrequencyRank": 3666
  },
  {
    "Character": "艨",
    "Pinyin": "méng",
    "Definition": "long and narrow war-boat",
    "Radical": "舟",
    "RadicalIndex": "137.14",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6402,
    "FrequencyRank": 6252
  },
  {
    "Character": "瀣",
    "Pinyin": "xiè",
    "Definition": "sea mist; vapor",
    "Radical": "水",
    "RadicalIndex": "85.16",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6413,
    "FrequencyRank": 5960
  },
  {
    "Character": "羸",
    "Pinyin": "léi",
    "Definition": "weak, lean, emaciated, exhausted",
    "Radical": "羊",
    "RadicalIndex": "123.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6410,
    "FrequencyRank": 5419
  },
  {
    "Character": "蠊",
    "Pinyin": "lián",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.13",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6393,
    "FrequencyRank": 5352
  },
  {
    "Character": "蹶",
    "Pinyin": "jué",
    "Definition": "stumble, fall down; trample",
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6384,
    "FrequencyRank": 4420
  },
  {
    "Character": "霨",
    "Pinyin": "wèi",
    "Definition": "clouding",
    "Radical": "雨",
    "RadicalIndex": "173.11",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6380,
    "FrequencyRank": 6736
  },
  {
    "Character": "黢",
    "Pinyin": "qū",
    "Definition": "black",
    "Radical": "黑",
    "RadicalIndex": "203.7",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6394,
    "FrequencyRank": 5569
  },
  {
    "Character": "蘧",
    "Pinyin": "qú",
    "Definition": "a plant which resembles wheat but has no edible grain",
    "Radical": "艸",
    "RadicalIndex": "140.17",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6372,
    "FrequencyRank": 6175
  },
  {
    "Character": "鞲",
    "Pinyin": "gōu",
    "Definition": "leather arm guard",
    "Radical": "革",
    "RadicalIndex": "177.1",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6369,
    "FrequencyRank": 6049
  },
  {
    "Character": "蹼",
    "Pinyin": "pǔ",
    "Definition": "webbed feet of waterfowl",
    "Radical": "足",
    "RadicalIndex": "157.12",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6386,
    "FrequencyRank": 5104
  },
  {
    "Character": "麓",
    "Pinyin": "lù",
    "Definition": "foot of hill; foothill",
    "Radical": "鹿",
    "RadicalIndex": "198.8",
    "StrokeCount": 19,
    "HSK": undefined,
    "GeneralStandard": 6374,
    "FrequencyRank": 3583
  },
  {
    "Character": "瀱",
    "Pinyin": "jì",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8066,
    "FrequencyRank": undefined
  },
  {
    "Character": "巇",
    "Pinyin": "xī",
    "Definition": "a crack; hazardous",
    "Radical": "山",
    "RadicalIndex": "46.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8058,
    "FrequencyRank": undefined
  },
  {
    "Character": "髎",
    "Pinyin": "liáo",
    "Definition": "hip bone",
    "Radical": "骨",
    "RadicalIndex": "188.11",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8060,
    "FrequencyRank": undefined
  },
  {
    "Character": "黧",
    "Pinyin": "lí",
    "Definition": "a dark, sallow colour",
    "Radical": "黑",
    "RadicalIndex": "203.8",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6437,
    "FrequencyRank": 5871
  },
  {
    "Character": "䶮",
    "Pinyin": "yǎn",
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6814,
    "FrequencyRank": 8753
  },
  {
    "Character": "糯",
    "Pinyin": "nuò",
    "Definition": "glutinous rice; glutinous, sticky",
    "Radical": "米",
    "RadicalIndex": "119.14",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 3485,
    "FrequencyRank": 4208
  },
  {
    "Character": "鳞",
    "Pinyin": "lín",
    "Definition": "fish scales",
    "Radical": "魚",
    "RadicalIndex": "195.12",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 3483,
    "FrequencyRank": 2763
  },
  {
    "Character": "瓒",
    "Pinyin": "zàn",
    "Definition": "ceremonial libation cup",
    "Radical": "玉",
    "RadicalIndex": "96.16",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6420,
    "FrequencyRank": 4011
  },
  {
    "Character": "巉",
    "Pinyin": "chán",
    "Definition": "steep, rugged, jagged, precipitous",
    "Radical": "山",
    "RadicalIndex": "46.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6431,
    "FrequencyRank": undefined
  },
  {
    "Character": "蠕",
    "Pinyin": "rú",
    "Definition": "eumenes polifomis, kind of wasp",
    "Radical": "虫",
    "RadicalIndex": "142.14",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 3478,
    "FrequencyRank": 3512
  },
  {
    "Character": "蘩",
    "Pinyin": "fán",
    "Definition": "Artemisia stellariana",
    "Radical": "艸",
    "RadicalIndex": "140.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6422,
    "FrequencyRank": 5813
  },
  {
    "Character": "鳟",
    "Pinyin": "zūn",
    "Definition": "barbel",
    "Radical": "魚",
    "RadicalIndex": "195.12",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6444,
    "FrequencyRank": 5271
  },
  {
    "Character": "䦃",
    "Pinyin": "zhuō",
    "Definition": "(simplified form) to dig with a hoe, a big hoe",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6111,
    "FrequencyRank": 9711
  },
  {
    "Character": "矍",
    "Pinyin": "jué",
    "Definition": "look about in fright or alarm",
    "Radical": "目",
    "RadicalIndex": "109.15",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6427,
    "FrequencyRank": 5129
  },
  {
    "Character": "黥",
    "Pinyin": "qíng",
    "Definition": "to tattoo; to brand the face of criminals",
    "Radical": "黑",
    "RadicalIndex": "203.8",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6433,
    "FrequencyRank": 6278
  },
  {
    "Character": "𨟠",
    "Pinyin": "quān",
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.18",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8057,
    "FrequencyRank": undefined
  },
  {
    "Character": "䲠",
    "Pinyin": "chūn",
    "Definition": "(simplified form 鰆) a kind of fish; long and flat; silver-gray with dark color dots and cross-grained strips",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 7960,
    "FrequencyRank": 9713
  },
  {
    "Character": "䴗",
    "Pinyin": "jú",
    "Definition": "a shrike",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 7668,
    "FrequencyRank": 7526
  },
  {
    "Character": "骧",
    "Pinyin": "xiāng",
    "Definition": "gallop about with head uplifted",
    "Radical": "馬",
    "RadicalIndex": "187.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6447,
    "FrequencyRank": 4475
  },
  {
    "Character": "襫",
    "Pinyin": "shì",
    "Definition": "raincoat",
    "Radical": "衣",
    "RadicalIndex": "145.15",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8070,
    "FrequencyRank": 8477
  },
  {
    "Character": "醴",
    "Pinyin": "lǐ",
    "Definition": "sweet wine; sweet spring",
    "Radical": "酉",
    "RadicalIndex": "164.13",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6424,
    "FrequencyRank": 4877
  },
  {
    "Character": "骦",
    "Pinyin": "shuāng",
    "Definition": "horse",
    "Radical": "馬",
    "RadicalIndex": "187.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8072,
    "FrequencyRank": undefined
  },
  {
    "Character": "颥",
    "Pinyin": "rú",
    "Definition": "the temporal bone",
    "Radical": "頁",
    "RadicalIndex": "181.14",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8055,
    "FrequencyRank": 7185
  },
  {
    "Character": "鬓",
    "Pinyin": "bìn",
    "Definition": "hair on temples",
    "Radical": "髟",
    "RadicalIndex": "190.1",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 3473,
    "FrequencyRank": 3749
  },
  {
    "Character": "臜",
    "Pinyin": "zā",
    "Definition": "dirty; filthy",
    "Radical": "肉",
    "RadicalIndex": "130.16",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6441,
    "FrequencyRank": 7079
  },
  {
    "Character": "瀼",
    "Pinyin": "ráng",
    "Definition": "river in Henan province; flowing",
    "Radical": "水",
    "RadicalIndex": "85.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8068,
    "FrequencyRank": undefined
  },
  {
    "Character": "獾",
    "Pinyin": "huān",
    "Definition": "the badger",
    "Radical": "犬",
    "RadicalIndex": "94.18",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6445,
    "FrequencyRank": 4312
  },
  {
    "Character": "醵",
    "Pinyin": "jù",
    "Definition": "contribute for drinks; pool money",
    "Radical": "酉",
    "RadicalIndex": "164.13",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8054,
    "FrequencyRank": 5750
  },
  {
    "Character": "孀",
    "Pinyin": "shuāng",
    "Definition": "widow",
    "Radical": "女",
    "RadicalIndex": "38.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6446,
    "FrequencyRank": 4368
  },
  {
    "Character": "犨",
    "Pinyin": "chōu",
    "Definition": "panting of cow; grunting of ox",
    "Radical": "牛",
    "RadicalIndex": "93.16",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8061,
    "FrequencyRank": undefined
  },
  {
    "Character": "鳜",
    "Pinyin": "guì",
    "Definition": "mandarin fish",
    "Radical": "魚",
    "RadicalIndex": "195.12",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6442,
    "FrequencyRank": 6451
  },
  {
    "Character": "蘖",
    "Pinyin": "niè",
    "Definition": "stump, sprout",
    "Radical": "艸",
    "RadicalIndex": "140.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6423,
    "FrequencyRank": 5666
  },
  {
    "Character": "蘘",
    "Pinyin": "ráng",
    "Definition": "a kind of wild ginger",
    "Radical": "艸",
    "RadicalIndex": "140.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8052,
    "FrequencyRank": 8825
  },
  {
    "Character": "𨭉",
    "Pinyin": "bān",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.12",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8063,
    "FrequencyRank": undefined
  },
  {
    "Character": "黪",
    "Pinyin": "cǎn",
    "Definition": "grey black",
    "Radical": "黑",
    "RadicalIndex": "203.8",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6434,
    "FrequencyRank": 7323
  },
  {
    "Character": "甗",
    "Pinyin": "yǎn",
    "Definition": "earthenware vessel in two parts",
    "Radical": "瓦",
    "RadicalIndex": "98.16",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8056,
    "FrequencyRank": undefined
  },
  {
    "Character": "酆",
    "Pinyin": "fēng",
    "Definition": "name of Zhou period state",
    "Radical": "邑",
    "RadicalIndex": "163.18",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6426,
    "FrequencyRank": 4964
  },
  {
    "Character": "鼍",
    "Pinyin": "tuó",
    "Definition": "large reptile, water lizard",
    "Radical": "黽",
    "RadicalIndex": "205.12",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6430,
    "FrequencyRank": 5562
  },
  {
    "Character": "欂",
    "Pinyin": "bó",
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8053,
    "FrequencyRank": undefined
  },
  {
    "Character": "镴",
    "Pinyin": "là",
    "Definition": "solder; tin",
    "Radical": "金",
    "RadicalIndex": "167.15",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6436,
    "FrequencyRank": 7148
  },
  {
    "Character": "鬒",
    "Pinyin": "zhěn",
    "Definition": "black, glossy hair",
    "Radical": "髟",
    "RadicalIndex": "190.1",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8051,
    "FrequencyRank": undefined
  },
  {
    "Character": "孅",
    "Pinyin": "qiān",
    "Definition": "slender, thin; sharp-pointed",
    "Radical": "女",
    "RadicalIndex": "38.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8071,
    "FrequencyRank": undefined
  },
  {
    "Character": "巍",
    "Pinyin": "wēi",
    "Definition": "high, lofty, majestic, eminent",
    "Radical": "山",
    "RadicalIndex": "46.18",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 3481,
    "FrequencyRank": 3201
  },
  {
    "Character": "镳",
    "Pinyin": "biāo",
    "Definition": "bit, bridle; ride",
    "Radical": "金",
    "RadicalIndex": "167.15",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6435,
    "FrequencyRank": 4558
  },
  {
    "Character": "霰",
    "Pinyin": "sǎn",
    "Definition": "hail, sleet",
    "Radical": "雨",
    "RadicalIndex": "173.12",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6425,
    "FrequencyRank": 4723
  },
  {
    "Character": "鳝",
    "Pinyin": "shàn",
    "Definition": "eel",
    "Radical": "魚",
    "RadicalIndex": "195.12",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6443,
    "FrequencyRank": 4598
  },
  {
    "Character": "瀵",
    "Pinyin": "fèn",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8069,
    "FrequencyRank": undefined
  },
  {
    "Character": "黩",
    "Pinyin": "dú",
    "Definition": "to dishonor, defile, corrupt; soiled",
    "Radical": "黑",
    "RadicalIndex": "203.8",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6432,
    "FrequencyRank": 5166
  },
  {
    "Character": "㸌",
    "Pinyin": "huò",
    "Definition": "to twinkle; bright light in glimpses; flashing",
    "Radical": "火",
    "RadicalIndex": "86.16",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8064,
    "FrequencyRank": undefined
  },
  {
    "Character": "纂",
    "Pinyin": "zuǎn",
    "Definition": "edit, compile; topknot, chignon",
    "Radical": "糸",
    "RadicalIndex": "120.14",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6438,
    "FrequencyRank": 3536
  },
  {
    "Character": "璺",
    "Pinyin": "wèn",
    "Definition": "a crack, as in porcelain",
    "Radical": "玉",
    "RadicalIndex": "96.14",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6439,
    "FrequencyRank": 6356
  },
  {
    "Character": "爔",
    "Pinyin": "xī",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.16",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8065,
    "FrequencyRank": undefined
  },
  {
    "Character": "躅",
    "Pinyin": "zhú",
    "Definition": "walk carefully; hesitate, falter",
    "Radical": "足",
    "RadicalIndex": "157.13",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6429,
    "FrequencyRank": 5611
  },
  {
    "Character": "曦",
    "Pinyin": "xī",
    "Definition": "sunlight, sunshine, early dawn",
    "Radical": "日",
    "RadicalIndex": "72.16",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6428,
    "FrequencyRank": 3744
  },
  {
    "Character": "酅",
    "Pinyin": "xī",
    "Definition": "raise feet; town in Shandong province",
    "Radical": "邑",
    "RadicalIndex": "163.18",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8059,
    "FrequencyRank": undefined
  },
  {
    "Character": "鼯",
    "Pinyin": "wú",
    "Definition": "flying squirrel",
    "Radical": "鼠",
    "RadicalIndex": "208.7",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 6440,
    "FrequencyRank": 6807
  },
  {
    "Character": "瀹",
    "Pinyin": "yuè",
    "Definition": "to boil; to wash, to cleanse, to soak",
    "Radical": "水",
    "RadicalIndex": "85.17",
    "StrokeCount": 20,
    "HSK": undefined,
    "GeneralStandard": 8067,
    "FrequencyRank": 7238
  },
  {
    "Character": "罍",
    "Pinyin": "léi",
    "Definition": "large earthenware wine jar",
    "Radical": "缶",
    "RadicalIndex": "121.15",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8080,
    "FrequencyRank": 8457
  },
  {
    "Character": "灈",
    "Pinyin": "qú",
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.18",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8087,
    "FrequencyRank": undefined
  },
  {
    "Character": "醺",
    "Pinyin": "xūn",
    "Definition": "get drunk, be intoxicated",
    "Radical": "酉",
    "RadicalIndex": "164.14",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6450,
    "FrequencyRank": 4131
  },
  {
    "Character": "曩",
    "Pinyin": "nǎng",
    "Definition": "in ancient times, of old, former",
    "Radical": "日",
    "RadicalIndex": "72.17",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6453,
    "FrequencyRank": 5888
  },
  {
    "Character": "爝",
    "Pinyin": "jué",
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.18",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6458,
    "FrequencyRank": 6942
  },
  {
    "Character": "䲢",
    "Pinyin": "téng",
    "Definition": "(simplified form of 鰧) a kind of fish; stout; bluish gray color; big flat head; big mouth and small eyes; usually stay in the bottom of the shallow sea; half bured in the muddy sand",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8014,
    "FrequencyRank": undefined
  },
  {
    "Character": "鳠",
    "Pinyin": "hù",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.13",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8082,
    "FrequencyRank": 8435
  },
  {
    "Character": "鼱",
    "Pinyin": "jīng",
    "Definition": undefined,
    "Radical": "鼠",
    "RadicalIndex": "208.8",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8081,
    "FrequencyRank": 7413
  },
  {
    "Character": "爟",
    "Pinyin": "guàn",
    "Definition": "fire",
    "Radical": "火",
    "RadicalIndex": "86.18",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8085,
    "FrequencyRank": undefined
  },
  {
    "Character": "麝",
    "Pinyin": "shè",
    "Definition": "musk deer",
    "Radical": "鹿",
    "RadicalIndex": "198.1",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6456,
    "FrequencyRank": 4053
  },
  {
    "Character": "蠡",
    "Pinyin": "lí",
    "Definition": "wood-boring insect; bore into wood",
    "Radical": "虫",
    "RadicalIndex": "142.15",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6463,
    "FrequencyRank": 4245
  },
  {
    "Character": "𤫉",
    "Pinyin": "xiè",
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.17",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8075,
    "FrequencyRank": undefined
  },
  {
    "Character": "鳡",
    "Pinyin": "gǎn",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.13",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8083,
    "FrequencyRank": 6233
  },
  {
    "Character": "羼",
    "Pinyin": "chàn",
    "Definition": "confuse, mix, interpolate",
    "Radical": "羊",
    "RadicalIndex": "123.15",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6462,
    "FrequencyRank": 6074
  },
  {
    "Character": "趯",
    "Pinyin": "tì",
    "Definition": "jump",
    "Radical": "走",
    "RadicalIndex": "156.14",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8078,
    "FrequencyRank": 8671
  },
  {
    "Character": "爚",
    "Pinyin": "yuè",
    "Definition": "bright; fiery",
    "Radical": "火",
    "RadicalIndex": "86.17",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8086,
    "FrequencyRank": undefined
  },
  {
    "Character": "鳣",
    "Pinyin": "zhān",
    "Definition": "sturgeon",
    "Radical": "魚",
    "RadicalIndex": "195.13",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8084,
    "FrequencyRank": 7781
  },
  {
    "Character": "鳢",
    "Pinyin": "lǐ",
    "Definition": "snakehead",
    "Radical": "魚",
    "RadicalIndex": "195.13",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6454,
    "FrequencyRank": 5737
  },
  {
    "Character": "禳",
    "Pinyin": "ráng",
    "Definition": "pray or sacrifice, exorcise",
    "Radical": "示",
    "RadicalIndex": "113.17",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6460,
    "FrequencyRank": 5310
  },
  {
    "Character": "颦",
    "Pinyin": "pín",
    "Definition": "frown, knit brows; with knitted",
    "Radical": "十",
    "RadicalIndex": "24.19",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6452,
    "FrequencyRank": 4851
  },
  {
    "Character": "黯",
    "Pinyin": "àn",
    "Definition": "dark, black; sullen, dreary",
    "Radical": "音",
    "RadicalIndex": "180.12",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 3493,
    "FrequencyRank": 2881
  },
  {
    "Character": "鬘",
    "Pinyin": "mán",
    "Definition": "beautiful hair",
    "Radical": "髟",
    "RadicalIndex": "190.11",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8077,
    "FrequencyRank": 6525
  },
  {
    "Character": "赣",
    "Pinyin": "gàn",
    "Definition": "Jiangxi province; places therein",
    "Radical": "貝",
    "RadicalIndex": "154.17",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 3495,
    "FrequencyRank": 3301
  },
  {
    "Character": "䴘",
    "Pinyin": "tī",
    "Definition": "a kind of bird",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 7795,
    "FrequencyRank": 6933
  },
  {
    "Character": "瓘",
    "Pinyin": "guàn",
    "Definition": "jade",
    "Radical": "玉",
    "RadicalIndex": "96.18",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6448,
    "FrequencyRank": undefined
  },
  {
    "Character": "鐾",
    "Pinyin": "bèi",
    "Definition": undefined,
    "Radical": "金",
    "RadicalIndex": "167.13",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6461,
    "FrequencyRank": 5752
  },
  {
    "Character": "夔",
    "Pinyin": "kuí",
    "Definition": "one-legged monster; walrus",
    "Radical": "夊",
    "RadicalIndex": "35.19",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6457,
    "FrequencyRank": 4330
  },
  {
    "Character": "霹",
    "Pinyin": "pī",
    "Definition": "thunder, crashing thunder",
    "Radical": "雨",
    "RadicalIndex": "173.13",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 3491,
    "FrequencyRank": 3438
  },
  {
    "Character": "躏",
    "Pinyin": "lìn",
    "Definition": "trample down, oppress, overrun",
    "Radical": "足",
    "RadicalIndex": "157.14",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 3492,
    "FrequencyRank": 3821
  },
  {
    "Character": "鼙",
    "Pinyin": "pí",
    "Definition": "drum carried on horseback",
    "Radical": "鼓",
    "RadicalIndex": "207.8",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6449,
    "FrequencyRank": 5677
  },
  {
    "Character": "癫",
    "Pinyin": "diān",
    "Definition": "crazy, mad; madness, mania, insan",
    "Radical": "疒",
    "RadicalIndex": "104.16",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6455,
    "FrequencyRank": 2887
  },
  {
    "Character": "瓖",
    "Pinyin": "xiāng",
    "Definition": "inlay",
    "Radical": "玉",
    "RadicalIndex": "96.17",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8076,
    "FrequencyRank": undefined
  },
  {
    "Character": "耰",
    "Pinyin": "yōu",
    "Definition": "toothless rake for drawing earth over newly sown grain",
    "Radical": "耒",
    "RadicalIndex": "127.15",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 8074,
    "FrequencyRank": 8409
  },
  {
    "Character": "灏",
    "Pinyin": "hào",
    "Definition": "vast, large, grand, expansive",
    "Radical": "水",
    "RadicalIndex": "85.18",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6459,
    "FrequencyRank": 5030
  },
  {
    "Character": "礴",
    "Pinyin": "bó",
    "Definition": "fill, extend",
    "Radical": "石",
    "RadicalIndex": "112.16",
    "StrokeCount": 21,
    "HSK": undefined,
    "GeneralStandard": 6451,
    "FrequencyRank": 4856
  },
  {
    "Character": "霾",
    "Pinyin": "mái",
    "Definition": "misty, foggy; dust storm",
    "Radical": "雨",
    "RadicalIndex": "173.14",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6468,
    "FrequencyRank": 4319
  },
  {
    "Character": "礵",
    "Pinyin": "shuāng",
    "Definition": "arsenic",
    "Radical": "石",
    "RadicalIndex": "112.17",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 8091,
    "FrequencyRank": undefined
  },
  {
    "Character": "耱",
    "Pinyin": "mò",
    "Definition": "a kind of farm tool",
    "Radical": "耒",
    "RadicalIndex": "127.16",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6464,
    "FrequencyRank": 7116
  },
  {
    "Character": "躔",
    "Pinyin": "chán",
    "Definition": "follow in, imitate; rut, path",
    "Radical": "足",
    "RadicalIndex": "157.15",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 8093,
    "FrequencyRank": 7321
  },
  {
    "Character": "韂",
    "Pinyin": "chàn",
    "Definition": "a saddle-flap. trappings",
    "Radical": "革",
    "RadicalIndex": "177.13",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 8088,
    "FrequencyRank": 8306
  },
  {
    "Character": "龢",
    "Pinyin": "hé",
    "Definition": "in harmony; calm, peaceful",
    "Radical": "龠",
    "RadicalIndex": "214.5",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 8095,
    "FrequencyRank": 4539
  },
  {
    "Character": "皭",
    "Pinyin": "jiào",
    "Definition": "white; bright; clear; clean",
    "Radical": "白",
    "RadicalIndex": "106.18",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 8094,
    "FrequencyRank": undefined
  },
  {
    "Character": "镵",
    "Pinyin": "chán",
    "Definition": "spade, trowel",
    "Radical": "金",
    "RadicalIndex": "167.17",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6473,
    "FrequencyRank": 8066
  },
  {
    "Character": "穰",
    "Pinyin": "ráng",
    "Definition": "stalks of grain; lush, abundant",
    "Radical": "禾",
    "RadicalIndex": "115.17",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6474,
    "FrequencyRank": 5152
  },
  {
    "Character": "饔",
    "Pinyin": "yōng",
    "Definition": "breakfast; eat prepared food",
    "Radical": "食",
    "RadicalIndex": "184.13",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6475,
    "FrequencyRank": 5019
  },
  {
    "Character": "亹",
    "Pinyin": "mén",
    "Definition": "progress; busy, untiring; exert",
    "Radical": "亠",
    "RadicalIndex": "8.19",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 8097,
    "FrequencyRank": undefined
  },
  {
    "Character": "瓤",
    "Pinyin": "ráng",
    "Definition": "flesh, core, pulp, pith",
    "Radical": "瓜",
    "RadicalIndex": "97.17",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 3498,
    "FrequencyRank": 5046
  },
  {
    "Character": "鹴",
    "Pinyin": "shuāng",
    "Definition": "eagle",
    "Radical": "鳥",
    "RadicalIndex": "196.17",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 8092,
    "FrequencyRank": undefined
  },
  {
    "Character": "饕",
    "Pinyin": "tāo",
    "Definition": "gluttonous, greedy, covetous",
    "Radical": "食",
    "RadicalIndex": "184.13",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6470,
    "FrequencyRank": 4324
  },
  {
    "Character": "氍",
    "Pinyin": "qú",
    "Definition": "fine woollen cloth; a mat used by the emperor in worshipping god",
    "Radical": "毛",
    "RadicalIndex": "82.18",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6469,
    "FrequencyRank": 7576
  },
  {
    "Character": "蘼",
    "Pinyin": "mí",
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.19",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 8090,
    "FrequencyRank": 5832
  },
  {
    "Character": "懿",
    "Pinyin": "yì",
    "Definition": "virtuous, admirable, esteemed",
    "Radical": "心",
    "RadicalIndex": "61.18",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6465,
    "FrequencyRank": 2932
  },
  {
    "Character": "鳤",
    "Pinyin": "guǎn",
    "Definition": undefined,
    "Radical": "魚",
    "RadicalIndex": "195.14",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 8096,
    "FrequencyRank": 7298
  },
  {
    "Character": "糵",
    "Pinyin": "niè",
    "Definition": "fermenting grain; grain which has sprouted; yeast",
    "Radical": "米",
    "RadicalIndex": "119.17",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 8089,
    "FrequencyRank": 7846
  },
  {
    "Character": "鹳",
    "Pinyin": "guàn",
    "Definition": "crane, grus japonensis; stork; Ciconia species (various)",
    "Radical": "鳥",
    "RadicalIndex": "196.17",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6467,
    "FrequencyRank": 3957
  },
  {
    "Character": "髑",
    "Pinyin": "dú",
    "Definition": "skull",
    "Radical": "骨",
    "RadicalIndex": "188.13",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6472,
    "FrequencyRank": 5727
  },
  {
    "Character": "鬻",
    "Pinyin": "yù",
    "Definition": "sell; child, childish; nourish",
    "Radical": "鬲",
    "RadicalIndex": "193.12",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6476,
    "FrequencyRank": 5314
  },
  {
    "Character": "躐",
    "Pinyin": "liè",
    "Definition": "to stride over; to step across",
    "Radical": "足",
    "RadicalIndex": "157.15",
    "StrokeCount": 22,
    "HSK": undefined,
    "GeneralStandard": 6471,
    "FrequencyRank": 6764
  },
  {
    "Character": "趱",
    "Pinyin": "zǎn",
    "Definition": "go in hurry, hasten; urge",
    "Radical": "走",
    "RadicalIndex": "156.16",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 6478,
    "FrequencyRank": 6578
  },
  {
    "Character": "鼷",
    "Pinyin": "xī",
    "Definition": "a mouse",
    "Radical": "鼠",
    "RadicalIndex": "208.1",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 8099,
    "FrequencyRank": 6865
  },
  {
    "Character": "麟",
    "Pinyin": "lín",
    "Definition": "female of Chinese unicorn",
    "Radical": "鹿",
    "RadicalIndex": "198.12",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 6485,
    "FrequencyRank": 2752
  },
  {
    "Character": "颧",
    "Pinyin": "quán",
    "Definition": "cheek bones",
    "Radical": "頁",
    "RadicalIndex": "181.17",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 6481,
    "FrequencyRank": 4593
  },
  {
    "Character": "蠲",
    "Pinyin": "juān",
    "Definition": "the millipede sometimes confounded with the grow-worm; bright, clear",
    "Radical": "虫",
    "RadicalIndex": "142.17",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 6486,
    "FrequencyRank": 5339
  },
  {
    "Character": "𫚭",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 8100,
    "FrequencyRank": undefined
  },
  {
    "Character": "玃",
    "Pinyin": "jué",
    "Definition": "a large ape found in W. China",
    "Radical": "犬",
    "RadicalIndex": "94.2",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 8101,
    "FrequencyRank": undefined
  },
  {
    "Character": "躜",
    "Pinyin": "zuān",
    "Definition": "to jump",
    "Radical": "足",
    "RadicalIndex": "157.16",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 6482,
    "FrequencyRank": 6930
  },
  {
    "Character": "籥",
    "Pinyin": "yuè",
    "Definition": "key; woodwind instrument",
    "Radical": "竹",
    "RadicalIndex": "118.17",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 8098,
    "FrequencyRank": 7066
  },
  {
    "Character": "癯",
    "Pinyin": "qú",
    "Definition": "thin, emaciated; worn, tired",
    "Radical": "疒",
    "RadicalIndex": "104.18",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 6484,
    "FrequencyRank": 5499
  },
  {
    "Character": "攫",
    "Pinyin": "jué",
    "Definition": "snatch away, seize; catch with",
    "Radical": "手",
    "RadicalIndex": "64.2",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 6479,
    "FrequencyRank": 3692
  },
  {
    "Character": "鼹",
    "Pinyin": "yǎn",
    "Definition": "a kind of insectivorous rodent",
    "Radical": "鼠",
    "RadicalIndex": "208.1",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 6483,
    "FrequencyRank": 4932
  },
  {
    "Character": "鬟",
    "Pinyin": "huán",
    "Definition": "dress hair in coiled knot; maid",
    "Radical": "髟",
    "RadicalIndex": "190.13",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 6477,
    "FrequencyRank": 5902
  },
  {
    "Character": "攥",
    "Pinyin": "zuàn",
    "Definition": "(coll.) hold; grip; grasp",
    "Radical": "手",
    "RadicalIndex": "64.2",
    "StrokeCount": 23,
    "HSK": undefined,
    "GeneralStandard": 6480,
    "FrequencyRank": 3827
  },
  {
    "Character": "矗",
    "Pinyin": "chù",
    "Definition": "straight, upright, erect, lofty",
    "Radical": "目",
    "RadicalIndex": "109.19",
    "StrokeCount": 24,
    "HSK": undefined,
    "GeneralStandard": 3500,
    "FrequencyRank": 3029
  },
  {
    "Character": "蠹",
    "Pinyin": "dù",
    "Definition": "moth; insects which eat into clot",
    "Radical": "虫",
    "RadicalIndex": "142.18",
    "StrokeCount": 24,
    "HSK": undefined,
    "GeneralStandard": 6487,
    "FrequencyRank": 4607
  },
  {
    "Character": "醾",
    "Pinyin": "mí",
    "Definition": undefined,
    "Radical": "酉",
    "RadicalIndex": "164.17",
    "StrokeCount": 24,
    "HSK": undefined,
    "GeneralStandard": 8102,
    "FrequencyRank": 7956
  },
  {
    "Character": "䴙",
    "Pinyin": "pì",
    "Definition": "a kind of bird resembling the duck; much smaller",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": 24,
    "HSK": undefined,
    "GeneralStandard": 8029,
    "FrequencyRank": 7074
  },
  {
    "Character": "鑫",
    "Pinyin": "xīn",
    "Definition": "used in names",
    "Radical": "金",
    "RadicalIndex": "167.16",
    "StrokeCount": 24,
    "HSK": undefined,
    "GeneralStandard": 6490,
    "FrequencyRank": 3843
  },
  {
    "Character": "襻",
    "Pinyin": "pàn",
    "Definition": "a loop; a belt or band",
    "Radical": "衣",
    "RadicalIndex": "145.19",
    "StrokeCount": 24,
    "HSK": undefined,
    "GeneralStandard": 6492,
    "FrequencyRank": 6420
  },
  {
    "Character": "躞",
    "Pinyin": "xiè",
    "Definition": "to walk",
    "Radical": "足",
    "RadicalIndex": "157.17",
    "StrokeCount": 24,
    "HSK": undefined,
    "GeneralStandard": 6488,
    "FrequencyRank": 6415
  },
  {
    "Character": "灞",
    "Pinyin": "bà",
    "Definition": "river in Shanxi province",
    "Radical": "水",
    "RadicalIndex": "85.21",
    "StrokeCount": 24,
    "HSK": undefined,
    "GeneralStandard": 6491,
    "FrequencyRank": 5624
  },
  {
    "Character": "衢",
    "Pinyin": "qú",
    "Definition": "highway; thoroughfare, intersection",
    "Radical": "行",
    "RadicalIndex": "144.18",
    "StrokeCount": 24,
    "HSK": undefined,
    "GeneralStandard": 6489,
    "FrequencyRank": 4387
  },
  {
    "Character": "馕",
    "Pinyin": "náng",
    "Definition": "bread (persian naan)",
    "Radical": "食",
    "RadicalIndex": "184.22",
    "StrokeCount": 25,
    "HSK": undefined,
    "GeneralStandard": 6497,
    "FrequencyRank": 4372
  },
  {
    "Character": "觿",
    "Pinyin": "xī",
    "Definition": "a bodkin made of ivory, horn",
    "Radical": "角",
    "RadicalIndex": "148.18",
    "StrokeCount": 25,
    "HSK": undefined,
    "GeneralStandard": 8104,
    "FrequencyRank": 7675
  },
  {
    "Character": "戆",
    "Pinyin": "gàng",
    "Definition": "stupid, simple, simple-minded",
    "Radical": "心",
    "RadicalIndex": "61.21",
    "StrokeCount": 25,
    "HSK": undefined,
    "GeneralStandard": 6498,
    "FrequencyRank": 5706
  },
  {
    "Character": "鬣",
    "Pinyin": "liè",
    "Definition": undefined,
    "Radical": "髟",
    "RadicalIndex": "190.15",
    "StrokeCount": 25,
    "HSK": undefined,
    "GeneralStandard": 6494,
    "FrequencyRank": 5372
  },
  {
    "Character": "纛",
    "Pinyin": "dào",
    "Definition": "a banner, a streamer",
    "Radical": "糸",
    "RadicalIndex": "120.19",
    "StrokeCount": 25,
    "HSK": undefined,
    "GeneralStandard": 6493,
    "FrequencyRank": 6329
  },
  {
    "Character": "攮",
    "Pinyin": "nǎng",
    "Definition": "to fend off; to stab",
    "Radical": "手",
    "RadicalIndex": "64.22",
    "StrokeCount": 25,
    "HSK": undefined,
    "GeneralStandard": 6495,
    "FrequencyRank": 6679
  },
  {
    "Character": "齇",
    "Pinyin": "zhā",
    "Definition": undefined,
    "Radical": "鼻",
    "RadicalIndex": "209.11",
    "StrokeCount": 25,
    "HSK": undefined,
    "GeneralStandard": 8103,
    "FrequencyRank": undefined
  },
  {
    "Character": "囔",
    "Pinyin": "nāng",
    "Definition": "muttering, indistinct speech",
    "Radical": "口",
    "RadicalIndex": "30.22",
    "StrokeCount": 25,
    "HSK": undefined,
    "GeneralStandard": 6496,
    "FrequencyRank": 4082
  },
  {
    "Character": "蠼",
    "Pinyin": "qú",
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.2",
    "StrokeCount": 26,
    "HSK": undefined,
    "GeneralStandard": 8105,
    "FrequencyRank": 4767
  },
  {
    "Character": "爨",
    "Pinyin": "cuàn",
    "Definition": "oven, cooking stove; cook",
    "Radical": "火",
    "RadicalIndex": "86.25",
    "StrokeCount": 30,
    "HSK": undefined,
    "GeneralStandard": 6499,
    "FrequencyRank": 6143
  },
  {
    "Character": "齉",
    "Pinyin": "nàng",
    "Definition": "stoppage of the nose to speak with a nasal twang",
    "Radical": "鼻",
    "RadicalIndex": "209.22",
    "StrokeCount": 36,
    "HSK": undefined,
    "GeneralStandard": 6500,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭼",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7945,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬱟",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7405,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬶠",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7963,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬶟",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7961,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬤊",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7254,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫭢",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6564,
    "FrequencyRank": undefined
  },
  {
    "Character": "鿍",
    "Pinyin": "gàng",
    "Definition": "hill; long and narrow highland; used in place names",
    "Radical": "土",
    "RadicalIndex": "32.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6774,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬶭",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 8042,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭶",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7939,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬺈",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7855,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭬",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7872,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬘫",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7097,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬨎",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7512,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬮱",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7421,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬣡",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6623,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬜬",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6791,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬍡",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7241,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬳵",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6747,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬤝",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7737,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬊈",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7053,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬸦",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7894,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫫇",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "口",
    "RadicalIndex": "30.1",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7519,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬬩",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6688,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬬭",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6848,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬘩",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6921,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭸",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7943,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭳",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7937,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬬿",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7008,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬂩",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6959,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬣳",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6731,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬬮",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6846,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬍛",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.5",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6761,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬒈",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.5",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7070,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭁",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7009,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭯",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7874,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭤",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7682,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫢸",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "人",
    "RadicalIndex": "9.8",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7019,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬬻",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7006,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬇹",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.8",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7234,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬌗",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "牙",
    "RadicalIndex": "92.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6979,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬟽",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "虫",
    "RadicalIndex": "142.5",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7166,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬯀",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "阜",
    "RadicalIndex": "170.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6739,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫶇",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.9",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7361,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬬱",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6847,
    "FrequencyRank": undefined
  },
  {
    "Character": "鿏",
    "Pinyin": "mài",
    "Definition": "meitnerium",
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7373,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬶮",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 8062,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬉼",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.4",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 4004,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬜯",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.7",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6951,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬳿",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7093,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬹼",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7347,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬸪",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7958,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭎",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7382,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬪩",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "酉",
    "RadicalIndex": "164.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7503,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬕂",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "竹",
    "RadicalIndex": "118.7",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7541,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬸚",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7712,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬘯",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7279,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬀪",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6672,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬨂",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6670,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬒔",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.8",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7506,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬶐",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7706,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫵷",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "山",
    "RadicalIndex": "46.4",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6594,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫰛",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "女",
    "RadicalIndex": "38.5",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6744,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬱖",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7161,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬣞",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6551,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬳽",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6920,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭩",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7789,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬘬",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7273,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬴃",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7456,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫸩",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "弓",
    "RadicalIndex": "57.4",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6630,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬙋",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 8073,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬯎",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "阜",
    "RadicalIndex": "170.9",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7260,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫭟",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.4",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6560,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫷷",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "广",
    "RadicalIndex": "53.9",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7414,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬙊",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 8030,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬞟",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.13",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7841,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬴊",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7831,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬙂",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7746,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬶨",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7974,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬍤",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "玉",
    "RadicalIndex": "96.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6937,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬴂",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7275,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬬸",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7004,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬒗",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "石",
    "RadicalIndex": "112.9",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7661,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬘡",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6919,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬃊",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "木",
    "RadicalIndex": "75.8",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7326,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬊤",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "火",
    "RadicalIndex": "86.8",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7423,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭚",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7534,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬩽",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "邑",
    "RadicalIndex": "163.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6732,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬸘",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7654,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬇕",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.3",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6547,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬘓",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6553,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬶋",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7561,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭛",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7537,
    "FrequencyRank": undefined
  },
  {
    "Character": "鿎",
    "Pinyin": "tǎ",
    "Definition": "(tǎ) place names in Guangdong and Zhejiang; (dá) cobblestone, water gate",
    "Radical": "石",
    "RadicalIndex": "112.7",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7146,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬤇",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7249,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬘘",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6642,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬮿",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "阜",
    "RadicalIndex": "170.6",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6737,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬇙",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "水",
    "RadicalIndex": "85.4",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6616,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬳶",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6749,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫮃",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7114,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬀩",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "日",
    "RadicalIndex": "72.4",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6671,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬭊",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7375,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬶍",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7562,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬶏",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7707,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬟁",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "艸",
    "RadicalIndex": "140.15",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7987,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬘭",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7274,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬣙",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6520,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬺓",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 8079,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬷕",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7334,
    "FrequencyRank": undefined
  },
  {
    "Character": "𫭼",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": "土",
    "RadicalIndex": "32.8",
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 6941,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬬹",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7007,
    "FrequencyRank": undefined
  },
  {
    "Character": "𬸣",
    "Pinyin": undefined,
    "Definition": undefined,
    "Radical": undefined,
    "RadicalIndex": undefined,
    "StrokeCount": undefined,
    "HSK": undefined,
    "GeneralStandard": 7824,
    "FrequencyRank": undefined
  }
]
