export interface KanaUnitItem {
  id : number,
  glyph: string,
  reading: string,
  type: "hiragana" | "katakana"
}

export const kana = [
  {
    "id": 1,
    "glyph": "あ",
    "reading": "a",
    "type": "hiragana"
  },
  {
    "id": 2,
    "glyph": "い",
    "reading": "i",
    "type": "hiragana"
  },
  {
    "id": 3,
    "glyph": "う",
    "reading": "u",
    "type": "hiragana"
  },
  {
    "id": 4,
    "glyph": "え",
    "reading": "e",
    "type": "hiragana"
  },
  {
    "id": 5,
    "glyph": "お",
    "reading": "o",
    "type": "hiragana"
  },
  {
    "id": 6,
    "glyph": "か",
    "reading": "ka",
    "type": "hiragana"
  },
  {
    "id": 7,
    "glyph": "き",
    "reading": "ki",
    "type": "hiragana"
  },
  {
    "id": 8,
    "glyph": "く",
    "reading": "ku",
    "type": "hiragana"
  },
  {
    "id": 9,
    "glyph": "け",
    "reading": "ke",
    "type": "hiragana"
  },
  {
    "id": 10,
    "glyph": "こ",
    "reading": "ko",
    "type": "hiragana"
  },
  {
    "id": 11,
    "glyph": "が",
    "reading": "ga",
    "type": "hiragana"
  },
  {
    "id": 12,
    "glyph": "ぎ",
    "reading": "gi",
    "type": "hiragana"
  },
  {
    "id": 13,
    "glyph": "ぐ",
    "reading": "gu",
    "type": "hiragana"
  },
  {
    "id": 14,
    "glyph": "げ",
    "reading": "ge",
    "type": "hiragana"
  },
  {
    "id": 15,
    "glyph": "ご",
    "reading": "go",
    "type": "hiragana"
  },
  {
    "id": 16,
    "glyph": "さ",
    "reading": "sa",
    "type": "hiragana"
  },
  {
    "id": 17,
    "glyph": "し",
    "reading": "shi",
    "type": "hiragana"
  },
  {
    "id": 18,
    "glyph": "す",
    "reading": "su",
    "type": "hiragana"
  },
  {
    "id": 19,
    "glyph": "せ",
    "reading": "se",
    "type": "hiragana"
  },
  {
    "id": 20,
    "glyph": "そ",
    "reading": "so",
    "type": "hiragana"
  },
  {
    "id": 21,
    "glyph": "ざ",
    "reading": "za",
    "type": "hiragana"
  },
  {
    "id": 22,
    "glyph": "じ",
    "reading": "ji (zi)",
    "type": "hiragana"
  },
  {
    "id": 23,
    "glyph": "ず",
    "reading": "zu",
    "type": "hiragana"
  },
  {
    "id": 24,
    "glyph": "ぜ",
    "reading": "ze",
    "type": "hiragana"
  },
  {
    "id": 25,
    "glyph": "ぞ",
    "reading": "zo",
    "type": "hiragana"
  },
  {
    "id": 26,
    "glyph": "た",
    "reading": "ta",
    "type": "hiragana"
  },
  {
    "id": 27,
    "glyph": "ち",
    "reading": "chi",
    "type": "hiragana"
  },
  {
    "id": 28,
    "glyph": "つ",
    "reading": "tsu",
    "type": "hiragana"
  },
  {
    "id": 29,
    "glyph": "て",
    "reading": "te",
    "type": "hiragana"
  },
  {
    "id": 30,
    "glyph": "と",
    "reading": "to",
    "type": "hiragana"
  },
  {
    "id": 31,
    "glyph": "だ",
    "reading": "da",
    "type": "hiragana"
  },
  {
    "id": 32,
    "glyph": "ぢ",
    "reading": "ji (di)",
    "type": "hiragana"
  },
  {
    "id": 33,
    "glyph": "づ",
    "reading": "zu (du)",
    "type": "hiragana"
  },
  {
    "id": 34,
    "glyph": "で",
    "reading": "de",
    "type": "hiragana"
  },
  {
    "id": 35,
    "glyph": "ど",
    "reading": "do",
    "type": "hiragana"
  },
  {
    "id": 36,
    "glyph": "な",
    "reading": "na",
    "type": "hiragana"
  },
  {
    "id": 37,
    "glyph": "に",
    "reading": "ni",
    "type": "hiragana"
  },
  {
    "id": 38,
    "glyph": "ぬ",
    "reading": "nu",
    "type": "hiragana"
  },
  {
    "id": 39,
    "glyph": "ね",
    "reading": "ne",
    "type": "hiragana"
  },
  {
    "id": 40,
    "glyph": "の",
    "reading": "no",
    "type": "hiragana"
  },
  {
    "id": 41,
    "glyph": "は",
    "reading": "ha",
    "type": "hiragana"
  },
  {
    "id": 42,
    "glyph": "ひ",
    "reading": "hi",
    "type": "hiragana"
  },
  {
    "id": 43,
    "glyph": "ふ",
    "reading": "fu",
    "type": "hiragana"
  },
  {
    "id": 44,
    "glyph": "へ",
    "reading": "he",
    "type": "hiragana"
  },
  {
    "id": 45,
    "glyph": "ほ",
    "reading": "ho",
    "type": "hiragana"
  },
  {
    "id": 46,
    "glyph": "ば",
    "reading": "ba",
    "type": "hiragana"
  },
  {
    "id": 47,
    "glyph": "び",
    "reading": "bi",
    "type": "hiragana"
  },
  {
    "id": 48,
    "glyph": "ぶ",
    "reading": "bu",
    "type": "hiragana"
  },
  {
    "id": 49,
    "glyph": "べ",
    "reading": "be",
    "type": "hiragana"
  },
  {
    "id": 50,
    "glyph": "ぼ",
    "reading": "bo",
    "type": "hiragana"
  },
  {
    "id": 51,
    "glyph": "ぱ",
    "reading": "pa",
    "type": "hiragana"
  },
  {
    "id": 52,
    "glyph": "ぴ",
    "reading": "pi",
    "type": "hiragana"
  },
  {
    "id": 53,
    "glyph": "ぷ",
    "reading": "pu",
    "type": "hiragana"
  },
  {
    "id": 54,
    "glyph": "ぺ",
    "reading": "pe",
    "type": "hiragana"
  },
  {
    "id": 55,
    "glyph": "ぽ",
    "reading": "po",
    "type": "hiragana"
  },
  {
    "id": 56,
    "glyph": "ま",
    "reading": "ma",
    "type": "hiragana"
  },
  {
    "id": 57,
    "glyph": "み",
    "reading": "mi",
    "type": "hiragana"
  },
  {
    "id": 58,
    "glyph": "む",
    "reading": "mu",
    "type": "hiragana"
  },
  {
    "id": 59,
    "glyph": "め",
    "reading": "me",
    "type": "hiragana"
  },
  {
    "id": 60,
    "glyph": "も",
    "reading": "mo",
    "type": "hiragana"
  },
  {
    "id": 61,
    "glyph": "や",
    "reading": "ya",
    "type": "hiragana"
  },
  {
    "id": 62,
    "glyph": "ゆ",
    "reading": "yu",
    "type": "hiragana"
  },
  {
    "id": 63,
    "glyph": "よ",
    "reading": "yo",
    "type": "hiragana"
  },
  {
    "id": 64,
    "glyph": "ら",
    "reading": "ra",
    "type": "hiragana"
  },
  {
    "id": 65,
    "glyph": "り",
    "reading": "ri",
    "type": "hiragana"
  },
  {
    "id": 66,
    "glyph": "る",
    "reading": "ru",
    "type": "hiragana"
  },
  {
    "id": 67,
    "glyph": "れ",
    "reading": "re",
    "type": "hiragana"
  },
  {
    "id": 68,
    "glyph": "ろ",
    "reading": "ro",
    "type": "hiragana"
  },
  {
    "id": 69,
    "glyph": "わ",
    "reading": "wa",
    "type": "hiragana"
  },
  {
    "id": 70,
    "glyph": "を",
    "reading": "o (wo)",
    "type": "hiragana"
  },
  {
    "id": 71,
    "glyph": "ん",
    "reading": "n",
    "type": "hiragana"
  },
  {
    "id": 72,
    "glyph": "きゃ",
    "reading": "kya",
    "type": "hiragana"
  },
  {
    "id": 73,
    "glyph": "きゅ",
    "reading": "kyu",
    "type": "hiragana"
  },
  {
    "id": 74,
    "glyph": "きょ",
    "reading": "kyo",
    "type": "hiragana"
  },
  {
    "id": 75,
    "glyph": "ぎゃ",
    "reading": "gya",
    "type": "hiragana"
  },
  {
    "id": 76,
    "glyph": "ぎゅ",
    "reading": "gyu",
    "type": "hiragana"
  },
  {
    "id": 77,
    "glyph": "ぎょ",
    "reading": "gyo",
    "type": "hiragana"
  },
  {
    "id": 78,
    "glyph": "しゃ",
    "reading": "sha",
    "type": "hiragana"
  },
  {
    "id": 79,
    "glyph": "しゅ",
    "reading": "shu",
    "type": "hiragana"
  },
  {
    "id": 80,
    "glyph": "しょ",
    "reading": "sho",
    "type": "hiragana"
  },
  {
    "id": 81,
    "glyph": "じゃ",
    "reading": "ja",
    "type": "hiragana"
  },
  {
    "id": 82,
    "glyph": "じゅ",
    "reading": "ju",
    "type": "hiragana"
  },
  {
    "id": 83,
    "glyph": "じょ",
    "reading": "jo",
    "type": "hiragana"
  },
  {
    "id": 84,
    "glyph": "ちゃ",
    "reading": "cha",
    "type": "hiragana"
  },
  {
    "id": 85,
    "glyph": "ちゅ",
    "reading": "chu",
    "type": "hiragana"
  },
  {
    "id": 86,
    "glyph": "ちょ",
    "reading": "cho",
    "type": "hiragana"
  },
  {
    "id": 87,
    "glyph": "にゃ",
    "reading": "nya",
    "type": "hiragana"
  },
  {
    "id": 88,
    "glyph": "にゅ",
    "reading": "nyu",
    "type": "hiragana"
  },
  {
    "id": 89,
    "glyph": "にょ",
    "reading": "nyo",
    "type": "hiragana"
  },
  {
    "id": 90,
    "glyph": "ひゃ",
    "reading": "hya",
    "type": "hiragana"
  },
  {
    "id": 91,
    "glyph": "ひゅ",
    "reading": "hyu",
    "type": "hiragana"
  },
  {
    "id": 92,
    "glyph": "ひょ",
    "reading": "hyo",
    "type": "hiragana"
  },
  {
    "id": 93,
    "glyph": "びゃ",
    "reading": "bya",
    "type": "hiragana"
  },
  {
    "id": 94,
    "glyph": "びゅ",
    "reading": "byu",
    "type": "hiragana"
  },
  {
    "id": 95,
    "glyph": "びょ",
    "reading": "byo",
    "type": "hiragana"
  },
  {
    "id": 96,
    "glyph": "ぴゃ",
    "reading": "pya",
    "type": "hiragana"
  },
  {
    "id": 97,
    "glyph": "ぴゅ",
    "reading": "pyu",
    "type": "hiragana"
  },
  {
    "id": 98,
    "glyph": "ぴょ",
    "reading": "pyo",
    "type": "hiragana"
  },
  {
    "id": 99,
    "glyph": "みゃ",
    "reading": "mya",
    "type": "hiragana"
  },
  {
    "id": 100,
    "glyph": "みゅ",
    "reading": "myu",
    "type": "hiragana"
  },
  {
    "id": 101,
    "glyph": "みょ",
    "reading": "myo",
    "type": "hiragana"
  },
  {
    "id": 102,
    "glyph": "りゃ",
    "reading": "rya",
    "type": "hiragana"
  },
  {
    "id": 103,
    "glyph": "りゅ",
    "reading": "ryu",
    "type": "hiragana"
  },
  {
    "id": 104,
    "glyph": "りょ",
    "reading": "ryo",
    "type": "hiragana"
  },
  {
    "id": 105,
    "glyph": "ア",
    "reading": "a",
    "type": "katakana"
  },
  {
    "id": 106,
    "glyph": "イ",
    "reading": "i",
    "type": "katakana"
  },
  {
    "id": 107,
    "glyph": "ウ",
    "reading": "u",
    "type": "katakana"
  },
  {
    "id": 108,
    "glyph": "え",
    "reading": "e",
    "type": "katakana"
  },
  {
    "id": 109,
    "glyph": "オ",
    "reading": "o",
    "type": "katakana"
  },
  {
    "id": 110,
    "glyph": "カ",
    "reading": "ka",
    "type": "katakana"
  },
  {
    "id": 111,
    "glyph": "キ",
    "reading": "ki",
    "type": "katakana"
  },
  {
    "id": 112,
    "glyph": "ク",
    "reading": "ku",
    "type": "katakana"
  },
  {
    "id": 113,
    "glyph": "ケ",
    "reading": "ke",
    "type": "katakana"
  },
  {
    "id": 114,
    "glyph": "コ",
    "reading": "ko",
    "type": "katakana"
  },
  {
    "id": 115,
    "glyph": "ガ",
    "reading": "ga",
    "type": "katakana"
  },
  {
    "id": 116,
    "glyph": "ギ",
    "reading": "gi",
    "type": "katakana"
  },
  {
    "id": 117,
    "glyph": "グ",
    "reading": "gu",
    "type": "katakana"
  },
  {
    "id": 118,
    "glyph": "ゲ",
    "reading": "ge",
    "type": "katakana"
  },
  {
    "id": 119,
    "glyph": "ゴ",
    "reading": "go",
    "type": "katakana"
  },
  {
    "id": 120,
    "glyph": "サ",
    "reading": "sa",
    "type": "katakana"
  },
  {
    "id": 121,
    "glyph": "シ",
    "reading": "shi",
    "type": "katakana"
  },
  {
    "id": 122,
    "glyph": "ス",
    "reading": "su",
    "type": "katakana"
  },
  {
    "id": 123,
    "glyph": "セ",
    "reading": "se",
    "type": "katakana"
  },
  {
    "id": 124,
    "glyph": "ソ",
    "reading": "so",
    "type": "katakana"
  },
  {
    "id": 125,
    "glyph": "ザ",
    "reading": "za",
    "type": "katakana"
  },
  {
    "id": 126,
    "glyph": "ジ",
    "reading": "ji (zi)",
    "type": "katakana"
  },
  {
    "id": 127,
    "glyph": "ズ",
    "reading": "zu",
    "type": "katakana"
  },
  {
    "id": 128,
    "glyph": "ゼ",
    "reading": "ze",
    "type": "katakana"
  },
  {
    "id": 129,
    "glyph": "ゾ",
    "reading": "zo",
    "type": "katakana"
  },
  {
    "id": 130,
    "glyph": "タ",
    "reading": "ta",
    "type": "katakana"
  },
  {
    "id": 131,
    "glyph": "チ",
    "reading": "chi",
    "type": "katakana"
  },
  {
    "id": 132,
    "glyph": "ツ",
    "reading": "tsu",
    "type": "katakana"
  },
  {
    "id": 133,
    "glyph": "テ",
    "reading": "te",
    "type": "katakana"
  },
  {
    "id": 134,
    "glyph": "ト",
    "reading": "to",
    "type": "katakana"
  },
  {
    "id": 135,
    "glyph": "ダ",
    "reading": "da",
    "type": "katakana"
  },
  {
    "id": 136,
    "glyph": "ジ",
    "reading": "ji (di)",
    "type": "katakana"
  },
  {
    "id": 137,
    "glyph": "ズ",
    "reading": "zu (du)",
    "type": "katakana"
  },
  {
    "id": 138,
    "glyph": "デ",
    "reading": "de",
    "type": "katakana"
  },
  {
    "id": 139,
    "glyph": "ド",
    "reading": "do",
    "type": "katakana"
  },
  {
    "id": 140,
    "glyph": "ナ",
    "reading": "na",
    "type": "katakana"
  },
  {
    "id": 141,
    "glyph": "ニ",
    "reading": "ni",
    "type": "katakana"
  },
  {
    "id": 142,
    "glyph": "ヌ",
    "reading": "nu",
    "type": "katakana"
  },
  {
    "id": 143,
    "glyph": "ネ",
    "reading": "ne",
    "type": "katakana"
  },
  {
    "id": 144,
    "glyph": "ノ",
    "reading": "no",
    "type": "katakana"
  },
  {
    "id": 145,
    "glyph": "ハ",
    "reading": "ha",
    "type": "katakana"
  },
  {
    "id": 146,
    "glyph": "ヒ",
    "reading": "hi",
    "type": "katakana"
  },
  {
    "id": 147,
    "glyph": "フ",
    "reading": "fu",
    "type": "katakana"
  },
  {
    "id": 148,
    "glyph": "ヘ",
    "reading": "he",
    "type": "katakana"
  },
  {
    "id": 149,
    "glyph": "ホ",
    "reading": "ho",
    "type": "katakana"
  },
  {
    "id": 150,
    "glyph": "バ",
    "reading": "ba",
    "type": "katakana"
  },
  {
    "id": 151,
    "glyph": "ビ",
    "reading": "bi",
    "type": "katakana"
  },
  {
    "id": 152,
    "glyph": "ブ",
    "reading": "bu",
    "type": "katakana"
  },
  {
    "id": 153,
    "glyph": "ベ",
    "reading": "be",
    "type": "katakana"
  },
  {
    "id": 154,
    "glyph": "ボ",
    "reading": "bo",
    "type": "katakana"
  },
  {
    "id": 155,
    "glyph": "パ",
    "reading": "pa",
    "type": "katakana"
  },
  {
    "id": 156,
    "glyph": "ピ",
    "reading": "pi",
    "type": "katakana"
  },
  {
    "id": 157,
    "glyph": "プ",
    "reading": "pu",
    "type": "katakana"
  },
  {
    "id": 158,
    "glyph": "ペ",
    "reading": "pe",
    "type": "katakana"
  },
  {
    "id": 159,
    "glyph": "ポ",
    "reading": "po",
    "type": "katakana"
  },
  {
    "id": 160,
    "glyph": "マ",
    "reading": "ma ",
    "type": "katakana"
  },
  {
    "id": 161,
    "glyph": "ミ",
    "reading": "mi ",
    "type": "katakana"
  },
  {
    "id": 162,
    "glyph": "ム",
    "reading": "mu ",
    "type": "katakana"
  },
  {
    "id": 163,
    "glyph": "メ",
    "reading": "me ",
    "type": "katakana"
  },
  {
    "id": 164,
    "glyph": "モ",
    "reading": "mo ",
    "type": "katakana"
  },
  {
    "id": 165,
    "glyph": "ヤ",
    "reading": "ya ",
    "type": "katakana"
  },
  {
    "id": 166,
    "glyph": "ユ",
    "reading": "yu ",
    "type": "katakana"
  },
  {
    "id": 167,
    "glyph": "ヨ",
    "reading": "yo ",
    "type": "katakana"
  },
  {
    "id": 168,
    "glyph": "ラ",
    "reading": "ra ",
    "type": "katakana"
  },
  {
    "id": 169,
    "glyph": "リ",
    "reading": "ri ",
    "type": "katakana"
  },
  {
    "id": 170,
    "glyph": "ル",
    "reading": "ru ",
    "type": "katakana"
  },
  {
    "id": 171,
    "glyph": "レ",
    "reading": "re ",
    "type": "katakana"
  },
  {
    "id": 172,
    "glyph": "ロ",
    "reading": "ro ",
    "type": "katakana"
  },
  {
    "id": 173,
    "glyph": "ワ",
    "reading": "wa ",
    "type": "katakana"
  },
  {
    "id": 174,
    "glyph": "ヲ",
    "reading": "o (wo) ",
    "type": "katakana"
  },
  {
    "id": 175,
    "glyph": "ン",
    "reading": "n ",
    "type": "katakana"
  },
  {
    "id": 176,
    "glyph": "キャ",
    "reading": "kya",
    "type": "katakana"
  },
  {
    "id": 177,
    "glyph": "キュ",
    "reading": "kyu",
    "type": "katakana"
  },
  {
    "id": 178,
    "glyph": "キョ",
    "reading": "kyo",
    "type": "katakana"
  },
  {
    "id": 179,
    "glyph": "ギャ",
    "reading": "gya",
    "type": "katakana"
  },
  {
    "id": 180,
    "glyph": "ギュ",
    "reading": "gyu",
    "type": "katakana"
  },
  {
    "id": 181,
    "glyph": "ギョ",
    "reading": "gyo",
    "type": "katakana"
  },
  {
    "id": 182,
    "glyph": "シャ",
    "reading": "sha",
    "type": "katakana"
  },
  {
    "id": 183,
    "glyph": "シュ",
    "reading": "shu",
    "type": "katakana"
  },
  {
    "id": 184,
    "glyph": "ショ",
    "reading": "sho",
    "type": "katakana"
  },
  {
    "id": 185,
    "glyph": "ジャ",
    "reading": "ja",
    "type": "katakana"
  },
  {
    "id": 186,
    "glyph": "ジュ",
    "reading": "ju",
    "type": "katakana"
  },
  {
    "id": 187,
    "glyph": "ジョ",
    "reading": "jo",
    "type": "katakana"
  },
  {
    "id": 188,
    "glyph": "チャ",
    "reading": "cha",
    "type": "katakana"
  },
  {
    "id": 189,
    "glyph": "チュ",
    "reading": "chu",
    "type": "katakana"
  },
  {
    "id": 190,
    "glyph": "チョ",
    "reading": "cho",
    "type": "katakana"
  },
  {
    "id": 191,
    "glyph": "ニャ",
    "reading": "nya",
    "type": "katakana"
  },
  {
    "id": 192,
    "glyph": "ニュ",
    "reading": "nyu",
    "type": "katakana"
  },
  {
    "id": 193,
    "glyph": "ニョ",
    "reading": "nyo",
    "type": "katakana"
  },
  {
    "id": 194,
    "glyph": "ヒャ",
    "reading": "hya",
    "type": "katakana"
  },
  {
    "id": 195,
    "glyph": "ヒュ",
    "reading": "hyu",
    "type": "katakana"
  },
  {
    "id": 196,
    "glyph": "ヒョ",
    "reading": "hyo",
    "type": "katakana"
  },
  {
    "id": 197,
    "glyph": "ビャ",
    "reading": "bya",
    "type": "katakana"
  },
  {
    "id": 198,
    "glyph": "ビュ",
    "reading": "byu",
    "type": "katakana"
  },
  {
    "id": 199,
    "glyph": "ビョ",
    "reading": "byo",
    "type": "katakana"
  },
  {
    "id": 200,
    "glyph": "ピャ",
    "reading": "pya",
    "type": "katakana"
  },
  {
    "id": 201,
    "glyph": "ピュ",
    "reading": "pyu",
    "type": "katakana"
  },
  {
    "id": 202,
    "glyph": "ピョ",
    "reading": "pyo",
    "type": "katakana"
  },
  {
    "id": 203,
    "glyph": "ミャ",
    "reading": "mya",
    "type": "katakana"
  },
  {
    "id": 204,
    "glyph": "ミュ",
    "reading": "myu",
    "type": "katakana"
  },
  {
    "id": 205,
    "glyph": "ミョ",
    "reading": "myo",
    "type": "katakana"
  },
  {
    "id": 206,
    "glyph": "リャ",
    "reading": "rya",
    "type": "katakana"
  },
  {
    "id": 207,
    "glyph": "リュ",
    "reading": "ryu",
    "type": "katakana"
  },
  {
    "id": 208,
    "glyph": "リョ",
    "reading": "ryo",
    "type": "katakana"
  },
  {
    "id": 209,
    "glyph": "ファ",
    "reading": "fa",
    "type": "katakana"
  },
  {
    "id": 210,
    "glyph": "フィ",
    "reading": "fi",
    "type": "katakana"
  },
  {
    "id": 211,
    "glyph": "フェ",
    "reading": "fe",
    "type": "katakana"
  },
  {
    "id": 212,
    "glyph": "フォ",
    "reading": "fo",
    "type": "katakana"
  },
  {
    "id": 213,
    "glyph": "フュ",
    "reading": "fyu",
    "type": "katakana"
  },
  {
    "id": 214,
    "glyph": "ウィ",
    "reading": "wi",
    "type": "katakana"
  },
  {
    "id": 215,
    "glyph": "ウェ",
    "reading": "we",
    "type": "katakana"
  },
  {
    "id": 216,
    "glyph": "ウォ",
    "reading": "wo",
    "type": "katakana"
  },
  {
    "id": 217,
    "glyph": "ヴァ",
    "reading": "va",
    "type": "katakana"
  },
  {
    "id": 218,
    "glyph": "ヴィ",
    "reading": "vi",
    "type": "katakana"
  },
  {
    "id": 219,
    "glyph": "ヴェ",
    "reading": "ve",
    "type": "katakana"
  },
  {
    "id": 220,
    "glyph": "ヴォ",
    "reading": "vo",
    "type": "katakana"
  },
  {
    "id": 221,
    "glyph": "ツァ",
    "reading": "tsa",
    "type": "katakana"
  },
  {
    "id": 222,
    "glyph": "ツィ",
    "reading": "tsi",
    "type": "katakana"
  },
  {
    "id": 223,
    "glyph": "ツェ",
    "reading": "tse",
    "type": "katakana"
  },
  {
    "id": 224,
    "glyph": "ツォ",
    "reading": "tso",
    "type": "katakana"
  },
  {
    "id": 225,
    "glyph": "チェ",
    "reading": "che",
    "type": "katakana"
  },
  {
    "id": 226,
    "glyph": "シェ",
    "reading": "she",
    "type": "katakana"
  },
  {
    "id": 227,
    "glyph": "ジェ",
    "reading": "je",
    "type": "katakana"
  },
  {
    "id": 228,
    "glyph": "ティ",
    "reading": "ti",
    "type": "katakana"
  },
  {
    "id": 229,
    "glyph": "ディ",
    "reading": "di",
    "type": "katakana"
  },
  {
    "id": 230,
    "glyph": "デュ",
    "reading": "du",
    "type": "katakana"
  },
  {
    "id": 231,
    "glyph": "トゥ",
    "reading": "tu",
    "type": "katakana"
  }
]
