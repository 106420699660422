import { TextStyle } from "../App";
import { StyledButton } from "../Components/ModeTile";
import { UnitDefinition } from "../Config/UnitDefinitionMap";
import { db } from "../Database/db";
import { AnswerState, HistoryState } from "../Database/HistoryState";
import { ControllerModeContainer } from "../Styles/Styles";
import { Data, SetData } from "../Types/Interfaces";

export const DevDebugView: React.FC<SetData & Data<{Unit: UnitDefinition<any>}>> = props => {

    
    const CompleteUnit = async (UnitId: number) => {


        var history = await (new HistoryState()).GetByUnitId(UnitId)
        var mappedHistory = history.map(h => {
            h.Introduced = true
            h.LastSeen = new Date()
            h.Score = 1
            h.Record = [{ Record: AnswerState.Success, TimeAnswered: new Date(), TimeToAnswer: 1 }]
            h.Unlocked = true
            return h
        });

        (new HistoryState()).SetTestDefinition(mappedHistory, UnitId, props.Data.Unit.ParentTestDefinitionId)

        props.SetData()


    }

    const ResetUnit = async (UnitId: number) => {
        var history = await (new HistoryState()).GetByUnitId(UnitId)

        var mappedHistory = history.map(h => {
            h.Introduced = false
            h.Unlocked = props.Data.Unit.ItemsLocked
            h.LastSeen = undefined
            h.Score = 0
            h.Record = []
            h.Unlocked = true
            return h
        });

        (new HistoryState()).SetTestDefinition(mappedHistory, UnitId, props.Data.Unit.ParentTestDefinitionId)

        await db.UnitState.update(UnitId, { Completed: false })

        props.SetData()

    }

    const LockUnit = async (UnitId: number) => {
        await db.UnitState.update(UnitId, { Locked: true })
        props.SetData()
    }

    return <>
        <ControllerModeContainer>
            <StyledButton onClick={() => CompleteUnit(props.Data.Unit.UnitId)}><TextStyle>Complete unit</TextStyle></StyledButton>
            <StyledButton onClick={() => ResetUnit(props.Data.Unit.UnitId)}><TextStyle>Reset unit</TextStyle></StyledButton>
            <StyledButton onClick={() => LockUnit(props.Data.Unit.UnitId)}><TextStyle>Lock unit</TextStyle></StyledButton>
        </ControllerModeContainer>
    </>
}