export type KangxiUnitItem = { n: number, glyph: string, definition: string, reading: string }


export const kangxi : KangxiUnitItem[] = [
  {
    "n": 1,
    "glyph": "一",
    "definition": "one",
    "reading": "yī"
  },
  {
    "n": 2,
    "glyph": "丨",
    "definition": "line",
    "reading": "shù"
  },
  {
    "n": 3,
    "glyph": "丶",
    "definition": "dot",
    "reading": "diǎn"
  },
  {
    "n": 4,
    "glyph": "丿",
    "definition": "slash",
    "reading": "piě"
  },
  {
    "n": 5,
    "glyph": "乙",
    "definition": "second",
    "reading": "yǐ"
  },
  {
    "n": 6,
    "glyph": "亅",
    "definition": "hook",
    "reading": "gōu"
  },
  {
    "n": 7,
    "glyph": "二",
    "definition": "two",
    "reading": "èr"
  },
  {
    "n": 8,
    "glyph": "亠",
    "definition": "lid",
    "reading": "tóu"
  },
  {
    "n": 9,
    "glyph": "人",
    "definition": "person",
    "reading": "rén"
  },
  {
    "n": 10,
    "glyph": "儿",
    "definition": "legs",
    "reading": "ér"
  },
  {
    "n": 11,
    "glyph": "入",
    "definition": "enter",
    "reading": "rù"
  },
  {
    "n": 12,
    "glyph": "八",
    "definition": "eight",
    "reading": "bā"
  },
  {
    "n": 13,
    "glyph": "冂",
    "definition": "down box",
    "reading": "jiǒng"
  },
  {
    "n": 14,
    "glyph": "冖",
    "definition": "cover",
    "reading": "mì"
  },
  {
    "n": 15,
    "glyph": "冫",
    "definition": "ice",
    "reading": "bīng"
  },
  {
    "n": 16,
    "glyph": "几",
    "definition": "table",
    "reading": "jī, jǐ"
  },
  {
    "n": 17,
    "glyph": "凵",
    "definition": "open box",
    "reading": "qǔ"
  },
  {
    "n": 18,
    "glyph": "刀",
    "definition": "knife",
    "reading": "dāo"
  },
  {
    "n": 19,
    "glyph": "力",
    "definition": "power",
    "reading": "lì"
  },
  {
    "n": 20,
    "glyph": "勹",
    "definition": "wrap",
    "reading": "bāo"
  },
  {
    "n": 21,
    "glyph": "匕",
    "definition": "ladle",
    "reading": "bǐ"
  },
  {
    "n": 22,
    "glyph": "匚",
    "definition": "right open box",
    "reading": "fāng"
  },
  {
    "n": 23,
    "glyph": "匸",
    "definition": "hiding enclosure",
    "reading": "xǐ"
  },
  {
    "n": 24,
    "glyph": "十",
    "definition": "ten",
    "reading": "shí"
  },
  {
    "n": 25,
    "glyph": "卜",
    "definition": "divination",
    "reading": "bǔ"
  },
  {
    "n": 26,
    "glyph": "卩",
    "definition": "seal",
    "reading": "jié"
  },
  {
    "n": 27,
    "glyph": "厂",
    "definition": "cliff",
    "reading": "hàn"
  },
  {
    "n": 28,
    "glyph": "厶",
    "definition": "private",
    "reading": "sī"
  },
  {
    "n": 29,
    "glyph": "又",
    "definition": "again",
    "reading": "yòu"
  },
  {
    "n": 30,
    "glyph": "口",
    "definition": "mouth",
    "reading": "kǒu"
  },
  {
    "n": 31,
    "glyph": "囗",
    "definition": "enclosure",
    "reading": "wéi"
  },
  {
    "n": 32,
    "glyph": "土",
    "definition": "earth",
    "reading": "tǔ"
  },
  {
    "n": 33,
    "glyph": "士",
    "definition": "scholar",
    "reading": "shì"
  },
  {
    "n": 34,
    "glyph": "夂",
    "definition": "go",
    "reading": "zhī"
  },
  {
    "n": 35,
    "glyph": "夊",
    "definition": "go slowly",
    "reading": "suī"
  },
  {
    "n": 36,
    "glyph": "夕",
    "definition": "night",
    "reading": "xī"
  },
  {
    "n": 37,
    "glyph": "大",
    "definition": "big",
    "reading": "dà"
  },
  {
    "n": 38,
    "glyph": "女",
    "definition": "woman",
    "reading": "nǚ"
  },
  {
    "n": 39,
    "glyph": "子",
    "definition": "child",
    "reading": "zǐ"
  },
  {
    "n": 40,
    "glyph": "宀",
    "definition": "roof",
    "reading": "gài"
  },
  {
    "n": 41,
    "glyph": "寸",
    "definition": "inch",
    "reading": "cùn"
  },
  {
    "n": 42,
    "glyph": "小",
    "definition": "small",
    "reading": "xiǎo"
  },
  {
    "n": 43,
    "glyph": "尢",
    "definition": "lame",
    "reading": "yóu"
  },
  {
    "n": 44,
    "glyph": "尸",
    "definition": "corpse",
    "reading": "shī"
  },
  {
    "n": 45,
    "glyph": "屮",
    "definition": "sprout",
    "reading": "chè"
  },
  {
    "n": 46,
    "glyph": "山",
    "definition": "mountain",
    "reading": "shān"
  },
  {
    "n": 47,
    "glyph": "川",
    "definition": "river",
    "reading": "chuān"
  },
  {
    "n": 48,
    "glyph": "工",
    "definition": "work",
    "reading": "gōng"
  },
  {
    "n": 49,
    "glyph": "己",
    "definition": "oneself",
    "reading": "jǐ"
  },
  {
    "n": 50,
    "glyph": "巾",
    "definition": "towel",
    "reading": "jīn"
  },
  {
    "n": 51,
    "glyph": "干",
    "definition": "dry",
    "reading": "gān"
  },
  {
    "n": 52,
    "glyph": "幺",
    "definition": "thread",
    "reading": "yāo"
  },
  {
    "n": 53,
    "glyph": "广",
    "definition": "shelter",
    "reading": "guǎng"
  },
  {
    "n": 54,
    "glyph": "廴",
    "definition": "stride",
    "reading": "yǐn"
  },
  {
    "n": 55,
    "glyph": "廾",
    "definition": "hands joined",
    "reading": "gǒng"
  },
  {
    "n": 56,
    "glyph": "弋",
    "definition": "shoot with a bow",
    "reading": "yì"
  },
  {
    "n": 57,
    "glyph": "弓",
    "definition": "bow",
    "reading": "gōng"
  },
  {
    "n": 58,
    "glyph": "彐",
    "definition": "snout",
    "reading": "jì"
  },
  {
    "n": 59,
    "glyph": "彡",
    "definition": "hair",
    "reading": "shān"
  },
  {
    "n": 60,
    "glyph": "彳",
    "definition": "step",
    "reading": "chì"
  },
  {
    "n": 61,
    "glyph": "心",
    "definition": "heart",
    "reading": "xīn"
  },
  {
    "n": 62,
    "glyph": "戈",
    "definition": "spear",
    "reading": "gē"
  },
  {
    "n": 63,
    "glyph": "户",
    "definition": "door",
    "reading": "hù"
  },
  {
    "n": 64,
    "glyph": "手",
    "definition": "hand",
    "reading": "shǒu"
  },
  {
    "n": 65,
    "glyph": "支",
    "definition": "branch",
    "reading": "zhī"
  },
  {
    "n": 66,
    "glyph": "攴",
    "definition": "rap",
    "reading": "pū"
  },
  {
    "n": 67,
    "glyph": "文",
    "definition": "script",
    "reading": "wén"
  },
  {
    "n": 68,
    "glyph": "斗",
    "definition": "dipper",
    "reading": "dǒu"
  },
  {
    "n": 69,
    "glyph": "斤",
    "definition": "axe",
    "reading": "jīn"
  },
  {
    "n": 70,
    "glyph": "方",
    "definition": "square",
    "reading": "fāng"
  },
  {
    "n": 71,
    "glyph": "无",
    "definition": "not",
    "reading": "wú"
  },
  {
    "n": 72,
    "glyph": "日",
    "definition": "sun",
    "reading": "rì"
  },
  {
    "n": 73,
    "glyph": "曰",
    "definition": "say",
    "reading": "yuē"
  },
  {
    "n": 74,
    "glyph": "月",
    "definition": "moon",
    "reading": "yuè"
  },
  {
    "n": 75,
    "glyph": "木",
    "definition": "tree",
    "reading": "mù"
  },
  {
    "n": 76,
    "glyph": "欠",
    "definition": "lack",
    "reading": "qiàn"
  },
  {
    "n": 77,
    "glyph": "止",
    "definition": "stop",
    "reading": "zhǐ"
  },
  {
    "n": 78,
    "glyph": "歹",
    "definition": "death",
    "reading": "dǎi"
  },
  {
    "n": 79,
    "glyph": "殳",
    "definition": "weapon",
    "reading": "shū"
  },
  {
    "n": 80,
    "glyph": "母",
    "definition": "mother",
    "reading": "mǔ"
  },
  {
    "n": 81,
    "glyph": "比",
    "definition": "compare",
    "reading": "bǐ"
  },
  {
    "n": 82,
    "glyph": "毛",
    "definition": "fur",
    "reading": "máo"
  },
  {
    "n": 83,
    "glyph": "氏",
    "definition": "clan",
    "reading": "shì"
  },
  {
    "n": 84,
    "glyph": "气",
    "definition": "steam",
    "reading": "qì"
  },
  {
    "n": 85,
    "glyph": "水",
    "definition": "water",
    "reading": "shuǐ"
  },
  {
    "n": 86,
    "glyph": "火",
    "definition": "fire",
    "reading": "huǒ"
  },
  {
    "n": 87,
    "glyph": "爪",
    "definition": "claw",
    "reading": "zhǎo"
  },
  {
    "n": 88,
    "glyph": "父",
    "definition": "father",
    "reading": "fù"
  },
  {
    "n": 89,
    "glyph": "爻",
    "definition": "lines on a trigram",
    "reading": "yáo"
  },
  {
    "n": 90,
    "glyph": "爿",
    "definition": "half of a tree trunk",
    "reading": "qiáng"
  },
  {
    "n": 91,
    "glyph": "片",
    "definition": "slice",
    "reading": "piàn"
  },
  {
    "n": 92,
    "glyph": "牙",
    "definition": "tooth",
    "reading": "yá"
  },
  {
    "n": 93,
    "glyph": "牛",
    "definition": "cow",
    "reading": "niú"
  },
  {
    "n": 94,
    "glyph": "犭",
    "definition": "dog",
    "reading": "quǎn"
  },
  {
    "n": 95,
    "glyph": "玄",
    "definition": "profound",
    "reading": "xuán"
  },
  {
    "n": 96,
    "glyph": "玉",
    "definition": "jade",
    "reading": "yù"
  },
  {
    "n": 97,
    "glyph": "瓜",
    "definition": "melon",
    "reading": "guā"
  },
  {
    "n": 98,
    "glyph": "瓦",
    "definition": "tile",
    "reading": "wǎ"
  },
  {
    "n": 99,
    "glyph": "甘",
    "definition": "sweet",
    "reading": "gān"
  },
  {
    "n": 100,
    "glyph": "生",
    "definition": "life",
    "reading": "shēng"
  },
  {
    "n": 101,
    "glyph": "用",
    "definition": "use",
    "reading": "yòng"
  },
  {
    "n": 102,
    "glyph": "田",
    "definition": "field",
    "reading": "tián"
  },
  {
    "n": 103,
    "glyph": "疋",
    "definition": "cloth",
    "reading": "pǐ"
  },
  {
    "n": 104,
    "glyph": "疒",
    "definition": "ill",
    "reading": "bìng"
  },
  {
    "n": 105,
    "glyph": "癶",
    "definition": "foot steps",
    "reading": "bō"
  },
  {
    "n": 106,
    "glyph": "白",
    "definition": "white",
    "reading": "bái"
  },
  {
    "n": 107,
    "glyph": "皮",
    "definition": "skin",
    "reading": "pí"
  },
  {
    "n": 108,
    "glyph": "皿",
    "definition": "dish",
    "reading": "mǐn"
  },
  {
    "n": 109,
    "glyph": "目",
    "definition": "eye",
    "reading": "mù"
  },
  {
    "n": 110,
    "glyph": "矛",
    "definition": "spear",
    "reading": "máo"
  },
  {
    "n": 111,
    "glyph": "矢",
    "definition": "arrow",
    "reading": "shǐ"
  },
  {
    "n": 112,
    "glyph": "石",
    "definition": "stone",
    "reading": "shí"
  },
  {
    "n": 113,
    "glyph": "示",
    "definition": "spirit",
    "reading": "shì"
  },
  {
    "n": 114,
    "glyph": "禸",
    "definition": "track",
    "reading": "róu"
  },
  {
    "n": 115,
    "glyph": "禾",
    "definition": "grain",
    "reading": "hé"
  },
  {
    "n": 116,
    "glyph": "穴",
    "definition": "cave",
    "reading": "xuè"
  },
  {
    "n": 117,
    "glyph": "立",
    "definition": "stand",
    "reading": "lì"
  },
  {
    "n": 118,
    "glyph": "竹",
    "definition": "bamboo",
    "reading": "zhú"
  },
  {
    "n": 119,
    "glyph": "米",
    "definition": "rice",
    "reading": "mǐ"
  },
  {
    "n": 120,
    "glyph": "纟",
    "definition": "silk",
    "reading": "sī"
  },
  {
    "n": 121,
    "glyph": "缶",
    "definition": "jar",
    "reading": "fǒu"
  },
  {
    "n": 122,
    "glyph": "网",
    "definition": "net",
    "reading": "wǎng"
  },
  {
    "n": 123,
    "glyph": "羊",
    "definition": "sheep",
    "reading": "yáng"
  },
  {
    "n": 124,
    "glyph": "羽",
    "definition": "feather",
    "reading": "yǔ"
  },
  {
    "n": 125,
    "glyph": "老",
    "definition": "old",
    "reading": "lǎo"
  },
  {
    "n": 126,
    "glyph": "而",
    "definition": "and",
    "reading": "ér"
  },
  {
    "n": 127,
    "glyph": "耒",
    "definition": "plow",
    "reading": "lěi"
  },
  {
    "n": 128,
    "glyph": "耳",
    "definition": "ear",
    "reading": "ěr"
  },
  {
    "n": 129,
    "glyph": "聿",
    "definition": "brush",
    "reading": "yù"
  },
  {
    "n": 130,
    "glyph": "肉",
    "definition": "meat",
    "reading": "ròu"
  },
  {
    "n": 131,
    "glyph": "臣",
    "definition": "minister",
    "reading": "chén"
  },
  {
    "n": 132,
    "glyph": "自",
    "definition": "oneself",
    "reading": "zì"
  },
  {
    "n": 133,
    "glyph": "至",
    "definition": "arrive",
    "reading": "zhì"
  },
  {
    "n": 134,
    "glyph": "臼",
    "definition": "mortar",
    "reading": "jiù"
  },
  {
    "n": 135,
    "glyph": "舌",
    "definition": "tongue",
    "reading": "shé"
  },
  {
    "n": 136,
    "glyph": "舛",
    "definition": "contrary",
    "reading": "chuǎn"
  },
  {
    "n": 137,
    "glyph": "舟",
    "definition": "boat",
    "reading": "zhōu"
  },
  {
    "n": 138,
    "glyph": "艮",
    "definition": "mountain",
    "reading": "gèn"
  },
  {
    "n": 139,
    "glyph": "色",
    "definition": "color",
    "reading": "sè"
  },
  {
    "n": 140,
    "glyph": "艹",
    "definition": "grass",
    "reading": "cǎo"
  },
  {
    "n": 141,
    "glyph": "虍",
    "definition": "tiger",
    "reading": "hǔ"
  },
  {
    "n": 142,
    "glyph": "虫",
    "definition": "insect",
    "reading": "chóng"
  },
  {
    "n": 143,
    "glyph": "血",
    "definition": "blood",
    "reading": "xuě"
  },
  {
    "n": 144,
    "glyph": "行",
    "definition": "walk",
    "reading": "xíng"
  },
  {
    "n": 145,
    "glyph": "衣",
    "definition": "clothes",
    "reading": "yī"
  },
  {
    "n": 146,
    "glyph": "西",
    "definition": "west",
    "reading": "xī"
  },
  {
    "n": 147,
    "glyph": "见",
    "definition": "see",
    "reading": "jiàn"
  },
  {
    "n": 148,
    "glyph": "角",
    "definition": "horn",
    "reading": "jiǎo"
  },
  {
    "n": 149,
    "glyph": "讠",
    "definition": "speech",
    "reading": "yán"
  },
  {
    "n": 150,
    "glyph": "谷",
    "definition": "valley",
    "reading": "gǔ"
  },
  {
    "n": 151,
    "glyph": "豆",
    "definition": "bean",
    "reading": "dòu"
  },
  {
    "n": 152,
    "glyph": "豕",
    "definition": "pig",
    "reading": "shǐ"
  },
  {
    "n": 153,
    "glyph": "豸",
    "definition": "badger",
    "reading": "zhì"
  },
  {
    "n": 154,
    "glyph": "贝",
    "definition": "shell",
    "reading": "bèi"
  },
  {
    "n": 155,
    "glyph": "赤",
    "definition": "red",
    "reading": "chì"
  },
  {
    "n": 156,
    "glyph": "走",
    "definition": "walk",
    "reading": "zǒu"
  },
  {
    "n": 157,
    "glyph": "足",
    "definition": "foot",
    "reading": "zú"
  },
  {
    "n": 158,
    "glyph": "身",
    "definition": "body",
    "reading": "shēn"
  },
  {
    "n": 159,
    "glyph": "车",
    "definition": "cart",
    "reading": "chē"
  },
  {
    "n": 160,
    "glyph": "辛",
    "definition": "bitter",
    "reading": "xīn"
  },
  {
    "n": 161,
    "glyph": "辰",
    "definition": "morning",
    "reading": "chén"
  },
  {
    "n": 162,
    "glyph": "辶",
    "definition": "walk",
    "reading": "chuò"
  },
  {
    "n": 163,
    "glyph": "邑",
    "definition": "city",
    "reading": "yì"
  },
  {
    "n": 164,
    "glyph": "酉",
    "definition": "wine",
    "reading": "yǒu"
  },
  {
    "n": 165,
    "glyph": "釆",
    "definition": "distinguish",
    "reading": "biàn"
  },
  {
    "n": 166,
    "glyph": "里",
    "definition": "village",
    "reading": "lǐ"
  },
  {
    "n": 167,
    "glyph": "钅",
    "definition": "metal",
    "reading": "jīn"
  },
  {
    "n": 168,
    "glyph": "长",
    "definition": "long",
    "reading": "cháng"
  },
  {
    "n": 169,
    "glyph": "门",
    "definition": "gate",
    "reading": "mén"
  },
  {
    "n": 170,
    "glyph": "阜",
    "definition": "mound",
    "reading": "fù"
  },
  {
    "n": 171,
    "glyph": "隶",
    "definition": "slave",
    "reading": "lì"
  },
  {
    "n": 172,
    "glyph": "隹",
    "definition": "short-tailed bird",
    "reading": "zhuī"
  },
  {
    "n": 173,
    "glyph": "雨",
    "definition": "rain",
    "reading": "yǔ"
  },
  {
    "n": 174,
    "glyph": "青",
    "definition": "blue",
    "reading": "qīng"
  },
  {
    "n": 175,
    "glyph": "非",
    "definition": "wrong",
    "reading": "fēi"
  },
  {
    "n": 176,
    "glyph": "面",
    "definition": "face",
    "reading": "miàn"
  },
  {
    "n": 177,
    "glyph": "革",
    "definition": "leather",
    "reading": "gé"
  },
  {
    "n": 178,
    "glyph": "韦",
    "definition": "soft leather",
    "reading": "wěi"
  },
  {
    "n": 179,
    "glyph": "韭",
    "definition": "leek",
    "reading": "jiǔ"
  },
  {
    "n": 180,
    "glyph": "音",
    "definition": "sound",
    "reading": "yīn"
  },
  {
    "n": 181,
    "glyph": "页",
    "definition": "page",
    "reading": "yè"
  },
  {
    "n": 182,
    "glyph": "风",
    "definition": "wind",
    "reading": "fēng"
  },
  {
    "n": 183,
    "glyph": "飞",
    "definition": "fly",
    "reading": "fēi"
  },
  {
    "n": 184,
    "glyph": "饣",
    "definition": "eat",
    "reading": "shí"
  },
  {
    "n": 185,
    "glyph": "首",
    "definition": "head",
    "reading": "shǒu"
  },
  {
    "n": 186,
    "glyph": "香",
    "definition": "fragrant",
    "reading": "xiāng"
  },
  {
    "n": 187,
    "glyph": "马",
    "definition": "horse",
    "reading": "mǎ"
  },
  {
    "n": 188,
    "glyph": "骨",
    "definition": "bone",
    "reading": "gǔ"
  },
  {
    "n": 189,
    "glyph": "高",
    "definition": "high",
    "reading": "gāo"
  },
  {
    "n": 190,
    "glyph": "髟",
    "definition": "long hair",
    "reading": "biāo"
  },
  {
    "n": 191,
    "glyph": "鬥",
    "definition": "fight",
    "reading": "dòu"
  },
  {
    "n": 192,
    "glyph": "鬯",
    "definition": "sacrificial wine",
    "reading": "chàng"
  },
  {
    "n": 193,
    "glyph": "鬲",
    "definition": "cauldron",
    "reading": "lì"
  },
  {
    "n": 194,
    "glyph": "鬼",
    "definition": "ghost",
    "reading": "guǐ"
  },
  {
    "n": 195,
    "glyph": "鱼",
    "definition": "fish",
    "reading": "yú"
  },
  {
    "n": 196,
    "glyph": "鸟",
    "definition": "bird",
    "reading": "niǎo"
  },
  {
    "n": 197,
    "glyph": "卤",
    "definition": "salty",
    "reading": "lǔ"
  },
  {
    "n": 198,
    "glyph": "鹿",
    "definition": "deer",
    "reading": "lù"
  },
  {
    "n": 199,
    "glyph": "麦",
    "definition": "wheat",
    "reading": "mài"
  },
  {
    "n": 200,
    "glyph": "麻",
    "definition": "hemp",
    "reading": "má"
  },
  {
    "n": 201,
    "glyph": "黄",
    "definition": "yellow",
    "reading": "huáng"
  },
  {
    "n": 202,
    "glyph": "黍",
    "definition": "millet",
    "reading": "shǔ"
  },
  {
    "n": 203,
    "glyph": "黑",
    "definition": "black",
    "reading": "hēi"
  },
  {
    "n": 204,
    "glyph": "黹",
    "definition": "embroidery",
    "reading": "zhǐ"
  },
  {
    "n": 205,
    "glyph": "黾",
    "definition": "frog",
    "reading": "mǐn"
  },
  {
    "n": 206,
    "glyph": "鼎",
    "definition": "tripod",
    "reading": "dǐng"
  },
  {
    "n": 207,
    "glyph": "鼓",
    "definition": "drum",
    "reading": "gǔ"
  },
  {
    "n": 208,
    "glyph": "鼠",
    "definition": "rat",
    "reading": "shǔ"
  },
  {
    "n": 209,
    "glyph": "鼻",
    "definition": "nose",
    "reading": "bí"
  },
  {
    "n": 210,
    "glyph": "齐",
    "definition": "even",
    "reading": "qí"
  },
  {
    "n": 211,
    "glyph": "齿",
    "definition": "tooth",
    "reading": "chǐ"
  },
  {
    "n": 212,
    "glyph": "龙",
    "definition": "dragon",
    "reading": "lóng"
  },
  {
    "n": 213,
    "glyph": "龟",
    "definition": "turtle",
    "reading": "guī"
  },
  {
    "n": 214,
    "glyph": "龠",
    "definition": "flute",
    "reading": "yuè"
  }
]
