import { Input } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useState, useMemo, useEffect } from "react"
import { TextStyle } from "../App"
import { Collapse } from "../Components/Collapse"
import { SetMenu } from "../Loaders/UnitLoader"
import { UnitDefinitionMap, UnitType } from "../Config/UnitDefinitionMap"
import { HistoryElement, HistoryState } from "../Database/HistoryState"
import { HistoryElementDisplay } from "../Displays/HistoryElementDisplay"
import { ControllerModeContainer } from "../Styles/Styles"

export const ProcessTextView: React.FC<SetMenu> = props => {

    enum Mode {
        Input,
        View,
        CreateSubUnit,
        Processed
    }

    const [displayMode, setDisplayMode] = useState(Mode.Input)

    const [text, setText] = useState<string | undefined>(undefined)

    const [process, setProcess] = useState(false)

    const [uniqueCount, setUniqueCount] = useState<number | undefined>(undefined)
    const [processedList, setProcessedList] = useState<any[]>([])
    const [processedHistory, setProcessedHistory] = useState<HistoryElement[]>([])

    const UploadMenu = [
        {
            Label: 'Process',
            OnClick: () => Process()
        }
    ]

    const ViewMenu = [
        {
            Label: 'Process again',
            OnClick: () => Reset()
        }, {
            Label: 'Create Sub-Unit',
            OnClick: () => CreateSubUnit()
        }
    ]

    const CreateSubUnitMenu = [
        {
            Label: 'Save',
            OnClick: () => Created()
        }
    ]

    const FinalMenu = [
        {
            Label: 'Process again',
            OnClick: () => Reset()
        },
    ]

    useMemo(() => props.SetMenu(UploadMenu), [])

    useEffect(() => {
        if (process) {
            ProcessText(text!!)
            setProcess(false)
        }
    }, [process])

    const ProcessText = async (text: string) => {
        const split = text.split("")
        const filtered = split.filter(c => {
            var codepoint = c.codePointAt(0);
            if (codepoint === undefined) {
                return false
            } else {
                return codepoint >= 19968 && codepoint <= 40959
            }
        })
        console.log(`raw ${split.length}`)
        console.log(`len ${filtered.length}`)


        var charMap = {} as any

        filtered.map(i => {
            if (charMap[i] == undefined) {
                charMap[i] = 1
            } else {
                charMap[i] += 1
            }
        })

        var uniqueC = Object.keys(charMap).length
        console.log(`charmap ${Object.keys(charMap).length}`)
        setUniqueCount(uniqueC)

        var getItemsMap = {} as any

        var unit = UnitDefinitionMap[UnitType.Hanzi]
        unit.Items.map(i => getItemsMap[unit.GetItemDisplay(i)] = i)

        const found = Object.keys(charMap).filter(c => {
            return getItemsMap[c] !== undefined
        }).map(c => {
            return {
                character: c,
                item: getItemsMap[c]
            }
        })

        console.log(`matching ${found.length}`)
        setProcessedList(found)

        var history = await (new HistoryState()).GetByTestDefinitionId(unit.UnitId, unit.ParentTestDefinitionId)

        var historyMap = {} as any

        history.map(h => historyMap[h.ItemId] = h)

        console.log(historyMap)

        var historyElements = found.map(f => historyMap[unit.GetItemId(f.item)])
        console.log(historyElements)
        setProcessedHistory(historyElements)

    }

    const Process = () => {

        setProcess(true)
        setDisplayMode(Mode.View)
        props.SetMenu(ViewMenu)
    }

    const Reset = () => {
        setDisplayMode(Mode.Input)
        setText(undefined)
        props.SetMenu(UploadMenu)

    }

    const CreateSubUnit = () => {
        setDisplayMode(Mode.CreateSubUnit)
        props.SetMenu(CreateSubUnitMenu)
    }

    const Created = () => {
        setDisplayMode(Mode.Processed)
        props.SetMenu(FinalMenu)
    }

    const knownHistory = processedHistory.filter(h => h.Introduced).length

    return <>
        {displayMode == Mode.Input && <>
            <TextStyle size={2}>Process Text</TextStyle>
            <TextArea rows={4} onChange={(e) => setText(e.target.value)} />
        </>}
        {displayMode == Mode.View && <>
            <TextStyle size={3}>Processed.</TextStyle>
            <TextStyle>Unique characters: {uniqueCount}</TextStyle>
            <TextStyle>Processed characters: {processedList.length}</TextStyle>
            <TextStyle>Seen characters: {knownHistory} ({((knownHistory / processedList.length) * 100).toFixed(2)}%)</TextStyle>
            <Collapse Label="Characters" Collapsed={true}>
                <ControllerModeContainer>

                    <HistoryElementDisplay Data={{ Unit: UnitDefinitionMap[UnitType.Hanzi], History: processedHistory }} />

                </ControllerModeContainer>
            </Collapse>

            <Collapse Label='Original text' Collapsed={true}>
                <TextArea defaultValue={text} disabled={true}></TextArea>
            </Collapse>

        </>}
        {displayMode == Mode.CreateSubUnit && <>
            <TextStyle>Name</TextStyle>
            <Input />
        </>}
        {displayMode == Mode.Processed && <>
            <TextStyle>Created!</TextStyle>
        </>}
    </>
}