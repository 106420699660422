import { TextStyle } from "../App"
import { RoundRecord } from "./RoundView"
import { UnitDefinition } from "../Config/UnitDefinitionMap"
import { RoundRecordDisplay } from "../Displays/RoundRecordDisplay"
import { ControllerModeContainer } from "../Styles/Styles"
import { Data, SetData } from "../Types/Interfaces"
import { Collapse } from "../Components/Collapse"
import { StyledButton } from "../Components/ModeTile"

export const RoundRecordView: React.FC<SetData & Data<{ Unit: UnitDefinition<any>, RoundRecords: RoundRecord[] }>> = props => {

    const dateSorted = Object.values(props.Data.RoundRecords)
        .sort((a, b) => { return (new Date(b.TimeFinished)).getTime() - (new Date(a.TimeFinished)).getTime() })

    const dayGrouped = {} as { [date: string]: { [guid: string]: RoundRecord[] } }

    dateSorted.map(rr => {
        var date = rr.TimeFinished
        var dayKey = date.toDateString()
        if (dayGrouped[dayKey] == undefined) {
            dayGrouped[dayKey] = {} as any
            dayGrouped[dayKey][rr.RoundGuid] = [rr]
        } else {
            if (dayGrouped[dayKey][rr.RoundGuid] == undefined) {
                dayGrouped[dayKey][rr.RoundGuid] = [rr]
            } else {
                dayGrouped[dayKey][rr.RoundGuid].push(rr)

            }
        }
    })

    console.log(dayGrouped)

    return <>
        <ControllerModeContainer>
            {
                Object.keys(dayGrouped).map(k => {
                    return <Collapse key={k} Label={k} Collapsed={true}>
                        {
                            Object.keys(dayGrouped[k]).map((guid: any) => {
                                return <Collapse key={guid} Label={guid} Collapsed={false}>
                                    <div><TextStyle>Times taken today: {dayGrouped[k][guid].length}</TextStyle></div>
                                    <div><StyledButton onClick={()=>{props.SetData(dayGrouped[k][guid][0])}}>Retake</StyledButton></div>
                                </Collapse>
                            })
                        }
                    </Collapse>
                })
            }
            {
                props.Data.RoundRecords.length == 0 && <TextStyle>No round records to display.</TextStyle>
            }
        </ControllerModeContainer>
    </>
}