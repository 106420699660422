import { UnitDefinition } from "../Config/UnitDefinitionMap"
import { HistoryElement } from "../Database/HistoryState"
import { HistoryElementDisplay } from "../Displays/HistoryElementDisplay"
import { ControllerModeContainer } from "../Styles/Styles"
import { Data } from "../Types/Interfaces"

export const UnitItemView: React.FC<Data<{ Unit: UnitDefinition<any>, History: HistoryElement[] }>> = props => {
    return <ControllerModeContainer>
        <HistoryElementDisplay Data={{ Unit: props.Data.Unit, History: props.Data.History }} />
    </ControllerModeContainer>
}