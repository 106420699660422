import { TextStyle } from "../App"
import { Collapse } from "../Components/Collapse"
import { SubUnitDefinition, UnitDefinition } from "../Config/UnitDefinitionMap"
import { HistoryElement } from "../Database/HistoryState"
import { SubUnitDisplay } from "../Displays/SubUnitDisplay"
import { ControllerModeContainer } from "../Styles/Styles"
import { Data, SetData } from "../Types/Interfaces"

export const SubUnitView: React.FC<SetData & Data<{ Unit: UnitDefinition<any>, History: HistoryElement[] }>> = props => {

    var subUnitCategories = {} as { [category : string ] : SubUnitDefinition<any>[] }

    props.Data.Unit.SubUnits.map(su => {
        if (subUnitCategories[su.Category] == undefined) {
            subUnitCategories[su.Category] = [su]
        } else {
            subUnitCategories[su.Category].push(su)
        }
    })

    var hasSubUnits = props.Data.Unit.SubUnits.length > 0

    return <>
        <ControllerModeContainer>
            {false && <Collapse Label="Processed" Collapsed={true}>
                <TextStyle>This unit has no processed text sub-units.</TextStyle>
            </Collapse>}
        </ControllerModeContainer>

        {hasSubUnits &&
            Object.entries(subUnitCategories).map((suc, i) => {
                return <Collapse Label={suc[0]} Collapsed={true}>
                    <ControllerModeContainer>

                        {suc[1].map(u => {
                            return <SubUnitDisplay SetData={props.SetData} Data={{ History: props.Data.History, Unit: props.Data.Unit, SubUnit: u }} />
                        })}
                    </ControllerModeContainer>
                </Collapse>
            })
        }
        {props.Data.Unit.SubUnits.length == 0 && <TextStyle>This unit has no sub-units defined.</TextStyle>}
    </>
}