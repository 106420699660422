import Dexie from "dexie"
import { TextStyle } from "../App"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { db } from "../Database/db"
import { HistoryElement } from "../Database/HistoryState"
import { ButtonDisplay, ButtonSubDisplay, ControllerModeContainer } from "../Styles/Styles"

export const GlobalDebugView: React.FC = props => {

    const DeleteDatabase = async () => {
        console.log('deleting database')
        await db.delete().then(() => db.open());
        alert('database deleted')
    }

    const BackupHistory = async () => {
        var history = await db.History.toArray()

        localStorage.setItem("historybackup", JSON.stringify(history));

        alert('backed up history')
    }

    const RestoreHistory = async () => {

        var historyBackupJson = localStorage.getItem("historybackup")

        if (historyBackupJson != null) {
            var historyBackup = JSON.parse(historyBackupJson) as HistoryElement[]

            historyBackup.map(h => { db.History.update(h.Id, h) })
            //db.History.update
        }

        alert('restored history')

    }

    return <>
        <ContentTile>

            <ControllerModeContainer>
                <ButtonDisplay>
                    <ButtonSubDisplay>
                        <StyledButton onClick={BackupHistory}><TextStyle>Backup history</TextStyle></StyledButton>
                        <StyledButton onClick={RestoreHistory}><TextStyle>Restore history</TextStyle></StyledButton>

                    </ButtonSubDisplay>
                    <ButtonSubDisplay>
                        <StyledButton onClick={() => { DeleteDatabase() }}><TextStyle>Delete database</TextStyle></StyledButton>

                    </ButtonSubDisplay>
                </ButtonDisplay>
                <ButtonDisplay>
                </ButtonDisplay>
            </ControllerModeContainer>

        </ContentTile>
    </>
}