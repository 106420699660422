import { AppCallbacks, TextStyle } from "../App"
import React, { useEffect, useState } from "react"
import { UnitDefinition, SubUnitDefinition } from "../Config/UnitDefinitionMap"
import { HistoryElement } from "../Database/HistoryState"
import { OnExit, Data, OnEnter } from "../Types/Interfaces"
import { RoundControllerParams, RoundRecord } from "../Views/RoundView"
import { ContentTile } from "../Components/ModeTile"
import { DefaultUnitControllerParams, SettingsState } from "../Database/SettingsState"

import { db } from "../Database/db"
import { HistoryState } from "../Database/HistoryState"
import { RoundHistoryState } from "../Database/RoundHistoryState"
import { UnitView } from "../Views/UnitView"

import {
    useWhatChanged,
    setUseWhatChange,
} from '@simbathesailor/use-what-changed';
import { subscribe } from "diagnostics_channel"
import { EventType } from "../Database/MessageRecord"

export interface SetMenu {
    SetMenu: (...a: any) => any
}

export interface SetPreamble {
    SetPreamble: (...a: any) => any
}
export interface ParentMenuConfig {
    ParentMenuConfig: {
        Label: string,
        OnClick: () => any
    }[]
}


export interface Reload {
    Reload: (mode: UnitControllerMode) => any
}

export interface UnitControllerProps {
    UnitDefinition: UnitDefinition<any>
    Params: RoundControllerParams
    RoundRecords: RoundRecord[]
    History: HistoryElement[]
}

export interface State<T> {
    State: T
}

export interface UnitControllerState {

    ControllerMode: UnitControllerMode
    Locked: boolean
    Loaded: boolean

    UseSubUnit: boolean
    SubUnitId: number

    RetryRound: boolean
    RoundRecordId: number
}

export interface UnitLoaderCallbacks {
    SetBySubUnit: (Defn: SubUnitDefinition<any>) => any
    SetByRoundRecord: (RoundRecord: RoundRecord) => any
    SetMode: (mode: UnitControllerMode) => any
    ClearRoundRecord: () => any
    ClearSubUnit: () => any
}

export enum UnitControllerMode {
    Home,
    Display,
    SubUnits,
    Learn,
    Options,
    ViewRoundRecords,
    DebugDev,
    ProcessText,
    Stats
}

export const UnitLoader: React.FC<Data<UnitDefinition<any>> & AppCallbacks> = props => {

    const unit = props.Data

    const [loaded, setLoaded] = useState(false)

    const [history, setHistory] = useState<HistoryElement[]>([])
    const [historyLoaded, setHistoryLoaded] = useState(false)
    const [roundRecords, setRoundRecords] = useState<RoundRecord[]>([])
    const [unitParams, setUnitParams] = useState(DefaultUnitControllerParams)

    const [locked, setLocked] = useState(false)

    useEffect(() => {


        let active = true

        async function ProcessStateChange() {
            // set unit state

            if (active) {
                var completed = await (new HistoryState()).SetUnitCompleted(unit.UnitId);;

                if (completed) {
                    props.SendEvent({
                        Type: EventType.UnitCompletion,
                        UnitId: props.Data.UnitId
                    })
                }
            }

            if (active) {
                var unlocked = await (new HistoryState()).UnlockUnit(unit.UnitId);;

                if (unlocked) {
                    props.SendEvent({
                        Type: EventType.UnitUnlock,
                        UnitId: props.Data.UnitId
                    })

                }
            }

        }

        async function Load() {

            if (!loaded) {
                const lockCheck = (await db.UnitState.where('UnitId').equals(unit.UnitId).first())!!.Locked
                setLocked(lockCheck)

                // skip loading history if locked - no need
                // load unit history
                await ((new HistoryState()).GetByTestDefinitionId(unit.UnitId, unit.ParentTestDefinitionId)
                    .then((history) => { setHistoryLoaded(true); setHistory(history) }))

                // load settings
                var settingsStore = new SettingsState()

                await settingsStore.Get(unit.UnitId).then(settingsStorage => {

                    settingsStorage!!.Params.OrderRound = array => array.map(value => ({ value, sort: Math.random() }))
                        .sort((a, b) => a.sort - b.sort)
                        .map(({ value }) => value)

                    setUnitParams(settingsStorage!!.Params)

                })

                // load round history

                setRoundRecords(await (new RoundHistoryState()).Get(unit.UnitId))
            }

        }

        ProcessStateChange().then(Load).finally(() => { setLoaded(true) })

        return () => {
            active = false
        }

    }, [props, loaded, unit.UnitId, unit.ParentTestDefinitionId])


    const [mode, setMode] = useState(UnitControllerMode.Home)

    const Reload = (ControllerMode: UnitControllerMode) => {
        setLoaded(false)
        setMode(ControllerMode)

    }

    const [useSubUnitContext, setUseSubUnitContext] = useState(false)
    const [useSubUnitId, setUseSubUnitId] = useState(-1)

    const [useRoundRecordContext, setUseRoundRecordContext] = useState(false)
    const [useRoundRecordId, setUseRoundRecordId] = useState(-1)

    const SetSubUnitContext = (Defn: SubUnitDefinition<any>) => {
        setUseSubUnitContext(true)
        setUseSubUnitId(Defn.Id)
        Reload(UnitControllerMode.Home)
    }

    const ClearSubUnitContext = () => {
        setUseSubUnitContext(false)
        setUseSubUnitId(-1)
        Reload(UnitControllerMode.Home)
    }

    const SetRoundRecordContext = (RoundRecord: RoundRecord) => {
        Reload(UnitControllerMode.Learn)

        setUseRoundRecordContext(true)
        setUseRoundRecordId(RoundRecord.Id!!)
    }

    const ClearRoundRecordContext = () => {
        Reload(UnitControllerMode.Learn)

        setUseSubUnitContext(false)
        setUseSubUnitId(-1)

        setUseRoundRecordContext(false)
        setUseRoundRecordId(-1)

    }

    return <>

        {loaded && history != undefined && <>
            <UnitView
                key={props.Data.UnitId}
                Data={{
                    UnitDefinition: props.Data,
                    Params: unitParams,
                    RoundRecords: roundRecords,
                    History: history
                }}
                State={{
                    ControllerMode: mode,
                    Locked: locked,
                    Loaded: historyLoaded,
                    UseSubUnit: useSubUnitContext,
                    SubUnitId: useSubUnitContext ? useSubUnitId : -1,
                    RetryRound: useRoundRecordContext,
                    RoundRecordId: useRoundRecordContext ? useRoundRecordId : -1
                }}
                SetMode={setMode}
                SetBySubUnit={SetSubUnitContext}
                SetByRoundRecord={SetRoundRecordContext}
                ClearRoundRecord={ClearRoundRecordContext}
                ClearSubUnit={ClearSubUnitContext}
                Reload={Reload}
                OnEnter={props.OnEnter}
                SetMenu={props.SetMenu}
                SetPreamble={props.SetPreamble}
                OnExit={props.OnExit}
            />

        </>}

        {(!loaded || history === undefined) && <ContentTile>
            <TextStyle size={8} weight='bold'>{props.Data.DisplayLabel}</TextStyle>
            <TextStyle size={3} weight='bold' case='uppercase'>{props.Data.NameLabel}</TextStyle>

            <TextStyle>Loading...</TextStyle>
        </ContentTile>}
    </>
}
