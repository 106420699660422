import { TextStyle } from "../App"
import { StyledButton } from "../Components/ModeTile"
import { UnitDefinition, SubUnitDefinition } from "../Config/UnitDefinitionMap"
import { HistoryElement } from "../Database/HistoryState"
import { InnerTile } from "../Styles/Styles"
import { SetData, Data } from "../Types/Interfaces"


export const SubUnitDisplay: React.FC<SetData & Data<{ History: HistoryElement[], Unit: UnitDefinition<any>, SubUnit: SubUnitDefinition<any> }>> = props => {

    var unit = props.Data.Unit.Items.filter(props.Data.SubUnit.GetItems)

    var itemCache = {} as any

    unit.map(i =>
        itemCache[props.Data.Unit.GetItemId(i)] = i
    )

    var historyMap = {} as any

    props.Data.History.map(h => historyMap[h.ItemId] = h)

    var unitHistory = unit.map(i => historyMap[props.Data.Unit.GetItemId(i)])

    const totalCorrect = unitHistory.filter(h => h.Score > 0).length

    const unseen = unitHistory.filter(h => !h.Introduced).length
    const seen = unitHistory.length - unseen

    return <>
        <InnerTile>
            <TextStyle size={2} weight='bold'>{props.Data.SubUnit.Label}</TextStyle>
            <TextStyle>Learned: {totalCorrect}</TextStyle>
            <TextStyle>Remaining: {unseen}</TextStyle>
            <TextStyle>Progress: {seen == 0 ? 0 : totalCorrect / seen}%</TextStyle>
            <StyledButton onClick={() => props.SetData(props.Data.SubUnit)}><TextStyle>Select</TextStyle></StyledButton>
        </InnerTile>

    </>
}

