import { RoundControllerParams, RoundItemData, RoundRecord } from "../Views/RoundView"
import { HistoryState } from "../Database/HistoryState"
import { RoundHistoryState } from "../Database/RoundHistoryState"


export async function SetHistoryFromRoundRecord(RoundRecord: RoundRecord) {

    console.log("set history from round record")
    var unitId = RoundRecord.Items[0].UnitId
    var testDefinitionId = RoundRecord.Items[0].TestDefinition

    const history = await (new HistoryState()).GetByTestDefinitionId(unitId, RoundRecord.Items[0].TestDefinition);

    const historyCache = {} as any

    history.forEach(h => { historyCache[h.Id!!] = h })

    var Id = await (new RoundHistoryState()).AppendHistory(RoundRecord);

    var updatedHistory = RoundRecord.Items.map(r => {
        var historyItem = historyCache[r.HistoryId]
        historyItem.Record = r.Record
        historyItem.LastSeen = new Date()
        historyItem.Introduced = true
        historyItem.Score += 1

        return historyItem
    })

    await (new HistoryState()).SetTestDefinition(updatedHistory, unitId, testDefinitionId)

    return Id

}