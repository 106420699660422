import { TextStyle } from "../App";
import { ContentTile, StyledButton } from "../Components/ModeTile";
import { RoundRecord } from "./RoundView";
import { UnitLoaderCallbacks, State, UnitControllerState, UnitControllerProps, Reload, SetMenu, SetPreamble, UnitControllerMode } from "../Loaders/UnitLoader";
import { SubUnitDefinition } from "../Config/UnitDefinitionMap";
import { ButtonDisplay, ButtonSubDisplay } from "../Styles/Styles";
import { Data, OnEnter, OnExit } from "../Types/Interfaces";
import { DevDebugView } from "./DevDebugView";
import { LearnView } from "./LearnView";
import { ProcessTextView } from "./ProcessTextView";
import { RoundRecordView } from "./RoundRecordView";
import { SubUnitView } from "./SubUnitView";
import { UnitItemView } from "./UnitItemView";
import { UnitOptionsView } from "./UnitOptionsView";
import { StatsView } from "./StatsView";

export const UnitView: React.FC<UnitLoaderCallbacks & State<UnitControllerState> & Data<UnitControllerProps> & Reload & OnEnter & SetMenu & SetPreamble & OnExit> = props => {




    const Unit = props.Data.UnitDefinition;

    const history = props.Data.History
    const roundHistory = props.Data.RoundRecords

    const totalCorrect = history.filter(h => h.Score > 0).length

    const unseen = history.filter(h => !h.Introduced).length
    const seen = history.length - unseen

    const UnitControllerMenuConfig = [{
        Label: "Back",
        OnClick: () => { ChangeMode(UnitControllerMode.Home); }
    },
    {
        Label: "Exit",
        OnClick: () => { props.SetMode(UnitControllerMode.Home); props.OnExit() }
    }]

    const UnitControllerHomeMenuConfig = [{
        Label: "Exit",
        OnClick: () => { props.SetMode(UnitControllerMode.Home); props.OnExit() }
    }]

    const TakeFocus = (mode: UnitControllerMode) => {
        props.OnEnter()

        ChangeMode(mode)
    }

    const ChangeMode = (mode: UnitControllerMode) => {
        if (mode == UnitControllerMode.Home) {
            props.Reload(UnitControllerMode.Home)
            props.SetMenu(UnitControllerHomeMenuConfig)
        } else {
            props.SetMenu(UnitControllerMenuConfig)
        }

        props.SetPreamble(Unit.DisplayLabel)
        props.SetMode(mode)

    }

    const SetBySubUnit = (Defn: SubUnitDefinition<any>) => {
        props.SetBySubUnit(Defn)
    }

    const SetByRoundRecord = (record: RoundRecord) => {
        props.SetByRoundRecord(record)
    }

    const AddToMenu = (Menu: any[]) => {

        var baseMenu = UnitControllerMenuConfig
        var menu = [
            ...Menu,
            ...baseMenu
        ]

        props.SetMenu(menu)
    }

    return (<>
        <ContentTile style={{ justifyContent: 'center' }}>
            {props.State.ControllerMode == UnitControllerMode.Home && <>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextStyle size={8} weight='bold'>{Unit.DisplayLabel}</TextStyle>
                    {!props.State.UseSubUnit && <TextStyle size={3} weight='bold' case='uppercase'>{Unit.NameLabel}</TextStyle>}
                    {!props.State.Locked && props.State.Loaded && <>

                        {props.State.UseSubUnit && <TextStyle size={3} weight='bold' case='uppercase'>{Unit.SubUnits.find(s => s.Id == props.State.SubUnitId)?.Label}</TextStyle>}
                        <TextStyle>Learned: {totalCorrect}</TextStyle>
                        {!props.State.UseSubUnit && <TextStyle>Remaining: {unseen}</TextStyle>}
                        {/* {props.State.UseSubUnit && <TextStyle>Remaining: {subUnitHistory.length}</TextStyle>} */}
                        {props.State.Loaded && <TextStyle>Progress: {((seen / history.length) * 100).toFixed(0)}%</TextStyle>}

                        <hr />
                        {props.State.UseSubUnit && <TextStyle>SubUnit {props.State.SubUnitId}</TextStyle>}
                        {props.State.RetryRound && <TextStyle>RetryRound {props.State.RoundRecordId}</TextStyle>}
                        <ButtonDisplay>
                            <StyledButton onClick={() => TakeFocus(UnitControllerMode.Learn)}><TextStyle case='uppercase' size={2} weight='bold'>{/*totalCorrect == 0*/ true ? "Begin" : "Continue"}</TextStyle></StyledButton>
                            <StyledButton onClick={() => TakeFocus(UnitControllerMode.Options)}><TextStyle case='uppercase' size={2} weight='bold'>Options</TextStyle></StyledButton>

                            <ButtonSubDisplay>
                                <StyledButton onClick={() => TakeFocus(UnitControllerMode.Display)}><TextStyle case='uppercase' size={2} weight='bold'>View</TextStyle></StyledButton>
                                <StyledButton onClick={() => TakeFocus(UnitControllerMode.ViewRoundRecords)}><TextStyle case='uppercase' size={2} weight='bold'>History</TextStyle></StyledButton>
                                {Unit.SubUnits.length > 0 && <StyledButton onClick={() => TakeFocus(UnitControllerMode.SubUnits)}><TextStyle case='uppercase' size={2} weight='bold'>Sub-Units</TextStyle></StyledButton>}
                            </ButtonSubDisplay>
                            <ButtonSubDisplay>
                                {Unit.CanProcessText && <StyledButton onClick={() => TakeFocus(UnitControllerMode.ProcessText)}><TextStyle case='uppercase' size={2} weight='bold'>Process Text</TextStyle></StyledButton>}
                                <StyledButton onClick={() => TakeFocus(UnitControllerMode.Stats)}><TextStyle case='uppercase' size={2} weight='bold'>Stats</TextStyle></StyledButton>

                            </ButtonSubDisplay>
                            <ButtonSubDisplay>
                                <StyledButton onClick={() => TakeFocus(UnitControllerMode.DebugDev)}><TextStyle case='uppercase' size={2} weight='bold'>Debug/Dev</TextStyle></StyledButton>
                            </ButtonSubDisplay>
                        </ButtonDisplay>



                    </>}
                    {props.State.Locked && <>
                        <TextStyle>This unit is locked.</TextStyle>
                    </>}
                    {!props.State.Loaded && <>
                        <TextStyle>Loading...</TextStyle>
                    </>}
                </div>
            </>}

            {props.State.ControllerMode == UnitControllerMode.Display && <>
                <UnitItemView Data={{ Unit: props.Data.UnitDefinition, History: history }} />
            </>}

            {props.State.ControllerMode == UnitControllerMode.Learn && <>

                <LearnView
                    OnExit={() => { props.ClearRoundRecord(); ChangeMode(UnitControllerMode.Home) }}
                    Data={{
                        Params: props.Data.Params,
                        History: history,
                        Unit: Unit,
                        RoundRecords: props.Data.RoundRecords
                    }}
                    State={{
                        UseSubUnit: props.State.UseSubUnit,
                        SubUnitId: props.State.SubUnitId,
                        RetryRound: props.State.RetryRound,
                        RoundRecordId: props.State.RoundRecordId
                    }}
                    SetMenu={props.SetMenu}
                    Retry={SetByRoundRecord}
                />
            </>}
            {props.State.ControllerMode == UnitControllerMode.SubUnits && <>
                <SubUnitView SetData={SetBySubUnit}
                    Data={{
                        Unit: Unit,
                        History: history
                    }}
                />
            </>}
            {props.State.ControllerMode == UnitControllerMode.Options && <>
                <UnitOptionsView
                    Data={{ UnitId: Unit.UnitId, Params: props.Data.Params }}
                    SetMenu={props.SetMenu}
                    ParentMenuConfig={UnitControllerMenuConfig}
                    SetData={() => {
                        ChangeMode(UnitControllerMode.Home);
                    }} />
            </>}
            {props.State.ControllerMode == UnitControllerMode.ViewRoundRecords && <>
                <RoundRecordView SetData={SetByRoundRecord} Data={{ Unit: Unit, RoundRecords: roundHistory }} />
            </>}
            {props.State.ControllerMode == UnitControllerMode.DebugDev && <>
                <DevDebugView SetData={() => { ChangeMode(UnitControllerMode.Home) }} Data={{ Unit: Unit }} />
            </>}
            {props.State.ControllerMode == UnitControllerMode.ProcessText && <>
                <ProcessTextView SetMenu={AddToMenu} />
            </>}
            {props.State.ControllerMode == UnitControllerMode.Stats && <>
                <StatsView Data={{ Unit: Unit, History: history, RoundRecords: roundHistory }} />
            </>}
        </ContentTile>
    </>)
}



