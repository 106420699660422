import { TextStyle } from "../App"
import { hanzi, HanziUnitItem } from "../data/hanzi"
import { kana, KanaUnitItem } from "../data/kana"
import { kangxi, KangxiUnitItem } from "../data/kangxi"
import { cnwords, CnWordUnitItem } from "../data/words"
import { HistoryState } from "../Database/HistoryState"

export enum UnitType {
    Radicals,
    Hanzi,
    Words,
    Custom,
    Kana
}


enum KangxiTestDefinition {
    GlyphToMeaning,
    ReadingMeaningToGlyph,
    GlyphMeaningToReading,
    GlyphReadingToMeaning
}

enum HanziTestDefinition {
    GlyphToMeaning,
}

enum CnWordTestDefinition {
    GlyphToMeaning,
}

enum KanaTestDefinition {
    GlyphToMeaning,
}

interface TestDefinition<Unit> {
    id: number

    Label: string
    UnitType: UnitType
    Reintroduce: boolean

    AboveFold: (unit: Unit) => React.ReactElement
    BelowFold: (unit: Unit) => React.ReactElement

    UserIndicatesIfCorrect: boolean
    InteractiveCorrect?: (unit: Unit, onAnswer: (correct: boolean) => any) => React.FC<{ onAnswer: (correct: boolean) => any }>

}

export type TestDefinitionMapType<T> = { [testDefinition: number]: TestDefinition<T> }

export interface SubUnitDefinition<UnitItem> {
    Id: number
    Category: string

    Label: string

    GetItems: (item: UnitItem) => boolean
}

interface OrderUnitDefinition<UnitItem> {
    Id: number
    Label: string

    Order: (i1: UnitItem, i2: UnitItem) => number
}

export interface UnitDefinition<UnitItem> {
    UnitType: UnitType
    UnitId: number
    NameLabel: string
    DisplayLabel: string

    Items: UnitItem[]
    GetItemId: (item: UnitItem) => number
    GetItemDisplay: (item: UnitItem) => string

    UnitLocked: boolean
    UnlockUnitOn: () => boolean | Promise<boolean>
    ItemsLocked: boolean
    UnlockItemOn: () => boolean

    CanProcessText: boolean

    ParentTestDefinitionId: number

    TestDefinitions: { [n: number]: TestDefinition<UnitItem> }

    SubUnits: SubUnitDefinition<UnitItem>[]

    OrderUnit: OrderUnitDefinition<UnitItem>[]

}

export const UnitDefinitionMap: { [n: number]: UnitDefinition<any> } = {
    [UnitType.Radicals]: {
        UnitType: UnitType.Radicals,
        UnitId: 0,
        NameLabel: "Radicals",
        DisplayLabel: "部首",

        Items: kangxi as KangxiUnitItem[],
        GetItemId: (item: KangxiUnitItem) => { return item.n },
        GetItemDisplay: (item: KangxiUnitItem) => item.glyph,

        UnitLocked: false,
        UnlockUnitOn: () => true,
        ItemsLocked: false,
        UnlockItemOn: () => true,

        CanProcessText: false,

        ParentTestDefinitionId: KangxiTestDefinition.GlyphToMeaning,
        ReintroduceChildTestItems: false,

        TestDefinitions: {
            [KangxiTestDefinition.GlyphToMeaning]: {
                id: KangxiTestDefinition.GlyphToMeaning,

                Label: 'Kangxi:KangxiToMeaning',
                UnitType: UnitType.Radicals,
                Reintroduce: false,

                AboveFold: (unit: KangxiUnitItem) => {
                    return <>
                        <TextStyle size={10} weight='bold'>{unit.glyph}</TextStyle></>
                },
                BelowFold: (unit: KangxiUnitItem) => {
                    return <>
                        <TextStyle size={3} weight='bold'>{unit.definition}</TextStyle>
                        <TextStyle size={3} weight='bold'>{unit.reading}</TextStyle>

                    </>
                },

                UserIndicatesIfCorrect: true

            } as TestDefinition<KangxiUnitItem>
        } as TestDefinitionMapType<KangxiUnitItem>,

        SubUnits: [],
        OrderUnit: []
    } as UnitDefinition<KangxiUnitItem>,

    [UnitType.Hanzi]: {
        UnitType: UnitType.Hanzi,
        UnitId: 1,
        NameLabel: "Hanzi",
        DisplayLabel: "汉字",

        Items: hanzi as HanziUnitItem[],
        GetItemId: (item: HanziUnitItem) => item.GeneralStandard,
        GetItemDisplay: (item: HanziUnitItem) => item.Character,

        UnitLocked: true,
        UnlockUnitOn: async () => {
            return (await (new HistoryState()).IsUnitCompleted(UnitType.Radicals)) === true
        },
        ItemsLocked: false,
        UnlockItemOn: () => true,

        CanProcessText: true,

        ParentTestDefinitionId: HanziTestDefinition.GlyphToMeaning,

        TestDefinitions: {
            [HanziTestDefinition.GlyphToMeaning]: {
                id: HanziTestDefinition.GlyphToMeaning,

                Label: 'Hanzi:GlyphToMeaning',
                UnitType: UnitType.Hanzi,
                Reintroduce: false,

                AboveFold: (unit: HanziUnitItem) => {
                    return <>
                        <TextStyle size={10} weight='bold'>{unit.Character}</TextStyle></>
                },
                BelowFold: (unit: HanziUnitItem) => {
                    return <>
                        <TextStyle size={3} weight='bold'>{unit.Pinyin}</TextStyle>
                        <TextStyle size={1.5} weight='bold'>{unit.Definition}</TextStyle>

                    </>
                },

                UserIndicatesIfCorrect: true
            }
        },
        SubUnits: [
            {
                Id: 1,
                Category: "HSK",
                Label: "HSK1",
                GetItems: (item: HanziUnitItem) => item.HSK == 1
            },
            {
                Id: 2,
                Category: "HSK",
                Label: "HSK2",
                GetItems: (item: HanziUnitItem) => item.HSK == 2
            },
            {
                Id: 3,
                Category: "HSK",
                Label: "HSK3",
                GetItems: (item: HanziUnitItem) => item.HSK == 3
            },
            {
                Id: 4,
                Category: "HSK",
                Label: "HSK4",
                GetItems: (item: HanziUnitItem) => item.HSK == 4
            },
            {
                Id: 5,
                Category: "HSK",
                Label: "HSK5",
                GetItems: (item: HanziUnitItem) => item.HSK == 5
            },
            {
                Id: 6,
                Category: "HSK",
                Label: "HSK6",
                GetItems: (item: HanziUnitItem) => item.HSK == 6
            },
            {
                Id: 7,
                Category: "HSK",
                Label: "HSK7",
                GetItems: (item: HanziUnitItem) => item.HSK == 7
            },
            {
                Id: 8,
                Category: "HSK",
                Label: "HSK8",
                GetItems: (item: HanziUnitItem) => item.HSK == 8

            },
            {
                Id: 9,
                Category: "HSK",            
                Label: "HSK9",
                GetItems: (item: HanziUnitItem) => item.HSK == 9
            },
            {
                Id: 10,
                Category: "General Standard",
                Label: "Common General Standard",
                GetItems: (item: HanziUnitItem) => item.GeneralStandard <= 6500
            },
            {
                Id: 11,
                Category: "General Standard",
                Label: "Uncommon General Standard",
                GetItems: (item: HanziUnitItem) => item.GeneralStandard > 6500
            }

        ],
        OrderUnit: [
            {
                Id: 0,
                Label: "ByGeneralStandard",
                Order: (i1: HanziUnitItem, i2: HanziUnitItem) => i1.GeneralStandard - i2.GeneralStandard
            },
            {
                Id: 1,
                Label: "ByFrequencyRank",
                Order: (i1: HanziUnitItem, i2: HanziUnitItem) => {
                    if (i1.FrequencyRank != undefined && i2.FrequencyRank != undefined)
                        return i1.FrequencyRank - i2.FrequencyRank

                    if (i1.FrequencyRank == undefined && i2.FrequencyRank == undefined)
                        return 0

                    if (i1.FrequencyRank == undefined)
                        return 1

                    if (i2.FrequencyRank == undefined)
                        return -1
                }
            },
            {
                Id: 1,
                Label: "ByHSK",
                Order: (i1: HanziUnitItem, i2: HanziUnitItem) => {
                    if (i1.HSK != undefined && i2.HSK != undefined)
                        return i1.HSK - i2.HSK

                    if (i1.HSK == undefined && i2.HSK == undefined)
                        return 0

                    if (i1.HSK == undefined)
                        return 1

                    if (i2.HSK == undefined)
                        return -1
                }
            },
        ]


    } as UnitDefinition<HanziUnitItem>,
    [UnitType.Words]: {
        UnitType: UnitType.Words,
        UnitId: 2,
        NameLabel: "CNWords",
        DisplayLabel: "词语",


        Items: cnwords as CnWordUnitItem[],
        GetItemId: (item: CnWordUnitItem) => item.id,
        GetItemDisplay: (item: CnWordUnitItem) => item.simplified,

        UnitLocked: true,
        UnlockUnitOn: async () => {
            return (await (new HistoryState()).IsUnitCompleted(UnitType.Radicals)) === true
        },
        ItemsLocked: true,
        UnlockItemOn: () => false,

        CanProcessText: false,

        ParentTestDefinitionId: CnWordTestDefinition.GlyphToMeaning,

        TestDefinitions: {
            [CnWordTestDefinition.GlyphToMeaning]: {
                id: CnWordTestDefinition.GlyphToMeaning,

                Label: 'CnWord:GlyphToMeaning',
                UnitType: UnitType.Words,
                Reintroduce: false,

                AboveFold: (unit: CnWordUnitItem) => {
                    return <>
                        <TextStyle size={6} weight='bold'>{unit.simplified}</TextStyle></>
                },
                BelowFold: (unit: CnWordUnitItem) => {
                    return <>
                        <TextStyle size={3} weight='bold'>{unit.reading}</TextStyle>
                        <TextStyle size={1.5} weight='bold'>{unit.meaning}</TextStyle>

                    </>
                },

                UserIndicatesIfCorrect: true
            }
        },
        SubUnits: [
        ],
        OrderUnit: []


    } as UnitDefinition<CnWordUnitItem>,

    [UnitType.Kana]: {
        UnitType: UnitType.Kana,
        UnitId: 4,
        NameLabel: "Kana",
        DisplayLabel: "かな",

        Items: kana as KanaUnitItem[],
        GetItemId: (item: KanaUnitItem) => item.id,
        GetItemDisplay: (item: KanaUnitItem) => item.glyph,

        UnitLocked: false,
        UnlockUnitOn: () => false,
        ItemsLocked: false,
        UnlockItemOn: () => false,

        CanProcessText: true,

        ParentTestDefinitionId: 0,

        TestDefinitions: {
            [KanaTestDefinition.GlyphToMeaning]: {
                id: 0,

                Label: 'Kana:GlyphToReading',
                UnitType: UnitType.Kana,
                Reintroduce: false,

                AboveFold: (unit: KanaUnitItem) => {
                    return <>
                        <TextStyle size={6} weight='bold'>{unit.glyph}</TextStyle></>
                },
                BelowFold: (unit: KanaUnitItem) => {
                    return <>
                        <TextStyle size={3} weight='bold'>{unit.reading}</TextStyle>

                    </>
                },

                UserIndicatesIfCorrect: true
            }
        },

        SubUnits: [
            {
                Id: 1,
                Category: "Script",
                Label: "Hiragana",
                GetItems: (item: KanaUnitItem) => item.type == "hiragana"
            },
            {
                Id: 2,
                Category: "Script",
                Label: "Katakana",
                GetItems: (item: KanaUnitItem) => item.type == "katakana"
            },
        ],
        OrderUnit: []


    } as UnitDefinition<KanaUnitItem>
}