import Dexie, { Transaction } from 'dexie';
import { RoundRecord } from '../Views/RoundView';
import { UnitDefinitionMap } from '../Config/UnitDefinitionMap';
import { HistoryElement } from './HistoryState';
import { RoundHistoryItem } from './RoundHistoryState';
import { DefaultUnitControllerParams, SettingsState, SettingsStorageItem } from './SettingsState';
import { MessageRecord } from './MessageRecord';

interface UnitState {
    id?: number
    UnitId: number
    Locked: boolean,
    Completed: boolean,

}

export class AppDexie extends Dexie {

    UnitState!: Dexie.Table<UnitState>

    History!: Dexie.Table<HistoryElement>

    RoundHistory!: Dexie.Table<RoundRecord>

    Settings!: Dexie.Table<SettingsStorageItem>

    Messages!: Dexie.Table<MessageRecord>

    constructor() {
        super('Database');
        this.version(1).stores({
            UnitState: '++id, UnitId, Unlocked',
            History: '++Id, UnitId, ItemId, TestDefinitionId',
            RoundHistory: '++Id, UnitId, RoundGuid',
            Settings: '++Id, UnitId',
            Messages: '++Id'
        });
    }
}

export const db = new AppDexie()


async function Initialise(tx: Transaction) {
    // init unit state

    for (const [k, ud] of Object.entries(UnitDefinitionMap)) {
        var unitId = parseInt(k)

        var unitState = await (tx.table("UnitState").where('UnitId').equals(unitId)).first()

        if (unitState == undefined) {

            await tx.table("UnitState").put({
                id: unitId,
                UnitId: unitId,
                Locked: ud.UnitLocked,
                Completed: false,
            });

            Object.entries(ud.TestDefinitions).map((td, i) => {
                ud.Items.map(async item => {

                    await tx.table("History").add({
                        UnitId: ud.UnitId,
                        ItemId: ud.GetItemId(item),
                        TestDefinitionId: td[1].id,

                        Introduced: false,
                        Unlocked: false,

                        LastSeen: undefined,
                        Score: 0,
                        Record: []
                    })
                })
            })

        }

        // set default settings for each unit
        var defaultSettings = {
            UnitId: unitId,
            Params: DefaultUnitControllerParams
        }

        defaultSettings.Params.OrderRound = undefined


        await tx.table("Settings").add(defaultSettings)
    }

};

db.on("populate", async (tx: Transaction) => {
    await Initialise(tx)
})